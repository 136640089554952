export const dataRegion = `
5d311191-41b9-11df-907f-00215aee3ebe;Алчевськ;Алчевск;d15e3023-60b0-11de-be1e-0030485903e8
e57e3c46-14f0-11ee-80bd-000c2961d091;Алчевський;;d15e3023-60b0-11de-be1e-0030485903e8
5d3111ae-41b9-11df-907f-00215aee3ebe;Амвросіївський;Амвросиевский;d15e301c-60b0-11de-be1e-0030485903e8
5d3111cc-41b9-11df-907f-00215aee3ebe;Ананьївський;Ананьевский;d15e3026-60b0-11de-be1e-0030485903e8
5d311237-41b9-11df-907f-00215aee3ebe;Андрушівський;Андрушевский;d15e301d-60b0-11de-be1e-0030485903e8
5d31128f-41b9-11df-907f-00215aee3ebe;Антрацитівський;Антрацитовский;d15e3023-60b0-11de-be1e-0030485903e8
5d3112a9-41b9-11df-907f-00215aee3ebe;Апостолівський;Апостоловский;d15e301b-60b0-11de-be1e-0030485903e8
4ffc4bc4-41b9-11df-907f-00215aee3ebe;Арбузинський;Арбузинский;d15e3025-60b0-11de-be1e-0030485903e8
64024b48-41b9-11df-907f-00215aee3ebe;Арцизький;Арцизский;d15e3026-60b0-11de-be1e-0030485903e8
5d311224-41b9-11df-907f-00215aee3ebe;Балаклійський;Балаклейский;d15e302c-60b0-11de-be1e-0030485903e8
5d31122c-41b9-11df-907f-00215aee3ebe;Балтський;Балтский;d15e3026-60b0-11de-be1e-0030485903e8
6b07f8b3-41b9-11df-907f-00215aee3ebe;Баранівський;Барановский;d15e301d-60b0-11de-be1e-0030485903e8
6b07f8d0-41b9-11df-907f-00215aee3ebe;Барвінківський;Барвенковский;d15e302c-60b0-11de-be1e-0030485903e8
6b07f8d4-41b9-11df-907f-00215aee3ebe;Баришівський;Барышевский;d15e3021-60b0-11de-be1e-0030485903e8
4ffc4bd0-41b9-11df-907f-00215aee3ebe;Барський;Барский;d15e3019-60b0-11de-be1e-0030485903e8
710c271d-41b9-11df-907f-00215aee3ebe;Бахмацький;Бахмачский;d15e3031-60b0-11de-be1e-0030485903e8
64024b25-41b9-11df-907f-00215aee3ebe;Бахмутський;Бахмутский;d15e301c-60b0-11de-be1e-0030485903e8
710c273c-41b9-11df-907f-00215aee3ebe;Баштанський;Баштанский;d15e3025-60b0-11de-be1e-0030485903e8
4ffc4bad-41b9-11df-907f-00215aee3ebe;Бердичівський;Бердичевский;d15e301d-60b0-11de-be1e-0030485903e8
5d31120e-41b9-11df-907f-00215aee3ebe;Бердянський;Бердянский;d15e301f-60b0-11de-be1e-0030485903e8
6b07f815-41b9-11df-907f-00215aee3ebe;Берегівський;Береговский;d15e301e-60b0-11de-be1e-0030485903e8
710c27ce-41b9-11df-907f-00215aee3ebe;Бережанський;Бережанский;d15e302b-60b0-11de-be1e-0030485903e8
5d3111d5-41b9-11df-907f-00215aee3ebe;Березанський;Березанский;d15e3025-60b0-11de-be1e-0030485903e8
5d3111d6-41b9-11df-907f-00215aee3ebe;Березівський;Березовский;d15e3026-60b0-11de-be1e-0030485903e8
710c27f1-41b9-11df-907f-00215aee3ebe;Березнегуватський;Березнеговатский;d15e3025-60b0-11de-be1e-0030485903e8
6b07f87f-41b9-11df-907f-00215aee3ebe;Березнівський;Березновский;d15e3028-60b0-11de-be1e-0030485903e8
78f453a7-41b9-11df-907f-00215aee3ebe;Бериславський;Бериславский;d15e302d-60b0-11de-be1e-0030485903e8
6b07f874-41b9-11df-907f-00215aee3ebe;Бершадський;Бершадский;d15e3019-60b0-11de-be1e-0030485903e8
4ffc4bd4-41b9-11df-907f-00215aee3ebe;Білгород-Дністровський;Белгород-Днестровский;d15e3026-60b0-11de-be1e-0030485903e8
6b07f8b1-41b9-11df-907f-00215aee3ebe;Біловодський;Беловодский;d15e3023-60b0-11de-be1e-0030485903e8
f933343e-db4e-11df-9197-00215aee3ebe;Білогірський;Белогорский;d15e302e-60b0-11de-be1e-0030485903e8
78f453fc-41b9-11df-907f-00215aee3ebe;Білозерський;Белозерский;d15e302d-60b0-11de-be1e-0030485903e8
78f45400-41b9-11df-907f-00215aee3ebe;Білокуракинський;Белокуракинский;d15e3023-60b0-11de-be1e-0030485903e8
78f453eb-41b9-11df-907f-00215aee3ebe;Білопільський;Белопольский;d15e302a-60b0-11de-be1e-0030485903e8
78f453c9-41b9-11df-907f-00215aee3ebe;Білоцерківський;Белоцерковский;d15e3021-60b0-11de-be1e-0030485903e8
4ffc4b84-41b9-11df-907f-00215aee3ebe;Біляївський;Беляевский;d15e3026-60b0-11de-be1e-0030485903e8
78f45406-41b9-11df-907f-00215aee3ebe;Більмацький;Бильмацкий;d15e301f-60b0-11de-be1e-0030485903e8
8067accd-41b9-11df-907f-00215aee3ebe;Благовіщенський (Ульянівс;Благовещенский (Ульяновск;d15e3022-60b0-11de-be1e-0030485903e8
56302a6a-41b9-11df-907f-00215aee3ebe;Близнюківський;Близнюковский;d15e302c-60b0-11de-be1e-0030485903e8
78f453a0-41b9-11df-907f-00215aee3ebe;Бобринецький;Бобринецкий;d15e3022-60b0-11de-be1e-0030485903e8
78f45407-41b9-11df-907f-00215aee3ebe;Бобровицький;Бобровицкий;d15e3031-60b0-11de-be1e-0030485903e8
8067acdb-41b9-11df-907f-00215aee3ebe;Богодухівський;Богодуховский;d15e302c-60b0-11de-be1e-0030485903e8
6b07f7f6-41b9-11df-907f-00215aee3ebe;Богородчанський;Богородчанский;d15e3020-60b0-11de-be1e-0030485903e8
78f453e5-41b9-11df-907f-00215aee3ebe;Богуславський;Богуславский;d15e3021-60b0-11de-be1e-0030485903e8
40c05e81-cb4c-11ea-80e1-00215aee3ebc;Бойківський;Бойковский;d15e301c-60b0-11de-be1e-0030485903e8
5d311216-41b9-11df-907f-00215aee3ebe;Бойківський (Тельманівський);Бойковский;d15e301c-60b0-11de-be1e-0030485903e8
6b07f8a2-41b9-11df-907f-00215aee3ebe;Болградський;Болградский;d15e3026-60b0-11de-be1e-0030485903e8
8067acf2-41b9-11df-907f-00215aee3ebe;Болехів;Болехов;d15e3020-60b0-11de-be1e-0030485903e8
78f453a2-41b9-11df-907f-00215aee3ebe;Борзнянський;Борзнянский;d15e3031-60b0-11de-be1e-0030485903e8
8067ad5d-41b9-11df-907f-00215aee3ebe;Борислав;Борислав;d15e3024-60b0-11de-be1e-0030485903e8
5d31121a-41b9-11df-907f-00215aee3ebe;Бориспільський;Бориспольский;d15e3021-60b0-11de-be1e-0030485903e8
710c272e-41b9-11df-907f-00215aee3ebe;Борівський;Боровский;d15e302c-60b0-11de-be1e-0030485903e8
6b07f7e6-41b9-11df-907f-00215aee3ebe;Бородянський;Бородянский;d15e3021-60b0-11de-be1e-0030485903e8
6b07f7e7-41b9-11df-907f-00215aee3ebe;Борщівський;Борщевский;d15e302b-60b0-11de-be1e-0030485903e8
5d311280-41b9-11df-907f-00215aee3ebe;Братський;Братский;d15e3025-60b0-11de-be1e-0030485903e8
78f45436-41b9-11df-907f-00215aee3ebe;Броварський;Броварской;d15e3021-60b0-11de-be1e-0030485903e8
710c2722-41b9-11df-907f-00215aee3ebe;Бродівський;Бродовский;d15e3024-60b0-11de-be1e-0030485903e8
8a199cda-41b9-11df-907f-00215aee3ebe;Брусилівський;Брусиловский;d15e301d-60b0-11de-be1e-0030485903e8
8a199ce1-41b9-11df-907f-00215aee3ebe;Брянка;Брянка;d15e3023-60b0-11de-be1e-0030485903e8
78f453e4-41b9-11df-907f-00215aee3ebe;Буринський;Буринский;d15e302a-60b0-11de-be1e-0030485903e8
5d31121f-41b9-11df-907f-00215aee3ebe;Буський;Бусский;d15e3024-60b0-11de-be1e-0030485903e8
804761f4-14c9-11ee-80bd-000c2961d091;Бучанський;Бучанский;d15e3021-60b0-11de-be1e-0030485903e8
6b07f802-41b9-11df-907f-00215aee3ebe;Бучацький;Бучацкий;d15e302b-60b0-11de-be1e-0030485903e8
6b07f8b9-41b9-11df-907f-00215aee3ebe;Валківський;Валковский;d15e302c-60b0-11de-be1e-0030485903e8
baa8187f-14c9-11ee-80bd-000c2961d091;Вараський;Варашский;d15e3028-60b0-11de-be1e-0030485903e8
5394b31c-41be-11df-907f-00215aee3ebe;Вараш (Кузнецовськ);Вараш;d15e3028-60b0-11de-be1e-0030485903e8
5d31127c-41b9-11df-907f-00215aee3ebe;Варвинський;Варвинский;d15e3031-60b0-11de-be1e-0030485903e8
6b07f889-41b9-11df-907f-00215aee3ebe;Василівський;Васильевский;d15e301f-60b0-11de-be1e-0030485903e8
4ffc4b9b-41b9-11df-907f-00215aee3ebe;Васильківський;Васильковский;d15e301b-60b0-11de-be1e-0030485903e8
271f789c-72ed-11df-b112-00215aee3ebe;Васильківський;Васильковский;d15e3021-60b0-11de-be1e-0030485903e8
6b07f807-41b9-11df-907f-00215aee3ebe;Великобагачанський;Великобагачанский;d15e3027-60b0-11de-be1e-0030485903e8
8a199cf5-41b9-11df-907f-00215aee3ebe;Великоберезнянський;Великоберезнянский;d15e301e-60b0-11de-be1e-0030485903e8
903ccace-41b9-11df-907f-00215aee3ebe;Великобілозерський;Великобелозерский;d15e301f-60b0-11de-be1e-0030485903e8
5d311225-41b9-11df-907f-00215aee3ebe;Великобурлуцький;Великобурлукский;d15e302c-60b0-11de-be1e-0030485903e8
903ccad1-41b9-11df-907f-00215aee3ebe;Великолепетиський;Великолепетихский;d15e302d-60b0-11de-be1e-0030485903e8
78f45420-41b9-11df-907f-00215aee3ebe;Великомихайлівський;Великомихайловский;d15e3026-60b0-11de-be1e-0030485903e8
5d311213-41b9-11df-907f-00215aee3ebe;Великоновосілківський;Великоновоселковский;d15e301c-60b0-11de-be1e-0030485903e8
78f453e8-41b9-11df-907f-00215aee3ebe;Великоолександрівський;Великоалександровский;d15e302d-60b0-11de-be1e-0030485903e8
8a199cd0-41b9-11df-907f-00215aee3ebe;Великописарівський;Великописаревский;d15e302a-60b0-11de-be1e-0030485903e8
4ffc4bcc-41b9-11df-907f-00215aee3ebe;Верхньодніпровський;Верхнеднепровский;d15e301b-60b0-11de-be1e-0030485903e8
64024bcd-41b9-11df-907f-00215aee3ebe;Верхньорогачицький;Верхнерогачикский;d15e302d-60b0-11de-be1e-0030485903e8
78f453dc-41b9-11df-907f-00215aee3ebe;Верховинський;Верховинский;d15e3020-60b0-11de-be1e-0030485903e8
8067ad55-41b9-11df-907f-00215aee3ebe;Веселинівський;Веселиновский;d15e3025-60b0-11de-be1e-0030485903e8
4ffc4b7e-41b9-11df-907f-00215aee3ebe;Веселівський;Веселовский;d15e301f-60b0-11de-be1e-0030485903e8
64024bce-41b9-11df-907f-00215aee3ebe;Вижницький;Вижницкий;d15e3030-60b0-11de-be1e-0030485903e8
78f4542f-41b9-11df-907f-00215aee3ebe;Виноградівський;Виноградовский;d15e301e-60b0-11de-be1e-0030485903e8
64024b41-41b9-11df-907f-00215aee3ebe;Високопільський;Высокопольский;d15e302d-60b0-11de-be1e-0030485903e8
4ffc4b58-41b9-11df-907f-00215aee3ebe;Вишгородський;Вышгородский;d15e3021-60b0-11de-be1e-0030485903e8
a1e9f9b0-41b9-11df-907f-00215aee3ebe;Вільногірськ;Вольногорск;d15e301b-60b0-11de-be1e-0030485903e8
5d311270-41b9-11df-907f-00215aee3ebe;Вільнянський;Вольнянский;d15e301f-60b0-11de-be1e-0030485903e8
8a199cee-41b9-11df-907f-00215aee3ebe;Вільшанський;Ольшанский;d15e3022-60b0-11de-be1e-0030485903e8
a1e9f9b4-41b9-11df-907f-00215aee3ebe;Вінниця;Винница;d15e3019-60b0-11de-be1e-0030485903e8
4ffc4bc3-41b9-11df-907f-00215aee3ebe;Вінницький;Винницкий;d15e3019-60b0-11de-be1e-0030485903e8
4ffc4bd6-41b9-11df-907f-00215aee3ebe;Віньковецький;Виньковецкий;d15e302e-60b0-11de-be1e-0030485903e8
6b07f8d8-41b9-11df-907f-00215aee3ebe;Вітовський (Жовтневий);Витовский (Октябрьский);d15e3025-60b0-11de-be1e-0030485903e8
78f453f5-41b9-11df-907f-00215aee3ebe;Вовчанський;Волчанский;d15e302c-60b0-11de-be1e-0030485903e8
56302966-41b9-11df-907f-00215aee3ebe;Вознесенський;Вознесенский;d15e3025-60b0-11de-be1e-0030485903e8
5d3111c2-41b9-11df-907f-00215aee3ebe;Волноваський;Волновахский;d15e301c-60b0-11de-be1e-0030485903e8
4ffc4b65-41b9-11df-907f-00215aee3ebe;Воловецький;Воловецкий;d15e301e-60b0-11de-be1e-0030485903e8
710c27ee-41b9-11df-907f-00215aee3ebe;Володарський;Володарский;d15e3021-60b0-11de-be1e-0030485903e8
5d3111ad-41b9-11df-907f-00215aee3ebe;Володимир-Волинський;Владимир-Волынский;d15e301a-60b0-11de-be1e-0030485903e8
4de64d35-cb2d-11ea-80e1-00215aee3ebc;Володимир-Волинський (місто);Владимир-Волынский город;d15e301a-60b0-11de-be1e-0030485903e8
5d311275-41b9-11df-907f-00215aee3ebe;Володимирецький;Владимирецкий;d15e3028-60b0-11de-be1e-0030485903e8
4ffc4b9e-41b9-11df-907f-00215aee3ebe;Волочиський;Волочисский;d15e302e-60b0-11de-be1e-0030485903e8
4ffc4bd3-41b9-11df-907f-00215aee3ebe;Врадіївський;Врадиевский;d15e3025-60b0-11de-be1e-0030485903e8
710c27fb-41b9-11df-907f-00215aee3ebe;Гадяцький;Гадячский;d15e3027-60b0-11de-be1e-0030485903e8
6b07f89d-41b9-11df-907f-00215aee3ebe;Гайворонський;Гайворонский;d15e3022-60b0-11de-be1e-0030485903e8
8067ad56-41b9-11df-907f-00215aee3ebe;Гайсинський;Гайсинский;d15e3019-60b0-11de-be1e-0030485903e8
78f4540c-41b9-11df-907f-00215aee3ebe;Галицький;Галицкий;d15e3020-60b0-11de-be1e-0030485903e8
64024b7b-41b9-11df-907f-00215aee3ebe;Генічеський;Генический;d15e302d-60b0-11de-be1e-0030485903e8
6b07f84d-41b9-11df-907f-00215aee3ebe;Герцаївський;Герцаевский;d15e3030-60b0-11de-be1e-0030485903e8
6b07f810-41b9-11df-907f-00215aee3ebe;Глибоцький;Глыбокский;d15e3030-60b0-11de-be1e-0030485903e8
6b07f7ed-41b9-11df-907f-00215aee3ebe;Глобинський;Глобинский;d15e3027-60b0-11de-be1e-0030485903e8
6b07f89f-41b9-11df-907f-00215aee3ebe;Глухівський;Глуховский;d15e302a-60b0-11de-be1e-0030485903e8
9b486f58-41b9-11df-907f-00215aee3ebe;Голованівський;Голованивский;d15e3022-60b0-11de-be1e-0030485903e8
78f453bb-41b9-11df-907f-00215aee3ebe;Голопристанський;Голопристанский;d15e302d-60b0-11de-be1e-0030485903e8
d00d3b59-41b9-11df-907f-00215aee3ebe;Горішні Плавні (Комсомольськ);Комсомольский;d15e3027-60b0-11de-be1e-0030485903e8
bad3ac34-41b9-11df-907f-00215aee3ebe;Горлівка;Горловка;d15e301c-60b0-11de-be1e-0030485903e8
97574e99-14f0-11ee-80bd-000c2961d091;Горлівський;Горловский;d15e301c-60b0-11de-be1e-0030485903e8
903ccacf-41b9-11df-907f-00215aee3ebe;Горностаївський;Горностаевский;d15e302d-60b0-11de-be1e-0030485903e8
903ccae3-41b9-11df-907f-00215aee3ebe;Городенківський;Городенковский;d15e3020-60b0-11de-be1e-0030485903e8
8a199cec-41b9-11df-907f-00215aee3ebe;Городищенський;Городищенский;d15e302f-60b0-11de-be1e-0030485903e8
5d31119f-41b9-11df-907f-00215aee3ebe;Городнянський;Городнянский;d15e3031-60b0-11de-be1e-0030485903e8
6b07f86f-41b9-11df-907f-00215aee3ebe;Городоцький;Городоцкий;d15e302e-60b0-11de-be1e-0030485903e8
f9333448-db4e-11df-9197-00215aee3ebe;Городоцький;Городокский;d15e3024-60b0-11de-be1e-0030485903e8
710c27d0-41b9-11df-907f-00215aee3ebe;Горохівський;Гороховский;d15e301a-60b0-11de-be1e-0030485903e8
5d311236-41b9-11df-907f-00215aee3ebe;Гощанський;Гощанский;d15e3028-60b0-11de-be1e-0030485903e8
710c27e9-41b9-11df-907f-00215aee3ebe;Гребінківський;Гребенковский;d15e3027-60b0-11de-be1e-0030485903e8
903ccaac-41b9-11df-907f-00215aee3ebe;Гуляйпільський;Гуляйпольский;d15e301f-60b0-11de-be1e-0030485903e8
903ccab8-41b9-11df-907f-00215aee3ebe;Гусятинський;Гусятинский;d15e302b-60b0-11de-be1e-0030485903e8
903ccad6-41b9-11df-907f-00215aee3ebe;Дворічанський;Двуречанский;d15e302c-60b0-11de-be1e-0030485903e8
bad3ac53-41b9-11df-907f-00215aee3ebe;Дебальцеве;Дебальцево;d15e301c-60b0-11de-be1e-0030485903e8
78f453a1-41b9-11df-907f-00215aee3ebe;Демидівський;Демидовский;d15e3028-60b0-11de-be1e-0030485903e8
8067accc-41b9-11df-907f-00215aee3ebe;Деражнянський;Деражнянский;d15e302e-60b0-11de-be1e-0030485903e8
710c275b-41b9-11df-907f-00215aee3ebe;Дергачівський;Дергачевский;d15e302c-60b0-11de-be1e-0030485903e8
64024b26-41b9-11df-907f-00215aee3ebe;Дзержинськ;Дзержинск;d15e301c-60b0-11de-be1e-0030485903e8
5d311220-41b9-11df-907f-00215aee3ebe;Диканський;Диканьский;d15e3027-60b0-11de-be1e-0030485903e8
d00d3b5d-41b9-11df-907f-00215aee3ebe;Дніпро;Днепро;d15e301b-60b0-11de-be1e-0030485903e8
6b07f886-41b9-11df-907f-00215aee3ebe;Дніпровський;Днепрвский;d15e301b-60b0-11de-be1e-0030485903e8
70234316-14c9-11ee-80bd-000c2961d091;Дністровський;Днестровский;d15e3030-60b0-11de-be1e-0030485903e8
5d31121b-41b9-11df-907f-00215aee3ebe;Добровеличківський;Добровеличковский;d15e3022-60b0-11de-be1e-0030485903e8
78f453f8-41b9-11df-907f-00215aee3ebe;Добропільський;Добропольский;d15e301c-60b0-11de-be1e-0030485903e8
64024b68-41b9-11df-907f-00215aee3ebe;Довжанський (Свердловський);Довжанский;d15e3023-60b0-11de-be1e-0030485903e8
710c2777-41b9-11df-907f-00215aee3ebe;Долинський;Долинский;d15e3020-60b0-11de-be1e-0030485903e8
f9333449-db4e-11df-9197-00215aee3ebe;Долинський;Долинский;d15e3022-60b0-11de-be1e-0030485903e8
5630292b-41b9-11df-907f-00215aee3ebe;Доманівський;Доманевский;d15e3025-60b0-11de-be1e-0030485903e8
78f45412-41b9-11df-907f-00215aee3ebe;Донецьк;Донецк;d15e301c-60b0-11de-be1e-0030485903e8
a6eec0a0-14f0-11ee-80bd-000c2961d091;Донецький;;d15e301c-60b0-11de-be1e-0030485903e8
710c2753-41b9-11df-907f-00215aee3ebe;Драбівський;Драбовский;d15e302f-60b0-11de-be1e-0030485903e8
78f453c7-41b9-11df-907f-00215aee3ebe;Дрогобицький;Дрогобычский;d15e3024-60b0-11de-be1e-0030485903e8
d00d3b68-41b9-11df-907f-00215aee3ebe;Дружківка;Дружковка;d15e301c-60b0-11de-be1e-0030485903e8
710c27c9-41b9-11df-907f-00215aee3ebe;Дубенський;Дубенский;d15e3028-60b0-11de-be1e-0030485903e8
710c27d3-41b9-11df-907f-00215aee3ebe;Дубровицький;Дубровицкий;d15e3028-60b0-11de-be1e-0030485903e8
6b07f884-41b9-11df-907f-00215aee3ebe;Дунаєвецький;Дунаевецкий;d15e302e-60b0-11de-be1e-0030485903e8
4ffc4c15-41b9-11df-907f-00215aee3ebe;Енергодар;Энергодар;d15e301f-60b0-11de-be1e-0030485903e8
8a199cd2-41b9-11df-907f-00215aee3ebe;Єланецький;Еланецкий;d15e3025-60b0-11de-be1e-0030485903e8
5d31120f-41b9-11df-907f-00215aee3ebe;Ємільчинський;Емильчинский;d15e301d-60b0-11de-be1e-0030485903e8
710c273e-41b9-11df-907f-00215aee3ebe;Жашківський;Жашковский;d15e302f-60b0-11de-be1e-0030485903e8
5d311272-41b9-11df-907f-00215aee3ebe;Жидачівський;Жидачевский;d15e3024-60b0-11de-be1e-0030485903e8
d00d3b86-41b9-11df-907f-00215aee3ebe;Житомир;Житомир;d15e301d-60b0-11de-be1e-0030485903e8
6b07f8c7-41b9-11df-907f-00215aee3ebe;Житомирський;Житомирский;d15e301d-60b0-11de-be1e-0030485903e8
78f453f7-41b9-11df-907f-00215aee3ebe;Жмеринський;Жмеринский;d15e3019-60b0-11de-be1e-0030485903e8
64024b1e-41b9-11df-907f-00215aee3ebe;Жовківський;Жолковский;d15e3024-60b0-11de-be1e-0030485903e8
d00d3b87-41b9-11df-907f-00215aee3ebe;Жовті Води;Желтые Воды;d15e301b-60b0-11de-be1e-0030485903e8
710c274e-41b9-11df-907f-00215aee3ebe;Заліщицький;Залещицкий;d15e302b-60b0-11de-be1e-0030485903e8
0c6de87a-41ba-11df-907f-00215aee3ebe;Запоріжжя;Запорожье;d15e301f-60b0-11de-be1e-0030485903e8
4ffc4b83-41b9-11df-907f-00215aee3ebe;Запорізький;Запорожский;d15e301f-60b0-11de-be1e-0030485903e8
8067ad6b-41b9-11df-907f-00215aee3ebe;Зарічненський;Заречненский;d15e3028-60b0-11de-be1e-0030485903e8
64024bcb-41b9-11df-907f-00215aee3ebe;Заставнівський;Заставновский;d15e3030-60b0-11de-be1e-0030485903e8
78f45411-41b9-11df-907f-00215aee3ebe;Захарівський (Фрунзівський);Zakharivskyi;d15e3026-60b0-11de-be1e-0030485903e8
4ffc4b24-41b9-11df-907f-00215aee3ebe;Зачепилівський;Зачепиловский;d15e302c-60b0-11de-be1e-0030485903e8
6b07f82a-41b9-11df-907f-00215aee3ebe;Збаразький;Збаражский;d15e302b-60b0-11de-be1e-0030485903e8
78f453a6-41b9-11df-907f-00215aee3ebe;Зборівський;Зборовский;d15e302b-60b0-11de-be1e-0030485903e8
6b07f805-41b9-11df-907f-00215aee3ebe;Звенигородський;Звенигородский;d15e302f-60b0-11de-be1e-0030485903e8
64024b08-41b9-11df-907f-00215aee3ebe;Згурівський;Згуровский;d15e3021-60b0-11de-be1e-0030485903e8
78f453ec-41b9-11df-907f-00215aee3ebe;Здолбунівський;Здолбуновский;d15e3028-60b0-11de-be1e-0030485903e8
64024b20-41b9-11df-907f-00215aee3ebe;Зіньківський;Зеньковский;d15e3027-60b0-11de-be1e-0030485903e8
78f4540f-41b9-11df-907f-00215aee3ebe;Зміївський;Змиевский;d15e302c-60b0-11de-be1e-0030485903e8
8067acc8-41b9-11df-907f-00215aee3ebe;Знам'янський;Знаменский;d15e3022-60b0-11de-be1e-0030485903e8
5d311268-41b9-11df-907f-00215aee3ebe;Золотоніський;Золотоношский;d15e302f-60b0-11de-be1e-0030485903e8
6b07f8b5-41b9-11df-907f-00215aee3ebe;Золочівський;Золочевский;d15e302c-60b0-11de-be1e-0030485903e8
f9333447-db4e-11df-9197-00215aee3ebe;Золочівський;Золочевский;d15e3024-60b0-11de-be1e-0030485903e8
78f453f9-41b9-11df-907f-00215aee3ebe;Іваничівський;Иваничевский;d15e301a-60b0-11de-be1e-0030485903e8
f933343f-db4e-11df-9197-00215aee3ebe;Іванівський;Ивановский;d15e302d-60b0-11de-be1e-0030485903e8
4ffc4bd5-41b9-11df-907f-00215aee3ebe;Іванівський;Ивановский;d15e3026-60b0-11de-be1e-0030485903e8
78f45428-41b9-11df-907f-00215aee3ebe;Іванківський;Иванковский;d15e3021-60b0-11de-be1e-0030485903e8
a1e9f9b8-41b9-11df-907f-00215aee3ebe;Івано-Франківськ;Ивано-Франковск;d15e3020-60b0-11de-be1e-0030485903e8
acb6de84-14c9-11ee-80bd-000c2961d091;Івано-Франківський;Ивано-Франковский;d15e3020-60b0-11de-be1e-0030485903e8
6b07f800-41b9-11df-907f-00215aee3ebe;Ізмаїльський;Измаильский;d15e3026-60b0-11de-be1e-0030485903e8
8a199ccc-41b9-11df-907f-00215aee3ebe;Ізюмський;Изюмский;d15e302c-60b0-11de-be1e-0030485903e8
78f453ef-41b9-11df-907f-00215aee3ebe;Ізяславський;Изяславский;d15e302e-60b0-11de-be1e-0030485903e8
64024bca-41b9-11df-907f-00215aee3ebe;Іллінецький;Ильинецкий;d15e3019-60b0-11de-be1e-0030485903e8
903cca8d-41b9-11df-907f-00215aee3ebe;Ірпінь;Ирпень;d15e3021-60b0-11de-be1e-0030485903e8
64024aed-41b9-11df-907f-00215aee3ebe;Іршавський;Иршавский;d15e301e-60b0-11de-be1e-0030485903e8
5d311228-41b9-11df-907f-00215aee3ebe;Ічнянський;Ичнянский;d15e3031-60b0-11de-be1e-0030485903e8
6b07f882-41b9-11df-907f-00215aee3ebe;Кагарлицький;Кагарлицкий;d15e3021-60b0-11de-be1e-0030485903e8
56302a75-41b9-11df-907f-00215aee3ebe;Кадіївка (Стаханов);Кадиевка;d15e3023-60b0-11de-be1e-0030485903e8
903ccadc-41b9-11df-907f-00215aee3ebe;Казанківський;Казанковский;d15e3025-60b0-11de-be1e-0030485903e8
64024bc9-41b9-11df-907f-00215aee3ebe;Каланчацький;Каланчакский;d15e302d-60b0-11de-be1e-0030485903e8
6b07f841-41b9-11df-907f-00215aee3ebe;Калинівський;Калиновский;d15e3019-60b0-11de-be1e-0030485903e8
710c27d6-41b9-11df-907f-00215aee3ebe;Калуський;Калушский;d15e3020-60b0-11de-be1e-0030485903e8
bfcf175d-14f0-11ee-80bd-000c2961d091;Кальміуський;Кальмиусский;d15e301c-60b0-11de-be1e-0030485903e8
4ffc4b69-41b9-11df-907f-00215aee3ebe;Кам'янець-Подільський;Каменец-Подольский;d15e302e-60b0-11de-be1e-0030485903e8
6b07f8a6-41b9-11df-907f-00215aee3ebe;Кам'янка-Бузький;Каменка-Бугский;d15e3024-60b0-11de-be1e-0030485903e8
d00d3b5c-41b9-11df-907f-00215aee3ebe;Кам'янське;Каменское (Днепродзержинск);d15e301b-60b0-11de-be1e-0030485903e8
6b07f871-41b9-11df-907f-00215aee3ebe;Кам'янський;Каменский;d15e302f-60b0-11de-be1e-0030485903e8
78f45418-41b9-11df-907f-00215aee3ebe;Кам'янсько-Дніпровський;Каменско-Днепровский;d15e301f-60b0-11de-be1e-0030485903e8
8067ad73-41b9-11df-907f-00215aee3ebe;Камінь-Каширський;Камень-Каширский;d15e301a-60b0-11de-be1e-0030485903e8
78f453aa-41b9-11df-907f-00215aee3ebe;Канівський;Каневский;d15e302f-60b0-11de-be1e-0030485903e8
78f45409-41b9-11df-907f-00215aee3ebe;Карлівський;Карловский;d15e3027-60b0-11de-be1e-0030485903e8
8a199cd8-41b9-11df-907f-00215aee3ebe;Катеринопільський;Катеринопольский;d15e302f-60b0-11de-be1e-0030485903e8
64024b40-41b9-11df-907f-00215aee3ebe;Каховський;Каховский;d15e302d-60b0-11de-be1e-0030485903e8
5d311226-41b9-11df-907f-00215aee3ebe;Кегичівський;Кегичевский;d15e302c-60b0-11de-be1e-0030485903e8
64024bcc-41b9-11df-907f-00215aee3ebe;Кельменецький;Кельменецкий;d15e3030-60b0-11de-be1e-0030485903e8
78f453fb-41b9-11df-907f-00215aee3ebe;Києво-Святошинський;Киево-Святошинский;d15e3021-60b0-11de-be1e-0030485903e8
e672324c-4237-11e3-9df6-003048d2b473;Київ;Киев;d15e3021-60b0-11de-be1e-0030485903e8
710c273a-41b9-11df-907f-00215aee3ebe;Ківерцівський;Киверцовский;d15e301a-60b0-11de-be1e-0030485903e8
903ccab3-41b9-11df-907f-00215aee3ebe;Кілійський;Килийский;d15e3026-60b0-11de-be1e-0030485903e8
710c27cd-41b9-11df-907f-00215aee3ebe;Кіцманський;Кицманский;d15e3030-60b0-11de-be1e-0030485903e8
78f453f6-41b9-11df-907f-00215aee3ebe;Кобеляцький;Кобелякский;d15e3027-60b0-11de-be1e-0030485903e8
64024b18-41b9-11df-907f-00215aee3ebe;Ковельський;Ковельский;d15e301a-60b0-11de-be1e-0030485903e8
710c273d-41b9-11df-907f-00215aee3ebe;Кодимський;Кодымский;d15e3026-60b0-11de-be1e-0030485903e8
78f453ad-41b9-11df-907f-00215aee3ebe;Козелецький;Козелецкий;d15e3031-60b0-11de-be1e-0030485903e8
8a199cd5-41b9-11df-907f-00215aee3ebe;Козельщинський;Козельщинский;d15e3027-60b0-11de-be1e-0030485903e8
4ffc4b85-41b9-11df-907f-00215aee3ebe;Козівський;Козовский;d15e302b-60b0-11de-be1e-0030485903e8
710c2755-41b9-11df-907f-00215aee3ebe;Козятинський;Казатинский;d15e3019-60b0-11de-be1e-0030485903e8
5d311235-41b9-11df-907f-00215aee3ebe;Коломацький;Коломакский;d15e302c-60b0-11de-be1e-0030485903e8
64024bc2-41b9-11df-907f-00215aee3ebe;Коломийський;Коломыйский;d15e3020-60b0-11de-be1e-0030485903e8
a1e9f9a9-41b9-11df-907f-00215aee3ebe;Компаніївський;Компанеевский;d15e3022-60b0-11de-be1e-0030485903e8
710c27d4-41b9-11df-907f-00215aee3ebe;Конотопський;Конотопский;d15e302a-60b0-11de-be1e-0030485903e8
8067acc9-41b9-11df-907f-00215aee3ebe;Корецький;Корецкий;d15e3028-60b0-11de-be1e-0030485903e8
64024b87-41b9-11df-907f-00215aee3ebe;Коропський;Коропский;d15e3031-60b0-11de-be1e-0030485903e8
710c27f0-41b9-11df-907f-00215aee3ebe;Коростенський;Коростенский;d15e301d-60b0-11de-be1e-0030485903e8
78f4540a-41b9-11df-907f-00215aee3ebe;Коростишівський;Коростышевский;d15e301d-60b0-11de-be1e-0030485903e8
8a199cd7-41b9-11df-907f-00215aee3ebe;Корсунь-Шевченківський;Корсунь-Шевченковский;d15e302f-60b0-11de-be1e-0030485903e8
78f45408-41b9-11df-907f-00215aee3ebe;Корюківський;Корюковский;d15e3031-60b0-11de-be1e-0030485903e8
56302932-41b9-11df-907f-00215aee3ebe;Косівський;Косовский;d15e3020-60b0-11de-be1e-0030485903e8
6b07f824-41b9-11df-907f-00215aee3ebe;Костопільський;Костопольский;d15e3028-60b0-11de-be1e-0030485903e8
64024b21-41b9-11df-907f-00215aee3ebe;Костянтинівський;Константиновский;d15e301c-60b0-11de-be1e-0030485903e8
78f4540b-41b9-11df-907f-00215aee3ebe;Котелевський;Котелевский;d15e3027-60b0-11de-be1e-0030485903e8
78f453f1-41b9-11df-907f-00215aee3ebe;Краматорськ;Краматорск;d15e301c-60b0-11de-be1e-0030485903e8
cc049175-14c9-11ee-80bd-000c2961d091;Краматорський;Краматорский;d15e301c-60b0-11de-be1e-0030485903e8
5d31127e-41b9-11df-907f-00215aee3ebe;Красилівський;Красиловский;d15e302e-60b0-11de-be1e-0030485903e8
903ccac0-41b9-11df-907f-00215aee3ebe;Красний Луч (Хрустальний);Хрустальный;d15e3023-60b0-11de-be1e-0030485903e8
6b07f88a-41b9-11df-907f-00215aee3ebe;Красноградський;Красноградский;d15e302c-60b0-11de-be1e-0030485903e8
903cca9b-41b9-11df-907f-00215aee3ebe;Краснокутський;Краснокутский;d15e302c-60b0-11de-be1e-0030485903e8
8a199cce-41b9-11df-907f-00215aee3ebe;Краснопільський;Краснопольский;d15e302a-60b0-11de-be1e-0030485903e8
710c273f-41b9-11df-907f-00215aee3ebe;Кременецький;Кременецкий;d15e302b-60b0-11de-be1e-0030485903e8
5394b316-41be-11df-907f-00215aee3ebe;Кременчук;Кременчуг;d15e3027-60b0-11de-be1e-0030485903e8
78f453f4-41b9-11df-907f-00215aee3ebe;Кременчуцький;Кременчугский;d15e3027-60b0-11de-be1e-0030485903e8
6b07f8b2-41b9-11df-907f-00215aee3ebe;Кремінський;Кременской;d15e3023-60b0-11de-be1e-0030485903e8
a1e9f9b1-41b9-11df-907f-00215aee3ebe;Крестовка (Кіровське);Крестовка;d15e301c-60b0-11de-be1e-0030485903e8
4ffc4b7d-41b9-11df-907f-00215aee3ebe;Кривий Ріг;Кривой Рог;d15e301b-60b0-11de-be1e-0030485903e8
6b07f804-41b9-11df-907f-00215aee3ebe;Кривоозерський;Кривоозерский;d15e3025-60b0-11de-be1e-0030485903e8
903ccaa5-41b9-11df-907f-00215aee3ebe;Криворізький;Криворожский;d15e301b-60b0-11de-be1e-0030485903e8
5d31122b-41b9-11df-907f-00215aee3ebe;Крижопільський;Крыжопольский;d15e3019-60b0-11de-be1e-0030485903e8
4ffc4bd2-41b9-11df-907f-00215aee3ebe;Криничанський;Криничанский;d15e301b-60b0-11de-be1e-0030485903e8
56302a8d-41b9-11df-907f-00215aee3ebe;Кролевецький;Кролевецкий;d15e302a-60b0-11de-be1e-0030485903e8
0c6de8b4-41ba-11df-907f-00215aee3ebe;Кропивницький (Кіровоград);Кропивницький;d15e3022-60b0-11de-be1e-0030485903e8
4ffc4be0-41b9-11df-907f-00215aee3ebe;Кропивницький (Кіровоградський);Кропивницький;d15e3022-60b0-11de-be1e-0030485903e8
4ffc4b8d-41b9-11df-907f-00215aee3ebe;Куликівський;Куликовский;d15e3031-60b0-11de-be1e-0030485903e8
a1e9f9af-41b9-11df-907f-00215aee3ebe;Куп'янський;Купянский;d15e302c-60b0-11de-be1e-0030485903e8
231fda9d-db59-11e8-80d7-1c98ec135261;Ладижин;Ладижин;d15e3019-60b0-11de-be1e-0030485903e8
710c27d1-41b9-11df-907f-00215aee3ebe;Лановецький;Лановецкий;d15e302b-60b0-11de-be1e-0030485903e8
78f453e0-41b9-11df-907f-00215aee3ebe;Лебединський;Лебединский;d15e302a-60b0-11de-be1e-0030485903e8
78f453f2-41b9-11df-907f-00215aee3ebe;Летичівський;Летичевский;d15e302e-60b0-11de-be1e-0030485903e8
78f4540e-41b9-11df-907f-00215aee3ebe;Лиманський;Лиманский;d15e3026-60b0-11de-be1e-0030485903e8
d00d3b66-41b9-11df-907f-00215aee3ebe;Лиманський;Лиманский;d15e301c-60b0-11de-be1e-0030485903e8
78f453e6-41b9-11df-907f-00215aee3ebe;Липовецький;Липовецкий;d15e3019-60b0-11de-be1e-0030485903e8
6b07f84c-41b9-11df-907f-00215aee3ebe;Липоводолинський;Липоводолинский;d15e302a-60b0-11de-be1e-0030485903e8
5394b32c-41be-11df-907f-00215aee3ebe;Лисичанськ;Лисичанск;d15e3023-60b0-11de-be1e-0030485903e8
8a199cc4-41b9-11df-907f-00215aee3ebe;Лисянський;Лисянский;d15e302f-60b0-11de-be1e-0030485903e8
6b07f811-41b9-11df-907f-00215aee3ebe;Літинський;Литинский;d15e3019-60b0-11de-be1e-0030485903e8
64024b28-41b9-11df-907f-00215aee3ebe;Лозівський;Лозовский;d15e302c-60b0-11de-be1e-0030485903e8
78f45401-41b9-11df-907f-00215aee3ebe;Локачинський;Локачинский;d15e301a-60b0-11de-be1e-0030485903e8
710c275c-41b9-11df-907f-00215aee3ebe;Лохвицький;Лохвицкий;d15e3027-60b0-11de-be1e-0030485903e8
710c27ea-41b9-11df-907f-00215aee3ebe;Лубенський;Лубенский;d15e3027-60b0-11de-be1e-0030485903e8
d00d3b53-41b9-11df-907f-00215aee3ebe;Луганськ;Луганск;d15e3023-60b0-11de-be1e-0030485903e8
02564337-14f1-11ee-80bd-000c2961d091;Луганський;;d15e3023-60b0-11de-be1e-0030485903e8
8067acbf-41b9-11df-907f-00215aee3ebe;Лугинський;Лугинский;d15e301d-60b0-11de-be1e-0030485903e8
78f453ee-41b9-11df-907f-00215aee3ebe;Лутугинський;Лутугинский;d15e3023-60b0-11de-be1e-0030485903e8
5394b32d-41be-11df-907f-00215aee3ebe;Луцьк;Луцк;d15e301a-60b0-11de-be1e-0030485903e8
5d31126e-41b9-11df-907f-00215aee3ebe;Луцький;Луцкий;d15e301a-60b0-11de-be1e-0030485903e8
4ffc4b9d-41b9-11df-907f-00215aee3ebe;Любарський;Любарский;d15e301d-60b0-11de-be1e-0030485903e8
4ffc4bae-41b9-11df-907f-00215aee3ebe;Любашівський;Любашевский;d15e3026-60b0-11de-be1e-0030485903e8
710c27e2-41b9-11df-907f-00215aee3ebe;Любешівський;Любешовский;d15e301a-60b0-11de-be1e-0030485903e8
710c27d9-41b9-11df-907f-00215aee3ebe;Любомльський;Любомльский;d15e301a-60b0-11de-be1e-0030485903e8
8a199cde-41b9-11df-907f-00215aee3ebe;Львів;Львов;d15e3024-60b0-11de-be1e-0030485903e8
992ee172-14c9-11ee-80bd-000c2961d091;Львівський;Львовский;d15e3024-60b0-11de-be1e-0030485903e8
8a199ced-41b9-11df-907f-00215aee3ebe;Магдалинівський;Магдалиновский;d15e301b-60b0-11de-be1e-0030485903e8
8067ad64-41b9-11df-907f-00215aee3ebe;Макарівський;Макаровский;d15e3021-60b0-11de-be1e-0030485903e8
56302a77-41b9-11df-907f-00215aee3ebe;Макіївка;Макеевка;d15e301c-60b0-11de-be1e-0030485903e8
6b07f8cf-41b9-11df-907f-00215aee3ebe;Малинський;Малинский;d15e301d-60b0-11de-be1e-0030485903e8
710c27e6-41b9-11df-907f-00215aee3ebe;Маловисківський;Малови?сковский;d15e3022-60b0-11de-be1e-0030485903e8
4ffc4c14-41b9-11df-907f-00215aee3ebe;Мангушський (Першотравневий);Мангушский (Первомайский);d15e301c-60b0-11de-be1e-0030485903e8
710c27d5-41b9-11df-907f-00215aee3ebe;Маневицький;Маневичский;d15e301a-60b0-11de-be1e-0030485903e8
6b07f808-41b9-11df-907f-00215aee3ebe;Маньківський;Маньковский;d15e302f-60b0-11de-be1e-0030485903e8
5d31126f-41b9-11df-907f-00215aee3ebe;Мар'їнський;Марьинский;d15e301c-60b0-11de-be1e-0030485903e8
5394b330-41be-11df-907f-00215aee3ebe;Марганець;Марганец;d15e301b-60b0-11de-be1e-0030485903e8
bad3ac2e-41b9-11df-907f-00215aee3ebe;Маріуполь;Мариуполь;d15e301c-60b0-11de-be1e-0030485903e8
cbaa7f34-14f0-11ee-80bd-000c2961d091;Маріупольський;Мариупольский;d15e301c-60b0-11de-be1e-0030485903e8
8067ad54-41b9-11df-907f-00215aee3ebe;Марківський;Марковский;d15e3023-60b0-11de-be1e-0030485903e8
4ffc4b59-41b9-11df-907f-00215aee3ebe;Машівський;Машевский;d15e3027-60b0-11de-be1e-0030485903e8
5d31122e-41b9-11df-907f-00215aee3ebe;Межівський;Межевский;d15e301b-60b0-11de-be1e-0030485903e8
64024b6c-41b9-11df-907f-00215aee3ebe;Мелітопольський;Мелитопольский;d15e301f-60b0-11de-be1e-0030485903e8
710c27ef-41b9-11df-907f-00215aee3ebe;Менський;Менский;d15e3031-60b0-11de-be1e-0030485903e8
5394b334-41be-11df-907f-00215aee3ebe;Миколаїв;Николаев;d15e3025-60b0-11de-be1e-0030485903e8
f9333445-db4e-11df-9197-00215aee3ebe;Миколаївський;Николаевский;d15e3025-60b0-11de-be1e-0030485903e8
f9333446-db4e-11df-9197-00215aee3ebe;Миколаївський;Николаевский;d15e3024-60b0-11de-be1e-0030485903e8
5d3111a9-41b9-11df-907f-00215aee3ebe;Миколаївський;Николаевский;d15e3026-60b0-11de-be1e-0030485903e8
6b07f85d-41b9-11df-907f-00215aee3ebe;Миргородський;Миргородский;d15e3027-60b0-11de-be1e-0030485903e8
bad3ac43-41b9-11df-907f-00215aee3ebe;Миронівський;Мироновский;d15e3021-60b0-11de-be1e-0030485903e8
6b07f8cc-41b9-11df-907f-00215aee3ebe;Михайлівський;Михайловский;d15e301f-60b0-11de-be1e-0030485903e8
903cca64-41b9-11df-907f-00215aee3ebe;Міжгірський;Межгорский;d15e301e-60b0-11de-be1e-0030485903e8
4ffc4c24-41b9-11df-907f-00215aee3ebe;Міловський;Меловский;d15e3023-60b0-11de-be1e-0030485903e8
64024b50-41b9-11df-907f-00215aee3ebe;Млинівський;Млиновский;d15e3028-60b0-11de-be1e-0030485903e8
6b07f89c-41b9-11df-907f-00215aee3ebe;Могилів-Подільський;Могилев-Подольский;d15e3019-60b0-11de-be1e-0030485903e8
63a4b4fb-3f54-11e0-94a2-00215aee3ebe;Могилів-Подільський (місто);Могилёв-Подольський;d15e3019-60b0-11de-be1e-0030485903e8
903ccaca-41b9-11df-907f-00215aee3ebe;Монастириський;Монастыриский;d15e302b-60b0-11de-be1e-0030485903e8
4ffc4b9c-41b9-11df-907f-00215aee3ebe;Монастирищенський;Монастырищенский;d15e302f-60b0-11de-be1e-0030485903e8
64024b0e-41b9-11df-907f-00215aee3ebe;Мостиський;Мостиский;d15e3024-60b0-11de-be1e-0030485903e8
6b07f7eb-41b9-11df-907f-00215aee3ebe;Мукачівський;Мукачевский;d15e301e-60b0-11de-be1e-0030485903e8
710c272d-41b9-11df-907f-00215aee3ebe;Мурованокуриловецький;Мурованокуриловецкий;d15e3019-60b0-11de-be1e-0030485903e8
78f453dd-41b9-11df-907f-00215aee3ebe;Надвірнянський;Надворнянский;d15e3020-60b0-11de-be1e-0030485903e8
6b07f825-41b9-11df-907f-00215aee3ebe;Народицький;Народичский;d15e301d-60b0-11de-be1e-0030485903e8
78f453b0-41b9-11df-907f-00215aee3ebe;Недригайлівський;Недригайловский;d15e302a-60b0-11de-be1e-0030485903e8
6b07f84e-41b9-11df-907f-00215aee3ebe;Немирівський;Немировский;d15e3019-60b0-11de-be1e-0030485903e8
5394b33e-41be-11df-907f-00215aee3ebe;Нетішин;Нетешин;d15e302e-60b0-11de-be1e-0030485903e8
5d3111d7-41b9-11df-907f-00215aee3ebe;Нижньосірогозький;Нижнесерогозский;d15e302d-60b0-11de-be1e-0030485903e8
710c275d-41b9-11df-907f-00215aee3ebe;Ніжинський;Нежинский;d15e3031-60b0-11de-be1e-0030485903e8
a5388eb2-db65-11df-9197-00215aee3ebe;Нікольський (Володарський);Никольский;d15e301c-60b0-11de-be1e-0030485903e8
8067ad5c-41b9-11df-907f-00215aee3ebe;Нікопольський;Никопольский;d15e301b-60b0-11de-be1e-0030485903e8
d00d3b5e-41b9-11df-907f-00215aee3ebe;Нова Каховка;новая Каховка;d15e302d-60b0-11de-be1e-0030485903e8
78f453fd-41b9-11df-907f-00215aee3ebe;Новгородківський;Новгородковский;d15e3022-60b0-11de-be1e-0030485903e8
710c27fa-41b9-11df-907f-00215aee3ebe;Новгород-Сіверський;Новгород-Северский;d15e3031-60b0-11de-be1e-0030485903e8
710c2756-41b9-11df-907f-00215aee3ebe;Новоазовський;Новоазовский;d15e301c-60b0-11de-be1e-0030485903e8
710c2728-41b9-11df-907f-00215aee3ebe;Новоайдарський;Новоайдарский;d15e3023-60b0-11de-be1e-0030485903e8
a1e9f9b2-41b9-11df-907f-00215aee3ebe;Новоархангельський;Новоархангельский;d15e3022-60b0-11de-be1e-0030485903e8
6b07f8c5-41b9-11df-907f-00215aee3ebe;Новобузький;Новобугский;d15e3025-60b0-11de-be1e-0030485903e8
78f45410-41b9-11df-907f-00215aee3ebe;Нововодолазький;Нововодолажский;d15e302c-60b0-11de-be1e-0030485903e8
5394b340-41be-11df-907f-00215aee3ebe;Нововолинськ;Нововолынск;d15e301a-60b0-11de-be1e-0030485903e8
78f4540d-41b9-11df-907f-00215aee3ebe;Нововоронцовський;Нововоронцовский;d15e302d-60b0-11de-be1e-0030485903e8
6b07f8cb-41b9-11df-907f-00215aee3ebe;Новоград-Волинський;Новоград-Волынский;d15e301d-60b0-11de-be1e-0030485903e8
6b07f8ce-41b9-11df-907f-00215aee3ebe;Новомиколаївський;Новониколаевский;d15e301f-60b0-11de-be1e-0030485903e8
5d31121c-41b9-11df-907f-00215aee3ebe;Новомиргородський;Новомиргородский;d15e3022-60b0-11de-be1e-0030485903e8
5d311210-41b9-11df-907f-00215aee3ebe;Новомосковський;Новомосковский;d15e301b-60b0-11de-be1e-0030485903e8
5d311273-41b9-11df-907f-00215aee3ebe;Новоодеський;Новоодесский;d15e3025-60b0-11de-be1e-0030485903e8
710c27e8-41b9-11df-907f-00215aee3ebe;Новопсковський;Новопсковский;d15e3023-60b0-11de-be1e-0030485903e8
5d311221-41b9-11df-907f-00215aee3ebe;Новосанжарський;Новосанжарский;d15e3027-60b0-11de-be1e-0030485903e8
6b07f88b-41b9-11df-907f-00215aee3ebe;Новоселицький;Новоселицкий;d15e3030-60b0-11de-be1e-0030485903e8
903ccab4-41b9-11df-907f-00215aee3ebe;Новотроїцький;Новотроицкий;d15e302d-60b0-11de-be1e-0030485903e8
903ccaad-41b9-11df-907f-00215aee3ebe;Новоукраїнський;Новоукраинский;d15e3022-60b0-11de-be1e-0030485903e8
5d311279-41b9-11df-907f-00215aee3ebe;Новоушицький;Новоушицкий;d15e302e-60b0-11de-be1e-0030485903e8
4ffc4bd7-41b9-11df-907f-00215aee3ebe;Носівський;Носовский;d15e3031-60b0-11de-be1e-0030485903e8
9b486f5b-41b9-11df-907f-00215aee3ebe;Обухівський;Обуховский;d15e3021-60b0-11de-be1e-0030485903e8
4ffc4b7f-41b9-11df-907f-00215aee3ebe;Овідіопольський;Овидиопольский;d15e3026-60b0-11de-be1e-0030485903e8
78f453e3-41b9-11df-907f-00215aee3ebe;Овруцький;Овручский;d15e301d-60b0-11de-be1e-0030485903e8
5394b336-41be-11df-907f-00215aee3ebe;Одеса;Одесса;d15e3026-60b0-11de-be1e-0030485903e8
4658e3ed-14c9-11ee-80bd-000c2961d091;Одеський;Одесский;d15e3026-60b0-11de-be1e-0030485903e8
5d311274-41b9-11df-907f-00215aee3ebe;Окнянський (Красноокнянсь;Окнянский (Красноокнянски;d15e3026-60b0-11de-be1e-0030485903e8
78f453fe-41b9-11df-907f-00215aee3ebe;Олевський;Олевский;d15e301d-60b0-11de-be1e-0030485903e8
a5388eb5-db65-11df-9197-00215aee3ebe;Олександрівський;Александровский;d15e301c-60b0-11de-be1e-0030485903e8
6b07f89e-41b9-11df-907f-00215aee3ebe;Олександрівський;Александровский;d15e3022-60b0-11de-be1e-0030485903e8
5d31121d-41b9-11df-907f-00215aee3ebe;Олександрійський;Александрийский;d15e3022-60b0-11de-be1e-0030485903e8
4ffc4b68-41b9-11df-907f-00215aee3ebe;Олешківський;Олешковский;d15e302d-60b0-11de-be1e-0030485903e8
903ccab2-41b9-11df-907f-00215aee3ebe;Онуфріївський;Онуфриевский;d15e3022-60b0-11de-be1e-0030485903e8
6b07f867-41b9-11df-907f-00215aee3ebe;Оратівський;Оратовский;d15e3019-60b0-11de-be1e-0030485903e8
903ccadf-41b9-11df-907f-00215aee3ebe;Оржицький;Оржицкий;d15e3027-60b0-11de-be1e-0030485903e8
78f453fa-41b9-11df-907f-00215aee3ebe;Оріхівський;Ореховский;d15e301f-60b0-11de-be1e-0030485903e8
710c2723-41b9-11df-907f-00215aee3ebe;Острозький;Острожский;d15e3028-60b0-11de-be1e-0030485903e8
6b07f855-41b9-11df-907f-00215aee3ebe;Охтирський;Ахтырский;d15e302a-60b0-11de-be1e-0030485903e8
78f4541a-41b9-11df-907f-00215aee3ebe;Очаківський;Очаковский;d15e3025-60b0-11de-be1e-0030485903e8
78f453f0-41b9-11df-907f-00215aee3ebe;П'ятихатський;Пятихатский;d15e301b-60b0-11de-be1e-0030485903e8
8067acc5-41b9-11df-907f-00215aee3ebe;Павлоградський;Павлоградский;d15e301b-60b0-11de-be1e-0030485903e8
f9333444-db4e-11df-9197-00215aee3ebe;Первомайський;Первомайский;d15e3025-60b0-11de-be1e-0030485903e8
f9333440-db4e-11df-9197-00215aee3ebe;Первомайський;Первомайский;d15e302c-60b0-11de-be1e-0030485903e8
bad3ac28-41b9-11df-907f-00215aee3ebe;Первомайський;Первомайский;d15e3023-60b0-11de-be1e-0030485903e8
4ffc4bef-41b9-11df-907f-00215aee3ebe;Перевальський;Перевальский;d15e3023-60b0-11de-be1e-0030485903e8
710c2734-41b9-11df-907f-00215aee3ebe;Перемишлянський;Перемышлянский;d15e3024-60b0-11de-be1e-0030485903e8
a1e9f9b3-41b9-11df-907f-00215aee3ebe;Перечинський;Перечинский;d15e301e-60b0-11de-be1e-0030485903e8
a1e9f9ab-41b9-11df-907f-00215aee3ebe;Переяслав-Хмельницький;Переяслав-Хмельницкий;d15e3021-60b0-11de-be1e-0030485903e8
5394b34d-41be-11df-907f-00215aee3ebe;Першотравенськ;Першотравенск;d15e301b-60b0-11de-be1e-0030485903e8
bad3ac3c-41b9-11df-907f-00215aee3ebe;Петриківський;Петриковский;d15e301b-60b0-11de-be1e-0030485903e8
6b07f876-41b9-11df-907f-00215aee3ebe;Петрівський;Петровский;d15e3022-60b0-11de-be1e-0030485903e8
8a199ccb-41b9-11df-907f-00215aee3ebe;Петропавлівський;Петропавловский;d15e301b-60b0-11de-be1e-0030485903e8
64024b24-41b9-11df-907f-00215aee3ebe;Печенізький;Печенежский;d15e302c-60b0-11de-be1e-0030485903e8
710c27fc-41b9-11df-907f-00215aee3ebe;Пирятинський;Пирятинский;d15e3027-60b0-11de-be1e-0030485903e8
8067accb-41b9-11df-907f-00215aee3ebe;Підволочиський;Подволочиский;d15e302b-60b0-11de-be1e-0030485903e8
78f453ff-41b9-11df-907f-00215aee3ebe;Підгаєцький;Подгаецкий;d15e302b-60b0-11de-be1e-0030485903e8
8067acf1-41b9-11df-907f-00215aee3ebe;Піщанський;Песчанский;d15e3019-60b0-11de-be1e-0030485903e8
4ffc4bd1-41b9-11df-907f-00215aee3ebe;Погребищенський;Погребищенский;d15e3019-60b0-11de-be1e-0030485903e8
8a199cc3-41b9-11df-907f-00215aee3ebe;Подільський (Котовський);Подольский (Котовский);d15e3026-60b0-11de-be1e-0030485903e8
5d311211-41b9-11df-907f-00215aee3ebe;Покровський;Покровський;d15e301b-60b0-11de-be1e-0030485903e8
bad3ac40-41b9-11df-907f-00215aee3ebe;Покровський (Красноармій);Покровский (Красноармей);d15e301c-60b0-11de-be1e-0030485903e8
bad3ac27-41b9-11df-907f-00215aee3ebe;Покровський (Орджонікідзе);Покровський (Орджонікідзе;d15e301b-60b0-11de-be1e-0030485903e8
a1e9f9bf-41b9-11df-907f-00215aee3ebe;Поліський;Полесский;d15e3021-60b0-11de-be1e-0030485903e8
6b07f88d-41b9-11df-907f-00215aee3ebe;Пологівський;Пологовский;d15e301f-60b0-11de-be1e-0030485903e8
78f453f3-41b9-11df-907f-00215aee3ebe;Полонський;Полонский;d15e302e-60b0-11de-be1e-0030485903e8
fbfb8c05-41be-11df-907f-00215aee3ebe;Полтава;Полтава;d15e3027-60b0-11de-be1e-0030485903e8
4ffc4b23-41b9-11df-907f-00215aee3ebe;Полтавський;Полтавский;d15e3027-60b0-11de-be1e-0030485903e8
710c27ec-41b9-11df-907f-00215aee3ebe;Попаснянський;Попаснянский;d15e3023-60b0-11de-be1e-0030485903e8
5d311239-41b9-11df-907f-00215aee3ebe;Попільнянський;Попельнянский;d15e301d-60b0-11de-be1e-0030485903e8
8067acc6-41b9-11df-907f-00215aee3ebe;Приазовський;Приазовский;d15e301f-60b0-11de-be1e-0030485903e8
78f453ca-41b9-11df-907f-00215aee3ebe;Прилуцький;Прилуцкий;d15e3031-60b0-11de-be1e-0030485903e8
6b07f8a0-41b9-11df-907f-00215aee3ebe;Приморський;Приморский;d15e301f-60b0-11de-be1e-0030485903e8
5d311217-41b9-11df-907f-00215aee3ebe;Пулинський (Червоноармійс;Красноармейский;d15e301d-60b0-11de-be1e-0030485903e8
710c2705-41b9-11df-907f-00215aee3ebe;Пустомитівський;Пустомытовский;d15e3024-60b0-11de-be1e-0030485903e8
78f4542c-41b9-11df-907f-00215aee3ebe;Путивльський;Путивльский;d15e302a-60b0-11de-be1e-0030485903e8
bad3ac1b-41b9-11df-907f-00215aee3ebe;Путильський;Путильский;d15e3030-60b0-11de-be1e-0030485903e8
6b07f7ec-41b9-11df-907f-00215aee3ebe;Радехівський;Радеховский;d15e3024-60b0-11de-be1e-0030485903e8
6b07f8b7-41b9-11df-907f-00215aee3ebe;Радивилівський;Радивиловский;d15e3028-60b0-11de-be1e-0030485903e8
78f453e7-41b9-11df-907f-00215aee3ebe;Радомишльський;Радомышльский;d15e301d-60b0-11de-be1e-0030485903e8
8a199cd9-41b9-11df-907f-00215aee3ebe;Ратнівський;Ратновский;d15e301a-60b0-11de-be1e-0030485903e8
78f453ea-41b9-11df-907f-00215aee3ebe;Рахівський;Раховский;d15e301e-60b0-11de-be1e-0030485903e8
d00d3b62-41b9-11df-907f-00215aee3ebe;Ренійський;Ренийский;d15e3026-60b0-11de-be1e-0030485903e8
8a199cd1-41b9-11df-907f-00215aee3ebe;Решетилівський;Решетиловский;d15e3027-60b0-11de-be1e-0030485903e8
fbfb8c0d-41be-11df-907f-00215aee3ebe;Рівне;Ровно;d15e3028-60b0-11de-be1e-0030485903e8
5d31128d-41b9-11df-907f-00215aee3ebe;Рівненський;Ровенский;d15e3028-60b0-11de-be1e-0030485903e8
903ccad0-41b9-11df-907f-00215aee3ebe;Ріпкинський;Репкинский;d15e3031-60b0-11de-be1e-0030485903e8
5d31121e-41b9-11df-907f-00215aee3ebe;Ровеньки;Ровеньки;d15e3023-60b0-11de-be1e-0030485903e8
0f18e268-14f1-11ee-80bd-000c2961d091;Ровеньківський;;d15e3023-60b0-11de-be1e-0030485903e8
6b07f7f2-41b9-11df-907f-00215aee3ebe;Рогатинський;Рогатинский;d15e3020-60b0-11de-be1e-0030485903e8
78f4539b-41b9-11df-907f-00215aee3ebe;Рожищенський;Рожищенский;d15e301a-60b0-11de-be1e-0030485903e8
78f453ac-41b9-11df-907f-00215aee3ebe;Рожнятівський;Рожнятовский;d15e3020-60b0-11de-be1e-0030485903e8
78f453bc-41b9-11df-907f-00215aee3ebe;Роздільнянський;Раздельнянский;d15e3026-60b0-11de-be1e-0030485903e8
4ffc4c10-41b9-11df-907f-00215aee3ebe;Розівський;Розовский;d15e301f-60b0-11de-be1e-0030485903e8
710c27ff-41b9-11df-907f-00215aee3ebe;Рокитнівський;Рокитновский;d15e3028-60b0-11de-be1e-0030485903e8
78f453d5-41b9-11df-907f-00215aee3ebe;Рокитнянський;Ракитнянский;d15e3021-60b0-11de-be1e-0030485903e8
5394b34e-41be-11df-907f-00215aee3ebe;Романівський;Романовский;d15e301d-60b0-11de-be1e-0030485903e8
5d3111d0-41b9-11df-907f-00215aee3ebe;Роменський;Роменский;d15e302a-60b0-11de-be1e-0030485903e8
fbfb8c0f-41be-11df-907f-00215aee3ebe;Рубіжне;Рубежное;d15e3023-60b0-11de-be1e-0030485903e8
78f4539e-41b9-11df-907f-00215aee3ebe;Ружинський;Ружинский;d15e301d-60b0-11de-be1e-0030485903e8
6b07f835-41b9-11df-907f-00215aee3ebe;Савранський;Савранский;d15e3026-60b0-11de-be1e-0030485903e8
64024bd0-41b9-11df-907f-00215aee3ebe;Самбірський;Самборский;d15e3024-60b0-11de-be1e-0030485903e8
78f4541b-41b9-11df-907f-00215aee3ebe;Саратський;Саратский;d15e3026-60b0-11de-be1e-0030485903e8
903cca87-41b9-11df-907f-00215aee3ebe;Сарненський;Сарненский;d15e3028-60b0-11de-be1e-0030485903e8
5d3112a7-41b9-11df-907f-00215aee3ebe;Сахновщинський;Сахновщинский;d15e302c-60b0-11de-be1e-0030485903e8
710c27f5-41b9-11df-907f-00215aee3ebe;Свалявський;Свалявский;d15e301e-60b0-11de-be1e-0030485903e8
9b486f7b-41b9-11df-907f-00215aee3ebe;Сватівський;Сватовский;d15e3023-60b0-11de-be1e-0030485903e8
903ccacc-41b9-11df-907f-00215aee3ebe;Світловодський;Светловодский;d15e3022-60b0-11de-be1e-0030485903e8
a1e9f9ae-41b9-11df-907f-00215aee3ebe;Селидове;Селидовский;d15e301c-60b0-11de-be1e-0030485903e8
f9333443-db4e-11df-9197-00215aee3ebe;Семенівський;Семеновский;d15e3027-60b0-11de-be1e-0030485903e8
64024b42-41b9-11df-907f-00215aee3ebe;Семенівський;Семеновский;d15e3031-60b0-11de-be1e-0030485903e8
903ccacd-41b9-11df-907f-00215aee3ebe;Середино-Будський;Середина-Будский;d15e302a-60b0-11de-be1e-0030485903e8
8067ad65-41b9-11df-907f-00215aee3ebe;Сєверодонецький;Северодонецкий;d15e3023-60b0-11de-be1e-0030485903e8
28dbba30-14f1-11ee-80bd-000c2961d091;Сєвєродонецький;;d15e3023-60b0-11de-be1e-0030485903e8
903ccaaa-41b9-11df-907f-00215aee3ebe;Синельниківський;Синельниковский;d15e301b-60b0-11de-be1e-0030485903e8
5d311277-41b9-11df-907f-00215aee3ebe;Скадовський;Скадовский;d15e302d-60b0-11de-be1e-0030485903e8
5d31126b-41b9-11df-907f-00215aee3ebe;Сквирський;Сквирский;d15e3021-60b0-11de-be1e-0030485903e8
9b486f70-41b9-11df-907f-00215aee3ebe;Сколівський;Сколевский;d15e3024-60b0-11de-be1e-0030485903e8
fbfb8c15-41be-11df-907f-00215aee3ebe;Славутич;Славутич;d15e3021-60b0-11de-be1e-0030485903e8
710c2737-41b9-11df-907f-00215aee3ebe;Славутський;Славутский;d15e302e-60b0-11de-be1e-0030485903e8
9b486f88-41b9-11df-907f-00215aee3ebe;Слов'яносербський;Славяносербский;d15e3023-60b0-11de-be1e-0030485903e8
5d311214-41b9-11df-907f-00215aee3ebe;Слов'янський;Славянский;d15e301c-60b0-11de-be1e-0030485903e8
6b07f870-41b9-11df-907f-00215aee3ebe;Смілянський;Смелянский;d15e302f-60b0-11de-be1e-0030485903e8
64024b8b-41b9-11df-907f-00215aee3ebe;Снігурівський;Снигиревский;d15e3025-60b0-11de-be1e-0030485903e8
5d311215-41b9-11df-907f-00215aee3ebe;Сніжне;Снежное;d15e301c-60b0-11de-be1e-0030485903e8
903ccad9-41b9-11df-907f-00215aee3ebe;Сновський (кол. Щорський);Сновский (быв. Щорский);d15e3031-60b0-11de-be1e-0030485903e8
6b07f885-41b9-11df-907f-00215aee3ebe;Снятинський;Снятинский;d15e3020-60b0-11de-be1e-0030485903e8
710c277a-41b9-11df-907f-00215aee3ebe;Сокальський;Сокальский;d15e3024-60b0-11de-be1e-0030485903e8
78f45405-41b9-11df-907f-00215aee3ebe;Сокирянський;Сокирянский;d15e3030-60b0-11de-be1e-0030485903e8
5d3112a6-41b9-11df-907f-00215aee3ebe;Солонянський;Солонянский;d15e301b-60b0-11de-be1e-0030485903e8
78f45402-41b9-11df-907f-00215aee3ebe;Сорокинський (Краснодонський);Сорокинский;d15e3023-60b0-11de-be1e-0030485903e8
4ffc4b8e-41b9-11df-907f-00215aee3ebe;Сосницький;Сосницкий;d15e3031-60b0-11de-be1e-0030485903e8
4ffc4b90-41b9-11df-907f-00215aee3ebe;Софіївський;Софиевский;d15e301b-60b0-11de-be1e-0030485903e8
903ccabc-41b9-11df-907f-00215aee3ebe;Срібнянський;Сребнянский;d15e3031-60b0-11de-be1e-0030485903e8
5d311271-41b9-11df-907f-00215aee3ebe;Ставищенський;Ставищенский;d15e3021-60b0-11de-be1e-0030485903e8
64024b22-41b9-11df-907f-00215aee3ebe;Станично-Луганський;Станично-Луганский;d15e3023-60b0-11de-be1e-0030485903e8
bad3ac2b-41b9-11df-907f-00215aee3ebe;Старобешівський;Старобешевский;d15e301c-60b0-11de-be1e-0030485903e8
6b07f83b-41b9-11df-907f-00215aee3ebe;Старобільський;Старобельский;d15e3023-60b0-11de-be1e-0030485903e8
903cca8c-41b9-11df-907f-00215aee3ebe;Старовижівський;Старовыжевский;d15e301a-60b0-11de-be1e-0030485903e8
6b07f80c-41b9-11df-907f-00215aee3ebe;Старокостянтинівський;Староконстантиновский;d15e302e-60b0-11de-be1e-0030485903e8
64024bd1-41b9-11df-907f-00215aee3ebe;Старосамбірський;Старосамборский;d15e3024-60b0-11de-be1e-0030485903e8
4ffc4bdb-41b9-11df-907f-00215aee3ebe;Старосинявський;Старосинявский;d15e302e-60b0-11de-be1e-0030485903e8
64024b4f-41b9-11df-907f-00215aee3ebe;Сторожинецький;Сторожинецкий;d15e3030-60b0-11de-be1e-0030485903e8
6b07f8a7-41b9-11df-907f-00215aee3ebe;Стрийський;Стрыйский;d15e3024-60b0-11de-be1e-0030485903e8
9b486f64-41b9-11df-907f-00215aee3ebe;Суми;Сумы;d15e302a-60b0-11de-be1e-0030485903e8
710c2706-41b9-11df-907f-00215aee3ebe;Сумський;Сумской;d15e302a-60b0-11de-be1e-0030485903e8
710c27eb-41b9-11df-907f-00215aee3ebe;Талалаївський;Талалаевский;d15e3031-60b0-11de-be1e-0030485903e8
78f453ed-41b9-11df-907f-00215aee3ebe;Тальнівський;Тальновский;d15e302f-60b0-11de-be1e-0030485903e8
8067acbe-41b9-11df-907f-00215aee3ebe;Таращанський;Таращанский;d15e3021-60b0-11de-be1e-0030485903e8
710c27df-41b9-11df-907f-00215aee3ebe;Тарутинський;Тарутинский;d15e3026-60b0-11de-be1e-0030485903e8
6b07f828-41b9-11df-907f-00215aee3ebe;Татарбунарський;Татарбунарский;d15e3026-60b0-11de-be1e-0030485903e8
6b07f823-41b9-11df-907f-00215aee3ebe;Теофіпольський;Теофипольский;d15e302e-60b0-11de-be1e-0030485903e8
5d31126c-41b9-11df-907f-00215aee3ebe;Теплицький;Теплицкий;d15e3019-60b0-11de-be1e-0030485903e8
6b07f803-41b9-11df-907f-00215aee3ebe;Теребовлянський;Теребовлянский;d15e302b-60b0-11de-be1e-0030485903e8
fbfb8c1d-41be-11df-907f-00215aee3ebe;Тернівка;Терновка;d15e301b-60b0-11de-be1e-0030485903e8
bad3ac18-41b9-11df-907f-00215aee3ebe;Тернопіль;Тернополь;d15e302b-60b0-11de-be1e-0030485903e8
5d3111de-41b9-11df-907f-00215aee3ebe;Тернопільський;Тернопольский;d15e302b-60b0-11de-be1e-0030485903e8
903cca7a-41b9-11df-907f-00215aee3ebe;Тетіївський;Тетиевский;d15e3021-60b0-11de-be1e-0030485903e8
903cca8e-41b9-11df-907f-00215aee3ebe;Тиврівський;Тывровский;d15e3019-60b0-11de-be1e-0030485903e8
710c27e5-41b9-11df-907f-00215aee3ebe;Тисменицький;Тысменицкий;d15e3020-60b0-11de-be1e-0030485903e8
8a199cbf-41b9-11df-907f-00215aee3ebe;Тлумацький;Тлумачский;d15e3020-60b0-11de-be1e-0030485903e8
6b07f88c-41b9-11df-907f-00215aee3ebe;Токмацький;Токмакский;d15e301f-60b0-11de-be1e-0030485903e8
9b486f93-41b9-11df-907f-00215aee3ebe;Томаківський;Томаковский;d15e301b-60b0-11de-be1e-0030485903e8
5d31126d-41b9-11df-907f-00215aee3ebe;Томашпільський;Томашпольский;d15e3019-60b0-11de-be1e-0030485903e8
5d311263-41b9-11df-907f-00215aee3ebe;Троїцький;Троицкий;d15e3023-60b0-11de-be1e-0030485903e8
f9333441-db4e-11df-9197-00215aee3ebe;Тростянецький;Тростянецкий;d15e302a-60b0-11de-be1e-0030485903e8
710c27cf-41b9-11df-907f-00215aee3ebe;Тростянецький;Тростянецкий;d15e3019-60b0-11de-be1e-0030485903e8
fbfb8c20-41be-11df-907f-00215aee3ebe;Трускавець;Трускавец;d15e3024-60b0-11de-be1e-0030485903e8
78f45403-41b9-11df-907f-00215aee3ebe;Тульчинський;Тульчинский;d15e3019-60b0-11de-be1e-0030485903e8
78f4542e-41b9-11df-907f-00215aee3ebe;Турійський;Турийский;d15e301a-60b0-11de-be1e-0030485903e8
6b07f80d-41b9-11df-907f-00215aee3ebe;Турківський;Турковский;d15e3024-60b0-11de-be1e-0030485903e8
710c274c-41b9-11df-907f-00215aee3ebe;Тячівський;Тячевский;d15e301e-60b0-11de-be1e-0030485903e8
fbfb8c23-41be-11df-907f-00215aee3ebe;Ужгород;Ужгород;d15e301e-60b0-11de-be1e-0030485903e8
5d311219-41b9-11df-907f-00215aee3ebe;Ужгородський;Ужгородский;d15e301e-60b0-11de-be1e-0030485903e8
5d31127a-41b9-11df-907f-00215aee3ebe;Уманський;Уманский;d15e302f-60b0-11de-be1e-0030485903e8
710c27e7-41b9-11df-907f-00215aee3ebe;Устинівський;Устиновский;d15e3022-60b0-11de-be1e-0030485903e8
8067ad6a-41b9-11df-907f-00215aee3ebe;Фастівський;Фастовский;d15e3021-60b0-11de-be1e-0030485903e8
fbfb8c27-41be-11df-907f-00215aee3ebe;Харків;Харьков;d15e302c-60b0-11de-be1e-0030485903e8
64024bc4-41b9-11df-907f-00215aee3ebe;Харківський;Харьковский;d15e302c-60b0-11de-be1e-0030485903e8
bad3ac25-41b9-11df-907f-00215aee3ebe;Харцизьк;Харцызск;d15e301c-60b0-11de-be1e-0030485903e8
5d311278-41b9-11df-907f-00215aee3ebe;Херсон;Херсон;d15e302d-60b0-11de-be1e-0030485903e8
df426eee-14c9-11ee-80bd-000c2961d091;Херсонський;Херсонский;d15e302d-60b0-11de-be1e-0030485903e8
5d31122a-41b9-11df-907f-00215aee3ebe;Хмельницький;Хмельницкий;d15e302e-60b0-11de-be1e-0030485903e8
46f64743-db59-11e8-80d7-1c98ec135261;Хмільник;Хмильнык;d15e3019-60b0-11de-be1e-0030485903e8
710c27ed-41b9-11df-907f-00215aee3ebe;Хмільницький;Хмельницкий;d15e3019-60b0-11de-be1e-0030485903e8
5d311222-41b9-11df-907f-00215aee3ebe;Хорольський;Хорольский;d15e3027-60b0-11de-be1e-0030485903e8
a1e9f9c1-41b9-11df-907f-00215aee3ebe;Хорошівський (Володарськ-Волинський);Хорошівський (Володарск-В;d15e301d-60b0-11de-be1e-0030485903e8
5d3111c1-41b9-11df-907f-00215aee3ebe;Хотинський;Хотинский;d15e3030-60b0-11de-be1e-0030485903e8
6b07f806-41b9-11df-907f-00215aee3ebe;Христинівський;Христиновский;d15e302f-60b0-11de-be1e-0030485903e8
78f45396-41b9-11df-907f-00215aee3ebe;Хустський;Хустский;d15e301e-60b0-11de-be1e-0030485903e8
64024bc5-41b9-11df-907f-00215aee3ebe;Царичанський;Царичанский;d15e301b-60b0-11de-be1e-0030485903e8
64024b5d-41b9-11df-907f-00215aee3ebe;Чаплинський;Чаплинский;d15e302d-60b0-11de-be1e-0030485903e8
5d311227-41b9-11df-907f-00215aee3ebe;Чемеровецький;Чемеровецкий;d15e302e-60b0-11de-be1e-0030485903e8
bad3ac26-41b9-11df-907f-00215aee3ebe;Червоноград;Червоноград;d15e3024-60b0-11de-be1e-0030485903e8
5b33762c-14c9-11ee-80bd-000c2961d091;Червоноградський;Червоноградский;d15e3024-60b0-11de-be1e-0030485903e8
5394b345-41be-11df-907f-00215aee3ebe;Черкаси;Черкассы;d15e302f-60b0-11de-be1e-0030485903e8
6b07f834-41b9-11df-907f-00215aee3ebe;Черкаський;Черкасский;d15e302f-60b0-11de-be1e-0030485903e8
6b07f7f7-41b9-11df-907f-00215aee3ebe;Чернівецький;Черновицкий;d15e3019-60b0-11de-be1e-0030485903e8
fbfb8c29-41be-11df-907f-00215aee3ebe;Чернівці;Черновцы;d15e3030-60b0-11de-be1e-0030485903e8
5394b31b-41be-11df-907f-00215aee3ebe;Чернігів;Чернигов;d15e3031-60b0-11de-be1e-0030485903e8
f933344a-db4e-11df-9197-00215aee3ebe;Чернігівський;Черниговский;d15e301f-60b0-11de-be1e-0030485903e8
5d311229-41b9-11df-907f-00215aee3ebe;Чернігівський;Черниговский;d15e3031-60b0-11de-be1e-0030485903e8
5d311218-41b9-11df-907f-00215aee3ebe;Черняхівський;Черняховский;d15e301d-60b0-11de-be1e-0030485903e8
78f453a8-41b9-11df-907f-00215aee3ebe;Чечельницький;Чечельницкий;d15e3019-60b0-11de-be1e-0030485903e8
8067ad70-41b9-11df-907f-00215aee3ebe;Чигиринський;Чигиринский;d15e302f-60b0-11de-be1e-0030485903e8
5394b34c-41be-11df-907f-00215aee3ebe;Чистякове (Торез);Чистяковое;d15e301c-60b0-11de-be1e-0030485903e8
fbfb8c2b-41be-11df-907f-00215aee3ebe;Чоп;Чоп;d15e301e-60b0-11de-be1e-0030485903e8
6b07f852-41b9-11df-907f-00215aee3ebe;Чорнобаївський;Чернобаевский;d15e302f-60b0-11de-be1e-0030485903e8
903cca7c-41b9-11df-907f-00215aee3ebe;Чорноморськ (Іллічівськ);Черноморск;d15e3026-60b0-11de-be1e-0030485903e8
78f45404-41b9-11df-907f-00215aee3ebe;Чорнухинський;Чернухинский;d15e3027-60b0-11de-be1e-0030485903e8
6b07f826-41b9-11df-907f-00215aee3ebe;Чортківський;Чертковский;d15e302b-60b0-11de-be1e-0030485903e8
6b07f822-41b9-11df-907f-00215aee3ebe;Чугуївський;Чугуевский;d15e302c-60b0-11de-be1e-0030485903e8
6b07f7f3-41b9-11df-907f-00215aee3ebe;Чуднівський;Чудновский;d15e301d-60b0-11de-be1e-0030485903e8
64024b23-41b9-11df-907f-00215aee3ebe;Чутівський;Чутовский;d15e3027-60b0-11de-be1e-0030485903e8
8a199cea-41b9-11df-907f-00215aee3ebe;Шаргородський;Шаргородский;d15e3019-60b0-11de-be1e-0030485903e8
903ccad2-41b9-11df-907f-00215aee3ebe;Шахтарський;Шахтерский;d15e301c-60b0-11de-be1e-0030485903e8
bad3ac39-41b9-11df-907f-00215aee3ebe;Шацький;Шацкий;d15e301a-60b0-11de-be1e-0030485903e8
64024b06-41b9-11df-907f-00215aee3ebe;Шевченківський;Шевченковский;d15e302c-60b0-11de-be1e-0030485903e8
78f453e9-41b9-11df-907f-00215aee3ebe;Шепетівський;Шепетовский;d15e302e-60b0-11de-be1e-0030485903e8
5d311212-41b9-11df-907f-00215aee3ebe;Широківський;Широковский;d15e301b-60b0-11de-be1e-0030485903e8
8a199ccd-41b9-11df-907f-00215aee3ebe;Ширяївський;Ширяевский;d15e3026-60b0-11de-be1e-0030485903e8
6b07f8b4-41b9-11df-907f-00215aee3ebe;Шишацький;Шишацкий;d15e3027-60b0-11de-be1e-0030485903e8
8067acca-41b9-11df-907f-00215aee3ebe;Шосткинський;Шосткинский;d15e302a-60b0-11de-be1e-0030485903e8
5d31127b-41b9-11df-907f-00215aee3ebe;Шполянський;Шполянский;d15e302f-60b0-11de-be1e-0030485903e8
5d311238-41b9-11df-907f-00215aee3ebe;Шумський;Шумский;d15e302b-60b0-11de-be1e-0030485903e8
3cb4c6c4-14f1-11ee-80bd-000c2961d091;Щастинський;;d15e3023-60b0-11de-be1e-0030485903e8
fbfb8c33-41be-11df-907f-00215aee3ebe;Южноукраїнськ;Южноукраинск;d15e3025-60b0-11de-be1e-0030485903e8
903ccaab-41b9-11df-907f-00215aee3ebe;Юр'ївський;Юрьевский;d15e301b-60b0-11de-be1e-0030485903e8
710c27c5-41b9-11df-907f-00215aee3ebe;Яворівський;Яворовский;d15e3024-60b0-11de-be1e-0030485903e8
8067acc7-41b9-11df-907f-00215aee3ebe;Яготинський;Яготинский;d15e3021-60b0-11de-be1e-0030485903e8
64024b83-41b9-11df-907f-00215aee3ebe;Якимівський;Акимовский;d15e301f-60b0-11de-be1e-0030485903e8
5d311276-41b9-11df-907f-00215aee3ebe;Ямпільський;Ямпольский;d15e3019-60b0-11de-be1e-0030485903e8
a5388eb3-db65-11df-9197-00215aee3ebe;Ямпільський;Ямпольский;d15e302a-60b0-11de-be1e-0030485903e8
a1e9f9cf-41b9-11df-907f-00215aee3ebe;Яремче;Яремче;d15e3020-60b0-11de-be1e-0030485903e8
5d31127d-41b9-11df-907f-00215aee3ebe;Ярмолинецький;Ярмолинецкий;d15e302e-60b0-11de-be1e-0030485903e8
4ffc4b8c-41b9-11df-907f-00215aee3ebe;Ясинуватський;Ясиноватский;d15e301c-60b0-11de-be1e-0030485903e8
`