// Функция для открытия модального окна
export function openModal() {
    var modal = document.getElementById('myModal');
    modal.style.display = 'block';
}

// Функция для закрытия модального окна
export function closeModal() {
    var modal = document.getElementById('myModal');
    modal.style.display = 'none';
}

export function openModalWithoutCode() {
    var modal = document.getElementById('myModalWithoutCode');
    modal.style.display = 'block';
}

// Функция для закрытия модального окна
export function closeModalWithoutCode() {
    var modal = document.getElementById('myModalWithoutCode');
    modal.style.display = 'none';
}