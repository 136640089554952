import { dataArea } from "./dataArea.js";
import { dataRegion } from "./dataRegion.js";
import { dataCity } from "./dataCity.js";
import { dataSubdivision } from "./dataSubdivision.js";


const MAXFINDCITY = 20;


const searchArea = (data) => {
    var searchId = data;

    // Разбиваем данные на строки
    var lines = dataArea.trim().split("\n");

    // Проходимся по каждой строке
    for (var i = 0; i < lines.length; i++) {
        // Разбиваем строку на части, используя ";" как разделитель
        var parts = lines[i].split(";");

        // Если первая часть (идентификатор) совпадает с искомым идентификатором
        if (parts[0] === searchId) {
            // Выводим вторую и третью части (названия регионов) в консоль
            //console.log("Найдено:", parts[1], parts[2]);
            // Можно прервать цикл, если нужно найти только первое совпадение
            return parts[1];
        }
    }
}

const searchRegion = (data) => {
    var searchId = data;

    // Разбиваем данные на строки
    var lines = dataRegion.trim().split("\n");

    // Проходимся по каждой строке
    for (var i = 0; i < lines.length; i++) {
        // Разбиваем строку на части, используя ";" как разделитель
        var parts = lines[i].split(";");

        // Если первая часть (идентификатор) совпадает с искомым идентификатором
        if (parts[0] === searchId) {
            // Выводим вторую и третью части (названия регионов) в консоль
            //console.log("Найдено:", parts[1], parts[2]);
            // Можно прервать цикл, если нужно найти только первое совпадение
            return parts[1];
        }
    }

}

const searchAllCity = (data) => {
    var searchId = data;

    // Разбиваем данные на строки
    var lines = dataCity.trim().split("\n");

    //Виводимо перші MAXFINDCITY співпадінь
    var j = 0;

    let strFindCity = '';
    // Проходимся по каждой строке
    for (var i = 0; i < lines.length; i++) {
        // Разбиваем строку на части, используя ";" как разделитель
        var parts = lines[i].split(";");

        // Если первая часть (идентификатор) совпадает с искомым идентификатором

       
        if (parts[1].toLowerCase().startsWith(searchId.toLowerCase())){
            j++;
            strFindCity += parts + "\n";
        }

        if (j == MAXFINDCITY) {
            break;          
        }

        if (parts[1] === searchId) {
            // Выводим вторую и третью части (названия регионов) в консоль
            //console.log("Найдено:", parts[1], parts[2]);
            // Можно прервать цикл, если нужно найти только первое совпадение
        }
    }
    return strFindCity;
}

export const searchFindCity = (data) => {
    const strAllCities =  searchAllCity(data);
    var lines = strAllCities.trim().split("\n");

    const arrCities = [];

    for (var i = 0; i < lines.length; i++) {
        // Разбиваем строку на части, используя "," как разделитель
        var parts = lines[i].split(",");

        let fullNameCity;
        let onlyCity;
        let searchId;

        if (parts[1] == undefined){
            fullNameCity = "введіть коректну назву насленного пункта";
            onlyCity = "введіть коректну назву насленного пункта";
            searchId = null;
        } else {
            fullNameCity = parts[1] + ' (' + searchArea(parts[5]) + ' обл, ' + searchRegion(parts[4]) + ' р-н)';
            onlyCity = parts[1];
            searchId = parts[0];
        }

        let objCity = {
            searchId,
            fullNameCity,
            onlyCity
        }

        arrCities.push(objCity)
    }

    return arrCities; 
}

export const searchSubdivision = (data) => {
        var searchId = data;
        // Разбиваем данные на строки
        var lines = dataSubdivision.trim().split("\n");

        for (var i = 0; i < lines.length; i++) {
            // Разбиваем строку на части, используя ";" как разделитель
            var parts = lines[i].split(";");
    
            if (parts[0] === searchId) {
                // Выводим вторую и третью части (названия регионов) в консоль
                //console.log("Найдено:", parts[1], parts[2]);
                return parts[2]
                // Можно прервать цикл, если нужно найти только первое совпадение
            }
        }
        return null;
}

export const addFilterfindDepartamentInThisCity = (data) => {
    var arrDepartamentInThisCity = []

    var lines = dataSubdivision.trim().split("\n");

    for (var i = 0; i < data.length; i++) {

        for (var j = 0; j < lines.length; j++) {
            var parts = lines[j].split(";");
            if((data[i].br_id === parts[0]) && (parts[0] !== "")){
                arrDepartamentInThisCity.push(data[i])
            }
        }
    }

    return arrDepartamentInThisCity;

}



