import { refs } from "../searchPost/refs";


export function showExpand() {
    // var expandableBlock = document.getElementById('expandable-block');
    // var followingContent = document.querySelector('.following-content');

    // console.log(expandableBlock);
    // console.log(followingContent);

    refs.expandableBlock.classList.add('expanded');
    refs.followingContent.classList.add('shifted');



    // if (expandableBlock.classList.contains('expanded')) {
    //     // Скрыть раскрывающийся блок
    //     expandableBlock.classList.remove('expanded');
    //     followingContent.classList.remove('shifted');
    // } else {
    //     // Показать раскрывающийся блок

    // }
}


export function hideExpand () {
    // var expandableBlock = document.getElementById('expandable-block');
    // var followingContent = document.querySelector('.following-content');

    refs.expandableBlock.classList.remove('expanded');
    refs.followingContent.classList.remove('shifted');
}
