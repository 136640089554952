const label4 = document.querySelector("#label-4");
const label5 = document.querySelector("#label-5");
const label6 = document.querySelector("#label-6");

const check4 = document.querySelector("#check4");
const check5 = document.querySelector("#check5");
const check6 = document.querySelector("#check6");

label4.addEventListener("click", e => {
    check4.classList.add("checked");
    check5.classList.remove("checked");
    check6.classList.remove("checked");

    // menu1.classList.add("is-active");
    // menu2.classList.remove("is-active");
    // menu3.classList.remove("is-active");
})

label5.addEventListener("click", e => {
    check4.classList.remove("checked");
    check5.classList.add("checked");
    check6.classList.remove("checked");

    // menu1.classList.remove("is-active");
    // menu2.classList.add("is-active");
    // menu3.classList.remove("is-active");
})

label6.addEventListener("click", e => {
    check4.classList.remove("checked");
    check5.classList.remove("checked");
    check6.classList.add("checked");

    // menu1.classList.remove("is-active");
    // menu2.classList.remove("is-active");
    // menu3.classList.add("is-active");
})