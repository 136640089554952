import { searchSubdivision } from "./searchRegion";
import meestPng from "../../images/ico_30.png";
import meestMiniPng from "../../images/ico_mini.png";
import meestPoshtomanPng from "../../images/transparent.png"

export const createListMurkup = (data) => {
    //console.log(data)
    const markup = data.map(({fullNameCity, searchId, onlyCity}) => `
    <li class="item-menu" data-id="${searchId}" data-info="${onlyCity}">${fullNameCity}</li>
    `).join("");
    return markup;
}

export const createMurkupDepartment = (data) => {

    let firstStr = ""
    if (data.length !== 0) firstStr = `<li class="item-menu">Виберіть відділення</li>`

    const markup = data.map(({br_id, type_public}) => `
    <li class="item-menu" data-branchid="${br_id}" data-info="${getPublicDepartment(type_public.ua) + " " + searchSubdivision(br_id)}">${getPublicDepartment(type_public.ua) + " " + searchSubdivision(br_id)}</li>
    `).join("");

    return firstStr + markup;
}

export const createMurkupOneDepartment = ({type_public, working_hours, location_description, city, street, street_number, lat, lng}) => {
    const markup = `
    <div>
        <img class="img_branch_type" src="${getPictureDepartment(type_public.ua)}" alt="логотип відділення"/>
        <b> ${getPublicDepartment(type_public.ua)}</b>
        <div class="margin_block">
            <p>${city.ua + " " + street.ua + ", " + street_number}</p>
            <p>${location_description}</p>
        </div>
        <div class="map_pop_time margin_block">
            <span class="r_l">
                <span style="line-height: 1.2">${workTime(working_hours)}</span>
                <div class="line"></div>
                <a class="link_map" target="_blanc" href = "https://www.google.com/maps/search/?api=1&query=${lat},${lng}">Показати на карті</a>
            </span>
        </div>
    </div>
    `
    return markup;
}

function getPictureDepartment(typePublic) {
    var dictionaryPicture = new Map();

    dictionaryPicture.set("Вантажне відділення", meestPng);
    dictionaryPicture.set("Міжнародне вантажне відділення", meestPng);
    dictionaryPicture.set("Міні відділення", meestMiniPng);
    dictionaryPicture.set("Поштомат", meestPoshtomanPng);

    return dictionaryPicture.get(typePublic);
}


function getPublicDepartment(typePublic) {
    var dictionary = new Map();

    dictionary.set("Вантажне відділення", "Meest ПОШТА");
    dictionary.set("Міжнародне вантажне відділення", "Meest ПОШТА");
    dictionary.set("Міні відділення", "Meest ПОШТА-mini");
    dictionary.set("Поштомат", "Meest Поштомат");

    return dictionary.get(typePublic);
}

function workTime (strTime) {

    // Разделение строки по запятой
    let parts = strTime.split(', ');

    // Добавление <br/> к каждой части, кроме последней
    let formattedSchedule = parts.map((part, index) => {
        // Проверяем, чтобы не добавлять <br/> после последней части
        if (index < parts.length - 1) {
            return part + '<br/>';
        } else {
            return part; // Для последней части не добавляем <br/>
        }
    });

    // Объединение частей обратно в строку
    let finalSchedule = formattedSchedule.join('');
    return finalSchedule;
}