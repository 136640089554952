import Notiflix from 'notiflix';
import {refs} from './refs'
//import { refsOne } from '../components/refs';
import { searchFindCity, addFilterfindDepartamentInThisCity } from './searchRegion';
import { createListMurkup, createMurkupDepartment, createMurkupOneDepartment } from './murkupList';
import { fetchInfoDepartment, fetchInfoOneDepartment } from '../helpers/axiosMeestExpress';
import { showSpinner, hideSpinner } from '../components/spinner';
import { showExpand, hideExpand } from '../components/expandable';


// !!!!!!!!!! Events Target !!!!!!!!!!!!!

refs.inputCity.addEventListener('input', changeInputCity);

//close list menu after click out menu
document.addEventListener('mouseup', function(e) {
    var container = refs.listMenu;
 
    if (!container.contains(e.target)) {
        container.style.display = 'none';
    }
});

document.addEventListener('mouseup', function(e) {
    var container = refs.listMenuDepartment;
 
    if (!container.contains(e.target)) {
        container.style.display = 'none';
    }
});

refs.listMenu.addEventListener('click', onClickMenuList);

refs.listMenuDepartment.addEventListener('click', onClickMenuDepartment)

// !!!!!!!!!! End Events Target !!!!!!!!!!!!!

function changeInputCity(e){
    refs.listMenu.innerHTML = "";   
    //розрахунок початкових координат для list menu
    const left = refs.inputCity.getBoundingClientRect().x + "px";
    const top = refs.inputCity.getBoundingClientRect().y + refs.inputCity.getBoundingClientRect().height + "px";
    
    refs.listMenu.style.left = left;
    refs.listMenu.style.top = top;

    refs.listMenu.style.display = "block";
    

    if (e.target.value !== ""){

        //маємо отримати масив строк в форматі "місто (область, район)"
        const arrFindCities = searchFindCity(e.target.value);
        //console.log(arrFindCities);

        
        //createListMurkup(arrFindCities);      

        if(arrFindCities[0].searchId !== undefined){
            const htmlListMurkUp = createListMurkup(arrFindCities);
            refs.listMenu.insertAdjacentHTML("beforeend", htmlListMurkUp)
        }
    }
}

function onClickMenuList (e) {
    //const MY = "d15e301c-60b0-11de-be1e-0030485903e8"
    const selectedCityId = e.target.dataset.id;
    const selectedCityInfo = e.target.dataset.info;
    refs.listMenu.style.display = 'none';
    // console.log(selectedCityId)
    // console.log(selectedCityInfo)
    refs.inputCity.value = selectedCityInfo;
    refs.inputDepartment.value = "Виберіть відділення";

    showSpinner()

    fetchInfoDepartment().then((data) => {
        refs.listMenuDepartment.innerHTML = "";

        hideExpand();
        // !!!!!!!!!!!!! ставим спинер до появления данных
        // и после этого навешиваем событие клика

        

        //console.log(data)
        const findDepartamentInThisCity = data.filter((obj) => obj.city_id === selectedCityId)
        //console.log(findDepartamentInThisCity)

        if(findDepartamentInThisCity.length === 0) {
            //Notiflix.Notify.warning('В цьому населеному пункті не зареєстровано відділень MeestExpress');

            Notiflix.Report.success(
                'Інформація відсутня',
                'В цьому населеному пункті не зареєстровано відділень MeestExpress',
                'Вибрати інший',
              );
        }
        //Залишаємо тільки ті елементи, на які є адреса в нашій базі
        const filterDepartamentInThisCity = addFilterfindDepartamentInThisCity(findDepartamentInThisCity);

        //console.log(filterDepartamentInThisCity)

        const htmlListMurkUpDepartment = createMurkupDepartment(filterDepartamentInThisCity);
        refs.listMenuDepartment.insertAdjacentHTML("beforeend", htmlListMurkUpDepartment);

        refs.inputDepartment.addEventListener('click', chooseDepartment);

        hideSpinner()

    }).catch((err) => console.log(err))

    
}

function chooseDepartment (e) {
    const left = refs.inputDepartment.getBoundingClientRect().x + "px";
    const top = refs.inputDepartment.getBoundingClientRect().y + refs.inputCity.getBoundingClientRect().height + "px";
        
    refs.listMenuDepartment.style.left = left;
    refs.listMenuDepartment.style.top = top;
    
    refs.listMenuDepartment.style.display = "block";
}

function onClickMenuDepartment (e) {
    refs.expandableBlock.innerHTML = "";
    const selectedDepartmentId = e.target.dataset.branchid;
    const selectedDepartmentInfo = e.target.dataset.info;
    refs.listMenuDepartment.style.display = "none";
    refs.inputDepartment.value = selectedDepartmentInfo;

    showExpand();

    fetchInfoOneDepartment(selectedDepartmentId).then((data) => {
        const htmlListMurkUpOneDepartment = createMurkupOneDepartment(data);
        refs.expandableBlock.insertAdjacentHTML("beforeend", htmlListMurkUpOneDepartment);
    }).catch(err => console.log(err))

}





