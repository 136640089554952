export const dataSubdivision = `
3db8e89f-e839-11e4-b9c8-003048d2b473;(ЗАЧИНЕНО)Поштомат №1801;Львів вул. Зелена, 147;62c3d54a-749b-11df-b112-00215aee3ebe
332910bf-38e4-11e9-80dd-1c98ec135261;JUSTLV;;62c3d54a-749b-11df-b112-00215aee3ebe
189cc0ad-2638-11e9-80dd-1c98ec135261;LVIV Expres;;62c3d54a-749b-11df-b112-00215aee3ebe
629aa3d4-a865-11ed-80ec-000c29800ae7;Meest International;;62c3d54a-749b-11df-b112-00215aee3ebe
d4b20fd1-ad37-11e4-b90b-003048d2b473;MIXROSAN;;62c3d54a-749b-11df-b112-00215aee3ebe
bda6acb4-8977-11ec-80e4-000c29800ae7;myMeest Ukraine;;62c3d54a-749b-11df-b112-00215aee3ebe
e8a0592e-afdf-11ea-80c8-000c29800ae7;RETURNS;;62c3d54a-749b-11df-b112-00215aee3ebe
1a509104-79f4-11ec-80e4-000c29800ae7;Rozetka4 - Олександрівка;Чубинське вул. Залізнична, 31 а;87162884-749b-11df-b112-00215aee3ebe
f895e2a0-8af0-11ee-80c1-000c2961d091;StarDelivery_LV;;62c3d54a-749b-11df-b112-00215aee3ebe
3a3f67b5-8af1-11ee-80c1-000c2961d091;StarDelivery_LV1 ;;62c3d54a-749b-11df-b112-00215aee3ebe
5f58306c-8af1-11ee-80c1-000c2961d091;StarDelivery_LV2;;62c3d54a-749b-11df-b112-00215aee3ebe
7ac2e3ef-c5ba-11ee-80c4-000c2961d091;StarDelivery_RV;;62c3d54a-749b-11df-b112-00215aee3ebe
8cd21acf-c5ba-11ee-80c4-000c2961d091;StarDelivery_RV1;;62c3d54a-749b-11df-b112-00215aee3ebe
a5b766ea-c5ba-11ee-80c4-000c2961d091;StarDelivery_RV2;;62c3d54a-749b-11df-b112-00215aee3ebe
dd0f4b02-c5b9-11ee-80c4-000c2961d091;StarDelivery_VO;;62c3d54a-749b-11df-b112-00215aee3ebe
fc0754c2-c5b9-11ee-80c4-000c2961d091;StarDelivery_VO1;;62c3d54a-749b-11df-b112-00215aee3ebe
1711090c-c5ba-11ee-80c4-000c2961d091;StarDelivery_VO2;;62c3d54a-749b-11df-b112-00215aee3ebe
bb4628ee-a4a6-11e9-80e2-1c98ec135261;Авангард-Ангарська 21;Авангард вул. Ангарська, 21;fa1d4252-7488-11df-b112-00215aee3ebe
3c9a7a84-829a-11ec-80e4-000c29800ae7;Авангард-Європейська 9 Зоотовари;Авангард вул. Європейська, 9;fa1d4252-7488-11df-b112-00215aee3ebe
332cd55c-0671-11ed-80e7-000c29800ae7;Авангард-Спрейса 3 Rozetka;Авангард вул. Спрейса, 3;fa1d4252-7488-11df-b112-00215aee3ebe
f4bf61e4-ba09-11eb-80d4-000c29800ae7;АвтоЛюксЕП;;62c3d54a-749b-11df-b112-00215aee3ebe
30a8ed60-e208-11ee-80c5-000c2961d091;Агрономічне-Мирний 2А;Агрономічне пров. Мирний, 2А;fa1d426e-7488-11df-b112-00215aee3ebe
c47f4aff-77e8-11ee-80c1-000c2961d091;Агрономічне-Мічуріна 12;Агрономічне вул. Мічуріна, 12;fa1d426e-7488-11df-b112-00215aee3ebe
d94972ad-abe1-11ee-80c4-000c2961d091;Андрушівка-Корольова 10Б;Андрушівка вул. Корольова, 10Б;44ac7f98-749b-11df-b112-00215aee3ebe
64961a81-8ad6-11ee-80c1-000c2961d091;Андрушівка-Шевченка 5;Андрушівка пл. Шевченка, 5;44ac7f98-749b-11df-b112-00215aee3ebe
10ed10eb-1bc1-11e8-80d0-1c98ec135261;Апостолове-Елеваторна 3;Апостолове вул. Елеваторна, 3;44ac7ff3-749b-11df-b112-00215aee3ebe
f2ad4861-9a19-11eb-80d3-000c29800ae7;Апостолове-Привокзальна;Апостолове вул. Привокзальна,;44ac7ff3-749b-11df-b112-00215aee3ebe
bcebb304-3ce8-11ee-80c0-000c2961d091;Апостолове-Центральна 49;Апостолове вул. Центральна, 49;44ac7ff3-749b-11df-b112-00215aee3ebe
5a59432e-4255-11ee-80c0-000c2961d091;Арциз-Соборна 2В;Арциз вул. Соборна, 2В;44ac8039-749b-11df-b112-00215aee3ebe
0b959020-43e2-11e9-80dd-1c98ec135261;Базалія-Хмельницького 5 А;Базалія вул. Хмельницького, 5А;44ac80af-749b-11df-b112-00215aee3ebe
5d9c3a33-592c-11ee-80c0-000c2961d091;Балаклія-Жовтнева 1;Балаклія вул. Жовтнева, 1;44ac80f8-749b-11df-b112-00215aee3ebe
7ebc4672-f055-11eb-80d5-000c29800ae7;Балаклія-Соборна 90;Балаклія вул. Соборна, 90;44ac80f8-749b-11df-b112-00215aee3ebe
1c007dc7-dac4-11ed-80ed-000c29800ae7;Балаклія-Соборна 95;Балаклія вул. Соборна, 95;44ac80f8-749b-11df-b112-00215aee3ebe
16537170-2b5c-11ec-80e3-000c29800ae7;Балта-Ломоносова 181;Балта вул. Ломоносова, 181;44ac8121-749b-11df-b112-00215aee3ebe
6dceba2f-f04f-11eb-80d5-000c29800ae7;Балта-Любомирська 179;Балта вул. Любомирська (Котовського), 179;44ac8121-749b-11df-b112-00215aee3ebe
10afd2da-3297-11e8-80d0-1c98ec135261;Балта-Любомирська,187/1;Балта вул. Любомирська (Котовського), 187/1;44ac8121-749b-11df-b112-00215aee3ebe
d6529099-d708-11ee-80c4-000c2961d091;Баранівка-Звягельська 82;Баранівка вул. Звягельська, 82;44ac814b-749b-11df-b112-00215aee3ebe
4dbd73a1-893d-11ee-80c1-000c2961d091;Баранівка-Пашкевича 1;Баранівка вул. Пашкевича, 1;44ac814b-749b-11df-b112-00215aee3ebe
a183a9de-eedf-11eb-80d5-000c29800ae7;Баранівка-Соборна 6;Баранівка вул. Соборна, 6;44ac814b-749b-11df-b112-00215aee3ebe
43627676-b36e-11e9-80e4-1c98ec135261;Барвінок-Егана Едмунда 1 А ;Барвінок вул. Егана Едмунда, 1А;44ac816a-749b-11df-b112-00215aee3ebe
6fe624f3-30d2-11ec-80e3-000c29800ae7;Бар-Героїв майдану 14;Бар вул. Героїв майдану (Пролетарська), 14;44ac8139-749b-11df-b112-00215aee3ebe
e9f2e646-4b85-11ec-80e3-000c29800ae7;Бар-Героїв майдану 22;Бар вул. Героїв майдану (Пролетарська), 22;44ac8139-749b-11df-b112-00215aee3ebe
681693a8-a70d-11e9-80e2-1c98ec135261;Бар-Героїв майдану 51;Бар вул. Героїв майдану (Пролетарська), 51;44ac8139-749b-11df-b112-00215aee3ebe
42d3c311-9fee-11ee-80c4-000c2961d091;Баришівка-Київський шлях 30;Баришівка вул. Київський шлях, 30;44ac8175-749b-11df-b112-00215aee3ebe
f4a166e8-207b-11ee-80bd-000c2961d091;Баришівка-Некрасова 9;Баришівка вул. Некрасова, 9;44ac8175-749b-11df-b112-00215aee3ebe
cfc77445-2c1d-11ec-80e3-000c29800ae7;Бар-Каштанова 2;Бар вул. Каштанова, 2;44ac8139-749b-11df-b112-00215aee3ebe
49ba4a9e-32a1-11e8-80d0-1c98ec135261;Бар-Соборна,6-А;Бар вул. Соборна, 6А;44ac8139-749b-11df-b112-00215aee3ebe
7804d392-7c7f-11e9-80e0-1c98ec135261;Бахмач-Дружби 4 А;Бахмач вул. Дружби, 4А;44ac819c-749b-11df-b112-00215aee3ebe
4099be41-e51b-11ee-80c5-000c2961d091;Бахмач-Першотравнева 41А;Бахмач вул. Першотравнева, 41А;44ac819c-749b-11df-b112-00215aee3ebe
f4e4df8e-0f52-11ee-80bd-000c2961d091;Бахмач-Соборності 24/11;Бахмач вул. Соборності, 24/11;44ac819c-749b-11df-b112-00215aee3ebe
2f0b2976-0f52-11ee-80bd-000c2961d091;Бахмач-Соборності 52/11;Бахмач вул. Соборності, 52/11;44ac819c-749b-11df-b112-00215aee3ebe
fce10b4c-100e-11ee-80bd-000c2961d091;Баштанка-Полтавська 2Б;Баштанка вул. Полтавська, 2Б;44ac81b8-749b-11df-b112-00215aee3ebe
2aa34969-ea7f-11ee-80c5-000c2961d091;Баштанка-Ювілейна 94;Баштанка вул. Ювілейна, 94;44ac81b8-749b-11df-b112-00215aee3ebe
072c5b41-27b4-11ee-80bd-000c2961d091;Безлюдівка-Городня 21Б;Безлюдівка вул. Городня, 21Б;44ac81df-749b-11df-b112-00215aee3ebe
efe7b789-9f35-11ee-80c4-000c2961d091;Безлюдівка-Зміївська 63А;Безлюдівка вул. Зміївська, 63А;44ac81df-749b-11df-b112-00215aee3ebe
60935adb-95d8-11ee-80c1-000c2961d091;Бердичів-30-річчя Перемоги 2/12;Бердичів вул. 30-річчя Перемоги, 2/12;44ac820a-749b-11df-b112-00215aee3ebe
9da8d92c-9b60-11eb-80d3-000c29800ae7;Бердичів-Володарського 3;Бердичів вул. Володарського, 3;44ac820a-749b-11df-b112-00215aee3ebe
7fc4a58d-8ae1-11ee-80c1-000c2961d091;Бердичів-Європейська 45;Бердичів вул. Європейська (Лібкнехта Карла), 45;44ac820a-749b-11df-b112-00215aee3ebe
b3b0f6a2-95d8-11ee-80c1-000c2961d091;Бердичів-Європейська 4А;Бердичів вул. Європейська (Лібкнехта Карла), 4А;44ac820a-749b-11df-b112-00215aee3ebe
56920c1d-dd38-11ea-80ca-000c29800ae7;Бердичів-Європейська 6/5;Бердичів вул. Європейська (Лібкнехта Карла), 6/5;44ac820a-749b-11df-b112-00215aee3ebe
bded7a0e-9169-11ee-80c1-000c2961d091;Бердичів-Європейська 79;Бердичів вул. Європейська (Лібкнехта Карла), 79;44ac820a-749b-11df-b112-00215aee3ebe
9d52fe31-a0f2-11ee-80c4-000c2961d091;Бердичів-Житомирська 20;Бердичів вул. Житомирська, 20;44ac820a-749b-11df-b112-00215aee3ebe
8171a32c-f1dd-11ec-80e7-000c29800ae7;Бердичів-Житомирська 24;Бердичів вул. Житомирська, 24;44ac820a-749b-11df-b112-00215aee3ebe
8cfb30ac-91c1-11e9-80e2-1c98ec135261;Бердичів-Житомирська 84;Бердичів вул. Житомирська, 84;44ac820a-749b-11df-b112-00215aee3ebe
719bae49-329c-11ee-80bd-000c2961d091;Бердичів-Короленка 39В;Бердичів вул. Короленка, 39В;44ac820a-749b-11df-b112-00215aee3ebe
1dd27a0b-b368-11e9-80e4-1c98ec135261;Бердичів-Одеська 16;Бердичів вул. Одеська, 16;44ac820a-749b-11df-b112-00215aee3ebe
85c3d11e-8ae2-11ee-80c1-000c2961d091;Бердичів-Хмельницького Богдана 2;Бердичів вул. Хмельницького Богдана, 2;44ac820a-749b-11df-b112-00215aee3ebe
9db50692-1661-11ee-80bd-000c2961d091;Бердичів-Шевченка 11;Бердичів вул. Шевченка, 11;44ac820a-749b-11df-b112-00215aee3ebe
7952f6cd-f565-11e9-80e7-1c98ec135261;Берегове-Героїв 6 Б;Берегове пл. Героїв, 6Б;44ac821e-749b-11df-b112-00215aee3ebe
9ac0cce5-2b91-11ee-80bd-000c2961d091;Берегове-Хмельницького Богдана 15;Берегове вул. Хмельницького Богдана, 15;44ac821e-749b-11df-b112-00215aee3ebe
1837bb76-b35c-11e9-80e4-1c98ec135261;Берегове-Хмельницького Богдана 86;Берегове вул. Хмельницького Богдана, 86;44ac821e-749b-11df-b112-00215aee3ebe
1102da9f-8877-11ee-80c1-000c2961d091;Берегомет-Центральна 98Б LUXIT;Берегомет вул. Центральна, 98Б;44ac8225-749b-11df-b112-00215aee3ebe
26430679-9ff2-11ee-80c4-000c2961d091;Бережани-Ринок 20;Бережани пл. Ринок, 20;44ac8228-749b-11df-b112-00215aee3ebe
a4d0db6c-a79e-11ed-80ec-000c29800ae7;Бережани-Шевченка 3;Бережани вул. Шевченка, 3;44ac8228-749b-11df-b112-00215aee3ebe
dec2e629-0524-11ee-80bd-000c2961d091;Березань-Гагаріна 3;Березань вул. Гагаріна, 3;44ac824f-749b-11df-b112-00215aee3ebe
0fab5e1c-ead5-11eb-80d5-000c29800ae7;Березань-Маяковського 10;Березань вул. Маяковського, 10;44ac824f-749b-11df-b112-00215aee3ebe
625d9ea7-31f3-11ee-80bd-000c2961d091;Березань-Шевченків шлях 128;Березань вул. Шевченків шлях /кол.Леніна/, 128;44ac824f-749b-11df-b112-00215aee3ebe
53e23262-e2ee-11eb-80d5-000c29800ae7;Березне-Андріївська 25;Березне вул. Андріївська, 25;44ac8295-749b-11df-b112-00215aee3ebe
caad9e58-e848-11ee-80c5-000c2961d091;Березне-Андріївська 5А;Березне вул. Андріївська, 5А;44ac8295-749b-11df-b112-00215aee3ebe
92670405-5d0b-11ee-80c0-000c2961d091;Березне-Андріївська 66;Березне вул. Андріївська, 66;44ac8295-749b-11df-b112-00215aee3ebe
3052b59d-b364-11e9-80e4-1c98ec135261;Березне-Андріївська 95;Березне вул. Андріївська, 95;44ac8295-749b-11df-b112-00215aee3ebe
e705e3ac-53ba-11ee-80c0-000c2961d091;Березне-Будівельників 6Г;Березне вул. Будівельників, 6Г;44ac8295-749b-11df-b112-00215aee3ebe
46820303-b362-11e9-80e4-1c98ec135261;Берестя-Миру 1 А ;Берестя вул. Миру, 1А;44ac82dc-749b-11df-b112-00215aee3ebe
fef137bb-bc1d-11ed-80ed-000c29800ae7;Бершадь-1-го Травня 6;Бершадь вул. 1-го Травня, 6;44ac82fa-749b-11df-b112-00215aee3ebe
0474ae14-1b38-11ea-80e7-1c98ec135261;Бершадь-Героїв України 3;Бершадь вул. Героїв України, 3;44ac82fa-749b-11df-b112-00215aee3ebe
a50ff592-a716-11e9-80e2-1c98ec135261;Бершадь-Покровська 227;Бершадь вул. Покровська, 227;44ac82fa-749b-11df-b112-00215aee3ebe
7c169be1-e20d-11ee-80c5-000c2961d091;Бершадь-Ярослава Мудрого 16;Бершадь вул. Ярослава Мудрого (Леніна), 16;44ac82fa-749b-11df-b112-00215aee3ebe
21b9fc5e-3135-11e9-80dd-1c98ec135261;Бібрка-Галицька 115А;Бібрка вул. Галицька, 115А;44ac8345-749b-11df-b112-00215aee3ebe
dad4ced6-3326-11eb-80ce-000c29800ae7;Бібрка-пл.Національного Відродження 16;Бібрка пл. Національного Відродження, 16;44ac8345-749b-11df-b112-00215aee3ebe
687cbc9c-c811-11ee-80c4-000c2961d091;Бібрка-Ринкова 2А;Бібрка вул. Ринкова, 2А;44ac8345-749b-11df-b112-00215aee3ebe
8e417708-b360-11e9-80e4-1c98ec135261;Біла Криниця-Рівненська 112;Біла Криниця вул. Рівненська, 112;44ac8361-749b-11df-b112-00215aee3ebe
2dff0a0d-a01b-11ee-80c4-000c2961d091;Біла Криниця-Чорновола 28;Біла Криниця вул. Чорновола, 28;44ac8361-749b-11df-b112-00215aee3ebe
6dec1c5f-dde6-11e0-a755-003048d2b473;Біла Церква;Біла Церква вул. Київська, 31;44ac8365-749b-11df-b112-00215aee3ebe
cdbf5c2f-4019-11ee-80c0-000c2961d091;Біла Церква- площа Соборна 1/1 Продукти;Біла Церква пл. Соборна, 1/1;44ac8365-749b-11df-b112-00215aee3ebe
cee1169c-4024-11ee-80c0-000c2961d091;Біла Церква-1-й Курсовий 22 Продукти;Біла Церква пров. 1-й Курсовий, 22;44ac8365-749b-11df-b112-00215aee3ebe
e601a4ea-0c36-11ee-80bd-000c2961d091;Біла Церква-2-а Піщана 2 Делві;Біла Церква вул. Героїв УПА (2-а Піщана), 2;44ac8365-749b-11df-b112-00215aee3ebe
775f9e42-4023-11ee-80c0-000c2961d091;Біла Церква-Академіка Линика 11 Продукти;Біла Церква вул. Академіка Линика, 11;44ac8365-749b-11df-b112-00215aee3ebe
0d9b0c28-401b-11ee-80c0-000c2961d091;Біла Церква-Вернадського 4 Продукти;Біла Церква вул. Вернадського, 4;44ac8365-749b-11df-b112-00215aee3ebe
0f573cae-74b6-11ee-80c1-000c2961d091;Біла Церква-Героїв Небесної Сотні 2А Сільпо;Біла Церква вул. Героїв Небесної Сотні, 2А;44ac8365-749b-11df-b112-00215aee3ebe
dad989fd-1c45-11ec-80df-000c29800ae7;Біла Церква-Героїв Чорнобиля 5/7 Делві;Біла Церква вул. Героїв Чорнобиля, 5/7;44ac8365-749b-11df-b112-00215aee3ebe
4ad00d4c-2092-11ee-80bd-000c2961d091;Біла Церква-Гоголя Миколи 16 lifecell;Біла Церква вул. Гоголя Миколи, 16;44ac8365-749b-11df-b112-00215aee3ebe
a37036cf-4021-11ee-80c0-000c2961d091;Біла Церква-Гончара Олеся 5 Продукти;Біла Церква вул. Гончара Олеся, 5;44ac8365-749b-11df-b112-00215aee3ebe
0dac6420-5810-11ec-80e4-000c29800ae7;Біла Церква-Грушевського 15 АЗС KLO;Біла Церква бул. Грушевського, 15;44ac8365-749b-11df-b112-00215aee3ebe
27b20854-f0e6-11ea-80cc-000c29800ae7;Біла Церква-Докучаєва 3357 Аптека;Біла Церква вул. Докучаєва, 3357;44ac8365-749b-11df-b112-00215aee3ebe
51ab2191-4022-11ee-80c0-000c2961d091;Біла Церква-Дружби 7А Продукти;Біла Церква вул. Дружби, 7А;44ac8365-749b-11df-b112-00215aee3ebe
81defea8-4017-11ee-80c0-000c2961d091;Біла Церква-Запорожця Петра 36 Продукти;Біла Церква вул. Запорожця Петра, 36;44ac8365-749b-11df-b112-00215aee3ebe
38552891-401a-11ee-80c0-000c2961d091;Біла Церква-Зенітного полка 24 Продукти;Біла Церква вул. Зенітного полка ( Гребоїдова ), 24;44ac8365-749b-11df-b112-00215aee3ebe
9dcd1340-3e24-11ec-80e3-000c29800ae7;Біла Церква-Княгині Ольги 12А Делві;Біла Церква бул. Княгині Ольги (кол. Комсомольський), 12А;44ac8365-749b-11df-b112-00215aee3ebe
d9fae842-019c-11ec-80d6-000c29800ae7;Біла Церква-Кожедуба 167 Економ;Біла Церква вул. Кожедуба, 167;44ac8365-749b-11df-b112-00215aee3ebe
37c2c168-ab93-11ed-80ec-000c29800ae7;Біла Церква-Крижановського 3 Rozetka;Біла Церква вул. Крижановського, 3;44ac8365-749b-11df-b112-00215aee3ebe
14ea416e-948a-11ec-80e4-000c29800ae7;Біла Церква-Курсова 38, прим.89 Делві;Біла Церква вул. Курсова, 38, прим.89;44ac8365-749b-11df-b112-00215aee3ebe
6b10a264-73e8-11ea-80c6-000c29800ae7;Біла Церква-Леваневського 10Б ОККО;Біла Церква Просп. Незалежності, 10Б;44ac8365-749b-11df-b112-00215aee3ebe
fd9ee31f-2091-11ee-80bd-000c2961d091;Біла Церква-Леваневського 47/1 Fishki;Біла Церква Просп. Незалежності, 47/1;44ac8365-749b-11df-b112-00215aee3ebe
2b2b5a3e-4023-11ee-80c0-000c2961d091;Біла Церква-Леваневського 47/1 Продукти;Біла Церква Просп. Незалежності, 47/1;44ac8365-749b-11df-b112-00215aee3ebe
82fde705-4024-11ee-80c0-000c2961d091;Біла Церква-Леваневського 50/5 Продукти;Біла Церква Просп. Незалежності, 50/5;44ac8365-749b-11df-b112-00215aee3ebe
7de91044-bcd0-11ed-80ed-000c29800ae7;Біла Церква-Леваневського 59 Lifecell;Біла Церква Просп. Незалежності, 59;44ac8365-749b-11df-b112-00215aee3ebe
13313210-b9aa-11ed-80ed-000c29800ae7;Біла Церква-Леваневського 70 Свіже мясо;Біла Церква Просп. Незалежності, 70;44ac8365-749b-11df-b112-00215aee3ebe
991846be-407e-11eb-80d0-000c29800ae7;Біла Церква-Леваневського 74А ОККО;Біла Церква Просп. Незалежності, 74А;44ac8365-749b-11df-b112-00215aee3ebe
3398795a-1412-11ee-80bd-000c2961d091;Біла Церква-Львівська 2Н Сімейний маркет;Біла Церква вул. Львівська, 2Н;44ac8365-749b-11df-b112-00215aee3ebe
fe1cd5fd-4020-11ee-80c0-000c2961d091;Біла Церква-Митрофанова 9А Продукти;Біла Церква вул. Митрофанова, 9А;44ac8365-749b-11df-b112-00215aee3ebe
8d0c8d88-f1e9-11ec-80e7-000c29800ae7;Біла Церква-Мудрого Ярослава 10 Rozetka;Біла Церква вул. Мудрого Ярослава, 10;44ac8365-749b-11df-b112-00215aee3ebe
36030764-f1e9-11ec-80e7-000c29800ae7;Біла Церква-Нагірна 17 Rozetka;Біла Церква вул. Нагірна, 17;44ac8365-749b-11df-b112-00215aee3ebe
86a1d355-401a-11ee-80c0-000c2961d091;Біла Церква-Олександрійський 139 Продукти;Біла Церква бул. Олександрійський (50-річчя Перемоги), 139;44ac8365-749b-11df-b112-00215aee3ebe
9968c554-019b-11ec-80d6-000c29800ae7;Біла Церква-Олександрійський 175 Економ;Біла Церква бул. Олександрійський (50-річчя Перемоги), 175;44ac8365-749b-11df-b112-00215aee3ebe
cfdaf284-01a8-11ec-80d6-000c29800ae7;Біла Церква-Олександрійський 20 Економ;Біла Церква бул. Олександрійський (50-річчя Перемоги), 20;44ac8365-749b-11df-b112-00215aee3ebe
54f1344e-5c01-11ec-80e4-000c29800ae7;Біла Церква-Олександрійський 50 Все Тут;Біла Церква бул. Олександрійський (50-річчя Перемоги), 50;44ac8365-749b-11df-b112-00215aee3ebe
578e269e-5f0b-11eb-80d0-000c29800ae7;Біла Церква-Олександрійський 55 ОККО;Біла Церква бул. Олександрійський (50-річчя Перемоги), 55;44ac8365-749b-11df-b112-00215aee3ebe
e165d25d-f1e8-11ec-80e7-000c29800ae7;Біла Церква-Олександрійський 66 Rozetka;Біла Церква бул. Олександрійський (50-річчя Перемоги), 66;44ac8365-749b-11df-b112-00215aee3ebe
bfdc0560-ba88-11eb-80d4-000c29800ae7;Біла Церква-Олександрійський 95В Lifecell;Біла Церква бул. Олександрійський (50-річчя Перемоги), 95В;44ac8365-749b-11df-b112-00215aee3ebe
fcc3d739-d8ca-11eb-80d5-000c29800ae7;Біла Церква-Павліченко 49/1 JaSe;Біла Церква вул. Павліченко, 49/1;44ac8365-749b-11df-b112-00215aee3ebe
6c9fa888-4020-11ee-80c0-000c2961d091;Біла Церква-Пролетарський 8 Продукти;Біла Церква пров. Пролетарський, 8;44ac8365-749b-11df-b112-00215aee3ebe
425f5a62-20ab-11e9-80dc-1c98ec135261;Біла Церква-Рибна 10 ОККО;Біла Церква вул. Рибна, 10;44ac8365-749b-11df-b112-00215aee3ebe
40d705c5-407e-11eb-80d0-000c29800ae7;Біла Церква-Сквирське 178 ОККО;Біла Церква шосе Сквирське, 178;44ac8365-749b-11df-b112-00215aee3ebe
34aecd54-4024-11ee-80c0-000c2961d091;Біла Церква-Сквирське 216/110 Продукти;Біла Церква шосе Сквирське, 216/101;44ac8365-749b-11df-b112-00215aee3ebe
7b5371eb-74b5-11ee-80c1-000c2961d091;Біла Церква-Сквирське 230 Сільпо;Біла Церква шосе Сквирське, 230;44ac8365-749b-11df-b112-00215aee3ebe
f19de121-4021-11ee-80c0-000c2961d091;Біла Церква-Стуса Василя 21 Продукти;Біла Церква вул. Стуса Василя (Славіна), 21;44ac8365-749b-11df-b112-00215aee3ebe
8abff301-0667-11ed-80e7-000c29800ae7;Біла Церква-Таращанська 191А Rozetka;Біла Церква вул. Таращанська, 191А;44ac8365-749b-11df-b112-00215aee3ebe
e3b87163-73e8-11ea-80c6-000c29800ae7;Біла Церква-Таращанська 193А ОККО;Біла Церква вул. Таращанська, 193А;44ac8365-749b-11df-b112-00215aee3ebe
a361969b-1c46-11ec-80df-000c29800ae7;Біла Церква-Томилівська 50А Делві;Біла Церква вул. Томилівська, 50А;44ac8365-749b-11df-b112-00215aee3ebe
81211f42-1c47-11ec-80df-000c29800ae7;Біла Церква-Фастівська 28 Делві;Біла Церква вул. Фастівська, 28;44ac8365-749b-11df-b112-00215aee3ebe
5622546a-401e-11ee-80c0-000c2961d091;Біла Церква-Шевченка 3 Продукти;Біла Церква вул. Шевченка, 3;44ac8365-749b-11df-b112-00215aee3ebe
301ad7b0-f57f-11ed-80ed-000c29800ae7;Біла Церква-Шевченка 74 Делві;Біла Церква вул. Шевченка, 74;44ac8365-749b-11df-b112-00215aee3ebe
a91cbda2-401f-11ee-80c0-000c2961d091;Біла Церква-Шептицького 59 Продукти;Біла Церква вул. Шептицького, 59;44ac8365-749b-11df-b112-00215aee3ebe
b671b33b-4020-11ee-80c0-000c2961d091;Біла Церква-Шолом-Алейхема 94 Продукти;Біла Церква вул. Шолом-Алейхема, 94;44ac8365-749b-11df-b112-00215aee3ebe
fca2d1ce-74b6-11ee-80c1-000c2961d091;Біла Церква-Ярослава Мудрого 40 Сільпо;Біла Церква вул. Ярослава Мудрого, 40;44ac8365-749b-11df-b112-00215aee3ebe
d150c9f2-6432-11ee-80c0-000c2961d091;Білгород-Дністровський-Миколаївська 23/25;Білгород-Дністровський вул. Миколаївська, 23/25;44ac8371-749b-11df-b112-00215aee3ebe
6f37f65b-e83a-11ee-80c5-000c2961d091;Білки-Центральна 62Б;Білки вул. Центральна, 62Б;44ac83af-749b-11df-b112-00215aee3ebe
4445455b-be24-11eb-80d5-000c29800ae7;Білогір'я-Шевченка 59А;Білогір'я вул. Шевченка, 59А;44ac83c5-749b-11df-b112-00215aee3ebe
a8ba6523-74b6-11ee-80c1-000c2961d091;Білогородка-Будівельна 19;Білогородка вул. Будівельна, 19;44ac83cd-749b-11df-b112-00215aee3ebe
9c5e367a-bc1f-11ed-80ed-000c29800ae7;Білогородка-Величка 26;Білогородка вул. Величка, 26;44ac83cd-749b-11df-b112-00215aee3ebe
40567beb-3c25-11ee-80c0-000c2961d091;Білогородка-Величка 41Г;Білогородка вул. Величка, 41Г;44ac83cd-749b-11df-b112-00215aee3ebe
d1a858df-8ff1-11ec-80e4-000c29800ae7;Білогородка-Величка 7;Білогородка вул. Величка, 7;44ac83cd-749b-11df-b112-00215aee3ebe
bbfc98d3-4088-11ec-80e3-000c29800ae7;Біляївка-Миколаївська 25;Біляївка вул. Миколаївська, 25;44ac8415-749b-11df-b112-00215aee3ebe
5aa6fd95-95db-11ee-80c1-000c2961d091;Бірки-Львівська 2;Бірки вул. Львівська, 2;44ac843e-749b-11df-b112-00215aee3ebe
5274a4c2-9e60-11ee-80c4-000c2961d091;Благовіщенське-Гагаріна Юрія 35;Благовіщенське вул. Гагаріна Юрія, 35;8716220f-749b-11df-b112-00215aee3ebe
02f670da-a57c-11ee-80c4-000c2961d091;Благовіщенське-Героїв України 17Б;Благовіщенське вул. Героїв України, 17Б;8716220f-749b-11df-b112-00215aee3ebe
6f6b7309-bf78-11ee-80c4-000c2961d091;Бобринець-Базарна 155;Бобринець вул. Базарна, 155;44ac84b0-749b-11df-b112-00215aee3ebe
6c93c68b-f85d-11eb-80d5-000c29800ae7;Бобринець-Базарна 5;Бобринець вул. Базарна, 5;44ac84b0-749b-11df-b112-00215aee3ebe
90683b57-9ff4-11ee-80c4-000c2961d091;Бобровиця-Незалежності 15Д;Бобровиця вул. Незалежності, 15Д;44ac84be-749b-11df-b112-00215aee3ebe
7701fd5f-052f-11ee-80bd-000c2961d091;Бобровиця-Незалежності 67Б;Бобровиця вул. Незалежності, 67Б;44ac84be-749b-11df-b112-00215aee3ebe
3cacbe5f-3d5f-11ec-80e3-000c29800ae7;Богодухів-Леонова 1;Богодухів вул. Леонова, 1;44ac851b-749b-11df-b112-00215aee3ebe
3ca504ca-32a4-11e8-80d0-1c98ec135261;Богодухів-Незалежності 32;Богодухів пл. Незалежності(Кірова), 32;44ac851b-749b-11df-b112-00215aee3ebe
e6063195-7a76-11eb-80d2-000c29800ae7;Богодухів-Незалежності 4;Богодухів пл. Незалежності(Кірова), 4;44ac851b-749b-11df-b112-00215aee3ebe
8b26ba8b-a79c-11e9-80e4-1c98ec135261;Богородчани-Шевченка 101;Богородчани вул. Шевченка, 101;44ac8524-749b-11df-b112-00215aee3ebe
531e9cdc-34fc-11e9-80dd-1c98ec135261;Богородчани-Шевченка 62;Богородчани вул. Шевченка, 62;44ac8524-749b-11df-b112-00215aee3ebe
b5cb9306-cbed-11ee-80c4-000c2961d091;Богородчани-Шевченка 63;Богородчани вул. Шевченка, 63;44ac8524-749b-11df-b112-00215aee3ebe
64d79fe6-f043-11eb-80d5-000c29800ae7;Богуслав-Івана Франка, 2;Богуслав вул. Франка Івана, 2;44ac852c-749b-11df-b112-00215aee3ebe
a631c535-ba84-11eb-80d4-000c29800ae7;Богуслав-Франка Івана 2 (Експерт);Богуслав вул. Франка Івана, 2;44ac852c-749b-11df-b112-00215aee3ebe
568962cf-a970-11ee-80c4-000c2961d091;Богуслав-Франка Івана 6;Богуслав вул. Франка Івана, 6;44ac852c-749b-11df-b112-00215aee3ebe
f9437950-d6f9-11ee-80c4-000c2961d091;Богуслав-Шевченка 1Б;Богуслав вул. Шевченка, 1Б;44ac852c-749b-11df-b112-00215aee3ebe
e803fe2a-be00-11eb-80d5-000c29800ae7;Богуслав-Шевченка 1Г;Богуслав вул. Шевченка, 1Г;44ac852c-749b-11df-b112-00215aee3ebe
b2f33dba-1e8b-11eb-80cd-000c29800ae7;Болград-Болгарських Ополченців 27;Болград вул. Болгарських Ополченців, 27;44ac855d-749b-11df-b112-00215aee3ebe
2051b800-c812-11ee-80c4-000c2961d091;Болград-Соборна 107;Болград вул. Соборна, 107;44ac855d-749b-11df-b112-00215aee3ebe
a416a3c4-cdbc-11ea-80ca-000c29800ae7;Болехів-24-го Серпня 1;Болехів вул. 24-го Серпня, 1;44ac8560-749b-11df-b112-00215aee3ebe
ae32dd27-b20b-11ea-80c8-000c29800ae7;Борзна-Незалежності 1;Борзна вул. Незалежності, 1;44ac85aa-749b-11df-b112-00215aee3ebe
57850a8f-9dd6-11eb-80d3-000c29800ae7;Борзна-Шевченка 16;Борзна вул. Шевченка, 16;44ac85aa-749b-11df-b112-00215aee3ebe
050a233c-c815-11ee-80c4-000c2961d091;Борзна-Шевченка 16 ЖЖУК;Борзна вул. Шевченка, 16;44ac85aa-749b-11df-b112-00215aee3ebe
554a944c-cc0f-11ee-80c4-000c2961d091;Борислав-Дрогобицька 7;Борислав вул. Дрогобицька, 7;44ac85be-749b-11df-b112-00215aee3ebe
2f732e4d-cbd9-11ee-80c4-000c2961d091;Борислав-Міцкевича Адама 1/4;Борислав вул. Міцкевича Адама, 1/4;44ac85be-749b-11df-b112-00215aee3ebe
9ee6ae5b-b20d-11e9-80e4-1c98ec135261;Бориспіль;Бориспіль вул. Привокзальна, 21;44ac85c0-749b-11df-b112-00215aee3ebe
02c32dff-7baf-11e9-80e0-1c98ec135261;Бориспіль-Бандери 1 ОККО;Бориспіль вул. Бандери (Лютнева), 1;44ac85c0-749b-11df-b112-00215aee3ebe
f32b987e-bb79-11ee-80c4-000c2961d091;Бориспіль-Бежівка 3 Дорадо;Бориспіль вул. Бежівка, 3;44ac85c0-749b-11df-b112-00215aee3ebe
3c091b29-3518-11ee-80bd-000c2961d091;Бориспіль-Головатого 71А Rozetka;Бориспіль вул. Головатого, 71А;44ac85c0-749b-11df-b112-00215aee3ebe
97cb95d5-bb7b-11ee-80c4-000c2961d091;Бориспіль-Головатого 89 Дорадо;Бориспіль вул. Головатого, 89;44ac85c0-749b-11df-b112-00215aee3ebe
0a7a3f97-d793-11ee-80c4-000c2961d091;Бориспіль-Європейська 10 Біле Сухе;Бориспіль вул. Європейська, 10;44ac85c0-749b-11df-b112-00215aee3ebe
fecfcc9f-bb7b-11ee-80c4-000c2961d091;Бориспіль-Камінського Сергія 51А Катрін;Бориспіль вул. Камінського Сергія (кол. Комуністична), 51А;44ac85c0-749b-11df-b112-00215aee3ebe
ed06dfcd-cfc4-11ed-80ed-000c29800ae7;Бориспіль-Київський Шлях 127Б Раки Love;Бориспіль вул. Київський Шлях, 127Б;44ac85c0-749b-11df-b112-00215aee3ebe
1ace4174-0e91-11ee-80bd-000c2961d091;Бориспіль-Київський Шлях 17 Біле Сухе;Бориспіль вул. Київський Шлях, 17;44ac85c0-749b-11df-b112-00215aee3ebe
55db22e1-bb7a-11ee-80c4-000c2961d091;Бориспіль-Київський Шлях 2/2 Дорадо;Бориспіль вул. Київський Шлях, 2/2;44ac85c0-749b-11df-b112-00215aee3ebe
41352941-9b61-11ee-80c1-000c2961d091;Бориспіль-Київський Шлях 2/6 Lifecell;Бориспіль вул. Київський Шлях, 2/6;44ac85c0-749b-11df-b112-00215aee3ebe
9c56c750-cfc4-11ed-80ed-000c29800ae7;Бориспіль-Київський Шлях 2/8 Fish.Co;Бориспіль вул. Київський Шлях, 2/8;44ac85c0-749b-11df-b112-00215aee3ebe
9adf7144-bd61-11eb-80d4-000c29800ae7;Бориспіль-Київський Шлях 2Б АЗС KLO;Бориспіль вул. Київський Шлях, 2Б;44ac85c0-749b-11df-b112-00215aee3ebe
e4dbb135-4577-11ed-80eb-000c29800ae7;Бориспіль-Київський Шлях 4 Rozetka;Бориспіль вул. Київський Шлях, 4;44ac85c0-749b-11df-b112-00215aee3ebe
6dc33dbd-0e91-11ee-80bd-000c2961d091;Бориспіль-Київський Шлях 47 Біле Сухе;Бориспіль вул. Київський Шлях, 47;44ac85c0-749b-11df-b112-00215aee3ebe
d03e7c0f-2490-11ee-80bd-000c2961d091;Бориспіль-Київський Шлях 6 Сільпо;Бориспіль вул. Київський Шлях, 6;44ac85c0-749b-11df-b112-00215aee3ebe
fed63ff6-f1e9-11ec-80e7-000c29800ae7;Бориспіль-Київський Шлях 67 Rozetka;Бориспіль вул. Київський Шлях, 67;44ac85c0-749b-11df-b112-00215aee3ebe
27942712-2491-11ee-80bd-000c2961d091;Бориспіль-Київський Шлях 67 Сільпо;Бориспіль вул. Київський Шлях, 67;44ac85c0-749b-11df-b112-00215aee3ebe
7ae8628f-2491-11ee-80bd-000c2961d091;Бориспіль-Київський Шлях 76 Сільпо;Бориспіль вул. Київський Шлях, 76;44ac85c0-749b-11df-b112-00215aee3ebe
dbfac1c5-bb7a-11ee-80c4-000c2961d091;Бориспіль-Польова 66А Філін;Бориспіль вул. Польова, 66А;44ac85c0-749b-11df-b112-00215aee3ebe
24edb259-d471-11ed-80ed-000c29800ae7;Бориспіль-Привокзальна 1Б Престиж;Бориспіль вул. Привокзальна, 1Б;44ac85c0-749b-11df-b112-00215aee3ebe
19a53b65-e619-11eb-80d5-000c29800ae7;Бориспіль-Привокзальна 88 АЗС KLO;Бориспіль вул. Привокзальна, 88;44ac85c0-749b-11df-b112-00215aee3ebe
53a1f282-8de1-11ee-80c1-000c2961d091;Борова-Київська 66;Борова вул. Київська, 66;44ac85d0-749b-11df-b112-00215aee3ebe
b63d1c69-f553-11ed-80ed-000c29800ae7;Бородянка-Паркова 1В;Бородянка вул. Паркова, 1В;44ac85eb-749b-11df-b112-00215aee3ebe
5edde917-4647-11e9-80dd-1c98ec135261;Бородянка-Семашка,157-А;Бородянка вул. Семашка, 157А;44ac85eb-749b-11df-b112-00215aee3ebe
5d88d11a-3372-11ed-80e8-000c29800ae7;Бородянка-Центральна 266;Бородянка вул. Центральна, 266;44ac85eb-749b-11df-b112-00215aee3ebe
11df0fdf-7f05-11ee-80c1-000c2961d091;Бортничі-Дяченко 20Б Кавярня;Бортничі вул. Дяченко, 20Б;44ac8603-749b-11df-b112-00215aee3ebe
8f13ee81-7f04-11ee-80c1-000c2961d091;Бортничі-Дяченко 8 Кавярня;Бортничі вул. Дяченко, 8;44ac8603-749b-11df-b112-00215aee3ebe
98341edc-8af5-11eb-80d2-000c29800ae7;Бортничі-Харченко Євгена 47А AUTO SHOP;Бортничі вул. Харченко Євгена, 47А;44ac8603-749b-11df-b112-00215aee3ebe
fc8ed15f-d2e6-11e9-80e4-1c98ec135261;Борщів-Бандери Степана 146 Б;Борщів вул. Бандери Степана, 146Б;44ac8610-749b-11df-b112-00215aee3ebe
4b4e90a7-05d2-11ee-80bd-000c2961d091;Борщів-Грушевського 9А;Борщів вул. Грушевського, 9А;44ac8610-749b-11df-b112-00215aee3ebe
1712df2d-5c58-11ee-80c0-000c2961d091;Борщів-Кондри 19;Борщів вул. Кондри, 19;44ac8610-749b-11df-b112-00215aee3ebe
eb777853-d2ea-11e9-80e4-1c98ec135261;Борщів-Кондри 6;Борщів вул. Кондри, 6;44ac8610-749b-11df-b112-00215aee3ebe
c6f0e1f2-4ae0-11ed-80eb-000c29800ae7;Борщів-Крушельницької 2А;Борщів вул. Крушельницької, 2А;44ac8610-749b-11df-b112-00215aee3ebe
4cda8df8-c814-11ee-80c4-000c2961d091;Борщів-Мазепи Гетьмана 6/14;Борщів вул. Мазепи Гетьмана, 6/14;44ac8610-749b-11df-b112-00215aee3ebe
c885fcd5-887c-11ee-80c1-000c2961d091;Борщів-Шевченка 42;Борщів вул. Шевченка, 42;44ac8610-749b-11df-b112-00215aee3ebe
30668b1a-81af-11eb-80d2-000c29800ae7;Боярка-Білогородська 25 Ярослав;Боярка вул. Білогородська, 25;44ac8642-749b-11df-b112-00215aee3ebe
35ed2e6c-f3dd-11ed-80ed-000c29800ae7;Боярка-Білогородська 27 Rozetka;Боярка вул. Білогородська, 27;44ac8642-749b-11df-b112-00215aee3ebe
f18ea2e6-3e42-11ed-80e9-000c29800ae7;Боярка-Білогородська 51 Сокол;Боярка вул. Білогородська, 51;44ac8642-749b-11df-b112-00215aee3ebe
f0b01d4d-2491-11ee-80bd-000c2961d091;Боярка-Магістральна 40 Сільпо;Боярка вул. Магістральна, 40;44ac8642-749b-11df-b112-00215aee3ebe
7b97c3e2-f1ea-11ec-80e7-000c29800ae7;Боярка-Садова 4Б Rozetka;Боярка вул. Садова, 4Б;44ac8642-749b-11df-b112-00215aee3ebe
ddf31be5-ece3-11ee-80c5-000c2961d091;Бровари-Героїв Упа 7А;Бровари вул. Героїв Упа (Кирпоноса), 7А;0f3cdf53-efbc-11de-9277-00215aee3ebe
aa8fd342-b912-11ee-80c4-000c2961d091;Бровари-Грушевського 9/1;Бровари вул. Грушевського, 9/1;0f3cdf53-efbc-11de-9277-00215aee3ebe
83c93eed-8d60-11ee-80c1-000c2961d091;Бровари-Ірини Цвілої 1;Бровари вул. Ірини Цвілої, 1;0f3cdf53-efbc-11de-9277-00215aee3ebe
0bf1a5fe-4579-11ed-80eb-000c29800ae7;Бровари-Київська 150А;Бровари вул. Київська, 150А;0f3cdf53-efbc-11de-9277-00215aee3ebe
5b00def3-2492-11ee-80bd-000c2961d091;Бровари-Київська 156;Бровари вул. Київська, 156;0f3cdf53-efbc-11de-9277-00215aee3ebe
48dd3d4a-6f5c-11ee-80c1-000c2961d091;Бровари-Київська 157;Бровари вул. Київська, 157;0f3cdf53-efbc-11de-9277-00215aee3ebe
28fe8094-bd62-11eb-80d4-000c29800ae7;Бровари-Київська 227;Бровари вул. Київська, 227;0f3cdf53-efbc-11de-9277-00215aee3ebe
320c136e-860c-11e9-80e0-1c98ec135261;Бровари-Київська 231;Бровари вул. Київська, 231;0f3cdf53-efbc-11de-9277-00215aee3ebe
9a29e866-6374-11ee-80c0-000c2961d091;Бровари-Київська 239;Бровари вул. Київська, 239;0f3cdf53-efbc-11de-9277-00215aee3ebe
ef9d0621-f1ea-11ec-80e7-000c29800ae7;Бровари-Київська 239 Rozetka;Бровари вул. Київська, 239;0f3cdf53-efbc-11de-9277-00215aee3ebe
ddaa3232-2492-11ee-80bd-000c2961d091;Бровари-Київська 241;Бровари вул. Київська, 241;0f3cdf53-efbc-11de-9277-00215aee3ebe
a473a14c-0e6a-11ee-80bd-000c2961d091;Бровари-Київська 245;Бровари вул. Київська, 245;0f3cdf53-efbc-11de-9277-00215aee3ebe
3cb46df3-f65d-11e9-80e7-1c98ec135261;Бровари-Київська 247;Бровари вул. Київська, 247;0f3cdf53-efbc-11de-9277-00215aee3ebe
905d5961-bcd4-11ed-80ed-000c29800ae7;Бровари-Київська 253;Бровари вул. Київська, 253;0f3cdf53-efbc-11de-9277-00215aee3ebe
82910f45-e619-11eb-80d5-000c29800ae7;Бровари-Київська 2А;Бровари вул. Київська, 2А;0f3cdf53-efbc-11de-9277-00215aee3ebe
f6db14fd-ffac-11ed-80bd-000c2961d091;Бровари-Москаленка Сергія 49;Бровари вул. Москаленка Сергія (Красовського), 49;0f3cdf53-efbc-11de-9277-00215aee3ebe
63a9fc17-f611-11ed-80ed-000c29800ae7;Бровари-Незалежності 13;Бровари бул. Незалежності, 13;0f3cdf53-efbc-11de-9277-00215aee3ebe
b7ba48b2-6f62-11ee-80c1-000c2961d091;Бровари-Незалежності 13 One Price;Бровари вул. Незалежності, 13;0f3cdf53-efbc-11de-9277-00215aee3ebe
c216e7f0-0e91-11ee-80bd-000c2961d091;Бровари-Олімпійська 4;Бровари вул. Олімпійська, 4;0f3cdf53-efbc-11de-9277-00215aee3ebe
06ad36de-20b5-11e9-80dc-1c98ec135261;Бровари-Оникієнка Олега 127/1;Бровари вул. Олега Онікієнка (Кутузова), 127/1;0f3cdf53-efbc-11de-9277-00215aee3ebe
3ca4be93-20b4-11e9-80dc-1c98ec135261;Бровари-Переяславський шлях 131;Бровари вул. Переяславський шлях (Осипова), 131;0f3cdf53-efbc-11de-9277-00215aee3ebe
f28a6b90-b77f-11ed-80ed-000c29800ae7;Бровари-Петлюри 23В;Бровари вул. Петлюри, 23В;0f3cdf53-efbc-11de-9277-00215aee3ebe
299b704d-0130-11ee-80bd-000c2961d091;Бровари-Петлюри Симона 22;Бровари вул. Петлюри Симона (Черняховського), 22;0f3cdf53-efbc-11de-9277-00215aee3ebe
3bebbd0d-3a70-11ed-80e9-000c29800ae7;Бровари-Симоненка 111;Бровари вул. Симоненка, 111;0f3cdf53-efbc-11de-9277-00215aee3ebe
d5df92f4-d183-11ee-80c4-000c2961d091;Бровари-Симоненка 117 зоомагазин;Бровари вул. Симоненка, 117;0f3cdf53-efbc-11de-9277-00215aee3ebe
aec83df6-c047-11ee-80c4-000c2961d091;Бровари-Симоненка 2;Бровари вул. Симоненка, 2;0f3cdf53-efbc-11de-9277-00215aee3ebe
50ad4712-95f7-11eb-80d3-000c29800ae7;Бровари-Чорновола 6 А;Бровари вул. Чорновола, 6А;0f3cdf53-efbc-11de-9277-00215aee3ebe
bd4f7589-14cf-11ee-80bd-000c2961d091;Бровари-Чорновола, 2;Бровари вул. Чорновола, 2;0f3cdf53-efbc-11de-9277-00215aee3ebe
4939d0e9-983f-11ee-80c1-000c2961d091;Бровари-Шевченка 14;Бровари вул. Шевченка, 14;0f3cdf53-efbc-11de-9277-00215aee3ebe
dd80d57b-388e-11ec-80e3-000c29800ae7;Броди;Броди вул. Юридика, 17;44ac86bb-749b-11df-b112-00215aee3ebe
9cf53604-2edb-11e9-80dd-1c98ec135261;Броди-Березова 1А ОККО;Броди вул. Березова, 1А;44ac86bb-749b-11df-b112-00215aee3ebe
b3849ea3-2305-11e9-80dc-1c98ec135261;Броди-Великі Фільварки 101 ОККО;Броди вул. Великі Фільварки, 101;44ac86bb-749b-11df-b112-00215aee3ebe
d73d8df4-b1ea-11ed-80ec-000c29800ae7;Броди-Залізнична 53 Візовий центр;Броди вул. Залізнична, 53;44ac86bb-749b-11df-b112-00215aee3ebe
3069a04a-0683-11e8-80cd-1c98ec135261;Броди-Золота 6 Жжук;Броди вул. Золота, 6;44ac86bb-749b-11df-b112-00215aee3ebe
dee2f18e-68d3-11ee-80c1-000c2961d091;Броди-Стуса Василя 8 Rozetka;Броди вул. Стуса Василя, 8;44ac86bb-749b-11df-b112-00215aee3ebe
51dc3b02-7d43-11e9-80e0-1c98ec135261;Брошнів-Осада-22-го Січня 87;Брошнів-Осада вул. 22-го Січня, 87;44ac86d4-749b-11df-b112-00215aee3ebe
9409afd1-989a-11eb-80d3-000c29800ae7;Брусилів-Митрополита Іларіона 34/2;Брусилів пров. Митрополита Іларіона, 34/2;44ac86d8-749b-11df-b112-00215aee3ebe
0e62b650-8ac2-11ee-80c1-000c2961d091;Брусилів-Митрополита Іларіона 35;Брусилів пров. Митрополита Іларіона, 35;44ac86d8-749b-11df-b112-00215aee3ebe
63c6cf48-5a16-11ed-80eb-000c29800ae7;Брюховичі-Весняна 1а;Брюховичі вул. Весняна, 1а;44ac86e4-749b-11df-b112-00215aee3ebe
8b10ff33-773f-11ee-80c1-000c2961d091;Брюховичі-Львівська 1Б;Брюховичі вул. Львівська, 1Б;44ac86e4-749b-11df-b112-00215aee3ebe
89772c28-c47c-11eb-80d5-000c29800ae7;Буди-Центральна 1А;Буди пл. Центральна, 1А;44ac8728-749b-11df-b112-00215aee3ebe
111aa419-4a05-11ed-80eb-000c29800ae7;Буринь-Незалежності 4;Буринь вул. Незалежності, 4;44ac87b5-749b-11df-b112-00215aee3ebe
188a215b-5c56-11ee-80c0-000c2961d091;Бурлача Балка-Шкільна 81;Бурлача Балка вул. Шкільна, 81;44ac87c7-749b-11df-b112-00215aee3ebe
9733f99d-a798-11e9-80e4-1c98ec135261;Бурштин-Бандери Степана 38 А;Бурштин вул. Бандери Степана, 38А;44ac87d7-749b-11df-b112-00215aee3ebe
0ff1cb6c-77fa-11ee-80c1-000c2961d091;Бурштин-Січових Стрільців 29А;Бурштин вул. Січових Стрільців, 29А;44ac87d7-749b-11df-b112-00215aee3ebe
d88440d7-ae39-11ed-80ec-000c29800ae7;Буськ-Львівська 13;Буськ вул. Львівська, 13;44ac87e5-749b-11df-b112-00215aee3ebe
5fa69724-1721-11ee-80bd-000c2961d091;Буча-Вокзальна 71В;Буча вул. Вокзальна, 71В;44ac8811-749b-11df-b112-00215aee3ebe
0bf21c5d-b1e5-11ed-80ec-000c29800ae7;Буча-Вокзальна 76А;Буча вул. Вокзальна, 76А;44ac8811-749b-11df-b112-00215aee3ebe
5ea229c5-486e-11ec-80e3-000c29800ae7;Буча-Депутатська 1В;Буча вул. Депутатська, 1В;44ac8811-749b-11df-b112-00215aee3ebe
c902744e-ab12-11ee-80c4-000c2961d091;Буча-Жовтнева 17;Буча вул. Левка Лук'яненка (Жовтнева), 17;44ac8811-749b-11df-b112-00215aee3ebe
98e01577-595d-11ee-80c0-000c2961d091;Буча-Жовтнева 66А;Буча вул. Левка Лук'яненка (Жовтнева), 66А;44ac8811-749b-11df-b112-00215aee3ebe
0ac0fd22-d344-11eb-80d5-000c29800ae7;Буча-Кожедуба 3;Буча вул. Кожедуба, 3;44ac8811-749b-11df-b112-00215aee3ebe
9d4ab0a0-2495-11ee-80bd-000c2961d091;Буча-Леоніда Бірюкова 2;Буча бул. Леоніда Бірюкова, 2;44ac8811-749b-11df-b112-00215aee3ebe
a97065cc-f1eb-11ec-80e7-000c29800ae7;Буча-Нове 52;Буча шосе Нове, 52;44ac8811-749b-11df-b112-00215aee3ebe
3dc0ab4b-7bdc-11ed-80ec-000c29800ae7;Буча-Нове 8А (Розетка);Буча шосе Нове, 8А;44ac8811-749b-11df-b112-00215aee3ebe
eb8a1f02-15c8-11ee-80bd-000c2961d091;Буча-Тарасівська 2Г;Буча вул. Тарасівська, 2Г;44ac8811-749b-11df-b112-00215aee3ebe
1a457ca7-4849-11ec-80e3-000c29800ae7;Буча-Українки Лесі 12;Буча вул. Українки Лесі, 12;44ac8811-749b-11df-b112-00215aee3ebe
8706ffeb-2dc8-11ed-80e7-000c29800ae7;Буча-Українки Лесі 3;Буча вул. Українки Лесі, 3;44ac8811-749b-11df-b112-00215aee3ebe
59eff378-f48d-11ed-80ed-000c29800ae7;Буча-Хмельницького 6/260;Буча вул. Хмельницького, 6/260;44ac8811-749b-11df-b112-00215aee3ebe
f332d9da-0c48-11ee-80bd-000c2961d091;Бучач-Галицька 10Д;Бучач вул. Галицька, 10Д;44ac8815-749b-11df-b112-00215aee3ebe
ae69b33e-a7a1-11e9-80e4-1c98ec135261;Бучач-Галицька 157;Бучач вул. Галицька, 157;44ac8815-749b-11df-b112-00215aee3ebe
31d75d4e-fbce-11ed-80ed-000c29800ae7;Бучач-Галицька 46;Бучач вул. Галицька, 46;44ac8815-749b-11df-b112-00215aee3ebe
6d2599b4-f87f-11eb-80d5-000c29800ae7;Бучач-Українки Лесі 1Б;Бучач вул. Українки Лесі, 1Б;44ac8815-749b-11df-b112-00215aee3ebe
79405dab-5864-11ee-80c0-000c2961d091;Буча-Шевченка 48Б;Буча вул. Шевченка, 48Б;44ac8811-749b-11df-b112-00215aee3ebe
c7485723-ccc0-11ee-80c4-000c2961d091;Буштино-Головна 78;Буштино вул. Головна, 78;44ac8822-749b-11df-b112-00215aee3ebe
e32fd8e0-f8f7-11eb-80d5-000c29800ae7;Валки-Героїв Чорнобиля 2А Жжук;Валки Просп. Героїв Чорнобиля (Радянської Армії), 2А;44ac8846-749b-11df-b112-00215aee3ebe
a4d78dde-cc2c-11ea-80ca-000c29800ae7;Валки-Харківська 8;Валки пров. Харківська, 8;44ac8846-749b-11df-b112-00215aee3ebe
152d8f08-cc2b-11ea-80ca-000c29800ae7;Валки-Центральна 3;Валки пл. Центральна, 3;44ac8846-749b-11df-b112-00215aee3ebe
b5d4e046-3d69-11ec-80e3-000c29800ae7;Валки-Центральна 4;Валки пл. Центральна, 4;44ac8846-749b-11df-b112-00215aee3ebe
34d1106b-988f-11eb-80d3-000c29800ae7;Вапнярка-Незалежності 142 А;Вапнярка вул. Незалежності, 142А;44ac885a-749b-11df-b112-00215aee3ebe
2abb5264-2500-11eb-80cd-000c29800ae7;Вапнярка-Привокзальна 16/32;Вапнярка вул. Привокзальна, 16/32;44ac885a-749b-11df-b112-00215aee3ebe
4571228d-60f3-11e9-80df-1c98ec135261;Вараш (Кузнецовськ)-Вараш 11;Вараш вул. Вараш, 11;62c3cefe-749b-11df-b112-00215aee3ebe
0691fca8-e31f-11eb-80d5-000c29800ae7;Вараш-Енергетиків 19;Вараш вул. Енергетиків, 19;62c3cefe-749b-11df-b112-00215aee3ebe
a864fd06-083a-11ed-80e7-000c29800ae7;Вараш-Незалежності 10;Вараш Майдан Незалежності, 10;62c3cefe-749b-11df-b112-00215aee3ebe
9248ee7e-efec-11ed-80ed-000c29800ae7;Вараш-Незалежності 13 Rozetka;Вараш Майдан Незалежності, 13;62c3cefe-749b-11df-b112-00215aee3ebe
81f3b317-78b3-11ea-80c6-000c29800ae7;Вараш-Ринкова 1;Вараш вул. Ринкова, 1;62c3cefe-749b-11df-b112-00215aee3ebe
87ecc043-b365-11e9-80e4-1c98ec135261;Вараш-Теплична 6;Вараш вул. Теплична, 6;62c3cefe-749b-11df-b112-00215aee3ebe
dc9a97ef-40bf-11e9-80dd-1c98ec135261;Варва-Шевченка 35;Варва вул. Шевченка, 35;44ac885e-749b-11df-b112-00215aee3ebe
04728581-d923-11ed-80ed-000c29800ae7;Васильків-Володимирська 42А;Васильків вул. Володимирська, 42А;4aba379d-749b-11df-b112-00215aee3ebe
ec6d543f-2df0-11ed-80e7-000c29800ae7;Васильків-Грушевського 13;Васильків вул. Грушевського, 13;4aba379d-749b-11df-b112-00215aee3ebe
01561123-74b9-11ee-80c1-000c2961d091;Васильків-Грушевського 19;Васильків вул. Грушевського, 19;4aba379d-749b-11df-b112-00215aee3ebe
e9f67683-893b-11ee-80c1-000c2961d091;Васильків-Декабристів 141;Васильків вул. Декабристів, 141;4aba379d-749b-11df-b112-00215aee3ebe
0c9116e3-1b19-11ee-80bd-000c2961d091;Васильків-Декабристів 186;Васильків вул. Декабристів, 186;4aba379d-749b-11df-b112-00215aee3ebe
02dfff1a-0521-11ee-80bd-000c2961d091;Васильків-Декабристів 45;Васильків вул. Декабристів, 45;4aba379d-749b-11df-b112-00215aee3ebe
c1800468-2316-11e9-80dc-1c98ec135261;Васильків-Робкорівська 1 А;Васильків вул. Робкорівська, 1А;4aba379d-749b-11df-b112-00215aee3ebe
53db7dae-74b8-11ee-80c1-000c2961d091;Васильків-Соборна 64/1;Васильків вул. Соборна, 64/1;4aba379d-749b-11df-b112-00215aee3ebe
fab8d89b-0521-11ee-80bd-000c2961d091;Васильків-Соборна 70А;Васильків вул. Соборна, 70А;4aba379d-749b-11df-b112-00215aee3ebe
25a0bd13-8de4-11ee-80c1-000c2961d091;Васильків-Тракторна 9;Васильків вул. Каштанова (Тракторна), 9;4aba379d-749b-11df-b112-00215aee3ebe
9f40d847-4e37-11ee-80c0-000c2961d091;Васищеве-Орешкова 1;Васищеве вул. Орешкова, 1;4aba37ae-749b-11df-b112-00215aee3ebe
157dc737-ed9b-11ed-80ed-000c29800ae7;Ватутіне-Ватутіна 13А;Багачеве (Ватутіне) Просп. Незалежності, 13А;4aba37c5-749b-11df-b112-00215aee3ebe
21268d68-7afc-11e9-80e0-1c98ec135261;Ватутіне-Ватутіна 25/1;Багачеве (Ватутіне) Просп. Незалежності, 25/1;4aba37c5-749b-11df-b112-00215aee3ebe
cf99c0eb-b153-11ee-80c4-000c2961d091;Ватутіне-Ювілейна 1;Багачеве (Ватутіне) вул. Ювілейна, 1;4aba37c5-749b-11df-b112-00215aee3ebe
55f7d853-200e-11ea-80e7-1c98ec135261;Вашківці-Соборна 24/1;Вашківці вул. Соборна, 24/1;4aba37ce-749b-11df-b112-00215aee3ebe
c2fe2d51-878d-11ee-80c1-000c2961d091;Велика Березовиця-Енергетична 21А;Велика Березовиця вул. Енергетична, 21А;4aba37ec-749b-11df-b112-00215aee3ebe
d91e5293-9933-11eb-80d3-000c29800ae7;Великий Березний-Шевченка 36/5;Великий Березний вул. Шевченка, 36/5;4aba388c-749b-11df-b112-00215aee3ebe
52c86f7b-7843-11eb-80d1-000c29800ae7;Великий Бичків-Грушевського 107;Великий Бичків вул. Грушевського, 107;4aba388d-749b-11df-b112-00215aee3ebe
21eaeb22-d339-11eb-80d5-000c29800ae7;Великий Глибочок-Грушевського 1;Великий Глибочок вул. Грушевського, 1;4aba3895-749b-11df-b112-00215aee3ebe
f0613229-d013-11ea-80ca-000c29800ae7;Великий Кучурів-Головна 76 Б;Великий Кучурів вул. Головна, 76Б;4aba38a8-749b-11df-b112-00215aee3ebe
2e6a5772-b360-11e9-80e4-1c98ec135261;Великий Олексин-Рівненьська 1;Великий Олексин вул. Рівненьська, 1;4aba38bb-749b-11df-b112-00215aee3ebe
29320ccf-20a0-11e9-80dc-1c98ec135261;Великі Мости-Львівська 1 Б;Великі Мости вул. Львівська, 1Б;4aba390c-749b-11df-b112-00215aee3ebe
19bacbb3-912f-11ee-80c1-000c2961d091;Великі Мости-Львівська 57;Великі Мости вул. Львівська, 57;4aba390c-749b-11df-b112-00215aee3ebe
53e74e36-f04e-11eb-80d5-000c29800ae7;Великодолинське-Центральна 101Б;Великодолинське вул. Центральна, 101Б;4aba3922-749b-11df-b112-00215aee3ebe
93e051d7-21e3-11ec-80df-000c29800ae7;Верхівцеве-Центральна 67;Верхівцеве вул. Центральна, 67;4aba39dd-749b-11df-b112-00215aee3ebe
0c0c4f6c-0517-11ee-80bd-000c2961d091;Верхній Коропець-Мукачівська 1В;Верхній Коропець вул. Мукачівська, 1В;4aba39f8-749b-11df-b112-00215aee3ebe
7be76485-64c6-11ec-80e4-000c29800ae7;Верхньодніпровськ-Театральна 24;Верхньодніпровськ вул. Театральна, 24;4aba3a32-749b-11df-b112-00215aee3ebe
91f02cd4-9896-11eb-80d3-000c29800ae7;Верхньодніпровськ-Шевченка 12/26;Верхньодніпровськ Просп. Шевченка, 12/26;4aba3a32-749b-11df-b112-00215aee3ebe
9509ef41-3cea-11ee-80c0-000c2961d091;Верхньодніпровськ-Шевченка 4;Верхньодніпровськ вул. Шевченка, 4;4aba3a32-749b-11df-b112-00215aee3ebe
e5cb306f-bed7-11eb-80d5-000c29800ae7;Верховина-Грушевського Михайла 1Д;Верховина вул. Грушевського Михайла, 1Д;4aba3a41-749b-11df-b112-00215aee3ebe
726eeebf-8877-11ee-80c1-000c2961d091;Вижниця-Чорновола 2;Вижниця вул. Чорновола, 2;4aba3b33-749b-11df-b112-00215aee3ebe
fd3059c6-b35e-11e9-80e4-1c98ec135261;Вилок-Ракоці 59;Вилок вул. Ракоці, 59;4aba3b44-749b-11df-b112-00215aee3ebe
05dc2ff7-d53a-11ed-80ed-000c29800ae7;Винники-Галицька 27;Винники вул. Галицька, 27;4aba3b4d-749b-11df-b112-00215aee3ebe
2a33c25f-0466-11ea-80e7-1c98ec135261;Винники-Галицька 3 Б;Винники вул. Галицька, 3Б;4aba3b4d-749b-11df-b112-00215aee3ebe
688e0fdb-f0a4-11ed-80ed-000c29800ae7;Винники-Івасюка 42В Rozetka;Винники вул. Івасюка, 42А;4aba3b4d-749b-11df-b112-00215aee3ebe
1d187aef-94d8-11e8-80d4-1c98ec135261;Виноградів;Виноградів вул. Вакарова, 18;4aba3b55-749b-11df-b112-00215aee3ebe
79172176-853e-11ee-80c1-000c2961d091;Виноградів-Комунальна 1;Виноградів вул. Комунальна, 1;4aba3b55-749b-11df-b112-00215aee3ebe
3ff8074b-b35e-11e9-80e4-1c98ec135261;Виноградів-Комунальна 9;Виноградів вул. Комунальна, 9;4aba3b55-749b-11df-b112-00215aee3ebe
7d73b842-f978-11ed-80ed-000c29800ae7;Виноградів-Миру 14;Виноградів вул. Миру, 14;4aba3b55-749b-11df-b112-00215aee3ebe
a18777e4-f570-11e9-80e7-1c98ec135261;Виноградів-Миру 43;Виноградів вул. Миру, 43;4aba3b55-749b-11df-b112-00215aee3ebe
0d71b437-6dac-11ee-80c1-000c2961d091;Виноградів-Станційна 1;Виноградів вул. Станційна, 1;4aba3b55-749b-11df-b112-00215aee3ebe
ea7c84ec-7fc8-11ee-80c1-000c2961d091;Високий-Височанський шлях 6;Високий вул. Височанський шлях, 6;4aba3bb5-749b-11df-b112-00215aee3ebe
efc102a9-87a3-11ee-80c1-000c2961d091;Вишгород-Квітковий 2Г;Вишгород пров. Квітковий, 2Г;4aba3be3-749b-11df-b112-00215aee3ebe
cb4e47ab-a4c3-11ee-80c4-000c2961d091;Вишгород-Кургузова 11В;Вишгород вул. Кургузова, 11В;4aba3be3-749b-11df-b112-00215aee3ebe
3b5d946e-a4c1-11ee-80c4-000c2961d091;Вишгород-Кургузова 1А;Вишгород вул. Кургузова, 1А;4aba3be3-749b-11df-b112-00215aee3ebe
072dc173-2496-11ee-80bd-000c2961d091;Вишгород-Набережна 11;Вишгород вул. Набережна, 11;4aba3be3-749b-11df-b112-00215aee3ebe
deaf9391-43b2-11ec-80e3-000c29800ae7;Вишгород-Шевченка 11 (Біле Сухе);Вишгород Просп. Шевченка, 11;4aba3be3-749b-11df-b112-00215aee3ebe
ee3c373d-f1ec-11ec-80e7-000c29800ae7;Вишгород-Шевченка 2Г;Вишгород Просп. Шевченка, 2Г;4aba3be3-749b-11df-b112-00215aee3ebe
944ab777-f041-11eb-80d5-000c29800ae7;Вишгород-Шолуденка 1;Вишгород вул. Шолуденка, 1;4aba3be3-749b-11df-b112-00215aee3ebe
292948b9-1f1d-11ee-80bd-000c2961d091;Вишгород-Шолуденка 13Б;Вишгород вул. Шолуденка, 13Б;4aba3be3-749b-11df-b112-00215aee3ebe
eff60487-71ab-11ee-80c1-000c2961d091;Вишеньки-Короленка 24Б;Вишеньки вул. Короленка, 24Б;4aba3bef-749b-11df-b112-00215aee3ebe
1523064d-774c-11ee-80c1-000c2961d091;Вишеньки-Центральна 150Б;Вишеньки вул. Центральна, 150Б;4aba3bef-749b-11df-b112-00215aee3ebe
64000cbb-16e7-11ec-80df-000c29800ae7;Вишневе-Вітянська 2Б Біле Сухе;Вишневе вул. Вітянська, 2Б;73e94c1f-ddc1-11df-9197-00215aee3ebe
4a8f6aba-cb32-11ee-80c4-000c2961d091;Вишневе-Європейська 30 Сільпо;Вишневе вул. Європейська, 30;73e94c1f-ddc1-11df-9197-00215aee3ebe
03050b2d-c755-11ee-80c4-000c2961d091;Вишневе-Європейська 32/1Б;Вишневе вул. Європейська, 32/1Б;73e94c1f-ddc1-11df-9197-00215aee3ebe
416381e2-b6eb-11eb-80d4-000c29800ae7;Вишневе-Європейська 35 Кавярня;Вишневе вул. Європейська, 35;73e94c1f-ddc1-11df-9197-00215aee3ebe
041f15b6-c98d-11ee-80c4-000c2961d091;Вишневе-Кузьми Скрябіна 29;Вишневе вул. Кузьми Скрябіна (Ватутіна), 29;73e94c1f-ddc1-11df-9197-00215aee3ebe
037ec952-0e93-11ee-80bd-000c2961d091;Вишневе-Марії Приймаченко 22/27 Біле Сухе;Вишневе вул. Марії Приймаченко ( Першотравнева ), 22/27;73e94c1f-ddc1-11df-9197-00215aee3ebe
1f84c73a-f1ec-11ec-80e7-000c29800ae7;Вишневе-Молодіжна/Піонерська 32А Rozetka;Вишневе вул. Молодіжна/Піонерська, 32А;73e94c1f-ddc1-11df-9197-00215aee3ebe
0c88b5f2-1673-11ee-80bd-000c2961d091;Вишневе-Освіти 4 Rozetka;Вишневе вул. Освіти, 4;73e94c1f-ddc1-11df-9197-00215aee3ebe
3f90a40f-9c4b-11eb-80d3-000c29800ae7;Вишневе-Святошинська 38 Мобільних Аксесуарів;Вишневе вул. Святошинська, 38;73e94c1f-ddc1-11df-9197-00215aee3ebe
842660d5-c548-11eb-80d5-000c29800ae7;Вишневе-Святошинська 42 Продукти;Вишневе вул. Святошинська, 42;73e94c1f-ddc1-11df-9197-00215aee3ebe
06e524ba-eda2-11ed-80ed-000c29800ae7;Вишневе-Святоюріївська 10/2 Київстар;Вишневе вул. Святоюріївська, 10/2;73e94c1f-ddc1-11df-9197-00215aee3ebe
05f2f1ce-29ff-11ee-80bd-000c2961d091;Вишневе-Українки Лесі 74 Біле Сухе;Вишневе вул. Українки Лесі, 74;73e94c1f-ddc1-11df-9197-00215aee3ebe
7156f51a-f1ec-11ec-80e7-000c29800ae7;Вишневе-Українки Лесі 74А Rozetka;Вишневе вул. Українки Лесі, 74А;73e94c1f-ddc1-11df-9197-00215aee3ebe
45b6f894-82e9-11e9-80e0-1c98ec135261;Вишневе-Чорновола В'ячеслава 46В ОККО;Вишневе вул. Чорновола В'ячеслава, 46В;73e94c1f-ddc1-11df-9197-00215aee3ebe
83a35787-42dc-11e8-80d0-1c98ec135261;Вишнівець- Майдан -Шевченка,28;Вишнівець вул. Шевченка, 28;4aba3c02-749b-11df-b112-00215aee3ebe
baeeccb4-b368-11e9-80e4-1c98ec135261;Вишнів-Київська 20;Вишнів вул. Київська, 20;4aba3bff-749b-11df-b112-00215aee3ebe
3aa698c5-b369-11e9-80e4-1c98ec135261;Вишнів-Київська 30;Вишнів вул. Київська, 30;4aba3bff-749b-11df-b112-00215aee3ebe
6448f72f-20bb-11e9-80dc-1c98ec135261;Війтівці-Миру 7;Війтівці вул. Миру, 7;4aba3c5a-749b-11df-b112-00215aee3ebe
aa2e3caf-c540-11eb-80d5-000c29800ae7;Вільногірськ-Миру 11;Вільногірськ бул. Миру, 11;4aba3ccd-749b-11df-b112-00215aee3ebe
dce13482-14a2-11ed-80e7-000c29800ae7;Вільногірськ-Соборний 2А;Вільногірськ пров. Соборний, 2А;4aba3ccd-749b-11df-b112-00215aee3ebe
f0aa7076-6402-11ed-80ec-000c29800ae7;Вільнянськ-Горького 9А;Вільнянськ вул. Горького, 9А;4aba3cd6-749b-11df-b112-00215aee3ebe
0e06360d-e3fc-11ed-80ed-000c29800ae7;Вільнянськ-Зачиняєва 23;Вільнянськ вул. Зачиняєва, 23;4aba3cd6-749b-11df-b112-00215aee3ebe
d53b72a8-e3fc-11ed-80ed-000c29800ae7;Вільнянськ-Сироти 55;Вільнянськ вул. Сироти, 55;4aba3cd6-749b-11df-b112-00215aee3ebe
7c2ba138-6402-11ed-80ec-000c29800ae7;Вільнянськ-Соборна 51А;Вільнянськ вул. Соборна /Леніна/, 51А;4aba3cd6-749b-11df-b112-00215aee3ebe
d0a207fb-0115-11df-b41f-00215aee3ebe;Вінниця;Вінниця вул. Гетьмана Мазепи (Чехова), 25;4aba3d28-749b-11df-b112-00215aee3ebe
b87ce593-2576-11ee-80bd-000c2961d091;Вінниця-600-річчя 17Е Сільпо;Вінниця вул. 600-річчя, 17Е;4aba3d28-749b-11df-b112-00215aee3ebe
a1f4d0fb-3425-11e9-80dd-1c98ec135261;Вінниця-600-річчя 5 ОККО;Вінниця вул. 600-річчя, 5;4aba3d28-749b-11df-b112-00215aee3ebe
0a06a66a-0ccb-11ec-80d6-000c29800ae7;Вінниця-600-річчя 50 Ветаптека;Вінниця вул. 600-річчя, 50;4aba3d28-749b-11df-b112-00215aee3ebe
23dc7414-1fbf-11ee-80bd-000c2961d091;Вінниця-Батозька 2У Автозапчастини;Вінниця вул. Батозька, 2У;4aba3d28-749b-11df-b112-00215aee3ebe
80234560-a0e9-11ee-80c4-000c2961d091;Вінниця-Батозька 9Б Rozetka;Вінниця вул. Батозька, 9Б;4aba3d28-749b-11df-b112-00215aee3ebe
8c79ec45-5783-11ee-80c0-000c2961d091;Вінниця-Гагаріна 2 Сільпо;Вінниця пл. Калічанська (Гагаріна), 2;4aba3d28-749b-11df-b112-00215aee3ebe
29a60d38-59b1-11e8-80d4-1c98ec135261;Вінниця-Героїв поліції 14 Медкнига;Вінниця вул. Героїв поліції (Блока Олександра), 14;4aba3d28-749b-11df-b112-00215aee3ebe
0f802369-f119-11ec-80e7-000c29800ae7;Вінниця-Гоголя Миколи 1 Rozetka;Вінниця вул. Гоголя Миколи, 1;4aba3d28-749b-11df-b112-00215aee3ebe
cff7a2ac-0be5-11ec-80d6-000c29800ae7;Вінниця-Гоголя Миколи 14 Аптека;Вінниця вул. Гоголя Миколи, 14;4aba3d28-749b-11df-b112-00215aee3ebe
a1eea938-b13d-11ee-80c4-000c2961d091;Вінниця-Замостянська 1 МАЙЖЕ ВСЕ ТЦ AIWA;Вінниця вул. Замостянська (50-річчя Перемоги), 1;4aba3d28-749b-11df-b112-00215aee3ebe
ba05b536-b7ac-11eb-80d4-000c29800ae7;Вінниця-Зодчих 1А GADGET;Вінниця вул. Зодчих, 1А;4aba3d28-749b-11df-b112-00215aee3ebe
01207f07-5784-11ee-80c0-000c2961d091;Вінниця-Зодчих 2 Сільпо;Вінниця вул. Зодчих, 2;4aba3d28-749b-11df-b112-00215aee3ebe
1dabba8c-f11b-11ec-80e7-000c29800ae7;Вінниця-Зодчих 2А Rozetka;Вінниця вул. Зодчих, 2А;4aba3d28-749b-11df-b112-00215aee3ebe
e00ac491-9177-11ee-80c1-000c2961d091;Вінниця-Зодчих 4 Сервісний Центр;Вінниця вул. Зодчих, 4;4aba3d28-749b-11df-b112-00215aee3ebe
4ea33d2f-9318-11ed-80ec-000c29800ae7;Вінниця-Келецька 1 Lifecell;Вінниця вул. Келецька, 1;4aba3d28-749b-11df-b112-00215aee3ebe
76de07af-5784-11ee-80c0-000c2961d091;Вінниця-Келецька 105 Сільпо;Вінниця вул. Келецька, 105;4aba3d28-749b-11df-b112-00215aee3ebe
9840885b-5781-11ee-80c0-000c2961d091;Вінниця-Келецька 121 Сільпо;Вінниця вул. Келецька, 121;4aba3d28-749b-11df-b112-00215aee3ebe
2554b605-dd25-11e8-80d7-1c98ec135261;Вінниця-Келецька 122А Гаджетів;Вінниця вул. Келецька, 122А;4aba3d28-749b-11df-b112-00215aee3ebe
e0d9e10c-e9ae-11e8-80d7-1c98ec135261;Вінниця-Келецька 57 Lifecell;Вінниця вул. Келецька, 57;4aba3d28-749b-11df-b112-00215aee3ebe
9ebc6314-e98f-11e8-80d7-1c98ec135261;Вінниця-Келецька 71А Vodafone;Вінниця вул. Келецька, 71А;4aba3d28-749b-11df-b112-00215aee3ebe
43ab6f41-ad1a-11ed-80ec-000c29800ae7;Вінниця-Київська 112 Хозтовари;Вінниця вул. Київська, 112;4aba3d28-749b-11df-b112-00215aee3ebe
ec89c02e-e768-11ea-80cc-000c29800ae7;Вінниця-Київська 146 зооЛапа;Вінниця вул. Київська, 146;4aba3d28-749b-11df-b112-00215aee3ebe
db0ac1e7-5cc1-11ec-80e4-000c29800ae7;Вінниця-Київська 29/8 Pan-Akva;Вінниця вул. Київська, 29/8;4aba3d28-749b-11df-b112-00215aee3ebe
7037b308-e74e-11e8-80d7-1c98ec135261;Вінниця-Київська 45 Ремонт телефонів;Вінниця вул. Київська, 45;4aba3d28-749b-11df-b112-00215aee3ebe
fcd14881-e74b-11e8-80d7-1c98ec135261;Вінниця-Київська 8 Vodafone;Вінниця вул. Київська, 8;4aba3d28-749b-11df-b112-00215aee3ebe
f5e7c964-f11b-11ec-80e7-000c29800ae7;Вінниця-Космонавтів 49 Rozetka;Вінниця Просп. Космонавтів, 49;4aba3d28-749b-11df-b112-00215aee3ebe
379d3841-5782-11ee-80c0-000c2961d091;Вінниця-Космонавтів 49 Сільпо;Вінниця Просп. Космонавтів, 49;4aba3d28-749b-11df-b112-00215aee3ebe
202fba41-48d1-11ea-80c6-000c29800ae7;Вінниця-Костянтина Василенка 19А ОККО;Вінниця вул. Костянтина Василенка, 19А;4aba3d28-749b-11df-b112-00215aee3ebe
ef6b8371-e204-11ee-80c5-000c2961d091;Вінниця-Коцюбинського 16 lifecell;Вінниця Просп. Коцюбинського, 16;4aba3d28-749b-11df-b112-00215aee3ebe
ad17aed4-b7b2-11eb-80d4-000c29800ae7;Вінниця-Коцюбинського 2А Vodafone;Вінниця Просп. Коцюбинського, 2А;4aba3d28-749b-11df-b112-00215aee3ebe
066618f7-f11d-11ec-80e7-000c29800ae7;Вінниця-Коцюбинського 78Б Rozetka;Вінниця вул. Коцюбинського, 78Б;4aba3d28-749b-11df-b112-00215aee3ebe
99064527-71b4-11ee-80c1-000c2961d091;Вінниця-Максима Шимка 7А Ремонт телефонів;Вінниця вул. Максима Шимка (Маркса Карла), 7А;4aba3d28-749b-11df-b112-00215aee3ebe
13946988-ad0d-11ed-80ec-000c29800ae7;Вінниця-Марії Приймаченко 8В Канцтовари;Вінниця вул. Марії Приймаченко (Покришкіна), 8В;4aba3d28-749b-11df-b112-00215aee3ebe
f483fffe-0be6-11ec-80d6-000c29800ae7;Вінниця-Матроса Кішки 7 Аптека;Вінниця вул. Матроса Кішки, 7;4aba3d28-749b-11df-b112-00215aee3ebe
9a629b8c-0be6-11ec-80d6-000c29800ae7;Вінниця-Миколи Зерова 29 Аптека;Вінниця вул. Миколи Зерова (Пархоменка), 29;4aba3d28-749b-11df-b112-00215aee3ebe
ba992351-2575-11ee-80bd-000c2961d091;Вінниця-Оводова 51 Сільпо;Вінниця вул. Оводова, 51;4aba3d28-749b-11df-b112-00215aee3ebe
daf27d29-89f7-11ee-80c1-000c2961d091;Вінниця-Оводова 57 Зоомохнатик;Вінниця вул. Оводова, 57;4aba3d28-749b-11df-b112-00215aee3ebe
eede1319-de82-11e8-80d7-1c98ec135261;Вінниця-Пирогова 103А Моб. телефони;Вінниця вул. Пирогова, 103А;4aba3d28-749b-11df-b112-00215aee3ebe
998d676a-a706-11e9-80e2-1c98ec135261;Вінниця-Пирогова 141А ОККО;Вінниця вул. Пирогова, 141А;4aba3d28-749b-11df-b112-00215aee3ebe
7bea1eec-8153-11e9-80e0-1c98ec135261;Вінниця-Пирогова 146 Апекс;Вінниця вул. Пирогова, 146;4aba3d28-749b-11df-b112-00215aee3ebe
51464395-de80-11e8-80d7-1c98ec135261;Вінниця-Пирогова 42 Simsim;Вінниця вул. Пирогова, 42;4aba3d28-749b-11df-b112-00215aee3ebe
25d127d9-845b-11ee-80c1-000c2961d091;Вінниця-Пирогова 49 Ремонт телефонів;Вінниця вул. Пирогова, 49;4aba3d28-749b-11df-b112-00215aee3ebe
37c0bd79-aa5c-11ee-80c4-000c2961d091;Вінниця-Пирогова 6 Корзина;Вінниця вул. Пирогова, 6;4aba3d28-749b-11df-b112-00215aee3ebe
2c982b3e-65b3-11ed-80ec-000c29800ae7;Вінниця-Пирогова 76А зооЛапа;Вінниця вул. Пирогова, 76А;4aba3d28-749b-11df-b112-00215aee3ebe
db639719-8de2-11ee-80c1-000c2961d091;Вінниця-Праведників світу 43Б АЗС MAN GO;Вінниця вул. Праведників світу ( Максимовича ), 43Б;4aba3d28-749b-11df-b112-00215aee3ebe
d12f76ef-3427-11e9-80dd-1c98ec135261;Вінниця-Привокзальна 17 ОККО;Вінниця вул. Привокзальна, 17;4aba3d28-749b-11df-b112-00215aee3ebe
95bb48ec-cc8c-11e8-80d7-1c98ec135261;Вінниця-Пушкіна 4 Кавярня;Вінниця вул. Пушкіна, 4;4aba3d28-749b-11df-b112-00215aee3ebe
dc0fabf9-be8a-11ee-80c4-000c2961d091;Вінниця-Соборна 82 Буцентр;Вінниця вул. Соборна, 82;4aba3d28-749b-11df-b112-00215aee3ebe
11f3df4b-7c09-11eb-80d2-000c29800ae7;Вінниця-Стельмаха Михайла 23 зооЛапа;Вінниця вул. Стельмаха Михайла, 23;4aba3d28-749b-11df-b112-00215aee3ebe
992d380c-8aaf-11ee-80c1-000c2961d091;Вінниця-Степана Тимошенка 2Б зооЛапа;Вінниця вул. Степана Тимошенка (Якіра), 2Б;4aba3d28-749b-11df-b112-00215aee3ebe
964bc1cc-4588-11ee-80c0-000c2961d091;Вінниця-Трамвайна 3 Продукти;Вінниця вул. Трамвайна, 3;4aba3d28-749b-11df-b112-00215aee3ebe
b48a0c4b-a701-11e9-80e2-1c98ec135261;Вінниця-Хмельницьке 107В ОККО;Вінниця шосе Хмельницьке, 107В;4aba3d28-749b-11df-b112-00215aee3ebe
31ae0e0a-d5f0-11e8-80d7-1c98ec135261;Вінниця-Хмельницьке 93А Lifecell;Вінниця шосе Хмельницьке, 93А;4aba3d28-749b-11df-b112-00215aee3ebe
1727ef95-30d4-11ec-80e3-000c29800ae7;Вінниця-Хмельницьке шосе 107 Lifecell;Вінниця вул. Хмельницьке шосе, 107;4aba3d28-749b-11df-b112-00215aee3ebe
d14675b1-725b-11ee-80c1-000c2961d091;Вінниця-Хмельницьке шосе 107 Моб. телефони;Вінниця вул. Хмельницьке шосе, 107;4aba3d28-749b-11df-b112-00215aee3ebe
9db33b5d-2255-11ee-80bd-000c2961d091;Вінниця-Хмельницьке шосе 114В Rozetka;Вінниця вул. Хмельницьке шосе, 114В;4aba3d28-749b-11df-b112-00215aee3ebe
4291117a-7256-11ee-80c1-000c2961d091;Вінниця-Хмельницьке шосе 114В Пиво Тут;Вінниця вул. Хмельницьке шосе, 114В;4aba3d28-749b-11df-b112-00215aee3ebe
f1a45648-f038-11eb-80d5-000c29800ae7;Вінниця-Хмельницьке шосе 75 Ломбард;Вінниця вул. Хмельницьке шосе, 75;4aba3d28-749b-11df-b112-00215aee3ebe
00c0a011-2128-11ec-80df-000c29800ae7;Вінниця-Чорновола 29 Same ta voda ta kava;Вінниця вул. Чорновола В'ячеслава, 29, секція 6;4aba3d28-749b-11df-b112-00215aee3ebe
bcf73c94-e202-11ee-80c5-000c2961d091;Вінниця-Чорновола В'ячеслава 29А lifecell;Вінниця вул. Чорновола В'ячеслава, 29А;4aba3d28-749b-11df-b112-00215aee3ebe
c83481ca-5782-11ee-80c0-000c2961d091;Вінниця-Юності 18 Сільпо;Вінниця Просп. Юності, 18;4aba3d28-749b-11df-b112-00215aee3ebe
1d7d81e5-61c0-11e9-80df-1c98ec135261;Вінниця-Якова Шепеля 23 Автозапчастини;Вінниця вул. Якова Шепеля (Примакова), 23;4aba3d28-749b-11df-b112-00215aee3ebe
700a0a68-7745-11ee-80c1-000c2961d091;Вінниця-Янгеля Академіка 30 АККО;Вінниця вул. Янгеля Академіка (Фрунзе), 30;4aba3d28-749b-11df-b112-00215aee3ebe
5f5f3b28-d715-11ee-80c4-000c2961d091;Вінницькі Хутори-Немирівське 94Е;Вінницькі Хутори шосе Немирівське, 94Е;4aba3d2d-749b-11df-b112-00215aee3ebe
5d38d47d-32ab-11e8-80d0-1c98ec135261;Віньківці-Заславська 10;Віньківці вул. Заславська, 10;4aba3d31-749b-11df-b112-00215aee3ebe
26b12ae3-8399-11e9-80e0-1c98ec135261;Віньківці-Патріотів України, 22;Віньківці вул. Патріотів України, 22;4aba3d31-749b-11df-b112-00215aee3ebe
c338e9cd-ef12-11ed-80ed-000c29800ae7;Віта-Поштова-Звенигородська 200Д;Віта-Поштова вул. Звенигородська, 200Д;4aba3d41-749b-11df-b112-00215aee3ebe
1b532ca1-0478-11ee-80bd-000c2961d091;Віта-Поштова-Янтарна 1А;Віта-Поштова вул. Янтарна, 1А;4aba3d41-749b-11df-b112-00215aee3ebe
d99c44ce-47f4-11ee-80c0-000c2961d091;Вознесенськ-Київська 150А;Вознесенськ вул. Київська, 150А;4aba3e04-749b-11df-b112-00215aee3ebe
9a24a19f-5151-11ee-80c0-000c2961d091;Вознесенськ-Київська 5;Вознесенськ вул. Київська, 5;4aba3e04-749b-11df-b112-00215aee3ebe
30bd20e7-f04c-11eb-80d5-000c29800ae7;Вознесенськ-Київська 9А/3;Вознесенськ вул. Київська, 9А;4aba3e04-749b-11df-b112-00215aee3ebe
72973cb9-47f1-11ee-80c0-000c2961d091;Вознесенськ-Матросова 201;Вознесенськ вул. Матросова, 201;4aba3e04-749b-11df-b112-00215aee3ebe
8e4cd0a7-47f0-11ee-80c0-000c2961d091;Вознесенськ-Осадчого 45А;Вознесенськ вул. Осадчого, 45А;4aba3e04-749b-11df-b112-00215aee3ebe
9ec22f8b-bb60-11ee-80c4-000c2961d091;Вознесенськ-Соборності 8;Вознесенськ вул. Соборності, 8;4aba3e04-749b-11df-b112-00215aee3ebe
781e122c-47f4-11ee-80c0-000c2961d091;Вознесенськ-Центральна Садиба 2/13;Вознесенськ вул. Центральна Садиба, 2/13;4aba3e04-749b-11df-b112-00215aee3ebe
01ccb82e-47f4-11ee-80c0-000c2961d091;Вознесенськ-Шевченка 41Е;Вознесенськ вул. Шевченка, 41Е;4aba3e04-749b-11df-b112-00215aee3ebe
40516221-3134-11e9-80dd-1c98ec135261;Волиця-Дружби 205А;Волиця вул. Дружби, 205А;4aba3e31-749b-11df-b112-00215aee3ebe
aa238424-7fd6-11ee-80c1-000c2961d091;Воловець-Карпатська 56/2;Воловець вул. Карпатська, 56/2;4aba3e4d-749b-11df-b112-00215aee3ebe
e18324f7-f8e4-11eb-80d5-000c29800ae7;Воловець-Карпатська 56А;Воловець вул. Карпатська, 56А;4aba3e4d-749b-11df-b112-00215aee3ebe
cc1e1207-cfdf-11ee-80c4-000c2961d091;Воловець-Привокзальна 6;Воловець вул. Привокзальна, 6;4aba3e4d-749b-11df-b112-00215aee3ebe
5a7c181f-5c6f-11ee-80c0-000c2961d091;Володарка-Армійська 1К;Володарка вул. Армійська, 1К;4aba3e55-749b-11df-b112-00215aee3ebe
9ed3e53b-6690-11ee-80c0-000c2961d091;Володарка-Коцюбинського 14А;Володарка вул. Коцюбинського, 14А;4aba3e55-749b-11df-b112-00215aee3ebe
77841742-9d8a-11e1-ad48-003048d2b473;Володимир-Волинський;Володимир вул. Сагайдачного, 47;4aba3e5a-749b-11df-b112-00215aee3ebe
03c2b427-c814-11ee-80c4-000c2961d091;Володимирець-Поштова 23;Володимирець вул. Поштова, 23;4aba3e5b-749b-11df-b112-00215aee3ebe
07f6d5f7-9e9b-11ea-80c8-000c29800ae7;Володимирець-Соборна 33/1;Володимирець вул. Соборна, 33/1;4aba3e5b-749b-11df-b112-00215aee3ebe
8d602108-7743-11eb-80d1-000c29800ae7;Володимир-Князя Василька 2 Сім23;Володимир вул. Князя Василька, 2;4aba3e5a-749b-11df-b112-00215aee3ebe
8c69589c-f95d-11ed-80ed-000c29800ae7;Володимир-Князя Василька 3 Lifecell;Володимир вул. Князя Василька, 3;4aba3e5a-749b-11df-b112-00215aee3ebe
c7eced9c-04e2-11e8-80cd-1c98ec135261;Володимир-Князя Василька 3/21 Жжук;Володимир вул. Князя Василька, 3/21;4aba3e5a-749b-11df-b112-00215aee3ebe
de0ef3bb-f11d-11ec-80e7-000c29800ae7;Володимир-Ковельська 71 Rozetka;Володимир вул. Ковельська, 71;4aba3e5a-749b-11df-b112-00215aee3ebe
dfd19128-5bf2-11eb-80d0-000c29800ae7;Володимир-Ковельська 71 Сім23;Володимир вул. Ковельська, 71;4aba3e5a-749b-11df-b112-00215aee3ebe
dbd8c879-0445-11ee-80bd-000c2961d091;Володимир-Луцька 148 Сім23;Володимир вул. Луцька, 148;4aba3e5a-749b-11df-b112-00215aee3ebe
91573c97-208c-11e9-80dc-1c98ec135261;Володимир-Устилузька 91А ОККО;Володимир вул. Устилузька, 91А;4aba3e5a-749b-11df-b112-00215aee3ebe
6fdc0e1d-6e87-11ee-80c1-000c2961d091;Волочиськ-Незалежності 17;Волочиськ вул. Незалежності, 17;4aba3ebc-749b-11df-b112-00215aee3ebe
19c85b60-32a9-11e8-80d0-1c98ec135261;Волочиськ-Незалежності 58 А;Волочиськ вул. Незалежності, 58А;4aba3ebc-749b-11df-b112-00215aee3ebe
26f2da7e-1c09-11ea-80e7-1c98ec135261;Волочиськ-Незалежності 6 А;Волочиськ вул. Незалежності, 6А;4aba3ebc-749b-11df-b112-00215aee3ebe
839b9620-fbbd-11ed-80ed-000c29800ae7;Волочиськ-Слави 14;Волочиськ вул. Слави, 14;4aba3ebc-749b-11df-b112-00215aee3ebe
aee7a92a-0e93-11ee-80bd-000c2961d091;Ворзель-Європейська 10;Ворзель вул. Європейська ( Радянська ), 10;4aba3ef2-749b-11df-b112-00215aee3ebe
9f20c38b-0522-11ee-80bd-000c2961d091;Ворзель-Ковельська 3;Ворзель вул. Ковельська, 3;4aba3ef2-749b-11df-b112-00215aee3ebe
f18c2926-1b1a-11ee-80bd-000c2961d091;Ворзель-Курортна 62;Ворзель вул. Курортна (Великого Жовтня), 62;4aba3ef2-749b-11df-b112-00215aee3ebe
0975f4f7-0be8-11ec-80d6-000c29800ae7;Вороновиця-Гагаріна 20;Вороновиця вул. Якова Гальчевського (Гагаріна), 20;4aba3f37-749b-11df-b112-00215aee3ebe
affbcac7-0be7-11ec-80d6-000c29800ae7;Вороновиця-Козацький 68;Вороновиця шлях Козацький, 68;4aba3f37-749b-11df-b112-00215aee3ebe
85ebe221-0be8-11ec-80d6-000c29800ae7;Вороновиця-Молодіжна 10;Вороновиця вул. Молодіжна, 10;4aba3f37-749b-11df-b112-00215aee3ebe
49a009d7-8566-11eb-80d2-000c29800ae7;Врадіївка-Героїв Врадіївщини 113;Врадіївка вул. Героїв Врадіївщини, 113;4aba3f76-749b-11df-b112-00215aee3ebe
b7c88619-c811-11ee-80c4-000c2961d091;Врадіївка-Героїв Врадіївщини 129/4;Врадіївка вул. Героїв Врадіївщини, 129/4;4aba3f76-749b-11df-b112-00215aee3ebe
778f4c14-c812-11ee-80c4-000c2961d091;Гадяч-Гетьманська 13;Гадяч вул. Гетьманська, 13;4aba3fc5-749b-11df-b112-00215aee3ebe
2fbfee02-449c-11ed-80eb-000c29800ae7;Гадяч-Гетьманська 41;Гадяч вул. Гетьманська, 41;4aba3fc5-749b-11df-b112-00215aee3ebe
633e3bc2-5883-11ee-80c0-000c2961d091;Гадяч-Гетьманська 49;Гадяч вул. Гетьманська, 49;4aba3fc5-749b-11df-b112-00215aee3ebe
73b478eb-c758-11ee-80c4-000c2961d091;Гадяч-Миру 13А;Гадяч вул. Миру, 13А;4aba3fc5-749b-11df-b112-00215aee3ebe
584c1569-c3aa-11eb-80d5-000c29800ae7;Гайворон-Стуса Василя 27;Гайворон вул. Стуса Василя, 27;4aba3feb-749b-11df-b112-00215aee3ebe
bd4fcb7a-1e1b-11ed-80e7-000c29800ae7;Гайворон-Центральна 82З;Гайворон вул. Центральна, 82З;4aba3feb-749b-11df-b112-00215aee3ebe
3ad843ae-a717-11e9-80e2-1c98ec135261;Гайсин-1-го Травня 130;Гайсин вул. 1-го Травня, 130;4aba4007-749b-11df-b112-00215aee3ebe
342fd389-1120-11ea-80e7-1c98ec135261;Галич-Обї'здна 1;Галич вул. Обї'здна, 1;4aba401f-749b-11df-b112-00215aee3ebe
7baac7d6-c80f-11ee-80c4-000c2961d091;Галич-Франка Івана 5;Галич вул. Франка Івана, 5;4aba401f-749b-11df-b112-00215aee3ebe
34afb30d-c531-11eb-80d5-000c29800ae7;Гатне-Абрикосова 12 Абрикоско маркет;Гатне вул. Абрикосова, 12;4aba40b5-749b-11df-b112-00215aee3ebe
86207115-3076-11e9-80dd-1c98ec135261;Гатне-автодорога Київ-Одеса 15км, АЗС №45;Гатне дорога автодорога Київ-Одеса 15км, АЗС №45,;4aba40b5-749b-11df-b112-00215aee3ebe
0467626c-f626-11ed-80ed-000c29800ae7;Гатне-Оптимістична 1;Гатне вул. Оптимістична, 1;4aba40b5-749b-11df-b112-00215aee3ebe
55d48917-c74d-11ee-80c4-000c2961d091;Гатне-Приозерний 6;Гатне бул. Приозерний, 6;4aba40b5-749b-11df-b112-00215aee3ebe
64d04c85-b715-11eb-80d4-000c29800ae7;Гвардійське-Гагаріна 15;Гвардійське вул. Гагаріна, 15;50c58da4-749b-11df-b112-00215aee3ebe
06966a72-1191-11ee-80bd-000c2961d091;Гвардійське-Соборна 39;Гвардійське вул. Соборна, 39;50c58daa-749b-11df-b112-00215aee3ebe
b99b4d90-2a01-11ee-80bd-000c2961d091;Глеваха-Вокзальна 45;Глеваха вул. Вокзальна, 45;50c58e38-749b-11df-b112-00215aee3ebe
ce128edc-68d5-11ee-80c1-000c2961d091;Глеваха-Вокзальна 45 Rozetka;Глеваха вул. Вокзальна, 45;50c58e38-749b-11df-b112-00215aee3ebe
61e08591-051e-11ee-80bd-000c2961d091;Глеваха-Грушевського 8;Глеваха вул. Грушевського, 8;50c58e38-749b-11df-b112-00215aee3ebe
1acbbe7b-372d-11e8-80d0-1c98ec135261;Глибока-Базарна 2 В;Глибока вул. Базарна, 2В;50c58e3f-749b-11df-b112-00215aee3ebe
a46060b4-8875-11ee-80c1-000c2961d091;Глибока-Залізнична 11Б;Глибока вул. Залізнична, 11Б;50c58e3f-749b-11df-b112-00215aee3ebe
2b013a03-30b9-11eb-80ce-000c29800ae7;Глобине-Травнева 3;Глобине вул. Травнева, 3;50c58e98-749b-11df-b112-00215aee3ebe
8401e9f5-5b98-11ed-80eb-000c29800ae7;Глухів-Києво-Московська 24;Глухів вул. Київська (к.Києво-Московська), 24;50c58ea1-749b-11df-b112-00215aee3ebe
3665e185-650b-11e9-80df-1c98ec135261;Глухів-Києво-Московська,36;Глухів вул. Київська (к.Києво-Московська), 36;50c58ea1-749b-11df-b112-00215aee3ebe
421016d1-007c-11ea-80e7-1c98ec135261;Глухів-Ціолковського 6;Глухів вул. Ціолковського, 6;50c58ea1-749b-11df-b112-00215aee3ebe
a7d9ad8d-36b3-11e9-80dd-1c98ec135261;Гнівань-Соборна 64;Гнівань вул. Соборна, 64;50c58ed4-749b-11df-b112-00215aee3ebe
578a7367-9fec-11ee-80c4-000c2961d091;Гнівань-Соборна 68;Гнівань вул. Соборна, 68;50c58ed4-749b-11df-b112-00215aee3ebe
48b3e94e-7efd-11ee-80c1-000c2961d091;Гнідин-Ідейна 3;Гнідин вул. Ідейна, 3;50c58ed5-749b-11df-b112-00215aee3ebe
823c7c4b-30c6-11ec-80e3-000c29800ae7;Голованівськ-Ринкова 6;Голованівськ вул. Ринкова, 6;50c58f13-749b-11df-b112-00215aee3ebe
d4a9b9fc-2044-11ec-80df-000c29800ae7;Голованівськ-Ринкова 7А;Голованівськ вул. Ринкова, 7А;50c58f13-749b-11df-b112-00215aee3ebe
7c70bb2d-69bc-11ee-80c1-000c2961d091;Голубине-Весела;Голубине вул. Весела,;50c58f4a-749b-11df-b112-00215aee3ebe
b8051ca8-0523-11ee-80bd-000c2961d091;Гора-Центральна 25;Гора вул. Центральна, 25;50c58f99-749b-11df-b112-00215aee3ebe
fa9dbffa-9d03-11eb-80d3-000c29800ae7;Гора-Центральна 39;Гора вул. Центральна, 39;50c58f99-749b-11df-b112-00215aee3ebe
f8d263fd-b364-11e9-80e4-1c98ec135261;Горбаків-Центральна 3 А;Горбаків вул. Центральна, 3А;50c58f9f-749b-11df-b112-00215aee3ebe
3a2a569a-08ce-11ed-80e7-000c29800ae7;Горішні Плавні-Героїв Дніпра 79;Горішні Плавні Просп. Героїв Дніпра (Леніна), 79;5cb619be-749b-11df-b112-00215aee3ebe
f4e309fc-5d01-11e9-80df-1c98ec135261;Горішні Плавні-Героїв Дніпра просп. 4;Горішні Плавні Просп. Героїв Дніпра (Леніна), 4;5cb619be-749b-11df-b112-00215aee3ebe
230e6f5c-bf27-11ed-80ed-000c29800ae7;Горішні Плавні-Добровольського 31;Горішні Плавні вул. Добровольського, 31;5cb619be-749b-11df-b112-00215aee3ebe
68b728fe-5628-11e9-80df-1c98ec135261;Горішні Плавні-Миру 8;Горішні Плавні вул. Миру, 8;5cb619be-749b-11df-b112-00215aee3ebe
d768855f-a79f-11e9-80e4-1c98ec135261;Городенка-Височана Семена 7 А;Городенка вул. Височана Семена, 7А;50c58ffe-749b-11df-b112-00215aee3ebe
ac83763c-80c5-11eb-80d2-000c29800ae7;Городенка-Шевченка 42;Городенка вул. Шевченка, 42;50c58ffe-749b-11df-b112-00215aee3ebe
4d170bde-a94b-11e9-80e4-1c98ec135261;Городенка-Шевченка 81;Городенка вул. Шевченка, 81;50c58ffe-749b-11df-b112-00215aee3ebe
513156ce-ba90-11eb-80d4-000c29800ae7;Городище-Миру 107Г;Городище вул. Миру, 107Г;50c59023-749b-11df-b112-00215aee3ebe
f89f521d-57cd-11ea-80c6-000c29800ae7;Городище-Миру 115;Городище вул. Миру, 115;50c59023-749b-11df-b112-00215aee3ebe
553888f3-87cf-11eb-80d2-000c29800ae7;Городище-Миру 131;Городище вул. Миру, 131;50c59023-749b-11df-b112-00215aee3ebe
27ea1517-ce1d-11ed-80ed-000c29800ae7;Городня-Петровського 2;Городня вул. Петровського, 2;50c59041-749b-11df-b112-00215aee3ebe
e570067d-be31-11eb-80d5-000c29800ae7;Городня-Чернігівська 3;Городня вул. Чернігівська (Чорноуса), 3;50c59041-749b-11df-b112-00215aee3ebe
69b0db5f-54d3-11eb-80d0-000c29800ae7;Городок-Гончара Олеся 3 А;Городок вул. Гончара Олеся, 3А;50c5904c-749b-11df-b112-00215aee3ebe
aa064ea2-2477-11ee-80bd-000c2961d091;Городок-Гончара Олеся 3А Lifecell;Городок вул. Гончара Олеся, 3А;50c5904c-749b-11df-b112-00215aee3ebe
80d666fa-f066-11eb-80d5-000c29800ae7;Городок-Грушевського 55/4;Городок вул. Грушевського, 55/4;50c5904c-749b-11df-b112-00215aee3ebe
3acadd3e-251d-11ec-80e3-000c29800ae7;Городок-Грушевського 84/2;Городок вул. Грушевського, 84/2;50c5904c-749b-11df-b112-00215aee3ebe
f720674c-9929-11eb-80d3-000c29800ae7;Городок-Грушевського 88;Городок вул. Грушевського, 88;50c5904c-749b-11df-b112-00215aee3ebe
da391540-305b-11e9-80dd-1c98ec135261;Городок-Львівська 320 Б ;Городок вул. Львівська, 320Б;50c59049-749b-11df-b112-00215aee3ebe
83123878-1726-11ee-80bd-000c2961d091;Городок-Чорновола В'ячеслава 8Д;Городок вул. Чорновола В'ячеслава, 8Д;50c59049-749b-11df-b112-00215aee3ebe
02c30704-a3cb-11e9-80e2-1c98ec135261;Горохів-Луцька 27 Б;Горохів вул. Луцька, 27Б;50c5905a-749b-11df-b112-00215aee3ebe
fe00facf-9a1c-11eb-80d3-000c29800ae7;Горохів-Торгова 10;Горохів вул. Торгова, 10;50c5905a-749b-11df-b112-00215aee3ebe
88224532-f95c-11ed-80ed-000c29800ae7;Горохів-Шевченка 21;Горохів вул. Шевченка, 21;50c5905a-749b-11df-b112-00215aee3ebe
f22cb8f4-cf1a-11ee-80c4-000c2961d091;Гостомель-Прорізна 9;Гостомель вул. Прорізна, 9;50c5907e-749b-11df-b112-00215aee3ebe
7f8dfc4e-9e5b-11ed-80ec-000c29800ae7;Гостомель-Свято-Покровська 9 з;Гостомель вул. Свято-Покровська, 9 з;50c5907e-749b-11df-b112-00215aee3ebe
b2166e81-20b5-11e9-80dc-1c98ec135261;Гостомель-Чкалова 11а;Гостомель вул. Чкалова, 11а;50c5907e-749b-11df-b112-00215aee3ebe
97a2f1cb-aeae-11ed-80ec-000c29800ae7;Гоща-Незалежності 11Б;Гоща вул. Незалежності, 11Б;50c5908a-749b-11df-b112-00215aee3ebe
3906b643-3c12-11ee-80c0-000c2961d091;Гоща-Незалежності 30;Гоща вул. Незалежності, 30;50c5908a-749b-11df-b112-00215aee3ebe
7a78551d-594c-11ee-80c0-000c2961d091;Гоща-Незалежності 76А;Гоща вул. Незалежності, 76А;50c5908a-749b-11df-b112-00215aee3ebe
ee9fecea-d924-11ed-80ed-000c29800ae7;Градизьк-Київська 44А;Градизьк вул. Київська, 44А;50c590bc-749b-11df-b112-00215aee3ebe
980de35b-d094-11ee-80c4-000c2961d091;Градизьк-Незалежності 13;Градизьк вул. Незалежності, 13;50c590bc-749b-11df-b112-00215aee3ebe
078e7778-cff5-11ee-80c4-000c2961d091;Гребінка-1-го Травня 9;Гребінка пров. 1-го Травня, 9;50c590e1-749b-11df-b112-00215aee3ebe
c20893ad-054a-11ee-80bd-000c2961d091;Гребінка-Незалежності 2;Гребінка вул. Незалежності, 2;50c590e1-749b-11df-b112-00215aee3ebe
26fcefa7-ba8b-11eb-80d4-000c29800ae7;Гребінки-Київська 99;Гребінки вул. Київська, 99;50c590e2-749b-11df-b112-00215aee3ebe
d3533380-f87f-11eb-80d5-000c29800ae7;Гусятин-Наливайка 4/3;Гусятин вул. Наливайка, 4/3;50c59271-749b-11df-b112-00215aee3ebe
0ed08478-887d-11ee-80c1-000c2961d091;Гусятин-Незалежності 7;Гусятин Просп. Незалежності, 7;50c59271-749b-11df-b112-00215aee3ebe
f7d0a3fd-77f0-11ee-80c1-000c2961d091;Давидів-Галицька 29;Давидів вул. Галицька, 29;50c592b9-749b-11df-b112-00215aee3ebe
ce4771d5-0507-11ee-80bd-000c2961d091;Давидів-Промислова 103;Давидів вул. Промислова, 103;50c592b9-749b-11df-b112-00215aee3ebe
0193d174-9890-11eb-80d3-000c29800ae7;Дашів-Горького 13;Дашів вул. Горького, 13;50c5932a-749b-11df-b112-00215aee3ebe
539a1407-cbed-11ee-80c4-000c2961d091;Делятин-16-го Липня 225А;Делятин вул. 16-го Липня, 225А;50c5935a-749b-11df-b112-00215aee3ebe
6943a392-bee1-11eb-80d5-000c29800ae7;Делятин-16-го Липня, 297Б;Делятин вул. 16-го Липня, 297Б;50c5935a-749b-11df-b112-00215aee3ebe
4bb2ac52-9a24-11eb-80d3-000c29800ae7;Диканька-Газовиків 2;Диканька вул. Газовиків, 2;50c59429-749b-11df-b112-00215aee3ebe
7ddf924a-48a8-11ee-80c0-000c2961d091;Димер-Соборна 102А;Димер вул. Соборна, 102А;50c59432-749b-11df-b112-00215aee3ebe
75ec2987-a8dd-11de-bac3-0030485903e8;Дніпро;Дніпро вул. Березинська, 80;50c5951b-749b-11df-b112-00215aee3ebe
8d2af2f7-a64d-11ee-80c4-000c2961d091;Дніпро-152-ї Дивізії 4 прим.38 Делві;Дніпро вул. 152-ї Дивізії, 4;50c5951b-749b-11df-b112-00215aee3ebe
f3ca80d9-37e4-11ec-80e3-000c29800ae7;Дніпро-20-річчя Перемоги 1 Кавярня;Дніпро вул. 20-річчя Перемоги (Самарський), 1;50c5951b-749b-11df-b112-00215aee3ebe
ee7183d7-77ce-11ee-80c1-000c2961d091;Дніпро-20-річчя Перемоги 23 Grivna Centr;Дніпро вул. 20-річчя Перемоги (Самарський), 23;50c5951b-749b-11df-b112-00215aee3ebe
66b1a4f2-baa5-11ee-80c4-000c2961d091;Дніпро-20-річчя Перемоги 35 Andi;Дніпро вул. 20-річчя Перемоги (Самарський), 35;50c5951b-749b-11df-b112-00215aee3ebe
8b393666-a96e-11ee-80c4-000c2961d091;Дніпро-20-річчя Перемоги 43Д Rozetka;Дніпро вул. 20-річчя Перемоги (Самарський), 43Д;50c5951b-749b-11df-b112-00215aee3ebe
c8afe636-3cd6-11ee-80c0-000c2961d091;Дніпро-Андрія Сахарова 13 Делві;Дніпро вул. Андрія Сахарова (Петровського), 13;50c5951b-749b-11df-b112-00215aee3ebe
1343709e-6400-11ed-80ec-000c29800ae7;Дніпро-Андрія Сахарова 78.літ.А-1 Делві;Дніпро вул. Андрія Сахарова (Петровського), 78.літ.А-1;50c5951b-749b-11df-b112-00215aee3ebe
c3cff642-fab2-11eb-80d6-000c29800ae7;Дніпро-Андрія Сахарова 78А, прим.А5 Делві;Дніпро вул. Андрія Сахарова (Петровського), 78А, прим.А5;50c5951b-749b-11df-b112-00215aee3ebe
9bb97b59-578c-11ee-80c0-000c2961d091;Дніпро-Андрія Фабра 7 Сільпо;Дніпро вул. Андрія Фабра (Сєрова), 7;50c5951b-749b-11df-b112-00215aee3ebe
b9228c67-3503-11ed-80e9-000c29800ae7;Дніпро-Артеківська 18 Foods-Body;Дніпро вул. Артеківська, 18;50c5951b-749b-11df-b112-00215aee3ebe
e493c188-ee41-11ed-80ed-000c29800ae7;Дніпро-Артеківська 22 МастерОК;Дніпро вул. Артеківська, 22;50c5951b-749b-11df-b112-00215aee3ebe
32d5c5d2-0750-11ed-80e7-000c29800ae7;Дніпро-Барикадна 7 Хімчистка;Дніпро вул. Барикадна, 7;50c5951b-749b-11df-b112-00215aee3ebe
035166ab-984b-11ee-80c1-000c2961d091;Дніпро-Березинська 23А Tabachini;Дніпро вул. Березинська, 23А;50c5951b-749b-11df-b112-00215aee3ebe
85e0a3e8-7410-11ed-80ec-000c29800ae7;Дніпро-Березинська 80 СПЕЦ;Дніпро вул. Березинська, 80;50c5951b-749b-11df-b112-00215aee3ebe
e2ae34f1-6401-11ed-80ec-000c29800ae7;Дніпро-Бестужева 41 Делві;Дніпро вул. Бестужева, 41;50c5951b-749b-11df-b112-00215aee3ebe
96816d8d-faa6-11eb-80d6-000c29800ae7;Дніпро-Бєляєва 4Д Делві;Дніпро вул. Бєляєва (Замполіта Бєляєва), 4Д;50c5951b-749b-11df-b112-00215aee3ebe
e06a29b2-fab3-11eb-80d6-000c29800ae7;Дніпро-Будівельників 25 Делві;Дніпро вул. Будівельників, 25;50c5951b-749b-11df-b112-00215aee3ebe
84a05f4d-9849-11ee-80c1-000c2961d091;Дніпро-Велика Діївська 32 Tabachini;Дніпро вул. Велика Діївська (Трофимових Братів), 32;50c5951b-749b-11df-b112-00215aee3ebe
fc86a31b-69ac-11ee-80c1-000c2961d091;Дніпро-Велика Діївська 36 Київстар;Дніпро вул. Велика Діївська (Трофимових Братів), 36;50c5951b-749b-11df-b112-00215aee3ebe
0858f824-47d0-11ee-80c0-000c2961d091;Дніпро-Велика Діївська 44 Нектар;Дніпро вул. Велика Діївська (Трофимових Братів), 44;50c5951b-749b-11df-b112-00215aee3ebe
bc45389d-3045-11ee-80bd-000c2961d091;Дніпро-Виконкомівська 7 Lifecell;Дніпро вул. Виконкомівська, 7;50c5951b-749b-11df-b112-00215aee3ebe
55f3cce4-984a-11ee-80c1-000c2961d091;Дніпро-Вільний 2А Tabachini;Дніпро пров. Вільний, 2А;50c5951b-749b-11df-b112-00215aee3ebe
42970eca-0c33-11ee-80bd-000c2961d091;Дніпро-Вознюка 1вА-1 Делві;Дніпро вул. Вознюка, 1вА-1;50c5951b-749b-11df-b112-00215aee3ebe
600c1f55-3045-11ee-80bd-000c2961d091;Дніпро-Вокзальна 13 Lifecell;Дніпро пл. Вокзальна (Петровського), 13;50c5951b-749b-11df-b112-00215aee3ebe
996d3f19-6900-11ee-80c1-000c2961d091;Дніпро-Вокзальна 13 One Price;Дніпро пл. Вокзальна (Петровського), 13;50c5951b-749b-11df-b112-00215aee3ebe
cc0ddd97-757b-11ee-80c1-000c2961d091;Дніпро-Вокзальна 13 Сільпо;Дніпро пл. Вокзальна (Петровського), 13;50c5951b-749b-11df-b112-00215aee3ebe
a1c4424c-8a12-11ee-80c1-000c2961d091;Дніпро-Вокзальна 2 EURO-OPT;Дніпро пл. Вокзальна (Петровського), 2;50c5951b-749b-11df-b112-00215aee3ebe
81aeadf4-2022-11eb-80cd-000c29800ae7;Дніпро-Вокзальна площа 13;Дніпро пл. Вокзальна (Петровського), 13;50c5951b-749b-11df-b112-00215aee3ebe
0be283f1-224d-11ea-80e7-1c98ec135261;Дніпро-Волинська 9 Тренажерний зал Sport West;Дніпро вул. Волинська, 9;50c5951b-749b-11df-b112-00215aee3ebe
56250b24-faa7-11eb-80d6-000c29800ae7;Дніпро-Волкова Космонавта 11 Делві;Дніпро вул. Волкова Космонавта, 11;50c5951b-749b-11df-b112-00215aee3ebe
decc639e-48ad-11ee-80c0-000c2961d091;Дніпро-Волкова Космонавта 19А-1 Делві;Дніпро вул. Волкова Космонавта, 19А-1;50c5951b-749b-11df-b112-00215aee3ebe
8e546b4f-8a0e-11ee-80c1-000c2961d091;Дніпро-Володимира Вернадського 10 EURO-OPT;Дніпро вул. Володимира Вернадського (Дзержинського), 10;50c5951b-749b-11df-b112-00215aee3ebe
7b1c82dc-8a0a-11ee-80c1-000c2961d091;Дніпро-Воскресенська 1 EURO-OPT;Дніпро вул. Воскресенська, 1;50c5951b-749b-11df-b112-00215aee3ebe
030e4ae7-68b8-11ec-80e4-000c29800ae7;Дніпровське-Спортивна 5;Дніпровське вул. Спортивна /Терешкової/, 5;50c59513-749b-11df-b112-00215aee3ebe
9cbdb5d0-db14-11eb-80d5-000c29800ae7;Дніпро-Гагаріна 105 Канцтовари;Дніпро Просп. Науки ( Гагаріна ), 105;50c5951b-749b-11df-b112-00215aee3ebe
45bf9d76-3d7d-11ec-80e3-000c29800ae7;Дніпро-Гагаріна 119 Келих;Дніпро Просп. Науки ( Гагаріна ), 119;50c5951b-749b-11df-b112-00215aee3ebe
93086541-e493-11eb-80d5-000c29800ae7;Дніпро-Гагаріна 13 Профит;Дніпро Просп. Науки ( Гагаріна ), 13;50c5951b-749b-11df-b112-00215aee3ebe
de85d0e2-d3f7-11eb-80d5-000c29800ae7;Дніпро-Гагаріна 171 Ломбард;Дніпро Просп. Науки ( Гагаріна ), 171;50c5951b-749b-11df-b112-00215aee3ebe
de3e48ec-984c-11ee-80c1-000c2961d091;Дніпро-Гагаріна 175 Tabachini;Дніпро Просп. Науки ( Гагаріна ), 175;50c5951b-749b-11df-b112-00215aee3ebe
739dec3e-717c-11ee-80c1-000c2961d091;Дніпро-Гагаріна 23 One Price;Дніпро Просп. Науки ( Гагаріна ), 23;50c5951b-749b-11df-b112-00215aee3ebe
f52fa917-757f-11ee-80c1-000c2961d091;Дніпро-Гагаріна 3 Сільпо;Дніпро Просп. Науки ( Гагаріна ), 3;50c5951b-749b-11df-b112-00215aee3ebe
39c54fdd-140a-11ee-80bd-000c2961d091;Дніпро-Гагаріна 40 Lifecell;Дніпро Просп. Науки ( Гагаріна ), 40;50c5951b-749b-11df-b112-00215aee3ebe
31719a7e-98f6-11ee-80c1-000c2961d091;Дніпро-Гагаріна 74 ZooLand;Дніпро Просп. Науки ( Гагаріна ), 74;50c5951b-749b-11df-b112-00215aee3ebe
157a492f-41c1-11ee-80c0-000c2961d091;Дніпро-Гагаріна 8А Lifecell;Дніпро Просп. Науки ( Гагаріна ), 8А;50c5951b-749b-11df-b112-00215aee3ebe
1392083e-f123-11ec-80e7-000c29800ae7;Дніпро-Гагаріна 8А Rozetka;Дніпро Просп. Науки ( Гагаріна ), 8А;50c5951b-749b-11df-b112-00215aee3ebe
d0c630f3-0c47-11ea-80e7-1c98ec135261;Дніпро-Гагаріна 8А Фонаревка;Дніпро Просп. Науки ( Гагаріна ), 8А;50c5951b-749b-11df-b112-00215aee3ebe
00f48ee1-0751-11ed-80e7-000c29800ae7;Дніпро-Гагаріна 8А Хімчистка;Дніпро Просп. Науки ( Гагаріна ), 8А;50c5951b-749b-11df-b112-00215aee3ebe
143c020e-717c-11ee-80c1-000c2961d091;Дніпро-Героїв 11Л One Price;Дніпро Просп. Героїв, 11Л;50c5951b-749b-11df-b112-00215aee3ebe
e025c4f6-ff3f-11eb-80d6-000c29800ae7;Дніпро-Героїв 12 Європошта;Дніпро Просп. Героїв, 12;50c5951b-749b-11df-b112-00215aee3ebe
2363f639-894b-11ee-80c1-000c2961d091;Дніпро-Героїв 12 ЗооМаг;Дніпро Просп. Героїв, 12;50c5951b-749b-11df-b112-00215aee3ebe
892f49a4-6dbc-11ee-80c1-000c2961d091;Дніпро-Героїв 12 Кавярня;Дніпро Просп. Героїв, 12;50c5951b-749b-11df-b112-00215aee3ebe
1efbc881-074f-11ed-80e7-000c29800ae7;Дніпро-Героїв 2 Хімчистка;Дніпро Просп. Героїв, 2;50c5951b-749b-11df-b112-00215aee3ebe
fa80721f-f121-11ec-80e7-000c29800ae7;Дніпро-Героїв 3 Rozetka;Дніпро Просп. Героїв, 3;50c5951b-749b-11df-b112-00215aee3ebe
6b341b97-3d7f-11ec-80e3-000c29800ae7;Дніпро-Героїв 33 Келих;Дніпро Просп. Героїв, 33;50c5951b-749b-11df-b112-00215aee3ebe
41c867e1-98f2-11ee-80c1-000c2961d091;Дніпро-Гетьманська 9 прим.3/3 ZooLand;Дніпро вул. Гетьманська, 9;50c5951b-749b-11df-b112-00215aee3ebe
6ca958ab-21d4-11ec-80df-000c29800ae7;Дніпро-Гладкова 16 зооТРИОВЕТ;Дніпро вул. Гладкова, 16;50c5951b-749b-11df-b112-00215aee3ebe
2af2e4f9-e3fd-11ed-80ed-000c29800ae7;Дніпро-Гладкова 25 Делві;Дніпро вул. Гладкова, 25;50c5951b-749b-11df-b112-00215aee3ebe
9bfb0279-d83f-11ed-80ed-000c29800ae7;Дніпро-Донецьке 124К ASIA PARTS;Дніпро шосе Донецьке, 124К;50c5951b-749b-11df-b112-00215aee3ebe
60872820-fab1-11eb-80d6-000c29800ae7;Дніпро-Донецьке 152/А1 Делві;Дніпро шосе Донецьке, 152/А1;50c5951b-749b-11df-b112-00215aee3ebe
b1a2ae23-b6c4-11ee-80c4-000c2961d091;Дніпро-Донецьке 9А Andi;Дніпро шосе Донецьке, 9А;50c5951b-749b-11df-b112-00215aee3ebe
6661a48b-757c-11ee-80c1-000c2961d091;Дніпро-Європейська 18А Сільпо;Дніпро вул. Європейська (Миронова), 18А;50c5951b-749b-11df-b112-00215aee3ebe
1af16c07-c118-11ea-80ca-000c29800ae7;Дніпро-Загорянська 1А ОККО;Дніпро вул. Загорянська, 1А;50c5951b-749b-11df-b112-00215aee3ebe
6b5891f6-074f-11ed-80e7-000c29800ae7;Дніпро-Зоряний 1А Хімчистка;Дніпро бул. Зоряний, 1А;50c5951b-749b-11df-b112-00215aee3ebe
9295a55d-70bf-11ed-80ec-000c29800ae7;Дніпро-Зоряний 1Б Rozetka;Дніпро бул. Зоряний, 1Б;50c5951b-749b-11df-b112-00215aee3ebe
23b4ccd1-8a13-11ee-80c1-000c2961d091;Дніпро-Івана Мазепи 25/1 EURO-OPT;Дніпро Просп. Івана Мазепи (Петровського), 25/1;50c5951b-749b-11df-b112-00215aee3ebe
03bf8cbc-6401-11ed-80ec-000c29800ae7;Дніпро-Івана Мазепи 3 Делві;Дніпро Просп. Івана Мазепи (Петровського), 3;50c5951b-749b-11df-b112-00215aee3ebe
0b05445f-8a15-11ee-80c1-000c2961d091;Дніпро-Івана Мазепи 42 EURO-OPT;Дніпро Просп. Івана Мазепи (Петровського), 42;50c5951b-749b-11df-b112-00215aee3ebe
e535f264-57b8-11ee-80c0-000c2961d091;Дніпро-Івана Мазепи 51Б Rozetka;Дніпро Просп. Івана Мазепи (Петровського), 51Б;50c5951b-749b-11df-b112-00215aee3ebe
c0f762d7-9025-11ed-80ec-000c29800ae7;Дніпро-Івана Мазепи 52 зооКот Бегемот;Дніпро Просп. Івана Мазепи (Петровського), 52;50c5951b-749b-11df-b112-00215aee3ebe
0eeee586-913f-11eb-80d2-000c29800ae7;Дніпро-Івана Мазепи 58 Флешка;Дніпро Просп. Івана Мазепи (Петровського), 58;50c5951b-749b-11df-b112-00215aee3ebe
24bff401-63ff-11ed-80ec-000c29800ae7;Дніпро-Кадрова 30,літ.А-1 Делві;Дніпро вул. Кадрова, 30,літ.А-1;50c5951b-749b-11df-b112-00215aee3ebe
9a7ecca2-984c-11ee-80c1-000c2961d091;Дніпро-Калинова 1/2 Tabachini;Дніпро вул. Калинова, 1/2;50c5951b-749b-11df-b112-00215aee3ebe
7952370b-70ba-11ed-80ec-000c29800ae7;Дніпро-Калинова 3 Rozetka;Дніпро вул. Калинова, 3;50c5951b-749b-11df-b112-00215aee3ebe
d5281273-7330-11ee-80c1-000c2961d091;Дніпро-Калинова 49 Mister Bags;Дніпро вул. Калинова, 49;50c5951b-749b-11df-b112-00215aee3ebe
180d2908-8a10-11ee-80c1-000c2961d091;Дніпро-Калинова 53 EURO-OPT;Дніпро вул. Калинова, 53;50c5951b-749b-11df-b112-00215aee3ebe
8986e454-4b9c-11ec-80e3-000c29800ae7;Дніпро-Калинова 79 Водолій;Дніпро вул. Калинова, 79;50c5951b-749b-11df-b112-00215aee3ebe
5b42d999-b66e-11ed-80ec-000c29800ae7;Дніпро-Калинова 82Д Lifecell;Дніпро вул. Калинова, 82Д;50c5951b-749b-11df-b112-00215aee3ebe
307bb30c-342f-11e9-80dd-1c98ec135261;Дніпро-Калинова 87Л ОККО;Дніпро вул. Калинова, 87Л;50c5951b-749b-11df-b112-00215aee3ebe
67e26c18-0752-11ed-80e7-000c29800ae7;Дніпро-Калинова 9А Хімчистка;Дніпро вул. Калинова, 9А;50c5951b-749b-11df-b112-00215aee3ebe
78043c5e-d8be-11ea-80ca-000c29800ae7;Дніпро-Каштанова 4К ОККО;Дніпро вул. Каштанова, 4К;50c5951b-749b-11df-b112-00215aee3ebe
9d093b7b-faaa-11eb-80d6-000c29800ae7;Дніпро-Квітки Цісик 12Б Делві;Дніпро вул. Квітки Цісик (Корольової), 12Б;50c5951b-749b-11df-b112-00215aee3ebe
f131b491-8a08-11ee-80c1-000c2961d091;Дніпро-Князя Володимира Великого ;Дніпро вул. Князя Володимира Великого ( Плєханова ), 5а;50c5951b-749b-11df-b112-00215aee3ebe
f9b9ad72-a64f-11ee-80c4-000c2961d091;Дніпро-Ковалевської Софії 61А Делві;Дніпро вул. Ковалевської Софії, 61А;50c5951b-749b-11df-b112-00215aee3ebe
6e4494d1-3e65-11e9-80dd-1c98ec135261;Дніпро-Комбрига Петрова 4Д Дим Димич;Дніпро вул. Комбрига Петрова, 4Д;50c5951b-749b-11df-b112-00215aee3ebe
2c493b23-c410-11ee-80c4-000c2961d091;Дніпро-Коробова 2 Tabachini;Дніпро вул. Коробова, 2;50c5951b-749b-11df-b112-00215aee3ebe
d904e400-074e-11ed-80e7-000c29800ae7;Дніпро-Королеви Єлизавети ІІ 1 Хімчистка;Дніпро вул. Королеви Єлизавети ІІ (Глінки), 1;50c5951b-749b-11df-b112-00215aee3ebe
b3c65aee-730c-11e9-80df-1c98ec135261;Дніпро-Королеви Єлизавети ІІ 12 іMaster;Дніпро вул. Королеви Єлизавети ІІ (Глінки), 12;50c5951b-749b-11df-b112-00215aee3ebe
7dd1b161-0750-11ed-80e7-000c29800ae7;Дніпро-Королеви Єлизавети ІІ 2 Хімчистка;Дніпро вул. Королеви Єлизавети ІІ (Глінки), 2;50c5951b-749b-11df-b112-00215aee3ebe
c615b3a9-0ebe-11eb-80cd-000c29800ae7;Дніпро-Космічна 32А ОККО;Дніпро вул. Космічна (Соборний (Жовтневий)), 32А;50c5951b-749b-11df-b112-00215aee3ebe
9f1068ca-faab-11eb-80d6-000c29800ae7;Дніпро-Котляревського 5А Делві;Дніпро вул. Котляревського, 5А;50c5951b-749b-11df-b112-00215aee3ebe
43cdddac-e859-11ee-80c5-000c2961d091;Дніпро-Красноводська 2 Будівельні матеріали;Дніпро вул. Красноводська, 2;50c5951b-749b-11df-b112-00215aee3ebe
02296bf4-faa3-11eb-80d6-000c29800ae7;Дніпро-Кремінна 9 Делві;Дніпро вул. Кремінна (Кольська), 9;50c5951b-749b-11df-b112-00215aee3ebe
5af52c0a-757d-11ee-80c1-000c2961d091;Дніпро-Крушельницької 6А Сільпо;Дніпро пров. Крушельницької (Мокієвської), 6А;50c5951b-749b-11df-b112-00215aee3ebe
7c19cc19-e10d-11ee-80c4-000c2961d091;Дніпро-Лазаряна 3 Домашні напівфабрикати;Дніпро вул. Лазаряна, 3;50c5951b-749b-11df-b112-00215aee3ebe
6964cc9f-8a15-11ee-80c1-000c2961d091;Дніпро-Левка Лук'яненка 2Е EURO-OPT;Дніпро вул. Левка Лук'яненка ( Жуковського Василя ), 2Е;50c5951b-749b-11df-b112-00215aee3ebe
34ef4e2d-e841-11ee-80c5-000c2961d091;Дніпро-Лесі Українки 69прим.5 Делві;Дніпро Просп. Лесі Українки (Пушкіна), 69;50c5951b-749b-11df-b112-00215aee3ebe
daac48a1-a7b7-11e9-80e4-1c98ec135261;Дніпро-Лисиченко Марії 12Б ОККО;Дніпро вул. Лисиченко Марії (Героїв Громад. Війни), 12Б;50c5951b-749b-11df-b112-00215aee3ebe
d1ab4dd0-3d7e-11ec-80e3-000c29800ae7;Дніпро-Лисиченко Марії 17 Келих;Дніпро вул. Лисиченко Марії (Героїв Громад. Війни), 17;50c5951b-749b-11df-b112-00215aee3ebe
f86b1a33-fab0-11eb-80d6-000c29800ae7;Дніпро-Литвищенка 62Л Делві;Дніпро вул. Литвищенка, 62Л;50c5951b-749b-11df-b112-00215aee3ebe
cfc0cbdf-3432-11e9-80dd-1c98ec135261;Дніпро-Любарського 2М ОККО;Дніпро вул. Любарського (Білостоцького), 2М;50c5951b-749b-11df-b112-00215aee3ebe
0bdf08d3-d398-11ed-80ed-000c29800ae7;Дніпро-Магдалинівська 1М Фірмова крамниця;Дніпро вул. Магдалинівська, 1М;50c5951b-749b-11df-b112-00215aee3ebe
11159ae2-46c7-11ec-80e3-000c29800ae7;Дніпро-Мануйлівський 19 Воронцовська аптека;Дніпро Просп. Мануйлівський (Воронцова), 19;50c5951b-749b-11df-b112-00215aee3ebe
ff9bac54-984b-11ee-80c1-000c2961d091;Дніпро-Маршала Малиновського 14А Tabachini;Дніпро вул. Маршала Малиновського, 14А;50c5951b-749b-11df-b112-00215aee3ebe
94a23d6a-bf7a-11ee-80c4-000c2961d091;Дніпро-Маршала Малиновського 2 Jetclean;Дніпро вул. Маршала Малиновського, 2;50c5951b-749b-11df-b112-00215aee3ebe
7c45ade5-faa5-11eb-80d6-000c29800ae7;Дніпро-Менахем-Мендл Шнеєрсона 11 Делві;Дніпро вул. Менахем-Мендл Шнеєрсона (Мініна), 11;50c5951b-749b-11df-b112-00215aee3ebe
8008ca7a-ade7-11ed-80ec-000c29800ae7;Дніпро-Метробудівська 19 Rozetka;Дніпро вул. Метробудівська, 19;50c5951b-749b-11df-b112-00215aee3ebe
0613113a-8a11-11ee-80c1-000c2961d091;Дніпро-Миколи Руденка 53 EURO-OPT;Дніпро вул. Миколи Руденка (Войцеховича), 53;50c5951b-749b-11df-b112-00215aee3ebe
e94fe5c0-b5f1-11ee-80c4-000c2961d091;Дніпро-Миру 12 Andi;Дніпро Просп. Миру, 12;50c5951b-749b-11df-b112-00215aee3ebe
868924a5-6dbf-11ee-80c1-000c2961d091;Дніпро-Миру 14А Rozetka;Дніпро Просп. Миру, 14А;50c5951b-749b-11df-b112-00215aee3ebe
1ae09621-d789-11ed-80ed-000c29800ae7;Дніпро-Миру 14А зооЛапа;Дніпро Просп. Миру, 14А;50c5951b-749b-11df-b112-00215aee3ebe
e1a333b8-8a0a-11ee-80c1-000c2961d091;Дніпро-Михайла Грушевського 1 EURO-OPT;Дніпро вул. Михайла Грушевського (Лібкнехта Карла), 1;50c5951b-749b-11df-b112-00215aee3ebe
b43e9643-984a-11ee-80c1-000c2961d091;Дніпро-Михайла Грушевського 60/4 Tabachini;Дніпро вул. Михайла Грушевського (Лібкнехта Карла), 60/4;50c5951b-749b-11df-b112-00215aee3ebe
7cc77e4a-57b8-11ee-80c0-000c2961d091;Дніпро-Михайла Грушевського 85Д Rozetka;Дніпро вул. Михайла Грушевського (Лібкнехта Карла), 85Д;50c5951b-749b-11df-b112-00215aee3ebe
b9ff6c70-c53a-11eb-80d5-000c29800ae7;Дніпро-Міхновського / Щербини / 4Д Ломбард;Дніпро вул. Міхновського / Щербини /, 4Д;50c5951b-749b-11df-b112-00215aee3ebe
b0e821c1-1c40-11ec-80df-000c29800ae7;Дніпро-Молодогвардійська 45 Делві;Дніпро вул. Тероборони ( Молодогвардійська ), 45;50c5951b-749b-11df-b112-00215aee3ebe
9e4e8d56-8a0b-11ee-80c1-000c2961d091;Дніпро-Моніторна 2 EURO-OPT;Дніпро вул. Моніторна, 2;50c5951b-749b-11df-b112-00215aee3ebe
478a083d-644f-11ee-80c0-000c2961d091;Дніпро-Набережна Заводська 101Л Крамниця;Дніпро вул. Набережна Заводська, 101Л;50c5951b-749b-11df-b112-00215aee3ebe
dc612f17-d8be-11ea-80ca-000c29800ae7;Дніпро-Набережна Перемоги 3М ОККО;Дніпро наб. Набережна Перемоги, 3М;50c5951b-749b-11df-b112-00215aee3ebe
aa43117b-1f1e-11ee-80bd-000c2961d091;Дніпро-Набережна Перемоги 42Ф Rozetka;Дніпро наб. Набережна Перемоги, 42Ф;50c5951b-749b-11df-b112-00215aee3ebe
308b9549-8a0a-11ee-80c1-000c2961d091;Дніпро-Набережна Перемоги 82 EURO-OPT;Дніпро наб. Набережна Перемоги, 82;50c5951b-749b-11df-b112-00215aee3ebe
fb6d5686-fab7-11eb-80d6-000c29800ae7;Дніпро-Народна 7 Делві;Дніпро вул. Народна (Ленинский), 7;50c5951b-749b-11df-b112-00215aee3ebe
ec014a6b-e6a6-11ee-80c5-000c2961d091;Дніпро-Науки 96 EURO-OPT;Дніпро Просп. Науки ( Гагаріна ), 96;50c5951b-749b-11df-b112-00215aee3ebe
8275cec3-faad-11eb-80d6-000c29800ae7;Дніпро-Немировича-Данченка 48 Делві;Дніпро вул. Немировича-Данченка, 48;50c5951b-749b-11df-b112-00215aee3ebe
c92fb415-f123-11ec-80e7-000c29800ae7;Дніпро-Нижньодніпровська 17 Rozetka;Дніпро вул. Нижньодніпровська, 17;50c5951b-749b-11df-b112-00215aee3ebe
7d3ef11f-3d7c-11ec-80e3-000c29800ae7;Дніпро-Новокодацька 3 Келих;Дніпро пл. Новокодацька, 3;50c5951b-749b-11df-b112-00215aee3ebe
e6c1bdb1-7580-11ee-80c1-000c2961d091;Дніпро-Новокримська 3А Сільпо;Дніпро вул. Новокримська, 3А;50c5951b-749b-11df-b112-00215aee3ebe
9d0caf6d-3cd5-11ee-80c0-000c2961d091;Дніпро-Новоорловська 7 Делві;Дніпро вул. Новоорловська, 7;50c5951b-749b-11df-b112-00215aee3ebe
61fe06eb-e840-11ee-80c5-000c2961d091;Дніпро-Новорічна 75А-9 Делві;Дніпро вул. Новорічна, 75;50c5951b-749b-11df-b112-00215aee3ebe
b167d61c-35cc-11ea-80e7-1c98ec135261;Дніпро-Новочеркаська, 211;Дніпро вул. Переволочанська (кол. Новочеркаська), 211;50c5951b-749b-11df-b112-00215aee3ebe
d8e837c7-7c83-11ee-80c1-000c2961d091;Дніпро-Орловська 28 Делві;Дніпро вул. Орловська, 28;50c5951b-749b-11df-b112-00215aee3ebe
c9c6c39a-2837-11eb-80ce-000c29800ae7;Дніпро-Панікахи 15;Дніпро вул. Панікахи, 15;50c5951b-749b-11df-b112-00215aee3ebe
6e2f9025-48d8-11ea-80c6-000c29800ae7;Дніпро-Панікахи 31 Г;Дніпро вул. Панікахи, 31Г;50c5951b-749b-11df-b112-00215aee3ebe
9603a43c-f124-11ec-80e7-000c29800ae7;Дніпро-Панікахи 61 Rozetka;Дніпро вул. Панікахи, 61;50c5951b-749b-11df-b112-00215aee3ebe
816b1bb2-773c-11ee-80c1-000c2961d091;Дніпро-Парусний 10 Хімчистка;Дніпро пров. Парусний, 10;50c5951b-749b-11df-b112-00215aee3ebe
e1320da0-9849-11ee-80c1-000c2961d091;Дніпро-Парусний 15 Tabachini;Дніпро пров. Парусний, 15;50c5951b-749b-11df-b112-00215aee3ebe
2bd35aa2-b66f-11ed-80ec-000c29800ae7;Дніпро-Парусний 7А Lifecell;Дніпро пров. Парусний, 7А;50c5951b-749b-11df-b112-00215aee3ebe
3552076e-4190-11ee-80c0-000c2961d091;Дніпро-Пастера 6/8 Хімчистка;Дніпро вул. Пастера, 6/8;50c5951b-749b-11df-b112-00215aee3ebe
5e2934af-578d-11ee-80c0-000c2961d091;Дніпро-Пастера 6А Сільпо;Дніпро вул. Пастера, 6А;50c5951b-749b-11df-b112-00215aee3ebe
42a7a77d-eee0-11eb-80d5-000c29800ae7;Дніпро-Передова 427 Крамниця;Дніпро вул. Передова, 427;50c5951b-749b-11df-b112-00215aee3ebe
c596d5ad-0c35-11ee-80bd-000c2961d091;Дніпро-Передова 427К Делві;Дніпро вул. Передова, 427К;50c5951b-749b-11df-b112-00215aee3ebe
dc4b9562-9fec-11ee-80c4-000c2961d091;Дніпро-Передова 511 Ломбард;Дніпро вул. Передова, 511;50c5951b-749b-11df-b112-00215aee3ebe
6f667afa-69d2-11ee-80c1-000c2961d091;Дніпро-Петра Калнишевського 18/67 СПЕЦ;Дніпро Просп. Петра Калнишевського (Косіора), 18/67;50c5951b-749b-11df-b112-00215aee3ebe
18a15f61-a96e-11ee-80c4-000c2961d091;Дніпро-Північно-донецька 1А Rozetka;Дніпро вул. Північно-донецька, 1А;50c5951b-749b-11df-b112-00215aee3ebe
48655e7e-fab4-11eb-80d6-000c29800ae7;Дніпро-Платонова 10А Делві;Дніпро бул. Платонова, 10А;50c5951b-749b-11df-b112-00215aee3ebe
04da2c1b-a7b7-11e9-80e4-1c98ec135261;Дніпро-Полтавське шосе 11К ОККО;Дніпро шосе Полтавське шосе, 11К;50c5951b-749b-11df-b112-00215aee3ebe
f93d8bb9-074d-11ed-80e7-000c29800ae7;Дніпро-Поля Олександра 104А Jetclean;Дніпро Просп. Поля Олександра (Кірова), 104А;50c5951b-749b-11df-b112-00215aee3ebe
118fbec1-3045-11ee-80bd-000c2961d091;Дніпро-Поля Олександра 104А Lifecell;Дніпро Просп. Поля Олександра (Кірова), 104А;50c5951b-749b-11df-b112-00215aee3ebe
e407e366-deb7-11e8-80d7-1c98ec135261;Дніпро-Поля Олександра 11 Best Device;Дніпро Просп. Поля Олександра (Кірова), 11;50c5951b-749b-11df-b112-00215aee3ebe
752e8c34-d9b1-11eb-80d5-000c29800ae7;Дніпро-Поля Олександра 14 Зоо Еліта;Дніпро Просп. Поля Олександра (Кірова), 14;50c5951b-749b-11df-b112-00215aee3ebe
8b1cef22-98f4-11ee-80c1-000c2961d091;Дніпро-Поля Олександра 59 ZooLand;Дніпро Просп. Поля Олександра (Кірова), 59;50c5951b-749b-11df-b112-00215aee3ebe
a1a44f46-511b-11ed-80eb-000c29800ae7;Дніпро-Поля Олександра 64 зооForpets;Дніпро Просп. Поля Олександра (Кірова), 64;50c5951b-749b-11df-b112-00215aee3ebe
1f1cd00b-8a0f-11ee-80c1-000c2961d091;Дніпро-Поля Олександра 66 EURO-OPT;Дніпро Просп. Поля Олександра (Кірова), 66;50c5951b-749b-11df-b112-00215aee3ebe
9766f4e5-e6a7-11ee-80c5-000c2961d091;Дніпро-Поля Олександра 88 прим. 61 EURO-OPT;Дніпро Просп. Поля Олександра (Кірова), 88;50c5951b-749b-11df-b112-00215aee3ebe
a3ef1b08-f125-11ec-80e7-000c29800ae7;Дніпро-Поля Олександра 8А Rozetka;Дніпро Просп. Поля Олександра (Кірова), 8А;50c5951b-749b-11df-b112-00215aee3ebe
3a54f9c0-e6a7-11ee-80c5-000c2961d091;Дніпро-Прогресивна 17 EURO-OPT;Дніпро вул. Прогресивна, 17;50c5951b-749b-11df-b112-00215aee3ebe
7bcf4acb-fab7-11eb-80d6-000c29800ae7;Дніпро-Проїзджа 2А Делві;Дніпро вул. Проїзджа, 2А;50c5951b-749b-11df-b112-00215aee3ebe
f784ec45-bab1-11ea-80ca-000c29800ae7;Дніпро-Робоча 146 Мастер ОК;Дніпро вул. Робоча, 146;50c5951b-749b-11df-b112-00215aee3ebe
12eaefd3-fab7-11eb-80d6-000c29800ae7;Дніпро-Робоча 146/46 Делві;Дніпро вул. Робоча, 146/46;50c5951b-749b-11df-b112-00215aee3ebe
13f69106-3d7f-11ec-80e3-000c29800ae7;Дніпро-Робоча 162 Келих;Дніпро вул. Робоча, 162;50c5951b-749b-11df-b112-00215aee3ebe
92474373-e6a6-11ee-80c5-000c2961d091;Дніпро-Робоча 168 EURO-OPT;Дніпро вул. Робоча, 168;50c5951b-749b-11df-b112-00215aee3ebe
b7f5db5f-71a2-11ee-80c1-000c2961d091;Дніпро-Робоча 23В Кавярня;Дніпро вул. Робоча, 23В;50c5951b-749b-11df-b112-00215aee3ebe
2be27edd-fab3-11eb-80d6-000c29800ae7;Дніпро-Робоча 24Д Делві;Дніпро вул. Робоча, 24Д;50c5951b-749b-11df-b112-00215aee3ebe
4c7f0a57-3431-11e9-80dd-1c98ec135261;Дніпро-Робоча 84 ОККО;Дніпро вул. Робоча, 84;50c5951b-749b-11df-b112-00215aee3ebe
06a2776d-5816-11ec-80e4-000c29800ae7;Дніпро-Робоча 89 Делві;Дніпро вул. Робоча, 89;50c5951b-749b-11df-b112-00215aee3ebe
d60aac3d-98f1-11ee-80c1-000c2961d091;Дніпро-Роторна 25 ZooLand;Дніпро вул. Роторна, 25;50c5951b-749b-11df-b112-00215aee3ebe
105efe68-78a6-11ee-80c1-000c2961d091;Дніпро-Савкіна 2 салон взуття Areg;Дніпро вул. Савкіна, 2;50c5951b-749b-11df-b112-00215aee3ebe
f1adcd27-a64d-11ee-80c4-000c2961d091;Дніпро-Савченка Юрія 46А-1 Делві;Дніпро вул. Савченка Юрія, 46А-1;50c5951b-749b-11df-b112-00215aee3ebe
1475b477-d9b0-11eb-80d5-000c29800ae7;Дніпро-Савченка Юрія 67А зооЗоо Еліта;Дніпро вул. Савченка Юрія, 67А;50c5951b-749b-11df-b112-00215aee3ebe
4c4c06f7-fab2-11eb-80d6-000c29800ae7;Дніпро-Святогірська 10 Делві;Дніпро вул. Святогірська, 10;50c5951b-749b-11df-b112-00215aee3ebe
c49843f1-e840-11ee-80c5-000c2961d091;Дніпро-Семафорна 13А-9 Делві;Дніпро вул. Семафорна, 13;50c5951b-749b-11df-b112-00215aee3ebe
08200e99-0753-11ed-80e7-000c29800ae7;Дніпро-Сергія Єфремова 25 Хімчистка;Дніпро вул. Сергія Єфремова (Ворошилова), 25;50c5951b-749b-11df-b112-00215aee3ebe
346029ed-faa6-11eb-80d6-000c29800ae7;Дніпро-Сергія Подолинського 10А Делві;Дніпро вул. Сергія Подолинського (Благоєва), 10А;50c5951b-749b-11df-b112-00215aee3ebe
12363779-fab0-11eb-80d6-000c29800ae7;Дніпро-Синявського 209 Делві;Дніпро пров. Синявського ( 40-річчя Комсомолу ), 209;50c5951b-749b-11df-b112-00215aee3ebe
aa74f7c5-b9ff-11ee-80c4-000c2961d091;Дніпро-Слави 28 зоомагазин Forpets;Дніпро бул. Слави, 28;50c5951b-749b-11df-b112-00215aee3ebe
7687f70d-6d90-11ee-80c1-000c2961d091;Дніпро-Слави 45 Rozetka;Дніпро бул. Слави, 45;50c5951b-749b-11df-b112-00215aee3ebe
447cab3d-74ba-11ee-80c1-000c2961d091;Дніпро-Слави 5 Сільпо;Дніпро бул. Слави, 5;50c5951b-749b-11df-b112-00215aee3ebe
a86b026c-0752-11ed-80e7-000c29800ae7;Дніпро-Слави 5 Хімчистка;Дніпро бул. Слави, 5;50c5951b-749b-11df-b112-00215aee3ebe
36c50e1d-d3f7-11eb-80d5-000c29800ae7;Дніпро-Слобожанський 1 Ломбард;Дніпро Просп. Слобожанський, 1;50c5951b-749b-11df-b112-00215aee3ebe
8e3a0f81-8a13-11ee-80c1-000c2961d091;Дніпро-Слобожанський 13 EURO-OPT;Дніпро Просп. Слобожанський, 13;50c5951b-749b-11df-b112-00215aee3ebe
0938a29c-757f-11ee-80c1-000c2961d091;Дніпро-Слобожанський 31Д Сільпо;Дніпро Просп. Слобожанський, 31Д;50c5951b-749b-11df-b112-00215aee3ebe
f2bfcc28-578c-11ee-80c0-000c2961d091;Дніпро-Слобожанський 76/78 Сільпо;Дніпро Просп. Слобожанський, 76/78;50c5951b-749b-11df-b112-00215aee3ebe
7b0313ea-b5f3-11ee-80c4-000c2961d091;Дніпро-Слобожанський 89 Andi;Дніпро Просп. Слобожанський, 89;50c5951b-749b-11df-b112-00215aee3ebe
931390e6-faac-11eb-80d6-000c29800ae7;Дніпро-Сонячна Набережна 12 Делві;Дніпро вул. Сонячна Набережна (Малиновського), 12;50c5951b-749b-11df-b112-00215aee3ebe
c95002c2-e00d-11ed-80ed-000c29800ae7;Дніпро-Сонячна Набережна 2 Rozetka;Дніпро вул. Сонячна Набережна (Малиновського), 2;50c5951b-749b-11df-b112-00215aee3ebe
4ef352f6-5815-11ec-80e4-000c29800ae7;Дніпро-Сонячна Набережна 8 Делві;Дніпро вул. Сонячна Набережна (Малиновського), 8;50c5951b-749b-11df-b112-00215aee3ebe
a2364159-df14-11eb-80d5-000c29800ae7;Дніпро-Спогадів 181 Сантехніка Електрика;Дніпро вул. Спогадів, 181;50c5951b-749b-11df-b112-00215aee3ebe
aa27314c-b66f-11ed-80ec-000c29800ae7;Дніпро-Старий шлях 7А Мобільний зв’язок;Дніпро вул. Старий шлях (Кірова), 7А;50c5951b-749b-11df-b112-00215aee3ebe
e7c39797-00a4-11ea-80e7-1c98ec135261;Дніпро-Старокозацька 74В DniproComp;Дніпро вул. Старокозацька (Комсомольська), 74В;50c5951b-749b-11df-b112-00215aee3ebe
75209115-5c31-11ee-80c0-000c2961d091;Дніпро-Старомостова 2 СПЕЦ;Дніпро пл. Старомостова (Островського Миколи), 2;50c5951b-749b-11df-b112-00215aee3ebe
7ef7b8ca-fab0-11eb-80d6-000c29800ae7;Дніпро-Татарська 44Д Делві;Дніпро вул. Татарська, 44Д;50c5951b-749b-11df-b112-00215aee3ebe
b587d5d0-35a3-11ed-80e9-000c29800ae7;Дніпро-Тверська 19 КРЕАТИВ;Дніпро вул. Тверська, 19;50c5951b-749b-11df-b112-00215aee3ebe
1edc32f0-faab-11eb-80d6-000c29800ae7;Дніпро-Тверська 2 Делві;Дніпро вул. Тверська, 2;50c5951b-749b-11df-b112-00215aee3ebe
0959104a-717b-11ee-80c1-000c2961d091;Дніпро-Театральний 3 One Price;Дніпро бул. Театральний, 3;50c5951b-749b-11df-b112-00215aee3ebe
1c0bab13-faac-11eb-80d6-000c29800ae7;Дніпро-Телевізійна 4 Делві;Дніпро вул. Телевізійна, 4;50c5951b-749b-11df-b112-00215aee3ebe
cabaf9a5-41d4-11ee-80c0-000c2961d091;Дніпро-Терещенківська 25Б Koffee and Tea;Дніпро вул. Терещенківська (Червоного Козацтва), 25Б;50c5951b-749b-11df-b112-00215aee3ebe
c08f050d-c410-11ee-80c4-000c2961d091;Дніпро-Терещенківська 27 Tabachini;Дніпро вул. Терещенківська (Червоного Козацтва), 27;50c5951b-749b-11df-b112-00215aee3ebe
137d6fa1-3d7c-11ec-80e3-000c29800ae7;Дніпро-Титова 11 Келих;Дніпро вул. Титова, 11;50c5951b-749b-11df-b112-00215aee3ebe
70e694f1-6427-11ed-80ec-000c29800ae7;Дніпро-Титова 16 Кавярня;Дніпро вул. Титова, 16;50c5951b-749b-11df-b112-00215aee3ebe
0110405a-f121-11ec-80e7-000c29800ae7;Дніпро-Титова 32 Rozetka;Дніпро вул. Титова, 32;50c5951b-749b-11df-b112-00215aee3ebe
e7cdd4bd-0c4a-11ea-80e7-1c98ec135261;Дніпро-Титова 36 Fonarevka.ua;Дніпро вул. Титова, 36;50c5951b-749b-11df-b112-00215aee3ebe
7fecdd16-757f-11ee-80c1-000c2961d091;Дніпро-Титова 36 Сільпо;Дніпро вул. Титова, 36;50c5951b-749b-11df-b112-00215aee3ebe
c637003b-ba31-11eb-80d4-000c29800ae7;Дніпро-Титова 6 Ломбард;Дніпро вул. Титова, 6;50c5951b-749b-11df-b112-00215aee3ebe
6da85bd3-7581-11ee-80c1-000c2961d091;Дніпро-Тополина 1 Сільпо;Дніпро вул. Тополина (Шевченківський р-н,(Бабушкинск, 1;50c5951b-749b-11df-b112-00215aee3ebe
9fd5415f-98f5-11ee-80c1-000c2961d091;Дніпро-Тополина 22Н ZooLand;Дніпро вул. Тополина (Шевченківський р-н,(Бабушкинск, 22Н;50c5951b-749b-11df-b112-00215aee3ebe
1e1365cc-21d0-11ec-80df-000c29800ae7;Дніпро-Тополя-1 16 зооТРИОВЕТ;Дніпро ж/м Тополя-1, 16;50c5951b-749b-11df-b112-00215aee3ebe
8edb67f5-fab6-11eb-80d6-000c29800ae7;Дніпро-Тополя-3 48 Делві;Дніпро ж/м Тополя-3, 48;50c5951b-749b-11df-b112-00215aee3ebe
ee8d77e2-98f5-11ee-80c1-000c2961d091;Дніпро-Хмельницького Богдана 109 ZooLand;Дніпро Просп. Хмельницького Богдана (Гер. Сталінграду), 109;50c5951b-749b-11df-b112-00215aee3ebe
b6c5a748-b611-11ea-80c8-000c29800ae7;Дніпро-Хмельницького Богдана 118 Д Fonarevka;Дніпро Просп. Хмельницького Богдана (Гер. Сталінграду), 118Д;50c5951b-749b-11df-b112-00215aee3ebe
73f4ecf2-074e-11ed-80e7-000c29800ae7;Дніпро-Хмельницького Богдана 118Д KIMS;Дніпро Просп. Хмельницького Богдана (Гер. Сталінграду), 118Д;50c5951b-749b-11df-b112-00215aee3ebe
91741a1d-f127-11ec-80e7-000c29800ae7;Дніпро-Хмельницького Богдана 118Д Rozetka;Дніпро Просп. Хмельницького Богдана (Гер. Сталінграду), 118Д;50c5951b-749b-11df-b112-00215aee3ebe
b3a72d1e-8a14-11ee-80c1-000c2961d091;Дніпро-Хмельницького Богдана 133;Дніпро Просп. Хмельницького Богдана (Гер. Сталінграду), 133;50c5951b-749b-11df-b112-00215aee3ebe
d59aaba1-98f4-11ee-80c1-000c2961d091;Дніпро-Хмельницького Богдана 36 ZooLand;Дніпро Просп. Хмельницького Богдана (Гер. Сталінграду), 36;50c5951b-749b-11df-b112-00215aee3ebe
1ecfda08-fab6-11eb-80d6-000c29800ae7;Дніпро-Хмельницького Богдана 93/9 Делві;Дніпро Просп. Хмельницького Богдана (Гер. Сталінграду), 93/9;50c5951b-749b-11df-b112-00215aee3ebe
08677617-8a14-11ee-80c1-000c2961d091;Дніпро-Челюскіна 12 EURO-OPT;Дніпро вул. Челюскіна, 12;50c5951b-749b-11df-b112-00215aee3ebe
a5e1af2b-8a09-11ee-80c1-000c2961d091;Дніпро-Шевченка 10 EURO-OPT;Дніпро вул. Шевченка (Жовтневый), 10;50c5951b-749b-11df-b112-00215aee3ebe
e0fa4d57-074f-11ed-80e7-000c29800ae7;Дніпро-Шевченка 53 Хімчистка;Дніпро вул. Шевченка (Жовтневый), 53;50c5951b-749b-11df-b112-00215aee3ebe
89a0b579-fab3-11eb-80d6-000c29800ae7;Дніпро-Шолохова 29 Делві;Дніпро вул. С Рудницького /Шолохова, 29;50c5951b-749b-11df-b112-00215aee3ebe
21845754-f128-11ec-80e7-000c29800ae7;Дніпро-Шолохова 7 Rozetka;Дніпро вул. С Рудницького /Шолохова, 7;50c5951b-749b-11df-b112-00215aee3ebe
df76c3e1-92d6-11eb-80d3-000c29800ae7;Дніпро-Шухевича Романа 70 ОККО;Дніпро вул. Шухевича Романа (Бабушкіна), 70;50c5951b-749b-11df-b112-00215aee3ebe
4e9ba361-3cd7-11ee-80c0-000c2961d091;Дніпро-Юрія Кондратюка 11Г Делві;Дніпро вул. Юрія Кондратюка, 11Г;50c5951b-749b-11df-b112-00215aee3ebe
416b896b-ab92-11ed-80ec-000c29800ae7;Дніпро-Юрія Кондратюка 2 Rozetka;Дніпро вул. Юрія Кондратюка, 2;50c5951b-749b-11df-b112-00215aee3ebe
b1209f8f-1219-11ec-80da-000c29800ae7;Дніпро-Юрія Кондратюка 255 Продукти;Дніпро вул. Юрія Кондратюка, 255;50c5951b-749b-11df-b112-00215aee3ebe
fd634f9c-757c-11ee-80c1-000c2961d091;Дніпро-Юрія Кондратюка 4 Сільпо;Дніпро вул. Юрія Кондратюка, 4;50c5951b-749b-11df-b112-00215aee3ebe
e3a45902-0c52-11ea-80e7-1c98ec135261;Дніпро-Юрія Кондратюка 8;Дніпро вул. Юрія Кондратюка (Комунарівська), 8;50c5951b-749b-11df-b112-00215aee3ebe
a568591a-f128-11ec-80e7-000c29800ae7;Дніпро-Яворницького 100 Rozetka;Дніпро вул. Яворницького, 100;50c5951b-749b-11df-b112-00215aee3ebe
58b8a745-717a-11ee-80c1-000c2961d091;Дніпро-Яворницького Дмитра 102 One Price;Дніпро Просп. Яворницького Дмитра ( Маркса Карла ), 102;50c5951b-749b-11df-b112-00215aee3ebe
80baf670-8a0f-11ee-80c1-000c2961d091;Дніпро-Яворницького Дмитра 1А EURO-OPT;Дніпро Просп. Яворницького Дмитра ( Маркса Карла ), 1А;50c5951b-749b-11df-b112-00215aee3ebe
b4b82c6d-31f2-11ee-80bd-000c2961d091;Дніпро-Яворницького Дмитра 46 Rozetka;Дніпро Просп. Яворницького Дмитра ( Маркса Карла ), 46;50c5951b-749b-11df-b112-00215aee3ebe
66e941d0-6401-11ed-80ec-000c29800ae7;Дніпро-Янгеля Академіка 14А Делві;Дніпро вул. Янгеля Академіка, 14А;50c5951b-749b-11df-b112-00215aee3ebe
e5f32d84-8a11-11ee-80c1-000c2961d091;Дніпро-Ярослава Мудрого 6 EURO-OPT;Дніпро вул. Ярослава Мудрого (Ленінградська), 6;50c5951b-749b-11df-b112-00215aee3ebe
0a057a77-1726-11ee-80bd-000c2961d091;Добромиль-Торгова 1А;Добромиль вул. Торгова, 1А;50c59553-749b-11df-b112-00215aee3ebe
08dc7256-eedc-11eb-80d5-000c29800ae7;Довбиш-Поліська 10;Довбиш вул. Поліська, 10;50c59576-749b-11df-b112-00215aee3ebe
675a18bc-8ab3-11ee-80c1-000c2961d091;Довжик-Нескорених 22А Євро-продукти;Житомир(Довжик) вул. Нескорених, 22А;50c595b8-749b-11df-b112-00215aee3ebe
1b6ed5ed-fbd2-11ed-80ed-000c29800ae7;Долина-Грушевського 7;Долина вул. Грушевського, 7;50c595df-749b-11df-b112-00215aee3ebe
9caf4fbe-f03e-11eb-80d5-000c29800ae7;Долина-Грушевського 9;Долина вул. Грушевського, 9;50c595df-749b-11df-b112-00215aee3ebe
1f901880-9e68-11e9-80e2-1c98ec135261;Долина-Грушевського Михайла 17;Долина вул. Грушевського, 17;50c595df-749b-11df-b112-00215aee3ebe
b9c4018e-a79d-11e9-80e4-1c98ec135261;Долина-Обліски 131 А;Долина вул. Обліски, 131А;50c595df-749b-11df-b112-00215aee3ebe
7977bba8-af1a-11eb-80d4-000c29800ae7;Долина-Пушкіна 4;Долина вул. Героїв України (Пушкіна), 4;50c595df-749b-11df-b112-00215aee3ebe
8aef7425-6e57-11ee-80c1-000c2961d091;ДОРУЧЕННЯ РОСАН;Тернопіль вул. Гайова, 64;80f55c6a-749b-11df-b112-00215aee3ebe
4f319c12-886a-11ee-80c1-000c2961d091;ДОРУЧЕННЯ РОСАН РН;;7ad950ad-749b-11df-b112-00215aee3ebe
1bbbdb52-886b-11ee-80c1-000c2961d091;ДОРУЧЕННЯ РОСАН СН;;7ad95481-749b-11df-b112-00215aee3ebe
66d09791-eb6c-11ee-80c5-000c2961d091;ДОРУЧЕННЯ РОСАН ЧР;Чернівці вул. Руська, 248;8716279d-749b-11df-b112-00215aee3ebe
03fd03a3-0513-11ee-80bd-000c2961d091;Дослідне-Полігонний 11 АЗС БРСМ;Дослідне пров. Полігонний, 11;50c59656-749b-11df-b112-00215aee3ebe
027f149f-71b3-11ee-80c1-000c2961d091;Доставка Автолюкс;;00000000-0000-0000-0000-000000000000
ed5f6ecc-71b2-11ee-80c1-000c2961d091;Доставка Нова Пошта;;00000000-0000-0000-0000-000000000000
3248b858-04a5-11eb-80cd-000c29800ae7;Дрогобич;Дрогобич вул. Хмельницького Богдана, 35;50c59683-749b-11df-b112-00215aee3ebe
382ac3ba-7582-11ee-80c1-000c2961d091;Дрогобич-Володимира Великого 1Р Сільпо;Дрогобич вул. Володимира Великого, 1Р;50c59683-749b-11df-b112-00215aee3ebe
d6286438-7581-11ee-80c1-000c2961d091;Дрогобич-Володимира Великого 7 Сільпо;Дрогобич вул. Володимира Великого, 7;50c59683-749b-11df-b112-00215aee3ebe
4433e48b-cc86-11ed-80ed-000c29800ae7;Дрогобич-Грушевського 17 Rozetka;Дрогобич вул. Грушевського, 17;50c59683-749b-11df-b112-00215aee3ebe
380daf0a-5e1e-11ee-80c0-000c2961d091;Дрогобич-Грушевського Михайла 58 Арт світло;Дрогобич вул. Грушевського Михайла, 58;50c59683-749b-11df-b112-00215aee3ebe
734c0dad-9115-11ea-80c6-000c29800ae7;Дрогобич-Мазепи Івана 6 Телефони та аксесуари;Дрогобич вул. Мазепи Івана, 6;50c59683-749b-11df-b112-00215aee3ebe
e8822968-b2a4-11ed-80ec-000c29800ae7;Дрогобич-Ринок 10 Візовий центр;Дрогобич пл. Ринок, 10;50c59683-749b-11df-b112-00215aee3ebe
b6b63244-61a5-11ec-80e4-000c29800ae7;Дрогобич-Ринок 20 Сім23;Дрогобич пл. Ринок, 20;50c59683-749b-11df-b112-00215aee3ebe
ab431960-3075-11ee-80bd-000c2961d091;Дрогобич-Ринок 21 Lifecell;Дрогобич пл. Ринок, 21;50c59683-749b-11df-b112-00215aee3ebe
ea0fe1ef-2ed1-11e9-80dd-1c98ec135261;Дрогобич-Самбірська 128/2 ОККО;Дрогобич вул. Самбірська, 128/2;50c59683-749b-11df-b112-00215aee3ebe
11b79164-2f88-11ee-80bd-000c2961d091;Дрогобич-Самбірська 29А Zavod Parketu;Дрогобич вул. Самбірська, 29А;50c59683-749b-11df-b112-00215aee3ebe
3fe396a7-d8bc-11ea-80ca-000c29800ae7;Дрогобич-Стрийська 274/1 ОККО;Дрогобич вул. Стрийська, 274/1;50c59683-749b-11df-b112-00215aee3ebe
cd158756-68f7-11ee-80c1-000c2961d091;Дружківка-Енгельса 120;Дружківка вул. Енгельса, 120;50c596ae-749b-11df-b112-00215aee3ebe
eead8a27-89fb-11ee-80c1-000c2961d091;Дружківка-Енгельса 84А;Дружківка вул. Енгельса, 84А;50c596ae-749b-11df-b112-00215aee3ebe
f6aa7f9b-95d5-11ee-80c1-000c2961d091;Дубляни-Коцюбинського 2;Дубляни вул. Коцюбинського, 2;50c596fa-749b-11df-b112-00215aee3ebe
456834af-9b62-11ee-80c1-000c2961d091;Дубно-Галицького 4;Дубно вул. Галицького, 4;50c59701-749b-11df-b112-00215aee3ebe
ca58d11b-26af-11ec-80e3-000c29800ae7;Дубно-Грушевського Михайла 117;Дубно вул. Грушевського Михайла, 117;50c59701-749b-11df-b112-00215aee3ebe
52d45ee2-2f0d-11eb-80ce-000c29800ae7;Дубно-Грушевського Михайла 119;Дубно вул. Грушевського Михайла, 119;50c59701-749b-11df-b112-00215aee3ebe
2ad76dcb-2f0c-11eb-80ce-000c29800ae7;Дубно-Грушевського Михайла 160;Дубно вул. Грушевського Михайла, 160;50c59701-749b-11df-b112-00215aee3ebe
73ed9187-5944-11ee-80c0-000c2961d091;Дубно-Грушевського Михайла 174;Дубно вул. Грушевського Михайла, 174;50c59701-749b-11df-b112-00215aee3ebe
9763361f-0c39-11ee-80bd-000c2961d091;Дубно-Грушевського Михайла 1А;Дубно вул. Грушевського Михайла, 1А;50c59701-749b-11df-b112-00215aee3ebe
14c420db-2f09-11eb-80ce-000c29800ae7;Дубно-Грушевського Михайла 2;Дубно вул. Грушевського Михайла, 2;50c59701-749b-11df-b112-00215aee3ebe
f9ca08d5-0f2a-11ed-80e7-000c29800ae7;Дубно-Грушевського Михайла 2 (lifecell);Дубно вул. Грушевського Михайла, 2;50c59701-749b-11df-b112-00215aee3ebe
b4d0889a-b363-11e9-80e4-1c98ec135261;Дубно-Грушевського Михайла 88;Дубно вул. Грушевського Михайла, 88;50c59701-749b-11df-b112-00215aee3ebe
2917d657-c268-11ed-80ed-000c29800ae7;Дубно-Забрама 32;Дубно вул. Забрама, 32;50c59701-749b-11df-b112-00215aee3ebe
3ab0224b-2f10-11eb-80ce-000c29800ae7;Дубно-Заводська 8;Дубно вул. Заводська, 8;50c59701-749b-11df-b112-00215aee3ebe
e95a08c1-5944-11ee-80c0-000c2961d091;Дубно-Замкова 12А;Дубно вул. Замкова, 12А;50c59701-749b-11df-b112-00215aee3ebe
6742797b-b2a5-11ed-80ec-000c29800ae7;Дубно-Кирила і Мефодія 22;Дубно вул. Кирила і Мефодія, 22;50c59701-749b-11df-b112-00215aee3ebe
ec57d736-5943-11ee-80c0-000c2961d091;Дубно-Мирогощанська 4;Дубно вул. Мирогощанська, 4;50c59701-749b-11df-b112-00215aee3ebe
47aa986a-2f14-11eb-80ce-000c29800ae7;Дубно-Мирогощанська 59;Дубно вул. Мирогощанська, 59;50c59701-749b-11df-b112-00215aee3ebe
a859ed96-e848-11ea-80cc-000c29800ae7;Дубно-Незалежності 3;Дубно Майдан Незалежності, 3;50c59701-749b-11df-b112-00215aee3ebe
91abdc17-2f11-11eb-80ce-000c29800ae7;Дубно-Свободи 2;Дубно вул. Свободи, 2;50c59701-749b-11df-b112-00215aee3ebe
e9d28f83-3c11-11ee-80c0-000c2961d091;Дубно-Сурмичі 3;Дубно вул. Сурмичі, 35600;50c59701-749b-11df-b112-00215aee3ebe
f1ba7a6d-2f14-11eb-80ce-000c29800ae7;Дубно-Сурмичі 99 ;Дубно вул. Сурмичі, 99;50c59701-749b-11df-b112-00215aee3ebe
2ac29d11-0c45-11ee-80bd-000c2961d091;Дубно-Шептицького 7;Дубно вул. Шептицького М, 7;50c59701-749b-11df-b112-00215aee3ebe
ade95ee2-c98b-11ee-80c4-000c2961d091;Дубровиця-Артеменка 26А;Дубровиця вул. Артеменка, 26А;50c59733-749b-11df-b112-00215aee3ebe
fce5301d-643b-11ee-80c0-000c2961d091;Дубровиця-Шевченка 32;Дубровиця вул. Шевченка, 32;50c59733-749b-11df-b112-00215aee3ebe
c379d96e-1706-11ed-80e7-000c29800ae7;Дунаївці-Шевченка 59;Дунаївці вул. Шевченка, 59;50c59761-749b-11df-b112-00215aee3ebe
485f5622-f5f5-11eb-80d5-000c29800ae7;Дунаївці-Шевченка 73;Дунаївці вул. Шевченка, 73;50c59761-749b-11df-b112-00215aee3ebe
5ffee06e-100f-11ee-80bd-000c2961d091;Єланець Просп. Незалежності, 8/122;Єланець Просп. Незалежності, 8/122;56bdd145-749b-11df-b112-00215aee3ebe
2bef5378-9a22-11eb-80d3-000c29800ae7;Єланець-50-річчя Жовтня 8/8;Єланець вул. 50-річчя Жовтня, 8/8;56bdd145-749b-11df-b112-00215aee3ebe
12d4dcf1-8ae3-11ee-80c1-000c2961d091;Ємільчине-Соборна 29;Ємільчине вул. Соборна, 29;56bdd16e-749b-11df-b112-00215aee3ebe
181d0878-a5f6-11ed-80ec-000c29800ae7;Ємільчине-Шевченка 6;Ємільчине вул. Шевченка, 6;56bdd16e-749b-11df-b112-00215aee3ebe
3632ad4b-b154-11ee-80c4-000c2961d091;Жашків-Шевченка 1А;Жашків вул. Шевченка, 1А;56bdd1af-749b-11df-b112-00215aee3ebe
67c1c4a0-4e0e-11ee-80c0-000c2961d091;Жашків-Шевченка 5;Жашків вул. Шевченка, 5;56bdd1af-749b-11df-b112-00215aee3ebe
c226da17-1708-11ed-80e7-000c29800ae7;Жидачів-Грушевського 37;Жидачів вул. Грушевського, 37;56bdd1e7-749b-11df-b112-00215aee3ebe
75ec2989-a8dd-11de-bac3-0030485903e8;Житомир;Житомир Просп. Незалежності (Ватутіна), 91/1;56bdd203-749b-11df-b112-00215aee3ebe
b4478342-f1de-11ec-80e7-000c29800ae7;Житомир-1-й Винокурний 63 Rozetka;Житомир пров. 1-й Винокурний, 63;56bdd203-749b-11df-b112-00215aee3ebe
083331d8-279a-11ee-80bd-000c2961d091;Житомир-Бердичівська 95 RemontER;Житомир вул. Бердичівська, 95;56bdd203-749b-11df-b112-00215aee3ebe
7a252116-95da-11ee-80c1-000c2961d091;Житомир-Велика Бердичівська 51А Чистенько;Житомир вул. Велика Бердичівська, 51А;56bdd203-749b-11df-b112-00215aee3ebe
79ff1b9c-893f-11ee-80c1-000c2961d091;Житомир-Велика Бердичівська 56А Чистенько;Житомир вул. Велика Бердичівська, 56А;56bdd203-749b-11df-b112-00215aee3ebe
596d6bf3-d780-11ed-80ed-000c29800ae7;Житомир-Велика Бердичівська 63 Rozetka;Житомир вул. Велика Бердичівська, 63;56bdd203-749b-11df-b112-00215aee3ebe
50dee311-fc0a-11eb-80d6-000c29800ae7;Житомир-Визволення 8А AIR ПРОДУКТ;Житомир Майдан Визволення, 8А;56bdd203-749b-11df-b112-00215aee3ebe
19f6d47a-9363-11ee-80c1-000c2961d091;Житомир-Вільський Шлях 14 Чистенько;Житомир вул. Вільський Шлях, 14;56bdd203-749b-11df-b112-00215aee3ebe
5fb96eeb-a0f4-11ee-80c4-000c2961d091;Житомир-Вільський Шлях 193 Одяг;Житомир вул. Вільський Шлях, 193;56bdd203-749b-11df-b112-00215aee3ebe
ba84382a-a408-11ee-80c4-000c2961d091;Житомир-Вільський Шлях 229 Продукти;Житомир вул. Вільський Шлях, 229;56bdd203-749b-11df-b112-00215aee3ebe
03a2f010-eee5-11eb-80d5-000c29800ae7;Житомир-Вітрука 17Б Центр екопереробки;Житомир вул. Вітрука, 17Б;56bdd203-749b-11df-b112-00215aee3ebe
a337f28d-22ba-11ed-80e7-000c29800ae7;Житомир-Вітрука 26 Магазин;Житомир вул. Вітрука, 26;56bdd203-749b-11df-b112-00215aee3ebe
430b6c81-1c70-11ec-80df-000c29800ae7;Житомир-Вітрука 42/7 Продукти;Житомир вул. Вітрука, 42/7;56bdd203-749b-11df-b112-00215aee3ebe
99e22493-f79d-11e7-80cc-1c98ec135261;Житомир-Вітрука 8А АВТОШКА;Житомир вул. Вітрука, 8А;56bdd203-749b-11df-b112-00215aee3ebe
3c75b686-8ad1-11ee-80c1-000c2961d091;Житомир-Вокзальна 14А Чистенько;Житомир вул. Вокзальна, 14А;56bdd203-749b-11df-b112-00215aee3ebe
d010413e-a4b6-11ee-80c4-000c2961d091;Житомир-Вокзальна 6А Копійка;Житомир вул. Вокзальна, 6А;56bdd203-749b-11df-b112-00215aee3ebe
0aee66df-e84f-11ee-80c5-000c2961d091;Житомир-Героїв Пожежних 124 Продукти;Житомир вул. Героїв Пожежних, 124;56bdd203-749b-11df-b112-00215aee3ebe
3a4d61b0-4a09-11ed-80eb-000c29800ae7;Житомир-Героїв Пожежних 125 Кавярня;Житомир вул. Героїв Пожежних, 125;56bdd203-749b-11df-b112-00215aee3ebe
d0b89329-77ec-11ee-80c1-000c2961d091;Житомир-Гоголівська 5 Ломбард;Житомир вул. Гоголівська, 5;56bdd203-749b-11df-b112-00215aee3ebe
b8954f27-c5d7-11ee-80c4-000c2961d091;Житомир-Грушевського 5 lifecell;Житомир вул. Грушевського, 5;56bdd203-749b-11df-b112-00215aee3ebe
3249b00b-30b9-11eb-80ce-000c29800ae7;Житомир-Грушевського 5 Vodafone;Житомир вул. Грушевського, 5;56bdd203-749b-11df-b112-00215aee3ebe
16e31bf1-578c-11ee-80c0-000c2961d091;Житомир-Грушевського 5 Сільпо;Житомир вул. Грушевського, 5;56bdd203-749b-11df-b112-00215aee3ebe
3d4ebe1b-e60a-11ee-80c5-000c2961d091;Житомир-Грушевського 98А LEMUR;Житомир вул. Грушевського, 98А;56bdd203-749b-11df-b112-00215aee3ebe
357ad28b-398a-11ed-80e9-000c29800ae7;Житомир-Домбровського 3 Emoji;Житомир вул. Домбровського, 3;56bdd203-749b-11df-b112-00215aee3ebe
02fc757e-578b-11ee-80c0-000c2961d091;Житомир-Житній Ринок 1 Сільпо;Житомир пл. Житній Ринок, 1;56bdd203-749b-11df-b112-00215aee3ebe
df92b2c3-aa5a-11ee-80c4-000c2961d091;Житомир-Зарембського 1/5 Дари моря;Житомир Майдан Зарембського, 1/5;56bdd203-749b-11df-b112-00215aee3ebe
b32e2067-8a8d-11e8-80d4-1c98ec135261;Житомир-Івана Мазепи 98 Квіти;Житомир вул. Івана Мазепи (Мануїльського), 98;56bdd203-749b-11df-b112-00215aee3ebe
03ef4c23-f1de-11ec-80e7-000c29800ae7;Житомир-Київська 106 Rozetka;Житомир вул. Київська, 106;56bdd203-749b-11df-b112-00215aee3ebe
8891618b-9365-11ee-80c1-000c2961d091;Житомир-Київська 120 Чистенько;Житомир вул. Київська, 120;56bdd203-749b-11df-b112-00215aee3ebe
0b4929e1-9902-11ee-80c1-000c2961d091;Житомир-Київська 17 Чистенько;Житомир вул. Київська, 17;56bdd203-749b-11df-b112-00215aee3ebe
43d6289c-ca91-11ea-80ca-000c29800ae7;Житомир-Київська 36 Lifecell;Житомир вул. Київська, 36;56bdd203-749b-11df-b112-00215aee3ebe
48f68f2b-cc13-11ee-80c4-000c2961d091;Житомир-Київська 66 Lifecell;Житомир вул. Київська, 66;56bdd203-749b-11df-b112-00215aee3ebe
f306c83c-191d-11eb-80cd-000c29800ae7;Житомир-Київська 67 Автозапчастини;Житомир вул. Київська, 67;56bdd203-749b-11df-b112-00215aee3ebe
355db00f-9b56-11ee-80c1-000c2961d091;Житомир-Київська 77 Lifecell;Житомир вул. Київська, 77;56bdd203-749b-11df-b112-00215aee3ebe
69653f18-cf07-11ee-80c4-000c2961d091;Житомир-Київська 84 VSETUT;Житомир вул. Київська, 84;56bdd203-749b-11df-b112-00215aee3ebe
48698ab6-8add-11ee-80c1-000c2961d091;Житомир-Київська 96 Чистенько;Житомир вул. Київська, 96;56bdd203-749b-11df-b112-00215aee3ebe
272ec251-dd92-11eb-80d5-000c29800ae7;Житомир-Київське 43/1 Продукти ;Житомир шосе Київське, 43/1;56bdd203-749b-11df-b112-00215aee3ebe
9c13f926-915c-11eb-80d2-000c29800ae7;Житомир-Князів Острозьких 102 Продукти;Житомир вул. Князів Острозьких, 102;56bdd203-749b-11df-b112-00215aee3ebe
e1b61c32-fc0e-11eb-80d6-000c29800ae7;Житомир-Князів Острозьких 64 AIR ПРОДУКТ;Житомир вул. Князів Острозьких, 64;56bdd203-749b-11df-b112-00215aee3ebe
ae4f7d30-95d1-11ee-80c1-000c2961d091;Житомир-Князів Острозьких 73 Чистенько;Житомир вул. Князів Острозьких, 73;56bdd203-749b-11df-b112-00215aee3ebe
c82e2936-bc8d-11eb-80d4-000c29800ae7;Житомир-Князів Острозьких 95 Блиск;Житомир вул. Князів Острозьких, 95;56bdd203-749b-11df-b112-00215aee3ebe
6d446676-95d9-11ee-80c1-000c2961d091;Житомир-Космонавтів 40 Чистенько;Житомир вул. Космонавтів, 40;56bdd203-749b-11df-b112-00215aee3ebe
ae3dc11f-fc0a-11eb-80d6-000c29800ae7;Житомир-Лермонтовська 7 Продукти;Житомир вул. Шляхетна  ( Лермонтовська ), 7;56bdd203-749b-11df-b112-00215aee3ebe
f1ee5a4b-daeb-11ee-80c4-000c2961d091;Житомир-Лятошинського 2 Vodafone;Житомир вул. Лятошинського, 2;56bdd203-749b-11df-b112-00215aee3ebe
f9b0c622-9167-11ee-80c1-000c2961d091;Житомир-Мала Бердичівська 2/7 NOVA BEER;Житомир вул. Мала Бердичівська, 2/7;56bdd203-749b-11df-b112-00215aee3ebe
f8257d9b-a497-11ee-80c4-000c2961d091;Житомир-Малікова 20 Гулівер;Житомир вул. Малікова, 20;56bdd203-749b-11df-b112-00215aee3ebe
bc30c309-9b4a-11ee-80c1-000c2961d091;Житомир-Миру 15 Lifecell;Житомир Просп. Миру, 15;56bdd203-749b-11df-b112-00215aee3ebe
e0680af8-9842-11ee-80c1-000c2961d091;Житомир-Миру 21 FENIX PUB;Житомир Просп. Миру, 21;56bdd203-749b-11df-b112-00215aee3ebe
942b45e8-8ad0-11ee-80c1-000c2961d091;Житомир-Миру 22 Чистенько;Житомир Просп. Миру, 22;56bdd203-749b-11df-b112-00215aee3ebe
4edb85d6-a4b6-11ee-80c4-000c2961d091;Житомир-Миру 37 Копійка;Житомир Просп. Миру, 37;56bdd203-749b-11df-b112-00215aee3ebe
7563b12d-2085-11ee-80bd-000c2961d091;Житомир-Миру 3В Дари моря;Житомир Просп. Миру, 3В;56bdd203-749b-11df-b112-00215aee3ebe
ebf4a4b5-8adb-11ee-80c1-000c2961d091;Житомир-Миру 47 Чистенько;Житомир Просп. Миру, 47;56bdd203-749b-11df-b112-00215aee3ebe
fed59249-159a-11e9-80dc-1c98ec135261;Житомир-Небесної Сотні 14 Ремонт телефонів;Житомир вул. Небесної Сотні (Московська), 14;56bdd203-749b-11df-b112-00215aee3ebe
a7591224-fc0b-11eb-80d6-000c29800ae7;Житомир-Небесної Сотні 29 AIR ПРОДУКТ;Житомир вул. Небесної Сотні (Московська), 29;56bdd203-749b-11df-b112-00215aee3ebe
a22bcc53-363f-11ec-80e3-000c29800ae7;Житомир-Небесної Сотні 52 VSETUT;Житомир вул. Небесної Сотні (Московська), 52;56bdd203-749b-11df-b112-00215aee3ebe
cf39b474-b366-11e9-80e4-1c98ec135261;Житомир-Незалежності 10В ОККО;Житомир Просп. Незалежності (Ватутіна), 10В;56bdd203-749b-11df-b112-00215aee3ebe
2a8a4628-d419-11eb-80d5-000c29800ae7;Житомир-Отаманів Соколовських 9/11 Аптека;Житомир вул. Отаманів Соколовських (Потапова Генерала, 9/11;56bdd203-749b-11df-b112-00215aee3ebe
2ce35e00-3684-11e9-80dd-1c98ec135261;Житомир-Параджанова Сергія 85Б ОККО;Житомир вул. Параджанова Сергія (Баранова), 85Б;56bdd203-749b-11df-b112-00215aee3ebe
52937fd0-f03b-11eb-80d5-000c29800ae7;Житомир-Перемоги 4 Ломбард;Житомир вул. Перемоги, 4;56bdd203-749b-11df-b112-00215aee3ebe
8f700fc6-b15d-11ee-80c4-000c2961d091;Житомир-Перемоги 43 Anomali Coffee;Житомир Просп. Перемоги, 43;56bdd203-749b-11df-b112-00215aee3ebe
090d131d-f3d9-11ed-80ed-000c29800ae7;Житомир-Перемоги 46 Кавярня;Житомир вул. Перемоги, 46;56bdd203-749b-11df-b112-00215aee3ebe
bed51f0c-dded-11e8-80d7-1c98ec135261;Житомир-Покровська 110 ЄВРОАВТО;Житомир вул. Покровська, 110;56bdd203-749b-11df-b112-00215aee3ebe
2e8a9d28-fc09-11eb-80d6-000c29800ae7;Житомир-Покровська 163 AIR ПРОДУКТ;Житомир вул. Покровська, 163;56bdd203-749b-11df-b112-00215aee3ebe
69a6f55c-b366-11e9-80e4-1c98ec135261;Житомир-Покровська 166 ОККО;Житомир вул. Покровська, 166;56bdd203-749b-11df-b112-00215aee3ebe
87074e5a-dba0-11ee-80c4-000c2961d091;Житомир-Покровська 18 Gigabyte.zt.ua;Житомир вул. Покровська, 18;56bdd203-749b-11df-b112-00215aee3ebe
8b95d1dd-fc09-11eb-80d6-000c29800ae7;Житомир-Покровська 239Б AIR ПРОДУКТ;Житомир вул. Покровська, 239Б;56bdd203-749b-11df-b112-00215aee3ebe
5114745e-53c6-11ee-80c0-000c2961d091;Житомир-Покровська 63 Rozetka;Житомир вул. Покровська, 63;56bdd203-749b-11df-b112-00215aee3ebe
d378376d-4305-11ed-80e9-000c29800ae7;Житомир-Покровська 99Д BierBauch;Житомир вул. Покровська, 99Д;56bdd203-749b-11df-b112-00215aee3ebe
8e8b6a84-7259-11ee-80c1-000c2961d091;Житомир-Рихліка Євгена  15 Розливне та пінне;Житомир вул. Рихліка Євгена  ( 5-й Щорса ), 15;56bdd203-749b-11df-b112-00215aee3ebe
3d8bca5d-e5c2-11ed-80ed-000c29800ae7;Житомир-Ріхтера 33 Поліграфія;Житомир вул. Ріхтера, 33;56bdd203-749b-11df-b112-00215aee3ebe
cf3aaee7-abdd-11ee-80c4-000c2961d091;Житомир-Саєнка 21 Офіс Сертифікат;Житомир вул. Саєнка, 21;56bdd203-749b-11df-b112-00215aee3ebe
38d99072-f1df-11ec-80e7-000c29800ae7;Житомир-Соборний 7/1 Rozetka;Житомир Майдан Соборний, 7/1;56bdd203-749b-11df-b112-00215aee3ebe
1c29c896-ea7e-11ee-80c5-000c2961d091;Житомир-Соснова 34Б ПИВО;Житомир вул. Соснова, 34Б;56bdd203-749b-11df-b112-00215aee3ebe
dc31db24-af34-11eb-80d4-000c29800ae7;Житомир-Тена Бориса 82 Мелочи Жизни;Житомир вул. Тена Бориса, 82;56bdd203-749b-11df-b112-00215aee3ebe
d38e8817-95da-11ee-80c1-000c2961d091;Житомир-Хлібна 23 Чистенько;Житомир вул. Хлібна, 23;56bdd203-749b-11df-b112-00215aee3ebe
f0122f33-8adc-11ee-80c1-000c2961d091;Житомир-Ціолковського 3 Чистенько;Житомир вул. Ціолковського, 3;56bdd203-749b-11df-b112-00215aee3ebe
e005ae4a-8936-11ee-80c1-000c2961d091;Житомир-Чуднівська 104 Чистенько;Житомир вул. Чуднівська (Черняховського), 104;56bdd203-749b-11df-b112-00215aee3ebe
8e26726b-f613-11ed-80ed-000c29800ae7;Житомир-Чуднівська 110 Дим та М'ясо;Житомир вул. Чуднівська (Черняховського), 110;56bdd203-749b-11df-b112-00215aee3ebe
e2db7e0a-8ada-11ee-80c1-000c2961d091;Житомир-Чуднівська 96 Чистенько;Житомир вул. Чуднівська (Черняховського), 96;56bdd203-749b-11df-b112-00215aee3ebe
d786729c-d4b2-11ee-80c4-000c2961d091;Жмеринка-Київська 57;Жмеринка вул. Київська, 57;56bdd207-749b-11df-b112-00215aee3ebe
caddd2e0-9098-11e2-ac3d-003048d2b473;Жовква;Жовква вул. Равська, 1Б;56bdd20d-749b-11df-b112-00215aee3ebe
004f567b-ee75-11ed-80ed-000c29800ae7;Жовква-Коновальця 8 Lifecell;Жовква пл. Коновальця, 8;56bdd20d-749b-11df-b112-00215aee3ebe
25f17988-9131-11ee-80c1-000c2961d091;Жовква-Львівська 47 Росана килими;Жовква вул. Львівська, 47;56bdd20d-749b-11df-b112-00215aee3ebe
5db5f4ce-beee-11eb-80d5-000c29800ae7;Жовква-Львівська 5 Жжук;Жовква вул. Львівська, 5;56bdd20d-749b-11df-b112-00215aee3ebe
269d6aa6-3c24-11e8-80d0-1c98ec135261;Жовква-Львівська 8-10 Vodafone;Жовква вул. Львівська, 8-10;56bdd20d-749b-11df-b112-00215aee3ebe
e35f6142-20a5-11e9-80dc-1c98ec135261;Жовква-Сагайдачного 1А ОККО;Жовква вул. Сагайдачного, 1А;56bdd20d-749b-11df-b112-00215aee3ebe
4e8606b0-a81d-11eb-80d3-000c29800ae7;Жовтанці-Львівська 8А;Жовтанці вул. Львівська, 8А;56bdd212-749b-11df-b112-00215aee3ebe
4ee58f8b-8cb0-11eb-80d2-000c29800ae7;Жовті Води-Гагаріна 33;Жовті Води вул. Гагаріна, 33;56bdd221-749b-11df-b112-00215aee3ebe
cee788dd-d97d-11eb-80d5-000c29800ae7;Жовті Води-Козацької Слави 17/1;Жовті Води вул. Козацької Слави, 17/1;56bdd221-749b-11df-b112-00215aee3ebe
933aadfc-3ce7-11ee-80c0-000c2961d091;Жовті Води-Козацької Слави 48;Жовті Води вул. Козацької Слави, 48;56bdd221-749b-11df-b112-00215aee3ebe
e0974ad3-207c-11ee-80bd-000c2961d091;Жовті Води-Свободи 64;Жовті Води бул. Свободи, 64;56bdd221-749b-11df-b112-00215aee3ebe
863e1bc6-cd50-11ed-80ed-000c29800ae7;Заболотів вул. Грушевського, 18 ;Заболотів вул. Грушевського, 18;56bdd301-749b-11df-b112-00215aee3ebe
0148be6a-5168-11ee-80c0-000c2961d091;Заводське-Європейська 31;Заводське вул. Європейська (Матросова), 31;8716270c-749b-11df-b112-00215aee3ebe
b0fbd8ae-da10-11ee-80c4-000c2961d091;Зазим'я-Лісова 31В;Зазим'я вул. Лісова, 31В;56bdd3d4-749b-11df-b112-00215aee3ebe
0e5098b2-b2e7-11e9-80e4-1c98ec135261;Залужжя-Пушкіна 1 А;Залужжя вул. Пушкіна, 1А;56bdd46d-749b-11df-b112-00215aee3ebe
d0a207eb-0115-11df-b41f-00215aee3ebe;Запоріжжя;Запоріжжя вул. Карпенка-Карого, 47;56bdd4c6-749b-11df-b112-00215aee3ebe
d1c6bcce-d357-11eb-80d5-000c29800ae7;Запоріжжя-Авраменка 4Б Економ Плюс;Запоріжжя вул. Авраменка, 4Б;56bdd4c6-749b-11df-b112-00215aee3ebe
248c77b9-9b58-11ee-80c1-000c2961d091;Запоріжжя-Бородинська 20Б Lifecell;Запоріжжя вул. Петра Сагайдачного (Бородинська), 20Б;56bdd4c6-749b-11df-b112-00215aee3ebe
833afc96-5791-11ee-80c0-000c2961d091;Запоріжжя-Бородинська 20Б Сільпо;Запоріжжя вул. Петра Сагайдачного (Бородинська), 20Б;56bdd4c6-749b-11df-b112-00215aee3ebe
0a3983a6-f1e2-11ec-80e7-000c29800ae7;Запоріжжя-Бородинська 9 Rozetka;Запоріжжя вул. Петра Сагайдачного (Бородинська), 9;56bdd4c6-749b-11df-b112-00215aee3ebe
784945df-d356-11eb-80d5-000c29800ae7;Запоріжжя-Бочарова 14Д Економ Плюс;Запоріжжя вул. Бочарова, 14Д;56bdd4c6-749b-11df-b112-00215aee3ebe
c81d6fdf-6676-11ee-80c0-000c2961d091;Запоріжжя-Бочарова 3 Rozetka;Запоріжжя вул. Бочарова, 3;56bdd4c6-749b-11df-b112-00215aee3ebe
cd2fecbe-1b9f-11ec-80df-000c29800ae7;Запоріжжя-Брюлова 7 Делві;Запоріжжя вул. Брюлова, 7;56bdd4c6-749b-11df-b112-00215aee3ebe
c78d983e-a651-11ee-80c4-000c2961d091;Запоріжжя-Будівельників 23 Делві;Запоріжжя бул. Будівельників, 23;56bdd4c6-749b-11df-b112-00215aee3ebe
5f064678-64ef-11ed-80ec-000c29800ae7;Запоріжжя-В'ячеслава Зайцева 26 Neon.ua;Запоріжжя вул. В'ячеслава Зайцева ( кол. Лермонтова), 26;56bdd4c6-749b-11df-b112-00215aee3ebe
671dc003-7583-11ee-80c1-000c2961d091;Запоріжжя-Василя Сергієнка 9 Сільпо;Запоріжжя вул. Василя Сергієнка (Задніпровська), 9;56bdd4c6-749b-11df-b112-00215aee3ebe
9229623a-7584-11ee-80c1-000c2961d091;Запоріжжя-Вінтера 30 Сільпо;Запоріжжя бул. Вінтера, 30;56bdd4c6-749b-11df-b112-00215aee3ebe
cc57aca0-00f5-11ec-80d6-000c29800ae7;Запоріжжя-Водограйна 16 Економ;Запоріжжя вул. Водограйна ( Гаврилова ), 16;56bdd4c6-749b-11df-b112-00215aee3ebe
86d076d2-d0af-11ee-80c4-000c2961d091;Запоріжжя-Володимира Українця 27А Rozetka;Запоріжжя вул. Володимира Українця (кол. Новокузнецька), 27А;56bdd4c6-749b-11df-b112-00215aee3ebe
7f525f25-e6b1-11ee-80c5-000c2961d091;Запоріжжя-Володимира Українця 6 Nails prof Beauty;Запоріжжя вул. Володимира Українця (кол. Новокузнецька), 6;56bdd4c6-749b-11df-b112-00215aee3ebe
61adaa4f-d359-11eb-80d5-000c29800ae7;Запоріжжя-Вороніна 31А Економ Плюс;Запоріжжя вул. Вороніна, 31А;56bdd4c6-749b-11df-b112-00215aee3ebe
b6b9058a-513d-11e9-80dd-1c98ec135261;Запоріжжя-Героїв 93-ї бригади 10 СЦ РемонтНик;Запоріжжя вул. Героїв 93-ї бригади, 10;56bdd4c6-749b-11df-b112-00215aee3ebe
bcbf0927-a51e-11e7-80cb-1c98ec135261;Запоріжжя-Демократична 63А комплекс Молочний;Запоріжжя вул. Демократична, 63А;56bdd4c6-749b-11df-b112-00215aee3ebe
815321fe-b502-11e8-80d4-1c98ec135261;Запоріжжя-Дніпровське 30 Сделай Сам;Запоріжжя шосе Дніпровське, 30;56bdd4c6-749b-11df-b112-00215aee3ebe
924347e3-1ba2-11ec-80df-000c29800ae7;Запоріжжя-Дорошенка П. 4 Делві;Запоріжжя вул. Дорошенка П., 4;56bdd4c6-749b-11df-b112-00215aee3ebe
83673261-d35c-11eb-80d5-000c29800ae7;Запоріжжя-Європейська 11 Економ Плюс;Запоріжжя вул. Європейська, 11;56bdd4c6-749b-11df-b112-00215aee3ebe
3fa681eb-3bbe-11eb-80cf-000c29800ae7;Запоріжжя-Європейська 7 МастерОк;Запоріжжя вул. Європейська, 7;56bdd4c6-749b-11df-b112-00215aee3ebe
3265bc71-a652-11ee-80c4-000c2961d091;Запоріжжя-Залізнична 5 Делві;Запоріжжя вул. Залізнична, 5;56bdd4c6-749b-11df-b112-00215aee3ebe
83277802-d8bd-11ea-80ca-000c29800ae7;Запоріжжя-Зачиняєва 15 ОККО;Запоріжжя вул. Зачиняєва, 15;56bdd4c6-749b-11df-b112-00215aee3ebe
3e67f763-3cd8-11ee-80c0-000c2961d091;Запоріжжя-Зестафонська 4 Делві;Запоріжжя вул. Зестафонська, 4;56bdd4c6-749b-11df-b112-00215aee3ebe
bdbe3c80-2a73-11ec-80e3-000c29800ae7;Запоріжжя-Зестафонська 6 Економ Плюс;Запоріжжя вул. Зестафонська, 6;56bdd4c6-749b-11df-b112-00215aee3ebe
867afdbd-7586-11ee-80c1-000c2961d091;Запоріжжя-Іванова 1А Сільпо;Запоріжжя вул. Іванова, 1А;56bdd4c6-749b-11df-b112-00215aee3ebe
5d8ae41c-89d2-11e8-80d4-1c98ec135261;Запоріжжя-Іванова 20 Сюрприз;Запоріжжя вул. Іванова, 20;56bdd4c6-749b-11df-b112-00215aee3ebe
9ee30536-7588-11ee-80c1-000c2961d091;Запоріжжя-Інженера Преображенського 13 Сільпо;Запоріжжя Просп. Інженера Преображенського, 13;56bdd4c6-749b-11df-b112-00215aee3ebe
d08164f4-3cd7-11ee-80c0-000c2961d091;Запоріжжя-Інженера Преображенського 5 Делві;Запоріжжя Просп. Інженера Преображенського, 5;56bdd4c6-749b-11df-b112-00215aee3ebe
30f72878-5e8d-11ee-80c0-000c2961d091;Запоріжжя-Карпенка-Карого, 47 СПЕЦ;Запоріжжя вул. Карпенка-Карого, 47;56bdd4c6-749b-11df-b112-00215aee3ebe
edc5cfaf-e842-11ee-80c5-000c2961d091;Запоріжжя-Качалова 16 Делві;Запоріжжя вул. Качалова, 16;56bdd4c6-749b-11df-b112-00215aee3ebe
d0af3691-92e7-11eb-80d3-000c29800ae7;Запоріжжя-Комарова 1 Економ Плюс;Запоріжжя вул. Комарова, 1;56bdd4c6-749b-11df-b112-00215aee3ebe
0b5a4b45-d35b-11eb-80d5-000c29800ae7;Запоріжжя-Космічна 122 Економ Плюс;Запоріжжя вул. Космічна, 122;56bdd4c6-749b-11df-b112-00215aee3ebe
d3d22848-636b-11ee-80c0-000c2961d091;Запоріжжя-Курузова 5 DF-STORE;Запоріжжя вул. Курузова, 5;56bdd4c6-749b-11df-b112-00215aee3ebe
a39144d3-71ac-11ee-80c1-000c2961d091;Запоріжжя-Ладозька 17А Євро Маркет;Запоріжжя вул. Ладозька, 17А;56bdd4c6-749b-11df-b112-00215aee3ebe
eb648707-b7ba-11eb-80d4-000c29800ae7;Запоріжжя-Ладозька 20 Буду мамой;Запоріжжя вул. Ладозька, 20;56bdd4c6-749b-11df-b112-00215aee3ebe
980c8e49-d35b-11eb-80d5-000c29800ae7;Запоріжжя-Ладозька 44 Економ Плюс;Запоріжжя вул. Ладозька, 44;56bdd4c6-749b-11df-b112-00215aee3ebe
e8ebf1cd-77cd-11ee-80c1-000c2961d091;Запоріжжя-Ладозька 8 Grivna Centr;Запоріжжя вул. Ладозька, 8;56bdd4c6-749b-11df-b112-00215aee3ebe
b9bb2d6d-f580-11ed-80ed-000c29800ae7;Запоріжжя-Лахтінська 10 Делві;Запоріжжя вул. Лахтінська, 10;56bdd4c6-749b-11df-b112-00215aee3ebe
09cd07ac-ff3e-11eb-80d6-000c29800ae7;Запоріжжя-Металургів 12А Європошта;Запоріжжя Просп. Металургів, 12А;56bdd4c6-749b-11df-b112-00215aee3ebe
9f9ddb5b-5175-11ee-80c0-000c2961d091;Запоріжжя-Металургів 24 СПЕЦ;Запоріжжя Просп. Металургів, 24;56bdd4c6-749b-11df-b112-00215aee3ebe
cf9be16b-7587-11ee-80c1-000c2961d091;Запоріжжя-Металургів 8 Сільпо;Запоріжжя Просп. Металургів, 8;56bdd4c6-749b-11df-b112-00215aee3ebe
52525e50-e843-11ee-80c5-000c2961d091;Запоріжжя-Мечнікова 36 Делві;Запоріжжя вул. Мечнікова, 36;56bdd4c6-749b-11df-b112-00215aee3ebe
6e2b45c5-1ba1-11ec-80df-000c29800ae7;Запоріжжя-Миколи Корищенка 17 Делві;Запоріжжя вул. Миколи Корищенка, 17;56bdd4c6-749b-11df-b112-00215aee3ebe
1d771892-4e3a-11ee-80c0-000c2961d091;Запоріжжя-Михайла Гончаренка 10 Ящик;Запоріжжя вул. Михайла Гончаренка (Панфіловців), 10;56bdd4c6-749b-11df-b112-00215aee3ebe
e340cef5-0694-11ee-80bd-000c2961d091;Запоріжжя-Михайла Гончаренка 5 СПЕЦ;Запоріжжя вул. Михайла Гончаренка, 5;56bdd4c6-749b-11df-b112-00215aee3ebe
6a3b2c53-48db-11ea-80c6-000c29800ae7;Запоріжжя-Морфлотська 52Априм.3 ОККО;Запоріжжя вул. Морфлотська, 52Априм.3;56bdd4c6-749b-11df-b112-00215aee3ebe
b7bb0164-d35d-11eb-80d5-000c29800ae7;Запоріжжя-Моторобудівників 3 Економ Плюс;Запоріжжя вул. Моторобудівників, 3;56bdd4c6-749b-11df-b112-00215aee3ebe
8aac7dff-8d6e-11ee-80c1-000c2961d091;Запоріжжя-Незалежної України 15 Чайна Лавка;Запоріжжя вул. Незалежної України, 15;56bdd4c6-749b-11df-b112-00215aee3ebe
8c321250-26cc-11ee-80bd-000c2961d091;Запоріжжя-Новокузнецька 36-Б СПЕЦ;Запоріжжя вул. Володимира Українця (кол. Новокузнецька), 36-Б;56bdd4c6-749b-11df-b112-00215aee3ebe
996bb452-9b5d-11ee-80c1-000c2961d091;Запоріжжя-Новокузнецька 41 Lifecel;Запоріжжя вул. Володимира Українця (кол. Новокузнецька), 41;56bdd4c6-749b-11df-b112-00215aee3ebe
796b51a0-7587-11ee-80c1-000c2961d091;Запоріжжя-Новокузнецька 41 Сільпо;Запоріжжя вул. Володимира Українця (кол. Новокузнецька), 41;56bdd4c6-749b-11df-b112-00215aee3ebe
973ab97c-89b5-11eb-80d2-000c29800ae7;Запоріжжя-Новокузнецька 5 Буду Мамой;Запоріжжя вул. Володимира Українця (кол. Новокузнецька), 5;56bdd4c6-749b-11df-b112-00215aee3ebe
98a7b737-00a3-11ea-80e7-1c98ec135261;Запоріжжя-Олександрівська 3 ASPOR;Запоріжжя вул. Олександрівська, 3;56bdd4c6-749b-11df-b112-00215aee3ebe
32783d73-d362-11eb-80d5-000c29800ae7;Запоріжжя-Олімпійська 20А Економ Плюс;Запоріжжя вул. Олімпійська, 20А;56bdd4c6-749b-11df-b112-00215aee3ebe
55f2e644-77ce-11ee-80c1-000c2961d091;Запоріжжя-Павлокічкаська 15А Grivna Centr;Запоріжжя вул. Павлокічкаська, 15А;56bdd4c6-749b-11df-b112-00215aee3ebe
0a8b0840-d363-11eb-80d5-000c29800ae7;Запоріжжя-Павлокічкаська 28 Економ Плюс;Запоріжжя вул. Павлокічкаська, 28;56bdd4c6-749b-11df-b112-00215aee3ebe
bca5f43d-3763-11ee-80c0-000c2961d091;Запоріжжя-Перемоги 12 Автозапчастини;Запоріжжя вул. Перемоги, 12;56bdd4c6-749b-11df-b112-00215aee3ebe
b45b4422-1ba1-11ec-80df-000c29800ae7;Запоріжжя-Перемоги 52А Делві;Запоріжжя вул. Перемоги, 52А;56bdd4c6-749b-11df-b112-00215aee3ebe
23b778f8-9b59-11ee-80c1-000c2961d091;Запоріжжя-Перемоги 64 Lifecell;Запоріжжя вул. Перемоги, 64;56bdd4c6-749b-11df-b112-00215aee3ebe
1ca23c56-5791-11ee-80c0-000c2961d091;Запоріжжя-Перемоги 64 Сільпо;Запоріжжя вул. Перемоги, 64;56bdd4c6-749b-11df-b112-00215aee3ebe
ade75ae7-cf17-11ee-80c4-000c2961d091;Запоріжжя-Перемоги 79 Зоомагазин;Запоріжжя вул. Перемоги, 79;56bdd4c6-749b-11df-b112-00215aee3ebe
ef5e872d-d364-11eb-80d5-000c29800ae7;Запоріжжя-Радіаторна 27А Економ Плюс;Запоріжжя вул. Радіаторна, 27А;56bdd4c6-749b-11df-b112-00215aee3ebe
26b89b48-7588-11ee-80c1-000c2961d091;Запоріжжя-Руставі 1Г Сільпо;Запоріжжя вул. Руставі, 1Г;56bdd4c6-749b-11df-b112-00215aee3ebe
ed2ebd22-d365-11eb-80d5-000c29800ae7;Запоріжжя-Сергія Серікова 13 Економ Плюс;Запоріжжя вул. Сергія Серікова, 13;56bdd4c6-749b-11df-b112-00215aee3ebe
f1e83322-7582-11ee-80c1-000c2961d091;Запоріжжя-Ситова 4 Сільпо;Запоріжжя вул. Ситова, 4;56bdd4c6-749b-11df-b112-00215aee3ebe
6e1b4d71-f1e3-11ec-80e7-000c29800ae7;Запоріжжя-Ситова 4А Rozetka;Запоріжжя вул. Ситова, 4А;56bdd4c6-749b-11df-b112-00215aee3ebe
55077680-11a4-11ee-80bd-000c2961d091;Запоріжжя-Соборний 133 Стейкмаркет Чугун;Запоріжжя Просп. Соборний, 133;56bdd4c6-749b-11df-b112-00215aee3ebe
3a6476b0-a823-11eb-80d3-000c29800ae7;Запоріжжя-Соборний 141 Крісла Столи;Запоріжжя Просп. Соборний, 141;56bdd4c6-749b-11df-b112-00215aee3ebe
d04fbb34-f1e3-11ec-80e7-000c29800ae7;Запоріжжя-Соборний 147 Rozetka;Запоріжжя Просп. Соборний, 147;56bdd4c6-749b-11df-b112-00215aee3ebe
2701eadf-7584-11ee-80c1-000c2961d091;Запоріжжя-Соборний 147 Сільпо;Запоріжжя Просп. Соборний, 147;56bdd4c6-749b-11df-b112-00215aee3ebe
71bfa850-9c3d-11eb-80d3-000c29800ae7;Запоріжжя-Соборний 153 Електротовари;Запоріжжя Просп. Соборний, 153;56bdd4c6-749b-11df-b112-00215aee3ebe
053585a8-64f0-11ed-80ec-000c29800ae7;Запоріжжя-Соборний 177 XCOMUA;Запоріжжя Просп. Соборний, 177;56bdd4c6-749b-11df-b112-00215aee3ebe
3f32dd7a-9b5d-11ee-80c1-000c2961d091;Запоріжжя-Соборний 179 Lifecell;Запоріжжя Просп. Соборний, 179;56bdd4c6-749b-11df-b112-00215aee3ebe
f0a2443b-48da-11ea-80c6-000c29800ae7;Запоріжжя-Соборний 17В ОККО;Запоріжжя Просп. Соборний, 17В;56bdd4c6-749b-11df-b112-00215aee3ebe
17c24d45-8d6f-11ee-80c1-000c2961d091;Запоріжжя-Соборний 192 Чайна Лавка;Запоріжжя Просп. Соборний, 192;56bdd4c6-749b-11df-b112-00215aee3ebe
b02aafcb-f3e2-11ed-80ed-000c29800ae7;Запоріжжя-Соборний 218 JoiceMobile;Запоріжжя Просп. Соборний, 218;56bdd4c6-749b-11df-b112-00215aee3ebe
b733fb18-f1e2-11ec-80e7-000c29800ae7;Запоріжжя-Соборний 222 Rozetka;Запоріжжя Просп. Соборний, 222;56bdd4c6-749b-11df-b112-00215aee3ebe
105f165f-f1e3-11ec-80e7-000c29800ae7;Запоріжжя-Соборний 44 Rozetka;Запоріжжя Просп. Соборний, 44;56bdd4c6-749b-11df-b112-00215aee3ebe
787accbd-1b9f-11ec-80df-000c29800ae7;Запоріжжя-Сталеварів 1А Делві;Запоріжжя вул. Сталеварів, 1А;56bdd4c6-749b-11df-b112-00215aee3ebe
d2b304a7-1ba2-11ec-80df-000c29800ae7;Запоріжжя-Стартова 94 Делві;Запоріжжя вул. Стартова, 94;56bdd4c6-749b-11df-b112-00215aee3ebe
8d89628a-5754-11e8-80d4-1c98ec135261;Запоріжжя-Тенісна 13 Интерьер мебель;Запоріжжя вул. Тенісна, 13;56bdd4c6-749b-11df-b112-00215aee3ebe
3ffd1720-2a74-11ec-80e3-000c29800ae7;Запоріжжя-Товариська 70 Економ Плюс;Запоріжжя вул. Товариська, 70;56bdd4c6-749b-11df-b112-00215aee3ebe
2d3dcc8d-e6aa-11ee-80c5-000c2961d091;Запоріжжя-Українська 2Ж прим.7 Black Service;Запоріжжя вул. Українська, 2Ж;56bdd4c6-749b-11df-b112-00215aee3ebe
71e774b5-00f6-11ec-80d6-000c29800ae7;Запоріжжя-Фортечна 10А Економ;Запоріжжя вул. Фортечна, 10А;56bdd4c6-749b-11df-b112-00215aee3ebe
c79fdefa-7583-11ee-80c1-000c2961d091;Запоріжжя-Фортечна 6 Сільпо;Запоріжжя вул. Фортечна, 6;56bdd4c6-749b-11df-b112-00215aee3ebe
18ed6e40-f576-11ed-80ed-000c29800ae7;Запоріжжя-Хортицьке 14 Буду Мамою;Запоріжжя шосе Хортицьке, 14;56bdd4c6-749b-11df-b112-00215aee3ebe
33ae004d-7586-11ee-80c1-000c2961d091;Запоріжжя-Хортицьке 30А Сільпо;Запоріжжя шосе Хортицьке, 30А;56bdd4c6-749b-11df-b112-00215aee3ebe
8928df77-48da-11ea-80c6-000c29800ae7;Запоріжжя-Хортицьке 3А ОККО;Запоріжжя шосе Хортицьке, 3А;56bdd4c6-749b-11df-b112-00215aee3ebe
57d43205-1ba2-11ec-80df-000c29800ae7;Запоріжжя-Хортицьке 44А Делві;Запоріжжя шосе Хортицьке, 44А;56bdd4c6-749b-11df-b112-00215aee3ebe
99254f1a-d366-11eb-80d5-000c29800ae7;Запоріжжя-Цитрусова 4 Економ Плюс;Запоріжжя вул. Цитрусова, 4;56bdd4c6-749b-11df-b112-00215aee3ebe
401b6fc3-d367-11eb-80d5-000c29800ae7;Запоріжжя-Чарівна 107 Економ Плюс;Запоріжжя вул. Чарівна, 107;56bdd4c6-749b-11df-b112-00215aee3ebe
e2138e6a-a485-11e8-80d4-1c98ec135261;Запоріжжя-Чарівна 155А АвтоСлуч;Запоріжжя вул. Чарівна, 155А;56bdd4c6-749b-11df-b112-00215aee3ebe
8a66de4f-7585-11ee-80c1-000c2961d091;Запоріжжя-Чарівна 155Б Сільпо;Запоріжжя вул. Чарівна, 155Б;56bdd4c6-749b-11df-b112-00215aee3ebe
5f61169e-4adc-11ed-80eb-000c29800ae7;Запоріжжя-Чарівна 40Б Rozetka;Запоріжжя вул. Чарівна, 40Б;56bdd4c6-749b-11df-b112-00215aee3ebe
e843fc21-7585-11ee-80c1-000c2961d091;Запоріжжя-Чарівна 74 Сільпо;Запоріжжя вул. Чарівна, 74;56bdd4c6-749b-11df-b112-00215aee3ebe
97d1c6d3-581e-11ec-80e4-000c29800ae7;Запоріжжя-Чарівна 93 Делві;Запоріжжя вул. Чарівна, 93;56bdd4c6-749b-11df-b112-00215aee3ebe
4ffac080-a7ba-11e9-80e4-1c98ec135261;Запоріжжя-Чубанова 13Г ОККО;Запоріжжя вул. Чубанова, 13Г;56bdd4c6-749b-11df-b112-00215aee3ebe
c3081092-e841-11ee-80c5-000c2961d091;Запоріжжя-Чумаченка 23 Делві;Запоріжжя вул. Чумаченка, 23;56bdd4c6-749b-11df-b112-00215aee3ebe
c29633f9-2a74-11ec-80e3-000c29800ae7;Запоріжжя-Шевченка 146 Економ Плюс;Запоріжжя вул. Шевченка, 146;56bdd4c6-749b-11df-b112-00215aee3ebe
ed1b0ac2-d367-11eb-80d5-000c29800ae7;Запоріжжя-Шевченка 243А Економ Плюс;Запоріжжя вул. Шевченка, 243А;56bdd4c6-749b-11df-b112-00215aee3ebe
e99a9d1f-ee04-11eb-80d5-000c29800ae7;Запоріжжя-Шкільна 13 Крепдешин;Запоріжжя вул. Шкільна, 13;56bdd4c6-749b-11df-b112-00215aee3ebe
67c47df0-26dc-11ee-80bd-000c2961d091;Запоріжжя-Шкільна 40 ASIA PARTS;Запоріжжя вул. Шкільна, 40;56bdd4c6-749b-11df-b112-00215aee3ebe
a4167178-34f1-11e9-80dd-1c98ec135261;Запоріжжя-Шкільна 4Г ОККО;Запоріжжя вул. Шкільна, 4Г;56bdd4c6-749b-11df-b112-00215aee3ebe
7b3fac01-2b92-11ee-80bd-000c2961d091;Запоріжжя-Ювілейний 29А Rozetka;Запоріжжя Просп. Ювілейний, 29А;56bdd4c6-749b-11df-b112-00215aee3ebe
7ccedf0e-a7b9-11e9-80e4-1c98ec135261;Запоріжжя-Яценка 18 ОККО;Запоріжжя вул. Яценка, 18;56bdd4c6-749b-11df-b112-00215aee3ebe
15b100f1-8ab8-11ee-80c1-000c2961d091;Зарічани-Бердичівське 4;Зарічани шосе Бердичівське, 4;56bdd503-749b-11df-b112-00215aee3ebe
c6155eee-b367-11e9-80e4-1c98ec135261;Зарічани-Майдан Бердичівський 11;Зарічани вул. Майдан Бердичівський, 11;56bdd503-749b-11df-b112-00215aee3ebe
0c9908bf-910d-11ee-80c1-000c2961d091;Заставна-Незалежності 101;Заставна вул. Незалежності, 101;56bdd564-749b-11df-b112-00215aee3ebe
8409a661-8876-11ee-80c1-000c2961d091;Заставна-Незалежності 99;Заставна вул. Незалежності, 99;56bdd564-749b-11df-b112-00215aee3ebe
7716d3c4-fb00-11ed-80ed-000c29800ae7;Збараж-Грушевського 8;Збараж вул. Грушевського, 8;56bdd5b9-749b-11df-b112-00215aee3ebe
9cef0066-e3b0-11eb-80d5-000c29800ae7;Збараж-Франка 14;Збараж Майдан Франка, 14;56bdd5b9-749b-11df-b112-00215aee3ebe
71a6fbf3-3038-11e9-80dd-1c98ec135261;Зборів-Тернопільська 50;Зборів вул. Тернопільська, 50;56bdd5c2-749b-11df-b112-00215aee3ebe
b7a01ece-0edb-11e9-80db-1c98ec135261;Зборів-Хмельницького Богдана,37;Зборів вул. Хмельницького Богдана, 37;56bdd5c2-749b-11df-b112-00215aee3ebe
2edb7fc7-3285-11e8-80d0-1c98ec135261;Зборів-Хмельницького Богдана,58;Зборів вул. Хмельницького Богдана, 58;56bdd5c2-749b-11df-b112-00215aee3ebe
8162ad24-bda5-11ed-80ed-000c29800ae7;Звенигородка-Грушевського 146;Звенигородка вул. Грушевського, 146;56bdd5d5-749b-11df-b112-00215aee3ebe
a5fcdb57-b154-11ee-80c4-000c2961d091;Звенигородка-Шевченка 48;Звенигородка вул. Шевченка, 48;56bdd5d5-749b-11df-b112-00215aee3ebe
a678efc0-758d-11ee-80c1-000c2961d091;Звенигородка-Шевченка 69;Звенигородка Просп. Шевченка, 69;56bdd5d5-749b-11df-b112-00215aee3ebe
4f2926de-95b8-11ee-80c1-000c2961d091;Звенигородка-Шевченка 81;Звенигородка Просп. Шевченка, 81;56bdd5d5-749b-11df-b112-00215aee3ebe
c38447ae-5db2-11ec-80e4-000c29800ae7;Звягель (Новоград-Волинський)-Шевченка 54;Звягель (Новоград-Волинський) вул. Шевченка, 54;6ed81964-749b-11df-b112-00215aee3ebe
db1341d3-f1df-11ec-80e7-000c29800ae7;Звягель-Київська 4А;Звягель (Новоград-Волинський) вул. Київська ( Пушкіна ), 4А;6ed81964-749b-11df-b112-00215aee3ebe
41084895-dd5a-11ea-80ca-000c29800ae7;Звягель-Шевченка 32 Б;Звягель (Новоград-Волинський) вул. Шевченка, 32Б;6ed81964-749b-11df-b112-00215aee3ebe
2be87bcb-5d0b-11ee-80c0-000c2961d091;Здолбунів-Гетьмана Сагайдачного 7 Пивна Гавань;Здолбунів вул. Гетьмана Сагайдачного (2-го Лютого), 7;56bdd5f6-749b-11df-b112-00215aee3ebe
6d428cd4-2e69-11eb-80ce-000c29800ae7;Здолбунів-Паркова 68 Spirit and food;Здолбунів вул. Паркова, 68;56bdd5f6-749b-11df-b112-00215aee3ebe
0c32059e-b361-11e9-80e4-1c98ec135261;Здолбунів-Пушкіна 1 ОККО;Здолбунів вул. Пушкіна, 1;56bdd5f6-749b-11df-b112-00215aee3ebe
240da9e0-2e6a-11eb-80ce-000c29800ae7;Здолбунів-Шкільна 11 Spirit and food;Здолбунів вул. Шкільна, 11;56bdd5f6-749b-11df-b112-00215aee3ebe
4988419c-5ba4-11ee-80c0-000c2961d091;Здолбунів-Шкільна 30А Леді Vi;Здолбунів вул. Шкільна, 30А;56bdd5f6-749b-11df-b112-00215aee3ebe
bcc2684e-5c53-11ee-80c0-000c2961d091;Зимна Вода-Городоцька 10;Зимна Вода вул. Городоцька, 10;56bdd6dd-749b-11df-b112-00215aee3ebe
688ab78c-95d7-11ee-80c1-000c2961d091;Зимна Вода-Тичини Павла 2;Зимна Вода вул. Тичини Павла, 2;56bdd6dd-749b-11df-b112-00215aee3ebe
610755a2-9fad-11e8-80d4-1c98ec135261;Зимна Вода-Тичини Павла 2/11;Зимна Вода вул. Тичини Павла, 2/11;56bdd6dd-749b-11df-b112-00215aee3ebe
5584606b-0519-11ee-80bd-000c2961d091;Зимна Вода-Яворівська 4;Зимна Вода вул. Яворівська, 4;56bdd6dd-749b-11df-b112-00215aee3ebe
ff11c5e1-abe9-11ea-80c8-000c29800ae7;Зіньків;Зіньків вул. Соборності, 2 Г;56bdd6ed-749b-11df-b112-00215aee3ebe
d3287502-9fef-11ee-80c4-000c2961d091;Зіньків-Воздвиженська 49Е;Зіньків вул. Воздвиженська ( Полтавська ), 49Е;56bdd6ed-749b-11df-b112-00215aee3ebe
b1294ca2-e6ae-11ea-80cb-000c29800ae7;Зміїв-Залізнична 117;Зміїв вул. Залізнична, 117;56bdd705-749b-11df-b112-00215aee3ebe
67a48889-dc61-11ee-80c4-000c2961d091;Зміїнець-Левадна 9;Зміїнець вул. Левадна, 9;56bdd708-749b-11df-b112-00215aee3ebe
55a1c476-0518-11ee-80bd-000c2961d091;Знам'янка-Віктора Голого 104А;Знам'янка вул. Віктора Голого (Фрунзе), 104А;56bdd719-749b-11df-b112-00215aee3ebe
183bdbd7-bf54-11ee-80c4-000c2961d091;Знам'янка-Грушевського 24А;Знам'янка вул. Грушевського, 24А;56bdd719-749b-11df-b112-00215aee3ebe
8d059b4f-d7dd-11ee-80c4-000c2961d091;Знам'янка-Кримська 46;Знам'янка вул. Кримська, 46;56bdd719-749b-11df-b112-00215aee3ebe
aa189c5a-dac4-11ed-80ed-000c29800ae7;Знам'янка-Матросова 45Б;Знам'янка вул. Матросова, 45Б;56bdd719-749b-11df-b112-00215aee3ebe
4bfac8ce-6717-11ea-80c6-000c29800ae7;Золотоноша;Золотоноша вул. Шевченка, 161;56bdd748-749b-11df-b112-00215aee3ebe
9c4cfec4-5c6b-11ee-80c0-000c2961d091;Золотоноша-Новоселівська 1В Київстар;Золотоноша вул. Захисників України (Новоселівська), 1В;56bdd748-749b-11df-b112-00215aee3ebe
b38b9b7f-9b65-11ee-80c1-000c2961d091;Золотоноша-Новоселівська 2 Lifecell;Золотоноша вул. Захисників України (Новоселівська), 2;56bdd748-749b-11df-b112-00215aee3ebe
a0f0c2d8-0696-11ed-80e7-000c29800ae7;Золотоноша-Черкаська 2 Rozetka;Золотоноша вул. Черкаська, 2;56bdd748-749b-11df-b112-00215aee3ebe
9b11b0b6-dac5-11ee-80c4-000c2961d091;Золотоноша-Шевченка 161 lifecell;Золотоноша вул. Шевченка, 161;56bdd748-749b-11df-b112-00215aee3ebe
7f5bd049-da10-11ed-80ed-000c29800ae7;Золочів-Бандери Степана 4;Золочів вул. Бандери Степана, 4;56bdd74e-749b-11df-b112-00215aee3ebe
1e23ebc8-2f66-11e9-80dd-1c98ec135261;Золочів-Об'їздна, 3;Золочів вул. Об'їздна, 3;56bdd74e-749b-11df-b112-00215aee3ebe
359d0420-410c-11ee-80c0-000c2961d091;Золочів-Рєпіна 2;Золочів вул. Рєпіна, 2;56bdd74e-749b-11df-b112-00215aee3ebe
1b58dafd-bb81-11ee-80c4-000c2961d091;Золочів-Січових Стрільців 28Д;Золочів вул. Січових Стрільців, 28Д;56bdd74e-749b-11df-b112-00215aee3ebe
9a9e8f0a-9a1d-11eb-80d3-000c29800ae7;Іваничі-Грушевського 31;Іваничі вул. Грушевського, 31;56bdd7ce-749b-11df-b112-00215aee3ebe
c62926bf-79fe-11ed-80ec-000c29800ae7;Іванківці-Левицького 46/1;Іванківці вул. Левицького, 46/1;56bdd860-749b-11df-b112-00215aee3ebe
75ec298b-a8dd-11de-bac3-0030485903e8;Івано-Франківськ;Івано-Франківськ вул. Левинського, 3-А;56bdd87c-749b-11df-b112-00215aee3ebe
ba40aa41-ea97-11ee-80c5-000c2961d091;Івано-Франківськ-Миколайчука 14А Товари з Європи;Івано-Франківськ вул. Миколайчука, 14А;56bdd87c-749b-11df-b112-00215aee3ebe
dfe999cf-b9cc-11ed-80ed-000c29800ae7;Івано-Франкове-Ринок 3;Івано-Франкове пл. Ринок, 3;56bdd87d-749b-11df-b112-00215aee3ebe
568519c5-92de-11eb-80d3-000c29800ae7;Ізмаїл;Ізмаїл вул. Некрасова, 3 А;56bdd8c5-749b-11df-b112-00215aee3ebe
deda78d5-f04c-11eb-80d5-000c29800ae7;Ізмаїл-Аврамівська 57/1 Ломбард;Ізмаїл вул. Аврамівська (28-го Червня), 57/1;56bdd8c5-749b-11df-b112-00215aee3ebe
7692322f-15c2-11ee-80bd-000c2961d091;Ізмаїл-Героїв Сталінграда 57 Твій час;Ізмаїл вул. Героїв Сталінграда, 57;56bdd8c5-749b-11df-b112-00215aee3ebe
62179a04-9f4e-11ee-80c4-000c2961d091;Ізмаїл-Миру 13 прим. 3 Rozetka;Ізмаїл Просп. Миру, 13;56bdd8c5-749b-11df-b112-00215aee3ebe
b1d9ff12-0ba2-11ea-80e7-1c98ec135261;Ізмаїл-Миру 5 Зооцентр;Ізмаїл Просп. Миру, 5;56bdd8c5-749b-11df-b112-00215aee3ebe
c5d68cd1-f04d-11eb-80d5-000c29800ae7;Ізмаїл-Покровська 42А Ломбард;Ізмаїл вул. Покровська (Комсомольська), 42А;56bdd8c5-749b-11df-b112-00215aee3ebe
d59a2858-3215-11ea-80e7-1c98ec135261;Ізмаїл-Придунайська 401А ZOOлогія;Ізмаїл вул. Придунайська ( Нахімова ), 401А;56bdd8c5-749b-11df-b112-00215aee3ebe
39b87fb0-0b9f-11ea-80e7-1c98ec135261;Ізмаїл-Суворова 181 ZOOлогія;Ізмаїл Просп. Суворова, 181;56bdd8c5-749b-11df-b112-00215aee3ebe
7cbe16e3-f04d-11eb-80d5-000c29800ae7;Ізмаїл-Суворова 58А Ломбард;Ізмаїл вул. Суворова, 58А;56bdd8c5-749b-11df-b112-00215aee3ebe
92ba4580-13f4-11ee-80bd-000c2961d091;Ізмаїл-Суворова 59А Rozetka;Ізмаїл вул. Суворова, 59А;56bdd8c5-749b-11df-b112-00215aee3ebe
8affc30c-77dd-11ee-80c1-000c2961d091;Ізяслав-Грушевського 3;Ізяслав вул. Грушевського, 3;56bdd8d0-749b-11df-b112-00215aee3ebe
4d36c36b-be25-11eb-80d5-000c29800ae7;Ізяслав-Шевченка 16;Ізяслав вул. Шевченка, 16;56bdd8d0-749b-11df-b112-00215aee3ebe
34e74c20-fb43-11eb-80d6-000c29800ae7;Іларіонове-Робоча 14;Іларіонове вул. Робоча, 14;56bdd8d4-749b-11df-b112-00215aee3ebe
305d6860-5ea9-11ee-80c0-000c2961d091;Іларіонове-Центральна 1А;Іларіонове вул. Центральна, 1А;56bdd8d4-749b-11df-b112-00215aee3ebe
e1d1003c-3131-11ee-80bd-000c2961d091;Іллінці-Європейська 15;Іллінці вул. Європейська, 15;56bdd8ea-749b-11df-b112-00215aee3ebe
16743f0e-d4b5-11ee-80c4-000c2961d091;Іллінці-Європейська 15 Мигдаль;Іллінці вул. Європейська, 15;56bdd8ea-749b-11df-b112-00215aee3ebe
6b592e9a-d793-11ee-80c4-000c2961d091;Ірпінь-Авіаконструктора Антонова 8Д;Ірпінь вул. Авіаконструктора Антонова, 8Д;56bdd92e-749b-11df-b112-00215aee3ebe
260bcba4-e111-11ee-80c4-000c2961d091;Ірпінь-Антонова 4А;Ірпінь вул. Антонова, 4А;56bdd92e-749b-11df-b112-00215aee3ebe
94d277b6-c5d5-11ee-80c4-000c2961d091;Ірпінь-Антонова 4Б;Ірпінь вул. Антонова, 4Б;56bdd92e-749b-11df-b112-00215aee3ebe
0a031441-c21f-11eb-80d5-000c29800ae7;Ірпінь-Гнатюка 8;Ірпінь пров. Гнатюка (Гайдара), 8;56bdd92e-749b-11df-b112-00215aee3ebe
bceb948a-ab14-11ee-80c4-000c2961d091;Ірпінь-Гостомельське 9;Ірпінь шосе Гостомельське, 9;56bdd92e-749b-11df-b112-00215aee3ebe
4adbc65b-ea94-11ee-80c5-000c2961d091;Ірпінь-Громадянська 4 BEERVIK;Ірпінь вул. Громадянська, 4;56bdd92e-749b-11df-b112-00215aee3ebe
1ed8a103-0e94-11ee-80bd-000c2961d091;Ірпінь-Західна 8;Ірпінь вул. Західна, 8;56bdd92e-749b-11df-b112-00215aee3ebe
cacaeb6c-5c8a-11ee-80c0-000c2961d091;Ірпінь-Літературна 27;Ірпінь вул. Літературна ( Чехова ), 27;56bdd92e-749b-11df-b112-00215aee3ebe
9de842f6-7bdc-11ed-80ec-000c29800ae7;Ірпінь-Мечнікова 101Б;Ірпінь вул. Мечнікова, 101Б;56bdd92e-749b-11df-b112-00215aee3ebe
d727ddaf-dd47-11ee-80c4-000c2961d091;Ірпінь-Пушкінська 27;Ірпінь вул. Пушкінська, 27;56bdd92e-749b-11df-b112-00215aee3ebe
b14bc5c7-f3c5-11ed-80ed-000c29800ae7;Ірпінь-Пушкінська 62Є;Ірпінь вул. Пушкінська, 62Є;56bdd92e-749b-11df-b112-00215aee3ebe
1de37b89-588a-11ee-80c0-000c2961d091;Ірпінь-Рибака 25В/1;Ірпінь вул. Рибака, 25В/1;56bdd92e-749b-11df-b112-00215aee3ebe
144e472d-92c5-11ee-80c1-000c2961d091;Ірпінь-Сковороди 11/3;Ірпінь вул. Сковороди, 11/3;56bdd92e-749b-11df-b112-00215aee3ebe
a24d71bf-ab13-11ee-80c4-000c2961d091;Ірпінь-Сковороди 11/8;Ірпінь вул. Сковороди, 11/8;56bdd92e-749b-11df-b112-00215aee3ebe
52a53354-bce3-11ed-80ed-000c29800ae7;Ірпінь-Сковороди 13/3;Ірпінь вул. Сковороди, 13/3;56bdd92e-749b-11df-b112-00215aee3ebe
c9edfa67-dc97-11ee-80c4-000c2961d091;Ірпінь-Соборна 122 ЕПІЦЕНТР.ua;Ірпінь вул. Соборна, 122;56bdd92e-749b-11df-b112-00215aee3ebe
73300ffa-e4e5-11ed-80ed-000c29800ae7;Ірпінь-Соборна 1Г;Ірпінь вул. Соборна, 1Г;56bdd92e-749b-11df-b112-00215aee3ebe
ab08cfbc-09ce-11ed-80e7-000c29800ae7;Ірпінь-Тургенівська 50А;Ірпінь вул. Матвія Донцова, 50А;56bdd92e-749b-11df-b112-00215aee3ebe
f234895f-e1e0-11ee-80c5-000c2961d091;Ірпінь-Українська 83Б;Ірпінь вул. Українська, 83Б;56bdd92e-749b-11df-b112-00215aee3ebe
bd9916c7-b547-11ee-80c4-000c2961d091;Ірпінь-Українська 85В SAMODELKIN;Ірпінь вул. Українська, 85В;56bdd92e-749b-11df-b112-00215aee3ebe
7717c574-e50c-11ee-80c5-000c2961d091;Ірпінь-Університетська 1Г прим.227;Ірпінь вул. Університетська, 1Г;56bdd92e-749b-11df-b112-00215aee3ebe
bcb73afd-e9cc-11ed-80ed-000c29800ae7;Ірпінь-Університетська 2/1 В;Ірпінь вул. Університетська, 2/1 В;56bdd92e-749b-11df-b112-00215aee3ebe
4cda5984-6904-11ee-80c1-000c2961d091;Ірпінь-Університетська 2/1 Г;Ірпінь вул. Університетська, 2/1 Г;56bdd92e-749b-11df-b112-00215aee3ebe
7cf9b114-876f-11e9-80e0-1c98ec135261;Ірпінь-Університетська 2/1 К;Ірпінь вул. Університетська, 2/1К;56bdd92e-749b-11df-b112-00215aee3ebe
67d6f865-f1ed-11ec-80e7-000c29800ae7;Ірпінь-Університетська 2Ф/4;Ірпінь вул. Університетська, 2Ф/4;56bdd92e-749b-11df-b112-00215aee3ebe
af91e766-56a8-11ec-80e4-000c29800ae7;Ірпінь-Університетська 3/13;Ірпінь вул. Університетська, 3/13;56bdd92e-749b-11df-b112-00215aee3ebe
3afe5f40-e506-11ee-80c5-000c2961d091;Ірпінь-Центральна 27;Ірпінь вул. Центральна, 27;56bdd92e-749b-11df-b112-00215aee3ebe
621a0b22-7e38-11ee-80c1-000c2961d091;Ірпінь-Центральна 28/1;Ірпінь вул. Центральна, 28/1;56bdd92e-749b-11df-b112-00215aee3ebe
d0a1bd5e-f1ed-11ec-80e7-000c29800ae7;Ірпінь-Центральна 2А;Ірпінь вул. Центральна, 2А;56bdd92e-749b-11df-b112-00215aee3ebe
bb53d70f-595c-11ee-80c0-000c2961d091;Ірпінь-Центральна 4А;Ірпінь вул. Центральна, 4А;56bdd92e-749b-11df-b112-00215aee3ebe
477a376c-ab13-11ee-80c4-000c2961d091;Ірпінь-Центральна 6Г/2;Ірпінь вул. Центральна, 6Г/2;56bdd92e-749b-11df-b112-00215aee3ebe
5f1960c0-2496-11ee-80bd-000c2961d091;Ірпінь-Чехова 27;Ірпінь вул. Літературна ( Чехова ), 27;56bdd92e-749b-11df-b112-00215aee3ebe
c85a3616-f951-11ed-80ed-000c29800ae7;Іршава-Гагаріна Юрія 14/1;Іршава вул. Гагаріна Юрія, 14/1;56bdd930-749b-11df-b112-00215aee3ebe
daafcff3-f571-11e9-80e7-1c98ec135261;Іршава-Гагаріна Юрія 44;Іршава вул. Гагаріна Юрія, 44;56bdd930-749b-11df-b112-00215aee3ebe
88452b28-be06-11eb-80d5-000c29800ae7;Іршанськ-Гулія 8А;Іршанськ вул. Гулія, 8А;56bdd931-749b-11df-b112-00215aee3ebe
b3fc2e95-b78f-11ea-80c8-000c29800ae7;ІФ-Бандери 35/1 СЦ Офіс-Люкс;Івано-Франківськ вул. Бандери, 35/1;56bdd87c-749b-11df-b112-00215aee3ebe
4d00d6c5-472c-11ea-80c5-000c29800ae7;ІФ-Бельведерська 32А Кавярня;Івано-Франківськ вул. Бельведерська, 32А;56bdd87c-749b-11df-b112-00215aee3ebe
e406163f-ec7e-11e7-80cc-1c98ec135261;ІФ-Берегова 22/А БМС-Захід;Івано-Франківськ вул. Берегова, 22/А;56bdd87c-749b-11df-b112-00215aee3ebe
7816c9f7-ae37-11ed-80ec-000c29800ae7;ІФ-Василіянок 68 Сім23;Івано-Франківськ вул. Василіянок, 68;56bdd87c-749b-11df-b112-00215aee3ebe
d8807ebc-d705-11ee-80c4-000c2961d091;ІФ-Височана Семена 18 Зоомагазин Зоопарк;Івано-Франківськ вул. Височана Семена, 18;56bdd87c-749b-11df-b112-00215aee3ebe
e4c6ac49-d44d-11e7-80cc-1c98ec135261;ІФ-Вовчинецька 172А СЦ КасКом;Івано-Франківськ вул. Вовчинецька, 172А;56bdd87c-749b-11df-b112-00215aee3ebe
b151e072-74b9-11ee-80c1-000c2961d091;ІФ-Вовчинецька 225 Сільпо;Вовчинець вул. Вовчинецька (Вовчинець), 225;4aba3da5-749b-11df-b112-00215aee3ebe
12b721d9-7bac-11e9-80e0-1c98ec135261;ІФ-Вовчинецька 225Д ОККО;Вовчинець вул. Вовчинецька (Вовчинець), 225Д;4aba3da5-749b-11df-b112-00215aee3ebe
db9f5205-99a8-11ee-80c1-000c2961d091;ІФ-Володимира Великого 14 Карамболь;Івано-Франківськ вул. Володимира Великого, 14;56bdd87c-749b-11df-b112-00215aee3ebe
42417928-56e1-11ee-80c0-000c2961d091;ІФ-Галицька 120А Маркет СІМІ;Івано-Франківськ вул. Галицька, 120А;56bdd87c-749b-11df-b112-00215aee3ebe
3bc8baa7-34f6-11e9-80dd-1c98ec135261;ІФ-Галицька 201А ОККО;Івано-Франківськ вул. Галицька, 201А;56bdd87c-749b-11df-b112-00215aee3ebe
08b01887-b0a2-11ea-80c8-000c29800ae7;ІФ-Галицька 22 Каскад Імпульс.Компьютери;Івано-Франківськ вул. Галицька, 22;56bdd87c-749b-11df-b112-00215aee3ebe
40fe2cf1-c73d-11ee-80c4-000c2961d091;ІФ-Галицька 32 Rozetka;Івано-Франківськ вул. Галицька, 32;56bdd87c-749b-11df-b112-00215aee3ebe
c2e49f1a-09d2-11ed-80e7-000c29800ae7;ІФ-Галицька 94 Rozetka;Івано-Франківськ вул. Галицька, 94;56bdd87c-749b-11df-b112-00215aee3ebe
03137753-5a9e-11ed-80eb-000c29800ae7;ІФ-Галицька 99 Сім23;Івано-Франківськ вул. Галицька, 99;56bdd87c-749b-11df-b112-00215aee3ebe
95b779da-ae36-11ed-80ec-000c29800ae7;ІФ-Героїв Миколаєва / Сеченова 80 Сім23;Угорники вул. Героїв Миколаєва, 80;8716216e-749b-11df-b112-00215aee3ebe
40f44512-9118-11ee-80c1-000c2961d091;ІФ-Гетьмана Мазепи 164 Contact Service;Івано-Франківськ вул. Гетьмана Мазепи, 164;56bdd87c-749b-11df-b112-00215aee3ebe
995ebcaa-083c-11ed-80e7-000c29800ae7;ІФ-Гетьмана Мазепи 164 Lifecell;Івано-Франківськ вул. Гетьмана Мазепи, 164;56bdd87c-749b-11df-b112-00215aee3ebe
e7f2bb10-48b5-11ee-80c0-000c2961d091;ІФ-Гетьмана Мазепи 164 корп 4 PURE;Івано-Франківськ вул. Гетьмана Мазепи, 164 корп 4;56bdd87c-749b-11df-b112-00215aee3ebe
06828a5b-758a-11ee-80c1-000c2961d091;ІФ-Гетьмана Мазепи 168А Сільпо;Івано-Франківськ вул. Гетьмана Мазепи, 168А;56bdd87c-749b-11df-b112-00215aee3ebe
5ee0b382-7eb7-11ec-80e4-000c29800ae7;ІФ-Гетьмана Мазепи 168Б Ремонт комп. тех.;Івано-Франківськ вул. Гетьмана Мазепи, 168Б;56bdd87c-749b-11df-b112-00215aee3ebe
b8842740-40d9-11e9-80dd-1c98ec135261;ІФ-Гетьмана Мазепи 40/2 Сервіс SmartPhone;Івано-Франківськ вул. Гетьмана Мазепи, 40/2;56bdd87c-749b-11df-b112-00215aee3ebe
2cd59db9-77f4-11ee-80c1-000c2961d091;ІФ-Гетьмана Мазепи 59 АЮ-mobile;Івано-Франківськ вул. Гетьмана Мазепи, 59;56bdd87c-749b-11df-b112-00215aee3ebe
eb954b50-56e1-11ee-80c0-000c2961d091;ІФ-Двірська 24 Маркет Сімі;Крихівці вул. Двірська, 24;62c3ce24-749b-11df-b112-00215aee3ebe
27d88429-7589-11ee-80c1-000c2961d091;ІФ-Дністровська 3 Сільпо;Івано-Франківськ вул. Дністровська, 3;56bdd87c-749b-11df-b112-00215aee3ebe
8b53883f-0446-11ee-80bd-000c2961d091;ІФ-Довга 74 Сім23;Івано-Франківськ вул. Довга, 74;56bdd87c-749b-11df-b112-00215aee3ebe
092ef74b-0c11-11ee-80bd-000c2961d091;ІФ-Довга 76 СПЕЦ;Івано-Франківськ вул. Довга, 76;56bdd87c-749b-11df-b112-00215aee3ebe
6417643b-aeda-11ee-80c4-000c2961d091;ІФ-Довженка Олександра 21А;Івано-Франківськ вул. Довженка Олександра, 21А;56bdd87c-749b-11df-b112-00215aee3ebe
84b78774-8a1c-11ee-80c1-000c2961d091;ІФ-Довженка Олександра 25Б Чистенько;Івано-Франківськ вул. Довженка Олександра, 25Б;56bdd87c-749b-11df-b112-00215aee3ebe
797f892d-f1e4-11ec-80e7-000c29800ae7;ІФ-Довженка Олександра 59 Rozetka;Івано-Франківськ вул. Довженка Олександра, 59;56bdd87c-749b-11df-b112-00215aee3ebe
47c7f3e5-0444-11ee-80bd-000c2961d091;ІФ-Дорошенка 22Б Сім23;Івано-Франківськ вул. Дорошенка, 22Б;56bdd87c-749b-11df-b112-00215aee3ebe
1f6c1732-13d5-11ed-80e7-000c29800ae7;ІФ-Дудаєва Джохара 1 СЦ КВЕРТІ;Івано-Франківськ вул. Дудаєва Джохара, 1;56bdd87c-749b-11df-b112-00215aee3ebe
a6f3d850-d8f8-11e7-80cc-1c98ec135261;ІФ-Євгена Коновальця 113 Будівельні товари;Івано-Франківськ вул. Євгена Коновальця, 113;56bdd87c-749b-11df-b112-00215aee3ebe
4cc44f7f-a406-11ee-80c4-000c2961d091;ІФ-Євгена Коновальця 121А Щедра Хата;Івано-Франківськ вул. Євгена Коновальця, 121А;56bdd87c-749b-11df-b112-00215aee3ebe
5301be3b-56de-11ee-80c0-000c2961d091;ІФ-Євгена Коновальця 146Г Сім23;Івано-Франківськ вул. Євгена Коновальця, 146Г;56bdd87c-749b-11df-b112-00215aee3ebe
7b40fcad-0c24-11ee-80bd-000c2961d091;ІФ-Євгена Коновальця 149 Rozetka;Івано-Франківськ вул. Євгена Коновальця, 149;56bdd87c-749b-11df-b112-00215aee3ebe
090f92a0-7bad-11e9-80e0-1c98ec135261;ІФ-Євгена Коновальця 225В ОККО;Івано-Франківськ вул. Євгена Коновальця, 225В;56bdd87c-749b-11df-b112-00215aee3ebe
bba179cf-a3fd-11ea-80c8-000c29800ae7;ІФ-Євгена Коновальця 28 Швидкий Олівець;Івано-Франківськ вул. Євгена Коновальця, 28;56bdd87c-749b-11df-b112-00215aee3ebe
2e3640b8-ae37-11ed-80ec-000c29800ae7;ІФ-Євгена Коновальця 289Ж Сім23;Івано-Франківськ вул. Євгена Коновальця, 289Ж;56bdd87c-749b-11df-b112-00215aee3ebe
14788b62-96e1-11eb-80d3-000c29800ae7;ІФ-Євгена Коновальця 291А Промтовари;Івано-Франківськ вул. Євгена Коновальця, 291А;56bdd87c-749b-11df-b112-00215aee3ebe
bcb68707-36fd-11ec-80e3-000c29800ae7;ІФ-Євгена Коновальця 86 Сім23;Івано-Франківськ вул. Євгена Коновальця, 86;56bdd87c-749b-11df-b112-00215aee3ebe
358ae3c1-ae35-11ed-80ec-000c29800ae7;ІФ-Європейська 3 Сім23;Вовчинець вул. Європейська, 3;4aba3da5-749b-11df-b112-00215aee3ebe
93a2cd6f-425c-11ee-80c0-000c2961d091;ІФ-Європейська 3/202 Сім23;Івано-Франківськ пл. Європейська, 3/202;56bdd87c-749b-11df-b112-00215aee3ebe
286d7c81-425a-11ee-80c0-000c2961d091;ІФ-Залізнична 3 Сім23;Івано-Франківськ вул. Залізнична, 3;56bdd87c-749b-11df-b112-00215aee3ebe
34db82f4-6433-11ee-80c0-000c2961d091;ІФ-Івасюка 21Г Rozetka;Івано-Франківськ вул. Івасюка, 21Г;56bdd87c-749b-11df-b112-00215aee3ebe
e7df9e41-f967-11ed-80ed-000c29800ae7;ІФ-Івасюка 26А Техніка для дому;Івано-Франківськ вул. Івасюка, 26А;56bdd87c-749b-11df-b112-00215aee3ebe
b3361e40-41c8-11ee-80c0-000c2961d091;ІФ-Карпатська 17 Продукти ;Івано-Франківськ вул. Карпатська, 17;56bdd87c-749b-11df-b112-00215aee3ebe
1a7928a1-5ec5-11e1-b817-003048d2b473;ІФ-Лепкого 10 СПЕЦ;Івано-Франківськ вул. Лепкого, 10;56bdd87c-749b-11df-b112-00215aee3ebe
5c2b25b6-345e-11e9-80dd-1c98ec135261;ІФ-Максимовича 12 ОККО;Івано-Франківськ вул. Максимовича, 12;56bdd87c-749b-11df-b112-00215aee3ebe
ef5a4bd4-0b21-11ec-80d6-000c29800ae7;ІФ-Максимовича 15 ТОР-БА;Івано-Франківськ вул. Максимовича, 15;56bdd87c-749b-11df-b112-00215aee3ebe
33e7efc8-5086-11ee-80c0-000c2961d091;ІФ-Матейка 21 Автокосметика;Івано-Франківськ вул. Матейка, 21;56bdd87c-749b-11df-b112-00215aee3ebe
6b57944b-a797-11e9-80e4-1c98ec135261;ІФ-Набережна імені Василя Стефаника 5 ОККО;Івано-Франківськ вул. Набережна імені Василя Стефаника, 5;56bdd87c-749b-11df-b112-00215aee3ebe
ed6a5568-a405-11ee-80c4-000c2961d091;ІФ-Національної Гвардії 14Ф Щедра Хата;Івано-Франківськ вул. Національної Гвардії, 14Ф;56bdd87c-749b-11df-b112-00215aee3ebe
d6195c29-cf71-11e7-80cc-1c98ec135261;ІФ-Незалежності 83 Сервісний Центр;Івано-Франківськ вул. Незалежності, 83;56bdd87c-749b-11df-b112-00215aee3ebe
3664ee57-ca50-11ee-80c4-000c2961d091;ІФ-Ольги Кисілевської 40 Побутова хімія Чисто Та;Івано-Франківськ вул. Кисілевської Ольги, 40;56bdd87c-749b-11df-b112-00215aee3ebe
6f305eac-cd4d-11ed-80ed-000c29800ae7;ІФ-Орлика Пилипа 10 Салон Краси Чорна і Біла;Івано-Франківськ вул. Орлика Пилипа, 10;56bdd87c-749b-11df-b112-00215aee3ebe
77f6109e-a4bf-11ee-80c4-000c2961d091;ІФ-Пасічна 15 Good Beer;Івано-Франківськ вул. Пасічна, 15;56bdd87c-749b-11df-b112-00215aee3ebe
4f5d9ac0-2a77-11eb-80ce-000c29800ae7;ІФ-Пасічна 1Б AYMobile;Івано-Франківськ вул. Пасічна, 1Б;56bdd87c-749b-11df-b112-00215aee3ebe
66fe8c35-e9fb-11eb-80d5-000c29800ae7;ІФ-Пасічна 38 Широка Газдиня;Івано-Франківськ вул. Пасічна, 38;56bdd87c-749b-11df-b112-00215aee3ebe
1cf6405f-c640-11e8-80d7-1c98ec135261;ІФ-Північний 12 Буон В’яджо;Івано-Франківськ бул. Північний, 12;56bdd87c-749b-11df-b112-00215aee3ebe
88499bbb-758a-11ee-80c1-000c2961d091;ІФ-Північний ім. Пушкіна 2А Сільпо;Івано-Франківськ бул. Північний ім. Пушкіна, 2А;56bdd87c-749b-11df-b112-00215aee3ebe
3e42a5d5-92f2-11eb-80d3-000c29800ae7;ІФ-Симоненка 15Б Bios;Івано-Франківськ вул. Симоненка, 15Б;56bdd87c-749b-11df-b112-00215aee3ebe
947192e5-06aa-11ed-80e7-000c29800ae7;ІФ-Симоненка 30А Сім23;Івано-Франківськ вул. Симоненка, 30А;56bdd87c-749b-11df-b112-00215aee3ebe
eb1e570f-425c-11ee-80c0-000c2961d091;ІФ-Симоненка 7А Сім23;Івано-Франківськ вул. Симоненка, 7А;56bdd87c-749b-11df-b112-00215aee3ebe
f624af58-f1e4-11ec-80e7-000c29800ae7;ІФ-Січових Стрільців 68А Rozetka;Івано-Франківськ вул. Січових Стрільців, 68А;56bdd87c-749b-11df-b112-00215aee3ebe
257a662f-dac5-11ed-80ed-000c29800ae7;ІФ-Січових Стрільців 9 Оптика KLEIMAN;Івано-Франківськ вул. Січових Стрільців, 9;56bdd87c-749b-11df-b112-00215aee3ebe
dbaedd85-ae34-11ed-80ec-000c29800ae7;ІФ-Слобідська 26 Сім23;Крихівці вул. Слобідська, 26;62c3ce24-749b-11df-b112-00215aee3ebe
8c8e59c6-0443-11ee-80bd-000c2961d091;ІФ-Стуса 17А Сім23;Івано-Франківськ вул. Стуса, 17А;56bdd87c-749b-11df-b112-00215aee3ebe
b92a920a-0696-11ee-80bd-000c2961d091;ІФ-Стуса 25 СПЕЦ;Івано-Франківськ вул. Стуса, 25;56bdd87c-749b-11df-b112-00215aee3ebe
2ef2a77f-ae36-11ed-80ec-000c29800ae7;ІФ-Стуса 35 Сім23;Івано-Франківськ вул. Стуса, 35;56bdd87c-749b-11df-b112-00215aee3ebe
83b10fb3-e4a1-11eb-80d5-000c29800ae7;ІФ-Стуса 38 Чері;Івано-Франківськ вул. Стуса, 38;56bdd87c-749b-11df-b112-00215aee3ebe
fbf60778-166e-11ee-80bd-000c2961d091;ІФ-Стуса 40 Rozetka;Івано-Франківськ вул. Стуса, 40;56bdd87c-749b-11df-b112-00215aee3ebe
27f6dff1-4fb2-11ed-80eb-000c29800ae7;ІФ-Стуса 5 Rozetka;Івано-Франківськ вул. Стуса, 5;56bdd87c-749b-11df-b112-00215aee3ebe
8518bfd6-34f4-11e9-80dd-1c98ec135261;ІФ-Тисменицька 249 ОККО;Івано-Франківськ вул. Тисменицька, 249;56bdd87c-749b-11df-b112-00215aee3ebe
bd07ad6c-ae37-11ed-80ec-000c29800ae7;ІФ-Франка Івана 10 Сім23;Івано-Франківськ вул. Франка Івана, 10;56bdd87c-749b-11df-b112-00215aee3ebe
90fab720-51e8-11ec-80e4-000c29800ae7;ІФ-Хіміків 19 Смайлик;Івано-Франківськ вул. Хіміків, 19;56bdd87c-749b-11df-b112-00215aee3ebe
188e18e8-ba29-11eb-80d4-000c29800ae7;ІФ-Хмельницького Богдана 53 Аптека;Івано-Франківськ вул. Хмельницького Богдана, 53;56bdd87c-749b-11df-b112-00215aee3ebe
584b0545-4147-11eb-80d0-000c29800ae7;ІФ-Хоткевича 65/2 Televik;Івано-Франківськ вул. Хоткевича, 65/2;56bdd87c-749b-11df-b112-00215aee3ebe
c08bf5af-f03c-11eb-80d5-000c29800ae7;ІФ-Хоткевича 69 Ломбард;Івано-Франківськ вул. Хоткевича, 69;56bdd87c-749b-11df-b112-00215aee3ebe
40d04e51-48dc-11ea-80c6-000c29800ae7;ІФ-Хриплинська 9 ОККО;Івано-Франківськ вул. Хриплинська, 9;56bdd87c-749b-11df-b112-00215aee3ebe
6e52fbf4-5121-11ec-80e3-000c29800ae7;ІФ-Целевича Юліана 4А MD маркет;Івано-Франківськ вул. Целевича Юліана, 4А;56bdd87c-749b-11df-b112-00215aee3ebe
52fa509d-a40b-11ee-80c4-000c2961d091;ІФ-Чорновола 103 ТеплоФранк;Івано-Франківськ вул. Чорновола, 103;56bdd87c-749b-11df-b112-00215aee3ebe
ac60f867-971f-11e8-80d4-1c98ec135261;ІФ-Чорновола 107 Сервісний Центр;Івано-Франківськ вул. Чорновола, 107;56bdd87c-749b-11df-b112-00215aee3ebe
091703b9-cb09-11e9-80e4-1c98ec135261;ІФ-Юності 5 Світанок;Микитинці вул. Юності, 5;68cf2775-749b-11df-b112-00215aee3ebe
1bf39902-be33-11eb-80d5-000c29800ae7;Ічня-Гоголя Миколи 2;Ічня вул. Гоголя Миколи, 2;56bdd94a-749b-11df-b112-00215aee3ebe
494fbf10-4716-11e9-80dd-1c98ec135261;Ічня-Коваля 1;Ічня вул. Коваля, 1;56bdd94a-749b-11df-b112-00215aee3ebe
2c9e177a-3ca2-11ec-80e3-000c29800ae7;Кагарлик-Незалежності 11;Кагарлик вул. Незалежності, 11;56bdd988-749b-11df-b112-00215aee3ebe
cb6a1fdd-f042-11eb-80d5-000c29800ae7;Кагарлик-Свято-Троїцька 2Г;Кагарлик вул. Свято-Троїцька, 2Г;56bdd988-749b-11df-b112-00215aee3ebe
11751d87-401b-11e9-80dd-1c98ec135261;Кадубівці-Михайлівського 2 А;Кадубівці вул. Михайлівського, 2А;56bdd992-749b-11df-b112-00215aee3ebe
f8d791e6-20b2-11e9-80dc-1c98ec135261;Калинівка-Київська 50/52;Калинівка вул. Київська, 50/52;56bdd9ee-749b-11df-b112-00215aee3ebe
d7eb31d4-4b29-11e9-80dd-1c98ec135261;Калинівка-Київська,3-В;Калинівка вул. Київська, 3В;56bdd9ee-749b-11df-b112-00215aee3ebe
22b56f66-8de7-11ee-80c1-000c2961d091;Калинівка-Мазепи І 45Д;Калинівка вул. Мазепи І, 45Д;56bdd9fe-749b-11df-b112-00215aee3ebe
fcc2feb2-8de1-11ee-80c1-000c2961d091;Калинівка-Незалежності 74;Калинівка вул. Незалежності, 74;56bdd9fe-749b-11df-b112-00215aee3ebe
de10c2bd-ba8f-11eb-80d4-000c29800ae7;Калинівка-Центральна 36;Калинівка вул. Центральна, 36;56bdd9ef-749b-11df-b112-00215aee3ebe
d36c9f67-c747-11ee-80c4-000c2961d091;Калинівка-Центральна 59 Rozetka;Калинівка вул. Центральна, 59;56bdd9ef-749b-11df-b112-00215aee3ebe
91fbf3e9-741d-11ea-80c6-000c29800ae7;Калуш-Біласа-Данилишина, 14А;Калуш вул. Біласа-Данилишина, 14-А;56bdda3b-749b-11df-b112-00215aee3ebe
253e6437-a546-11e8-80d4-1c98ec135261;Калуш-Героїв пл.25;Калуш пл. Героїв, 25;56bdda3b-749b-11df-b112-00215aee3ebe
914ca7ae-a79e-11e9-80e4-1c98ec135261;Калуш-Івано-Франківська 140;Калуш вул. Івано-Франківська, 140;56bdda3b-749b-11df-b112-00215aee3ebe
f495d783-067e-11ed-80e7-000c29800ae7;Калуш-Незалежності 2;Калуш бул. Незалежності, 2;56bdda3b-749b-11df-b112-00215aee3ebe
ff238960-909c-11eb-80d2-000c29800ae7;Калуш-Підвальна 14;Калуш вул. Підвальна, 14;56bdda3b-749b-11df-b112-00215aee3ebe
64e8e02b-cbee-11ee-80c4-000c2961d091;Калуш-Стуса Василя 32В;Калуш вул. Стуса Василя, 32В;56bdda3b-749b-11df-b112-00215aee3ebe
1d831dd7-f03e-11eb-80d5-000c29800ae7;Калуш-Хмельницького Богдана 29;Калуш вул. Хмельницького Богдана, 29;56bdda3b-749b-11df-b112-00215aee3ebe
ce456919-3070-11ee-80bd-000c2961d091;Калуш-Хмельницького Богдана 30;Калуш вул. Хмельницького Богдана, 30;56bdda3b-749b-11df-b112-00215aee3ebe
e818b9df-effb-11ed-80ed-000c29800ae7;Калуш-Хмельницького Богдана 6;Калуш вул. Хмельницького Богдана, 6;56bdda3b-749b-11df-b112-00215aee3ebe
1904dbdd-93d2-11e8-80d4-1c98ec135261;Калуш-Хмельницького Богдана, 37/2;Калуш вул. Хмельницького Богдана, 37/2;56bdda3b-749b-11df-b112-00215aee3ebe
d09b553e-758a-11ee-80c1-000c2961d091;Калуш-Шептицького 7;Калуш Майдан Шептицького, 7;56bdda3b-749b-11df-b112-00215aee3ebe
4fde0044-6970-11ec-80e4-000c29800ae7;Калуш-Шкільний 2А;Калуш пров. Шкільний, 2А;56bdda3b-749b-11df-b112-00215aee3ebe
a8188a49-6e72-11ee-80c1-000c2961d091;Кам.-Подільський-Васильєва 1 Будматеріали;Кам'янець-Подільський вул. Героїв УПА (Васильєва), 1;5cb61495-749b-11df-b112-00215aee3ebe
1aa85e24-92e7-11ee-80c1-000c2961d091;Кам.-Подільський-Васильєва 15 Rozetka;Кам'янець-Подільський вул. Героїв УПА (Васильєва), 15;5cb61495-749b-11df-b112-00215aee3ebe
c0b3a822-a7a5-11e9-80e4-1c98ec135261;Кам.-Подільський-Героїв Маріуполя 6 ОККО;Кам'янець-Подільський вул. Героїв Маріуполя ( Чехова ), 6;5cb61495-749b-11df-b112-00215aee3ebe
798e867a-0471-11ee-80bd-000c2961d091;Кам.-Подільський-Грушевського 27/5 Lifecell;Кам'янець-Подільський вул. Грушевського, 27/5;5cb61495-749b-11df-b112-00215aee3ebe
b95728a3-a7a4-11e9-80e4-1c98ec135261;Кам.-Подільський-Грушевського 2В ОККО;Кам'янець-Подільський Просп. Грушевського, 2В;5cb61495-749b-11df-b112-00215aee3ebe
888ab64f-dac5-11ed-80ed-000c29800ae7;Кам.-Подільський-Грушевського 38 KLEIMAN;Кам'янець-Подільський вул. Грушевського, 38;5cb61495-749b-11df-b112-00215aee3ebe
2e49d6f8-f066-11eb-80d5-000c29800ae7;Кам.-Подільський-Грушевського 42 Ломбард;Кам'янець-Подільський вул. Грушевського, 42;5cb61495-749b-11df-b112-00215aee3ebe
f00bbd53-24e7-11eb-80cd-000c29800ae7;Кам.-Подільський-Грушевського 46 Карандаш;Кам'янець-Подільський Просп. Грушевського, 46;5cb61495-749b-11df-b112-00215aee3ebe
4f2536af-51e7-11ec-80e4-000c29800ae7;Кам.-Подільський-Князів Коріатовичів 5 МОРЕ-МОРЕ;Кам'янець-Подільський вул. Князів Коріатовичів, 5;5cb61495-749b-11df-b112-00215aee3ebe
5086647e-758b-11ee-80c1-000c2961d091;Кам.-Подільський-Нігинське 41/1 Сільпо;Кам'янець-Подільський шосе Нігинське, 41/1;5cb61495-749b-11df-b112-00215aee3ebe
515f5df1-8ec0-11ea-80c6-000c29800ae7;Кам.-Подільський-Огієнка 28 ПершаСтрахова;Кам'янець-Подільський вул. Огієнка, 28;5cb61495-749b-11df-b112-00215aee3ebe
1c77e16a-f065-11eb-80d5-000c29800ae7;Кам.-Подільський-Привокзальна 18 Ломбард;Кам'янець-Подільський вул. Привокзальна, 18;5cb61495-749b-11df-b112-00215aee3ebe
a4bce6bb-ba4a-11e8-80d7-1c98ec135261;Кам.-Подільський-Привокзальна 28/5 Steko;Кам'янець-Подільський вул. Привокзальна, 28/5;5cb61495-749b-11df-b112-00215aee3ebe
cc235a3d-f065-11eb-80d5-000c29800ae7;Кам.-Подільський-Соборна 16 Ломбард;Кам'янець-Подільський вул. Соборна, 16;5cb61495-749b-11df-b112-00215aee3ebe
ad7e4239-f0c1-11ec-80e7-000c29800ae7;Кам.-Подільський-Соборна 19 Rozetka;Кам'янець-Подільський вул. Соборна, 19;5cb61495-749b-11df-b112-00215aee3ebe
ba0cbe4f-758b-11ee-80c1-000c2961d091;Кам.-Подільський-Степана Бандери 42/1 Сільпо;Кам'янець-Подільський вул. Степана Бандери (Гагаріна), 42/1;5cb61495-749b-11df-b112-00215aee3ebe
418e4dfc-758c-11ee-80c1-000c2961d091;Кам.-Подільський-Українки Лесі 30 Сільпо;Кам'янець-Подільський вул. Українки Лесі, 30;5cb61495-749b-11df-b112-00215aee3ebe
558d0d9d-ccc2-11ee-80c4-000c2961d091;Кам.-Подільський-Шевченка 2 Автомаг;Кам'янець-Подільський вул. Шевченка, 2;5cb61495-749b-11df-b112-00215aee3ebe
2c078ac1-42e5-11df-9eec-0030485a2fe2;Кам'янець-Подільський;Кам'янець-Подільський вул. Вокзальна, 2;5cb61495-749b-11df-b112-00215aee3ebe
012ae7c4-f9c6-11eb-80d5-000c29800ae7;Кам'янка-Бузька-Незалежності 57А;Кам'янка-Бузька вул. Незалежності, 57А;5cb614da-749b-11df-b112-00215aee3ebe
4198e924-f86b-11eb-80d5-000c29800ae7;Кам'янка-Бузька-Незалежності 61/3;Кам'янка-Бузька вул. Незалежності, 61/3;5cb614da-749b-11df-b112-00215aee3ebe
9485367a-5c6d-11ee-80c0-000c2961d091;Кам'янка-Пушкіна 37;Кам'янка вул. Пушкіна, 37;5cb614d1-749b-11df-b112-00215aee3ebe
1cac4b9b-f56c-11ed-80ed-000c29800ae7;Кам'янка-Пушкіна 54;Кам'янка вул. Пушкіна, 54;5cb614d1-749b-11df-b112-00215aee3ebe
32687c76-e334-11ed-80ed-000c29800ae7;Кам'янка-Синюка 2;Кам'янка вул. Синюка, 2;5cb614b0-749b-11df-b112-00215aee3ebe
e23ffd91-47cd-11ee-80c0-000c2961d091;Кам'янське-40-річчя Перемоги 13 Нектар;Кам'янське вул. 40-річчя Перемоги, 13;50c59518-749b-11df-b112-00215aee3ebe
9058898e-47ce-11ee-80c0-000c2961d091;Кам'янське-Аношкіна 66 Нектар;Кам'янське Просп. Аношкіна, 66;50c59518-749b-11df-b112-00215aee3ebe
9440a4a4-a7b6-11e9-80e4-1c98ec135261;Кам'янське-Аношкіна 8А ОККО;Кам'янське Просп. Аношкіна, 8А;50c59518-749b-11df-b112-00215aee3ebe
299749b0-f12a-11ec-80e7-000c29800ae7;Кам'янське-Будівельників 27А Rozetka;Кам'янське бул. Будівельників, 27А;50c59518-749b-11df-b112-00215aee3ebe
71e68aa4-e43d-11ed-80ed-000c29800ae7;Кам'янське-Будівельників 27А Мир Чехлов;Кам'янське бул. Будівельників, 27А;50c59518-749b-11df-b112-00215aee3ebe
ca1e0423-47d3-11ee-80c0-000c2961d091;Кам'янське-Будівельників 28 Нектар;Кам'янське Просп. Будівельників, 28;50c59518-749b-11df-b112-00215aee3ebe
9ddc11de-cfec-11ee-80c4-000c2961d091;Кам'янське-Будівельників 37Л Мир Чехлов;Кам'янське бул. Будівельників, 37Л;50c59518-749b-11df-b112-00215aee3ebe
88b1f2da-20cc-11ee-80bd-000c2961d091;Кам'янське-Будівельників 7 Lifecell;Кам'янське бул. Будівельників, 7;50c59518-749b-11df-b112-00215aee3ebe
c640f9e2-40ba-11ec-80e3-000c29800ae7;Кам'янське-Василя Стуса 14 ППО Ремонт;Кам'янське Просп. Василя Стуса, 14;50c59518-749b-11df-b112-00215aee3ebe
61967946-0c35-11ee-80bd-000c2961d091;Кам'янське-Василя Стуса 19 Делві;Кам'янське Просп. Василя Стуса, 19;50c59518-749b-11df-b112-00215aee3ebe
4c6f3cf8-5d41-11ee-80c0-000c2961d091;Кам'янське-Воїнів-Афганців 18прим.73 Делві;Кам'янське вул. Воїнів-Афганців (Воїнів Інтернаціоналіст, 18прим.73;50c59518-749b-11df-b112-00215aee3ebe
44e7bad9-c53f-11eb-80d5-000c29800ae7;Кам'янське-Генерала Глаголєва 18/26 Ломбард;Кам'янське вул. Сержанта Коновалова (Глаголева), 18/26;50c59518-749b-11df-b112-00215aee3ebe
103c0dab-e43d-11ed-80ed-000c29800ae7;Кам'янське-Генерала Глаголєва 18/26 Мир Чехлов;Кам'янське вул. Сержанта Коновалова (Глаголева), 18/26;50c59518-749b-11df-b112-00215aee3ebe
7b45105a-47d3-11ee-80c0-000c2961d091;Кам'янське-Героїв 16 Нектар;Кам'янське вул. Героїв, 16;50c59518-749b-11df-b112-00215aee3ebe
983c060b-fab8-11eb-80d6-000c29800ae7;Кам'янське-Героїв 38А Делві;Кам'янське бул. Героїв, 38А;50c59518-749b-11df-b112-00215aee3ebe
19e3916b-cfed-11ee-80c4-000c2961d091;Кам'янське-Героїв АТО 15Д Мир Чехлов;Кам'янське Просп. Героїв АТО, 15Д;50c59518-749b-11df-b112-00215aee3ebe
629e45f3-f580-11ed-80ed-000c29800ae7;Кам'янське-Горького 19А Делві;Кам'янське вул. Єдності, 19А;50c59518-749b-11df-b112-00215aee3ebe
048621f3-fab9-11eb-80d6-000c29800ae7;Кам'янське-Дружби Народів 55 Делві;Кам'янське вул. Дружби Народів, 55;50c59518-749b-11df-b112-00215aee3ebe
76ddde05-cfed-11ee-80c4-000c2961d091;Кам'янське-Дунайська 41Б Мир Чехлов;Кам'янське вул. Дунайська, 41Б;50c59518-749b-11df-b112-00215aee3ebe
b3445667-510e-11ec-80e3-000c29800ae7;Кам'янське-Конституції 17 Мобільний світ;Кам'янське Просп. Конституції, 17;50c59518-749b-11df-b112-00215aee3ebe
6c18c2ba-ab91-11ed-80ec-000c29800ae7;Кам'янське-Конституції 17Г Rozetka;Кам'янське Просп. Конституції, 17Г;50c59518-749b-11df-b112-00215aee3ebe
0b6aea02-b5f4-11ee-80c4-000c2961d091;Кам'янське-Конституції 18 Andi;Кам'янське Просп. Конституції, 18;50c59518-749b-11df-b112-00215aee3ebe
53c7f8ab-886d-11ea-80c6-000c29800ae7;Кам'янське-Металургів 74 CityMobile;Кам'янське вул. Металургів, 74;50c59518-749b-11df-b112-00215aee3ebe
701b8db3-e43c-11ed-80ed-000c29800ae7;Кам'янське-Перемоги 59А Мир Чехлов;Кам'янське Просп. Перемоги, 59А;50c59518-749b-11df-b112-00215aee3ebe
cbfe2830-5dbd-11ec-80e4-000c29800ae7;Кам'янське-Сачка 12 Mobile service;Кам'янське вул. Сачка, 12;50c59518-749b-11df-b112-00215aee3ebe
dc1976cb-20cc-11ee-80bd-000c2961d091;Кам'янське-Свободи 107 Lifecell;Кам'янське Просп. Свободи, 107;50c59518-749b-11df-b112-00215aee3ebe
969f2970-e43a-11ed-80ed-000c29800ae7;Кам'янське-Свободи 51 Мир Чехлов;Кам'янське Просп. Свободи, 51;50c59518-749b-11df-b112-00215aee3ebe
26752b74-f129-11ec-80e7-000c29800ae7;Кам'янське-Свободи 53 Rozetka;Кам'янське Просп. Свободи, 53;50c59518-749b-11df-b112-00215aee3ebe
0059a0d4-9e3a-11e9-80e2-1c98ec135261;Кам'янське-Свободи 54Є СЦ Склад чохлів;Кам'янське Просп. Свободи, 54Є;50c59518-749b-11df-b112-00215aee3ebe
97427204-cfe4-11ee-80c4-000c2961d091;Кам'янське-Свободи 56 Мир Чехлов;Кам'янське Просп. Свободи, 56;50c59518-749b-11df-b112-00215aee3ebe
d448e5b6-e43b-11ed-80ed-000c29800ae7;Кам'янське-Свободи 59 Мир Чехлов;Кам'янське Просп. Свободи, 59;50c59518-749b-11df-b112-00215aee3ebe
aeb7b07c-8de4-11ee-80c1-000c2961d091;Кам'янське-Січеславський Шлях 154А MAN GO;Кам'янське вул. Січеславський Шлях ( Дніпропетровська ), 154А;50c59518-749b-11df-b112-00215aee3ebe
8f866183-95c9-11ee-80c1-000c2961d091;Кам'янське-Соборна 6В CowWorking;Кам'янське вул. Соборна, 6В;50c59518-749b-11df-b112-00215aee3ebe
f0b504bd-b5f0-11ee-80c4-000c2961d091;Кам'янське-Стуса 13Д Andi;Кам'янське вул. Стуса, 13Д;50c59518-749b-11df-b112-00215aee3ebe
477f854f-e00e-11ed-80ed-000c29800ae7;Кам'янське-Стуса 13Д Rozetka;Кам'янське вул. Стуса, 13Д;50c59518-749b-11df-b112-00215aee3ebe
be567bf9-4ea6-11eb-80d0-000c29800ae7;Кам'янське-Тараса Шевченка /Сировця/ 18 СТАНДАРТ;Кам'янське Просп. Тараса Шевченка /Сировця/, 18;50c59518-749b-11df-b112-00215aee3ebe
7ba2f3ac-fab9-11eb-80d6-000c29800ae7;Кам'янське-Тараса Шевченка /Сировця/ 33Д Делві;Кам'янське Просп. Тараса Шевченка /Сировця/, 33Д;50c59518-749b-11df-b112-00215aee3ebe
c7a6adf0-758c-11ee-80c1-000c2961d091;Кам'янське-Тараса Шевченка /Сировця/ 9 Сільпо;Кам'янське Просп. Тараса Шевченка /Сировця/, 9;50c59518-749b-11df-b112-00215aee3ebe
53f24a4b-47cf-11ee-80c0-000c2961d091;Кам'янське-Харківська 27 Нектар;Кам'янське вул. Харківська, 27;50c59518-749b-11df-b112-00215aee3ebe
86b01697-af3d-11eb-80d4-000c29800ae7;Кам'янське-Чорновола 69/20 Канцтовари;Кам'янське вул. Чорновола, 69/20;50c59518-749b-11df-b112-00215aee3ebe
f2717b75-e43d-11ed-80ed-000c29800ae7;Кам'янське-Шевченка 2К Мир Чехлов;Кам'янське пров. Шевченка, 2К;50c59518-749b-11df-b112-00215aee3ebe
9e2696c9-0c31-11ee-80bd-000c2961d091;Кам'янське-Шевченка 50А-1 Делві;Кам'янське пров. Шевченка, 50А-1;50c59518-749b-11df-b112-00215aee3ebe
596453b3-e436-11ed-80ed-000c29800ae7;Кам'янське-Шевченка 8А Мир Чехлов;Кам'янське пров. Шевченка, 8А;50c59518-749b-11df-b112-00215aee3ebe
b5442d88-491d-11ec-80e3-000c29800ae7;Кам'янське-Ювілейний 27 Будівник;Кам'янське Просп. Ювілейний, 27;50c59518-749b-11df-b112-00215aee3ebe
d39ab87d-e30a-11eb-80d5-000c29800ae7;Камінь-Каширський-Незалежності 10;Камінь-Каширський пл. Незалежності, 10;5cb61478-749b-11df-b112-00215aee3ebe
00f83ce5-be10-11eb-80d5-000c29800ae7;Камінь-Каширський-Шевченка 22;Камінь-Каширський вул. Шевченка, 22;5cb61478-749b-11df-b112-00215aee3ebe
92275d77-2862-11ed-80e7-000c29800ae7;Камінь-Каширський-Шевченка 7В;Камінь-Каширський вул. Шевченка, 7 В;5cb61478-749b-11df-b112-00215aee3ebe
465b77f4-debd-11e5-88dd-001ec93df11f;Камянське;Кам'янське вул. Воїнів-Афганців (Воїнів Інтернаціоналіст, 1а;50c59518-749b-11df-b112-00215aee3ebe
66415adf-051b-11ee-80bd-000c2961d091;Канів-206-ї Дивізії 5;Канів вул. 206-ї Дивізії, 5;5cb614ff-749b-11df-b112-00215aee3ebe
f0994536-cf38-11eb-80d5-000c29800ae7;Канів-Героїв Дніпра 1;Канів вул. Героїв Дніпра, 1;5cb614ff-749b-11df-b112-00215aee3ebe
58efa07f-758d-11ee-80c1-000c2961d091;Канів-Енергетиків 12А;Канів вул. Енергетиків, 12А;5cb614ff-749b-11df-b112-00215aee3ebe
38535f8e-e9cd-11e9-80e7-1c98ec135261;Канів-Енергетиків 31 А;Канів вул. Енергетиків, 31А;5cb614ff-749b-11df-b112-00215aee3ebe
87e2e3c7-3ca6-11ec-80e3-000c29800ae7;Канів-Кошового Олега 2;Канів вул. Кошового Олега, 2;5cb614ff-749b-11df-b112-00215aee3ebe
e90052bc-53a8-11ee-80c0-000c2961d091;Канів-Шевченка 13;Канів вул. Шевченка, 13;5cb614ff-749b-11df-b112-00215aee3ebe
cb2b01a6-c812-11ee-80c4-000c2961d091;Карлівка-Полтавський Шлях 46;Карлівка вул. Полтавський Шлях, 46;5cb6157d-749b-11df-b112-00215aee3ebe
a4f21315-ef86-11eb-80d5-000c29800ae7;Карлівка-Спартака 7;Карлівка вул. Спартака, 7;5cb6157d-749b-11df-b112-00215aee3ebe
0878ed97-5d0a-11ee-80c0-000c2961d091;Квасилів-Молодіжна 24А Пивна Гавань;Квасилів вул. Молодіжна, 24А;5cb6163a-749b-11df-b112-00215aee3ebe
a71e43eb-be2e-11eb-80d5-000c29800ae7;Кельменці-Довженка Олександра 32/2;Кельменці вул. Довженка Олександра, 32/2;5cb6165a-749b-11df-b112-00215aee3ebe
d14db4c5-8877-11ee-80c1-000c2961d091;Кельменці-Довженка Олександра 9;Кельменці вул. Довженка Олександра, 9;5cb6165a-749b-11df-b112-00215aee3ebe
49ef2fad-3631-11ec-80e3-000c29800ae7;Кельменці-Сагайдачного 35;Кельменці вул. Сагайдачного, 35;5cb6165a-749b-11df-b112-00215aee3ebe
a8bc35df-4aa1-11e3-9df6-003048d2b473;Київ (HUB);Київ(Софіївська Борщагівка) вул. Мала Кільцева, 10/1;80f5570b-749b-11df-b112-00215aee3ebe
6fb79be9-dd18-11ec-80e7-000c29800ae7;Київ- Берестейський (Перемоги) 24 Rozetka;Київ Просп. Берестейський (к.Перемоги (Проспект)), 24;5cb61671-749b-11df-b112-00215aee3ebe
b92e5b63-c9a8-11ee-80c4-000c2961d091;Київ- пров. Світличної Надії 5/2 Зоомагазин;Київ пров. Світличної Надії  ( Волго-Донський ), 5/2;5cb61671-749b-11df-b112-00215aee3ebe
b5027ead-a97f-11ee-80c4-000c2961d091;Київ прос. Воскресенський , 20  Лап-Усик;Київ бул. Воскресенський ( Перова ), 20;5cb61671-749b-11df-b112-00215aee3ebe
41f031f8-5e8b-11ee-80c0-000c2961d091;Київ(Соф.Борщагівка)вул. Мала Кільцева, 10/1 СПЕЦ;Київ(Софіївська Борщагівка) вул. Мала Кільцева, 10/1;80f5570b-749b-11df-b112-00215aee3ebe
391c8478-3fff-11ee-80c0-000c2961d091;Київ-2-а Лугова 1 Testy;Київ вул. 2-а Лугова(Осокорки), 1;5cb61671-749b-11df-b112-00215aee3ebe
ce4ffc98-667e-11ee-80c0-000c2961d091;Київ-57-а Садова 42 Rozetka;Київ вул. 57-а Садова (Осокорки), 42;5cb61671-749b-11df-b112-00215aee3ebe
b3cbb3b0-cf05-11ee-80c4-000c2961d091;Київ-Авіаконструктора Антонова 2/32 корп.4А VSETUT;Київ вул. Авіаконструктора Антонова, 2/32;5cb61671-749b-11df-b112-00215aee3ebe
ad94672d-ef1e-11ed-80ed-000c29800ae7;Київ-Авіаконструктора Антонова 4 Моб. телефони;Київ вул. Авіаконструктора Антонова, 4;5cb61671-749b-11df-b112-00215aee3ebe
1bbd51fb-0e96-11ee-80bd-000c2961d091;Київ-Авіаконструктора Антонова 43 Біле Сухе;Київ вул. Авіаконструктора Антонова, 43;5cb61671-749b-11df-b112-00215aee3ebe
a157d6ef-4026-11ee-80c0-000c2961d091;Київ-Авіаконструктора Антонова 5пр1/1 Продукти;Київ вул. Авіаконструктора Антонова, 5пр1/1;5cb61671-749b-11df-b112-00215aee3ebe
f507edc0-15ba-11ee-80bd-000c2961d091;Київ-Академіка Єфремова 2 Д Зоомагазин;Київ вул. Академіка Єфремова, 2 Д;5cb61671-749b-11df-b112-00215aee3ebe
a6504878-4034-11ee-80c0-000c2961d091;Київ-Академіка Каблукова 19 Продукти;Київ вул. Академіка Каблукова, 19;5cb61671-749b-11df-b112-00215aee3ebe
d1873fe6-a0e9-11ee-80c4-000c2961d091;Київ-Алматинська 4 Rozetka;Київ вул. Алматинська, 4;5cb61671-749b-11df-b112-00215aee3ebe
e95b965c-24d2-11ee-80bd-000c2961d091;Київ-Андрія Верхогляда 16 Сільпо;Київ вул. Андрія Верхогляда ( Драгомирова Михайла, 16;5cb61671-749b-11df-b112-00215aee3ebe
a22cac31-4b30-11ee-80c0-000c2961d091;Київ-Антоновича 165 Rozetka;Київ вул. Антоновича (Горького), 165;5cb61671-749b-11df-b112-00215aee3ebe
045067a2-2497-11ee-80bd-000c2961d091;Київ-Антоновича 165 Сільпо;Київ вул. Антоновича (Горького), 165;5cb61671-749b-11df-b112-00215aee3ebe
3e8e406c-d847-11ec-80e7-000c29800ae7;Київ-Антоновича 50 Rozetka;Київ вул. Антоновича (Горького), 50;5cb61671-749b-11df-b112-00215aee3ebe
b29126be-e82e-11ee-80c5-000c2961d091;Київ-Архипенка Олександра 10 Пивна Лавка;Київ вул. Архипенка Олександра ( Мате Залки ), 10;5cb61671-749b-11df-b112-00215aee3ebe
65c5807f-2497-11ee-80bd-000c2961d091;Київ-Архипенка Олександра 6 Сільпо;Київ вул. Архипенка Олександра ( Мате Залки ), 6;5cb61671-749b-11df-b112-00215aee3ebe
e44dd6a6-cf06-11ee-80c4-000c2961d091;Київ-Архипенка Олександра 8 VSETUT;Київ вул. Архипенка Олександра ( Мате Залки ), 8;5cb61671-749b-11df-b112-00215aee3ebe
5150296b-15c0-11ee-80bd-000c2961d091;Київ-Ахматової Анни 13А Кристальна Вода;Київ вул. Ахматової Анни, 13А;5cb61671-749b-11df-b112-00215aee3ebe
fcb73ad0-dd53-11ea-80ca-000c29800ae7;Київ-Ахматової Анни 14А Канцтовари;Київ вул. Ахматової Анни, 14А;5cb61671-749b-11df-b112-00215aee3ebe
2c08cebd-d796-11ee-80c4-000c2961d091;Київ-Ахматової Анни 22 Біле Сухе;Київ вул. Ахматової Анни, 22;5cb61671-749b-11df-b112-00215aee3ebe
87a4e12d-6627-11eb-80d1-000c29800ae7;Київ-Ахматової Анни 3/5 Lifecell;Київ вул. Ахматової Анни, 3/5;5cb61671-749b-11df-b112-00215aee3ebe
f67e9052-cc0a-11ee-80c4-000c2961d091;Київ-Ахматової Анни 30 F.ua;Київ вул. Ахматової Анни, 30;5cb61671-749b-11df-b112-00215aee3ebe
f030a1af-ada2-11eb-80d3-000c29800ae7;Київ-Ахматової Анни 34 Біле Сухе;Київ вул. Ахматової Анни, 34;5cb61671-749b-11df-b112-00215aee3ebe
5d5ec8fa-8d6e-11eb-80d2-000c29800ae7;Київ-Ахматової Анни 34 Кавярня;Київ вул. Ахматової Анни, 34;5cb61671-749b-11df-b112-00215aee3ebe
38a8e6e3-16f8-11ec-80df-000c29800ae7;Київ-Ахматової Анни 47 Біле Сухе;Київ вул. Ахматової Анни, 47;5cb61671-749b-11df-b112-00215aee3ebe
e846160d-fadd-11ed-80ed-000c29800ae7;Київ-Ахматової Анни 5 Біле Сухе;Київ вул. Ахматової Анни, 5;5cb61671-749b-11df-b112-00215aee3ebe
e416cbde-b54e-11ee-80c4-000c2961d091;Київ-Бажана 10 Пивна Лавка;Київ Просп. Бажана, 10;5cb61671-749b-11df-b112-00215aee3ebe
7ec481d2-4150-11ec-80e3-000c29800ae7;Київ-Бажана 14 China service;Київ Просп. Бажана, 14;5cb61671-749b-11df-b112-00215aee3ebe
9e2a06b7-e615-11eb-80d5-000c29800ae7;Київ-Бажана 1Д АЗС KLO;Київ Просп. Бажана, 1Д;5cb61671-749b-11df-b112-00215aee3ebe
09188bcd-16e2-11ec-80df-000c29800ae7;Київ-Бажана 26 Біле Сухе;Київ Просп. Бажана, 26;5cb61671-749b-11df-b112-00215aee3ebe
0cf57bd0-eed8-11eb-80d5-000c29800ae7;Київ-Бажана 2Б АЗС KLO;Київ Просп. Бажана, 2Б;5cb61671-749b-11df-b112-00215aee3ebe
5a48403f-dbc3-11ee-80c4-000c2961d091;Київ-Бажана 36 Human Cup Coffee;Київ Просп. Бажана, 36;5cb61671-749b-11df-b112-00215aee3ebe
d6362ab8-ba8d-11eb-80d4-000c29800ae7;Київ-Бажана 3Г Експерт;Київ Просп. Бажана, 3Г;5cb61671-749b-11df-b112-00215aee3ebe
ff1dc276-a4c5-11ee-80c4-000c2961d091;Київ-Бажана 8 Beercult;Київ Просп. Бажана, 8;5cb61671-749b-11df-b112-00215aee3ebe
a043dae6-d798-11ee-80c4-000c2961d091;Київ-Балтійський 3А Біле Сухе;Київ пров. Балтійський, 3А;5cb61671-749b-11df-b112-00215aee3ebe
a4e61fb9-2499-11ee-80bd-000c2961d091;Київ-Бережанська 22 Сільпо;Київ вул. Бережанська, 22;5cb61671-749b-11df-b112-00215aee3ebe
ee111a8f-2499-11ee-80bd-000c2961d091;Київ-Березнева 12А Сільпо;Київ вул. Березнева, 12А;5cb61671-749b-11df-b112-00215aee3ebe
f5e5dd99-61d5-11e9-80df-1c98ec135261;Київ-Березняківська 27 Автозапчастини;Київ вул. Березняківська, 27;5cb61671-749b-11df-b112-00215aee3ebe
8566759e-e84c-11ee-80c5-000c2961d091;Київ-Березняківська 36Б Life love;Київ вул. Березняківська, 36Б;5cb61671-749b-11df-b112-00215aee3ebe
f03eb90d-40c3-11ee-80c0-000c2961d091;Київ-Берестейський 100 Vodafone;Київ Просп. Берестейський (к.Перемоги (Проспект)), 100;5cb61671-749b-11df-b112-00215aee3ebe
12fc47f3-595d-11ee-80c0-000c2961d091;Київ-Берестейський 100Ж Lifecell;Київ Просп. Берестейський (к.Перемоги (Проспект)), 100Ж;5cb61671-749b-11df-b112-00215aee3ebe
72863492-c810-11ee-80c4-000c2961d091;Київ-Берестейський 100Ц ЖЖУК;Київ Просп. Берестейський (к.Перемоги (Проспект)), 100Ц;5cb61671-749b-11df-b112-00215aee3ebe
411df721-d9d9-11ec-80e7-000c29800ae7;Київ-Берестейський 108/1 Rozetka;Київ Просп. Берестейський (к.Перемоги (Проспект)), 108/1;5cb61671-749b-11df-b112-00215aee3ebe
88aeb9bb-e275-11e8-80d7-1c98ec135261;Київ-Берестейський 123 Канцтовари;Київ Просп. Берестейський (к.Перемоги (Проспект)), 123;5cb61671-749b-11df-b112-00215aee3ebe
0f1b7485-595a-11ee-80c0-000c2961d091;Київ-Берестейський 134/1 Lifecell;Київ Просп. Берестейський (к.Перемоги (Проспект)), 134/1;5cb61671-749b-11df-b112-00215aee3ebe
785940be-6f61-11ee-80c1-000c2961d091;Київ-Берестейський 136 One Price;Київ Просп. Берестейський (к.Перемоги (Проспект)), 136;5cb61671-749b-11df-b112-00215aee3ebe
0710c518-d9d5-11ec-80e7-000c29800ae7;Київ-Берестейський 136 Rozetka;Київ Просп. Берестейський (к.Перемоги (Проспект)), 136;5cb61671-749b-11df-b112-00215aee3ebe
cf8fb214-740a-11ee-80c1-000c2961d091;Київ-Берестейський 18 зооЛап-Усик;Київ Просп. Берестейський (к.Перемоги (Проспект)), 18;5cb61671-749b-11df-b112-00215aee3ebe
e8b30bb8-6f5b-11ee-80c1-000c2961d091;Київ-Берестейський 24 One Price;Київ Просп. Берестейський (к.Перемоги (Проспект)), 24;5cb61671-749b-11df-b112-00215aee3ebe
2fc85235-249c-11ee-80bd-000c2961d091;Київ-Берестейський 24,26 Сільпо;Київ Просп. Берестейський (к.Перемоги (Проспект)), 24,26;5cb61671-749b-11df-b112-00215aee3ebe
1037723f-6f64-11ee-80c1-000c2961d091;Київ-Берестейський 31 One Price;Київ Просп. Берестейський (к.Перемоги (Проспект)), 31;5cb61671-749b-11df-b112-00215aee3ebe
a13f37fa-6735-11e9-80df-1c98ec135261;Київ-Берестейський 31 Поліграфія;Київ Просп. Берестейський (к.Перемоги (Проспект)), 31;5cb61671-749b-11df-b112-00215aee3ebe
8f976a1d-249c-11ee-80bd-000c2961d091;Київ-Берестейський 47 Сільпо;Київ Просп. Берестейський (к.Перемоги (Проспект)), 47;5cb61671-749b-11df-b112-00215aee3ebe
91cc4d6b-09d3-11ed-80e7-000c29800ae7;Київ-Берестейський 65Б Rozetka;Київ Просп. Берестейський (к.Перемоги (Проспект)), 65Б;5cb61671-749b-11df-b112-00215aee3ebe
98c43a02-249a-11ee-80bd-000c2961d091;Київ-Берестейський 87 Сільпо;Київ Просп. Берестейський (к.Перемоги (Проспект)), 87;5cb61671-749b-11df-b112-00215aee3ebe
e81e3d96-249c-11ee-80bd-000c2961d091;Київ-Берестейський 94/1 Сільпо;Київ Просп. Берестейський (к.Перемоги (Проспект)), 94/1;5cb61671-749b-11df-b112-00215aee3ebe
4aaf3f0e-249d-11ee-80bd-000c2961d091;Київ-Берковецька 6 Сільпо;Київ вул. Берковецька, 6;5cb61671-749b-11df-b112-00215aee3ebe
fcc5e312-40cc-11ee-80c0-000c2961d091;Київ-Берлінського Максима 8 Продукти;Київ вул. Берлінського Максима, 8;5cb61671-749b-11df-b112-00215aee3ebe
e261a365-9b5e-11ee-80c1-000c2961d091;Київ-Бесарабська 2 Lifecell;Київ пл. Бесарабська, 2;5cb61671-749b-11df-b112-00215aee3ebe
a63a7ccb-6c0c-11ee-80c1-000c2961d091;Київ-Білоруська 10/18А Сервісний Центр;Київ вул. Білоруська, 10/18А;5cb61671-749b-11df-b112-00215aee3ebe
c903a1cf-249d-11ee-80bd-000c2961d091;Київ-Білоруська 2 Сільпо;Київ вул. Білоруська, 2;5cb61671-749b-11df-b112-00215aee3ebe
4e75f95e-5958-11ee-80c0-000c2961d091;Київ-Білоруська 2А Lifecell;Київ вул. Білоруська, 2А;5cb61671-749b-11df-b112-00215aee3ebe
72921da2-464a-11e9-80dd-1c98ec135261;Київ-Богатирська 1/20 (ОККО 1 поверх);Київ вул. Богатирська, 1/20;5cb61671-749b-11df-b112-00215aee3ebe
a67df147-bd5f-11eb-80d4-000c29800ae7;Київ-Богатирська 1Г KLO;Київ вул. Богатирська, 1Г;5cb61671-749b-11df-b112-00215aee3ebe
1123d2f7-e618-11eb-80d5-000c29800ae7;Київ-Богатирська 2 KLO;Київ вул. Богатирська, 2;5cb61671-749b-11df-b112-00215aee3ebe
38d51b03-2309-11e9-80dc-1c98ec135261;Київ-Богатирська 2 Е ОККО;Київ вул. Богатирська, 2Е;5cb61671-749b-11df-b112-00215aee3ebe
ae45eba2-2312-11e9-80dc-1c98ec135261;Київ-Богатирська 32 А;Київ вул. Богатирська, 32А;5cb61671-749b-11df-b112-00215aee3ebe
5aad46ca-40d4-11ee-80c0-000c2961d091;Київ-Богдана Гаврилишина 18 Продукти;Київ вул. Богдана Гаврилишина (Василевської Ванди, 18;5cb61671-749b-11df-b112-00215aee3ebe
493255ef-5744-11eb-80d0-000c29800ae7;Київ-Богданівська 7А Фіто Аптека Мирослав;Київ вул. Богданівська, 7А;5cb61671-749b-11df-b112-00215aee3ebe
25c5c1cd-8fef-11ec-80e4-000c29800ae7;Київ-Богданівська 7В Біле Сухе;Київ вул. Богданівська, 7В;5cb61671-749b-11df-b112-00215aee3ebe
b005a39f-40c9-11ee-80c0-000c2961d091;Київ-Борщагівська 117 Продукти;Київ вул. Борщагівська, 117;5cb61671-749b-11df-b112-00215aee3ebe
7c531d50-6736-11e9-80df-1c98ec135261;Київ-Борщагівська 128 Олівець;Київ вул. Борщагівська, 128;5cb61671-749b-11df-b112-00215aee3ebe
47f5a805-249e-11ee-80bd-000c2961d091;Київ-Борщагівська 154А Сільпо;Київ вул. Борщагівська, 154А;5cb61671-749b-11df-b112-00215aee3ebe
5381d71b-18a9-11ed-80e7-000c29800ae7;Київ-Борщагівська 206 Rozetka;Київ вул. Борщагівська, 206;5cb61671-749b-11df-b112-00215aee3ebe
31c2b24e-7c36-11eb-80d2-000c29800ae7;Київ-Братиславська 14Б Мастерица;Київ вул. Братиславська, 14Б;5cb61671-749b-11df-b112-00215aee3ebe
c4c30b52-249e-11ee-80bd-000c2961d091;Київ-Братиславська 14Б Сільпо;Київ вул. Братиславська, 14Б;5cb61671-749b-11df-b112-00215aee3ebe
2a9f031e-6f65-11ee-80c1-000c2961d091;Київ-Братства тарасівців 2 One Price;Київ вул. Братства тарасівців (кол.Декабристів), 2;5cb61671-749b-11df-b112-00215aee3ebe
4ff5bf5a-ab1c-11ee-80c4-000c2961d091;Київ-Братства тарасівців 2А Vape Shop;Київ вул. Братства тарасівців, 2А;5cb61671-749b-11df-b112-00215aee3ebe
a443a5ea-5878-11e9-80df-1c98ec135261;Київ-Братства тарасівців 2А/2 QWERTY;Київ вул. Братства тарасівців (кол.Декабристів), 2А/2;5cb61671-749b-11df-b112-00215aee3ebe
870494b5-cf71-11eb-80d5-000c29800ae7;Київ-Братства тарасівців 6 Кристальна Вода;Київ вул. Братства тарасівців (кол.Декабристів), 6;5cb61671-749b-11df-b112-00215aee3ebe
3cb5af24-dd1b-11ec-80e7-000c29800ae7;Київ-Братства тарасівців 9Е Rozetka;Київ вул. Братства тарасівців (кол.Декабристів), 9Е;5cb61671-749b-11df-b112-00215aee3ebe
af2a3f0a-836c-11e9-80e0-1c98ec135261;Київ-Броварський 20-З ОККО;Київ Просп. Броварський, 20-З;5cb61671-749b-11df-b112-00215aee3ebe
816cc1dd-16e5-11ec-80df-000c29800ae7;Київ-Будівельників 4 Біле Сухе;Київ вул. Будівельників, 4;5cb61671-749b-11df-b112-00215aee3ebe
a7a7b186-2dd7-11ed-80e7-000c29800ae7;Київ-Булаховського Академіка 3а HiMobile;Київ вул. Булаховського Академіка, 3а;5cb61671-749b-11df-b112-00215aee3ebe
5bf90449-8e94-11ed-80ec-000c29800ae7;Київ-Булаховського Академіка 3А Rozetka;Київ вул. Булаховського Академіка, 3А;5cb61671-749b-11df-b112-00215aee3ebe
72e2ebff-813b-11e9-80e0-1c98ec135261;Київ-Булаховського Академіка 5Б Ломбард;Київ вул. Булаховського Академіка, 5Б;5cb61671-749b-11df-b112-00215aee3ebe
f7b1c1e5-43ae-11ec-80e3-000c29800ae7;Київ-Булгакова 15А Біле Сухе;Київ вул. Вахтанга Кікабідзе (Булгакова), 15А;5cb61671-749b-11df-b112-00215aee3ebe
054ff847-40c9-11ee-80c0-000c2961d091;Київ-Бурмистенка 8/9 Продукти;Київ вул. Оріхуватська ( Бурмістенка ), 8/9;5cb61671-749b-11df-b112-00215aee3ebe
1732a70b-d310-11ea-80ca-000c29800ae7;Київ-Вантажний;Київ(Софіївська Борщагівка) вул. Мала Кільцева, 10/1;80f5570b-749b-11df-b112-00215aee3ebe
3545b4d8-2560-11ee-80bd-000c2961d091;Київ-Василя Липківського 1А Сільпо;Київ вул. Василя Липківського, 1А;5cb61671-749b-11df-b112-00215aee3ebe
893fca60-403e-11ee-80c0-000c2961d091;Київ-Василя Липківського 40 Продукти;Київ вул. Василя Липківського, 40;5cb61671-749b-11df-b112-00215aee3ebe
7bfc7a1a-db70-11ec-80e7-000c29800ae7;Київ-Васильківська 2 Rozetka;Київ вул. Васильківська (Голосієво), 2;5cb61671-749b-11df-b112-00215aee3ebe
f385b5de-b45a-11e9-80e4-1c98ec135261;Київ-Васильківська 30/1 ARDUINO UA;Київ вул. Васильківська (Голосієво), 30/1;5cb61671-749b-11df-b112-00215aee3ebe
59d7911a-6f64-11ee-80c1-000c2961d091;Київ-Васильківська 34 One Price;Київ вул. Васильківська (Голосієво), 34;5cb61671-749b-11df-b112-00215aee3ebe
316a6e52-b688-11ed-80ec-000c29800ae7;Київ-Васильківська 8 Lifecell;Київ вул. Васильківська (Голосієво), 8;5cb61671-749b-11df-b112-00215aee3ebe
f03803a2-dc10-11ec-80e7-000c29800ae7;Київ-Велика Житомирська 40 Rozetka;Київ вул. Велика Житомирська, 40;5cb61671-749b-11df-b112-00215aee3ebe
4b04c293-4649-11e9-80dd-1c98ec135261;Київ-Велика Кільцева 10 ОККО;Київ вул. Велика Кільцева, 10;5cb61671-749b-11df-b112-00215aee3ebe
21da0ecf-2498-11ee-80bd-000c2961d091;Київ-Вербицького Архітектора 1 Сільпо;Київ вул. Вербицького Архітектора, 1;5cb61671-749b-11df-b112-00215aee3ebe
07e7bd10-7ef0-11ee-80c1-000c2961d091;Київ-Вербицького Архітектора 10 Кавярня;Київ вул. Вербицького Архітектора, 10;5cb61671-749b-11df-b112-00215aee3ebe
4d80e8c1-d180-11ee-80c4-000c2961d091;Київ-Вербицького Архітектора 11;Київ вул. Вербицького Архітектора, 11;5cb61671-749b-11df-b112-00215aee3ebe
54da4957-6626-11eb-80d1-000c29800ae7;Київ-Вербицького Архітектора 15 Lifecell;Київ вул. Вербицького Архітектора, 15;5cb61671-749b-11df-b112-00215aee3ebe
1f68ed92-6633-11eb-80d1-000c29800ae7;Київ-Вербицького Архітектора 18 Lifecell;Київ вул. Вербицького Архітектора, 18;5cb61671-749b-11df-b112-00215aee3ebe
2d43130f-c892-11ed-80ed-000c29800ae7;Київ-Вербицького Архітектора 30 Сільпо;Київ вул. Вербицького Архітектора, 30;5cb61671-749b-11df-b112-00215aee3ebe
b8706b7e-7ed1-11ee-80c1-000c2961d091;Київ-Вербицького, 1 СПЕЦ;Київ вул. Вербицького Архітектора, 1;5cb61671-749b-11df-b112-00215aee3ebe
85bc3e15-d340-11eb-80d5-000c29800ae7;Київ-Вереснева 26/28 Біле Сухе;Київ вул. Вереснева, 26/28;5cb61671-749b-11df-b112-00215aee3ebe
089037db-5e05-11ee-80c0-000c2961d091;Київ-Вернадського Академіка 16 JustTBeer;Київ вул. Вернадського Академіка, 16;5cb61671-749b-11df-b112-00215aee3ebe
3c796d7c-d049-11ec-80e7-000c29800ae7;Київ-Верхній Вал 28/12 Rozetka;Київ вул. Верхній Вал, 28/12;5cb61671-749b-11df-b112-00215aee3ebe
f585f25d-249f-11ee-80bd-000c2961d091;Київ-Вершигори Петра 1 Сільпо;Київ вул. Вершигори Петра, 1;5cb61671-749b-11df-b112-00215aee3ebe
c5be336b-24ca-11ee-80bd-000c2961d091;Київ-Вишгородська 21 Сільпо;Київ вул. Вишгородська, 21;5cb61671-749b-11df-b112-00215aee3ebe
9d9f5692-bce3-11ed-80ed-000c29800ae7;Київ-Вишгородська 46 Rozetka;Київ вул. Вишгородська, 46;5cb61671-749b-11df-b112-00215aee3ebe
b7673472-d799-11ee-80c4-000c2961d091;Київ-Вишгородська 46 Біле Сухе;Київ вул. Вишгородська, 46;5cb61671-749b-11df-b112-00215aee3ebe
fa1d4241-7488-11df-b112-00215aee3ebe;Київ-Відрадний;Київ просп. Відрадний,107а;5cb61671-749b-11df-b112-00215aee3ebe
3c643d69-5c2f-11ed-80eb-000c29800ae7;Київ-Відрадний (рг);Київ Просп. Відрадний, 107а;5cb61671-749b-11df-b112-00215aee3ebe
8e71ec34-c9de-11eb-80d5-000c29800ae7;Київ-Відрадний 18 Ремонт ноутбуків телефонів;Київ Просп. Відрадний, 18;5cb61671-749b-11df-b112-00215aee3ebe
bbcfb0cf-40c1-11ee-80c0-000c2961d091;Київ-Відрадний 40 Продукти;Київ Просп. Відрадний, 40;5cb61671-749b-11df-b112-00215aee3ebe
b129689e-f0bd-11ed-80ed-000c29800ae7;Київ-Відрадний 6/1 Rozetka;Київ Просп. Відрадний, 6/1;5cb61671-749b-11df-b112-00215aee3ebe
1837e3d9-e608-11ee-80c5-000c2961d091;Київ-Вінграновського 2 КупіDон;Київ вул. Вінграновського, 2;5cb61671-749b-11df-b112-00215aee3ebe
542b2807-b5de-11ee-80c4-000c2961d091;Київ-Вірменська 29 Хуторок;Київ вул. Вірменська, 29;5cb61671-749b-11df-b112-00215aee3ebe
3d70b197-9172-11ee-80c1-000c2961d091;Київ-Волинська 10 Кавярня;Київ вул. Волинська, 10;5cb61671-749b-11df-b112-00215aee3ebe
5d204170-1ba9-11ec-80df-000c29800ae7;Київ-Волинська 16 Делві;Київ вул. Волинська, 16;5cb61671-749b-11df-b112-00215aee3ebe
1b2df2ca-758e-11ee-80c1-000c2961d091;Київ-Володимира Івасюка 12П Сільпо;Київ Просп. Володимира Івасюка / Героїв Сталінграда, 12П;5cb61671-749b-11df-b112-00215aee3ebe
e2bf839d-24cb-11ee-80bd-000c2961d091;Київ-Володимира Івасюка 27Б Сільпо;Київ Просп. Володимира Івасюка / Героїв Сталінграда, 27Б;5cb61671-749b-11df-b112-00215aee3ebe
4e54f8aa-24cb-11ee-80bd-000c2961d091;Київ-Володимира Івасюка 46 Сільпо;Київ Просп. Володимира Івасюка / Героїв Сталінграда, 46;5cb61671-749b-11df-b112-00215aee3ebe
c2b9d222-40ce-11ee-80c0-000c2961d091;Київ-Володимира Івасюка 61Б Продукти;Київ Просп. Володимира Івасюка / Героїв Сталінграда, 61Б;5cb61671-749b-11df-b112-00215aee3ebe
bb95db6c-29fd-11ee-80bd-000c2961d091;Київ-Володимира Івасюка 65Б Біле Сухе;Київ Просп. Володимира Івасюка / Героїв Сталінграда, 65Б;5cb61671-749b-11df-b112-00215aee3ebe
68d87878-d9d4-11ec-80e7-000c29800ae7;Київ-Володимира Івасюка 8 Rozetka;Київ Просп. Володимира Івасюка / Героїв Сталінграда, 8;5cb61671-749b-11df-b112-00215aee3ebe
b00cbb0b-c890-11ed-80ed-000c29800ae7;Київ-Володимира Івасюка 8А Сільпо;Київ Просп. Володимира Івасюка / Героїв Сталінграда, 8А;5cb61671-749b-11df-b112-00215aee3ebe
685325e7-e775-11ee-80c5-000c2961d091;Київ-Володимиро-Либідська 22 Барбершоп;Київ вул. Володимиро-Либідська, 22;5cb61671-749b-11df-b112-00215aee3ebe
bed8a648-c7db-11ed-80ed-000c29800ae7;Київ-Воскресенська 12В Rozetka;Київ вул. Воскресенська, 12В;5cb61671-749b-11df-b112-00215aee3ebe
113219da-4040-11ee-80c0-000c2961d091;Київ-Воскресенська 18 GREEN;Київ вул. Воскресенська, 18;5cb61671-749b-11df-b112-00215aee3ebe
e4fbbafc-971c-11e8-80d4-1c98ec135261;Київ-Воскресенський 20/2 Фото Ксерокс Канцтовари;Київ бул. Воскресенський ( Перова ), 20/2;5cb61671-749b-11df-b112-00215aee3ebe
380c1c4f-dab2-11ed-80ed-000c29800ae7;Київ-Воскресенський 24 СПЕЦ;Київ бул. Воскресенський ( Перова ), 24;5cb61671-749b-11df-b112-00215aee3ebe
f7c75375-daac-11ec-80e7-000c29800ae7;Київ-Воскресенський 32 Rozetka;Київ бул. Воскресенський ( Перова ), 32;5cb61671-749b-11df-b112-00215aee3ebe
62417584-595b-11ee-80c0-000c2961d091;Київ-Воскресенський 36 Lifecell;Київ бул. Воскресенський ( Перова ), 36;5cb61671-749b-11df-b112-00215aee3ebe
4013109f-24ae-11ee-80bd-000c2961d091;Київ-Воскресенський 36 Сільпо;Київ бул. Воскресенський ( Перова ), 36;5cb61671-749b-11df-b112-00215aee3ebe
45251817-b54f-11ee-80c4-000c2961d091;Київ-Воскресенський 44 Пивна Лавка;Київ бул. Воскресенський ( Перова ), 44;5cb61671-749b-11df-b112-00215aee3ebe
54353778-4026-11ee-80c0-000c2961d091;Київ-Гавела Вацлава  28 GREEN;Київ бул. Гавела Вацлава  (Лепсе Івана), 28;5cb61671-749b-11df-b112-00215aee3ebe
5d8b4a1a-f0be-11ed-80ed-000c29800ae7;Київ-Гавела Вацлава  50 Rozetka;Київ бул. Гавела Вацлава  (Лепсе Івана), 50;5cb61671-749b-11df-b112-00215aee3ebe
fc8ba686-0695-11ee-80bd-000c2961d091;Київ-Гавела Вацлава  50 СПЕЦ;Київ бул. Гавела Вацлава  (Лепсе Івана), 50;5cb61671-749b-11df-b112-00215aee3ebe
5e992c16-fade-11ed-80ed-000c29800ae7;Київ-Гавела Вацлава  51/16 Біле Сухе;Київ бул. Гавела Вацлава  (Лепсе Івана), 51/16;5cb61671-749b-11df-b112-00215aee3ebe
d05da2a7-8907-11e9-80e0-1c98ec135261;Київ-Гавела Вацлава  53 Сервісний Центр;Київ бул. Гавела Вацлава  (Лепсе Івана), 53;5cb61671-749b-11df-b112-00215aee3ebe
66a75feb-29fd-11ee-80bd-000c2961d091;Київ-Гавела Вацлава  79 Біле Сухе;Київ бул. Гавела Вацлава  (Лепсе Івана), 79;5cb61671-749b-11df-b112-00215aee3ebe
afd8db4b-4fb3-11ed-80eb-000c29800ae7;Київ-Гавела Вацлава 8 Rozetka;Київ бул. Гавела Вацлава  (Лепсе Івана), 8;5cb61671-749b-11df-b112-00215aee3ebe
18074add-2a02-11ee-80bd-000c2961d091;Київ-Гарматна 20 Біле Сухе;Київ вул. Гарматна, 20;5cb61671-749b-11df-b112-00215aee3ebe
e3bafec9-16e3-11ec-80df-000c29800ae7;Київ-Героїв Дніпра 37Б Біле Сухе;Київ вул. Героїв Дніпра, 37Б;5cb61671-749b-11df-b112-00215aee3ebe
fc8691f4-d798-11ee-80c4-000c2961d091;Київ-Героїв Дніпра 43А Біле Сухе;Київ вул. Героїв Дніпра, 43А;5cb61671-749b-11df-b112-00215aee3ebe
bc0d744a-c59a-11ee-80c4-000c2961d091;Київ-Героїв Полку Азов 12 F.ua;Київ вул. Героїв Полку Азов (Малиновського Маршала, 12;5cb61671-749b-11df-b112-00215aee3ebe
de888083-2b90-11ee-80bd-000c2961d091;Київ-Героїв Полку Азов 12 Rozetka;Київ вул. Героїв Полку Азов (Малиновського Маршала, 12;5cb61671-749b-11df-b112-00215aee3ebe
af95d98a-24cc-11ee-80bd-000c2961d091;Київ-Героїв Полку Азов 34 Сільпо;Київ вул. Героїв Полку Азов (Малиновського Маршала, 34;5cb61671-749b-11df-b112-00215aee3ebe
c3ff652d-d9d6-11ec-80e7-000c29800ae7;Київ-Героїв Полку Азов 4В Rozetka;Київ вул. Героїв Полку Азов (Малиновського Маршала, 4В;5cb61671-749b-11df-b112-00215aee3ebe
63d61da4-24cc-11ee-80bd-000c2961d091;Київ-Героїв Полку Азов 5 Сільпо;Київ вул. Героїв Полку Азов (Малиновського Маршала, 5;5cb61671-749b-11df-b112-00215aee3ebe
46df2da3-6829-11e9-80df-1c98ec135261;Київ-Героїв Полку Азов 5Б IT Master;Київ вул. Героїв Полку Азов (Малиновського Маршала, 5Б;5cb61671-749b-11df-b112-00215aee3ebe
1cf3eae8-40cc-11ee-80c0-000c2961d091;Київ-Героїв Севастополя 20/1 Green Market;Київ вул. Героїв Севастополя, 20/1;5cb61671-749b-11df-b112-00215aee3ebe
9e842ff8-0e95-11ee-80bd-000c2961d091;Київ-Героїв Севастополя 24/2 Біле Сухе;Київ вул. Героїв Севастополя, 24/2;5cb61671-749b-11df-b112-00215aee3ebe
93a2d8bd-0e98-11ee-80bd-000c2961d091;Київ-Героїв Севастополя 35А Біле Сухе;Київ вул. Героїв Севастополя, 35А;5cb61671-749b-11df-b112-00215aee3ebe
c1a9d0a7-1ba8-11ec-80df-000c29800ae7;Київ-Героїв Севастополя 44/10 Делві;Київ вул. Героїв Севастополя, 44/10;5cb61671-749b-11df-b112-00215aee3ebe
14bbf722-5812-11ec-80e4-000c29800ae7;Київ-Гетьмана Вадима 27Д АЗС KLO;Київ вул. Гетьмана Вадима, 27Д;5cb61671-749b-11df-b112-00215aee3ebe
0d030028-41b5-11ee-80c0-000c2961d091;Київ-Гетьмана Вадима 40 Lifecell;Київ вул. Гетьмана Вадима, 40;5cb61671-749b-11df-b112-00215aee3ebe
fa092df1-3a6f-11ed-80e9-000c29800ae7;Київ-Гетьмана Павла Полуботка 18 Rozetka;Київ вул. Гетьмана Павла Полуботка (к.Попудренка), 18;5cb61671-749b-11df-b112-00215aee3ebe
b760bfa4-d4a7-11ee-80c4-000c2961d091;Київ-Гетьмана Павла Полуботка 50Б Vape Shop;Київ вул. Гетьмана Павла Полуботка (к.Попудренка), 50Б;5cb61671-749b-11df-b112-00215aee3ebe
481c2409-6f63-11ee-80c1-000c2961d091;Київ-Гетьмана Павла Полуботка 9 One Price;Київ вул. Гетьмана Павла Полуботка (к.Попудренка), 9;5cb61671-749b-11df-b112-00215aee3ebe
187a1dcf-24cd-11ee-80bd-000c2961d091;Київ-Глибочицька 32Б Сільпо;Київ вул. Глибочицька, 32Б;5cb61671-749b-11df-b112-00215aee3ebe
5cb7f7b4-48de-11ea-80c6-000c29800ae7;Київ-Глибочицький 1 ОККО;Київ проїзд Глибочицький, 1;5cb61671-749b-11df-b112-00215aee3ebe
0d71ca14-e615-11eb-80d5-000c29800ae7;Київ-Глушкова Академіка 10 АЗС KLO;Київ Просп. Глушкова Академіка, 10;5cb61671-749b-11df-b112-00215aee3ebe
7e84426a-9b5f-11ee-80c1-000c2961d091;Київ-Глушкова Академіка 13Б Lifecell;Київ Просп. Глушкова Академіка, 13Б;5cb61671-749b-11df-b112-00215aee3ebe
fafe8119-ab16-11ee-80c4-000c2961d091;Київ-Глушкова Академіка 31 Vape Shop;Київ Просп. Глушкова Академіка, 31;5cb61671-749b-11df-b112-00215aee3ebe
5e831fb5-5811-11ec-80e4-000c29800ae7;Київ-Глушкова Академіка 9А АЗС KLO;Київ Просп. Глушкова Академіка, 9А;5cb61671-749b-11df-b112-00215aee3ebe
ff04795b-cedf-11ed-80ed-000c29800ae7;Київ-Гмирі Бориса 16А Кристальна Вода;Київ вул. Гмирі Бориса, 16А;5cb61671-749b-11df-b112-00215aee3ebe
7687498c-ab16-11ee-80c4-000c2961d091;Київ-Гмирі Бориса 5А Vape Shop;Київ вул. Гмирі Бориса, 5А;5cb61671-749b-11df-b112-00215aee3ebe
0dde523d-db3c-11ec-80e7-000c29800ae7;Київ-Гната Хоткевича 1А Rozetka;Київ вул. Гната Хоткевича, 1А;5cb61671-749b-11df-b112-00215aee3ebe
c059e09c-595a-11ee-80c0-000c2961d091;Київ-Гната Юри 20 Lifecell;Київ вул. Гната Юри, 20;5cb61671-749b-11df-b112-00215aee3ebe
d8ef67f3-8e93-11ed-80ec-000c29800ae7;Київ-Гната Юри 20 Rozetka;Київ вул. Гната Юри, 20;5cb61671-749b-11df-b112-00215aee3ebe
68b6d119-24cd-11ee-80bd-000c2961d091;Київ-Гната Юри 20 Сільпо;Київ вул. Гната Юри, 20;5cb61671-749b-11df-b112-00215aee3ebe
4086a616-1bac-11ec-80df-000c29800ae7;Київ-Гоголівська 27 Делві;Київ вул. Гоголівська, 27;5cb61671-749b-11df-b112-00215aee3ebe
33abe668-cf4f-11eb-80d5-000c29800ae7;Київ-Голосіївський 106 Кавярня;Київ Просп. Голосіївський, 106;5cb61671-749b-11df-b112-00215aee3ebe
8cbbee3f-0c53-11eb-80cd-000c29800ae7;Київ-Голосіївський 116 Продукти;Київ Просп. Голосіївський, 116;5cb61671-749b-11df-b112-00215aee3ebe
bf591fd5-afd1-11ea-80c8-000c29800ae7;Київ-Голосіївський 13 зооNavita;Київ Просп. Голосіївський, 13;5cb61671-749b-11df-b112-00215aee3ebe
5a54ffe4-6cc8-11ee-80c1-000c2961d091;Київ-Голосіївський 132 Rozetka;Київ Просп. Голосіївський, 132;5cb61671-749b-11df-b112-00215aee3ebe
2d6a9bd4-29fe-11ee-80bd-000c2961d091;Київ-Голосіївський 17А Біле Сухе;Київ Просп. Голосіївський, 17А;5cb61671-749b-11df-b112-00215aee3ebe
deb42d57-14ce-11ee-80bd-000c2961d091;Київ-Голосіївський 70 СПЕЦ;Київ Просп. Голосіївський, 70;5cb61671-749b-11df-b112-00215aee3ebe
779707e6-ab17-11ee-80c4-000c2961d091;Київ-Голосіївський 8Г Vape Shop;Київ Просп. Голосіївський, 8Г;5cb61671-749b-11df-b112-00215aee3ebe
b914b0e6-ab1e-11ee-80c4-000c2961d091;Київ-Гонгадзе 2 Vape Shop;Київ Просп. Гонгадзе, 2;5cb61671-749b-11df-b112-00215aee3ebe
ac94e6e3-ebb9-11eb-80d5-000c29800ae7;Київ-Гонгадзе 20 Пан Ветеринар;Київ Просп. Гонгадзе, 20;5cb61671-749b-11df-b112-00215aee3ebe
c3f4c31e-24cd-11ee-80bd-000c2961d091;Київ-Гончара Олеся 96 Сільпо;Київ вул. Гончара Олеся, 96;5cb61671-749b-11df-b112-00215aee3ebe
0505f2c7-d2e1-11ed-80ed-000c29800ae7;Київ-Градинська 3 Rozetka;Київ вул. Градинська, 3;5cb61671-749b-11df-b112-00215aee3ebe
aa6b2768-29ff-11ee-80bd-000c2961d091;Київ-Градинська 9 Біле Сухе;Київ вул. Градинська, 9;5cb61671-749b-11df-b112-00215aee3ebe
2a8887e8-15b7-11ee-80bd-000c2961d091;Київ-Григоренка Петра 20 Будмаркет;Київ Просп. Григоренка Петра, 20;5cb61671-749b-11df-b112-00215aee3ebe
0f0a6c7a-24cf-11ee-80bd-000c2961d091;Київ-Григоренка Петра 23 Сільпо;Київ Просп. Григоренка Петра, 23;5cb61671-749b-11df-b112-00215aee3ebe
dbe1a08c-b741-11ed-80ec-000c29800ae7;Київ-Григоренка Петра 28 Канцтовари;Київ Просп. Григоренка Петра, 28;5cb61671-749b-11df-b112-00215aee3ebe
8d50db4c-a97c-11ee-80c4-000c2961d091;Київ-Григоренка Петра 28 Лап-Усик;Київ Просп. Григоренка Петра, 28;5cb61671-749b-11df-b112-00215aee3ebe
27786124-e77a-11ee-80c5-000c2961d091;Київ-Григоренка Петра 32Д Пивна Лавка;Київ Просп. Григоренка Петра, 32Д;5cb61671-749b-11df-b112-00215aee3ebe
6e04e67f-beb6-11ee-80c4-000c2961d091;Київ-Григоренка Петра 36А I.VA. Кава від друзів;Київ Просп. Григоренка Петра, 36А;5cb61671-749b-11df-b112-00215aee3ebe
be935a95-d846-11ec-80e7-000c29800ae7;Київ-Гришка Михайла 3А Rozetka;Київ вул. Гришка Михайла, 3А;5cb61671-749b-11df-b112-00215aee3ebe
c00b34d6-782b-11eb-80d1-000c29800ae7;Київ-Гришка Михайла 8 Кавярня;Київ вул. Гришка Михайла, 8;5cb61671-749b-11df-b112-00215aee3ebe
e8cb80a1-ab19-11ee-80c4-000c2961d091;Київ-Данила Щербаківського 45 Vape Shop;Київ вул. Данила Щербаківського, 45;5cb61671-749b-11df-b112-00215aee3ebe
36c36f96-d9da-11ec-80e7-000c29800ae7;Київ-Данила Щербаківського 45А Rozetka;Київ вул. Данила Щербаківського, 45А;5cb61671-749b-11df-b112-00215aee3ebe
3c1b2b93-6452-11ee-80c0-000c2961d091;Київ-Данила Щербаківського 72/7 QWAY;Київ вул. Данила Щербаківського, 72/7;5cb61671-749b-11df-b112-00215aee3ebe
84edc540-b9e8-11ee-80c4-000c2961d091;Київ-Данченка Сергія 28А Peachy Pet Care;Київ вул. Данченка Сергія, 28А;5cb61671-749b-11df-b112-00215aee3ebe
1a4654c9-9a8e-11ee-80c1-000c2961d091;Київ-Данченка Сергія 3 Культура пива;Київ вул. Данченка Сергія, 3;5cb61671-749b-11df-b112-00215aee3ebe
898b06c8-bce0-11ed-80ed-000c29800ae7;Київ-Данченка Сергія 4 Rozetka;Київ вул. Данченка Сергія, 4;5cb61671-749b-11df-b112-00215aee3ebe
18888541-babf-11ee-80c4-000c2961d091;Київ-Данченка Сергія 5 корп.2 ЛюдОк;Київ вул. Данченка Сергія, 5;5cb61671-749b-11df-b112-00215aee3ebe
bfc61ddd-e835-11ee-80c5-000c2961d091;Київ-Дарницький 5 Кавярня;Київ бул. Дарницький, 5;5cb61671-749b-11df-b112-00215aee3ebe
3b6c9111-24d0-11ee-80bd-000c2961d091;Київ-Дарницький 8А Сільпо;Київ бул. Дарницький, 8А;5cb61671-749b-11df-b112-00215aee3ebe
0a483d0f-5c4b-11ee-80c0-000c2961d091;Київ-Дегтярівська 17 Rozetka;Київ вул. Дегтярівська, 17;5cb61671-749b-11df-b112-00215aee3ebe
f882b445-4030-11ee-80c0-000c2961d091;Київ-Дегтярівська 47 Продукти;Київ вул. Дегтярівська, 47;5cb61671-749b-11df-b112-00215aee3ebe
b7e74b6d-e4b6-11ed-80ed-000c29800ae7;Київ-Дегтярівська 53А Rozetka;Київ вул. Дегтярівська, 53А;5cb61671-749b-11df-b112-00215aee3ebe
8a884089-ab1d-11ee-80c4-000c2961d091;Київ-Дегтярівська 62 Vape Shop;Київ вул. Дегтярівська, 62;5cb61671-749b-11df-b112-00215aee3ebe
5a4a4f7e-402b-11ee-80c0-000c2961d091;Київ-Депутатська 23В Продукти;Київ вул. Депутатська, 23В;5cb61671-749b-11df-b112-00215aee3ebe
172b635b-b9dc-11e9-80e4-1c98ec135261;Київ-Деревлянська 12/42 Канцтовари;Київ вул. Деревлянська ( Якіра ), 12/42;5cb61671-749b-11df-b112-00215aee3ebe
f68da572-50a1-11e9-80dd-1c98ec135261;Київ-Дмитра Багалія 2 Good Toys;Київ вул. Дмитра Багалія (Пожарського), 2;5cb61671-749b-11df-b112-00215aee3ebe
4d06ed8a-40d5-11ee-80c0-000c2961d091;Київ-Дмитра Багалія 2 Продукти;Київ вул. Дмитра Багалія (Пожарського), 2;5cb61671-749b-11df-b112-00215aee3ebe
be7a0a04-24d0-11ee-80bd-000c2961d091;Київ-Дніпровська набережна 12 Сільпо;Київ вул. Дніпровська набережна, 12;5cb61671-749b-11df-b112-00215aee3ebe
12c01be0-16fa-11ec-80df-000c29800ae7;Київ-Дніпровська набережна 13Біле Сухе;Київ вул. Дніпровська набережна, 13;5cb61671-749b-11df-b112-00215aee3ebe
6989bb5e-e614-11eb-80d5-000c29800ae7;Київ-Дніпровська набережна 17 АЗС KLO;Київ вул. Дніпровська набережна, 17;5cb61671-749b-11df-b112-00215aee3ebe
34fc7f5d-fadf-11ed-80ed-000c29800ae7;Київ-Дніпровська набережна 19А Біле Сухе;Київ вул. Дніпровська набережна, 19А;5cb61671-749b-11df-b112-00215aee3ebe
a7769894-dc0c-11ec-80e7-000c29800ae7;Київ-Дніпровська набережна 33 Rozetka;Київ вул. Дніпровська набережна, 33;5cb61671-749b-11df-b112-00215aee3ebe
1d431bf9-24d1-11ee-80bd-000c2961d091;Київ-Дніпровська набережна 33А Сільпо;Київ вул. Дніпровська набережна, 33А;5cb61671-749b-11df-b112-00215aee3ebe
e0292543-82eb-11e9-80e0-1c98ec135261;Київ-Дніпровська набережна 8А ОККО;Київ вул. Дніпровська набережна, 8;5cb61671-749b-11df-b112-00215aee3ebe
9d6b371e-dabb-11ec-80e7-000c29800ae7;Київ-Довженка Олександра 1 Rozetka;Київ вул. Довженка Олександра, 1;5cb61671-749b-11df-b112-00215aee3ebe
ddebc79a-da95-11ed-80ed-000c29800ae7;Київ-Довженка Олександра 1 СПЕЦ;Київ вул. Довженка Олександра, 1;5cb61671-749b-11df-b112-00215aee3ebe
ea21e52a-6f5c-11ee-80c1-000c2961d091;Київ-Довженка Олександра 1В One Price;Київ вул. Довженка Олександра, 1В;5cb61671-749b-11df-b112-00215aee3ebe
bde765d9-cf0f-11ee-80c4-000c2961d091;Київ-Донецька 8/10;Київ вул. Донецька, 8/10;5cb61671-749b-11df-b112-00215aee3ebe
90c514c7-24d1-11ee-80bd-000c2961d091;Київ-Дорогожицька 2 Сільпо;Київ вул. Дорогожицька, 2;5cb61671-749b-11df-b112-00215aee3ebe
8c0eecfe-24d2-11ee-80bd-000c2961d091;Київ-Драгоманова Михайла 10 Сільпо;Київ вул. Драгоманова Михайла, 10;5cb61671-749b-11df-b112-00215aee3ebe
caaada73-69d1-11ee-80c1-000c2961d091;Київ-Драгоманова Михайла 11 СПЕЦ;Київ вул. Драгоманова Михайла, 11;5cb61671-749b-11df-b112-00215aee3ebe
128f9985-8fec-11ec-80e4-000c29800ae7;Київ-Драгоманова Михайла 1А Біле Сухе;Київ вул. Драгоманова Михайла, 1А;5cb61671-749b-11df-b112-00215aee3ebe
3e4bdbc0-40d1-11ee-80c0-000c2961d091;Київ-Дубровицька 12 Продукти;Київ вул. Дубровицька, 12;5cb61671-749b-11df-b112-00215aee3ebe
be14db5c-7b56-11eb-80d2-000c29800ae7;Київ-Екстер Олександри 8 Продукти ;Київ вул. Екстер Олександри ( Цвєтаєвої Марини ), 8;5cb61671-749b-11df-b112-00215aee3ebe
99836536-402e-11ee-80c0-000c2961d091;Київ-Електриків 26 Продукти;Київ вул. Електриків, 26;5cb61671-749b-11df-b112-00215aee3ebe
d8fd424e-63f5-11ec-80e4-000c29800ae7;Київ-Ернста Федора 16А Питна Вода;Київ вул. Ернста Федора, 16А;5cb61671-749b-11df-b112-00215aee3ebe
8e196dbd-40d0-11ee-80c0-000c2961d091;Київ-Жмеринська 22А Продукти;Київ вул. Жмеринська, 22А;5cb61671-749b-11df-b112-00215aee3ebe
0391d09b-8643-11eb-80d2-000c29800ae7;Київ-Забіли Віктора 4Б Продукти ;Київ вул. Забіли Віктора, 4Б;5cb61671-749b-11df-b112-00215aee3ebe
ffc34966-cf4a-11eb-80d5-000c29800ae7;Київ-Заболотного Академіка 136А Казкова Крамничка;Київ вул. Заболотного Академіка, 136А;5cb61671-749b-11df-b112-00215aee3ebe
c6a1fa95-20b5-11e9-80dc-1c98ec135261;Київ-Заболотного Академіка 158Д ОККО;Київ вул. Заболотного Академіка, 158Д;5cb61671-749b-11df-b112-00215aee3ebe
0679cd68-e614-11eb-80d5-000c29800ae7;Київ-Заболотного Академіка 15Д АЗС KLO;Київ вул. Заболотного Академіка, 15Д;5cb61671-749b-11df-b112-00215aee3ebe
f6bafd19-6cc7-11ee-80c1-000c2961d091;Київ-Заболотного Академіка 1А Rozetka;Київ вул. Заболотного Академіка, 1А;5cb61671-749b-11df-b112-00215aee3ebe
380cfe6f-20b3-11e9-80dc-1c98ec135261;Київ-Заболотного Академіка 25 ОККО;Київ вул. Заболотного Академіка, 25;5cb61671-749b-11df-b112-00215aee3ebe
0c745a88-9b5e-11ee-80c1-000c2961d091;Київ-Заболотного Академіка 37;Київ вул. Заболотного Академіка, 37;5cb61671-749b-11df-b112-00215aee3ebe
3df8fab6-d33d-11eb-80d5-000c29800ae7;Київ-Заболотного Академіка 48А Біле Сухе;Київ вул. Заболотного Академіка, 48А;5cb61671-749b-11df-b112-00215aee3ebe
a220ffe5-2586-11ee-80bd-000c2961d091;Київ-Загорівська 17-20 Сільпо;Київ вул. Загорівська ( Багговутівська ), 17-20;5cb61671-749b-11df-b112-00215aee3ebe
9d4ae53d-4030-11ee-80c0-000c2961d091;Київ-Загорівська 38 Продукти;Київ вул. Загорівська ( Багговутівська ), 38;5cb61671-749b-11df-b112-00215aee3ebe
fb1ed92d-e77a-11ee-80c5-000c2961d091;Київ-Закревського Миколи 29 Пивна Лавка;Київ вул. Закревського Миколи, 29;5cb61671-749b-11df-b112-00215aee3ebe
59324013-6e7b-11ee-80c1-000c2961d091;Київ-Закревського Миколи 61/2 Ремонт телефонів;Київ вул. Закревського Миколи, 61/2;5cb61671-749b-11df-b112-00215aee3ebe
3c94cc83-24d4-11ee-80bd-000c2961d091;Київ-Закревського Миколи 61/2 Сільпо;Київ вул. Закревського Миколи, 61/2;5cb61671-749b-11df-b112-00215aee3ebe
939eab9d-ada2-11eb-80d3-000c29800ae7;Київ-Закревського Миколи 87 Біле Сухе;Київ вул. Закревського Миколи, 87;5cb61671-749b-11df-b112-00215aee3ebe
b34b6134-e77a-11ee-80c5-000c2961d091;Київ-Закревського Миколи 87 Пивна Лавка;Київ вул. Закревського Миколи, 87;5cb61671-749b-11df-b112-00215aee3ebe
6ab3d10f-0c19-11ee-80bd-000c2961d091;Київ-Закревського Миколи 93А Rozetka;Київ вул. Закревського Миколи, 93А;5cb61671-749b-11df-b112-00215aee3ebe
50d1baa4-91a7-11e9-80e2-1c98ec135261;Київ-Закревського Миколи 95В Будмаркет;Київ вул. Закревського Миколи, 95В;5cb61671-749b-11df-b112-00215aee3ebe
b3d3e113-24d4-11ee-80bd-000c2961d091;Київ-Западинська 15А Сільпо;Київ вул. Западинська, 15А;5cb61671-749b-11df-b112-00215aee3ebe
9b8cced7-ab92-11ed-80ec-000c29800ae7;Київ-Зарічна 1В Rozetka;Київ вул. Зарічна, 1В;5cb61671-749b-11df-b112-00215aee3ebe
b71b2c3b-ab1f-11ee-80c4-000c2961d091;Київ-Зарічна 44А Vape Shop;Київ вул. Зарічна, 44А;5cb61671-749b-11df-b112-00215aee3ebe
022e83f3-1baf-11ec-80df-000c29800ae7;Київ-Заслонова Костянтина 13А Делві;Київ вул. Заслонова Костянтина, 13А;5cb61671-749b-11df-b112-00215aee3ebe
62d079cb-be20-11eb-80d5-000c29800ae7;Київ-Затишна 1 АЗС KLO;Київ вул. Затишна, 1;5cb61671-749b-11df-b112-00215aee3ebe
18a5b0d6-52a6-11e7-8101-1c98ec135263;Київ-Захід;Київ(Софіївська Борщагівка) вул. Мала Кільцева, 10/1;80f5570b-749b-11df-b112-00215aee3ebe
57652193-d4a5-11ee-80c4-000c2961d091;Київ-Здановської Юлії 48 Продуктова лавка;Київ вул. Здановської Юлії (Ломоносова), 48;5cb61671-749b-11df-b112-00215aee3ebe
0e92c292-24d5-11ee-80bd-000c2961d091;Київ-Здолбунівська 4 Сільпо;Київ вул. Здолбунівська, 4;5cb61671-749b-11df-b112-00215aee3ebe
beb25e04-2ba9-11e9-80dd-1c98ec135261;Київ-Здолбунівська 7 Копі Центр;Київ вул. Здолбунівська, 7;5cb61671-749b-11df-b112-00215aee3ebe
b7912d3c-fadf-11ed-80ed-000c29800ae7;Київ-Зодчих 52А Біле Сухе;Київ вул. Зодчих, 52А;5cb61671-749b-11df-b112-00215aee3ebe
0abdf44a-e76e-11ee-80c5-000c2961d091;Київ-Золотоворітський фойє станції метро KavaLaba;Київ проїзд Золотоворітський, 3;5cb61671-749b-11df-b112-00215aee3ebe
8038a0db-dc12-11ec-80e7-000c29800ae7;Київ-Золотоустівська 2/4 Rozetka;Київ вул. Золотоустівська, 2/4;5cb61671-749b-11df-b112-00215aee3ebe
b0bf3f02-d797-11ee-80c4-000c2961d091;Київ-Золотоустівська 44 Біле Сухе;Київ вул. Золотоустівська, 44;5cb61671-749b-11df-b112-00215aee3ebe
faf9fb7b-d797-11ee-80c4-000c2961d091;Київ-Золотоустівська 50 Біле Сухе;Київ вул. Золотоустівська, 50;5cb61671-749b-11df-b112-00215aee3ebe
b303ff31-890e-11e9-80e0-1c98ec135261;Київ-І. Котляревського 10 Інтертелеком;Київ бул. І. Котляревського (кол.Праці), 10;5cb61671-749b-11df-b112-00215aee3ebe
d525fdc6-87a0-11ee-80c1-000c2961d091;Київ-Івана Виговського 22 Пивна Лавка;Київ вул. Івана Виговського /Гречка Маршала/, 22;5cb61671-749b-11df-b112-00215aee3ebe
2dc90af0-cf06-11ee-80c4-000c2961d091;Київ-Івана Виговського 8 VSETUT;Київ вул. Івана Виговського /Гречка Маршала/, 8;5cb61671-749b-11df-b112-00215aee3ebe
c9655ca5-9b60-11ee-80c1-000c2961d091;Київ-Івана Дзюби 17 Lifecell;Київ вул. Івана Дзюби ( Сосніних Сім'ї ), 17;5cb61671-749b-11df-b112-00215aee3ebe
60f915e4-24d5-11ee-80bd-000c2961d091;Київ-Івашкевича Ярослава 6-8А Сільпо;Київ вул. Івашкевича Ярослава, 6-8А;5cb61671-749b-11df-b112-00215aee3ebe
4dc23ee2-dc14-11ec-80e7-000c29800ae7;Київ-Іллєнка Юрія 1 Rozetka;Київ вул. Іллєнка Юрія  (Мельникова), 1;5cb61671-749b-11df-b112-00215aee3ebe
e01dc733-24d5-11ee-80bd-000c2961d091;Київ-Інженерна 1 Сільпо;Київ вул. Інженерна, 1;5cb61671-749b-11df-b112-00215aee3ebe
e26ab0d7-b3a9-11ee-80c4-000c2961d091;Київ-Каденюка Леоніда 21А БРСМ-Нафта;Київ вул. Каденюка Леоніда ( Гагаріна Юрія ), 21А;5cb61671-749b-11df-b112-00215aee3ebe
02ed0531-727f-11ee-80c1-000c2961d091;Київ-Каденюка Леоніда 3 Lifecell;Київ вул. Каденюка Леоніда ( Гагаріна Юрія ), 3;5cb61671-749b-11df-b112-00215aee3ebe
f9984b23-dac5-11ed-80ed-000c29800ae7;Київ-Каденюка Леоніда 3 Оптика KLEIMAN;Київ вул. Каденюка Леоніда ( Гагаріна Юрія ), 3;5cb61671-749b-11df-b112-00215aee3ebe
cbe73089-24d9-11ee-80bd-000c2961d091;Київ-Казимира Малевича 107 Сільпо;Київ вул. Казимира Малевича, 107;5cb61671-749b-11df-b112-00215aee3ebe
b707c30b-e614-11eb-80d5-000c29800ae7;Київ-Кайсарова 15 АЗС KLO;Київ вул. Кайсарова, 15;5cb61671-749b-11df-b112-00215aee3ebe
0aa05bb4-40d4-11ee-80c0-000c2961d091;Київ-Калнишевського Петра 14 Продукти;Київ вул. Калнишевського Петра ( Майорова Михайла), 14;5cb61671-749b-11df-b112-00215aee3ebe
b4c43952-c891-11ed-80ed-000c29800ae7;Київ-Калнишевського Петра 2 Сільпо;Київ вул. Калнишевського Петра ( Майорова Михайла), 2;5cb61671-749b-11df-b112-00215aee3ebe
0f50c5d7-c1f4-11eb-80d5-000c29800ae7;Київ-Калнишевського Петра 7 Ательє;Київ вул. Калнишевського Петра ( Майорова Михайла), 7;5cb61671-749b-11df-b112-00215aee3ebe
7fb4fb97-92d9-11ee-80c1-000c2961d091;Київ-Калнишевського Петра 7 Копі Центр;Київ вул. Калнишевського Петра ( Майорова Михайла), 7;5cb61671-749b-11df-b112-00215aee3ebe
488639d5-2e92-11ed-80e7-000c29800ae7;Київ-Калнишевського Петра 8 Rozetka;Київ вул. Калнишевського Петра ( Майорова Михайла), 8;5cb61671-749b-11df-b112-00215aee3ebe
26e082f9-2585-11ee-80bd-000c2961d091;Київ-Карельський 3 Сільпо;Київ пров. Карельський, 3;5cb61671-749b-11df-b112-00215aee3ebe
2a51241f-2475-11ee-80bd-000c2961d091;Київ-Каховська 62 зооALL 4 PETS;Київ вул. Каховська, 62;5cb61671-749b-11df-b112-00215aee3ebe
209b0f66-16f9-11ec-80df-000c29800ae7;Київ-Каховська 62А Біле Сухе;Київ вул. Каховська, 62А;5cb61671-749b-11df-b112-00215aee3ebe
4e4801f2-40d0-11ee-80c0-000c2961d091;Київ-Качуровського І 72/3 Продукти;Київ пров. Качуровського І /кол. Руднєва Миколи/, 72/3;5cb61671-749b-11df-b112-00215aee3ebe
ebd01920-e330-11ed-80ed-000c29800ae7;Київ-Кибальчича Миколи 11 Lifecell;Київ вул. Кибальчича Миколи, 11;5cb61671-749b-11df-b112-00215aee3ebe
bcc63fa3-24d6-11ee-80bd-000c2961d091;Київ-Кибальчича Миколи 11А Сільпо;Київ вул. Кибальчича Миколи, 11А;5cb61671-749b-11df-b112-00215aee3ebe
8a3df66f-d33c-11eb-80d5-000c29800ae7;Київ-Кибальчича Миколи 11В Біле Сухе;Київ вул. Кибальчича Миколи, 11В;5cb61671-749b-11df-b112-00215aee3ebe
2ccd2dab-daab-11ec-80e7-000c29800ae7;Київ-Кибальчича Миколи 19В Rozetka;Київ вул. Кибальчича Миколи, 19В;5cb61671-749b-11df-b112-00215aee3ebe
cc364639-2a00-11ee-80bd-000c2961d091;Київ-Кирилівська 115/1 Біле Сухе;Київ вул. Кирилівська (Фрунзе), 115/1;5cb61671-749b-11df-b112-00215aee3ebe
0f8bc617-ee1e-11eb-80d5-000c29800ae7;Київ-Кирилівська 122 VSETUT;Київ вул. Кирилівська (Фрунзе), 122;5cb61671-749b-11df-b112-00215aee3ebe
6664055f-aed8-11ea-80c8-000c29800ae7;Київ-Кирилівська 122/1 Зоомагазин;Київ вул. Кирилівська (Фрунзе), 122/1;5cb61671-749b-11df-b112-00215aee3ebe
a780be60-6f5b-11ee-80c1-000c2961d091;Київ-Кирилівська 127 One Price;Київ вул. Кирилівська (Фрунзе), 127;5cb61671-749b-11df-b112-00215aee3ebe
1970972f-0697-11ed-80e7-000c29800ae7;Київ-Кільцева 1 Rozetka;Київ дорога Кільцева, 1;5cb61671-749b-11df-b112-00215aee3ebe
95bb826e-24d7-11ee-80bd-000c2961d091;Київ-Кільцева 1 Сільпо;Київ дорога Кільцева, 1;5cb61671-749b-11df-b112-00215aee3ebe
dcc1490e-daab-11ec-80e7-000c29800ae7;Київ-Кіото 25 Rozetka;Київ вул. Кіото, 25;5cb61671-749b-11df-b112-00215aee3ebe
80731f67-ea8e-11ee-80c5-000c2961d091;Київ-Кіото 3 Крамниця;Київ вул. Кіото, 3;5cb61671-749b-11df-b112-00215aee3ebe
d835b063-09ef-11e9-80d9-1c98ec135261;Київ-Клавдіївська 40Г зооHAPPY PAWS;Київ вул. Клавдіївська, 40Г;5cb61671-749b-11df-b112-00215aee3ebe
51816d07-846b-11ee-80c1-000c2961d091;Київ-Клавдіївська 40Д BEER WAY;Київ вул. Клавдіївська, 40Д;5cb61671-749b-11df-b112-00215aee3ebe
7ccd120a-15b7-11ee-80bd-000c2961d091;Київ-Княжий Затон 1/2 Майстер;Київ вул. Княжий Затон, 1/2;5cb61671-749b-11df-b112-00215aee3ebe
181d9a67-16e5-11ec-80df-000c29800ae7;Київ-Княжий Затон 11 Біле Сухе;Київ вул. Княжий Затон, 11;5cb61671-749b-11df-b112-00215aee3ebe
76d04b5d-5875-11e9-80df-1c98ec135261;Київ-Княжий Затон 9 iGarage;Київ вул. Княжий Затон, 9;5cb61671-749b-11df-b112-00215aee3ebe
4db919fe-b54d-11ee-80c4-000c2961d091;Київ-Кольцова 14А Пивна Лавка;Київ бул. Кольцова, 14А;5cb61671-749b-11df-b112-00215aee3ebe
8b4d76fb-18c1-11ed-80e7-000c29800ae7;Київ-Кольцова 14Д Rozetka;Київ бул. Кольцова, 14Д;5cb61671-749b-11df-b112-00215aee3ebe
e85423df-24d7-11ee-80bd-000c2961d091;Київ-Кольцова 14М Сільпо;Київ бул. Кольцова, 14М;5cb61671-749b-11df-b112-00215aee3ebe
0a423bfa-4fb3-11ed-80eb-000c29800ae7;Київ-Кондратюка Юрія 5 Rozetka;Київ вул. Кондратюка Юрія, 5;5cb61671-749b-11df-b112-00215aee3ebe
56bf0684-24d8-11ee-80bd-000c2961d091;Київ-Коновальця Євгена 26А Сільпо;Київ вул. Коновальця Євгена, 26А;5cb61671-749b-11df-b112-00215aee3ebe
02ef4c96-d9d6-11ec-80e7-000c29800ae7;Київ-Корольова Академіка 3 Rozetka;Київ Просп. Корольова Академіка, 3;5cb61671-749b-11df-b112-00215aee3ebe
1a829eb0-407b-11ec-80e3-000c29800ae7;Київ-Костянтинівська 21 Сервісний Центр;Київ вул. Костянтинівська, 21;5cb61671-749b-11df-b112-00215aee3ebe
4059b462-d79a-11ee-80c4-000c2961d091;Київ-Костянтинівська 2А Біле Сухе;Київ вул. Костянтинівська, 2А;5cb61671-749b-11df-b112-00215aee3ebe
8673e857-1ba7-11ec-80df-000c29800ae7;Київ-Котельникова Михайла 26 Делві;Київ вул. Котельникова Михайла, 26;5cb61671-749b-11df-b112-00215aee3ebe
3d25b537-8feb-11ec-80e4-000c29800ae7;Київ-Котельникова Михайла 33А Біле Сухе;Київ вул. Котельникова Михайла, 33А;5cb61671-749b-11df-b112-00215aee3ebe
d29d4e7e-d795-11ee-80c4-000c2961d091;Київ-Краснова Миколи 17 Біле Сухе;Київ вул. Краснова Миколи, 17;5cb61671-749b-11df-b112-00215aee3ebe
276e55ff-ee82-11ed-80ed-000c29800ae7;Київ-Кримського Академіка 4А Авіста Груп;Київ вул. Кримського Академіка, 4А;5cb61671-749b-11df-b112-00215aee3ebe
43d3d989-d798-11ee-80c4-000c2961d091;Київ-Крушельницької 15Б Біле Сухе;Київ вул. Крушельницької, 15Б;5cb61671-749b-11df-b112-00215aee3ebe
6cc6c4b0-d794-11ee-80c4-000c2961d091;Київ-Курбаса Леся 12А Біле Сухе;Київ Просп. Курбаса Леся, 12А;5cb61671-749b-11df-b112-00215aee3ebe
190c6cff-2310-11e9-80dc-1c98ec135261;Київ-Курбаса Леся 1А/1 ОККО;Київ Просп. Курбаса Леся, 1А/1;5cb61671-749b-11df-b112-00215aee3ebe
c7b64558-b549-11ee-80c4-000c2961d091;Київ-Курбаса Леся 2/13 Світ Канцелярії;Київ Просп. Курбаса Леся, 2/13;5cb61671-749b-11df-b112-00215aee3ebe
a6d0ca3b-836f-11e9-80e0-1c98ec135261;Київ-Курбаса Леся 2Ж ОККО;Київ Просп. Курбаса Леся, 2Ж;5cb61671-749b-11df-b112-00215aee3ebe
b2881158-403f-11ee-80c0-000c2961d091;Київ-Лаврухіна Миколи 15/46 Продукти;Київ вул. Лаврухіна Миколи, 15/46;5cb61671-749b-11df-b112-00215aee3ebe
ec12ee80-9b5f-11ee-80c1-000c2961d091;Київ-Лаврухіна Миколи 4 Lifecell;Київ вул. Лаврухіна Миколи, 4;5cb61671-749b-11df-b112-00215aee3ebe
c062f524-24d8-11ee-80bd-000c2961d091;Київ-Лаврухіна Миколи 4 Сільпо;Київ вул. Лаврухіна Миколи, 4;5cb61671-749b-11df-b112-00215aee3ebe
fccc6967-4031-11ee-80c0-000c2961d091;Київ-Лебедєва Академіка 1к2 Продукти;Київ вул. Лебедєва Академіка, 1к2;5cb61671-749b-11df-b112-00215aee3ebe
183305b3-cf10-11ee-80c4-000c2961d091;Київ-Левка Лук'яненка 21 Interoptica;Київ вул. Левка Лук'яненка ( Тимошенка Маршала ), 21;5cb61671-749b-11df-b112-00215aee3ebe
923f6255-92da-11ee-80c1-000c2961d091;Київ-Левка Лук'яненка 21 Кавярня;Київ вул. Левка Лук'яненка ( Тимошенка Маршала ), 21;5cb61671-749b-11df-b112-00215aee3ebe
ee91764b-92d9-11ee-80c1-000c2961d091;Київ-Левка Лук'яненка 21 Копі Центр;Київ вул. Левка Лук'яненка ( Тимошенка Маршала ), 21;5cb61671-749b-11df-b112-00215aee3ebe
d165f864-dff3-11e7-80cc-1c98ec135261;Київ-Левка Лук'яненка 29 Копі Центр;Київ вул. Левка Лук'яненка ( Тимошенка Маршала ), 29;5cb61671-749b-11df-b112-00215aee3ebe
6464fb08-6f5d-11ee-80c1-000c2961d091;Київ-Лесі Українки 2 One Price;Київ бул. Лесі Українки ( Бульвар ), 2;5cb61671-749b-11df-b112-00215aee3ebe
52f01fb0-dcb9-11ec-80e7-000c29800ae7;Київ-Лесі Українки 24 Rozetka;Київ бул. Лесі Українки ( Бульвар ), 24;5cb61671-749b-11df-b112-00215aee3ebe
a3ffdda6-f57f-11ed-80ed-000c29800ae7;Київ-Лесі Українки 34 Делві;Київ бул. Лесі Українки ( Бульвар ), 34;5cb61671-749b-11df-b112-00215aee3ebe
ebf6ff5c-1bad-11ec-80df-000c29800ae7;Київ-Лесі Українки 36/10 Делві;Київ бул. Лесі Українки (Бульвар), 36/10;5cb61671-749b-11df-b112-00215aee3ebe
6ca47012-24da-11ee-80bd-000c2961d091;Київ-Литовський 4А Сільпо;Київ Просп. Литовський (кол.Мінський), 4А;5cb61671-749b-11df-b112-00215aee3ebe
b39d3efd-41b6-11ee-80c0-000c2961d091;Київ-Лісовий 23/25 Lifecell;Київ Просп. Лісовий, 23/25;5cb61671-749b-11df-b112-00215aee3ebe
2f3f6ad8-24d9-11ee-80bd-000c2961d091;Київ-Лісовий 39 Сільпо;Київ Просп. Лісовий, 39;5cb61671-749b-11df-b112-00215aee3ebe
d1e595da-3dd2-11ee-80c0-000c2961d091;Київ-Лобановського Валерія 140прим.2А Вееr time;Київ Просп. Лобановського Валерія, 140прим.2А;5cb61671-749b-11df-b112-00215aee3ebe
7d0e0f57-2a00-11ee-80bd-000c2961d091;Київ-Лобачевського 7 Біле Сухе;Київ пров. Лобачевського, 7;5cb61671-749b-11df-b112-00215aee3ebe
b9b787a1-40cf-11ee-80c0-000c2961d091;Київ-Лондонська 14-16/7 Продукти;Київ вул. Лондонська (Пітерська), 14-16/7;5cb61671-749b-11df-b112-00215aee3ebe
0df16e05-836f-11e9-80e0-1c98ec135261;Київ-Лугова 2А ОККО;Київ вул. Лугова, 2А;5cb61671-749b-11df-b112-00215aee3ebe
874a8ee1-8465-11ee-80c1-000c2961d091;Київ-Луценка Дмитра 12 BEER WAY;Київ вул. Луценка Дмитра (Крейсера Аврора), 12;5cb61671-749b-11df-b112-00215aee3ebe
de7c8408-f933-11e8-80d9-1c98ec135261;Київ-Лютеранська 11А В Мире Искусства;Київ вул. Лютеранська, 11А;5cb61671-749b-11df-b112-00215aee3ebe
bfb806f7-db71-11ec-80e7-000c29800ae7;Київ-Лятошинського 14 Rozetka;Київ вул. Лятошинського, 14;5cb61671-749b-11df-b112-00215aee3ebe
e7dbbfb7-d4a5-11ee-80c4-000c2961d091;Київ-Лятошинського 14 магазин Одяг;Київ вул. Лятошинського, 18;5cb61671-749b-11df-b112-00215aee3ebe
20afe18b-1ba8-11ec-80df-000c29800ae7;Київ-Львівська 12 Делві;Київ вул. Львівська, 12;5cb61671-749b-11df-b112-00215aee3ebe
4464ec80-4030-11ee-80c0-000c2961d091;Київ-Макіївська 4 Продукти;Київ вул. Макіївська, 4;5cb61671-749b-11df-b112-00215aee3ebe
05b8d6ad-4026-11ee-80c0-000c2961d091;Київ-Макіївська 7 міні маркет Вечеря;Київ вул. Макіївська, 7;5cb61671-749b-11df-b112-00215aee3ebe
d216bf93-16f7-11ec-80df-000c29800ae7;Київ-Максимовича Михайла 24А Біле Сухе;Київ вул. Максимовича Михайла ( Трутенка Онуфрія ), 24А;5cb61671-749b-11df-b112-00215aee3ebe
3c02ccd5-ab1a-11ee-80c4-000c2961d091;Київ-Максимовича Михайла 24Б Vape Shop;Київ вул. Максимовича Михайла ( Трутенка Онуфрія ), 24Б;5cb61671-749b-11df-b112-00215aee3ebe
1488e7f0-8466-11ee-80c1-000c2961d091;Київ-Максимовича Михайла 26В BEER WAY;Київ вул. Максимовича Михайла ( Трутенка Онуфрія ), 26В;5cb61671-749b-11df-b112-00215aee3ebe
46a7b667-db72-11ec-80e7-000c29800ae7;Київ-Максимовича Михайла 28Б Rozetka;Київ вул. Максимовича Михайла ( Трутенка Онуфрія ), 28Б;5cb61671-749b-11df-b112-00215aee3ebe
92e5535b-43af-11ec-80e3-000c29800ae7;Київ-Максимовича Михайла 3Д Біле Сухе;Київ вул. Максимовича Михайла ( Трутенка Онуфрія ), 3Д;5cb61671-749b-11df-b112-00215aee3ebe
46010ad6-ab1b-11ee-80c4-000c2961d091;Київ-Малишка Андрія 2Д Vape Shop;Київ вул. Малишка Андрія, 2Д;5cb61671-749b-11df-b112-00215aee3ebe
20fedb5f-9e88-11ed-80ec-000c29800ae7;Київ-Малишка Андрія 3У Lifecell;Київ вул. Малишка Андрія, 3У;5cb61671-749b-11df-b112-00215aee3ebe
35952d03-c59a-11ee-80c4-000c2961d091;Київ-Машинобудівна 44 F.ua;Київ вул. Машинобудівна, 44;5cb61671-749b-11df-b112-00215aee3ebe
221c7afd-ab15-11ee-80c4-000c2961d091;Київ-Маяковського 75 Vape Shop;Київ Просп. Маяковського (Троєщина), 75;5cb61671-749b-11df-b112-00215aee3ebe
908d9eb3-dd17-11ec-80e7-000c29800ae7;Київ-Межигірська 63 Rozetka;Київ вул. Межигірська, 63;5cb61671-749b-11df-b112-00215aee3ebe
6c1e7b34-e85b-11ee-80c5-000c2961d091;Київ-Межова 24 Rozetka;Київ вул. Межова, 24;5cb61671-749b-11df-b112-00215aee3ebe
6d0d1645-8e93-11ed-80ec-000c29800ae7;Київ-Метрологічна 44 Rozetka;Київ вул. Метрологічна, 44;5cb61671-749b-11df-b112-00215aee3ebe
6ef18dfe-402a-11ee-80c0-000c2961d091;Київ-Микитенка Івана 11 Продукти;Київ вул. Микитенка Івана, 11;5cb61671-749b-11df-b112-00215aee3ebe
f527ef07-4032-11ee-80c0-000c2961d091;Київ-Микільсько-Слобідська 10прим648 Грін Маркет;Київ вул. Микільсько-Слобідська, 10прим648;5cb61671-749b-11df-b112-00215aee3ebe
31e46331-403f-11ee-80c0-000c2961d091;Київ-Микільсько-Слобідська 15 Грін Маркет;Київ вул. Микільсько-Слобідська, 15;5cb61671-749b-11df-b112-00215aee3ebe
0baee0ad-7fb9-11ee-80c1-000c2961d091;Київ-Миколайчука Івана 4 Lifecell;Київ вул. Миколайчука Івана (Серафимовича), 4;5cb61671-749b-11df-b112-00215aee3ebe
62f7e489-ab1e-11ee-80c4-000c2961d091;Київ-Миколи Голего 2 Vape Shop;Київ вул. Миколи Голего (Лебедєва-Кумача), 2;5cb61671-749b-11df-b112-00215aee3ebe
656a0d1b-db71-11ec-80e7-000c29800ae7;Київ-Миколи Міхновського 16/1 Rozetka;Київ бул. Миколи Міхновського (Дружби Народів), 16/1;5cb61671-749b-11df-b112-00215aee3ebe
b1486016-dc96-11ee-80c4-000c2961d091;Київ-Миколи Міхновського 17/5 ЕПІЦЕНТР.ua;Київ бул. Миколи Міхновського (Дружби Народів), 17/5;5cb61671-749b-11df-b112-00215aee3ebe
fa8de93f-5958-11ee-80c0-000c2961d091;Київ-Миколи Міхновського 30А Lifecell;Київ бул. Миколи Міхновського (Дружби Народів), 30А;5cb61671-749b-11df-b112-00215aee3ebe
19520b6e-24da-11ee-80bd-000c2961d091;Київ-Милославська 10А Сільпо;Київ вул. Милославська, 10А;5cb61671-749b-11df-b112-00215aee3ebe
7790c0cf-836b-11e9-80e0-1c98ec135261;Київ-Милославська 2А ОККО;Київ вул. Милославська, 2А;5cb61671-749b-11df-b112-00215aee3ebe
3e854ff4-ada2-11eb-80d3-000c29800ae7;Київ-Милославська 31В Біле Сухе;Київ вул. Милославська, 31В;5cb61671-749b-11df-b112-00215aee3ebe
6e607dd1-ab20-11ee-80c4-000c2961d091;Київ-Миропільська 23 Vape Shop;Київ вул. Миропільська, 23;5cb61671-749b-11df-b112-00215aee3ebe
9f065461-b47e-11eb-80d4-000c29800ae7;Київ-Миру 5 СПЕЦ;Київ Просп. Миру (проспект), 5;5cb61671-749b-11df-b112-00215aee3ebe
f4df4e63-aa5d-11ee-80c4-000c2961d091;Київ-Митрополита Андрея Шептицького 22 Лап-Усик;Київ вул. Митрополита Андрея Шептицького, 22;5cb61671-749b-11df-b112-00215aee3ebe
f9ead0e2-24d8-11ee-80bd-000c2961d091;Київ-Митрополита Андрея Шептицького 22 Сільпо;Київ вул. Митрополита Андрея Шептицького, 22;5cb61671-749b-11df-b112-00215aee3ebe
07e03b30-d795-11ee-80c4-000c2961d091;Київ-Митрополита Андрея Шептицького 24 Біле Сухе;Київ вул. Митрополита Андрея Шептицького, 24;5cb61671-749b-11df-b112-00215aee3ebe
531166fa-24d9-11ee-80bd-000c2961d091;Київ-Митрополита Андрея Шептицького 4А Сільпо;Київ вул. Митрополита Андрея Шептицького, 4А;5cb61671-749b-11df-b112-00215aee3ebe
00036222-dabc-11ec-80e7-000c29800ae7;Київ-Митрополита Василя Липківського 40 Rozetka;Київ вул. Митрополита Василя Липківського(Урицьког, 40;5cb61671-749b-11df-b112-00215aee3ebe
e7ba96bb-dc11-11ec-80e7-000c29800ae7;Київ-Михайла Драгоманова 2 (Розетка) ;Київ вул. Драгоманова Михайла, 2;5cb61671-749b-11df-b112-00215aee3ebe
a745d614-69a8-11ee-80c1-000c2961d091;Київ-Мишуги Олександра 3В One Price;Київ вул. Мишуги Олександра, 3В;5cb61671-749b-11df-b112-00215aee3ebe
05ff7d3e-dd18-11ec-80e7-000c29800ae7;Київ-Мишуги Олександра 4 Rozetka;Київ вул. Мишуги Олександра, 4;5cb61671-749b-11df-b112-00215aee3ebe
b56b1988-2560-11ee-80bd-000c2961d091;Київ-Мишуги Олександра 4 Сільпо;Київ вул. Мишуги Олександра, 4;5cb61671-749b-11df-b112-00215aee3ebe
1c08d1e8-bb60-11ee-80c4-000c2961d091;Київ-Мілютенка 19 Rozetka;Київ вул. Мілютенка, 19;5cb61671-749b-11df-b112-00215aee3ebe
a2802235-c412-11ee-80c4-000c2961d091;Київ-Мілютенка 23 Vape Shop;Київ вул. Мілютенка, 23;5cb61671-749b-11df-b112-00215aee3ebe
0f452911-fae1-11ed-80ed-000c29800ae7;Київ-Мінський 6 Біле Сухе;Київ Просп. Мінський, 6;5cb61671-749b-11df-b112-00215aee3ebe
bd244648-b1e4-11ed-80ec-000c29800ae7;Київ-Мокра 20А Rozetka;Київ вул. Мокра ( Кудряшова ), 20А;5cb61671-749b-11df-b112-00215aee3ebe
e6a45e6e-40d4-11ee-80c0-000c2961d091;Київ-Монтажників 101/2 Продукти;Київ вул. Монтажників, 101/2;5cb61671-749b-11df-b112-00215aee3ebe
429af708-24cc-11ee-80bd-000c2961d091;Київ-Набережна Русанівська 10 Сільпо;Київ наб. Набережна Русанівська, 10;5cb61671-749b-11df-b112-00215aee3ebe
e7d288c4-dce1-11ec-80e7-000c29800ae7;Київ-Набережна Русанівська 4 Rozetka;Київ наб. Набережна Русанівська, 4;5cb61671-749b-11df-b112-00215aee3ebe
e34d7691-20b6-11e9-80dc-1c98ec135261;Київ-Наддніпрянське 10 ОККО;Київ шосе Наддніпрянське, 10;5cb61671-749b-11df-b112-00215aee3ebe
603beb16-c41b-11ee-80c4-000c2961d091;Київ-Науки 1/2 lifecell;Київ Просп. Науки, 1/2;5cb61671-749b-11df-b112-00215aee3ebe
4b5a315b-6f5b-11ee-80c1-000c2961d091;Київ-Науки 4Г One Price;Київ Просп. Науки, 4Г;5cb61671-749b-11df-b112-00215aee3ebe
c1e2e8a4-6f65-11ee-80c1-000c2961d091;Київ-Незалежності 1 One Price;Київ Майдан Незалежності, 1;5cb61671-749b-11df-b112-00215aee3ebe
f00a26c7-3036-11e9-80dd-1c98ec135261;Київ-Нижній Вал 3А ОККО;Київ вул. Нижній Вал, 3А;5cb61671-749b-11df-b112-00215aee3ebe
74b4f5fe-16ee-11ec-80df-000c29800ae7;Київ-Ніжинська 5 Біле Сухе;Київ вул. Ніжинська, 5;5cb61671-749b-11df-b112-00215aee3ebe
85f09a2d-0e97-11ee-80bd-000c2961d091;Київ-Новгород-Сіверська 5 Біле Сухе;Київ вул. Новгород-Сіверська ( Новгородська ), 5;5cb61671-749b-11df-b112-00215aee3ebe
ad66ad8c-230a-11e9-80dc-1c98ec135261;Київ-Новокостянтинівська 1Б ОККО;Київ вул. Новокостянтинівська, 1Б;5cb61671-749b-11df-b112-00215aee3ebe
52951f68-3075-11e9-80dd-1c98ec135261;Київ-Новокостянтинівська 4Ж ОККО;Київ вул. Новокостянтинівська, 4Ж;5cb61671-749b-11df-b112-00215aee3ebe
b8152bd9-8184-11eb-80d2-000c29800ae7;Київ-Новоукраїнська 2/6 Зоомагазин;Київ вул. Новоукраїнська, 2/6;5cb61671-749b-11df-b112-00215aee3ebe
fe1f6171-ab1a-11ee-80c4-000c2961d091;Київ-Оболонська(Площа) 1 Vape Shop;Київ пл. Оболонська (Площа), 1;5cb61671-749b-11df-b112-00215aee3ebe
2498b941-a0e1-11ee-80c4-000c2961d091;Київ-Оболонський (проспект) 51Б Rozetka;Київ Просп. Оболонський (проспект), 51Б;5cb61671-749b-11df-b112-00215aee3ebe
178fb079-8e89-11ed-80ec-000c29800ae7;Київ-Оболонський 19 Rozetka;Київ Просп. Оболонський (проспект), 19;5cb61671-749b-11df-b112-00215aee3ebe
fe3fd24c-24db-11ee-80bd-000c2961d091;Київ-Оболонський 19 Сільпо;Київ Просп. Оболонський (проспект), 19;5cb61671-749b-11df-b112-00215aee3ebe
0cf29c15-24a9-11ee-80bd-000c2961d091;Київ-Оболонський 1Б Сільпо;Київ Просп. Оболонський (проспект), 1Б;5cb61671-749b-11df-b112-00215aee3ebe
618780b2-9b60-11ee-80c1-000c2961d091;Київ-Оболонський 21Б Lifecell;Київ Просп. Оболонський (проспект), 21Б;5cb61671-749b-11df-b112-00215aee3ebe
376c5787-24ab-11ee-80bd-000c2961d091;Київ-Оболонський 21Б Сільпо;Київ Просп. Оболонський (проспект), 21Б;5cb61671-749b-11df-b112-00215aee3ebe
c38b96d0-6f55-11ee-80c1-000c2961d091;Київ-Оболонський 21Б СПЕЦ;Київ Просп. Оболонський (проспект), 21Б;5cb61671-749b-11df-b112-00215aee3ebe
c3eea4a7-d049-11ec-80e7-000c29800ae7;Київ-Оболонський 26Б Rozetka;Київ Просп. Оболонський (проспект), 26Б;5cb61671-749b-11df-b112-00215aee3ebe
f45ae165-6f5f-11ee-80c1-000c2961d091;Київ-Оболонський 47/42 One Price;Київ Просп. Оболонський (проспект), 47/42;5cb61671-749b-11df-b112-00215aee3ebe
3df6a86a-d9d8-11ec-80e7-000c29800ae7;Київ-Оболонський 47/42 Rozetka;Київ Просп. Оболонський (проспект), 47/42;5cb61671-749b-11df-b112-00215aee3ebe
c732faa0-b54c-11ee-80c4-000c2961d091;Київ-Оболонський 7 Пивна Лавка;Київ Просп. Оболонський (проспект), 7;5cb61671-749b-11df-b112-00215aee3ebe
439f453e-43b1-11ec-80e3-000c29800ae7;Київ-Окіпної Раїси 10 Біле Сухе;Київ вул. Окіпної Раїси, 10;5cb61671-749b-11df-b112-00215aee3ebe
f7d64d23-ab15-11ee-80c4-000c2961d091;Київ-Окіпної Раїси 3 Vape Shop;Київ вул. Окіпної Раїси, 3;5cb61671-749b-11df-b112-00215aee3ebe
17aa66cc-16fc-11ec-80df-000c29800ae7;Київ-Олевська 5 Біле Сухе;Київ вул. Олевська, 5;5cb61671-749b-11df-b112-00215aee3ebe
80a450bc-6737-11e9-80df-1c98ec135261;Київ-Олександра Кониського 16 Олівець;Київ вул. Олександра Кониського/Тургенєвська, 16;5cb61671-749b-11df-b112-00215aee3ebe
f4721729-ab1b-11ee-80c4-000c2961d091;Київ-Олександра Махова 4 Vape Shop;Київ вул. Олександра Махова ( Жолудєва ), 4;5cb61671-749b-11df-b112-00215aee3ebe
ac2ca2d6-ab15-11ee-80c4-000c2961d091;Київ-Олександри Екстер 16 Vape Shop;Київ вул. Олександри Екстер ( Цвєтаєвої Марини ), 16;5cb61671-749b-11df-b112-00215aee3ebe
4ea5a38e-40cb-11ee-80c0-000c2961d091;Київ-Олекси Тихого 80/17 GREEN;Київ вул. Олекси Тихого (Виборзька), 80/17;5cb61671-749b-11df-b112-00215aee3ebe
159c2ffa-9170-11ee-80c1-000c2961d091;Київ-Олексіївська 5-3 Храпкова;Київ вул. Олексіївська, 5-3;5cb61671-749b-11df-b112-00215aee3ebe
d4157e03-24ab-11ee-80bd-000c2961d091;Київ-Оленівська 3 Сільпо;Київ вул. Оленівська, 3;5cb61671-749b-11df-b112-00215aee3ebe
ec7d93c1-31ee-11ee-80bd-000c2961d091;Київ-Олеся Олександра 2Б Мій пивний;Київ вул. Олеся Олександра, 2Б;5cb61671-749b-11df-b112-00215aee3ebe
1c9aef96-56df-11ee-80c0-000c2961d091;Київ-Олійника Степана 21 Кавярня;Київ вул. Олійника Степана, 21;5cb61671-749b-11df-b112-00215aee3ebe
15bac83d-a0f0-11ee-80c4-000c2961d091;Київ-Оноре де Бальзака 2А APS.Partners;Київ вул. Оноре де Бальзака, 2А;5cb61671-749b-11df-b112-00215aee3ebe
ec5ae0f3-d33c-11eb-80d5-000c29800ae7;Київ-Оноре де Бальзака 42/20 Біле Сухе;Київ вул. Оноре де Бальзака, 42/20;5cb61671-749b-11df-b112-00215aee3ebe
a25862d4-43ae-11ec-80e3-000c29800ae7;Київ-Оноре де Бальзака 55В Біле Сухе;Київ вул. Оноре де Бальзака, 55В;5cb61671-749b-11df-b112-00215aee3ebe
3038a269-2a01-11ee-80bd-000c2961d091;Київ-Оноре де Бальзака 66 Біле Сухе;Київ вул. Оноре де Бальзака, 66;5cb61671-749b-11df-b112-00215aee3ebe
eb74599d-2498-11ee-80bd-000c2961d091;Київ-Оноре де Бальзака 91/29А Сільпо;Київ вул. Оноре де Бальзака, 91/29А;5cb61671-749b-11df-b112-00215aee3ebe
98ee429b-75e0-11eb-80d1-000c29800ae7;Київ-Оноре де Бальзака 94 Ательє;Київ вул. Оноре де Бальзака, 94;5cb61671-749b-11df-b112-00215aee3ebe
7f5baf83-daa9-11ec-80e7-000c29800ae7;Київ-Оноре де Бальзака 94Б Rozetka;Київ вул. Оноре де Бальзака, 94Б;5cb61671-749b-11df-b112-00215aee3ebe
5c616a7f-40ce-11ee-80c0-000c2961d091;Київ-Освіти 10 Продукти;Київ вул. Освіти, 10;5cb61671-749b-11df-b112-00215aee3ebe
a88e717c-16f6-11ec-80df-000c29800ae7;Київ-Осокорська 2А Біле Сухе;Київ вул. Осокорська, 2А;5cb61671-749b-11df-b112-00215aee3ebe
57751fcb-d797-11ee-80c4-000c2961d091;Київ-Остафія Дашкевича 15 Біле Сухе;Київ вул. Остафія Дашкевича (к.Курнатовського), 15;5cb61671-749b-11df-b112-00215aee3ebe
a6f5dc80-dd06-11ed-80ed-000c29800ae7;Київ-Павлівська 18 СПЕЦ;Київ вул. Павлівська, 18;5cb61671-749b-11df-b112-00215aee3ebe
605d10d6-e26d-11ed-80ed-000c29800ae7;Київ-Палладіна Академіка 16/39 Rozetka;Київ Просп. Палладіна Академіка, 16/39;5cb61671-749b-11df-b112-00215aee3ebe
c20f0a30-48dd-11ea-80c6-000c29800ae7;Київ-Палладіна Академіка 33 ОККО;Київ Просп. Палладіна Академіка, 33;5cb61671-749b-11df-b112-00215aee3ebe
0f1395d8-13fb-11ee-80bd-000c2961d091;Київ-Палладіна Академіка 7Б Lifecell;Київ Просп. Палладіна Академіка, 7Б;5cb61671-749b-11df-b112-00215aee3ebe
a2d44557-1ba9-11ec-80df-000c29800ae7;Київ-Парково-Сирецька 6Д Делві;Київ вул. Парково-Сирецька, 6Д;5cb61671-749b-11df-b112-00215aee3ebe
c2bdf649-662b-11eb-80d1-000c29800ae7;Київ-Пасхаліна Юрія 2 Lifecell;Київ вул. Пасхаліна Юрія (Ілліча), 2;5cb61671-749b-11df-b112-00215aee3ebe
93664e48-d33d-11eb-80d5-000c29800ae7;Київ-Перемишльська 14 Біле Сухе;Київ вул. Перемишльська ( Червонопільська ), 14;5cb61671-749b-11df-b112-00215aee3ebe
80f20d9b-500e-11eb-80d0-000c29800ae7;Київ-Петра Запорожця 21 FOTODRIVE;Київ вул. Петра Запорожця, 21;5cb61671-749b-11df-b112-00215aee3ebe
f46ff6b0-16ea-11ec-80df-000c29800ae7;Київ-Петрицького 21 Біле Сухе;Київ вул. Петрицького, 21;5cb61671-749b-11df-b112-00215aee3ebe
b704ad7a-40d3-11ee-80c0-000c2961d091;Київ-Петропавлівська 58 Продукти;Київ вул. Петропавлівська, 58;5cb61671-749b-11df-b112-00215aee3ebe
fb3321df-402d-11ee-80c0-000c2961d091;Київ-Пимоненка Миколи 5 Продукти;Київ вул. Пимоненка Миколи, 5;5cb61671-749b-11df-b112-00215aee3ebe
31b6840d-24b0-11ee-80bd-000c2961d091;Київ-Північна 6 Сільпо;Київ вул. Північна, 6;5cb61671-749b-11df-b112-00215aee3ebe
a92cc4cb-fade-11ed-80ed-000c29800ae7;Київ-Підгірна 3/7 Біле Сухе;Київ вул. Підгірна, 3/7;5cb61671-749b-11df-b112-00215aee3ebe
b400740f-24b0-11ee-80bd-000c2961d091;Київ-Підлісна 1 Сільпо;Київ вул. Підлісна, 1;5cb61671-749b-11df-b112-00215aee3ebe
e02b735d-ab1d-11ee-80c4-000c2961d091;Київ-Підлісна 5 Vape Shop;Київ вул. Підлісна, 5;5cb61671-749b-11df-b112-00215aee3ebe
4c235457-dc8d-11ea-80ca-000c29800ae7;Київ-Повітрофлотський 33/2 Канцтовари;Київ Просп. Повітряних сил України (Повітрофлотський, 33/2;5cb61671-749b-11df-b112-00215aee3ebe
9ad687c8-40ca-11ee-80c0-000c2961d091;Київ-Повітрофлотський 33/2 Продукти;Київ Просп. Повітряних сил України (Повітрофлотський, 33/2;5cb61671-749b-11df-b112-00215aee3ebe
9f129fde-daba-11ec-80e7-000c29800ae7;Київ-Повітрофлотський 36 Rozetka;Київ Просп. Повітряних сил України (Повітрофлотський, 36;5cb61671-749b-11df-b112-00215aee3ebe
258ff27f-4e22-11ee-80c0-000c2961d091;Київ-Повітрофлотський 56 Rozetka;Київ Просп. Повітряних сил України (Повітрофлотський, 56;5cb61671-749b-11df-b112-00215aee3ebe
88c6aacb-d79a-11ee-80c4-000c2961d091;Київ-Покровська 24А Біле Сухе;Київ вул. Покровська, 24А;5cb61671-749b-11df-b112-00215aee3ebe
bec4c984-4027-11ee-80c0-000c2961d091;Київ-Поліська 28Бпр.169 Продукти;Київ вул. Поліська, 28Бпр.169;5cb61671-749b-11df-b112-00215aee3ebe
e0fbba22-403e-11ee-80c0-000c2961d091;Київ-Поліський 7/1 Green Market;Київ пров. Поліський, 7/1;5cb61671-749b-11df-b112-00215aee3ebe
f7fe6e50-ab1c-11ee-80c4-000c2961d091;Київ-Політехнічний 3/1 Vape Shop;Київ пров. Політехнічний, 3/1;5cb61671-749b-11df-b112-00215aee3ebe
a35328a5-e831-11ee-80c5-000c2961d091;Київ-Полтавська 13 Є Пиво Є кава;Київ вул. Полтавська, 13;5cb61671-749b-11df-b112-00215aee3ebe
0e8f24ac-ead4-11eb-80d5-000c29800ae7;Київ-Полярна 12/1 АЗС KLO;Київ вул. Полярна, 12/1;5cb61671-749b-11df-b112-00215aee3ebe
bef9f110-201b-11e7-80fd-1c98ec135263;Київ-Полярна 15б СПЕЦ;Київ вул. Полярна, 15б;5cb61671-749b-11df-b112-00215aee3ebe
e0365021-8d63-11ee-80c1-000c2961d091;Київ-Полярна 8Е ;Київ вул. Полярна, 8Е;5cb61671-749b-11df-b112-00215aee3ebe
9fbc5b5e-fae0-11ed-80ed-000c29800ae7;Київ-Польова 73 Біле Сухе;Київ вул. Польова, 73;5cb61671-749b-11df-b112-00215aee3ebe
da8fe36e-d796-11ee-80c4-000c2961d091;Київ-Поправки Юрія 4/39 Біле Сухе;Київ вул. Поправки Юрія, 4/39;5cb61671-749b-11df-b112-00215aee3ebe
801e787e-24b1-11ee-80bd-000c2961d091;Київ-Порика Василя 5А Сільпо;Київ вул. Порика Василя, 5А;5cb61671-749b-11df-b112-00215aee3ebe
77669164-16fc-11ec-80df-000c29800ae7;Київ-Порика Василя 9 Біле Сухе;Київ Просп. Порика Василя, 9;5cb61671-749b-11df-b112-00215aee3ebe
60c3420a-f656-11ea-80cc-000c29800ae7;Київ-Поштовий ЗАХІД;Київ(Софіївська Борщагівка) вул. Мала Кільцева, 10/1;80f5570b-749b-11df-b112-00215aee3ebe
e9b0d747-0877-11eb-80cd-000c29800ae7;Київ-Поштовий СХІД;Київ вул. Роберта Лісовського ( Акад. Бутлерова ), 8Б;5cb61671-749b-11df-b112-00215aee3ebe
6b73e6e3-16f7-11ec-80df-000c29800ae7;Київ-Правди 43Б Біле Сухе;Київ Просп. Правди, 43Б;5cb61671-749b-11df-b112-00215aee3ebe
fdd56f5e-08d0-11ed-80e7-000c29800ae7;Київ-Правди 47 Rozetka;Київ Просп. Правди, 47;5cb61671-749b-11df-b112-00215aee3ebe
bb450d52-d9d8-11ec-80e7-000c29800ae7;Київ-Правди 58 Rozetka;Київ Просп. Правди, 58;5cb61671-749b-11df-b112-00215aee3ebe
b7025d02-24b2-11ee-80bd-000c2961d091;Київ-Правди 58 Сільпо;Київ Просп. Правди, 58;5cb61671-749b-11df-b112-00215aee3ebe
4a43544e-24b2-11ee-80bd-000c2961d091;Київ-Правди 66 Сільпо;Київ Просп. Правди, 66;5cb61671-749b-11df-b112-00215aee3ebe
1068fe30-402a-11ee-80c0-000c2961d091;Київ-Правди 88 Продукти;Київ Просп. Правди, 88;5cb61671-749b-11df-b112-00215aee3ebe
70ada1f0-20b7-11e9-80dc-1c98ec135261;Київ-Празька 18В ОККО;Київ вул. Празька, 18В;5cb61671-749b-11df-b112-00215aee3ebe
96180c3b-e613-11eb-80d5-000c29800ae7;Київ-Преображенська 1А АЗС KLO;Київ вул. Преображенська (Клименка Івана), 1А;5cb61671-749b-11df-b112-00215aee3ebe
747d554c-c246-11ed-80ed-000c29800ae7;Київ-Преображенська 22/9 Rozetka;Київ вул. Преображенська (Клименка Івана), 22/9;5cb61671-749b-11df-b112-00215aee3ebe
22a7d69f-0e97-11ee-80bd-000c2961d091;Київ-Преображенська 22/9 Біле Сухе;Київ вул. Преображенська (Клименка Івана), 22/9;5cb61671-749b-11df-b112-00215aee3ebe
4abba3b4-402f-11ee-80c0-000c2961d091;Київ-Привокзальна 14А Продукти;Київ вул. Привокзальна, 14А;5cb61671-749b-11df-b112-00215aee3ebe
1e0dea9c-20b8-11e9-80dc-1c98ec135261;Київ-Привокзальна 1А ОККО;Київ вул. Привокзальна, 1А;5cb61671-749b-11df-b112-00215aee3ebe
c013cd87-d794-11ee-80c4-000c2961d091;Київ-Приймаченко 1/27 Біле Сухе;Київ бул. Приймаченко, 1/27;5cb61671-749b-11df-b112-00215aee3ebe
27509851-18c2-11ed-80e7-000c29800ae7;Київ-Прилужна 4/15 Rozetka;Київ вул. Прилужна, 4/15;5cb61671-749b-11df-b112-00215aee3ebe
65594307-29ff-11ee-80bd-000c2961d091;Київ-Пріорська 13 Біле Сухе;Київ вул. Пріорська (Полупанова), 13;5cb61671-749b-11df-b112-00215aee3ebe
57dbf0ab-dabc-11ec-80e7-000c29800ae7;Київ-Пулюя Івана 8А Rozetka;Київ вул. Пулюя Івана, 8А;5cb61671-749b-11df-b112-00215aee3ebe
75aa29b7-d795-11ee-80c4-000c2961d091;Київ-Пчілки Олени 2 Біле Сухе;Київ вул. Пчілки Олени, 2;5cb61671-749b-11df-b112-00215aee3ebe
236e46a3-87a1-11ee-80c1-000c2961d091;Київ-Пчілки Олени 6 Пивна Лавка;Київ вул. Пчілки Олени, 6;5cb61671-749b-11df-b112-00215aee3ebe
17eb1586-16ef-11ec-80df-000c29800ae7;Київ-Пчілки Олени 6А Біле Сухе;Київ вул. Пчілки Олени, 6А;5cb61671-749b-11df-b112-00215aee3ebe
7fa03604-d19a-11ee-80c4-000c2961d091;Київ-Раїси Окіпної 2 lifecell;Київ вул. Раїси Окіпної, 2;5cb61671-749b-11df-b112-00215aee3ebe
61164667-24ca-11ee-80bd-000c2961d091;Київ-Райдужна 15 Сільпо;Київ вул. Райдужна, 15;5cb61671-749b-11df-b112-00215aee3ebe
c5aa756b-59b3-11ec-80e4-000c29800ae7;Київ-Ракетна 24Б Кав'ярня;Київ вул. Ракетна, 24Б;5cb61671-749b-11df-b112-00215aee3ebe
af516506-24cb-11ee-80bd-000c2961d091;Київ-Ревуцького 12/1А Сільпо;Київ вул. Ревуцького, 12/1А;5cb61671-749b-11df-b112-00215aee3ebe
be732b0e-8530-11ee-80c1-000c2961d091;Київ-Ревуцького 33 Кавярня;Київ вул. Ревуцького, 33;5cb61671-749b-11df-b112-00215aee3ebe
a09997a0-b13f-11ee-80c4-000c2961d091;Київ-Ревуцького 44Г Boss coffee;Київ вул. Ревуцького, 44Г;5cb61671-749b-11df-b112-00215aee3ebe
cd3eaab3-1bac-11ec-80df-000c29800ae7;Київ-Ревуцького 5 Делві;Київ вул. Ревуцького, 5;5cb61671-749b-11df-b112-00215aee3ebe
ab57aa71-7eff-11ee-80c1-000c2961d091;Київ-Ревуцького 54 Кавярня;Київ вул. Ревуцького, 54;5cb61671-749b-11df-b112-00215aee3ebe
7b5048ce-0e94-11ee-80bd-000c2961d091;Київ-Ревуцького 9 Біле Сухе;Київ вул. Ревуцького, 9;5cb61671-749b-11df-b112-00215aee3ebe
3b89ee30-0e95-11ee-80bd-000c2961d091;Київ-Рея Бредбері 12/12 Біле Сухе;Київ вул. Рея Бредбері (Дубініна), 12/12;5cb61671-749b-11df-b112-00215aee3ebe
89591abd-5e8e-11ee-80c0-000c2961d091;Київ-Роберта Лісовського 8-Б СПЕЦ;Київ вул. Роберта Лісовського ( Акад. Бутлерова ), 8-Б;5cb61671-749b-11df-b112-00215aee3ebe
3b9010d0-3954-11eb-80cf-000c29800ae7;Київ-Рональда Рейгана 4 зооЛімпопо;Київ вул. Рональда Рейгана ( Теодора Драйзера ), 4;5cb61671-749b-11df-b112-00215aee3ebe
9aa8108c-efd6-11ed-80ed-000c29800ae7;Київ-Рональда Рейгана 8 Lifecell;Київ вул. Рональда Рейгана ( Теодора Драйзера ), 8;5cb61671-749b-11df-b112-00215aee3ebe
703a7426-daaa-11ec-80e7-000c29800ae7;Київ-Рональда Рейгана 8 Rozetka;Київ вул. Рональда Рейгана ( Теодора Драйзера ), 8;5cb61671-749b-11df-b112-00215aee3ebe
5a5d27b6-24d3-11ee-80bd-000c2961d091;Київ-Рональда Рейгана 8 Сільпо;Київ вул. Рональда Рейгана ( Теодора Драйзера ), 8;5cb61671-749b-11df-b112-00215aee3ebe
3136d16d-2a00-11ee-80bd-000c2961d091;Київ-Русанівський 2/8 Біле Сухе;Київ бул. Русанівський (Бульвар), 2/8;5cb61671-749b-11df-b112-00215aee3ebe
ba0b5762-24cc-11ee-80bd-000c2961d091;Київ-Сагайдачного Петра 41 Сільпо;Київ вул. Сагайдачного Петра, 41;5cb61671-749b-11df-b112-00215aee3ebe
e193d24d-ab17-11ee-80c4-000c2961d091;Київ-Саксаганського 131Б Vape Shop;Київ вул. Саксаганського, 131Б;5cb61671-749b-11df-b112-00215aee3ebe
a294ea16-983f-11ee-80c1-000c2961d091;Київ-Салютна 2 Аптека;Київ вул. Салютна, 2;5cb61671-749b-11df-b112-00215aee3ebe
2a714f80-24cd-11ee-80bd-000c2961d091;Київ-Самійла Кішки 7 Сільпо;Київ вул. Самійла Кішки (Маршала Конєва), 7;5cb61671-749b-11df-b112-00215aee3ebe
4bef83a2-b6ec-11eb-80d4-000c29800ae7;Київ-Сверстюка Євгена 1 Фото Центр;Київ вул. Сверстюка Євгена (Раскової Марини), 1;5cb61671-749b-11df-b112-00215aee3ebe
fc533b7d-537b-11ed-80eb-000c29800ae7;Київ-Сверстюка Євгена 19 Rozetka;Київ вул. Сверстюка Євгена (Раскової Марини), 19;5cb61671-749b-11df-b112-00215aee3ebe
5d70c8ee-d343-11eb-80d5-000c29800ae7;Київ-Сверстюка Євгена 6В Біле Сухе;Київ вул. Сверстюка Євгена (Раскової Марини), 6В;5cb61671-749b-11df-b112-00215aee3ebe
45b783ca-dd19-11ec-80e7-000c29800ae7;Київ-Світла 6Б Rozetka;Київ вул. Світла, 6Б;5cb61671-749b-11df-b112-00215aee3ebe
8201d043-fec2-11e8-80d9-1c98ec135261;Київ-Свободи 26 Аксесуари;Київ Просп. Свободи, 26;5cb61671-749b-11df-b112-00215aee3ebe
c1ccb1d3-ab18-11ee-80c4-000c2961d091;Київ-Святошинська 1 Vape Shop;Київ вул. Святошинська, 1;5cb61671-749b-11df-b112-00215aee3ebe
eab3d8d0-b54a-11ee-80c4-000c2961d091;Київ-Святошинська 1 Левеня;Київ пл. Святошинська, 1;5cb61671-749b-11df-b112-00215aee3ebe
0424d5af-82eb-11e9-80e0-1c98ec135261;Київ-Святошинська 34Б ОККО;Київ вул. Святошинська, 34Б;5cb61671-749b-11df-b112-00215aee3ebe
ba150c1f-d9d9-11ec-80e7-000c29800ae7;Київ-Святошинська 3А Rozetka;Київ вул. Святошинська, 3А;5cb61671-749b-11df-b112-00215aee3ebe
4378954a-ea4d-11ed-80ed-000c29800ae7;Київ-Селянська 1 Rozetka;Київ вул. Селянська, 1;5cb61671-749b-11df-b112-00215aee3ebe
8408b3b7-b9ca-11e9-80e4-1c98ec135261;Київ-Сеноманська 18/5 Еда Вода;Київ вул. Сеноманська, 18/5;5cb61671-749b-11df-b112-00215aee3ebe
ff9436c6-40c9-11ee-80c0-000c2961d091;Київ-Сергія Берегового 16/14 Продукти;Київ вул. Сергія Берегового ( Мартиросяна ), 16/14;5cb61671-749b-11df-b112-00215aee3ebe
9dfc09d1-4033-11ee-80c0-000c2961d091;Київ-Сергія Берегового 19 GREEN;Київ вул. Сергія Берегового ( Мартиросяна ), 19;5cb61671-749b-11df-b112-00215aee3ebe
750feda2-ccb7-11ee-80c4-000c2961d091;Київ-Серпова 11 Nektar;Київ вул. Серпова, 11;5cb61671-749b-11df-b112-00215aee3ebe
70c5fa91-0e96-11ee-80bd-000c2961d091;Київ-Симиренка 2/19 Біле Сухе;Київ вул. Симиренка, 2/19;5cb61671-749b-11df-b112-00215aee3ebe
3871dfc6-f65a-11ed-80ed-000c29800ae7;Київ-Симиренка 5 Rozetka;Київ вул. Симиренка, 5;5cb61671-749b-11df-b112-00215aee3ebe
a7d58379-40cb-11ee-80c0-000c2961d091;Київ-Сирецька 30/1 Продукти;Київ вул. Сирецька, 30/1;5cb61671-749b-11df-b112-00215aee3ebe
19bc0390-24ce-11ee-80bd-000c2961d091;Київ-Січових Стрільців 37/41 Сільпо;Київ вул. Січових Стрільців (Артема), 37/41;5cb61671-749b-11df-b112-00215aee3ebe
12cdd23a-1f25-11ee-80bd-000c2961d091;Київ-Скляренка Семена 17 Master City;Київ вул. Скляренка Семена, 17;5cb61671-749b-11df-b112-00215aee3ebe
822916dd-24ce-11ee-80bd-000c2961d091;Київ-Скляренка Семена 17 Сільпо;Київ вул. Скляренка Семена, 17;5cb61671-749b-11df-b112-00215aee3ebe
f71dd0ae-3074-11e9-80dd-1c98ec135261;Київ-Скляренка Семена 4 ОККО;Київ вул. Скляренка Семена, 4;5cb61671-749b-11df-b112-00215aee3ebe
258b7b21-bd61-11eb-80d4-000c29800ae7;Київ-Скляренка Семена 5Б АЗС KLO;Київ вул. Скляренка Семена, 5Б;5cb61671-749b-11df-b112-00215aee3ebe
38f7432d-ab18-11ee-80c4-000c2961d091;Київ-Смилянська 8 Vape Shop;Київ вул. Смилянська, 8;5cb61671-749b-11df-b112-00215aee3ebe
6ad977aa-1bad-11ec-80df-000c29800ae7;Київ-Соборності 10/1А Делві;Київ Просп. Соборності, 10/1А;5cb61671-749b-11df-b112-00215aee3ebe
0caaadaf-20af-11e9-80dc-1c98ec135261;Київ-Соборності 15Б ОККО;Київ Просп. Соборності, 15Б;5cb61671-749b-11df-b112-00215aee3ebe
d1bba8bd-6f61-11ee-80c1-000c2961d091;Київ-Соборності 4 One Price;Київ Просп. Соборності, 4;5cb61671-749b-11df-b112-00215aee3ebe
9291fa16-ab1b-11ee-80c4-000c2961d091;Київ-Соборності 4 Vape Shop;Київ Просп. Соборності, 4;5cb61671-749b-11df-b112-00215aee3ebe
a6dd34ce-ab1c-11ee-80c4-000c2961d091;Київ-Соборності 5В Vape Shop;Київ Просп. Соборності, 5В;5cb61671-749b-11df-b112-00215aee3ebe
49434252-dc97-11ee-80c4-000c2961d091;Київ-Соборності 7Б ЕПІЦЕНТР.ua;Київ Просп. Соборності, 7Б;5cb61671-749b-11df-b112-00215aee3ebe
400bd572-daad-11ed-80ed-000c29800ae7;Київ-Соборності 7Б СПЕЦ;Київ Просп. Соборності, 7Б;5cb61671-749b-11df-b112-00215aee3ebe
11a993ca-fae0-11ed-80ed-000c29800ae7;Київ-Солом'янська 14 Біле Сухе;Київ вул. Солом'янська, 14;5cb61671-749b-11df-b112-00215aee3ebe
4674ca93-4031-11ee-80c0-000c2961d091;Київ-Солом'янська 4/2 Продукти;Київ вул. Солом'янська, 4/2;5cb61671-749b-11df-b112-00215aee3ebe
e53812d4-5d05-11ee-80c0-000c2961d091;Київ-Сосницька 10В Chilln Coffee;Київ вул. Сосницька, 10В;5cb61671-749b-11df-b112-00215aee3ebe
f2ad5a93-c9a3-11ee-80c4-000c2961d091;Київ-Софії Русової 1 Coffe and Eclairs;Київ вул. Софії Русової, 1;5cb61671-749b-11df-b112-00215aee3ebe
001c292e-782e-11eb-80d1-000c29800ae7;Київ-Софії Русової 7 зооУсоЛапоХвост;Київ вул. Софії Русової, 7;5cb61671-749b-11df-b112-00215aee3ebe
529436ef-c891-11ed-80ed-000c29800ae7;Київ-Спортивна 1 Сільпо;Київ пл. Спортивна, 1;5cb61671-749b-11df-b112-00215aee3ebe
97a7e615-ab19-11ee-80c4-000c2961d091;Київ-Срібнокільська 20 В Vape Shop;Київ вул. Срібнокільська, 20 В;5cb61671-749b-11df-b112-00215aee3ebe
d078d124-0e96-11ee-80bd-000c2961d091;Київ-Срібнокільська 22 Біле Сухе;Київ вул. Срібнокільська, 22;5cb61671-749b-11df-b112-00215aee3ebe
2fed5e67-24cf-11ee-80bd-000c2961d091;Київ-Срібнокільська 3Г Сільпо;Київ вул. Срібнокільська, 3Г;5cb61671-749b-11df-b112-00215aee3ebe
3e309bbf-a3a9-11eb-80d3-000c29800ae7;Київ-Срібнокільська 4 Копі Центр;Київ вул. Срібнокільська, 4;5cb61671-749b-11df-b112-00215aee3ebe
a154788c-24cf-11ee-80bd-000c2961d091;Київ-Стальського Сулеймана 22/10 Сільпо;Київ вул. Стальського Сулеймана, 22/10;5cb61671-749b-11df-b112-00215aee3ebe
77e6e508-595a-11ee-80c0-000c2961d091;Київ-Степана Бандери  10 Lifecell;Київ Просп. Степана Бандери  (кол. Московський), 10;5cb61671-749b-11df-b112-00215aee3ebe
3129cabe-690a-11ee-80c1-000c2961d091;Київ-Степана Бандери  10Б One Price;Київ Просп. Степана Бандери  (кол. Московський), 10Б;5cb61671-749b-11df-b112-00215aee3ebe
bbba4507-d047-11ec-80e7-000c29800ae7;Київ-Степана Бандери  6 Rozetka;Київ Просп. Степана Бандери  (кол. Московський), 6;5cb61671-749b-11df-b112-00215aee3ebe
ced8835c-ead4-11eb-80d5-000c29800ae7;Київ-Степана Бандери  6 АЗС KLO;Київ Просп. Степана Бандери  (кол. Московський), 6;5cb61671-749b-11df-b112-00215aee3ebe
01e262cd-ab1f-11ee-80c4-000c2961d091;Київ-Степана Бандери 16 Vape Shop;Київ Просп. Степана Бандери  (кол. Московський), 16;5cb61671-749b-11df-b112-00215aee3ebe
eba03446-9a6a-11ee-80c1-000c2961d091;Київ-Степана Бандери 36 Сільпо;Київ Просп. Степана Бандери  (кол. Московський), 36;5cb61671-749b-11df-b112-00215aee3ebe
7cb0c995-f3c1-11ed-80ed-000c29800ae7;Київ-Степана Рудницького 2Г Будівельні товари;Київ вул. Степана Рудницького (Вільямса Академіка), 2Г;5cb61671-749b-11df-b112-00215aee3ebe
f3f98d07-16f5-11ec-80df-000c29800ae7;Київ-Степана Рудницького 5А Біле Сухе;Київ вул. Степана Рудницького (Вільямса Академіка), 5А;5cb61671-749b-11df-b112-00215aee3ebe
0bd28a03-24d0-11ee-80bd-000c2961d091;Київ-Столичне 103 Сільпо;Київ шосе Столичне, 103;5cb61671-749b-11df-b112-00215aee3ebe
15cb6202-1830-11eb-80cd-000c29800ae7;Київ-Схід;Київ вул. Роберта Лісовського ( Акад. Бутлерова ), 8-Б;5cb61671-749b-11df-b112-00215aee3ebe
0dd39e6b-172f-11ee-80bd-000c2961d091;Київ-Татарська 18Б Канцтовари;Київ вул. Татарська, 18Б;5cb61671-749b-11df-b112-00215aee3ebe
afaec0c1-68fc-11ee-80c1-000c2961d091;Київ-Теліги Олени 17 One Price;Київ вул. Теліги Олени, 17;5cb61671-749b-11df-b112-00215aee3ebe
21271ec6-5541-11ed-80eb-000c29800ae7;Київ-Теліги Олени 25 Rozetka;Київ вул. Теліги Олени, 25;5cb61671-749b-11df-b112-00215aee3ebe
b9d69bf8-402b-11ee-80c0-000c2961d091;Київ-Теремківська 2А Продукти;Київ вул. Теремківська, 2А;5cb61671-749b-11df-b112-00215aee3ebe
ccf1531d-09d3-11ed-80e7-000c29800ae7;Київ-Тираспольська 54 Rozetka;Київ вул. Тираспольська, 54;5cb61671-749b-11df-b112-00215aee3ebe
3008b4aa-ab19-11ee-80c4-000c2961d091;Київ-Тираспольська 54 Vape Shop;Київ вул. Тираспольська, 54;5cb61671-749b-11df-b112-00215aee3ebe
8c6a632a-d341-11eb-80d5-000c29800ae7;Київ-Тираспольська 58 Біле Сухе;Київ вул. Тираспольська, 58;5cb61671-749b-11df-b112-00215aee3ebe
067fac5c-ff83-11ed-80bc-000c2961d091;Київ-Тичини Павла 16/2 Мінімаркет;Київ Просп. Тичини Павла, 16/2;5cb61671-749b-11df-b112-00215aee3ebe
aa2ab92e-09d4-11e9-80d9-1c98ec135261;Київ-Тичини Павла 18В Сервісний Центр;Київ Просп. Тичини Павла, 18В;5cb61671-749b-11df-b112-00215aee3ebe
ba7ac3e4-24ac-11ee-80bd-000c2961d091;Київ-Тичини Павла 1В Сільпо;Київ Просп. Тичини Павла, 1В;5cb61671-749b-11df-b112-00215aee3ebe
22e680bf-ab20-11ee-80c4-000c2961d091;Київ-Тростянецька 12 Vape Shop;Київ вул. Тростянецька, 12;5cb61671-749b-11df-b112-00215aee3ebe
f6830987-bd59-11eb-80d4-000c29800ae7;Київ-Трублаїні 1Б АЗС KLO;Київ вул. Трублаїні, 1Б;5cb61671-749b-11df-b112-00215aee3ebe
9431b151-6f5c-11ee-80c1-000c2961d091;Київ-Туполєва Академіка 13А One Price;Київ вул. Мрії (Туполєва Академіка), 13А;5cb61671-749b-11df-b112-00215aee3ebe
7c77c1c8-16e3-11ec-80df-000c29800ae7;Київ-Туполєва Академіка 16 Біле Сухе;Київ вул. Мрії (Туполєва Академіка), 16;5cb61671-749b-11df-b112-00215aee3ebe
12600660-69c3-11ee-80c1-000c2961d091;Київ-Урлівська 11/44 Good Beer;Київ вул. Урлівська, 11/44;5cb61671-749b-11df-b112-00215aee3ebe
73ef054f-fadd-11ed-80ed-000c29800ae7;Київ-Урлівська 11А Біле Сухе;Київ вул. Урлівська, 11А;5cb61671-749b-11df-b112-00215aee3ebe
deea5db9-4bf4-11e9-80dd-1c98ec135261;Київ-Ушакова Миколи 14А Зоомагазин;Київ вул. Ушакова Миколи, 14А;5cb61671-749b-11df-b112-00215aee3ebe
faa9d1cd-40c7-11ee-80c0-000c2961d091;Київ-Феодосійська 1 Продукти;Київ вул. Феодосійська, 1;5cb61671-749b-11df-b112-00215aee3ebe
b747244f-29fc-11ee-80bd-000c2961d091;Київ-Феодосійська 2Л Біле Сухе;Київ вул. Феодосійська, 2Л;5cb61671-749b-11df-b112-00215aee3ebe
0b43b0a2-24d3-11ee-80bd-000c2961d091;Київ-Філатова Академіка 7 Сільпо;Київ вул. Філатова Академіка, 7;5cb61671-749b-11df-b112-00215aee3ebe
dcbf47db-6f5d-11ee-80c1-000c2961d091;Київ-Харківське 144 One Price;Київ шосе Харківське, 144;5cb61671-749b-11df-b112-00215aee3ebe
d0b2a5b6-24d3-11ee-80bd-000c2961d091;Київ-Харківське 144 Сільпо;Київ шосе Харківське, 144;5cb61671-749b-11df-b112-00215aee3ebe
8fa156f4-662c-11eb-80d1-000c29800ae7;Київ-Харківське 144А Lifecell;Київ шосе Харківське, 144А;5cb61671-749b-11df-b112-00215aee3ebe
cc3c6c58-e843-11ee-80c5-000c2961d091;Київ-Харківське 150/15 Делві;Київ шосе Харківське, 150/15;5cb61671-749b-11df-b112-00215aee3ebe
25d6278a-24d4-11ee-80bd-000c2961d091;Київ-Харківське 168 Сільпо;Київ шосе Харківське, 168;5cb61671-749b-11df-b112-00215aee3ebe
587e20f5-e50f-11ee-80c5-000c2961d091;Київ-Харківське 182 Пухнастик;Київ шосе Харківське, 182;5cb61671-749b-11df-b112-00215aee3ebe
a08dd700-b909-11e9-80e4-1c98ec135261;Київ-Харківське 19 Дитячий одяг;Київ шосе Харківське, 19;5cb61671-749b-11df-b112-00215aee3ebe
68e03900-5098-11ee-80c0-000c2961d091;Київ-Харківське 201-203 Rozetka;Київ шосе Харківське, 201-203;5cb61671-749b-11df-b112-00215aee3ebe
d731721d-20b8-11e9-80dc-1c98ec135261;Київ-Харківське 203 ОККО;Київ шосе Харківське, 203;5cb61671-749b-11df-b112-00215aee3ebe
9a30f85a-dd19-11ec-80e7-000c29800ae7;Київ-Харківське 2А Rozetka;Київ шосе Харківське, 2А;5cb61671-749b-11df-b112-00215aee3ebe
2dcbf144-b122-11ee-80c4-000c2961d091;Київ-Харченка 36 Vape Shop;Київ вул. Харченка, 36;5cb61671-749b-11df-b112-00215aee3ebe
8153a0a3-a988-11ee-80c4-000c2961d091;Київ-Хорива 1А Лап-Усик;Київ вул. Хорива, 1А;5cb61671-749b-11df-b112-00215aee3ebe
9ac8f285-ab1a-11ee-80c4-000c2961d091;Київ-Хрещатик 21 Vape Shop;Київ вул. Хрещатик, 21;5cb61671-749b-11df-b112-00215aee3ebe
d40911c3-0e94-11ee-80bd-000c2961d091;Київ-Чавдар Єлизавети 13 Біле Сухе;Київ вул. Чавдар Єлизавети (район Осокорки), 13;5cb61671-749b-11df-b112-00215aee3ebe
dbebcf15-18c1-11ed-80e7-000c29800ae7;Київ-Чавдар Єлизавети 18 Rozetka;Київ вул. Чавдар Єлизавети (район Осокорки), 18;5cb61671-749b-11df-b112-00215aee3ebe
35a9e563-dab4-11ed-80ed-000c29800ae7;Київ-Чавдар Єлизавети 1-з СПЕЦ;Київ вул. Чавдар Єлизавети (район Осокорки), 1-з;5cb61671-749b-11df-b112-00215aee3ebe
cb7791ae-16ef-11ec-80df-000c29800ae7;Київ-Чавдар Єлизавети 24 Біле Сухе;Київ вул. Чавдар Єлизавети (район Осокорки), 24;5cb61671-749b-11df-b112-00215aee3ebe
3ee98009-8d66-11ee-80c1-000c2961d091;Київ-Червоної Калини 26 Зоохвіст;Київ Просп. Червоної Калини (Маяковського В), 26;5cb61671-749b-11df-b112-00215aee3ebe
ad2acf94-5541-11ed-80eb-000c29800ae7;Київ-Червоної Калини 43/2 Rozetka;Київ Просп. Червоної Калини (Маяковського В), 43/2;5cb61671-749b-11df-b112-00215aee3ebe
15b515f1-24d5-11ee-80bd-000c2961d091;Київ-Червоної Калини 43/2 Сільпо;Київ Просп. Червоної Калини (Маяковського В), 43/2;5cb61671-749b-11df-b112-00215aee3ebe
7cb042d1-daac-11ec-80e7-000c29800ae7;Київ-Червоної Калини 6 Rozetka;Київ Просп. Червоної Калини (Маяковського В), 6;5cb61671-749b-11df-b112-00215aee3ebe
8b35ffd9-24d5-11ee-80bd-000c2961d091;Київ-Червоної Калини 75/2 Сільпо;Київ Просп. Червоної Калини (Маяковського В), 75/2;5cb61671-749b-11df-b112-00215aee3ebe
35715e26-24d6-11ee-80bd-000c2961d091;Київ-Чоколівський 28/1 Сільпо;Київ бул. Чоколівський, 28/1;5cb61671-749b-11df-b112-00215aee3ebe
b7345c75-dabc-11ec-80e7-000c29800ae7;Київ-Чоколівський 37 Rozetka;Київ бул. Чоколівський, 37;5cb61671-749b-11df-b112-00215aee3ebe
32fae9d6-2308-11e9-80dc-1c98ec135261;Київ-Чоколівський 42 ОККО;Київ бул. Чоколівський, 42;5cb61671-749b-11df-b112-00215aee3ebe
ec3220ae-24d5-11ee-80bd-000c2961d091;Київ-Чоколівський 6 Сільпо;Київ бул. Чоколівський, 6;5cb61671-749b-11df-b112-00215aee3ebe
95e9d4aa-24d8-11ee-80bd-000c2961d091;Київ-Чорнобильська 3 Сільпо;Київ вул. Чорнобильська, 3;5cb61671-749b-11df-b112-00215aee3ebe
a13b65dc-dd1b-11ec-80e7-000c29800ae7;Київ-Чорновола В'ячеслава 20 Rozetka;Київ вул. Чорновола В'ячеслава, 20;5cb61671-749b-11df-b112-00215aee3ebe
7ad89d58-d796-11ee-80c4-000c2961d091;Київ-Шолом-Алейхема 17Г Біле Сухе;Київ вул. Шолом-Алейхема, 17Г;5cb61671-749b-11df-b112-00215aee3ebe
46357c64-82ea-11e9-80e0-1c98ec135261;Київ-Шухевича Романа  32 ОККО;Київ Просп. Шухевича Романа /Ватутіна Генерала/, 32;5cb61671-749b-11df-b112-00215aee3ebe
daf55f81-e616-11eb-80d5-000c29800ae7;Київ-Шухевича Романа 11 АЗС KLO;Київ Просп. Шухевича Романа /Ватутіна Генерала/, 11;5cb61671-749b-11df-b112-00215aee3ebe
ab911e83-3074-11e9-80dd-1c98ec135261;Київ-Шухевича Романа 2А ОККО;Київ Просп. Шухевича Романа /Ватутіна Генерала/, 2А;5cb61671-749b-11df-b112-00215aee3ebe
445b72bd-836e-11e9-80e0-1c98ec135261;Київ-Шухевича Романа 5 ОККО;Київ Просп. Шухевича Романа /Ватутіна Генерала/, 5;5cb61671-749b-11df-b112-00215aee3ebe
46a08d53-ab1f-11ee-80c4-000c2961d091;Київ-Щербаківського 2 Vape Shop;Київ вул. Щербаківського (Щербакова), 2;5cb61671-749b-11df-b112-00215aee3ebe
0d170bdb-e2ea-11e2-b5e8-003048d2b473;Київ-Щербаківського 45 СПЕЦ;Київ вул. Щербаківського (Щербакова), 45;5cb61671-749b-11df-b112-00215aee3ebe
d05d3fae-24d9-11ee-80bd-000c2961d091;Київ-Щербаківського 56/7 Сільпо;Київ вул. Щербаківського (Щербакова), 56/7;5cb61671-749b-11df-b112-00215aee3ebe
6f3cc2e2-40cf-11ee-80c0-000c2961d091;Київ-Щусєва Академіка 28 Продукти;Київ вул. Щусєва Академіка, 28;5cb61671-749b-11df-b112-00215aee3ebe
d6853118-ab20-11ee-80c4-000c2961d091;Київ-Щусєва Академіка 55 Vape Shop;Київ вул. Щусєва Академіка, 55;5cb61671-749b-11df-b112-00215aee3ebe
aa8f4eeb-4025-11ee-80c0-000c2961d091;Київ-Ю. Здановської 40 Продукти;Київ вул. Здановської Юлії (Ломоносова), 40;5cb61671-749b-11df-b112-00215aee3ebe
8ea4925c-7f09-11ee-80c1-000c2961d091;Київ-Ю. Здановської 73А Зоомагазин;Київ вул. Здановської Юлії (Ломоносова), 73А;5cb61671-749b-11df-b112-00215aee3ebe
511579de-d799-11ee-80c4-000c2961d091;Київ-Юнкерова Миколи 92 Біле Сухе;Київ вул. Юнкерова Миколи, 92;5cb61671-749b-11df-b112-00215aee3ebe
f9c8f880-40d0-11ee-80c0-000c2961d091;Київ-Юності 8А Продукти;Київ вул. Юності, 8А;5cb61671-749b-11df-b112-00215aee3ebe
12906b8f-b8e8-11ed-80ed-000c29800ae7;Київ-Юрківська 20 Lifecell;Київ вул. Юрківська, 20;5cb61671-749b-11df-b112-00215aee3ebe
7226b0d4-24d9-11ee-80bd-000c2961d091;Київ-Якова Гніздовського 1А Сільпо;Київ вул. Якова Гніздовського (кол.Магнітогорська), 1А;5cb61671-749b-11df-b112-00215aee3ebe
4f943eef-6e78-11ee-80c1-000c2961d091;Київ-Якуба Колоса 10 фітнес клуб SportStar;Київ вул. Якуба Колоса, 10;5cb61671-749b-11df-b112-00215aee3ebe
33d18358-24da-11ee-80bd-000c2961d091;Київ-Якутська 8 Сільпо;Київ вул. Родини Бунге /Якутська, 8;5cb61671-749b-11df-b112-00215aee3ebe
75cc5665-dd1c-11ec-80e7-000c29800ae7;Київ-Ялтинська 22Б Rozetka;Київ вул. Ялтинська, 22Б;5cb61671-749b-11df-b112-00215aee3ebe
0c28c274-ced0-11e7-80cc-1c98ec135261;Київ-Янгеля Академіка 4 Поліграфія;Київ вул. Янгеля Академіка, 4;5cb61671-749b-11df-b112-00215aee3ebe
b3c34c5a-24da-11ee-80bd-000c2961d091;Київ-Ярославська 56А Сільпо;Київ вул. Ярославська, 56А;5cb61671-749b-11df-b112-00215aee3ebe
55673a8e-4033-11ee-80c0-000c2961d091;Київ-Ясинуватський 10 GREEN;Київ пров. Ясинуватський, 10;5cb61671-749b-11df-b112-00215aee3ebe
18d85d34-535f-11ec-80e4-000c29800ae7;Кишин-Житомирська 64;Кишин вул. Житомирська, 64;5cb616d3-749b-11df-b112-00215aee3ebe
0e51b7d3-9a1e-11eb-80d3-000c29800ae7;Ківерці-Визволителів 4;Ківерці вул. Визволителів, 4;5cb616e1-749b-11df-b112-00215aee3ebe
b3e9f5e8-ae02-11ed-80ec-000c29800ae7;Ківерці-Відродження 1А;Ківерці вул. Відродження, 1А;5cb616e1-749b-11df-b112-00215aee3ebe
4e76cdff-1e36-11e9-80dc-1c98ec135261;Ківерці-Соборності 23;Ківерці вул. Соборності, 23;5cb616e1-749b-11df-b112-00215aee3ebe
c609f758-cc09-11ea-80ca-000c29800ae7;Ківерці-Чкалова 24 А;Ківерці вул. Чкалова, 24А;5cb616e1-749b-11df-b112-00215aee3ebe
6d747190-ae02-11ed-80ec-000c29800ae7;Ківерці-Шевченка 23;Ківерці вул. Шевченка, 23;5cb616e1-749b-11df-b112-00215aee3ebe
5f78d5af-a3ba-11ed-80ec-000c29800ae7;Кілія-Вилківська 38;Кілія вул. Вилківська, 38;5cb616ed-749b-11df-b112-00215aee3ebe
0c106b18-4254-11ee-80c0-000c2961d091;Кілія-Гагаріна 57А оф.5;Кілія вул. Гагаріна, 57А оф.5;5cb616ed-749b-11df-b112-00215aee3ebe
e804a6d9-fbde-11e9-80e7-1c98ec135261;Кіцмань-Незалежності 33;Кіцмань вул. Незалежності, 33;5cb61740-749b-11df-b112-00215aee3ebe
663cdfae-35bb-11ed-80e9-000c29800ae7;Кіцмань-Незалежності 71;Кіцмань вул. Незалежності, 71;5cb61740-749b-11df-b112-00215aee3ebe
7ceb0260-aa1c-11ee-80c4-000c2961d091;Кіцмань-Незалежності 85А;Кіцмань вул. Незалежності, 85А;5cb61740-749b-11df-b112-00215aee3ebe
44cfc1ca-a0e6-11ee-80c4-000c2961d091;Клевань-Козацька 10;Клевань вул. Козацька, 10;5cb6174a-749b-11df-b112-00215aee3ebe
71271661-b963-11eb-80d4-000c29800ae7;Клесів-Радянська 23А;Клесів вул. Радянська, 23А;5cb6175f-749b-11df-b112-00215aee3ebe
ce92d5b8-5940-11ee-80c0-000c2961d091;Клесів-Центральна 1;Клесів вул. Центральна, 1;5cb6175f-749b-11df-b112-00215aee3ebe
f9e9b194-e2ee-11eb-80d5-000c29800ae7;Клесів-Центральна 10;Клесів вул. Центральна, 10;5cb6175f-749b-11df-b112-00215aee3ebe
c6b3c85c-cfc9-11ed-80ed-000c29800ae7;Клячаново-Мукачівська 140;Клячаново вул. Мукачівська, 140;5cb617c3-749b-11df-b112-00215aee3ebe
f3638b07-0984-11ec-80d6-000c29800ae7;Княгининок-Соборна 76;Княгининок (Маяки) вул. Соборна (17-го Вересня), 76;68cf26c5-749b-11df-b112-00215aee3ebe
b74811d5-1703-11ed-80e7-000c29800ae7;Кобеляки-Мільйонна 16/2;Кобеляки вул. Мільйонна, 16/2;5cb617eb-749b-11df-b112-00215aee3ebe
c75b9e16-32a1-11e8-80d0-1c98ec135261;Кобеляки-Огія,25-Б;Кобеляки вул. Огія, 25Б;5cb617eb-749b-11df-b112-00215aee3ebe
74a28d38-ccc2-11ea-80ca-000c29800ae7;Ков'яги-Центральна 1 А;Ков'яги вул. Центральна, 1А;5cb6185c-749b-11df-b112-00215aee3ebe
d8c9571c-7a6c-11ea-80c6-000c29800ae7;Ковель;Ковель вул. Кияна Володимира /Ватутіна/, 44;5cb6183d-749b-11df-b112-00215aee3ebe
c1400301-17ca-11ed-80e7-000c29800ae7;Ковель-Відродження 4 Сім23;Ковель вул. Відродження, 4;5cb6183d-749b-11df-b112-00215aee3ebe
922187f8-c2e9-11eb-80d5-000c29800ae7;Ковель-Володимирська 101 Сім23;Ковель вул. Володимирська, 101;5cb6183d-749b-11df-b112-00215aee3ebe
1f869e2d-345b-11e9-80dd-1c98ec135261;Ковель-Володимирська 120 ОККО;Ковель вул. Володимирська, 120;5cb6183d-749b-11df-b112-00215aee3ebe
aa7e8409-425f-11ee-80c0-000c2961d091;Ковель-Володимирська 85Б Сім23;Ковель вул. Володимирська, 85Б;5cb6183d-749b-11df-b112-00215aee3ebe
207376f2-b9f9-11ee-80c4-000c2961d091;Ковель-Героїв України 9А OptKlin;Ковель вул. Героїв України ( 40 років Перемоги ), 9А;5cb6183d-749b-11df-b112-00215aee3ebe
1c698e76-ae06-11ed-80ec-000c29800ae7;Ковель-Грушевського 1 Сім23;Ковель вул. Грушевського, 1;5cb6183d-749b-11df-b112-00215aee3ebe
d9c87ff8-e2f3-11eb-80d5-000c29800ae7;Ковель-Грушевського 10 Наш Край;Ковель вул. Грушевського, 10;5cb6183d-749b-11df-b112-00215aee3ebe
7e1c728d-0bd4-11ec-80d6-000c29800ae7;Ковель-Заводська 13 Сім23;Ковель вул. Заводська, 13;5cb6183d-749b-11df-b112-00215aee3ebe
f72f251b-ae06-11ed-80ec-000c29800ae7;Ковель-Мечнікова 10Б Сім23;Ковель вул. Мечнікова, 10Б;5cb6183d-749b-11df-b112-00215aee3ebe
f5ec56a7-53b5-11ee-80c0-000c2961d091;Ковель-Модеста Левицького 2А Rozetka;Ковель вул. Модеста Левицького, 2А;5cb6183d-749b-11df-b112-00215aee3ebe
d1177adf-ae05-11ed-80ec-000c29800ae7;Ковель-Незалежності 101 Сім23;Ковель вул. Незалежності, 101;5cb6183d-749b-11df-b112-00215aee3ebe
153bad6d-c2ea-11eb-80d5-000c29800ae7;Ковель-Українки Лесі 12 Сім23;Ковель бул. Українки Лесі, 12;5cb6183d-749b-11df-b112-00215aee3ebe
d9595567-32aa-11e8-80d0-1c98ec135261;Ковель-Українки Лесі 20 Жжук;Ковель бул. Українки Лесі, 20;5cb6183d-749b-11df-b112-00215aee3ebe
aa9f7a69-41d1-11ee-80c0-000c2961d091;Кодима-Соборна 104;Кодима вул. Соборна, 104;5cb61864-749b-11df-b112-00215aee3ebe
23e6b35c-4743-11ee-80c0-000c2961d091;Кодима-Соборна 39;Кодима вул. Соборна, 39;5cb61864-749b-11df-b112-00215aee3ebe
7d036e48-1aa9-11eb-80cd-000c29800ae7;Кожичі-Шевченка 18;Кожичі вул. Шевченка, 19;5cb6186c-749b-11df-b112-00215aee3ebe
e3dc5717-f90b-11eb-80d5-000c29800ae7;Козелець-Комсомольська 53/1;Козелець вул. Комсомольська, 53/1;5cb618a0-749b-11df-b112-00215aee3ebe
670a759d-7c98-11ee-80c1-000c2961d091;Козелець-Соборності 13;Козелець вул. Соборності, 13;5cb618a0-749b-11df-b112-00215aee3ebe
2eefdc55-b4fe-11e9-80e4-1c98ec135261;Козелець-Соборності 3;Козелець вул. Соборності, 3;5cb618a0-749b-11df-b112-00215aee3ebe
f1678860-431a-11ee-80c0-000c2961d091;Козин-Київська 39;Козин вул. Київська, 39;5cb618a8-749b-11df-b112-00215aee3ebe
beeef1aa-d630-11ee-80c4-000c2961d091;Козин-Партизанська 2А;Козин вул. Партизанська, 2А;5cb618a8-749b-11df-b112-00215aee3ebe
3511a383-9937-11eb-80d3-000c29800ae7;Козова-Грушевського 24;Козова вул. Грушевського, 24;5cb618ce-749b-11df-b112-00215aee3ebe
af59f41e-6a2e-11ec-80e4-000c29800ae7;Козятин-Героїв Майдану 15А;Козятин вул. Героїв Майдану (Леніна), 15А;5cb618da-749b-11df-b112-00215aee3ebe
e2ee2433-f6b3-11eb-80d5-000c29800ae7;Козятин-Грушевського Михайла 25;Козятин вул. Грушевського Михайла, 25;5cb618da-749b-11df-b112-00215aee3ebe
b49cc0ce-3d8d-11ed-80e9-000c29800ae7;Козятин-Січових Стрільців 12;Козятин вул. Січових Стрільців, 12;5cb618da-749b-11df-b112-00215aee3ebe
b02df0e8-e695-11ee-80c5-000c2961d091;Козятин-Січових Стрільців 14Б;Козятин вул. Січових Стрільців, 14Б;5cb618da-749b-11df-b112-00215aee3ebe
20e46c43-58eb-11ec-80e4-000c29800ae7;Колоденка-Зелена 55;Колоденка вул. Зелена, 55;5cb61909-749b-11df-b112-00215aee3ebe
e31337e9-a01e-11ee-80c4-000c2961d091;Колоденка-Рівненська 131;Колоденка вул. Рівненська, 131;5cb61909-749b-11df-b112-00215aee3ebe
f750e00b-1cdc-11eb-80cd-000c29800ae7;Коломия;Коломия вул. Антоненка-Давидовича, 3;5cb61933-749b-11df-b112-00215aee3ebe
6774c2dd-0c48-11ee-80bd-000c2961d091;Коломия-Валова 48 ELMAS;Коломия вул. Валова, 48;5cb61933-749b-11df-b112-00215aee3ebe
1f6e2650-31d1-11ee-80bd-000c2961d091;Коломия-Грушевського Михайла 13/1 МАРКО;Коломия Просп. Грушевського Михайла, 13/1;5cb61933-749b-11df-b112-00215aee3ebe
78c8561c-a79a-11e9-80e4-1c98ec135261;Коломия-Карпатська 142Б ОККО;Коломия вул. Карпатська, 142Б;5cb61933-749b-11df-b112-00215aee3ebe
7058e06a-30c4-11ec-80e3-000c29800ae7;Коломия-Моцарта 84А Щедрий маркет;Коломия вул. Моцарта, 84А;5cb61933-749b-11df-b112-00215aee3ebe
2f713191-3ada-11eb-80cf-000c29800ae7;Коломия-Русина 4 Мода;Коломия вул. Русина, 4;5cb61933-749b-11df-b112-00215aee3ebe
96f02f8e-a4a6-11ee-80c4-000c2961d091;Коломия-Чорновола В'ячеслава 21 VISA INFO;Коломия вул. Чорновола В'ячеслава, 21;5cb61933-749b-11df-b112-00215aee3ebe
95fea9c7-f1e5-11ec-80e7-000c29800ae7;Коломия-Шевченка Тараса 14/1 Rozetka;Коломия пл. Шевченка Тараса, 14/1;5cb61933-749b-11df-b112-00215aee3ebe
1993b0cb-aef7-11ee-80c4-000c2961d091;Коломия-Шевченка Тараса 22 lifecell;Коломия пл. Шевченка Тараса, 22;5cb61933-749b-11df-b112-00215aee3ebe
c829efd8-98a3-11eb-80d3-000c29800ae7;Компаніївка-Вишнева 11 А;Компаніївка вул. Вишнева (Калініна), 11А;5cb619ba-749b-11df-b112-00215aee3ebe
85378737-212e-11ec-80df-000c29800ae7;Компаніївка-Вишнева 32;Компаніївка вул. Вишнева (Калініна), 32;5cb619ba-749b-11df-b112-00215aee3ebe
d90b2935-f051-11eb-80d5-000c29800ae7;Конотоп-Конотопських Дивізій 24А;Конотоп пл. Конотопських Дивізій, 24А;5cb61a12-749b-11df-b112-00215aee3ebe
2a8d656b-da47-11eb-80d5-000c29800ae7;Конотоп-Конотопських Дивізій 28;Конотоп пл. Конотопських Дивізій, 28;5cb61a12-749b-11df-b112-00215aee3ebe
1f69c6b9-c30c-11ed-80ed-000c29800ae7;Конотоп-Лазаревського 21;Конотоп вул. Лазаревського, 21;5cb61a12-749b-11df-b112-00215aee3ebe
ddebad43-c7de-11ed-80ed-000c29800ae7;Конотоп-Миру 1;Конотоп пл. Миру, 1;5cb61a12-749b-11df-b112-00215aee3ebe
3ddb6d24-b36c-11e9-80e4-1c98ec135261;Конотоп-Миру просп. 27;Конотоп Просп. Миру, 27;5cb61a12-749b-11df-b112-00215aee3ebe
53ba17a8-5b97-11ed-80eb-000c29800ae7;Конотоп-Свободи 13;Конотоп вул. Свободи, 13;5cb61a12-749b-11df-b112-00215aee3ebe
2a8a031a-051a-11ee-80bd-000c2961d091;Копили-Стрельникова 1В;Копили вул. Стрельникова, 1В;5cb61a51-749b-11df-b112-00215aee3ebe
b33503a7-62a1-11e9-80df-1c98ec135261;Копичинці-Ринок 1;Копичинці вул. Ринок, 1;5cb61a5b-749b-11df-b112-00215aee3ebe
f3a7fb83-f880-11eb-80d5-000c29800ae7;Копичинці-Ринок 1 Жжук;Копичинці вул. Ринок, 1;5cb61a5b-749b-11df-b112-00215aee3ebe
7dd6ba71-887a-11ee-80c1-000c2961d091;Копичинці-Ринок 7;Копичинці вул. Ринок, 7;5cb61a5b-749b-11df-b112-00215aee3ebe
1d3a9a74-083c-11ed-80e7-000c29800ae7;Корець-Київська 49;Корець вул. Київська, 49;5cb61a7c-749b-11df-b112-00215aee3ebe
8cf3765d-53bb-11ee-80c0-000c2961d091;Корець-Київська 49А;Корець вул. Київська, 49А;5cb61a7c-749b-11df-b112-00215aee3ebe
1ab35bc9-5d09-11ee-80c0-000c2961d091;Корець-Київська 86А;Корець вул. Київська, 86А;5cb61a7c-749b-11df-b112-00215aee3ebe
4cd5f78c-9933-11eb-80d3-000c29800ae7;Королево-Злагоди 13;Королево вул. Злагоди, 13;5cb61acc-749b-11df-b112-00215aee3ebe
d29d4c66-870c-11eb-80d2-000c29800ae7;Короп-Успенська 8;Короп вул. Успенська, 8;5cb61ae4-749b-11df-b112-00215aee3ebe
bcc229b3-943f-11ee-80c1-000c2961d091;Коростень-Базарна 2Г;Коростень пл. Базарна, 2Г;5cb61af0-749b-11df-b112-00215aee3ebe
51c86927-dd58-11ea-80ca-000c29800ae7;Коростень-Базарна площа 1 з;Коростень пл. Базарна, 1з;5cb61af0-749b-11df-b112-00215aee3ebe
c816b8e7-758e-11ee-80c1-000c2961d091;Коростень-Героїв Чорнобиля 11;Коростень вул. Героїв Чорнобиля (Красіна), 11;5cb61af0-749b-11df-b112-00215aee3ebe
54296359-daec-11ee-80c4-000c2961d091;Коростень-Героїв Чорнобиля 27А;Коростень вул. Героїв Чорнобиля (Красіна), 27А;5cb61af0-749b-11df-b112-00215aee3ebe
7175dd20-e378-11ed-80ed-000c29800ae7;Коростень-Грушевського 17;Коростень вул. Грушевського, 17;5cb61af0-749b-11df-b112-00215aee3ebe
e2434eb3-95d7-11ee-80c1-000c2961d091;Коростень-Грушевського 33;Коростень вул. Грушевського, 33;5cb61af0-749b-11df-b112-00215aee3ebe
df7bda65-d0dc-11ea-80ca-000c29800ae7;Коростень-Грушевського 34;Коростень вул. Грушевського, 34;5cb61af0-749b-11df-b112-00215aee3ebe
fa61b8be-b365-11e9-80e4-1c98ec135261;Коростень-Сергія Кемського1 Б ;Коростень вул. Сергія Кемського (Жовтнева), 1Б;5cb61af0-749b-11df-b112-00215aee3ebe
537c4db7-3576-11ec-80e3-000c29800ae7;Коростень-Сосновського 25;Коростень вул. Сосновського, 25;5cb61af0-749b-11df-b112-00215aee3ebe
ef0ccd3b-e60c-11ee-80c5-000c2961d091;Коростень-Сосновського 29;Коростень вул. Сосновського, 29;5cb61af0-749b-11df-b112-00215aee3ebe
66a13818-943a-11ee-80c1-000c2961d091;Коростень-Шевченка 76;Коростень пров. Шевченка (Кірова), 11;5cb61af0-749b-11df-b112-00215aee3ebe
1adacad2-758f-11ee-80c1-000c2961d091;Коростишів-Київська 66;Коростишів вул. Київська, 66;5cb61af3-749b-11df-b112-00215aee3ebe
6f6ca7d4-da40-11ee-80c4-000c2961d091;Коростишів-Київська 87;Коростишів вул. Київська, 87;5cb61af3-749b-11df-b112-00215aee3ebe
c62abf93-8add-11ee-80c1-000c2961d091;Коростишів-Різдвяна 5;Коростишів вул. Різдвяна, 5;5cb61af3-749b-11df-b112-00215aee3ebe
471d5caf-d4ae-11ee-80c4-000c2961d091;Коростишів-Шевченка Тараса 68А;Коростишів вул. Шевченка Тараса, 68А;5cb61af3-749b-11df-b112-00215aee3ebe
10ab7c4b-eda0-11ed-80ed-000c29800ae7;Корсунь-Шевченківський-Бабушкіна 23;Корсунь-Шевченківський вул. Бабушкіна, 23;5cb61b0c-749b-11df-b112-00215aee3ebe
04937a57-b157-11ee-80c4-000c2961d091;Корсунь-Шевченківський-Шевченка 39;Корсунь-Шевченківський вул. Шевченка, 39;5cb61b0c-749b-11df-b112-00215aee3ebe
00fa0ba2-576c-11ec-80e4-000c29800ae7;Корсунь-Шевченківський-Ярослава Мудрого 25;Корсунь-Шевченківський вул. Ярослава Мудрого, 25;5cb61b0c-749b-11df-b112-00215aee3ebe
07f81b7b-cbee-11ee-80c4-000c2961d091;Косів-Небесної Сотні 16А;Косів вул. Небесної Сотні, 16А;5cb61b48-749b-11df-b112-00215aee3ebe
75421659-887c-11ee-80c1-000c2961d091;Косів-Незалежності 33А;Косів вул. Незалежності, 33А;5cb61b48-749b-11df-b112-00215aee3ebe
1071940a-8790-11ee-80c1-000c2961d091;Косів-Незалежності 5А;Косів вул. Незалежності, 5А;5cb61b48-749b-11df-b112-00215aee3ebe
e68cf39d-2e6b-11eb-80ce-000c29800ae7;Костопіль-1-го Травня 12;Костопіль вул. 1-го Травня, 12;5cb61b70-749b-11df-b112-00215aee3ebe
44b1cfce-9dc1-11eb-80d3-000c29800ae7;Костопіль-Грушевського 12;Костопіль вул. Грушевського, 12;5cb61b70-749b-11df-b112-00215aee3ebe
8ff4194d-758f-11ee-80c1-000c2961d091;Костопіль-Грушевського 29;Костопіль вул. Грушевського, 29;5cb61b70-749b-11df-b112-00215aee3ebe
93d7d273-3c12-11ee-80c0-000c2961d091;Костопіль-Заріччя 71А;Костопіль вул. Заріччя, 71А;5cb61b70-749b-11df-b112-00215aee3ebe
ae733b7d-594b-11ee-80c0-000c2961d091;Костопіль-Нова 1В;Костопіль вул. Нова, 1В;5cb61b70-749b-11df-b112-00215aee3ebe
a5ef4f05-7750-11eb-80d1-000c29800ae7;Костопіль-Нова 3 Д;Костопіль вул. Нова, 3Д;5cb61b70-749b-11df-b112-00215aee3ebe
b8188a41-5a15-11ed-80eb-000c29800ae7;Костопіль-Ольги Кобилянської  25А;Костопіль вул. Ольги Кобилянської (Островського М.), 25А;5cb61b70-749b-11df-b112-00215aee3ebe
0d0755d9-2e70-11eb-80ce-000c29800ae7;Костопіль-Рівненська 72;Костопіль вул. Рівненська, 72;5cb61b70-749b-11df-b112-00215aee3ebe
8af0ebf6-593a-11ee-80c0-000c2961d091;Костопіль-Руданського 4;Костопіль вул. Руданського, 4;5cb61b70-749b-11df-b112-00215aee3ebe
03c1aae8-53ec-11eb-80d0-000c29800ae7;Костопіль-Руданського 5;Костопіль вул. Руданського, 5;5cb61b70-749b-11df-b112-00215aee3ebe
1229668d-4eb3-11ec-80e3-000c29800ae7;Костопіль-Степанська 20Б;Костопіль вул. Степанська, 20Б;5cb61b70-749b-11df-b112-00215aee3ebe
12de133e-594c-11ee-80c0-000c2961d091;Костопіль-Степанська 8А;Костопіль вул. Степанська, 8А;5cb61b70-749b-11df-b112-00215aee3ebe
2d5b3b2c-a74d-11eb-80d3-000c29800ae7;Костянтинівка-Білоусова 4А;Костянтинівка вул. Білоусова, 4А;5cb61b90-749b-11df-b112-00215aee3ebe
6e3ad805-674d-11ee-80c0-000c2961d091;Костянтинівка-Громова 5А;Костянтинівка вул. Громова, 5А;5cb61b90-749b-11df-b112-00215aee3ebe
65b0f73f-68f7-11ee-80c1-000c2961d091;Костянтинівка-Космонавтів 7;Костянтинівка бул. Космонавтів, 7;5cb61b90-749b-11df-b112-00215aee3ebe
03ae6bc2-5c59-11ee-80c0-000c2961d091;Котляри-Безлюдівська 1;Котляри (Комунар) вул. Безлюдівська, 1;5cb619e0-749b-11df-b112-00215aee3ebe
6c34bf7f-166b-11ee-80bd-000c2961d091;Коцюбинське-Доківська 1 Rozetka;Коцюбинське вул. Доківська, 1;5cb61bf9-749b-11df-b112-00215aee3ebe
ac1fd025-d343-11eb-80d5-000c29800ae7;Коцюбинське-Пономарьова 26 Біле Сухе;Коцюбинське вул. Пономарьова, 26;5cb61bf9-749b-11df-b112-00215aee3ebe
38d1e979-68f5-11ee-80c1-000c2961d091;Краматорськ-Краматорський 3;Краматорськ бул. Краматорський, 3;5cb61c49-749b-11df-b112-00215aee3ebe
fda28450-911a-11ee-80c1-000c2961d091;Краматорськ-Краматорський 30;Краматорськ бул. Краматорський, 30;5cb61c49-749b-11df-b112-00215aee3ebe
15d07751-66ae-11ee-80c0-000c2961d091;Краматорськ-Паркова 30;Краматорськ вул. Паркова, 30;5cb61c49-749b-11df-b112-00215aee3ebe
a25d41d4-68f6-11ee-80c1-000c2961d091;Краматорськ-Стуса Василя 54;Краматорськ вул. Стуса Василя ( Соціалістична ), 54;5cb61c49-749b-11df-b112-00215aee3ebe
328466ae-68f6-11ee-80c1-000c2961d091;Краматорськ-Шкільна 5;Краматорськ вул. Шкільна, 5;5cb61c49-749b-11df-b112-00215aee3ebe
f556edbc-be25-11eb-80d5-000c29800ae7;Красилів вул. Грушевського, 82-а/9;Красилів вул. Грушевського, 82-а/9;5cb61c4d-749b-11df-b112-00215aee3ebe
9e8e5f9f-90da-11e8-80d4-1c98ec135261;Красилів-Булаєнка, 11;Красилів вул. Булаєнка, 11;5cb61c4d-749b-11df-b112-00215aee3ebe
49f46a38-f888-11ed-80ed-000c29800ae7;Красилів-Грушевського 84Б/3;Красилів вул. Грушевського, 84Б/3;5cb61c4d-749b-11df-b112-00215aee3ebe
787254e7-a1d0-11eb-80d3-000c29800ae7;Красилівка-Басова 28;Красилівка вул. Басова, 28;5cb61c50-749b-11df-b112-00215aee3ebe
9a2f30c5-e31e-11eb-80d5-000c29800ae7;Красилів-Хотовицького Степана 4;Красилів вул. Хотовицького Степана, 4;5cb61c4d-749b-11df-b112-00215aee3ebe
317d13a5-f063-11eb-80d5-000c29800ae7;Красилів-Центральна 40А;Красилів вул. Центральна, 40А;5cb61c4d-749b-11df-b112-00215aee3ebe
390df86e-772b-11ee-80c1-000c2961d091;Красноград-Бельовська 62;Красноград вул. Бельовська (Леніна), 62;62c3ccf3-749b-11df-b112-00215aee3ebe
9bc5b34d-f053-11eb-80d5-000c29800ae7;Красноград-Захисників України 109;Красноград вул. Захисників України (Щучки), 109;62c3ccf3-749b-11df-b112-00215aee3ebe
da5b64e2-ef42-11ed-80ed-000c29800ae7;Красноград-Московська 45 Б;Красноград вул. Незалежності (Московська), 45 Б;62c3ccf3-749b-11df-b112-00215aee3ebe
8d4b1277-3d6a-11ec-80e3-000c29800ae7;Красноград-Полтавська 91 Lifecell;Красноград вул. Полтавська, 91;62c3ccf3-749b-11df-b112-00215aee3ebe
696c3881-ab84-11ed-80ec-000c29800ae7;Краснокутськ-Миру 121;Краснокутськ вул. Миру, 121;62c3cd04-749b-11df-b112-00215aee3ebe
8cdb2884-be1e-11eb-80d5-000c29800ae7;Краснокутськ-Перемоги 27;Краснокутськ вул. Перемоги, 27;62c3cd04-749b-11df-b112-00215aee3ebe
cafaf3e2-d40d-11eb-80d5-000c29800ae7;Краснопілка-Діденка 16А;Краснопілка вул. Діденка, 16А;62c3cd0d-749b-11df-b112-00215aee3ebe
7080c2c2-1e7f-11eb-80cd-000c29800ae7;Кременець-Дубенська 132;Кременець вул. Дубенська, 132;62c3cd65-749b-11df-b112-00215aee3ebe
88ef229f-f695-11ea-80cc-000c29800ae7;Кременець-Дубенська 59 А;Кременець вул. Дубенська, 59А;62c3cd65-749b-11df-b112-00215aee3ebe
5d805546-43da-11e8-80d0-1c98ec135261;Кременець-Медова,5;Кременець вул. Медова, 5;62c3cd65-749b-11df-b112-00215aee3ebe
22ce3ef0-dac3-11ee-80c4-000c2961d091;Кременець-Петлюри 4В;Кременець вул. Петлюри, 4В;62c3cd65-749b-11df-b112-00215aee3ebe
5ba40d05-bc68-11ea-80ca-000c29800ae7;Кременчук;Кременчук вул. Київська, 9;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
2dcafbc4-8a29-11ee-80c1-000c2961d091;Кременчук-Білаша Олександра 2В Коблево;Кременчук вул. Білаша Олександра (Бєдного Дем'яна), 2В;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
db198648-78a3-11ee-80c1-000c2961d091;Кременчук-Давида Кострова 72 Дарина;Кременчук вул. Давида Кострова (Мічуріна), 72;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
8311c772-dac6-11ed-80ed-000c29800ae7;Кременчук-Київська 1/49 Оптика KLEIMAN;Кременчук вул. Київська, 1/49;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
263d9a26-ea82-11e9-80e7-1c98ec135261;Кременчук-Лесі Українки 10 ALVI SERVIS;Кременчук Просп. Лесі Українки, 10;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
05489799-4161-11ec-80e3-000c29800ae7;Кременчук-Лесі Українки 54 Леді;Кременчук Просп. Лесі Українки, 54;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
6363b171-f192-11ec-80e7-000c29800ae7;Кременчук-Лесі Українки 57 Rozetka;Кременчук Просп. Лесі Українки, 57;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
330920bb-e8e6-11e9-80e7-1c98ec135261;Кременчук-Лесі Українки 67 Теле Радіо Майстерня;Кременчук Просп. Лесі Українки, 67;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
7a4d99ad-ef2f-11e9-80e7-1c98ec135261;Кременчук-Лесі Українки 74 Канцтовари;Кременчук Просп. Лесі Українки, 74;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
99b9ca24-a3ff-11ee-80c4-000c2961d091;Кременчук-Лесі Українки 78Б Zeus Net;Кременчук Просп. Лесі Українки, 78Б;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
f1a5ce75-a7c0-11e9-80e4-1c98ec135261;Кременчук-Лесі Українки 97 ОККО;Кременчук Просп. Лесі Українки, 97;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
7e5ebed1-d8bf-11ea-80ca-000c29800ae7;Кременчук-Небесної Сотні 1А ОККО;Кременчук вул. Небесної Сотні (Пролетарська), 1А;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
8f1fe8c1-473f-11ee-80c0-000c2961d091;Кременчук-Першотравнева 23 Теплоклімат;Кременчук вул. Старшого Лейтенанта Кагала/Першотравнева, 23;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
45c69576-d952-11e9-80e4-1c98ec135261;Кременчук-Полковника Гегечкорі 52/2 СТО;Кременчук вул. Полковника Гегечкорі (1905 року), 52/2;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
14b9ad10-457c-11ed-80eb-000c29800ae7;Кременчук-Республіканська 134 Rozetka;Кременчук вул. Республіканська, 134;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
0df0c853-416b-11ec-80e3-000c29800ae7;Кременчук-Республіканська 73 Рибалка;Кременчук вул. Республіканська, 73;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
185ec8ce-018b-11eb-80cc-000c29800ae7;Кременчук-Свободи 172В Супутникове TV;Кременчук Просп. Свободи (60-річчя Жовтня), 172В;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
db5a6d42-08cd-11ed-80e7-000c29800ae7;Кременчук-Свободи 28/42 Rozetka;Кременчук Просп. Свободи (60-річчя Жовтня), 28/42;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
353118ae-9ff0-11ee-80c4-000c2961d091;Кременчук-Свободи 69А Ломбард;Кременчук Просп. Свободи (60-річчя Жовтня), 69А;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
2c67858d-bb6e-11ee-80c4-000c2961d091;Кременчук-Свободи 83/1 Rozetka;Кременчук Просп. Свободи (60-річчя Жовтня), 83/1;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
1258a8cc-5aa0-11ed-80eb-000c29800ae7;Кременчук-Сердюка Ігоря 15 Rozetka;Кременчук вул. Сердюка Ігоря, 15;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
95b5e431-b116-11ed-80ec-000c29800ae7;Кременчук-Соборна 15/42 Lifecell;Кременчук вул. Соборна (Леніна), 15/42;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
1842f6a2-1231-11ec-80da-000c29800ae7;Кременчук-Соборна 16/9 AVANTI;Кременчук вул. Соборна (Леніна), 16/9;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
813d72ee-abbe-11ed-80ec-000c29800ae7;Кременчук-Соборна 28/40 Ломбард;Кременчук вул. Соборна (Леніна), 28/40;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
25719957-daca-11ed-80ed-000c29800ae7;Кременчук-Соборна 9/16 Оптика KLEIMAN;Кременчук вул. Соборна (Леніна), 9/16;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
8a1bdf5b-466d-11ee-80c0-000c2961d091;Кременчук-Тараса Бульби 7А Фітнес Центр;Кременчук вул. Тараса Бульби (Карнаухова), 7А;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
6921a7c9-bcda-11ed-80ed-000c29800ae7;Кременчук-Театральна 27А Lifecell;Кременчук вул. Театральна (Воровського), 27А;2e1bd8f3-19fe-11df-b41f-00215aee3ebe
423f16c8-f04b-11eb-80d5-000c29800ae7;Криве Озеро-1-го Травня 24А;Криве Озеро вул. 1-го Травня, 24А;62c3cd98-749b-11df-b112-00215aee3ebe
a4274f5d-c8be-11ed-80ed-000c29800ae7;Криве Озеро-Калантирська 1;Криве Озеро вул. Калантирська, 1;62c3cd98-749b-11df-b112-00215aee3ebe
d0a207f0-0115-11df-b41f-00215aee3ebe;Кривий Ріг;Кривий Ріг вул. Окружна, 125;62c3cda0-749b-11df-b112-00215aee3ebe
6b369be3-0128-11ee-80bd-000c2961d091;Кривий Ріг-200-річчя Кривого Рогу 24 Сирне кор.;Кривий Ріг Просп. 200-річчя Кривого Рогу, 24;62c3cda0-749b-11df-b112-00215aee3ebe
8a46a5b7-1c47-11ec-80df-000c29800ae7;Кривий Ріг-200-річчя Кривого Рогу 6 Делві;Кривий Ріг Просп. 200-річчя Кривого Рогу, 6, прим. 70;62c3cda0-749b-11df-b112-00215aee3ebe
39c2cee0-727c-11ee-80c1-000c2961d091;Кривий Ріг-5-й Зарічний 11К Rozetka;Кривий Ріг мікр. 5-й Зарічний, 11К;62c3cda0-749b-11df-b112-00215aee3ebe
b3342fad-d8ea-11eb-80d5-000c29800ae7;Кривий Ріг-5-й Зарічний 20, прим.39 Ломбард;Кривий Ріг мікр. 5-й Зарічний, 20, прим.39;62c3cda0-749b-11df-b112-00215aee3ebe
f2a76a9b-ff58-11e7-80cd-1c98ec135261;Кривий Ріг-5-й Зарічний 39А style-baby.com;Кривий Ріг мікр. 5-й Зарічний, 39А;62c3cda0-749b-11df-b112-00215aee3ebe
274a55b6-6de2-11ea-80c6-000c29800ae7;Кривий Ріг-5-й Зарічний 87 Пік;Кривий Ріг мікр. 5-й Зарічний, 87;62c3cda0-749b-11df-b112-00215aee3ebe
afc3977c-48d1-11ea-80c6-000c29800ae7;Кривий Ріг-5-й Зарічний 97 ОККО;Кривий Ріг мікр. 5-й Зарічний, 97;62c3cda0-749b-11df-b112-00215aee3ebe
7e68f632-d977-11eb-80d5-000c29800ae7;Кривий Ріг-Адмірала Головка 40/1 Ломбард;Кривий Ріг вул. Адмірала Головка, 40/1;62c3cda0-749b-11df-b112-00215aee3ebe
e38bde69-b5f4-11ee-80c4-000c2961d091;Кривий Ріг-Адмірала Головка 40А Andi;Кривий Ріг вул. Адмірала Головка, 40А;62c3cda0-749b-11df-b112-00215aee3ebe
e1b279e7-0581-11ec-80d6-000c29800ae7;Кривий Ріг-Адмірала Головка 45 Зоорукавичка;Кривий Ріг вул. Адмірала Головка, 45;62c3cda0-749b-11df-b112-00215aee3ebe
a97fc11d-6c12-11ee-80c1-000c2961d091;Кривий Ріг-Алмазна 18 Сирне королівство;Кривий Ріг вул. Алмазна (Погребняка), 18;62c3cda0-749b-11df-b112-00215aee3ebe
ae17f043-1c50-11ec-80df-000c29800ae7;Кривий Ріг-Алмазна 21Б Делві;Кривий Ріг вул. Алмазна (Погребняка), 21Б;62c3cda0-749b-11df-b112-00215aee3ebe
c4b42d64-cc0d-11ee-80c4-000c2961d091;Кривий Ріг-Алмазна 22Б Andi;Кривий Ріг вул. Алмазна (Погребняка), 22Б;62c3cda0-749b-11df-b112-00215aee3ebe
cf6a4141-ea63-11e9-80e7-1c98ec135261;Кривий Ріг-Алмазна 22Б На звязку;Кривий Ріг вул. Алмазна (Погребняка), 22Б;62c3cda0-749b-11df-b112-00215aee3ebe
ec7b675c-7590-11ee-80c1-000c2961d091;Кривий Ріг-Вечірній 33 Сільпо;Кривий Ріг бул. Вечірній, 33;62c3cda0-749b-11df-b112-00215aee3ebe
1c083416-7590-11ee-80c1-000c2961d091;Кривий Ріг-Визволення 1К Сільпо;Кривий Ріг пл. Визволення, 1К;62c3cda0-749b-11df-b112-00215aee3ebe
afba8eaf-63ff-11ed-80ec-000c29800ae7;Кривий Ріг-Військове містечко 35 1Б Делві;Кривий Ріг мікр. Військове містечко 35, 1Б;62c3cda0-749b-11df-b112-00215aee3ebe
28589a6d-1b9c-11ec-80df-000c29800ae7;Кривий Ріг-Військового тилу 7 Делві;Кривий Ріг вул. Військового тилу ( Уфимська ), 7;62c3cda0-749b-11df-b112-00215aee3ebe
50d07ae3-cbfb-11ea-80ca-000c29800ae7;Кривий Ріг-Водоп'янова 5 Игрушки;Кривий Ріг вул. Водоп'янова, 5;62c3cda0-749b-11df-b112-00215aee3ebe
31424f6a-a7c0-11e9-80e4-1c98ec135261;Кривий Ріг-Волгоградська 15 ОККО;Кривий Ріг вул. Волгоградська, 15;62c3cda0-749b-11df-b112-00215aee3ebe
5bb60bc3-f12b-11ec-80e7-000c29800ae7;Кривий Ріг-Волгоградська 9 Rozetka;Кривий Ріг вул. Волгоградська, 9;62c3cda0-749b-11df-b112-00215aee3ebe
6c3f741a-911d-11ee-80c1-000c2961d091;Кривий Ріг-Володимира Великого 38 Зоомагазин;Кривий Ріг вул. Володимира Великого (Мелешкіна), 38;62c3cda0-749b-11df-b112-00215aee3ebe
1371aa04-9b44-11ee-80c1-000c2961d091;Кривий Ріг-Гагаріна 38/3 лотомаркет;Кривий Ріг Просп. Гагаріна, 38/3;62c3cda0-749b-11df-b112-00215aee3ebe
aab07852-0127-11ee-80bd-000c2961d091;Кривий Ріг-Гагаріна 54В Сирне королівство;Кривий Ріг Просп. Гагаріна, 54В;62c3cda0-749b-11df-b112-00215aee3ebe
ba3c0aa8-e6a0-11ee-80c5-000c2961d091;Кривий Ріг-Генерала Радієвського 15А Remedium;Кривий Ріг вул. Генерала Радієвського (Тинка), 15А;62c3cda0-749b-11df-b112-00215aee3ebe
81f0204b-1c49-11ec-80df-000c29800ae7;Кривий Ріг-Героїв АТО 108А Делві;Кривий Ріг вул. Героїв АТО ( Димитрова ), 108А;62c3cda0-749b-11df-b112-00215aee3ebe
64321087-7590-11ee-80c1-000c2961d091;Кривий Ріг-Героїв АТО 30А Сільпо;Кривий Ріг вул. Героїв АТО ( Димитрова ), 30А;62c3cda0-749b-11df-b112-00215aee3ebe
df37c3cb-fa1d-11ed-80ed-000c29800ae7;Кривий Ріг-Героїв Маріуполя 47Б Rozetka;Кривий Ріг вул. Героїв Маріуполя ( Головка Андрія ), 47Б;62c3cda0-749b-11df-b112-00215aee3ebe
8b3c6076-0582-11ec-80d6-000c29800ae7;Кривий Ріг-Гетьманська 74 Зоорукавичка;Кривий Ріг вул. Гетьманська (Іллічівська), 74;62c3cda0-749b-11df-b112-00215aee3ebe
f19d2617-d97a-11eb-80d5-000c29800ae7;Кривий Ріг-Гірницький 1 Ломбард;Кривий Ріг мікр. Гірницький, 1;62c3cda0-749b-11df-b112-00215aee3ebe
123d19b0-f617-11ed-80ed-000c29800ae7;Кривий Ріг-Гірницький 36 Чарівні Іграшки;Кривий Ріг мікр. Гірницький, 36;62c3cda0-749b-11df-b112-00215aee3ebe
a7b70f65-d17d-11ea-80ca-000c29800ae7;Кривий Ріг-Дніпровське 2 Ломбард;Кривий Ріг шосе Дніпровське (Дніпропетровське), 2;62c3cda0-749b-11df-b112-00215aee3ebe
c3db39a6-a64e-11ee-80c4-000c2961d091;Кривий Ріг-Едуарда Фукса 18 прим.68 Делві;Кривий Ріг вул. Едуарда Фукса (Тухачевського), 18;62c3cda0-749b-11df-b112-00215aee3ebe
e995db51-d180-11ea-80ca-000c29800ae7;Кривий Ріг-Едуарда Фукса 61 Ломбард;Кривий Ріг вул. Едуарда Фукса (Тухачевського), 61;62c3cda0-749b-11df-b112-00215aee3ebe
39b8fb52-11bd-11ee-80bd-000c2961d091;Кривий Ріг-Зінчевського 12 Rozetka;Кривий Ріг вул. Зінчевського (20-го Партз'їзду), 12;62c3cda0-749b-11df-b112-00215aee3ebe
0d4e252f-1b9b-11ec-80df-000c29800ae7;Кривий Ріг-Кобилянського 150Г Делві;Кривий Ріг вул. Кобилянського, 150Г;62c3cda0-749b-11df-b112-00215aee3ebe
51ecb257-d8e8-11eb-80d5-000c29800ae7;Кривий Ріг-Колачевського 4, прим.58 Ломбард;Кривий Ріг вул. Колачевського, 4, прим.58;62c3cda0-749b-11df-b112-00215aee3ebe
183d0450-f12c-11ec-80e7-000c29800ae7;Кривий Ріг-Космонавтів 3А Rozetka;Кривий Ріг вул. Космонавтів, 3А;62c3cda0-749b-11df-b112-00215aee3ebe
94c97952-6f49-11ee-80c1-000c2961d091;Кривий Ріг-Космонавтів 7А Ломбард;Кривий Ріг вул. Космонавтів, 7А;62c3cda0-749b-11df-b112-00215aee3ebe
443e222e-6f48-11ee-80c1-000c2961d091;Кривий Ріг-Криворіжсталі 13 Ломбард;Кривий Ріг вул. Криворіжсталі (Орджонікідзе), 13;62c3cda0-749b-11df-b112-00215aee3ebe
5304b839-8272-11ec-80e4-000c29800ae7;Кривий Ріг-Криворіжсталі 9 СЦ 2Masters;Кривий Ріг вул. Криворіжсталі (Орджонікідзе), 9;62c3cda0-749b-11df-b112-00215aee3ebe
86fc4aab-1672-11ee-80bd-000c2961d091;Кривий Ріг-Криворіжсталі 9В Rozetka;Кривий Ріг вул. Криворіжсталі (Орджонікідзе), 9В;62c3cda0-749b-11df-b112-00215aee3ebe
6f5945cd-b601-11ee-80c4-000c2961d091;Кривий Ріг-Лермонтова 24Б Andi;Кривий Ріг вул. Лермонтова, 24Б;62c3cda0-749b-11df-b112-00215aee3ebe
b2405ea1-d17f-11ea-80ca-000c29800ae7;Кривий Ріг-Лермонтова 35/3 Ломбард;Кривий Ріг вул. Лермонтова, 35/3;62c3cda0-749b-11df-b112-00215aee3ebe
a33da53f-7590-11ee-80c1-000c2961d091;Кривий Ріг-Лермонтова 37 Сільпо;Кривий Ріг вул. Лермонтова, 37;62c3cda0-749b-11df-b112-00215aee3ebe
3ca09603-0582-11ec-80d6-000c29800ae7;Кривий Ріг-Лісового 10 Зоорукавичка;Кривий Ріг вул. Лісового, 10;62c3cda0-749b-11df-b112-00215aee3ebe
210297d1-1c4b-11ec-80df-000c29800ae7;Кривий Ріг-Матусевича 43 Делві;Кривий Ріг вул. Матусевича ( 22-го Партз'їзду ), 43;62c3cda0-749b-11df-b112-00215aee3ebe
f1c817aa-1c4f-11ec-80df-000c29800ae7;Кривий Ріг-Мерецкова 6 Делві;Кривий Ріг вул. Мерецкова, 6;62c3cda0-749b-11df-b112-00215aee3ebe
7f7a2b29-1c46-11ec-80df-000c29800ae7;Кривий Ріг-Миколи Світальського 15 Делві;Кривий Ріг вул. Миколи Світальського (Коротченка), 15;62c3cda0-749b-11df-b112-00215aee3ebe
53f84d63-ff3a-11eb-80d6-000c29800ae7;Кривий Ріг-Миру 33 Європошта;Кривий Ріг Просп. Миру, 33;62c3cda0-749b-11df-b112-00215aee3ebe
b8873ff7-3abb-11eb-80cf-000c29800ae7;Кривий Ріг-Миру 46 Karcher;Кривий Ріг Просп. Миру, 46;62c3cda0-749b-11df-b112-00215aee3ebe
eaa4733b-89fd-11ee-80c1-000c2961d091;Кривий Ріг-Мусоргського 17 Rozetka;Кривий Ріг вул. Мусоргського, 17;62c3cda0-749b-11df-b112-00215aee3ebe
97291e1f-d974-11eb-80d5-000c29800ae7;Кривий Ріг-Незалежності України 16А Ломбард;Кривий Ріг вул. Незалежності України, 16А, прим.1;62c3cda0-749b-11df-b112-00215aee3ebe
d970c55c-26dc-11ee-80bd-000c2961d091;Кривий Ріг-Нікопольське 1Г ASIA PARTS;Кривий Ріг шосе Нікопольське, 1Г;62c3cda0-749b-11df-b112-00215aee3ebe
9efaf07f-1b9d-11ec-80df-000c29800ae7;Кривий Ріг-Ногіна 12В Делві;Кривий Ріг вул. Ногіна, 12В;62c3cda0-749b-11df-b112-00215aee3ebe
f9b63eab-5dfd-11ee-80c0-000c2961d091;Кривий Ріг-Перемоги 37В Rozetka;Кривий Ріг Просп. Перемоги, 37В;62c3cda0-749b-11df-b112-00215aee3ebe
0f89c86f-0128-11ee-80bd-000c2961d091;Кривий Ріг-Перемоги 37В Сирне королівство;Кривий Ріг Просп. Перемоги, 37В;62c3cda0-749b-11df-b112-00215aee3ebe
37fbabb7-f12d-11ec-80e7-000c29800ae7;Кривий Ріг-Петра Калнишевського 2 Rozetka;Кривий Ріг вул. Петра Калнишевського (Харитонова), 2;62c3cda0-749b-11df-b112-00215aee3ebe
dd6d4fb5-0128-11ee-80bd-000c2961d091;Кривий Ріг-Петра Калнишевського 4 Сирне кор.;Кривий Ріг вул. Петра Калнишевського (Харитонова), 4;62c3cda0-749b-11df-b112-00215aee3ebe
fa0d030c-e63c-11eb-80d5-000c29800ae7;Кривий Ріг-Петра Калнишевського 6 СD BOX;Кривий Ріг вул. Петра Калнишевського (Харитонова), 6;62c3cda0-749b-11df-b112-00215aee3ebe
2faf4e7c-8a21-11ee-80c1-000c2961d091;Кривий Ріг-Південний 17 Ломбард;Кривий Ріг Просп. Південний, 17;62c3cda0-749b-11df-b112-00215aee3ebe
621006dc-6340-11e8-80d4-1c98ec135261;Кривий Ріг-Південний 23 СЦ Моби-док;Кривий Ріг Просп. Південний, 23;62c3cda0-749b-11df-b112-00215aee3ebe
c0739a96-ab91-11ed-80ec-000c29800ae7;Кривий Ріг-Південний 34 Rozetka;Кривий Ріг Просп. Південний, 34;62c3cda0-749b-11df-b112-00215aee3ebe
659b27b1-342b-11e9-80dd-1c98ec135261;Кривий Ріг-Пісочна 7А ОККО;Кривий Ріг вул. Пісочна, 7А;62c3cda0-749b-11df-b112-00215aee3ebe
9b533c30-8720-11eb-80d2-000c29800ae7;Кривий Ріг-Поштовий 98А VIANOR;Кривий Ріг Просп. Поштовий (Маркса Карла), 98А;62c3cda0-749b-11df-b112-00215aee3ebe
34e848ce-d61f-11ee-80c4-000c2961d091;Кривий Ріг-просп.Гагаріна 13 Anime Lab;Кривий Ріг пров. Гагаріна, 13;62c3cda0-749b-11df-b112-00215aee3ebe
7f6815ce-1c4e-11ec-80df-000c29800ae7;Кривий Ріг-Рахманінова 4 Делві;Кривий Ріг вул. Рахманінова, 4;62c3cda0-749b-11df-b112-00215aee3ebe
ad89bc6d-1c51-11ec-80df-000c29800ae7;Кривий Ріг-Світлогірська 105, прим. 46 Делві;Кривий Ріг вул. Світлогірська, 105, прим. 46;62c3cda0-749b-11df-b112-00215aee3ebe
988f4666-a3b4-11ed-80ec-000c29800ae7;Кривий Ріг-Серафимовича 64 Моб Аксесуари;Кривий Ріг вул. Серафимовича, 64;62c3cda0-749b-11df-b112-00215aee3ebe
eaf52db3-2c93-11ee-80bd-000c2961d091;Кривий Ріг-Січеславська 1 Rozetka;Кривий Ріг вул. Січеславська (Кремлівська), 1;62c3cda0-749b-11df-b112-00215aee3ebe
63d8cd28-d975-11eb-80d5-000c29800ae7;Кривий Ріг-Соборності 10 Ломбард;Кривий Ріг вул. Соборності ( Косіора ), 10;62c3cda0-749b-11df-b112-00215aee3ebe
2e1f68cc-166d-11ee-80bd-000c2961d091;Кривий Ріг-Соборності 24 Rozetka;Кривий Ріг вул. Соборності ( Косіора ), 24;62c3cda0-749b-11df-b112-00215aee3ebe
5bbf2aac-636e-11ee-80c0-000c2961d091;Кривий Ріг-Соборності 29Б Lifecell;Кривий Ріг вул. Соборності ( Косіора ), 29Б;62c3cda0-749b-11df-b112-00215aee3ebe
b9ec2e49-e5bb-11ed-80ed-000c29800ae7;Кривий Ріг-Соборності 35 АСЦ Гарант-сервис;Кривий Ріг вул. Соборності ( Косіора ), 35;62c3cda0-749b-11df-b112-00215aee3ebe
08aa392c-bb7e-11ee-80c4-000c2961d091;Кривий Ріг-Соборності 42 прим.17 Парус;Кривий Ріг вул. Соборності ( Косіора ), 42;62c3cda0-749b-11df-b112-00215aee3ebe
fbeee65f-d703-11ee-80c4-000c2961d091;Кривий Ріг-Соборності 73 Ломбард Центральний;Кривий Ріг вул. Соборності ( Косіора ), 73;62c3cda0-749b-11df-b112-00215aee3ebe
ffad3093-f12d-11ec-80e7-000c29800ae7;Кривий Ріг-Співдружності 66А Rozetka;Кривий Ріг вул. Співдружності, 66А;62c3cda0-749b-11df-b112-00215aee3ebe
9c341f93-f616-11ed-80ed-000c29800ae7;Кривий Ріг-Співдружності 8 Чарівні Іграшки;Кривий Ріг вул. Співдружності, 8;62c3cda0-749b-11df-b112-00215aee3ebe
b1ab4fcd-1c4b-11ec-80df-000c29800ae7;Кривий Ріг-Тбіліська 4 Делві;Кривий Ріг вул. Тбіліська, 4;62c3cda0-749b-11df-b112-00215aee3ebe
6b8e64a0-e058-11ee-80c4-000c2961d091;Кривий Ріг-Терещенка 6 зоомагазин;Кривий Ріг вул. Терещенка (Федоренка), 6;62c3cda0-749b-11df-b112-00215aee3ebe
feece31e-1c46-11ec-80df-000c29800ae7;Кривий Ріг-Тесленка 22/67 Делві;Кривий Ріг вул. Тесленка, 22/67;62c3cda0-749b-11df-b112-00215aee3ebe
b7b1ced4-49c9-11eb-80d0-000c29800ae7;Кривий Ріг-Федора Караманиць 24 Віпоінт;Кривий Ріг вул. Федора Караманиць (Ватутіна), 24;62c3cda0-749b-11df-b112-00215aee3ebe
37e70295-d97a-11eb-80d5-000c29800ae7;Кривий Ріг-Федора Караманиць 24, прим.14 Ломбард;Кривий Ріг вул. Федора Караманиць (Ватутіна), 24, прим.14;62c3cda0-749b-11df-b112-00215aee3ebe
c65893ed-d17c-11ea-80ca-000c29800ae7;Кривий Ріг-Федора Караманиць 80 Ломбард;Кривий Ріг вул. Федора Караманиць (Ватутіна), 80;62c3cda0-749b-11df-b112-00215aee3ebe
073dca0c-1c4e-11ec-80df-000c29800ae7;Кривий Ріг-Ціолковського 16А Делві;Кривий Ріг вул. Ціолковського, 16А;62c3cda0-749b-11df-b112-00215aee3ebe
cbf4afcb-e640-11eb-80d5-000c29800ae7;Кривий Ріг-Ювілейний 4/1 Зоолавка Їжак маркет;Кривий Ріг мікр. Ювілейний, 4/1;62c3cda0-749b-11df-b112-00215aee3ebe
9e69ece2-c0da-11ee-80c4-000c2961d091;Крижопіль-Героїв України 65;Крижопіль вул. Героїв України (Карла Маркса), 65;62c3cdd2-749b-11df-b112-00215aee3ebe
e46985ec-ee7f-11ed-80ed-000c29800ae7;Кринички-Героїв Чорнобиля 32;Кринички вул. Героїв Чорнобиля, 32;62c3cdfa-749b-11df-b112-00215aee3ebe
82f38250-e02d-11ed-80ed-000c29800ae7;Кринички-Героїв Чорнобиля 7Б;Кринички вул. Героїв Чорнобиля, 7Б;62c3cdfa-749b-11df-b112-00215aee3ebe
f6e4e938-5ab1-11e9-80df-1c98ec135261;Кролевець-Соборна 2 А;Кролевець вул. Соборна, 2А;62c3ce37-749b-11df-b112-00215aee3ebe
d5323fdc-b83b-11ed-80ed-000c29800ae7;Кролевець-Українки Лесі 2А;Кролевець вул. Українки Лесі, 2А;62c3ce37-749b-11df-b112-00215aee3ebe
d0a207f3-0115-11df-b41f-00215aee3ebe;Кропивницький (Кіровоград);Кропивницький вул. Аерофлотська, 28;5cb61730-749b-11df-b112-00215aee3ebe
0f9e3481-d172-11ee-80c4-000c2961d091;Кропивницький- Тамма 4 KALUSH;Кропивницький вул. Академіка Тамма, 5;5cb61730-749b-11df-b112-00215aee3ebe
d49262ca-d220-11ee-80c4-000c2961d091;Кропивницький- Чобану 22 Imaginarium;Кропивницький вул. Степана Чобану, 22;5cb61730-749b-11df-b112-00215aee3ebe
1e02077e-a65c-11ee-80c4-000c2961d091;Кропивницький-Академіка Тамма 23 Смак;Кропивницький вул. Академіка Тамма, 23;5cb61730-749b-11df-b112-00215aee3ebe
282a8266-e425-11ed-80ed-000c29800ae7;Кропивницький-Академіка Тамма 4 TEAKAVA;Кропивницький вул. Академіка Тамма, 4;5cb61730-749b-11df-b112-00215aee3ebe
e9edcc9f-90b9-11e8-80d4-1c98ec135261;Кропивницький-Бєляєва 11 Побутові дрібниці;Кропивницький вул. Френчка Віктора (Бєляєва), 11;5cb61730-749b-11df-b112-00215aee3ebe
7f77de06-d170-11ee-80c4-000c2961d091;Кропивницький-Біляєва 2 Imaginarium;Кропивницький вул. Біляєва, 2;5cb61730-749b-11df-b112-00215aee3ebe
a898cf4c-9833-11ee-80c1-000c2961d091;Кропивницький-Бородянська 1 Бистро;Кропивницький вул. Бородянська (кол.Алтайська), 1;5cb61730-749b-11df-b112-00215aee3ebe
4b42699d-ff3d-11eb-80d6-000c29800ae7;Кропивницький-Велика Перспективна 16/9 Європошта;Кропивницький вул. Велика Перспективна (Карла Маркса), 16/9;5cb61730-749b-11df-b112-00215aee3ebe
aef948d6-5f43-11ec-80e4-000c29800ae7;Кропивницький-Велика Перспективна 18/14 Крамниця;Кропивницький вул. Велика Перспективна (Карла Маркса), 18/14;5cb61730-749b-11df-b112-00215aee3ebe
429107b2-5c1e-11ec-80e4-000c29800ae7;Кропивницький-Велика Перспективна 20 Ветмедик;Кропивницький вул. Велика Перспективна (Карла Маркса), 20;5cb61730-749b-11df-b112-00215aee3ebe
89c9a89e-f260-11ec-80e7-000c29800ae7;Кропивницький-Велика Перспективна 27 Rozetka;Кропивницький вул. Велика Перспективна (Карла Маркса), 27;5cb61730-749b-11df-b112-00215aee3ebe
2ea16f5f-e557-11eb-80d5-000c29800ae7;Кропивницький-Велика Перспективна 38А Indicative;Кропивницький вул. Велика Перспективна (Карла Маркса), 38А;5cb61730-749b-11df-b112-00215aee3ebe
f0cd92df-2c27-11ec-80e3-000c29800ae7;Кропивницький-Велика Перспективна 44/66 Service;Кропивницький вул. Велика Перспективна (Карла Маркса), 44/66;5cb61730-749b-11df-b112-00215aee3ebe
be7a5d1f-e038-11e9-80e6-1c98ec135261;Кропивницький-Велика Перспективна 62 Світ Техніки;Кропивницький вул. Велика Перспективна (Карла Маркса), 62;5cb61730-749b-11df-b112-00215aee3ebe
c152a0da-e034-11ed-80ed-000c29800ae7;Кропивницький-Верхня Пермська 60/1 Здорова Вода;Кропивницький вул. Верхня Пермська, 60/1;5cb61730-749b-11df-b112-00215aee3ebe
673151b5-3689-11e9-80dd-1c98ec135261;Кропивницький-Винниченка 1Б ОККО;Кропивницький Просп. Винниченка, 1Б;5cb61730-749b-11df-b112-00215aee3ebe
45783260-f082-11eb-80d5-000c29800ae7;Кропивницький-Віктора Чміленка 181 будСтроиса;Кропивницький вул. Віктора Чміленка (Московська), 181;5cb61730-749b-11df-b112-00215aee3ebe
df6c81a4-48dc-11ea-80c6-000c29800ae7;Кропивницький-Вокзальна 14А ОККО;Кропивницький вул. Вокзальна, 14А;5cb61730-749b-11df-b112-00215aee3ebe
801fbfc0-c264-11e8-80d7-1c98ec135261;Кропивницький-Вокзальна 31 Куб;Кропивницький вул. Вокзальна, 31;5cb61730-749b-11df-b112-00215aee3ebe
33eeaf4e-c4e2-11e9-80e4-1c98ec135261;Кропивницький-Вокзальна 33в Югос;Кропивницький вул. Вокзальна, 33в;5cb61730-749b-11df-b112-00215aee3ebe
5523979b-5f44-11ec-80e4-000c29800ae7;Кропивницький-Вокзальна 39Н Крамниця;Кропивницький вул. Вокзальна, 39Н;5cb61730-749b-11df-b112-00215aee3ebe
c0b5aaa5-09b9-11e9-80d9-1c98ec135261;Кропивницький-Генерала Жадова 21/3 Internet Club;Кропивницький вул. Генерала Жадова, 21/3;5cb61730-749b-11df-b112-00215aee3ebe
e89a0c4b-c832-11ea-80ca-000c29800ae7;Кропивницький-Генерала Жадова 23А Іграшки;Кропивницький вул. Генерала Жадова, 23А;5cb61730-749b-11df-b112-00215aee3ebe
83c721a9-ea2d-11eb-80d5-000c29800ae7;Кропивницький-Героїв Маріуполя 106 Автозапчастини;Кропивницький вул. Героїв Маріуполя (кол.Генерала Родімцева, 106;5cb61730-749b-11df-b112-00215aee3ebe
56cb308c-5ea3-11ee-80c0-000c2961d091;Кропивницький-Героїв України 30 Крамниця;Кропивницький вул. Героїв України ( Героїв Сталінграда ), 30;5cb61730-749b-11df-b112-00215aee3ebe
12fa82b4-5a53-11eb-80d0-000c29800ae7;Кропивницький-Героїв України 8 Салон Штор;Кропивницький вул. Героїв України ( Героїв Сталінграда ), 8;5cb61730-749b-11df-b112-00215aee3ebe
60cd6b0f-9385-11ee-80c1-000c2961d091;Кропивницький-Героїв-рятувальників 11Г Kids;Кропивницький вул. Героїв-рятувальників (кол.Волкова), 11Г;5cb61730-749b-11df-b112-00215aee3ebe
75907107-a65b-11ee-80c4-000c2961d091;Кропивницький-Глінки 60/20 Смак;Кропивницький вул. Глінки, 60/20;5cb61730-749b-11df-b112-00215aee3ebe
404ce3bf-2043-11ec-80df-000c29800ae7;Кропивницький-Гоголя Миколи 109/58 Аромат Дерева;Кропивницький вул. Гоголя Миколи, 109/58;5cb61730-749b-11df-b112-00215aee3ebe
200ae5be-88f5-11ec-80e4-000c29800ae7;Кропивницький-Єгорова 56 Крамниця;Кропивницький вул. Єгорова, 56;5cb61730-749b-11df-b112-00215aee3ebe
fbe89d57-1c44-11ec-80df-000c29800ae7;Кропивницький-Кільцева 13/63 Делві;Кропивницький вул. Кільцева, 13/63;5cb61730-749b-11df-b112-00215aee3ebe
44c29544-9834-11ee-80c1-000c2961d091;Кропивницький-Козака Мамая 2Б Бистро;Кропивницький пров. Козака Мамая, 2Б;5cb61730-749b-11df-b112-00215aee3ebe
1d18e263-5f42-11ec-80e4-000c29800ae7;Кропивницький-Комарова Космонавта 12А ФормулаСмаку;Кропивницький вул. Любомира Гузара (Комарова Космонавта), 12-А;5cb61730-749b-11df-b112-00215aee3ebe
9869c479-d171-11ee-80c4-000c2961d091;Кропивницький-Короленка  69а Imaginarium;Кропивницький вул. Короленка, 69а;5cb61730-749b-11df-b112-00215aee3ebe
145686b3-a7bc-11e9-80e4-1c98ec135261;Кропивницький-Короленка 137 ОККО;Кропивницький вул. Короленка, 137;5cb61730-749b-11df-b112-00215aee3ebe
e52ed665-f260-11ec-80e7-000c29800ae7;Кропивницький-Короленка 32А Rozetka;Кропивницький вул. Короленка, 32А;5cb61730-749b-11df-b112-00215aee3ebe
a197d4a3-30c8-11ec-80e3-000c29800ae7;Кропивницький-Корольова Академіка 32/4 СвітРибалки;Кропивницький вул. Корольова Академіка, 32/4;5cb61730-749b-11df-b112-00215aee3ebe
30dffd66-f261-11ec-80e7-000c29800ae7;Кропивницький-Космонавта Попова 28Л Rozetka;Кропивницький вул. Космонавта Попова, 28Л;5cb61730-749b-11df-b112-00215aee3ebe
fcd94d18-9833-11ee-80c1-000c2961d091;Кропивницький-Кременчуцька 11 Бистро;Кропивницький вул. Кременчуцька, 11;5cb61730-749b-11df-b112-00215aee3ebe
0fd1c227-88f0-11ec-80e4-000c29800ae7;Кропивницький-Кропивницького 139 Здорова Вода;Кропивницький вул. Кропивницького, 139;5cb61730-749b-11df-b112-00215aee3ebe
3080d8ca-b6c7-11ee-80c4-000c2961d091;Кропивницький-Кропивницького 150 Vianor;Кропивницький вул. Кропивницького, 150;5cb61730-749b-11df-b112-00215aee3ebe
1ea922c1-368b-11e9-80dd-1c98ec135261;Кропивницький-Кропивницького 183 ОККО;Кропивницький вул. Кропивницького, 183;5cb61730-749b-11df-b112-00215aee3ebe
e79926df-d0af-11ea-80ca-000c29800ae7;Кропивницький-Леоніда Каденюка 6/1 TopService;Кропивницький вул. Леоніда Каденюка (Пацаєва), 6/1;5cb61730-749b-11df-b112-00215aee3ebe
07901862-79d7-11ec-80e4-000c29800ae7;Кропивницький-Михайлівська 5 Берізка;Кропивницький вул. Михайлівська (Кірова), 5;5cb61730-749b-11df-b112-00215aee3ebe
953860b5-3685-11e9-80dd-1c98ec135261;Кропивницький-Нижньоп'ятихатська 1 ОККО;Кропивницький вул. Нижньоп'ятихатська, 1;5cb61730-749b-11df-b112-00215aee3ebe
496f0b73-d171-11ee-80c4-000c2961d091;Кропивницький-Нікітіна (Височина) 13 Imaginarium;Кропивницький вул. Нікітіна Василя, 13;5cb61730-749b-11df-b112-00215aee3ebe
203ebe5d-d55e-11ee-80c4-000c2961d091;Кропивницький-Олександрійське 2Г;Кропивницький шосе Олександрійське, 2Г;5cb61730-749b-11df-b112-00215aee3ebe
9dd16397-9834-11ee-80c1-000c2961d091;Кропивницький-Очаківська 1 Бистро;Кропивницький вул. Очаківська, 1;5cb61730-749b-11df-b112-00215aee3ebe
1811d049-9832-11ee-80c1-000c2961d091;Кропивницький-Перемоги 18 Бистро;Кропивницький Просп. Перемоги, 18;5cb61730-749b-11df-b112-00215aee3ebe
3ecb0bfe-7180-11ee-80c1-000c2961d091;Кропивницький-Перспективна 23/13 One Price;Кропивницький вул. Перспективна, 23/13;5cb61730-749b-11df-b112-00215aee3ebe
84ddb791-daca-11ed-80ed-000c29800ae7;Кропивницький-Перспективна 23/13 Оптика KLEIMAN;Кропивницький вул. Перспективна, 23/13;5cb61730-749b-11df-b112-00215aee3ebe
10ff4f8b-7594-11ee-80c1-000c2961d091;Кропивницький-Перспективна 53 Сільпо;Кропивницький вул. Перспективна, 53;5cb61730-749b-11df-b112-00215aee3ebe
e68ed408-1734-11ee-80bd-000c2961d091;Кропивницький-Полтавська 28Г Волинські солодощі;Кропивницький вул. Полтавська, 28Г;5cb61730-749b-11df-b112-00215aee3ebe
18ea86a4-d170-11ee-80c4-000c2961d091;Кропивницький-Полтавська 60 Imaginarium;Кропивницький вул. Полтавська, 60;5cb61730-749b-11df-b112-00215aee3ebe
12e83d97-89fd-11ee-80c1-000c2961d091;Кропивницький-Полтавська 81Б Rozetka;Кропивницький вул. Полтавська, 81Б;5cb61730-749b-11df-b112-00215aee3ebe
cdc83f45-f043-11eb-80d5-000c29800ae7;Кропивницький-Полтавська 83 Ломбард;Кропивницький вул. Полтавська, 83;5cb61730-749b-11df-b112-00215aee3ebe
d8f739fd-7724-11e9-80e0-1c98ec135261;Кропивницький-Попова 28Н Кавярня;Кропивницький вул. Попова, 28Н;5cb61730-749b-11df-b112-00215aee3ebe
e307d124-b3ad-11ee-80c4-000c2961d091;Кропивницький-Попова 6 Сирна точка;Кропивницький вул. Попова, 6;5cb61730-749b-11df-b112-00215aee3ebe
e225d496-8a2e-11ee-80c1-000c2961d091;Кропивницький-Попова 9Е Зоомагазин;Кропивницький вул. Попова, 9;5cb61730-749b-11df-b112-00215aee3ebe
af37d066-3686-11e9-80dd-1c98ec135261;Кропивницький-Преображенська 90А ОККО;Кропивницький вул. Преображенська, 90А;5cb61730-749b-11df-b112-00215aee3ebe
caaf0ea7-88ef-11ec-80e4-000c29800ae7;Кропивницький-Салтикова-Щедріна 15 Здорова Вода;Кропивницький пров. Салтикова-Щедріна, 15;5cb61730-749b-11df-b112-00215aee3ebe
3edf6e10-1bb0-11ec-80df-000c29800ae7;Кропивницький-Сєрова 1/121 Делві;Кропивницький вул. Сєрова, 1/121;5cb61730-749b-11df-b112-00215aee3ebe
0173dcc1-02ad-11e9-80d9-1c98ec135261;Кропивницький-Соборна 13 VO-Service;Кропивницький вул. Соборна, 13;5cb61730-749b-11df-b112-00215aee3ebe
7ab0b296-7c8c-11e9-80e0-1c98ec135261;Кропивницький-Соборна 1А Іграшок;Кропивницький вул. Соборна, 1А;5cb61730-749b-11df-b112-00215aee3ebe
6f39ae65-7594-11ee-80c1-000c2961d091;Кропивницький-Соборна 22Б Сільпо;Кропивницький вул. Соборна, 22Б;5cb61730-749b-11df-b112-00215aee3ebe
47c4e3ec-cbad-11e7-80cc-1c98ec135261;Кропивницький-Соборна 26 Тепло;Кропивницький вул. Соборна, 26;5cb61730-749b-11df-b112-00215aee3ebe
133603d9-2c29-11ec-80e3-000c29800ae7;Кропивницький-Соборна 34/32 Service Master;Кропивницький вул. Соборна, 34/32;5cb61730-749b-11df-b112-00215aee3ebe
4081779e-8a2f-11ee-80c1-000c2961d091;Кропивницький-Соборна 38 Зоомагазин;Кропивницький вул. Соборна, 38;5cb61730-749b-11df-b112-00215aee3ebe
a8783450-9832-11ee-80c1-000c2961d091;Кропивницький-Степана Чобану 3 Бистро;Кропивницький вул. Степана Чобану (кол.Добровольського), 3;5cb61730-749b-11df-b112-00215aee3ebe
b27f997c-1c44-11ec-80df-000c29800ae7;Кропивницький-Театральна 68/45 Делві;Кропивницький вул. Театральна (кол.Дворцова), 68/45;5cb61730-749b-11df-b112-00215aee3ebe
911a638b-689a-11ec-80e4-000c29800ae7;Кропивницький-Тельнова Євгена 13 Крамниця;Кропивницький вул. Тельнова Євгена (Правди), 13;5cb61730-749b-11df-b112-00215aee3ebe
fb584c20-1c09-11ea-80e7-1c98ec135261;Кропивницький-Університетський 15А ОККО;Кропивницький Просп. Університетський, 15А;5cb61730-749b-11df-b112-00215aee3ebe
3ffb658a-1c45-11ec-80df-000c29800ae7;Кропивницький-Хмельницького Богдана 160 Делві;Кропивницький вул. Хмельницького Богдана, 160;5cb61730-749b-11df-b112-00215aee3ebe
be720196-7f4e-11ec-80e4-000c29800ae7;Кропивницький-Центральний 3/1 Julie;Кропивницький пров. Центральний, 3/1;5cb61730-749b-11df-b112-00215aee3ebe
e80a65e4-5f45-11ec-80e4-000c29800ae7;Кропивницький-Чикаленка 11 Крамниця;Кропивницький вул. Чикаленка (Медвєдєва), 11;5cb61730-749b-11df-b112-00215aee3ebe
2cc833ec-e426-11ed-80ed-000c29800ae7;Кропивницький-Шевченка 41 TEAKAVA;Кропивницький вул. Шевченка, 41;5cb61730-749b-11df-b112-00215aee3ebe
680b772f-1baf-11ec-80df-000c29800ae7;Кропивницький-Шульгиних 10/14 Делві;Кропивницький вул. Шульгиних, 10/14;5cb61730-749b-11df-b112-00215aee3ebe
e7d231e9-3bec-11ec-80e3-000c29800ae7;Круглик-Вітянська 2;Круглик вул. Вітянська, 2;62c3ce6d-749b-11df-b112-00215aee3ebe
e91d93f6-7665-11ee-80c1-000c2961d091;Крюківщина-Богуна Івана 2А Сільпо;Крюківщина вул. Богуна Івана, 2А;62c3cebf-749b-11df-b112-00215aee3ebe
f1ab5e2c-d342-11eb-80d5-000c29800ae7;Крюківщина-Жулянська 1Г Біле Сухе;Крюківщина вул. Жулянська, 1Г;62c3cebf-749b-11df-b112-00215aee3ebe
0695d323-0666-11ed-80e7-000c29800ae7;Крюківщина-Одеська 70Б Rozetka;Крюківщина вул. Одеська, 70Б;62c3cebf-749b-11df-b112-00215aee3ebe
ceb3e91f-846a-11ee-80c1-000c2961d091;Крюківщина-Панорамна 2 BEER WAY;Крюківщина вул. Панорамна, 2;62c3cebf-749b-11df-b112-00215aee3ebe
a412b33c-cfe0-11ed-80ed-000c29800ae7;Крюківщина-Панорамна 2А Біля Хати;Крюківщина вул. Панорамна, 2А;62c3cebf-749b-11df-b112-00215aee3ebe
77f55300-9912-11eb-80d3-000c29800ae7;Куликівка-Шевченка 1;Куликівка вул. Шевченка, 1;62c3cf4d-749b-11df-b112-00215aee3ebe
835bc5c1-c73f-11ee-80c4-000c2961d091;Ладижин-Будівельників 15;Ладижин вул. Будівельників, 15;62c3d07b-749b-11df-b112-00215aee3ebe
3ca1bf49-845e-11ee-80c1-000c2961d091;Ладижин-Будівельників 1А;Ладижин вул. Будівельників, 1А;62c3d07b-749b-11df-b112-00215aee3ebe
a6ce3ebc-a715-11e9-80e2-1c98ec135261;Ладижин-Процишина 2А;Ладижин вул. Процишина, 2А;62c3d07b-749b-11df-b112-00215aee3ebe
82c01c23-7c89-11ee-80c1-000c2961d091;Ладижин-Процишина 4Б;Ладижин вул. Процишина, 4Б;62c3d07b-749b-11df-b112-00215aee3ebe
5ae0dfb8-e842-11ea-80cc-000c29800ae7;Ланівці-Грушевського 1а;Ланівці вул. Грушевського, 1 а;62c3d0a5-749b-11df-b112-00215aee3ebe
7c884b0c-8b25-11e8-80d4-1c98ec135261;Ланівці-Незалежності, 13/1;Ланівці вул. Незалежності, 7;62c3d0a5-749b-11df-b112-00215aee3ebe
a95ef0e1-5c57-11ee-80c0-000c2961d091;Латівка-Дорожня 8;Латівка (Котовка) вул. Дорожня, 8;5cb61bd2-749b-11df-b112-00215aee3ebe
890f716a-5b96-11ed-80eb-000c29800ae7;Лебедин-Соборна 3;Лебедин пл. Соборна (Інтернаціональна), 3;62c3d0d0-749b-11df-b112-00215aee3ebe
496a2e80-1707-11ed-80e7-000c29800ae7;Летичів-Савицького 14/2;Летичів вул. Савицького, 14/2;62c3d18a-749b-11df-b112-00215aee3ebe
942df2d2-2b7a-11ee-80bd-000c2961d091;Летичів-Савицького 9;Летичів вул. Савицького, 9;62c3d18a-749b-11df-b112-00215aee3ebe
dd2faf11-a791-11e9-80e4-1c98ec135261;Летичів-Хмельницького Богдана 21;Летичів вул. Хмельницького Богдана, 21;62c3d18a-749b-11df-b112-00215aee3ebe
7a40c4f0-7666-11ee-80c1-000c2961d091;Лиманка-Перлинна 5 Сільпо;Лиманка (Мізікевича) вул. Перлинна (Кадор), 5;c8674a8b-dbb0-11df-9197-00215aee3ebe
86a727a0-717d-11ee-80c1-000c2961d091;Лиманка-Райдужний 15/3 One Price;Лиманка (Мізікевича) ж/м Райдужний, 15/3;c8674a8b-dbb0-11df-9197-00215aee3ebe
12b3b68a-87b6-11ee-80c1-000c2961d091;Лиманка-Райдужний 16/2 Копійка;Лиманка (Мізікевича) ж/м Райдужний, 16/2;c8674a8b-dbb0-11df-9197-00215aee3ebe
794e265c-87b6-11ee-80c1-000c2961d091;Лиманка-Райдужний 22 Копійка;Лиманка (Мізікевича) ж/м Райдужний, 22;c8674a8b-dbb0-11df-9197-00215aee3ebe
70d49cc5-e300-11eb-80d5-000c29800ae7;Липини-Заньковецької Марії 1 SPAR;Липини вул. Заньковецької Марії, 1;62c3d1d3-749b-11df-b112-00215aee3ebe
45738afa-ae03-11ed-80ec-000c29800ae7;Липини-Січових Стрільців 2В Сім23;Липини вул. Січових Стрільців, 2В;62c3d1d3-749b-11df-b112-00215aee3ebe
427f3731-5d31-11e9-80df-1c98ec135261;Липова Долина-Горького 7;Липова Долина вул. Горького, 7;62c3d1f9-749b-11df-b112-00215aee3ebe
e00c2232-6502-11e9-80df-1c98ec135261;Липова Долина-Полтавська 27 А;Липова Долина вул. Полтавська, 27А;62c3d1f9-749b-11df-b112-00215aee3ebe
3de92316-a70a-11e9-80e2-1c98ec135261;Липовець-Героїв Майдану 57;Липовець вул. Героїв Майдану, 57;62c3d20b-749b-11df-b112-00215aee3ebe
294f9582-24db-11ee-80bd-000c2961d091;Лісники-Ватутіна 170;Лісники вул. Ватутіна, 170;62c3d2ea-749b-11df-b112-00215aee3ebe
32ff1dda-7e35-11ee-80c1-000c2961d091;Лісники-Ватутіна 62А;Лісники вул. Ватутіна, 62А;62c3d2ea-749b-11df-b112-00215aee3ebe
e86f2969-be22-11eb-80d5-000c29800ae7;Літин-Хмельницького Богдана 7;Літин вул. Хмельницького Богдана, 7;62c3d320-749b-11df-b112-00215aee3ebe
18745653-b7e4-11eb-80d4-000c29800ae7;Літки-Шевченка 6;Літки вул. Шевченка, 6;62c3d323-749b-11df-b112-00215aee3ebe
717eeba3-7595-11ee-80c1-000c2961d091;Лозова-5-й мікрорайон 1 Сільпо;Лозова мікр. 5-й, 1;62c3d36c-749b-11df-b112-00215aee3ebe
198e2755-5cbd-11eb-80d0-000c29800ae7;Лозова-5-й мікрорайон, 1;Лозова мікр. 5-й, 1;62c3d36c-749b-11df-b112-00215aee3ebe
384f030a-1f20-11ee-80bd-000c2961d091;Лозова-Грушевського Михайла 4;Лозова вул. Грушевського Михайла, 4;62c3d36c-749b-11df-b112-00215aee3ebe
73cd6109-c409-11ee-80c4-000c2961d091;Лозова-Павлоградська 36;Лозова вул. Павлоградська, 36;62c3d36c-749b-11df-b112-00215aee3ebe
3c3e2150-15c9-11ee-80bd-000c2961d091;Лозова-Павлоградська 76;Лозова вул. Павлоградська, 76;62c3d36c-749b-11df-b112-00215aee3ebe
08d94710-a798-11e9-80e4-1c98ec135261;Локітка-Центральна 144;Локітка вул. Центральна, 144;62c3d39f-749b-11df-b112-00215aee3ebe
5ff1892e-49be-11eb-80d0-000c29800ae7;Лохвиця-Героїв України 14 В;Лохвиця вул. Героїв України, 14В;62c3d3df-749b-11df-b112-00215aee3ebe
4815b007-cc09-11ee-80c4-000c2961d091;Лохвиця-Героїв України 26 Vodafone;Лохвиця вул. Героїв України, 26;62c3d3df-749b-11df-b112-00215aee3ebe
9f59a2f3-da09-11ee-80c4-000c2961d091;Лохвиця-Героїв України 4/1;Лохвиця вул. Героїв України, 4/1;62c3d3df-749b-11df-b112-00215aee3ebe
38e11e8e-32a2-11e8-80d0-1c98ec135261;Лохвиця-Героїв України,26;Лохвиця вул. Героїв України, 26;62c3d3df-749b-11df-b112-00215aee3ebe
d4567073-5bc9-11eb-80d0-000c29800ae7;Лохвиця-Перемоги 6;Лохвиця пл. Перемоги, 6;62c3d3df-749b-11df-b112-00215aee3ebe
a8fef48e-8374-11e8-80d4-1c98ec135261;Лубни;Лубни вул. Фабрична, 7;62c3d3f2-749b-11df-b112-00215aee3ebe
29240d51-285a-11ed-80e7-000c29800ae7;Лубни-Володимирський 32 Lifecell;Лубни Просп. Володимирський (Радянська), 32;62c3d3f2-749b-11df-b112-00215aee3ebe
a3fa64af-f194-11ec-80e7-000c29800ae7;Лубни-Володимирський 32 Rozetka;Лубни Просп. Володимирський (Радянська), 32;62c3d3f2-749b-11df-b112-00215aee3ebe
6ed08bec-efd5-11ed-80ed-000c29800ae7;Лубни-Володимирський 40 Київстар;Лубни Просп. Володимирський (Радянська), 40;62c3d3f2-749b-11df-b112-00215aee3ebe
b09db044-d1a9-11ea-80ca-000c29800ae7;Лубни-Ярослава Мудрого 19/2 Домотроніка;Лубни вул. Ярослава Мудрого, 19/2;62c3d3f2-749b-11df-b112-00215aee3ebe
62ab9855-2fa8-11ee-80bd-000c2961d091;Лугини-Люксембург Рози 6;Лугини вул. Люксембург Рози, 6;62c3d410-749b-11df-b112-00215aee3ebe
16d97480-430a-11ed-80e9-000c29800ae7;Лугини-Шевченка 14/18;Лугини вул. Шевченка, 14/18;62c3d410-749b-11df-b112-00215aee3ebe
56d72269-caa7-11eb-80d5-000c29800ae7;Лукавці-Героїв Майдану 194;Лукавці вул. Героїв Майдану, 194;62c3d473-749b-11df-b112-00215aee3ebe
4a0e3d04-0670-11ec-80d6-000c29800ae7;Лука-Мелешківська-Тиврівське 20;Лука-Мелешківська шосе Тиврівське, 20;62c3d44e-749b-11df-b112-00215aee3ebe
d0a207ff-0115-11df-b41f-00215aee3ebe;Луцьк;Луцьк вул. Лідавська, 8;62c3d4a4-749b-11df-b112-00215aee3ebe
a5dc2175-14cc-11ee-80bd-000c2961d091;Луцьк-Відродження 10 СПЕЦ;Луцьк Просп. Відродження, 10;62c3d4a4-749b-11df-b112-00215aee3ebe
7f9acbe6-e30b-11eb-80d5-000c29800ae7;Луцьк-Відродження 18 SPAR;Луцьк Просп. Відродження, 18;62c3d4a4-749b-11df-b112-00215aee3ebe
edf3d012-cccf-11ee-80c4-000c2961d091;Луцьк-Відродження 2;Луцьк Просп. Відродження, 2;62c3d4a4-749b-11df-b112-00215aee3ebe
458d39be-7596-11ee-80c1-000c2961d091;Луцьк-Волі 1 Сільпо;Луцьк Просп. Волі, 1;62c3d4a4-749b-11df-b112-00215aee3ebe
7c63dfd4-f11e-11ec-80e7-000c29800ae7;Луцьк-Волі 15 Rozetka;Луцьк Просп. Волі, 15;62c3d4a4-749b-11df-b112-00215aee3ebe
2105892d-17cb-11ed-80e7-000c29800ae7;Луцьк-Волі 23 Сім23;Луцьк Просп. Волі, 23;62c3d4a4-749b-11df-b112-00215aee3ebe
9209e596-ae03-11ed-80ec-000c29800ae7;Луцьк-Волі 4 Сім23;Луцьк Просп. Волі, 4;62c3d4a4-749b-11df-b112-00215aee3ebe
4589aab5-ff38-11eb-80d6-000c29800ae7;Луцьк-Волі 66 Європошта;Луцьк Просп. Волі, 66;62c3d4a4-749b-11df-b112-00215aee3ebe
eb5e9e9f-1966-11e9-80dc-1c98ec135261;Луцьк-Володимирська 20 Сім23;Луцьк вул. Володимирська, 20;62c3d4a4-749b-11df-b112-00215aee3ebe
0a5ce15a-1e34-11e9-80dc-1c98ec135261;Луцьк-Володимирська 94 Сім23;Луцьк вул. Володимирська, 94;62c3d4a4-749b-11df-b112-00215aee3ebe
3a6c4c89-731a-11e9-80df-1c98ec135261;Луцьк-Гордіюк 2 Сім23;Луцьк вул. Захисників України  (Гордіюк), 2;62c3d4a4-749b-11df-b112-00215aee3ebe
42feefc8-e066-11e9-80e6-1c98ec135261;Луцьк-Гордіюк 39А Сім23;Луцьк вул. Захисників України  (Гордіюк), 39А;62c3d4a4-749b-11df-b112-00215aee3ebe
2f0d53f8-732c-11ee-80c1-000c2961d091;Луцьк-Грушевського 16 Зоомагазин;Луцьк Просп. Грушевського, 16;62c3d4a4-749b-11df-b112-00215aee3ebe
3a1a1e31-f95c-11ed-80ed-000c29800ae7;Луцьк-Грушевського 2 lifecell;Луцьк Просп. Грушевського, 2;62c3d4a4-749b-11df-b112-00215aee3ebe
996bff9b-7596-11ee-80c1-000c2961d091;Луцьк-Грушевського 2 Сільпо;Луцьк Просп. Грушевського, 2;62c3d4a4-749b-11df-b112-00215aee3ebe
2abd2398-cfb4-11e9-80e4-1c98ec135261;Луцьк-Грушевського 4В Наш Край;Луцьк Просп. Грушевського, 4В;62c3d4a4-749b-11df-b112-00215aee3ebe
4da11689-0c3d-11ee-80bd-000c2961d091;Луцьк-Дружби Народів 2 Rozetka;Луцьк бул. Дружби Народів, 2;62c3d4a4-749b-11df-b112-00215aee3ebe
9c28b350-a4b8-11ee-80c4-000c2961d091;Луцьк-Дружби Народів 4А Куточок рибака;Луцьк бул. Дружби Народів, 4А;62c3d4a4-749b-11df-b112-00215aee3ebe
e0021908-731a-11e9-80df-1c98ec135261;Луцьк-Захарова 7А Сім23;Луцьк вул. Князя Романа (Захарова), 7А;62c3d4a4-749b-11df-b112-00215aee3ebe
97b20426-5a9e-11ed-80eb-000c29800ae7;Луцьк-Зацепи 4 Сім23;Луцьк вул. Зацепи, 4;62c3d4a4-749b-11df-b112-00215aee3ebe
8385e676-ab0c-11ee-80c4-000c2961d091;Луцьк-Зацепи 6 Говвір;Луцьк вул. Зацепи, 6;62c3d4a4-749b-11df-b112-00215aee3ebe
08f0f143-5a9f-11ed-80eb-000c29800ae7;Луцьк-Інтернаціоналістів 5 Сім23;Луцьк вул. Інтернаціоналістів, 5;62c3d4a4-749b-11df-b112-00215aee3ebe
5a9a2063-425f-11ee-80c0-000c2961d091;Луцьк-Карбишева Генерала 2 Сім23;Луцьк вул. Карбишева Генерала, 2;62c3d4a4-749b-11df-b112-00215aee3ebe
05f9b0fd-b36b-11e9-80e4-1c98ec135261;Луцьк-Карпенка-Карого 5 ОККО;Луцьк вул. Карпенка-Карого, 5;62c3d4a4-749b-11df-b112-00215aee3ebe
58f333d2-7597-11ee-80c1-000c2961d091;Луцьк-Київський 11 Сільпо;Луцьк Майдан Київський, 11;62c3d4a4-749b-11df-b112-00215aee3ebe
f6250083-ae02-11ed-80ec-000c29800ae7;Луцьк-Ковельська 54 Сім23;Луцьк вул. Ковельська, 54;62c3d4a4-749b-11df-b112-00215aee3ebe
1bfee87a-ae02-11ed-80ec-000c29800ae7;Луцьк-Коновальця 12А Сім23;Луцьк вул. Коновальця, 12А;62c3d4a4-749b-11df-b112-00215aee3ebe
feeb486e-6dc4-11ee-80c1-000c2961d091;Луцьк-Конякіна 11 Продукти;Луцьк вул. Конякіна, 11;62c3d4a4-749b-11df-b112-00215aee3ebe
b45230d7-4739-11e9-80dd-1c98ec135261;Луцьк-Конякіна 12А Сім23;Луцьк вул. Конякіна, 12А;62c3d4a4-749b-11df-b112-00215aee3ebe
eda7f734-e6c3-11ee-80c5-000c2961d091;Луцьк-Конякіна 14 Rozetka;Луцьк вул. Конякіна, 14;62c3d4a4-749b-11df-b112-00215aee3ebe
88b9baa4-3396-11e9-80dd-1c98ec135261;Луцьк-Конякіна 16Д ОККО;Луцьк вул. Конякіна, 16Д;62c3d4a4-749b-11df-b112-00215aee3ebe
5d5c522a-938a-11eb-80d3-000c29800ae7;Луцьк-Конякіна 37А СІА Мобайл;Луцьк вул. Конякіна, 37А;62c3d4a4-749b-11df-b112-00215aee3ebe
122b47b2-e30c-11eb-80d5-000c29800ae7;Луцьк-Конякіна 7А SPAR;Луцьк вул. Конякіна, 7А;62c3d4a4-749b-11df-b112-00215aee3ebe
ed8ba786-7595-11ee-80c1-000c2961d091;Луцьк-Кравчука 23 Сільпо;Луцьк вул. Кравчука, 23;62c3d4a4-749b-11df-b112-00215aee3ebe
134e271b-26b1-11ec-80e3-000c29800ae7;Луцьк-Кравчука 48Б Сім23;Луцьк вул. Кравчука, 48Б;62c3d4a4-749b-11df-b112-00215aee3ebe
906b31b5-e680-11e9-80e7-1c98ec135261;Луцьк-Левчанівської Сенаторки 2 Сім23;Луцьк вул. Левчанівської Сенаторки, 2;62c3d4a4-749b-11df-b112-00215aee3ebe
532f49bf-4733-11e9-80dd-1c98ec135261;Луцьк-Липинського 7 Сім23;Луцьк вул. Липинського, 7;62c3d4a4-749b-11df-b112-00215aee3ebe
af8ff29c-9a73-11ee-80c1-000c2961d091;Луцьк-Львівська 102 Зоомагазин;Луцьк вул. Львівська, 102;62c3d4a4-749b-11df-b112-00215aee3ebe
9ee6f6b6-e30c-11eb-80d5-000c29800ae7;Луцьк-Львівська 61 SPAR;Луцьк вул. Львівська, 61;62c3d4a4-749b-11df-b112-00215aee3ebe
f5e87fd4-b9f3-11ee-80c4-000c2961d091;Луцьк-Молоді 1А OptKlin;Луцьк Просп. Молоді, 1А;62c3d4a4-749b-11df-b112-00215aee3ebe
e86209d1-06ab-11ed-80e7-000c29800ae7;Луцьк-Молоді 4Е Сім23;Луцьк Просп. Молоді, 4Е;62c3d4a4-749b-11df-b112-00215aee3ebe
030db8b2-4735-11e9-80dd-1c98ec135261;Луцьк-Молоді 7 Сім23;Луцьк Просп. Молоді, 7;62c3d4a4-749b-11df-b112-00215aee3ebe
4dd76d7b-472d-11e9-80dd-1c98ec135261;Луцьк-Перемоги 12 Сім23;Луцьк Просп. Перемоги, 12;62c3d4a4-749b-11df-b112-00215aee3ebe
cf207d25-e67f-11e9-80e7-1c98ec135261;Луцьк-Перемоги 17 Сім23;Луцьк Просп. Перемоги, 17;62c3d4a4-749b-11df-b112-00215aee3ebe
e8db5037-f11e-11ec-80e7-000c29800ae7;Луцьк-Привокзальна 10Б Rozetka;Луцьк вул. Привокзальна, 10Б;62c3d4a4-749b-11df-b112-00215aee3ebe
20cc1ff2-472e-11e9-80dd-1c98ec135261;Луцьк-Рівненська 105 Сім23;Луцьк вул. Рівненська, 105;62c3d4a4-749b-11df-b112-00215aee3ebe
179b1155-732e-11ee-80c1-000c2961d091;Луцьк-Рівненська 25А/128 Фея;Луцьк вул. Рівненська, 25А/128;62c3d4a4-749b-11df-b112-00215aee3ebe
648b09e7-4260-11ee-80c0-000c2961d091;Луцьк-Рівненська 4/101 Сім23;Луцьк вул. Рівненська, 4/101;62c3d4a4-749b-11df-b112-00215aee3ebe
ccfca1e6-fa30-11ed-80ed-000c29800ae7;Луцьк-Рівненська 83 Rozetka;Луцьк вул. Рівненська, 83;62c3d4a4-749b-11df-b112-00215aee3ebe
ff01cc0b-7596-11ee-80c1-000c2961d091;Луцьк-Рівненська 89 Сільпо;Луцьк вул. Рівненська, 89;62c3d4a4-749b-11df-b112-00215aee3ebe
cdc7fbe0-f95b-11ed-80ed-000c29800ae7;Луцьк-Соборності 11 Lifecell;Луцьк Просп. Соборності, 11;62c3d4a4-749b-11df-b112-00215aee3ebe
4110e2bb-4fb4-11ed-80eb-000c29800ae7;Луцьк-Соборності 14 Rozetka;Луцьк Просп. Соборності, 14;62c3d4a4-749b-11df-b112-00215aee3ebe
d4119ef4-9a71-11ee-80c1-000c2961d091;Луцьк-Соборності 22Б Зоомагазин;Луцьк Просп. Соборності, 22Б;62c3d4a4-749b-11df-b112-00215aee3ebe
c8893ade-cb32-11ee-80c4-000c2961d091;Луцьк-Соборності 22Б Сільпо;Луцьк Просп. Соборності, 22Б;62c3d4a4-749b-11df-b112-00215aee3ebe
bf577321-4731-11e9-80dd-1c98ec135261;Луцьк-Соборності 26 Сім23;Луцьк Просп. Соборності, 26;62c3d4a4-749b-11df-b112-00215aee3ebe
e7a07164-692a-11ea-80c6-000c29800ae7;Луцьк-Соборності 32 Сім23;Луцьк Просп. Соборності, 32;62c3d4a4-749b-11df-b112-00215aee3ebe
4c197d58-e065-11e9-80e6-1c98ec135261;Луцьк-Соборності 37В Сім23;Луцьк Просп. Соборності, 37В;62c3d4a4-749b-11df-b112-00215aee3ebe
64710680-e30d-11eb-80d5-000c29800ae7;Луцьк-Соборності 6 SPAR;Луцьк Просп. Соборності, 6;62c3d4a4-749b-11df-b112-00215aee3ebe
a44b499c-b9f3-11ee-80c4-000c2961d091;Луцьк-Супутника 14А OptKlin;Луцьк вул. Супутника, 14А;62c3d4a4-749b-11df-b112-00215aee3ebe
dd146f3b-ae03-11ed-80ec-000c29800ae7;Луцьк-Українки Лесі 43 Сім23;Луцьк вул. Українки Лесі, 43;62c3d4a4-749b-11df-b112-00215aee3ebe
6f2d14ef-ae04-11ed-80ec-000c29800ae7;Луцьк-Українки Лесі 67 Сім23;Луцьк вул. Українки Лесі, 67;62c3d4a4-749b-11df-b112-00215aee3ebe
79066970-e064-11e9-80e6-1c98ec135261;Луцьк-Хурсенка 58 Сім23;Луцьк вул. Хурсенка ( Московська ), 58;62c3d4a4-749b-11df-b112-00215aee3ebe
1673eeca-93c9-11ec-80e4-000c29800ae7;Луцьк-Цукрова 6 Сім23;Луцьк вул. Цукрова, 6;62c3d4a4-749b-11df-b112-00215aee3ebe
030946c9-e2ff-11eb-80d5-000c29800ae7;Луцьк-Шевченка 13 Наш Край;Луцьк вул. Шевченка, 13;62c3d4a4-749b-11df-b112-00215aee3ebe
0ef11fb2-4260-11ee-80c0-000c2961d091;Луцьк-Шухевича 1А Сім23;Луцьк вул. Шухевича, 1А;62c3d4a4-749b-11df-b112-00215aee3ebe
0327e0b8-f660-11e9-80e7-1c98ec135261;Любар-Незалежності 19 А;Любар вул. Незалежності, 19А;62c3d4c3-749b-11df-b112-00215aee3ebe
b4528f03-7955-11e8-80d4-1c98ec135261;Любар-Незалежності,41;Любар вул. Незалежності, 41;62c3d4c3-749b-11df-b112-00215aee3ebe
f15a1434-8cd4-11e4-b90b-003048d2b473;Любашівка;Любашівка вул. Спортивна, 4А;62c3d4cb-749b-11df-b112-00215aee3ebe
bd6cf1c4-122d-11ec-80da-000c29800ae7;Любашівка-Софіївська 130 Індіго;Любашівка вул. Софіївська, 130;62c3d4cb-749b-11df-b112-00215aee3ebe
3917164c-1739-11ee-80bd-000c2961d091;Любашівка-Софіївська 5 Міні - маркет;Любашівка вул. Софіївська, 5;62c3d4cb-749b-11df-b112-00215aee3ebe
86cdc9f3-ae06-11ed-80ec-000c29800ae7;Люблинець-Незалежності 17;Люблинець вул. Незалежності, 17;62c3d4f8-749b-11df-b112-00215aee3ebe
2698098f-f95e-11ed-80ed-000c29800ae7;Любомль-1-го Травня 32;Любомль вул. 1-го Травня, 32;62c3d50b-749b-11df-b112-00215aee3ebe
ef6afd06-ab80-11ed-80ec-000c29800ae7;Люботин-Новобаварська 1А;Люботин вул. Новобаварська, 1А;62c3d511-749b-11df-b112-00215aee3ebe
6a037aa5-6441-11ea-80c6-000c29800ae7;Люботин-Слобожанська 6;Люботин вул. Слобожанська, 6;62c3d511-749b-11df-b112-00215aee3ebe
75ec298e-a8dd-11de-bac3-0030485903e8;Львів (HUB);Львів(Пасіки-Зубрицькі) вул. Заводська, 56;74dc477e-749b-11df-b112-00215aee3ebe
9cb6a328-56e3-11ee-80c0-000c2961d091;Львів-Антонича 20 Сім23;Львів вул. Антонича, 20;62c3d54a-749b-11df-b112-00215aee3ebe
4e4204b3-9446-11ee-80c1-000c2961d091;Львів-Антонича 20 Чистенько;Львів вул. Антонича, 20;62c3d54a-749b-11df-b112-00215aee3ebe
99e07b9c-eeaa-11eb-80d5-000c29800ae7;Львів-АО;Львів вул. Пасіки Зубрицькі (Заводська), 56;62c3d54a-749b-11df-b112-00215aee3ebe
01ff9498-f0d6-11ed-80ed-000c29800ae7;Львів-Бандери Степана 25 Свій маркет;Львів вул. Бандери Степана, 25;62c3d54a-749b-11df-b112-00215aee3ebe
2dd12094-f262-11ec-80e7-000c29800ae7;Львів-Бандери Степана 37 Rozetka;Львів вул. Бандери Степана, 37;62c3d54a-749b-11df-b112-00215aee3ebe
7b13fc62-06ab-11ed-80e7-000c29800ae7;Львів-Васильківського 9 Сім23;Львів вул. Васильківського, 9;62c3d54a-749b-11df-b112-00215aee3ebe
76a46f93-7b9b-11e9-80e0-1c98ec135261;Львів-Вашингтона 12 ОККО;Львів вул. Вашингтона, 12;62c3d54a-749b-11df-b112-00215aee3ebe
5bc0744c-3c26-11ee-80c0-000c2961d091;Львів-Вашингтона 17Б Rozetka;Львів вул. Вашингтона, 17Б;62c3d54a-749b-11df-b112-00215aee3ebe
bd4c4d17-abee-11ee-80c4-000c2961d091;Львів-Величковського 40 Львівські крани;Львів вул. Величковського, 40;62c3d54a-749b-11df-b112-00215aee3ebe
a9a180ac-f0cf-11ed-80ed-000c29800ae7;Львів-Виговського 1 Свій маркет;Львів вул. Виговського, 1;62c3d54a-749b-11df-b112-00215aee3ebe
2ca5a6d7-2557-11ee-80bd-000c2961d091;Львів-Виговського 100 Сільпо;Львів вул. Виговського, 100;62c3d54a-749b-11df-b112-00215aee3ebe
05b30a6c-f0d0-11ed-80ed-000c29800ae7;Львів-Виговського 45 Свій маркет;Львів вул. Виговського, 45;62c3d54a-749b-11df-b112-00215aee3ebe
779c137a-f0cd-11ed-80ed-000c29800ae7;Львів-Виговського 5 Свій маркет;Львів вул. Виговського, 5;62c3d54a-749b-11df-b112-00215aee3ebe
a1d0bf19-2b83-11e9-80dd-1c98ec135261;Львів-Виговського 69 Ремонт телефонів;Львів вул. Виговського, 69;62c3d54a-749b-11df-b112-00215aee3ebe
f52a8f81-ae3b-11ed-80ec-000c29800ae7;Львів-Винниченка 6 Сім23;Львів вул. Винниченка, 6;62c3d54a-749b-11df-b112-00215aee3ebe
f5807f68-f262-11ec-80e7-000c29800ae7;Львів-Володимира Великого 18 Rozetka;Львів вул. Володимира Великого, 18;62c3d54a-749b-11df-b112-00215aee3ebe
6b8ad52c-2557-11ee-80bd-000c2961d091;Львів-Володимира Великого 26А Сільпо;Львів вул. Володимира Великого, 26А;62c3d54a-749b-11df-b112-00215aee3ebe
83fd1e8b-a3fc-11ee-80c4-000c2961d091;Львів-Володимира Великого 27 ;Львів вул. Володимира Великого, 27;62c3d54a-749b-11df-b112-00215aee3ebe
912bc976-2092-11e9-80dc-1c98ec135261;Львів-Володимира Великого 58 ОККО;Львів вул. Володимира Великого, 58;62c3d54a-749b-11df-b112-00215aee3ebe
31c18c4a-4f43-11eb-80d0-000c29800ae7;Львів-Володимира Великого 59В Мобі-Люкс;Львів вул. Володимира Великого, 59В;62c3d54a-749b-11df-b112-00215aee3ebe
5b641001-56e2-11ee-80c0-000c2961d091;Львів-Володимира Великого 93А Сім23;Львів вул. Володимира Великого, 93А;62c3d54a-749b-11df-b112-00215aee3ebe
508241f0-f0d0-11ed-80ed-000c29800ae7;Львів-Гашека Ярослава 17 Свій маркет;Львів вул. Гашека Ярослава, 17;62c3d54a-749b-11df-b112-00215aee3ebe
934e1716-0376-11ee-80bd-000c2961d091;Львів-Гашека Ярослава 17 Сім23;Львів вул. Гашека Ярослава, 17;62c3d54a-749b-11df-b112-00215aee3ebe
3edff06f-f263-11ec-80e7-000c29800ae7;Львів-Героїв УПА 76 Rozetka;Львів вул. Героїв УПА, 76;62c3d54a-749b-11df-b112-00215aee3ebe
cfb93df3-f0d1-11ed-80ed-000c29800ae7;Львів-Героїв УПА 76 Свій маркет;Львів вул. Героїв УПА, 76;62c3d54a-749b-11df-b112-00215aee3ebe
1c98e8b5-2f7e-11ee-80bd-000c2961d091;Львів-Городоцька 174 ZAVOD PARKETU;Львів вул. Городоцька, 174;62c3d54a-749b-11df-b112-00215aee3ebe
0b705222-2558-11ee-80bd-000c2961d091;Львів-Городоцька 179 Сільпо;Львів вул. Городоцька, 179;62c3d54a-749b-11df-b112-00215aee3ebe
d3de8f23-56e2-11ee-80c0-000c2961d091;Львів-Городоцька 187 Сім23;Львів вул. Городоцька, 187;62c3d54a-749b-11df-b112-00215aee3ebe
e5c093e6-f2f7-11e4-ba66-003048d2b473;Львів-Городоцька 242;Львів вул. Городоцька, 242;62c3d54a-749b-11df-b112-00215aee3ebe
27754dae-7b9d-11e9-80e0-1c98ec135261;Львів-Городоцька 284-1 ОККО;Львів вул. Городоцька, 284-1;62c3d54a-749b-11df-b112-00215aee3ebe
1ced9fca-f264-11ec-80e7-000c29800ae7;Львів-Городоцька 8 Rozetka;Львів вул. Городоцька, 8;62c3d54a-749b-11df-b112-00215aee3ebe
c916718c-f0cd-11ed-80ed-000c29800ae7;Львів-Демнянська 26 Свій маркет;Львів вул. Демнянська, 26;62c3d54a-749b-11df-b112-00215aee3ebe
9da32a76-abef-11ee-80c4-000c2961d091;Львів-Демнянська 3 Львівські крани;Львів вул. Демнянська, 3;62c3d54a-749b-11df-b112-00215aee3ebe
6e83b5c8-abee-11ee-80c4-000c2961d091;Львів-Драгана 1 Львівські крани;Львів вул. Драгана, 1;62c3d54a-749b-11df-b112-00215aee3ebe
265394e7-1fd2-11ee-80bd-000c2961d091;Львів-Залізнична 19 Rozetka;Львів вул. Залізнична, 19;62c3d54a-749b-11df-b112-00215aee3ebe
76974a08-2d2e-11ed-80e7-000c29800ae7;Львів-Залізнична 8/5 Вінс-Тур;Львів вул. Залізнична, 8/5;62c3d54a-749b-11df-b112-00215aee3ebe
35d803a1-2090-11e9-80dc-1c98ec135261;Львів-Замарстинівська 174 ОККО;Львів вул. Замарстинівська, 174;62c3d54a-749b-11df-b112-00215aee3ebe
1d30ccb3-40e3-11ee-80c0-000c2961d091;Львів-Замарстинівська 233А Продукти;Львів вул. Замарстинівська, 233А;62c3d54a-749b-11df-b112-00215aee3ebe
18cf4914-c8c3-11ed-80ed-000c29800ae7;Львів-Зелена 151 Rozetka;Львів вул. Зелена, 151;62c3d54a-749b-11df-b112-00215aee3ebe
15835ed2-51e4-11ec-80e4-000c29800ae7;Львів-Зелена 204а Сантехніка Аква Світ;Львів вул. Зелена, 204а;62c3d54a-749b-11df-b112-00215aee3ebe
4a4fe105-f0d2-11ed-80ed-000c29800ae7;Львів-Зелена 28 Свій Маркет;Львів вул. Зелена, 28;62c3d54a-749b-11df-b112-00215aee3ebe
86b65e45-7ba0-11e9-80e0-1c98ec135261;Львів-Зелена 283 ОККО;Львів вул. Зелена, 283;62c3d54a-749b-11df-b112-00215aee3ebe
0d161467-ffac-11e8-80d9-1c98ec135261;Львів-Зелена, 147;Львів вул. Зелена, 147;62c3d54a-749b-11df-b112-00215aee3ebe
ab5a71d6-5a6d-11e2-b7ac-003048d2b473;Львів-Зубрівська 34Г СПЕЦ;Львів вул. Зубрівська, 34Г;62c3d54a-749b-11df-b112-00215aee3ebe
1ea6d5ad-abee-11ee-80c4-000c2961d091;Львів-Кавалерідзе 1 Львівські крани;Львів вул. Кавалерідзе, 1;62c3d54a-749b-11df-b112-00215aee3ebe
3d5f65b0-5a18-11ed-80eb-000c29800ae7;Львів-Кавалерідзе 23 Сім23;Львів вул. Кавалерідзе, 23;62c3d54a-749b-11df-b112-00215aee3ebe
ce612d50-ff3c-11eb-80d6-000c29800ae7;Львів-Клепарівська 10 Європошта;Львів вул. Клепарівська, 10;62c3d54a-749b-11df-b112-00215aee3ebe
b835eda5-2091-11e9-80dc-1c98ec135261;Львів-Клепарівська 30Б ОККО;Львів вул. Клепарівська, 30Б;62c3d54a-749b-11df-b112-00215aee3ebe
e23b7f03-abef-11ee-80c4-000c2961d091;Львів-Княгині Ольги 100К Львівські крани;Львів вул. Княгині Ольги, 100К;62c3d54a-749b-11df-b112-00215aee3ebe
f91be5bd-c8c3-11ed-80ed-000c29800ae7;Львів-Княгині Ольги 100Л Rozetka;Львів вул. Княгині Ольги, 100Л;62c3d54a-749b-11df-b112-00215aee3ebe
b92dbeff-e282-11e8-80d7-1c98ec135261;Львів-Княгині Ольги 106 Аксесуари;Львів вул. Княгині Ольги, 106;62c3d54a-749b-11df-b112-00215aee3ebe
4c75a9f0-f0d3-11ed-80ed-000c29800ae7;Львів-Коперніка 10 Свій маркет;Львів вул. Коперніка, 10;62c3d54a-749b-11df-b112-00215aee3ebe
99be8552-be8c-11ee-80c4-000c2961d091;Львів-Коперніка 56/58 Свій маркет;Львів вул. Коперніка, 56/58;62c3d54a-749b-11df-b112-00215aee3ebe
2099d642-f0ce-11ed-80ed-000c29800ae7;Львів-Костюшка 6 Свій маркет;Львів вул. Костюшка, 6;62c3d54a-749b-11df-b112-00215aee3ebe
a7f06ab5-f0d2-11ed-80ed-000c29800ae7;Львів-Костя Левицького 15 Свій маркет;Львів вул. Костя Левицького, 15;62c3d54a-749b-11df-b112-00215aee3ebe
9e00ac96-f0d3-11ed-80ed-000c29800ae7;Львів-Кошиця Олександра 1 Свій маркет;Львів вул. Кошиця Олександра, 1;62c3d54a-749b-11df-b112-00215aee3ebe
02ad44ac-56e4-11ee-80c0-000c2961d091;Львів-Куліша 6Б Сім23;Львів вул. Куліша, 6Б;62c3d54a-749b-11df-b112-00215aee3ebe
a77b584e-f262-11ec-80e7-000c29800ae7;Львів-Кульпарківська 226А Rozetka;Львів вул. Кульпарківська, 226А;62c3d54a-749b-11df-b112-00215aee3ebe
65addb8c-2558-11ee-80bd-000c2961d091;Львів-Кульпарківська 226А Сільпо;Львів вул. Кульпарківська, 226А;62c3d54a-749b-11df-b112-00215aee3ebe
f2ebb0f3-2558-11ee-80bd-000c2961d091;Львів-Кульпарківська 93А Сільпо;Львів вул. Кульпарківська, 93А;62c3d54a-749b-11df-b112-00215aee3ebe
df71dd67-209a-11e9-80dc-1c98ec135261;Львів-Липинського 54А ОККО;Львів вул. Липинського, 54А;62c3d54a-749b-11df-b112-00215aee3ebe
934bbe53-3072-11ee-80bd-000c2961d091;Львів-Липинського 58А ZAVOD PARKETU;Львів вул. Липинського, 58А;62c3d54a-749b-11df-b112-00215aee3ebe
a5a4d7e0-ae3c-11ed-80ec-000c29800ae7;Львів-Липинського 60 Сім23;Львів вул. Липинського, 60;62c3d54a-749b-11df-b112-00215aee3ebe
ce7cc19b-dab4-11ed-80ed-000c29800ae7;Львів-Листопадового Чину 3 СПЕЦ;Львів вул. Листопадового Чину, 3;62c3d54a-749b-11df-b112-00215aee3ebe
7d7419ed-577e-11ee-80c0-000c2961d091;Львів-Литвиненка 19 Сім23;Львів вул. Литвиненка, 19;62c3d54a-749b-11df-b112-00215aee3ebe
ab6a92fb-fa5e-11e9-80e7-1c98ec135261;Львів-Личаківська 152А Мобі-Люкс;Львів вул. Личаківська, 152А;62c3d54a-749b-11df-b112-00215aee3ebe
6f29dce7-7982-11ee-80c1-000c2961d091;Львів-Личаківська 152Б ОККО;Львів вул. Личаківська, 152Б;62c3d54a-749b-11df-b112-00215aee3ebe
97838711-5a17-11ed-80eb-000c29800ae7;Львів-Любінська 89 Сім23;Львів вул. Любінська, 89;62c3d54a-749b-11df-b112-00215aee3ebe
48f0f180-2559-11ee-80bd-000c2961d091;Львів-Мазепи Гетьмана 11 Сільпо;Львів вул. Мазепи Гетьмана, 11;62c3d54a-749b-11df-b112-00215aee3ebe
b0698201-d58d-11ea-80ca-000c29800ae7;Львів-Мазепи Гетьмана 11/13 Мобі-Люкс;Львів вул. Мазепи Гетьмана, 11/13;62c3d54a-749b-11df-b112-00215aee3ebe
13b905d0-f265-11ec-80e7-000c29800ae7;Львів-Мазепи Гетьмана 1Б Rozetka;Львів вул. Мазепи Гетьмана, 1Б;62c3d54a-749b-11df-b112-00215aee3ebe
c10a8acc-2557-11ee-80bd-000c2961d091;Львів-Мазепи Гетьмана 1Б Сільпо;Львів вул. Мазепи Гетьмана, 1Б;62c3d54a-749b-11df-b112-00215aee3ebe
7bacf984-4da3-11ea-80c6-000c29800ae7;Львів-Мазепи Гетьмана 25А Vodafone;Львів вул. Мазепи Гетьмана, 25А;62c3d54a-749b-11df-b112-00215aee3ebe
187e5cca-5a16-11ed-80eb-000c29800ae7;Львів-Мазепи Гетьмана 25Д Сім23;Львів вул. Мазепи Гетьмана, 25Д;62c3d54a-749b-11df-b112-00215aee3ebe
38e3e754-cd1a-11eb-80d5-000c29800ae7;Львів-Малоголосківська 16А Свій маркет;Львів вул. Малоголосківська, 16А;62c3d54a-749b-11df-b112-00215aee3ebe
1ce2f1ab-95d7-11ee-80c1-000c2961d091;Львів-Миколайчука 22 Чистенько;Львів вул. Миколайчука, 22;62c3d54a-749b-11df-b112-00215aee3ebe
4f317198-b376-11ed-80ec-000c29800ae7;Львів-Митрополита Андрея 34 СПЕЦ;Львів вул. Митрополита Андрея, 34;62c3d54a-749b-11df-b112-00215aee3ebe
e32a0ad3-f0d3-11ed-80ed-000c29800ae7;Львів-Наукова 16 Свій маркет;Львів вул. Наукова, 16;62c3d54a-749b-11df-b112-00215aee3ebe
c61ccac0-20a1-11e9-80dc-1c98ec135261;Львів-Наукова 2А ОККО;Львів вул. Наукова, 2А;62c3d54a-749b-11df-b112-00215aee3ebe
80286cd2-c8c3-11ed-80ed-000c29800ae7;Львів-Наукова 2Ж Rozetka;Львів вул. Наукова, 2Ж;62c3d54a-749b-11df-b112-00215aee3ebe
97178404-2559-11ee-80bd-000c2961d091;Львів-Наукова 35А Сільпо;Львів вул. Наукова, 35А;62c3d54a-749b-11df-b112-00215aee3ebe
44be1039-51cf-11ec-80e4-000c29800ae7;Львів-Наукова 7А Angel Fit;Львів вул. Наукова, 7А;62c3d54a-749b-11df-b112-00215aee3ebe
568bfab8-fdf4-11ed-80ed-000c29800ae7;Львів-Низинна 17 Свій маркет;Львів вул. Низинна, 17;62c3d54a-749b-11df-b112-00215aee3ebe
55b96d96-29f2-11e9-80dd-1c98ec135261;Львів-Олекси Довбуша 1 Продукти ;Львів вул. Олекси Довбуша, 1;62c3d54a-749b-11df-b112-00215aee3ebe
b8acf05d-77f1-11ee-80c1-000c2961d091;Львів-Панча 16 Сяйво;Львів вул. Панча, 16;62c3d54a-749b-11df-b112-00215aee3ebe
d74f4288-2559-11ee-80bd-000c2961d091;Львів-Пасічна 164 Сільпо;Львів вул. Пасічна, 164;62c3d54a-749b-11df-b112-00215aee3ebe
cd3c825b-5a16-11ed-80eb-000c29800ae7;Львів-Пасічна 173 Сім23;Львів вул. Пасічна, 173;62c3d54a-749b-11df-b112-00215aee3ebe
93f46514-f0d4-11ed-80ed-000c29800ae7;Львів-Пасічна 42 Свій маркет;Львів вул. Пасічна, 42;62c3d54a-749b-11df-b112-00215aee3ebe
b59fa693-8702-11eb-80d2-000c29800ae7;Львів-Пасічна 43 Продукти;Львів вул. Пасічна, 43;62c3d54a-749b-11df-b112-00215aee3ebe
df35ca45-f0d4-11ed-80ed-000c29800ae7;Львів-Пасічна 59 Свій маркет;Львів вул. Пасічна, 59;62c3d54a-749b-11df-b112-00215aee3ebe
986f44d4-f0d5-11ed-80ed-000c29800ae7;Львів-Пекарська 31 Свій маркет;Львів вул. Пекарська, 31;62c3d54a-749b-11df-b112-00215aee3ebe
d58a4906-20a7-11e9-80dc-1c98ec135261;Львів-Петлюри 1 ОККО;Львів вул. Петлюри, 1;62c3d54a-749b-11df-b112-00215aee3ebe
9bc7e436-9b3d-11ee-80c1-000c2961d091;Львів-Пимоненка Миколи 20А Good FOOD;Львів вул. Пимоненка Миколи, 20А;62c3d54a-749b-11df-b112-00215aee3ebe
de730616-b9eb-11ee-80c4-000c2961d091;Львів-Під Голоском 17В ВсеБудЛюкс;Львів вул. Під Голоском, 17В;62c3d54a-749b-11df-b112-00215aee3ebe
a3245b1b-7eed-11ee-80c1-000c2961d091;Львів-Під Голоском 24 зоокрамниця Pets and Coffee;Львів вул. Під Голоском, 24;62c3d54a-749b-11df-b112-00215aee3ebe
1beeba0b-255a-11ee-80bd-000c2961d091;Львів-Під Дубом 7Б Сільпо;Львів вул. Під Дубом, 7Б;62c3d54a-749b-11df-b112-00215aee3ebe
7d1766f6-b6ff-11eb-80d4-000c29800ae7;Львів-Поштовий;Львів вул. Пасіки Зубрицькі (Заводська), 56;62c3d54a-749b-11df-b112-00215aee3ebe
3542301e-20a7-11e9-80dc-1c98ec135261;Львів-Рудненська 1 ОККО;Львів вул. Рудненська, 1;62c3d54a-749b-11df-b112-00215aee3ebe
b42c5c0c-2452-11ed-80e7-000c29800ae7;Львів-Руставелі Шота 34А Балакун;Львів вул. Руставелі Шота, 34А;62c3d54a-749b-11df-b112-00215aee3ebe
bad2f406-ae3b-11ed-80ec-000c29800ae7;Львів-Руставелі Шота 4 Сім23;Львів вул. Руставелі Шота, 4;62c3d54a-749b-11df-b112-00215aee3ebe
549146af-31f4-11ee-80bd-000c2961d091;Львів-Садова 2А Rozetka;Львів вул. Садова, 2А;62c3d54a-749b-11df-b112-00215aee3ebe
cb8cb9cc-7597-11ee-80c1-000c2961d091;Львів-Садова 2А Сільпо;Львів вул. Садова, 2А;62c3d54a-749b-11df-b112-00215aee3ebe
85b7f747-2556-11ee-80bd-000c2961d091;Львів-Сахарова 45 Сільпо;Львів вул. Сахарова, 45;62c3d54a-749b-11df-b112-00215aee3ebe
e4cb2080-95db-11ee-80c1-000c2961d091;Львів-Сахарова 6 Чистенько;Львів вул. Сахарова, 6;62c3d54a-749b-11df-b112-00215aee3ebe
1b140765-e341-11ed-80ed-000c29800ae7;Львів-Свободи 6/8 Lifecell;Львів Просп. Свободи, 6/8;62c3d54a-749b-11df-b112-00215aee3ebe
bd97d9c3-498c-11e9-80dd-1c98ec135261;Львів-Симиренків 17А Книги Канцтовари;Львів вул. Симиренків (Глухий Кут), 17А;62c3d54a-749b-11df-b112-00215aee3ebe
f06c2e1a-5a17-11ed-80eb-000c29800ae7;Львів-Сихівська 13 Сім23;Львів вул. Сихівська, 13;62c3d54a-749b-11df-b112-00215aee3ebe
0aabe8c3-ce13-11ed-80ed-000c29800ae7;Львів-Сихівська 8 Rozetka;Львів вул. Сихівська, 8;62c3d54a-749b-11df-b112-00215aee3ebe
4a1d35ce-255b-11ee-80bd-000c2961d091;Львів-Соборна 14-15 Сільпо;Львів пл. Соборна, 14-15;62c3d54a-749b-11df-b112-00215aee3ebe
60efc290-f264-11ec-80e7-000c29800ae7;Львів-Солодова 1 Rozetka;Львів вул. Солодова, 1;62c3d54a-749b-11df-b112-00215aee3ebe
9f90964b-4f42-11eb-80d0-000c29800ae7;Львів-Солодова 4 Мобі-Люкс;Львів вул. Солодова, 4;62c3d54a-749b-11df-b112-00215aee3ebe
df8e1a39-425d-11ee-80c0-000c2961d091;Львів-Стрийська 103А Сім23;Львів вул. Стрийська, 103А;62c3d54a-749b-11df-b112-00215aee3ebe
528ed8bd-abef-11ee-80c4-000c2961d091;Львів-Стрийська 113Г Львівські крани;Львів вул. Стрийська, 113Г;62c3d54a-749b-11df-b112-00215aee3ebe
ad091884-0c2e-11ee-80bd-000c2961d091;Львів-Стрийська 113Д Rozetka;Львів вул. Стрийська, 113Д;62c3d54a-749b-11df-b112-00215aee3ebe
3f419afc-9442-11ee-80c1-000c2961d091;Львів-Стрийська 113Д Чистенько;Львів вул. Стрийська, 113Д;62c3d54a-749b-11df-b112-00215aee3ebe
97efc414-7307-11ed-80ec-000c29800ae7;Львів-Стрийська 3 СПЕЦ;Львів вул. Стрийська, 3;62c3d54a-749b-11df-b112-00215aee3ebe
6fc2c2e5-e341-11ed-80ed-000c29800ae7;Львів-Стрийська 45 Lifecell;Львів вул. Стрийська, 45;62c3d54a-749b-11df-b112-00215aee3ebe
918f16c8-255b-11ee-80bd-000c2961d091;Львів-Стрийська 45 Сільпо;Львів вул. Стрийська, 45;62c3d54a-749b-11df-b112-00215aee3ebe
0619a722-abef-11ee-80c4-000c2961d091;Львів-Стрийська 45А Львівські крани;Львів вул. Стрийська, 45А;62c3d54a-749b-11df-b112-00215aee3ebe
f88435d1-944e-11ee-80c1-000c2961d091;Львів-Стрийська 45А Чистенько;Львів вул. Стрийська, 45А;62c3d54a-749b-11df-b112-00215aee3ebe
05406421-990a-11ee-80c1-000c2961d091;Львів-Тернопільська 21Е Товари для дому;Львів вул. Тернопільська, 21Е;62c3d54a-749b-11df-b112-00215aee3ebe
52f26408-95dc-11ee-80c1-000c2961d091;Львів-Трускавецька 131 Чистенько;Львів вул. Трускавецька, 131;62c3d54a-749b-11df-b112-00215aee3ebe
3b2b5ce2-f0d7-11ed-80ed-000c29800ae7;Львів-Трускавецька 2Б Свій маркет;Львів вул. Трускавецька, 2Б;62c3d54a-749b-11df-b112-00215aee3ebe
a75b2b73-ce12-11ed-80ed-000c29800ae7;Львів-Угорська 12 Rozetka;Львів вул. Угорська, 12;62c3d54a-749b-11df-b112-00215aee3ebe
09ff5de5-51b8-11ec-80e4-000c29800ae7;Львів-Франка Івана 59 СЦ IT TOP;Львів вул. Франка Івана, 59;62c3d54a-749b-11df-b112-00215aee3ebe
7472275f-4f44-11eb-80d0-000c29800ae7;Львів-Франка Івана 81 Мобі-Люкс;Львів вул. Франка Івана, 81;62c3d54a-749b-11df-b112-00215aee3ebe
9ec2f7d9-7011-11e6-b3d3-001ec93df11f;Львів-Хмельницького 172;Львів вул. Хмельницького Богдана, 172;62c3d54a-749b-11df-b112-00215aee3ebe
560284a5-b378-11ed-80ec-000c29800ae7;Львів-Хмельницького Богдана 105 СПЕЦ;Львів вул. Хмельницького Богдана, 105;62c3d54a-749b-11df-b112-00215aee3ebe
fca68abf-40e0-11ee-80c0-000c2961d091;Львів-Хмельницького Богдана 21 Продукти;Львів вул. Хмельницького Богдана, 21;62c3d54a-749b-11df-b112-00215aee3ebe
b7a4ee1c-f265-11ec-80e7-000c29800ae7;Львів-Хмельницького Богдана 214 Rozetka;Львів вул. Хмельницького Богдана, 214;62c3d54a-749b-11df-b112-00215aee3ebe
c1773b0d-2556-11ee-80bd-000c2961d091;Львів-Хмельницького Богдана 214 Сільпо;Львів вул. Хмельницького Богдана, 214;62c3d54a-749b-11df-b112-00215aee3ebe
ca11fdd6-20a9-11e9-80dc-1c98ec135261;Львів-Хмельницького Богдана 292 ОККО;Львів вул. Хмельницького Богдана, 292;62c3d54a-749b-11df-b112-00215aee3ebe
68d3c761-2900-11eb-80ce-000c29800ae7;Львів-Хорватська 16А iTechno;Львів вул. Хорватська, 16А;62c3d54a-749b-11df-b112-00215aee3ebe
9be361f1-d8bc-11ea-80ca-000c29800ae7;Львів-Червоної Калини 34А ОККО;Львів Просп. Червоної Калини, 34А;62c3d54a-749b-11df-b112-00215aee3ebe
0b655d77-4648-11e9-80dd-1c98ec135261;Львів-Червоної Калини 35А ОККО;Львів Просп. Червоної Калини, 35А;62c3d54a-749b-11df-b112-00215aee3ebe
145b8d42-f267-11ec-80e7-000c29800ae7;Львів-Червоної Калини 36 Rozetka;Львів Просп. Червоної Калини, 36;62c3d54a-749b-11df-b112-00215aee3ebe
39bbbbb6-573a-11ec-80e4-000c29800ae7;Львів-Червоної Калини 36 ВелоБайк;Львів Просп. Червоної Калини, 36;62c3d54a-749b-11df-b112-00215aee3ebe
c783310a-255b-11ee-80bd-000c2961d091;Львів-Червоної Калини 62 Сільпо;Львів Просп. Червоної Калини, 62;62c3d54a-749b-11df-b112-00215aee3ebe
f652c0c9-8b60-11e8-80d4-1c98ec135261;Львів-Червоної Калини 77 НЕОН СЕРВІС;Львів Просп. Червоної Калини, 77;62c3d54a-749b-11df-b112-00215aee3ebe
c028e287-e341-11ed-80ed-000c29800ae7;Львів-Чернівецька 13/15 Lifecell;Львів вул. Чернівецька, 13/15;62c3d54a-749b-11df-b112-00215aee3ebe
8a06eff6-abed-11ee-80c4-000c2961d091;Львів-Чорновола В'ячеслава 103 Львівські крани;Львів Просп. Чорновола В'ячеслава, 103;62c3d54a-749b-11df-b112-00215aee3ebe
6974972a-7b9e-11e9-80e0-1c98ec135261;Львів-Чорновола В'ячеслава 14 ОККО;Львів Просп. Чорновола В'ячеслава, 14;62c3d54a-749b-11df-b112-00215aee3ebe
7c85c926-dc53-11ee-80c4-000c2961d091;Львів-Чорновола В'ячеслава 16І Hot-case;Львів Просп. Чорновола В'ячеслава, 16І;62c3d54a-749b-11df-b112-00215aee3ebe
3bbd2d1b-f266-11ec-80e7-000c29800ae7;Львів-Чорновола В'ячеслава 57 Rozetka;Львів Просп. Чорновола В'ячеслава, 57;62c3d54a-749b-11df-b112-00215aee3ebe
5ac2d958-255c-11ee-80bd-000c2961d091;Львів-Чорновола В'ячеслава 77 Сільпо;Львів Просп. Чорновола В'ячеслава, 77;62c3d54a-749b-11df-b112-00215aee3ebe
7e39ec43-48fd-11eb-80d0-000c29800ae7;Львів-Чупринки Генерала 16 Мобільні Дрібниці;Львів вул. Чупринки Генерала, 16;62c3d54a-749b-11df-b112-00215aee3ebe
a8056ddf-f0d7-11ed-80ed-000c29800ae7;Львів-Шараневича 1 Свій маркет;Львів вул. Шараневича, 1;62c3d54a-749b-11df-b112-00215aee3ebe
50ac7304-944b-11ee-80c1-000c2961d091;Львів-Шевченка 111 Чистенько;Львів вул. Шевченка, 111;62c3d54a-749b-11df-b112-00215aee3ebe
ae845161-abec-11ee-80c4-000c2961d091;Львів-Шевченка 19 Львівські крани;Львів вул. Шевченка, 19;62c3d54a-749b-11df-b112-00215aee3ebe
b2fbeed6-f266-11ec-80e7-000c29800ae7;Львів-Шевченка 24 Rozetka;Львів Просп. Шевченка (просп.), 24;62c3d54a-749b-11df-b112-00215aee3ebe
f72f5017-0ec3-11eb-80cd-000c29800ae7;Львів-Шевченка 313 ОККО;Львів вул. Шевченка, 313;62c3d54a-749b-11df-b112-00215aee3ebe
91efe07c-5174-11ee-80c0-000c2961d091;Львів-Шевченка 35 СПЕЦ;Львів вул. Шевченка, 35;62c3d54a-749b-11df-b112-00215aee3ebe
22902087-255c-11ee-80bd-000c2961d091;Львів-Шевченка 358А Сільпо;Львів вул. Шевченка, 358А;62c3d54a-749b-11df-b112-00215aee3ebe
d3ba25d7-f0ce-11ed-80ed-000c29800ae7;Львів-Шевченка 45 Свій маркет;Львів вул. Шевченка, 45;62c3d54a-749b-11df-b112-00215aee3ebe
880f9ac3-f0ce-11ed-80ed-000c29800ae7;Львів-Шевченка 60 Свій маркет;Львів вул. Шевченка, 60;62c3d54a-749b-11df-b112-00215aee3ebe
a19e8e8b-255c-11ee-80bd-000c2961d091;Львів-Шевченка 60 Сільпо;Львів вул. Шевченка, 60;62c3d54a-749b-11df-b112-00215aee3ebe
e02b6157-9442-11ee-80c1-000c2961d091;Львів-Шевченка 60 Чистенько;Львів вул. Шевченка, 60;62c3d54a-749b-11df-b112-00215aee3ebe
41213f45-726b-11ee-80c1-000c2961d091;Львів-Шевченка 8 Lifecell;Львів вул. Шевченка, 8;62c3d54a-749b-11df-b112-00215aee3ebe
a5073743-9dc5-11eb-80d3-000c29800ae7;Львів-Шевченка 96 Балакун;Львів вул. Шевченка, 96;62c3d54a-749b-11df-b112-00215aee3ebe
2e666e67-d093-11e9-80e4-1c98ec135261;Львів-Широка 66 Балакун;Львів вул. Широка, 66;62c3d54a-749b-11df-b112-00215aee3ebe
6127edc5-0846-11ed-80e7-000c29800ae7;Львів-Широка 83В Rozetka;Львів вул. Широка, 83В;62c3d54a-749b-11df-b112-00215aee3ebe
0b64fe17-255d-11ee-80bd-000c2961d091;Львів-Широка 87 Сільпо;Львів вул. Широка, 87;62c3d54a-749b-11df-b112-00215aee3ebe
5f436c18-20aa-11e9-80dc-1c98ec135261;Львів-Широка 97 ОККО;Львів вул. Широка, 97;62c3d54a-749b-11df-b112-00215aee3ebe
f386c060-7190-11e9-80df-1c98ec135261;Львів-Шолом-Алейхема 18 Сервісний Центр;Львів вул. Шолом-Алейхема, 18;62c3d54a-749b-11df-b112-00215aee3ebe
857c5ee4-966b-11e9-80e2-1c98ec135261;Львів-Юнаківа 9Б Сім23;Львів вул. Юнаківа, 9Б;62c3d54a-749b-11df-b112-00215aee3ebe
782b27ed-76fe-11e9-80e0-1c98ec135261;Львів-Яцкова 25 Сервісний Центр;Львів вул. Яцкова, 25;62c3d54a-749b-11df-b112-00215aee3ebe
12b29aa1-c920-11eb-80d5-000c29800ae7;МTERNOP;;62c3d54a-749b-11df-b112-00215aee3ebe
f45c3471-051c-11ee-80bd-000c2961d091;Магала-Чернівецька 3А;Магала вул. Чернівецька, 3А;62c3d55c-749b-11df-b112-00215aee3ebe
c5624fa8-b9cb-11ed-80ed-000c29800ae7;Макарів-Ростовського 34А;Макарів вул. Ростовського (Фрунзе), 34А;68cf2328-749b-11df-b112-00215aee3ebe
055fb070-7bdd-11ed-80ec-000c29800ae7;Макарів-Ростовського 40/2;Макарів вул. Ростовського (Фрунзе), 40/2;68cf2328-749b-11df-b112-00215aee3ebe
3ecd145c-a7bb-11e9-80e4-1c98ec135261;Мала Виска-Велігіна 76 А;Мала Виска вул. Велігіна, 76А;68cf23a5-749b-11df-b112-00215aee3ebe
1607b6a4-f044-11eb-80d5-000c29800ae7;Мала Виска-Центральна 66;Мала Виска вул. Центральна, 66;68cf23a5-749b-11df-b112-00215aee3ebe
215bbf72-d0b5-11ea-80ca-000c29800ae7;Мала Виска-Шевченка 60;Мала Виска вул. Шевченка, 60;68cf23a5-749b-11df-b112-00215aee3ebe
dce2b917-c810-11ee-80c4-000c2961d091;Мала Виска-Шевченка ЖЖУК;Мала Виска вул. Шевченка,;68cf23a5-749b-11df-b112-00215aee3ebe
27d1d601-5c64-11ea-80c6-000c29800ae7;Малехів;Малехів вул. Вокзальна, 15;68cf2437-749b-11df-b112-00215aee3ebe
9812fa88-501a-11eb-80d0-000c29800ae7;Малий Березний-Ублянська 34;Малий Березний вул. Ублянська, 34;68cf243c-749b-11df-b112-00215aee3ebe
74e0a2b8-68b6-11ec-80e4-000c29800ae7;Малин-Гагаріна Юрія 17А;Малин вул. Івана Мазепи (Гагаріна Юрія), 17А;68cf247a-749b-11df-b112-00215aee3ebe
b01ff02f-0be9-11ed-80e7-000c29800ae7;Малин-Грушевського Михайла 1 А;Малин вул. Грушевського Михайла, 1А;68cf247a-749b-11df-b112-00215aee3ebe
4491f87b-7598-11ee-80c1-000c2961d091;Малин-Грушевського Михайла 43;Малин вул. Грушевського Михайла, 43;68cf247a-749b-11df-b112-00215aee3ebe
c119d26c-a973-11ee-80c4-000c2961d091;Малин-Грушевського Михайла 47;Малин вул. Грушевського Михайла, 47;68cf247a-749b-11df-b112-00215aee3ebe
14b367f4-a4af-11e9-80e2-1c98ec135261;Малодолинське-Космонавтів 61;Малодолинське вул. Космонавтів, 61;68cf24fe-749b-11df-b112-00215aee3ebe
1e8c47d9-b367-11e9-80e4-1c98ec135261;Мамрин-Польова 75;Мамрин вул. Польова, 75;68cf2540-749b-11df-b112-00215aee3ebe
2f4f0bea-e3b0-11eb-80d5-000c29800ae7;Маневичі-100-річчя Маневичів 14;Маневичі вул. 100-річчя Маневичів, 14;68cf2548-749b-11df-b112-00215aee3ebe
7fdb2207-f55a-11ed-80ed-000c29800ae7;Маневичі-Луцька 10;Маневичі вул. Луцька, 10;68cf2548-749b-11df-b112-00215aee3ebe
776cf64f-63eb-11ec-80e4-000c29800ae7;Маньківка-Благодатна 2/4;Маньківка вул. Благодатна, 2/4;68cf2567-749b-11df-b112-00215aee3ebe
86f4a4f6-5eac-11ee-80c0-000c2961d091;Марганець-Єдності 164;Марганець вул. Єдності ( Радянська ), 164;68cf2571-749b-11df-b112-00215aee3ebe
c06ae022-3ce9-11ee-80c0-000c2961d091;Марганець-Київська 143А;Марганець вул. Київська, 143А;68cf2571-749b-11df-b112-00215aee3ebe
eb671d89-5eab-11ee-80c0-000c2961d091;Марганець-Київська 149Б;Марганець вул. Київська, 149Б;68cf2571-749b-11df-b112-00215aee3ebe
9c3069e5-2e32-11eb-80ce-000c29800ae7;Марганець-Лермонтова 12 ;Марганець вул. Лермонтова, 12;68cf2571-749b-11df-b112-00215aee3ebe
01ffbe9c-58e4-11ec-80e4-000c29800ae7;Мархалівка-Антонова 1А;Мархалівка вул. Антонова, 1А;68cf2605-749b-11df-b112-00215aee3ebe
27ccc5c0-0195-11ec-80d6-000c29800ae7;Мачухи-Котляревського 32А;Мачухи вул. Котляревського, 32А;68cf26ae-749b-11df-b112-00215aee3ebe
0c79f53d-3896-11eb-80cf-000c29800ae7;Мачухи-Радянська 4;Мачухи вул. Радянська, 4;68cf26ae-749b-11df-b112-00215aee3ebe
e58b6f31-3f9e-11eb-80d0-000c29800ae7;Машівка-Незалежності 117;Машівка вул. Незалежності, 117;68cf26b6-749b-11df-b112-00215aee3ebe
d3bd6641-5791-11e9-80df-1c98ec135261;Мерефа-Дніпровська 218;Мерефа вул. Дніпровська, 218;68cf274f-749b-11df-b112-00215aee3ebe
41e57230-cf13-11ee-80c4-000c2961d091;Мерефа-Дніпропетровська 200;Мерефа вул. Дніпропетровська, 200;68cf274f-749b-11df-b112-00215aee3ebe
9965a954-d8e7-11eb-80d5-000c29800ae7;Мигове-Центральна 145;Мигове вул. Центральна, 145;68cf276f-749b-11df-b112-00215aee3ebe
75ec298f-a8dd-11de-bac3-0030485903e8;Миколаїв;Миколаїв вул. Казарського, 3 K/4;68cf2798-749b-11df-b112-00215aee3ebe
1c33cb10-f5c7-11eb-80d5-000c29800ae7;Миколаїв(Львів.обл.)-Галицького Данила 12;Миколаїв вул. Галицького Данила, 12;68cf279d-749b-11df-b112-00215aee3ebe
3307ff57-f9c4-11eb-80d5-000c29800ae7;Миколаїв(Львів.обл.)-Шептицького 17;Миколаїв вул. Шептицького, 17;68cf279d-749b-11df-b112-00215aee3ebe
81eb6ed2-92b8-11ee-80c1-000c2961d091;Миколаїв-3-я Слобідська 49 Кружка;Миколаїв вул. 3-я Слобідська, 49;68cf2798-749b-11df-b112-00215aee3ebe
087edc65-87af-11ee-80c1-000c2961d091;Миколаїв-6-а Слобідська 48 Копійка;Миколаїв вул. 6-а Слобідська, 48;68cf2798-749b-11df-b112-00215aee3ebe
bcabae98-ec1f-11ee-80c5-000c2961d091;Миколаїв-Адмірала Макарова 60Г Тютюн напої;Миколаїв вул. Адмірала Макарова, 60Г;68cf2798-749b-11df-b112-00215aee3ebe
702702a6-a0d1-11ee-80c4-000c2961d091;Миколаїв-Богоявленський 305 Бельевик;Миколаїв Просп. Богоявленський (Жовтневий), 305;68cf2798-749b-11df-b112-00215aee3ebe
a7291b4d-913e-11ee-80c1-000c2961d091;Миколаїв-Богоявленський 342А/1 OptCase;Миколаїв Просп. Богоявленський (Жовтневий), 342А/1;68cf2798-749b-11df-b112-00215aee3ebe
30169b5a-47da-11ee-80c0-000c2961d091;Миколаїв-Богоявленський 4 Кефір;Миколаїв Просп. Богоявленський (Жовтневий), 4;68cf2798-749b-11df-b112-00215aee3ebe
6adfea6f-a196-11ea-80c8-000c29800ae7;Миколаїв-Богоявленський 41 Ломбард;Миколаїв Просп. Богоявленський (Жовтневий), 41;68cf2798-749b-11df-b112-00215aee3ebe
26e253a2-92b6-11ee-80c1-000c2961d091;Миколаїв-Богоявленський 55 Кружка;Миколаїв Просп. Богоявленський (Жовтневий), 55;68cf2798-749b-11df-b112-00215aee3ebe
cd99cc55-0aaf-11e9-80d9-1c98ec135261;Миколаїв-Будівельників 18 Любимчик;Миколаїв вул. Будівельників, 18;68cf2798-749b-11df-b112-00215aee3ebe
ce0ac4ff-e5c4-11ed-80ed-000c29800ae7;Миколаїв-Возз'єднання 17;Миколаїв вул. Возз'єднання, 17;68cf279d-749b-11df-b112-00215aee3ebe
165b1027-92bb-11ee-80c1-000c2961d091;Миколаїв-Генерала Карпенка 22А Кружка;Миколаїв вул. Генерала Карпенка, 22А;68cf2798-749b-11df-b112-00215aee3ebe
114d9559-7baa-11e9-80e0-1c98ec135261;Миколаїв-Генерала Карпенка 55 ОККО;Миколаїв вул. Генерала Карпенка, 55;68cf2798-749b-11df-b112-00215aee3ebe
1478a854-92bc-11ee-80c1-000c2961d091;Миколаїв-Генерала Карпенка 9 Кружка;Миколаїв вул. Генерала Карпенка, 9;68cf2798-749b-11df-b112-00215aee3ebe
2b53b3c6-92b9-11ee-80c1-000c2961d091;Миколаїв-Героїв України 101/1 Кружка;Миколаїв Просп. Героїв України /Героїв Сталінграда/, 101/1;68cf2798-749b-11df-b112-00215aee3ebe
5e1084d9-c039-11ee-80c4-000c2961d091;Миколаїв-Героїв України 103/1 Rozetka;Миколаїв Просп. Героїв України /Героїв Сталінграда/, 103/1;68cf2798-749b-11df-b112-00215aee3ebe
adbf10a5-100f-11ee-80bd-000c2961d091;Миколаїв-Героїв України 13 Lifecell;Миколаїв Просп. Героїв України /Героїв Сталінграда/, 13;68cf2798-749b-11df-b112-00215aee3ebe
908c69b9-92b9-11ee-80c1-000c2961d091;Миколаїв-Героїв України 21/7б Кружка;Миколаїв Просп. Героїв України /Героїв Сталінграда/, 21/7б;68cf2798-749b-11df-b112-00215aee3ebe
0e6891be-a0cc-11ee-80c4-000c2961d091;Миколаїв-Героїв України 93/1 Ветеринарна аптека;Миколаїв Просп. Героїв України /Героїв Сталінграда/, 93/1;68cf2798-749b-11df-b112-00215aee3ebe
8b9745e4-d18d-11ee-80c4-000c2961d091;Миколаїв-Глінки 3 SEGMENT;Миколаїв вул. Глінки, 3;68cf2798-749b-11df-b112-00215aee3ebe
d9c0b9b3-47d9-11ee-80c0-000c2961d091;Миколаїв-Громадянська 46В Кефір;Миколаїв вул. Громадянська, 46В;68cf2798-749b-11df-b112-00215aee3ebe
33ff4f44-719f-11ee-80c1-000c2961d091;Миколаїв-Грушевського Михайла 14;Миколаїв Просп. Грушевського Михайла, 14;68cf279d-749b-11df-b112-00215aee3ebe
8b654ad2-8de7-11ee-80c1-000c2961d091;Миколаїв-Індустріальна 3А/3 АЗС MAN GO;Миколаїв вул. Індустріальна, 3А/3;68cf2798-749b-11df-b112-00215aee3ebe
e5befae2-cfcc-11ee-80c4-000c2961d091;Миколаїв-Корабелів (Набережна) 17 Moa Food;Миколаїв вул. Корабелів, 17;68cf2798-749b-11df-b112-00215aee3ebe
72b20f8e-d47b-11ed-80ed-000c29800ae7;Миколаїв-Корабелів 12 Tabaco Market;Миколаїв вул. Корабелів, 12;68cf2798-749b-11df-b112-00215aee3ebe
992bc65a-853b-11ee-80c1-000c2961d091;Миколаїв-Корабелів 12/13 Rozetka;Миколаїв Просп. Корабелів, 12/13;68cf2798-749b-11df-b112-00215aee3ebe
d83c2b8b-aa24-11ee-80c4-000c2961d091;Миколаїв-Корабелів 14 MIDA;Миколаїв Просп. Корабелів, 14;68cf2798-749b-11df-b112-00215aee3ebe
26b658f2-77e1-11ee-80c1-000c2961d091;Миколаїв-Корабелів 8А Кавярня;Миколаїв вул. Корабелів, 8А;68cf2798-749b-11df-b112-00215aee3ebe
57a75c86-92b5-11ee-80c1-000c2961d091;Миколаїв-Космонавтів 104А Кружка;Миколаїв вул. Космонавтів, 104А;68cf2798-749b-11df-b112-00215aee3ebe
8a656dd5-87ae-11ee-80c1-000c2961d091;Миколаїв-Космонавтів 152 Копійка;Миколаїв вул. Космонавтів, 152;68cf2798-749b-11df-b112-00215aee3ebe
89bc6b88-d614-11ee-80c4-000c2961d091;Миколаїв-Космонавтів 156/1 Мобілочка;Миколаїв вул. Космонавтів, 156/1;68cf2798-749b-11df-b112-00215aee3ebe
c3605300-92b5-11ee-80c1-000c2961d091;Миколаїв-Космонавтів 53 Кружка;Миколаїв вул. Космонавтів, 53;68cf2798-749b-11df-b112-00215aee3ebe
ec0fad50-47da-11ee-80c0-000c2961d091;Миколаїв-Космонавтів 53/1Ж Продукти;Миколаїв вул. Космонавтів, 53/1Ж;68cf2798-749b-11df-b112-00215aee3ebe
f7661666-368f-11e9-80dd-1c98ec135261;Миколаїв-Космонавтів 93А ОККО;Миколаїв вул. Космонавтів, 93А;68cf2798-749b-11df-b112-00215aee3ebe
30b0ce3f-99a7-11ee-80c1-000c2961d091;Миколаїв-Курортна 9 City Gym;Миколаїв вул. Курортна, 9;68cf2798-749b-11df-b112-00215aee3ebe
273ea058-d614-11ee-80c4-000c2961d091;Миколаїв-Лазурна 16 Мобілочка;Миколаїв вул. Лазурна, 16;68cf2798-749b-11df-b112-00215aee3ebe
7fd9afcf-47d9-11ee-80c0-000c2961d091;Миколаїв-Мала Морська 39 Green express;Миколаїв вул. Мала Морська, 39;68cf2798-749b-11df-b112-00215aee3ebe
11edb457-47f0-11ee-80c0-000c2961d091;Миколаїв-Миколаївська 11А/1 Везунчик;Миколаїв вул. Миколаївська, 11А/1;68cf2798-749b-11df-b112-00215aee3ebe
33648afb-47db-11ee-80c0-000c2961d091;Миколаїв-Миколаївська 26/6 Продукти;Миколаїв вул. Миколаївська, 26/6;68cf2798-749b-11df-b112-00215aee3ebe
eb04cba7-92b7-11ee-80c1-000c2961d091;Миколаїв-Миру 14Б Кружка;Миколаїв Просп. Миру, 14Б;68cf2798-749b-11df-b112-00215aee3ebe
522faaea-87af-11ee-80c1-000c2961d091;Миколаїв-Миру 17г/5 Копійка;Миколаїв Просп. Миру, 17г/5;68cf2798-749b-11df-b112-00215aee3ebe
7e437074-a0cc-11ee-80c4-000c2961d091;Миколаїв-Миру 2 Ветеринарна аптека;Миколаїв Просп. Миру, 2;68cf2798-749b-11df-b112-00215aee3ebe
e466ff03-2287-11eb-80cd-000c29800ae7;Миколаїв-Миру 2А ЮВІЛЕЙНИЙ;Миколаїв Просп. Миру, 2А;68cf2798-749b-11df-b112-00215aee3ebe
d48a12f0-8e89-11ed-80ec-000c29800ae7;Миколаїв-Миру 56В Rozetka;Миколаїв Просп. Миру, 56В;68cf2798-749b-11df-b112-00215aee3ebe
4a0a06a1-47d8-11ee-80c0-000c2961d091;Миколаїв-Миру 9А AlkoBasket;Миколаїв Просп. Миру, 9А;68cf2798-749b-11df-b112-00215aee3ebe
b0875322-4635-11ec-80e3-000c29800ae7;Миколаїв-Московська 56А Автозапчастини;Миколаїв вул. Маріупольська (Московська), 56А;68cf2798-749b-11df-b112-00215aee3ebe
81017451-a56c-11ee-80c4-000c2961d091;Миколаїв-Нікольська 32 Книжка;Миколаїв вул. Нікольська, 32;68cf2798-749b-11df-b112-00215aee3ebe
1d7f19b7-0c3f-11ee-80bd-000c2961d091;Миколаїв-Одеське 88 Rozetka;Миколаїв шосе Одеське (Варварівка), 88;68cf2798-749b-11df-b112-00215aee3ebe
03904bc3-92ba-11ee-80c1-000c2961d091;Миколаїв-Одеське шосе 88 Кружка;Миколаїв шосе Одеське (Варварівка), 88;68cf2798-749b-11df-b112-00215aee3ebe
f04a93ec-82eb-11e9-80e0-1c98ec135261;Миколаїв-Озерна 11 Фото Ксерокс;Миколаїв вул. Озерна (Червоних Майовщиків), 11;68cf2798-749b-11df-b112-00215aee3ebe
47a1cec7-d47c-11ed-80ed-000c29800ae7;Миколаїв-Озерна 2/2 Tabaco Market;Миколаїв вул. Озерна (Червоних Майовщиків), 2/2;68cf2798-749b-11df-b112-00215aee3ebe
0d9c4265-9e58-11ed-80ec-000c29800ae7;Миколаїв-Озерна 2В Rozetka;Миколаїв вул. Озерна (Червоних Майовщиків), 2В;68cf2798-749b-11df-b112-00215aee3ebe
4094e60e-cfcd-11ee-80c4-000c2961d091;Миколаїв-Омеляновича-Павленка(Морська) 58 Moa Food;Миколаїв вул. Омеляновича-Павленка, 58;68cf2798-749b-11df-b112-00215aee3ebe
6f8e66f5-92ba-11ee-80c1-000c2961d091;Миколаїв-Петрової Галини 6А Кружка;Миколаїв вул. Петрової Галини, 6;68cf2798-749b-11df-b112-00215aee3ebe
6def7948-92b7-11ee-80c1-000c2961d091;Миколаїв-Південна 52 Кружка;Миколаїв вул. Південна, 52;68cf2798-749b-11df-b112-00215aee3ebe
3ddfe67d-15c3-11ee-80bd-000c2961d091;Миколаїв-Погранична 47Б АЗС маркет;Миколаїв вул. Погранична, 47Б;68cf2798-749b-11df-b112-00215aee3ebe
800dce0d-a574-11ee-80c4-000c2961d091;Миколаїв-Потьомкінська 131 А Бельевик;Миколаїв вул. Потьомкінська, 131А;68cf2798-749b-11df-b112-00215aee3ebe
f74b99d8-394e-11eb-80cf-000c29800ae7;Миколаїв-Потьомкінська 69А Smart Service;Миколаїв вул. Потьомкінська, 69А;68cf2798-749b-11df-b112-00215aee3ebe
ba1ddfc5-edff-11eb-80d5-000c29800ae7;Миколаїв-Привільна 87Г Eclipse АГК Ракета;Миколаїв вул. Привільна, 87Г;68cf2798-749b-11df-b112-00215aee3ebe
af7529b6-47ef-11ee-80c0-000c2961d091;Миколаїв-Райдужна 63 Везунчик;Миколаїв вул. Райдужна, 63;68cf2798-749b-11df-b112-00215aee3ebe
2c1377b4-47d9-11ee-80c0-000c2961d091;Миколаїв-Соборна 2 Грін маркет;Миколаїв вул. Соборна (Радянська), 2;68cf2798-749b-11df-b112-00215aee3ebe
c47decd9-aa26-11ee-80c4-000c2961d091;Миколаїв-Соборна 9 MIDA;Миколаїв вул. Соборна (Радянська), 9;68cf2798-749b-11df-b112-00215aee3ebe
07a4c7de-d700-11ee-80c4-000c2961d091;Миколаїв-Старова 46;Миколаїв вул. Старова Архітектора, 46;68cf2798-749b-11df-b112-00215aee3ebe
e567a9f8-aa25-11ee-80c4-000c2961d091;Миколаїв-Старова Архітектора 6А MIDA;Миколаїв вул. Старова Архітектора, 6А;68cf2798-749b-11df-b112-00215aee3ebe
b9a95def-7e22-11ee-80c1-000c2961d091;Миколаїв-Старофортечна 1/2 Дольче;Миколаїв вул. Старофортечна, 1/2;68cf2798-749b-11df-b112-00215aee3ebe
49afa188-9d25-11eb-80d3-000c29800ae7;Миколаїв-Центральний 122/10 City Gym;Миколаїв Просп. Центральний, 122/10;68cf2798-749b-11df-b112-00215aee3ebe
11541149-1010-11ee-80bd-000c2961d091;Миколаїв-Центральний 154 Lifecell;Миколаїв Просп. Центральний, 154;68cf2798-749b-11df-b112-00215aee3ebe
5ee4be17-ff3c-11eb-80d6-000c29800ae7;Миколаїв-Центральний 166 Європошта;Миколаїв Просп. Центральний, 166;68cf2798-749b-11df-b112-00215aee3ebe
bbf756aa-f25f-11ec-80e7-000c29800ae7;Миколаїв-Центральний 171/2 Rozetka;Миколаїв Просп. Центральний, 171/2;68cf2798-749b-11df-b112-00215aee3ebe
e5cecbf4-d47b-11ed-80ed-000c29800ae7;Миколаїв-Центральний 24/11 Tabaco Market;Миколаїв Просп. Центральний, 24/11;68cf2798-749b-11df-b112-00215aee3ebe
874c5cd4-759d-11ee-80c1-000c2961d091;Миколаїв-Центральний 259/1 Сільпо;Миколаїв Просп. Центральний, 259/1;68cf2798-749b-11df-b112-00215aee3ebe
b4ad3d50-d479-11ed-80ed-000c29800ae7;Миколаїв-Центральний 63/2 Tabaco Market;Миколаїв Просп. Центральний, 63/2;68cf2798-749b-11df-b112-00215aee3ebe
9660af4e-47da-11ee-80c0-000c2961d091;Миколаїв-Центральний 74/8 Гастроном;Миколаїв Просп. Центральний, 74/8;68cf2798-749b-11df-b112-00215aee3ebe
d7ef80db-ca5e-11ed-80ed-000c29800ae7;Миколаїв-Центральний 74Б Rozetka;Миколаїв Просп. Центральний, 74Б;68cf2798-749b-11df-b112-00215aee3ebe
9e07a62f-d47a-11ed-80ed-000c29800ae7;Миколаїв-Центральний 78/1 Tabaco Market;Миколаїв Просп. Центральний, 78/1;68cf2798-749b-11df-b112-00215aee3ebe
c0ee26b7-92ba-11ee-80c1-000c2961d091;Миколаїв-Центральний 8 Кружка;Миколаїв Просп. Центральний, 8;68cf2798-749b-11df-b112-00215aee3ebe
ae918691-92e1-11ee-80c1-000c2961d091;Миколаїв-Центральний 85 Джерело здоровя;Миколаїв Просп. Центральний, 85;68cf2798-749b-11df-b112-00215aee3ebe
3c8a19b7-7599-11ee-80c1-000c2961d091;Миколаїв-Центральний 98 Сільпо;Миколаїв Просп. Центральний, 98;68cf2798-749b-11df-b112-00215aee3ebe
d5cc925d-7598-11ee-80c1-000c2961d091;Миколаїв-Чкалова 82 Сільпо;Миколаїв вул. Чкалова, 82;68cf2798-749b-11df-b112-00215aee3ebe
db45e0e6-92d5-11ee-80c1-000c2961d091;Миколаїв-Шосейна 11 Золоте теля;Миколаїв вул. Шосейна (Фрунзе), 11;68cf2798-749b-11df-b112-00215aee3ebe
5d93dcc2-aa26-11ee-80c4-000c2961d091;Миколаїв-Шосейна 15/1 MIDA;Миколаїв вул. Шосейна (Фрунзе), 15/1;68cf2798-749b-11df-b112-00215aee3ebe
fa9426a6-aeb2-11ed-80ec-000c29800ae7;Миколаїв-Шосейна 33 Тренажерний зал City Gym;Миколаїв вул. Шосейна (Фрунзе), 33;68cf2798-749b-11df-b112-00215aee3ebe
cb7eab4a-4b13-11e9-80dd-1c98ec135261;Микулинці-22-го Січня 4 А;Микулинці вул. 22-го Січня, 4А;68cf2807-749b-11df-b112-00215aee3ebe
6a5cc32a-64ba-11ec-80e4-000c29800ae7;Минай-Б.Хмельницького 4А Мініма;Минай вул. Б.Хмельницького, 4А;68cf282b-749b-11df-b112-00215aee3ebe
03a733c6-d8be-11ea-80ca-000c29800ae7;Минай-Свободи 1 ОККО;Минай вул. Свободи, 1;68cf282b-749b-11df-b112-00215aee3ebe
96dd0e88-f658-11ed-80ed-000c29800ae7;Миргород-Гоголя Миколи 123;Миргород вул. Гоголя Миколи, 123;68cf2835-749b-11df-b112-00215aee3ebe
6320edc1-b841-11ed-80ed-000c29800ae7;Миргород-Гоголя Миколи 147;Миргород вул. Гоголя Миколи, 147;68cf2835-749b-11df-b112-00215aee3ebe
8b8ed4b8-1704-11ed-80e7-000c29800ae7;Миргород-Гоголя Миколи 98/6;Миргород вул. Гоголя Миколи, 98/6;68cf2835-749b-11df-b112-00215aee3ebe
10cfbfee-759e-11ee-80c1-000c2961d091;Миргород-Гоголя Миколи 98/6 Сільпо;Миргород вул. Гоголя Миколи, 98/6;68cf2835-749b-11df-b112-00215aee3ebe
decd8dae-d844-11ed-80ed-000c29800ae7;Миргород-Почапці 37;Миргород вул. Почапці, 37;68cf2835-749b-11df-b112-00215aee3ebe
4b57471f-a974-11ee-80c4-000c2961d091;Миронівка-Бузницького 6/1;Миронівка вул. Бузницького, 6/1;68cf289a-749b-11df-b112-00215aee3ebe
aaa26e82-db9d-11ee-80c4-000c2961d091;Миронівка-Перемоги 1 lifecell;Миронівка вул. Перемоги, 1;68cf289a-749b-11df-b112-00215aee3ebe
9782d8cd-ba83-11eb-80d4-000c29800ae7;Миронівка-Перемоги 1 Vodafone;Миронівка вул. Перемоги, 1;68cf289a-749b-11df-b112-00215aee3ebe
d07747ae-ba82-11eb-80d4-000c29800ae7;Миронівка-Перемоги 19;Миронівка вул. Перемоги, 19;68cf289a-749b-11df-b112-00215aee3ebe
34745fbf-b7d6-11eb-80d4-000c29800ae7;Миронівка-Соборності 127з;Миронівка вул. Соборності, 127з;68cf289a-749b-11df-b112-00215aee3ebe
03f5ac76-ed9a-11ed-80ed-000c29800ae7;Миронівка-Соборності 61;Миронівка вул. Соборності, 61;68cf289a-749b-11df-b112-00215aee3ebe
82affbff-839e-11ee-80c1-000c2961d091;Міжгір'я-Туряниці 1;Міжгір'я вул. Туряниці, 1;68cf2995-749b-11df-b112-00215aee3ebe
f2fd5783-773f-11ee-80c1-000c2961d091;Міжгір'я-Хустська ;Міжгір'я вул. Хустська,;68cf2995-749b-11df-b112-00215aee3ebe
6847a2bd-b2e8-11e9-80e4-1c98ec135261;Міжгір'я-Хустська 63;Міжгір'я вул. Хустська, 63;68cf2995-749b-11df-b112-00215aee3ebe
853e0ab6-4806-11ee-80c0-000c2961d091;Мізікевича-Райдужний масив 1 Продукти;Мізікевича вул. Райдужний масив, 1;68cf299e-749b-11df-b112-00215aee3ebe
e3cfa392-2e66-11eb-80ce-000c29800ae7;Млинів-Степана Бандери 13;Млинів вул. Степана Бандери, 13;68cf29dd-749b-11df-b112-00215aee3ebe
ae826a0a-e307-11eb-80d5-000c29800ae7;Млинів-Степана Бандери 38;Млинів вул. Степана Бандери, 38;68cf29dd-749b-11df-b112-00215aee3ebe
aeb91ee3-9941-11eb-80d3-000c29800ae7;Млинів-Степана Бандери 61/6;Млинів вул. Степана Бандери, 61/6;68cf29dd-749b-11df-b112-00215aee3ebe
99d44eab-4be7-11ee-80c0-000c2961d091;ММПO DRIV;;62c3d54a-749b-11df-b112-00215aee3ebe
51050562-3d92-11ec-80e3-000c29800ae7;ММПO ME;;62c3d54a-749b-11df-b112-00215aee3ebe
f61d81e3-6e6b-11ea-80c6-000c29800ae7;ММПOTE;;62c3d54a-749b-11df-b112-00215aee3ebe
75ec2986-a8dd-11de-bac3-0030485903e8;ММПО;;62c3d54a-749b-11df-b112-00215aee3ebe
8889e34c-8e51-11ec-80e4-000c29800ae7;ММПО (МАЛЕХІВ);;62c3d54a-749b-11df-b112-00215aee3ebe
13fc625b-b812-11e4-b90b-003048d2b473;ММПО-ДОГЛЯД;;62c3d54a-749b-11df-b112-00215aee3ebe
bd720c01-47d6-11ee-80c0-000c2961d091;Могилів-Панікахи 4;Могилів вул. Панікахи, 4;68cf29fc-749b-11df-b112-00215aee3ebe
5339202b-4d53-11df-84df-0030485903e8;Могилів-Подільський;Могилів-Подільський вул. Пушкінська, 154;68cf2a01-749b-11df-b112-00215aee3ebe
f4495332-54e1-11eb-80d0-000c29800ae7;Могилів-Подільський-Стависька 51 Жжук;Могилів-Подільський вул. Стависька, 51;68cf2a01-749b-11df-b112-00215aee3ebe
52ac0aec-4aec-11e9-80dd-1c98ec135261;Монастириська-Шевченка 17;Монастириська вул. Шевченка, 17;68cf2a70-749b-11df-b112-00215aee3ebe
d4e884c6-4af2-11e9-80dd-1c98ec135261;Монастириська-Шевченка 26;Монастириська вул. Шевченка, 26;68cf2a70-749b-11df-b112-00215aee3ebe
8618d44a-a7a2-11e9-80e4-1c98ec135261;Монастириська-Шевченка 9 А;Монастириська вул. Шевченка, 9А;68cf2a70-749b-11df-b112-00215aee3ebe
3d04c4bd-c564-11e8-80d7-1c98ec135261;Монастирище-Соборна 122Т;Монастирище вул. Соборна, 122Т;68cf2a73-749b-11df-b112-00215aee3ebe
2e2b4511-c922-11eb-80d5-000c29800ae7;Моршин-Франка Івана 27;Моршин вул. Франка Івана, 27;68cf2ab0-749b-11df-b112-00215aee3ebe
0e5ed127-ae38-11ed-80ec-000c29800ae7;Мостиська-Полуботка 3;Мостиська вул. Полуботка, 3;68cf2ad4-749b-11df-b112-00215aee3ebe
779e0582-7cc9-11e7-80c8-1c98ec135261;Мукачево;Мукачево вул. Дорошенка (Фурманова), 21;68cf2b27-749b-11df-b112-00215aee3ebe
3c42de7b-879a-11ee-80c1-000c2961d091;Мукачево-Берегівська 11 Ситий тато;Мукачево вул. Берегівська, 11;68cf2b27-749b-11df-b112-00215aee3ebe
2b4de09c-15bd-11ee-80bd-000c2961d091;Мукачево-Духновича 169 АЗС маркет;Мукачево вул. Духновича, 169;68cf2b27-749b-11df-b112-00215aee3ebe
59148e9e-f1e0-11ec-80e7-000c29800ae7;Мукачево-Духновича 22 Rozetka;Мукачево вул. Духновича, 22;68cf2b27-749b-11df-b112-00215aee3ebe
c149a5b2-b35d-11e9-80e4-1c98ec135261;Мукачево-Ілони Зріні 165А ОККО;Мукачево вул. Ілони Зріні, 165А;68cf2b27-749b-11df-b112-00215aee3ebe
271ce0b9-7a72-11eb-80d2-000c29800ae7;Мукачево-Маргітича Івана 49/75 САМОБУД;Мукачево вул. Маргітича Івана, 49/75;68cf2b27-749b-11df-b112-00215aee3ebe
55b3eb11-f979-11ed-80ed-000c29800ae7;Мукачево-Масарика Томаша 17 Lifecell;Мукачево вул. Масарика Томаша (Матросова), 17;68cf2b27-749b-11df-b112-00215aee3ebe
4537d790-b370-11e9-80e4-1c98ec135261;Мукачево-Масарика Томаша 21 ОККО;Мукачево вул. Масарика Томаша (Матросова), 21;68cf2b27-749b-11df-b112-00215aee3ebe
07aa1789-f578-11e9-80e7-1c98ec135261;Мукачево-Миру 3 Ломбард;Мукачево вул. Миру, 3;68cf2b27-749b-11df-b112-00215aee3ebe
a1da1be0-16ec-11ec-80df-000c29800ae7;Мукачево-Пряшівська 2Б АЗС Motto;Мукачево вул. Пряшівська, 2Б;68cf2b27-749b-11df-b112-00215aee3ebe
29ea4922-cfca-11ed-80ed-000c29800ae7;Мукачево-Росвигівська 2Б АЗС маркет;Мукачево вул. Росвигівська, 2Б;68cf2b27-749b-11df-b112-00215aee3ebe
41a7aaa0-2d46-11ee-80bd-000c2961d091;Мукачево-Соборна 11 Vodafone;Мукачево вул. Соборна (Олександра Пушкіна), 11;68cf2b27-749b-11df-b112-00215aee3ebe
5ddb4571-006e-11ee-80bd-000c2961d091;Мукачево-Соборна 7 Lifecell;Мукачево вул. Соборна (Олександра Пушкіна), 7;68cf2b27-749b-11df-b112-00215aee3ebe
c51eb472-d71a-11ee-80c4-000c2961d091;Мукачево-Ужгородська 31/1/1 Craft;Мукачево вул. Ужгородська, 31/1/1;68cf2b27-749b-11df-b112-00215aee3ebe
e6a557b5-759e-11ee-80c1-000c2961d091;Мукачево-Федорова Івана 6 Сільпо;Мукачево вул. Федорова Івана, 6;68cf2b27-749b-11df-b112-00215aee3ebe
f7d397d5-9445-11ee-80c1-000c2961d091;Муроване-Січових Стрільців 32;Муроване вул. Січових Стрільців, 32;68cf2b3e-749b-11df-b112-00215aee3ebe
ee02cf7d-aa51-11ee-80c4-000c2961d091;Муровані Курилівці-Комарова Космонавта 1/131;Муровані Курилівці вул. Комарова Космонавта, 1/131;68cf2b40-749b-11df-b112-00215aee3ebe
d9104438-a70e-11e9-80e2-1c98ec135261;Муровані Курилівці-Комарова Космонавта 7;Муровані Курилівці вул. Комарова Космонавта, 7;68cf2b40-749b-11df-b112-00215aee3ebe
a8f1ef70-276a-11ec-80e3-000c29800ae7;Муровані Курилівці-Соборна 180;Муровані Курилівці вул. Соборна, 180;68cf2b40-749b-11df-b112-00215aee3ebe
e896ac12-35e5-11ee-80bd-000c2961d091;НАВЧАННЯ Point24;Львів вул. Зелена, 2 7/9/8/6;62c3d54a-749b-11df-b112-00215aee3ebe
5911f7ce-a79b-11e9-80e4-1c98ec135261;Надвірна-Вокзальна 8;Надвірна вул. Вокзальна, 8;68cf2ba1-749b-11df-b112-00215aee3ebe
280d8588-172a-11ee-80bd-000c2961d091;Надвірна-Грушевського 10;Надвірна вул. Грушевського, 10;68cf2ba1-749b-11df-b112-00215aee3ebe
b4744f79-5156-11e9-80dd-1c98ec135261;Надвірна-Грушевського,7-Ж;Надвірна вул. Грушевського, 7Ж;68cf2ba1-749b-11df-b112-00215aee3ebe
90cbdb85-741a-11ea-80c6-000c29800ae7;Надвірна-Мазепи Гетьмана, 25;Надвірна вул. Мазепи Гетьмана, 25;68cf2ba1-749b-11df-b112-00215aee3ebe
61599518-5c52-11ee-80c0-000c2961d091;Надвірна-Рамішвілі 15;Надвірна вул. Рамішвілі, 15;68cf2ba1-749b-11df-b112-00215aee3ebe
53765153-f03f-11eb-80d5-000c29800ae7;Надвірна-Чорновола В'ячеслава 3А;Надвірна вул. Чорновола В'ячеслава, 3А;68cf2ba1-749b-11df-b112-00215aee3ebe
254c8164-5b9d-11e9-80df-1c98ec135261;Надвірна-Чорновола В'ячеслава 4;Надвірна вул. Чорновола В'ячеслава, 4;68cf2ba1-749b-11df-b112-00215aee3ebe
2b5c406d-5183-11ee-80c0-000c2961d091;Надвірна-Чорновола В'ячеслава 4 lifecell;Надвірна вул. Чорновола В'ячеслава, 4;68cf2ba1-749b-11df-b112-00215aee3ebe
a0ed3692-cbe8-11ee-80c4-000c2961d091;Недригайлів-Сумська 6;Недригайлів вул. Сумська (Леніна), 6;68cf2c48-749b-11df-b112-00215aee3ebe
483a898b-ce0f-11ed-80ed-000c29800ae7;Немирів-Горького 92;Немирів вул. Горького, 92;68cf2c71-749b-11df-b112-00215aee3ebe
17243266-209c-11e9-80dc-1c98ec135261;Немирів-Яворівська 7;Немирів вул. Яворівська, 7;68cf2c70-749b-11df-b112-00215aee3ebe
26be1897-48aa-11ee-80c0-000c2961d091;Немішаєве-Заводська 48А/1;Немішаєве вул. Заводська, 48А/1;68cf2c77-749b-11df-b112-00215aee3ebe
87eed329-b893-11eb-80d4-000c29800ae7;Неполоківці-Головна 22;Неполоківці вул. Головна, 22;68cf2c87-749b-11df-b112-00215aee3ebe
eec3fd5f-a4ca-11ea-80c8-000c29800ae7;Нетішин;Нетішин вул. Незалежності, 22;68cf2cb0-749b-11df-b112-00215aee3ebe
76363c71-d98b-11eb-80d5-000c29800ae7;Нетішин-Будівельників 7А Професіонал;Нетішин вул. Будівельників, 7А;68cf2cb0-749b-11df-b112-00215aee3ebe
e0fdc6ce-5933-11ee-80c0-000c2961d091;Нетішин-Василя Стуса 4 Пивна Гавань;Нетішин вул. Василя Стуса, 4;68cf2cb0-749b-11df-b112-00215aee3ebe
b0fe47a6-b36c-11e9-80e4-1c98ec135261;Нетішин-Енергетиків 14 ОККО;Нетішин вул. Енергетиків, 14;68cf2cb0-749b-11df-b112-00215aee3ebe
c590303b-759f-11ee-80c1-000c2961d091;Нетішин-Лісова 1Б Сільпо;Нетішин вул. Лісова, 1Б;68cf2cb0-749b-11df-b112-00215aee3ebe
cce8582d-d98c-11eb-80d5-000c29800ae7;Нетішин-Незалежності 10 ОКА;Нетішин вул. Незалежності, 10;68cf2cb0-749b-11df-b112-00215aee3ebe
5a65fab9-ae39-11ed-80ec-000c29800ae7;Нетішин-Незалежності 5/2 Rozetka;Нетішин Просп. Незалежності, 5/2;68cf2cb0-749b-11df-b112-00215aee3ebe
1a171ebc-593a-11ee-80c0-000c2961d091;Нетішин-Шевченка 7 Пивна Гавань;Нетішин вул. Шевченка, 7;68cf2cb0-749b-11df-b112-00215aee3ebe
dd8d252b-a0ec-11ee-80c4-000c2961d091;Нижня Апша-Дібровська 163;Нижня Апша вул. Дібровська, 163;73e94bf8-ddc1-11df-9197-00215aee3ebe
4bb2f9a8-f2fd-11ed-80ed-000c29800ae7;Ніжин-Батюка 9;Ніжин вул. Батюка, 9;a5b151ba-ef03-11de-9277-00215aee3ebe
245b2f9c-9b68-11ee-80c1-000c2961d091;Ніжин-Гоголя Миколи 2А;Ніжин вул. Гоголя Миколи, 2А;a5b151ba-ef03-11de-9277-00215aee3ebe
cad6e002-ce1c-11ed-80ed-000c29800ae7;Ніжин-Московська 3;Ніжин вул. Станіслава Прощенка (к.Московська), 3;a5b151ba-ef03-11de-9277-00215aee3ebe
5ef0eb05-75a0-11ee-80c1-000c2961d091;Ніжин-Незалежності 36;Ніжин вул. Незалежності, 36;a5b151ba-ef03-11de-9277-00215aee3ebe
0685518a-9ff5-11ee-80c4-000c2961d091;Ніжин-Шевченка 107;Ніжин вул. Шевченка, 107;a5b151ba-ef03-11de-9277-00215aee3ebe
f150be27-4e97-11eb-80d0-000c29800ae7;Ніжин-Шевченка 120;Ніжин вул. Шевченка, 120;a5b151ba-ef03-11de-9277-00215aee3ebe
28d880c8-0c75-11e1-9215-003048d2b473;Нікополь;Нікополь вул. Електрометалургів, 44Д;6ed8177b-749b-11df-b112-00215aee3ebe
b4fb51fd-01da-11e8-80cd-1c98ec135261;Нікополь-Трубників, 13/36;Нікополь Просп. Трубників, ,13/36;6ed8177b-749b-11df-b112-00215aee3ebe
b7a728c4-18bc-11ed-80e7-000c29800ae7;Нікополь-Шевченка 173Б Rozetka;Нікополь вул. Шевченка, 173Б;6ed8177b-749b-11df-b112-00215aee3ebe
de124954-622e-11ec-80e4-000c29800ae7;Нова Водолага-Кооперативна 3А;Нова Водолага вул. Кооперативна, 3А;6ed817af-749b-11df-b112-00215aee3ebe
8154f84a-f04c-11eb-80d5-000c29800ae7;Нова Одеса-Центральна 201;Нова Одеса вул. Центральна, 201;6ed817ef-749b-11df-b112-00215aee3ebe
33ef0353-f108-11eb-80d5-000c29800ae7;Нова Одеса-Червоних Партизанів 46;Нова Одеса вул. Червоних Партизанів, 46;6ed817ef-749b-11df-b112-00215aee3ebe
ef1056f7-32aa-11e8-80d0-1c98ec135261;Нова Ушиця-40-річчя Перемоги б/н;Нова Ушиця вул. 40-річчя Перемоги, б/н;6ed8179b-749b-11df-b112-00215aee3ebe
f67e8128-05ac-11ec-80d6-000c29800ae7;Новгород-Сіверський-Базарна 13;Новгород-Сіверський вул. Базарна, 13;6ed81827-749b-11df-b112-00215aee3ebe
502500e0-c815-11ee-80c4-000c2961d091;Новгород-Сіверський-Губернська 1;Новгород-Сіверський вул. Губернська, 1;6ed81827-749b-11df-b112-00215aee3ebe
0221c4ff-051e-11ee-80bd-000c2961d091;Нове Запоріжжя-Нікопольське 1 АЗС БРСМ;Нове Запоріжжя шосе Нікопольське, 1;6ed81858-749b-11df-b112-00215aee3ebe
df5edac7-1baf-11ec-80df-000c29800ae7;Нове-Металургів 10А;Нове вул. Металургів, 10А;6ed81843-749b-11df-b112-00215aee3ebe
91f07963-e6cc-11ee-80c5-000c2961d091;Нове-Металургів 25А;Нове вул. Металургів, 25А;6ed81843-749b-11df-b112-00215aee3ebe
481b82b6-8564-11eb-80d2-000c29800ae7;Новий Буг-Свободи 26;Новий Буг пл. Свободи, 26;6ed81873-749b-11df-b112-00215aee3ebe
1e132c86-9a23-11eb-80d3-000c29800ae7;Новий Буг-Свободи 40/2;Новий Буг пл. Свободи, 40/2;6ed81873-749b-11df-b112-00215aee3ebe
9ba8ae68-c920-11eb-80d5-000c29800ae7;Новий Калинів-Авіаторів 3;Новий Калинів вул. Авіаторів, 3;6ed67cdb-5e7e-11e3-ae68-003048d2b473
b642bfb3-3102-11e9-80dd-1c98ec135261;Новий Розділ-Ходорівська 9 В;Новий Розділ вул. Ходорівська, 9В;6ed818a6-749b-11df-b112-00215aee3ebe
0b93c95b-a82b-11eb-80d3-000c29800ae7;Новий Розділ-Шевченка 16;Новий Розділ вул. Шевченка, 16;6ed818a6-749b-11df-b112-00215aee3ebe
930cd831-7637-11ee-80c1-000c2961d091;Новий Розділ-Шевченка 30А;Новий Розділ Просп. Шевченка, 30А;6ed818a6-749b-11df-b112-00215aee3ebe
7eccd793-4664-11ee-80c0-000c2961d091;Новий Розділ-Шевченка 30А lifecell;Новий Розділ Просп. Шевченка, 30А;6ed818a6-749b-11df-b112-00215aee3ebe
67347975-b54c-11ee-80c4-000c2961d091;Нові Петрівці-Свято-Покровська 165;Нові Петрівці вул. Свято-Покровська, 165;6ed818ea-749b-11df-b112-00215aee3ebe
750dee83-808e-11e9-80e0-1c98ec135261;Нові Петрівці-Свято-Покровська 1Г;Нові Петрівці вул. Свято-Покровська, 1Г;6ed818ea-749b-11df-b112-00215aee3ebe
9fe54966-d463-11ed-80ed-000c29800ae7;Нові Петрівці-Свято-Покровська 235;Нові Петрівці вул. Свято-Покровська, 235;6ed818ea-749b-11df-b112-00215aee3ebe
5fd870c6-e82f-11ee-80c5-000c2961d091;Нові Петрівці-Свято-Покровська 332;Нові Петрівці вул. Свято-Покровська, 332;6ed818ea-749b-11df-b112-00215aee3ebe
0a2c7803-1686-11ee-80bd-000c2961d091;Нові Санжари-Незалежності 22;Нові Санжари вул. Незалежності, 22;6ed818ed-749b-11df-b112-00215aee3ebe
edc6bc09-8279-11e3-b53d-003048d2b473;Нововолинськ;Нововолинськ вул. Сонячна, 5а;6ed81947-749b-11df-b112-00215aee3ebe
1b034311-06aa-11ed-80e7-000c29800ae7;Нововолинськ-5-й 9 Сім23;Нововолинськ мікр. 5-й, 9;6ed81947-749b-11df-b112-00215aee3ebe
af3a9117-f11f-11ec-80e7-000c29800ae7;Нововолинськ-Перемоги 2А Rozetka;Нововолинськ Просп. Перемоги, 2А;6ed81947-749b-11df-b112-00215aee3ebe
4efd4690-208b-11e9-80dc-1c98ec135261;Нововолинськ-Чехова 2 ОККО;Нововолинськ вул. Чехова, 2;6ed81947-749b-11df-b112-00215aee3ebe
16b06616-4eb0-11ec-80e3-000c29800ae7;Нововолинськ-Шевченка 9 Сім23;Нововолинськ бул. Шевченка, 9;6ed81947-749b-11df-b112-00215aee3ebe
21ce1546-3636-11ec-80e3-000c29800ae7;Новодністровськ-2-й 5;Новодністровськ кв. 2-й, 5;6ed819ab-749b-11df-b112-00215aee3ebe
de255cc4-8878-11ee-80c1-000c2961d091;Новодністровськ-Діброва 25;Новодністровськ мікр. Діброва, 25;6ed819ab-749b-11df-b112-00215aee3ebe
5859001a-f90a-11eb-80d5-000c29800ae7;Новодністровськ-Діброва 4;Новодністровськ мікр. Діброва, 4;6ed819ab-749b-11df-b112-00215aee3ebe
9b1a94ca-e855-11ee-80c5-000c2961d091;Новомиргород-Соборності 108А;Новомиргород вул. Соборності, 108А;6ed81a73-749b-11df-b112-00215aee3ebe
bf0530d5-a44e-11eb-80d3-000c29800ae7;Новомиргород-Соборності 99/12;Новомиргород вул. Соборності, 99/12;6ed81a73-749b-11df-b112-00215aee3ebe
e36fed17-fb40-11eb-80d6-000c29800ae7;Новомосковськ-Білої Зіни 158;Новомосковськ вул. Білої Зіни, 158;6ed81a8b-749b-11df-b112-00215aee3ebe
079c817e-f580-11ed-80ed-000c29800ae7;Новомосковськ-Волгоградський 5А;Новомосковськ пров. Волгоградський, 5А;6ed81a8b-749b-11df-b112-00215aee3ebe
c87dd661-f12f-11ec-80e7-000c29800ae7;Новомосковськ-Гетьманська (Радянська) 34;Новомосковськ вул. Гетьманська (Радянська), 34;6ed81a8b-749b-11df-b112-00215aee3ebe
f74310cd-3f52-11e9-80dd-1c98ec135261;Новомосковськ-Гетьманська 22;Новомосковськ вул. Гетьманська (Радянська), 22;6ed81a8b-749b-11df-b112-00215aee3ebe
ddb2a7a9-5d40-11ee-80c0-000c2961d091;Новомосковськ-Гетьманська 220;Новомосковськ вул. Гетьманська (Радянська), 220;6ed81a8b-749b-11df-b112-00215aee3ebe
afd6025b-15bb-11ee-80bd-000c2961d091;Новомосковськ-Гетьманська 220А;Новомосковськ вул. Гетьманська (Радянська), 220А;6ed81a8b-749b-11df-b112-00215aee3ebe
61cb6b3b-3d7e-11ec-80e3-000c29800ae7;Новомосковськ-Гетьманська 35;Новомосковськ вул. Гетьманська (Радянська), 35;6ed81a8b-749b-11df-b112-00215aee3ebe
05e30d22-3134-11e9-80dd-1c98ec135261;Новомосковськ-Гетьманська 41;Новомосковськ вул. Гетьманська (Радянська), 41;6ed81a8b-749b-11df-b112-00215aee3ebe
7840ca11-7638-11ee-80c1-000c2961d091;Новомосковськ-Гетьманська 47А;Новомосковськ вул. Гетьманська (Радянська), 47А;6ed81a8b-749b-11df-b112-00215aee3ebe
4c38176f-41c0-11ee-80c0-000c2961d091;Новомосковськ-Гетьманська 48;Новомосковськ вул. Гетьманська (Радянська), 48;6ed81a8b-749b-11df-b112-00215aee3ebe
e2f8f8b4-fab9-11eb-80d6-000c29800ae7;Новомосковськ-Жлутенка 2А;Новомосковськ вул. Жлуктенка, 2А;6ed81a8b-749b-11df-b112-00215aee3ebe
6377a140-3cd6-11ee-80c0-000c2961d091;Новомосковськ-Кулебовська 29;Новомосковськ вул. Кулебовська (Леніна), 29;6ed81a8b-749b-11df-b112-00215aee3ebe
4e067312-fb40-11eb-80d6-000c29800ae7;Новомосковськ-Малинова 99;Новомосковськ вул. Малинова, 99;6ed81a8b-749b-11df-b112-00215aee3ebe
8b0f0afb-eb58-11ed-80ed-000c29800ae7;Новомосковськ-Митягіна 62Д;Новомосковськ вул. Митягіна, 62Д;6ed81a8b-749b-11df-b112-00215aee3ebe
9bd874fe-079a-11ea-80e7-1c98ec135261;Новомосковськ-Перемоги 10;Новомосковськ пл. Перемоги, 10;6ed81a8b-749b-11df-b112-00215aee3ebe
8179bff2-efce-11ed-80ed-000c29800ae7;Новомосковськ-Перемоги 8;Новомосковськ пл. Перемоги, 8;6ed81a8b-749b-11df-b112-00215aee3ebe
64749a73-7c84-11ee-80c1-000c2961d091;Новомосковськ-Сучкова 124А ;Новомосковськ вул. Сучкова, 124А;6ed81a8b-749b-11df-b112-00215aee3ebe
636da9a0-8a16-11ee-80c1-000c2961d091;Новомосковськ-Сучкова 32;Новомосковськ вул. Сучкова, 32;6ed81a8b-749b-11df-b112-00215aee3ebe
2b8dd355-efce-11ed-80ed-000c29800ae7;Новомосковськ-Сучкова 52;Новомосковськ вул. Сучкова, 52;6ed81a8b-749b-11df-b112-00215aee3ebe
9cb7019f-0513-11ee-80bd-000c2961d091;Новомосковськ-Сучкова 66;Новомосковськ вул. Сучкова, 66;6ed81a8b-749b-11df-b112-00215aee3ebe
b386d86e-0529-11ea-80e7-1c98ec135261;Новомосковськ-Українська 12;Новомосковськ вул. Українська, 12;6ed81a8b-749b-11df-b112-00215aee3ebe
64936c40-98f3-11ee-80c1-000c2961d091;Новоолександрівка-Гагаріна 2Е ZooLand;Новоолександрівка вул. Гагаріна, 2Е;6ed81a99-749b-11df-b112-00215aee3ebe
18d6063c-8876-11ee-80c1-000c2961d091;Новоселиця-Хотинська 32;Новоселиця вул. Хотинська, 32;6ed81b64-749b-11df-b112-00215aee3ebe
f83428a3-caf9-11e9-80e4-1c98ec135261;Новоселиця-Центральна 31;Новоселиця вул. Центральна, 31;6ed81b64-749b-11df-b112-00215aee3ebe
b5615c74-92c4-11ee-80c1-000c2961d091;Новосілки-Миру 2А;Новосілки вул. Миру, 2А;325c57ec-ddd2-11df-9197-00215aee3ebe
971f3fbc-522d-11ee-80c0-000c2961d091;Новосілки-Миру 2А Rozetka;Новосілки вул. Миру, 2А;325c57ec-ddd2-11df-9197-00215aee3ebe
85e992f6-2095-11e9-80dc-1c98ec135261;Новояворівськ-Львівська 25;Новояворівськ вул. Львівська, 25;6ed81ca0-749b-11df-b112-00215aee3ebe
ed3690d1-410c-11ee-80c0-000c2961d091;Новояворівськ-Франка Івана 4А;Новояворівськ вул. Франка Івана, 4А;6ed81ca0-749b-11df-b112-00215aee3ebe
b0752217-ae3a-11ed-80ec-000c29800ae7;Новояворівськ-Франка Івана 5;Новояворівськ вул. Франка Івана, 5;6ed81ca0-749b-11df-b112-00215aee3ebe
08d02051-ccc7-11ee-80c4-000c2961d091;Новояворівськ-Шевченка 8В;Новояворівськ вул. Шевченка, 8В;6ed81ca0-749b-11df-b112-00215aee3ebe
23c8df7b-069b-11e8-80cd-1c98ec135261;НПLV;;62c3d54a-749b-11df-b112-00215aee3ebe
b43a4651-05aa-11ec-80d6-000c29800ae7;Оброшине-Грушевського Михайла 25;Оброшине вул. Грушевського Михайла, 25;6ed81cf9-749b-11df-b112-00215aee3ebe
1b5e9bec-5d42-11ee-80c0-000c2961d091;Обухівка-Білякова 17А;Обухівка (Кіровське) вул. Білякова, 17А;5cb61733-749b-11df-b112-00215aee3ebe
5f403666-f46b-11eb-80d5-000c29800ae7;Обухівка-Висока 2;Обухівка (Кіровське) вул. Висока, 2;5cb61733-749b-11df-b112-00215aee3ebe
0578b6c8-8a16-11ee-80c1-000c2961d091;Обухівка-Солідарності 544;Обухівка (Кіровське) вул. Солідарності, 544;5cb61733-749b-11df-b112-00215aee3ebe
f18371b1-7998-11ee-80c1-000c2961d091;Обухів-Каштанова 16/5;Обухів вул. Каштанова, 16/5;6ed81cfc-749b-11df-b112-00215aee3ebe
de48ea61-7638-11ee-80c1-000c2961d091;Обухів-Київська 107;Обухів вул. Київська, 107;6ed81cfc-749b-11df-b112-00215aee3ebe
af4c7dba-bcd5-11ed-80ed-000c29800ae7;Обухів-Київська 111;Обухів вул. Київська, 111;6ed81cfc-749b-11df-b112-00215aee3ebe
d09e8953-ba8b-11eb-80d4-000c29800ae7;Обухів-Київська 119;Обухів вул. Київська, 119;6ed81cfc-749b-11df-b112-00215aee3ebe
4e22e8e3-f1ee-11ec-80e7-000c29800ae7;Обухів-Київська 166;Обухів вул. Київська, 166;6ed81cfc-749b-11df-b112-00215aee3ebe
feb6fce2-0522-11ee-80bd-000c2961d091;Обухів-Київська 25;Обухів вул. Київська, 25;6ed81cfc-749b-11df-b112-00215aee3ebe
dbefed00-16eb-11ec-80df-000c29800ae7;Обухів-Миру 13а;Обухів вул. Миру, 13а;6ed81cfc-749b-11df-b112-00215aee3ebe
c84881a6-a4ac-11e9-80e2-1c98ec135261;Овідіополь-Колісніченка 9 А;Овідіополь вул. Колісніченка, 9А;6ed81d0a-749b-11df-b112-00215aee3ebe
a5f8f225-7639-11ee-80c1-000c2961d091;Овідіополь-Шевченка 228;Овідіополь вул. Шевченка, 228;6ed81d0a-749b-11df-b112-00215aee3ebe
5e33205e-8ad4-11ee-80c1-000c2961d091;Овруч-Базарна 10/9;Овруч вул. Базарна, 10/9;6ed81d13-749b-11df-b112-00215aee3ebe
c99cf9ba-a974-11ee-80c4-000c2961d091;Овруч-Виговського 34/2;Овруч вул. Виговського (Леніна), 34/2;6ed81d13-749b-11df-b112-00215aee3ebe
8c2eeb88-8de3-11ee-80c1-000c2961d091;Овруч-Металістів 1;Овруч вул. Металістів, 1;6ed81d13-749b-11df-b112-00215aee3ebe
21719eb9-943b-11ee-80c1-000c2961d091;Овруч-Шевченка 53В;Овруч вул. Шевченка, 53В;6ed81d13-749b-11df-b112-00215aee3ebe
a61c9f4e-dd63-11ea-80ca-000c29800ae7;Овруч-Шевченка 58/2;Овруч вул. Шевченка, 58/2;6ed81d13-749b-11df-b112-00215aee3ebe
75ec2990-a8dd-11de-bac3-0030485903e8;Одеса;Одеса вул. Грушевського Михайла, 41;6ed81d37-749b-11df-b112-00215aee3ebe
c2b37d18-336b-11e0-82c7-00215aee3ebe;Одеса 7-й кілометр;Одеса вул. Базова, 11;6ed81d37-749b-11df-b112-00215aee3ebe
3fc3aac6-f304-11ec-80e7-000c29800ae7;Одеса-Адміральський 1 Rozetka;Одеса Просп. Адміральський, 1;6ed81d37-749b-11df-b112-00215aee3ebe
922c79d7-87b5-11ee-80c1-000c2961d091;Одеса-Академічна 11 Копійка;Одеса вул. Академічна, 11;6ed81d37-749b-11df-b112-00215aee3ebe
ce996da6-4e1b-11e8-80d0-1c98ec135261;Одеса-Армійська 8Г зооI-pet;Одеса вул. Армійська, 8Г;6ed81d37-749b-11df-b112-00215aee3ebe
c0a1ae2c-a983-11ee-80c4-000c2961d091;Одеса-Асташкіна 29 Lifecell;Одеса пров. Асташкіна, 29;6ed81d37-749b-11df-b112-00215aee3ebe
18cd8b23-7bab-11e9-80e0-1c98ec135261;Одеса-Балківська 115 ОККО;Одеса вул. Балківська, 115;6ed81d37-749b-11df-b112-00215aee3ebe
7912765e-763c-11ee-80c1-000c2961d091;Одеса-Бочарова Генерала 13А Сільпо;Одеса вул. Бочарова Генерала, 13А;6ed81d37-749b-11df-b112-00215aee3ebe
1c0e5750-adec-11e9-80e4-1c98ec135261;Одеса-Бочарова Генерала 30 Ксерокс Фото Центр;Одеса вул. Бочарова Генерала, 30;6ed81d37-749b-11df-b112-00215aee3ebe
d3bbd614-173c-11ee-80bd-000c2961d091;Одеса-Бочарова Генерала 37 Lifecell;Одеса вул. Бочарова Генерала, 37;6ed81d37-749b-11df-b112-00215aee3ebe
13b76b8c-763a-11ee-80c1-000c2961d091;Одеса-Бочарова Генерала 44 Сільпо;Одеса вул. Бочарова Генерала, 44;6ed81d37-749b-11df-b112-00215aee3ebe
c35bc840-5dcc-11ee-80c0-000c2961d091;Одеса-Бочарова Генерала 6 Hookah shop;Одеса вул. Бочарова Генерала, 6;6ed81d37-749b-11df-b112-00215aee3ebe
368219ee-d47d-11ed-80ed-000c29800ae7;Одеса-Бочарова Генерала 62 Tabaco Market;Одеса вул. Бочарова Генерала, 62;6ed81d37-749b-11df-b112-00215aee3ebe
d7be92c1-173d-11ee-80bd-000c2961d091;Одеса-Бугаївська 21В Lifecell;Одеса вул. Бугаївська, 21В;6ed81d37-749b-11df-b112-00215aee3ebe
17abd066-7981-11ee-80c1-000c2961d091;Одеса-Бугаївська 41Г ОККО;Одеса вул. Бугаївська, 41Г;6ed81d37-749b-11df-b112-00215aee3ebe
accf92ec-d317-11ea-80ca-000c29800ae7;Одеса-Велика Арнаутська 18 Зоомагазин;Одеса вул. Велика Арнаутська, 18;6ed81d37-749b-11df-b112-00215aee3ebe
f84ee9ab-8c57-11e9-80e2-1c98ec135261;Одеса-Вільямса Академіка 56А Хобби Рум;Одеса вул. Євгена Чикаленка, 56А;6ed81d37-749b-11df-b112-00215aee3ebe
6d44f5f1-87b0-11ee-80c1-000c2961d091;Одеса-Вільямса Академіка 59З Копійка;Одеса вул. Євгена Чикаленка, 59З;6ed81d37-749b-11df-b112-00215aee3ebe
16331728-5786-11ee-80c0-000c2961d091;Одеса-Вільямса Академіка 75 Сільпо;Одеса вул. Євгена Чикаленка, 75;6ed81d37-749b-11df-b112-00215aee3ebe
4a1633d3-3691-11e9-80dd-1c98ec135261;Одеса-Вільямса Академіка 82 ОККО;Одеса вул. Євгена Чикаленка, 82;6ed81d37-749b-11df-b112-00215aee3ebe
f30657b0-5789-11ee-80c0-000c2961d091;Одеса-Генуезька 5 Сільпо;Одеса вул. Генуезька, 5;6ed81d37-749b-11df-b112-00215aee3ebe
7a6ed004-763d-11ee-80c1-000c2961d091;Одеса-Героїв Крут 17/1 Сільпо;Одеса вул. Героїв Крут (Терешкової Валентини), 17/1;6ed81d37-749b-11df-b112-00215aee3ebe
f89e6e6d-0672-11ed-80e7-000c29800ae7;Одеса-Героїв оборони Одеси 52А Rozetka;Одеса вул. Героїв оборони Одеси (Героїв Сталінграда, 52А;6ed81d37-749b-11df-b112-00215aee3ebe
1b4d6202-6905-11ee-80c1-000c2961d091;Одеса-Героїв оборони Одеси 86 One Price;Одеса вул. Героїв оборони Одеси (Героїв Сталінграда, 86;6ed81d37-749b-11df-b112-00215aee3ebe
3afebe89-5787-11ee-80c0-000c2961d091;Одеса-Героїв оборони Одеси 98Б Сільпо;Одеса вул. Героїв оборони Одеси (Героїв Сталінграда, 98Б;6ed81d37-749b-11df-b112-00215aee3ebe
24efecd1-87b1-11ee-80c1-000c2961d091;Одеса-Глушка Академіка 11/1 Копійка;Одеса Просп. Глушка Академіка, 11/1;6ed81d37-749b-11df-b112-00215aee3ebe
adef2826-fbc2-11ed-80ed-000c29800ae7;Одеса-Глушка Академіка 11Г Lifecell;Одеса Просп. Глушка Академіка, 11Г;6ed81d37-749b-11df-b112-00215aee3ebe
37542e14-da34-11ee-80c4-000c2961d091;Одеса-Глушка Академіка 14/1 Порт;Одеса Просп. Глушка Академіка, 4/14;6ed81d37-749b-11df-b112-00215aee3ebe
a3768eda-15ca-11ee-80bd-000c2961d091;Одеса-Глушка Академіка 16 Ломбард;Одеса Просп. Глушка Академіка, 16;6ed81d37-749b-11df-b112-00215aee3ebe
0b3d8191-f305-11ec-80e7-000c29800ae7;Одеса-Глушка Академіка 17 Rozetka;Одеса Просп. Глушка Академіка, 17;6ed81d37-749b-11df-b112-00215aee3ebe
3b2ec679-14ce-11ee-80bd-000c2961d091;Одеса-Глушка Академіка 17 СПЕЦ;Одеса Просп. Глушка Академіка, 17;6ed81d37-749b-11df-b112-00215aee3ebe
a7a3f4d4-fbc3-11ed-80ed-000c29800ae7;Одеса-Грецька 50 Lifecell;Одеса вул. Грецька, 50;6ed81d37-749b-11df-b112-00215aee3ebe
1415c33d-ee3f-11ed-80ed-000c29800ae7;Одеса-Грушевського Михайла 30Г Rozetka;Одеса вул. Грушевського Михайла, 30Г;6ed81d37-749b-11df-b112-00215aee3ebe
515395ee-ab86-11ed-80ec-000c29800ae7;Одеса-Грушевського Михайла 52 Lifecell;Одеса вул. Грушевського Михайла, 52;6ed81d37-749b-11df-b112-00215aee3ebe
fe0d001d-5e8b-11ee-80c0-000c2961d091;Одеса-Грушевського Михайла, 41 СПЕЦ;Одеса вул. Грушевського Михайла, 41;6ed81d37-749b-11df-b112-00215aee3ebe
544115e6-0ec5-11eb-80cd-000c29800ae7;Одеса-Добровольського 108 ОККО;Одеса Просп. Добровольського, 108;6ed81d37-749b-11df-b112-00215aee3ebe
7d449b5d-95ef-11eb-80d3-000c29800ae7;Одеса-Добровольського 112 Ломбард;Одеса Просп. Добровольського, 112;6ed81d37-749b-11df-b112-00215aee3ebe
981bae7e-c474-11eb-80d5-000c29800ae7;Одеса-Добровольського 112/1 Біле Сухе;Одеса Просп. Добровольського, 112/1;6ed81d37-749b-11df-b112-00215aee3ebe
b3f92595-fd2c-11e7-80cd-1c98ec135261;Одеса-Добровольського 137А Pastila;Одеса Просп. Добровольського, 137А;6ed81d37-749b-11df-b112-00215aee3ebe
9d0be53e-c475-11eb-80d5-000c29800ae7;Одеса-Добровольського 82 Біле Сухе;Одеса Просп. Добровольського, 82;6ed81d37-749b-11df-b112-00215aee3ebe
836b52ba-22c9-11ed-80e7-000c29800ae7;Одеса-Добровольського 98 Lifecell;Одеса Просп. Добровольського, 98;6ed81d37-749b-11df-b112-00215aee3ebe
dd34ef97-459d-11e9-80dd-1c98ec135261;Одеса-Добровольського 99 Салон красоты Sun Vital;Одеса Просп. Добровольського, 99;6ed81d37-749b-11df-b112-00215aee3ebe
17f9a865-763b-11ee-80c1-000c2961d091;Одеса-Довженка Олександра 4 Сільпо;Одеса вул. Довженка Олександра, 4;6ed81d37-749b-11df-b112-00215aee3ebe
8b53296e-a0e2-11ee-80c4-000c2961d091;Одеса-Євгена Чикаленка (кол.Вільямса) 68/1 Rozetka;Одеса вул. Євгена Чикаленка, 68/1;6ed81d37-749b-11df-b112-00215aee3ebe
d6983313-5787-11ee-80c0-000c2961d091;Одеса-Єврейська 50/1 Сільпо;Одеса вул. Єврейська, 50/1;6ed81d37-749b-11df-b112-00215aee3ebe
92bc9aa5-e8e5-11e9-80e7-1c98ec135261;Одеса-Жуковського 38 Книжковий супермаркет;Одеса вул. Жуковського, 38;6ed81d37-749b-11df-b112-00215aee3ebe
1e7a5a36-47fd-11ee-80c0-000c2961d091;Одеса-Заболотного Академіка 19 Продукти;Одеса вул. Заболотного Академіка, 19;6ed81d37-749b-11df-b112-00215aee3ebe
ab3db75e-47fd-11ee-80c0-000c2961d091;Одеса-Заболотного Академіка 22 Продукти;Одеса вул. Заболотного Академіка, 22;6ed81d37-749b-11df-b112-00215aee3ebe
e1eb11e6-1cfa-11eb-80cd-000c29800ae7;Одеса-Заболотного Академіка 33/2 СЦ Server OK;Одеса вул. Заболотного Академіка, 33/2;6ed81d37-749b-11df-b112-00215aee3ebe
5131ebe7-f305-11ec-80e7-000c29800ae7;Одеса-Заболотного Академіка 52 Rozetka;Одеса вул. Заболотного Академіка, 52;6ed81d37-749b-11df-b112-00215aee3ebe
f3e6b1f9-cf0b-11ee-80c4-000c2961d091;Одеса-Інглезі 2 Rozetka;Одеса вул. Інглезі ( 25-ї Чапаєвської Дивізії ), 2;6ed81d37-749b-11df-b112-00215aee3ebe
ba24d59d-9e63-11e9-80e2-1c98ec135261;Одеса-Інглезі 8Б Copy Paste;Одеса вул. Інглезі ( 25-ї Чапаєвської Дивізії ), 8Б;6ed81d37-749b-11df-b112-00215aee3ebe
2231e6cd-3aa7-11e9-80dd-1c98ec135261;Одеса-Інглезі 9А ОККО;Одеса вул. Інглезі ( 25-ї Чапаєвської Дивізії ), 9А;6ed81d37-749b-11df-b112-00215aee3ebe
4616643f-43b6-11ec-80e3-000c29800ae7;Одеса-Каманіна 16А/2 Біле Сухе;Одеса вул. Каманіна, 16А/2;6ed81d37-749b-11df-b112-00215aee3ebe
cf666804-6dbd-11ee-80c1-000c2961d091;Одеса-Каманіна 16А/3 Rozetka;Одеса вул. Каманіна, 16А/3;6ed81d37-749b-11df-b112-00215aee3ebe
fab05bc4-15c1-11ee-80bd-000c2961d091;Одеса-Канатна 83 Військторг;Одеса вул. Канатна, 83;6ed81d37-749b-11df-b112-00215aee3ebe
f6a14e6c-dc58-11ee-80c4-000c2961d091;Одеса-Катерининська 14;Одеса вул. Катерининська, 14;6ed81d37-749b-11df-b112-00215aee3ebe
94d4ab5c-f305-11ec-80e7-000c29800ae7;Одеса-Катерининська 26 Rozetka;Одеса вул. Катерининська, 26;6ed81d37-749b-11df-b112-00215aee3ebe
189e230b-763e-11ee-80c1-000c2961d091;Одеса-Катерининська 27/1 Сільпо;Одеса вул. Катерининська, 27/1;6ed81d37-749b-11df-b112-00215aee3ebe
745d7d35-87b1-11ee-80c1-000c2961d091;Одеса-Катерининська 48 Копійка;Одеса вул. Катерининська, 48;6ed81d37-749b-11df-b112-00215aee3ebe
1504c90f-5d28-11ee-80c0-000c2961d091;Одеса-Катерининська 85 Vodafone;Одеса вул. Катерининська, 85;6ed81d37-749b-11df-b112-00215aee3ebe
02ef7a5f-d53d-11e9-80e4-1c98ec135261;Одеса-Князівська 2 Сервісний Центр;Одеса вул. Князівська, 2;6ed81d37-749b-11df-b112-00215aee3ebe
75021399-763a-11ee-80c1-000c2961d091;Одеса-Корольова Академіка 44 Сільпо;Одеса вул. Корольова Академіка, 44;6ed81d37-749b-11df-b112-00215aee3ebe
2c6f20bd-92e2-11ee-80c1-000c2961d091;Одеса-Корольова Академіка 58 Джерело здоровя;Одеса вул. Корольова Академіка, 58;6ed81d37-749b-11df-b112-00215aee3ebe
6328fbbc-bcd6-11ed-80ed-000c29800ae7;Одеса-Корольова Академіка 70 Lifecell;Одеса вул. Корольова Академіка, 70;6ed81d37-749b-11df-b112-00215aee3ebe
a6cd2642-238e-11ed-80e7-000c29800ae7;Одеса-Корольова Академіка 98 Lifecell;Одеса вул. Корольова Академіка, 98;6ed81d37-749b-11df-b112-00215aee3ebe
cb1fa6d7-43b3-11ec-80e3-000c29800ae7;Одеса-Космонавтів 14 Біле Сухе;Одеса вул. Космонавтів, 14;6ed81d37-749b-11df-b112-00215aee3ebe
3b5f9522-173e-11ee-80bd-000c2961d091;Одеса-Космонавтів 32 Lifecell;Одеса вул. Космонавтів, 32;6ed81d37-749b-11df-b112-00215aee3ebe
85d87b29-173e-11ee-80bd-000c2961d091;Одеса-Космонавтів 9 Lifecell;Одеса вул. Космонавтів, 9;6ed81d37-749b-11df-b112-00215aee3ebe
4488353b-3aaa-11e9-80dd-1c98ec135261;Одеса-Краснова 3Б/1 ОККО;Одеса вул. Краснова, 3Б/1;6ed81d37-749b-11df-b112-00215aee3ebe
33998fce-f306-11ec-80e7-000c29800ae7;Одеса-Левітана 118/2 Rozetka;Одеса вул. Левітана, 118/2;6ed81d37-749b-11df-b112-00215aee3ebe
7f8d6566-f306-11ec-80e7-000c29800ae7;Одеса-Лип Івана та Юрія 13А Rozetka;Одеса вул. Лип Івана та Юрія, 13А;6ed81d37-749b-11df-b112-00215aee3ebe
0568810b-5c69-11ee-80c0-000c2961d091;Одеса-Люстдорфська 142б/6 Hookah shop;Одеса дорога Люстдорфська, 142б/6;6ed81d37-749b-11df-b112-00215aee3ebe
f1435f7f-f306-11ec-80e7-000c29800ae7;Одеса-Люстдорфська 52 Rozetka;Одеса дорога Люстдорфська, 52;6ed81d37-749b-11df-b112-00215aee3ebe
740cf444-763e-11ee-80c1-000c2961d091;Одеса-Люстдорфська 54 Сільпо;Одеса дорога Люстдорфська, 54;6ed81d37-749b-11df-b112-00215aee3ebe
37b3ade4-ac58-11ed-80ec-000c29800ae7;Одеса-Мала Арнаутська 53 Rozetka;Одеса вул. Мала Арнаутська (Воровського), 53;6ed81d37-749b-11df-b112-00215aee3ebe
962056c7-87b2-11ee-80c1-000c2961d091;Одеса-Малиновського Маршала 18А Копійка;Одеса вул. Малиновського Маршала, 18А;6ed81d37-749b-11df-b112-00215aee3ebe
5fa0176e-47fd-11ee-80c0-000c2961d091;Одеса-Махачкалинська 17/2 Продукти;Одеса вул. Махачкалинська, 17/2;6ed81d37-749b-11df-b112-00215aee3ebe
4e2eefc2-1da1-11e6-b35b-001ec93df11f;Одеса-Миколаївська дорога, 126;Одеса дорога Миколаївська (дорога), 126;6ed81d37-749b-11df-b112-00215aee3ebe
7d8e6ffd-763b-11ee-80c1-000c2961d091;Одеса-Небесної Сотні 14 Сільпо;Одеса Просп. Небесної Сотні ( Жукова Маршала ), 14;6ed81d37-749b-11df-b112-00215aee3ebe
25faa6b4-ab0d-11ea-80c8-000c29800ae7;Одеса-Небесної Сотні 14Б18 Superbis;Одеса Просп. Небесної Сотні ( Жукова Маршала ), 14Б18;6ed81d37-749b-11df-b112-00215aee3ebe
ade23bc3-5788-11ee-80c0-000c2961d091;Одеса-Небесної Сотні 2 Сільпо;Одеса Просп. Небесної Сотні ( Жукова Маршала ), 2;6ed81d37-749b-11df-b112-00215aee3ebe
c3517069-87b1-11ee-80c1-000c2961d091;Одеса-Небесної Сотні 53 Копійка;Одеса Просп. Небесної Сотні ( Жукова Маршала ), 53;6ed81d37-749b-11df-b112-00215aee3ebe
c56682a2-763c-11ee-80c1-000c2961d091;Одеса-Небесної Сотні 5А Сільпо;Одеса Просп. Небесної Сотні ( Жукова Маршала ), 5А;6ed81d37-749b-11df-b112-00215aee3ebe
0b600602-87b5-11ee-80c1-000c2961d091;Одеса-Небесної Сотні 8/10 Копійка;Одеса Просп. Небесної Сотні ( Жукова Маршала ), 8/10;6ed81d37-749b-11df-b112-00215aee3ebe
e04b065a-d47d-11ed-80ed-000c29800ae7;Одеса-Ніжинська 12 Tabaco Market;Одеса вул. Ніжинська, 12;6ed81d37-749b-11df-b112-00215aee3ebe
816b67ca-c401-11ed-80ed-000c29800ae7;Одеса-Новосельського 58 Зоомагазин;Одеса вул. Новосельського (Приморський р-н), 58;6ed81d37-749b-11df-b112-00215aee3ebe
686e4e11-f307-11ec-80e7-000c29800ae7;Одеса-Новощепний Ряд 2 Rozetka;Одеса вул. Новощепний Ряд, 2;6ed81d37-749b-11df-b112-00215aee3ebe
99550985-763f-11ee-80c1-000c2961d091;Одеса-Новощепний Ряд 2 Сільпо;Одеса вул. Новощепний Ряд, 2;6ed81d37-749b-11df-b112-00215aee3ebe
799cdca4-82cd-11ed-80ec-000c29800ae7;Одеса-Овідіопільська 3/1 Прораб;Одеса дорога Овідіопільська, 3/1;6ed81d37-749b-11df-b112-00215aee3ebe
09146274-dbd0-11ee-80c4-000c2961d091;Одеса-Одарія 2/1;Одеса вул. Одарія, 2/1;6ed81d37-749b-11df-b112-00215aee3ebe
edf8fcb1-ff3b-11eb-80d6-000c29800ae7;Одеса-Пантелеймонівська 34 Європошта;Одеса вул. Пантелеймонівська, 34;6ed81d37-749b-11df-b112-00215aee3ebe
8c79a8eb-fbc4-11ed-80ed-000c29800ae7;Одеса-Пантелеймонівська 74 Lifecell;Одеса вул. Пантелеймонівська, 74;6ed81d37-749b-11df-b112-00215aee3ebe
182b142f-31d4-11ee-80bd-000c2961d091;Одеса-Перлинна 10 Зоомагазин;Одеса ст. Перлинна, 10;6ed81d37-749b-11df-b112-00215aee3ebe
2bdcd758-eb21-11ed-80ed-000c29800ae7;Одеса-Перлинна 5Б Rozetka;Одеса ст. Перлинна, 5Б;6ed81d37-749b-11df-b112-00215aee3ebe
a4a2f81e-7980-11ee-80c1-000c2961d091;Одеса-Пестеля 2 ОККО;Одеса вул. Пестеля, 2;6ed81d37-749b-11df-b112-00215aee3ebe
a3f86b6f-5785-11ee-80c0-000c2961d091;Одеса-Петрова Генерала 51 Сільпо;Одеса вул. Петрова Генерала, 51;6ed81d37-749b-11df-b112-00215aee3ebe
bad57c29-2dc9-11ed-80e7-000c29800ae7;Одеса-Пішонівська 37 Біле Сухе;Одеса вул. Пішонівська, 37;6ed81d37-749b-11df-b112-00215aee3ebe
66a7fb39-9793-11eb-80d3-000c29800ae7;Одеса-Поштова 34а Продукти ;Одеса вул. Поштова, 34а;6ed81d37-749b-11df-b112-00215aee3ebe
c524a547-cff2-11ea-80ca-000c29800ae7;Одеса-Преображенська 28 Сервісний Центр;Одеса вул. Преображенська, 28;6ed81d37-749b-11df-b112-00215aee3ebe
8cfbd28a-717f-11ee-80c1-000c2961d091;Одеса-Преображенська 45А One Price;Одеса вул. Преображенська, 45А;6ed81d37-749b-11df-b112-00215aee3ebe
1a76a2ce-87b4-11ee-80c1-000c2961d091;Одеса-Преображенська 66 Копійка;Одеса вул. Преображенська, 66;6ed81d37-749b-11df-b112-00215aee3ebe
b4328201-f307-11ec-80e7-000c29800ae7;Одеса-Преображенська 68 Rozetka;Одеса вул. Преображенська, 68;6ed81d37-749b-11df-b112-00215aee3ebe
917e2c7e-717e-11ee-80c1-000c2961d091;Одеса-Привозна 2 к.1 One Price;Одеса вул. Привозна (Приморський район), 2 к.1;6ed81d37-749b-11df-b112-00215aee3ebe
0e258302-fbc3-11ed-80ed-000c29800ae7;Одеса-Пушкінська 59 Lifecell;Одеса вул. Пушкінська, 59;6ed81d37-749b-11df-b112-00215aee3ebe
22b2f963-da37-11ee-80c4-000c2961d091;Одеса-Рабіна Іцхака 2 Порт;Одеса вул. Рабіна Іцхака, 2;6ed81d37-749b-11df-b112-00215aee3ebe
61bea4a8-3a4a-11ed-80e9-000c29800ae7;Одеса-Рішельєвська 27 Lifecell;Одеса вул. Рішельєвська, 27;6ed81d37-749b-11df-b112-00215aee3ebe
f68dd079-2b9c-11ee-80bd-000c2961d091;Одеса-Рішельєвська 67 СПЕЦ;Одеса вул. Рішельєвська, 67;6ed81d37-749b-11df-b112-00215aee3ebe
0b72d47a-a37e-11eb-80d3-000c29800ae7;Одеса-Розкидайлівська 3 Хімчистка;Одеса вул. Розкидайлівська, 3;6ed81d37-749b-11df-b112-00215aee3ebe
d7f110c6-2b7e-11ee-80bd-000c2961d091;Одеса-Садова 2 Rozetka;Одеса вул. Садова, 2;6ed81d37-749b-11df-b112-00215aee3ebe
4bd316a5-1b2c-11ee-80bd-000c2961d091;Одеса-Сахарова Академіка 1А Lifecell;Одеса вул. Сахарова Академіка, 1А;6ed81d37-749b-11df-b112-00215aee3ebe
15f8d350-f308-11ec-80e7-000c29800ae7;Одеса-Сахарова Академіка 1Б Rozetka;Одеса вул. Сахарова Академіка, 1Б;6ed81d37-749b-11df-b112-00215aee3ebe
b46f485b-5786-11ee-80c0-000c2961d091;Одеса-Семафорний 4 Сільпо;Одеса пров. Семафорний, 4;6ed81d37-749b-11df-b112-00215aee3ebe
810a9bfc-f308-11ec-80e7-000c29800ae7;Одеса-Семафорний 4Т Rozetka;Одеса пров. Семафорний, 4Т;6ed81d37-749b-11df-b112-00215aee3ebe
03b92b95-682f-11ee-80c0-000c2961d091;Одеса-Семена Палія 108 СПЕЦ;Одеса вул. Семена Палія (Дніпропетровська дорога), 108;6ed81d37-749b-11df-b112-00215aee3ebe
e40ed6d1-763e-11ee-80c1-000c2961d091;Одеса-Семена Палія 125Б Сільпо;Одеса вул. Семена Палія (Дніпропетровська дорога), 125Б;6ed81d37-749b-11df-b112-00215aee3ebe
c4d5abe1-763a-11ee-80c1-000c2961d091;Одеса-Семена Палія 72 Сільпо;Одеса вул. Семена Палія (Дніпропетровська дорога), 72;6ed81d37-749b-11df-b112-00215aee3ebe
83e59ed8-5789-11ee-80c0-000c2961d091;Одеса-Семена Палія 93А Сільпо;Одеса вул. Семена Палія (Дніпропетровська дорога), 93А;6ed81d37-749b-11df-b112-00215aee3ebe
dbd2f476-a4a4-11e9-80e2-1c98ec135261;Одеса-Семена Палія 99А ОККО;Одеса вул. Семена Палія (Дніпропетровська дорога), 99А;6ed81d37-749b-11df-b112-00215aee3ebe
8e3c6004-a985-11ee-80c4-000c2961d091;Одеса-Семена Палія 99В Lifecell;Одеса вул. Семена Палія (Дніпропетровська дорога), 99В;6ed81d37-749b-11df-b112-00215aee3ebe
973238d5-57c0-11ee-80c0-000c2961d091;Одеса-Скісна 16 Продукти;Одеса вул. Скісна, 16;6ed81d37-749b-11df-b112-00215aee3ebe
623a5680-e03e-11ed-80ed-000c29800ae7;Одеса-Соборна 1 Мобільні аксесуари;Одеса пл. Соборна, 1;6ed81d37-749b-11df-b112-00215aee3ebe
c53396f8-22c9-11ed-80e7-000c29800ae7;Одеса-Старосінна 1А Lifecell;Одеса пл. Старосінна, 1А;6ed81d37-749b-11df-b112-00215aee3ebe
09dcecdf-5dcc-11ee-80c0-000c2961d091;Одеса-Тираспольська 17 Hookah shop;Одеса вул. Тираспольська (Приморський р-н), 17;6ed81d37-749b-11df-b112-00215aee3ebe
bf762103-a4a8-11e9-80e2-1c98ec135261;Одеса-Тираспольське 22 Е;Одеса шосе Тираспольське (шосе), 22Е;6ed81d37-749b-11df-b112-00215aee3ebe
9f46e0c2-87b4-11ee-80c1-000c2961d091;Одеса-Тіниста 13А Копійка;Одеса вул. Тіниста, 13А;6ed81d37-749b-11df-b112-00215aee3ebe
f5c51a6a-1f01-11ee-80bd-000c2961d091;Одеса-Толбухіна 37 Будівельний Прораб;Одеса вул. Толбухіна, 37;6ed81d37-749b-11df-b112-00215aee3ebe
8e299739-7e4d-11ee-80c1-000c2961d091;Одеса-Торгова 35 Andi;Одеса вул. Торгова (Приморський р-н), 35;6ed81d37-749b-11df-b112-00215aee3ebe
36519e2f-173d-11ee-80bd-000c2961d091;Одеса-Торгова 36 Lifecell;Одеса вул. Торгова (Приморський р-н), 36;6ed81d37-749b-11df-b112-00215aee3ebe
5170c863-87b5-11ee-80c1-000c2961d091;Одеса-Троїцька 12 Копійка;Одеса вул. Троїцька, 12;6ed81d37-749b-11df-b112-00215aee3ebe
189ea0ea-763d-11ee-80c1-000c2961d091;Одеса-Філатова Академіка 1 Сільпо;Одеса вул. Філатова Академіка, 1;6ed81d37-749b-11df-b112-00215aee3ebe
93ad21d1-14cd-11ee-80bd-000c2961d091;Одеса-Філатова Академіка 2 А СПЕЦ;Одеса вул. Філатова Академіка, 2А;6ed81d37-749b-11df-b112-00215aee3ebe
1ff1c4d4-a381-11eb-80d3-000c29800ae7;Одеса-Філатова Академіка 21 CleanUp;Одеса вул. Філатова Академіка, 21;6ed81d37-749b-11df-b112-00215aee3ebe
35979b2f-c473-11eb-80d5-000c29800ae7;Одеса-Філатова Академіка 51 Біле Сухе;Одеса вул. Філатова Академіка, 51;6ed81d37-749b-11df-b112-00215aee3ebe
4a8754ae-76a9-11eb-80d1-000c29800ae7;Одеса-Фонтанська 12А Косметика;Одеса дорога Фонтанська, 12А;6ed81d37-749b-11df-b112-00215aee3ebe
a6a7045d-682f-11ee-80c0-000c2961d091;Одеса-Фонтанська 15 СПЕЦ;Одеса дорога Фонтанська, 15;6ed81d37-749b-11df-b112-00215aee3ebe
1c921a72-763c-11ee-80c1-000c2961d091;Одеса-Фонтанська 39 Сільпо;Одеса дорога Фонтанська, 39;6ed81d37-749b-11df-b112-00215aee3ebe
425c4b10-763f-11ee-80c1-000c2961d091;Одеса-Фонтанська 58/1 Сільпо;Одеса дорога Фонтанська, 58/1;6ed81d37-749b-11df-b112-00215aee3ebe
e5a9fbab-da35-11ee-80c4-000c2961d091;Одеса-Фонтанська 67А Порт;Одеса дорога Фонтанська, 67А;6ed81d37-749b-11df-b112-00215aee3ebe
7d26f71d-578a-11ee-80c0-000c2961d091;Одеса-Французький 16 Сільпо;Одеса бул. Французький, 16;6ed81d37-749b-11df-b112-00215aee3ebe
b1b7e1bc-c477-11eb-80d5-000c29800ae7;Одеса-Французький 22/5 Біле Сухе;Одеса бул. Французький, 22/5;6ed81d37-749b-11df-b112-00215aee3ebe
c98590bf-3aa8-11e9-80dd-1c98ec135261;Одеса-Херсонський 1 ОККО;Одеса сквер Херсонський, 1;6ed81d37-749b-11df-b112-00215aee3ebe
551e101b-5788-11ee-80c0-000c2961d091;Одеса-Черняховського 1 Сільпо;Одеса вул. Черняховського, 1;6ed81d37-749b-11df-b112-00215aee3ebe
3453fea8-22c9-11ed-80e7-000c29800ae7;Одеса-Черняховського 4 Lifecell;Одеса вул. Черняховського, 4;6ed81d37-749b-11df-b112-00215aee3ebe
3009be0f-9940-11eb-80d3-000c29800ae7;Оженин-Мирна 21;Оженин вул. Мирна, 21;6ed81d57-749b-11df-b112-00215aee3ebe
a263c883-587d-11ee-80c0-000c2961d091;Озерне-Авіаційна 72;Озерне вул. Авіаційна, 72;6ed81d7c-749b-11df-b112-00215aee3ebe
fac1011e-2865-11e9-80dd-1c98ec135261;Олевськ-Кооперативна 1;Олевськ вул. Кооперативна, 1;6ed81dc9-749b-11df-b112-00215aee3ebe
d33b3b9d-280f-11eb-80ce-000c29800ae7;Олександрівка-Гагаріна 2 В;Олександрівка вул. Гагаріна, 2В;6ed81df6-749b-11df-b112-00215aee3ebe
547f99d4-e1ea-11ee-80c5-000c2961d091;Олександрівка-Незалежності 106;Олександрівка вул. Незалежності, 106;6ed81e25-749b-11df-b112-00215aee3ebe
8a3c3385-898a-11e8-80d4-1c98ec135261;Олександрівка-Незалежності України, 43;Олександрівка вул. Незалежності України, 43;6ed81e25-749b-11df-b112-00215aee3ebe
5ccb2d1b-1491-11ec-80dd-000c29800ae7;Олександрія- прос.Соборний 48 ;Олександрія Просп. Соборний, 48;6ed81e40-749b-11df-b112-00215aee3ebe
ae7f9f66-5c4f-11ee-80c0-000c2961d091;Олександрія-Гетьмана Мазепи 16;Олександрія вул. Гетьмана Мазепи (Героїв Сталінграда), 16;6ed81e40-749b-11df-b112-00215aee3ebe
bf8e8a24-a7bc-11e9-80e4-1c98ec135261;Олександрія-Куйбишева 95;Олександрія вул. Куйбишева, 95;6ed81e40-749b-11df-b112-00215aee3ebe
11eff866-2167-11ee-80bd-000c2961d091;Олександрія-просп. Соборний 98;Олександрія Просп. Соборний, 98;6ed81e40-749b-11df-b112-00215aee3ebe
34fcc3ef-f260-11ec-80e7-000c29800ae7;Олександрія-просп.Соборний,100А;Олександрія Просп. Соборний, 100А;6ed81e40-749b-11df-b112-00215aee3ebe
a103b760-5c4e-11ee-80c0-000c2961d091;Олександрія-Садова 3;Олександрія вул. Садова, 3;6ed81e40-749b-11df-b112-00215aee3ebe
d9266b7f-fe81-11eb-80d6-000c29800ae7;Олександрія-Садова 64;Олександрія вул. Садова, 64;6ed81e40-749b-11df-b112-00215aee3ebe
a018ce30-2166-11ee-80bd-000c2961d091;Олександрія-Соборна 2;Олександрія пл. Соборна ( Леніна ), 2;6ed81e40-749b-11df-b112-00215aee3ebe
54b5b7b2-bf37-11e9-80e4-1c98ec135261;Олександрія-Соборний 94;Олександрія Просп. Соборний, 94;6ed81e40-749b-11df-b112-00215aee3ebe
a2568a87-d3f8-11eb-80d5-000c29800ae7;Олександрія-Соборний 95;Олександрія Просп. Соборний, 95;6ed81e40-749b-11df-b112-00215aee3ebe
4434076a-fe82-11eb-80d6-000c29800ae7;Олександрія-Усика Григорія 38;Олександрія вул. Усика Григорія (Червоноармійська), 38;6ed81e40-749b-11df-b112-00215aee3ebe
ca80c7e3-d028-11eb-80d5-000c29800ae7;Олександрія-Усика Григорія 40;Олександрія вул. Усика Григорія (Червоноармійська), 40;6ed81e40-749b-11df-b112-00215aee3ebe
2bc44209-e95b-11eb-80d5-000c29800ae7;Олександрія-Усика Григорія 58;Олександрія вул. Усика Григорія (Червоноармійська), 58;6ed81e40-749b-11df-b112-00215aee3ebe
12194c7a-f3d4-11ed-80ed-000c29800ae7;Олешин-Підлісна 2/2;Олешин вул. Підлісна, 2/2;6ed81ec3-749b-11df-b112-00215aee3ebe
b624bee0-c26f-11ed-80ed-000c29800ae7;Онуфріївка-Центральна 23;Онуфріївка вул. Центральна, 23;6ed81f30-749b-11df-b112-00215aee3ebe
1bf93412-d7d7-11ee-80c4-000c2961d091;Онуфріївка-Центральна 44;Онуфріївка вул. Центральна, 44;6ed81f30-749b-11df-b112-00215aee3ebe
a0eabc49-a70b-11e9-80e2-1c98ec135261;Оратів-Героїв Майдану 2 А;Оратів вул. Героїв Майдану, 2А;6ed81f4a-749b-11df-b112-00215aee3ebe
742e252d-1d3f-11ec-80df-000c29800ae7;Орлівщина-Покровська 4;Орлівщина вул. Покровська, 4;6ed81fac-749b-11df-b112-00215aee3ebe
d471f445-6370-11ee-80c0-000c2961d091;Остер-Незалежності 25/1;Остер вул. Незалежності, 25/1;74dc4601-749b-11df-b112-00215aee3ebe
1b17345e-7640-11ee-80c1-000c2961d091;Остер-Хмельницького Богдана 54;Остер вул. Хмельницького Богдана, 54;74dc4601-749b-11df-b112-00215aee3ebe
6384d14f-a7a8-11e9-80e4-1c98ec135261;Остриця-Чернівецька  33;Остриця вул. Чернівецька, 33;74dc4608-749b-11df-b112-00215aee3ebe
d57ae716-a7a8-11e9-80e4-1c98ec135261;Остриця-Чернівецька 16;Остриця вул. Чернівецька, 16;74dc4608-749b-11df-b112-00215aee3ebe
363d2b62-e848-11ee-80c5-000c2961d091;Острог-Незалежності 77А;Острог вул. Незалежності, 77А;74dc4632-749b-11df-b112-00215aee3ebe
2ee74c3b-b363-11e9-80e4-1c98ec135261;Острог-Островського Миколи 30;Острог вул. Островського Миколи, 30;74dc4632-749b-11df-b112-00215aee3ebe
7b4a4abc-ad16-11ed-80ec-000c29800ae7;Острог-Українки Лесі 21;Острог вул. Українки Лесі, 21;74dc4632-749b-11df-b112-00215aee3ebe
ec6903c8-5b96-11ed-80eb-000c29800ae7;Охтирка-Незалежності 20;Охтирка вул. Незалежності (Жовтнева), 20;74dc4657-749b-11df-b112-00215aee3ebe
630068cc-40d6-11ee-80c0-000c2961d091;Охтирка-Незалежності 30;Охтирка вул. Незалежності (Жовтнева), 30;74dc4657-749b-11df-b112-00215aee3ebe
094dcc54-3d6b-11ec-80e3-000c29800ae7;Охтирка-Харківський 12;Охтирка пров. Харківський, 12;74dc4657-749b-11df-b112-00215aee3ebe
93567453-a388-11eb-80d3-000c29800ae7;Охтирка-Харківський 2;Охтирка пров. Харківський, 2;74dc4657-749b-11df-b112-00215aee3ebe
d707456a-2086-11ee-80bd-000c2961d091;Охтирка-Шевченка 18;Охтирка вул. Шевченка, 18;74dc4657-749b-11df-b112-00215aee3ebe
0d61faef-d3f9-11eb-80d5-000c29800ae7;П'ятихатки-Олександрійська 55;П'ятихатки вул. Олександрійська, 55;7ad94f20-749b-11df-b112-00215aee3ebe
00791f01-9139-11eb-80d2-000c29800ae7;П'ятихатки-Пушкіна 93;П'ятихатки вул. Європейська (Пушкіна), 93;7ad94f20-749b-11df-b112-00215aee3ebe
b8a2b220-22f0-11e9-80dc-1c98ec135261;П'ятничани-Львівська 10 А;П'ятничани вул. Львівська, 10А;7ad94f2e-749b-11df-b112-00215aee3ebe
657e940e-f067-11eb-80d5-000c29800ae7;Павлиш-Леніна 18;Павлиш вул. Леніна, 18;74dc467b-749b-11df-b112-00215aee3ebe
d28eab2c-3a2e-11e3-9df6-003048d2b473;Павлоград;Павлоград вул. Харківська, 88;74dc46c4-749b-11df-b112-00215aee3ebe
c0a35e28-5d41-11ee-80c0-000c2961d091;Павлоград-Ватолиної 9 Делві;Павлоград вул. Ватолиної, 9;74dc46c4-749b-11df-b112-00215aee3ebe
4478bfec-08d2-11ed-80e7-000c29800ae7;Павлоград-Водопровідна 13 Rozetka;Павлоград вул. Водопровідна, 13;74dc46c4-749b-11df-b112-00215aee3ebe
df395160-0c34-11ee-80bd-000c2961d091;Павлоград-Дмитра Бочарникова 11А Делві;Павлоград вул. Дмитра Бочарникова (Сташкова), 11А;74dc46c4-749b-11df-b112-00215aee3ebe
2bcf5348-a7bf-11e9-80e4-1c98ec135261;Павлоград-Дніпровська 156 ОККО;Павлоград вул. Дніпровська, 156;74dc46c4-749b-11df-b112-00215aee3ebe
c5551f1b-1c53-11ec-80df-000c29800ae7;Павлоград-Дніпровська 340 Делві;Павлоград вул. Дніпровська, 340;74dc46c4-749b-11df-b112-00215aee3ebe
fa111b2a-b5f2-11ee-80c4-000c2961d091;Павлоград-Дніпровська 424 Andi;Павлоград вул. Дніпровська, 424;74dc46c4-749b-11df-b112-00215aee3ebe
0c720533-1c55-11ec-80df-000c29800ae7;Павлоград-Європейська 3А Делві;Павлоград вул. Європейська (Комарова), 3А;74dc46c4-749b-11df-b112-00215aee3ebe
113d9bd6-1c52-11ec-80df-000c29800ae7;Павлоград-Заводська 29 Делві;Павлоград вул. Заводська, 29;74dc46c4-749b-11df-b112-00215aee3ebe
2551a637-1c54-11ec-80df-000c29800ae7;Павлоград-Західнодонбаська 13 Делві;Павлоград вул. Західнодонбаська ( Ленінградська ), 13;74dc46c4-749b-11df-b112-00215aee3ebe
94ad7fd4-6400-11ed-80ec-000c29800ae7;Павлоград-Західнодонбаська 37прим.1 Делві;Павлоград вул. Західнодонбаська ( Ленінградська ), 37прим.1;74dc46c4-749b-11df-b112-00215aee3ebe
93d7f5af-1c54-11ec-80df-000c29800ae7;Павлоград-Ленінградська 43 Делві;Павлоград вул. Ленінградська, 43;74dc46c4-749b-11df-b112-00215aee3ebe
45a14c81-47d5-11ee-80c0-000c2961d091;Павлоград-Леоніда Каденюка 15А Нектар;Павлоград вул. Леоніда Каденюка (Гагаріна), 15А;74dc46c4-749b-11df-b112-00215aee3ebe
4d30c5d9-d3f8-11eb-80d5-000c29800ae7;Павлоград-Незалежності 145А/1 Ломбард;Павлоград вул. Незалежності (Горького), 145А/1;74dc46c4-749b-11df-b112-00215aee3ebe
7699cc9f-f130-11ec-80e7-000c29800ae7;Павлоград-Незалежності 164 Rozetka;Павлоград вул. Незалежності (Горького), 164;74dc46c4-749b-11df-b112-00215aee3ebe
b0cc9834-7641-11ee-80c1-000c2961d091;Павлоград-Незалежності 166 Сільпо;Павлоград вул. Незалежності (Горького), 166;74dc46c4-749b-11df-b112-00215aee3ebe
794b4f03-1c55-11ec-80df-000c29800ae7;Павлоград-Промислова 6А Делві;Павлоград вул. Промислова, 6А;74dc46c4-749b-11df-b112-00215aee3ebe
faca824c-1c55-11ec-80df-000c29800ae7;Павлоград-Сергія Корольова 4/1 Делві;Павлоград вул. Сергія Корольова ( Тельмана ), 4/1;74dc46c4-749b-11df-b112-00215aee3ebe
ca2a24b9-83b2-11ea-80c6-000c29800ae7;Павлоград-Соборна 33 Нектар;Павлоград вул. Соборна, 33;74dc46c4-749b-11df-b112-00215aee3ebe
7732903e-1c52-11ec-80df-000c29800ae7;Павлоград-Соборна 74 Делві;Павлоград вул. Соборна (Леніна), 74;74dc46c4-749b-11df-b112-00215aee3ebe
df959ff3-1c52-11ec-80df-000c29800ae7;Павлоград-Харківська 106 Делві;Павлоград вул. Харківська, 106;74dc46c4-749b-11df-b112-00215aee3ebe
5ad710eb-0515-11ee-80bd-000c2961d091;Павлоград-Харківська 7Д АЗС БРСМ;Павлоград вул. Харківська, 7Д;74dc46c4-749b-11df-b112-00215aee3ebe
556b88bc-1c53-11ec-80df-000c29800ae7;Павлоград-Хуторська 9А/3 Делві;Павлоград вул. Хуторська (Плеханова), 9А/3;74dc46c4-749b-11df-b112-00215aee3ebe
5ee2b24e-b5f2-11ee-80c4-000c2961d091;Павлоград-Шахтобудівників 13 Andi;Павлоград Просп. Шахтобудівників, 13;74dc46c4-749b-11df-b112-00215aee3ebe
09f19841-c540-11eb-80d5-000c29800ae7;Павлоград-Шахтобудівників 6 Ломбард;Павлоград Просп. Шахтобудівників, 6;74dc46c4-749b-11df-b112-00215aee3ebe
be310ace-eedb-11eb-80d5-000c29800ae7;Партизанське-Центральна 2А;Партизанське вул. Центральна, 2А;74dc4762-749b-11df-b112-00215aee3ebe
e2cf99d6-7ba1-11e9-80e0-1c98ec135261;Пасіки-Зубрицькі-Хмельницького 3;Пасіки-Зубрицькі вул. Хмельницького Богдана, 3;74dc477e-749b-11df-b112-00215aee3ebe
5d859fc3-47f2-11ee-80c0-000c2961d091;Первомайськ-40-річчя Перемоги 3;Первомайськ вул. 40-річчя Перемоги, 3;74dc47df-749b-11df-b112-00215aee3ebe
0f512f2d-47f2-11ee-80c0-000c2961d091;Первомайськ-Базарна 3;Первомайськ вул. Базарна, 3;74dc47df-749b-11df-b112-00215aee3ebe
47a86aee-47f5-11ee-80c0-000c2961d091;Первомайськ-Вознесенська 23А;Первомайськ вул. Вознесенська, 23А;74dc47df-749b-11df-b112-00215aee3ebe
7dba5db4-47f7-11ee-80c0-000c2961d091;Первомайськ-Голтянська ( 20-річчя Жовтня ) 100;Первомайськ вул. Голтянська ( 20-річчя Жовтня ), 100;74dc47df-749b-11df-b112-00215aee3ebe
e7b55825-f04b-11eb-80d5-000c29800ae7;Первомайськ-Грушевського 14/1;Первомайськ вул. Грушевського, 14/1;74dc47df-749b-11df-b112-00215aee3ebe
262f61a4-87c0-11ee-80c1-000c2961d091;Первомайськ-Грушевського 32;Первомайськ вул. Грушевського, 32;74dc47df-749b-11df-b112-00215aee3ebe
616c8cc4-6432-11ee-80c0-000c2961d091;Первомайськ-Грушевського 41/35;Первомайськ вул. Грушевського, 41/35;74dc47df-749b-11df-b112-00215aee3ebe
2b323ca0-47fa-11ee-80c0-000c2961d091;Первомайськ-Дніпровська 49;Первомайськ вул. Дніпровська, 49;74dc47df-749b-11df-b112-00215aee3ebe
43665ad5-47f6-11ee-80c0-000c2961d091;Первомайськ-Достоєвського 31А;Первомайськ вул. Достоєвського, 31А;74dc47df-749b-11df-b112-00215aee3ebe
c6a48d64-4126-11eb-80d0-000c29800ae7;Первомайський-4-й 2/3;Первомайський мікр. 4-й, 2/3;74dc4800-749b-11df-b112-00215aee3ebe
5252010b-3d6a-11ec-80e3-000c29800ae7;Первомайський-Комарова Космонавта 11;Первомайський вул. Центральна (Комарова Космонавта), 11;74dc4800-749b-11df-b112-00215aee3ebe
6b459274-3ee1-11eb-80d0-000c29800ae7;Первомайський-Комарова Космонавта 29;Первомайський вул. Комарова Космонавта, 29;74dc4800-749b-11df-b112-00215aee3ebe
46565f31-47f9-11ee-80c0-000c2961d091;Первомайськ-Київська 59;Первомайськ вул. Київська, 59;74dc47df-749b-11df-b112-00215aee3ebe
da35f18d-a4a3-11e9-80e2-1c98ec135261;Первомайськ-Київська 7;Первомайськ вул. Київська, 7;74dc47df-749b-11df-b112-00215aee3ebe
27ac1dc5-51c7-11ec-80e4-000c29800ae7;Первомайськ-Корабельна 17Х;Первомайськ вул. Корабельна, 17Х;74dc47df-749b-11df-b112-00215aee3ebe
872921d9-47f8-11ee-80c0-000c2961d091;Первомайськ-Коротченка 1/5;Первомайськ вул. Коротченка, 1/5;74dc47df-749b-11df-b112-00215aee3ebe
f3600d0c-47f7-11ee-80c0-000c2961d091;Первомайськ-Коротченка 18;Первомайськ вул. Коротченка, 18;74dc47df-749b-11df-b112-00215aee3ebe
c450f7ac-47f6-11ee-80c0-000c2961d091;Первомайськ-Молокова 52/1;Первомайськ вул. Молокова, 52/1;74dc47df-749b-11df-b112-00215aee3ebe
c77df262-47f1-11ee-80c0-000c2961d091;Первомайськ-Одеська 113/3;Первомайськ вул. Одеська, 113/3;74dc47df-749b-11df-b112-00215aee3ebe
808b630b-47fa-11ee-80c0-000c2961d091;Первомайськ-Праці 3А;Первомайськ Просп. Праці, 3А;74dc47df-749b-11df-b112-00215aee3ebe
24d998b2-47f7-11ee-80c0-000c2961d091;Первомайськ-Родімцева Гвардійців 65;Первомайськ вул. Родімцева Гвардійців, 65;74dc47df-749b-11df-b112-00215aee3ebe
41d27c3f-47f8-11ee-80c0-000c2961d091;Первомайськ-Рожева 5Б;Первомайськ вул. Рожева, 5Б;74dc47df-749b-11df-b112-00215aee3ebe
0feb47ac-a8fb-11eb-80d3-000c29800ae7;Первомайськ-Театральна 31;Первомайськ вул. Театральна, 31;74dc47df-749b-11df-b112-00215aee3ebe
967132d6-47f9-11ee-80c0-000c2961d091;Первомайськ-Толбухіна 99;Первомайськ вул. Толбухіна, 99;74dc47df-749b-11df-b112-00215aee3ebe
ddb2ff51-47f9-11ee-80c0-000c2961d091;Первомайськ-Урицького 102;Первомайськ вул. Урицького, 102;74dc47df-749b-11df-b112-00215aee3ebe
7013c5dd-5c48-11ea-80c6-000c29800ae7;Перегінське;Перегінське вул. Сотенного Довбуша, 5В;74dc4819-749b-11df-b112-00215aee3ebe
46afc152-328b-11e8-80d0-1c98ec135261;Перемишляни-Борщівська 1;Перемишляни вул. Борщівська, 1;74dc484e-749b-11df-b112-00215aee3ebe
779b74cc-3295-11e8-80d0-1c98ec135261;Перемишляни-Борщівська 24а;Перемишляни вул. Борщівська, 24а;74dc484e-749b-11df-b112-00215aee3ebe
404cb362-1195-11ee-80bd-000c2961d091;Перещепине-Шевченка 12;Перещепине вул. Шевченка, 12;74dc48a3-749b-11df-b112-00215aee3ebe
5c172eb1-9898-11eb-80d3-000c29800ae7;Перещепине-Шевченка 14;Перещепине вул. Шевченка, 14;74dc48a3-749b-11df-b112-00215aee3ebe
54fe6224-0fc4-11ec-80d9-000c29800ae7;Перещепине-Шевченка 63;Перещепине вул. Шевченка, 63;74dc48a3-749b-11df-b112-00215aee3ebe
6252c7ea-0849-11ed-80e7-000c29800ae7;Переяслав-Небесної Сотні 2Б;Переяслав вул. Небесної Сотні, 2Б;74dc48a6-749b-11df-b112-00215aee3ebe
40091d9f-bd64-11eb-80d4-000c29800ae7;Переяслав-Хмельницький-Червоноармійців 31;Переяслав Просп. Червоноармійців, 31;74dc48a6-749b-11df-b112-00215aee3ebe
8aafd90b-e61c-11eb-80d5-000c29800ae7;Переяслав-Хмельницький-Шкільна 51;Переяслав вул. Шкільна, 51;74dc48a6-749b-11df-b112-00215aee3ebe
9ef602de-dd4b-11ee-80c4-000c2961d091;Переяслав-Хмельницького Богдана 129/1;Переяслав вул. Хмельницького Богдана, 129/1;74dc48a6-749b-11df-b112-00215aee3ebe
b5335861-bef0-11eb-80d5-000c29800ae7;Першотравенськ-Горького 14;Першотравенськ вул. Горького, 14;74dc48cf-749b-11df-b112-00215aee3ebe
cecb0162-05e4-11ee-80bd-000c2961d091;Першотравенськ-Горького 4В;Першотравенськ вул. Горького, 4В;74dc48cf-749b-11df-b112-00215aee3ebe
11a58239-0bcd-11ec-80d6-000c29800ae7;Першотравенськ-Пушкіна 2;Першотравенськ вул. Пушкіна, 2;74dc48cf-749b-11df-b112-00215aee3ebe
9d1cb5f4-4ba7-11ec-80e3-000c29800ae7;Петриківка-Калнишевського 8А;Петриківка вул. Калнишевського, 8А;74dc4914-749b-11df-b112-00215aee3ebe
92f5e9f4-9a2c-11eb-80d3-000c29800ae7;Петрове-Літвінова 6;Петрове вул. Літвінова, 9;74dc49ab-749b-11df-b112-00215aee3ebe
b89f544a-2ecf-11e9-80dd-1c98ec135261;Петрове-Літвінова 9;Петрове вул. Літвінова, БН;74dc49ab-749b-11df-b112-00215aee3ebe
462ac0cd-f6b1-11eb-80d5-000c29800ae7;Петропавлівка-Соборна 15;Петропавлівка вул. Соборна, 15;74dc49c4-749b-11df-b112-00215aee3ebe
122188ab-0bd3-11ec-80d6-000c29800ae7;Петропавлівка-Соборна 18А;Петропавлівка вул. Соборна, 18А;74dc49c4-749b-11df-b112-00215aee3ebe
0ef05d35-05e7-11ee-80bd-000c2961d091;Петропавлівка-Червона 77;Петропавлівка вул. Червона, 77;74dc49c4-749b-11df-b112-00215aee3ebe
04353159-dab6-11ed-80ed-000c29800ae7;Петропавлівська Борщагівка-Велика Окружна 4 СПЕЦ;Петропавлівська Борщагівка вул. Велика Окружна, 4;74dc49d5-749b-11df-b112-00215aee3ebe
85e51e5e-0e99-11ee-80bd-000c2961d091;Петропавлівська Борщагівка-Львівська 1А;Петропавлівська Борщагівка вул. Львівська, 1А;74dc49d5-749b-11df-b112-00215aee3ebe
9ab8483a-3a6f-11ed-80e9-000c29800ae7;Петропавлівська Борщагівка--Львівська 1Акорп3;Петропавлівська Борщагівка вул. Львівська, 1Акорп3;74dc49d5-749b-11df-b112-00215aee3ebe
07eac327-0696-11ed-80e7-000c29800ae7;Петропавлівська Борщагівка-Петропавлівська 4;Петропавлівська Борщагівка вул. Петропавлівська, 4;74dc49d5-749b-11df-b112-00215aee3ebe
7b461d63-30aa-11eb-80ce-000c29800ae7;Пирятин-Героїв Майдану 1 Б;Пирятин пл. Героїв Майдану (Борців Революції), 1Б;74dc4a43-749b-11df-b112-00215aee3ebe
b80241bf-0519-11ee-80bd-000c2961d091;Пирятин-Європейська 157;Пирятин пров. Європейська, 157;74dc4a43-749b-11df-b112-00215aee3ebe
9b36594a-302e-11e9-80dd-1c98ec135261;Пирятин-Європейська 171;Пирятин пров. Європейська, 171;74dc4a43-749b-11df-b112-00215aee3ebe
505b549e-c757-11ee-80c4-000c2961d091;Пирятин-Незалежності 2Б;Пирятин пл. Незалежності, 2Б;74dc4a43-749b-11df-b112-00215aee3ebe
d8d2dca5-1f78-11eb-80cd-000c29800ae7;Підволочиськ-Галицького 58;Підволочиськ вул. Галицького, 58;74dc4a9f-749b-11df-b112-00215aee3ebe
0e731df0-9936-11eb-80d3-000c29800ae7;Підгайці-Галицька 1;Підгайці вул. Галицька, 1;74dc4aa7-749b-11df-b112-00215aee3ebe
9233eb0e-0517-11ee-80bd-000c2961d091;Підгайці-Паркова 44А АЗС БРСМ;Підгайці вул. Паркова, 44А;74dc4aa9-749b-11df-b112-00215aee3ebe
3229a7a0-cce7-11eb-80d5-000c29800ae7;Підгірне-Сонячна 22;Підгірне вул. Сонячна, 22;74dc4abd-749b-11df-b112-00215aee3ebe
8d5f192a-eaec-11eb-80d5-000c29800ae7;Підгірці-26 км а/д Київ-Луганськ;Підгірці вул. 26 км а/д Київ-Луганськ,;74dc4aca-749b-11df-b112-00215aee3ebe
d37ab9df-40bf-11ec-80e3-000c29800ae7;Підгородне-Партизанська 111;Підгородне вул. Партизанська, 111;74dc4add-749b-11df-b112-00215aee3ebe
f9eb0ac8-5b86-11ee-80c0-000c2961d091;Підгородне-Шосейна 1Т;Підгородне вул. Шосейна, 1Т;74dc4add-749b-11df-b112-00215aee3ebe
5a424907-27b6-11ee-80bd-000c2961d091;Пісочин-Квартальна 14;Пісочин вул. Квартальна, 14;74dc4b92-749b-11df-b112-00215aee3ebe
2b8f0339-615c-11eb-80d0-000c29800ae7;Пісочин-Надії 28 А;Пісочин вул. Надії, 28А;74dc4b92-749b-11df-b112-00215aee3ebe
9234bb87-a7c4-11e9-80e4-1c98ec135261;Пісочин-Полтавське шосе 208 А;Пісочин шосе Полтавське, 208А;74dc4b92-749b-11df-b112-00215aee3ebe
4a7f2efa-f85f-11eb-80d5-000c29800ae7;Побузьке-Першотравнева 16/2;Побузьке вул. Першотравнева, 16/2;74dc4c4e-749b-11df-b112-00215aee3ebe
321a36c2-7642-11ee-80c1-000c2961d091;Погреби-Погребський шлях 19;Погреби шлях Погребський шлях, 19;74dc4c75-749b-11df-b112-00215aee3ebe
3874e40d-4678-11ee-80c0-000c2961d091;Погребище-Вінницька 3;Погребище вул. Вінницька, 3;74dc4c7a-749b-11df-b112-00215aee3ebe
f5610d95-a70a-11e9-80e2-1c98ec135261;Погребище-Привокзальна 2;Погребище вул. Привокзальна, 2;74dc4c7a-749b-11df-b112-00215aee3ebe
caf2338d-becb-11eb-80d5-000c29800ae7;Погребище-Хмельницького Богдана 118 (Жжук);Погребище вул. Хмельницького Богдана, 118;74dc4c7a-749b-11df-b112-00215aee3ebe
b10e89ad-8f01-11ec-80e4-000c29800ae7;Подвірки-Сумський шлях 2Ж;Подвірки шлях Сумський, 2Ж;74dc4c83-749b-11df-b112-00215aee3ebe
da8e388a-1b28-11e9-80dc-1c98ec135261;Подільськ-8-го Березня 8;Подільськ вул. 8-го Березня, 8;5cb61bd4-749b-11df-b112-00215aee3ebe
9c8c29f8-f050-11eb-80d5-000c29800ae7;Подільськ-Базарна 2А;Подільськ пл. Базарна, 2А;5cb61bd4-749b-11df-b112-00215aee3ebe
f76e4330-1f1d-11ee-80bd-000c2961d091;Подільськ-Перемоги 1А;Подільськ Просп. Перемоги, 1А;5cb61bd4-749b-11df-b112-00215aee3ebe
64ac8adb-1797-11ec-80df-000c29800ae7;Подільськ-Перемоги 9А;Подільськ Просп. Перемоги, 9А;5cb61bd4-749b-11df-b112-00215aee3ebe
572ff3ef-178f-11ec-80df-000c29800ae7;Подільськ-Севастопольська 8А;Подільськ вул. Севастопольська, 8А;5cb61bd4-749b-11df-b112-00215aee3ebe
d68750b3-cf95-11ed-80ed-000c29800ae7;Подільськ-Соборна 243/1;Подільськ вул. Соборна, 243/1;5cb61bd4-749b-11df-b112-00215aee3ebe
d951daf4-1597-11ee-80bd-000c2961d091;Покотилівка-Незалежності 37;Покотилівка вул. Незалежності (Інтернаціональна), 37;74dc4ccb-749b-11df-b112-00215aee3ebe
4f34d888-cfbb-11ed-80ed-000c29800ae7;Покров-Партизанська 1/1;Покров вул. Партизанська, 1/1;6ed81f54-749b-11df-b112-00215aee3ebe
74a47668-f654-11ed-80ed-000c29800ae7;Покров-Соборна 11А;Покров вул. Соборна, 11А;6ed81f54-749b-11df-b112-00215aee3ebe
2cce5a8a-d8e7-11eb-80d5-000c29800ae7;Покров-Центральна 33;Покров вул. Центральна, 33;6ed81f54-749b-11df-b112-00215aee3ebe
73896aa3-2474-11ee-80bd-000c2961d091;Покров-Центральна 42/3;Покров вул. Центральна, 42/3;6ed81f54-749b-11df-b112-00215aee3ebe
df9ec02d-f908-11eb-80d5-000c29800ae7;Полонне-Герасимчука 2;Полонне вул. Герасимчука, 2;74dc4d32-749b-11df-b112-00215aee3ebe
85119f04-845d-11e8-80d4-1c98ec135261;Полонне-Герасимчука,10;Полонне вул. Герасимчука, 10;74dc4d32-749b-11df-b112-00215aee3ebe
665a33e0-fbcb-11ed-80ed-000c29800ae7;Полонне-Українки Лесі 113А;Полонне вул. Українки Лесі, 113А;74dc4d32-749b-11df-b112-00215aee3ebe
0ba4ad12-6e87-11ee-80c1-000c2961d091;Полонне-Українки Лесі 119;Полонне вул. Українки Лесі, 119;74dc4d32-749b-11df-b112-00215aee3ebe
e29c56f2-004e-11df-9277-00215aee3ebe;Полтава;Полтава вул. Половки, 72;74dc4d38-749b-11df-b112-00215aee3ebe
292627d0-7800-11ee-80c1-000c2961d091;Полтава-Алмазна 3 Рибалка;Полтава вул. Алмазна, 3;74dc4d38-749b-11df-b112-00215aee3ebe
a25f9f44-3cd9-11ee-80c0-000c2961d091;Полтава-Балакіна 85А Делві;Полтава вул. Семена Антонця (Балакіна), 85А;74dc4d38-749b-11df-b112-00215aee3ebe
cfc8709f-ef83-11eb-80d5-000c29800ae7;Полтава-Баленка 2 ЄвроСмак;Полтава вул. Баленка, 2;74dc4d38-749b-11df-b112-00215aee3ebe
e3bf6f34-7c84-11ee-80c1-000c2961d091;Полтава-Бідного Олександра 11 Делві;Полтава вул. Бідного Олександра, 11;74dc4d38-749b-11df-b112-00215aee3ebe
47b0e7aa-8937-11ea-80c6-000c29800ae7;Полтава-Бірюзова Маршала 15 On Top;Полтава вул. Бірюзова Маршала, 15;74dc4d38-749b-11df-b112-00215aee3ebe
83ea940d-fbd5-11e9-80e7-1c98ec135261;Полтава-Бірюзова Маршала 48/2 Автозапчастини;Полтава вул. Бірюзова Маршала, 48/2;74dc4d38-749b-11df-b112-00215aee3ebe
c8f071e2-7334-11ee-80c1-000c2961d091;Полтава-Великотирнівська 30 Кавярня;Полтава вул. Великотирнівська, 30;74dc4d38-749b-11df-b112-00215aee3ebe
1f5682ad-f658-11ed-80ed-000c29800ae7;Полтава-Великотирнівська 39А Rozetka;Полтава вул. Великотирнівська, 39А;74dc4d38-749b-11df-b112-00215aee3ebe
15e6ff54-f195-11ec-80e7-000c29800ae7;Полтава-Ветеринарна 22 Rozetka;Полтава вул. Ветеринарна, 22;74dc4d38-749b-11df-b112-00215aee3ebe
27ecf45e-c96e-11ed-80ed-000c29800ae7;Полтава-Героїв ОУН 26 Lifecell;Полтава вул. Героїв ОУН (Ковпака), 26;74dc4d38-749b-11df-b112-00215aee3ebe
5027553f-0cb0-11ec-80d6-000c29800ae7;Полтава-Героїв-чорнобильців 1 Ломбард;Полтава вул. Героїв-чорнобильців ( Чапаєва ), 1;74dc4d38-749b-11df-b112-00215aee3ebe
a5d7ff1e-f718-11e9-80e7-1c98ec135261;Полтава-Гоголя Миколи 38 Пральня;Полтава вул. Гоголя Миколи, 38;74dc4d38-749b-11df-b112-00215aee3ebe
c2140e6c-7ee9-11ee-80c1-000c2961d091;Полтава-Гожулівська 26 Хозяюшка;Полтава вул. Гожулівська, 26;74dc4d38-749b-11df-b112-00215aee3ebe
523ba8a5-ef84-11eb-80d5-000c29800ae7;Полтава-Євгена Коновальця 1Б ЄвроСмак;Полтава бул. Євгена Коновальця, 1Б;74dc4d38-749b-11df-b112-00215aee3ebe
6aa3f399-e063-11ee-80c4-000c2961d091;Полтава-Європейська 106 зоомагазин;Полтава вул. Європейська, 106;74dc4d38-749b-11df-b112-00215aee3ebe
af987530-7643-11ee-80c1-000c2961d091;Полтава-Європейська 185 Сільпо;Полтава вул. Європейська, 185;74dc4d38-749b-11df-b112-00215aee3ebe
5858f130-9a8c-11ee-80c1-000c2961d091;Полтава-Європейська 48 Грін Зона;Полтава вул. Європейська, 48;74dc4d38-749b-11df-b112-00215aee3ebe
999d41bb-fe59-11ed-80ed-000c29800ae7;Полтава-Європейська 6 CompX;Полтава вул. Європейська, 6;74dc4d38-749b-11df-b112-00215aee3ebe
642e1435-7643-11ee-80c1-000c2961d091;Полтава-Європейська 60А Сільпо;Полтава вул. Європейська, 60А;74dc4d38-749b-11df-b112-00215aee3ebe
7cb8106d-d6f5-11ee-80c4-000c2961d091;Полтава-Європейська 63 Фламинго;Полтава вул. Європейська, 63;74dc4d38-749b-11df-b112-00215aee3ebe
7fb71d1a-c885-11e7-80cc-1c98ec135261;Полтава-Європейська 66 Світ Електроніки;Полтава вул. Європейська, 66;74dc4d38-749b-11df-b112-00215aee3ebe
1c76012b-5239-11ee-80c0-000c2961d091;Полтава-Європейська 94 Rozetka;Полтава вул. Європейська, 94;74dc4d38-749b-11df-b112-00215aee3ebe
72d5e944-08cd-11ed-80e7-000c29800ae7;Полтава-Зіньківська 5 Rozetka;Полтава вул. Зіньківська, 5;74dc4d38-749b-11df-b112-00215aee3ebe
d939f06c-b116-11ed-80ec-000c29800ae7;Полтава-Зіньківська 6/1А Lifecell;Полтава вул. Зіньківська, 6/1А;74dc4d38-749b-11df-b112-00215aee3ebe
96836de5-7642-11ee-80c1-000c2961d091;Полтава-Зіньківська 6/1А Сільпо;Полтава вул. Зіньківська, 6/1А;74dc4d38-749b-11df-b112-00215aee3ebe
041d84b0-0cb0-11ec-80d6-000c29800ae7;Полтава-Івана Мазепи 33А Ломбард;Полтава вул. Івана Мазепи (Калініна), 33А;74dc4d38-749b-11df-b112-00215aee3ebe
93e0b84e-15c3-11ee-80bd-000c2961d091;Полтава-Івана Мазепи 43 На звязку;Полтава вул. Івана Мазепи (Калініна), 43;74dc4d38-749b-11df-b112-00215aee3ebe
7c104af1-f195-11ec-80e7-000c29800ae7;Полтава-Івана Мазепи 45/4 Rozetka Rozetka;Полтава вул. Івана Мазепи (Калініна), 45/4 Rozetka;74dc4d38-749b-11df-b112-00215aee3ebe
0b3dd1f7-7644-11ee-80c1-000c2961d091;Полтава-Івана Мазепи 45/4 Сільпо;Полтава вул. Івана Мазепи (Калініна), 45/4;74dc4d38-749b-11df-b112-00215aee3ebe
f809fe54-2e8d-11e8-80d0-1c98ec135261;Полтава-Івана Мазепи 9 ІТ-сервіс;Полтава вул. Івана Мазепи (Калініна), 9;74dc4d38-749b-11df-b112-00215aee3ebe
4aa8102e-ef7a-11eb-80d5-000c29800ae7;Полтава-Квітки Цісик 15Ж ЄвроСмак;Полтава вул. Квітки Цісик (Червонофлотська), 15Ж;74dc4d38-749b-11df-b112-00215aee3ebe
bf49cb47-2675-11ec-80e3-000c29800ae7;Полтава-Керамічний 11 Автодетальки;Полтава пров. Керамічний, 11;74dc4d38-749b-11df-b112-00215aee3ebe
58f09d9f-3b3c-11e9-80dd-1c98ec135261;Полтава-Київське шосе 29А ОККО;Полтава шосе Київське шосе, 29А;74dc4d38-749b-11df-b112-00215aee3ebe
53601cd5-49d1-11eb-80d0-000c29800ae7;Полтава-Короленка 17/53 Инструмент;Полтава вул. Короленка, 17/53;74dc4d38-749b-11df-b112-00215aee3ebe
45f0ecea-4486-11ec-80e3-000c29800ae7;Полтава-Кукоби Анатолія  12 ЄвроСмак;Полтава вул. Кукоби Анатолія  ( Пролетарська ), 12;74dc4d38-749b-11df-b112-00215aee3ebe
c92a5504-2e8e-11e8-80d0-1c98ec135261;Полтава-Кукоби Анатолія  27 ІТ-сервіс;Полтава вул. Кукоби Анатолія  ( Пролетарська ), 27;74dc4d38-749b-11df-b112-00215aee3ebe
eaa540ab-7642-11ee-80c1-000c2961d091;Полтава-Миру 30А Сільпо;Полтава Просп. Миру, 30А;74dc4d38-749b-11df-b112-00215aee3ebe
f3e48531-f195-11ec-80e7-000c29800ae7;Полтава-Небесної Сотні 10/19 Rozetka;Полтава вул. Небесної Сотні, 10/19;74dc4d38-749b-11df-b112-00215aee3ebe
4aeef831-ef85-11eb-80d5-000c29800ae7;Полтава-Небесної Сотні 112 ЄвроСмак;Полтава вул. Небесної Сотні, 112;74dc4d38-749b-11df-b112-00215aee3ebe
424db588-e6b4-11ee-80c5-000c2961d091;Полтава-Небесної Сотні 2 ЄвроСмак;Полтава вул. Небесної Сотні, 2;74dc4d38-749b-11df-b112-00215aee3ebe
2379645a-08cd-11ed-80e7-000c29800ae7;Полтава-Небесної Сотні 61 Rozetka;Полтава вул. Небесної Сотні, 61;74dc4d38-749b-11df-b112-00215aee3ebe
c266c2cc-0c33-11ee-80bd-000c2961d091;Полтава-Освітянська 6 Делві;Полтава вул. Освітянська, 6;74dc4d38-749b-11df-b112-00215aee3ebe
7c046af5-6403-11ed-80ec-000c29800ae7;Полтава-Патріарха Мстислава 72В Делві;Полтава вул. Патріарха Мстислава (Лібкнехта Карла), 72В;74dc4d38-749b-11df-b112-00215aee3ebe
11245588-bda8-11ed-80ed-000c29800ae7;Полтава-Пилипа Орлика 29 Co.cos.metica;Полтава вул. Пилипа Орлика, 29;74dc4d38-749b-11df-b112-00215aee3ebe
deff4e2f-3e23-11ec-80e3-000c29800ae7;Полтава-Полюсна 10А Делві;Полтава вул. Полюсна, 10А;74dc4d38-749b-11df-b112-00215aee3ebe
a1479d9f-ab8d-11ed-80ec-000c29800ae7;Полтава-Пушкіна 45 Канцелярські товари;Полтава вул. Матвійчука Юліана (Пушкіна), 45;74dc4d38-749b-11df-b112-00215aee3ebe
18b60407-58e5-11ec-80e4-000c29800ae7;Полтава-Серьогіна 4Б АЗС Motto;Полтава вул. Серьогіна, 4Б;74dc4d38-749b-11df-b112-00215aee3ebe
7749fdff-a653-11ee-80c4-000c2961d091;Полтава-Силікатний 4А Делві;Полтава пров. Силікатний, 4А;74dc4d38-749b-11df-b112-00215aee3ebe
2289a070-53a5-11ee-80c0-000c2961d091;Полтава-Сінна 2/49 Rozetka;Полтава вул. Сінна, 2/49;74dc4d38-749b-11df-b112-00215aee3ebe
26b7dab3-3b3b-11e9-80dd-1c98ec135261;Полтава-Сінна 42 ОККО;Полтава вул. Сінна, 42;74dc4d38-749b-11df-b112-00215aee3ebe
215e91ef-582d-11ec-80e4-000c29800ae7;Полтава-Соборності 29А Пеликан;Полтава вул. Соборності ( Жовтнева ), 29А;74dc4d38-749b-11df-b112-00215aee3ebe
98dd5d3e-9ff0-11ee-80c4-000c2961d091;Полтава-Соборності 43 Ломбард;Полтава вул. Соборності ( Жовтнева ), 43;74dc4d38-749b-11df-b112-00215aee3ebe
207b8da7-5884-11ee-80c0-000c2961d091;Полтава-Соборності 46 JoinUP;Полтава вул. Соборності ( Жовтнева ), 46;74dc4d38-749b-11df-b112-00215aee3ebe
3f51f46d-ff3b-11eb-80d6-000c29800ae7;Полтава-Соборності 60 Європошта;Полтава вул. Соборності ( Жовтнева ), 60;74dc4d38-749b-11df-b112-00215aee3ebe
63705156-d304-11ea-80ca-000c29800ae7;Полтава-Соборності 76 Чайгород;Полтава вул. Соборності ( Жовтнева ), 76;74dc4d38-749b-11df-b112-00215aee3ebe
c655eb70-000a-11ec-80d6-000c29800ae7;Полтава-Станіславського 14/2 ЄвроСмак;Полтава вул. Станіславського, 14/2;74dc4d38-749b-11df-b112-00215aee3ebe
76b54a96-b70e-11eb-80d4-000c29800ae7;Полтава-Стрітенська 34А GROШИК;Полтава вул. Стрітенська (Комсомольська), 34А;74dc4d38-749b-11df-b112-00215aee3ebe
487142de-7fc3-11ee-80c1-000c2961d091;Полтава-Стрітенська 38А ШАРландія;Полтава вул. Стрітенська (Комсомольська), 38А;74dc4d38-749b-11df-b112-00215aee3ebe
0c027dd4-3851-11ee-80c0-000c2961d091;Полтава-Хмельницького Богдана 21 ФОТОSHOP;Полтава бул. Хмельницького Богдана, 21;74dc4d38-749b-11df-b112-00215aee3ebe
6d57e108-b70c-11eb-80d4-000c29800ae7;Полтава-Чорновола В'ячеслава 7 GROШИК;Полтава вул. Чорновола В'ячеслава, 7;74dc4d38-749b-11df-b112-00215aee3ebe
6c905948-5ba7-11ee-80c0-000c2961d091;Полтава-Чорновола В’ячеслава 12 JoinUP;Полтава вул. Чорновола В’ячеслава ( Куйбишева ), 12;74dc4d38-749b-11df-b112-00215aee3ebe
edc84037-e062-11ee-80c4-000c2961d091;Полтава-Чураївни 3/2 зоомагазин;Полтава вул. Чураївни, 3/2;74dc4d38-749b-11df-b112-00215aee3ebe
7d47e96b-0a8b-11e9-80d9-1c98ec135261;Полтава-Шевченка 102 Сервісний Центр;Полтава вул. Шевченка, 102;74dc4d38-749b-11df-b112-00215aee3ebe
f7e74983-bcd6-11ed-80ed-000c29800ae7;Полтава-Шевченка 31 Lifecell;Полтава вул. Шевченка, 31;74dc4d38-749b-11df-b112-00215aee3ebe
7201915b-0a8d-11e8-80cd-1c98ec135261;Полтава-Щепотьєва 7А ДоброБУД;Полтава бул. Щепотьєва, 7А;74dc4d38-749b-11df-b112-00215aee3ebe
52b07599-69ba-11ee-80c1-000c2961d091;Поляна-Гірська 16А;Поляна вул. Гірська, 16А;74dc4d5f-749b-11df-b112-00215aee3ebe
de29c834-69ba-11ee-80c1-000c2961d091;Поляна-Курортна 8;Поляна вул. Курортна, 8;74dc4d5f-749b-11df-b112-00215aee3ebe
5715a4b5-3061-11e9-80dd-1c98ec135261;Поляна-Хирівська 1А;Поляна вул. Хирівська, 1А;74dc4d5e-749b-11df-b112-00215aee3ebe
37a43b62-f6b6-11eb-80d5-000c29800ae7;Попільня-Героїв Майдану 19;Попільня вул. Героїв Майдану, 19;74dc4dd6-749b-11df-b112-00215aee3ebe
db70e35b-f122-11eb-80d5-000c29800ae7;Почаїв-Радивилівська 1;Почаїв вул. Радивилівська, 1;74dc4e4d-749b-11df-b112-00215aee3ebe
980b8fe5-9935-11eb-80d3-000c29800ae7;Почаїв-Шевченка 1;Почаїв вул. Шевченка, 1;74dc4e4d-749b-11df-b112-00215aee3ebe
54060839-b91c-11e4-b90b-003048d2b473;Поштомат №1004 ;Київ вул. Зеленого Клину ( Уссурійська ), 8;5cb61671-749b-11df-b112-00215aee3ebe
5406083c-b91c-11e4-b90b-003048d2b473;Поштомат №1005 ;Київ вул. Метрологічна, 25;5cb61671-749b-11df-b112-00215aee3ebe
5406083e-b91c-11e4-b90b-003048d2b473;Поштомат №1006 ;Новосілки вул. Миру, 2;325c57ec-ddd2-11df-9197-00215aee3ebe
54060840-b91c-11e4-b90b-003048d2b473;Поштомат №1007;Київ вул. Метрологічна, 11А;5cb61671-749b-11df-b112-00215aee3ebe
54060842-b91c-11e4-b90b-003048d2b473;Поштомат №1008;Київ вул. Куліша Пантелеймона ( Челябінська ), 15-А;5cb61671-749b-11df-b112-00215aee3ebe
54060844-b91c-11e4-b90b-003048d2b473;Поштомат №1009 ;Київ вул. Андрія Аболмасова ( Панельна ), 3;5cb61671-749b-11df-b112-00215aee3ebe
54060849-b91c-11e4-b90b-003048d2b473;Поштомат №1011 ;Київ вул. Корольова Академіка, 8;5cb61671-749b-11df-b112-00215aee3ebe
5406084b-b91c-11e4-b90b-003048d2b473;Поштомат №1012 ;Київ вул. Лаврухіна Миколи, 12;5cb61671-749b-11df-b112-00215aee3ebe
5406084f-b91c-11e4-b90b-003048d2b473;Поштомат №1014 ;Погреби вул. Єдності /Кірова/, 61;74dc4c75-749b-11df-b112-00215aee3ebe
54060855-b91c-11e4-b90b-003048d2b473;Поштомат №1017;Київ вул. Борщагівська, 128;5cb61671-749b-11df-b112-00215aee3ebe
54060857-b91c-11e4-b90b-003048d2b473;Поштомат №1018 ;Київ вул. Довженка Олександра, 1;5cb61671-749b-11df-b112-00215aee3ebe
54060859-b91c-11e4-b90b-003048d2b473;Поштомат №1019 ;Київ вул. Хмельницького Богдана, 10;5cb61671-749b-11df-b112-00215aee3ebe
5406085b-b91c-11e4-b90b-003048d2b473;Поштомат №1020 ;Білогородка вул. Володимирська, 35/1;44ac83cd-749b-11df-b112-00215aee3ebe
5406085d-b91c-11e4-b90b-003048d2b473;Поштомат №1021 ;Ірпінь вул. Сковороди, 11;56bdd92e-749b-11df-b112-00215aee3ebe
54060865-b91c-11e4-b90b-003048d2b473;Поштомат №1025 ;Київ вул. Григоровича-Барського, 5;5cb61671-749b-11df-b112-00215aee3ebe
54060867-b91c-11e4-b90b-003048d2b473;Поштомат №1026 ;Київ вул. Парково-Сирецька, 1;5cb61671-749b-11df-b112-00215aee3ebe
5406086a-b91c-11e4-b90b-003048d2b473;Поштомат №1027 ;Київ вул. Героїв Оборони, 10А;5cb61671-749b-11df-b112-00215aee3ebe
5406086e-b91c-11e4-b90b-003048d2b473;Поштомат №1028 ;Київ вул. Оноре де Бальзака, 80;5cb61671-749b-11df-b112-00215aee3ebe
51965f9a-b91e-11e4-b90b-003048d2b473;Поштомат №1032 ;Київ вул. Сікорського Ігоря Авіаконструктора, 1А;5cb61671-749b-11df-b112-00215aee3ebe
51965f9d-b91e-11e4-b90b-003048d2b473;Поштомат №1033 ;Бориспіль вул. Головатого, 64;44ac85c0-749b-11df-b112-00215aee3ebe
51965fac-b91e-11e4-b90b-003048d2b473;Поштомат №1038 ;Київ вул. Вишняківська, 12;5cb61671-749b-11df-b112-00215aee3ebe
51965fba-b91e-11e4-b90b-003048d2b473;Поштомат №1043 ;Київ вул. Лифаря Сержа (Сабурова), 1;5cb61671-749b-11df-b112-00215aee3ebe
51965fbe-b91e-11e4-b90b-003048d2b473;Поштомат №1045 ;Київ вул. Закревського Миколи, 12В;5cb61671-749b-11df-b112-00215aee3ebe
51965fc0-b91e-11e4-b90b-003048d2b473;Поштомат №1046 ;Київ Просп. Правди, 64;5cb61671-749b-11df-b112-00215aee3ebe
51965fc2-b91e-11e4-b90b-003048d2b473;Поштомат №1047 ;Київ вул. Юлії Здановської, 50/2;5cb61671-749b-11df-b112-00215aee3ebe
51965fc6-b91e-11e4-b90b-003048d2b473;Поштомат №1049 ;Київ Просп. Соборності, 5Д;5cb61671-749b-11df-b112-00215aee3ebe
51965fca-b91e-11e4-b90b-003048d2b473;Поштомат №1051 ;Бровари вул. Шевченка, 10В;0f3cdf53-efbc-11de-9277-00215aee3ebe
51965fd2-b91e-11e4-b90b-003048d2b473;Поштомат №1055 ;Київ вул. Оноре де Бальзака, 94;5cb61671-749b-11df-b112-00215aee3ebe
51965fd4-b91e-11e4-b90b-003048d2b473;Поштомат №1056 ;Київ вул. Ореста Левицького ( Курчатова Академіка, 19А;5cb61671-749b-11df-b112-00215aee3ebe
51965fda-b91e-11e4-b90b-003048d2b473;Поштомат №1059 ;Київ вул. Малишка Андрія, 15/1;5cb61671-749b-11df-b112-00215aee3ebe
51965fe0-b91e-11e4-b90b-003048d2b473;Поштомат №1062 ;Бровари вул. Броварської сотні (Чкалова), 12;0f3cdf53-efbc-11de-9277-00215aee3ebe
51965fe2-b91e-11e4-b90b-003048d2b473;Поштомат №1063 ;Київ вул. Драгоманова, 40Є;5cb61671-749b-11df-b112-00215aee3ebe
2ee64781-b920-11e4-b90b-003048d2b473;Поштомат №1067;Київ вул. Бажана, 30;5cb61671-749b-11df-b112-00215aee3ebe
2ee64783-b920-11e4-b90b-003048d2b473;Поштомат №1068 ;Шевченкове пров. Ювілейний, 1;8d17ed1c-749b-11df-b112-00215aee3ebe
2ee64785-b920-11e4-b90b-003048d2b473;Поштомат №1069 ;Київ вул. Хмельницького Богдана, 31/27;5cb61671-749b-11df-b112-00215aee3ebe
2ee64793-b920-11e4-b90b-003048d2b473;Поштомат №1076 ;Вишневе вул. Марії Приймаченко ( Першотравнева ), 7;73e94c1f-ddc1-11df-9197-00215aee3ebe
2ee6479b-b920-11e4-b90b-003048d2b473;Поштомат №1080 ;Буча вул. Склозаводська, 10Б;44ac8811-749b-11df-b112-00215aee3ebe
2ee647a3-b920-11e4-b90b-003048d2b473;Поштомат №1084;Мила вул. Комарова Космонавта, 44В;68cf280d-749b-11df-b112-00215aee3ebe
2ee647af-b920-11e4-b90b-003048d2b473;Поштомат №1090;Київ вул. Архипенка Олександра ( Мате Залки ), 1/12;5cb61671-749b-11df-b112-00215aee3ebe
2ee647b1-b920-11e4-b90b-003048d2b473;Поштомат №1091 ;Київ вул. Оноре де Бальзака, 42/20;5cb61671-749b-11df-b112-00215aee3ebe
2ee647b3-b920-11e4-b90b-003048d2b473;Поштомат №1092 ;Київ Просп. Броварський, 51;5cb61671-749b-11df-b112-00215aee3ebe
2ee647b5-b920-11e4-b90b-003048d2b473;Поштомат №1093 ;Київ шосе Харківське, 8;5cb61671-749b-11df-b112-00215aee3ebe
21218cd0-bc30-11e4-b90b-003048d2b473;Поштомат №1095;Київ бул. Верховної Ради, 27;5cb61671-749b-11df-b112-00215aee3ebe
21218cd2-bc30-11e4-b90b-003048d2b473;Поштомат №1096 ;Вишневе вул. Машинобудівників, 11А;73e94c1f-ddc1-11df-9197-00215aee3ebe
21218cd4-bc30-11e4-b90b-003048d2b473;Поштомат №1097;Софіївська Борщагівка Просп. Героїв Небесної Сотні, 38;80f5570b-749b-11df-b112-00215aee3ebe
21218cd6-bc30-11e4-b90b-003048d2b473;Поштомат №1098 ;Бровари вул. Москаленка Сергія (Красовського), 39;0f3cdf53-efbc-11de-9277-00215aee3ebe
21218cd8-bc30-11e4-b90b-003048d2b473;Поштомат №1099;Бровари вул. Київська, 4;0f3cdf53-efbc-11de-9277-00215aee3ebe
21218cda-bc30-11e4-b90b-003048d2b473;Поштомат №1100;Київ вул. Мрії (Туполєва Академіка), 16;5cb61671-749b-11df-b112-00215aee3ebe
21218ce2-bc30-11e4-b90b-003048d2b473;Поштомат №1104;Вишневе вул. Чорновола В'ячеслава, 46В;73e94c1f-ddc1-11df-9197-00215aee3ebe
21218cf0-bc30-11e4-b90b-003048d2b473;Поштомат №1111 ;Київ вул. Левка Лук'яненка ( Тимошенка Маршала ), 21;5cb61671-749b-11df-b112-00215aee3ebe
21218cf2-bc30-11e4-b90b-003048d2b473;Поштомат №1112;Петропавлівська Борщагівка вул. Соборна (Леніна), 16;74dc49d5-749b-11df-b112-00215aee3ebe
21218cf6-bc30-11e4-b90b-003048d2b473;Поштомат №1114;Київ вул. Володимира Покотила, 7/2;5cb61671-749b-11df-b112-00215aee3ebe
21218cf8-bc30-11e4-b90b-003048d2b473;Поштомат №1115;Київ вул. Цілинна, 2а;5cb61671-749b-11df-b112-00215aee3ebe
21218cfb-bc30-11e4-b90b-003048d2b473;Поштомат №1116;Київ бул. Воскресенський ( Перова ), 23б;5cb61671-749b-11df-b112-00215aee3ebe
21218cfd-bc30-11e4-b90b-003048d2b473;Поштомат №1117;Київ вул. Рональда Рейгана ( Теодора Драйзера ), 24;5cb61671-749b-11df-b112-00215aee3ebe
21218cff-bc30-11e4-b90b-003048d2b473;Поштомат №1118;Київ Просп. Оболонський (проспект), 51;5cb61671-749b-11df-b112-00215aee3ebe
21218d03-bc30-11e4-b90b-003048d2b473;Поштомат №1119;Київ вул. Білецького Академіка, 3;5cb61671-749b-11df-b112-00215aee3ebe
21218d09-bc30-11e4-b90b-003048d2b473;Поштомат №1122;Київ Просп. Науки, 58В;5cb61671-749b-11df-b112-00215aee3ebe
271c3286-bc30-11e4-b90b-003048d2b473;Поштомат №1124;Київ вул. Академіка Єфремова, 23;5cb61671-749b-11df-b112-00215aee3ebe
271c329c-bc30-11e4-b90b-003048d2b473;Поштомат №1131;Петропавлівська Борщагівка вул. Княгині Ольги, 67;74dc49d5-749b-11df-b112-00215aee3ebe
271c329f-bc30-11e4-b90b-003048d2b473;Поштомат №1132 ;Київ бул. Гавела Вацлава  (Лепсе Івана), 83;5cb61671-749b-11df-b112-00215aee3ebe
271c32a1-bc30-11e4-b90b-003048d2b473;Поштомат №1133 ;Київ Просп. Берестейський (к.Перемоги (Проспект)), 60;5cb61671-749b-11df-b112-00215aee3ebe
271c32a3-bc30-11e4-b90b-003048d2b473;Поштомат №1134;Київ вул. Зарічна, 6;5cb61671-749b-11df-b112-00215aee3ebe
271c32a5-bc30-11e4-b90b-003048d2b473;Поштомат №1135 ;Київ вул. Кирила Осьмака (Бортничі), 1Б;5cb61671-749b-11df-b112-00215aee3ebe
4d0eaa31-e837-11e4-b9c8-003048d2b473;Поштомат №1792 до 15 кг.;Бориспіль вул. Київський Шлях, 76д;44ac85c0-749b-11df-b112-00215aee3ebe
e9bceea4-6bde-11e4-81a4-003048d2b473;Поштомат №462;Зимна Вода вул. Яворівська, 22;56bdd6dd-749b-11df-b112-00215aee3ebe
0e3483d8-6be2-11e4-81a4-003048d2b473;Поштомат №463 ;Святопетрівське (Петрівське) бул. Українки Лесі, 12;74dc4991-749b-11df-b112-00215aee3ebe
e9bcee9a-6bde-11e4-81a4-003048d2b473;Поштомат №464;Ірпінь вул. Єрошенка (Островського Миколи), 14;56bdd92e-749b-11df-b112-00215aee3ebe
e9bcee91-6bde-11e4-81a4-003048d2b473;Поштомат №465;Київ вул. Щусєва Академіка, 34/1;5cb61671-749b-11df-b112-00215aee3ebe
e9bcee88-6bde-11e4-81a4-003048d2b473;Поштомат №466;Київ вул. Коцюбинського Михайла, 1;5cb61671-749b-11df-b112-00215aee3ebe
e9bcee82-6bde-11e4-81a4-003048d2b473;Поштомат №468;Київ вул. Солом'янська, 33;5cb61671-749b-11df-b112-00215aee3ebe
0726cb56-89c4-11e4-b90b-003048d2b473;Поштомат №469;Київ Просп. Червоної Калини (Маяковського В), 26;5cb61671-749b-11df-b112-00215aee3ebe
0726cb51-89c4-11e4-b90b-003048d2b473;Поштомат №471 ;Київ Просп. Глушкова Академіка, 9є;5cb61671-749b-11df-b112-00215aee3ebe
0726cb4d-89c4-11e4-b90b-003048d2b473;Поштомат №472;Київ Просп. Глушкова Академіка, 36а;5cb61671-749b-11df-b112-00215aee3ebe
0726cb49-89c4-11e4-b90b-003048d2b473;Поштомат №473;Київ вул. Сверстюка Євгена (Раскової Марини), 23;5cb61671-749b-11df-b112-00215aee3ebe
0726cb44-89c4-11e4-b90b-003048d2b473;Поштомат №475;Бровари вул. Героїв Упа (Кирпоноса), 7а;0f3cdf53-efbc-11de-9277-00215aee3ebe
0726cb3e-89c4-11e4-b90b-003048d2b473;Поштомат №476 ;Київ вул. Новомостицька, 2;5cb61671-749b-11df-b112-00215aee3ebe
0726cb3a-89c4-11e4-b90b-003048d2b473;Поштомат №478 ;Львів Просп. Червоної Калини, 35;62c3d54a-749b-11df-b112-00215aee3ebe
0726cb38-89c4-11e4-b90b-003048d2b473;Поштомат №479 ;Київ Просп. Відрадний, 12;5cb61671-749b-11df-b112-00215aee3ebe
d14cf17e-4269-11e4-81a4-003048d2b473;Поштомат №480;Київ шосе Харківське, 58;5cb61671-749b-11df-b112-00215aee3ebe
0726cb34-89c4-11e4-b90b-003048d2b473;Поштомат №481 ;Проліски вул. Паркова, 3;7ad94e4d-749b-11df-b112-00215aee3ebe
d0ef7334-89c1-11e4-b90b-003048d2b473;Поштомат №483 ;Білогородка вул. Володимирська, 35;44ac83cd-749b-11df-b112-00215aee3ebe
caf4cd92-89c1-11e4-b90b-003048d2b473;Поштомат №485;Київ вул. Ломоносова, 48;5cb61671-749b-11df-b112-00215aee3ebe
caf4cd90-89c1-11e4-b90b-003048d2b473;Поштомат №486 ;Київ вул. Григоровича-Барського, 1;5cb61671-749b-11df-b112-00215aee3ebe
caf4cd8e-89c1-11e4-b90b-003048d2b473;Поштомат №488;Київ вул. Ентузіастів, 5;5cb61671-749b-11df-b112-00215aee3ebe
caf4cd8a-89c1-11e4-b90b-003048d2b473;Поштомат №490 ;Львів вул. Зелена, 147;62c3d54a-749b-11df-b112-00215aee3ebe
caf4cd88-89c1-11e4-b90b-003048d2b473;Поштомат №491;Гора вул. Центральна, 20а;50c58f99-749b-11df-b112-00215aee3ebe
a9662ca8-6c09-11e4-81a4-003048d2b473;Поштомат №492 ;Київ вул. Кубанської України ( Жукова Маршала ), 31а;5cb61671-749b-11df-b112-00215aee3ebe
bda21f85-6c0c-11e4-81a4-003048d2b473;Поштомат №494;Київ вул. Порика Василя, 5;5cb61671-749b-11df-b112-00215aee3ebe
0e3483dc-6be2-11e4-81a4-003048d2b473;Поштомат №495 ;Київ вул. Макіївська, 7;5cb61671-749b-11df-b112-00215aee3ebe
c90f6876-6c07-11e4-81a4-003048d2b473;Поштомат №496;Київ вул. Васильківська (Голосієво), 11/11;5cb61671-749b-11df-b112-00215aee3ebe
8e0d5c08-6c07-11e4-81a4-003048d2b473;Поштомат №497 ;Київ вул. Каховська, 62А;5cb61671-749b-11df-b112-00215aee3ebe
c90f6874-6c07-11e4-81a4-003048d2b473;Поштомат №499;Ірпінь вул. Літературна ( Чехова ), 11;56bdd92e-749b-11df-b112-00215aee3ebe
0e3483de-6be2-11e4-81a4-003048d2b473;Поштомат №500 ;Київ вул. Митрополита Андрея Шептицького, 22;5cb61671-749b-11df-b112-00215aee3ebe
d793e336-43ec-11e4-81a4-003048d2b473;Поштомат №501 ;Київ вул. Полярна, 8;5cb61671-749b-11df-b112-00215aee3ebe
bda21f83-6c0c-11e4-81a4-003048d2b473;Поштомат №503;Київ вул. Руденко Лариси, 6а;5cb61671-749b-11df-b112-00215aee3ebe
8e0d5c06-6c07-11e4-81a4-003048d2b473;Поштомат №504;Київ вул. Зодчих, 34;5cb61671-749b-11df-b112-00215aee3ebe
054a6fc2-6c09-11e4-81a4-003048d2b473;Поштомат №505 ;Київ вул. Петра Запорожця, 28;5cb61671-749b-11df-b112-00215aee3ebe
8e0d5c04-6c07-11e4-81a4-003048d2b473;Поштомат №506 ;Київ бул. Кольцова, 17в;5cb61671-749b-11df-b112-00215aee3ebe
a1e02a38-6be2-11e4-81a4-003048d2b473;Поштомат №507;Бровари вул. Володимира Великого (Енгельса), 2;0f3cdf53-efbc-11de-9277-00215aee3ebe
a1e02a36-6be2-11e4-81a4-003048d2b473;Поштомат №508;Київ вул. Закревського Миколи, 101А;5cb61671-749b-11df-b112-00215aee3ebe
a1e02a34-6be2-11e4-81a4-003048d2b473;Поштомат №509 ;Київ Просп. Правди, 3;5cb61671-749b-11df-b112-00215aee3ebe
9be7e6e5-6be2-11e4-81a4-003048d2b473;Поштомат №510;Київ Просп. Лісовий, 23;5cb61671-749b-11df-b112-00215aee3ebe
9be7e6e3-6be2-11e4-81a4-003048d2b473;Поштомат №511;Святопетрівське (Петрівське) вул. Шевченка Тараса, 6а;74dc4991-749b-11df-b112-00215aee3ebe
3ddc2e94-44b8-11e4-81a4-003048d2b473;Поштомат №512 ;Київ вул. Ракетна, 24Б;5cb61671-749b-11df-b112-00215aee3ebe
0e3483e2-6be2-11e4-81a4-003048d2b473;Поштомат №513 ;Вишневе вул. Марії Приймаченко ( Першотравнева ), 21/25;73e94c1f-ddc1-11df-9197-00215aee3ebe
0e3483e0-6be2-11e4-81a4-003048d2b473;Поштомат №514 ;Київ Просп. Соборності, 10/1;5cb61671-749b-11df-b112-00215aee3ebe
d793e338-43ec-11e4-81a4-003048d2b473;Поштомат №515 ;Безуглівка вул. Центральна, 62;44ac81f2-749b-11df-b112-00215aee3ebe
e9bcee79-6bde-11e4-81a4-003048d2b473;Поштомат №516;Київ вул. Княжий Затон, 4;5cb61671-749b-11df-b112-00215aee3ebe
d18b50a0-6bdf-11e4-81a4-003048d2b473;Поштомат №517;Київ вул. Закревського Миколи, 61/2;5cb61671-749b-11df-b112-00215aee3ebe
c39cc53b-6c0c-11e4-81a4-003048d2b473;Поштомат №518;Київ вул. Азербайджанська, 6;5cb61671-749b-11df-b112-00215aee3ebe
054a6fc8-6c09-11e4-81a4-003048d2b473;Поштомат №519 ;Київ вул. Білицька, 72г;5cb61671-749b-11df-b112-00215aee3ebe
c39cc539-6c0c-11e4-81a4-003048d2b473;Поштомат №520 ;Київ вул. Митрополита Андрея Шептицького, 4а;5cb61671-749b-11df-b112-00215aee3ebe
c39cc537-6c0c-11e4-81a4-003048d2b473;Поштомат №521 ;Київ вул. Березняківська, 8;5cb61671-749b-11df-b112-00215aee3ebe
8afd20b4-9b41-11e4-b90b-003048d2b473;Поштомат №710;Київ Просп. Курбаса Леся, 19А;5cb61671-749b-11df-b112-00215aee3ebe
8afd20b6-9b41-11e4-b90b-003048d2b473;Поштомат №711;Київ вул. Чистяківська, 2;5cb61671-749b-11df-b112-00215aee3ebe
8afd20ba-9b41-11e4-b90b-003048d2b473;Поштомат №713 ;Київ вул. Татарська, 6;5cb61671-749b-11df-b112-00215aee3ebe
8afd20bc-9b41-11e4-b90b-003048d2b473;Поштомат №714;Бровари вул. Героїв Крут, 14;0f3cdf53-efbc-11de-9277-00215aee3ebe
8afd20be-9b41-11e4-b90b-003048d2b473;Поштомат №715;Київ Просп. Свободи, 26;5cb61671-749b-11df-b112-00215aee3ebe
8afd20c0-9b41-11e4-b90b-003048d2b473;Поштомат №716;Київ вул. Героїв Севастополя, 44/10;5cb61671-749b-11df-b112-00215aee3ebe
8afd20c3-9b41-11e4-b90b-003048d2b473;Поштомат №717;Київ вул. Княжий Затон, 21;5cb61671-749b-11df-b112-00215aee3ebe
8afd20c8-9b41-11e4-b90b-003048d2b473;Поштомат №719;Київ вул. Кирилівська (Фрунзе), 122/1;5cb61671-749b-11df-b112-00215aee3ebe
fd1cf0b6-9b41-11e4-b90b-003048d2b473;Поштомат №721;Тарасівка вул. Шкільна, 6;80f55b7d-749b-11df-b112-00215aee3ebe
fd1cf0ba-9b41-11e4-b90b-003048d2b473;Поштомат №723;Буча пров. Тихого, 1;44ac8811-749b-11df-b112-00215aee3ebe
fd1cf0be-9b41-11e4-b90b-003048d2b473;Поштомат №725;Київ бул. Гавела Вацлава  (Лепсе Івана), 18;5cb61671-749b-11df-b112-00215aee3ebe
fd1cf0c2-9b41-11e4-b90b-003048d2b473;Поштомат №727;Київ вул. Почайнинська, 57-59;5cb61671-749b-11df-b112-00215aee3ebe
fd1cf0c4-9b41-11e4-b90b-003048d2b473;Поштомат №728;Київ вул. Воскресенська, 14;5cb61671-749b-11df-b112-00215aee3ebe
fd1cf0c6-9b41-11e4-b90b-003048d2b473;Поштомат №729;Київ вул. Старонаводницька, 8Б;5cb61671-749b-11df-b112-00215aee3ebe
fd1cf0cc-9b41-11e4-b90b-003048d2b473;Поштомат №731 ;Київ вул. Автозаводська, 27а;5cb61671-749b-11df-b112-00215aee3ebe
fd1cf0ce-9b41-11e4-b90b-003048d2b473;Поштомат №732;Київ вул. Антоновича (Горького), 115;5cb61671-749b-11df-b112-00215aee3ebe
bc41f82a-9b42-11e4-b90b-003048d2b473;Поштомат №733 ;Київ Просп. Оболонський (проспект), 52б;5cb61671-749b-11df-b112-00215aee3ebe
bc41f82c-9b42-11e4-b90b-003048d2b473;Поштомат №734 ;Київ вул. Голосіївська, 13;5cb61671-749b-11df-b112-00215aee3ebe
bc41f82e-9b42-11e4-b90b-003048d2b473;Поштомат №735 ;Київ вул. Червоноткацька, 43;5cb61671-749b-11df-b112-00215aee3ebe
bc41f830-9b42-11e4-b90b-003048d2b473;Поштомат №736 ;Вишневе вул. Паркова ( Ломоносова ), 36;73e94c1f-ddc1-11df-9197-00215aee3ebe
bc41f832-9b42-11e4-b90b-003048d2b473;Поштомат №737;Київ вул. Вершигори Петра, 1;5cb61671-749b-11df-b112-00215aee3ebe
bc41f838-9b42-11e4-b90b-003048d2b473;Поштомат №740 ;Львів вул. Стрийська, 45;62c3d54a-749b-11df-b112-00215aee3ebe
bc41f83a-9b42-11e4-b90b-003048d2b473;Поштомат №741 ;Київ вул. Золотоустівська, 44/22;5cb61671-749b-11df-b112-00215aee3ebe
bc41f83c-9b42-11e4-b90b-003048d2b473;Поштомат №742 ;Київ вул. Березняківська, 16;5cb61671-749b-11df-b112-00215aee3ebe
bc41f83e-9b42-11e4-b90b-003048d2b473;Поштомат №743 ;Київ вул. Межова, 18;5cb61671-749b-11df-b112-00215aee3ebe
bc41f840-9b42-11e4-b90b-003048d2b473;Поштомат №744 ;Київ Просп. Бажана, 3;5cb61671-749b-11df-b112-00215aee3ebe
bc41f842-9b42-11e4-b90b-003048d2b473;Поштомат №745 ;Київ вул. Тираспольська (Сальського / Котовського), 58;5cb61671-749b-11df-b112-00215aee3ebe
a6e0e07b-9b44-11e4-b90b-003048d2b473;Поштомат №747;Київ вул. Парково-Сирецька, 6д;5cb61671-749b-11df-b112-00215aee3ebe
a6e0e07d-9b44-11e4-b90b-003048d2b473;Поштомат №748 ;Київ вул. Межова, 23б;5cb61671-749b-11df-b112-00215aee3ebe
a6e0e07f-9b44-11e4-b90b-003048d2b473;Поштомат №749 ;Київ Просп. Корольова Академіка, 3;5cb61671-749b-11df-b112-00215aee3ebe
a6e0e081-9b44-11e4-b90b-003048d2b473;Поштомат №750 ;Глеваха вул. Вокзальна, 2а;50c58e38-749b-11df-b112-00215aee3ebe
a6e0e085-9b44-11e4-b90b-003048d2b473;Поштомат №752 ;Київ вул. Деревлянська ( Якіра ), 12/42;5cb61671-749b-11df-b112-00215aee3ebe
a6e0e087-9b44-11e4-b90b-003048d2b473;Поштомат №753;Київ вул. Оноре де Бальзака, 56а;5cb61671-749b-11df-b112-00215aee3ebe
a6e0e08b-9b44-11e4-b90b-003048d2b473;Поштомат №755 ;Київ вул. Срібнокільська, 12;5cb61671-749b-11df-b112-00215aee3ebe
a6e0e08d-9b44-11e4-b90b-003048d2b473;Поштомат №756 ;Київ вул. Ружинська, 26/41;5cb61671-749b-11df-b112-00215aee3ebe
a6e0e08f-9b44-11e4-b90b-003048d2b473;Поштомат №757 ;Київ вул. Олександрівська, 1;5cb61671-749b-11df-b112-00215aee3ebe
4e1106ba-9bdb-11e4-b90b-003048d2b473;Поштомат №758;Київ вул. Ревуцького, 5;5cb61671-749b-11df-b112-00215aee3ebe
4e1106bc-9bdb-11e4-b90b-003048d2b473;Поштомат №759;Київ вул. Гоголівська, 27;5cb61671-749b-11df-b112-00215aee3ebe
4e1106be-9bdb-11e4-b90b-003048d2b473;Поштомат №760;Київ вул. Котельникова Михайла, 26;5cb61671-749b-11df-b112-00215aee3ebe
17ceb598-a792-11e4-b90b-003048d2b473;Поштомат №920 ;Київ Просп. Володимира Івасюка / Героїв Сталінграда, 39В;5cb61671-749b-11df-b112-00215aee3ebe
17ceb5a2-a792-11e4-b90b-003048d2b473;Поштомат №923 ;Вишневе вул. Вишні Остапа, 3;73e94c1f-ddc1-11df-9197-00215aee3ebe
17ceb5a6-a792-11e4-b90b-003048d2b473;Поштомат №925 ;Вишневе вул. Вітянська, 1;73e94c1f-ddc1-11df-9197-00215aee3ebe
17ceb5ba-a792-11e4-b90b-003048d2b473;Поштомат №933 ;Вишневе вул. Київська, 2Л;73e94c1f-ddc1-11df-9197-00215aee3ebe
17ceb5be-a792-11e4-b90b-003048d2b473;Поштомат №934 ;Київ вул. Гарматна, 38Б;5cb61671-749b-11df-b112-00215aee3ebe
17ceb5c1-a792-11e4-b90b-003048d2b473;Поштомат №935 ;Київ вул. Закревського Миколи, 95В;5cb61671-749b-11df-b112-00215aee3ebe
1dce1fc8-a792-11e4-b90b-003048d2b473;Поштомат №941 ;Київ вул. Гетьмана Павла Полуботка (к.Попудренка), 7Б,7В;5cb61671-749b-11df-b112-00215aee3ebe
a844ec51-a799-11e4-b90b-003048d2b473;Поштомат №947 ;Київ Просп. Правди, 94;5cb61671-749b-11df-b112-00215aee3ebe
232453ec-b919-11e4-b90b-003048d2b473;Поштомат №974 ;Київ вул. Борщагівська, 195/43;5cb61671-749b-11df-b112-00215aee3ebe
96328f20-b919-11e4-b90b-003048d2b473;Поштомат №975 ;Боярка вул. Симона Петлюри (Маяковського), 2;44ac8642-749b-11df-b112-00215aee3ebe
96328f22-b919-11e4-b90b-003048d2b473;Поштомат №976 ;Київ вул. Вернадського Академіка, 79;5cb61671-749b-11df-b112-00215aee3ebe
96328f24-b919-11e4-b90b-003048d2b473;Поштомат №977 ;Київ бул. Кольцова, 7Б;5cb61671-749b-11df-b112-00215aee3ebe
96328f26-b919-11e4-b90b-003048d2b473;Поштомат №978 ;Київ вул. Волинська, 9Б;5cb61671-749b-11df-b112-00215aee3ebe
96328f28-b919-11e4-b90b-003048d2b473;Поштомат №979 ;Київ вул. Булаховського Академіка, 3А;5cb61671-749b-11df-b112-00215aee3ebe
96328f2a-b919-11e4-b90b-003048d2b473;Поштомат №980 ;Київ пров. Ізяславський /Ватутіна(Жуляни), 46-49;5cb61671-749b-11df-b112-00215aee3ebe
96328f2e-b919-11e4-b90b-003048d2b473;Поштомат №982 ;Київ вул. Пулюя Івана, 5;5cb61671-749b-11df-b112-00215aee3ebe
96328f32-b919-11e4-b90b-003048d2b473;Поштомат №984 ;Новосілки вул. Васильківська, 21;325c57ec-ddd2-11df-9197-00215aee3ebe
96328f38-b919-11e4-b90b-003048d2b473;Поштомат №987 ;Київ бул. Лесі Українки ( Бульвар ), 28;5cb61671-749b-11df-b112-00215aee3ebe
96328f3a-b919-11e4-b90b-003048d2b473;Поштомат №988 ;Київ вул. Північна, 46;5cb61671-749b-11df-b112-00215aee3ebe
96328f3c-b919-11e4-b90b-003048d2b473;Поштомат №989 ;Київ вул. Миропільська, 11;5cb61671-749b-11df-b112-00215aee3ebe
96328f3e-b919-11e4-b90b-003048d2b473;Поштомат №990 ;Київ вул. Лук'янівська, 1;5cb61671-749b-11df-b112-00215aee3ebe
96328f40-b919-11e4-b90b-003048d2b473;Поштомат №991 ;Київ бул. Гавела Вацлава  (Лепсе Івана), 45;5cb61671-749b-11df-b112-00215aee3ebe
96328f46-b919-11e4-b90b-003048d2b473;Поштомат №994 ;Крюківщина вул. Жулянська, 1А;62c3cebf-749b-11df-b112-00215aee3ebe
4df5ed50-b91c-11e4-b90b-003048d2b473;Поштомат №996 ;Київ вул. Ялтинська, 15;5cb61671-749b-11df-b112-00215aee3ebe
5406082d-b91c-11e4-b90b-003048d2b473;Поштомат №998 ;Київ вул. Преображенська (Клименка Івана), 28;5cb61671-749b-11df-b112-00215aee3ebe
9464467d-e83c-11ee-80c5-000c2961d091;Приборжавське-Центральна 145;Приборжавське вул. Центральна, 145;74dc4e8e-749b-11df-b112-00215aee3ebe
90fd95d2-c26c-11ed-80ed-000c29800ae7;Прилуки-Густинська 45;Прилуки вул. Густинська, 45;74dc4efb-749b-11df-b112-00215aee3ebe
604de7cf-7644-11ee-80c1-000c2961d091;Прилуки-Київська 138;Прилуки вул. Київська, 138;74dc4efb-749b-11df-b112-00215aee3ebe
f0ba6e50-bc16-11ed-80ed-000c29800ae7;Прилуки-Київська 156;Прилуки вул. Київська, 156;74dc4efb-749b-11df-b112-00215aee3ebe
8c9869b3-9b68-11ee-80c1-000c2961d091;Прилуки-Коптєва Юрія 3А;Прилуки вул. Коптєва Юрія, 3А;74dc4efb-749b-11df-b112-00215aee3ebe
ef7acda3-3202-11ee-80bd-000c2961d091;Прилуки-Незалежності 63Б;Прилуки вул. Незалежності, 63Б;74dc4efb-749b-11df-b112-00215aee3ebe
009df321-3c06-11ee-80c0-000c2961d091;Прилуки-Незалежності 65/14;Прилуки вул. Незалежності, 65/14;74dc4efb-749b-11df-b112-00215aee3ebe
9838dbaf-b36a-11e9-80e4-1c98ec135261;Прилуцьке-Богуна Івана 1А ОККО;Прилуцьке вул. Богуна Івана, 1А;74dc4efd-749b-11df-b112-00215aee3ebe
ae7245ee-d42d-11eb-80d5-000c29800ae7;Пулини-Незалежності 16;Пулини вул. Незалежності, 16;871626ee-749b-11df-b112-00215aee3ebe
13ee4ccf-cf00-11ee-80c4-000c2961d091;Пустомити-Грушевського Михайла 25;Пустомити вул. Грушевського Михайла, 25;7ad94ed0-749b-11df-b112-00215aee3ebe
5524b6d0-9f33-11ee-80c4-000c2961d091;Путивль-Путивльського Іоанна 61;Путивль Просп. Путивльського Іоанна, 61;7ad94ed5-749b-11df-b112-00215aee3ebe
6fb3fdb8-adf2-11ed-80ec-000c29800ae7;Рава-Руська-1-го Листопада 10А;Рава-Руська вул. 1-го Листопада, 10А;7ad94f31-749b-11df-b112-00215aee3ebe
0603185f-209e-11e9-80dc-1c98ec135261;Рава-Руська-Львівська 146 А;Рава-Руська вул. Львівська, 146А;7ad94f31-749b-11df-b112-00215aee3ebe
ac1e092f-effc-11ed-80ed-000c29800ae7;Радехів-Витківська 3А оф.407;Радехів вул. Витківська, 3А;7ad94f4f-749b-11df-b112-00215aee3ebe
12af3902-f0d8-11ed-80ed-000c29800ae7;Радехів-Відродження 14;Радехів вул. Відродження, 14;7ad94f4f-749b-11df-b112-00215aee3ebe
2e6fdae2-22ea-11e9-80dc-1c98ec135261;Радехів-Стоянівська 36;Радехів вул. Стоянівська, 36;7ad94f4f-749b-11df-b112-00215aee3ebe
d26ae96b-5932-11ee-80c0-000c2961d091;Радивилів-Невського Олександра 12;Радивилів вул. Невського Олександра, 12;7ad94f53-749b-11df-b112-00215aee3ebe
0afb7ce6-fef9-11ed-80b7-000c2961d091;Радивилів-Невського Олександра 6;Радивилів вул. Невського Олександра, 6;7ad94f53-749b-11df-b112-00215aee3ebe
d2ee2ec7-e318-11eb-80d5-000c29800ae7;Радивилів-Почаївська 33;Радивилів вул. Почаївська, 33;7ad94f53-749b-11df-b112-00215aee3ebe
e4ae1e79-7644-11ee-80c1-000c2961d091;Радомишль-Велика Житомирська 1/2;Радомишль вул. Велика Житомирська, 1/2;7ad94f75-749b-11df-b112-00215aee3ebe
0d6367b5-cfed-11ea-80ca-000c29800ae7;Радомишль-Велика Житомирська 12/1;Радомишль вул. Велика Житомирська, 12/1;7ad94f75-749b-11df-b112-00215aee3ebe
e3aafbee-0515-11ee-80bd-000c2961d091;Радомишль-Карпенка 2А;Радомишль вул. Карпенка, 2А;7ad94f75-749b-11df-b112-00215aee3ebe
bce172f0-fb29-11ed-80ed-000c29800ae7;Ратне-Центральна 52А;Ратне вул. Центральна, 52А;7ad95000-749b-11df-b112-00215aee3ebe
14c96b7d-a4bb-11ee-80c4-000c2961d091;Ратне-Центральна 69Б;Ратне вул. Центральна, 69Б;7ad95000-749b-11df-b112-00215aee3ebe
bf94ad69-7844-11eb-80d1-000c29800ae7;Рахів-Миру 40;Рахів вул. Миру, 40;7ad9500b-749b-11df-b112-00215aee3ebe
993f32d1-54be-11eb-80d0-000c29800ae7;Рахів-Миру 7;Рахів вул. Миру, 7;7ad9500b-749b-11df-b112-00215aee3ebe
205c033c-f978-11ed-80ed-000c29800ae7;Рахів-Тиха 1А;Рахів вул. Тиха, 1А;7ad9500b-749b-11df-b112-00215aee3ebe
d6c63649-e88e-11eb-80d5-000c29800ae7;Ревне-Бориспільська 4;Ревне вул. Бориспільська, 4;7ad9502a-749b-11df-b112-00215aee3ebe
df992715-1401-11ee-80bd-000c2961d091;Рені-Вознесенська 137 (Lifecell);Рені вул. Вознесенська, 137;7ad95045-749b-11df-b112-00215aee3ebe
0c2d3f53-f04e-11eb-80d5-000c29800ae7;Рені-Вознесенська 145;Рені вул. Вознесенська, 145;7ad95045-749b-11df-b112-00215aee3ebe
c87382db-1e8d-11eb-80cd-000c29800ae7;Рені-Пролетарська 29;Рені вул. Пролетарська, 29;7ad95045-749b-11df-b112-00215aee3ebe
8a50ca0c-4253-11ee-80c0-000c2961d091;Рені-Шевченка 29А;Рені вул. Шевченка, 29А;7ad95045-749b-11df-b112-00215aee3ebe
46524542-e6b7-11ea-80cb-000c29800ae7;Репужинці-Центральна 24 А;Репужинці вул. Центральна, 24А;7ad9504a-749b-11df-b112-00215aee3ebe
b92bd559-db4c-11eb-80d5-000c29800ae7;Решетилівка-Покровська 10;Решетилівка вул. Покровська, 10;7ad95052-749b-11df-b112-00215aee3ebe
f45711c7-db4b-11eb-80d5-000c29800ae7;Решетилівка-Полтавська 5;Решетилівка вул. Полтавська, 5;7ad95052-749b-11df-b112-00215aee3ebe
d4dfd4a3-efd4-11ed-80ed-000c29800ae7;Ржищів-Соборна 23;Ржищів вул. Соборна, 23;7ad9505f-749b-11df-b112-00215aee3ebe
669a506f-f69f-11eb-80d5-000c29800ae7;Ржищів-Соборна б/н;Ржищів вул. Соборна, б/н;7ad9505f-749b-11df-b112-00215aee3ebe
9e40dc73-010e-11df-b41f-00215aee3ebe;Рівне;Рівне вул. Боярка, 28;7ad950ad-749b-11df-b112-00215aee3ebe
3d181d3f-dd56-11ea-80ca-000c29800ae7;Рівне-16-го Липня 32 Кавярня;Рівне вул. 16-го Липня, 32;7ad950ad-749b-11df-b112-00215aee3ebe
db809f68-593f-11ee-80c0-000c2961d091;Рівне-Андрія Мельника 25 Пивна Гавань;Рівне вул. Андрія Мельника, 25;7ad950ad-749b-11df-b112-00215aee3ebe
bfe173b9-2d9e-11eb-80ce-000c29800ae7;Рівне-Безручка Марка 2/20 Spirit and food;Рівне вул. Безручка Марка (Дундича Олеко), 2/20;7ad950ad-749b-11df-b112-00215aee3ebe
cc66dc72-9111-11ee-80c1-000c2961d091;Рівне-Біла 5А Кавярня;Рівне вул. Біла, 5А;7ad950ad-749b-11df-b112-00215aee3ebe
f518d601-894d-11ee-80c1-000c2961d091;Рівне-Богоявленська 12 Spirit&Food;Рівне вул. Богоявленська (Черняка), 12;7ad950ad-749b-11df-b112-00215aee3ebe
5d8ff949-067b-11ed-80e7-000c29800ae7;Рівне-Богоявленська 26 Rozetka;Рівне вул. Богоявленська (Черняка), 26;7ad950ad-749b-11df-b112-00215aee3ebe
cb2f5316-2d9c-11eb-80ce-000c29800ae7;Рівне-Буковинська 14 Spirit and food;Рівне вул. Буковинська, 14;7ad950ad-749b-11df-b112-00215aee3ebe
94630187-5939-11ee-80c0-000c2961d091;Рівне-Буковинська 3 Пивна Гавань;Рівне вул. Буковинська, 3;7ad950ad-749b-11df-b112-00215aee3ebe
2bad2733-dc7d-11ee-80c4-000c2961d091;Рівне-Відінська 5В  Електроскутери;Рівне вул. Відінська, 5В;7ad950ad-749b-11df-b112-00215aee3ebe
9f21362c-39ab-11e9-80dd-1c98ec135261;Рівне-Володимира Стельмаха 15 ОККО;Рівне вул. Володимира Стельмаха ( Курчатова ), 15;7ad950ad-749b-11df-b112-00215aee3ebe
792da900-2d9d-11eb-80ce-000c29800ae7;Рівне-Гайдамацька 10А Spirit and food;Рівне вул. Гайдамацька (кол. Струтинської ), 10А;7ad950ad-749b-11df-b112-00215aee3ebe
650fc301-95d6-11ee-80c1-000c2961d091;Рівне-Гайдамацька 2Г Чистенько;Рівне вул. Гайдамацька (кол. Струтинської ), 2Г;7ad950ad-749b-11df-b112-00215aee3ebe
2ee3e1bf-c244-11ed-80ed-000c29800ae7;Рівне-Галицького Данила 9 Spirit and food;Рівне вул. Галицького Данила, 9;7ad950ad-749b-11df-b112-00215aee3ebe
f89e77fe-ee78-11ed-80ed-000c29800ae7;Рівне-Генерала Безручка 30 Spirit and food;Рівне Просп. Генерала Безручка, 30;7ad950ad-749b-11df-b112-00215aee3ebe
ad4758e8-dacc-11ed-80ed-000c29800ae7;Рівне-Грушевського Академіка 3 Оптика KLEIMAN;Рівне вул. Грушевського Академіка, 3;7ad950ad-749b-11df-b112-00215aee3ebe
583a5942-e302-11eb-80d5-000c29800ae7;Рівне-Дарвіна 1 SPAR;Рівне вул. Дарвіна, 1;7ad950ad-749b-11df-b112-00215aee3ebe
c20f1ce6-7ba8-11e9-80e0-1c98ec135261;Рівне-Дубенська 74 ОККО;Рівне вул. Дубенська, 74;7ad950ad-749b-11df-b112-00215aee3ebe
12cb1a90-e062-11e9-80e6-1c98ec135261;Рівне-Замкова 14а Сім23;Рівне вул. Замкова, 14а;7ad950ad-749b-11df-b112-00215aee3ebe
18fcc6f9-2da0-11eb-80ce-000c29800ae7;Рівне-Захисників Маріуполя 87 Spirit and food;Рівне вул. Захисників Маріуполя (к.Костромська), 87;7ad950ad-749b-11df-b112-00215aee3ebe
29c6c909-5948-11ee-80c0-000c2961d091;Рівне-Київська 17 Пивна Гавань;Рівне вул. Київська, 17;7ad950ad-749b-11df-b112-00215aee3ebe
9b45cdc0-731c-11e9-80df-1c98ec135261;Рівне-Київська 17 Сім23;Рівне вул. Київська, 17;7ad950ad-749b-11df-b112-00215aee3ebe
fce84cba-f197-11ec-80e7-000c29800ae7;Рівне-Київська 19 Rozetka;Рівне вул. Київська, 19;7ad950ad-749b-11df-b112-00215aee3ebe
c53f4750-7646-11ee-80c1-000c2961d091;Рівне-Київська 69 Сільпо;Рівне вул. Київська, 69;7ad950ad-749b-11df-b112-00215aee3ebe
f4af9109-3b3e-11e9-80dd-1c98ec135261;Рівне-Київська 84 ОККО;Рівне вул. Київська, 84;7ad950ad-749b-11df-b112-00215aee3ebe
1ea0623a-731d-11e9-80df-1c98ec135261;Рівне-Княгині Ольги 13 Сім23;Рівне вул. Княгині Ольги, 13;7ad950ad-749b-11df-b112-00215aee3ebe
7cd226d3-2d9f-11eb-80ce-000c29800ae7;Рівне-Княгині Ольги 27 Spirit and food;Рівне вул. Княгині Ольги, 27;7ad950ad-749b-11df-b112-00215aee3ebe
f6a8d2cc-ae42-11ee-80c4-000c2961d091;Рівне-Корольова 9 Чистенько;Рівне вул. Корольова, 9;7ad950ad-749b-11df-b112-00215aee3ebe
f6e73837-806c-11e9-80e0-1c98ec135261;Рівне-Крушельницької Соломії 62А Сім23;Рівне вул. Крушельницької Соломії, 62А;7ad950ad-749b-11df-b112-00215aee3ebe
52906109-083b-11ed-80e7-000c29800ae7;Рівне-Кулика і Гудачека 23 Lifecell;Рівне вул. Кулика і Гудачека ( Макарова ), 23;7ad950ad-749b-11df-b112-00215aee3ebe
ef1408df-494f-11ec-80e3-000c29800ae7;Рівне-Лабунського 5 Spirit and food;Рівне вул. Лабунського, 5;7ad950ad-749b-11df-b112-00215aee3ebe
4cd41461-f198-11ec-80e7-000c29800ae7;Рівне-Миру 11 Rozetka;Рівне Просп. Миру, 11;7ad950ad-749b-11df-b112-00215aee3ebe
5d483794-731b-11e9-80df-1c98ec135261;Рівне-Миру 14 Сім23;Рівне Просп. Миру, 14;7ad950ad-749b-11df-b112-00215aee3ebe
12c17e4d-ae32-11ed-80ec-000c29800ae7;Рівне-Миру 15 Сім23;Рівне Просп. Миру, 15;7ad950ad-749b-11df-b112-00215aee3ebe
e08a577b-99af-11ee-80c1-000c2961d091;Рівне-Миру 16;Рівне Просп. Миру, 18;7ad950ad-749b-11df-b112-00215aee3ebe
d2b75572-6bbf-11e7-80c4-1c98ec135263;Рівне-Миру 18 СПЕЦ;Рівне Просп. Миру, 18;7ad950ad-749b-11df-b112-00215aee3ebe
34ce0fd8-e301-11eb-80d5-000c29800ae7;Рівне-Миру 2 SPAR;Рівне Просп. Миру, 2;7ad950ad-749b-11df-b112-00215aee3ebe
10c2272a-e063-11e9-80e6-1c98ec135261;Рівне-Миру 8 Сім23;Рівне Просп. Миру, 8;7ad950ad-749b-11df-b112-00215aee3ebe
7102d439-7647-11ee-80c1-000c2961d091;Рівне-Олександра Борисенка 1 Сільпо;Рівне вул. Олександра Борисенка ( Короленка ), 1;7ad950ad-749b-11df-b112-00215aee3ebe
dd404169-f9c9-11eb-80d5-000c29800ae7;Рівне-Петлюри Симона 7 ТурАгенство;Рівне вул. Петлюри Симона, 7;7ad950ad-749b-11df-b112-00215aee3ebe
8c18d8e3-2da1-11eb-80ce-000c29800ae7;Рівне-Полуботка Гетьмана 35 Spirit and Food;Рівне вул. Полуботка Гетьмана, 35;7ad950ad-749b-11df-b112-00215aee3ebe
7e12fbd3-5947-11ee-80c0-000c2961d091;Рівне-Привокзальний 1В Пивна Гавань;Рівне Майдан Привокзальний, 1В;7ad950ad-749b-11df-b112-00215aee3ebe
31d04289-080a-11ed-80e7-000c29800ae7;Рівне-Різдвяна 2А Будівельний магазин;Рівне вул. Різдвяна, 2А;7ad950ad-749b-11df-b112-00215aee3ebe
3ad6c939-39ac-11e9-80dd-1c98ec135261;Рівне-Романа Шухевича 18А ОККО;Рівне вул. Романа Шухевича, 18А;7ad950ad-749b-11df-b112-00215aee3ebe
c4aceed0-56e4-11ee-80c0-000c2961d091;Рівне-Савури Клима 17 Сім23;Рівне вул. Савури Клима, 17;7ad950ad-749b-11df-b112-00215aee3ebe
92854ed1-083b-11ed-80e7-000c29800ae7;Рівне-Сергія Бачинського 5 Lifecell;Рівне вул. Сергія Бачинського, 5;7ad950ad-749b-11df-b112-00215aee3ebe
74b7164e-f2fc-11ed-80ed-000c29800ae7;Рівне-Сергія Бачинського 5 Rozetka;Рівне вул. Сергія Бачинського, 5;7ad950ad-749b-11df-b112-00215aee3ebe
b81c4528-7647-11ee-80c1-000c2961d091;Рівне-Сергія Бачинського 5 Сільпо;Рівне вул. Сергія Бачинського, 5;7ad950ad-749b-11df-b112-00215aee3ebe
48f619e5-e848-11ea-80cc-000c29800ae7;Рівне-Словацького 4/6 Сім23;Рівне вул. Словацького, 4/6;7ad950ad-749b-11df-b112-00215aee3ebe
c36deed1-e063-11e9-80e6-1c98ec135261;Рівне-Соборна 16 Сім23;Рівне вул. Соборна, 16;7ad950ad-749b-11df-b112-00215aee3ebe
9cbabbac-2da4-11eb-80ce-000c29800ae7;Рівне-Соборна 264 Spirit and food;Рівне вул. Соборна, 264;7ad950ad-749b-11df-b112-00215aee3ebe
e444d797-b66c-11ed-80ec-000c29800ae7;Рівне-Соборна 285А Rozetka;Рівне вул. Соборна, 285А;7ad950ad-749b-11df-b112-00215aee3ebe
afdb0918-9db2-11ee-80c1-000c2961d091;Рівне-Соборна 434 маркет Меблі;Рівне вул. Соборна, 430 А;7ad950ad-749b-11df-b112-00215aee3ebe
7268e6a9-dacb-11ed-80ed-000c29800ae7;Рівне-Соборна 65 Оптика KLEIMAN;Рівне вул. Соборна, 65;7ad950ad-749b-11df-b112-00215aee3ebe
2f4205d1-2da2-11eb-80ce-000c29800ae7;Рівне-Соборна 67 Spirit and food;Рівне вул. Соборна, 67;7ad950ad-749b-11df-b112-00215aee3ebe
5a7b4672-ae32-11ed-80ec-000c29800ae7;Рівне-Соборна 67 Сім23;Рівне вул. Соборна, 67;7ad950ad-749b-11df-b112-00215aee3ebe
66b8ce8c-2e3a-11eb-80ce-000c29800ae7;Рівне-Степана Бандери 26 Spirit and food;Рівне вул. Степана Бандери, 26;7ad950ad-749b-11df-b112-00215aee3ebe
52832832-7167-11e9-80df-1c98ec135261;Рівне-Степана Бандери 55 Сім23;Рівне вул. Степана Бандери, 55;7ad950ad-749b-11df-b112-00215aee3ebe
af83853d-ae42-11ee-80c4-000c2961d091;Рівне-Степана Бандери 60 Чистенько;Рівне вул. Степана Бандери, 60;7ad950ad-749b-11df-b112-00215aee3ebe
0a5fc2ff-083b-11ed-80e7-000c29800ae7;Рівне-Червонія Василя  16 Lifecell;Рівне вул. Червонія Василя  ( Гагаріна ), 16;7ad950ad-749b-11df-b112-00215aee3ebe
321fd182-3b40-11e9-80dd-1c98ec135261;Рівне-Червонія Василя  18 ОККО;Рівне вул. Червонія Василя  ( Гагаріна ), 18;7ad950ad-749b-11df-b112-00215aee3ebe
1b8d0f86-7647-11ee-80c1-000c2961d091;Рівне-Червонія Василя 16 Сільпо;Рівне вул. Червонія Василя  ( Гагаріна ), 16;7ad950ad-749b-11df-b112-00215aee3ebe
43819a38-e303-11eb-80d5-000c29800ae7;Рівне-Чорновола 60 SPAR;Рівне вул. Чорновола, 60;7ad950ad-749b-11df-b112-00215aee3ebe
6dae730e-0375-11ee-80bd-000c2961d091;Рівне-Чорновола 91У Сім23;Рівне вул. Чорновола, 91У;7ad950ad-749b-11df-b112-00215aee3ebe
78b2c3ed-3b42-11e9-80dd-1c98ec135261;Рівне-Чорновола 92 ОККО;Рівне вул. Чорновола, 92;7ad950ad-749b-11df-b112-00215aee3ebe
03bdc403-ae34-11ed-80ec-000c29800ae7;Рівне-Шевченка 9 Сім23;Рівне вул. Шевченка, 9;7ad950ad-749b-11df-b112-00215aee3ebe
0395a3f0-a81b-11eb-80d3-000c29800ae7;Рідківці-Магалянська 2А;Рідківці вул. Могилянська, 2А;7ad950d4-749b-11df-b112-00215aee3ebe
cb2d8959-0715-11ec-80d6-000c29800ae7;Ріпки-Святомиколаївська 116;Ріпки вул. Святомиколаївська, 116;7ad950f4-749b-11df-b112-00215aee3ebe
b37f769b-be33-11eb-80d5-000c29800ae7;Ріпки-Святомиколаївська 120;Ріпки вул. Святомиколаївська, 120;7ad950f4-749b-11df-b112-00215aee3ebe
6c9c786b-a7ac-11e9-80e4-1c98ec135261;Рогатин-Мухи 1;Рогатин вул. Мухи, 1;7ad95119-749b-11df-b112-00215aee3ebe
c2aa2c00-c80f-11ee-80c4-000c2961d091;Рогатин-Шевченка 3А;Рогатин вул. Шевченка, 3А;7ad95119-749b-11df-b112-00215aee3ebe
ecb65375-1b17-11e9-80dc-1c98ec135261;Рожище-Незалежності 1;Рожище вул. Незалежності, 1;7ad9515c-749b-11df-b112-00215aee3ebe
5ab9fde4-0bd5-11ec-80d6-000c29800ae7;Рожище-Незалежності 60А;Рожище вул. Незалежності, 60А;7ad9515c-749b-11df-b112-00215aee3ebe
9186cd7b-a0ef-11eb-80d3-000c29800ae7;Рожнів-Шевченка 151;Рожнів вул. Шевченка, 151;7ad95163-749b-11df-b112-00215aee3ebe
097bff71-d532-11ed-80ed-000c29800ae7;Рожнятів-Шкільна 3Б;Рожнятів вул. Шкільна, 3Б;7ad95165-749b-11df-b112-00215aee3ebe
525f7620-581f-11ec-80e4-000c29800ae7;Розсошенці-Геологічна 1А Делві;Розсошенці вул. Геологічна, 1А;7ad951e7-749b-11df-b112-00215aee3ebe
b3fd502a-6704-11ea-80c6-000c29800ae7;Рокитне;Рокитне вул. Першотравнева, 118;7ad9520e-749b-11df-b112-00215aee3ebe
98b8d2cc-ba8a-11eb-80d4-000c29800ae7;Рокитне-Вокзальна 1 Lifecell;Рокитне вул. Вокзальна, 1;7ad9520e-749b-11df-b112-00215aee3ebe
1809d225-ba85-11eb-80d4-000c29800ae7;Рокитне-Зінича Ігора 7 Експерт;Рокитне вул. Зінича Ігора (кол. Радянська), 7;7ad9520e-749b-11df-b112-00215aee3ebe
e4c50dc1-b961-11eb-80d4-000c29800ae7;Рокитне-Партизанська 13;Рокитне вул. Партизанська, 13;7ad95212-749b-11df-b112-00215aee3ebe
2cb800fa-c810-11ee-80c4-000c2961d091;Рокитне-Першотравнева 3 ЖЖУК;Рокитне вул. Першотравнева, 3;7ad9520e-749b-11df-b112-00215aee3ebe
16493682-2e68-11eb-80ce-000c29800ae7;Рокитне-Пролетарська 15;Рокитне вул. Патріотична ( Пролетарська ), 15;7ad95212-749b-11df-b112-00215aee3ebe
afa62f7a-2e68-11eb-80ce-000c29800ae7;Рокитне-Пролетарська 44;Рокитне вул. Патріотична ( Пролетарська ), 44;7ad95212-749b-11df-b112-00215aee3ebe
8a92cf5d-18c5-11ed-80e7-000c29800ae7;Рокитне-Соборна 2А;Рокитне вул. Соборна, 2А;7ad95212-749b-11df-b112-00215aee3ebe
b19edf25-2e58-11eb-80ce-000c29800ae7;Рокитне-Соборна 54;Рокитне вул. Соборна, 54;7ad95212-749b-11df-b112-00215aee3ebe
c9fce783-2da0-11eb-80ce-000c29800ae7;Рокитне-Франка Івана 1А;Рокитне вул. Франка Івана, 1А;7ad95212-749b-11df-b112-00215aee3ebe
1b92d7eb-53bc-11ee-80c0-000c2961d091;Рокитне-Франка Івана 78А;Рокитне вул. Франка Івана, 78А;7ad95212-749b-11df-b112-00215aee3ebe
04d05a3e-53c0-11ee-80c0-000c2961d091;Рокитне-Шкільна 41;Рокитне вул. Шкільна, 41;7ad95212-749b-11df-b112-00215aee3ebe
8bc1701a-f6b7-11eb-80d5-000c29800ae7;Романів-Небесної Сотні 105/1;Романів вул. Небесної Сотні, 105/1;73e94be4-ddc1-11df-9197-00215aee3ebe
8363a3d6-8a27-11ee-80c1-000c2961d091;Ромни-Героїв Роменщини 244;Ромни вул. Героїв Роменщини ( Горького ), 244;7ad9525b-749b-11df-b112-00215aee3ebe
d9847c64-878a-11ee-80c1-000c2961d091;Ромни-Коржівська 65/38;Ромни вул. Коржівська, 65/38;7ad9525b-749b-11df-b112-00215aee3ebe
c7f36a5f-b6c9-11ed-80ec-000c29800ae7;Ромни-Коржівська 79;Ромни вул. Коржівська, 79;7ad9525b-749b-11df-b112-00215aee3ebe
777c62e5-8786-11ee-80c1-000c2961d091;Ромни-Полтавська 8/1;Ромни вул. Полтавська, 8/1;7ad9525b-749b-11df-b112-00215aee3ebe
fb778f37-89fa-11ee-80c1-000c2961d091;Ромни-Соборна 23;Ромни вул. Соборна, 23;7ad9525b-749b-11df-b112-00215aee3ebe
190dd300-62ae-11e9-80df-1c98ec135261;Рудне-Огієнка 1;Рудне вул. Огієнка, 1;7ad952f0-749b-11df-b112-00215aee3ebe
9d88bc40-29f7-11ee-80bd-000c2961d091;Ружин-Нова 2А;Ружин вул. Нова, 2А;7ad95334-749b-11df-b112-00215aee3ebe
6cba3495-a1d5-11eb-80d3-000c29800ae7;Ружичанка-Хмельницька 69;Ружичанка вул. Хмельницька, 69;7ad95336-749b-11df-b112-00215aee3ebe
72dc2ebc-7ba5-11e9-80e0-1c98ec135261;Рясне-Руська Кільцева 5;Рясне-Руське вул. Героїв Упа (Кільцева), 5;7ad95383-749b-11df-b112-00215aee3ebe
53162e79-2823-11eb-80ce-000c29800ae7;Самбір;Самбір вул. Військторг, 37и;7ad9543b-749b-11df-b112-00215aee3ebe
321524c2-40f2-11ee-80c0-000c2961d091;Самбір-Білозіра Ігора 2 Продукти;Самбір вул. Білозіра Ігора, 2;7ad9543b-749b-11df-b112-00215aee3ebe
f6c70569-e59c-11ed-80ed-000c29800ae7;Самбір-Валова 13 Lifecell;Самбір вул. Валова, 13;7ad9543b-749b-11df-b112-00215aee3ebe
ccf30ce4-40f2-11ee-80c0-000c2961d091;Самбір-Валова 4/1 Продукти;Самбір вул. Валова, 4/1;7ad9543b-749b-11df-b112-00215aee3ebe
1cea883c-40f3-11ee-80c0-000c2961d091;Самбір-Грушевського Михайла 35 Продукти;Самбір вул. Грушевського Михайла, 35;7ad9543b-749b-11df-b112-00215aee3ebe
a09c82fb-40f3-11ee-80c0-000c2961d091;Самбір-Заміська 2 Продукти;Самбір вул. Заміська, 2;7ad9543b-749b-11df-b112-00215aee3ebe
dea842e1-40f5-11ee-80c0-000c2961d091;Самбір-Коперніка 13А Продукти;Самбір вул. Коперніка, 13А;7ad9543b-749b-11df-b112-00215aee3ebe
7bd377ff-40f4-11ee-80c0-000c2961d091;Самбір-Коперніка 57 Продукти;Самбір вул. Коперніка, 57;7ad9543b-749b-11df-b112-00215aee3ebe
8037214e-bf24-11ed-80ed-000c29800ae7;Самбір-Лемківська 1А Rozetka;Самбір вул. Лемківська (Завокзальна), 1А;7ad9543b-749b-11df-b112-00215aee3ebe
9be4ea02-b2a4-11ed-80ec-000c29800ae7;Самбір-Лемківська 3Ж Візовий центр;Самбір вул. Лемківська (Завокзальна), 3Ж;7ad9543b-749b-11df-b112-00215aee3ebe
2ed1d6bb-40f6-11ee-80c0-000c2961d091;Самбір-Мазепи 2 Продукти;Самбір вул. Мазепи, 2;7ad9543b-749b-11df-b112-00215aee3ebe
826a7f2f-7eeb-11ee-80c1-000c2961d091;Самбір-Мазепи 3/2 туристична агенція Віт;Самбір вул. Мазепи, 3/2;7ad9543b-749b-11df-b112-00215aee3ebe
3a583d11-40f7-11ee-80c0-000c2961d091;Самбір-Чорновола В'ячеслава 2В Продукти;Самбір вул. Чорновола В'ячеслава, 2В;7ad9543b-749b-11df-b112-00215aee3ebe
32f7bfec-d30f-11e9-80e4-1c98ec135261;Самбір-Шевченка 5 Косметика;Самбір вул. Шевченка, 5;7ad9543b-749b-11df-b112-00215aee3ebe
94dd592c-8be3-11e2-ade3-003048d2b473;Сарни;Сарни вул. Бєлгородська, 31;7ad95481-749b-11df-b112-00215aee3ebe
c1427b2b-2e3c-11eb-80ce-000c29800ae7;Сарни-Бєлгородська 2А/1 Spirit and food;Сарни вул. Бєлгородська, 2А/1;7ad95481-749b-11df-b112-00215aee3ebe
51814d69-2e3d-11eb-80ce-000c29800ae7;Сарни-Бєлгородська 33В Spirit and food;Сарни вул. Бєлгородська, 33В;7ad95481-749b-11df-b112-00215aee3ebe
334c2711-eb5f-11e9-80e7-1c98ec135261;Сарни-Бєлгородська 9/2 Lifecell;Сарни вул. Бєлгородська, 9/2;7ad95481-749b-11df-b112-00215aee3ebe
8f57de1c-b364-11e9-80e4-1c98ec135261;Сарни-Варшавська 4Б ОККО;Сарни вул. Варшавська, 4Б;7ad95481-749b-11df-b112-00215aee3ebe
dbbd4250-5947-11ee-80c0-000c2961d091;Сарни-Ковельська 33 Пивна Гавань;Сарни вул. Ковельська, 33;7ad95481-749b-11df-b112-00215aee3ebe
7523001f-3c28-11e8-80d0-1c98ec135261;Сарни-Лисенка 4 SOWA;Сарни вул. Лисенка, 4;7ad95481-749b-11df-b112-00215aee3ebe
951ab429-e2d7-11ea-80cb-000c29800ae7;Сарни-Соборна 16 Меблі;Сарни вул. Соборна (17-го Вересня), 16;7ad95481-749b-11df-b112-00215aee3ebe
4cf268c6-a188-11e9-80e2-1c98ec135261;Сарни-Суворова 5 Ypistore;Сарни вул. Степана Бандери (Суворова), 5;7ad95481-749b-11df-b112-00215aee3ebe
7ad2d6ee-92af-11ee-80c1-000c2961d091;Сарни-Широка 36 SOWA;Сарни вул. Широка, 36;7ad95481-749b-11df-b112-00215aee3ebe
b312212c-2b7c-11ee-80bd-000c2961d091;Сарни-Широка 60 Rozetka;Сарни вул. Широка, 60;7ad95481-749b-11df-b112-00215aee3ebe
007fac9e-b93f-11ee-80c4-000c2961d091;Сатанів-Хмельницького Богдана 22;Сатанів вул. Хмельницького Богдана, 22;7ad95490-749b-11df-b112-00215aee3ebe
dd23dd4e-15bd-11ee-80bd-000c2961d091;Свалява-Верховинська 21А;Свалява вул. Верховинська, 21А;7ad954ac-749b-11df-b112-00215aee3ebe
9b69a25a-69b9-11ee-80c1-000c2961d091;Свалява-Визволення 41;Свалява вул. Визволення, 41;7ad954ac-749b-11df-b112-00215aee3ebe
249b2280-69b9-11ee-80c1-000c2961d091;Свалява-Мукачівська 9;Свалява вул. Мукачівська, 9;7ad954ac-749b-11df-b112-00215aee3ebe
b65e1ed0-6181-11ec-80e4-000c29800ae7;Світловодськ-8-го Березня, 22;Світловодськ вул. 8-го Березня, 22;7ad9550e-749b-11df-b112-00215aee3ebe
7008c943-617e-11ec-80e4-000c29800ae7;Світловодськ-Будівельників 2;Світловодськ вул. Будівельників, 2;7ad9550e-749b-11df-b112-00215aee3ebe
2914199f-617f-11ec-80e4-000c29800ae7;Світловодськ-Будівельників 20;Світловодськ вул. Будівельників, 20;7ad9550e-749b-11df-b112-00215aee3ebe
bbe93561-617a-11ec-80e4-000c29800ae7;Світловодськ-Героїв України 12;Світловодськ вул. Героїв України (Леніна), 12;7ad9550e-749b-11df-b112-00215aee3ebe
c7e4f105-617d-11ec-80e4-000c29800ae7;Світловодськ-Героїв України 23;Світловодськ вул. Героїв України (Леніна), 23;7ad9550e-749b-11df-b112-00215aee3ebe
956904fa-522c-11ee-80c0-000c2961d091;Світловодськ-Героїв України 73;Світловодськ вул. Героїв України (Леніна), 73;7ad9550e-749b-11df-b112-00215aee3ebe
e5b6edb8-6182-11ec-80e4-000c29800ae7;Світловодськ-Захисників України 1;Світловодськ вул. Захисників України, 1;7ad9550e-749b-11df-b112-00215aee3ebe
99472fdf-6184-11ec-80e4-000c29800ae7;Світловодськ-Конько 31;Світловодськ вул. Конько, 31;7ad9550e-749b-11df-b112-00215aee3ebe
cf1158bf-48c7-11ee-80c0-000c2961d091;Світловодськ-Михайла Грушевського 3;Світловодськ вул. Михайла Грушевського, 3;7ad9550e-749b-11df-b112-00215aee3ebe
336c3fa1-6182-11ec-80e4-000c29800ae7;Світловодськ-Незалежності 189А;Світловодськ вул. Незалежності, 189А;7ad9550e-749b-11df-b112-00215aee3ebe
2be19e14-7652-11e9-80e0-1c98ec135261;Світловодськ-Приморська 56;Світловодськ вул. Приморська, 56;7ad9550e-749b-11df-b112-00215aee3ebe
4b8ef512-6181-11ec-80e4-000c29800ae7;Світловодськ-Приморська 64;Світловодськ вул. Приморська, 64;7ad9550e-749b-11df-b112-00215aee3ebe
b4bb9925-6180-11ec-80e4-000c29800ae7;Світловодськ-Приморська 72;Світловодськ вул. Приморська, 72;7ad9550e-749b-11df-b112-00215aee3ebe
32ee0f63-6184-11ec-80e4-000c29800ae7;Світловодськ-Робітнича 2;Світловодськ вул. Робітнича, 2;7ad9550e-749b-11df-b112-00215aee3ebe
85488c65-9f28-11ea-80c8-000c29800ae7;Святопетрівське-Білогородська 10 Автотовари;Святопетрівське (Петрівське) вул. Білогородська, 10;74dc4991-749b-11df-b112-00215aee3ebe
c196c7e7-3988-11ec-80e3-000c29800ae7;Святопетрівське-Українки Лесі 6А Домашній;Святопетрівське (Петрівське) бул. Українки Лесі, 6А;74dc4991-749b-11df-b112-00215aee3ebe
4d09a49b-ada3-11eb-80d3-000c29800ae7;Святопетрівське-Шевченка 6А Біле Сухе;Святопетрівське (Петрівське) бул. Шевченка, 6А;74dc4991-749b-11df-b112-00215aee3ebe
17112db3-4179-11ec-80e3-000c29800ae7;Семенівка-Вокзальний 4А;Семенівка пров. Вокзальний, 4А;7ad9557d-749b-11df-b112-00215aee3ebe
cdab91ad-fb41-11eb-80d6-000c29800ae7;Синельникове-Виконкомівська 21;Синельникове вул. Виконкомівська, 21;7ad95689-749b-11df-b112-00215aee3ebe
62be1acc-f97c-11ed-80ed-000c29800ae7;Синельникове-Виконкомівська 39А;Синельникове вул. Виконкомівська, 39А;7ad95689-749b-11df-b112-00215aee3ebe
d287f1c2-fb42-11eb-80d6-000c29800ae7;Синельникове-Космодем'янської Зої 26;Синельникове вул. Космодем'янської Зої, 26;7ad95689-749b-11df-b112-00215aee3ebe
c48554cd-1f1f-11ee-80bd-000c2961d091;Синельникове-Кримська 31;Синельникове вул. Кримська, 31;7ad95689-749b-11df-b112-00215aee3ebe
fbe57f49-21f5-11ec-80df-000c29800ae7;Синельникове-Миру 1;Синельникове вул. Миру, 1;7ad95689-749b-11df-b112-00215aee3ebe
1e646891-5954-11ee-80c0-000c2961d091;Синельникове-Музична 1;Синельникове вул. Музична, 1;7ad95689-749b-11df-b112-00215aee3ebe
74fe37a4-fb41-11eb-80d6-000c29800ae7;Синельникове-Сонячний 2А;Синельникове пров. Сонячний, 2А;7ad95689-749b-11df-b112-00215aee3ebe
6673dcb9-7740-11ee-80c1-000c2961d091;Сільце-Центральна 109;Сільце вул. Центральна, 109;7ad956e6-749b-11df-b112-00215aee3ebe
0384b5e7-b2e4-11e9-80e4-1c98ec135261;Сімер-Будівельників 11;Сімер вул. Будівельників, 11;7ad956ec-749b-11df-b112-00215aee3ebe
7c8794f1-9902-11ee-80c1-000c2961d091;Сінгури-Шкільна 5;Сінгури вул. Шкільна, 5;7ad956f1-749b-11df-b112-00215aee3ebe
b70de84f-f050-11eb-80d5-000c29800ae7;Скала-Подільська-Грушевського 53;Скала-Подільська вул. Грушевського, 53;7ad95711-749b-11df-b112-00215aee3ebe
07f19ff4-9935-11eb-80d3-000c29800ae7;Скалат-Грушевського 4 А;Скалат вул. Грушевського, 4А;7ad95712-749b-11df-b112-00215aee3ebe
78996b1c-b36b-11e9-80e4-1c98ec135261;Сквира-Київська 23 А;Сквира вул. Київська, 23А;7ad9571f-749b-11df-b112-00215aee3ebe
10cf6e08-cfd4-11ed-80ed-000c29800ae7;Сквира-Червона 2А;Сквира пл. Червона, 2А;7ad9571f-749b-11df-b112-00215aee3ebe
94c868fd-22f7-11e9-80dc-1c98ec135261;Сколе-Героїв Маківки 10;Сколе вул. Героїв Маківки, 10;7ad95747-749b-11df-b112-00215aee3ebe
cf8d5461-5c54-11ee-80c0-000c2961d091;Сколе-Героїв Маківки 9;Сколе вул. Героїв Маківки, 9;7ad95747-749b-11df-b112-00215aee3ebe
1fde7b8f-c811-11ee-80c4-000c2961d091;Сколе-Незалежності 7А;Сколе Майдан Незалежності, 7А;7ad95747-749b-11df-b112-00215aee3ebe
8c7fb29b-d0a7-11ee-80c4-000c2961d091;Сколе-Ушневича 11А;Сколе вул. Ушневича (Стрийська), 11А;7ad95747-749b-11df-b112-00215aee3ebe
78ceea80-2083-11ee-80bd-000c2961d091;Славське-Франка Івана 14А;Славське вул. Франка Івана, 14А;80f554b2-749b-11df-b112-00215aee3ebe
5cf1dc70-a4cb-11ea-80c8-000c29800ae7;Славута;Славута вул. Ярослава Мудрого, 11;80f554b3-749b-11df-b112-00215aee3ebe
fa85197f-6f3c-11ee-80c1-000c2961d091;Славута-Князів Сангушків 99 Дніпро ПП Влад;Славута пров. Князів Сангушків, 99;80f554b3-749b-11df-b112-00215aee3ebe
f79519b9-9ff2-11ee-80c4-000c2961d091;Славута-Соборності 32 Ломбард;Славута вул. Соборності, 32;80f554b3-749b-11df-b112-00215aee3ebe
ac12c1da-8458-11e8-80d4-1c98ec135261;Славута-Соборності 5 ПП ВЛАД;Славута вул. Соборності, 5;80f554b3-749b-11df-b112-00215aee3ebe
26524c22-4131-11eb-80d0-000c29800ae7;Славута-Шевченка 2 Технопростір;Славута пл. Шевченка, 2;80f554b3-749b-11df-b112-00215aee3ebe
c3c2bf1e-c895-11ed-80ed-000c29800ae7;Славутич- квартал Бакінський 1;Славутич кв. Бакинський, 1;80f554b5-749b-11df-b112-00215aee3ebe
65de92b1-54e4-11eb-80d0-000c29800ae7;Славутич-Московський квартал 60;Славутич кв. Поліський, 60;80f554b5-749b-11df-b112-00215aee3ebe
71f92c97-ffac-11ed-80bd-000c2961d091;Славутич-Полівський 60 (Московський);Славутич кв. Поліський, 60;80f554b5-749b-11df-b112-00215aee3ebe
5b81fe3d-26d9-11ee-80bd-000c2961d091;Славутич-Тбіліський 1;Славутич кв. Тбіліський, 1;80f554b5-749b-11df-b112-00215aee3ebe
37318069-98f5-11ee-80c1-000c2961d091;Слобожанське-8-го Березня 3Б ZooLand;Слобожанське (Ювілейне ) вул. Слобожанська (8-го Березня), 3Б;8d17eee5-749b-11df-b112-00215aee3ebe
128ab118-f2fe-11ed-80ed-000c29800ae7;Слобожанське-Володимирська 1 Rozetka;Слобожанське (Ювілейне ) вул. Володимирська, 1;8d17eee5-749b-11df-b112-00215aee3ebe
1bdba922-3963-11eb-80cf-000c29800ae7;Слобожанське-Закори 16;Слобожанське (Комсомольське) вул. Закори, 16;5cb619ca-749b-11df-b112-00215aee3ebe
30e13052-c986-11ee-80c4-000c2961d091;Слобожанське-Логістична 7А;Слобожанське (Ювілейне ) вул. Логістична, 7А;8d17eee5-749b-11df-b112-00215aee3ebe
654bef05-9fed-11ee-80c4-000c2961d091;Слобожанське-Сухомлинського 48А Ломбард;Слобожанське (Ювілейне ) вул. Сухомлинського (Радгоспна), 48А;8d17eee5-749b-11df-b112-00215aee3ebe
572fe7f2-5e88-11ee-80c0-000c2961d091;Слов'янськ;Слов'янськ вул. Вокзальна (Свердлова), 10;80f55533-749b-11df-b112-00215aee3ebe
ef8008f2-68f6-11ee-80c1-000c2961d091;Слов'янськ-Свободи 5 ЕЛМА-СЕРВІС;Слов'янськ в'їзд Свободи, 5;80f55533-749b-11df-b112-00215aee3ebe
deddef0b-66ac-11ee-80c0-000c2961d091;Слов'янськ-Шовковична 12 Rozetka;Слов'янськ вул. Шовковична (Іскри), 12;80f55533-749b-11df-b112-00215aee3ebe
0d88808d-051b-11ee-80bd-000c2961d091;Сміла-Незалежності 117;Сміла вул. Незалежності, 117;80f5554f-749b-11df-b112-00215aee3ebe
58fd37b7-09d0-11ed-80e7-000c29800ae7;Сміла-Незалежності 16;Сміла вул. Незалежності, 16;80f5554f-749b-11df-b112-00215aee3ebe
60bf4ae2-31f8-11ee-80bd-000c2961d091;Сміла-Незалежності 26;Сміла вул. Незалежності, 26;80f5554f-749b-11df-b112-00215aee3ebe
eb35078b-7649-11ee-80c1-000c2961d091;Сміла-Незалежності 67А;Сміла вул. Незалежності, 67А;80f5554f-749b-11df-b112-00215aee3ebe
31c39e9c-913d-11ee-80c1-000c2961d091;Сміла-Соборна 118;Сміла вул. Соборна, 118;80f5554f-749b-11df-b112-00215aee3ebe
2cab9d44-1fd0-11ee-80bd-000c2961d091;Сміла-Хмельницького Богдана 47;Сміла вул. Хмельницького Богдана, 47;80f5554f-749b-11df-b112-00215aee3ebe
d2b43551-051b-11ee-80bd-000c2961d091;Сміла-Хмельницького Богдана 49Б;Сміла вул. Хмельницького Богдана, 49Б;80f5554f-749b-11df-b112-00215aee3ebe
15b3f6a1-b155-11ee-80c4-000c2961d091;Сміла-Шевченка 5Б;Сміла вул. Шевченка, 5Б;80f5554f-749b-11df-b112-00215aee3ebe
74497edd-be34-11eb-80d5-000c29800ae7;Сновськ-Залізнична 15;Сновськ вул. Залізнична, 15;8d17eece-749b-11df-b112-00215aee3ebe
d92e1fd5-41bb-11ee-80c0-000c2961d091;Сновськ-Незалежності 14;Сновськ вул. Незалежності, 14;8d17eece-749b-11df-b112-00215aee3ebe
eff82759-a799-11e9-80e4-1c98ec135261;Снятин-Винниченка 29;Снятин вул. Винниченка, 29;80f555a0-749b-11df-b112-00215aee3ebe
9c7b844c-ff89-11ed-80bc-000c2961d091;Снятин-Хроновича Володимира 3;Снятин вул. Хроновича Володимира, 3;80f555a0-749b-11df-b112-00215aee3ebe
42e8be2a-887b-11ee-80c1-000c2961d091;Снятин-Шевченка 147А;Снятин вул. Шевченка, 147А;80f555a0-749b-11df-b112-00215aee3ebe
3127cc63-8bf2-11eb-80d2-000c29800ae7;Снятин-Шевченка 61;Снятин вул. Шевченка, 61;80f555a0-749b-11df-b112-00215aee3ebe
b09ab156-2b33-11ec-80e3-000c29800ae7;Сокаль-Тартаківська 101;Сокаль вул. Тартаківська, 101;34fec090-ff60-11de-9277-00215aee3ebe
b668e42f-26b2-11ec-80e3-000c29800ae7;Сокаль-Шептицького 111;Сокаль вул. Шептицького, 111;34fec090-ff60-11de-9277-00215aee3ebe
c5f84fe4-ca3d-11ed-80ed-000c29800ae7;Сокаль-Шептицького 49А;Сокаль вул. Шептицького, 49А;34fec090-ff60-11de-9277-00215aee3ebe
12c9fa09-1709-11ed-80e7-000c29800ae7;Сокаль-Шептицького 67А;Сокаль вул. Шептицького, 67А;34fec090-ff60-11de-9277-00215aee3ebe
ffae070b-f90a-11eb-80d5-000c29800ae7;Сокиряни-Перемоги 18;Сокиряни вул. Перемоги, 18;80f555cf-749b-11df-b112-00215aee3ebe
0492d8f5-abf1-11ee-80c4-000c2961d091;Сокільники-Героїв Майдану 17В;Сокільники вул. Героїв Майдану, 17В;80f555dd-749b-11df-b112-00215aee3ebe
36d1c0b9-5c55-11ee-80c0-000c2961d091;Сокільники-Скнилівська 1;Сокільники вул. Скнилівська, 1;80f555dd-749b-11df-b112-00215aee3ebe
da9d5cc1-7ba6-11e9-80e0-1c98ec135261;Сокільники-Сковороди 22;Сокільники вул. Сковороди, 22;80f555dd-749b-11df-b112-00215aee3ebe
d7ecb446-c376-11eb-80d5-000c29800ae7;Сокільники-Стрийська 20;Сокільники вул. Стрийська, 20;80f555dd-749b-11df-b112-00215aee3ebe
949df628-2307-11e9-80dc-1c98ec135261;Солонка-Стрийська 34;Солонка вул. Стрийська, 34;80f5564f-749b-11df-b112-00215aee3ebe
7b01a3e1-b2e7-11e9-80e4-1c98ec135261;Солотвино-Спортивна 98;Солотвино вул. Спортивна, 98;80f5565b-749b-11df-b112-00215aee3ebe
f945bc04-5c51-11ee-80c0-000c2961d091;Сонячне-Комплекс будівель та споруд №1 1 АЗС БРСМ;Сонячне вул. Комплекс будівель та споруд №1, 1;80f55674-749b-11df-b112-00215aee3ebe
b42008f0-285c-11ed-80e7-000c29800ae7;Соснівка-Галицька 9/3;Соснівка вул. Галицька, 9/3;80f556b9-749b-11df-b112-00215aee3ebe
cfe2b730-de2e-11ea-80ca-000c29800ae7;Соф. Борщагівка-Амосова 7 Канцтовари;Софіївська Борщагівка пров. Амосова, 7;80f5570b-749b-11df-b112-00215aee3ebe
f1eec9bf-8466-11ee-80c1-000c2961d091;Соф. Борщагівка-Бишівська 7 BEER WAY;Софіївська Борщагівка вул. Бишівська, 7;80f5570b-749b-11df-b112-00215aee3ebe
68f77719-bc8e-11eb-80d4-000c29800ae7;Соф. Борщагівка-Боголюбова 22  КАВ?ЯРНЯ;Софіївська Борщагівка вул. Боголюбова, 22;80f5570b-749b-11df-b112-00215aee3ebe
e9e94079-635b-11ee-80c0-000c2961d091;Соф. Борщагівка-Боголюбова 9Б Smart Kit;Софіївська Борщагівка вул. Боголюбова, 9Б;80f5570b-749b-11df-b112-00215aee3ebe
1763533d-7bae-11e9-80e0-1c98ec135261;Соф. Борщагівка-Велика Окружна 54 ОККО;Софіївська Борщагівка вул. Велика Окружна, 54;80f5570b-749b-11df-b112-00215aee3ebe
bfe9634e-255d-11ee-80bd-000c2961d091;Соф. Борщагівка-Героїв Небесної Сотні 24/83 Сільпо;Софіївська Борщагівка Просп. Героїв Небесної Сотні, 24/83;80f5570b-749b-11df-b112-00215aee3ebe
f83cc71b-da94-11ed-80ed-000c29800ae7;Соф. Борщагівка-Героїв Небесної Сотні 24/83 СПЕЦ;Софіївська Борщагівка Просп. Героїв Небесної Сотні, 24/83;80f5570b-749b-11df-b112-00215aee3ebe
dca79d39-592c-11ee-80c0-000c2961d091;Соф. Борщагівка-Героїв Небесної Сотні 28 Rozetka;Софіївська Борщагівка Просп. Героїв Небесної Сотні, 28;80f5570b-749b-11df-b112-00215aee3ebe
bda3cab0-16f8-11ec-80df-000c29800ae7;Соф. Борщагівка-Героїв Небесної Сотні 30 Біле Сухе;Софіївська Борщагівка Просп. Героїв Небесної Сотні, 30;80f5570b-749b-11df-b112-00215aee3ebe
3b039bbc-846a-11ee-80c1-000c2961d091;Соф. Борщагівка-Героїв Небесної Сотні 32 BEER WAY;Софіївська Борщагівка Просп. Героїв Небесної Сотні, 32;80f5570b-749b-11df-b112-00215aee3ebe
6f652b27-16f5-11ec-80df-000c29800ae7;Соф. Борщагівка-Горького 5Б Біле Сухе;Софіївська Борщагівка вул. Горького, 5Б;80f5570b-749b-11df-b112-00215aee3ebe
697d2514-7648-11ee-80c1-000c2961d091;Соф. Борщагівка-Київська 1/102 Сільпо;Софіївська Борщагівка вул. Київська, 1/102;80f5570b-749b-11df-b112-00215aee3ebe
22ce84cb-7648-11ee-80c1-000c2961d091;Соф. Борщагівка-Київська 36 Сільпо;Софіївська Борщагівка вул. Київська, 36;80f5570b-749b-11df-b112-00215aee3ebe
cdfe2cc1-808d-11e9-80e0-1c98ec135261;Соф. Борщагівка-Київська 38 ОККО;Софіївська Борщагівка вул. Київська, 38;80f5570b-749b-11df-b112-00215aee3ebe
1d79c7fe-89ff-11ee-80c1-000c2961d091;Соф. Борщагівка-Київська 76 Rozetka;Софіївська Борщагівка вул. Київська, 76;80f5570b-749b-11df-b112-00215aee3ebe
30442d79-e1d9-11ee-80c5-000c2961d091;Соф. Борщагівка-Мартинова 15 Bon Ami;Софіївська Борщагівка вул. Мартинова, 15;80f5570b-749b-11df-b112-00215aee3ebe
31641525-40cf-11ee-80c0-000c2961d091;Соф. Борщагівка-Садова 14А Продукти;Софіївська Борщагівка вул. Садова, 14А;80f5570b-749b-11df-b112-00215aee3ebe
c3c6bdd9-f1ef-11ec-80e7-000c29800ae7;Соф. Борщагівка-Соборна 140А Rozetka;Софіївська Борщагівка вул. Соборна, 140А;80f5570b-749b-11df-b112-00215aee3ebe
29743a12-255e-11ee-80bd-000c2961d091;Соф. Борщагівка-Соборна 140А Сільпо;Софіївська Борщагівка вул. Соборна, 140А;80f5570b-749b-11df-b112-00215aee3ebe
e9c290ac-56ac-11ed-80eb-000c29800ae7;Соф. Борщагівка-Соборна 55 BEER WAY;Софіївська Борщагівка вул. Соборна, 55;80f5570b-749b-11df-b112-00215aee3ebe
31deddb4-92c4-11ee-80c1-000c2961d091;Соф. Борщагівка-Сонячна 1 Будмаркет;Софіївська Борщагівка вул. Сонячна, 1;80f5570b-749b-11df-b112-00215aee3ebe
d2e8148a-fa21-11ed-80ed-000c29800ae7;Соф. Борщагівка-Толстого 102 Rozetka;Софіївська Борщагівка вул. Толстого, 102;80f5570b-749b-11df-b112-00215aee3ebe
dcf3115e-43b0-11ec-80e3-000c29800ae7;Соф. Борщагівка-Шалімова Академіка 65А Біле Сухе;Софіївська Борщагівка вул. Шалімова Академіка, 65А;80f5570b-749b-11df-b112-00215aee3ebe
2e07f39d-f1f0-11ec-80e7-000c29800ae7;Соф. Борщагівка-Шалімова Академіка 70/2 Rozetka;Софіївська Борщагівка вул. Шалімова Академіка, 70/2;80f5570b-749b-11df-b112-00215aee3ebe
f84bbef0-3051-11ee-80bd-000c2961d091;Соф. Борщагівка-Щаслива 50 Мастерок;Софіївська Борщагівка вул. Щаслива, 50;80f5570b-749b-11df-b112-00215aee3ebe
2b22d7a1-f866-11eb-80d5-000c29800ae7;Софіївка-Незалежності 81;Софіївка вул. Незалежності, 81;80f556e6-749b-11df-b112-00215aee3ebe
f1af8228-21c4-11ec-80df-000c29800ae7;Спаське-Козинця 47;Спаське вул. Козинця, 47;80f55731-749b-11df-b112-00215aee3ebe
f3e204e1-a1d7-11eb-80d3-000c29800ae7;Ставниця-Центральна 6;Ставниця вул. Центральна, 6;80f55769-749b-11df-b112-00215aee3ebe
0e9fe870-e31e-11eb-80d5-000c29800ae7;Стара Вижівка-Миру 1;Стара Вижівка пл. Миру, 1;80f557b3-749b-11df-b112-00215aee3ebe
dfb21d7e-f55a-11ed-80ed-000c29800ae7;Стара Вижівка-Миру 2;Стара Вижівка пл. Миру, 2;80f557b3-749b-11df-b112-00215aee3ebe
fb463998-f112-11eb-80d5-000c29800ae7;Старий Косів-Українки Лесі 1;Старий Косів вул. Українки Лесі, 1;80f5580f-749b-11df-b112-00215aee3ebe
e72dd8a5-198b-11ee-80bd-000c2961d091;Старий Самбір-Галицького 42;Старий Самбір вул. Галицького, 42;80f55827-749b-11df-b112-00215aee3ebe
fec2d6ae-f868-11eb-80d5-000c29800ae7;Старий Самбір-Галицького 42/1М;Старий Самбір вул. Галицького, 42/1М;80f55827-749b-11df-b112-00215aee3ebe
e5aef55f-911f-11ee-80c1-000c2961d091;Старий Самбір-Галицького 96А;Старий Самбір вул. Галицького, 96А;80f55827-749b-11df-b112-00215aee3ebe
83575fcb-41b5-11ee-80c0-000c2961d091;Старі Петрівці-Святослава 167А lifecell;Старі Петрівці вул. Святослава, 167А;80f55854-749b-11df-b112-00215aee3ebe
bb06bc23-255e-11ee-80bd-000c2961d091;Старі Петрівці-Святослава 167А Сільпо;Старі Петрівці вул. Святослава, 167А;80f55854-749b-11df-b112-00215aee3ebe
4968cd9d-d2f5-11ed-80ed-000c29800ae7;Старокостянтинів-Миру 28/2;Старокостянтинів вул. Миру, 28/2;80f55876-749b-11df-b112-00215aee3ebe
eb1a8650-0501-11e8-80cd-1c98ec135261;Старокостянтинів-Острозького 10;Старокостянтинів вул. Острозького, 10;80f55876-749b-11df-b112-00215aee3ebe
4a5382ef-3075-11ee-80bd-000c2961d091;Стебник-Грушевського Михайла 6;Стебник вул. Грушевського Михайла, 6;80f558aa-749b-11df-b112-00215aee3ebe
223039f9-8875-11ee-80c1-000c2961d091;Сторожинець-Видинівського 2;Сторожинець вул. Видинівського, 2;80f5598d-749b-11df-b112-00215aee3ebe
316af109-8874-11ee-80c1-000c2961d091;Сторожинець-Федьковича 1;Сторожинець вул. Федьковича, 1;80f5598d-749b-11df-b112-00215aee3ebe
86134625-f567-11ed-80ed-000c29800ae7;Сторожинець-Федьковича 1 lifecell;Сторожинець вул. Федьковича, 1;80f5598d-749b-11df-b112-00215aee3ebe
ce1c51f1-467f-11ee-80c0-000c2961d091;Стоянка-21-й Км Житомирського;Стоянка шосе 21-й Км Житомирського,;80f55999-749b-11df-b112-00215aee3ebe
01e4d07d-255f-11ee-80bd-000c2961d091;Стоянка-Київська 10;Стоянка вул. Київська, 10;80f55999-749b-11df-b112-00215aee3ebe
e73a5dbe-d4af-11ee-80c4-000c2961d091;Стрижавка-Алеї 35/1 корп.1;Стрижавка вул. Алеї, 35/1;80f559b6-749b-11df-b112-00215aee3ebe
52ed8445-04a4-11eb-80cd-000c29800ae7;Стрий;Стрий вул. Вокзальна, 156;80f559bd-749b-11df-b112-00215aee3ebe
fd83df78-d537-11ed-80ed-000c29800ae7;Стрий-Багряного 4/166 Мобільна техніка;Стрий вул. Багряного, 4/166;80f559bd-749b-11df-b112-00215aee3ebe
10899ed1-d8bb-11ea-80ca-000c29800ae7;Стрий-Болехівська 2В ОККО;Стрий вул. Болехівська, 2В;80f559bd-749b-11df-b112-00215aee3ebe
bbb7b7e5-22ed-11e9-80dc-1c98ec135261;Стрий-Болехівська 49 ОККО;Стрий вул. Болехівська, 49;80f559bd-749b-11df-b112-00215aee3ebe
dd419309-2302-11e9-80dc-1c98ec135261;Стрий-Галицька 10 ОККО;Стрий вул. Галицька, 10;80f559bd-749b-11df-b112-00215aee3ebe
7afc38e2-2789-11eb-80cd-000c29800ae7;Стрий-Героїв Небесної Сотні 8 Мобільна техніка;Стрий вул. Героїв Небесної Сотні (Замкова), 8;80f559bd-749b-11df-b112-00215aee3ebe
8c21b6e6-2303-11e9-80dc-1c98ec135261;Стрий-Дрогобицька 197 ОККО;Стрий вул. Дрогобицька, 197;80f559bd-749b-11df-b112-00215aee3ebe
a256c45c-2098-11ee-80bd-000c2961d091;Стрий-Ринок 5 Lifecell;Стрий Майдан Ринок, 5;80f559bd-749b-11df-b112-00215aee3ebe
b3a09692-4647-11e9-80dd-1c98ec135261;Стрий-Сколівська 19А ОККО;Стрий вул. Сколівська, 19А;80f559bd-749b-11df-b112-00215aee3ebe
6f259352-764a-11ee-80c1-000c2961d091;Стрий-Хмельницького Богдана 16 Сільпо;Стрий вул. Хмельницького Богдана, 16;80f559bd-749b-11df-b112-00215aee3ebe
e1bd0422-ae38-11ed-80ec-000c29800ae7;Стрий-Чорновола В'ячеслава 2 Сім23;Стрий Просп. Чорновола В'ячеслава, 2;80f559bd-749b-11df-b112-00215aee3ebe
1029e253-ad30-11ed-80ec-000c29800ae7;Стрий-Шевченка 16 Ремонт Цифрової Техніки;Стрий вул. Шевченка, 16;80f559bd-749b-11df-b112-00215aee3ebe
620bb532-17ca-11ed-80e7-000c29800ae7;Стрий-Шевченка 34 Сім23;Стрий вул. Шевченка, 34;80f559bd-749b-11df-b112-00215aee3ebe
0c18673d-2099-11ee-80bd-000c2961d091;Стрий-Шевченка 50 Lifecell;Стрий вул. Шевченка, 50;80f559bd-749b-11df-b112-00215aee3ebe
ac4bf8b2-cc86-11ed-80ed-000c29800ae7;Стрий-Шевченка 61 Rozetka;Стрий вул. Шевченка, 61;80f559bd-749b-11df-b112-00215aee3ebe
e41cf053-2f85-11ee-80bd-000c2961d091;Стрий-Шевченка 72 ZAVOD PARKETU;Стрий вул. Шевченка, 72;80f559bd-749b-11df-b112-00215aee3ebe
2ad50169-b36a-11e9-80e4-1c98ec135261;Струмівка-Рівненська 2А ОККО;Струмівка вул. Рівненська, 2А;80f559ec-749b-11df-b112-00215aee3ebe
eaad94cb-bee7-11eb-80d5-000c29800ae7;Судова Вишня-Франка Івана 4А;Судова Вишня пл. Франка Івана, 4А;80f55a45-749b-11df-b112-00215aee3ebe
75ec2991-a8dd-11de-bac3-0030485903e8;Суми;Суми вул. Юрія Вєтрова (Воровського), 20;80f55a5e-749b-11df-b112-00215aee3ebe
286fd079-a3fe-11ee-80c4-000c2961d091;Суми-Білопільський 13/1 Кошик;Суми шлях Білопільський, 13/1;80f55a5e-749b-11df-b112-00215aee3ebe
8c3e3435-a7c1-11e9-80e4-1c98ec135261;Суми-Білопільський 26А ОККО;Суми шлях Білопільський, 26А;80f55a5e-749b-11df-b112-00215aee3ebe
ea4a4e45-351a-11e9-80dd-1c98ec135261;Суми-Британська 23/1 Канцелярія Спектр;Суми вул. Британська, 23/1;80f55a5e-749b-11df-b112-00215aee3ebe
4eb6701e-2617-11ee-80bd-000c2961d091;Суми-Британська 41 Rozetka;Суми вул. Британська, 41;80f55a5e-749b-11df-b112-00215aee3ebe
0987ab3f-9ff1-11ee-80c4-000c2961d091;Суми-Британська 43 Ломбард;Суми вул. Британська, 43;80f55a5e-749b-11df-b112-00215aee3ebe
109d94d7-1acf-11ec-80df-000c29800ae7;Суми-Британська 52 Кошик;Суми вул. Британська, 52;80f55a5e-749b-11df-b112-00215aee3ebe
a8215874-eb4f-11ee-80c5-000c2961d091;Суми-Героїв Крут 21-1 автомийка Hose;Суми вул. Героїв Крут, 21-1;80f55a5e-749b-11df-b112-00215aee3ebe
03f64118-6335-11ec-80e4-000c29800ae7;Суми-Збройних Сил України 18 Кошик;Суми вул. Збройних Сил України (Інтернаціоналістів, 18;80f55a5e-749b-11df-b112-00215aee3ebe
3db29cd9-5018-11eb-80d0-000c29800ae7;Суми-Збройних Сил України 21 Mobile;Суми вул. Збройних Сил України (Інтернаціоналістів, 21;80f55a5e-749b-11df-b112-00215aee3ebe
94cbf52f-ccbb-11ee-80c4-000c2961d091;Суми-Збройних Сил України 41А продукти Мікс;Суми вул. Збройних Сил України (Інтернаціоналістів, 41А;80f55a5e-749b-11df-b112-00215aee3ebe
95ea26cd-a38d-11eb-80d3-000c29800ae7;Суми-Зеленко 8 МедТехніка;Суми вул. Зеленко, 8;80f55a5e-749b-11df-b112-00215aee3ebe
bcd7ac66-9ff1-11ee-80c4-000c2961d091;Суми-Іллінська 1 Ломбард;Суми вул. Іллінська, 1;80f55a5e-749b-11df-b112-00215aee3ebe
a6b5a169-416d-11ec-80e3-000c29800ae7;Суми-Кондратьєва 122 Кошик;Суми вул. Кондратьєва (Кірова), 122;80f55a5e-749b-11df-b112-00215aee3ebe
a84e072d-6755-11e9-80df-1c98ec135261;Суми-Кондратьєва 144/3 Тираж;Суми вул. Кондратьєва (Кірова), 144/3;80f55a5e-749b-11df-b112-00215aee3ebe
41de87cc-f25e-11ec-80e7-000c29800ae7;Суми-Кооперативна 1 Rozetka;Суми вул. Кооперативна, 1;80f55a5e-749b-11df-b112-00215aee3ebe
a547e951-a429-11eb-80d3-000c29800ae7;Суми-Курський 125 МедТехніка;Суми Просп. Перемоги (Курський), 125;80f55a5e-749b-11df-b112-00215aee3ebe
9e482864-e69e-11ee-80c5-000c2961d091;Суми-Лінійна 15 Електрика;Суми вул. Лінійна, 15;80f55a5e-749b-11df-b112-00215aee3ebe
0f38f50e-764b-11ee-80c1-000c2961d091;Суми-Лушпи 4/1 Сільпо;Суми вул. Лушпи, 4/1;80f55a5e-749b-11df-b112-00215aee3ebe
9fb45ba8-f25e-11ec-80e7-000c29800ae7;Суми-Лушпи Михайла 10/1 Rozetka;Суми Просп. Лушпи Михайла, 10/1;80f55a5e-749b-11df-b112-00215aee3ebe
4d2c04cc-ccbd-11ee-80c4-000c2961d091;Суми-Лушпи Михайла 15 Мікс;Суми Просп. Лушпи Михайла, 15;80f55a5e-749b-11df-b112-00215aee3ebe
6e8ac772-d17b-11ee-80c4-000c2961d091;Суми-Лушпи Михайла 40 магазин канцелярії;Суми Просп. Лушпи Михайла, 40;80f55a5e-749b-11df-b112-00215aee3ebe
cc24cff4-416f-11ec-80e3-000c29800ae7;Суми-Лушпи Михайла 41 Кошик;Суми Просп. Лушпи Михайла, 41;80f55a5e-749b-11df-b112-00215aee3ebe
b686d616-3121-11ee-80bd-000c2961d091;Суми-Марка Вовчка 13А Кошик;Суми вул. Марка Вовчка, 13А;80f55a5e-749b-11df-b112-00215aee3ebe
0dfd97bf-5b99-11ed-80eb-000c29800ae7;Суми-Металургів 13 Ломбард;Суми вул. Металургів, 13;80f55a5e-749b-11df-b112-00215aee3ebe
4a299333-c9c3-11ee-80c4-000c2961d091;Суми-Набережна Річки Стрілки 10А Жжук;Суми наб. Набережна Річки Стрілки, 10А;80f55a5e-749b-11df-b112-00215aee3ebe
8947a3b3-416e-11ec-80e3-000c29800ae7;Суми-Нахімова 30 Кошик;Суми вул. Олександра Коваленка ( Нахімова ), 30;80f55a5e-749b-11df-b112-00215aee3ebe
773d6110-b6f2-11eb-80d4-000c29800ae7;Суми-Охтирська 27 Смайл;Суми вул. Охтирська, 27;80f55a5e-749b-11df-b112-00215aee3ebe
2e14264c-4d7d-11ea-80c6-000c29800ae7;Суми-Охтирська 33А Колобочок;Суми вул. Охтирська, 33А;80f55a5e-749b-11df-b112-00215aee3ebe
f63a2d0d-0c3d-11ee-80bd-000c2961d091;Суми-Перемоги 115 Rozetka;Суми вул. Українських Перемог (Перемоги), 115;80f55a5e-749b-11df-b112-00215aee3ebe
6a36f517-517f-11ee-80c0-000c2961d091;Суми-Петропавлівська 61, оф.19 Сервісний Центр;Суми вул. Петропавлівська, 61, оф.19;80f55a5e-749b-11df-b112-00215aee3ebe
eb8ece93-0c19-11ee-80bd-000c2961d091;Суми-Петропавлівська 76 Rozetka;Суми вул. Петропавлівська, 76;80f55a5e-749b-11df-b112-00215aee3ebe
25d19779-416d-11ec-80e3-000c29800ae7;Суми-Покровська 3 Кошик;Суми пл. Покровська, 3;80f55a5e-749b-11df-b112-00215aee3ebe
ca9efba4-ee8f-11ea-80cc-000c29800ae7;Суми-Покровська 5 клуб віртуальної реальності;Суми пл. Покровська, 5;80f55a5e-749b-11df-b112-00215aee3ebe
e7791db9-69c1-11ea-80c6-000c29800ae7;Суми-Пушкіна 55 Сервісний Центр;Суми вул. Пушкіна, 55;80f55a5e-749b-11df-b112-00215aee3ebe
8ef90e7d-416f-11ec-80e3-000c29800ae7;Суми-Революції Гідності 26 Кошик;Суми вул. Революції Гідності ( Супруна ), 26;80f55a5e-749b-11df-b112-00215aee3ebe
441bab9f-416e-11ec-80e3-000c29800ae7;Суми-Реміснича 10/1 Кошик;Суми вул. Реміснича, 10/1;80f55a5e-749b-11df-b112-00215aee3ebe
af48da57-ccbe-11ee-80c4-000c2961d091;Суми-Родини Линтварьових 66 продукти Мікс;Суми вул. Родини Линтварьових (Орджонікідзе), 66;80f55a5e-749b-11df-b112-00215aee3ebe
fdd2e8e7-a7c1-11e9-80e4-1c98ec135261;Суми-Роменська 100 ОККО;Суми вул. Роменська, 100;80f55a5e-749b-11df-b112-00215aee3ebe
db9c1275-0ab7-11e9-80d9-1c98ec135261;Суми-Роменська 81 Канцтовари;Суми вул. Роменська, 81;80f55a5e-749b-11df-b112-00215aee3ebe
cb41d2d1-416e-11ec-80e3-000c29800ae7;Суми-Свободи /Прокоф'єва 32 Кошик;Суми Просп. Свободи /Прокоф'єва, 32;80f55a5e-749b-11df-b112-00215aee3ebe
399957f7-da09-11ee-80c4-000c2961d091;Суми-Соборна 44 lifecell;Суми вул. Соборна, 44;80f55a5e-749b-11df-b112-00215aee3ebe
03638ae8-416e-11ec-80e3-000c29800ae7;Суми-Троїцька 21 Кошик;Суми вул. Троїцька, 21;80f55a5e-749b-11df-b112-00215aee3ebe
efca8987-fc90-11e8-80d9-1c98ec135261;Суми-Харківська 106 Все для води;Суми вул. Харківська, 106;80f55a5e-749b-11df-b112-00215aee3ebe
fea0eddb-f25e-11ec-80e7-000c29800ae7;Суми-Харківська 2/1 Rozetka;Суми вул. Харківська, 2/1;80f55a5e-749b-11df-b112-00215aee3ebe
84b816e3-b91b-11ee-80c4-000c2961d091;Суми-Харківська 2/2 ZERMON;Суми вул. Харківська, 2/2;80f55a5e-749b-11df-b112-00215aee3ebe
7032535f-764b-11ee-80c1-000c2961d091;Суми-Харківська 2/2 Сільпо;Суми вул. Харківська, 2/2;80f55a5e-749b-11df-b112-00215aee3ebe
c73bf067-5b98-11ed-80eb-000c29800ae7;Суми-Харківська 38 Ломбард;Суми вул. Харківська, 38;80f55a5e-749b-11df-b112-00215aee3ebe
88d1dfc4-a7c2-11e9-80e4-1c98ec135261;Суми-Харківська 79 ОККО;Суми вул. Харківська, 79;80f55a5e-749b-11df-b112-00215aee3ebe
cf806b28-1bb2-11ec-80df-000c29800ae7;Супрунівка-Миру 49/1;Супрунівка вул. Миру, 49/1;80f55a6d-749b-11df-b112-00215aee3ebe
33da7390-3e24-11ec-80e3-000c29800ae7;Супрунівка-Нафтовиків 11Б;Супрунівка вул. Нафтовиків, 11Б;80f55a6d-749b-11df-b112-00215aee3ebe
ccbf41ea-d271-11ea-80ca-000c29800ae7;Суховерхів-Центральна 51;Суховерхів вул. Центральна, 51;80f55abc-749b-11df-b112-00215aee3ebe
2e146f61-22b6-11ec-80df-000c29800ae7;Суховоля-Липова 13;Суховоля вул. Липова, 13;80f55ac2-749b-11df-b112-00215aee3ebe
56e6daf6-e7f7-11df-a987-00215aee3ebe;СЦ СП 'Росан'(Львів) ;Львів вул. Зелена, 147;62c3d54a-749b-11df-b112-00215aee3ebe
d7de3ef7-adf3-11ed-80ec-000c29800ae7;СЦ СП 'Росан'(Львів) №2;Львів вул. Зелена, 147;62c3d54a-749b-11df-b112-00215aee3ebe
216e2e6c-e36f-11e8-80d7-1c98ec135261;Тальне-Гагаріна 91;Тальне вул. Захисників України (Гагаріна), 91;80f55b36-749b-11df-b112-00215aee3ebe
5abf1898-2b9c-11ee-80bd-000c2961d091;Таранівка-Харківська 20А;Таранівка вул. Харківська, 20А;80f55b4b-749b-11df-b112-00215aee3ebe
807a5249-ed9a-11ed-80ed-000c29800ae7;Тараща-Хмельницького Богдана 14А;Тараща вул. Хмельницького Богдана, 14А;80f55baa-749b-11df-b112-00215aee3ebe
cfa77cf7-ba86-11eb-80d4-000c29800ae7;Тараща-Хмельницького Богдана 56А;Тараща вул. Хмельницького Богдана, 56А;80f55baa-749b-11df-b112-00215aee3ebe
bda811b6-4254-11ee-80c0-000c2961d091;Татарбунари-23-го Серпня 13 Ломбард;Татарбунари вул. 23-го Серпня, 13;80f55bbf-749b-11df-b112-00215aee3ebe
2f73b0a2-be15-11eb-80d5-000c29800ae7;Татарбунари-Горького 16;Татарбунари вул. Горького, 16;80f55bbf-749b-11df-b112-00215aee3ebe
8d96cb90-1404-11ee-80bd-000c2961d091;Татарбунари-Тура 13Д;Татарбунари вул. Тура, 13Д;80f55bbf-749b-11df-b112-00215aee3ebe
cb22667d-a7a0-11e9-80e4-1c98ec135261;Татарів-Незалежності 754;Татарів вул. Незалежності, 754;c4122e07-9729-11e8-80d4-1c98ec135261
b8a32e6f-cad5-11e8-80d7-1c98ec135261;Теофіполь-Садовий 27;Теофіполь пров. Садовий, 27;80f55c00-749b-11df-b112-00215aee3ebe
b1976ca4-be27-11eb-80d5-000c29800ae7;Теофіполь-Хмельницького Богдана 1;Теофіполь вул. Хмельницького Богдана, 1;80f55c00-749b-11df-b112-00215aee3ebe
1b02a1dc-becd-11eb-80d5-000c29800ae7;Теплик-Незалежності 28;Теплик вул. Незалежності, 28;80f55c07-749b-11df-b112-00215aee3ebe
a14b656f-122c-11ec-80da-000c29800ae7;Теплодар-Комунальна 8В;Теплодар вул. Комунальна, 8В;80f55c0f-749b-11df-b112-00215aee3ebe
85b54647-764d-11ee-80c1-000c2961d091;Теплодар-Піонерська 1;Теплодар вул. Піонерська, 1;80f55c0f-749b-11df-b112-00215aee3ebe
eab27252-1fc1-11ee-80bd-000c2961d091;Теребовля-22-го Січня 17;Теребовля вул. 22-го Січня, 17;80f55c17-749b-11df-b112-00215aee3ebe
75226e1c-2315-11e9-80dc-1c98ec135261;Теребовля-Князя Василька 121;Теребовля вул. Князя Василька, 121;80f55c17-749b-11df-b112-00215aee3ebe
a194048e-051a-11ee-80bd-000c2961d091;Теребовля-Князя Василька 73А;Теребовля вул. Князя Василька, 73А;80f55c17-749b-11df-b112-00215aee3ebe
e624d4ac-887a-11ee-80c1-000c2961d091;Теребовля-Князя Василька 87;Теребовля вул. Князя Василька, 87;80f55c17-749b-11df-b112-00215aee3ebe
d4253329-1bb1-11ec-80df-000c29800ae7;Терешки-Шевченка 3;Терешки вул. Шевченка, 3;80f55c25-749b-11df-b112-00215aee3ebe
691072c5-1c56-11ec-80df-000c29800ae7;Тернівка-Героїв Космосу 5А;Тернівка бул. Героїв Космосу, 5А;80f55c45-749b-11df-b112-00215aee3ebe
e96596f9-1c56-11ec-80df-000c29800ae7;Тернівка-Перемоги 17Б;Тернівка вул. Перемоги, 17Б;80f55c45-749b-11df-b112-00215aee3ebe
057965e7-2618-11ee-80bd-000c2961d091;Тернівка-Харківська 5 Rozetka;Тернівка вул. Харківська, 5;80f55c45-749b-11df-b112-00215aee3ebe
75ec2992-a8dd-11de-bac3-0030485903e8;Тернопіль;Тернопіль вул. Гайова, 64;80f55c6a-749b-11df-b112-00215aee3ebe
e3b7ed7a-303a-11e9-80dd-1c98ec135261;Тернопіль-15 Квітня 14 ОККО;Тернопіль вул. 15 Квітня, 14;80f55c6a-749b-11df-b112-00215aee3ebe
348eb173-7652-11ee-80c1-000c2961d091;Тернопіль-15 Квітня 5А Сільпо;Тернопіль вул. 15 Квітня, 5А;80f55c6a-749b-11df-b112-00215aee3ebe
b0a1a3d2-b6d5-11ea-80c8-000c29800ae7;Тернопіль-15 Квітня 7А Healthy Shop Ternopil;Тернопіль вул. 15 Квітня, 7А;80f55c6a-749b-11df-b112-00215aee3ebe
df1f53d0-31f3-11ee-80bd-000c2961d091;Тернопіль-15 Квітня Rozetka;Тернопіль вул. 15 Квітня,;80f55c6a-749b-11df-b112-00215aee3ebe
6e380b4d-61a6-11ec-80e4-000c29800ae7;Тернопіль-Бандери Степана 27А Сім23;Тернопіль Просп. Бандери Степана, 27А;80f55c6a-749b-11df-b112-00215aee3ebe
0e3fae04-7179-11e9-80df-1c98ec135261;Тернопіль-Бандери Степана 33 Приорітет;Тернопіль Просп. Бандери Степана, 33;80f55c6a-749b-11df-b112-00215aee3ebe
1b655b95-e51c-11e9-80e6-1c98ec135261;Тернопіль-Бандери Степана 4 Сім23;Тернопіль Просп. Бандери Степана, 4;80f55c6a-749b-11df-b112-00215aee3ebe
57d2db99-fa1e-11ed-80ed-000c29800ae7;Тернопіль-Бандери Степана 90 Rozetka;Тернопіль вул. Бандери Степана, 90;80f55c6a-749b-11df-b112-00215aee3ebe
6e80c21d-61bb-11ed-80eb-000c29800ae7;Тернопіль-Бандери Степана 90 Сім23;Тернопіль вул. Бандери Степана, 90;80f55c6a-749b-11df-b112-00215aee3ebe
be0006b8-eff5-11ed-80ed-000c29800ae7;Тернопіль-Бережанська 53 Розвиваючі іграшки;Тернопіль вул. Бережанська, 53;80f55c6a-749b-11df-b112-00215aee3ebe
beed21dc-20b9-11e9-80dc-1c98ec135261;Тернопіль-Будного Степана 40 ОККО;Тернопіль вул. Будного Степана, 40;80f55c6a-749b-11df-b112-00215aee3ebe
0095285e-7fc6-11ee-80c1-000c2961d091;Тернопіль-Вербицького Архітектора 16 Домашній;Тернопіль вул. Вербицького Архітектора, 16;80f55c6a-749b-11df-b112-00215aee3ebe
4951af74-819b-11eb-80d2-000c29800ae7;Тернопіль-Весела 1 Старий Парк;Тернопіль вул. Весела, 1;80f55c6a-749b-11df-b112-00215aee3ebe
4babfce7-56e6-11ee-80c0-000c2961d091;Тернопіль-Винниченка 8А Сімі;Тернопіль вул. Винниченка, 8А;80f55c6a-749b-11df-b112-00215aee3ebe
7bcd225c-f2a6-11ea-80cc-000c29800ae7;Тернопіль-Вишневецького Дмитра 1 Сім23;Тернопіль бул. Вишневецького Дмитра, 1;80f55c6a-749b-11df-b112-00215aee3ebe
dedf0ddc-5a14-11ed-80eb-000c29800ae7;Тернопіль-Вільхова 11А маркет Сімі;Тернопіль вул. Вільхова, 11А;80f55c6a-749b-11df-b112-00215aee3ebe
61866b82-dbc5-11ee-80c4-000c2961d091;Тернопіль-Володимира Великого 17 Шопер;Тернопіль вул. Володимира Великого, 17;80f55c6a-749b-11df-b112-00215aee3ebe
31b74710-382d-11ee-80c0-000c2961d091;Тернопіль-Галицька 7Д Ozon Shop;Тернопіль вул. Галицька, 7Д;80f55c6a-749b-11df-b112-00215aee3ebe
ae1ea125-40fb-11ea-80c5-000c29800ae7;Тернопіль-Героїв Євромайдану 6А Сім23;Тернопіль пл. Героїв Євромайдану, 6А;80f55c6a-749b-11df-b112-00215aee3ebe
57e545ae-842e-11e8-80d4-1c98ec135261;Тернопіль-Глиняна 18 М-Авто;Тернопіль вул. Глиняна, 18;80f55c6a-749b-11df-b112-00215aee3ebe
9c5c9345-e515-11e9-80e6-1c98ec135261;Тернопіль-Грушевського 5 Сім23;Тернопіль вул. Грушевського, 5;80f55c6a-749b-11df-b112-00215aee3ebe
a25724f6-5543-11ed-80eb-000c29800ae7;Тернопіль-Енергетична 5А Energy Food;Тернопіль вул. Енергетична, 5А;80f55c6a-749b-11df-b112-00215aee3ebe
0858356a-15bc-11ee-80bd-000c2961d091;Тернопіль-Замонастирська 8 Компас;Тернопіль вул. Замонастирська, 8;80f55c6a-749b-11df-b112-00215aee3ebe
1a0c3fb6-7164-11e9-80df-1c98ec135261;Тернопіль-Захисників України 5 Сім23;Тернопіль вул. Захисників України (к.Пушкіна), 5;80f55c6a-749b-11df-b112-00215aee3ebe
e1c5f94f-20ba-11e9-80dc-1c98ec135261;Тернопіль-Збаразьке кільце 1 ОККО;Тернопіль вул. Збаразьке кільце, 1;80f55c6a-749b-11df-b112-00215aee3ebe
8a47f8c7-e51f-11e9-80e6-1c98ec135261;Тернопіль-Злуки 1 Сім23;Тернопіль Просп. Злуки, 1;80f55c6a-749b-11df-b112-00215aee3ebe
199e28ce-1fd3-11ee-80bd-000c2961d091;Тернопіль-Злуки 18 Rozetka;Тернопіль Просп. Злуки, 18;80f55c6a-749b-11df-b112-00215aee3ebe
4c3d8377-d431-11e7-80cc-1c98ec135261;Тернопіль-Карпенка 5/А Ремонт комп. тех.;Тернопіль вул. Карпенка, 5/А;80f55c6a-749b-11df-b112-00215aee3ebe
2fddca13-85dc-11ed-80ec-000c29800ae7;Тернопіль-Качали 18 Хостел;Тернопіль вул. Качали, 18;80f55c6a-749b-11df-b112-00215aee3ebe
d0b5142a-d430-11e7-80cc-1c98ec135261;Тернопіль-Київська 7Д Guru;Тернопіль вул. Київська, 7Д;80f55c6a-749b-11df-b112-00215aee3ebe
7c99892a-bb91-11ea-80ca-000c29800ae7;Тернопіль-Київська 9А Сім23;Тернопіль вул. Київська, 9А;80f55c6a-749b-11df-b112-00215aee3ebe
ff581d4b-ccc4-11ee-80c4-000c2961d091;Тернопіль-Корольова 4А Сонечко;Тернопіль вул. Корольова, 4А;80f55c6a-749b-11df-b112-00215aee3ebe
3d7d7870-d42e-11e7-80cc-1c98ec135261;Тернопіль-Кривоноса Максима 12 електроніки МКТ;Тернопіль вул. Кривоноса Максима, 12;80f55c6a-749b-11df-b112-00215aee3ebe
c0551743-7650-11ee-80c1-000c2961d091;Тернопіль-Кривоноса Максима 2Б Сільпо;Тернопіль вул. Кривоноса Максима, 2Б;80f55c6a-749b-11df-b112-00215aee3ebe
4fb66c84-fec8-11ed-80ed-000c29800ae7;Тернопіль-Крушельницької Соломії 53 СПЕЦ;Тернопіль вул. Крушельницької Соломії, 53;80f55c6a-749b-11df-b112-00215aee3ebe
40c9661b-90cf-11e8-80d4-1c98ec135261;Тернопіль-Купчинського 14 Твій малюк;Тернопіль вул. Купчинського, 14;80f55c6a-749b-11df-b112-00215aee3ebe
28bd2914-6890-11ec-80e4-000c29800ae7;Тернопіль-Купчинського 7А Сім23;Тернопіль вул. Купчинського, 7А;80f55c6a-749b-11df-b112-00215aee3ebe
9476e51f-158b-11e8-80ce-1c98ec135261;Тернопіль-Курбаса Леся 9А Господар;Тернопіль вул. Курбаса Леся, 9А;80f55c6a-749b-11df-b112-00215aee3ebe
675bf543-dab5-11ed-80ed-000c29800ae7;Тернопіль-Мазепи 1 СПЕЦ;Тернопіль вул. Мазепи, 1;80f55c6a-749b-11df-b112-00215aee3ebe
dc65c431-b7f6-11e8-80d4-1c98ec135261;Тернопіль-Медова 21 СЦ Data Service;Тернопіль вул. Медова, 21;80f55c6a-749b-11df-b112-00215aee3ebe
c1443855-2313-11e9-80dc-1c98ec135261;Тернопіль-Микулинецька 40Б ОККО;Тернопіль вул. Микулинецька, 40Б;80f55c6a-749b-11df-b112-00215aee3ebe
0c0d01f2-7166-11e9-80df-1c98ec135261;Тернопіль-Миру 5 Сім23;Тернопіль вул. Миру, 5;80f55c6a-749b-11df-b112-00215aee3ebe
ba0a6b41-7e1d-11ee-80c1-000c2961d091;Тернопіль-Наливайка 1Д/9 Rozetka;Тернопіль вул. Наливайка, 1Д/9;80f55c6a-749b-11df-b112-00215aee3ebe
4c530a86-05e2-11ee-80bd-000c2961d091;Тернопіль-Патріарха Любомира Гузара 1 маркет Сімі;Тернопіль вул. Патріарха Любомира Гузара (к.Чалдаєва), 1;80f55c6a-749b-11df-b112-00215aee3ebe
167ec74f-5f50-11e9-80df-1c98ec135261;Тернопіль-Патріарха Мстислава 2/1 Магніт;Тернопіль вул. Патріарха Мстислава, 2/1;80f55c6a-749b-11df-b112-00215aee3ebe
5c773be5-56e5-11ee-80c0-000c2961d091;Тернопіль-Перемоги 2 Сімі;Тернопіль Майдан Перемоги, 2;80f55c6a-749b-11df-b112-00215aee3ebe
ea1ce72d-18be-11ed-80e7-000c29800ae7;Тернопіль-Перемоги 4 Rozetka;Тернопіль Майдан Перемоги, 4;80f55c6a-749b-11df-b112-00215aee3ebe
d9309567-7651-11ee-80c1-000c2961d091;Тернопіль-Петлюри 2Б Сільпо;Тернопіль вул. Петлюри, 2Б;80f55c6a-749b-11df-b112-00215aee3ebe
bec174a5-2efd-11eb-80ce-000c29800ae7;Тернопіль-Покрови 14А Люран;Тернопіль вул. Покрови (к.Бригадна 12-26), 14А;80f55c6a-749b-11df-b112-00215aee3ebe
f0b92cab-e51e-11e9-80e6-1c98ec135261;Тернопіль-Просвіта 19 Сім23;Тернопіль бул. Просвіта, 19;80f55c6a-749b-11df-b112-00215aee3ebe
e34f93bc-285f-11ed-80e7-000c29800ae7;Тернопіль-Руська 23 Lifecell;Тернопіль вул. Руська, 23;80f55c6a-749b-11df-b112-00215aee3ebe
8e1c3b65-26b0-11ec-80e3-000c29800ae7;Тернопіль-Руська 24 Сім23;Тернопіль вул. Руська, 24;80f55c6a-749b-11df-b112-00215aee3ebe
8dfc4509-7165-11e9-80df-1c98ec135261;Тернопіль-Руська 26 Сім23;Тернопіль вул. Руська, 26;80f55c6a-749b-11df-b112-00215aee3ebe
b5a7f3ae-7166-11e9-80df-1c98ec135261;Тернопіль-Симоненка 5А Сім23;Тернопіль вул. Симоненка, 5А;80f55c6a-749b-11df-b112-00215aee3ebe
5a579265-43b5-11e8-80d0-1c98ec135261;Тернопіль-Слівенська 15 Кубік;Тернопіль вул. Слівенська, 15;80f55c6a-749b-11df-b112-00215aee3ebe
a6050266-eff6-11ed-80ed-000c29800ae7;Тернопіль-Слівенська 3 Книжковий магазин;Тернопіль вул. Слівенська, 3;80f55c6a-749b-11df-b112-00215aee3ebe
e0547eec-f5bb-11eb-80d5-000c29800ae7;Тернопіль-Старий Поділ 16 Візи;Тернопіль вул. Старий Поділ (к.Танцорова), 16;80f55c6a-749b-11df-b112-00215aee3ebe
12a6dcb7-e0a4-11eb-80d5-000c29800ae7;Тернопіль-Старий Поділ 22 Електронні системи;Тернопіль вул. Старий Поділ (к.Танцорова), 22;80f55c6a-749b-11df-b112-00215aee3ebe
422f09df-847f-11e8-80d4-1c98ec135261;Тернопіль-Стуса Василя 1 На звязку;Тернопіль вул. Стуса Василя, 1;80f55c6a-749b-11df-b112-00215aee3ebe
4d2ca642-eff6-11ed-80ed-000c29800ae7;Тернопіль-Тарнавського 11 Розвиваючі іграшки;Тернопіль вул. Тарнавського, 11;80f55c6a-749b-11df-b112-00215aee3ebe
c970d06d-e51d-11e9-80e6-1c98ec135261;Тернопіль-Тарнавського 2А Сім23;Тернопіль вул. Тарнавського, 2А;80f55c6a-749b-11df-b112-00215aee3ebe
7ec590e0-f177-11ec-80e7-000c29800ae7;Тернопіль-Тарнавського 34 Rozetka;Тернопіль вул. Тарнавського, 34;80f55c6a-749b-11df-b112-00215aee3ebe
983136a6-86cc-11e9-80e0-1c98ec135261;Тернопіль-Текстильна 16 Музична крамниця ЗІМО;Тернопіль вул. Текстильна, 16;80f55c6a-749b-11df-b112-00215aee3ebe
82770e66-15c4-11ee-80bd-000c2961d091;Тернопіль-Текстильна 1А АЗС маркет;Тернопіль вул. Текстильна, 1А;80f55c6a-749b-11df-b112-00215aee3ebe
17010465-7651-11ee-80c1-000c2961d091;Тернопіль-Текстильна 28 Сільпо;Тернопіль вул. Текстильна, 28;80f55c6a-749b-11df-b112-00215aee3ebe
50f9e8f3-2313-11e9-80dc-1c98ec135261;Тернопіль-Торговиця 15 ОККО;Тернопіль вул. Торговиця (к. Живова ), 15;80f55c6a-749b-11df-b112-00215aee3ebe
084ae6b2-7650-11ee-80c1-000c2961d091;Тернопіль-Торговиця 15А Сільпо;Тернопіль вул. Торговиця (к. Живова ), 15А;80f55c6a-749b-11df-b112-00215aee3ebe
75e684d2-cf0c-11ee-80c4-000c2961d091;Тернопіль-Тролейбусна 17 Rozetka;Тернопіль вул. Тролейбусна, 17;80f55c6a-749b-11df-b112-00215aee3ebe
be52993b-6e8c-11e1-bcbd-003048d2b473;Тернопіль-Хмельницького Богдана 12 СПЕЦ;Тернопіль вул. Хмельницького Богдана, 12;80f55c6a-749b-11df-b112-00215aee3ebe
db01e143-85da-11ed-80ec-000c29800ae7;Тернопіль-Чернівецька 53 Pixel;Тернопіль вул. Чернівецька, 53;80f55c6a-749b-11df-b112-00215aee3ebe
67701bb8-8996-11e8-80d4-1c98ec135261;Тернопіль-Шашкевича 2 MAX SERVICE;Тернопіль вул. Шашкевича, 2;80f55c6a-749b-11df-b112-00215aee3ebe
e4d4a1c9-f177-11ec-80e7-000c29800ae7;Тернопіль-Шептицького 20 Rozetka;Тернопіль вул. Шептицького, 20;80f55c6a-749b-11df-b112-00215aee3ebe
d61cb531-7164-11e9-80df-1c98ec135261;Тернопіль-Шептицького 5А Сім23;Тернопіль вул. Шептицького, 5А;80f55c6a-749b-11df-b112-00215aee3ebe
4d0eaa3c-e837-11e4-b9c8-003048d2b473;Тестовий Офіс Аптека 911 Поштомат №1796 ;Київ Просп. Берестейський (к.Перемоги (Проспект)), 22;5cb61671-749b-11df-b112-00215aee3ebe
05be339a-a53c-11e3-aaa4-003048d2b473;ТЕСТУВАННЯ Point24 ;Львів пл. тест, 9 3/4/5/6;62c3d54a-749b-11df-b112-00215aee3ebe
3470b1ac-2a83-11ec-80e3-000c29800ae7;Тетіїв-Байраковського 72;Тетіїв вул. Байраковського, 72;80f55c89-749b-11df-b112-00215aee3ebe
53ddae32-ba88-11eb-80d4-000c29800ae7;Тетіїв-Соборна 24В;Тетіїв вул. Соборна, 24В;80f55c89-749b-11df-b112-00215aee3ebe
26b0a352-efd6-11ed-80ed-000c29800ae7;Тетіїв-Цвіткова 2;Тетіїв вул. Цвіткова, 2;80f55c89-749b-11df-b112-00215aee3ebe
c3e218ee-30d3-11ec-80e3-000c29800ae7;Тиврів-Слобожанська 5;Тиврів вул. Слобожанська, 5;80f55c93-749b-11df-b112-00215aee3ebe
8f849e6b-688f-11ec-80e4-000c29800ae7;Тисмениця-Галицька 21 Сім23;Тисмениця вул. Галицька, 21;80f55cc4-749b-11df-b112-00215aee3ebe
0abe792b-40f9-11e9-80dd-1c98ec135261;Тлумач-Кобилянської Ольги 1;Тлумач вул. Кобилянської Ольги, 1;80f55d02-749b-11df-b112-00215aee3ebe
1fdd33f1-3e88-11e9-80dd-1c98ec135261;Тлумач-Шевченка 6/2;Тлумач вул. Шевченка, 6/2;80f55d02-749b-11df-b112-00215aee3ebe
37230acc-90c2-11e4-b90b-003048d2b473;ТНТ Україна – Бориспіль;;44ac85c0-749b-11df-b112-00215aee3ebe
e2fbbd69-f6bb-11eb-80d5-000c29800ae7;Томаківка-Шевченка 48/3А;Томаківка вул. Шевченка, 48/3А;80f55d37-749b-11df-b112-00215aee3ebe
5278a99b-2e43-11eb-80ce-000c29800ae7;Томашгород-Залізнична 2;Томашгород вул. Залізнична, 2;80f55d3c-749b-11df-b112-00215aee3ebe
e30fc4ba-3004-11eb-80ce-000c29800ae7;Томашпіль-Гаврилюка 5;Томашпіль вул. Гаврилюка, 5;80f55d48-749b-11df-b112-00215aee3ebe
da6bef8c-becd-11eb-80d5-000c29800ae7;Томашпіль-Леонтовича 10;Томашпіль вул. Леонтовича, 10;80f55d48-749b-11df-b112-00215aee3ebe
c2c5bf15-3204-11ee-80bd-000c2961d091;Томашпіль-Шевченка 7;Томашпіль вул. Шевченка, 7;80f55d48-749b-11df-b112-00215aee3ebe
07022311-7745-11eb-80d1-000c29800ae7;Торчин-Незалежності 74;Торчин вул. Незалежності, 74;80f55d95-749b-11df-b112-00215aee3ebe
2ed86c94-c80e-11ee-80c4-000c2961d091;Торчин-Незалежності 84;Торчин вул. Незалежності, 84;80f55d95-749b-11df-b112-00215aee3ebe
949b4b23-af72-11e9-80e4-1c98ec135261;Торчин-Незалежності 98;Торчин вул. Незалежності, 98;80f55d95-749b-11df-b112-00215aee3ebe
29f0b8f0-71b3-11ee-80c1-000c2961d091;Транспортний відділ РГ;;00000000-0000-0000-0000-000000000000
3e6fcf94-774a-11ee-80c1-000c2961d091;Требухів-Садова 1А;Требухів вул. Садова, 1А;8716200f-749b-11df-b112-00215aee3ebe
caa451dd-3d6a-11ec-80e3-000c29800ae7;Тростянець-Вознесенська 3;Тростянець вул. Вознесенська, 3;8716205e-749b-11df-b112-00215aee3ebe
e8853884-cff9-11ee-80c4-000c2961d091;Тростянець-Соборна 26;Тростянець вул. Соборна, 26;8716206c-749b-11df-b112-00215aee3ebe
3b15cca8-1890-11ed-80e7-000c29800ae7;Тростянець-Соборна 50;Тростянець вул. Соборна, 50;8716206c-749b-11df-b112-00215aee3ebe
d53d4e3b-5601-11ed-80eb-000c29800ae7;Тростянець-Татаренка 69;Тростянець вул. Татаренка, 69;8716205e-749b-11df-b112-00215aee3ebe
0dd84e51-22ef-11e9-80dc-1c98ec135261;Тростянець-Урочище Трикутник 2;Тростянець урочище Трикутник, 2;87162066-749b-11df-b112-00215aee3ebe
857d7e40-f0d9-11ed-80ed-000c29800ae7;Трускавець-Сагайдачного 16;Трускавець вул. Сагайдачного, 16;871620d9-749b-11df-b112-00215aee3ebe
3a1d817b-7653-11ee-80c1-000c2961d091;Трускавець-Стебницька 43;Трускавець вул. Стебницька, 43;871620d9-749b-11df-b112-00215aee3ebe
88b6db54-0c3e-11ee-80bd-000c2961d091;Трускавець-Стебницька 43А;Трускавець вул. Стебницька, 43А;871620d9-749b-11df-b112-00215aee3ebe
be2dc8f4-c91d-11eb-80d5-000c29800ae7;Трускавець-Українки Лесі 3;Трускавець вул. Українки Лесі, 3;871620d9-749b-11df-b112-00215aee3ebe
126f8cdf-3137-11ee-80bd-000c2961d091;Тульчин-Леонтовича 48;Тульчин вул. Леонтовича, 48А;871620f3-749b-11df-b112-00215aee3ebe
2be77efc-bed0-11eb-80d5-000c29800ae7;Турбів-Маяковського 2;Турбів вул. Маяковського, 2;87162103-749b-11df-b112-00215aee3ebe
21b7a161-a708-11e9-80e2-1c98ec135261;Турбів-Миру 49 А;Турбів вул. Миру, 49А;87162103-749b-11df-b112-00215aee3ebe
b2a77559-be14-11eb-80d5-000c29800ae7;Турійськ-Ковельська 3;Турійськ вул. Ковельська, 3;87162119-749b-11df-b112-00215aee3ebe
fe8379d3-f95c-11ed-80ed-000c29800ae7;Турійськ-Луцька 6;Турійськ вул. Луцька, 6;87162119-749b-11df-b112-00215aee3ebe
d44d5fbe-9ff5-11ee-80c4-000c2961d091;Турка-Міцкевича Адама 14;Турка вул. Міцкевича Адама, 14;8716211e-749b-11df-b112-00215aee3ebe
12a67533-329c-11e8-80d0-1c98ec135261;Турка-Міцкевича Адама,14-А;Турка вул. Міцкевича Адама, 14А;8716211e-749b-11df-b112-00215aee3ebe
b0a28a7e-be0c-11eb-80d5-000c29800ae7;Тязів-Українки Лесі 40;Тязів вул. Українки Лесі, 40;87162155-749b-11df-b112-00215aee3ebe
1cfa8c74-5d20-11ea-80c6-000c29800ae7;Тячів;Тячів вул. Лазівська, 2;cdc44f5c-dde8-11df-9197-00215aee3ebe
f671c05a-f8e5-11eb-80d5-000c29800ae7;Тячів-Незалежності 25;Тячів вул. Незалежності, 25;cdc44f5c-dde8-11df-9197-00215aee3ebe
9fc5acfd-f94b-11ed-80ed-000c29800ae7;Тячів-Незалежності 33/2;Тячів вул. Незалежності, 33/2;cdc44f5c-dde8-11df-9197-00215aee3ebe
53886c3a-7845-11eb-80d1-000c29800ae7;Тячів-Незалежності 39;Тячів вул. Незалежності, 39;cdc44f5c-dde8-11df-9197-00215aee3ebe
0b90967b-d8c0-11ea-80ca-000c29800ae7;Тячів-Польова 2 А;Тячів вул. Польова, 2А;cdc44f5c-dde8-11df-9197-00215aee3ebe
103669cc-a799-11e9-80e4-1c98ec135261;Угринів-Галицька 23А ОККО;Угринів вул. Галицька, 23А;87162175-749b-11df-b112-00215aee3ebe
9e40dc6a-010e-11df-b41f-00215aee3ebe;Ужгород;Ужгород вул. Блистіва ( Краснодонців ), 1;8716218d-749b-11df-b112-00215aee3ebe
02bb1611-b2e5-11e9-80e4-1c98ec135261;Ужгород-Бабяка Миколи 5 ОККО;Ужгород вул. Бабяка Миколи, 5;8716218d-749b-11df-b112-00215aee3ebe
455cdf89-ff90-11ed-80bc-000c2961d091;Ужгород-Бородіна 24 Бутор-Плит;Ужгород вул. Бородіна, 24;8716218d-749b-11df-b112-00215aee3ebe
09625f49-b2e6-11e9-80e4-1c98ec135261;Ужгород-Гагаріна 139 ОККО;Ужгород вул. Гагаріна, 139;8716218d-749b-11df-b112-00215aee3ebe
22bce52d-e6b1-11e9-80e7-1c98ec135261;Ужгород-Грушевського 67 Ломбард;Ужгород вул. Грушевського, 67;8716218d-749b-11df-b112-00215aee3ebe
853e8182-0130-11ee-80bd-000c2961d091;Ужгород-Закарпатська 47 Rozetka;Ужгород вул. Закарпатська, 47;8716218d-749b-11df-b112-00215aee3ebe
325690ae-8cab-11eb-80d2-000c29800ae7;Ужгород-Залізнична 1А Ломбард;Ужгород вул. Залізнична, 1А;8716218d-749b-11df-b112-00215aee3ebe
ea518a4f-f1e0-11ec-80e7-000c29800ae7;Ужгород-Заньковецької Марії 70 Rozetka;Ужгород вул. Заньковецької Марії, 70;8716218d-749b-11df-b112-00215aee3ebe
5e407d29-7654-11ee-80c1-000c2961d091;Ужгород-Капушанська 150 Сільпо;Ужгород вул. Капушанська, 150;8716218d-749b-11df-b112-00215aee3ebe
ca141a9c-1586-11ee-80bd-000c2961d091;Ужгород-Капушанська 5 СПЕЦ;Ужгород вул. Капушанська, 5;8716218d-749b-11df-b112-00215aee3ebe
43f22353-e6b3-11e9-80e7-1c98ec135261;Ужгород-Легоцького 19А Ломбард;Ужгород вул. Легоцького, 19А;8716218d-749b-11df-b112-00215aee3ebe
2e4f12e0-f8f4-11eb-80d5-000c29800ae7;Ужгород-Легоцького 19Аж Жжук;Ужгород вул. Легоцького, 19Аж;8716218d-749b-11df-b112-00215aee3ebe
a8c60c98-7653-11ee-80c1-000c2961d091;Ужгород-Минайська 16Г Сільпо;Ужгород вул. Минайська, 16Г;8716218d-749b-11df-b112-00215aee3ebe
c701832e-c755-11ee-80c4-000c2961d091;Ужгород-Минайська 18Г Rozetka;Ужгород вул. Минайська, 18Г;8716218d-749b-11df-b112-00215aee3ebe
73efe6ae-f1e1-11ec-80e7-000c29800ae7;Ужгород-Петефі Шандора 17 Rozetka;Ужгород пл. Петефі Шандора, 17;8716218d-749b-11df-b112-00215aee3ebe
a493a6a5-b2e4-11e9-80e4-1c98ec135261;Ужгород-Підградська 29 ОККО;Ужгород вул. Підградська, 29;8716218d-749b-11df-b112-00215aee3ebe
db001746-7654-11ee-80c1-000c2961d091;Ужгород-Поштова 4А Сільпо;Ужгород пл. Поштова, 4А;8716218d-749b-11df-b112-00215aee3ebe
33998b05-f951-11ed-80ed-000c29800ae7;Ужгород-Свободи 39 Lifecell;Ужгород Просп. Свободи, 39;8716218d-749b-11df-b112-00215aee3ebe
b380740c-e6b4-11e9-80e7-1c98ec135261;Ужгород-Свободи 40/83 Ломбард;Ужгород Просп. Свободи, 40/83;8716218d-749b-11df-b112-00215aee3ebe
00062dd2-7654-11ee-80c1-000c2961d091;Ужгород-Свободи Генерала 9Б Сільпо;Ужгород вул. Свободи Генерала, 9Б;8716218d-749b-11df-b112-00215aee3ebe
d694eaad-6693-11ee-80c0-000c2961d091;Ужгород-Снігурського 15 Агенство Легіон;Ужгород вул. Снігурського, 15;8716218d-749b-11df-b112-00215aee3ebe
8b7b7a63-b2e5-11e9-80e4-1c98ec135261;Ужгород-Собранецька 158А ОККО;Ужгород вул. Собранецька, 158А;8716218d-749b-11df-b112-00215aee3ebe
e52f858d-e6b3-11e9-80e7-1c98ec135261;Ужгород-Фединця 35/2 Ломбард;Ужгород вул. Фединця, 35/2;8716218d-749b-11df-b112-00215aee3ebe
244d8083-180b-11e9-80dc-1c98ec135261;Ужгород-Швабська 51 Сундучок;Ужгород вул. Швабська, 51;8716218d-749b-11df-b112-00215aee3ebe
bd746573-15c0-11ee-80bd-000c2961d091;Ужгород-Шумна 22А АЗС маркет;Ужгород вул. Шумна, 22А;8716218d-749b-11df-b112-00215aee3ebe
b52a0277-ba85-11eb-80d4-000c29800ae7;Узин-Авіаторів 71В;Узин вул. Авіаторів, 71В;87162194-749b-11df-b112-00215aee3ebe
f9c642f4-56d3-11ee-80c0-000c2961d091;Узин-Авіаторів 71Г;Узин вул. Авіаторів, 71Г;87162194-749b-11df-b112-00215aee3ebe
e1bda297-87a7-11ee-80c1-000c2961d091;Узин-Поповича 2;Узин вул. Поповича, 2;87162194-749b-11df-b112-00215aee3ebe
d6054ab4-0e99-11ee-80bd-000c2961d091;Українка-Будівельників 11;Українка вул. Будівельників, 11;871621ad-749b-11df-b112-00215aee3ebe
aa0c583d-b918-11ee-80c4-000c2961d091;Українка-Дніпровський 5;Українка Просп. Дніпровський, 5;871621ad-749b-11df-b112-00215aee3ebe
edcfcb6a-f1f0-11ec-80e7-000c29800ae7;Українка-Юності 6;Українка вул. Юності, 6;871621ad-749b-11df-b112-00215aee3ebe
69bc7a64-11a9-11e4-9beb-003048d2b473;УкрПоштаLV;;62c3d54a-749b-11df-b112-00215aee3ebe
75ec2993-a8dd-11de-bac3-0030485903e8;Умань;Умань вул. Індустріальна, 38 г;87162214-749b-11df-b112-00215aee3ebe
88ad7871-1b84-11ec-80df-000c29800ae7;Умань-24-го З'їзду КПРС 3А Делві;Умань вул. 24-го З'їзду КПРС, 3А;87162214-749b-11df-b112-00215aee3ebe
6f0f47e1-80df-11eb-80d2-000c29800ae7;Умань-Велика Фонтанна 31А Smart ON;Умань вул. Велика Фонтанна (Паризької Комуни), 31А;87162214-749b-11df-b112-00215aee3ebe
811546e1-7655-11ee-80c1-000c2961d091;Умань-Велика Фонтанна 31А Сільпо;Умань вул. Велика Фонтанна (Паризької Комуни), 31А;87162214-749b-11df-b112-00215aee3ebe
73538346-f0bb-11ec-80e7-000c29800ae7;Умань-Велика Фонтанна 31Г Rozetka;Умань вул. Велика Фонтанна (Паризької Комуни), 31Г;87162214-749b-11df-b112-00215aee3ebe
1df65aef-20ca-11ee-80bd-000c2961d091;Умань-Велика Фонтанна 32 Lifecell;Умань вул. Велика Фонтанна (Паризької Комуни), 32;87162214-749b-11df-b112-00215aee3ebe
ee016e17-3cd8-11ee-80c0-000c2961d091;Умань-Виговського 35А-9 Делві;Умань вул. Виговського (Котовського), 35А-9;87162214-749b-11df-b112-00215aee3ebe
4ea9c5c4-1b97-11ec-80df-000c29800ae7;Умань-Горького 9, кв. 2 Делві;Умань вул. В'ячеслава Чорновола ( Горького ), 9, кв. 2;87162214-749b-11df-b112-00215aee3ebe
4a80c673-1b85-11ec-80df-000c29800ae7;Умань-Грушевського 39/1 Делві;Умань вул. Грушевського (Урицького), 39/1;87162214-749b-11df-b112-00215aee3ebe
2598a108-1b96-11ec-80df-000c29800ae7;Умань-Драгоманова 27А Делві;Умань вул. Драгоманова ( Герцена ), 27А;87162214-749b-11df-b112-00215aee3ebe
e966f1d5-581d-11ec-80e4-000c29800ae7;Умань-Європейська 57 Делві;Умань вул. Європейська, 57;87162214-749b-11df-b112-00215aee3ebe
cba5824d-8cf4-11e9-80e2-1c98ec135261;Умань-Європейська 76 Телекомунікаційні послуги ТІМ;Умань вул. Європейська, 76;87162214-749b-11df-b112-00215aee3ebe
413fbf7f-5c6d-11ee-80c0-000c2961d091;Умань-Європейська 8А Vodafone;Умань вул. Європейська, 8А;87162214-749b-11df-b112-00215aee3ebe
8e264087-cfdb-11ee-80c4-000c2961d091;Умань-Київська 1А Smart ON;Умань вул. Київська, 1А;87162214-749b-11df-b112-00215aee3ebe
676bec55-1b95-11ec-80df-000c29800ae7;Умань-Кооперативна 18 Делві;Умань вул. Кооперативна, 18;87162214-749b-11df-b112-00215aee3ebe
ef8f230d-63ec-11ec-80e4-000c29800ae7;Умань-Леніна 44 KindeR;Умань вул. Леніна, 44;87162214-749b-11df-b112-00215aee3ebe
4a52f189-4703-11ee-80c0-000c2961d091;Умань-Небесної Сотні 5 Lifecell;Умань вул. Небесної Сотні (Радянська), 5;87162214-749b-11df-b112-00215aee3ebe
ba26011e-853d-11ee-80c1-000c2961d091;Умань-Незалежності 2А Rozetka;Умань вул. Незалежності (Жовтневої Революції), 2А;87162214-749b-11df-b112-00215aee3ebe
c508a64c-1b90-11ec-80df-000c29800ae7;Умань-Садова 15 Делві;Умань вул. Садова, 15;87162214-749b-11df-b112-00215aee3ebe
db5cfdf7-b36b-11e9-80e4-1c98ec135261;Умань-Шолом-Алейхема 5 ОККО;Умань вул. Шолом-Алейхема, 5;87162214-749b-11df-b112-00215aee3ebe
7ae23ca5-d8bd-11ea-80ca-000c29800ae7;Устилуг-Володимирська 3Б ОККО;Устилуг вул. Володимирська, 3Б;87162252-749b-11df-b112-00215aee3ebe
f6f35420-7981-11ee-80c1-000c2961d091;Фастів-Козацької Слави 1А;Фастів пров. Козацької Слави (Щербакова), 1А;87162298-749b-11df-b112-00215aee3ebe
fa50e085-f1f1-11ec-80e7-000c29800ae7;Фастів-Небесної Сотні (9-го Січня) 10;Фастів вул. Небесної Сотні (9-го Січня), 10;87162298-749b-11df-b112-00215aee3ebe
8bbec0b5-7656-11ee-80c1-000c2961d091;Фастів-Соборна 42;Фастів вул. Соборна, 42;87162298-749b-11df-b112-00215aee3ebe
0c33d0d7-7656-11ee-80c1-000c2961d091;Фастів-Ступака Івана 4;Фастів вул. Ступака Івана (Радянська), 4;87162298-749b-11df-b112-00215aee3ebe
6a72beb6-31f7-11ee-80bd-000c2961d091;Фастів-Шевченка 23/4;Фастів вул. Шевченка, 23/4 (стадіон);87162298-749b-11df-b112-00215aee3ebe
9336b36a-0e81-11ee-80bd-000c2961d091;Фастів-Шевченка 33;Фастів вул. Шевченка, 33;87162298-749b-11df-b112-00215aee3ebe
dbadd07f-fbbd-11e9-80e7-1c98ec135261;Фонтанка-Заставська 1;Фонтанка вул. Заставська, 1;87162318-749b-11df-b112-00215aee3ebe
75ec2994-a8dd-11de-bac3-0030485903e8;Харків;Харків пров. Ващенківський, 28Б;87162365-749b-11df-b112-00215aee3ebe
19e2645f-eb5e-11ee-80c5-000c2961d091;Харків-23 Серпня 16Б КВІТИ;Харків вул. 23 Серпня, 16Б;87162365-749b-11df-b112-00215aee3ebe
8305c016-b5e8-11ee-80c4-000c2961d091;Харків-23 Серпня 47 Andi;Харків вул. 23 Серпня, 47;87162365-749b-11df-b112-00215aee3ebe
5103ea28-0739-11ec-80d6-000c29800ae7;Харків-Академіка Вальтера 7Г Хазар;Харків вул. Академіка Вальтера, 7Г;87162365-749b-11df-b112-00215aee3ebe
aa8e02b3-4ef5-11e9-80dd-1c98ec135261;Харків-Амосова 13 Копировальный Центр;Харків вул. Амосова (Корчагінців), 13;87162365-749b-11df-b112-00215aee3ebe
c1ac9317-f57c-11ed-80ed-000c29800ae7;Харків-Архітектора Альошина 2 Канцтовари;Харків Просп. Архітектора Альошина (Орджонікідзе), 2;87162365-749b-11df-b112-00215aee3ebe
55c21d81-0737-11ec-80d6-000c29800ae7;Харків-Астрономічна 35Л Хазар;Харків вул. Астрономічна, 35Л;87162365-749b-11df-b112-00215aee3ebe
4f8919dd-eb5b-11ee-80c5-000c2961d091;Харків-Ахсарова 20 Laundry House;Харків вул. Ахсарова, 20;87162365-749b-11df-b112-00215aee3ebe
2706f0ee-e69b-11ee-80c5-000c2961d091;Харків-Бажанова Маршала 2 Laundry House;Харків вул. Бажанова Маршала, 2;87162365-749b-11df-b112-00215aee3ebe
5fc00d0c-5a38-11eb-80d0-000c29800ae7;Харків-Бажанова Маршала 3 Арбуз-Маркет;Харків вул. Бажанова Маршала, 3;87162365-749b-11df-b112-00215aee3ebe
b5e7d352-7271-11ee-80c1-000c2961d091;Харків-Бекетова 21 ITEHNO;Харків вул. Бекетова, 21;87162365-749b-11df-b112-00215aee3ebe
243d4c3e-516c-11ee-80c0-000c2961d091;Харків-Беркоса 87 MobiWorld;Харків вул. Беркоса(Довгалівська), 87;87162365-749b-11df-b112-00215aee3ebe
578b6308-2c69-11e8-80d0-1c98ec135261;Харків-Біблика 1Б Зоомікс;Харків вул. Біблика (2-ї П'ятирічки), 1Б;87162365-749b-11df-b112-00215aee3ebe
1e2df1dc-3206-11ea-80e7-1c98ec135261;Харків-Біблика 37А Ветлидер;Харків вул. Біблика (2-ї П'ятирічки), 37А;87162365-749b-11df-b112-00215aee3ebe
b3a80c21-09dc-11ed-80e7-000c29800ae7;Харків-Богомольця Академіка 1А Bell;Харків вул. Богомольця Академіка, 1А;87162365-749b-11df-b112-00215aee3ebe
e9d6673d-d236-11ee-80c4-000c2961d091;Харків-Валентинівська 20 Квіти;Харків вул. Валентинівська, 20;87162365-749b-11df-b112-00215aee3ebe
fc73278d-d62d-11ee-80c4-000c2961d091;Харків-Валентинівська 37/128 lifecell;Харків вул. Валентинівська, 37/128;87162365-749b-11df-b112-00215aee3ebe
a6a2811b-b80d-11e7-80cc-1c98ec135261;Харків-Валентинівська 37/128 Канцтовари;Харків вул. Валентинівська, 37/128;87162365-749b-11df-b112-00215aee3ebe
ac874dd1-5e8c-11ee-80c0-000c2961d091;Харків-Ващенківський, 28Б СПЕЦ;Харків пров. Ващенківський, 28Б;87162365-749b-11df-b112-00215aee3ebe
01eafb1e-39a8-11e9-80dd-1c98ec135261;Харків-Веринська 1А ОККО;Харків вул. Віринська, 1А;87162365-749b-11df-b112-00215aee3ebe
42ab151b-be88-11ee-80c4-000c2961d091;Харків-Власенка 1 Продукти;Харків вул. Власенка, 1;87162365-749b-11df-b112-00215aee3ebe
993c5c46-d175-11ee-80c4-000c2961d091;Харків-Волонтерська 74 Laundry House;Харків вул. Волонтерська (Соціалістична), 74;87162365-749b-11df-b112-00215aee3ebe
ec56ace1-4579-11ed-80eb-000c29800ae7;Харків-Гагаріна 167 Rozetka;Харків Просп. Гагаріна, 167;87162365-749b-11df-b112-00215aee3ebe
b05465c5-578f-11ee-80c0-000c2961d091;Харків-Гагаріна 167/1 Сільпо;Харків Просп. Гагаріна, 167/1;87162365-749b-11df-b112-00215aee3ebe
a1c4dbe0-893d-11ee-80c1-000c2961d091;Харків-Гагаріна 182 SIMKA;Харків Просп. Гагаріна, 178;87162365-749b-11df-b112-00215aee3ebe
9e414505-f945-11ed-80ed-000c29800ae7;Харків-Гагаріна 22 Lifecell;Харків Просп. Гагаріна, 22;87162365-749b-11df-b112-00215aee3ebe
b97b8387-b910-11ee-80c4-000c2961d091;Харків-Гагаріна 244 lifecell;Харків Просп. Гагаріна, 244;87162365-749b-11df-b112-00215aee3ebe
a1da0296-d4bd-11eb-80d5-000c29800ae7;Харків-Гагаріна 316А/1 Канцтовари;Харків Просп. Гагаріна, 316А/1;87162365-749b-11df-b112-00215aee3ebe
3ebf86de-1215-11ec-80da-000c29800ae7;Харків-Гагаріна 72 Промтовари;Харків Просп. Гагаріна, 72;87162365-749b-11df-b112-00215aee3ebe
6467aaee-39aa-11e9-80dd-1c98ec135261;Харків-Гагаріна Юрія 185 ОККО;Харків Просп. Гагаріна Юрія, 185;87162365-749b-11df-b112-00215aee3ebe
562e5dff-399f-11e9-80dd-1c98ec135261;Харків-Гвардійців-Широнінців 12А ОККО;Харків вул. Гвардійців-Широнінців, 12А;87162365-749b-11df-b112-00215aee3ebe
64cbe896-307c-11ee-80bd-000c2961d091;Харків-Гвардійців-Широнінців 33 Rozetka;Харків вул. Гвардійців-Широнінців, 33;87162365-749b-11df-b112-00215aee3ebe
a5995a10-cfcd-11ed-80ed-000c29800ae7;Харків-Гвардійців-Широнінців 44 Відділ ксерокс;Харків вул. Гвардійців-Широнінців, 44;87162365-749b-11df-b112-00215aee3ebe
9c1241a1-5eae-11e8-80d4-1c98ec135261;Харків-Гвардійців-Широнінців 70 Канцтовари;Харків вул. Гвардійців-Широнінців, 70;87162365-749b-11df-b112-00215aee3ebe
0079f749-1b87-11ec-80df-000c29800ae7;Харків-Гвардійців-Широнінців 70Б Репкин;Харків вул. Гвардійців-Широнінців, 70Б;87162365-749b-11df-b112-00215aee3ebe
f294a975-b159-11ee-80c4-000c2961d091;Харків-Гвардійців-Широнінців 83/26;Харків вул. Гвардійців-Широнінців, 83/26;87162365-749b-11df-b112-00215aee3ebe
8a99216e-32ab-11ee-80bd-000c2961d091;Харків-Гвардійців-Широнінців 83/26 Дрібниць;Харків вул. Гвардійців-Широнінців, 83/26;87162365-749b-11df-b112-00215aee3ebe
9dea0ab6-539f-11ee-80c0-000c2961d091;Харків-Генерала Удовиченко 32А Одяг;Харків вул. Генерала Удовиченко (Паризької Комуни), 32А;87162365-749b-11df-b112-00215aee3ebe
3f25993c-3829-11ee-80c0-000c2961d091;Харків-Героїв Праці 20/321 Lifecell;Харків вул. Героїв Праці, 20/321;87162365-749b-11df-b112-00215aee3ebe
c23a5c3d-5740-11eb-80d0-000c29800ae7;Харків-Героїв Праці 20/321А Ксерокс;Харків вул. Героїв Праці, 20/321А;87162365-749b-11df-b112-00215aee3ebe
2c1c0b86-9ff7-11ee-80c4-000c2961d091;Харків-Героїв Праці 22М Ломбард;Харків вул. Героїв Праці, 22М;87162365-749b-11df-b112-00215aee3ebe
ce2c692e-f0c3-11ec-80e7-000c29800ae7;Харків-Героїв Праці 29Г Rozetka;Харків вул. Героїв Праці, 29Г;87162365-749b-11df-b112-00215aee3ebe
c774dc13-7e1a-11ee-80c1-000c2961d091;Харків-Героїв Праці 9 Rozetka;Харків вул. Героїв Праці, 9;87162365-749b-11df-b112-00215aee3ebe
1cc53bd5-bcd8-11ed-80ed-000c29800ae7;Харків-Героїв Сталінграду 134 Lifecell;Харків Просп. Байрона (Героїв Сталінграду), 134;87162365-749b-11df-b112-00215aee3ebe
0c04b3d7-172b-11e8-80ce-1c98ec135261;Харків-Героїв Сталінграду 177 Сервісний Центр;Харків Просп. Байрона (Героїв Сталінграду), 177;87162365-749b-11df-b112-00215aee3ebe
784da7d8-f0c5-11ec-80e7-000c29800ae7;Харків-Героїв Сталінграду 179 Rozetka;Харків Просп. Байрона (Героїв Сталінграду), 179;87162365-749b-11df-b112-00215aee3ebe
9dca5d2f-8534-11ee-80c1-000c2961d091;Харків-Героїв Сталінграду 1А Сервісний Центр;Харків Просп. Байрона (Героїв Сталінграду), 1А;87162365-749b-11df-b112-00215aee3ebe
51bfb084-123d-11ec-80da-000c29800ae7;Харків-Героїв Харкова 1 Продажа и Ремонт Часов;Харків Просп. Героїв Харкова (кол.Московський), 1;87162365-749b-11df-b112-00215aee3ebe
ab55f516-39a8-11e9-80dd-1c98ec135261;Харків-Героїв Харкова 140А ОККО;Харків Просп. Героїв Харкова (кол.Московський), 140А;87162365-749b-11df-b112-00215aee3ebe
ea017715-3829-11ee-80c0-000c2961d091;Харків-Героїв Харкова 19/23 Lifecell;Харків Просп. Героїв Харкова (кол.Московський), 19/23;87162365-749b-11df-b112-00215aee3ebe
f70307b4-7657-11ee-80c1-000c2961d091;Харків-Героїв Харкова 206/1 Сільпо;Харків Просп. Героїв Харкова (кол.Московський), 206/1;87162365-749b-11df-b112-00215aee3ebe
153eee88-6f56-11ee-80c1-000c2961d091;Харків-Героїв Харкова 220 Aspor;Харків Просп. Героїв Харкова (кол.Московський), 220;87162365-749b-11df-b112-00215aee3ebe
b507cfb4-457a-11ed-80eb-000c29800ae7;Харків-Героїв Харкова 256 Rozetka;Харків Просп. Героїв Харкова (кол.Московський), 256;87162365-749b-11df-b112-00215aee3ebe
3de22a4a-1013-11ee-80bd-000c2961d091;Харків-Героїв Харкова 256 S-TELL;Харків Просп. Героїв Харкова (кол.Московський), 256;87162365-749b-11df-b112-00215aee3ebe
a7c46fc2-7658-11ee-80c1-000c2961d091;Харків-Героїв Харкова 256 Сільпо;Харків Просп. Героїв Харкова (кол.Московський), 256;87162365-749b-11df-b112-00215aee3ebe
76162ad5-39a9-11e9-80dd-1c98ec135261;Харків-Героїв Харкова 269А ОККО;Харків Просп. Героїв Харкова (кол.Московський), 269А;87162365-749b-11df-b112-00215aee3ebe
ca3c5321-8526-11ee-80c1-000c2961d091;Харків-Героїв Харкова 274 Lifecell;Харків Просп. Героїв Харкова (кол.Московський), 274;87162365-749b-11df-b112-00215aee3ebe
1729a727-a3f3-11ee-80c4-000c2961d091;Харків-Героїв Харкова 30 PIVKO;Харків Просп. Героїв Харкова (кол.Московський), 30;87162365-749b-11df-b112-00215aee3ebe
2c6ff35c-ff41-11eb-80d6-000c29800ae7;Харків-Гімназійна 26 Європошта;Харків наб. Гімназійна (Червоношкільна), 26;87162365-749b-11df-b112-00215aee3ebe
b4612f35-39a0-11e9-80dd-1c98ec135261;Харків-Греківська 112 ОККО;Харків вул. Греківська, 112;87162365-749b-11df-b112-00215aee3ebe
d7eece1c-b5eb-11ee-80c4-000c2961d091;Харків-Григорівське 32 Andi;Харків шосе Григорівське (Комсомольське), 32;87162365-749b-11df-b112-00215aee3ebe
5bbc8c6a-0146-11e8-80cd-1c98ec135261;Харків-Грицевца Сергія 31 Магазин;Харків вул. Грицевца Сергія, 31;87162365-749b-11df-b112-00215aee3ebe
dbaac607-ccb9-11ee-80c4-000c2961d091;Харків-Грицевця 33А ITEHNO;Харків вул. Грицевця, 33А;87162365-749b-11df-b112-00215aee3ebe
caa1f12a-3112-11e8-80d0-1c98ec135261;Харків-Грицевця Сергія 2 Зоомікс;Харків бул. Грицевця Сергія, 2;87162365-749b-11df-b112-00215aee3ebe
345adf25-bba9-11ea-80ca-000c29800ae7;Харків-Данилевського 20 Радіодеталі;Харків вул. Данилевського, 20;87162365-749b-11df-b112-00215aee3ebe
51c9eaba-f657-11ed-80ed-000c29800ae7;Харків-Дружби Народів 249/80 Rozetka;Харків вул. Дружби Народів, 249/80;87162365-749b-11df-b112-00215aee3ebe
7849bf03-9ff9-11ee-80c4-000c2961d091;Харків-Євгена Котляра 7 Ломбард;Харків вул. Євгена Котляра, 7;87162365-749b-11df-b112-00215aee3ebe
b457cba7-b729-11e8-80d4-1c98ec135261;Харків-Жасминовий 5-1 Зоотовари;Харків бул. Жасминовий (Слинька), 5-1;87162365-749b-11df-b112-00215aee3ebe
01d8d940-073a-11ec-80d6-000c29800ae7;Харків-Жуковського Василя 5А Хазар;Харків Просп. Жуковського Василя, 5А;87162365-749b-11df-b112-00215aee3ebe
986e8aa3-8a2c-11ee-80c1-000c2961d091;Харків-Захисників України 7/8 ITEHNO;Харків Майдан Захисників України, 7/8;87162365-749b-11df-b112-00215aee3ebe
5afff3fc-dc55-11ee-80c4-000c2961d091;Харків-Зернова 53Б Laundry House;Харків вул. Зернова, 53Б;87162365-749b-11df-b112-00215aee3ebe
c8c9bf32-c826-11eb-80d5-000c29800ae7;Харків-Зернова 6 Фрукти, Овочі;Харків вул. Зернова, 6;87162365-749b-11df-b112-00215aee3ebe
f366cc84-39aa-11e9-80dd-1c98ec135261;Харків-Зіркова 2А ОККО;Харків вул. Зіркова, 2А;87162365-749b-11df-b112-00215aee3ebe
444e2273-1041-11ee-80bd-000c2961d091;Харків-Золочівська 12 Ремонт телефонів;Харків вул. Золочівська, 12;87162365-749b-11df-b112-00215aee3ebe
a0647ad4-a836-11eb-80d3-000c29800ae7;Харків-Індустріальний 12 VanVET;Харків Просп. Індустріальний, 12;87162365-749b-11df-b112-00215aee3ebe
1234b85b-f4a0-11e8-80d9-1c98ec135261;Харків-Індустріальний 24/31 Зоотовари;Харків Просп. Індустріальний, 24/31;87162365-749b-11df-b112-00215aee3ebe
5fe78037-2520-11ec-80e3-000c29800ae7;Харків-Індустріальний 26 Ксерокс канцтовари;Харків Просп. Індустріальний, 26;87162365-749b-11df-b112-00215aee3ebe
a89730a5-7643-11e9-80e0-1c98ec135261;Харків-Інженерний 9 Ксерокс Канцтовары;Харків пров. Інженерний, 9;87162365-749b-11df-b112-00215aee3ebe
3c5f5d1b-1ad3-11e8-80d0-1c98ec135261;Харків-Киргизька 15/30 Все в деталях;Харків вул. Киргизька, 15/30;87162365-749b-11df-b112-00215aee3ebe
90a75acb-0579-11ec-80d6-000c29800ae7;Харків-Китаєнка 8 Промтовари;Харків вул. Китаєнка, 8;87162365-749b-11df-b112-00215aee3ebe
ac060236-7fcd-11ee-80c1-000c2961d091;Харків-Клочківська 117 SUSHIFLOW;Харків вул. Клочківська, 117;87162365-749b-11df-b112-00215aee3ebe
42a19418-39a1-11e9-80dd-1c98ec135261;Харків-Клочківська 197Е ОККО;Харків вул. Клочківська, 197Е;87162365-749b-11df-b112-00215aee3ebe
91980190-39a2-11e9-80dd-1c98ec135261;Харків-Клочківська 44 ОККО;Харків вул. Клочківська, 44;87162365-749b-11df-b112-00215aee3ebe
211cb0fe-f0c4-11ec-80e7-000c29800ae7;Харків-Клочківська 9А Rozetka;Харків вул. Клочківська, 9А;87162365-749b-11df-b112-00215aee3ebe
f0bce414-a7c3-11e9-80e4-1c98ec135261;Харків-Ковпака 24 ОККО;Харків вул. Ковпака, 24;87162365-749b-11df-b112-00215aee3ebe
1a3d4f4d-9ffa-11ee-80c4-000c2961d091;Харків-Конституції 13М Ломбард;Харків Майдан Конституції, 13М;87162365-749b-11df-b112-00215aee3ebe
2c2dfcba-5790-11ee-80c0-000c2961d091;Харків-Космічна 23А Сільпо;Харків вул. Космічна, 23А;87162365-749b-11df-b112-00215aee3ebe
76adb676-14df-11ee-80bd-000c2961d091;Харків-Котельниківська 5 Dark склад;Харків вул. Котельниківська, 5;87162365-749b-11df-b112-00215aee3ebe
cbc0d781-b5e0-11ee-80c4-000c2961d091;Харків-Краснодарська 181Б Крамницька;Харків вул. Краснодарська, 181Б;87162365-749b-11df-b112-00215aee3ebe
de8c2730-810d-11ed-80ec-000c29800ae7;Харків-Кузнецька 85 Автомагазин;Харків вул. Кузнецька, 85;87162365-749b-11df-b112-00215aee3ebe
bcb0c128-08c2-11ed-80e7-000c29800ae7;Харків-Культури 17 Rozetka;Харків вул. Культури, 17;87162365-749b-11df-b112-00215aee3ebe
2eb91268-0738-11ec-80d6-000c29800ae7;Харків-Курчатова Академіка 4Б Хазар;Харків Просп. Курчатова Академіка, 4Б;87162365-749b-11df-b112-00215aee3ebe
9974ab13-1c0a-11ea-80e7-1c98ec135261;Харків-Ландау Льва 62В ОККО;Харків Просп. Ландау Льва ( 50-річчя СРСР ), 62В;87162365-749b-11df-b112-00215aee3ebe
bc8503ea-5a9f-11ed-80eb-000c29800ae7;Харків-Людвіга Свободи 28А Rozetka;Харків Просп. Людвіга Свободи, 28А;87162365-749b-11df-b112-00215aee3ebe
0016f993-7659-11ee-80c1-000c2961d091;Харків-Людвіга Свободи 30 Сільпо;Харків Просп. Людвіга Свободи, 30;87162365-749b-11df-b112-00215aee3ebe
6e49872b-d18c-11ee-80c4-000c2961d091;Харків-Людвіга Свободи 37Д Avon;Харків Просп. Людвіга Свободи, 37Д;87162365-749b-11df-b112-00215aee3ebe
0ed7e48f-47a5-11ec-80e3-000c29800ae7;Харків-Людвіга Свободи 41 Lifecell;Харків Просп. Людвіга Свободи, 41;87162365-749b-11df-b112-00215aee3ebe
1a58e3ce-ae39-11ed-80ec-000c29800ae7;Харків-Людвіга Свободи 43 Rozetka;Харків Просп. Людвіга Свободи, 43;87162365-749b-11df-b112-00215aee3ebe
ad688f84-9ff8-11ee-80c4-000c2961d091;Харків-Людвіга Свободи 52А Ломбард;Харків Просп. Людвіга Свободи, 52А;87162365-749b-11df-b112-00215aee3ebe
871e046b-267f-11ec-80e3-000c29800ae7;Харків-Маяковського 15 Арбуз-Маркет;Харків вул. Маяковського, 15;87162365-749b-11df-b112-00215aee3ebe
01c8b12b-9f43-11ee-80c4-000c2961d091;Харків-Мерефянське 25 Зоомагазин;Харків шосе Мерефянське, 25;87162365-749b-11df-b112-00215aee3ebe
02c4eea6-bf49-11ee-80c4-000c2961d091;Харків-Мироносицька 64 Смартфони-Ноутбуки;Харків вул. Мироносицька, 64;87162365-749b-11df-b112-00215aee3ebe
0d49f3ae-d7de-11ee-80c4-000c2961d091;Харків-Москалівська 72 Rozetka;Харків вул. Москалівська (Жовтневої Революції), 72;87162365-749b-11df-b112-00215aee3ebe
5ea154c8-894f-11ee-80c1-000c2961d091;Харків-Науки 10 Флешка;Харків Просп. Науки, 10;87162365-749b-11df-b112-00215aee3ebe
99922959-9ff7-11ee-80c4-000c2961d091;Харків-Науки 12 Ломбард ;Харків Просп. Науки, 12;87162365-749b-11df-b112-00215aee3ebe
06ede033-2c75-11ee-80bd-000c2961d091;Харків-Науки 27 Lifecell;Харків Просп. Науки, 27;87162365-749b-11df-b112-00215aee3ebe
07fc50fc-3825-11ee-80c0-000c2961d091;Харків-Науки 41/43 СПЕЦ;Харків Просп. Науки, 41/43;87162365-749b-11df-b112-00215aee3ebe
ef971dab-9ff7-11ee-80c4-000c2961d091;Харків-Науки 41-43 Ломбард;Харків Просп. Науки, 41-43;87162365-749b-11df-b112-00215aee3ebe
8614fc09-f0c4-11ec-80e7-000c29800ae7;Харків-Науки 64 Rozetka;Харків Просп. Науки, 64;87162365-749b-11df-b112-00215aee3ebe
55e3568f-47a5-11ec-80e3-000c29800ae7;Харків-Науки 9 Lifecell;Харків Просп. Науки, 9;87162365-749b-11df-b112-00215aee3ebe
d06cd8d5-57bc-11ee-80c0-000c2961d091;Харків-Незалежності 3 Сервісний Центр;Харків Просп. Незалежності ( Правди ), 3;87162365-749b-11df-b112-00215aee3ebe
4e4f6507-5a9f-11ed-80eb-000c29800ae7;Харків-Нетіченська 25 Rozetka;Харків вул. Нетіченська, 25;87162365-749b-11df-b112-00215aee3ebe
4eb4944d-0730-11ec-80d6-000c29800ae7;Харків-Ново-Баварський 31 НАФАНЯ;Харків Просп. Ново-Баварський ( Ілліча ), 31;87162365-749b-11df-b112-00215aee3ebe
23fae3ad-47cc-11e8-80d0-1c98ec135261;Харків-Ново-Баварський 71 будОбєкт;Харків Просп. Ново-Баварський ( Ілліча ), 71;87162365-749b-11df-b112-00215aee3ebe
790e4994-5b32-11eb-80d0-000c29800ae7;Харків-Новоолександрівська 54А Арбуз-Маркет;Харків вул. Новоолександрівська, 54А;87162365-749b-11df-b112-00215aee3ebe
ca9c7576-d8f5-11e7-80cc-1c98ec135261;Харків-Одеська 106 Телефони;Харків вул. Одеська, 106;87162365-749b-11df-b112-00215aee3ebe
62d09b60-f0c3-11ec-80e7-000c29800ae7;Харків-Олександрівський 156 Rozetka;Харків Просп. Олександрівський (Косіора), 156;87162365-749b-11df-b112-00215aee3ebe
bae33bab-38eb-11e9-80dd-1c98ec135261;Харків-Павлова Академіка 120Б ОККО;Харків вул. Павлова Академіка, 120Б;87162365-749b-11df-b112-00215aee3ebe
05d06e1e-9ff9-11ee-80c4-000c2961d091;Харків-Павлова Академіка 136М Ломбард;Харків вул. Павлова Академіка, 136М;87162365-749b-11df-b112-00215aee3ebe
8a1983ad-b5f4-11ee-80c4-000c2961d091;Харків-Павлова Академіка 160 Andi;Харків вул. Павлова Академіка, 160;87162365-749b-11df-b112-00215aee3ebe
03c95502-234f-11eb-80cd-000c29800ae7;Харків-Павлова Академіка 311 Ветлідер;Харків вул. Павлова Академіка, 311;87162365-749b-11df-b112-00215aee3ebe
ff4d87ec-d62e-11ee-80c4-000c2961d091;Харків-Павлова Академіка 323А lifecell;Харків вул. Павлова Академіка, 323А;87162365-749b-11df-b112-00215aee3ebe
0ec8483f-765b-11ee-80c1-000c2961d091;Харків-Павлова Академіка 44Б Сільпо;Харків вул. Павлова Академіка, 44Б;87162365-749b-11df-b112-00215aee3ebe
33ef9d33-eb66-11ee-80c5-000c2961d091;Харків-Перемоги 50 Дрібниці Побуту;Харків Просп. Перемоги, 50;87162365-749b-11df-b112-00215aee3ebe
8d994ceb-5173-11ee-80c0-000c2961d091;Харків-Перемоги 64-Г СПЕЦ;Харків Просп. Перемоги, 64-Г;87162365-749b-11df-b112-00215aee3ebe
ee1776fb-48de-11ea-80c6-000c29800ae7;Харків-Петра Григоренка 10/14 ОККО;Харків Просп. Петра Григоренка (Жукова Маршала), 10/14;87162365-749b-11df-b112-00215aee3ebe
63484bea-9ff8-11ee-80c4-000c2961d091;Харків-Петра Григоренка 3 Ломбард;Харків Просп. Петра Григоренка (Жукова Маршала), 3;87162365-749b-11df-b112-00215aee3ebe
fa6df647-e2a1-11ee-80c5-000c2961d091;Харків-Плеханівська 41/43;Харків вул. Плеханівська, 41/43;87162365-749b-11df-b112-00215aee3ebe
ac633899-1025-11ee-80bd-000c2961d091;Харків-Полтавський Шлях 123 TEXNO ENOT;Харків вул. Полтавський Шлях, 123;87162365-749b-11df-b112-00215aee3ebe
0aaba039-69d3-11ee-80c1-000c2961d091;Харків-Полтавський Шлях 133-Б СПЕЦ;Харків вул. Полтавський Шлях, 133-Б;87162365-749b-11df-b112-00215aee3ebe
dbf91400-f0c4-11ec-80e7-000c29800ae7;Харків-Полтавський Шлях 140 Rozetka;Харків вул. Полтавський Шлях, 140;87162365-749b-11df-b112-00215aee3ebe
ac791c02-bcd7-11ed-80ed-000c29800ae7;Харків-Полтавський Шлях 147 Lifecell;Харків вул. Полтавський Шлях, 147;87162365-749b-11df-b112-00215aee3ebe
40785892-7179-11ee-80c1-000c2961d091;Харків-Полтавський Шлях 152 One Price;Харків вул. Полтавський Шлях, 152;87162365-749b-11df-b112-00215aee3ebe
d9a01215-9ff9-11ee-80c4-000c2961d091;Харків-Полтавський Шлях 155 Ломбард;Харків вул. Полтавський Шлях, 155;87162365-749b-11df-b112-00215aee3ebe
6aabb3bf-b22c-11eb-80d4-000c29800ae7;Харків-Полтавський Шлях 188 Бджілка;Харків вул. Полтавський Шлях, 188;87162365-749b-11df-b112-00215aee3ebe
3cf57ace-852a-11ee-80c1-000c2961d091;Харків-Полтавський Шлях 53/55 Lifecell;Харків вул. Полтавський Шлях, 53/55;87162365-749b-11df-b112-00215aee3ebe
fbbdcef7-2081-11ee-80bd-000c2961d091;Харків-Полтавський Шлях 79 ОККО;Харків вул. Полтавський Шлях, 79;87162365-749b-11df-b112-00215aee3ebe
301ff417-7e1b-11ee-80c1-000c2961d091;Харків-Проскури Академіка 1 Rozetka;Харків вул. Проскури Академіка, 1;87162365-749b-11df-b112-00215aee3ebe
18292804-99c9-11ee-80c1-000c2961d091;Харків-Пушкінська 1 Сервісний Центр;Харків вул. Пушкінська, 1;87162365-749b-11df-b112-00215aee3ebe
6393705a-765b-11ee-80c1-000c2961d091;Харків-Пушкінська 2А Сільпо;Харків вул. Пушкінська, 2А;87162365-749b-11df-b112-00215aee3ebe
59c5cd10-47a2-11ec-80e3-000c29800ae7;Харків-Пушкінська 43 Lifecell;Харків вул. Пушкінська, 43;87162365-749b-11df-b112-00215aee3ebe
7947973c-af86-11e8-80d4-1c98ec135261;Харків-Рибалка 20 Зоомагазин;Харків вул. Рибалка, 20;87162365-749b-11df-b112-00215aee3ebe
94a871f1-5fb6-11eb-80d0-000c29800ae7;Харків-Рижівська 13 Арбуз-Маркет;Харків вул. Рижівська (Куйбишева), 13;87162365-749b-11df-b112-00215aee3ebe
7dba6ab6-c73d-11ed-80ed-000c29800ae7;Харків-Римарська 18 Edwardstore;Харків вул. Римарська, 18;87162365-749b-11df-b112-00215aee3ebe
bcf80b44-abd3-11ea-80c8-000c29800ae7;Харків-Римарська 19 Mobile Planet;Харків вул. Римарська, 19;87162365-749b-11df-b112-00215aee3ebe
1d6add4b-a7c3-11e9-80e4-1c98ec135261;Харків-Роганська 160А ОККО;Харків вул. Роганська, 160А;87162365-749b-11df-b112-00215aee3ebe
ed8f1c58-399f-11e9-80dd-1c98ec135261;Харків-Роганська 63А ОККО;Харків вул. Роганська, 63А;87162365-749b-11df-b112-00215aee3ebe
2efd31d0-2c63-11ee-80bd-000c2961d091;Харків-Салтівське 100/1 AQUAYUR;Харків шосе Салтівське, 100/1;87162365-749b-11df-b112-00215aee3ebe
1b84f0c7-879d-11ee-80c1-000c2961d091;Харків-Салтівське 12 Копицентр;Харків шосе Салтівське, 12;87162365-749b-11df-b112-00215aee3ebe
2ca69860-d4ee-11eb-80d5-000c29800ae7;Харків-Салтівське 137А Сервісний Центр;Харків шосе Салтівське, 137А;87162365-749b-11df-b112-00215aee3ebe
de1a1323-d60f-11ee-80c4-000c2961d091;Харків-Салтівське 246 Laundry House;Харків шосе Салтівське, 246;87162365-749b-11df-b112-00215aee3ebe
382818e8-41cc-11ee-80c0-000c2961d091;Харків-Стадіонний 17/3 Ремонт телефонів;Харків проїзд Стадіонний, 17/3;87162365-749b-11df-b112-00215aee3ebe
2cf474b7-f0c5-11ec-80e7-000c29800ae7;Харків-Стадіонний 5А Rozetka;Харків проїзд Стадіонний, 5А;87162365-749b-11df-b112-00215aee3ebe
f2450852-7179-11ee-80c1-000c2961d091;Харків-Сумська 15 One Price;Харків вул. Сумська, 15;87162365-749b-11df-b112-00215aee3ebe
49769a10-47a4-11ec-80e3-000c29800ae7;Харків-Сумська 37Д Lifecell;Харків вул. Сумська, 37Д;87162365-749b-11df-b112-00215aee3ebe
5d142a4e-283a-11ec-80e3-000c29800ae7;Харків-Сумська 71 Арбуз-Маркет;Харків вул. Сумська, 71;87162365-749b-11df-b112-00215aee3ebe
21c72e68-7f53-11ec-80e4-000c29800ae7;Харків-Сумська 80 Lifecell;Харків вул. Сумська, 80;87162365-749b-11df-b112-00215aee3ebe
bd0453e9-765a-11ee-80c1-000c2961d091;Харків-Тракторобудівників 108 Сільпо;Харків Просп. Тракторобудівників, 108;87162365-749b-11df-b112-00215aee3ebe
2280f89f-457b-11ed-80eb-000c29800ae7;Харків-Тракторобудівників 130 Rozetka;Харків Просп. Тракторобудівників, 130;87162365-749b-11df-b112-00215aee3ebe
9b3e049d-5790-11ee-80c0-000c2961d091;Харків-Тракторобудівників 59/56 Сільпо;Харків Просп. Тракторобудівників, 59/56;87162365-749b-11df-b112-00215aee3ebe
6eb39df3-430f-11e8-80d0-1c98ec135261;Харків-Ужвій Наталії 94 Зоомікс;Харків вул. Ужвій Наталії, 94;87162365-749b-11df-b112-00215aee3ebe
52bdc0fb-eb58-11ee-80c5-000c2961d091;Харків-Харківська Набережна 9 Автозапчастини;Харків наб. Харківська, 9;87162365-749b-11df-b112-00215aee3ebe
4803095b-b5f1-11ee-80c4-000c2961d091;Харків-Харківських Дивізій 14/1 Andi;Харків вул. Харківських Дивізій, 14/1;87162365-749b-11df-b112-00215aee3ebe
4d6bdbe5-3211-11ea-80e7-1c98ec135261;Харків-Холодногірська 10 Ветклініка Ветлідер;Харків вул. Холодногірська (Єлізарова), 10;87162365-749b-11df-b112-00215aee3ebe
3c5b4b32-6f68-11eb-80d1-000c29800ae7;Харків-Чичибабіна 2 Арбуз-Маркет;Харків вул. Чичибабіна, 2;87162365-749b-11df-b112-00215aee3ebe
07dfc1b1-0739-11ec-80d6-000c29800ae7;Харків-Чкалова 15 Хазар;Харків вул. Вадима Манька ( Чкалова ), 15;87162365-749b-11df-b112-00215aee3ebe
94d3b413-8d6b-11ee-80c1-000c2961d091;Харків-Шарикова 29 ITEHNO;Харків вул. Шарикова, 29;87162365-749b-11df-b112-00215aee3ebe
4b00d74f-1c0b-11ea-80e7-1c98ec135261;Харків-Ювілейний 32Б ОККО;Харків Просп. Ювілейний ( 50-річчя ВЛКСМ ), 32Б;87162365-749b-11df-b112-00215aee3ebe
8edc864f-5ead-11e8-80d4-1c98ec135261;Харків-Ювілейний 49/8 Канцтовари;Харків Просп. Ювілейний ( 50-річчя ВЛКСМ ), 49/8;87162365-749b-11df-b112-00215aee3ebe
ecde7b76-9ffa-11ee-80c4-000c2961d091;Харків-Ювілейний 64 Ломбард;Харків Просп. Ювілейний, 64;87162365-749b-11df-b112-00215aee3ebe
fd3b75cb-d198-11ee-80c4-000c2961d091;Харків-Ювілейний 7А Rozetka;Харків Просп. Ювілейний, 7А;87162365-749b-11df-b112-00215aee3ebe
bb9c25f2-79fd-11ed-80ec-000c29800ae7;Харків-Ювілейний 89 Rozetka;Харків Просп. Ювілейний, 89;87162365-749b-11df-b112-00215aee3ebe
30e149c4-79fe-11ed-80ec-000c29800ae7;Харків-Ярослава Мудрого 30/32 Rozetka;Харків вул. Ярослава Мудрого (Петровського), 30/32;87162365-749b-11df-b112-00215aee3ebe
afdfa549-47a2-11ec-80e3-000c29800ae7;Харків-Ярослава Мудрого 33А Lifecell;Харків вул. Ярослава Мудрого (Петровського), 33А;87162365-749b-11df-b112-00215aee3ebe
42d583de-7658-11ee-80c1-000c2961d091;Харків-Яроша Отакара 18Д Сільпо;Харків вул. Яроша Отакара, 18Д;87162365-749b-11df-b112-00215aee3ebe
6cf50991-667a-11ee-80c0-000c2961d091;Херсон- 49-ї Гвардійської Дивізії;Херсон вул. 49 Гвардійської Дивізії,;8716239a-749b-11df-b112-00215aee3ebe
c91821dc-35f4-11e9-80dd-1c98ec135261;Херсон-Бериславське;Херсон шосе Бериславське,;8716239a-749b-11df-b112-00215aee3ebe
be968dfb-6431-11ee-80c0-000c2961d091;Херсон-Некрасова 29;Херсон вул. Некрасова, 29;8716239a-749b-11df-b112-00215aee3ebe
3ae87343-35f4-11e9-80dd-1c98ec135261;Херсон-Сенявіна 49;Херсон Просп. Сенявіна, 49;8716239a-749b-11df-b112-00215aee3ebe
75ec2995-a8dd-11de-bac3-0030485903e8;Хмельницький;Хмельницький вул. Озерна, 16/1;871623ec-749b-11df-b112-00215aee3ebe
d31ac0f5-4cd5-11ea-80c6-000c29800ae7;Хмельницький-Бандери 2/1А ЧІП;Хмельницький вул. Бандери (Рибалка), 2/1А;871623ec-749b-11df-b112-00215aee3ebe
2c75bb78-765e-11ee-80c1-000c2961d091;Хмельницький-Бандери 2А Сільпо;Хмельницький вул. Бандери (Рибалка), 2А;871623ec-749b-11df-b112-00215aee3ebe
342159f2-f067-11eb-80d5-000c29800ae7;Хмельницький-Гарнізонна 4/4 Ломбард;Хмельницький вул. Гарнізонна, 4/4;871623ec-749b-11df-b112-00215aee3ebe
7c2933d2-a796-11e9-80e4-1c98ec135261;Хмельницький-Гетьмана Мазепи 51 ОККО;Хмельницький вул. Гетьмана Мазепи (Красовського), 51;871623ec-749b-11df-b112-00215aee3ebe
1c78d06d-40ac-11ed-80e9-000c29800ae7;Хмельницький-Довженка Олександра 7/1 Lifecell;Хмельницький вул. Довженка Олександра, 7/1;871623ec-749b-11df-b112-00215aee3ebe
1025f463-2dd5-11e8-80d0-1c98ec135261;Хмельницький-Зарічанська 22/2 Все до лампочки;Хмельницький вул. Зарічанська, 22/2;871623ec-749b-11df-b112-00215aee3ebe
640ff7ab-c90c-11eb-80d5-000c29800ae7;Хмельницький-Зарічанська 38 Xmobi;Хмельницький вул. Зарічанська, 38;871623ec-749b-11df-b112-00215aee3ebe
3d9fc8ef-09cb-11ed-80e7-000c29800ae7;Хмельницький-Зарічанська 57/1 Rozetka;Хмельницький вул. Зарічанська, 57/1;871623ec-749b-11df-b112-00215aee3ebe
9ce2506e-1707-11ed-80e7-000c29800ae7;Хмельницький-Інститутська 11/1 Lifecell;Хмельницький вул. Інститутська, 11/1;871623ec-749b-11df-b112-00215aee3ebe
2155eeba-bc1a-11ed-80ed-000c29800ae7;Хмельницький-Інститутська 21/1 Портал;Хмельницький вул. Інститутська, 21/1;871623ec-749b-11df-b112-00215aee3ebe
5f8b5271-765f-11ee-80c1-000c2961d091;Хмельницький-Кам'янецька 122 Сільпо;Хмельницький вул. Кам'янецька, 122;871623ec-749b-11df-b112-00215aee3ebe
b1a1d18b-f0c2-11ec-80e7-000c29800ae7;Хмельницький-Кам'янецька 19/1А Rozetka;Хмельницький вул. Кам'янецька, 19/1А;871623ec-749b-11df-b112-00215aee3ebe
62768397-ef2b-11ed-80ed-000c29800ae7;Хмельницький-Кам'янецька 48 Оптика KLEIMAN;Хмельницький вул. Кам'янецька, 48;871623ec-749b-11df-b112-00215aee3ebe
99cb790e-ff39-11eb-80d6-000c29800ae7;Хмельницький-Кам'янецька 52 Європошта;Хмельницький вул. Кам'янецька, 52;871623ec-749b-11df-b112-00215aee3ebe
8fa63646-3cb0-11ec-80e3-000c29800ae7;Хмельницький-Козацька 42/3 РозумЦентрБуд;Хмельницький вул. Козацька, 42/3;871623ec-749b-11df-b112-00215aee3ebe
485c0210-eecc-11eb-80d5-000c29800ae7;Хмельницький-Купріна 2 ТехноСвіт;Хмельницький вул. Купріна, 2;871623ec-749b-11df-b112-00215aee3ebe
f9fb3377-0d7a-11ed-80e7-000c29800ae7;Хмельницький-Купріна 65 Lifecell;Хмельницький вул. Купріна, 65;871623ec-749b-11df-b112-00215aee3ebe
04783e06-046d-11e9-80d9-1c98ec135261;Хмельницький-Кушнірука 15/1 МІЙ ДІМ;Хмельницький вул. Кушнірука ( Гастелло ), 15/1;871623ec-749b-11df-b112-00215aee3ebe
000be1dd-a796-11e9-80e4-1c98ec135261;Хмельницький-Львівське 20/1А ОККО;Хмельницький шосе Львівське, 20/1А;871623ec-749b-11df-b112-00215aee3ebe
5e0ce069-90dc-11e8-80d4-1c98ec135261;Хмельницький-Львівське 51 TonerPlus;Хмельницький шосе Львівське, 51;871623ec-749b-11df-b112-00215aee3ebe
a4764cda-ab93-11ed-80ec-000c29800ae7;Хмельницький-Львівське шосе 10/1 Rozetka;Хмельницький вул. Львівське шосе, 10/1;871623ec-749b-11df-b112-00215aee3ebe
6dc3b7ee-818d-11eb-80d2-000c29800ae7;Хмельницький-Магери Миколи 2 БІЛЯ ДОМУ;Хмельницький вул. Магери Миколи, 2;871623ec-749b-11df-b112-00215aee3ebe
e5643335-7c0c-11eb-80d2-000c29800ae7;Хмельницький-Мирного Панаса 1 Lifecell;Хмельницький вул. Мирного Панаса, 1;871623ec-749b-11df-b112-00215aee3ebe
04f40ae5-ab94-11ed-80ec-000c29800ae7;Хмельницький-Мирного Панаса 1 Rozetka;Хмельницький вул. Мирного Панаса, 1;871623ec-749b-11df-b112-00215aee3ebe
9a256ad8-09cb-11ed-80e7-000c29800ae7;Хмельницький-Мирного Панаса 16/1 Rozetka;Хмельницький вул. Мирного Панаса, 16/1;871623ec-749b-11df-b112-00215aee3ebe
3a55446e-35f1-11e9-80dd-1c98ec135261;Хмельницький-Миру 102/1 ОККО;Хмельницький Просп. Миру, 102/1;871623ec-749b-11df-b112-00215aee3ebe
07c01cc7-90b5-11e8-80d4-1c98ec135261;Хмельницький-Миру 60/1 Нюанс;Хмельницький Просп. Миру, 60/1;871623ec-749b-11df-b112-00215aee3ebe
3671f0e0-35f0-11e9-80dd-1c98ec135261;Хмельницький-Миру 63/2 ОККО;Хмельницький Просп. Миру, 63/2;871623ec-749b-11df-b112-00215aee3ebe
ed1fe27f-24f3-11eb-80cd-000c29800ae7;Хмельницький-Миру 90/1 Сервісний Центр;Хмельницький Просп. Миру, 90/1;871623ec-749b-11df-b112-00215aee3ebe
939f2354-981e-11e9-80e2-1c98ec135261;Хмельницький-Народна Воля 1А/2к СЦ Майстеркомп;Хмельницький вул. Народна Воля, 1А/2к;871623ec-749b-11df-b112-00215aee3ebe
fff37038-0032-11ec-80d6-000c29800ae7;Хмельницький-Нижня Берегова 35 КОПІРКА;Хмельницький вул. Нижня Берегова, 35;871623ec-749b-11df-b112-00215aee3ebe
1c4dd0dc-a795-11e9-80e4-1c98ec135261;Хмельницький-Озерна 8/1 ОККО;Хмельницький вул. Озерна, 8/1;871623ec-749b-11df-b112-00215aee3ebe
7f493a54-c1ff-11eb-80d5-000c29800ae7;Хмельницький-Петра Болбочана 3/2 Будівельник;Хмельницький вул. Петра Болбочана, 3/2;871623ec-749b-11df-b112-00215aee3ebe
a61d9971-9022-11ed-80ec-000c29800ae7;Хмельницький-Пілотська 49 Шинний Комплекс;Хмельницький вул. Пілотська, 49;871623ec-749b-11df-b112-00215aee3ebe
f31c1772-f066-11eb-80d5-000c29800ae7;Хмельницький-Подільська 38 Ломбард;Хмельницький вул. Подільська, 38;871623ec-749b-11df-b112-00215aee3ebe
7accd35b-9ff3-11ee-80c4-000c2961d091;Хмельницький-Подільська 63 Ломбард;Хмельницький вул. Подільська, 63;871623ec-749b-11df-b112-00215aee3ebe
b4a94c84-a792-11e9-80e4-1c98ec135261;Хмельницький-Прибузька 1/1 ОККО;Хмельницький вул. Прибузька, 1/1;871623ec-749b-11df-b112-00215aee3ebe
0a535999-99d0-11ee-80c1-000c2961d091;Хмельницький-Прибузька 6 Центр Дзеркал;Хмельницький вул. Прибузька, 6;871623ec-749b-11df-b112-00215aee3ebe
b5b1608a-2f9c-11e9-80dd-1c98ec135261;Хмельницький-Проскурівська 1 tехнопростір;Хмельницький вул. Проскурівська, 1;871623ec-749b-11df-b112-00215aee3ebe
034e6076-c28d-11ea-80ca-000c29800ae7;Хмельницький-Проскурівська 24 Lifecell;Хмельницький вул. Проскурівська, 24;871623ec-749b-11df-b112-00215aee3ebe
9b0ddef8-4e2c-11ee-80c0-000c2961d091;Хмельницький-Романа Шухевича 2/1Б Мобіленд;Хмельницький вул. Романа Шухевича ( Курчатова ), 2/1Б;871623ec-749b-11df-b112-00215aee3ebe
7e1a911b-4ebb-11ed-80eb-000c29800ae7;Хмельницький-Романа Шухевича 90 Будівельник;Хмельницький вул. Романа Шухевича ( Курчатова ), 90;871623ec-749b-11df-b112-00215aee3ebe
33a5bbb0-1dcd-11eb-80cd-000c29800ae7;Хмельницький-Свободи 48 ЧІП;Хмельницький вул. Свободи, 48;871623ec-749b-11df-b112-00215aee3ebe
09fe868d-765f-11ee-80c1-000c2961d091;Хмельницький-Свободи 73 Сільпо;Хмельницький вул. Свободи, 73;871623ec-749b-11df-b112-00215aee3ebe
8dd1c0dd-e1ee-11ea-80cb-000c29800ae7;Хмельницький-Соборна 12 Lifecell;Хмельницький вул. Соборна, 12;871623ec-749b-11df-b112-00215aee3ebe
caf0ed23-1dcf-11eb-80cd-000c29800ae7;Хмельницький-Соборна 15 ЧІП;Хмельницький вул. Соборна, 15;871623ec-749b-11df-b112-00215aee3ebe
52b63d58-0d7b-11ed-80e7-000c29800ae7;Хмельницький-Старокостянтинівське 2/1Б Lifecell;Хмельницький вул. Старокостянтинівське шосе, 2/1Б;871623ec-749b-11df-b112-00215aee3ebe
5e9f3dfd-8d6a-11ee-80c1-000c2961d091;Хмельницький-Старокостянтинівське 2/1Б Vodafone;Хмельницький вул. Старокостянтинівське шосе, 2/1Б;871623ec-749b-11df-b112-00215aee3ebe
656d3b29-17ef-11e8-80d0-1c98ec135261;Хмельницький-Старокостянтинівське шосе 2/1Б ЧІП;Хмельницький вул. Старокостянтинівське шосе, 2/1Б;871623ec-749b-11df-b112-00215aee3ebe
8f429329-a794-11e9-80e4-1c98ec135261;Хмельницький-Старокостянтинівське шосе 20 ОККО;Хмельницький вул. Старокостянтинівське шосе, 20;871623ec-749b-11df-b112-00215aee3ebe
894cdd34-765e-11ee-80c1-000c2961d091;Хмельницький-Старокостянтинівське шосе 6/1А Сільпо;Хмельницький вул. Старокостянтинівське шосе, 6/1А;871623ec-749b-11df-b112-00215aee3ebe
110e5853-f0c2-11ec-80e7-000c29800ae7;Хмельницький-Трудова 6А Rozetka;Хмельницький вул. Трудова, 6А;871623ec-749b-11df-b112-00215aee3ebe
3c7d179f-c5e0-11ea-80ca-000c29800ae7;Хмельницький-Трудова 6А Vodafone;Хмельницький вул. Трудова, 6А;871623ec-749b-11df-b112-00215aee3ebe
d347d5c6-765d-11ee-80c1-000c2961d091;Хмельницький-Шевченка 34 Сільпо;Хмельницький вул. Шевченка, 34;871623ec-749b-11df-b112-00215aee3ebe
8c71afee-89fc-11ee-80c1-000c2961d091;Хмельницький-Шевченка 39 Rozetka;Хмельницький вул. Шевченка, 39;871623ec-749b-11df-b112-00215aee3ebe
ab3f9120-3881-11eb-80cf-000c29800ae7;Хмельницький-Шевченка 58 Lifecell;Хмельницький вул. Шевченка, 58;871623ec-749b-11df-b112-00215aee3ebe
d17ade31-765f-11ee-80c1-000c2961d091;Хмільник-Свободи 1;Хмільник Просп. Свободи, 1;871623f6-749b-11df-b112-00215aee3ebe
88ce9a91-b7b5-11eb-80d4-000c29800ae7;Хмільник-Свободи 16А;Хмільник Просп. Свободи, 16А;871623f6-749b-11df-b112-00215aee3ebe
b7f0ddba-d97f-11eb-80d5-000c29800ae7;Хмільник-Столярчука 56;Хмільник пров. Столярчука, 56;871623f6-749b-11df-b112-00215aee3ebe
a7d1debd-abf4-11ee-80c4-000c2961d091;Хмільник-Тімірязєва 5 Будцентр;Хмільник вул. Тімірязєва, 5;871623f6-749b-11df-b112-00215aee3ebe
d178dd09-4646-11e9-80dd-1c98ec135261;Ходосівка-Обухівське 1,2-К;Ходосівка шосе Обухівське, 1,2К;87162410-749b-11df-b112-00215aee3ebe
35ce3d88-baad-11ee-80c4-000c2961d091;Ходосівка-Печерського 31;Ходосівка вул. Печерського, 31;87162410-749b-11df-b112-00215aee3ebe
a42d7832-5c55-11ee-80c0-000c2961d091;Холодновідка-Кільцева 5;Холодновідка вул. Кільцева, 5;8716242b-749b-11df-b112-00215aee3ebe
a6249408-cc06-11ee-80c4-000c2961d091;Хорол-1-го Травня 5А;Хорол вул. 1-го Травня, 5А;87162461-749b-11df-b112-00215aee3ebe
4ceae462-6966-11e8-80d4-1c98ec135261;Хорол-8-го Березня,24;Хорол вул. 8-го Березня, 24;87162461-749b-11df-b112-00215aee3ebe
0ad67972-1705-11ed-80e7-000c29800ae7;Хорол-Небесної сотні 52;Хорол вул. Небесної сотні, 52;87162461-749b-11df-b112-00215aee3ebe
4697a007-cdad-11eb-80d5-000c29800ae7;Хорол-Небесної сотні 77А;Хорол вул. Небесної сотні, 77А;87162461-749b-11df-b112-00215aee3ebe
d125a900-7e1e-11ee-80c1-000c2961d091;Хорол-Незалежності 29;Хорол вул. Незалежності, 29;87162461-749b-11df-b112-00215aee3ebe
6f4ed8de-6536-11eb-80d1-000c29800ae7;Хоростків-Заводська 2/1 Б;Хоростків вул. Заводська, 2/1Б;87162466-749b-11df-b112-00215aee3ebe
55faccd0-6d8b-11e9-80df-1c98ec135261;Хоростків-Князя Володимира 94;Хоростків вул. Князя Володимира, 94;87162466-749b-11df-b112-00215aee3ebe
1c42068f-3291-11e8-80d0-1c98ec135261;Хотин-Олімпійська 68 А (Жжук);Хотин вул. Олімпійська, 68А;87162481-749b-11df-b112-00215aee3ebe
2fc12f95-8878-11ee-80c1-000c2961d091;Хотин-Олімпійська 79;Хотин вул. Олімпійська, 79;87162481-749b-11df-b112-00215aee3ebe
e14d9aa2-a7a6-11e9-80e4-1c98ec135261;Хотин-Шевченка 22;Хотин вул. Шевченка, 22;87162481-749b-11df-b112-00215aee3ebe
0b3a5915-3bec-11ec-80e3-000c29800ae7;Хотів-Паширова 2А;Хотів пл. Паширова, 2А;87162487-749b-11df-b112-00215aee3ebe
9c9d7d53-c404-11ed-80ed-000c29800ae7;Хотів-Шевченка 97;Хотів вул. Шевченка, 97;87162487-749b-11df-b112-00215aee3ebe
e9ed2281-5c6c-11ee-80c0-000c2961d091;Христинівка-Українська 37Б;Христинівка вул. Українська, 37Б;871624b8-749b-11df-b112-00215aee3ebe
2ec52d17-1e1c-11ed-80e7-000c29800ae7;Христинівка-Чорновола 13;Христинівка вул. Чорновола, 13;871624b8-749b-11df-b112-00215aee3ebe
0a0d8bbf-6396-11ea-80c6-000c29800ae7;Хуст;Хуст вул. Львівська, 12;871624dc-749b-11df-b112-00215aee3ebe
f85ad917-40d6-11ee-80c0-000c2961d091;Хуст-900-річчя Хуста 9А;Хуст вул. 900-річчя Хуста, 9А;871624dc-749b-11df-b112-00215aee3ebe
d7e0fa31-f8f6-11eb-80d5-000c29800ae7;Хуст-Карпатської Січі 24;Хуст вул. Карпатської Січі, 24;871624dc-749b-11df-b112-00215aee3ebe
c2af3962-b2e8-11e9-80e4-1c98ec135261;Хуст-Львівська 206;Хуст вул. Львівська, 206;871624dc-749b-11df-b112-00215aee3ebe
d56181c8-64b2-11ec-80e4-000c29800ae7;Хуст-Чехова 16/2;Хуст вул. Жан Роша ( Чехова ), 16/2;871624dc-749b-11df-b112-00215aee3ebe
bd4f379a-47d4-11ee-80c0-000c2961d091;Царичанка-Піонерська 92В;Царичанка вул. Піонерська, 92В;871624fa-749b-11df-b112-00215aee3ebe
394a6fb0-d91e-11ed-80ed-000c29800ae7;Царичанка-Царичанська (Кірова) 73А;Царичанка вул. Царичанська, 73А;871624fa-749b-11df-b112-00215aee3ebe
9f87dd8a-c80e-11ee-80c4-000c2961d091;Царичанка-Центральна 118 З;Царичанка вул. Центральна, 118 З;871624fa-749b-11df-b112-00215aee3ebe
23094e95-47d7-11ee-80c0-000c2961d091;Царичанка-Центральна 34;Царичанка вул. Центральна, 34;871624fa-749b-11df-b112-00215aee3ebe
97bd3a18-d91d-11ed-80ed-000c29800ae7;Царичанка-Центральна 85;Царичанка вул. Центральна, 85;871624fa-749b-11df-b112-00215aee3ebe
8a6d2460-9046-11e6-a7f1-001ec93df11f;ЦСС Київ;;5cb61671-749b-11df-b112-00215aee3ebe
b5623b28-3181-11ec-80e3-000c29800ae7;ЦСС Київ МЕ;;5cb61671-749b-11df-b112-00215aee3ebe
9ddde0f6-ea5a-11de-9277-00215aee3ebe;ЦСС Львів;Львів ЦСС вул. Зелена 147,147;4d7861b5-9043-11e6-a7f1-001ec93df11f
1f598806-490c-11e2-84a8-003048d2b473;ЦСС(Доставка до місця розмитнення);;00000000-0000-0000-0000-000000000000
7471c420-c8c6-11ed-80ed-000c29800ae7;Чабани-Машинобудівників 16;Чабани вул. Машинобудівників, 16;87162562-749b-11df-b112-00215aee3ebe
c7139dcc-20ab-11e9-80dc-1c98ec135261;Чабани-Одеське шосе 12;Чабани шосе Одеське, 12;87162562-749b-11df-b112-00215aee3ebe
2aa89ce0-eb6c-11ee-80c5-000c2961d091;Чагор-Приміська 1А;Чагор вул. Приміська, 1А;87162574-749b-11df-b112-00215aee3ebe
9001f41a-8466-11ee-80c1-000c2961d091;Чайки-Лобановського 21 корп.2;Чайки вул. Лобановського, 21;87162578-749b-11df-b112-00215aee3ebe
f9b45815-08d9-11ed-80e7-000c29800ae7;Чайки-Лобановського 31;Чайки вул. Лобановського (Чайки), 31;87162578-749b-11df-b112-00215aee3ebe
1a15c512-9920-11eb-80d3-000c29800ae7;Чемерівці-Центральна 16/2;Чемерівці вул. Центральна, 16/2;871625d4-749b-11df-b112-00215aee3ebe
df84f5ee-9d9c-11e9-80e2-1c98ec135261;Чемерівці-Центральна 44/1;Чемерівці вул. Центральна, 44/1;871625d4-749b-11df-b112-00215aee3ebe
2d0c8b83-17a5-11df-b41f-00215aee3ebe;Червоноград;Червоноград вул. Хмельницького Богдана, 65;87162707-749b-11df-b112-00215aee3ebe
21d182d8-2f82-11ee-80bd-000c2961d091;Червоноград-Бандери Степана 20А/7 ZAVOD PARKETU;Червоноград вул. Бандери Степана, 20А/7;87162707-749b-11df-b112-00215aee3ebe
f7647a6b-40f7-11ee-80c0-000c2961d091;Червоноград-Бандери Степана 53 Продукти;Червоноград вул. Бандери Степана, 53;87162707-749b-11df-b112-00215aee3ebe
350b4df4-7660-11ee-80c1-000c2961d091;Червоноград-Героїв Майдану 10 Сільпо;Червоноград вул. Героїв Майдану, 10;87162707-749b-11df-b112-00215aee3ebe
eb54eeee-f301-11ed-80ed-000c29800ae7;Червоноград-Грушевського 7 Свій маркет;Червоноград вул. Грушевського, 7;87162707-749b-11df-b112-00215aee3ebe
a2ab2eaf-20a0-11e9-80dc-1c98ec135261;Червоноград-Львівська 51 ОККО;Червоноград вул. Львівська, 51;87162707-749b-11df-b112-00215aee3ebe
d007d19f-e53f-11eb-80d5-000c29800ae7;Червоноград-Святого Володимира 45А Молочний;Червоноград вул. Святого Володимира, 45А;87162707-749b-11df-b112-00215aee3ebe
1ea1f8d1-b1f8-11ed-80ec-000c29800ae7;Червоноград-Сокальська 5 Візовий центр;Червоноград вул. Сокальська, 5;87162707-749b-11df-b112-00215aee3ebe
0620e638-61a4-11ec-80e4-000c29800ae7;Червоноград-Стуса Василя 22 Сім23;Червоноград вул. Стуса Василя, 22;87162707-749b-11df-b112-00215aee3ebe
3f002a71-0446-11ee-80bd-000c2961d091;Червоноград-Стуса Василя 53 Сім23;Червоноград вул. Стуса Василя, 53;87162707-749b-11df-b112-00215aee3ebe
43a0069b-e3b1-11eb-80d5-000c29800ae7;Червоноград-Хмельницького Богдана 14 Сім23;Червоноград вул. Хмельницького Богдана, 14;87162707-749b-11df-b112-00215aee3ebe
8be6f8c1-9b35-11ed-80ec-000c29800ae7;Червоноград-Хмельницького Богдана 61 Rozetka;Червоноград вул. Хмельницького Богдана, 61;87162707-749b-11df-b112-00215aee3ebe
0505c6df-40f9-11ee-80c0-000c2961d091;Червоноград-Чорновола В'ячеслава 20 Продукти;Червоноград вул. Чорновола В'ячеслава, 20;87162707-749b-11df-b112-00215aee3ebe
6fe320ba-d2e3-11ed-80ed-000c29800ae7;Червоноград-Шевченка 12 Lifecell;Червоноград вул. Шевченка, 12;87162707-749b-11df-b112-00215aee3ebe
9dcff8c5-ae38-11ed-80ec-000c29800ae7;Червоноград-Шевченка 16А Сім23;Червоноград вул. Шевченка, 16А;87162707-749b-11df-b112-00215aee3ebe
98d8fc0c-f304-11ed-80ed-000c29800ae7;Червоноград-Шептицького 1 Свій маркет;Червоноград вул. Шептицького, 1;87162707-749b-11df-b112-00215aee3ebe
3aef9b66-53ed-11eb-80d0-000c29800ae7;Червоноград-Шептицького 1 Сім23;Червоноград вул. Шептицького, 1;87162707-749b-11df-b112-00215aee3ebe
d0a207f7-0115-11df-b41f-00215aee3ebe;Черкаси;Черкаси вул. Смілянська, 144/1;8716275b-749b-11df-b112-00215aee3ebe
6181776f-3079-11ee-80bd-000c2961d091;Черкаси-Амброса Сергія 147 Майстер;Черкаси вул. Амброса Сергія (Орджонікідзе),;8716275b-749b-11df-b112-00215aee3ebe
218f40eb-95b6-11ee-80c1-000c2961d091;Черкаси-Анатолія Лупиноса 80 Sandalikshop;Черкаси вул. Анатолія Лупиноса, 80;8716275b-749b-11df-b112-00215aee3ebe
2a7bef1b-6a5c-11e8-80d4-1c98ec135261;Черкаси-Благовісна 227 МІКРОТЕХ;Черкаси вул. Благовісна, 227;8716275b-749b-11df-b112-00215aee3ebe
97b3e894-dd86-11eb-80d5-000c29800ae7;Черкаси-Благовісна 469 Мікро Тех;Черкаси вул. Благовісна, 469;8716275b-749b-11df-b112-00215aee3ebe
b7a96145-c80c-11ea-80ca-000c29800ae7;Черкаси-В'ячеслава Чорновола 118 DONINI;Черкаси вул. Чорновола Вячеслава, 118;8716275b-749b-11df-b112-00215aee3ebe
6b5e2d71-dacd-11ed-80ed-000c29800ae7;Черкаси-Вернигори 2 Оптика KLEIMAN;Черкаси вул. Вернигори, 2;8716275b-749b-11df-b112-00215aee3ebe
904248dc-b156-11ee-80c4-000c2961d091;Черкаси-Вернигори 9 lifecell;Черкаси вул. Вернигори, 9;8716275b-749b-11df-b112-00215aee3ebe
54b083d0-dae8-11ee-80c4-000c2961d091;Черкаси-Вишневецького 34 KRAM Studio;Черкаси вул. Вишневецького, 34;8716275b-749b-11df-b112-00215aee3ebe
bb92c33e-d60b-11ee-80c4-000c2961d091;Черкаси-Володимира Великого 17 Кавярня;Черкаси вул. Володимира Великого ( Гагаріна ), 17;8716275b-749b-11df-b112-00215aee3ebe
80589dc3-bb71-11ee-80c4-000c2961d091;Черкаси-Гагаріна 39/1 Мясоїд;Черкаси пров. Гагаріна, 39/1;8716275b-749b-11df-b112-00215aee3ebe
d995656a-69bf-11ee-80c1-000c2961d091;Черкаси-Героїв Дніпра 4 Біля Дому;Черкаси вул. Героїв Дніпра, 4;8716275b-749b-11df-b112-00215aee3ebe
45da92d2-fed1-11ed-80ed-000c29800ae7;Черкаси-Героїв Майдану 8 Чайка;Черкаси вул. Героїв Майдану ( Гайдара ), 8;8716275b-749b-11df-b112-00215aee3ebe
e4b79a30-8562-11ee-80c1-000c2961d091;Черкаси-Гоголя Миколи 458 Пральня;Черкаси вул. Гоголя Миколи, 458;8716275b-749b-11df-b112-00215aee3ebe
38a9a5ef-1b99-11ec-80df-000c29800ae7;Черкаси-Івана Мазепи 63 Делві;Черкаси вул. Івана Мазепи ( Чайковського ), 63;8716275b-749b-11df-b112-00215aee3ebe
5aaf0a8b-581c-11ec-80e4-000c29800ae7;Черкаси-Казбетська 33А Делві;Черкаси вул. Казбетська (Красіна), 33А;8716275b-749b-11df-b112-00215aee3ebe
1389aa17-5220-11ee-80c0-000c2961d091;Черкаси-Козацька 1/2 Зоотовари;Черкаси вул. Козацька, 1/2;8716275b-749b-11df-b112-00215aee3ebe
171cf092-1bb0-11ec-80df-000c29800ae7;Черкаси-Медичний 7, літ.А-5 Делві;Черкаси пров. Медичний (Боженка), 7, літ.А-5;8716275b-749b-11df-b112-00215aee3ebe
9913e838-c10a-11ee-80c4-000c2961d091;Черкаси-Михайла Грушевського 95 Чистий дім;Черкаси вул. Михайла Грушевського, 95;8716275b-749b-11df-b112-00215aee3ebe
6f260477-e844-11ee-80c5-000c2961d091;Черкаси-Михайла Грушевського 97А-1 Делві;Черкаси вул. Михайла Грушевського, 97;8716275b-749b-11df-b112-00215aee3ebe
32e1c713-e845-11ee-80c5-000c2961d091;Черкаси-Можайського 60 Делві;Черкаси вул. Можайського,;8716275b-749b-11df-b112-00215aee3ebe
664a25ef-eddc-11ee-80c5-000c2961d091;Черкаси-Надпільна 334;Черкаси вул. Надпільна ( Ільїна ), 334;8716275b-749b-11df-b112-00215aee3ebe
aa701019-1b99-11ec-80df-000c29800ae7;Черкаси-Невського Олександра 15/1 Делві;Черкаси пров. Невського Олександра, 15/1;8716275b-749b-11df-b112-00215aee3ebe
ad41da3d-f2fb-11ed-80ed-000c29800ae7;Черкаси-Нижня Горова 44 Rozetka;Черкаси вул. Нижня Горова (Калініна), 44;8716275b-749b-11df-b112-00215aee3ebe
d48dd5f5-199e-11ee-80bd-000c2961d091;Черкаси-Нижня Горова 99А Ляпота;Черкаси вул. Нижня Горова (Калініна), 99А;8716275b-749b-11df-b112-00215aee3ebe
896694dc-bacb-11ee-80c4-000c2961d091;Черкаси-Онопрієнка 2 Хозяїн;Черкаси вул. Онопрієнка, 2;8716275b-749b-11df-b112-00215aee3ebe
5c274307-1b98-11ec-80df-000c29800ae7;Черкаси-Пастерівська 263 Делві;Черкаси вул. Пастерівська, 263;8716275b-749b-11df-b112-00215aee3ebe
05352a33-abb5-11ea-80c8-000c29800ae7;Черкаси-Перемоги 36 Хімчистка;Черкаси Просп. Перемоги (30-річчя Перемоги), 36;8716275b-749b-11df-b112-00215aee3ebe
441d6c6e-d7e0-11ee-80c4-000c2961d091;Черкаси-Перемоги 4/1 Зоотовари;Черкаси вул. Перемоги, 4/1;8716275b-749b-11df-b112-00215aee3ebe
2af788c4-1f1f-11ee-80bd-000c2961d091;Черкаси-Прикордонника Лазаренка 26 Rozetka;Черкаси вул. Прикордонника Лазаренка /Ярославська/, 26;8716275b-749b-11df-b112-00215aee3ebe
9e605d55-0e7a-11ee-80bd-000c2961d091;Черкаси-Прикордонника Лазаренка 26/2 ASIA PARTS;Черкаси вул. Прикордонника Лазаренка /Ярославська/, 26/2;8716275b-749b-11df-b112-00215aee3ebe
62b3dab8-95ba-11ee-80c1-000c2961d091;Черкаси-Припортова 22А Сервісний Центр;Черкаси вул. Припортова, 22А;8716275b-749b-11df-b112-00215aee3ebe
211b1cb9-edda-11ee-80c5-000c2961d091;Черкаси-Припортова 34;Черкаси вул. Припортова, 34;8716275b-749b-11df-b112-00215aee3ebe
80358e60-c5b4-11ee-80c4-000c2961d091;Черкаси-Припортова 42/1 Доміко;Черкаси вул. Припортова, 42/1;8716275b-749b-11df-b112-00215aee3ebe
bfcd9c4d-467b-11ee-80c0-000c2961d091;Черкаси-Самійла Кішки 208 Сузір я;Черкаси вул. Самійла Кішки (Люксембург від 152-254), 208;8716275b-749b-11df-b112-00215aee3ebe
c82e0d6b-301c-11ec-80e3-000c29800ae7;Черкаси-Симиренківська 29 Ветаптека;Черкаси вул. Симиренківська (Рябоконя), 29;8716275b-749b-11df-b112-00215aee3ebe
138e95ee-1bae-11ec-80df-000c29800ae7;Черкаси-Січовий 17 Делві;Черкаси пров. Січовий, 17;8716275b-749b-11df-b112-00215aee3ebe
1624625d-690b-11ee-80c1-000c2961d091;Черкаси-Смілянська 44 One Price;Черкаси вул. Смілянська, 44;8716275b-749b-11df-b112-00215aee3ebe
68cd02ca-f13f-11ec-80e7-000c29800ae7;Черкаси-Смілянська 45 Rozetka;Черкаси вул. Смілянська, 45;8716275b-749b-11df-b112-00215aee3ebe
6e76133b-15cf-11ee-80bd-000c2961d091;Черкаси-Смілянська 96 Rozetka;Черкаси вул. Смілянська, 96;8716275b-749b-11df-b112-00215aee3ebe
c7f0a706-e571-11eb-80d5-000c29800ae7;Черкаси-Сумгаїтська 24/1 HI-Tech;Черкаси вул. Сумгаїтська, 24/1;8716275b-749b-11df-b112-00215aee3ebe
ea9a11dc-a7bd-11e9-80e4-1c98ec135261;Черкаси-Сумгаїтська 8/1 ОККО;Черкаси вул. Сумгаїтська, 8/1;8716275b-749b-11df-b112-00215aee3ebe
9f310cc6-d60d-11ee-80c4-000c2961d091;Черкаси-Теліги Олени 1 ЗооЛис;Черкаси вул. Теліги Олени (Конєва), 1;8716275b-749b-11df-b112-00215aee3ebe
14e701af-abb6-11ea-80c8-000c29800ae7;Черкаси-Університетська 27 Хімчистка;Черкаси вул. Університетська, 27;8716275b-749b-11df-b112-00215aee3ebe
94bffb1f-1b9a-11ec-80df-000c29800ae7;Черкаси-Хіміків 28 Делві;Черкаси Просп. Хіміків, 28;8716275b-749b-11df-b112-00215aee3ebe
b1d420cc-c26e-11ed-80ed-000c29800ae7;Черкаси-Хрещатик 193/2 Доміко;Черкаси вул. Хрещатик, 193/2;8716275b-749b-11df-b112-00215aee3ebe
dcecb4d6-ff38-11eb-80d6-000c29800ae7;Черкаси-Хрещатик 255/2 Європошта;Черкаси вул. Хрещатик, 255/2;8716275b-749b-11df-b112-00215aee3ebe
1deac5ad-1b9a-11ec-80df-000c29800ae7;Черкаси-Чалого Михайла 17 Делві;Черкаси вул. Чалого Михайла, 17;8716275b-749b-11df-b112-00215aee3ebe
c9cc7ed4-1b97-11ec-80df-000c29800ae7;Черкаси-Чехова 209/1 Делві;Черкаси вул. Чехова, 209/1;8716275b-749b-11df-b112-00215aee3ebe
be91f4e6-1b98-11ec-80df-000c29800ae7;Черкаси-Чигиринська 40 Делві;Черкаси вул. Чигиринська, 40;8716275b-749b-11df-b112-00215aee3ebe
4ee9da8a-d5c0-11eb-80d5-000c29800ae7;Черкаси-Чорновола Вячеслава 112/3 Магазин;Черкаси вул. Чорновола Вячеслава, 112/3;8716275b-749b-11df-b112-00215aee3ebe
613ad4f5-a7bd-11e9-80e4-1c98ec135261;Черкаси-Чорновола Вячеслава 164/2 ОККО;Черкаси вул. Чорновола Вячеслава, 164/2;8716275b-749b-11df-b112-00215aee3ebe
394cc1d1-0c34-11ee-80bd-000c2961d091;Черкаси-Чорновола Вячеслава 33 Делві;Черкаси вул. Чорновола Вячеслава, 33;8716275b-749b-11df-b112-00215aee3ebe
161b9454-7803-11ee-80c1-000c2961d091;Черкаси-Чорновола Вячеслава 37/1 Broparts;Черкаси вул. Чорновола Вячеслава, 37/1;8716275b-749b-11df-b112-00215aee3ebe
7ec74e51-5e00-11ee-80c0-000c2961d091;Черкаси-Шевченка 132 Rozetka;Черкаси бул. Шевченка, 132;8716275b-749b-11df-b112-00215aee3ebe
43731af2-3c44-11ee-80c0-000c2961d091;Черкаси-Шевченка 135 Оленка;Черкаси бул. Шевченка, 135;8716275b-749b-11df-b112-00215aee3ebe
66c9a72e-9b66-11ee-80c1-000c2961d091;Черкаси-Шевченка 207 Lifecell;Черкаси бул. Шевченка, 207;8716275b-749b-11df-b112-00215aee3ebe
a5f21d5f-7660-11ee-80c1-000c2961d091;Черкаси-Шевченка 207 Сільпо;Черкаси бул. Шевченка, 207;8716275b-749b-11df-b112-00215aee3ebe
62f0b744-7661-11ee-80c1-000c2961d091;Черкаси-Шевченка 208/1 Сільпо;Черкаси бул. Шевченка, 208/1;8716275b-749b-11df-b112-00215aee3ebe
09430c0d-91ce-11e9-80e2-1c98ec135261;Черкаси-Шевченка 208/1 Центр Замовлень;Черкаси бул. Шевченка, 208/1;8716275b-749b-11df-b112-00215aee3ebe
d6b0a3f7-d4b6-11ee-80c4-000c2961d091;Черкаси-Шевченка 243 прим. 2 ISERVICE;Черкаси бул. Шевченка, 243;8716275b-749b-11df-b112-00215aee3ebe
d9a09b33-f0bb-11ec-80e7-000c29800ae7;Черкаси-Шевченка 318 Rozetka;Черкаси бул. Шевченка, 318;8716275b-749b-11df-b112-00215aee3ebe
e43315ef-c805-11ea-80ca-000c29800ae7;Черкаси-Шевченка 390 Хімчистка;Черкаси бул. Шевченка, 390;8716275b-749b-11df-b112-00215aee3ebe
960aa71c-b2d6-11e9-80e4-1c98ec135261;Черкаси-Шевченка 417 Зоотовари;Черкаси вул. Шевченка, 417;8716275b-749b-11df-b112-00215aee3ebe
8ac133b4-b4af-11eb-80d4-000c29800ae7;Черкаське-Лісова 18;Черкаське вул. Лісова, 18;87162762-749b-11df-b112-00215aee3ebe
9e40dc7b-010e-11df-b41f-00215aee3ebe;Чернівці;Чернівці вул. Руська, 248;8716279d-749b-11df-b112-00215aee3ebe
0ce1df20-bed1-11eb-80d5-000c29800ae7;Чернівці (Вінницька обл)-Святомиколаївська 99А;Чернівці вул. Святомиколаївська, 99А;8716279e-749b-11df-b112-00215aee3ebe
5bdf60ed-d61c-11ee-80c4-000c2961d091;Чернівці-Бандери Степана 6 магазин Центр;Чернівці вул. Бандери Степана ( Ватутіна ), 6;8716279d-749b-11df-b112-00215aee3ebe
24de067c-1fd9-11e9-80dc-1c98ec135261;Чернівці-Вільшини 7В Продукти;Чернівці вул. Вільшини, 7В;8716279d-749b-11df-b112-00215aee3ebe
f56a5503-a7aa-11e9-80e4-1c98ec135261;Чернівці-Галицький Шлях 46М ОККО;Чернівці вул. Галицький Шлях, 46М;8716279d-749b-11df-b112-00215aee3ebe
28c0da68-d61a-11ee-80c4-000c2961d091;Чернівці-Героїв Майдану 270 Ситна Хата;Чернівці вул. Героїв Майдану (Червоноармійська), 270;8716279d-749b-11df-b112-00215aee3ebe
2d882696-7663-11ee-80c1-000c2961d091;Чернівці-Героїв Майдану 71 Сільпо;Чернівці вул. Героїв Майдану (Червоноармійська), 71;8716279d-749b-11df-b112-00215aee3ebe
ba180937-853c-11ee-80c1-000c2961d091;Чернівці-Головна 200 Rozetka;Чернівці вул. Головна, 200;8716279d-749b-11df-b112-00215aee3ebe
83a599e2-7663-11ee-80c1-000c2961d091;Чернівці-Головна 265А Сільпо;Чернівці вул. Головна, 265А;8716279d-749b-11df-b112-00215aee3ebe
6c8ec2cb-fada-11ed-80ed-000c29800ae7;Чернівці-Головна 42 Lifecell;Чернівці вул. Головна, 42;8716279d-749b-11df-b112-00215aee3ebe
853082ee-a0da-11ee-80c4-000c2961d091;Чернівці-Ентузіастів 11 Карамбола;Чернівці вул. Ентузіастів, 11;8716279d-749b-11df-b112-00215aee3ebe
38514202-b794-11ea-80c8-000c29800ae7;Чернівці-Ентузіастів 1А GRANOVSKI;Чернівці вул. Ентузіастів, 1А;8716279d-749b-11df-b112-00215aee3ebe
2297c453-f567-11ed-80ed-000c29800ae7;Чернівці-Ентузіастів 2 Б Lifecell;Чернівці вул. Ентузіастів, 2 Г;8716279d-749b-11df-b112-00215aee3ebe
0398a5c1-dc5b-11e8-80d7-1c98ec135261;Чернівці-Ентузіастів 2А Моб. телефони;Чернівці вул. Ентузіастів, 2А;8716279d-749b-11df-b112-00215aee3ebe
86e2f100-aa1e-11ee-80c4-000c2961d091;Чернівці-Ентузіастів 3АCARE PLUS;Чернівці вул. Ентузіастів, 3А;8716279d-749b-11df-b112-00215aee3ebe
8e1c4cca-ff41-11eb-80d6-000c29800ae7;Чернівці-Заньковецької Марії 25 Європошта;Чернівці вул. Заньковецької Марії, 25;8716279d-749b-11df-b112-00215aee3ebe
03f74733-8795-11ee-80c1-000c2961d091;Чернівці-Заньковецької Марії 9 Євро Маркет;Чернівці вул. Заньковецької Марії, 9А;8716279d-749b-11df-b112-00215aee3ebe
5c2ff589-8b8e-11e0-82d0-003048d2b473;Чернівці-Калинівська 13;Чернівці вул. Калинівська, 13;8716279d-749b-11df-b112-00215aee3ebe
ea84c0d6-a7a9-11e9-80e4-1c98ec135261;Чернівці-Калинівська 1А ОККО;Чернівці вул. Калинівська, 1А;8716279d-749b-11df-b112-00215aee3ebe
65e6f91a-0b1e-11ec-80d6-000c29800ae7;Чернівці-Каспрука 15 Гайка;Чернівці вул. Каспрука, 15;8716279d-749b-11df-b112-00215aee3ebe
1709acf6-cd53-11ed-80ed-000c29800ae7;Чернівці-Київська 31Ж маркет Tutto;Чернівці вул. Київська, 31Ж;8716279d-749b-11df-b112-00215aee3ebe
aacabc62-dace-11ed-80ed-000c29800ae7;Чернівці-Кобилянської 20 Оптика KLEIMAN;Чернівці вул. Кобилянської, 20;8716279d-749b-11df-b112-00215aee3ebe
7cee7bdd-e5a0-11ed-80ed-000c29800ae7;Чернівці-Комарова Володимира 30Б KANO;Чернівці вул. Комарова Володимира, 30Б;8716279d-749b-11df-b112-00215aee3ebe
2445b35b-2d6e-11eb-80ce-000c29800ae7;Чернівці-Комарова Володимира 33Г Моб Аксесуари;Чернівці вул. Комарова Володимира, 33Г;8716279d-749b-11df-b112-00215aee3ebe
c99c9f6c-65c4-11e9-80df-1c98ec135261;Чернівці-Небесної Сотні 23 hoco;Чернівці вул. Небесної Сотні (Стасюка), 23;8716279d-749b-11df-b112-00215aee3ebe
051cb336-3bda-11ec-80e3-000c29800ae7;Чернівці-Небесної Сотні 30 KING- SERVISE;Чернівці вул. Небесної Сотні (Стасюка), 30;8716279d-749b-11df-b112-00215aee3ebe
6298fe10-ac87-11ea-80c8-000c29800ae7;Чернівці-Небесної Сотні 58 Ремонт у ПАПИ;Чернівці вул. Небесної Сотні (Стасюка), 58;8716279d-749b-11df-b112-00215aee3ebe
f76c9fd4-fa46-11ed-80ed-000c29800ae7;Чернівці-Незалежності 111 Lifecell;Чернівці Просп. Незалежності, 111;8716279d-749b-11df-b112-00215aee3ebe
9b6d61b9-6385-11ee-80c0-000c2961d091;Чернівці-Незалежності 131 Шафран;Чернівці Просп. Незалежності, 131;8716279d-749b-11df-b112-00215aee3ebe
65bfa90e-f0b9-11ec-80e7-000c29800ae7;Чернівці-Незалежності,119 Rozetka;Чернівці Просп. Незалежності, 119;8716279d-749b-11df-b112-00215aee3ebe
983862b3-aa18-11ee-80c4-000c2961d091;Чернівці-Південно-Кільцева 6А Вікна Двері;Чернівці вул. Південно-Кільцева, 6А;8716279d-749b-11df-b112-00215aee3ebe
90df847c-a023-11ee-80c4-000c2961d091;Чернівці-Руська 126 Wok in Roll;Чернівці вул. Руська, 126;8716279d-749b-11df-b112-00215aee3ebe
ed9a5314-e59e-11ed-80ed-000c29800ae7;Чернівці-Руська 219Є Телефони у Папи;Чернівці вул. Руська, 219Є;8716279d-749b-11df-b112-00215aee3ebe
b74d8e39-910f-11ee-80c1-000c2961d091;Чернівці-Руська 219Є ТЦ Шик;Чернівці вул. Руська, 219Є;8716279d-749b-11df-b112-00215aee3ebe
e8743656-0130-11ee-80bd-000c2961d091;Чернівці-Руська 248 Rozetka;Чернівці вул. Руська, 248;8716279d-749b-11df-b112-00215aee3ebe
834b01ff-e59e-11ed-80ed-000c29800ae7;Чернівці-Руська 250А Телефони у Папи;Чернівці вул. Руська, 250А;8716279d-749b-11df-b112-00215aee3ebe
debc6d6e-a7a7-11e9-80e4-1c98ec135261;Чернівці-Руська 250Б ОККО;Чернівці вул. Руська, 250Б;8716279d-749b-11df-b112-00215aee3ebe
fb9d93c6-b3c2-11eb-80d4-000c29800ae7;Чернівці-Руська 269 Економ;Чернівці вул. Руська, 269;8716279d-749b-11df-b112-00215aee3ebe
8a8fb63a-aa52-11ee-80c4-000c2961d091;Чернівці-Святомиколаївська 174/3;Чернівці вул. Святомиколаївська, 174/3;8716279e-749b-11df-b112-00215aee3ebe
0709f2b0-8924-11e9-80e0-1c98ec135261;Чернівці-Січових Стрільців 6Б ВСЕ ДЛЯ ДОМУ;Чернівці вул. Січових Стрільців, 6Б;8716279d-749b-11df-b112-00215aee3ebe
717caf77-99b4-11ee-80c1-000c2961d091;Чернівці-Хотинська 1 Кавярня;Чернівці вул. Хотинська, 1;8716279d-749b-11df-b112-00215aee3ebe
67ea7fa2-a7aa-11e9-80e4-1c98ec135261;Чернівці-Хотинська 41 ОККО;Чернівці вул. Хотинська, 41;8716279d-749b-11df-b112-00215aee3ebe
68edbe9d-b3c1-11eb-80d4-000c29800ae7;Чернівці-Чкалова 13 Бона;Чернівці вул. Леоніда Каденюка (Чкалова), 13;8716279d-749b-11df-b112-00215aee3ebe
b6950bdf-d5ba-11eb-80d5-000c29800ae7;Чернівці-Чорноморська 4А Хімчистка;Чернівці вул. Чорноморська, 4А;8716279d-749b-11df-b112-00215aee3ebe
75ec2996-a8dd-11de-bac3-0030485903e8;Чернігів;Чернігів вул. Ціолковського, 22;8716279f-749b-11df-b112-00215aee3ebe
1610b13e-c46f-11eb-80d5-000c29800ae7;Чернігів-1-ї танкової бригади 29 Біле Сухе;Чернігів вул. 1-ї танкової бригади (Бєлова), 29;8716279f-749b-11df-b112-00215aee3ebe
d22acd18-578e-11ee-80c0-000c2961d091;Чернігів-77-ї Гвардійської дивізії 1 Сільпо;Чернігів вул. 77-ї Гвардійської дивізії, 1;8716279f-749b-11df-b112-00215aee3ebe
a3d6e2b4-9b69-11ee-80c1-000c2961d091;Чернігів-77-ї Гвардійської дивізії 1В Lifecell;Чернігів вул. 77-ї Гвардійської дивізії, 1В;8716279f-749b-11df-b112-00215aee3ebe
4d3f11fe-9840-11ee-80c1-000c2961d091;Чернігів-Гетьмана Полуботка 26 Зоомагазин;Чернігів вул. Гетьмана Полуботка, 26;8716279f-749b-11df-b112-00215aee3ebe
ecffc8cc-48e6-11eb-80d0-000c29800ae7;Чернігів-Доценка 30 Кавярня;Чернігів вул. Доценка, 30;8716279f-749b-11df-b112-00215aee3ebe
329dfdc4-d57e-11ee-80c4-000c2961d091;Чернігів-Доценка 7 Bierwelle;Чернігів вул. Доценка, 7;8716279f-749b-11df-b112-00215aee3ebe
3a36dc9f-cb50-11ee-80c4-000c2961d091;Чернігів-Єськова 10 Аптека оптові ціни;Чернігів вул. Єськова, 10;8716279f-749b-11df-b112-00215aee3ebe
0c1a772d-7b40-11eb-80d2-000c29800ae7;Чернігів-Єськова 10 Кавярня;Чернігів вул. Єськова, 10;8716279f-749b-11df-b112-00215aee3ebe
3adc031b-578f-11ee-80c0-000c2961d091;Чернігів-Захисників України 9 Сільпо;Чернігів вул. Захисників України, 9;8716279f-749b-11df-b112-00215aee3ebe
f5d2fce3-d57c-11ee-80c4-000c2961d091;Чернігів-Івана Мазепи 37 Bierwelle;Чернігів вул. Івана Мазепи, 37;8716279f-749b-11df-b112-00215aee3ebe
d454bdae-1be7-11ee-80bd-000c2961d091;Чернігів-Івана Мазепи 38 Кофейня;Чернігів вул. Івана Мазепи, 38;8716279f-749b-11df-b112-00215aee3ebe
f775d18a-790f-11ec-80e4-000c29800ae7;Чернігів-Івана Мазепи 59А Сівер-Агротех;Чернігів вул. Івана Мазепи, 59А;8716279f-749b-11df-b112-00215aee3ebe
f5f16634-20bb-11e9-80dc-1c98ec135261;Чернігів-Івана Мазепи 79 ОККО;Чернігів вул. Івана Мазепи, 79;8716279f-749b-11df-b112-00215aee3ebe
54d44b12-f0ba-11ec-80e7-000c29800ae7;Чернігів-Кирпоноса 26 Rozetka;Чернігів вул. Княжа (Кирпоноса), 26;8716279f-749b-11df-b112-00215aee3ebe
ab00cc9b-2804-11eb-80ce-000c29800ae7;Чернігів-Кирпоноса 40 MobComplekt;Чернігів вул. Княжа (Кирпоноса), 40;8716279f-749b-11df-b112-00215aee3ebe
8e4c09e8-82eb-11e9-80e0-1c98ec135261;Чернігів-Кільцева 1 ОККО;Чернігів вул. Кільцева, 1;8716279f-749b-11df-b112-00215aee3ebe
d6c69430-d57d-11ee-80c4-000c2961d091;Чернігів-Козацька 20А Bierwelle;Чернігів вул. Козацька, 20А;8716279f-749b-11df-b112-00215aee3ebe
6f884afe-dbd1-11ee-80c4-000c2961d091;Чернігів-Левка Лук'яненка 17 Stock;Чернігів Просп. Левка Лук'яненка (кол.Рокосовського), 17;8716279f-749b-11df-b112-00215aee3ebe
2f7c1fae-d574-11ee-80c4-000c2961d091;Чернігів-Левка Лук'яненка 17 Народне Пиво;Чернігів Просп. Левка Лук'яненка (кол.Рокосовського), 17;8716279f-749b-11df-b112-00215aee3ebe
523fc705-535e-11ec-80e4-000c29800ae7;Чернігів-Левка Лук'яненка 25 Lifecell;Чернігів Просп. Левка Лук'яненка (кол.Рокосовського), 25;8716279f-749b-11df-b112-00215aee3ebe
fe5eb341-696f-11ed-80ec-000c29800ae7;Чернігів-Левка Лук'яненка 25 СЦ ТОЧКА;Чернігів Просп. Левка Лук'яненка (кол.Рокосовського), 25;8716279f-749b-11df-b112-00215aee3ebe
920f119d-c46e-11eb-80d5-000c29800ae7;Чернігів-Левка Лук'яненка 41 Біле Сухе;Чернігів Просп. Левка Лук'яненка (кол.Рокосовського), 41;8716279f-749b-11df-b112-00215aee3ebe
3eea064a-a419-11eb-80d3-000c29800ae7;Чернігів-Левка Лук'яненка 6 Здорова Вода;Чернігів Просп. Левка Лук'яненка (кол.Рокосовського), 6;8716279f-749b-11df-b112-00215aee3ebe
6247449e-d570-11ee-80c4-000c2961d091;Чернігів-Левка Лук'яненка 62 Bierwelle;Чернігів Просп. Левка Лук'яненка (кол.Рокосовського), 62;8716279f-749b-11df-b112-00215aee3ebe
ef0b9348-f0ba-11ec-80e7-000c29800ae7;Чернігів-Левка Лук'яненка 70 Rozetka;Чернігів Просп. Левка Лук'яненка (кол.Рокосовського), 70;8716279f-749b-11df-b112-00215aee3ebe
74d654a3-d56c-11ee-80c4-000c2961d091;Чернігів-Любецька 2 Bierwelle;Чернігів вул. Любецька, 2;8716279f-749b-11df-b112-00215aee3ebe
4b06e5ef-d4e4-11eb-80d5-000c29800ae7;Чернігів-Любецька 56 Спецназ;Чернігів вул. Любецька, 56;8716279f-749b-11df-b112-00215aee3ebe
ff34ede0-c471-11eb-80d5-000c29800ae7;Чернігів-Льотна 10 Біле Сухе;Чернігів вул. Льотна (Червоногвардійська), 10;8716279f-749b-11df-b112-00215aee3ebe
824ad986-4739-11ee-80c0-000c2961d091;Чернігів-Миру 126 Lifecell;Чернігів Просп. Миру, 126;8716279f-749b-11df-b112-00215aee3ebe
ab8456e5-d714-11ee-80c4-000c2961d091;Чернігів-Миру 194 Rozetka;Чернігів Просп. Миру, 194;8716279f-749b-11df-b112-00215aee3ebe
7c7af8d3-ae48-11eb-80d4-000c29800ae7;Чернігів-Миру 194 Здорова Вода;Чернігів Просп. Миру, 194;8716279f-749b-11df-b112-00215aee3ebe
334374a8-838d-11e9-80e0-1c98ec135261;Чернігів-Миру 194 ОККО;Чернігів Просп. Миру, 194;8716279f-749b-11df-b112-00215aee3ebe
129a7e57-644e-11ee-80c0-000c2961d091;Чернігів-Миру 194 Оптика;Чернігів Просп. Миру, 194;8716279f-749b-11df-b112-00215aee3ebe
50e35ba1-9b67-11ee-80c1-000c2961d091;Чернігів-Миру 32 Lifecell;Чернігів вул. Миру, 32;8716279f-749b-11df-b112-00215aee3ebe
33dbbcab-5d12-11ee-80c0-000c2961d091;Чернігів-Миру 34 Avon;Чернігів Просп. Миру, 34;8716279f-749b-11df-b112-00215aee3ebe
f6cd6b7a-cb4f-11ee-80c4-000c2961d091;Чернігів-Миру 44 Аптека оптові ціни;Чернігів Просп. Миру, 44;8716279f-749b-11df-b112-00215aee3ebe
88855269-cf15-11ee-80c4-000c2961d091;Чернігів-Миру 44 Чайkoff;Чернігів Просп. Миру, 44;8716279f-749b-11df-b112-00215aee3ebe
76eecf55-578e-11ee-80c0-000c2961d091;Чернігів-Миру 49 Сільпо;Чернігів Просп. Миру, 49;8716279f-749b-11df-b112-00215aee3ebe
944208a3-d57d-11ee-80c4-000c2961d091;Чернігів-Миру 50 Bierwelle;Чернігів Просп. Миру, 50;8716279f-749b-11df-b112-00215aee3ebe
d1ba3568-26de-11ee-80bd-000c2961d091;Чернігів-Миру 53А Rozetka;Чернігів Просп. Миру, 53А;8716279f-749b-11df-b112-00215aee3ebe
8879fd78-d56b-11ee-80c4-000c2961d091;Чернігів-Мстиславська 52Д Народне Пиво;Чернігів вул. Мстиславська, 52Д;8716279f-749b-11df-b112-00215aee3ebe
f1efd5c2-535d-11ec-80e4-000c29800ae7;Чернігів-Музична 1 Lifecell;Чернігів вул. Музична, 1;8716279f-749b-11df-b112-00215aee3ebe
ef3551b2-578d-11ee-80c0-000c2961d091;Чернігів-Музична 1 Сільпо;Чернігів вул. Музична, 1;8716279f-749b-11df-b112-00215aee3ebe
74153371-5082-11ee-80c0-000c2961d091;Чернігів-Музична 1 Сонечко;Чернігів вул. Музична, 1;8716279f-749b-11df-b112-00215aee3ebe
5b47d3a9-d572-11ee-80c4-000c2961d091;Чернігів-Незалежності 24 Світ Пива;Чернігів вул. Незалежності, 24;8716279f-749b-11df-b112-00215aee3ebe
00e6ac2d-7bde-11ed-80ec-000c29800ae7;Чернігів-Незалежності 25 Rozetka;Чернігів вул. Незалежності, 25;8716279f-749b-11df-b112-00215aee3ebe
b20a434a-cb4f-11ee-80c4-000c2961d091;Чернігів-Незалежності 46 Аптека;Чернігів вул. Незалежності, 46;8716279f-749b-11df-b112-00215aee3ebe
979b9e85-d573-11ee-80c4-000c2961d091;Чернігів-Перемоги 10 Світ Пива;Чернігів Просп. Перемоги, 10;8716279f-749b-11df-b112-00215aee3ebe
24fb8040-d56c-11ee-80c4-000c2961d091;Чернігів-Перемоги 103 Bierwelle;Чернігів Просп. Перемоги, 103;8716279f-749b-11df-b112-00215aee3ebe
1da57b77-a0f7-11ee-80c4-000c2961d091;Чернігів-Перемоги 119А Bierwille;Чернігів Просп. Перемоги, 119А;8716279f-749b-11df-b112-00215aee3ebe
8397db40-4155-11ec-80e3-000c29800ae7;Чернігів-Перемоги 3 Крамичка;Чернігів Просп. Перемоги, 3;8716279f-749b-11df-b112-00215aee3ebe
c1126862-9b67-11ee-80c1-000c2961d091;Чернігів-Перемоги 83 Lifecell;Чернігів Просп. Перемоги, 83;8716279f-749b-11df-b112-00215aee3ebe
47bc99e9-43b3-11ec-80e3-000c29800ae7;Чернігів-Перемоги 90 Біле Сухе;Чернігів Просп. Перемоги, 90;8716279f-749b-11df-b112-00215aee3ebe
ff79f32b-d571-11ee-80c4-000c2961d091;Чернігів-Пухова 129 Bierwelle;Чернігів вул. Пухова, 129;8716279f-749b-11df-b112-00215aee3ebe
02a3ba28-7959-11e8-80d4-1c98ec135261;Чернігів-Пухова 142 Товари для дому;Чернігів вул. Пухова, 142;8716279f-749b-11df-b112-00215aee3ebe
b980fa11-cf55-11eb-80d5-000c29800ae7;Чернігів-Реміснича 58 Зоосвіт;Чернігів вул. Реміснича, 58;8716279f-749b-11df-b112-00215aee3ebe
a1ac7ae4-dbd0-11ee-80c4-000c2961d091;Чернігів-Ринкова 2 Stock;Чернігів вул. Ринкова, 2;8716279f-749b-11df-b112-00215aee3ebe
ad3d708f-836d-11e9-80e0-1c98ec135261;Чернігів-Ріпкинська 3Г ОККО;Чернігів вул. Ріпкинська, 3Г;8716279f-749b-11df-b112-00215aee3ebe
81880da3-f3db-11ed-80ed-000c29800ae7;Чернігів-Ріпкинська 4 Магазинчик;Чернігів вул. Ріпкинська, 4;8716279f-749b-11df-b112-00215aee3ebe
08ff79a8-d573-11ee-80c4-000c2961d091;Чернігів-Софіївська 38 Світ Пива;Чернігів вул. Софіївська (Богуна), 38;8716279f-749b-11df-b112-00215aee3ebe
1141b0d9-2204-11ec-80df-000c29800ae7;Чернігів-Старобілоуська 73 УкрІнструмент;Чернігів вул. Старобілоуська, 73;8716279f-749b-11df-b112-00215aee3ebe
bd38f655-d56f-11ee-80c4-000c2961d091;Чернігів-Стрілецька 1 Bierwelle;Чернігів вул. Стрілецька, 1;8716279f-749b-11df-b112-00215aee3ebe
0b7f07af-cf57-11eb-80d5-000c29800ae7;Чернігів-Стрілецька 102 Зоосвіт;Чернігів вул. Стрілецька, 102;8716279f-749b-11df-b112-00215aee3ebe
177fd258-d570-11ee-80c4-000c2961d091;Чернігів-Стрілецька 88 Bierwelle;Чернігів вул. Стрілецька, 88;8716279f-749b-11df-b112-00215aee3ebe
2206a916-0a87-11e9-80d9-1c98ec135261;Чернігів-Текстильників 5 Фото Услуги;Чернігів вул. Текстильників, 5;8716279f-749b-11df-b112-00215aee3ebe
2b3e0c53-855b-11ee-80c1-000c2961d091;Чернігів-Текстильників 7 Dark and Light;Чернігів вул. Текстильників, 7;8716279f-749b-11df-b112-00215aee3ebe
40e065ee-d56f-11ee-80c4-000c2961d091;Чернігів-Ціолковського 2А;Чернігів вул. Ціолковського, 2А;8716279f-749b-11df-b112-00215aee3ebe
ab7a18a8-d574-11ee-80c4-000c2961d091;Чернігів-Шевченка 111В Bierwelle;Чернігів вул. Шевченка, 111В;8716279f-749b-11df-b112-00215aee3ebe
29523ff1-b211-11ea-80c8-000c29800ae7;Чернігів-Шевченка 112А Копіцентр Оптовичок;Чернігів вул. Шевченка, 112А;8716279f-749b-11df-b112-00215aee3ebe
e74e7dde-a0dc-11ee-80c4-000c2961d091;Чернігів-Шевченка 114А Rozetka;Чернігів вул. Шевченка, 114А;8716279f-749b-11df-b112-00215aee3ebe
874c7d40-19b1-11ee-80bd-000c2961d091;Черняхів-Володимирська 4;Черняхів вул. Володимирська, 4;871627b7-749b-11df-b112-00215aee3ebe
9feb2fae-a712-11e9-80e2-1c98ec135261;Чечельник-Героїв Майдану (Леніна) 64;Чечельник вул. Героїв Майдану (Леніна), 64;871627de-749b-11df-b112-00215aee3ebe
42e5cc2b-bed5-11eb-80d5-000c29800ae7;Чечельник-Жовтнева 31;Чечельник вул. Жовтнева, 31;871627de-749b-11df-b112-00215aee3ebe
fd5203b0-ad10-11ed-80ec-000c29800ae7;Чигирин-Хмельницького Богдана 86;Чигирин вул. Хмельницького Богдана, 86;871627e3-749b-11df-b112-00215aee3ebe
45c159a7-9931-11eb-80d3-000c29800ae7;Чоп-Головна 2/6;Чоп вул. Головна, 2/6;8716282d-749b-11df-b112-00215aee3ebe
404349bc-b2e3-11e9-80e4-1c98ec135261;Чоп-Ужгородська 9;Чоп вул. Ужгородська, 9;8716282d-749b-11df-b112-00215aee3ebe
148ce79a-3d15-11ee-80c0-000c2961d091;Чорнобай-Центральна 80;Чорнобай вул. Центральна, 80;8716284f-749b-11df-b112-00215aee3ebe
d2c220da-31f4-11ee-80bd-000c2961d091;Чорноморськ-1-го Травня 23;Чорноморськ вул. 1-го Травня, 23;56bdd8f8-749b-11df-b112-00215aee3ebe
70faf4cd-173c-11ee-80bd-000c2961d091;Чорноморськ-1-го Травня 7;Чорноморськ вул. 1-го Травня, 7;56bdd8f8-749b-11df-b112-00215aee3ebe
d1bab690-3832-11ee-80c0-000c2961d091;Чорноморськ-Вишнева 16;Чорноморськ вул. Вишнева, 16;56bdd8f8-749b-11df-b112-00215aee3ebe
44787701-5dcd-11ee-80c0-000c2961d091;Чорноморськ-Данченка 9;Чорноморськ вул. Данченка, 9;56bdd8f8-749b-11df-b112-00215aee3ebe
f6ea6d36-1742-11ee-80bd-000c2961d091;Чорноморськ-Миру 18/2Н;Чорноморськ пров. Миру, 18/2Н;56bdd8f8-749b-11df-b112-00215aee3ebe
0365058b-7664-11ee-80c1-000c2961d091;Чорноморськ-Миру 19;Чорноморськ Просп. Миру, 19;56bdd8f8-749b-11df-b112-00215aee3ebe
87f4a377-f947-11ed-80ed-000c29800ae7;Чорноморськ-Миру 35А;Чорноморськ Просп. Миру, 35А;56bdd8f8-749b-11df-b112-00215aee3ebe
eb3bdb8e-adea-11ed-80ec-000c29800ae7;Чорноморськ-Миру 39Н;Чорноморськ Просп. Миру, 39Н;56bdd8f8-749b-11df-b112-00215aee3ebe
1ef762f3-a7a3-11e9-80e4-1c98ec135261;Чортків- Бандери Степана 91;Чортків вул. Бандери Степана, 91;8716287b-749b-11df-b112-00215aee3ebe
e5f3ce2f-5f25-11ec-80e4-000c29800ae7;Чортків-Володимира Великого 1Е;Чортків вул. Володимира Великого, 1Е;8716287b-749b-11df-b112-00215aee3ebe
043417c6-d3a4-11e9-80e4-1c98ec135261;Чортків-Залізнична 23;Чортків вул. Залізнична, 23;8716287b-749b-11df-b112-00215aee3ebe
6564cb76-0c49-11ee-80bd-000c2961d091;Чортків-Незалежності 133;Чортків вул. Незалежності, 133;8716287b-749b-11df-b112-00215aee3ebe
c085d6ab-16de-11ec-80df-000c29800ae7;Чубинське-Виставкова 10;Чубинське вул. Виставкова, 10;87162884-749b-11df-b112-00215aee3ebe
2e5ffff3-9043-11ee-80c1-000c2961d091;Чубинське-Виставкова, 14;Чубинське вул. Виставкова, 14;87162884-749b-11df-b112-00215aee3ebe
b84cb11f-cb25-11ee-80c4-000c2961d091;Чубинське-Погребняка 13;Чубинське вул. Погребняка, 13;87162884-749b-11df-b112-00215aee3ebe
1a393fef-8dd9-11ee-80c1-000c2961d091;Чубинське-Погребняка 14;Чубинське вул. Погребняка, 14;87162884-749b-11df-b112-00215aee3ebe
093899c6-6e5f-11ee-80c1-000c2961d091;Чугуїв-Жаданівського 6;Чугуїв вул. Торгова (Жаданівського), 6;8716288d-749b-11df-b112-00215aee3ebe
05708577-622d-11ec-80e4-000c29800ae7;Чугуїв-Комарова Космонавта 5Б;Чугуїв бул. Комарова Космонавта, 5Б;8716288d-749b-11df-b112-00215aee3ebe
344f0bbe-6767-11ee-80c0-000c2961d091;Чугуїв-Комарова Космонавта 8;Чугуїв бул. Комарова Космонавта, 8;8716288d-749b-11df-b112-00215aee3ebe
8b6d8e23-5dff-11ee-80c0-000c2961d091;Чугуїв-Харківська 127;Чугуїв вул. Харківська, 127;8716288d-749b-11df-b112-00215aee3ebe
699d040c-002f-11ec-80d6-000c29800ae7;Чуднів-Героїв Майдану 140;Чуднів вул. Героїв Майдану, 140;87162898-749b-11df-b112-00215aee3ebe
eb7dfa57-cd1d-11eb-80d5-000c29800ae7;Чуднів-Героїв Майдану 165;Чуднів вул. Героїв Майдану, 165;87162898-749b-11df-b112-00215aee3ebe
e54ac3de-0478-11ee-80bd-000c2961d091;Чутове-Короленка 8;Чутове вул. Короленка, 8;871628b7-749b-11df-b112-00215aee3ebe
7c54d69c-9a2b-11eb-80d3-000c29800ae7;Чутове-Набережна 2в;Чутове вул. Набережна, 2В;871628b7-749b-11df-b112-00215aee3ebe
8a06546f-7fc0-11ee-80c1-000c2961d091;Чутове-Перемоги 2;Чутове пл. Перемоги, 2;871628b7-749b-11df-b112-00215aee3ebe
38b25d2f-aa4e-11ee-80c4-000c2961d091;Шаргород-Героїв Майдану 239;Шаргород вул. Героїв Майдану (Леніна), 239;871628ee-749b-11df-b112-00215aee3ebe
d934882a-af0a-11ea-80c8-000c29800ae7;Шацьк-50-річчя Перемоги 40 А;Шацьк вул. 50-річчя Перемоги, 40А;87162918-749b-11df-b112-00215aee3ebe
cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;Шепетівка;Шепетівка вул. Соборності (Островського), 6;8d17ed50-749b-11df-b112-00215aee3ebe
f0b53bf7-58e9-11ec-80e4-000c29800ae7;Шепетівка-Валі Котика 172А АЗС Motto;Шепетівка вул. Валі Котика, 172А;8d17ed50-749b-11df-b112-00215aee3ebe
7a335957-1ae8-11ec-80df-000c29800ae7;Шепетівка-Валі Котика 20Б Перша Страхова компанія;Шепетівка вул. Валі Котика, 20Б;8d17ed50-749b-11df-b112-00215aee3ebe
dfea5af9-ff98-11ed-80bc-000c2961d091;Шепетівка-Героїв Небесної Сотні 23А Lifecell;Шепетівка вул. Героїв Небесної Сотні (Маркса Карла), 23А;8d17ed50-749b-11df-b112-00215aee3ebe
4bd2733a-ab09-11ee-80c4-000c2961d091;Шепетівка-Героїв Небесної Сотні 5 Арона;Шепетівка вул. Героїв Небесної Сотні (Маркса Карла), 5;8d17ed50-749b-11df-b112-00215aee3ebe
813a0e1b-99ae-11ee-80c1-000c2961d091;Шепетівка-Миру 14 ТехноСвіт;Шепетівка Просп. Миру, 14;8d17ed50-749b-11df-b112-00215aee3ebe
9161fc0e-66c5-11eb-80d1-000c29800ae7;Шепетівка-Миру 30 texнопростір;Шепетівка Просп. Миру, 30;8d17ed50-749b-11df-b112-00215aee3ebe
905db2a1-a199-11ea-80c8-000c29800ae7;Шепетівка-Небесної Сотні 33 ЧІП;Шепетівка вул. Небесної Сотні, 33;8d17ed50-749b-11df-b112-00215aee3ebe
79553b61-3bc9-11ec-80e3-000c29800ae7;Шепетівка-Рубіжна 8В Glamour;Шепетівка вул. Рубіжна (Шешукова), 8В;8d17ed50-749b-11df-b112-00215aee3ebe
a9f44cf0-2526-11ec-80e3-000c29800ae7;Шепетівка-Старокостянтинівське 35А Рибачок;Шепетівка шосе Старокостянтинівське, 35А;8d17ed50-749b-11df-b112-00215aee3ebe
ff65c8ef-0bf3-11ed-80e7-000c29800ae7;Шишаки-Кирила Осьмака (Леніна) 71;Шишаки вул. Кирила Осьмака (Леніна), 71;8d17edc3-749b-11df-b112-00215aee3ebe
a7361519-c813-11ee-80c4-000c2961d091;Шишаки-Кирила Осьмака ЖЖУК;Шишаки вул. Кирила Осьмака (Леніна),;8d17edc3-749b-11df-b112-00215aee3ebe
75d6f85a-c91e-11eb-80d5-000c29800ae7;Шкло-Сагайдачного 62;Шкло вул. Сагайдачного,;8d17ede1-749b-11df-b112-00215aee3ebe
d683fcd1-6721-11ea-80c6-000c29800ae7;Шостка;Шостка вул. Привокзальна, 1-Б;8d17ee2d-749b-11df-b112-00215aee3ebe
571cdd1a-eead-11eb-80d5-000c29800ae7;Шостка-Свободи 16/2 Lifecell;Шостка вул. Свободи (Маркса Карла), 16/2;8d17ee2d-749b-11df-b112-00215aee3ebe
551b3638-f25f-11ec-80e7-000c29800ae7;Шостка-Свободи 35 Rozetka;Шостка вул. Свободи (Маркса Карла), 35;8d17ee2d-749b-11df-b112-00215aee3ebe
1ae2d51d-8e62-11ec-80e4-000c29800ae7;Шостка-Свободи 45 Компью Ленд;Шостка вул. Свободи (Маркса Карла), 45;8d17ee2d-749b-11df-b112-00215aee3ebe
7c89527e-c80d-11ee-80c4-000c2961d091;Шпиків-Незалежності 53;Шпиків вул. Незалежності, 53;8d17ee37-749b-11df-b112-00215aee3ebe
f88cce7d-7bc0-11e9-80e0-1c98ec135261;Шпиків-Незалежності 76;Шпиків вул. Незалежності, 76;8d17ee37-749b-11df-b112-00215aee3ebe
446509b9-b120-11ed-80ec-000c29800ae7;Шпола-Героїв Крут 66В;Шпола вул. Героїв Крут (Червоноармійська),;8d17ee46-749b-11df-b112-00215aee3ebe
a81c3f15-913b-11ee-80c1-000c2961d091;Шпола-Соборна 35;Шпола вул. Соборна, 35;8d17ee46-749b-11df-b112-00215aee3ebe
c212cce7-3524-11e9-80dd-1c98ec135261;Шумськ-Ринкова площа,7;Шумськ вул. Ринкова, 7;8d17ee78-749b-11df-b112-00215aee3ebe
8ba6fb7a-9934-11eb-80d3-000c29800ae7;Шумськ-Українська 56 А;Шумськ вул. Українська, 56А;8d17ee78-749b-11df-b112-00215aee3ebe
e3055a19-d33f-11eb-80d5-000c29800ae7;Щасливе-Героїв Майдану 11;Щасливе вул. Героїв Майдану (Леніна), 11;8d17ee94-749b-11df-b112-00215aee3ebe
73c57be8-255f-11ee-80bd-000c2961d091;Щасливе-Харківське 1В;Щасливе шосе Харківське, 1В;8d17ee94-749b-11df-b112-00215aee3ebe
e1912721-20ac-11e9-80dc-1c98ec135261;Щасливе-Харківське шосе 1 Г;Щасливе шосе Харківське, 1Г;8d17ee94-749b-11df-b112-00215aee3ebe
db30f179-ecdb-11ee-80c5-000c2961d091;Щасливе-Ювілейний 2;Щасливе пров. Ювілейний, 2;8d17ee94-749b-11df-b112-00215aee3ebe
efef99fd-6ffe-11ea-80c6-000c29800ae7;ЮджинLV;;5cb61671-749b-11df-b112-00215aee3ebe
2cb5787b-f051-11eb-80d5-000c29800ae7;Южне-Григоріївського десанту 28;Южне Просп. Григоріївського десанту, 28;8d17eef0-749b-11df-b112-00215aee3ebe
5823d070-7664-11ee-80c1-000c2961d091;Южне-Григоріївського десанту 3;Южне Просп. Григоріївського десанту, 3;8d17eef0-749b-11df-b112-00215aee3ebe
ae4ae383-764c-11e9-80e0-1c98ec135261;Южне-Миру просп. 22;Южне Просп. Миру, 22;8d17eef0-749b-11df-b112-00215aee3ebe
d058cb45-f308-11ec-80e7-000c29800ae7;Южне-просп.Григоріївського десанту,3;Южне Просп. Григоріївського десанту, 3;8d17eef0-749b-11df-b112-00215aee3ebe
dd8a002f-47fa-11ee-80c0-000c2961d091;Южноукраїнськ-Європейська 17В;Южноукраїнськ вул. Європейська ( Дружби Народів ), 17В;8d17eef5-749b-11df-b112-00215aee3ebe
aa339d65-47fb-11ee-80c0-000c2961d091;Южноукраїнськ-Європейська 35;Южноукраїнськ вул. Європейська ( Дружби Народів ), 35;8d17eef5-749b-11df-b112-00215aee3ebe
630746f3-47fb-11ee-80c0-000c2961d091;Южноукраїнськ-Квітковий 6Б;Южноукраїнськ бул. Квітковий, 6Б;8d17eef5-749b-11df-b112-00215aee3ebe
c8627675-87c0-11ee-80c1-000c2961d091;Южноукраїнськ-Миру 14;Южноукраїнськ вул. Миру, 14;8d17eef5-749b-11df-b112-00215aee3ebe
aaac92ef-f04b-11eb-80d5-000c29800ae7;Южноукраїнськ-Незалежності  24;Южноукраїнськ Просп. Незалежності (Леніна), 24;8d17eef5-749b-11df-b112-00215aee3ebe
9f8e5599-d198-11ee-80c4-000c2961d091;Южноукраїнськ-Незалежності 21;Южноукраїнськ Просп. Незалежності (Леніна), 21;8d17eef5-749b-11df-b112-00215aee3ebe
bd6b2548-7664-11ee-80c1-000c2961d091;Южноукраїнськ-Незалежності 35;Южноукраїнськ Просп. Незалежності (Леніна), 35;8d17eef5-749b-11df-b112-00215aee3ebe
fed49dbb-47f2-11ee-80c0-000c2961d091;Южноукраїнськ-Соборності 1;Южноукраїнськ Просп. Соборності (Комуністичний), 1;8d17eef5-749b-11df-b112-00215aee3ebe
5ffbfcab-668e-11ee-80c0-000c2961d091;Юр'ївка-Центральна 51;Юр'ївка вул. Центральна, 51;8d17ef0e-749b-11df-b112-00215aee3ebe
14dfc3ed-20a9-11e9-80dc-1c98ec135261;Яворів-Левада 2;Яворів вул. Левада, 2;8d17ef8d-749b-11df-b112-00215aee3ebe
8154e1ed-b2a3-11ed-80ec-000c29800ae7;Яворів-Левада 2А;Яворів вул. Левада, 2А;8d17ef8d-749b-11df-b112-00215aee3ebe
7b5d4167-5088-11ee-80c0-000c2961d091;Яворів-Маковея 62;Яворів вул. Маковея, 62;8d17ef8d-749b-11df-b112-00215aee3ebe
acfa89f3-9a6b-11ee-80c1-000c2961d091;Яворів-Тичини Павла 3;Яворів вул. Тичини Павла, 3;8d17ef8d-749b-11df-b112-00215aee3ebe
d30c19a0-a570-11ee-80c4-000c2961d091;Яворів-Тичини Павла 3 Rozetka;Яворів вул. Тичини Павла, 3;8d17ef8d-749b-11df-b112-00215aee3ebe
63ba431d-0524-11ee-80bd-000c2961d091;Яготин-Вокзальна 32;Яготин вул. Вокзальна, 32;8d17efa9-749b-11df-b112-00215aee3ebe
ac260d8b-be0e-11eb-80d5-000c29800ae7;Яготин-Незалежності 100;Яготин вул. Незалежності, 100;8d17efa9-749b-11df-b112-00215aee3ebe
1dcd0a43-4b31-11ee-80c0-000c2961d091;Яготин-Незалежності 140;Яготин вул. Незалежності, 140;8d17efa9-749b-11df-b112-00215aee3ebe
643bb73c-bd63-11eb-80d4-000c29800ae7;Яготин-Незалежності 162;Яготин вул. Незалежності, 162;8d17efa9-749b-11df-b112-00215aee3ebe
120a0886-bed6-11eb-80d5-000c29800ae7;Ямпіль-Леніна 142;Ямпіль вул. Леніна, 142;8d17f00b-749b-11df-b112-00215aee3ebe
9bb2a4ea-a70f-11e9-80e2-1c98ec135261;Ямпіль-Свободи 1Б;Ямпіль вул. Свободи, 1Б;8d17f00b-749b-11df-b112-00215aee3ebe
75aeaff5-e85c-11eb-80d5-000c29800ae7;Ямпіль-Свободи 1И;Ямпіль вул. Свободи, 1И;8d17f00b-749b-11df-b112-00215aee3ebe
1f030bed-6a2d-11ec-80e4-000c29800ae7;Ямпіль-Свободи 81;Ямпіль вул. Свободи, 81;8d17f00b-749b-11df-b112-00215aee3ebe
3ac78e28-7759-11eb-80d1-000c29800ae7;Ямпіль-Центральна 34 А;Ямпіль вул. Центральна, 34А;8d17f00c-749b-11df-b112-00215aee3ebe
d6abbf11-a79b-11e9-80e4-1c98ec135261;Яремче-Свободи 149А;Яремче вул. Свободи, 149А;8d17f022-749b-11df-b112-00215aee3ebe
9280f283-f3cc-11ed-80ed-000c29800ae7;Ярмолинці-Петропавлівська 64Б;Ярмолинці вул. Петропавлівська, 64Б;8d17f039-749b-11df-b112-00215aee3ebe
0f53ff92-a794-11e9-80e4-1c98ec135261;Ярмолинці-Пушкіна 153;Ярмолинці вул. Пушкіна, 153;8d17f039-749b-11df-b112-00215aee3ebe
336c0580-3d97-11ee-80c0-000c2961d091;Ярмолинці-Хмельницька 14/1;Ярмолинці вул. Хмельницька, 14/1;8d17f039-749b-11df-b112-00215aee3ebe
74f7747a-a793-11e9-80e4-1c98ec135261;Ярмолинці-Хмельницька 57А ;Ярмолинці вул. Хмельницька, 57А;8d17f039-749b-11df-b112-00215aee3ebe
`