const refs={
    controls: document.querySelector(".container-radio__flex"),
}

const label1 = document.querySelector("#label-1");
const label2 = document.querySelector("#label-2");
const label3 = document.querySelector("#label-3");
const circleRadio1 = document.querySelector("#sel-checked-map1");
const circleRadio2 = document.querySelector("#sel-checked-map2");
const circleRadio3 = document.querySelector("#sel-checked-map3");
const check1 = document.querySelector("#check1");
const check2 = document.querySelector("#check2");
const check3 = document.querySelector("#check3");
const menu1 = document.querySelector("#menu-1");
const menu2 = document.querySelector("#menu-2");
const menu3 = document.querySelector("#menu-3");

label1.addEventListener("click", e => {
    check1.classList.add("checked");
    check2.classList.remove("checked");
    check3.classList.remove("checked");

    menu1.classList.add("is-active");
    menu2.classList.remove("is-active");
    menu3.classList.remove("is-active");
})

label2.addEventListener("click", e => {
    check1.classList.remove("checked");
    check2.classList.add("checked");
    check3.classList.remove("checked");

    menu1.classList.remove("is-active");
    menu2.classList.add("is-active");
    menu3.classList.remove("is-active");
})

label3.addEventListener("click", e => {
    check1.classList.remove("checked");
    check2.classList.remove("checked");
    check3.classList.add("checked");

    menu1.classList.remove("is-active");
    menu2.classList.remove("is-active");
    menu3.classList.add("is-active");
})

