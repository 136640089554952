export const dataCity = `
833e2a52-71be-11e1-bcbd-003048d2b473;_Кам'янець-Подільський СКЛАД;_Кам'янець-Подільський СКЛАД;місто;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;;2;1
2297e8d3-8b98-11e0-82d0-003048d2b473;_Чернівці-Калинівська СКЛАД;_Чернівці-Калинівська СКЛАД;місто;fbfb8c29-41be-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5c2ff589-8b8e-11e0-82d0-003048d2b473;;1;1
fa1d4243-7488-11df-b112-00215aee3ebe;Абазівка;Абазовка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324080101;3;1
fa1d4244-7488-11df-b112-00215aee3ebe;Абазівка;Абазовка;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322282502;3;1
fa1d4245-7488-11df-b112-00215aee3ebe;Абрамівка;Абрамовка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323080201;3;1
7f4b5690-cccd-11df-bb67-00215aee3ebe;Абрамівка;Абрамовка;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221880401;3;1
fa1d4246-7488-11df-b112-00215aee3ebe;Абрамівська Долина;Абрамовская Долина;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524984503;3;1
fa1d4247-7488-11df-b112-00215aee3ebe;Абрамок;Абрамок;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824086202;3;1
fa1d4248-7488-11df-b112-00215aee3ebe;Абранка;Абранка;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121580501;3;1
fa1d424d-7488-11df-b112-00215aee3ebe;Абрикосівка;Абрикосовка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822480101;3;1
fa1d424e-7488-11df-b112-00215aee3ebe;Абрикосове;Абрикосово;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120887704;3;1
fa1d4250-7488-11df-b112-00215aee3ebe;Авангард;Авангард;село;4ffc4b7d-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1211090001;3;1
fa1d4251-7488-11df-b112-00215aee3ebe;Авангард;Авангард;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224581703;3;1
fa1d4252-7488-11df-b112-00215aee3ebe;Авангард;Авангард;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123755200;1;0
fa1d4254-7488-11df-b112-00215aee3ebe;Августинівка;Августиновка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322180401;3;1
fa1d4255-7488-11df-b112-00215aee3ebe;Августівка;Августовка;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123080201;3;1
fa1d4256-7488-11df-b112-00215aee3ebe;Августівка;Августовка;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121080301;3;1
fa1d4257-7488-11df-b112-00215aee3ebe;Августівка;Августовка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421710101;3;1
fa1d4259-7488-11df-b112-00215aee3ebe;Авдіївка;Авдеевка;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424980501;3;1
fa1d425a-7488-11df-b112-00215aee3ebe;Авдіївка;Авдеевка;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422780201;3;1
fa1d425b-7488-11df-b112-00215aee3ebe;Авдотівка;Авдотьевка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225285502;3;1
fa1d425c-7488-11df-b112-00215aee3ebe;Авидівка;Авидовка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120880302;3;1
e03a4603-2b4f-11e7-80fe-1c98ec135263;Авіаторське;Авиаторское;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1210141000;3;0
fa1d425e-7488-11df-b112-00215aee3ebe;Авксенівка;Авксеновка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221081802;3;1
fa1d425f-7488-11df-b112-00215aee3ebe;Авраменкове;Авраменково;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924188503;3;1
fa1d4260-7488-11df-b112-00215aee3ebe;Аврамівка;Аврамовка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220787702;3;1
fa1d4261-7488-11df-b112-00215aee3ebe;Аврамівка;Аврамовка;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123480301;3;1
fa1d4262-7488-11df-b112-00215aee3ebe;Аврамівка;Аврамовка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522584503;3;1
fa1d4263-7488-11df-b112-00215aee3ebe;Аврамківщина;Аврамковщина;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920382402;3;1
fa1d4264-7488-11df-b112-00215aee3ebe;Авратин;Авратин;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823180201;3;1
fa1d4265-7488-11df-b112-00215aee3ebe;Авратин;Авратин;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820980301;3;1
fa1d4267-7488-11df-b112-00215aee3ebe;Автуничі;Автуничи;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421480402;3;1
fa1d4268-7488-11df-b112-00215aee3ebe;Агатівка;Агатовка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820884602;3;1
fa1d4269-7488-11df-b112-00215aee3ebe;Агафіївка;Агафиевка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123380301;3;1
fa1d426a-7488-11df-b112-00215aee3ebe;Агеївка;Агеевка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123381802;3;1
fa1d4270-7488-11df-b112-00215aee3ebe;Аграфенівка;Аграфеновка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321585602;3;1
fa1d426e-7488-11df-b112-00215aee3ebe;Агрономічне;Агрономичное;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520680203;3;0
fa1d426f-7488-11df-b112-00215aee3ebe;Агрономія;Агрономия;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820380101;3;1
fa1d4271-7488-11df-b112-00215aee3ebe;Адалимівка;Адалимовка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221086602;3;1
fa1d4272-7488-11df-b112-00215aee3ebe;Адамів;Адамов;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823681502;3;1
fa1d4273-7488-11df-b112-00215aee3ebe;Адамівка;Адамовка;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724285802;3;1
fa1d4274-7488-11df-b112-00215aee3ebe;Адамівка;Адамовка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222080501;3;1
fa1d4275-7488-11df-b112-00215aee3ebe;Адамівка;Адамовка;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822380110;3;1
fa1d4276-7488-11df-b112-00215aee3ebe;Адамівка;Адамовка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123382602;3;1
fa1d4277-7488-11df-b112-00215aee3ebe;Адамівка;Адамовка;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121883802;3;1
fa1d4278-7488-11df-b112-00215aee3ebe;Адамівка;Адамовка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120880301;3;1
fa1d4279-7488-11df-b112-00215aee3ebe;Адамівка;Адамовка;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623983002;3;1
fa1d427b-7488-11df-b112-00215aee3ebe;Адамівка;Адамовка;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820655401;3;1
fa1d427c-7488-11df-b112-00215aee3ebe;Адамівка;Адамовка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420886402;3;1
fa1d427d-7488-11df-b112-00215aee3ebe;Адамівка;Адамовка;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423881002;3;1
fa1d427e-7488-11df-b112-00215aee3ebe;Адамівка;Адамовка;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120989702;3;1
fa1d427f-7488-11df-b112-00215aee3ebe;Адамівка;Адамовка;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523480301;3;1
44ac7ef4-749b-11df-b112-00215aee3ebe;Адамівка;Адамовка;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521684006;3;1
44ac7ef5-749b-11df-b112-00215aee3ebe;Адамівка;Адамовка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520280203;3;1
44ac7ef6-749b-11df-b112-00215aee3ebe;Адамівка;Адамовка;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520881806;3;1
44ac7ef7-749b-11df-b112-00215aee3ebe;Адамівка;Адамовка;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821589205;3;1
44ac7ef8-749b-11df-b112-00215aee3ebe;Адамівка;Адамовка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822486502;2;1
44ac7ef9-749b-11df-b112-00215aee3ebe;Адамівка;Адамовка;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820680501;3;1
44ac7efa-749b-11df-b112-00215aee3ebe;Адамівка;Адамовка;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620455701;3;1
44ac7efb-749b-11df-b112-00215aee3ebe;Адамівка;Адамовка;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625886902;3;1
44ac7efc-749b-11df-b112-00215aee3ebe;Адамівське;Адамовское;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222082502;3;1
44ac7efd-749b-11df-b112-00215aee3ebe;Адамове;Адамово;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821780602;3;1
44ac7efe-749b-11df-b112-00215aee3ebe;Адампіль;Адамполь;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824480501;3;1
44ac7eff-749b-11df-b112-00215aee3ebe;Адамчуки;Адамчуки;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725780902;3;1
44ac7f00-749b-11df-b112-00215aee3ebe;Аджамка;Аджамка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522580301;3;1
44ac7f07-749b-11df-b112-00215aee3ebe;Азовське;Азовское;село;4ffc4c15-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;;3;1
44ac7f0e-749b-11df-b112-00215aee3ebe;Акмечетські Ставки;Акмечетские Ставки;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822784003;3;1
44ac7f0f-749b-11df-b112-00215aee3ebe;Акрешори;Акрешоры;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623680401;3;1
44ac7f10-749b-11df-b112-00215aee3ebe;Аксютівка;Аксютовка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321786202;3;1
44ac7f11-749b-11df-b112-00215aee3ebe;Актове;Актово;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822055202;3;1
44ac7f12-749b-11df-b112-00215aee3ebe;Акулинівка;Акулиновка;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290001;3;1
44ac7f13-749b-11df-b112-00215aee3ebe;Алісівка;Алисовка;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322081502;3;1
44ac7f14-749b-11df-b112-00215aee3ebe;Алісівка;Алисовка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320680301;3;1
44ac7f18-749b-11df-b112-00215aee3ebe;Алтестове;Алтестово;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121085402;3;1
44ac7f19-749b-11df-b112-00215aee3ebe;Алтинівка;Алтыновка;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922680401;3;1
44ac7f1d-749b-11df-b112-00215aee3ebe;Альошинське;Алешинское;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421410101;3;1
44ac7f1e-749b-11df-b112-00215aee3ebe;Амбарів;Амбаров;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123581402;3;1
44ac7f20-749b-11df-b112-00215aee3ebe;Амбуків;Амбуков;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0720510401;3;1
44ac7f22-749b-11df-b112-00215aee3ebe;Амури;Амуры;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120285602;3;1
44ac7f24-749b-11df-b112-00215aee3ebe;Анадоли;Анадолы;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325082001;3;1
44ac7f26-749b-11df-b112-00215aee3ebe;Ананьїв;Ананьев;місто;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120210100;2;1
c8674a86-dbb0-11df-9197-00215aee3ebe;Ананьїв Другий;Ананьев Второй;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120280501;3;1
c8674a87-dbb0-11df-9197-00215aee3ebe;Ананьїв Перший;Ананьев Первый;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120280401;3;1
44ac7f27-749b-11df-b112-00215aee3ebe;Ананьїв село;Ананьев Село;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120210100;3;1
44ac7f29-749b-11df-b112-00215aee3ebe;Анастасівка;Анастасовка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924180601;3;1
44ac7f2a-749b-11df-b112-00215aee3ebe;Анастасівка;Анастасовка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221882203;3;1
44ac7f2b-749b-11df-b112-00215aee3ebe;Анастасівка;Анастасовка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824581902;3;1
44ac7f2c-749b-11df-b112-00215aee3ebe;Анастасівка;Анастасовка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824055601;3;1
44ac7f2d-749b-11df-b112-00215aee3ebe;Анатолівка;Анатольевка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820980301;3;1
44ac7f2e-749b-11df-b112-00215aee3ebe;Анатолівка;Анатольевка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283602;3;1
44ac7f30-749b-11df-b112-00215aee3ebe;Анатолівка;Анатольевка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521783503;3;1
44ac7f31-749b-11df-b112-00215aee3ebe;Ангелівка;Ангеловка;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125287103;3;1
44ac7f32-749b-11df-b112-00215aee3ebe;Ангелівка;Ангеловка;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122087002;3;1
44ac7f33-749b-11df-b112-00215aee3ebe;Ангелівка;Ангеловка;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622082902;3;1
44ac7f35-749b-11df-b112-00215aee3ebe;Ангелінівка;Ангелиновка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123986402;3;1
44ac7f36-749b-11df-b112-00215aee3ebe;Андреківське;Андрековское;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323586002;3;1
44ac7f37-749b-11df-b112-00215aee3ebe;Андренки;Андренки;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321084103;3;1
44ac7f39-749b-11df-b112-00215aee3ebe;Андрієвичі;Андриевичи;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821780201;3;1
44ac7f3b-749b-11df-b112-00215aee3ebe;Андрієво-Зорине;Андреево-Зорино;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820955105;3;1
44ac7f3a-749b-11df-b112-00215aee3ebe;Андрієво-Іванівка;Андреево-Ивановка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123581001;3;1
44ac7f3c-749b-11df-b112-00215aee3ebe;Андрієво-Іванове;Андреево-Иваново;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123983002;3;1
44ac7f3d-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324880301;3;1
44ac7f3e-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324280502;3;1
44ac7f3f-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321080401;3;1
44ac7f40-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324080702;3;1
44ac7f41-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323486002;3;1
44ac7f42-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323080401;3;1
44ac7f43-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924180901;3;1
44ac7f44-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924780302;3;1
44ac7f45-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225683002;3;1
44ac7f46-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223281002;3;1
44ac7f47-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221083302;3;1
44ac7f48-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222083502;3;1
44ac7f49-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224884902;3;1
44ac7f4a-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282506;3;1
44ac7f4b-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224281201;3;1
44ac7f4c-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225882201;3;1
44ac7f4d-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655102;3;1
44ac7f4e-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610101;3;1
44ac7f4f-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824286010;3;1
44ac7f50-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820955101;3;1
44ac7f51-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322682002;3;1
44ac7f53-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321082002;3;1
44ac7f54-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323180401;3;1
44ac7f55-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320255300;3;1
44ac7f56-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322883002;3;1
44ac7f57-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324884502;3;1
44ac7f58-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320683502;3;1
44ac7f59-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122985602;3;1
44ac7f5a-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120880701;3;1
44ac7f5b-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321584002;3;1
44ac7f5e-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520980502;3;1
44ac7f61-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4623984602;3;1
44ac7f6a-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124882702;3;1
44ac7f6d-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221884802;3;1
44ac7f6e-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222780201;3;1
44ac7f6f-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220886702;3;1
44ac7f70-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222988302;3;1
44ac7f71-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224081203;3;1
44ac7f72-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824486802;3;1
44ac7f73-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825480201;3;1
44ac7f74-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825680401;3;1
44ac7f75-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421480401;3;1
44ac7f76-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425580301;3;1
44ac7f78-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421780401;3;1
44ac7f79-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423882002;3;1
44ac7f7a-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525385502;3;1
44ac7f7b-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523884002;3;1
44ac7f7c-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523180302;3;1
44ac7f7d-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521786002;3;1
44ac7f7e-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520380801;3;1
44ac7f7f-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825280201;2;1
44ac7f80-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821288602;3;1
44ac7f81-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825888603;3;1
44ac7f82-749b-11df-b112-00215aee3ebe;Андріївка;Андреевка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323283402;3;1
17af7b47-ddb9-11df-9197-00215aee3ebe;Андріївка;Андреевка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;;3;1
44ac7f84-749b-11df-b112-00215aee3ebe;Андріївське;Андреевское;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922084502;3;1
44ac7f86-749b-11df-b112-00215aee3ebe;Андрійки;Андрейки;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084602;3;1
44ac7f87-749b-11df-b112-00215aee3ebe;Андрійківці;Андрейковцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825080301;3;1
44ac7f88-749b-11df-b112-00215aee3ebe;Андрійчикове;Андрейчиково;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083802;3;1
44ac7f89-749b-11df-b112-00215aee3ebe;Андріянів;Андриянов;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620984202;3;1
44ac7f8a-749b-11df-b112-00215aee3ebe;Андріяшівка;Андрияшевка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924181101;3;1
44ac7f8b-749b-11df-b112-00215aee3ebe;Андріяшівка;Андрияшевка;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290002;3;1
44ac7f8c-749b-11df-b112-00215aee3ebe;Андріяшівка;Андрияшевка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820880201;3;1
44ac7f8d-749b-11df-b112-00215aee3ebe;Андріяшівка;Андрияшевка;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521980201;3;1
44ac7f8e-749b-11df-b112-00215aee3ebe;Андроники;Андроники;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422485502;3;1
44ac7f8f-749b-11df-b112-00215aee3ebe;Андронівка;Андроновка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222688802;3;1
44ac7f90-749b-11df-b112-00215aee3ebe;Андронівка;Андроновка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824286702;3;1
44ac7f91-749b-11df-b112-00215aee3ebe;Андросове;Андросово;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522582102;3;1
44ac7f92-749b-11df-b112-00215aee3ebe;Андруга;Андруга;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123480702;3;1
44ac7f93-749b-11df-b112-00215aee3ebe;Андрусівка;Андрусовка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221882402;3;1
44ac7f94-749b-11df-b112-00215aee3ebe;Андрусівка;Андрусовка;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323281202;3;1
44ac7f95-749b-11df-b112-00215aee3ebe;Андрусіїв;Андрусиев;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621284402;3;1
44ac7f96-749b-11df-b112-00215aee3ebe;Андрусова;Андрусова;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125255401;3;1
44ac7f97-749b-11df-b112-00215aee3ebe;Андрушівка;Андрушевка;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125880301;3;1
44ac7f98-749b-11df-b112-00215aee3ebe;Андрушівка;Андрушевка;місто;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820310100;2;0
44ac7f99-749b-11df-b112-00215aee3ebe;Андрушівка;Андрушевка;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523480601;3;1
44ac7f9a-749b-11df-b112-00215aee3ebe;Андрушки;Андрушки;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081902;3;1
44ac7f9b-749b-11df-b112-00215aee3ebe;Андрушки;Андрушки;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824780301;3;1
44ac7f9c-749b-11df-b112-00215aee3ebe;Андрущине;Андрущино;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320284702;3;1
44ac7f9d-749b-11df-b112-00215aee3ebe;Анета;Анета;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824084402;3;1
44ac7f9e-749b-11df-b112-00215aee3ebe;Анетівка;Анетовка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822784002;3;1
44ac7f9f-749b-11df-b112-00215aee3ebe;Анисів;Анисов;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425580501;3;1
73e94bf9-ddc1-11df-9197-00215aee3ebe;Анталовці;Анталовцы;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124887602;3;1
44ac7fa3-749b-11df-b112-00215aee3ebe;Антинське;Антинськое;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920687402;3;1
44ac7fa4-749b-11df-b112-00215aee3ebe;Антипівка;Антиповка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121580401;3;1
50c59078-749b-11df-b112-00215aee3ebe;Антішки;Антишки;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425181004;3;1
44ac7fa5-749b-11df-b112-00215aee3ebe;Антоненкове;Антоненково;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923285602;3;1
44ac7fa6-749b-11df-b112-00215aee3ebe;Антонів;Антонов;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125587302;3;1
44ac7fa7-749b-11df-b112-00215aee3ebe;Антонів;Антонов;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224080301;2;1
44ac7fa8-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925680201;3;1
44ac7fa9-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922685502;3;1
44ac7faa-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881802;3;1
44ac7fab-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720885803;3;1
44ac7fac-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055102;3;1
44ac7fad-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821480802;3;1
44ac7fae-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824580402;3;1
44ac7faf-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822783202;3;1
44ac7fb0-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824880201;3;1
44ac7fb1-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323155101;3;1
44ac7fb2-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123381803;3;1
44ac7fb3-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283607;3;1
44ac7fb4-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123982602;3;1
44ac7fb5-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123180401;3;1
44ac7fb6-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321580501;3;1
44ac7fbb-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625686402;3;1
44ac7fbc-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621584402;3;1
44ac7fc0-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222284802;3;1
44ac7fc1-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224280401;3;1
44ac7fc2-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224482302;3;1
44ac7fc3-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821781402;3;1
44ac7fc4-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510102;3;1
44ac7fc5-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421180401;3;1
44ac7fc6-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124380301;3;1
44ac7fc7-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124083602;3;1
44ac7fc8-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125780401;3;1
44ac7fc9-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522481602;3;1
44ac7fca-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520280101;3;1
44ac7fcb-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523786402;3;1
44ac7fcc-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523955102;3;1
44ac7fcd-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520588002;3;1
44ac7fce-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522881503;3;1
44ac7fcf-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521987603;3;1
44ac7fd0-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6822183002;3;1
44ac7fd1-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824786502;3;1
44ac7fd2-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825080601;3;1
44ac7fd3-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823084202;3;1
44ac7fd4-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825289002;2;1
44ac7fd5-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821882602;3;1
44ac7fd6-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823387002;3;1
44ac7fd7-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620880301;3;1
44ac7fd8-749b-11df-b112-00215aee3ebe;Антонівка;Антоновка;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620485702;3;1
44ac7fd9-749b-11df-b112-00215aee3ebe;Антонівське;Антоновское;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222685505;3;1
44ac7fda-749b-11df-b112-00215aee3ebe;Антонівці;Антоновцы;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125883503;3;1
44ac7fdb-749b-11df-b112-00215aee3ebe;Антонівці;Антоновцы;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825880401;3;1
44ac7fdc-749b-11df-b112-00215aee3ebe;Антоніна;Антонина;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123455501;3;1
44ac7fdd-749b-11df-b112-00215aee3ebe;Антоніни;Антонины;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822755200;3;1
44ac7fde-749b-11df-b112-00215aee3ebe;Антонове;Антоново;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821455101;3;1
44ac7fdf-749b-11df-b112-00215aee3ebe;Антоновичі;Антоновичи;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287302;3;1
44ac7fe0-749b-11df-b112-00215aee3ebe;Антоновичі;Антоновичи;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425589902;3;1
44ac7fe1-749b-11df-b112-00215aee3ebe;Антоно-Ковач;Антоно-Ковач;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121684602;3;1
44ac7fe2-749b-11df-b112-00215aee3ebe;Антонопіль;Антонополь;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821482202;3;1
44ac7fe3-749b-11df-b112-00215aee3ebe;Антонопіль;Антонополь;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521682206;3;1
44ac7fe4-749b-11df-b112-00215aee3ebe;Антонюки;Антонюки;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123581401;3;1
44ac7fe5-749b-11df-b112-00215aee3ebe;Антопіль;Антополь;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820380401;3;1
44ac7fe6-749b-11df-b112-00215aee3ebe;Антопіль;Антополь;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523983002;3;1
44ac7fe7-749b-11df-b112-00215aee3ebe;Антопіль;Антополь;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624680703;3;1
44ac7fe8-749b-11df-b112-00215aee3ebe;Антося;Антося;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620382403;3;1
44ac7feb-749b-11df-b112-00215aee3ebe;Анциполівка;Анциполовка;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523083602;3;1
44ac7fec-749b-11df-b112-00215aee3ebe;Анютине;Анютино;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922083002;3;1
44ac7fed-749b-11df-b112-00215aee3ebe;Анютине;Анютино;село;78f453a8-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0525086402;3;1
44ac7fee-749b-11df-b112-00215aee3ebe;Анютине;Анютино;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823082902;3;1
44ac7fef-749b-11df-b112-00215aee3ebe;Аполлонівка;Аполлоновка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055101;3;1
44ac7ff0-749b-11df-b112-00215aee3ebe;Аполлонівка;Аполлоновка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324880501;3;1
44ac7ff1-749b-11df-b112-00215aee3ebe;Аполлонівка;Аполлоновка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821783802;3;1
44ac7ff2-749b-11df-b112-00215aee3ebe;Аполянка;Аполянка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124380401;3;1
44ac7ff3-749b-11df-b112-00215aee3ebe;Апостолове;Апостолово;місто;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1220310100;2;1
8cdd5cd9-de27-11df-9197-00215aee3ebe;Апрелівка;Апрелевка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520883602;3;1
4aba3e9f-749b-11df-b112-00215aee3ebe;Апрелівське;Апрелевское;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520883601;3;1
44ac7ff7-749b-11df-b112-00215aee3ebe;Араповичі;Араповичи;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423685002;3;1
44ac7ff8-749b-11df-b112-00215aee3ebe;Арбузинка;Арбузинка;місто;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820355100;3;1
44ac7ffb-749b-11df-b112-00215aee3ebe;Арданово;Арданово;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121980401;3;1
44ac7ffc-749b-11df-b112-00215aee3ebe;Арепівка;Ареповка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524010104;3;1
44ac7ffd-749b-11df-b112-00215aee3ebe;Аристівка;Аристовка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525381202;3;1
44ac7ffe-749b-11df-b112-00215aee3ebe;Аркавське;Аркавское;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920655301;3;1
44ac7fff-749b-11df-b112-00215aee3ebe;Аркадівка;Аркадевка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325781001;3;1
44ac8001-749b-11df-b112-00215aee3ebe;Аркадіївка;Аркадиевка;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221980301;3;1
44ac8002-749b-11df-b112-00215aee3ebe;Аркадіївці;Аркадиевцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825080901;3;1
44ac8004-749b-11df-b112-00215aee3ebe;Арламівська Воля;Арламовская Воля;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622480201;3;1
6ed81f55-749b-11df-b112-00215aee3ebe;Армашівка;Армашівка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125484401;3;1
44ac800a-749b-11df-b112-00215aee3ebe;Арсенівка;Арсеневка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321386002;3;1
44ac800b-749b-11df-b112-00215aee3ebe;Арсенівка;Арсеневка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523880803;3;1
44ac800c-749b-11df-b112-00215aee3ebe;Арсенівка;Арсеневка;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525281202;3;1
44ac800d-749b-11df-b112-00215aee3ebe;Арсеновичі;Арсеновичи;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722181202;3;1
44ac800e-749b-11df-b112-00215aee3ebe;Артасів;Артасов;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622780202;3;1
44ac8010-749b-11df-b112-00215aee3ebe;Артелярщина;Артелярщина;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321380401;3;1
44ac8016-749b-11df-b112-00215aee3ebe;Артеменків;Артеменков;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425155101;3;1
44ac8021-749b-11df-b112-00215aee3ebe;Артемівка;Артемовка;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220884402;3;1
44ac8022-749b-11df-b112-00215aee3ebe;Артемівка;Артемовка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524981902;3;1
44ac8018-749b-11df-b112-00215aee3ebe;Артемівка;Артемовка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220786602;3;1
44ac801b-749b-11df-b112-00215aee3ebe;Артемівка;Артемовка;село;64024b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324680501;3;1
44ac8028-749b-11df-b112-00215aee3ebe;Артемо-Растівка;Артемо-Рястовка;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925085002;3;1
44ac8029-749b-11df-b112-00215aee3ebe;Артинськ;Артинск;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824487202;3;1
44ac802a-749b-11df-b112-00215aee3ebe;Артирівка;Артыровка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123181302;3;1
44ac802b-749b-11df-b112-00215aee3ebe;Артищів;Артищев;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620983902;3;1
44ac802d-749b-11df-b112-00215aee3ebe;Артільне;Артельное;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323980501;3;1
44ac802e-749b-11df-b112-00215aee3ebe;Артюхівка;Артюховка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924181401;3;1
44ac802f-749b-11df-b112-00215aee3ebe;Артюхівка;Артюховка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321786502;3;1
44ac8030-749b-11df-b112-00215aee3ebe;Артюхове;Артюхово;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922687206;3;1
44ac8031-749b-11df-b112-00215aee3ebe;Архангелівка;Архангеловка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320485502;3;1
44ac8033-749b-11df-b112-00215aee3ebe;Архангельське;Архангельськое;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820682602;3;1
44ac8034-749b-11df-b112-00215aee3ebe;Архангельське;Архангельськое;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521855200;3;1
44ac8036-749b-11df-b112-00215aee3ebe;Архемівка;Архемовка;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323881402;3;1
44ac8037-749b-11df-b112-00215aee3ebe;Архипівка;Архиповка;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424781001;3;1
44ac8038-749b-11df-b112-00215aee3ebe;Архипівка;Архиповка;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322687802;3;1
44ac8039-749b-11df-b112-00215aee3ebe;Арциз;Арциз;місто;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5120410100;3;0
44ac803a-749b-11df-b112-00215aee3ebe;Арчепитівка;Арчепитовка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123380602;3;1
44ac803b-749b-11df-b112-00215aee3ebe;Аршиця;Аршица;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324587003;3;1
44ac803c-749b-11df-b112-00215aee3ebe;Аршичин;Аршичин;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623889402;3;1
44ac803d-749b-11df-b112-00215aee3ebe;Аршуки;Аршуки;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923283202;3;1
44ac803e-749b-11df-b112-00215aee3ebe;Аршуки;Аршуки;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423689302;3;1
44ac803f-749b-11df-b112-00215aee3ebe;Асіївка;Асеевка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320280401;3;1
44ac8043-749b-11df-b112-00215aee3ebe;Астей;Астей;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120480101;3;1
44ac8045-749b-11df-b112-00215aee3ebe;Атаки;Атаки;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325080201;3;1
44ac8046-749b-11df-b112-00215aee3ebe;Атаманське;Атаманское;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920987002;3;1
44ac8048-749b-11df-b112-00215aee3ebe;Аткильня;Аткильня;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424455401;3;1
44ac804a-749b-11df-b112-00215aee3ebe;Атюша;Атюша;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422280501;3;1
44ac804b-749b-11df-b112-00215aee3ebe;Аудиторівка;Аудиторовка;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525282402;3;1
44ac804c-749b-11df-b112-00215aee3ebe;Аули;Аулы;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055300;3;1
44ac804d-749b-11df-b112-00215aee3ebe;Афанасіївка;Афанасьевка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825780501;3;1
44ac804e-749b-11df-b112-00215aee3ebe;Африканівка;Африкановка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320481502;3;1
44ac804f-749b-11df-b112-00215aee3ebe;Ашанівка;Ашановка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120682602;3;1
44ac8086-749b-11df-b112-00215aee3ebe;Баб'янка;Бабянка;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623286802;3;1
44ac8051-749b-11df-b112-00215aee3ebe;Баба;Баба;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923581302;3;1
44ac8052-749b-11df-b112-00215aee3ebe;Бабаї;Бабаи;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325155300;2;0
44ac8053-749b-11df-b112-00215aee3ebe;Бабайківка;Бабайковка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655109;3;1
44ac8054-749b-11df-b112-00215aee3ebe;Бабайкове;Бабайково;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321682902;3;1
c6cc1c01-db7e-11df-9197-00215aee3ebe;Бабаки;Бабаки;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320882002;3;1
2aa5b4ba-834f-11e8-80d4-1c98ec135261;Бабакове;Бабакове;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755111;3;1
44ac8055-749b-11df-b112-00215aee3ebe;Бабакове;Бабаково;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923555601;3;1
44ac8057-749b-11df-b112-00215aee3ebe;Бабанка;Бабанка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124355300;3;1
44ac8058-749b-11df-b112-00215aee3ebe;Бабанське;Бабанское;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321387202;3;1
44ac8059-749b-11df-b112-00215aee3ebe;Бабарики;Бабарики;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422086502;3;1
44ac805b-749b-11df-b112-00215aee3ebe;Бабаці;Бабацы;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723380402;3;1
c6cc1c02-db7e-11df-9197-00215aee3ebe;Бабенки;Бабенки;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320885002;3;1
44ac805f-749b-11df-b112-00215aee3ebe;Бабенкове;Бабенково;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322881502;3;1
44ac8061-749b-11df-b112-00215aee3ebe;Бабин;Бабин;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321580401;3;1
44ac8062-749b-11df-b112-00215aee3ebe;Бабин;Бабин;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322080401;3;1
44ac8063-749b-11df-b112-00215aee3ebe;Бабин;Бабин;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623680801;3;1
44ac8064-749b-11df-b112-00215aee3ebe;Бабин;Бабин;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521280403;3;1
44ac8065-749b-11df-b112-00215aee3ebe;Бабин;Бабин;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621280401;3;1
44ac806a-749b-11df-b112-00215aee3ebe;Бабина;Бабина;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624280301;3;1
44ac8067-749b-11df-b112-00215aee3ebe;Бабина Долина;Бабина Долина;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525386002;3;1
44ac8068-749b-11df-b112-00215aee3ebe;Бабине;Бабино;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320583002;3;1
44ac806b-749b-11df-b112-00215aee3ebe;Бабине;Бабино;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824481001;3;1
44ac806c-749b-11df-b112-00215aee3ebe;Бабин-Зарічний;Бабин-Заречный;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622881202;3;1
44ac806d-749b-11df-b112-00215aee3ebe;Бабиничі;Бабиничи;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755165;3;1
44ac8066-749b-11df-b112-00215aee3ebe;Бабино;Бабина;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625181902;3;1
44ac806e-749b-11df-b112-00215aee3ebe;Бабинці;Бабинцы;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120880601;3;1
44ac806f-749b-11df-b112-00215aee3ebe;Бабинці;Бабинцы;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221055300;3;1
44ac8070-749b-11df-b112-00215aee3ebe;Бабинці;Бабинцы;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523480801;3;1
44ac8072-749b-11df-b112-00215aee3ebe;Бабичі;Бабичи;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755101;3;1
44ac8073-749b-11df-b112-00215aee3ebe;Бабичі;Бабичи;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324280902;3;1
44ac8074-749b-11df-b112-00215aee3ebe;Бабичі;Бабичи;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623987202;3;1
44ac8075-749b-11df-b112-00215aee3ebe;Бабичі;Бабичи;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122780201;3;1
44ac8076-749b-11df-b112-00215aee3ebe;Бабичі;Бабичи;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122085102;3;1
44ac8077-749b-11df-b112-00215aee3ebe;Бабичівка;Бабичовка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610102;3;1
44ac8078-749b-11df-b112-00215aee3ebe;Бабичівка;Бабичовка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825480401;3;1
44ac8079-749b-11df-b112-00215aee3ebe;Бабичівка;Бабичовка;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525287602;3;1
44ac807b-749b-11df-b112-00215aee3ebe;Бабії;Бабии;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784802;3;1
44ac807c-749b-11df-b112-00215aee3ebe;Бабка;Бабка;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620889302;3;1
44ac807e-749b-11df-b112-00215aee3ebe;Баболоки;Баболоки;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623881802;3;1
44ac807f-749b-11df-b112-00215aee3ebe;Бабське;Бабское;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624886707;3;1
44ac8080-749b-11df-b112-00215aee3ebe;Бабурка;Бабурка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322183502;3;0
44ac8081-749b-11df-b112-00215aee3ebe;Бабухів;Бабухов;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624480301;3;1
44ac8082-749b-11df-b112-00215aee3ebe;Бабушки;Бабушки;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825880401;3;1
44ac8083-749b-11df-b112-00215aee3ebe;Бабче;Бабче;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620480401;3;1
44ac8084-749b-11df-b112-00215aee3ebe;Бабчинці;Бабчинцы;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524980301;3;1
44ac8085-749b-11df-b112-00215aee3ebe;Бабшин;Бабшин;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822481302;3;1
44ac8087-749b-11df-b112-00215aee3ebe;Баворів;Баворов;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125280301;2;1
44ac8088-749b-11df-b112-00215aee3ebe;Багата Чернещина;Богатая Чернещина;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324881001;3;1
44ac808a-749b-11df-b112-00215aee3ebe;Багате;Богатое;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223280501;3;1
44ac808b-749b-11df-b112-00215aee3ebe;Багате;Богатое;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122080401;2;1
44ac808e-749b-11df-b112-00215aee3ebe;Багате;Богатое;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824080802;3;1
68cf2a86-749b-11df-b112-00215aee3ebe;Багате (Мопрівське);Багате (Мопрівське);село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055404;3;1
44ac8092-749b-11df-b112-00215aee3ebe;Багатківці;Богатковцы;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125080301;3;1
44ac8093-749b-11df-b112-00215aee3ebe;Багачеве;Багачево;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655102;3;1
4aba37c5-749b-11df-b112-00215aee3ebe;Багачеве (Ватутіне);Богачево (Ватутино);місто;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7110200000;2;0
44ac8094-749b-11df-b112-00215aee3ebe;Багачівка;Богачовка;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823980401;3;1
44ac8096-749b-11df-b112-00215aee3ebe;Багачівка;Богачовка;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124681502;3;1
44ac8097-749b-11df-b112-00215aee3ebe;Багачівка;Богачовка;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121280401;3;1
44ac8099-749b-11df-b112-00215aee3ebe;Багачка;Богачка Первая;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;;3;1
c8674a8e-dbb0-11df-9197-00215aee3ebe;Багачка Перша;Богачка;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320280901;3;1
44ac809a-749b-11df-b112-00215aee3ebe;Багва;Багва;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123180501;3;1
44ac809c-749b-11df-b112-00215aee3ebe;Баглаї;Баглаи;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824280401;3;1
44ac809d-749b-11df-b112-00215aee3ebe;Баглаї;Баглаи;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820987802;3;1
44ac809e-749b-11df-b112-00215aee3ebe;Баглайки;Баглайки;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822782902;3;1
44ac809f-749b-11df-b112-00215aee3ebe;Багна;Багна;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320580301;3;1
44ac80a0-749b-11df-b112-00215aee3ebe;Багни;Багны;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320685802;3;1
44ac80a1-749b-11df-b112-00215aee3ebe;Багнувате;Багноватое;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625585602;3;1
44ac80a2-749b-11df-b112-00215aee3ebe;Баговиця;Баговица;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822489102;3;1
44ac80a3-749b-11df-b112-00215aee3ebe;Багрин;Багрин;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221482103;3;1
44ac80a4-749b-11df-b112-00215aee3ebe;Багринівка;Багриновка;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321080101;3;1
44ac80a5-749b-11df-b112-00215aee3ebe;Багринівці;Багриновцы;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522480401;3;1
44ac80a6-749b-11df-b112-00215aee3ebe;Бадалово;Бодалово;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120481201;3;1
73e94be9-ddc1-11df-9197-00215aee3ebe;Бадів;Бадов;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120488402;3;1
44ac80a7-749b-11df-b112-00215aee3ebe;Бадів Перший;Бадов Первый;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;;3;1
44ac80a8-749b-11df-b112-00215aee3ebe;Бадівка;Бадовка;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624281202;3;1
44ac80a9-749b-11df-b112-00215aee3ebe;Бадьорівка;Бадьоривка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324555102;3;1
44ac80aa-749b-11df-b112-00215aee3ebe;Бажани;Бажаны;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223881502;3;1
44ac80ab-749b-11df-b112-00215aee3ebe;Бажани;Бажаны;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620686602;3;1
44ac80ac-749b-11df-b112-00215aee3ebe;Бажанівка;Бажановка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424180302;3;1
44ac80ad-749b-11df-b112-00215aee3ebe;Базавлучок;Базавлучок;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225283305;3;1
44ac80ae-749b-11df-b112-00215aee3ebe;Базаліївка;Базалиевка;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325481001;3;1
44ac80af-749b-11df-b112-00215aee3ebe;Базалія;Базалия;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824755300;3;1
44ac80b0-749b-11df-b112-00215aee3ebe;Базалуки;Базалуки;село;d00d3b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5310290303;3;1
44ac80b1-749b-11df-b112-00215aee3ebe;Базальтове;Базальтовое;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623480802;3;1
44ac80b2-749b-11df-b112-00215aee3ebe;Базар;Базар;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125580401;3;1
44ac80b3-749b-11df-b112-00215aee3ebe;Базар;Базар;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755111;3;1
44ac80b7-749b-11df-b112-00215aee3ebe;Базар'янка;Базарьянка;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125080201;2;1
44ac80b4-749b-11df-b112-00215aee3ebe;Базаринці;Базаринцы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122480401;3;1
44ac80b5-749b-11df-b112-00215aee3ebe;Базарівка;Базаровка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824282202;3;1
44ac80b6-749b-11df-b112-00215aee3ebe;Базарове;Базарово;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221884002;3;1
44ac80b8-749b-11df-b112-00215aee3ebe;Базелівщина;Базелевщина;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925680303;3;1
62c3ce88-749b-11df-b112-00215aee3ebe;Базилівка;Базиливка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922089302;3;1
44ac80b9-749b-11df-b112-00215aee3ebe;Базилівщина;Базилевщина;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323080701;3;1
44ac80ba-749b-11df-b112-00215aee3ebe;Базниківка;Базниковка;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120487902;3;1
44ac80bb-749b-11df-b112-00215aee3ebe;Баїв;Баев;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722880301;3;1
44ac80bc-749b-11df-b112-00215aee3ebe;Баїха;Баиха;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920687002;3;1
44ac80bd-749b-11df-b112-00215aee3ebe;Байбузи;Байбузы;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124980501;3;1
44ac80be-749b-11df-b112-00215aee3ebe;Байбузівка;Байбузовка;село;6b07f835-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5124380301;3;1
44ac80bf-749b-11df-b112-00215aee3ebe;Байдакове;Байдаково;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524685802;3;1
44ac80c1-749b-11df-b112-00215aee3ebe;Байківка;Байковка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224555302;3;1
44ac80c2-749b-11df-b112-00215aee3ebe;Байківка;Байковка;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521680303;3;1
44ac80c3-749b-11df-b112-00215aee3ebe;Байківці;Байковцы;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155306;3;1
44ac80c4-749b-11df-b112-00215aee3ebe;Байківці;Байковцы;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125280601;2;1
44ac80c5-749b-11df-b112-00215aee3ebe;Баймаки;Баймаки;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620685302;3;1
44ac80c6-749b-11df-b112-00215aee3ebe;Баймаки;Баймаки;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820388003;3;1
44ac80c7-749b-11df-b112-00215aee3ebe;Байрак;Байрак;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324580602;3;1
44ac80c8-749b-11df-b112-00215aee3ebe;Байрак;Байрак;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320255101;3;1
44ac80c9-749b-11df-b112-00215aee3ebe;Байрак;Байрак;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321080801;3;1
44ac80ca-749b-11df-b112-00215aee3ebe;Байрак;Байрак;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083202;3;1
44ac80cb-749b-11df-b112-00215aee3ebe;Байрак;Байрак;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922986502;3;1
44ac80cc-749b-11df-b112-00215aee3ebe;Байрак;Байрак;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923280401;3;1
44ac80cd-749b-11df-b112-00215aee3ebe;Байрак;Байрак;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157302;3;1
44ac80cf-749b-11df-b112-00215aee3ebe;Байрак;Байрак;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320286802;3;1
c6cc1c03-db7e-11df-9197-00215aee3ebe;Байрак;Байрак;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320888803;3;1
44ac80d0-749b-11df-b112-00215aee3ebe;Байраки;Байраки;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320780301;3;1
44ac80d1-749b-11df-b112-00215aee3ebe;Байраківка;Байраковка;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523010101;3;1
44ac80d3-749b-11df-b112-00215aee3ebe;Байтали;Байталы;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120280701;3;1
44ac80d4-749b-11df-b112-00215aee3ebe;Бакаєве;Бакаево;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125189602;3;1
44ac80d5-749b-11df-b112-00215aee3ebe;Бакаївка;Бакаевка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421780801;3;1
44ac80d6-749b-11df-b112-00215aee3ebe;Бакаївка;Бакаевка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121580403;3;1
44ac80d7-749b-11df-b112-00215aee3ebe;Бакай;Бакай;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324282202;3;1
44ac80d8-749b-11df-b112-00215aee3ebe;Бакай;Бакай;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083804;3;1
44ac80d9-749b-11df-b112-00215aee3ebe;Бакали;Бакалы;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220483303;3;1
44ac80da-749b-11df-b112-00215aee3ebe;Бакалове;Бакалово;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123985202;3;1
44ac80db-749b-11df-b112-00215aee3ebe;Бакирівка;Бакировка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920380401;3;1
44ac80dc-749b-11df-b112-00215aee3ebe;Баківці;Баковцы;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722880501;3;1
44ac80dd-749b-11df-b112-00215aee3ebe;Баківці;Баковцы;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621555903;3;1
44ac80de-749b-11df-b112-00215aee3ebe;Бакланова Муравійка;Бакланова Муравейка;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422780501;3;1
44ac80df-749b-11df-b112-00215aee3ebe;Бакланове;Бакланово;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423385202;3;1
44ac80e0-749b-11df-b112-00215aee3ebe;Бакорин;Бакорин;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623886102;3;1
44ac80e1-749b-11df-b112-00215aee3ebe;Бакоти;Бакоты;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123485102;3;1
44ac80e2-749b-11df-b112-00215aee3ebe;Бакош;Бакош;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120488403;3;1
44ac80e3-749b-11df-b112-00215aee3ebe;Бакумівка;Бакумовка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324582403;3;1
44ac80e4-749b-11df-b112-00215aee3ebe;Бакумівка;Бакумовка;село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223785002;3;1
44ac80e5-749b-11df-b112-00215aee3ebe;Бакумівка;Бакумовка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323288602;3;1
44ac80e6-749b-11df-b112-00215aee3ebe;Бакути;Бакуты;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320485303;3;1
44ac80e7-749b-11df-b112-00215aee3ebe;Бакша;Бакша;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920655701;3;1
44ac80e8-749b-11df-b112-00215aee3ebe;Бакша;Бакша;село;6b07f835-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5124380401;3;1
44ac80e9-749b-11df-b112-00215aee3ebe;Бакшарівка;Бакшаровка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323984502;3;1
44ac80eb-749b-11df-b112-00215aee3ebe;Балабанівка;Балабановка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320886503;3;1
44ac80ec-749b-11df-b112-00215aee3ebe;Балабанівка;Балабановка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523180401;3;1
44ac80ed-749b-11df-b112-00215aee3ebe;Балабанівка;Балабановка;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823386002;3;1
44ac80ee-749b-11df-b112-00215aee3ebe;Балабанка;Балабанка;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125084203;2;1
44ac80ef-749b-11df-b112-00215aee3ebe;Балабине;Балабино;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322155300;2;0
c8674a90-dbb0-11df-9197-00215aee3ebe;Балабушині Верби;Балабушины Вербы;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320687602;3;1
44ac80f2-749b-11df-b112-00215aee3ebe;Балажер;Балажер;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120485203;3;1
44ac80f3-749b-11df-b112-00215aee3ebe;Балайчук;Балайчук;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121282902;3;1
44ac80f4-749b-11df-b112-00215aee3ebe;Балакири;Балакиры;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821282202;3;1
44ac80f7-749b-11df-b112-00215aee3ebe;Балаклія;Балаклея;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281202;3;1
44ac80f8-749b-11df-b112-00215aee3ebe;Балаклія;Балаклея;місто;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320210100;2;0
44ac80f9-749b-11df-b112-00215aee3ebe;Балаклія;Балаклея;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123780501;3;1
44ac80fa-749b-11df-b112-00215aee3ebe;Баламутівка;Баламутовка;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321580701;3;1
44ac80fb-749b-11df-b112-00215aee3ebe;Баламутівка;Баламутовка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825880801;3;1
44ac80fc-749b-11df-b112-00215aee3ebe;Баланди;Баланды;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281203;3;1
44ac80fd-749b-11df-b112-00215aee3ebe;Баландине;Баландино;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121880501;3;1
44ac80fe-749b-11df-b112-00215aee3ebe;Баланини;Баланины;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121881203;3;1
44ac80ff-749b-11df-b112-00215aee3ebe;Баланівка;Балановка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
44ac8100-749b-11df-b112-00215aee3ebe;Баланівка;Балановка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520480403;3;1
44ac8101-749b-11df-b112-00215aee3ebe;Баланове;Баланово;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825181202;3;1
44ac8103-749b-11df-b112-00215aee3ebe;Балахівка;Балаховка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524955300;3;1
44ac8104-749b-11df-b112-00215aee3ebe;Балаховичі;Балаховичи;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620880601;3;1
44ac8106-749b-11df-b112-00215aee3ebe;Балашівка;Балашовка;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620480401;3;1
44ac8107-749b-11df-b112-00215aee3ebe;Балашове;Балашово;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125255101;3;1
44ac8109-749b-11df-b112-00215aee3ebe;Балико-Щучинка;Балыко-Щучинка;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222280401;3;1
44ac810a-749b-11df-b112-00215aee3ebe;Балин;Балин;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522484602;3;1
44ac810b-749b-11df-b112-00215aee3ebe;Балин;Балин;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821880301;3;1
44ac810c-749b-11df-b112-00215aee3ebe;Балинці;Балинцы;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625280301;3;1
44ac810d-749b-11df-b112-00215aee3ebe;Баличі;Баличи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622480401;3;1
44ac810e-749b-11df-b112-00215aee3ebe;Балівка;Баловка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323482202;3;1
44ac810f-749b-11df-b112-00215aee3ebe;Балівка;Баловка;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221486801;3;1
44ac8111-749b-11df-b112-00215aee3ebe;Балка;Балка;село;5394b336-41be-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123755801;2;0
c8674a8a-dbb0-11df-9197-00215aee3ebe;Балка;Балка;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123755801;3;1
698893b0-575d-11e7-8102-1c98ec135263;Балки;Балки;місто;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;e20b1e63-63d8-11e2-b7ac-003048d2b473;5625881602;3;1
44ac8112-749b-11df-b112-00215aee3ebe;Балки;Балки;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323385002;3;1
44ac8115-749b-11df-b112-00215aee3ebe;Балки;Балки;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520280201;3;1
44ac8116-749b-11df-b112-00215aee3ebe;Балки;Балки;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;;3;1
44ac8117-749b-11df-b112-00215aee3ebe;Балки;Балки;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820684503;3;1
44ac8118-749b-11df-b112-00215aee3ebe;Балки;Балки;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625881602;3;1
44ac8119-749b-11df-b112-00215aee3ebe;Балківка;Балковка;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522883602;3;1
44ac811a-749b-11df-b112-00215aee3ebe;Балківці;Балковцы;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323080401;3;1
44ac811b-749b-11df-b112-00215aee3ebe;Балкове;Балково;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224585902;3;1
44ac811c-749b-11df-b112-00215aee3ebe;Балкове;Балково;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123984702;3;1
44ac811e-749b-11df-b112-00215aee3ebe;Баловне;Баловное;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824880401;3;0
44ac8121-749b-11df-b112-00215aee3ebe;Балта;Балта;місто;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110200000;2;0
44ac8123-749b-11df-b112-00215aee3ebe;Балучин;Балучин;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620680801;3;1
44ac8124-749b-11df-b112-00215aee3ebe;Балюки;Балюки;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320280302;3;1
44ac8125-749b-11df-b112-00215aee3ebe;Баляси;Балясы;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422486502;3;1
44ac8126-749b-11df-b112-00215aee3ebe;Балясне;Балясное;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321081301;3;1
44ac8127-749b-11df-b112-00215aee3ebe;Балясне;Балясное;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320485202;3;1
44ac8128-749b-11df-b112-00215aee3ebe;Бальківці;Бальковцы;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820983603;3;1
44ac8129-749b-11df-b112-00215aee3ebe;Бандишівка;Бандышовка;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522680601;3;1
44ac812a-749b-11df-b112-00215aee3ebe;Бандури;Бандуры;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920387502;3;1
44ac812b-749b-11df-b112-00215aee3ebe;Бандурівка;Бандуровка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520381301;3;1
44ac812c-749b-11df-b112-00215aee3ebe;Бандурка;Бандурка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825487602;3;1
44ac812d-749b-11df-b112-00215aee3ebe;Бандурове;Бандурово;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521180401;3;1
44ac812e-749b-11df-b112-00215aee3ebe;Бандурове;Бандурово;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520583502;3;1
44ac812f-749b-11df-b112-00215aee3ebe;Банилів;Банилов;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320580501;3;1
44ac8130-749b-11df-b112-00215aee3ebe;Банилів-Підгірний;Банилов-Подгорный;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324580501;3;1
44ac8131-749b-11df-b112-00215aee3ebe;Баничі;Баничи;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921580401;3;1
44ac8133-749b-11df-b112-00215aee3ebe;Баннівка;Банновка;село;6b07f8a2-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5121480701;3;1
44ac8134-749b-11df-b112-00215aee3ebe;Банчени;Банчены;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320781302;3;1
44ac8135-749b-11df-b112-00215aee3ebe;Банюнин;Банюнин;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622180401;3;1
44ac8136-749b-11df-b112-00215aee3ebe;Баня-Березів;Баня-Березов;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623681201;3;1
44ac8137-749b-11df-b112-00215aee3ebe;Баня-Лисовецька;Баня-Лисовецкая;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625384003;3;1
44ac8138-749b-11df-b112-00215aee3ebe;Бар;Бар;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620983002;3;1
44ac8139-749b-11df-b112-00215aee3ebe;Бар;Бар;місто;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520280603;2;0
44ac813a-749b-11df-b112-00215aee3ebe;Бар Вокзал;Бар Вокзал;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
44ac813b-749b-11df-b112-00215aee3ebe;Барабанівське;Барабановское;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421481602;3;1
44ac813e-749b-11df-b112-00215aee3ebe;Барабашівка;Барабашовка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922987802;3;1
44ac813f-749b-11df-b112-00215aee3ebe;Барабашівка;Барабашовка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323980502;3;1
44ac8140-749b-11df-b112-00215aee3ebe;Барабой;Барабой;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123780801;3;1
44ac8143-749b-11df-b112-00215aee3ebe;Баранинці;Баранинцы;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2124880301;3;1
44ac8145-749b-11df-b112-00215aee3ebe;Баранівка;Барановка;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755109;3;1
44ac8146-749b-11df-b112-00215aee3ebe;Баранівка;Барановка;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921589002;3;1
44ac8147-749b-11df-b112-00215aee3ebe;Баранівка;Барановка;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120482502;3;1
44ac8148-749b-11df-b112-00215aee3ebe;Баранівка;Барановка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322682003;3;1
44ac814a-749b-11df-b112-00215aee3ebe;Баранівка;Барановка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823486802;3;1
44ac814b-749b-11df-b112-00215aee3ebe;Баранівка;Барановка;місто;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;1820610100;2;0
44ac814c-749b-11df-b112-00215aee3ebe;Баранівка;Барановка;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424784502;3;1
44ac814d-749b-11df-b112-00215aee3ebe;Баранівка;Барановка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825881201;3;1
44ac814e-749b-11df-b112-00215aee3ebe;Баранівці;Барановцы;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624281602;3;1
44ac814f-749b-11df-b112-00215aee3ebe;Баранне;Баранное;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625884802;3;1
44ac8150-749b-11df-b112-00215aee3ebe;Баранники;Баранники;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;;3;1
44ac8152-749b-11df-b112-00215aee3ebe;Баранове;Бараново;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321280501;3;1
44ac8153-749b-11df-b112-00215aee3ebe;Баранове;Бараново;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325782002;3;1
44ac8154-749b-11df-b112-00215aee3ebe;Баранове;Бараново;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121880401;3;1
44ac8155-749b-11df-b112-00215aee3ebe;Баратівка;Баратовка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824580401;3;1
44ac8156-749b-11df-b112-00215aee3ebe;Баратівка;Баратовка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825780701;3;1
44ac8157-749b-11df-b112-00215aee3ebe;Барахти;Барахты;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221480201;3;1
44ac8158-749b-11df-b112-00215aee3ebe;Бараші;Бараши;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821780401;3;1
44ac8159-749b-11df-b112-00215aee3ebe;Барашівка;Барашевка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822083002;1;0
44ac815a-749b-11df-b112-00215aee3ebe;Барбово;Барбово;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122785402;3;1
44ac815b-749b-11df-b112-00215aee3ebe;Барбурське;Барбурское;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421787602;3;1
44ac815c-749b-11df-b112-00215aee3ebe;Барвинівка;Барвиновка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824080201;3;1
44ac815d-749b-11df-b112-00215aee3ebe;Барвінівка;Барвиновка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323680401;3;1
44ac815f-749b-11df-b112-00215aee3ebe;Барвінівка;Барвиновка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522282602;3;1
44ac8160-749b-11df-b112-00215aee3ebe;Барвінки;Барвинки;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823480401;3;1
44ac8161-749b-11df-b112-00215aee3ebe;Барвінків;Барвинков;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;2620887702;3;1
44ac8162-749b-11df-b112-00215aee3ebe;Барвінкове;Барвинково;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924788702;3;1
44ac8163-749b-11df-b112-00215aee3ebe;Барвінкове;Барвинково;місто;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320410100;2;1
44ac8164-749b-11df-b112-00215aee3ebe;Барвінкове;Барвинково;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824286202;3;1
44ac8165-749b-11df-b112-00215aee3ebe;Барвінкове;Барвинково;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421788503;3;1
44ac8166-749b-11df-b112-00215aee3ebe;Барвінкове;Барвинково;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523484702;3;1
44ac8167-749b-11df-b112-00215aee3ebe;Барвінок;Барвинок;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222084502;3;1
44ac8168-749b-11df-b112-00215aee3ebe;Барвінок;Барвинок;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225083506;3;1
44ac8169-749b-11df-b112-00215aee3ebe;Барвінок;Барвинок;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520383002;3;1
44ac816a-749b-11df-b112-00215aee3ebe;Барвінок;Барвинок;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2124880302;3;1
44ac816b-749b-11df-b112-00215aee3ebe;Барвінок;Барвинок;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121288203;3;1
cdc44f3c-dde8-11df-9197-00215aee3ebe;Барвінок;Барвынок;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722886204;3;1
44ac816c-749b-11df-b112-00215aee3ebe;Барвінщина;Барвинщина;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322885302;3;1
44ac816d-749b-11df-b112-00215aee3ebe;Барди;Барды;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822383402;3;1
44ac816f-749b-11df-b112-00215aee3ebe;Барилів;Барилов;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623980401;3;1
44ac8170-749b-11df-b112-00215aee3ebe;Барилівка;Бариловка;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922385802;3;1
44ac8171-749b-11df-b112-00215aee3ebe;Барилівщина;Бариловщина;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324888203;3;1
44ac8172-749b-11df-b112-00215aee3ebe;Барило;Барыло;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920681802;3;1
44ac8173-749b-11df-b112-00215aee3ebe;Барилове;Барилово;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324884202;3;1
44ac8174-749b-11df-b112-00215aee3ebe;Бариш;Барыш;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121280401;3;1
44ac8175-749b-11df-b112-00215aee3ebe;Баришівка;Барышевка;місто;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220255100;2;0
44ac8176-749b-11df-b112-00215aee3ebe;Баркасово;Баркасово;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122787002;2;1
44ac8177-749b-11df-b112-00215aee3ebe;Баркове;Барково;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455106;3;1
44ac8178-749b-11df-b112-00215aee3ebe;Бармаки;Бармаки;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624681503;3;1
44ac817a-749b-11df-b112-00215aee3ebe;Барок;Барок;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520284806;3;1
44ac817c-749b-11df-b112-00215aee3ebe;Бартатів;Бартатов;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620980801;3;1
44ac817d-749b-11df-b112-00215aee3ebe;Бартошівка;Бартошевка;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123088803;3;1
44ac817e-749b-11df-b112-00215aee3ebe;Бартошівка;Бартошевка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523182003;3;1
44ac817f-749b-11df-b112-00215aee3ebe;Бартуха;Бартуха;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821482702;3;1
44ac8180-749b-11df-b112-00215aee3ebe;Барчани;Барчаны;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157601;3;1
44ac8181-749b-11df-b112-00215aee3ebe;Басаличівка;Басалычевка;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520887606;3;1
44ac8183-749b-11df-b112-00215aee3ebe;Баси;Басы;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124981002;3;1
44ac8184-749b-11df-b112-00215aee3ebe;Басівка;Басовка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924181701;3;1
44ac8185-749b-11df-b112-00215aee3ebe;Басівка;Басовка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924780701;3;1
44ac8186-749b-11df-b112-00215aee3ebe;Басівка;Басовка;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623682003;3;0
44ac8187-749b-11df-b112-00215aee3ebe;Басівка;Басовка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821285502;3;1
44ac8188-749b-11df-b112-00215aee3ebe;Басівщина;Басовщина;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5922982402;3;1
44ac8189-749b-11df-b112-00215aee3ebe;Басове;Басово;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322684402;3;1
44ac818a-749b-11df-b112-00215aee3ebe;Бастова Рудня;Бастова Рудня;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821784002;3;1
44ac818b-749b-11df-b112-00215aee3ebe;Баталий;Баталий;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125189202;3;1
44ac818d-749b-11df-b112-00215aee3ebe;Батиїв;Батыев;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623982202;3;1
44ac818f-749b-11df-b112-00215aee3ebe;Батрадь;Батрадь;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120480401;3;1
44ac8190-749b-11df-b112-00215aee3ebe;Батурин;Батурин;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420310300;3;1
44ac8191-749b-11df-b112-00215aee3ebe;Батфа;Батфа;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124881502;3;1
c6cc1c00-db7e-11df-9197-00215aee3ebe;Батюшки;Батюшки;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320655101;3;1
44ac8192-749b-11df-b112-00215aee3ebe;Батятичі;Батятичи;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622180801;3;1
44ac8193-749b-11df-b112-00215aee3ebe;Батьки;Батьки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321380701;3;1
44ac8194-749b-11df-b112-00215aee3ebe;Батьків;Батьков;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620380401;3;1
44ac8195-749b-11df-b112-00215aee3ebe;Батьків;Батьков;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625886002;3;1
44ac8196-749b-11df-b112-00215aee3ebe;Батьківці;Батьковцы;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624283602;3;1
44ac8197-749b-11df-b112-00215aee3ebe;Батьківщина;Батьковщина;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755102;3;1
44ac8198-749b-11df-b112-00215aee3ebe;Батьово;Батьево;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120455400;3;0
44ac8199-749b-11df-b112-00215aee3ebe;Бахани;Баханы;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424487202;3;1
44ac819a-749b-11df-b112-00215aee3ebe;Бахів;Бахов;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722182402;2;1
44ac819b-749b-11df-b112-00215aee3ebe;Бахматівці;Бахматовцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825081001;3;1
44ac819c-749b-11df-b112-00215aee3ebe;Бахмач;Бахмач;місто;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7420310100;1;0
44ac819d-749b-11df-b112-00215aee3ebe;Бахмач село;Бахмач Село;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420380501;3;1
44ac819e-749b-11df-b112-00215aee3ebe;Бахметівка;Бахметовка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324286002;3;1
44ac81a1-749b-11df-b112-00215aee3ebe;Бахтин;Бахтин;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321081502;3;1
44ac81a2-749b-11df-b112-00215aee3ebe;Бахтин;Бахтин;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522880401;3;1
44ac81a3-749b-11df-b112-00215aee3ebe;Бахтинок;Бахтинок;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522880402;3;1
44ac81a7-749b-11df-b112-00215aee3ebe;Бацеве;Бацево;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824481302;3;1
44ac81a8-749b-11df-b112-00215aee3ebe;Бацмани;Бацманы;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924186102;3;1
44ac81a9-749b-11df-b112-00215aee3ebe;Бачина;Бачина;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625187403;3;1
44ac81aa-749b-11df-b112-00215aee3ebe;Бачів;Бачев;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623380306;3;1
44ac81ab-749b-11df-b112-00215aee3ebe;Бачівськ;Бачевск;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921580701;3;1
44ac81ac-749b-11df-b112-00215aee3ebe;Бачка;Бачка;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620686603;3;1
44ac81ad-749b-11df-b112-00215aee3ebe;Бачкурине;Бачкурино;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123481001;3;1
44ac81ae-749b-11df-b112-00215aee3ebe;Бачманівка;Бачмановка;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823980701;3;1
44ac81af-749b-11df-b112-00215aee3ebe;Башарівка;Башаровка;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625880401;3;1
44ac81b0-749b-11df-b112-00215aee3ebe;Башилівка;Башиловка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320680501;3;1
44ac81b1-749b-11df-b112-00215aee3ebe;Башине;Башино;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621281202;3;1
44ac81b2-749b-11df-b112-00215aee3ebe;Башківці;Башковци;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125888402;3;1
44ac81b3-749b-11df-b112-00215aee3ebe;Башлики;Башлыки;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721882403;3;1
44ac81b4-749b-11df-b112-00215aee3ebe;Башмачка;Башмачка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225081001;3;1
44ac81b5-749b-11df-b112-00215aee3ebe;Башова;Башова;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724582902;3;1
44ac81b6-749b-11df-b112-00215aee3ebe;Баштанівка;Баштановка;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125080501;2;1
44ac81b8-749b-11df-b112-00215aee3ebe;Баштанка;Баштанка;місто;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610100;2;0
44ac81b9-749b-11df-b112-00215aee3ebe;Баштанків;Баштанков;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122580701;3;1
44ac81bb-749b-11df-b112-00215aee3ebe;Баштечки;Баштечки;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120980801;3;1
44ac81bc-749b-11df-b112-00215aee3ebe;Баштине;Баштино;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524985902;3;1
44ac81bd-749b-11df-b112-00215aee3ebe;Башуки;Башуки;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123480401;3;1
44ac81be-749b-11df-b112-00215aee3ebe;Баяничі;Бояничи;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624884602;3;1
44ac81bf-749b-11df-b112-00215aee3ebe;Бджільна;Бджильна;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523780401;3;1
44ac81c0-749b-11df-b112-00215aee3ebe;Бебехи;Бебехи;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623980602;3;1
44ac81c1-749b-11df-b112-00215aee3ebe;Бегендяцька Пастіль;Бегендяцкая Пастиль;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120884002;3;1
44ac81c2-749b-11df-b112-00215aee3ebe;Бегета;Бегета;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720580803;3;1
44ac81c3-749b-11df-b112-00215aee3ebe;Бегма;Бегма;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224856201;3;1
44ac81c4-749b-11df-b112-00215aee3ebe;Бедевля;Бедевля;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124480401;3;0
44ac81c5-749b-11df-b112-00215aee3ebe;Бедриківці;Бедриковцы;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122080401;3;1
44ac81c6-749b-11df-b112-00215aee3ebe;Бедриківці;Бедриковцы;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821280401;3;1
44ac81c7-749b-11df-b112-00215aee3ebe;Беєве;Беево;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923280801;3;1
44ac81c9-749b-11df-b112-00215aee3ebe;Бежів;Бежев;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825680801;3;1
44ac81ca-749b-11df-b112-00215aee3ebe;Безбородьки;Безбородьки;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120680501;3;1
44ac81cb-749b-11df-b112-00215aee3ebe;Безбородьків;Безбородьков;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421782002;3;1
44ac81cc-749b-11df-b112-00215aee3ebe;Безбородькове;Безбородьково;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225085502;3;1
44ac81cd-749b-11df-b112-00215aee3ebe;Безброди;Безброды;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620682802;3;1
44ac81ce-749b-11df-b112-00215aee3ebe;Безводівка;Безводовка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421710102;3;1
44ac81cf-749b-11df-b112-00215aee3ebe;Безводівка;Безводовка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323288902;3;1
44ac81d0-749b-11df-b112-00215aee3ebe;Безводне;Безводное;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824280201;3;1
44ac81d1-749b-11df-b112-00215aee3ebe;Безводне;Безводное;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520981502;3;1
44ac81d2-749b-11df-b112-00215aee3ebe;Безводне;Безводное;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525680401;3;1
44ac81d3-749b-11df-b112-00215aee3ebe;Безводня;Безводня;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522583202;3;1
44ac81d5-749b-11df-b112-00215aee3ebe;Бездрик;Бездрик;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924780901;2;1
44ac81d7-749b-11df-b112-00215aee3ebe;Безиків;Безиков;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421480802;3;1
44ac81dd-749b-11df-b112-00215aee3ebe;Безім'янка;Безымянка;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125080301;2;1
44ac81d8-749b-11df-b112-00215aee3ebe;Безіменне;Безымянное;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825784203;3;1
44ac81da-749b-11df-b112-00215aee3ebe;Безіменне;Безымянное;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223185602;3;1
44ac81db-749b-11df-b112-00215aee3ebe;Безіменне;Безымянное;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521480203;3;1
44ac81de-749b-11df-b112-00215aee3ebe;Безкровне;Бескровное;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922681702;3;1
44ac81df-749b-11df-b112-00215aee3ebe;Безлюдівка;Безлюдовка;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325155600;2;0
44ac81e1-749b-11df-b112-00215aee3ebe;Безмятежне;Безмятежное;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325781501;3;1
44ac81e2-749b-11df-b112-00215aee3ebe;Безодня;Безодня;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625887802;3;1
44ac81e9-749b-11df-b112-00215aee3ebe;Безп'ятне;Безпятное;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221482802;3;1
38396f62-02ed-11e2-a474-003048d2b473;Безпалівка;Беспаловка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321786003;3;1
44ac81e3-749b-11df-b112-00215aee3ebe;Безпальцеве;Беспальцево;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320680303;3;1
44ac81e4-749b-11df-b112-00215aee3ebe;Безпальче;Беспальче;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120681101;3;1
44ac81e5-749b-11df-b112-00215aee3ebe;Безпечна;Беспечная;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224082102;2;1
44ac81e6-749b-11df-b112-00215aee3ebe;Безпечна;Беспечная;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825880501;3;1
44ac81e7-749b-11df-b112-00215aee3ebe;Безпечна;Беспечная;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120981001;3;1
44ac81e8-749b-11df-b112-00215aee3ebe;Безпечне;Беспечное;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420389002;3;1
44ac81ea-749b-11df-b112-00215aee3ebe;Безруки;Безруки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321383602;3;1
44ac81eb-749b-11df-b112-00215aee3ebe;Безруки;Безруки;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322080501;3;1
44ac81ec-749b-11df-b112-00215aee3ebe;Безруків;Безруков;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322081002;3;1
44ac81ed-749b-11df-b112-00215aee3ebe;Безручки;Безручки;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083902;3;1
44ac81ee-749b-11df-b112-00215aee3ebe;Безсали;Безсалы;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322680401;3;1
44ac81ef-749b-11df-b112-00215aee3ebe;Безсалівка;Безсаловка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920683502;3;1
44ac81f0-749b-11df-b112-00215aee3ebe;Безсокирне;Безсокирное;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920682602;3;1
44ac81f1-749b-11df-b112-00215aee3ebe;Безуглівка;Безугловка;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221980601;3;1
44ac81f2-749b-11df-b112-00215aee3ebe;Безуглівка;Безугловка;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220880902;3;0
44ac81f3-749b-11df-b112-00215aee3ebe;Безуглівка;Безугловка;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425881502;3;1
44ac81f4-749b-11df-b112-00215aee3ebe;Безуглівка;Безугловка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423380401;3;1
44ac81f5-749b-11df-b112-00215aee3ebe;Безугляки;Безугляки;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224085602;2;1
44ac81f6-749b-11df-b112-00215aee3ebe;Бейзими;Бейзымы;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822183302;3;1
44ac81f7-749b-11df-b112-00215aee3ebe;Бейзимівка;Бейзимовка;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825880601;3;1
44ac81f8-749b-11df-b112-00215aee3ebe;Бекарівка;Бекаровка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582202;3;1
0ad59739-8357-11e8-80d4-1c98ec135261;Бекетівка;Бекетовка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222982203;3;1
5cb618e6-749b-11df-b112-00215aee3ebe;Бекетівка (Колгоспне);Бекетовка (Колгоспное);село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222982203;3;1
44ac81f9-749b-11df-b112-00215aee3ebe;Белеїв;Белеев;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622080101;3;1
44ac81fa-749b-11df-b112-00215aee3ebe;Белелуя;Белелуя;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625280401;3;1
44ac81fb-749b-11df-b112-00215aee3ebe;Белз;Белз;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624810300;3;1
44ac81fc-749b-11df-b112-00215aee3ebe;Бендзари;Бендзары;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290003;3;1
44ac81fd-749b-11df-b112-00215aee3ebe;Бендюга;Биндюга;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624883502;3;1
44ac81fe-749b-11df-b112-00215aee3ebe;Бендюгівка;Бендюговка;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222280801;3;1
44ac81ff-749b-11df-b112-00215aee3ebe;Бене;Бэнэ;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120484401;3;0
44ac8200-749b-11df-b112-00215aee3ebe;Бенева;Бенева;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125080201;3;1
44ac8201-749b-11df-b112-00215aee3ebe;Бенедиківці;Бенедиковцы;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122786803;3;1
44ac8202-749b-11df-b112-00215aee3ebe;Бенедівка;Бенедовка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820885002;3;1
44ac8203-749b-11df-b112-00215aee3ebe;Бензики;Бензики;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925386803;3;1
44ac8204-749b-11df-b112-00215aee3ebe;Беньківці;Беньковцы;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624486502;3;1
44ac8205-749b-11df-b112-00215aee3ebe;Бербениці;Бербеницы;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322680501;3;1
44ac8206-749b-11df-b112-00215aee3ebe;Бервиця;Бервица;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221255502;3;1
44ac8207-749b-11df-b112-00215aee3ebe;Бердинове;Бердыново;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125484402;3;1
44ac8208-749b-11df-b112-00215aee3ebe;Бердихів;Бердыхов;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625880401;3;1
44ac820a-749b-11df-b112-00215aee3ebe;Бердичів;Бердичев;місто;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1810400000;2;0
44ac820b-749b-11df-b112-00215aee3ebe;Бердянка;Бердянка;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322280501;3;1
44ac8210-749b-11df-b112-00215aee3ebe;Берег;Берег;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621680401;3;1
44ac8211-749b-11df-b112-00215aee3ebe;Берегелі;Берегели;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822781702;3;1
44ac8212-749b-11df-b112-00215aee3ebe;Берегелі;Берегели;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824286202;3;1
44ac8213-749b-11df-b112-00215aee3ebe;Береги;Берега;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623685902;3;1
44ac8214-749b-11df-b112-00215aee3ebe;Береги;Берега;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624280302;3;1
44ac8215-749b-11df-b112-00215aee3ebe;Береги;Берега;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122487202;3;1
44ac8216-749b-11df-b112-00215aee3ebe;Береги;Берега;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623880301;3;1
e0517ecd-db89-11df-9197-00215aee3ebe;Береги;Берега;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;;3;1
44ac8217-749b-11df-b112-00215aee3ebe;Берегове;Береговое;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724584102;3;1
44ac8218-749b-11df-b112-00215aee3ebe;Берегове;Береговое;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121083803;3;1
44ac821a-749b-11df-b112-00215aee3ebe;Берегове;Береговое;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520382502;3;1
44ac821c-749b-11df-b112-00215aee3ebe;Берегове;Береговое;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622480801;3;1
44ac821e-749b-11df-b112-00215aee3ebe;Берегове;Берегово;місто;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2110200000;2;0
44ac8223-749b-11df-b112-00215aee3ebe;Берегове;Береговое;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824083602;3;1
44ac8224-749b-11df-b112-00215aee3ebe;Берегове;Береговое;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523885202;3;1
44ac8225-749b-11df-b112-00215aee3ebe;Берегомет;Берегомет;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7320555300;3;0
44ac8226-749b-11df-b112-00215aee3ebe;Берегомет;Берегомет;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322580501;3;1
44ac8227-749b-11df-b112-00215aee3ebe;Берегуйфалу;Берегуйфалу;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120487201;3;1
44ac8228-749b-11df-b112-00215aee3ebe;Бережани;Бережаны;місто;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6110500000;2;0
44ac8229-749b-11df-b112-00215aee3ebe;Бережани;Бережаны;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623688202;3;0
5cb619e7-749b-11df-b112-00215aee3ebe;Бережани (Комунарівка);Бережаны (Коммунаровка);село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521683303;3;1
44ac822a-749b-11df-b112-00215aee3ebe;Бережанка;Бережанка;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720880201;3;1
44ac822b-749b-11df-b112-00215aee3ebe;Бережанка;Бережанка;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123881201;3;1
44ac822c-749b-11df-b112-00215aee3ebe;Бережанка;Бережанка;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120883002;3;1
44ac822e-749b-11df-b112-00215aee3ebe;Бережанка;Бережанка;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524184202;3;1
44ac822f-749b-11df-b112-00215aee3ebe;Бережанка;Бережанка;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825086002;3;1
44ac8230-749b-11df-b112-00215aee3ebe;Бережанка;Бережанка;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825280501;2;1
44ac8231-749b-11df-b112-00215aee3ebe;Бережесть;Бережесть;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824286702;3;1
44ac8232-749b-11df-b112-00215aee3ebe;Бережинка;Бережинка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522580901;3;1
44ac8233-749b-11df-b112-00215aee3ebe;Бережинка;Бережинка;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523483602;3;1
44ac8234-749b-11df-b112-00215aee3ebe;Бережинці;Бережинцы;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824780201;3;1
44ac8235-749b-11df-b112-00215aee3ebe;Бережівка;Бережовка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421781201;3;1
44ac8236-749b-11df-b112-00215aee3ebe;Бережки;Бережки;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621880401;3;1
44ac8237-749b-11df-b112-00215aee3ebe;Бережне;Бережное;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922083003;3;1
44ac8239-749b-11df-b112-00215aee3ebe;Бережне;Бережное;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624884202;3;1
44ac823a-749b-11df-b112-00215aee3ebe;Бережне;Бережное;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520883806;3;1
44ac823b-749b-11df-b112-00215aee3ebe;Бережниця;Бережница;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723687302;3;1
44ac823c-749b-11df-b112-00215aee3ebe;Бережниця;Бережница;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320580502;3;1
44ac823d-749b-11df-b112-00215aee3ebe;Бережниця;Бережница;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622884803;3;1
44ac823e-749b-11df-b112-00215aee3ebe;Бережниця;Бережница;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620885502;3;1
44ac823f-749b-11df-b112-00215aee3ebe;Бережниця;Бережница;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624289202;3;1
44ac8240-749b-11df-b112-00215aee3ebe;Бережниця;Бережница;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621580601;3;1
44ac8241-749b-11df-b112-00215aee3ebe;Бережниця;Бережница;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625386802;3;1
44ac8242-749b-11df-b112-00215aee3ebe;Бережниця;Бережница;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621880601;3;1
44ac8243-749b-11df-b112-00215aee3ebe;Бережнівка;Бережновка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321880702;3;1
44ac8244-749b-11df-b112-00215aee3ebe;Бережок;Бережок;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625585102;3;1
44ac8245-749b-11df-b112-00215aee3ebe;Бережонка;Бережонка;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320583502;3;1
44ac8246-749b-11df-b112-00215aee3ebe;Бережці;Бережци;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723384802;3;1
44ac8247-749b-11df-b112-00215aee3ebe;Береза;Береза;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921581101;3;1
44ac8248-749b-11df-b112-00215aee3ebe;Береза;Береза;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724281003;3;1
44ac8249-749b-11df-b112-00215aee3ebe;Березанка;Березанка;місто;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820955100;3;1
44ac824a-749b-11df-b112-00215aee3ebe;Березанка;Березанка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425583502;3;1
44ac824b-749b-11df-b112-00215aee3ebe;Березанка;Березанка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423380650;3;1
6fc23bc3-81f5-11e0-9a6b-003048d2b473;Березанщина;Березанщина;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;;3;1
44ac824d-749b-11df-b112-00215aee3ebe;Березань;Березань;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825184802;3;1
44ac824e-749b-11df-b112-00215aee3ebe;Березань;Березань;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121080701;3;1
44ac824f-749b-11df-b112-00215aee3ebe;Березань;Березань;місто;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3210400000;2;0
44ac8250-749b-11df-b112-00215aee3ebe;Берездів;Берездов;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823980601;3;1
44ac8251-749b-11df-b112-00215aee3ebe;Берездівці;Берездовцы;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623080401;3;1
44ac824c-749b-11df-b112-00215aee3ebe;Березенщина;Березенщина;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221487002;3;1
44ac8252-749b-11df-b112-00215aee3ebe;Березець;Березец;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620980702;3;1
44ac8253-749b-11df-b112-00215aee3ebe;Березина;Березина;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622786702;3;1
44ac8254-749b-11df-b112-00215aee3ebe;Березина;Березина;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623080601;3;1
44ac8255-749b-11df-b112-00215aee3ebe;Березина;Березина;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621581802;3;1
44ac8256-749b-11df-b112-00215aee3ebe;Березина;Березина;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822082002;1;1
44ac8257-749b-11df-b112-00215aee3ebe;Березина;Березина;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820985302;3;1
44ac8258-749b-11df-b112-00215aee3ebe;Березина;Березина;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620881202;3;1
2334a987-dd4b-11df-9197-00215aee3ebe;Березина;Березина;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520688904;3;0
44ac8259-749b-11df-b112-00215aee3ebe;Березине;Березино;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222082002;3;1
44ac825a-749b-11df-b112-00215aee3ebe;Березине;Березино;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124755300;3;1
44ac825b-749b-11df-b112-00215aee3ebe;Березине;Березино;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823480803;3;1
44ac825c-749b-11df-b112-00215aee3ebe;Березини;Березины;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625880801;3;1
44ac825d-749b-11df-b112-00215aee3ebe;Березинка;Березинка;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122785002;2;1
44ac825e-749b-11df-b112-00215aee3ebe;Березичі;Березичи;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155101;3;1
44ac825f-749b-11df-b112-00215aee3ebe;Березів;Березов;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625183502;3;1
44ac8260-749b-11df-b112-00215aee3ebe;Березів Яр;Березов Яр;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922986902;3;1
44ac8261-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083703;3;1
44ac8262-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921282502;3;1
44ac8263-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323586202;3;1
44ac8264-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322655101;3;1
44ac8265-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325155800;3;1
44ac8266-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323386002;3;1
44ac8267-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325782001;3;1
44ac8268-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290004;3;1
44ac8269-749b-11df-b112-00215aee3ebe;Березівка;Березовка;місто;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121210100;3;1
44ac826b-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624483103;3;1
44ac826c-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2625880101;3;1
44ac826d-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623980601;3;1
44ac8274-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222782602;3;1
44ac8275-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821780801;3;1
44ac8276-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822381202;3;1
44ac8277-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821180201;3;1
44ac8278-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822080301;3;1
44ac8279-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823180401;3;1
44ac827a-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424488802;3;1
44ac827b-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420887602;3;1
44ac827c-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425380501;3;1
44ac827d-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123180701;3;1
44ac827e-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523185503;3;1
44ac827f-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523080802;3;1
44ac8280-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524981201;3;1
44ac8281-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520484406;3;1
44ac8282-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523180901;3;1
44ac8283-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521187602;3;1
44ac8284-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520884002;3;1
44ac8285-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520386202;3;1
44ac8286-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521987601;3;1
44ac8287-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525881201;3;1
44ac8288-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823380701;3;1
44ac8289-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623083003;3;1
44ac828a-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320880801;3;1
44ac828b-749b-11df-b112-00215aee3ebe;Березівка;Березовка;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322880301;3;1
44ac828c-749b-11df-b112-00215aee3ebe;Березівське;Березовское;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322056501;3;1
44ac828f-749b-11df-b112-00215aee3ebe;Березна;Березна;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221680801;2;1
44ac8290-749b-11df-b112-00215aee3ebe;Березна;Березна;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423055300;3;1
44ac8291-749b-11df-b112-00215aee3ebe;Березна;Березна;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524880301;3;1
44ac8292-749b-11df-b112-00215aee3ebe;Березна Воля;Березная Воля;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155103;3;1
44ac8293-749b-11df-b112-00215aee3ebe;Березне;Березное;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825587502;3;1
44ac8294-749b-11df-b112-00215aee3ebe;Березне;Березное;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824280701;3;1
44ac8295-749b-11df-b112-00215aee3ebe;Березне;Березное;місто;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620410100;2;0
44ac8296-749b-11df-b112-00215aee3ebe;Березневе;Березневое;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386002;3;1
44ac8297-749b-11df-b112-00215aee3ebe;Березнегувате;Березнегуватое;місто;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821183002;2;1
8cdd5ce5-de27-11df-9197-00215aee3ebe;Березнегувате;Березнегуватое;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522885802;3;1
44ac8298-749b-11df-b112-00215aee3ebe;Березнегувате вокзал;Березнеговатое Вокзал;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;3;1
44ac8299-749b-11df-b112-00215aee3ebe;Березнегуватське;Березнеговатское;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824580701;3;1
44ac829a-749b-11df-b112-00215aee3ebe;Березники;Березники;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724287103;3;1
44ac829d-749b-11df-b112-00215aee3ebe;Березники;Березники;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124080301;3;1
44ac829e-749b-11df-b112-00215aee3ebe;Березники;Березники;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821780601;3;1
44ac829f-749b-11df-b112-00215aee3ebe;Березнуватівка;Березноватовка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225081201;3;1
44ac82a0-749b-11df-b112-00215aee3ebe;Березнуватка;Березнуватка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224883002;3;1
44ac82a1-749b-11df-b112-00215aee3ebe;Березняк;Березняк;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224587502;3;1
44ac82a2-749b-11df-b112-00215aee3ebe;Березняки;Березняки;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324285002;3;1
44ac82a3-749b-11df-b112-00215aee3ebe;Березняки;Березняки;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923583802;3;1
44ac82a4-749b-11df-b112-00215aee3ebe;Березняки;Березняки;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124987502;3;1
44ac82a5-749b-11df-b112-00215aee3ebe;Березняки;Березняки;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123781001;3;1
44ac82a6-749b-11df-b112-00215aee3ebe;Березняки;Березняки;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324885002;3;1
325c57f3-ddd2-11df-9197-00215aee3ebe;Березова Балка;Березовая Балка;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524381301;3;1
44ac82a7-749b-11df-b112-00215aee3ebe;Березова Гать;Березовая Гать;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825482602;3;1
44ac82a8-749b-11df-b112-00215aee3ebe;Березова Гать;Березовая Гать;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423681204;3;1
44ac82a9-749b-11df-b112-00215aee3ebe;Березова Лука;Березовая Лука;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320480701;3;1
44ac82aa-749b-11df-b112-00215aee3ebe;Березова Роща;Березовая Роща;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424785502;3;1
44ac82ab-749b-11df-b112-00215aee3ebe;Березова Рудка;Березовая Рудка;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323880701;3;1
44ac82ac-749b-11df-b112-00215aee3ebe;Березове;Березовое;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325483703;3;1
44ac82ad-749b-11df-b112-00215aee3ebe;Березове;Березовое;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224282301;3;1
44ac82b1-749b-11df-b112-00215aee3ebe;Березове;Березовое;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223187702;3;1
44ac82b2-749b-11df-b112-00215aee3ebe;Березове;Березовое;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821580402;3;1
44ac82b3-749b-11df-b112-00215aee3ebe;Березове;Березовое;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625080801;3;1
8716268a-749b-11df-b112-00215aee3ebe;Березовий Гай (Червоний Гай);Березовая Роща (Червоный Гай);село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424787010;3;1
44ac82b4-749b-11df-b112-00215aee3ebe;Березовий Груд;Березовый Груд;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822880802;3;1
44ac82b5-749b-11df-b112-00215aee3ebe;Березовиця;Березовица;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425384002;3;1
44ac82b6-749b-11df-b112-00215aee3ebe;Березовичі;Березовичи;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720580801;3;1
44ac82af-749b-11df-b112-00215aee3ebe;Березово;Березово;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125380601;3;1
44ac82b7-749b-11df-b112-00215aee3ebe;Березолуки;Березолуки;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724582905;3;1
44ac82b8-749b-11df-b112-00215aee3ebe;Березоточа;Березоточа;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322880401;3;1
44ac82b9-749b-11df-b112-00215aee3ebe;Березуватка;Березоватка;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525885602;3;1
44ac82ba-749b-11df-b112-00215aee3ebe;Березці;Березцы;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825085502;3;1
44ac82bb-749b-11df-b112-00215aee3ebe;Березянка;Березянка;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825280801;3;1
44ac82bc-749b-11df-b112-00215aee3ebe;Берека;Берека;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324581001;3;1
44ac82be-749b-11df-b112-00215aee3ebe;Берем'яни;Беремяны;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
44ac82bf-749b-11df-b112-00215aee3ebe;Берем'яни;Беремяны;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623255201;3;1
44ac82bd-749b-11df-b112-00215aee3ebe;Беремицьке;Беремицкое;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422010401;3;1
44ac82c0-749b-11df-b112-00215aee3ebe;Береславка;Береславка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520881602;3;1
44ac82c1-749b-11df-b112-00215aee3ebe;Бересняги;Бересняги;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122082702;3;1
44ac82c2-749b-11df-b112-00215aee3ebe;Берестечко;Берестечко;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720810300;3;1
44ac82c4-749b-11df-b112-00215aee3ebe;Берестечко;Берестечко;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621484003;3;1
44ac82c5-749b-11df-b112-00215aee3ebe;Берестівка;Берестовка;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923281201;3;1
44ac82c6-749b-11df-b112-00215aee3ebe;Берестівка;Берестовка;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820680401;3;1
44ac82c7-749b-11df-b112-00215aee3ebe;Берестівка;Берестовка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522283602;3;1
44ac82c8-749b-11df-b112-00215aee3ebe;Берестівка;Берестовка;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620881301;3;1
44ac82c9-749b-11df-b112-00215aee3ebe;Берестки;Берестки;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324585502;3;1
44ac82cd-749b-11df-b112-00215aee3ebe;Берестове;Берестовое;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324883002;3;1
44ac82ce-749b-11df-b112-00215aee3ebe;Берестове;Берестовое;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320682502;3;1
44ac82cf-749b-11df-b112-00215aee3ebe;Берестове;Берестовое;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582002;3;1
44ac82d0-749b-11df-b112-00215aee3ebe;Берестове;Берестовое;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323686202;3;1
44ac82d4-749b-11df-b112-00215aee3ebe;Берестовенька;Берестовенька;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323384502;3;1
44ac82d5-749b-11df-b112-00215aee3ebe;Берестовець;Берестовец;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822380401;3;1
44ac82d6-749b-11df-b112-00215aee3ebe;Берестовець;Берестовец;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420880401;3;1
44ac82d7-749b-11df-b112-00215aee3ebe;Берестовець;Берестовец;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124380901;3;1
44ac82d8-749b-11df-b112-00215aee3ebe;Берестовець;Берестовец;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623480803;3;1
44ac82d9-749b-11df-b112-00215aee3ebe;Бересток;Бересток;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122088703;3;1
44ac82db-749b-11df-b112-00215aee3ebe;Берестя;Берестя;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323080601;3;1
44ac82dc-749b-11df-b112-00215aee3ebe;Берестя;Берестя;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621880901;3;0
44ac82dd-749b-11df-b112-00215aee3ebe;Берестяги;Берестяги;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521180801;3;1
44ac82de-749b-11df-b112-00215aee3ebe;Берестяне;Берестяное;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721855702;3;1
44ac82df-749b-11df-b112-00215aee3ebe;Берестяни;Берестяны;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624281603;3;1
44ac82e0-749b-11df-b112-00215aee3ebe;Берестянка;Берестянка;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221086302;3;1
44ac82e1-749b-11df-b112-00215aee3ebe;Берестянки;Берестянки;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320284802;3;1
44ac82e2-749b-11df-b112-00215aee3ebe;Береськ;Береск;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724580601;3;1
44ac82e3-749b-11df-b112-00215aee3ebe;Берилівка;Бериловка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421487502;3;1
44ac82e4-749b-11df-b112-00215aee3ebe;Беримівці;Беримовцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122680701;3;1
44ac82e5-749b-11df-b112-00215aee3ebe;Берислав;Берислав;місто;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520610100;2;1
44ac82e6-749b-11df-b112-00215aee3ebe;Берізка;Березка;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421183202;3;1
44ac82e7-749b-11df-b112-00215aee3ebe;Берізки;Березки;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923581303;3;1
44ac82e8-749b-11df-b112-00215aee3ebe;Берізки;Березки;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823980201;3;1
44ac82ea-749b-11df-b112-00215aee3ebe;Берізки;Березки;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320487202;3;1
44ac828e-749b-11df-b112-00215aee3ebe;Берізки;Березки;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820883302;3;1
44ac82eb-749b-11df-b112-00215aee3ebe;Берізки-Бершадські;Березки-Бершадские;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520481606;3;1
44ac82ec-749b-11df-b112-00215aee3ebe;Берізки-Чечельницькі;Березки-Чечельницкие;село;78f453a8-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0525080801;3;1
44ac82ed-749b-11df-b112-00215aee3ebe;Беркозівка;Беркозовка;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122080701;3;1
44ac82ee-749b-11df-b112-00215aee3ebe;Берлин;Берлин;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620388605;3;1
44ac82ef-749b-11df-b112-00215aee3ebe;Берлоги;Берлоги;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624880301;3;1
44ac82f0-749b-11df-b112-00215aee3ebe;Берлози;Берлозы;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422080901;3;1
44ac82f1-749b-11df-b112-00215aee3ebe;Берлютине;Берлютино;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122585302;3;1
74dc495d-749b-11df-b112-00215aee3ebe;Берлядка (Петрівка);Берлядка (Петровка);село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522884804;3;1
44ac82f2-749b-11df-b112-00215aee3ebe;Бермешів;Бермешев;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722482203;3;1
44ac82f3-749b-11df-b112-00215aee3ebe;Бернадівка;Бернадивка;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125088303;3;1
44ac82f4-749b-11df-b112-00215aee3ebe;Бернашівка;Бернашовка;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522680800;3;1
44ac82f5-749b-11df-b112-00215aee3ebe;Бернове;Берново;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322080801;3;1
44ac82f6-749b-11df-b112-00215aee3ebe;Бертишів;Бертышев;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621555904;3;1
44ac82f7-749b-11df-b112-00215aee3ebe;Бертники;Бертники;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124280201;3;1
44ac82f9-749b-11df-b112-00215aee3ebe;Берці;Берцы;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484402;3;1
44ac82fa-749b-11df-b112-00215aee3ebe;Бершадь;Бершадь;місто;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;520410100;2;0
44ac82fb-749b-11df-b112-00215aee3ebe;Бершацьке;Бершацкое;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086902;3;1
44ac82fc-749b-11df-b112-00215aee3ebe;Беседівка;Беседовка;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923586202;3;1
44ac82fd-749b-11df-b112-00215aee3ebe;Бесіди;Беседы;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785502;3;1
44ac82ff-749b-11df-b112-00215aee3ebe;Бесідівщина;Беседовщина;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320886602;3;1
44ac8300-749b-11df-b112-00215aee3ebe;Бесідка;Беседка;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224280801;3;1
44ac8301-749b-11df-b112-00215aee3ebe;Бесідки;Беседки;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823988002;3;1
44ac8302-749b-11df-b112-00215aee3ebe;Бессараби;Бессарабы;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323287702;3;1
44ac8303-749b-11df-b112-00215aee3ebe;Бессарабівка;Бессарабовка;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323180601;3;1
44ac8304-749b-11df-b112-00215aee3ebe;Бессарабка;Бессарабка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924181702;3;1
44ac8305-749b-11df-b112-00215aee3ebe;Бессарабка;Бессарабка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820982204;3;1
44ac8306-749b-11df-b112-00215aee3ebe;Бессарабка;Бессарабка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655103;3;1
44ac8308-749b-11df-b112-00215aee3ebe;Бехи;Бехи;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822380601;3;1
44ac830a-749b-11df-b112-00215aee3ebe;Бехтерщина;Бехтерщина;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320285703;3;1
44ac830b-749b-11df-b112-00215aee3ebe;Бецилове;Бецилово;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123980401;3;1
44ac833c-749b-11df-b112-00215aee3ebe;Бечеве;Бечево;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323484802;3;1
44ac830d-749b-11df-b112-00215aee3ebe;Бешківка;Бешковка;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422482502;3;1
44ac830e-749b-11df-b112-00215aee3ebe;Бешти;Бешты;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322681902;3;1
44ac8310-749b-11df-b112-00215aee3ebe;Бзів;Бзов;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220280401;3;1
44ac8311-749b-11df-b112-00215aee3ebe;Бзовиця;Бзовица;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122680801;3;1
44ac8312-749b-11df-b112-00215aee3ebe;Библо;Быбло;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625180502;3;1
44ac8313-749b-11df-b112-00215aee3ebe;Биків;Быков;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622487902;3;1
44ac8314-749b-11df-b112-00215aee3ebe;Биків;Быков;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621280802;3;1
44ac8315-749b-11df-b112-00215aee3ebe;Биків;Быков;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820983503;3;1
44ac8316-749b-11df-b112-00215aee3ebe;Биківка;Быковка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821455300;3;1
44ac8317-749b-11df-b112-00215aee3ebe;Биківці;Быковцы;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125881201;3;1
44ac8318-749b-11df-b112-00215aee3ebe;Бикова Гребля;Быковая Гребля;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220480501;2;1
44ac8319-749b-11df-b112-00215aee3ebe;Бикове;Быково;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222081701;3;1
44ac831a-749b-11df-b112-00215aee3ebe;Бикоза;Быкоза;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120884402;3;1
44ac831c-749b-11df-b112-00215aee3ebe;Биличі;Биличи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625183102;3;1
8716266a-749b-11df-b112-00215aee3ebe;Билка (Червоне);Былка (Червоное);село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422289001;3;1
44ac831d-749b-11df-b112-00215aee3ebe;Бирзулове;Бирзулово;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523810101;3;1
44ac831e-749b-11df-b112-00215aee3ebe;Бирине;Бирино;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423680801;3;1
44ac831f-749b-11df-b112-00215aee3ebe;Бирлівка;Бырловка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120682101;3;1
44ac8320-749b-11df-b112-00215aee3ebe;Бирлівка;Бырловка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520480603;3;1
44ac8322-749b-11df-b112-00215aee3ebe;Бирюки;Бирюки;село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223780501;3;1
44ac8323-749b-11df-b112-00215aee3ebe;Бисків;Бысков;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323582502;3;1
44ac8324-749b-11df-b112-00215aee3ebe;Бистре;Быстрое;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325156101;3;1
44ac8325-749b-11df-b112-00215aee3ebe;Бистрець;Быстрец;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620881001;3;1
44ac8326-749b-11df-b112-00215aee3ebe;Бистрий;Быстрый;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621283302;3;1
44ac8327-749b-11df-b112-00215aee3ebe;Бистрик;Быстрик;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922681701;3;1
44ac8328-749b-11df-b112-00215aee3ebe;Бистрик;Быстрик;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820880601;3;1
44ac8329-749b-11df-b112-00215aee3ebe;Бистрик;Быстрик;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825281401;3;1
44ac832a-749b-11df-b112-00215aee3ebe;Бистрик;Быстрик;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523480802;3;1
44ac832b-749b-11df-b112-00215aee3ebe;Бистриця;Быстрица;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624080601;3;1
44ac832c-749b-11df-b112-00215aee3ebe;Бистриця;Быстрица;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621280801;3;1
44ac832d-749b-11df-b112-00215aee3ebe;Бистриця;Быстрица;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122781001;2;1
44ac832e-749b-11df-b112-00215aee3ebe;Бистриця;Быстрица;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820684003;3;1
44ac832f-749b-11df-b112-00215aee3ebe;Бистриця-Гірська;Быстрица-Горская;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621280907;3;1
44ac8330-749b-11df-b112-00215aee3ebe;Бистричі;Быстричи;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620481801;3;1
44ac8331-749b-11df-b112-00215aee3ebe;Бистрі;Быстри;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822086802;2;1
44ac8332-749b-11df-b112-00215aee3ebe;Бистріївка;Быстреевка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825081202;3;1
44ac8333-749b-11df-b112-00215aee3ebe;Бистріївка;Быстреевка;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825281201;3;1
44ac8334-749b-11df-b112-00215aee3ebe;Бистровиця;Быстровица;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720887402;3;1
44ac8335-749b-11df-b112-00215aee3ebe;Битакове Озеро;Битаково Озеро;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610109;3;1
44ac8336-749b-11df-b112-00215aee3ebe;Битень;Битень;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155307;3;1
44ac8337-749b-11df-b112-00215aee3ebe;Битиця;Битица;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924781501;3;1
44ac8338-749b-11df-b112-00215aee3ebe;Битків;Бытков;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624055300;3;1
44ac833a-749b-11df-b112-00215aee3ebe;Бихів;Быхов;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155104;3;1
44ac8339-749b-11df-b112-00215aee3ebe;Бихольцохівка;Бихольцохивка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424484402;3;1
44ac830c-749b-11df-b112-00215aee3ebe;Бичаль;Бычаль;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623481602;3;1
44ac833b-749b-11df-b112-00215aee3ebe;Бичева;Бичева;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823180801;3;1
44ac833d-749b-11df-b112-00215aee3ebe;Бичківці;Бычковцы;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125581401;3;1
44ac833e-749b-11df-b112-00215aee3ebe;Бишів;Бышев;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621281502;3;1
44ac833f-749b-11df-b112-00215aee3ebe;Бишів;Бышев;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623980901;3;1
44ac8340-749b-11df-b112-00215aee3ebe;Бишів;Бышев;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222780601;3;1
44ac8341-749b-11df-b112-00215aee3ebe;Бишки;Бышки;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123080301;3;1
44ac8342-749b-11df-b112-00215aee3ebe;Бишків;Бышков;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622780801;3;1
44ac8343-749b-11df-b112-00215aee3ebe;Бишкінь;Бишкинь;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922980401;3;1
44ac8344-749b-11df-b112-00215aee3ebe;Бишляк;Бышляк;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620881802;3;1
44ac8345-749b-11df-b112-00215aee3ebe;Бібрка;Бобрка;місто;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;7d1766f6-b6ff-11eb-80d4-000c29800ae7;4623310300;2;0
44ac8346-749b-11df-b112-00215aee3ebe;Бібщани;Бибщаны;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621880801;3;1
44ac8347-749b-11df-b112-00215aee3ebe;Бігач;Бигач;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423080501;3;1
44ac8348-749b-11df-b112-00215aee3ebe;Бігунь;Бигунь;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824280301;3;1
44ac8349-749b-11df-b112-00215aee3ebe;Бідани;Биданы;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920387503;3;1
44ac834a-749b-11df-b112-00215aee3ebe;Бідило;Бидыло;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323580302;3;1
44ac834b-749b-11df-b112-00215aee3ebe;Бідин;Бедин;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423380402;3;1
44ac834c-749b-11df-b112-00215aee3ebe;Бідряги;Бедряги;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325156102;3;1
44ac834d-749b-11df-b112-00215aee3ebe;Бієве;Биево;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323282802;3;1
44ac834e-749b-11df-b112-00215aee3ebe;Біж;Биж;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923586702;3;1
44ac834f-749b-11df-b112-00215aee3ebe;Біжівка;Бижевка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920980801;3;1
44ac8350-749b-11df-b112-00215aee3ebe;Біївське;Биевское;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
44ac8351-749b-11df-b112-00215aee3ebe;Біївці;Биевцы;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220680301;3;1
44ac8352-749b-11df-b112-00215aee3ebe;Біївці;Биевцы;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322880701;3;1
44ac8353-749b-11df-b112-00215aee3ebe;Бійничі;Бойничи;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621282102;3;1
44ac8354-749b-11df-b112-00215aee3ebe;Біла;Белая;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125280701;2;0
44ac8355-749b-11df-b112-00215aee3ebe;Біла;Белая;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125581001;3;1
44ac8356-749b-11df-b112-00215aee3ebe;Біла;Белая;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322580701;3;1
44ac8357-749b-11df-b112-00215aee3ebe;Біла;Белая;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522280201;3;1
44ac8358-749b-11df-b112-00215aee3ebe;Біла;Белая;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525681602;3;1
44ac8359-749b-11df-b112-00215aee3ebe;Біла;Белая;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825280401;2;1
86c17858-a873-11e0-a658-003048d2b473;Біла Гора;Белая Гора;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3f6103d6-ddd8-11e0-a755-003048d2b473;*462588320;3;1
44ac835a-749b-11df-b112-00215aee3ebe;Біла Криниця;Белая Криница;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821180401;3;1
44ac835b-749b-11df-b112-00215aee3ebe;Біла Криниця;Белая Криница;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825055300;3;1
44ac835f-749b-11df-b112-00215aee3ebe;Біла Криниця;Белая Криница;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321086303;3;1
44ac8360-749b-11df-b112-00215aee3ebe;Біла Криниця;Белая Криница;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520955400;3;1
44ac8361-749b-11df-b112-00215aee3ebe;Біла Криниця;Белая Криница;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624680701;2;0
44ac8362-749b-11df-b112-00215aee3ebe;Біла Річка;Белая Речка;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620882502;3;1
44ac8364-749b-11df-b112-00215aee3ebe;Біла Церква;Белая Церковь;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123681001;3;0
44ac8365-749b-11df-b112-00215aee3ebe;Біла Церква;Белая Церковь;місто;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3210300000;1;0
44ac8367-749b-11df-b112-00215aee3ebe;Білани;Биланы;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920681803;3;1
44ac8368-749b-11df-b112-00215aee3ebe;Біласовиця;Беласовица;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121581001;3;1
44ac8369-749b-11df-b112-00215aee3ebe;Білаші;Белаши;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325785202;3;1
44ac836a-749b-11df-b112-00215aee3ebe;Білаші;Белаши;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322010101;3;1
44ac836b-749b-11df-b112-00215aee3ebe;Білаші;Белаши;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621880603;3;1
44ac836c-749b-11df-b112-00215aee3ebe;Білашів;Белашов;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722180401;3;1
44ac836d-749b-11df-b112-00215aee3ebe;Білашів;Белашов;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622680401;3;1
44ac836e-749b-11df-b112-00215aee3ebe;Білашів;Белашов;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624280401;3;1
44ac836f-749b-11df-b112-00215aee3ebe;Білашки;Белашки;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124080401;3;1
44ac8370-749b-11df-b112-00215aee3ebe;Білашки;Белашки;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523481001;3;1
44ac8371-749b-11df-b112-00215aee3ebe;Білгород-Дністровський;Белгород-Днестровский;місто;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5110300000;2;0
44ac8373-749b-11df-b112-00215aee3ebe;Біле;Белое;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623380401;3;1
44ac8375-749b-11df-b112-00215aee3ebe;Біле;Белое;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621885002;3;1
44ac8376-749b-11df-b112-00215aee3ebe;Біле;Белое;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620880901;3;1
44ac8377-749b-11df-b112-00215aee3ebe;Білеве;Билево;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822180401;3;1
44ac8378-749b-11df-b112-00215aee3ebe;Білейки;Белейки;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422080601;3;1
44ac8379-749b-11df-b112-00215aee3ebe;Біленченківка;Беленченковка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5310390001;3;1
44ac837a-749b-11df-b112-00215aee3ebe;Біленщина;Беленщина;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224580501;3;1
44ac837b-749b-11df-b112-00215aee3ebe;Біленьке;Беленькое;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120887702;3;1
44ac837c-749b-11df-b112-00215aee3ebe;Біленьке;Беленькое;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322180801;3;1
44ac8380-749b-11df-b112-00215aee3ebe;Біленьке Перше;Беленькое Первое;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322184902;3;1
44ac8381-749b-11df-b112-00215aee3ebe;Білецьке;Белицкое;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823680301;3;1
44ac8382-749b-11df-b112-00215aee3ebe;Білецьке;Белицкое;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823083702;3;1
44ac8383-749b-11df-b112-00215aee3ebe;Білецьківка;Белецковка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322480401;3;1
44ac8385-749b-11df-b112-00215aee3ebe;Білещина;Белещина;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424188802;3;1
44ac8386-749b-11df-b112-00215aee3ebe;Білижинці;Белижинцы;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822181902;3;1
44ac8387-749b-11df-b112-00215aee3ebe;Білий Берег;Белый Берег;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222086802;3;1
44ac8388-749b-11df-b112-00215aee3ebe;Білий Берег;Белый Берег;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823484802;3;1
44ac8389-749b-11df-b112-00215aee3ebe;Білий Камінь;Белый Камень;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4621880401;3;1
44ac838a-749b-11df-b112-00215aee3ebe;Білий Камінь;Белый Камень;село;78f453a8-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0525080401;3;1
44ac838c-749b-11df-b112-00215aee3ebe;Білий Потік;Белый Поток;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125587103;3;1
44ac838d-749b-11df-b112-00215aee3ebe;Білий Рукав;Белый Рукав;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524886302;3;1
44ac838e-749b-11df-b112-00215aee3ebe;Білики;Белики;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321855300;3;1
44ac838f-749b-11df-b112-00215aee3ebe;Білики;Белики;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323280201;3;1
c8ffe618-14d1-11e5-93c7-003048d2b473;Білики;Белики;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422080701;3;1
44ac8390-749b-11df-b112-00215aee3ebe;Біликівка;Беликовка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920688202;3;1
44ac8391-749b-11df-b112-00215aee3ebe;Біликівці;Беликовцы;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521080303;3;1
44ac8392-749b-11df-b112-00215aee3ebe;Білилівка;Белиловка;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825280401;3;1
44ac8393-749b-11df-b112-00215aee3ebe;Білин;Белин;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720580401;3;1
44ac8394-749b-11df-b112-00215aee3ebe;Білин;Белин;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722180801;3;1
44ac8395-749b-11df-b112-00215aee3ebe;Білин;Белин;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123680501;3;1
44ac8396-749b-11df-b112-00215aee3ebe;Білине;Белино;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290005;3;1
44ac8397-749b-11df-b112-00215aee3ebe;Білинівка;Белиновка;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121683702;3;1
44ac8398-749b-11df-b112-00215aee3ebe;Білиця;Белица;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925680301;3;1
d866bb64-6969-11e2-a79e-003048d2b473;Біличеве;Биличеве;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325181703;3;1
44ac839a-749b-11df-b112-00215aee3ebe;Біличин;Беличин;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520286506;3;1
44ac839b-749b-11df-b112-00215aee3ebe;Біличі;Беличи;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723381902;3;1
44ac839c-749b-11df-b112-00215aee3ebe;Біличі;Беличи;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721182402;3;1
44ac839d-749b-11df-b112-00215aee3ebe;Біличі;Беличи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625180201;3;1
44ac839e-749b-11df-b112-00215aee3ebe;Білі Ослави;Белые Ославы;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624080301;3;1
44ac839f-749b-11df-b112-00215aee3ebe;Білів;Белев;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624684902;3;1
44ac83a0-749b-11df-b112-00215aee3ebe;Білівка;Беловка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655103;3;1
44ac83a2-749b-11df-b112-00215aee3ebe;Білівські Хутори;Белевские Хутора;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624681103;3;1
44ac83a3-749b-11df-b112-00215aee3ebe;Білівці ;Беловцы;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120881802;3;1
44ac83a4-749b-11df-b112-00215aee3ebe;Білівці;Беловцы;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325080401;3;1
44ac83a5-749b-11df-b112-00215aee3ebe;Біліївка;Белиевка;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221680401;3;1
44ac83a6-749b-11df-b112-00215aee3ebe;Білка;Белка;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925080401;3;1
44ac83a7-749b-11df-b112-00215aee3ebe;Білка;Белка;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121880701;3;1
44ac83a8-749b-11df-b112-00215aee3ebe;Білка;Белка;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621682202;3;1
44ac83a9-749b-11df-b112-00215aee3ebe;Білка;Белка;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623381402;3;1
44ac83aa-749b-11df-b112-00215aee3ebe;Білка;Белка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824288202;3;1
44ac83ab-749b-11df-b112-00215aee3ebe;Білка;Белка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822380201;3;1
44ac83ac-749b-11df-b112-00215aee3ebe;Білка;Белка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825484302;3;1
44ac83ad-749b-11df-b112-00215aee3ebe;Білка;Белка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825084902;3;1
44ac83ae-749b-11df-b112-00215aee3ebe;Білка;Белка;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620481601;3;1
44ac83af-749b-11df-b112-00215aee3ebe;Білки;Белки;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121980801;3;0
44ac83b0-749b-11df-b112-00215aee3ebe;Білки;Белки;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821484002;3;1
44ac83b1-749b-11df-b112-00215aee3ebe;Білки;Белки;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824780601;3;1
44ac83b2-749b-11df-b112-00215aee3ebe;Білки;Белки;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521255401;3;1
44ac83b3-749b-11df-b112-00215aee3ebe;Білківці;Белковцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122681002;3;1
44ac83b4-749b-11df-b112-00215aee3ebe;Білобережжя;Белобережье;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621682602;3;1
44ac83b5-749b-11df-b112-00215aee3ebe;Білоберізка;Белоберезка;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;2620880501;3;1
44ac83b6-749b-11df-b112-00215aee3ebe;Білобожниця;Белобожница;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125580701;3;1
44ac83b7-749b-11df-b112-00215aee3ebe;Біловарці;Беловарцы;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124480501;3;1
44ac83b9-749b-11df-b112-00215aee3ebe;Біловежі Другі;Беловежи Вторые;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420381002;3;1
44ac83ba-749b-11df-b112-00215aee3ebe;Біловежі Перші;Беловежи Первые;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420381001;3;1
44ac83bb-749b-11df-b112-00215aee3ebe;Біловишневе;Беловишневое;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920682603;3;1
44ac83bc-749b-11df-b112-00215aee3ebe;Біловіж;Беловеж;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625080401;3;1
44ac83bd-749b-11df-b112-00215aee3ebe;Біловод;Беловод;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924182001;3;1
44ac83be-749b-11df-b112-00215aee3ebe;Біловоди;Беловоды;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924781101;3;1
44ac83c0-749b-11df-b112-00215aee3ebe;Біловодське;Беловодское;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924182302;3;1
44ac83c1-749b-11df-b112-00215aee3ebe;Білогалиця;Белогалица;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923882802;3;1
44ac83c4-749b-11df-b112-00215aee3ebe;Білогір'я;Белогорье;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323982502;3;1
44ac83c5-749b-11df-b112-00215aee3ebe;Білогір'я;Белогорье;місто;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820355100;3;1
44ac83c2-749b-11df-b112-00215aee3ebe;Білогірка;Белогорка;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520982002;3;1
44ac83c7-749b-11df-b112-00215aee3ebe;Білоголови;Белоголовы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122680401;3;1
44ac83cb-749b-11df-b112-00215aee3ebe;Білогорівка;Белогоровка;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625883402;3;1
44ac83cc-749b-11df-b112-00215aee3ebe;Білогорілка;Белогорилка;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322680701;3;1
44ac83cd-749b-11df-b112-00215aee3ebe;Білогородка;Белогородка;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222480401;2;0
44ac83ce-749b-11df-b112-00215aee3ebe;Білогородка;Белогородка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822180701;3;1
44ac83cf-749b-11df-b112-00215aee3ebe;Білогородка;Белогородка;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621681202;3;1
44ac83d0-749b-11df-b112-00215aee3ebe;Білогриве;Белогривое;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922681201;3;1
44ac83d2-749b-11df-b112-00215aee3ebe;Білогуби;Белогубы;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324580802;3;1
44ac83d3-749b-11df-b112-00215aee3ebe;Білозерівка;Белозеровка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522287002;3;1
44ac83d4-749b-11df-b112-00215aee3ebe;Білозерка;Белозерка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922055302;3;1
44ac83d5-749b-11df-b112-00215aee3ebe;Білозерка;Белозерка;місто;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520355100;3;1
44ac83d6-749b-11df-b112-00215aee3ebe;Білозерне;Белозерное;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523482402;3;1
44ac83d7-749b-11df-b112-00215aee3ebe;Білозерське;Белозерское;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955102;3;1
44ac83db-749b-11df-b112-00215aee3ebe;Білозір'я;Белозорье;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124981001;3;1
44ac83d8-749b-11df-b112-00215aee3ebe;Білозірка;Белозерка;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123880901;3;1
44ac83d9-749b-11df-b112-00215aee3ebe;Білозірка;Белозерка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522486402;3;1
44ac8179-749b-11df-b112-00215aee3ebe;Білозірка;Бармашово;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823380401;3;1
44ac83dc-749b-11df-b112-00215aee3ebe;Білозорина;Белозорина;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624085602;3;1
44ac83dd-749b-11df-b112-00215aee3ebe;Білокам'янка;Белокаменка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824282703;3;1
44ac83df-749b-11df-b112-00215aee3ebe;Білоки;Белоки;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624287902;3;1
44ac83e0-749b-11df-b112-00215aee3ebe;Білоконі;Белокони;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324210101;3;1
56bdda2a-749b-11df-b112-00215aee3ebe;Білоконівка (Калініне);Билоконивка (Калинино);село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321887503;3;1
44ac83e1-749b-11df-b112-00215aee3ebe;Білокопитове;Белокопытово;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921581001;3;1
44ac83e2-749b-11df-b112-00215aee3ebe;Білокоровичі;Белокоровичи;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824480401;3;1
44ac83e3-749b-11df-b112-00215aee3ebe;Білокриниця;Белокриница;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123480701;3;1
44ac83e4-749b-11df-b112-00215aee3ebe;Білокриниця;Белокриница;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122688902;3;1
44ac83e5-749b-11df-b112-00215aee3ebe;Білокриниця;Белокриница;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124880301;3;1
44ac83e6-749b-11df-b112-00215aee3ebe;Білокриниччя;Белокриничье;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825588502;3;1
44ac83e9-749b-11df-b112-00215aee3ebe;Білолісся;Белолесье;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125080401;2;1
44ac83eb-749b-11df-b112-00215aee3ebe;Білопілля;Белополье;місто;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920610100;2;1
44ac83ec-749b-11df-b112-00215aee3ebe;Білопілля;Белополье;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521480403;3;1
44ac83ed-749b-11df-b112-00215aee3ebe;Білопіль;Белополь;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722480601;3;1
44ac83ee-749b-11df-b112-00215aee3ebe;Білопіль;Белополь;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523484702;3;1
44ac83ef-749b-11df-b112-00215aee3ebe;Білопіль;Белополь;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825585102;3;1
44ac83f3-749b-11df-b112-00215aee3ebe;Білорічиця;Белоречица;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424180301;3;1
44ac83f6-749b-11df-b112-00215aee3ebe;Білоскірка;Белоскирка;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125282303;3;1
44ac83f7-749b-11df-b112-00215aee3ebe;Білосток;Белосток;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722880601;3;1
44ac83f8-749b-11df-b112-00215aee3ebe;Білотин;Белотин;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822181001;3;1
44ac83f9-749b-11df-b112-00215aee3ebe;Білоусівка;Белоусовка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320681402;3;1
44ac83fb-749b-11df-b112-00215aee3ebe;Білоусівка;Белоусовка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922087402;3;1
44ac83fc-749b-11df-b112-00215aee3ebe;Білоусівка;Белоусовка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786302;3;1
44ac83fd-749b-11df-b112-00215aee3ebe;Білоусівка;Белоусовка;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822082602;3;1
44ac83fe-749b-11df-b112-00215aee3ebe;Білоусівка;Белоусовка;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324081001;3;1
44ac83ff-749b-11df-b112-00215aee3ebe;Білоусівка;Белоусовка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324285002;3;1
44ac8400-749b-11df-b112-00215aee3ebe;Білоусівка;Белоусовка;село;6b07f835-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5124381502;3;1
44ac8401-749b-11df-b112-00215aee3ebe;Білоусівка;Белоусовка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120680801;3;1
44ac8402-749b-11df-b112-00215aee3ebe;Білоусівка;Белоусовка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524380401;3;1
44ac8403-749b-11df-b112-00215aee3ebe;Білоусівка;Белоусовка;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325180401;3;1
44ac8404-749b-11df-b112-00215aee3ebe;Білоусове;Белоусово;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323281002;3;1
44ac8405-749b-11df-b112-00215aee3ebe;Білоусове;Белоусово;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520980301;3;1
44ac8406-749b-11df-b112-00215aee3ebe;Білоцерківка;Белоцерковка;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281201;3;1
44ac8407-749b-11df-b112-00215aee3ebe;Білоцерківка;Белоцерковка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824583402;3;1
44ac840a-749b-11df-b112-00215aee3ebe;Білоцерківці;Белоцерковцы;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420680801;3;1
44ac840b-749b-11df-b112-00215aee3ebe;Білоцерківці;Белоцерковцы;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323880401;3;1
44ac840c-749b-11df-b112-00215aee3ebe;Білошапки;Белошапки;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424180601;3;1
8d17eed2-749b-11df-b112-00215aee3ebe;Білошиці;Билошици;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822387001;3;1
44ac840d-749b-11df-b112-00215aee3ebe;Білошицька Слобода;Белошицкая Слобода;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422481001;3;1
44ac840f-749b-11df-b112-00215aee3ebe;Білоярське;Белоярское;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923584402;3;1
44ac8410-749b-11df-b112-00215aee3ebe;Білухівка;Белуховка;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321680401;3;1
44ac8411-749b-11df-b112-00215aee3ebe;Білявинці;Белявинцы;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121287402;3;1
44ac8412-749b-11df-b112-00215aee3ebe;Білявці;Белявцы;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620388402;3;1
44ac8413-749b-11df-b112-00215aee3ebe;Біляєве;Беляево;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822383802;3;1
44ac8414-749b-11df-b112-00215aee3ebe;Біляївка;Беляевка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324587002;3;1
44ac8415-749b-11df-b112-00215aee3ebe;Біляївка;Беляевка;місто;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5110500000;2;0
44ac8416-749b-11df-b112-00215aee3ebe;Біляївка;Беляевка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321586102;3;1
44ac8417-749b-11df-b112-00215aee3ebe;Біляївка;Беляевка;село;78f4540d-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6524180501;3;1
44ac8418-749b-11df-b112-00215aee3ebe;Біляївка;Беляевка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520588603;3;1
44ac8419-749b-11df-b112-00215aee3ebe;Біляки;Беляки;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324580401;3;1
9171e832-8356-11e8-80d4-1c98ec135261;Біляківка;Билякивка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655110;3;1
44ac841a-749b-11df-b112-00215aee3ebe;Біляни;Беляны;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522885602;3;1
44ac841b-749b-11df-b112-00215aee3ebe;Біляни;Беляны;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524980901;3;1
44ac841c-749b-11df-b112-00215aee3ebe;Білярі;Беляры;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122755401;3;1
44ac841d-749b-11df-b112-00215aee3ebe;Білятичі;Белятичи;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625484402;3;1
44ac841e-749b-11df-b112-00215aee3ebe;Більківці;Бельковцы;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510104;3;1
44ac8420-749b-11df-b112-00215aee3ebe;Більмачівка;Бельмачовка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421781001;3;1
44ac8421-749b-11df-b112-00215aee3ebe;Більськ;Бельск;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322280401;3;1
44ac8422-749b-11df-b112-00215aee3ebe;Більськ;Бельск;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625081302;3;1
44ac8423-749b-11df-b112-00215aee3ebe;Більська Воля;Бельская Воля;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620881201;3;1
44ac8424-749b-11df-b112-00215aee3ebe;Більчаки;Бельчаки;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620486602;3;1
44ac8425-749b-11df-b112-00215aee3ebe;Більче;Бильче;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623080801;3;1
44ac8426-749b-11df-b112-00215aee3ebe;Більче;Бильче;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621484006;3;1
44ac8427-749b-11df-b112-00215aee3ebe;Більче-Золоте;Бильче-Золотое;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120880901;3;1
44ac8428-749b-11df-b112-00215aee3ebe;Більчин;Бельчин;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822184202;3;1
44ac8429-749b-11df-b112-00215aee3ebe;Більчинка;Бельчинка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822184203;3;1
44ac842a-749b-11df-b112-00215aee3ebe;Більшівці;Большовцы;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621255300;3;1
44ac842e-749b-11df-b112-00215aee3ebe;Більшовик;Большевик;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521910101;3;1
44ac8430-749b-11df-b112-00215aee3ebe;Біньова;Бинева;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625587103;3;1
44ac8431-749b-11df-b112-00215aee3ebe;Біологічне;Биологичное;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324080704;3;1
74dc46d5-749b-11df-b112-00215aee3ebe;Біологічне (Падалки);Биологическое (Падалки);село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324080704;3;1
44ac8432-749b-11df-b112-00215aee3ebe;Бір;Бор;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621886402;3;1
44ac8433-749b-11df-b112-00215aee3ebe;Бір;Бор;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422083902;3;1
44ac8434-749b-11df-b112-00215aee3ebe;Бір;Бор;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622286602;3;1
44ac8435-749b-11df-b112-00215aee3ebe;Бірки;Бирки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321381001;3;1
44ac8436-749b-11df-b112-00215aee3ebe;Бірки;Бирки;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281204;3;1
44ac8437-749b-11df-b112-00215aee3ebe;Бірки;Бирки;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155106;3;1
44ac8438-749b-11df-b112-00215aee3ebe;Бірки;Бирки;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723310101;3;1
44ac8439-749b-11df-b112-00215aee3ebe;Бірки;Бирки;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125887203;3;1
44ac843a-749b-11df-b112-00215aee3ebe;Бірки;Бирки;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324255300;3;1
44ac843b-749b-11df-b112-00215aee3ebe;Бірки;Бирки;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321780501;3;1
44ac843c-749b-11df-b112-00215aee3ebe;Бірки;Бирки;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624887402;3;1
44ac843d-749b-11df-b112-00215aee3ebe;Бірки;Бирки;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622755901;3;1
44ac843e-749b-11df-b112-00215aee3ebe;Бірки;Бирки;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625880801;3;0
44ac843f-749b-11df-b112-00215aee3ebe;Бірки;Бирки;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425587202;3;1
44ac8440-749b-11df-b112-00215aee3ebe;Бірки;Бирки;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422081201;3;1
44ac8441-749b-11df-b112-00215aee3ebe;Бірки;Бирки;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420687402;3;1
44ac8442-749b-11df-b112-00215aee3ebe;Бірки;Бирки;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520580301;3;1
44ac8443-749b-11df-b112-00215aee3ebe;Бірки;Бирки;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320487203;3;1
44ac8444-749b-11df-b112-00215aee3ebe;Бірків;Бирков;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522480801;3;1
f9333457-db4e-11df-9197-00215aee3ebe;Бірківка;Борковка;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423081501;3;1
44ac8445-749b-11df-b112-00215aee3ebe;Бірківське;Берковское;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824286703;3;1
44ac8446-749b-11df-b112-00215aee3ebe;Бір-Кунинський;Бор-Кунинский;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784602;3;1
44ac8447-749b-11df-b112-00215aee3ebe;Бірносове;Бирносово;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125283203;3;1
44ac8448-749b-11df-b112-00215aee3ebe;Бірок;Борок;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621685203;3;1
1fefcad0-45af-11e5-93c7-003048d2b473;Бірочок Другий;Бирочок Второй;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321785002;3;1
44ac8449-749b-11df-b112-00215aee3ebe;Бірче;Бирче;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620955301;3;1
44ac844a-749b-11df-b112-00215aee3ebe;Бірчиці;Бирчицы;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624283402;3;1
44ac844b-749b-11df-b112-00215aee3ebe;Бірюкове;Бирюково;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123585502;3;1
44ac844d-749b-11df-b112-00215aee3ebe;Бісквітне;Бисквитное;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325182503;3;1
44ac844e-749b-11df-b112-00215aee3ebe;Бісків;Бисков;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323585502;3;1
44ac844f-749b-11df-b112-00215aee3ebe;Бісковичі;Бисковичи;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624280601;3;1
44ac8450-749b-11df-b112-00215aee3ebe;Бітля;Битля;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625580301;3;1
44ac8452-749b-11df-b112-00215aee3ebe;Біще;Бище;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120480601;3;1
44ac8454-749b-11df-b112-00215aee3ebe;Благовіщенка;Благовещенка;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221487202;3;1
44ac845a-749b-11df-b112-00215aee3ebe;Благовіщенське;Благовещенское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321585002;3;1
8716220f-749b-11df-b112-00215aee3ebe;Благовіщенське;Благовещенское;місто;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525500000;2;1
44a99df3-572e-11e7-8102-1c98ec135263;Благодарівка (Комсомольське ;Благодаровка (Комсомольское);село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824282708;3;1
5cb619c6-749b-11df-b112-00215aee3ebe;Благодарівка (Комсомольське);Благораровка (Комсомольское);село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824281501;3;1
44ac845b-749b-11df-b112-00215aee3ebe;Благодатівка;Благодатовка;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520980501;3;1
44ac845c-749b-11df-b112-00215aee3ebe;Благодатівка;Благодатовка;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623384502;3;1
44ac845d-749b-11df-b112-00215aee3ebe;Благодатне;Благодатное;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222082003;3;1
44ac845e-749b-11df-b112-00215aee3ebe;Благодатне;Благодатное;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820380401;3;1
44ac845f-749b-11df-b112-00215aee3ebe;Благодатне;Благодатное;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825184803;3;1
44ac8460-749b-11df-b112-00215aee3ebe;Благодатне;Благодатное;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323582202;3;1
44ac8462-749b-11df-b112-00215aee3ebe;Благодатне;Благодатное;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321281001;3;1
44ac8463-749b-11df-b112-00215aee3ebe;Благодатне;Благодатное;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321755601;3;1
44ac8464-749b-11df-b112-00215aee3ebe;Благодатне;Благодатное;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323987003;3;1
44ac8465-749b-11df-b112-00215aee3ebe;Благодатне;Благодатное;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320485503;3;1
44ac8466-749b-11df-b112-00215aee3ebe;Благодатне;Благодатное;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120284803;3;1
44ac8467-749b-11df-b112-00215aee3ebe;Благодатне;Благодатное;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122783002;3;1
44ac8468-749b-11df-b112-00215aee3ebe;Благодатне;Благодатное;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120880303;3;1
44ac8469-749b-11df-b112-00215aee3ebe;Благодатне;Благодатное;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124583202;3;1
44ac846a-749b-11df-b112-00215aee3ebe;Благодатне;Благодатное;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124781402;3;1
44ac846b-749b-11df-b112-00215aee3ebe;Благодатне;Благодатное;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323681302;3;1
44ac846d-749b-11df-b112-00215aee3ebe;Благодатне;Благодатное;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521880802;3;1
44ac8474-749b-11df-b112-00215aee3ebe;Благодатне;Благодатное;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523980302;3;1
44ac8475-749b-11df-b112-00215aee3ebe;Благодатне;Благодатное;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3521982502;3;1
08a79c4f-f47b-11e8-80d9-1c98ec135261;Благодатне;Благодатне;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520384701;3;1
da41b73e-509b-11e8-80d0-1c98ec135261;Благодатне;Благодатное;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157301;3;1
201d5c72-222e-11e0-9ffb-00215aee3ebe;Благодатне;Благодатное;село;5394b340-41be-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0710745300;3;1
5cb61715-749b-11df-b112-00215aee3ebe;Благодатне;Благодатное;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123983001;3;1
8716259d-749b-11df-b112-00215aee3ebe;Благодатне;Благодатне;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225855101;3;1
871625aa-749b-11df-b112-00215aee3ebe;Благодатне;Благодатное;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121589401;3;1
44ac801a-749b-11df-b112-00215aee3ebe;Благодатне (Артемівка);Благодатное (Артемовка);село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157301;3;1
62c3cf22-749b-11df-b112-00215aee3ebe;Благодатне (Куйбишеве);Благодатное (Куйбышево);село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520884201;3;1
44ac8476-749b-11df-b112-00215aee3ebe;Благодатний, радгосп;Благодатный, радгосп;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321755601;3;1
44ac847b-749b-11df-b112-00215aee3ebe;Благословенна;Благословенная;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222083503;3;1
44ac847c-749b-11df-b112-00215aee3ebe;Блаженик;Блаженик;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725583102;3;1
44ac847d-749b-11df-b112-00215aee3ebe;Блажів;Блажив;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624289204;3;1
44ac847e-749b-11df-b112-00215aee3ebe;Блажіївка;Блажиевка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521481606;3;1
44ac847f-749b-11df-b112-00215aee3ebe;Блажове;Блажово;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625081301;3;1
44ac8480-749b-11df-b112-00215aee3ebe;Блакитне;Блакитное;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955403;3;1
44ac8481-749b-11df-b112-00215aee3ebe;Блакитне;Блакитное;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521855201;3;1
44ac8482-749b-11df-b112-00215aee3ebe;Блакитне;Блакитное;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522883302;3;1
44ac8483-749b-11df-b112-00215aee3ebe;Блешня;Блешня;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424784503;3;1
44ac8484-749b-11df-b112-00215aee3ebe;Блидні;Блыдни;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823683002;3;1
44ac8489-749b-11df-b112-00215aee3ebe;Ближнє;Ближнее;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421484802;3;1
44ac848b-749b-11df-b112-00215aee3ebe;Близнюки;Близнюки;місто;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320655100;3;1
44ac848c-749b-11df-b112-00215aee3ebe;Блиставиця;Блиставица;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221080501;2;1
44ac848d-749b-11df-b112-00215aee3ebe;Блистова;Блистова;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423081001;3;1
44ac848e-749b-11df-b112-00215aee3ebe;Блистова;Блистова;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423681201;3;1
44ac848f-749b-11df-b112-00215aee3ebe;Блищадь;Блыщадь;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325088002;3;1
44ac8490-749b-11df-b112-00215aee3ebe;Блищанівка;Блищановка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821886202;3;1
44ac8491-749b-11df-b112-00215aee3ebe;Блищанка;Блищанка;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122080701;3;1
44ac8492-749b-11df-b112-00215aee3ebe;Блищиводи;Блищиводы;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787203;3;1
44ac8493-749b-11df-b112-00215aee3ebe;Блідча;Блидча;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222080301;3;1
44ac8494-749b-11df-b112-00215aee3ebe;Бліх;Блих;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122685702;3;1
44ac8495-749b-11df-b112-00215aee3ebe;Блонське;Блонское;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121880702;3;1
44ac8496-749b-11df-b112-00215aee3ebe;Блощинці;Блощинцы;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220484602;2;1
44ac8497-749b-11df-b112-00215aee3ebe;Блудше;Блудшее;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422089102;3;1
44ac8498-749b-11df-b112-00215aee3ebe;Блюдники;Блюдники;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621280301;3;1
44ac84c3-749b-11df-b112-00215aee3ebe;Боб'ятин;Бобятин;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624880201;3;1
44ac8499-749b-11df-b112-00215aee3ebe;Боберка;Боберка;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625580601;3;1
44ac849a-749b-11df-b112-00215aee3ebe;Бобилівка;Бобылевка;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921588603;3;1
44ac849b-749b-11df-b112-00215aee3ebe;Бобине;Бобино;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923880401;3;1
44ac849c-749b-11df-b112-00215aee3ebe;Бобичі;Бобичи;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720580805;3;1
44ac849d-749b-11df-b112-00215aee3ebe;Бобівці;Бобовцы;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324581001;3;1
44ac849e-749b-11df-b112-00215aee3ebe;Бобли;Боблы;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725580401;3;1
44ac849f-749b-11df-b112-00215aee3ebe;Боблів;Боблов;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523080401;3;1
44ac84a1-749b-11df-b112-00215aee3ebe;Бобове;Бобовое;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124487602;3;1
44ac84a2-749b-11df-b112-00215aee3ebe;Бобовище;Бобовище;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122780401;2;1
44ac84a3-749b-11df-b112-00215aee3ebe;Бобрик;Бобрик;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920681001;3;1
44ac84a4-749b-11df-b112-00215aee3ebe;Бобрик;Бобрик;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924182301;3;1
44ac84a5-749b-11df-b112-00215aee3ebe;Бобрик;Бобрик;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822380402;3;1
44ac84a6-749b-11df-b112-00215aee3ebe;Бобрик;Бобрик;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221280401;3;1
44ac84a7-749b-11df-b112-00215aee3ebe;Бобрик;Бобрик;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510101;3;1
44ac84a8-749b-11df-b112-00215aee3ebe;Бобрик;Бобрик;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422484502;3;1
44ac84a9-749b-11df-b112-00215aee3ebe;Бобрик;Бобрик;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423381902;3;1
44ac84aa-749b-11df-b112-00215aee3ebe;Бобрик;Бобрик;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320481101;3;1
44ac84ab-749b-11df-b112-00215aee3ebe;Бобрик Другий;Бобрик Второй;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123380603;3;1
44ac84ac-749b-11df-b112-00215aee3ebe;Бобрик Другій;Бобрик Второй;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424787502;3;1
44ac84ad-749b-11df-b112-00215aee3ebe;Бобрик Перший;Бобрик Первый;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123380601;3;1
44ac84b0-749b-11df-b112-00215aee3ebe;Бобринець;Бобринец;місто;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520810100;2;0
44ac84b1-749b-11df-b112-00215aee3ebe;Бобринка;Бобринка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520880801;3;1
44ac84b2-749b-11df-b112-00215aee3ebe;Бобриця;Бобрица;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222480601;3;1
44ac84b3-749b-11df-b112-00215aee3ebe;Бобриця;Бобрица;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221482002;3;1
44ac84b4-749b-11df-b112-00215aee3ebe;Бобриця;Бобрица;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821781001;3;1
44ac84b5-749b-11df-b112-00215aee3ebe;Бобриця;Бобрица;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122081101;3;1
44ac84b6-749b-11df-b112-00215aee3ebe;Бобрицька Болярка;Бобрицкая Болярка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821780302;3;1
44ac84b7-749b-11df-b112-00215aee3ebe;Бобрицьке;Бобрицкое;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123383202;3;1
44ac84b8-749b-11df-b112-00215aee3ebe;Бобричі;Бобричи;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822887603;3;1
44ac84b9-749b-11df-b112-00215aee3ebe;Бобрівка;Бобровка;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157303;3;1
44ac84ba-749b-11df-b112-00215aee3ebe;Бобрівник;Бобровник;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321386402;3;1
44ac84bb-749b-11df-b112-00215aee3ebe;Бобрівники;Бобровники;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124289202;3;1
44ac84bc-749b-11df-b112-00215aee3ebe;Боброве;Боброво;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922984202;3;1
44ac84bd-749b-11df-b112-00215aee3ebe;Бобровий Кут;Бобровый Кут;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520980701;3;1
44ac84be-749b-11df-b112-00215aee3ebe;Бобровиця;Бобровица;місто;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420610100;2;1
44ac84bf-749b-11df-b112-00215aee3ebe;Боброїди;Боброеды;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622780802;3;1
44ac84c1-749b-11df-b112-00215aee3ebe;Бобруйки;Бобруйки;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422081001;3;1
44ac84c2-749b-11df-b112-00215aee3ebe;Бобулинці;Бобулинцы;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121280901;3;1
44ac84c4-749b-11df-b112-00215aee3ebe;Бовбасівка;Бовбасовка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324886203;3;1
44ac84c5-749b-11df-b112-00215aee3ebe;Бовдури;Бовдуры;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620388403;3;1
44ac84c6-749b-11df-b112-00215aee3ebe;Бовкун;Бовкун;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224487202;3;1
44ac84c7-749b-11df-b112-00215aee3ebe;Бовкуни;Бовкуны;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824286703;3;1
44ac84c8-749b-11df-b112-00215aee3ebe;Бовсуни;Бовсуны;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822880401;3;1
44ac84c9-749b-11df-b112-00215aee3ebe;Бовтишка;Бовтышка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520580501;3;1
44ac84ca-749b-11df-b112-00215aee3ebe;Бовшів;Бовшев;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621280601;3;1
44ac84cb-749b-11df-b112-00215aee3ebe;Богаревиця;Богаревица;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121984802;3;1
44ac84cf-749b-11df-b112-00215aee3ebe;Богатир;Богатырь;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523483002;3;1
44ac84d0-749b-11df-b112-00215aee3ebe;Богатирівка;Богатыревка;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123780802;3;1
44ac84d1-749b-11df-b112-00215aee3ebe;Богатирівка;Богатыревка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582703;3;0
44ac808f-749b-11df-b112-00215aee3ebe;Богатирка;Богатырка;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224283202;3;1
44ac84d2-749b-11df-b112-00215aee3ebe;Богдалівка;Богдалевка;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422487502;3;1
44ac84d3-749b-11df-b112-00215aee3ebe;Богдан;Богдан;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123681301;3;1
44ac84d4-749b-11df-b112-00215aee3ebe;Богдани;Богданы;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221880801;3;1
44ac84d5-749b-11df-b112-00215aee3ebe;Богдани;Богданы;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421180601;3;1
44ac84d6-749b-11df-b112-00215aee3ebe;Богдани;Богданы;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121580801;3;1
44ac84d7-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324580601;3;1
44ac84d8-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321880703;3;1
44ac84d9-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323480301;3;1
44ac84da-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323082202;3;1
44ac84db-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925380401;3;1
44ac84dc-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122681001;3;1
44ac84dd-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124680401;3;1
44ac84de-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223581301;3;1
44ac84df-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755112;3;1
44ac84e0-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222685506;3;1
44ac84e1-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822784004;3;1
44ac84e2-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820981202;3;1
44ac84e3-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684603;3;1
44ac84e4-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123583902;3;1
44ac84e5-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124789002;3;1
44ac84e6-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321587002;3;1
44ac84e7-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323686203;3;1
44ac84ed-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620682002;3;1
44ac84f3-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221280801;3;1
44ac84f4-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225580901;3;0
44ac84f6-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824281302;3;1
44ac84f7-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822080302;3;1
44ac84f8-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423387402;3;1
44ac84f9-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;7424180901;3;1
44ac84fa-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522280303;3;1
44ac84fb-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523180402;3;1
44ac84fc-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524382201;3;1
44ac84fd-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521786003;3;1
44ac84fe-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522280401;3;1
44ac84ff-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524980801;3;1
44ac8500-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521980701;3;1
44ac8501-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820980601;3;1
44ac8502-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623080402;3;1
44ac8503-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325155101;3;1
44ac8504-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123482302;3;1
17af7b45-ddb9-11df-9197-00215aee3ebe;Богданівка;Богдановка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820884502;3;1
62c3d165-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120685800;3;1
62c3d152-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225087302;3;1
87162339-749b-11df-b112-00215aee3ebe;Богданівка;Богдановка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520810104;3;1
44ac8505-749b-11df-b112-00215aee3ebe;Богданівське;Богдановское;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822781502;3;1
44ac8506-749b-11df-b112-00215aee3ebe;Богданівське;Богдановское;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121884502;3;1
44ac8507-749b-11df-b112-00215aee3ebe;Богданівці;Богданивци;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825083302;3;1
44ac8508-749b-11df-b112-00215aee3ebe;Богданівці;Богданивци;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821580401;3;1
44ac8509-749b-11df-b112-00215aee3ebe;Богданка;Богданка;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925383503;3;1
44ac850e-749b-11df-b112-00215aee3ebe;Богдано - Вербки;Богдано-Вербки;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223881002;3;1
44ac850a-749b-11df-b112-00215aee3ebe;Богданове;Богданово;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320481503;3;1
44ac850b-749b-11df-b112-00215aee3ebe;Богданове;Богданово;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423683202;3;1
44ac850c-749b-11df-b112-00215aee3ebe;Богданове;Богданово;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525580401;3;1
44ac850d-749b-11df-b112-00215aee3ebe;Богданове Перше;Богданово Первое;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125280903;3;1
44ac850f-749b-11df-b112-00215aee3ebe;Богдано-Надеждівка;Богдано-Надеждовка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224581001;3;1
44ac8510-749b-11df-b112-00215aee3ebe;Богдашів;Богдашев;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622680601;3;1
44ac8511-749b-11df-b112-00215aee3ebe;Богемка;Богемка;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822381302;3;1
dd574d29-e267-11e8-80d7-1c98ec135261;Богинівка;Богиновка;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223881001;3;1
44ac8512-749b-11df-b112-00215aee3ebe;Богнатове;Богнатово;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123981702;3;1
44ac8513-749b-11df-b112-00215aee3ebe;Богодарівка;Богодаровка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224280503;3;1
44ac8514-749b-11df-b112-00215aee3ebe;Богодарівка;Богодаровка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823082402;3;1
44ac8515-749b-11df-b112-00215aee3ebe;Богодарівка;Богодаровка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325785902;3;1
44ac8516-749b-11df-b112-00215aee3ebe;Богодарівка;Богодаровка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521780302;3;1
44ac8517-749b-11df-b112-00215aee3ebe;Богодарівка;Богодаровка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522885803;3;1
44ac8518-749b-11df-b112-00215aee3ebe;Богодарівка;Богодаровка;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325180501;3;1
44ac8519-749b-11df-b112-00215aee3ebe;Богодарівка;Богодаровка;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322885702;3;1
44ac851a-749b-11df-b112-00215aee3ebe;Богодарове;Богодарово;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320480501;3;1
44ac851b-749b-11df-b112-00215aee3ebe;Богодухів;Богодухов;місто;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320810100;2;0
44ac851c-749b-11df-b112-00215aee3ebe;Богодухівка;Богодуховка;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125180401;3;1
44ac851d-749b-11df-b112-00215aee3ebe;Боголюби;Боголюбы;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881806;3;1
44ac851e-749b-11df-b112-00215aee3ebe;Богомази;Богомазы;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824582602;3;1
44ac851f-749b-11df-b112-00215aee3ebe;Богомолівка;Богомоловка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323986002;3;1
44ac8520-749b-11df-b112-00215aee3ebe;Богородицьке;Богородицкое;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825782903;3;1
44ac8522-749b-11df-b112-00215aee3ebe;Богородичин;Богородичин;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623282202;3;1
44ac8524-749b-11df-b112-00215aee3ebe;Богородчани;Богородчаны;місто;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2620455100;2;0
44ac8525-749b-11df-b112-00215aee3ebe;Богословка;Богословка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825486703;3;1
44ac8526-749b-11df-b112-00215aee3ebe;Богрівка;Богровка;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620480601;3;1
44ac8527-749b-11df-b112-00215aee3ebe;Богунівка;Богуновка;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720885402;3;1
44ac8528-749b-11df-b112-00215aee3ebe;Богунівка;Богуновка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323683502;3;1
44ac8529-749b-11df-b112-00215aee3ebe;Богунове;Богуново;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121882002;3;1
44ac852a-749b-11df-b112-00215aee3ebe;Богунове;Богуново;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123786002;3;1
44ac852b-749b-11df-b112-00215aee3ebe;Богуслав;Богуслав;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223581801;3;1
44ac852c-749b-11df-b112-00215aee3ebe;Богуслав;Богуслав;місто;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220610100;2;0
44ac852d-749b-11df-b112-00215aee3ebe;Богуславець;Богуславец;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121581101;3;1
44ac852e-749b-11df-b112-00215aee3ebe;Богуславка;Богуславка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224887102;3;1
44ac852f-749b-11df-b112-00215aee3ebe;Богуславка;Богуславка;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321080501;3;1
44ac8530-749b-11df-b112-00215aee3ebe;Богутин;Богутин;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621855601;3;1
44ac8531-749b-11df-b112-00215aee3ebe;Богуші;Богуши;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620488602;3;1
44ac8532-749b-11df-b112-00215aee3ebe;Богушівка;Богушовка;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881807;3;1
44ac8533-749b-11df-b112-00215aee3ebe;Богущівка;Богущевка;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623887303;3;1
44ac8534-749b-11df-b112-00215aee3ebe;Бодаква;Бодаква;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322681101;3;1
44ac8535-749b-11df-b112-00215aee3ebe;Бодаки;Бодаки;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122480701;3;1
44ac8536-749b-11df-b112-00215aee3ebe;Боденьки;Боденьки;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221888002;3;1
44ac8537-749b-11df-b112-00215aee3ebe;Боднарів;Боднаров;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622880101;3;1
44ac8538-749b-11df-b112-00215aee3ebe;Боднарів;Боднарев;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623286803;3;1
44ac8539-749b-11df-b112-00215aee3ebe;Боднарівка;Боднаровка;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121687602;3;1
44ac853a-749b-11df-b112-00215aee3ebe;Боднарівка;Боднаровка;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825282302;2;1
44ac853b-749b-11df-b112-00215aee3ebe;Боднарівка;Боднаровка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825881401;3;1
44ac853c-749b-11df-b112-00215aee3ebe;Бодячів;Бодячев;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721886603;3;1
44ac853d-749b-11df-b112-00215aee3ebe;Бодячів;Бодячев;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624886902;3;1
44ac853e-749b-11df-b112-00215aee3ebe;Боєвичі;Боевичи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622481602;3;1
44ac853f-749b-11df-b112-00215aee3ebe;Божа Воля;Божья Воля;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625887805;3;1
44ac8540-749b-11df-b112-00215aee3ebe;Божедарівка;Божедаровка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222082503;3;1
44ac8541-749b-11df-b112-00215aee3ebe;Божиків;Божиков;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120486701;3;1
44ac8542-749b-11df-b112-00215aee3ebe;Божиківці;Божикивци;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821580801;3;1
44ac8543-749b-11df-b112-00215aee3ebe;Божки;Божки;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086502;3;1
44ac8544-749b-11df-b112-00215aee3ebe;Божки;Божки;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225587202;3;1
c8674a9d-dbb0-11df-9197-00215aee3ebe;Божківське;Божковское;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083502;3;1
44ac8545-749b-11df-b112-00215aee3ebe;Божкове;Божково;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086503;3;1
871626bb-749b-11df-b112-00215aee3ebe;Божок;Божок;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922688201;3;1
44ac8546-749b-11df-b112-00215aee3ebe;Бойки;Бойки;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922987103;3;1
44ac8547-749b-11df-b112-00215aee3ebe;Бойки;Бойки;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624486002;3;1
44ac8548-749b-11df-b112-00215aee3ebe;Бойки;Бойки;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620484802;3;1
44ac8549-749b-11df-b112-00215aee3ebe;Бойківка;Бойковка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;;3;1
44ac854b-749b-11df-b112-00215aee3ebe;Бойківщина;Бойковщина;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120681601;3;1
44ac854c-749b-11df-b112-00215aee3ebe;Бойкове;Бойково;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187102;3;1
44ac854d-749b-11df-b112-00215aee3ebe;Бойкове;Бойково;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323685202;3;1
44ac854e-749b-11df-b112-00215aee3ebe;Бойні;Бойни;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321055101;3;1
44ac8552-749b-11df-b112-00215aee3ebe;Бокиївка;Бокиевка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820980901;3;1
44ac8553-749b-11df-b112-00215aee3ebe;Боків;Боков;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
44ac8554-749b-11df-b112-00215aee3ebe;Бокійма;Бокийма;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623880501;3;1
44ac8555-749b-11df-b112-00215aee3ebe;Бокове;Боково;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123380901;3;1
5cb61728-749b-11df-b112-00215aee3ebe;Бокове (Кірове);Боковое (Кирово);село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3521984401;3;1
44ac8559-749b-11df-b112-00215aee3ebe;Бокшин;Бокшин;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623086902;3;1
44ac855a-749b-11df-b112-00215aee3ebe;Болган;Болган;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523280301;3;1
44ac855b-749b-11df-b112-00215aee3ebe;Болгарка;Болгарка;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822384502;3;1
44ac855c-749b-11df-b112-00215aee3ebe;Болгарка;Болгарка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123981402;3;1
44ac855d-749b-11df-b112-00215aee3ebe;Болград;Болград;місто;6b07f8a2-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5121410100;2;0
44ac855f-749b-11df-b112-00215aee3ebe;Болеславчик;Болеславчик;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825480401;3;1
44ac8560-749b-11df-b112-00215aee3ebe;Болехів;Болехов;місто;8067acf2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2610200000;3;1
44ac8561-749b-11df-b112-00215aee3ebe;Болехівці;Болеховцы;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621280301;3;1
44ac8562-749b-11df-b112-00215aee3ebe;Болибоки;Болибоки;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322010102;3;1
44ac8564-749b-11df-b112-00215aee3ebe;Боложинів;Боложинов;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620681201;3;1
44ac8565-749b-11df-b112-00215aee3ebe;Боложівка;Боложовка;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125880601;3;1
44ac8566-749b-11df-b112-00215aee3ebe;Болозів;Болозев;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625180401;3;1
44ac8567-749b-11df-b112-00215aee3ebe;Болоня;Болоня;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623080802;3;1
7ad94f90-749b-11df-b112-00215aee3ebe;Болотишине (Радянське);Болотишино (Радянское);село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920685303;3;1
44ac8569-749b-11df-b112-00215aee3ebe;Болотівка;Болотовка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920980802;3;1
44ac856a-749b-11df-b112-00215aee3ebe;Болотківці;Болотковцы;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624283603;3;1
44ac856c-749b-11df-b112-00215aee3ebe;Болотниця;Болотница;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755112;3;1
44ac856d-749b-11df-b112-00215aee3ebe;Болотниця;Болотница;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425381001;3;1
44ac856e-749b-11df-b112-00215aee3ebe;Болотня;Болотня;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623380701;3;1
44ac856f-749b-11df-b112-00215aee3ebe;Болотня;Болотня;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222055101;3;1
44ac8570-749b-11df-b112-00215aee3ebe;Болохів;Болохов;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622880802;3;1
44ac8571-749b-11df-b112-00215aee3ebe;Болтишка;Болтышка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055709;3;1
44ac8572-749b-11df-b112-00215aee3ebe;Болюхівка;Болюховка;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
44ac8573-749b-11df-b112-00215aee3ebe;Болязуби;Болязубы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122484802;3;1
44ac8574-749b-11df-b112-00215aee3ebe;Боляновичі;Боляновичи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622483202;3;1
44ac8575-749b-11df-b112-00215aee3ebe;Болярка;Болярка;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824484002;3;1
44ac8576-749b-11df-b112-00215aee3ebe;Болярка;Болярка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821782005;3;1
44ac8577-749b-11df-b112-00215aee3ebe;Болярка;Болярка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822385602;3;1
44ac8578-749b-11df-b112-00215aee3ebe;Болярка;Болярка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824081702;3;1
44ac8579-749b-11df-b112-00215aee3ebe;Болярка;Болярка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822080902;3;1
44ac857a-749b-11df-b112-00215aee3ebe;Болячів;Болячев;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820980303;3;1
44ac857b-749b-11df-b112-00215aee3ebe;Бомкове;Бомково;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821583602;3;1
44ac857c-749b-11df-b112-00215aee3ebe;Бондареве;Бондарево;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755101;3;1
ceb83070-7668-11e0-be68-003048d2b472;Бондареве;Бондарево;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1220755101;3;1
44ac8582-749b-11df-b112-00215aee3ebe;Бондарі;Бондари;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322480701;3;1
44ac8583-749b-11df-b112-00215aee3ebe;Бондарі;Бондари;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922087603;3;1
44ac8584-749b-11df-b112-00215aee3ebe;Бондарі;Бондари;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920984602;3;1
44ac8585-749b-11df-b112-00215aee3ebe;Бондарі;Бондари;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120284805;3;1
44ac8586-749b-11df-b112-00215aee3ebe;Бондарі;Бондари;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824280601;3;1
44ac8587-749b-11df-b112-00215aee3ebe;Бондарі;Бондари;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420386002;3;1
44ac8588-749b-11df-b112-00215aee3ebe;Бондарі;Бондари;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621688203;3;1
44ac8589-749b-11df-b112-00215aee3ebe;Бондарі;Бондари;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325185403;3;1
44ac858a-749b-11df-b112-00215aee3ebe;Бондарівка;Бондаревка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821755104;3;1
44ac858b-749b-11df-b112-00215aee3ebe;Бондарівка;Бондаревка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324884002;3;1
44ac858e-749b-11df-b112-00215aee3ebe;Бондарівка;Бондаревка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824280602;3;1
44ac858f-749b-11df-b112-00215aee3ebe;Бондарівка;Бондаревка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386011;3;1
44ac8590-749b-11df-b112-00215aee3ebe;Бондарівка;Бондаревка;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424981002;3;1
e3db5ae4-ddbe-11e8-80d7-1c98ec135261;Бондарівка;Бондарівка;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323281203;3;1
44ac8591-749b-11df-b112-00215aee3ebe;Бондарівщина;Бондаревщина;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924789802;3;1
44ac8594-749b-11df-b112-00215aee3ebe;Бондарня;Бондарня;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221082002;3;1
44ac8595-749b-11df-b112-00215aee3ebe;Бондарці;Бондарцы;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822083003;3;1
44ac8596-749b-11df-b112-00215aee3ebe;Бондури;Бондуры;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323484603;3;1
44ac8597-749b-11df-b112-00215aee3ebe;Бондурі;Бондури;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520880303;3;1
44ac8598-749b-11df-b112-00215aee3ebe;Бондурівка;Бондуровка;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523080601;3;1
44ac8599-749b-11df-b112-00215aee3ebe;Бондурівка;Бондуровка;село;78f453a8-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0525081301;3;1
44ac859a-749b-11df-b112-00215aee3ebe;Боневичі;Боневичи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625183902;3;1
44ac859b-749b-11df-b112-00215aee3ebe;Бонишин;Бонишин;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621888502;3;1
44ac859c-749b-11df-b112-00215aee3ebe;Бонівка;Боновка;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123489002;3;1
44ac859d-749b-11df-b112-00215aee3ebe;Боратин;Боратин;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722880701;3;1
44ac859e-749b-11df-b112-00215aee3ebe;Боратин;Боратин;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620385303;3;1
44ac859f-749b-11df-b112-00215aee3ebe;Боратин;Боратин;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625881201;3;1
44ac85a0-749b-11df-b112-00215aee3ebe;Боратичі;Боратычи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622489003;3;1
44ac85a1-749b-11df-b112-00215aee3ebe;Борбин;Борбин;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623889202;3;1
44ac85a2-749b-11df-b112-00215aee3ebe;Борбухи;Борбухи;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825881403;3;1
44ac85a3-749b-11df-b112-00215aee3ebe;Бордуляки;Бордуляки;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620386602;3;1
44ac85a5-749b-11df-b112-00215aee3ebe;Боремель;Боремель;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621480703;3;1
44ac85a4-749b-11df-b112-00215aee3ebe;Боремець;Боремец;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623889802;3;1
44ac85a6-749b-11df-b112-00215aee3ebe;Боремщина;Боремщина;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723310102;3;1
44ac85a7-749b-11df-b112-00215aee3ebe;Боржава;Боржава;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120481701;3;1
44ac85a8-749b-11df-b112-00215aee3ebe;Боржавське;Боржавское;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121280301;3;1
44ac85aa-749b-11df-b112-00215aee3ebe;Борзна;Борзна;місто;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420810100;2;1
44ac85ab-749b-11df-b112-00215aee3ebe;Борзова;Борзовая;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725084602;3;1
44ac85ac-749b-11df-b112-00215aee3ebe;Бориничі;Бориничи;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621589102;3;1
44ac85ad-749b-11df-b112-00215aee3ebe;Бориня;Бориня;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625555300;3;1
44ac85ae-749b-11df-b112-00215aee3ebe;Бориси;Борисы;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610110;3;1
44ac85af-749b-11df-b112-00215aee3ebe;Бориси;Борисы;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625884502;3;1
44ac85b0-749b-11df-b112-00215aee3ebe;Борисів;Борисов;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221484002;3;1
44ac85b1-749b-11df-b112-00215aee3ebe;Борисів;Борисов;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822181301;3;1
44ac85b2-749b-11df-b112-00215aee3ebe;Борисів;Борисов;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;;3;1
44ac85b3-749b-11df-b112-00215aee3ebe;Борисівка;Борисовка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321080402;3;1
44ac85b4-749b-11df-b112-00215aee3ebe;Борисівка;Борисовка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222981002;3;1
44ac85b5-749b-11df-b112-00215aee3ebe;Борисівка;Борисовка;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325182303;3;1
44ac85b6-749b-11df-b112-00215aee3ebe;Борисівка;Борисовка;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125080801;2;1
44ac85ba-749b-11df-b112-00215aee3ebe;Борисівка;Борисовка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824081202;3;1
44ac85bb-749b-11df-b112-00215aee3ebe;Борисівка;Борисовка;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521210103;3;1
44ac85bc-749b-11df-b112-00215aee3ebe;Борисівка;Борисовка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520887002;3;1
44ac85bd-749b-11df-b112-00215aee3ebe;Борисковичі;Борисковичи;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720880402;3;1
44ac85be-749b-11df-b112-00215aee3ebe;Борислав;Борислав;місто;8067ad5d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4610300000;2;0
44ac85bf-749b-11df-b112-00215aee3ebe;Борисове;Борисово;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422282003;3;1
44ac85c0-749b-11df-b112-00215aee3ebe;Бориспіль;Борисполь;місто;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3210500000;1;0
44ac85c1-749b-11df-b112-00215aee3ebe;Боричівка;Боричевка;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125010101;3;1
44ac85c2-749b-11df-b112-00215aee3ebe;Боришківці;Борышковцы;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120881803;3;1
44ac85c3-749b-11df-b112-00215aee3ebe;Боришківці;Борышковцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822487402;3;1
44ac85c4-749b-11df-b112-00215aee3ebe;Борівка;Боровка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222780401;3;1
44ac85c5-749b-11df-b112-00215aee3ebe;Борівка;Боровка;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524981501;3;1
44ac85c6-749b-11df-b112-00215aee3ebe;Борівська Андріївка;Боровская Андреевка;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321082502;3;1
44ac85c7-749b-11df-b112-00215aee3ebe;Борівське;Боровское;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325782501;3;1
44ac85c9-749b-11df-b112-00215aee3ebe;Борівське;Боровское;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525380502;3;1
44ac85ca-749b-11df-b112-00215aee3ebe;Борівське лісництво;Боровское Лисничество;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322283002;3;1
44ac85cb-749b-11df-b112-00215aee3ebe;Борівці;Боровцы;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322581001;3;1
44ac85cc-749b-11df-b112-00215aee3ebe;Борківка;Бобровка;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423081501;3;1
44ac85cd-749b-11df-b112-00215aee3ebe;Борова;Боровая;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321781001;3;1
44ac85ce-749b-11df-b112-00215aee3ebe;Борова;Боровая;місто;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321055100;3;1
44ac85d0-749b-11df-b112-00215aee3ebe;Борова;Боровая;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224955300;3;0
44ac85d1-749b-11df-b112-00215aee3ebe;Борове;Боровое;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723384902;3;1
44ac85d2-749b-11df-b112-00215aee3ebe;Борове;Боровое;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325183002;3;1
44ac85d3-749b-11df-b112-00215aee3ebe;Борове;Боровое;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624810601;3;1
44ac85d4-749b-11df-b112-00215aee3ebe;Борове;Боровое;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787602;3;1
44ac85d6-749b-11df-b112-00215aee3ebe;Борове;Боровое;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622280401;3;1
44ac85d7-749b-11df-b112-00215aee3ebe;Борове;Боровое;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625081701;3;1
44ac85cf-749b-11df-b112-00215aee3ebe;Борове;Боровая;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787602;3;1
44ac85d8-749b-11df-b112-00215aee3ebe;Боровенька;Боровенька;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922980801;3;1
44ac85da-749b-11df-b112-00215aee3ebe;Боровики;Боровики;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425580801;3;1
44ac85db-749b-11df-b112-00215aee3ebe;Боровикове;Боровиково;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121280801;3;1
44ac85dc-749b-11df-b112-00215aee3ebe;Боровиця;Боровица;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822381002;3;1
44ac85dd-749b-11df-b112-00215aee3ebe;Боровиця;Боровица;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125480501;3;1
44ac85de-749b-11df-b112-00215aee3ebe;Боровичі;Боровичи;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924483202;3;1
44ac85df-749b-11df-b112-00215aee3ebe;Боровичі;Боровичи;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723655401;3;1
44ac85e0-749b-11df-b112-00215aee3ebe;Боровківка;Боровковка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221081801;3;1
44ac85e1-749b-11df-b112-00215aee3ebe;Боровне;Боровно;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721480301;3;1
44ac85e2-749b-11df-b112-00215aee3ebe;Боровуха;Боровуха;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724286903;3;1
44ac85e3-749b-11df-b112-00215aee3ebe;Бородаївка;Бородаевка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221082201;3;1
44ac85e4-749b-11df-b112-00215aee3ebe;Бородаївські Хутори;Бородаевские Хутора;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221085502;3;1
44ac85e5-749b-11df-b112-00215aee3ebe;Бородані;Бороданы;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220681402;3;1
44ac85e6-749b-11df-b112-00215aee3ebe;Бороданове;Бороданово;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923583402;3;1
44ac85e7-749b-11df-b112-00215aee3ebe;Бородіно;Бородино;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124755500;3;1
44ac85e9-749b-11df-b112-00215aee3ebe;Бородоярське;Бородоярское;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320284402;3;1
44ac85ea-749b-11df-b112-00215aee3ebe;Бородчиці;Бородчицы;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621582702;3;1
44ac85eb-749b-11df-b112-00215aee3ebe;Бородянка;Бородянка;місто;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221055100;2;0
44ac85ec-749b-11df-b112-00215aee3ebe;Борозенка;Борозенка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187302;3;1
44ac85ed-749b-11df-b112-00215aee3ebe;Борозенське;Борозенское;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520981001;3;1
44ac85ee-749b-11df-b112-00215aee3ebe;Борок;Борок;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820384802;3;1
44ac85ef-749b-11df-b112-00215aee3ebe;Борок;Борок;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422484503;3;1
44ac84c0-749b-11df-b112-00215aee3ebe;Борок;Борок;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510105;3;1
44ac85f0-749b-11df-b112-00215aee3ebe;Боромики;Боромыки;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425581001;3;1
44ac85f1-749b-11df-b112-00215aee3ebe;Боромля;Боромля;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925080801;3;1
44ac85f2-749b-11df-b112-00215aee3ebe;Бороняво;Бороняво;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125381001;3;1
44ac85f3-749b-11df-b112-00215aee3ebe;Боротьба;Боротьба;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424180303;3;1
44ac85f4-749b-11df-b112-00215aee3ebe;Борохи;Борохи;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322684502;3;1
44ac85f5-749b-11df-b112-00215aee3ebe;Борохів;Борохов;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721880801;3;1
44ac85f6-749b-11df-b112-00215aee3ebe;Борочиче;Борочиче;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720888602;3;1
44ac85f7-749b-11df-b112-00215aee3ebe;Борсків;Борсков;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524581803;3;1
44ac85f8-749b-11df-b112-00215aee3ebe;Борсуки;Барсуки;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123880601;3;1
44ac85f9-749b-11df-b112-00215aee3ebe;Борсуки;Барсуки;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290006;3;1
44ac85fa-749b-11df-b112-00215aee3ebe;Борсуки;Барсуки;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520883809;3;1
44ac85fb-749b-11df-b112-00215aee3ebe;Борсуки;Барсуки;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823380501;3;1
44ac85fc-749b-11df-b112-00215aee3ebe;Борсуків;Борсуков;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422086802;3;1
44ac85fd-749b-11df-b112-00215aee3ebe;Бортків;Бортков;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621881201;3;1
44ac85fe-749b-11df-b112-00215aee3ebe;Бортники;Бортники;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625610101;3;1
44ac85ff-749b-11df-b112-00215aee3ebe;Бортники;Бортники;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621581201;3;1
44ac8600-749b-11df-b112-00215aee3ebe;Бортники;Бортники;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224980401;3;1
44ac8601-749b-11df-b112-00215aee3ebe;Бортники;Бортники;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524380601;3;1
44ac8602-749b-11df-b112-00215aee3ebe;Бортниця;Бортница;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621682002;3;1
44ac8603-749b-11df-b112-00215aee3ebe;Бортничі;Бортничи;село;e672324c-4237-11e3-9df6-003048d2b473;d15e3021-60b0-11de-be1e-0030485903e8;15cb6202-1830-11eb-80cd-000c29800ae7;;3;0
44ac8604-749b-11df-b112-00215aee3ebe;Бортнів;Бортнов;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721180402;3;1
44ac8605-749b-11df-b112-00215aee3ebe;Бортятин;Бортятин;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622482402;3;1
44ac8606-749b-11df-b112-00215aee3ebe;Бортяхівка;Бортяховка;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724587003;3;1
44ac8607-749b-11df-b112-00215aee3ebe;Борусів;Борусов;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621581803;3;1
44ac8608-749b-11df-b112-00215aee3ebe;Борутине;Борутино;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;;3;1
44ac8609-749b-11df-b112-00215aee3ebe;Борушківці;Борушковцы;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823186402;3;1
44ac860a-749b-11df-b112-00215aee3ebe;Боршевичі;Боршевичи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625180501;3;1
44ac860b-749b-11df-b112-00215aee3ebe;Боршна;Боршна;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424181602;3;1
44ac860c-749b-11df-b112-00215aee3ebe;Борщагівка;Борщаговка;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523481201;3;1
44ac860d-749b-11df-b112-00215aee3ebe;Борщі;Борщи;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122980801;3;1
44ac860e-749b-11df-b112-00215aee3ebe;Борщі;Борщи;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520285606;3;1
44ac860f-749b-11df-b112-00215aee3ebe;Борщі вокзал;Борщи вокзал;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122980802;3;1
44ac8610-749b-11df-b112-00215aee3ebe;Борщів;Борщев;місто;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120810100;2;0
44ac8611-749b-11df-b112-00215aee3ebe;Борщів;Борщев;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625280601;3;1
44ac8612-749b-11df-b112-00215aee3ebe;Борщів;Борщев;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623381001;3;1
44ac8613-749b-11df-b112-00215aee3ebe;Борщів;Борщев;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220281703;3;1
44ac8614-749b-11df-b112-00215aee3ebe;Борщів;Борщев;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825080601;3;1
44ac8615-749b-11df-b112-00215aee3ebe;Борщівка;Борщовка;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155308;3;1
44ac8616-749b-11df-b112-00215aee3ebe;Борщівка;Борщовка;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123484702;3;1
44ac8617-749b-11df-b112-00215aee3ebe;Борщівка;Борщовка;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123881501;3;1
44ac8618-749b-11df-b112-00215aee3ebe;Борщівка;Борщовка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320280801;3;1
44ac8619-749b-11df-b112-00215aee3ebe;Борщівка;Борщовка;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824780301;3;1
44ac861a-749b-11df-b112-00215aee3ebe;Борщівка;Борщовка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821287102;3;1
44ac861b-749b-11df-b112-00215aee3ebe;Борщівка;Борщовка;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623483902;3;1
44ac861c-749b-11df-b112-00215aee3ebe;Борщівка Друга;Борщовка Вторая;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622681203;3;1
44ac861d-749b-11df-b112-00215aee3ebe;Борщівка Перша;Борщовка Первая;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622681202;3;1
44ac861e-749b-11df-b112-00215aee3ebe;Борщівська Турка;Борщовская Турка;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625280602;3;1
44ac861f-749b-11df-b112-00215aee3ebe;Борщівці;Борщевцы;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522655501;2;1
44ac8620-749b-11df-b112-00215aee3ebe;Борщова;Борщевая;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325181002;3;1
44ac8621-749b-11df-b112-00215aee3ebe;Борщова;Борщевая;село;64024b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324681001;3;1
44ac8622-749b-11df-b112-00215aee3ebe;Борщова;Борщевая;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523682202;3;1
44ac8623-749b-11df-b112-00215aee3ebe;Борщовичі;Борщовичи;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4623680401;3;1
44ac8624-749b-11df-b112-00215aee3ebe;Борятин;Борятин;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624884203;3;1
44ac8625-749b-11df-b112-00215aee3ebe;Борятин;Борятин;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821486702;3;1
44ac8626-749b-11df-b112-00215aee3ebe;Босири;Босыры;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125581201;3;1
44ac8627-749b-11df-b112-00215aee3ebe;Босівка;Босовка;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122880401;3;1
44ac8666-749b-11df-b112-00215aee3ebe;Ботар;Ботар;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121282402;3;1
73e94bea-ddc1-11df-9197-00215aee3ebe;Ботар;Ботар;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;a73a04a6-0835-11e4-a577-003048d2b473;2121282402;3;1
44ac8629-749b-11df-b112-00215aee3ebe;Ботвинівка;Ботвиновка;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124680501;3;1
44ac862a-749b-11df-b112-00215aee3ebe;Ботин;Ботин;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722885602;3;1
44ac862c-749b-11df-b112-00215aee3ebe;Ботфалва;Ботфалва;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2124886802;3;1
44ac862d-749b-11df-b112-00215aee3ebe;Боханів;Боханов;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421182603;3;1
44ac862e-749b-11df-b112-00215aee3ebe;Бохни;Бохны;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823082402;3;1
44ac862f-749b-11df-b112-00215aee3ebe;Бохоники;Бохоники;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520680503;3;1
44ac8630-749b-11df-b112-00215aee3ebe;Боцманів;Боцманов;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922687203;3;1
44ac8631-749b-11df-b112-00215aee3ebe;Боцянівка;Боцановка;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621682603;3;1
44ac8632-749b-11df-b112-00215aee3ebe;Бочаниця;Бочаница;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621280801;3;1
44ac8633-749b-11df-b112-00215aee3ebe;Бочанівка;Бочановка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081903;3;1
44ac8634-749b-11df-b112-00215aee3ebe;Бочечки;Бочечки;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922080401;3;1
44ac8635-749b-11df-b112-00215aee3ebe;Бочки;Бочки;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324887703;3;1
44ac8636-749b-11df-b112-00215aee3ebe;Бочківці;Бочковцы;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325080801;3;1
44ac8638-749b-11df-b112-00215aee3ebe;Бочманівка;Бочмановка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122981001;3;1
44ac8639-749b-11df-b112-00215aee3ebe;Бошівка;Бошевка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920985002;3;1
44ac863a-749b-11df-b112-00215aee3ebe;Боянець;Боянец;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622780401;3;1
44ac863b-749b-11df-b112-00215aee3ebe;Бояни;Бояны;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323080801;3;1
44ac863c-749b-11df-b112-00215aee3ebe;Бояничі;Бояничи;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624884602;3;1
44ac863d-749b-11df-b112-00215aee3ebe;Боянівка;Бояновка;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323085703;3;1
44ac863e-749b-11df-b112-00215aee3ebe;Боянівка;Бояновка;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624684703;3;1
44ac863f-749b-11df-b112-00215aee3ebe;Боянчук;Боянчук;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321581001;3;1
44ac8640-749b-11df-b112-00215aee3ebe;Боярівка;Бояровка;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422085002;3;1
44ac8641-749b-11df-b112-00215aee3ebe;Боярка;Боярка;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120282604;3;1
44ac8642-749b-11df-b112-00215aee3ebe;Боярка;Боярка;місто;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222410300;2;0
44ac8643-749b-11df-b112-00215aee3ebe;Боярка;Боярка;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122880801;3;1
44ac8644-749b-11df-b112-00215aee3ebe;Бояро-Лежачі;Бояро-Лежачи;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923880701;3;1
7ad94e49-749b-11df-b112-00215aee3ebe;Боярщина (Пролетарське);Боярщина (Пролетарское);село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421789203;3;1
44ac8645-749b-11df-b112-00215aee3ebe;Брага;Брага;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822482702;3;1
44ac8647-749b-11df-b112-00215aee3ebe;Брагинівка станція;Брагиновка Станция;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;;3;1
44ac8648-749b-11df-b112-00215aee3ebe;Брагинці;Брагинцы;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421180801;3;1
44ac8649-749b-11df-b112-00215aee3ebe;Браженець;Браженец;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510106;3;1
44ac864a-749b-11df-b112-00215aee3ebe;Браженка;Браженка;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825687202;3;1
44ac864c-749b-11df-b112-00215aee3ebe;Бражинці;Бражинцы;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823680501;3;1
44ac864d-749b-11df-b112-00215aee3ebe;Бражківка;Бражковка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322880801;3;1
44ac864e-749b-11df-b112-00215aee3ebe;Бражники;Бражники;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157304;3;1
44ac864f-749b-11df-b112-00215aee3ebe;Бражники;Бражники;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324284702;3;1
44ac8650-749b-11df-b112-00215aee3ebe;Бразолове;Бразолово;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955101;3;1
44ac8651-749b-11df-b112-00215aee3ebe;Браїлів;Браилов;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521055303;3;1
44ac8652-749b-11df-b112-00215aee3ebe;Браїлівка;Браиловка;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322086802;3;1
44ac8653-749b-11df-b112-00215aee3ebe;Браїлівка;Браиловка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323955401;3;1
44ac8654-749b-11df-b112-00215aee3ebe;Браїлівка;Браиловка;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524655401;3;1
44ac8655-749b-11df-b112-00215aee3ebe;Браїлівка;Браиловка;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823381001;3;1
44ac8656-749b-11df-b112-00215aee3ebe;Браїлки;Браилки;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324283802;3;1
44ac8657-749b-11df-b112-00215aee3ebe;Бране Поле;Браное Поле;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220680701;3;1
44ac8658-749b-11df-b112-00215aee3ebe;Брани;Браны;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720880401;3;1
44ac8659-749b-11df-b112-00215aee3ebe;Браниця;Браница;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420681201;3;1
44ac865a-749b-11df-b112-00215aee3ebe;Бранів;Бранев;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623086903;3;1
44ac865b-749b-11df-b112-00215aee3ebe;Бранкованове;Бранкованово;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125480501;3;1
44ac865c-749b-11df-b112-00215aee3ebe;Бранцівка;Бранцовка;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922380401;3;1
44ac865d-749b-11df-b112-00215aee3ebe;Братанівка;Братановка;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7324086501;3;1
44ac865e-749b-11df-b112-00215aee3ebe;Братениця;Братеница;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921281302;3;1
44ac865f-749b-11df-b112-00215aee3ebe;Братениця;Братеница;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320883502;3;1
44ac8660-749b-11df-b112-00215aee3ebe;Братерське;Братерское;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522885902;3;1
44ac8661-749b-11df-b112-00215aee3ebe;Братешки;Братешки;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281502;3;1
44ac8662-749b-11df-b112-00215aee3ebe;Братишів;Братишев;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625610102;3;1
44ac8663-749b-11df-b112-00215aee3ebe;Братківці;Братковцы;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2625880201;3;1
44ac8664-749b-11df-b112-00215aee3ebe;Братківці;Братковцы;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625380101;3;1
44ac8665-749b-11df-b112-00215aee3ebe;Братковичі;Братковичи;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620981001;3;1
44ac8667-749b-11df-b112-00215aee3ebe;Братолюбівка;Братолюбовка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823055101;3;1
44ac8668-749b-11df-b112-00215aee3ebe;Братолюбівка;Братолюбовка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323981502;3;1
44ac866b-749b-11df-b112-00215aee3ebe;Братолюбівка;Братолюбовка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521780801;3;1
44ac866c-749b-11df-b112-00215aee3ebe;Братолюбівка;Братолюбовка;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521980801;3;1
44ac866d-749b-11df-b112-00215aee3ebe;Братське;Братское;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924182602;3;1
44ac866e-749b-11df-b112-00215aee3ebe;Братське;Братское;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925085402;3;1
44ac866f-749b-11df-b112-00215aee3ebe;Братське;Братское;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221055801;3;1
44ac8670-749b-11df-b112-00215aee3ebe;Братське;Братское;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221486202;3;1
44ac8671-749b-11df-b112-00215aee3ebe;Братське;Братское;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225255105;3;1
44ac8672-749b-11df-b112-00215aee3ebe;Братське;Братское;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224281202;3;1
44ac8673-749b-11df-b112-00215aee3ebe;Братське;Братское;місто;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821455100;3;1
44ac8679-749b-11df-b112-00215aee3ebe;Братське;Братское;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520385902;3;1
44ac867a-749b-11df-b112-00215aee3ebe;Братське;Братское;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524983102;3;1
44ac867b-749b-11df-b112-00215aee3ebe;Братське;Братское;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;;3;1
22abe82b-488a-11e3-9df6-003048d2b473;Братське ;Братское ;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6523880401;3;1
44ac867c-749b-11df-b112-00215aee3ebe;Братський Посад;Братский Посад;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521980702;3;1
44ac867d-749b-11df-b112-00215aee3ebe;Братсько-Семенівка;Братско-Семеновка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221884302;3;1
44ac867e-749b-11df-b112-00215aee3ebe;Брахівка;Браховка;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620683202;3;1
44ac867f-749b-11df-b112-00215aee3ebe;Брацлав;Брацлав;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523055300;3;1
44ac8680-749b-11df-b112-00215aee3ebe;Брачківка;Брачковка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321886402;3;1
44ac8681-749b-11df-b112-00215aee3ebe;Бребоя;Бребоя;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123681303;3;1
44ac8682-749b-11df-b112-00215aee3ebe;Бредихине;Бредихино;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520886002;3;1
44ac8683-749b-11df-b112-00215aee3ebe;Брестів;Брестов;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122780801;1;1
c9e7042e-06fb-11e6-b35b-001ec93df11f;Брестів;Брестов;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122780801;3;1
44ac8684-749b-11df-b112-00215aee3ebe;Бреусівка;Бреусовка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322080401;3;1
44ac8685-749b-11df-b112-00215aee3ebe;Бреч;Бречь;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422481501;3;1
44ac8686-749b-11df-b112-00215aee3ebe;Бригадирівка;Бригадировка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324888205;3;1
44ac8687-749b-11df-b112-00215aee3ebe;Бригадирівка;Бригадировка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322083702;3;1
44ac8688-749b-11df-b112-00215aee3ebe;Бригадирівка;Бригадировка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320281201;3;1
44ac8689-749b-11df-b112-00215aee3ebe;Бригадирівка;Бригадировка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322881501;3;1
44ac868a-749b-11df-b112-00215aee3ebe;Бригадирівка;Бригадировка;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625883403;3;1
44ac868b-749b-11df-b112-00215aee3ebe;Бригидівка;Бригидовка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520285306;3;1
44ac868c-749b-11df-b112-00215aee3ebe;Бригинці;Бригинцы;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422081501;3;1
44ac868d-749b-11df-b112-00215aee3ebe;Бригинці;Бригинцы;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420681601;3;1
44ac868e-749b-11df-b112-00215aee3ebe;Бридуни;Бридуны;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323486704;3;1
44ac868f-749b-11df-b112-00215aee3ebe;Бриків;Брыков;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125880901;3;1
44ac8690-749b-11df-b112-00215aee3ebe;Бриків;Брыков;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623080401;3;1
44ac8691-749b-11df-b112-00215aee3ebe;Брикуля;Брыкуля;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825588002;3;1
44ac8692-749b-11df-b112-00215aee3ebe;Брикун;Брыкун;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623382502;3;1
44ac8694-749b-11df-b112-00215aee3ebe;Брилівка;Брилевка;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224281201;3;1
44ac8695-749b-11df-b112-00215aee3ebe;Бринзи;Брынзы;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321880902;3;1
44ac8696-749b-11df-b112-00215aee3ebe;Бринівка;Бриновка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123981703;3;1
44ac8697-749b-11df-b112-00215aee3ebe;Бринці-Загірні;Брынци-Загорные;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621581804;3;1
44ac8698-749b-11df-b112-00215aee3ebe;Бринці-Церковні;Брынци-Церковные;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621581806;3;1
44ac8699-749b-11df-b112-00215aee3ebe;Бринь;Бринь;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621280901;3;1
44ac869a-749b-11df-b112-00215aee3ebe;Бритавка;Бритавка;село;78f453a8-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0525081601;3;1
44ac869b-749b-11df-b112-00215aee3ebe;Бритай;Бритай;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323986003;3;1
44ac869c-749b-11df-b112-00215aee3ebe;Бритівка;Бритовка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120887705;3;1
44ac869d-749b-11df-b112-00215aee3ebe;Брицьке;Брицкое;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522280401;3;1
44ac869e-749b-11df-b112-00215aee3ebe;Бричківка;Бричковка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324080201;3;1
44ac869f-749b-11df-b112-00215aee3ebe;Брище;Брище;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722855220;3;1
44ac86a0-749b-11df-b112-00215aee3ebe;Брищі;Брыщи;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623884902;3;1
44ac86a1-749b-11df-b112-00215aee3ebe;Брід;Брод;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825481202;3;1
44ac86a2-749b-11df-b112-00215aee3ebe;Брід;Брод;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121981201;3;1
44ac86a3-749b-11df-b112-00215aee3ebe;Брід;Брод;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825084402;3;1
44ac86a4-749b-11df-b112-00215aee3ebe;Брідки;Бридки;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725055101;3;1
44ac86a5-749b-11df-b112-00215aee3ebe;Брідок;Бродок;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321581501;3;1
44ac86a6-749b-11df-b112-00215aee3ebe;Брідок;Бродок;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324280502;3;1
44ac86a7-749b-11df-b112-00215aee3ebe;Брідок;Бродок;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821786602;3;1
44ac86a8-749b-11df-b112-00215aee3ebe;Брідок;Бродок;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523780801;3;1
0f3cdf53-efbc-11de-9277-00215aee3ebe;Бровари;Бровары;місто;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3210600000;1;0
44ac86a9-749b-11df-b112-00215aee3ebe;Броварі;Бровари;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121288402;3;1
44ac86aa-749b-11df-b112-00215aee3ebe;Броварі;Бровари;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822482102;3;1
44ac86ab-749b-11df-b112-00215aee3ebe;Броварки;Броварки;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320680701;3;1
44ac86ac-749b-11df-b112-00215aee3ebe;Броварки;Броварки;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121581301;3;1
44ac86ad-749b-11df-b112-00215aee3ebe;Броварки;Броварки;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320483003;3;1
44ac86ae-749b-11df-b112-00215aee3ebe;Броварки;Броварки;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322886202;3;1
44ac86af-749b-11df-b112-00215aee3ebe;Бровахи;Бровахи;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122580501;3;1
44ac86b0-749b-11df-b112-00215aee3ebe;Бровки;Бровки;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220786607;3;1
44ac86b2-749b-11df-b112-00215aee3ebe;Бровки Другі;Бровки Вторые;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820385302;3;1
44ac86b3-749b-11df-b112-00215aee3ebe;Бровки Перші;Бровки Первые;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820380801;3;1
44ac86b4-749b-11df-b112-00215aee3ebe;Бровкове;Бровково;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924789102;3;1
44ac86b5-749b-11df-b112-00215aee3ebe;Бровкове;Бровково;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523880402;3;1
44ac86b6-749b-11df-b112-00215aee3ebe;Бродець;Бродец;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621888702;3;1
44ac86b7-749b-11df-b112-00215aee3ebe;Бродецьке;Бродецкое;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122280401;3;1
44ac86b8-749b-11df-b112-00215aee3ebe;Бродецьке;Бродецкое;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521455502;3;1
44ac86b9-749b-11df-b112-00215aee3ebe;Броди;Броды;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922080802;3;1
44ac86ba-749b-11df-b112-00215aee3ebe;Броди;Броды;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724281802;3;1
44ac86bb-749b-11df-b112-00215aee3ebe;Броди;Броды;місто;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620310100;1;0
44ac86bc-749b-11df-b112-00215aee3ebe;Броди;Броды;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386012;3;1
44ac86bd-749b-11df-b112-00215aee3ebe;Бродів;Бродов;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624286402;3;1
44ac86be-749b-11df-b112-00215aee3ebe;Бродівське;Бродовское;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624288802;3;1
44ac86bf-749b-11df-b112-00215aee3ebe;Бродки;Бродки;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623088402;3;1
44ac86c0-749b-11df-b112-00215aee3ebe;Бродник;Бродник;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755113;3;1
44ac86c1-749b-11df-b112-00215aee3ebe;Бродниця;Бродница;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622280702;3;1
44ac86c2-749b-11df-b112-00215aee3ebe;Бродок;Бродок;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923583602;3;1
44ac86c3-749b-11df-b112-00215aee3ebe;Бродщина;Бродщина;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321880401;3;1
44ac86c4-749b-11df-b112-00215aee3ebe;Бродятине;Бродятино;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724285302;3;1
44ac86c5-749b-11df-b112-00215aee3ebe;Брожки;Брожки;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625855601;3;1
44ac86c6-749b-11df-b112-00215aee3ebe;Бронгалівка;Бронгаловка;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
87162202-749b-11df-b112-00215aee3ebe;Брониви;Бронивы;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424786506;3;1
44ac86c7-749b-11df-b112-00215aee3ebe;Броники;Броники;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824080401;3;1
44ac86c8-749b-11df-b112-00215aee3ebe;Брониця;Броница;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721480601;3;1
44ac86c9-749b-11df-b112-00215aee3ebe;Брониця;Броница;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621280601;3;1
44ac86ca-749b-11df-b112-00215aee3ebe;Брониця;Броница;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824055603;3;1
44ac86cb-749b-11df-b112-00215aee3ebe;Броницька Гута;Броницкая Гута;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824080501;3;1
44ac86cc-749b-11df-b112-00215aee3ebe;Бронівка;Бронивка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820981201;3;1
44ac86cd-749b-11df-b112-00215aee3ebe;Бронне;Бронное;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620481401;3;1
44ac86ce-749b-11df-b112-00215aee3ebe;Бронники;Бронники;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825582602;3;1
44ac86cf-749b-11df-b112-00215aee3ebe;Бронники;Бронники;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624681101;3;1
44ac86d0-749b-11df-b112-00215aee3ebe;Бронниця;Бронница;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522681401;2;1
44ac86d1-749b-11df-b112-00215aee3ebe;Бронька;Бронька;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121981601;3;0
44ac86d2-749b-11df-b112-00215aee3ebe;Броска;Броска;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122080701;3;1
44ac86d3-749b-11df-b112-00215aee3ebe;Брохвичі;Брохвичи;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523283007;3;1
73e94c0b-ddc1-11df-9197-00215aee3ebe;Брошнів;Брошнив;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624880401;3;1
44ac86d4-749b-11df-b112-00215aee3ebe;Брошнів-Осада;Брошнив-Осада;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624855400;3;1
44ac86d5-749b-11df-b112-00215aee3ebe;Брунетівка;Брунетовка;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725084002;3;1
44ac86d6-749b-11df-b112-00215aee3ebe;Брунівка;Бруновка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324084002;3;1
44ac86d7-749b-11df-b112-00215aee3ebe;Брусенки;Брусенки;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322586002;3;1
44ac86d8-749b-11df-b112-00215aee3ebe;Брусилів;Брусилов;місто;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820955100;2;0
44ac86d9-749b-11df-b112-00215aee3ebe;Брусилів;Брусилов;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425583503;3;1
325c5800-ddd2-11df-9197-00215aee3ebe;Брусівка;Брусовка;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525881601;3;1
44ac86dc-749b-11df-b112-00215aee3ebe;Бруски;Бруски;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923881002;3;1
44ac86dd-749b-11df-b112-00215aee3ebe;Брускинське;Брускинское;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520981501;3;1
44ac86de-749b-11df-b112-00215aee3ebe;Бруслинів;Бруслинов;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522481303;3;1
44ac86df-749b-11df-b112-00215aee3ebe;Бруслинівка;Бруслиновка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522486002;3;1
44ac86e0-749b-11df-b112-00215aee3ebe;Брусниця;Брусница;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322581501;3;1
44ac86e1-749b-11df-b112-00215aee3ebe;Брусове;Брусово;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324580803;3;1
44ac86e2-749b-11df-b112-00215aee3ebe;Брустурів;Брустуров;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623684002;3;1
44ac86e3-749b-11df-b112-00215aee3ebe;Бруховичі;Бруховичи;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155301;3;1
44ac86e4-749b-11df-b112-00215aee3ebe;Брюховичі;Брюховичи;село;8a199cde-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4610166300;1;0
44ac86e5-749b-11df-b112-00215aee3ebe;Брюховичі ;Брюховичи;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623381401;2;1
44ac86e6-749b-11df-b112-00215aee3ebe;Брюшків;Брюшков;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623486002;3;1
56bdd265-749b-11df-b112-00215aee3ebe;Бубельня ( Жовтневе );Жовтневое;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123481601;3;1
44ac86e9-749b-11df-b112-00215aee3ebe;Бубереве;Буберево;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324884204;3;1
44ac86ea-749b-11df-b112-00215aee3ebe;Бубликове;Бубликово;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920683002;3;1
44ac86eb-749b-11df-b112-00215aee3ebe;Бубни;Бубны;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821483203;3;1
44ac86ec-749b-11df-b112-00215aee3ebe;Бубни;Бубны;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325182303;3;1
44ac86ed-749b-11df-b112-00215aee3ebe;Бубнище;Бубнище;село;8067acf2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2610291602;3;1
44ac86ee-749b-11df-b112-00215aee3ebe;Бубнів;Бубнов;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720582006;3;1
44ac86ef-749b-11df-b112-00215aee3ebe;Бубнів;Бубнов;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722480501;3;1
44ac86f0-749b-11df-b112-00215aee3ebe;Бубнівка;Бубновка;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520880603;3;1
44ac86f1-749b-11df-b112-00215aee3ebe;Бубнівка;Бубновка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820981501;3;1
44ac86f2-749b-11df-b112-00215aee3ebe;Бубнівка;Бубновка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821280701;3;1
44ac86f3-749b-11df-b112-00215aee3ebe;Бубнівка;Бубновка;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624288002;3;1
44ac86f4-749b-11df-b112-00215aee3ebe;Бубнівська Слобідка;Бубновская Слободка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121581501;3;1
44ac86f5-749b-11df-b112-00215aee3ebe;Бубнівщина;Бубновщина;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424181201;3;1
44ac86f6-749b-11df-b112-00215aee3ebe;Бувалине;Бывалино;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923881003;3;1
44ac86f7-749b-11df-b112-00215aee3ebe;Бугаї Другі;Бугаи Вторые;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322655103;3;1
44ac86f8-749b-11df-b112-00215aee3ebe;Бугаївка;Бугаевка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081402;3;1
44ac86f9-749b-11df-b112-00215aee3ebe;Бугаївка;Бугаевка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320681001;3;1
44ac86fa-749b-11df-b112-00215aee3ebe;Бугаївка;Бугаевка;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923287203;3;1
44ac86fb-749b-11df-b112-00215aee3ebe;Бугаївка;Бугаевка;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322082502;3;1
44ac86fc-749b-11df-b112-00215aee3ebe;Бугаївка;Бугаевка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321282502;3;1
44ac86fd-749b-11df-b112-00215aee3ebe;Бугаївка;Бугаевка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322882001;3;1
44ac86ff-749b-11df-b112-00215aee3ebe;Бугаївка;Бугаевка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523180203;3;1
44ac8700-749b-11df-b112-00215aee3ebe;Бугаївка;Бугаевка;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625881601;3;1
44ac8701-749b-11df-b112-00215aee3ebe;Бугай;Бугай;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123981102;3;1
44ac8702-749b-11df-b112-00215aee3ebe;Бугаків;Бугаков;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523080701;3;1
44ac8704-749b-11df-b112-00215aee3ebe;Буглаї;Буглаи;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824487002;3;1
44ac8705-749b-11df-b112-00215aee3ebe;Буглаки;Буглаки;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825086902;3;1
44ac8706-749b-11df-b112-00215aee3ebe;Буглаки;Буглаки;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420687202;3;1
44ac8707-749b-11df-b112-00215aee3ebe;Буглів;Буглов;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123881801;3;1
44ac8708-749b-11df-b112-00215aee3ebe;Бугове;Буговое;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521182402;3;1
44ac8709-749b-11df-b112-00215aee3ebe;Бугор;Бугор;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925684902;3;1
44ac870a-749b-11df-b112-00215aee3ebe;Бугорок;Бугорок;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823084202;3;1
44ac870b-749b-11df-b112-00215aee3ebe;Бугрин;Бугрин;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621281201;3;1
44ac870c-749b-11df-b112-00215aee3ebe;Бугринівка;Бугриновка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423685802;3;1
44ac870d-749b-11df-b112-00215aee3ebe;Бугрувате;Бугроватое;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920380801;3;1
44ac870e-749b-11df-b112-00215aee3ebe;Бугруватка;Бугроватка;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525285202;3;1
44ac870f-749b-11df-b112-00215aee3ebe;Буда;Буда;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323083602;3;1
44ac8710-749b-11df-b112-00215aee3ebe;Буда;Буда;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625889204;3;1
44ac8711-749b-11df-b112-00215aee3ebe;Буда;Буда;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;;3;1
44ac8712-749b-11df-b112-00215aee3ebe;Буда;Буда;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224481703;3;1
44ac8713-749b-11df-b112-00215aee3ebe;Буда;Буда;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822884402;3;1
44ac8714-749b-11df-b112-00215aee3ebe;Буда;Буда;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422482001;3;1
44ac8715-749b-11df-b112-00215aee3ebe;Буда;Буда;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;;3;1
44ac8716-749b-11df-b112-00215aee3ebe;Буда;Буда;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125484002;3;1
44ac8717-749b-11df-b112-00215aee3ebe;Буда;Буда;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625085002;3;1
44ac871a-749b-11df-b112-00215aee3ebe;Буда Вовчківська;Буда Волчковская;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223581602;3;1
44ac8718-749b-11df-b112-00215aee3ebe;Буда-Бабинецька;Буда-Бабинецкая;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221055301;3;1
44ac8719-749b-11df-b112-00215aee3ebe;Буда-Бровахівська;Буда-Броваховская;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122580502;3;1
2546e11b-56e1-11e9-80df-1c98ec135261;Буда-Вороб'ївська;Буда-Воробьёвская;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423681601;3;1
73e94be1-ddc1-11df-9197-00215aee3ebe;Буда-Голубієвичі;Буда-Голубиевичи;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755114;3;1
44ac871b-749b-11df-b112-00215aee3ebe;Буда-Горобіївська;Буда-Горобиевская;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122081502;3;1
44ac871c-749b-11df-b112-00215aee3ebe;Будаївці;Будаивцы;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123180802;3;1
44ac871d-749b-11df-b112-00215aee3ebe;Буда-Макіївка;Буда-Макеевка;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123784002;3;1
44ac871e-749b-11df-b112-00215aee3ebe;Буданів;Буданов;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125080501;3;1
44ac8756-749b-11df-b112-00215aee3ebe;Буда-Орловецька;Буда-Орловецкая;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120380501;3;1
44ac871f-749b-11df-b112-00215aee3ebe;Буда-Полідарівська;Буда-Полідарівська;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222084602;3;1
44ac8720-749b-11df-b112-00215aee3ebe;Буда-Радинська;Буда-Радинская;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223588002;3;1
44ac8722-749b-11df-b112-00215aee3ebe;Будеї;Будеи;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122581201;3;1
44ac8723-749b-11df-b112-00215aee3ebe;Буденець;Буденец;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324581501;3;1
44ac8725-749b-11df-b112-00215aee3ebe;Будераж;Будераж;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621685403;3;1
44ac8726-749b-11df-b112-00215aee3ebe;Будераж;Будераж;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622680801;3;1
44ac8727-749b-11df-b112-00215aee3ebe;Будзин;Будзин;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625686203;3;1
44ac8728-749b-11df-b112-00215aee3ebe;Буди;Буды;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325156100;3;0
44ac8729-749b-11df-b112-00215aee3ebe;Буди;Буды;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622786703;3;1
44ac872a-749b-11df-b112-00215aee3ebe;Буди;Буды;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421781601;3;1
44ac872b-749b-11df-b112-00215aee3ebe;Буди;Буды;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524180601;3;1
44ac872c-749b-11df-b112-00215aee3ebe;Буди;Буды;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623886903;3;1
f933344c-db4e-11df-9197-00215aee3ebe;Буди;Буды;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425581250;3;1
44ac872d-749b-11df-b112-00215aee3ebe;Будиголош;Будиголош;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620685802;3;1
44ac872e-749b-11df-b112-00215aee3ebe;Будилів;Будилов;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123080401;3;1
44ac872f-749b-11df-b112-00215aee3ebe;Будилів;Будилов;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625280201;3;1
44ac8730-749b-11df-b112-00215aee3ebe;Будилівка;Будиловка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825086903;3;1
44ac8731-749b-11df-b112-00215aee3ebe;Будилка;Будилка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922981201;3;1
44ac8732-749b-11df-b112-00215aee3ebe;Будимля;Будымля;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621886402;3;1
44ac8733-749b-11df-b112-00215aee3ebe;Будисько;Будиско;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820656301;3;1
44ac8734-749b-11df-b112-00215aee3ebe;Будичани;Будичаны;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825880801;3;1
44ac8735-749b-11df-b112-00215aee3ebe;Будища;Будища;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921580402;3;1
44ac8736-749b-11df-b112-00215aee3ebe;Будища;Будища;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824081402;3;1
44ac8737-749b-11df-b112-00215aee3ebe;Будище;Будище;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722183602;3;1
44ac8738-749b-11df-b112-00215aee3ebe;Будище;Будище;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825480402;3;1
44ac8739-749b-11df-b112-00215aee3ebe;Будище;Будище;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421484002;3;1
44ac873a-749b-11df-b112-00215aee3ebe;Будище;Будище;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425580802;3;1
44ac873b-749b-11df-b112-00215aee3ebe;Будище;Будище;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423684702;3;1
44ac873c-749b-11df-b112-00215aee3ebe;Будище;Будище;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422280701;3;1
44ac873d-749b-11df-b112-00215aee3ebe;Будище;Будище;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422783502;3;1
44ac873e-749b-11df-b112-00215aee3ebe;Будище;Будище;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422087702;3;1
44ac873f-749b-11df-b112-00215aee3ebe;Будище;Будище;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122881201;3;1
44ac8740-749b-11df-b112-00215aee3ebe;Будище;Будище;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124981501;3;1
44ac8741-749b-11df-b112-00215aee3ebe;Будище;Будище;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121281201;3;1
44ac8742-749b-11df-b112-00215aee3ebe;Будівельне;Будивельное;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921581003;3;1
44ac8743-749b-11df-b112-00215aee3ebe;Будки;Будки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321380402;3;1
44ac8744-749b-11df-b112-00215aee3ebe;Будки;Будки;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920687403;3;1
44ac8745-749b-11df-b112-00215aee3ebe;Будки;Будки;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723680601;3;1
44ac8746-749b-11df-b112-00215aee3ebe;Будки;Будки;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123481001;3;1
44ac8747-749b-11df-b112-00215aee3ebe;Будки;Будки;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824484402;3;1
44ac8748-749b-11df-b112-00215aee3ebe;Будки;Будки;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123783002;3;1
44ac8749-749b-11df-b112-00215aee3ebe;Будки;Будки;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523084402;3;1
44ac874a-749b-11df-b112-00215aee3ebe;Будки-Кам'янські;Будки-Каменские;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625083202;3;1
44ac874b-749b-11df-b112-00215aee3ebe;Будки-Сновидовицькі;Будки-Сновидовицкие;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625086202;3;1
44ac874c-749b-11df-b112-00215aee3ebe;Будків;Будков;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524880602;3;1
44ac874d-749b-11df-b112-00215aee3ebe;Будне;Будное;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920388002;3;1
44ac874e-749b-11df-b112-00215aee3ebe;Будне;Будное;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525387703;3;1
44ac874f-749b-11df-b112-00215aee3ebe;Будники;Будники;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723384904;3;1
44ac8750-749b-11df-b112-00215aee3ebe;Будницьке;Будницкое;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823484002;3;1
44ac8751-749b-11df-b112-00215aee3ebe;Будо-Бобриця;Буда-Бобрица;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821781401;3;1
44ac8752-749b-11df-b112-00215aee3ebe;Будова;Будовая;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123084403;3;1
44ac8753-749b-11df-b112-00215aee3ebe;Будо-Вороб'ї;Будо-Воробьи;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823481201;3;1
44ac8754-749b-11df-b112-00215aee3ebe;Будо-Вороб'ївська;Буда-Воробьевская;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423681601;3;1
44ac8755-749b-11df-b112-00215aee3ebe;Будо-Літки;Будо-Литки;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822880801;3;1
44ac8757-749b-11df-b112-00215aee3ebe;Будо-Рижани;Будо-Рыжаны;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821183802;3;1
44ac8758-749b-11df-b112-00215aee3ebe;Будятичі;Будятичи;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721187702;3;1
44ac8759-749b-11df-b112-00215aee3ebe;Будячки;Будячки;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123980702;3;1
44ac875a-749b-11df-b112-00215aee3ebe;Будьки;Будьки;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521086306;3;1
44ac875b-749b-11df-b112-00215aee3ebe;Будьків;Будьков;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623687102;3;1
44ac875c-749b-11df-b112-00215aee3ebe;Бужани;Бужаны;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720880801;3;1
44ac875d-749b-11df-b112-00215aee3ebe;Бужанка;Бужанка;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721180401;3;1
44ac875e-749b-11df-b112-00215aee3ebe;Бужанка;Бужанка;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422281502;3;1
44ac875f-749b-11df-b112-00215aee3ebe;Бужанка;Бужанка;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122881601;3;1
44ac8760-749b-11df-b112-00215aee3ebe;Бужковичі;Бужковичи;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721182802;3;1
44ac8761-749b-11df-b112-00215aee3ebe;Бужок;Бужок;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4621880402;3;1
44ac8762-749b-11df-b112-00215aee3ebe;Бузаки;Бузаки;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721480801;3;1
44ac8764-749b-11df-b112-00215aee3ebe;Бузинівщина;Бузиновщина;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281602;3;1
44ac8765-749b-11df-b112-00215aee3ebe;Бузинове;Бузиново;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121881201;3;1
44ac8766-749b-11df-b112-00215aee3ebe;Бузівка;Бузовка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222384003;3;1
44ac8767-749b-11df-b112-00215aee3ebe;Бузівка;Бузовка;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120981201;3;1
44ac8768-749b-11df-b112-00215aee3ebe;Бузникувате;Бузниковатое;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524380201;3;1
44ac8769-749b-11df-b112-00215aee3ebe;Бузова;Бузовая;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222480801;3;1
44ac876a-749b-11df-b112-00215aee3ebe;Бузова;Бузовая;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522882004;3;1
44ac876b-749b-11df-b112-00215aee3ebe;Бузова Пасківка;Бузовая Пасковка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083903;3;1
44ac876c-749b-11df-b112-00215aee3ebe;Бузоварове;Бузоварово;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821755105;3;1
44ac876d-749b-11df-b112-00215aee3ebe;Бузове;Бузовое;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323581702;3;1
44ac876f-749b-11df-b112-00215aee3ebe;Бузовиця;Бузовица;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322081201;3;1
44ac8770-749b-11df-b112-00215aee3ebe;Бузуків;Бузуков;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124987003;3;1
44ac8771-749b-11df-b112-00215aee3ebe;Бузулуки;Бузулуки;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222082004;3;1
44ac8772-749b-11df-b112-00215aee3ebe;Бузьке;Бужское;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820355701;3;1
44ac8773-749b-11df-b112-00215aee3ebe;Бузьке;Бужское;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822080801;3;1
44ac8774-749b-11df-b112-00215aee3ebe;Бузьке;Бужское;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824880801;3;1
44ac8775-749b-11df-b112-00215aee3ebe;Буйвалове;Буйвалово;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922681501;3;1
44ac8776-749b-11df-b112-00215aee3ebe;Буйволівці;Буйволивцы;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825881601;3;1
44ac8777-749b-11df-b112-00215aee3ebe;Буймер;Буймер;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925081201;3;1
44ac8778-749b-11df-b112-00215aee3ebe;Буймерівка;Буймеровка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920384002;3;1
44ac877a-749b-11df-b112-00215aee3ebe;Букати;Букаты;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922987105;3;1
44ac877b-749b-11df-b112-00215aee3ebe;Букатинка;Букатинка;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524980302;3;1
62c3d134-749b-11df-b112-00215aee3ebe;Букачі;Букачи;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121883203;3;1
44ac877c-749b-11df-b112-00215aee3ebe;Букачівська Слобода;Букачевская Слобода;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624455301;3;1
73e94c06-ddc1-11df-9197-00215aee3ebe;Букачівська Слобода;Букачевская Слобода;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;;3;1
44ac877d-749b-11df-b112-00215aee3ebe;Букачівці;Букачевцы;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624455300;3;1
44ac877e-749b-11df-b112-00215aee3ebe;Букварка;Букварка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520581501;3;1
44ac877f-749b-11df-b112-00215aee3ebe;Буки;Буки;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224080601;3;1
44ac8780-749b-11df-b112-00215aee3ebe;Буки;Буки;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823481403;3;1
44ac8781-749b-11df-b112-00215aee3ebe;Буки;Буки;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822087202;3;1
44ac8782-749b-11df-b112-00215aee3ebe;Буки;Буки;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123155300;3;1
44ac8783-749b-11df-b112-00215aee3ebe;Буків;Буков;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722883702;3;1
44ac8784-749b-11df-b112-00215aee3ebe;Буківка;Буковка;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320780601;3;1
44ac8785-749b-11df-b112-00215aee3ebe;Буківна;Буковна;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625686403;3;1
44ac8786-749b-11df-b112-00215aee3ebe;Буківцьово;Буковцево;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120880401;3;1
44ac8787-749b-11df-b112-00215aee3ebe;Букова;Букова;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625183103;3;1
44ac8788-749b-11df-b112-00215aee3ebe;Букове;Буковое;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624085002;3;1
44ac8789-749b-11df-b112-00215aee3ebe;Букове;Буковое;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121280601;3;1
44ac878a-749b-11df-b112-00215aee3ebe;Буковець;Буковец;село;8067acf2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2610291603;3;1
44ac878b-749b-11df-b112-00215aee3ebe;Буковець;Буковец;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620881301;3;1
44ac878c-749b-11df-b112-00215aee3ebe;Буковець;Буковец;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121581501;3;1
44ac878d-749b-11df-b112-00215aee3ebe;Буковець;Буковец;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122480401;3;1
44ac878e-749b-11df-b112-00215aee3ebe;Буковина;Буковина;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621581202;3;1
44ac8790-749b-11df-b112-00215aee3ebe;Буковинка;Буковинка;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625583903;3;1
44ac8791-749b-11df-b112-00215aee3ebe;Буковинка;Буковинка;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122781603;2;1
44ac8795-749b-11df-b112-00215aee3ebe;Булавівщина;Булавовщина;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421182402;3;1
44ac8796-749b-11df-b112-00215aee3ebe;Булаї;Булаи;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523487202;3;1
44ac8797-749b-11df-b112-00215aee3ebe;Буланове;Буланово;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086202;3;1
44ac8798-749b-11df-b112-00215aee3ebe;Булатівка;Булатовка;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124786402;3;1
44ac8799-749b-11df-b112-00215aee3ebe;Булахи;Булахи;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084003;3;1
44ac879a-749b-11df-b112-00215aee3ebe;Булахів;Булахов;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422081801;3;1
44ac879b-749b-11df-b112-00215aee3ebe;Булахівка;Булаховка;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223584507;3;1
44ac879c-749b-11df-b112-00215aee3ebe;Булахівка;Булаховка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822381602;3;1
44ac879d-749b-11df-b112-00215aee3ebe;Булацелівка;Булацеловка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324587003;3;1
44ac879e-749b-11df-b112-00215aee3ebe;Булацелове;Булацелово;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820380402;3;1
44ac87a0-749b-11df-b112-00215aee3ebe;Булдинка;Булдынка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122755402;3;1
44ac87a1-749b-11df-b112-00215aee3ebe;Булдичів;Булдычев;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821480601;3;1
44ac87a2-749b-11df-b112-00215aee3ebe;Булуки;Булуки;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323255401;3;1
44ac87a3-749b-11df-b112-00215aee3ebe;Бунакове;Бунаково;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323981001;3;1
44ac87a4-749b-11df-b112-00215aee3ebe;Бунів;Бунов;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625881301;3;1
44ac87a6-749b-11df-b112-00215aee3ebe;Бунякине;Бунякино;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923881001;3;1
44ac87a7-749b-11df-b112-00215aee3ebe;Буняківка;Буняковка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322081602;3;1
44ac87a8-749b-11df-b112-00215aee3ebe;Буньковичі;Буньковичи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625181102;3;1
44ac87e1-749b-11df-b112-00215aee3ebe;Бур'яни;Бурьяны;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520882206;3;1
44ac87a9-749b-11df-b112-00215aee3ebe;Бурбине;Бурбино;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324586703;3;1
44ac87aa-749b-11df-b112-00215aee3ebe;Бурбине;Бурбино;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187002;3;1
44ac87ab-749b-11df-b112-00215aee3ebe;Бурбулатове;Бурбулатово;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320681501;3;1
44ac87ac-749b-11df-b112-00215aee3ebe;Бургунка;Бургунка;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520680201;3;1
44ac87ad-749b-11df-b112-00215aee3ebe;Бурдей;Бурдей;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322588503;3;1
44ac87ae-749b-11df-b112-00215aee3ebe;Бурдівка;Бурдовка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123981704;3;1
44ac87af-749b-11df-b112-00215aee3ebe;Бурдії;Бурдеи;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524384302;3;1
44ac87b0-749b-11df-b112-00215aee3ebe;Бурдюг;Бурдюг;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322081601;3;1
44ac87b1-749b-11df-b112-00215aee3ebe;Бурдяківці;Бурдяковцы;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120881201;3;1
44ac87b3-749b-11df-b112-00215aee3ebe;Бурики;Бурики;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920981201;3;1
44ac87b4-749b-11df-b112-00215aee3ebe;Бурилове;Бурилово;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823980701;3;1
44ac87b5-749b-11df-b112-00215aee3ebe;Буринь;Бурынь;місто;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920910100;3;1
44ac87b6-749b-11df-b112-00215aee3ebe;Бурівка;Буровка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922980802;3;1
44ac87b7-749b-11df-b112-00215aee3ebe;Бурівка;Буровка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321288502;3;1
44ac87b8-749b-11df-b112-00215aee3ebe;Бурівка;Буровка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421480801;3;1
44ac87b9-749b-11df-b112-00215aee3ebe;Бурімка;Буромка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324555103;3;1
44ac87ba-749b-11df-b112-00215aee3ebe;Бурімка;Буромка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421782001;3;1
44ac87bb-749b-11df-b112-00215aee3ebe;Бурканів;Бурканов;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125080701;3;1
44ac87bc-749b-11df-b112-00215aee3ebe;Буркачі;Буркачи;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720883802;3;1
44ac87bd-749b-11df-b112-00215aee3ebe;Бурківка;Бурковка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423380801;3;1
44ac87be-749b-11df-b112-00215aee3ebe;Бурківці;Бурковцы;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224610101;3;1
44ac87bf-749b-11df-b112-00215aee3ebe;Бурківці;Бурковцы;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825881201;3;1
44ac87c0-749b-11df-b112-00215aee3ebe;Бурківці;Бурковцы;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523483302;3;1
44ac87c1-749b-11df-b112-00215aee3ebe;Буркут;Буркут;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620884002;3;1
44ac87c3-749b-11df-b112-00215aee3ebe;Бурлаки;Бурлаки;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324883203;3;1
44ac87c4-749b-11df-b112-00215aee3ebe;Бурлацьке;Бурлацкое;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221881202;3;1
44ac87c7-749b-11df-b112-00215aee3ebe;Бурлача Балка;Бурлачая Балка;село;903cca7c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5110890101;2;0
44ac87c9-749b-11df-b112-00215aee3ebe;Буро-Рубанівка;Буро-Рубановка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920382802;3;1
44ac87ca-749b-11df-b112-00215aee3ebe;Бурти;Бурты;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086504;3;1
44ac87cb-749b-11df-b112-00215aee3ebe;Бурти;Бурты;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323487602;3;1
44ac87cc-749b-11df-b112-00215aee3ebe;Бурти;Бурты;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322480402;3;1
44ac87cd-749b-11df-b112-00215aee3ebe;Бурти;Бурты;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222281201;3;1
44ac87ce-749b-11df-b112-00215aee3ebe;Бурти;Бурты;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125780801;3;1
44ac87cf-749b-11df-b112-00215aee3ebe;Бурти;Бурты;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523881302;3;1
44ac87d0-749b-11df-b112-00215aee3ebe;Бурти;Бурты;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825282002;2;1
44ac87d1-749b-11df-b112-00215aee3ebe;Буртин;Буртын;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823681001;3;1
44ac87d3-749b-11df-b112-00215aee3ebe;Бурханівка;Бурхановка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825782510;3;1
44ac87ff-749b-11df-b112-00215aee3ebe;Бурханівка;Бурхановка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224881302;3;1
44ac87d4-749b-11df-b112-00215aee3ebe;Бурчак;Бурчак;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786702;3;1
44ac87d5-749b-11df-b112-00215aee3ebe;Бурчак;Бурчак;село;6b07f8cc-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;e4c661c5-f688-11e1-a474-003048d2b473;2323355106;3;1
44ac87d7-749b-11df-b112-00215aee3ebe;Бурштин;Бурштын;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2610300000;2;0
44ac87d8-749b-11df-b112-00215aee3ebe;Буряки;Буряки;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820880301;3;1
44ac87d9-749b-11df-b112-00215aee3ebe;Буряківка;Буряковка;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122081401;3;1
44ac87db-749b-11df-b112-00215aee3ebe;Буряківка;Буряковка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825455101;3;1
44ac87dc-749b-11df-b112-00215aee3ebe;Буряківщина;Буряковщина;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320255102;3;1
44ac87de-749b-11df-b112-00215aee3ebe;Бурякове;Буряковое;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321282503;3;1
44ac87df-749b-11df-b112-00215aee3ebe;Бурякове;Буряковое;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125482002;3;1
44ac87e0-749b-11df-b112-00215aee3ebe;Бурякове;Буряковое;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520582002;3;1
44ac87e2-749b-11df-b112-00215aee3ebe;Бурячиха;Бурячиха;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920385802;3;1
44ac87e3-749b-11df-b112-00215aee3ebe;Бурячки;Бурячки;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655104;3;1
44ac87e4-749b-11df-b112-00215aee3ebe;Бусовисько;Бусовиско;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625181302;3;1
44ac87e5-749b-11df-b112-00215aee3ebe;Буськ;Буск;місто;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620610100;2;0
44ac87e6-749b-11df-b112-00215aee3ebe;Бутейки;Бутейки;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625480502;3;1
44ac87e7-749b-11df-b112-00215aee3ebe;Бутенки;Бутенки;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084202;3;1
44ac87e8-749b-11df-b112-00215aee3ebe;Бутенки;Бутенки;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321880701;3;1
44ac87e9-749b-11df-b112-00215aee3ebe;Бутенкове;Бутенково;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325185002;3;1
44ac87ea-749b-11df-b112-00215aee3ebe;Бутин;Бутин;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122480801;3;1
44ac87eb-749b-11df-b112-00215aee3ebe;Бутини;Бутыны;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624880601;3;1
44ac87ec-749b-11df-b112-00215aee3ebe;Бутівка;Бутовка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321755301;3;1
44ac87ed-749b-11df-b112-00215aee3ebe;Бутівка;Бутовка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122781302;3;1
44ac87ee-749b-11df-b112-00215aee3ebe;Бутівка;Бутовка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421481201;3;1
44ac87ef-749b-11df-b112-00215aee3ebe;Бутівка;Бутовка;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424981001;3;1
44ac87f0-749b-11df-b112-00215aee3ebe;Бутівське;Бутовское;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520383802;3;1
44ac87f1-749b-11df-b112-00215aee3ebe;Бутівці;Бутовцы;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324885403;3;1
44ac87f2-749b-11df-b112-00215aee3ebe;Бутівці;Бутовцы;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824280702;3;1
44ac87f5-749b-11df-b112-00215aee3ebe;Бутова Долина;Бутовая Долина;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320255103;3;1
44ac87f7-749b-11df-b112-00215aee3ebe;Бутове;Бутово;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622280703;3;1
44ac87f8-749b-11df-b112-00215aee3ebe;Бутовичеське;Бутовическое;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320488202;3;1
44ac87f9-749b-11df-b112-00215aee3ebe;Бутовичівка;Бутовичевка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225080503;3;1
44ac87fa-749b-11df-b112-00215aee3ebe;Бутовщина;Бутовщина;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920655302;3;1
44ac87fb-749b-11df-b112-00215aee3ebe;Бутоярівка;Бутояровка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084005;3;1
44ac87fc-749b-11df-b112-00215aee3ebe;Бухалівка;Бухаловка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321384802;3;1
44ac87fd-749b-11df-b112-00215aee3ebe;Бухалівщина;Бухаловщина;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824783302;3;1
44ac87fe-749b-11df-b112-00215aee3ebe;Бухалове;Бухалово;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923282803;3;1
44ac8804-749b-11df-b112-00215aee3ebe;Бухалове;Бухалово;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320483602;3;1
44ac8800-749b-11df-b112-00215aee3ebe;Бухарів;Бухаров;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624280801;3;1
44ac8801-749b-11df-b112-00215aee3ebe;Бухни;Бухны;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523483003;3;1
44ac8802-749b-11df-b112-00215aee3ebe;Буховецьке;Буховецкое;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;3520881201;3;1
44ac8803-749b-11df-b112-00215aee3ebe;Буховичі;Буховичи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622485202;3;1
44ac8805-749b-11df-b112-00215aee3ebe;Бухуни;Бухуны;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325786002;3;1
44ac8806-749b-11df-b112-00215aee3ebe;Буцики;Буцыки;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121655401;3;1
44ac8807-749b-11df-b112-00215aee3ebe;Буцикове;Буциково;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924787902;3;1
44ac8809-749b-11df-b112-00215aee3ebe;Буцинівка;Буциновка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123980501;3;1
44ac8808-749b-11df-b112-00215aee3ebe;Буцинь;Буцинь;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725080501;3;1
44ac880a-749b-11df-b112-00215aee3ebe;Буців;Буцов;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622487903;3;1
44ac880b-749b-11df-b112-00215aee3ebe;Буцівське;Буцовское;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520984002;3;1
44ac880c-749b-11df-b112-00215aee3ebe;Буцневе;Буцнево;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821589202;3;1
44ac880d-749b-11df-b112-00215aee3ebe;Буцні;Буцни;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520281606;3;1
44ac880e-749b-11df-b112-00215aee3ebe;Буцні;Буцни;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823080402;3;1
44ac880f-749b-11df-b112-00215aee3ebe;Буцнів;Буцнев;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125280901;2;1
44ac8810-749b-11df-b112-00215aee3ebe;Буцьківка;Буцковка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321288503;3;1
44ac8811-749b-11df-b112-00215aee3ebe;Буча;Буча;місто;903cca8d-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3210800000;2;0
44ac8813-749b-11df-b112-00215aee3ebe;Бучак;Бучак;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122087402;3;1
44ac8814-749b-11df-b112-00215aee3ebe;Бучали;Бучалы;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620981101;2;1
44ac8815-749b-11df-b112-00215aee3ebe;Бучач;Бучач;місто;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121210100;2;0
44ac8816-749b-11df-b112-00215aee3ebe;Бучачки;Бучачки;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625280302;3;1
44ac8817-749b-11df-b112-00215aee3ebe;Бучая;Бучая;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823381201;3;1
44ac8818-749b-11df-b112-00215aee3ebe;Бучин;Бучин;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155107;3;1
44ac8819-749b-11df-b112-00215aee3ebe;Бучина;Бучина;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620387002;3;1
44ac881a-749b-11df-b112-00215aee3ebe;Бучки;Бучки;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823481405;3;1
44ac881b-749b-11df-b112-00215aee3ebe;Бучки;Бучки;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423682001;3;1
44ac881c-749b-11df-b112-00215aee3ebe;Бучмани;Бучманы;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824455200;3;1
44ac881d-749b-11df-b112-00215aee3ebe;Бучми;Бучмы;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622780803;3;1
44ac881e-749b-11df-b112-00215aee3ebe;Буша;Буша;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525680801;3;1
44ac881f-749b-11df-b112-00215aee3ebe;Бушеве;Бушево;село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223781001;3;1
44ac8820-749b-11df-b112-00215aee3ebe;Бушинка;Бушинка;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524580401;3;1
44ac8821-749b-11df-b112-00215aee3ebe;Бушкалик;Бушкалик;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625685602;3;1
44ac8822-749b-11df-b112-00215aee3ebe;Буштино;Буштыно;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124455300;2;0
44ac8823-749b-11df-b112-00215aee3ebe;Буща;Буща;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621689503;3;1
44ac8824-749b-11df-b112-00215aee3ebe;Буща;Буща;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622681201;3;1
44ac8825-749b-11df-b112-00215aee3ebe;Буян;Буян;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;;3;1
44ac8826-749b-11df-b112-00215aee3ebe;Буяни;Буяны;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722880901;3;1
44ac8827-749b-11df-b112-00215aee3ebe;Буянів;Буянов;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621584702;3;1
44ac8828-749b-11df-b112-00215aee3ebe;Буянки;Буянки;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424481202;3;1
4aba3f8c-749b-11df-b112-00215aee3ebe;В'юнище;Вьюнище;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823480805;3;1
4aba3f8d-749b-11df-b112-00215aee3ebe;В'юнівщина;Вьюновщина;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5622683802;3;1
4aba3f8e-749b-11df-b112-00215aee3ebe;В'юнки;Вьюнки;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825481201;3;1
4aba3f8f-749b-11df-b112-00215aee3ebe;В'юнки;Вьюнки;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524085202;3;1
4aba3f90-749b-11df-b112-00215aee3ebe;В'юнне;Вьюнное;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924188202;3;1
4aba3f91-749b-11df-b112-00215aee3ebe;В'язенка;Вязенка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923882301;3;1
4aba3f92-749b-11df-b112-00215aee3ebe;В'язівка;Вязовка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322082003;3;1
4aba3f93-749b-11df-b112-00215aee3ebe;В'язівка;Вязовка;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755120;3;1
4aba3f94-749b-11df-b112-00215aee3ebe;В'язівне;Вьязовное;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155112;3;1
4aba3f96-749b-11df-b112-00215aee3ebe;В'язівок;Вязовок;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324880303;3;1
4aba3f97-749b-11df-b112-00215aee3ebe;В'язівок;Вязовок;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223582804;3;1
4aba3f98-749b-11df-b112-00215aee3ebe;В'язівок;Вязовок;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120382501;3;1
4aba3f99-749b-11df-b112-00215aee3ebe;В'язівок;Вязовок;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322884902;3;1
4aba3f95-749b-11df-b112-00215aee3ebe;В'язівське-Водяне;Вязовское-Водяное;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225980509;3;1
4aba3f9b-749b-11df-b112-00215aee3ebe;В'язова;Вязовая;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323580501;3;1
4aba3f9c-749b-11df-b112-00215aee3ebe;В'язова;Вязовая;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622789202;3;1
4aba3f9d-749b-11df-b112-00215aee3ebe;В'язове;Вязовое;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922081501;3;1
4aba3f9e-749b-11df-b112-00215aee3ebe;В'язове;Вязовое;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920381601;3;1
4aba3f9f-749b-11df-b112-00215aee3ebe;В'язове;Вязовое;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322081003;3;1
4aba3fa0-749b-11df-b112-00215aee3ebe;В'язовець;Вьязовец;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825482203;3;1
4aba3fa1-749b-11df-b112-00215aee3ebe;В'язовець;Вьязовец;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820381001;3;1
4aba3fa2-749b-11df-b112-00215aee3ebe;В'язовиця;Вязовица;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521210102;3;1
4aba3fa3-749b-11df-b112-00215aee3ebe;В'ятка;Вятка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923882303;3;1
44ac8829-749b-11df-b112-00215aee3ebe;Вабля;Вабля;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221086802;3;1
44ac882a-749b-11df-b112-00215aee3ebe;Вавилове;Вавилово;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825784702;3;1
44ac882b-749b-11df-b112-00215aee3ebe;Вага;Вага;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
44ac882c-749b-11df-b112-00215aee3ebe;Ваганичі;Ваганичи;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421481601;3;1
44ac882d-749b-11df-b112-00215aee3ebe;Вадул Сірет;Вадул Сирет;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;;3;1
44ac882e-749b-11df-b112-00215aee3ebe;Важне;Важное;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121080702;3;1
44ac882f-749b-11df-b112-00215aee3ebe;Важне;Важное;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523787003;3;1
44ac8830-749b-11df-b112-00215aee3ebe;Важниче;Важниче;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;;3;1
44ac8831-749b-11df-b112-00215aee3ebe;Вазлуївка;Вазлуевка;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524980303;3;1
44ac8832-749b-11df-b112-00215aee3ebe;Вакалівщина;Вакаловщина;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924781502;3;1
44ac8833-749b-11df-b112-00215aee3ebe;Вакарське;Вакарское;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655104;3;1
44ac8834-749b-11df-b112-00215aee3ebe;Вакуленчук;Вакуленчук;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825855200;3;1
44ac8835-749b-11df-b112-00215aee3ebe;Вакули;Вакулы;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625884503;3;1
44ac8836-749b-11df-b112-00215aee3ebe;Вакулиха;Вакулиха;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321685602;3;1
44ac8837-749b-11df-b112-00215aee3ebe;Вакулівка;Вакуловка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123980703;3;1
44ac8838-749b-11df-b112-00215aee3ebe;Вакулки;Вакулки;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923555101;3;1
56bdd234-749b-11df-b112-00215aee3ebe;Вакулове (Жовтневе);Жовтневое;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225283301;3;1
44ac8839-749b-11df-b112-00215aee3ebe;Валени;Валены;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;;3;1
44ac883a-749b-11df-b112-00215aee3ebe;Валентинівка;Валентиновка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125485902;3;1
44ac883d-749b-11df-b112-00215aee3ebe;Валентіїв;Валентиев;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423387102;3;1
44ac883e-749b-11df-b112-00215aee3ebe;Валер'янівка;Валерьяновка;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724585902;3;1
44ac8840-749b-11df-b112-00215aee3ebe;Валер'яновка;Валерьяновка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684803;3;1
44ac8841-749b-11df-b112-00215aee3ebe;Вали;Валы;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320583004;3;1
44ac8842-749b-11df-b112-00215aee3ebe;Валігури;Валигуры;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123481002;3;1
44ac8843-749b-11df-b112-00215aee3ebe;Валіївка;Валиевка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920681402;3;1
44ac8845-749b-11df-b112-00215aee3ebe;Валки;Валки;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982403;3;1
44ac8846-749b-11df-b112-00215aee3ebe;Валки;Валки;місто;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321210100;3;0
44ac8847-749b-11df-b112-00215aee3ebe;Валки;Валки;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821187602;3;1
44ac8848-749b-11df-b112-00215aee3ebe;Валки;Валки;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424181601;3;1
44ac884a-749b-11df-b112-00215aee3ebe;Валок;Валок;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324080301;3;1
44ac884d-749b-11df-b112-00215aee3ebe;Валя Кузьмина;Валя Кузьмина;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321080501;3;1
44ac884e-749b-11df-b112-00215aee3ebe;Валява;Валява;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322582001;3;1
44ac884f-749b-11df-b112-00215aee3ebe;Валява;Валява;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120381001;3;1
44ac8851-749b-11df-b112-00215aee3ebe;Ванжина Долина;Ванжина Долина;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324887705;3;1
44ac8852-749b-11df-b112-00215aee3ebe;Ванжулів;Ванжулов;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123882001;3;1
44ac8853-749b-11df-b112-00215aee3ebe;Ванів;Ванов;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624880901;3;1
44ac8854-749b-11df-b112-00215aee3ebe;Ванчиківці;Ванчиковцы;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323081201;3;1
44ac8855-749b-11df-b112-00215aee3ebe;Ванчинець;Ванчинец;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323081202;3;1
7c7897ca-c273-11e4-94fe-003048d2b473;Ваньковичі;Ваньковичи;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624284002;3;1
44ac8857-749b-11df-b112-00215aee3ebe;Ваньовичі;Ваньовичи;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624287903;3;1
44ac8858-749b-11df-b112-00215aee3ebe;Вапнярка;Вапнярка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123983902;3;1
44ac8859-749b-11df-b112-00215aee3ebe;Вапнярка;Вапнярка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122783902;3;1
44ac885a-749b-11df-b112-00215aee3ebe;Вапнярка;Вапнярка;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523955400;3;1
44ac885b-749b-11df-b112-00215aee3ebe;Вапнярки;Вапнярки;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523955103;3;1
44ac885c-749b-11df-b112-00215aee3ebe;Варакути;Варакуты;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322483203;3;1
44ac885d-749b-11df-b112-00215aee3ebe;Варачине;Варачино;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924785902;3;1
62c3cefe-749b-11df-b112-00215aee3ebe;Вараш;Вараш;місто;5394b31c-41be-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5610700000;1;0
44ac885e-749b-11df-b112-00215aee3ebe;Варва;Варва;місто;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;7421155100;3;0
44ac885f-749b-11df-b112-00215aee3ebe;Варваринці;Варваринцы;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125088302;3;1
44ac8860-749b-11df-b112-00215aee3ebe;Варварівка;Варваровка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323480303;3;1
44ac8861-749b-11df-b112-00215aee3ebe;Варварівка;Варваровка;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321680701;3;1
44ac8862-749b-11df-b112-00215aee3ebe;Варварівка;Варваровка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223284502;3;1
44ac8863-749b-11df-b112-00215aee3ebe;Варварівка;Варваровка;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225980501;3;1
44ac8864-749b-11df-b112-00215aee3ebe;Варварівка;Варваровка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224880501;3;1
44ac8866-749b-11df-b112-00215aee3ebe;Варварівка;Варваровка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323380502;3;1
44ac8867-749b-11df-b112-00215aee3ebe;Варварівка;Варваровка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320682002;3;1
44ac886d-749b-11df-b112-00215aee3ebe;Варварівка;Варваровка;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824486202;3;1
44ac886e-749b-11df-b112-00215aee3ebe;Варварівка;Варваровка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821781601;3;1
44ac886f-749b-11df-b112-00215aee3ebe;Варварівка;Варваровка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824081703;3;1
44ac8870-749b-11df-b112-00215aee3ebe;Варварівка;Варваровка;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420381502;3;1
44ac8871-749b-11df-b112-00215aee3ebe;Варварівка;Варваровка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524083403;3;1
44ac8872-749b-11df-b112-00215aee3ebe;Варварівка;Варваровка;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521981301;3;1
44ac8873-749b-11df-b112-00215aee3ebe;Варварівка;Варваровка;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823980901;3;1
44ac8874-749b-11df-b112-00215aee3ebe;Варварівка;Варваровка;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823681503;3;1
44ac8875-749b-11df-b112-00215aee3ebe;Варварівка;Варваровка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821887402;3;1
44ac8876-749b-11df-b112-00215aee3ebe;Варварівка;Варваровка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324887707;3;1
44ac8877-749b-11df-b112-00215aee3ebe;Варваро-Олександрівка;Варваро-Александровка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521755101;3;1
44ac8878-749b-11df-b112-00215aee3ebe;Варенка;Варенка;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823082903;3;1
62c3d149-749b-11df-b112-00215aee3ebe;Варжинка (Ленінка);Варжинка (Ленинка);село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521086806;3;1
44ac8879-749b-11df-b112-00215aee3ebe;Вари;Вары;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120481601;3;1
44ac887a-749b-11df-b112-00215aee3ebe;Вариводки;Вариводки;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820386503;3;1
44ac887b-749b-11df-b112-00215aee3ebe;Варівськ;Варовск;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222080601;3;1
44ac887c-749b-11df-b112-00215aee3ebe;Варівці;Варовцы;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821281001;3;1
44ac887d-749b-11df-b112-00215aee3ebe;Варковичі;Варковичи;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621680801;3;1
44ac887e-749b-11df-b112-00215aee3ebe;Варламівка;Варламовка;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955104;3;1
44ac887f-749b-11df-b112-00215aee3ebe;Варламівка;Варламовка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520880802;3;1
44ac8880-749b-11df-b112-00215aee3ebe;Варовичі;Варовичи;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221480401;3;1
325c57ef-ddd2-11df-9197-00215aee3ebe;Варовичі;Варовичи;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;a8bc35df-4aa1-11e3-9df6-003048d2b473;;3;1
44ac8881-749b-11df-b112-00215aee3ebe;Вартиківці;Вартиковцы;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322082001;3;1
44ac8882-749b-11df-b112-00215aee3ebe;Варюшине;Варюшино;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821780201;3;1
44ac8883-749b-11df-b112-00215aee3ebe;Варяж;Варяж;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624883901;3;1
44ac8884-749b-11df-b112-00215aee3ebe;Василева Гута;Василева Гута;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425580803;3;1
44ac8885-749b-11df-b112-00215aee3ebe;Василенки;Василенки;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322480702;3;1
44ac8886-749b-11df-b112-00215aee3ebe;Василенкове;Василенково;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325783001;3;1
44ac8887-749b-11df-b112-00215aee3ebe;Василе-Устимівка;Василе-Устимовка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321384402;3;1
44ac8888-749b-11df-b112-00215aee3ebe;Василець;Василец;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925680804;3;1
44ac8889-749b-11df-b112-00215aee3ebe;Василиха;Василиха;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224281601;3;1
44ac888a-749b-11df-b112-00215aee3ebe;Василів;Василев;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321581901;3;1
44ac888b-749b-11df-b112-00215aee3ebe;Василів;Василев;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220410501;2;1
44ac888c-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324580801;3;1
44ac888d-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321055101;3;1
44ac888e-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324080501;3;1
44ac888f-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325480301;3;1
44ac8890-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322080801;3;1
44ac8891-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321880901;3;1
44ac8892-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924486302;3;1
44ac8893-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924182601;3;1
44ac8894-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922981601;3;1
44ac8895-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223281001;3;1
44ac8896-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955106;3;1
44ac8897-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221085503;3;1
44ac8898-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221481003;2;1
44ac8899-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222081703;3;1
44ac889a-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055107;3;1
44ac889b-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224885002;3;1
44ac889c-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655111;3;1
44ac889d-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821180703;3;1
44ac889e-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822081703;3;1
44ac889f-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825781201;3;1
44ac88a0-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820955106;3;1
44ac88a1-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;;3;1
44ac88a2-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324081501;3;1
44ac88a5-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123381402;3;1
44ac88a6-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125280503;3;1
44ac88a7-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123580402;3;1
44ac88a8-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655105;3;1
44ac88a9-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121883202;3;1
44ac88aa-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121081001;3;1
44ac88ab-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123183702;3;1
44ac88ac-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;903ccab3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122380401;3;1
44ac88ad-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120484203;3;1
44ac88ae-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;6b07f8a2-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5121481001;3;1
44ac88af-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582003;3;1
4aba3780-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224980802;3;1
4aba3781-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822080901;3;1
4aba3782-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824780901;3;1
4aba3784-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521210101;3;1
4aba3785-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521083406;3;1
4aba3786-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524580801;3;1
4aba3787-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524381202;3;1
4aba3788-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;78f453a8-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0525082202;3;1
4aba3789-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523881303;3;1
4aba378a-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521781902;3;1
4aba378b-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520881601;3;1
4aba378c-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524680401;3;1
4aba378d-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521981901;3;1
4aba378e-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824780303;3;1
4aba37a0-749b-11df-b112-00215aee3ebe;Василівка;Василевка;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1223886002;3;1
4aba3e61-749b-11df-b112-00215aee3ebe;Василівка (Володимирівка);Василевка (Владимировка);село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921280802;3;1
4aba378f-749b-11df-b112-00215aee3ebe;Василівка Друга;Васильевка Вторая;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320482503;3;1
4aba3790-749b-11df-b112-00215aee3ebe;Василівка Перша;Васильевка Первая;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320482505;3;1
4aba3791-749b-11df-b112-00215aee3ebe;Василівка-на-Дніпрі;Василевка-на-Днепре;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224881203;3;1
4aba3793-749b-11df-b112-00215aee3ebe;Василівське;Василевское;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924482802;3;1
4aba3794-749b-11df-b112-00215aee3ebe;Василівське;Василевское;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324255301;3;1
4aba3795-749b-11df-b112-00215aee3ebe;Василівське;Василевское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321584003;3;1
4aba3796-749b-11df-b112-00215aee3ebe;Василівське;Василевское;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323987804;3;1
4aba3797-749b-11df-b112-00215aee3ebe;Василівщина;Василевщина;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920683003;3;1
4aba3798-749b-11df-b112-00215aee3ebe;Василяки;Василяки;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324586705;3;1
4aba3799-749b-11df-b112-00215aee3ebe;Васильки;Васильки;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825783902;3;1
4aba379a-749b-11df-b112-00215aee3ebe;Васильки;Васильки;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220887602;3;1
4aba379b-749b-11df-b112-00215aee3ebe;Васильки;Васильки;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322681501;3;1
4aba379c-749b-11df-b112-00215aee3ebe;Васильків;Васильков;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125585502;3;1
4aba379d-749b-11df-b112-00215aee3ebe;Васильків;Васильков;місто;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3210700000;2;0
4aba379e-749b-11df-b112-00215aee3ebe;Васильків;Васильков;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125781201;3;1
4aba379f-749b-11df-b112-00215aee3ebe;Васильківка;Васильковка;місто;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755100;2;1
4aba37a1-749b-11df-b112-00215aee3ebe;Васильківське;Васильковское;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223882502;3;1
4aba37a2-749b-11df-b112-00215aee3ebe;Васильківське;Васильковское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321587003;3;1
4aba37a3-749b-11df-b112-00215aee3ebe;Васильківці;Васильковцы;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121680401;3;1
4aba37a4-749b-11df-b112-00215aee3ebe;Васильківці;Васильковцы;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523486402;3;1
4aba37a5-749b-11df-b112-00215aee3ebe;Васильківці;Васильковцы;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825884802;3;1
4aba37a6-749b-11df-b112-00215aee3ebe;Васильківщина;Васильковщина;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922682202;3;1
4aba37a7-749b-11df-b112-00215aee3ebe;Василькове;Василькове;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321387802;3;1
4aba37a8-749b-11df-b112-00215aee3ebe;Василькове;Василькове;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323680402;3;1
4aba37aa-749b-11df-b112-00215aee3ebe;Васильці;Васильцы;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083705;3;1
4aba37ac-749b-11df-b112-00215aee3ebe;Васине;Васино;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522284202;3;1
4aba37ad-749b-11df-b112-00215aee3ebe;Васинівка;Васиновка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323987802;3;1
4aba37ae-749b-11df-b112-00215aee3ebe;Васищеве;Васищево;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325156400;2;0
4aba37af-749b-11df-b112-00215aee3ebe;Васловівці;Васлововцы;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321582201;3;1
4aba37b0-749b-11df-b112-00215aee3ebe;Васюків;Васюков;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425181002;3;1
4aba37b1-749b-11df-b112-00215aee3ebe;Васюківка;Васюковка;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223884802;3;1
4aba37b3-749b-11df-b112-00215aee3ebe;Васюківщина;Васюковщина;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786902;3;1
4aba37b4-749b-11df-b112-00215aee3ebe;Васюкове;Васюково;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320680203;3;1
4aba37b6-749b-11df-b112-00215aee3ebe;Васютинці;Васютинцы;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125180801;3;1
4aba37b7-749b-11df-b112-00215aee3ebe;Васютинці;Васютинцы;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520282806;3;1
4aba37b8-749b-11df-b112-00215aee3ebe;Васючин;Васючин;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624480601;3;1
4aba37b9-749b-11df-b112-00215aee3ebe;Васьки;Васьки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321355401;3;1
4aba37ba-749b-11df-b112-00215aee3ebe;Васьки;Васьки;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083203;3;1
4aba37bb-749b-11df-b112-00215aee3ebe;Васьківка;Васьковка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320687202;3;1
4aba37bc-749b-11df-b112-00215aee3ebe;Васьківці;Васьковцы;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125881501;3;1
4aba37bd-749b-11df-b112-00215aee3ebe;Васьківці;Васьковцы;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425182001;3;1
4aba37be-749b-11df-b112-00215aee3ebe;Васьківці;Васьковцы;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822182602;3;1
4aba37bf-749b-11df-b112-00215aee3ebe;Васьківчики;Васьковчики;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822780401;3;1
4aba37c0-749b-11df-b112-00215aee3ebe;Васьковичі;Васьковичи;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822380901;3;1
4aba37c1-749b-11df-b112-00215aee3ebe;Ватажкове;Ватажково;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081002;3;1
4aba37c2-749b-11df-b112-00215aee3ebe;Ватин;Ватин;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720881001;3;1
4aba37c3-749b-11df-b112-00215aee3ebe;Ватинець;Ватинец;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720881003;3;1
4aba37c4-749b-11df-b112-00215aee3ebe;Ватутіне;Ватутино;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324280301;3;1
4aba37c6-749b-11df-b112-00215aee3ebe;Вахівка;Ваховка;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221881201;3;1
4aba37c7-749b-11df-b112-00215aee3ebe;Вахнівка;Вахновка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522280601;3;1
4aba37c8-749b-11df-b112-00215aee3ebe;Вахнівці;Вахновцы;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320584002;3;1
4aba37c9-749b-11df-b112-00215aee3ebe;Вахнівці;Вахновцы;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823381501;3;1
4aba37cb-749b-11df-b112-00215aee3ebe;Вахутинці;Вахутинцы;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222981202;3;1
4aba37cc-749b-11df-b112-00215aee3ebe;Ваці;Вацы;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083904;3;1
4aba37cd-749b-11df-b112-00215aee3ebe;Вачів;Вачев;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823987702;3;1
4aba37ce-749b-11df-b112-00215aee3ebe;Вашківці;Вашковцы;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7320510400;3;0
4aba37cf-749b-11df-b112-00215aee3ebe;Вашківці;Вашковцы;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324082001;3;1
4aba37d0-749b-11df-b112-00215aee3ebe;Введенка;Введенка;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325455300;3;1
4aba37d1-749b-11df-b112-00215aee3ebe;Введенка;Введенка;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124580501;3;1
4aba37d2-749b-11df-b112-00215aee3ebe;Вдовичене;Вдовичино;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125482003;3;1
4aba37d3-749b-11df-b112-00215aee3ebe;Вдовичине;Вдовичино;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323280602;3;1
4aba37d4-749b-11df-b112-00215aee3ebe;Вегерівка;Вегеровка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923882302;3;1
4aba37d6-749b-11df-b112-00215aee3ebe;Ведильці;Ведильцы;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425581301;3;1
4aba37d7-749b-11df-b112-00215aee3ebe;Ведмедівка;Ведмедевка;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222981502;3;1
4aba37d8-749b-11df-b112-00215aee3ebe;Ведмедівка;Ведмедевка;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423881003;3;1
4aba37d9-749b-11df-b112-00215aee3ebe;Ведмедівка;Ведмедевка;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620484002;3;1
4aba37da-749b-11df-b112-00215aee3ebe;Ведмежа;Ведмежа;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523085401;3;1
4aba37db-749b-11df-b112-00215aee3ebe;Ведмеже;Медвежье;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924188502;3;1
4aba37dc-749b-11df-b112-00215aee3ebe;Вежиця;Вежица;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625082201;3;1
4aba37dd-749b-11df-b112-00215aee3ebe;Везденьки;Везденьки;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825081101;3;1
4aba37de-749b-11df-b112-00215aee3ebe;Велень;Велень;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386013;3;1
4aba37df-749b-11df-b112-00215aee3ebe;Велеснів;Велеснев;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124280401;3;1
4aba37e0-749b-11df-b112-00215aee3ebe;Велетенське;Велетенское;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520382503;3;1
4aba37e3-749b-11df-b112-00215aee3ebe;Велика Андріївка;Великая Андреевка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320483502;3;1
4aba37e5-749b-11df-b112-00215aee3ebe;Велика Андрусівка;Большая Андрусовка;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525280501;3;1
4aba37e6-749b-11df-b112-00215aee3ebe;Велика Бабка;Большая Бабка;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325482001;3;1
4aba37e7-749b-11df-b112-00215aee3ebe;Велика Багачка;Большая Багачка;місто;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320255100;3;1
4aba37e8-749b-11df-b112-00215aee3ebe;Велика Бакта;Большая Бакта;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120483301;3;1
4aba37e9-749b-11df-b112-00215aee3ebe;Велика Балка;Большая Балка;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121084202;3;1
4aba37ea-749b-11df-b112-00215aee3ebe;Велика Безуглівка;Великая Безугловка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322055501;3;1
4aba37eb-749b-11df-b112-00215aee3ebe;Велика Березна;Большая Березна;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823681501;3;1
4aba37ec-749b-11df-b112-00215aee3ebe;Велика Березовиця;Большая Березовица;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125255200;2;0
4aba37ed-749b-11df-b112-00215aee3ebe;Велика Березянка;Большая Березянка;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224480501;3;1
4aba37ee-749b-11df-b112-00215aee3ebe;Велика Берізка;Большая Березка;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924480401;3;1
4aba37ef-749b-11df-b112-00215aee3ebe;Велика Бийгань;Большая Быйгань;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120482001;3;1
4aba37f0-749b-11df-b112-00215aee3ebe;Велика Білина;Большая Белина;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624281201;3;1
4aba37f1-749b-11df-b112-00215aee3ebe;Велика Білка;Великая Белка;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123884403;3;1
6b733cb3-db5b-11df-9197-00215aee3ebe;Велика Боровиця;Большая Боровица;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820380301;3;1
4aba37f5-749b-11df-b112-00215aee3ebe;Велика Бугаївка;Велика Бугаевка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221480501;3;1
4aba37f6-749b-11df-b112-00215aee3ebe;Велика Буда;Большая Буда;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320780801;3;1
4aba37f7-749b-11df-b112-00215aee3ebe;Велика Бузова;Большая Бузова;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755110;3;1
4aba37f8-749b-11df-b112-00215aee3ebe;Велика Бурімка;Большая Буромка;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125181201;3;1
4aba37f9-749b-11df-b112-00215aee3ebe;Велика Бутівка;Великая Бутовка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924181703;3;1
4aba37fa-749b-11df-b112-00215aee3ebe;Велика Бушинка;Большая Бушинка;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523080801;3;1
4aba37fb-749b-11df-b112-00215aee3ebe;Велика Василівка;Великая Василевка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123382902;3;1
4aba37fc-749b-11df-b112-00215aee3ebe;Велика Ведмежка;Большая Ведмежка;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723680901;3;1
4aba37fd-749b-11df-b112-00215aee3ebe;Велика Виска;Большая Виска;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523181201;3;1
4aba37fe-749b-11df-b112-00215aee3ebe;Велика Вільшаниця;Большая Ольшаница;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621881501;3;1
4aba37ff-749b-11df-b112-00215aee3ebe;Велика Вільшанка;Большая Ольшанка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221480701;3;1
4aba3800-749b-11df-b112-00215aee3ebe;Велика Вісь;Большая Весь;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424480401;3;1
4aba3801-749b-11df-b112-00215aee3ebe;Велика Вовнянка;Большая Вовнянка;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224481001;3;1
4aba3802-749b-11df-b112-00215aee3ebe;Велика Волиця;Большая Волица;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823181501;3;1
4aba3803-749b-11df-b112-00215aee3ebe;Велика Волосянка;Великая Волосянка;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625188702;3;1
4aba3804-749b-11df-b112-00215aee3ebe;Велика Воля;Великая Воля;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623088403;3;1
4aba3805-749b-11df-b112-00215aee3ebe;Велика Вулига;Большая Вулыга;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524581301;3;1
4aba3806-749b-11df-b112-00215aee3ebe;Велика Глумча;Большая Глумча;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821781801;3;1
4aba3807-749b-11df-b112-00215aee3ebe;Велика Глуша;Большая Глуша;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723182601;3;1
4aba3808-749b-11df-b112-00215aee3ebe;Велика Гомільша;Большая Гомольша;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321781501;3;1
4aba3809-749b-11df-b112-00215aee3ebe;Велика Горбаша;Большая Горбаша;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824080601;3;1
4aba380a-749b-11df-b112-00215aee3ebe;Велика Горбаша;Большая Горбаша;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825681001;3;1
4aba380b-749b-11df-b112-00215aee3ebe;Велика Городниця;Большая Городница;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623889803;3;1
4aba380c-749b-11df-b112-00215aee3ebe;Велика Горожанка;Большая Горожанка;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623081601;3;1
4aba380d-749b-11df-b112-00215aee3ebe;Велика Горянка;Великая Горянка;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123481501;3;1
2c07c8ec-8d88-11e7-80ca-1c98ec135261;Велика Грабівниця;Большая Грабовница;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;;3;1
4aba380e-749b-11df-b112-00215aee3ebe;Велика Грем'яча;Великая Гремячая;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323286002;3;1
4aba380f-749b-11df-b112-00215aee3ebe;Велика Губщина;Великая Губщина;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321284007;3;1
4aba3810-749b-11df-b112-00215aee3ebe;Велика Данилівка;Большая Даниловка;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
4aba3811-749b-11df-b112-00215aee3ebe;Велика Деражня;Большая Деражня;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824085202;3;1
4aba3812-749b-11df-b112-00215aee3ebe;Велика Димерка;Великая Дымерка;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221255200;2;0
4aba3813-749b-11df-b112-00215aee3ebe;Велика Діброва;Великая Диброва;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923585703;3;1
4aba3814-749b-11df-b112-00215aee3ebe;Велика Діброва;Великая Диброва;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622482002;3;1
4aba3815-749b-11df-b112-00215aee3ebe;Велика Дівиця;Большая Девица;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424181901;3;1
4aba3816-749b-11df-b112-00215aee3ebe;Велика Добронь;Большая Добронь;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124880901;2;1
4aba3817-749b-11df-b112-00215aee3ebe;Велика Дорога;Большая Дорога;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423381201;3;1
4aba3818-749b-11df-b112-00215aee3ebe;Велика Доч;Большая Дочь;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420880601;3;1
4aba3819-749b-11df-b112-00215aee3ebe;Велика Загорівка;Великая Загоровка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420880801;3;1
4aba381a-749b-11df-b112-00215aee3ebe;Велика Зелена;Великая Зеленая;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825289402;2;1
4aba381c-749b-11df-b112-00215aee3ebe;Велика іловиця;Большая Иловица;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125883501;3;1
4aba381d-749b-11df-b112-00215aee3ebe;Велика Кадигробівка;ВеликаяКадыгробовка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321284504;3;1
4aba381e-749b-11df-b112-00215aee3ebe;Велика Калинівка;Великая Калиновка;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825085102;3;1
4aba381f-749b-11df-b112-00215aee3ebe;Велика Калинка;Великая Калинка;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620983904;3;1
4aba3820-749b-11df-b112-00215aee3ebe;Велика Кам'янка;Большая Каменка;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623280201;3;1
4aba3821-749b-11df-b112-00215aee3ebe;Велика Каратуль;Большая Каратуль;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223380401;3;1
4aba3823-749b-11df-b112-00215aee3ebe;Велика Киріївка;Большая Киреевка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520480903;3;1
4aba3824-749b-11df-b112-00215aee3ebe;Велика Кіндратівка;Великая Кондратовка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122982502;3;1
4aba3825-749b-11df-b112-00215aee3ebe;Велика Кісниця;Большая Косница;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525681301;3;1
4aba3826-749b-11df-b112-00215aee3ebe;Велика Клецька;Большая Клецка;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623080801;3;1
4aba3827-749b-11df-b112-00215aee3ebe;Велика Клітна;Великая Клетна;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822787002;3;1
4aba3828-749b-11df-b112-00215aee3ebe;Велика Козара;Большая Козара;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821480801;3;1
4aba382a-749b-11df-b112-00215aee3ebe;Велика Комишуваха;Большая Камышеваха;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320481001;3;1
4aba382b-749b-11df-b112-00215aee3ebe;Велика Копаня;Большая Копаня;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121281501;3;0
4aba382c-749b-11df-b112-00215aee3ebe;Велика Корениха;Большая Корениха;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;3;1
4aba382d-749b-11df-b112-00215aee3ebe;Велика Костромка;Большая Костромка;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220310302;3;1
4aba382e-749b-11df-b112-00215aee3ebe;Велика Кошелівка;Большая Кошелевка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423381701;3;1
4aba382f-749b-11df-b112-00215aee3ebe;Велика Круча;Большая Круча;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323881001;3;1
4aba3830-749b-11df-b112-00215aee3ebe;Велика Кужелева;Большая Кужелева;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821880701;3;1
4aba3831-749b-11df-b112-00215aee3ebe;Велика Купля;Великая Купля;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620482202;3;1
4aba3832-749b-11df-b112-00215aee3ebe;Велика Левада;Большая Левада;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821283902;3;1
4aba3834-749b-11df-b112-00215aee3ebe;Велика Лінина;Большая Линина;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625180601;3;1
4aba3835-749b-11df-b112-00215aee3ebe;Велика Лука;Большая Лука;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923284402;3;1
4aba3836-749b-11df-b112-00215aee3ebe;Велика Лука;Большая Лука;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125282201;2;1
4aba3837-749b-11df-b112-00215aee3ebe;Велика Любаша;Большая Любаша;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623486902;3;1
4aba3839-749b-11df-b112-00215aee3ebe;Велика Медведівка;Большая Медведевка;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825589002;3;1
4aba383a-749b-11df-b112-00215aee3ebe;Велика Медведівка;Большая Медведевка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822781101;3;1
4aba383b-749b-11df-b112-00215aee3ebe;Велика Мечетня;Большая Мечетня;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823981001;3;1
4aba383c-749b-11df-b112-00215aee3ebe;Велика Михайлівка;Большая Михайловка;місто;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655100;3;1
4aba383d-749b-11df-b112-00215aee3ebe;Велика Мотовилівка;Великая Мотовиловка;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224987202;3;1
4aba383e-749b-11df-b112-00215aee3ebe;Велика Мочулка;Большая Мочулка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523781301;3;1
4aba3840-749b-11df-b112-00215aee3ebe;Велика Обухівка;Большая Обуховка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323280701;3;1
4aba3841-749b-11df-b112-00215aee3ebe;Велика Озимина;Великая Озимина;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624281301;3;1
4aba3842-749b-11df-b112-00215aee3ebe;Велика Олександрівка;Великая Александровка;місто;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520955100;2;1
4aba3843-749b-11df-b112-00215aee3ebe;Велика Олександрівка;Великая Александровка;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220880901;2;0
4aba3844-749b-11df-b112-00215aee3ebe;Велика Омеляна;Большая Омеляна;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624682001;3;1
4aba3845-749b-11df-b112-00215aee3ebe;Велика Осниця;Большая Осница;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723681101;3;1
4aba3846-749b-11df-b112-00215aee3ebe;Велика Офірна;Великая Офирна;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224984202;3;1
4aba384d-749b-11df-b112-00215aee3ebe;Велика П'ятигірка;Большая Пятигорка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820881201;3;1
4aba3847-749b-11df-b112-00215aee3ebe;Велика Павлівка;Большая Павловка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321381401;3;1
4aba3848-749b-11df-b112-00215aee3ebe;Велика Паладь;Большая Паладь;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121281201;3;1
4aba3849-749b-11df-b112-00215aee3ebe;Велика Писарівка;Большая Писаревка;місто;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921255100;3;1
4aba384a-749b-11df-b112-00215aee3ebe;Велика Плавуча;Большая Плавуча;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123080801;3;1
4aba384b-749b-11df-b112-00215aee3ebe;Велика Побійна;Велика Побыйна;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821880901;3;1
4aba384c-749b-11df-b112-00215aee3ebe;Велика Пожарня;ВеликаяПожарня;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321384002;3;1
4aba384e-749b-11df-b112-00215aee3ebe;Велика Радогощ;Большая Радогощ;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822184502;3;1
4aba384f-749b-11df-b112-00215aee3ebe;Велика Рача;Большая Рача;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825080901;3;1
4aba3850-749b-11df-b112-00215aee3ebe;Велика Рибиця;Большая Рыбица;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922381602;3;1
4aba3851-749b-11df-b112-00215aee3ebe;Велика Рішнівка;Большая Решневка;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825580501;3;1
4aba3852-749b-11df-b112-00215aee3ebe;Велика Рогозянка;Большая Рогозянка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322681501;3;1
4aba3853-749b-11df-b112-00215aee3ebe;Велика Розтока;Великая Ростока;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121982802;3;1
4aba3854-749b-11df-b112-00215aee3ebe;Велика Ростівка;Большая Ростовка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523180801;3;1
4aba3855-749b-11df-b112-00215aee3ebe;Велика Рублівка;Большая Рублевка;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322280801;3;1
4aba3856-749b-11df-b112-00215aee3ebe;Велика Рудка;Большая Рудка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321082001;3;1
4aba3857-749b-11df-b112-00215aee3ebe;Велика Русава;Большая Русава;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523980901;3;1
4aba3858-749b-11df-b112-00215aee3ebe;Велика Салиха;Большая Салиха;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822781401;3;1
4aba3859-749b-11df-b112-00215aee3ebe;Велика Севастянівка;Большая Севастьяновка;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124681001;3;1
4aba385a-749b-11df-b112-00215aee3ebe;Велика Северинка;Большая Северинка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522581201;3;1
4aba385b-749b-11df-b112-00215aee3ebe;Велика Скельова;Большая Скелевая;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525280701;3;1
4aba385c-749b-11df-b112-00215aee3ebe;Велика Слобідка;Большая Слободка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822489103;3;1
4aba385d-749b-11df-b112-00215aee3ebe;Велика Снітинка;Большая Снетинка;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224981201;3;1
62c3cf24-749b-11df-b112-00215aee3ebe;Велика Солона (Куйбишевка);Большая Солона (Куйбышевка);село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823082201;3;1
4aba385e-749b-11df-b112-00215aee3ebe;Велика Солтанівка;Большая Солтановка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221480901;3;1
4aba385f-749b-11df-b112-00215aee3ebe;Велика Стариця;Великая Старица;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220886703;3;1
4aba3860-749b-11df-b112-00215aee3ebe;Велика Стратіївка;Большая Стратиевка;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524181502;3;1
4aba3861-749b-11df-b112-00215aee3ebe;Велика Сушиця;Большая Сушица;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625181101;3;1
4aba3863-749b-11df-b112-00215aee3ebe;Велика Тур'я;Большая Турья;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622080201;3;1
73e94bf7-ddc1-11df-9197-00215aee3ebe;Велика Уголька;Большая Уголька;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124480601;3;1
4aba3864-749b-11df-b112-00215aee3ebe;Велика Фосня;Большая Фосня;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824280901;3;1
4aba3865-749b-11df-b112-00215aee3ebe;Велика Хайча;Большая Хайча;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824281101;3;1
4aba3866-749b-11df-b112-00215aee3ebe;Велика Хвороща;Великая Хвороща;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624281202;3;1
4aba3867-749b-11df-b112-00215aee3ebe;Велика Цвіля;Большая Цвиля;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821782001;3;1
4aba3868-749b-11df-b112-00215aee3ebe;Велика Чернеччина;Большая Чернеччина;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924782201;2;1
4aba386a-749b-11df-b112-00215aee3ebe;Велика Чернігівка;Большая Черниговка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824281301;3;1
4aba386b-749b-11df-b112-00215aee3ebe;Велика Чернявка;Великая Чернявка;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824781802;3;1
4aba386c-749b-11df-b112-00215aee3ebe;Велика Чечеліївка;Большая Чечелиевка;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523455101;3;1
4aba386f-749b-11df-b112-00215aee3ebe;Велика Шкарівка;Большая Шкаровка;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825580801;3;1
4aba3870-749b-11df-b112-00215aee3ebe;Велика Яблунівка;Большая Яблоновка;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123781501;3;1
4aba3871-749b-11df-b112-00215aee3ebe;Велика Яблунька;Большая Яблонька;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723682202;3;1
4aba3872-749b-11df-b112-00215aee3ebe;Велика Яромирка;Большая Яромырка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821281601;3;1
4aba3873-749b-11df-b112-00215aee3ebe;Велике;Великое;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924185002;3;1
4aba3874-749b-11df-b112-00215aee3ebe;Велике;Великое;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320585002;3;1
4aba3875-749b-11df-b112-00215aee3ebe;Велике;Великое;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624885502;3;1
4aba3876-749b-11df-b112-00215aee3ebe;Велике;Великое;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625182902;3;1
4aba3878-749b-11df-b112-00215aee3ebe;Велике;Великое;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521480806;3;1
4aba3879-749b-11df-b112-00215aee3ebe;Велике;Великое;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623886702;3;1
4aba387a-749b-11df-b112-00215aee3ebe;Велике;Великое;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320484702;3;1
4aba387e-749b-11df-b112-00215aee3ebe;Велике Артакове;Большое Артаково;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821183003;3;1
4aba387f-749b-11df-b112-00215aee3ebe;Велике Бокове;Большое Боково;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123380902;3;1
4aba3880-749b-11df-b112-00215aee3ebe;Велике Болото;Большое Болото;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323483402;3;1
4aba3881-749b-11df-b112-00215aee3ebe;Велике Бурилове;Большое Бурилово;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122983002;3;1
4aba387b-749b-11df-b112-00215aee3ebe;Велике Вербче;Большое Вербче;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625480501;3;1
4aba3882-749b-11df-b112-00215aee3ebe;Велике Колодне;Большое Колодное;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622181201;3;1
4aba3884-749b-11df-b112-00215aee3ebe;Велике Озеро;Большое Озеро;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5910290001;3;1
4aba3886-749b-11df-b112-00215aee3ebe;Велике Передмістя;Большое Предместье;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622755903;3;1
4aba387d-749b-11df-b112-00215aee3ebe;Велике Поле;Большое Поле;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620482201;3;1
4aba3888-749b-11df-b112-00215aee3ebe;Велике Устя;Большое Устье;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424981501;3;1
4aba388b-749b-11df-b112-00215aee3ebe;Великий Байрак;Большой Байрак;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323280401;3;1
4aba388c-749b-11df-b112-00215aee3ebe;Великий Березний;Великий Березный;місто;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120855100;3;1
4aba388d-749b-11df-b112-00215aee3ebe;Великий Бичків;Большой Бычков;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123655500;3;0
4aba388e-749b-11df-b112-00215aee3ebe;Великий Бобрик;Большой Бобрик;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922380801;3;1
4aba388f-749b-11df-b112-00215aee3ebe;Великий Браталів;Большой Браталов;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823181201;3;1
4aba3890-749b-11df-b112-00215aee3ebe;Великий Букрин;Большой Букрин;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222984002;3;1
44ac8479-749b-11df-b112-00215aee3ebe;Великий Буялик ( Благоєве );Благоево;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121881001;3;1
4aba3893-749b-11df-b112-00215aee3ebe;Великий Вистороп;Большой Выстороп;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982001;3;1
4aba3894-749b-11df-b112-00215aee3ebe;Великий Гай;Большой Гай;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423682002;3;1
4aba3895-749b-11df-b112-00215aee3ebe;Великий Глибочок;Большой Глубочек;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125281901;2;1
4aba3896-749b-11df-b112-00215aee3ebe;Великий Говилів;Большой Говилов;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125080901;3;1
4aba3897-749b-11df-b112-00215aee3ebe;Великий Дальник;Большой Дальник;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121081401;3;1
4aba3898-749b-11df-b112-00215aee3ebe;Великий Дивлин;Большой Дивлин;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822881301;3;1
4aba3899-749b-11df-b112-00215aee3ebe;Великий Дирчин;Большой Дирчин;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421481801;3;1
4aba389a-749b-11df-b112-00215aee3ebe;Великий Дорошів;Большой Дорошев;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622781001;3;1
4aba389b-749b-11df-b112-00215aee3ebe;Великий Жванчик;Большой Жванчик;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821880601;3;1
4aba389c-749b-11df-b112-00215aee3ebe;Великий Житин;Большой Житин;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624681501;3;1
4aba389d-749b-11df-b112-00215aee3ebe;Великий Жолудськ;Большой Жолудск;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620881501;3;1
4aba389e-749b-11df-b112-00215aee3ebe;Великий Зліїв;Большой Злиев;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424480801;3;1
4aba389f-749b-11df-b112-00215aee3ebe;Великий Карабчіїв;Большой Карабчиев;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821281301;3;1
4aba38a0-749b-11df-b112-00215aee3ebe;Великий Карашин;Большой Карашин;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222780901;3;1
4aba38a2-749b-11df-b112-00215aee3ebe;Великий Ключів;Большой Ключев;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623280401;3;1
4aba38a3-749b-11df-b112-00215aee3ebe;Великий Кобелячок;Большой Кобелячек;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323480401;3;1
4aba38a4-749b-11df-b112-00215aee3ebe;Великий Кобилин;Большой Кобылин;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824281102;3;1
4aba38a5-749b-11df-b112-00215aee3ebe;Великий Крупіль;Большой Крупиль;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221981001;3;1
4aba38a6-749b-11df-b112-00215aee3ebe;Великий Кунинець;Большой Кунинец;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122481001;3;1
4aba38a7-749b-11df-b112-00215aee3ebe;Великий Курінь;Большой Курень;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155108;3;1
4aba38a8-749b-11df-b112-00215aee3ebe;Великий Кучурів;Большой Кучуров;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7324582001;3;0
4aba38a9-749b-11df-b112-00215aee3ebe;Великий Лазучин;Большой Лазучин;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824780501;3;1
4aba38aa-749b-11df-b112-00215aee3ebe;Великий Липовець;Большой Липовец;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323582002;3;1
4aba38ab-749b-11df-b112-00215aee3ebe;Великий Листвен;Большой Листвен;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421482001;3;1
4aba38ac-749b-11df-b112-00215aee3ebe;Великий Ліс;Большой Лес;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925383006;3;1
4aba38ad-749b-11df-b112-00215aee3ebe;Великий Ліс;Большой Лес;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923282602;3;1
4aba38ae-749b-11df-b112-00215aee3ebe;Великий Ліс;Большой Лес;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221085902;3;1
4aba38af-749b-11df-b112-00215aee3ebe;Великий Ліс;Большой Лес;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822883002;3;1
4aba38b0-749b-11df-b112-00215aee3ebe;Великий Ліс;Большой Лес;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822383202;3;1
4aba38b1-749b-11df-b112-00215aee3ebe;Великий Ліс;Большой Лес;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422284002;3;1
4aba38b3-749b-11df-b112-00215aee3ebe;Великий Луг;Большой Луг;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825480801;3;1
4aba38b4-749b-11df-b112-00215aee3ebe;Великий Любінь;Большой Любень;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620955300;3;1
4aba38b5-749b-11df-b112-00215aee3ebe;Великий Мидськ;Большой Мидск;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623480401;3;1
4aba38b6-749b-11df-b112-00215aee3ebe;Великий Митник;Большой Мытник;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524880601;3;1
4aba38b7-749b-11df-b112-00215aee3ebe;Великий Молодьків;Большой Молодьков;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824080801;3;1
4aba38b8-749b-11df-b112-00215aee3ebe;Великий Обзир;Большой Обзыр;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721481001;3;1
4aba38b9-749b-11df-b112-00215aee3ebe;Великий Окорськ;Большой Окорск;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722482402;3;1
4aba38ba-749b-11df-b112-00215aee3ebe;Великий Олександрів;Большой Александров;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820681001;3;1
4aba38bb-749b-11df-b112-00215aee3ebe;Великий Олексин;Большой Алексин;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624689502;3;1
4aba38bc-749b-11df-b112-00215aee3ebe;Великий Омеляник;Большой Омеляник;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881803;2;0
4aba38bd-749b-11df-b112-00215aee3ebe;Великий Остріжок;Большой Острожок;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524880901;3;1
4aba38be-749b-11df-b112-00215aee3ebe;Великий Перевіз;Большой Перевоз;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755111;3;1
4aba38bf-749b-11df-b112-00215aee3ebe;Великий Полюхів;Большой Полюхов;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621881601;3;1
4aba38c0-749b-11df-b112-00215aee3ebe;Великий Порськ;Большой Порск;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155309;3;1
4aba38c1-749b-11df-b112-00215aee3ebe;Великий Правутин;Большой Правутин;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823981201;3;1
4aba38c2-749b-11df-b112-00215aee3ebe;Великий Прикіл;Большой Прикол;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922382502;3;1
4aba38c3-749b-11df-b112-00215aee3ebe;Великий Раковець;Большой Раковец;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121982001;3;1
4aba38c4-749b-11df-b112-00215aee3ebe;Великий Рожин;Большой Рожин;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;2623682001;3;1
4aba38c5-749b-11df-b112-00215aee3ebe;Великий Самбір;Большой Самбор;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922080801;3;1
4aba38c6-749b-11df-b112-00215aee3ebe;Великий Скнит;Большой Скнит;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823981501;3;1
4aba38c7-749b-11df-b112-00215aee3ebe;Великий Степ;Большая Степь;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521485406;3;1
4aba38c8-749b-11df-b112-00215aee3ebe;Великий Стидин;Большой Стыдин;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623480601;3;1
4aba38ca-749b-11df-b112-00215aee3ebe;Великий Тростянець;Большой Тростянец;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086201;3;1
4aba38cb-749b-11df-b112-00215aee3ebe;Великий Фонтан;Большой Фонтан;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122981301;3;1
4aba38cc-749b-11df-b112-00215aee3ebe;Великий Ходак;Большой Ходак;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620884103;3;1
4aba38cd-749b-11df-b112-00215aee3ebe;Великий Ходачків;Большой Ходачков;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123081101;3;1
4aba38ce-749b-11df-b112-00215aee3ebe;Великий Хутір;Большой Хутор;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120682601;3;1
4aba38cf-749b-11df-b112-00215aee3ebe;Великий Чемерель;Великий Чемерель;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621881303;3;1
200d986a-ccc5-11e0-ad66-003048d2b473;Великий Черемель;Большой Черемель;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621881303;3;1
4aba38d0-749b-11df-b112-00215aee3ebe;Великий Чернятин;Большой Чернятин;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824281301;3;1
4aba38d1-749b-11df-b112-00215aee3ebe;Великий Шпаків;Большой Шпаков;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624686702;3;1
4aba38d2-749b-11df-b112-00215aee3ebe;Великий Щимель;Большой Щимель;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425880501;3;1
4aba38d3-749b-11df-b112-00215aee3ebe;Великий Яблунець;Большой Яблонец;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821782201;3;1
4aba38d4-749b-11df-b112-00215aee3ebe;Великий Яр;Большой Яр;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924782002;3;1
4aba38e5-749b-11df-b112-00215aee3ebe;Великі Береги;Великие Берега;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120480801;3;1
4aba38e6-749b-11df-b112-00215aee3ebe;Великі Бережці;Великие Бережцы;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123481401;3;1
4aba38d5-749b-11df-b112-00215aee3ebe;Великі Бірки;Великие Борки;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125255400;2;1
4aba38d6-749b-11df-b112-00215aee3ebe;Великі Бубни;Великие Бубны;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924183201;3;1
4aba38d7-749b-11df-b112-00215aee3ebe;Великі Будища;Великие Будища;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320481401;3;1
4aba38e7-749b-11df-b112-00215aee3ebe;Великі Будища;Великие Будища;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321081701;3;1
4aba38e8-749b-11df-b112-00215aee3ebe;Великі Будки;Великие Будки;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923580301;3;1
4aba38d8-749b-11df-b112-00215aee3ebe;Великі Будки;Великие Будки;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924181704;3;1
4aba38e9-749b-11df-b112-00215aee3ebe;Великі Бучки;Великие Бучки;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324881301;3;1
4aba38ea-749b-11df-b112-00215aee3ebe;Великі Вікнини;Великие Окнины;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122481201;3;1
4aba38eb-749b-11df-b112-00215aee3ebe;Великі Вільми;Великие Вильмы;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924782001;3;1
4aba38ec-749b-11df-b112-00215aee3ebe;Великі Гадомці;Великие Гадомцы;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820885602;3;1
4aba38d9-749b-11df-b112-00215aee3ebe;Великі Гаї;Великие Гаи;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125281701;2;0
4aba38ed-749b-11df-b112-00215aee3ebe;Великі Геївці;Великие Геевцы;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124880601;3;1
4aba38ee-749b-11df-b112-00215aee3ebe;Великі Глібовичі;Великие Глебовичи;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623381801;3;1
4aba38ef-749b-11df-b112-00215aee3ebe;Великі Гори;Великие Горы;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625885202;3;1
4aba38f0-749b-11df-b112-00215aee3ebe;Великі Грибовичі;Великие Грибовичи;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622782301;3;0
4aba38f1-749b-11df-b112-00215aee3ebe;Великі Гуляки;Великие Гуляки;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224980801;3;1
4aba38da-749b-11df-b112-00215aee3ebe;Великі Дедеркали;Великие Дедеркалы;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125881801;3;1
4aba38f2-749b-11df-b112-00215aee3ebe;Великі Деревичі;Великие Деревичи;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823181801;3;1
4aba38db-749b-11df-b112-00215aee3ebe;Великі Дідушичі;Великие Дедушичи;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625380801;3;1
4aba38f3-749b-11df-b112-00215aee3ebe;Великі Дмитровичі;Великие Дмитровичи;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223180501;3;1
4aba38f4-749b-11df-b112-00215aee3ebe;Великі Долини;Великие Долины;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787603;3;1
4aba38f5-749b-11df-b112-00215aee3ebe;Великі Єрчики;Великие Ерчики;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224080901;2;1
4aba38f6-749b-11df-b112-00215aee3ebe;Великі Жеребки;Великие Жеребки;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820986202;3;1
4aba38dc-749b-11df-b112-00215aee3ebe;Великі Загайці;Великие Загайцы;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125882101;3;1
4aba38f7-749b-11df-b112-00215aee3ebe;Великі Загірці;Великие Загорцы;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621689103;3;1
4aba38dd-749b-11df-b112-00215aee3ebe;Великі Зозулинці;Великие Зозулинцы;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822780801;3;1
4aba38f8-749b-11df-b112-00215aee3ebe;Великі Каленичі;Великие Каленичи;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823682001;3;1
4aba38f9-749b-11df-b112-00215aee3ebe;Великі Калетинці;Великие Калетинцы;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820389503;3;1
4aba38fa-749b-11df-b112-00215aee3ebe;Великі Канівці;Великие Каневцы;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125181601;3;1
4aba38fb-749b-11df-b112-00215aee3ebe;Великі Ком'яти;Великие Комяты;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121280901;3;0
4aba38fc-749b-11df-b112-00215aee3ebe;Великі Коровинці;Великие Коровинцы;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825855300;3;1
4aba38fd-749b-11df-b112-00215aee3ebe;Великі Кошарища;Великие Кошарища;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822583203;3;1
4aba38fe-749b-11df-b112-00215aee3ebe;Великі Кринки;Великие Крынки;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320684601;3;1
4aba38ff-749b-11df-b112-00215aee3ebe;Великі Крушлинці;Великие Крушлинцы;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520680803;3;1
4aba3900-749b-11df-b112-00215aee3ebe;Великі Кусківці;Великие Кусковцы;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123882101;3;1
4aba3901-749b-11df-b112-00215aee3ebe;Великі Кутища;Великие Кутыща;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521684406;3;1
4aba3902-749b-11df-b112-00215aee3ebe;Великі Лази;Великие Лазы;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124880305;3;1
4aba3903-749b-11df-b112-00215aee3ebe;Великі Ламзаки;Великие Ламзаки;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122786902;3;1
4aba38df-749b-11df-b112-00215aee3ebe;Великі Липняги;Великие Липняги;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324555104;3;1
4aba3904-749b-11df-b112-00215aee3ebe;Великі Лісівці;Великие Лесовцы;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824781201;3;1
4aba3905-749b-11df-b112-00215aee3ebe;Великі Луки;Великие Луки;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922984602;3;1
4aba3906-749b-11df-b112-00215aee3ebe;Великі Лучки;Великие Лучки;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122781201;3;1
4aba3907-749b-11df-b112-00215aee3ebe;Великі Макари;Великие Макары;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625855901;3;1
4aba3908-749b-11df-b112-00215aee3ebe;Великі Мацевичі;Великие Мацевичи;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824281001;3;1
4aba38e0-749b-11df-b112-00215aee3ebe;Великі Межирічі;Великие Межеречи;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623081001;3;1
4aba3909-749b-11df-b112-00215aee3ebe;Великі Міньки;Великие Миньки;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755117;3;1
4aba390a-749b-11df-b112-00215aee3ebe;Великі Млинівці;Большие Млинивци;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123486201;3;1
7ad94f94-749b-11df-b112-00215aee3ebe;Великі Млинівці (Радянське);Большие Млиновцы (Радянское);село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123486201;3;1
4aba390b-749b-11df-b112-00215aee3ebe;Великі Мокряни;Великие Мокряны;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484403;3;1
4aba390c-749b-11df-b112-00215aee3ebe;Великі Мости;Великие Мосты;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624810600;3;1
4aba390d-749b-11df-b112-00215aee3ebe;Великі Мошки;Великие Мошки;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824286502;3;1
4aba390e-749b-11df-b112-00215aee3ebe;Великі Мошківці;Великие Мошковцы;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820381301;3;1
4aba390f-749b-11df-b112-00215aee3ebe;Великі Низгірці;Великие Низгирцы;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820880901;3;1
4aba3910-749b-11df-b112-00215aee3ebe;Великі Новосілки;Великие Новоселки;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622480402;3;1
4aba38e1-749b-11df-b112-00215aee3ebe;Великі Озера;Великие Озера;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621881301;3;1
4aba3911-749b-11df-b112-00215aee3ebe;Великі Орлинці;Великие Орлинцы;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822781201;3;1
4aba3912-749b-11df-b112-00215aee3ebe;Великі Осняки;Великие Осняки;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424488602;3;1
4aba3913-749b-11df-b112-00215aee3ebe;Великі Передримихи;Великие Передремихи;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787204;3;1
4aba3889-749b-11df-b112-00215aee3ebe;Великі Переліски;Великие Перелески;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620382002;3;1
4aba3914-749b-11df-b112-00215aee3ebe;Великі Підліски;Великие Подлески;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622182302;3;1
4aba3915-749b-11df-b112-00215aee3ebe;Великі Прицьки;Великие Прицки;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222281601;3;1
4aba3916-749b-11df-b112-00215aee3ebe;Великі Проходи;Великие Проходы;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322081503;3;1
4aba3917-749b-11df-b112-00215aee3ebe;Великі Пузирки;Великие Пузырьки;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822181601;3;1
4aba3918-749b-11df-b112-00215aee3ebe;Великі Сади;Великие Сады;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621683503;3;1
4aba37e2-749b-11df-b112-00215aee3ebe;Великі Селища;Великие Селища;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620455702;3;1
4aba3919-749b-11df-b112-00215aee3ebe;Великі Солонці;Великие Солонцы;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323480701;3;1
4aba391a-749b-11df-b112-00215aee3ebe;Великі Сорочинці;Великие Сорочинцы;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323281001;3;1
4aba38e2-749b-11df-b112-00215aee3ebe;Великі Телковичі;Великие Телковичи;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620881801;3;1
4aba38e3-749b-11df-b112-00215aee3ebe;Великі Трояни;Великие Трояны;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525580801;3;1
4aba391b-749b-11df-b112-00215aee3ebe;Великі Хутори;Великие Хутора;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325783501;3;1
4aba38e4-749b-11df-b112-00215aee3ebe;Великі Цепцевичі;Великие Цепцевичи;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620882201;3;1
4aba391c-749b-11df-b112-00215aee3ebe;Великі Чорнокінці;Великие Черноконцы;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125588601;3;1
4aba391d-749b-11df-b112-00215aee3ebe;Великі Юначки;Великие Юначки;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822784803;3;1
4aba391e-749b-11df-b112-00215aee3ebe;Великобоярка;Великобоярка;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120282802;3;1
4aba391f-749b-11df-b112-00215aee3ebe;Великобраталівське;Великобраталовское;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823181202;3;1
4aba3921-749b-11df-b112-00215aee3ebe;Великовеселе;Великовеселое;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822380401;3;1
4aba3922-749b-11df-b112-00215aee3ebe;Великодолинське;Великодолинское;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123755300;2;0
8cdd5cda-de27-11df-9197-00215aee3ebe;Великодрюкове;Великодрюково;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520810105;3;1
4aba3923-749b-11df-b112-00215aee3ebe;Великодубове;Великодубовое;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321587302;3;1
4aba3924-749b-11df-b112-00215aee3ebe;Великозалісся;Великозалесье;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822480401;3;1
4aba3925-749b-11df-b112-00215aee3ebe;Великозименове;Великозименово;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121680301;3;1
4aba3829-749b-11df-b112-00215aee3ebe;Великокозирщина;Великокозырщина;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222384002;3;1
4aba3927-749b-11df-b112-00215aee3ebe;Великокомарівка;Великокомаровка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655106;3;1
4aba3928-749b-11df-b112-00215aee3ebe;Великомар'янівка;Великомарьяновка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120884202;3;1
4aba3929-749b-11df-b112-00215aee3ebe;Великомихайлівка;Великомихайловка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224881001;2;1
4aba392a-749b-11df-b112-00215aee3ebe;Великомихайлівка;Великомихайловка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224283401;3;1
4aba392b-749b-11df-b112-00215aee3ebe;Великоолександрівка;Великоалександровка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755113;3;1
4aba392c-749b-11df-b112-00215aee3ebe;Великоолександрівка;Великоалександровка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655105;3;1
4aba392d-749b-11df-b112-00215aee3ebe;Великопілля;Великополье;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825781402;3;1
4aba392e-749b-11df-b112-00215aee3ebe;Великоплоске;Великоплоское;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121680801;3;1
4aba392f-749b-11df-b112-00215aee3ebe;Великополе;Великополе;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625881601;3;1
4aba3930-749b-11df-b112-00215aee3ebe;Великополовецьке;Великополовецкое;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224081201;2;1
4aba3931-749b-11df-b112-00215aee3ebe;Великорибальське;Великорыбальское;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124587702;3;1
4aba3932-749b-11df-b112-00215aee3ebe;Великоселецьке;Великоселецкое;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323685901;3;1
4aba3933-749b-11df-b112-00215aee3ebe;Великосербулівка;Великосербуловка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823080401;3;1
4aba3934-749b-11df-b112-00215aee3ebe;Великосілки;Великоселки;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622181601;3;1
4aba3935-749b-11df-b112-00215aee3ebe;Великосілля;Великоселье;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320783002;3;1
4aba3936-749b-11df-b112-00215aee3ebe;Великосілля;Великоселье;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625180901;3;1
cdc44f4e-dde8-11df-9197-00215aee3ebe;Велимче;Велимче;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724280401;3;1
4aba393a-749b-11df-b112-00215aee3ebe;Велин;Велин;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620382003;3;1
c8674aa4-dbb0-11df-9197-00215aee3ebe;Велихів;Велихов;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620883002;3;1
4aba393b-749b-11df-b112-00215aee3ebe;Велицьк;Велицк;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722181201;3;1
4aba393c-749b-11df-b112-00215aee3ebe;Величківка;Величковка;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423081801;3;1
4aba393d-749b-11df-b112-00215aee3ebe;Величківка;Величковка;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322885203;3;1
4aba393e-749b-11df-b112-00215aee3ebe;Величкове;Величково;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755112;3;1
4aba393f-749b-11df-b112-00215aee3ebe;Велідарівка;Велидаровка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823055102;3;1
73e94be3-ddc1-11df-9197-00215aee3ebe;Велідники;Велидники;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824285201;3;1
4aba3940-749b-11df-b112-00215aee3ebe;Велізарове;Велизарово;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123985203;3;1
4aba3942-749b-11df-b112-00215aee3ebe;Велюнь;Велюнь;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621880701;3;1
4aba3943-749b-11df-b112-00215aee3ebe;Велятино;Велятино;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125381401;3;0
4aba3944-749b-11df-b112-00215aee3ebe;Вельбівка;Вельбовка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320481701;3;1
4aba3c8d-749b-11df-b112-00215aee3ebe;Вельбівно;Вельбовно;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624281201;3;1
4aba3945-749b-11df-b112-00215aee3ebe;Вендичани;Вендичаны;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522655500;2;1
4aba3946-749b-11df-b112-00215aee3ebe;Венеславівка;Венеславовка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320485203;3;1
4aba3947-749b-11df-b112-00215aee3ebe;Венслави;Венславы;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322685802;3;1
4aba3948-749b-11df-b112-00215aee3ebe;Веприк;Веприк;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224981601;3;1
4aba3949-749b-11df-b112-00215aee3ebe;Веприк;Веприк;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421786802;3;1
4aba394a-749b-11df-b112-00215aee3ebe;Веприк;Веприк;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420682001;3;1
4aba394b-749b-11df-b112-00215aee3ebe;Веприк;Веприк;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320482001;3;1
4aba394c-749b-11df-b112-00215aee3ebe;Веприн;Веприн;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825081101;3;1
4aba39ab-749b-11df-b112-00215aee3ebe;Верб'яж;Вербяж;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121582001;3;1
4aba39ac-749b-11df-b112-00215aee3ebe;Верб'ятин;Вербятин;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
4aba394e-749b-11df-b112-00215aee3ebe;Верба;Верба;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720584402;3;1
4aba394f-749b-11df-b112-00215aee3ebe;Верба;Верба;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422281001;3;1
4aba3950-749b-11df-b112-00215aee3ebe;Верба;Верба;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621681201;3;1
17af7b1e-ddb9-11df-9197-00215aee3ebe;Вербаїв;Вербаев;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722885102;3;1
4aba3951-749b-11df-b112-00215aee3ebe;Вербень;Вербень;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621481003;3;1
33ec001c-ddd5-11df-9197-00215aee3ebe;Вербецьке;Вербецкое;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521980402;3;1
4aba3952-749b-11df-b112-00215aee3ebe;Вербецькі хутори;Вербецкие Хуторы;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;;3;1
4aba3953-749b-11df-b112-00215aee3ebe;Верби;Вербы;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;;3;1
4aba3955-749b-11df-b112-00215aee3ebe;Верби;Вербы;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821780802;3;1
4aba3956-749b-11df-b112-00215aee3ebe;Вербилівці;Вербиловцы;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624480801;3;1
4aba3957-749b-11df-b112-00215aee3ebe;Вербине;Вербино;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324881203;3;1
4aba3958-749b-11df-b112-00215aee3ebe;Вербиця;Вербица;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125885902;3;1
4aba3959-749b-11df-b112-00215aee3ebe;Вербиця;Вербица;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621581501;3;1
4aba395a-749b-11df-b112-00215aee3ebe;Вербичі;Вербичи;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424481001;3;1
4aba395b-749b-11df-b112-00215aee3ebe;Вербичне;Вербичное;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725587602;3;1
4aba395c-749b-11df-b112-00215aee3ebe;Вербів;Вербов;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120480901;3;1
4aba395d-749b-11df-b112-00215aee3ebe;Вербів;Вербов;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124886201;3;1
4aba395e-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723384905;3;1
4aba395f-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
4aba3960-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320281601;3;1
4aba3961-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320281601;3;1
4aba3962-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624880601;3;1
4aba3963-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624287602;3;1
4aba3966-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822881302;3;1
4aba3967-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824086802;3;1
4aba3968-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825281601;3;1
4aba3969-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424455402;3;1
4aba396a-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420387002;3;1
4aba396b-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120381501;3;1
4aba396c-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121881001;3;1
4aba396d-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;;3;1
4aba396e-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524883702;3;1
4aba396f-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522481301;3;1
4aba3970-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522280701;3;1
4aba3971-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523185002;3;1
4aba3972-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521284006;3;1
4aba3973-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525381203;3;1
4aba3974-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523688503;3;1
4aba3975-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524080602;3;1
4aba3976-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822787003;3;1
4aba3977-749b-11df-b112-00215aee3ebe;Вербівка;Вербовка;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621882003;3;1
4aba3978-749b-11df-b112-00215aee3ebe;Вербівочка;Вербовочка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824280703;3;1
4aba3979-749b-11df-b112-00215aee3ebe;Вербівське;Вербовское;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755114;3;1
4aba397a-749b-11df-b112-00215aee3ebe;Вербівці;Вербовцы;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125081101;3;1
4aba397b-749b-11df-b112-00215aee3ebe;Вербівці;Вербовцы;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321582801;3;1
4aba397c-749b-11df-b112-00215aee3ebe;Вербівці;Вербовцы;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621680101;3;1
4aba397d-749b-11df-b112-00215aee3ebe;Вербівці;Вербовцы;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825581301;3;1
4aba397e-749b-11df-b112-00215aee3ebe;Вербівчик;Вербовчик;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;4620380701;3;1
4aba397f-749b-11df-b112-00215aee3ebe;Вербіж;Вербиж;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623082402;3;1
4aba3980-749b-11df-b112-00215aee3ebe;Вербка;Вербка;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722182403;2;0
4aba3981-749b-11df-b112-00215aee3ebe;Вербка;Вербка;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124280801;3;1
4aba3982-749b-11df-b112-00215aee3ebe;Вербка;Вербка;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521980401;3;1
4aba3983-749b-11df-b112-00215aee3ebe;Вербка;Вербка;село;78f453a8-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0525082201;3;1
4aba3984-749b-11df-b112-00215aee3ebe;Вербка;Вербка;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823087002;3;1
4aba3985-749b-11df-b112-00215aee3ebe;Вербка;Вербка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825882001;3;1
4aba3986-749b-11df-b112-00215aee3ebe;Вербка;Вербка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822481802;3;1
4aba3987-749b-11df-b112-00215aee3ebe;Вербка-Мурована;Вербка-Мурованая;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825882401;3;1
4aba3988-749b-11df-b112-00215aee3ebe;Вербки;Вербки;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324588003;3;1
4aba3989-749b-11df-b112-00215aee3ebe;Вербки;Вербки;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223582801;3;1
74dc496f-749b-11df-b112-00215aee3ebe;Вербки-Осокорівка (Петрівське);Вербки-Осокоровка (Петровское);село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224886008;3;1
4aba398a-749b-11df-b112-00215aee3ebe;Верблюжка;Верблюжка;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523480401;3;1
4aba398b-749b-11df-b112-00215aee3ebe;Вербляни;Вербляны;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620681202;3;1
4aba398c-749b-11df-b112-00215aee3ebe;Вербляни;Вербляны;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625881901;3;1
4aba398d-749b-11df-b112-00215aee3ebe;Вербова;Вербовая;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220410502;3;1
4aba398e-749b-11df-b112-00215aee3ebe;Вербова;Вербовая;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523981401;3;1
4aba398f-749b-11df-b112-00215aee3ebe;Вербова Балка;Вербовая Балка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825455401;3;1
4aba3991-749b-11df-b112-00215aee3ebe;Вербове;Вербовое;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086505;3;1
4aba3992-749b-11df-b112-00215aee3ebe;Вербове;Вербовое;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786903;3;1
4aba3993-749b-11df-b112-00215aee3ebe;Вербове;Вербовое;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920381202;3;1
4aba3994-749b-11df-b112-00215aee3ebe;Вербове;Вербовое;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655110;3;1
4aba3995-749b-11df-b112-00215aee3ebe;Вербове;Вербовое;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223587502;3;1
4aba3996-749b-11df-b112-00215aee3ebe;Вербове;Вербовое;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282508;3;1
4aba3997-749b-11df-b112-00215aee3ebe;Вербове;Вербовое;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224284503;3;1
4aba3998-749b-11df-b112-00215aee3ebe;Вербове;Вербовое;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823684202;3;1
4aba399c-749b-11df-b112-00215aee3ebe;Вербове;Вербовое;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624883702;3;1
4aba399d-749b-11df-b112-00215aee3ebe;Вербове;Вербовое;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121282503;3;1
4aba399e-749b-11df-b112-00215aee3ebe;Вербове;Вербовое;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;;3;1
4aba399f-749b-11df-b112-00215aee3ebe;Вербове;Вербовое;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521480501;3;1
4aba39a0-749b-11df-b112-00215aee3ebe;Вербове;Вербовое;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522885002;3;1
17af7b28-ddb9-11df-9197-00215aee3ebe;Вербове;Вербовое;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224886402;3;1
56bdd249-749b-11df-b112-00215aee3ebe;Вербове ( Жовтневе );Жовтневое;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321581003;3;1
4aba39a1-749b-11df-b112-00215aee3ebe;Вербовець;Вербовец;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123882401;3;1
4aba39a2-749b-11df-b112-00215aee3ebe;Вербовець;Вербовец;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623682401;3;1
4aba39a3-749b-11df-b112-00215aee3ebe;Вербовець;Вербовец;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121281801;3;1
4aba39a4-749b-11df-b112-00215aee3ebe;Вербовець;Вербовец;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122281001;3;1
4aba39a5-749b-11df-b112-00215aee3ebe;Вербовець;Вербовец;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522880801;3;1
4aba39a6-749b-11df-b112-00215aee3ebe;Верболози;Верболозы;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521480809;3;1
4aba39a7-749b-11df-b112-00215aee3ebe;Вербородинці;Вербородинцы;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824280901;3;1
4aba39a8-749b-11df-b112-00215aee3ebe;Вербське;Вербское;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225980503;3;1
4aba39a9-749b-11df-b112-00215aee3ebe;Вербувата;Вербоватая;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124681501;3;1
4aba39aa-749b-11df-b112-00215aee3ebe;Вербуватівка;Вербуватовка;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225980502;3;1
4aba39b0-749b-11df-b112-00215aee3ebe;Вергуни;Вергуны;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324881903;3;1
4aba39b1-749b-11df-b112-00215aee3ebe;Вергуни;Вергуны;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124982001;3;1
4aba39b8-749b-11df-b112-00215aee3ebe;Верем'я;Веремье;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223181001;3;1
4aba39b2-749b-11df-b112-00215aee3ebe;Вереміївка;Веремеевка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324555105;3;1
4aba39b3-749b-11df-b112-00215aee3ebe;Вереміївка;Веремеевка;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1223886003;3;1
4aba39b4-749b-11df-b112-00215aee3ebe;Вереміївка;Веремеевка;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521882502;3;1
4aba39b5-749b-11df-b112-00215aee3ebe;Вереміївка;Веремеевка;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125182001;3;1
4aba39b6-749b-11df-b112-00215aee3ebe;Вереміївка;Веремеевка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520887802;3;1
4aba39b7-749b-11df-b112-00215aee3ebe;Вереміївка;Веремеевка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822787402;3;1
4aba39b9-749b-11df-b112-00215aee3ebe;Верени;Верены;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622183002;3;1
4aba39ba-749b-11df-b112-00215aee3ebe;Веренчанка;Веренчанка;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321582901;3;1
4aba39bc-749b-11df-b112-00215aee3ebe;Вереси;Вересы;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822081201;1;1
4aba39bd-749b-11df-b112-00215aee3ebe;Вересівка;Вересовка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821781602;3;1
4aba39be-749b-11df-b112-00215aee3ebe;Верескуни;Верескуни;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421788505;3;1
4aba39bf-749b-11df-b112-00215aee3ebe;Вересневе;Вересневое;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624682003;2;0
4aba39c0-749b-11df-b112-00215aee3ebe;Вересня;Вересня;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223583602;3;1
4aba39c1-749b-11df-b112-00215aee3ebe;Вересня;Вересня;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820681805;3;1
4aba39c2-749b-11df-b112-00215aee3ebe;Вересоч;Вересочь;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422781001;3;1
4aba39c3-749b-11df-b112-00215aee3ebe;Веретено;Веретено;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620888002;3;1
4aba39c4-749b-11df-b112-00215aee3ebe;Верешки;Верешки;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520284406;3;1
4aba39c5-749b-11df-b112-00215aee3ebe;Верещаки;Верещаки;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123882701;3;1
4aba39c6-749b-11df-b112-00215aee3ebe;Верещаки;Верещаки;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122885202;3;1
4aba39c7-749b-11df-b112-00215aee3ebe;Верещиця;Верещица;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625855303;3;1
4aba39c8-749b-11df-b112-00215aee3ebe;Верин;Верин;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623081801;3;1
4aba39c9-749b-11df-b112-00215aee3ebe;Верини;Верины;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622780402;3;1
4aba39ca-749b-11df-b112-00215aee3ebe;Верлок;Верлок;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825081201;3;1
4aba39cc-749b-11df-b112-00215aee3ebe;Вернигородок;Вернигородок;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521480803;3;1
4eb9e900-741e-11e1-bcbd-003048d2b473;Верняки;Верняки;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122488202;3;1
4aba39cd-749b-11df-b112-00215aee3ebe;Вернянка;Вернянка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522284802;3;1
4aba39cb-749b-11df-b112-00215aee3ebe;Верпа;Верпа;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824284702;3;1
4aba39ce-749b-11df-b112-00215aee3ebe;Вертелецьке;Вертелецкое;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755113;3;1
4aba39cf-749b-11df-b112-00215aee3ebe;Вертелка;Вертелка;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122684802;3;1
4aba39d0-749b-11df-b112-00215aee3ebe;Вертеп;Вертеп;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125387103;3;1
68cf2c4d-749b-11df-b112-00215aee3ebe;Вертеча (Незаможне);Вертеча (Незаможное);село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424489204;3;1
ab75842d-2a4b-11e2-bd3c-003048d2b473;Вертиіївка;Вертиевка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
4aba39d1-749b-11df-b112-00215aee3ebe;Вертіївка;Вертиевка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320887502;3;1
4aba39d2-749b-11df-b112-00215aee3ebe;Вертіївка;Вертиевка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423381901;3;1
4aba39d3-749b-11df-b112-00215aee3ebe;Вертокиївка;Вертокиевка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822081301;3;1
4aba39d4-749b-11df-b112-00215aee3ebe;Верхи;Верхи;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721481201;3;1
4aba39d5-749b-11df-b112-00215aee3ebe;Верхи;Верхи;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881202;3;1
4aba39d6-749b-11df-b112-00215aee3ebe;Верхів;Верхов;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624281601;3;1
4aba39d7-749b-11df-b112-00215aee3ebe;Верхівка;Верховка;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722885603;3;1
4aba39d9-749b-11df-b112-00215aee3ebe;Верхівка;Верховка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520280401;3;1
4aba39da-749b-11df-b112-00215aee3ebe;Верхівка;Верховка;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524180801;3;1
4aba39db-749b-11df-b112-00215aee3ebe;Верхівня;Верховня;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825281801;3;1
4aba39dc-749b-11df-b112-00215aee3ebe;Верхівськ;Верховск;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624682701;3;1
cdc44f36-dde8-11df-9197-00215aee3ebe;Верхівське;Верховское;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524180802;3;1
4aba39dd-749b-11df-b112-00215aee3ebe;Верхівцеве;Верховцево;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221010300;3;1
4aba39de-749b-11df-b112-00215aee3ebe;Верхівці;Верховцы;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121685702;3;1
4aba39df-749b-11df-b112-00215aee3ebe;Верхівці;Верховцы;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624281401;3;1
4aba39e0-749b-11df-b112-00215aee3ebe;Верхівці;Верховцы;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522580902;3;1
4aba39e1-749b-11df-b112-00215aee3ebe;Верхівці;Верховцы;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825884402;3;1
4aba39e2-749b-11df-b112-00215aee3ebe;Верхнє;Верхнее;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625581901;3;1
4aba39e4-749b-11df-b112-00215aee3ebe;Верхнє Висоцьке;Верхнее Высоцкое;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625581301;3;1
4aba39e5-749b-11df-b112-00215aee3ebe;Верхнє Водяне;Верхнее Водяное;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684003;3;1
4aba39e6-749b-11df-b112-00215aee3ebe;Верхнє Водяне;Верхнее Водяное;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123681501;3;1
4aba39e3-749b-11df-b112-00215aee3ebe;Верхнє Гусне;Верхнее Гусное;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625581601;3;1
4aba39e7-749b-11df-b112-00215aee3ebe;Верхнє Піщане;Верхнее Пищаное;село;9b486f64-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5910191503;3;1
4aba39e8-749b-11df-b112-00215aee3ebe;Верхнє Синьовидне;Верхнее Синевидное;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624555300;3;1
869e7f50-e1db-11e5-88dd-001ec93df11f;Верхнє Солотвино;Верхнее Солотвино;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124887603;3;1
4aba39e9-749b-11df-b112-00215aee3ebe;Верхні Ворота;Верхние Ворота;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121582501;3;0
4aba39ea-749b-11df-b112-00215aee3ebe;Верхні Гаї;Верхние Гаи;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621281001;3;1
4aba39ec-749b-11df-b112-00215aee3ebe;Верхні Петрівці;Верхние Петровцы;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324582501;3;1
4aba39ed-749b-11df-b112-00215aee3ebe;Верхні Ремети;Верхние Реметы;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120486802;3;1
4aba39ee-749b-11df-b112-00215aee3ebe;Верхні Рівні;Верхние Ровни;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325484202;3;1
4aba39ef-749b-11df-b112-00215aee3ebe;Верхні Синівці;Верхние Синевцы;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321085701;3;1
4aba39f1-749b-11df-b112-00215aee3ebe;Верхні Станівці;Верхние Становцы;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322582501;3;1
4aba39f3-749b-11df-b112-00215aee3ebe;Верхнів;Верхнов;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721180403;3;1
4aba39f4-749b-11df-b112-00215aee3ebe;Верхній Бистрий;Верхний Быстрый;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122480601;3;1
4aba39f5-749b-11df-b112-00215aee3ebe;Верхній Бишкин;Верхний Бышкин;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324581501;3;1
4aba39f6-749b-11df-b112-00215aee3ebe;Верхній Вербіж;Верхний Вербиж;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623280601;3;1
4aba39f7-749b-11df-b112-00215aee3ebe;Верхній Дорожів;Верхний Дорожев;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621281201;3;1
4aba39f8-749b-11df-b112-00215aee3ebe;Верхній Коропець;Верхний Коропец;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122781601;2;0
4aba39fa-749b-11df-b112-00215aee3ebe;Верхній Куяльник;Верхний Куяльник;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121881205;3;1
4aba39fb-749b-11df-b112-00215aee3ebe;Верхній Лужок;Верхний Лужок;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625181301;3;1
4aba39fc-749b-11df-b112-00215aee3ebe;Верхній Майдан;Верхний Майдан;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624080801;3;1
4aba3a01-749b-11df-b112-00215aee3ebe;Верхній Струтинь;Верхний Струтинь;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624880901;3;1
4aba3a02-749b-11df-b112-00215aee3ebe;Верхній Студений;Верхний Студеный;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122484402;3;1
4aba3a06-749b-11df-b112-00215aee3ebe;Верхній Турів;Верхний Туров;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625586202;3;1
4aba3a07-749b-11df-b112-00215aee3ebe;Верхній Яловець;Верхний Яловец;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323586003;3;1
4aba3a08-749b-11df-b112-00215aee3ebe;Верхній Ясенів;Верхний Ясенов;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620881501;3;1
4aba3a09-749b-11df-b112-00215aee3ebe;Верхня;Верхняя;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622880801;3;1
4aba3a0a-749b-11df-b112-00215aee3ebe;Верхня Білка;Верхняя Белка;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623680801;3;1
4aba3a0b-749b-11df-b112-00215aee3ebe;Верхня Будаківка;Верхняя Будаковка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323284602;3;1
4aba3a0c-749b-11df-b112-00215aee3ebe;Верхня Визниця;Верхняя Вызница;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122781401;2;1
4aba3a0e-749b-11df-b112-00215aee3ebe;Верхня Грабівниця;Верхняя Грабовница;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121584502;3;1
4aba3a10-749b-11df-b112-00215aee3ebe;Верхня Жужманівка;Верхняя Жужмановка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084903;3;1
4aba3a16-749b-11df-b112-00215aee3ebe;Верхня Ланна;Верхняя Ланная;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321681001;3;1
4aba3a17-749b-11df-b112-00215aee3ebe;Верхня Липиця;Верхняя Липица;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624481001;3;1
4aba3a18-749b-11df-b112-00215aee3ebe;Верхня Лукавиця;Верхняя Лукавица;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625381602;3;1
4aba3a19-749b-11df-b112-00215aee3ebe;Верхня Мануйлівка;Верхняя Мануйловка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322083001;3;1
4aba3a1a-749b-11df-b112-00215aee3ebe;Верхня Озеряна;Верхняя Озеряна;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325158801;3;1
4aba3a1b-749b-11df-b112-00215aee3ebe;Верхня Орілька;Верхняя Орелька;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324582001;3;1
4aba3a1e-749b-11df-b112-00215aee3ebe;Верхня Пожня;Верхняя Пожня;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922386402;3;1
4aba3a20-749b-11df-b112-00215aee3ebe;Верхня Роганка;Верхняя Роганка;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325184003;3;1
4aba3a21-749b-11df-b112-00215aee3ebe;Верхня Рожанка;Верхняя Рожанка;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624584302;3;1
4aba3a22-749b-11df-b112-00215aee3ebe;Верхня Рудня;Верхняя Рудня;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287002;3;1
4aba3a23-749b-11df-b112-00215aee3ebe;Верхня Сагарівка;Верхняя Сагаревка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920981401;3;1
4aba3a24-749b-11df-b112-00215aee3ebe;Верхня Самара;Верхняя Самара;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320682001;3;1
4aba3a25-749b-11df-b112-00215aee3ebe;Верхня Сироватка;Верхняя Сыроватка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924782901;2;1
4aba3a26-749b-11df-b112-00215aee3ebe;Верхня Слобідка;Верхняя Слободка;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523281103;3;1
4aba3a27-749b-11df-b112-00215aee3ebe;Верхня Солотвіна;Верхняя Солотвина;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124887603;3;1
4aba3a28-749b-11df-b112-00215aee3ebe;Верхня Стинава;Верхняя Стынава;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625387201;3;1
4aba3a2a-749b-11df-b112-00215aee3ebe;Верхня Яблунька;Верхняя Яблонька;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625582201;3;1
4aba3a2b-749b-11df-b112-00215aee3ebe;Верхняки;Верхняки;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824285602;3;1
4aba3a2c-749b-11df-b112-00215aee3ebe;Верхняківці;Верхняковцы;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120881301;3;1
4aba3a2d-749b-11df-b112-00215aee3ebe;Верхнячка;Верхнячка;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624580401;3;1
4aba3a2e-749b-11df-b112-00215aee3ebe;Верхнячка;Верхнячка;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124655300;3;1
4aba3a32-749b-11df-b112-00215aee3ebe;Верхньодніпровськ;Верхнеднепровск;місто;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221010100;2;0
4aba3a35-749b-11df-b112-00215aee3ebe;Верхньозорянське;Верхнезорянское;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325755101;3;1
4aba3a36-749b-11df-b112-00215aee3ebe;Верхньоінгульське;Верхнеингульское;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520882401;3;1
4aba3a37-749b-11df-b112-00215aee3ebe;Верхньокам'яниста;Верхнекаменистая;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224580502;3;1
4aba3a3f-749b-11df-b112-00215aee3ebe;Верхобуж;Верхобуж;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621883602;3;1
56bdd244-749b-11df-b112-00215aee3ebe;Верхове (Жовтневе);Верхове (Жовтневое);село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684504;3;1
4aba3a41-749b-11df-b112-00215aee3ebe;Верховина;Верховина;місто;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2620855100;3;0
4aba3a42-749b-11df-b112-00215aee3ebe;Верховина;Верховина;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323281802;3;1
4aba3a43-749b-11df-b112-00215aee3ebe;Верховина-Бистра;Верховина-Быстрая;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120880801;3;1
4aba3a44-749b-11df-b112-00215aee3ebe;Верхоли;Верхолы;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081904;3;1
4aba3a45-749b-11df-b112-00215aee3ebe;Верхолісся;Верхолесье;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222081202;3;1
4aba3a46-749b-11df-b112-00215aee3ebe;Верхолісся;Верхолесье;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422480502;3;1
4aba3a48-749b-11df-b112-00215aee3ebe;Верхосулка;Верхосулка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920681401;3;1
4aba3a49-749b-11df-b112-00215aee3ebe;Верхоярівка;Верхояровка;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323810101;3;1
4aba3a4a-749b-11df-b112-00215aee3ebe;Верхутка;Верхутка;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625885203;3;1
4aba3a4b-749b-11df-b112-00215aee3ebe;Верчани;Верчаны;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625385202;3;1
4aba3a4c-749b-11df-b112-00215aee3ebe;Вершаці;Вершацы;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125481001;3;1
4aba3a4d-749b-11df-b112-00215aee3ebe;Вершина;Вершина;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983702;3;1
4aba3a50-749b-11df-b112-00215aee3ebe;Вершина;Вершина;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822084702;3;1
4aba3a52-749b-11df-b112-00215aee3ebe;Вершина-Згарська;Вершина-Згарская;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120683001;3;1
4aba3a53-749b-11df-b112-00215aee3ebe;Вершини;Вершины;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421481603;3;1
7ad94f3c-749b-11df-b112-00215aee3ebe;Вершинне (Радгоспне);Вершинное (Радгоспное);село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224581803;3;1
4aba3a56-749b-11df-b112-00215aee3ebe;Вершинова Муравійка;Вершинова Муравейка;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422781501;3;1
4aba3a57-749b-11df-b112-00215aee3ebe;Вершино-Кам'янка;Вершино-Каменка;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523480801;3;1
4aba3a58-749b-11df-b112-00215aee3ebe;Вершниця;Вершница;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824084002;3;1
4aba3a59-749b-11df-b112-00215aee3ebe;Веряця;Веряца;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121281901;3;1
4aba3a5a-749b-11df-b112-00215aee3ebe;Весела;Веселая;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123485902;3;1
4aba3aa7-749b-11df-b112-00215aee3ebe;Весела;Веселая;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521682406;3;1
4aba3a5b-749b-11df-b112-00215aee3ebe;Весела Балка;Веселая Балка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823681101;3;1
4aba3a5c-749b-11df-b112-00215aee3ebe;Весела Балка;Веселая Балка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125255403;3;1
4aba3a5d-749b-11df-b112-00215aee3ebe;Весела Балка;Веселая Балка;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125085607;2;1
4aba3a5e-749b-11df-b112-00215aee3ebe;Весела Гірка;Веселая Горка;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225980507;3;1
4aba3a61-749b-11df-b112-00215aee3ebe;Весела Дача;Веселая Дача;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225855102;3;1
4aba3a62-749b-11df-b112-00215aee3ebe;Весела Долина;Веселая Долина;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320684602;3;1
4aba3a63-749b-11df-b112-00215aee3ebe;Весела Долина;Веселая Долина;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923284403;3;1
4aba3a64-749b-11df-b112-00215aee3ebe;Весела Долина;Веселая Долина;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055710;3;1
4aba3a65-749b-11df-b112-00215aee3ebe;Весела Долина;Веселая Долина;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124780701;3;1
4aba3a68-749b-11df-b112-00215aee3ebe;Весела Роща;Веселая Роща;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222086002;3;1
4aba3a69-749b-11df-b112-00215aee3ebe;Весела Слобідка;Веселая Слободка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222782102;3;1
4aba3a6b-749b-11df-b112-00215aee3ebe;Весела Федорівка;Веселая Федоровка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225485502;3;1
4aba3a6c-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923881401;3;1
4aba3a6d-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920655303;3;1
4aba3a6e-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922387402;3;1
4aba3a6f-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923281703;3;1
4aba3a70-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921285802;3;1
4aba3a71-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881201;3;1
4aba3a72-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222386502;3;1
4aba3a73-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223582803;3;1
4aba3a74-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055701;3;1
4aba3a75-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224887103;3;1
4aba3a76-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655112;3;1
4aba3a77-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221881201;3;1
4aba3a78-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222982802;3;1
4aba3a79-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821181703;3;1
4aba3a7a-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824880202;3;1
4aba3a7b-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325180501;3;1
4aba3a7d-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324584002;3;1
4aba3a7e-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320286202;3;1
4aba3a7f-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323988503;3;1
4aba3a80-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320482507;3;1
4aba3a81-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320682003;3;1
4aba3a82-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123583903;3;1
4aba3a83-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283608;3;1
4aba3a84-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123981705;3;1
4aba3a85-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120886402;3;1
4aba3a86-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125080303;2;1
4aba3a88-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322181301;3;1
4aba3a89-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955404;3;1
4aba3a8c-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520983302;3;1
4aba3a8d-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520680401;3;1
4aba3a9f-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223387202;3;1
4aba3aa0-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224286802;3;1
4aba3aa1-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825455102;3;1
4aba3aa2-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423083002;3;1
4aba3aa3-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423600000;3;1
4aba3aa4-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422781002;3;1
4aba3aa5-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420387203;3;1
4aba3aa6-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423884502;3;1
4aba3aa8-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523110104;3;1
4aba3aa9-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520581701;3;1
4aba3aaa-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520383703;3;1
4aba3aab-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523483603;3;1
4aba3aac-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824288302;3;1
4aba3aad-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625889002;3;1
4aba3aae-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322682403;3;1
4aba3aaf-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320485902;3;1
4aba3ab0-749b-11df-b112-00215aee3ebe;Веселе;Веселое;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323281803;3;1
113359f4-835b-11e8-80d4-1c98ec135261;Веселе;Веселе;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223284506;3;1
e66d8390-557f-11e2-b7ac-003048d2b473;Веселе (Радгоспне);Веселое (Радгоспне);село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321786006;3;1
4aba3ab2-749b-11df-b112-00215aee3ebe;Веселе Поле;Веселое Поле;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282503;3;1
4aba3ab5-749b-11df-b112-00215aee3ebe;Веселець;Веселец;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821281901;3;1
4aba3ab6-749b-11df-b112-00215aee3ebe;Веселий Гай;Веселый Гай;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925681502;3;1
4aba3ab7-749b-11df-b112-00215aee3ebe;Веселий Гай;Веселый Гай;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920381203;3;1
4aba3ab8-749b-11df-b112-00215aee3ebe;Веселий Гай;Веселый Гай;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222386002;3;1
4aba3ab9-749b-11df-b112-00215aee3ebe;Веселий Гай;Веселый Гай;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;;3;1
4aba3aba-749b-11df-b112-00215aee3ebe;Веселий Гай;Веселый Гай;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323680801;3;1
4aba3abc-749b-11df-b112-00215aee3ebe;Веселий Кут;Веселый Кут;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755115;3;1
4aba3abd-749b-11df-b112-00215aee3ebe;Веселий Кут;Веселый Кут;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221882002;3;1
4aba3abe-749b-11df-b112-00215aee3ebe;Веселий Кут;Веселый Кут;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823685302;3;1
4aba3abf-749b-11df-b112-00215aee3ebe;Веселий Кут;Веселый Кут;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821182202;3;1
4aba3ac0-749b-11df-b112-00215aee3ebe;Веселий Кут;Веселый Кут;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120480501;3;1
4aba3ac1-749b-11df-b112-00215aee3ebe;Веселий Кут;Веселый Кут;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224481201;3;1
4aba3ac2-749b-11df-b112-00215aee3ebe;Веселий Кут;Веселый Кут;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124080801;3;1
4aba3ac3-749b-11df-b112-00215aee3ebe;Веселий Кут;Веселый Кут;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125781601;3;1
4aba3ac4-749b-11df-b112-00215aee3ebe;Веселий Кут;Веселый Кут;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524084003;3;1
4aba3ac5-749b-11df-b112-00215aee3ebe;Веселий Кут;Веселый Кут;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522281502;3;1
4aba3ac6-749b-11df-b112-00215aee3ebe;Веселий Лан;Веселый Лан;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822382602;3;1
4aba3ac7-749b-11df-b112-00215aee3ebe;Веселий Поділ;Веселый Подол;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324555106;3;1
4aba3ac8-749b-11df-b112-00215aee3ebe;Веселий Поділ;Веселый Подол;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224584803;3;1
4aba3ac9-749b-11df-b112-00215aee3ebe;Веселий Поділ;Веселый Подол;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823082403;3;1
4aba3aca-749b-11df-b112-00215aee3ebe;Веселий Поділ;Веселый Подол;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824582202;3;1
4aba3acb-749b-11df-b112-00215aee3ebe;Веселий Поділ;Веселый Подол;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125185202;3;1
4aba3acc-749b-11df-b112-00215aee3ebe;Веселий Роздол;Веселый Раздол;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821485102;3;1
4aba3acd-749b-11df-b112-00215aee3ebe;Веселий Роздол;Веселый Раздол;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822055201;3;1
4aba3ace-749b-11df-b112-00215aee3ebe;Веселий Став;Веселый Став;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225882202;3;1
4aba3acf-749b-11df-b112-00215aee3ebe;Веселий Степ;Веселая Степь;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924182002;3;1
4aba3ad0-749b-11df-b112-00215aee3ebe;Веселий Хутір;Веселый Хутор;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125182201;3;1
87162721-749b-11df-b112-00215aee3ebe;Веселий Яр (Червоноукраїнка);Веселый Яр (Червоноукраинка);село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225483209;3;1
4aba3ad1-749b-11df-b112-00215aee3ebe;Веселинівка;Веселиновка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283103;3;1
4aba3ad2-749b-11df-b112-00215aee3ebe;Веселинівка;Веселиновка;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220280801;3;1
4aba3ad3-749b-11df-b112-00215aee3ebe;Веселинівка;Веселиновка;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823986702;3;1
4aba3ad4-749b-11df-b112-00215aee3ebe;Веселинове;Веселиново;місто;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821755100;3;1
4aba3ad5-749b-11df-b112-00215aee3ebe;Веселі Боковеньки;Веселые Боковеньки;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521983002;3;1
4aba3ad6-749b-11df-b112-00215aee3ebe;Веселі Гори;Веселые Горы;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922688203;3;1
4aba3ad7-749b-11df-b112-00215aee3ebe;Веселі Чумаки;Веселые Чумаки;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1220387702;3;1
4aba3ad8-749b-11df-b112-00215aee3ebe;Веселівка;Веселовка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321082002;3;1
4aba3ad9-749b-11df-b112-00215aee3ebe;Веселівка;Веселовка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924781102;3;1
4aba3ada-749b-11df-b112-00215aee3ebe;Веселівка;Веселовка;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123480703;3;1
4aba3add-749b-11df-b112-00215aee3ebe;Веселівка;Веселовка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824285002;3;1
4aba3ade-749b-11df-b112-00215aee3ebe;Веселівка;Веселовка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822384601;3;1
4aba3adf-749b-11df-b112-00215aee3ebe;Веселівка;Веселовка;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420386003;3;1
4aba3ae0-749b-11df-b112-00215aee3ebe;Веселівка;Веселовка;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124685502;3;1
4aba3ae1-749b-11df-b112-00215aee3ebe;Веселівка;Веселовка;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523487802;3;1
4aba3ae2-749b-11df-b112-00215aee3ebe;Веселівка;Веселовка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523781601;3;1
4aba3ae3-749b-11df-b112-00215aee3ebe;Веселівка;Веселовка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523880801;3;1
4aba3ae4-749b-11df-b112-00215aee3ebe;Веселівка;Веселовка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520882801;3;1
4aba3ae5-749b-11df-b112-00215aee3ebe;Веселівка;Веселовка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522281902;3;1
4aba3ae6-749b-11df-b112-00215aee3ebe;Веселівка;Веселовка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522581501;3;1
4aba3ae7-749b-11df-b112-00215aee3ebe;Веселівка;Веселовка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822781701;3;1
4aba3ae8-749b-11df-b112-00215aee3ebe;Веселівське;Веселовское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321585003;3;1
4aba3ae9-749b-11df-b112-00215aee3ebe;Веселка;Веселка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323481002;3;1
4aba3aea-749b-11df-b112-00215aee3ebe;Веселка;Веселка;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823182101;3;1
4aba3aeb-749b-11df-b112-00215aee3ebe;Веселка;Веселка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522284902;3;1
4aba3aee-749b-11df-b112-00215aee3ebe;Веселотернувате;Веселотерноватое;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582502;3;1
4aba3aef-749b-11df-b112-00215aee3ebe;Веселянка;Веселянка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322182402;3;1
4aba3af0-749b-11df-b112-00215aee3ebe;Веснівка;Весновка;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123082802;3;1
4aba3af1-749b-11df-b112-00215aee3ebe;Весногірське;Весногорское;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923584403;3;1
4aba3af2-749b-11df-b112-00215aee3ebe;Весняна Квітка;Весняная Квитка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821780602;3;1
4aba3af3-749b-11df-b112-00215aee3ebe;Весняне;Весняное;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824280401;3;1
4aba3af6-749b-11df-b112-00215aee3ebe;Весняне;Весняное;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524984505;3;1
4aba3af7-749b-11df-b112-00215aee3ebe;Весняне;Весняное;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820384502;3;1
4aba3af8-749b-11df-b112-00215aee3ebe;Весняне;Весняное;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623081401;3;1
775c7111-8daa-11e0-82d0-003048d2b473;Весняне;Весняное;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;3;1
4aba3af9-749b-11df-b112-00215aee3ebe;Веснянка;Веснянка;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721883602;3;1
4aba3afb-749b-11df-b112-00215aee3ebe;Веснянка;Веснянка;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221681602;3;1
4aba3afc-749b-11df-b112-00215aee3ebe;Веснянка;Веснянка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825482202;3;1
4aba3afd-749b-11df-b112-00215aee3ebe;Веснянка;Веснянка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521785302;3;1
4aba3afe-749b-11df-b112-00215aee3ebe;Веснянка;Веснянка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824281701;3;1
4aba3aff-749b-11df-b112-00215aee3ebe;Вестерничани;Вестерничаны;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122983802;3;1
4aba3b00-749b-11df-b112-00215aee3ebe;Ветеринарне;Ветеринарное;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322055601;3;1
4aba3b01-749b-11df-b112-00215aee3ebe;Ветли;Ветлы;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155109;3;1
4aba3b02-749b-11df-b112-00215aee3ebe;Ветхалівка;Ветхаловка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320487502;3;1
4aba3b03-749b-11df-b112-00215aee3ebe;Вечірки;Вечорки;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323880703;3;1
4aba3b04-749b-11df-b112-00215aee3ebe;Вечірчине;Вечирчино;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320483402;3;1
4aba3b05-749b-11df-b112-00215aee3ebe;Виблі;Выбли;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422782001;3;1
4aba3b06-749b-11df-b112-00215aee3ebe;Вибранівка;Выбрановка;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621581801;3;1
4aba3b07-749b-11df-b112-00215aee3ebe;Вибудів;Выбудов;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123081701;3;1
4aba3b08-749b-11df-b112-00215aee3ebe;Виводове;Выводово;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225482001;3;1
4aba3b09-749b-11df-b112-00215aee3ebe;Вигів;Выгов;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822381001;3;1
4aba3b0a-749b-11df-b112-00215aee3ebe;Вигівка;Выговка;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621210301;3;1
4aba3b0b-749b-11df-b112-00215aee3ebe;Вигін;Выгон;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623483003;3;1
4aba3b0c-749b-11df-b112-00215aee3ebe;Вигнанка;Выгнанка;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723310103;3;1
4aba3b0d-749b-11df-b112-00215aee3ebe;Вигнанка;Выгнанка;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823182401;3;1
4aba3b0e-749b-11df-b112-00215aee3ebe;Вигнанка;Выгнанка;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523082002;3;1
4aba3b0f-749b-11df-b112-00215aee3ebe;Вигнанка;Выгнанка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821288603;3;1
4aba3b10-749b-11df-b112-00215aee3ebe;Вигода;Выгода;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121682402;3;1
4aba3b11-749b-11df-b112-00215aee3ebe;Вигода;Выгода;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122083303;3;1
4aba3b12-749b-11df-b112-00215aee3ebe;Вигода;Выгода;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120881801;3;1
4aba3b13-749b-11df-b112-00215aee3ebe;Вигода;Выгода;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121081701;3;1
4aba3b14-749b-11df-b112-00215aee3ebe;Вигода;Выгода;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622055300;3;1
4aba3b15-749b-11df-b112-00215aee3ebe;Вигода;Выгода;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;2620884502;3;1
4aba3b16-749b-11df-b112-00215aee3ebe;Вигода;Выгода;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822086303;3;1
4aba3b17-749b-11df-b112-00215aee3ebe;Вигода;Выгода;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521984202;3;1
4aba3b18-749b-11df-b112-00215aee3ebe;Вигода;Выгода;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820981903;3;1
4aba3b19-749b-11df-b112-00215aee3ebe;Вигода;Выгода;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825285802;2;1
cdc44f67-dde8-11df-9197-00215aee3ebe;Вигода;Выгода;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624484102;3;1
4aba3b1a-749b-11df-b112-00215aee3ebe;Вигодівка;Выгодовка;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622080502;3;1
4aba3b1b-749b-11df-b112-00215aee3ebe;Вигон;Выгон;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120887706;3;1
4aba3b1c-749b-11df-b112-00215aee3ebe;Виграїв;Выграев;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122581001;3;1
4aba3b1d-749b-11df-b112-00215aee3ebe;Вигуричі;Вигуричи;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722886802;3;1
4aba3b1e-749b-11df-b112-00215aee3ebe;Видава;Видава;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820981203;3;1
4aba3b1f-749b-11df-b112-00215aee3ebe;Видвиженець;Выдвиженец;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;*122328200;3;1
4aba3b20-749b-11df-b112-00215aee3ebe;Видень;Видень;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822381402;3;1
4aba3b21-749b-11df-b112-00215aee3ebe;Видерта;Выдерта;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721482001;3;1
4aba3b22-749b-11df-b112-00215aee3ebe;Видибор;Выдыбор;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825681601;3;1
4aba3b23-749b-11df-b112-00215aee3ebe;Видинів;Видинов;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625280801;3;1
4aba3b26-749b-11df-b112-00215aee3ebe;Видне;Видное;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520383502;3;1
4aba3b27-749b-11df-b112-00215aee3ebe;Виднівка;Видновка;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922387403;3;1
4aba3b29-749b-11df-b112-00215aee3ebe;Видошня;Видошня;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825883602;3;1
4aba3b2a-749b-11df-b112-00215aee3ebe;Видра;Выдра;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620385305;3;1
4aba3b2b-749b-11df-b112-00215aee3ebe;Видраниця;Выдраница;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724280801;3;1
4aba3b2c-749b-11df-b112-00215aee3ebe;Видричі;Выдричи;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721482201;3;1
4aba3b2d-749b-11df-b112-00215aee3ebe;Видричка;Выдричка;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123682201;3;1
4aba3b2e-749b-11df-b112-00215aee3ebe;Видумка;Выдумка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825480403;3;1
4aba3b2f-749b-11df-b112-00215aee3ebe;Видумка;Выдумка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510107;3;1
4aba3b30-749b-11df-b112-00215aee3ebe;Вижгів;Выжгов;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723384702;3;1
4aba3b31-749b-11df-b112-00215aee3ebe;Виженка;Виженка;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320581001;3;1
4aba3b32-749b-11df-b112-00215aee3ebe;Вижине;Вижино;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123182502;3;1
4aba3939-749b-11df-b112-00215aee3ebe;Вижичне;Вижично;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724281007;3;1
4aba3b33-749b-11df-b112-00215aee3ebe;Вижниця;Вижница;місто;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7320510100;3;0
4aba3b34-749b-11df-b112-00215aee3ebe;Вижній Березів;Выжний Березив;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623682801;3;1
4aba3b35-749b-11df-b112-00215aee3ebe;Вижняни;Выжняны;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621882203;3;1
4aba3b36-749b-11df-b112-00215aee3ebe;Визирівка;Визировка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924789103;3;1
4aba3b37-749b-11df-b112-00215aee3ebe;Визирка;Визирка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122780501;3;1
4aba3b39-749b-11df-b112-00215aee3ebe;Викоти;Выкоты;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624282301;3;1
4aba3b3a-749b-11df-b112-00215aee3ebe;Вила;Вилы;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755118;3;1
4aba3b3b-749b-11df-b112-00215aee3ebe;Вила;Вилы;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821484802;3;1
4aba3b3c-749b-11df-b112-00215aee3ebe;Вила;Вилы;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523981501;3;1
4aba3b3d-749b-11df-b112-00215aee3ebe;Вила-Ярузькі;Вилы-Яружские;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524980304;3;1
4aba3b3e-749b-11df-b112-00215aee3ebe;Вили;Вилы;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423881502;3;1
4aba3b3f-749b-11df-b112-00215aee3ebe;Вили;Вилы;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322887003;3;1
4aba3b40-749b-11df-b112-00215aee3ebe;Вилівка;Виловка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321785003;3;1
4aba3b42-749b-11df-b112-00215aee3ebe;Вилки;Вилки;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622880803;3;1
4aba3b43-749b-11df-b112-00215aee3ebe;Вилкове;Вилково;село;903ccab3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122310300;2;1
4aba3b44-749b-11df-b112-00215aee3ebe;Вилок;Вылок;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121255300;3;0
4aba3b45-749b-11df-b112-00215aee3ebe;Вильчики;Вильчики;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423682003;3;1
4aba3b46-749b-11df-b112-00215aee3ebe;Вимислівка;Вымысловка;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123081702;3;1
4aba3b47-749b-11df-b112-00215aee3ebe;Вимушів;Вымушев;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321580402;3;1
4aba3b48-749b-11df-b112-00215aee3ebe;Винарівка;Винаровка;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224282001;3;1
4aba3b49-749b-11df-b112-00215aee3ebe;Винарівка;Винаровка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822383602;3;1
4aba3b4a-749b-11df-b112-00215aee3ebe;Винімок;Винимок;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721486202;3;1
4aba3b4b-749b-11df-b112-00215aee3ebe;Винники;Винники;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322080402;3;1
4aba3b4c-749b-11df-b112-00215aee3ebe;Винники;Винники;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324285003;3;1
4aba3b4d-749b-11df-b112-00215aee3ebe;Винники;Винники;місто;8a199cde-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4610160300;1;0
4aba3b4e-749b-11df-b112-00215aee3ebe;Винники;Винники;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621288602;3;1
4aba3b4f-749b-11df-b112-00215aee3ebe;Виннички;Виннички;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623681201;3;1
4aba3b50-749b-11df-b112-00215aee3ebe;Виноград;Виноград;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7322586003;3;1
4aba3b51-749b-11df-b112-00215aee3ebe;Виноград;Виноград;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621680201;3;1
4aba3b52-749b-11df-b112-00215aee3ebe;Виноград;Виноград;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623280801;3;1
4aba3b53-749b-11df-b112-00215aee3ebe;Виноград;Виноград;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122882001;3;1
56bdd278-749b-11df-b112-00215aee3ebe;Виноград (Жовтнівка);Виноград (Жовтневка);село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121282003;3;1
4aba3b54-749b-11df-b112-00215aee3ebe;Виноградар;Виноградарь;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123980701;3;1
4aba3b55-749b-11df-b112-00215aee3ebe;Виноградів;Виноградов;місто;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121210100;2;0
4aba3b56-749b-11df-b112-00215aee3ebe;Виноградівка;Виноградовка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222385002;3;1
4aba3b57-749b-11df-b112-00215aee3ebe;Виноградівка;Виноградовка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224588501;3;1
4aba3b58-749b-11df-b112-00215aee3ebe;Виноградівка;Виноградовка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820683402;3;1
4aba3b59-749b-11df-b112-00215aee3ebe;Виноградівка;Виноградовка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821755106;3;1
4aba3b5a-749b-11df-b112-00215aee3ebe;Виноградівка;Виноградовка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125484702;3;1
4aba3b5b-749b-11df-b112-00215aee3ebe;Виноградівка;Виноградовка;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120480701;3;1
4aba3b5c-749b-11df-b112-00215aee3ebe;Виноградівка;Виноградовка;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124781001;3;1
4aba3b5d-749b-11df-b112-00215aee3ebe;Виноградівка;Виноградовка;село;6b07f8a2-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5121481601;3;1
4aba3b5f-749b-11df-b112-00215aee3ebe;Виноградівка;Виноградовка;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823184302;3;1
4aba3b60-749b-11df-b112-00215aee3ebe;Виноградівка;Виноградовка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522880901;3;1
4aba3b61-749b-11df-b112-00215aee3ebe;Виноградівка;Виноградовка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825882601;3;1
10817924-5c67-11ea-80c6-000c29800ae7;Виноградівка; Виноградовка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123985302;3;1
74dc4b67-749b-11df-b112-00215aee3ebe;Виноградівка (Піонерськ);Виноградовка (Пионерск);село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123985302;3;1
4aba3b62-749b-11df-b112-00215aee3ebe;Виноградне;Виноградное;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122084002;3;1
4aba3b63-749b-11df-b112-00215aee3ebe;Виноградне;Виноградное;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221488002;3;1
4aba3b64-749b-11df-b112-00215aee3ebe;Виноградне;Виноградное;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825781403;3;1
4aba3b65-749b-11df-b112-00215aee3ebe;Виноградне;Виноградное;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121285602;3;1
4aba3b66-749b-11df-b112-00215aee3ebe;Виноградне;Виноградное;село;6b07f8a2-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5121481401;3;1
4aba3b6f-749b-11df-b112-00215aee3ebe;Виноградне;Виноградное;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522880802;3;1
4aba3b70-749b-11df-b112-00215aee3ebe;Виноградне;Виноградное;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523180303;3;1
62c3d145-749b-11df-b112-00215aee3ebe;Виноградне (Ленінка);Виноградное (Ленинка);село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820982202;3;1
7ad94f3b-749b-11df-b112-00215aee3ebe;Виноградне (Радгоспне);Виноградное (Радгоспное);село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925081203;3;1
4aba3b73-749b-11df-b112-00215aee3ebe;Виноградний Сад;Виноградный Сад;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822784007;3;1
4aba3b74-749b-11df-b112-00215aee3ebe;Виноградний Яр;Виноградный Яр;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820380403;3;1
4aba3b77-749b-11df-b112-00215aee3ebe;Винокурня;Винокурня;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524355601;3;1
4aba3b78-749b-11df-b112-00215aee3ebe;Винторівка;Винторовка;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924410101;3;1
4aba3b79-749b-11df-b112-00215aee3ebe;Винятинці;Винятинцы;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122081701;3;1
4aba3b7a-749b-11df-b112-00215aee3ebe;Випасне;Выпасное;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120882001;3;1
4aba3b7d-749b-11df-b112-00215aee3ebe;Виписки;Выписки;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623381002;3;1
4aba3b7e-749b-11df-b112-00215aee3ebe;Виповзів;Выповзив;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422083002;3;1
4aba3b7f-749b-11df-b112-00215aee3ebe;Виповзки;Выповзки;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223380601;3;1
4aba3b80-749b-11df-b112-00215aee3ebe;Випчина;Выпчина;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323585002;3;1
4aba3b81-749b-11df-b112-00215aee3ebe;Вир;Выр;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424483303;3;1
4aba3b82-749b-11df-b112-00215aee3ebe;Вирва;Вырва;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825084303;3;1
4aba3b83-749b-11df-b112-00215aee3ebe;Вири;Выры;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920681801;3;1
4aba3b84-749b-11df-b112-00215aee3ebe;Вири;Выры;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625480901;3;1
4aba3b85-749b-11df-b112-00215aee3ebe;Вирів;Выров;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622182001;3;0
4aba3b86-749b-11df-b112-00215aee3ebe;Вирівка;Выривка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922081101;3;1
4aba3b87-749b-11df-b112-00215aee3ebe;Вирішальне;Виришальное;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322681901;3;1
4aba3b88-749b-11df-b112-00215aee3ebe;Вирішальне;Виришальное;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320482602;3;1
4aba3b89-749b-11df-b112-00215aee3ebe;Вирішальний;Выришальный;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321710101;3;1
4aba3b8a-749b-11df-b112-00215aee3ebe;Вирка;Вырка;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625480503;3;1
4aba3b8b-749b-11df-b112-00215aee3ebe;Вироби;Выробы;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824085002;3;1
4aba3b8c-749b-11df-b112-00215aee3ebe;Висачки;Висачки;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322881102;3;1
4aba3b8e-749b-11df-b112-00215aee3ebe;Виселок;Выселок;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820687503;3;1
4aba3b8f-749b-11df-b112-00215aee3ebe;Виселок;Выселок;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823382502;3;1
4aba3b90-749b-11df-b112-00215aee3ebe;Виселок Вільний;Выселок Свободный;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;;3;1
4aba3b91-749b-11df-b112-00215aee3ebe;Висипівці;Высыповцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122681601;3;1
4aba3b92-749b-11df-b112-00215aee3ebe;Висіч;Высоч;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625888202;3;1
4aba3b93-749b-11df-b112-00215aee3ebe;Висічка;Высечка;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120881901;3;1
4aba3b95-749b-11df-b112-00215aee3ebe;Вислобоки;Вислобоки;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622185202;3;1
4aba3bb1-749b-11df-b112-00215aee3ebe;Висове;Высово;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625487602;3;1
4aba3b97-749b-11df-b112-00215aee3ebe;Висока Вакулівка;Высокая Вакуловка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322081201;3;1
4aba3b98-749b-11df-b112-00215aee3ebe;Висока Гора;Высокая Гора;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821455102;3;1
4aba3b99-749b-11df-b112-00215aee3ebe;Висока Гребля;Высокая Гребля;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521980202;3;1
4aba3b96-749b-11df-b112-00215aee3ebe;Висока Піч;Високая Печь;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822081501;3;1
4aba3b9a-749b-11df-b112-00215aee3ebe;Висока Яруга;Високая Яруга;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322081504;3;1
4aba3b9b-749b-11df-b112-00215aee3ebe;Високе;Високое;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321381501;3;1
4aba3b9c-749b-11df-b112-00215aee3ebe;Високе;Високое;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922381202;3;1
4aba3b9d-749b-11df-b112-00215aee3ebe;Високе;Високое;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920381201;3;1
4aba3b9e-749b-11df-b112-00215aee3ebe;Високе;Високое;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723381903;3;1
4aba3b9f-749b-11df-b112-00215aee3ebe;Високе;Високое;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124281201;3;1
4aba3ba0-749b-11df-b112-00215aee3ebe;Високе;Високое;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222082005;3;1
4aba3ba1-749b-11df-b112-00215aee3ebe;Високе;Високое;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222984302;3;1
4aba3ba2-749b-11df-b112-00215aee3ebe;Високе;Високое;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323381002;3;1
4aba3ba3-749b-11df-b112-00215aee3ebe;Високе;Високое;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324582502;3;1
4aba3ba6-749b-11df-b112-00215aee3ebe;Високе;Високое;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520680601;3;1
4aba3baa-749b-11df-b112-00215aee3ebe;Високе;Високое;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224680801;3;1
4aba3bab-749b-11df-b112-00215aee3ebe;Високе;Високое;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825682001;3;1
4aba3bac-749b-11df-b112-00215aee3ebe;Високе;Високое;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820986003;3;1
4aba3bad-749b-11df-b112-00215aee3ebe;Високе;Високое;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422485503;3;1
4aba3bae-749b-11df-b112-00215aee3ebe;Високе;Високое;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420881201;3;1
4aba3baf-749b-11df-b112-00215aee3ebe;Високе;Високое;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424188502;3;1
4aba3bb0-749b-11df-b112-00215aee3ebe;Високе;Високое;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523981701;3;1
4aba3bb2-749b-11df-b112-00215aee3ebe;Високе;Високое;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323886202;3;1
4aba3bb3-749b-11df-b112-00215aee3ebe;Високе;Високое;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322681903;3;1
44ac801c-749b-11df-b112-00215aee3ebe;Високе (Артемівка);Високе (Артемівка);село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323186003;3;1
4aba3e67-749b-11df-b112-00215aee3ebe;Високе (Володимирівка);Високе (Володимирівка);село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455107;3;1
56bdd243-749b-11df-b112-00215aee3ebe;Високе (Жовтневе);Высокое (Жовтневое);село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320483503;3;1
4aba3bb4-749b-11df-b112-00215aee3ebe;Високе Поле;Высокое Поле;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221885902;3;1
4aba3bb5-749b-11df-b112-00215aee3ebe;Високий;Высокий;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325156700;2;0
4aba3bb6-749b-11df-b112-00215aee3ebe;Високий Камінь;Высокий Камень;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822581703;3;1
4aba3bb7-749b-11df-b112-00215aee3ebe;Високинь;Высокинь;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424488002;3;1
4aba3bb8-749b-11df-b112-00215aee3ebe;Високі Байраки;Высокие Байраки;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522582101;3;1
4aba3bb9-749b-11df-b112-00215aee3ebe;Високогірне;Высокогорное;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322183503;3;1
4aba3bbb-749b-11df-b112-00215aee3ebe;Високопілля;Высокополье;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321281501;3;1
4aba3bbc-749b-11df-b112-00215aee3ebe;Високопілля;Высокополье;місто;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521855100;3;1
4aba3bbe-749b-11df-b112-00215aee3ebe;Високопіль;Высокополь;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222982202;3;1
4aba3bc0-749b-11df-b112-00215aee3ebe;Високофедорівка;Высокофедоровка;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622183003;3;1
4aba3bc1-749b-11df-b112-00215aee3ebe;Висоцьк;Высоцк;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723387202;3;1
4aba3bc2-749b-11df-b112-00215aee3ebe;Висоцьк;Высоцк;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621882001;3;1
4aba3bc3-749b-11df-b112-00215aee3ebe;Висоцьке;Высоцкое;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524083203;3;1
4aba3bc4-749b-11df-b112-00215aee3ebe;Висоцько;Высоцкое;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620382004;3;1
4aba3bc5-749b-11df-b112-00215aee3ebe;Височанка;Высочанка;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621281301;3;1
4aba3bc6-749b-11df-b112-00215aee3ebe;Височанове;Высочаново;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520887602;3;1
4aba3bc7-749b-11df-b112-00215aee3ebe;Височанське;Высочанское;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124782601;3;1
7ad94e44-749b-11df-b112-00215aee3ebe;Височинівка (Пролетарське);Височинівка (Пролетарське);село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321786505;3;1
4aba3bc9-749b-11df-b112-00215aee3ebe;Височне;Высочное;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724281001;3;1
4aba3bca-749b-11df-b112-00215aee3ebe;Виспа;Выспа;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624481201;3;1
4aba3bcb-749b-11df-b112-00215aee3ebe;Виступовичі;Выступовичи;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824286709;3;1
4aba3bcc-749b-11df-b112-00215aee3ebe;Висунськ;Висунск;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821180701;3;1
4aba3bcd-749b-11df-b112-00215aee3ebe;Висунь;Висунь;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823682203;3;1
4aba3bce-749b-11df-b112-00215aee3ebe;Вись;Вись;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523183602;3;1
4aba3bcf-749b-11df-b112-00215aee3ebe;Витань;Витань;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624455302;3;1
4aba3bd0-749b-11df-b112-00215aee3ebe;Витачів;Вытачев;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223181501;3;1
4aba3bd1-749b-11df-b112-00215aee3ebe;Витвиця;Витвица;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622080501;3;1
4aba3bd3-749b-11df-b112-00215aee3ebe;Витень;Витень;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724281009;3;1
4aba3bd2-749b-11df-b112-00215aee3ebe;Витилівка;Витилевка;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322585002;3;1
4aba3bd4-749b-11df-b112-00215aee3ebe;Витівка;Вытевка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081403;3;1
4aba3bd5-749b-11df-b112-00215aee3ebe;Витків;Витков;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621283202;3;1
4aba3bd6-749b-11df-b112-00215aee3ebe;Витківці;Витковцы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122483302;3;1
4aba3bd7-749b-11df-b112-00215aee3ebe;Витуле;Витуле;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155110;3;1
4aba3bd8-749b-11df-b112-00215aee3ebe;Витязівка;Витязевка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520883201;3;1
4aba3bd9-749b-11df-b112-00215aee3ebe;Вихватнівці;Выхватневцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822483902;3;1
4aba3bda-749b-11df-b112-00215aee3ebe;Вихвостів;Выхвостов;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421482801;3;1
4aba3bdb-749b-11df-b112-00215aee3ebe;Вихилівка;Вихилевка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825885202;3;1
4aba3bdc-749b-11df-b112-00215aee3ebe;Вихля;Выхля;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825082202;3;1
4aba3bdd-749b-11df-b112-00215aee3ebe;Вихопні;Выхопни;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622183302;3;1
4aba3bde-749b-11df-b112-00215aee3ebe;Вихрівка;Вихровка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821881201;3;1
4aba3bdf-749b-11df-b112-00215aee3ebe;Виців;Вицев;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625182102;3;1
4aba3be0-749b-11df-b112-00215aee3ebe;Вичавки;Вычавки;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621485503;3;1
4aba3be1-749b-11df-b112-00215aee3ebe;Вичівка;Вичевка;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622280701;3;1
4aba3be2-749b-11df-b112-00215aee3ebe;Вишарі;Вишары;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281802;3;1
4aba3be3-749b-11df-b112-00215aee3ebe;Вишгород;Вышгород;місто;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221810100;2;0
4aba3be4-749b-11df-b112-00215aee3ebe;Вишгородок;Вышгородок;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123883001;3;1
4aba3be5-749b-11df-b112-00215aee3ebe;Вишевичі;Вышевичи;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825081801;3;1
4aba3be6-749b-11df-b112-00215aee3ebe;Вишеград;Вышеград;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222784603;3;1
4aba3be8-749b-11df-b112-00215aee3ebe;Вишенька;Вишенька;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622485102;3;1
4aba3be9-749b-11df-b112-00215aee3ebe;Вишенька;Вишенька;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524881201;3;1
4aba3bea-749b-11df-b112-00215aee3ebe;Вишенька;Вишенька;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522484603;3;1
4aba3beb-749b-11df-b112-00215aee3ebe;Вишеньки;Вишенки;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320680702;3;1
4aba3bec-749b-11df-b112-00215aee3ebe;Вишеньки;Вишенки;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921589802;3;1
4aba3bed-749b-11df-b112-00215aee3ebe;Вишеньки;Вишенки;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724584602;3;1
4aba3bee-749b-11df-b112-00215aee3ebe;Вишеньки;Вишенки;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125081302;3;1
4aba3bef-749b-11df-b112-00215aee3ebe;Вишеньки;Вишенки;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220881301;3;0
4aba3bf0-749b-11df-b112-00215aee3ebe;Вишеньки;Вишенки;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422281501;3;1
4aba3bf1-749b-11df-b112-00215aee3ebe;Вишеньки;Вишенки;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624288003;3;1
4aba3bf2-749b-11df-b112-00215aee3ebe;Вишеньки;Вишенки;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322680402;3;1
4aba3bf3-749b-11df-b112-00215aee3ebe;Вишів;Вышев;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823482601;3;1
4aba3bf4-749b-11df-b112-00215aee3ebe;Вишка;Вышка;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120881601;3;1
4aba3bf5-749b-11df-b112-00215aee3ebe;Вишків;Вышков;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622080801;3;1
4aba3bf6-749b-11df-b112-00215aee3ebe;Вишківка;Вышковка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824086803;3;1
4aba3bf7-749b-11df-b112-00215aee3ebe;Вишківське;Вышковское;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224282003;3;1
4aba3bf9-749b-11df-b112-00215aee3ebe;Вишківці;Вышковцы;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523082001;3;1
4aba3bfa-749b-11df-b112-00215aee3ebe;Вишково;Вышково;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125355300;3;0
4aba3bfc-749b-11df-b112-00215aee3ebe;Вишнева;Вишневая;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320282001;3;1
aaf5578b-2be8-11e7-80fe-1c98ec135263;Вишневе;Вишневое;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123355101;3;1
c8674a9c-dbb0-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323886203;3;1
17af7b19-ddb9-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524982703;3;1
c8674a8d-dbb0-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125081201;2;1
a5388ebf-db65-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825581302;3;1
c6cc1bf9-db7e-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922084902;3;1
c6cc1bfd-db7e-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925080402;3;1
f933344f-db4e-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424481201;3;1
f9333450-db4e-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423885502;3;1
e0517ecf-db89-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820355101;3;1
c6cc1c08-db7e-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321082503;3;1
c6cc1c0a-db7e-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321284005;3;1
cdc44f4c-dde8-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510108;3;1
8cdd5cf9-de27-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520985002;3;1
8cdd5ce2-de27-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521980703;3;1
cdc44f3d-dde8-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222386503;3;1
73e94c1f-ddc1-11df-9197-00215aee3ebe;Вишневе;Вишневое;місто;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222410600;1;0
325c580b-ddd2-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120689502;3;1
17af7b22-ddb9-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1223210505;3;1
17af7b27-ddb9-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224284501;3;1
17af7b25-ddb9-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224555300;3;1
17af7b31-ddb9-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225283802;3;1
17af7b49-ddb9-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822086502;3;1
17af7b34-ddb9-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225884802;3;1
73e94be5-ddc1-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825282001;3;1
73e94be7-ddc1-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825687402;3;1
73e94bff-ddc1-11df-9197-00215aee3ebe;Вишневе;Вишневое;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321586103;3;1
5dd319c7-c2d9-11e2-bb38-003048d2b473;Вишневе;Вишневе;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621483503;3;1
56bdd245-749b-11df-b112-00215aee3ebe;Вишневе (Жовтневе);Вишневое (Жовтневое);село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123355101;3;1
56bdd22d-749b-11df-b112-00215aee3ebe;Вишневе (Жовтневе);Вишневое (Жовтневое);село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924788704;3;1
56bdd268-749b-11df-b112-00215aee3ebe;Вишневе (Жовтневе);Вишневое (Жовтневое);село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521682209;3;1
73e94c12-ddc1-11df-9197-00215aee3ebe;Вишневе (Згурівський район);Вишневое;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221982003;3;1
5cb61714-749b-11df-b112-00215aee3ebe;Вишневе (Кірове);Вишневе (Кірове);село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121681901;3;1
62c3cf23-749b-11df-b112-00215aee3ebe;Вишневе (Куйбишеве);Вишневое (Куйбышево);село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323682601;3;1
74dc491f-749b-11df-b112-00215aee3ebe;Вишневе (Петрівка);Вишневе (Петрівка);село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324884601;3;1
74dc49b5-749b-11df-b112-00215aee3ebe;Вишневе (Петровського);Вишневое (Петровского);село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122785505;3;1
7ad94f41-749b-11df-b112-00215aee3ebe;Вишневе (Радгоспне);Вишневе (Радгоспне);село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320685101;3;1
7ad94f96-749b-11df-b112-00215aee3ebe;Вишневе (Радянське);Вишневое (Радянское);село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055108;3;1
7ad954bf-749b-11df-b112-00215aee3ebe;Вишневе (Свердловське);Вишневое (Свердловское);село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321880709;3;1
87162590-749b-11df-b112-00215aee3ebe;Вишневе (Чапаєве);Вишневое (Чапаево);село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323382003;3;1
87162685-749b-11df-b112-00215aee3ebe;Вишневе (Червоний Агроном);Вишневое (Червоный Агроном);село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283605;3;1
871626f2-749b-11df-b112-00215aee3ebe;Вишневе (Червоноармійське);Вишневое (Червоноармейское);село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322280504;3;1
871626fb-749b-11df-b112-00215aee3ebe;Вишневе (Червоноармійське);Вишневое (Червоноармейское);село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420610106;3;1
87162330-749b-11df-b112-00215aee3ebe;Вишневецьке (Фрунзе);Вишневецкое (Фрунзе);село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224888206;3;1
4aba3bfe-749b-11df-b112-00215aee3ebe;Вишневий Яр;Вишневый Яр;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920986402;3;1
4aba3bff-749b-11df-b112-00215aee3ebe;Вишнів;Вишнев;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723380401;3;1
4aba3c00-749b-11df-b112-00215aee3ebe;Вишнів;Вишнев;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721880802;3;1
4aba3c01-749b-11df-b112-00215aee3ebe;Вишнів;Вишнев;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624455304;3;1
4aba3c02-749b-11df-b112-00215aee3ebe;Вишнівець;Вишневец;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122455300;3;0
4aba3c03-749b-11df-b112-00215aee3ebe;Вишнівка;Вишневка;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723384906;3;1
4aba3c06-749b-11df-b112-00215aee3ebe;Вишнівка;Вишневка;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625255301;3;1
4aba3c08-749b-11df-b112-00215aee3ebe;Вишнівка;Вишневка;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820682202;3;1
4aba3c09-749b-11df-b112-00215aee3ebe;Вишнівка;Вишневка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421787603;3;1
4aba3c0a-749b-11df-b112-00215aee3ebe;Вишнівка;Вишневка;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125185203;3;1
4aba3c0b-749b-11df-b112-00215aee3ebe;Вишнівка;Вишневка;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523481002;3;1
4aba3c0c-749b-11df-b112-00215aee3ebe;Вишнівка;Вишневка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522884302;3;1
4aba3c0d-749b-11df-b112-00215aee3ebe;Вишнівське;Вишневское;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420381003;3;1
4aba3c0e-749b-11df-b112-00215aee3ebe;Вишнівці;Вишневцы;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524680801;3;1
4aba3bf8-749b-11df-b112-00215aee3ebe;Вишнівці;Вишневцы;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624086402;3;1
4aba3c0f-749b-11df-b112-00215aee3ebe;Вишнівчик;Вишневчик;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125081301;3;1
4aba3c10-749b-11df-b112-00215aee3ebe;Вишнівчик;Вишневчик;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623382201;3;1
4aba3c11-749b-11df-b112-00215aee3ebe;Вишнівчик;Вишневчик;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825281201;2;1
4aba3c12-749b-11df-b112-00215aee3ebe;Вишній Дубовець;Вышний Дубовец;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124455601;3;1
4aba3c13-749b-11df-b112-00215aee3ebe;Вишнопіль;Вишнополь;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124081201;3;1
4aba3c14-749b-11df-b112-00215aee3ebe;Вишнопіль;Вишнополь;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824282001;3;1
4aba3c16-749b-11df-b112-00215aee3ebe;Вишня;Вишня;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620981401;3;1
4aba3c17-749b-11df-b112-00215aee3ebe;Вишня;Вишня;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224985702;3;1
4aba3c18-749b-11df-b112-00215aee3ebe;Вишняки;Вишняки;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324881201;3;1
4aba3c19-749b-11df-b112-00215aee3ebe;Вишняки;Вишняки;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322080802;3;1
4aba3c1a-749b-11df-b112-00215aee3ebe;Вишняки;Вишняки;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120481002;3;1
4aba3c1b-749b-11df-b112-00215aee3ebe;Вишняки;Вишняки;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582503;3;1
4aba3c1c-749b-11df-b112-00215aee3ebe;Вишняки;Вишняки;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221084502;3;1
4aba3c1d-749b-11df-b112-00215aee3ebe;Вишняки;Вишняки;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224987203;3;1
4aba3c1e-749b-11df-b112-00215aee3ebe;Вишняківка;Вишняковка;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281207;3;1
4aba3c20-749b-11df-b112-00215aee3ebe;Вишняківка;Вишняковка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522581601;3;1
4aba3bfb-749b-11df-b112-00215aee3ebe;Вишняківка;Вишняковка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821182002;3;1
4aba3c21-749b-11df-b112-00215aee3ebe;Вишнянка;Вишнянка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823485802;3;1
4aba3c22-749b-11df-b112-00215aee3ebe;Вишоватий;Вышоватый;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124487402;3;1
4aba3c23-749b-11df-b112-00215aee3ebe;Вишпіль;Вышполь;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825684402;3;1
4aba3c24-749b-11df-b112-00215aee3ebe;Вища Дубечня;Высшая Дубечня;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221881601;3;1
4aba3c25-749b-11df-b112-00215aee3ebe;Вища Кропивна;Высшая Крапивна;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523055601;3;1
4aba3c26-749b-11df-b112-00215aee3ebe;Вище Солоне;Высшее Соленое;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321081001;3;1
4aba3c27-749b-11df-b112-00215aee3ebe;Вищевеселе;Высшевеселое;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921280801;3;1
4aba3c28-749b-11df-b112-00215aee3ebe;Вищеольчедаїв;Высшеольчедаев;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522881601;3;1
4aba3c29-749b-11df-b112-00215aee3ebe;Вищетарасівка;Высшетарасовка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225482501;3;1
4aba3c2a-749b-11df-b112-00215aee3ebe;Вищий Булатець;Высший Булатец;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322881401;3;1
4aba3c2b-749b-11df-b112-00215aee3ebe;Вищі Верещаки;Высшие Верещаки;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520582001;3;1
4aba3c2c-749b-11df-b112-00215aee3ebe;Вищі Вільшани;Высшие Ольшаны;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086203;3;1
4aba3c2d-749b-11df-b112-00215aee3ebe;Вищі Вовківці;Высшие Волковцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825087203;3;1
4aba3c2e-749b-11df-b112-00215aee3ebe;Вищі Луб'янки;Высшие Лубянки;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122481501;3;1
4aba3c2f-749b-11df-b112-00215aee3ebe;Вібли;Виблы;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321888003;3;1
4aba3c30-749b-11df-b112-00215aee3ebe;Вівня;Вовня;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625380201;3;1
4aba3c31-749b-11df-b112-00215aee3ebe;Вівся;Вився;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123081401;3;1
4aba3c32-749b-11df-b112-00215aee3ebe;Вівся;Вився;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825280801;2;1
4aba3c33-749b-11df-b112-00215aee3ebe;Вівсяники;Овсяники;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521485603;3;1
4aba3c34-749b-11df-b112-00215aee3ebe;Вівсяники;Овсяники;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524382701;3;1
4aba3c35-749b-11df-b112-00215aee3ebe;Вівсяники;Овсяники;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821584402;3;1
4aba3c36-749b-11df-b112-00215aee3ebe;Вівсянівка;Овсяновка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824580403;3;1
4aba3c37-749b-11df-b112-00215aee3ebe;Вівчарне;Овчарное;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582004;3;1
4aba3c38-749b-11df-b112-00215aee3ebe;Вівчарня;Овчарня;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524985503;3;1
4aba3c3a-749b-11df-b112-00215aee3ebe;Вівче;Вивче;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825087202;3;1
4aba3c3b-749b-11df-b112-00215aee3ebe;Вівчицьк;Вивчицк;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155302;3;1
4aba3c3e-749b-11df-b112-00215aee3ebe;Відники;Водники;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623683102;3;1
4aba3c3f-749b-11df-b112-00215aee3ebe;Віднів;Виднев;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622786402;3;1
4aba3c41-749b-11df-b112-00215aee3ebe;Відрада;Отрада;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290007;3;1
4aba3c42-749b-11df-b112-00215aee3ebe;Відрадне;Отрадное;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224280507;3;1
4aba3c43-749b-11df-b112-00215aee3ebe;Відрадне;Отрадное;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322180402;3;1
4aba3c47-749b-11df-b112-00215aee3ebe;Відрадне;Отрадное;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423886002;3;1
4aba3c48-749b-11df-b112-00215aee3ebe;Відрадне;Отрадное;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320886202;3;1
4aba3c49-749b-11df-b112-00215aee3ebe;Відродженівське;Видродженовское;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322683502;3;1
4aba3c4a-749b-11df-b112-00215aee3ebe;Відродження;Видродження;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223881503;3;1
4aba3c4d-749b-11df-b112-00215aee3ebe;Відродження;Видродження;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785902;3;1
4aba3c50-749b-11df-b112-00215aee3ebe;Відродження;Видродження;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824788202;3;1
99a4cd2c-6a80-11e0-b29c-003048d2b472;Відродження;Видродження;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824788202;2;1
4aba3c52-749b-11df-b112-00215aee3ebe;Відути;Видуты;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725586703;3;1
4aba3c53-749b-11df-b112-00215aee3ebe;Віжомля;Вижомля;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625882201;3;1
4aba3c54-749b-11df-b112-00215aee3ebe;Візня;Визня;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823482202;3;1
4aba3c55-749b-11df-b112-00215aee3ebe;Війниця;Войница;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722481201;3;1
4aba3c56-749b-11df-b112-00215aee3ebe;Війниця;Войница;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623881801;3;1
4aba3c57-749b-11df-b112-00215aee3ebe;Військове;Войсковое;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225081901;3;1
4aba3c58-749b-11df-b112-00215aee3ebe;Війтенки;Вийтенки;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321280502;3;1
2334a986-dd4b-11df-9197-00215aee3ebe;Війтівка;Вийтовка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520485803;3;1
871625ac-749b-11df-b112-00215aee3ebe;Війтівка (Чапаєвка);Вийтовка (Чапаевка);село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520485803;3;1
4aba3c5a-749b-11df-b112-00215aee3ebe;Війтівці;Войтовцы;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820955300;3;1
56bdd1ba-749b-11df-b112-00215aee3ebe;Війтівці (Жданівка);Войтовцы (Ждановка);село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524881801;3;1
4aba3c5b-749b-11df-b112-00215aee3ebe;Війтівщина;Вийтивщина;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625888402;3;1
4aba3c5c-749b-11df-b112-00215aee3ebe;Вікентіївка;Викентиевка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522283802;3;1
4aba3c5d-749b-11df-b112-00215aee3ebe;Вікентове;Викентово;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820383502;3;1
4aba3c5e-749b-11df-b112-00215aee3ebe;Вікнина;Викнина;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521184401;3;1
4aba3c5f-749b-11df-b112-00215aee3ebe;Вікнине;Окнино;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322682502;3;1
4aba3c60-749b-11df-b112-00215aee3ebe;Вікнине;Окнино;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122284501;3;1
4aba3c61-749b-11df-b112-00215aee3ebe;Вікнини;Викнины;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820382501;3;1
4aba3c62-749b-11df-b112-00215aee3ebe;Вікно;Окно;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121685101;3;1
4aba3c63-749b-11df-b112-00215aee3ebe;Вікно;Окно;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321586001;3;1
4aba3c64-749b-11df-b112-00215aee3ebe;Вікно;Окно;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621682601;3;1
4aba3c65-749b-11df-b112-00215aee3ebe;Вікняне;Окняное;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625610103;3;1
4aba3c66-749b-11df-b112-00215aee3ebe;Вікняне;Окняное;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521783002;3;1
4aba3c68-749b-11df-b112-00215aee3ebe;Вікторинівка;Викториновка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920982002;3;1
4aba3c69-749b-11df-b112-00215aee3ebe;Вікторів;Викторов;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621281201;3;1
4aba3c6a-749b-11df-b112-00215aee3ebe;Вікторівка;Викторовка;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123081501;3;1
4aba3c6b-749b-11df-b112-00215aee3ebe;Вікторівка;Викторовка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821485103;3;1
4aba3c6c-749b-11df-b112-00215aee3ebe;Вікторівка;Викторовка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822781503;3;1
4aba3c6d-749b-11df-b112-00215aee3ebe;Вікторівка;Викторовка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820955107;3;1
4aba3c6e-749b-11df-b112-00215aee3ebe;Вікторівка;Викторовка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125480701;3;1
4aba3c6f-749b-11df-b112-00215aee3ebe;Вікторівка;Викторовка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121280801;3;1
4aba3c70-749b-11df-b112-00215aee3ebe;Вікторівка;Викторовка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323686602;3;1
4aba3c77-749b-11df-b112-00215aee3ebe;Вікторівка;Викторовка;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222980901;3;1
4aba3c78-749b-11df-b112-00215aee3ebe;Вікторівка;Викторовка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821782404;3;1
4aba3c79-749b-11df-b112-00215aee3ebe;Вікторівка;Викторовка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425588302;3;1
4aba3c7a-749b-11df-b112-00215aee3ebe;Вікторівка;Викторовка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423382401;3;1
4aba3c7b-749b-11df-b112-00215aee3ebe;Вікторівка;Викторовка;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124688502;3;1
4aba3c7d-749b-11df-b112-00215aee3ebe;Вікторівка;Викторовка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521481203;3;1
4aba3c7e-749b-11df-b112-00215aee3ebe;Вікторівка;Викторовка;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825289403;2;1
87162624-749b-11df-b112-00215aee3ebe;Вікторівка (Червона Нива);Викторовка (Червоная Нива);село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320888801;3;1
4aba3c80-749b-11df-b112-00215aee3ebe;Вікторія;Виктория;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323881401;3;1
4aba3c81-749b-11df-b112-00215aee3ebe;Вікторове;Викторово;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921588302;3;1
4aba3c67-749b-11df-b112-00215aee3ebe;Вікторяни;Викторяны;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722885402;3;1
4aba3c82-749b-11df-b112-00215aee3ebe;Віл;Вол;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155111;3;1
4aba3c84-749b-11df-b112-00215aee3ebe;Віленька;Виленка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510109;3;1
4aba3c85-749b-11df-b112-00215aee3ebe;Вілиця;Волица;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725755102;3;1
4aba3c87-749b-11df-b112-00215aee3ebe;Вілія;Вилия;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125882401;3;1
4aba3c88-749b-11df-b112-00215aee3ebe;Вілія;Вилия;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624282001;3;1
4aba3c8a-749b-11df-b112-00215aee3ebe;Вілля;Вилья;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620455703;3;1
4aba3c8b-749b-11df-b112-00215aee3ebe;Вілюничі;Вилюничи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625182604;3;1
4aba3c8c-749b-11df-b112-00215aee3ebe;Вілявче;Вилявче;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623387202;3;1
4aba3c8e-749b-11df-b112-00215aee3ebe;Вільгір;Вильгор;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621281203;3;1
4aba3c8f-749b-11df-b112-00215aee3ebe;Вілька-Підгородненська;Волька-Подгороднянская;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723310104;3;1
4aba3c90-749b-11df-b112-00215aee3ebe;Вілька-Садівська;Вилька-Садовская;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722482403;3;1
4aba3c91-749b-11df-b112-00215aee3ebe;Вільна;Вольная;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123488502;3;1
4aba3c93-749b-11df-b112-00215aee3ebe;Вільна Поляна;Вольная Поляна;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820680802;3;1
4aba3c94-749b-11df-b112-00215aee3ebe;Вільна Слобода;Вольная Слобода;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921581501;3;1
4aba3c95-749b-11df-b112-00215aee3ebe;Вільна Тарасівка;Вольная Тарасовка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220481001;2;1
4aba3c96-749b-11df-b112-00215aee3ebe;Вільна Терешківка;Вольная Терешковка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322486302;3;1
4aba3c99-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324585603;3;1
4aba3c9a-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322085003;3;1
4aba3c9b-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323084402;3;1
4aba3c9c-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922087802;3;1
4aba3c9d-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921280501;3;1
4aba3c9e-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223281501;3;1
4aba3c9f-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224585903;3;1
4aba3ca0-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055702;3;1
4aba3ca1-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224881101;3;1
4aba3ca2-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221881301;3;1
4aba3ca3-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225487502;3;1
4aba3ca4-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224281203;3;1
4aba3ca5-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1220387703;3;1
4aba3ca6-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323181302;3;1
4aba3ca7-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320283602;3;1
4aba3ca8-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323987503;3;1
4aba3ca9-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120885602;3;1
4aba3caa-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124781701;3;1
4aba3cab-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323683503;3;1
4aba3cad-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955405;3;1
4aba3cb0-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625687202;3;1
4aba3cb5-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221282003;3;1
4aba3cb6-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221984203;3;1
4aba3cb7-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222781501;3;1
4aba3cb8-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423081803;3;1
4aba3cb9-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422282001;3;1
4aba3cba-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;;3;1
4aba3cbb-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522682602;3;1
4aba3cbc-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524085602;3;1
4aba3cbd-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522581901;3;1
4aba3cbe-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822487602;2;1
4aba3cbf-749b-11df-b112-00215aee3ebe;Вільне;Вольное;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621888202;3;1
4aba3c83-749b-11df-b112-00215aee3ebe;Вільне (Віленка);Вольное (Виленка);село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225080505;3;1
7ad954bd-749b-11df-b112-00215aee3ebe;Вільне (Свердловка);Вольное (Свердловка);село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525286902;3;1
4aba3cc0-749b-11df-b112-00215aee3ebe;Вільне Друге;Вольное Второе;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320681503;3;1
cdc44f42-dde8-11df-9197-00215aee3ebe;Вільне Життя;Вильне Життя;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225255106;3;1
4aba3cc1-749b-11df-b112-00215aee3ebe;Вільне Запоріжжя;Вольное Запорожье;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824581201;3;1
4aba3cc2-749b-11df-b112-00215aee3ebe;Вільне Перше;Вольное Первое;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320681502;3;1
4aba3cc4-749b-11df-b112-00215aee3ebe;Вільний Посад;Вольный Посад;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221888202;3;1
4aba3cc5-749b-11df-b112-00215aee3ebe;Вільний Посад;Вольный Посад;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520385202;3;1
4aba3cc6-749b-11df-b112-00215aee3ebe;Вільний Степ;Вольная Степь;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323481802;3;1
4aba3cc7-749b-11df-b112-00215aee3ebe;Вільний Табір;Вольный Табор;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221888203;3;1
4aba3cc8-749b-11df-b112-00215aee3ebe;Вільний Яр;Вольный Яр;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822055204;3;1
4aba3cc9-749b-11df-b112-00215aee3ebe;Вільниця;Вольница;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325480701;3;1
4aba3ccb-749b-11df-b112-00215aee3ebe;Вільні Луки;Вольные Луки;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524082802;3;1
4aba3cca-749b-11df-b112-00215aee3ebe;Вільні Хутори;Вольные Хутора;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221081803;3;1
4aba3ccc-749b-11df-b112-00215aee3ebe;Вільноандріївка;Вольноандреевка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321584004;3;1
4aba3ccd-749b-11df-b112-00215aee3ebe;Вільногірськ;Вольногорск;місто;a1e9f9b0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1210200000;2;0
4aba3cce-749b-11df-b112-00215aee3ebe;Вільногрушівське;Вольногрушевское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582705;3;1
4aba3ccf-749b-11df-b112-00215aee3ebe;Вільнокур'янівське;Вольнокурьяновское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321584005;3;1
4aba3cd0-749b-11df-b112-00215aee3ebe;Вільнопілля;Вольнополье;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825282201;3;1
4aba3cd1-749b-11df-b112-00215aee3ebe;Вільноуланівське;Вольноулановское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582707;3;1
4aba3cd2-749b-11df-b112-00215aee3ebe;Вільня;Вольня;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822581501;3;1
4aba3cd3-749b-11df-b112-00215aee3ebe;Вільнянка;Вольнянка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582504;3;1
4aba3cd4-749b-11df-b112-00215aee3ebe;Вільнянка;Вольнянка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323987805;3;1
4aba3cd5-749b-11df-b112-00215aee3ebe;Вільнянка;Вольнянка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510111;3;1
4aba3cd6-749b-11df-b112-00215aee3ebe;Вільнянськ;Вольнянск;місто;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321510100;2;1
4aba3cd7-749b-11df-b112-00215aee3ebe;Вільськ;Вильск;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825681201;3;1
4aba3cd8-749b-11df-b112-00215aee3ebe;Вільха;Ольха;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821481001;3;1
4aba3cd9-749b-11df-b112-00215aee3ebe;Вільхи;Ольхи;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121581801;3;1
4aba3cda-749b-11df-b112-00215aee3ebe;Вільхівка;Ольховка;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720885001;3;1
4aba3cdb-749b-11df-b112-00215aee3ebe;Вільхівка;Ольховка;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325184001;3;1
4aba3cdc-749b-11df-b112-00215aee3ebe;Вільхівка;Ольховка;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624884001;3;1
4aba3cde-749b-11df-b112-00215aee3ebe;Вільхівка;Ольховка;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121986401;3;1
4aba3cdf-749b-11df-b112-00215aee3ebe;Вільхівка;Ольховка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821782405;3;1
4aba3ce0-749b-11df-b112-00215aee3ebe;Вільхівка;Ольховка;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620484003;3;1
4aba3ce1-749b-11df-b112-00215aee3ebe;Вільхівське;Ольховское;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321287502;3;1
4aba3ce2-749b-11df-b112-00215aee3ebe;Вільхівці;Ольховцы;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621683001;3;1
4aba3ce3-749b-11df-b112-00215aee3ebe;Вільхівці;Ольховцы;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621585901;3;1
4aba3ce4-749b-11df-b112-00215aee3ebe;Вільхівці;Ольховцы;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124485801;2;1
4aba3ce5-749b-11df-b112-00215aee3ebe;Вільхівці;Ольховцы;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825286201;2;1
4aba3ce6-749b-11df-b112-00215aee3ebe;Вільхівці-Лази;Ольховцы-Лазы;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124485804;3;1
4aba3ce7-749b-11df-b112-00215aee3ebe;Вільхівчик;Ольховчик;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121685201;3;1
4aba3ce8-749b-11df-b112-00215aee3ebe;Вільхівчик;Ольховчик;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124485802;3;1
4aba3ce9-749b-11df-b112-00215aee3ebe;Вільхівчик;Ольховчик;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122589402;3;1
4aba3cea-749b-11df-b112-00215aee3ebe;Вільхівщина;Ольховщина;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086506;3;1
4aba3ceb-749b-11df-b112-00215aee3ebe;Вільхова;Ольховая;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624480602;3;1
4aba3cec-749b-11df-b112-00215aee3ebe;Вільхова;Ольховая;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755119;3;1
4aba3ced-749b-11df-b112-00215aee3ebe;Вільховатий;Ольховатый;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123684002;3;1
4aba3cfd-749b-11df-b112-00215aee3ebe;Вільховатка;Ольховатка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321885401;3;1
4aba3cee-749b-11df-b112-00215aee3ebe;Вільхове;Ольховое;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321355402;3;1
4aba3cf0-749b-11df-b112-00215aee3ebe;Вільхове;Ольховое;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525587101;3;1
4aba3cf1-749b-11df-b112-00215aee3ebe;Вільховець;Ольховец;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120485502;3;1
4aba3cf2-749b-11df-b112-00215aee3ebe;Вільховець;Ольховец;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120885701;3;1
4aba3cf3-749b-11df-b112-00215aee3ebe;Вільховець;Ольховец;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623387602;3;1
4aba3cf4-749b-11df-b112-00215aee3ebe;Вільховець;Ольховец;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220685301;3;1
4aba3cf5-749b-11df-b112-00215aee3ebe;Вільховець;Ольховец;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121285201;3;1
4aba3cf6-749b-11df-b112-00215aee3ebe;Вільховець;Ольховец;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822484202;3;1
4aba3cf7-749b-11df-b112-00215aee3ebe;Вільховець;Ольховец;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823386501;3;1
4aba3cf8-749b-11df-b112-00215aee3ebe;Вільховецьке;Ольховецкое;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521183602;3;1
4aba3cf9-749b-11df-b112-00215aee3ebe;Вільховий Ріг;Ольховый Рог;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324084004;3;1
4aba3cfa-749b-11df-b112-00215aee3ebe;Вільховий Ріг;Ольховый Рог;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323382502;3;1
4aba3cfb-749b-11df-b112-00215aee3ebe;Вільховиця;Ольховица;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122781002;3;1
4aba3cfc-749b-11df-b112-00215aee3ebe;Вільхуватка;Ольховатка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325482701;3;1
4aba3cff-749b-11df-b112-00215aee3ebe;Вільхуватка;Ольховатка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324280303;3;1
4aba3d00-749b-11df-b112-00215aee3ebe;Вільхуватка;Ольховатка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320284403;3;1
325c57f0-ddd2-11df-9197-00215aee3ebe;Вільча;Вильча;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;a8bc35df-4aa1-11e3-9df6-003048d2b473;;3;1
4aba3d01-749b-11df-b112-00215aee3ebe;Вільшана;Ольшана;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923584401;3;1
4aba3d03-749b-11df-b112-00215aee3ebe;Вільшана;Ольшана;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421787201;3;1
4aba3d04-749b-11df-b112-00215aee3ebe;Вільшана;Ольшана;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120355400;3;1
4aba3d05-749b-11df-b112-00215aee3ebe;Вільшана-Слобідка;Ольшана-Слободка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124385601;3;1
4aba3d06-749b-11df-b112-00215aee3ebe;Вільшане;Ольшаное;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424986001;3;1
4aba3d07-749b-11df-b112-00215aee3ebe;Вільшани;Ольшаны;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322056200;3;1
4aba3d08-749b-11df-b112-00215aee3ebe;Вільшани;Ольшаны;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125386901;3;1
4aba3d0a-749b-11df-b112-00215aee3ebe;Вільшани;Ольшаны;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820981702;3;1
4aba3d0b-749b-11df-b112-00215aee3ebe;Вільшаник;Ольшаник;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624286301;3;1
4aba3d0c-749b-11df-b112-00215aee3ebe;Вільшаниця;Вильшаница;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625883501;3;1
4aba3d0d-749b-11df-b112-00215aee3ebe;Вільшаниця;Вильшаница;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820386501;3;1
4aba3d0e-749b-11df-b112-00215aee3ebe;Вільшанка;Ольшанка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922988208;3;1
4aba3d0f-749b-11df-b112-00215aee3ebe;Вільшанка;Ольшанка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924782202;3;1
4aba3d10-749b-11df-b112-00215aee3ebe;Вільшанка;Ольшанка;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725784602;3;1
4aba3d11-749b-11df-b112-00215aee3ebe;Вільшанка;Ольшанка;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122689802;3;1
4aba3d12-749b-11df-b112-00215aee3ebe;Вільшанка;Ольшанка;село;6b07f835-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5124382701;3;1
4aba3d13-749b-11df-b112-00215aee3ebe;Вільшанка;Ольшанка;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783004;3;1
4aba3d14-749b-11df-b112-00215aee3ebe;Вільшанка;Ольшанка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821785802;3;1
4aba3d15-749b-11df-b112-00215aee3ebe;Вільшанка;Ольшанка;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825886401;3;1
4aba3d16-749b-11df-b112-00215aee3ebe;Вільшанка;Ольшанка;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424786003;3;1
4aba3d17-749b-11df-b112-00215aee3ebe;Вільшанка;Ольшанка;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120984901;3;1
4aba3d18-749b-11df-b112-00215aee3ebe;Вільшанка;Ольшанка;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124684002;3;1
4aba3d19-749b-11df-b112-00215aee3ebe;Вільшанка;Ольшанка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124385701;3;1
4aba3d1a-749b-11df-b112-00215aee3ebe;Вільшанка;Ольшанка;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522883303;3;1
4aba3d1b-749b-11df-b112-00215aee3ebe;Вільшанка;Ольшанка;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521985503;3;1
4aba3d1c-749b-11df-b112-00215aee3ebe;Вільшанка;Ольшанка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523683901;3;1
4aba3d1d-749b-11df-b112-00215aee3ebe;Вільшанка;Ольшанка;місто;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524355100;3;1
4aba3d1e-749b-11df-b112-00215aee3ebe;Вільшанка;Ольшанка;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322884602;3;1
4aba3d1f-749b-11df-b112-00215aee3ebe;Вільшанська Нива;Ольшанская Нива;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224982402;3;1
4aba3d20-749b-11df-b112-00215aee3ebe;Вільшанська Новоселиця;Вильшанская Новоселица;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221485801;3;1
4aba3d21-749b-11df-b112-00215aee3ebe;Вільшанське;Ольшанское;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322684002;3;1
4aba3d22-749b-11df-b112-00215aee3ebe;Вільшинки;Ольшинки;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123282501;3;1
4aba3d23-749b-11df-b112-00215aee3ebe;Вільшка;Вольшка;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820987003;3;1
4aba3d25-749b-11df-b112-00215aee3ebe;Вінинці;Вининцы;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223384202;3;1
4aba3d26-749b-11df-b112-00215aee3ebe;Вінкове;Винково;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122782802;3;1
4aba3d27-749b-11df-b112-00215aee3ebe;Вінниківці;Винниковцы;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522481603;3;1
4aba3d28-749b-11df-b112-00215aee3ebe;Вінниця;Винница;місто;a1e9f9b4-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0510100000;1;0
4aba3d2b-749b-11df-b112-00215aee3ebe;Вінницькі Івани;Винницкие Иваны;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320881001;3;1
4aba3d2c-749b-11df-b112-00215aee3ebe;Вінницькі Стави;Винницкие Ставы;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221481201;3;1
4aba3d2d-749b-11df-b112-00215aee3ebe;Вінницькі Хутори;Винницкие Хутора;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520681003;2;0
4aba3d2e-749b-11df-b112-00215aee3ebe;Вінож;Винож;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522884602;3;1
4aba3d2f-749b-11df-b112-00215aee3ebe;Вінтенці;Винтенцы;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321384803;3;1
4aba3d30-749b-11df-b112-00215aee3ebe;Віняви;Винявы;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623685903;3;1
4aba3d31-749b-11df-b112-00215aee3ebe;Віньківці;Виньковцы;місто;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820655100;3;0
73e94c05-ddc1-11df-9197-00215aee3ebe;Віпче;Випчее;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620855101;3;1
4aba3d33-749b-11df-b112-00215aee3ebe;Вірівка;Виривка;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520685502;3;1
4aba3d35-749b-11df-b112-00215aee3ebe;Вірівка;Виривка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821782202;3;1
4aba3d36-749b-11df-b112-00215aee3ebe;Вірівка;Виривка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824083603;3;1
4aba3d37-749b-11df-b112-00215aee3ebe;Вірлів;Вирлов;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122681101;3;1
4aba3d38-749b-11df-b112-00215aee3ebe;Вірля;Вирля;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820680801;3;1
4aba3d39-749b-11df-b112-00215aee3ebe;Вірне;Верное;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521781003;3;1
4aba3d3a-749b-11df-b112-00215aee3ebe;Вірнопілля;Вернополье;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322882201;3;1
4aba3d3c-749b-11df-b112-00215aee3ebe;Вісичі;Висичи;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323482203;3;1
4aba3d3d-749b-11df-b112-00215aee3ebe;Вістова;Вестовая;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622881201;3;1
4aba3d3e-749b-11df-b112-00215aee3ebe;Вістовичі;Вистовичи;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624284902;3;1
4aba3d3f-749b-11df-b112-00215aee3ebe;Вістря;Вистря;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124281601;3;1
4aba3d41-749b-11df-b112-00215aee3ebe;Віта-Поштова;Вита-Почтовая;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222481201;3;0
4aba3d44-749b-11df-b112-00215aee3ebe;Вітківці;Витковцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822482902;3;1
4aba3d45-749b-11df-b112-00215aee3ebe;Вітковичі;Витковичи;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620482701;3;1
4aba3d46-749b-11df-b112-00215aee3ebe;Вітове;Витово;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125485002;3;1
4aba3d47-749b-11df-b112-00215aee3ebe;Вітольдів Брід;Витольдов Брод;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;;3;1
4aba3d48-749b-11df-b112-00215aee3ebe;Вітоніж;Витониж;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724587802;3;1
4aba3d49-749b-11df-b112-00215aee3ebe;Вітрівка;Ветровка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222085502;3;1
4aba3d4a-749b-11df-b112-00215aee3ebe;Вітрівка;Ветровка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320286402;3;1
4aba3d4c-749b-11df-b112-00215aee3ebe;Вітрівка;Ветровка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222786502;3;1
4aba3d4d-749b-11df-b112-00215aee3ebe;Вітрівка;Ветровка;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525686602;3;1
5cb61bd5-749b-11df-b112-00215aee3ebe;Вітрова балка (Котовське);Ветрова Балка (Котовское);село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321880708;3;1
4aba3d4e-749b-11df-b112-00215aee3ebe;Вітрове;Ветрово;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;3;1
4aba3d4f-749b-11df-b112-00215aee3ebe;Вітрове;Ветрово;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520682405;3;1
4aba3d51-749b-11df-b112-00215aee3ebe;Вітрянка;Ветрянка;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324082501;3;1
4aba3d53-749b-11df-b112-00215aee3ebe;Вітязеве;Витязевое;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125781602;3;1
c6cc1bfb-db7e-11df-9197-00215aee3ebe;Віхове;Вехово;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923555102;3;1
4aba3d54-749b-11df-b112-00215aee3ebe;Віхті;Вихти;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784804;3;1
4aba3d55-749b-11df-b112-00215aee3ebe;Вічині;Вичини;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724580801;3;1
4aba3d56-749b-11df-b112-00215aee3ebe;Владин;Владин;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824083002;3;1
4aba3d57-749b-11df-b112-00215aee3ebe;Владипіль;Владиполь;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624287603;3;1
4aba3d5a-749b-11df-b112-00215aee3ebe;Владиславівка;Владиславовка;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623881301;3;1
4aba3d5b-749b-11df-b112-00215aee3ebe;Владиславка;Владиславка;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;;3;1
4aba3d5c-749b-11df-b112-00215aee3ebe;Владиславка;Владиславка;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224086402;3;1
4aba3d5d-749b-11df-b112-00215aee3ebe;Владиславка;Владиславка;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524381302;3;1
4aba3d5e-749b-11df-b112-00215aee3ebe;Владиславчик;Владиславчик;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123481101;3;1
4aba3d5f-749b-11df-b112-00215aee3ebe;Владичень;Владычень;село;6b07f8a2-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5121482001;3;1
4aba3d60-749b-11df-b112-00215aee3ebe;Владична;Владычна;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325089202;3;1
4aba3d62-749b-11df-b112-00215aee3ebe;Владівка;Владовка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823481801;3;1
4aba3d64-749b-11df-b112-00215aee3ebe;Власівка;Власовка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321381601;3;1
4aba3d65-749b-11df-b112-00215aee3ebe;Власівка;Власовка;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323180801;3;1
4aba3d67-749b-11df-b112-00215aee3ebe;Власівка;Власовка;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220286702;3;1
4aba3d68-749b-11df-b112-00215aee3ebe;Власівка;Власовка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421782403;3;1
325c57ff-ddd2-11df-9197-00215aee3ebe;Власівка;Власовка;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3510945300;3;1
4aba3d69-749b-11df-b112-00215aee3ebe;Влашанівка;Влашановка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822186602;3;1
4aba3d6a-749b-11df-b112-00215aee3ebe;Влащинці;Влащинцы;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123882801;3;1
4aba3d6b-749b-11df-b112-00215aee3ebe;Влізьки;Влезки;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922986903;3;1
4aba3d6e-749b-11df-b112-00215aee3ebe;Внутрішній Бір;Внутренний Бор;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423682402;3;1
4aba3d6f-749b-11df-b112-00215aee3ebe;Вовки;Вовки;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323283403;3;1
4aba3d70-749b-11df-b112-00215aee3ebe;Вовків;Вовкив;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925080802;3;1
4aba3d71-749b-11df-b112-00215aee3ebe;Вовків;Вовкив;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623681801;3;1
4aba3d72-749b-11df-b112-00215aee3ebe;Вовків;Вовкив;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623382501;3;1
4aba3d73-749b-11df-b112-00215aee3ebe;Вовківка;Вовкивка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323481003;3;1
4aba3d74-749b-11df-b112-00215aee3ebe;Вовківка;Вовкивка;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921589303;3;1
4aba3d75-749b-11df-b112-00215aee3ebe;Вовківка;Вовкивка;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323181001;3;1
4aba3d77-749b-11df-b112-00215aee3ebe;Вовківка;Вовкивка;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123787203;3;1
4aba3d78-749b-11df-b112-00215aee3ebe;Вовківське;Вовковское;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122755101;3;1
4aba3d79-749b-11df-b112-00215aee3ebe;Вовківське;Вовковское;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322683402;3;1
4aba3d7a-749b-11df-b112-00215aee3ebe;Вовківці;Вовковцы;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187902;3;1
4aba3d7b-749b-11df-b112-00215aee3ebe;Вовківці;Вовковцы;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125881802;3;1
4aba3d7c-749b-11df-b112-00215aee3ebe;Вовківці;Вовковцы;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120881501;3;1
4aba3d7d-749b-11df-b112-00215aee3ebe;Вовківці;Вовковцы;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825581801;3;1
4aba3d7e-749b-11df-b112-00215aee3ebe;Вовківці;Вовковцы;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824786503;3;1
4aba3d7f-749b-11df-b112-00215aee3ebe;Вовківчики;Вовковчики;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825581802;3;1
4aba3d80-749b-11df-b112-00215aee3ebe;Вовковатиця;Вовковатица;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620382005;3;1
4aba3d81-749b-11df-b112-00215aee3ebe;Вовкове;Вовково;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923282603;3;1
4aba3d82-749b-11df-b112-00215aee3ebe;Вовкове;Вовково;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121881402;3;1
4aba3d85-749b-11df-b112-00215aee3ebe;Вовкове;Вовково;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124855501;3;1
4aba3d86-749b-11df-b112-00215aee3ebe;Вовковиї;Вовковои;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621482003;3;1
4aba3d87-749b-11df-b112-00215aee3ebe;Вовковинці;Вовковинцы;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821555400;3;1
4aba3d89-749b-11df-b112-00215aee3ebe;Вовкошів;Вовкошов;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621284403;3;1
4aba3d8a-749b-11df-b112-00215aee3ebe;Вовна;Вовна;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925380801;3;1
4aba3d8b-749b-11df-b112-00215aee3ebe;Вовничі;Вовничи;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623881601;3;1
4aba3d8c-749b-11df-b112-00215aee3ebe;Вовніги;Вовниги;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225081902;3;1
4aba3d8d-749b-11df-b112-00215aee3ebe;Вовнянка;Вовнянка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323281201;3;1
4aba3d8e-749b-11df-b112-00215aee3ebe;Вовча Балка;Вовчая Балка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323086202;3;1
4aba3d8f-749b-11df-b112-00215aee3ebe;Вовча Балка;Вовчая Балка;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524382203;3;1
4aba3d90-749b-11df-b112-00215aee3ebe;Вовча Гора;Вовча Гора;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625887802;3;1
4aba3d91-749b-11df-b112-00215aee3ebe;Вовча Гора;Вовча Гора;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825055501;3;1
4aba3d92-749b-11df-b112-00215aee3ebe;Вовча Долина;Вовчая Долина;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322881103;3;1
4aba3d94-749b-11df-b112-00215aee3ebe;Вовчанське;Вовчанское;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755102;3;1
4aba3d97-749b-11df-b112-00215aee3ebe;Вовчатичі;Вовчатычи;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621581502;3;1
4aba3d98-749b-11df-b112-00215aee3ebe;Вовче;Вовче;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224280505;3;1
4aba3d9a-749b-11df-b112-00215aee3ebe;Вовче;Вовче;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625587001;3;1
4aba3d9d-749b-11df-b112-00215aee3ebe;Вовчий;Вовчий;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124083502;3;1
4aba3d9f-749b-11df-b112-00215aee3ebe;Вовчий Яр;Вовчий Яр;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320288002;3;1
4aba3da1-749b-11df-b112-00215aee3ebe;Вовчик;Вовчик;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922083802;3;1
4aba3da2-749b-11df-b112-00215aee3ebe;Вовчик;Вовчик;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322881101;3;1
4aba3da3-749b-11df-b112-00215aee3ebe;Вовчинець;Вовчинец;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320584003;3;1
4aba3da4-749b-11df-b112-00215aee3ebe;Вовчинець;Вовчинец;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322082401;3;1
4aba3da5-749b-11df-b112-00215aee3ebe;Вовчинець;Вовчинец;село;a1e9f9b8-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2610190501;2;0
4aba3da6-749b-11df-b112-00215aee3ebe;Вовчинець;Вовчинец;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521481003;3;1
4aba3da7-749b-11df-b112-00215aee3ebe;Вовчиці;Вовчицы;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622281002;3;1
4aba3da8-749b-11df-b112-00215aee3ebe;Вовчицьк;Вовчицк;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723683402;3;1
4aba3da9-749b-11df-b112-00215aee3ebe;Вовчишовичі;Вовчишовычи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622482003;3;1
4aba3daa-749b-11df-b112-00215aee3ebe;Вовчків;Вовчков;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223581601;3;1
4aba3dab-749b-11df-b112-00215aee3ebe;Вовчків;Вовчков;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223380501;3;1
4aba3dac-749b-11df-b112-00215aee3ebe;Вовчківці;Вовчковцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122681301;3;1
4aba3dad-749b-11df-b112-00215aee3ebe;Вовчківці;Вовчковцы;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625280901;3;1
4aba3dae-749b-11df-b112-00215aee3ebe;Вовчок;Вовчок;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422082101;3;1
4aba3daf-749b-11df-b112-00215aee3ebe;Вовчок;Вовчок;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523083402;3;1
4aba3db0-749b-11df-b112-00215aee3ebe;Вовчок;Вовчок;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520482806;3;1
4aba3db2-749b-11df-b112-00215aee3ebe;Вовчухи;Вовчухи;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620983302;3;1
4aba3db5-749b-11df-b112-00215aee3ebe;Водиця;Водица;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123682001;3;1
4aba3db6-749b-11df-b112-00215aee3ebe;Водички;Водички;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820388503;3;1
4aba3db7-749b-11df-b112-00215aee3ebe;Водички;Водички;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825081201;3;1
4aba3dba-749b-11df-b112-00215aee3ebe;Водник;Водник;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823384110;3;1
4aba3dbb-749b-11df-b112-00215aee3ebe;Водники;Водники;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621285302;3;1
b4c9f99a-5960-11e1-b817-003048d2b473;Водники;Водники;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623683102;3;1
74dc4972-749b-11df-b112-00215aee3ebe;Водокачка (Петрівське);Водокачка (Петрівське);село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823380703;3;1
4aba3dbd-749b-11df-b112-00215aee3ebe;Водолаги;Водолаги;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924781103;3;1
4aba3dbe-749b-11df-b112-00215aee3ebe;Водолагівка;Водолаговка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321883003;3;1
4aba3dbf-749b-11df-b112-00215aee3ebe;Водолазьке;Водолажское;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655113;3;1
4aba3dc0-749b-11df-b112-00215aee3ebe;Водолазьке;Водолажское;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684002;3;1
4aba3dc1-749b-11df-b112-00215aee3ebe;Водопій;Водопой;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321282002;3;1
4aba3dc5-749b-11df-b112-00215aee3ebe;Водотиї;Водотыи;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820980301;3;1
4aba3dc7-749b-11df-b112-00215aee3ebe;Водяна Балка;Водяная Балка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321082601;3;1
4aba3dc8-749b-11df-b112-00215aee3ebe;Водяна Балка;Водяная Балка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321284505;3;1
4aba3dc9-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325455101;3;1
4aba3dca-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222385003;3;1
4aba3dcb-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225982503;3;1
4aba3dcc-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221083301;3;1
4aba3dcd-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222081705;3;1
4aba3dce-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055405;3;1
4aba3dcf-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223884003;3;1
4aba3dd0-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221883502;3;1
4aba3dd1-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282502;3;1
4aba3dd2-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222985002;3;1
4aba3dd3-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224255101;3;1
4aba3dd4-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885702;3;1
4aba3dd5-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823081702;3;1
4aba3dd6-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323580303;3;1
4aba3dd9-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321781002;3;1
4aba3dda-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684004;3;1
4aba3ddb-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655107;3;1
4aba3ddc-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322189002;3;1
4aba3de3-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125782001;3;1
4aba3de4-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521782702;3;1
4aba3de5-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524082402;3;1
4aba3de6-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3510645301;3;1
4aba3de7-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524981301;3;1
4aba3de8-749b-11df-b112-00215aee3ebe;Водяне;Водяное;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522881101;3;1
17af7b29-ddb9-11df-9197-00215aee3ebe;Водяне;Водяное;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224888202;3;1
4aba3de9-749b-11df-b112-00215aee3ebe;Водяне Друге;Водяное Второе;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684004;3;1
4aba3deb-749b-11df-b112-00215aee3ebe;Водяники;Водяники;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121281601;3;1
4aba3ded-749b-11df-b112-00215aee3ebe;Водяно-Лорине;Водяно-Лорино;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823080701;3;1
4aba3dee-749b-11df-b112-00215aee3ebe;Водяно-Михайлівка;Водяно-Михайловка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520885602;3;1
4aba3df0-749b-11df-b112-00215aee3ebe;Водяхівка;Водяховка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321786503;3;1
4aba3df1-749b-11df-b112-00215aee3ebe;Воєводівка;Воеводовка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221055802;3;1
4aba3df3-749b-11df-b112-00215aee3ebe;Воєводське;Воеводское;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820380404;3;1
4aba3df5-749b-11df-b112-00215aee3ebe;Воєводчинці;Воеводчинцы;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522682103;3;1
4aba3df6-749b-11df-b112-00215aee3ebe;Воєгоща;Воегоща;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721481401;3;1
4aba3df7-749b-11df-b112-00215aee3ebe;Возвратне;Возвратное;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755116;3;1
4aba3df9-749b-11df-b112-00215aee3ebe;Воздвиженське;Воздвиженское;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925680401;3;1
4aba3dfb-749b-11df-b112-00215aee3ebe;Возилів;Возилов;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
4aba3dfc-749b-11df-b112-00215aee3ebe;Возлякове;Возляково;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824288203;3;1
4aba3dfd-749b-11df-b112-00215aee3ebe;Вознесенка;Вознесенка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920981601;3;1
4aba3e02-749b-11df-b112-00215aee3ebe;Вознесенка Друга;Вознесенка Вторая;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124781401;3;1
4aba3e03-749b-11df-b112-00215aee3ebe;Вознесенка Перша;Вознесенка Первая;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120481001;3;1
4aba3e04-749b-11df-b112-00215aee3ebe;Вознесенськ;Вознесенск;місто;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4810200000;2;0
4aba3e05-749b-11df-b112-00215aee3ebe;Вознесенське;Вознесенское;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921584802;3;1
4aba3e06-749b-11df-b112-00215aee3ebe;Вознесенське;Вознесенское;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655102;3;1
4aba3e07-749b-11df-b112-00215aee3ebe;Вознесенське;Вознесенское;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822081201;3;1
4aba3e08-749b-11df-b112-00215aee3ebe;Вознесенське;Вознесенское;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121582001;3;1
56bdd256-749b-11df-b112-00215aee3ebe;Вознесенське (Жовтневе);Жовтневое;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221982001;3;1
871621f3-749b-11df-b112-00215aee3ebe;Вознесенське (Улянівка);Вознесенское (Ульяновка);село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425585704;3;1
4aba3e09-749b-11df-b112-00215aee3ebe;Возничі;Возничи;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824284302;3;1
4aba3e0a-749b-11df-b112-00215aee3ebe;Вознівці;Вознивцы;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521086309;3;1
4aba3e0b-749b-11df-b112-00215aee3ebe;Возсіятське;Воссиятское;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823081001;3;1
4aba3e0d-749b-11df-b112-00215aee3ebe;Войкове;Войково;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225085002;3;1
4aba3e12-749b-11df-b112-00215aee3ebe;Войкове;Войково;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221981301;3;1
4aba3e14-749b-11df-b112-00215aee3ebe;Войнашівка;Войнашовка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520280601;3;1
4aba3e15-749b-11df-b112-00215aee3ebe;Войнилів;Войнилов;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622855300;3;1
4aba3e16-749b-11df-b112-00215aee3ebe;Войнин;Войнин;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722486302;3;1
4aba3e17-749b-11df-b112-00215aee3ebe;Войниха;Войниха;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322881201;3;1
4aba3e18-749b-11df-b112-00215aee3ebe;Войничеве;Войничево;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125280901;3;1
4aba3e19-749b-11df-b112-00215aee3ebe;Войнівка;Войновка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325480401;3;1
4aba3e1a-749b-11df-b112-00215aee3ebe;Войнівка;Войновка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524084302;3;1
4aba3e1b-749b-11df-b112-00215aee3ebe;Войнівка;Войновка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520381801;3;1
4aba3e1c-749b-11df-b112-00215aee3ebe;Войславичі;Войславичи;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624887802;3;1
4aba3e1e-749b-11df-b112-00215aee3ebe;Вокзал;Вокзал;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083806;3;1
4aba3e1f-749b-11df-b112-00215aee3ebe;Вокзал Городня;Вокзал Городня;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421410102;3;1
4aba3e20-749b-11df-b112-00215aee3ebe;Волвенкове;Волвенково;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320285602;3;1
4aba3e21-749b-11df-b112-00215aee3ebe;Волевачі;Волевачи;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422086503;3;1
4aba3e22-749b-11df-b112-00215aee3ebe;Волинка;Волынка;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424982001;3;1
4aba3e24-749b-11df-b112-00215aee3ebe;Волинцеве;Волынцево;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923888202;3;1
4aba3e25-749b-11df-b112-00215aee3ebe;Волинцівське;Волынцевское;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923888203;3;1
4aba3e26-749b-11df-b112-00215aee3ebe;Волиця;Волиця;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721486203;3;1
4aba3e27-749b-11df-b112-00215aee3ebe;Волиця;Волиця;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725581302;3;1
4aba3e28-749b-11df-b112-00215aee3ebe;Волиця;Волиця;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123481503;3;1
4aba3e29-749b-11df-b112-00215aee3ebe;Волиця;Волиця;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123810101;3;1
4aba3e2a-749b-11df-b112-00215aee3ebe;Волиця;Волиця;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120487302;3;1
4aba3e2b-749b-11df-b112-00215aee3ebe;Волиця;Волиця;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124881820;3;1
4aba3e2c-749b-11df-b112-00215aee3ebe;Волиця;Волиця;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121681802;3;1
4aba3e2d-749b-11df-b112-00215aee3ebe;Волиця;Волиця;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624881802;3;1
4aba3e2e-749b-11df-b112-00215aee3ebe;Волиця;Волиця;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623981602;3;1
4aba3e2f-749b-11df-b112-00215aee3ebe;Волиця;Волиця;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622781401;3;1
4aba3e30-749b-11df-b112-00215aee3ebe;Волиця;Волиця;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623688203;3;0
4aba3e31-749b-11df-b112-00215aee3ebe;Волиця;Волиця;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622487202;3;1
4aba3e32-749b-11df-b112-00215aee3ebe;Волиця;Волиця;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624281604;3;1
4aba3e33-749b-11df-b112-00215aee3ebe;Волиця;Волиця;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221083002;3;1
4aba3e34-749b-11df-b112-00215aee3ebe;Волиця;Волиця;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224982001;3;1
4aba3e35-749b-11df-b112-00215aee3ebe;Волиця;Волиця;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822086503;3;1
4aba3e36-749b-11df-b112-00215aee3ebe;Волиця;Волиця;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820381601;3;1
4aba3e37-749b-11df-b112-00215aee3ebe;Волиця;Волиця;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823981601;3;1
4aba3e38-749b-11df-b112-00215aee3ebe;Волиця;Волиця;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824781201;3;1
4aba3e39-749b-11df-b112-00215aee3ebe;Волиця;Волиця;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822782101;3;1
4aba3e3a-749b-11df-b112-00215aee3ebe;Волиця;Волиця;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825083902;3;1
4aba3e3b-749b-11df-b112-00215aee3ebe;Волиця;Волиця;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623487802;3;1
17af7b1b-ddb9-11df-9197-00215aee3ebe;Волиця;Волиця;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721183802;3;1
4aba3e3c-749b-11df-b112-00215aee3ebe;Волиця Друга;Волица Вторая;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822787004;3;1
4aba3e3d-749b-11df-b112-00215aee3ebe;Волиця-Барилова;Волица-Барилова;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623981902;3;1
4aba3e3e-749b-11df-b112-00215aee3ebe;Волиця-Гніздичівська;Волица-Гнездычевская;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621585902;3;1
4aba3e3f-749b-11df-b112-00215aee3ebe;Волиця-Деревлянська;Волица-Деревлянская;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620685803;3;1
4aba3e40-749b-11df-b112-00215aee3ebe;Волиця-Дружкопільська;Волица-Дружкопольская;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720881602;3;1
4aba3e41-749b-11df-b112-00215aee3ebe;Волиця-Керекешина;Волыце-Керекешино;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824282101;3;1
4aba3e42-749b-11df-b112-00215aee3ebe;Волиця-Лобачівська;Волица-Лобачовская;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720883202;3;1
4aba3e43-749b-11df-b112-00215aee3ebe;Волиця-Морозовицька;Волица-Морозовицкая;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721183303;3;1
4aba3e44-749b-11df-b112-00215aee3ebe;Волиця-Польова;Волица-Полевая;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824781001;3;1
4aba3e45-749b-11df-b112-00215aee3ebe;Волна;Волна;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823680302;3;1
4aba3e49-749b-11df-b112-00215aee3ebe;Волобуївка;Волобуевка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320282402;3;1
4aba3e4a-749b-11df-b112-00215aee3ebe;Волова;Воловая;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120688802;3;1
4aba3e4b-749b-11df-b112-00215aee3ebe;Волова;Воловая;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620885002;3;1
4aba3e4c-749b-11df-b112-00215aee3ebe;Волове;Воловое;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623387203;3;1
4aba3e4d-749b-11df-b112-00215aee3ebe;Воловець;Воловец;місто;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121555100;3;0
4aba3e4e-749b-11df-b112-00215aee3ebe;Воловики;Воловики;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422483502;3;1
4aba3e4f-749b-11df-b112-00215aee3ebe;Воловиця;Воловица;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121984803;3;1
4aba3e50-749b-11df-b112-00215aee3ebe;Воловиця;Воловица;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420887603;3;1
4aba3e51-749b-11df-b112-00215aee3ebe;Воловодівка;Воловодовка;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523084004;3;1
4aba3e53-749b-11df-b112-00215aee3ebe;Володарівка;Володаровка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;;3;1
4aba3e54-749b-11df-b112-00215aee3ebe;Володарка;Володарка;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223581501;3;1
4aba3e55-749b-11df-b112-00215aee3ebe;Володарка;Володарка;місто;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221655100;2;0
4aba3e5a-749b-11df-b112-00215aee3ebe;Володимир;Владимир;місто;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0710200000;2;0
4aba3e5b-749b-11df-b112-00215aee3ebe;Володимирець;Владимирец;місто;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620855100;2;1
4aba3e5c-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320284302;3;1
4aba3e5d-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321683402;3;1
4aba3e5e-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924184903;3;1
4aba3e5f-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923555602;3;1
4aba3e60-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924780303;3;1
4aba3e62-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720582007;3;1
4aba3e63-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224588002;3;1
4aba3e64-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222088603;3;1
4aba3e65-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655114;3;1
4aba3e68-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1220310102;3;1
4aba3e69-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824585002;3;1
4aba3e6a-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823681401;3;1
4aba3e6b-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822784602;3;1
4aba3e6c-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825181203;3;1
4aba3e6d-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323584702;3;1
4aba3e6e-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323380501;3;1
4aba3e6f-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325785002;3;1
4aba3e70-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324881501;3;1
4aba3e71-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123381403;3;1
4aba3e72-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125282503;3;1
4aba3e73-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125482203;3;1
4aba3e74-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123982603;3;1
4aba3e75-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120881801;3;1
4aba3e76-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123180402;3;1
4aba3e77-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124783002;3;1
4aba3e78-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321580502;3;1
4aba3e85-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221887202;3;1
4aba3e86-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220481002;2;1
4aba3e87-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221686802;3;1
4aba3e88-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224481101;3;1
4aba3e89-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821783002;3;1
4aba3e8a-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820685802;3;1
4aba3e8b-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421482401;3;1
4aba3e8c-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423688102;3;1
4aba3e8d-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424180304;3;1
4aba3e8e-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123485702;3;1
4aba3e8f-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524885003;3;1
4aba3e90-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521055306;3;1
4aba3e91-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522882002;3;1
4aba3e92-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523684202;3;1
4aba3e93-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521782703;3;1
4aba3e94-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522280801;3;1
4aba3e95-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522581801;3;1
4aba3e96-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524980402;3;1
4aba3e97-749b-11df-b112-00215aee3ebe;Володимирівка;Владимировка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522884303;3;1
4aba3e66-749b-11df-b112-00215aee3ebe;Володимирівка (Миколаївської с-ої ради);Владимировка (Миколаївської с-ої ради);село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225255108;3;1
871625f3-749b-11df-b112-00215aee3ebe;Володимирівка (Червона Володим;Владимировка (Червоная Владимировка);село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524885003;3;1
fd097a79-f654-11e5-b35b-001ec93df11f;Володимирівка(Ордо-Василівської с. ради);Владимировкаа(Ордо-Василівської с. ради);село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225286602;3;1
4aba3e98-749b-11df-b112-00215aee3ebe;Володимирівське;Владимировское;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224881303;3;1
4aba3e99-749b-11df-b112-00215aee3ebe;Володимирівське;Владимировское;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322181601;3;0
4aba3e9a-749b-11df-b112-00215aee3ebe;Володимирівське лісництво;Владимирское лесничество;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
4aba3e9b-749b-11df-b112-00215aee3ebe;Володимирівський ДОК;Владимирский ДОК;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
4aba3e9c-749b-11df-b112-00215aee3ebe;Володимирка;Владимирка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525381204;3;1
4aba3ea0-749b-11df-b112-00215aee3ebe;Володимиро-Павлівка;Владимиро-Павловка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824585003;3;1
4aba3ea1-749b-11df-b112-00215aee3ebe;Володимиро-Ульяновка;Владимиро-Ульяновка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520385203;3;1
4aba3ea2-749b-11df-b112-00215aee3ebe;Володимирське;Владимирское;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221987903;3;1
4aba3ea3-749b-11df-b112-00215aee3ebe;Володимирці;Владимирцы;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621587801;3;1
4aba3ea6-749b-11df-b112-00215aee3ebe;Володіївці;Володиевцы;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520280801;3;1
4aba3ea7-749b-11df-b112-00215aee3ebe;Володіївці;Володиевцы;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524982301;3;1
300f018b-c614-11e6-80f3-1c98ec135263;Володькова Дівиця;Володькова Девица;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423887001;3;1
4aba3ea8-749b-11df-b112-00215aee3ebe;Волока;Волока;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320510401;3;1
4aba3ea9-749b-11df-b112-00215aee3ebe;Волока;Волока;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321080901;3;1
4aba3eaa-749b-11df-b112-00215aee3ebe;Волокитине;Волокитино;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923881901;3;1
4aba3eab-749b-11df-b112-00215aee3ebe;Волосів;Волосов;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624080901;3;1
4aba3eac-749b-11df-b112-00215aee3ebe;Волосів;Волосов;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820382001;3;1
4aba3ead-749b-11df-b112-00215aee3ebe;Волосівка;Волосовка;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122689807;3;1
4aba3eae-749b-11df-b112-00215aee3ebe;Волосівка;Волосовка;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825883602;3;1
4aba3eaf-749b-11df-b112-00215aee3ebe;Волосівці;Волосовцы;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823080801;3;1
cdc44f6f-dde8-11df-9197-00215aee3ebe;Волосінь;Волосень;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222782302;3;1
4aba3eb0-749b-11df-b112-00215aee3ebe;Волосківці;Волосковцы;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423082001;3;1
4aba3eb1-749b-11df-b112-00215aee3ebe;Волосківці;Волосковцы;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624285302;3;1
4aba3eb2-749b-11df-b112-00215aee3ebe;Волостків;Волостков;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622482203;3;1
4aba3eb3-749b-11df-b112-00215aee3ebe;Волосянка;Волосянка;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624580801;3;1
4aba3eb4-749b-11df-b112-00215aee3ebe;Волосянка;Волосянка;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120881301;3;1
4aba3eb5-749b-11df-b112-00215aee3ebe;Волоська Балаклія;Волосская Балаклея;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325784001;3;1
4aba3eb6-749b-11df-b112-00215aee3ebe;Волоське;Волосское;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221486209;3;1
4aba3eb7-749b-11df-b112-00215aee3ebe;Волоське;Волосское;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821581201;3;1
4aba3eb8-749b-11df-b112-00215aee3ebe;Волохів Яр;Волохов Яр;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325482501;3;1
4aba3eba-749b-11df-b112-00215aee3ebe;Волочаївка;Волочаевка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224583702;3;1
4aba3ebc-749b-11df-b112-00215aee3ebe;Волочиськ;Волочиск;місто;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820910100;2;0
4aba3ebd-749b-11df-b112-00215aee3ebe;Волоша;Волоша;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625483902;3;1
4aba3ebe-749b-11df-b112-00215aee3ebe;Волошине;Волошино;село;d00d3b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;;3;1
4aba3ec0-749b-11df-b112-00215aee3ebe;Волошине;Волошино;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822887605;3;1
4aba3ec1-749b-11df-b112-00215aee3ebe;Волошинівка;Волошиновка;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220281701;3;1
4aba3ec2-749b-11df-b112-00215aee3ebe;Волошиново;Волошиново;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625181501;3;1
4aba3ec3-749b-11df-b112-00215aee3ebe;Волошки;Волошки;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722183603;3;1
4aba3ec4-749b-11df-b112-00215aee3ebe;Волошки;Волошки;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522882302;3;1
4aba3ec5-749b-11df-b112-00215aee3ebe;Волошки;Волошки;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624680403;3;1
4aba3ec6-749b-11df-b112-00215aee3ebe;Волошкове;Волошковое;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321387203;3;1
4aba3ec7-749b-11df-b112-00215aee3ebe;Волошкове;Волошковое;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324083001;3;1
4aba3d6c-749b-11df-b112-00215aee3ebe;Волошкове;Волошковое;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521255402;3;1
4aba3ec8-749b-11df-b112-00215aee3ebe;Волошнівка;Волошновка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924183801;3;1
4aba3ec9-749b-11df-b112-00215aee3ebe;Волоща;Волоща;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621281501;3;1
4aba3eca-749b-11df-b112-00215aee3ebe;Волощина;Волощина;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120486705;3;1
4aba3ecb-749b-11df-b112-00215aee3ebe;Волощина;Волощина;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623381802;3;1
4aba3ecc-749b-11df-b112-00215aee3ebe;Волсвин;Волсвин;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624881301;3;1
4aba3ecd-749b-11df-b112-00215aee3ebe;Волудринці;Волудринцы;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825883202;3;1
4aba3ece-749b-11df-b112-00215aee3ebe;Волуйки;Волуйки;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620655601;3;1
4aba3ecf-749b-11df-b112-00215aee3ebe;Волфине;Волфино;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920686602;3;1
4aba3ed0-749b-11df-b112-00215aee3ebe;Воля;Воля;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722182803;3;1
4aba3ed1-749b-11df-b112-00215aee3ebe;Воля;Воля;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125055401;3;1
4aba3ed2-749b-11df-b112-00215aee3ebe;Воля;Воля;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820382603;3;1
4aba3ed3-749b-11df-b112-00215aee3ebe;Воля;Воля;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823685303;3;1
4aba3ed4-749b-11df-b112-00215aee3ebe;Воля;Воля;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822783203;3;1
4aba3ed5-749b-11df-b112-00215aee3ebe;Воля;Воля;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625855902;3;1
4aba3ed6-749b-11df-b112-00215aee3ebe;Воля;Воля;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625181701;3;1
4aba3ed8-749b-11df-b112-00215aee3ebe;Воля;Воля;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322680702;3;1
4aba3edc-749b-11df-b112-00215aee3ebe;Воля Баранецька;Воля-Баранецкая;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624281601;3;1
4aba3edd-749b-11df-b112-00215aee3ebe;Воля Бартатівська;Воля-Бартатовская;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620980803;3;1
4aba3ede-749b-11df-b112-00215aee3ebe;Воля Блажівська;Воля-Блаживская;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624289205;3;1
4aba3edf-749b-11df-b112-00215aee3ebe;Воля Висоцька;Воля-Высоцкая;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622781201;3;1
4aba3ee0-749b-11df-b112-00215aee3ebe;Воля Гомулецька;Воля-Гомулецкая;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622782503;3;1
4aba3ee1-749b-11df-b112-00215aee3ebe;Воля Добростанська;Воля-Добростанская;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625883202;3;1
4aba3ee2-749b-11df-b112-00215aee3ebe;Воля Довголуцька;Воля-Долголуцкая;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625381202;3;1
4aba3ee3-749b-11df-b112-00215aee3ebe;Воля Жовтанецька;Воля-Жовтанецкая;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622183004;3;1
4aba3ee4-749b-11df-b112-00215aee3ebe;Воля Задеревацька;Воля-Задеревацкая;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625380301;3;1
4aba3ee5-749b-11df-b112-00215aee3ebe;Воля Ковельська;Воля-Ковельская;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722182602;2;0
4aba3ed9-749b-11df-b112-00215aee3ebe;Воля Любинська;Воля Любинская;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625887603;3;1
c24ee46a-2a1a-11e1-b44a-003048d2b473;Воля Любинська;Воля-Любинская;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625887603;;1
4aba3ee6-749b-11df-b112-00215aee3ebe;Воля Любитівська;Воля-Любитовская;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722184002;3;1
4aba3edb-749b-11df-b112-00215aee3ebe;Воля Якубова;Воля Якубова;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621281701;3;1
4aba3ee8-749b-11df-b112-00215aee3ebe;Волянка;Волянка;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624289206;3;1
4aba3ee9-749b-11df-b112-00215aee3ebe;Волянщина;Волянщина;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821184002;3;1
4aba3eea-749b-11df-b112-00215aee3ebe;Воля-Облазницька;Воля-Облазницкая;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621585602;3;1
4aba3eeb-749b-11df-b112-00215aee3ebe;Волярка;Волярка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123155101;3;1
4aba3eec-749b-11df-b112-00215aee3ebe;Воля-Садківська;Воля-Садковская;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622483602;3;1
4aba3eed-749b-11df-b112-00215aee3ebe;Воля-Свійчівська;Воля-Свийчевская;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720581503;3;1
4aba3eda-749b-11df-b112-00215aee3ebe;Воля-Старицька;Воля-Старицкая;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625888503;3;1
4aba3eee-749b-11df-b112-00215aee3ebe;Вольниця;Вольница;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420882802;3;1
4aba3eef-749b-11df-b112-00215aee3ebe;Вонігово;Вонигово;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124480801;2;1
4aba3ef0-749b-11df-b112-00215aee3ebe;Ворвулинці;Ворвулинцы;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122082001;3;1
4aba3ef1-749b-11df-b112-00215aee3ebe;Воргол;Воргол;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922685503;3;1
4aba3ef2-749b-11df-b112-00215aee3ebe;Ворзель;Ворзель;село;903cca8d-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3210945600;2;0
4aba3ef3-749b-11df-b112-00215aee3ebe;Ворничани;Ворничаны;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325080901;3;1
4aba3efb-749b-11df-b112-00215aee3ebe;Вороб'їв;Воробьев;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424480802;3;1
4aba3efc-749b-11df-b112-00215aee3ebe;Вороб'ївка;Воробьевка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423682401;3;1
4aba3efd-749b-11df-b112-00215aee3ebe;Вороб'ївка;Воробьевка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521780803;3;1
4aba3ef4-749b-11df-b112-00215aee3ebe;Вороб'ївка;Воробьевка;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;;3;1
4aba3efe-749b-11df-b112-00215aee3ebe;Вороб'ївщина;Воробьевщина;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823485503;3;1
4aba3ef5-749b-11df-b112-00215aee3ebe;Воробіївка;Воробиевка;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122681602;3;1
4aba3ef6-749b-11df-b112-00215aee3ebe;Воробіївка;Воробиевка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124680701;3;1
4aba3ef7-749b-11df-b112-00215aee3ebe;Воробіївка;Воробиевка;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523081401;3;1
4aba3ef8-749b-11df-b112-00215aee3ebe;Воробіївка;Воробиевка;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820380501;3;1
4aba3ef9-749b-11df-b112-00215aee3ebe;Воробіївка;Воробиевка;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823682201;3;1
4aba3efa-749b-11df-b112-00215aee3ebe;Воробіївка;Воробиевка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821881501;3;1
87162592-749b-11df-b112-00215aee3ebe;Воробіївка (Чапаєве);Воробиевка (Чапаево);село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121686401;3;1
4aba3eff-749b-11df-b112-00215aee3ebe;Воробйове;Воробьево;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121686403;3;1
4aba3f01-749b-11df-b112-00215aee3ebe;Вороблевичі;Вороблевичи;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621281801;3;1
4aba3f02-749b-11df-b112-00215aee3ebe;Вороблячин;Вороблячин;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625888403;3;1
4aba3f07-749b-11df-b112-00215aee3ebe;Воровського;Воровского;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320683002;3;1
4aba3f0a-749b-11df-b112-00215aee3ebe;Ворожба;Ворожба;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920610300;3;1
4aba3f0b-749b-11df-b112-00215aee3ebe;Ворожба;Ворожба;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982401;3;1
4aba3f0c-749b-11df-b112-00215aee3ebe;Ворокомле;Ворокомле;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721481801;3;1
4aba3f0e-749b-11df-b112-00215aee3ebe;Ворона;Ворона;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722184003;3;1
4aba3f0f-749b-11df-b112-00215aee3ebe;Ворона;Ворона;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623280901;3;1
4aba3f10-749b-11df-b112-00215aee3ebe;Ворона;Ворона;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420888402;3;1
4aba3f11-749b-11df-b112-00215aee3ebe;Вороне;Вороное;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224283402;3;1
4aba3f12-749b-11df-b112-00215aee3ebe;Вороне;Вороное;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120981601;3;1
4aba3f13-749b-11df-b112-00215aee3ebe;Вороневе;Воронево;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822380603;3;1
4aba3f14-749b-11df-b112-00215aee3ebe;Вороненко;Вороненко;село;a1e9f9cf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2611093003;3;1
4aba3f15-749b-11df-b112-00215aee3ebe;Ворони;Вороны;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322281902;3;1
4aba3f16-749b-11df-b112-00215aee3ebe;Ворони;Вороны;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;;3;1
4aba3f17-749b-11df-b112-00215aee3ebe;Воронине;Воронино;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920683902;3;1
4aba3f18-749b-11df-b112-00215aee3ebe;Воронине;Воронино;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821480403;3;1
4aba3f19-749b-11df-b112-00215aee3ebe;Воронинці;Воронинцы;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125186402;3;1
4aba3f1a-749b-11df-b112-00215aee3ebe;Воронинці;Воронинцы;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323680401;3;1
4aba3f1b-749b-11df-b112-00215aee3ebe;Ворониця;Вороница;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621285402;3;1
4aba3f1c-749b-11df-b112-00215aee3ebe;Воронів;Воронов;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624481101;3;1
4aba3f1d-749b-11df-b112-00215aee3ebe;Воронів;Воронов;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621682303;3;1
4aba3f1e-749b-11df-b112-00215aee3ebe;Воронів;Воронов;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624882002;3;1
4aba3f1f-749b-11df-b112-00215aee3ebe;Воронів;Воронов;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621285102;3;1
4aba3f20-749b-11df-b112-00215aee3ebe;Воронівка;Вороновка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923884803;3;1
4aba3f21-749b-11df-b112-00215aee3ebe;Воронівка;Вороновка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920682201;3;1
4aba3f22-749b-11df-b112-00215aee3ebe;Воронівка;Вороновка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224881703;3;1
4aba3f23-749b-11df-b112-00215aee3ebe;Воронівка;Вороновка;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822055205;3;1
4aba3f24-749b-11df-b112-00215aee3ebe;Воронівка;Вороновка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821755107;3;1
4aba3f25-749b-11df-b112-00215aee3ebe;Воронівка;Вороновка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122780502;3;1
4aba3f26-749b-11df-b112-00215aee3ebe;Воронівка;Вороновка;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222288602;3;1
4aba3f27-749b-11df-b112-00215aee3ebe;Воронівка;Вороновка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421788802;3;1
4aba3f28-749b-11df-b112-00215aee3ebe;Воронівка;Вороновка;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120382001;3;1
4aba3f29-749b-11df-b112-00215aee3ebe;Воронівка;Вороновка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524010105;3;1
4aba3f2a-749b-11df-b112-00215aee3ebe;Воронівці;Вороновцы;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524887402;3;1
4aba3f2b-749b-11df-b112-00215aee3ebe;Воронівці;Вороновцы;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522880403;3;1
4aba3f2c-749b-11df-b112-00215aee3ebe;Воронівці;Вороновцы;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824781301;3;1
4aba3f2d-749b-11df-b112-00215aee3ebe;Воронівщина;Вороновщина;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225581402;3;1
4aba3f2e-749b-11df-b112-00215aee3ebe;Воронівщина;Вороновщина;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320484703;3;1
4aba3f2f-749b-11df-b112-00215aee3ebe;Вороніж;Воронеж;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925355300;3;1
4aba3f30-749b-11df-b112-00215aee3ebe;Воронізьке;Воронежское;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755117;3;1
4aba3f32-749b-11df-b112-00215aee3ebe;Воронки;Воронки;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620882601;3;1
4aba3f33-749b-11df-b112-00215aee3ebe;Воронківці;Воронковцы;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824282302;3;1
4aba3f34-749b-11df-b112-00215aee3ebe;Воронове;Вороново;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224881201;3;1
4aba3f36-749b-11df-b112-00215aee3ebe;Вороновиця;Вороновица;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322082801;3;1
4aba3f37-749b-11df-b112-00215aee3ebe;Вороновиця;Вороновица;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520655300;3;0
4aba3f38-749b-11df-b112-00215aee3ebe;Воронцівка;Воронцовка;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824881601;3;1
4aba3f3a-749b-11df-b112-00215aee3ebe;Воронцівка;Воронцовка;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523455102;3;1
4aba3f3b-749b-11df-b112-00215aee3ebe;Воронцове;Воронцово;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922682203;3;1
4aba3f3c-749b-11df-b112-00215aee3ebe;Ворончин;Ворончин;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724581001;3;1
4aba3f3d-749b-11df-b112-00215aee3ebe;Вороняки;Вороняки;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621881801;3;1
4aba3f3e-749b-11df-b112-00215aee3ebe;Воронянщина;Воронянщина;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755114;3;1
4aba3f3f-749b-11df-b112-00215aee3ebe;Вороньки;Вороньки;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420682401;3;1
4aba3f40-749b-11df-b112-00215aee3ebe;Вороньки;Вороньки;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325180801;3;1
4aba3f41-749b-11df-b112-00215aee3ebe;Вороньків;Вороньков;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220881701;3;1
4aba3f42-749b-11df-b112-00215aee3ebe;Вороньківка;Вороньковка;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221887502;3;1
4aba3f43-749b-11df-b112-00215aee3ebe;Воропаї;Воропаи;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923283203;3;1
4aba3f44-749b-11df-b112-00215aee3ebe;Воропаїв;Воропаев;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221881402;3;1
4aba3f45-749b-11df-b112-00215aee3ebe;Воропаївка;Воропаевка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222086803;3;1
4aba3f46-749b-11df-b112-00215aee3ebe;Воропай;Воропай;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922385402;3;1
4aba3f47-749b-11df-b112-00215aee3ebe;Воротнів;Воротнев;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722883602;3;1
4aba3f48-749b-11df-b112-00215aee3ebe;Ворохівка;Вороховка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425580804;3;1
4aba3f49-749b-11df-b112-00215aee3ebe;Ворохта;Ворохта;село;a1e9f9cf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2611040300;3;1
4aba3f4a-749b-11df-b112-00215aee3ebe;Вороців;Вороцев;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625882901;3;1
4aba3f4b-749b-11df-b112-00215aee3ebe;Ворочово;Ворочево;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123210101;3;1
4aba3f4c-749b-11df-b112-00215aee3ebe;Ворошилівка;Ворошиловка;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524581801;3;1
4aba3f4e-749b-11df-b112-00215aee3ebe;Ворсівка;Ворсовка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823482201;3;1
4aba3f4f-749b-11df-b112-00215aee3ebe;Ворчин;Ворчин;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720510405;3;1
4aba3f50-749b-11df-b112-00215aee3ebe;Воскобійники;Воскобойники;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755115;3;1
4aba3f51-749b-11df-b112-00215aee3ebe;Воскобійники;Воскобойники;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322487002;3;1
4aba3f52-749b-11df-b112-00215aee3ebe;Воскобійники;Воскобойники;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320882502;3;1
4aba3f53-749b-11df-b112-00215aee3ebe;Воскодави;Воскодавы;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621281501;3;1
4aba3f54-749b-11df-b112-00215aee3ebe;Воскодавинці;Воскодавинцы;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521481201;3;1
4aba3f55-749b-11df-b112-00215aee3ebe;Воскодавинці;Воскодавинцы;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822782201;3;1
4aba3f56-749b-11df-b112-00215aee3ebe;Воскресенівка;Воскресеновка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223282003;3;1
4aba3f57-749b-11df-b112-00215aee3ebe;Воскресенівка;Воскресеновка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755118;3;1
4aba3f58-749b-11df-b112-00215aee3ebe;Воскресенівка;Воскресеновка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320888303;3;1
4aba3f5a-749b-11df-b112-00215aee3ebe;Воскресенка;Воскресенка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920982001;3;1
4aba3f5b-749b-11df-b112-00215aee3ebe;Воскресенка;Воскресенка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323686603;3;1
4aba3f60-749b-11df-b112-00215aee3ebe;Воскресенське;Воскресенское;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823355300;3;1
4aba3f62-749b-11df-b112-00215aee3ebe;Воскресенське;Воскресенское;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223384602;3;1
4aba3f63-749b-11df-b112-00215aee3ebe;Воскресенське;Воскресенское;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421155101;3;1
4aba3f64-749b-11df-b112-00215aee3ebe;Воскресинці;Воскресинцы;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624481401;3;1
4aba3f65-749b-11df-b112-00215aee3ebe;Воскресинці;Воскресинцы;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623281001;3;1
4aba3f67-749b-11df-b112-00215aee3ebe;Восточне;Восточное;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423688103;3;1
4aba3f6c-749b-11df-b112-00215aee3ebe;Восьме Березня;Восьмое Березня;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920380802;3;1
4aba3f6d-749b-11df-b112-00215aee3ebe;Восьме Березня;Восьмое Березня;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420382002;3;1
4aba3f6e-749b-11df-b112-00215aee3ebe;Вотилівка;Вотылевка;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122882401;3;1
4aba3f6f-749b-11df-b112-00215aee3ebe;Вочківці;Вочковцы;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820981701;3;1
4aba3f70-749b-11df-b112-00215aee3ebe;Вощанці;Вощанцы;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624281901;3;1
4aba3f71-749b-11df-b112-00215aee3ebe;Вощатин;Вощатин;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720581607;3;1
4aba3f72-749b-11df-b112-00215aee3ebe;Вощилиха;Вощилиха;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924181705;3;1
4aba3f73-749b-11df-b112-00215aee3ebe;Вощинине;Вощинино;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923885407;3;1
4aba3f74-749b-11df-b112-00215aee3ebe;Воютин;Воютин;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881501;3;1
4aba3f75-749b-11df-b112-00215aee3ebe;Воютичі;Воютичи;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624282201;3;1
4aba3f76-749b-11df-b112-00215aee3ebe;Врадіївка;Врадиевка;місто;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822355100;3;0
4aba3f7a-749b-11df-b112-00215aee3ebe;Врублівка;Врублевка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821481501;3;1
4aba3f7b-749b-11df-b112-00215aee3ebe;Врублівці;Врублевцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822480701;3;1
4aba3f7c-749b-11df-b112-00215aee3ebe;Всадки;Всадки;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920382002;3;1
4aba3f7d-749b-11df-b112-00215aee3ebe;Всеволодівка;Всеволодовка;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881804;3;1
4aba3f7e-749b-11df-b112-00215aee3ebe;Всесвятське;Всесвятское;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223281003;3;1
4aba3f7f-749b-11df-b112-00215aee3ebe;Всесвятське;Всесвятское;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655115;3;1
4aba3f82-749b-11df-b112-00215aee3ebe;Вугли;Вуглы;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524884502;3;1
4aba3f84-749b-11df-b112-00215aee3ebe;Вужиськ;Вужиск;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724284202;3;1
4aba3f85-749b-11df-b112-00215aee3ebe;Вузлове;Узловое;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623987201;3;1
4aba3f87-749b-11df-b112-00215aee3ebe;Вуйковичі;Вуйковичи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622486902;3;1
4aba3f88-749b-11df-b112-00215aee3ebe;Вукитичеве;Вукитичево;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523681003;3;1
4aba3f8a-749b-11df-b112-00215aee3ebe;Вучкове;Вучково;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122480801;3;1
4aba3f8b-749b-11df-b112-00215aee3ebe;Вчорайше;Вчерайше;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825282401;3;1
4aba3fa6-749b-11df-b112-00215aee3ebe;Гавареччина;Гаваретчина;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4621880403;3;1
c6df4c6f-a122-11e8-80d4-1c98ec135261;Гавареччина;Гавареччина;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;c766a65e-61e3-11ea-80c6-000c29800ae7;4621880403;3;1
4aba3fa7-749b-11df-b112-00215aee3ebe;Гавиноси;Гавиносы;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123180601;3;1
4aba3fa9-749b-11df-b112-00215aee3ebe;Гаврилівка;Гавриловка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224285601;3;1
4aba3faa-749b-11df-b112-00215aee3ebe;Гаврилівка;Гавриловка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324285004;3;1
4aba3fab-749b-11df-b112-00215aee3ebe;Гаврилівка;Гавриловка;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325456701;3;1
4aba3fac-749b-11df-b112-00215aee3ebe;Гаврилівка;Гавриловка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320481501;3;1
4aba3fad-749b-11df-b112-00215aee3ebe;Гаврилівка;Гавриловка;село;78f4540d-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6524181001;3;1
4aba3fae-749b-11df-b112-00215aee3ebe;Гаврилівка;Гавриловка;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624081201;3;1
4aba3fb0-749b-11df-b112-00215aee3ebe;Гаврилівка;Гавриловка;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221882001;3;1
4aba3fb1-749b-11df-b112-00215aee3ebe;Гаврилівка;Гавриловка;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420682801;3;1
4aba3fb2-749b-11df-b112-00215aee3ebe;Гаврилівка;Гавриловка;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824781501;3;1
50c59175-749b-11df-b112-00215aee3ebe;Гаврилівка (Гришине);Гавриловка (Гришино);село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924184701;3;1
871621fb-749b-11df-b112-00215aee3ebe;Гаврилівка (Улянівка);Гавриловка (Ульяновка);село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822185102;3;1
4aba3fb4-749b-11df-b112-00215aee3ebe;Гаврилівці;Гавриловцы;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322583502;3;1
4aba3fb5-749b-11df-b112-00215aee3ebe;Гаврилівці;Гавриловцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822486802;3;1
4aba3fb6-749b-11df-b112-00215aee3ebe;Гаврилки;Гаврилки;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324084605;3;1
4aba3fb7-749b-11df-b112-00215aee3ebe;Гаврилова Слобода;Гаврилова Слобода;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924486303;3;1
4aba3fb9-749b-11df-b112-00215aee3ebe;Гавриляк;Гавриляк;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625681201;3;1
4aba3fba-749b-11df-b112-00215aee3ebe;Гавриші;Гавриши;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320888105;3;1
4aba3fbb-749b-11df-b112-00215aee3ebe;Гавришівка;Гавришовка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222384004;3;1
4aba3fbc-749b-11df-b112-00215aee3ebe;Гавришівка;Гавришовка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522482602;3;1
4aba3fbd-749b-11df-b112-00215aee3ebe;Гавришівка;Гавришовка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520285006;3;1
4aba3fbe-749b-11df-b112-00215aee3ebe;Гавришівка;Гавришовка;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520681203;3;0
4aba3fbf-749b-11df-b112-00215aee3ebe;Гавронці;Гавронцы;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321084902;3;1
4aba3fc0-749b-11df-b112-00215aee3ebe;Гавронщина;Гавронщина;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222781801;3;1
4aba3fc1-749b-11df-b112-00215aee3ebe;Гавчиці;Гавчици;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;*721884301;3;1
4aba3fc2-749b-11df-b112-00215aee3ebe;Гадзинка;Гадзинка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822082003;3;1
4aba3fc3-749b-11df-b112-00215aee3ebe;Гадинківці;Гадинковцы;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121682401;3;1
4aba3fc4-749b-11df-b112-00215aee3ebe;Гадяч;Гадяч;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323382504;3;1
4aba3fc5-749b-11df-b112-00215aee3ebe;Гадяч;Гадяч;місто;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5310300000;2;0
4aba3fc6-749b-11df-b112-00215aee3ebe;Гаєвичі;Гаевичи;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824286203;3;1
4aba3fc7-749b-11df-b112-00215aee3ebe;Гаєнка;Гаенка;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;;3;1
4aba3fc8-749b-11df-b112-00215aee3ebe;Газове;Газовое;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321256001;3;1
4aba3fc9-749b-11df-b112-00215aee3ebe;Газопровідне;Газопроводное;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424780502;3;1
4aba3fca-749b-11df-b112-00215aee3ebe;Гаї;Гаи;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924181706;3;1
4aba3fcb-749b-11df-b112-00215aee3ebe;Гаї;Гаи;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123481701;3;1
4aba3fcc-749b-11df-b112-00215aee3ebe;Гаї;Гаи;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620381301;3;1
4aba3fcd-749b-11df-b112-00215aee3ebe;Гаї;Гаи;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623684402;3;1
0272e71f-005b-11e5-ba66-003048d2b473;Гаї Ходорівські;Гаи Ходоривски;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125280601;3;1
4aba3fce-749b-11df-b112-00215aee3ebe;Гаївка;Гаевка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324581502;3;1
4aba3fcf-749b-11df-b112-00215aee3ebe;Гаївка;Гаевка;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725755101;3;1
4aba3fd0-749b-11df-b112-00215aee3ebe;Гаївка;Гаевка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123981101;3;1
4aba3fd2-749b-11df-b112-00215aee3ebe;Гаївка;Гаевка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521781001;3;1
4aba3fd3-749b-11df-b112-00215aee3ebe;Гаївка;Гаевка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522582401;3;1
87162204-749b-11df-b112-00215aee3ebe;Гаївка (Улянове);Гаївка (Улянове);село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824787002;3;1
4aba3fd4-749b-11df-b112-00215aee3ebe;Гаївське;Гаевское;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825485102;3;1
4aba3fd5-749b-11df-b112-00215aee3ebe;Гаївське;Гаевское;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620681703;3;1
4aba3fd6-749b-11df-b112-00215aee3ebe;Гаївщина;Гаевщина;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322681502;3;1
ca634b76-4ca3-11e0-a467-00215aee3ebe;Гаї-Гречинські;Гаи-Гречинские;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125289802;2;0
4aba3fd7-749b-11df-b112-00215aee3ebe;Гаї-Дітковецькі;Гаи-Дитковецкие;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620381302;3;1
4aba3fd8-749b-11df-b112-00215aee3ebe;Гаї-за-Рудою;Гаи-За-Рудою;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122681801;3;1
4aba3fd9-749b-11df-b112-00215aee3ebe;Гаї-Лев'ятинські;Гаи-Левятинские;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625886003;3;1
4aba3fda-749b-11df-b112-00215aee3ebe;Гаї-Розтоцькі;Гаи-Ростоцкие;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122681901;3;1
4aba3fdb-749b-11df-b112-00215aee3ebe;Гаї-Смоленські;Гаи-Смоленские;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620381303;3;1
4aba3fdc-749b-11df-b112-00215aee3ebe;Гаї-Суходільські;Гаи-Суходольские;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620385307;3;1
4aba3fdd-749b-11df-b112-00215aee3ebe;Гаї-Шевченківські;Гаи-Шевченковские;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125282101;2;1
4aba3fde-749b-11df-b112-00215aee3ebe;Гай;Гай;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923583403;3;1
4aba3fdf-749b-11df-b112-00215aee3ebe;Гай;Гай;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224280509;3;1
4aba3fe0-749b-11df-b112-00215aee3ebe;Гай;Гай;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323080803;3;1
4aba3fe1-749b-11df-b112-00215aee3ebe;Гай;Гай;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221082003;3;1
4aba3fe3-749b-11df-b112-00215aee3ebe;Гай;Гай;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822086202;3;1
4aba3fe4-749b-11df-b112-00215aee3ebe;Гай;Гай;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423683204;3;1
4aba3fe5-749b-11df-b112-00215aee3ebe;Гай;Гай;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424986002;3;1
4aba3fe6-749b-11df-b112-00215aee3ebe;Гай;Гай;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120688403;3;1
4aba3fe7-749b-11df-b112-00215aee3ebe;Гай;Гай;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625887302;3;1
4aba3fe8-749b-11df-b112-00215aee3ebe;Гай;Гай;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624288004;3;1
80f55c90-749b-11df-b112-00215aee3ebe;Гай;Гай ;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;;3;1
4aba3fe9-749b-11df-b112-00215aee3ebe;Гайворон;Гайворон;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221681201;3;1
4aba3fea-749b-11df-b112-00215aee3ebe;Гайворон;Гайворон;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420382001;3;1
4aba3feb-749b-11df-b112-00215aee3ebe;Гайворон;Гайворон;місто;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521110100;2;0
4aba3fec-749b-11df-b112-00215aee3ebe;Гайворонка;Гайворонка;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125081501;3;1
4aba3fed-749b-11df-b112-00215aee3ebe;Гайворонське;Гайворонское;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
4aba3fee-749b-11df-b112-00215aee3ebe;Гайдайки;Гайдайки;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820984002;3;1
871626d5-749b-11df-b112-00215aee3ebe;Гайдамацьке (Червоний Яр);Гайдамацьке (Червоний Яр);село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225086009;3;1
4aba3fef-749b-11df-b112-00215aee3ebe;Гайдари;Гайдары;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321782502;3;1
4aba3ff0-749b-11df-b112-00215aee3ebe;Гайдош;Гайдош;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124883002;3;1
4aba3ff1-749b-11df-b112-00215aee3ebe;Гайдучина;Гайдучина;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625355301;3;1
4aba3ff2-749b-11df-b112-00215aee3ebe;Гайки;Гайки;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725580801;3;1
4aba3ff3-749b-11df-b112-00215aee3ebe;Гайки;Гайки;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821183803;3;1
4aba3ff4-749b-11df-b112-00215aee3ebe;Гайки;Гайки;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625884803;3;1
4aba3ff5-749b-11df-b112-00215aee3ebe;Гайки;Гайки;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325180803;3;1
4aba3ff6-749b-11df-b112-00215aee3ebe;Гайки-Ситенські;Гайки-Сытенские;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;Броди;3;1
4aba3ff7-749b-11df-b112-00215aee3ebe;Гаймарівка;Гаймаровка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321880903;3;1
4aba3ff8-749b-11df-b112-00215aee3ebe;Гай-Мошенка;Гай-Мошенка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920384003;3;1
4aba3ff9-749b-11df-b112-00215aee3ebe;Гайове;Гаевое;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322080803;3;1
4aba3ffa-749b-11df-b112-00215aee3ebe;Гайове;Гаевое;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321884202;3;1
4aba3ffb-749b-11df-b112-00215aee3ebe;Гайове;Гаевое;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721884802;3;1
4aba3ffe-749b-11df-b112-00215aee3ebe;Гайове;Гаевое;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422088502;3;1
4aba3fff-749b-11df-b112-00215aee3ebe;Гайове;Гаевое;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520281001;3;1
4aba4000-749b-11df-b112-00215aee3ebe;Гайове;Гаевое;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520581703;3;1
4aba4001-749b-11df-b112-00215aee3ebe;Гайове;Гаевое;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520387302;3;1
4aba4002-749b-11df-b112-00215aee3ebe;Гайове;Гаевое;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825287402;2;1
8716233f-749b-11df-b112-00215aee3ebe;Гайове (Фрунзівка);Гайове (Фрунзівка);село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221280403;3;1
4aba4004-749b-11df-b112-00215aee3ebe;Гайок;Гаек;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622184903;3;1
4aba4005-749b-11df-b112-00215aee3ebe;Гайок;Гаек;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221687602;3;1
4aba4006-749b-11df-b112-00215aee3ebe;Гайок;Гаек;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520383503;3;1
4aba3fe2-749b-11df-b112-00215aee3ebe;Гайок;Гаек;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220481003;2;1
a738d079-60e8-11e0-9fa7-00215aee3ebe;Гайок;Гаек;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120483002;3;1
4aba4007-749b-11df-b112-00215aee3ebe;Гайсин;Гайсин;місто;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520810100;2;0
4aba4008-749b-11df-b112-00215aee3ebe;Гайшин;Гайшин;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223380801;3;1
4aba4009-749b-11df-b112-00215aee3ebe;Галабор;Галабор;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120482201;3;1
4aba400a-749b-11df-b112-00215aee3ebe;Галаганівка;Галагановка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825782703;3;1
4aba400b-749b-11df-b112-00215aee3ebe;Галаганівка;Галагановка;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424781301;3;1
4aba400c-749b-11df-b112-00215aee3ebe;Галаганівка;Галагановка;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125481201;3;1
4aba400d-749b-11df-b112-00215aee3ebe;Галагурівка;Галагуровка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321882203;3;1
4aba400e-749b-11df-b112-00215aee3ebe;Галаївець (Галаєвець);Галаивец (Галаевец);село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923281705;3;1
4aba400f-749b-11df-b112-00215aee3ebe;Галайбине;Галайбино;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420881202;3;1
4aba4010-749b-11df-b112-00215aee3ebe;Галайки;Галайки;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224681201;3;1
4aba4011-749b-11df-b112-00215aee3ebe;Галайківці;Галайковцы;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522882001;3;1
4aba4012-749b-11df-b112-00215aee3ebe;Галасі;Галасы;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783905;3;1
4aba4013-749b-11df-b112-00215aee3ebe;Галенкове;Галенково;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187602;3;1
4aba4014-749b-11df-b112-00215aee3ebe;Галина Воля;Галина Воля;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725085201;3;1
4aba4015-749b-11df-b112-00215aee3ebe;Галина Лозуватка;Галина Лозоватка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224585904;3;1
4aba4016-749b-11df-b112-00215aee3ebe;Галинівка;Галиновка;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720581501;3;1
4aba4017-749b-11df-b112-00215aee3ebe;Галинівка;Галиновка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821185402;3;1
4aba4018-749b-11df-b112-00215aee3ebe;Галинка;Галинка;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221083003;3;1
4aba401a-749b-11df-b112-00215aee3ebe;Галицинове;Галицыново;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823380601;3;1
4aba401b-749b-11df-b112-00215aee3ebe;Галицівка;Галицивка;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323584502;3;1
4aba401c-749b-11df-b112-00215aee3ebe;Галиця;Галица;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324086003;3;1
4aba401d-749b-11df-b112-00215aee3ebe;Галиця;Галица;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423382801;3;1
4aba401e-749b-11df-b112-00215aee3ebe;Галич;Галич;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124881101;3;1
4aba401f-749b-11df-b112-00215aee3ebe;Галич;Галич;місто;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2621210100;3;0
4aba4020-749b-11df-b112-00215aee3ebe;Галичани;Галичаны;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720881301;3;1
4aba4021-749b-11df-b112-00215aee3ebe;Галичани;Галичаны;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620981801;3;1
4aba4022-749b-11df-b112-00215aee3ebe;Галичівка;Галичевка;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422285002;3;1
4aba4023-749b-11df-b112-00215aee3ebe;Галівка;Галовка;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625183702;3;1
4aba4024-749b-11df-b112-00215aee3ebe;Галі-Горбатки;Гали-Горбатки;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321884403;3;1
4aba4025-749b-11df-b112-00215aee3ebe;Галіївка;Галиевка;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825881701;3;1
4aba4026-749b-11df-b112-00215aee3ebe;Галійка;Галийка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321384403;3;1
4aba4027-749b-11df-b112-00215aee3ebe;Галка;Галка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924184101;3;1
4aba4028-749b-11df-b112-00215aee3ebe;Галка;Галка;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425182503;3;1
4aba4029-749b-11df-b112-00215aee3ebe;Галків;Галков;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424485202;3;1
4aba402a-749b-11df-b112-00215aee3ebe;Галоч;Галоч;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124881501;3;1
4aba402b-749b-11df-b112-00215aee3ebe;Галочі;Галочи;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123181303;3;1
4aba402c-749b-11df-b112-00215aee3ebe;Галузинці;Галузынци;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821581601;3;1
4aba402d-749b-11df-b112-00215aee3ebe;Галузія;Галузия;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723685502;3;1
4aba402e-749b-11df-b112-00215aee3ebe;Галупове;Галупово;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125485202;3;1
4aba402f-749b-11df-b112-00215aee3ebe;Галушки;Галушки;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922987305;3;1
4aba4030-749b-11df-b112-00215aee3ebe;Галущина Гребля;Галущина Гребля;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323480901;3;1
4aba4031-749b-11df-b112-00215aee3ebe;Галущинці;Галущинцы;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124681001;3;1
4aba4032-749b-11df-b112-00215aee3ebe;Галяве;Галяво;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325181705;3;1
4aba4033-749b-11df-b112-00215aee3ebe;Гальжбіївка;Гальжбиевка;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525681601;3;1
4aba4034-749b-11df-b112-00215aee3ebe;Гальчин;Гальчин;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820881501;3;1
4aba4035-749b-11df-b112-00215aee3ebe;Гальчин;Гальчин;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820382401;3;1
4aba4036-749b-11df-b112-00215aee3ebe;Гальчин;Гальчин;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422084702;3;1
4aba4037-749b-11df-b112-00215aee3ebe;Гальчинці;Гальчинцы;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824782001;3;1
4aba4038-749b-11df-b112-00215aee3ebe;Гамаліївка;Гамалиевка;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925381001;3;1
4aba4039-749b-11df-b112-00215aee3ebe;Гамаліївка;Гамалиевка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922055303;3;1
4aba403a-749b-11df-b112-00215aee3ebe;Гамаліївка;Гамалиевка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983202;3;1
4aba403b-749b-11df-b112-00215aee3ebe;Гамаліївка;Гамалиевка;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623681915;3;1
4aba403c-749b-11df-b112-00215aee3ebe;Гамаліївка;Гамалиевка;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322687202;3;1
4aba403d-749b-11df-b112-00215aee3ebe;Гамарня;Гамарня;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823487402;3;1
4aba403e-749b-11df-b112-00215aee3ebe;Гамарня;Гамарня;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122083103;3;1
4aba403f-749b-11df-b112-00215aee3ebe;Гамарня;Гамарня;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821886802;3;1
4aba4041-749b-11df-b112-00215aee3ebe;Гамове;Гамово;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821781202;3;1
4aba4042-749b-11df-b112-00215aee3ebe;Гамулівка;Гамуловка;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524980305;3;1
4aba4043-749b-11df-b112-00215aee3ebe;Ганачівка;Ганачевка;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623386802;3;1
4aba4044-749b-11df-b112-00215aee3ebe;Гандеровиця;Гандеровица;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122787202;3;1
4aba4045-749b-11df-b112-00215aee3ebe;Гандрабури;Гандрабуры;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120281001;3;1
4aba4046-749b-11df-b112-00215aee3ebe;Ганжалівка;Ганжаловка;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122886802;3;1
4aba4047-749b-11df-b112-00215aee3ebe;Ганжі;Ганжи;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324210103;3;1
4aba4048-749b-11df-b112-00215aee3ebe;Ганжі;Ганжи;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323482204;3;1
4aba4049-749b-11df-b112-00215aee3ebe;Ганжівка;Ганжевка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321882204;3;1
4aba404a-749b-11df-b112-00215aee3ebe;Ганичі;Ганичи;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124481201;3;1
4aba404b-749b-11df-b112-00215aee3ebe;Ганівка;Гановка;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624883202;3;1
4aba404c-749b-11df-b112-00215aee3ebe;Ганнинське;Аннинское;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522584203;3;1
4aba404d-749b-11df-b112-00215aee3ebe;Ганнів;Аннов;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623281802;3;1
4aba404e-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320655401;3;1
4aba404f-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322082502;3;1
4aba4050-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223283002;3;1
4aba4051-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221081001;3;1
4aba4052-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455108;3;1
4aba4053-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225855103;3;1
4aba4054-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821480401;3;1
4aba4055-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824580404;3;1
4aba4056-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820981203;3;1
4aba4057-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;64024b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324681002;3;1
4aba4058-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323280603;3;1
4aba4059-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320682503;3;1
4aba405a-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124782815;3;1
4aba4062-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821786802;3;1
4aba4063-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755103;3;1
4aba4064-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;;3;1
4aba4065-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424955101;3;1
4aba4066-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423880501;3;1
4aba4067-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522286402;3;1
4aba4068-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523186902;3;1
4aba4069-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523883403;3;1
4aba406a-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523680401;3;1
4aba406b-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524080301;3;1
4aba406c-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525281203;3;1
4aba406d-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524980401;3;1
4aba406e-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3521984403;3;1
4aba406f-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821880201;3;1
4aba4070-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623086302;3;1
4aba4071-749b-11df-b112-00215aee3ebe;Ганнівка;Анновка;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623487402;3;1
4aba4072-749b-11df-b112-00215aee3ebe;Ганнівка-Вирівська;Анновка-Вировская;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920680301;3;1
4aba4073-749b-11df-b112-00215aee3ebe;Ганнівка-Тернівська;Анновка-Терновская;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920688203;3;1
4aba4074-749b-11df-b112-00215aee3ebe;Ганнівське;Анновское;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920687802;3;1
4aba4075-749b-11df-b112-00215aee3ebe;Ганнівці;Анновцы;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621280302;3;1
4aba4076-749b-11df-b112-00215aee3ebe;Ганнівці;Анновцы;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621555303;3;1
4aba4078-749b-11df-b112-00215aee3ebe;Ганно- Миколаївка;Анно-Николаевка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282509;3;1
4aba4077-749b-11df-b112-00215aee3ebe;Ганно-Леонтовичеве;Анно-Леонтовичево;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525880301;3;1
871626ef-749b-11df-b112-00215aee3ebe;Ганно-Мусіївка (Червоноармійське);Анно-Мусиевка (Червоноармейское);село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225083009;3;1
4aba407b-749b-11df-b112-00215aee3ebe;Ганнопіль;Аннополь;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821780301;3;1
4aba407c-749b-11df-b112-00215aee3ebe;Ганнопіль;Аннополь;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825681602;3;1
4aba407d-749b-11df-b112-00215aee3ebe;Ганнопіль;Аннополь;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524380201;3;1
4aba407e-749b-11df-b112-00215aee3ebe;Ганнопіль;Аннополь;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520886402;3;1
4aba407f-749b-11df-b112-00215aee3ebe;Ганнопіль;Аннополь;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823980301;3;1
4aba4080-749b-11df-b112-00215aee3ebe;Ганно-Покровка;Анно-Покровка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125481402;3;1
4aba4081-749b-11df-b112-00215aee3ebe;Ганно-Рудаєве;Анно-Рудаево;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684005;3;1
4aba4082-749b-11df-b112-00215aee3ebe;Ганно-Требинівка;Анно-Требиновка;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525880401;3;1
4aba4084-749b-11df-b112-00215aee3ebe;Ганнусине;Ганнусино;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823610101;3;1
4aba4083-749b-11df-b112-00215aee3ebe;Ганнусівка;Ганнусовка;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625880801;3;1
4aba4086-749b-11df-b112-00215aee3ebe;Ганьківці;Ганьковцы;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625255302;3;1
4aba4087-749b-11df-b112-00215aee3ebe;Ганьковиця;Ганьковица;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124080801;3;1
4aba4088-749b-11df-b112-00215aee3ebe;Ганьковичі;Ганьковичи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484902;3;1
4aba4089-749b-11df-b112-00215aee3ebe;Гапішківка;Гапишковка;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424982502;3;1
4aba408a-749b-11df-b112-00215aee3ebe;Гапонівка;Гапоновка;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922387602;3;1
4aba408b-749b-11df-b112-00215aee3ebe;Гапоно - Мечетне;Гапоно-Мечетное;село;00000000-0000-0000-0000-000000000000;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224255103;3;1
4aba408c-749b-11df-b112-00215aee3ebe;Гаразджа;Гаразджа;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722885002;3;1
4aba408d-749b-11df-b112-00215aee3ebe;Гарапівка;Гараповка;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820387802;3;1
4aba408e-749b-11df-b112-00215aee3ebe;Гарасимів;Гарасимов;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625681301;3;1
4aba408f-749b-11df-b112-00215aee3ebe;Гарасимівка;Гарасимовка;село;00000000-0000-0000-0000-000000000000;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;;3;1
4aba4090-749b-11df-b112-00215aee3ebe;Гарасимівка;Гарасимовка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224281204;3;1
4aba4092-749b-11df-b112-00215aee3ebe;Гарасівка;Гарасовка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582505;3;1
4aba4094-749b-11df-b112-00215aee3ebe;Гарашківка;Гарашковка;село;64024b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324681003;3;1
4aba4095-749b-11df-b112-00215aee3ebe;Гарбарі;Гарбари;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922981202;3;1
4aba4096-749b-11df-b112-00215aee3ebe;Гарбузи;Гарбузы;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320888202;3;1
4aba4097-749b-11df-b112-00215aee3ebe;Гарбузин;Гарбузин;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422080202;3;1
4aba4098-749b-11df-b112-00215aee3ebe;Гарбузин;Гарбузин;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122510101;3;1
4aba4099-749b-11df-b112-00215aee3ebe;Гарбузів;Гарбузов;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122682201;3;1
4aba409a-749b-11df-b112-00215aee3ebe;Гарбузівка;Гарбузовка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321883202;3;1
4aba409b-749b-11df-b112-00215aee3ebe;Гарбузівка;Гарбузовка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982701;3;1
4aba409c-749b-11df-b112-00215aee3ebe;Гарбузівка;Гарбузовка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455109;3;1
4aba409e-749b-11df-b112-00215aee3ebe;Гардишівка;Гардышевка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820881801;3;1
4aba409f-749b-11df-b112-00215aee3ebe;Гаркушине;Гаркушино;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225080507;3;1
4aba40a0-749b-11df-b112-00215aee3ebe;Гаркушине;Гаркушино;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324885502;3;1
4aba40a1-749b-11df-b112-00215aee3ebe;Гаркушинці;Гаркушинцы;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323281401;3;1
4aba40a2-749b-11df-b112-00215aee3ebe;Гармаки;Гармаки;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520281201;3;1
4aba40a3-749b-11df-b112-00215aee3ebe;Гарманівка;Гармановка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522881401;3;1
4aba40a5-749b-11df-b112-00215aee3ebe;Гармащина;Гармащина;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423387403;3;1
4aba40a6-749b-11df-b112-00215aee3ebe;Гарнишівка;Гарнышевка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820981901;3;1
4aba40a7-749b-11df-b112-00215aee3ebe;Гарнокут;Гарнокут;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320255104;3;1
4aba40a8-749b-11df-b112-00215aee3ebe;Гарт;Гарт;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420688002;3;1
4aba40a9-749b-11df-b112-00215aee3ebe;Гаруша;Гаруша;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725581303;3;1
4aba40ab-749b-11df-b112-00215aee3ebe;Гарячківка;Горячковка;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521981502;3;1
4aba40ac-749b-11df-b112-00215aee3ebe;Гасанівка;Гасановка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321581402;3;0
4aba40ad-749b-11df-b112-00215aee3ebe;Гасенки;Гасенки;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323283405;3;1
4aba40ae-749b-11df-b112-00215aee3ebe;Гасичівка;Гасичевка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421486802;3;1
4aba40b1-749b-11df-b112-00215aee3ebe;Гаті;Гати;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424782502;3;1
4aba40b2-749b-11df-b112-00215aee3ebe;Гатка;Гатка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920983202;3;1
4aba40b3-749b-11df-b112-00215aee3ebe;Гатківка;Гатковка;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624884603;3;1
4aba40b4-749b-11df-b112-00215aee3ebe;Гатна;Гатная;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821582001;3;1
4aba40b5-749b-11df-b112-00215aee3ebe;Гатне;Гатное;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222481601;2;0
4aba40b6-749b-11df-b112-00215aee3ebe;Гать;Гать;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881503;3;1
4aba40b7-749b-11df-b112-00215aee3ebe;Гать;Гать;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120482801;3;1
50c58da2-749b-11df-b112-00215aee3ebe;Гацьківка;Гацковка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821183002;3;1
50c58da4-749b-11df-b112-00215aee3ebe;Гвардійське;Гвардейское;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223255300;3;1
50c58da5-749b-11df-b112-00215aee3ebe;Гвардійське;Гвардейское;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122755903;3;1
50c58da9-749b-11df-b112-00215aee3ebe;Гвардійське;Гвардейское;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224986902;3;1
50c58daa-749b-11df-b112-00215aee3ebe;Гвардійське;Гвардейское;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825081501;3;1
50c58da3-749b-11df-b112-00215aee3ebe;Гвардійське (Гниловоди);Гвардейское;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125081701;3;1
50c58dab-749b-11df-b112-00215aee3ebe;Гвинтове;Гвинтовое;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920982401;3;1
50c58dac-749b-11df-b112-00215aee3ebe;Гвізд;Гвозд;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624081501;3;1
50c58dad-749b-11df-b112-00215aee3ebe;Гвіздава;Гвоздава;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820885402;3;1
50c58dae-749b-11df-b112-00215aee3ebe;Гвіздець;Гвоздец;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623255200;3;1
50c58daf-749b-11df-b112-00215aee3ebe;Гвіздів;Гвоздев;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623081601;3;1
50c58db0-749b-11df-b112-00215aee3ebe;Гвіздівці;Гвоздовцы;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324083501;3;1
50c58db1-749b-11df-b112-00215aee3ebe;Гвіздярня;Гвоздярня;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821484602;3;1
50c58db2-749b-11df-b112-00215aee3ebe;Гвоздавка Друга;Гвоздавка Вторая;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123381401;3;1
50c58db3-749b-11df-b112-00215aee3ebe;Гвоздавка Перша;Гвоздавка Первая;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123381404;3;1
50c58db4-749b-11df-b112-00215aee3ebe;Гвоздець;Гвоздец;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625181907;3;1
50c58db5-749b-11df-b112-00215aee3ebe;Гвоздиківка;Гвоздиковка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081302;3;1
50c58db6-749b-11df-b112-00215aee3ebe;Гвоздиківка;Гвоздиковка;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425887502;3;1
50c58db7-749b-11df-b112-00215aee3ebe;Гвоздів;Гвоздов;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221481501;3;1
50c58db8-749b-11df-b112-00215aee3ebe;Гвоздів;Гвоздов;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523087802;3;1
50c58db9-749b-11df-b112-00215aee3ebe;Гвоздівка;Гвоздовка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123583002;3;1
50c58dba-749b-11df-b112-00215aee3ebe;Гвоздьове;Гвоздево;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321281504;3;1
50c58dbb-749b-11df-b112-00215aee3ebe;Гевин;Гевин;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720581505;3;1
50c58dbc-749b-11df-b112-00215aee3ebe;Гедеримове Перше;Гедеримово Первое;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125255405;3;1
50c58dbd-749b-11df-b112-00215aee3ebe;Гезівка;Гезовка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920687803;3;1
50c58dbe-749b-11df-b112-00215aee3ebe;Геївка;Геевка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321783002;3;1
50c58dbf-749b-11df-b112-00215aee3ebe;Гейківка;Гейковка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221881401;3;1
50c58dc0-749b-11df-b112-00215aee3ebe;Гейсиха;Гейсиха;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224282401;3;1
50c58dc1-749b-11df-b112-00215aee3ebe;Гейці;Гейцы;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421788402;3;1
50c58dc2-749b-11df-b112-00215aee3ebe;Гектари;Гектары;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720885805;3;1
87162712-749b-11df-b112-00215aee3ebe;Геленджик (Червонокозацьке);Геленджик (Червоноказацкое);село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321580506;3;1
50c58dc3-749b-11df-b112-00215aee3ebe;Геленки;Геленки;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123081901;3;1
50c58dc4-749b-11df-b112-00215aee3ebe;Гелетина;Гелетина;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822482103;3;1
50c58dc5-749b-11df-b112-00215aee3ebe;Гелетинці;Гелетинцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825081801;3;1
50c58dc6-749b-11df-b112-00215aee3ebe;Гельмязів;Гельмязов;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121582301;3;1
50c58dc8-749b-11df-b112-00215aee3ebe;Гензерівка;Гензеровка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225583002;3;1
50c58dc9-749b-11df-b112-00215aee3ebe;Генівка;Геновка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825485702;3;1
50c58dca-749b-11df-b112-00215aee3ebe;Геніївка;Гениевка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321782001;3;1
50c58dcd-749b-11df-b112-00215aee3ebe;Георгіївка;Георгиевка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224887104;3;1
50c58dd4-749b-11df-b112-00215aee3ebe;Георгіївка;Георгиевка;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125782603;3;1
50c58dd5-749b-11df-b112-00215aee3ebe;Георгіївське;Георгиевское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321584006;3;1
50c58dd6-749b-11df-b112-00215aee3ebe;Герасимівка;Герасимовка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324588005;3;1
50c58dd7-749b-11df-b112-00215aee3ebe;Герасимівка;Герасимовка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187903;3;1
50c58dd8-749b-11df-b112-00215aee3ebe;Герасимівка;Гарасимовка;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521680906;3;1
50c58ddb-749b-11df-b112-00215aee3ebe;Гербине;Гербино;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120681501;3;1
50c58ddc-749b-11df-b112-00215aee3ebe;Гереженівка;Гереженовка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124381201;3;1
50c58ddd-749b-11df-b112-00215aee3ebe;Гериня;Герыня;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622081201;3;1
50c58dde-749b-11df-b112-00215aee3ebe;Гермаківка;Гермаковка;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120882101;3;1
50c58ddf-749b-11df-b112-00215aee3ebe;Германівка;Германовка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324855101;3;1
50c58de0-749b-11df-b112-00215aee3ebe;Германівка;Германовка;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223184601;3;1
50c58de3-749b-11df-b112-00215aee3ebe;Геронимівка;Геронимовка;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124985202;3;0
50c58de4-749b-11df-b112-00215aee3ebe;Герсеванівка;Герсевановка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323985003;3;1
50c58de5-749b-11df-b112-00215aee3ebe;Гертопи;Гертопы;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122985604;3;1
50c58de6-749b-11df-b112-00215aee3ebe;Герусівка;Герусовка;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281205;3;1
50c58de7-749b-11df-b112-00215aee3ebe;Герца;Герца;місто;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320710100;3;1
50c58de8-749b-11df-b112-00215aee3ebe;Герцівці;Герцовцы;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122786602;3;1
50c58de9-749b-11df-b112-00215aee3ebe;Гетен;Гетен;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120488002;3;1
50c58dea-749b-11df-b112-00215aee3ebe;Гетиня;Гетиня;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121285902;3;1
50c58deb-749b-11df-b112-00215aee3ebe;Гетьманівка;Гетмановка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325784501;3;1
50c58dec-749b-11df-b112-00215aee3ebe;Гетьманівка;Гетмановка;село;6b07f835-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5124355101;3;1
50c58ded-749b-11df-b112-00215aee3ebe;Гетьманка;Гетманка;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322283702;3;1
50c58dee-749b-11df-b112-00215aee3ebe;Гетьманці;Гетманцы;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123983903;3;1
50c58def-749b-11df-b112-00215aee3ebe;Геча;Геча;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120483201;3;1
50c58df0-749b-11df-b112-00215aee3ebe;Гзондарівка;Гзондаровка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;;3;1
50c58df1-749b-11df-b112-00215aee3ebe;Гибалівка;Гибаловка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525380501;3;1
50c58df2-749b-11df-b112-00215aee3ebe;Гидерим;Гидерим;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122981302;3;1
50c58df3-749b-11df-b112-00215aee3ebe;Гиновичі;Гиновичи;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120481802;3;1
50c58df4-749b-11df-b112-00215aee3ebe;Гиньківці;Гиньковцы;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122082002;3;1
50c58df5-749b-11df-b112-00215aee3ebe;Гирине;Гирино;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324884603;3;1
50c58df6-749b-11df-b112-00215aee3ebe;Гирине;Гирино;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925681503;3;1
50c58df7-749b-11df-b112-00215aee3ebe;Гиріне;Гирино;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920683004;3;1
50c58df8-749b-11df-b112-00215aee3ebe;Гиряві Ісківці;Гирявые Исковцы;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322682401;3;1
50c58dfa-749b-11df-b112-00215aee3ebe;Гізівщина;Гизовщина;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823182901;3;1
50c58dfb-749b-11df-b112-00215aee3ebe;Гійче;Гийче;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622782001;3;1
50c58dfc-749b-11df-b112-00215aee3ebe;Гільці;Гильцы;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325181301;3;1
50c58dfd-749b-11df-b112-00215aee3ebe;Гільча Друга;Гильча Вторая;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5622687602;3;1
50c58dfe-749b-11df-b112-00215aee3ebe;Гільча Перша;Гильча Первая;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5622683803;3;1
50c58dff-749b-11df-b112-00215aee3ebe;Гінці;Гонцы;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322881802;3;1
50c58e00-749b-11df-b112-00215aee3ebe;Гіржове;Гиржово;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655108;3;1
50c58e02-749b-11df-b112-00215aee3ebe;Гірка Полонка;Горькая Полонка;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881601;3;0
50c58e03-749b-11df-b112-00215aee3ebe;Гіркани;Горканы;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787604;3;1
50c58e05-749b-11df-b112-00215aee3ebe;Гірки;Горки;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923881004;3;1
50c58e06-749b-11df-b112-00215aee3ebe;Гірки;Горки;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922987803;3;1
50c58e07-749b-11df-b112-00215aee3ebe;Гірки;Горки;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155113;3;1
50c58e08-749b-11df-b112-00215aee3ebe;Гірки;Горки;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224881301;3;1
50c58e09-749b-11df-b112-00215aee3ebe;Гірки;Горки;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787605;3;1
50c58e0a-749b-11df-b112-00215aee3ebe;Гірки;Горки;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824087402;3;1
50c58e0b-749b-11df-b112-00215aee3ebe;Гірки;Горки;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424456001;3;1
50c58e0c-749b-11df-b112-00215aee3ebe;Гірки;Горки;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423683602;3;1
50c58e0d-749b-11df-b112-00215aee3ebe;Гірки;Горки;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322681904;3;1
50c58e0e-749b-11df-b112-00215aee3ebe;Гірківка;Горковка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125255103;3;1
50c58e0f-749b-11df-b112-00215aee3ebe;Гірманка;Гирманка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425582502;3;1
50c58e10-749b-11df-b112-00215aee3ebe;Гірне;Горное;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924788703;3;1
50c58e11-749b-11df-b112-00215aee3ebe;Гірне;Горное;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625380401;3;1
50c58e16-749b-11df-b112-00215aee3ebe;Гірник;Горник;село;bad3ac26-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4611845300;2;0
50c58e19-749b-11df-b112-00215aee3ebe;Гірники;Горники;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724281801;3;1
50c58e1a-749b-11df-b112-00215aee3ebe;Гірники;Горники;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621681601;3;1
50c58e1b-749b-11df-b112-00215aee3ebe;Гірницьке;Горняцкое;село;4ffc4b7d-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1211090003;3;1
50c58e1c-749b-11df-b112-00215aee3ebe;Гірницьке;Гірницьке;село;bad3ac27-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1212145300;3;1
50c58e1e-749b-11df-b112-00215aee3ebe;Гірничий;Горный;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;;3;1
50c58e20-749b-11df-b112-00215aee3ebe;Гірськ;Горск;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425881001;3;1
50c58e21-749b-11df-b112-00215aee3ebe;Гірське;Горское;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655109;3;1
50c58e22-749b-11df-b112-00215aee3ebe;Гірське;Горское;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623287001;3;1
50c58e23-749b-11df-b112-00215aee3ebe;Гірське;Горское;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623083201;3;1
50c58e25-749b-11df-b112-00215aee3ebe;Гірчаківка;Горчаковка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323383502;3;1
50c58e26-749b-11df-b112-00215aee3ebe;Гірчична;Гирчичная;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821882101;3;1
50c58e27-749b-11df-b112-00215aee3ebe;Гішин;Гишин;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722181802;3;1
50c58e28-749b-11df-b112-00215aee3ebe;Главані;Главани;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120481401;3;1
50c58e2a-749b-11df-b112-00215aee3ebe;Гладке;Гладкое;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422087703;3;1
50c58e2b-749b-11df-b112-00215aee3ebe;Гладківка;Гладковка;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323280303;3;1
50c58e2d-749b-11df-b112-00215aee3ebe;Гладківщина;Гладковщина;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121582702;3;1
50c58e2f-749b-11df-b112-00215aee3ebe;Гладковицька Кам'янка;Гладковичская Каменка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824281902;3;1
50c58e30-749b-11df-b112-00215aee3ebe;Гладковичі;Гладковичи;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824281901;3;1
50c58e32-749b-11df-b112-00215aee3ebe;Глажова;Глажова;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623485003;3;1
50c58e34-749b-11df-b112-00215aee3ebe;Глазове;Глазовое;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925381201;3;1
50c58e35-749b-11df-b112-00215aee3ebe;Глазунівка;Глазуновка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320286002;3;1
50c58e36-749b-11df-b112-00215aee3ebe;Гланишів;Гланышев;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223381101;3;1
50c58e38-749b-11df-b112-00215aee3ebe;Глеваха;Глеваха;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221455300;2;0
50c58e39-749b-11df-b112-00215aee3ebe;Глезне;Глезно;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823183201;3;1
50c58e3a-749b-11df-b112-00215aee3ebe;Глещава;Глещава;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125081901;3;1
50c58e3b-749b-11df-b112-00215aee3ebe;Глеюватка;Глеюватка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221882001;3;1
50c58e3c-749b-11df-b112-00215aee3ebe;Глибівка;Глыбовка;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620480901;3;1
50c58e3d-749b-11df-b112-00215aee3ebe;Глибівка;Глыбовка;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823384502;3;1
50c58e3e-749b-11df-b112-00215aee3ebe;Глибне;Глыбное;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922385403;3;1
50c58e3f-749b-11df-b112-00215aee3ebe;Глибока;Глубокая;місто;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7321055100;3;0
50c58e40-749b-11df-b112-00215aee3ebe;Глибока;Глубокая;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623255401;3;1
50c58e41-749b-11df-b112-00215aee3ebe;Глибока;Глубокая;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625184502;3;1
50c58e4f-749b-11df-b112-00215aee3ebe;Глибока;Глубокая;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620480801;3;1
50c58e42-749b-11df-b112-00215aee3ebe;Глибока Балка;Глубокая Балка;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281903;3;1
50c58e43-749b-11df-b112-00215aee3ebe;Глибока Балка;Глубокая Балка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522282603;3;1
50c58e44-749b-11df-b112-00215aee3ebe;Глибока Долина;Глубокая Долина;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084703;3;1
50c58e45-749b-11df-b112-00215aee3ebe;Глибока Долина;Глубокая Долина;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321785004;3;1
50c58e46-749b-11df-b112-00215aee3ebe;Глибока Долина;Глубокая Долина;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522884002;3;1
50c58e47-749b-11df-b112-00215aee3ebe;Глибока Долина;Глубокая Долина;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621482503;3;1
50c58e48-749b-11df-b112-00215aee3ebe;Глибока Долина;Глубокая Долина;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320484602;3;1
50c58e49-749b-11df-b112-00215aee3ebe;Глибока Долина;Глубокая Долина;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324885405;3;1
50c58e4b-749b-11df-b112-00215aee3ebe;Глибоке;Глубокое;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320682502;3;1
50c58e4c-749b-11df-b112-00215aee3ebe;Глибоке;Глубокое;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820982205;3;1
50c58e4d-749b-11df-b112-00215aee3ebe;Глибоке;Глубокое;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325180701;3;1
50c58e4e-749b-11df-b112-00215aee3ebe;Глибоке;Глубокое;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125081401;2;1
50c58e51-749b-11df-b112-00215aee3ebe;Глибоке;Глубокое;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124881801;3;1
50c58e53-749b-11df-b112-00215aee3ebe;Глибоке;Глубокое;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220882201;3;1
50c58e54-749b-11df-b112-00215aee3ebe;Глибоке;Глубокое;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420389502;3;1
50c58e55-749b-11df-b112-00215aee3ebe;Глибоке;Глубокое;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425385002;3;1
50c58e56-749b-11df-b112-00215aee3ebe;Глибоке;Глубокое;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520385204;3;1
50c58e57-749b-11df-b112-00215aee3ebe;Глибоке;Глубокое;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320484102;3;1
50c58e58-749b-11df-b112-00215aee3ebe;Глибоке;Глубокое;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323281804;3;1
50c58e59-749b-11df-b112-00215aee3ebe;Глибоке Друге;Глубокое Второе;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;;3;1
50c58e5b-749b-11df-b112-00215aee3ebe;Глибокий Потік;Глубокий Поток;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124481601;3;1
50c58e5d-749b-11df-b112-00215aee3ebe;Глибокий Ріг;Глубокий Рог;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425887002;3;1
50c58e5e-749b-11df-b112-00215aee3ebe;Глибокояр;Глубокояр;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125255105;3;1
50c58e5f-749b-11df-b112-00215aee3ebe;Глибоч;Глыбоч;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624286302;3;1
50c58e60-749b-11df-b112-00215aee3ebe;Глибочанське;Глубочанское;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524181202;3;1
50c58e61-749b-11df-b112-00215aee3ebe;Глибочиця;Глубочица;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822082001;1;0
50c58e62-749b-11df-b112-00215aee3ebe;Глибочка;Глыбочка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220485102;2;1
50c58e63-749b-11df-b112-00215aee3ebe;Глибочок;Глубочок;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120882401;3;1
50c58e64-749b-11df-b112-00215aee3ebe;Глибочок;Глубочок;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324588502;3;1
50c58e65-749b-11df-b112-00215aee3ebe;Глибочок;Глубочок;село;6b07f835-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5124383202;3;1
50c58e66-749b-11df-b112-00215aee3ebe;Глибочок;Глубочок;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122980402;3;1
50c58e67-749b-11df-b112-00215aee3ebe;Глибочок;Глубочок;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822081801;3;1
50c58e68-749b-11df-b112-00215aee3ebe;Глибочок;Глубочок;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822587903;3;1
50c58e69-749b-11df-b112-00215aee3ebe;Глибочок;Глубочок;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820683602;3;1
50c58e6a-749b-11df-b112-00215aee3ebe;Глибочок;Глубочок;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124081601;3;1
50c58e6b-749b-11df-b112-00215aee3ebe;Глибочок;Глубочок;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524181201;3;1
50c58e6c-749b-11df-b112-00215aee3ebe;Глибочок;Глубочок;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823383502;3;1
50c58e6d-749b-11df-b112-00215aee3ebe;Глибочок;Глубочок;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620455704;3;1
50c58e6e-749b-11df-b112-00215aee3ebe;Глибочок;Глубочок;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621282702;3;1
50c58e6f-749b-11df-b112-00215aee3ebe;Глиненка;Глиненка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424455101;3;1
50c58e70-749b-11df-b112-00215aee3ebe;Глинець;Глинец;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625882202;3;1
50c58e71-749b-11df-b112-00215aee3ebe;Глиниці;Глиницы;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625855602;3;1
50c58e72-749b-11df-b112-00215aee3ebe;Глиниця;Глиница;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322582601;3;1
50c58e73-749b-11df-b112-00215aee3ebe;Глиниця;Глиница;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825084403;3;1
50c58e74-749b-11df-b112-00215aee3ebe;Глинівці;Глиновцы;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820382801;3;1
50c58e76-749b-11df-b112-00215aee3ebe;Глинки;Глинки;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624086403;3;1
50c58e77-749b-11df-b112-00215aee3ebe;Глинки;Глинки;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624680705;3;1
50c58e78-749b-11df-b112-00215aee3ebe;Глинна;Глинная;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123082001;3;1
50c58e79-749b-11df-b112-00215aee3ebe;Глинне;Глинное;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621280803;3;1
50c58e7a-749b-11df-b112-00215aee3ebe;Глинне;Глинное;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625082601;3;1
50c58e7b-749b-11df-b112-00215aee3ebe;Глинники;Глинники;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823980302;3;1
50c58e7d-749b-11df-b112-00215aee3ebe;Глинськ;Глинск;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924184401;3;1
50c58e7e-749b-11df-b112-00215aee3ebe;Глинськ;Глинск;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622782101;3;1
50c58e7f-749b-11df-b112-00215aee3ebe;Глинськ;Глинск;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521680603;3;1
50c58e80-749b-11df-b112-00215aee3ebe;Глинськ;Глинск;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525281201;3;1
50c58e81-749b-11df-b112-00215aee3ebe;Глинськ;Глинск;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622681601;3;1
50c58e82-749b-11df-b112-00215aee3ebe;Глинське;Глинское;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321383603;3;1
50c58e83-749b-11df-b112-00215aee3ebe;Глинське;Глинское;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322886502;3;1
50c58e84-749b-11df-b112-00215aee3ebe;Глинське;Глинское;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425382002;3;1
50c58e85-749b-11df-b112-00215aee3ebe;Глинське;Глинское;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523086103;3;1
33ec0012-ddd5-11df-9197-00215aee3ebe;Глинське;Глинское;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520484206;3;1
50c58e86-749b-11df-b112-00215aee3ebe;Глинча;Глинча;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122087403;3;1
50c58e87-749b-11df-b112-00215aee3ebe;Глинчуки;Глинчуки;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122484803;3;1
50c58e88-749b-11df-b112-00215aee3ebe;Глинщина;Глинщина;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424180902;3;1
50c58e89-749b-11df-b112-00215aee3ebe;Глиняна Балка;Глиняная Балка;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125780402;3;1
50c58e8a-749b-11df-b112-00215aee3ebe;Глиняне;Глиняное;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924755801;3;1
50c58e8b-749b-11df-b112-00215aee3ebe;Глиняне;Глиняное;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521781201;3;1
50c58e8c-749b-11df-b112-00215aee3ebe;Глинянець;Глинянец;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523010102;3;1
50c58e8d-749b-11df-b112-00215aee3ebe;Глиняни;Глиняны;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621810200;3;1
50c58e8e-749b-11df-b112-00215aee3ebe;Глиняний;Глиняный;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124480403;3;1
50c58e8f-749b-11df-b112-00215aee3ebe;Глинянка;Глинянка;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723383302;3;1
50c58e90-749b-11df-b112-00215aee3ebe;Глинянка;Глинянка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520280102;3;1
50c58e91-749b-11df-b112-00215aee3ebe;Глібів;Глебов;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121680701;3;1
50c58e92-749b-11df-b112-00215aee3ebe;Глібів;Глебов;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823382001;3;1
50c58e93-749b-11df-b112-00215aee3ebe;Глібівка;Глебовка;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221882201;3;1
50c58e94-749b-11df-b112-00215aee3ebe;Глібки;Глебки;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822782401;3;1
50c58e95-749b-11df-b112-00215aee3ebe;Глібовичі;Глебовичи;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623386402;3;1
50c58e97-749b-11df-b112-00215aee3ebe;Глоби;Глобы;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324084606;3;1
50c58e98-749b-11df-b112-00215aee3ebe;Глобине;Глобино;місто;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610100;2;0
50c58e99-749b-11df-b112-00215aee3ebe;Глобівка;Глобовка;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322283003;3;1
50c58e9a-749b-11df-b112-00215aee3ebe;Глоди;Глоды;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321084903;3;1
50c58e9c-749b-11df-b112-00215aee3ebe;Глодоси;Глодосы;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524080601;3;1
50c58e9d-749b-11df-b112-00215aee3ebe;Гломазди;Гломазды;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422080902;3;1
50c58e9f-749b-11df-b112-00215aee3ebe;Глузди;Глузды;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422782502;3;1
17af7b33-ddb9-11df-9197-00215aee3ebe;Глухе;Глухое;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;20ef54f1-6c7a-11ea-80c6-000c29800ae7;1225482002;3;1
50c58ea0-749b-11df-b112-00215aee3ebe;Глухи;Глухи;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725080801;3;1
50c58ea1-749b-11df-b112-00215aee3ebe;Глухів;Глухов;місто;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5910300000;2;0
50c58ea2-749b-11df-b112-00215aee3ebe;Глухів;Глухов;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624880902;3;1
50c58ea3-749b-11df-b112-00215aee3ebe;Глухів Другий;Глухов Второй;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825085503;3;1
50c58ea4-749b-11df-b112-00215aee3ebe;Глухів Перший;Глухов Первый;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825010101;3;1
50c58ea5-749b-11df-b112-00215aee3ebe;Глухівець;Глуховец;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623088404;3;1
50c58ea6-749b-11df-b112-00215aee3ebe;Глухівці;Глуховцы;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521455503;3;1
50c58ea7-749b-11df-b112-00215aee3ebe;Глухова;Глухова;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822855101;3;1
50c58ea8-749b-11df-b112-00215aee3ebe;Глухове;Глухово;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086904;3;1
50c58ea9-749b-11df-b112-00215aee3ebe;Глуховичі;Глуховичи;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623687902;3;1
50c58eaa-749b-11df-b112-00215aee3ebe;Глушин;Глушин;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620385802;3;1
50c58eab-749b-11df-b112-00215aee3ebe;Глушиця;Глушица;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625484802;3;1
50c58eac-749b-11df-b112-00215aee3ebe;Глушицьке;Глушицкое;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625484803;3;1
50c58ead-749b-11df-b112-00215aee3ebe;Глушка;Глушка;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122088302;3;1
50c58eae-749b-11df-b112-00215aee3ebe;Глушки;Глушки;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220481101;2;1
50c58eaf-749b-11df-b112-00215aee3ebe;Глушки;Глушки;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122584802;3;1
50c58eb0-749b-11df-b112-00215aee3ebe;Глушків;Глушков;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621680401;3;1
50c58eb2-749b-11df-b112-00215aee3ebe;Глушківці;Глушковцы;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825882801;3;1
50c58eb3-749b-11df-b112-00215aee3ebe;Глушковецьке;Глушковецкое;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825882802;3;1
50c58eb4-749b-11df-b112-00215aee3ebe;Глущенкове;Глущенково;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321082003;3;1
50c58eb5-749b-11df-b112-00215aee3ebe;Глюгове;Глюгово;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083803;3;1
50c58eb6-749b-11df-b112-00215aee3ebe;Глядин;Глядин;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425584902;3;1
50c58eb7-749b-11df-b112-00215aee3ebe;Глядки;Глядки;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122689502;3;1
50c58eb8-749b-11df-b112-00215aee3ebe;Глядки;Глядки;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820988602;3;1
50c58eb9-749b-11df-b112-00215aee3ebe;Гмирянка;Гмырянка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421782801;3;1
50c58eba-749b-11df-b112-00215aee3ebe;Гнаровське;Гнаровское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321581001;3;1
50c58ebb-749b-11df-b112-00215aee3ebe;Гнатенки;Гнатенки;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755116;3;1
50c58ebc-749b-11df-b112-00215aee3ebe;Гнатівка;Гнатовка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655111;3;1
50c58ebd-749b-11df-b112-00215aee3ebe;Гнатівка;Гнатовка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223284504;3;1
50c58ec0-749b-11df-b112-00215aee3ebe;Гнатівка;Гнатовка;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222482002;3;1
50c58ec1-749b-11df-b112-00215aee3ebe;Гнатівка;Гнатовка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423685402;3;1
50c58ec2-749b-11df-b112-00215aee3ebe;Гнатівка;Гнатовка;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425155401;3;1
50c58ec3-749b-11df-b112-00215aee3ebe;Гнатівка;Гнатовка;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524886102;3;1
50c58ec4-749b-11df-b112-00215aee3ebe;Гнатівка;Гнатовка;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520883812;3;1
50c58ec5-749b-11df-b112-00215aee3ebe;Гнатівка;Гнатовка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521781501;3;1
50c58ec6-749b-11df-b112-00215aee3ebe;Гнатівка;Гнатовка;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623886905;3;1
50c58ec7-749b-11df-b112-00215aee3ebe;Гнатівці;Гнатовцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825082101;3;1
50c58ec8-749b-11df-b112-00215aee3ebe;Гнатки;Гнатки;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824280902;3;1
50c58ec9-749b-11df-b112-00215aee3ebe;Гнатків;Гнатков;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523981601;3;1
50c58eca-749b-11df-b112-00215aee3ebe;Гненне;Гненное;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125482004;3;1
50c58ecb-749b-11df-b112-00215aee3ebe;Гнидава;Гнидава;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122485102;3;1
50c58ecc-749b-11df-b112-00215aee3ebe;Гнилець;Гнилец;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121284002;3;1
50c58ecd-749b-11df-b112-00215aee3ebe;Гнилиці;Гнилицы;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124681301;3;1
50c58ece-749b-11df-b112-00215aee3ebe;Гнилиця;Гнилица;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922385803;3;1
50c58ecf-749b-11df-b112-00215aee3ebe;Гнилиця;Гнилица;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920381701;3;1
50c58ed1-749b-11df-b112-00215aee3ebe;Гнилички;Гнилички;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124685003;3;1
50c58ed2-749b-11df-b112-00215aee3ebe;Гнильче;Гнильче;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124881401;3;1
50c58ed3-749b-11df-b112-00215aee3ebe;Гнильче;Гнильче;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625884804;3;1
50c58ed4-749b-11df-b112-00215aee3ebe;Гнівань;Гнивань;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524510500;3;0
50c58ed5-749b-11df-b112-00215aee3ebe;Гнідин;Гнедин;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220882601;3;0
50c58ed6-749b-11df-b112-00215aee3ebe;Гнідинці;Гнединцы;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421181201;3;1
50c58ed7-749b-11df-b112-00215aee3ebe;Гніздичів;Гнездычев;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621555300;3;1
50c58ed8-749b-11df-b112-00215aee3ebe;Гніздичне;Гнездычное;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122481801;3;1
50c58ed9-749b-11df-b112-00215aee3ebe;Гніздище;Гнездище;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421483201;3;1
50c58edb-749b-11df-b112-00215aee3ebe;Говерла;Говерла;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123685002;3;1
50c58edc-749b-11df-b112-00215aee3ebe;Говори;Говоры;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320284704;3;1
50c58edd-749b-11df-b112-00215aee3ebe;Говори;Говоры;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822782403;3;1
50c58ede-749b-11df-b112-00215aee3ebe;Говори;Говоры;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820681501;3;1
50c58edf-749b-11df-b112-00215aee3ebe;Говорунове;Говоруново;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925680402;3;1
50c58ee1-749b-11df-b112-00215aee3ebe;Говтва;Говтва;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322081601;3;1
50c59086-749b-11df-b112-00215aee3ebe;Говтянчик;Готвянчык;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324085203;3;0
50c58ee3-749b-11df-b112-00215aee3ebe;Гоголеве;Гоголево;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755117;3;1
50c58ee4-749b-11df-b112-00215aee3ebe;Гоголеве;Гоголево;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320255400;3;1
50c58ee5-749b-11df-b112-00215aee3ebe;Гоголі;Гоголи;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820683003;3;1
50c58ee6-749b-11df-b112-00215aee3ebe;Гоголів;Гоголев;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4623981603;3;1
50c58ee7-749b-11df-b112-00215aee3ebe;Гоголів;Гоголев;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221281601;3;0
50c58eea-749b-11df-b112-00215aee3ebe;Годвишня;Годвишня;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620983303;3;1
50c58eeb-749b-11df-b112-00215aee3ebe;Годи-Добровідка;Годы-Доброводка;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623281201;3;1
50c58eec-749b-11df-b112-00215aee3ebe;Годилів;Годылов;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324582002;3;1
50c58eed-749b-11df-b112-00215aee3ebe;Годині;Годыни;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622481001;3;1
50c58eee-749b-11df-b112-00215aee3ebe;Годинівка;Годыновка;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320781101;3;1
50c58eef-749b-11df-b112-00215aee3ebe;Годинь;Годынь;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724285502;3;1
50c58ef0-749b-11df-b112-00215aee3ebe;Годиха;Годыха;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821481801;3;1
50c58ef1-749b-11df-b112-00215aee3ebe;Годів;Годов;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122682501;3;1
50c58ef2-749b-11df-b112-00215aee3ebe;Годовиця;Годовица;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623682001;2;0
50c58ef3-749b-11df-b112-00215aee3ebe;Годовичі;Годовичи;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725582902;3;1
50c58ef4-749b-11df-b112-00215aee3ebe;Годомичі;Годомичи;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723655402;3;1
50c58ef5-749b-11df-b112-00215aee3ebe;Годунівка;Годуновка;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921584803;3;1
50c58ef6-749b-11df-b112-00215aee3ebe;Годунівка;Годуновка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225581201;3;1
50c58ef7-749b-11df-b112-00215aee3ebe;Гожули;Гожулы;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324080701;1;0
62c3d13b-749b-11df-b112-00215aee3ebe;Гойдаї (Леніне);Гойдаї (Леніне);село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823982001;3;1
50c58ef9-749b-11df-b112-00215aee3ebe;Голгоча;Голгоча;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124881801;3;1
50c58efa-749b-11df-b112-00215aee3ebe;Голдашівка;Голдашовка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520481303;3;1
50c58efb-749b-11df-b112-00215aee3ebe;Голдовичі;Голдовичи;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621589103;3;1
50c58efc-749b-11df-b112-00215aee3ebe;Голендра;Голендра;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124887303;3;1
50c58efd-749b-11df-b112-00215aee3ebe;Голендри;Голендры;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521680909;3;1
50c58efe-749b-11df-b112-00215aee3ebe;Голенищеве;Голенищево;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823080401;3;1
50c58eff-749b-11df-b112-00215aee3ebe;Голенищеве;Голенищево;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825281601;2;1
50c58f00-749b-11df-b112-00215aee3ebe;Голенка;Голенка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924186702;3;1
50c58f01-749b-11df-b112-00215aee3ebe;Голешів;Голешов;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621584902;3;1
50c58f02-749b-11df-b112-00215aee3ebe;Голики;Голики;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823980902;3;1
50c58f04-749b-11df-b112-00215aee3ebe;Голикове;Голиково;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520582501;3;1
50c58f05-749b-11df-b112-00215aee3ebe;Голинчинці;Голынчинцы;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525380801;3;1
50c58f06-749b-11df-b112-00215aee3ebe;Голинь;Голынь;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622881601;3;1
50c58f07-749b-11df-b112-00215aee3ebe;Голиця;Голица;село;6b07f8a2-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5121482401;3;1
50c58f0d-749b-11df-b112-00215aee3ebe;Голичівка;Голычевка;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623085202;3;1
50c58f08-749b-11df-b112-00215aee3ebe;Голишів;Голышев;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722880703;3;1
50c58f09-749b-11df-b112-00215aee3ebe;Голишів;Голышев;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624684903;3;1
50c58f0a-749b-11df-b112-00215aee3ebe;Голишівське;Голышовское;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920687404;3;1
50c58f0b-749b-11df-b112-00215aee3ebe;Голігради;Голиграды;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122081702;3;1
50c58f0c-749b-11df-b112-00215aee3ebe;Голінка;Голенка;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420382501;3;1
50c58f0e-749b-11df-b112-00215aee3ebe;Голоби;Голобы;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155300;3;1
50c58f0f-749b-11df-b112-00215aee3ebe;Голобородька;Голобородько;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323810102;3;1
50c58f10-749b-11df-b112-00215aee3ebe;Голобородьківське;Голобородьковское;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321681201;3;1
50c58f11-749b-11df-b112-00215aee3ebe;Голобутів;Голобутов;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625380501;3;1
50c58f30-749b-11df-b112-00215aee3ebe;Голов'ятине;Головятино;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123782001;3;1
50c58f13-749b-11df-b112-00215aee3ebe;Голованівськ;Голованевск;місто;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521455101;2;0
50c58f14-749b-11df-b112-00215aee3ebe;Головатівка;Головатовка;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125180401;3;1
50c58f15-749b-11df-b112-00215aee3ebe;Головач;Головач;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081003;3;1
50c58f16-749b-11df-b112-00215aee3ebe;Головачі;Головачи;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920681804;3;1
50c58f17-749b-11df-b112-00215aee3ebe;Головашівка;Головашевка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924755802;3;1
50c58f18-749b-11df-b112-00215aee3ebe;Головашівка;Головашевка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322686502;3;1
50c58f19-749b-11df-b112-00215aee3ebe;Головенка;Головенка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822082101;3;1
50c58f1a-749b-11df-b112-00215aee3ebe;Головеньки;Головеньки;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420881601;3;1
50c58f1b-749b-11df-b112-00215aee3ebe;Головеньки;Головеньки;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523084005;3;1
50c58f1c-749b-11df-b112-00215aee3ebe;Головецько;Головецко;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625181901;3;1
50c58f1d-749b-11df-b112-00215aee3ebe;Головецько;Головецко;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624581201;3;1
50c58f1e-749b-11df-b112-00215aee3ebe;Голови;Головы;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620882001;3;1
50c58f1f-749b-11df-b112-00215aee3ebe;Головин;Головин;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623480801;3;1
50c58f20-749b-11df-b112-00215aee3ebe;Головине;Головино;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825655500;3;1
50c58f21-749b-11df-b112-00215aee3ebe;Головище;Головище;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724286904;3;1
50c58f22-749b-11df-b112-00215aee3ebe;Головки;Головки;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823482801;3;1
50c58f23-749b-11df-b112-00215aee3ebe;Головківка;Головковка;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125481501;3;1
8cdd5ce9-de27-11df-9197-00215aee3ebe;Головківка;Головковка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520382101;3;1
50c58f24-749b-11df-b112-00215aee3ebe;Головківське;Головковское;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3510390403;3;1
50c58f25-749b-11df-b112-00215aee3ebe;Головкове;Головково;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222983402;3;1
50c58f27-749b-11df-b112-00215aee3ebe;Головлі;Головли;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823982101;3;1
50c58f28-749b-11df-b112-00215aee3ebe;Головне;Головно;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723355300;3;1
50c58f29-749b-11df-b112-00215aee3ebe;Головниця;Головница;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623081801;3;1
50c58f2a-749b-11df-b112-00215aee3ebe;Головнівка;Головновка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324284703;3;1
50c58f2b-749b-11df-b112-00215aee3ebe;Головське;Головское;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625582601;3;1
50c58f2c-749b-11df-b112-00215aee3ebe;Головурів;Головуров;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220882901;3;1
50c58f2d-749b-11df-b112-00215aee3ebe;Головчинці;Головчинцы;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122082101;3;1
50c58f2e-749b-11df-b112-00215aee3ebe;Головчинці;Головчинцы;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823085802;3;1
50c58f2f-749b-11df-b112-00215aee3ebe;Головчиці;Головчицы;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623889403;3;1
50c58f31-749b-11df-b112-00215aee3ebe;Гологірки;Гологорки;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621881502;3;1
50c58f32-749b-11df-b112-00215aee3ebe;Гологори;Гологоры;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621882601;3;1
50c58f33-749b-11df-b112-00215aee3ebe;Голодьки;Голодьки;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224610102;2;1
50c58f34-749b-11df-b112-00215aee3ebe;Голодьки;Голодьки;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524881501;3;1
50c58f35-749b-11df-b112-00215aee3ebe;Голозубинці;Голозубынци;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821881801;3;1
50c58f36-749b-11df-b112-00215aee3ebe;Голокам'янка;Голокаменка;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785203;3;1
50c58f37-749b-11df-b112-00215aee3ebe;Голосків;Голосков;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623281401;3;1
50c58f38-749b-11df-b112-00215aee3ebe;Голосків;Голосков;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823081201;3;1
50c58f39-749b-11df-b112-00215aee3ebe;Голосків;Голосков;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822481001;3;1
50c58f3a-749b-11df-b112-00215aee3ebe;Голоскове;Голосково;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823983103;3;1
50c58f3b-749b-11df-b112-00215aee3ebe;Голосковичі;Голосковичи;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620385803;3;1
50c58f3c-749b-11df-b112-00215aee3ebe;Голотівщина;Голотовщина;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325180503;3;1
50c58f3d-749b-11df-b112-00215aee3ebe;Голотки;Голотки;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124683902;3;1
50c58f3e-749b-11df-b112-00215aee3ebe;Голохвасти;Голохвасты;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825880402;3;1
50c58f3f-749b-11df-b112-00215aee3ebe;Голошина;Голошина;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323586502;3;1
50c58f40-749b-11df-b112-00215aee3ebe;Голошина;Голошина;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620882201;3;1
50c58f41-749b-11df-b112-00215aee3ebe;Голошинці;Голошинцы;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124685005;3;1
50c58f6c-749b-11df-b112-00215aee3ebe;Голуб'ятин;Голубятин;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824781501;3;1
50c58f43-749b-11df-b112-00215aee3ebe;Голубенка;Голубенка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523686003;3;1
50c58f44-749b-11df-b112-00215aee3ebe;Голубече;Голубече;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521980801;3;1
50c58f45-749b-11df-b112-00215aee3ebe;Голуби;Голуби;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324280903;3;1
50c58f46-749b-11df-b112-00215aee3ebe;Голуби;Голуби;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920980803;3;1
50c58f47-749b-11df-b112-00215aee3ebe;Голуби;Голуби;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923285603;3;1
50c58f48-749b-11df-b112-00215aee3ebe;Голуби;Голуби;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621689505;3;1
50c58f49-749b-11df-b112-00215aee3ebe;Голубин;Голубин;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821483002;3;1
50c58f4a-749b-11df-b112-00215aee3ebe;Голубине;Голубиное;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124081601;3;0
50c58f4b-749b-11df-b112-00215aee3ebe;Голубинівка;Голубиновка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225083507;3;1
50c58f4d-749b-11df-b112-00215aee3ebe;Голубиця;Голубица;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620381601;3;1
50c58f4f-749b-11df-b112-00215aee3ebe;Голубичі;Голубичи;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424481501;3;1
50c58f50-749b-11df-b112-00215aee3ebe;Голубів;Голубое;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420386004;3;1
50c58f51-749b-11df-b112-00215aee3ebe;Голубівка;Голубовка;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924480901;3;1
50c58f52-749b-11df-b112-00215aee3ebe;Голубівка;Голубовка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983001;3;1
50c58f53-749b-11df-b112-00215aee3ebe;Голубівка;Голубовка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223282001;3;1
50c58f54-749b-11df-b112-00215aee3ebe;Голубівка;Голубовка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222982803;3;1
50c58f56-749b-11df-b112-00215aee3ebe;Голубівка;Голубовка;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321081503;3;1
50c58f5a-749b-11df-b112-00215aee3ebe;Голубівка;Голубовка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510112;3;1
50c58f5b-749b-11df-b112-00215aee3ebe;Голубівка;Голубовка;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825282601;3;1
50c58f5c-749b-11df-b112-00215aee3ebe;Голубівка;Голубовка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424484203;3;1
50c58f5d-749b-11df-b112-00215aee3ebe;Голубівка;Голубовка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424183703;3;1
50c58f5e-749b-11df-b112-00215aee3ebe;Голубівка;Голубовка;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521680903;3;1
50c58f5f-749b-11df-b112-00215aee3ebe;Голубівка;Голубовка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520282406;3;1
50c58f60-749b-11df-b112-00215aee3ebe;Голубівка;Голубовка;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521085206;3;1
50c58f61-749b-11df-b112-00215aee3ebe;Голубівське;Голубовское;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955108;3;1
50c58f63-749b-11df-b112-00215aee3ebe;Голубівщина;Голубовщина;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422487902;3;1
50c58f64-749b-11df-b112-00215aee3ebe;Голубієвичі;Голубиевичи;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755121;3;1
50c58f65-749b-11df-b112-00215aee3ebe;Голубієвичі;Голубиевичи;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522881501;3;1
50c58f66-749b-11df-b112-00215aee3ebe;Голубкове;Голубково;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923888103;3;1
50c58f67-749b-11df-b112-00215aee3ebe;Голубкове;Голубково;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323687202;3;1
50c58f68-749b-11df-b112-00215aee3ebe;Голубне;Голубное;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622284402;3;1
50c58f69-749b-11df-b112-00215aee3ebe;Голубне;Голубное;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620483201;3;1
50c58f6a-749b-11df-b112-00215aee3ebe;Голубці;Голубцы;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923583803;3;1
50c58f6b-749b-11df-b112-00215aee3ebe;Голубча;Голубча;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823683503;3;1
50c58f6d-749b-11df-b112-00215aee3ebe;Голядин;Голядин;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725780903;3;1
50c58f6e-749b-11df-b112-00215aee3ebe;Голяки;Голяки;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122085403;3;1
50c58f6f-749b-11df-b112-00215aee3ebe;Голятин;Голятин;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122481201;3;1
50c58f70-749b-11df-b112-00215aee3ebe;Гольма;Гольма;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290008;3;1
50c58f71-749b-11df-b112-00215aee3ebe;Гольманівка;Гольмановка;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324283103;3;1
50c58f73-749b-11df-b112-00215aee3ebe;Гомельське;Гомельское;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221887002;3;1
50c58f74-749b-11df-b112-00215aee3ebe;Гонората;Гонората;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122981701;3;1
50c58f75-749b-11df-b112-00215aee3ebe;Гоноратівка;Гоноратовка;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624481002;3;1
50c58f76-749b-11df-b112-00215aee3ebe;Гоноратка;Гоноратка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523181702;3;1
50c58f77-749b-11df-b112-00215aee3ebe;Гоноратка;Гоноратка;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625881202;3;1
50c58f78-749b-11df-b112-00215aee3ebe;Гонорівка;Гоноровка;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525686603;3;1
50c58f79-749b-11df-b112-00215aee3ebe;Гонорівка;Гоноровка;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523283008;3;1
50c58f7a-749b-11df-b112-00215aee3ebe;Гонорівка;Гоноровка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820986503;3;1
50c58f7b-749b-11df-b112-00215aee3ebe;Гонтарі;Гонтари;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083709;3;1
50c58f7d-749b-11df-b112-00215aee3ebe;Гонтів Яр;Гонтов Яр;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321282001;3;1
50c58f7e-749b-11df-b112-00215aee3ebe;Гонтівка;Гонтовка;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524982701;3;1
50c58f7f-749b-11df-b112-00215aee3ebe;Гонцош;Гонцош;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125380602;3;1
50c58f84-749b-11df-b112-00215aee3ebe;Гончари;Гончары;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623681202;3;1
50c58f80-749b-11df-b112-00215aee3ebe;Гончариха;Гончариха;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122281501;3;1
50c58f81-749b-11df-b112-00215aee3ebe;Гончариха;Гончариха;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824485002;3;1
50c58f82-749b-11df-b112-00215aee3ebe;Гончариха;Гончариха;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623881302;3;1
50c58f83-749b-11df-b112-00215aee3ebe;Гончарі;Гончары;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325786003;3;1
50c58f85-749b-11df-b112-00215aee3ebe;Гончарів;Гончаров;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625655401;3;1
50c58f86-749b-11df-b112-00215aee3ebe;Гончарів;Гончаров;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623384503;3;1
50c58f87-749b-11df-b112-00215aee3ebe;Гончарівка;Гончаровка;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124282001;3;1
50c58f88-749b-11df-b112-00215aee3ebe;Гончарівка;Гончаровка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120886403;3;1
50c58f89-749b-11df-b112-00215aee3ebe;Гончарівка;Гончаровка;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625686902;3;1
50c58f8a-749b-11df-b112-00215aee3ebe;Гончарівка;Гончаровка;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621882701;3;1
50c58f8c-749b-11df-b112-00215aee3ebe;Гончарівка;Гончаровка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522480402;3;1
50c58f8d-749b-11df-b112-00215aee3ebe;Гончарівське;Гончаровское;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425555200;3;1
50c58f8e-749b-11df-b112-00215aee3ebe;Гончарка;Гончарка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055103;3;1
50c58f8f-749b-11df-b112-00215aee3ebe;Гончарки;Гончарки;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125088002;3;1
50c58f90-749b-11df-b112-00215aee3ebe;Гончарне;Гончарное;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520382505;3;1
50c58f92-749b-11df-b112-00215aee3ebe;Гончарове;Гончарово;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282504;3;1
50c58f94-749b-11df-b112-00215aee3ebe;Гончий Брід;Гончий Брод;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722182002;3;1
50c58f95-749b-11df-b112-00215aee3ebe;Гонятичі;Гонятичи;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623082401;3;1
50c58f96-749b-11df-b112-00215aee3ebe;Гоптівка;Гоптовка;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322082503;3;1
50c58f97-749b-11df-b112-00215aee3ebe;Гопчиця;Гопчица;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523481601;3;1
50c58fe8-749b-11df-b112-00215aee3ebe;Гор'ївка;Горьевка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122986002;3;1
50c58f98-749b-11df-b112-00215aee3ebe;Гора;Гора;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324087702;3;1
50c58f99-749b-11df-b112-00215aee3ebe;Гора;Гора;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220883201;2;0
5cb618e5-749b-11df-b112-00215aee3ebe;Гора (Колгоспна Гора);Гора (Колгоспная Гора);село;d00d3b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5310290311;3;1
50c58f9a-749b-11df-b112-00215aee3ebe;Гораєць;Гораец;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625881902;3;1
50c58f9b-749b-11df-b112-00215aee3ebe;Гораївка;Гораевка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822455801;3;1
50c58f9c-749b-11df-b112-00215aee3ebe;Горай;Горай;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522887802;3;1
50c58f9d-749b-11df-b112-00215aee3ebe;Гораймівка;Гораймовка;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723681901;3;1
50c58f9e-749b-11df-b112-00215aee3ebe;Горб;Горб;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122482103;3;1
50c58f9f-749b-11df-b112-00215aee3ebe;Горбаків;Горбаков;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621282001;3;1
50c58fa0-749b-11df-b112-00215aee3ebe;Горбалі;Горбали;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620385309;3;1
50c58fa1-749b-11df-b112-00215aee3ebe;Горбані;Горбани;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322055503;3;1
50c58fa2-749b-11df-b112-00215aee3ebe;Горбані;Горбани;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325183003;3;1
50c58fa3-749b-11df-b112-00215aee3ebe;Горбані;Горбани;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223381401;3;1
bf7b6b75-86ca-11e0-8cb2-003048d2b473;Горбані;Горбани;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;2;1
c6cc1c04-db7e-11df-9197-00215aee3ebe;Горбанівка;Горбановка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320882003;3;1
50c58fa4-749b-11df-b112-00215aee3ebe;Горбанівка;Горбановка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324087703;1;0
50c58fa5-749b-11df-b112-00215aee3ebe;Горбанівка;Горбановка;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620386202;3;1
50c58fa6-749b-11df-b112-00215aee3ebe;Горбанівка;Горбановка;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520682206;3;1
50c58fa7-749b-11df-b112-00215aee3ebe;Горбасів;Горбасов;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823081601;3;1
50c58fa9-749b-11df-b112-00215aee3ebe;Горбатівка;Горбатовка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321084202;3;1
50c58fab-749b-11df-b112-00215aee3ebe;Горбачі;Горбачи;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620686202;3;1
50c58fac-749b-11df-b112-00215aee3ebe;Горбачі;Горбачи;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623682602;3;1
50c58fad-749b-11df-b112-00215aee3ebe;Горбачі;Горбачи;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386202;3;1
50c58fae-749b-11df-b112-00215aee3ebe;Горбачі;Горбачи;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422085003;3;1
50c58faf-749b-11df-b112-00215aee3ebe;Горбачі;Горбачи;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420683201;3;1
5f70bead-1a5a-11e1-8b3d-003048d2b473;Горбачі;Лемеши;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;;3;1
50c58fb0-749b-11df-b112-00215aee3ebe;Горбачівка;Горбачовка;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221982703;3;1
50c58fb1-749b-11df-b112-00215aee3ebe;Горби;Горбы;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320681401;3;1
50c58fb2-749b-11df-b112-00215aee3ebe;Горби;Горбы;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123082407;3;1
50c58fb3-749b-11df-b112-00215aee3ebe;Горби;Горбы;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521980203;3;1
50c58fb4-749b-11df-b112-00215aee3ebe;Горби;Горбы;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320884603;3;1
50c58fb5-749b-11df-b112-00215aee3ebe;Горбів;Горбов;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621282103;3;1
50c58fb6-749b-11df-b112-00215aee3ebe;Горбівці;Горбовцы;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321085402;3;1
50c58fb7-749b-11df-b112-00215aee3ebe;Горбівці;Горбовцы;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522481601;3;1
73e94beb-ddc1-11df-9197-00215aee3ebe;Горбки;Горбки;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121281903;3;1
50c58fb8-749b-11df-b112-00215aee3ebe;Горбків;Горбков;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624885503;3;1
50c58fb9-749b-11df-b112-00215aee3ebe;Горбова;Горбова;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320781301;3;1
50c58fba-749b-11df-b112-00215aee3ebe;Горбове;Горбово;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821755101;3;1
50c58fbb-749b-11df-b112-00215aee3ebe;Горбове;Горбово;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423682801;3;1
50c58fbc-749b-11df-b112-00215aee3ebe;Горбове;Горбово;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422782501;3;1
50c58fbd-749b-11df-b112-00215aee3ebe;Горбовичі;Горбовичи;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222488202;2;1
50c58fbe-749b-11df-b112-00215aee3ebe;Горбок;Горбок;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121983202;3;1
50c58fbf-749b-11df-b112-00215aee3ebe;Горбулів;Горбулев;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825682401;3;1
50c58fc0-749b-11df-b112-00215aee3ebe;Гордашівка;Гордашовка;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124082001;3;1
50c58fc1-749b-11df-b112-00215aee3ebe;Гординівка;Гордыновка;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521980704;3;1
50c58fc2-749b-11df-b112-00215aee3ebe;Гординя;Гордыня;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624282501;3;1
50c58fc3-749b-11df-b112-00215aee3ebe;Гордівці;Гордовцы;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325081001;3;1
50c58fc4-749b-11df-b112-00215aee3ebe;Гордіївка;Гордиевка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821482001;3;1
50c58fc5-749b-11df-b112-00215aee3ebe;Гордіївка;Гордиевка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522287503;3;1
50c58fc6-749b-11df-b112-00215aee3ebe;Гордіївка;Гордиевка;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524181501;3;1
50c58fc7-749b-11df-b112-00215aee3ebe;Гордіївка;Гордиевка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522884304;3;1
50c58fc8-749b-11df-b112-00215aee3ebe;Гореничі;Гореничи;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222482001;3;0
50c58fc9-749b-11df-b112-00215aee3ebe;Горенка;Горенка;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222482401;2;0
50c58fca-749b-11df-b112-00215aee3ebe;Горзвин;Горзвин;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722880603;3;1
50c58fcb-749b-11df-b112-00215aee3ebe;Гори;Горы;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787205;3;1
50c58fcc-749b-11df-b112-00215aee3ebe;Горигляди;Горигляды;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124282401;3;1
50c58fcd-749b-11df-b112-00215aee3ebe;Гориничі;Горыничи;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622284802;3;1
50c58fce-749b-11df-b112-00215aee3ebe;Горинка;Горынка;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123482001;3;1
50c58fcf-749b-11df-b112-00215aee3ebe;Горинь;Горынь;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823480801;3;1
50c58fd0-749b-11df-b112-00215aee3ebe;Гориньград Другий;Горинград Второй;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624689803;3;1
50c58fd1-749b-11df-b112-00215aee3ebe;Гориньград Перший;Горыньград Первый;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624689805;3;1
50c58fd2-749b-11df-b112-00215aee3ebe;Гориславичі;Гориславичи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622481603;3;1
50c58fd3-749b-11df-b112-00215aee3ebe;Гориславці;Гориславцы;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322483802;3;1
50c58fd4-749b-11df-b112-00215aee3ebe;Гори-Стрийовецькі;Горы-Стрыевецкие;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122486402;3;1
50c58fd5-749b-11df-b112-00215aee3ebe;Гориця;Горица;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423086302;3;1
50c58fd6-749b-11df-b112-00215aee3ebe;Гориця;Горица;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823982201;3;1
50c58fd7-749b-11df-b112-00215aee3ebe;Горичів;Горичев;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720582002;3;1
50c58fd8-749b-11df-b112-00215aee3ebe;Горишківка;Горышковка;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523955104;3;1
50c58fd9-749b-11df-b112-00215aee3ebe;Горишківське;Орешковское;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523955101;3;1
50c58fda-749b-11df-b112-00215aee3ebe;Горіле;Горелое;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921581901;3;1
50c58fdb-749b-11df-b112-00215aee3ebe;Горінчово;Горинчово;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125382201;3;0
50c58fdc-749b-11df-b112-00215aee3ebe;Горіхівка;Гориховка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520880803;3;1
50c58fdd-749b-11df-b112-00215aee3ebe;Горіхове;Горихово;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157602;3;1
50c58fde-749b-11df-b112-00215aee3ebe;Горіхове;Горихово;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822588403;3;1
50c58fdf-749b-11df-b112-00215aee3ebe;Горішнє;Горишнее;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755118;3;1
50c58fe0-749b-11df-b112-00215aee3ebe;Горішнє;Горишнее;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720881401;3;1
50c58fe1-749b-11df-b112-00215aee3ebe;Горішнє;Горишнее;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623082801;3;1
50c58fe2-749b-11df-b112-00215aee3ebe;Горішнє;Горишнее;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625381603;3;1
50c58fe3-749b-11df-b112-00215aee3ebe;Горішнє Залуччя;Горишнее Залучье;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625281501;3;1
5cb619be-749b-11df-b112-00215aee3ebe;Горішні Плавні;Горишние Плавни;місто;d00d3b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5310200000;2;0
50c58fe4-749b-11df-b112-00215aee3ebe;Горішні Шерівці;Горишние Шеровцы;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321583301;3;1
50c58fe5-749b-11df-b112-00215aee3ebe;Горішній;Горишний;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623685103;3;1
50c58fe6-749b-11df-b112-00215aee3ebe;Горішня Вигнанка;Горишняя Выгнанка;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125582201;3;1
50c58fe7-749b-11df-b112-00215aee3ebe;Горішня Слобідка;Горишняя Слободка;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124282801;3;1
50c58fea-749b-11df-b112-00215aee3ebe;Горлицьке;Горлицкое;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323685902;3;1
50c58fef-749b-11df-b112-00215aee3ebe;Горностаївка;Горностаевка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424482001;3;1
50c58ff0-749b-11df-b112-00215aee3ebe;Горностайпіль;Горностайполь;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222080701;3;1
50c58ff1-749b-11df-b112-00215aee3ebe;Горобівка;Горобовка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920682601;3;1
50c58ff2-749b-11df-b112-00215aee3ebe;Горобії;Горобии;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321381602;3;1
50c58ff3-749b-11df-b112-00215aee3ebe;Горобії;Горобии;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322880702;3;1
50c58ff4-749b-11df-b112-00215aee3ebe;Горобіївка;Горобиевка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222780602;3;1
50c58ff5-749b-11df-b112-00215aee3ebe;Горобіївка;Горобиевка;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220886704;3;1
50c58ff6-749b-11df-b112-00215aee3ebe;Горобіївка;Горобиевка;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222982902;3;1
50c58ff7-749b-11df-b112-00215aee3ebe;Горобіївка;Горобиевка;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224081501;2;1
50c58ff8-749b-11df-b112-00215aee3ebe;Горобіївка;Горобиевка;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425182301;3;1
50c58ff9-749b-11df-b112-00215aee3ebe;Горобіївка;Горобиевка;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122081501;3;1
50c58ffb-749b-11df-b112-00215aee3ebe;Горобці;Горобцы;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323483202;3;1
50c58ffc-749b-11df-b112-00215aee3ebe;Горове;Горовое;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924186402;3;1
50c58ffd-749b-11df-b112-00215aee3ebe;Город;Город;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623683001;3;1
50c58ffe-749b-11df-b112-00215aee3ebe;Городенка;Городенка;місто;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621610100;3;0
50c58fff-749b-11df-b112-00215aee3ebe;Городенка;Городенка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822587303;3;1
50c59000-749b-11df-b112-00215aee3ebe;Городець;Городец;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824282101;3;1
50c59001-749b-11df-b112-00215aee3ebe;Городець;Городец;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620883001;3;1
50c59002-749b-11df-b112-00215aee3ebe;Городецьке;Городецкое;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124381501;3;1
50c59003-749b-11df-b112-00215aee3ebe;Городещина;Городещина;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223581502;3;1
50c59004-749b-11df-b112-00215aee3ebe;Городжів;Городжев;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784806;3;1
50c59005-749b-11df-b112-00215aee3ebe;Городилець;Городилец;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725585602;3;1
50c59006-749b-11df-b112-00215aee3ebe;Городилів;Городилов;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621882802;3;1
50c59007-749b-11df-b112-00215aee3ebe;Городині;Городыни;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724581401;3;1
50c59008-749b-11df-b112-00215aee3ebe;Городиславичі;Городыславичи;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623684403;3;1
50c59009-749b-11df-b112-00215aee3ebe;Городиська;Городыска;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624481003;3;1
50c5900a-749b-11df-b112-00215aee3ebe;Городиська;Городыска;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821885903;3;1
50c5900b-749b-11df-b112-00215aee3ebe;Городисько;Городиско;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625183903;3;1
50c5900c-749b-11df-b112-00215aee3ebe;Городище;Городище;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923580903;3;1
50c5900d-749b-11df-b112-00215aee3ebe;Городище;Городище;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722182404;3;1
50c5900e-749b-11df-b112-00215aee3ebe;Городище;Городище;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721886202;3;1
73e94c14-ddc1-11df-9197-00215aee3ebe;Городище;Городище;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;;3;1
50c59010-749b-11df-b112-00215aee3ebe;Городище;Городище;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122682801;3;1
50c59011-749b-11df-b112-00215aee3ebe;Городище;Городище;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123082401;3;1
50c59012-749b-11df-b112-00215aee3ebe;Городище;Городище;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624881302;3;1
50c59013-749b-11df-b112-00215aee3ebe;Городище;Городище;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624282801;3;1
50c59014-749b-11df-b112-00215aee3ebe;Городище;Городище;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621582703;3;1
50c59018-749b-11df-b112-00215aee3ebe;Городище;Городище;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220882202;2;1
50c59019-749b-11df-b112-00215aee3ebe;Городище;Городище;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220485103;3;1
50c5901a-749b-11df-b112-00215aee3ebe;Городище;Городище;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824084403;3;1
50c5901b-749b-11df-b112-00215aee3ebe;Городище;Городище;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825682003;3;1
50c5901c-749b-11df-b112-00215aee3ebe;Городище;Городище;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822081302;3;1
50c5901d-749b-11df-b112-00215aee3ebe;Городище;Городище;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825888402;3;1
50c5901e-749b-11df-b112-00215aee3ebe;Городище;Городище;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820387002;3;1
50c5901f-749b-11df-b112-00215aee3ebe;Городище;Городище;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423082501;3;1
50c59020-749b-11df-b112-00215aee3ebe;Городище;Городище;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423682004;3;1
50c59021-749b-11df-b112-00215aee3ebe;Городище;Городище;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422283301;3;1
50c59022-749b-11df-b112-00215aee3ebe;Городище;Городище;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420383001;3;1
50c59023-749b-11df-b112-00215aee3ebe;Городище;Городище;місто;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120310100;2;0
50c59024-749b-11df-b112-00215aee3ebe;Городище;Городище;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522486403;3;1
50c59025-749b-11df-b112-00215aee3ebe;Городище;Городище;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523280901;3;1
50c59026-749b-11df-b112-00215aee3ebe;Городище;Городище;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825582301;3;1
50c59027-749b-11df-b112-00215aee3ebe;Городище;Городище;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821582401;3;1
50c59028-749b-11df-b112-00215aee3ebe;Городище;Городище;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621888703;3;1
50c59029-749b-11df-b112-00215aee3ebe;Городище;Городище;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620483601;3;1
50c5902a-749b-11df-b112-00215aee3ebe;Городище;Городище;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623086502;3;1
50c5902b-749b-11df-b112-00215aee3ebe;Городище;Городище;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624683001;3;1
50c5902c-749b-11df-b112-00215aee3ebe;Городище;Городище;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325182603;3;1
50c5900f-749b-11df-b112-00215aee3ebe;Городище (Баївської);Городище;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722880302;3;1
50c5902d-749b-11df-b112-00215aee3ebe;Городище (Городищенської);Городище-2;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881701;3;0
50c5902e-749b-11df-b112-00215aee3ebe;Городище-Косівське;Городище-Косовское;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221683202;3;1
50c5902f-749b-11df-b112-00215aee3ebe;Городищенське;Городищенское;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621587202;3;1
50c59030-749b-11df-b112-00215aee3ebe;Городище-Пустоварівське;Городище-Пустоваровское;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221681601;2;1
50c59031-749b-11df-b112-00215aee3ebe;Городківка;Городковка;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621284502;3;1
50c59032-749b-11df-b112-00215aee3ebe;Городківка;Городковка;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820383201;3;1
50c59033-749b-11df-b112-00215aee3ebe;Городківка;Городковка;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521981201;3;1
50c59034-749b-11df-b112-00215aee3ebe;Городнє;Городнее;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723310105;3;1
50c59035-749b-11df-b112-00215aee3ebe;Городнє;Городнее;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323583202;3;1
50c59036-749b-11df-b112-00215aee3ebe;Городнє;Городнее;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323988003;3;1
50c59037-749b-11df-b112-00215aee3ebe;Городнє;Городнее;село;6b07f8a2-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5121482701;3;1
50c5903b-749b-11df-b112-00215aee3ebe;Городниця;Городница;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124681601;3;1
50c5903c-749b-11df-b112-00215aee3ebe;Городниця;Городница;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121681001;3;1
50c5903d-749b-11df-b112-00215aee3ebe;Городниця;Городница;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621680501;3;1
50c5903e-749b-11df-b112-00215aee3ebe;Городниця;Городница;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824055600;3;1
50c5903f-749b-11df-b112-00215aee3ebe;Городниця;Городница;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124381701;3;1
50c59040-749b-11df-b112-00215aee3ebe;Городниця;Городница;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523084202;3;1
50c59041-749b-11df-b112-00215aee3ebe;Городня;Городня;місто;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421410100;2;0
50c59042-749b-11df-b112-00215aee3ebe;Городня;Городня;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421783201;3;1
50c59043-749b-11df-b112-00215aee3ebe;Городнявка;Городнявка;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825582601;3;1
50c59044-749b-11df-b112-00215aee3ebe;Городовичі;Городовичи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625184702;3;1
50c59045-749b-11df-b112-00215aee3ebe;Городок;Городок;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721486204;3;1
50c59046-749b-11df-b112-00215aee3ebe;Городок;Городок;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723685503;3;1
50c59047-749b-11df-b112-00215aee3ebe;Городок;Городок;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881808;3;1
50c59048-749b-11df-b112-00215aee3ebe;Городок;Городок;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122082201;3;1
50c59049-749b-11df-b112-00215aee3ebe;Городок;Городок;місто;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;7d1766f6-b6ff-11eb-80d4-000c29800ae7;4620910100;2;0
50c5904a-749b-11df-b112-00215aee3ebe;Городок;Городок;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825282801;3;1
50c5904b-749b-11df-b112-00215aee3ebe;Городок;Городок;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521281603;3;1
50c5904c-749b-11df-b112-00215aee3ebe;Городок;Городок;місто;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821210100;2;0
50c5904d-749b-11df-b112-00215aee3ebe;Городок;Городок;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620887402;3;1
50c5904e-749b-11df-b112-00215aee3ebe;Городок;Городок;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624683301;2;1
0256ff4a-5afd-11e2-b7ac-003048d2b473;Городок;Городок;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825055500;3;1
50c5904f-749b-11df-b112-00215aee3ebe;Городоцьке;Городоцкое;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825283502;3;1
50c59050-749b-11df-b112-00215aee3ebe;Городське;Городское;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822581701;3;1
50c59051-749b-11df-b112-00215aee3ebe;Городуватка;Городоватка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225882203;3;1
50c59052-749b-11df-b112-00215aee3ebe;Городчин;Городчин;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825086202;3;1
50c59055-749b-11df-b112-00215aee3ebe;Горожанівка;Горожановка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325785703;3;1
50c59056-749b-11df-b112-00215aee3ebe;Горожанка;Горожанка;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124283201;3;1
50c59054-749b-11df-b112-00215aee3ebe;Горожене;Горожено;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610105;3;1
50c59057-749b-11df-b112-00215aee3ebe;Горонглаб;Горонглаб;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120480402;3;1
50c59058-749b-11df-b112-00215aee3ebe;Горонда;Горонда;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122781801;2;1
50c59059-749b-11df-b112-00215aee3ebe;Горопаї;Горопаи;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823180701;3;1
50c5905a-749b-11df-b112-00215aee3ebe;Горохів;Горохов;місто;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720810100;3;0
50c5905b-749b-11df-b112-00215aee3ebe;Горохівка;Гороховка;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823355310;3;1
50c5905c-749b-11df-b112-00215aee3ebe;Горохівське;Гороховское;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825781401;3;1
50c5905d-749b-11df-b112-00215aee3ebe;Горохове;Гороховое;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;Шост;3;1
50c5905e-749b-11df-b112-00215aee3ebe;Горохове;Гороховое;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222282401;3;1
50c5905f-749b-11df-b112-00215aee3ebe;Горохове;Гороховое;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422286502;3;1
50c59060-749b-11df-b112-00215aee3ebe;Гороховище;Гороховище;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723384907;3;1
50c59061-749b-11df-b112-00215aee3ebe;Горохолин Ліс;Горохолин Лес;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620481202;3;1
50c59062-749b-11df-b112-00215aee3ebe;Горохолина;Горохолина;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620481201;3;1
50c59063-749b-11df-b112-00215aee3ebe;Горохуватка;Гороховатка;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321081501;3;1
50c59064-749b-11df-b112-00215aee3ebe;Горохуватка;Гороховатка;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222282001;3;1
50c59065-749b-11df-b112-00215aee3ebe;Горошине;Горошино;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324581501;3;1
50c59066-749b-11df-b112-00215aee3ebe;Горошівці;Горошовцы;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321583601;3;1
50c59067-749b-11df-b112-00215aee3ebe;Горошків;Горошков;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224610103;3;1
50c59068-749b-11df-b112-00215aee3ebe;Горошківка;Горошковка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421483202;3;1
50c59069-749b-11df-b112-00215aee3ebe;Горошова;Горошова;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120882501;3;1
50c5906a-749b-11df-b112-00215aee3ebe;Горпин;Горпин;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622182002;3;1
50c5906b-749b-11df-b112-00215aee3ebe;Горщик;Горщик;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822381201;3;1
50c5906c-749b-11df-b112-00215aee3ebe;Горяйстівка;Горяйстовка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920386302;3;1
50c5906e-749b-11df-b112-00215aee3ebe;Горяни;Горяны;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787606;3;1
50c5906f-749b-11df-b112-00215aee3ebe;Горяни;Горяны;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520282809;3;1
50c591fa-749b-11df-b112-00215aee3ebe;Горянівка;Горяновка;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721881603;3;1
50c59070-749b-11df-b112-00215aee3ebe;Горянівське;Горяновское;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221455401;3;0
50c59071-749b-11df-b112-00215aee3ebe;Горянка;Горянка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822782002;3;1
50c59072-749b-11df-b112-00215aee3ebe;Горянщина;Горянщина;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321082603;3;1
50c59073-749b-11df-b112-00215aee3ebe;Горячівка;Горячовка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123185202;3;1
50c59074-749b-11df-b112-00215aee3ebe;Горькове;Горьково;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923587202;3;1
50c59075-749b-11df-b112-00215aee3ebe;Горького;Горького;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221482001;3;1
50c59076-749b-11df-b112-00215aee3ebe;Горького;Горького;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320887503;3;1
50c59079-749b-11df-b112-00215aee3ebe;Гостинне;Гостинное;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920655305;3;1
50c5907a-749b-11df-b112-00215aee3ebe;Гостинне;Гостинное;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523010103;3;1
50c5907b-749b-11df-b112-00215aee3ebe;Гостинне;Гостинное;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522281503;3;1
50c5907c-749b-11df-b112-00215aee3ebe;Гостинцеве;Гостинцово;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622481201;2;1
50c5907d-749b-11df-b112-00215aee3ebe;Гостів;Гостев;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625681701;3;1
50c5907e-749b-11df-b112-00215aee3ebe;Гостомель;Гостомель;місто;903cca8d-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3210945900;2;0
50c5907f-749b-11df-b112-00215aee3ebe;Гостра Могила;Острая Могила;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224284401;3;1
50c59081-749b-11df-b112-00215aee3ebe;Гострий Камінь;Острый Камень;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224855601;3;1
50c59082-749b-11df-b112-00215aee3ebe;Гострий Шпиль;Острый Шпиль;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923555603;3;1
50c59083-749b-11df-b112-00215aee3ebe;Гостробури;Остробуры;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922986202;3;1
50c59084-749b-11df-b112-00215aee3ebe;Гостролуччя;Остролучье;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220285301;3;1
50c59088-749b-11df-b112-00215aee3ebe;Гошів;Гошев;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622081101;3;1
50c59089-749b-11df-b112-00215aee3ebe;Гошів;Гошев;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824282201;3;1
50c5908a-749b-11df-b112-00215aee3ebe;Гоща;Гоща;місто;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621255100;3;0
50c5908b-749b-11df-b112-00215aee3ebe;Грабарівка;Грабаровка;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522685802;3;1
50c5908c-749b-11df-b112-00215aee3ebe;Грабарівка;Грабаровка;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523281101;3;1
50c5908d-749b-11df-b112-00215aee3ebe;Грабарівка;Грабаровка;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323881801;3;1
50c5908e-749b-11df-b112-00215aee3ebe;Граби;Грабы;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822383603;3;1
50c5908f-749b-11df-b112-00215aee3ebe;Грабина;Грабина;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620685303;3;1
50c59091-749b-11df-b112-00215aee3ebe;Грабине;Грабино;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620986602;3;1
50c59092-749b-11df-b112-00215aee3ebe;Грабинівка;Грабиновка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081905;3;1
50c59093-749b-11df-b112-00215aee3ebe;Грабич;Грабич;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623255403;3;1
73e94c0f-ddc1-11df-9197-00215aee3ebe;Грабичанка;Грабычанка;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625685003;3;1
50c59094-749b-11df-b112-00215aee3ebe;Грабів;Грабов;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624881101;3;1
50c59095-749b-11df-b112-00215aee3ebe;Грабів;Грабов;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424482401;3;1
50c59096-749b-11df-b112-00215aee3ebe;Грабів;Грабов;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421781602;3;1
50c59097-749b-11df-b112-00215aee3ebe;Грабів;Грабов;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624684904;3;1
50c59098-749b-11df-b112-00215aee3ebe;Грабів;Грабов;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323685102;3;1
50c59099-749b-11df-b112-00215aee3ebe;Грабівка;Грабовка;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622882802;3;1
50c5909a-749b-11df-b112-00215aee3ebe;Грабівка;Грабовка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822087902;3;1
50c5909b-749b-11df-b112-00215aee3ebe;Грабівка;Грабовка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424485602;3;1
50c5909c-749b-11df-b112-00215aee3ebe;Грабівка;Грабовка;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422783001;3;1
17af7b48-ddb9-11df-9197-00215aee3ebe;Грабівка;Грабовка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821184003;3;1
50c5909d-749b-11df-b112-00215aee3ebe;Грабівниця;Грабовница;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625183904;3;1
50c5909e-749b-11df-b112-00215aee3ebe;Грабівці;Грабовцы;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120886102;3;1
87162671-749b-11df-b112-00215aee3ebe;Грабівці (Червоне);Грабовцы (Червоное);село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520282006;3;1
50c5909f-749b-11df-b112-00215aee3ebe;Грабівщина;Грабовщина;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323085002;3;1
50c590a0-749b-11df-b112-00215aee3ebe;Грабки;Грабки;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222382502;3;1
50c590a1-749b-11df-b112-00215aee3ebe;Грабківці;Грабковцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122686003;3;1
50c590a2-749b-11df-b112-00215aee3ebe;Грабник;Грабник;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623681802;3;1
50c590a3-749b-11df-b112-00215aee3ebe;Грабник;Грабник;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623386403;3;1
50c590a4-749b-11df-b112-00215aee3ebe;Грабняк;Грабняк;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821180403;3;1
50c590a5-749b-11df-b112-00215aee3ebe;Грабова;Грабова;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620685804;3;1
50c590a6-749b-11df-b112-00215aee3ebe;Грабове;Грабово;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725780901;3;1
50c590a8-749b-11df-b112-00215aee3ebe;Грабове;Грабово;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122581601;3;1
7ad95637-749b-11df-b112-00215aee3ebe;Грабове;Грабово;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725084802;3;1
50c590ab-749b-11df-b112-00215aee3ebe;Грабовець;Грабовец;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125282301;3;1
50c590ac-749b-11df-b112-00215aee3ebe;Грабовець;Грабовец;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620481601;3;1
50c590ad-749b-11df-b112-00215aee3ebe;Грабовець;Грабовец;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622183303;3;1
50c590ae-749b-11df-b112-00215aee3ebe;Грабовець;Грабовец;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625380601;3;1
50c590af-749b-11df-b112-00215aee3ebe;Грабовець;Грабовец;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624555701;3;1
50c590b0-749b-11df-b112-00215aee3ebe;Грабовець;Грабовец;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523082801;3;1
50c590b1-749b-11df-b112-00215aee3ebe;Грабовець;Грабовец;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524984507;3;1
50c590b2-749b-11df-b112-00215aee3ebe;Грабово;Грабово;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621886410;3;1
50c590aa-749b-11df-b112-00215aee3ebe;Грабово;Грабово;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122786603;3;1
50c590b3-749b-11df-b112-00215aee3ebe;Грабовське;Грабовское;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922381201;3;1
50c590b5-749b-11df-b112-00215aee3ebe;Грабунь;Грабунь;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625080802;3;1
50c590b6-749b-11df-b112-00215aee3ebe;Грабці;Грабцы;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922981203;3;1
50c590b7-749b-11df-b112-00215aee3ebe;Грабщина;Грабщина;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923287205;3;1
50c590b8-749b-11df-b112-00215aee3ebe;Грабщина;Грабщина;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425382802;3;1
50c590b9-749b-11df-b112-00215aee3ebe;Града;Града;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123481702;3;1
50c590ba-749b-11df-b112-00215aee3ebe;Граддя;Градье;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723682203;3;1
50c590bb-749b-11df-b112-00215aee3ebe;Градениці;Граденицы;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121082001;3;1
50c590bc-749b-11df-b112-00215aee3ebe;Градизьк;Градижск;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320655400;3;0
50c590bd-749b-11df-b112-00215aee3ebe;Градиськ;Градиск;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723685102;3;1
50c590be-749b-11df-b112-00215aee3ebe;Градівка;Градовка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821755108;3;1
50c590bf-749b-11df-b112-00215aee3ebe;Градівка;Градовка;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620982201;3;1
50c590c0-749b-11df-b112-00215aee3ebe;Градське;Градское;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925081603;3;1
50c590c1-749b-11df-b112-00215aee3ebe;Гражданівка;Граждановка;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822383603;3;1
50c590c2-749b-11df-b112-00215aee3ebe;Гражданка;Гражданка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823084203;3;1
cdc44f52-dde8-11df-9197-00215aee3ebe;Граки;Гракы;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;;3;1
50c590c4-749b-11df-b112-00215aee3ebe;Гракове;Граково;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325483001;3;1
50c590c5-749b-11df-b112-00215aee3ebe;Грамине;Грамино;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983002;3;1
50c5919d-749b-11df-b112-00215aee3ebe;Грамівка;Громовка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224588003;3;1
50c590c6-749b-11df-b112-00215aee3ebe;Грамотне;Грамотное;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620886502;3;1
50c590c7-749b-11df-b112-00215aee3ebe;Гранатів;Гранатов;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722486303;3;1
50c590c8-749b-11df-b112-00215aee3ebe;Граничне;Граничное;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323683504;3;1
50c590c9-749b-11df-b112-00215aee3ebe;Грані;Грани;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621888203;3;1
50c590ca-749b-11df-b112-00215aee3ebe;Гранів;Гранов;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322055602;3;1
50c590cb-749b-11df-b112-00215aee3ebe;Гранів;Гранов;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520880903;3;1
50c590cc-749b-11df-b112-00215aee3ebe;Гранівка;Грановка;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625883902;3;1
50c590cd-749b-11df-b112-00215aee3ebe;Гранітне;Гранитное;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124283401;3;1
50c590ce-749b-11df-b112-00215aee3ebe;Гранітне;Гранитное;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222082702;3;1
50c590cf-749b-11df-b112-00215aee3ebe;Гранітне;Гранитное;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220384402;3;1
50c590d2-749b-11df-b112-00215aee3ebe;Гранітне;Гранитное;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823455200;3;1
50c590d3-749b-11df-b112-00215aee3ebe;Гранітне;Гранитное;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523082802;3;1
50c590d4-749b-11df-b112-00215aee3ebe;Гранітне;Гранитное;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625480910;3;1
50c590d5-749b-11df-b112-00215aee3ebe;Гранки-Кути;Гранки-Куты;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623080402;3;1
50c590d6-749b-11df-b112-00215aee3ebe;Гранове;Граново;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221086603;3;1
50c590d7-749b-11df-b112-00215aee3ebe;Графське;Графское;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924789803;3;1
50c590d9-749b-11df-b112-00215aee3ebe;Гребельки;Гребельки;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221288402;3;1
50c590da-749b-11df-b112-00215aee3ebe;Гребеники;Гребеники;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824882303;3;1
50c590db-749b-11df-b112-00215aee3ebe;Гребеники;Гребеники;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655110;3;1
50c590dc-749b-11df-b112-00215aee3ebe;Гребениківка;Гребенниковка;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925081601;3;1
50c590dd-749b-11df-b112-00215aee3ebe;Гребені;Гребени;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222282801;3;1
50c590de-749b-11df-b112-00215aee3ebe;Гребенів;Гребенив;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624581401;3;1
50c590df-749b-11df-b112-00215aee3ebe;Гребенюки;Гребенюки;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125480303;3;1
50c590e0-749b-11df-b112-00215aee3ebe;Гребеняки;Гребеняки;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325782803;3;1
50c590e1-749b-11df-b112-00215aee3ebe;Гребінка;Гребенка;місто;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320810100;3;0
50c590e2-749b-11df-b112-00215aee3ebe;Гребінки;Гребенки;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221455500;3;1
50c590e3-749b-11df-b112-00215aee3ebe;Гребінники;Гребенники;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321284506;3;1
50c590e4-749b-11df-b112-00215aee3ebe;Гребінці;Гребенцы;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622786403;3;1
50c590e5-749b-11df-b112-00215aee3ebe;Греблина;Греблина;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323581002;3;1
50c590e6-749b-11df-b112-00215aee3ebe;Греблі;Гребли;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324555107;3;1
50c590e7-749b-11df-b112-00215aee3ebe;Гребля;Гребля;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121982401;3;1
50c590e8-749b-11df-b112-00215aee3ebe;Гребля;Гребля;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223380803;3;1
50c590e9-749b-11df-b112-00215aee3ebe;Гребля;Гребля;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423086702;3;1
50c590ea-749b-11df-b112-00215aee3ebe;Гребля;Гребля;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124680502;3;1
50c590eb-749b-11df-b112-00215aee3ebe;Гредьки;Гредьки;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722182405;3;1
50c590ec-749b-11df-b112-00215aee3ebe;Грезля;Грезля;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755122;3;1
50c590ed-749b-11df-b112-00215aee3ebe;Грейгове;Грейгово;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823380701;3;1
50c590ee-749b-11df-b112-00215aee3ebe;Греки;Греки;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923282604;3;1
50c590ef-749b-11df-b112-00215aee3ebe;Грекова;Грекова;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;;3;1
50c590f0-749b-11df-b112-00215aee3ebe;Грекове;Греково;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121883002;3;1
50c590f1-749b-11df-b112-00215aee3ebe;Грекове Друге;Греково Второе;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122987404;3;1
50c590f2-749b-11df-b112-00215aee3ebe;Грекове Перше;Греково Первое;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122987403;3;1
50c590f5-749b-11df-b112-00215aee3ebe;Грекопавлівка;Грекопавловка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323486402;3;1
50c590f6-749b-11df-b112-00215aee3ebe;Грелівка;Греловка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525387705;3;1
50c590f7-749b-11df-b112-00215aee3ebe;Грем'яч;Гремяч;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423683201;3;1
50c590f8-749b-11df-b112-00215aee3ebe;Грем'яче;Гремячее;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721881301;3;1
50c590f9-749b-11df-b112-00215aee3ebe;Грем'яче;Гремячее;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821482703;3;1
50c590fa-749b-11df-b112-00215aee3ebe;Грем'яче;Гремячее;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624282801;3;1
50c590fb-749b-11df-b112-00215aee3ebe;Грем'ячка;Гремячка;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925680403;3;1
50c590fc-749b-11df-b112-00215aee3ebe;Грем'ячка;Гремячка;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424781302;3;1
50c590fd-749b-11df-b112-00215aee3ebe;Гресі;Греси;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322684503;3;1
50c590ff-749b-11df-b112-00215aee3ebe;Гречана;Гречаная;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820987402;3;1
50c59100-749b-11df-b112-00215aee3ebe;Гречана;Гречаная;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824485502;3;1
50c59101-749b-11df-b112-00215aee3ebe;Гречана Гребля;Гречаная Гребля;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221982007;3;1
50c59102-749b-11df-b112-00215aee3ebe;Гречане;Гречаное;село;bad3ac3c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1223781403;3;1
50c59103-749b-11df-b112-00215aee3ebe;Гречаники;Гречаники;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223387203;3;1
7ad9519b-749b-11df-b112-00215aee3ebe;Гречані Поди (Рози Люксембург);Гречаные Поды (Розы Люксембург);село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885701;3;1
50c59104-749b-11df-b112-00215aee3ebe;Гречанівка;Гречановка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825780703;3;1
50c59105-749b-11df-b112-00215aee3ebe;Гречанівка;Гречановка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225589003;3;1
50c59106-749b-11df-b112-00215aee3ebe;Гречанівка;Гречановка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120685002;3;1
50c59107-749b-11df-b112-00215aee3ebe;Гречанівка;Гречановка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320482401;3;1
50c59108-749b-11df-b112-00215aee3ebe;Гречинці;Гречинцы;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823082001;3;1
50c5910a-749b-11df-b112-00215aee3ebe;Гречища;Гречища;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723184802;3;1
50c5910b-749b-11df-b112-00215aee3ebe;Гречкине;Гречкино;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922682201;3;1
50c5910c-749b-11df-b112-00215aee3ebe;Грибаньове;Грибанево;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922687204;3;1
50c5910d-749b-11df-b112-00215aee3ebe;Грибенинка;Грибенинка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824286402;3;1
50c5910e-749b-11df-b112-00215aee3ebe;Грибівка;Грибовка;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123781302;3;1
50c5910f-749b-11df-b112-00215aee3ebe;Грибівці;Грибовцы;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122780403;3;1
50c59110-749b-11df-b112-00215aee3ebe;Грибова;Грибова;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123883302;3;1
50c59111-749b-11df-b112-00215aee3ebe;Грибова Рудня;Грибова Рудня;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424486802;3;1
50c59113-749b-11df-b112-00215aee3ebe;Грибовиця;Грибовица;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721180901;3;1
e0517ed7-db89-11df-9197-00215aee3ebe;Грибоносове;Грибоносово;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822783509;3;1
50c59114-749b-11df-b112-00215aee3ebe;Грив'ятки;Гривятки;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722187405;3;1
50c59115-749b-11df-b112-00215aee3ebe;Григорів;Григорев;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124283601;3;1
50c59116-749b-11df-b112-00215aee3ebe;Григорів;Григорев;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624481501;3;1
50c59117-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321881702;3;1
50c59118-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323086203;3;1
50c59119-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224588004;3;1
50c5911a-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225055406;3;1
50c5911b-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755119;3;1
50c5911c-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225855104;3;1
50c5911d-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821480801;3;1
50c5911e-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823080402;3;1
50c5911f-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821783402;3;1
50c59121-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323281003;3;1
50c59122-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320481801;3;1
50c59123-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320680205;3;1
50c59124-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125480305;3;1
50c59125-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122755404;3;1
50c59126-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322182401;3;1
50c5912d-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220288703;3;1
50c5912e-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225581802;3;1
50c5912f-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220886705;3;1
50c59130-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223182001;3;1
50c59131-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224610104;3;1
50c59132-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420383501;3;1
50c59134-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423884503;3;1
50c59135-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122081901;3;1
50c59136-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125185204;3;1
50c59137-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523482802;3;1
50c59138-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522681402;3;1
50c59139-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524080701;3;1
50c5913a-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520584502;3;1
50c5913b-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525281801;3;1
50c5913c-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520355401;3;1
50c5913d-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822183303;3;1
50c5913e-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824282301;3;1
50c5913f-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320881301;3;1
50c59140-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323283406;3;1
50c59141-749b-11df-b112-00215aee3ebe;Григорівка;Григоровка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324886503;3;1
429293c8-8643-11e7-80c9-1c98ec135261;Григорівка;Григорьевка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;;3;1
8cdd5ce4-de27-11df-9197-00215aee3ebe;Григорівка;Григоровка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522580302;3;1
68cf2556-749b-11df-b112-00215aee3ebe;Григорівка (Мануїлівка);Григорьевка (Мануиловка);село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322080902;3;1
50c59142-749b-11df-b112-00215aee3ebe;Григорівська Слобода;Григоревская Слобода;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624481503;3;1
50c59143-749b-11df-b112-00215aee3ebe;Григорівська Слобода;Григоревская Слобода;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224287003;3;1
50c59144-749b-11df-b112-00215aee3ebe;Григорівське;Григоровское;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822081701;3;1
50c59145-749b-11df-b112-00215aee3ebe;Григорівське;Григоровское;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955401;3;1
50c59146-749b-11df-b112-00215aee3ebe;Григорівщина;Григоровщина;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755103;3;1
50c59148-749b-11df-b112-00215aee3ebe;Григоро-Бригадирівка;Григоро-Бригадировка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5321881301;3;1
50c59149-749b-11df-b112-00215aee3ebe;Григоровичі;Григоровичи;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881703;3;1
50c5914a-749b-11df-b112-00215aee3ebe;Григоросове;Григоросово;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323980503;3;1
50c5914b-749b-11df-b112-00215aee3ebe;Грижинці;Грижинцы;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524510501;3;1
50c5914f-749b-11df-b112-00215aee3ebe;Грим'ячка;Гремячка;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820682001;3;1
50c5914c-749b-11df-b112-00215aee3ebe;Гримайлів;Гримайлов;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121655400;3;1
50c5914d-749b-11df-b112-00215aee3ebe;Грималівка;Грималовка;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620383202;3;1
50c5914e-749b-11df-b112-00215aee3ebe;Гримуче;Гремучее;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055101;3;1
50c59150-749b-11df-b112-00215aee3ebe;Гринева;Гринева;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820655101;3;1
50c59151-749b-11df-b112-00215aee3ebe;Гриненки;Гриненки;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523082901;3;1
50c59152-749b-11df-b112-00215aee3ebe;Гринів;Гринев;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623683103;3;1
50c59153-749b-11df-b112-00215aee3ebe;Гринів Яр;Гринев Яр;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323586203;3;1
50c59154-749b-11df-b112-00215aee3ebe;Гринівка;Гриновка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324080203;3;1
50c59155-749b-11df-b112-00215aee3ebe;Гринівка;Гриневка;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923580501;3;1
50c59156-749b-11df-b112-00215aee3ebe;Гринівка;Гриневка;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620488603;3;1
50c59157-749b-11df-b112-00215aee3ebe;Гринівці;Гриневцы;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625610104;3;1
50c59158-749b-11df-b112-00215aee3ebe;Гринівці;Гриневцы;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823184002;3;1
50c59159-749b-11df-b112-00215aee3ebe;Гринцеве;Гринцево;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983201;3;1
50c5915a-749b-11df-b112-00215aee3ebe;Гринців Ріг;Гринцов Рог;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321280503;3;1
50c5915b-749b-11df-b112-00215aee3ebe;Гринчук;Грынчук;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822481301;3;1
50c5915c-749b-11df-b112-00215aee3ebe;Гринчуки;Гринчуки;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784603;3;1
50c5915d-749b-11df-b112-00215aee3ebe;Гринява;Гринява;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620882501;3;1
50c5915e-749b-11df-b112-00215aee3ebe;Гринячка;Гринячка;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325088003;3;1
50c5915f-749b-11df-b112-00215aee3ebe;Гриньки;Гриньки;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320681701;3;1
50c59160-749b-11df-b112-00215aee3ebe;Гриньки;Гриньки;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123883301;3;1
50c59161-749b-11df-b112-00215aee3ebe;Гриньки;Гриньки;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820683002;3;1
50c59163-749b-11df-b112-00215aee3ebe;Гриньки;Гриньки;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;;3;1
50c59162-749b-11df-b112-00215aee3ebe;Гриньків;Гриньков;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624884102;3;1
50c59164-749b-11df-b112-00215aee3ebe;Гриньківці;Гриньковцы;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125885402;3;1
50c59165-749b-11df-b112-00215aee3ebe;Грипаки;Грипаки;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5310390002;3;1
50c59166-749b-11df-b112-00215aee3ebe;Грицаї;Грицаи;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324580402;3;1
50c59168-749b-11df-b112-00215aee3ebe;Грицаївка;Грицаевка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321884203;3;1
e0517ecb-db89-11df-9197-00215aee3ebe;Грицеволя;Грицеволя;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623980603;3;1
50c5916a-749b-11df-b112-00215aee3ebe;Гриценки;Гриценки;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822782501;3;1
50c5916b-749b-11df-b112-00215aee3ebe;Гриценкове;Гриценковое;село;9b486f64-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786904;3;1
50c5916c-749b-11df-b112-00215aee3ebe;Грицики;Грицики;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822787902;3;1
50c5916d-749b-11df-b112-00215aee3ebe;Грицини;Грицины;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922987306;3;1
50c5916e-749b-11df-b112-00215aee3ebe;Гриців;Грицев;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825555300;3;1
50c5916f-749b-11df-b112-00215aee3ebe;Грицівка;Грицовка;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425383002;3;1
50c59170-749b-11df-b112-00215aee3ebe;Грицівці;Грицевцы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122485603;3;1
50c59171-749b-11df-b112-00215aee3ebe;Гриціївка;Грициевка;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425182501;3;1
50c59172-749b-11df-b112-00215aee3ebe;Грицьки;Грицки;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621885602;3;1
50c59173-749b-11df-b112-00215aee3ebe;Грицьків;Грицков;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821281302;3;1
50c59174-749b-11df-b112-00215aee3ebe;Гришаї;Гришаи;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220786608;3;1
50c5917a-749b-11df-b112-00215aee3ebe;Гришівка;Гришевка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324882002;3;1
50c5917b-749b-11df-b112-00215aee3ebe;Гришівка;Гришевка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420889602;3;1
50c5917c-749b-11df-b112-00215aee3ebe;Гришівці;Гришевцы;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524582201;3;1
50c5917d-749b-11df-b112-00215aee3ebe;Гришки;Гришки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321383803;3;1
50c5917e-749b-11df-b112-00215aee3ebe;Гришки;Гришки;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324282203;3;1
50c5917f-749b-11df-b112-00215aee3ebe;Гришки;Гришки;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923281902;3;1
50c59181-749b-11df-b112-00215aee3ebe;Гришки;Гришки;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821582501;3;1
50c59182-749b-11df-b112-00215aee3ebe;Гришківка;Гришковка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321785005;3;1
50c59183-749b-11df-b112-00215aee3ebe;Гришківка;Гришковка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825084202;3;1
50c59184-749b-11df-b112-00215aee3ebe;Гришківка;Гришковка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7110494501;3;1
50c59185-749b-11df-b112-00215aee3ebe;Гришківці;Гришковцы;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820855300;3;1
50c59186-749b-11df-b112-00215aee3ebe;Гришкове;Гришково;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323280604;3;1
50c59187-749b-11df-b112-00215aee3ebe;Грищинці;Грищинцы;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122082001;3;1
50c59188-749b-11df-b112-00215aee3ebe;Грігорівщина;Григоривщина;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421182403;3;1
50c59189-749b-11df-b112-00215aee3ebe;Грізне;Грозное;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582506;3;1
50c5918a-749b-11df-b112-00215aee3ebe;Грізне;Грозное;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522885805;3;1
50c5918b-749b-11df-b112-00215aee3ebe;Грімне;Громное;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620982301;3;1
50c5918c-749b-11df-b112-00215aee3ebe;Грінченкове;Гринченково;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920382001;3;1
50c5918d-749b-11df-b112-00215aee3ebe;Гробище;Гробище;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323581502;3;1
50c5918e-749b-11df-b112-00215aee3ebe;Гродзеве;Гродзево;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124382001;3;1
50c59190-749b-11df-b112-00215aee3ebe;Гроза;Гроза;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225081903;3;1
50c59191-749b-11df-b112-00215aee3ebe;Гроза;Гроза;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325785802;3;1
50c59192-749b-11df-b112-00215aee3ebe;Грозине;Грозино;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822385202;3;1
50c59193-749b-11df-b112-00215aee3ebe;Грозинці;Грозинцы;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325081201;3;1
50c59194-749b-11df-b112-00215aee3ebe;Грозів;Грозов;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624282802;3;1
50c59197-749b-11df-b112-00215aee3ebe;Грозове;Грозовое;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520385802;3;1
50c59198-749b-11df-b112-00215aee3ebe;Грозьово;Грозево;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625182101;3;1
50c59199-749b-11df-b112-00215aee3ebe;Громада;Громада;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823183301;3;1
50c5919a-749b-11df-b112-00215aee3ebe;Громадське;Громадское;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522481801;3;1
50c5919b-749b-11df-b112-00215aee3ebe;Громадське;Громадское;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522855101;3;1
50c5919c-749b-11df-b112-00215aee3ebe;Громи;Громы;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124382201;3;1
50c591a1-749b-11df-b112-00215aee3ebe;Громівка;Громовка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824282603;3;1
50c591a6-749b-11df-b112-00215aee3ebe;Громуха;Громуха;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524080303;3;1
50c591a7-749b-11df-b112-00215aee3ebe;Грубна;Грубна;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7324084001;3;1
50c591a8-749b-11df-b112-00215aee3ebe;Грубське;Грубское;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510113;3;1
50c591a9-749b-11df-b112-00215aee3ebe;Груд;Груд;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824080803;3;1
50c591aa-749b-11df-b112-00215aee3ebe;Груди;Груды;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422282502;3;1
50c591ab-749b-11df-b112-00215aee3ebe;Грудки;Грудки;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721482801;3;1
50c591ac-749b-11df-b112-00215aee3ebe;Грузевиця;Грузевица;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825082201;2;1
50c591ad-749b-11df-b112-00215aee3ebe;Грузинівка;Грузиновка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055711;3;1
50c591ae-749b-11df-b112-00215aee3ebe;Грузливець;Грузливец;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825484602;3;1
50c591af-749b-11df-b112-00215aee3ebe;Грузянка;Грузянка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521484602;3;1
50c591b0-749b-11df-b112-00215aee3ebe;Грузятин;Грузятин;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723655403;3;1
50c591b1-749b-11df-b112-00215aee3ebe;Грузька Григорівка;Грузская Григоровка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221887702;3;1
50c591b2-749b-11df-b112-00215aee3ebe;Грузьке;Грузское;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922682601;3;1
50c591b3-749b-11df-b112-00215aee3ebe;Грузьке;Грузское;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922081901;3;1
50c591b4-749b-11df-b112-00215aee3ebe;Грузьке;Грузское;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925080403;3;1
50c591b5-749b-11df-b112-00215aee3ebe;Грузьке;Грузское;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221882201;3;1
50c591b8-749b-11df-b112-00215aee3ebe;Грузьке;Грузское;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222782101;3;1
50c591b9-749b-11df-b112-00215aee3ebe;Грузьке;Грузское;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520881003;3;1
50c591ba-749b-11df-b112-00215aee3ebe;Грузьке;Грузское;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521480801;3;1
50c591bb-749b-11df-b112-00215aee3ebe;Грузьке;Грузское;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522582801;3;1
50c591be-749b-11df-b112-00215aee3ebe;Груники;Груники;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124487603;3;1
50c591bf-749b-11df-b112-00215aee3ebe;Грунівка;Груновка;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922385804;3;1
50c591c0-749b-11df-b112-00215aee3ebe;Грунь;Грунь;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922984604;3;1
50c591c1-749b-11df-b112-00215aee3ebe;Грунь;Грунь;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920382401;3;1
50c591c2-749b-11df-b112-00215aee3ebe;Грунька;Грунька;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920382003;3;1
50c591c3-749b-11df-b112-00215aee3ebe;Грусятичі;Грусятичи;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621582101;3;1
50c591c4-749b-11df-b112-00215aee3ebe;Грушатичі;Грушатичи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625182301;3;1
50c591c5-749b-11df-b112-00215aee3ebe;Грушвиця Друга;Грушвица Вторая;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624683701;3;1
50c591c6-749b-11df-b112-00215aee3ebe;Грушвиця Перша;Грушвица Первая;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624683702;3;1
50c591c7-749b-11df-b112-00215aee3ebe;Грушеве;Грушевое;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922989102;3;1
50c591c8-749b-11df-b112-00215aee3ebe;Грушеве;Грушевое;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455110;3;1
50c591cb-749b-11df-b112-00215aee3ebe;Грушине;Грушино;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324884206;3;1
50c591cc-749b-11df-b112-00215aee3ebe;Грушине;Грушино;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324582501;3;1
50c591cd-749b-11df-b112-00215aee3ebe;Грушів;Грушев;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721183804;3;1
50c591ce-749b-11df-b112-00215aee3ebe;Грушів;Грушев;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623282802;3;1
50c591cf-749b-11df-b112-00215aee3ebe;Грушів;Грушев;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625883903;3;1
50c591d0-749b-11df-b112-00215aee3ebe;Грушів;Грушев;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621282401;3;1
50c591d1-749b-11df-b112-00215aee3ebe;Грушів;Грушев;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222981501;3;1
50c591d2-749b-11df-b112-00215aee3ebe;Грушівка;Грушевка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222088605;3;1
50c591d3-749b-11df-b112-00215aee3ebe;Грушівка;Грушевка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225081202;3;1
50c591d4-749b-11df-b112-00215aee3ebe;Грушівка;Грушевка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825480801;3;1
50c591d5-749b-11df-b112-00215aee3ebe;Грушівка;Грушевка;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321080902;3;1
50c591d7-749b-11df-b112-00215aee3ebe;Грушівка;Грушевка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321586803;3;1
50c591db-749b-11df-b112-00215aee3ebe;Грушівка;Грушевка;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420389003;3;1
50c591dc-749b-11df-b112-00215aee3ebe;Грушівка;Грушевка;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625455701;3;1
50c591dd-749b-11df-b112-00215aee3ebe;Грушівка;Грушевка;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620484001;3;1
50c591e9-749b-11df-b112-00215aee3ebe;Грушівка;Грушевка;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722180402;3;1
894093b3-ab2f-11e6-a7f1-001ec93df11f;Грушівка (Ленінське);Грушевка (Ленинское);село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220384401;3;1
50c591de-749b-11df-b112-00215aee3ebe;Грушівська Гута;Грушевская Гута;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620484004;3;1
50c591e0-749b-11df-b112-00215aee3ebe;Грушівці;Грушевцы;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322083201;3;1
50c591e1-749b-11df-b112-00215aee3ebe;Грушка;Грушка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655111;3;1
50c591e2-749b-11df-b112-00215aee3ebe;Грушка;Грушка;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625682501;3;1
50c591e3-749b-11df-b112-00215aee3ebe;Грушка;Грушка;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622183503;3;1
50c591e4-749b-11df-b112-00215aee3ebe;Грушка;Грушка;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522682601;3;1
50c591e5-749b-11df-b112-00215aee3ebe;Грушка;Грушка;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525581301;3;1
50c591e6-749b-11df-b112-00215aee3ebe;Грушка;Грушка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822481501;3;1
50c591e7-749b-11df-b112-00215aee3ebe;Грушки;Грушки;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282505;3;1
50c591e8-749b-11df-b112-00215aee3ebe;Грушки;Грушки;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821180401;3;1
50c591ea-749b-11df-b112-00215aee3ebe;Грушківка;Грушковка;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122284503;3;1
50c591eb-749b-11df-b112-00215aee3ebe;Грушківка;Грушковка;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121881501;3;1
50c591ec-749b-11df-b112-00215aee3ebe;Грушківка;Грушковка;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320884202;3;1
50c591ed-749b-11df-b112-00215aee3ebe;Грушківці;Грушковцы;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521680306;3;1
50c591ee-749b-11df-b112-00215aee3ebe;Грушківці;Грушковцы;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823082401;3;1
50c591c9-749b-11df-b112-00215aee3ebe;Грушово;Грушево;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124482001;3;0
50c591f0-749b-11df-b112-00215aee3ebe;Грушуватка;Грушеватка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224581201;3;1
50c591f1-749b-11df-b112-00215aee3ebe;Грушувато-Криничне;Грушевато-Криничное;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224886403;3;1
50c591f2-749b-11df-b112-00215aee3ebe;Грушуваха;Грушеваха;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320482001;3;1
50c591f3-749b-11df-b112-00215aee3ebe;Гряда;Гряда;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622782501;3;1
50c591f4-749b-11df-b112-00215aee3ebe;Гряда;Гряда;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622184602;3;1
50c591f5-749b-11df-b112-00215aee3ebe;Гряди;Гряды;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721181201;3;1
50c591f6-749b-11df-b112-00215aee3ebe;Грядки;Грядки;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621687403;3;1
50c591f7-749b-11df-b112-00215aee3ebe;Грякове;Гряково;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325481201;3;1
50c591f8-749b-11df-b112-00215aee3ebe;Грякувате;Гряковатое;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224880505;3;1
50c591fb-749b-11df-b112-00215aee3ebe;Грянчиха;Грянчиха;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320285302;3;1
50c591fc-749b-11df-b112-00215aee3ebe;Губарі;Губари;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424485203;3;1
50c591fd-749b-11df-b112-00215aee3ebe;Губарів;Губарев;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823381502;3;1
50c591fe-749b-11df-b112-00215aee3ebe;Губарівка;Губаревка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323483702;3;1
50c591ff-749b-11df-b112-00215aee3ebe;Губарівка;Губаревка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320881501;3;1
50c59200-749b-11df-b112-00215aee3ebe;Губарівщина;Губаревщина;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922686802;3;1
33ec000f-ddd5-11df-9197-00215aee3ebe;Губачівка;Губачевка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520284809;3;1
50c59201-749b-11df-b112-00215aee3ebe;Губельці;Губельцы;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823981602;3;1
50c59202-749b-11df-b112-00215aee3ebe;Губенкове;Губенково;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821181802;3;1
50c59203-749b-11df-b112-00215aee3ebe;Губенське;Губенское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321587303;3;1
50c59204-749b-11df-b112-00215aee3ebe;Губин;Губин;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722481202;3;1
50c59205-749b-11df-b112-00215aee3ebe;Губин;Губин;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
50c59206-749b-11df-b112-00215aee3ebe;Губин;Губин;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222080703;3;1
50c59207-749b-11df-b112-00215aee3ebe;Губин;Губин;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824285002;3;1
50c59208-749b-11df-b112-00215aee3ebe;Губин Перший;Губин Первый;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720881501;3;1
50c59209-749b-11df-b112-00215aee3ebe;Губиниха;Губиниха;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223255600;3;1
50c5920a-749b-11df-b112-00215aee3ebe;Губичі;Губичи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625184902;3;1
50c5920b-749b-11df-b112-00215aee3ebe;Губичі;Губичи;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424482501;3;1
50c5920c-749b-11df-b112-00215aee3ebe;Губівка;Губовка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522881601;3;1
50c5920d-749b-11df-b112-00215aee3ebe;Губків;Губков;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620484401;3;1
50c5920e-749b-11df-b112-00215aee3ebe;Губник;Губник;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520881506;3;1
50c5920f-749b-11df-b112-00215aee3ebe;Губник;Губник;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0510690001;3;1
50c59210-749b-11df-b112-00215aee3ebe;Губське;Губское;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187003;3;1
50c59211-749b-11df-b112-00215aee3ebe;Губське;Губское;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322887004;3;1
50c59212-749b-11df-b112-00215aee3ebe;Губча;Губча;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824289101;3;1
50c59213-749b-11df-b112-00215aee3ebe;Гудевичеве;Гудевичево;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121881403;3;1
50c59214-749b-11df-b112-00215aee3ebe;Гудзівка;Гудзовка;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121282001;3;1
50c59215-749b-11df-b112-00215aee3ebe;Гудими;Гудимы;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924181102;3;1
50c59216-749b-11df-b112-00215aee3ebe;Гудимівка;Гудымовка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983703;3;1
50c59217-749b-11df-b112-00215aee3ebe;Гудівщина;Гудовщина;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925382502;3;1
50c59218-749b-11df-b112-00215aee3ebe;Гудове;Гудово;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921589302;3;1
50c59219-749b-11df-b112-00215aee3ebe;Гудя;Гудя;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121285002;3;1
50c5921a-749b-11df-b112-00215aee3ebe;Гужвинське;Гужвинское;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321780502;3;1
50c5921b-749b-11df-b112-00215aee3ebe;Гужівка;Гужовка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421783601;3;1
50c5921c-749b-11df-b112-00215aee3ebe;Гузівка;Гузовка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321283502;3;1
50c5921d-749b-11df-b112-00215aee3ebe;Гузіїв;Гузиев;село;8067acf2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2610290601;3;1
50c5921e-749b-11df-b112-00215aee3ebe;Гуйва;Гуйва;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822055300;1;1
50c5921f-749b-11df-b112-00215aee3ebe;Гукалівці;Гукаловцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122683101;3;1
50c59220-749b-11df-b112-00215aee3ebe;Гуків;Гуков;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825282001;2;1
50c59221-749b-11df-b112-00215aee3ebe;Гуківка;Гуковка;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322081004;3;1
50c59222-749b-11df-b112-00215aee3ebe;Гукливий;Гуклывый;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121583001;3;1
50c59223-749b-11df-b112-00215aee3ebe;Гукове;Гуково;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925381003;3;1
50c59224-749b-11df-b112-00215aee3ebe;Гулевичі;Гулевичи;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520384402;3;1
50c59225-749b-11df-b112-00215aee3ebe;Гулі;Гули;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222981203;3;1
50c59226-749b-11df-b112-00215aee3ebe;Гулі;Гули;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524884503;3;1
50c59227-749b-11df-b112-00215aee3ebe;Гулі;Гули;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520281401;3;1
50c59229-749b-11df-b112-00215aee3ebe;Гулівка;Гулевка;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722187402;3;1
50c5922a-749b-11df-b112-00215aee3ebe;Гулівці;Гулевцы;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521683806;3;1
50c5922b-749b-11df-b112-00215aee3ebe;Гулівці;Гулевцы;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820381501;3;1
50c5922c-749b-11df-b112-00215aee3ebe;Гуляївка;Гуляевка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121281201;3;1
50c5922d-749b-11df-b112-00215aee3ebe;Гуляйгородок;Гуляйгородок;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825785203;3;1
50c5922e-749b-11df-b112-00215aee3ebe;Гуляйгородок;Гуляйгородок;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123782002;3;1
50c5922f-749b-11df-b112-00215aee3ebe;Гуляйка;Гуляйка;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124083202;3;1
50c59230-749b-11df-b112-00215aee3ebe;Гуляйполе;Гуляйполе;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320685803;3;1
50c59231-749b-11df-b112-00215aee3ebe;Гуляйполе;Гуляйполе;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222082001;3;1
50c59233-749b-11df-b112-00215aee3ebe;Гуляйполе;Гуляйполе;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122281701;3;1
50c59235-749b-11df-b112-00215aee3ebe;Гулянка;Гулянка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123180801;3;1
50c59236-749b-11df-b112-00215aee3ebe;Гулянка;Гулянка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386016;3;1
50c59237-749b-11df-b112-00215aee3ebe;Гульськ;Гульск;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824081001;3;1
50c59238-749b-11df-b112-00215aee3ebe;Гуманець;Гуманец;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625188203;3;1
50c59239-749b-11df-b112-00215aee3ebe;Гуменець;Гуменец;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623682201;3;1
50c5923a-749b-11df-b112-00215aee3ebe;Гуменів;Гуменев;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622880804;3;1
50c5923b-749b-11df-b112-00215aee3ebe;Гуменне;Гуменное;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520681403;3;1
50c5923c-749b-11df-b112-00215aee3ebe;Гуменники;Гуменники;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822581801;3;1
50c5923d-749b-11df-b112-00215aee3ebe;Гуменники;Гуменники;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624686703;3;1
50c5923e-749b-11df-b112-00215aee3ebe;Гуменці;Гуменцы;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723355302;3;1
50c5923f-749b-11df-b112-00215aee3ebe;Гуменці;Гуменцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822481801;3;1
50c59240-749b-11df-b112-00215aee3ebe;Гумниська;Гумниска;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125086202;3;1
50c59241-749b-11df-b112-00215aee3ebe;Гумниська;Гумниска;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620681513;3;1
50c59242-749b-11df-b112-00215aee3ebe;Гумничи;Гумничы;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;;3;1
50c59243-749b-11df-b112-00215aee3ebe;Гумнище;Гумнище;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720885403;3;1
50c59244-749b-11df-b112-00215aee3ebe;Гуничі;Гуничи;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824286503;3;1
50c59245-749b-11df-b112-00215aee3ebe;Гунча;Гунча;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520881803;3;1
50c59246-749b-11df-b112-00215aee3ebe;Гуняді;Гуняди;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120486005;3;1
50c59247-749b-11df-b112-00215aee3ebe;Гунька;Гунька;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523010104;3;1
50c59248-749b-11df-b112-00215aee3ebe;Гуньки;Гуньки;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322481002;3;1
50c59249-749b-11df-b112-00215aee3ebe;Гуньківка;Гуньковка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424482503;3;1
50c5924a-749b-11df-b112-00215aee3ebe;Гупали;Гупалы;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723382202;3;1
50c5924b-749b-11df-b112-00215aee3ebe;Гупалівка;Гупаловка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222381501;3;1
50c59258-749b-11df-b112-00215aee3ebe;Гур'їв;Гурьев;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322685002;3;1
50c59259-749b-11df-b112-00215aee3ebe;Гур'їв Козачок;Гурьев Казачок;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322682001;3;1
50c5925a-749b-11df-b112-00215aee3ebe;Гур'ївка;Гурьевка;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824882001;3;1
8ead1512-d655-11e1-a474-003048d2b473;Гураве;Гураве;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;;3;1
50c5924c-749b-11df-b112-00215aee3ebe;Гуральня;Гуральня;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623388302;3;1
50c5924d-749b-11df-b112-00215aee3ebe;Гуральня;Гуральня;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524383302;3;1
50c5924e-749b-11df-b112-00215aee3ebe;Гурбинці;Гурбинцы;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425183001;3;1
50c5924f-749b-11df-b112-00215aee3ebe;Гурбинці;Гурбинцы;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323882202;3;1
50c59251-749b-11df-b112-00215aee3ebe;Гурине;Гурино;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157603;3;1
50c59252-749b-11df-b112-00215aee3ebe;Гуринівка;Гуриновка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920683001;3;1
50c59253-749b-11df-b112-00215aee3ebe;Гуринівка;Гуриновка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322681503;3;1
50c59254-749b-11df-b112-00215aee3ebe;Гуринівка;Гуриновка;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422481503;3;1
50c59255-749b-11df-b112-00215aee3ebe;Гурівка;Гуровка;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3521982501;3;1
50c59256-749b-11df-b112-00215aee3ebe;Гурівці;Гуровцы;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521455504;3;1
50c59257-749b-11df-b112-00215aee3ebe;Гурівщина;Гуровщина;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222480803;3;1
50c5925c-749b-11df-b112-00215aee3ebe;Гуртовівка;Гуртововка;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323280605;3;1
50c5925d-749b-11df-b112-00215aee3ebe;Гурщина;Гурщина;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820384002;3;1
50c5925e-749b-11df-b112-00215aee3ebe;Гусавка;Гусавка;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423086303;3;1
50c5925f-749b-11df-b112-00215aee3ebe;Гусаки;Гусаки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321310103;3;1
50c59260-749b-11df-b112-00215aee3ebe;Гусаків;Гусаков;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622481601;3;1
50c59261-749b-11df-b112-00215aee3ebe;Гусакове;Гусаково;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121282401;3;1
50c59262-749b-11df-b112-00215aee3ebe;Гусари;Гусары;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625887303;3;1
50c59263-749b-11df-b112-00215aee3ebe;Гусарівка;Гусаровка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320282401;3;1
50c59264-749b-11df-b112-00215aee3ebe;Гусарівка;Гусаровка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320482501;3;1
50c59265-749b-11df-b112-00215aee3ebe;Гусарівка;Гусаровка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824281903;3;1
50c59267-749b-11df-b112-00215aee3ebe;Гусарщина;Гусарщина;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920384402;3;1
50c59268-749b-11df-b112-00215aee3ebe;Гусачівка;Гусачевка;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223182002;3;1
50c5926c-749b-11df-b112-00215aee3ebe;Гусинна Поляна;Гусинная Поляна;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321781003;3;1
50c5926d-749b-11df-b112-00215aee3ebe;Гуска;Гуска;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823483202;3;1
50c5926e-749b-11df-b112-00215aee3ebe;Гуслівка;Гусловка;село;64024b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324681004;3;1
50c5926f-749b-11df-b112-00215aee3ebe;Гусний;Гусный;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120886402;3;1
50c59270-749b-11df-b112-00215aee3ebe;Густиня;Густыня;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424183202;3;1
50c59271-749b-11df-b112-00215aee3ebe;Гусятин;Гусятин;місто;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121655100;2;0
50c59272-749b-11df-b112-00215aee3ebe;Гусятин;Гусятин;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825282301;2;1
50c59273-749b-11df-b112-00215aee3ebe;Гут;Гут;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120482401;3;1
50c59274-749b-11df-b112-00215aee3ebe;Гута;Гута;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921582702;3;1
50c59275-749b-11df-b112-00215aee3ebe;Гута;Гута;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724282201;3;1
50c59276-749b-11df-b112-00215aee3ebe;Гута;Гута;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
50c59277-749b-11df-b112-00215aee3ebe;Гута;Гута;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620482001;3;1
50c59278-749b-11df-b112-00215aee3ebe;Гута;Гута;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624886203;3;1
50c59279-749b-11df-b112-00215aee3ebe;Гута;Гута;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620686203;3;1
50c5927a-749b-11df-b112-00215aee3ebe;Гута;Гута;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621285102;3;1
50c5927b-749b-11df-b112-00215aee3ebe;Гута;Гута;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2124882903;3;1
50c5927c-749b-11df-b112-00215aee3ebe;Гута;Гута;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220681102;3;1
50c5927d-749b-11df-b112-00215aee3ebe;Гута;Гута;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422285503;3;1
50c5927e-749b-11df-b112-00215aee3ebe;Гута;Гута;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523055602;3;1
50c5927f-749b-11df-b112-00215aee3ebe;Гута;Гута;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823988802;3;1
50c59280-749b-11df-b112-00215aee3ebe;Гута;Гута;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821581202;3;1
50c59281-749b-11df-b112-00215aee3ebe;Гута;Гута;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820684505;3;1
50c59282-749b-11df-b112-00215aee3ebe;Гута;Гута;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5623481201;3;1
50c59283-749b-11df-b112-00215aee3ebe;Гута Обединська;Гута Обединская;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787619;3;1
50c59284-749b-11df-b112-00215aee3ebe;Гута станція;Гута Станция;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;;3;1
50c59285-749b-11df-b112-00215aee3ebe;Гута-Блищанівська;Гута-Блищановская;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821882402;3;1
50c59286-749b-11df-b112-00215aee3ebe;Гута-Бобрицька;Гута-Бобрицкая;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821781403;3;1
50c59287-749b-11df-b112-00215aee3ebe;Гута-Боровенська;Гута-Боровенская;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721483101;3;1
50c59288-749b-11df-b112-00215aee3ebe;Гута-Бушинська;Гута-Бушинская;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524580403;3;1
50c59289-749b-11df-b112-00215aee3ebe;Гута-Глібівська;Гута-Глебовская;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823382002;3;1
50c5928a-749b-11df-b112-00215aee3ebe;Гута-Добринь;Гута-Добрынь;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821181803;3;1
50c5928b-749b-11df-b112-00215aee3ebe;Гута-Забілоцька;Гута-Забелоцкая;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825082602;3;1
50c5928c-749b-11df-b112-00215aee3ebe;Гута-Зеленицька;Гута-Зеленицкая;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821782402;3;1
50c5928d-749b-11df-b112-00215aee3ebe;Гута-Камінська;Гута-Каменская;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721484802;3;1
50c5928e-749b-11df-b112-00215aee3ebe;Гута-Катюжанська;Гута-Катюжанская;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221883203;3;1
50c5928f-749b-11df-b112-00215aee3ebe;Гута-Ксаверівська;Гута-Ксаверовская;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755104;3;1
50c59290-749b-11df-b112-00215aee3ebe;Гута-Лісівська;Гута-Лесовская;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723684002;3;1
50c59291-749b-11df-b112-00215aee3ebe;Гута-Логанівська;Гута-Логановская;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823480807;3;1
50c59292-749b-11df-b112-00215aee3ebe;Гута-Межигірська;Гута-Межигорская;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221885203;3;1
50c59293-749b-11df-b112-00215aee3ebe;Гута-Межиріцька;Гута-Межиречская;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124984003;3;1
50c59294-749b-11df-b112-00215aee3ebe;Гута-Мовчанська;Гута-Мовчанская;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521083906;3;1
50c59295-749b-11df-b112-00215aee3ebe;Гута-Морозівська;Гута-Морозовская;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821886803;3;1
50c59296-749b-11df-b112-00215aee3ebe;Гута-Перейма;Гута-Перейма;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625485402;3;1
50c59297-749b-11df-b112-00215aee3ebe;Гута-Потіївка;Гута-Потиевка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825082201;3;1
50c59298-749b-11df-b112-00215aee3ebe;Гута-Селицька;Гута-Селицкая;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122587602;3;1
50c59299-749b-11df-b112-00215aee3ebe;Гута-Скляна;Гута-Скляна;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623983802;3;1
50c5929a-749b-11df-b112-00215aee3ebe;Гута-Стеблівська;Гута-Стебловская;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122588102;3;1
50c5929b-749b-11df-b112-00215aee3ebe;Гута-Студенецька;Гута-Студенецкая;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425881250;3;1
50c5929c-749b-11df-b112-00215aee3ebe;Гута-Ткачова;Гута-Ткачева;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424482002;3;1
50c5929d-749b-11df-b112-00215aee3ebe;Гута-Чугорська;Гута-Чугорская;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822489502;3;1
50c5929e-749b-11df-b112-00215aee3ebe;Гута-Шершнівська;Гута-Шершневская;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524587503;3;1
73e94be6-ddc1-11df-9197-00215aee3ebe;Гута-Юстинівка;Гута-Юстиновка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825483602;3;1
50c5929f-749b-11df-b112-00215aee3ebe;Гута-Яцьковецька;Гута-Яцковецкая;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821882401;3;1
50c592a0-749b-11df-b112-00215aee3ebe;Гути;Гуты;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922085002;3;1
50c592a1-749b-11df-b112-00215aee3ebe;Гути;Гуты;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320855300;3;1
50c592a2-749b-11df-b112-00215aee3ebe;Гути;Гуты;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524383202;3;1
50c592a3-749b-11df-b112-00215aee3ebe;Гутирівка;Гутыревка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081404;3;1
50c592a4-749b-11df-b112-00215aee3ebe;Гутирівка;Гутыревка;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323184803;3;1
50c592a5-749b-11df-b112-00215aee3ebe;Гутисько;Гутиско;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120485703;3;1
50c592a6-749b-11df-b112-00215aee3ebe;Гутисько-Тур'янське;Гутиско-Турьянское;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620686604;3;1
50c592a7-749b-11df-b112-00215aee3ebe;Гутище;Гутище;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621886403;3;1
50c592a8-749b-11df-b112-00215aee3ebe;Гутище;Гутище;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422487503;3;1
50c592a9-749b-11df-b112-00215aee3ebe;Гутко-Ожинка;Гутко-Ожинка;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924481302;3;1
50c592aa-749b-11df-b112-00215aee3ebe;Гутницька;Гутницкая;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520584002;3;1
50c592ab-749b-11df-b112-00215aee3ebe;Гутницьке;Гутницкое;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922989602;3;1
50c592ac-749b-11df-b112-00215aee3ebe;Гуто-Мар'ятин;Гуто-Марьятин;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755123;3;1
50c592ad-749b-11df-b112-00215aee3ebe;Гутянське;Гутянское;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823484003;3;1
50c592ae-749b-11df-b112-00215aee3ebe;Гуцулівка;Гуцуловка;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623687603;3;1
50c592af-749b-11df-b112-00215aee3ebe;Гучин;Гучин;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424482601;3;1
50c592b0-749b-11df-b112-00215aee3ebe;Гуштин;Гуштын;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120882601;3;1
50c592b1-749b-11df-b112-00215aee3ebe;Гуштинка;Гуштынка;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120883005;3;1
50c592b2-749b-11df-b112-00215aee3ebe;Гуща;Гуща;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723384908;3;1
50c592b3-749b-11df-b112-00215aee3ebe;Гущанки;Гущанкы;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124688202;3;1
50c592b4-749b-11df-b112-00215aee3ebe;Гущин;Гущин;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425583402;3;1
50c592b5-749b-11df-b112-00215aee3ebe;Гущинці;Гущинцы;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521681403;3;1
50c592b8-749b-11df-b112-00215aee3ebe;Дабинівка;Дабиновка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5321883802;3;1
50c592b9-749b-11df-b112-00215aee3ebe;Давидів;Давыдов;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623682401;2;0
50c592ba-749b-11df-b112-00215aee3ebe;Давидів Брід;Давыдов Брод;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520982001;3;1
50c592bb-749b-11df-b112-00215aee3ebe;Давидівка;Давыдовка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081906;3;1
50c592bc-749b-11df-b112-00215aee3ebe;Давидівка;Давыдовка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323486403;3;1
50c592bd-749b-11df-b112-00215aee3ebe;Давидівка;Давыдовка;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321683403;3;1
50c592be-749b-11df-b112-00215aee3ebe;Давидівка;Давыдовка;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324583001;3;1
50c592bf-749b-11df-b112-00215aee3ebe;Давидівка;Давыдовка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125255107;3;1
50c592c3-749b-11df-b112-00215aee3ebe;Давидівка;Давыдовка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821181201;3;1
50c592c4-749b-11df-b112-00215aee3ebe;Давидівка;Давыдовка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822083004;3;1
50c592c5-749b-11df-b112-00215aee3ebe;Давидівка;Давыдовка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521486002;3;1
50c592c6-749b-11df-b112-00215aee3ebe;Давидівка;Давыдовка;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323882201;3;1
50c592c7-749b-11df-b112-00215aee3ebe;Давидівці;Давыдовцы;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322582801;3;1
50c592c8-749b-11df-b112-00215aee3ebe;Давидки;Давыдки;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755124;3;1
50c592c9-749b-11df-b112-00215aee3ebe;Давидки;Давыдки;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822381401;3;1
50c592ca-749b-11df-b112-00215aee3ebe;Давидківці;Давыдковцы;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125582601;3;1
50c592cb-749b-11df-b112-00215aee3ebe;Давидківці;Давыдковцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825082401;3;0
50c592ce-749b-11df-b112-00215aee3ebe;Дадакалівка;Дадакаловка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321381603;3;1
50c592cf-749b-11df-b112-00215aee3ebe;Далеке;Далекое;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320685103;3;1
50c592d2-749b-11df-b112-00215aee3ebe;Далеке;Далекое;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524081402;3;1
50c592d3-749b-11df-b112-00215aee3ebe;Далешове;Далешово;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621680801;3;1
50c592d4-749b-11df-b112-00215aee3ebe;Далява;Далява;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621287202;3;1
50c592d6-749b-11df-b112-00215aee3ebe;Дальнє;Дальнее;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225083508;3;1
50c592d7-749b-11df-b112-00215aee3ebe;Дальнє;Дальнее;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323184805;3;1
50c592db-749b-11df-b112-00215aee3ebe;Дальник;Дальник;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123781301;3;1
50c592dc-749b-11df-b112-00215aee3ebe;Дальнич;Дальнич;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622182602;3;1
50c592dd-749b-11df-b112-00215aee3ebe;Дальній;Дальний;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
50c592de-749b-11df-b112-00215aee3ebe;Дальнічень;Дальничень;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120881802;3;1
50c592df-749b-11df-b112-00215aee3ebe;Дальня Шебелинка;Дальняя Шебелинка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320256001;3;1
50c592e0-749b-11df-b112-00215aee3ebe;Дамаска;Дамаска;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321386004;3;1
50c592e1-749b-11df-b112-00215aee3ebe;Данилівка;Даниловка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224284505;3;1
50c592e2-749b-11df-b112-00215aee3ebe;Данилівка;Даниловка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820980901;3;1
50c592e3-749b-11df-b112-00215aee3ebe;Данилівка;Даниловка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320485504;3;1
50c592e4-749b-11df-b112-00215aee3ebe;Данилівка;Даниловка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121683705;3;1
50c592e5-749b-11df-b112-00215aee3ebe;Данилівка;Даниловка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283609;3;1
50c592e6-749b-11df-b112-00215aee3ebe;Данилівка;Даниловка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323682402;3;1
50c592e7-749b-11df-b112-00215aee3ebe;Данилівка;Даниловка;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120488404;3;1
50c592ea-749b-11df-b112-00215aee3ebe;Данилівка;Даниловка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221482001;3;1
50c592eb-749b-11df-b112-00215aee3ebe;Данилівка;Даниловка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821184004;3;1
50c592ec-749b-11df-b112-00215aee3ebe;Данилівка;Даниловка;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423083001;3;1
50c592ed-749b-11df-b112-00215aee3ebe;Данилівка;Даниловка;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624283604;3;1
50c592ee-749b-11df-b112-00215aee3ebe;Данилівка;Даниловка;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820382002;3;1
cdc44f6c-dde8-11df-9197-00215aee3ebe;Данилівка Друга;Даниловка Вторая;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;;3;1
cdc44f6d-dde8-11df-9197-00215aee3ebe;Данилівка Перша;Даниловка Первая;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;;3;1
50c592ef-749b-11df-b112-00215aee3ebe;Данилівці;Даниловцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122687302;3;1
50c592f0-749b-11df-b112-00215aee3ebe;Данилки;Данилки;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523084403;3;1
50c592f2-749b-11df-b112-00215aee3ebe;Данилова Балка;Данилова Балка;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525581901;3;1
50c592f4-749b-11df-b112-00215aee3ebe;Данилово;Данилово;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125382601;3;1
50c592fa-749b-11df-b112-00215aee3ebe;Данильче;Данильче;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624481601;3;1
50c592f5-749b-11df-b112-00215aee3ebe;Данильчин Кут;Данильчин Кут;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321283002;3;1
50c592f6-749b-11df-b112-00215aee3ebe;Данине;Данино;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423383601;3;1
50c592f7-749b-11df-b112-00215aee3ebe;Даничі;Даничи;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424482801;3;1
50c592f8-749b-11df-b112-00215aee3ebe;Даничів;Даничев;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623082001;3;1
50c592f9-749b-11df-b112-00215aee3ebe;Данівка;Даневка;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422082401;3;1
50c592fb-749b-11df-b112-00215aee3ebe;Данківці;Данковцы;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325081601;3;1
50c592fc-749b-11df-b112-00215aee3ebe;Данчиміст;Данчимост;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623485402;3;1
50c592fd-749b-11df-b112-00215aee3ebe;Данюки;Данюки;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825089002;3;1
50c592fe-749b-11df-b112-00215aee3ebe;Даньківка;Даньковка;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521280406;3;1
50c592ff-749b-11df-b112-00215aee3ebe;Даньківка;Даньковка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424182101;3;1
50c59300-749b-11df-b112-00215aee3ebe;Даньківка;Даньковка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524385205;3;1
50c59301-749b-11df-b112-00215aee3ebe;Даньківці;Данькивци;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822183304;3;1
50c59306-749b-11df-b112-00215aee3ebe;Дар'ївка;Дарьевка;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520381001;3;1
50c59308-749b-11df-b112-00215aee3ebe;Дар'ївка;Дарьевка;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122084703;3;1
50c59309-749b-11df-b112-00215aee3ebe;Дар'ївка;Дарьевка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522584207;3;1
50c59302-749b-11df-b112-00215aee3ebe;Дараганове;Дараганово;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923586703;3;1
50c59303-749b-11df-b112-00215aee3ebe;Дарахів;Дарахов;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125082201;3;1
50c59304-749b-11df-b112-00215aee3ebe;Дарівка;Даровка;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155310;3;1
50c5930b-749b-11df-b112-00215aee3ebe;Дар-Надежда;Дар-Надежда;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324882001;3;1
50c5930c-749b-11df-b112-00215aee3ebe;Дарниця;Дарница;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821480404;3;1
50c5930d-749b-11df-b112-00215aee3ebe;Дарницьке;Дарницкое;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720510406;3;1
50c5930e-749b-11df-b112-00215aee3ebe;Датинь;Датынь;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724282601;3;1
50c5930f-749b-11df-b112-00215aee3ebe;Дахнівка;Дахновка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821285202;3;1
50c59311-749b-11df-b112-00215aee3ebe;Дахталія;Дахталия;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521981801;3;1
50c59312-749b-11df-b112-00215aee3ebe;Даценківка;Даценковка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982404;3;1
50c59313-749b-11df-b112-00215aee3ebe;Дації;Дации;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324883205;3;1
50c59314-749b-11df-b112-00215aee3ebe;Дацьки;Дацки;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625881903;3;1
50c59315-749b-11df-b112-00215aee3ebe;Дацьки;Дацки;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825880402;3;1
50c59316-749b-11df-b112-00215aee3ebe;Дацьки;Дацки;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122586301;3;1
50c59318-749b-11df-b112-00215aee3ebe;Дача;Дача;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524687702;3;1
50c5931a-749b-11df-b112-00215aee3ebe;Дачне;Дачное;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721885802;3;1
50c5931b-749b-11df-b112-00215aee3ebe;Дачне;Дачное;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220786603;3;1
50c5931c-749b-11df-b112-00215aee3ebe;Дачне;Дачное;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222685502;3;1
50c5931d-749b-11df-b112-00215aee3ebe;Дачне;Дачное;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225855105;3;1
50c5931e-749b-11df-b112-00215aee3ebe;Дачне;Дачное;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321782002;3;1
50c5931f-749b-11df-b112-00215aee3ebe;Дачне;Дачное;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121082401;2;1
50c59325-749b-11df-b112-00215aee3ebe;Дачне;Дачное;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422484504;3;1
50c59327-749b-11df-b112-00215aee3ebe;Дачне;Дачное;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
bc78d1a0-835b-11e8-80d4-1c98ec135261;Дачне;Дачне;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;;3;1
42a5e2c9-4a2f-11e9-80dd-1c98ec135261;Дачне;Дачное;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320482605;2;1
5cb61bd0-749b-11df-b112-00215aee3ebe;Дачне (Котовець);Дачное (Котовец);село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223584503;3;1
62c3d133-749b-11df-b112-00215aee3ebe;Дачне (Леніна);Дачное (Ленина);село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225283303;3;1
50c59328-749b-11df-b112-00215aee3ebe;Дашава;Дашава;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625355300;3;1
50c59329-749b-11df-b112-00215aee3ebe;Дашинка;Дашинка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821181301;3;1
50c5932a-749b-11df-b112-00215aee3ebe;Дашів;Дашев;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521280409;3;0
50c5932b-749b-11df-b112-00215aee3ebe;Дашківка;Дашковка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321881701;3;1
50c5932c-749b-11df-b112-00215aee3ebe;Дашківці;Дашковцы;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522482201;3;1
50c5932d-749b-11df-b112-00215aee3ebe;Дашківці;Дашковцы;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824487003;3;1
50c5932e-749b-11df-b112-00215aee3ebe;Дашківці;Дашковцы;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820682501;3;1
50c5932f-749b-11df-b112-00215aee3ebe;Дашуківка;Дашуковка;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122882801;3;1
50c59330-749b-11df-b112-00215aee3ebe;Дащенки;Дащенки;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421181601;3;1
50c59332-749b-11df-b112-00215aee3ebe;Двірець;Дворец;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123483102;3;1
50c59333-749b-11df-b112-00215aee3ebe;Двірець;Дворец;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822086504;3;1
50c59334-749b-11df-b112-00215aee3ebe;Двірець;Дворец;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822181901;3;1
50c59335-749b-11df-b112-00215aee3ebe;Двірець;Дворец;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625455702;3;1
50c59336-749b-11df-b112-00215aee3ebe;Двірківщина;Дворковщина;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225581401;3;1
50c59337-749b-11df-b112-00215aee3ebe;Двірці;Дворцы;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120487003;3;1
50c59338-749b-11df-b112-00215aee3ebe;Двірці;Дворцы;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624881801;3;1
50c59339-749b-11df-b112-00215aee3ebe;Дворик;Дворик;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822787005;3;1
50c5933a-749b-11df-b112-00215aee3ebe;Дворище;Дворище;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123084005;3;1
50c5933b-749b-11df-b112-00215aee3ebe;Дворище;Дворище;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821181501;3;1
50c5933e-749b-11df-b112-00215aee3ebe;Дворічний Кут;Двуречный Кут;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322080901;3;1
50c5933f-749b-11df-b112-00215aee3ebe;Дворіччя;Двуречье;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125082401;3;1
50c59342-749b-11df-b112-00215aee3ebe;Дворовичі;Дворовичи;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624686704;3;1
50c59343-749b-11df-b112-00215aee3ebe;Дебальцеве;Дебальцево;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755120;3;1
50c59345-749b-11df-b112-00215aee3ebe;Дебеславці;Дебеславцы;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623281801;3;1
50c59346-749b-11df-b112-00215aee3ebe;Дебреве;Дебрево;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423810101;3;1
50c59347-749b-11df-b112-00215aee3ebe;Дебрі;Дебри;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625881904;3;1
50c59349-749b-11df-b112-00215aee3ebe;Дев'ятибратове;Девятибратово;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320888305;3;1
50c5934a-749b-11df-b112-00215aee3ebe;Дев'ятир;Девятир;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783001;3;1
50c5934b-749b-11df-b112-00215aee3ebe;Дев'ятники;Девятники;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621582401;3;1
50c59348-749b-11df-b112-00215aee3ebe;Девладове;Девладово;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282501;3;1
50c5934c-749b-11df-b112-00215aee3ebe;Дегова;Дегова;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624485201;3;1
50c5934d-749b-11df-b112-00215aee3ebe;Дегтярі;Дегтяри;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320881003;3;1
50c5934e-749b-11df-b112-00215aee3ebe;Дегтярка;Дегтярка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324285005;3;1
50c59350-749b-11df-b112-00215aee3ebe;Дейкалівка;Дейкаловка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321381701;3;1
50c59351-749b-11df-b112-00215aee3ebe;Дейманівка;Деймановка;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425183002;3;1
50c59352-749b-11df-b112-00215aee3ebe;Дейманівка;Деймановка;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323882601;3;1
50c59353-749b-11df-b112-00215aee3ebe;Дейнеківка;Дейнековка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321084105;3;1
50c59354-749b-11df-b112-00215aee3ebe;Декабристів;Декабристов;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323280402;3;1
50c59355-749b-11df-b112-00215aee3ebe;Деконка;Деконка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222382503;2;1
50c59356-749b-11df-b112-00215aee3ebe;Делева;Делева;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625686204;3;1
50c59357-749b-11df-b112-00215aee3ebe;Делень;Делень;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120481901;3;1
50c59358-749b-11df-b112-00215aee3ebe;Делета;Делета;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824280302;3;1
50c59359-749b-11df-b112-00215aee3ebe;Делієве;Делиево;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621281501;3;1
50c5935a-749b-11df-b112-00215aee3ebe;Делятин;Делятин;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2624055900;3;0
50c594e3-749b-11df-b112-00215aee3ebe;Дельжилер (Дмитрівка);Дельжилер (Дмитровка);село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125081901;2;1
50c5935c-749b-11df-b112-00215aee3ebe;Дельфінове;Дельфиново;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525584802;3;1
50c59386-749b-11df-b112-00215aee3ebe;Дем'янів;Демьянов;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621282001;3;1
50c59387-749b-11df-b112-00215aee3ebe;Дем'янівка;Демьяновка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324586303;3;1
50c59388-749b-11df-b112-00215aee3ebe;Дем'янівка;Демьяновка;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322282503;3;1
50c59389-749b-11df-b112-00215aee3ebe;Дем'янівка;Демьяновка;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925681902;3;1
50c5938a-749b-11df-b112-00215aee3ebe;Дем'янівка;Демьяновка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225884803;3;1
50c5938b-749b-11df-b112-00215aee3ebe;Дем'янівка;Демьяновка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123183703;3;1
50c59391-749b-11df-b112-00215aee3ebe;Дем'янка-Лісна;Демьянка-Лесная;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621583302;3;1
50c59392-749b-11df-b112-00215aee3ebe;Дем'янка-Наддністрянська;Демьянка-Надднестрянская;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621583303;3;1
69adc83a-6104-11e0-9fa7-00215aee3ebe;Дем'янка-Наддністрянська;Демьянка-Надднестрянская;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;044989ca-09e5-11e1-9215-003048d2b473;4621583303;3;1
50c59393-749b-11df-b112-00215aee3ebe;Дем'янки;Демьянки;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755119;3;1
50c59394-749b-11df-b112-00215aee3ebe;Дем'янківці;Демьянковцы;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821882501;3;1
50c59395-749b-11df-b112-00215aee3ebe;Дем'янці;Демьянцы;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281504;3;1
50c59396-749b-11df-b112-00215aee3ebe;Дем'янці;Демьянцы;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223382401;3;1
50c5935d-749b-11df-b112-00215aee3ebe;Деменки;Деменки;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321885402;3;1
50c5935f-749b-11df-b112-00215aee3ebe;Дементівка;Дементовка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125283003;3;1
50c59360-749b-11df-b112-00215aee3ebe;Дементіївка;Дементиевка;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322082504;3;1
50c59361-749b-11df-b112-00215aee3ebe;Деменці;Деменцы;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125481803;3;1
50c59362-749b-11df-b112-00215aee3ebe;Демечі;Демечи;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124883902;3;1
50c59363-749b-11df-b112-00215aee3ebe;Демешківці;Демешковцы;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621281801;3;1
50c59364-749b-11df-b112-00215aee3ebe;Демешкове;Демешково;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522586902;3;1
50c59365-749b-11df-b112-00215aee3ebe;Демидів;Демидов;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621581203;3;1
50c59366-749b-11df-b112-00215aee3ebe;Демидів;Демидов;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221882401;3;1
50c59367-749b-11df-b112-00215aee3ebe;Демидівка;Демидовка;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324280501;3;1
50c59368-749b-11df-b112-00215aee3ebe;Демидівка;Демидовка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320684902;3;1
50c59369-749b-11df-b112-00215aee3ebe;Демидівка;Демидовка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322481001;3;1
50c5936a-749b-11df-b112-00215aee3ebe;Демидівка;Демидовка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123383402;3;1
50c5936c-749b-11df-b112-00215aee3ebe;Демидівка;Демидовка;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521080603;3;1
50c5936d-749b-11df-b112-00215aee3ebe;Демидівка;Демидовка;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524181701;3;1
50c5936e-749b-11df-b112-00215aee3ebe;Демидівка;Демидовка;місто;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621455300;3;1
50c5936f-749b-11df-b112-00215aee3ebe;Демидове;Демидово;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121281601;3;1
50c59375-749b-11df-b112-00215aee3ebe;Демина Балка;Демина Балка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324881205;3;1
50c59370-749b-11df-b112-00215aee3ebe;Деминівка;Деминовка;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224982403;3;1
50c59372-749b-11df-b112-00215aee3ebe;Демівка;Демовка;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621583602;3;1
50c59373-749b-11df-b112-00215aee3ebe;Демівка;Демовка;село;78f453a8-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0525082601;3;1
50c59374-749b-11df-b112-00215aee3ebe;Демівщина;Демовщина;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222283201;3;1
50c59376-749b-11df-b112-00215aee3ebe;Демки;Демки;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120683501;3;1
50c59377-749b-11df-b112-00215aee3ebe;Демків;Демков;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521084206;3;1
50c59378-749b-11df-b112-00215aee3ebe;Демківка;Демковка;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524180302;3;1
50c59379-749b-11df-b112-00215aee3ebe;Демківське;Демковское;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524180303;3;1
50c5937a-749b-11df-b112-00215aee3ebe;Демківці;Демковцы;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823182902;3;1
50c5937b-749b-11df-b112-00215aee3ebe;Демківці;Демковцы;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824283203;3;1
50c5937c-749b-11df-b112-00215aee3ebe;Демківці;Демковцы;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825287002;2;1
50c5937d-749b-11df-b112-00215aee3ebe;Демкове;Демково;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121289202;3;1
50c5937e-749b-11df-b112-00215aee3ebe;Демня;Демня;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120485705;3;1
50c5937f-749b-11df-b112-00215aee3ebe;Демня;Демня;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623088405;3;1
50c59380-749b-11df-b112-00215aee3ebe;Демурине;Демурино;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655109;3;1
50c59381-749b-11df-b112-00215aee3ebe;Демурино-Варварівка;Демурино-Варваровка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224585905;3;1
50c59382-749b-11df-b112-00215aee3ebe;Демченкове;Демченково;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320683602;3;1
50c59383-749b-11df-b112-00215aee3ebe;Демченкове;Демченково;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924485602;3;1
50c59384-749b-11df-b112-00215aee3ebe;Демчин;Демчин;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820883902;3;1
50c59385-749b-11df-b112-00215aee3ebe;Демшин;Демшин;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822483602;3;1
50c59398-749b-11df-b112-00215aee3ebe;Дениси;Денисы;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223382801;3;1
50c59399-749b-11df-b112-00215aee3ebe;Денисів;Денисов;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123082801;3;1
50c5939b-749b-11df-b112-00215aee3ebe;Денисівка;Денисовка;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820382001;3;1
50c5939c-749b-11df-b112-00215aee3ebe;Денисівка;Денисовка;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323680801;3;1
50c5939d-749b-11df-b112-00215aee3ebe;Денихівка;Дениховка;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224682401;2;1
50c5939e-749b-11df-b112-00215aee3ebe;Дениші;Дениши;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822087203;3;1
50c5939f-749b-11df-b112-00215aee3ebe;Деньги;Деньги;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121583101;3;1
50c593a0-749b-11df-b112-00215aee3ebe;День-Добрий;День-Добрый;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421482402;3;1
50c593a2-749b-11df-b112-00215aee3ebe;Дептівка;Дептовка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922082601;3;1
50c593a3-749b-11df-b112-00215aee3ebe;Деражне;Деражное;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623481601;3;1
50c593a4-749b-11df-b112-00215aee3ebe;Деражня;Деражня;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925686003;3;1
50c593a5-749b-11df-b112-00215aee3ebe;Деражня;Деражня;місто;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821510100;3;0
50c593a6-749b-11df-b112-00215aee3ebe;Дерганівка;Дергановка;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825283201;3;1
50c593a7-749b-11df-b112-00215aee3ebe;Дергачі;Дергачи;місто;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322010100;2;1
50c593a8-749b-11df-b112-00215aee3ebe;Деребчин;Деребчин;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525381201;3;1
50c593b1-749b-11df-b112-00215aee3ebe;Дерев'яна;Деревянная;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223182501;3;1
50c593b2-749b-11df-b112-00215aee3ebe;Дерев'яне;Деревянное;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822482101;3;1
50c593b3-749b-11df-b112-00215aee3ebe;Дерев'яне;Деревянное;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624684905;3;1
50c593b4-749b-11df-b112-00215aee3ebe;Дерев'янки;Деревянки;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621885202;3;1
50c593b5-749b-11df-b112-00215aee3ebe;Дерев'янки;Деревянки;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221484003;3;1
50c593b6-749b-11df-b112-00215aee3ebe;Дерев'янки;Деревянки;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525380503;3;1
50c593b7-749b-11df-b112-00215aee3ebe;Дерев'янче;Деревянче;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624280405;3;1
50c593a9-749b-11df-b112-00215aee3ebe;Деревач;Деревач;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623685602;3;1
50c593ab-749b-11df-b112-00215aee3ebe;Деревини;Деревины;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421483601;3;1
50c593ac-749b-11df-b112-00215aee3ebe;Деревищина;Деревищина;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820682002;3;1
50c593ad-749b-11df-b112-00215aee3ebe;Деревки;Деревки;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322281301;3;1
50c593ae-749b-11df-b112-00215aee3ebe;Деревляни;Деревляны;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622186602;3;1
50c593af-749b-11df-b112-00215aee3ebe;Деревня;Деревня;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783101;3;1
50c593b0-749b-11df-b112-00215aee3ebe;Деревок;Деревок;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155114;3;1
50c593b8-749b-11df-b112-00215aee3ebe;Дерегівка;Дереговка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324284002;3;1
50c593b9-749b-11df-b112-00215aee3ebe;Дережичі;Дережичи;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621282701;3;1
50c593ba-749b-11df-b112-00215aee3ebe;Дерезівка;Дерезовка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582507;3;1
50c593bb-749b-11df-b112-00215aee3ebe;Дерезувате;Дерезоватое;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224881501;3;1
50c593bc-749b-11df-b112-00215aee3ebe;Дереківщина;Дерековщина;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322686202;3;1
50c593bd-749b-11df-b112-00215aee3ebe;Деремезна;Деремезна;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223183001;3;1
50c593be-749b-11df-b112-00215aee3ebe;Деренівка;Дереневка;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125082702;3;1
50c593bf-749b-11df-b112-00215aee3ebe;Деренковець;Деренковец;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122581501;3;1
50c593c0-749b-11df-b112-00215aee3ebe;Дерепівка;Дереповка;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423081002;3;1
50c593c1-749b-11df-b112-00215aee3ebe;Дерешова;Дерешова;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522882301;3;1
50c593c2-749b-11df-b112-00215aee3ebe;Держаки;Держаки;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820385002;3;1
50c593c3-749b-11df-b112-00215aee3ebe;Держанівка;Держановка;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824486402;3;1
50c593c4-749b-11df-b112-00215aee3ebe;Держанівка;Держановка;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423881001;3;1
50c593c5-749b-11df-b112-00215aee3ebe;Держанівка;Держановка;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524887202;3;1
50c593c6-749b-11df-b112-00215aee3ebe;Держанівка;Держановка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521486606;3;1
50c593c7-749b-11df-b112-00215aee3ebe;Держанівка;Держановка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821882601;3;1
50c593c8-749b-11df-b112-00215aee3ebe;Держанка;Держанка;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525680802;3;1
50c593c9-749b-11df-b112-00215aee3ebe;Держів;Держев;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623083601;3;1
50c593cb-749b-11df-b112-00215aee3ebe;Деріївка;Дериевка;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524681301;3;1
50c593cc-749b-11df-b112-00215aee3ebe;Деркачі;Деркачи;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983003;3;1
50c593cd-749b-11df-b112-00215aee3ebe;Деркачі;Деркачи;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824286203;3;1
50c593ce-749b-11df-b112-00215aee3ebe;Деркачі;Деркачи;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323289202;3;1
50c593cf-749b-11df-b112-00215aee3ebe;Деркачівка;Деркачовка;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923580901;3;1
50c593d1-749b-11df-b112-00215aee3ebe;Дерманка;Дерманка;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623087902;3;1
50c593d2-749b-11df-b112-00215aee3ebe;Дермань Друга;Дермань Вторая;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622681801;3;1
50c593d3-749b-11df-b112-00215aee3ebe;Дермань Перша;Дермань Первая;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622681901;3;1
50c593d4-749b-11df-b112-00215aee3ebe;Дернаки;Дернаки;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625887002;3;1
50c593d5-749b-11df-b112-00215aee3ebe;Дернів;Дернов;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622182601;3;1
50c593d6-749b-11df-b112-00215aee3ebe;Дернівка;Дерновка;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787206;3;1
50c593d7-749b-11df-b112-00215aee3ebe;Дернівка;Дерновка;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220282001;3;1
50c593d8-749b-11df-b112-00215aee3ebe;Дерно;Дерно;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721882001;3;1
50c593d9-749b-11df-b112-00215aee3ebe;Дернове;Дерновое;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925082001;3;1
50c593db-749b-11df-b112-00215aee3ebe;Дертка;Дертка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821455601;3;1
50c593dc-749b-11df-b112-00215aee3ebe;Дертка;Дертка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822182201;3;1
50c593dd-749b-11df-b112-00215aee3ebe;Дерть;Дерть;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625084402;3;1
50c593de-749b-11df-b112-00215aee3ebe;Дерцен;Дерцен;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122782001;2;1
50c593df-749b-11df-b112-00215aee3ebe;Деряги;Деряги;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321384804;3;1
50c593e0-749b-11df-b112-00215aee3ebe;Десантне;Десантное;село;903ccab3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122310302;3;1
50c593e1-749b-11df-b112-00215aee3ebe;Десна;Десна;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422055400;2;1
50c593e2-749b-11df-b112-00215aee3ebe;Десна;Десна;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520655500;3;0
50c593e3-749b-11df-b112-00215aee3ebe;Деснянка;Деснянка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425585502;3;1
d8148db8-90de-11e8-80d4-1c98ec135261;Деснянське;Деснянское;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422288601;3;1
50c593e4-749b-11df-b112-00215aee3ebe;Десятина;Десятина;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720884402;3;1
50c593e5-749b-11df-b112-00215aee3ebe;Десятини;Десятины;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386802;3;1
50c593e7-749b-11df-b112-00215aee3ebe;Дехтярка;Дехтярка;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824785702;3;1
50c593e8-749b-11df-b112-00215aee3ebe;Дешичі;Дешичи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625182302;3;1
50c593e9-749b-11df-b112-00215aee3ebe;Дешки;Дешки;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220687302;3;1
50c593ea-749b-11df-b112-00215aee3ebe;Дешки;Дешки;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422081202;3;1
50c593eb-749b-11df-b112-00215aee3ebe;Дешковиця;Дешковица;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121981202;3;1
50c593ed-749b-11df-b112-00215aee3ebe;Джгун;Джгун;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321780503;3;1
50c593ef-749b-11df-b112-00215aee3ebe;Джерело;Джерело;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824485602;3;1
50c593f0-749b-11df-b112-00215aee3ebe;Джугастра;Джугастра;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521982001;3;1
50c593f1-749b-11df-b112-00215aee3ebe;Джугастрове;Джугастрово;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121881404;3;1
50c593f2-749b-11df-b112-00215aee3ebe;Джулинка;Джулинка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520481603;3;1
50c593f3-749b-11df-b112-00215aee3ebe;Джуржівка;Джуржевка;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823382003;3;1
50c593f4-749b-11df-b112-00215aee3ebe;Джурин;Джурин;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125582901;3;1
50c593f5-749b-11df-b112-00215aee3ebe;Джурин;Джурин;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525381401;3;1
50c593f6-749b-11df-b112-00215aee3ebe;Джуринська Слобідка;Джуринская Слободка;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125582902;3;1
50c593f7-749b-11df-b112-00215aee3ebe;Джуринці;Джуринцы;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523083201;3;1
50c593f8-749b-11df-b112-00215aee3ebe;Джурів;Джуров;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625281801;3;1
50c593f9-749b-11df-b112-00215aee3ebe;Джурків;Джурков;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623282001;3;1
50c593fa-749b-11df-b112-00215aee3ebe;Дзвенигород;Дзвенигород;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120888302;3;1
50c593fb-749b-11df-b112-00215aee3ebe;Дзвеняче;Дзвеняче;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224610105;3;1
50c593fc-749b-11df-b112-00215aee3ebe;Дзвиняч;Дзвиняч;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122082401;3;1
50c593fd-749b-11df-b112-00215aee3ebe;Дзвиняч;Дзвиняч;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620482401;3;1
50c593fe-749b-11df-b112-00215aee3ebe;Дзвиняча;Дзвиняча;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122482101;3;1
50c593ff-749b-11df-b112-00215aee3ebe;Дзвинячка;Дзвинячка;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120883601;3;1
50c59400-749b-11df-b112-00215aee3ebe;Дзвінки;Дзвонки;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820382503;3;1
50c59401-749b-11df-b112-00215aee3ebe;Дзвінкове;Дзвонковое;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221482201;3;1
50c59402-749b-11df-b112-00215aee3ebe;Дзвониха;Дзвониха;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524582701;3;1
50c59403-749b-11df-b112-00215aee3ebe;Дзеленці;Дзеленцы;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820982101;3;1
720763f3-0c20-11e4-a577-003048d2b473;Дземброня;Дземброня;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620881002;3;1
50c59404-749b-11df-b112-00215aee3ebe;Дзензелівка;Дзензелевка;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123181501;3;1
50c59407-749b-11df-b112-00215aee3ebe;Дзержинськ;Дзержинск;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821782003;3;1
50c5940f-749b-11df-b112-00215aee3ebe;Дзержинське;Дзержинское;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;;3;1
50c59411-749b-11df-b112-00215aee3ebe;Дзержинського;Дзержинского;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420685202;3;1
50c59412-749b-11df-b112-00215aee3ebe;Дзеркалька;Дзеркалька;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924184904;3;1
50c59414-749b-11df-b112-00215aee3ebe;Дзигівка;Дзыговка;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525682201;3;1
50c59415-749b-11df-b112-00215aee3ebe;Дзинілор;Дзинилор;село;903ccab3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122383902;3;1
50c59416-749b-11df-b112-00215aee3ebe;Дзюбанівка;Дзюбановка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322083703;3;1
50c59417-749b-11df-b112-00215aee3ebe;Дзюби;Дзюбы;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
50c59418-749b-11df-b112-00215aee3ebe;Дзюбівка;Дзюбовка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225587402;3;1
50c59419-749b-11df-b112-00215aee3ebe;Дзюбівка;Дзюбовка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421788403;3;1
50c5941a-749b-11df-b112-00215aee3ebe;Дзюбівщина;Дзюбовщина;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281206;3;1
50c5941b-749b-11df-b112-00215aee3ebe;Дзюброве;Дзюброво;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525682202;3;1
50c5941c-749b-11df-b112-00215aee3ebe;Дзюньків;Дзюньков;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523481801;3;1
50c5941f-749b-11df-b112-00215aee3ebe;Диб'янки;Дыбянки;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623684202;3;1
50c5941d-749b-11df-b112-00215aee3ebe;Дибинці;Дыбинцы;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220681401;3;1
50c5941e-749b-11df-b112-00215aee3ebe;Дибще;Дыбще;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123083201;3;1
50c59420-749b-11df-b112-00215aee3ebe;Дивень;Дивень;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623081003;3;1
50c59421-749b-11df-b112-00215aee3ebe;Дивин;Дивин;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820980701;3;1
50c59422-749b-11df-b112-00215aee3ebe;Дивізійне;Дивизийное;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323980504;3;1
50c59423-749b-11df-b112-00215aee3ebe;Дивізія;Дивизия;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125081601;2;1
50c59427-749b-11df-b112-00215aee3ebe;Дидятичі;Дидятичи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622482001;3;1
50c59428-749b-11df-b112-00215aee3ebe;Дийда;Дыйда;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120483603;3;1
50c59429-749b-11df-b112-00215aee3ebe;Диканька;Диканька;місто;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321055100;3;0
50c5942a-749b-11df-b112-00215aee3ebe;Диків;Диков;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624684906;3;1
50c5942b-749b-11df-b112-00215aee3ebe;Диківка;Диковка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522281201;3;1
50c5942c-749b-11df-b112-00215aee3ebe;Дикове;Диково;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;;3;1
50c5942d-749b-11df-b112-00215aee3ebe;Диковини;Диковины;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720883803;3;1
50c5942e-749b-11df-b112-00215aee3ebe;Диковичеве;Диковичево;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523682702;3;1
50c59431-749b-11df-b112-00215aee3ebe;Димарка;Дымарка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222080901;3;1
50c59432-749b-11df-b112-00215aee3ebe;Димер;Дымер;місто;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221855300;3;1
50c59433-749b-11df-b112-00215aee3ebe;Димерка;Дымерка;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422086803;3;1
50c59434-749b-11df-b112-00215aee3ebe;Димине;Дымино;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523182802;3;1
50c59435-749b-11df-b112-00215aee3ebe;Димине;Дымино;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524080901;3;1
b36f1a40-db87-11df-9197-00215aee3ebe;Димитрова;Димитрова;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6311090103;3;1
50c59438-749b-11df-b112-00215aee3ebe;Димитрове;Димитрово;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323981301;3;1
50c5943d-749b-11df-b112-00215aee3ebe;Димівське;Дымовское;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824882601;3;1
50c5943e-749b-11df-b112-00215aee3ebe;Димка;Дымка;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321081901;3;1
50c5943f-749b-11df-b112-00215aee3ebe;Динівці;Диновцы;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323081601;3;1
50c59440-749b-11df-b112-00215aee3ebe;Дирдин;Дырдин;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120382801;3;1
50c59441-749b-11df-b112-00215aee3ebe;Дитиничі;Дитиничи;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621685205;3;1
50c59442-749b-11df-b112-00215aee3ebe;Дитятин;Дитятин;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621282401;3;1
50c59443-749b-11df-b112-00215aee3ebe;Дитятки;Дитятки;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222080801;3;1
50c59444-749b-11df-b112-00215aee3ebe;Диханівка;Дыхановка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421484003;3;1
50c59445-749b-11df-b112-00215aee3ebe;Дихтинець;Дихтинец;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323580501;3;1
50c59446-749b-11df-b112-00215aee3ebe;Дич;Дичь;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920988402;3;1
50c59448-749b-11df-b112-00215aee3ebe;Дички;Дички;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624481701;3;1
50c59449-749b-11df-b112-00215aee3ebe;Дичків;Дичков;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125282401;2;1
50c5944a-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925380802;3;1
50c5944b-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755121;3;1
50c5944c-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324589502;3;1
50c5944d-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122980803;3;1
50c5944f-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121982801;3;1
50c59450-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124480404;3;1
50c59453-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222281602;3;1
50c59454-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220683202;3;1
50c59455-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224610106;2;1
50c59456-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821783003;3;1
50c59457-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;;3;1
50c59458-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820988003;3;1
50c59459-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820655701;3;1
50c5945a-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825885203;3;1
50c5945b-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423089002;3;1
50c5945c-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123481301;3;1
50c5945d-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122883001;3;1
50c5945e-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121587803;3;1
50c5945f-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524881802;3;1
50c59460-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523881301;3;1
50c59461-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520810101;3;1
50c59465-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323283407;3;1
50c59463-749b-11df-b112-00215aee3ebe;Дібрівка;Дибровка;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624683703;3;1
50c59466-749b-11df-b112-00215aee3ebe;Дібрівки;Дибровки;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623687003;3;1
50c59467-749b-11df-b112-00215aee3ebe;Дібрівне;Дибровное;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320483702;3;1
50c59468-749b-11df-b112-00215aee3ebe;Дібрівне;Дибровное;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421487602;3;1
50c59469-749b-11df-b112-00215aee3ebe;Дібрівне;Дибровное;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322685302;3;1
50c5946a-749b-11df-b112-00215aee3ebe;Дібрівськ;Дибровск;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622281001;3;1
50c5946c-749b-11df-b112-00215aee3ebe;Дібрівське;Дубровское;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824082602;3;1
50c5946d-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321355403;3;1
50c5946e-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322281903;3;1
50c5946f-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925655101;3;1
50c59470-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924184901;3;1
50c59471-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155115;3;1
50c59472-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155311;3;1
50c59473-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721880803;3;1
50c59474-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720885003;3;1
50c59475-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123481703;3;1
50c59476-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122489202;3;1
50c59477-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120488403;3;1
50c59478-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124280802;3;1
50c59479-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055109;3;1
50c5947a-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224881701;3;1
50c5947b-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224287803;3;1
50c5947c-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824581202;3;1
50c5947d-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322581502;3;1
50c59480-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624485802;3;1
50c59481-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622884202;3;1
50c59482-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622086002;3;1
50c59483-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620487202;3;1
50c59485-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624882003;3;1
50c59486-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783907;3;1
50c59487-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625889206;3;1
50c59488-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625388802;3;1
50c5948b-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124482401;3;1
50c5948d-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221482101;3;1
50c5948e-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824455300;3;1
50c5948f-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822880402;3;1
50c59490-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822385603;3;1
50c59491-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823483201;3;1
50c59492-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423683205;3;1
50c59493-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423385203;3;1
50c59494-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425385003;3;1
50c59495-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520281203;3;1
50c59496-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825287403;2;1
50c59497-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620889102;3;1
50c59498-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;;3;1
50c59499-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322884507;3;1
50c59703-749b-11df-b112-00215aee3ebe;Діброва;Диброва;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;;3;1
a738d07c-60e8-11e0-9fa7-00215aee3ebe;Діброва;Диброва;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625687203;3;1
50c5949b-749b-11df-b112-00215aee3ebe;Діброва (Діброво-Ленінське);Диброва (Диброво-Ленинское);село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221083502;3;1
8716233a-749b-11df-b112-00215aee3ebe;Діброва (Фрунзенка);Диброва (Фрунзенка);село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924789805;3;1
87162599-749b-11df-b112-00215aee3ebe;Діброва (Чапаєвка);Диброва (Чапаевка);село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321085801;3;1
325c57fd-ddd2-11df-9197-00215aee3ebe;Діброви;Дибровы;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520386204;3;1
50c5949a-749b-11df-b112-00215aee3ebe;Дібровинці;Дибровинцы;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523182202;3;1
50c59462-749b-11df-b112-00215aee3ebe;Дібровка;Дибровка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822182403;3;1
50c5949c-749b-11df-b112-00215aee3ebe;Дівички;Девички;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223382001;3;1
8cdd5cd5-de27-11df-9197-00215aee3ebe;Дівички Перші;Девички Первые;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;;3;1
50c5949d-749b-11df-b112-00215aee3ebe;Дівичне;Дивичное;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;;3;1
50c5949f-749b-11df-b112-00215aee3ebe;Дівоцьке;Дивоцкое;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655112;3;1
50c594a0-749b-11df-b112-00215aee3ebe;Дівоче Поле;Девичье Поле;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520382401;3;1
50c594a2-749b-11df-b112-00215aee3ebe;Дівочина;Дивочина;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523184702;3;1
50c594a1-749b-11df-b112-00215aee3ebe;Дівочки;Дивочки;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825683201;3;1
50c594a3-749b-11df-b112-00215aee3ebe;Дівошин;Девошин;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824286204;3;1
50c594a4-749b-11df-b112-00215aee3ebe;Дігори;Дигоры;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123185703;3;1
50c594a5-749b-11df-b112-00215aee3ebe;Дігтів;Дегтев;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0720581602;3;1
50c594a6-749b-11df-b112-00215aee3ebe;Дігтярі;Дегтяри;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922987107;3;1
50c594a7-749b-11df-b112-00215aee3ebe;Дігтярі;Дегтяри;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425155400;3;1
50c594a8-749b-11df-b112-00215aee3ebe;Дігтярівка;Дегтяревка;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923583404;3;1
50c594a9-749b-11df-b112-00215aee3ebe;Дігтярівка;Дегтяревка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423683601;3;1
50c594aa-749b-11df-b112-00215aee3ebe;Дігтярка;Дегтярка;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923581305;3;1
50c594ab-749b-11df-b112-00215aee3ebe;Дігтярка;Дегтярка;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522883603;3;1
50c594ac-749b-11df-b112-00215aee3ebe;Дігтярне;Дегтярное;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920686202;3;1
50c594ad-749b-11df-b112-00215aee3ebe;Діденкове;Диденково;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221083303;3;1
50c594ae-749b-11df-b112-00215aee3ebe;Дідилів;Дедилов;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622182301;3;1
50c594af-749b-11df-b112-00215aee3ebe;Дідичі;Дедычи;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721881601;3;1
50c594b1-749b-11df-b112-00215aee3ebe;Дідівка;Дедовка;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622284002;3;1
50c594b2-749b-11df-b112-00215aee3ebe;Дідівці;Дедовцы;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424182201;3;1
50c594b3-749b-11df-b112-00215aee3ebe;Дідівщина;Дедовщина;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922682204;3;1
50c594b4-749b-11df-b112-00215aee3ebe;Дідівщина;Дедовщина;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224982401;3;1
50c594b6-749b-11df-b112-00215aee3ebe;Дідківці;Дедковцы;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825882402;3;1
50c594b7-749b-11df-b112-00215aee3ebe;Дідківці;Дедковцы;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820355601;3;1
50c594b8-749b-11df-b112-00215aee3ebe;Дідковичі;Дедковичи;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822381601;3;1
50c594b9-749b-11df-b112-00215aee3ebe;Дідова Гора;Дедова Гора;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823984702;3;1
50c594ba-749b-11df-b112-00215aee3ebe;Дідове;Дедово;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221084102;3;1
50c594bb-749b-11df-b112-00215aee3ebe;Дідовичі;Дедовичи;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721886605;3;1
50c594bc-749b-11df-b112-00215aee3ebe;Дідовичі;Дедовичи;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824081201;3;1
50c594bd-749b-11df-b112-00215aee3ebe;Діл;Дол;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122486002;3;1
50c594be-749b-11df-b112-00215aee3ebe;Ділове;Деловое;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123682501;3;0
50c594bf-749b-11df-b112-00215aee3ebe;Ділок;Дилок;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122780202;2;1
50c594c0-749b-11df-b112-00215aee3ebe;Ділок;Дилок;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125382202;3;1
50c594c1-749b-11df-b112-00215aee3ebe;Дільниче;Дильныче;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824882301;3;1
50c594c2-749b-11df-b112-00215aee3ebe;Дітинець;Детинец;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825087203;3;1
50c594c3-749b-11df-b112-00215aee3ebe;Дітківці;Детковцы;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620381304;3;1
50c594b5-749b-11df-b112-00215aee3ebe;Дітківці;Детковцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122687702;3;1
50c594c4-749b-11df-b112-00215aee3ebe;Дмитрашківка;Дмитрашковка;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523281401;3;1
50c594c5-749b-11df-b112-00215aee3ebe;Дмитре;Дмитре;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623682601;3;1
50c594c6-749b-11df-b112-00215aee3ebe;Дмитренка;Дмитренко;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520984802;3;1
50c594c7-749b-11df-b112-00215aee3ebe;Дмитренки;Дмитренки;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281403;3;1
50c594c8-749b-11df-b112-00215aee3ebe;Дмитренки;Дмитренки;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323484606;3;1
50c594c9-749b-11df-b112-00215aee3ebe;Дмитренки;Дмитренки;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220681101;3;1
50c594ca-749b-11df-b112-00215aee3ebe;Дмитренки;Дмитренки;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520880606;3;1
50c594cb-749b-11df-b112-00215aee3ebe;Дмитрів;Дмитров;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623981201;3;1
50c594cc-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755120;3;1
50c594cd-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323081301;3;1
50c594ce-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;d00d3b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5310290301;3;1
50c594cf-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920987003;3;1
50c594d0-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983203;3;1
50c594d1-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921281301;3;1
50c594d2-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724586902;3;1
50c594d3-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223286502;3;1
50c594d4-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221084101;3;1
50c594d5-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224584805;3;1
50c594d6-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223881501;3;1
50c594d7-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222981001;3;1
50c594d8-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655107;3;1
50c594d9-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083808;3;1
50c594da-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820981201;3;1
50c594db-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825182202;3;1
50c594dc-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320882001;3;1
50c594dd-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323281205;3;1
50c594de-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324583001;3;1
50c594df-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322882202;3;1
50c594e0-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320483602;3;1
50c594e1-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684006;3;1
50c594e2-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122781301;3;1
50c594e4-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;903ccab3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122381201;3;1
50c594e5-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;6b07f8a2-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5121483001;3;1
50c594f2-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;a8bc35df-4aa1-11e3-9df6-003048d2b473;3221888602;3;1
50c594f3-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222484401;3;1
50c594f4-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224982801;3;1
50c594f5-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423088502;3;1
50c594f6-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420355600;3;1
50c594f7-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121583501;3;1
50c594f8-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;;3;1
50c594f9-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522281501;3;1
50c594fa-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824783203;3;1
50c594fb-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621286302;3;1
50c594fc-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323683602;3;1
50c594fd-749b-11df-b112-00215aee3ebe;Дмитрівка;Дмитровка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;;3;1
56bdd257-749b-11df-b112-00215aee3ebe;Дмитрівка (Жовтневе);Дмитровка (Жовтневое);село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221081501;3;1
56bdd264-749b-11df-b112-00215aee3ebe;Дмитрівка (Жовтневе);Дмитривка (Жовтневое);село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424182801;3;1
1e0626db-6aa7-11eb-80d1-000c29800ae7;Дмитрівське;Дмитровское;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123383203;3;1
50c594ff-749b-11df-b112-00215aee3ebe;Дмитро-Білівка;Дмитро-Беловка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655108;3;1
50c59501-749b-11df-b112-00215aee3ebe;Дмитровичі;Дмитровичи;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623681203;3;1
50c59502-749b-11df-b112-00215aee3ebe;Дмитровичі;Дмитровичи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622482201;3;1
50c59505-749b-11df-b112-00215aee3ebe;Дмитрушки;Дмитрушки;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124382501;3;1
50c59506-749b-11df-b112-00215aee3ebe;Дмухайлівка;Дмухайловка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222382001;3;1
50c59507-749b-11df-b112-00215aee3ebe;Дмухівці;Дмуховцы;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123055401;3;1
50c59508-749b-11df-b112-00215aee3ebe;Дніпрельстан;Днепрельстан;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;*122328400;3;1
50c5950a-749b-11df-b112-00215aee3ebe;Дніпрельстан;Днепрельстан;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322181603;3;1
50c5951b-749b-11df-b112-00215aee3ebe;Дніпро;Днепр;місто;d00d3b5d-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1210100000;1;0
50c5950b-749b-11df-b112-00215aee3ebe;Дніпрове;Днепровое;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221486203;3;1
50c5950c-749b-11df-b112-00215aee3ebe;Дніпрові Хвилі;Днепровы Хвыли;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322187202;3;1
50c5950d-749b-11df-b112-00215aee3ebe;Дніпровка;Днепровка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221884707;3;1
50c5950e-749b-11df-b112-00215aee3ebe;Дніпровка;Днепровка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321581301;3;1
50c59512-749b-11df-b112-00215aee3ebe;Дніпровокам'янка;Днепровокаменка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221084401;3;1
50c59513-749b-11df-b112-00215aee3ebe;Дніпровське;Днепровское;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221055400;3;1
50c59514-749b-11df-b112-00215aee3ebe;Дніпровське;Днепровское;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055104;3;1
50c59515-749b-11df-b112-00215aee3ebe;Дніпровське;Днепровское;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825182203;3;1
50c59516-749b-11df-b112-00215aee3ebe;Дніпровське;Днепровское;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520381401;3;1
50c59517-749b-11df-b112-00215aee3ebe;Дніпровське;Днепровское;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425581601;3;1
cdc44f3e-dde8-11df-9197-00215aee3ebe;Дніпровське;Днепровское;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224888502;3;1
50c5951e-749b-11df-b112-00215aee3ebe;Дністрик;Днестрик;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625184302;3;1
50c5951f-749b-11df-b112-00215aee3ebe;Дністрик-Дубовий;Днестрик-Дубовый;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625580602;3;1
50c59520-749b-11df-b112-00215aee3ebe;Дністрівка;Днестровка;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322083601;3;1
50c59521-749b-11df-b112-00215aee3ebe;Дністрове;Днестровое;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
50c59522-749b-11df-b112-00215aee3ebe;Дністровець;Днестровец;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123183403;3;1
50c59523-749b-11df-b112-00215aee3ebe;Добощівка;Добощевка;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484003;3;1
50c59524-749b-11df-b112-00215aee3ebe;Добра;Добрая;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123182001;3;1
50c59526-749b-11df-b112-00215aee3ebe;Добра Воля;Добрая Воля;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824510101;3;1
50c59525-749b-11df-b112-00215aee3ebe;Добра Криниця;Добрая Криница;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820680701;3;1
50c59527-749b-11df-b112-00215aee3ebe;Добра Надія;Добрая Надия;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1211390003;3;1
50c59528-749b-11df-b112-00215aee3ebe;Добра Надія;Добрая Надия;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823381702;3;1
50c59529-749b-11df-b112-00215aee3ebe;Добраничівка;Добраничевка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225582103;3;1
50c5952a-749b-11df-b112-00215aee3ebe;Добре;Доброе;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721483301;3;1
50c5952b-749b-11df-b112-00215aee3ebe;Добре;Доброе;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610106;3;1
50c5952c-749b-11df-b112-00215aee3ebe;Добре;Доброе;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821184202;3;1
50c5952e-749b-11df-b112-00215aee3ebe;Добре;Доброе;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524380401;3;1
50c5952f-749b-11df-b112-00215aee3ebe;Добренька;Добренькая;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323382505;3;1
50c59530-749b-11df-b112-00215aee3ebe;Добривода;Добрывода;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625882101;3;1
50c59531-749b-11df-b112-00215aee3ebe;Добриднівка;Добрыдневка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123581002;3;1
50c59532-749b-11df-b112-00215aee3ebe;Добрий Лук;Добрый Лук;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121683002;3;1
50c59533-749b-11df-b112-00215aee3ebe;Добрин;Добрин;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822184503;3;1
50c59534-749b-11df-b112-00215aee3ebe;Добринів;Добрынев;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624481901;3;1
50c59535-749b-11df-b112-00215aee3ebe;Добринівці;Добриновцы;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321584001;3;1
50c59536-749b-11df-b112-00215aee3ebe;Добринь;Добрынь;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821181801;3;1
50c59537-749b-11df-b112-00215aee3ebe;Добринька;Добринька;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223884007;3;1
50c59539-749b-11df-b112-00215aee3ebe;Добрівляни;Добравляны;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122082801;3;1
50c5953a-749b-11df-b112-00215aee3ebe;Добрівляни;Добравляны;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621583002;3;1
50c5953b-749b-11df-b112-00215aee3ebe;Добрівляни;Добравляны;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621283001;3;1
50c5953c-749b-11df-b112-00215aee3ebe;Добрівляни;Добравляны;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625380901;3;1
50c5953d-749b-11df-b112-00215aee3ebe;Добрік;Добрик;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123687002;3;1
50c5953e-749b-11df-b112-00215aee3ebe;Добровеличківка;Добровеличковка;місто;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521755100;2;1
50c5953f-749b-11df-b112-00215aee3ebe;Добровілля;Доброволье;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220786609;3;1
50c59540-749b-11df-b112-00215aee3ebe;Добровілля;Доброволье;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320682501;3;1
50c59542-749b-11df-b112-00215aee3ebe;Добровілля;Доброволье;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822486503;2;1
50c59544-749b-11df-b112-00215aee3ebe;Добровільське;Добровольское;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655109;3;1
50c59545-749b-11df-b112-00215aee3ebe;Добровляни;Добровляны;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622884804;3;1
50c59546-749b-11df-b112-00215aee3ebe;Добровляни;Добровляны;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625881201;3;1
50c59547-749b-11df-b112-00215aee3ebe;Доброводи;Доброводы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122482401;3;1
50c59548-749b-11df-b112-00215aee3ebe;Доброводи;Доброводы;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124283801;3;1
50c59549-749b-11df-b112-00215aee3ebe;Доброводи;Доброводы;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124382801;3;1
50c5954b-749b-11df-b112-00215aee3ebe;Доброгірське;Доброгорское;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221084103;3;1
50c5954c-749b-11df-b112-00215aee3ebe;Доброгорща;Доброгорща;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825081502;3;1
50c5954d-749b-11df-b112-00215aee3ebe;Доброгостів;Доброгостов;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621283301;3;1
50c5954e-749b-11df-b112-00215aee3ebe;Доброжанівка;Доброжановка;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822381301;3;1
50c5954f-749b-11df-b112-00215aee3ebe;Доброжанове;Доброжаново;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121080703;3;1
50c59550-749b-11df-b112-00215aee3ebe;Доброкам'янка;Доброкаменка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820682202;3;1
50c59553-749b-11df-b112-00215aee3ebe;Добромиль;Добромиль;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625110300;3;0
50c59554-749b-11df-b112-00215aee3ebe;Добромишиль;Добромышль;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
50c59555-749b-11df-b112-00215aee3ebe;Добромірка;Добромерка;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122482601;3;1
50c59556-749b-11df-b112-00215aee3ebe;Добронадіївка;Добронадиевка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520382601;3;1
50c59557-749b-11df-b112-00215aee3ebe;Доброолександрівка;Доброалександровка;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123781401;3;1
50c59558-749b-11df-b112-00215aee3ebe;Добропілля;Доброполье;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321256002;3;1
50c5955c-749b-11df-b112-00215aee3ebe;Добропілля;Доброполье;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420881602;3;1
50c5955e-749b-11df-b112-00215aee3ebe;Доброполе;Доброполе;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121281601;3;1
50c5955f-749b-11df-b112-00215aee3ebe;Добросин;Добросин;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783201;3;1
5cb619af-749b-11df-b112-00215aee3ebe;Доброслав;Доброслав;місто;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122755100;3;1
50c59561-749b-11df-b112-00215aee3ebe;Доброславівка;Доброславовка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920389203;3;1
50c59562-749b-11df-b112-00215aee3ebe;Добростани;Добростаны;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625883201;3;1
50c59563-749b-11df-b112-00215aee3ebe;Добротвір;Добротвор;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622155300;3;1
50c59564-749b-11df-b112-00215aee3ebe;Добротимофіївка;Добротимофеевка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521784402;3;1
50c59565-749b-11df-b112-00215aee3ebe;Добротів;Добротов;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624081601;3;1
50c59566-749b-11df-b112-00215aee3ebe;Добротове;Добротово;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922683001;3;1
50c59568-749b-11df-b112-00215aee3ebe;Добряни;Добряны;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620983001;3;1
50c59569-749b-11df-b112-00215aee3ebe;Добряни;Добряны;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623088406;3;1
50c5956a-749b-11df-b112-00215aee3ebe;Добряни;Добряны;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625381001;3;1
50c5956b-749b-11df-b112-00215aee3ebe;Добряничі;Добряничи;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623380601;3;1
50c5956c-749b-11df-b112-00215aee3ebe;Добрянка;Добрянка;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521882503;3;1
50c5956d-749b-11df-b112-00215aee3ebe;Добрянка;Добрянка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424455400;3;1
50c5956e-749b-11df-b112-00215aee3ebe;Добрянка;Добрянка;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124084802;3;1
50c5956f-749b-11df-b112-00215aee3ebe;Добрянка;Добрянка;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525684002;3;1
50c59570-749b-11df-b112-00215aee3ebe;Добрянка;Добрянка;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524380801;3;1
50c59571-749b-11df-b112-00215aee3ebe;Добрянське;Добрянское;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921281701;3;1
50c59572-749b-11df-b112-00215aee3ebe;Добрянське;Добрянское;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124485805;3;1
50c59573-749b-11df-b112-00215aee3ebe;Добрятин;Добрятин;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623882301;3;1
50c59574-749b-11df-b112-00215aee3ebe;Добрячин;Добрячин;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624884204;3;1
50c59576-749b-11df-b112-00215aee3ebe;Довбиш;Довбыш;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820655400;3;1
50c59577-749b-11df-b112-00215aee3ebe;Довбиші;Довбыши;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825884003;3;1
50c59578-749b-11df-b112-00215aee3ebe;Довбні;Довбни;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421788002;3;1
50c59579-749b-11df-b112-00215aee3ebe;Довбнівка;Довбневка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321387803;3;1
50c5957b-749b-11df-b112-00215aee3ebe;Довга Гребля;Долгая Гребля;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;;3;1
50c5957c-749b-11df-b112-00215aee3ebe;Довга Гребля;Долгая Гребля;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422487504;3;1
50c5957d-749b-11df-b112-00215aee3ebe;Довга Лука;Долгая Лука;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923280402;3;1
50c5957a-749b-11df-b112-00215aee3ebe;Довга Пристань;Долгая Пристань;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825481201;3;1
50c5957e-749b-11df-b112-00215aee3ebe;Довга Пустош;Долгая Пустошь;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323481004;3;1
50c5957f-749b-11df-b112-00215aee3ebe;Довгалеве;Довгалево;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323280403;3;1
50c59580-749b-11df-b112-00215aee3ebe;Довгалівка;Довгалевка;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320255105;3;1
50c59581-749b-11df-b112-00215aee3ebe;Довгалівка;Довгалевка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320255701;3;1
50c59582-749b-11df-b112-00215aee3ebe;Довгалівка;Довгалевка;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425385502;3;1
50c59583-749b-11df-b112-00215aee3ebe;Довгалівка;Довгалевка;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523482001;3;1
50c59584-749b-11df-b112-00215aee3ebe;Довгалівка;Довгалевка;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820383001;3;1
50c59585-749b-11df-b112-00215aee3ebe;Довгалівка;Довгалевка;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625881203;3;1
50c59586-749b-11df-b112-00215aee3ebe;Довгалівка;Довгалевка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323288202;3;1
50c59588-749b-11df-b112-00215aee3ebe;Довге;Долгое;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322081202;3;1
50c59589-749b-11df-b112-00215aee3ebe;Довге;Долгое;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125082701;3;1
50c5958a-749b-11df-b112-00215aee3ebe;Довге;Долгое;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220787703;3;1
50c5958c-749b-11df-b112-00215aee3ebe;Довге;Долгое;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625881001;3;1
50c5958d-749b-11df-b112-00215aee3ebe;Довге;Долгое;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621283801;3;1
50c5958e-749b-11df-b112-00215aee3ebe;Довге;Долгое;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625386403;3;1
50c5958f-749b-11df-b112-00215aee3ebe;Довге;Долгое;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121983001;3;0
50c59591-749b-11df-b112-00215aee3ebe;Довге;Долгое;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421484402;3;1
50c59592-749b-11df-b112-00215aee3ebe;Довге;Долгое;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423380403;3;1
50c59593-749b-11df-b112-00215aee3ebe;Довге;Долгое;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625487603;3;1
50c59594-749b-11df-b112-00215aee3ebe;Довге Поле;Долгое Поле;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124880303;3;1
50c59595-749b-11df-b112-00215aee3ebe;Довге Поле;Долгое Поле;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621687405;3;1
50c59596-749b-11df-b112-00215aee3ebe;Довге-Калуське;Долгое-Калушское;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622885202;3;1
50c59599-749b-11df-b112-00215aee3ebe;Довгеньке;Довгенькое;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322882501;3;1
50c5959a-749b-11df-b112-00215aee3ebe;Довгеньке;Довгенькое;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124084402;3;1
50c5959b-749b-11df-b112-00215aee3ebe;Довгий Войнилів;Долгий Войнилов;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622880806;3;1
50c5959c-749b-11df-b112-00215aee3ebe;Довгиничі;Довгиничи;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824288802;3;1
50c5959d-749b-11df-b112-00215aee3ebe;Довгів;Долгов;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720880403;3;1
50c5959e-749b-11df-b112-00215aee3ebe;Довгівка;Довговка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282510;3;1
50c5959f-749b-11df-b112-00215aee3ebe;Довгівка;Довговка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222982804;3;1
50c595a0-749b-11df-b112-00215aee3ebe;Довгове;Довговое;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323981503;3;1
50c595a1-749b-11df-b112-00215aee3ebe;Довгове;Довговое;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520984303;3;1
50c595a2-749b-11df-b112-00215aee3ebe;Довговоля;Довговоля;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620883401;3;1
50c595a3-749b-11df-b112-00215aee3ebe;Довголука;Довголука;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625380603;3;1
50c595a4-749b-11df-b112-00215aee3ebe;Довгомостиська;Довгомостиска;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622482401;3;1
50c595a5-749b-11df-b112-00215aee3ebe;Довгоноси;Довгоносы;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155701;3;1
50c595a6-749b-11df-b112-00215aee3ebe;Довгопілля;Довгополье;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323581001;3;1
50c595a7-749b-11df-b112-00215aee3ebe;Довгополе;Довгополе;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;2620883001;3;1
50c595a8-749b-11df-b112-00215aee3ebe;Довгополівка;Довгополовка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924185801;3;1
50c595a9-749b-11df-b112-00215aee3ebe;Довгополівка;Довгополовка;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524582801;3;1
50c595aa-749b-11df-b112-00215aee3ebe;Довгошиї;Довгошии;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623882601;3;1
50c595ab-749b-11df-b112-00215aee3ebe;Довгуни;Довгуны;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424484205;3;1
50c595ac-749b-11df-b112-00215aee3ebe;Довжанка;Довжанка;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125282501;2;1
50c595ad-749b-11df-b112-00215aee3ebe;Довжанка;Довжанка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822783204;3;1
50c595af-749b-11df-b112-00215aee3ebe;Довжанка;Довжанка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123181301;3;1
50c595b1-749b-11df-b112-00215aee3ebe;Довженки;Довженки;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822783205;3;1
50c595b2-749b-11df-b112-00215aee3ebe;Довжик;Довжик;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321385002;3;1
50c595b3-749b-11df-b112-00215aee3ebe;Довжик;Довжик;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925610301;3;1
50c595b4-749b-11df-b112-00215aee3ebe;Довжик;Довжик;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923286802;3;1
50c595b5-749b-11df-b112-00215aee3ebe;Довжик;Довжик;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920382801;3;1
50c595b6-749b-11df-b112-00215aee3ebe;Довжик;Довжик;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322682501;3;1
50c595b7-749b-11df-b112-00215aee3ebe;Довжик;Довжик;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321283003;3;1
50c595b9-749b-11df-b112-00215aee3ebe;Довжик;Довжик;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424781504;3;1
50c595ba-749b-11df-b112-00215aee3ebe;Довжик;Довжик;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425581901;3;1
84231bd4-8ac4-11ee-80c1-000c2961d091;Довжик;Дедовичи;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824081201;3;1
50c595bb-749b-11df-b112-00215aee3ebe;Довжиця;Довжица;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723682201;3;1
50c595bc-749b-11df-b112-00215aee3ebe;Довжка;Довжка;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622886603;3;1
50c595bd-749b-11df-b112-00215aee3ebe;Довжки;Довжки;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624581601;3;1
50c595be-749b-11df-b112-00215aee3ebe;Довжки;Довжки;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823982401;3;1
50c595bf-749b-11df-b112-00215aee3ebe;Довжок;Довжок;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321383202;3;1
50c595c0-749b-11df-b112-00215aee3ebe;Довжок;Довжок;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323081801;3;1
50c595c1-749b-11df-b112-00215aee3ebe;Довжок;Довжок;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523483802;3;1
50c595c2-749b-11df-b112-00215aee3ebe;Довжок;Довжок;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523081203;3;1
50c595c3-749b-11df-b112-00215aee3ebe;Довжок;Довжок;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525381803;3;1
50c595c4-749b-11df-b112-00215aee3ebe;Довжок;Довжок;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524184802;3;1
50c595c5-749b-11df-b112-00215aee3ebe;Довжок;Довжок;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525682401;3;1
50c595c6-749b-11df-b112-00215aee3ebe;Довжок;Довжок;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;;3;1
50c595c7-749b-11df-b112-00215aee3ebe;Довжок;Довжок;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822482401;3;0
50c595c8-749b-11df-b112-00215aee3ebe;Довпотів;Довпотов;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622855301;3;1
50c595cb-749b-11df-b112-00215aee3ebe;Дожва;Дожва;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725583402;3;1
50c595cf-749b-11df-b112-00215aee3ebe;Докучаєве;Докучаево;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525884401;3;1
0c8a5a95-9c16-11e0-82d0-003048d2b473;Докучаєве;Докучаево;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325158502;2;1
5cb619ec-749b-11df-b112-00215aee3ebe;Докучаєвське (Комуніст);Докучаевское (Коммунист);село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325158502;3;1
50c595d1-749b-11df-b112-00215aee3ebe;Долина;Долина;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281405;3;1
50c595d2-749b-11df-b112-00215aee3ebe;Долина;Долина;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086905;3;1
50c595d3-749b-11df-b112-00215aee3ebe;Долина;Долина;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921586402;3;1
50c595d4-749b-11df-b112-00215aee3ebe;Долина;Долина;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923555604;3;1
50c595d5-749b-11df-b112-00215aee3ebe;Долина;Долина;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724285503;3;1
50c595d6-749b-11df-b112-00215aee3ebe;Долина;Долина;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125082901;3;1
50c595d7-749b-11df-b112-00215aee3ebe;Долина;Долина;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125589503;3;1
50c595d8-749b-11df-b112-00215aee3ebe;Долина;Долина;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225980504;3;1
50c595da-749b-11df-b112-00215aee3ebe;Долина;Долина;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625686205;3;1
50c595dc-749b-11df-b112-00215aee3ebe;Долина;Долина;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223183401;3;1
50c595dd-749b-11df-b112-00215aee3ebe;Долина;Долина;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522281504;3;1
50c595de-749b-11df-b112-00215aee3ebe;Долина;Долина;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623885002;3;1
50c595df-749b-11df-b112-00215aee3ebe;Долина;Долина;місто;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622010100;2;0
50c595e0-749b-11df-b112-00215aee3ebe;Долини;Долины;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622185907;3;1
50c595e1-749b-11df-b112-00215aee3ebe;Долинівка;Долиновка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120884204;3;1
50c595e2-749b-11df-b112-00215aee3ebe;Долинівка;Долиновка;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120482101;3;1
50c595e3-749b-11df-b112-00215aee3ebe;Долинівка;Долиновка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322187203;3;1
50c595e4-749b-11df-b112-00215aee3ebe;Долинівка;Долиновка;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624587202;3;1
50c595e7-749b-11df-b112-00215aee3ebe;Долинівка;Долиновка;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820985303;3;1
50c595e8-749b-11df-b112-00215aee3ebe;Долинівка;Долиновка;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521181301;3;1
50c595e9-749b-11df-b112-00215aee3ebe;Долинівка;Долиновка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522885003;3;1
50c595ea-749b-11df-b112-00215aee3ebe;Долинівка;Долиновка;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825282003;2;1
50c595eb-749b-11df-b112-00215aee3ebe;Долинівці;Долиновцы;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822781402;3;1
50c595ec-749b-11df-b112-00215aee3ebe;Долинка;Долинка;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721155101;3;1
50c595ed-749b-11df-b112-00215aee3ebe;Долинка;Долинка;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124580801;3;1
50c595f1-749b-11df-b112-00215aee3ebe;Долинка;Долинка;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123481401;3;1
50c595f2-749b-11df-b112-00215aee3ebe;Долинка;Долинка;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322687203;3;1
33ec001d-ddd5-11df-9197-00215aee3ebe;Долинка;Долинка;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521980403;3;1
50c595f5-749b-11df-b112-00215aee3ebe;Долино-Кам'янка;Долино-Каменка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522287402;3;1
50c595f6-749b-11df-b112-00215aee3ebe;Долинська;Долинская;місто;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521910100;2;1
50c595f7-749b-11df-b112-00215aee3ebe;Долинське;Долинское;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221482002;3;1
50c595f8-749b-11df-b112-00215aee3ebe;Долинське;Долинское;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224586002;3;1
50c595f9-749b-11df-b112-00215aee3ebe;Долинське;Долинское;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225080509;3;1
50c595fa-749b-11df-b112-00215aee3ebe;Долинське;Долинское;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225482003;3;1
50c595fb-749b-11df-b112-00215aee3ebe;Долинське;Долинское;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120281401;3;1
50c595fc-749b-11df-b112-00215aee3ebe;Долинське;Долинское;село;d00d3b62-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5124180501;3;1
50c595fd-749b-11df-b112-00215aee3ebe;Долинське;Долинское;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322183501;3;1
50c59601-749b-11df-b112-00215aee3ebe;Долинське;Долинское;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424981502;3;1
50c59602-749b-11df-b112-00215aee3ebe;Долинське;Долинское;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520382901;3;1
50c59603-749b-11df-b112-00215aee3ebe;Долиняни;Долиняны;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325082401;3;1
50c59604-749b-11df-b112-00215aee3ebe;Долиняни;Долиняны;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624482101;3;1
50c59605-749b-11df-b112-00215aee3ebe;Долиняни;Долиняны;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620983301;3;1
50c59606-749b-11df-b112-00215aee3ebe;Долиняни;Долиняны;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522882501;3;1
50c59607-749b-11df-b112-00215aee3ebe;Долішнє;Долишнее;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623082802;3;1
50c59608-749b-11df-b112-00215aee3ebe;Долішнє;Долишнее;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625381601;3;1
50c59609-749b-11df-b112-00215aee3ebe;Долішнє Залуччя;Долишнее Залучье;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625281502;3;1
50c5960a-749b-11df-b112-00215aee3ebe;Долішній Лужок;Долишний Лужок;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621284001;3;1
50c5960b-749b-11df-b112-00215aee3ebe;Долішній Шепіт;Долишний Шепот;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320581501;3;1
50c5960d-749b-11df-b112-00215aee3ebe;Долобів;Долобов;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624288603;3;1
50c5960f-749b-11df-b112-00215aee3ebe;Долоччя;Долочье;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6822183003;3;1
d7ca2029-feed-11e7-80cd-1c98ec135261;Долоччя;Долочье;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;;3;1
50c59612-749b-11df-b112-00215aee3ebe;Дольськ;Дольск;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723184801;3;1
50c59613-749b-11df-b112-00215aee3ebe;Дольськ;Дольск;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725555101;3;1
50c59614-749b-11df-b112-00215aee3ebe;Домажир;Домажир;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625883601;3;1
50c59615-749b-11df-b112-00215aee3ebe;Домаморич;Домаморич;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125282601;3;1
50c59616-749b-11df-b112-00215aee3ebe;Доманівка;Домановка;місто;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822755100;3;1
50c59617-749b-11df-b112-00215aee3ebe;Доманівка;Домановка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222083602;3;1
50c59618-749b-11df-b112-00215aee3ebe;Доманове;Доманово;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724285504;3;1
50c59619-749b-11df-b112-00215aee3ebe;Домантівка;Домантовка;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224081801;3;1
50c5961a-749b-11df-b112-00215aee3ebe;Домантове;Домантово;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121583901;3;1
50c5961b-749b-11df-b112-00215aee3ebe;Домаха;Домаха;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223584502;3;1
50c5961c-749b-11df-b112-00215aee3ebe;Домаха;Домаха;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6311090101;3;1
50c5961d-749b-11df-b112-00215aee3ebe;Домашин;Домашин;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120884802;3;1
50c5961e-749b-11df-b112-00215aee3ebe;Домашів;Домашов;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721883102;3;1
50c5961f-749b-11df-b112-00215aee3ebe;Домашів;Домашов;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624882001;3;1
50c59620-749b-11df-b112-00215aee3ebe;Домашлин;Домашлин;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422482501;3;1
50c59621-749b-11df-b112-00215aee3ebe;Домбоки;Домбоки;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122786805;3;1
50c59622-749b-11df-b112-00215aee3ebe;Домниця;Домница;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122983003;3;1
50c59623-749b-11df-b112-00215aee3ebe;Домниця;Домница;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423086703;3;1
50c59624-749b-11df-b112-00215aee3ebe;Домолоч;Домолочь;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386602;3;1
50c59626-749b-11df-b112-00215aee3ebe;Домоткань;Домоткань;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221085504;3;1
50c59627-749b-11df-b112-00215aee3ebe;Донець;Донец;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321755603;3;1
8716268c-749b-11df-b112-00215aee3ebe;Донець (Червоний Донець);Донец (Червоный Донец);село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320256000;2;1
50c5962a-749b-11df-b112-00215aee3ebe;Донецьке;Донецкое;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322887003;3;1
50c5962d-749b-11df-b112-00215aee3ebe;Донська Балка;Донская Балка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121285105;3;1
50c5962e-749b-11df-b112-00215aee3ebe;Донське;Донское;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121285107;3;1
50c59632-749b-11df-b112-00215aee3ebe;Донцівщина;Донцовщина;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320486902;3;1
50c59633-749b-11df-b112-00215aee3ebe;Дончине;Дончино;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520883202;3;1
50c59634-749b-11df-b112-00215aee3ebe;Доробратово;Доробратово;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121983201;3;1
50c59635-749b-11df-b112-00215aee3ebe;Дороганівка;Дорогановка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055109;3;1
50c59636-749b-11df-b112-00215aee3ebe;Дорогань;Дорогань;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820655901;3;1
50c59637-749b-11df-b112-00215aee3ebe;Дороге;Дорогое;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221486204;3;0
50c59638-749b-11df-b112-00215aee3ebe;Дороге;Дорогое;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224881502;3;1
50c59639-749b-11df-b112-00215aee3ebe;Дорогиничі;Дорогиничи;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722481801;3;1
50c5963a-749b-11df-b112-00215aee3ebe;Дорогинка;Дорогинка;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224983301;3;1
50c5963b-749b-11df-b112-00215aee3ebe;Дорогинка;Дорогинка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421784001;3;1
50c5963c-749b-11df-b112-00215aee3ebe;Дорогичівка;Дорогичевка;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122083001;3;1
50c5963d-749b-11df-b112-00215aee3ebe;Дорогів;Дорогов;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621282601;3;1
50c5963e-749b-11df-b112-00215aee3ebe;Дорогобуж;Дорогобуж;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;Область 20;3;1
50c5963f-749b-11df-b112-00215aee3ebe;Дорогунь;Дорогунь;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825084203;3;1
50c59640-749b-11df-b112-00215aee3ebe;Дорожинка;Дорожинка;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524381001;3;1
50c59644-749b-11df-b112-00215aee3ebe;Дорожне;Дорожное;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520681803;3;1
50c59646-749b-11df-b112-00215aee3ebe;Доросині;Доросини;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724581901;3;1
50c59647-749b-11df-b112-00215aee3ebe;Доротище;Доротище;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722181801;3;1
50c59649-749b-11df-b112-00215aee3ebe;Дорофіївка;Дорофеевка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124681801;3;1
50c5964a-749b-11df-b112-00215aee3ebe;Дорофіївка;Дорофеевка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321287503;3;1
50c5964b-749b-11df-b112-00215aee3ebe;Дорофіївка;Дорофеевка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523180304;3;1
50c5964c-749b-11df-b112-00215aee3ebe;Дорошенкове;Дорошенково;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925680805;3;1
50c5964f-749b-11df-b112-00215aee3ebe;Дорошівка;Дорошовка;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925680801;3;1
50c59650-749b-11df-b112-00215aee3ebe;Дорошівка;Дорошовка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923880702;3;1
50c59651-749b-11df-b112-00215aee3ebe;Дорошівка;Дорошовка;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822082601;3;1
50c59653-749b-11df-b112-00215aee3ebe;Дорошівка;Дорошовка;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525680803;3;1
50c59654-749b-11df-b112-00215aee3ebe;Дорошівці;Дорошовцы;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321584401;3;1
50c59655-749b-11df-b112-00215aee3ebe;Досин;Досин;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823980303;3;1
50c59656-749b-11df-b112-00215aee3ebe;Дослідне;Дослидное;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221486205;1;0
50c59657-749b-11df-b112-00215aee3ebe;Дослідне;Дослидное;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323310101;3;1
50c59658-749b-11df-b112-00215aee3ebe;Дослідне;Дослидное;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325456702;3;1
50c59659-749b-11df-b112-00215aee3ebe;Дослідне;Дослидное;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121080704;3;1
50c5965a-749b-11df-b112-00215aee3ebe;Дослідне;Дослидное;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520385803;3;1
50c5965b-749b-11df-b112-00215aee3ebe;Дослідне;Дослидное;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823186403;3;1
50c5965c-749b-11df-b112-00215aee3ebe;Дослідне;Дослидное;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423887002;3;1
50c5965d-749b-11df-b112-00215aee3ebe;Дослідницьке;Дослидницкое;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221455600;3;1
50c5965e-749b-11df-b112-00215aee3ebe;Дохно;Дохно;село;78f453a8-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0525081302;3;1
50c59660-749b-11df-b112-00215aee3ebe;Доценківка;Доценковка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924787302;3;1
50c59661-749b-11df-b112-00215aee3ebe;Дошне;Дошно;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724280402;3;1
50c59662-749b-11df-b112-00215aee3ebe;Доярня;Доярня;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521985505;3;1
50c59663-749b-11df-b112-00215aee3ebe;Драбинівка;Драбиновка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323481001;3;1
50c59664-749b-11df-b112-00215aee3ebe;Драбів;Драбов;місто;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120655100;3;1
50c59665-749b-11df-b112-00215aee3ebe;Драбівка;Драбовка;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122582001;3;1
50c59666-749b-11df-b112-00215aee3ebe;Драбівці;Драбовцы;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121584301;3;1
50c59667-749b-11df-b112-00215aee3ebe;Драбово-Барятинське;Драбово-Барятинское;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120683601;3;1
50c59668-749b-11df-b112-00215aee3ebe;Драганівка;Драгановка;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125282801;2;1
50c59669-749b-11df-b112-00215aee3ebe;Драганівка;Драгановка;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825283802;2;1
50c5966a-749b-11df-b112-00215aee3ebe;Драгасимів;Драгасимов;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625282602;3;1
50c5966c-749b-11df-b112-00215aee3ebe;Драгиня;Драгиня;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122782803;2;1
50c5966b-749b-11df-b112-00215aee3ebe;Драгівка;Драговка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655101;3;1
50c5966d-749b-11df-b112-00215aee3ebe;Драгово;Драгово;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125382801;3;1
50c5966e-749b-11df-b112-00215aee3ebe;Драгоманівка;Драгомановка;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123085602;3;1
50c59670-749b-11df-b112-00215aee3ebe;Драгомирчани;Драгомирчаны;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625880901;3;0
50c59671-749b-11df-b112-00215aee3ebe;Драни;Драны;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321380702;3;1
50c59672-749b-11df-b112-00215aee3ebe;Драниця;Драница;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323082001;3;1
50c59673-749b-11df-b112-00215aee3ebe;Дранка;Дранка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524380801;3;1
50c59674-749b-11df-b112-00215aee3ebe;Драчино;Драчино;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124010101;3;1
50c59675-749b-11df-b112-00215aee3ebe;Драчинці;Драчинцы;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322583001;3;1
50c59676-749b-11df-b112-00215aee3ebe;Драчі;Драчи;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;;3;1
50c59677-749b-11df-b112-00215aee3ebe;Драчі;Драчи;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824286704;3;1
50c59678-749b-11df-b112-00215aee3ebe;Драчівщина;Драчевщина;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425588303;3;1
50c59679-749b-11df-b112-00215aee3ebe;Древині;Древини;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721155103;3;1
50c5967a-749b-11df-b112-00215aee3ebe;Дремлюги;Дремлюги;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922981204;3;1
50c5967b-749b-11df-b112-00215aee3ebe;Дремове;Дремово;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923581306;3;1
50c5967c-749b-11df-b112-00215aee3ebe;Дреники;Дреники;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825887703;3;1
50c5967d-749b-11df-b112-00215aee3ebe;Дриглів;Дрыглов;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825882001;3;1
50c5967e-749b-11df-b112-00215aee3ebe;Дрижина Гребля;Дрижина Гребля;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321881801;3;1
50c5967f-749b-11df-b112-00215aee3ebe;Дрібці;Дробцы;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422286503;3;1
50c59680-749b-11df-b112-00215aee3ebe;Дрімайлівка;Дремайловка;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422783501;3;1
50c59682-749b-11df-b112-00215aee3ebe;Дробишів;Дробышев;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423685003;3;1
50c59683-749b-11df-b112-00215aee3ebe;Дрогобич;Дрогобыч;місто;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4610600000;1;0
50c59684-749b-11df-b112-00215aee3ebe;Дроговиж;Дроговиж;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623082001;3;1
50c59685-749b-11df-b112-00215aee3ebe;Дрогомишль;Дрогомышль;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625883901;3;1
50c59686-749b-11df-b112-00215aee3ebe;Дрозди;Дрозды;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220481301;3;1
0cc9f43b-e0f7-11e8-80d7-1c98ec135261;Дрозди;Дрозды;село;00000000-0000-0000-0000-000000000000;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224286103;3;1
50c59687-749b-11df-b112-00215aee3ebe;Дроздинь;Дроздынь;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625086802;3;1
50c59688-749b-11df-b112-00215aee3ebe;Дроздів;Дроздов;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621282101;3;1
50c59689-749b-11df-b112-00215aee3ebe;Дроздівка;Дроздовка;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422784001;3;1
50c5968a-749b-11df-b112-00215aee3ebe;Дроздні;Дроздни;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722182001;3;1
50c5968b-749b-11df-b112-00215aee3ebe;Дроздовиця;Дроздовица;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421484001;3;1
50c5968c-749b-11df-b112-00215aee3ebe;Дроздовичі;Дроздовичи;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620981803;3;1
50c5968d-749b-11df-b112-00215aee3ebe;Дроздовичі;Дроздовичи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625182601;3;1
50c5968f-749b-11df-b112-00215aee3ebe;Дротинці;Дротинцы;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121282101;3;1
50c59691-749b-11df-b112-00215aee3ebe;Дроховичі;Дроховичи;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621589104;3;1
50c59692-749b-11df-b112-00215aee3ebe;Дружба;Дружба;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324285603;3;1
50c59693-749b-11df-b112-00215aee3ebe;Дружба;Дружба;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925610300;3;1
50c59694-749b-11df-b112-00215aee3ebe;Дружба;Дружба;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921280502;3;1
50c59695-749b-11df-b112-00215aee3ebe;Дружба;Дружба;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125055300;3;1
50c59696-749b-11df-b112-00215aee3ebe;Дружба;Дружба;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055110;3;1
50c59697-749b-11df-b112-00215aee3ebe;Дружба;Дружба;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221887703;3;1
50c59698-749b-11df-b112-00215aee3ebe;Дружба;Дружба;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222982805;3;1
50c59699-749b-11df-b112-00215aee3ebe;Дружба;Дружба;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123986403;3;1
50c5969d-749b-11df-b112-00215aee3ebe;Дружба;Дружба;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221682003;3;1
50c5969e-749b-11df-b112-00215aee3ebe;Дружба;Дружба;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824455400;3;1
50c5969f-749b-11df-b112-00215aee3ebe;Дружба;Дружба;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522885902;3;1
50c596a0-749b-11df-b112-00215aee3ebe;Дружба;Дружба;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522882303;3;1
50c596a1-749b-11df-b112-00215aee3ebe;Дружба;Дружба;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820685003;3;1
50c596a2-749b-11df-b112-00215aee3ebe;Дружба;Дружба;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625882501;3;1
f9333456-db4e-11df-9197-00215aee3ebe;Дружба;Дружба;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421755300;3;1
62c3d155-749b-11df-b112-00215aee3ebe;Дружба ( Ленінське );Ленинское;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323381204;3;1
50c596a4-749b-11df-b112-00215aee3ebe;Дружбівка;Дружбовка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822381403;3;1
50c596a5-749b-11df-b112-00215aee3ebe;Дружелюбівка;Дружелюбовка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055401;3;1
50c596a6-749b-11df-b112-00215aee3ebe;Дружелюбівка;Дружелюбовка;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321082004;3;1
50c596a7-749b-11df-b112-00215aee3ebe;Дружелюбівка;Дружелюбовка;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120285003;3;1
50c596a8-749b-11df-b112-00215aee3ebe;Дружелюбівка;Дружелюбовка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125255407;3;1
50c596a9-749b-11df-b112-00215aee3ebe;Дружелюбівка;Дружелюбовка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123555102;3;1
50c596aa-749b-11df-b112-00215aee3ebe;Дружелюбівка;Дружелюбовка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321581401;3;1
50c596ac-749b-11df-b112-00215aee3ebe;Дружелюбівка;Дружелюбовка;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521681603;3;1
50c596ad-749b-11df-b112-00215aee3ebe;Дружелюбівка;Дружелюбовка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521781901;3;1
e0517edc-db89-11df-9197-00215aee3ebe;Дружелюбівка;Дружелюбовка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823082602;3;1
50c596ae-749b-11df-b112-00215aee3ebe;Дружківка;Дружковка;місто;d00d3b68-41b9-11df-907f-00215aee3ebe;d15e301c-60b0-11de-be1e-0030485903e8;572fe7f2-5e88-11ee-80c0-000c2961d091;1411700000;2;0
50c596af-749b-11df-b112-00215aee3ebe;Дружне;Дружное;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983204;3;1
50c596b0-749b-11df-b112-00215aee3ebe;Дружне;Дружное;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955406;3;1
50c596b6-749b-11df-b112-00215aee3ebe;Дружне;Дружное;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823486202;3;1
50c596b7-749b-11df-b112-00215aee3ebe;Дружне;Дружное;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521681803;3;1
50c596b8-749b-11df-b112-00215aee3ebe;Дружне;Дружное;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621284407;3;1
50c596b9-749b-11df-b112-00215aee3ebe;Дружнє;Дружное;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;1823486202;3;1
50c596ba-749b-11df-b112-00215aee3ebe;Дружня;Дружная;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221081001;3;1
50c596bb-749b-11df-b112-00215aee3ebe;Друхів;Друхов;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620484801;3;1
50c596bc-749b-11df-b112-00215aee3ebe;Друцьке;Друцкое;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425584302;3;1
50c596bd-749b-11df-b112-00215aee3ebe;Дрюківщина;Дрюковщина;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322682802;3;1
50c596c2-749b-11df-b112-00215aee3ebe;Дуб'є;Дубье;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620388802;3;1
50c59736-749b-11df-b112-00215aee3ebe;Дуб'яги;Дубяги;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321385003;3;1
50c59737-749b-11df-b112-00215aee3ebe;Дуб'янка;Дубянка;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623655501;3;1
50c59738-749b-11df-b112-00215aee3ebe;Дуб'янщина;Дубянщина;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321383805;3;1
50c596be-749b-11df-b112-00215aee3ebe;Дуба;Дуба;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624881301;3;1
50c596bf-749b-11df-b112-00215aee3ebe;Дубаневичі;Дубаневичи;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620983401;3;1
50c596c0-749b-11df-b112-00215aee3ebe;Дубенки;Дубенки;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124284001;3;1
50c596c1-749b-11df-b112-00215aee3ebe;Дубечне;Дубечно;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725081301;3;1
50c596c3-749b-11df-b112-00215aee3ebe;Дуби;Дубы;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121983602;3;1
50c596c4-749b-11df-b112-00215aee3ebe;Дуби;Дубы;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287303;3;1
50c596c5-749b-11df-b112-00215aee3ebe;Дуби;Дубы;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624689807;3;1
50c596c6-749b-11df-b112-00215aee3ebe;Дубина;Дубина;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323485502;3;1
50c596c7-749b-11df-b112-00215aee3ebe;Дубина;Дубина;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924189602;3;1
50c596c8-749b-11df-b112-00215aee3ebe;Дубина;Дубина;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620388803;3;1
50c596c9-749b-11df-b112-00215aee3ebe;Дубина;Дубина;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624555301;3;1
50c596ca-749b-11df-b112-00215aee3ebe;Дубина;Дубина;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224610107;3;1
50c596cb-749b-11df-b112-00215aee3ebe;Дубина;Дубина;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122885003;3;1
50c596cc-749b-11df-b112-00215aee3ebe;Дубина;Дубина;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521455501;3;1
50c596cd-749b-11df-b112-00215aee3ebe;Дубина;Дубина;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523782602;3;1
50c596ce-749b-11df-b112-00215aee3ebe;Дубина;Дубина;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524989005;3;1
50c596cf-749b-11df-b112-00215aee3ebe;Дубина;Дубина;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524180602;3;1
50c596d0-749b-11df-b112-00215aee3ebe;Дубина;Дубина;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822789202;3;1
50c596d1-749b-11df-b112-00215aee3ebe;Дубина;Дубина;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824288902;3;1
cdc44f35-dde8-11df-9197-00215aee3ebe;Дубина;Дубина;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524583403;3;1
7ad94e39-749b-11df-b112-00215aee3ebe;Дубина (Пролетар);Дубина (Пролетар);село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524884202;3;1
50c596d3-749b-11df-b112-00215aee3ebe;Дубини;Дубины;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623983803;3;1
50c596d4-749b-11df-b112-00215aee3ebe;Дубини;Дубины;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625883903;3;1
50c596d5-749b-11df-b112-00215aee3ebe;Дубини;Дубины;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624288005;3;1
50c596d6-749b-11df-b112-00215aee3ebe;Дубинка;Дубинка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922081902;3;1
50c596d7-749b-11df-b112-00215aee3ebe;Дубинка;Дубинка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822483103;3;1
50c596d8-749b-11df-b112-00215aee3ebe;Дубинка;Дубинка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821887603;3;1
50c596d9-749b-11df-b112-00215aee3ebe;Дубинки;Дубинки;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484602;3;1
50c596da-749b-11df-b112-00215aee3ebe;Дубинки;Дубинки;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525386305;3;1
50c596d2-749b-11df-b112-00215aee3ebe;Дубино;Дубино;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122786203;3;1
50c596db-749b-11df-b112-00215aee3ebe;Дубинове;Дубиново;село;6b07f835-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5124380901;3;1
50c596dc-749b-11df-b112-00215aee3ebe;Дубичанське;Дубичанское;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722886202;3;1
50c596dd-749b-11df-b112-00215aee3ebe;Дубище;Дубище;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724555300;3;1
50c596de-749b-11df-b112-00215aee3ebe;Дубище;Дубище;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721882401;3;1
50c596df-749b-11df-b112-00215aee3ebe;Дубище;Дубище;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825882401;3;1
50c596e0-749b-11df-b112-00215aee3ebe;Дубище;Дубище;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822782102;3;1
50c596e1-749b-11df-b112-00215aee3ebe;Дубівка;Дубовка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321310105;3;1
50c596e2-749b-11df-b112-00215aee3ebe;Дубівка;Дубовка;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120881202;3;1
50c596e3-749b-11df-b112-00215aee3ebe;Дубівка;Дубовка;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322082505;3;1
50c596e8-749b-11df-b112-00215aee3ebe;Дубівка;Дубовка;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224481301;3;1
50c596e9-749b-11df-b112-00215aee3ebe;Дубівка;Дубовка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824083802;3;1
50c596eb-749b-11df-b112-00215aee3ebe;Дубівка;Дубовка;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524185302;3;1
50c596ec-749b-11df-b112-00215aee3ebe;Дубівка;Дубовка;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523484703;3;1
50c596ed-749b-11df-b112-00215aee3ebe;Дубівка;Дубовка;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825285402;2;1
50c596ee-749b-11df-b112-00215aee3ebe;Дубівка;Дубовка;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620884902;3;1
cdc44f4f-dde8-11df-9197-00215aee3ebe;Дубівка;Дубовка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820883002;3;1
6ed81f42-749b-11df-b112-00215aee3ebe;Дубівка (Опришени);Дубовка (Опрышены);село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321081801;3;1
50c596f0-749b-11df-b112-00215aee3ebe;Дубівці;Дубовцы;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125283101;3;1
50c596f1-749b-11df-b112-00215aee3ebe;Дубівці;Дубовцы;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322583201;3;1
50c596f2-749b-11df-b112-00215aee3ebe;Дубівці;Дубовцы;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621282901;3;1
50c596f3-749b-11df-b112-00215aee3ebe;Дубіївка;Дубиевка;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124983001;3;1
50c596f4-749b-11df-b112-00215aee3ebe;Дубіївка;Дубиевка;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825582603;3;1
50c596f5-749b-11df-b112-00215aee3ebe;Дубка;Дубка;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621680803;3;1
50c596f6-749b-11df-b112-00215aee3ebe;Дубки;Дубки;село;6b07f835-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5124381503;3;1
50c596f8-749b-11df-b112-00215aee3ebe;Дубки;Дубки;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625484807;3;1
50c596f9-749b-11df-b112-00215aee3ebe;Дублинки;Дублинки;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824084602;3;1
50c596fa-749b-11df-b112-00215aee3ebe;Дубляни;Дубляны;місто;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622710200;2;0
50c596fb-749b-11df-b112-00215aee3ebe;Дубляни;Дубляны;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624255300;3;1
50c596fc-749b-11df-b112-00215aee3ebe;Дубляни;Дубляны;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621455303;3;1
50c596fd-749b-11df-b112-00215aee3ebe;Дублянка;Дублянка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323580305;3;1
50c596fe-749b-11df-b112-00215aee3ebe;Дубмаслівка;Дубмасловка;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523010105;3;1
50c596ff-749b-11df-b112-00215aee3ebe;Дубники;Дубники;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824055604;3;1
50c59700-749b-11df-b112-00215aee3ebe;Дубно;Дубно;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625082602;3;1
50c59701-749b-11df-b112-00215aee3ebe;Дубно;Дубно;місто;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5610300000;2;0
50c59702-749b-11df-b112-00215aee3ebe;Дубняки;Дубняки;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625488802;3;1
50c59727-749b-11df-b112-00215aee3ebe;Дубо - Осокорівка;Дубо-Осокоровка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224880506;3;1
50c59725-749b-11df-b112-00215aee3ebe;Дубов'язівка;Дубовязовка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922055300;3;1
50c59704-749b-11df-b112-00215aee3ebe;Дубова;Дубовая;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124383101;3;1
50c59705-749b-11df-b112-00215aee3ebe;Дубова;Дубовая;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521080803;3;1
50c59706-749b-11df-b112-00215aee3ebe;Дубова;Дубовая;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824481002;3;1
50c596ea-749b-11df-b112-00215aee3ebe;Дубова;Дубовая;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820884603;3;1
a84b1d46-ec10-11e5-88dd-001ec93df11f;Дубова;Дубовая;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223581505;3;1
50c59707-749b-11df-b112-00215aee3ebe;Дубова Корчма;Дубовая Корчма;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720887803;3;1
50c59708-749b-11df-b112-00215aee3ebe;Дубове;Дубовое;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324880305;3;1
50c59709-749b-11df-b112-00215aee3ebe;Дубове;Дубовое;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722182401;3;0
50c5970a-749b-11df-b112-00215aee3ebe;Дубове;Дубовое;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655102;3;1
50c5970b-749b-11df-b112-00215aee3ebe;Дубове;Дубовое;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225982505;3;1
50c5970c-749b-11df-b112-00215aee3ebe;Дубове;Дубовое;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221086605;3;1
50c5970d-749b-11df-b112-00215aee3ebe;Дубове;Дубовое;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224886404;3;1
50c5970e-749b-11df-b112-00215aee3ebe;Дубове;Дубовое;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324586502;3;1
50c5970f-749b-11df-b112-00215aee3ebe;Дубове;Дубовое;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320686004;3;1
50c59710-749b-11df-b112-00215aee3ebe;Дубове;Дубовое;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123181701;3;1
50c59713-749b-11df-b112-00215aee3ebe;Дубове;Дубовое;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124455600;2;1
44ac842d-749b-11df-b112-00215aee3ebe;Дубове /Більшовик;Большевик;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220288702;3;1
50c59715-749b-11df-b112-00215aee3ebe;Дубове, станція;Дубовое,станция;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320686004;3;1
50c59716-749b-11df-b112-00215aee3ebe;Дубовець;Дубовец;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822080304;3;1
50c59717-749b-11df-b112-00215aee3ebe;Дубовець;Дубовец;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523084602;3;1
50c59718-749b-11df-b112-00215aee3ebe;Дубовий Гай;Дубовый Гай;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323684002;3;1
50c59719-749b-11df-b112-00215aee3ebe;Дубовий Гай;Дубовый Гай;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824283007;3;1
50c5971a-749b-11df-b112-00215aee3ebe;Дубовий Гай;Дубовый Гай;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424182501;3;1
50c5971b-749b-11df-b112-00215aee3ebe;Дубовий Гай;Дубовый Гай;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823685503;3;1
50c5971c-749b-11df-b112-00215aee3ebe;Дубовик;Дубовик;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825084204;3;1
50c5971e-749b-11df-b112-00215aee3ebe;Дубовики;Дубовики;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220786604;3;1
50c5971f-749b-11df-b112-00215aee3ebe;Дубовиця;Дубовица;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622855302;3;1
50c59720-749b-11df-b112-00215aee3ebe;Дубовиця;Дубовица;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621688503;3;1
50c59721-749b-11df-b112-00215aee3ebe;Дубовичі;Дубовичи;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922683401;3;1
50c59722-749b-11df-b112-00215aee3ebe;Дубові Гряди;Дубовые Гряды;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324882501;3;1
50c59723-749b-11df-b112-00215aee3ebe;Дубові Махаринці;Дубовые Махаринцы;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521481603;3;1
50c59726-749b-11df-b112-00215aee3ebe;Дуболугівка;Дуболуговка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423384001;3;1
50c59728-749b-11df-b112-00215aee3ebe;Дубравка;Дубравка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222355101;3;1
50c59729-749b-11df-b112-00215aee3ebe;Дубравка;Дубравка;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621589202;3;1
50c5972a-749b-11df-b112-00215aee3ebe;Дубриничі;Дубриничи;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123281001;3;1
50c5972b-749b-11df-b112-00215aee3ebe;Дубрівка;Дубровка;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785205;3;1
50c5972c-749b-11df-b112-00215aee3ebe;Дубрівка;Дубровка;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624287904;3;1
50c5972d-749b-11df-b112-00215aee3ebe;Дубрівка;Дубровка;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124882401;3;1
50c5972e-749b-11df-b112-00215aee3ebe;Дубрівка;Дубровка;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820681801;3;1
50c59464-749b-11df-b112-00215aee3ebe;Дубрівка;Дубровка;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621685603;3;1
d611d849-2670-11e7-80fd-1c98ec135263;Дубрівка;Дубровка;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121982801;3;1
50c5972f-749b-11df-b112-00215aee3ebe;Дуброва;Дуброва;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623088407;3;1
50c59730-749b-11df-b112-00215aee3ebe;Дубровине;Дубровино;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521980802;3;1
50c59731-749b-11df-b112-00215aee3ebe;Дубровиця;Дубровица;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721482202;3;1
50c59732-749b-11df-b112-00215aee3ebe;Дубровиця;Дубровица;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625885204;3;1
50c59733-749b-11df-b112-00215aee3ebe;Дубровиця;Дубровица;місто;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621810100;2;0
50c59734-749b-11df-b112-00215aee3ebe;Дубчиці;Дубчицы;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622286202;3;1
50c59735-749b-11df-b112-00215aee3ebe;Дубшари;Дубшары;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624881302;3;1
50c59739-749b-11df-b112-00215aee3ebe;Дуванка;Дуванка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322685502;3;1
50c5973a-749b-11df-b112-00215aee3ebe;Дуванка;Дуванка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325786002;3;1
50c5973c-749b-11df-b112-00215aee3ebe;Дуга;Дуга;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821782403;3;1
50c5973d-749b-11df-b112-00215aee3ebe;Дударі;Дудари;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222981503;3;1
50c5973e-749b-11df-b112-00215aee3ebe;Дударків;Дударков;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220883601;2;0
50c5973f-749b-11df-b112-00215aee3ebe;Дудин;Дудин;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620386203;3;1
50c59740-749b-11df-b112-00215aee3ebe;Дудівка;Дудовка;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322283002;3;1
50c59741-749b-11df-b112-00215aee3ebe;Дудки;Дудки;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221880402;3;1
50c59742-749b-11df-b112-00215aee3ebe;Дудкин Гай;Дудкин Гай;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323481803;3;1
50c59743-749b-11df-b112-00215aee3ebe;Дудківка;Дудковка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222382504;3;1
50c59744-749b-11df-b112-00215aee3ebe;Дудківка;Дудковка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321786004;3;1
50c59745-749b-11df-b112-00215aee3ebe;Дудникове;Дудниково;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324082703;3;1
50c59746-749b-11df-b112-00215aee3ebe;Дудникове;Дудниково;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323683505;3;1
50c59747-749b-11df-b112-00215aee3ebe;Дудникове;Дудниково;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955407;3;1
50c59748-749b-11df-b112-00215aee3ebe;Дудчани;Дудчаны;село;78f4540d-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6524181501;3;1
50c59749-749b-11df-b112-00215aee3ebe;Дудченки;Дудченки;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920682202;3;1
cdc44f34-dde8-11df-9197-00215aee3ebe;Дукля;Дукля;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523786403;3;1
50c5974b-749b-11df-b112-00215aee3ebe;Дулицьке;Дулицкое;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224082101;2;1
50c5974c-749b-11df-b112-00215aee3ebe;Дуліби;Дулибы;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725581001;3;1
50c5974d-749b-11df-b112-00215aee3ebe;Дуліби;Дулибы;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121282201;3;1
50c5974e-749b-11df-b112-00215aee3ebe;Дуліби;Дулибы;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621587203;3;1
50c5974f-749b-11df-b112-00215aee3ebe;Дуліби;Дулибы;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625382001;3;1
50c59750-749b-11df-b112-00215aee3ebe;Дуліби;Дулибы;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621282301;3;1
50c59751-749b-11df-b112-00215aee3ebe;Дулово;Дулово;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124482601;3;1
50c59752-749b-11df-b112-00215aee3ebe;Дуляби;Дулябы;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120485102;3;1
50c59753-749b-11df-b112-00215aee3ebe;Думанів;Думанов;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822482501;3;1
50c59754-749b-11df-b112-00215aee3ebe;Думанці;Думанцы;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124983501;3;1
50c59755-749b-11df-b112-00215aee3ebe;Думени;Думены;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323082802;3;1
50c59756-749b-11df-b112-00215aee3ebe;Думенки;Думенки;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524884504;3;1
50c59757-749b-11df-b112-00215aee3ebe;Думи;Думы;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622786704;3;1
50c59758-749b-11df-b112-00215aee3ebe;Думинське;Думинское;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824286704;3;1
50c59759-749b-11df-b112-00215aee3ebe;Думичі;Думичи;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787403;3;1
50c5975a-749b-11df-b112-00215aee3ebe;Думівка;Думовка;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922385404;3;1
50c5975b-749b-11df-b112-00215aee3ebe;Думниця;Думница;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620682302;3;1
50c5975c-749b-11df-b112-00215aee3ebe;Дунаєць;Дунаец;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921582301;3;1
50c5975d-749b-11df-b112-00215aee3ebe;Дунаєць;Дунаец;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621585603;3;1
50c5975e-749b-11df-b112-00215aee3ebe;Дунаїв;Дунаев;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123482301;3;1
50c5975f-749b-11df-b112-00215aee3ebe;Дунаїв;Дунаев;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623382901;3;1
50c59761-749b-11df-b112-00215aee3ebe;Дунаївці;Дунаевцы;місто;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821855300;2;0
56bdd0fc-749b-11df-b112-00215aee3ebe;Дунаївці селище;Дунаевцы поселок;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821855300;2;1
56bdd0fd-749b-11df-b112-00215aee3ebe;Дунайка;Дунайка;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224086202;3;1
56bdd0fe-749b-11df-b112-00215aee3ebe;Дунайське;Дунайское;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122086302;3;1
56bdd0ff-749b-11df-b112-00215aee3ebe;Дунине;Дунино;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684805;3;1
56bdd100-749b-11df-b112-00215aee3ebe;Дунинівка;Дуниновка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;;3;1
56bdd101-749b-11df-b112-00215aee3ebe;Дунів;Дунев;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122083301;3;1
56bdd102-749b-11df-b112-00215aee3ebe;Дунів;Дунев;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620684002;3;1
56bdd103-749b-11df-b112-00215aee3ebe;Дунковиця;Дунковица;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121980402;3;1
56bdd104-749b-11df-b112-00215aee3ebe;Дуплинська;Дуплинское;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122083530;3;1
56bdd105-749b-11df-b112-00215aee3ebe;Дурбайли;Дурбайлы;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121683003;3;1
56bdd106-749b-11df-b112-00215aee3ebe;Дусанів;Дусанов;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623383201;3;1
56bdd107-749b-11df-b112-00215aee3ebe;Дусино;Дусино;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124082301;3;1
56bdd108-749b-11df-b112-00215aee3ebe;Духанівка;Духановка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922083001;3;1
56bdd109-749b-11df-b112-00215aee3ebe;Духанки;Духанки;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424483202;3;1
56bdd10a-749b-11df-b112-00215aee3ebe;Духів;Духов;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123482002;3;1
56bdd10b-749b-11df-b112-00215aee3ebe;Духове;Духово;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322881801;3;1
56bdd10c-749b-11df-b112-00215aee3ebe;Духовниче;Духовничее;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920385402;3;1
56bdd10d-749b-11df-b112-00215aee3ebe;Духче;Духче;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724586202;3;1
56bdd10e-749b-11df-b112-00215aee3ebe;Дучинці;Дучинцы;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320483004;3;1
56bdd10f-749b-11df-b112-00215aee3ebe;Дюксин;Дюксин;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623482001;3;1
56bdd110-749b-11df-b112-00215aee3ebe;Дюла;Дюла;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121282301;3;1
700b6eef-eea8-11e2-8ad9-003048d2b473;Дюла (Юлівці);Дюла (Юливцы);село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;a73a04a6-0835-11e4-a577-003048d2b473;2121282301;;1
56bdd111-749b-11df-b112-00215aee3ebe;Дягова;Дягова;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423083501;3;1
56bdd112-749b-11df-b112-00215aee3ebe;Дядьковичі;Дядьковичи;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621687003;3;1
56bdd113-749b-11df-b112-00215aee3ebe;Дядьковичі;Дядьковичи;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624684101;3;1
56bdd114-749b-11df-b112-00215aee3ebe;Дяків;Дьяков;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823982701;3;1
56bdd115-749b-11df-b112-00215aee3ebe;Дяківка;Дьяковка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920983201;3;1
56bdd116-749b-11df-b112-00215aee3ebe;Дяківка;Дьяковка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520481803;3;1
56bdd117-749b-11df-b112-00215aee3ebe;Дяківці;Дьяковцы;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320785002;3;1
56bdd118-749b-11df-b112-00215aee3ebe;Дяківці;Дьяковцы;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522482601;3;1
56bdd119-749b-11df-b112-00215aee3ebe;Дякове;Дьяково;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;;3;1
56bdd11b-749b-11df-b112-00215aee3ebe;Дятилівка;Дятиловка;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823986502;3;1
56bdd11d-749b-11df-b112-00215aee3ebe;Дяченки;Дьяченки;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322083002;3;1
56bdd11e-749b-11df-b112-00215aee3ebe;Дячківка;Дьячковка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324285502;3;1
56bdd11f-749b-11df-b112-00215aee3ebe;Дячкове;Дьячково;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321085802;3;1
56bdd120-749b-11df-b112-00215aee3ebe;Елеваторне;Элеваторное;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820955109;3;1
56bdd122-749b-11df-b112-00215aee3ebe;Енергетиків;Энергетиков;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320681505;3;1
56bdd123-749b-11df-b112-00215aee3ebe;Енергодар;Энергодар;місто;4ffc4c15-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;ca28e095-db0e-11eb-80d5-000c29800ae7;2312500000;2;1
56bdd124-749b-11df-b112-00215aee3ebe;Есень;Есень;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124888001;3;1
56bdd125-749b-11df-b112-00215aee3ebe;Есмань;Эсмань;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921583702;3;1
87162647-749b-11df-b112-00215aee3ebe;Есмань (Червоне);Есмань (Червоное);село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921555500;3;1
56bdd126-749b-11df-b112-00215aee3ebe;Есхар;Эсхар;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325456800;3;1
56bdd127-749b-11df-b112-00215aee3ebe;Євгенівка;Евгеновка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825781203;3;1
56bdd128-749b-11df-b112-00215aee3ebe;Євгенівка;Евгеновка;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124783001;3;1
56bdd12b-749b-11df-b112-00215aee3ebe;Євгенівка;Евгеновка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821780303;3;1
56bdd12c-749b-11df-b112-00215aee3ebe;Євдокіївка;Евдокиевка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222387403;3;1
56bdd12d-749b-11df-b112-00215aee3ebe;Євецько-Миколаївка;Евецко-Николаевка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223255601;3;1
56bdd8ae-749b-11df-b112-00215aee3ebe;Євминка;Евминка;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422082701;3;1
56bdd130-749b-11df-b112-00215aee3ebe;Євтодія;Евтодия;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290009;3;1
56bdd131-749b-11df-b112-00215aee3ebe;Єгорине;Егорино;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225685002;3;1
56bdd132-749b-11df-b112-00215aee3ebe;Єгорівка;Егоровка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324588007;3;1
56bdd133-749b-11df-b112-00215aee3ebe;Єгорівка;Егоровка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222088607;3;1
56bdd134-749b-11df-b112-00215aee3ebe;Єгорівка;Егоровка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224284507;3;1
56bdd135-749b-11df-b112-00215aee3ebe;Єгорівка;Егоровка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821482602;3;1
56bdd137-749b-11df-b112-00215aee3ebe;Єгорівка;Егоровка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323781506;3;1
56bdd139-749b-11df-b112-00215aee3ebe;Єгорівка;Егоровка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323781506;3;1
56bdd13a-749b-11df-b112-00215aee3ebe;Єгорівка;Егоровка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123981401;3;1
56bdd13b-749b-11df-b112-00215aee3ebe;Єгорівка;Егоровка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323987806;3;1
56bdd13f-749b-11df-b112-00215aee3ebe;Єгорівка;Егоровка;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422282503;3;1
56bdd140-749b-11df-b112-00215aee3ebe;Єгорівка;Егоровка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424182202;3;1
56bdd141-749b-11df-b112-00215aee3ebe;Єгорівка;Егоровка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524010106;3;1
56bdd143-749b-11df-b112-00215aee3ebe;Єзупіль;Езуполь;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2625855300;3;1
56bdd144-749b-11df-b112-00215aee3ebe;Єкатеринівка;Екатериновка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;3;1
56bdd145-749b-11df-b112-00215aee3ebe;Єланець;Еланец;місто;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823055100;3;1
56bdd146-749b-11df-b112-00215aee3ebe;Єлизаветградка;Елизаветградка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520555400;3;1
56bdd164-749b-11df-b112-00215aee3ebe;Єлизаветин;Елизаветин;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724584604;3;1
56bdd147-749b-11df-b112-00215aee3ebe;Єлизаветівка;Елизаветовка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321084503;3;1
56bdd148-749b-11df-b112-00215aee3ebe;Єлизаветівка;Елизаветовка;село;bad3ac3c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1223780801;2;1
56bdd149-749b-11df-b112-00215aee3ebe;Єлизаветівка;Елизаветовка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821480405;3;1
56bdd14a-749b-11df-b112-00215aee3ebe;Єлизаветівка;Елизаветовка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825782704;3;1
56bdd14b-749b-11df-b112-00215aee3ebe;Єлизаветівка;Елизаветовка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820981204;3;1
56bdd14c-749b-11df-b112-00215aee3ebe;Єлизаветівка;Елизаветовка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323981201;3;1
56bdd14d-749b-11df-b112-00215aee3ebe;Єлизаветівка;Елизаветовка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125255109;3;1
56bdd14e-749b-11df-b112-00215aee3ebe;Єлизаветівка;Елизаветовка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123981403;3;1
56bdd14f-749b-11df-b112-00215aee3ebe;Єлизаветівка;Елизаветовка;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124789003;3;1
56bdd157-749b-11df-b112-00215aee3ebe;Єлизаветівка;Елизаветовка;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224985402;3;1
56bdd158-749b-11df-b112-00215aee3ebe;Єлизаветівка;Елизаветовка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510114;3;1
56bdd159-749b-11df-b112-00215aee3ebe;Єлизаветівка;Елизаветовка;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124981503;3;1
56bdd15b-749b-11df-b112-00215aee3ebe;Єлизаветка;Елизаветка;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122282001;3;1
56bdd15e-749b-11df-b112-00215aee3ebe;Єлизаветпіль;Елизаветполь;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821780304;3;1
56bdd15f-749b-11df-b112-00215aee3ebe;Єлизаветпіль;Елизаветполь;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824782002;3;1
56bdd160-749b-11df-b112-00215aee3ebe;Єлисеєнкове;Елисеенково;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786802;3;1
56bdd162-749b-11df-b112-00215aee3ebe;Єлиховичі;Елиховичи;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621882801;3;1
56bdd163-749b-11df-b112-00215aee3ebe;Єлівка;Елевка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823480809;3;1
56bdd165-749b-11df-b112-00215aee3ebe;Єлізаветпілля;Елизаветполье;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1220387704;3;1
56bdd167-749b-11df-b112-00215aee3ebe;Єліне;Елино;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425881501;3;1
56bdd168-749b-11df-b112-00215aee3ebe;Єльне;Ельное;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625087402;3;1
56bdd169-749b-11df-b112-00215aee3ebe;Ємадикине;Емадыкино;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921555801;3;1
56bdd16a-749b-11df-b112-00215aee3ebe;Ємелівка;Емелевка;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121682702;3;1
56bdd16b-749b-11df-b112-00215aee3ebe;Ємилівка;Емиловка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821181804;3;1
56bdd16c-749b-11df-b112-00215aee3ebe;Ємилівка;Емиловка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521481302;3;1
56bdd16d-749b-11df-b112-00215aee3ebe;Ємилівка вокзал;Емиловка вокзал;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;;3;1
56bdd16e-749b-11df-b112-00215aee3ebe;Ємільчине;Емильчино;місто;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821755100;3;1
56bdd16f-749b-11df-b112-00215aee3ebe;Ємці;Емцы;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;;3;1
56bdd170-749b-11df-b112-00215aee3ebe;Ємці;Емцы;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322010103;3;1
56bdd171-749b-11df-b112-00215aee3ebe;Ємці;Емцы;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824280402;3;1
56bdd172-749b-11df-b112-00215aee3ebe;Ємці;Емцы;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323289602;3;1
56bdd173-749b-11df-b112-00215aee3ebe;Ємчиха;Емчиха;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222981801;3;1
56bdd922-749b-11df-b112-00215aee3ebe;Єнківці;Енковцы;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322880703;3;1
072eea26-24a3-11e5-93c7-003048d2b473;Єнківці (неактуальне);Енковци;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;;3;1
56bdd175-749b-11df-b112-00215aee3ebe;Єньки;Еньки;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324884605;3;1
56bdd176-749b-11df-b112-00215aee3ebe;Єньків;Еньков;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425586602;3;1
56bdd178-749b-11df-b112-00215aee3ebe;Єреміївка;Еремеевка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123981701;3;1
56bdd179-749b-11df-b112-00215aee3ebe;Єристівка;Еристовка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322484802;3;1
56bdd17a-749b-11df-b112-00215aee3ebe;Єрки;Ерки;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122255400;3;1
56bdd17b-749b-11df-b112-00215aee3ebe;Єрки;Ерки;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323289603;3;1
56bdd17c-749b-11df-b112-00215aee3ebe;Єрків;Ерков;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422080203;3;1
56bdd17d-749b-11df-b112-00215aee3ebe;Єрківці;Ерковцы;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223383201;3;1
56bdd17e-749b-11df-b112-00215aee3ebe;Єрківці;Ерковцы;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324885004;3;1
50c5940a-749b-11df-b112-00215aee3ebe;Єрмішкове (Дзержинське);Ермишково (Дзержинское);село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121687030;3;1
56bdd180-749b-11df-b112-00215aee3ebe;Єрмолаївка;Ермолаевка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825484202;3;1
56bdd181-749b-11df-b112-00215aee3ebe;Єрмолівка;Ермоловка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820680801;3;1
56bdd183-749b-11df-b112-00215aee3ebe;Єрчики;Ерчики;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824781801;3;1
56bdd185-749b-11df-b112-00215aee3ebe;Єршов;Ершов;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424781505;3;1
56bdd187-749b-11df-b112-00215aee3ebe;Єфремівка;Ефремовка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824582603;3;1
56bdd188-749b-11df-b112-00215aee3ebe;Єфремівка;Ефремовка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324583501;3;1
56bdd189-749b-11df-b112-00215aee3ebe;Єфросинівка;Ефросиновка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122987405;3;1
56bdd199-749b-11df-b112-00215aee3ebe;Жаб'янка;Жабянка;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122883401;3;1
56bdd18a-749b-11df-b112-00215aee3ebe;Жабелівка;Жабелевка;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520681903;3;1
56bdd18b-749b-11df-b112-00215aee3ebe;Жабинці;Жабинцы;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121681101;3;1
56bdd18c-749b-11df-b112-00215aee3ebe;Жабинці;Жабинцы;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825282701;2;1
56bdd18d-749b-11df-b112-00215aee3ebe;Жабинці;Жабинцы;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823382503;3;1
56bdd18e-749b-11df-b112-00215aee3ebe;Жабиня;Жабыня;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122684502;3;1
56bdd18f-749b-11df-b112-00215aee3ebe;Жабка;Жабка;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721885803;3;1
62c3d49f-749b-11df-b112-00215aee3ebe;Жабки (Луценки);Жабки (Луценки);село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322684001;3;1
56bdd190-749b-11df-b112-00215aee3ebe;Жабкине;Жабкино;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922686303;3;1
56bdd191-749b-11df-b112-00215aee3ebe;Жабокрич;Жабокрич;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521982401;3;1
56bdd192-749b-11df-b112-00215aee3ebe;Жабокричка;Жабокричка;село;78f453a8-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0525083202;3;1
56bdd193-749b-11df-b112-00215aee3ebe;Жабокруки;Жабокруки;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625688202;3;1
56bdd194-749b-11df-b112-00215aee3ebe;Жаботин;Жаботин;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121882001;3;1
56bdd195-749b-11df-b112-00215aee3ebe;Жабоч;Жабоч;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823483602;3;1
56bdd196-749b-11df-b112-00215aee3ebe;Жабче;Жабче;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720881701;3;1
56bdd197-749b-11df-b112-00215aee3ebe;Жабче;Жабче;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386017;3;1
56bdd198-749b-11df-b112-00215aee3ebe;Жабче;Жабче;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824283209;3;1
56bdd19a-749b-11df-b112-00215aee3ebe;Жавинка;Жавинка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425583403;3;1
56bdd19b-749b-11df-b112-00215aee3ebe;Жаврів;Жавров;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621282701;3;1
56bdd19c-749b-11df-b112-00215aee3ebe;Жаглівка;Жагловка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821284202;3;1
56bdd19d-749b-11df-b112-00215aee3ebe;Жадани;Жаданы;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321084505;3;1
56bdd19e-749b-11df-b112-00215aee3ebe;Жадани;Жаданы;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521210105;3;1
56bdd19f-749b-11df-b112-00215aee3ebe;Жаданівка;Жадановка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321786005;3;1
56bdd1a0-749b-11df-b112-00215aee3ebe;Жаданівка;Жадановка;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424786005;3;1
56bdd1a1-749b-11df-b112-00215aee3ebe;Жадень;Жадень;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621882401;3;1
56bdd1a2-749b-11df-b112-00215aee3ebe;Жадківка;Жадковка;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623082201;3;1
56bdd1a3-749b-11df-b112-00215aee3ebe;Жадове;Жадово;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424781501;3;1
56bdd1a4-749b-11df-b112-00215aee3ebe;Жадьки;Жадьки;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825683601;3;1
56bdd1a6-749b-11df-b112-00215aee3ebe;Жакчик;Жакчик;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521181701;3;1
56bdd1a7-749b-11df-b112-00215aee3ebe;Жалибори;Жалиборы;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621255302;3;1
56bdd1a8-749b-11df-b112-00215aee3ebe;Жалин;Жалин;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623487803;3;1
56bdd1a9-749b-11df-b112-00215aee3ebe;Жалківщина;Жалковщина;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921583703;3;1
56bdd1aa-749b-11df-b112-00215aee3ebe;Жалянка;Жалянка;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621286702;3;1
56bdd1ab-749b-11df-b112-00215aee3ebe;Жари;Жары;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923888003;3;1
56bdd1ac-749b-11df-b112-00215aee3ebe;Жари;Жары;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820682001;3;1
56bdd1ad-749b-11df-b112-00215aee3ebe;Жарків;Жарков;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620381602;3;1
56bdd1ae-749b-11df-b112-00215aee3ebe;Жахнівка;Жахновка;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524583201;3;1
56bdd1af-749b-11df-b112-00215aee3ebe;Жашків;Жашков;місто;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120910100;2;0
56bdd1b0-749b-11df-b112-00215aee3ebe;Жашковичі;Жашковичи;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721183806;3;1
56bdd1b1-749b-11df-b112-00215aee3ebe;Жборівці;Жборовцы;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122755301;2;1
56bdd1b2-749b-11df-b112-00215aee3ebe;Жван;Жван;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522882801;3;1
56bdd1b3-749b-11df-b112-00215aee3ebe;Жванець;Жванец;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822482701;3;1
56bdd1b4-749b-11df-b112-00215aee3ebe;Жвирка;Жвирка;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624855300;2;0
56bdd1b5-749b-11df-b112-00215aee3ebe;Ждани;Жданы;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322882201;3;1
56bdd1b6-749b-11df-b112-00215aee3ebe;Жданів;Жданов;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420889603;3;1
56bdd1b7-749b-11df-b112-00215aee3ebe;Жданівка;Ждановка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222382501;3;1
56bdd1b8-749b-11df-b112-00215aee3ebe;Жданівка;Ждановка;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621286602;3;1
56bdd1bb-749b-11df-b112-00215aee3ebe;Жданове;Жданово;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;*122505510;3;1
56bdd1bc-749b-11df-b112-00215aee3ebe;Жданове;Жданово;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;;3;1
56bdd1bd-749b-11df-b112-00215aee3ebe;Ждань;Ждань;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622282002;3;1
56bdd1be-749b-11df-b112-00215aee3ebe;Жденієво;Ждениево;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121555300;3;1
56bdd1bf-749b-11df-b112-00215aee3ebe;Жеванівка;Жевановка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523681202;3;1
56bdd1c0-749b-11df-b112-00215aee3ebe;Жеведь;Жеведь;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425582201;3;1
56bdd1c1-749b-11df-b112-00215aee3ebe;Жежелів;Жежелев;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521481803;3;1
56bdd1c5-749b-11df-b112-00215aee3ebe;Желдець;Желдец;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622183001;3;1
56bdd1c6-749b-11df-b112-00215aee3ebe;Желепове;Желепово;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123980402;3;1
56bdd1c8-749b-11df-b112-00215aee3ebe;Жемелинці;Жемелинцы;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820386003;3;1
56bdd1cb-749b-11df-b112-00215aee3ebe;Жемчужне;Жемчужное;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955110;3;1
56bdd1cc-749b-11df-b112-00215aee3ebe;Женишківці;Женишковцы;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820683001;3;1
56bdd1cd-749b-11df-b112-00215aee3ebe;Женів;Женов;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621810201;3;1
56bdd1ce-749b-11df-b112-00215aee3ebe;Жерделі;Жердели;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820385303;3;1
56bdd1cf-749b-11df-b112-00215aee3ebe;Жерденівка;Жерденовка;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520882003;3;1
56bdd1d0-749b-11df-b112-00215aee3ebe;Жердова;Жердова;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221282001;3;1
56bdd1d1-749b-11df-b112-00215aee3ebe;Жердя;Жердя;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825283001;2;1
56bdd258-749b-11df-b112-00215aee3ebe;Жереб'ятин (Жовтневе);Жеребятин (Жовтневое);село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220881702;3;1
56bdd1d2-749b-11df-b112-00215aee3ebe;Жеребецьке;Жеребецкое;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422080204;3;1
56bdd1d3-749b-11df-b112-00215aee3ebe;Жеребилівка;Жеребиловка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824084603;3;1
56bdd1d4-749b-11df-b112-00215aee3ebe;Жеребилівка;Жеребиловка;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522682801;3;1
56bdd1d5-749b-11df-b112-00215aee3ebe;Жеребки;Жеребки;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124682001;3;1
56bdd1d6-749b-11df-b112-00215aee3ebe;Жеребки;Жеребки;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825882801;3;1
56bdd1d7-749b-11df-b112-00215aee3ebe;Жеребкове;Жеребково;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120282001;3;1
56bdd1d9-749b-11df-b112-00215aee3ebe;Жерев;Жерев;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755125;3;1
56bdd1da-749b-11df-b112-00215aee3ebe;Жерева;Жерева;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222084202;3;1
56bdd1db-749b-11df-b112-00215aee3ebe;Жеревпілля;Жеревполье;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222084203;3;1
56bdd1dc-749b-11df-b112-00215aee3ebe;Жеревці;Жеревцы;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822881601;3;1
56bdd1dd-749b-11df-b112-00215aee3ebe;Жернівка;Жерновка;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422287802;3;1
56bdd1de-749b-11df-b112-00215aee3ebe;Живанівка;Живановка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522855102;3;1
56bdd1df-749b-11df-b112-00215aee3ebe;Живачів;Живачов;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625683701;3;1
56bdd1e2-749b-11df-b112-00215aee3ebe;Животівка;Животовка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523181301;3;1
3680a56e-7b3f-11e2-85b4-003048d2b473;Жигайли ;Жигайлы;;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785203;3;1
56bdd1e3-749b-11df-b112-00215aee3ebe;Жигайлівка;Жигайловка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922081502;3;1
56bdd1e4-749b-11df-b112-00215aee3ebe;Жигайлівка;Жигайловка;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925082401;3;1
56bdd1e5-749b-11df-b112-00215aee3ebe;Жигайлове;Жигайлово;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125283603;3;1
56bdd1e6-749b-11df-b112-00215aee3ebe;Жигалівка;Жигаловка;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521682003;3;1
56bdd1e7-749b-11df-b112-00215aee3ebe;Жидачів;Жидачов;місто;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621510100;2;0
87162669-749b-11df-b112-00215aee3ebe;Жидиничі (Червоне);Жидиничи (Червоное);село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425583703;3;1
56bdd1e8-749b-11df-b112-00215aee3ebe;Жидичин;Жидычин;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721882701;3;1
56bdd1e9-749b-11df-b112-00215aee3ebe;Жижниківці;Жижниковцы;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820381502;3;1
56bdd1ea-749b-11df-b112-00215aee3ebe;Жизномир;Жизномир;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121281101;3;1
56bdd1eb-749b-11df-b112-00215aee3ebe;Жилин Млинок;Жилин Млынок;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422080703;3;1
56bdd1ed-749b-11df-b112-00215aee3ebe;Жилинці;Жилинцы;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
56bdd1ee-749b-11df-b112-00215aee3ebe;Жилинці;Жилинцы;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825585803;3;1
56bdd1ef-749b-11df-b112-00215aee3ebe;Жилинці;Жилинцы;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825883001;3;1
56bdd1f0-749b-11df-b112-00215aee3ebe;Жилівка;Жиловка;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323082201;3;1
56bdd1f1-749b-11df-b112-00215aee3ebe;Жильжа;Жильжа;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623482002;3;1
56bdd1f2-749b-11df-b112-00215aee3ebe;Жиричі;Жиричи;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724283001;3;1
56bdd1f3-749b-11df-b112-00215aee3ebe;Жирівка;Жировка;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623682801;3;1
56bdd1f4-749b-11df-b112-00215aee3ebe;Жирівське;Жировское;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621585604;3;1
56bdd1f5-749b-11df-b112-00215aee3ebe;Жирки;Жирки;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321886602;3;1
56bdd1f6-749b-11df-b112-00215aee3ebe;Жирківка;Жирковка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323083802;3;1
56bdd1f7-749b-11df-b112-00215aee3ebe;Жирова;Жирова;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621582701;2;1
56bdd1f8-749b-11df-b112-00215aee3ebe;Житані;Житани;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720586002;3;1
56bdd1f9-749b-11df-b112-00215aee3ebe;Житейське;Житейское;село;9b486f64-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5910191505;3;1
56bdd1fb-749b-11df-b112-00215aee3ebe;Житинці;Житинцы;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823182903;3;1
56bdd1fc-749b-11df-b112-00215aee3ebe;Житинці;Житинцы;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820880602;3;1
56bdd1fe-749b-11df-b112-00215aee3ebe;Житне;Житное;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924186403;3;1
56bdd1ff-749b-11df-b112-00215aee3ebe;Житники;Житники;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120982001;3;1
56bdd200-749b-11df-b112-00215aee3ebe;Житники;Житники;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522885003;3;1
56bdd201-749b-11df-b112-00215aee3ebe;Житні Гори;Житние Горы;село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223781501;3;1
56bdd202-749b-11df-b112-00215aee3ebe;Житнівка;Житновка;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721480302;3;1
56bdd203-749b-11df-b112-00215aee3ebe;Житомир;Житомир;місто;d00d3b86-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1810100000;1;0
50c595b8-749b-11df-b112-00215aee3ebe;Житомир(Довжик);Житомир(Довжик);село;d00d3b86-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822083202;1;0
17af7b4b-ddb9-11df-9197-00215aee3ebe;Житомирське;Житомирское;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;;3;1
56bdd204-749b-11df-b112-00215aee3ebe;Жихове;Жихово;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924481301;3;1
56bdd205-749b-11df-b112-00215aee3ebe;Жищинці;Жищинцы;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821282201;3;1
56bdd206-749b-11df-b112-00215aee3ebe;Жмерине;Жмерино;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225482004;3;1
56bdd207-749b-11df-b112-00215aee3ebe;Жмеринка;Жмеринка;місто;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0510300000;2;0
56bdd208-749b-11df-b112-00215aee3ebe;Жміївка;Жмиевка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222081201;3;1
56bdd209-749b-11df-b112-00215aee3ebe;Жмудче;Жмудче;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155312;3;1
56bdd20a-749b-11df-b112-00215aee3ebe;Жнибороди;Жнибороды;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121288601;3;1
56bdd20b-749b-11df-b112-00215aee3ebe;Жнятино;Жнятино;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122782201;2;1
c8674aa8-dbb0-11df-9197-00215aee3ebe;Жобрин;Жобрин;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624684501;3;1
56bdd20c-749b-11df-b112-00215aee3ebe;Жовідь;Жоведь;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425882001;3;1
56bdd20d-749b-11df-b112-00215aee3ebe;Жовква;Жовква;місто;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622710100;1;0
56bdd20e-749b-11df-b112-00215aee3ebe;Жовкині;Жовкини;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620883801;3;1
56bdd20f-749b-11df-b112-00215aee3ebe;Жовнине;Жовнино;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125182401;3;1
56bdd210-749b-11df-b112-00215aee3ebe;Жовнівка;Жовновка;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120481601;3;1
56bdd211-749b-11df-b112-00215aee3ebe;Жовта Круча;Желтая Круча;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955408;3;1
56bdd212-749b-11df-b112-00215aee3ebe;Жовтанці;Жовтанцы;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622183301;3;0
56bdd213-749b-11df-b112-00215aee3ebe;Жовте;Желтое;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224581501;3;1
56bdd214-749b-11df-b112-00215aee3ebe;Жовте;Желтое;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225283308;3;1
56bdd215-749b-11df-b112-00215aee3ebe;Жовте;Желтое;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220310104;3;1
56bdd216-749b-11df-b112-00215aee3ebe;Жовте;Желтое;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121880703;3;1
56bdd219-749b-11df-b112-00215aee3ebe;Жовте;Желтое;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820655702;3;1
56bdd21a-749b-11df-b112-00215aee3ebe;Жовтень;Жовтень;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823684802;3;1
56bdd21b-749b-11df-b112-00215aee3ebe;Жовтень;Жовтень;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825181204;3;1
56bdd21f-749b-11df-b112-00215aee3ebe;Жовтий Брід;Желтый Брод;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821484003;3;1
56bdd220-749b-11df-b112-00215aee3ebe;Жовтий Яр;Желтый Яр;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125082401;2;1
56bdd221-749b-11df-b112-00215aee3ebe;Жовті Води;Желтые Воды;місто;d00d3b87-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1210700000;2;0
56bdd277-749b-11df-b112-00215aee3ebe;Жовтнева Революція (Радісне);Октябрьская Революция;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121056802;3;1
56bdd266-749b-11df-b112-00215aee3ebe;Жовтневе;Жовтневое;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125184402;3;1
56bdd26a-749b-11df-b112-00215aee3ebe;Жовтневе;Жовтневое;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;;3;1
56bdd26b-749b-11df-b112-00215aee3ebe;Жовтневе;Жовтневое;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520882402;3;1
56bdd26c-749b-11df-b112-00215aee3ebe;Жовтневе;Жовтневое;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525884701;3;1
56bdd26d-749b-11df-b112-00215aee3ebe;Жовтневе;Жовтневое;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822788802;3;1
56bdd26e-749b-11df-b112-00215aee3ebe;Жовтневе;Жовтневое;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820900000;3;1
56bdd26f-749b-11df-b112-00215aee3ebe;Жовтневе;Жовтневое;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825089801;3;1
56bdd235-749b-11df-b112-00215aee3ebe;Жовтневе;Жовтневое;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;6324582503;3;1
56bdd236-749b-11df-b112-00215aee3ebe;Жовтневе;Жовтневое;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824581203;3;1
56bdd230-749b-11df-b112-00215aee3ebe;Жовтневе;Жовтневое;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0710745300;3;1
56bdd231-749b-11df-b112-00215aee3ebe;Жовтневе;Жовтневое;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125289202;3;1
56bdd232-749b-11df-b112-00215aee3ebe;Жовтневе;Жовтневое;село;bad3ac3c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;*122375510;3;1
56bdd25a-749b-11df-b112-00215aee3ebe;Жовтневе;Жовтневое;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222283601;3;1
56bdd261-749b-11df-b112-00215aee3ebe;Жовтневе;Жовтневое;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423084001;3;1
56bdd262-749b-11df-b112-00215aee3ebe;Жовтневе;Жовтневое;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422282501;3;1
56bdd263-749b-11df-b112-00215aee3ebe;Жовтневе;Жовтневое;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421710100;3;1
56bdd25d-749b-11df-b112-00215aee3ebe;Жовтневе;Жовтневое;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823482802;3;1
56bdd25f-749b-11df-b112-00215aee3ebe;Жовтневе;Жовтневое;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825283301;3;1
56bdd239-749b-11df-b112-00215aee3ebe;Жовтневе;Жовтневое;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824280202;3;1
56bdd23a-749b-11df-b112-00215aee3ebe;Жовтневе;Жовтневое;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322686503;3;1
56bdd23e-749b-11df-b112-00215aee3ebe;Жовтневе;Жовтневое;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323255101;3;1
56bdd240-749b-11df-b112-00215aee3ebe;Жовтневе;Жовтневое;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324582503;3;1
56bdd25c-749b-11df-b112-00215aee3ebe;Жовтневе(Миролюбів);Жовтневое;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822856000;3;1
56bdd276-749b-11df-b112-00215aee3ebe;Жовтневий Промінь;Жовтневый Проминь;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125184402;3;1
56bdd279-749b-11df-b112-00215aee3ebe;Жовтнівка;Жовтневка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821184005;3;1
56bdd27b-749b-11df-b112-00215aee3ebe;Жовтоолександрівка;Жовтоалександровка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224584807;3;1
56bdd27c-749b-11df-b112-00215aee3ebe;Жовчів;Жовчев;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624482201;3;1
56bdd27d-749b-11df-b112-00215aee3ebe;Жолдаки;Жолдаки;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922085003;3;1
56bdd27e-749b-11df-b112-00215aee3ebe;Жолоби;Желобы;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920387505;3;1
56bdd27f-749b-11df-b112-00215aee3ebe;Жолоби;Желобы;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123482601;3;1
56bdd280-749b-11df-b112-00215aee3ebe;Жолоби;Желобы;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523955105;3;1
56bdd281-749b-11df-b112-00215aee3ebe;Жолобки;Жолобки;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125882801;3;1
56bdd282-749b-11df-b112-00215aee3ebe;Жолобне;Желобное;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824081401;3;1
56bdd283-749b-11df-b112-00215aee3ebe;Жолобок;Желобок;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920655306;3;1
56bdd284-749b-11df-b112-00215aee3ebe;Жолобок;Желобок;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223582806;3;1
56bdd286-749b-11df-b112-00215aee3ebe;Жолобок;Желобок;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425384003;3;1
56bdd287-749b-11df-b112-00215aee3ebe;Жолудки;Жолудки;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825585602;3;1
56bdd288-749b-11df-b112-00215aee3ebe;Жолудькове;Желудьково;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123155101;3;1
56bdd289-749b-11df-b112-00215aee3ebe;Жоравка;Жоравка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225581601;3;1
56bdd28a-749b-11df-b112-00215aee3ebe;Жоржівка;Жоржевка;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755121;3;1
56bdd28b-749b-11df-b112-00215aee3ebe;Жорнава;Жорнава;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120882002;3;1
56bdd28c-749b-11df-b112-00215aee3ebe;Жорниська;Жорниска;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625883602;3;1
56bdd28d-749b-11df-b112-00215aee3ebe;Жорнище;Жорнище;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721882901;3;1
56bdd28e-749b-11df-b112-00215aee3ebe;Жорнище;Жорнище;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521210107;3;1
56bdd28f-749b-11df-b112-00215aee3ebe;Жорнів;Жорнов;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621687005;3;1
56bdd290-749b-11df-b112-00215aee3ebe;Жорнівка;Жорновка;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222483602;3;1
56bdd291-749b-11df-b112-00215aee3ebe;Жорнівка;Жорновка;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623085602;3;1
56bdd292-749b-11df-b112-00215aee3ebe;Жорнокльови;Жерноклевы;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120683801;3;1
56bdd293-749b-11df-b112-00215aee3ebe;Жорняки;Жорняки;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610111;3;1
56bdd294-749b-11df-b112-00215aee3ebe;Жорняки;Жорняки;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;;3;1
56bdd295-749b-11df-b112-00215aee3ebe;Жубровичі;Жубровичи;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824481601;3;1
56bdd296-749b-11df-b112-00215aee3ebe;Жужеляни;Жужеляны;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624882101;3;1
56bdd297-749b-11df-b112-00215aee3ebe;Жуки;Жуки;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324085702;3;1
56bdd298-749b-11df-b112-00215aee3ebe;Жуки;Жуки;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610112;3;1
56bdd299-749b-11df-b112-00215aee3ebe;Жуки;Жуки;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321882001;3;1
56bdd29a-749b-11df-b112-00215aee3ebe;Жукин;Жукин;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221882801;3;1
56bdd29b-749b-11df-b112-00215aee3ebe;Жуків;Жуков;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120481801;3;1
56bdd29c-749b-11df-b112-00215aee3ebe;Жуків;Жуков;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625684001;3;1
56bdd29d-749b-11df-b112-00215aee3ebe;Жуків;Жуков;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621883201;3;1
56bdd29e-749b-11df-b112-00215aee3ebe;Жуків;Жуков;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823982501;3;1
56bdd2a0-749b-11df-b112-00215aee3ebe;Жуківка;Жуковка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920983601;3;1
56bdd2a1-749b-11df-b112-00215aee3ebe;Жуківка;Жуковка;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221982301;3;1
56bdd2a2-749b-11df-b112-00215aee3ebe;Жуківка;Жуковка;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422784501;3;1
56bdd2a3-749b-11df-b112-00215aee3ebe;Жуківці;Жуковцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122689803;3;1
56bdd2a4-749b-11df-b112-00215aee3ebe;Жуківці;Жуковцы;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123881203;3;1
56bdd2a5-749b-11df-b112-00215aee3ebe;Жуківці;Жуковцы;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223183801;3;1
56bdd2a6-749b-11df-b112-00215aee3ebe;Жуківці;Жуковцы;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521081403;3;1
56bdd2a7-749b-11df-b112-00215aee3ebe;Жуківщина;Жуковщина;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422081203;3;1
56bdd2a8-749b-11df-b112-00215aee3ebe;Жукля;Жукля;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422483001;3;1
56bdd2aa-749b-11df-b112-00215aee3ebe;Жукове;Жуково;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655103;3;1
56bdd2ab-749b-11df-b112-00215aee3ebe;Жукове;Жуково;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123585503;3;1
56bdd2ad-749b-11df-b112-00215aee3ebe;Жуковець;Жуковец;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720887204;3;1
56bdd2ac-749b-11df-b112-00215aee3ebe;Жуково;Жуково;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122782301;2;1
74dc497b-749b-11df-b112-00215aee3ebe;Жуковське (Петрівське);Жуковское (Петровское);село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125484404;3;1
56bdd2ae-749b-11df-b112-00215aee3ebe;Жукотин;Жукотин;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623282201;3;1
56bdd2af-749b-11df-b112-00215aee3ebe;Жукотин;Жукотин;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625585105;3;1
56bdd2b0-749b-11df-b112-00215aee3ebe;Жукотки;Жукотки;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425582501;3;1
56bdd2b1-749b-11df-b112-00215aee3ebe;Жулин;Жулин;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625382401;3;1
56bdd2b2-749b-11df-b112-00215aee3ebe;Жуличі;Жуличи;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621885803;3;1
56bdd2b3-749b-11df-b112-00215aee3ebe;Жупани;Жупаны;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624582001;3;1
56bdd2b4-749b-11df-b112-00215aee3ebe;Жупанівка;Жупановка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822381203;3;1
56bdd2b5-749b-11df-b112-00215aee3ebe;Журава;Журава;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522280602;3;1
56bdd2b6-749b-11df-b112-00215aee3ebe;Журавеньки;Журавеньки;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624455305;3;1
56bdd2b7-749b-11df-b112-00215aee3ebe;Журавець;Журавец;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722485802;3;1
56bdd2b8-749b-11df-b112-00215aee3ebe;Журавичі;Журавичи;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721883101;3;1
56bdd2b9-749b-11df-b112-00215aee3ebe;Журавка;Журавка;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924484402;3;1
56bdd2ba-749b-11df-b112-00215aee3ebe;Журавка;Журавка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924781104;3;1
56bdd2bb-749b-11df-b112-00215aee3ebe;Журавка;Журавка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325783502;3;1
56bdd2bd-749b-11df-b112-00215aee3ebe;Журавка;Журавка;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421182001;3;1
56bdd2be-749b-11df-b112-00215aee3ebe;Журавка;Журавка;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120383001;3;1
56bdd2bf-749b-11df-b112-00215aee3ebe;Журавка;Журавка;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125782401;3;1
56bdd2c2-749b-11df-b112-00215aee3ebe;Журавків;Журавков;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621580602;3;1
56bdd2c3-749b-11df-b112-00215aee3ebe;Журавлева Буда;Журавлевая Буда;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422487903;3;1
56bdd2c4-749b-11df-b112-00215aee3ebe;Журавлине;Журавлиное;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725081801;3;1
56bdd2c5-749b-11df-b112-00215aee3ebe;Журавлине;Журавлиное;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620886902;3;1
56bdd2c6-749b-11df-b112-00215aee3ebe;Журавлинка;Журавлинка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755701;3;1
56bdd2c7-749b-11df-b112-00215aee3ebe;Журавлинка;Журавлинка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825087602;3;1
56bdd2c8-749b-11df-b112-00215aee3ebe;Журавлинка;Журавлинка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521481801;3;1
56bdd2c9-749b-11df-b112-00215aee3ebe;Журавлинці;Журавлинцы;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821281902;3;1
56bdd2ca-749b-11df-b112-00215aee3ebe;Журавлиха;Журавлиха;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224282801;3;1
56bdd2cb-749b-11df-b112-00215aee3ebe;Журавлі;Журавли;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321255401;3;1
56bdd2d0-749b-11df-b112-00215aee3ebe;Журавлівка;Журавлевка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520281603;3;1
56bdd2d1-749b-11df-b112-00215aee3ebe;Журавлівка;Журавлевка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524381001;3;1
56bdd2d2-749b-11df-b112-00215aee3ebe;Журавлівка вокзал;Журавлевка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524381001;3;1
56bdd2d3-749b-11df-b112-00215aee3ebe;Журавне;Журавное;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920389204;3;1
56bdd2d4-749b-11df-b112-00215aee3ebe;Журавне;Журавное;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522482801;3;1
56bdd2d5-749b-11df-b112-00215aee3ebe;Журавники;Журавники;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720881601;3;1
56bdd2d6-749b-11df-b112-00215aee3ebe;Журавники;Журавники;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623687602;3;1
56bdd2d7-749b-11df-b112-00215aee3ebe;Журавно;Журавно;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621555600;3;1
56bdd2d8-749b-11df-b112-00215aee3ebe;Журавок;Журавок;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425885002;3;1
56bdd2d9-749b-11df-b112-00215aee3ebe;Журавське;Журавское;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;;3;1
56bdd2db-749b-11df-b112-00215aee3ebe;Жураки;Жураки;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620482801;3;1
56bdd2dc-749b-11df-b112-00215aee3ebe;Журатин;Журатин;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620682303;3;1
56bdd2dd-749b-11df-b112-00215aee3ebe;Журбинці;Журбинцы;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820885802;3;1
56bdd2de-749b-11df-b112-00215aee3ebe;Журбинці;Журбинцы;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521482003;3;1
56bdd2df-749b-11df-b112-00215aee3ebe;Журжевичі;Журжевичи;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824482001;3;1
56bdd2e0-749b-11df-b112-00215aee3ebe;Журжинці;Журжинцы;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122883601;3;1
56bdd2e1-749b-11df-b112-00215aee3ebe;Журів;Журов;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624484902;3;1
56bdd2e2-749b-11df-b112-00215aee3ebe;Журівка;Журовка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820981205;3;1
56bdd2e3-749b-11df-b112-00215aee3ebe;Журівка;Журовка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123584502;3;1
56bdd2e4-749b-11df-b112-00215aee3ebe;Журівка;Журовка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523655101;3;1
56bdd2e5-749b-11df-b112-00215aee3ebe;Жучківці;Жучковцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825082701;3;1
56bdd2e7-749b-11df-b112-00215aee3ebe;Забава;Забава;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623980902;3;1
56bdd2e8-749b-11df-b112-00215aee3ebe;Забавне;Забавное;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322886503;3;1
56bdd2e9-749b-11df-b112-00215aee3ebe;Забара;Забара;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724585004;3;1
56bdd2ea-749b-11df-b112-00215aee3ebe;Забара;Забара;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125883102;3;1
56bdd2eb-749b-11df-b112-00215aee3ebe;Забара;Забара;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820681806;3;1
56bdd2ec-749b-11df-b112-00215aee3ebe;Забара;Забара;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820383601;3;1
56bdd2ed-749b-11df-b112-00215aee3ebe;Забара;Забара;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623081802;3;1
8716263c-749b-11df-b112-00215aee3ebe;Забара (Червона Трибунівка);Забара (Червоная Трибуновка);село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521683109;3;1
56bdd2ee-749b-11df-b112-00215aee3ebe;Забари;Забары;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822755101;3;1
56bdd2ef-749b-11df-b112-00215aee3ebe;Забари;Забары;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124580901;3;1
56bdd2f0-749b-11df-b112-00215aee3ebe;Забарине;Забарино;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322281001;3;1
56bdd2f2-749b-11df-b112-00215aee3ebe;Забарівка;Забаровка;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422483501;3;1
56bdd2f3-749b-11df-b112-00215aee3ebe;Забаро-Давидівка;Забаро-Давыдовка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821783004;3;1
56bdd2f4-749b-11df-b112-00215aee3ebe;Забаряни;Забаряны;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083503;3;1
56bdd253-749b-11df-b112-00215aee3ebe;Забереж (Жовтневе);Забереж (Жовтневе);село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125382802;3;1
56bdd2f5-749b-11df-b112-00215aee3ebe;Забережжя;Забережье;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620482901;3;1
56bdd2f6-749b-11df-b112-00215aee3ebe;Заберізки;Заберезки;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123084802;3;1
56bdd2f7-749b-11df-b112-00215aee3ebe;Забілівщина;Забеловщина;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420810102;3;1
44ac842f-749b-11df-b112-00215aee3ebe;Забілівщина Друга (Більшовик);Забиливщина Вторая (Большевик);село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420810101;3;1
56bdd2f8-749b-11df-b112-00215aee3ebe;Забілоччя;Забелочье;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825082601;3;1
56bdd2f9-749b-11df-b112-00215aee3ebe;Забіляни;Забеляны;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123485302;3;1
56bdd2fa-749b-11df-b112-00215aee3ebe;Забіляни;Забеляны;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523980303;3;1
56bdd2fc-749b-11df-b112-00215aee3ebe;Забір'я;Заборье;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783501;3;1
56bdd2fd-749b-11df-b112-00215aee3ebe;Забір'я;Заборье;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222483201;3;1
56bdd2fb-749b-11df-b112-00215aee3ebe;Забірки;Заборки;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621688505;3;1
56bdd2fe-749b-11df-b112-00215aee3ebe;Забодаква;Забодаква;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;;3;1
56bdd2ff-749b-11df-b112-00215aee3ebe;Забойки;Забойки;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125287002;2;1
56bdd300-749b-11df-b112-00215aee3ebe;Заболотинці;Заболотинцы;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623885202;3;1
56bdd301-749b-11df-b112-00215aee3ebe;Заболотів;Заболотов;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625255300;3;1
56bdd302-749b-11df-b112-00215aee3ebe;Заболотівка;Заболотовка;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125583101;3;1
56bdd303-749b-11df-b112-00215aee3ebe;Заболотівці;Заболотовцы;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621580603;3;1
56bdd304-749b-11df-b112-00215aee3ebe;Заболотне;Заболотное;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925383002;3;1
56bdd305-749b-11df-b112-00215aee3ebe;Заболотне;Заболотное;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620681203;3;1
56bdd306-749b-11df-b112-00215aee3ebe;Заболотне;Заболотное;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121982002;3;1
56bdd307-749b-11df-b112-00215aee3ebe;Заболотне;Заболотное;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521982601;3;1
56bdd308-749b-11df-b112-00215aee3ebe;Заболото;Заболото;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620686204;3;1
56bdd309-749b-11df-b112-00215aee3ebe;Заболотове;Заболотово;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922688204;3;1
56bdd30a-749b-11df-b112-00215aee3ebe;Заболоття;Заболотье;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724255300;3;1
56bdd30b-749b-11df-b112-00215aee3ebe;Заболоття;Заболотье;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723355304;3;1
56bdd30c-749b-11df-b112-00215aee3ebe;Заболоття;Заболотье;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720510407;3;1
56bdd30d-749b-11df-b112-00215aee3ebe;Заболоття;Заболотье;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324587502;3;1
56bdd30e-749b-11df-b112-00215aee3ebe;Заболоття;Заболотье;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624882103;3;1
56bdd30f-749b-11df-b112-00215aee3ebe;Заболоття;Заболотье;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620984204;3;1
56bdd311-749b-11df-b112-00215aee3ebe;Заболоття;Заболотье;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420387502;3;1
56bdd312-749b-11df-b112-00215aee3ebe;Заболоття;Заболотье;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625080803;3;1
56bdd313-749b-11df-b112-00215aee3ebe;Заболоття;Заболотье;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620884201;3;1
56bdd314-749b-11df-b112-00215aee3ebe;Заболоття;Заболотье;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623885203;3;1
56bdd315-749b-11df-b112-00215aee3ebe;Заболотці;Заболотцы;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721182403;3;1
56bdd316-749b-11df-b112-00215aee3ebe;Заболотці;Заболотцы;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620382001;3;1
56bdd319-749b-11df-b112-00215aee3ebe;Заболотці;Заболотцы;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881809;3;1
56bdd317-749b-11df-b112-00215aee3ebe;Заболоть;Заболоть;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287003;3;1
56bdd318-749b-11df-b112-00215aee3ebe;Заболоть;Заболоть;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825082901;3;1
56bdd31a-749b-11df-b112-00215aee3ebe;Заборознівці;Заборозновцы;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823388002;3;1
56bdd31b-749b-11df-b112-00215aee3ebe;Забороль;Забороль;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881801;3;1
56bdd31c-749b-11df-b112-00215aee3ebe;Забороль;Забороль;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624684701;3;1
56bdd31d-749b-11df-b112-00215aee3ebe;Забороче;Заборочье;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824484003;3;1
73e94be0-ddc1-11df-9197-00215aee3ebe;Забране;Забранное;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823481407;3;1
56bdd31e-749b-11df-b112-00215aee3ebe;Забрід;Заброд;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785504;3;1
56bdd31f-749b-11df-b112-00215aee3ebe;Забрід;Заброд;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620685805;3;1
56bdd320-749b-11df-b112-00215aee3ebe;Забрід;Заброд;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822189202;3;1
56bdd321-749b-11df-b112-00215aee3ebe;Забріддя;Забродье;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825682004;3;1
56bdd322-749b-11df-b112-00215aee3ebe;Забрідки;Забродки;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323482802;3;1
56bdd323-749b-11df-b112-00215aee3ebe;Забрідь;Забродь;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120881801;3;1
56bdd324-749b-11df-b112-00215aee3ebe;Забрідь;Забродь;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125383001;3;1
56bdd325-749b-11df-b112-00215aee3ebe;Заброди;Заброды;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724283401;3;1
56bdd326-749b-11df-b112-00215aee3ebe;Заброди;Заброды;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721886607;3;1
56bdd327-749b-11df-b112-00215aee3ebe;Заброди;Заброды;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320882501;3;1
56bdd328-749b-11df-b112-00215aee3ebe;Забуги;Забуги;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983704;3;1
56bdd329-749b-11df-b112-00215aee3ebe;Забужжя;Забужье;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723384909;3;1
56bdd32a-749b-11df-b112-00215aee3ebe;Забужжя;Забужье;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624884604;3;1
56bdd32b-749b-11df-b112-00215aee3ebe;Забужжя;Забужье;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622110101;3;1
56bdd32c-749b-11df-b112-00215aee3ebe;Забужжя;Забужье;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523082003;3;1
56bdd32d-749b-11df-b112-00215aee3ebe;Забуччя;Забучье;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222485202;3;1
56bdd32e-749b-11df-b112-00215aee3ebe;Забуяння;Забуянье;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222782301;3;1
56bdd379-749b-11df-b112-00215aee3ebe;Зав'язанці;Завязанцы;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622482801;3;1
56bdd37a-749b-11df-b112-00215aee3ebe;Зав'ялівка;Завьяловка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225286603;3;1
56bdd32f-749b-11df-b112-00215aee3ebe;Завада;Завада;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622481202;3;1
56bdd330-749b-11df-b112-00215aee3ebe;Завади;Завады;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622782103;3;1
56bdd331-749b-11df-b112-00215aee3ebe;Завадинці;Завадинцы;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822182401;3;1
56bdd332-749b-11df-b112-00215aee3ebe;Завадинці;Завадинцы;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821281602;3;1
56bdd333-749b-11df-b112-00215aee3ebe;Завадів;Завадов;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784102;3;1
56bdd334-749b-11df-b112-00215aee3ebe;Завадів;Завадов;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625884201;3;1
56bdd335-749b-11df-b112-00215aee3ebe;Завадів;Завадов;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622486903;3;1
56bdd336-749b-11df-b112-00215aee3ebe;Завадів;Завадов;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625382601;3;1
56bdd337-749b-11df-b112-00215aee3ebe;Завадівка;Завадовка;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124284801;3;1
56bdd338-749b-11df-b112-00215aee3ebe;Завадівка;Завадовка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324281002;3;1
56bdd339-749b-11df-b112-00215aee3ebe;Завадівка;Завадовка;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625582901;3;1
56bdd33a-749b-11df-b112-00215aee3ebe;Завадівка;Завадовка;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221682001;2;1
56bdd33b-749b-11df-b112-00215aee3ebe;Завадівка;Завадовка;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122582501;3;1
56bdd33c-749b-11df-b112-00215aee3ebe;Завадівка;Завадовка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523781801;3;1
56bdd33d-749b-11df-b112-00215aee3ebe;Завадівка;Завадовка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520886903;3;1
56bdd33e-749b-11df-b112-00215aee3ebe;Завадівка;Завадовка;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825281202;2;1
56bdd33f-749b-11df-b112-00215aee3ebe;Завадка;Завадка;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622880807;3;1
56bdd340-749b-11df-b112-00215aee3ebe;Завадка;Завадка;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625186602;3;1
56bdd341-749b-11df-b112-00215aee3ebe;Завадка;Завадка;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624582301;3;1
56bdd342-749b-11df-b112-00215aee3ebe;Завадка;Завадка;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121582002;3;1
56bdd343-749b-11df-b112-00215aee3ebe;Завадське;Завадское;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322680502;3;1
56bdd344-749b-11df-b112-00215aee3ebe;Завалів;Завалов;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124882501;3;1
56bdd345-749b-11df-b112-00215aee3ebe;Завалівка;Заваловка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222788602;3;1
56bdd346-749b-11df-b112-00215aee3ebe;Завалійки;Завалийки;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820982301;3;1
56bdd347-749b-11df-b112-00215aee3ebe;Завалля;Завалье;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625282101;3;1
56bdd348-749b-11df-b112-00215aee3ebe;Завалля;Завалье;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;3521155300;3;1
56bdd349-749b-11df-b112-00215aee3ebe;Завалля;Завалье;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822482901;3;1
56bdd34a-749b-11df-b112-00215aee3ebe;Завалля;Завалье;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623889002;3;1
56bdd34b-749b-11df-b112-00215aee3ebe;Завбуч;Завбуч;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123285502;3;1
56bdd34c-749b-11df-b112-00215aee3ebe;Завгороднє;Завгороднее;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321287002;3;1
56bdd34d-749b-11df-b112-00215aee3ebe;Завгороднє;Завгороднее;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320284803;3;1
56bdd34e-749b-11df-b112-00215aee3ebe;Заверешиця;Заверешица;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620986202;3;1
56bdd34f-749b-11df-b112-00215aee3ebe;Заверхи;Заверхи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622487603;3;1
56bdd350-749b-11df-b112-00215aee3ebe;Заверхня Кичера;Заверхняя Кичера;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122486902;3;1
56bdd351-749b-11df-b112-00215aee3ebe;Завидів;Завидов;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721183807;3;1
56bdd352-749b-11df-b112-00215aee3ebe;Завидів;Завидов;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624286703;3;1
56bdd355-749b-11df-b112-00215aee3ebe;Завидовичі;Завидовичи;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620983601;3;1
56bdd354-749b-11df-b112-00215aee3ebe;Завидово;Завидово;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122782401;3;1
56bdd357-749b-11df-b112-00215aee3ebe;Завидче;Завидче;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623981401;3;1
56bdd373-749b-11df-b112-00215aee3ebe;Завизів;Завизов;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624280802;3;1
56bdd358-749b-11df-b112-00215aee3ebe;Завийка;Завыйка;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122485602;3;1
56bdd359-749b-11df-b112-00215aee3ebe;Завишень;Завишень;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624855301;3;0
56bdd35a-749b-11df-b112-00215aee3ebe;Завій;Завой;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622882801;3;1
56bdd35c-749b-11df-b112-00215aee3ebe;Завітне;Заветное;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721883301;3;1
56bdd363-749b-11df-b112-00215aee3ebe;Завітне;Заветное;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520487506;3;1
56bdd364-749b-11df-b112-00215aee3ebe;Завітне;Заветное;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524385503;3;1
56bdd366-749b-11df-b112-00215aee3ebe;Завод;Завод;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425586302;3;1
56bdd367-749b-11df-b112-00215aee3ebe;Заводи;Заводы;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922087803;3;1
56bdd368-749b-11df-b112-00215aee3ebe;Заводи;Заводы;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322883001;3;1
56bdd36a-749b-11df-b112-00215aee3ebe;Заводівка;Заводовка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121282001;3;1
56bdd36c-749b-11df-b112-00215aee3ebe;Заводське;Заводское;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922081102;3;1
56bdd36d-749b-11df-b112-00215aee3ebe;Заводське;Заводское;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;;3;1
56bdd36e-749b-11df-b112-00215aee3ebe;Заводське;Заводское;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125555700;3;1
56bdd36f-749b-11df-b112-00215aee3ebe;Заводське;Заводское;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620681701;3;1
56bdd371-749b-11df-b112-00215aee3ebe;Заводське;Заводское;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424486803;3;1
8716270c-749b-11df-b112-00215aee3ebe;Заводське;Заводское;місто;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322610600;3;0
56bdd374-749b-11df-b112-00215aee3ebe;Заволока;Заволока;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324586503;3;1
56bdd375-749b-11df-b112-00215aee3ebe;Заворичі;Заворичи;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221255503;3;1
56bdd376-749b-11df-b112-00215aee3ebe;Заворскло;Заворскло;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081001;3;1
56bdd377-749b-11df-b112-00215aee3ebe;Завосина;Завосина;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120883602;3;1
56bdd378-749b-11df-b112-00215aee3ebe;Завтурове;Завтурово;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525884902;3;1
56bdd37b-749b-11df-b112-00215aee3ebe;Загаї;Загаи;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720884603;3;1
56bdd37c-749b-11df-b112-00215aee3ebe;Загайпіль;Загайполь;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623282401;3;1
56bdd37d-749b-11df-b112-00215aee3ebe;Загайці;Загайцы;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124887305;3;1
56bdd37e-749b-11df-b112-00215aee3ebe;Загальна Користь;Загальная Корысть;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824510103;3;1
56bdd37f-749b-11df-b112-00215aee3ebe;Загальці;Загальцы;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221082001;3;1
56bdd380-749b-11df-b112-00215aee3ebe;Загаркушине;Загаркушино;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324882003;3;1
56bdd381-749b-11df-b112-00215aee3ebe;Загатинці;Загатинцы;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623886703;3;1
56bdd382-749b-11df-b112-00215aee3ebe;Загатка;Загатка;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623985930;3;1
56bdd383-749b-11df-b112-00215aee3ebe;Загатка;Загатка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425581602;3;1
56bdd384-749b-11df-b112-00215aee3ebe;Загаття;Загатье;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121983601;3;1
56bdd385-749b-11df-b112-00215aee3ebe;Загвіздя;Загвоздье;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625881101;3;0
56bdd386-749b-11df-b112-00215aee3ebe;Загін;Загон;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421785202;3;1
56bdd387-749b-11df-b112-00215aee3ebe;Загінці;Загинци;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821582801;3;1
56bdd391-749b-11df-b112-00215aee3ebe;Загір'я;Загорье;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122683401;3;1
56bdd392-749b-11df-b112-00215aee3ebe;Загір'я;Загорье;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125255409;3;1
56bdd393-749b-11df-b112-00215aee3ebe;Загір'я;Загорье;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624482502;3;1
56bdd394-749b-11df-b112-00215aee3ebe;Загір'я;Загорье;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785207;3;1
56bdd395-749b-11df-b112-00215aee3ebe;Загір'я;Загорье;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623681803;3;1
56bdd396-749b-11df-b112-00215aee3ebe;Загір'я;Загорье;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624285602;3;1
7ad94fa7-749b-11df-b112-00215aee3ebe;Загір'я;Загорье;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625610105;3;1
56bdd397-749b-11df-b112-00215aee3ebe;Загір'я-Кукільницьке;Загорье-Кукольницкое;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621255303;3;1
56bdd389-749b-11df-b112-00215aee3ebe;Загірне;Загорное;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625382701;3;1
56bdd38a-749b-11df-b112-00215aee3ebe;Загірне;Загорное;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824280403;3;1
56bdd38c-749b-11df-b112-00215aee3ebe;Загірочко;Загорочко;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621583001;3;1
56bdd38d-749b-11df-b112-00215aee3ebe;Загірське;Загорское;село;9b486f64-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5910191507;3;1
56bdd38e-749b-11df-b112-00215aee3ebe;Загірці;Загорцы;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123883701;3;1
56bdd38f-749b-11df-b112-00215aee3ebe;Загірці;Загорцы;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620384802;3;1
56bdd390-749b-11df-b112-00215aee3ebe;Загірці;Загорцы;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820384003;3;1
56bdd398-749b-11df-b112-00215aee3ebe;Загнітків;Загнитков;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122582201;3;1
56bdd399-749b-11df-b112-00215aee3ebe;Заголосна;Заголосная;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821885602;3;1
56bdd39a-749b-11df-b112-00215aee3ebe;Загора;Загора;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621855602;3;1
56bdd39b-749b-11df-b112-00215aee3ebe;Загора;Загора;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622681602;3;1
56bdd39c-749b-11df-b112-00215aee3ebe;Загорб;Загорб;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120882001;3;1
56bdd39d-749b-11df-b112-00215aee3ebe;Загорб;Загорб;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122487203;3;1
56bdd39e-749b-11df-b112-00215aee3ebe;Загорби;Загорбы;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484603;3;1
56bdd39f-749b-11df-b112-00215aee3ebe;Загорівка;Загоровка;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922688703;3;1
56bdd3a0-749b-11df-b112-00215aee3ebe;Загорівка;Загоровка;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723682204;3;1
74dc4700-749b-11df-b112-00215aee3ebe;Загорівка;Загоровка;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423085003;3;1
56bdd3a1-749b-11df-b112-00215aee3ebe;Загороддя;Загородье;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122455302;3;1
56bdd3a2-749b-11df-b112-00215aee3ebe;Загороди;Загороды;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622482205;3;1
56bdd3a3-749b-11df-b112-00215aee3ebe;Загородище;Загородище;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125182802;3;1
56bdd3a4-749b-11df-b112-00215aee3ebe;Загороща;Загороща;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624685902;3;1
56bdd3a5-749b-11df-b112-00215aee3ebe;Загоряни;Загоряны;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823381203;3;1
56bdd3a6-749b-11df-b112-00215aee3ebe;Загорянівка;Загоряновка;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520386703;3;1
56bdd3a7-749b-11df-b112-00215aee3ebe;Заграбівка;Заграбовка;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621587802;3;1
56bdd3a8-749b-11df-b112-00215aee3ebe;Заградівка;Заградовка;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;6521880701;3;1
56bdd3a9-749b-11df-b112-00215aee3ebe;Загребелля;Загребелье;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322085007;3;1
56bdd3aa-749b-11df-b112-00215aee3ebe;Загребелля;Загребелье;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187303;3;1
56bdd3ab-749b-11df-b112-00215aee3ebe;Загребелля;Загребелье;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424982501;3;1
56bdd3ac-749b-11df-b112-00215aee3ebe;Загребелля;Загребелье;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325182605;3;1
56bdd3ad-749b-11df-b112-00215aee3ebe;Загребелля;Загребелье;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320884903;3;1
56bdd3ae-749b-11df-b112-00215aee3ebe;Загребелля;Загребелье;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323688202;3;1
56bdd3b0-749b-11df-b112-00215aee3ebe;Загребельна Слобода;Загребельная Слобода;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425884002;3;1
56bdd3af-749b-11df-b112-00215aee3ebe;Загребельня;Загребельная;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521683606;3;1
56bdd3b1-749b-11df-b112-00215aee3ebe;Загребля;Загребля;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823480402;3;1
56bdd3b2-749b-11df-b112-00215aee3ebe;Загребля;Загребля;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820385503;3;1
56bdd3b3-749b-11df-b112-00215aee3ebe;Загребля;Загребля;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621880703;3;1
56bdd3b4-749b-11df-b112-00215aee3ebe;Загребля;Загребля;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5622687603;3;1
56bdd3b5-749b-11df-b112-00215aee3ebe;Загребля;Загребля;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323683603;3;1
56bdd3b6-749b-11df-b112-00215aee3ebe;Загризове;Загрызово;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321080502;3;1
56bdd3b7-749b-11df-b112-00215aee3ebe;Загрунівка;Загруновка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321382001;3;1
56bdd3b8-749b-11df-b112-00215aee3ebe;Загурщина;Загурщина;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621580604;3;1
56bdd3b9-749b-11df-b112-00215aee3ebe;Задарів;Задаров;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124285001;3;1
56bdd3ba-749b-11df-b112-00215aee3ebe;Задвір'я;Задворье;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620682001;3;1
56bdd3bb-749b-11df-b112-00215aee3ebe;Задебрі;Задебри;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625889209;3;1
56bdd3bc-749b-11df-b112-00215aee3ebe;Задеревач;Задеревач;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625380303;3;1
56bdd3bd-749b-11df-b112-00215aee3ebe;Задеріївка;Задериевка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424483001;3;1
56bdd3be-749b-11df-b112-00215aee3ebe;Зади;Зады;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621282402;3;1
56bdd3bf-749b-11df-b112-00215aee3ebe;Задиби;Задыбы;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725586003;3;1
56bdd3c0-749b-11df-b112-00215aee3ebe;Задільське;Задельское;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624582432;3;1
56bdd3c1-749b-11df-b112-00215aee3ebe;Задільське;Задельское;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121584202;3;1
56bdd3c2-749b-11df-b112-00215aee3ebe;Задністря;Заднистря;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624287202;3;1
56bdd3c3-749b-11df-b112-00215aee3ebe;Задністряни;Заднестряны;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624288202;3;1
56bdd3c4-749b-11df-b112-00215aee3ebe;Задністрянське;Заднестрянское;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621283201;3;1
56bdd3c5-749b-11df-b112-00215aee3ebe;Задовга;Задовга;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322082504;3;1
56bdd3c6-749b-11df-b112-00215aee3ebe;Задовже;Задовжье;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622281603;3;1
56bdd3c7-749b-11df-b112-00215aee3ebe;Задолина;Задолина;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724280802;3;1
56bdd3c8-749b-11df-b112-00215aee3ebe;Задонецьке;Задонецкое;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321782501;3;1
56bdd3ca-749b-11df-b112-00215aee3ebe;Задорожнє;Задорожное;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321586104;3;1
56bdd3cb-749b-11df-b112-00215aee3ebe;Задорожнє;Задорожное;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324584704;3;1
56bdd3cc-749b-11df-b112-00215aee3ebe;Задорожнє;Задорожное;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523486202;3;1
56bdd3cd-749b-11df-b112-00215aee3ebe;Задорожок;Задорожок;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287304;3;1
56bdd3ce-749b-11df-b112-00215aee3ebe;Задубина;Задубина;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623386404;3;1
56bdd3cf-749b-11df-b112-00215aee3ebe;Задубрівка;Задубровка;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321584701;3;1
56bdd3d0-749b-11df-b112-00215aee3ebe;Задубрівці;Задубровцы;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625282201;3;1
56bdd3d1-749b-11df-b112-00215aee3ebe;Задунаївка;Задунаевка;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120482401;3;1
56bdd3d2-749b-11df-b112-00215aee3ebe;Заздрівка;Заздровка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821181502;3;1
56bdd3d3-749b-11df-b112-00215aee3ebe;Заздрість;Заздрость;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125083401;3;1
56bdd3d4-749b-11df-b112-00215aee3ebe;Зазим'я;Зазимье;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221282801;1;0
56bdd3d5-749b-11df-b112-00215aee3ebe;Зазірки;Зазерки;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922683901;3;1
56bdd3d6-749b-11df-b112-00215aee3ebe;Заіванове;Заиваново;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724280803;3;1
56bdd3d7-749b-11df-b112-00215aee3ebe;Заїзд;Заезд;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924183203;3;1
56bdd3d8-749b-11df-b112-00215aee3ebe;Заїзд;Заезд;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;7424183101;3;1
56bdd3d9-749b-11df-b112-00215aee3ebe;Заїки;Заики;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321387204;3;1
56bdd3da-749b-11df-b112-00215aee3ebe;Заїки;Заики;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157305;3;1
56bdd3dd-749b-11df-b112-00215aee3ebe;Заїченці;Заиченцы;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321384805;3;1
56bdd3de-749b-11df-b112-00215aee3ebe;Заїчинці;Заичинцы;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324582401;3;1
56bdd3df-749b-11df-b112-00215aee3ebe;Зайве;Зайвое;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824883902;3;1
56bdd3e0-749b-11df-b112-00215aee3ebe;Займанка;Займанка;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322284002;3;1
56bdd3e1-749b-11df-b112-00215aee3ebe;Займище;Займище;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220883602;3;1
56bdd3e2-749b-11df-b112-00215aee3ebe;Займище;Займище;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425882101;3;1
56bdd3e3-749b-11df-b112-00215aee3ebe;Займище;Займище;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425384502;3;1
56bdd3e4-749b-11df-b112-00215aee3ebe;Зайцеве;Зайцево;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224882001;3;1
56bdd3e8-749b-11df-b112-00215aee3ebe;Зайцеве;Зайцево;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422282004;3;1
56bdd3e9-749b-11df-b112-00215aee3ebe;Зайці;Зайцы;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321386903;3;1
56bdd3ea-749b-11df-b112-00215aee3ebe;Зайці;Зайцы;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322283703;3;1
56bdd3eb-749b-11df-b112-00215aee3ebe;Зайці;Зайцы;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425584602;3;1
56bdd3ec-749b-11df-b112-00215aee3ebe;Зайці Другі;Зайцы Вторые;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322283004;3;1
56bdd3ed-749b-11df-b112-00215aee3ebe;Зайців;Зайцев;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221485503;3;1
56bdd3ee-749b-11df-b112-00215aee3ebe;Зайцівка;Зайцевка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321283004;3;1
56bdd3ef-749b-11df-b112-00215aee3ebe;Зайченки;Зайченки;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322055901;3;1
56bdd3f0-749b-11df-b112-00215aee3ebe;Зайчики;Зайчики;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820982601;3;1
56bdd3f1-749b-11df-b112-00215aee3ebe;Зайчівське;Зайчевское;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823381703;3;1
56bdd3f2-749b-11df-b112-00215aee3ebe;Закаблуки;Закаблуки;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920385804;3;1
56bdd3f3-749b-11df-b112-00215aee3ebe;Закерничне;Закерничное;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624855802;3;1
56bdd3f4-749b-11df-b112-00215aee3ebe;Закичера;Закичера;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625583906;3;1
56bdd3f5-749b-11df-b112-00215aee3ebe;Закіпці;Закипцы;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625583203;3;1
56bdd3f6-749b-11df-b112-00215aee3ebe;Закіт;Закит;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820355103;3;1
56bdd3f8-749b-11df-b112-00215aee3ebe;Заклад;Заклад;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623088408;3;1
56bdd3f9-749b-11df-b112-00215aee3ebe;Заклимок;Заклимок;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924189402;3;1
56bdd3fa-749b-11df-b112-00215aee3ebe;Закомар'я;Закомарье;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620684404;3;1
56bdd3fb-749b-11df-b112-00215aee3ebe;Закомельське;Закомельское;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
56bdd3fc-749b-11df-b112-00215aee3ebe;Закомірня;Закоморня;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821183804;3;1
56bdd3fe-749b-11df-b112-00215aee3ebe;Закревки;Закревки;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124980503;3;1
56bdd3ff-749b-11df-b112-00215aee3ebe;Закревське;Закревское;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422080205;3;1
56bdd400-749b-11df-b112-00215aee3ebe;Закривець;Закривец;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621555905;3;1
56bdd401-749b-11df-b112-00215aee3ebe;Закриничне;Закриничное;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822184504;3;1
56bdd402-749b-11df-b112-00215aee3ebe;Закриниччя;Закриничье;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820681807;3;1
56bdd403-749b-11df-b112-00215aee3ebe;Закриниччя;Закриничье;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523186602;3;1
56bdd404-749b-11df-b112-00215aee3ebe;Закриниччя;Закриничье;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822755201;3;1
56bdd405-749b-11df-b112-00215aee3ebe;Закрівці;Закривцы;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623287402;3;1
56bdd406-749b-11df-b112-00215aee3ebe;Закроївщина;Закроевщина;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923586203;3;1
56bdd407-749b-11df-b112-00215aee3ebe;Закружці;Закружцы;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822183902;3;1
56bdd408-749b-11df-b112-00215aee3ebe;Закубанка;Закубанка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924180602;3;1
56bdd409-749b-11df-b112-00215aee3ebe;Закумське;Закумское;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924783802;3;1
56bdd40a-749b-11df-b112-00215aee3ebe;Закупне;Закупное;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825255400;2;1
56bdd40b-749b-11df-b112-00215aee3ebe;Закусили;Закусилы;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755127;3;1
56bdd40c-749b-11df-b112-00215aee3ebe;Закутинці;Закутинцы;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220683203;3;1
56bdd40d-749b-11df-b112-00215aee3ebe;Закутинці;Закутинцы;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820882101;3;1
56bdd40e-749b-11df-b112-00215aee3ebe;Закутнівка;Закутневка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324584001;3;1
56bdd40f-749b-11df-b112-00215aee3ebe;Залав'є;Залавье;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125086402;3;1
56bdd410-749b-11df-b112-00215aee3ebe;Залав'я;Залавье;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625081303;3;1
56bdd411-749b-11df-b112-00215aee3ebe;Залав'я;Залавье;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623885802;3;1
56bdd412-749b-11df-b112-00215aee3ebe;Залаззя;Залазье;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155116;3;1
56bdd413-749b-11df-b112-00215aee3ebe;Залазько;Залазько;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721485803;3;1
56bdd414-749b-11df-b112-00215aee3ebe;Заланів;Заланов;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624482301;3;1
56bdd415-749b-11df-b112-00215aee3ebe;Залатиха;Залатиха;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924186703;3;1
56bdd416-749b-11df-b112-00215aee3ebe;Залевки;Залевки;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123782201;3;1
56bdd417-749b-11df-b112-00215aee3ebe;Залелія;Залелия;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225683003;3;1
56bdd418-749b-11df-b112-00215aee3ebe;Заливанщина;Заливанщина;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521682403;3;1
56bdd419-749b-11df-b112-00215aee3ebe;Заливки;Заливки;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624485803;3;1
56bdd41a-749b-11df-b112-00215aee3ebe;Заливне;Заливное;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323686604;3;1
56bdd41c-749b-11df-b112-00215aee3ebe;Залижня;Залыжня;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624886903;3;1
56bdd41d-749b-11df-b112-00215aee3ebe;Залиман;Залиман;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320282801;3;1
73e94c0a-ddc1-11df-9197-00215aee3ebe;Залип'я;Залипье;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624484503;3;1
56bdd41f-749b-11df-b112-00215aee3ebe;Залисиця;Залисица;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724285303;3;1
56bdd420-749b-11df-b112-00215aee3ebe;Залишани;Залишаны;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223583601;3;1
56bdd421-749b-11df-b112-00215aee3ebe;Залібівка;Залибовка;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622683402;3;1
56bdd422-749b-11df-b112-00215aee3ebe;Залізне;Зализное;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221484004;3;1
56bdd423-749b-11df-b112-00215aee3ebe;Залізний Міст;Железный Мост;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424784002;3;1
56bdd425-749b-11df-b112-00215aee3ebe;Залізниця;Зализница;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155117;3;1
56bdd426-749b-11df-b112-00215aee3ebe;Залізниця;Зализница;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623082601;3;1
56bdd427-749b-11df-b112-00215aee3ebe;Залізничне;Зализничное;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081907;3;1
56bdd428-749b-11df-b112-00215aee3ebe;Залізничне;Зализничное;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922086902;3;1
56bdd429-749b-11df-b112-00215aee3ebe;Залізничне;Зализничное;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922985802;3;1
56bdd42a-749b-11df-b112-00215aee3ebe;Залізничне;Зализничное;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325483002;3;1
56bdd42b-749b-11df-b112-00215aee3ebe;Залізничне;Зализничное;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121682602;3;1
56bdd42c-749b-11df-b112-00215aee3ebe;Залізничне;Зализничное;село;6b07f8a2-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5121483801;3;1
56bdd430-749b-11df-b112-00215aee3ebe;Залізничне;Зализничное;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0510541000;3;1
56bdd431-749b-11df-b112-00215aee3ebe;Залізничне;Зализничное;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524381503;3;1
bfc5d217-b4fb-11e8-80d4-1c98ec135261;Залізничне (Брагинівка);Брагиновка (Железнодорожное);село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223855300;3;1
b36f1a38-db87-11df-9197-00215aee3ebe;Залізничні Бірки;Зализничные Бирки;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321780504;3;1
56bdd432-749b-11df-b112-00215aee3ebe;Залізня;Зализня;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822082103;3;1
56bdd433-749b-11df-b112-00215aee3ebe;Залізняк;Зализняк;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924782902;3;1
56bdd434-749b-11df-b112-00215aee3ebe;Залізняки;Зализняки;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323283408;3;1
56bdd436-749b-11df-b112-00215aee3ebe;Залізнячка;Зализнячка;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122255401;3;1
56bdd45d-749b-11df-b112-00215aee3ebe;Залізці;Зализцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122655300;3;1
718aaa42-17eb-11e0-82d7-00215aee3ebe;Залізці;Зализцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
56bdd437-749b-11df-b112-00215aee3ebe;Залінійне;Залинейное;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322282002;3;1
56bdd438-749b-11df-b112-00215aee3ebe;Заліси;Залесы;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724283701;3;1
56bdd439-749b-11df-b112-00215aee3ebe;Заліски;Залески;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621582704;3;1
56bdd43a-749b-11df-b112-00215aee3ebe;Заліски;Залески;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823487202;3;1
56bdd43b-749b-11df-b112-00215aee3ebe;Залісне;Залесное;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920380402;3;1
56bdd43f-749b-11df-b112-00215aee3ebe;Залісоче;Залесочье;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721881605;3;1
56bdd440-749b-11df-b112-00215aee3ebe;Залісся;Залесье;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725784603;3;1
56bdd441-749b-11df-b112-00215aee3ebe;Залісся;Залесье;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721483701;3;1
56bdd442-749b-11df-b112-00215aee3ebe;Залісся;Залесье;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123084805;3;1
56bdd443-749b-11df-b112-00215aee3ebe;Залісся;Залесье;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124280403;3;1
56bdd444-749b-11df-b112-00215aee3ebe;Залісся;Залесье;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125583201;3;1
56bdd445-749b-11df-b112-00215aee3ebe;Залісся;Залесье;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120882901;3;1
56bdd446-749b-11df-b112-00215aee3ebe;Залісся;Залесье;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620388005;3;1
56bdd447-749b-11df-b112-00215aee3ebe;Залісся;Залесье;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621888503;3;1
56bdd449-749b-11df-b112-00215aee3ebe;Залісся;Залесье;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221280802;3;1
56bdd44a-749b-11df-b112-00215aee3ebe;Залісся;Залесье;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755128;3;1
56bdd44b-749b-11df-b112-00215aee3ebe;Залісся;Залесье;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421486402;3;1
56bdd44c-749b-11df-b112-00215aee3ebe;Залісся;Залесье;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521984203;3;1
56bdd44d-749b-11df-b112-00215aee3ebe;Залісся;Залесье;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823681002;3;1
56bdd44e-749b-11df-b112-00215aee3ebe;Залісся;Залесье;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824286705;3;1
56bdd44f-749b-11df-b112-00215aee3ebe;Залісся;Залесье;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824481501;3;1
56bdd450-749b-11df-b112-00215aee3ebe;Залісся;Залесье;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825287003;2;1
56bdd451-749b-11df-b112-00215aee3ebe;Залісся;Залесье;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822483101;3;1
56bdd452-749b-11df-b112-00215aee3ebe;Залісся;Залесье;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622687604;3;1
87162616-749b-11df-b112-00215aee3ebe;Залісся (Червона Зірка);Залісся (Червоная Зирка);село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420382004;3;1
5c67ad3a-9bd6-11e8-80d4-1c98ec135261;Залісся Друге;Залісся Друге;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822483101;3;1
56bdd453-749b-11df-b112-00215aee3ebe;Залісся Перше;Залесье Первое;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822483001;2;1
56bdd454-749b-11df-b112-00215aee3ebe;Залісці;Залесцы;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725582903;3;1
56bdd455-749b-11df-b112-00215aee3ebe;Залісці;Залесцы;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724582906;3;1
56bdd456-749b-11df-b112-00215aee3ebe;Залісці;Залесцы;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125883101;3;1
56bdd457-749b-11df-b112-00215aee3ebe;Залісці;Залесцы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122482801;3;1
56bdd458-749b-11df-b112-00215aee3ebe;Залісці;Залесцы;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821882701;3;1
56bdd459-749b-11df-b112-00215aee3ebe;Заліське;Залесское;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124082401;3;1
56bdd45a-749b-11df-b112-00215aee3ebe;Залішани;Залешаны;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621888204;3;1
56bdd45b-749b-11df-b112-00215aee3ebe;Заліщики;Залещики;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121285603;3;1
56bdd45c-749b-11df-b112-00215aee3ebe;Заліщики;Залещики;місто;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122010100;2;1
56bdd45e-749b-11df-b112-00215aee3ebe;Залози;Залозы;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785505;3;1
56bdd45f-749b-11df-b112-00215aee3ebe;Залокоть;Залокоть;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621283701;3;1
56bdd460-749b-11df-b112-00215aee3ebe;Залом;Залом;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125384202;3;1
56bdd461-749b-11df-b112-00215aee3ebe;Заломи;Заломы;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522281903;3;1
56bdd462-749b-11df-b112-00215aee3ebe;Залужани;Залужаны;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5910290003;3;1
56bdd463-749b-11df-b112-00215aee3ebe;Залужани;Залужаны;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624286002;3;1
56bdd464-749b-11df-b112-00215aee3ebe;Залужани;Залужаны;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620983602;3;1
56bdd465-749b-11df-b112-00215aee3ebe;Залужани;Залужаны;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621286802;3;1
56bdd466-749b-11df-b112-00215aee3ebe;Залужжя;Залужье;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720510402;3;1
56bdd467-749b-11df-b112-00215aee3ebe;Залужжя;Залужье;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125886702;3;1
56bdd468-749b-11df-b112-00215aee3ebe;Залужжя;Залужье;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122483001;3;1
56bdd469-749b-11df-b112-00215aee3ebe;Залужжя;Залужье;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120480603;3;1
56bdd46a-749b-11df-b112-00215aee3ebe;Залужжя;Залужье;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624480802;3;1
56bdd46b-749b-11df-b112-00215aee3ebe;Залужжя;Залужье;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625884501;3;1
56bdd46c-749b-11df-b112-00215aee3ebe;Залужжя;Залужье;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620986207;3;1
56bdd46d-749b-11df-b112-00215aee3ebe;Залужжя;Залужье;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122782601;2;0
56bdd46e-749b-11df-b112-00215aee3ebe;Залужжя;Залужье;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523782001;3;1
56bdd46f-749b-11df-b112-00215aee3ebe;Залужжя;Залужье;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820383501;3;1
56bdd470-749b-11df-b112-00215aee3ebe;Залужжя;Залужье;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621882801;3;1
56bdd471-749b-11df-b112-00215aee3ebe;Залужжя;Залужье;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621687407;3;1
56bdd472-749b-11df-b112-00215aee3ebe;Залужне;Залужное;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722482205;3;1
56bdd473-749b-11df-b112-00215aee3ebe;Залужне;Залужное;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821485602;3;1
56bdd474-749b-11df-b112-00215aee3ebe;Залужне;Залужное;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522480802;3;1
56bdd475-749b-11df-b112-00215aee3ebe;Залужне;Залужное;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323688203;3;1
56bdd476-749b-11df-b112-00215aee3ebe;Залуква;Залуква;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621283301;3;1
56bdd477-749b-11df-b112-00215aee3ebe;Залухів;Залухов;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724283901;3;1
56bdd478-749b-11df-b112-00215aee3ebe;Залуцьке;Залуцкое;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924181707;3;1
56bdd479-749b-11df-b112-00215aee3ebe;Залуччя;Залучье;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
56bdd47a-749b-11df-b112-00215aee3ebe;Залуччя;Залучье;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623281803;3;1
56bdd47b-749b-11df-b112-00215aee3ebe;Залуччя;Залучье;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825283401;3;1
56bdd47c-749b-11df-b112-00215aee3ebe;Залюття;Залютье;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724288204;3;1
56bdd47d-749b-11df-b112-00215aee3ebe;Залюття;Залютье;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725081302;3;1
56bdd47e-749b-11df-b112-00215aee3ebe;Заляддя;Залядье;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422487505;3;1
56bdd4a0-749b-11df-b112-00215aee3ebe;Зам'ятниця;Замятница;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125481801;3;1
56bdd47f-749b-11df-b112-00215aee3ebe;Замагора;Замагора;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620883501;3;1
56bdd480-749b-11df-b112-00215aee3ebe;Замглай;Замглай;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424455700;3;1
56bdd481-749b-11df-b112-00215aee3ebe;Замисловичі;Замысловичи;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824482401;3;1
56bdd482-749b-11df-b112-00215aee3ebe;Заміри;Замеры;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825082203;3;1
56bdd483-749b-11df-b112-00215aee3ebe;Замірці;Замерцы;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322010104;3;1
56bdd49b-749b-11df-b112-00215aee3ebe;Замістя;Замостье;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;7424183201;3;1
56bdd486-749b-11df-b112-00215aee3ebe;Заміське;Замеськое;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321282501;3;1
56bdd487-749b-11df-b112-00215aee3ebe;Заміхів;Замехов;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823382501;3;1
56bdd488-749b-11df-b112-00215aee3ebe;Заміщина;Замощина;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625884805;3;1
56bdd489-749b-11df-b112-00215aee3ebe;Замлиння;Замлынье;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723387203;3;1
56bdd48a-749b-11df-b112-00215aee3ebe;Замлинок;Замлынок;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5622683804;3;1
56bdd48b-749b-11df-b112-00215aee3ebe;Замличі;Замличи;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722482201;3;1
56bdd48c-749b-11df-b112-00215aee3ebe;Замогила;Замогила;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323580503;3;1
56bdd48d-749b-11df-b112-00215aee3ebe;Заможне;Заможное;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320682501;3;1
56bdd48f-749b-11df-b112-00215aee3ebe;Заможне;Заможное;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520685203;3;1
56bdd490-749b-11df-b112-00215aee3ebe;Заможне;Заможное;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822082401;3;1
56bdd491-749b-11df-b112-00215aee3ebe;Заможне;Заможное;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520280605;3;1
68cf2c4f-749b-11df-b112-00215aee3ebe;Заможне (Незаможник);Заможное (Незаможник);село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121683708;3;1
56bdd492-749b-11df-b112-00215aee3ebe;Замок;Замок;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783801;3;1
56bdd493-749b-11df-b112-00215aee3ebe;Заморіївка;Замориевка;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322681104;3;1
56bdd494-749b-11df-b112-00215aee3ebe;Заморочення;Заморочення;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825582604;3;1
56bdd495-749b-11df-b112-00215aee3ebe;Замости;Замосты;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725580802;3;1
56bdd496-749b-11df-b112-00215aee3ebe;Замостище;Замостище;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620482203;3;1
56bdd497-749b-11df-b112-00215aee3ebe;Замостище;Замостище;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323810103;3;1
56bdd498-749b-11df-b112-00215aee3ebe;Замостя;Замостье;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723685504;3;1
56bdd499-749b-11df-b112-00215aee3ebe;Замостя;Замостье;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320582001;3;1
56bdd49c-749b-11df-b112-00215aee3ebe;Замочок;Замочек;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783901;3;1
56bdd49d-749b-11df-b112-00215aee3ebe;Замулинці;Замулинцы;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623286402;3;1
56bdd49e-749b-11df-b112-00215aee3ebe;Замчисько;Замчиско;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621686003;3;1
56bdd49f-749b-11df-b112-00215aee3ebe;Замшани;Замшаны;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724284201;3;1
56bdd4a1-749b-11df-b112-00215aee3ebe;Занивське;Занивское;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724286905;3;1
56bdd4a2-749b-11df-b112-00215aee3ebe;Занки, санаторій;Занки;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
56bdd4a3-749b-11df-b112-00215aee3ebe;Занфірівка;Занфировка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520383803;3;1
56bdd4a4-749b-11df-b112-00215aee3ebe;Заньки;Заньки;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825084201;3;1
56bdd4a5-749b-11df-b112-00215aee3ebe;Заньки;Заньки;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423384401;3;1
56bdd4ab-749b-11df-b112-00215aee3ebe;Заозер'я;Заозерье;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622281605;3;1
56bdd4a6-749b-11df-b112-00215aee3ebe;Заозерне;Заозерное;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723382203;3;1
56bdd4aa-749b-11df-b112-00215aee3ebe;Заозерне;Заозерное;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524381201;3;1
56bdd4ac-749b-11df-b112-00215aee3ebe;Заорілля;Заорилье;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655112;3;1
56bdd4ad-749b-11df-b112-00215aee3ebe;Заострів;Заостров;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223386403;3;1
56bdd4ae-749b-11df-b112-00215aee3ebe;Западинці;Западинцы;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822782901;3;1
56bdd4af-749b-11df-b112-00215aee3ebe;Западинці;Западинцы;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823086602;3;1
56bdd4b0-749b-11df-b112-00215aee3ebe;Западинці;Западинцы;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322687803;3;1
56bdd4b2-749b-11df-b112-00215aee3ebe;Западня;Западня;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321781502;3;1
56bdd4b3-749b-11df-b112-00215aee3ebe;Западня;Западня;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820983505;3;1
56bdd4b4-749b-11df-b112-00215aee3ebe;Запарівка;Запаровка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323955701;3;1
56bdd4b6-749b-11df-b112-00215aee3ebe;Запасне;Запасное;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955410;3;1
56bdd4b7-749b-11df-b112-00215aee3ebe;Запашка;Запашка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523186802;3;1
56bdd4b8-749b-11df-b112-00215aee3ebe;Запереводське;Запереводское;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424180603;3;1
56bdd4b9-749b-11df-b112-00215aee3ebe;Запереділля;Запеределье;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122455101;3;1
56bdd4ba-749b-11df-b112-00215aee3ebe;Запитів;Запытов;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622155400;3;0
56bdd4bb-749b-11df-b112-00215aee3ebe;Запілля;Заполье;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724282602;3;1
56bdd4bc-749b-11df-b112-00215aee3ebe;Запілля;Заполье;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723310106;3;1
56bdd4bd-749b-11df-b112-00215aee3ebe;Запілля;Заполье;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822881602;3;1
56bdd4be-749b-11df-b112-00215aee3ebe;Заплавка;Заплавка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222383001;3;1
56bdd4bf-749b-11df-b112-00215aee3ebe;Заплази;Заплазы;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123380903;3;1
56bdd4c0-749b-11df-b112-00215aee3ebe;Заплатин;Заплатин;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625388801;2;1
56bdd4c1-749b-11df-b112-00215aee3ebe;Заповіт;Заповит;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520980702;3;1
56bdd4c4-749b-11df-b112-00215aee3ebe;Запоківне;Запоковное;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;;3;1
56bdd4c5-749b-11df-b112-00215aee3ebe;Заполички;Заполички;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221081003;3;1
56bdd4c6-749b-11df-b112-00215aee3ebe;Запоріжжя;Запорожье;місто;0c6de87a-41ba-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2310100000;1;0
56bdd4c7-749b-11df-b112-00215aee3ebe;Запоріжжя;Запорожье;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222380503;3;1
56bdd4c8-749b-11df-b112-00215aee3ebe;Запоріжжя;Запорожье;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224588503;3;1
56bdd4c9-749b-11df-b112-00215aee3ebe;Запоріжжя;Запорожье;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223882503;3;1
56bdd4ca-749b-11df-b112-00215aee3ebe;Запоріжжя;Запорожье;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225855106;3;1
56bdd4cd-749b-11df-b112-00215aee3ebe;Запоріжжя;Запорожье;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520982003;3;1
56bdd4d2-749b-11df-b112-00215aee3ebe;Запоріжжя;Запорожье;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420882402;3;1
56bdd4d3-749b-11df-b112-00215aee3ebe;Запоріжжя;Запорожье;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420682802;3;1
56bdd4d4-749b-11df-b112-00215aee3ebe;Запоріжжя;Запорожье;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520384602;3;1
56bdd4d6-749b-11df-b112-00215aee3ebe;Запорізька Балка;Запорожская Балка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455111;3;1
56bdd4d7-749b-11df-b112-00215aee3ebe;Запорізьке;Запорожское;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224881305;3;1
56bdd4d8-749b-11df-b112-00215aee3ebe;Запорізьке;Запорожское;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655104;3;1
56bdd4d9-749b-11df-b112-00215aee3ebe;Запорізьке;Запорожское;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225255109;3;1
56bdd4da-749b-11df-b112-00215aee3ebe;Запорізьке;Запорожское;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455112;3;1
56bdd4db-749b-11df-b112-00215aee3ebe;Запорізьке;Запорожское;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224282302;3;1
56bdd4dc-749b-11df-b112-00215aee3ebe;Запорізьке;Запорожское;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220310105;3;1
56bdd4dd-749b-11df-b112-00215aee3ebe;Запорізьке;Запорожское;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323985504;3;1
56bdd4de-749b-11df-b112-00215aee3ebe;Запорізьке;Запорожское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321584007;3;1
56bdd4e3-749b-11df-b112-00215aee3ebe;Запорізьке;Запорожское;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420381503;3;1
56bdd4e4-749b-11df-b112-00215aee3ebe;Запорожець;Запорожец;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;;3;1
56bdd4e5-749b-11df-b112-00215aee3ebe;Запорожець;Запорожец;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224886405;3;1
56bdd4e6-749b-11df-b112-00215aee3ebe;Запорожець;Запорожец;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322182403;3;1
17af7b21-ddb9-11df-9197-00215aee3ebe;Запорожець;Запорожец;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221887003;3;1
56bdd4e9-749b-11df-b112-00215aee3ebe;Запорожці;Запорожцы;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323288903;3;1
56bdd4ea-749b-11df-b112-00215aee3ebe;Запорожчине;Запорожчино;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324885005;3;1
56bdd4eb-749b-11df-b112-00215aee3ebe;Заприп'ять;Заприпять;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724283902;3;1
56bdd4ec-749b-11df-b112-00215aee3ebe;Запруда;Запруда;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821786202;3;1
56bdd4ed-749b-11df-b112-00215aee3ebe;Запруддя;Запрудье;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721486902;3;1
56bdd4ee-749b-11df-b112-00215aee3ebe;Запруддя;Запрудье;село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223782001;3;1
56bdd4ef-749b-11df-b112-00215aee3ebe;Запрудка;Запрудка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222055102;3;1
56bdd4f1-749b-11df-b112-00215aee3ebe;Запруття;Запрутье;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625282102;3;1
56bdd4f2-749b-11df-b112-00215aee3ebe;Запсілля;Запселье;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322481301;3;1
56bdd4f3-749b-11df-b112-00215aee3ebe;Запсілля;Запселье;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922381601;3;1
c8674a8f-dbb0-11df-9197-00215aee3ebe;Запсілля;Запселье;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320283602;3;1
56bdd4f4-749b-11df-b112-00215aee3ebe;Запсільське;Запсельское;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320481702;3;1
56bdd4f5-749b-11df-b112-00215aee3ebe;Запсонь;Запсонь;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120484801;3;1
56bdd4f6-749b-11df-b112-00215aee3ebe;Запуст;Запуст;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722484407;3;1
56bdd4f7-749b-11df-b112-00215aee3ebe;Зарайське;Зарайское;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624284602;3;1
56bdd4f8-749b-11df-b112-00215aee3ebe;Зарваниця;Зарваница;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125083101;3;1
56bdd4f9-749b-11df-b112-00215aee3ebe;Зарваниця;Зарваница;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621887602;3;1
56bdd4fa-749b-11df-b112-00215aee3ebe;Зарванка;Зарванка;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524580805;3;1
56bdd4fb-749b-11df-b112-00215aee3ebe;Зарванці;Зарванцы;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520688906;2;0
9e6df81e-7f5d-11e2-ade3-003048d2b473;Заремба;Заремба;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;00000000-0000-0000-0000-000000000000;;;1
56bdd4fc-749b-11df-b112-00215aee3ebe;Заривинці;Зарывинцы;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121281001;3;1
56bdd4fd-749b-11df-b112-00215aee3ebe;Зарище;Зарище;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783206;3;1
56bdd4fe-749b-11df-b112-00215aee3ebe;Зарів'я;Заровье;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625488803;3;1
56bdd4ff-749b-11df-b112-00215aee3ebe;Заріг;Зарог;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323681301;3;1
56bdd500-749b-11df-b112-00215aee3ebe;Заріка;Зарека;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155102;3;1
56bdd501-749b-11df-b112-00215aee3ebe;Заріка;Зарека;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624881807;3;1
56bdd502-749b-11df-b112-00215aee3ebe;Заріцьк;Зарецк;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624686705;3;1
56bdd503-749b-11df-b112-00215aee3ebe;Зарічани;Заречаны;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822082501;1;0
56bdd504-749b-11df-b112-00215aee3ebe;Зарічанка;Заречанка;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825283801;2;1
56bdd506-749b-11df-b112-00215aee3ebe;Зарічка;Заречка;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822881603;3;1
56bdd507-749b-11df-b112-00215aee3ebe;Зарічне;Заречное;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920682604;3;1
56bdd508-749b-11df-b112-00215aee3ebe;Зарічне;Заречное;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925082701;3;1
56bdd509-749b-11df-b112-00215aee3ebe;Зарічне;Заречное;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225982507;3;1
56bdd50a-749b-11df-b112-00215aee3ebe;Зарічне;Заречное;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224286102;3;1
56bdd50b-749b-11df-b112-00215aee3ebe;Зарічне;Заречное;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824884202;3;1
56bdd50c-749b-11df-b112-00215aee3ebe;Зарічне;Заречное;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125082702;2;1
56bdd50d-749b-11df-b112-00215aee3ebe;Зарічне;Заречное;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323655501;3;1
56bdd50f-749b-11df-b112-00215aee3ebe;Зарічне;Заречное;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521880801;3;1
56bdd510-749b-11df-b112-00215aee3ebe;Зарічне;Заречное;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621583601;3;1
56bdd511-749b-11df-b112-00215aee3ebe;Зарічне;Заречное;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625385602;3;1
56bdd513-749b-11df-b112-00215aee3ebe;Зарічне;Заречное;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2110890001;3;1
56bdd519-749b-11df-b112-00215aee3ebe;Зарічне;Заречное;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524381701;3;1
56bdd51a-749b-11df-b112-00215aee3ebe;Зарічне;Заречное;місто;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622255100;3;1
56bdd51b-749b-11df-b112-00215aee3ebe;Зарічне;Заречное;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621281204;3;1
56bdd51c-749b-11df-b112-00215aee3ebe;Зарічне;Заречное;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625883404;3;1
c6cc1c10-db7e-11df-9197-00215aee3ebe;Зарічне;Заречное;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322282003;3;1
842b9ac8-6485-11eb-80d1-000c29800ae7;Зарічне;Заречное;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824283801;3;1
e2d6e139-f165-11e8-80d8-1c98ec135261;Зарічне;Заречное;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955411;3;1
56bdd225-749b-11df-b112-00215aee3ebe;Зарічне  (Жовтневе);Заричное (Жовтневое);село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320685804;3;1
6ed81db5-749b-11df-b112-00215aee3ebe;Зарічне (Октябрське);Заричне (Октябрьское);село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923886701;3;1
74dc4976-749b-11df-b112-00215aee3ebe;Зарічне (Петрівське);Петровское;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325483403;3;1
7ad954b7-749b-11df-b112-00215aee3ebe;Зарічне (Свердлове);Заричное (Свердлово);село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924410105;3;1
56bdd505-749b-11df-b112-00215aee3ebe;Зарічово;Заречево;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123210102;3;1
56bdd51d-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924480902;3;1
56bdd51e-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922682205;3;1
56bdd51f-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924181708;3;1
56bdd520-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723681102;3;1
56bdd521-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720581601;3;1
56bdd522-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722182805;3;1
56bdd523-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221085501;3;1
56bdd524-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7320555301;3;1
56bdd525-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;8067acf2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2610291403;3;1
56bdd526-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624081801;3;1
56bdd527-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622482207;3;1
56bdd528-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624282203;3;1
56bdd529-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621583301;3;1
56bdd52a-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625181103;3;1
56bdd52b-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625585903;3;1
56bdd52c-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121984001;2;0
56bdd52f-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221486502;3;1
56bdd530-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824282501;3;1
56bdd531-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386003;3;1
56bdd532-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825255101;3;1
56bdd533-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425882201;3;1
56bdd534-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424786503;3;1
56bdd535-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122582901;3;1
56bdd536-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520882406;3;1
56bdd537-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523183603;3;1
56bdd538-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820385504;3;1
56bdd539-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822186902;3;1
56bdd53a-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323810104;3;1
56bdd53b-749b-11df-b112-00215aee3ebe;Заріччя;Заречье;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323687402;3;1
56bdd53c-749b-11df-b112-00215aee3ebe;Заровенка;Заровенка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821783703;3;1
56bdd53d-749b-11df-b112-00215aee3ebe;Зарожани;Зарожаны;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325082601;3;1
56bdd53e-749b-11df-b112-00215aee3ebe;Зарожне;Зарожное;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325483301;3;1
56bdd540-749b-11df-b112-00215aee3ebe;Зарубани;Зарубаны;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625888203;3;1
56bdd542-749b-11df-b112-00215aee3ebe;Зарубинка;Зарубинка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822385402;3;1
56bdd543-749b-11df-b112-00215aee3ebe;Зарубинці;Зарубинцы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122483140;3;1
56bdd544-749b-11df-b112-00215aee3ebe;Зарубинці;Зарубинцы;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124682130;3;1
56bdd545-749b-11df-b112-00215aee3ebe;Зарубинці;Зарубинцы;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820384001;3;1
56bdd546-749b-11df-b112-00215aee3ebe;Зарубинці;Зарубинцы;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123481801;3;1
56bdd547-749b-11df-b112-00215aee3ebe;Заруддя;Зарудье;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322480704;3;1
56bdd548-749b-11df-b112-00215aee3ebe;Заруддя;Зарудье;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922684402;3;1
56bdd549-749b-11df-b112-00215aee3ebe;Заруддя;Зарудье;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924185001;3;1
56bdd54a-749b-11df-b112-00215aee3ebe;Заруддя;Зарудье;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122683701;3;1
56bdd54b-749b-11df-b112-00215aee3ebe;Заруддя;Зарудье;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122483301;3;1
56bdd54c-749b-11df-b112-00215aee3ebe;Заруддя;Зарудье;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222081301;3;1
56bdd54d-749b-11df-b112-00215aee3ebe;Заруддя;Зарудье;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523181701;3;1
56bdd54e-749b-11df-b112-00215aee3ebe;Заруддя;Зарудье;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824783502;3;1
56bdd54f-749b-11df-b112-00215aee3ebe;Заруддя;Зарудье;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822787403;3;1
56bdd550-749b-11df-b112-00215aee3ebe;Заруддя;Зарудье;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621682604;3;1
56bdd551-749b-11df-b112-00215aee3ebe;Зарудечко;Зарудечко;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122483902;3;1
56bdd552-749b-11df-b112-00215aee3ebe;Зарудинці;Зарудинцы;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825283501;3;1
56bdd553-749b-11df-b112-00215aee3ebe;Зарудинці;Зарудинцы;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523083401;3;1
56bdd554-749b-11df-b112-00215aee3ebe;Зарудка;Зарудка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424183402;3;1
56bdd556-749b-11df-b112-00215aee3ebe;Зарудці;Зарудцы;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784103;3;1
56bdd557-749b-11df-b112-00215aee3ebe;Зарудчі;Зарудчи;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155118;3;1
56bdd558-749b-11df-b112-00215aee3ebe;Зарукавне;Зарукавное;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420381004;3;1
56bdd559-749b-11df-b112-00215aee3ebe;Заруцьке;Заруцкое;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921581005;3;1
56bdd55a-749b-11df-b112-00215aee3ebe;Зарябинка;Зарябинка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320883001;3;1
56bdd55b-749b-11df-b112-00215aee3ebe;Засадки;Засадки;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625184503;3;1
56bdd55c-749b-11df-b112-00215aee3ebe;Засілля;Заселье;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823355701;3;1
56bdd55d-749b-11df-b112-00215aee3ebe;Заслучне;Заслучное;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822783401;3;1
56bdd55e-749b-11df-b112-00215aee3ebe;Заслуччя;Заслучье;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621883403;3;1
56bdd55f-749b-11df-b112-00215aee3ebe;Застав'є;Заставье;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723310107;3;1
56bdd560-749b-11df-b112-00215aee3ebe;Застав'є;Заставье;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620684405;3;1
56bdd56b-749b-11df-b112-00215aee3ebe;Застав'я;Заставье;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125280305;3;1
56bdd56c-749b-11df-b112-00215aee3ebe;Застав'я;Заставье;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623081005;3;1
56bdd56d-749b-11df-b112-00215aee3ebe;Застав'я;Заставье;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624684907;3;1
56bdd561-749b-11df-b112-00215aee3ebe;Заставки;Заставки;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822783701;3;1
56bdd562-749b-11df-b112-00215aee3ebe;Заставки-Яблунів;Заставки-Яблонов;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623380702;3;1
56bdd563-749b-11df-b112-00215aee3ebe;Заставля;Заставля;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821889502;3;1
56bdd564-749b-11df-b112-00215aee3ebe;Заставна;Заставна;місто;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7321510100;3;0
56bdd565-749b-11df-b112-00215aee3ebe;Заставне;Заставное;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721182404;3;1
56bdd566-749b-11df-b112-00215aee3ebe;Заставне;Заставное;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624810901;3;1
56bdd567-749b-11df-b112-00215aee3ebe;Заставне;Заставное;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621882201;3;1
56bdd568-749b-11df-b112-00215aee3ebe;Заставці;Заставцы;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124285301;3;1
56bdd569-749b-11df-b112-00215aee3ebe;Заставці;Заставцы;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824482001;3;1
56bdd56a-749b-11df-b112-00215aee3ebe;Заставче;Заставче;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124882505;3;1
56bdd56e-749b-11df-b112-00215aee3ebe;Застінка;Застенка;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125287805;3;1
56bdd56f-749b-11df-b112-00215aee3ebe;Застіноче;Застеночье;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125086205;3;1
56bdd570-749b-11df-b112-00215aee3ebe;Застугна;Застугна;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221482801;3;1
56bdd571-749b-11df-b112-00215aee3ebe;Застугна;Застугна;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223184202;3;1
56bdd572-749b-11df-b112-00215aee3ebe;Засув;Засув;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625885202;3;1
56bdd573-749b-11df-b112-00215aee3ebe;Засулля;Засулье;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923581301;3;1
56bdd574-749b-11df-b112-00215aee3ebe;Засулля;Засулье;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322886601;3;1
56bdd575-749b-11df-b112-00215aee3ebe;Засупоївка;Засупоевка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;a8bc35df-4aa1-11e3-9df6-003048d2b473;3225581801;3;1
f35cffa7-9ef5-11e0-a658-003048d2b473;Засупоївка;Засупоевка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225581801;3;1
56bdd576-749b-11df-b112-00215aee3ebe;Засядьки;Засядьки;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;;3;1
56bdd577-749b-11df-b112-00215aee3ebe;Заськи;Заськи;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824288402;3;1
56bdd578-749b-11df-b112-00215aee3ebe;Затемне;Затемное;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623383902;3;1
56bdd579-749b-11df-b112-00215aee3ebe;Затисівка;Затисовка;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121285903;3;1
56bdd57a-749b-11df-b112-00215aee3ebe;Затиша;Затиша;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220410503;3;1
56bdd57b-749b-11df-b112-00215aee3ebe;Затишне;Затышное;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124285905;3;1
56bdd57c-749b-11df-b112-00215aee3ebe;Затишне;Затышное;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955111;3;1
56bdd57d-749b-11df-b112-00215aee3ebe;Затишне;Затышное;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222082701;3;1
56bdd57f-749b-11df-b112-00215aee3ebe;Затишне;Затышное;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2110290001;3;1
56bdd582-749b-11df-b112-00215aee3ebe;Затишне;Затышное;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523283002;3;1
27ffe077-835b-11e8-80d4-1c98ec135261;Затишне;Затишне;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223284002;3;1
62c3d143-749b-11df-b112-00215aee3ebe;Затишне (Ленінівка);Затышне (Лениновка);село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220886203;3;1
56bdd583-749b-11df-b112-00215aee3ebe;Затишок;Затышок;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124383201;3;1
56bdd584-749b-11df-b112-00215aee3ebe;Затишшя;Затишье;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725783902;3;1
56bdd585-749b-11df-b112-00215aee3ebe;Затишшя;Затишье;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123410501;3;1
56bdd586-749b-11df-b112-00215aee3ebe;Затишшя;Затишье;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157306;3;1
56bdd587-749b-11df-b112-00215aee3ebe;Затишшя;Затишье;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122981702;3;1
56bdd588-749b-11df-b112-00215aee3ebe;Затишшя;Затишье;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125255400;3;1
56bdd58a-749b-11df-b112-00215aee3ebe;Затишшя;Затишье;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420610101;3;1
56bdd7be-749b-11df-b112-00215aee3ebe;Затківці Вокзал;Затковцы Вокзал;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;;3;1
56bdd58b-749b-11df-b112-00215aee3ebe;Затока;Затока;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5110345300;2;1
56bdd58c-749b-11df-b112-00215aee3ebe;Затока;Затока;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625881602;3;1
56bdd58d-749b-11df-b112-00215aee3ebe;Затоки;Затоки;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520280607;3;1
56bdd58e-749b-11df-b112-00215aee3ebe;Затон;Затон;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320255106;3;1
56bdd58f-749b-11df-b112-00215aee3ebe;Затурин;Затурин;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124882502;3;1
56bdd590-749b-11df-b112-00215aee3ebe;Затурине;Затурино;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081908;3;0
56bdd591-749b-11df-b112-00215aee3ebe;Затурці;Затурцы;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722482401;3;1
56bdd592-749b-11df-b112-00215aee3ebe;Заудайка;Заудайка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421784401;3;1
56bdd593-749b-11df-b112-00215aee3ebe;Заудайка;Заудайка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424186102;3;1
56bdd594-749b-11df-b112-00215aee3ebe;Зауддя;Заудье;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325455301;3;1
56bdd596-749b-11df-b112-00215aee3ebe;Захарівка;Захаровка;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822382203;3;1
56bdd59b-749b-11df-b112-00215aee3ebe;Захарівка;Захаровка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222082902;3;1
56bdd59c-749b-11df-b112-00215aee3ebe;Захарівка;Захаровка;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221282005;3;1
56bdd59d-749b-11df-b112-00215aee3ebe;Захарівка;Захаровка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524081401;3;1
56bdd59e-749b-11df-b112-00215aee3ebe;Захарівка;Захаровка;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525282401;3;1
a874150d-0d36-11eb-80cd-000c29800ae7;Захарівка;Захаровка;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221282001;3;1
8716233e-749b-11df-b112-00215aee3ebe;Захарівка;Захаровка;місто;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125200000;3;1
56bdd59f-749b-11df-b112-00215aee3ebe;Захарівське;Захаровское;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924782903;3;1
56bdd5a0-749b-11df-b112-00215aee3ebe;Захарівці;Захаровцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825083001;3;1
56bdd5a2-749b-11df-b112-00215aee3ebe;Західне;Захидное;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222985202;3;1
56bdd5a6-749b-11df-b112-00215aee3ebe;Західне;Захидное;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520887203;3;1
56bdd5a9-749b-11df-b112-00215aee3ebe;Зацарне;Зацарное;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924788302;3;1
56bdd5ac-749b-11df-b112-00215aee3ebe;Зачепилівка;Зачепиловка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323481201;2;1
56bdd5ad-749b-11df-b112-00215aee3ebe;Зачепилівка;Зачепиловка;місто;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322255100;3;1
56bdd5ae-749b-11df-b112-00215aee3ebe;Зачернеччя;Зачернечье;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723381901;3;1
56bdd5af-749b-11df-b112-00215aee3ebe;Зашків;Зашков;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784101;3;1
56bdd5b0-749b-11df-b112-00215aee3ebe;Зашків;Зашков;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621882602;3;1
56bdd5b1-749b-11df-b112-00215aee3ebe;Зашковичі;Зашковичи;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620983603;3;1
56bdd5b2-749b-11df-b112-00215aee3ebe;Зашляхом;Зашляхом;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122487402;3;1
325c57f8-ddd2-11df-9197-00215aee3ebe;Защита;Защита;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523882502;3;1
56bdd5b3-749b-11df-b112-00215aee3ebe;Защитів;Защитов;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722483902;3;1
56bdd5b4-749b-11df-b112-00215aee3ebe;Заяче;Заячье;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224881003;3;1
56bdd5b5-749b-11df-b112-00215aee3ebe;Заячиці;Заячицы;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722482601;3;1
56bdd5b6-749b-11df-b112-00215aee3ebe;Заячівка;Заячевка;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722187403;3;1
56bdd5b7-749b-11df-b112-00215aee3ebe;Заячківка;Заячковка;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124682001;3;1
56bdd5b8-749b-11df-b112-00215aee3ebe;Заячківка;Заячковка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124388002;3;1
56bdd5b9-749b-11df-b112-00215aee3ebe;Збараж;Збараж;місто;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122410100;1;0
56bdd5ba-749b-11df-b112-00215aee3ebe;Збараж;Збараж;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521482203;3;1
56bdd5bb-749b-11df-b112-00215aee3ebe;Збаржівка;Збаржевка;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523482201;3;1
56bdd5bc-749b-11df-b112-00215aee3ebe;Збини;Збыны;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121555301;3;1
56bdd5bd-749b-11df-b112-00215aee3ebe;Збиранка;Збиранка;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622782304;3;1
56bdd5be-749b-11df-b112-00215aee3ebe;Збитин;Збытин;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621681602;3;1
56bdd5c0-749b-11df-b112-00215aee3ebe;Збоївська;Збоевская;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623985802;3;1
56bdd5c1-749b-11df-b112-00215aee3ebe;Збора;Збора;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622880808;3;1
56bdd5c2-749b-11df-b112-00215aee3ebe;Зборів;Зборов;місто;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122610100;3;0
56bdd5c3-749b-11df-b112-00215aee3ebe;Зборів;Зборов;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623886803;3;1
56bdd5c4-749b-11df-b112-00215aee3ebe;Збраньки;Збраньки;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824288804;3;1
56bdd5c5-749b-11df-b112-00215aee3ebe;Збраньківці;Збраньковцы;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824283703;3;1
56bdd5c6-749b-11df-b112-00215aee3ebe;Збриж;Збриж;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120881203;3;1
56bdd5c7-749b-11df-b112-00215aee3ebe;Збриж;Збриж;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825287802;2;1
56bdd5c8-749b-11df-b112-00215aee3ebe;Зброжківка;Зброжковка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283402;3;1
56bdd5c9-749b-11df-b112-00215aee3ebe;Зброшкове;Зброшково;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822755102;3;1
56bdd5ca-749b-11df-b112-00215aee3ebe;Збруї;Збруи;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620386603;3;1
56bdd5cb-749b-11df-b112-00215aee3ebe;Збруч;Збруч;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822485102;3;1
56bdd5cc-749b-11df-b112-00215aee3ebe;Збручанське;Збручанское;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
56bdd5cd-749b-11df-b112-00215aee3ebe;Збручівка;Збручовка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820980303;3;1
56bdd5ce-749b-11df-b112-00215aee3ebe;Збуж;Збуж;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623487804;3;1
56bdd5d1-749b-11df-b112-00215aee3ebe;Зведенівка;Зведеновка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525382001;3;1
56bdd5d2-749b-11df-b112-00215aee3ebe;Звенигород;Звенигород;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121285403;3;1
56bdd5d3-749b-11df-b112-00215aee3ebe;Звенигород;Звенигород;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623683101;3;1
56bdd5d4-749b-11df-b112-00215aee3ebe;Звенигородка;Звенигородка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821755101;3;1
56bdd5d5-749b-11df-b112-00215aee3ebe;Звенигородка;Звенигородка;місто;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121210100;2;0
56bdd5d6-749b-11df-b112-00215aee3ebe;Звенигородка;Звенигородка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3510390401;3;1
56bdd5d7-749b-11df-b112-00215aee3ebe;Звеничів;Звеничев;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424480402;3;1
56bdd5d8-749b-11df-b112-00215aee3ebe;Звенячин;Звенячин;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321584901;3;1
56bdd5d9-749b-11df-b112-00215aee3ebe;Звертів;Звертов;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622786404;3;1
56bdd5da-749b-11df-b112-00215aee3ebe;Зверхівці;Зверховцы;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821289402;3;1
56bdd5db-749b-11df-b112-00215aee3ebe;Звижень;Звыжень;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620380402;3;1
56bdd5dc-749b-11df-b112-00215aee3ebe;Звиняч;Звиняч;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125583601;3;1
56bdd5dd-749b-11df-b112-00215aee3ebe;Звиняче;Звиняче;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720882001;3;1
56bdd5de-749b-11df-b112-00215aee3ebe;Звиняче;Звиняче;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825283302;3;1
56bdd5df-749b-11df-b112-00215aee3ebe;Звізда;Звизда;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222985003;3;1
56bdd5e0-749b-11df-b112-00215aee3ebe;Звіздівка;Звездовка;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623482601;3;1
56bdd5e2-749b-11df-b112-00215aee3ebe;Звір;Звир;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624289207;3;1
56bdd5e3-749b-11df-b112-00215aee3ebe;Звірів;Зверев;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721883601;3;1
56bdd5e4-749b-11df-b112-00215aee3ebe;Звірівка;Зверевка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524010101;3;1
56bdd5e6-749b-11df-b112-00215aee3ebe;Звози;Звозы;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721886802;3;1
56bdd5e7-749b-11df-b112-00215aee3ebe;Звонецьке;Звонецкое;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225084303;3;1
56bdd5e8-749b-11df-b112-00215aee3ebe;Звонецький Хутір;Звонецкий Хутор;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225084305;3;1
56bdd5e9-749b-11df-b112-00215aee3ebe;Зворець;Зворец;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625583908;3;1
6ed81964-749b-11df-b112-00215aee3ebe;Звягель (Новоград-Волинський);Новоград-Волынский;місто;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1811000000;2;0
56bdd5ea-749b-11df-b112-00215aee3ebe;Згар;Згар;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7110494503;3;1
56bdd5eb-749b-11df-b112-00215aee3ebe;Згар;Згар;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821582503;3;1
56bdd5ec-749b-11df-b112-00215aee3ebe;Згари;Згары;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624080602;3;1
56bdd5ed-749b-11df-b112-00215aee3ebe;Згарок;Згарок;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821583201;3;1
56bdd5ee-749b-11df-b112-00215aee3ebe;Згода;Згода;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521980705;3;1
56bdd5ef-749b-11df-b112-00215aee3ebe;Згорани;Згораны;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723382201;3;1
56bdd5f0-749b-11df-b112-00215aee3ebe;Згурівка;Згуровка;місто;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221955100;2;1
56bdd5f1-749b-11df-b112-00215aee3ebe;Здвижівка;Здвижевка;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221082501;3;1
56bdd5f2-749b-11df-b112-00215aee3ebe;Здвижка;Здвижка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510115;3;1
56bdd5f3-749b-11df-b112-00215aee3ebe;Здобуток Жовтня;Здобуток Жовтня;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124082601;3;1
56bdd5f4-749b-11df-b112-00215aee3ebe;Здовбиця;Здовбица;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621681603;3;1
56bdd5f5-749b-11df-b112-00215aee3ebe;Здовбиця;Здовбица;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622682001;3;1
56bdd5f6-749b-11df-b112-00215aee3ebe;Здолбунів;Здолбунов;місто;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622610100;2;0
56bdd5f7-749b-11df-b112-00215aee3ebe;Здомишель;Здомышель;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724284501;3;1
56bdd5f8-749b-11df-b112-00215aee3ebe;Здорівка;Здоровка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221483001;3;1
56bdd5f9-749b-11df-b112-00215aee3ebe;Здоровець;Здоровец;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821755102;3;1
56bdd5fa-749b-11df-b112-00215aee3ebe;Здрягівка;Здряговка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421481202;3;1
56bdd5fb-749b-11df-b112-00215aee3ebe;Зеблози;Зеблозы;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123489003;3;1
56bdd5fd-749b-11df-b112-00215aee3ebe;Зелена;Зеленая;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722182601;3;1
56bdd5fe-749b-11df-b112-00215aee3ebe;Зелена;Зеленая;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121281301;3;1
56bdd5ff-749b-11df-b112-00215aee3ebe;Зелена;Зеленая;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125585203;3;1
56bdd600-749b-11df-b112-00215aee3ebe;Зелена;Зеленая;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322084001;3;1
56bdd601-749b-11df-b112-00215aee3ebe;Зелена;Зеленая;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624082101;3;1
56bdd602-749b-11df-b112-00215aee3ebe;Зелена;Зеленая;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822780402;3;1
56bdd603-749b-11df-b112-00215aee3ebe;Зелена;Зеленая;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820983001;3;1
56bdd604-749b-11df-b112-00215aee3ebe;Зелена Балка;Зеленая Балка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885505;3;1
56bdd605-749b-11df-b112-00215aee3ebe;Зелена Балка;Зеленая Балка;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120485203;3;1
56bdd607-749b-11df-b112-00215aee3ebe;Зелена Балка;Зеленая Балка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521487002;3;1
56bdd608-749b-11df-b112-00215aee3ebe;Зелена Балка;Зеленая Балка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520385903;3;1
56bdd609-749b-11df-b112-00215aee3ebe;Зелена Балка;Зеленая Балка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320482403;3;1
56bdd60a-749b-11df-b112-00215aee3ebe;Зелена Гута;Зеленая Гута;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787608;3;1
56bdd60b-749b-11df-b112-00215aee3ebe;Зелена Діброва;Зеленая Диброва;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925682502;3;1
56bdd60c-749b-11df-b112-00215aee3ebe;Зелена Діброва;Зеленая Диброва;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323155102;3;1
56bdd60d-749b-11df-b112-00215aee3ebe;Зелена Діброва;Зеленая Диброва;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323686605;3;1
56bdd60e-749b-11df-b112-00215aee3ebe;Зелена Діброва;Зеленая Диброва;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825485103;3;1
56bdd60f-749b-11df-b112-00215aee3ebe;Зелена Діброва;Зеленая Диброва;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122587102;3;1
56bdd610-749b-11df-b112-00215aee3ebe;Зелена Діброва;Зеленая Диброва;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120383501;3;1
56bdd611-749b-11df-b112-00215aee3ebe;Зелена Діброва;Зеленая Диброва;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622281007;3;1
56bdd612-749b-11df-b112-00215aee3ebe;Зелена Долина;Зеленая Долина;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1213590001;3;1
56bdd613-749b-11df-b112-00215aee3ebe;Зелена Долина;Зеленая Долина;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222080502;3;1
56bdd614-749b-11df-b112-00215aee3ebe;Зелена Долина;Зеленая Долина;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224255105;3;1
56bdd615-749b-11df-b112-00215aee3ebe;Зелена Долина;Зеленая Долина;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;;3;1
56bdd617-749b-11df-b112-00215aee3ebe;Зелена Левада;Зеленая Левада;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825481203;3;1
56bdd618-749b-11df-b112-00215aee3ebe;Зелена Липа;Зеленая Липа;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325086402;3;1
56bdd61a-749b-11df-b112-00215aee3ebe;Зелена Поляна;Зеленая Поляна;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820380405;3;1
56bdd61b-749b-11df-b112-00215aee3ebe;Зелена Поляна;Зеленая Поляна;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223584001;3;1
56bdd61c-749b-11df-b112-00215aee3ebe;Зелена Поляна;Зеленая Поляна;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825481501;3;1
56bdd61d-749b-11df-b112-00215aee3ebe;Зелена Поляна;Зеленая Поляна;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422255501;3;1
56bdd61e-749b-11df-b112-00215aee3ebe;Зелена Роща;Зеленая Роща;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924787303;3;1
56bdd61f-749b-11df-b112-00215aee3ebe;Зелена Роща;Зеленая Роща;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220786605;3;1
56bdd620-749b-11df-b112-00215aee3ebe;Зелена Роща;Зеленая Роща;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424780503;3;1
56bdd622-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755122;3;1
56bdd623-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321880706;3;1
56bdd624-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920684403;3;1
56bdd625-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923582202;3;1
56bdd626-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720887602;3;1
56bdd627-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125086605;3;1
56bdd628-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121681401;3;1
56bdd629-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120855402;3;1
56bdd62a-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;4ffc4b7d-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;122188770*;3;1
56bdd62b-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223581305;3;1
56bdd62c-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221083304;3;1
56bdd62d-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224581502;3;1
56bdd62e-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224880507;3;1
56bdd62f-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225255110;3;1
56bdd630-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222983403;3;1
56bdd631-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821755109;3;1
56bdd632-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824280203;3;1
56bdd633-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325180502;3;1
56bdd634-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120884203;3;1
56bdd635-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321586105;3;1
56bdd636-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323681301;3;1
56bdd63c-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620884001;3;1
56bdd644-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221984503;3;1
56bdd645-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825284902;3;1
56bdd646-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421485350;3;1
56bdd647-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420683202;3;1
56bdd648-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520280803;3;1
56bdd649-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523881304;3;1
56bdd64a-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524981901;3;1
56bdd64b-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522883002;3;1
56bdd64c-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620885802;3;1
56bdd64d-749b-11df-b112-00215aee3ebe;Зелене;Зеленое;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621682003;3;1
bc4214f4-8356-11e8-80d4-1c98ec135261;Зелене;Зеленое;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222683307;3;1
8716259f-749b-11df-b112-00215aee3ebe;Зелене (Чапаєвка);Зеленое (Чапаевка);село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324886505;3;1
56bdd64e-749b-11df-b112-00215aee3ebe;Зелене Поле;Зеленое Поле;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221885903;3;1
56bdd651-749b-11df-b112-00215aee3ebe;Зелений Барвінок;Зеленый Барвинок;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520382602;3;1
56bdd652-749b-11df-b112-00215aee3ebe;Зелений Бір;Зеленый Бор;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221484005;3;1
56bdd654-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923583406;3;1
56bdd655-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922984203;3;1
56bdd656-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924781503;3;1
56bdd657-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122083701;3;1
56bdd658-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221487502;3;1
56bdd659-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224886803;3;1
56bdd65a-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220786611;3;1
56bdd65b-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1223886004;3;1
56bdd65c-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221882902;3;1
56bdd65d-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282507;3;1
56bdd65e-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225483201;3;1
56bdd65f-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225884804;3;1
56bdd660-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820681802;3;1
56bdd661-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;3;1
56bdd662-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824284002;3;1
56bdd663-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823381001;3;1
56bdd664-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323082401;3;1
56bdd666-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321082504;3;1
56bdd667-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323984503;3;1
56bdd668-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290010;3;1
56bdd669-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321580503;3;1
56bdd66f-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520955701;3;1
56bdd670-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520383003;3;1
56bdd672-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620984902;3;1
56bdd677-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823485803;3;1
56bdd678-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820681808;3;1
56bdd679-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424782002;3;1
56bdd67b-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425382003;3;1
56bdd67c-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123187002;3;1
56bdd67d-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522287403;3;1
56bdd67e-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522581502;3;1
56bdd67f-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520382603;3;1
56bdd680-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524984802;3;1
56bdd681-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521983003;3;1
56bdd682-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;;3;1
56bdd683-749b-11df-b112-00215aee3ebe;Зелений Гай;Зеленый Гай;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621680802;3;1
184ada29-4f76-11eb-80d0-000c29800ae7;Зелений Гай;Зелёный Гай;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;;3;1
e0517ed6-db89-11df-9197-00215aee3ebe;Зелений Гай;Зеленый Гай;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;3;1
56bdd684-749b-11df-b112-00215aee3ebe;Зелений Довжок;Зеленый Довжок;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524184803;3;1
56bdd685-749b-11df-b112-00215aee3ebe;Зелений Дуб;Зеленый Дуб;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622680802;3;1
56bdd686-749b-11df-b112-00215aee3ebe;Зелений Клин;Зеленый Клин;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224587503;3;1
56bdd687-749b-11df-b112-00215aee3ebe;Зелений Клин;Зеленый Клин;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455113;3;1
56bdd688-749b-11df-b112-00215aee3ebe;Зелений Клин;Зеленый Клин;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610108;3;1
62c3d156-749b-11df-b112-00215aee3ebe;Зелений Клин (Ленінське);Зеленый Клын (Ленинское);село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324884505;3;1
56bdd689-749b-11df-b112-00215aee3ebe;Зелений Колодязь;Зеленый Колодезь;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325455302;3;1
56bdd68b-749b-11df-b112-00215aee3ebe;Зелений Кут;Зеленый Кут;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222084503;3;1
56bdd68c-749b-11df-b112-00215aee3ebe;Зелений Кут;Зеленый Кут;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122980804;3;1
56bdd68e-749b-11df-b112-00215aee3ebe;Зелений Кут;Зеленый Кут;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323287202;3;1
56bdd68f-749b-11df-b112-00215aee3ebe;Зелений Луг;Зеленый Луг;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224581705;3;1
56bdd690-749b-11df-b112-00215aee3ebe;Зелений Луг;Зеленый Луг;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221882903;3;1
56bdd693-749b-11df-b112-00215aee3ebe;Зелений Ріг;Зеленый Рог;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120982401;3;1
56bdd694-749b-11df-b112-00215aee3ebe;Зелений Став;Зеленый Став;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225884805;3;1
56bdd695-749b-11df-b112-00215aee3ebe;Зелений Яр;Зеленый Яр;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055712;3;1
56bdd696-749b-11df-b112-00215aee3ebe;Зелений Яр;Зеленый Яр;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821455103;3;1
56bdd697-749b-11df-b112-00215aee3ebe;Зелений Яр;Зеленый Яр;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610102;3;1
56bdd698-749b-11df-b112-00215aee3ebe;Зелений Яр;Зеленый Яр;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822781501;3;1
56bdd699-749b-11df-b112-00215aee3ebe;Зелений Яр;Зеленый Яр;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824283202;3;1
56bdd69b-749b-11df-b112-00215aee3ebe;Зелений Яр;Зеленый Яр;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622884805;3;1
7baa881e-f476-11e8-80d9-1c98ec135261;Зелений Яр;Зелений Яр;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222283601;3;1
56bdd69f-749b-11df-b112-00215aee3ebe;Зелениця;Зеленица;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821782401;3;1
56bdd6a0-749b-11df-b112-00215aee3ebe;Зелениця;Зеленица;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620888302;3;1
56bdd6a1-749b-11df-b112-00215aee3ebe;Зелені Кошари;Зеленые Кошары;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825487003;3;1
56bdd6a2-749b-11df-b112-00215aee3ebe;Зелені Курилівці;Зеленые Куриловцы;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823382701;3;1
56bdd6a3-749b-11df-b112-00215aee3ebe;Зеленів;Зеленов;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322581503;3;1
56bdd6a4-749b-11df-b112-00215aee3ebe;Зеленів;Зеленов;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624481004;3;1
56bdd6a5-749b-11df-b112-00215aee3ebe;Зеленівка;Зеленовка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324587004;3;1
56bdd6a6-749b-11df-b112-00215aee3ebe;Зеленівка;Зеленовка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120886802;3;1
56bdd6aa-749b-11df-b112-00215aee3ebe;Зеленівка;Зеленовка;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420381005;3;1
56bdd6ab-749b-11df-b112-00215aee3ebe;Зеленівщина;Зеленовщина;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924185402;3;1
56bdd6ac-749b-11df-b112-00215aee3ebe;Зеленківка;Зеленковка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325481601;3;1
56bdd6ad-749b-11df-b112-00215aee3ebe;Зеленківка;Зеленковка;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923581701;3;1
56bdd6ae-749b-11df-b112-00215aee3ebe;Зеленогірське;Зеленогорское;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123355300;3;1
56bdd6b2-749b-11df-b112-00215aee3ebe;Зеленодольськ;Зеленодольск;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220310300;2;1
56bdd6b3-749b-11df-b112-00215aee3ebe;Зеленолужне;Зеленолужное;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720886402;3;1
56bdd6b4-749b-11df-b112-00215aee3ebe;Зеленопілля;Зеленополье;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283802;3;1
74dc497f-749b-11df-b112-00215aee3ebe;Зеленопілля (Петрівське);Зеленополье (Петровское);село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322188805;3;1
56bdd6b9-749b-11df-b112-00215aee3ebe;Зеленці;Зеленцы;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824289102;3;1
56bdd6ba-749b-11df-b112-00215aee3ebe;Зеленче;Зеленче;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821883001;3;1
35c979a0-d058-11df-ac87-00215aee3ebe;Зеленче ;Зеленче;;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125086605;3;1
56bdd6bc-749b-11df-b112-00215aee3ebe;Зеленянка;Зеленянка;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523083403;3;1
56bdd6bd-749b-11df-b112-00215aee3ebe;Зеленянка;Зеленянка;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521984204;3;1
56bdd6be-749b-11df-b112-00215aee3ebe;Зелень;Зелень;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621886902;3;1
56bdd6bf-749b-11df-b112-00215aee3ebe;Зеленьки;Зеленьки;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222982201;3;1
56bdd6c0-749b-11df-b112-00215aee3ebe;Зеленьків;Зеленьков;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124082801;3;1
56bdd6c1-749b-11df-b112-00215aee3ebe;Зелів;Зелив;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625883603;3;1
56bdd6c2-749b-11df-b112-00215aee3ebe;Земляне;Земляное;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922385002;3;1
56bdd6c5-749b-11df-b112-00215aee3ebe;Землянка;Землянка;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921582701;3;1
56bdd6c6-749b-11df-b112-00215aee3ebe;Землянка;Землянка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922083501;3;1
56bdd6c7-749b-11df-b112-00215aee3ebe;Землянка;Землянка;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222289202;3;1
56bdd6c8-749b-11df-b112-00215aee3ebe;Землянки;Землянки;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320682801;3;1
56bdd6ca-749b-11df-b112-00215aee3ebe;Землянки;Землянки;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323180402;3;1
56bdd6cd-749b-11df-b112-00215aee3ebe;Зеремля;Зеремля;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820682201;3;1
56bdd6ce-749b-11df-b112-00215aee3ebe;Зернове;Зерновое;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157307;3;1
56bdd6d6-749b-11df-b112-00215aee3ebe;Зибин;Зыбин;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823482203;3;1
56bdd6d9-749b-11df-b112-00215aee3ebe;Зибкове;Зыбково;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524681901;3;1
56bdd6db-749b-11df-b112-00215aee3ebe;Зимівки;Зимовки;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624585202;3;1
56bdd6dd-749b-11df-b112-00215aee3ebe;Зимна Вода;Зимняя Вода;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623681601;2;0
56bdd6de-749b-11df-b112-00215aee3ebe;Зимне;Зимнее;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720582001;3;1
56bdd6df-749b-11df-b112-00215aee3ebe;Зимницьке;Зимницкое;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822382603;3;1
56bdd6e0-749b-11df-b112-00215aee3ebe;Зимовище;Зимовище;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222086804;3;1
56bdd6e2-749b-11df-b112-00215aee3ebe;Зіболки;Зиболки;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787201;3;1
56bdd6e3-749b-11df-b112-00215aee3ebe;Зібранівка;Зибрановка;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625255303;3;1
56bdd6e4-749b-11df-b112-00215aee3ebe;Зідьки;Зидьки;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321755300;3;1
56bdd6e5-749b-11df-b112-00215aee3ebe;Зікранці;Зикранцы;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324585103;3;1
56bdd6e6-749b-11df-b112-00215aee3ebe;Зікрачі;Зикрачи;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222283801;3;1
56bdd6e7-749b-11df-b112-00215aee3ebe;Зілове;Зилово;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725582904;3;1
56bdd6e8-749b-11df-b112-00215aee3ebe;Зінове;Зиново;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923882801;3;1
56bdd6e9-749b-11df-b112-00215aee3ebe;Зінове;Зиново;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187904;3;1
56bdd6ea-749b-11df-b112-00215aee3ebe;Зінці;Зенцы;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083905;3;1
56bdd6eb-749b-11df-b112-00215aee3ebe;Зінченкове;Зинченково;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421788603;3;1
56bdd6ec-749b-11df-b112-00215aee3ebe;Зіньки;Зиньки;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820385505;3;1
56bdd6ed-749b-11df-b112-00215aee3ebe;Зіньків;Зиньков;місто;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321310100;2;0
56bdd6ee-749b-11df-b112-00215aee3ebe;Зіньків;Зиньков;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820683501;3;1
56bdd6ef-749b-11df-b112-00215aee3ebe;Зіньківське;Зиньковское;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322684003;3;1
56bdd6f0-749b-11df-b112-00215aee3ebe;Зіньківці;Зеньковцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822483201;3;0
56bdd6f1-749b-11df-b112-00215aee3ebe;Зіньківщина;Зиньковщина;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322283003;3;1
56bdd6f2-749b-11df-b112-00215aee3ebe;Зірка;Зирка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125483405;3;1
56bdd6f5-749b-11df-b112-00215aee3ebe;Зірка;Зирка;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820681802;3;1
56bdd6f6-749b-11df-b112-00215aee3ebe;Зірка;Зирка;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322682404;3;1
56bdd6f7-749b-11df-b112-00215aee3ebe;Зірне;Зорное;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620485301;3;1
56bdd6f8-749b-11df-b112-00215aee3ebe;Зірниця;Зарница;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323680403;3;1
56bdd6f9-749b-11df-b112-00215aee3ebe;Злазне;Злазне;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623483001;3;1
7ad954ba-749b-11df-b112-00215aee3ebe;Златопілля (Свердлове);Златополье (Свердлово);село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520886801;3;1
56bdd6fa-749b-11df-b112-00215aee3ebe;Златопіль;Златополь;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221884703;3;1
56bdd6fc-749b-11df-b112-00215aee3ebe;Златоустівка;Златоустовка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221882401;3;1
56bdd6fe-749b-11df-b112-00215aee3ebe;Златоустове;Златоустово;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121282101;3;1
56bdd700-749b-11df-b112-00215aee3ebe;Злинець;Злынец;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;;3;1
56bdd6ff-749b-11df-b112-00215aee3ebe;Злинка;Злынка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523181601;3;1
56bdd701-749b-11df-b112-00215aee3ebe;Злобичі;Злобичи;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386402;3;1
56bdd702-749b-11df-b112-00215aee3ebe;Змагання;Змагання;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222982806;3;1
56bdd704-749b-11df-b112-00215aee3ebe;Змажине;Змажино;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320486502;3;1
56bdd705-749b-11df-b112-00215aee3ebe;Зміїв;Змиев;місто;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321710100;2;0
56bdd706-749b-11df-b112-00215aee3ebe;Зміївка;Змиевка;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520681201;3;1
56bdd708-749b-11df-b112-00215aee3ebe;Зміїнець;Змиенец;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722883703;2;0
56bdd709-749b-11df-b112-00215aee3ebe;Змійове;Змиево;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525584803;3;1
56bdd70a-749b-11df-b112-00215aee3ebe;Змітнів;Зметнев;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424983001;3;1
56bdd711-749b-11df-b112-00215aee3ebe;Знам'янка;Знаменка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825784703;3;1
56bdd712-749b-11df-b112-00215aee3ebe;Знам'янка;Знаменка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324280501;3;1
56bdd713-749b-11df-b112-00215aee3ebe;Знам'янка;Знаменка;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520380703;3;1
56bdd717-749b-11df-b112-00215aee3ebe;Знам'янка;Знаменка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821184006;3;1
56bdd718-749b-11df-b112-00215aee3ebe;Знам'янка;Знаменка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424183401;3;1
56bdd719-749b-11df-b112-00215aee3ebe;Знам'янка;Знаменка;місто;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3510600000;2;0
8716270d-749b-11df-b112-00215aee3ebe;Знам'янка (Червонознам'янка);Знам'янка (Червонознам'янка);село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121884201;3;1
56bdd71a-749b-11df-b112-00215aee3ebe;Знам'янка Друга;Знаменка Вторая;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3510645300;3;1
56bdd70b-749b-11df-b112-00215aee3ebe;Знаменівка;Знаменовка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223282501;3;1
56bdd70e-749b-11df-b112-00215aee3ebe;Знаменівка;Знаменовка;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522883002;3;1
56bdd70f-749b-11df-b112-00215aee3ebe;Знаменівське;Знаменовское;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224855301;3;1
c8674a93-dbb0-11df-9197-00215aee3ebe;Знаменка (Червонознам'янка);Знаменка (Червонознаменка);село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321680402;3;1
56bdd710-749b-11df-b112-00215aee3ebe;Знамирівка;Знамировка;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721855704;3;1
56bdd71b-749b-11df-b112-00215aee3ebe;Значкове;Значково;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321586106;3;1
56bdd71c-749b-11df-b112-00215aee3ebe;Зноб-Новгородське;Знобь-Новгородское;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924455300;3;1
56bdd71d-749b-11df-b112-00215aee3ebe;Зноб-Трубчевська;Знобь-Трубчевская;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924481701;3;1
56bdd71e-749b-11df-b112-00215aee3ebe;Зносичі;Зносичи;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625481101;3;1
56bdd71f-749b-11df-b112-00215aee3ebe;Зняцьово;Зняцево;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122782801;2;1
56bdd720-749b-11df-b112-00215aee3ebe;Зозів;Зозов;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522281401;3;1
56bdd721-749b-11df-b112-00215aee3ebe;Зозів;Зозов;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624689503;3;1
56bdd722-749b-11df-b112-00215aee3ebe;Зозівка;Зозовка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522281201;3;1
56bdd723-749b-11df-b112-00215aee3ebe;Зозулине;Зозулино;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923883805;3;1
56bdd724-749b-11df-b112-00215aee3ebe;Зозулинці;Зозулинцы;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122084001;3;1
56bdd726-749b-11df-b112-00215aee3ebe;Зозулинці;Зозулинцы;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524885802;3;1
56bdd727-749b-11df-b112-00215aee3ebe;Зозулинці;Зозулинцы;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521482403;3;1
56bdd728-749b-11df-b112-00215aee3ebe;Зозулі;Зозули;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325785203;3;1
56bdd729-749b-11df-b112-00215aee3ebe;Зозулі;Зозули;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621882803;3;1
56bdd72a-749b-11df-b112-00215aee3ebe;Зозулі;Зозули;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221482803;3;1
56bdd72b-749b-11df-b112-00215aee3ebe;Золота Балка;Золотая Балка;село;78f4540d-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6524182001;3;1
56bdd72e-749b-11df-b112-00215aee3ebe;Золота Липа;Золотая Липа;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625687204;3;1
56bdd730-749b-11df-b112-00215aee3ebe;Золота Поляна;Золотая Поляна;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221884705;3;1
56bdd72c-749b-11df-b112-00215aee3ebe;Золота Слобода;Золотая Слобода;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123083601;3;1
56bdd733-749b-11df-b112-00215aee3ebe;Золотарівка;Золотаревка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321882201;3;1
56bdd734-749b-11df-b112-00215aee3ebe;Золотарівка;Золотаревка;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925085003;3;1
56bdd738-749b-11df-b112-00215aee3ebe;Золотарівка;Золотаревка;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525285203;3;1
56bdd732-749b-11df-b112-00215aee3ebe;Золотарьово;Золотарево;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125383201;3;1
56bdd73c-749b-11df-b112-00215aee3ebe;Золоте;Золотое;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621884303;3;1
56bdd73f-749b-11df-b112-00215aee3ebe;Золотий Потік;Золотой Поток;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121255500;3;1
56bdd740-749b-11df-b112-00215aee3ebe;Золотинка;Золотинка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425584002;3;1
56bdd742-749b-11df-b112-00215aee3ebe;Золотковичі;Золотковичи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622483201;3;1
56bdd743-749b-11df-b112-00215aee3ebe;Золотники;Золотники;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125083601;3;1
56bdd744-749b-11df-b112-00215aee3ebe;Золотницьке;Золотницкое;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224587504;3;1
56bdd745-749b-11df-b112-00215aee3ebe;Золотницьке;Золотницкое;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522883803;3;1
56bdd746-749b-11df-b112-00215aee3ebe;Золотогірка;Золотогорка;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522885004;3;1
56bdd747-749b-11df-b112-00215aee3ebe;Золотолин;Золотолин;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623483401;3;1
56bdd748-749b-11df-b112-00215aee3ebe;Золотоноша;Золотоноша;місто;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7110400000;2;0
56bdd749-749b-11df-b112-00215aee3ebe;Золотоношка;Золотоношка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120684001;3;1
56bdd74a-749b-11df-b112-00215aee3ebe;Золотуха;Золотуха;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224082402;3;1
56bdd74b-749b-11df-b112-00215aee3ebe;Золотухи;Золотухи;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323681601;3;1
56bdd74c-749b-11df-b112-00215aee3ebe;Золотухівка;Золотуховка;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323182705;3;1
56bdd74d-749b-11df-b112-00215aee3ebe;Золочів;Золочев;місто;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322655100;2;1
56bdd74e-749b-11df-b112-00215aee3ebe;Золочів;Золочев;місто;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4621810100;2;0
56bdd74f-749b-11df-b112-00215aee3ebe;Золочівка;Золочевка;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123082003;3;1
56bdd750-749b-11df-b112-00215aee3ebe;Золочівка;Золочевка;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621887603;3;1
56bdd751-749b-11df-b112-00215aee3ebe;Золочівка;Золочевка;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621482901;3;1
56bdd752-749b-11df-b112-00215aee3ebe;Золочівське;Золочевское;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321288504;3;1
56bdd753-749b-11df-b112-00215aee3ebe;Золочівське;Золочевское;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125485604;3;1
56bdd754-749b-11df-b112-00215aee3ebe;Зольня;Зольня;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824482801;3;1
56bdd758-749b-11df-b112-00215aee3ebe;Зорин;Зорин;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222080803;3;1
56bdd77d-749b-11df-b112-00215aee3ebe;Зорине;Зорино;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925682503;3;1
56bdd755-749b-11df-b112-00215aee3ebe;Зоринове;Зориново;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122755102;3;1
56bdd756-749b-11df-b112-00215aee3ebe;Зоринці;Зоринцы;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521084806;3;1
56bdd75c-749b-11df-b112-00215aee3ebe;Зорівка;Зоревка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324080703;3;1
56bdd75d-749b-11df-b112-00215aee3ebe;Зорівка;Зоревка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323685203;3;1
56bdd75e-749b-11df-b112-00215aee3ebe;Зорівка;Зоревка;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520380705;3;1
56bdd75f-749b-11df-b112-00215aee3ebe;Зорівка;Зоревка;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222284003;3;1
56bdd760-749b-11df-b112-00215aee3ebe;Зорівка;Зоревка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121584701;3;1
56bdd762-749b-11df-b112-00215aee3ebe;Зороків;Зорокив;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825684401;3;1
56bdd763-749b-11df-b112-00215aee3ebe;Зоротовичі;Зоротовичи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625183302;3;1
56bdd764-749b-11df-b112-00215aee3ebe;Зоря;Заря;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323087202;3;1
56bdd765-749b-11df-b112-00215aee3ebe;Зоря;Заря;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720510408;3;1
56bdd766-749b-11df-b112-00215aee3ebe;Зоря;Заря;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221488003;3;1
56bdd767-749b-11df-b112-00215aee3ebe;Зоря;Заря;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222083005;3;1
56bdd768-749b-11df-b112-00215aee3ebe;Зоря;Заря;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755103;3;1
56bdd769-749b-11df-b112-00215aee3ebe;Зоря;Заря;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221884502;3;1
56bdd76a-749b-11df-b112-00215aee3ebe;Зоря;Заря;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225482901;3;1
56bdd76b-749b-11df-b112-00215aee3ebe;Зоря;Заря;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823680303;3;1
56bdd76c-749b-11df-b112-00215aee3ebe;Зоря;Заря;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822055206;3;1
56bdd76d-749b-11df-b112-00215aee3ebe;Зоря;Заря;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821785002;3;1
56bdd76e-749b-11df-b112-00215aee3ebe;Зоря;Заря;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823384702;3;1
56bdd76f-749b-11df-b112-00215aee3ebe;Зоря;Заря;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5124581101;3;1
56bdd776-749b-11df-b112-00215aee3ebe;Зоря;Заря;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221281602;3;1
56bdd777-749b-11df-b112-00215aee3ebe;Зоря;Заря;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424781003;3;1
56bdd778-749b-11df-b112-00215aee3ebe;Зоря;Заря;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423600000;3;1
56bdd779-749b-11df-b112-00215aee3ebe;Зоря;Заря;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522586604;3;1
56bdd77a-749b-11df-b112-00215aee3ebe;Зоря;Заря;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624684901;2;0
56bdd77b-749b-11df-b112-00215aee3ebe;Зоря (Зоря Комунізму);Заря (Заря Коммунизма);село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224581701;3;1
56bdd77c-749b-11df-b112-00215aee3ebe;Зоря Труда;Заря Труда;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122784202;3;1
56bdd77e-749b-11df-b112-00215aee3ebe;Зоряне;Зоряное;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920655307;3;1
56bdd77f-749b-11df-b112-00215aee3ebe;Зоряне;Зоряное;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724285803;3;1
56bdd780-749b-11df-b112-00215aee3ebe;Зоряне;Зоряное;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222683301;3;1
56bdd784-749b-11df-b112-00215aee3ebe;Зоряне;Зоряное;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825283601;3;1
56bdd785-749b-11df-b112-00215aee3ebe;Зоряне;Зоряное;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124682201;3;1
56bdd786-749b-11df-b112-00215aee3ebe;Зоряне;Зоряное;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520282812;3;1
56bdd787-749b-11df-b112-00215aee3ebe;Зоряне;Зоряное;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520883203;3;1
56bdd788-749b-11df-b112-00215aee3ebe;Зоряне;Зоряное;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820683701;3;1
56bdd789-749b-11df-b112-00215aee3ebe;Зоряне;Зоряное;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623885505;3;1
6ed81b3e-749b-11df-b112-00215aee3ebe;Зоряне (Новопролетарське);Звездное (Новопролетарское);село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222384005;3;1
87162611-749b-11df-b112-00215aee3ebe;Зоряне (Червона Зірка);Зоряне (Червона Зірка);село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121081704;3;1
8716260d-749b-11df-b112-00215aee3ebe;Зоряне (Червона Зірка);Зоряное (Червоная Зирка);село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1220387708;3;1
56bdd78a-749b-11df-b112-00215aee3ebe;Зорянка;Зорянка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821785602;3;1
56bdd78b-749b-11df-b112-00215aee3ebe;Зорянське;Зорянское;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325755102;3;1
cdc44f48-dde8-11df-9197-00215aee3ebe;Зосимівка;Зосимовка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821785803;3;1
56bdd78c-749b-11df-b112-00215aee3ebe;Зоцівка;Зоцовка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421785203;3;1
56bdd78f-749b-11df-b112-00215aee3ebe;Зразкове;Зразковое;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520382604;3;1
56bdd790-749b-11df-b112-00215aee3ebe;Зрайки;Зрайки;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221682401;2;1
56bdd791-749b-11df-b112-00215aee3ebe;Зруб;Зруб;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624455306;3;1
56bdd792-749b-11df-b112-00215aee3ebe;Зруб;Зруб;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423385602;3;1
56bdd793-749b-11df-b112-00215aee3ebe;Зрубанці;Зрубанцы;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523688505;3;1
56bdd794-749b-11df-b112-00215aee3ebe;Зруб-Комарівці;Сруб-Комаровцы;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324583201;3;1
56bdd796-749b-11df-b112-00215aee3ebe;Зрубок;Зрубок;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820680402;3;1
56bdd798-749b-11df-b112-00215aee3ebe;Зубані;Зубани;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320683201;3;1
56bdd799-749b-11df-b112-00215aee3ebe;Зубарі;Зубари;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822188102;3;1
56bdd79a-749b-11df-b112-00215aee3ebe;Зубахи;Зубахи;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424487203;3;1
56bdd79b-749b-11df-b112-00215aee3ebe;Зубейки;Зубейки;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787402;3;1
56bdd79c-749b-11df-b112-00215aee3ebe;Зубенки;Зубенки;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324881902;3;1
56bdd79d-749b-11df-b112-00215aee3ebe;Зуби;Зубы;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322281904;3;1
56bdd79e-749b-11df-b112-00215aee3ebe;Зубильне;Зубильное;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722482801;3;1
56bdd79f-749b-11df-b112-00215aee3ebe;Зубів;Зубов;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125087102;3;1
56bdd7a1-749b-11df-b112-00215aee3ebe;Зубів Міст;Зубов Мост;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622183501;3;1
56bdd7a2-749b-11df-b112-00215aee3ebe;Зубівка;Зубовка;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925081202;3;1
56bdd7a3-749b-11df-b112-00215aee3ebe;Зубівка;Зубовка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323586204;3;1
56bdd7a4-749b-11df-b112-00215aee3ebe;Зубівка;Зубовка;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122783001;2;1
56bdd7a5-749b-11df-b112-00215aee3ebe;Зубівка;Зубовка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323282201;3;1
56bdd7a6-749b-11df-b112-00215aee3ebe;Зубівщина;Зубовщина;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822383002;3;1
56bdd7a7-749b-11df-b112-00215aee3ebe;Зубівщина;Зубовщина;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823980602;3;1
56bdd7a8-749b-11df-b112-00215aee3ebe;Зубків;Зубков;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624884802;3;1
56bdd7a9-749b-11df-b112-00215aee3ebe;Зубківка;Зубковка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225685003;3;1
56bdd7aa-749b-11df-b112-00215aee3ebe;Зубковичі;Зубковичи;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824483201;3;1
56bdd7ab-749b-11df-b112-00215aee3ebe;Зубове;Зубово;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320686303;3;1
56bdd7ac-749b-11df-b112-00215aee3ebe;Зуботрясівка;Зуботрясовка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221083305;3;1
56bdd7ad-749b-11df-b112-00215aee3ebe;Зубра;Зубра;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623683301;1;0
56bdd7ae-749b-11df-b112-00215aee3ebe;Зубрець;Зубрец;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121282801;3;1
56bdd7af-749b-11df-b112-00215aee3ebe;Зубринка;Зубринка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821182001;3;1
56bdd7b0-749b-11df-b112-00215aee3ebe;Зубриця;Зубрица;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625582602;3;1
56bdd7b1-749b-11df-b112-00215aee3ebe;Зубрівка;Зубровка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822485903;3;1
56bdd7b4-749b-11df-b112-00215aee3ebe;Зуївці;Зуевцы;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323282401;3;1
56bdd7b5-749b-11df-b112-00215aee3ebe;Зурівка;Зуровка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222755101;3;1
56bdd7b6-749b-11df-b112-00215aee3ebe;Зушиці;Зушици;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620986209;3;1
56bdd7b8-749b-11df-b112-00215aee3ebe;Зюбриха;Зюбриха;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123481701;3;1
56bdd7b9-749b-11df-b112-00215aee3ebe;Зюзюки;Зюзюки;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187103;3;1
56bdd7ba-749b-11df-b112-00215aee3ebe;Зяньківці;Зяньковцы;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523083601;3;1
56bdd7bb-749b-11df-b112-00215aee3ebe;Зяньківці;Зяньковцы;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821583601;3;1
56bdd7bc-749b-11df-b112-00215aee3ebe;Зятинець;Зятинец;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820655902;3;1
56bdd7bd-749b-11df-b112-00215aee3ebe;Зятківці;Зятковцы;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520882209;3;1
56bdd7bf-749b-11df-b112-00215aee3ebe;Івана-Франка (Нагуєвичі);Ивана-Франка (Нагуевичи);село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;;3;1
56bdd7c0-749b-11df-b112-00215aee3ebe;Івангород;Ивангород;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322180403;3;1
56bdd7c1-749b-11df-b112-00215aee3ebe;Івангород;Ивангород;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421784801;3;1
56bdd7c2-749b-11df-b112-00215aee3ebe;Івангород;Ивангород;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124682501;3;1
56bdd7c3-749b-11df-b112-00215aee3ebe;Івангород;Ивангород;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520583001;3;1
56bdd7c5-749b-11df-b112-00215aee3ebe;Іване-Золоте;Иване-Золотое;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122084301;3;1
56bdd7c6-749b-11df-b112-00215aee3ebe;Іване-Пусте;Иване-Пусте;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120883201;3;1
56bdd7c7-749b-11df-b112-00215aee3ebe;Іваники;Иваники;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625881302;3;1
56bdd7c8-749b-11df-b112-00215aee3ebe;Іваниківка;Иваниковка;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620483201;3;1
56bdd7c9-749b-11df-b112-00215aee3ebe;Іванине;Иванино;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424781506;3;1
56bdd7ca-749b-11df-b112-00215aee3ebe;Іваниничі;Иваниничи;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621687409;3;1
56bdd7cb-749b-11df-b112-00215aee3ebe;Іванинці;Иванинцы;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823082003;3;1
56bdd7cc-749b-11df-b112-00215aee3ebe;Іваниця;Иваница;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923582201;3;1
56bdd7cd-749b-11df-b112-00215aee3ebe;Іваниця;Иваница;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421785201;3;1
56bdd7ce-749b-11df-b112-00215aee3ebe;Іваничі;Иваничи;місто;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721155100;3;0
56bdd7cf-749b-11df-b112-00215aee3ebe;Іваничі;Иваничи;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623480804;3;1
56bdd7d0-749b-11df-b112-00215aee3ebe;Іваничі;Иваничи;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624686706;3;1
56bdd7d1-749b-11df-b112-00215aee3ebe;Іванів;Иванов;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721180404;3;1
56bdd7d2-749b-11df-b112-00215aee3ebe;Іванів;Иванов;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625188204;3;1
56bdd7d3-749b-11df-b112-00215aee3ebe;Іванів;Иванов;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521682803;3;1
17af7b17-ddb9-11df-9197-00215aee3ebe;Іванів;Иванов;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523783003;3;1
f933344b-db4e-11df-9197-00215aee3ebe;Іванівка;Ивановка;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425887003;3;1
56bdd7d4-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324582701;3;1
56bdd7d5-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321882601;3;1
56bdd7d6-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321610101;3;1
56bdd7d7-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923885002;3;1
56bdd7d8-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923287404;3;1
56bdd7d9-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921282001;3;1
56bdd7da-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722180403;3;1
56bdd7db-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724583202;3;1
56bdd7dc-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721155104;3;1
56bdd7dd-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720881402;3;1
56bdd7de-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124682301;3;1
56bdd7df-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125084101;3;1
56bdd7e0-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222386504;3;1
56bdd7e1-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;bad3ac3c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1223781401;3;1
56bdd7e2-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224555303;3;1
56bdd7e3-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224886406;3;1
56bdd7e4-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755104;3;1
56bdd7e5-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655116;3;1
56bdd7e6-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221881402;3;1
56bdd7e7-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222985004;3;1
56bdd7e8-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823981002;3;1
56bdd7e9-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825485703;3;1
56bdd7ea-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820381001;3;1
56bdd7eb-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823083002;3;1
56bdd7ec-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822382201;3;1
56bdd7ed-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821781203;3;1
56bdd7ee-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824282702;3;1
56bdd7ef-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825180701;3;1
56bdd7f0-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320888306;3;1
56bdd7f3-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325483401;3;1
56bdd7f4-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325785705;3;1
56bdd7f6-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322886504;3;1
56bdd7f7-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323986004;3;1
56bdd7f8-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320483601;3;1
56bdd7f9-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123381801;3;1
56bdd7fa-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125282505;3;1
56bdd7fb-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121683004;3;1
56bdd7fc-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;місто;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121855100;2;1
56bdd7fd-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283803;3;1
56bdd7fe-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123183002;3;1
56bdd804-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521881001;3;1
56bdd805-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520387002;3;1
56bdd809-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624886603;3;1
56bdd80b-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623383501;3;1
56bdd819-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220410504;2;1
56bdd81a-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222282002;3;1
56bdd81b-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224283201;3;1
56bdd81c-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220681801;3;1
56bdd81d-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822881303;3;1
56bdd81e-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822384603;3;1
56bdd81f-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823483601;3;1
56bdd820-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821182702;3;1
56bdd821-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822083001;1;0
56bdd822-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820685302;3;1
56bdd823-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424782001;3;1
56bdd824-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425582801;3;1
56bdd825-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420883202;3;1
56bdd826-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125183602;3;1
56bdd827-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124684003;3;1
56bdd828-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124383401;3;1
56bdd829-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125482001;3;1
56bdd82a-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521255403;3;1
56bdd82b-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520682003;3;1
56bdd82c-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523886503;3;1
56bdd82d-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523681001;3;1
56bdd82e-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524081801;3;1
56bdd82f-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520887204;3;1
56bdd830-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520587502;3;1
56bdd831-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525283501;3;1
56bdd832-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522583201;3;1
56bdd833-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520382102;3;1
56bdd834-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524982501;3;1
56bdd835-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521983001;3;1
56bdd836-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823983001;3;1
56bdd837-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820380303;3;1
56bdd838-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822186903;3;1
56bdd839-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820984405;3;1
56bdd83a-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821285802;3;1
56bdd83b-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825883603;3;1
56bdd83c-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823381002;3;1
56bdd83d-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625482503;3;1
56bdd83e-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620455705;3;1
56bdd83f-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623083001;3;1
56bdd840-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623881303;3;1
56bdd841-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625887304;3;1
56bdd842-749b-11df-b112-00215aee3ebe;Іванівка;Ивановка;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323682605;3;1
56920b06-5e23-11e2-b7ac-003048d2b473;Іванівка;Иванивка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
56bdd843-749b-11df-b112-00215aee3ebe;Іванівка Друга;Ивановка Вторая;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320483601;3;1
56bdd844-749b-11df-b112-00215aee3ebe;Іванівське;Ивановское;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923888402;3;1
56bdd845-749b-11df-b112-00215aee3ebe;Іванівське;Ивановское;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755123;3;1
56bdd846-749b-11df-b112-00215aee3ebe;Іванівське;Ивановское;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323686204;3;1
6ed81db8-749b-11df-b112-00215aee3ebe;Іванівське ( Октябрське );Октябрьское;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323384501;3;1
56bdd847-749b-11df-b112-00215aee3ebe;Іванівці;Ивановцы;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322084401;3;1
56bdd848-749b-11df-b112-00215aee3ebe;Іванівці;Ивановцы;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621585903;3;1
56bdd849-749b-11df-b112-00215aee3ebe;Іванівці;Ивановцы;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122783201;3;1
56bdd84a-749b-11df-b112-00215aee3ebe;Іванівці;Ивановцы;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522486802;3;1
56bdd84b-749b-11df-b112-00215aee3ebe;Іванівці;Ивановцы;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520281801;3;1
56bdd84c-749b-11df-b112-00215aee3ebe;Іванівці;Ивановцы;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524085603;3;1
56bdd84d-749b-11df-b112-00215aee3ebe;Іванівці;Ивановцы;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820981905;3;1
56bdd80a-749b-11df-b112-00215aee3ebe;Іванівці;Ивановцы;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623282501;3;1
56bdd84e-749b-11df-b112-00215aee3ebe;Іванівщина;Ивановщина;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821486703;3;1
56bdd84f-749b-11df-b112-00215aee3ebe;Іванків;Иванков;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120883001;3;1
56bdd850-749b-11df-b112-00215aee3ebe;Іванків;Иванков;місто;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222055100;2;1
56bdd851-749b-11df-b112-00215aee3ebe;Іванків;Иванков;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222484202;3;1
56bdd852-749b-11df-b112-00215aee3ebe;Іванків;Иванков;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220884001;3;0
56bdd853-749b-11df-b112-00215aee3ebe;Іванків;Иванков;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825684403;3;1
56bdd854-749b-11df-b112-00215aee3ebe;Іванків;Иванков;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820384401;3;1
56bdd869-749b-11df-b112-00215aee3ebe;Іванків;Иванков;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525684602;3;1
56bdd855-749b-11df-b112-00215aee3ebe;Іванківці;Иванковцы;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123482905;3;1
56bdd856-749b-11df-b112-00215aee3ebe;Іванківці;Иванковцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122685402;3;1
56bdd857-749b-11df-b112-00215aee3ebe;Іванківці;Иванковцы;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123884001;3;1
56bdd858-749b-11df-b112-00215aee3ebe;Іванківці;Иванковцы;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322583501;3;1
56bdd859-749b-11df-b112-00215aee3ebe;Іванківці;Иванковцы;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822081303;3;1
56bdd85a-749b-11df-b112-00215aee3ebe;Іванківці;Иванковцы;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823186603;3;1
56bdd85b-749b-11df-b112-00215aee3ebe;Іванківці;Иванковцы;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820882401;3;1
56bdd85c-749b-11df-b112-00215aee3ebe;Іванківці;Иванковцы;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425155402;3;1
56bdd85d-749b-11df-b112-00215aee3ebe;Іванківці;Иванковцы;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521482806;3;1
56bdd85e-749b-11df-b112-00215aee3ebe;Іванківці;Иванковцы;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524583301;3;1
56bdd85f-749b-11df-b112-00215aee3ebe;Іванківці;Иванковцы;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522281901;3;1
56bdd860-749b-11df-b112-00215aee3ebe;Іванківці;Иванковцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825085103;3;0
56bdd861-749b-11df-b112-00215aee3ebe;Іванківці;Иванковцы;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824455101;3;1
56bdd862-749b-11df-b112-00215aee3ebe;Іванківці;Иванковцы;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821282501;3;1
56bdd863-749b-11df-b112-00215aee3ebe;Іванківці;Иванковцы;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825884002;3;1
56bdd864-749b-11df-b112-00215aee3ebe;Іванківці;Иванковцы;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821883301;3;1
56bdd865-749b-11df-b112-00215aee3ebe;Іванківці;Иванковцы;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823388502;3;1
56bdd866-749b-11df-b112-00215aee3ebe;Іванківці;Иванковцы;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623886404;3;1
56bdd868-749b-11df-b112-00215aee3ebe;Іванковичі;Иванковичи;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221483301;3;1
56bdd867-749b-11df-b112-00215aee3ebe;Іванковка;Иванковка;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622880805;3;1
56bdd86a-749b-11df-b112-00215aee3ebe;Іванне;Иванье;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621682001;3;1
56bdd86b-749b-11df-b112-00215aee3ebe;Івання;Иванья;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123487602;3;1
56bdd86c-749b-11df-b112-00215aee3ebe;Івано-Благодатне;Ивано-Благодатное;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522583302;3;1
7ad954b8-749b-11df-b112-00215aee3ebe;Іванове (Свердлове);Иваново (Свердлово);село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122785101;3;1
56bdd86d-749b-11df-b112-00215aee3ebe;Іванове Селище;Иваново Селище;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320683601;3;1
cf3f6437-dbb9-11df-9197-00215aee3ebe;Івановецьке;Ивановецкое;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520281003;3;1
56bdd86e-749b-11df-b112-00215aee3ebe;Івановичі;Ивановичи;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825481801;3;1
56bdd86f-749b-11df-b112-00215aee3ebe;Івановичі;Ивановичи;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825686202;3;1
56bdd870-749b-11df-b112-00215aee3ebe;Івано-Ганнівка;Ивано-Анновка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322186802;3;1
56bdd872-749b-11df-b112-00215aee3ebe;Івано-Кепине;Ивано-Кепино;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825783704;3;1
56bdd873-749b-11df-b112-00215aee3ebe;Івано-Межеріцьке;Ивано-Межерецкое;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955105;3;1
56bdd874-749b-11df-b112-00215aee3ebe;Івано-Миколаївка;Ивано-Николаевка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123985402;3;1
56bdd875-749b-11df-b112-00215aee3ebe;Іваномисль;Иваномысль;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721486402;3;1
56bdd876-749b-11df-b112-00215aee3ebe;Івано-Михайлівка;Ивано-Михайловка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223284505;3;1
56bdd878-749b-11df-b112-00215aee3ebe;Іванопіль;Иванополь;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386019;3;1
56bdd879-749b-11df-b112-00215aee3ebe;Іванопіль;Иванополь;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825855600;3;1
56bdd87a-749b-11df-b112-00215aee3ebe;Іванопіль;Иванополь;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522487002;3;1
56bdd87b-749b-11df-b112-00215aee3ebe;Івано-Слиньківка;Ивано-Слиньковка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324882004;3;1
56bdd87c-749b-11df-b112-00215aee3ebe;Івано-Франківськ;Ивано-Франковск;місто;a1e9f9b8-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2610100000;1;0
56bdd87d-749b-11df-b112-00215aee3ebe;Івано-Франкове;Ивано-Франково;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;7d1766f6-b6ff-11eb-80d4-000c29800ae7;4625855300;3;0
56bdd87e-749b-11df-b112-00215aee3ebe;Івано-Шийчине;Ивано-Шийчино;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320883501;3;1
56bdd87f-749b-11df-b112-00215aee3ebe;Івано-Яризівка;Ивано-Яризовка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655113;3;1
56bdd880-749b-11df-b112-00215aee3ebe;Іванпуть;Иванпуть;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424782003;3;1
56bdd881-749b-11df-b112-00215aee3ebe;Іванці;Иванцы;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324885006;3;1
56bdd882-749b-11df-b112-00215aee3ebe;Іванчани;Иванчаны;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122483601;3;1
56bdd883-749b-11df-b112-00215aee3ebe;Іванчанка;Иванчанка;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124785602;3;1
56bdd884-749b-11df-b112-00215aee3ebe;Іванчиці;Иванчицы;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724582601;3;1
56bdd885-749b-11df-b112-00215aee3ebe;Іванчиці;Иванчицы;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622255101;3;1
56bdd886-749b-11df-b112-00215aee3ebe;Іванчі;Иванчи;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620888003;3;1
56bdd887-749b-11df-b112-00215aee3ebe;Іванчуківка;Иванчуковка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322883501;3;1
56bdd888-749b-11df-b112-00215aee3ebe;Іваньки;Иваньки;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123182501;3;1
56bdd889-749b-11df-b112-00215aee3ebe;Іваньки;Иваньки;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523487002;3;1
56bdd88a-749b-11df-b112-00215aee3ebe;Іваньки;Иваньки;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522282001;3;1
56bdd88b-749b-11df-b112-00215aee3ebe;Іваньків;Иванков;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422284003;3;1
56bdd88c-749b-11df-b112-00215aee3ebe;Іваньків;Иванков;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122087404;3;1
56bdd88d-749b-11df-b112-00215aee3ebe;Івахи;Ивахи;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920387506;3;1
56bdd88e-749b-11df-b112-00215aee3ebe;Івахни;Ивахны;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123481501;3;1
56bdd88f-749b-11df-b112-00215aee3ebe;Івахнівка;Ивахновка;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922380802;3;1
56bdd890-749b-11df-b112-00215aee3ebe;Івахнівці;Ивахновцы;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825284201;2;1
56bdd891-749b-11df-b112-00215aee3ebe;Івачів;Ивачев;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122681302;3;1
56bdd892-749b-11df-b112-00215aee3ebe;Івачів Горішній;Ивачов Горишный;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125286002;3;1
56bdd893-749b-11df-b112-00215aee3ebe;Івачів Долішній;Ивачов Долишный;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125286001;2;1
56bdd894-749b-11df-b112-00215aee3ebe;Івачкове;Ивачково;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5622683805;3;1
56bdd895-749b-11df-b112-00215aee3ebe;Івашинівка;Ивашиновка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224581801;3;1
56bdd896-749b-11df-b112-00215aee3ebe;Івашки;Ивашки;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324085205;3;0
56bdd897-749b-11df-b112-00215aee3ebe;Івашки;Ивашки;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322684403;3;1
56bdd899-749b-11df-b112-00215aee3ebe;Івашків;Ивашков;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122582601;3;1
56bdd89a-749b-11df-b112-00215aee3ebe;Івашківка;Ивашковка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824085602;3;1
56bdd89b-749b-11df-b112-00215aee3ebe;Івашківка;Ивашковка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421484401;3;1
56bdd89c-749b-11df-b112-00215aee3ebe;Івашківці;Ивашковцы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122483003;3;1
56bdd89d-749b-11df-b112-00215aee3ebe;Івашківці;Ивашковцы;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625584402;3;1
56bdd89e-749b-11df-b112-00215aee3ebe;Івашківці;Ивашковцы;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525382201;3;1
56bdd89f-749b-11df-b112-00215aee3ebe;Івашківці;Ивашковцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825083303;3;1
56bdd8a0-749b-11df-b112-00215aee3ebe;Івашківці;Ивашковцы;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823383001;3;1
56bdd8a1-749b-11df-b112-00215aee3ebe;Івашкове;Ивашково;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221084403;3;1
56bdd8a2-749b-11df-b112-00215aee3ebe;Івашковиця;Ивашковица;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121983603;3;1
56bdd8a3-749b-11df-b112-00215aee3ebe;Іващенки;Иващенки;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323285602;3;1
56bdd8a4-749b-11df-b112-00215aee3ebe;Іващенки;Иващенки;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324881905;3;1
56bdd8a6-749b-11df-b112-00215aee3ebe;Іващенкове;Иващенково;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921583001;3;1
56bdd8a7-749b-11df-b112-00215aee3ebe;Іващуки;Иващуки;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625883401;3;1
e3fe448d-6021-11e8-80d4-1c98ec135261;Іверське;Иверское;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225083001;3;1
50c59405-749b-11df-b112-00215aee3ebe;Іверське (Дзержинівка);Иверское (Дзержиновка);село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225083001;3;1
56bdd8aa-749b-11df-b112-00215aee3ebe;Івки;Ивки;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220687602;3;1
56bdd8ab-749b-11df-b112-00215aee3ebe;Івки;Ивки;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824482501;3;1
56bdd8ac-749b-11df-b112-00215aee3ebe;Івківці;Ивковцы;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424183701;3;1
56bdd8ad-749b-11df-b112-00215aee3ebe;Івківці;Ивковцы;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125483504;3;1
56bdd8af-749b-11df-b112-00215aee3ebe;Івниця;Ивница;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820384801;3;1
56bdd8b0-749b-11df-b112-00215aee3ebe;Івнівка;Івновка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;;3;1
56bdd8b1-749b-11df-b112-00215aee3ebe;Іволжанське;Иволжанское;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924785903;3;1
56bdd8b2-749b-11df-b112-00215aee3ebe;Івонівка;Ивоновка;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522688002;3;1
56bdd8b3-749b-11df-b112-00215aee3ebe;Івонівці;Ивоновцы;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524583203;3;1
167680e2-5dbe-11e3-ae68-003048d2b473;Івонченці ;Ежаковка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;;3;1
56bdd8b4-749b-11df-b112-00215aee3ebe;Івот;Ивот;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925381701;3;1
56bdd8b5-749b-11df-b112-00215aee3ebe;Івотка;Ивотка;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925655102;3;1
56bdd8b6-749b-11df-b112-00215aee3ebe;Івча;Ивча;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522483201;3;1
56bdd8b7-749b-11df-b112-00215aee3ebe;Ігнатпіль;Игнатполь;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824282701;3;1
56bdd8b8-749b-11df-b112-00215aee3ebe;Ігорівка;Игоревка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920984102;3;1
56bdd8bb-749b-11df-b112-00215aee3ebe;Ігровиця;Игровица;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125283401;2;1
56bdd8bd-749b-11df-b112-00215aee3ebe;Іжицьке;Ижицкое;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825184804;3;1
56bdd8be-749b-11df-b112-00215aee3ebe;Іза;Иза;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125383601;2;0
e0517ed8-db89-11df-9197-00215aee3ebe;Ізбашівка;Избашевка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822783512;3;1
f1f523fd-ec60-11e1-a474-003048d2b473;Іздецьке;Издецкое;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;;3;1
56bdd8c3-749b-11df-b112-00215aee3ebe;Ізки;Изки;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122481601;3;1
56bdd8c4-749b-11df-b112-00215aee3ebe;Ізлучисте;Излучистое;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225283803;3;1
56bdd8c5-749b-11df-b112-00215aee3ebe;Ізмаїл;Измаил;місто;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5110600000;1;0
56bdd8c7-749b-11df-b112-00215aee3ebe;Ізмайлівка;Измайловка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520383501;3;1
56bdd8cb-749b-11df-b112-00215aee3ebe;Ізов;Изов;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0720510409;3;1
56bdd8cd-749b-11df-b112-00215aee3ebe;Ізюм;Изюм;місто;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6310400000;2;1
56bdd8cf-749b-11df-b112-00215aee3ebe;Ізюмське;Изюмское;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321082001;3;1
56bdd8d0-749b-11df-b112-00215aee3ebe;Ізяслав;Изяслав;місто;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822110100;2;0
56bdd8d1-749b-11df-b112-00215aee3ebe;Іква;Иква;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123481405;3;1
56bdd8d3-749b-11df-b112-00215aee3ebe;Ілавче;Илавче;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125084301;3;1
56bdd8d4-749b-11df-b112-00215aee3ebe;Іларіонове;Илларионово;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224855300;2;0
56bdd8d5-749b-11df-b112-00215aee3ebe;Ілемня;Илемня;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624881501;3;1
56bdd8d6-749b-11df-b112-00215aee3ebe;Ілів;Илов;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623088409;3;1
56bdd8d8-749b-11df-b112-00215aee3ebe;Іллівка;Ильевка;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521984402;3;1
56bdd8d9-749b-11df-b112-00215aee3ebe;Іллімка;Ильимка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824285202;3;1
56bdd8da-749b-11df-b112-00215aee3ebe;Іллін;Ильин;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621282003;3;1
56bdd8db-749b-11df-b112-00215aee3ebe;Іллінецьке;Ильинецкое;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521282603;3;1
56bdd8dc-749b-11df-b112-00215aee3ebe;Іллінка;Ильинка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222084504;3;1
56bdd8dd-749b-11df-b112-00215aee3ebe;Іллінка;Ильинка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1211390004;3;1
56bdd8de-749b-11df-b112-00215aee3ebe;Іллінка;Ильинка;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121082801;3;1
56bdd8e6-749b-11df-b112-00215aee3ebe;Іллінське;Иллинское;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923888105;3;1
56bdd8e7-749b-11df-b112-00215aee3ebe;Іллінське;Иллинское;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221986403;3;1
56bdd8e8-749b-11df-b112-00215aee3ebe;Іллінці;Ильинцы;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625255304;3;1
56bdd8e9-749b-11df-b112-00215aee3ebe;Іллінці;Ильинцы;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420880402;3;1
56bdd8ea-749b-11df-b112-00215aee3ebe;Іллінці;Ильинцы;місто;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521210100;2;0
56bdd8f3-749b-11df-b112-00215aee3ebe;Іллічанка (Іллічівка);Ильичанка (Ильичевка);село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122782602;1;1
56bdd8f6-749b-11df-b112-00215aee3ebe;Іллічівка;Ильичевка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521486003;3;1
56bdd8f7-749b-11df-b112-00215aee3ebe;Іллічівка;Ильичевка;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525280502;3;1
56bdd8f1-749b-11df-b112-00215aee3ebe;Іллічівка;Ильичевка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821481001;3;1
56bdd8fb-749b-11df-b112-00215aee3ebe;Ілля;Илья;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123181307;3;1
56bdd8fc-749b-11df-b112-00215aee3ebe;Ілляшівка;Ильяшевка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821787302;3;1
56bdd8fd-749b-11df-b112-00215aee3ebe;Ілляшівка;Ильяшевка;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524181801;3;1
56bdd8fe-749b-11df-b112-00215aee3ebe;Ілляшівка;Ильяшевка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824283601;3;1
56bdd8ff-749b-11df-b112-00215aee3ebe;Ілляшівка;Ильяшевка;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624283605;3;1
56bdd901-749b-11df-b112-00215aee3ebe;Ілятка;Илятка;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824482002;3;1
56bdd902-749b-11df-b112-00215aee3ebe;Ільківка;Ильковка;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520682203;3;1
56bdd903-749b-11df-b112-00215aee3ebe;Ільківці;Ильковцы;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122780405;3;1
56bdd904-749b-11df-b112-00215aee3ebe;Ільківці;Ильковцы;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824782501;3;1
56bdd905-749b-11df-b112-00215aee3ebe;Ільковичі;Ильковичи;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624886402;3;1
56bdd906-749b-11df-b112-00215aee3ebe;Ількуча;Илькуча;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425888802;3;1
56bdd907-749b-11df-b112-00215aee3ebe;Ільмівка;Ильмовка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421484801;3;1
56bdd908-749b-11df-b112-00215aee3ebe;Ільник;Ильник;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625583201;3;1
56bdd909-749b-11df-b112-00215aee3ebe;Ільниця;Ильница;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121984501;3;0
56bdd90a-749b-11df-b112-00215aee3ebe;Ільпибоки;Ильпибоки;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621483003;3;1
56bdd90b-749b-11df-b112-00215aee3ebe;Ільпінь;Ильпень;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622680602;3;1
56bdd90c-749b-11df-b112-00215aee3ebe;Ільці;Ильцы;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620884101;3;1
56bdd90e-749b-11df-b112-00215aee3ebe;Ільченкове;Ильченково;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120386002;3;1
56bdd90f-749b-11df-b112-00215aee3ebe;Імені Горького;Имени Горького;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
56bdd910-749b-11df-b112-00215aee3ebe;Імстичово;Имстичево;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121984401;3;1
56bdd911-749b-11df-b112-00215aee3ebe;Імшана;Имшана;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925655103;3;1
56bdd912-749b-11df-b112-00215aee3ebe;Інгулець;Ингулец;село;4ffc4b7d-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221887704;3;1
56bdd913-749b-11df-b112-00215aee3ebe;Інгулець;Ингулец;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221887704;3;1
56bdd914-749b-11df-b112-00215aee3ebe;Інгулець;Ингулец;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885502;3;1
56bdd915-749b-11df-b112-00215aee3ebe;Інгулець;Ингулец;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520382001;3;1
56bdd916-749b-11df-b112-00215aee3ebe;Інгулівка;Ингуловка;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520682403;3;1
56bdd917-749b-11df-b112-00215aee3ebe;Інгулка;Ингулка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820681201;3;1
56bdd918-749b-11df-b112-00215aee3ebe;Інгуло-Кам'янка;Ингуло-Каменка;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523481301;3;1
56bdd919-749b-11df-b112-00215aee3ebe;Інгульське;Ингульское;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525884901;3;1
56bdd91d-749b-11df-b112-00215aee3ebe;Інженерівка;Инженеровка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522884602;3;1
56bdd91f-749b-11df-b112-00215aee3ebe;Інженерний;Інженерный;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;;3;1
56bdd923-749b-11df-b112-00215aee3ebe;Інтернаціональне;Интернациональное;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;*122078770;3;1
56bdd924-749b-11df-b112-00215aee3ebe;Іонине;Ионино;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921586002;3;1
56bdd925-749b-11df-b112-00215aee3ebe;Іорданівка;Иордановка;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622483203;3;1
56bdd926-749b-11df-b112-00215aee3ebe;Іракліївка;Ираклиевка;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522682802;3;1
f9333452-db4e-11df-9197-00215aee3ebe;Ірдинівка;Ирдыновка;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7110590001;3;1
56bdd927-749b-11df-b112-00215aee3ebe;Ірдинь;Ирдынь;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124955300;3;1
56bdd928-749b-11df-b112-00215aee3ebe;Іржавець;Иржавец;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421785601;3;1
56bdd929-749b-11df-b112-00215aee3ebe;Іржавець;Иржавец;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423881401;3;1
56bdd92a-749b-11df-b112-00215aee3ebe;Іринівка;Ириновка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655501;3;1
56bdd92b-749b-11df-b112-00215aee3ebe;Іркліїв;Ирклиев;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125182801;3;1
56bdd92c-749b-11df-b112-00215aee3ebe;Ірлява;Ирлява;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124882701;3;1
56bdd92e-749b-11df-b112-00215aee3ebe;Ірпінь;Ирпень;місто;903cca8d-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3210900000;2;0
56bdd92f-749b-11df-b112-00215aee3ebe;Ірша;Ирша;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825084301;3;1
56bdd930-749b-11df-b112-00215aee3ebe;Іршава;Иршава;місто;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121910100;2;0
56bdd931-749b-11df-b112-00215aee3ebe;Іршанськ;Иршанск;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821155400;3;0
3361d072-d858-11e0-a755-003048d2b473;Іршанськ;Иршанск;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821155400;3;1
56bdd932-749b-11df-b112-00215aee3ebe;Іршики;Иршики;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824283901;3;1
56bdd933-749b-11df-b112-00215aee3ebe;Ісаєве;Исаево;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123581801;3;1
56bdd934-749b-11df-b112-00215aee3ebe;Ісаї;Исаи;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625583401;3;1
56bdd935-749b-11df-b112-00215aee3ebe;Ісайки;Исайки;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220682201;3;1
56bdd936-749b-11df-b112-00215aee3ebe;Ісаків;Исаков;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625684301;3;1
56bdd937-749b-11df-b112-00215aee3ebe;Ісаківка;Исаковка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821186502;3;1
56bdd938-749b-11df-b112-00215aee3ebe;Ісаківці;Исаковци;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822485103;3;1
56bdd939-749b-11df-b112-00215aee3ebe;Ісківці;Исковцы;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322682801;3;1
56bdd93a-749b-11df-b112-00215aee3ebe;Ісківці;Исковцы;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322882701;3;1
56bdd93b-749b-11df-b112-00215aee3ebe;Іскорость;Искорость;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386603;3;1
56bdd93c-749b-11df-b112-00215aee3ebe;Іскра;Искра;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322886505;3;1
56bdd940-749b-11df-b112-00215aee3ebe;Іскрене;Искреное;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125782601;3;1
56bdd941-749b-11df-b112-00215aee3ebe;Іскриня;Искриня;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522482202;3;1
56bdd942-749b-11df-b112-00215aee3ebe;Іскрисківщина;Искрисковщина;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920683501;3;1
56bdd943-749b-11df-b112-00215aee3ebe;Іскрівка;Искровка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325455302;3;1
56bdd944-749b-11df-b112-00215aee3ebe;Іскрівка;Искровка;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524584403;3;1
56bdd945-749b-11df-b112-00215aee3ebe;Іскрівка;Искровка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524982901;3;1
56bdd946-749b-11df-b112-00215aee3ebe;Іспас;Испас;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320582501;3;1
56bdd949-749b-11df-b112-00215aee3ebe;Іценків;Иценков;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421789003;3;1
56bdd94a-749b-11df-b112-00215aee3ebe;Ічня;Ичня;місто;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;7421710100;3;0
56bdd94b-749b-11df-b112-00215aee3ebe;Ішків;Ишков;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123084001;3;1
56bdd94d-749b-11df-b112-00215aee3ebe;Іщенка;Ищенка;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520981503;3;1
56bdd94e-749b-11df-b112-00215aee3ebe;Іщенківка;Ищенковка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321381702;3;1
56bdd8a9-749b-11df-b112-00215aee3ebe;Ївженки;Ивженки;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323810105;3;1
56bdd94f-749b-11df-b112-00215aee3ebe;Їжаківка;Ежаковка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081909;3;1
56bdd950-749b-11df-b112-00215aee3ebe;Їжівці;Иживцы;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324583501;3;1
56bdd951-749b-11df-b112-00215aee3ebe;Їздецьке;Ездецкое;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921280503;3;1
56bdd952-749b-11df-b112-00215aee3ebe;Йоничі;Йоничи;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785209;3;1
56bdd953-749b-11df-b112-00215aee3ebe;Йорданешти;Йорданешты;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321082001;3;1
56bdd954-749b-11df-b112-00215aee3ebe;Йосипенки;Йосипенки;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523083404;3;1
56bdd955-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084203;3;1
56bdd956-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122682502;3;1
56bdd957-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123085202;3;1
56bdd958-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125283701;3;1
56bdd959-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222384006;3;1
56bdd95a-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;;3;1
56bdd95b-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822380802;3;1
56bdd95c-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321584902;3;1
56bdd95d-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;6b07f835-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5124380402;3;1
56bdd95e-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125281301;3;1
56bdd95f-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125455101;3;1
56bdd960-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123781601;3;1
56bdd961-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624487802;3;1
56bdd962-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623984502;3;1
56bdd963-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620684406;3;1
56bdd964-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220410505;3;1
56bdd965-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821781002;3;1
56bdd966-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823484001;3;1
56bdd967-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820986005;3;1
56bdd968-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820682601;3;1
56bdd969-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825884004;3;1
56bdd96a-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825280402;3;1
56bdd96b-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521482603;3;1
56bdd96d-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524955101;3;1
56bdd96e-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523882501;3;1
56bdd96f-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525582501;3;1
56bdd970-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524381501;3;1
56bdd971-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524983001;3;1
56bdd972-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824288005;3;1
56bdd973-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824455102;3;1
56bdd974-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825282303;2;1
56bdd975-749b-11df-b112-00215aee3ebe;Йосипівка;Йосиповка;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5622687605;3;1
b9174e9c-ca30-11e8-80d7-1c98ec135261;Йосипівка;Йосипивка;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;00000000-0000-0000-0000-000000000000;;;1
f66b670b-ca30-11e8-80d7-1c98ec135261;Йосипівка;Йосипивка;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;00000000-0000-0000-0000-000000000000;;;1
56bdd976-749b-11df-b112-00215aee3ebe;Йосипівці;Иосиповцы;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820384001;3;1
56bdd96c-749b-11df-b112-00215aee3ebe;Йосипівці;Иосиповцы;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520280205;3;1
56bdd977-749b-11df-b112-00215aee3ebe;Йосипове;Йосипово;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920684903;3;1
56bdd978-749b-11df-b112-00215aee3ebe;Йосиповичі;Йосиповичи;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625388901;3;1
56bdd979-749b-11df-b112-00215aee3ebe;Кабарівці;Кабаровцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122684001;3;1
56bdd97a-749b-11df-b112-00215aee3ebe;Кабатня;Кабатня;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521210108;3;1
56bdd97c-749b-11df-b112-00215aee3ebe;Каблуки;Каблуки;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423381903;3;1
56bdd97d-749b-11df-b112-00215aee3ebe;Каветчина;Каветчина;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822487902;3;1
56bdd97e-749b-11df-b112-00215aee3ebe;Кавказ;Кавказ;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821181705;3;1
56bdd97f-749b-11df-b112-00215aee3ebe;Кавказ;Кавказ;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223387204;3;1
56bdd980-749b-11df-b112-00215aee3ebe;Кавків Яр;Кавков Яр;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521981505;3;1
33ec0013-ddd5-11df-9197-00215aee3ebe;Кавкули;Кавкулы;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520487501;3;1
56bdd981-749b-11df-b112-00215aee3ebe;Кавське;Кавское;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625382801;3;1
56bdd982-749b-11df-b112-00215aee3ebe;Кавуни;Кавуны;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820381501;3;1
56bdd984-749b-11df-b112-00215aee3ebe;Кавунівка;Кавуновка;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125782701;3;1
56bdd985-749b-11df-b112-00215aee3ebe;Кагамлик;Кагамлык;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320687203;3;1
56bdd986-749b-11df-b112-00215aee3ebe;Кагань;Кагань;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;Область 1 ;3;1
56bdd987-749b-11df-b112-00215aee3ebe;Кагарлик;Кагарлык;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121083001;3;1
56bdd988-749b-11df-b112-00215aee3ebe;Кагарлик;Кагарлык;місто;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222210100;2;0
56bdd989-749b-11df-b112-00215aee3ebe;Кагарлик;Кагарлык;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523680402;3;1
56bdd98a-749b-11df-b112-00215aee3ebe;Кагарлик Другий;Кагарлык Второй;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;;3;1
56bdd98b-749b-11df-b112-00215aee3ebe;Кагуїв;Кагуев;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623082403;3;1
56bdd98c-749b-11df-b112-00215aee3ebe;Кадиївка;Кадиевка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825883201;3;1
56bdd98d-749b-11df-b112-00215aee3ebe;Кадиївці;Кадиевцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822483301;2;1
56bdd98e-749b-11df-b112-00215aee3ebe;Кадище;Кадище;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721855701;3;1
56bdd98f-749b-11df-b112-00215aee3ebe;Кадниця;Кадница;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320885003;3;1
56bdd990-749b-11df-b112-00215aee3ebe;Кадобна;Кадобна;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622883401;3;1
56bdd991-749b-11df-b112-00215aee3ebe;Кадомка;Кадомка;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222284001;3;1
56bdd992-749b-11df-b112-00215aee3ebe;Кадубівці;Кадубовцы;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321585201;3;1
56bdd993-749b-11df-b112-00215aee3ebe;Казавчин;Казавчин;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521182401;3;1
fb60eb7e-c3e7-11e0-a0cc-003048d2b473;Казакова Балка;Казакова Балка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524083003;3;1
56bdd994-749b-11df-b112-00215aee3ebe;Казанів;Казанов;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623283002;3;1
56bdd995-749b-11df-b112-00215aee3ebe;Казанка;Казанка;місто;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823681102;2;1
56bdd996-749b-11df-b112-00215aee3ebe;Казанка вокзал;Казанка вокзал;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;;3;1
56bdd998-749b-11df-b112-00215aee3ebe;Казанківка;Казанковка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225884806;3;1
56bdd999-749b-11df-b112-00215aee3ebe;Казаринське;Казаринское;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822755103;3;1
56bdd99a-749b-11df-b112-00215aee3ebe;Казарня;Казарня;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522282601;3;1
5cb6189c-749b-11df-b112-00215aee3ebe;Казачка;Козачка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321781504;3;1
62c3cf26-749b-11df-b112-00215aee3ebe;Казбеки (Куйбишевське);Казбеки (Куйбишевське);село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122984202;3;1
56bdd99b-749b-11df-b112-00215aee3ebe;Казмірі;Казьмиры;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625886905;3;1
56bdd99c-749b-11df-b112-00215aee3ebe;Казначеївка;Казначеевка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222383301;3;1
56bdd99d-749b-11df-b112-00215aee3ebe;Казумин;Казумин;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785506;3;1
56bdd99e-749b-11df-b112-00215aee3ebe;Каїри;Каиры;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;c7bb5b2d-671b-11ea-80c6-000c29800ae7;5122781501;3;1
56bdd9a0-749b-11df-b112-00215aee3ebe;Кайданово;Кайданово;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122786807;3;1
56bdd9a1-749b-11df-b112-00215aee3ebe;Кайнари;Кайнары;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225581403;3;1
56bdd9a2-749b-11df-b112-00215aee3ebe;Кайтанівка;Кайтановка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825087603;3;1
56bdd9a3-749b-11df-b112-00215aee3ebe;Кайтанівка;Кайтановка;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122282501;3;1
56bdd9a4-749b-11df-b112-00215aee3ebe;Кайтанівка;Кайтановка;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821555401;3;1
56bdd9a5-749b-11df-b112-00215aee3ebe;Калабатине;Калабатино;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820955110;3;1
56bdd9a6-749b-11df-b112-00215aee3ebe;Калагарівка;Калагаровка;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121681801;3;1
56bdd9a7-749b-11df-b112-00215aee3ebe;Калаглія;Калаглия;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123781701;3;1
56bdd9a8-749b-11df-b112-00215aee3ebe;Калайдинці;Калайдинцы;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322883201;3;1
56bdd9aa-749b-11df-b112-00215aee3ebe;Каламаринка;Каламаринка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822789502;3;1
56bdd9ab-749b-11df-b112-00215aee3ebe;Калантаїв;Калантаев;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525280503;3;1
56bdd9ac-749b-11df-b112-00215aee3ebe;Калантаївка;Калантаевка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123983005;3;1
56bdd9ad-749b-11df-b112-00215aee3ebe;Каланчак;Каланчак;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122081301;3;1
56bdd9af-749b-11df-b112-00215aee3ebe;Калачеве;Калачово;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821155101;3;1
56bdd9b0-749b-11df-b112-00215aee3ebe;Калачівка;Калачовка;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124783801;3;1
56bdd9b1-749b-11df-b112-00215aee3ebe;Калачівка;Калачовка;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524686203;3;1
56bdd9b2-749b-11df-b112-00215aee3ebe;Калачківці;Калачковцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822483601;3;1
56bdd9b3-749b-11df-b112-00215aee3ebe;Калашинівка;Калашиновка;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922683902;3;1
56bdd9b4-749b-11df-b112-00215aee3ebe;Калашники;Калашники;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081301;3;1
56bdd9b5-749b-11df-b112-00215aee3ebe;Калашники;Калашники;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084007;3;1
56bdd9b6-749b-11df-b112-00215aee3ebe;Калашники;Калашники;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225283302;3;1
56bdd9b7-749b-11df-b112-00215aee3ebe;Каленики;Каленики;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281301;3;1
56bdd9b9-749b-11df-b112-00215aee3ebe;Каленики;Каленики;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121585101;3;1
56bdd9ba-749b-11df-b112-00215aee3ebe;Каленикове;Калениково;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323280606;3;1
56bdd9bb-749b-11df-b112-00215aee3ebe;Каленівка;Каленовка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523185505;3;1
56bdd9bc-749b-11df-b112-00215aee3ebe;Каленна;Каленная;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224082301;3;1
56bdd9bd-749b-11df-b112-00215aee3ebe;Каленське;Каленское;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822381801;3;1
56bdd9be-749b-11df-b112-00215aee3ebe;Калетинці;Калетынци;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822182405;3;1
56bdd9bf-749b-11df-b112-00215aee3ebe;Каливиця;Каливицая;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723186607;3;1
56bdd9c0-749b-11df-b112-00215aee3ebe;Калини;Калины;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120284807;3;1
56bdd9c1-749b-11df-b112-00215aee3ebe;Калини;Калины;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124482801;3;1
56bdd9c2-749b-11df-b112-00215aee3ebe;Калинів;Калинов;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624283001;3;1
56bdd9c3-749b-11df-b112-00215aee3ebe;Калинів Міст;Калинов Мост;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323810106;3;1
56bdd9c4-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324582008;3;1
56bdd9c5-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322083704;3;1
56bdd9c6-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323081304;3;1
56bdd9c8-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920655308;3;1
56bdd9c9-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924186404;3;1
56bdd9ca-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723655404;3;1
56bdd9cb-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155703;3;1
56bdd9cc-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655103;3;1
56bdd9cd-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221086604;3;1
56bdd9ce-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224581002;3;1
56bdd9cf-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055713;3;1
56bdd9d0-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225081904;3;1
56bdd9d1-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224882002;3;1
56bdd9d2-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885703;3;1
56bdd9d3-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823081701;3;1
56bdd9d4-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821183005;3;1
56bdd9d5-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822784008;3;1
56bdd9d6-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821755111;3;1
56bdd9d7-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823381301;3;1
56bdd9d8-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323181303;3;1
56bdd9d9-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324885002;3;1
56bdd9da-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121881401;3;1
56bdd9db-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122781701;3;1
56bdd9e3-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625884701;3;1
56bdd9e4-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621582402;3;1
56bdd9ec-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223588402;3;1
56bdd9ed-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221255600;2;0
56bdd9ee-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222755102;2;0
56bdd9ef-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221455800;2;0
56bdd9f0-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222284004;3;1
56bdd9f1-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223787003;3;1
56bdd9f2-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220685302;3;1
56bdd9f3-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822882001;3;1
56bdd9f4-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755106;3;1
56bdd9f5-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386020;3;1
56bdd9f6-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824084802;3;1
56bdd9f7-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821183602;3;1
56bdd9f8-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822084102;1;1
56bdd9f9-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420389503;3;1
56bdd9fa-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423381202;3;1
56bdd9fb-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423881501;3;1
56bdd9fc-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120384001;3;1
56bdd9fd-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121881002;3;1
56bdd9fe-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;місто;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521610100;2;0
56bdd9ff-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525386203;3;1
56bdda00-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524387002;3;1
56bdda01-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523283204;3;1
56bdda02-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523681203;3;1
56bdda03-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522281505;3;1
56bdda04-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522583601;3;1
56bdda05-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820382003;3;1
56bdda06-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822187202;3;1
56bdda07-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824288007;3;1
56bdda08-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821280702;3;1
56bdda09-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625455703;3;1
56bdda0a-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623081803;3;1
56bdda0b-749b-11df-b112-00215aee3ebe;Калинівка;Калиновка;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621485003;3;1
14679dc4-8356-11e8-80d4-1c98ec135261;Калинівка;Калиновка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222386004;3;1
7bbd894d-9728-11e8-80d4-1c98ec135261;Калинівка;Калинівка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825782501;3;1
56bdd25b-749b-11df-b112-00215aee3ebe;Калинівка (Жовтневе);Калиновка (Жовтневое);село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824481301;3;1
56bdda26-749b-11df-b112-00215aee3ebe;Калинівка (Калініна);Калиновка (Калинина);село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221887004;3;1
4dc9046d-a64c-11ea-80c8-000c29800ae7;Калинівка (Кімівка);Калиновка (Кимовка);село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820981801;3;1
5cb619c1-749b-11df-b112-00215aee3ebe;Калинівка (Комсомольська Комун;Калиновка (Комсомольская Коммуна);село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5910490502;3;1
56bdda0c-749b-11df-b112-00215aee3ebe;Калинівка Друга;Калиновка Вторая;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521610103;3;1
56bdda0d-749b-11df-b112-00215aee3ebe;Калинівна;Калиновна;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122684301;3;1
56bdda0e-749b-11df-b112-00215aee3ebe;Калинівське;Калиновское;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224883003;3;1
56bdda0f-749b-11df-b112-00215aee3ebe;Калинівське;Калиновское;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224282303;3;1
56bdda11-749b-11df-b112-00215aee3ebe;Калинівське;Калиновское;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424786504;3;1
56bdda12-749b-11df-b112-00215aee3ebe;Калинівщина;Калиновщина;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125580703;3;1
56bdda13-749b-11df-b112-00215aee3ebe;Калинівщина;Калиновщина;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320483403;3;1
17af7b18-ddb9-11df-9197-00215aee3ebe;Калинка;Калинка;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523955106;3;1
56bdda15-749b-11df-b112-00215aee3ebe;Калинове;Калиново;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321083503;3;1
871625a6-749b-11df-b112-00215aee3ebe;Калинове (Чапаєвка);Калиновое (Чапаевка);село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224487701;3;1
56bdda1b-749b-11df-b112-00215aee3ebe;Калиновий Гай;Калиновый Гай;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825484603;3;1
56bdda1c-749b-11df-b112-00215aee3ebe;Калиновиця;Калиновица;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421182401;3;1
56bdda1d-749b-11df-b112-00215aee3ebe;Калиновник;Калиновник;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155303;3;1
56bdda1e-749b-11df-b112-00215aee3ebe;Калиня;Калиня;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822484802;3;1
56bdda1f-749b-11df-b112-00215aee3ebe;Калита;Калита;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221255500;2;1
56bdda20-749b-11df-b112-00215aee3ebe;Калитинка;Калитинка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525382801;3;1
56bdda21-749b-11df-b112-00215aee3ebe;Калитинці;Калитинцы;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821286202;3;1
56bdda22-749b-11df-b112-00215aee3ebe;Калитяки;Калытяки;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625881906;3;1
56bdda23-749b-11df-b112-00215aee3ebe;Калитянське;Калитянское;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422087102;3;1
56bdda24-749b-11df-b112-00215aee3ebe;Калишенкове;Калишенково;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922086903;3;1
56bdda25-749b-11df-b112-00215aee3ebe;Каліївка;Калиевка;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925382501;3;1
56bdda27-749b-11df-b112-00215aee3ebe;Калініна;Калинина;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323987902;3;1
56bdda32-749b-11df-b112-00215aee3ebe;Калінінське;Калининское;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520955700;3;1
56bdda34-749b-11df-b112-00215aee3ebe;Калістратівка;Калистратовка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123183704;3;1
56bdda35-749b-11df-b112-00215aee3ebe;Каллів;Каллов;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125384802;3;1
56bdda36-749b-11df-b112-00215aee3ebe;Калмазове;Калмазово;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524355101;3;1
56bdda39-749b-11df-b112-00215aee3ebe;Калуга;Калуга;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821181301;3;1
56bdda3a-749b-11df-b112-00215aee3ebe;Калужине;Калужино;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221084405;3;1
56bdda3b-749b-11df-b112-00215aee3ebe;Калуш;Калуш;місто;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2610400000;1;0
08b737e3-b27e-11e8-80d4-1c98ec135261;Калуш Орісіл;Калуш Орисил;місто;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;;2;1
5cb61456-749b-11df-b112-00215aee3ebe;Калчева;Калчева;село;6b07f8a2-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5121484101;3;1
5cb61457-749b-11df-b112-00215aee3ebe;Калюжине;Калюжино;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323183702;3;1
5cb61458-749b-11df-b112-00215aee3ebe;Калюжинці;Калюжинцы;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425184001;3;1
5cb61459-749b-11df-b112-00215aee3ebe;Калюжне;Калюжное;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921586003;3;1
5cb6145a-749b-11df-b112-00215aee3ebe;Калюжне;Калюжное;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983701;3;1
5cb6145b-749b-11df-b112-00215aee3ebe;Калюсик;Калюсик;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820684005;3;1
5cb6145c-749b-11df-b112-00215aee3ebe;Кальна;Кальная;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622080503;3;1
5cb6145d-749b-11df-b112-00215aee3ebe;Кальна;Кальная;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821584001;3;1
5cb6145e-749b-11df-b112-00215aee3ebe;Кальне;Кальное;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122684501;3;1
5cb6145f-749b-11df-b112-00215aee3ebe;Кальне;Кальное;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123084401;3;1
5cb61460-749b-11df-b112-00215aee3ebe;Кальне;Кальное;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624588503;3;1
5cb61461-749b-11df-b112-00215aee3ebe;Кальниболота;Кальниболота;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523681201;3;1
5cb61462-749b-11df-b112-00215aee3ebe;Кальник;Кальник;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122783401;2;1
5cb61463-749b-11df-b112-00215aee3ebe;Кальник;Кальник;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521255404;3;1
5cb61464-749b-11df-b112-00215aee3ebe;Кальнишівка;Кальнишовка;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524584003;3;1
5cb61465-749b-11df-b112-00215aee3ebe;Кальнівці;Кальновцы;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322581504;3;1
5cb61466-749b-11df-b112-00215aee3ebe;Кальченки;Кальченки;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920683901;3;1
5cb61469-749b-11df-b112-00215aee3ebe;Кальчинівка;Кальчиновка;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420381006;3;1
5cb6147b-749b-11df-b112-00215aee3ebe;Кам'яна;Каменная;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324584001;3;1
5cb6147d-749b-11df-b112-00215aee3ebe;Кам'яна Балка;Каменная Балка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825481801;3;1
5cb6147e-749b-11df-b112-00215aee3ebe;Кам'яна Балка;Каменная Балка;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083810;3;1
5cb6147f-749b-11df-b112-00215aee3ebe;Кам'яна Балка;Каменная Балка;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824280903;3;1
5cb61480-749b-11df-b112-00215aee3ebe;Кам'яна Верба;Каменная Верба;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621688507;3;1
5cb61481-749b-11df-b112-00215aee3ebe;Кам'яна Гірка;Каменная Горка;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822884002;3;1
5cb61482-749b-11df-b112-00215aee3ebe;Кам'яна Гора;Каменная Гора;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784201;3;1
5cb61483-749b-11df-b112-00215aee3ebe;Кам'яна Гора;Каменная Гора;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822385802;3;1
5cb61484-749b-11df-b112-00215aee3ebe;Кам'яна Гора;Каменная Гора;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623485005;3;1
5cb61485-749b-11df-b112-00215aee3ebe;Кам'яна Гребля;Каменная Гребля;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224082401;2;1
5cb6147c-749b-11df-b112-00215aee3ebe;Кам'яна Криниця;Каменная Криница;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525584201;3;1
5cb61486-749b-11df-b112-00215aee3ebe;Кам'яна Яруга;Каменная Яруга;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325483501;3;1
5cb61487-749b-11df-b112-00215aee3ebe;Кам'яне;Каменное;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922984201;3;1
5cb61488-749b-11df-b112-00215aee3ebe;Кам'яне;Каменное;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922380803;3;1
5cb61489-749b-11df-b112-00215aee3ebe;Кам'яне;Каменное;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224585906;3;1
5cb6148a-749b-11df-b112-00215aee3ebe;Кам'яне;Каменное;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055110;3;1
5cb6148b-749b-11df-b112-00215aee3ebe;Кам'яне;Каменное;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824581501;3;1
5cb6148c-749b-11df-b112-00215aee3ebe;Кам'яне;Каменное;село;6b07f835-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5124381301;3;1
5cb6148d-749b-11df-b112-00215aee3ebe;Кам'яне;Каменное;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321555300;3;1
5cb6148e-749b-11df-b112-00215aee3ebe;Кам'яне;Каменное;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520984305;3;1
5cb6148f-749b-11df-b112-00215aee3ebe;Кам'яне;Каменное;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621587502;3;1
5cb61491-749b-11df-b112-00215aee3ebe;Кам'яне;Каменное;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625083201;3;1
5cb61492-749b-11df-b112-00215aee3ebe;Кам'яне Поле;Каменное Поле;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221885904;3;1
5cb61493-749b-11df-b112-00215aee3ebe;Кам'яне-Случанське;Каменно-Случанское;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625481601;3;1
5cb61494-749b-11df-b112-00215aee3ebe;Кам'янецьке;Каменецкое;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925083402;3;1
62c3d14e-749b-11df-b112-00215aee3ebe;Кам'янецькі Хутори (Ленінська Слобода);Каменецкие Хутора (Ленинская Слобода);село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522884604;3;1
5cb61495-749b-11df-b112-00215aee3ebe;Кам'янець-Подільський;Каменец-Подольский;місто;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6810400000;1;0
5cb61496-749b-11df-b112-00215aee3ebe;Кам'янече;Каменечье;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523681801;3;1
5cb61497-749b-11df-b112-00215aee3ebe;Кам'яний Брід;Каменный Брод;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821186503;3;1
5cb61498-749b-11df-b112-00215aee3ebe;Кам'яний Брід;Каменный Брод;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822582401;3;1
5cb61499-749b-11df-b112-00215aee3ebe;Кам'яний Брід;Каменный Брод;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820655700;3;1
5cb6149a-749b-11df-b112-00215aee3ebe;Кам'яний Брід;Каменный Брод;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122884001;3;1
5cb6149b-749b-11df-b112-00215aee3ebe;Кам'яний Брід;Каменный Брод;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525583601;3;1
5cb6149c-749b-11df-b112-00215aee3ebe;Кам'яний Майдан;Каменный Майдан;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824081701;3;1
5cb6149d-749b-11df-b112-00215aee3ebe;Кам'яний Міст;Каменный Мост;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825482203;3;1
5cb6149e-749b-11df-b112-00215aee3ebe;Кам'яний Міст;Каменный Мост;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524010107;3;1
5cb6149f-749b-11df-b112-00215aee3ebe;Кам'яний Міст вокз;;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;;3;1
5cb614a0-749b-11df-b112-00215aee3ebe;Кам'янисте;Каменистое;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621885203;3;1
5cb614a1-749b-11df-b112-00215aee3ebe;Кам'яниця;Каменица;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2124882901;3;1
5cb614a2-749b-11df-b112-00215aee3ebe;Кам'яниця;Каменица;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621686005;3;1
5cb614a3-749b-11df-b112-00215aee3ebe;Кам'яні Потоки;Каменные Потоки;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322481701;3;1
5cb614a4-749b-11df-b112-00215aee3ebe;Кам'янівка;Каменевка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824255401;3;1
5cb614a5-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321084904;3;1
5cb614a6-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924482201;3;1
5cb614a7-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925083401;3;1
5cb614a8-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725783903;3;1
5cb614a9-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125082202;3;1
5cb614aa-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221486206;3;0
5cb614ab-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225283801;3;1
5cb614ac-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220310106;3;1
5cb614ad-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823082404;3;1
5cb614ae-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824884203;3;1
5cb614af-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825181201;3;1
5cb614b0-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7321082101;3;0
5cb614b2-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323382506;3;1
5cb614b3-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324584502;3;1
5cb614b4-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322884001;3;1
5cb614b5-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123982601;3;1
5cb614b6-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121083201;3;1
5cb614b7-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124582403;3;1
5cb614b8-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122081701;3;1
5cb614b9-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323685903;3;1
5cb614bb-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521884502;3;1
5cb614bd-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624481202;3;1
5cb614be-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624582501;3;1
5cb614c9-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824483601;3;1
5cb614ca-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821785204;3;1
5cb614cb-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823487203;3;1
5cb614cc-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824086002;3;1
5cb614cd-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822083201;3;1
5cb614ce-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824782201;3;1
5cb614cf-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424483002;3;1
5cb614d0-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423085502;3;1
5cb614d1-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;місто;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121810100;2;0
5cb614d2-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522485802;3;1
5cb614d3-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523882901;3;1
5cb614d4-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823989202;3;1
5cb614d5-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6822183004;3;1
5cb614d6-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;;3;1
5cb614d7-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821282901;3;1
5cb614d8-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822483701;2;0
5cb614d9-749b-11df-b112-00215aee3ebe;Кам'янка;Каменка;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620485701;3;1
5cb614da-749b-11df-b112-00215aee3ebe;Кам'янка-Бузька;Каменка-Бужская;місто;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622110100;2;0
5cb614dc-749b-11df-b112-00215aee3ebe;Кам'янки;Каменки;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124682701;3;1
4a262ab3-82e7-11e1-bcbd-003048d2b473;Кам'янки;Камянки;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523786202;3;1
5cb614e4-749b-11df-b112-00215aee3ebe;Кам'яно - Зубилівка;Каменно-Зубиловка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225081002;3;1
5cb614de-749b-11df-b112-00215aee3ebe;Кам'янобрід;Каменноброд;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625884801;3;1
5cb614df-749b-11df-b112-00215aee3ebe;Кам'яногірка;Каменногорка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821783803;3;1
5cb614e0-749b-11df-b112-00215aee3ebe;Кам'яногірка;Каменногорка;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521681406;3;1
5cb614e1-749b-11df-b112-00215aee3ebe;Кам'яногірка;Каменногорка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523185003;3;1
5cb614e2-749b-11df-b112-00215aee3ebe;Кам'яногірка;Каменногорка;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521283206;3;1
5cb614e3-749b-11df-b112-00215aee3ebe;Кам'яногірка;Каменногорка;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521082203;3;1
5cb614e5-749b-11df-b112-00215aee3ebe;Кам'яно-Костувате;Каменно-Костоватое;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821481301;3;1
5cb614e6-749b-11df-b112-00215aee3ebe;Кам'янопіль;Каменнополь;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821481302;3;1
5cb614e7-749b-11df-b112-00215aee3ebe;Кам'янопіль;Каменнополь;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623688603;3;0
5cb614e8-749b-11df-b112-00215aee3ebe;Кам'яно-Хутірське;Каменно-Хуторское;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323555401;3;1
5cb614e9-749b-11df-b112-00215aee3ebe;Кам'янська Слобода;Каменская Слобода;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423684001;3;1
5cb614ea-749b-11df-b112-00215aee3ebe;Кам'янське;Каменское;село;d00d3b5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;;3;1
5cb614eb-749b-11df-b112-00215aee3ebe;Кам'янське;Каменское;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222986002;3;1
5cb614ec-749b-11df-b112-00215aee3ebe;Кам'янське;Каменское;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120482701;3;1
5cb614ef-749b-11df-b112-00215aee3ebe;Кам'янське;Каменское;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121984801;3;1
50c59518-749b-11df-b112-00215aee3ebe;Кам'янське;Каменское;місто;d00d3b5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1210400000;1;0
7ad954f7-749b-11df-b112-00215aee3ebe;Кам'янське(Світле);Камянское(Светлое);село;d00d3b5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1210436301;3;1
5cb614f1-749b-11df-b112-00215aee3ebe;Кам'янувате;Каменоватое;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323685904;3;1
5cb614f2-749b-11df-b112-00215aee3ebe;Кам'януватка;Каменоватка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224882503;3;1
5cb614f3-749b-11df-b112-00215aee3ebe;Кам'януватка;Каменоватка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821455104;3;1
5cb614f4-749b-11df-b112-00215aee3ebe;Кам'януватка;Каменоватка;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125784402;3;1
5cb614f5-749b-11df-b112-00215aee3ebe;Кам'януватка;Каменоватка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523882503;3;1
5cb614f6-749b-11df-b112-00215aee3ebe;Кам'януха;Каменуха;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723680602;3;1
5cb614f7-749b-11df-b112-00215aee3ebe;Кам'янчани;Каменчаны;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055111;3;1
5cb6146a-749b-11df-b112-00215aee3ebe;Камбурліївка;Камбурлиевка;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524682501;3;1
5cb6146b-749b-11df-b112-00215aee3ebe;Камені;Камени;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820385301;3;1
5cb6146c-749b-11df-b112-00215aee3ebe;Каменка;Каменка;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221855301;3;1
5cb6146f-749b-11df-b112-00215aee3ebe;Камінна;Каменная;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623382202;3;1
5cb61470-749b-11df-b112-00215aee3ebe;Камінне;Каменное;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322255101;3;1
5cb61471-749b-11df-b112-00215aee3ebe;Камінне;Каменное;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624082501;3;1
5cb61472-749b-11df-b112-00215aee3ebe;Камінське;Каминское;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524982707;3;1
5cb61473-749b-11df-b112-00215aee3ebe;Камінь;Камень;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922684401;3;1
5cb61474-749b-11df-b112-00215aee3ebe;Камінь;Камень;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624881801;3;1
5cb61475-749b-11df-b112-00215aee3ebe;Камінь;Камень;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824281303;3;1
5cb61476-749b-11df-b112-00215aee3ebe;Камінь;Камень;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821482201;3;1
5cb61477-749b-11df-b112-00215aee3ebe;Камінь;Камень;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423684002;3;1
5cb61478-749b-11df-b112-00215aee3ebe;Камінь-Каширський;Камень-Каширский;місто;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0721410100;2;0
5cb61479-749b-11df-b112-00215aee3ebe;Камка;Камка;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425889203;3;1
8cdd5cfd-de27-11df-9197-00215aee3ebe;Камка;Камка;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422484001;3;1
5cb6147a-749b-11df-b112-00215aee3ebe;Камплиця;Камплица;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321782504;3;1
5cb614f8-749b-11df-b112-00215aee3ebe;Канава;Канава;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524583503;3;1
5cb614f9-749b-11df-b112-00215aee3ebe;Канави;Канавы;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321883001;3;1
5cb614fb-749b-11df-b112-00215aee3ebe;Канафости;Канафосты;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624281902;3;1
5cb614fc-749b-11df-b112-00215aee3ebe;Кандаурове;Кандаурово;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522581203;3;1
5cb614fd-749b-11df-b112-00215aee3ebe;Кандибине;Кандыбино;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920683005;3;1
5cb614fe-749b-11df-b112-00215aee3ebe;Кандибине;Кандыбино;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824883201;3;1
5cb614ff-749b-11df-b112-00215aee3ebe;Канів;Канев;місто;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7110300000;2;0
5cb61500-749b-11df-b112-00215aee3ebe;Канівка;Каневка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820985903;3;1
5cb61501-749b-11df-b112-00215aee3ebe;Канівське;Каневское;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322184903;3;1
5cb61503-749b-11df-b112-00215aee3ebe;Канівщина;Каневщина;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320686802;3;1
5cb61504-749b-11df-b112-00215aee3ebe;Канівщина;Каневщина;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424184001;3;1
5cb61505-749b-11df-b112-00215aee3ebe;Канівщина;Каневщина;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121582002;3;1
5cb61506-749b-11df-b112-00215aee3ebe;Каніж;Каниж;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523883201;3;1
5cb61508-749b-11df-b112-00215aee3ebe;Каноницьке;Каноницкое;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520280609;3;1
5cb61507-749b-11df-b112-00215aee3ebe;Каноничі;Каноничи;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620884901;3;1
5cb61509-749b-11df-b112-00215aee3ebe;Канора;Канора;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121555101;3;1
5cb6150a-749b-11df-b112-00215aee3ebe;Кантакузівка;Кантакузовка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321287504;3;1
5cb6150b-749b-11df-b112-00215aee3ebe;Кантакузівка;Кантакузовка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120684501;3;1
5cb6150c-749b-11df-b112-00215aee3ebe;Кантелина;Кантелина;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521255405;3;1
5cb6150d-749b-11df-b112-00215aee3ebe;Кантемирівка;Кантемировка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325455103;3;1
5cb6150e-749b-11df-b112-00215aee3ebe;Кантівка;Кантовка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824286204;3;1
5cb6150f-749b-11df-b112-00215aee3ebe;Кануни;Кануны;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824085402;3;1
5cb61510-749b-11df-b112-00215aee3ebe;Канцерівка;Канцеровка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322183504;3;1
5cb61511-749b-11df-b112-00215aee3ebe;Канцурове;Канцурово;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125480307;3;1
5cb61512-749b-11df-b112-00215aee3ebe;Капаклієве;Капаклиево;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123984202;3;1
5cb61513-749b-11df-b112-00215aee3ebe;Капелюх;Капелюх;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783007;3;1
5cb61514-749b-11df-b112-00215aee3ebe;Капитолівка;Капитоловка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322884501;3;1
5cb61515-749b-11df-b112-00215aee3ebe;Капітанівка;Капитановка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922083303;3;1
5cb61516-749b-11df-b112-00215aee3ebe;Капітанівка;Капитановка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924783203;3;1
5cb61517-749b-11df-b112-00215aee3ebe;Капітанівка;Капитановка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;c7bb5b2d-671b-11ea-80c6-000c29800ae7;5122784003;3;1
5cb61518-749b-11df-b112-00215aee3ebe;Капітанівка;Капитановка;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222484403;2;1
5cb61519-749b-11df-b112-00215aee3ebe;Капітанівка;Капитановка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523855400;3;1
5cb6151a-749b-11df-b112-00215aee3ebe;Капітанка;Капитанка;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822380803;3;1
5cb6151b-749b-11df-b112-00215aee3ebe;Капітанка;Капитанка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521482301;3;1
5cb6151d-749b-11df-b112-00215aee3ebe;Капки;Капки;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524382903;3;1
5cb6151f-749b-11df-b112-00215aee3ebe;Каплинці;Каплинцы;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323883201;3;1
5cb61520-749b-11df-b112-00215aee3ebe;Каплистівка;Каплистовка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220787704;3;1
5cb61521-749b-11df-b112-00215aee3ebe;Каплівка;Каплевка;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325082801;3;1
5cb61522-749b-11df-b112-00215aee3ebe;Каплунівка;Каплуновка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324080303;3;1
5cb61523-749b-11df-b112-00215aee3ebe;Каплунівка;Каплуновка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323581201;3;1
5cb61524-749b-11df-b112-00215aee3ebe;Капранське;Капранское;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323583702;3;1
5cb61525-749b-11df-b112-00215aee3ebe;Капулівка;Капуловка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222985502;3;1
5cb61526-749b-11df-b112-00215aee3ebe;Капустин;Капустин;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824284201;3;1
5cb61527-749b-11df-b112-00215aee3ebe;Капустине;Капустино;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823381704;3;0
5cb61528-749b-11df-b112-00215aee3ebe;Капустине;Капустино;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125782801;3;1
5cb61529-749b-11df-b112-00215aee3ebe;Капустинський Ліс;Капустинский Лес;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122483903;3;1
5cb6152a-749b-11df-b112-00215aee3ebe;Капустинці;Капустинцы;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923281901;3;1
5cb6152b-749b-11df-b112-00215aee3ebe;Капустинці;Капустинцы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122483901;3;1
5cb6152c-749b-11df-b112-00215aee3ebe;Капустинці;Капустинцы;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125584001;3;1
5cb6152d-749b-11df-b112-00215aee3ebe;Капустинці;Капустинцы;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225582101;3;1
5cb6152e-749b-11df-b112-00215aee3ebe;Капустинці;Капустинцы;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221682801;2;1
5cb6152f-749b-11df-b112-00215aee3ebe;Капустинці;Капустинцы;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424183203;3;1
8cdd5cd4-de27-11df-9197-00215aee3ebe;Капустяна;Капустяная;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223187703;3;1
5cb61530-749b-11df-b112-00215aee3ebe;Капустяни;Капустяны;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524182701;3;1
5cb61531-749b-11df-b112-00215aee3ebe;Капустяни;Капустяны;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823383501;3;1
8716233c-749b-11df-b112-00215aee3ebe;Капустяни (Фрунзівка);Капустяни (Фрунзовка);село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324285607;3;1
5cb61532-749b-11df-b112-00215aee3ebe;Капустянка;Капустянка;село;6b07f835-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5124381501;3;1
5cb6156d-749b-11df-b112-00215aee3ebe;Кар'єрне;Карьерное;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520956000;3;1
5cb61533-749b-11df-b112-00215aee3ebe;Карабазівка;Карабазовка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321355404;3;1
5cb61534-749b-11df-b112-00215aee3ebe;Карабани;Карабаны;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423689303;3;1
5cb61535-749b-11df-b112-00215aee3ebe;Карабанове;Карабаново;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125281901;3;1
5cb61536-749b-11df-b112-00215aee3ebe;Карабачин;Карабачин;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820982001;3;1
5cb61537-749b-11df-b112-00215aee3ebe;Карабелівка;Карабеловка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523787005;3;1
5cb61538-749b-11df-b112-00215aee3ebe;Карабинівка;Карабиновка;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223584508;3;1
5cb61539-749b-11df-b112-00215aee3ebe;Карабинівка;Карабиновка;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423886003;3;1
5cb6153a-749b-11df-b112-00215aee3ebe;Карабіївка;Карабиевка;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824783001;3;1
5cb6153b-749b-11df-b112-00215aee3ebe;Карабутове;Карабутово;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922084101;3;1
5cb6153c-749b-11df-b112-00215aee3ebe;Карабущине;Карабущино;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323181304;3;1
5cb6153d-749b-11df-b112-00215aee3ebe;Карабчиїв;Карабчиев;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825283801;3;1
5cb6153e-749b-11df-b112-00215aee3ebe;Караван;Караван;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982702;3;1
5cb6153f-749b-11df-b112-00215aee3ebe;Караван;Караван;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322055902;3;1
5cb61540-749b-11df-b112-00215aee3ebe;Караван;Караван;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
5cb61541-749b-11df-b112-00215aee3ebe;Караван;Караван;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324281001;3;1
5cb618e2-749b-11df-b112-00215aee3ebe;Каравелове (Коларівка);Каравелове (Коларівка);село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823381701;3;1
5cb61543-749b-11df-b112-00215aee3ebe;Караєвичі;Караевичи;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624683302;3;1
5cb61544-749b-11df-b112-00215aee3ebe;Караїмівка;Караимовка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824281704;3;1
5cb61545-749b-11df-b112-00215aee3ebe;Караїна;Караина;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824781502;3;1
5cb61548-749b-11df-b112-00215aee3ebe;Карайкове;Карайково;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225083509;3;1
5cb61549-749b-11df-b112-00215aee3ebe;Карайкозівка;Карайкозовка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323581704;3;1
5cb6154a-749b-11df-b112-00215aee3ebe;Караказелівка;Караказелевка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524080703;3;1
56bdd248-749b-11df-b112-00215aee3ebe;Каракурт (Жовтневе);Каракурт (Жовтневое);село;6b07f8a2-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5121483601;3;1
5cb6154b-749b-11df-b112-00215aee3ebe;Карандинці;Карандинцы;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220688002;3;1
5cb6154c-749b-11df-b112-00215aee3ebe;Карапиші;Карапыши;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222982601;3;1
5cb6154d-749b-11df-b112-00215aee3ebe;Карапчів;Карапчов;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320583001;3;1
5cb6154e-749b-11df-b112-00215aee3ebe;Карапчів;Карапчов;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321082401;3;1
5cb6154f-749b-11df-b112-00215aee3ebe;Карасин;Карасин;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721484301;3;1
5cb61550-749b-11df-b112-00215aee3ebe;Карасин;Карасин;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723685505;3;1
5cb61551-749b-11df-b112-00215aee3ebe;Карасин;Карасин;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625482001;3;1
5cb61552-749b-11df-b112-00215aee3ebe;Карасинівка;Карасиновка;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422081502;3;1
5cb61553-749b-11df-b112-00215aee3ebe;Карасиха;Карасиха;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820355101;3;1
5cb61554-749b-11df-b112-00215aee3ebe;Карасі;Караси;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424780504;3;1
5cb61555-749b-11df-b112-00215aee3ebe;Карасівка;Карасовка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322683002;3;1
5cb61557-749b-11df-b112-00215aee3ebe;Карацюбине;Карацюбино;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422286504;3;1
5cb61558-749b-11df-b112-00215aee3ebe;Караченців;Караченцев;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324582505;3;1
5cb61559-749b-11df-b112-00215aee3ebe;Карачин;Карачин;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121283902;3;1
5cb6155a-749b-11df-b112-00215aee3ebe;Карачинів;Карачинов;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625882902;3;1
5cb6155b-749b-11df-b112-00215aee3ebe;Карачіївці;Карачиевцы;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820684001;3;1
5cb6155c-749b-11df-b112-00215aee3ebe;Карачківці;Карачковцы;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825288602;2;1
5cb6155d-749b-11df-b112-00215aee3ebe;Карачун;Карачун;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620486202;3;1
5cb6155e-749b-11df-b112-00215aee3ebe;Карашина;Карашина;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122589403;3;1
5cb6155f-749b-11df-b112-00215aee3ebe;Карашинці;Карашинцы;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121611001;3;1
5cb61561-749b-11df-b112-00215aee3ebe;Карбівка;Карбовка;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520882403;3;1
5cb61562-749b-11df-b112-00215aee3ebe;Карбівка;Карбовка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521782401;3;1
33ec0018-ddd5-11df-9197-00215aee3ebe;Карбівське;Карбовское;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520883301;3;1
5cb61563-749b-11df-b112-00215aee3ebe;Карвинівка;Карвиновка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821482701;3;1
5cb61564-749b-11df-b112-00215aee3ebe;Кардамичеве;Кардамычево;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655113;3;1
5cb61566-749b-11df-b112-00215aee3ebe;Кардаші;Кардаши;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923883809;3;1
5cb61567-749b-11df-b112-00215aee3ebe;Кардаші;Кардаши;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223881504;3;1
5cb61568-749b-11df-b112-00215aee3ebe;Кардаші;Кардаши;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423381904;3;1
5cb61569-749b-11df-b112-00215aee3ebe;Кардашівка;Кардашовка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321081702;3;1
5cb6156a-749b-11df-b112-00215aee3ebe;Кардашівка;Кардашовка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924787103;3;1
5cb6156b-749b-11df-b112-00215aee3ebe;Кардашівка;Кардашовка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920384001;3;1
5cb6156c-749b-11df-b112-00215aee3ebe;Каре;Карое;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123580403;3;1
5cb6156f-749b-11df-b112-00215aee3ebe;Карижин;Карыжин;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820655103;3;1
5cb61570-749b-11df-b112-00215aee3ebe;Карильське;Карыльское;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422283001;3;1
5cb61571-749b-11df-b112-00215aee3ebe;Каришків;Карышков;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520282003;3;1
5cb61572-749b-11df-b112-00215aee3ebe;Карів;Каров;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624882401;3;1
5cb61573-749b-11df-b112-00215aee3ebe;Карла Лібкнехта;Карла Либкнехта;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;*122148800;3;1
5cb61576-749b-11df-b112-00215aee3ebe;Карла Маркса;Карла Маркса;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;*122238600;3;1
5cb6157a-749b-11df-b112-00215aee3ebe;Карла Маркса;Карла Маркса;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825683602;3;1
5cb6157b-749b-11df-b112-00215aee3ebe;Карла Маркса;Карла Маркса;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423880503;3;1
5cb6157c-749b-11df-b112-00215aee3ebe;Карликівка;Карликовка;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824283203;3;1
5cb6157d-749b-11df-b112-00215aee3ebe;Карлівка;Карловка;місто;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321610100;2;0
5cb6157e-749b-11df-b112-00215aee3ebe;Карлівка;Карловка;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824880203;3;1
62c3ce8d-749b-11df-b112-00215aee3ebe;Карлівка ( Крупське );Карловка ( Крупское );село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522584201;3;1
5cb61580-749b-11df-b112-00215aee3ebe;Карло-Марксівське;Карло-Марксовское;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520985001;3;1
5cb61583-749b-11df-b112-00215aee3ebe;Кармалюківка;Кармалюковка;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290011;3;1
5cb61584-749b-11df-b112-00215aee3ebe;Кармалюкове;Кармалюково;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521082603;3;1
5cb61585-749b-11df-b112-00215aee3ebe;Карнагорове;Карнагорово;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121285109;3;1
5cb61586-749b-11df-b112-00215aee3ebe;Карналіївка;Карналиевка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120883201;3;1
5cb61587-749b-11df-b112-00215aee3ebe;Карнаухи;Карнаухи;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324084609;3;1
5cb61588-749b-11df-b112-00215aee3ebe;Карнаухівка;Карнауховка;село;d00d3b5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1210465300;2;0
5cb61589-749b-11df-b112-00215aee3ebe;Карнаухівка;Карнауховка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322882203;3;1
5cb6158a-749b-11df-b112-00215aee3ebe;Карначівка;Карначевка;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123884101;3;1
5cb6158b-749b-11df-b112-00215aee3ebe;Кароліна;Каролина;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523083405;3;1
5cb6158c-749b-11df-b112-00215aee3ebe;Кароліно-Бугаз;Каролино-Бугаз;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123781501;2;1
5cb6158d-749b-11df-b112-00215aee3ebe;Карпати;Карпаты;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122755501;2;1
5cb6158f-749b-11df-b112-00215aee3ebe;Карпатське;Карпатское;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625583701;3;1
5cb61590-749b-11df-b112-00215aee3ebe;Карпеки;Карпеки;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422089302;3;1
5cb61591-749b-11df-b112-00215aee3ebe;Карпенкове;Карпенково;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920988803;3;1
5cb61592-749b-11df-b112-00215aee3ebe;Карпенкове;Карпенково;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420385002;3;1
5cb61593-749b-11df-b112-00215aee3ebe;Карпеченкове;Карпеченково;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924481702;3;1
5cb61594-749b-11df-b112-00215aee3ebe;Карпи;Карпи;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625888404;3;1
5cb61595-749b-11df-b112-00215aee3ebe;Карпилівка;Карпиловка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920384401;3;1
5cb61596-749b-11df-b112-00215aee3ebe;Карпилівка;Карпиловка;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721484302;3;1
5cb61597-749b-11df-b112-00215aee3ebe;Карпилівка;Карпиловка;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721883801;3;1
5cb61598-749b-11df-b112-00215aee3ebe;Карпилівка;Карпиловка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222083603;3;1
5cb61599-749b-11df-b112-00215aee3ebe;Карпилівка;Карпиловка;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755129;3;1
5cb6159a-749b-11df-b112-00215aee3ebe;Карпилівка;Карпиловка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824083604;3;1
5cb6159b-749b-11df-b112-00215aee3ebe;Карпилівка;Карпиловка;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422083001;3;1
5cb6159c-749b-11df-b112-00215aee3ebe;Карпилівка;Карпиловка;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425185001;3;1
5cb6159d-749b-11df-b112-00215aee3ebe;Карпилівка;Карпиловка;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820385507;3;1
5cb6159e-749b-11df-b112-00215aee3ebe;Карпилівка;Карпиловка;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625083801;3;1
5cb6159f-749b-11df-b112-00215aee3ebe;Карпилівка;Карпиловка;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625482301;3;1
5cb615a0-749b-11df-b112-00215aee3ebe;Карпилівка;Карпиловка;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624683303;3;1
5cb615a1-749b-11df-b112-00215aee3ebe;Карпилівка;Карпиловка;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625888203;3;1
5cb615a2-749b-11df-b112-00215aee3ebe;Карпилівка;Карпиловка;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322881803;3;1
5cb615a3-749b-11df-b112-00215aee3ebe;Карпиха;Карпиха;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324555108;3;1
5cb615a4-749b-11df-b112-00215aee3ebe;Карпівка;Карповка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322485602;3;1
5cb615a5-749b-11df-b112-00215aee3ebe;Карпівка;Карповка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225884801;3;1
5cb615a6-749b-11df-b112-00215aee3ebe;Карпівка;Карповка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123980503;3;1
5cb615ab-749b-11df-b112-00215aee3ebe;Карпівка;Карповка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421484803;3;1
5cb615ac-749b-11df-b112-00215aee3ebe;Карпівка;Карповка;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522683001;2;1
5cb615ad-749b-11df-b112-00215aee3ebe;Карпівці;Карповцы;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825883201;3;1
5cb615ae-749b-11df-b112-00215aee3ebe;Карпівці;Карповцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825087302;3;1
5cb615af-749b-11df-b112-00215aee3ebe;Карпівці;Карповцы;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824486002;3;1
5cb615b1-749b-11df-b112-00215aee3ebe;Карпове;Карпово;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123983007;3;1
5cb615b3-749b-11df-b112-00215aee3ebe;Карповичі;Карповичи;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424782501;3;1
5cb615b5-749b-11df-b112-00215aee3ebe;Карповтлаш;Карповтлаш;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125383602;3;1
5cb615b6-749b-11df-b112-00215aee3ebe;Карпусі;Карпуси;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081405;3;1
5cb615b7-749b-11df-b112-00215aee3ebe;Карпці;Карпцы;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923285604;3;1
5cb615b8-749b-11df-b112-00215aee3ebe;Картамиш;Картамыш;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324584201;3;1
5cb615ba-749b-11df-b112-00215aee3ebe;Картинів;Картинов;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625883206;3;1
5cb615bb-749b-11df-b112-00215aee3ebe;Картовецьке;Картовецкое;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421486403;3;1
5cb615bd-749b-11df-b112-00215aee3ebe;Кархівка;Карховка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425583101;3;1
5cb615be-749b-11df-b112-00215aee3ebe;Касаєве;Касаево;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755702;3;1
5cb615c0-749b-11df-b112-00215aee3ebe;Касинівка;Касиновка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224581707;3;1
5cb615c1-749b-11df-b112-00215aee3ebe;Каскада;Каскада;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823355101;3;1
5cb615c2-749b-11df-b112-00215aee3ebe;Касперівці;Касперовцы;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122084701;3;1
5cb615c3-749b-11df-b112-00215aee3ebe;Касяни;Касьяны;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322281905;3;1
5cb615c4-749b-11df-b112-00215aee3ebe;Касяни;Касьяны;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122786903;3;1
5cb615c6-749b-11df-b112-00215aee3ebe;Касянівка;Касьяновка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324884503;3;1
5cb615c8-749b-11df-b112-00215aee3ebe;Касянівка;Касьяновка;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523955107;3;1
5cb615ca-749b-11df-b112-00215aee3ebe;Каталине;Каталино;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825182205;3;1
5cb615cb-749b-11df-b112-00215aee3ebe;Катанське;Катанское;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921282501;3;1
5cb615cc-749b-11df-b112-00215aee3ebe;Каташин;Каташин;село;78f453a8-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0525083201;3;1
5cb615cd-749b-11df-b112-00215aee3ebe;Катериничі;Катериничи;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620981103;3;1
5cb615ce-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921587102;3;1
5cb615cf-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920686603;3;1
5cb615d0-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922984601;3;1
5cb615d1-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921284402;3;1
5cb615d2-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123482901;3;1
5cb615d3-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225685004;3;1
5cb615d4-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955112;3;1
5cb615d5-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224580503;3;1
5cb615d6-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222083504;3;1
5cb615d7-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755124;3;1
5cb615d8-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223882504;3;1
5cb615d9-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225255111;3;1
5cb615da-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;bad3ac27-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224286101;3;1
5cb615db-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224286101;3;1
5cb615dc-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1220310107;3;1
5cb615dd-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820681803;3;1
5cb615de-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821780601;3;1
5cb615e0-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
5cb615e1-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324883001;3;1
5cb615e2-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323981501;3;1
5cb615e3-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
5cb615e4-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684007;3;1
5cb615ef-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821781603;3;1
5cb615f0-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821183805;3;1
5cb615f1-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822087204;3;1
5cb615f2-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820887702;3;1
5cb615f3-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420680402;3;1
5cb615f4-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121882201;3;1
5cb615f5-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521481006;3;1
5cb615f6-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522585802;3;1
5cb615f7-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520382605;3;1
5cb615f8-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521980706;3;1
5cb615f9-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825080602;3;1
5cb615fa-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821885904;3;1
5cb615fb-749b-11df-b112-00215aee3ebe;Катеринівка;Катериновка;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625485403;3;1
17af7b24-ddb9-11df-9197-00215aee3ebe;Катеринівка;Катериновка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222985503;3;1
61310d3d-ac60-11e8-80d4-1c98ec135261;Катеринівка;Катериновка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1224286101;3;1
5cb615fc-749b-11df-b112-00215aee3ebe;Катеринівка Перша;Катериновка Первая;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123383802;3;1
5cb615fd-749b-11df-b112-00215aee3ebe;Катеринка;Катеринка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825482202;3;1
5cb615fe-749b-11df-b112-00215aee3ebe;Катерино - Наталівка;Катерино-Наталовка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225255112;3;1
5cb615ff-749b-11df-b112-00215aee3ebe;Катеринопіль;Катеринополь;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222083001;3;1
5cb61600-749b-11df-b112-00215aee3ebe;Катеринопіль;Катеринополь;місто;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122255100;3;1
5cb61601-749b-11df-b112-00215aee3ebe;Катерино-Платонівка;Катерино-Платоновка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125481401;3;1
5cb61602-749b-11df-b112-00215aee3ebe;Катина;Катино;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625185302;3;1
5cb61603-749b-11df-b112-00215aee3ebe;Катражка;Катражка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224888203;3;1
5cb61604-749b-11df-b112-00215aee3ebe;Катричівка;Катричовка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321283005;3;1
5cb61606-749b-11df-b112-00215aee3ebe;Катюжанка;Катюжанка;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221883201;3;1
5cb61607-749b-11df-b112-00215aee3ebe;Катюха;Катюха;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824086203;3;1
5cb61608-749b-11df-b112-00215aee3ebe;Катьощине;Катещино;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455101;3;1
5cb61609-749b-11df-b112-00215aee3ebe;Каховка;Каховка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123582203;3;1
5cb6160c-749b-11df-b112-00215aee3ebe;Каціри;Кациры;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420386005;3;1
5cb6160d-749b-11df-b112-00215aee3ebe;Кацмазів;Кацмазов;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521082803;3;1
5cb6160f-749b-11df-b112-00215aee3ebe;Качали;Качалы;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221083001;3;1
5cb61610-749b-11df-b112-00215aee3ebe;Качалівка;Качаловка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323581701;3;1
5cb61611-749b-11df-b112-00215aee3ebe;Качанівка;Качановка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920385805;3;1
5cb61612-749b-11df-b112-00215aee3ebe;Качанівка;Качановка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124683001;3;1
5cb61613-749b-11df-b112-00215aee3ebe;Качанівка;Качановка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421782405;3;1
5cb61614-749b-11df-b112-00215aee3ebe;Качанівка;Качановка;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524882701;3;1
5cb61615-749b-11df-b112-00215aee3ebe;Качанове;Качаново;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320482601;3;1
5cb61616-749b-11df-b112-00215aee3ebe;Качин;Качин;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721484601;3;1
5cb61618-749b-11df-b112-00215aee3ebe;Качкарівка;Качкаровка;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520681801;3;1
5cb6161b-749b-11df-b112-00215aee3ebe;Качківка;Качковка;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525682801;3;1
5cb6161c-749b-11df-b112-00215aee3ebe;Качмарі;Качмари;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783207;3;1
5cb6161d-749b-11df-b112-00215aee3ebe;Качмарі;Качмари;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625883205;3;1
5cb6161e-749b-11df-b112-00215aee3ebe;Качмарі;Качмари;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622486403;3;1
5cb6161f-749b-11df-b112-00215aee3ebe;Качулове;Качулово;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125485903;3;1
5cb61620-749b-11df-b112-00215aee3ebe;Качуринці;Качуринцы;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820985403;3;1
5cb61621-749b-11df-b112-00215aee3ebe;Качурівка;Качуровка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122982501;3;1
5cb61622-749b-11df-b112-00215aee3ebe;Каширівка;Кашировка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823682201;3;1
5cb61623-749b-11df-b112-00215aee3ebe;Кашівка;Кашевка;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722181204;3;1
5cb61624-749b-11df-b112-00215aee3ebe;Кашкарівка;Кашкаровка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225083002;3;1
5cb61626-749b-11df-b112-00215aee3ebe;Кашперівка;Кашперовка;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224683601;2;1
5cb61627-749b-11df-b112-00215aee3ebe;Кашперівка;Кашперовка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822586703;3;1
5cb61628-749b-11df-b112-00215aee3ebe;Кашперівка;Кашперовка;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820683001;3;1
5cb61629-749b-11df-b112-00215aee3ebe;Кашперівка;Кашперовка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521483003;3;1
5cb6162a-749b-11df-b112-00215aee3ebe;Кашперо-Миколаївка;Кашперо-Николаевка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820681801;3;1
5cb6162b-749b-11df-b112-00215aee3ebe;Кашпури;Кашпуры;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187104;3;1
5cb6162c-749b-11df-b112-00215aee3ebe;Кашпурівка;Кашпуровка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324582506;3;1
5cb6162e-749b-11df-b112-00215aee3ebe;Каштанівка;Каштановка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323683506;3;1
5cb61631-749b-11df-b112-00215aee3ebe;Каштанівка;Каштановка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822481502;3;1
5cb61633-749b-11df-b112-00215aee3ebe;Каштаново;Каштаново;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120484002;3;1
5cb61635-749b-11df-b112-00215aee3ebe;Кашубівка;Кашубовка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083906;3;1
5cb61636-749b-11df-b112-00215aee3ebe;Кащенкове;Кащенково;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922686304;3;1
5cb61637-749b-11df-b112-00215aee3ebe;Кащенці;Кащенцы;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820388007;3;1
56bdd226-749b-11df-b112-00215aee3ebe;Кащівка (Жовтневе);Кащивка (Жовтневое);село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322082503;3;1
5cb61639-749b-11df-b112-00215aee3ebe;Кваси;Квасы;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123683001;3;1
5cb6163a-749b-11df-b112-00215aee3ebe;Квасилів;Квасилов;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624655300;2;0
5cb6163b-749b-11df-b112-00215aee3ebe;Квасів;Квасов;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720882401;3;1
62c3cd52-749b-11df-b112-00215aee3ebe;Квасовиця;Квасовица;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722482404;3;1
5cb6163c-749b-11df-b112-00215aee3ebe;Квасово;Квасово;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120485401;3;1
5cb6163e-749b-11df-b112-00215aee3ebe;Квіти;Квиты;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322055102;3;1
5cb6163f-749b-11df-b112-00215aee3ebe;Квітка;Квитка;село;6b07f835-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5124383203;3;1
5cb61640-749b-11df-b112-00215aee3ebe;Квітка;Квитка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520282815;3;1
5cb61641-749b-11df-b112-00215aee3ebe;Квітка;Квитка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524081802;3;1
5cb61642-749b-11df-b112-00215aee3ebe;Квітки;Квитки;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122583401;3;1
5cb61643-749b-11df-b112-00215aee3ebe;Квіткове;Квитковое;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086204;3;1
5cb61644-749b-11df-b112-00215aee3ebe;Квіткове;Квитковое;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120486702;3;1
5cb61647-749b-11df-b112-00215aee3ebe;Квітневе;Квитневое;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724587202;3;1
5cb61648-749b-11df-b112-00215aee3ebe;Квітневе;Квитневое;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823355702;3;1
5cb61649-749b-11df-b112-00215aee3ebe;Квітневе;Квитневое;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621555906;3;1
5cb6164a-749b-11df-b112-00215aee3ebe;Квітневе;Квитневое;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224081802;3;1
5cb6164b-749b-11df-b112-00215aee3ebe;Квітневе;Квитневое;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823482803;3;1
5cb6164c-749b-11df-b112-00215aee3ebe;Квітневе;Квитневое;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510116;3;1
5cb6164d-749b-11df-b112-00215aee3ebe;Квітневе;Квитневое;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520387303;3;1
5cb6164e-749b-11df-b112-00215aee3ebe;Квітневе;Квитневое;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820384501;3;1
5cb6164f-749b-11df-b112-00215aee3ebe;Квітневе;Квитневое;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621684703;3;1
56bdd25e-749b-11df-b112-00215aee3ebe;Квітневе ( Жовтневе );Жовтневое;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824782101;3;1
50c5943a-749b-11df-b112-00215aee3ebe;Квітневе (Димитрове);Цветочное (Димитрово);село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221255603;1;0
5cb61713-749b-11df-b112-00215aee3ebe;Квітневе (Кірове);Апрельськое (Кирово);село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320683001;3;1
a8220da8-e269-11e8-80d7-1c98ec135261;Квітуче;Цветущее;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225055407;3;1
2656cfa7-a24b-11e5-a7d9-001ec93df11f;Кевелів;Кевелив;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123656200;3;1
5cb61650-749b-11df-b112-00215aee3ebe;Кегичівка;Кегичевка;місто;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323155100;3;1
5cb61651-749b-11df-b112-00215aee3ebe;Кедина Гора;Кедина Гора;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121586202;3;1
5cb61652-749b-11df-b112-00215aee3ebe;Кезі;Кези;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424483203;3;1
5cb61653-749b-11df-b112-00215aee3ebe;Кейбалівка;Кейбаловка;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323884902;3;1
5cb61654-749b-11df-b112-00215aee3ebe;Кекине;Кекино;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924783201;3;1
5cb61655-749b-11df-b112-00215aee3ebe;Келеберда;Келеберда;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322482001;3;1
5cb61656-749b-11df-b112-00215aee3ebe;Келеберда;Келеберда;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122082301;3;1
5cb61657-749b-11df-b112-00215aee3ebe;Келебердівка;Келебердовка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081406;3;1
5cb61658-749b-11df-b112-00215aee3ebe;Келечин;Келечин;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122482001;3;1
5cb61659-749b-11df-b112-00215aee3ebe;Келихів;Келихов;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625285902;3;1
5cb6165a-749b-11df-b112-00215aee3ebe;Кельменці;Кельменцы;місто;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7322055100;3;0
5cb6165c-749b-11df-b112-00215aee3ebe;Кербутівка;Кербутовка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420883602;3;1
5cb6165d-749b-11df-b112-00215aee3ebe;Кердилівщина;Кердылевщина;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982405;3;1
5cb6165e-749b-11df-b112-00215aee3ebe;Керецьки;Керецки;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124082901;3;1
5cb6165f-749b-11df-b112-00215aee3ebe;Керниця;Керница;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620983901;3;1
5cb61660-749b-11df-b112-00215aee3ebe;Керносівка;Керносовка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223283001;3;1
5cb61661-749b-11df-b112-00215aee3ebe;Керстенці;Керстенцы;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325083201;3;1
5cb61663-749b-11df-b112-00215aee3ebe;Кетрисанівка;Кетрисановка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520883801;3;1
5cb61664-749b-11df-b112-00215aee3ebe;Кибаки;Кибаки;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7320584502;3;1
5cb61665-749b-11df-b112-00215aee3ebe;Кибинці;Кибинцы;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323282801;3;1
5cb61666-749b-11df-b112-00215aee3ebe;Кибляри;Кибляры;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124883001;3;1
5cb61667-749b-11df-b112-00215aee3ebe;Кивачівка;Кивачовка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523782601;3;1
5cb61668-749b-11df-b112-00215aee3ebe;Киданів;Кыданов;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121282901;3;1
5cb61669-749b-11df-b112-00215aee3ebe;Киданівка;Кидановка;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220682701;3;1
5cb6166a-749b-11df-b112-00215aee3ebe;Киданці;Киданцы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122484201;3;1
5cb6166b-749b-11df-b112-00215aee3ebe;Кидрасівка;Кидрасовка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520482203;3;1
5cb6166c-749b-11df-b112-00215aee3ebe;Києво-Олександрівське;Киево-Александровское;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821785003;3;1
5cb61670-749b-11df-b112-00215aee3ebe;Кизими;Кизимы;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523785802;3;1
5cb61671-749b-11df-b112-00215aee3ebe;Київ;Киев;місто;e672324c-4237-11e3-9df6-003048d2b473;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;8000000000;1;0
5cb61672-749b-11df-b112-00215aee3ebe;Київець;Киевец;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623084201;3;1
5cb61674-749b-11df-b112-00215aee3ebe;Київське;Киевское;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224881004;3;1
5cb61675-749b-11df-b112-00215aee3ebe;Київське;Киевское;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610109;3;1
5cb61676-749b-11df-b112-00215aee3ebe;Київське;Киевское;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323687203;3;1
5cb61677-749b-11df-b112-00215aee3ebe;Київське;Киевское;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320486903;3;1
e0517ed2-db89-11df-9197-00215aee3ebe;Київське;Киевское;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824880204;3;1
5cb61678-749b-11df-b112-00215aee3ebe;Киїнка;Киенка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425583401;3;0
5cb61679-749b-11df-b112-00215aee3ebe;Кийданці;Кийданцы;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623255701;3;1
5cb6167a-749b-11df-b112-00215aee3ebe;Кийків;Кийков;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621887613;3;1
5cb6167b-749b-11df-b112-00215aee3ebe;Кийлів;Кийлов;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220882903;3;1
5cb6167c-749b-11df-b112-00215aee3ebe;Кикишівка;Кикишовка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820886802;3;1
5cb6167d-749b-11df-b112-00215aee3ebe;Кикова;Кикова;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824081801;3;1
5cb6167e-749b-11df-b112-00215aee3ebe;Киликіїв;Киликиев;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823983401;3;1
5cb6167f-749b-11df-b112-00215aee3ebe;Кимир;Кимир;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623388002;3;1
5cb61680-749b-11df-b112-00215aee3ebe;Кимличка;Кимличка;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923282201;3;1
5cb61681-749b-11df-b112-00215aee3ebe;Кинахівці;Кинаховцы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122488102;3;1
5cb61682-749b-11df-b112-00215aee3ebe;Кинашів;Кинашев;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524382901;3;1
5cb61683-749b-11df-b112-00215aee3ebe;Кинашівка;Кинашевка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420810103;3;1
5cb61687-749b-11df-b112-00215aee3ebe;Кип'ячка;Кипячка;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125281703;3;1
5cb61688-749b-11df-b112-00215aee3ebe;Кип'ячка;Кипячка;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222982901;3;1
5cb616a7-749b-11df-b112-00215aee3ebe;Кир'яківка;Кирьяковка;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824280901;3;1
5cb61689-749b-11df-b112-00215aee3ebe;Кирдани;Кирданы;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224481501;3;1
5cb6168a-749b-11df-b112-00215aee3ebe;Кирдани;Кирданы;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824283001;3;1
5cb61699-749b-11df-b112-00215aee3ebe;Кириївка;Киреевка;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823180202;3;1
5cb6168b-749b-11df-b112-00215aee3ebe;Кириївщина;Кириевщина;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220886402;3;1
5cb6168c-749b-11df-b112-00215aee3ebe;Кириківка;Кириковка;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921255400;3;1
5cb6168d-749b-11df-b112-00215aee3ebe;Кирилівка;Кириловка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323381001;3;1
5cb6168e-749b-11df-b112-00215aee3ebe;Кирилівка;Кириловка;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122585002;3;1
5cb6168f-749b-11df-b112-00215aee3ebe;Кирилівка;Кириловка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122987406;3;1
5cb61693-749b-11df-b112-00215aee3ebe;Кирилівка;Кириловка;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422483503;3;1
5cb61694-749b-11df-b112-00215aee3ebe;Кирилівка;Кириловка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521780602;3;1
5cb61695-749b-11df-b112-00215aee3ebe;Кирило-Ганнівка;Кирило-Анновка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321382701;3;1
5cb61696-749b-11df-b112-00215aee3ebe;Киричкове;Киричково;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224287804;3;1
5cb61697-749b-11df-b112-00215aee3ebe;Кирияківка;Кирияковка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320680703;3;1
5cb61698-749b-11df-b112-00215aee3ebe;Кирияківщина;Кирияковщина;село;9b486f64-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5910191509;3;1
5cb6169a-749b-11df-b112-00215aee3ebe;Киріївка;Кириевка;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424983501;3;1
5cb6169b-749b-11df-b112-00215aee3ebe;Кирнасівка;Кирнасовка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524355300;3;1
5cb6169c-749b-11df-b112-00215aee3ebe;Кирнички;Кирнички;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122082601;2;1
5cb6169e-749b-11df-b112-00215aee3ebe;Кирпичне;Кирпичное;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224284509;3;1
5cb616a1-749b-11df-b112-00215aee3ebe;Кирпотине;Кирпотино;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955413;3;1
5cb616a2-749b-11df-b112-00215aee3ebe;Кирпотівка;Кирпотовка;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755123;3;1
5cb616a4-749b-11df-b112-00215aee3ebe;Кирсанове;Кирсаново;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325181502;3;1
5cb616a5-749b-11df-b112-00215aee3ebe;Кирсівка;Кирсовка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;;3;1
5cb616a6-749b-11df-b112-00215aee3ebe;Кирюхи;Кирюхи;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321780505;3;1
5cb616a8-749b-11df-b112-00215aee3ebe;Кирякове;Киряково;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321384806;3;1
5cb616aa-749b-11df-b112-00215aee3ebe;Киселиха;Киселиха;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755124;3;1
5cb616ab-749b-11df-b112-00215aee3ebe;Киселиці;Киселицы;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323581501;3;1
5cb616ac-749b-11df-b112-00215aee3ebe;Киселі;Кисели;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324584501;3;1
5cb616ad-749b-11df-b112-00215aee3ebe;Киселі;Кисели;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824287703;3;1
5cb616ae-749b-11df-b112-00215aee3ebe;Киселів;Киселев;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322584001;3;1
5cb616af-749b-11df-b112-00215aee3ebe;Киселівка;Киселевка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322081604;3;1
5cb616b0-749b-11df-b112-00215aee3ebe;Киселівка;Киселевка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825781701;3;1
5cb616b1-749b-11df-b112-00215aee3ebe;Киселівка;Киселевка;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520383001;3;1
5cb616b3-749b-11df-b112-00215aee3ebe;Киселівка;Киселевка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821780305;3;1
5cb616b4-749b-11df-b112-00215aee3ebe;Киселівка;Киселевка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425583501;3;1
5cb616b5-749b-11df-b112-00215aee3ebe;Киселівка;Киселевка;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423084501;3;1
5cb616b6-749b-11df-b112-00215aee3ebe;Киселівка;Киселевка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423686602;3;1
5cb616b7-749b-11df-b112-00215aee3ebe;Киселівка;Киселевка;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423885002;3;1
5cb616b8-749b-11df-b112-00215aee3ebe;Киселівка;Киселевка;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122283001;3;1
5cb616b9-749b-11df-b112-00215aee3ebe;Киселівка;Киселевка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822480404;3;1
f933344e-db4e-11df-9197-00215aee3ebe;Киселівка;Киселевка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424486403;3;1
5cb616ba-749b-11df-b112-00215aee3ebe;Кисилин;Кисилин;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722483201;3;1
5cb616bb-749b-11df-b112-00215aee3ebe;Кисівка;Кисовка;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323281206;3;1
5cb616bc-749b-11df-b112-00215aee3ebe;Кисла Балка;Кислая Балка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083201;3;1
5cb616bd-749b-11df-b112-00215aee3ebe;Кисле;Кислое;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321780506;3;1
5cb616be-749b-11df-b112-00215aee3ebe;Кислин;Кислин;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123182801;3;1
5cb616bf-749b-11df-b112-00215aee3ebe;Кислиця;Кислица;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122082901;3;1
5cb616c0-749b-11df-b112-00215aee3ebe;Кислицьке;Кислицкое;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523955108;3;1
5cb616c1-749b-11df-b112-00215aee3ebe;Кисличеватое;Кисличеватое;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455114;3;1
5cb616c2-749b-11df-b112-00215aee3ebe;Кислі;Кислые;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424487204;3;1
5cb616c4-749b-11df-b112-00215aee3ebe;Кислівка;Кисловка;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224481701;3;1
5cb616c5-749b-11df-b112-00215aee3ebe;Кисляк;Кисляк;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520882409;3;1
5cb616c6-749b-11df-b112-00215aee3ebe;Кислянка;Кислянка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224882501;3;1
5cb616c7-749b-11df-b112-00215aee3ebe;Кисоричі;Кисоричи;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625084401;3;1
5cb616c8-749b-11df-b112-00215aee3ebe;Китайгород;Китайгород;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225682001;3;1
5cb616c9-749b-11df-b112-00215aee3ebe;Китайгород;Китайгород;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521283203;3;1
5cb616ca-749b-11df-b112-00215aee3ebe;Китайгород;Китайгород;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524184804;3;1
5cb616cb-749b-11df-b112-00215aee3ebe;Китайгород;Китайгород;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520555101;3;1
5cb616cc-749b-11df-b112-00215aee3ebe;Китайгород;Китайгород;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822483901;3;1
5cb616cd-749b-11df-b112-00215aee3ebe;Китайгородка;Китайгородка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455115;3;1
5cb616ce-749b-11df-b112-00215aee3ebe;Китченківка;Китченковка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323582201;3;1
5cb6173f-749b-11df-b112-00215aee3ebe;Кихті;Кихти;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825882002;3;1
5cb616cf-749b-11df-b112-00215aee3ebe;Кицівка;Кицовка;село;64024b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324655101;3;1
5cb616d0-749b-11df-b112-00215aee3ebe;Кичинці;Кичинцы;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122583901;3;1
5cb616d1-749b-11df-b112-00215aee3ebe;Кичкирі;Кичкири;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825084401;3;1
5cb616d2-749b-11df-b112-00215aee3ebe;Кишеньки;Кишеньки;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321886403;3;1
5cb616d3-749b-11df-b112-00215aee3ebe;Кишин;Кишин;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824484001;3;1
5cb616d5-749b-11df-b112-00215aee3ebe;Кищенці;Кищенцы;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123183001;3;1
5cb616d6-749b-11df-b112-00215aee3ebe;Кищинці;Кищинцы;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224983501;3;1
5cb617d0-749b-11df-b112-00215aee3ebe;Кияж;Кияж;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724581903;3;1
5cb616d7-749b-11df-b112-00215aee3ebe;Кияни;Кияны;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320888109;3;1
5cb616d8-749b-11df-b112-00215aee3ebe;Кияниця;Кияница;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924785901;3;1
5cb616d9-749b-11df-b112-00215aee3ebe;Киянівка;Кияновка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520283606;3;1
5cb616da-749b-11df-b112-00215aee3ebe;Киянка;Киянка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821782701;3;1
5cb616db-749b-11df-b112-00215aee3ebe;Киянка;Киянка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824082001;3;1
5cb616dd-749b-11df-b112-00215aee3ebe;Кияшки;Кияшки;село;d00d3b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5310290309;3;1
5cb616de-749b-11df-b112-00215aee3ebe;Кияшківське;Кияшковское;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320488203;3;1
5cb616df-749b-11df-b112-00215aee3ebe;Кіблицьке;Киблицкое;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5310390003;3;1
5cb616e0-749b-11df-b112-00215aee3ebe;Кіблич;Киблич;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520882603;3;1
5cb616e1-749b-11df-b112-00215aee3ebe;Ківерці;Киверцы;місто;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721810100;2;0
5cb616e3-749b-11df-b112-00215aee3ebe;Ківшик;Ковшик;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923555605;3;1
5cb616e4-749b-11df-b112-00215aee3ebe;Ківшовата;Кившовата;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224481901;3;1
5cb616e5-749b-11df-b112-00215aee3ebe;Кідри;Кидры;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620885401;3;1
5cb616e6-749b-11df-b112-00215aee3ebe;Кідьош;Кидьош;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120483501;3;1
5cb616eb-749b-11df-b112-00215aee3ebe;Кіз'я;Кизья;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620382405;3;1
5cb616e7-749b-11df-b112-00215aee3ebe;Кізлів;Кизлов;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620682301;3;1
5cb616e8-749b-11df-b112-00215aee3ebe;Кізлівка;Кизловка;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325181701;3;1
5cb616e9-749b-11df-b112-00215aee3ebe;Кізомис;Кизомыс;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520382501;3;1
5cb616ea-749b-11df-b112-00215aee3ebe;Кізя;Кизя;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822486504;2;1
5cb616ec-749b-11df-b112-00215aee3ebe;Кізя-Кудринецька;Кизя-Кудринецкая;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822487603;2;1
5cb616ed-749b-11df-b112-00215aee3ebe;Кілія;Килия;місто;903ccab3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122310100;2;0
5cb616ee-749b-11df-b112-00215aee3ebe;Кілки;Колки;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825883601;3;1
9967d4d9-dad5-11ea-80ca-000c29800ae7;Кілки;Колки;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825883601;3;1
5cb616ef-749b-11df-b112-00215aee3ebe;Кілочки;Килочки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321384003;3;1
5cb616f0-749b-11df-b112-00215aee3ebe;Кільчень;Кильчень;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223282004;3;1
7ad94f95-749b-11df-b112-00215aee3ebe;Кільчень (Радянське);Радянское;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222355102;3;1
5cb616f1-749b-11df-b112-00215aee3ebe;Кільянівка;Кильяновка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522480803;3;1
5cb616f3-749b-11df-b112-00215aee3ebe;Кімівка;Кимовка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820981801;3;1
5cb616f4-749b-11df-b112-00215aee3ebe;Кімната;Комната;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123481704;3;1
5cb616f6-749b-11df-b112-00215aee3ebe;Кінашів;Кинашев;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621255304;3;1
5cb616f7-749b-11df-b112-00215aee3ebe;Кіндратів;Кондратов;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625588202;3;1
5cb616f8-749b-11df-b112-00215aee3ebe;Кіндратівка;Кондратовка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924783401;3;1
5cb616f9-749b-11df-b112-00215aee3ebe;Кіндратівка;Кондратовка;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955113;3;1
5cb616fb-749b-11df-b112-00215aee3ebe;Кіндратівка;Кондратовка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523681204;3;1
5cb616fc-749b-11df-b112-00215aee3ebe;Кіндратки;Кандратки;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822180402;3;1
e759272d-4f3d-11e2-a681-003048d2b473;Кіндратки ;Киндратки;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;;3;1
5cb616ff-749b-11df-b112-00215aee3ebe;Кіндрівка;Киндровка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322487003;3;1
5cb61700-749b-11df-b112-00215aee3ebe;Кінецьпіль;Конецполь;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825482801;3;1
5cb61701-749b-11df-b112-00215aee3ebe;Кінлодь;Кинлодь;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122782804;3;1
5cb61702-749b-11df-b112-00215aee3ebe;Кінне;Конное;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323982101;3;1
5cb61703-749b-11df-b112-00215aee3ebe;Кінне;Конное;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122784203;3;1
5cb61705-749b-11df-b112-00215aee3ebe;Кінчаки;Кинчаки;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621283901;3;1
5cb61706-749b-11df-b112-00215aee3ebe;Кінчеш;Кинчеш;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2124883602;3;1
5cb61707-749b-11df-b112-00215aee3ebe;Кіпті;Кипти;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784807;3;1
5cb61708-749b-11df-b112-00215aee3ebe;Кіпті;Кипти;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422083301;3;1
5cb61709-749b-11df-b112-00215aee3ebe;Кіптівка;Коптевка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324585002;3;1
5cb6170a-749b-11df-b112-00215aee3ebe;Кіпчинці;Кипчинцы;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823680502;3;1
73e94bfa-ddc1-11df-9197-00215aee3ebe;Кіреші;Киреши;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2110890002;3;1
5cb6170b-749b-11df-b112-00215aee3ebe;Кірова;Кирова;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582001;3;1
5cb61712-749b-11df-b112-00215aee3ebe;Кірове;Кирово;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824280701;3;1
5cb6170f-749b-11df-b112-00215aee3ebe;Кірове;Кирово;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;*122188590;3;1
5cb61710-749b-11df-b112-00215aee3ebe;Кірове;Кирово;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;*122298220;3;1
5cb61717-749b-11df-b112-00215aee3ebe;Кірове;Кирово;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;;3;1
5cb61727-749b-11df-b112-00215aee3ebe;Кірове;Кирово;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520884203;3;1
5cb61725-749b-11df-b112-00215aee3ebe;Кірове;Кирово;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122583701;3;1
5cb61722-749b-11df-b112-00215aee3ebe;Кірове;Кирово;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222081303;3;1
5cb61729-749b-11df-b112-00215aee3ebe;Кіровка;Кировка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;*122208500;3;1
5cb6172b-749b-11df-b112-00215aee3ebe;Кіровка;Кировка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;*122428610;3;1
5cb6172d-749b-11df-b112-00215aee3ebe;Кіровка;Кировка;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423886004;3;1
5cb6172a-749b-11df-b112-00215aee3ebe;Кіровка (Данилівка);Кировка (Даниловка);село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221882901;3;1
5cb61735-749b-11df-b112-00215aee3ebe;Кіровське;Кировское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582203;3;1
5cb6173b-749b-11df-b112-00215aee3ebe;Кісниця;Косница;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521984002;3;1
5cb6173c-749b-11df-b112-00215aee3ebe;Кістельниця;Кистельница;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121685802;3;1
5cb6173d-749b-11df-b112-00215aee3ebe;Кісточки;Косточки;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;;3;1
5cb61740-749b-11df-b112-00215aee3ebe;Кіцмань;Кицмань;місто;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7322510100;3;0
cdc44f5d-dde8-11df-9197-00215aee3ebe;Кічерели;Кичерели;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125382803;3;1
5cb61741-749b-11df-b112-00215aee3ebe;Кічерний;Кичерный;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121585002;3;1
5cb61742-749b-11df-b112-00215aee3ebe;Клавдієво-Тарасове;Клавдиево-Тарасово;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221055500;2;0
5cb61743-749b-11df-b112-00215aee3ebe;Кладнів;Кладнов;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720510410;3;1
5cb61744-749b-11df-b112-00215aee3ebe;Кладьківка;Кладьковка;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422785001;3;1
5cb61745-749b-11df-b112-00215aee3ebe;Клапая;Клапая;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520383004;3;1
5cb61747-749b-11df-b112-00215aee3ebe;Клебани;Клебаны;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787610;3;1
5cb61748-749b-11df-b112-00215aee3ebe;Клебанівка;Клебановка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124683301;3;1
5cb61749-749b-11df-b112-00215aee3ebe;Клебань;Клебань;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524383201;3;1
5cb6174a-749b-11df-b112-00215aee3ebe;Клевань;Клевань;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624655400;2;1
5cb6174b-749b-11df-b112-00215aee3ebe;Клевецьк;Клевецк;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725585603;3;1
5cb6174c-749b-11df-b112-00215aee3ebe;Клекотина;Клекотина;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525381802;3;1
5cb6174d-749b-11df-b112-00215aee3ebe;Клекотів;Клекотов;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620388404;3;1
5cb6174e-749b-11df-b112-00215aee3ebe;Клембівка;Клембовка;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525683201;3;1
5cb6174f-749b-11df-b112-00215aee3ebe;Клен;Клен;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825082204;3;1
5cb61752-749b-11df-b112-00215aee3ebe;Кленова;Кленовая;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824055605;3;1
5cb61753-749b-11df-b112-00215aee3ebe;Кленове;Кленовое;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320884501;3;1
5cb61754-749b-11df-b112-00215aee3ebe;Кленове;Кленовое;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221684402;3;1
5cb61755-749b-11df-b112-00215aee3ebe;Кленове;Кленовое;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423880504;3;1
5cb61756-749b-11df-b112-00215aee3ebe;Кленове;Кленовое;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524355602;3;1
7ad94e45-749b-11df-b112-00215aee3ebe;Кленове (Пролетарське);Кленовое (Пролетарское);село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320686509;3;1
5cb61757-749b-11df-b112-00215aee3ebe;Кленовець;Кленовец;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122755302;2;1
5cb61759-749b-11df-b112-00215aee3ebe;Клепали;Клепалы;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920984101;3;1
5cb6175a-749b-11df-b112-00215aee3ebe;Клепачі;Клепачи;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823983601;3;1
5cb6175b-749b-11df-b112-00215aee3ebe;Клепачі;Клепачи;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322883202;3;1
5cb6175c-749b-11df-b112-00215aee3ebe;Клепачі;Клепачи;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324881901;3;1
5cb6175d-749b-11df-b112-00215aee3ebe;Клепачів;Клепачев;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721882702;3;1
5cb6175f-749b-11df-b112-00215aee3ebe;Клесів;Клесов;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625455401;3;0
5cb61760-749b-11df-b112-00215aee3ebe;Клесів (село);Клесов;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;;3;1
5cb61761-749b-11df-b112-00215aee3ebe;Клехівка;Клеховка;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224986903;3;1
5cb61762-749b-11df-b112-00215aee3ebe;Клеці;Клецы;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424155901;3;1
5cb61763-749b-11df-b112-00215aee3ebe;Клешнівка;Клешнивка;село;bad3ac3c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1223781406;3;1
5cb61764-749b-11df-b112-00215aee3ebe;Клещиха;Клещиха;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621682004;3;1
5cb61765-749b-11df-b112-00215aee3ebe;Климашівка;Климашовка;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825085702;3;1
5cb61766-749b-11df-b112-00215aee3ebe;Клименівка;Клименовка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321781503;3;1
5cb61767-749b-11df-b112-00215aee3ebe;Клименки;Клименки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321387205;3;1
5cb61768-749b-11df-b112-00215aee3ebe;Клименки;Клименки;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081303;3;1
5cb61769-749b-11df-b112-00215aee3ebe;Климентинівка;Климентиновка;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423085503;3;1
5cb6176a-749b-11df-b112-00215aee3ebe;Климентіївка;Климентовка;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820680802;3;1
5cb6176b-749b-11df-b112-00215aee3ebe;Климентове;Климентово;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920388003;3;1
5cb6176c-749b-11df-b112-00215aee3ebe;Климентове;Климентово;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122983001;3;1
5cb6176d-749b-11df-b112-00215aee3ebe;Климентовичі;Климентовичи;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825588503;3;1
5cb6176e-749b-11df-b112-00215aee3ebe;Климець;Климец;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624582701;3;1
5cb6176f-749b-11df-b112-00215aee3ebe;Климівка;Климовка;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321681601;3;1
5cb61770-749b-11df-b112-00215aee3ebe;Климівка;Климовка;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823980402;3;1
5cb61773-749b-11df-b112-00215aee3ebe;Климівщина;Климовщина;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624284603;3;1
5cb61774-749b-11df-b112-00215aee3ebe;Климківка;Климковка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321084003;3;1
5cb61775-749b-11df-b112-00215aee3ebe;Климківці;Климковцы;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124687102;3;1
5cb61776-749b-11df-b112-00215aee3ebe;Климківці;Климковцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825081202;3;1
5cb61777-749b-11df-b112-00215aee3ebe;Климове;Климово;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755125;3;1
5cb61779-749b-11df-b112-00215aee3ebe;Климовиця;Климовица;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121983604;3;1
5cb6177a-749b-11df-b112-00215aee3ebe;Климпуші;Клымпуши;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624080603;3;1
5cb6177b-749b-11df-b112-00215aee3ebe;Клин;Клин;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923582203;3;1
5cb6177c-749b-11df-b112-00215aee3ebe;Клин;Клин;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221081004;3;1
5cb6177d-749b-11df-b112-00215aee3ebe;Клин;Клин;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623888502;3;1
5cb6177e-749b-11df-b112-00215aee3ebe;Клинець;Клинец;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824285802;3;1
5cb61780-749b-11df-b112-00215aee3ebe;Клинини;Клинины;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820983301;3;1
5cb61783-749b-11df-b112-00215aee3ebe;Клинове;Клиновое;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324283502;3;1
5cb61786-749b-11df-b112-00215aee3ebe;Клинове;Клиновое;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521482801;3;1
5cb61787-749b-11df-b112-00215aee3ebe;Клинове;Клиновое;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821283201;3;1
73e94bec-ddc1-11df-9197-00215aee3ebe;Клинове;Клиновое;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;;3;1
5cb61788-749b-11df-b112-00215aee3ebe;Клиновецька Гора;Клиновецкая Гора;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121282404;3;1
5cb6178a-749b-11df-b112-00215aee3ebe;Клинці;Клинцы;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522583901;3;1
5cb6178b-749b-11df-b112-00215aee3ebe;Клинці;Клинцы;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621681605;3;1
5cb6178c-749b-11df-b112-00215aee3ebe;Клипець;Клипец;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621688205;3;1
5cb6178d-749b-11df-b112-00215aee3ebe;Клипин;Клыпин;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420881603;3;1
5cb6178e-749b-11df-b112-00215aee3ebe;Кличкове;Клычково;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120381502;3;1
5cb6178f-749b-11df-b112-00215aee3ebe;Кличковичі;Кличковичи;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725582905;3;1
5cb61790-749b-11df-b112-00215aee3ebe;Клишки;Клишки;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925383001;3;1
5cb61791-749b-11df-b112-00215aee3ebe;Кліводин;Кливодин;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322584501;3;1
5cb61792-749b-11df-b112-00215aee3ebe;Клітенка;Клетенка;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524883102;3;1
5cb61793-749b-11df-b112-00215aee3ebe;Клітицьк;Клетицк;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721484801;3;1
5cb61794-749b-11df-b112-00215aee3ebe;Клітище;Клетище;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825684801;3;1
5cb61795-749b-11df-b112-00215aee3ebe;Клітня;Клетня;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823481202;3;1
5cb61796-749b-11df-b112-00215aee3ebe;Клітчин;Клетчин;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822084103;1;0
5cb61797-749b-11df-b112-00215aee3ebe;Кліцько;Клецко;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620984201;3;1
5cb61798-749b-11df-b112-00215aee3ebe;Клішківці;Клишковцы;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7325083601;3;1
5cb61799-749b-11df-b112-00215aee3ebe;Кліщинці;Клещинцы;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125183201;3;1
5cb6179a-749b-11df-b112-00215aee3ebe;Кліщів;Клещов;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524582802;3;1
5cb6179b-749b-11df-b112-00215aee3ebe;Кліщівна;Клищивна;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624482401;3;1
5cb6179d-749b-11df-b112-00215aee3ebe;Клокічка;Клокичка;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322580503;3;1
5cb6179e-749b-11df-b112-00215aee3ebe;Клонів;Клонов;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424488803;3;1
5cb6179f-749b-11df-b112-00215aee3ebe;Клопіт;Клопот;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622655402;3;1
5cb617a0-749b-11df-b112-00215aee3ebe;Клопотівці;Клопотивци;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821584401;3;1
5cb617a1-749b-11df-b112-00215aee3ebe;Клопочин;Клопочин;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721183808;3;1
5cb617a2-749b-11df-b112-00215aee3ebe;Клочеве;Клочевое;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822382402;3;1
5cb617a3-749b-11df-b112-00215aee3ebe;Клочки;Клочки;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122781402;3;1
5cb617a4-749b-11df-b112-00215aee3ebe;Клочки;Клочки;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220482202;2;1
5cb617a5-749b-11df-b112-00215aee3ebe;Клочки;Клочки;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755130;3;1
cdc44f4a-dde8-11df-9197-00215aee3ebe;Клочки;Клочки;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;;3;1
5cb617a6-749b-11df-b112-00215aee3ebe;Клочків;Клочков;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425589502;3;1
5cb617a7-749b-11df-b112-00215aee3ebe;Клочківка;Клочковка;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921581902;3;1
5cb617a8-749b-11df-b112-00215aee3ebe;Клубівка;Клубовка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424483301;3;1
5cb617a9-749b-11df-b112-00215aee3ebe;Клубівка;Клубовка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822182601;3;1
5cb617aa-749b-11df-b112-00215aee3ebe;Клубівці;Клубовцы;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625881501;3;1
5cb617ab-749b-11df-b112-00215aee3ebe;Клубочин;Клубочин;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721886203;3;1
5cb617ac-749b-11df-b112-00215aee3ebe;Клугино-Башкирівка;Клугино-Башкировка;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6312090001;3;1
5cb617ad-749b-11df-b112-00215aee3ebe;Клузів;Клузов;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625881601;3;1
5cb617af-749b-11df-b112-00215aee3ebe;Клювинці;Клювинцы;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121682101;3;1
5cb617b0-749b-11df-b112-00215aee3ebe;Клюки;Клюки;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224684001;2;1
5cb617b1-749b-11df-b112-00215aee3ebe;Клюки;Клюки;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621685207;3;1
5cb617b2-749b-11df-b112-00215aee3ebe;Клюси;Клюсы;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923281903;3;1
5cb617b3-749b-11df-b112-00215aee3ebe;Клюси;Клюсы;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425882501;3;1
5cb617b4-749b-11df-b112-00215aee3ebe;Клюсівка;Клюсовка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323481401;3;1
5cb617b5-749b-11df-b112-00215aee3ebe;Клюськ;Клюск;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725581301;3;1
5cb617b6-749b-11df-b112-00215aee3ebe;Ключарки;Ключарки;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122783801;2;1
5cb617b7-749b-11df-b112-00215aee3ebe;Ключеводське;Ключеводское;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324255302;3;1
5cb617b8-749b-11df-b112-00215aee3ebe;Ключинівка;Ключиновка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983205;3;1
5cb617ba-749b-11df-b112-00215aee3ebe;Ключівка;Ключовка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822781204;3;1
5cb617bb-749b-11df-b112-00215aee3ebe;Ключники;Ключники;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122084705;3;1
5cb617c1-749b-11df-b112-00215aee3ebe;Клюшники;Клюшники;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083907;3;1
5cb617c2-749b-11df-b112-00215aee3ebe;Клюшниківка;Клюшниковка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323283401;3;1
5cb617c3-749b-11df-b112-00215aee3ebe;Клячаново;Клячаново;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122783202;3;0
5cb617c4-749b-11df-b112-00215aee3ebe;Кмитів;Кмитов;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822583201;3;1
5cb617c5-749b-11df-b112-00215aee3ebe;Книшівка;Кнышовка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084204;3;1
5cb617c6-749b-11df-b112-00215aee3ebe;Книшівка;Кнышовка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320483001;3;1
5cb617c7-749b-11df-b112-00215aee3ebe;Кнісело;Книсело;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621555907;3;1
5cb617c8-749b-11df-b112-00215aee3ebe;Кнути;Кнуты;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424986502;3;1
5cb617c9-749b-11df-b112-00215aee3ebe;Княгинин;Княгинин;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822489302;2;1
5cb617cb-749b-11df-b112-00215aee3ebe;Княгинин;Княгинин;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621682601;3;1
5cb617ca-749b-11df-b112-00215aee3ebe;Княгинине;Княгинино;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621483506;3;1
68cf26c5-749b-11df-b112-00215aee3ebe;Княгининок (Маяки);Княгининок (Маяки);село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722883701;3;0
5cb617cc-749b-11df-b112-00215aee3ebe;Княгиничі;Княгиничи;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624482501;3;1
5cb617cd-749b-11df-b112-00215aee3ebe;Княгиничі;Княгиничи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622482802;3;1
5cb617cf-749b-11df-b112-00215aee3ebe;Княгиня;Княгиня;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120885602;3;1
5cb617d1-749b-11df-b112-00215aee3ebe;Княжа;Княжья;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121283201;3;1
871626b6-749b-11df-b112-00215aee3ebe;Княжа Долина (Червоний Прапор);Княжеская Долина (Червоный Прапор);село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323580312;3;1
5cb617d2-749b-11df-b112-00215aee3ebe;Княжа Криниця;Княжья Криница;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123482001;3;1
7ad94fa4-749b-11df-b112-00215aee3ebe;Княжа Криниця (Радянське);Княжья Криница (Радянское);село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521980805;3;1
871621ff-749b-11df-b112-00215aee3ebe;Княжа Лука (Улянівка);Княжа Лука (Улянівка);село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324885413;3;1
5cb617d3-749b-11df-b112-00215aee3ebe;Княждвір;Княждвор;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623255702;3;1
5cb617d4-749b-11df-b112-00215aee3ebe;Княже;Княжье;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625282601;3;1
5cb617d5-749b-11df-b112-00215aee3ebe;Княже;Княжье;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624882601;3;1
5cb617d6-749b-11df-b112-00215aee3ebe;Княже;Княжье;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621883301;3;1
5cb617d7-749b-11df-b112-00215aee3ebe;Княжева Слобода;Княжева Слобода;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321387804;3;1
5cb617d8-749b-11df-b112-00215aee3ebe;Княжий Міст;Княжий Мост;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622482403;3;1
5cb617d9-749b-11df-b112-00215aee3ebe;Княжики;Княжики;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825284001;3;1
5cb617da-749b-11df-b112-00215aee3ebe;Княжики;Княжики;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123482501;3;1
5cb617db-749b-11df-b112-00215aee3ebe;Княжин;Княжин;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825888202;3;1
5cb617dc-749b-11df-b112-00215aee3ebe;Княжичі;Княжичи;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925681501;3;1
5cb617dd-749b-11df-b112-00215aee3ebe;Княжичі;Княжичи;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221284001;3;0
5cb617de-749b-11df-b112-00215aee3ebe;Княжичі;Княжичи;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222483601;3;1
5cb617df-749b-11df-b112-00215aee3ebe;Княжне;Княжное;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324284704;3;1
5cb617e0-749b-11df-b112-00215aee3ebe;Княжолука;Княжелука;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622082401;3;1
5cb617e1-749b-11df-b112-00215aee3ebe;Княжпіль;Княжполь;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625182901;3;1
5cb617e2-749b-11df-b112-00215aee3ebe;Княжпіль;Княжполь;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822484001;3;1
5cb617e4-749b-11df-b112-00215aee3ebe;Князівка;Князевка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923885801;3;1
5cb617e5-749b-11df-b112-00215aee3ebe;Князівка;Князевка;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521855101;3;1
5cb617e6-749b-11df-b112-00215aee3ebe;Князівка;Князевка;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620488603;3;1
5cb617e7-749b-11df-b112-00215aee3ebe;Князівське;Князевское;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624882101;3;1
5cb617e8-749b-11df-b112-00215aee3ebe;Кобаки;Кобаки;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623685202;3;1
5cb617e9-749b-11df-b112-00215aee3ebe;Кобалевиця;Кобалевица;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121983605;3;1
5cb617ea-749b-11df-b112-00215aee3ebe;Кобелецьке;Кобелецкое;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524582203;3;1
5cb617eb-749b-11df-b112-00215aee3ebe;Кобеляки;Кобеляки;місто;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321810100;2;0
5cb617ec-749b-11df-b112-00215aee3ebe;Кобелячок;Кобелячок;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322484803;3;1
5cb617ed-749b-11df-b112-00215aee3ebe;Кобзар;Кобзарь;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220787705;3;1
5cb617ef-749b-11df-b112-00215aee3ebe;Кобзарівка;Кобзаривка;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122684801;3;1
5cb617f0-749b-11df-b112-00215aee3ebe;Кобзарівка;Кобзаривка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321283001;3;1
5cb617f1-749b-11df-b112-00215aee3ebe;Кобзарівка;Кобзаривка;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525281802;3;1
5cb617f2-749b-11df-b112-00215aee3ebe;Кобзарці;Кобзарцы;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825782001;3;1
5cb617f3-749b-11df-b112-00215aee3ebe;Кобзівка;Кобзивка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323381201;3;1
59503b55-e861-11e1-a474-003048d2b473;Коби;Коби;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323486404;3;1
5cb617f4-749b-11df-b112-00215aee3ebe;Кобижча;Кобыжча;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420684001;3;1
5cb617f5-749b-11df-b112-00215aee3ebe;Кобилець;Кобылец;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623282402;3;1
5cb617f6-749b-11df-b112-00215aee3ebe;Кобилецька Поляна;Кобылецкая Поляна;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123655800;3;1
5cb617f7-749b-11df-b112-00215aee3ebe;Кобилеччина;Кобылеччина;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621887609;3;1
5cb617f8-749b-11df-b112-00215aee3ebe;Кобилещина;Кобылещина;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423887003;3;1
5cb617f9-749b-11df-b112-00215aee3ebe;Кобилля;Кобылье;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122484501;3;1
5cb617fa-749b-11df-b112-00215aee3ebe;Кобиловолоки;Кобыловолоки;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125083201;3;1
5cb617fb-749b-11df-b112-00215aee3ebe;Кобиляки;Кобыляки;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121283401;3;1
5cb617fc-749b-11df-b112-00215aee3ebe;Кобиляки;Кобыляки;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124087403;3;1
5cb617fd-749b-11df-b112-00215aee3ebe;Кобиляки;Кобыляки;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122286003;3;1
5cb617fe-749b-11df-b112-00215aee3ebe;Кобилянка;Кобылянка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425583504;3;1
5cb61800-749b-11df-b112-00215aee3ebe;Кобильня;Кобыльня;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522282602;3;1
5cb61801-749b-11df-b112-00215aee3ebe;Коблеве;Коблево;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820982201;3;1
5cb61802-749b-11df-b112-00215aee3ebe;Коблеве сезонне;Коблеве сезонне;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;3;1
5cb61803-749b-11df-b112-00215aee3ebe;Коблин;Коблин;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623887102;3;1
5cb61804-749b-11df-b112-00215aee3ebe;Коблиця;Коблица;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221085904;3;1
5cb61805-749b-11df-b112-00215aee3ebe;Коблицький Ліс;Коблицкий Лес;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221085903;3;1
5cb61806-749b-11df-b112-00215aee3ebe;Кобло;Кобло;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625185502;3;1
5cb61807-749b-11df-b112-00215aee3ebe;Коболчин;Коболчин;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324084501;3;1
5cb61808-749b-11df-b112-00215aee3ebe;Кобринова Гребля;Кобринова Гребля;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124083601;3;1
5cb61809-749b-11df-b112-00215aee3ebe;Кобринове;Кобриново;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124083201;3;1
5cb6180a-749b-11df-b112-00215aee3ebe;Кобці;Кобцы;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221487003;3;1
5cb6180b-749b-11df-b112-00215aee3ebe;Кобче;Кобче;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724582801;3;1
5cb6185c-749b-11df-b112-00215aee3ebe;Ков'яги;Ковяги;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321255400;3;1
5cb6185d-749b-11df-b112-00215aee3ebe;Ков'ярі;Ковяри;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623682802;3;1
5cb6180c-749b-11df-b112-00215aee3ebe;Ковалеве;Ковалево;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322281501;3;1
5cb6180d-749b-11df-b112-00215aee3ebe;Ковалеве;Ковалево;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282511;3;1
5cb6180e-749b-11df-b112-00215aee3ebe;Коваленки;Коваленки;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920610102;3;1
5cb6180f-749b-11df-b112-00215aee3ebe;Коваленки;Коваленки;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6311290005;3;1
5cb61810-749b-11df-b112-00215aee3ebe;Коваленки;Коваленки;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823185402;3;1
5cb61811-749b-11df-b112-00215aee3ebe;Коваленківка;Коваленковка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321888004;3;1
5cb61812-749b-11df-b112-00215aee3ebe;Ковалин;Ковалин;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223384101;3;1
5cb61813-749b-11df-b112-00215aee3ebe;Ковалиха;Ковалиха;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123782501;3;1
5cb61814-749b-11df-b112-00215aee3ebe;Ковалі;Ковали;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324884207;3;1
5cb61815-749b-11df-b112-00215aee3ebe;Ковалі;Ковали;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322481003;3;1
5cb61816-749b-11df-b112-00215aee3ebe;Ковалі;Ковали;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322684504;3;1
5cb61817-749b-11df-b112-00215aee3ebe;Ковалі;Ковали;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783008;3;1
5cb61818-749b-11df-b112-00215aee3ebe;Ковалі;Ковали;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625884505;3;1
5cb61819-749b-11df-b112-00215aee3ebe;Ковалі;Ковали;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822382201;3;1
5cb6181a-749b-11df-b112-00215aee3ebe;Ковалі;Ковали;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122084302;3;1
5cb6181b-749b-11df-b112-00215aee3ebe;Ковалі;Ковали;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325181707;3;1
5cb6181c-749b-11df-b112-00215aee3ebe;Ковалівка;Ковалевка;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325782601;3;1
5cb6181d-749b-11df-b112-00215aee3ebe;Ковалівка;Ковалевка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081901;3;1
5cb6181e-749b-11df-b112-00215aee3ebe;Ковалівка;Ковалевка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322483803;3;1
5cb6181f-749b-11df-b112-00215aee3ebe;Ковалівка;Ковалевка;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124285601;3;1
5cb61820-749b-11df-b112-00215aee3ebe;Ковалівка;Ковалевка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222083505;3;1
5cb61821-749b-11df-b112-00215aee3ebe;Ковалівка;Ковалевка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823081002;3;1
5cb61822-749b-11df-b112-00215aee3ebe;Ковалівка;Ковалевка;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822383803;3;1
5cb61823-749b-11df-b112-00215aee3ebe;Ковалівка;Ковалевка;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824281201;3;1
5cb61824-749b-11df-b112-00215aee3ebe;Ковалівка;Ковалевка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323586205;3;1
5cb61825-749b-11df-b112-00215aee3ebe;Ковалівка;Ковалевка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320481505;3;1
5cb61826-749b-11df-b112-00215aee3ebe;Ковалівка;Ковалевка;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121082803;3;1
5cb61827-749b-11df-b112-00215aee3ebe;Ковалівка;Ковалевка;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623282601;3;1
5cb61829-749b-11df-b112-00215aee3ebe;Ковалівка;Ковалевка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222083604;3;1
5cb6182a-749b-11df-b112-00215aee3ebe;Ковалівка;Ковалевка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221483601;3;1
5cb6182b-749b-11df-b112-00215aee3ebe;Ковалівка;Ковалевка;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824482802;3;1
5cb6182c-749b-11df-b112-00215aee3ebe;Ковалівка;Ковалевка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120685001;3;1
5cb6182d-749b-11df-b112-00215aee3ebe;Ковалівка;Ковалевка;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523084001;3;1
5cb6182e-749b-11df-b112-00215aee3ebe;Ковалівка;Ковалевка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523183604;3;1
5cb6182f-749b-11df-b112-00215aee3ebe;Ковалівка;Ковалевка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521482802;3;1
5cb61830-749b-11df-b112-00215aee3ebe;Ковалівка;Ковалевка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524083204;3;1
5cb61831-749b-11df-b112-00215aee3ebe;Ковалівка;Ковалевка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821285803;3;1
5cb61832-749b-11df-b112-00215aee3ebe;Ковалівське;Ковалевское;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323555402;3;1
5cb61833-749b-11df-b112-00215aee3ebe;Ковалівщина;Ковалевщина;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822384605;3;1
5cb61834-749b-11df-b112-00215aee3ebe;Ковальове;Ковалево;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420387503;3;1
5cb61835-749b-11df-b112-00215aee3ebe;Ковальчуківка;Ковальчуковка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323582203;3;1
5cb61836-749b-11df-b112-00215aee3ebe;Кованка;Кованка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287703;3;1
5cb61837-749b-11df-b112-00215aee3ebe;Кованчик;Кованчик;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083204;3;1
5cb61838-749b-11df-b112-00215aee3ebe;Кованьківка;Кованьковка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083506;3;1
5cb61839-749b-11df-b112-00215aee3ebe;Ковбань;Ковбань;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720884802;3;1
5cb6183b-749b-11df-b112-00215aee3ebe;Ковбасова Поляна;Ковбасова Поляна;село;6b07f835-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5124355102;3;1
5cb6183c-749b-11df-b112-00215aee3ebe;Ковбащина;Колбащина;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386004;3;1
17af7b46-ddb9-11df-9197-00215aee3ebe;Ковганівка;Ковгановка;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820982503;3;1
5cb6183d-749b-11df-b112-00215aee3ebe;Ковель;Ковель;місто;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0710400000;2;0
5cb6183e-749b-11df-b112-00215aee3ebe;Ковердина Балка;Ковердина Балка;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755126;3;1
5cb6183f-749b-11df-b112-00215aee3ebe;Коверівка;Коверовка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321280504;3;1
5cb61840-749b-11df-b112-00215aee3ebe;Ковжижа;Ковжижа;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322283005;3;1
5cb61845-749b-11df-b112-00215aee3ebe;Ковиничі;Ковиничи;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624284604;3;1
5cb61847-749b-11df-b112-00215aee3ebe;Ковпаківка;Колпаковка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222384007;3;1
5cb61849-749b-11df-b112-00215aee3ebe;Ковпин Ставок;Ковпин Ставок;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620385804;3;1
5cb6184a-749b-11df-b112-00215aee3ebe;Ковпинка;Ковпинка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423684701;3;1
5cb6184b-749b-11df-b112-00215aee3ebe;Ковпита;Ковпыта;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425583701;3;1
5cb6184c-749b-11df-b112-00215aee3ebe;Коврай;Коврай;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121585501;3;1
5cb6184d-749b-11df-b112-00215aee3ebe;Коврай;Коврай;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125188002;3;1
5cb6184e-749b-11df-b112-00215aee3ebe;Коврай Другий;Коврай Второй;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121585601;3;1
5cb6184f-749b-11df-b112-00215aee3ebe;Коврайські Хутори;Коврайские Хутора;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121588202;3;1
5cb61852-749b-11df-b112-00215aee3ebe;Ковтуни;Ковтуны;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
5cb61853-749b-11df-b112-00215aee3ebe;Ковтуни;Ковтуны;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121585801;3;1
5cb61854-749b-11df-b112-00215aee3ebe;Ковтуни;Ковтуны;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324887709;3;1
5cb61855-749b-11df-b112-00215aee3ebe;Ковтунівка;Ковтуновка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423382803;3;1
5cb61856-749b-11df-b112-00215aee3ebe;Ковтунівка;Ковтуновка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421785204;3;1
5cb61857-749b-11df-b112-00215aee3ebe;Ковтунівка;Ковтуновка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424184150;3;1
5cb61858-749b-11df-b112-00215aee3ebe;Ковтунівка;Ковтуновка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121585103;3;1
5cb61859-749b-11df-b112-00215aee3ebe;Ковтунівка;Ковтуновка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120687603;3;1
5cb6185a-749b-11df-b112-00215aee3ebe;Ковтунове;Ковтуново;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925383501;3;1
5cb6185b-749b-11df-b112-00215aee3ebe;Ковчин;Ковчин;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422785501;3;1
5cb6185e-749b-11df-b112-00215aee3ebe;Когильне;Когильное;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720582009;3;1
5cb6185f-749b-11df-b112-00215aee3ebe;Когути;Когуты;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625810503;3;1
5cb61860-749b-11df-b112-00215aee3ebe;Кодак;Кодак;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225286604;3;1
5cb61861-749b-11df-b112-00215aee3ebe;Кодаки;Кодаки;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221483801;3;1
5cb61862-749b-11df-b112-00215aee3ebe;Кодацьке;Кодакское;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224881005;3;1
5cb61864-749b-11df-b112-00215aee3ebe;Кодима;Кодыма;місто;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122510100;2;0
5cb61865-749b-11df-b112-00215aee3ebe;Кодня;Кодня;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822083501;3;1
5cb61866-749b-11df-b112-00215aee3ebe;Кодра;Кодра;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222755400;3;1
5cb61867-749b-11df-b112-00215aee3ebe;Кодра-Торф;Кодра-Торф;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;;3;1
5cb61868-749b-11df-b112-00215aee3ebe;Кожанка;Кожанка;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224955600;3;1
5cb61869-749b-11df-b112-00215aee3ebe;Кожанка;Кожанка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523182001;3;1
5cb6186b-749b-11df-b112-00215aee3ebe;Коженики;Коженики;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220482201;2;1
5cb6186c-749b-11df-b112-00215aee3ebe;Кожичі;Кожичи;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625883604;3;1
5cb6186e-749b-11df-b112-00215aee3ebe;Кожухів;Кожухов;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522483401;3;1
5cb6186f-749b-11df-b112-00215aee3ebe;Кожухівка;Кожуховка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221482003;3;1
5cb61870-749b-11df-b112-00215aee3ebe;Кожухівка;Кожуховка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822382401;3;1
5cb61871-749b-11df-b112-00215aee3ebe;Кожухівка;Кожуховка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523786203;3;1
5cb61872-749b-11df-b112-00215aee3ebe;Кожушки;Кожушки;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824082402;3;1
5cb61874-749b-11df-b112-00215aee3ebe;Козак;Казак;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510117;3;1
5cb61875-749b-11df-b112-00215aee3ebe;Козак;Казак;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623086303;3;1
5cb61876-749b-11df-b112-00215aee3ebe;Козаки;Казаки;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622185909;3;1
5cb61877-749b-11df-b112-00215aee3ebe;Козаки;Казаки;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621887604;3;1
5cb61878-749b-11df-b112-00215aee3ebe;Козаківка;Казаковка;село;8067acf2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2610291001;3;1
5cb6187a-749b-11df-b112-00215aee3ebe;Козаківка;Казаковка;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523010106;3;1
5cb6187b-749b-11df-b112-00215aee3ebe;Козаківське;Казаковское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321585603;3;1
5cb6187c-749b-11df-b112-00215aee3ebe;Козакова Балка;Казакова Балка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524083003;3;1
5cb6187d-749b-11df-b112-00215aee3ebe;Козакове;Казаково;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121683904;3;1
5cb6187e-749b-11df-b112-00215aee3ebe;Козари;Козары;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423882001;3;1
5cb61880-749b-11df-b112-00215aee3ebe;Козарі;Козари;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624455307;3;1
5cb61881-749b-11df-b112-00215aee3ebe;Козарівка;Козаровка;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122082701;3;1
5cb61882-749b-11df-b112-00215aee3ebe;Козарівка;Козаровка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520283503;3;1
5cb61883-749b-11df-b112-00215aee3ebe;Козаровичі;Козаровичи;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221883601;3;1
5cb61884-749b-11df-b112-00215aee3ebe;Козацьке;Казацкое;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922083801;3;1
5cb61885-749b-11df-b112-00215aee3ebe;Козацьке;Казацкое;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120682450;3;1
5cb61886-749b-11df-b112-00215aee3ebe;Козацьке;Казацкое;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120886803;3;1
5cb61887-749b-11df-b112-00215aee3ebe;Козацьке;Казацкое;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520655400;3;1
5cb61889-749b-11df-b112-00215aee3ebe;Козацьке;Казацкое;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420683601;3;1
5cb6188a-749b-11df-b112-00215aee3ebe;Козацьке;Казацкое;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121282801;3;1
5cb6188b-749b-11df-b112-00215aee3ebe;Козацьке;Казацкое;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523283009;3;1
74dc499b-749b-11df-b112-00215aee3ebe;Козацьке (Петрівське);Казацкое (Петровское);село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524984801;3;1
5cb6188c-749b-11df-b112-00215aee3ebe;Козача Лопань;Казачья Лопань;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322055600;3;1
5cb6188d-749b-11df-b112-00215aee3ebe;Козачани;Казачаны;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125781603;3;1
5cb6188e-749b-11df-b112-00215aee3ebe;Козаче;Казачье;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325483705;3;1
5cb6188f-749b-11df-b112-00215aee3ebe;Козаче;Казачье;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923883401;3;1
5cb61890-749b-11df-b112-00215aee3ebe;Козаче;Казачье;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120283902;3;1
5cb61891-749b-11df-b112-00215aee3ebe;Козаче;Казачье;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321583002;3;1
5cb61892-749b-11df-b112-00215aee3ebe;Козаче;Казачье;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120686504;3;1
5cb61893-749b-11df-b112-00215aee3ebe;Козаче;Казачье;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124684004;3;1
5cb61894-749b-11df-b112-00215aee3ebe;Козаче;Казачье;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323680402;3;1
e97d509b-8350-11e8-80d4-1c98ec135261;Козаче;Козаче;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222082006;3;1
5cb61895-749b-11df-b112-00215aee3ebe;Козаченківка;Козаченковка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321283006;3;1
5cb61897-749b-11df-b112-00215aee3ebe;Козачий Гай;Казачий Гай;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224883503;3;1
5cb61898-749b-11df-b112-00215aee3ebe;Козачий Яр;Казачий Яр;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123383803;3;1
5cb6189b-749b-11df-b112-00215aee3ebe;Козачі Майдани;Козачьи Майданы;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323186005;3;1
5cb6189d-749b-11df-b112-00215aee3ebe;Козачки;Казачки;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123883303;3;1
5cb6189e-749b-11df-b112-00215aee3ebe;Козачки;Казачки;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823082901;3;1
5cb6189f-749b-11df-b112-00215aee3ebe;Козаччина;Козаччина;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120884503;3;1
5cb618a0-749b-11df-b112-00215aee3ebe;Козелець;Козелец;місто;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422055100;2;1
5cb618a1-749b-11df-b112-00215aee3ebe;Козельне;Козельное;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923582901;3;1
5cb618a2-749b-11df-b112-00215aee3ebe;Козельщина;Козельщина;місто;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322055100;3;1
5cb618a3-749b-11df-b112-00215aee3ebe;Козельщина;Козельщина;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323084403;3;1
5cb618a4-749b-11df-b112-00215aee3ebe;Козероги;Козероги;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425588502;3;1
5cb618a5-749b-11df-b112-00215aee3ebe;Козилівка;Козиловка;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422484501;3;1
5cb618a6-749b-11df-b112-00215aee3ebe;Козилівщина;Козиловщина;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;;3;1
2afaaa43-a69a-11e2-bb38-003048d2b473;Козилівщина;Козиловщина;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424787503;3;1
5cb618a7-749b-11df-b112-00215aee3ebe;Козин;Козин;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724585603;3;1
5cb618a8-749b-11df-b112-00215aee3ebe;Козин;Козин;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223155400;2;0
5cb618a9-749b-11df-b112-00215aee3ebe;Козин;Козин;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222983301;3;1
5cb618ab-749b-11df-b112-00215aee3ebe;Козин;Козин;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820387003;3;1
5cb618ac-749b-11df-b112-00215aee3ebe;Козин;Козин;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625883901;3;1
5cb618ad-749b-11df-b112-00215aee3ebe;Козина;Козина;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121683302;3;1
5cb618ae-749b-11df-b112-00215aee3ebe;Козина;Козина;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2625881801;3;1
5cb618af-749b-11df-b112-00215aee3ebe;Козинці;Козинцы;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221083501;3;1
5cb618b0-749b-11df-b112-00215aee3ebe;Козинці;Козинцы;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522282601;3;1
5cb618b1-749b-11df-b112-00215aee3ebe;Козинці;Козинцы;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524180803;3;1
5cb618b2-749b-11df-b112-00215aee3ebe;Козирів;Козырев;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324881502;3;1
5cb618b3-749b-11df-b112-00215aee3ebe;Козирівка;Козыревка;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523486902;3;1
5cb618b4-749b-11df-b112-00215aee3ebe;Козирка;Козырка;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825181601;3;1
5cb618b5-749b-11df-b112-00215aee3ebe;Козирщина;Козырщина;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623880502;3;1
5cb618b6-749b-11df-b112-00215aee3ebe;Козиряни;Козыряны;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322084801;3;1
5cb618b7-749b-11df-b112-00215aee3ebe;Козичанка;Козичанка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222782401;3;1
5cb618b8-749b-11df-b112-00215aee3ebe;Козівка;Козовка;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123084620;3;1
5cb618b9-749b-11df-b112-00215aee3ebe;Козівка;Козовка;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125284101;3;1
5cb618ba-749b-11df-b112-00215aee3ebe;Козіївка;Козиевка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323583201;3;1
5cb618bb-749b-11df-b112-00215aee3ebe;Козіївка;Козиевка;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223185102;3;1
5cb618bc-749b-11df-b112-00215aee3ebe;Козіївка;Козиевка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822583301;3;1
5cb618bd-749b-11df-b112-00215aee3ebe;Козлин;Козлин;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624687004;3;1
5cb618be-749b-11df-b112-00215aee3ebe;Козлиничі;Козлиничи;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723687602;3;1
5cb618bf-749b-11df-b112-00215aee3ebe;Козлиничі;Козлиничи;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722187406;3;1
5cb618c0-749b-11df-b112-00215aee3ebe;Козлів;Козлов;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722483401;3;1
5cb618c1-749b-11df-b112-00215aee3ebe;Козлів;Козлов;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123055400;3;1
5cb618c2-749b-11df-b112-00215aee3ebe;Козлів;Козлов;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223384001;3;1
5cb618c3-749b-11df-b112-00215aee3ebe;Козлів;Козлов;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522683201;3;1
5cb618c4-749b-11df-b112-00215aee3ebe;Козлівка;Козловка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923888005;3;1
5cb618c5-749b-11df-b112-00215aee3ebe;Козлівка;Козловка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655110;3;1
5cb618c7-749b-11df-b112-00215aee3ebe;Козлівка;Козловка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525384201;3;1
5cb618c8-749b-11df-b112-00215aee3ebe;Козлівка;Козловка;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523283402;3;1
5cb618c9-749b-11df-b112-00215aee3ebe;Козлівське;Козловское;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525880203;3;1
5cb618ca-749b-11df-b112-00215aee3ebe;Козлівщина;Козловщина;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322281901;3;1
5cb618cb-749b-11df-b112-00215aee3ebe;Козлове;Козлово;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121882602;3;1
5cb618cd-749b-11df-b112-00215aee3ebe;Козляничі;Козляничи;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424984001;3;1
5cb618ce-749b-11df-b112-00215aee3ebe;Козова;Козова;місто;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123055100;3;0
5cb618cf-749b-11df-b112-00215aee3ebe;Козовата;Козовата;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0724286906;3;1
5cb618d0-749b-11df-b112-00215aee3ebe;Козодуб;Козодуб;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;;3;1
17af7b23-ddb9-11df-9197-00215aee3ebe;Козодуб;Козодуб;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222086502;3;1
5cb618d2-749b-11df-b112-00215aee3ebe;Козуби;Козубы;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323480403;3;1
5cb618d3-749b-11df-b112-00215aee3ebe;Козубівка;Козубовка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324880307;3;1
5cb618d4-749b-11df-b112-00215aee3ebe;Козубівка;Козубовка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822782001;3;1
5cb618d5-749b-11df-b112-00215aee3ebe;Козулі;Козули;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824284303;3;1
5cb618d6-749b-11df-b112-00215aee3ebe;Козулька;Козулька;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784402;3;1
b36f1a3c-db87-11df-9197-00215aee3ebe;Козютівка;Козютовка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322880503;3;1
5cb618d7-749b-11df-b112-00215aee3ebe;Козярі;Козяры;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124685007;3;1
5cb618d8-749b-11df-b112-00215aee3ebe;Козятин;Казатин;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5910290005;3;1
5cb618d9-749b-11df-b112-00215aee3ebe;Козятин;Казатин;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720883006;3;1
5cb618da-749b-11df-b112-00215aee3ebe;Козятин;Казатин;місто;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0510500000;2;0
5cb618db-749b-11df-b112-00215aee3ebe;Козятин село;Козятин Село;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521482803;3;1
5cb618dc-749b-11df-b112-00215aee3ebe;Козьова;Козева;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624583001;3;1
5cb618dd-749b-11df-b112-00215aee3ebe;Кокозівка;Кокозовка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321085804;3;1
5cb618de-749b-11df-b112-00215aee3ebe;Коколове;Коколово;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521783003;3;1
5cb618df-749b-11df-b112-00215aee3ebe;Кокошинці;Кокошинцы;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121684502;3;1
5cb618e1-749b-11df-b112-00215aee3ebe;Кокутківці;Кокутковцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122681603;3;1
5cb618e4-749b-11df-b112-00215aee3ebe;Колбаєвичі;Колбаевичи;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624288902;3;1
5cb618e7-749b-11df-b112-00215aee3ebe;Коленці;Коленцы;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222080302;3;1
5cb618e8-749b-11df-b112-00215aee3ebe;Коленцівське;Коленцовское;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222080303;3;1
5cb618ea-749b-11df-b112-00215aee3ebe;Колесники;Колесники;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621281205;3;1
5cb618ed-749b-11df-b112-00215aee3ebe;Колибабинці;Колыбабинцы;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524882903;3;1
5cb618ee-749b-11df-b112-00215aee3ebe;Колибаївка;Колыбаевка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822484201;3;1
5cb618ef-749b-11df-b112-00215aee3ebe;Колибань;Колыбань;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825083304;3;1
5cb618f0-749b-11df-b112-00215aee3ebe;Колиндяни;Колындяны;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125584501;3;1
5cb618f1-749b-11df-b112-00215aee3ebe;Количівка;Колычовка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425582802;3;1
5cb618f2-749b-11df-b112-00215aee3ebe;Колінки;Коленки;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621680805;3;1
5cb618f3-749b-11df-b112-00215aee3ebe;Колінківці;Коленковцы;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325084001;3;1
5cb618f4-749b-11df-b112-00215aee3ebe;Колінці;Коленцы;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625610106;3;1
5cb618f5-749b-11df-b112-00215aee3ebe;Коліньки;Коленьки;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281506;3;1
5cb618f6-749b-11df-b112-00215aee3ebe;Колісець;Колесец;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824782503;3;1
5cb618f7-749b-11df-b112-00215aee3ebe;Колісники;Колесники;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5321883803;3;1
5cb618f8-749b-11df-b112-00215aee3ebe;Колісники;Колесники;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923282605;3;1
5cb618f9-749b-11df-b112-00215aee3ebe;Колісники;Колесники;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321786203;3;1
5cb618fa-749b-11df-b112-00215aee3ebe;Колісники;Колесники;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423384801;3;1
5cb618fb-749b-11df-b112-00215aee3ebe;Колісники;Колесники;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424184201;3;1
5cb618fc-749b-11df-b112-00215aee3ebe;Колісниківка;Колесниковка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321884405;3;1
5cb618fd-749b-11df-b112-00215aee3ebe;Колісниківка;Колесниковка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325785803;3;1
5cb618ff-749b-11df-b112-00215aee3ebe;Колісникове;Колесниково;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5910790003;3;1
5cb618ec-749b-11df-b112-00215aee3ebe;Колісникове;Колесниково;село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223783502;3;1
5cb61900-749b-11df-b112-00215aee3ebe;Колки;Колки;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723655400;3;0
5cb61901-749b-11df-b112-00215aee3ebe;Колки;Колки;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824785302;3;1
5cb61902-749b-11df-b112-00215aee3ebe;Колки;Колки;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621883401;3;1
5cb61903-749b-11df-b112-00215aee3ebe;Колмів;Колмов;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720883203;3;1
5cb61904-749b-11df-b112-00215aee3ebe;Коло - Михайлівка;Коло - Михайловка;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;*052065590;3;1
5cb61905-749b-11df-b112-00215aee3ebe;Коловерти;Коловерты;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623083401;3;1
5cb61906-749b-11df-b112-00215aee3ebe;Кологори;Кологоры;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621588503;3;1
5cb61907-749b-11df-b112-00215aee3ebe;Колодеже;Колодеже;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720882801;3;1
5cb61908-749b-11df-b112-00215aee3ebe;Колоденка;Колоденка;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523982001;3;1
5cb61909-749b-11df-b112-00215aee3ebe;Колоденка;Колоденка;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624685903;3;0
5cb6190a-749b-11df-b112-00215aee3ebe;Колоденці;Колоденцы;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622183304;3;1
5cb6190b-749b-11df-b112-00215aee3ebe;Колодисте;Колодистое;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124383701;3;1
5cb6190c-749b-11df-b112-00215aee3ebe;Колодисте;Колодистое;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124084001;3;1
5cb6190d-749b-11df-b112-00215aee3ebe;Колодії;Колодии;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723683403;3;1
5cb6190e-749b-11df-b112-00215aee3ebe;Колодіїв;Колодиев;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621282602;3;1
5cb6190f-749b-11df-b112-00215aee3ebe;Колодіївка;Колодиевка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124683601;3;1
5cb61910-749b-11df-b112-00215aee3ebe;Колодіївка;Колодиевка;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625881901;3;1
5cb61911-749b-11df-b112-00215aee3ebe;Колодіївка;Колодиевка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825486202;3;1
5cb61912-749b-11df-b112-00215aee3ebe;Колодіївка;Колодиевка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822484101;3;1
5cb61913-749b-11df-b112-00215aee3ebe;Колодіївка;Колодиевка;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623081007;3;1
5cb61914-749b-11df-b112-00215aee3ebe;Колодка;Колодка;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622486404;3;1
5cb61915-749b-11df-b112-00215aee3ebe;Колодківка;Колодковка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321283503;3;1
5cb61916-749b-11df-b112-00215aee3ebe;Колодна;Колодна;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323683604;3;1
5cb61917-749b-11df-b112-00215aee3ebe;Колодне;Колодное;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122484801;3;1
5cb61918-749b-11df-b112-00215aee3ebe;Колодне;Колодное;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124483001;3;1
5cb61919-749b-11df-b112-00215aee3ebe;Колодниця;Колодница;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722180802;3;1
5cb6191a-749b-11df-b112-00215aee3ebe;Колодниця;Колодница;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625380604;3;1
5cb6191b-749b-11df-b112-00215aee3ebe;Колодрібка;Колодробка;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122084901;3;1
5cb6191c-749b-11df-b112-00215aee3ebe;Колодруби;Колодрубы;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623084401;3;1
5cb6191d-749b-11df-b112-00215aee3ebe;Колодяжне;Колодежно;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755127;3;1
5cb6191e-749b-11df-b112-00215aee3ebe;Колодяжне;Колодежно;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321880707;3;1
5cb6191f-749b-11df-b112-00215aee3ebe;Колодяжне;Колодежно;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722183601;3;1
5cb61920-749b-11df-b112-00215aee3ebe;Колодяжне;Колодежно;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821455602;3;1
5cb61925-749b-11df-b112-00215aee3ebe;Колодязне;Колодезное;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620488302;3;1
5cb61926-749b-11df-b112-00215aee3ebe;Колодязьки;Колодезьки;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822587007;3;1
5cb61927-749b-11df-b112-00215aee3ebe;Колодянка;Колодянка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824082401;3;1
5cb61928-749b-11df-b112-00215aee3ebe;Колоколин;Колоколин;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624482901;3;1
5cb6192e-749b-11df-b112-00215aee3ebe;Колом'є;Коломье;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823986802;3;1
5cb61929-749b-11df-b112-00215aee3ebe;Коломак;Коломак;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281407;3;1
5cb6192a-749b-11df-b112-00215aee3ebe;Коломак;Коломак;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086507;3;1
5cb6192b-749b-11df-b112-00215aee3ebe;Коломак;Коломак;місто;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323255100;3;1
78f7eec1-319b-11e7-80fe-1c98ec135263;Коломацьке;Коломацкое;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324082701;3;1
62c3cf52-749b-11df-b112-00215aee3ebe;Коломацьке (Куликове);Коломацкое (Куликово);село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324082701;3;1
5cb6192c-749b-11df-b112-00215aee3ebe;Коломацький Шлях;Коломакский Шлях;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323582204;3;1
5cb6192f-749b-11df-b112-00215aee3ebe;Коломийці;Коломийцы;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983004;3;1
5cb61931-749b-11df-b112-00215aee3ebe;Коломиці;Коломыци;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120685201;3;1
5cb61932-749b-11df-b112-00215aee3ebe;Коломицівка;Коломицевка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610113;3;1
5cb61933-749b-11df-b112-00215aee3ebe;Коломия;Коломыя;місто;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2610600000;1;0
5cb61934-749b-11df-b112-00215aee3ebe;Коломіївка;Коломиевка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825482205;3;1
5cb61935-749b-11df-b112-00215aee3ebe;Коломійцева Долина;Коломейцева Долина;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923284404;3;1
5cb61936-749b-11df-b112-00215aee3ebe;Коломійцеве;Коломийцево;село;4ffc4b7d-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1211090005;2;1
5cb61937-749b-11df-b112-00215aee3ebe;Коломійцеве;Коломийцево;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221884709;3;1
5cb61938-749b-11df-b112-00215aee3ebe;Коломійцеве;Коломийцево;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421788404;3;1
5cb61939-749b-11df-b112-00215aee3ebe;Коломійцеве Озеро;Коломийцево Озеро;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324887711;3;1
5cb6193a-749b-11df-b112-00215aee3ebe;Коломійці;Коломийцы;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224280511;3;1
5cb6193b-749b-11df-b112-00215aee3ebe;Коломійцівка;Коломийцевка;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423881503;3;1
5cb6193c-749b-11df-b112-00215aee3ebe;Колона;Колона;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721183809;3;1
5cb6193d-749b-11df-b112-00215aee3ebe;Колона-Межова;Колона-Межевая;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655117;3;1
5cb6193e-749b-11df-b112-00215aee3ebe;Колониці;Колоницы;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625884203;3;1
5cb6193f-749b-11df-b112-00215aee3ebe;Колонія;Колония;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624287604;3;1
5cb61940-749b-11df-b112-00215aee3ebe;Колоно-Миколаївка;Колоно-Николаевка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755125;3;1
5cb61941-749b-11df-b112-00215aee3ebe;Колонтаїв;Колонтаев;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323583701;3;1
5cb61942-749b-11df-b112-00215aee3ebe;Колонщина;Колонщина;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222782601;3;1
5cb61943-749b-11df-b112-00215aee3ebe;Колос;Колос;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321580504;3;1
5cb61944-749b-11df-b112-00215aee3ebe;Колос;Колос;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423683206;3;1
5cb61945-749b-11df-b112-00215aee3ebe;Колос Добра;Колос Добра;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820382605;3;1
5cb61946-749b-11df-b112-00215aee3ebe;Колосівка;Колосовка;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281213;3;1
5cb61947-749b-11df-b112-00215aee3ebe;Колосівка;Колосовка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821755112;3;1
5cb61948-749b-11df-b112-00215aee3ebe;Колосівка;Колосовка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824286002;3;1
5cb61949-749b-11df-b112-00215aee3ebe;Колосівка;Колосовка;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823686202;3;1
5cb6194a-749b-11df-b112-00215aee3ebe;Колосівка;Колосовка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520281005;3;1
5cb6194f-749b-11df-b112-00215aee3ebe;Колосова;Колосовая;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123483101;3;1
5cb61950-749b-11df-b112-00215aee3ebe;Колотії;Колотии;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324210104;3;1
5cb61951-749b-11df-b112-00215aee3ebe;Колочава;Колочава;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122482101;3;1
5cb61952-749b-11df-b112-00215aee3ebe;Колпитів;Колпытов;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722483701;3;1
5cb61954-749b-11df-b112-00215aee3ebe;Колтів;Колтов;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621883601;3;1
5cb61955-749b-11df-b112-00215aee3ebe;Колубаївці;Колубаевцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822481803;3;1
5cb61957-749b-11df-b112-00215aee3ebe;Колюхів;Колюхов;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524583501;3;1
5cb61958-749b-11df-b112-00215aee3ebe;Коляди;Коляды;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755128;3;1
5cb61959-749b-11df-b112-00215aee3ebe;Колядин;Колядин;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425380502;3;1
5cb6195a-749b-11df-b112-00215aee3ebe;Колядинець;Колядинец;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923282601;3;1
5cb6195d-749b-11df-b112-00215aee3ebe;Кольченки;Кольченки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321387206;3;1
5cb6195e-749b-11df-b112-00215aee3ebe;Кольчино;Кольчино;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122755300;2;0
5cb61960-749b-11df-b112-00215aee3ebe;Комань;Комань;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423685001;3;1
5cb61963-749b-11df-b112-00215aee3ebe;Комарашеве;Комарашево;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123585002;3;1
5cb61964-749b-11df-b112-00215aee3ebe;Комаргород;Комаргород;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523982201;3;1
5cb61965-749b-11df-b112-00215aee3ebe;Комаргородське;Комаргородское;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523982202;3;1
5cb61966-749b-11df-b112-00215aee3ebe;Комарин;Комарин;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123488202;3;1
5cb61967-749b-11df-b112-00215aee3ebe;Комарицьке;Комарицкое;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920688903;3;1
5cb61968-749b-11df-b112-00215aee3ebe;Комарі;Комари;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
5cb61969-749b-11df-b112-00215aee3ebe;Комарів;Комаров;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725583603;3;1
5cb6196a-749b-11df-b112-00215aee3ebe;Комарів;Комаров;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322085201;3;1
5cb6196b-749b-11df-b112-00215aee3ebe;Комарів;Комаров;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621284201;3;1
5cb6196c-749b-11df-b112-00215aee3ebe;Комарів;Комаров;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624881102;3;1
5cb6196d-749b-11df-b112-00215aee3ebe;Комарів;Комаров;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625385203;3;1
5cb6196e-749b-11df-b112-00215aee3ebe;Комарів;Комаров;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520655301;3;1
5cb6196f-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321887504;3;1
5cb61970-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920386303;3;1
5cb61971-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123481003;3;1
5cb61972-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120489402;3;1
5cb61973-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124285901;3;1
5cb61974-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323586206;3;1
5cb61975-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322885001;3;1
5cb61976-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123382903;3;1
5cb61977-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655114;3;1
5cb61978-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620382401;3;1
5cb6197a-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222782901;3;1
5cb6197b-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821185403;3;1
5cb6197c-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424485603;3;1
5cb6197d-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423084504;3;1
5cb6197e-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420882001;3;1
5cb6197f-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421780802;3;1
5cb61980-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122584401;3;1
5cb61981-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121586203;3;1
5cb61982-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523783001;3;1
5cb61983-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823986803;3;1
5cb61984-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623483402;3;1
5cb61985-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621680405;3;1
5cb61962-749b-11df-b112-00215aee3ebe;Комарівка;Комаровка;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;;3;1
73e94c17-ddc1-11df-9197-00215aee3ebe;Комарівка;Комаровка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;;3;1
5cb61986-749b-11df-b112-00215aee3ebe;Комарівці;Комаровцы;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324585001;3;1
5cb61987-749b-11df-b112-00215aee3ebe;Комарівці;Комаровцы;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520282203;3;1
5cb61988-749b-11df-b112-00215aee3ebe;Комарники;Комарники;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625583901;3;1
5cb61989-749b-11df-b112-00215aee3ebe;Комарно;Комарно;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620910400;3;1
5cb6198a-749b-11df-b112-00215aee3ebe;Комарове;Комарово;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724286003;3;1
5cb6198b-749b-11df-b112-00215aee3ebe;Комарове;Комарово;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725085502;3;1
5cb6198c-749b-11df-b112-00215aee3ebe;Комарове;Комарово;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723683001;3;1
5cb6198d-749b-11df-b112-00215aee3ebe;Комаровичі;Комаровичи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625183905;3;1
5cb6198e-749b-11df-b112-00215aee3ebe;Комендантівка;Комендантовка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5321883801;3;1
5cb61990-749b-11df-b112-00215aee3ebe;Комини;Комины;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822181003;3;1
5cb61993-749b-11df-b112-00215aee3ebe;Комишанка;Комышанка;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923581702;3;1
5cb61996-749b-11df-b112-00215aee3ebe;Комишеве;Комышево;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523655102;3;1
5cb61999-749b-11df-b112-00215aee3ebe;Комиші;Камыши;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920383601;3;1
5cb6199a-749b-11df-b112-00215aee3ebe;Комишівка;Камышевка;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122082001;3;1
5cb6199c-749b-11df-b112-00215aee3ebe;Комишівка Перша;Камишевка Первая;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124586802;3;1
5cb6199f-749b-11df-b112-00215aee3ebe;Комишня;Камышня;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323255400;3;1
5cb619a1-749b-11df-b112-00215aee3ebe;Комишувате;Камышеватое;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524082401;3;1
5cb619a3-749b-11df-b112-00215aee3ebe;Комишуваха;Камышеваха;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955400;3;1
5cb619ab-749b-11df-b112-00215aee3ebe;Комінтерн (Козирщина);Коминтерн (Козирщина);село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223210501;3;1
5cb619b2-749b-11df-b112-00215aee3ebe;Комісарівка;Комиссаровка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224582001;3;1
5cb619b3-749b-11df-b112-00215aee3ebe;Комісарівка;Комиссаровка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820981206;3;1
5cb619b9-749b-11df-b112-00215aee3ebe;Комори;Коморы;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622283602;3;1
5cb619ba-749b-11df-b112-00215aee3ebe;Компаніївка;Компаниевка;місто;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522855100;3;0
5cb619bc-749b-11df-b112-00215aee3ebe;Комсомолець;Комсомолец;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323586207;3;1
5cb619c2-749b-11df-b112-00215aee3ebe;Комсомольська Правда;Комсомольская Правда;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
5cb619c4-749b-11df-b112-00215aee3ebe;Комсомольське;Комсомольское;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225980203;3;1
5cb619c5-749b-11df-b112-00215aee3ebe;Комсомольське;Комсомольское;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;*122458120;3;1
5cb619d7-749b-11df-b112-00215aee3ebe;Комсомольське;Комсомольское;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523110101;3;1
5cb619c7-749b-11df-b112-00215aee3ebe;Комсомольське;Комсомольское;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823383702;3;1
5cb619c9-749b-11df-b112-00215aee3ebe;Комсомольське;Комсомольское;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324284003;3;1
5cb619d5-749b-11df-b112-00215aee3ebe;Комсомольське;Комсомольское;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422484505;3;1
5cb619e1-749b-11df-b112-00215aee3ebe;Комунар;Коммунар;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823383002;3;1
5cb619e4-749b-11df-b112-00215aee3ebe;Комунарівка;Коммунаровка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224281205;3;1
5cb619e8-749b-11df-b112-00215aee3ebe;Комунарка;Коммунарка;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323183703;3;1
5cb619ea-749b-11df-b112-00215aee3ebe;Комунарське;Коммунарское;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323155603;3;1
5cb619ed-749b-11df-b112-00215aee3ebe;Конатківці;Конатковцы;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525384301;3;1
5cb619ef-749b-11df-b112-00215aee3ebe;Конева;Конева;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522683501;2;1
5cb619f0-749b-11df-b112-00215aee3ebe;Коневе;Конево;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522885806;3;1
5cb619f1-749b-11df-b112-00215aee3ebe;Конела;Конела;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120982801;3;1
5cb619f2-749b-11df-b112-00215aee3ebe;Конельська Попівка;Конельская Поповка;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120983001;3;1
5cb619f3-749b-11df-b112-00215aee3ebe;Конельські Хутори;Конельские Хутора;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120983101;3;1
5cb619f4-749b-11df-b112-00215aee3ebe;Коник;Коник;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622285702;3;1
5cb619f5-749b-11df-b112-00215aee3ebe;Конище;Конище;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724286005;3;1
5cb619f6-749b-11df-b112-00215aee3ebe;Конищів;Конищев;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522883001;3;1
5cb619f7-749b-11df-b112-00215aee3ebe;Конів;Конев;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625183001;3;1
5cb619f8-749b-11df-b112-00215aee3ebe;Коновали;Коновалы;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924189102;3;1
5cb619f9-749b-11df-b112-00215aee3ebe;Коновалик;Коновалик;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920355503;3;1
5cb619fa-749b-11df-b112-00215aee3ebe;Коновалівка;Коноваловка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323081901;3;1
5cb619fc-749b-11df-b112-00215aee3ebe;Коновалове;Коновалово;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920982402;3;1
5cb619fe-749b-11df-b112-00215aee3ebe;Коновалове;Коновалово;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320487505;3;1
5cb619ff-749b-11df-b112-00215aee3ebe;Коновиця;Коновица;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125880902;3;1
5cb61a00-749b-11df-b112-00215aee3ebe;Коновка;Коновка;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322085401;3;1
5cb61a01-749b-11df-b112-00215aee3ebe;Кононенки;Кононенки;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321082605;3;1
5cb61a02-749b-11df-b112-00215aee3ebe;Кононенкове;Кононенково;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187304;3;1
5cb61a03-749b-11df-b112-00215aee3ebe;Кононенкове;Кононенково;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924783803;3;1
5cb61a05-749b-11df-b112-00215aee3ebe;Кононівка;Кононовка;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224010101;3;1
5cb61a06-749b-11df-b112-00215aee3ebe;Кононівка;Кононовка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120685502;3;1
5cb61a07-749b-11df-b112-00215aee3ebe;Кононівка;Кононовка;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322881402;3;1
5cb61a08-749b-11df-b112-00215aee3ebe;Кононове-івасів;Кононово-Ивасев;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121289203;3;1
5cb61a09-749b-11df-b112-00215aee3ebe;Кононча;Кононча;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122083101;3;1
5cb61a0a-749b-11df-b112-00215aee3ebe;Конопелька;Конопелька;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721886609;3;1
5cb61a0b-749b-11df-b112-00215aee3ebe;Конопельки;Конопельки;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222787702;3;1
5cb61a0c-749b-11df-b112-00215aee3ebe;Конопківка;Конопковка;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125055402;3;1
5cb61a0d-749b-11df-b112-00215aee3ebe;Коноплівці;Конопливци;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122755303;3;0
73e94bf4-ddc1-11df-9197-00215aee3ebe;Коноплівці (Кендерешів);Конопливци (Кендерешов);село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;;3;1
5cb61a0e-749b-11df-b112-00215aee3ebe;Конопляне;Конопляное;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121882001;3;1
5cb61a10-749b-11df-b112-00215aee3ebe;Коноплянка;Коноплянка;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281208;3;1
5cb61a11-749b-11df-b112-00215aee3ebe;Конопниця;Конопница;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623683401;2;0
5cb61a12-749b-11df-b112-00215aee3ebe;Конотоп;Конотоп;місто;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5910400000;1;0
5cb61a13-749b-11df-b112-00215aee3ebe;Конотоп;Конотоп;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421485201;3;1
5cb61a14-749b-11df-b112-00215aee3ebe;Конотоп;Конотоп;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825584602;3;1
5cb61a15-749b-11df-b112-00215aee3ebe;Конотопи;Конотопы;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624884605;3;1
5cb61a16-749b-11df-b112-00215aee3ebe;Коношівка;Коношовка;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420686802;3;1
5cb61a17-749b-11df-b112-00215aee3ebe;Констанція;Констанция;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120885502;3;1
5cb61a19-749b-11df-b112-00215aee3ebe;Концеба;Концеба;село;6b07f835-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5124382001;3;1
5cb61a1a-749b-11df-b112-00215aee3ebe;Концово;Концово;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2124887402;3;1
5cb61a1c-749b-11df-b112-00215aee3ebe;Конча-Заспа;Конча-Заспа;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;;3;0
5cb61a1b-749b-11df-b112-00215aee3ebe;Кончаки;Кончаки;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224985703;3;1
5cb61a1d-749b-11df-b112-00215aee3ebe;Конюхи;Конюхи;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722483901;3;1
5cb61a1e-749b-11df-b112-00215aee3ebe;Конюхи;Конюхи;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123084801;3;1
5cb61a1f-749b-11df-b112-00215aee3ebe;Конюхів;Конюхов;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625380605;3;1
5cb61a20-749b-11df-b112-00215aee3ebe;Конюша;Конюша;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223187704;3;1
5cb61a21-749b-11df-b112-00215aee3ebe;Конюшеве;Конюшево;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323255701;3;1
5cb61a22-749b-11df-b112-00215aee3ebe;Конюшівка;Конюшевка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522280402;3;1
5cb61a23-749b-11df-b112-00215aee3ebe;Конюшки;Конюшки;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624483101;3;1
5cb61a24-749b-11df-b112-00215aee3ebe;Конюшки;Конюшки;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484903;3;1
5cb61a25-749b-11df-b112-00215aee3ebe;Конюшки-Королівські;Конюшки-Королевские;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624286602;3;1
5cb61a26-749b-11df-b112-00215aee3ebe;Конюшки-Тулиголівські;Конюшки-Тулиголовские;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624286603;3;1
5cb61a27-749b-11df-b112-00215aee3ebe;Конюшків;Конюшков;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620388602;3;1
5cb61a28-749b-11df-b112-00215aee3ebe;Конятин;Конятин;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323582001;3;1
5cb61a29-749b-11df-b112-00215aee3ebe;Конятин;Конятин;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424984501;3;1
5cb61a2b-749b-11df-b112-00215aee3ebe;Копай;Копай;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520255303;3;1
5cb61a2c-749b-11df-b112-00215aee3ebe;Копайгород;Копайгород;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520255300;3;1
5cb61a2d-749b-11df-b112-00215aee3ebe;Копани;Копаны;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621684705;3;1
5cb61a2e-749b-11df-b112-00215aee3ebe;Копані;Копани;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220786612;3;1
5cb61a2f-749b-11df-b112-00215aee3ebe;Копані;Копани;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823684503;3;1
5cb61a30-749b-11df-b112-00215aee3ebe;Копані;Копани;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822755105;3;1
5cb61a31-749b-11df-b112-00215aee3ebe;Копані;Копани;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323983002;3;1
5cb61a33-749b-11df-b112-00215aee3ebe;Копані;Копани;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323910101;3;1
5cb61a34-749b-11df-b112-00215aee3ebe;Копані;Копани;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520385003;3;1
5cb61a35-749b-11df-b112-00215aee3ebe;Копані;Копани;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621882804;3;1
5cb61a38-749b-11df-b112-00215aee3ebe;Копані;Копани;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522287404;3;1
5cb61a39-749b-11df-b112-00215aee3ebe;Копані;Копани;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625886907;3;1
5cb61a3a-749b-11df-b112-00215aee3ebe;Копанівка;Копановка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821185404;3;1
5cb61a3b-749b-11df-b112-00215aee3ebe;Копанка;Копанка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320256002;3;1
5cb61a3c-749b-11df-b112-00215aee3ebe;Копанка;Копанка;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785903;3;1
5cb61a3d-749b-11df-b112-00215aee3ebe;Копанки;Копанки;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323380503;3;1
5cb61a3e-749b-11df-b112-00215aee3ebe;Копанки;Копанки;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321082505;3;1
5cb61a3f-749b-11df-b112-00215aee3ebe;Копанки;Копанки;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322887004;3;1
5cb61a40-749b-11df-b112-00215aee3ebe;Копанки;Копанки;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622883601;3;1
5cb61a41-749b-11df-b112-00215aee3ebe;Копанки;Копанки;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523182402;3;1
5cb61a42-749b-11df-b112-00215aee3ebe;Копань;Копань;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623386405;3;1
5cb61a43-749b-11df-b112-00215aee3ebe;Копань;Копань;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624281605;3;1
5cb61a44-749b-11df-b112-00215aee3ebe;Копань;Копань;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621482506;3;1
5cb61a45-749b-11df-b112-00215aee3ebe;Копачинці;Копачинцы;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621681301;3;1
5cb61a46-749b-11df-b112-00215aee3ebe;Копачів;Копачов;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223184201;3;1
5cb61a47-749b-11df-b112-00215aee3ebe;Копачів;Копачов;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422085902;3;1
5cb61a48-749b-11df-b112-00215aee3ebe;Копачівка;Копачевка;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724582901;3;1
5cb61a49-749b-11df-b112-00215aee3ebe;Копачівка;Копачевка;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620481603;3;1
5cb61a4a-749b-11df-b112-00215aee3ebe;Копачівка;Копачевка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820983501;3;1
5cb61a4b-749b-11df-b112-00215aee3ebe;Копачівка;Копачевка;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821584801;3;1
5cb61a4c-749b-11df-b112-00215aee3ebe;Копачівка Друга;Копачовка Вторая;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820985303;3;1
5cb61a4d-749b-11df-b112-00215aee3ebe;Копашново;Копашново;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125384001;3;1
5cb61a4e-749b-11df-b112-00215aee3ebe;Копелянка;Копелянка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821185405;3;1
5cb61a4f-749b-11df-b112-00215aee3ebe;Копенкувате;Копенковатое;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523682201;3;1
5cb61a50-749b-11df-b112-00215aee3ebe;Копи;Копы;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822781202;3;1
5cb61a51-749b-11df-b112-00215aee3ebe;Копили;Копылы;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324085908;3;0
5cb61a52-749b-11df-b112-00215aee3ebe;Копилів;Копылов;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222783201;2;0
5cb61a53-749b-11df-b112-00215aee3ebe;Копилля;Копылье;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723683201;3;1
56bdd229-749b-11df-b112-00215aee3ebe;Копилове (Жовтневе);Копылово (Жовтневое);село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920983203;3;1
5cb61a54-749b-11df-b112-00215aee3ebe;Копинівці;Копыновцы;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122784201;3;1
5cb61a55-749b-11df-b112-00215aee3ebe;Копистин;Копыстин;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825083301;3;0
5cb61a56-749b-11df-b112-00215aee3ebe;Копистирин;Копыстырин;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525384401;3;1
5cb61a57-749b-11df-b112-00215aee3ebe;Копитинці;Копытинцы;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823084203;3;1
5cb61a58-749b-11df-b112-00215aee3ebe;Копитів;Копытов;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624887403;3;1
5cb61a59-749b-11df-b112-00215aee3ebe;Копитів;Копытов;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623086304;3;1
5cb61a5a-749b-11df-b112-00215aee3ebe;Копиткове;Копытково;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622682801;3;1
5cb61a5b-749b-11df-b112-00215aee3ebe;Копичинці;Копычинцы;місто;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121610400;3;0
5cb61a5c-749b-11df-b112-00215aee3ebe;Копище;Копище;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824484801;3;1
5cb61a5d-749b-11df-b112-00215aee3ebe;Копище;Копище;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625485802;3;1
5cb61a5e-749b-11df-b112-00215aee3ebe;Копіївка;Копиевка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222788603;3;1
5cb61a5f-749b-11df-b112-00215aee3ebe;Копіївка;Копиевка;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521255406;3;1
5cb61a60-749b-11df-b112-00215aee3ebe;Копіївка;Копиевка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524383301;3;1
5cb61a61-749b-11df-b112-00215aee3ebe;Копійки;Копейки;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921286302;3;1
5cb61a62-749b-11df-b112-00215aee3ebe;Копійкове;Копейково;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125482204;3;1
5cb61a63-749b-11df-b112-00215aee3ebe;Копійчана;Копейчана;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121882802;3;1
5cb61a64-749b-11df-b112-00215aee3ebe;Копіювата;Копиевата;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122083501;3;1
5cb61a65-749b-11df-b112-00215aee3ebe;Копіювата;Копиевата;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123483001;3;1
5cb61953-749b-11df-b112-00215aee3ebe;Коптевичівка;Коптевичевка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225580902;3;1
5cb61a67-749b-11df-b112-00215aee3ebe;Коптів;Коптев;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323287703;3;1
5cb61a68-749b-11df-b112-00215aee3ebe;Коптівщина;Коптевщина;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824286205;3;1
5cb61a69-749b-11df-b112-00215aee3ebe;Корабельське;Корабельское;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920386902;3;1
5cb61a6a-749b-11df-b112-00215aee3ebe;Кораблище;Кораблище;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623883801;3;1
5cb61a6b-749b-11df-b112-00215aee3ebe;Кораки;Кораки;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287004;3;1
5cb61a6c-749b-11df-b112-00215aee3ebe;Коралівка;Коралевка;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120883701;3;1
5cb61a6d-749b-11df-b112-00215aee3ebe;Корбині івани;Корбины Иваны;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320888307;3;1
5cb61a6e-749b-11df-b112-00215aee3ebe;Корбомиколаївка;Корбониколаевка;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523484704;3;1
5cb61a6f-749b-11df-b112-00215aee3ebe;Корделівка;Корделевка;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521683603;3;1
5cb61a70-749b-11df-b112-00215aee3ebe;Кордишів;Кордышев;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125884401;3;1
5cb61a71-749b-11df-b112-00215aee3ebe;Кордишівка;Кордышевка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521483403;3;1
5cb61a72-749b-11df-b112-00215aee3ebe;Кордишівка;Кордышевка;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520685406;3;1
5cb61a73-749b-11df-b112-00215aee3ebe;Кордон;Кордон;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122782401;3;1
5cb61a74-749b-11df-b112-00215aee3ebe;Кордонівка;Кордоновка;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825283202;3;1
5cb61a75-749b-11df-b112-00215aee3ebe;Кордубанове;Кордубаново;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610101;3;1
5cb61a77-749b-11df-b112-00215aee3ebe;Кореличі;Кореличи;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623383601;3;1
5cb61a78-749b-11df-b112-00215aee3ebe;Кореневе;Коренево;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923581307;3;1
5cb61a79-749b-11df-b112-00215aee3ebe;Коренівка;Кореневка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920981602;3;1
5cb61a7a-749b-11df-b112-00215aee3ebe;Коренівка;Кореневка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824288403;3;1
5cb61a7b-749b-11df-b112-00215aee3ebe;Кореньок;Коренек;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921587902;3;1
5cb61a7c-749b-11df-b112-00215aee3ebe;Корець;Корец;місто;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5623010100;3;0
5cb61a7d-749b-11df-b112-00215aee3ebe;Корещина;Корещина;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320682802;3;1
5cb61a7e-749b-11df-b112-00215aee3ebe;Коржеве;Коржево;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520810106;3;1
5cb61a7f-749b-11df-b112-00215aee3ebe;Коржиха;Коржиха;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321682502;3;1
5cb61a80-749b-11df-b112-00215aee3ebe;Коржиха;Коржиха;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221682802;3;1
5cb61a81-749b-11df-b112-00215aee3ebe;Коржі;Коржи;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924185401;3;1
5cb61a82-749b-11df-b112-00215aee3ebe;Коржі;Коржи;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220282801;3;1
5cb61a83-749b-11df-b112-00215aee3ebe;Коржів;Коржов;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523084203;3;1
5cb61a84-749b-11df-b112-00215aee3ebe;Коржівка;Коржовка;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281907;3;1
5cb61a85-749b-11df-b112-00215aee3ebe;Коржівка;Коржовка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322483804;3;1
5cb61a86-749b-11df-b112-00215aee3ebe;Коржівка;Коржовка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220484102;3;1
5cb61a87-749b-11df-b112-00215aee3ebe;Коржівка;Коржовка;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523084201;3;1
5cb61a88-749b-11df-b112-00215aee3ebe;Коржівка;Коржовка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824284701;3;1
5cb61a89-749b-11df-b112-00215aee3ebe;Коржівці;Корживци;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821585201;3;1
5cb61a8a-749b-11df-b112-00215aee3ebe;Коржківці;Коржковцы;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123882703;3;1
5cb61a8b-749b-11df-b112-00215aee3ebe;Коржова;Коржова;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124284802;3;1
5cb61a8c-749b-11df-b112-00215aee3ebe;Коржова;Коржова;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124383901;3;1
5cb61a8d-749b-11df-b112-00215aee3ebe;Коржова Слобода;Коржова Слобода;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124384101;3;1
5cb61a8e-749b-11df-b112-00215aee3ebe;Коржовий Кут;Коржовый Кут;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124383801;3;1
5cb61a8f-749b-11df-b112-00215aee3ebe;Користова;Корыстова;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820983601;3;1
5cb61a90-749b-11df-b112-00215aee3ebe;Користь;Корысть;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623084201;3;1
5cb61a91-749b-11df-b112-00215aee3ebe;Коритища;Корытища;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824081403;3;1
5cb61a92-749b-11df-b112-00215aee3ebe;Коритище;Корытище;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625584603;3;1
5cb61a93-749b-11df-b112-00215aee3ebe;Коритище;Корытище;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222983401;3;1
5cb61afd-749b-11df-b112-00215aee3ebe;Коритище;Корытище;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821185406;3;1
5cb61a94-749b-11df-b112-00215aee3ebe;Коритна;Корытна;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825884403;3;1
5cb61a95-749b-11df-b112-00215aee3ebe;Коритне;Корытное;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320583501;3;1
5cb61a96-749b-11df-b112-00215aee3ebe;Коритне;Корытное;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290013;3;1
5cb61a97-749b-11df-b112-00215aee3ebe;Коритне;Корытное;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820384701;3;1
5cb61a98-749b-11df-b112-00215aee3ebe;Коритне;Корытное;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625888502;3;1
5cb61a99-749b-11df-b112-00215aee3ebe;Коритниця;Корытница;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720510411;3;1
5cb61a9a-749b-11df-b112-00215aee3ebe;Коритниця;Корытница;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722484602;3;1
5cb61a9b-749b-11df-b112-00215aee3ebe;Коритно-Забузьке;Корытно-Забужское;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524382201;3;1
5cb61a9c-749b-11df-b112-00215aee3ebe;Коритня;Корытня;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123483501;3;1
5cb61a9d-749b-11df-b112-00215aee3ebe;Коритняни;Корытняны;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2124883601;2;1
5cb61a9e-749b-11df-b112-00215aee3ebe;Коритувата;Корытовата;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521486806;3;1
5cb61a9f-749b-11df-b112-00215aee3ebe;Коричинці;Корычынци;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821585601;3;1
5cb61aa0-749b-11df-b112-00215aee3ebe;Корінецьке;Коренецкое;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425381501;3;1
5cb61aa3-749b-11df-b112-00215aee3ebe;Корлюгівка;Корлюговка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522585002;3;1
5cb61aa4-749b-11df-b112-00215aee3ebe;Корлюкове;Корлюково;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321380703;3;1
5cb61aa5-749b-11df-b112-00215aee3ebe;Корма;Корма;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822381802;3;1
5cb61aa6-749b-11df-b112-00215aee3ebe;Кормань;Кормань;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324085001;3;1
5cb61aa7-749b-11df-b112-00215aee3ebe;Кормильча;Кормильча;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825284601;2;1
5cb61aa9-749b-11df-b112-00215aee3ebe;Корналовичі;Корналовичи;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624283101;3;1
5cb61aaa-749b-11df-b112-00215aee3ebe;Корначівка;Корначовка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825885602;3;1
5cb61aab-749b-11df-b112-00215aee3ebe;Корнелівка;Корнелевка;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621585605;3;1
5cb61aac-749b-11df-b112-00215aee3ebe;Корнешти;Корнешты;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325088005;3;1
5cb61aad-749b-11df-b112-00215aee3ebe;Корнилів;Корнилов;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
5cb61aae-749b-11df-b112-00215aee3ebe;Корнило-Наталівка;Корнило-Наталовка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221085505;3;1
5cb61aaf-749b-11df-b112-00215aee3ebe;Корнин;Корнин;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824755400;3;1
5cb61ab0-749b-11df-b112-00215aee3ebe;Корнин;Корнин;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624685901;3;1
a5388eb6-db65-11df-9197-00215aee3ebe;Корниця;Корница;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820383503;3;1
5cb61ab1-749b-11df-b112-00215aee3ebe;Корнич;Корнич;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623282801;3;1
5cb61ab2-749b-11df-b112-00215aee3ebe;Корничі;Корничи;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624283401;3;1
5cb61ab3-749b-11df-b112-00215aee3ebe;Корнів;Корнев;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621681501;3;1
5cb61ab4-749b-11df-b112-00215aee3ebe;Корнієнки;Корниенки;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281801;3;1
5cb61ab5-749b-11df-b112-00215aee3ebe;Корнієнкове;Корниенково;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321282504;3;1
5cb61ab6-749b-11df-b112-00215aee3ebe;Корніїв;Корниев;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422081503;3;1
5cb61ab7-749b-11df-b112-00215aee3ebe;Корніївка;Корнеевка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;;3;1
5cb61aba-749b-11df-b112-00215aee3ebe;Корніївка;Корнеевка;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220282901;3;1
5cb61abb-749b-11df-b112-00215aee3ebe;Корніївка;Корнеевка;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320880802;3;1
5cb61abc-749b-11df-b112-00215aee3ebe;Коробів Хутір;Коробов Хутор;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321782505;3;1
5cb61abd-749b-11df-b112-00215aee3ebe;Коробівка;Коробовка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121586201;3;1
5cb61abe-749b-11df-b112-00215aee3ebe;Коробки;Коробки;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321284009;3;1
5cb61ac0-749b-11df-b112-00215aee3ebe;Коробки;Коробки;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424484207;3;1
5cb61ac2-749b-11df-b112-00215aee3ebe;Коробочкине;Коробочкино;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325484001;3;1
5cb61ac3-749b-11df-b112-00215aee3ebe;Коробчине;Коробчино;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222086503;3;1
5cb61ac4-749b-11df-b112-00215aee3ebe;Коробчине;Коробчино;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423887004;3;1
5cb61ac5-749b-11df-b112-00215aee3ebe;Коробчине;Коробчино;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523883601;3;1
5cb61ac8-749b-11df-b112-00215aee3ebe;Коров' є;Коровье;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824785002;3;1
5cb61ac6-749b-11df-b112-00215aee3ebe;Короваї;Караваи;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320881901;3;1
5cb61ac7-749b-11df-b112-00215aee3ebe;Коровайна;Коровайна;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523083802;3;1
5cb61ac9-749b-11df-b112-00215aee3ebe;Коровинці;Коровинцы;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923583401;3;1
5cb61acb-749b-11df-b112-00215aee3ebe;Коровія;Коровия;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7321082701;3;0
5cb61acc-749b-11df-b112-00215aee3ebe;Королево;Королево;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121255600;3;1
5cb61acd-749b-11df-b112-00215aee3ebe;Короленківка;Короленковка;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321683404;3;1
5cb61ace-749b-11df-b112-00215aee3ebe;Королин;Королин;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622480803;3;1
5cb61acf-749b-11df-b112-00215aee3ebe;Королівка;Королевка;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122082105;3;1
5cb61ad0-749b-11df-b112-00215aee3ebe;Королівка;Королевка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223284003;3;1
5cb61ad1-749b-11df-b112-00215aee3ebe;Королівка;Королевка;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625610107;3;1
5cb61ad2-749b-11df-b112-00215aee3ebe;Королівка;Королевка;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623282701;3;1
5cb61ad3-749b-11df-b112-00215aee3ebe;Королівка;Королевка;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620383203;3;1
5cb61ad4-749b-11df-b112-00215aee3ebe;Королівка;Королевка;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621555301;3;1
5cb61ad6-749b-11df-b112-00215aee3ebe;Королівка;Королевка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222783501;3;1
5cb61ad7-749b-11df-b112-00215aee3ebe;Королівка;Королевка;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224985404;3;1
5cb61ad8-749b-11df-b112-00215aee3ebe;Королівка;Королевка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821786803;3;1
5cb61ad9-749b-11df-b112-00215aee3ebe;Королівка;Королевка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823482205;3;1
5cb61ada-749b-11df-b112-00215aee3ebe;Королівка;Королевка;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824755401;3;1
5cb61adb-749b-11df-b112-00215aee3ebe;Королівка;Королевка;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120983301;3;1
5cb61adc-749b-11df-b112-00215aee3ebe;Королівка;Королевка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521483406;3;1
5cb61add-749b-11df-b112-00215aee3ebe;Королівка;Королевка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522287003;3;1
5cb61ade-749b-11df-b112-00215aee3ebe;Королівка;Королевка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520383504;3;1
5cb61adf-749b-11df-b112-00215aee3ebe;Королівка;Королевка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825883604;3;1
5cb61ae0-749b-11df-b112-00215aee3ebe;Королівщина;Королевщина;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924184702;3;1
5cb61ae1-749b-11df-b112-00215aee3ebe;Корольки;Корольки;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923886702;3;1
5cb61ae3-749b-11df-b112-00215aee3ebe;Корольча;Корольча;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424489202;3;1
5cb61ae4-749b-11df-b112-00215aee3ebe;Короп;Короп;місто;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422255100;3;1
5cb61ae7-749b-11df-b112-00215aee3ebe;Короп'є;Коропье;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422083601;3;1
5cb61ae5-749b-11df-b112-00215aee3ebe;Коропець;Коропец;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124255500;3;1
5cb61ae6-749b-11df-b112-00215aee3ebe;Коропець;Коропец;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621883701;3;1
5cb61ae8-749b-11df-b112-00215aee3ebe;Коропи;Коропы;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325182505;3;1
5cb61ae9-749b-11df-b112-00215aee3ebe;Коропове;Коропово;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321782505;3;1
5cb61aea-749b-11df-b112-00215aee3ebe;Коропуж;Коропуж;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620984501;3;1
5cb61aeb-749b-11df-b112-00215aee3ebe;Коропчик;Коропчик;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621887202;3;1
5cb61aec-749b-11df-b112-00215aee3ebe;Коросне;Коросно;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623383901;3;1
5cb61aed-749b-11df-b112-00215aee3ebe;Коросниця;Коросница;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621284303;3;1
5cb61aee-749b-11df-b112-00215aee3ebe;Корост;Корост;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625482801;3;1
5cb61aef-749b-11df-b112-00215aee3ebe;Коростелівка;Коростелевка;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825681603;3;1
5cb61af0-749b-11df-b112-00215aee3ebe;Коростень;Коростень;місто;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1810700000;2;0
5cb61af1-749b-11df-b112-00215aee3ebe;Коростень;Коростень;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422787002;3;1
5cb61af2-749b-11df-b112-00215aee3ebe;Корости;Коросты;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623687604;3;1
5cb61af3-749b-11df-b112-00215aee3ebe;Коростишів;Коростышев;місто;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510100;2;0
5cb61af4-749b-11df-b112-00215aee3ebe;Коростів;Коростов;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624583301;3;1
5cb61af5-749b-11df-b112-00215aee3ebe;Коростівці;Коростовцы;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521083003;3;1
5cb61af6-749b-11df-b112-00215aee3ebe;Коростки;Коростки;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823183401;3;1
5cb61af7-749b-11df-b112-00215aee3ebe;Коростова;Коростова;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123885002;3;1
5cb61af8-749b-11df-b112-00215aee3ebe;Коростовичі;Коростовичи;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621284501;3;1
5cb61af9-749b-11df-b112-00215aee3ebe;Коростувата;Коростовата;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322582603;3;1
5cb61afa-749b-11df-b112-00215aee3ebe;Коротине;Коротино;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125780403;3;1
5cb61afb-749b-11df-b112-00215aee3ebe;Коротич;Коротыч;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157000;3;1
5cb61afc-749b-11df-b112-00215aee3ebe;Коротище;Корытище;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923586204;3;1
5cb61afe-749b-11df-b112-00215aee3ebe;Коротке;Короткое;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320881004;3;1
5cb61b00-749b-11df-b112-00215aee3ebe;Коротяк;Коротяк;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522882007;3;1
73e94be8-ddc1-11df-9197-00215aee3ebe;Короченки;Короченки;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825888403;3;1
5cb61b01-749b-11df-b112-00215aee3ebe;Корощине;Корощино;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824486203;3;1
5cb61b02-749b-11df-b112-00215aee3ebe;Корпилівка;Корпиловка;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825555301;3;1
5cb61b03-749b-11df-b112-00215aee3ebe;Корсині;Корсыни;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724583203;3;1
5cb61b04-749b-11df-b112-00215aee3ebe;Корсів;Корсов;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620382407;3;1
5cb61b05-749b-11df-b112-00215aee3ebe;Корсунівка;Корсуновка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321282004;3;1
5cb61b06-749b-11df-b112-00215aee3ebe;Корсунівка;Корсуновка;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322683401;3;1
5cb61b08-749b-11df-b112-00215aee3ebe;Корсунка;Корсунка;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124084401;3;1
5cb61b09-749b-11df-b112-00215aee3ebe;Корсунове;Корсуново;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321280303;3;1
5cb61b0a-749b-11df-b112-00215aee3ebe;Корсунці;Корсунцы;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122782603;3;0
5cb61b0c-749b-11df-b112-00215aee3ebe;Корсунь-Шевченківський;Корсунь-Шевченковский;місто;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122510100;2;0
5cb61b0d-749b-11df-b112-00215aee3ebe;Кортеліси;Кортелесы;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724285201;3;1
5cb61b0e-749b-11df-b112-00215aee3ebe;Корчак;Корчак;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822087205;3;1
5cb61b0f-749b-11df-b112-00215aee3ebe;Корчаківка;Корчаковка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924785904;3;1
5cb61b10-749b-11df-b112-00215aee3ebe;Корчани;Корчаны;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983706;3;1
5cb61b11-749b-11df-b112-00215aee3ebe;Корчев'я;Корчевье;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424456401;3;1
5cb61b12-749b-11df-b112-00215aee3ebe;Корчик;Корчик;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825583201;3;1
5cb61b13-749b-11df-b112-00215aee3ebe;Корчин;Корчин;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4623981601;3;1
5cb61b14-749b-11df-b112-00215aee3ebe;Корчин;Корчин;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624583601;3;1
5cb61b15-749b-11df-b112-00215aee3ebe;Корчин;Корчин;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623482602;3;1
5cb61b16-749b-11df-b112-00215aee3ebe;Корчине;Корчино;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824285002;3;1
5cb61b17-749b-11df-b112-00215aee3ebe;Корчів;Корчев;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624882801;3;1
5cb61b21-749b-11df-b112-00215aee3ebe;Корчів'я;Корчевье;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623486905;3;1
5cb61b18-749b-11df-b112-00215aee3ebe;Корчівка;Корчовка;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623981903;3;1
5cb61b19-749b-11df-b112-00215aee3ebe;Корчівка;Корчовка;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621583603;3;1
5cb61b1a-749b-11df-b112-00215aee3ebe;Корчівка;Корчовка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824283002;3;1
5cb61b1b-749b-11df-b112-00215aee3ebe;Корчівка;Корчовка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825483002;3;1
5cb61b1c-749b-11df-b112-00215aee3ebe;Корчівка;Корчовка;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825687602;3;1
5cb61b1d-749b-11df-b112-00215aee3ebe;Корчівка;Корчовка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821481502;3;1
5cb61b1e-749b-11df-b112-00215aee3ebe;Корчівка;Корчовка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822784301;3;1
5cb61b1f-749b-11df-b112-00215aee3ebe;Корчівка;Корчовка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822480103;3;1
5cb61b20-749b-11df-b112-00215aee3ebe;Корчівці;Корчевцы;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321083001;3;1
5cb61b22-749b-11df-b112-00215aee3ebe;Корчмище;Корчмище;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820388602;3;1
5cb61b23-749b-11df-b112-00215aee3ebe;Корчунок;Корчунок;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122681303;3;1
5cb61b24-749b-11df-b112-00215aee3ebe;Корчунок;Корчунок;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624481902;3;1
5cb61b25-749b-11df-b112-00215aee3ebe;Корчунок;Корчунок;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622486405;3;1
301ca0a6-f31a-11e0-9215-003048d2b473;Корчунок;Корчунок;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;;3;1
5cb61b26-749b-11df-b112-00215aee3ebe;Коршаки;Коршаки;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421784402;3;1
5cb61b27-749b-11df-b112-00215aee3ebe;Коршачина;Коршачина;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920684401;3;1
5cb61b28-749b-11df-b112-00215aee3ebe;Коршелівка;Коршеловка;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;;3;1
5cb61b29-749b-11df-b112-00215aee3ebe;Коршилів;Коршилов;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122683702;3;1
5cb61b2a-749b-11df-b112-00215aee3ebe;Коршилівка;Коршиловка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124688003;3;1
5cb61b2b-749b-11df-b112-00215aee3ebe;Коршів;Коршев;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722882901;3;1
5cb61b2c-749b-11df-b112-00215aee3ebe;Коршів;Коршев;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623283001;3;1
5cb61b2d-749b-11df-b112-00215aee3ebe;Коршів;Коршев;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622687002;3;1
5cb61b2e-749b-11df-b112-00215aee3ebe;Коршовець;Коршевец;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722885103;3;1
5cb61b2f-749b-11df-b112-00215aee3ebe;Корюківка;Корюковка;місто;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422410100;2;1
5cb61b30-749b-11df-b112-00215aee3ebe;Коряківка;Коряковка;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220681103;3;1
5cb61b31-749b-11df-b112-00215aee3ebe;Коса;Коса;село;6b07f8a2-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5121484702;3;1
5cb61b32-749b-11df-b112-00215aee3ebe;Коса Рішнівка;Косая Решневка;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825580502;3;1
5cb61b34-749b-11df-b112-00215aee3ebe;Косаківка;Косаковка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522285202;3;1
5cb61b35-749b-11df-b112-00215aee3ebe;Косанове;Косаново;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520883302;3;1
5cb61b36-749b-11df-b112-00215aee3ebe;Косареве;Косарево;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623881304;3;1
5cb61b37-749b-11df-b112-00215aee3ebe;Косарі;Косари;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121882501;3;1
5cb61b38-749b-11df-b112-00215aee3ebe;Косарівщина;Косаревщина;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924184905;3;1
5cb61b39-749b-11df-b112-00215aee3ebe;Косарщина;Косарщина;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620385805;3;1
5cb61b3a-749b-11df-b112-00215aee3ebe;Косачівка;Косачовка;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422083901;3;1
5cb61b3b-749b-11df-b112-00215aee3ebe;Косенів;Косенев;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824082501;3;1
5cb61b3c-749b-11df-b112-00215aee3ebe;Косенівка;Косеновка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124384001;3;1
5cb61b3d-749b-11df-b112-00215aee3ebe;Косенки;Косенки;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923586205;3;1
5cb61b3e-749b-11df-b112-00215aee3ebe;Коси;Косы;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122983401;3;1
5cb61b3f-749b-11df-b112-00215aee3ebe;Коси;Косы;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524984003;3;1
5cb61b40-749b-11df-b112-00215aee3ebe;Косиківці;Косиковцы;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823384001;3;1
5cb61b41-749b-11df-b112-00215aee3ebe;Косино;Косино;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122786205;3;1
5cb61b42-749b-11df-b112-00215aee3ebe;Косинське;Косинское;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925680806;3;1
5cb61b43-749b-11df-b112-00215aee3ebe;Коси-Слобідка;Косы-Слободка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122983402;3;1
5cb61b45-749b-11df-b112-00215aee3ebe;Косиці;Косицы;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724285206;3;1
5cb61b46-749b-11df-b112-00215aee3ebe;Косичі;Косычи;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621884902;3;1
5cb61b47-749b-11df-b112-00215aee3ebe;Косів;Косов;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125584901;3;1
5cb61b48-749b-11df-b112-00215aee3ebe;Косів;Косов;місто;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623610100;2;0
4da0ce83-7630-11e5-93c7-003048d2b473;Косів (Смодна);Косов (Смодна);село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623685801;3;1
5cb61b49-749b-11df-b112-00215aee3ebe;Косів Верх;Косов Верх;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122484002;3;1
5cb61b57-749b-11df-b112-00215aee3ebe;Косівець;Косовец;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620955302;3;1
5cb61b4a-749b-11df-b112-00215aee3ebe;Косівка;Косовка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121285111;3;1
5cb61b4b-749b-11df-b112-00215aee3ebe;Косівка;Косовка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120885603;3;1
5cb61b4c-749b-11df-b112-00215aee3ebe;Косівка;Косовка;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221683201;2;1
5cb61b4d-749b-11df-b112-00215aee3ebe;Косівка;Косовка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520384101;3;1
5cb61b4e-749b-11df-b112-00215aee3ebe;Косівська Поляна;Косовская Поляна;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123683501;3;1
5cb61b4f-749b-11df-b112-00215aee3ebe;Косівцеве;Косовцево;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323655503;3;1
5cb61b50-749b-11df-b112-00215aee3ebe;Косівщина;Косовщина;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924783801;3;1
5cb61b51-749b-11df-b112-00215aee3ebe;Космач;Космач;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620482402;3;1
5cb61b52-749b-11df-b112-00215aee3ebe;Космач;Космач;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623684001;3;1
5cb61b53-749b-11df-b112-00215aee3ebe;Космачів;Космачев;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623486907;3;1
5cb61b54-749b-11df-b112-00215aee3ebe;Космирин;Космирин;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
5cb61b55-749b-11df-b112-00215aee3ebe;Космівка;Космовка;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721187704;3;1
5cb61b56-749b-11df-b112-00215aee3ebe;Косованка;Косованка;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324589503;3;1
5cb61b58-749b-11df-b112-00215aee3ebe;Косогірка;Косогорка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825883401;3;1
5cb61b59-749b-11df-b112-00215aee3ebe;Косонь;Косонь;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120485601;3;1
5cb61b5a-749b-11df-b112-00215aee3ebe;Костева Пастіль;Костева Пастиль;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120884003;3;1
5cb61b5b-749b-11df-b112-00215aee3ebe;Костеїв;Костыев;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622755603;3;1
5cb61b5c-749b-11df-b112-00215aee3ebe;Костенів;Костенев;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623381403;3;1
5cb61b5d-749b-11df-b112-00215aee3ebe;Костилівка;Костылевка;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123684001;3;0
5cb61b5e-749b-11df-b112-00215aee3ebe;Костильники;Костыльники;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622482803;3;1
5cb61b5f-749b-11df-b112-00215aee3ebe;Костинці;Костинцы;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324585501;3;1
5cb61b60-749b-11df-b112-00215aee3ebe;Костирів;Костырев;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424986503;3;1
5cb61b62-749b-11df-b112-00215aee3ebe;Костирка;Костырка;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521882504;3;1
5cb61b63-749b-11df-b112-00215aee3ebe;Костирка;Костырка;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520685204;3;1
5cb61b64-749b-11df-b112-00215aee3ebe;Костичани;Костичаны;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323082801;3;1
5cb61b65-749b-11df-b112-00215aee3ebe;Костичі;Костычи;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820682201;3;1
5cb61b66-749b-11df-b112-00215aee3ebe;Костів;Костов;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922988202;3;1
5cb61b67-749b-11df-b112-00215aee3ebe;Костів;Костов;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321283501;3;1
5cb61b68-749b-11df-b112-00215aee3ebe;Костівка;Костовка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322085009;3;1
5cb61b69-749b-11df-b112-00215aee3ebe;Костівці;Костовцы;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820986007;3;1
5cb61b6a-749b-11df-b112-00215aee3ebe;Костільники;Костильники;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121283101;3;1
5cb61b6b-749b-11df-b112-00215aee3ebe;Костобобрів;Костобобров;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424783001;3;1
5cb61b6e-749b-11df-b112-00215aee3ebe;Костомарівка;Костомаровка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520884001;3;1
5cb61b6f-749b-11df-b112-00215aee3ebe;Костомарове;Костомарово;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520984803;3;1
5cb61b70-749b-11df-b112-00215aee3ebe;Костопіль;Костополь;місто;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623410100;2;0
5cb61b72-749b-11df-b112-00215aee3ebe;Косточки;Косточки;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081407;3;1
5cb61b73-749b-11df-b112-00215aee3ebe;Кострижівка;Кострижевка;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321555400;3;1
5cb61b74-749b-11df-b112-00215aee3ebe;Кострино;Кострина;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120882401;3;1
5cb61b75-749b-11df-b112-00215aee3ebe;Костринська Розтока;Костринская Ростока;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120882402;3;1
5cb61b76-749b-11df-b112-00215aee3ebe;Костромка;Костромка;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520981504;3;1
5cb61b77-749b-11df-b112-00215aee3ebe;Костувате;Костоватое;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821481701;3;1
5cb61b78-749b-11df-b112-00215aee3ebe;Костюки;Костюки;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324881207;3;1
5cb61b79-749b-11df-b112-00215aee3ebe;Костюківка;Костюковка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523783201;3;1
5cb61b7a-749b-11df-b112-00215aee3ebe;Костюхнівка;Костюхновка;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723683401;3;1
5cb61b7b-749b-11df-b112-00215aee3ebe;Костючки;Костючки;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422489202;3;1
5cb61b7c-749b-11df-b112-00215aee3ebe;Костянець;Костянец;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824288904;3;1
5cb61b7d-749b-11df-b112-00215aee3ebe;Костянець;Костянец;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621684203;3;1
5cb61b7e-749b-11df-b112-00215aee3ebe;Костяни;Костяны;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923282606;3;1
5cb61b7f-749b-11df-b112-00215aee3ebe;Костянтинів;Константинов;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923583603;3;1
5cb61b80-749b-11df-b112-00215aee3ebe;Костянтинів;Константинов;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125481002;3;1
5cb61b81-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924783402;3;1
5cb61b82-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125284802;3;1
5cb61b83-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225081203;3;1
5cb61b84-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820382607;3;1
5cb61b85-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610110;3;1
5cb61b86-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824883401;3;0
5cb61b87-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823383407;3;1
5cb61b88-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323555400;3;1
5cb61b89-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322686002;3;1
5cb61b8a-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324885003;3;1
5cb61b8b-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124581702;3;1
5cb61b90-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;місто;64024b21-41b9-11df-907f-00215aee3ebe;d15e301c-60b0-11de-be1e-0030485903e8;572fe7f2-5e88-11ee-80c0-000c2961d091;1412600000;2;0
5cb61b95-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821484004;3;1
5cb61b96-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820884503;3;1
5cb61b97-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422487002;3;1
5cb61b98-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120984602;3;1
5cb61b99-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123783001;3;1
5cb61b9a-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522282801;3;1
5cb61b9b-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523883401;3;1
5cb61b9d-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524081803;3;1
5cb61b9e-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522286302;3;1
5cb61b9f-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520383801;3;1
5cb61ba0-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524686205;3;1
5cb61ba1-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525880402;3;1
5cb61ba2-749b-11df-b112-00215aee3ebe;Костянтинівка;Константиновка;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625482501;3;1
8cdd5ce0-de27-11df-9197-00215aee3ebe;Костянтинівка;Константиновка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521486502;3;1
5cb61ba4-749b-11df-b112-00215aee3ebe;Костянтівка;Константивка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321781005;3;1
5cb61ba5-749b-11df-b112-00215aee3ebe;Косяк;Косяк;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821783203;3;1
5cb61ba6-749b-11df-b112-00215aee3ebe;Косяки;Косяки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321386904;3;1
5cb61ba7-749b-11df-b112-00215aee3ebe;Косяківка;Косяковка;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224482301;3;1
5cb61ba9-749b-11df-b112-00215aee3ebe;Коськів;Коськов;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825583601;3;1
5cb61baa-749b-11df-b112-00215aee3ebe;Котелеве;Котелево;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323083201;3;1
5cb61bab-749b-11df-b112-00215aee3ebe;Котелевка;Котелевка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323583703;3;1
5cb61bac-749b-11df-b112-00215aee3ebe;Котелянка;Котелянка;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825280403;3;1
5cb61bad-749b-11df-b112-00215aee3ebe;Котелянка;Котелянка;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823682801;3;1
5cb61bae-749b-11df-b112-00215aee3ebe;Котельва;Котельва;місто;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322255100;2;1
5cb61bb0-749b-11df-b112-00215aee3ebe;Котельниця;Котельница;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121586002;3;1
5cb61bb1-749b-11df-b112-00215aee3ebe;Котенки;Котенки;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920680302;3;1
5cb61bb2-749b-11df-b112-00215aee3ebe;Коти;Коты;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625881907;3;1
5cb61bb3-749b-11df-b112-00215aee3ebe;Коти;Коты;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625888204;3;1
5cb61bb4-749b-11df-b112-00215aee3ebe;Котиківка;Котыковка;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621681601;3;1
5cb61bb5-749b-11df-b112-00215aee3ebe;Котира;Котыра;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622284003;3;1
5cb61bb6-749b-11df-b112-00215aee3ebe;Котів;Котов;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721882002;3;1
5cb61bb7-749b-11df-b112-00215aee3ebe;Котів;Котов;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120482301;3;1
5cb61bb8-749b-11df-b112-00215aee3ebe;Котів;Котов;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422087404;3;1
5cb61bb9-749b-11df-b112-00215aee3ebe;Котів;Котов;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624689809;3;1
5cb61bba-749b-11df-b112-00215aee3ebe;Котівка;Котовка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923882304;3;1
5cb61bbb-749b-11df-b112-00215aee3ebe;Котівка;Котовка;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121682701;3;1
5cb61bbd-749b-11df-b112-00215aee3ebe;Котівка;Котовка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320481506;3;1
5cb61bbe-749b-11df-b112-00215aee3ebe;Котівка;Котовка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825084601;3;1
5cb61bbf-749b-11df-b112-00215aee3ebe;Котівка;Котовка;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820383602;3;1
5cb61bc0-749b-11df-b112-00215aee3ebe;Котівка;Котовка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523884402;3;1
5cb61bda-749b-11df-b112-00215aee3ebe;Котівка (Котовського);Котовка (Котовского);село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322283004;3;1
5cb61bc1-749b-11df-b112-00215aee3ebe;Котловина;Котловина;село;d00d3b62-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5124181201;3;1
5cb61bc2-749b-11df-b112-00215aee3ebe;Котлубаївка;Котлубаевка;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524982709;3;1
5cb61bc3-749b-11df-b112-00215aee3ebe;Котляреве;Котлярево;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823382001;3;1
5cb61bc4-749b-11df-b112-00215aee3ebe;Котляревське;Котляревское;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320655402;3;1
5cb61bc5-749b-11df-b112-00215aee3ebe;Котляревське;Котляревское;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323680403;3;1
5cb619e0-749b-11df-b112-00215aee3ebe;Котляри (Комунар);Котляры (Коммунар);село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325181201;2;0
5cb61bc6-749b-11df-b112-00215aee3ebe;Котлярі;Котляри;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323281805;3;1
5cb61bc7-749b-11df-b112-00215aee3ebe;Котлярівка;Котляровка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222086003;3;1
5cb61bc8-749b-11df-b112-00215aee3ebe;Котлярівка;Котляровка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225055402;3;1
17af7b2a-ddb9-11df-9197-00215aee3ebe;Котлярівське;Котляревское;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224888505;3;1
5cb61bcd-749b-11df-b112-00215aee3ebe;Котлярка;Котлярка;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824782401;3;1
5cb61bce-749b-11df-b112-00215aee3ebe;Котова;Котова;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520281007;3;1
5cb61bcf-749b-11df-b112-00215aee3ebe;Котоване;Котовано;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621287802;3;1
5cb61bd1-749b-11df-b112-00215aee3ebe;Котовка;Котовка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222383501;3;1
5cb61bd3-749b-11df-b112-00215aee3ebe;Котовка;Котовка;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521182801;3;1
5cb61bd9-749b-11df-b112-00215aee3ebe;Котовське;Котовское;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524382501;3;1
5cb61bdd-749b-11df-b112-00215aee3ebe;Которини;Которины;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621585302;3;1
5cb61bde-749b-11df-b112-00215aee3ebe;Котузів;Котузов;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125084601;3;1
5cb61605-749b-11df-b112-00215aee3ebe;Котуш (Катуш);Котуш (Катуш);село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721487902;3;1
5cb61bdf-749b-11df-b112-00215aee3ebe;Котюжани;Котюжаны;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522884802;3;1
5cb61be0-749b-11df-b112-00215aee3ebe;Котюжани вокзал;Котюжаны Вокзал;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;;3;1
5cb61be1-749b-11df-b112-00215aee3ebe;Котюжини;Котюжины;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122481202;3;1
5cb61be2-749b-11df-b112-00215aee3ebe;Котюжинці;Котюжинцы;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521683803;3;1
5cb61be3-749b-11df-b112-00215aee3ebe;Котюржинці;Котюржинцы;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823683001;3;1
5cb61be4-749b-11df-b112-00215aee3ebe;Котюржинці;Котюржинцы;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824783002;3;1
5cb61be5-749b-11df-b112-00215aee3ebe;Котюржинці;Котюржинцы;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822784401;3;1
5cb61be6-749b-11df-b112-00215aee3ebe;Кофанівка;Кофановка;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323180802;3;1
5cb61be7-749b-11df-b112-00215aee3ebe;Кохан;Кохан;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620883002;3;1
5cb61be9-749b-11df-b112-00215aee3ebe;Кохане;Коханое;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520884004;3;1
5cb61beb-749b-11df-b112-00215aee3ebe;Коханівка;Кохановка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325455303;3;1
5cb61bec-749b-11df-b112-00215aee3ebe;Коханівка;Кохановка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922055304;3;1
5cb61bed-749b-11df-b112-00215aee3ebe;Коханівка;Кохановка;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122485101;3;1
5cb61bee-749b-11df-b112-00215aee3ebe;Коханівка;Кохановка;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223887103;3;1
5cb61bef-749b-11df-b112-00215aee3ebe;Коханівка;Кохановка;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323180603;3;1
5cb61bf0-749b-11df-b112-00215aee3ebe;Коханівка;Кохановка;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120282601;3;1
5cb61bf1-749b-11df-b112-00215aee3ebe;Коханівка;Кохановка;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625887803;3;1
5cb61bf2-749b-11df-b112-00215aee3ebe;Коханівка;Кохановка;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121882601;3;1
5cb61bf3-749b-11df-b112-00215aee3ebe;Коханівка;Кохановка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522282603;3;1
5cb61bf4-749b-11df-b112-00215aee3ebe;Коханівка;Кохановка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522286303;3;1
5cb61bf5-749b-11df-b112-00215aee3ebe;Коханівка;Кохановка;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823682002;3;1
5cb61bf6-749b-11df-b112-00215aee3ebe;Кохівка;Коховка;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223581306;3;1
5cb61bf7-749b-11df-b112-00215aee3ebe;Кохівка;Коховка;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120282801;3;1
62c3d039-749b-11df-b112-00215aee3ebe;Коцупіївка;Коцупиивка;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923287405;3;1
5cb61bf8-749b-11df-b112-00215aee3ebe;Коцурів;Коцуров;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623683104;3;1
5cb61bf9-749b-11df-b112-00215aee3ebe;Коцюбинське;Коцюбинское;місто;903cca8d-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3210946200;1;0
73e94c18-ddc1-11df-9197-00215aee3ebe;Коцюбинське;Коцюбинское;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;a8bc35df-4aa1-11e3-9df6-003048d2b473;;3;1
5cb61bfa-749b-11df-b112-00215aee3ebe;Коцюбинці;Коцюбинцы;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121683001;3;1
5cb61bfb-749b-11df-b112-00215aee3ebe;Коцюбинчики;Коцюбинчики;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125585201;3;1
5cb61bfc-749b-11df-b112-00215aee3ebe;Коцюри;Коцюры;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723380404;3;1
5cb61bfe-749b-11df-b112-00215aee3ebe;Кочерги;Кочерги;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923881902;3;1
5cb61c02-749b-11df-b112-00215aee3ebe;Кочережки;Кочережки;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223582807;3;1
5cb61c00-749b-11df-b112-00215aee3ebe;Кочержинці;Кочержинцы;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124384301;3;1
5cb61c01-749b-11df-b112-00215aee3ebe;Кочерів;Кочеров;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825084801;3;1
c6cc1c11-db7e-11df-9197-00215aee3ebe;Кочетівка;Кочетовка;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322281502;3;1
5cb61c03-749b-11df-b112-00215aee3ebe;Кочеток;Кочеток;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325455600;3;1
5cb61c05-749b-11df-b112-00215aee3ebe;Кочичине;Кочичино;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821783001;3;1
5cb61c06-749b-11df-b112-00215aee3ebe;Кочкувате;Кочковатое;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125082601;2;1
5cb61c07-749b-11df-b112-00215aee3ebe;Кочубеївка;Кочубеевка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325484204;3;1
5cb61c08-749b-11df-b112-00215aee3ebe;Кочубеївка;Кочубеевка;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322082506;3;1
5cb61c09-749b-11df-b112-00215aee3ebe;Кочубеївка;Кочубеевка;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;6521881501;3;1
5cb61c0b-749b-11df-b112-00215aee3ebe;Кочубеївка;Кочубеевка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124384501;3;1
c8674a9a-dbb0-11df-9197-00215aee3ebe;Кочубеївка;Кочубеевка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;;3;1
5cb61c0c-749b-11df-b112-00215aee3ebe;Кочубіїв;Кочубеев;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825284401;2;1
17af7b0e-ddb9-11df-9197-00215aee3ebe;Кочурів;Кочуров;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520883606;3;1
5cb61c0d-749b-11df-b112-00215aee3ebe;Кошаковські;Кошаковски;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622185911;3;1
5cb61c0e-749b-11df-b112-00215aee3ebe;Кошани;Кошаны;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422086504;3;1
5cb61c0f-749b-11df-b112-00215aee3ebe;Кошара;Кошара;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520686702;3;1
5cb61c10-749b-11df-b112-00215aee3ebe;Кошари;Кошары;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922084501;3;1
5cb61c11-749b-11df-b112-00215aee3ebe;Кошари;Кошары;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725784604;3;1
5cb61c12-749b-11df-b112-00215aee3ebe;Кошари;Кошары;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123983201;3;1
5cb61c13-749b-11df-b112-00215aee3ebe;Кошари;Кошары;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122785802;3;1
5cb61c15-749b-11df-b112-00215aee3ebe;Кошаринці;Кошаринцы;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520284409;3;1
5cb61c16-749b-11df-b112-00215aee3ebe;Кошаринці;Кошаринцы;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520482303;3;1
5cb61c17-749b-11df-b112-00215aee3ebe;Кошарище;Кошарище;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422781502;3;1
5cb61c18-749b-11df-b112-00215aee3ebe;Кошарка;Кошарка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125281903;3;1
5cb61c1a-749b-11df-b112-00215aee3ebe;Кошаро-Олександрівка;Кошаро-Александровка;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525585202;3;1
5cb61c1b-749b-11df-b112-00215aee3ebe;Кошарське;Кошарское;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920983204;3;1
5cb61c1c-749b-11df-b112-00215aee3ebe;Кошатів;Кошатов;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622680603;3;1
5cb61c1e-749b-11df-b112-00215aee3ebe;Кошелів;Кошелев;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622786405;3;1
5cb61c20-749b-11df-b112-00215aee3ebe;Кошелівка;Кошелевка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825482201;3;1
5cb61c21-749b-11df-b112-00215aee3ebe;Кошелівка;Кошелевка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822787006;3;1
5cb61c1d-749b-11df-b112-00215aee3ebe;Кошельово;Кошелево;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125384201;3;1
5cb61c22-749b-11df-b112-00215aee3ebe;Кошечки;Кошечки;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824284002;3;1
5cb61c23-749b-11df-b112-00215aee3ebe;Кошилівці;Кошиловцы;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122085401;3;1
5cb61c24-749b-11df-b112-00215aee3ebe;Кошів;Кошев;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722886002;3;1
5cb61c25-749b-11df-b112-00215aee3ebe;Кошів;Кошев;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224684401;3;1
5cb61c26-749b-11df-b112-00215aee3ebe;Кошівка;Кошевка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425585503;3;1
5cb61c27-749b-11df-b112-00215aee3ebe;Кошлани;Кошланы;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523182201;3;1
5cb61c28-749b-11df-b112-00215aee3ebe;Кошляки;Кошляки;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124683901;3;1
5cb61c29-749b-11df-b112-00215aee3ebe;Кошляки;Кошляки;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824785703;3;1
5cb61c2a-749b-11df-b112-00215aee3ebe;Кошмак;Кошмак;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122584801;3;1
5cb61c2b-749b-11df-b112-00215aee3ebe;Кошмаки;Кошмаки;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620886202;3;1
5cb61c2c-749b-11df-b112-00215aee3ebe;Кошмалів;Кошмалов;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420380502;3;1
5cb61c2d-749b-11df-b112-00215aee3ebe;Кошманівка;Кошмановка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323082201;3;1
5cb61c2e-749b-11df-b112-00215aee3ebe;Кошове;Кошевое;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225855107;3;1
e0517ed9-db89-11df-9197-00215aee3ebe;Коштове;Коштовое;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822781505;3;1
5cb61c2f-749b-11df-b112-00215aee3ebe;Коштуля;Коштуля;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522682105;3;1
5cb61c30-749b-11df-b112-00215aee3ebe;Кошуляни;Кошуляны;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323084302;3;1
5cb61c31-749b-11df-b112-00215aee3ebe;Кощіївка;Кощиевка;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224983302;3;1
5cb61c32-749b-11df-b112-00215aee3ebe;Кравці;Кравцы;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885704;3;1
5cb61c33-749b-11df-b112-00215aee3ebe;Кравцівка;Кравцовка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225682002;3;1
5cb61c34-749b-11df-b112-00215aee3ebe;Кравцівка;Кравцовка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325781502;3;1
5cb61c35-749b-11df-b112-00215aee3ebe;Кравцове;Кравцово;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321780507;3;1
5cb61c36-749b-11df-b112-00215aee3ebe;Кравченки;Кравченки;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320284705;3;1
5cb61c37-749b-11df-b112-00215aee3ebe;Кравченкове;Кравченково;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921586004;3;1
5cb61c38-749b-11df-b112-00215aee3ebe;Кравченкове;Кравченково;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920681805;3;1
5cb61c39-749b-11df-b112-00215aee3ebe;Кравчики;Кравчики;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;;3;1
5cb61c3a-749b-11df-b112-00215aee3ebe;Кравчиха;Кравчиха;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423381203;3;1
5cb61c3b-749b-11df-b112-00215aee3ebe;Країв;Краев;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624286403;3;1
5cb61c3c-749b-11df-b112-00215aee3ebe;Краївщина;Краевщина;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821182701;3;1
5cb61c3d-749b-11df-b112-00215aee3ebe;Крайнє;Крайнее;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125385202;3;1
5cb61c3f-749b-11df-b112-00215aee3ebe;Крайниково;Крайниково;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125384401;3;1
5cb61c40-749b-11df-b112-00215aee3ebe;Крайня Деражня;Крайняя Дережня;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824082503;3;1
5cb61c41-749b-11df-b112-00215aee3ebe;Крайня Мартинка;Крайняя Мартынка;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121987202;3;1
5cb61c42-749b-11df-b112-00215aee3ebe;Краківщина;Краковщина;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820987005;3;1
5cb61c43-749b-11df-b112-00215aee3ebe;Краковець;Краковец;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625855600;2;1
5cb61c44-749b-11df-b112-00215aee3ebe;Крамаренки;Крамаренки;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322481305;3;1
5cb61c45-749b-11df-b112-00215aee3ebe;Крамарівка;Крамаровка;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323280607;3;1
5cb61c46-749b-11df-b112-00215aee3ebe;Крамарівка;Крамаровка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322886506;3;1
5cb61c47-749b-11df-b112-00215aee3ebe;Крамарка;Крамарка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222382505;3;1
5cb61c48-749b-11df-b112-00215aee3ebe;Крамарщина;Крамарщина;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320485904;3;1
5cb61c49-749b-11df-b112-00215aee3ebe;Краматорськ;Краматорск;місто;78f453f1-41b9-11df-907f-00215aee3ebe;d15e301c-60b0-11de-be1e-0030485903e8;572fe7f2-5e88-11ee-80c0-000c2961d091;1412900000;1;0
5cb61c4a-749b-11df-b112-00215aee3ebe;Крамчанка;Крамчанка;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921284802;3;1
5cb61c4b-749b-11df-b112-00215aee3ebe;Красенівка;Красеновка;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125183601;3;1
5cb61c4c-749b-11df-b112-00215aee3ebe;Красиве;Красивое;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324586002;3;1
5cb61c4d-749b-11df-b112-00215aee3ebe;Красилів;Красилов;місто;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822710100;2;0
5cb61c4e-749b-11df-b112-00215aee3ebe;Красилівка;Красиловка;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625885503;3;1
5cb61c4f-749b-11df-b112-00215aee3ebe;Красилівка;Красиловка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222085003;3;1
5cb61c50-749b-11df-b112-00215aee3ebe;Красилівка;Красиловка;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221284401;1;0
5cb61c51-749b-11df-b112-00215aee3ebe;Красилівка;Красиловка;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224283601;3;1
5cb61c52-749b-11df-b112-00215aee3ebe;Красилівка;Красиловка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824285203;3;1
5cb61c53-749b-11df-b112-00215aee3ebe;Красилівка;Красиловка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824082601;3;1
5cb61c54-749b-11df-b112-00215aee3ebe;Красилівка;Красиловка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510119;3;1
5cb61c55-749b-11df-b112-00215aee3ebe;Красилівка;Красиловка;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420384501;3;1
5cb61c56-749b-11df-b112-00215aee3ebe;Красилівка;Красиловка;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422084201;3;1
5cb61c57-749b-11df-b112-00215aee3ebe;Красилівське;Красиловское;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824082603;3;1
5cb61c58-749b-11df-b112-00215aee3ebe;Красичка;Красичка;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924481303;3;1
5cb61c59-749b-11df-b112-00215aee3ebe;Красів;Красов;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720882002;3;1
5cb61c5a-749b-11df-b112-00215aee3ebe;Красів;Красов;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623088410;3;1
5cb61c5b-749b-11df-b112-00215aee3ebe;Красівка;Красовка;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125282404;3;1
5cb61c5c-749b-11df-b112-00215aee3ebe;Красівка;Красовка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820883001;3;1
5cb61c5d-749b-11df-b112-00215aee3ebe;Красівка;Красовка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822781102;3;1
4f966221-8350-11e8-80d4-1c98ec135261;Красівське;Красовское;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221883501;3;1
5cb61c5e-749b-11df-b112-00215aee3ebe;Красіїв;Красиев;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124286401;3;1
5cb61c5f-749b-11df-b112-00215aee3ebe;Красіне;Красино;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;*122188350;3;1
5cb61c60-749b-11df-b112-00215aee3ebe;Красічин;Красичин;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622183005;3;1
5cb61c61-749b-11df-b112-00215aee3ebe;Краска;Краска;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724284502;3;1
5cb61c62-749b-11df-b112-00215aee3ebe;Красківське;Красковское;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424483401;3;1
5cb61c63-749b-11df-b112-00215aee3ebe;Красляни;Красляны;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424184301;3;1
5cb61c64-749b-11df-b112-00215aee3ebe;Красна;Красная;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624082701;3;1
5cb61c65-749b-11df-b112-00215aee3ebe;Красна;Красная;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124483201;3;1
5cb61c66-749b-11df-b112-00215aee3ebe;Красна Балка;Красная Балка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221882003;3;1
5cb61c68-749b-11df-b112-00215aee3ebe;Красна Воля;Красная Воля;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224581805;3;1
5cb61c69-749b-11df-b112-00215aee3ebe;Красна Гірка;Красная Горка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324880502;3;1
5cb61c6a-749b-11df-b112-00215aee3ebe;Красна Гірка;Красная Горка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7410590002;3;1
5cb61c71-749b-11df-b112-00215aee3ebe;Красна Коса;Красная Коса;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120883601;3;1
5cb61c67-749b-11df-b112-00215aee3ebe;Красна Лука;Красная Лука;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320483601;3;1
5cb61c72-749b-11df-b112-00215aee3ebe;Красна Поляна;Красная Поляна;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321781006;3;1
5cb61c76-749b-11df-b112-00215aee3ebe;Красна Слобідка;Красная Слободка;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223185601;3;1
5cb61c7a-749b-11df-b112-00215aee3ebe;Красне;Красное;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;;3;1
5cb61c7b-749b-11df-b112-00215aee3ebe;Красне;Красное;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321884201;3;1
5cb61c7c-749b-11df-b112-00215aee3ebe;Красне;Красное;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321685603;3;1
5cb61c7d-749b-11df-b112-00215aee3ebe;Красне;Красное;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923883811;3;1
5cb61c7e-749b-11df-b112-00215aee3ebe;Красне;Красное;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922084901;3;1
5cb61c7f-749b-11df-b112-00215aee3ebe;Красне;Красное;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122684302;3;1
5cb61c80-749b-11df-b112-00215aee3ebe;Красне;Красное;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121683301;3;1
5cb61c81-749b-11df-b112-00215aee3ebe;Красне;Красное;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224882003;3;1
5cb61c82-749b-11df-b112-00215aee3ebe;Красне;Красное;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755105;3;1
5cb61c83-749b-11df-b112-00215aee3ebe;Красне;Красное;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221883504;3;1
5cb61c84-749b-11df-b112-00215aee3ebe;Красне;Красное;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222985504;3;1
5cb61c85-749b-11df-b112-00215aee3ebe;Красне;Красное;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820982530;3;1
5cb61c86-749b-11df-b112-00215aee3ebe;Красне;Красное;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325180703;3;1
5cb61c88-749b-11df-b112-00215aee3ebe;Красне;Красное;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323181301;2;1
5cb61c89-749b-11df-b112-00215aee3ebe;Красне;Красное;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324584701;3;1
5cb61c8a-749b-11df-b112-00215aee3ebe;Красне;Красное;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120281403;3;1
5cb61c8b-749b-11df-b112-00215aee3ebe;Красне;Красное;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283610;3;1
5cb61c8c-749b-11df-b112-00215aee3ebe;Красне;Красное;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124784201;3;1
5cb61c90-749b-11df-b112-00215aee3ebe;Красне;Красное;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624882401;3;1
5cb61c91-749b-11df-b112-00215aee3ebe;Красне;Красное;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620655300;3;1
5cb61c92-749b-11df-b112-00215aee3ebe;Красне;Красное;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625584201;3;1
5cb61c97-749b-11df-b112-00215aee3ebe;Красне;Красное;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221983001;3;1
5cb61c98-749b-11df-b112-00215aee3ebe;Красне;Красное;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220410506;3;1
5cb61c99-749b-11df-b112-00215aee3ebe;Красне;Красное;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425584001;2;1
5cb61c9a-749b-11df-b112-00215aee3ebe;Красне;Красное;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423687803;3;1
5cb61c9b-749b-11df-b112-00215aee3ebe;Красне;Красное;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420385001;3;1
5cb61c9c-749b-11df-b112-00215aee3ebe;Красне;Красное;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420687403;3;1
5cb61c9d-749b-11df-b112-00215aee3ebe;Красне;Красное;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521486809;3;1
5cb61c9e-749b-11df-b112-00215aee3ebe;Красне;Красное;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
5cb61c9f-749b-11df-b112-00215aee3ebe;Красне;Красное;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524584405;3;1
5cb61ca0-749b-11df-b112-00215aee3ebe;Красне;Красное;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522884202;3;1
5cb61ca1-749b-11df-b112-00215aee3ebe;Красне;Красное;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521980802;3;1
5cb61ca2-749b-11df-b112-00215aee3ebe;Красне;Красное;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623881602;3;1
5cb61ca3-749b-11df-b112-00215aee3ebe;Красне;Красное;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325180805;3;1
5cb61ca6-749b-11df-b112-00215aee3ebe;Красне Перше;Красное Первое;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223185101;3;1
5cb61ca8-749b-11df-b112-00215aee3ebe;Красненьке;Красненькое;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823981501;3;1
5cb61ca9-749b-11df-b112-00215aee3ebe;Красненьке;Красненькое;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521210109;3;1
5cb61caa-749b-11df-b112-00215aee3ebe;Красний Бір;Красный Бор;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725785002;3;1
5cb61cad-749b-11df-b112-00215aee3ebe;Красний Колядин;Красный Колядин;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425382001;3;1
5cb61cb4-749b-11df-b112-00215aee3ebe;Красний Луг;Красный Луг;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224584809;3;1
5cb61cc0-749b-11df-b112-00215aee3ebe;Красний Під;Красный Под;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885705;3;1
5cb61cc2-749b-11df-b112-00215aee3ebe;Красний Ріг;Красный Рог;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221088603;3;1
5cb61cc3-749b-11df-b112-00215aee3ebe;Красний Хутір;Красный Хутор;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423681603;3;1
5cb61cc4-749b-11df-b112-00215aee3ebe;Красний Яр;Красный Яр;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
5cb61cc6-749b-11df-b112-00215aee3ebe;Красний Яр;Красный Яр;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
5cb61cc9-749b-11df-b112-00215aee3ebe;Красник;Красник;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620884301;3;1
5cb61cca-749b-11df-b112-00215aee3ebe;Краснилівка;Красниловка;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721480802;3;1
5cb61ccb-749b-11df-b112-00215aee3ebe;Красниця;Красница;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624281606;3;1
5cb61ccc-749b-11df-b112-00215aee3ebe;Красні Лози;Красные Лозы;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424782503;3;1
5cb61cce-749b-11df-b112-00215aee3ebe;Краснівка;Красновка;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822382604;3;1
5cb61ccf-749b-11df-b112-00215aee3ebe;Краснівка;Красновка;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;6521884503;3;1
62c3ccdc-749b-11df-b112-00215aee3ebe;Краснобірка;Красноборка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825085001;3;1
62c3ccdd-749b-11df-b112-00215aee3ebe;Красноволиця;Красноволица;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825884002;3;1
62c3ccde-749b-11df-b112-00215aee3ebe;Красноволя;Красноволя;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723310108;3;1
62c3ccdf-749b-11df-b112-00215aee3ebe;Красноволя;Красноволя;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723683501;3;1
62c3cce0-749b-11df-b112-00215aee3ebe;Красноволя;Красноволя;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;5b6cbc30-170a-11df-b41f-00215aee3ebe;0722182406;3;1
62c3cce3-749b-11df-b112-00215aee3ebe;Красногірка;Красногорка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323080402;3;1
62c3cce5-749b-11df-b112-00215aee3ebe;Красногірка;Красногорка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821783402;3;1
62c3cce6-749b-11df-b112-00215aee3ebe;Красногірка;Красногорка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386022;3;1
62c3cce7-749b-11df-b112-00215aee3ebe;Красногірка;Красногорка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821183603;3;1
62c3cce8-749b-11df-b112-00215aee3ebe;Красногірка;Красногорка;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825884001;3;1
62c3cce9-749b-11df-b112-00215aee3ebe;Красногірка;Красногорка;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824782701;3;1
62c3ccea-749b-11df-b112-00215aee3ebe;Красногірка;Красногорка;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524181802;3;1
62c3cceb-749b-11df-b112-00215aee3ebe;Красногірка;Красногорка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521483201;3;1
62c3ccee-749b-11df-b112-00215aee3ebe;Красногорівка;Красногоровка;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281209;3;1
62c3ccef-749b-11df-b112-00215aee3ebe;Красногорівка;Красногоровка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655118;3;1
62c3ccf2-749b-11df-b112-00215aee3ebe;Красногородка;Красногородка;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220683204;3;1
62c3ccf3-749b-11df-b112-00215aee3ebe;Красноград;Красноград;місто;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323310100;2;0
62c3ccfa-749b-11df-b112-00215aee3ebe;Краснодуб'я;Краснодубье;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155702;3;1
62c3ccfc-749b-11df-b112-00215aee3ebe;Краснознам'янка;Краснознаменка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222687703;3;1
62c3ccff-749b-11df-b112-00215aee3ebe;Красноіванівка;Красноивановка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224581205;3;1
62c3cd00-749b-11df-b112-00215aee3ebe;Красноїлля;Красноилье;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620884501;3;1
62c3cd01-749b-11df-b112-00215aee3ebe;Красноїльськ;Красноильск;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324555400;3;1
62c3cd04-749b-11df-b112-00215aee3ebe;Краснокутськ;Краснокутск;місто;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323555100;3;1
62c3cd05-749b-11df-b112-00215aee3ebe;Красноліси;Краснолесы;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224082801;3;1
62c3cd07-749b-11df-b112-00215aee3ebe;Краснолука;Краснолука;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123884201;3;1
62c3cd09-749b-11df-b112-00215aee3ebe;Краснолюбецьк;Краснолюбецк;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520955702;3;1
62c3cd0a-749b-11df-b112-00215aee3ebe;Краснопавлівка;Краснопавловка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323955400;3;1
b36f1a44-db87-11df-9197-00215aee3ebe;Краснопавлівка;Краснопавловка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;;3;1
62c3cd0c-749b-11df-b112-00215aee3ebe;Краснопілка;Краснополка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124384801;3;1
62c3cd0d-749b-11df-b112-00215aee3ebe;Краснопілка;Краснополка;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520882803;3;1
62c3cd0e-749b-11df-b112-00215aee3ebe;Краснопілка;Краснополка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523110102;3;1
62c3cd0f-749b-11df-b112-00215aee3ebe;Краснопілля;Краснополье;місто;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922355100;2;1
62c3cd10-749b-11df-b112-00215aee3ebe;Краснопілля;Краснополье;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222383002;3;1
62c3cd11-749b-11df-b112-00215aee3ebe;Краснопілля;Краснополье;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820982401;3;1
62c3cd16-749b-11df-b112-00215aee3ebe;Краснопілля;Краснополье;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422283501;3;1
62c3cd17-749b-11df-b112-00215aee3ebe;Краснопілля;Краснополье;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521485202;3;1
62c3cd18-749b-11df-b112-00215aee3ebe;Краснопілля;Краснополье;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524985903;3;1
62c3cd19-749b-11df-b112-00215aee3ebe;Краснопіль;Краснополь;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225483203;3;1
62c3cd1a-749b-11df-b112-00215aee3ebe;Краснопіль;Краснополь;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822382601;3;1
62c3cd1b-749b-11df-b112-00215aee3ebe;Краснопіль;Краснополь;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125255411;3;1
62c3cd1c-749b-11df-b112-00215aee3ebe;Краснопіль;Краснополь;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822381003;3;1
62c3cd1d-749b-11df-b112-00215aee3ebe;Краснопіль;Краснополь;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825884401;3;1
62c3cd1f-749b-11df-b112-00215aee3ebe;Краснопуща;Краснопуща;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120489102;3;1
62c3cd21-749b-11df-b112-00215aee3ebe;Краснорічка;Красноречка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821183402;3;1
62c3cd25-749b-11df-b112-00215aee3ebe;Красносілка;Красноселка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122782601;2;1
62c3cd26-749b-11df-b112-00215aee3ebe;Красносілка;Красноселка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824280603;3;1
62c3cd27-749b-11df-b112-00215aee3ebe;Красносілка;Красноселка;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822887607;3;1
62c3cd28-749b-11df-b112-00215aee3ebe;Красносілка;Красноселка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822382202;3;1
62c3cd29-749b-11df-b112-00215aee3ebe;Красносілка;Красноселка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825085003;3;1
62c3cd2a-749b-11df-b112-00215aee3ebe;Красносілка;Красноселка;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825884801;3;1
62c3cd2b-749b-11df-b112-00215aee3ebe;Красносілка;Красноселка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522483402;3;1
62c3cd2c-749b-11df-b112-00215aee3ebe;Красносілка;Красноселка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520482403;3;1
62c3cd2d-749b-11df-b112-00215aee3ebe;Красносілка;Красноселка;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521984201;3;1
62c3cd2e-749b-11df-b112-00215aee3ebe;Красносілка;Красноселка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520583501;3;1
62c3cd2f-749b-11df-b112-00215aee3ebe;Красносілка;Красноселка;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823982702;3;1
62c3cd30-749b-11df-b112-00215aee3ebe;Красносілка;Красноселка;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825582302;3;1
62c3cd31-749b-11df-b112-00215aee3ebe;Красносілка;Красноселка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824287702;3;1
62c3cd32-749b-11df-b112-00215aee3ebe;Красносілка;Красноселка;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824482003;3;1
62c3cd33-749b-11df-b112-00215aee3ebe;Красносілка;Красноселка;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821589802;3;1
62c3cd34-749b-11df-b112-00215aee3ebe;Красносілля;Красноселье;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322080403;3;1
62c3cd35-749b-11df-b112-00215aee3ebe;Красносілля;Красноселье;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125482501;3;1
62c3cd36-749b-11df-b112-00215aee3ebe;Красносілля;Красноселье;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520584001;3;1
62c3cd37-749b-11df-b112-00215aee3ebe;Красносілля;Красноселье;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620885801;3;1
62c3cd22-749b-11df-b112-00215aee3ebe;Красносілля;Красноселье;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621283201;3;1
62c3cd38-749b-11df-b112-00215aee3ebe;Красносільське;Красносельское;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520984804;3;1
62c3cd3b-749b-11df-b112-00215aee3ebe;Красносільське;Красносельское;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420882401;3;1
62c3cd3c-749b-11df-b112-00215aee3ebe;Красносільці;Красносельцы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122485401;3;1
62c3cd3d-749b-11df-b112-00215aee3ebe;Красносільці;Красносельцы;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621885502;3;1
62c3cd3e-749b-11df-b112-00215aee3ebe;Красностав;Красностав;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720582201;3;1
62c3cd3f-749b-11df-b112-00215aee3ebe;Красностав;Красностав;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822882401;3;1
62c3cd40-749b-11df-b112-00215aee3ebe;Красностав;Красностав;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420889605;3;1
62c3cd41-749b-11df-b112-00215aee3ebe;Красностав;Красностав;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823982202;3;1
62c3cd42-749b-11df-b112-00215aee3ebe;Красноставка;Красноставка;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123181001;3;1
62c3cd43-749b-11df-b112-00215aee3ebe;Красноставці;Красноставцы;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625282801;3;1
62c3cd44-749b-11df-b112-00215aee3ebe;Красноставці;Красноставцы;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825284701;2;1
62c3cd46-749b-11df-b112-00215aee3ebe;Краснофедорівка;Краснофедоровка;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524684203;3;1
62c3cd48-749b-11df-b112-00215aee3ebe;Краснощокове;Краснощеково;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220788802;3;1
62c3cd49-749b-11df-b112-00215aee3ebe;Красноярка;Красноярка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821482603;3;1
62c3cd4a-749b-11df-b112-00215aee3ebe;Красноярка;Красноярка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324883502;3;1
62c3cd4b-749b-11df-b112-00215aee3ebe;Красноярське;Красноярское;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924484403;3;1
62c3cd4f-749b-11df-b112-00215aee3ebe;Краснянка;Краснянка;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224086203;3;1
62c3cd50-749b-11df-b112-00215aee3ebe;Краснянка;Краснянка;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524583401;3;1
62c3cd51-749b-11df-b112-00215aee3ebe;Краснянське;Краснянское;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323181305;3;1
62c3cd54-749b-11df-b112-00215aee3ebe;Красулине;Красулино;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;;3;1
62c3cd55-749b-11df-b112-00215aee3ebe;Красуля;Красуля;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820681803;3;1
62c3cd56-749b-11df-b112-00215aee3ebe;Красюки;Красюки;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224486402;3;1
62c3cd57-749b-11df-b112-00215aee3ebe;Красятичі;Красятичи;місто;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223556100;3;1
62c3cd58-749b-11df-b112-00215aee3ebe;Кратинь;Кратынь;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424487205;3;1
62c3cd59-749b-11df-b112-00215aee3ebe;Кратова Говтва;Кратова Говтва;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321082606;3;1
62c3cd5a-749b-11df-b112-00215aee3ebe;Крать;Крать;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725583902;3;1
62c3cd5b-749b-11df-b112-00215aee3ebe;Крачанівка;Крачановка;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823687005;3;1
62c3cd5c-749b-11df-b112-00215aee3ebe;Крачки;Крачки;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825083002;3;1
62c3cd5d-749b-11df-b112-00215aee3ebe;Крачківка;Крачковка;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123183501;3;1
62c3cd5e-749b-11df-b112-00215aee3ebe;Кревелівка;Кревелевка;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322880302;3;1
62c3cd62-749b-11df-b112-00215aee3ebe;Крейдянка;Крейдянка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320210101;3;1
62c3cd79-749b-11df-b112-00215aee3ebe;Крем'янка;Кремянка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821782703;3;1
62c3cd7a-749b-11df-b112-00215aee3ebe;Крем'янка;Кремянка;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322885304;3;1
62c3cd63-749b-11df-b112-00215aee3ebe;Кременець;Кременец;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724582908;3;1
62c3cd64-749b-11df-b112-00215aee3ebe;Кременець;Кременец;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;;3;1
62c3cd65-749b-11df-b112-00215aee3ebe;Кременець;Кременец;місто;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6110700000;2;0
62c3cd66-749b-11df-b112-00215aee3ebe;Кременець;Кременец;місто;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
62c3cd67-749b-11df-b112-00215aee3ebe;Кременище;Кременище;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222487202;3;1
62c3cd68-749b-11df-b112-00215aee3ebe;Кременівка;Кременевка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222383003;3;1
62c3cd69-749b-11df-b112-00215aee3ebe;Кременівка;Кременевка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821755102;3;1
62c3cd6b-749b-11df-b112-00215aee3ebe;Кременчувате;Кременчеватое;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522885808;3;1
2e1bd8f3-19fe-11df-b41f-00215aee3ebe;Кременчук;Кременчуг;місто;5394b316-41be-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5310400000;1;0
62c3cd6c-749b-11df-b112-00215aee3ebe;Кременчуки;Кременчуки;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822784801;3;1
17af7b1d-ddb9-11df-9197-00215aee3ebe;Кремеш;Кремеш;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722482603;3;1
62c3cd6d-749b-11df-b112-00215aee3ebe;Кремидів;Кремидов;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621283902;3;1
62c3cd6e-749b-11df-b112-00215aee3ebe;Кремидівка;Кремидовка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122783001;3;1
62c3cd71-749b-11df-b112-00215aee3ebe;Кремінна;Кременная;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821283401;3;1
62c3cd73-749b-11df-b112-00215aee3ebe;Кремінне;Кременное;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522683600;3;1
62c3cd74-749b-11df-b112-00215aee3ebe;Кремінці;Кременци;село;a1e9f9cf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;;3;1
62c3cd75-749b-11df-b112-00215aee3ebe;Кремінянські Хутори;Кременянские Хутора;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821283403;3;1
62c3cd76-749b-11df-b112-00215aee3ebe;Кремне;Кремно;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822882701;3;1
62c3cd78-749b-11df-b112-00215aee3ebe;Кремський Бугор;Кремский Бугор;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423684703;3;1
62c3cd7b-749b-11df-b112-00215aee3ebe;Кренидівка;Кренидовка;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924482801;3;1
62c3cd7c-749b-11df-b112-00215aee3ebe;Креничі;Креничи;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223186802;3;1
62c3cd7d-749b-11df-b112-00215aee3ebe;Крені;Крени;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422081204;3;1
62c3cd7f-749b-11df-b112-00215aee3ebe;Крестопівщина;Крестоповщина;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425889205;3;1
62c3cd81-749b-11df-b112-00215aee3ebe;Кретівці;Кретовцы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122485601;3;1
62c3cd82-749b-11df-b112-00215aee3ebe;Крехаїв;Крехаев;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422084501;3;1
62c3cd83-749b-11df-b112-00215aee3ebe;Крехів;Крехов;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621588302;3;1
8cdd5cf7-de27-11df-9197-00215aee3ebe;Крехів;Крехов;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784401;3;1
62c3cd84-749b-11df-b112-00215aee3ebe;Креховичі;Креховичи;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624882601;3;1
62c3cd85-749b-11df-b112-00215aee3ebe;Кречів;Кречев;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721182405;3;1
62c3cdc9-749b-11df-b112-00215aee3ebe;Крив'яки;Кривяки;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622486406;3;1
62c3cd96-749b-11df-b112-00215aee3ebe;Крива;Крива;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124483401;3;0
62c3cd86-749b-11df-b112-00215aee3ebe;Крива;Кривая;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624881102;3;1
62c3cd87-749b-11df-b112-00215aee3ebe;Крива;Кривая;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125384601;3;1
62c3cd88-749b-11df-b112-00215aee3ebe;Крива;Кривая;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224482601;3;1
62c3cd89-749b-11df-b112-00215aee3ebe;Крива Балка;Кривая Балка;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124582201;3;1
62c3cd8b-749b-11df-b112-00215aee3ebe;Крива Балка;Кривая Балка;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824282001;3;1
62c3cd8d-749b-11df-b112-00215aee3ebe;Крива Пустош;Кривая Пустошь;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821482201;3;1
62c3cd8e-749b-11df-b112-00215aee3ebe;Крива Руда;Кривая Руда;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320688102;3;1
62c3cd8a-749b-11df-b112-00215aee3ebe;Крива Руда;Кривая Руда;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324583201;3;1
62c3cd8f-749b-11df-b112-00215aee3ebe;Кривачинці;Кривачинцы;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820983701;3;1
62c3cd90-749b-11df-b112-00215aee3ebe;Кривбас;Кривбасс;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221881403;3;1
62c3cd91-749b-11df-b112-00215aee3ebe;Криве;Криве;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123085201;3;1
62c3cd92-749b-11df-b112-00215aee3ebe;Криве;Криве;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124684030;3;1
62c3cd93-749b-11df-b112-00215aee3ebe;Криве;Криве;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623981701;3;1
62c3cd94-749b-11df-b112-00215aee3ebe;Криве;Криве;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622786705;3;1
62c3cd95-749b-11df-b112-00215aee3ebe;Криве;Криве;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624581602;3;1
62c3cd97-749b-11df-b112-00215aee3ebe;Криве;Криве;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824783001;3;1
62c3cd98-749b-11df-b112-00215aee3ebe;Криве Озеро;Кривое Озеро;місто;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823955100;2;0
f47541ed-3a8b-11e9-80dd-1c98ec135261;Криве Озеро Друге;Кривое Озеро Второе;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823981601;2;1
62c3cd99-749b-11df-b112-00215aee3ebe;Кривенкове;Кривенково;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921583003;3;1
62c3cd9a-749b-11df-b112-00215aee3ebe;Кривеньке;Кривенькое;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125585501;3;1
62c3cd9b-749b-11df-b112-00215aee3ebe;Кривець;Кривец;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620483801;3;1
62c3cd9c-749b-11df-b112-00215aee3ebe;Кривець;Кривец;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224284001;3;1
62c3cd9d-749b-11df-b112-00215aee3ebe;Кривець;Кривец;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123184001;3;1
62c3cd9e-749b-11df-b112-00215aee3ebe;Кривецьке;Кривецкое;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422080602;3;1
62c3cd9f-749b-11df-b112-00215aee3ebe;Кривий;Кривой;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125385203;3;1
62c3cda0-749b-11df-b112-00215aee3ebe;Кривий Ріг;Кривой Рог;місто;4ffc4b7d-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1211000000;1;0
62c3cda2-749b-11df-b112-00215aee3ebe;Кривин;Кривин;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823987300;3;1
62c3cda3-749b-11df-b112-00215aee3ebe;Кривиця;Кривица;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621888205;3;1
62c3cda4-749b-11df-b112-00215aee3ebe;Кривичі;Кривичи;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621884803;3;1
62c3cda5-749b-11df-b112-00215aee3ebe;Кривичі;Кривичи;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624682703;3;1
62c3cda6-749b-11df-b112-00215aee3ebe;Криві Коліна;Кривые Колена;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124084801;3;1
62c3cda7-749b-11df-b112-00215aee3ebe;Кривка;Кривка;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625584401;3;1
62c3cda8-749b-11df-b112-00215aee3ebe;Кривки;Кривки;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324280907;3;1
62c3cda9-749b-11df-b112-00215aee3ebe;Кривки;Кривки;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125055403;3;1
62c3cdaa-749b-11df-b112-00215aee3ebe;Кривлин;Кривлин;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722181205;3;1
62c3cdab-749b-11df-b112-00215aee3ebe;Кривня;Кривня;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624481102;3;1
62c3cdac-749b-11df-b112-00215aee3ebe;Кривобокове;Кривобоково;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224287805;3;1
62c3cdad-749b-11df-b112-00215aee3ebe;Кривоброди;Кривоброды;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623687605;3;1
62c3cdae-749b-11df-b112-00215aee3ebe;Кривовілька;Кривовилька;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824785003;3;1
62c3cdaf-749b-11df-b112-00215aee3ebe;Криволука;Криволука;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125586302;3;1
62c3cdb0-749b-11df-b112-00215aee3ebe;Криволука;Криволука;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822186904;3;1
62c3cdb1-749b-11df-b112-00215aee3ebe;Кривоносівка;Кривоносовка;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924483201;3;1
62c3cdb2-749b-11df-b112-00215aee3ebe;Кривоносівка;Кривоносовка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121586801;3;1
62c3cdb3-749b-11df-b112-00215aee3ebe;Кривоносове;Кривоносово;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221083306;3;1
62c3cdb4-749b-11df-b112-00215aee3ebe;Кривопілля;Кривополье;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620885001;3;1
62c3cdb5-749b-11df-b112-00215aee3ebe;Криворівня;Криворивня;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620885501;3;1
62c3cdb6-749b-11df-b112-00215aee3ebe;Криворіжжя;Криворожье;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824810101;3;1
62c3cdb9-749b-11df-b112-00215aee3ebe;Криворізьке;Криворожское;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220787706;3;1
62c3cdba-749b-11df-b112-00215aee3ebe;Криворудка;Криворудка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822785101;3;1
62c3cdbb-749b-11df-b112-00215aee3ebe;Криворучка;Криворучка;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083805;3;1
62c3cdbc-749b-11df-b112-00215aee3ebe;Криворучки;Криворучки;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755129;3;1
62c3cdbd-749b-11df-b112-00215aee3ebe;Кривотин;Кривотин;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821783201;3;1
62c3cdbe-749b-11df-b112-00215aee3ebe;Кривохижинці;Кривохижинцы;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522882502;3;1
62c3cdbf-749b-11df-b112-00215aee3ebe;Кривошиї;Кривошеи;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524882901;3;1
62c3cdc1-749b-11df-b112-00215aee3ebe;Кривошиїнці;Кривошеинцы;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224083201;2;1
62c3cdc2-749b-11df-b112-00215aee3ebe;Кривуха;Кривуха;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621682005;3;1
62c3cdc3-749b-11df-b112-00215aee3ebe;Кривуші;Кривуши;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322483805;3;1
62c3cdc4-749b-11df-b112-00215aee3ebe;Кривці;Кривцы;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324888207;3;1
62c3cdc6-749b-11df-b112-00215aee3ebe;Кривче;Кривче;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120883901;3;1
62c3cdc7-749b-11df-b112-00215aee3ebe;Кривчик;Кривчик;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821887605;3;1
c6cc1bff-db7e-11df-9197-00215aee3ebe;Кривчики;Кривчики;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122486002;3;1
62c3cdc8-749b-11df-b112-00215aee3ebe;Кривчунка;Кривчунка;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120983401;3;1
62c3cdca-749b-11df-b112-00215aee3ebe;Крижанівка;Крыжановка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125484403;3;1
62c3cdcb-749b-11df-b112-00215aee3ebe;Крижанівка;Крыжановка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122783201;2;0
62c3cdcc-749b-11df-b112-00215aee3ebe;Крижанівка;Крыжановка;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524883301;3;1
56bdd246-749b-11df-b112-00215aee3ebe;Крижанівка (Жовтневе);Крижанивка (Жовтневое);село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125485603;3;1
62c3cdcd-749b-11df-b112-00215aee3ebe;Крижик;Крыжик;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920683903;3;1
62c3cdce-749b-11df-b112-00215aee3ebe;Крижі;Крыжи;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123483801;3;1
62c3cdcf-749b-11df-b112-00215aee3ebe;Крижівка;Крыжовка;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724583502;3;1
62c3cdd0-749b-11df-b112-00215aee3ebe;Крижова;Крыжовая;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720882402;3;1
62c3cdd1-749b-11df-b112-00215aee3ebe;Крижовлин;Крыжовлин;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120688803;3;1
62c3cdd2-749b-11df-b112-00215aee3ebe;Крижопіль;Крыжополь;місто;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521955100;2;1
62c3cdd5-749b-11df-b112-00215aee3ebe;Криків;Криков;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825289404;2;1
62c3cdd6-749b-11df-b112-00215aee3ebe;Криківці;Криковцы;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523084401;3;1
62c3cdd7-749b-11df-b112-00215aee3ebe;Крикливець;Крикливец;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521984401;3;1
62c3cdd8-749b-11df-b112-00215aee3ebe;Крикунка;Крикунка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521785303;3;1
62c3cdd9-749b-11df-b112-00215aee3ebe;Крилів;Крылов;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623084701;3;1
62c3cdda-749b-11df-b112-00215aee3ebe;Крилів;Крылов;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621680803;3;1
62c3cddb-749b-11df-b112-00215aee3ebe;Крилівка;Крыловка;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820385601;3;1
62c3cddc-749b-11df-b112-00215aee3ebe;Крилівка;Крыловка;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825284201;3;1
62c3cddd-749b-11df-b112-00215aee3ebe;Крилівське;Крыловское;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322187204;3;1
62c3cde0-749b-11df-b112-00215aee3ebe;Крилос;Крылос;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621284801;3;1
62c3cde1-749b-11df-b112-00215aee3ebe;Кримка;Крымка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825482206;3;1
62c3cde5-749b-11df-b112-00215aee3ebe;Кримки;Крымки;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125783201;3;1
62c3cde6-749b-11df-b112-00215aee3ebe;Кримки;Крымки;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520582502;3;1
62c3cde7-749b-11df-b112-00215aee3ebe;Кримне;Крымно;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725082201;3;1
62c3cde8-749b-11df-b112-00215aee3ebe;Кримне;Крымно;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721480603;3;1
62c3cde9-749b-11df-b112-00215aee3ebe;Кримне;Крымно;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620886903;3;1
62c3cdea-749b-11df-b112-00215aee3ebe;Кримок;Крымок;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825084901;3;1
62c3cdeb-749b-11df-b112-00215aee3ebe;Кримпулька;Крымпулька;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125281905;3;1
62c3cdf1-749b-11df-b112-00215aee3ebe;Криниця;Криница;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124286501;3;1
62c3cdf2-749b-11df-b112-00215aee3ebe;Криниця;Криница;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623085201;3;1
62c3cdf3-749b-11df-b112-00215aee3ebe;Криниця;Криница;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423887005;3;1
62c3cdf4-749b-11df-b112-00215aee3ebe;Криниця;Криница;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824286706;3;1
62c3cdf5-749b-11df-b112-00215aee3ebe;Криниця;Криница;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322610101;3;1
62c3cdf6-749b-11df-b112-00215aee3ebe;Криничани;Крынычаны;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821855701;3;1
62c3cdf7-749b-11df-b112-00215aee3ebe;Криничанка;Криничанка;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520985004;3;1
62c3cdf8-749b-11df-b112-00215aee3ebe;Кринички;Кринички;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;;3;1
62c3cdf9-749b-11df-b112-00215aee3ebe;Кринички;Кринички;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221084104;3;1
62c3cdfa-749b-11df-b112-00215aee3ebe;Кринички;Кринички;місто;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055100;2;1
62c3cdfb-749b-11df-b112-00215aee3ebe;Кринички;Кринички;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225080511;3;1
62c3cdfc-749b-11df-b112-00215aee3ebe;Кринички;Кринички;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282512;3;1
62c3cdfd-749b-11df-b112-00215aee3ebe;Кринички;Кринички;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824284603;3;1
62c3cdfe-749b-11df-b112-00215aee3ebe;Кринички;Кринички;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325158802;3;1
62c3cdff-749b-11df-b112-00215aee3ebe;Кринички;Кринички;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120689502;3;1
62c3ce00-749b-11df-b112-00215aee3ebe;Кринички;Кринички;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283603;3;1
62c3ce01-749b-11df-b112-00215aee3ebe;Кринички;Кринички;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623286403;3;1
62c3ce05-749b-11df-b112-00215aee3ebe;Кринички;Кринички;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424787003;3;1
62c3ce06-749b-11df-b112-00215aee3ebe;Кринички;Кринички;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521980205;3;1
62c3ce07-749b-11df-b112-00215aee3ebe;Кринички;Кринички;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523686502;3;1
62c3ce08-749b-11df-b112-00215aee3ebe;Кринички;Кринички;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621283601;3;1
44ac8779-749b-11df-b112-00215aee3ebe;Кринички;Кринички;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822087903;3;1
62c3ce0a-749b-11df-b112-00215aee3ebe;Криничне;Криничное;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5321883804;3;1
62c3ce0b-749b-11df-b112-00215aee3ebe;Криничне;Криничное;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925083901;3;1
62c3ce0c-749b-11df-b112-00215aee3ebe;Криничне;Криничное;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723655405;3;1
62c3ce0d-749b-11df-b112-00215aee3ebe;Криничне;Криничное;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320284002;3;1
62c3ce0e-749b-11df-b112-00215aee3ebe;Криничне;Криничное;село;6b07f8a2-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5121484701;3;1
62c3ce0f-749b-11df-b112-00215aee3ebe;Криничне;Криничное;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321584008;3;1
62c3ce15-749b-11df-b112-00215aee3ebe;Криничне;Криничное;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525886701;3;1
62c3ce16-749b-11df-b112-00215aee3ebe;Криничувате;Криничеватое;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282513;3;1
62c3ce17-749b-11df-b112-00215aee3ebe;Криничувате;Криничеватое;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222982801;3;1
62c3ce18-749b-11df-b112-00215aee3ebe;Криничувате;Криничеватое;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522883804;3;1
62c3ce19-749b-11df-b112-00215aee3ebe;Криничуватка;Криничеватка;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525885601;3;1
62c3ce1a-749b-11df-b112-00215aee3ebe;Кринівка;Криновка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323686606;3;1
62c3ce1c-749b-11df-b112-00215aee3ebe;Кринтята;Кринтята;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625582603;3;1
62c3ce1d-749b-11df-b112-00215aee3ebe;Кринцілів;Крынцилов;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821282502;3;1
62c3ce1e-749b-11df-b112-00215aee3ebe;Крисине;Крысино;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320885001;3;1
62c3ce1f-749b-11df-b112-00215aee3ebe;Криски;Крыски;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422284001;3;1
62c3ce20-749b-11df-b112-00215aee3ebe;Крисовичі;Крысовичи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622483901;3;1
62c3ce22-749b-11df-b112-00215aee3ebe;Крите;Крытое;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122786604;3;1
62c3ce23-749b-11df-b112-00215aee3ebe;Криульки;Криульки;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424780505;3;1
62c3ce24-749b-11df-b112-00215aee3ebe;Крихівці;Криховци;село;a1e9f9b8-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2610192001;1;0
62c3ce25-749b-11df-b112-00215aee3ebe;Кричанівка;Кричановка;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522683700;3;1
62c3ce26-749b-11df-b112-00215aee3ebe;Кричевичі;Кричевичи;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722183604;3;1
62c3ce27-749b-11df-b112-00215aee3ebe;Кричильськ;Кричильск;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625483401;3;1
62c3ce28-749b-11df-b112-00215aee3ebe;Кричка;Кричка;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620484001;3;1
62c3ce29-749b-11df-b112-00215aee3ebe;Кричово;Кричево;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124483601;3;1
62c3ce2a-749b-11df-b112-00215aee3ebe;Кричунове;Кричуново;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123382601;3;1
62c3ce2b-749b-11df-b112-00215aee3ebe;Криштопівка;Крыштоповка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320683501;3;1
62c3ce2c-749b-11df-b112-00215aee3ebe;Криштопівка;Крыштоповка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120685601;3;1
62c3ce2d-749b-11df-b112-00215aee3ebe;Криштопівка;Крыштоповка;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521284003;3;1
62c3ce2e-749b-11df-b112-00215aee3ebe;Криштопівка;Крыштоповка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820983801;3;1
62c3ce21-749b-11df-b112-00215aee3ebe;Криштопівське;Криштопольское;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521284009;3;1
cdc44f31-dde8-11df-9197-00215aee3ebe;Криштофівка;Криштофовка;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522681403;3;1
62c3ce2f-749b-11df-b112-00215aee3ebe;Крищинці;Крищинцы;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524383801;3;1
62c3ce32-749b-11df-b112-00215aee3ebe;Кроватка;Кроватка;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724582909;3;1
62c3ce33-749b-11df-b112-00215aee3ebe;Кровинка;Кровинка;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125084801;3;1
62c3ce34-749b-11df-b112-00215aee3ebe;Кровне;Кровное;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924784201;3;1
62c3ce35-749b-11df-b112-00215aee3ebe;Крогулець;Крогулец;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121683601;3;1
62c3ce36-749b-11df-b112-00215aee3ebe;Кроква;Кроква;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322087602;3;1
62c3ce37-749b-11df-b112-00215aee3ebe;Кролевець;Кролевец;місто;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922610100;2;0
62c3ce38-749b-11df-b112-00215aee3ebe;Кролевець-Слободка;Кролевець-Слободка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423689803;3;1
62c3ce39-749b-11df-b112-00215aee3ebe;Кролівка;Кролевка;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124781403;3;1
62c3ce3a-749b-11df-b112-00215aee3ebe;Кропи;Кропы;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783908;3;1
62c3ce3b-749b-11df-b112-00215aee3ebe;Кропивенка;Кропивенка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821184007;3;1
62c3ce3c-749b-11df-b112-00215aee3ebe;Кропивинці;Кропивинцы;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924185803;3;1
62c3ce3d-749b-11df-b112-00215aee3ebe;Кропивище;Крапивище;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623285403;3;1
62c3ce3e-749b-11df-b112-00215aee3ebe;Кропивна;Кропивна;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121586601;3;1
62c3ce3f-749b-11df-b112-00215aee3ebe;Кропивна;Кропивна;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524883101;3;1
62c3ce40-749b-11df-b112-00215aee3ebe;Кропивна;Кропивна;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822186603;3;1
62c3ce51-749b-11df-b112-00215aee3ebe;Кропивна;Кропивна;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621883206;3;1
62c3ce41-749b-11df-b112-00215aee3ebe;Кропивне;Крапивное;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420384001;3;1
62c3ce42-749b-11df-b112-00215aee3ebe;Кропивне;Крапивное;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423383202;3;1
62c3ce43-749b-11df-b112-00215aee3ebe;Кропивник;Крапивник;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622884001;3;1
62c3ce44-749b-11df-b112-00215aee3ebe;Кропивник;Крапивник;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622082601;3;1
62c3ce45-749b-11df-b112-00215aee3ebe;Кропивник;Крапивник;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625182903;3;1
62c3ce46-749b-11df-b112-00215aee3ebe;Кропивники;Крапивники;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725755104;3;1
62c3ce48-749b-11df-b112-00215aee3ebe;Кропивницьке (Село);Кропивницкое (Посёлок);село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524082801;3;1
5cb61730-749b-11df-b112-00215aee3ebe;Кропивницький;Кропивницкий;місто;0c6de8b4-41ba-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3510100000;1;0
62c3ce49-749b-11df-b112-00215aee3ebe;Кропивня;Крапивня;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222081601;3;1
62c3ce4a-749b-11df-b112-00215aee3ebe;Кропивня;Крапивня;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822385403;3;1
62c3ce4b-749b-11df-b112-00215aee3ebe;Кропивня;Крапивня;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824080402;3;1
62c3ce4c-749b-11df-b112-00215aee3ebe;Кропивня;Крапивня;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821183001;3;1
62c3ce4d-749b-11df-b112-00215aee3ebe;Кропивня;Крапивня;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510120;3;1
62c3ce4e-749b-11df-b112-00215aee3ebe;Кропивня;Крапивня;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525380504;3;1
62c3ce4f-749b-11df-b112-00215aee3ebe;Кропивщина;Крапивщина;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721181202;3;1
62c3ce50-749b-11df-b112-00215aee3ebe;Кропильники;Кропильники;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622482804;3;1
62c3ce54-749b-11df-b112-00215aee3ebe;Кроти;Кроты;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424184002;3;1
62c3ce55-749b-11df-b112-00215aee3ebe;Кроти;Кроты;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323882203;3;1
62c3ce56-749b-11df-b112-00215aee3ebe;Кротівка;Кротовка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655111;3;1
62c3ce57-749b-11df-b112-00215aee3ebe;Кротівщина;Кротовщина;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320282201;3;1
62c3ce58-749b-11df-b112-00215aee3ebe;Кротошин;Кротошин;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623683501;3;1
62c3ce5a-749b-11df-b112-00215aee3ebe;Крохмальці;Крахмальцы;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281908;3;1
62c3ce5b-749b-11df-b112-00215aee3ebe;Кругель;Кругель;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155704;3;1
62c3ce5c-749b-11df-b112-00215aee3ebe;Круги;Круги;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221887002;3;1
62c3ce5d-749b-11df-b112-00215aee3ebe;Круги;Круги;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524583405;3;1
62c3cea8-749b-11df-b112-00215aee3ebe;Кругів;Кругов;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621883603;3;1
62c3ce5f-749b-11df-b112-00215aee3ebe;Кругле;Круглое;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321384004;3;1
62c3ce62-749b-11df-b112-00215aee3ebe;Кругле;Круглое;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425584603;3;1
62c3ce63-749b-11df-b112-00215aee3ebe;Кругле;Круглое;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620881203;3;1
62c3ce64-749b-11df-b112-00215aee3ebe;Кругле;Круглое;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624683003;3;0
62c3ce65-749b-11df-b112-00215aee3ebe;Кругле Озеро;Круглое Озеро;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320484103;3;1
62c3ce66-749b-11df-b112-00215aee3ebe;Круглий;Круглый;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123682502;3;1
62c3ce67-749b-11df-b112-00215aee3ebe;Круглик;Круглик;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325084401;3;1
62c3ce68-749b-11df-b112-00215aee3ebe;Круглик;Круглик;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321282005;3;1
62c3ce69-749b-11df-b112-00215aee3ebe;Круглик;Круглик;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321586805;3;1
62c3ce6d-749b-11df-b112-00215aee3ebe;Круглик;Круглик;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222487203;2;0
62c3ce6e-749b-11df-b112-00215aee3ebe;Круглик;Круглик;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825587002;3;1
62c3ce6f-749b-11df-b112-00215aee3ebe;Круглик;Круглик;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320488204;3;1
62c3ce70-749b-11df-b112-00215aee3ebe;Круглики;Круглики;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824288303;3;1
62c3ce71-749b-11df-b112-00215aee3ebe;Круглолугівка;Круглолуговка;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;;3;1
62c3ce74-749b-11df-b112-00215aee3ebe;Круглянка;Круглянка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324280503;3;1
62c3ce75-749b-11df-b112-00215aee3ebe;Круголець;Круголец;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125884402;3;1
62c3ce76-749b-11df-b112-00215aee3ebe;Кружики;Кружики;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624283003;3;1
62c3ce78-749b-11df-b112-00215aee3ebe;Кружківці;Кружковци;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823387003;3;1
62c3ce79-749b-11df-b112-00215aee3ebe;Кружок;Кружок;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923880703;3;1
62c3ce7a-749b-11df-b112-00215aee3ebe;Крук;Крук;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821184303;3;1
62c3ce7b-749b-11df-b112-00215aee3ebe;Крукеничі;Крукеничи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622483601;3;1
62c3ce7c-749b-11df-b112-00215aee3ebe;Круки;Круки;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625886909;3;1
62c3ce7d-749b-11df-b112-00215aee3ebe;Круковець;Круковец;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624286604;3;1
62c3ce91-749b-11df-b112-00215aee3ebe;Круп'янське;Крупянское;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320780803;3;1
62c3ce7e-749b-11df-b112-00215aee3ebe;Крупа;Крупа;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722884802;3;1
62c3ce7f-749b-11df-b112-00215aee3ebe;Крупець;Крупец;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925384902;3;1
62c3ce80-749b-11df-b112-00215aee3ebe;Крупець;Крупец;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823984001;3;1
62c3ce81-749b-11df-b112-00215aee3ebe;Крупець;Крупец;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625884801;3;1
62c3ce82-749b-11df-b112-00215aee3ebe;Крупин;Крупин;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524880302;3;1
62c3ce83-749b-11df-b112-00215aee3ebe;Крупиця;Крупица;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520688202;3;1
62c3ce84-749b-11df-b112-00215aee3ebe;Крупичполе;Крупичполе;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421786001;3;1
62c3ce85-749b-11df-b112-00215aee3ebe;Крупове;Крупово;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621885402;3;1
62c3ce86-749b-11df-b112-00215aee3ebe;Круподеринці;Круподеринцы;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523484002;3;1
62c3ce87-749b-11df-b112-00215aee3ebe;Круподеринці;Круподеринцы;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323682201;3;1
62c3ce89-749b-11df-b112-00215aee3ebe;Крупське;Крупское;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623085401;3;1
62c3ce8a-749b-11df-b112-00215aee3ebe;Крупське;Крупское;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823487603;3;1
62c3ce8b-749b-11df-b112-00215aee3ebe;Крупське;Крупское;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121587001;3;1
62c3ce8c-749b-11df-b112-00215aee3ebe;Крупське;Крупское;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523883603;3;1
7b6c22b6-3081-11e1-b44a-003048d2b473;Крупське;Крупское;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;00000000-0000-0000-0000-000000000000;;;1
62c3ce8f-749b-11df-b112-00215aee3ebe;Крупчатка;Крупчатка;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822855102;3;1
62c3ce90-749b-11df-b112-00215aee3ebe;Крупчине;Крупчино;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320887504;3;1
62c3ce92-749b-11df-b112-00215aee3ebe;Крута Балка;Крутая Балка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323481801;3;1
62c3ce93-749b-11df-b112-00215aee3ebe;Крута Балка;Крутая Балка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321281003;3;1
81d150f5-8351-11e8-80d4-1c98ec135261;Крута Балка;Крутая Балка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055714;3;1
62c3ce96-749b-11df-b112-00215aee3ebe;Крута Долина;Крутая Долина;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784403;3;1
62c3ce97-749b-11df-b112-00215aee3ebe;Круте;Крутое;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;;3;1
62c3ce98-749b-11df-b112-00215aee3ebe;Круте;Крутое;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225085503;3;1
62c3ce99-749b-11df-b112-00215aee3ebe;Крутеньке;Крутенькое;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220786613;3;1
62c3ce9b-749b-11df-b112-00215aee3ebe;Крутеньке;Крутенькое;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521483901;3;1
5cb61711-749b-11df-b112-00215aee3ebe;Крутеньке (Кірове);Крутенькое (Кирово);село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455116;3;1
62c3ce9c-749b-11df-b112-00215aee3ebe;Крутеньки;Крутеньки;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325084801;3;1
62c3ce9d-749b-11df-b112-00215aee3ebe;Крути;Круты;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822081802;3;1
62c3ce9e-749b-11df-b112-00215aee3ebe;Крути;Круты;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423385201;3;1
f9333454-db4e-11df-9197-00215aee3ebe;Крути;Круты;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;;3;1
62c3ce9f-749b-11df-b112-00215aee3ebe;Крутий Берег;Крутой Берег;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222986902;3;1
62c3cea0-749b-11df-b112-00215aee3ebe;Крутий Берег;Крутой Берег;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322887402;3;1
62c3cea1-749b-11df-b112-00215aee3ebe;Крутий Яр;Крутой Яр;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321583003;3;1
62c3cea2-749b-11df-b112-00215aee3ebe;Крутий Яр;Крутой Яр;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520387502;3;1
62c3cea4-749b-11df-b112-00215aee3ebe;Крутилів;Крутилов;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121681803;3;1
62c3cea5-749b-11df-b112-00215aee3ebe;Круті;Крутые;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122583001;3;1
62c3cea6-749b-11df-b112-00215aee3ebe;Круті Броди;Крутые Броды;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825881202;3;1
62c3cea7-749b-11df-b112-00215aee3ebe;Круті Горби;Крутые Горбы;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224482801;3;1
8b9e27d5-323e-11e1-b44a-003048d2b473;Крутів ;Крутів ;;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;;3;1
62c3cea9-749b-11df-b112-00215aee3ebe;Крутнів;Крутнев;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123484403;3;1
62c3ceaa-749b-11df-b112-00215aee3ebe;Крутнів;Крутнев;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524883703;3;1
62c3ceab-749b-11df-b112-00215aee3ebe;Крутогорб;Крутогорб;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520883609;3;1
62c3ceac-749b-11df-b112-00215aee3ebe;Крутоярівка;Крутояровка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222683302;3;1
62c3cead-749b-11df-b112-00215aee3ebe;Крутоярівка;Крутояровка;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323184502;3;1
62c3ceae-749b-11df-b112-00215aee3ebe;Крутоярівка;Крутояровка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120886804;3;1
62c3ceaf-749b-11df-b112-00215aee3ebe;Крутоярівка;Крутояровка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424184501;3;1
62c3ceb0-749b-11df-b112-00215aee3ebe;Крутоярка;Крутоярка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220787707;3;1
62c3ceb1-749b-11df-b112-00215aee3ebe;Крутоярка;Крутоярка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823084204;3;1
62c3ceb2-749b-11df-b112-00215aee3ebe;Крутоярка;Крутоярка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820982402;3;1
62c3ceb3-749b-11df-b112-00215aee3ebe;Крутоярка;Крутоярка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520882802;3;1
62c3ceb4-749b-11df-b112-00215aee3ebe;Крутьки;Крутьки;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125184401;3;1
62c3ceb5-749b-11df-b112-00215aee3ebe;Крухиничі;Крухиничи;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722484001;3;1
62c3ceb6-749b-11df-b112-00215aee3ebe;Круча;Круча;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824281002;3;1
62c3ceb7-749b-11df-b112-00215aee3ebe;Крученець;Крученец;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825685003;3;1
62c3ceb8-749b-11df-b112-00215aee3ebe;Кручик;Кручик;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320886002;3;1
62c3ceb9-749b-11df-b112-00215aee3ebe;Крушанівка;Крушановка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822484501;3;1
62c3ceba-749b-11df-b112-00215aee3ebe;Крушельниця;Крушельница;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624583801;3;1
62c3cebb-749b-11df-b112-00215aee3ebe;Крушинець;Крушинец;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723355306;3;1
62c3cebc-749b-11df-b112-00215aee3ebe;Крушинівка;Крушиновка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520483006;3;1
62c3cebd-749b-11df-b112-00215aee3ebe;Крушинка;Крушинка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221484001;3;1
62c3cebe-749b-11df-b112-00215aee3ebe;Крушники;Крушники;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823488602;3;1
62c3cebf-749b-11df-b112-00215aee3ebe;Крюківщина;Крюковщина;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222484001;2;0
62c3cec0-749b-11df-b112-00215aee3ebe;Крюкове;Крюково;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324084008;3;1
b36f1a45-db87-11df-9197-00215aee3ebe;Крюкове;Крюково;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324586503;3;1
62c3cec1-749b-11df-b112-00215aee3ebe;Крючки;Крючки;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320286203;3;1
62c3cec2-749b-11df-b112-00215aee3ebe;Кряжове;Кряжевое;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885706;3;1
62c3cec4-749b-11df-b112-00215aee3ebe;Крячки;Крячки;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221487004;3;1
62c3cec5-749b-11df-b112-00215aee3ebe;Крячківка;Крячковка;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323880705;3;1
62c3cec6-749b-11df-b112-00215aee3ebe;Ксаверів;Ксаверов;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823484601;3;1
62c3cec7-749b-11df-b112-00215aee3ebe;Ксаверівка;Ксаверовка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221484301;3;1
62c3cec8-749b-11df-b112-00215aee3ebe;Ксаверівка;Ксаверовка;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825685001;3;1
62c3cec9-749b-11df-b112-00215aee3ebe;Ксаверівка;Ксаверовка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522280705;3;1
62c3ceca-749b-11df-b112-00215aee3ebe;Ксаверівка;Ксаверовка;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520688902;3;1
62c3cecb-749b-11df-b112-00215aee3ebe;Ксаверівка;Ксаверовка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821882403;3;1
62c3cecc-749b-11df-b112-00215aee3ebe;Ксаверівка Друга;Ксаверовка Вторая;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221484303;3;1
62c3cecd-749b-11df-b112-00215aee3ebe;Ксаверове;Ксаверово;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120384501;3;1
62c3cecf-749b-11df-b112-00215aee3ebe;Кубаївка;Кубаевка;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624086002;3;1
62c3ced0-749b-11df-b112-00215aee3ebe;Кубанка;Кубанка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122782604;3;1
62c3ced3-749b-11df-b112-00215aee3ebe;Кубареве;Кубарево;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923881903;3;1
62c3ced4-749b-11df-b112-00215aee3ebe;Кубахове;Кубахово;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922686305;3;1
62c3ced5-749b-11df-b112-00215aee3ebe;Кублич;Кублич;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523787007;3;1
62c3ced6-749b-11df-b112-00215aee3ebe;Кубракове;Кубраково;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920983602;3;1
62c3ced7-749b-11df-b112-00215aee3ebe;Кубряки;Кубряки;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821781201;3;1
62c3ced8-749b-11df-b112-00215aee3ebe;Кувечічі;Кувечичи;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425584101;3;1
62c3ced9-749b-11df-b112-00215aee3ebe;Кугаїв;Кугаев;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623681805;3;1
62c3ceda-749b-11df-b112-00215aee3ebe;Кугаївці;Кугаевцы;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825285001;2;1
62c3cedb-749b-11df-b112-00215aee3ebe;Кугуки;Кугуки;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422489203;3;1
62c3cedc-749b-11df-b112-00215aee3ebe;Куданівка;Кудановка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5910590001;3;1
62c3cedd-749b-11df-b112-00215aee3ebe;Кударі;Кудари;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920385806;3;1
62c3cede-749b-11df-b112-00215aee3ebe;Кудашеве;Кудашево;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125481002;3;1
62c3cedf-749b-11df-b112-00215aee3ebe;Кудашівка;Кудашевка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222083501;3;1
62c3cee0-749b-11df-b112-00215aee3ebe;Кудашівка;Кудашевка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221882904;3;1
62c3cee1-749b-11df-b112-00215aee3ebe;Кудинів Ліс;Кудинов Лес;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123410101;3;1
62c3cee2-749b-11df-b112-00215aee3ebe;Кудинівці;Кудиновцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122680702;3;1
62c3cee3-749b-11df-b112-00215aee3ebe;Кудинка;Кудинка;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823083301;3;1
62c3cee4-749b-11df-b112-00215aee3ebe;Кудирявці;Кудырявцы;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620684003;3;1
62c3cee5-749b-11df-b112-00215aee3ebe;Кудіївка;Кудиевка;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322082507;3;1
62c3cee6-749b-11df-b112-00215aee3ebe;Кудіївці;Кудиевцы;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521080206;3;1
62c3cee7-749b-11df-b112-00215aee3ebe;Кудлаї;Кудлаи;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523084601;3;1
62c3cee8-749b-11df-b112-00215aee3ebe;Кудлаївка;Кудлаевка;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125885903;3;1
62c3cee9-749b-11df-b112-00215aee3ebe;Кудлаївка;Кудлаевка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423685401;3;1
62c3ceea-749b-11df-b112-00215aee3ebe;Кудлатівка;Кудлатовка;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622885102;3;1
62c3ceeb-749b-11df-b112-00215aee3ebe;Кудобинці;Кудобинцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122686005;3;1
62c3ceec-749b-11df-b112-00215aee3ebe;Кудоярове;Кудоярово;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924481703;3;1
62c3ceee-749b-11df-b112-00215aee3ebe;Кудринці;Кудринцы;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
62c3ceef-749b-11df-b112-00215aee3ebe;Кудринці;Кудринцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825084202;3;1
62c3cef0-749b-11df-b112-00215aee3ebe;Кудринці;Кудринцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822482903;3;1
62c3cef1-749b-11df-b112-00215aee3ebe;Кудрівка;Кудровка;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424985001;3;1
62c3cef2-749b-11df-b112-00215aee3ebe;Кудряве;Кудрявое;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920381204;3;1
62c3cef4-749b-11df-b112-00215aee3ebe;Кудрявка;Кудрявка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121286103;3;1
62c3cef5-749b-11df-b112-00215aee3ebe;Кудрявське;Кудрявское;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821482203;3;1
62c3cef8-749b-11df-b112-00215aee3ebe;Куземин;Куземин;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920385001;3;1
62c3cefc-749b-11df-b112-00215aee3ebe;Кузнецове;Кузнецово;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822784604;3;1
62c3cf00-749b-11df-b112-00215aee3ebe;Кузничі;Кузничи;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421485351;3;1
62c3cf01-749b-11df-b112-00215aee3ebe;Кузубатиця;Кузубатица;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623388303;3;1
62c3cf02-749b-11df-b112-00215aee3ebe;Кузубівка;Кузубовка;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322881104;3;1
62c3cf03-749b-11df-b112-00215aee3ebe;Кузьки;Кузьки;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922085001;3;1
62c3cf04-749b-11df-b112-00215aee3ebe;Кузьменка;Кузьменка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123980505;3;1
62c3cf05-749b-11df-b112-00215aee3ebe;Кузьменки;Кузьменки;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281409;3;1
62c3cf06-749b-11df-b112-00215aee3ebe;Кузьменки;Кузьменки;село;d00d3b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5310290313;3;1
62c3cf07-749b-11df-b112-00215aee3ebe;Кузьменки;Кузьменки;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323285202;3;1
62c3cf08-749b-11df-b112-00215aee3ebe;Кузьменкове;Кузьменково;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187105;3;1
62c3cf09-749b-11df-b112-00215aee3ebe;Кузьмин;Кузьмин;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822785501;3;1
62c3cf0a-749b-11df-b112-00215aee3ebe;Кузьмин;Кузьмин;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821283601;3;1
62c3cf0b-749b-11df-b112-00215aee3ebe;Кузьмина Гребля;Кузьмина Гребля;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124683001;3;1
62c3cf0e-749b-11df-b112-00215aee3ebe;Кузьминець;Кузьминец;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624884103;3;1
62c3cf0f-749b-11df-b112-00215aee3ebe;Кузьминівка;Кузьминовка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324884504;3;1
62c3cf0c-749b-11df-b112-00215aee3ebe;Кузьмино;Кузьмино;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122783402;3;1
62c3cf11-749b-11df-b112-00215aee3ebe;Кузьминське;Кузьминское;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423688104;3;1
62c3cf12-749b-11df-b112-00215aee3ebe;Кузьминці;Кузьминцы;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222284401;3;1
62c3cf13-749b-11df-b112-00215aee3ebe;Кузьминці;Кузьминцы;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520282403;3;1
62c3cf14-749b-11df-b112-00215aee3ebe;Кузьминці;Кузьминцы;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520883304;3;1
62c3cf15-749b-11df-b112-00215aee3ebe;Кузьминці;Кузьминцы;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824786003;3;1
62c3cf16-749b-11df-b112-00215aee3ebe;Кузьминчик;Кузьминчик;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825286202;2;1
62c3cf17-749b-11df-b112-00215aee3ebe;Кузьмівка;Кузьмовка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321282006;3;1
62c3cf18-749b-11df-b112-00215aee3ebe;Кузьмівка;Кузьмовка;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625483901;3;1
62c3cf1a-749b-11df-b112-00215aee3ebe;Куйбишеве;Куйбышево;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;*482578250;3;1
62c3cf1e-749b-11df-b112-00215aee3ebe;Куйбишеве;Куйбышево;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520682405;3;1
62c3cf21-749b-11df-b112-00215aee3ebe;Куйбишеве;Куйбышево;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221282007;3;1
62c3cf25-749b-11df-b112-00215aee3ebe;Куйбишевка;Куйбышевка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822780904;3;1
62c3cf27-749b-11df-b112-00215aee3ebe;Кукавка;Кукавка;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522884603;3;1
62c3cf28-749b-11df-b112-00215aee3ebe;Кукавка;Кукавка;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522684001;2;1
62c3cf29-749b-11df-b112-00215aee3ebe;Кукарі;Кукари;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424456005;3;1
62c3cf2a-749b-11df-b112-00215aee3ebe;Кукезів;Кукезов;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622186202;3;1
62c3cf2b-749b-11df-b112-00215aee3ebe;Кукільники;Кукольники;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621255305;3;1
62c3cf2c-749b-11df-b112-00215aee3ebe;Кукільня;Кукильня;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820884202;3;1
62c3cf2d-749b-11df-b112-00215aee3ebe;Кукли;Куклы;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723683601;3;1
62c3cf2e-749b-11df-b112-00215aee3ebe;Куклинці;Куклинцы;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083205;3;1
cdc44f5a-dde8-11df-9197-00215aee3ebe;Кукля;Кукля;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;;3;1
62c3cf2f-749b-11df-b112-00215aee3ebe;Кукоби;Кукобы;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324581503;3;1
62c3cf30-749b-11df-b112-00215aee3ebe;Кукобівка;Кукобовка;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281401;3;1
62c3cf31-749b-11df-b112-00215aee3ebe;Куковицьке;Куковичское;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423085002;3;1
62c3cf32-749b-11df-b112-00215aee3ebe;Куковичі;Куковичи;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423085001;3;1
62c3cf33-749b-11df-b112-00215aee3ebe;Куколівка;Куколовка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520384401;3;1
62c3cf34-749b-11df-b112-00215aee3ebe;Кукули;Кукулы;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523281601;3;1
62c3cf35-749b-11df-b112-00215aee3ebe;Кукулівка;Кукулевка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321780508;3;1
62c3cf36-749b-11df-b112-00215aee3ebe;Кукурівка;Кукуровка;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522882802;3;1
62c3cf37-749b-11df-b112-00215aee3ebe;Кукуріки;Кукурики;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725085004;3;1
62c3cf3a-749b-11df-b112-00215aee3ebe;Кукшин;Кукшин;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423385601;3;1
62c3cf3c-749b-11df-b112-00215aee3ebe;Кулажинці;Кулажинцы;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221284501;3;1
62c3cf3d-749b-11df-b112-00215aee3ebe;Кулажинці;Кулажинцы;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320882101;3;1
62c3cf3e-749b-11df-b112-00215aee3ebe;Кулаківці;Кулаковцы;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122085501;3;1
62c3cf3f-749b-11df-b112-00215aee3ebe;Кулачківці;Кулачковцы;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625283301;3;1
62c3cf40-749b-11df-b112-00215aee3ebe;Кулевча;Кулевча;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124581730;3;1
62c3cf41-749b-11df-b112-00215aee3ebe;Кулешів;Кулешов;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222983602;3;1
62c3cf42-749b-11df-b112-00215aee3ebe;Кулешів;Кулешов;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523487803;3;1
62c3cf43-749b-11df-b112-00215aee3ebe;Кулига;Кулыга;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522483601;3;1
62c3cf44-749b-11df-b112-00215aee3ebe;Кулики;Кулики;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324886205;3;1
62c3cf45-749b-11df-b112-00215aee3ebe;Кулики;Кулики;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922985202;3;1
62c3cf46-749b-11df-b112-00215aee3ebe;Куликів;Куликов;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123482303;3;1
62c3cf47-749b-11df-b112-00215aee3ebe;Куликів;Куликов;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623981901;3;1
62c3cf48-749b-11df-b112-00215aee3ebe;Куликів;Куликов;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622755600;3;0
62c3cf49-749b-11df-b112-00215aee3ebe;Куликівка;Куликовка;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320782501;3;1
62c3cf4c-749b-11df-b112-00215aee3ebe;Куликівка;Куликовка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421485401;3;1
62c3cf4d-749b-11df-b112-00215aee3ebe;Куликівка;Куликовка;місто;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422755100;3;1
62c3cf4e-749b-11df-b112-00215aee3ebe;Куликівка;Куликовка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421787604;3;1
62c3cf4f-749b-11df-b112-00215aee3ebe;Куликівка;Куликовка;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121883003;3;1
62c3cf51-749b-11df-b112-00215aee3ebe;Куликова Балка;Куликова Балка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524085203;3;1
62c3cf54-749b-11df-b112-00215aee3ebe;Куликовичі;Куликовичи;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723683801;3;1
62c3cf55-749b-11df-b112-00215aee3ebe;Кулиничі;Кулиничи;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324884208;3;1
62c3cf56-749b-11df-b112-00215aee3ebe;Кулиничі;Кулиничи;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157300;2;0
62c3cf57-749b-11df-b112-00215aee3ebe;Кулиничі;Кулиничи;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622780804;3;1
62c3cf58-749b-11df-b112-00215aee3ebe;Кулинка;Кулинка;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622880809;3;1
62c3cf59-749b-11df-b112-00215aee3ebe;Куличка;Куличка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922981205;3;1
62c3cf5b-749b-11df-b112-00215aee3ebe;Куличків;Куличков;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624810602;3;1
62c3cf5c-749b-11df-b112-00215aee3ebe;Кулишівка;Кулишовка;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421182002;3;1
62c3cf5d-749b-11df-b112-00215aee3ebe;Кулі;Кули;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223187202;3;1
62c3cf5e-749b-11df-b112-00215aee3ebe;Кулібаба;Кулибаба;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221482804;3;1
62c3cf5f-749b-11df-b112-00215aee3ebe;Кулівці;Кулевцы;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321585501;3;1
ebc3cb70-835b-11e8-80d4-1c98ec135261;Кулішеве;Кулешеве;село;bad3ac3c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1223755101;3;1
62c3cf60-749b-11df-b112-00215aee3ebe;Куліші;Кулеши;село;bad3ac3c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1223781407;3;1
62c3cf61-749b-11df-b112-00215aee3ebe;Куліші;Кулеши;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821783401;3;1
62c3cf62-749b-11df-b112-00215aee3ebe;Кулішівка;Кулешовка;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923583601;3;1
62c3cf63-749b-11df-b112-00215aee3ebe;Кулішівка;Кулешовка;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324085201;3;1
62c3cf64-749b-11df-b112-00215aee3ebe;Кулішівка;Кулешовка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822588405;3;1
62c3cf65-749b-11df-b112-00215aee3ebe;Кулішове;Кулешово;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420381504;3;1
62c3cf66-749b-11df-b112-00215aee3ebe;Кулябівка;Кулябовка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225582701;3;1
62c3cf67-749b-11df-b112-00215aee3ebe;Кулябкине;Кулябкино;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224555301;3;1
62c3cf68-749b-11df-b112-00215aee3ebe;Кулява;Кулява;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784501;3;1
cb6524f7-7ce4-11e2-ade3-003048d2b473;Кульбашне;Кульбашне;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320282603;3;1
62c3cf69-749b-11df-b112-00215aee3ebe;Кульби;Кульбы;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621855603;3;1
62c3cf6a-749b-11df-b112-00215aee3ebe;Кульматичі;Кульматычи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622482008;3;1
62c3cf6b-749b-11df-b112-00215aee3ebe;Культура;Культура;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224581003;3;1
62c3cf6d-749b-11df-b112-00215aee3ebe;Кульчин;Кульчин;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725555102;3;1
62c3cf6e-749b-11df-b112-00215aee3ebe;Кульчин;Кульчин;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721882703;3;1
62c3cf6f-749b-11df-b112-00215aee3ebe;Кульчини;Кульчины;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822785801;3;1
62c3cf70-749b-11df-b112-00215aee3ebe;Кульчинки;Кульчинки;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822786001;3;1
62c3cf71-749b-11df-b112-00215aee3ebe;Кульчиці;Кульчицы;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624283701;3;1
62c3cf72-749b-11df-b112-00215aee3ebe;Кульчіївці;Кульчиевцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822484801;3;1
62c3cf73-749b-11df-b112-00215aee3ebe;Куманів;Куманов;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821288604;3;1
62c3cf74-749b-11df-b112-00215aee3ebe;Куманівка;Куманевка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521483603;3;1
62c3cf75-749b-11df-b112-00215aee3ebe;Куманівці;Кумановцы;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524883401;3;1
62c3cf76-749b-11df-b112-00215aee3ebe;Кумарі;Кумари;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825483801;3;1
62c3cf77-749b-11df-b112-00215aee3ebe;Кумарі;Кумари;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822383001;3;1
62c3cf79-749b-11df-b112-00215aee3ebe;Кумейки;Кумейки;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124984001;3;1
62c3cf7a-749b-11df-b112-00215aee3ebe;Куми;Кумы;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321682503;3;1
62c3cf7b-749b-11df-b112-00215aee3ebe;Куми;Кумы;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323310102;3;1
62c3cf7d-749b-11df-b112-00215aee3ebe;Кумовище;Кумовище;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720884803;3;1
62c3cf7f-749b-11df-b112-00215aee3ebe;Куна;Куна;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520883603;3;1
62c3cf80-749b-11df-b112-00215aee3ebe;Кунашівка;Кунашовка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423385901;3;1
62c3cf81-749b-11df-b112-00215aee3ebe;Кунин;Кунин;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784601;3;1
62c3cf82-749b-11df-b112-00215aee3ebe;Кунин;Кунин;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622687003;3;1
62c3cf83-749b-11df-b112-00215aee3ebe;Кунисівці;Кунисовцы;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621681701;3;1
62c3cf84-749b-11df-b112-00215aee3ebe;Куниче;Куничье;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521984601;3;1
62c3cf85-749b-11df-b112-00215aee3ebe;Куничі;Куничи;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621285702;3;1
62c3cf86-749b-11df-b112-00215aee3ebe;Кунів;Кунив;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6822183001;3;1
62c3cf87-749b-11df-b112-00215aee3ebe;Кунівка;Куновка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321884401;3;1
62c3cf88-749b-11df-b112-00215aee3ebe;Кунінова;Кунинова;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223882505;3;1
62c3cf89-749b-11df-b112-00215aee3ebe;Кунка;Кунка;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520883303;3;1
62c3cf8a-749b-11df-b112-00215aee3ebe;Кунцеве;Кунцево;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323482201;3;1
62c3cf8c-749b-11df-b112-00215aee3ebe;Кунча;Кунча;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824783201;3;1
62c3cf8d-749b-11df-b112-00215aee3ebe;Куньє;Кунье;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322886001;3;1
62c3cf91-749b-11df-b112-00215aee3ebe;Куп'євате;Купьеватое;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320684901;3;1
62c3cf92-749b-11df-b112-00215aee3ebe;Куп'єваха;Купьеваха;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320885501;3;1
c8674a96-dbb0-11df-9197-00215aee3ebe;Куп'єваха;Купьеваха;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322881203;3;1
62c3cf8e-749b-11df-b112-00215aee3ebe;Купелів;Купелев;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625685603;3;1
62c3cf8f-749b-11df-b112-00215aee3ebe;Купель;Купель;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625080402;3;1
62c3cf90-749b-11df-b112-00215aee3ebe;Купеч;Купеч;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822385203;3;1
62c3cf94-749b-11df-b112-00215aee3ebe;Купин;Купин;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821283901;3;1
62c3cf95-749b-11df-b112-00215aee3ebe;Купина;Купина;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421785205;3;1
62c3cf97-749b-11df-b112-00215aee3ebe;Купине;Купино;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825589003;3;1
62c3cf98-749b-11df-b112-00215aee3ebe;Купичволя;Купичволя;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784701;3;1
62c3cf99-749b-11df-b112-00215aee3ebe;Купичів;Купичев;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725582201;3;1
62c3cf9a-749b-11df-b112-00215aee3ebe;Купище;Купище;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386023;3;1
62c3cf9b-749b-11df-b112-00215aee3ebe;Купівщина;Куповщина;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323285603;3;1
62c3cf9c-749b-11df-b112-00215aee3ebe;Купіль;Купель;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820984001;3;1
62c3cf9d-749b-11df-b112-00215aee3ebe;Купка;Купка;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321083301;3;1
62c3cf9e-749b-11df-b112-00215aee3ebe;Куплеваха;Куплеваха;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923280805;3;1
62c3cfa0-749b-11df-b112-00215aee3ebe;Купновичі;Купновичи;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624284001;3;1
62c3cfa2-749b-11df-b112-00215aee3ebe;Купріянівка;Куприяновка;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823981003;3;1
62c3cfa3-749b-11df-b112-00215aee3ebe;Купріянівка;Куприяновка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582201;3;1
62c3cfa5-749b-11df-b112-00215aee3ebe;Купче;Купче;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620682401;3;1
62c3cfa6-749b-11df-b112-00215aee3ebe;Купченків;Купченков;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420881203;3;1
62c3cfa7-749b-11df-b112-00215aee3ebe;Купчинці;Купчинцы;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123085601;3;1
62c3cfa8-749b-11df-b112-00215aee3ebe;Купчинці;Купчинцы;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521255407;3;1
62c3cfa9-749b-11df-b112-00215aee3ebe;Купчичі;Купчичи;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424983002;3;1
62c3cff5-749b-11df-b112-00215aee3ebe;Кур'янки;Курьянки;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820385001;3;1
62c3cff6-749b-11df-b112-00215aee3ebe;Кур'янці;Курьянцы;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523485203;3;1
62c3cfac-749b-11df-b112-00215aee3ebe;Кураж;Кураж;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624287102;3;1
62c3cfad-749b-11df-b112-00215aee3ebe;Куражин;Куражин;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823384501;3;1
62c3cfae-749b-11df-b112-00215aee3ebe;Курасове;Курасово;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920681403;3;1
62c3cfb1-749b-11df-b112-00215aee3ebe;Кураш;Кураш;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621887702;3;1
62c3cfb2-749b-11df-b112-00215aee3ebe;Курашівці;Курашевцы;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522883601;3;1
62c3cfb4-749b-11df-b112-00215aee3ebe;Курган;Курган;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922985201;3;1
62c3cfb5-749b-11df-b112-00215aee3ebe;Курган;Курган;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121080705;3;1
62c3cfb7-749b-11df-b112-00215aee3ebe;Кургани;Курганы;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620485703;3;1
62c3cfb8-749b-11df-b112-00215aee3ebe;Кургани;Курганы;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624285303;3;1
62c3cfb9-749b-11df-b112-00215aee3ebe;Курганівка;Кургановка;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825580802;3;1
62c3cfba-749b-11df-b112-00215aee3ebe;Курганка;Курганка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885507;3;1
62c3cfbb-749b-11df-b112-00215aee3ebe;Курганне;Курганное;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324580607;3;1
62c3cfbf-749b-11df-b112-00215aee3ebe;Курганське;Курганское;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422082402;3;1
62c3cfc0-749b-11df-b112-00215aee3ebe;Курганці;Курганцы;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821181302;3;1
62c3cfc1-749b-11df-b112-00215aee3ebe;Курдибанівка;Курдыбановка;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121284602;3;1
62c3cfc2-749b-11df-b112-00215aee3ebe;Курдюмівка;Курдюмовка;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925355301;3;1
62c3cfc4-749b-11df-b112-00215aee3ebe;Курдюмове;Курдюмово;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923882805;3;1
62c3cfc5-749b-11df-b112-00215aee3ebe;Куренівка;Куренивка;село;78f453a8-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0525083601;3;1
62c3cfc6-749b-11df-b112-00215aee3ebe;Курилехівка;Курилеховка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083909;3;1
62c3cfc7-749b-11df-b112-00215aee3ebe;Курили;Курилы;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
62c3cfc8-749b-11df-b112-00215aee3ebe;Курилівка;Куриловка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922988203;3;1
62c3cfc9-749b-11df-b112-00215aee3ebe;Курилівка;Куриловка;село;bad3ac3c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1223756800;3;1
62c3cfcb-749b-11df-b112-00215aee3ebe;Курилівка;Куриловка;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422284502;3;1
62c3cfcc-749b-11df-b112-00215aee3ebe;Курилівка;Куриловка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423380404;3;1
62c3cfcd-749b-11df-b112-00215aee3ebe;Курилівка;Куриловка;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122083701;3;1
62c3cfce-749b-11df-b112-00215aee3ebe;Курилівка;Куриловка;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524885602;3;1
62c3cfcf-749b-11df-b112-00215aee3ebe;Курилівка;Куриловка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820984101;3;1
56bdd228-749b-11df-b112-00215aee3ebe;Курилівка (Жовтневе);Куриловка (Жовтневое);село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922083301;3;1
62c3cfd0-749b-11df-b112-00215aee3ebe;Курилівці;Куриловцы;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521083403;3;1
62c3cfd1-749b-11df-b112-00215aee3ebe;Куринів;Куриново;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;;3;1
69774035-8b4b-11df-aa1f-00215aee3ebe;Курипів;Курипов;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621280303;3;1
62c3cfd3-749b-11df-b112-00215aee3ebe;Курів;Куров;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621255306;3;1
62c3cfd4-749b-11df-b112-00215aee3ebe;Курівка;Куровка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821284201;3;1
62c3cfd5-749b-11df-b112-00215aee3ebe;Курівці;Куровцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122685101;3;1
62c3cfd6-749b-11df-b112-00215aee3ebe;Курінь;Курень;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420385501;3;1
62c3cfd7-749b-11df-b112-00215aee3ebe;Курінька;Куренька;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325182101;3;1
62c3cfd8-749b-11df-b112-00215aee3ebe;Куріпчине;Курипчино;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825485103;3;1
b35f7cc7-1902-11e7-80fc-1c98ec135263;Курісове;Курисовое;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122784601;3;1
74dc493f-749b-11df-b112-00215aee3ebe;Курісове (Петрівка);Курісове (Петровка);село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;c7bb5b2d-671b-11ea-80c6-000c29800ae7;5122784601;3;1
62c3cfd9-749b-11df-b112-00215aee3ebe;Курмани;Курманы;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923583801;3;1
62c3cfda-749b-11df-b112-00215aee3ebe;Курне;Курное;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825482603;3;1
62c3cfdb-749b-11df-b112-00215aee3ebe;Курний;Курный;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623382902;3;1
62c3cfdc-749b-11df-b112-00215aee3ebe;Курники;Курники;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125284503;2;1
62c3cfdd-749b-11df-b112-00215aee3ebe;Курники;Курники;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524580807;3;1
62c3cfde-749b-11df-b112-00215aee3ebe;Куровечка;Куровечка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820987203;3;1
62c3cfdf-749b-11df-b112-00215aee3ebe;Куровичі;Куровичи;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621883901;3;1
62c3cfe0-749b-11df-b112-00215aee3ebe;Курозвани;Курозваны;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621284001;3;1
62c3cfe2-749b-11df-b112-00215aee3ebe;Куропатники;Куропатники;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120482501;3;1
62c3cfe3-749b-11df-b112-00215aee3ebe;Куропатники;Куропатники;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621284502;3;1
62c3cfe4-749b-11df-b112-00215aee3ebe;Куропіївка;Куропиевка;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425883502;3;1
62c3cfe5-749b-11df-b112-00215aee3ebe;Курортне;Курортное;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321782004;3;1
62c3cfe6-749b-11df-b112-00215aee3ebe;Курортне;Курортное;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120885604;3;1
5cb619df-749b-11df-b112-00215aee3ebe;Курортне (Комунар);Курортное (Коммунар);село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322056502;3;1
62c3cfed-749b-11df-b112-00215aee3ebe;Курсики;Курсики;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625880802;3;1
62c3cff0-749b-11df-b112-00215aee3ebe;Курулька;Курулька;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320483703;3;1
62c3cff1-749b-11df-b112-00215aee3ebe;Курчиця;Курчица;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824082801;3;1
62c3cff2-749b-11df-b112-00215aee3ebe;Курчицька Гута;Курчицкая Гута;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824082802;3;1
62c3cff3-749b-11df-b112-00215aee3ebe;Куряжанка;Куряжанка;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322057601;3;1
62c3cff4-749b-11df-b112-00215aee3ebe;Куряни;Куряны;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120482701;3;1
62c3cff8-749b-11df-b112-00215aee3ebe;Курячі Лози;Курячьи Лозы;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823981701;3;1
62c3cffc-749b-11df-b112-00215aee3ebe;Кусиківці;Кусиковцы;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522481803;3;1
62c3cffd-749b-11df-b112-00215aee3ebe;Кусії;Кусеи;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421487202;3;1
62c3cffe-749b-11df-b112-00215aee3ebe;Куснища;Куснища;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723310109;3;1
62c3cfff-749b-11df-b112-00215aee3ebe;Кустин;Кустин;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623982201;3;1
62c3d000-749b-11df-b112-00215aee3ebe;Кустин;Кустин;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820881802;3;1
62c3d001-749b-11df-b112-00215aee3ebe;Кустин;Кустин;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624686501;3;1
62c3d002-749b-11df-b112-00215aee3ebe;Кустине;Кустино;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924455301;3;1
62c3d003-749b-11df-b112-00215aee3ebe;Кустичі;Кустичи;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725586502;3;1
62c3d004-749b-11df-b112-00215aee3ebe;Кустівці;Кустовцы;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524883501;3;1
62c3d005-749b-11df-b112-00215aee3ebe;Кустівці;Кустовцы;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823683501;3;1
62c3d006-749b-11df-b112-00215aee3ebe;Кустовецьке;Кустовецкое;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524883502;3;1
62c3d007-749b-11df-b112-00215aee3ebe;Кустолове;Кустолово;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323482501;3;1
62c3d008-749b-11df-b112-00215aee3ebe;Кустолові Кущі;Кустоловы Кущи;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321886605;3;1
62c3d009-749b-11df-b112-00215aee3ebe;Кустолово-Суходілка;Кустолово-Суходолка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323082601;3;1
62c3d00a-749b-11df-b112-00215aee3ebe;Кусторівка;Кусторовка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323581705;3;1
62c3d00b-749b-11df-b112-00215aee3ebe;Кут;Кут;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121688003;3;1
62c3d00c-749b-11df-b112-00215aee3ebe;Кут;Кут;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321083602;3;1
62c3d00d-749b-11df-b112-00215aee3ebe;Кут;Кут;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324282502;3;1
6e1d006f-f733-11e5-b35b-001ec93df11f;Кутелів;Кутелив;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222983302;3;1
62c3d010-749b-11df-b112-00215aee3ebe;Кути;Куты;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722480602;3;1
62c3d011-749b-11df-b112-00215aee3ebe;Кути;Куты;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125881502;3;1
62c3d012-749b-11df-b112-00215aee3ebe;Кути;Куты;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120483902;3;1
62c3d013-749b-11df-b112-00215aee3ebe;Кути;Куты;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623655400;3;1
62c3d014-749b-11df-b112-00215aee3ebe;Кути;Куты;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623910101;3;1
62c3d015-749b-11df-b112-00215aee3ebe;Кути;Куты;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620683201;3;1
62c3d016-749b-11df-b112-00215aee3ebe;Кути;Куты;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620386604;3;1
62c3d017-749b-11df-b112-00215aee3ebe;Кути;Куты;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625383602;3;1
62c3d018-749b-11df-b112-00215aee3ebe;Кути;Куты;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425186002;3;1
62c3d019-749b-11df-b112-00215aee3ebe;Кути;Куты;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123184501;3;1
62c3d01a-749b-11df-b112-00215aee3ebe;Кути Другі;Куты Вторые;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424710102;3;1
62c3d01c-749b-11df-b112-00215aee3ebe;Кутин;Кутин;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622281601;3;1
62c3d01d-749b-11df-b112-00215aee3ebe;Кутинок;Кутинок;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622281607;3;1
62c3d01e-749b-11df-b112-00215aee3ebe;Кутиска;Кутыска;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123885003;3;1
62c3d01f-749b-11df-b112-00215aee3ebe;Кутище;Кутище;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625685001;3;1
62c3d020-749b-11df-b112-00215aee3ebe;Кутище;Кутище;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620384002;3;1
62c3d021-749b-11df-b112-00215aee3ebe;Кутище;Кутище;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823488603;3;1
62c3d022-749b-11df-b112-00215aee3ebe;Кутище;Кутище;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823183602;3;1
62c3d023-749b-11df-b112-00215aee3ebe;Кутки;Кутки;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823987103;3;1
62c3d024-749b-11df-b112-00215aee3ebe;Кутківці;Кутковцы;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825285401;2;1
62c3d025-749b-11df-b112-00215aee3ebe;Куткір;Куткор;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620682801;3;1
62c3d026-749b-11df-b112-00215aee3ebe;Кутлаш;Кутлаш;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125382203;3;1
3253b8b3-765c-11e0-be68-003048d2b472;Куторжиха;Куторжиха;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324884403;3;1
62c3d028-749b-11df-b112-00215aee3ebe;Кутрів;Кутров;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720883804;3;1
62c3d029-749b-11df-b112-00215aee3ebe;Кутузівка;Кутузовка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821755113;3;1
62c3d02a-749b-11df-b112-00215aee3ebe;Кутузівка;Кутузовка;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157308;3;1
62c3d02d-749b-11df-b112-00215aee3ebe;Кутці;Кутцы;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624484302;3;1
62c3d02e-749b-11df-b112-00215aee3ebe;Кутянка;Кутянка;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125880302;3;1
62c3d02f-749b-11df-b112-00215aee3ebe;Кутянка;Кутянка;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624283601;3;1
62c3d031-749b-11df-b112-00215aee3ebe;Кухарі;Кухари;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722181203;3;1
62c3d032-749b-11df-b112-00215aee3ebe;Кухарі;Кухари;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222081901;3;1
62c3d033-749b-11df-b112-00215aee3ebe;Кухарка;Кухарка;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421182601;3;1
62c3d034-749b-11df-b112-00215aee3ebe;Кухітська Воля;Кухотская Воля;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622282001;3;1
62c3d035-749b-11df-b112-00215aee3ebe;Кухче;Кухче;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622282301;3;1
62c3d036-749b-11df-b112-00215aee3ebe;Куцеволівка;Куцеволовка;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524683601;3;1
62c3d037-749b-11df-b112-00215aee3ebe;Куцівка;Куцовка;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123783501;3;1
62c3d038-749b-11df-b112-00215aee3ebe;Куцівка;Куцовка;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523482401;3;1
62c3d03a-749b-11df-b112-00215aee3ebe;Куцуруб;Куцуруб;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825182201;3;1
62c3d03b-749b-11df-b112-00215aee3ebe;Куча;Куча;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823385001;3;1
62c3d03c-749b-11df-b112-00215aee3ebe;Кучава;Кучава;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122781605;3;1
5cb61723-749b-11df-b112-00215aee3ebe;Кучаків (Кірове);Кучаков (Кирово);село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220884401;3;1
62c3d03e-749b-11df-b112-00215aee3ebe;Кучерівка;Кучеровка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321080404;3;1
62c3d03f-749b-11df-b112-00215aee3ebe;Кучерівка;Кучеровка;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921583201;3;1
62c3d041-749b-11df-b112-00215aee3ebe;Кучерівка;Кучеровка;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623388304;3;1
62c3d042-749b-11df-b112-00215aee3ebe;Кучерівка;Кучеровка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522280402;3;1
62c3d043-749b-11df-b112-00215aee3ebe;Кучерське;Кучерское;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520981002;3;1
62c3d045-749b-11df-b112-00215aee3ebe;Кучинівка;Кучиновка;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425883001;3;1
62c3d046-749b-11df-b112-00215aee3ebe;Кучі;Кучи;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422280502;3;1
62c3d047-749b-11df-b112-00215aee3ebe;Кучівка;Кучовка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824288905;3;1
62c3d048-749b-11df-b112-00215aee3ebe;Кучківка;Кучковка;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122888802;3;1
62c3d049-749b-11df-b112-00215aee3ebe;Кучманівка;Кучмановка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822785102;3;1
62c3d04a-749b-11df-b112-00215aee3ebe;Кучугури;Кучугуры;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422455401;3;1
62c3d04b-749b-11df-b112-00215aee3ebe;Кучурган;Кучурган;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121685202;3;1
62c3d04c-749b-11df-b112-00215aee3ebe;Кучурган;Кучурган;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123983401;2;1
62c3d04d-749b-11df-b112-00215aee3ebe;Кушелівка;Кушелевка;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524880603;3;1
62c3d04e-749b-11df-b112-00215aee3ebe;Кушкине;Кушкино;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921582703;3;1
62c3d04f-749b-11df-b112-00215aee3ebe;Кушлин;Кушлин;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123482003;3;1
62c3d050-749b-11df-b112-00215aee3ebe;Кушнарівка;Кушнаревка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221084105;3;1
62c3d051-749b-11df-b112-00215aee3ebe;Кушнирівка;Кушнировка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820988302;3;1
62c3d052-749b-11df-b112-00215aee3ebe;Кушнирівська Слобідка;Кушнировская Слободка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820989402;3;1
62c3d053-749b-11df-b112-00215aee3ebe;Кушниця;Кушница;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121985201;3;0
62c3d054-749b-11df-b112-00215aee3ebe;Кушніри;Кушниры;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923581703;3;1
62c3d055-749b-11df-b112-00215aee3ebe;Куштановиця;Куштановица;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122781607;2;1
62c3d056-749b-11df-b112-00215aee3ebe;Кушугум;Кушугум;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322155600;3;1
62c3d057-749b-11df-b112-00215aee3ebe;Кущинці;Кущинцы;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520883803;3;1
62c3d058-749b-11df-b112-00215aee3ebe;Кущівка;Кущевка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655114;3;1
62c3d059-749b-11df-b112-00215aee3ebe;Кущівка;Кущевка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820988603;3;1
62c3d05a-749b-11df-b112-00215aee3ebe;Кущове;Кущовое;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955414;3;1
62c3d05b-749b-11df-b112-00215aee3ebe;Кущове;Кущовое;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824084003;3;1
62c3d05c-749b-11df-b112-00215aee3ebe;Куяльник;Куяльник;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122983801;3;1
62c3d05d-749b-11df-b112-00215aee3ebe;Куянівка;Куяновка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920684901;3;1
62c3d05e-749b-11df-b112-00215aee3ebe;Лабач;Лабач;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620685304;3;1
62c3d05f-749b-11df-b112-00215aee3ebe;Лабурівка;Лабуревка;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322283006;3;1
62c3d060-749b-11df-b112-00215aee3ebe;Лабушне;Лабушное;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122583401;3;1
62c3d062-749b-11df-b112-00215aee3ebe;Лави;Лавы;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424985301;3;1
62c3d063-749b-11df-b112-00215aee3ebe;Лавіркове;Лавирково;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425382502;3;1
73e94bf5-ddc1-11df-9197-00215aee3ebe;Лавки;Лавки;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122784801;2;1
62c3d064-749b-11df-b112-00215aee3ebe;Лавочне;Лавочное;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624584001;3;1
62c3d065-749b-11df-b112-00215aee3ebe;Лаврики;Лаврики;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324080103;3;1
62c3d066-749b-11df-b112-00215aee3ebe;Лаврики;Лаврики;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224081502;3;1
62c3d067-749b-11df-b112-00215aee3ebe;Лавриків;Лавриков;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784801;3;1
62c3d068-749b-11df-b112-00215aee3ebe;Лавриківці;Лавриковцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122683705;3;1
62c3d069-749b-11df-b112-00215aee3ebe;Лавринівці;Лавриневцы;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825588003;3;1
62c3d34c-749b-11df-b112-00215aee3ebe;Лавринці;Лавринцы;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321387207;3;1
62c3d06a-749b-11df-b112-00215aee3ebe;Лаврів;Лавров;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722883201;3;1
62c3d06b-749b-11df-b112-00215aee3ebe;Лаврів;Лавров;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625180602;3;1
62c3d06c-749b-11df-b112-00215aee3ebe;Лаврівка;Лавровка;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520682603;3;1
62c3d06d-749b-11df-b112-00215aee3ebe;Лаврівка;Лавровка;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525686604;3;1
62c3d06e-749b-11df-b112-00215aee3ebe;Лаврівка;Лавровка;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521984601;3;1
62c3d06f-749b-11df-b112-00215aee3ebe;Лаврове;Лавровое;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923586704;3;1
56bdd242-749b-11df-b112-00215aee3ebe;Лагідне (Жовтневе);Лагидне (Жовтневое);село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323982103;3;1
62c3d071-749b-11df-b112-00215aee3ebe;Лагоди;Лагоды;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321380405;3;1
62c3d072-749b-11df-b112-00215aee3ebe;Лагодинці;Лагодинцы;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822786201;3;1
62c3d073-749b-11df-b112-00215aee3ebe;Лагодів;Лагодов;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620388603;3;1
62c3d074-749b-11df-b112-00215aee3ebe;Лагодів;Лагодов;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623384201;3;1
62c3d075-749b-11df-b112-00215aee3ebe;Лагодівка;Лагодовка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324888209;3;1
62c3d076-749b-11df-b112-00215aee3ebe;Лагодівка;Лагодовка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655112;3;1
62c3d077-749b-11df-b112-00215aee3ebe;Лагульськ;Лагульск;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824081704;3;1
62c3d078-749b-11df-b112-00215aee3ebe;Ладан;Ладан;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424155400;3;1
62c3d079-749b-11df-b112-00215aee3ebe;Ладанці;Ладанцы;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623381003;3;1
62c3d07a-749b-11df-b112-00215aee3ebe;Ладиги;Ладыги;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824285001;3;1
62c3d07b-749b-11df-b112-00215aee3ebe;Ладижин;Ладыжин;місто;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0510600000;2;1
62c3d07c-749b-11df-b112-00215aee3ebe;Ладижинка;Ладыжинка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124385101;3;1
62c3d07d-749b-11df-b112-00215aee3ebe;Ладижинське;Ладыжинское;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524184602;3;1
62c3d07e-749b-11df-b112-00215aee3ebe;Ладижинські Хутори;Ладыжинские Хутора;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520884003;3;0
62c3d07f-749b-11df-b112-00215aee3ebe;Ладинка;Ладинка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425584301;3;1
62c3d080-749b-11df-b112-00215aee3ebe;Ладинь;Ладынь;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723382601;3;1
62c3d081-749b-11df-b112-00215aee3ebe;Ладичин;Ладычин;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125085001;3;1
7ad94e4a-749b-11df-b112-00215aee3ebe;Ладівщина (Пролетарське);Ладивщина (Пролетарское);село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424185105;3;1
62c3d082-749b-11df-b112-00215aee3ebe;Ладне;Ладное;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684008;3;1
62c3d083-749b-11df-b112-00215aee3ebe;Лажева;Лажева;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824280404;3;1
62c3d084-749b-11df-b112-00215aee3ebe;Лазарівка;Лазаревка;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124286801;3;1
62c3d085-749b-11df-b112-00215aee3ebe;Лазарівка;Лазаревка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655101;3;1
62c3d089-749b-11df-b112-00215aee3ebe;Лазарівка;Лазаревка;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820982601;3;1
62c3d08a-749b-11df-b112-00215aee3ebe;Лазарівка;Лазаревка;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423088503;3;1
62c3d08b-749b-11df-b112-00215aee3ebe;Лазещина;Лазещина;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123684501;3;1
62c3d08c-749b-11df-b112-00215aee3ebe;Лази;Лазы;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624886702;3;1
62c3d08d-749b-11df-b112-00215aee3ebe;Лази;Лазы;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121584001;3;1
62c3d08e-749b-11df-b112-00215aee3ebe;Лази;Лазы;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124410102;3;1
62c3d08f-749b-11df-b112-00215aee3ebe;Лазірки;Лазорки;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323683001;3;1
62c3d090-749b-11df-b112-00215aee3ebe;Лазірці;Лазорцы;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122086602;3;1
62c3d091-749b-11df-b112-00215aee3ebe;Лазуківка;Лазуковка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321755303;3;1
62c3d095-749b-11df-b112-00215aee3ebe;Лазьки;Лазки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321380704;3;1
62c3d096-749b-11df-b112-00215aee3ebe;Лазьки;Лазки;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324883207;3;1
62c3d097-749b-11df-b112-00215aee3ebe;Лалово;Лалово;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122785001;2;1
62c3d098-749b-11df-b112-00215aee3ebe;Ламане;Ламаное;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320685403;3;1
62c3d099-749b-11df-b112-00215aee3ebe;Ламбрівка;Ламбровка;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124784430;3;1
62c3d09a-749b-11df-b112-00215aee3ebe;Ландарі;Ландари;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321080405;3;1
62c3d09b-749b-11df-b112-00215aee3ebe;Ландишове;Ландышево;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321281004;3;1
62c3d09c-749b-11df-b112-00215aee3ebe;Ланерівка;Ланеровка;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620685040;3;1
62c3d09d-749b-11df-b112-00215aee3ebe;Лани;Ланы;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321082004;3;1
62c3d09e-749b-11df-b112-00215aee3ebe;Лани;Ланы;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621284901;3;1
62c3d09f-749b-11df-b112-00215aee3ebe;Лани;Ланы;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623682202;3;1
62c3d0a0-749b-11df-b112-00215aee3ebe;Лани;Ланы;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623384501;3;1
62c3d0a1-749b-11df-b112-00215aee3ebe;Лани;Ланы;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524587903;3;1
62c3d0a2-749b-11df-b112-00215aee3ebe;Лани;Ланы;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524984804;3;1
62c3d0a3-749b-11df-b112-00215aee3ebe;Лани-Соколівські;Ланы-Соколовские;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625385607;3;1
62c3d0a4-749b-11df-b112-00215aee3ebe;Ланівка;Лановка;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625383201;3;1
62c3d0a5-749b-11df-b112-00215aee3ebe;Ланівці;Лановцы;місто;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123810100;3;0
62c3d0a6-749b-11df-b112-00215aee3ebe;Ланівці;Лановцы;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120884501;3;1
62c3d0a8-749b-11df-b112-00215aee3ebe;Ланна;Ланна;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321682501;3;1
62c3d0a9-749b-11df-b112-00215aee3ebe;Ланове;Лановое;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121280807;3;1
62c3d0aa-749b-11df-b112-00215aee3ebe;Лановичі;Лановичи;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624286902;3;1
62c3d0ab-749b-11df-b112-00215aee3ebe;Ланок;Ланок;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824281705;3;1
62c3d0ac-749b-11df-b112-00215aee3ebe;Лантратівка;Лантратовка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920385401;3;1
62c3d0ae-749b-11df-b112-00215aee3ebe;Ланчин;Ланчин;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624056200;3;1
62c3d0af-749b-11df-b112-00215aee3ebe;Лапаївка;Лапаевка;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623683701;3;0
62c3d0b0-749b-11df-b112-00215aee3ebe;Лапківці;Лапковцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825087102;3;1
62c3d0b1-749b-11df-b112-00215aee3ebe;Лапні;Лапни;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722182807;3;1
62c3d0b2-749b-11df-b112-00215aee3ebe;Лапутьки;Лапутькы;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222080705;3;1
62c3d0b3-749b-11df-b112-00215aee3ebe;Лапшин;Лапшин;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120483001;3;1
62c3d0b4-749b-11df-b112-00215aee3ebe;Лапшин;Лапшин;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621584903;3;1
62c3d0b5-749b-11df-b112-00215aee3ebe;Лапшине;Лапшино;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922685102;3;1
62c3d0b6-749b-11df-b112-00215aee3ebe;Ларжанка;Ларжанка;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122083201;3;1
62c3d0b9-749b-11df-b112-00215aee3ebe;Ларинівка;Лариновка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423685801;3;1
62c3d0ba-749b-11df-b112-00215aee3ebe;Ласки;Ласки;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755132;3;1
62c3d0bb-749b-11df-b112-00215aee3ebe;Ласків;Ласков;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720581608;3;1
62c3d0bc-749b-11df-b112-00215aee3ebe;Ласківці;Ласковцы;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125085201;3;1
62c3d0bd-749b-11df-b112-00215aee3ebe;Ластівка;Ластовка;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625584601;3;1
62c3d0be-749b-11df-b112-00215aee3ebe;Ластівці;Ластовцы;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620488802;3;1
62c3d0bf-749b-11df-b112-00215aee3ebe;Ластівці;Ластовцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822485101;3;1
62c3d0c1-749b-11df-b112-00215aee3ebe;Латаші;Латаши;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755133;3;1
62c3d0c3-749b-11df-b112-00215aee3ebe;Латишівка;Латишовка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323086602;3;1
62c3d0c4-749b-11df-b112-00215aee3ebe;Латишівка;Латишовка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923882806;3;1
62c3d0c5-749b-11df-b112-00215aee3ebe;Латишівка;Латишовка;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323281207;3;1
62c3d0c6-749b-11df-b112-00215aee3ebe;Латівка;Латовка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885503;3;1
5cb61bd2-749b-11df-b112-00215aee3ebe;Латівка (Котовка);Латовка (Котовка);село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121080302;3;0
62c3d0c7-749b-11df-b112-00215aee3ebe;Латірка;Латирка;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121581002;3;1
62c3d0c8-749b-11df-b112-00215aee3ebe;Латківці;Латковци;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120888304;3;1
62c3d0c9-749b-11df-b112-00215aee3ebe;Лахвичі;Лахвичи;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155119;3;1
62c3d0ca-749b-11df-b112-00215aee3ebe;Лахни;Лахны;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323483204;3;1
62c3d0cb-749b-11df-b112-00215aee3ebe;Лахнівщина;Лахновщина;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923585706;3;1
62c3d0cc-749b-11df-b112-00215aee3ebe;Лашківка;Лашковка;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322585001;3;1
62c3d0cd-749b-11df-b112-00215aee3ebe;Лашуки;Лашуки;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421481803;3;1
62c3d0ce-749b-11df-b112-00215aee3ebe;Лащова;Лащовая;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124085001;3;1
62c3d0e8-749b-11df-b112-00215aee3ebe;Леб'яже;Лебяжье;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325484501;3;1
62c3d0e9-749b-11df-b112-00215aee3ebe;Леб'яже;Лебяжье;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322281501;3;1
62c3d0cf-749b-11df-b112-00215aee3ebe;Лебедєве;Лебедево;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922084905;3;1
62c3d0d0-749b-11df-b112-00215aee3ebe;Лебедин;Лебедин;місто;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5910500000;2;0
62c3d0d1-749b-11df-b112-00215aee3ebe;Лебедин;Лебедин;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220884403;3;1
62c3d0d2-749b-11df-b112-00215aee3ebe;Лебедин;Лебедин;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422285003;3;1
62c3d0d3-749b-11df-b112-00215aee3ebe;Лебедин;Лебедин;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125783601;3;1
5cb6170d-749b-11df-b112-00215aee3ebe;Лебедине (Кірове);Лебедине (Кирово);село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321883201;3;1
62c3d0d5-749b-11df-b112-00215aee3ebe;Лебединка;Лебединка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521484201;3;1
62c3d0d6-749b-11df-b112-00215aee3ebe;Лебединське;Лебединское;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222982807;3;1
62c3d0d8-749b-11df-b112-00215aee3ebe;Лебединці;Лебединцы;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820386001;3;1
62c3d0d9-749b-11df-b112-00215aee3ebe;Лебединці;Лебединцы;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425185002;3;1
62c3d0da-749b-11df-b112-00215aee3ebe;Лебеді;Лебеди;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624281603;3;1
62c3d0dc-749b-11df-b112-00215aee3ebe;Лебедівка;Лебедевка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324883501;3;1
62c3d0dd-749b-11df-b112-00215aee3ebe;Лебедівка;Лебедевка;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125085611;2;1
62c3d0df-749b-11df-b112-00215aee3ebe;Лебедівка;Лебедевка;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221884001;3;1
62c3d0e0-749b-11df-b112-00215aee3ebe;Лебедівка;Лебедевка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225581602;3;1
62c3d0e1-749b-11df-b112-00215aee3ebe;Лебедівка;Лебедевка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824083001;3;1
62c3d0e2-749b-11df-b112-00215aee3ebe;Лебедівка;Лебедевка;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422085302;3;1
62c3d0e3-749b-11df-b112-00215aee3ebe;Лебедівка;Лебедевка;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121882801;3;1
62c3d0e4-749b-11df-b112-00215aee3ebe;Лебедівка;Лебедевка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825882402;3;1
62c3d0e5-749b-11df-b112-00215aee3ebe;Лебедянка;Лебедянка;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621682006;3;1
62c3d0e6-749b-11df-b112-00215aee3ebe;Лебіддя;Лебедье;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422410102;3;1
62c3d0e7-749b-11df-b112-00215aee3ebe;Лебідь;Лебедь;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821786804;3;1
62c3d111-749b-11df-b112-00215aee3ebe;Лев'ятин;Левьятин;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625881603;3;1
62c3d0eb-749b-11df-b112-00215aee3ebe;Левада;Левада;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124080402;3;1
62c3d0ec-749b-11df-b112-00215aee3ebe;Левадівка;Левадовка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123582201;3;1
62c3d0ed-749b-11df-b112-00215aee3ebe;Левадки;Левадки;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223587503;3;1
62c3d0f3-749b-11df-b112-00215aee3ebe;Леваневське;Леваневское;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321880402;3;1
62c3d0f4-749b-11df-b112-00215aee3ebe;Левантівка;Левантовка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123182503;3;1
62c3d0f6-749b-11df-b112-00215aee3ebe;Лев-Балка;Лев-Балка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523886002;3;1
62c3d0f7-749b-11df-b112-00215aee3ebe;Левенцівка;Левенцовка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325482702;3;1
62c3d0f8-749b-11df-b112-00215aee3ebe;Левенцівка;Левенцовка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223283502;3;1
c8674aa0-dbb0-11df-9197-00215aee3ebe;Левенцівка;Левенцовка;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324283803;3;1
62c3d0f9-749b-11df-b112-00215aee3ebe;Левичівка;Левичовка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424485604;3;1
62c3d0fa-749b-11df-b112-00215aee3ebe;Левівщина;Левовщина;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425382004;3;1
62c3d0fc-749b-11df-b112-00215aee3ebe;Левки;Левки;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424184203;3;1
62c3d0fd-749b-11df-b112-00215aee3ebe;Левків;Левково;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822084101;1;1
62c3d0fe-749b-11df-b112-00215aee3ebe;Левків;Левково;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521986202;3;1
62c3d0ff-749b-11df-b112-00215aee3ebe;Левківка;Левковка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321786504;3;1
62c3d100-749b-11df-b112-00215aee3ebe;Левківка;Левковка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322886501;3;1
62c3d101-749b-11df-b112-00215aee3ebe;Левківка;Левковка;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523482501;3;1
62c3d102-749b-11df-b112-00215aee3ebe;Левківка;Левковка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523687702;3;1
62c3d103-749b-11df-b112-00215aee3ebe;Левківка;Левковка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824288003;3;1
62c3d104-749b-11df-b112-00215aee3ebe;Левківці;Левковцы;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524384301;3;1
62c3d105-749b-11df-b112-00215aee3ebe;Левківці;Левковцы;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820989003;3;1
62c3d106-749b-11df-b112-00215aee3ebe;Левковицький Млинок;Левковичский Млынок;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824283302;3;1
62c3d107-749b-11df-b112-00215aee3ebe;Левковичі;Левковичи;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824283301;3;1
62c3d108-749b-11df-b112-00215aee3ebe;Левковичі;Левковичи;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425584601;3;1
9db41f3b-dada-11e9-80e6-1c98ec135261;Левковичі;Левковичи;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223581501;3;1
62c3d109-749b-11df-b112-00215aee3ebe;Левондівка;Левондовка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187106;3;1
62c3d10a-749b-11df-b112-00215aee3ebe;Левоньки;Левоньки;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425582503;3;1
62c3d10b-749b-11df-b112-00215aee3ebe;Левченки;Левченки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321386905;3;1
62c3d10c-749b-11df-b112-00215aee3ebe;Левченки;Левченки;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924185802;3;1
62c3d10d-749b-11df-b112-00215aee3ebe;Левченки;Левченки;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324881904;3;1
62c3d10e-749b-11df-b112-00215aee3ebe;Левченкове;Левченково;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221983903;3;1
62c3d10f-749b-11df-b112-00215aee3ebe;Левченкове;Левченково;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120685701;3;1
62c3d112-749b-11df-b112-00215aee3ebe;Легедзине;Легедзино;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124085401;3;1
62c3d113-749b-11df-b112-00215aee3ebe;Легейди;Легейды;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755104;3;1
62c3d114-749b-11df-b112-00215aee3ebe;Легуші;Легуши;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923281707;3;1
62c3d115-749b-11df-b112-00215aee3ebe;Ледне;Ледно;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623484203;3;1
62c3d116-749b-11df-b112-00215aee3ebe;Ледянка;Ледянка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822786601;3;1
62c3d117-749b-11df-b112-00215aee3ebe;Лежанівка;Лежановка;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121683701;3;1
62c3d118-749b-11df-b112-00215aee3ebe;Лежине;Лежино;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322186803;3;1
62c3d119-749b-11df-b112-00215aee3ebe;Лежниця;Лежница;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721184402;3;1
62c3d11a-749b-11df-b112-00215aee3ebe;Лекечі;Лекечи;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320581502;3;1
62c3d11b-749b-11df-b112-00215aee3ebe;Лелеківка;Лелековка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524985904;3;1
62c3d11c-749b-11df-b112-00215aee3ebe;Лелехівка;Лелеховка;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625855305;3;1
62c3d11d-749b-11df-b112-00215aee3ebe;Лелітка;Лелитка;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524883701;3;1
62c3d11e-749b-11df-b112-00215aee3ebe;Лелюки;Лелюки;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325183004;3;1
62c3d11f-749b-11df-b112-00215aee3ebe;Лелюківка;Лелюковка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325784502;3;1
62c3d120-749b-11df-b112-00215aee3ebe;Лелюхівка;Лелюховка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323482801;2;1
62c3d121-749b-11df-b112-00215aee3ebe;Леляки;Леляки;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220287302;3;1
62c3d122-749b-11df-b112-00215aee3ebe;Леляки;Леляки;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421182801;3;1
62c3d123-749b-11df-b112-00215aee3ebe;Леляки;Леляки;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521083603;3;1
62c3d124-749b-11df-b112-00215aee3ebe;Леляки;Леляки;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323884903;3;1
62c3d125-749b-11df-b112-00215aee3ebe;Лемешинське;Лемешинское;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322180404;3;1
62c3d126-749b-11df-b112-00215aee3ebe;Лемеші;Лемеши;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820885203;3;1
62c3d127-749b-11df-b112-00215aee3ebe;Лемеші;Лемеши;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422085001;3;1
62c3d128-749b-11df-b112-00215aee3ebe;Лемешів;Лемешов;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720883001;3;1
62c3d129-749b-11df-b112-00215aee3ebe;Лемешівка;Лемешовка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225583001;3;1
62c3d12a-749b-11df-b112-00215aee3ebe;Лемешівка;Лемешовка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421485601;3;1
62c3d12b-749b-11df-b112-00215aee3ebe;Лемешівка;Лемешовка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
62c3d12c-749b-11df-b112-00215aee3ebe;Лемешівка;Лемешовка;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521684003;3;1
62c3d12d-749b-11df-b112-00215aee3ebe;Леміщине;Лемещино;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322685003;3;1
62c3d12e-749b-11df-b112-00215aee3ebe;Леміщиха;Лемещиха;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120983801;3;1
62c3d12f-749b-11df-b112-00215aee3ebe;Ленди;Ленды;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3211690001;3;1
62c3d130-749b-11df-b112-00215aee3ebe;Ленівка;Леновка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822483903;3;1
62c3d131-749b-11df-b112-00215aee3ebe;Леніна;Ленина;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;*122208200;3;1
62c3d138-749b-11df-b112-00215aee3ebe;Леніна;Ленина;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523182401;3;1
62c3d13a-749b-11df-b112-00215aee3ebe;Леніна Перше;Ленина Первое;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520385904;3;1
62c3d141-749b-11df-b112-00215aee3ebe;Леніне;Ленино;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223381103;3;1
62c3d13c-749b-11df-b112-00215aee3ebe;Леніне;Ленино;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820682601;3;1
62c3d142-749b-11df-b112-00215aee3ebe;Леніне (Ставки);Ленино (Ставки);село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825085201;3;1
62c3d146-749b-11df-b112-00215aee3ebe;Ленінка;Ленинка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323382001;3;1
62c3d147-749b-11df-b112-00215aee3ebe;Ленінка;Ленинка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325781004;3;1
62c3d14a-749b-11df-b112-00215aee3ebe;Ленінка;Ленинка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523786902;3;1
62c3d14b-749b-11df-b112-00215aee3ebe;Ленінка;Ленинка;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525888302;3;1
62c3d14d-749b-11df-b112-00215aee3ebe;Леніно-Ульяновка;Ленино-Ульяновка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523186803;3;1
62c3d159-749b-11df-b112-00215aee3ebe;Ленінське;Ленинское;село;78f4540d-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6524182502;3;1
62c3d166-749b-11df-b112-00215aee3ebe;Ленінське;Ленинское;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125184801;3;1
62c3d167-749b-11df-b112-00215aee3ebe;Ленінське;Ленинское;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123780501;3;1
62c3d168-749b-11df-b112-00215aee3ebe;Ленінське;Ленинское;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523184002;3;1
62c3d169-749b-11df-b112-00215aee3ebe;Ленінське;Ленинское;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824781503;3;1
62c3d16a-749b-11df-b112-00215aee3ebe;Ленінське;Ленинское;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824289402;3;1
62c3d16b-749b-11df-b112-00215aee3ebe;Ленінське;Ленинское;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825287803;2;1
62c3d154-749b-11df-b112-00215aee3ebe;Ленінське;Ленинское;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;*122038440;3;1
62c3d164-749b-11df-b112-00215aee3ebe;Ленінське;Ленинское;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823182904;3;1
62c3d16f-749b-11df-b112-00215aee3ebe;Ленківці;Ленковцы;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322086001;3;1
62c3d170-749b-11df-b112-00215aee3ebe;Ленківці;Ленковцы;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825583801;3;1
62c3d172-749b-11df-b112-00215aee3ebe;Леньків;Леньков;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620686205;3;1
62c3d173-749b-11df-b112-00215aee3ebe;Леньків;Леньков;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423686603;3;1
62c3d174-749b-11df-b112-00215aee3ebe;Леньків Круг;Леньков Круг;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425583102;3;1
62c3d175-749b-11df-b112-00215aee3ebe;Леонівка;Леоновка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222080304;3;1
62c3d176-749b-11df-b112-00215aee3ebe;Леонівка;Леоновка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222788902;3;1
62c3d177-749b-11df-b112-00215aee3ebe;Леонівка;Леоновка;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222284801;3;1
62c3d178-749b-11df-b112-00215aee3ebe;Леонівка;Леоновка;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822882702;3;1
62c3d179-749b-11df-b112-00215aee3ebe;Леонівка;Леоновка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822085003;3;1
62c3d17a-749b-11df-b112-00215aee3ebe;Леонівка;Леоновка;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424781004;3;1
62c3d17b-749b-11df-b112-00215aee3ebe;Леонівка;Леоновка;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521982002;3;1
62c3d17d-749b-11df-b112-00215aee3ebe;Леонідівка;Леонидовка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423382402;3;1
62c3d17e-749b-11df-b112-00215aee3ebe;Леонтіївка;Леонтиевка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920986002;3;1
62c3d17f-749b-11df-b112-00215aee3ebe;Леонтовичеве;Леонтовичево;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524084004;3;1
62c3d180-749b-11df-b112-00215aee3ebe;Лепесівка;Лепесовка;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820355602;3;1
62c3d181-749b-11df-b112-00215aee3ebe;Лепетиха;Лепетиха;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821181701;3;1
62c3d182-749b-11df-b112-00215aee3ebe;Лесинки;Лесинки;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321880904;3;1
62c3d183-749b-11df-b112-00215aee3ebe;Лесівка;Лесовка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324880503;3;1
62c3d184-749b-11df-b112-00215aee3ebe;Лесівка;Лесовка;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620488604;3;1
62c3d185-749b-11df-b112-00215aee3ebe;Леськи;Леськи;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124984501;3;1
62c3d187-749b-11df-b112-00215aee3ebe;Леськівка;Леськовка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320883002;3;1
62c3d188-749b-11df-b112-00215aee3ebe;Леськове;Леськово;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123484001;3;1
62c3d189-749b-11df-b112-00215aee3ebe;Летава;Летава;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825285801;2;1
62c3d18a-749b-11df-b112-00215aee3ebe;Летичів;Летичев;місто;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823055100;2;0
62c3d18b-749b-11df-b112-00215aee3ebe;Летичівка;Летичивка;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123480305;3;1
62c3d18c-749b-11df-b112-00215aee3ebe;Летківка;Летковка;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524183201;3;1
62c3d18d-749b-11df-b112-00215aee3ebe;Летня;Летня;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621284301;3;1
62c3d18e-749b-11df-b112-00215aee3ebe;Леухи;Леухи;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521284303;3;1
62c3d18f-749b-11df-b112-00215aee3ebe;Лехнівка;Лехновка;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220283001;3;1
62c3d190-749b-11df-b112-00215aee3ebe;Лехнівка;Лехновка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825886402;3;1
62c3d191-749b-11df-b112-00215aee3ebe;Лецівка;Лецовка;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624881307;3;1
62c3d192-749b-11df-b112-00215aee3ebe;Лецовиця;Лецовица;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122780802;3;1
62c3d193-749b-11df-b112-00215aee3ebe;Лецьки;Лецки;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223384201;3;1
62c3d194-749b-11df-b112-00215aee3ebe;Лешків;Лешкив;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624883903;3;1
62c3d195-749b-11df-b112-00215aee3ebe;Лешнів;Лешнев;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620383201;3;1
62c3d196-749b-11df-b112-00215aee3ebe;Лещани;Лещаны;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755130;3;1
62c3d197-749b-11df-b112-00215aee3ebe;Лещатів;Лещатов;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624880202;3;1
62c3d198-749b-11df-b112-00215aee3ebe;Лещенки;Лещенки;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322080502;3;1
62c3d199-749b-11df-b112-00215aee3ebe;Лещенки;Лещенки;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323280202;3;1
62c3d19a-749b-11df-b112-00215aee3ebe;Лещівка;Лещовка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521484202;3;1
62c3d19b-749b-11df-b112-00215aee3ebe;Либохора;Либохора;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625584901;3;1
62c3d19c-749b-11df-b112-00215aee3ebe;Либохора;Либохора;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624584101;3;1
62c3d19d-749b-11df-b112-00215aee3ebe;Лигівка;Лиговка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324884001;3;1
62c3d19f-749b-11df-b112-00215aee3ebe;Лизник;Лизник;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821185407;3;1
62c3d1a0-749b-11df-b112-00215aee3ebe;Лизогубівка;Лизогубовка;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325181501;3;1
62c3d1a1-749b-11df-b112-00215aee3ebe;Лизогубова Слобода;Лизогубова Слобода;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221983301;3;1
62c3d1a2-749b-11df-b112-00215aee3ebe;Лизунівка;Лизуновка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423688105;3;1
62c3d1a3-749b-11df-b112-00215aee3ebe;Лизунова Рудня;Лизунова Рудня;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424483305;3;1
62c3d1a4-749b-11df-b112-00215aee3ebe;Ликошине;Лыкошино;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224555304;3;1
62c3d1a5-749b-11df-b112-00215aee3ebe;Лиман;Лиман;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321782704;3;1
62c3d1a6-749b-11df-b112-00215aee3ebe;Лиман;Лиман;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323987005;3;1
62c3d1a7-749b-11df-b112-00215aee3ebe;Лиман;Лиман;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125082701;2;1
62c3d1ab-749b-11df-b112-00215aee3ebe;Лиман Другий;Лиман Второй;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281501;3;1
62c3d1ad-749b-11df-b112-00215aee3ebe;Лиман Перший;Лиман Первый;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281601;3;1
5cb6171a-749b-11df-b112-00215aee3ebe;Лиманець (Кірове);Кирово;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520682401;3;1
62c3d1af-749b-11df-b112-00215aee3ebe;Лимани;Лиманы;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823382601;3;1
62c3d1b0-749b-11df-b112-00215aee3ebe;Лимани;Лиманы;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820955112;3;1
62c3d1b1-749b-11df-b112-00215aee3ebe;Лиманівка;Лимановка;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322284003;3;1
c8674a8b-dbb0-11df-9197-00215aee3ebe;Лиманка (Мізікевича);Лыманка (Мизикевича);село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123755802;2;0
62c3d1b4-749b-11df-b112-00215aee3ebe;Лиманне;Лиманное;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825181205;3;1
62c3d1b6-749b-11df-b112-00215aee3ebe;Лиманське;Лиманское;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223584509;3;1
62c3d1b7-749b-11df-b112-00215aee3ebe;Лиманське;Лиманское;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820955113;3;1
62c3d1b8-749b-11df-b112-00215aee3ebe;Лиманське;Лиманское;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123955400;3;1
62c3d1b9-749b-11df-b112-00215aee3ebe;Лиманське;Лиманское;село;d00d3b62-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5124181701;3;1
62c3d1bc-749b-11df-b112-00215aee3ebe;Лиманці;Лиманцы;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825783403;3;1
62c3d1be-749b-11df-b112-00215aee3ebe;Лимареве;Лимарево;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920383602;3;1
62c3d1c1-749b-11df-b112-00215aee3ebe;Линея;Линея;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425586304;3;1
62c3d1c2-749b-11df-b112-00215aee3ebe;Линів;Линев;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722480502;3;1
62c3d1c3-749b-11df-b112-00215aee3ebe;Линівка;Линевка;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724585005;3;1
62c3d1c4-749b-11df-b112-00215aee3ebe;Линівка;Линевка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420888403;3;1
62c3d1c5-749b-11df-b112-00215aee3ebe;Линове;Линово;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923884401;3;1
62c3d1c6-749b-11df-b112-00215aee3ebe;Линове;Линово;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;;3;1
62c3d1c7-749b-11df-b112-00215aee3ebe;Линовиця;Линовица;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;7424155600;3;1
62c3d1c8-749b-11df-b112-00215aee3ebe;Линтварівка;Линтваровка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786905;3;1
62c3d215-749b-11df-b112-00215aee3ebe;Лип'янка;Липянка;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321682901;3;1
62c3d216-749b-11df-b112-00215aee3ebe;Лип'янка;Липянка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323385003;3;1
62c3d217-749b-11df-b112-00215aee3ebe;Лип'янка;Липянка;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125784001;3;1
62c3d218-749b-11df-b112-00215aee3ebe;Лип'ятин;Липятин;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524884001;3;1
62c3d1c9-749b-11df-b112-00215aee3ebe;Липа;Липа;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725582603;3;1
62c3d1ca-749b-11df-b112-00215aee3ebe;Липа;Липа;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720885404;3;1
62c3d1cb-749b-11df-b112-00215aee3ebe;Липа;Липа;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622080504;3;1
62c3d1cc-749b-11df-b112-00215aee3ebe;Липа;Липа;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621684205;3;1
62c3d1cd-749b-11df-b112-00215aee3ebe;Липецька Поляна;Липецкая Поляна;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125384801;3;1
62c3d1ce-749b-11df-b112-00215aee3ebe;Липецьке;Липецкое;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122984201;3;1
62c3d1cf-749b-11df-b112-00215aee3ebe;Липи;Липы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822489503;3;1
62c3d1d0-749b-11df-b112-00215aee3ebe;Липина;Липина;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620385311;3;1
62c3d1d1-749b-11df-b112-00215aee3ebe;Липина;Липина;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625886003;3;1
62c3d1d2-749b-11df-b112-00215aee3ebe;Липине;Липино;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824055606;3;1
62c3d1d3-749b-11df-b112-00215aee3ebe;Липини;Липины;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722883401;2;0
62c3d1d4-749b-11df-b112-00215aee3ebe;Липиці;Липицы;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623083202;3;1
62c3d1d5-749b-11df-b112-00215aee3ebe;Липів Ріг;Липов Рог;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423386301;3;1
62c3d1d6-749b-11df-b112-00215aee3ebe;Липівка;Липовка;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923283802;3;1
62c3d1d7-749b-11df-b112-00215aee3ebe;Липівка;Липовка;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624481103;3;1
62c3d1d8-749b-11df-b112-00215aee3ebe;Липівка;Липовка;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625882401;3;1
62c3d1d9-749b-11df-b112-00215aee3ebe;Липівка;Липовка;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623088411;3;1
62c3d1db-749b-11df-b112-00215aee3ebe;Липівка;Липовка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222783801;3;1
62c3d1dc-749b-11df-b112-00215aee3ebe;Липівка;Липовка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825483003;3;1
62c3d1dd-749b-11df-b112-00215aee3ebe;Липівка;Липовка;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425881002;3;1
62c3d1de-749b-11df-b112-00215aee3ebe;Липівка;Липовка;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523982601;3;1
62c3d1df-749b-11df-b112-00215aee3ebe;Липівка;Липовка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520586002;3;1
62c3d1e0-749b-11df-b112-00215aee3ebe;Липівка;Липовка;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823655401;3;1
62c3d1e1-749b-11df-b112-00215aee3ebe;Липівка;Липовка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821280703;3;1
62c3d1e2-749b-11df-b112-00215aee3ebe;Липівське;Липовское;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923280403;3;1
62c3d1e3-749b-11df-b112-00215aee3ebe;Липівці;Липовцы;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623384901;3;1
62c3d1e4-749b-11df-b112-00215aee3ebe;Липки;Липки;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484904;3;1
62c3d1e5-749b-11df-b112-00215aee3ebe;Липки;Липки;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824783301;3;1
62c3d1e6-749b-11df-b112-00215aee3ebe;Липки;Липки;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824480502;3;1
62c3d1e7-749b-11df-b112-00215aee3ebe;Липки;Липки;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621284401;3;1
62c3d1e8-749b-11df-b112-00215aee3ebe;Липкуватівка;Липковатовка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324255303;3;1
62c3d1e9-749b-11df-b112-00215aee3ebe;Липляни;Липляны;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721882704;3;1
62c3d1ea-749b-11df-b112-00215aee3ebe;Липляни;Липляны;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823484004;3;1
62c3d1eb-749b-11df-b112-00215aee3ebe;Липлянщина;Липлянщина;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755134;3;1
62c3d1ec-749b-11df-b112-00215aee3ebe;Липне;Липно;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721855706;3;1
62c3d1ed-749b-11df-b112-00215aee3ebe;Липне;Липно;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823183601;3;1
62c3d1ee-749b-11df-b112-00215aee3ebe;Липне;Липно;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620885803;3;1
62c3d1ef-749b-11df-b112-00215aee3ebe;Липник;Липник;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785201;3;1
62c3d1f0-749b-11df-b112-00215aee3ebe;Липники;Липники;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623281804;3;1
62c3d1f1-749b-11df-b112-00215aee3ebe;Липники;Липники;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787102;3;1
62c3d1f2-749b-11df-b112-00215aee3ebe;Липники;Липники;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622180802;3;1
62c3d1f3-749b-11df-b112-00215aee3ebe;Липники;Липники;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623685503;3;1
62c3d1f4-749b-11df-b112-00215aee3ebe;Липники;Липники;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484001;3;1
62c3d1f5-749b-11df-b112-00215aee3ebe;Липники;Липники;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822883201;3;1
62c3d1f6-749b-11df-b112-00215aee3ebe;Липняги;Липняги;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521484603;3;1
62c3d1f7-749b-11df-b112-00215aee3ebe;Липняжка;Липняжка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521782701;3;1
62c3d1f8-749b-11df-b112-00215aee3ebe;Липняк;Липняк;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924782203;3;0
62c3d1f9-749b-11df-b112-00215aee3ebe;Липова Долина;Липовая Долина;місто;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923255100;3;0
62c3d1fa-749b-11df-b112-00215aee3ebe;Липовани;Липованы;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320584004;3;1
62c3d1fb-749b-11df-b112-00215aee3ebe;Липове;Липовое;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320688103;3;1
62c3d1fc-749b-11df-b112-00215aee3ebe;Липове;Липовое;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224580504;3;1
62c3d203-749b-11df-b112-00215aee3ebe;Липове;Липовое;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425384001;3;1
62c3d204-749b-11df-b112-00215aee3ebe;Липове;Липовое;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522587202;3;1
62c3d205-749b-11df-b112-00215aee3ebe;Липове;Липовое;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325181709;3;1
62c3d206-749b-11df-b112-00215aee3ebe;Липовеньке;Липовенькое;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521484601;3;1
62c3d207-749b-11df-b112-00215aee3ebe;Липовець;Липовец;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625883905;3;1
62c3d208-749b-11df-b112-00215aee3ebe;Липовець;Липовец;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123284502;3;1
62c3d209-749b-11df-b112-00215aee3ebe;Липовець;Липовец;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125385001;3;1
62c3d20a-749b-11df-b112-00215aee3ebe;Липовець;Липовец;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222285401;3;1
62c3d20b-749b-11df-b112-00215aee3ebe;Липовець;Липовец;місто;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522285603;3;0
62c3d20c-749b-11df-b112-00215aee3ebe;Липовець вокзал;Липовец вокзал;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
62c3d20d-749b-11df-b112-00215aee3ebe;Липовець селище;Липовец ;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
62c3d20e-749b-11df-b112-00215aee3ebe;Липовий Ріг;Липовий Рог;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222985402;3;1
62c3d20f-749b-11df-b112-00215aee3ebe;Липовий Скиток;Липовий Скиток;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221482004;3;1
62c3d210-749b-11df-b112-00215aee3ebe;Липовиця;Липовица;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624882901;3;1
62c3d211-749b-11df-b112-00215aee3ebe;Липці;Липцы;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325182001;3;1
62c3d212-749b-11df-b112-00215aee3ebe;Липча;Липча;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125385201;3;0
62c3d213-749b-11df-b112-00215aee3ebe;Липчани;Липчаны;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522683202;3;1
62c3d214-749b-11df-b112-00215aee3ebe;Липчанівка;Липчановка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322881503;3;1
62c3d219-749b-11df-b112-00215aee3ebe;Лиса;Лиса;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124883401;3;1
62c3d21a-749b-11df-b112-00215aee3ebe;Лиса Балка;Лысая Балка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220786614;3;1
62c3d21b-749b-11df-b112-00215aee3ebe;Лиса Гора;Лысая Гора;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825484601;3;1
62c3d21c-749b-11df-b112-00215aee3ebe;Лиса Гора;Лысая Гора;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521210110;3;1
cdc44f68-dde8-11df-9197-00215aee3ebe;Лиса Гора;Лысая Гора;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625685004;3;1
62c3d21d-749b-11df-b112-00215aee3ebe;Лисанівці;Лисановцы;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824483001;3;1
62c3d21e-749b-11df-b112-00215aee3ebe;Лисе;Лысое;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920386304;3;1
62c3d220-749b-11df-b112-00215aee3ebe;Лисець;Лисец;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2625855600;3;1
62c3d221-749b-11df-b112-00215aee3ebe;Лисець;Лисец;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821884401;3;1
62c3d222-749b-11df-b112-00215aee3ebe;Лисин;Лысин;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621485509;3;1
62c3d223-749b-11df-b112-00215aee3ebe;Лисиничі;Лисиничи;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623683801;2;0
62c3d224-749b-11df-b112-00215aee3ebe;Лисинці;Лисинцы;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824280704;3;1
62c3d225-749b-11df-b112-00215aee3ebe;Лисиця;Лисица;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222780402;3;1
62c3d226-749b-11df-b112-00215aee3ebe;Лисича;Лисичья;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325455105;3;1
62c3d227-749b-11df-b112-00215aee3ebe;Лисича Балка;Лисичья Балка;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122283501;3;1
62c3d22c-749b-11df-b112-00215aee3ebe;Лисиче;Лисичье;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823984701;3;1
62c3d22e-749b-11df-b112-00215aee3ebe;Лисичин;Лисичин;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622280402;3;1
62c3d22f-749b-11df-b112-00215aee3ebe;Лисичинці;Лисичинцы;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124684201;3;1
62c3d231-749b-11df-b112-00215aee3ebe;Лисичники;Лисичники;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122084702;3;1
62c3d22d-749b-11df-b112-00215aee3ebe;Лисичово;Лисичево;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121985601;3;1
62c3d232-749b-11df-b112-00215aee3ebe;Лисівка;Лысовка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323484404;3;1
62c3d234-749b-11df-b112-00215aee3ebe;Лисівка;Лысовка;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824755404;3;1
62c3d235-749b-11df-b112-00215aee3ebe;Лисівка;Лысовка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825888402;3;1
62c3d236-749b-11df-b112-00215aee3ebe;Лисівка;Лысовка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320484101;3;1
62c3d237-749b-11df-b112-00215aee3ebe;Лисівці;Лысовцы;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122085801;3;1
62c3d238-749b-11df-b112-00215aee3ebe;Лисівщина;Лысовщина;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325480702;3;1
62c3d239-749b-11df-b112-00215aee3ebe;Лиски;Лыски;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424184302;3;1
62c3d23a-749b-11df-b112-00215aee3ebe;Лисків;Лысков;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621583604;3;1
62c3d23b-749b-11df-b112-00215aee3ebe;Лисківка;Лысковка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655104;3;1
62c3d23c-749b-11df-b112-00215aee3ebe;Лисківці;Лесковцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822489504;3;1
62c3d23d-749b-11df-b112-00215aee3ebe;Лисконоги;Лисконоги;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321284508;3;1
62c3d23e-749b-11df-b112-00215aee3ebe;Лисняки;Лысняки;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723310110;3;1
8976c19d-b450-11ed-80ec-000c29800ae7;Лисняки;Лисняки ;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723310106;3;1
62c3d23f-749b-11df-b112-00215aee3ebe;Лисовичі;Лысовичи;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625384001;3;1
62c3d240-749b-11df-b112-00215aee3ebe;Лисогірка;Лысогорка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322883502;3;1
62c3d241-749b-11df-b112-00215aee3ebe;Лисогірка;Лысогорка;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122583601;3;1
62c3d242-749b-11df-b112-00215aee3ebe;Лисогірка;Лысогорка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322184901;3;1
62c3d243-749b-11df-b112-00215aee3ebe;Лисогірка;Лысогорка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522487402;3;1
62c3d244-749b-11df-b112-00215aee3ebe;Лисогірка;Лысогорка;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521083701;3;1
62c3d245-749b-11df-b112-00215aee3ebe;Лисогірка;Лысогорка;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824784002;3;1
62c3d246-749b-11df-b112-00215aee3ebe;Лисогірка;Лысогорка;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823085803;3;1
62c3d247-749b-11df-b112-00215aee3ebe;Лисогірка;Лысогорка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822481804;2;0
62c3d248-749b-11df-b112-00215aee3ebe;Лисогірка;Лысогорка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821884501;3;1
62c3d249-749b-11df-b112-00215aee3ebe;Лисогора;Лисогора;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520688903;3;1
62c3d24a-749b-11df-b112-00215aee3ebe;Лисогори;Лысогоры;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421789202;3;1
62c3d24c-749b-11df-b112-00215aee3ebe;Лисогубівка;Лысогубовка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922081103;3;1
62c3d251-749b-11df-b112-00215aee3ebe;Листв'яний;Листвяный;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623086002;3;1
62c3d24d-749b-11df-b112-00215aee3ebe;Листвин;Листвин;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824284001;3;1
62c3d24e-749b-11df-b112-00215aee3ebe;Листвин;Листвин;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621682605;3;1
62c3d24f-749b-11df-b112-00215aee3ebe;Листвина;Листвина;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122586702;3;1
62c3d253-749b-11df-b112-00215aee3ebe;Листівка;Листовка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323684003;3;1
62c3d255-749b-11df-b112-00215aee3ebe;Листопадівка;Листопадовка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521487806;3;1
62c3d256-749b-11df-b112-00215aee3ebe;Листопадове;Листопадово;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523884001;3;1
62c3d257-749b-11df-b112-00215aee3ebe;Лисянка;Лысянка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820682603;3;1
62c3d258-749b-11df-b112-00215aee3ebe;Лисянка;Лысянка;місто;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122855100;3;1
62c3d259-749b-11df-b112-00215aee3ebe;Лисянка;Лысянка;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
33ec0016-ddd5-11df-9197-00215aee3ebe;Лисянка;Лысянка;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520685502;3;1
62c3d25a-749b-11df-b112-00215aee3ebe;Лисятичі;Лисятичи;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625383601;3;1
62c3d25b-749b-11df-b112-00215aee3ebe;Литвиненки;Литвиненки;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322483205;3;1
62c3d25d-749b-11df-b112-00215aee3ebe;Литвинець;Литвинец;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122084301;3;1
62c3d25e-749b-11df-b112-00215aee3ebe;Литвини;Литвины;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321880905;3;1
62c3d25f-749b-11df-b112-00215aee3ebe;Литвинів;Литвинов;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
62c3d260-749b-11df-b112-00215aee3ebe;Литвинівка;Литвиновка;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324280503;3;1
62c3d262-749b-11df-b112-00215aee3ebe;Литвинівка;Литвиновка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321288003;3;1
62c3d264-749b-11df-b112-00215aee3ebe;Литвинівка;Литвиновка;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221884401;3;1
62c3d265-749b-11df-b112-00215aee3ebe;Литвинівка;Литвиновка;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120984001;3;1
62c3d266-749b-11df-b112-00215aee3ebe;Литвинове;Литвиново;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322655106;3;1
62c3d268-749b-11df-b112-00215aee3ebe;Литвиновичі;Литвиновичи;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922685501;3;1
62c3d269-749b-11df-b112-00215aee3ebe;Литвяки;Литвяки;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322883901;3;1
62c3d26a-749b-11df-b112-00215aee3ebe;Литовеж;Литовеж;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721182401;3;1
62c3d26b-749b-11df-b112-00215aee3ebe;Литовка;Литовка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920380403;3;1
62c3d26c-749b-11df-b112-00215aee3ebe;Литовка;Литовка;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620981105;3;1
62c3d26d-749b-11df-b112-00215aee3ebe;Литовки;Литовки;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324285007;3;1
62c3d26e-749b-11df-b112-00215aee3ebe;Литовщина;Литовщина;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323983003;3;1
62c3d26f-749b-11df-b112-00215aee3ebe;Литячі;Литячи;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122085601;3;1
62c3d270-749b-11df-b112-00215aee3ebe;Лифине;Лифино;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982406;3;1
62c3d271-749b-11df-b112-00215aee3ebe;Лихачиха;Лихачиха;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221684802;3;1
62c3d272-749b-11df-b112-00215aee3ebe;Лихачів;Лихачов;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423882501;3;1
62c3d273-749b-11df-b112-00215aee3ebe;Лихачівка;Лихачовка;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322282505;3;1
62c3d274-749b-11df-b112-00215aee3ebe;Лихачівка;Лихачовка;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623889003;3;1
62c3d275-749b-11df-b112-00215aee3ebe;Лихівка;Лиховка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224555600;3;1
62c3d276-749b-11df-b112-00215aee3ebe;Лихове;Лихово;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324284004;3;1
62c3d278-749b-11df-b112-00215aee3ebe;Лихоліти;Лихолеты;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125185001;3;1
62c3d279-749b-11df-b112-00215aee3ebe;Лихолітки;Лихолетки;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422085101;3;1
62c3d27a-749b-11df-b112-00215aee3ebe;Лихопілля;Лихополье;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320480702;3;1
62c3d27b-749b-11df-b112-00215aee3ebe;Лихосілка;Лихоселка;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825881203;3;1
62c3d27c-749b-11df-b112-00215aee3ebe;Личани;Лычаны;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721855401;3;1
62c3d27d-749b-11df-b112-00215aee3ebe;Личанка;Лычанка;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222484601;3;1
62c3d27e-749b-11df-b112-00215aee3ebe;Личини;Личины;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721485001;3;1
62c3d27f-749b-11df-b112-00215aee3ebe;Личівка;Лычовка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820984302;3;1
62c3d280-749b-11df-b112-00215aee3ebe;Лички;Лички;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721887203;3;1
62c3d281-749b-11df-b112-00215aee3ebe;Личківці;Лычковцы;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121683901;3;1
62c3d282-749b-11df-b112-00215aee3ebe;Личкове;Лычково;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222384001;3;1
62c3d283-749b-11df-b112-00215aee3ebe;Личмани;Личманы;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824283705;3;1
cdc44f33-dde8-11df-9197-00215aee3ebe;Лишава;Лишава;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523182602;3;1
62c3d284-749b-11df-b112-00215aee3ebe;Лишнівка;Лишневка;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723685506;3;1
62c3d285-749b-11df-b112-00215aee3ebe;Лишня;Лишня;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222784201;3;1
62c3d286-749b-11df-b112-00215aee3ebe;Лище;Лыще;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722883601;3;1
62c3d16e-749b-11df-b112-00215aee3ebe;Лібенталь (Ленінталь);Лібенталь (Ленінталь);село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123781602;3;1
68cf236c-749b-11df-b112-00215aee3ebe;Лібухова (Максимівка);Лебухо?ва (Максимовка);село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625186103;3;1
62c3d289-749b-11df-b112-00215aee3ebe;Лівенське;Ливенское;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323483001;3;1
62c3d28a-749b-11df-b112-00215aee3ebe;Лівинці;Левинцы;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322085601;3;1
62c3d28b-749b-11df-b112-00215aee3ebe;Лівобережна Сокілка;Левобережная Соколка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321885404;3;1
62c3d28c-749b-11df-b112-00215aee3ebe;Лівчиці;Ливчицы;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620986603;3;1
62c3d28d-749b-11df-b112-00215aee3ebe;Лівчиці;Ливчицы;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621555304;3;1
62c3d28e-749b-11df-b112-00215aee3ebe;Лідава;Лидава;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5622687606;3;1
62c3d28f-749b-11df-b112-00215aee3ebe;Лідин;Лидин;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420680403;3;1
62c3d290-749b-11df-b112-00215aee3ebe;Лідине;Лидино;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920688204;3;1
62c3d293-749b-11df-b112-00215aee3ebe;Лідихів;Ледыхов;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123484101;3;1
62c3d294-749b-11df-b112-00215aee3ebe;Лідихівка;Ледыховка;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824783501;3;1
62c3d295-749b-11df-b112-00215aee3ebe;Лідівка;Лидовка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125486004;3;1
62c3d296-749b-11df-b112-00215aee3ebe;Лідівка;Лидовка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823482804;3;1
62c3d297-749b-11df-b112-00215aee3ebe;Лідівка;Лидовка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824084202;3;1
62c3d298-749b-11df-b112-00215aee3ebe;Лідіївка;Лидиевка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822783513;3;1
62c3d299-749b-11df-b112-00215aee3ebe;Лізинка;Лизинка;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121883204;3;1
62c3d29a-749b-11df-b112-00215aee3ebe;Лізки;Лизки;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320655403;3;1
62c3d29b-749b-11df-b112-00215aee3ebe;Лізки;Лизки;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122080702;3;1
62c3d29c-749b-11df-b112-00215aee3ebe;Лікареве;Ликарево;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523810102;3;1
62c3d29d-749b-11df-b112-00215aee3ebe;Лікарівка;Лекаревка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520384601;3;1
62c3d29e-749b-11df-b112-00215aee3ebe;Лікарівщина;Лекаревщина;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923581704;3;1
62c3d2a0-749b-11df-b112-00215aee3ebe;Лікарське;Лекарское;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786704;3;1
62c3d2a1-749b-11df-b112-00215aee3ebe;Лікіцари;Ликицары;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123284503;3;1
62c3d2a3-749b-11df-b112-00215aee3ebe;Ліктів;Локтев;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625583205;3;1
62c3d2a4-749b-11df-b112-00215aee3ebe;Лімна;Лимна;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625585101;3;1
62c3d2a5-749b-11df-b112-00215aee3ebe;Лінеччина;Линеччина;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523180403;3;1
62c3d2a8-749b-11df-b112-00215aee3ebe;Лінія;Линия;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621555901;3;1
62c3d2a9-749b-11df-b112-00215aee3ebe;Лінці;Линцы;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124883003;3;1
62c3d2aa-749b-11df-b112-00215aee3ebe;Лінчин;Ленчин;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620480402;3;1
62c3d2ab-749b-11df-b112-00215aee3ebe;Ліпибоки;Липыбоки;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821281903;3;1
62c3d2ac-749b-11df-b112-00215aee3ebe;Ліпини;Лепины;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821880602;3;1
62c3d2ad-749b-11df-b112-00215aee3ebe;Ліпляве;Лепляво;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122083901;3;1
62c3d2ae-749b-11df-b112-00215aee3ebe;Ліс;Лес;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625888602;3;1
62c3d2af-749b-11df-b112-00215aee3ebe;Лісарня;Лесарня;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122781403;3;1
62c3d2b1-749b-11df-b112-00215aee3ebe;Лісівка;Лесовка;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820384002;3;1
62c3d2b2-749b-11df-b112-00215aee3ebe;Лісівщина;Лесовщина;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822087904;3;1
62c3d2b3-749b-11df-b112-00215aee3ebe;Лісівщина;Лесовщина;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822382801;3;1
62c3d2b4-749b-11df-b112-00215aee3ebe;Ліски;Лески;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;;3;1
62c3d2b5-749b-11df-b112-00215aee3ebe;Ліски;Лески;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720580407;3;1
62c3d2b6-749b-11df-b112-00215aee3ebe;Ліски;Лески;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122483005;3;1
62c3d2b7-749b-11df-b112-00215aee3ebe;Ліски;Лески;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122783202;3;0
62c3d2b8-749b-11df-b112-00215aee3ebe;Ліски;Лески;село;903ccab3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122381901;3;1
62c3d2b9-749b-11df-b112-00215aee3ebe;Ліски;Лески;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623283003;3;1
62c3d2ba-749b-11df-b112-00215aee3ebe;Ліски;Лески;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824481303;3;1
62c3d2bb-749b-11df-b112-00215aee3ebe;Ліски;Лески;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821182003;3;1
62c3d2bc-749b-11df-b112-00215aee3ebe;Ліски;Лески;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425580805;3;1
62c3d2bd-749b-11df-b112-00215aee3ebe;Ліски;Лески;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423085901;3;1
62c3d2be-749b-11df-b112-00215aee3ebe;Ліски;Лески;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524083001;3;1
62c3d2bf-749b-11df-b112-00215aee3ebe;Ліски;Лески;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820388009;3;1
62c3d2c0-749b-11df-b112-00215aee3ebe;Лісківка;Лесковка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424482505;3;1
62c3d2c1-749b-11df-b112-00215aee3ebe;Лісківці;Лесковци;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822487102;2;1
62c3d2c2-749b-11df-b112-00215aee3ebe;Лісківщина;Лесковщина;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924181402;3;1
62c3d2c3-749b-11df-b112-00215aee3ebe;Лісківщина;Лесковщина;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424787004;3;1
62c3d2c4-749b-11df-b112-00215aee3ebe;Лісковець;Лисковец;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122482801;3;1
62c3d2c5-749b-11df-b112-00215aee3ebe;Лісконоги;Лесконоги;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423686201;3;1
62c3d2c6-749b-11df-b112-00215aee3ebe;Лісна;Лесная;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822182202;3;1
62c3d2c7-749b-11df-b112-00215aee3ebe;Лісна Велесниця;Лесная Велесница;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624084602;3;1
62c3d2c9-749b-11df-b112-00215aee3ebe;Лісна Колона;Лесная Колонна;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823483203;3;1
62c3d2cb-749b-11df-b112-00215aee3ebe;Лісна Поляна;Лесная Поляна;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520882212;3;1
62c3d2cc-749b-11df-b112-00215aee3ebe;Лісна Рудня;Лесная Рудня;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821486704;3;1
62c3d2cd-749b-11df-b112-00215aee3ebe;Лісна Слобідка;Лесная Слободка;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623283401;3;1
62c3d2cf-749b-11df-b112-00215aee3ebe;Лісна Тарновиця;Лесная Тарновица;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2624083201;3;1
62c3d2d0-749b-11df-b112-00215aee3ebe;Лісне;Лесное;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321886503;3;1
62c3d2d1-749b-11df-b112-00215aee3ebe;Лісне;Лесное;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924480903;3;1
62c3d2d2-749b-11df-b112-00215aee3ebe;Лісне;Лесное;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925386602;3;1
62c3d2d3-749b-11df-b112-00215aee3ebe;Лісне;Лесное;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922385003;3;1
62c3d2d4-749b-11df-b112-00215aee3ebe;Лісне;Лесное;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925083902;3;1
62c3d2d5-749b-11df-b112-00215aee3ebe;Лісне;Лесное;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224283403;3;1
62c3d2d6-749b-11df-b112-00215aee3ebe;Лісне;Лесное;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322083002;3;1
62c3d2d7-749b-11df-b112-00215aee3ebe;Лісне;Лесное;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321784502;3;1
62c3d2d8-749b-11df-b112-00215aee3ebe;Лісне;Лесное;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124784601;3;1
62c3d2d9-749b-11df-b112-00215aee3ebe;Лісне;Лесное;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323682403;3;1
62c3d2e2-749b-11df-b112-00215aee3ebe;Лісне;Лесное;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222488205;2;1
62c3d2e3-749b-11df-b112-00215aee3ebe;Лісне;Лесное;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425580806;3;1
62c3d2e4-749b-11df-b112-00215aee3ebe;Лісне;Лесное;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522483403;3;1
62c3d2e5-749b-11df-b112-00215aee3ebe;Лісне;Лесное;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522580602;3;1
62c3d2e6-749b-11df-b112-00215aee3ebe;Лісне;Лесное;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823681003;3;1
5cb619aa-749b-11df-b112-00215aee3ebe;Лісне (Комінтерн);Лесное (Коминтерн);село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925680203;3;1
62c3d2e8-749b-11df-b112-00215aee3ebe;Лісний Хлібичин;Лесной Хлебичин;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623283601;3;1
62c3d2e9-749b-11df-b112-00215aee3ebe;Лісники;Лесники;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6110590001;3;1
62c3d2ea-749b-11df-b112-00215aee3ebe;Лісники;Лесники;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222484501;3;0
62c3d2eb-749b-11df-b112-00215aee3ebe;Лісниче;Лесничье;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520482803;3;1
62c3d2ec-749b-11df-b112-00215aee3ebe;Лісничівка;Лесничевка;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290014;3;1
62c3d2ed-749b-11df-b112-00215aee3ebe;Лісничівка;Лесничевка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525386403;3;1
62c3d2e7-749b-11df-b112-00215aee3ebe;Лісновичі;Лесновичи;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620987202;3;1
62c3d2f0-749b-11df-b112-00215aee3ebe;Лісняки;Лесняки;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725085002;3;1
62c3d2f1-749b-11df-b112-00215aee3ebe;Лісо-Березівка;Лесо-Березовка;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823087003;3;1
62c3d2f2-749b-11df-b112-00215aee3ebe;Лісобуда;Лесобуда;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822382802;3;1
62c3d2f3-749b-11df-b112-00215aee3ebe;Лісова;Лесовая;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624484303;3;1
62c3d2f5-749b-11df-b112-00215aee3ebe;Лісова Волиця;Лесовая Волица;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822784805;3;1
62c3d2f4-749b-11df-b112-00215aee3ebe;Лісова Лисіївка;Лесовая Лысиевка;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521684203;3;1
62c3d2f6-749b-11df-b112-00215aee3ebe;Лісова Пісня;Лесовая Песня;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;*072575510;3;1
62c3d2f7-749b-11df-b112-00215aee3ebe;Лісова Поляна;Лесовая Поляна;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924485603;3;1
62c3d2f8-749b-11df-b112-00215aee3ebe;Лісова Поляна;Лесовая Поляна;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820655402;3;1
62c3d2f9-749b-11df-b112-00215aee3ebe;Лісова Поляна;Лесовая Поляна;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420386006;3;1
62c3d2fa-749b-11df-b112-00215aee3ebe;Лісова Поляна;Лесовая Поляна;;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;;3;1
62c3d2fb-749b-11df-b112-00215aee3ebe;Лісова Слобідка;Лесовая Слободка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820884504;3;1
62c3d2fc-749b-11df-b112-00215aee3ebe;Лісова Слобідка;Лесовая Слободка;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325183803;3;1
62c3d2fd-749b-11df-b112-00215aee3ebe;Лісове;Лесовое;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723684001;3;1
62c3d2fe-749b-11df-b112-00215aee3ebe;Лісове;Лесовое;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821482402;3;1
62c3d2ff-749b-11df-b112-00215aee3ebe;Лісове;Лесовое;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823681402;3;1
62c3d300-749b-11df-b112-00215aee3ebe;Лісове;Лесовое;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622780404;3;1
62c3d301-749b-11df-b112-00215aee3ebe;Лісове;Лесовое;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620687004;3;1
62c3d302-749b-11df-b112-00215aee3ebe;Лісове;Лесовое;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620383204;3;1
62c3d303-749b-11df-b112-00215aee3ebe;Лісове;Лесовое;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824483602;3;1
62c3d304-749b-11df-b112-00215aee3ebe;Лісове;Лесовое;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823482805;3;1
62c3d305-749b-11df-b112-00215aee3ebe;Лісове;Лесовое;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820687602;3;1
62c3d306-749b-11df-b112-00215aee3ebe;Лісове;Лесовое;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422487904;3;1
62c3d307-749b-11df-b112-00215aee3ebe;Лісове;Лесовое;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423389503;3;1
62c3d308-749b-11df-b112-00215aee3ebe;Лісове;Лесовое;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124085801;3;1
62c3d309-749b-11df-b112-00215aee3ebe;Лісове;Лесовое;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520282603;3;1
62c3d30a-749b-11df-b112-00215aee3ebe;Лісове;Лесовое;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621883901;3;1
62c3d30b-749b-11df-b112-00215aee3ebe;Лісове;Лесовое;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625085602;3;1
62c3d30c-749b-11df-b112-00215aee3ebe;Лісове;Лесовое;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323255404;3;1
325c57fa-ddd2-11df-9197-00215aee3ebe;Лісове;Лесовое;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520555500;3;1
cdc44f59-dde8-11df-9197-00215aee3ebe;Лісове;Лесовое;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825282402;3;1
74717c1c-65ab-11e0-971d-003048d2b472;Лісове;Лесовое;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520555500;3;1
8bd3663a-8350-11e8-80d4-1c98ec135261;Лісове;Лесное;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221885905;3;1
74dc4998-749b-11df-b112-00215aee3ebe;Лісове ( Петрівське );Петровское;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121884002;3;1
62c3d30d-749b-11df-b112-00215aee3ebe;Лісовинівка;Лесовиновка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
62c3d30e-749b-11df-b112-00215aee3ebe;Лісовичі;Лесовичи;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221884402;3;1
62c3d30f-749b-11df-b112-00215aee3ebe;Лісовичі;Лесовичи;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224483201;3;1
62c3d310-749b-11df-b112-00215aee3ebe;Лісові;Лесовые;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621882603;3;1
62c3d311-749b-11df-b112-00215aee3ebe;Лісові Гринівці;Лесные Гриневцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825083601;2;0
62c3d312-749b-11df-b112-00215aee3ebe;Лісові Сорочинці;Лесные Сорочинцы;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424184901;3;1
56bdd21e-749b-11df-b112-00215aee3ebe;Лісові Хутори;Лесовые Хутора;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423810102;3;1
62c3d313-749b-11df-b112-00215aee3ebe;Лісоводи;Лесоводы;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821284504;3;1
62c3d315-749b-11df-b112-00215aee3ebe;Лісовське;Лесовское;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6311090003;3;1
62c3d316-749b-11df-b112-00215aee3ebe;Лісогірка;Лесогорка;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524885002;3;1
62c3d317-749b-11df-b112-00215aee3ebe;Лісогірка;Лесогорка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821284801;3;1
62c3d318-749b-11df-b112-00215aee3ebe;Лісок;Лесок;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620684004;3;1
62c3d319-749b-11df-b112-00215aee3ebe;Лісок;Лесок;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625888802;3;1
62c3d31a-749b-11df-b112-00215aee3ebe;Лісопитомник;Лесопитомник;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221884711;3;1
62c3d31b-749b-11df-b112-00215aee3ebe;Лісопіль;Лесополь;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623483903;3;1
62c3d31c-749b-11df-b112-00215aee3ebe;Лісопотік;Лесопоток;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625889213;3;1
62c3d31d-749b-11df-b112-00215aee3ebe;Лісянщина;Лисянщина;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324887713;3;1
62c3d31e-749b-11df-b112-00215aee3ebe;Літвиця;Литвица;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621888206;3;1
62c3d31f-749b-11df-b112-00215aee3ebe;Літин;Литин;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725587603;3;1
62c3d320-749b-11df-b112-00215aee3ebe;Літин;Литин;місто;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522455100;2;0
62c3d321-749b-11df-b112-00215aee3ebe;Літинка;Литинка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522484201;3;1
7ad94fa2-749b-11df-b112-00215aee3ebe;Літинські Хутори;Литинским Хутора;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522486404;3;1
62c3d322-749b-11df-b112-00215aee3ebe;Літиня;Летыня;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621284501;3;1
62c3d323-749b-11df-b112-00215aee3ebe;Літки;Летки;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221284801;3;1
62c3d324-749b-11df-b112-00215aee3ebe;Літки;Литки;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822883001;3;1
62c3d325-749b-11df-b112-00215aee3ebe;Літки;Литки;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821587802;3;1
62c3d326-749b-11df-b112-00215aee3ebe;Літовище;Литовище;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125884801;3;1
62c3d327-749b-11df-b112-00215aee3ebe;Літовище;Литовище;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620384402;3;1
62c3d328-749b-11df-b112-00215aee3ebe;Літогоща;Литогоща;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724583201;3;1
62c3d329-749b-11df-b112-00215aee3ebe;Літочки;Леточки;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221285201;3;1
62c3d32a-749b-11df-b112-00215aee3ebe;Літятин;Литятин;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120483301;3;1
62c3d32c-749b-11df-b112-00215aee3ebe;Лішня;Лешня;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123480704;3;1
62c3d32d-749b-11df-b112-00215aee3ebe;Лішня;Лешня;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621284801;3;1
62c3d32e-749b-11df-b112-00215aee3ebe;Лішня;Лешня;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621455306;3;1
62c3d32f-749b-11df-b112-00215aee3ebe;Ліщани;Лищаны;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822183301;3;1
62c3d330-749b-11df-b112-00215aee3ebe;Ліщанці;Лещанцы;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121283201;3;1
62c3d331-749b-11df-b112-00215aee3ebe;Ліщин;Лещин;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822084401;3;1
62c3d332-749b-11df-b112-00215aee3ebe;Ліщини;Лещины;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621582102;3;1
62c3d333-749b-11df-b112-00215aee3ebe;Ліщинівка;Лещиновка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321884407;3;1
62c3d334-749b-11df-b112-00215aee3ebe;Ліщинівка;Лещиновка;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124683501;3;1
62c3d335-749b-11df-b112-00215aee3ebe;Ліщинка;Лещинка;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222285201;3;1
62c3d336-749b-11df-b112-00215aee3ebe;Ліщинці;Лещинцы;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523487003;3;1
62c3d337-749b-11df-b112-00215aee3ebe;Лобанівка;Лобановка;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322082508;3;1
62c3d33d-749b-11df-b112-00215aee3ebe;Лобачі;Лобачи;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281901;3;1
62c3d33e-749b-11df-b112-00215aee3ebe;Лобачів;Лобачов;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221683601;2;1
62c3d33f-749b-11df-b112-00215aee3ebe;Лобачівка;Лобачовка;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720883201;3;1
62c3d340-749b-11df-b112-00215aee3ebe;Лобківка;Лобковка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5910490503;3;1
62c3d341-749b-11df-b112-00215aee3ebe;Лобкова Балка;Лобковая Балка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324887715;3;1
62c3d343-749b-11df-b112-00215aee3ebe;Лобна;Лобна;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155120;3;1
62c3d344-749b-11df-b112-00215aee3ebe;Лободине;Лободино;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320483404;3;1
62c3d345-749b-11df-b112-00215aee3ebe;Лободівщина;Лободовщина;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982407;3;1
62c3d346-749b-11df-b112-00215aee3ebe;Лобойківка;Лобойковка;село;bad3ac3c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1223781701;3;1
62c3d348-749b-11df-b112-00215aee3ebe;Лобріївка;Лобриевка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820682203;3;1
62c3d349-749b-11df-b112-00215aee3ebe;Ловаги;Ловаги;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624883602;3;1
62c3d34a-749b-11df-b112-00215aee3ebe;Ловинь;Ловынь;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424483601;3;1
62c3d34b-749b-11df-b112-00215aee3ebe;Ловища;Ловища;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725583403;3;1
62c3d34d-749b-11df-b112-00215aee3ebe;Логачівка;Логачовка;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325183005;3;1
62c3d34e-749b-11df-b112-00215aee3ebe;Логвин;Логвин;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221684001;2;1
62c3d34f-749b-11df-b112-00215aee3ebe;Логвинівка;Логвиновка;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323281208;3;1
62c3d352-749b-11df-b112-00215aee3ebe;Лодзянівка;Лодзяновка;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823655402;3;1
62c3d353-749b-11df-b112-00215aee3ebe;Лоєва;Лоевая;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624083401;3;1
73e94bf3-ddc1-11df-9197-00215aee3ebe;Лоза;Лоза;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121910102;3;1
62c3d355-749b-11df-b112-00215aee3ebe;Лози;Лозы;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324884607;3;1
62c3d356-749b-11df-b112-00215aee3ebe;Лози;Лозы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122486001;3;1
62c3d358-749b-11df-b112-00215aee3ebe;Лозино;Лозино;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625885201;3;1
62c3d359-749b-11df-b112-00215aee3ebe;Лозичне;Лозичное;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825588504;3;1
62c3d35a-749b-11df-b112-00215aee3ebe;Лозівка;Лозовка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324080305;3;1
62c3d35b-749b-11df-b112-00215aee3ebe;Лозівка;Лозовка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124688203;3;1
62c3d35c-749b-11df-b112-00215aee3ebe;Лозівка;Лозовка;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125084102;3;1
62c3d35d-749b-11df-b112-00215aee3ebe;Лозівка;Лозовка;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;;3;1
62c3d35e-749b-11df-b112-00215aee3ebe;Лозівка;Лозовка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521486807;3;1
62c3d35f-749b-11df-b112-00215aee3ebe;Лозівок;Лозовок;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124981502;3;1
b36f1a46-db87-11df-9197-00215aee3ebe;Лозівське;Лозовское;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324584205;3;1
62c3d361-749b-11df-b112-00215aee3ebe;Лозки;Лозки;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322055103;3;1
62c3d362-749b-11df-b112-00215aee3ebe;Лозки;Лозки;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620886201;3;1
62c3d363-749b-11df-b112-00215aee3ebe;Лозна;Лозна;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524884201;3;1
62c3d365-749b-11df-b112-00215aee3ebe;Лозни;Лозны;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823085002;3;1
62c3d366-749b-11df-b112-00215aee3ebe;Лозниця;Лозница;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755107;3;1
62c3d368-749b-11df-b112-00215aee3ebe;Лозова;Лозовая;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125284501;3;1
62c3d369-749b-11df-b112-00215aee3ebe;Лозова;Лозовая;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320882503;3;1
62c3d36a-749b-11df-b112-00215aee3ebe;Лозова;Лозовая;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321080503;3;1
62c3d36b-749b-11df-b112-00215aee3ebe;Лозова;Лозовая;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323182001;3;1
62c3d36c-749b-11df-b112-00215aee3ebe;Лозова;Лозовая;місто;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6311000000;2;0
62c3d36d-749b-11df-b112-00215aee3ebe;Лозова;Лозовая;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524884501;3;1
62c3d36e-749b-11df-b112-00215aee3ebe;Лозова;Лозовая;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525384801;3;1
62c3d36f-749b-11df-b112-00215aee3ebe;Лозова;Лозовая;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820983502;3;1
62c3d389-749b-11df-b112-00215aee3ebe;Лозовата;Лозовата;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523786003;3;1
62c3d371-749b-11df-b112-00215aee3ebe;Лозове;Лозовое;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922387404;3;1
62c3d372-749b-11df-b112-00215aee3ebe;Лозове;Лозовое;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223882001;3;1
62c3d373-749b-11df-b112-00215aee3ebe;Лозове;Лозовое;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655113;3;1
62c3d376-749b-11df-b112-00215aee3ebe;Лозове;Лозовое;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123983203;3;1
62c3d378-749b-11df-b112-00215aee3ebe;Лозове;Лозовое;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520980503;3;1
62c3d380-749b-11df-b112-00215aee3ebe;Лозове;Лозовое;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425182505;3;1
62c3d381-749b-11df-b112-00215aee3ebe;Лозове;Лозовое;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524984001;3;1
62c3d382-749b-11df-b112-00215aee3ebe;Лозове;Лозовое;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821555700;3;1
62c3d383-749b-11df-b112-00215aee3ebe;Лозовенька;Лозовенька;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320283601;3;1
62c3d384-749b-11df-b112-00215aee3ebe;Лозовий Яр;Лозовой Яр;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225583601;3;1
62c3d385-749b-11df-b112-00215aee3ebe;Лозовик;Лозовик;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222783802;3;1
62c3d387-749b-11df-b112-00215aee3ebe;Лозово-Грушеве;Лозово-Грушевое;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922985203;3;1
b36f1a41-db87-11df-9197-00215aee3ebe;Лозовське;Лозовское;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;;3;1
62c3d388-749b-11df-b112-00215aee3ebe;Лозувата;Лозоватая;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522283003;3;1
62c3d38a-749b-11df-b112-00215aee3ebe;Лозувата;Лозоватая;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525584801;3;1
62c3d38b-749b-11df-b112-00215aee3ebe;Лозуватка;Лозоватка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325480302;3;1
62c3d38c-749b-11df-b112-00215aee3ebe;Лозуватка;Лозоватка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225683004;3;1
62c3d38d-749b-11df-b112-00215aee3ebe;Лозуватка;Лозоватка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224555305;3;1
62c3d38e-749b-11df-b112-00215aee3ebe;Лозуватка;Лозоватка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222085504;3;1
62c3d38f-749b-11df-b112-00215aee3ebe;Лозуватка;Лозоватка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224855303;3;1
62c3d390-749b-11df-b112-00215aee3ebe;Лозуватка;Лозоватка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221884001;3;1
62c3d391-749b-11df-b112-00215aee3ebe;Лозуватка;Лозоватка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825487603;3;1
62c3d394-749b-11df-b112-00215aee3ebe;Лозуватка;Лозоватка;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125784401;3;1
62c3d395-749b-11df-b112-00215aee3ebe;Лозуватка;Лозоватка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523655103;3;1
62c3d396-749b-11df-b112-00215aee3ebe;Лозуватка;Лозоватка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523182801;3;1
62c3d397-749b-11df-b112-00215aee3ebe;Лозуватка;Лозоватка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524085604;3;1
62c3d398-749b-11df-b112-00215aee3ebe;Лозуватка;Лозоватка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522581205;3;1
62c3d399-749b-11df-b112-00215aee3ebe;Лозуватка;Лозоватка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520355403;3;1
62c3d39a-749b-11df-b112-00215aee3ebe;Лозуватка;Лозоватка;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524680802;3;1
62c3d39b-749b-11df-b112-00215aee3ebe;Лозуватка;Лозоватка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522882301;3;1
8716259b-749b-11df-b112-00215aee3ebe;Лозуватське;Лозуватське;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222085507;3;1
62c3d39d-749b-11df-b112-00215aee3ebe;Лозянський;Лозянский;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122483201;3;1
62c3d39e-749b-11df-b112-00215aee3ebe;Локачі;Локачи;місто;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722455100;3;1
62c3d39f-749b-11df-b112-00215aee3ebe;Локітка;Локотка;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625686903;3;1
62c3d3a0-749b-11df-b112-00215aee3ebe;Локіть;Локоть;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121987203;3;1
62c3d3a1-749b-11df-b112-00215aee3ebe;Локнисте;Локнистое;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423086301;3;1
62c3d3a2-749b-11df-b112-00215aee3ebe;Локниця;Локница;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622282701;3;1
62c3d3a3-749b-11df-b112-00215aee3ebe;Локня;Локня;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922685901;3;1
62c3d3a4-749b-11df-b112-00215aee3ebe;Локня;Локня;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924189603;3;1
62c3d3a5-749b-11df-b112-00215aee3ebe;Локня;Локня;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924780702;3;1
62c3d3a6-749b-11df-b112-00215aee3ebe;Локутки;Локутки;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723384910;3;1
62c3d3a7-749b-11df-b112-00215aee3ebe;Лолин;Лолин;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622082901;3;1
62c3d3a8-749b-11df-b112-00215aee3ebe;Ломазів;Ломазов;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522684002;3;1
c8674a97-dbb0-11df-9197-00215aee3ebe;Ломаки;Ломаки;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322887905;3;1
62c3d3aa-749b-11df-b112-00215aee3ebe;Ломанка;Ломанка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423687804;3;1
62c3d3ab-749b-11df-b112-00215aee3ebe;Ломачанка;Ломачанка;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722183605;3;1
62c3d3ac-749b-11df-b112-00215aee3ebe;Ломачинці;Ломачинцы;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324085501;3;1
62c3d3ad-749b-11df-b112-00215aee3ebe;Ломачинці;Ломачинцы;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820681503;3;1
62c3d3ae-749b-11df-b112-00215aee3ebe;Ломленка;Ломленка;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925681903;3;1
62c3d3b3-749b-11df-b112-00215aee3ebe;Лоні;Лони;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623384902;3;1
62c3d3b4-749b-11df-b112-00215aee3ebe;Лонівка;Лоновка;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623382203;3;1
62c3d3b5-749b-11df-b112-00215aee3ebe;Лонки;Лонки;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820989403;3;1
62c3d3cf-749b-11df-b112-00215aee3ebe;Лоп'янка;Лопянка;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624883201;3;1
62c3d3b6-749b-11df-b112-00215aee3ebe;Лопавше;Лопавше;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621485506;3;1
62c3d3b8-749b-11df-b112-00215aee3ebe;Лопатин;Лопатин;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623955400;3;0
62c3d3b9-749b-11df-b112-00215aee3ebe;Лопатин;Лопатин;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420386007;3;1
62c3d3ba-749b-11df-b112-00215aee3ebe;Лопатин;Лопатин;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521485409;3;1
62c3d3bb-749b-11df-b112-00215aee3ebe;Лопатинка;Лопатинка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523182601;3;1
62c3d3bc-749b-11df-b112-00215aee3ebe;Лопатинці;Лопатинцы;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525387802;3;1
17af7b0f-ddb9-11df-9197-00215aee3ebe;Лопатинці;Лопатинцы;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521080306;3;1
62c3d3bd-749b-11df-b112-00215aee3ebe;Лопатичі;Лопатичи;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824485301;3;1
62c3d3be-749b-11df-b112-00215aee3ebe;Лопатів;Лопатов;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324085601;3;1
62c3d3c0-749b-11df-b112-00215aee3ebe;Лопатні;Лопатни;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424456402;3;1
62c3d3c1-749b-11df-b112-00215aee3ebe;Лопухів (Брустури);Лопухов;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;ec2fa446-dd78-11e6-80f8-1c98ec135263;2124484401;3;1
62c3d3c2-749b-11df-b112-00215aee3ebe;Лопушани;Лопушаны;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122683102;3;1
62c3d3c3-749b-11df-b112-00215aee3ebe;Лопушанка;Лопушанка;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625587202;3;1
62c3d3c4-749b-11df-b112-00215aee3ebe;Лопушанка;Лопушанка;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124082303;3;1
62c3d3c5-749b-11df-b112-00215aee3ebe;Лопушанка-Хомина;Лопушанка-Хомина;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625185702;3;1
62c3d3c6-749b-11df-b112-00215aee3ebe;Лопушна;Лопушна;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320581503;3;1
62c3d3c7-749b-11df-b112-00215aee3ebe;Лопушна;Лопушна;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623387603;3;1
62c3d3c8-749b-11df-b112-00215aee3ebe;Лопушне;Лопушное;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123484401;3;1
62c3d3c9-749b-11df-b112-00215aee3ebe;Лопушне;Лопушное;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123884301;3;1
62c3d3ca-749b-11df-b112-00215aee3ebe;Лопушне;Лопушное;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122487902;3;1
62c3d3cb-749b-11df-b112-00215aee3ebe;Лопушне;Лопушное;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822184204;3;1
62c3d3cc-749b-11df-b112-00215aee3ebe;Лопушниця;Лопушница;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625185303;3;1
62c3d3cd-749b-11df-b112-00215aee3ebe;Лопушно;Лопушно;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624285202;3;1
62c3d3ce-749b-11df-b112-00215aee3ebe;Лопушня;Лопушня;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624481005;3;1
62c3d3d0-749b-11df-b112-00215aee3ebe;Лосевочка;Лосевочка;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424786007;3;1
62c3d3d1-749b-11df-b112-00215aee3ebe;Лосєва Слобода;Лосева Слобода;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425884502;3;1
62c3d3d2-749b-11df-b112-00215aee3ebe;Лосинець;Лосинец;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625582902;3;1
62c3d3d3-749b-11df-b112-00215aee3ebe;Лосини;Лосины;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783010;3;1
62c3d3d4-749b-11df-b112-00215aee3ebe;Лосинівка;Лосиновка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423355400;3;1
62c3d3d5-749b-11df-b112-00215aee3ebe;Лосівка;Лосевка;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424785005;3;1
62c3d3d6-749b-11df-b112-00215aee3ebe;Лоска;Лоска;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423681202;3;1
62c3d3d8-749b-11df-b112-00215aee3ebe;Лосятин;Лосятин;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123484701;3;1
62c3d3d9-749b-11df-b112-00215aee3ebe;Лосятин;Лосятин;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221484601;3;1
62c3d3da-749b-11df-b112-00215aee3ebe;Лосяч;Лосяч;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120884701;3;1
62c3d3db-749b-11df-b112-00215aee3ebe;Лотатники;Лотатники;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625386803;3;1
62c3d3dc-749b-11df-b112-00215aee3ebe;Лоташеве;Лоташово;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124086201;3;1
62c3d3de-749b-11df-b112-00215aee3ebe;Лотівка;Лотовка;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825584101;3;1
62c3d3df-749b-11df-b112-00215aee3ebe;Лохвиця;Лохвица;місто;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322610100;2;0
62c3d3e1-749b-11df-b112-00215aee3ebe;Лохня;Лохня;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920681404;3;1
62c3d3e2-749b-11df-b112-00215aee3ebe;Лохово;Лохово;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122785201;2;1
62c3d3e3-749b-11df-b112-00215aee3ebe;Лоцкине;Лоцкино;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824583002;3;1
62c3d3e4-749b-11df-b112-00215aee3ebe;Лоцкине;Лоцкино;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820683001;3;1
62c3d3e5-749b-11df-b112-00215aee3ebe;Лошакова Гута;Лошакова Гута;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422083903;3;1
62c3d3e7-749b-11df-b112-00215aee3ebe;Лошкарівка;Лошкаревка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225255113;3;1
62c3d3e8-749b-11df-b112-00215aee3ebe;Лошкарівка;Лошкаревка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222983401;3;1
62c3d3e9-749b-11df-b112-00215aee3ebe;Лошківці;Лошкивцы;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821884101;3;1
62c3d3ea-749b-11df-b112-00215aee3ebe;Лошнів;Лошнев;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125085401;3;1
62c3d3eb-749b-11df-b112-00215aee3ebe;Лощинівка;Лощиновка;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122083401;3;1
62c3d3f7-749b-11df-b112-00215aee3ebe;Луб'яна;Лубьяна;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623088412;3;1
62c3d3f8-749b-11df-b112-00215aee3ebe;Луб'янка;Лубянка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224883001;3;1
62c3d3f9-749b-11df-b112-00215aee3ebe;Луб'янка;Лубянка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821755114;3;1
62c3d3fb-749b-11df-b112-00215aee3ebe;Луб'янка;Лубянка;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221084001;3;1
62c3d3fc-749b-11df-b112-00215aee3ebe;Луб'янка;Лубянка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221484701;3;1
62c3d3fd-749b-11df-b112-00215aee3ebe;Луб'янка;Лубянка;село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223782501;3;1
62c3d3fe-749b-11df-b112-00215aee3ebe;Луб'янці;Лубянцы;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755126;3;1
62c3d3ed-749b-11df-b112-00215aee3ebe;Лубенець;Лубенец;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422481505;3;1
62c3d3ee-749b-11df-b112-00215aee3ebe;Лубенець;Лубенец;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422280503;3;1
62c3d3ef-749b-11df-b112-00215aee3ebe;Лубенці;Лубенцы;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121883001;3;1
62c3d3f0-749b-11df-b112-00215aee3ebe;Лубенщина;Лубенщина;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320688902;3;1
62c3d3f2-749b-11df-b112-00215aee3ebe;Лубни;Лубны;місто;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5310700000;1;0
62c3d3f3-749b-11df-b112-00215aee3ebe;Лубнівка;Лубновка;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624883902;3;1
62c3d3f4-749b-11df-b112-00215aee3ebe;Лубня;Лубня;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120882801;3;1
62c3d3f5-749b-11df-b112-00215aee3ebe;Лубня;Лубня;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424787504;3;1
62c3d3f6-749b-11df-b112-00215aee3ebe;Лубське;Лубское;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222780603;3;1
62c3d3ff-749b-11df-b112-00215aee3ebe;Луг;Луг;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924484802;3;1
62c3d400-749b-11df-b112-00215aee3ebe;Луг;Луг;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625686206;3;1
62c3d401-749b-11df-b112-00215aee3ebe;Луг;Луг;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787612;3;1
62c3d402-749b-11df-b112-00215aee3ebe;Луг;Луг;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621882805;3;1
62c3d403-749b-11df-b112-00215aee3ebe;Луг;Луг;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625888405;3;1
62c3d404-749b-11df-b112-00215aee3ebe;Луг;Луг;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625383603;3;1
62c3d405-749b-11df-b112-00215aee3ebe;Луг;Луг;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120881303;3;1
62c3d406-749b-11df-b112-00215aee3ebe;Луг;Луг;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123685501;3;1
62c3d407-749b-11df-b112-00215aee3ebe;Луганка;Луганка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524983101;3;1
62c3d40d-749b-11df-b112-00215aee3ebe;Луги;Луги;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624885504;3;1
62c3d40e-749b-11df-b112-00215aee3ebe;Луги;Луги;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123685001;3;1
62c3d40f-749b-11df-b112-00215aee3ebe;Луги;Луги;село;78f453a8-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0525083901;3;1
62c3d410-749b-11df-b112-00215aee3ebe;Лугини;Лугины;місто;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822855100;3;1
62c3d411-749b-11df-b112-00215aee3ebe;Лугинки;Лугинки;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822855103;3;1
62c3d412-749b-11df-b112-00215aee3ebe;Лугівка;Луговка;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921283602;3;1
62c3d414-749b-11df-b112-00215aee3ebe;Лугівське;Луговское;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323982503;3;1
62c3d415-749b-11df-b112-00215aee3ebe;Лугова;Луговая;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521210111;3;1
62c3d416-749b-11df-b112-00215aee3ebe;Лугова;Луговая;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520485406;3;1
62c3d417-749b-11df-b112-00215aee3ebe;Лугове;Луговое;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281210;3;1
62c3d418-749b-11df-b112-00215aee3ebe;Лугове;Луговое;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721155105;3;1
62c3d419-749b-11df-b112-00215aee3ebe;Лугове;Луговое;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222082703;3;1
62c3d41a-749b-11df-b112-00215aee3ebe;Лугове;Луговое;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224881503;3;1
62c3d41b-749b-11df-b112-00215aee3ebe;Лугове;Луговое;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755127;3;1
62c3d41c-749b-11df-b112-00215aee3ebe;Лугове;Луговое;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223884803;3;1
62c3d41d-749b-11df-b112-00215aee3ebe;Лугове;Луговое;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820982206;3;1
62c3d41e-749b-11df-b112-00215aee3ebe;Лугове;Луговое;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320680502;3;1
62c3d41f-749b-11df-b112-00215aee3ebe;Лугове;Луговое;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124585203;3;1
62c3d422-749b-11df-b112-00215aee3ebe;Лугове;Луговое;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620382006;3;1
62c3d429-749b-11df-b112-00215aee3ebe;Лугове;Луговое;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423085504;3;1
62c3d42a-749b-11df-b112-00215aee3ebe;Лугове;Луговое;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421755702;3;1
62c3d42b-749b-11df-b112-00215aee3ebe;Лугове;Луговое;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825880803;3;1
62c3d42c-749b-11df-b112-00215aee3ebe;Лугове;Луговое;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621885007;3;1
62c3d14c-749b-11df-b112-00215aee3ebe;Лугове;Луговое;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323487204;3;1
33ec0010-ddd5-11df-9197-00215aee3ebe;Лугове;Луговое;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520285309;3;1
cdc44f55-dde8-11df-9197-00215aee3ebe;Лугове;Луговое;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824484004;3;1
50c59409-749b-11df-b112-00215aee3ebe;Лугове (Дзержинське);Луговое (Дзержинское);село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924755501;3;1
62c3d42d-749b-11df-b112-00215aee3ebe;Луговики;Луговики;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223585201;3;1
62c3d42e-749b-11df-b112-00215aee3ebe;Луговики;Луговики;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325182301;3;1
62c3d42f-749b-11df-b112-00215aee3ebe;Лудин;Лудин;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0720510412;3;1
62c3d430-749b-11df-b112-00215aee3ebe;Лужани;Лужаны;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322555400;3;1
62c3d431-749b-11df-b112-00215aee3ebe;Лужанка;Лужанка;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124788002;3;1
62c3d432-749b-11df-b112-00215aee3ebe;Лужки;Лужки;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;;3;1
62c3d433-749b-11df-b112-00215aee3ebe;Лужки;Лужки;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921555502;3;1
62c3d434-749b-11df-b112-00215aee3ebe;Лужки;Лужки;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622080505;3;1
62c3d435-749b-11df-b112-00215aee3ebe;Лужки;Лужки;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785210;3;1
62c3d436-749b-11df-b112-00215aee3ebe;Лужки;Лужки;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625883906;3;1
62c3d439-749b-11df-b112-00215aee3ebe;Лужок;Лужок;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322055903;3;1
62c3d43a-749b-11df-b112-00215aee3ebe;Лужок;Лужок;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524981203;3;1
62c3d43b-749b-11df-b112-00215aee3ebe;Лужок;Лужок;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522855103;3;1
62c3d43c-749b-11df-b112-00215aee3ebe;Лузанівка;Лузановка;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121883501;3;1
62c3d485-749b-11df-b112-00215aee3ebe;Лук'янівка;Лукьяновка;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824282703;3;1
62c3d487-749b-11df-b112-00215aee3ebe;Лук'янівка;Лукьяновка;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220283801;3;1
62c3d488-749b-11df-b112-00215aee3ebe;Лук'янівка;Лукьяновка;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224483601;3;1
62c3d48a-749b-11df-b112-00215aee3ebe;Лук'янці;Лукьянцы;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325182301;3;1
62c3d43d-749b-11df-b112-00215aee3ebe;Лука;Лука;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124288802;3;1
62c3d43e-749b-11df-b112-00215aee3ebe;Лука;Лука;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622884201;3;1
62c3d43f-749b-11df-b112-00215aee3ebe;Лука;Лука;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621682001;3;1
62c3d440-749b-11df-b112-00215aee3ebe;Лука;Лука;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621887605;3;1
62c3d441-749b-11df-b112-00215aee3ebe;Лука;Лука;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222482003;3;1
62c3d442-749b-11df-b112-00215aee3ebe;Лука;Лука;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224484001;3;1
62c3d443-749b-11df-b112-00215aee3ebe;Лука;Лука;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821781802;3;1
62c3d444-749b-11df-b112-00215aee3ebe;Лука;Лука;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822084701;3;1
62c3d445-749b-11df-b112-00215aee3ebe;Лука;Лука;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425888803;3;1
62c3d446-749b-11df-b112-00215aee3ebe;Лука;Лука;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122085103;3;1
62c3d447-749b-11df-b112-00215aee3ebe;Лука;Лука;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522486803;3;1
62c3d448-749b-11df-b112-00215aee3ebe;Лука;Лука;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523010107;3;1
62c3d449-749b-11df-b112-00215aee3ebe;Лука;Лука;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825883605;3;1
62c3d44a-749b-11df-b112-00215aee3ebe;Лука;Лука;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322684401;3;1
6ed81d78-749b-11df-b112-00215aee3ebe;Лука;Лука;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624286001;3;1
8723e76a-6dd4-11e5-93c7-003048d2b473;Лука (Озерне);Озерне;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4624286001;3;1
62c3d44b-749b-11df-b112-00215aee3ebe;Лука-Барська;Лука-Барская;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520282803;3;1
62c3d44c-749b-11df-b112-00215aee3ebe;Лукавець;Лукавец;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620380403;3;1
62c3d44d-749b-11df-b112-00215aee3ebe;Лукавиця;Лукавица;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624285203;3;1
62c3d473-749b-11df-b112-00215aee3ebe;Лукавці;Лукавци;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7320584001;3;0
62c3d44e-749b-11df-b112-00215aee3ebe;Лука-Мелешківська;Лука- Мелешковская;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520682803;3;0
62c3d44f-749b-11df-b112-00215aee3ebe;Лука-Мовчанська;Лука-Молчанская;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521083903;3;1
62c3d450-749b-11df-b112-00215aee3ebe;Луканівка;Лукановка;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823982201;3;1
62c3d451-749b-11df-b112-00215aee3ebe;Лукарівка;Лукаровка;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623886402;3;1
62c3d452-749b-11df-b112-00215aee3ebe;Лукачівка;Лукачевка;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322086201;3;1
62c3d453-749b-11df-b112-00215aee3ebe;Лукашеве;Лукашево;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322184601;3;1
62c3d454-749b-11df-b112-00215aee3ebe;Лукашенківське;Лукашенковское;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924480402;3;1
62c3d455-749b-11df-b112-00215aee3ebe;Лукаші;Лукаши;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320282202;3;1
62c3d456-749b-11df-b112-00215aee3ebe;Лукаші;Лукаши;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620383602;3;1
62c3d457-749b-11df-b112-00215aee3ebe;Лукаші;Лукаши;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220283401;3;1
62c3d458-749b-11df-b112-00215aee3ebe;Лукашівка;Лукашовка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324586707;3;1
62c3d459-749b-11df-b112-00215aee3ebe;Лукашівка;Лукашовка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320684903;3;1
62c3d45a-749b-11df-b112-00215aee3ebe;Лукашівка;Лукашовка;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921281303;3;1
62c3d45b-749b-11df-b112-00215aee3ebe;Лукашівка;Лукашовка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825484201;3;1
62c3d45c-749b-11df-b112-00215aee3ebe;Лукашівка;Лукашовка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323380504;3;1
62c3d45d-749b-11df-b112-00215aee3ebe;Лукашівка;Лукашовка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684001;3;1
62c3d45e-749b-11df-b112-00215aee3ebe;Лукашівка;Лукашовка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121281603;3;1
62c3d45f-749b-11df-b112-00215aee3ebe;Лукашівка;Лукашовка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425580502;3;1
62c3d460-749b-11df-b112-00215aee3ebe;Лукашівка;Лукашовка;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423810103;3;1
62c3d461-749b-11df-b112-00215aee3ebe;Лукашівка;Лукашовка;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123484501;3;1
62c3d462-749b-11df-b112-00215aee3ebe;Лукашівка;Лукашовка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121587401;3;1
62c3d463-749b-11df-b112-00215aee3ebe;Лукашівка;Лукашовка;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125185201;3;1
62c3d464-749b-11df-b112-00215aee3ebe;Лукашівка;Лукашовка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522482203;3;1
62c3d465-749b-11df-b112-00215aee3ebe;Лукашівка;Лукашовка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522283601;3;1
62c3d466-749b-11df-b112-00215aee3ebe;Лукашівка;Лукашовка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525380505;3;1
62c3d467-749b-11df-b112-00215aee3ebe;Лукашівка;Лукашовка;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0510690002;3;1
62c3d468-749b-11df-b112-00215aee3ebe;Лукашове;Лукашово;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924182303;3;1
62c3d46a-749b-11df-b112-00215aee3ebe;Луквиця;Луквица;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620484401;3;1
62c3d46b-749b-11df-b112-00215aee3ebe;Луки;Луки;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923555103;3;1
62c3d46c-749b-11df-b112-00215aee3ebe;Луки;Луки;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624284301;3;1
62c3d46d-749b-11df-b112-00215aee3ebe;Луки;Луки;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823481401;3;1
62c3d46e-749b-11df-b112-00215aee3ebe;Луки;Луки;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322884603;3;1
62c3d46f-749b-11df-b112-00215aee3ebe;Лукищина;Лукищина;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081004;3;1
62c3d470-749b-11df-b112-00215aee3ebe;Луків;Луков;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725555400;3;1
62c3d471-749b-11df-b112-00215aee3ebe;Луків;Луков;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724583501;3;1
62c3d472-749b-11df-b112-00215aee3ebe;Луківка;Луковка;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122285003;3;1
62c3d49e-749b-11df-b112-00215aee3ebe;Луківка;Луковка;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722187407;3;1
62c3d474-749b-11df-b112-00215aee3ebe;Лукіївка;Лукиевка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222982808;3;1
62c3d475-749b-11df-b112-00215aee3ebe;Лукім'я;Лукомье;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323683601;3;1
62c3d476-749b-11df-b112-00215aee3ebe;Лукішки;Лукошки;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824288404;3;1
62c3d477-749b-11df-b112-00215aee3ebe;Лукнове;Лукново;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422284301;3;1
62c3d478-749b-11df-b112-00215aee3ebe;Луко;Луко;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620882602;3;1
62c3d47b-749b-11df-b112-00215aee3ebe;Луковець;Луковец;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821183003;3;1
62c3d47c-749b-11df-b112-00215aee3ebe;Луковець;Луковец;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422482503;3;1
62c3d47d-749b-11df-b112-00215aee3ebe;Луковець-Вишнівський;Луковец-Вишневский;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624483701;3;1
62c3d47e-749b-11df-b112-00215aee3ebe;Луковець-Журівський;Луковец-Журовский;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624483704;3;1
62c3d47f-749b-11df-b112-00215aee3ebe;Луковиця;Луковица;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321083601;3;1
62c3d480-749b-11df-b112-00215aee3ebe;Луковиця;Луковица;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320781102;3;1
62c3d481-749b-11df-b112-00215aee3ebe;Луковиця;Луковица;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122081902;3;1
62c3d482-749b-11df-b112-00215aee3ebe;Луковичі;Луковичи;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721182801;3;1
62c3d483-749b-11df-b112-00215aee3ebe;Луковище;Луковище;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624484402;3;1
62c3d47a-749b-11df-b112-00215aee3ebe;Луково;Луково;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121985801;3;1
62c3d484-749b-11df-b112-00215aee3ebe;Лукомщина;Лукомщина;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225583003;3;1
62c3d48b-749b-11df-b112-00215aee3ebe;Лумля;Лумля;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823485202;3;1
62c3d48c-749b-11df-b112-00215aee3ebe;Лумшори;Лумшоры;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123284504;3;1
62c3d48f-749b-11df-b112-00215aee3ebe;Лунка;Лунка;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320783001;3;1
62c3d490-749b-11df-b112-00215aee3ebe;Лунка;Лунка;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125384002;3;1
62c3d491-749b-11df-b112-00215aee3ebe;Лупареве;Лупарево;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823382603;3;1
62c3d492-749b-11df-b112-00215aee3ebe;Лупасове;Лупасова;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422486003;3;1
62c3d493-749b-11df-b112-00215aee3ebe;Луполове;Луполово;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525585201;3;1
62c3d494-749b-11df-b112-00215aee3ebe;Лустівка;Лустовка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423388902;3;1
62c3d495-749b-11df-b112-00215aee3ebe;Лустун;Лустун;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323583002;3;1
62c3d496-749b-11df-b112-00215aee3ebe;Лутава;Лутава;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422083003;3;1
62c3d497-749b-11df-b112-00215aee3ebe;Лутайка;Лутайка;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320881903;3;1
62c3d498-749b-11df-b112-00215aee3ebe;Лутайка;Лутайка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424185802;3;1
62c3d499-749b-11df-b112-00215aee3ebe;Лутище;Лутыще;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920385601;3;1
62c3d49a-749b-11df-b112-00215aee3ebe;Лутівка;Лутовка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825085501;3;1
62c3d49b-749b-11df-b112-00215aee3ebe;Лутківка;Лутковка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523183202;3;1
62c3d49c-749b-11df-b112-00215aee3ebe;Лутовинівка;Лутовиновка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322082501;3;1
62c3d4a0-749b-11df-b112-00215aee3ebe;Луценкове;Луценково;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924181104;3;1
62c3d4a1-749b-11df-b112-00215aee3ebe;Луцики;Луцыки;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785211;3;1
62c3d4a2-749b-11df-b112-00215aee3ebe;Луциківка;Луциковка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920685301;3;1
62c3d4a3-749b-11df-b112-00215aee3ebe;Луці;Луци;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755131;3;1
62c3d4a4-749b-11df-b112-00215aee3ebe;Луцьк;Луцк;місто;5394b32d-41be-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0710100000;1;0
62c3d4a5-749b-11df-b112-00215aee3ebe;Луч;Луч;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922681203;3;1
62c3d4a6-749b-11df-b112-00215aee3ebe;Луч;Луч;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823384703;3;1
62c3d4a7-749b-11df-b112-00215aee3ebe;Лучани;Лучаны;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621589105;3;1
62c3d4a8-749b-11df-b112-00215aee3ebe;Лучанки;Лучанки;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824284301;3;1
62c3d4a9-749b-11df-b112-00215aee3ebe;Лучин;Лучин;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824755405;3;1
62c3d4aa-749b-11df-b112-00215aee3ebe;Лучинець;Лучинец;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522883801;3;1
62c3d4ab-749b-11df-b112-00215aee3ebe;Лучинське;Лучинское;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123981103;3;1
62c3d4ac-749b-11df-b112-00215aee3ebe;Лучинці;Лучинцы;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624483901;3;1
62c3d4ad-749b-11df-b112-00215aee3ebe;Лучинчик;Лучинчик;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522884001;3;1
62c3d4af-749b-11df-b112-00215aee3ebe;Лучиці;Лучицы;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722885104;3;1
62c3d4b0-749b-11df-b112-00215aee3ebe;Лучиці;Лучицы;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624883201;3;1
62c3d4b1-749b-11df-b112-00215aee3ebe;Лучиця;Лучица;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824055607;3;1
62c3d4b2-749b-11df-b112-00215aee3ebe;Лучичі;Лучичи;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724283402;3;1
62c3d4b3-749b-11df-b112-00215aee3ebe;Лучка;Лучка;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923282801;3;1
62c3d4b4-749b-11df-b112-00215aee3ebe;Лучка;Лучка;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925082702;3;1
62c3d4b5-749b-11df-b112-00215aee3ebe;Лучка;Лучка;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125285102;3;1
62c3d4b6-749b-11df-b112-00215aee3ebe;Лучка;Лучка;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322686602;3;1
62c3d4b7-749b-11df-b112-00215aee3ebe;Лучки;Лучки;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324285402;3;1
62c3d4b8-749b-11df-b112-00215aee3ebe;Лучки;Лучки;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321884501;3;1
62c3d4b9-749b-11df-b112-00215aee3ebe;Лучки;Лучки;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5910790005;3;1
62c3d4ba-749b-11df-b112-00215aee3ebe;Лучки;Лучки;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822485503;3;1
62c3d4bb-749b-11df-b112-00215aee3ebe;Лучківка;Лучковка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421781603;3;1
62c3d4bc-749b-11df-b112-00215aee3ebe;Лучківці;Лучковцы;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620388804;3;1
62c3d4bf-749b-11df-b112-00215aee3ebe;Лушники;Лушники;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925387307;3;1
62c3d4c0-749b-11df-b112-00215aee3ebe;Лушники;Лушники;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322883203;3;1
62c3d4c1-749b-11df-b112-00215aee3ebe;Лущики;Лущики;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622780805;3;1
62c3d519-749b-11df-b112-00215aee3ebe;Люб'язь;Любьязь;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723186401;3;1
62c3d4c2-749b-11df-b112-00215aee3ebe;Любанівка;Любановка;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;;3;1
62c3d4c3-749b-11df-b112-00215aee3ebe;Любар;Любар;місто;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823155100;3;0
62c3d4c4-749b-11df-b112-00215aee3ebe;Любарка;Любарка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820681804;3;1
62c3d4c5-749b-11df-b112-00215aee3ebe;Любарка;Любарка;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755108;3;1
62c3d4c6-749b-11df-b112-00215aee3ebe;Любарська Гута;Любарская Гута;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820655403;3;1
62c3d4c7-749b-11df-b112-00215aee3ebe;Любарці;Любарцы;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220884801;3;1
62c3d4c8-749b-11df-b112-00215aee3ebe;Любахи;Любахи;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620886601;3;1
62c3d4c9-749b-11df-b112-00215aee3ebe;Любахове;Любахово;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924481704;3;1
62c3d4ca-749b-11df-b112-00215aee3ebe;Любачеве;Любачево;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786803;3;1
62c3d4cb-749b-11df-b112-00215aee3ebe;Любашівка;Любашевка;місто;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123355100;1;0
62c3d4cc-749b-11df-b112-00215aee3ebe;Любе;Любое;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282514;3;1
62c3d4cd-749b-11df-b112-00215aee3ebe;Любеля;Любеля;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785501;3;1
62c3d4ce-749b-11df-b112-00215aee3ebe;Любеч;Любеч;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424456000;3;1
62c3d4cf-749b-11df-b112-00215aee3ebe;Любечанинів;Любечанинов;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422010402;3;1
62c3d4d0-749b-11df-b112-00215aee3ebe;Любешів;Любешов;місто;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155100;2;1
62c3d4d1-749b-11df-b112-00215aee3ebe;Любешівська Воля;Любешовская Воля;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155121;3;1
62c3d4d2-749b-11df-b112-00215aee3ebe;Любешка;Любешка;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623384504;3;1
62c3d4d3-749b-11df-b112-00215aee3ebe;Любидва;Любидва;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221881202;3;1
62c3d4d4-749b-11df-b112-00215aee3ebe;Любиковичі;Любиковичи;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625484401;3;1
62c3d4d6-749b-11df-b112-00215aee3ebe;Любимівка;Любимовка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323083803;3;1
62c3d4d7-749b-11df-b112-00215aee3ebe;Любимівка;Любимовка;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221484001;2;1
62c3d4d8-749b-11df-b112-00215aee3ebe;Любимівка;Любимовка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222086004;3;1
62c3d4d9-749b-11df-b112-00215aee3ebe;Любимівка;Любимовка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225081003;3;1
62c3d4da-749b-11df-b112-00215aee3ebe;Любимівка;Любимовка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225255107;3;1
62c3d4db-749b-11df-b112-00215aee3ebe;Любимівка;Любимовка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582501;3;1
62c3d4dd-749b-11df-b112-00215aee3ebe;Любимівка;Любимовка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323981502;3;1
62c3d4e0-749b-11df-b112-00215aee3ebe;Любимівка;Любимовка;село;78f4540d-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6524182801;3;1
62c3d4e4-749b-11df-b112-00215aee3ebe;Любимівка;Любимовка;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221884801;3;1
62c3d4e5-749b-11df-b112-00215aee3ebe;Любимівка;Любимовка;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222480804;3;1
62c3d4e6-749b-11df-b112-00215aee3ebe;Любимівка;Любимовка;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820386201;3;1
e0517ee5-db89-11df-9197-00215aee3ebe;Любимівка;Любимовка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825782550;3;1
62c3d4e9-749b-11df-b112-00215aee3ebe;Любине;Любино;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825783206;3;1
62c3d4ea-749b-11df-b112-00215aee3ebe;Любині;Любини;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625887609;3;1
62c3d4eb-749b-11df-b112-00215aee3ebe;Любинці;Любинцы;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625384401;3;1
62c3d4ec-749b-11df-b112-00215aee3ebe;Любинь;Любынь;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622281609;3;1
62c3d4ed-749b-11df-b112-00215aee3ebe;Любисток;Любысток;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122283703;3;1
62c3d4ee-749b-11df-b112-00215aee3ebe;Любитів;Любитов;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722184001;3;1
62c3d4ef-749b-11df-b112-00215aee3ebe;Любитове;Любитово;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922685103;3;1
62c3d4f0-749b-11df-b112-00215aee3ebe;Любицьке;Любицкое;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323682401;3;1
62c3d4f1-749b-11df-b112-00215aee3ebe;Любівка;Любовка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323584201;3;1
62c3d3f1-749b-11df-b112-00215aee3ebe;Любівка;Любовка;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524686207;3;1
62c3d4f3-749b-11df-b112-00215aee3ebe;Любівщина;Любовщина;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323289204;3;1
f2538ba9-9934-11e5-8e51-003048d2b473;Любінь Великий;Любинь Великий;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620955300;3;1
62c3d4f4-749b-11df-b112-00215aee3ebe;Любка;Любка;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322281302;3;1
62c3d4f5-749b-11df-b112-00215aee3ebe;Любка;Любка;село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223782502;3;1
62c3d4f6-749b-11df-b112-00215aee3ebe;Любківці;Любковцы;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625255305;3;1
62c3d4f7-749b-11df-b112-00215aee3ebe;Люблине;Люблино;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820985002;3;1
62c3d4f8-749b-11df-b112-00215aee3ebe;Люблинець;Люблинец;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155700;3;1
62c3d4fa-749b-11df-b112-00215aee3ebe;Любов;Любовь;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225081004;3;1
62c3d4fb-749b-11df-b112-00215aee3ebe;Любовичі;Любовичи;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620983906;3;1
62c3d4fc-749b-11df-b112-00215aee3ebe;Любовичі;Любовичи;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823484801;3;1
62c3d4fd-749b-11df-b112-00215aee3ebe;Любовичка;Любовичка;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525884903;3;1
62c3d4fe-749b-11df-b112-00215aee3ebe;Любоіванівка;Любоивановка;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820381801;3;1
62c3d500-749b-11df-b112-00215aee3ebe;Любомирівка;Любомировка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222086005;3;1
62c3d501-749b-11df-b112-00215aee3ebe;Любомирівка;Любомировка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823681103;3;1
62c3d502-749b-11df-b112-00215aee3ebe;Любомирівка;Любомировка;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821182201;3;1
62c3d503-749b-11df-b112-00215aee3ebe;Любомирівка;Любомировка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582005;3;1
62c3d504-749b-11df-b112-00215aee3ebe;Любомирівка;Любомировка;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823389002;3;1
3588b705-fd33-11e8-80d9-1c98ec135261;Любомирівка;Любомирівка;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221982701;3;1
5cb61ca4-749b-11df-b112-00215aee3ebe;Любомирівка (Красне Знам'я);Любомирівка (Красне Знам'я);село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825782201;3;1
74dc4e68-749b-11df-b112-00215aee3ebe;Любомирівка (Право Жовтня);Любомировка (Право Жовтня);село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221982701;3;1
62c3d505-749b-11df-b112-00215aee3ebe;Любомирка;Любомирка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122984601;3;1
62c3d506-749b-11df-b112-00215aee3ebe;Любомирка;Любомирка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820881202;3;1
62c3d507-749b-11df-b112-00215aee3ebe;Любомирка;Любомирка;село;78f453a8-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0525084201;3;1
62c3d508-749b-11df-b112-00215aee3ebe;Любомирка;Любомирка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521783001;3;1
62c3d509-749b-11df-b112-00215aee3ebe;Любомирка;Любомирка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520582003;3;1
62c3d50a-749b-11df-b112-00215aee3ebe;Любомирка;Любомирка;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823686203;3;1
62c3d50b-749b-11df-b112-00215aee3ebe;Любомль;Любомль;місто;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723310100;2;0
62c3d50c-749b-11df-b112-00215aee3ebe;Любомудрівка;Любомудровка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420810104;3;1
62c3d50d-749b-11df-b112-00215aee3ebe;Любо-Надеждівка;Любо-Надеждовка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522586903;3;1
62c3d50e-749b-11df-b112-00215aee3ebe;Любопіль;Любополь;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122783801;3;1
62c3d50f-749b-11df-b112-00215aee3ebe;Люботаївка;Люботаевка;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121882003;3;1
62c3d510-749b-11df-b112-00215aee3ebe;Люботин;Люботин;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155122;3;1
62c3d511-749b-11df-b112-00215aee3ebe;Люботин;Люботин;місто;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6311200000;2;0
62c3d512-749b-11df-b112-00215aee3ebe;Люботин;Люботин;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;;3;1
62c3d513-749b-11df-b112-00215aee3ebe;Любохини;Любохины;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725082501;3;1
62c3d514-749b-11df-b112-00215aee3ebe;Любча;Любча;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224284402;3;1
62c3d515-749b-11df-b112-00215aee3ebe;Любче;Любче;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722182809;3;1
62c3d516-749b-11df-b112-00215aee3ebe;Любче;Любче;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724582910;3;1
62c3d517-749b-11df-b112-00215aee3ebe;Любша;Любша;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624481203;3;1
62c3d518-749b-11df-b112-00215aee3ebe;Любша;Любша;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621584401;3;1
62c3d51a-749b-11df-b112-00215aee3ebe;Людавка;Людавка;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521083803;3;1
62c3d51b-749b-11df-b112-00215aee3ebe;Людвинівка;Людвиновка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222080602;3;1
62c3d51c-749b-11df-b112-00215aee3ebe;Людвинівка;Людвиновка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222784401;3;1
62c3d51d-749b-11df-b112-00215aee3ebe;Людвинівка;Людвиновка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220485802;3;1
62c3d51e-749b-11df-b112-00215aee3ebe;Людвинівка;Людвиновка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;;3;1
62c3d51f-749b-11df-b112-00215aee3ebe;Людвище;Людвище;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125885101;3;1
62c3d520-749b-11df-b112-00215aee3ebe;Люджа;Люджа;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925084601;3;1
62c3d521-749b-11df-b112-00215aee3ebe;Людинь;Людынь;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621884301;3;1
62c3d522-749b-11df-b112-00215aee3ebe;Людмилівка;Людмиловка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055703;3;1
62c3d523-749b-11df-b112-00215aee3ebe;Людмилівка;Людмиловка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821480803;3;1
62c3d524-749b-11df-b112-00215aee3ebe;Людмильпіль;Людмилполь;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720582205;3;1
62c3d525-749b-11df-b112-00215aee3ebe;Люлинці;Люлинцы;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123881805;3;1
62c3d526-749b-11df-b112-00215aee3ebe;Люлинці;Люлинцы;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521684403;3;1
62c3d527-749b-11df-b112-00215aee3ebe;Люлинці;Люлинцы;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523183904;3;1
62c3d528-749b-11df-b112-00215aee3ebe;Люта;Люта;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120883201;3;1
62c3d529-749b-11df-b112-00215aee3ebe;Лютарі;Лютари;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220686602;3;1
62c3d52a-749b-11df-b112-00215aee3ebe;Лютарівка;Лютаровка;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824785502;3;1
62c3d52b-749b-11df-b112-00215aee3ebe;Лютарка;Лютарка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822183601;3;1
62c3d52c-749b-11df-b112-00215aee3ebe;Люте;Лютое;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924455302;3;1
62c3d52d-749b-11df-b112-00215aee3ebe;Лютенські Будища;Лютенские Будища;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321383201;3;1
62c3d52e-749b-11df-b112-00215aee3ebe;Лютенька;Лютенька;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320484401;3;1
62c3d52f-749b-11df-b112-00215aee3ebe;Лютинка;Лютинка;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621584703;3;1
62c3d530-749b-11df-b112-00215aee3ebe;Лютинськ;Лютинск;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621884601;3;1
62c3d531-749b-11df-b112-00215aee3ebe;Лютівка;Лютовка;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281411;3;1
62c3d532-749b-11df-b112-00215aee3ebe;Лютівка;Лютовка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322683501;3;1
62c3d533-749b-11df-b112-00215aee3ebe;Лютівка;Лютовка;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425883503;3;1
62c3d534-749b-11df-b112-00215aee3ebe;Лютіж;Лютеж;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221885201;3;1
62c3d535-749b-11df-b112-00215aee3ebe;Лютіж;Лютеж;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421486405;3;1
62c3d536-749b-11df-b112-00215aee3ebe;Лютка;Лютка;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725081303;3;1
62c3d537-749b-11df-b112-00215aee3ebe;Лютовиська;Лютовиска;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625183101;3;1
62c3d538-749b-11df-b112-00215aee3ebe;Люхча;Люхча;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625484801;3;1
62c3d539-749b-11df-b112-00215aee3ebe;Люцерна;Люцерна;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582701;3;0
62c3d53a-749b-11df-b112-00215aee3ebe;Люцинів;Люцинов;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621286703;3;1
62c3d53b-749b-11df-b112-00215aee3ebe;Люча;Люча;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623684101;3;1
62c3d53c-749b-11df-b112-00215aee3ebe;Лючин;Лючин;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624285602;3;1
62c3d53d-749b-11df-b112-00215aee3ebe;Лючки;Лючки;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623684701;3;1
62c3d53e-749b-11df-b112-00215aee3ebe;Люшнювате;Люшневатое;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521484801;3;1
62c3d53f-749b-11df-b112-00215aee3ebe;Лядова;Лядова;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520281609;3;1
62c3d540-749b-11df-b112-00215aee3ebe;Лядова;Лядова;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522688202;2;1
62c3d541-749b-11df-b112-00215aee3ebe;Лядське;Лядское;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124289201;3;1
62c3d542-749b-11df-b112-00215aee3ebe;Ляпинці;Ляпинцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825083003;3;1
62c3d543-749b-11df-b112-00215aee3ebe;Ляхівці;Ляховцы;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124887604;3;1
62c3d544-749b-11df-b112-00215aee3ebe;Ляшівка;Ляшовка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324285008;3;1
62c3d545-749b-11df-b112-00215aee3ebe;Ляшки;Ляшки;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983707;3;1
62c3d546-749b-11df-b112-00215aee3ebe;Ляшківка;Ляшковка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225683001;3;1
62c3d548-749b-11df-b112-00215aee3ebe;Ляшківці;Ляшковцы;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424985002;3;1
62c3d549-749b-11df-b112-00215aee3ebe;Лящівка;Лящовка;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125185601;3;1
62c3d54a-749b-11df-b112-00215aee3ebe;Львів;Львов;місто;8a199cde-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4610100000;1;0
62c3d54c-749b-11df-b112-00215aee3ebe;Львів;Львов;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;;3;1
47250932-044c-11e0-bc87-00215aee3ebe;Львів;Львов;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221881203;3;1
62c3d54d-749b-11df-b112-00215aee3ebe;Львівка;Львовка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121589002;3;1
62c3d54e-749b-11df-b112-00215aee3ebe;Львівське;Львовское;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321682504;3;1
62c3d550-749b-11df-b112-00215aee3ebe;Львівські Отруби;Львовские Отрубы;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520680602;3;1
62c3d551-749b-11df-b112-00215aee3ebe;Львове;Львово;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520683001;3;1
62c3d553-749b-11df-b112-00215aee3ebe;Льгів;Льгов;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425584604;3;1
62c3d554-749b-11df-b112-00215aee3ebe;Льгівка;Льговка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425584605;3;1
62c3d556-749b-11df-b112-00215aee3ebe;Льонівка;Леневка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821784402;3;1
62c3d557-749b-11df-b112-00215aee3ebe;Льопи;Лепы;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623684603;3;1
62c3d558-749b-11df-b112-00215aee3ebe;Льотниче;Летничье;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720582010;3;1
68cf2b6c-749b-11df-b112-00215aee3ebe;М' ятин;Мятин;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623889404;3;1
f2f5ae2b-27b3-11e1-b44a-003048d2b473;М' ятин;М'ятын;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;;2;1
68cf2b68-749b-11df-b112-00215aee3ebe;М'якеньківка;Мякеньковка;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324282601;3;1
68cf2b69-749b-11df-b112-00215aee3ebe;М'якоти;Мякоты;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822184501;3;1
68cf2b6a-749b-11df-b112-00215aee3ebe;М'якохід;Мягкоход;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520483403;3;1
68cf2b6b-749b-11df-b112-00215aee3ebe;М'ясківка;Мясковка;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825880403;3;1
68cf2b6d-749b-11df-b112-00215aee3ebe;М'ятин;Мятин;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621285902;3;1
62c3d559-749b-11df-b112-00215aee3ebe;Мавдрики;Мавдрики;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784808;3;1
62c3d55a-749b-11df-b112-00215aee3ebe;Мавковичі;Мавковичи;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620983907;3;1
62c3d55c-749b-11df-b112-00215aee3ebe;Магала;Магала;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323083601;3;1
62c3d55d-749b-11df-b112-00215aee3ebe;Магдалинівка;Магдалиновка;місто;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222355100;3;1
62c3d55e-749b-11df-b112-00215aee3ebe;Магдалинівка;Магдалиновка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955415;3;1
62c3d560-749b-11df-b112-00215aee3ebe;Магдалівка;Магдалевка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124684601;3;1
62c3d561-749b-11df-b112-00215aee3ebe;Магдин;Магдин;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824283707;3;1
62c3d563-749b-11df-b112-00215aee3ebe;Магерів;Магеров;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622755900;3;1
62c3d564-749b-11df-b112-00215aee3ebe;Магнишівка;Магнышевка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825884803;3;1
17af7b2b-ddb9-11df-9197-00215aee3ebe;Мажари;Мажары;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224888507;3;1
62c3d565-749b-11df-b112-00215aee3ebe;Мажарка;Мажарка;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323182501;3;1
62c3d566-749b-11df-b112-00215aee3ebe;Мажугівка;Мажуговка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425588102;3;1
62c3d576-749b-11df-b112-00215aee3ebe;Мазарня-Каранська;Мазярня-Каранская;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622184603;3;1
62c3d568-749b-11df-b112-00215aee3ebe;Мазепинці;Мазепинцы;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220481302;3;1
62c3d569-749b-11df-b112-00215aee3ebe;Мазинки;Мазинки;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223384401;3;1
62c3d56a-749b-11df-b112-00215aee3ebe;Мазів;Мазов;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621886902;3;1
62c3d56b-749b-11df-b112-00215aee3ebe;Мазівка;Мазевка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923884801;3;1
62c3d56c-749b-11df-b112-00215aee3ebe;Мазки;Мазки;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424185101;3;1
62c3d56d-749b-11df-b112-00215aee3ebe;Мазне;Мазное;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923555606;3;1
62c3d56e-749b-11df-b112-00215aee3ebe;Мазники;Мазники;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821586001;3;1
62c3d56f-749b-11df-b112-00215aee3ebe;Мазури;Мазуры;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484604;3;1
62c3d570-749b-11df-b112-00215aee3ebe;Мазурівка;Мазуровка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083206;3;1
62c3d571-749b-11df-b112-00215aee3ebe;Мазурівка;Мазуровка;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621584403;3;1
62c3d572-749b-11df-b112-00215aee3ebe;Мазурівка;Мазуровка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524382905;3;1
62c3d573-749b-11df-b112-00215aee3ebe;Мазурівка;Мазуровка;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524984501;3;1
23c87536-f5e8-11e2-a200-003048d2b473;Мазурівка;Мазуривка;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125580706;3;1
62c3d574-749b-11df-b112-00215aee3ebe;Мазурове;Мазурово;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823982601;3;1
62c3d575-749b-11df-b112-00215aee3ebe;Мазярка;Мазярка;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622183006;3;1
62c3d577-749b-11df-b112-00215aee3ebe;Маївка;Маевка;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221488005;3;1
62c3d578-749b-11df-b112-00215aee3ebe;Маїки;Маикы;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622185913;3;1
62c3d579-749b-11df-b112-00215aee3ebe;Майбородівка;Майбородовка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322486303;3;1
62c3d57a-749b-11df-b112-00215aee3ebe;Майбутнє;Майбутне;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422481002;3;1
62c3d57b-749b-11df-b112-00215aee3ebe;Майдаки;Майдаки;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922987309;3;1
62c3d57c-749b-11df-b112-00215aee3ebe;Майдан;Майдан;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723687303;3;1
62c3d57d-749b-11df-b112-00215aee3ebe;Майдан;Майдан;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155313;3;1
62c3d57e-749b-11df-b112-00215aee3ebe;Майдан;Майдан;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121684001;3;1
62c3d57f-749b-11df-b112-00215aee3ebe;Майдан;Майдан;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7320584005;3;1
62c3d580-749b-11df-b112-00215aee3ebe;Майдан;Майдан;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321287003;3;1
62c3d581-749b-11df-b112-00215aee3ebe;Майдан;Майдан;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2625882701;3;1
62c3d582-749b-11df-b112-00215aee3ebe;Майдан;Майдан;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784404;3;1
62c3d583-749b-11df-b112-00215aee3ebe;Майдан;Майдан;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621286540;3;1
62c3d585-749b-11df-b112-00215aee3ebe;Майдан;Майдан;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122483301;3;1
62c3d586-749b-11df-b112-00215aee3ebe;Майдан;Майдан;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824485601;3;1
62c3d587-749b-11df-b112-00215aee3ebe;Майдан;Майдан;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821788402;3;1
62c3d588-749b-11df-b112-00215aee3ebe;Майдан;Майдан;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520688905;3;1
62c3d589-749b-11df-b112-00215aee3ebe;Майдан;Майдан;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524580809;3;1
62c3d58a-749b-11df-b112-00215aee3ebe;Майдан;Майдан;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823080403;3;1
62c3d58b-749b-11df-b112-00215aee3ebe;Майдан;Майдан;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623484603;3;1
62c3d58c-749b-11df-b112-00215aee3ebe;Майдан;Майдан;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621683201;3;1
62c3d58e-749b-11df-b112-00215aee3ebe;Майдан-Бобрик;Майдан-Бобрик;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522487003;3;1
62c3d58f-749b-11df-b112-00215aee3ebe;Майдан-Вербецький;Майдан-Вербецкий;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823083701;3;1
62c3d590-749b-11df-b112-00215aee3ebe;Майдан-Голенищівський;Майдан-Голенищевский;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823080404;3;1
62c3d58d-749b-11df-b112-00215aee3ebe;Майдан-Головчинський;Майдан-Головчинский;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521082606;3;1
62c3d591-749b-11df-b112-00215aee3ebe;Майдан-Гологірський;Майдан-Гологорский;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621882604;3;1
62c3d592-749b-11df-b112-00215aee3ebe;Майданецьке;Майданецкое;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124086601;3;1
62c3d593-749b-11df-b112-00215aee3ebe;Майданівка;Майдановка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320684904;3;1
62c3d594-749b-11df-b112-00215aee3ebe;Майданівка;Майдановка;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221084501;3;1
62c3d595-749b-11df-b112-00215aee3ebe;Майданівка;Майдановка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822385604;3;1
62c3d596-749b-11df-b112-00215aee3ebe;Майданівка;Майдановка;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121288002;3;1
62c3d597-749b-11df-b112-00215aee3ebe;Майдан-Карачієвецький;Майдан-Карачиевецкий;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820684007;3;1
8cdd5cfa-de27-11df-9197-00215aee3ebe;Майдан-Карачієвецький;Майдан-Карачиевецкий;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;;3;1
62c3d598-749b-11df-b112-00215aee3ebe;Майдан-Копищенський;Майдан-Копищенский;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824484802;3;1
62c3d599-749b-11df-b112-00215aee3ebe;Майдан-Лабунь;Майдан-Лабунь;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825584603;3;1
68cf2304-749b-11df-b112-00215aee3ebe;Майдан-Липненський;Майдан-Липненский;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723681902;3;1
68cf2305-749b-11df-b112-00215aee3ebe;Майдан-Морозівський;Майдан-Морозовский;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825885603;3;1
68cf2306-749b-11df-b112-00215aee3ebe;Майдан-Олександрівський;Майдан-Александровский;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820684501;3;1
68cf2307-749b-11df-b112-00215aee3ebe;Майдан-Петрівський;Майдан-Петровский;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824785303;3;1
68cf2308-749b-11df-b112-00215aee3ebe;Майдан-Сахнівський;Майдан-Сахновский;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823085003;3;1
68cf2309-749b-11df-b112-00215aee3ebe;Майдан-Чапельський;Майдан-Чапельский;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520684906;3;0
68cf230a-749b-11df-b112-00215aee3ebe;Майдан-Чернелевецький;Майдан-Чернелевецкий;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821586401;3;1
68cf230b-749b-11df-b112-00215aee3ebe;Майків;Майков;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621284801;3;1
68cf230c-749b-11df-b112-00215aee3ebe;Майнич;Майнич;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624286003;3;1
2971e0a5-4c28-11e7-8101-1c98ec135263;Майнівка;Майнивка;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420685203;3;1
68cf230d-749b-11df-b112-00215aee3ebe;Майори;Майоры;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5110590001;3;1
68cf230e-749b-11df-b112-00215aee3ebe;Майорівка;Майоровка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325484007;3;1
68cf230f-749b-11df-b112-00215aee3ebe;Майорівка;Майоровка;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922686101;3;1
68cf2310-749b-11df-b112-00215aee3ebe;Майорівка;Майоровка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824580405;3;1
68cf2311-749b-11df-b112-00215aee3ebe;Майорка;Майорка;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221486210;3;1
68cf2312-749b-11df-b112-00215aee3ebe;Майорка;Майорка;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322085202;3;1
68cf2313-749b-11df-b112-00215aee3ebe;Майорське;Майорское;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125255111;3;1
68cf2314-749b-11df-b112-00215aee3ebe;Майорщина;Майорщина;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322082505;3;1
68cf2315-749b-11df-b112-00215aee3ebe;Майорщина;Майорщина;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524980307;3;1
68cf2316-749b-11df-b112-00215aee3ebe;Майорщина;Майорщина;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320882501;3;1
68cf2317-749b-11df-b112-00215aee3ebe;Майстрів;Майстров;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824083401;3;1
68cf2318-749b-11df-b112-00215aee3ebe;Майстрова Воля;Майстрова Воля;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824083402;3;1
68cf2319-749b-11df-b112-00215aee3ebe;Майське;Майское;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925683702;3;1
68cf231a-749b-11df-b112-00215aee3ebe;Майське;Майское;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922382902;3;1
68cf231b-749b-11df-b112-00215aee3ebe;Майське;Майское;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921285803;3;1
68cf231c-749b-11df-b112-00215aee3ebe;Майське;Майское;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224883501;3;1
68cf231d-749b-11df-b112-00215aee3ebe;Майське;Майское;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824582204;3;1
68cf231e-749b-11df-b112-00215aee3ebe;Майське;Майское;село;78f4540d-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6524185002;3;1
68cf2321-749b-11df-b112-00215aee3ebe;Майське;Майское;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221987905;3;1
68cf2322-749b-11df-b112-00215aee3ebe;Майське;Майское;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423085902;3;1
68cf2323-749b-11df-b112-00215aee3ebe;Майське;Майское;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520884005;3;1
68cf2324-749b-11df-b112-00215aee3ebe;Макалевичі;Макалевичи;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825085801;3;1
68cf2325-749b-11df-b112-00215aee3ebe;Макаренкове;Макаренково;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425385005;3;1
68cf2326-749b-11df-b112-00215aee3ebe;Макариха;Макариха;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322655107;3;1
68cf2327-749b-11df-b112-00215aee3ebe;Макариха;Макариха;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522283701;3;1
68cf2328-749b-11df-b112-00215aee3ebe;Макарів;Макаров;місто;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222755100;2;0
68cf2329-749b-11df-b112-00215aee3ebe;Макарівка;Макаровка;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223183402;3;1
68cf232a-749b-11df-b112-00215aee3ebe;Макарівка;Макаровка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885508;3;1
68cf232b-749b-11df-b112-00215aee3ebe;Макарівка;Макаровка;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322086003;3;1
68cf232f-749b-11df-b112-00215aee3ebe;Макарівка;Макаровка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222082101;3;1
68cf2330-749b-11df-b112-00215aee3ebe;Макарівка;Макаровка;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824783901;3;1
68cf2331-749b-11df-b112-00215aee3ebe;Макарівка;Макаровка;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420610102;3;1
68cf2332-749b-11df-b112-00215aee3ebe;Макарівська Буда;Макаровская Буда;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222782303;3;1
68cf2333-749b-11df-b112-00215aee3ebe;Макарове;Макарово;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821484802;3;1
68cf2334-749b-11df-b112-00215aee3ebe;Макарове;Макарово;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322685503;3;1
68cf2335-749b-11df-b112-00215aee3ebe;Макарове;Макарово;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125481901;3;1
68cf2338-749b-11df-b112-00215aee3ebe;Макарове;Макарово;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821586402;3;1
68cf233a-749b-11df-b112-00215aee3ebe;Макарцівка;Макарцовка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086908;3;1
68cf2336-749b-11df-b112-00215aee3ebe;Макарьово;Макарево;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122785401;2;1
68cf233b-749b-11df-b112-00215aee3ebe;Македони;Македоны;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222983601;3;1
68cf233e-749b-11df-b112-00215aee3ebe;Макишин;Макишин;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421486001;3;1
68cf233f-749b-11df-b112-00215aee3ebe;Маків;Маков;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821884701;3;1
68cf2342-749b-11df-b112-00215aee3ebe;Маківка;Маковка;село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223783001;3;1
68cf2344-749b-11df-b112-00215aee3ebe;Макієве;Макеево;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822380403;3;1
68cf2345-749b-11df-b112-00215aee3ebe;Макіївка;Макеевка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920686204;3;1
68cf2348-749b-11df-b112-00215aee3ebe;Макіївка;Макеевка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220482901;2;1
68cf2349-749b-11df-b112-00215aee3ebe;Макіївка;Макеевка;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423883001;3;1
68cf234a-749b-11df-b112-00215aee3ebe;Макіївка;Макеевка;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421182901;3;1
68cf234b-749b-11df-b112-00215aee3ebe;Макіївка;Макеевка;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123784001;3;1
68cf234c-749b-11df-b112-00215aee3ebe;Макіївське;Макеевское;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923287407;3;1
68cf234d-749b-11df-b112-00215aee3ebe;Макове;Маково;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925384201;3;1
68cf234e-749b-11df-b112-00215aee3ebe;Макове;Маково;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522580903;3;1
68cf234f-749b-11df-b112-00215aee3ebe;Маковецьке;Маковецкое;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224481902;3;1
68cf2350-749b-11df-b112-00215aee3ebe;Маковисько;Маковиское;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123084405;3;1
68cf2351-749b-11df-b112-00215aee3ebe;Маковиці;Маковицы;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824083403;3;1
68cf2352-749b-11df-b112-00215aee3ebe;Маковичі;Маковичи;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725582601;3;1
68cf2353-749b-11df-b112-00215aee3ebe;Маковище;Маковище;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222784601;3;1
68cf2354-749b-11df-b112-00215aee3ebe;Маковіївка;Маковиевка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520881202;3;1
68cf2355-749b-11df-b112-00215aee3ebe;Макорти;Макорты;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282515;3;1
68cf2356-749b-11df-b112-00215aee3ebe;Макотерти;Макотерты;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624682705;3;1
68cf2357-749b-11df-b112-00215aee3ebe;Макошине;Макошино;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423055700;3;1
68cf2358-749b-11df-b112-00215aee3ebe;Максаки;Максаки;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423085904;3;1
68cf2359-749b-11df-b112-00215aee3ebe;Максим;Максим;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422085301;3;1
68cf235b-749b-11df-b112-00215aee3ebe;Максима Горького;Максима Горького;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520683601;3;1
68cf235d-749b-11df-b112-00215aee3ebe;Максимець;Максимец;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624082102;3;1
68cf235e-749b-11df-b112-00215aee3ebe;Максимихине;Максимихино;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424780506;3;1
68cf235f-749b-11df-b112-00215aee3ebe;Максимівка;Максимовка;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321683401;3;1
68cf2360-749b-11df-b112-00215aee3ebe;Максимівка;Максимовка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322482802;3;1
68cf2361-749b-11df-b112-00215aee3ebe;Максимівка;Максимовка;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122486401;3;1
68cf2362-749b-11df-b112-00215aee3ebe;Максимівка;Максимовка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222986903;3;1
68cf2363-749b-11df-b112-00215aee3ebe;Максимівка;Максимовка;село;5394b330-41be-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1211390001;3;1
68cf2364-749b-11df-b112-00215aee3ebe;Максимівка;Максимовка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824583403;3;1
68cf2365-749b-11df-b112-00215aee3ebe;Максимівка;Максимовка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825781703;3;1
68cf2366-749b-11df-b112-00215aee3ebe;Максимівка;Максимовка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320885004;3;1
68cf2367-749b-11df-b112-00215aee3ebe;Максимівка;Максимовка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325785804;3;1
68cf2368-749b-11df-b112-00215aee3ebe;Максимівка;Максимовка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324586003;3;1
68cf2369-749b-11df-b112-00215aee3ebe;Максимівка;Максимовка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324882005;3;1
68cf236a-749b-11df-b112-00215aee3ebe;Максимівка;Максимовка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321583001;3;1
68cf236b-749b-11df-b112-00215aee3ebe;Максимівка;Максимовка;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622082905;3;1
68cf2370-749b-11df-b112-00215aee3ebe;Максимівка;Максимовка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421788003;3;1
68cf2371-749b-11df-b112-00215aee3ebe;Максимівка;Максимовка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124385301;3;1
68cf2372-749b-11df-b112-00215aee3ebe;Максимівка;Максимовка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320486503;3;1
6ed81db7-749b-11df-b112-00215aee3ebe;Максимівка (Октябрське);Максимовка (Октябрьское);село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224883509;3;1
68cf2373-749b-11df-b112-00215aee3ebe;Максимівщина;Максимовщина;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920682605;3;1
68cf2374-749b-11df-b112-00215aee3ebe;Максимівщина;Максимовщина;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323680404;3;1
68cf2376-749b-11df-b112-00215aee3ebe;Максимовичі;Максимовичи;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624286903;3;1
68cf2377-749b-11df-b112-00215aee3ebe;Максимовичі;Максимовичи;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223585601;3;1
68cf2378-749b-11df-b112-00215aee3ebe;Максимці;Максимцы;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622486407;3;1
68cf2379-749b-11df-b112-00215aee3ebe;Макунів;Макунов;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484404;3;1
68cf237a-749b-11df-b112-00215aee3ebe;Макухи;Макухи;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321382704;3;1
68cf237b-749b-11df-b112-00215aee3ebe;Макухівка;Макуховка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081910;3;1
68cf237c-749b-11df-b112-00215aee3ebe;Макушиха;Макушиха;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421183003;3;1
68cf238f-749b-11df-b112-00215aee3ebe;Мала Анастасівка;Малая Анастасьевка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824055608;3;1
68cf2391-749b-11df-b112-00215aee3ebe;Мала Антонівка;Малая Антоновка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220410507;3;1
68cf2392-749b-11df-b112-00215aee3ebe;Мала Багачівка;Малая Богачовка;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823980403;3;1
68cf2393-749b-11df-b112-00215aee3ebe;Мала Бережанка;Малая Бережанка;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825286602;2;1
68cf2394-749b-11df-b112-00215aee3ebe;Мала Березанка;Малая Березанка;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221983901;3;1
68cf2395-749b-11df-b112-00215aee3ebe;Мала Березівка;Малая Березовка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520380802;3;1
68cf2396-749b-11df-b112-00215aee3ebe;Мала Березовиця;Малая Березовица;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122483602;3;1
68cf2397-749b-11df-b112-00215aee3ebe;Мала Березянка;Малая Березянка;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224486902;3;1
68cf2398-749b-11df-b112-00215aee3ebe;Мала Бийгань;Малая Быйгань;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120482002;3;1
33ec0021-ddd5-11df-9197-00215aee3ebe;Мала Біла (Червона Зірка);Малая Белая (Червоная Зирка);село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522280203;3;1
68cf2399-749b-11df-b112-00215aee3ebe;Мала Білина;Малая Белина;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624281203;3;1
68cf239a-749b-11df-b112-00215aee3ebe;Мала Білка;Малая Белка;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123884401;3;1
68cf239c-749b-11df-b112-00215aee3ebe;Мала Боровиця;Малая Боровица;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820385501;3;1
68cf239d-749b-11df-b112-00215aee3ebe;Мала Бугаївка;Малая Бугаевка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221484007;3;1
68cf239e-749b-11df-b112-00215aee3ebe;Мала Буда;Малая Буда;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320780802;3;1
68cf239f-749b-11df-b112-00215aee3ebe;Мала Бузова;Малая Бузова;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755132;3;1
68cf23a0-749b-11df-b112-00215aee3ebe;Мала Бурімка;Малая Буромка;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125186001;3;1
68cf23a1-749b-11df-b112-00215aee3ebe;Мала Бушинка;Малая Бушинка;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523085802;3;1
68cf23a2-749b-11df-b112-00215aee3ebe;Мала Василівка;Малая Василевка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123382901;3;1
68cf23a3-749b-11df-b112-00215aee3ebe;Мала Ведмежка;Малая Ведмежка;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723687603;3;1
68cf23a4-749b-11df-b112-00215aee3ebe;Мала Вербівка;Малая Вербовка;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624287605;3;1
68cf23a5-749b-11df-b112-00215aee3ebe;Мала Виска;Малая Выска;місто;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523110100;3;0
68cf23a6-749b-11df-b112-00215aee3ebe;Мала Вільшанка;Малая Ольшанка;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621881202;3;1
68cf23a7-749b-11df-b112-00215aee3ebe;Мала Вільшанка;Малая Ольшанка;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223186001;3;1
68cf23a8-749b-11df-b112-00215aee3ebe;Мала Вільшанка;Малая Ольшанка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220483301;2;1
68cf23a9-749b-11df-b112-00215aee3ebe;Мала Вільшанка;Малая Ольшанка;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524382601;3;1
cdc44f6a-dde8-11df-9197-00215aee3ebe;Мала Вільшанка Перша;Малая Ольшанка Первая;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;;3;1
68cf23aa-749b-11df-b112-00215aee3ebe;Мала Вовнянка;Малая Вовнянка;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224481303;3;1
68cf23ac-749b-11df-b112-00215aee3ebe;Мала Волиця;Малая Волица;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825885201;3;1
68cf23ad-749b-11df-b112-00215aee3ebe;Мала Волосянка;Малая Волосянка;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625587802;3;1
68cf23ae-749b-11df-b112-00215aee3ebe;Мала Воля;Малая Воля;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623088413;3;1
68cf23af-749b-11df-b112-00215aee3ebe;Мала Врадіївка;Малая Врадиевка;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822381703;3;1
68cf237f-749b-11df-b112-00215aee3ebe;Мала Вулига;Малая Вулыга;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524583801;3;1
68cf23b0-749b-11df-b112-00215aee3ebe;Мала Ганнівка;Малая Анновка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824580406;3;1
68cf23b1-749b-11df-b112-00215aee3ebe;Мала Ганнівка;Малая Анновка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524980802;3;1
325c5801-ddd2-11df-9197-00215aee3ebe;Мала Ганнівка;Малая Анновка;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525880302;3;1
68cf23b2-749b-11df-b112-00215aee3ebe;Мала Глумча;Малая Глумча;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821783601;3;1
68cf2380-749b-11df-b112-00215aee3ebe;Мала Глуша;Малая Глуша;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723186601;3;1
68cf23b3-749b-11df-b112-00215aee3ebe;Мала Горбаша;Малая Горбаша;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824080602;3;1
68cf23b4-749b-11df-b112-00215aee3ebe;Мала Горбаша;Малая Горбаша;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825681002;3;1
68cf23b5-749b-11df-b112-00215aee3ebe;Мала Горожанна;Малая Горожанна;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623086005;3;1
68cf23b6-749b-11df-b112-00215aee3ebe;Мала Горянка;Малая Горянка;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123481505;3;1
68cf23b7-749b-11df-b112-00215aee3ebe;Мала Грем'яча;Малая Гремячая;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323286003;3;1
68cf23b8-749b-11df-b112-00215aee3ebe;Мала Данилівка;Малая Даниловка;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322055900;2;1
68cf23b9-749b-11df-b112-00215aee3ebe;Мала Дворянка;Малая Дворянка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123580404;3;1
68cf23ba-749b-11df-b112-00215aee3ebe;Мала Деребчинка;Малая Деребчинка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525381205;3;1
68cf23bb-749b-11df-b112-00215aee3ebe;Мала Деревичка;Малая Деревичка;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823183202;3;1
68cf23bc-749b-11df-b112-00215aee3ebe;Мала Діброва;Малая Диброва;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622482009;3;1
68cf23bd-749b-11df-b112-00215aee3ebe;Мала Дівиця;Малая Девица;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424155900;3;1
68cf23be-749b-11df-b112-00215aee3ebe;Мала Добронь;Малая Добронь;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124883901;3;1
68cf23bf-749b-11df-b112-00215aee3ebe;Мала Доч;Малая Дочь;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420884003;3;1
68cf23c0-749b-11df-b112-00215aee3ebe;Мала Жмеринка;Малая Жмеринка;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521083606;3;1
68cf23c1-749b-11df-b112-00215aee3ebe;Мала Загорівка;Малая Загоровка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420882801;3;1
68cf23c2-749b-11df-b112-00215aee3ebe;Мала Зелена;Малая Зеленая;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825289405;2;1
68cf23c3-749b-11df-b112-00215aee3ebe;Мала Зубівщина;Малая Зубовщина;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822383001;3;1
4aba3e52-749b-11df-b112-00215aee3ebe;Мала Іванівка ( Володарівка );Володаровка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320884502;3;1
68cf23c4-749b-11df-b112-00215aee3ebe;Мала іловиця;Малая Иловица;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125883505;3;1
68cf23c5-749b-11df-b112-00215aee3ebe;Мала Кадигробівка;Малая Кадыгробовка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321282506;3;1
68cf23c6-749b-11df-b112-00215aee3ebe;Мала Калинівка;Малая Калиновка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225083003;3;1
68cf23c7-749b-11df-b112-00215aee3ebe;Мала Кам'янка;Малая Каменная;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623283701;3;1
68cf23c8-749b-11df-b112-00215aee3ebe;Мала Каратуль;Малая Каратуль;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223384601;3;1
68cf23c9-749b-11df-b112-00215aee3ebe;Мала Карначівка;Малая Карначевка;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123882004;3;1
68cf23ca-749b-11df-b112-00215aee3ebe;Мала Киріївка;Малая Киреевка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520483606;3;1
68cf23cb-749b-11df-b112-00215aee3ebe;Мала Кіндратівка;Малая Кондратовка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122986003;3;1
68cf23cc-749b-11df-b112-00215aee3ebe;Мала Клецька;Малая Клецка;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623080802;3;1
68cf23cd-749b-11df-b112-00215aee3ebe;Мала Клітинка;Малая Клетинка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521486406;3;1
68cf2382-749b-11df-b112-00215aee3ebe;Мала Клітна;Малая Клетна;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822787001;3;1
68cf23ce-749b-11df-b112-00215aee3ebe;Мала Козара;Малая Козара;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821455603;3;1
68cf23cf-749b-11df-b112-00215aee3ebe;Мала Колибань;Малая Колыбань;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825083305;3;0
68cf23d0-749b-11df-b112-00215aee3ebe;Мала Комишуваха;Малая Камышеваха;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322887001;3;1
68cf23d1-749b-11df-b112-00215aee3ebe;Мала Копаня;Малая Копаня;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121282601;3;1
68cf23d2-749b-11df-b112-00215aee3ebe;Мала Корениха;Малая Корениха;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;2;1
7ad94f92-749b-11df-b112-00215aee3ebe;Мала Корчаківка (Радянське);Мала Корчакивка (Радянское);село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924785907;3;1
68cf23d4-749b-11df-b112-00215aee3ebe;Мала Костромка;Малая Костромка;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220310301;3;1
68cf23d5-749b-11df-b112-00215aee3ebe;Мала Кохнівка;Малая Кохновка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322484403;3;1
68cf23d6-749b-11df-b112-00215aee3ebe;Мала Кошелівка;Малая Кошелевка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423386701;3;1
68cf23d8-749b-11df-b112-00215aee3ebe;Мала Круча;Малая Круча;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323881002;3;1
68cf2383-749b-11df-b112-00215aee3ebe;Мала Кужелівка;Малая Кужелевка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821885301;3;1
68cf23d9-749b-11df-b112-00215aee3ebe;Мала Левада;Малая Левада;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821283903;3;1
d0264a11-590f-11e4-81a4-003048d2b473;Мала Лінина;Мала Лінина;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;;3;1
68cf2385-749b-11df-b112-00215aee3ebe;Мала Лука;Малая Лука;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121684501;3;1
68cf2386-749b-11df-b112-00215aee3ebe;Мала Любаша;Малая Любаша;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623483901;3;1
68cf23da-749b-11df-b112-00215aee3ebe;Мала Мазниця;Малая Мазница;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524383504;3;1
68cf23db-749b-11df-b112-00215aee3ebe;Мала Макарівка;Малая Макаровка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222082102;3;1
68cf23dc-749b-11df-b112-00215aee3ebe;Мала Маньківка;Малая Маньковка;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123185002;3;1
68cf23de-749b-11df-b112-00215aee3ebe;Мала Мартинка;Малая Мартынка;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124087203;3;1
68cf23df-749b-11df-b112-00215aee3ebe;Мала Медведівка;Малая Медведевка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822181602;3;1
68cf2387-749b-11df-b112-00215aee3ebe;Мала Мечетня;Малая Мечетня;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823982901;3;1
68cf23e0-749b-11df-b112-00215aee3ebe;Мала Михайлівка;Малая Михайловка;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224081205;3;1
68cf2388-749b-11df-b112-00215aee3ebe;Мала Мочулка;Малая Мочулка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523783901;3;1
68cf2389-749b-11df-b112-00215aee3ebe;Мала Мощаниця;Малая Мощаница;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622683401;3;1
68cf23e1-749b-11df-b112-00215aee3ebe;Мала Нехвороща;Малая Нехвороща;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323083001;3;1
68cf23e2-749b-11df-b112-00215aee3ebe;Мала Обухівка;Малая Обуховка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320484104;3;1
68cf23e3-749b-11df-b112-00215aee3ebe;Мала Озимина;Малая Озимина;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624281303;3;1
68cf23e4-749b-11df-b112-00215aee3ebe;Мала Олександрівка;Малая Александровка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122985605;3;1
68cf23e5-749b-11df-b112-00215aee3ebe;Мала Олександрівка;Малая Александровка;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220880903;3;0
68cf238a-749b-11df-b112-00215aee3ebe;Мала Олександрівка;Малая Александровка;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520982501;3;1
68cf23e6-749b-11df-b112-00215aee3ebe;Мала Осниця;Малая Осница;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723681103;3;1
68cf23e7-749b-11df-b112-00215aee3ebe;Мала Офірна;Малая Офирна;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224984203;3;1
68cf23f0-749b-11df-b112-00215aee3ebe;Мала П'ятигірка;Малая Пятигорка;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820386801;3;1
68cf238b-749b-11df-b112-00215aee3ebe;Мала Павлівка;Малая Павловка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920385801;3;1
68cf23e8-749b-11df-b112-00215aee3ebe;Мала Перещепина;Малая Перещепина;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323483401;3;1
87162715-749b-11df-b112-00215aee3ebe;Мала Петриківка (Червонопартизанське);Мала Петриківка (Червонопартизанське);село;bad3ac3c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1223755109;3;1
68cf23e9-749b-11df-b112-00215aee3ebe;Мала Петрівка;Малая Петровка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122986004;3;1
68cf23ea-749b-11df-b112-00215aee3ebe;Мала Плавуча;Малая Плавуча;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123085801;3;1
68cf23eb-749b-11df-b112-00215aee3ebe;Мала Побиванка;Малая Побиванка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320484601;3;1
68cf23ec-749b-11df-b112-00215aee3ebe;Мала Побіянка;Малая Побоянка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821885601;3;1
68cf23ed-749b-11df-b112-00215aee3ebe;Мала Покровка;Малая Покровка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222683303;3;1
68cf23ee-749b-11df-b112-00215aee3ebe;Мала Помічна;Малая Помошная;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524083201;3;1
68cf23ef-749b-11df-b112-00215aee3ebe;Мала Попівка;Малая Поповка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324884203;3;1
68cf23f1-749b-11df-b112-00215aee3ebe;Мала Радогощ;Малая Радогощь;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822184505;3;1
68cf23f2-749b-11df-b112-00215aee3ebe;Мала Рача;Малая Рача;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825080902;3;1
68cf23f3-749b-11df-b112-00215aee3ebe;Мала Решетилівка;Малая Решетиловка;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320255107;3;1
68cf23f4-749b-11df-b112-00215aee3ebe;Мала Рибиця;Малая Рыбица;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922382501;3;1
68cf23f5-749b-11df-b112-00215aee3ebe;Мала Рогань;Малая Рогань;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325182501;3;1
68cf23f6-749b-11df-b112-00215aee3ebe;Мала Рогозянка;Малая Рогозянка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322684001;3;1
68cf23f7-749b-11df-b112-00215aee3ebe;Мала Розтока;Малая Ростока;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121982803;3;1
68cf23f8-749b-11df-b112-00215aee3ebe;Мала Ростівка;Малая Ростовка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523186302;3;1
68cf23f9-749b-11df-b112-00215aee3ebe;Мала Рублівка;Малая Рублевка;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322282501;3;1
68cf23fa-749b-11df-b112-00215aee3ebe;Мала Рудка;Малая Рудка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321082005;3;1
68cf23fb-749b-11df-b112-00215aee3ebe;Мала Салиха;Малая Салиха;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822781403;3;1
68cf23fc-749b-11df-b112-00215aee3ebe;Мала Севастянівка;Малая Севастьяновка;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124684001;3;1
68cf23fd-749b-11df-b112-00215aee3ebe;Мала Сейдеминуха;Малая Сейдеминуха;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520980504;3;1
68cf23fe-749b-11df-b112-00215aee3ebe;Мала Сквирка;Малая Сквирка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220489702;2;1
68cf23ff-749b-11df-b112-00215aee3ebe;Мала Скельова;Малая Скелевая;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525280705;3;1
68cf2400-749b-11df-b112-00215aee3ebe;Мала Слобідка;Малая Слободка;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921581502;3;1
68cf2401-749b-11df-b112-00215aee3ebe;Мала Слобідка;Малая Слободка;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122583701;3;1
68cf2402-749b-11df-b112-00215aee3ebe;Мала Слобідка;Малая Слободка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822489105;3;1
68cf2403-749b-11df-b112-00215aee3ebe;Мала Смілянка;Малая Смелянка;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123784501;3;1
68cf2404-749b-11df-b112-00215aee3ebe;Мала Снігурівка;Малая Снегуровка;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123885205;3;1
68cf2405-749b-11df-b112-00215aee3ebe;Мала Снітинка;Малая Снетинка;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224984201;3;1
68cf2406-749b-11df-b112-00215aee3ebe;Мала Совпа;Малая Совпа;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623088802;3;1
68cf2407-749b-11df-b112-00215aee3ebe;Мала Солтанівка;Малая Солтановка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221484901;3;1
68cf2408-749b-11df-b112-00215aee3ebe;Мала Спринька;Малая Спрынька;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624285204;3;1
68cf2409-749b-11df-b112-00215aee3ebe;Мала Стадниця;Малая Стадница;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
68cf240a-749b-11df-b112-00215aee3ebe;Мала Стариця;Малая Старица;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220884404;3;1
68cf240b-749b-11df-b112-00215aee3ebe;Мала Стратіївка;Малая Стратеевка;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524183903;3;1
68cf238c-749b-11df-b112-00215aee3ebe;Мала Стружка;Малая Стружка;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823386001;3;1
68cf240c-749b-11df-b112-00215aee3ebe;Мала Супоївка;Малая Супоевка;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221984001;3;1
68cf240d-749b-11df-b112-00215aee3ebe;Мала Тарасівка;Малая Тарасовка;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220286402;3;1
68cf240f-749b-11df-b112-00215aee3ebe;Мала Тернівка;Малая Терновка;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;;3;1
68cf2410-749b-11df-b112-00215aee3ebe;Мала Тимошівка;Малая Тимошовка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524083401;3;1
68cf2411-749b-11df-b112-00215aee3ebe;Мала Токарівка;Малая Токаревка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821481003;3;1
68cf2412-749b-11df-b112-00215aee3ebe;Мала Токмачка;Малая Токмачка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323982501;3;1
68cf2413-749b-11df-b112-00215aee3ebe;Мала Топорівка;Малая Топоровка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125283605;3;1
68cf2414-749b-11df-b112-00215aee3ebe;Мала Тур'я;Малая Турья;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622083001;3;1
68cf2415-749b-11df-b112-00215aee3ebe;Мала Уголька;Малая Уголька;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124487604;3;1
68cf2416-749b-11df-b112-00215aee3ebe;Мала Фосня;Малая Фосня;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824280902;3;1
68cf2417-749b-11df-b112-00215aee3ebe;Мала Хайча;Малая Хайча;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824281103;3;1
68cf2418-749b-11df-b112-00215aee3ebe;Мала Хвороща;Малая Хвороща;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624286004;3;1
68cf238d-749b-11df-b112-00215aee3ebe;Мала Цвіля;Малая Цвиля;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824083601;3;1
8716274a-749b-11df-b112-00215aee3ebe;Мала Черепівка (Черепівка);Малая Череповка (Череповка);село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923555609;3;1
68cf2419-749b-11df-b112-00215aee3ebe;Мала Чернігівка;Малая Черниговка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824281304;3;1
68cf241a-749b-11df-b112-00215aee3ebe;Мала Чернявка;Малая Чернявка;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825284401;3;1
68cf238e-749b-11df-b112-00215aee3ebe;Мала Шестірня;Малая Шестерня;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521882001;3;1
68cf241d-749b-11df-b112-00215aee3ebe;Мала Шкарівка;Малая Шкаровка;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823684001;3;1
68cf241e-749b-11df-b112-00215aee3ebe;Мала Щурка;Малая Щурка;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823384503;3;1
68cf2420-749b-11df-b112-00215aee3ebe;Мала Яблунька;Малая Яблонька;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723682205;3;1
68cf2421-749b-11df-b112-00215aee3ebe;Мала Яромирка;Малая Яромирка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821281603;3;1
68cf2422-749b-11df-b112-00215aee3ebe;Малаївці;Малаивцы;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123182301;3;1
68cf2423-749b-11df-b112-00215aee3ebe;Малаківщина;Малаковщина;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823085402;3;1
68cf2424-749b-11df-b112-00215aee3ebe;Маламівка;Маламовка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322480403;3;1
68cf2425-749b-11df-b112-00215aee3ebe;Малахівка;Малаховка;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822883202;3;1
68cf2426-749b-11df-b112-00215aee3ebe;Малахове;Малахово;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820955102;3;1
68cf2428-749b-11df-b112-00215aee3ebe;Малашівці;Малашевцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122685401;3;1
68cf2429-749b-11df-b112-00215aee3ebe;Малашівці;Малашевцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825083901;3;1
68cf242a-749b-11df-b112-00215aee3ebe;Мале;Малое;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924185003;3;1
68cf242b-749b-11df-b112-00215aee3ebe;Мале;Малое;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123981404;3;1
68cf242c-749b-11df-b112-00215aee3ebe;Мале Вербче;Малое Вербче;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625482802;3;1
68cf242d-749b-11df-b112-00215aee3ebe;Мале Ладижине;Малое Ладыжино;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324080505;3;1
68cf242e-749b-11df-b112-00215aee3ebe;Мале Микільське;Малое Никольское;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083910;3;1
68cf242f-749b-11df-b112-00215aee3ebe;Мале Оріхове;Малое Орехово;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724287105;3;1
68cf2430-749b-11df-b112-00215aee3ebe;Мале Попово;Малое Попово;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120488003;3;1
68cf2432-749b-11df-b112-00215aee3ebe;Мале Старосілля;Малое Староселье;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123785001;3;1
68cf2433-749b-11df-b112-00215aee3ebe;Мале Устя;Малое Устье;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424955102;3;1
68cf2434-749b-11df-b112-00215aee3ebe;Малеве;Малево;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621484009;3;1
68cf2435-749b-11df-b112-00215aee3ebe;Маленки;Маленки;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822788803;3;1
68cf2437-749b-11df-b112-00215aee3ebe;Малехів;Малехов;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785701;2;0
64af4b9e-a348-11e2-bb38-003048d2b473;Малехів;Малехив;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;;2;1
68cf2438-749b-11df-b112-00215aee3ebe;Малечковичі;Малечковичи;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623686604;3;0
68cf2439-749b-11df-b112-00215aee3ebe;Малижине;Малыжино;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322685004;3;1
68cf243a-749b-11df-b112-00215aee3ebe;Малий;Малый;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785212;3;1
68cf243b-749b-11df-b112-00215aee3ebe;Малий Бакай;Малый Бакай;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324282201;3;1
68cf243c-749b-11df-b112-00215aee3ebe;Малий Березний;Малый Березный;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120883601;3;1
68cf243d-749b-11df-b112-00215aee3ebe;Малий Бобрик;Малый Бобрик;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922380804;3;1
68cf243e-749b-11df-b112-00215aee3ebe;Малий Браталів;Малый Браталов;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823184001;3;1
68cf243f-749b-11df-b112-00215aee3ebe;Малий Бузуків;Малый Бузуков;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123782003;3;1
68cf2440-749b-11df-b112-00215aee3ebe;Малий Букрин;Малый Букрин;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222984001;3;1
68cf2444-749b-11df-b112-00215aee3ebe;Малий В'язівок;Малый Вязовок;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322881403;3;1
68cf2442-749b-11df-b112-00215aee3ebe;Малий Вистороп;Малый Выстороп;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922985801;3;1
68cf2443-749b-11df-b112-00215aee3ebe;Малий Вишнопіль;Малый Вишнополь;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824282002;3;1
68cf2445-749b-11df-b112-00215aee3ebe;Малий Гвіздець;Малый Гвоздец;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623286703;3;1
68cf2446-749b-11df-b112-00215aee3ebe;Малий Глибочок;Малый Глубочок;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122480402;3;1
68cf2447-749b-11df-b112-00215aee3ebe;Малий Говилів;Малый Говилов;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125080902;3;1
68cf2448-749b-11df-b112-00215aee3ebe;Малий Дивлин;Малый Дивлин;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822881304;3;1
68cf2449-749b-11df-b112-00215aee3ebe;Малий Дирчин;Малый Дирчин;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421481805;3;1
68cf244a-749b-11df-b112-00215aee3ebe;Малий Дихтинець;Малый Дихтинец;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323580504;3;1
68cf244b-749b-11df-b112-00215aee3ebe;Малий Дорошів;Малый Дорошев;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622781003;3;1
68cf244c-749b-11df-b112-00215aee3ebe;Малий Жванчик;Малый Жванчик;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821880603;3;1
68cf244d-749b-11df-b112-00215aee3ebe;Малий Житин;Малый Житин;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624681505;3;1
68cf244e-749b-11df-b112-00215aee3ebe;Малий Жолудськ;Малый Жолудск;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620881502;3;1
68cf244f-749b-11df-b112-00215aee3ebe;Малий Заланів;Малый Заланов;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624482302;3;1
68cf2450-749b-11df-b112-00215aee3ebe;Малий Зліїв;Малый Злиев;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424480803;3;1
68cf2451-749b-11df-b112-00215aee3ebe;Малий Карабчіїв;Малый Карабчиев;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821885201;3;1
68cf2452-749b-11df-b112-00215aee3ebe;Малий Карашин;Малый Карашин;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222780902;3;1
68cf2453-749b-11df-b112-00215aee3ebe;Малий Ключів;Малый Ключев;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623255703;3;1
68cf2454-749b-11df-b112-00215aee3ebe;Малий Кобелячок;Малый Кобелячок;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323483201;3;1
68cf2455-749b-11df-b112-00215aee3ebe;Малий Кобилин;Малый Кобылин;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824281104;3;1
68cf2457-749b-11df-b112-00215aee3ebe;Малий Кривотин;Малый Кривотин;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821783205;3;1
68cf23d7-749b-11df-b112-00215aee3ebe;Малий Крупіль;Малый Крупиль;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221981003;3;1
68cf2458-749b-11df-b112-00215aee3ebe;Малий Кунинець;Малый Кунинец;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122481002;3;1
68cf2459-749b-11df-b112-00215aee3ebe;Малий Кучурів;Малый Кучуров;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321585601;3;1
0d1425b1-5a2d-11eb-80d0-000c29800ae7;Малий Куяльник; Малый Куяльник;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122983801;3;1
68cf245a-749b-11df-b112-00215aee3ebe;Малий Лазучин;Малый Лазучин;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824780504;3;1
68cf245b-749b-11df-b112-00215aee3ebe;Малий Листвен;Малый Листвен;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424484401;3;1
68cf245c-749b-11df-b112-00215aee3ebe;Малий Любінь;Малый Любень;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620955303;3;1
68cf245e-749b-11df-b112-00215aee3ebe;Малий Мидськ;Малый Мидск;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623484201;3;1
68cf245f-749b-11df-b112-00215aee3ebe;Малий Митник;Малый Мытник;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524880604;3;1
68cf2460-749b-11df-b112-00215aee3ebe;Малий Обзир;Малый Обзыр;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721481002;3;1
68cf2461-749b-11df-b112-00215aee3ebe;Малий Обухів;Малый Обухов;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522884803;3;1
68cf2462-749b-11df-b112-00215aee3ebe;Малий Окорськ;Малый Окорск;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722482405;3;1
68cf2463-749b-11df-b112-00215aee3ebe;Малий Олексин;Малый Алексин;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624689504;3;1
68cf2464-749b-11df-b112-00215aee3ebe;Малий Орчик;Малый Орчик;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322282001;3;1
68cf2465-749b-11df-b112-00215aee3ebe;Малий Острожок;Малый Острожок;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524881202;3;1
68cf2466-749b-11df-b112-00215aee3ebe;Малий Перевіз;Малый Перевоз;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755133;3;1
68cf2467-749b-11df-b112-00215aee3ebe;Малий Полюхів;Малый Полюхов;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623382503;3;1
68cf2468-749b-11df-b112-00215aee3ebe;Малий Порськ;Малый Порск;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155314;3;1
68cf2469-749b-11df-b112-00215aee3ebe;Малий Правутин;Малый Правутин;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823984801;3;1
68cf246a-749b-11df-b112-00215aee3ebe;Малий Раковець;Малый Раковец;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121986001;3;1
68cf246b-749b-11df-b112-00215aee3ebe;Малий Ржавець;Малый Ржавец;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122086203;3;1
68cf246c-749b-11df-b112-00215aee3ebe;Малий Рожин;Малый Рожин;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623684501;3;1
68cf246d-749b-11df-b112-00215aee3ebe;Малий Самбір;Малый Самбор;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922085401;3;1
68cf246e-749b-11df-b112-00215aee3ebe;Малий Скнит;Малый Скнит;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823984901;3;1
68cf246f-749b-11df-b112-00215aee3ebe;Малий Стидин;Малый Стыдин;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623484601;3;1
68cf2471-749b-11df-b112-00215aee3ebe;Малий Тростянець;Малый Тростянец;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086205;3;1
68cf2473-749b-11df-b112-00215aee3ebe;Малий Фонтан;Малый Фонтан;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122981303;2;1
68cf2474-749b-11df-b112-00215aee3ebe;Малий Ходачків;Малый Ходачков;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125284801;2;1
68cf2475-749b-11df-b112-00215aee3ebe;Малий Чернятин;Малый Чернятин;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824281302;3;1
68cf2476-749b-11df-b112-00215aee3ebe;Малий Шпаків;Малый Шпаков;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624686701;3;1
68cf2477-749b-11df-b112-00215aee3ebe;Малий Яблунець;Малый Яблонец;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821782203;3;1
68cf2478-749b-11df-b112-00215aee3ebe;Малики;Малики;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322484805;3;1
68cf2479-749b-11df-b112-00215aee3ebe;Маликівщина;Маликовщина;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755134;3;1
68cf247a-749b-11df-b112-00215aee3ebe;Малин;Малин;місто;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1810900000;2;0
68cf247b-749b-11df-b112-00215aee3ebe;Малин;Малин;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623884701;3;1
9b5abc20-b0e9-11df-893f-00215aee3ebe;Малиничі;Малиничи;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825084201;3;1
68cf247c-749b-11df-b112-00215aee3ebe;Малинище;Малинище;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620384403;3;1
68cf247d-749b-11df-b112-00215aee3ebe;Малинів;Малинов;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624288203;3;1
68cf247e-749b-11df-b112-00215aee3ebe;Малинівка;Малиновка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320682803;3;1
68cf247f-749b-11df-b112-00215aee3ebe;Малинівка;Малиновка;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724585006;3;1
68cf2480-749b-11df-b112-00215aee3ebe;Малинівка;Малиновка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225086002;3;1
68cf2481-749b-11df-b112-00215aee3ebe;Малинівка;Малиновка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224287807;3;1
68cf2482-749b-11df-b112-00215aee3ebe;Малинівка;Малиновка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225884807;3;1
68cf2483-749b-11df-b112-00215aee3ebe;Малинівка;Малиновка;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323084001;3;1
68cf2484-749b-11df-b112-00215aee3ebe;Малинівка;Малиновка;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325455900;2;1
68cf2485-749b-11df-b112-00215aee3ebe;Малинівка;Малиновка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320481507;3;1
68cf2486-749b-11df-b112-00215aee3ebe;Малинівка;Малиновка;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121880402;3;1
68cf248a-749b-11df-b112-00215aee3ebe;Малинівка;Малиновка;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624481006;3;1
68cf248b-749b-11df-b112-00215aee3ebe;Малинівка;Малиновка;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623684204;3;1
68cf2491-749b-11df-b112-00215aee3ebe;Малинівка;Малиновка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823485201;3;1
68cf2492-749b-11df-b112-00215aee3ebe;Малинівка;Малиновка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824085003;3;1
68cf2493-749b-11df-b112-00215aee3ebe;Малинівка;Малиновка;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825687902;3;1
68cf2494-749b-11df-b112-00215aee3ebe;Малинівка;Малиновка;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820982703;3;1
68cf2495-749b-11df-b112-00215aee3ebe;Малинівка;Малиновка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424484201;3;1
68cf2496-749b-11df-b112-00215aee3ebe;Малинівка;Малиновка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425588702;3;1
68cf2497-749b-11df-b112-00215aee3ebe;Малинівка;Малиновка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522484601;3;1
68cf2498-749b-11df-b112-00215aee3ebe;Малинівка;Малиновка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524983401;3;1
68cf2499-749b-11df-b112-00215aee3ebe;Малинівка;Малиновка;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621285101;3;1
68cf249a-749b-11df-b112-00215aee3ebe;Малинівка;Малиновка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323289205;3;1
96744e53-e4bb-11e3-9b87-003048d2b473;Малинівка;Малиновка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324588013;3;1
68cf249b-749b-11df-b112-00215aee3ebe;Малинівці;Малинивцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822481303;3;1
68cf249c-749b-11df-b112-00215aee3ebe;Малинівщина;Малиновщина;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121581303;3;1
68cf249d-749b-11df-b112-00215aee3ebe;Малинки;Малинки;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523484103;3;1
68cf249e-749b-11df-b112-00215aee3ebe;Малинове;Малиновое;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225055403;3;1
68cf24a1-749b-11df-b112-00215aee3ebe;Малинськ;Малинск;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620486201;3;1
68cf24a2-749b-11df-b112-00215aee3ebe;Малинці;Малинцы;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325085201;3;1
68cf24a3-749b-11df-b112-00215aee3ebe;Маличина Гребля;Маличина Гребля;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420881204;3;1
68cf24a4-749b-11df-b112-00215aee3ebe;Малишівка;Малышевка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322188803;3;1
68cf24a5-749b-11df-b112-00215aee3ebe;Малишівка;Малышевка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521482606;3;1
68cf24a6-749b-11df-b112-00215aee3ebe;Малишівка;Малышевка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824283902;3;1
68cf24ac-749b-11df-b112-00215aee3ebe;Малі Баранівці;Малые Барановцы;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624281607;3;1
68cf24ad-749b-11df-b112-00215aee3ebe;Малі Бережці;Малые Бережцы;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123481402;3;1
68cf24a7-749b-11df-b112-00215aee3ebe;Малі Бірки;Малые Борки;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121684201;3;1
68cf24a8-749b-11df-b112-00215aee3ebe;Малі Бубни;Малые Бубны;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924186101;3;1
68cf24ae-749b-11df-b112-00215aee3ebe;Малі Будища;Малые Будища;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321383601;3;1
68cf24af-749b-11df-b112-00215aee3ebe;Малі Будища;Малые Будища;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320486904;3;1
68cf24b0-749b-11df-b112-00215aee3ebe;Малі Будки;Малые Будки;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923583407;3;1
68cf24b1-749b-11df-b112-00215aee3ebe;Малі Бучки;Малые Бучки;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324881002;3;1
68cf24b2-749b-11df-b112-00215aee3ebe;Малі Вікнини;Малые Окнины;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122481203;3;1
68cf24b3-749b-11df-b112-00215aee3ebe;Малі Вільми;Малые Вильмы;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924783804;3;1
68cf24b4-749b-11df-b112-00215aee3ebe;Малі Гадомці;Малые Гадомцы;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820882102;3;1
68cf24b5-749b-11df-b112-00215aee3ebe;Малі Гайки;Малые Гайки;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625882502;3;1
68cf24b6-749b-11df-b112-00215aee3ebe;Малі Геївці;Малые Геевцы;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124880602;3;1
68cf24b7-749b-11df-b112-00215aee3ebe;Малі Голоби;Малые Голобы;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721483102;3;1
68cf24b8-749b-11df-b112-00215aee3ebe;Малі Грибовичі;Малые Грибовичи;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622782305;3;1
68cf24b9-749b-11df-b112-00215aee3ebe;Малі Дедеркали;Малые Дедеркалы;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125881803;3;1
68cf24ba-749b-11df-b112-00215aee3ebe;Малі Дідушичі;Малые Дидушичи;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625380802;3;1
68cf24bb-749b-11df-b112-00215aee3ebe;Малі Дмитровичі;Малые Дмитровичи;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223180502;3;1
68cf24bc-749b-11df-b112-00215aee3ebe;Малі Долини;Малые Долины;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787613;3;1
68cf24bd-749b-11df-b112-00215aee3ebe;Малі Дорогостаї;Малые Дорогостаи;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623884901;3;1
68cf24be-749b-11df-b112-00215aee3ebe;Малі Єрківці;Малые Ерковцы;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220885702;3;1
68cf24bf-749b-11df-b112-00215aee3ebe;Малі Єрчики;Малые Ерчики;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224084001;3;1
68cf24c0-749b-11df-b112-00215aee3ebe;Малі Жеребки;Малые Жеребки;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824784502;3;1
68cf24c1-749b-11df-b112-00215aee3ebe;Малі Загайці;Малые Загайцы;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125882102;3;1
68cf24c2-749b-11df-b112-00215aee3ebe;Малі Загірці;Малые Загорцы;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621689105;3;1
8747c5f1-5e99-11e0-aeca-003048d2b472;Малі Заліщики;Малые Залещики;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121285603;3;1
a5388ebb-db65-11df-9197-00215aee3ebe;Малі Зозулинці;Малые Зозулинцы;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822780802;3;1
68cf24c4-749b-11df-b112-00215aee3ebe;Малі Каленичі;Малые Каленичи;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823682003;3;1
68cf24c5-749b-11df-b112-00215aee3ebe;Малі Калетинці;Малые Калетинцы;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820389003;3;1
68cf24c6-749b-11df-b112-00215aee3ebe;Малі Канівці;Малые Каневцы;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125181602;3;1
68cf24c7-749b-11df-b112-00215aee3ebe;Малі Козуби;Малые Козубы;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081304;3;1
68cf24c8-749b-11df-b112-00215aee3ebe;Малі Коровинці;Малые Коровинцы;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825888404;3;1
871625af-749b-11df-b112-00215aee3ebe;Малі Коростівці (Чапаєвка);Малые Коростовцы (Чапаевка);село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521083009;3;1
68cf24c9-749b-11df-b112-00215aee3ebe;Малі Кошарища;Малые Кошарища;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822583206;3;1
68cf24ca-749b-11df-b112-00215aee3ebe;Малі Кринки;Малые Крынки;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325784503;3;1
68cf24cb-749b-11df-b112-00215aee3ebe;Малі Крушлинці;Малые Крушлинцы;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520683503;3;1
68cf24cc-749b-11df-b112-00215aee3ebe;Малі Кусківці;Малые Кусковцы;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123810103;3;1
68cf24c3-749b-11df-b112-00215aee3ebe;Малі Кутища;Малые Кутыща;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521684009;3;1
68cf24cd-749b-11df-b112-00215aee3ebe;Малі Ланки;Малые Ланки;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623387204;3;1
68cf24ce-749b-11df-b112-00215aee3ebe;Малі Липняги;Малые Липняги;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324555109;3;1
68cf24cf-749b-11df-b112-00215aee3ebe;Малі Лисівці;Малые Лисовцы;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224084301;2;1
68cf24d0-749b-11df-b112-00215aee3ebe;Малі Мацевичі;Малые Мацевичи;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824281003;3;1
68cf24aa-749b-11df-b112-00215aee3ebe;Малі Мокряни;Малые Мокряны;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484401;3;1
68cf24d1-749b-11df-b112-00215aee3ebe;Малі Мошки;Малые Мошки;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824286504;3;1
68cf24d2-749b-11df-b112-00215aee3ebe;Малі Мошківці;Малые Мошковцы;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820386601;3;1
68cf24d3-749b-11df-b112-00215aee3ebe;Малі Нагірці;Малые Нагорцы;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622181602;3;1
68cf24d4-749b-11df-b112-00215aee3ebe;Малі Низгірці;Малые Низгорцы;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825284301;3;1
68cf24d5-749b-11df-b112-00215aee3ebe;Малі Новосілки;Малые Новоселки;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622480403;3;1
68cf24d6-749b-11df-b112-00215aee3ebe;Малі Орлинці;Малые Орлинцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825081106;3;1
68cf24d7-749b-11df-b112-00215aee3ebe;Малі Передримихи;Малые Передремихи;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787207;3;1
68cf24d8-749b-11df-b112-00215aee3ebe;Малі Переліски;Малые Перелески;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620382007;3;1
68cf24ab-749b-11df-b112-00215aee3ebe;Малі Підліски;Малые Подлески;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622710203;3;1
68cf24d9-749b-11df-b112-00215aee3ebe;Малі Прицьки;Малые Прицки;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222983603;3;1
68cf24da-749b-11df-b112-00215aee3ebe;Малі Проходи;Малые Проходы;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322081501;3;1
68cf24db-749b-11df-b112-00215aee3ebe;Малі Пузирки;Малые Пузырьки;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822786602;3;1
68cf24dc-749b-11df-b112-00215aee3ebe;Малі Сади;Малые Сады;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621683501;3;1
68cf24dd-749b-11df-b112-00215aee3ebe;Малі Садки;Малые Садки;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125881503;3;1
68cf24de-749b-11df-b112-00215aee3ebe;Малі Селменці;Малые Селменцы;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124883302;3;1
68cf24df-749b-11df-b112-00215aee3ebe;Малі Сорочинці;Малые Сорочинцы;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323288203;3;1
68cf24e0-749b-11df-b112-00215aee3ebe;Малі Телковичі;Малые Телковичи;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620880902;3;1
68cf24e1-749b-11df-b112-00215aee3ebe;Малі Чорнокінці;Малые Черноконцы;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125585601;3;1
68cf24e3-749b-11df-b112-00215aee3ebe;Малі Юначки;Малые Юначки;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822784806;3;1
68cf24e4-749b-11df-b112-00215aee3ebe;Малів;Малев;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125086602;3;1
68cf24e5-749b-11df-b112-00215aee3ebe;Малігонове;Малигоново;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125482201;3;1
68cf24e6-749b-11df-b112-00215aee3ebe;Малієве;Малиево;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222683304;3;1
68cf24ec-749b-11df-b112-00215aee3ebe;Малієвці;Малиевци;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821885001;3;1
68cf24e7-749b-11df-b112-00215aee3ebe;Маліївка;Малиевка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224283404;3;1
68cf24e8-749b-11df-b112-00215aee3ebe;Маліївка;Малиевка;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821182601;3;1
68cf24e9-749b-11df-b112-00215aee3ebe;Маліївка;Малиевка;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321083002;3;1
68cf24ea-749b-11df-b112-00215aee3ebe;Маліївка;Малиевка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121586602;3;1
68cf24eb-749b-11df-b112-00215aee3ebe;Маліївське;Малиевское;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222683305;3;1
68cf24ed-749b-11df-b112-00215aee3ebe;Малійки;Малейки;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425581302;3;1
68cf24ee-749b-11df-b112-00215aee3ebe;Малківка;Малковка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;7424185001;3;1
68cf24ef-749b-11df-b112-00215aee3ebe;Малнів;Малнов;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484601;3;1
68cf24f0-749b-11df-b112-00215aee3ebe;Малнівська Воля;Малновская Воля;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484501;3;1
68cf24f1-749b-11df-b112-00215aee3ebe;Малоандріївка;Малоандреевка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222380505;3;1
68cf24f2-749b-11df-b112-00215aee3ebe;Малоберезанське;Малоберезанское;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224483602;3;1
68cf24f3-749b-11df-b112-00215aee3ebe;Маловарварівка;Маловарваровка;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824282709;3;1
68cf24f5-749b-11df-b112-00215aee3ebe;Маловидне;Маловидное;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322281503;3;1
68cf24f7-749b-11df-b112-00215aee3ebe;Маловоди;Маловоды;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125085601;3;1
68cf24f9-749b-11df-b112-00215aee3ebe;Маловодяне;Маловодяное;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524981302;3;1
68cf24fa-749b-11df-b112-00215aee3ebe;Маловодяне;Маловодяное;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521984901;3;1
68cf24fb-749b-11df-b112-00215aee3ebe;Малоглумчанка;Малоглумчанка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821781803;3;1
68cf24fd-749b-11df-b112-00215aee3ebe;Малодворянка;Малодворянка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823082301;3;1
68cf24fe-749b-11df-b112-00215aee3ebe;Малодолинське;Малодолинское;село;903cca7c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5110890301;3;0
68cf24ff-749b-11df-b112-00215aee3ebe;Маложенівка;Маложеневка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823082401;3;1
68cf2500-749b-11df-b112-00215aee3ebe;Малозалісся;Малозалесье;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822480405;3;1
68cf2501-749b-11df-b112-00215aee3ebe;Малозахарине;Малозахарино;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055111;3;1
68cf2502-749b-11df-b112-00215aee3ebe;Малозименове;Малозименово;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121686004;3;1
68cf2504-749b-11df-b112-00215aee3ebe;Малокатеринівка;Малокатериновка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322155900;3;1
68cf2506-749b-11df-b112-00215aee3ebe;Малокозирщина;Малокозырщина;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223210502;3;1
68cf2507-749b-11df-b112-00215aee3ebe;Малоконеве;Малоконево;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522885809;3;1
68cf2509-749b-11df-b112-00215aee3ebe;Малолітки;Малолетки;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320481803;3;1
68cf250a-749b-11df-b112-00215aee3ebe;Маломиколаївка;Малониколаевка;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223882506;3;1
68cf250c-749b-11df-b112-00215aee3ebe;Маломихайлівка;Маломихайловка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055112;3;1
68cf250d-749b-11df-b112-00215aee3ebe;Маломихайлівка;Маломихайловка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224286701;3;1
68cf250e-749b-11df-b112-00215aee3ebe;Маломихайлівське;Маломихайловское;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821155103;3;1
68cf250f-749b-11df-b112-00215aee3ebe;Малоолександрівка;Малоалександровка;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223587504;3;1
68cf2510-749b-11df-b112-00215aee3ebe;Малоолександрівка;Малоалександровка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221086601;3;1
68cf2514-749b-11df-b112-00215aee3ebe;Малоплоске;Малоплоское;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121685803;3;1
68cf2515-749b-11df-b112-00215aee3ebe;Малополовецьке;Малополовецкое;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224984001;3;1
68cf2517-749b-11df-b112-00215aee3ebe;Малорошове;Малорошево;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125282507;3;1
68cf2519-749b-11df-b112-00215aee3ebe;Малоселецьке;Малоселецкое;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323685902;3;1
68cf251a-749b-11df-b112-00215aee3ebe;Малосербулівка;Малосербуловка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823080403;3;1
68cf251b-749b-11df-b112-00215aee3ebe;Малосілка;Малоселка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820883301;3;1
68cf251c-749b-11df-b112-00215aee3ebe;Малосолоне;Малосоленое;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822081203;3;1
68cf251d-749b-11df-b112-00215aee3ebe;Малософіївка;Малософиевка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222082007;3;1
68cf251f-749b-11df-b112-00215aee3ebe;Малоукраїнка;Малоукраинка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823082601;3;1
68cf2520-749b-11df-b112-00215aee3ebe;Малофедорівка;Малофедоровка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823682204;3;1
68cf2522-749b-11df-b112-00215aee3ebe;Малоцебрикове;Малоцебриково;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655503;3;1
68cf2525-749b-11df-b112-00215aee3ebe;Малоярославець Другий;Малоярославец Второй;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124785001;3;1
68cf2526-749b-11df-b112-00215aee3ebe;Малоярославець Перший;Малоярославец Первый;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124789402;3;1
68cf2527-749b-11df-b112-00215aee3ebe;Малушине;Малушино;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;;3;1
68cf2528-749b-11df-b112-00215aee3ebe;Малушка;Малушка;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620486203;3;1
68cf2529-749b-11df-b112-00215aee3ebe;Малютинці;Малютинцы;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323883801;3;1
68cf252a-749b-11df-b112-00215aee3ebe;Малютянка;Малютянка;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222484201;3;1
68cf252b-749b-11df-b112-00215aee3ebe;Малярівщина;Маляровщина;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187107;3;1
8716263f-749b-11df-b112-00215aee3ebe;Малярщина (Червона Українка);Малярщина (Червоная Украинка);село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055113;3;1
68cf252c-749b-11df-b112-00215aee3ebe;Малятин;Малятин;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621285401;3;1
68cf252d-749b-11df-b112-00215aee3ebe;Малятинці;Малятинцы;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322585501;3;1
68cf252e-749b-11df-b112-00215aee3ebe;Мальки;Мальки;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824289103;3;1
68cf252f-749b-11df-b112-00215aee3ebe;Мальоване;Малеваное;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623885001;3;1
68cf2530-749b-11df-b112-00215aee3ebe;Мальованка;Малеванка;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620983604;3;1
68cf2531-749b-11df-b112-00215aee3ebe;Мальованка;Малеванка;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825585603;3;1
68cf2532-749b-11df-b112-00215aee3ebe;Мальовниче;Малевничье;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525384202;3;1
68cf2533-749b-11df-b112-00215aee3ebe;Мальовниче;Малевничье;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522580603;3;1
68cf2534-749b-11df-b112-00215aee3ebe;Мальці;Мальцы;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323287502;3;1
8716259a-749b-11df-b112-00215aee3ebe;Мальці (Чапаєвка);Мальцы (Чапаевка);село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322085501;3;1
68cf2536-749b-11df-b112-00215aee3ebe;Мальцівське;Мальцевское;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323983502;3;1
68cf2537-749b-11df-b112-00215aee3ebe;Мальча;Мальча;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421485602;3;1
68cf2538-749b-11df-b112-00215aee3ebe;Мальчевське;Мальчевское;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525884402;3;1
68cf2539-749b-11df-b112-00215aee3ebe;Мальчиці;Мальчицы;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625885501;3;1
68cf253a-749b-11df-b112-00215aee3ebe;Мальчівці;Мальчевцы;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520283003;3;1
68cf253b-749b-11df-b112-00215aee3ebe;Мамаївці;Мамаевцы;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322586501;3;1
68cf253c-749b-11df-b112-00215aee3ebe;Мамалига;Мамалыга;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323084301;3;1
68cf253d-749b-11df-b112-00215aee3ebe;Мамекине;Мамекино;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423686601;3;1
68cf253e-749b-11df-b112-00215aee3ebe;Мамеч;Мамеч;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824281305;3;1
68cf253f-749b-11df-b112-00215aee3ebe;Маморниця;Маморница;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320786205;3;1
7ad94f3a-749b-11df-b112-00215aee3ebe;Маморниця Вама (Радгоспівка);Маморниця Вама (Радгосповка);село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320786207;3;1
68cf2540-749b-11df-b112-00215aee3ebe;Мамрин;Мамрин;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822587905;3;1
68cf2541-749b-11df-b112-00215aee3ebe;Мамчурі;Мамчури;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620382008;3;1
68cf2542-749b-11df-b112-00215aee3ebe;Манаїв;Манаев;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122682202;3;1
68cf2543-749b-11df-b112-00215aee3ebe;Маначин;Маначин;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820984201;3;1
68cf2544-749b-11df-b112-00215aee3ebe;Маначинівка;Маначинивка;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755135;3;1
68cf2545-749b-11df-b112-00215aee3ebe;Манвелівка;Манвеловка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755107;3;1
68cf2547-749b-11df-b112-00215aee3ebe;Маневе;Маневое;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123885207;3;1
68cf2548-749b-11df-b112-00215aee3ebe;Маневичі;Маневичи;місто;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723655100;3;0
68cf2549-749b-11df-b112-00215aee3ebe;Манжелія;Манжелия;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320685401;3;1
68cf254a-749b-11df-b112-00215aee3ebe;Манжосівка;Манжосовка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424182203;3;1
68cf254b-749b-11df-b112-00215aee3ebe;Манжурка;Манжурка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521483202;3;1
68cf254c-749b-11df-b112-00215aee3ebe;Маниківці;Маникивци;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821587001;3;1
68cf254d-749b-11df-b112-00215aee3ebe;Манили;Манилы;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321284509;3;1
68cf254e-749b-11df-b112-00215aee3ebe;Манилівка;Маниловка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321387805;3;1
68cf254f-749b-11df-b112-00215aee3ebe;Манилівка;Маниловка;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825084802;3;1
68cf2550-749b-11df-b112-00215aee3ebe;Манилівське;Маниловское;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320684905;3;1
68cf2552-749b-11df-b112-00215aee3ebe;Мані;Мани;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821485803;3;1
68cf2553-749b-11df-b112-00215aee3ebe;Манівці;Маневцы;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822787101;3;1
68cf2554-749b-11df-b112-00215aee3ebe;Манне;Манное;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083812;3;1
68cf2555-749b-11df-b112-00215aee3ebe;Мантин;Мантин;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623884904;3;1
68cf2559-749b-11df-b112-00215aee3ebe;Мануйлівка;Мануйловка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523183201;3;1
68cf255a-749b-11df-b112-00215aee3ebe;Мануйлове;Мануйлово;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324280504;3;1
68cf255b-749b-11df-b112-00215aee3ebe;Манухівка;Мануховка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923885001;3;1
68cf255d-749b-11df-b112-00215aee3ebe;Манченки;Манченки;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157600;3;1
68cf255e-749b-11df-b112-00215aee3ebe;Манчичі;Манчичи;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920386903;3;1
68cf255f-749b-11df-b112-00215aee3ebe;Манява;Манява;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620484801;3;1
68cf2560-749b-11df-b112-00215aee3ebe;Манятин;Манятин;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823985101;3;1
68cf2561-749b-11df-b112-00215aee3ebe;Маньки;Маньки;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983005;3;1
68cf2562-749b-11df-b112-00215aee3ebe;Маньки;Маньки;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424484209;3;1
68cf2563-749b-11df-b112-00215aee3ebe;Маньків;Маньков;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722485803;3;1
68cf2564-749b-11df-b112-00215aee3ebe;Маньківка;Маньковка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323483405;3;1
68cf2567-749b-11df-b112-00215aee3ebe;Маньківка;Маньковка;місто;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123155100;3;1
68cf2568-749b-11df-b112-00215aee3ebe;Маньківка;Маньковка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524384802;3;1
68cf2569-749b-11df-b112-00215aee3ebe;Маньківка;Маньковка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520483003;3;1
68cf256a-749b-11df-b112-00215aee3ebe;Маньківське;Маньковское;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525580402;3;1
68cf256b-749b-11df-b112-00215aee3ebe;Маньківці;Маньковцы;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520283203;3;1
68cf2575-749b-11df-b112-00215aee3ebe;Мар'є - Дмитрівка;Марье-Дмитровка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282516;3;1
68cf2576-749b-11df-b112-00215aee3ebe;Мар'є - Костянтинівка;Марье-Константиновка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225286605;3;1
68cf256c-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225286606;3;1
68cf258b-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083711;3;1
68cf258c-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786906;3;1
68cf258d-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222384501;3;1
68cf258e-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223581307;3;1
68cf258f-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224886805;3;1
68cf2590-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655119;3;1
68cf2591-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222982204;3;1
68cf2592-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;5394b330-41be-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1211345400;3;1
68cf2593-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820683401;3;1
68cf2594-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822784010;3;1
68cf2595-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324583002;3;1
68cf2596-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324886003;3;1
68cf2597-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323983004;3;1
68cf2598-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320483603;3;1
68cf2599-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320686304;3;1
68cf259a-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125480703;3;1
68cf259b-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322185701;3;1
68cf25a1-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523884601;3;1
68cf25a2-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521755102;3;1
68cf25a3-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520385205;3;1
68cf25a4-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524684201;3;1
68cf25a5-749b-11df-b112-00215aee3ebe;Мар'ївка;Марьевка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522883001;3;1
68cf25a6-749b-11df-b112-00215aee3ebe;Мар'ївка Друга;Марьевка Вторая;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;*122528660;3;1
68cf25a7-749b-11df-b112-00215aee3ebe;Мар'ївське;Марьевское;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224880509;3;1
68cf25a8-749b-11df-b112-00215aee3ebe;Мар'їна Роща;Марьина Роща;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223882507;3;1
68cf25a9-749b-11df-b112-00215aee3ebe;Мар'їне;Марьино;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322282507;3;1
68cf25aa-749b-11df-b112-00215aee3ebe;Мар'їне;Марьино;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610114;3;1
68cf25ab-749b-11df-b112-00215aee3ebe;Мар'їне;Марьино;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922387002;3;1
68cf25ac-749b-11df-b112-00215aee3ebe;Мар'їне;Марьино;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320888311;3;1
68cf25ad-749b-11df-b112-00215aee3ebe;Мар'їне;Марьино;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521880803;3;1
7ad94e40-749b-11df-b112-00215aee3ebe;Мар'їне (Пролетарське);Марьино (Пролетарское);село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924785906;3;1
68cf25af-749b-11df-b112-00215aee3ebe;Мар'їнка;Марьинка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224884005;3;1
68cf25b2-749b-11df-b112-00215aee3ebe;Мар'їнське;Марьинское;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323880707;3;1
68cf2610-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322081203;3;1
68cf2611-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323485503;3;1
68cf2612-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321685605;3;1
68cf2613-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922086904;3;1
68cf2614-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920683006;3;1
68cf2615-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923285605;3;1
68cf2616-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723655406;3;1
68cf2617-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155315;3;1
68cf2618-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724585002;3;1
68cf2619-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720855400;3;1
68cf261a-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125284901;3;1
68cf261b-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221884004;3;1
68cf261c-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221084106;3;1
68cf261d-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224583701;3;1
68cf261e-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;4ffc4b7d-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221884004;3;1
68cf261f-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820382609;3;1
68cf2620-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655114;3;1
68cf2621-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820680703;3;1
68cf2622-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822381303;3;1
68cf2623-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125283001;3;1
68cf2624-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125482501;3;1
68cf2625-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123585003;3;1
68cf2626-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121281203;3;1
68cf2627-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122782403;3;1
68cf2628-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123781801;3;1
68cf2629-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323686608;3;1
68cf2630-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223586401;3;1
68cf2631-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;;3;1
68cf2632-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222784501;3;1
68cf2633-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223380402;3;1
68cf2634-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221485401;3;1
68cf2635-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821785003;3;1
68cf2636-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821184304;3;1
68cf2637-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825085202;3;1
68cf2638-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820983507;3;1
68cf2639-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820655900;2;1
68cf263a-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825283203;3;1
68cf263b-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122884401;3;1
68cf263c-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125184002;3;1
68cf263d-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125784801;3;1
68cf263e-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524885001;3;1
68cf263f-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523087003;3;1
68cf2640-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520280805;3;1
68cf2641-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520883612;3;1
68cf2642-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521981802;3;1
68cf2643-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523682701;3;1
68cf2644-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523183601;3;1
68cf2645-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524080605;3;1
68cf2646-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520884401;3;1
68cf2647-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524983702;3;1
68cf2648-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525882302;3;1
68cf2649-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824784003;3;1
68cf264a-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825055502;3;1
68cf264b-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825282004;2;1
68cf264c-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625484403;3;1
68cf264d-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623486003;3;1
68cf264e-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622682802;3;1
68cf264f-749b-11df-b112-00215aee3ebe;Мар'янівка;Марьяновка;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320882801;3;1
19799e87-06ec-11ea-80e7-1c98ec135261;Мар'янівка;Марьяновка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223283301;3;1
87162598-749b-11df-b112-00215aee3ebe;Мар'янівка (Чапаєвка);Марьяновка (Чапаевка);село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320284305;3;1
68cf2650-749b-11df-b112-00215aee3ebe;Мар'янівка Перша;Марьяновка Первая;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;;3;1
68cf2651-749b-11df-b112-00215aee3ebe;Мар'янівське;Марьяновское;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521981803;3;1
68cf2652-749b-11df-b112-00215aee3ebe;Мар'янка;Марьянка;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1223886005;3;1
68cf2653-749b-11df-b112-00215aee3ebe;Мар'янопіль;Марьянополь;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523886504;3;1
68cf2654-749b-11df-b112-00215aee3ebe;Мар'янопіль;Марьянополь;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524010108;3;1
68cf2655-749b-11df-b112-00215aee3ebe;Мар'янське;Марьянское;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320282604;3;1
68cf2656-749b-11df-b112-00215aee3ebe;Мар'янське;Марьянское;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220310303;3;1
68cf2657-749b-11df-b112-00215aee3ebe;Мар'ятин;Марьятин;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823482602;3;1
68cf256d-749b-11df-b112-00215aee3ebe;Маразліївка;Маразлиевка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120884201;3;1
68cf256e-749b-11df-b112-00215aee3ebe;Маразліївка;Маразлиевка;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125082402;2;1
74dc496d-749b-11df-b112-00215aee3ebe;Маракучка (Петрівське);Маракучка (Петровское);село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921255402;3;1
68cf256f-749b-11df-b112-00215aee3ebe;Марачівка;Марачевка;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823985201;3;1
68cf2570-749b-11df-b112-00215aee3ebe;Маращанка;Маращанка;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620685806;3;1
68cf2571-749b-11df-b112-00215aee3ebe;Марганець;Марганец;місто;5394b330-41be-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1211300000;2;0
68cf2572-749b-11df-b112-00215aee3ebe;Мардарівка;Мардаровка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122984840;3;1
68cf2573-749b-11df-b112-00215aee3ebe;Мардарівка;Мардаровка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655504;3;1
68cf2574-749b-11df-b112-00215aee3ebe;Мареничі;Мареничи;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323287704;3;1
68cf2577-749b-11df-b112-00215aee3ebe;Маринин;Маринин;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620486601;3;1
68cf2578-749b-11df-b112-00215aee3ebe;Мариничі;Мариничи;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323582501;3;1
68cf2579-749b-11df-b112-00215aee3ebe;Маринівка;Мариновка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822783201;3;1
68cf257a-749b-11df-b112-00215aee3ebe;Маринівка;Мариновка;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121083801;3;1
68cf257f-749b-11df-b112-00215aee3ebe;Маринівка;Мариновка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821785002;3;1
68cf2580-749b-11df-b112-00215aee3ebe;Маринка;Маринка;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621587803;3;1
68cf2581-749b-11df-b112-00215aee3ebe;Маринове;Мариново;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121282901;3;1
68cf2582-749b-11df-b112-00215aee3ebe;Маринопіль;Маринополь;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222985005;3;1
68cf2585-749b-11df-b112-00215aee3ebe;Маринопіль;Маринополь;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521486503;3;1
68cf2587-749b-11df-b112-00215aee3ebe;Марійка;Марийка;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120984201;3;1
68cf2589-749b-11df-b112-00215aee3ebe;Маріуполь;Мариуполь;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520886403;3;1
68cf258a-749b-11df-b112-00215aee3ebe;Марія-Воля;Мария-Воля;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720582011;3;1
68cf2584-749b-11df-b112-00215aee3ebe;Маріямпіль;Маринополь;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621285301;3;1
68cf25b3-749b-11df-b112-00215aee3ebe;Маркевичеве;Маркевичево;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125481404;3;1
68cf25b5-749b-11df-b112-00215aee3ebe;Маркелівка;Маркеловка;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720584404;3;1
68cf25b6-749b-11df-b112-00215aee3ebe;Маркизівка;Маркизовка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121584302;3;1
68cf25b8-749b-11df-b112-00215aee3ebe;Марківка;Марковка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083911;3;1
68cf25b9-749b-11df-b112-00215aee3ebe;Марківка;Марковка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321884601;3;1
68cf25ba-749b-11df-b112-00215aee3ebe;Марківка;Марковка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920685701;3;1
68cf25bb-749b-11df-b112-00215aee3ebe;Марківка;Марковка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820955103;3;1
68cf25bc-749b-11df-b112-00215aee3ebe;Марківка;Марковка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123983901;3;1
68cf25bd-749b-11df-b112-00215aee3ebe;Марківка;Марковка;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623255704;3;1
68cf25bf-749b-11df-b112-00215aee3ebe;Марківка;Марковка;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820683601;3;1
68cf25c0-749b-11df-b112-00215aee3ebe;Марківка;Марковка;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524584001;3;1
68cf25c1-749b-11df-b112-00215aee3ebe;Марківка;Марковка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523787009;3;1
68cf25c2-749b-11df-b112-00215aee3ebe;Марківка;Марковка;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523983001;3;1
68cf25c3-749b-11df-b112-00215aee3ebe;Марківка;Марковка;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821582002;3;1
68cf25c4-749b-11df-b112-00215aee3ebe;Марківське;Марковское;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924182003;3;1
68cf25c6-749b-11df-b112-00215aee3ebe;Марківці;Марковцы;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625882901;3;1
68cf25c7-749b-11df-b112-00215aee3ebe;Марківці;Марковцы;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420684401;3;1
68cf25c8-749b-11df-b112-00215aee3ebe;Марківці;Марковцы;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521483206;3;1
68cf25c9-749b-11df-b112-00215aee3ebe;Марківці;Марковцы;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825588004;3;1
68cf25ca-749b-11df-b112-00215aee3ebe;Марківці;Марковцы;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822784403;3;1
68cf25cb-749b-11df-b112-00215aee3ebe;Марківці;Марковцы;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823081602;3;1
68cf25cc-749b-11df-b112-00215aee3ebe;Маркова;Маркова;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124284803;3;1
68cf25cd-749b-11df-b112-00215aee3ebe;Маркова;Маркова;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620485201;3;1
68cf25ce-749b-11df-b112-00215aee3ebe;Маркова Волиця;Маркова Волица;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824786202;3;1
68cf25d1-749b-11df-b112-00215aee3ebe;Маркове;Марково;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524355302;3;1
68cf25d2-749b-11df-b112-00215aee3ebe;Маркове;Марково;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521783501;3;1
68cf25d3-749b-11df-b112-00215aee3ebe;Марковичі;Марковичи;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722484201;3;1
68cf25d4-749b-11df-b112-00215aee3ebe;Марковичі;Марковичи;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720887403;3;1
68cf25d5-749b-11df-b112-00215aee3ebe;Маркопіль;Маркополь;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620380801;3;1
68cf25d6-749b-11df-b112-00215aee3ebe;Маркостав;Маркостав;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720582012;3;1
68cf25d8-749b-11df-b112-00215aee3ebe;Маркуші;Маркуши;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820883601;3;1
68cf25d9-749b-11df-b112-00215aee3ebe;Маркуші;Маркуши;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524884801;3;1
68cf25da-749b-11df-b112-00215aee3ebe;Мармизівка;Мармызовка;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421183001;3;1
44ac7f62-749b-11df-b112-00215aee3ebe;Мармузовичі;Мармузовичі;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620680401;3;1
68cf25db-749b-11df-b112-00215aee3ebe;Мармуліївка;Мармулиевка;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221684401;2;1
68cf25dd-749b-11df-b112-00215aee3ebe;Марс;Марс;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424782005;3;1
68cf25de-749b-11df-b112-00215aee3ebe;Мартини;Мартыны;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484503;3;1
68cf25df-749b-11df-b112-00215aee3ebe;Мартинівка;Мартыновка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321880906;3;1
68cf25e0-749b-11df-b112-00215aee3ebe;Мартинівка;Мартыновка;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925085001;3;1
68cf25e1-749b-11df-b112-00215aee3ebe;Мартинівка;Мартыновка;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881705;3;1
68cf25e2-749b-11df-b112-00215aee3ebe;Мартинівка;Мартыновка;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
68cf25e3-749b-11df-b112-00215aee3ebe;Мартинівка;Мартыновка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322684505;3;1
68cf25e4-749b-11df-b112-00215aee3ebe;Мартинівка;Мартыновка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323382501;3;1
68cf25e5-749b-11df-b112-00215aee3ebe;Мартинівка;Мартыновка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123584503;3;1
68cf25e7-749b-11df-b112-00215aee3ebe;Мартинівка;Мартыновка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825483001;3;1
68cf25e8-749b-11df-b112-00215aee3ebe;Мартинівка;Мартыновка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820885204;3;1
68cf25e9-749b-11df-b112-00215aee3ebe;Мартинівка;Мартыновка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421786401;3;1
68cf25ea-749b-11df-b112-00215aee3ebe;Мартинівка;Мартыновка;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122084701;3;1
68cf25eb-749b-11df-b112-00215aee3ebe;Мартинівка;Мартыновка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520282409;3;1
68cf25ec-749b-11df-b112-00215aee3ebe;Мартинівка;Мартыновка;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521080309;3;1
68cf25ed-749b-11df-b112-00215aee3ebe;Мартинівка;Мартыновка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523682601;3;1
68cf25ee-749b-11df-b112-00215aee3ebe;Мартинівка;Мартыновка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824282003;3;1
68cf25ef-749b-11df-b112-00215aee3ebe;Мартинівка;Мартыновка;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825086003;3;1
68cf25f0-749b-11df-b112-00215aee3ebe;Мартинівка;Мартыновка;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624683704;3;1
68cf25f1-749b-11df-b112-00215aee3ebe;Мартинівка;Мартыновка;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621689507;3;1
68cf25f2-749b-11df-b112-00215aee3ebe;Мартинівка;Мартыновка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320484701;3;1
68cf25f3-749b-11df-b112-00215aee3ebe;Мартинівка;Мартыновка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324883209;3;1
68cf25f4-749b-11df-b112-00215aee3ebe;Мартинівське;Мартыновское;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083809;3;1
68cf25f5-749b-11df-b112-00215aee3ebe;Мартинківці;Мартинковцы;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821284203;3;1
68cf25f6-749b-11df-b112-00215aee3ebe;Мартинці;Мартынцы;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922987109;3;1
68cf25f7-749b-11df-b112-00215aee3ebe;Мартишківці;Мартышковцы;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123884405;3;1
68cf25f9-749b-11df-b112-00215aee3ebe;Мартове;Мартово;село;64024b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324683501;3;1
44ac8478-749b-11df-b112-00215aee3ebe;Мартове (Благоєве);Мартове (Благоево);село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121683703;3;1
68cf25fa-749b-11df-b112-00215aee3ebe;Марто-іванівка;Марто-Ивановка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3510390407;3;1
68cf25fb-749b-11df-b112-00215aee3ebe;Мартоноша;Мартоноша;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523884401;3;1
68cf25fc-749b-11df-b112-00215aee3ebe;Мартусівка;Мартусовка;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220885001;2;0
68cf25fd-749b-11df-b112-00215aee3ebe;Марусенкове;Марусенково;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922987111;3;1
68cf25fe-749b-11df-b112-00215aee3ebe;Марусине;Марусино;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524382906;3;1
68cf25ff-749b-11df-b112-00215aee3ebe;Маруся;Маруся;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720884605;3;1
68cf2600-749b-11df-b112-00215aee3ebe;Марухи;Марухи;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922681704;3;1
68cf2601-749b-11df-b112-00215aee3ebe;Марушівка;Марушовка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824080403;3;1
68cf2603-749b-11df-b112-00215aee3ebe;Марфівка;Марфовка;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521980707;3;1
68cf2605-749b-11df-b112-00215aee3ebe;Мархалівка;Мархаловка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221485501;3;0
68cf2607-749b-11df-b112-00215aee3ebe;Марціянове;Марцияново;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121680304;3;1
68cf2608-749b-11df-b112-00215aee3ebe;Марціянове;Марцияново;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121882603;3;1
68cf260a-749b-11df-b112-00215aee3ebe;Марченки;Марченки;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321081304;3;1
68cf260b-749b-11df-b112-00215aee3ebe;Марченки;Марченки;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922383802;3;1
68cf260c-749b-11df-b112-00215aee3ebe;Марченки;Марченки;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323280203;3;1
68cf260d-749b-11df-b112-00215aee3ebe;Марчихина Буда;Марчихина Буда;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925681901;3;1
68cf260e-749b-11df-b112-00215aee3ebe;Маршали;Маршалы;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923584001;3;1
68cf260f-749b-11df-b112-00215aee3ebe;Маршинці;Маршинцы;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323084701;3;1
68cf2659-749b-11df-b112-00215aee3ebe;Масалаївка;Масалаивка;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424982503;3;1
68cf265b-749b-11df-b112-00215aee3ebe;Масевичі;Масевичи;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625085001;3;1
68cf265c-749b-11df-b112-00215aee3ebe;Масензівка;Масензовка;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921583704;3;1
68cf265d-749b-11df-b112-00215aee3ebe;Масиків;Масиков;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925355302;3;1
68cf265e-749b-11df-b112-00215aee3ebe;Масівці;Масевцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825084501;3;1
68cf265f-749b-11df-b112-00215aee3ebe;Масківці;Масковцы;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220284401;3;1
68cf2661-749b-11df-b112-00215aee3ebe;Маслівка;Масловка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324582508;3;1
68cf2664-749b-11df-b112-00215aee3ebe;Маслівка;Масловка;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222984301;3;1
68cf2665-749b-11df-b112-00215aee3ebe;Маслівці;Масловцы;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755136;3;1
68cf2666-749b-11df-b112-00215aee3ebe;Маслії;Маслии;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322010106;3;1
68cf2667-749b-11df-b112-00215aee3ebe;Маслове;Маслово;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121880704;3;1
68cf2669-749b-11df-b112-00215aee3ebe;Маслове;Маслово;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125785001;3;1
68cf266a-749b-11df-b112-00215aee3ebe;Масловець;Масловец;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723355301;3;1
68cf266b-749b-11df-b112-00215aee3ebe;Маслопуща;Маслопуща;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625487202;3;1
c8674aa7-dbb0-11df-9197-00215aee3ebe;Маслянка;Маслянка;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623884905;3;1
68cf266d-749b-11df-b112-00215aee3ebe;Матвіївка;Матвеевка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324584603;3;1
68cf266e-749b-11df-b112-00215aee3ebe;Матвіївка;Матвеевка;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322283007;3;1
68cf266f-749b-11df-b112-00215aee3ebe;Матвіївка;Матвеевка;село;5394b334-41be-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;2;1
68cf2670-749b-11df-b112-00215aee3ebe;Матвіївка;Матвеевка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320882004;3;1
68cf2671-749b-11df-b112-00215aee3ebe;Матвіївка;Матвеевка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321583601;3;0
68cf2675-749b-11df-b112-00215aee3ebe;Матвіївка;Матвеевка;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222980902;3;1
68cf2676-749b-11df-b112-00215aee3ebe;Матвіївка;Матвеевка;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424985501;3;1
68cf2677-749b-11df-b112-00215aee3ebe;Матвіївка;Матвеевка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121583502;3;1
68cf2678-749b-11df-b112-00215aee3ebe;Матвіївка;Матвеевка;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125483001;3;1
68cf2679-749b-11df-b112-00215aee3ebe;Матвіївка;Матвеевка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521485602;3;1
68cf267a-749b-11df-b112-00215aee3ebe;Матвіївці;Матвеевцы;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125885401;3;1
68cf267b-749b-11df-b112-00215aee3ebe;Матвіїха;Матвеиха;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221684801;2;1
68cf267c-749b-11df-b112-00215aee3ebe;Матвіїха;Матвеиха;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123484002;3;1
68cf267d-749b-11df-b112-00215aee3ebe;Матвійківці;Матвейковцы;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821285804;3;1
68cf267e-749b-11df-b112-00215aee3ebe;Матеївка;Метеевка;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;;3;1
68cf267f-749b-11df-b112-00215aee3ebe;Матеївці;Матеевцы;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623284201;3;1
68cf2680-749b-11df-b112-00215aee3ebe;Матейки;Матейки;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723683502;3;1
68cf2681-749b-11df-b112-00215aee3ebe;Матейків;Матейков;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520283403;3;1
68cf2682-749b-11df-b112-00215aee3ebe;Матейкове;Матейково;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521085209;3;1
68cf2683-749b-11df-b112-00215aee3ebe;Матеушівка;Матеушовка;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121281602;3;1
56bdd247-749b-11df-b112-00215aee3ebe;Матильдівка (Жовтневе);Матильдивка (Жовтневое);село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124786902;3;1
68cf2684-749b-11df-b112-00215aee3ebe;Матишівка;Матышевка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123982604;3;1
68cf2685-749b-11df-b112-00215aee3ebe;Матів;Матов;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624886904;3;1
68cf2687-749b-11df-b112-00215aee3ebe;Матіївка;Матиевка;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420386001;3;1
68cf2688-749b-11df-b112-00215aee3ebe;Матіївка;Матиевка;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621285402;3;1
68cf2686-749b-11df-b112-00215aee3ebe;Матійово;Матиево;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121282501;3;1
68cf2689-749b-11df-b112-00215aee3ebe;Матіясове;Матиясово;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820955114;3;1
68cf268a-749b-11df-b112-00215aee3ebe;Матків;Матков;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625585703;3;1
68cf268b-749b-11df-b112-00215aee3ebe;Матлахове;Матлахово;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924183205;3;1
68cf2af0-749b-11df-b112-00215aee3ebe;Матроно-Василівка;Матроно-Васильевка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823680304;3;1
68cf268c-749b-11df-b112-00215aee3ebe;Матросівка;Матросовка;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825182206;3;1
68cf268d-749b-11df-b112-00215aee3ebe;Матросівка;Матросовка;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520680603;3;1
68cf268f-749b-11df-b112-00215aee3ebe;Матросове;Матросово;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225083510;3;1
68cf2690-749b-11df-b112-00215aee3ebe;Матроська;Матросская;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122083601;3;1
68cf2691-749b-11df-b112-00215aee3ebe;Матусів;Матусов;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125785201;3;1
68cf2692-749b-11df-b112-00215aee3ebe;Матусівка;Матусовка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523183606;3;1
68cf2693-749b-11df-b112-00215aee3ebe;Матюченкове;Матюченково;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221081804;3;1
68cf2694-749b-11df-b112-00215aee3ebe;Матюші;Матюши;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220483501;2;1
68cf2696-749b-11df-b112-00215aee3ebe;Матяші;Матяши;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321386906;3;1
68cf2697-749b-11df-b112-00215aee3ebe;Матяші;Матяши;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622185915;3;1
68cf2698-749b-11df-b112-00215aee3ebe;Матяшівка;Матяшевка;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320282901;3;1
68cf2699-749b-11df-b112-00215aee3ebe;Матяшівка;Матяшевка;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223182003;3;1
68cf269a-749b-11df-b112-00215aee3ebe;Матяшівка;Матяшевка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522487004;3;1
68cf269b-749b-11df-b112-00215aee3ebe;Матяшівка;Матяшевка;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322885305;3;1
68cf269c-749b-11df-b112-00215aee3ebe;Махаринці;Махаринцы;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521484803;3;1
68cf269d-749b-11df-b112-00215aee3ebe;Махаринці;Махаринцы;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824284703;3;1
68cf269e-749b-11df-b112-00215aee3ebe;Махлинець;Махлинец;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621585606;3;1
68cf269f-749b-11df-b112-00215aee3ebe;Махнівка;Махновка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320687505;3;1
68cf26a0-749b-11df-b112-00215aee3ebe;Махнівка;Махновка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322485605;3;1
5cb619d6-749b-11df-b112-00215aee3ebe;Махнівка (кол. Комсомольське);Махнивка (быв. Комсомольское);село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521483203;3;1
33ec0015-ddd5-11df-9197-00215aee3ebe;Махнівка (Красне);Махновка (Красное);село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520685503;3;1
74dc4957-749b-11df-b112-00215aee3ebe;Махнівка (Петрівка);Махнивка (Петровка);село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420885201;3;1
68cf26a1-749b-11df-b112-00215aee3ebe;Махнівці;Махновцы;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621885503;3;1
68cf26a2-749b-11df-b112-00215aee3ebe;Маховик;Маховик;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323385704;3;1
68cf26a3-749b-11df-b112-00215aee3ebe;Маховики;Маховики;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422482002;3;1
68cf26a4-749b-11df-b112-00215aee3ebe;Маціївка;Мациевка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424183204;3;1
68cf26a5-749b-11df-b112-00215aee3ebe;Маціорськ;Мациорск;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823386502;3;1
68cf26a6-749b-11df-b112-00215aee3ebe;Мацківці;Мацковцы;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322884001;3;1
68cf26a7-749b-11df-b112-00215aee3ebe;Мацкова Лучка;Мацкова Лучка;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322884002;3;1
68cf26a8-749b-11df-b112-00215aee3ebe;Мацкове;Мацково;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921580403;3;1
68cf26a9-749b-11df-b112-00215aee3ebe;Мацкули;Мацкулы;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121683005;3;1
68cf26aa-749b-11df-b112-00215aee3ebe;Мацошин;Мацошын;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785840;3;0
68cf26ab-749b-11df-b112-00215aee3ebe;Мацьки;Мацки;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824284304;3;1
68cf26ac-749b-11df-b112-00215aee3ebe;Мацьківці;Мацьковцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825089602;3;0
68cf26ad-749b-11df-b112-00215aee3ebe;Мачулища;Мачулища;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923885401;3;1
68cf26ae-749b-11df-b112-00215aee3ebe;Мачухи;Мачехи;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083201;3;0
68cf26af-749b-11df-b112-00215aee3ebe;Машарі;Машары;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920681405;3;1
68cf26b0-749b-11df-b112-00215aee3ebe;Машеве;Машево;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424783501;3;1
68cf26b1-749b-11df-b112-00215aee3ebe;Машенька;Машенька;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125482205;3;1
68cf26b2-749b-11df-b112-00215aee3ebe;Машина;Машина;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822588407;3;1
68cf26b5-749b-11df-b112-00215aee3ebe;Машів;Машев;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723382801;3;1
68cf26b6-749b-11df-b112-00215aee3ebe;Машівка;Машевка;місто;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323055100;3;1
68cf26b7-749b-11df-b112-00215aee3ebe;Машкове;Машково;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925083903;3;1
68cf26b8-749b-11df-b112-00215aee3ebe;Маща;Маща;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623485001;3;1
68cf26b9-749b-11df-b112-00215aee3ebe;Мащанка;Мащанка;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925085401;3;1
68cf26ba-749b-11df-b112-00215aee3ebe;Маюничі;Маюничи;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620880602;3;1
68cf26bb-749b-11df-b112-00215aee3ebe;Маюрки;Маюрки;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123283002;3;1
68cf26bc-749b-11df-b112-00215aee3ebe;Маяк;Маяк;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221884303;3;1
68cf26bd-749b-11df-b112-00215aee3ebe;Маяк;Маяк;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225482903;3;1
68cf26be-749b-11df-b112-00215aee3ebe;Маяк;Маяк;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224287808;3;1
68cf26bf-749b-11df-b112-00215aee3ebe;Маяк;Маяк;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320482509;3;1
b36f1a39-db87-11df-9197-00215aee3ebe;Маяк;Маяк;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322686506;3;1
0c89cf93-93c5-11e8-80d4-1c98ec135261;Маяк;Маяк;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225086008;3;1
68cf26c6-749b-11df-b112-00215aee3ebe;Маяки;Маяки;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121083901;3;1
68cf26c7-749b-11df-b112-00215aee3ebe;Маяки;Маяки;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123182501;3;1
68cf26c9-749b-11df-b112-00215aee3ebe;Маяки;Маяки;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524381002;3;1
68cf26ca-749b-11df-b112-00215aee3ebe;Маяки;Маяки;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621687007;3;1
68cf26cb-749b-11df-b112-00215aee3ebe;Маяківка;Маяковка;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323655101;3;1
68cf26cc-749b-11df-b112-00215aee3ebe;Маянів;Маянов;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524581805;3;1
68cf26ce-749b-11df-b112-00215aee3ebe;Маячка;Маячка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323483701;3;1
68cf26d1-749b-11df-b112-00215aee3ebe;Мгар;Мгарь;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322884601;3;1
68cf26d2-749b-11df-b112-00215aee3ebe;Медведеве;Медведево;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922681204;3;1
68cf26de-749b-11df-b112-00215aee3ebe;Медведеве;Медведево;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821783701;3;1
68cf26d4-749b-11df-b112-00215aee3ebe;Медведиха;Медведиха;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825855601;3;1
68cf26d5-749b-11df-b112-00215aee3ebe;Медведівка;Медведовка;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323182701;3;1
68cf26d7-749b-11df-b112-00215aee3ebe;Медведівка;Медведовка;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424786505;3;1
68cf26d8-749b-11df-b112-00215aee3ebe;Медведівка;Медведовка;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125483501;3;1
68cf26d9-749b-11df-b112-00215aee3ebe;Медведівка;Медведовка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521483209;3;1
68cf26da-749b-11df-b112-00215aee3ebe;Медведівка;Медведовка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522487403;3;1
68cf26db-749b-11df-b112-00215aee3ebe;Медведівка;Медведовка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820980905;3;1
68cf26dc-749b-11df-b112-00215aee3ebe;Медведівці;Медведевцы;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121283401;3;1
68cf26dd-749b-11df-b112-00215aee3ebe;Медведівці;Медведевцы;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122783403;2;1
68cf26df-749b-11df-b112-00215aee3ebe;Медвежа;Медвежья;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621285001;3;1
68cf26e0-749b-11df-b112-00215aee3ebe;Медвежа;Медвежья;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523010108;3;1
68cf26e1-749b-11df-b112-00215aee3ebe;Медвежа Балка;Медвежья Балка;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525884904;3;1
68cf26e3-749b-11df-b112-00215aee3ebe;Медвеже Вушко;Медвежье Ушко;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520683803;3;1
68cf26e4-749b-11df-b112-00215aee3ebe;Медвежий;Медвежий;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125385602;3;1
68cf26e5-749b-11df-b112-00215aee3ebe;Медвин;Медвин;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222081403;3;1
68cf26e6-749b-11df-b112-00215aee3ebe;Медвин;Медвин;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220683201;3;1
68cf26e7-749b-11df-b112-00215aee3ebe;Медвідка;Медведка;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520682606;3;1
68cf26e8-749b-11df-b112-00215aee3ebe;Меделівка;Меделевка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825086602;3;1
68cf26e9-749b-11df-b112-00215aee3ebe;Меденичі;Меденичи;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621255400;3;1
68cf26ea-749b-11df-b112-00215aee3ebe;Медерове;Медерово;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522585402;3;1
68cf26eb-749b-11df-b112-00215aee3ebe;Меджибіж;Меджибож;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823055400;3;1
68cf26ec-749b-11df-b112-00215aee3ebe;Медин;Медин;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124680702;3;1
68cf26ed-749b-11df-b112-00215aee3ebe;Мединівка;Мединовка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822383201;3;1
68cf26ef-749b-11df-b112-00215aee3ebe;Мединя;Медыня;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621285501;3;1
68cf26f0-749b-11df-b112-00215aee3ebe;Медисівка;Медисовка;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824781504;3;1
68cf26f1-749b-11df-b112-00215aee3ebe;Медичне;Медичное;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220786606;3;1
68cf26f2-749b-11df-b112-00215aee3ebe;Медівка;Медовка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523182801;3;1
68cf26f3-749b-11df-b112-00215aee3ebe;Медова;Медовая;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123080402;3;1
68cf26f4-749b-11df-b112-00215aee3ebe;Медове;Медовое;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525884403;3;1
62c3d139-749b-11df-b112-00215aee3ebe;Медове;Медовое;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520382606;3;1
00fbec1f-83d1-11e6-a7f1-001ec93df11f;Медове;Медовое;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;40d0953a-66fb-11ea-80c6-000c29800ae7;3520382606;3;1
68cf26f5-749b-11df-b112-00215aee3ebe;Медувата;Медоватая;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120983102;3;1
68cf26f6-749b-11df-b112-00215aee3ebe;Медуха;Медуха;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621285401;3;1
68cf26f7-749b-11df-b112-00215aee3ebe;Медці;Медцы;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822784807;3;1
68cf26f8-749b-11df-b112-00215aee3ebe;Межеріч;Межерич;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223584501;3;1
68cf26f9-749b-11df-b112-00215aee3ebe;Межиброди;Межиброды;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624555302;3;1
68cf26fa-749b-11df-b112-00215aee3ebe;Межигаї;Межигаи;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624487803;3;1
68cf26fb-749b-11df-b112-00215aee3ebe;Межигір;Межигор;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822487903;3;1
68cf26ff-749b-11df-b112-00215aee3ebe;Межигір'я;Межигорье;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124288803;3;1
68cf2700-749b-11df-b112-00215aee3ebe;Межигір'я;Межигорье;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625585605;3;1
68cf26fc-749b-11df-b112-00215aee3ebe;Межигірка;Межигорка;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125784002;3;1
68cf26fd-749b-11df-b112-00215aee3ebe;Межигірка;Межигорка;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523084002;3;1
68cf26fe-749b-11df-b112-00215aee3ebe;Межигірці;Межигорцы;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621286101;3;1
68cf2701-749b-11df-b112-00215aee3ebe;Межигори;Межигоры;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620380404;3;1
68cf2702-749b-11df-b112-00215aee3ebe;Межиліска;Межилеска;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755136;3;1
68cf2703-749b-11df-b112-00215aee3ebe;Межирів;Межиров;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521085006;3;1
68cf2704-749b-11df-b112-00215aee3ebe;Межиріч;Межирич;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922986201;3;1
68cf2706-749b-11df-b112-00215aee3ebe;Межиріч;Межирич;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122085101;3;1
68cf2707-749b-11df-b112-00215aee3ebe;Межиріч;Межирич;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624284201;3;1
68cf2708-749b-11df-b112-00215aee3ebe;Межирічка;Межиречка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822383401;3;1
68cf2709-749b-11df-b112-00215aee3ebe;Межирічка;Межиречка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825086101;3;1
68cf270a-749b-11df-b112-00215aee3ebe;Межирічка;Межиречка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821481802;3;1
68cf270b-749b-11df-b112-00215aee3ebe;Межирічка;Межиречка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521485201;3;1
68cf270c-749b-11df-b112-00215aee3ebe;Межиріччя;Межиречье;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624883501;3;1
68cf270d-749b-11df-b112-00215aee3ebe;Межиріччя;Межиречье;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621583304;3;1
68cf270e-749b-11df-b112-00215aee3ebe;Межисить;Межисыть;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724285301;3;1
68cf270f-749b-11df-b112-00215aee3ebe;Межова;Межевая;місто;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655100;3;1
68cf2713-749b-11df-b112-00215aee3ebe;Межове;Межовое;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220484103;2;1
68cf2714-749b-11df-b112-00215aee3ebe;Межове;Межовое;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523180305;3;1
d567adb1-e269-11e8-80d7-1c98ec135261;Межове;Межове;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225081204;3;1
68cf2715-749b-11df-b112-00215aee3ebe;Межуївка;Межуевка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222983406;3;1
68cf2716-749b-11df-b112-00215aee3ebe;Мезенівка;Мезеновка;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922382901;3;1
68cf2717-749b-11df-b112-00215aee3ebe;Мезин;Мезин;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422284501;3;1
68cf2718-749b-11df-b112-00215aee3ebe;Мекшунівка;Мекшуновка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424485605;3;1
68cf271a-749b-11df-b112-00215aee3ebe;Мелені;Мелени;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822383601;3;1
68cf271b-749b-11df-b112-00215aee3ebe;Меленці;Меленцы;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823184301;3;1
68cf271c-749b-11df-b112-00215aee3ebe;Мелесівка;Мелесовка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121587804;3;1
68cf271d-749b-11df-b112-00215aee3ebe;Мелехи;Мелехи;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325182601;3;1
68cf271f-749b-11df-b112-00215aee3ebe;Мелешки;Мелешки;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484607;3;1
68cf2720-749b-11df-b112-00215aee3ebe;Мелешки;Мелешки;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320480703;3;1
68cf2721-749b-11df-b112-00215aee3ebe;Мелешків;Мелешков;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520882606;3;1
68cf2722-749b-11df-b112-00215aee3ebe;Мелешківка;Мелешковка;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923587203;3;1
68cf2723-749b-11df-b112-00215aee3ebe;Мелихівка;Мелиховка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324282001;3;1
68cf2724-749b-11df-b112-00215aee3ebe;Меліоративне;Мелиоративное;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223255800;2;1
68cf2726-749b-11df-b112-00215aee3ebe;Мелюшки;Мелюшки;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324884209;3;1
74dc496c-749b-11df-b112-00215aee3ebe;Мелячиха (Петрівське);Мелячиха (Петровское);село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920686607;3;1
68cf2727-749b-11df-b112-00215aee3ebe;Мельна;Мельна;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624481204;3;1
68cf2728-749b-11df-b112-00215aee3ebe;Мельники;Мельники;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924181105;3;1
68cf2729-749b-11df-b112-00215aee3ebe;Мельники;Мельники;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725755103;3;1
68cf272a-749b-11df-b112-00215aee3ebe;Мельники;Мельники;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725055102;3;1
68cf272b-749b-11df-b112-00215aee3ebe;Мельники;Мельники;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625887611;3;1
68cf272d-749b-11df-b112-00215aee3ebe;Мельники;Мельники;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122085401;3;1
68cf272e-749b-11df-b112-00215aee3ebe;Мельники;Мельники;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125186401;3;1
68cf272f-749b-11df-b112-00215aee3ebe;Мельники;Мельники;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125484001;3;1
68cf2730-749b-11df-b112-00215aee3ebe;Мельники;Мельники;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520283206;3;1
17af7b10-ddb9-11df-9197-00215aee3ebe;Мельники;Мельники;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521086006;3;1
cdc44f69-dde8-11df-9197-00215aee3ebe;Мельники;Мельники;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625682502;3;1
68cf2731-749b-11df-b112-00215aee3ebe;Мельники-Мостище;Мельники-Мостище;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721485201;3;1
68cf2732-749b-11df-b112-00215aee3ebe;Мельники-Річицькі;Мельники-Речицкие;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724286402;3;1
68cf2733-749b-11df-b112-00215aee3ebe;Мельниківка;Мельниковка;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123785201;3;1
68cf2734-749b-11df-b112-00215aee3ebe;Мельниківці;Мельниковцы;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523085601;3;1
68cf2735-749b-11df-b112-00215aee3ebe;Мельникове;Мельниково;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923280803;3;1
68cf2736-749b-11df-b112-00215aee3ebe;Мельникове;Мельниково;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321284001;3;1
44ac81c8-749b-11df-b112-00215aee3ebe;Мельникове (Беєве-Комуна);Мельниково (Беево-Коммуна);село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923280803;3;1
68cf2738-749b-11df-b112-00215aee3ebe;Мельниця;Мельница;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722181206;3;1
68cf2739-749b-11df-b112-00215aee3ebe;Мельниця;Мельница;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625455704;3;1
68cf273a-749b-11df-b112-00215aee3ebe;Мельниця-Подільська;Мельница-Подольская;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120855400;3;1
68cf273b-749b-11df-b112-00215aee3ebe;Мельнич;Мельнич;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621584701;3;1
68cf273c-749b-11df-b112-00215aee3ebe;Мельничне;Мельничное;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625582903;3;1
68cf273f-749b-11df-b112-00215aee3ebe;Мельня;Мельня;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922085801;3;1
68cf2741-749b-11df-b112-00215aee3ebe;Мена;Мена;місто;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423010100;2;1
68cf2743-749b-11df-b112-00215aee3ebe;Менделєєвка;Менделеевка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222982809;3;1
68cf2745-749b-11df-b112-00215aee3ebe;Менжинське;Менжинское;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222983900;3;1
68cf2747-749b-11df-b112-00215aee3ebe;Менчичі;Менчичи;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721155106;3;1
68cf2749-749b-11df-b112-00215aee3ebe;Меньківка;Меньковка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825086201;3;1
68cf274a-749b-11df-b112-00215aee3ebe;Мерва;Мерва;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720883801;3;1
68cf274b-749b-11df-b112-00215aee3ebe;Мервин;Мервин;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523186303;3;1
68cf274c-749b-11df-b112-00215aee3ebe;Мервинці;Мервинцы;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522684501;3;1
68cf274d-749b-11df-b112-00215aee3ebe;Мервичі;Мервичи;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622755607;3;0
68cf274f-749b-11df-b112-00215aee3ebe;Мерефа;Мерефа;місто;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325110700;2;0
68cf2750-749b-11df-b112-00215aee3ebe;Мерешор;Мерешор;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122482105;3;1
68cf2751-749b-11df-b112-00215aee3ebe;Мерещів;Мерещев;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623382504;3;1
68cf2752-749b-11df-b112-00215aee3ebe;Мерло;Мерло;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320884503;3;1
68cf2753-749b-11df-b112-00215aee3ebe;Мерло;Мерло;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322685005;3;1
68cf2606-749b-11df-b112-00215aee3ebe;Мерцалівка;Мерцаловка;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223581302;3;1
68cf2758-749b-11df-b112-00215aee3ebe;Метанівка;Метановка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523784201;3;1
68cf2759-749b-11df-b112-00215aee3ebe;Метельне;Метельное;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721855402;3;1
68cf275a-749b-11df-b112-00215aee3ebe;Метенів;Метенев;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122684002;3;1
68cf275b-749b-11df-b112-00215aee3ebe;Метків;Метков;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624683304;3;1
68cf275e-749b-11df-b112-00215aee3ebe;Мефедівка (Мефодівка);Мефедівка (Мефодівка);село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924482804;3;1
68cf275f-749b-11df-b112-00215aee3ebe;Мефодіївка;Мефодиевка;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824282704;3;1
68cf2760-749b-11df-b112-00215aee3ebe;Мехедівка;Мехедовка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120686001;3;1
68cf2761-749b-11df-b112-00215aee3ebe;Мехедівка;Мехедовка;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322680403;3;1
68cf2762-749b-11df-b112-00215aee3ebe;Мечебилове;Мечебилово;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320484001;3;1
68cf2763-749b-11df-b112-00215aee3ebe;Меченки;Меченки;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323884904;3;1
68cf2765-749b-11df-b112-00215aee3ebe;Мечетне;Мечетное;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224287809;3;1
68cf2766-749b-11df-b112-00215aee3ebe;Мечиславка;Мечиславка;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525585501;3;1
68cf2767-749b-11df-b112-00215aee3ebe;Мечищів;Мечищев;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120483901;3;1
68cf2769-749b-11df-b112-00215aee3ebe;Мивсева;Мивсевая;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623386406;3;1
68cf276a-749b-11df-b112-00215aee3ebe;Мигаєве;Мигаево;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121682603;3;1
68cf276b-749b-11df-b112-00215aee3ebe;Мигаї;Мигаи;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121682601;3;1
68cf276c-749b-11df-b112-00215aee3ebe;Мигалівці;Мигалевцы;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520283501;3;1
47250933-044c-11e0-bc87-00215aee3ebe;Мигалки;Мигалки;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221085001;3;1
68cf276e-749b-11df-b112-00215aee3ebe;Мигія;Мигия;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825485101;3;1
68cf276f-749b-11df-b112-00215aee3ebe;Мигове;Мигово;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7320585001;3;0
68cf2770-749b-11df-b112-00215aee3ebe;Мигурів;Мигуров;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425381502;3;1
68cf2771-749b-11df-b112-00215aee3ebe;Мизинівка;Мизиновка;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121283601;3;1
68cf2772-749b-11df-b112-00215aee3ebe;Мизове;Мызово;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725082901;3;1
68cf2774-749b-11df-b112-00215aee3ebe;Микити;Ныкиты;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620383603;3;1
68cf2775-749b-11df-b112-00215aee3ebe;Микитинці;Никитинцы;село;a1e9f9b8-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2610193001;2;0
68cf2776-749b-11df-b112-00215aee3ebe;Микитинці;Никитинцы;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623684301;3;1
68cf2777-749b-11df-b112-00215aee3ebe;Микитинці;Никитинцы;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825883606;3;1
68cf2778-749b-11df-b112-00215aee3ebe;Микитичі;Никитичи;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720510413;3;1
68cf2779-749b-11df-b112-00215aee3ebe;Микитичі;Никитичи;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621686007;3;1
68cf277a-749b-11df-b112-00215aee3ebe;Микитівка;Никитовка;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925682501;3;1
68cf277b-749b-11df-b112-00215aee3ebe;Микитівка;Никитовка;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925080404;3;1
68cf277d-749b-11df-b112-00215aee3ebe;Микитське;Никитское;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925684903;3;1
68cf277e-749b-11df-b112-00215aee3ebe;Микитяни;Микитяны;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222989103;3;1
68cf2780-749b-11df-b112-00215aee3ebe;Микілка;Николка;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322283701;3;1
68cf2781-749b-11df-b112-00215aee3ebe;Микільськ;Никольск;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821481803;3;1
68cf2782-749b-11df-b112-00215aee3ebe;Микільське;Никольское;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083901;3;1
68cf2783-749b-11df-b112-00215aee3ebe;Микільське;Никольское;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924781504;3;1
68cf2784-749b-11df-b112-00215aee3ebe;Микільське;Никольское;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225086003;3;1
68cf2785-749b-11df-b112-00215aee3ebe;Микільське;Никольское;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821481004;3;1
68cf2787-749b-11df-b112-00215aee3ebe;Микільське;Никольское;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325781005;3;1
68cf2788-749b-11df-b112-00215aee3ebe;Микільське;Никольское;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320680207;3;1
68cf2789-749b-11df-b112-00215aee3ebe;Микільське;Никольское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321585004;3;1
68cf278a-749b-11df-b112-00215aee3ebe;Микільське;Никольское;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323987807;3;1
68cf278b-749b-11df-b112-00215aee3ebe;Микільське;Никольское;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521881503;3;1
68cf278c-749b-11df-b112-00215aee3ebe;Микільське;Никольское;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520384501;3;1
68cf2791-749b-11df-b112-00215aee3ebe;Микільське;Никольское;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525285201;3;1
8cdd5ce1-de27-11df-9197-00215aee3ebe;Микільське;Никольское;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521786803;3;1
68cf2792-749b-11df-b112-00215aee3ebe;Микільське-на-Дніпрі;Никольское-На-Днепре;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225084301;3;1
68cf2793-749b-11df-b112-00215aee3ebe;Миклаші;Миклаши;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820386201;3;1
68cf2794-749b-11df-b112-00215aee3ebe;Миклашів;Миклашев;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623684001;3;1
68cf2795-749b-11df-b112-00215aee3ebe;Микове;Мыково;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721883103;3;1
68cf2796-749b-11df-b112-00215aee3ebe;Миколаєве;Николаево;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;3;1
68cf2797-749b-11df-b112-00215aee3ebe;Миколаєнкове;Николаенково;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922681503;3;1
68cf2798-749b-11df-b112-00215aee3ebe;Миколаїв;Николаев;місто;5394b334-41be-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4810100000;1;0
68cf279b-749b-11df-b112-00215aee3ebe;Миколаїв;Николаев;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623983001;3;1
68cf279c-749b-11df-b112-00215aee3ebe;Миколаїв;Николаев;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623684401;3;1
68cf279d-749b-11df-b112-00215aee3ebe;Миколаїв;Николаев;місто;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623010100;2;0
68cf279e-749b-11df-b112-00215aee3ebe;Миколаїв;Николаев;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420683603;3;1
68cf279f-749b-11df-b112-00215aee3ebe;Миколаїв;Николаев;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825084801;3;1
68cf2799-749b-11df-b112-00215aee3ebe;Миколаїв Жовтневий;Николаев Октябрьский;місто;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;1;1
68cf27a0-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324888211;3;1
68cf27a1-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321382705;3;1
68cf27a2-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324284204;3;1
68cf27a3-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083207;3;1
68cf27a4-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321880403;3;1
68cf27a5-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920985001;3;1
68cf27a6-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924186401;3;1
68cf27a7-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924785401;3;1
68cf27a8-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920384403;3;1
68cf27a9-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
68cf27aa-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223284001;3;1
68cf27ab-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221083307;3;1
68cf27ac-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;bad3ac3c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1223757100;3;1
68cf27ad-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;о;3;1
68cf27ae-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224587506;3;1
68cf27af-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220786601;3;1
68cf27b0-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223882501;3;1
68cf27b1-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222685507;3;1
68cf27b2-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225255114;3;1
68cf27b3-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225884808;3;1
68cf27b4-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823980404;3;1
68cf27b5-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821482601;3;1
68cf27b6-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655115;3;1
68cf27b7-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821782501;3;1
68cf27b9-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323281209;3;1
68cf27ba-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325484502;3;1
68cf27bc-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322885002;3;1
68cf27bd-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322282501;3;1
68cf27be-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324887003;3;1
68cf27bf-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323988005;3;1
68cf27c0-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122980404;3;1
68cf27c1-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125482901;3;1
68cf27c2-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;місто;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123555100;2;1
68cf27c3-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123980705;3;1
68cf27c4-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120885101;3;1
68cf27c5-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123782501;3;1
68cf27c6-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;903ccab3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122384602;3;1
68cf27c7-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124786001;3;1
68cf27cb-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521881002;3;1
68cf27cc-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520685902;3;1
68cf27de-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221884403;3;1
68cf27df-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222782604;3;1
68cf27e0-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821784201;3;1
68cf27e1-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824081705;3;1
68cf27e2-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424784001;3;1
68cf27e3-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423086701;3;1
68cf27e4-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420883201;3;1
68cf27e5-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424187002;3;1
68cf27e6-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122582902;3;1
68cf27e7-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123784502;3;1
68cf27e8-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521485403;3;1
68cf27e9-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522485803;3;1
68cf27ea-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523281402;3;1
68cf27eb-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521784001;3;1
68cf27ec-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520585502;3;1
68cf27ed-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522585001;3;1
68cf27ee-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824486503;3;1
68cf27ef-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623886901;3;1
68cf27f0-749b-11df-b112-00215aee3ebe;Миколаївка;Николаевка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320482405;3;1
e0517eda-db89-11df-9197-00215aee3ebe;Миколаївка;Николаевка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822783504;3;1
56bdd22a-749b-11df-b112-00215aee3ebe;Миколаївка (Жовтневе);Николаевка (Жовтневое);село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920655300;3;1
68cf27f2-749b-11df-b112-00215aee3ebe;Миколаївка Друга;Николаевка Вторая;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320683505;3;1
68cf27f3-749b-11df-b112-00215aee3ebe;Миколаївка Друга;Николаевка Вторая;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323684004;3;1
68cf27f6-749b-11df-b112-00215aee3ebe;Миколаївка Перша;Николаевка Первая;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320681506;3;1
68cf27f7-749b-11df-b112-00215aee3ebe;Миколаївка Перша;Николаевка Первая;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122981304;3;1
68cf27f8-749b-11df-b112-00215aee3ebe;Миколаївка-Новоросійська;Николаевка-Новороссийская;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124583601;3;1
68cf27f9-749b-11df-b112-00215aee3ebe;Миколаївка-Тернівська;Николаевка-Терновская;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920687804;3;1
68cf27fa-749b-11df-b112-00215aee3ebe;Миколаївське;Николаевское;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924184703;3;1
68cf27fb-749b-11df-b112-00215aee3ebe;Миколаївське;Николаевское;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823383201;3;1
68cf27fd-749b-11df-b112-00215aee3ebe;Миколаївські Сади;Николаевские Сады;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522587603;3;1
68cf27ff-749b-11df-b112-00215aee3ebe;Миколай-Поле;Николай-Поле;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323685905;3;1
68cf2800-749b-11df-b112-00215aee3ebe;Миколай-Поле;Николай-Поле;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322187201;3;1
68cf2801-749b-11df-b112-00215aee3ebe;Миколо-Бабанка;Николо-Бабанка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520884801;3;1
68cf2802-749b-11df-b112-00215aee3ebe;Миколо-Гулак;Николо-Гулак;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823684201;3;1
68cf2803-749b-11df-b112-00215aee3ebe;Миколо-Комишувата;Николо-Камышеватая;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323383501;3;1
68cf2804-749b-11df-b112-00215aee3ebe;Миколо-Мусіївка;Николо-Мусиевка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225083004;3;1
68cf2805-749b-11df-b112-00215aee3ebe;Микулин;Микулин;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823684501;3;1
68cf2806-749b-11df-b112-00215aee3ebe;Микулин;Микулин;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621282107;3;1
68cf2807-749b-11df-b112-00215aee3ebe;Микулинці;Микулинцы;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125055400;2;0
68cf2808-749b-11df-b112-00215aee3ebe;Микулинці;Микулинцы;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522485401;3;1
68cf2809-749b-11df-b112-00215aee3ebe;Микуличин;Мыкуличин;село;a1e9f9cf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2611091501;3;1
68cf280a-749b-11df-b112-00215aee3ebe;Микуличі;Микуличи;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720586602;3;1
68cf280b-749b-11df-b112-00215aee3ebe;Микуличі;Микуличи;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221085501;3;1
68cf280c-749b-11df-b112-00215aee3ebe;Микулівці;Микуловцы;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122784202;3;1
68cf280d-749b-11df-b112-00215aee3ebe;Мила;Мила;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222484405;2;1
68cf280e-749b-11df-b112-00215aee3ebe;Милашенкове;Милашенково;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323280204;3;1
68cf280f-749b-11df-b112-00215aee3ebe;Милівка;Мыловка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320686506;3;1
68cf2810-749b-11df-b112-00215aee3ebe;Милівці;Миловцы;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125585701;3;1
68cf2811-749b-11df-b112-00215aee3ebe;Миловань;Миловань;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723384911;3;1
68cf2813-749b-11df-b112-00215aee3ebe;Милове;Мыловое;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520684201;3;1
68cf2814-749b-11df-b112-00215aee3ebe;Миловидівка;Миловидовка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322483806;3;1
68cf2815-749b-11df-b112-00215aee3ebe;Миловидівка;Миловидовка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786304;3;1
68cf2816-749b-11df-b112-00215aee3ebe;Милорадівка;Милорадовка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055715;3;1
68cf2817-749b-11df-b112-00215aee3ebe;Милорадове;Милорадово;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322283001;3;1
68cf2818-749b-11df-b112-00215aee3ebe;Милостів;Милостов;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624684102;3;1
68cf2819-749b-11df-b112-00215aee3ebe;Милошевичі;Милошевичи;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623685904;3;1
68cf281a-749b-11df-b112-00215aee3ebe;Милування;Милованье;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625883001;3;1
68cf281b-749b-11df-b112-00215aee3ebe;Милушин;Милушин;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722883704;3;1
68cf281c-749b-11df-b112-00215aee3ebe;Милуші;Милуши;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722883705;3;1
68cf281d-749b-11df-b112-00215aee3ebe;Миляновичі;Миляновичи;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725582901;3;1
68cf281e-749b-11df-b112-00215aee3ebe;Милятин;Милятин;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721183810;3;1
68cf281f-749b-11df-b112-00215aee3ebe;Милятин;Милятин;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620983003;3;1
68cf2820-749b-11df-b112-00215aee3ebe;Милятин;Милятин;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624284601;3;1
68cf2821-749b-11df-b112-00215aee3ebe;Милятичі;Милятичи;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623682803;3;1
68cf2822-749b-11df-b112-00215aee3ebe;Миляч;Миляч;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621885001;3;1
68cf2823-749b-11df-b112-00215aee3ebe;Мильне;Мильно;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122685701;3;1
68cf2824-749b-11df-b112-00215aee3ebe;Мильники;Мыльники;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423881504;3;1
50c59133-749b-11df-b112-00215aee3ebe;Мильники (Григорівка);Мильники (Григоровка);село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423384802;3;1
68cf2825-749b-11df-b112-00215aee3ebe;Мильськ;Мыльск;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724584101;3;1
68cf2826-749b-11df-b112-00215aee3ebe;Мильці;Мыльцы;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324085207;3;1
68cf2827-749b-11df-b112-00215aee3ebe;Мильці;Мыльцы;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725085402;3;1
68cf2828-749b-11df-b112-00215aee3ebe;Мильча;Мильча;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621683901;3;1
68cf2829-749b-11df-b112-00215aee3ebe;Мильчиці;Мильчицы;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620984901;3;1
68cf282a-749b-11df-b112-00215aee3ebe;Минаївщина;Минаевщина;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421486406;3;1
68cf282b-749b-11df-b112-00215aee3ebe;Минай;Минай;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2124887403;2;0
68cf282c-749b-11df-b112-00215aee3ebe;Минівка;Миновка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081005;3;1
68cf282d-749b-11df-b112-00215aee3ebe;Минівка;Миновка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222383004;3;1
68cf282e-749b-11df-b112-00215aee3ebe;Минійки;Минейки;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822584001;3;1
68cf282f-749b-11df-b112-00215aee3ebe;Минківка;Минковка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321284501;3;1
68cf2831-749b-11df-b112-00215aee3ebe;Миньківці;Миньковцы;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224084302;2;1
68cf2832-749b-11df-b112-00215aee3ebe;Миньківці;Миньковцы;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823985301;3;1
68cf2833-749b-11df-b112-00215aee3ebe;Миньківці;Миньковцы;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821885901;3;1
68cf2834-749b-11df-b112-00215aee3ebe;Миньківці;Миньковцы;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621680407;3;1
68cf2835-749b-11df-b112-00215aee3ebe;Миргород;Миргород;місто;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5310900000;2;0
68cf2836-749b-11df-b112-00215aee3ebe;Миргороди;Миргороды;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321786204;3;1
68cf2837-749b-11df-b112-00215aee3ebe;Миргородщина;Миргородщина;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084803;3;1
68cf2838-749b-11df-b112-00215aee3ebe;Миржіївка;Миржиевка;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823382004;3;1
68cf2839-749b-11df-b112-00215aee3ebe;Мирин;Мирин;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722181207;3;1
68cf283a-749b-11df-b112-00215aee3ebe;Мирівка;Мировка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323682404;3;1
68cf283b-749b-11df-b112-00215aee3ebe;Мирівка;Мировка;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222285601;3;1
68cf283c-749b-11df-b112-00215aee3ebe;Мирівка;Мировка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820988202;3;1
68cf283d-749b-11df-b112-00215aee3ebe;Мирівське;Мировске;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1211067400;3;1
68cf283e-749b-11df-b112-00215aee3ebe;Мирків;Мирков;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720884001;3;1
68cf283f-749b-11df-b112-00215aee3ebe;Мирлоги;Мирлоги;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920686606;3;1
68cf2840-749b-11df-b112-00215aee3ebe;Мирна Долина;Мирная Долина;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320282802;3;1
68cf2843-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281605;3;1
68cf2844-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322486304;3;1
68cf2845-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922681504;3;1
68cf2847-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921280804;3;1
68cf2848-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720884401;3;1
68cf2849-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124884001;3;1
68cf284a-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223283304;3;1
68cf284b-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224581004;3;1
68cf284c-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055114;3;1
68cf284d-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225055408;3;1
68cf284e-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821483002;3;1
68cf284f-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823383101;3;1
68cf2850-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323584704;3;1
68cf2851-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
68cf2852-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321083504;3;1
68cf2853-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323982003;3;1
68cf2854-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121084101;3;1
68cf2855-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;903ccab3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122310303;3;1
68cf2858-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323910102;3;1
68cf285d-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624084202;3;1
68cf2864-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220885701;3;1
68cf2865-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825481502;3;1
68cf2866-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825082205;3;1
68cf2867-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820881203;3;1
68cf2868-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423388304;3;1
68cf2869-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422081504;3;1
68cf286a-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425380503;3;1
68cf286b-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420610103;3;1
68cf286c-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520280611;3;1
68cf286d-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520887805;3;1
68cf286e-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521987402;3;1
68cf286f-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822187203;3;1
68cf2870-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623485401;3;1
62c3d150-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983207;3;1
56bdd8f0-749b-11df-b112-00215aee3ebe;Мирне;Мирное;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920387803;3;1
5cb6172e-749b-11df-b112-00215aee3ebe;Мирне (Кіровка);Мирное (Кировка);село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521683103;3;1
5cb619bb-749b-11df-b112-00215aee3ebe;Мирне (Комсомолець);Мирное (Комсомолец);село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921280804;3;1
5cb61bd6-749b-11df-b112-00215aee3ebe;Мирне (Котовське);Мирное (Котовское);село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225884809;3;1
8716232e-749b-11df-b112-00215aee3ebe;Мирне (Фрунзе);Мирное (Фрунзе);село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321882005;3;1
68cf2874-749b-11df-b112-00215aee3ebe;Мирнопілля;Мирнополье;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124582401;3;1
68cf2875-749b-11df-b112-00215aee3ebe;Мирнопілля;Мирнополье;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120483401;3;1
68cf2876-749b-11df-b112-00215aee3ebe;Мирове;Мировое;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125884403;3;1
68cf2877-749b-11df-b112-00215aee3ebe;Мирове;Мировое;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225485501;3;1
68cf2878-749b-11df-b112-00215aee3ebe;Мировичі;Мировичи;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725555103;3;1
68cf2879-749b-11df-b112-00215aee3ebe;Мирогоща Друга;Мирогоща Вторая;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621684207;3;1
68cf287a-749b-11df-b112-00215aee3ebe;Мирогоща Перша;Мирогоща Первая;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621684201;3;1
68cf287b-749b-11df-b112-00215aee3ebe;Миролюбівка;Миролюбовка;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125285101;3;1
68cf287c-749b-11df-b112-00215aee3ebe;Миролюбівка;Миролюбовка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224581005;3;1
68cf287d-749b-11df-b112-00215aee3ebe;Миролюбівка;Миролюбовка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224884901;3;1
68cf287e-749b-11df-b112-00215aee3ebe;Миролюбівка;Миролюбовка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455117;3;1
68cf287f-749b-11df-b112-00215aee3ebe;Миролюбівка;Миролюбовка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885707;3;1
68cf2880-749b-11df-b112-00215aee3ebe;Миролюбівка;Миролюбовка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821482401;3;1
68cf2881-749b-11df-b112-00215aee3ebe;Миролюбівка;Миролюбовка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824581503;3;1
68cf2882-749b-11df-b112-00215aee3ebe;Миролюбівка;Миролюбовка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320886003;3;1
68cf2883-749b-11df-b112-00215aee3ebe;Миролюбівка;Миролюбовка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323982504;3;1
68cf2884-749b-11df-b112-00215aee3ebe;Миролюбівка;Миролюбовка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684009;3;1
68cf2885-749b-11df-b112-00215aee3ebe;Миролюбівка;Миролюбовка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321584502;3;1
68cf2886-749b-11df-b112-00215aee3ebe;Миролюбівка;Миролюбовка;село;78f4540d-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6524183001;3;1
68cf2889-749b-11df-b112-00215aee3ebe;Миролюбівка;Миролюбовка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822085001;3;1
68cf288a-749b-11df-b112-00215aee3ebe;Миролюбівка;Миролюбовка;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824784101;3;1
68cf288b-749b-11df-b112-00215aee3ebe;Миролюбівка;Миролюбовка;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523282001;3;1
5cb6172f-749b-11df-b112-00215aee3ebe;Миролюбівка;Миролюбовка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523182001;3;1
a1df8a05-835b-11e8-80d4-1c98ec135261;Миролюбівка;Миролюбовка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223282002;3;1
56bdd269-749b-11df-b112-00215aee3ebe;Миролюбівка (Жовтневе);Миролюбівка (Жовтневе);село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523881801;3;1
5cb619cc-749b-11df-b112-00215aee3ebe;Миролюбівка (Комсомольське);Миролюбивка (Комсомольское);село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323982001;3;1
7ad94f9a-749b-11df-b112-00215aee3ebe;Миролюбівка (Радянське);Миролюбивка (Радянское);село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520385801;3;1
577f4e19-835b-11e8-80d4-1c98ec135261;Миролюбівці;Миролюбовци;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;;3;1
68cf288c-749b-11df-b112-00215aee3ebe;Миролюбне;Миролюбное;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824285601;3;1
68cf288d-749b-11df-b112-00215aee3ebe;Мирони;Мироны;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324586305;3;1
68cf288e-749b-11df-b112-00215aee3ebe;Мирони;Мироны;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290015;3;1
68cf288f-749b-11df-b112-00215aee3ebe;Миронівка;Мироновка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323082203;3;1
68cf2890-749b-11df-b112-00215aee3ebe;Миронівка;Мироновка;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925384901;3;1
68cf2891-749b-11df-b112-00215aee3ebe;Миронівка;Мироновка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224588005;3;1
68cf2892-749b-11df-b112-00215aee3ebe;Миронівка;Мироновка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222987002;3;1
68cf2893-749b-11df-b112-00215aee3ebe;Миронівка;Мироновка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322683003;3;1
68cf2894-749b-11df-b112-00215aee3ebe;Миронівка;Мироновка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324585001;3;1
68cf2895-749b-11df-b112-00215aee3ebe;Миронівка;Мироновка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323955405;3;1
68cf289a-749b-11df-b112-00215aee3ebe;Миронівка;Мироновка;місто;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222910100;2;0
68cf289b-749b-11df-b112-00215aee3ebe;Миронівка;Мироновка;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525683602;3;1
68cf289c-749b-11df-b112-00215aee3ebe;Миронівка;Мироновка;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525284601;3;1
68cf289d-749b-11df-b112-00215aee3ebe;Миронівка;Мироновка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522581805;3;1
68cf289f-749b-11df-b112-00215aee3ebe;Миронівщина;Мироновщина;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983208;3;1
d0625773-8356-11e8-80d4-1c98ec135261;Миронове;Миронове;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222683306;3;1
68cf28a0-749b-11df-b112-00215aee3ebe;Миропілля;Мирополье;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922383301;3;1
68cf28a1-749b-11df-b112-00215aee3ebe;Миропілля;Мирополье;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325781503;3;1
68cf28a2-749b-11df-b112-00215aee3ebe;Миропілля;Мирополье;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122584803;3;1
68cf28a3-749b-11df-b112-00215aee3ebe;Миропіль;Мирополь;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225083005;3;1
68cf28a4-749b-11df-b112-00215aee3ebe;Миропіль;Мирополь;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821455600;3;1
68cf28a5-749b-11df-b112-00215aee3ebe;Миропіль;Мирополь;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523180306;3;1
68cf28a6-749b-11df-b112-00215aee3ebe;Миропільське;Миропольское;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922387003;3;1
68cf28a7-749b-11df-b112-00215aee3ebe;Мирославка;Мирославка;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724585007;3;1
68cf28a8-749b-11df-b112-00215aee3ebe;Мирославка;Мирославка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820883901;3;1
68cf28a9-749b-11df-b112-00215aee3ebe;Мирославль;Мирославль;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820680403;3;1
68cf28aa-749b-11df-b112-00215aee3ebe;Миротин;Миротин;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5622683801;3;1
68cf28ab-749b-11df-b112-00215aee3ebe;Мироцьке;Мироцкое;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222484801;3;1
68cf28ad-749b-11df-b112-00215aee3ebe;Миртюки;Миртюки;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625384601;3;1
68cf28ae-749b-11df-b112-00215aee3ebe;Мирутин;Мирутин;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823985501;3;1
68cf28af-749b-11df-b112-00215aee3ebe;Мирча;Мырча;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120883603;3;1
68cf28b0-749b-11df-b112-00215aee3ebe;Мирча;Мырча;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221085901;3;1
68cf28b1-749b-11df-b112-00215aee3ebe;Мисайлівка;Мисайловка;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220683601;3;1
68cf28b2-749b-11df-b112-00215aee3ebe;Миси;Мысы;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424482507;3;1
68cf28b3-749b-11df-b112-00215aee3ebe;Мисики;Мысики;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321387208;3;1
68cf28b4-749b-11df-b112-00215aee3ebe;Мисики;Мысики;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123483801;3;1
68cf28b5-749b-11df-b112-00215aee3ebe;Мислина;Мыслина;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722182407;3;1
68cf28b6-749b-11df-b112-00215aee3ebe;Мислині;Мыслины;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720884804;3;1
68cf28b7-749b-11df-b112-00215aee3ebe;Мислів;Мыслов;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622886002;3;1
68cf28b8-749b-11df-b112-00215aee3ebe;Мислівка;Мысловка;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622087602;3;1
68cf28b9-749b-11df-b112-00215aee3ebe;Мислова;Мыслова;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124684701;3;1
68cf28ba-749b-11df-b112-00215aee3ebe;Мислова;Мыслова;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820983503;3;1
68cf28bb-749b-11df-b112-00215aee3ebe;Мислятин;Мыслятин;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822184201;3;1
68cf28be-749b-11df-b112-00215aee3ebe;Мистичі;Мистичи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622486602;3;1
68cf28bf-749b-11df-b112-00215aee3ebe;Мисюрівка;Мисюровка;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824482502;3;1
68cf28c0-749b-11df-b112-00215aee3ebe;Мита;Мыта;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624586902;3;1
68cf28c1-749b-11df-b112-00215aee3ebe;Митаївка;Митаевка;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220684001;3;1
68cf28c2-749b-11df-b112-00215aee3ebe;Митинці;Мытинцы;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524884802;3;1
68cf28c3-749b-11df-b112-00215aee3ebe;Митинці;Мытинцы;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822787401;3;1
68cf28c4-749b-11df-b112-00215aee3ebe;Митки;Мытки;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520283603;3;1
68cf28c5-749b-11df-b112-00215aee3ebe;Митків;Мытков;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321585901;3;1
68cf28c6-749b-11df-b112-00215aee3ebe;Митків;Мытков;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520884803;3;1
68cf28c7-749b-11df-b112-00215aee3ebe;Митківка;Мытковка;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524181503;3;1
68cf28c8-749b-11df-b112-00215aee3ebe;Митківці;Мытковцы;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823083801;3;1
68cf28c9-749b-11df-b112-00215aee3ebe;Митлашівка;Митлашевка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120686501;3;1
68cf28ca-749b-11df-b112-00215aee3ebe;Митниця;Мытница;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124684602;3;1
68cf28cb-749b-11df-b112-00215aee3ebe;Митниця;Мытница;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620382409;3;1
68cf28cc-749b-11df-b112-00215aee3ebe;Митниця;Мытница;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221485601;3;1
68cf28cd-749b-11df-b112-00215aee3ebe;Митниця;Мытница;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625885603;3;1
68cf28d0-749b-11df-b112-00215aee3ebe;Митрофанівка;Митрофановка;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523482901;3;1
48a386bf-9c9e-11e0-82d0-003048d2b473;Митулин;Митулин;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621884302;3;1
68cf28d1-749b-11df-b112-00215aee3ebe;Митченки;Митченки;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420386501;3;1
68cf28d5-749b-11df-b112-00215aee3ebe;Михайлевичі;Михайлевичи;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624284901;3;1
68cf28d6-749b-11df-b112-00215aee3ebe;Михайлевичі;Михайлевичи;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621289001;3;1
68cf28d7-749b-11df-b112-00215aee3ebe;Михайленки;Михайленки;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322487004;3;1
68cf28d8-749b-11df-b112-00215aee3ebe;Михайленки;Михайленки;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825855301;3;1
68cf28d9-749b-11df-b112-00215aee3ebe;Михайленків;Михайленков;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221086305;3;1
68cf28da-749b-11df-b112-00215aee3ebe;Михайленкове;Михайленково;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920384004;3;1
68cf28db-749b-11df-b112-00215aee3ebe;Михайлики;Михайлики;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755137;3;1
68cf28dc-749b-11df-b112-00215aee3ebe;Михайлики;Михайлики;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081305;3;1
68cf28dd-749b-11df-b112-00215aee3ebe;Михайлики;Михайлики;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322082001;3;1
68cf28de-749b-11df-b112-00215aee3ebe;Михайлин;Михайлин;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724586904;3;1
68cf28df-749b-11df-b112-00215aee3ebe;Михайлин;Михайлин;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521485003;3;1
68cf28e0-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321386006;3;1
68cf28e1-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320282601;3;1
68cf28e2-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321084905;3;1
68cf28e3-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323083801;3;1
68cf28e4-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920984601;3;1
68cf28e5-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922986501;3;1
68cf28e6-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922355101;3;1
68cf28e7-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722481203;3;1
68cf28e8-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722886205;3;1
68cf28e9-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123883705;3;1
68cf28ea-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
68cf28eb-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655115;3;1
68cf28ec-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223283501;3;1
68cf28ed-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224580505;3;1
68cf28ee-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222085505;3;1
68cf28ef-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225080513;3;1
68cf28f0-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224885001;3;1
68cf28f1-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225255102;3;1
68cf28f2-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455118;3;1
68cf28f3-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220310108;3;1
68cf28f4-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821484602;3;1
68cf28f5-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823084208;3;1
68cf28f6-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655116;3;1
68cf28f7-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820682604;3;1
68cf28f8-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822381304;3;1
68cf28f9-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824883740;3;1
68cf28fa-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821755115;3;1
68cf28fb-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;3;1
68cf28fc-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825780403;3;1
68cf28fd-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820955115;3;1
68cf28fe-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825181603;3;1
68cf28ff-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322085602;3;1
68cf2900-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321084201;3;1
68cf2901-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323580502;3;1
68cf2902-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325185004;3;1
68cf2904-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321284011;3;1
68cf2905-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325483402;3;1
68cf2906-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325785004;3;1
68cf2907-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324585501;3;1
68cf2908-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
68cf2909-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323981504;3;1
68cf290a-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120282003;3;1
68cf290b-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123382905;3;1
68cf290c-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121084505;3;1
68cf290d-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124582601;3;1
68cf290e-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321584001;3;1
68cf2910-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;78f4540d-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6524183501;3;1
68cf2914-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624882402;3;1
68cf2924-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221282011;3;1
68cf2925-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220410508;3;1
68cf2926-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221684002;3;1
68cf2927-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220684401;3;1
68cf2928-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224610108;2;1
68cf2929-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824484403;3;1
68cf292a-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821785603;3;1
68cf292b-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822383801;3;1
68cf292c-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821182004;3;1
68cf292d-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822087206;3;1
68cf292e-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823182102;3;1
68cf292f-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425886503;3;1
68cf2930-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423684704;3;1
68cf2931-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122088903;3;1
68cf2932-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122888402;3;1
68cf2933-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120686901;3;1
68cf2934-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125181202;3;1
68cf2935-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121280402;3;1
68cf2936-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121884001;3;1
68cf2937-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520681406;3;1
68cf2938-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525385301;3;1
68cf2939-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524384801;3;1
68cf293a-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520884403;3;1
68cf293b-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520483203;3;1
68cf293c-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525683401;3;1
68cf293d-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521781503;3;1
68cf293e-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524084303;3;1
68cf293f-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520584501;3;1
68cf2940-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520384901;3;1
68cf2941-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823980603;3;1
68cf2942-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822182203;3;1
68cf2943-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825285803;2;1
68cf2944-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825883601;3;1
68cf2945-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821886201;3;1
68cf2946-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624683305;3;1
68cf2947-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625885201;3;1
68cf2948-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624282803;3;1
68cf2949-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320885303;3;1
68cf294c-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124880905;3;1
871621e2-749b-11df-b112-00215aee3ebe;Михайлівка;Михайловка;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824284601;3;1
87162701-749b-11df-b112-00215aee3ebe;Михайлівка (Червоноволодимирівка);Михайлівка (Червоноволодимирівка);село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121285701;3;1
68cf294a-749b-11df-b112-00215aee3ebe;Михайлівка Перша;Михайловка Первая;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322285003;3;1
68cf294b-749b-11df-b112-00215aee3ebe;Михайлівка-Рубежівка;Михайловка-Рубежовка;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222485201;3;1
68cf294d-749b-11df-b112-00215aee3ebe;Михайлівське;Михайловское;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321681202;3;1
68cf294e-749b-11df-b112-00215aee3ebe;Михайлівське;Михайловское;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922355102;3;1
68cf294f-749b-11df-b112-00215aee3ebe;Михайлівське;Михайловское;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323581706;3;1
68cf2950-749b-11df-b112-00215aee3ebe;Михайлівське;Михайловское;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323655101;3;1
68cf2952-749b-11df-b112-00215aee3ebe;Михайлівці;Михайловцы;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522884201;3;1
68cf2953-749b-11df-b112-00215aee3ebe;Михайлівці;Михайловцы;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822787201;3;1
68cf2954-749b-11df-b112-00215aee3ebe;Михайлове;Михайлово;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322255103;3;1
68cf2956-749b-11df-b112-00215aee3ebe;Михайло-Ганнівка;Михайло-Анновка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922086001;3;1
68cf2957-749b-11df-b112-00215aee3ebe;Михайло-Жукове;Михайло-Жуково;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821480804;3;1
68cf2958-749b-11df-b112-00215aee3ebe;Михайло-Заводське;Михайло-Заводское;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220310109;3;1
68cf2959-749b-11df-b112-00215aee3ebe;Михайло-Коцюбинське;Михайло-Коцюбинское;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425555400;3;1
68cf295a-749b-11df-b112-00215aee3ebe;Михайло-Ларине;Михайло-Ларино;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823383301;3;1
68cf295b-749b-11df-b112-00215aee3ebe;Михайло-Лукашеве;Михайло-Лукашово;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321584501;3;1
68cf295c-749b-11df-b112-00215aee3ebe;Михайло-Олександрівка;Михайло-Александровка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283101;3;1
68cf295d-749b-11df-b112-00215aee3ebe;Михайлопіль;Михайлополь;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121882601;3;1
68cf295f-749b-11df-b112-00215aee3ebe;Михайлючка;Михайлючка;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825584601;3;1
17af7b1c-ddb9-11df-9197-00215aee3ebe;Михалє;Михале;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721184407;3;1
68cf2960-749b-11df-b112-00215aee3ebe;Михалин;Михалин;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620480403;3;1
68cf2961-749b-11df-b112-00215aee3ebe;Михалків;Михалков;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120888503;3;1
68cf2962-749b-11df-b112-00215aee3ebe;Михалків;Михалков;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623282203;3;1
68cf2963-749b-11df-b112-00215aee3ebe;Михалківці;Михалковцы;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825884003;3;1
68cf2964-749b-11df-b112-00215aee3ebe;Михалківці;Михалковцы;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624280803;3;1
68cf2965-749b-11df-b112-00215aee3ebe;Михалкове;Михалково;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823982602;3;1
68cf2966-749b-11df-b112-00215aee3ebe;Михалкове;Михалково;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324086001;3;1
68cf2967-749b-11df-b112-00215aee3ebe;Михальча;Михальча;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324586501;3;1
68cf2968-749b-11df-b112-00215aee3ebe;Михальче;Михальче;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621682201;3;1
68cf2969-749b-11df-b112-00215aee3ebe;Михальчина Слобода;Михальчина Слобода;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423687001;3;1
68cf296b-749b-11df-b112-00215aee3ebe;Михиринці;Михиринцы;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824784501;3;1
68cf296c-749b-11df-b112-00215aee3ebe;Михівка;Миховка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821855702;3;1
68cf296a-749b-11df-b112-00215aee3ebe;Михіївка;Михеевка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824082803;3;1
68cf296d-749b-11df-b112-00215aee3ebe;Михлас;Мыхлас;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;;3;1
68cf296e-749b-11df-b112-00215aee3ebe;Михлин;Михлин;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720884601;3;1
68cf296f-749b-11df-b112-00215aee3ebe;Михля;Михля;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822183602;3;1
68cf2970-749b-11df-b112-00215aee3ebe;Михнів;Михнов;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822183901;3;1
68cf2971-749b-11df-b112-00215aee3ebe;Михнівка;Михновка;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324282602;3;1
68cf2972-749b-11df-b112-00215aee3ebe;Михнівка;Михновка;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824783601;3;1
68cf2973-749b-11df-b112-00215aee3ebe;Михнівці;Михновцы;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322884901;3;1
68cf2974-749b-11df-b112-00215aee3ebe;Михунки;Михунки;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823082004;3;1
68cf2975-749b-11df-b112-00215aee3ebe;Мицалівка;Мицаловка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121585802;3;1
68cf2976-749b-11df-b112-00215aee3ebe;Мичів;Мичев;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621285403;3;1
68cf2977-749b-11df-b112-00215aee3ebe;Мишарівка;Мышаровка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523783902;3;1
68cf2986-749b-11df-b112-00215aee3ebe;Мишерине;Мишерино;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824755406;3;1
68cf2978-749b-11df-b112-00215aee3ebe;Мишин;Мышин;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623284601;3;1
68cf2979-749b-11df-b112-00215aee3ebe;Мишине;Мишино;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425888804;3;1
68cf297a-749b-11df-b112-00215aee3ebe;Мишів;Мышев;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721155107;3;1
68cf297b-749b-11df-b112-00215aee3ebe;Мишівське;Мышевское;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525380506;3;1
68cf297c-749b-11df-b112-00215aee3ebe;Мишків;Мишков;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122085901;3;1
68cf297d-749b-11df-b112-00215aee3ebe;Мишківці;Мышковцы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122488105;3;1
68cf297e-749b-11df-b112-00215aee3ebe;Мишковичі;Мышковичи;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125285401;2;1
68cf297f-749b-11df-b112-00215aee3ebe;Мишковичі;Мышковичи;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824285803;3;1
68cf2980-749b-11df-b112-00215aee3ebe;Мишлятичі;Мышлятичи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484901;3;1
68cf2981-749b-11df-b112-00215aee3ebe;Мишурин Ріг;Мишурин Рог;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221087001;3;1
68cf2982-749b-11df-b112-00215aee3ebe;Мишутине;Мишутино;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923887003;3;1
68cf2983-749b-11df-b112-00215aee3ebe;Мищенки;Мищенки;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322010107;3;1
68cf2984-749b-11df-b112-00215aee3ebe;Мищенки;Мищенки;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157604;3;1
68cf2985-749b-11df-b112-00215aee3ebe;Мищенки;Мищенки;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324883211;3;1
68cf2987-749b-11df-b112-00215aee3ebe;Мігово;Мегово;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625182904;3;1
68cf2989-749b-11df-b112-00215aee3ebe;Мідне;Медное;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620388604;3;1
68cf298a-749b-11df-b112-00215aee3ebe;Мідяниця;Медяница;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121980403;3;1
68cf298b-749b-11df-b112-00215aee3ebe;Мідянівка;Медяновка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321882205;3;1
68cf298c-749b-11df-b112-00215aee3ebe;Міжброди;Межброды;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323583502;3;1
68cf298e-749b-11df-b112-00215aee3ebe;Міжгайці;Межгайцы;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155123;3;1
68cf298f-749b-11df-b112-00215aee3ebe;Міжгайці;Межгайцы;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624281608;3;1
68cf2991-749b-11df-b112-00215aee3ebe;Міжгір'я;Межгорье;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321085805;3;1
68cf2992-749b-11df-b112-00215aee3ebe;Міжгір'я;Межгорье;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722484203;3;1
68cf2993-749b-11df-b112-00215aee3ebe;Міжгір'я;Межгорье;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620482403;3;1
68cf2995-749b-11df-b112-00215aee3ebe;Міжгір'я;Межгорье;місто;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122455100;2;0
68cf2997-749b-11df-b112-00215aee3ebe;Міжгір'я;Межгорье;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820387503;3;1
68cf2998-749b-11df-b112-00215aee3ebe;Міженець;Миженец;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625183301;3;1
68cf2999-749b-11df-b112-00215aee3ebe;Міжколодяжне;Межколодежное;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521781202;3;1
68cf299a-749b-11df-b112-00215aee3ebe;Міжлісся;Межлесье;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720580807;3;1
68cf299b-749b-11df-b112-00215aee3ebe;Міжлісся;Межлесье;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520280613;3;1
68cf299c-749b-11df-b112-00215aee3ebe;Міжріччя;Межречье;село;8067acf2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2610291401;3;1
054b4726-9053-11e6-a7f1-001ec93df11f;Міжріччя;Межречье;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;;3;1
68cf299e-749b-11df-b112-00215aee3ebe;Мізікевича;Мизикевича;село;5394b336-41be-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;;1;1
68cf299f-749b-11df-b112-00215aee3ebe;Мізоч;Мизоч;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622655400;3;1
68cf29a0-749b-11df-b112-00215aee3ebe;Мізочок;Мизочек;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622655403;3;1
68cf29a1-749b-11df-b112-00215aee3ebe;Мізюринці;Мизюринцы;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125885501;3;1
68cf29a2-749b-11df-b112-00215aee3ebe;Мізяки;Мизяки;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321280505;3;1
68cf29a3-749b-11df-b112-00215aee3ebe;Мізяків;Мизяков;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521684803;3;1
68cf29a4-749b-11df-b112-00215aee3ebe;Мізяківська Слобідка;Мизяковская Слободка;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521684806;3;1
68cf29a5-749b-11df-b112-00215aee3ebe;Мізяківські Хутори;Мизяковские Хутора;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520684003;3;1
68cf29a6-749b-11df-b112-00215aee3ebe;Міліардівка;Миллиардовка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123980507;3;1
68cf29a7-749b-11df-b112-00215aee3ebe;Мілівці;Милевцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822487604;2;1
68cf29a8-749b-11df-b112-00215aee3ebe;Мілієве;Милиево;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320584501;3;1
68cf29aa-749b-11df-b112-00215aee3ebe;Мілова;Меловая;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320284001;3;1
325c57f5-ddd2-11df-9197-00215aee3ebe;Мілова Балка;Меловая Балка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522282604;3;1
68cf29ae-749b-11df-b112-00215aee3ebe;Мілява;Милява;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622780806;3;1
68cf29af-749b-11df-b112-00215aee3ebe;Мільки;Мильки;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424181603;3;1
68cf29b0-749b-11df-b112-00215aee3ebe;Мінакове;Минаково;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923885803;3;1
68cf29b3-749b-11df-b112-00215aee3ebe;Мінеральні Води;Минеральные Воды;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223584510;3;1
68cf29b4-749b-11df-b112-00215aee3ebe;Міняйлівка;Меняйловка;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124582301;3;1
68cf29b6-749b-11df-b112-00215aee3ebe;Міньківці;Миньковцы;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820387001;3;1
68cf29b7-749b-11df-b112-00215aee3ebe;Мірки;Мерки;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923581705;3;1
68cf29b8-749b-11df-b112-00215aee3ebe;Мірошники;Мирошники;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325785204;3;1
68cf29b9-749b-11df-b112-00215aee3ebe;Мірошники;Мирошники;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321284510;3;1
68cf29ba-749b-11df-b112-00215aee3ebe;Мірошники;Мирошники;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321284510;3;1
68cf29bb-749b-11df-b112-00215aee3ebe;Мірошниківка;Мирошниковка;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323280609;3;1
68cf29bc-749b-11df-b112-00215aee3ebe;Мірошниківка;Мирошниковка;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122583902;3;1
95089b31-e34e-11e8-80d7-1c98ec135261;Мірошниківка;Мірошниківка;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;;;1
cb00ea42-ddbd-11e8-80d7-1c98ec135261;Мірошниківка;Мірошниківка;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520383503;3;1
68cf29bd-749b-11df-b112-00215aee3ebe;Мірча;Мирча;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825086601;3;1
68cf29be-749b-11df-b112-00215aee3ebe;Містечко;Мистечко;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820982501;3;1
68cf29bf-749b-11df-b112-00215aee3ebe;Містки;Мостки;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623684201;3;1
68cf29c1-749b-11df-b112-00215aee3ebe;Містки;Мостки;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425881503;3;1
68cf29c2-749b-11df-b112-00215aee3ebe;Містковичі;Местковичи;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624284601;3;1
68cf29c3-749b-11df-b112-00215aee3ebe;Міські Млини;Миськи Млыны;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321355405;3;1
68cf29c4-749b-11df-b112-00215aee3ebe;Мітлинці;Метлинцы;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520883305;3;1
68cf29c7-749b-11df-b112-00215aee3ebe;Міхлівщина;Михлевщина;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223556102;3;1
68cf29c8-749b-11df-b112-00215aee3ebe;Міцівці;Мицивци;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821886501;3;1
68cf29ca-749b-11df-b112-00215aee3ebe;Мічуріне;Мичурино;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825485303;3;1
68cf29cd-749b-11df-b112-00215aee3ebe;Мічурінське;Мичуринское;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321256004;3;1
68cf29cf-749b-11df-b112-00215aee3ebe;Мішково-Погорілове;Мешково-Погорелово;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823383001;2;1
68cf29d0-749b-11df-b112-00215aee3ebe;Міщанка;Мещанка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122784204;3;1
68cf29d1-749b-11df-b112-00215aee3ebe;Млаки;Млаки;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821882404;3;1
68cf29d2-749b-11df-b112-00215aee3ebe;Млачівка;Млачевка;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223586801;3;1
68cf29d3-749b-11df-b112-00215aee3ebe;Млин;Млын;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624289203;3;1
68cf29d4-749b-11df-b112-00215aee3ebe;Млин;Млын;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622284004;3;1
68cf29d5-749b-11df-b112-00215aee3ebe;Млини;Млыны;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624081502;3;1
68cf29d6-749b-11df-b112-00215aee3ebe;Млини;Млыны;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824282705;3;1
68cf29d7-749b-11df-b112-00215aee3ebe;Млини;Млыны;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820684802;3;1
68cf29d8-749b-11df-b112-00215aee3ebe;Млини;Млыны;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322682405;3;1
68cf29d9-749b-11df-b112-00215aee3ebe;Млини;Млыны;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320484105;3;1
68cf29da-749b-11df-b112-00215aee3ebe;Млиниська;Млыниска;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621584901;3;1
68cf29db-749b-11df-b112-00215aee3ebe;Млинище;Млынище;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721184403;3;1
68cf29dc-749b-11df-b112-00215aee3ebe;Млинище;Млынище;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822084703;3;1
68cf29dd-749b-11df-b112-00215aee3ebe;Млинів;Млинов;місто;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623855100;2;0
68cf29de-749b-11df-b112-00215aee3ebe;Млинівці;Млыновцы;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123485101;3;1
68cf29df-749b-11df-b112-00215aee3ebe;Млинівці;Млыновцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122686001;3;1
68cf29e0-749b-11df-b112-00215aee3ebe;Млинки;Млынки;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322281303;3;1
68cf29e1-749b-11df-b112-00215aee3ebe;Млинки;Млынки;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121287803;3;1
68cf29e2-749b-11df-b112-00215aee3ebe;Млинки;Млынки;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325083602;3;1
33ec0019-ddd5-11df-9197-00215aee3ebe;Млинки;Млынки;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520810103;3;1
68cf29e3-749b-11df-b112-00215aee3ebe;Млинове;Млыново;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724285501;3;1
68cf29e4-749b-11df-b112-00215aee3ebe;Млинок;Млынок;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224981602;3;1
68cf29e5-749b-11df-b112-00215aee3ebe;Млинок;Млынок;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824484404;3;1
68cf29e6-749b-11df-b112-00215aee3ebe;Млинок;Млынок;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425881504;3;1
68cf29e7-749b-11df-b112-00215aee3ebe;Млинок;Млынок;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524684701;3;1
68cf29e8-749b-11df-b112-00215aee3ebe;Млинок;Млынок;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622280403;3;1
68cf29e9-749b-11df-b112-00215aee3ebe;Млинська;Млинская;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125083202;3;1
68cf29ea-749b-11df-b112-00215aee3ebe;Млинці;Млинцы;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123082409;3;1
68cf29eb-749b-11df-b112-00215aee3ebe;Мліїв;Млиев;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120385001;3;1
68cf29ec-749b-11df-b112-00215aee3ebe;Мнишин;Мнишин;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621282004;3;1
68cf29f0-749b-11df-b112-00215aee3ebe;Мньов;Мнев;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425584901;3;1
68cf29f1-749b-11df-b112-00215aee3ebe;Мовники;Мовники;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721182406;3;1
68cf29f2-749b-11df-b112-00215aee3ebe;Мовчани;Мовчаны;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325181203;3;1
68cf29f3-749b-11df-b112-00215aee3ebe;Мовчани;Мовчаны;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521084003;3;1
68cf29f4-749b-11df-b112-00215aee3ebe;Мовчани;Мовчаны;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822789503;3;1
68cf29f5-749b-11df-b112-00215aee3ebe;Мовчанів;Молчанов;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722485804;3;1
68cf29f6-749b-11df-b112-00215aee3ebe;Мовчанів;Молчанов;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423682403;3;1
68cf29f7-749b-11df-b112-00215aee3ebe;Мовчанівка;Мовчановка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124682702;3;1
68cf29f8-749b-11df-b112-00215aee3ebe;Мовчанівка;Мовчановка;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224084901;2;1
68cf29f9-749b-11df-b112-00215aee3ebe;Мовчанівка;Мовчановка;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825284601;3;1
8cdd5cea-de27-11df-9197-00215aee3ebe;Мовчанівка;Мовчановка;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;;3;1
68cf29fa-749b-11df-b112-00215aee3ebe;Мовчанівське;Молчановское;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825284602;3;1
68cf29fb-749b-11df-b112-00215aee3ebe;Могилатів;Могилатов;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320484704;3;1
68cf29fc-749b-11df-b112-00215aee3ebe;Могилів;Могилев;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225683601;3;1
68cf29fd-749b-11df-b112-00215aee3ebe;Могилів Курінь;Могилев Курень;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520586004;3;1
68cf29fe-749b-11df-b112-00215aee3ebe;Могилівка;Могилевка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225882204;3;1
68cf29ff-749b-11df-b112-00215aee3ebe;Могилівка;Могилевка;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320783003;3;1
68cf2a00-749b-11df-b112-00215aee3ebe;Могилівка;Могилевка;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521080606;3;1
68cf2a01-749b-11df-b112-00215aee3ebe;Могилів-Подільський;Могилев-Подольский;місто;63a4b4fb-3f54-11e0-94a2-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0510400000;1;0
68cf2a02-749b-11df-b112-00215aee3ebe;Могилівщина;Могилевщина;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323810107;3;1
68cf2a04-749b-11df-b112-00215aee3ebe;Могиляни;Могиляны;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622780247;3;1
68cf2a05-749b-11df-b112-00215aee3ebe;Могиляни;Могиляны;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624285101;3;1
68cf2a06-749b-11df-b112-00215aee3ebe;Могильне;Могильное;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521183601;3;1
68cf2a08-749b-11df-b112-00215aee3ebe;Могриця;Могрица;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924784601;3;1
68cf2a0a-749b-11df-b112-00215aee3ebe;Могутнє;Могутнее;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522584501;3;1
68cf2a0b-749b-11df-b112-00215aee3ebe;Моделів;Моделев;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825087802;3;1
68cf2a0c-749b-11df-b112-00215aee3ebe;Модестівка;Модестовка;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823980604;3;1
68cf2a0d-749b-11df-b112-00215aee3ebe;Модричі;Модрычи;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621284947;3;1
68cf2a0e-749b-11df-b112-00215aee3ebe;Модьорош;Модьерош;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125355301;3;1
68cf2a0f-749b-11df-b112-00215aee3ebe;Можари;Можары;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824284701;3;1
68cf2a11-749b-11df-b112-00215aee3ebe;Мозкове;Мозговое;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922387603;3;1
68cf2a12-749b-11df-b112-00215aee3ebe;Мозкове;Мозговое;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925080803;3;1
68cf2a13-749b-11df-b112-00215aee3ebe;Мозолівка;Мозолевка;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124884501;3;1
68cf2a14-749b-11df-b112-00215aee3ebe;Мозолівка;Мозолевка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321287004;3;1
68cf2a15-749b-11df-b112-00215aee3ebe;Мозолівка;Мозолевка;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624085603;3;1
68cf2a16-749b-11df-b112-00215aee3ebe;Мозоліївка;Мозолиевка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320687204;3;1
68cf2a17-749b-11df-b112-00215aee3ebe;Моївка;Моевка;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524980308;3;1
68cf2a18-749b-11df-b112-00215aee3ebe;Мойка;Мойка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323581202;3;1
68cf2a19-749b-11df-b112-00215aee3ebe;Мойсівка;Мойсевка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120687101;3;1
68cf2a1a-749b-11df-b112-00215aee3ebe;Мойсіївка;Моисеевка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822382803;3;1
cdc44f49-dde8-11df-9197-00215aee3ebe;Мойсіївка;Моисеевка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821781604;3;1
68cf2a1b-749b-11df-b112-00215aee3ebe;Моквин;Моквин;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623485403;3;1
68cf2ac3-749b-11df-b112-00215aee3ebe;Моквин;Моквин;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620487010;3;1
68cf2a1c-749b-11df-b112-00215aee3ebe;Моквинські Хутори;Моквинские Хутора;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623486502;3;1
68cf2a1d-749b-11df-b112-00215aee3ebe;Мокіївка;Мокиевка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924188203;3;1
68cf2a1e-749b-11df-b112-00215aee3ebe;Мокіївка;Мокиевка;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325183201;3;1
68cf2a1f-749b-11df-b112-00215aee3ebe;Мокіївці;Мокиевцы;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825585101;3;1
68cf2a20-749b-11df-b112-00215aee3ebe;Мокляки;Мокляки;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821783801;3;1
68cf2a21-749b-11df-b112-00215aee3ebe;Мокра;Мокрая;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123283003;3;1
68cf2a22-749b-11df-b112-00215aee3ebe;Мокра Калигірка;Мокрая Калигорка;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122283701;3;1
68cf2a23-749b-11df-b112-00215aee3ebe;Мокра Корма;Мокрая Корма;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222081602;3;1
68cf2a24-749b-11df-b112-00215aee3ebe;Мокра Рокитна;Мокрая Ракитная;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324284504;3;1
68cf2a25-749b-11df-b112-00215aee3ebe;Мокре;Мокрое;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725081304;3;1
68cf2a26-749b-11df-b112-00215aee3ebe;Мокре;Мокрое;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820681809;3;1
68cf2a27-749b-11df-b112-00215aee3ebe;Мокре;Мокрое;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621684209;3;1
68cf2a28-749b-11df-b112-00215aee3ebe;Мокренщина;Мокренщина;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825686802;3;1
68cf2a29-749b-11df-b112-00215aee3ebe;Мокрець;Мокрец;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725583101;3;1
68cf2a2a-749b-11df-b112-00215aee3ebe;Мокрець;Мокрец;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221255504;3;1
68cf2a2b-749b-11df-b112-00215aee3ebe;Мокрець;Мокрец;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822183603;3;1
68cf2a2d-749b-11df-b112-00215aee3ebe;Мокрі Велички;Мокрые Велички;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424489203;3;1
68cf2a2e-749b-11df-b112-00215aee3ebe;Мокріївка;Мокриевка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323288905;3;1
68cf2a2f-749b-11df-b112-00215aee3ebe;Мокроволя;Мокроволя;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820386001;3;1
68cf2a31-749b-11df-b112-00215aee3ebe;Мокротин;Мокротин;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785901;3;1
68cf2afb-749b-11df-b112-00215aee3ebe;Мокряни;Мокряны;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621288603;3;1
68cf2a32-749b-11df-b112-00215aee3ebe;Мокрянка;Мокрянка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323383503;3;1
68cf2a33-749b-11df-b112-00215aee3ebe;Молдовка;Молдовка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521485601;3;1
68cf2a34-749b-11df-b112-00215aee3ebe;Молниця;Молница;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320783501;3;1
68cf2a35-749b-11df-b112-00215aee3ebe;Молога;Молога;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120884401;3;1
68cf2a36-749b-11df-b112-00215aee3ebe;Молодаво Друга;Молодава Вторая;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621684405;3;1
68cf2a37-749b-11df-b112-00215aee3ebe;Молодаво Перша;Молодаво Первое;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621684403;3;1
68cf2a38-749b-11df-b112-00215aee3ebe;Молодаво Третя;Молодава Третья;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621684401;3;1
68cf2a39-749b-11df-b112-00215aee3ebe;Молодецьке;Молодецкое;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920381705;3;1
68cf2a3a-749b-11df-b112-00215aee3ebe;Молодецьке;Молодецкое;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520385804;3;1
68cf2a3c-749b-11df-b112-00215aee3ebe;Молодецьке;Молодецкое;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123185501;3;1
68cf2a3e-749b-11df-b112-00215aee3ebe;Молодецьке;Молодецкое;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522585403;3;1
68cf2a40-749b-11df-b112-00215aee3ebe;Молодиківщина;Молодиковщина;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324282803;3;1
68cf2a41-749b-11df-b112-00215aee3ebe;Молодилів;Молодилов;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623286205;3;1
68cf2a42-749b-11df-b112-00215aee3ebe;Молодинче;Молодинче;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621585001;3;1
68cf2a43-749b-11df-b112-00215aee3ebe;Молодівка;Молодовка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920986403;3;1
68cf2a44-749b-11df-b112-00215aee3ebe;Молодівка;Молодовка;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725587604;3;1
68cf2a45-749b-11df-b112-00215aee3ebe;Молодіжне;Молодежное;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225685005;3;1
68cf2a46-749b-11df-b112-00215aee3ebe;Молодіжне;Молодежное;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123782001;3;1
68cf2a4c-749b-11df-b112-00215aee3ebe;Молодіжне;Молодежное;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825482605;3;1
68cf2a4d-749b-11df-b112-00215aee3ebe;Молодіжне;Молодежное;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521955400;3;1
68cf2a4e-749b-11df-b112-00215aee3ebe;Молодіжне;Молодежное;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621682606;3;1
17af7b13-ddb9-11df-9197-00215aee3ebe;Молодіжне;Молодежное;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521480206;3;1
68cf2a4f-749b-11df-b112-00215aee3ebe;Молодія;Молодия;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321084801;3;1
68cf2a50-749b-11df-b112-00215aee3ebe;Молодків;Молодков;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624083701;3;1
68cf2a52-749b-11df-b112-00215aee3ebe;Молодове;Молодово;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124583205;3;1
68cf2a54-749b-11df-b112-00215aee3ebe;Молодятин;Молодятин;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623255705;3;1
68cf2a55-749b-11df-b112-00215aee3ebe;Моложава;Моложава;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421486401;3;1
68cf2a56-749b-11df-b112-00215aee3ebe;Моломолинці;Маломолинцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825082102;3;1
68cf2a57-749b-11df-b112-00215aee3ebe;Молотів;Молотов;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621581204;3;1
68cf2a58-749b-11df-b112-00215aee3ebe;Молотків;Молотков;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123884501;3;1
68cf2a59-749b-11df-b112-00215aee3ebe;Молотківці;Молотковцы;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521480209;3;1
68cf2a5a-749b-11df-b112-00215aee3ebe;Молохів;Молохов;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120482304;3;1
68cf2a5e-749b-11df-b112-00215aee3ebe;Молочки;Молочки;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825885601;3;1
68cf2a5f-749b-11df-b112-00215aee3ebe;Молочки;Молочки;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424488003;3;1
68cf2a66-749b-11df-b112-00215aee3ebe;Молочне;Молочное;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224685302;3;1
68cf2a67-749b-11df-b112-00215aee3ebe;Молошки;Молошки;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620987602;3;1
68cf2523-749b-11df-b112-00215aee3ebe;Молошковичі;Молошковичи;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625880402;3;1
68cf2a68-749b-11df-b112-00215aee3ebe;Момоти;Момоты;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;;3;1
68cf2a69-749b-11df-b112-00215aee3ebe;Момотове;Момотово;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157309;3;1
68cf2a6a-749b-11df-b112-00215aee3ebe;Монастир-Дережицький;Монастырь-Дережичский;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621282703;2;1
68cf2a6b-749b-11df-b112-00215aee3ebe;Монастирець;Монастырец;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624285201;3;1
68cf2a6c-749b-11df-b112-00215aee3ebe;Монастирець;Монастырец;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620985201;3;1
68cf2a6d-749b-11df-b112-00215aee3ebe;Монастирець;Монастырец;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621585301;3;1
68cf2a6e-749b-11df-b112-00215aee3ebe;Монастирець;Монастырец;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625380606;3;1
68cf2a6f-749b-11df-b112-00215aee3ebe;Монастирець;Монастырец;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125385601;3;1
68cf2a70-749b-11df-b112-00215aee3ebe;Монастириська;Монастыриска;місто;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124210100;3;0
68cf2a71-749b-11df-b112-00215aee3ebe;Монастириха;Монастыриха;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121684503;3;1
68cf2a72-749b-11df-b112-00215aee3ebe;Монастирище;Монастырище;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421786801;3;1
68cf2a73-749b-11df-b112-00215aee3ebe;Монастирище;Монастырище;місто;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123410100;2;0
68cf2a74-749b-11df-b112-00215aee3ebe;Монастир-Лішнянський;Монастырь-Лешнянский;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621284803;3;1
68cf2a75-749b-11df-b112-00215aee3ebe;Монастирок;Монастырек;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120880902;3;1
68cf2a76-749b-11df-b112-00215aee3ebe;Монастирок;Монастырек;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621682003;3;1
68cf2a77-749b-11df-b112-00215aee3ebe;Монастирок;Монастырек;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783813;3;1
68cf2a78-749b-11df-b112-00215aee3ebe;Монастирок;Монастырек;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620386605;3;1
68cf2a79-749b-11df-b112-00215aee3ebe;Монастирок;Монастырек;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621882806;3;1
68cf2a7a-749b-11df-b112-00215aee3ebe;Монастирок;Монастырек;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821486902;3;1
68cf2a7b-749b-11df-b112-00215aee3ebe;Монастирок;Монастырек;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523083803;3;1
68cf2a7c-749b-11df-b112-00215aee3ebe;Монастирок;Монастырек;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825884001;3;1
e0517ecc-db89-11df-9197-00215aee3ebe;Монастирок-Оглядівський;Монастырек-Оглядовский;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623983804;3;1
68cf25d7-749b-11df-b112-00215aee3ebe;Монастирське (Марксове);Монастырское (Марксово);село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523085101;3;1
68cf2a7d-749b-11df-b112-00215aee3ebe;Монастирчани;Монастырчаны;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620486401;3;1
68cf2a80-749b-11df-b112-00215aee3ebe;Монаші;Монаши;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120884601;3;1
68cf2a81-749b-11df-b112-00215aee3ebe;Монилівка;Мониловка;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122689703;3;1
68cf2a82-749b-11df-b112-00215aee3ebe;Мончин;Мончин;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523482801;3;1
68cf2a83-749b-11df-b112-00215aee3ebe;Мончинці;Мончинцы;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521685206;3;1
68cf2a84-749b-11df-b112-00215aee3ebe;Мончинці;Мончинцы;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822789203;3;1
68cf2a85-749b-11df-b112-00215aee3ebe;Моньки;Моньки;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822789204;3;1
68cf2a87-749b-11df-b112-00215aee3ebe;Моргуличі;Моргуличи;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425581002;3;1
68cf2a88-749b-11df-b112-00215aee3ebe;Моргунівка;Моргуновка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523881803;3;1
68cf2a89-749b-11df-b112-00215aee3ebe;Моргунове;Моргуново;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120386004;3;1
68cf2a8b-749b-11df-b112-00215aee3ebe;Моринці;Моринцы;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122585301;3;1
68cf2a8c-749b-11df-b112-00215aee3ebe;Моринці;Моринцы;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121284001;3;1
68cf2a8d-749b-11df-b112-00215aee3ebe;Морівськ;Моровск;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422085601;3;1
68cf2a8e-749b-11df-b112-00215aee3ebe;Морквина;Морквина;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522882010;3;1
68cf2a8f-749b-11df-b112-00215aee3ebe;Морогівка;Мороговка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825087204;3;1
68cf2a90-749b-11df-b112-00215aee3ebe;Морози;Морозы;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321384404;3;1
68cf2a91-749b-11df-b112-00215aee3ebe;Морози;Морозы;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321885102;3;1
68cf2a92-749b-11df-b112-00215aee3ebe;Морозів;Морозов;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821886801;3;1
68cf2a93-749b-11df-b112-00215aee3ebe;Морозівка;Морозовка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320284401;3;1
68cf2a94-749b-11df-b112-00215aee3ebe;Морозівка;Морозовка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322187205;3;1
68cf2a97-749b-11df-b112-00215aee3ebe;Морозівка;Морозовка;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220284901;3;1
68cf2a98-749b-11df-b112-00215aee3ebe;Морозівка;Морозовка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823485501;3;1
68cf2a99-749b-11df-b112-00215aee3ebe;Морозівка;Морозовка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824082604;3;1
68cf2a9a-749b-11df-b112-00215aee3ebe;Морозівка;Морозовка;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820982701;3;1
68cf2a9b-749b-11df-b112-00215aee3ebe;Морозівка;Морозовка;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524884002;3;1
68cf2a9c-749b-11df-b112-00215aee3ebe;Морозівка;Морозовка;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523483001;3;1
68cf2a9d-749b-11df-b112-00215aee3ebe;Морозівка;Морозовка;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522884301;3;1
68cf2a9e-749b-11df-b112-00215aee3ebe;Морозівка;Морозовка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520381302;3;1
68cf2a9f-749b-11df-b112-00215aee3ebe;Морозівка;Морозовка;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524684702;3;1
68cf2aa0-749b-11df-b112-00215aee3ebe;Морозівка;Морозовка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824285603;3;1
68cf2aa1-749b-11df-b112-00215aee3ebe;Морозівка;Морозовка;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623085201;3;1
74dc4924-749b-11df-b112-00215aee3ebe;Морозівка (Петрівка);Морозивка (Петровка);село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922684403;3;1
68cf2aa2-749b-11df-b112-00215aee3ebe;Морозівське;Морозовское;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223582802;3;1
68cf2aa3-749b-11df-b112-00215aee3ebe;Морозівське;Морозовское;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224887105;3;1
911f1382-834f-11e8-80d4-1c98ec135261;Морозівське;Морозовское;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220787711;3;1
68cf2aa4-749b-11df-b112-00215aee3ebe;Морозівщина;Морозовщина;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281211;3;1
68cf2aa5-749b-11df-b112-00215aee3ebe;Морозівщина;Морозовщина;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320484705;3;1
68cf2aa6-749b-11df-b112-00215aee3ebe;Морозова;Морозова;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123580405;3;1
68cf2aa7-749b-11df-b112-00215aee3ebe;Морозова Долина;Морозова Долина;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322685006;3;1
68cf2aa8-749b-11df-b112-00215aee3ebe;Морозовичі;Морозовичи;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721183301;3;1
68cf2aa9-749b-11df-b112-00215aee3ebe;Морозовичі;Морозовичи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625187409;3;1
68cf2aaa-749b-11df-b112-00215aee3ebe;Морокине;Морокино;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684605;3;1
68cf2aab-749b-11df-b112-00215aee3ebe;Мороховець;Мороховец;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325180704;3;1
68cf2aac-749b-11df-b112-00215aee3ebe;Мороча;Мороча;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920683007;3;1
68cf2aad-749b-11df-b112-00215aee3ebe;Морочне;Морочное;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622283201;3;1
68cf2aae-749b-11df-b112-00215aee3ebe;Морське;Морское;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820982203;3;1
68cf2ab0-749b-11df-b112-00215aee3ebe;Моршин;Моршин;місто;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4610700000;2;0
68cf2ab1-749b-11df-b112-00215aee3ebe;Морянці;Моранцы;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625855603;3;1
68cf2ab2-749b-11df-b112-00215aee3ebe;Мосир;Мосыр;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723382602;3;1
68cf2ab4-749b-11df-b112-00215aee3ebe;Москаленки;Москаленки;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921584804;3;1
68cf2ab5-749b-11df-b112-00215aee3ebe;Москаленки;Москаленки;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920682203;3;1
68cf2ab6-749b-11df-b112-00215aee3ebe;Москаленки;Москаленки;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320810101;3;1
68cf2ab7-749b-11df-b112-00215aee3ebe;Москаленки;Москаленки;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220684801;3;1
68cf2ab8-749b-11df-b112-00215aee3ebe;Москаленки;Москаленки;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125186801;3;1
68cf2ab9-749b-11df-b112-00215aee3ebe;Москалі;Москали;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425589903;3;1
68cf2aba-749b-11df-b112-00215aee3ebe;Москалівка;Москалевка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924182004;3;1
68cf2abb-749b-11df-b112-00215aee3ebe;Москалівка;Москалевка;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123884801;3;1
68cf2abd-749b-11df-b112-00215aee3ebe;Москалівка;Москалевка;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820383002;3;1
68cf2abe-749b-11df-b112-00215aee3ebe;Москалівка;Москалевка;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823683504;3;1
68cf2abf-749b-11df-b112-00215aee3ebe;Москалівка;Москалевка;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823087004;3;1
68cf2ac0-749b-11df-b112-00215aee3ebe;Москалівка;Москалевка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825884401;3;1
68cf2ac1-749b-11df-b112-00215aee3ebe;Москалівщина;Москалевщина;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786804;3;1
68cf2ac2-749b-11df-b112-00215aee3ebe;Москальцівка;Москальцовка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324285009;3;1
68cf2ac4-749b-11df-b112-00215aee3ebe;Москвитянівка;Москвитяновка;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823684002;3;1
68cf2ac5-749b-11df-b112-00215aee3ebe;Москівщина;Московщина;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924185804;3;1
68cf2ac7-749b-11df-b112-00215aee3ebe;Московка;Московка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321585001;3;1
68cf2ac8-749b-11df-b112-00215aee3ebe;Московське;Московское;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925383003;3;1
68cf2ac9-749b-11df-b112-00215aee3ebe;Московське;Московское;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923283201;3;1
68cf2acc-749b-11df-b112-00215aee3ebe;Московський Бобрик;Московский Бобрик;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922986901;3;1
68cf2acd-749b-11df-b112-00215aee3ebe;Московщина;Московщина;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623887602;3;1
68cf2b4e-749b-11df-b112-00215aee3ebe;Мосорівка;Мосоровка;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321585801;3;1
68cf2acf-749b-11df-b112-00215aee3ebe;Мости;Мосты;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221081005;3;1
68cf2ad0-749b-11df-b112-00215aee3ebe;Мости;Мосты;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620985202;3;1
68cf2ad1-749b-11df-b112-00215aee3ebe;Мости;Мосты;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421484805;3;1
68cf2ad2-749b-11df-b112-00215aee3ebe;Мости;Мосты;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420386008;3;1
68cf2ad3-749b-11df-b112-00215aee3ebe;Мости;Мосты;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622680803;3;1
68cf2ad4-749b-11df-b112-00215aee3ebe;Мостиська;Мостиска;місто;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;7d1766f6-b6ff-11eb-80d4-000c29800ae7;4622410100;2;0
68cf2ad5-749b-11df-b112-00215aee3ebe;Мостиська Друга;Моститская Вторая;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484701;3;1
68cf2ad6-749b-11df-b112-00215aee3ebe;Мостище;Мостище;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922682602;3;1
68cf2ad7-749b-11df-b112-00215aee3ebe;Мостище;Мостище;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721485202;3;1
68cf2ad8-749b-11df-b112-00215aee3ebe;Мостище;Мостище;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622884301;3;1
68cf2ad9-749b-11df-b112-00215aee3ebe;Мостище;Мостище;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625686207;3;1
68cf2ada-749b-11df-b112-00215aee3ebe;Мостище;Мостище;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623387205;3;1
68cf2adb-749b-11df-b112-00215aee3ebe;Мостище;Мостище;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222784701;3;1
68cf2adc-749b-11df-b112-00215aee3ebe;Мостище;Мостище;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620881503;3;1
74dc4996-749b-11df-b112-00215aee3ebe;Мостище (Петрівське);Мостище (Петрівське);село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422088001;3;1
68cf2add-749b-11df-b112-00215aee3ebe;Мостове;Мостовое;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821480406;3;1
68cf2ade-749b-11df-b112-00215aee3ebe;Мостове;Мостовое;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822783501;3;1
68cf2adf-749b-11df-b112-00215aee3ebe;Мостове;Мостовое;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;3;1
68cf2ae0-749b-11df-b112-00215aee3ebe;Мостове;Мостовое;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325784504;3;1
68cf2ae3-749b-11df-b112-00215aee3ebe;Мостове;Мостовое;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820387201;3;1
68cf2ae4-749b-11df-b112-00215aee3ebe;Мостове;Мостовое;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523886003;3;1
68cf2ae5-749b-11df-b112-00215aee3ebe;Мостовівщина;Мостовивщина;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281803;3;1
68cf2ae6-749b-11df-b112-00215aee3ebe;Моськівка;Моськовка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324284705;3;1
68cf2ae7-749b-11df-b112-00215aee3ebe;Мосьпанове;Мосьпаново;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325485501;3;1
68cf2ae8-749b-11df-b112-00215aee3ebe;Моташівка;Моташовка;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722883706;3;1
68cf2ae9-749b-11df-b112-00215aee3ebe;Мотижин;Мотыжин;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222784801;3;1
68cf2aea-749b-11df-b112-00215aee3ebe;Мотина Балка;Мотина Балка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225286607;3;1
68cf2aeb-749b-11df-b112-00215aee3ebe;Мотійки;Мотейки;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755137;3;1
68cf2aec-749b-11df-b112-00215aee3ebe;Мотовилівка;Мотовиловка;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823184601;3;1
87162622-749b-11df-b112-00215aee3ebe;Мотовилівка;Мотовиловка;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224987201;3;1
68cf2aed-749b-11df-b112-00215aee3ebe;Мотовилівська Слобідка;Мотовиловская Слободка;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224984501;3;1
68cf2aee-749b-11df-b112-00215aee3ebe;Мотрине;Мотрино;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5321881302;3;1
68cf2aef-749b-11df-b112-00215aee3ebe;Мотронівка;Мотроновка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221084107;3;1
68cf2af1-749b-11df-b112-00215aee3ebe;Мотрунки;Мотрунки;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825885602;3;1
68cf2af2-749b-11df-b112-00215aee3ebe;Мотрунки;Мотрунки;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822787203;3;1
68cf2af3-749b-11df-b112-00215aee3ebe;Мохнате;Мохнатое;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625585701;3;1
68cf2af4-749b-11df-b112-00215aee3ebe;Мохнатин;Мохнатин;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425585201;3;1
68cf2af5-749b-11df-b112-00215aee3ebe;Мохнач;Мохнач;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321784503;3;1
68cf2af6-749b-11df-b112-00215aee3ebe;Мохнач;Мохнач;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125187001;3;1
68cf2af7-749b-11df-b112-00215aee3ebe;Мохначі;Мохначи;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424485201;3;1
68cf2af8-749b-11df-b112-00215aee3ebe;Мохначка;Мохначка;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824780602;3;1
68cf2af9-749b-11df-b112-00215aee3ebe;Мохнівка;Мохновка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424185804;3;1
68cf2afa-749b-11df-b112-00215aee3ebe;Мохове;Моховое;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422289502;3;1
68cf2afd-749b-11df-b112-00215aee3ebe;Мочалище;Мочалище;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420687404;3;1
68cf2afe-749b-11df-b112-00215aee3ebe;Мочалки;Мочалки;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725582605;3;1
68cf2aff-749b-11df-b112-00215aee3ebe;Мочеради;Мочерады;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622483204;3;1
68cf2b00-749b-11df-b112-00215aee3ebe;Мочола;Мочола;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120486001;3;1
68cf2b01-749b-11df-b112-00215aee3ebe;Мочулинці;Мочулинцы;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820985002;3;1
68cf2b02-749b-11df-b112-00215aee3ebe;Мочулище;Мочулище;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621885401;3;1
68cf2b03-749b-11df-b112-00215aee3ebe;Мочулки;Мочулки;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624684502;3;1
68cf2b04-749b-11df-b112-00215aee3ebe;Мочулянка;Мочулянка;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620484403;3;1
68cf2b05-749b-11df-b112-00215aee3ebe;Мочульня;Мочульня;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824285804;3;1
68cf2b06-749b-11df-b112-00215aee3ebe;Мошанець;Мошанец;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322086401;3;1
68cf2b07-749b-11df-b112-00215aee3ebe;Мошенка;Мошенка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920384005;3;1
68cf2b08-749b-11df-b112-00215aee3ebe;Мошків;Мошков;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623886403;3;1
68cf2b09-749b-11df-b112-00215aee3ebe;Мошківка;Мошковка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822087905;3;1
68cf2b0a-749b-11df-b112-00215aee3ebe;Мошківка;Мошковка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386027;3;1
68cf2b0b-749b-11df-b112-00215aee3ebe;Мошківці;Мошковцы;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622886605;3;1
68cf2b0c-749b-11df-b112-00215aee3ebe;Мошни;Мошны;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124985001;3;1
68cf2b0d-749b-11df-b112-00215aee3ebe;Мошногір'я;Мошногорье;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;;3;1
68cf2b0e-749b-11df-b112-00215aee3ebe;Мошняги;Мошняги;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290016;3;1
68cf2b0f-749b-11df-b112-00215aee3ebe;Мошорине;Мошорино;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522284201;3;1
68cf2b10-749b-11df-b112-00215aee3ebe;Мошурів;Мошуров;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124087001;3;1
68cf2b11-749b-11df-b112-00215aee3ebe;Мощана;Мощана;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622786706;3;1
68cf2b12-749b-11df-b112-00215aee3ebe;Мощаниця;Мощаница;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721882003;3;1
68cf2b13-749b-11df-b112-00215aee3ebe;Мощаниця;Мощаница;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824285402;3;1
68cf2b14-749b-11df-b112-00215aee3ebe;Мощаниця;Мощаница;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624285301;3;1
68cf2b15-749b-11df-b112-00215aee3ebe;Мощена;Мощеная;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155705;3;1
68cf2b16-749b-11df-b112-00215aee3ebe;Мощене;Мощеное;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521184001;3;1
68cf2b17-749b-11df-b112-00215aee3ebe;Мощенка;Мощенка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421486801;3;1
68cf2b18-749b-11df-b112-00215aee3ebe;Мощне;Мощное;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423086704;3;1
68cf2b19-749b-11df-b112-00215aee3ebe;Мощони;Мощоны;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621285404;3;1
68cf2b1a-749b-11df-b112-00215aee3ebe;Мощун;Мощун;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222482402;3;1
68cf2b1c-749b-11df-b112-00215aee3ebe;Мрин;Мрин;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423883501;3;1
68cf2b1d-749b-11df-b112-00215aee3ebe;Мрія;Мрия;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222488207;2;1
68cf2b1e-749b-11df-b112-00215aee3ebe;Мстишин;Мстышин;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722885105;3;1
68cf2b1f-749b-11df-b112-00215aee3ebe;Мудре;Мудрое;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421181602;3;1
68cf2b20-749b-11df-b112-00215aee3ebe;Мудриголови;Мудрыголовы;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821289002;3;1
68cf2b21-749b-11df-b112-00215aee3ebe;Мужилів;Мужилов;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124885001;3;1
68cf2b22-749b-11df-b112-00215aee3ebe;Мужиловичі;Мужиловичи;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625885701;3;1
68cf2b23-749b-11df-b112-00215aee3ebe;Мужієво;Мужиево;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120486401;3;0
68cf2b24-749b-11df-b112-00215aee3ebe;Музиківка;Музыковка;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520383501;2;1
68cf2b25-749b-11df-b112-00215aee3ebe;Музичі;Музычи;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222485501;3;1
68cf2b26-749b-11df-b112-00215aee3ebe;Мукані;Мукани;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623985202;3;1
68cf2b27-749b-11df-b112-00215aee3ebe;Мукачево;Мукачево;місто;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2110400000;1;0
68cf2b28-749b-11df-b112-00215aee3ebe;Мукіївка;Мукиевка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920681406;3;1
68cf2b29-749b-11df-b112-00215aee3ebe;Мукошин;Мукошин;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155124;3;1
56bdd270-749b-11df-b112-00215aee3ebe;Мукша Китайгородська (Жовтневе);Мукша Китайгородская (Жовтневое);село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822487403;2;0
68cf2b2a-749b-11df-b112-00215aee3ebe;Мульчиці;Мульчицы;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620886901;3;1
68cf2b2f-749b-11df-b112-00215aee3ebe;Мурав'ї;Муравьи;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423683207;3;1
68cf2b2c-749b-11df-b112-00215aee3ebe;Муравейник;Муравейник;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423688502;3;1
68cf2b2d-749b-11df-b112-00215aee3ebe;Муравейня;Муравейня;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921589003;3;1
68cf2b2e-749b-11df-b112-00215aee3ebe;Муравище;Муравище;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721886611;3;1
68cf2b31-749b-11df-b112-00215aee3ebe;Муравлинка;Муравлинка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324285503;3;1
68cf2b32-749b-11df-b112-00215aee3ebe;Муравлівка;Муравлевка;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122083801;3;1
68cf2b33-749b-11df-b112-00215aee3ebe;Муравня;Муравня;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825485202;3;1
68cf2b34-749b-11df-b112-00215aee3ebe;Муратове;Муратово;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655115;3;1
68cf2b36-749b-11df-b112-00215aee3ebe;Мурафа;Мурафа;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323584701;3;1
68cf2b37-749b-11df-b112-00215aee3ebe;Мурафа;Мурафа;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525381801;3;1
68cf2b38-749b-11df-b112-00215aee3ebe;Мурахівка;Мураховка;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821183001;3;1
68cf2b39-749b-11df-b112-00215aee3ebe;Мурашка;Мурашка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520283406;3;1
68cf2b3a-749b-11df-b112-00215aee3ebe;Мурзинці;Мурзинцы;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121284402;3;1
68cf2b3b-749b-11df-b112-00215aee3ebe;Мурівка;Муровка;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423086705;3;1
68cf2b3c-749b-11df-b112-00215aee3ebe;Мурована;Мурованая;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122984602;3;1
68cf2b3d-749b-11df-b112-00215aee3ebe;Муроване;Мурованое;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624883701;3;1
68cf2b3e-749b-11df-b112-00215aee3ebe;Муроване;Мурованое;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4623686902;2;0
68cf2b3f-749b-11df-b112-00215aee3ebe;Муроване;Мурованое;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625183501;3;1
68cf2b40-749b-11df-b112-00215aee3ebe;Муровані Курилівці;Мурованые Куриловцы;місто;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522855100;3;0
68cf2b42-749b-11df-b112-00215aee3ebe;Мусієнки;Мусиенки;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320888313;3;1
68cf2b43-749b-11df-b112-00215aee3ebe;Мусієнкове;Мусиенково;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222387203;3;1
68cf2b44-749b-11df-b112-00215aee3ebe;Мусіївка;Мусиевка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221882905;3;1
68cf2b45-749b-11df-b112-00215aee3ebe;Мусіївка;Мусиевка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222986003;3;1
68cf2b48-749b-11df-b112-00215aee3ebe;Мусіївка;Мусиевка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822080903;3;1
68cf2b49-749b-11df-b112-00215aee3ebe;Мусіївка;Мусиевка;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825281802;3;1
68cf2b4a-749b-11df-b112-00215aee3ebe;Мусіївка;Мусиевка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324883201;3;1
68cf2b4b-749b-11df-b112-00215aee3ebe;Мусійки;Мусийки;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320810102;3;1
68cf2b4c-749b-11df-b112-00215aee3ebe;Мусійки;Мусийки;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222082401;3;1
68cf2b4f-749b-11df-b112-00215aee3ebe;Мусорівці;Мусоровцы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122483904;3;1
68cf2b50-749b-11df-b112-00215aee3ebe;Мутвиця;Мутвица;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622283202;3;1
68cf2b51-749b-11df-b112-00215aee3ebe;Мутин;Мутин;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922686301;3;1
68cf2b52-749b-11df-b112-00215aee3ebe;Мутичів;Мутичев;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424482802;3;1
68cf2b53-749b-11df-b112-00215aee3ebe;Мухавка;Мухавка;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125585801;3;1
68cf2b54-749b-11df-b112-00215aee3ebe;Мухарів;Мухарев;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823985901;3;1
68cf2b55-749b-11df-b112-00215aee3ebe;Мухівці;Муховцы;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523085801;3;1
68cf2b56-749b-11df-b112-00215aee3ebe;Мухувата;Муховата;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521485606;3;1
68cf2b57-749b-11df-b112-00215aee3ebe;Мухувате;Муховатое;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923583408;3;1
68cf2b58-749b-11df-b112-00215aee3ebe;Мушина Гребля;Мушина Гребля;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323480905;3;1
68cf2b59-749b-11df-b112-00215aee3ebe;Мушкарів;Мушкаров;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120880903;3;1
68cf2b5a-749b-11df-b112-00215aee3ebe;Мушкатівка;Мушкатовка;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120884901;3;1
68cf2b5b-749b-11df-b112-00215aee3ebe;Мушкутинці;Мушкутинцы;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821810101;3;1
68cf2b5c-749b-11df-b112-00215aee3ebe;Мушні;Мушни;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625080403;3;1
68cf2b5d-749b-11df-b112-00215aee3ebe;Мушти;Мушты;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324282204;3;1
68cf2b5e-749b-11df-b112-00215aee3ebe;Мхи;Мхи;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424784505;3;1
68cf2b5f-749b-11df-b112-00215aee3ebe;Мшана;Мшана;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122689804;3;1
68cf2b60-749b-11df-b112-00215aee3ebe;Мшана;Мшана;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620985601;2;1
68cf2b61-749b-11df-b112-00215aee3ebe;Мшанець;Мшанец;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723355307;3;1
68cf2b62-749b-11df-b112-00215aee3ebe;Мшанець;Мшанец;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122687701;3;1
68cf2b63-749b-11df-b112-00215aee3ebe;Мшанець;Мшанец;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125085701;3;1
68cf2b64-749b-11df-b112-00215aee3ebe;Мшанець;Мшанец;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625183701;3;1
68cf2b65-749b-11df-b112-00215aee3ebe;Мшанець;Мшанец;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521483212;3;1
68cf2b66-749b-11df-b112-00215aee3ebe;Мшанець;Мшанец;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824483501;3;1
68cf2b67-749b-11df-b112-00215aee3ebe;Мюдівка;Мюдовка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520887003;3;1
68cf2b6e-749b-11df-b112-00215aee3ebe;Набережне;Набережное;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610115;3;1
68cf2b6f-749b-11df-b112-00215aee3ebe;Набережне;Набережное;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925081604;3;1
68cf2b70-749b-11df-b112-00215aee3ebe;Набережне;Набережное;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
68cf2b71-749b-11df-b112-00215aee3ebe;Набережне;Набережное;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222985505;3;1
68cf2b72-749b-11df-b112-00215aee3ebe;Набережне;Набережное;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121080303;3;0
68cf2b74-749b-11df-b112-00215aee3ebe;Набережне;Набережное;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621282402;3;1
68cf2b77-749b-11df-b112-00215aee3ebe;Набережне;Набережное;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424782504;3;1
68cf2b78-749b-11df-b112-00215aee3ebe;Набережне;Набережное;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621480712;3;1
68cf2b79-749b-11df-b112-00215aee3ebe;Набільське;Набильское;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422080705;3;1
68cf2b7a-749b-11df-b112-00215aee3ebe;Набоків;Набоков;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120310102;3;1
68cf2b7b-749b-11df-b112-00215aee3ebe;Набруска;Набруска;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723685103;3;1
68cf2b7c-749b-11df-b112-00215aee3ebe;Набутів;Набутов;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122585802;3;1
68cf2b7d-749b-11df-b112-00215aee3ebe;Наварія;Навария;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623610101;3;0
68cf2b7e-749b-11df-b112-00215aee3ebe;Навіз;Навоз;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724584401;3;1
68cf2b7f-749b-11df-b112-00215aee3ebe;Нагальне;Нагально;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323281005;3;1
68cf2b80-749b-11df-b112-00215aee3ebe;Нагачів;Нагачев;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625886001;3;1
68cf2b81-749b-11df-b112-00215aee3ebe;Нагірна;Нагорная;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120984601;3;1
68cf2b82-749b-11df-b112-00215aee3ebe;Нагірне;Нагорное;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324284206;3;1
68cf2b83-749b-11df-b112-00215aee3ebe;Нагірне;Нагорное;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322255101;3;1
68cf2b84-749b-11df-b112-00215aee3ebe;Нагірне;Нагорное;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324883504;3;1
68cf2b85-749b-11df-b112-00215aee3ebe;Нагірне;Нагорное;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123180403;3;1
68cf2b86-749b-11df-b112-00215aee3ebe;Нагірне;Нагорное;село;d00d3b62-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5124182401;3;1
68cf2b87-749b-11df-b112-00215aee3ebe;Нагірне;Нагорное;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321584009;3;1
68cf2b88-749b-11df-b112-00215aee3ebe;Нагірне;Нагорное;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622483904;3;1
68cf2b89-749b-11df-b112-00215aee3ebe;Нагірне;Нагорное;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624287203;3;1
68cf2b8a-749b-11df-b112-00215aee3ebe;Нагірне;Нагорное;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624587203;3;1
68cf2b8e-749b-11df-b112-00215aee3ebe;Нагірне;Нагорное;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525287603;3;1
68cf2b8f-749b-11df-b112-00215aee3ebe;Нагірне;Нагорное;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521983004;3;1
68cf2b90-749b-11df-b112-00215aee3ebe;Нагірне;Нагорное;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621684707;3;1
68cf2b92-749b-11df-b112-00215aee3ebe;Нагірнівка;Нагорновка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920689502;3;1
68cf2b93-749b-11df-b112-00215aee3ebe;Нагірці;Нагорцы;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787209;3;1
68cf2b94-749b-11df-b112-00215aee3ebe;Нагірянка;Нагорянка;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125585901;3;1
68cf2b95-749b-11df-b112-00215aee3ebe;Наглядівка;Наглядовка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522884603;3;1
68cf2b97-749b-11df-b112-00215aee3ebe;Нагоряни;Нагоряны;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122086602;3;1
68cf2b98-749b-11df-b112-00215aee3ebe;Нагоряни;Нагоряны;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322083202;3;1
68cf2b99-749b-11df-b112-00215aee3ebe;Нагоряни;Нагоряны;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623686605;3;1
68cf2b9a-749b-11df-b112-00215aee3ebe;Нагоряни;Нагоряны;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287902;3;1
68cf2b9b-749b-11df-b112-00215aee3ebe;Нагоряни;Нагоряны;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522683203;3;1
68cf2b9c-749b-11df-b112-00215aee3ebe;Нагоряни;Нагоряны;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822482402;2;1
68cf2b9d-749b-11df-b112-00215aee3ebe;Нагоряни;Нагоряны;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621688207;3;1
e0517ec6-db89-11df-9197-00215aee3ebe;Нагуєвичі;Нагуевычи;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621283901;3;1
68cf2bd4-749b-11df-b112-00215aee3ebe;Над'ярне;Надъярное;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786907;3;1
68cf2bd5-749b-11df-b112-00215aee3ebe;Над'ярне;Надъярное;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124582202;3;1
68cf2b9f-749b-11df-b112-00215aee3ebe;Надбузьке;Надбужское;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824280402;3;1
68cf2ba0-749b-11df-b112-00215aee3ebe;Надвисся;Надвисье;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122288503;3;1
68cf2ba1-749b-11df-b112-00215aee3ebe;Надвірна;Надвирна;місто;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2624010100;2;0
68cf2ba3-749b-11df-b112-00215aee3ebe;Наддністрянське;Надднестрянское;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522884401;3;1
68cf2ba4-749b-11df-b112-00215aee3ebe;Надежда;Надежда;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321084001;3;1
68cf2ba5-749b-11df-b112-00215aee3ebe;Надежда;Надежда;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124583201;3;1
68cf2ba7-749b-11df-b112-00215aee3ebe;Надеждине;Надеждино;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224881306;3;1
68cf2ba8-749b-11df-b112-00215aee3ebe;Надеждине;Надеждино;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324884506;3;1
68cf2ba9-749b-11df-b112-00215aee3ebe;Надеждине;Надеждино;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323980506;3;1
68cf2baa-749b-11df-b112-00215aee3ebe;Надеждине;Надеждино;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684501;3;1
68cf2bae-749b-11df-b112-00215aee3ebe;Надеждівка;Надеждовка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223286003;3;1
68cf2baf-749b-11df-b112-00215aee3ebe;Надеждівка;Надеждовка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224882504;3;1
68cf2bb0-749b-11df-b112-00215aee3ebe;Надеждівка;Надеждовка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221884301;3;1
68cf2bb1-749b-11df-b112-00215aee3ebe;Надеждівка;Надеждовка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821483402;3;1
68cf2bb2-749b-11df-b112-00215aee3ebe;Надеждівка;Надеждовка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323982501;3;1
68cf2bb3-749b-11df-b112-00215aee3ebe;Надеждівка;Надеждовка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320480505;3;1
68cf2bb4-749b-11df-b112-00215aee3ebe;Надеждівка;Надеждовка;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120483701;3;1
68cf2bb5-749b-11df-b112-00215aee3ebe;Надеждівка;Надеждовка;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520380701;3;1
68cf2bb8-749b-11df-b112-00215aee3ebe;Надеждівка;Надеждовка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;Умань;3;1
68cf2bb9-749b-11df-b112-00215aee3ebe;Надержинщина;Надержинщина;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083501;3;1
68cf2bba-749b-11df-b112-00215aee3ebe;Надиби;Надыбы;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625185102;3;1
68cf2bbb-749b-11df-b112-00215aee3ebe;Надинівка;Надиновка;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422085901;3;1
68cf2bbc-749b-11df-b112-00215aee3ebe;Надичі;Надычи;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622786401;3;1
68cf2bbd-749b-11df-b112-00215aee3ebe;Надишень;Надышень;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820382505;3;1
68cf2bbe-749b-11df-b112-00215aee3ebe;Надіїв;Надиев;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622083401;3;1
49bc5733-b149-11e6-a7f1-001ec93df11f;Надіївка;Надиевка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225055105;3;1
68cf2bc0-749b-11df-b112-00215aee3ebe;Надільне;Надельное;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621855604;3;1
68cf2bc1-749b-11df-b112-00215aee3ebe;Надітичі;Надетычи;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623086403;3;1
68cf2bc2-749b-11df-b112-00215aee3ebe;Надія;Надия;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055704;3;1
68cf2bc3-749b-11df-b112-00215aee3ebe;Надія;Надия;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225855108;3;1
68cf2bc4-749b-11df-b112-00215aee3ebe;Надія;Надия;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123985204;3;1
68cf2bc5-749b-11df-b112-00215aee3ebe;Надія;Надия;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322181302;3;1
7ad94f3d-749b-11df-b112-00215aee3ebe;Надія (Радгоспне);Надия (Радгоспное);село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221881303;3;1
68cf2bc7-749b-11df-b112-00215aee3ebe;Надлак;Надлак;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523682901;3;1
68cf2bc8-749b-11df-b112-00215aee3ebe;Надлиманське;Надлиманское;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123782201;3;1
68cf2bc9-749b-11df-b112-00215aee3ebe;Надорожна;Надорожная;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625610108;3;1
68cf2bca-749b-11df-b112-00215aee3ebe;Надорожнів;Надорожнев;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120483903;3;1
68cf2bcb-749b-11df-b112-00215aee3ebe;Надрічне;Надречное;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721480303;3;1
68cf2bcc-749b-11df-b112-00215aee3ebe;Надрічне;Надречное;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120484901;3;1
68cf2bcd-749b-11df-b112-00215aee3ebe;Надрічне;Надречное;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125085901;3;1
68cf2bce-749b-11df-b112-00215aee3ebe;Надрічне;Надречное;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124785601;3;1
68cf2bcf-749b-11df-b112-00215aee3ebe;Надросівка;Надросовка;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221688002;3;1
5757e370-c118-11e6-80f3-1c98ec135263;Надросівка;Надросивка;місто;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221688002;2;1
871625ab-749b-11df-b112-00215aee3ebe;Надросся (Чапаєвка);Надросся (Чапаевка);село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523487201;3;1
68cf2bd0-749b-11df-b112-00215aee3ebe;Надточаївка;Надточаевка;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125785601;3;1
68cf2bd1-749b-11df-b112-00215aee3ebe;Надточієве;Надточиево;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924783805;3;1
68cf2bd2-749b-11df-b112-00215aee3ebe;Надточії;Надточии;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157901;3;1
68cf2bd3-749b-11df-b112-00215aee3ebe;Надчиці;Надчицы;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623885509;3;1
68cf2bd6-749b-11df-b112-00215aee3ebe;Назавизів;Назавизов;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624084201;3;1
68cf2bd7-749b-11df-b112-00215aee3ebe;Назаренки;Назаренки;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322283008;3;1
68cf2bd8-749b-11df-b112-00215aee3ebe;Назаренки;Назаренки;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225683005;3;1
68cf2bd9-749b-11df-b112-00215aee3ebe;Назарівка;Назаровка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225255115;3;1
68cf2bdb-749b-11df-b112-00215aee3ebe;Назарівка;Назаровка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522584801;3;1
68cf2bdc-749b-11df-b112-00215aee3ebe;Назарівка;Назаровка;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322888902;3;1
68cf2bdd-749b-11df-b112-00215aee3ebe;Назірна;Назирная;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623282403;3;1
68cf2bde-749b-11df-b112-00215aee3ebe;Найденівка;Найденовка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322481004;3;1
68cf2be0-749b-11df-b112-00215aee3ebe;Наказне;Наказное;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
68cf2be1-749b-11df-b112-00215aee3ebe;Накваша;Накваша;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620383601;3;1
68cf2be2-749b-11df-b112-00215aee3ebe;Наконечне Друге;Наконечно Второе;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625886101;3;1
68cf2be3-749b-11df-b112-00215aee3ebe;Наконечне Перше;Наконечно Первое;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625886102;3;1
68cf2be4-749b-11df-b112-00215aee3ebe;Накот;Накот;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422282504;3;1
68cf2be5-749b-11df-b112-00215aee3ebe;Наливайка;Наливайка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521485801;3;1
68cf2be6-749b-11df-b112-00215aee3ebe;Наливайківка;Наливайковка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222785201;3;1
68cf2be7-749b-11df-b112-00215aee3ebe;Наливайкове;Наливайково;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121683905;3;1
68cf2be8-749b-11df-b112-00215aee3ebe;Налужжя;Налужье;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125088304;3;1
68cf2bea-749b-11df-b112-00215aee3ebe;Нанково;Нанково;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125386001;3;1
68cf2beb-749b-11df-b112-00215aee3ebe;Нападівка;Нападовка;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123880602;3;1
68cf2bec-749b-11df-b112-00215aee3ebe;Нападівка;Нападовка;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521685203;3;1
68cf2bed-749b-11df-b112-00215aee3ebe;Нападівка;Нападовка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522283801;3;1
68cf2bee-749b-11df-b112-00215aee3ebe;Нападівка;Нападовка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824280705;3;1
68cf2bef-749b-11df-b112-00215aee3ebe;Нараїв;Нараев;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120485101;3;1
68cf2bf0-749b-11df-b112-00215aee3ebe;Нараїв;Нараев;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621682607;3;1
68cf2bf1-749b-11df-b112-00215aee3ebe;Нараївка;Нараевка;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621255307;3;1
68cf2bf2-749b-11df-b112-00215aee3ebe;Нараївка;Нараевка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821783403;3;1
68cf2bf3-749b-11df-b112-00215aee3ebe;Нараївка;Нараевка;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520885003;3;1
68cf2bf4-749b-11df-b112-00215aee3ebe;Нараївка;Нараевка;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823981502;3;1
68cf2bf5-749b-11df-b112-00215aee3ebe;Нарбутівка;Нарбутовка;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921583705;3;1
68cf2bf6-749b-11df-b112-00215aee3ebe;Нариманівка;Наримановка;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525886703;3;1
68cf2bf7-749b-11df-b112-00215aee3ebe;Наріжжя;Нарожье;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324584601;3;1
a5388eb8-db65-11df-9197-00215aee3ebe;Наркевичі;Наркевичи;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820955700;3;1
68cf2bf8-749b-11df-b112-00215aee3ebe;Народичі;Народичи;місто;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755100;3;1
68cf2bf9-749b-11df-b112-00215aee3ebe;Нарцизівка;Нарцизовка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825481503;3;1
68cf2bfa-749b-11df-b112-00215aee3ebe;Нарцизівка;Нарцизовка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522283603;3;1
68cf2bfc-749b-11df-b112-00215aee3ebe;Настасів;Настасов;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125285701;2;1
68cf2bfd-749b-11df-b112-00215aee3ebe;Настасівка;Настасовка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225485504;3;1
68cf2bfe-749b-11df-b112-00215aee3ebe;Настасівка;Настасовка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;;3;1
68cf2bff-749b-11df-b112-00215aee3ebe;Настасівка;Настасовка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684506;3;1
68cf2c00-749b-11df-b112-00215aee3ebe;Настасівка;Настасовка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324885003;3;1
68cf2c01-749b-11df-b112-00215aee3ebe;Настасіївка;Настасиевка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123582801;3;1
68cf2c02-749b-11df-b112-00215aee3ebe;Настасіївка;Настасиевка;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521082806;3;1
68cf2c03-749b-11df-b112-00215aee3ebe;Настахівське;Настаховское;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;;3;1
68cf2c04-749b-11df-b112-00215aee3ebe;Насташине;Насташино;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621285701;3;1
68cf2c05-749b-11df-b112-00215aee3ebe;Насташка;Насташка;село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223783501;3;1
68cf2c06-749b-11df-b112-00215aee3ebe;Настеньківка;Настеньковка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323582207;3;1
68cf2c07-749b-11df-b112-00215aee3ebe;Настине;Настино;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520582004;3;1
68cf2c09-749b-11df-b112-00215aee3ebe;Наталенки;Наталенки;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324585109;3;1
68cf2c0a-749b-11df-b112-00215aee3ebe;Наталин;Наталин;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720882803;3;1
68cf2c0b-749b-11df-b112-00215aee3ebe;Наталине;Наталино;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323383001;3;1
68cf2c0c-749b-11df-b112-00215aee3ebe;Наталине;Наталино;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;6521884002;3;1
68cf2c0d-749b-11df-b112-00215aee3ebe;Наталівка;Наталовка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225083511;3;1
68cf2c0e-749b-11df-b112-00215aee3ebe;Наталівка;Наталовка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222688805;3;1
68cf2c0f-749b-11df-b112-00215aee3ebe;Наталівка;Наталовка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322186801;3;1
68cf2c12-749b-11df-b112-00215aee3ebe;Наталівка;Наталовка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824083701;3;1
68cf2c13-749b-11df-b112-00215aee3ebe;Наталівка;Наталовка;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320883201;3;1
68cf2c14-749b-11df-b112-00215aee3ebe;Наталівка;Наталовка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324887717;3;1
68cf2c15-749b-11df-b112-00215aee3ebe;Наталія;Наталия;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820655404;3;1
16ead6da-7512-11e2-85b4-003048d2b473;Наті;Нати;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;;3;1
68cf2c17-749b-11df-b112-00215aee3ebe;Натягайлівка;Натягайловка;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223385302;3;1
68cf2c19-749b-11df-b112-00215aee3ebe;Науменка;Науменко;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825682402;3;1
68cf2c1a-749b-11df-b112-00215aee3ebe;Науменки;Науменки;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755138;3;1
68cf2c1b-749b-11df-b112-00215aee3ebe;Наумівка;Наумовка;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922355601;3;1
68cf2c1d-749b-11df-b112-00215aee3ebe;Наумівка;Наумовка;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422485501;3;1
68cf2c1e-749b-11df-b112-00215aee3ebe;Наумівка;Наумовка;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420685403;3;1
68cf2c1f-749b-11df-b112-00215aee3ebe;Наумівське;Наумовское;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423385902;3;1
312fddc2-b9ea-11e0-9aaa-003048d2b473;Научний;Научный;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
68cf2c21-749b-11df-b112-00215aee3ebe;Нафтулівка;Нафтуловка;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825081503;3;1
68cf2c24-749b-11df-b112-00215aee3ebe;Небелиця;Небелица;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222785501;3;1
68cf2c25-749b-11df-b112-00215aee3ebe;Небелівка;Небелевка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523683201;3;1
68cf2c26-749b-11df-b112-00215aee3ebe;Небилів;Небылов;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624883601;3;1
68cf2c27-749b-11df-b112-00215aee3ebe;Небіж;Небеж;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821183401;3;1
68cf2c28-749b-11df-b112-00215aee3ebe;Небіжка;Небежка;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721882705;3;1
68cf2c29-749b-11df-b112-00215aee3ebe;Неборівка;Неборовка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825483004;3;1
68cf2c2a-749b-11df-b112-00215aee3ebe;Небрат;Небрат;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221086301;3;1
68cf2c2b-749b-11df-b112-00215aee3ebe;Невгоди;Невгоды;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824285001;3;1
68cf2c2d-749b-11df-b112-00215aee3ebe;Неветленфолу;Неветленфолу;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121282401;3;1
68cf2c2e-749b-11df-b112-00215aee3ebe;Невирівка;Невировка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821184008;3;1
68cf2c2f-749b-11df-b112-00215aee3ebe;Невицьке;Невицкое;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2124884501;3;1
68cf2c30-749b-11df-b112-00215aee3ebe;Невір;Невир;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723182602;3;1
68cf2c31-749b-11df-b112-00215aee3ebe;Невірків;Невирков;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623085601;3;1
68cf2c32-749b-11df-b112-00215aee3ebe;Невкля;Невкля;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421486407;3;1
68cf2c35-749b-11df-b112-00215aee3ebe;Негівці;Неговцы;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622880810;3;1
68cf2c36-749b-11df-b112-00215aee3ebe;Неграші;Неграши;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222485502;3;1
68cf2c37-749b-11df-b112-00215aee3ebe;Негребівка;Негребовка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825088302;3;1
68cf2c38-749b-11df-b112-00215aee3ebe;Негринці;Негринцы;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323082002;3;1
68cf2c39-749b-11df-b112-00215aee3ebe;Негрове;Негрово;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124583206;3;1
68cf2c3b-749b-11df-b112-00215aee3ebe;Негровець;Негровец;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122484001;3;1
68cf2c3a-749b-11df-b112-00215aee3ebe;Негрово;Негрово;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121986201;3;1
68cf2c3c-749b-11df-b112-00215aee3ebe;Недайвода;Недайвода;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221884501;3;1
68cf2c3d-749b-11df-b112-00215aee3ebe;Неданчичі;Неданчичи;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424485601;3;1
68cf2c3e-749b-11df-b112-00215aee3ebe;Недашки;Недашки;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823485801;3;1
68cf2c3f-749b-11df-b112-00215aee3ebe;Недашківка;Недашковка;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755138;3;1
68cf2c40-749b-11df-b112-00215aee3ebe;Неділиська;Неделиска;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623388003;3;1
68cf2c41-749b-11df-b112-00215aee3ebe;Неділище;Неделище;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821784001;3;1
68cf2c42-749b-11df-b112-00215aee3ebe;Неділкове;Неделково;село;6b07f835-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5124382601;3;1
68cf2c43-749b-11df-b112-00215aee3ebe;Недільна;Недельня;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625187202;3;1
68cf2c44-749b-11df-b112-00215aee3ebe;Недобоївці;Недобоевцы;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7325085601;3;1
68cf2c45-749b-11df-b112-00215aee3ebe;Недогарки;Недогарки;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322482801;3;1
68cf2c46-749b-11df-b112-00215aee3ebe;Недогарки;Недогарки;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520385201;3;1
68cf2c47-749b-11df-b112-00215aee3ebe;Недра;Недра;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220285101;3;1
68cf2c48-749b-11df-b112-00215aee3ebe;Недригайлів;Недригайлов;місто;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923555100;3;1
68cf2c4b-749b-11df-b112-00215aee3ebe;Нежухів;Нежухов;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625384801;3;1
68cf2c4c-749b-11df-b112-00215aee3ebe;Незабудине;Незабудино;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225083513;3;1
68cf2c4e-749b-11df-b112-00215aee3ebe;Незаможник;Незаможник;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823683505;3;1
68cf2c51-749b-11df-b112-00215aee3ebe;Незвисько;Незвиско;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621682301;3;1
68cf2c52-749b-11df-b112-00215aee3ebe;Незвір;Незвир;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724583503;3;1
68cf2c53-749b-11df-b112-00215aee3ebe;Незнанів;Незнанов;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622184601;3;1
68cf2c54-749b-11df-b112-00215aee3ebe;Нейкове;Нейково;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283611;3;1
68cf2c55-749b-11df-b112-00215aee3ebe;Некрасове;Некрасово;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921586005;3;1
68cf2c58-749b-11df-b112-00215aee3ebe;Некрасове;Некрасово;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520684203;3;1
68cf2c5b-749b-11df-b112-00215aee3ebe;Некраші;Некраши;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825688202;3;1
68cf2c5d-749b-11df-b112-00215aee3ebe;Нелени;Нелены;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923580504;3;1
68cf2c5e-749b-11df-b112-00215aee3ebe;Нелипівці;Нелиповцы;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322086801;3;1
68cf2c5f-749b-11df-b112-00215aee3ebe;Неліпино;Нелипино;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124083501;3;1
68cf2c62-749b-11df-b112-00215aee3ebe;Нелюбівка;Нелюбовка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321084101;3;1
68cf2c7c-749b-11df-b112-00215aee3ebe;Нем'яч;Немяч;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620386204;3;1
68cf2c64-749b-11df-b112-00215aee3ebe;Неменка;Неменка;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521210106;3;1
68cf2c65-749b-11df-b112-00215aee3ebe;Немерче;Немерче;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522884605;3;1
68cf2c66-749b-11df-b112-00215aee3ebe;Немерче вокзал;Немерче Вокзал;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;;3;1
68cf2c67-749b-11df-b112-00215aee3ebe;Немилів;Немилов;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623982501;3;1
68cf2c68-749b-11df-b112-00215aee3ebe;Немильня;Немыльня;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824081802;3;1
68cf2c69-749b-11df-b112-00215aee3ebe;Немир;Немир;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724584501;3;1
68cf2c6b-749b-11df-b112-00215aee3ebe;Немиринці;Немиринцы;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825284901;3;1
68cf2c6c-749b-11df-b112-00215aee3ebe;Немиринці;Немиринцы;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521485806;3;1
68cf2c6d-749b-11df-b112-00215aee3ebe;Немиринці;Немиринцы;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824781303;3;1
68cf2c6e-749b-11df-b112-00215aee3ebe;Немиринці;Немиринцы;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824286201;3;1
68cf2c6f-749b-11df-b112-00215aee3ebe;Немиринці;Немиринцы;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821285201;3;1
68cf2c70-749b-11df-b112-00215aee3ebe;Немирів;Немиров;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;7d1766f6-b6ff-11eb-80d4-000c29800ae7;4625855900;3;0
68cf2c71-749b-11df-b112-00215aee3ebe;Немирів;Немиров;місто;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523010100;2;0
68cf2c72-749b-11df-b112-00215aee3ebe;Немирів вокзал;Немиров Вокзал;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
68cf2c73-749b-11df-b112-00215aee3ebe;Немирівка;Немировка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822385204;3;1
68cf2c74-749b-11df-b112-00215aee3ebe;Немирівка;Немировка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824287705;3;1
68cf2c6a-749b-11df-b112-00215aee3ebe;Немирівка;Немировка;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625886001;3;1
68cf2c75-749b-11df-b112-00215aee3ebe;Немирівське;Немировское;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290017;3;1
68cf2c76-749b-11df-b112-00215aee3ebe;Немичинці;Немичинцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825081504;3;1
68cf2c77-749b-11df-b112-00215aee3ebe;Немішаєве;Немешаево;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221055900;2;0
68cf2c78-749b-11df-b112-00215aee3ebe;Немія;Немия;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522684800;2;1
68cf2c79-749b-11df-b112-00215aee3ebe;Немовичі;Немовичи;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625485404;3;1
68cf2c7a-749b-11df-b112-00215aee3ebe;Неморож;Неморож;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121284401;3;1
68cf2c7b-749b-11df-b112-00215aee3ebe;Немудруї;Немудруи;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923584404;3;1
68cf2c7d-749b-11df-b112-00215aee3ebe;Ненадиха;Ненадиха;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224610109;2;1
68cf2c7e-749b-11df-b112-00215aee3ebe;Ненадівка;Ненадовка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924184705;3;1
68cf2c7f-749b-11df-b112-00215aee3ebe;Ненадівка;Ненадовка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655116;3;1
68cf2c80-749b-11df-b112-00215aee3ebe;Ненаситець;Ненасытец;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224881207;3;1
68cf2c81-749b-11df-b112-00215aee3ebe;Неньковичі;Неньковичи;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622283601;3;1
68cf2c82-749b-11df-b112-00215aee3ebe;Неопалимівка;Неопалимовка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522586602;3;1
68cf2c83-749b-11df-b112-00215aee3ebe;Непедівка;Непедовка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521455505;3;1
17af7b32-ddb9-11df-9197-00215aee3ebe;Непереможне;Непереможное;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225255116;3;1
68cf2c84-749b-11df-b112-00215aee3ebe;Непізнаничі;Непознаничи;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821782204;3;1
68cf2c85-749b-11df-b112-00215aee3ebe;Неплатине;Неплатино;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920385807;3;1
68cf2c86-749b-11df-b112-00215aee3ebe;Неплюєве;Неплюево;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925655104;3;1
68cf2c87-749b-11df-b112-00215aee3ebe;Неполоківці;Неполоковцы;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322555700;3;1
68cf2c88-749b-11df-b112-00215aee3ebe;Непоротове;Непоротово;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324086005;3;1
68cf2c89-749b-11df-b112-00215aee3ebe;Нераж;Нераж;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825683603;3;1
68cf2c8a-749b-11df-b112-00215aee3ebe;Нересниця;Нересница;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124484801;3;1
68cf2c8b-749b-11df-b112-00215aee3ebe;Неровнине;Неровнино;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922681505;3;1
68cf2c8c-749b-11df-b112-00215aee3ebe;Нерубаївка;Нерубаевка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524086002;3;1
68cf2c8d-749b-11df-b112-00215aee3ebe;Нерубайка;Нерубайка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523683601;3;1
68cf2c8e-749b-11df-b112-00215aee3ebe;Нерубайка;Нерубайка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3523683601;3;1
68cf2c8f-749b-11df-b112-00215aee3ebe;Нерубайське;Нерубайское;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121084201;2;0
68cf2c90-749b-11df-b112-00215aee3ebe;Нерудсталь;Нерудсталь;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224581207;3;1
68cf2c91-749b-11df-b112-00215aee3ebe;Нерушай;Нерушай;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125083401;3;1
68cf2c92-749b-11df-b112-00215aee3ebe;Несваткове;Несватково;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520584701;3;1
68cf2c93-749b-11df-b112-00215aee3ebe;Несвіч;Несвич;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722884001;3;1
68cf2c94-749b-11df-b112-00215aee3ebe;Несвоя;Несвоя;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323085101;3;1
68cf2c95-749b-11df-b112-00215aee3ebe;Несено-Іржавець;Несено-Иржавец;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323682606;3;1
68cf2c96-749b-11df-b112-00215aee3ebe;Нескучне;Нескучное;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920683503;3;1
68cf2c97-749b-11df-b112-00215aee3ebe;Нескучне;Нескучное;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325180503;3;1
68cf2c99-749b-11df-b112-00215aee3ebe;Неслухів;Неслухов;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622184701;3;1
68cf2c9a-749b-11df-b112-00215aee3ebe;Несолонь;Несолонь;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824083801;3;1
68cf2c9b-749b-11df-b112-00215aee3ebe;Нестаничі;Нестаничи;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623987203;3;1
68cf2c9c-749b-11df-b112-00215aee3ebe;Нестеліївка;Нестелиевка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323983503;3;1
68cf2c9d-749b-11df-b112-00215aee3ebe;Нестерварка;Нестерварка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524382907;3;1
68cf2c9e-749b-11df-b112-00215aee3ebe;Нестеренки;Нестеренки;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083701;3;1
68cf2c9f-749b-11df-b112-00215aee3ebe;Нестеренки;Нестеренки;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923285606;3;1
68cf2ca0-749b-11df-b112-00215aee3ebe;Нестеренки;Нестеренки;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157605;3;1
68cf2ca1-749b-11df-b112-00215aee3ebe;Нестеренки;Нестеренки;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321284013;3;1
68cf2ca2-749b-11df-b112-00215aee3ebe;Нестерівка;Нестеровка;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123186001;3;1
68cf2ca3-749b-11df-b112-00215aee3ebe;Нестерівці;Нестеровцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122686301;3;1
68cf2ca4-749b-11df-b112-00215aee3ebe;Нестерівці;Нестеровцы;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821887101;3;1
68cf2ca6-749b-11df-b112-00215aee3ebe;Нестерове;Нестерово;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123183404;3;1
68cf2ca7-749b-11df-b112-00215aee3ebe;Нестерянка;Нестерянка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323910103;3;1
68cf2ca8-749b-11df-b112-00215aee3ebe;Нестоїта;Нестоита;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122985101;3;1
68cf2ca9-749b-11df-b112-00215aee3ebe;Несторівка;Несторовка;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;;3;1
68cf2caa-749b-11df-b112-00215aee3ebe;Нестюки;Нестюки;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621880803;3;1
68cf2cac-749b-11df-b112-00215aee3ebe;Нетеребка;Нетеребка;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122585801;3;1
68cf2cad-749b-11df-b112-00215aee3ebe;Нетерпинці;Нетерпинцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122680403;3;1
68cf2cae-749b-11df-b112-00215aee3ebe;Нетесівка;Нетесовка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225683006;3;1
68cf2caf-749b-11df-b112-00215aee3ebe;Нетечинці;Нетечинцы;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820685001;3;1
68cf2cb0-749b-11df-b112-00215aee3ebe;Нетішин;Нетешин;місто;5394b33e-41be-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6810500000;1;0
68cf2cb1-749b-11df-b112-00215aee3ebe;Нетратівка;Нетратовка;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325182103;3;1
68cf2cb2-749b-11df-b112-00215aee3ebe;Нетреба;Нетреба;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625081702;3;1
68cf2cb3-749b-11df-b112-00215aee3ebe;Нетреба;Нетреба;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620882202;3;1
68cf2cb4-749b-11df-b112-00215aee3ebe;Нетребівка;Нетребовка;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523955109;3;1
68cf2cb5-749b-11df-b112-00215aee3ebe;Нетягівщина;Нетяговщина;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424182103;3;1
68cf2cb6-749b-11df-b112-00215aee3ebe;Нетяжине;Нетяжино;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424185501;3;1
68cf2cb7-749b-11df-b112-00215aee3ebe;Неудачне;Неудачное;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823682205;3;1
68cf2cb9-749b-11df-b112-00215aee3ebe;Нефедівці;Нефедовцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822485501;3;1
68cf2cba-749b-11df-b112-00215aee3ebe;Нехаївка;Нехаевка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922084102;3;1
68cf2cbb-749b-11df-b112-00215aee3ebe;Нехаївка;Нехаевка;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422285001;3;1
68cf2cbc-749b-11df-b112-00215aee3ebe;Нехайки;Нехайки;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120687401;3;1
68cf2cbd-749b-11df-b112-00215aee3ebe;Нехворощ;Нехворощ;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820387401;3;1
68cf2cbe-749b-11df-b112-00215aee3ebe;Нехворощ;Нехворощ;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122587103;3;1
68cf2cbf-749b-11df-b112-00215aee3ebe;Нехвороща;Нехвороща;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323484201;3;1
68cf2cc0-749b-11df-b112-00215aee3ebe;Нехвороща;Нехвороща;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720586003;3;1
68cf2cc1-749b-11df-b112-00215aee3ebe;Нехристівка;Нехристовка;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325185405;3;1
68cf2cc2-749b-11df-b112-00215aee3ebe;Нечаєве;Нечаево;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125786001;3;1
68cf2cc3-749b-11df-b112-00215aee3ebe;Нечаїв;Нечаев;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420382003;3;1
6ed816d4-749b-11df-b112-00215aee3ebe;Нечаївка;Нечаевка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920982403;3;1
6ed816d5-749b-11df-b112-00215aee3ebe;Нечаївка;Нечаевка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224281206;3;1
6ed816d6-749b-11df-b112-00215aee3ebe;Нечаївка;Нечаевка;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124982002;3;1
6ed816d7-749b-11df-b112-00215aee3ebe;Нечаївка;Нечаевка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522883801;3;1
6ed816d8-749b-11df-b112-00215aee3ebe;Нечаївка;Нечаевка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822188103;3;1
6ed816d9-749b-11df-b112-00215aee3ebe;Нечаївське;Нечаевское;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922084503;3;1
6ed816da-749b-11df-b112-00215aee3ebe;Нечаяне;Нечаяное;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824282701;3;1
7ad94fa3-749b-11df-b112-00215aee3ebe;Нечуївка (Радянське);Нечуивка (Радянское);село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525684302;3;1
6ed816de-749b-11df-b112-00215aee3ebe;Нещерів;Нещеров;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223186201;3;1
6ed816e1-749b-11df-b112-00215aee3ebe;Нива Трудова;Нива Трудовая;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1220387701;3;1
6ed816e2-749b-11df-b112-00215aee3ebe;Нивецьк;Нивецк;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621885601;3;1
6ed816e3-749b-11df-b112-00215aee3ebe;Ниви;Нивы;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787614;3;1
6ed816e4-749b-11df-b112-00215aee3ebe;Ниви;Нивы;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620610101;3;1
6ed816e5-749b-11df-b112-00215aee3ebe;Ниви-Губинські;Нивы-Губинские;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720881507;3;1
6ed816e6-749b-11df-b112-00215aee3ebe;Ниви-Золочівські;Нивы-Золочовские;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621482902;3;1
6ed816e7-749b-11df-b112-00215aee3ebe;Нивиці;Нивицы;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623982801;3;1
6ed816e8-749b-11df-b112-00215aee3ebe;Нивки;Нивки;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824281306;3;1
6ed816e9-749b-11df-b112-00215aee3ebe;Нивки;Нивки;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386403;3;1
6ed816ea-749b-11df-b112-00215aee3ebe;Нивна;Нивна;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821483001;3;1
6ed816eb-749b-11df-b112-00215aee3ebe;Нивочин;Нивочин;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620488605;3;1
6ed816ec-749b-11df-b112-00215aee3ebe;Нивра;Нивра;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
6ed816ed-749b-11df-b112-00215aee3ebe;Ниговищі;Ниговищи;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622284803;3;1
6ed816ee-749b-11df-b112-00215aee3ebe;Нижанковичі;Нижанковичи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625155400;3;1
6ed816ef-749b-11df-b112-00215aee3ebe;Нижбірок;Нижборок;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121684801;3;1
6ed816f1-749b-11df-b112-00215aee3ebe;Нижнє;Нижнее;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624284003;3;1
6ed816f2-749b-11df-b112-00215aee3ebe;Нижнє;Нижнее;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625581903;3;1
6ed816f4-749b-11df-b112-00215aee3ebe;Нижнє;Нижнее;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821587401;3;1
6ed816f0-749b-11df-b112-00215aee3ebe;Нижнє Болотне;Нижнее Болотное;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121986402;3;1
6ed816f5-749b-11df-b112-00215aee3ebe;Нижнє Висоцьке;Нижнее Высоцкое;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625585901;3;1
6ed816f7-749b-11df-b112-00215aee3ebe;Нижнє Гусне;Нижнее Гусне;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625581602;3;1
cc469e4e-d0a6-11e5-88dd-001ec93df11f;Нижнє Піщане;Нижнє Піщане;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;;3;1
6ed816fa-749b-11df-b112-00215aee3ebe;Нижнє Селище;Нижнее Селище;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125386601;3;1
6ed816f6-749b-11df-b112-00215aee3ebe;Нижнє Синьовидне;Нижнее Синевидное;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624584501;3;1
6ed81737-749b-11df-b112-00215aee3ebe;Нижнє Солотвино;Нижнее Солотвино;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124881802;3;1
6ed816fd-749b-11df-b112-00215aee3ebe;Нижні Вільшани;Нижние Ольшаны;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086206;3;1
6ed816fe-749b-11df-b112-00215aee3ebe;Нижні Ворота;Нижние Ворота;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121584201;3;0
6ed816ff-749b-11df-b112-00215aee3ebe;Нижні Гаї;Нижние Гаи;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621282101;3;1
6ed81700-749b-11df-b112-00215aee3ebe;Нижні Головлі;Нижние Головли;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823982102;3;1
6ed81701-749b-11df-b112-00215aee3ebe;Нижні Млини;Нижние Млыны;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324087704;3;1
6ed81704-749b-11df-b112-00215aee3ebe;Нижні Петрівці;Нижние Петровцы;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324587001;3;1
6ed81705-749b-11df-b112-00215aee3ebe;Нижні Ремети;Нижние Реметы;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120486801;3;1
6ed81706-749b-11df-b112-00215aee3ebe;Нижні Рівні;Нижние Ровни;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325484205;3;1
6ed81707-749b-11df-b112-00215aee3ebe;Нижні Синівці;Нижние Синевцы;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321085702;3;1
6ed81708-749b-11df-b112-00215aee3ebe;Нижні Станівці;Нижние Становцы;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322586001;3;1
6ed81709-749b-11df-b112-00215aee3ebe;Нижні Яреськи;Нижние Яреськи;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325786004;3;1
6ed8170a-749b-11df-b112-00215aee3ebe;Нижнів;Нижнев;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625685601;3;1
6ed8170b-749b-11df-b112-00215aee3ebe;Нижній Березів;Нижний Березов;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623684201;3;1
6ed8170c-749b-11df-b112-00215aee3ebe;Нижній Бистрий;Нижний Быстрый;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125386201;3;1
6ed8170d-749b-11df-b112-00215aee3ebe;Нижній Бишкин;Нижний Бышкин;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321783001;3;1
6ed8170e-749b-11df-b112-00215aee3ebe;Нижній Булатець;Нижний Булатец;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322881404;3;1
6ed8170f-749b-11df-b112-00215aee3ebe;Нижній Бурлук;Нижний Бурлук;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325785001;3;1
6ed81710-749b-11df-b112-00215aee3ebe;Нижній Вербіж;Нижний Вербиж;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623284801;2;1
6ed81711-749b-11df-b112-00215aee3ebe;Нижній Дубовець;Нижний Дубовец;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124455602;3;1
6ed81712-749b-11df-b112-00215aee3ebe;Нижній іржавець;Нижний Иржавец;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323683605;3;1
6ed81713-749b-11df-b112-00215aee3ebe;Нижній Коропець;Нижний Коропець;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122785601;3;1
6ed81714-749b-11df-b112-00215aee3ebe;Нижній Куяльник;Нижний Куяльник;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121881207;3;1
6ed81718-749b-11df-b112-00215aee3ebe;Нижній Струтинь;Нижний Струтинь;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624883901;3;1
6ed81719-749b-11df-b112-00215aee3ebe;Нижній Студений;Нижний Студеный;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122484401;3;1
6ed8171c-749b-11df-b112-00215aee3ebe;Нижній Турів;Нижний Туров;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625586201;3;1
6ed8171d-749b-11df-b112-00215aee3ebe;Нижній Яловець;Нижний Яловец;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323586004;3;1
73e94bf8-ddc1-11df-9197-00215aee3ebe;Нижня Апша;Нижняя Апша;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124482401;2;0
6ed81721-749b-11df-b112-00215aee3ebe;Нижня Балка;Нижняя Балка;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623680802;3;1
6ed81722-749b-11df-b112-00215aee3ebe;Нижня Білка;Нижняя Белка;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623680802;3;1
6ed81723-749b-11df-b112-00215aee3ebe;Нижня Будаківка;Нижняя Будаковка;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322681105;3;1
6ed81724-749b-11df-b112-00215aee3ebe;Нижня Велесниця;Нижняя Велесница;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623280803;3;1
6ed81726-749b-11df-b112-00215aee3ebe;Нижня Вовча;Нижняя Вовча;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625180403;3;1
6ed81727-749b-11df-b112-00215aee3ebe;Нижня Водяна;Нижняя Водяная;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;;3;1
6ed8172b-749b-11df-b112-00215aee3ebe;Нижня Жужманівка;Нижняя Жужмановка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084907;3;1
fe1da5e4-fecf-11e4-ba66-003048d2b473;Нижня Журавка;Нижняя Журавка;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321084002;3;1
6ed8172c-749b-11df-b112-00215aee3ebe;Нижня Краснопавлівка;Нижняя Краснопавловка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323988504;3;1
6ed8172f-749b-11df-b112-00215aee3ebe;Нижня Ланна;Нижняя Ланная;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321682507;3;1
6ed81730-749b-11df-b112-00215aee3ebe;Нижня Липиця;Нижняя Липица;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624484101;3;1
6ed81731-749b-11df-b112-00215aee3ebe;Нижня Лукавиця;Нижняя Луковица;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625381604;3;1
6ed81732-749b-11df-b112-00215aee3ebe;Нижня Мануйлівка;Нижняя Мануйловка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322083003;3;1
6ed81733-749b-11df-b112-00215aee3ebe;Нижня Озеряна;Нижняя Озеряна;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325158804;3;1
6ed8171e-749b-11df-b112-00215aee3ebe;Нижня Рожанка;Нижняя Рожанка;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624584301;3;1
6ed81734-749b-11df-b112-00215aee3ebe;Нижня Рудня;Нижняя Рудня;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287005;3;1
6ed81735-749b-11df-b112-00215aee3ebe;Нижня Сагарівка;Нижняя Сагаровка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920987004;3;1
6ed81736-749b-11df-b112-00215aee3ebe;Нижня Сироватка;Нижняя Сыроватка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924785001;3;1
6ed81738-749b-11df-b112-00215aee3ebe;Нижня Стинава;Нижняя Стинава;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625385001;3;1
6ed8171f-749b-11df-b112-00215aee3ebe;Нижня Хортиця;Нижняя Хортица;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322183508;3;1
6ed81720-749b-11df-b112-00215aee3ebe;Нижня Яблунька;Нижняя Яблонька;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625586501;3;1
6ed81739-749b-11df-b112-00215aee3ebe;Нижнянка;Нижнянка;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225980505;3;1
6ed81742-749b-11df-b112-00215aee3ebe;Нижча Дубечня;Низшая Дубечня;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221885601;3;1
6ed81743-749b-11df-b112-00215aee3ebe;Нижча Кропивна;Низшая Крапивна;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523086402;3;1
6ed81744-749b-11df-b112-00215aee3ebe;Нижче Солоне;Нижнее Соленое;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321081002;3;1
6ed81745-749b-11df-b112-00215aee3ebe;Нижчий Ольчедаїв;Низший Ольчедаев;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522684003;3;1
6ed81747-749b-11df-b112-00215aee3ebe;Нижчі Верещаки;Низшие Верещаки;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520586502;3;1
6ed81748-749b-11df-b112-00215aee3ebe;Нижчі Вовківці;Низшие Волковцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825087202;3;1
6ed81746-749b-11df-b112-00215aee3ebe;Нижчі Луб'янки;Низшие Лубянки;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122486701;3;1
6ed81749-749b-11df-b112-00215aee3ebe;Низи;Низы;село;d00d3b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;;3;1
6ed8174a-749b-11df-b112-00215aee3ebe;Низи;Низы;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924755500;3;1
6ed8174b-749b-11df-b112-00215aee3ebe;Низи;Низы;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624880903;3;1
6ed8174c-749b-11df-b112-00215aee3ebe;Низи;Низы;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423381905;3;1
6ed8174f-749b-11df-b112-00215aee3ebe;Низівка;Низовка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324284706;3;1
6ed81751-749b-11df-b112-00215aee3ebe;Низкиничі;Низкиничи;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721181203;3;1
6ed81752-749b-11df-b112-00215aee3ebe;Низківка;Низковка;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425883501;3;1
6ed81753-749b-11df-b112-00215aee3ebe;Низова Яковенщина;Низовая Яковенщина;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755139;3;1
6ed81755-749b-11df-b112-00215aee3ebe;Низове;Низовое;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523681205;3;1
6ed81756-749b-11df-b112-00215aee3ebe;Низькі Цевеличі;Низкие Цевеличи;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722481805;3;1
6ed81757-749b-11df-b112-00215aee3ebe;Низьколизи;Низколизы;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124286802;3;1
6ed8175a-749b-11df-b112-00215aee3ebe;Никифорівка;Никифоровка;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521983005;3;1
6ed8175b-749b-11df-b112-00215aee3ebe;Никифорівці;Никифоровцы;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523010109;3;1
6ed8175d-749b-11df-b112-00215aee3ebe;Никловичі;Никловичи;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624285601;3;1
6ed8175e-749b-11df-b112-00215aee3ebe;Никомаврівка;Никомавровка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125485204;3;1
6ed8175f-749b-11df-b112-00215aee3ebe;Никонівка;Никоновка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820884201;3;1
6ed81760-749b-11df-b112-00215aee3ebe;Никонівка;Никоновка;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425155102;3;1
6ed81761-749b-11df-b112-00215aee3ebe;Никонковичі;Никонковичи;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623684605;3;1
6ed81762-749b-11df-b112-00215aee3ebe;Никонорівка;Никоноровка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325483706;3;1
6ed81764-749b-11df-b112-00215aee3ebe;Никонорівка;Никоноровка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520881604;3;1
6ed81765-749b-11df-b112-00215aee3ebe;Никонці;Никонцы;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786805;3;1
6ed81766-749b-11df-b112-00215aee3ebe;Ниновичі;Ниновичи;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624885703;3;1
6ed81767-749b-11df-b112-00215aee3ebe;Нири;Ныры;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725582202;3;1
6ed81768-749b-11df-b112-00215aee3ebe;Нирків;Нырков;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122086601;3;1
6ed8176a-749b-11df-b112-00215aee3ebe;Нитине;Нитино;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821784602;3;1
6ed8176b-749b-11df-b112-00215aee3ebe;Ницаха;Ницаха;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925086201;3;1
6ed8176c-749b-11df-b112-00215aee3ebe;Ниці;Ницы;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725085503;3;1
6ed8176d-749b-11df-b112-00215aee3ebe;Ничипорівка;Ничипоровка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225584201;3;1
6ed8176e-749b-11df-b112-00215aee3ebe;Нишівці;Нишивцы;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522886202;3;1
6ed8176f-749b-11df-b112-00215aee3ebe;Нище;Нище;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122681304;3;1
6ed81771-749b-11df-b112-00215aee3ebe;Ніверка;Ниверка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822489802;2;1
6ed81772-749b-11df-b112-00215aee3ebe;Нівецьке;Нивецкое;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223587403;3;1
6ed81773-749b-11df-b112-00215aee3ebe;Нігин;Негин;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822485401;3;1
6ed81774-749b-11df-b112-00215aee3ebe;Ніговичі;Ниговичи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622482805;3;1
6ed81775-749b-11df-b112-00215aee3ebe;Ніженка;Неженка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323686609;3;1
6ed81776-749b-11df-b112-00215aee3ebe;Ніжиловичі;Нежиловичи;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222785801;3;1
a5b151ba-ef03-11de-9277-00215aee3ebe;Ніжин;Нежин;місто;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7410400000;2;0
6ed81778-749b-11df-b112-00215aee3ebe;Ніжинське;Нежинское;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922687205;3;1
50c59147-749b-11df-b112-00215aee3ebe;Ніжинське (Григоро-Іванівка);Нежинское (Григоро-Ивановка);село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423383201;2;1
6ed8177b-749b-11df-b112-00215aee3ebe;Нікополь;Никополь;місто;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1211600000;1;0
6ed8177c-749b-11df-b112-00215aee3ebe;Нікополь;Никополь;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320482511;3;1
5cb619bf-749b-11df-b112-00215aee3ebe;Німецька Мокра (Комсомольськ);Немецкий Мокрая (Комсомольск);село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;ec2fa446-dd78-11e6-80f8-1c98ec135263;2124486102;3;1
6ed8177d-749b-11df-b112-00215aee3ebe;Німшин;Немшин;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621281802;3;1
6ed8177e-749b-11df-b112-00215aee3ebe;Нісмичі;Нисмичи;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624885704;3;1
6ed8177f-749b-11df-b112-00215aee3ebe;Нічогівка;Ничеговка;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723683503;3;1
6ed81780-749b-11df-b112-00215aee3ebe;Нічогівка;Ничеговка;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422086201;3;1
c8674aa5-dbb0-11df-9197-00215aee3ebe;Нобель;Нобель;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622284001;3;1
6ed81781-749b-11df-b112-00215aee3ebe;Нова;Новая;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425384503;3;1
60953673-56f4-11ee-80c0-000c2961d091;Нова  Носовиця;Нова Носовица;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621686013;3;1
6ed817a1-749b-11df-b112-00215aee3ebe;Нова Балка;Новая Балка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324884507;3;1
6ed817a2-749b-11df-b112-00215aee3ebe;Нова Балта;Новая Балта;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222983407;3;1
6ed817a3-749b-11df-b112-00215aee3ebe;Нова Басань;Новая Басань;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420684801;3;1
6ed817a4-749b-11df-b112-00215aee3ebe;Нова Березівка;Новая Березовка;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325156701;3;1
6ed817a6-749b-11df-b112-00215aee3ebe;Нова Борова;Новая Боровая;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821155700;3;1
6ed817a7-749b-11df-b112-00215aee3ebe;Нова Брикуля;Новая Брикуля;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125089202;3;1
6ed817a8-749b-11df-b112-00215aee3ebe;Нова Буда;Новая Буда;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221082004;3;1
6ed817a9-749b-11df-b112-00215aee3ebe;Нова Буда;Новая Буда;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422483504;3;1
6ed817aa-749b-11df-b112-00215aee3ebe;Нова Буда;Новая Буда;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122589702;3;1
6ed81782-749b-11df-b112-00215aee3ebe;Нова Буда;Новая Буда;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825086901;3;1
6ed817ab-749b-11df-b112-00215aee3ebe;Нова Василівка;Новая Василевка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321084507;3;1
17af7b4a-ddb9-11df-9197-00215aee3ebe;Нова Василівка;Новая Василевка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822080905;3;1
6ed817ac-749b-11df-b112-00215aee3ebe;Нова Вигода;Новая Выгода;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822082004;3;1
6ed81783-749b-11df-b112-00215aee3ebe;Нова Вижва;Новая Выжва;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725083201;3;1
6ed817ad-749b-11df-b112-00215aee3ebe;Нова Висунь;Новая Висунь;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655117;3;1
6ed817ae-749b-11df-b112-00215aee3ebe;Нова Вільшанка;Нова Ольшанка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122784205;3;1
6ed817af-749b-11df-b112-00215aee3ebe;Нова Водолага;Новая Водолага;місто;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324255100;2;0
6ed817b0-749b-11df-b112-00215aee3ebe;Нова Воля;Новая Воля;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725084803;3;1
6ed817b1-749b-11df-b112-00215aee3ebe;Нова Волярка;Новая Волярка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123155102;3;1
6ed817b2-749b-11df-b112-00215aee3ebe;Нова Галещина;Новая Галещина;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322055500;3;1
6ed817b3-749b-11df-b112-00215aee3ebe;Нова Гнилиця;Новая Гнилица;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325456704;3;1
6ed817b4-749b-11df-b112-00215aee3ebe;Нова Гребля;Новая Гребля;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120487605;3;1
6ed817b5-749b-11df-b112-00215aee3ebe;Нова Гребля;Новая Гребля;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422089103;3;1
6ed817b6-749b-11df-b112-00215aee3ebe;Нова Гребля;Новая Гребля;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121589703;3;1
6ed817b7-749b-11df-b112-00215aee3ebe;Нова Гребля;Новая Гребля;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820984401;3;1
6ed81784-749b-11df-b112-00215aee3ebe;Нова Гребля;Новая Гребля;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924186701;3;1
6ed81785-749b-11df-b112-00215aee3ebe;Нова Гребля;Новая Гребля;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221086801;3;1
6ed81786-749b-11df-b112-00215aee3ebe;Нова Гребля;Новая Гребля;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424185801;3;1
6ed81787-749b-11df-b112-00215aee3ebe;Нова Гребля;Новая Гребля;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120984801;3;1
6ed81788-749b-11df-b112-00215aee3ebe;Нова Гребля;Новая Гребля;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521685603;3;1
6ed817b8-749b-11df-b112-00215aee3ebe;Нова Григорівка;Новая Григоровка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125255413;3;1
6ed817b9-749b-11df-b112-00215aee3ebe;Нова Григорівка;Новая Григоровка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123585504;3;1
6ed817ba-749b-11df-b112-00215aee3ebe;Нова Григорівка;Новая Григоровка;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522681404;3;1
6ed817bb-749b-11df-b112-00215aee3ebe;Нова Гуринівка;Новая Гуриновка;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422481507;3;1
871625fd-749b-11df-b112-00215aee3ebe;Нова Гусарівка (Червона Гусарі;Новая Гусаровка (Червоная Гусаровка);село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320286801;3;1
6ed817bc-749b-11df-b112-00215aee3ebe;Нова Гута;Новая Гута;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924486305;3;1
6ed817bd-749b-11df-b112-00215aee3ebe;Нова Гута;Новая Гута;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2625882703;3;1
6ed817be-749b-11df-b112-00215aee3ebe;Нова Гута;Новая Гута;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823485504;3;1
6ed817bf-749b-11df-b112-00215aee3ebe;Нова Гута;Новая Гута;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122088905;3;1
6ed817c0-749b-11df-b112-00215aee3ebe;Нова Гута;Новая Гута;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823080405;3;1
6ed817c1-749b-11df-b112-00215aee3ebe;Нова Гута;Новая Гута;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825287404;2;1
6ed817c2-749b-11df-b112-00215aee3ebe;Нова Гута;Новая Гута;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823386503;3;1
08935b8a-081d-11e4-a577-003048d2b473;Нова Гута;Нова Гута;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124282802;3;1
6ed817c3-749b-11df-b112-00215aee3ebe;Нова Гутиська;Новая Гутиска;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822188702;3;1
6ed81789-749b-11df-b112-00215aee3ebe;Нова Дача;Новая Дача;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223581308;3;1
6ed817c6-749b-11df-b112-00215aee3ebe;Нова Диканька;Новая Диканька;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324280504;3;1
6ed817c7-749b-11df-b112-00215aee3ebe;Нова Діброва;Новая Диброва;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823483204;3;1
6ed817c8-749b-11df-b112-00215aee3ebe;Нова Діброва;Новая Диброва;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325180807;3;1
6ed817c9-749b-11df-b112-00215aee3ebe;Нова Дмитрівка;Новая Дмитровка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320483704;3;1
6ed817ca-749b-11df-b112-00215aee3ebe;Нова Дмитрівка;Новая Дмитровка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121587801;3;1
6ed817cb-749b-11df-b112-00215aee3ebe;Нова Долина;Новая Долина;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123783201;3;1
6ed817cc-749b-11df-b112-00215aee3ebe;Нова Дофінівка;Новая Дофиновка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122783901;3;1
6ed817cd-749b-11df-b112-00215aee3ebe;Нова Еметівка;Новая Эметовка;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121083805;3;1
6ed817ce-749b-11df-b112-00215aee3ebe;Нова Жадова;Новая Жадова;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324589504;3;1
6ed817d0-749b-11df-b112-00215aee3ebe;Нова Зоря;Новая Заря;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221881204;3;1
6ed817d1-749b-11df-b112-00215aee3ebe;Нова Зоря;Новая Заря;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282517;3;1
6ed817d2-749b-11df-b112-00215aee3ebe;Нова Зоря;Новая Заря;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520385503;3;1
6ed817d3-749b-11df-b112-00215aee3ebe;Нова іванівка;Новая Ивановка;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323983501;3;1
6ed817d4-749b-11df-b112-00215aee3ebe;Нова Іванівка;Новая Ивановка;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124582407;3;1
6ed8179e-749b-11df-b112-00215aee3ebe;Нова Іванівка;Новая Ивановка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323983501;3;1
6ed8179f-749b-11df-b112-00215aee3ebe;Нова Іванівка;Новая Ивановка;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120483901;3;1
6ed8178a-749b-11df-b112-00215aee3ebe;Нова Калуга;Новая Калуга;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520983301;3;1
6ed817d5-749b-11df-b112-00215aee3ebe;Нова Калуга Друга;Новая Калуга Вторая;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520983303;3;1
6ed8178b-749b-11df-b112-00215aee3ebe;Нова Кам'янка;Новая Каменка;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520983501;3;1
6ed8178c-749b-11df-b112-00215aee3ebe;Нова Кам'янка;Новая Каменка;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622786701;3;1
4f1fb7c4-6c48-11e2-a79e-003048d2b473;Нова Кам'янка;Новая  Каменка ;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520983501;3;1
6ed817d8-749b-11df-b112-00215aee3ebe;Нова Ковалівка;Новая Ковалевка;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121082806;3;1
6ed817d9-749b-11df-b112-00215aee3ebe;Нова Ковалівка;Новая Ковалевка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521785305;3;1
56bdd8ec-749b-11df-b112-00215aee3ebe;Нова Козача;Нова Козача;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322055603;3;1
6ed817da-749b-11df-b112-00215aee3ebe;Нова Котельня;Новая Котельня;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820387501;3;1
6ed817db-749b-11df-b112-00215aee3ebe;Нова Кочубеївка;Новая Кочубеевка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325482601;3;1
6ed817dc-749b-11df-b112-00215aee3ebe;Нова Красношора;Новая Красношора;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324586002;3;1
6ed817dd-749b-11df-b112-00215aee3ebe;Нова Криворудка;Новая Криворудка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822785103;3;1
6ed817de-749b-11df-b112-00215aee3ebe;Нова Кругляківка;Новая Кругляковка;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321080504;3;1
6ed8178e-749b-11df-b112-00215aee3ebe;Нова Кубань;Новая Кубань;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520983701;3;1
6ed817df-749b-11df-b112-00215aee3ebe;Нова Кульна;Новая Кульна;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122985102;3;1
6ed817e0-749b-11df-b112-00215aee3ebe;Нова Липівка;Новая Липовка;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625882403;3;1
6ed817e1-749b-11df-b112-00215aee3ebe;Нова Лішня;Новая Лешня;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721187705;3;1
6ed817e2-749b-11df-b112-00215aee3ebe;Нова Лодина;Новая Лодына;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622181603;3;1
6ed8178f-749b-11df-b112-00215aee3ebe;Нова Любомирка;Новая Любомирка;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624680405;3;1
74dc4994-749b-11df-b112-00215aee3ebe;Нова Маківка (Петрівське);Новая Макивка (Петровское);село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223783002;3;1
6ed817e5-749b-11df-b112-00215aee3ebe;Нова Мар'янівка;Новая Марьяновка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825082206;3;1
6ed817e7-749b-11df-b112-00215aee3ebe;Нова Мерефа;Новая Мерефа;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324283002;3;1
6ed817e8-749b-11df-b112-00215aee3ebe;Нова Мечебилівка;Новая Мечебиловка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323987007;3;1
6ed817e9-749b-11df-b112-00215aee3ebe;Нова Миколаївка;Новая Николаевка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320485001;3;1
6ed817ea-749b-11df-b112-00215aee3ebe;Нова Миколаївка;Новая Николаевка;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523081402;3;1
6ed817eb-749b-11df-b112-00215aee3ebe;Нова Миколаївка;Новая Николаевка;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621689509;3;1
7ad94f17-749b-11df-b112-00215aee3ebe;Нова Миронівка;Новая Мироновка;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222988303;3;1
6ed817ec-749b-11df-b112-00215aee3ebe;Нова Митниця;Новая Мытница;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625885605;3;1
6ed81790-749b-11df-b112-00215aee3ebe;Нова Михайлівка;Новая Михайловка;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324282801;3;1
6ed817ed-749b-11df-b112-00215aee3ebe;Нова Могильниця;Новая Могильница;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125088901;3;1
6ed817ee-749b-11df-b112-00215aee3ebe;Нова Мощаниця;Новая Мощаница;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622684801;3;1
6ed81791-749b-11df-b112-00215aee3ebe;Нова Некрасівка;Новая Некрасовка;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122084201;3;1
6ed81792-749b-11df-b112-00215aee3ebe;Нова Ободівка;Новая Ободовка;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524183901;3;1
6ed817ef-749b-11df-b112-00215aee3ebe;Нова Одеса;Новая Одесса;місто;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824810100;2;0
6ed817f0-749b-11df-b112-00215aee3ebe;Нова Олександрівка;Новая Александровка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920984603;3;1
6ed817f2-749b-11df-b112-00215aee3ebe;Нова Олександрівка;Новая Александровка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
6ed817f3-749b-11df-b112-00215aee3ebe;Нова Олександрівка;Новая Александровка;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221984201;3;1
6ed817f4-749b-11df-b112-00215aee3ebe;Нова Олександрівка;Новая Александровка;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222910101;3;1
6ed817f5-749b-11df-b112-00215aee3ebe;Нова Олександрівка;Новая Александровка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820880902;3;1
c8674aa2-dbb0-11df-9197-00215aee3ebe;Нова Олександрівка;Новая Александровка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324555110;3;1
6ed817f6-749b-11df-b112-00215aee3ebe;Нова Олексіївка;Новая Алексеевка;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125081903;2;1
6ed817f7-749b-11df-b112-00215aee3ebe;Нова Оржиця;Новая Оржица;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221984501;3;1
6ed817f8-749b-11df-b112-00215aee3ebe;Нова Осота;Новая Осота;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520587503;3;1
6ed817f9-749b-11df-b112-00215aee3ebe;Нова Павлівка;Новая Павловка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323080203;3;1
6ed817fa-749b-11df-b112-00215aee3ebe;Нова Павлівка;Новая Павловка;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124084803;3;1
325c57f7-ddd2-11df-9197-00215aee3ebe;Нова Павлівка;Новая Павловка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522587203;3;1
6ed817fb-749b-11df-b112-00215aee3ebe;Нова Папірня;Новая Папирня;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424486406;3;1
6ed817fc-749b-11df-b112-00215aee3ebe;Нова Парафіївка;Новая Парафиевка;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323183001;3;1
6ed817fd-749b-11df-b112-00215aee3ebe;Нова Петрівка;Новая Петровка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324582009;3;1
6ed817fe-749b-11df-b112-00215aee3ebe;Нова Петрівщина;Новая Петровщина;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325181303;3;1
6ed817ff-749b-11df-b112-00215aee3ebe;Нова Пісочна;Новая Песочная;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821287802;3;1
6ed81800-749b-11df-b112-00215aee3ebe;Нова Плахтіївка;Новая Плахтиевка;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124582409;3;1
56bdd271-749b-11df-b112-00215aee3ebe;Нова Пляшева (Жовтневе);Новое Пляшевое (Жовтневое);село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625882901;3;1
6ed81801-749b-11df-b112-00215aee3ebe;Нова Покровка;Новая Покровка;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122084501;3;1
6ed81793-749b-11df-b112-00215aee3ebe;Нова Прага;Новая Прага;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520355400;3;1
6ed81803-749b-11df-b112-00215aee3ebe;Нова Праця;Нова Праця;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222083006;3;1
6ed81794-749b-11df-b112-00215aee3ebe;Нова Прилука;Новая Прилука;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522286602;3;1
6ed81805-749b-11df-b112-00215aee3ebe;Нова Пустоварівка;Новая Пустоваровка;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224083602;3;1
6ed81806-749b-11df-b112-00215aee3ebe;Нова Радча;Новая Радча;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755139;3;1
6ed81807-749b-11df-b112-00215aee3ebe;Нова Романівка;Новая Романовка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824084001;3;1
6ed81795-749b-11df-b112-00215aee3ebe;Нова Руда;Новая Руда;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723685101;3;1
6ed81808-749b-11df-b112-00215aee3ebe;Нова Рудня;Новая Рудня;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287705;3;1
6ed81809-749b-11df-b112-00215aee3ebe;Нова Рудня;Новая Рудня;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822885202;3;1
6ed8180a-749b-11df-b112-00215aee3ebe;Нова Рудня;Новая Рудня;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822087207;3;1
6ed8180b-749b-11df-b112-00215aee3ebe;Нова Рудня;Новая Рудня;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424485606;3;1
6ed81796-749b-11df-b112-00215aee3ebe;Нова Русь;Новая Русь;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223581309;3;1
6ed8180c-749b-11df-b112-00215aee3ebe;Нова Рутвянка;Новая Рутвянка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823485505;3;1
6ed8180d-749b-11df-b112-00215aee3ebe;Нова Семенівка;Новая Семеновка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324584709;3;1
6ed8180e-749b-11df-b112-00215aee3ebe;Нова Семенівка;Новая Семеновка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324584709;3;1
6ed8180f-749b-11df-b112-00215aee3ebe;Нова Серпухівка;Новая Серпуховка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320283603;3;1
6ed81797-749b-11df-b112-00215aee3ebe;Нова Синявка;Новая Синявка;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824484001;3;1
6ed81810-749b-11df-b112-00215aee3ebe;Нова Січ;Новая Сечь;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924785905;3;1
6ed81811-749b-11df-b112-00215aee3ebe;Нова Січ;Новая Сечь;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220310110;3;1
6ed81798-749b-11df-b112-00215aee3ebe;Нова Скварява;Новая Скварява;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787101;3;1
6ed81799-749b-11df-b112-00215aee3ebe;Нова Слобода;Новая Слобода;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923886301;3;1
6ed81812-749b-11df-b112-00215aee3ebe;Нова Слобода;Новая Слобода;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324082002;3;1
6ed81813-749b-11df-b112-00215aee3ebe;Нова Спарта;Новая Спарта;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924481304;3;1
6ed81814-749b-11df-b112-00215aee3ebe;Нова Сулківка;Новая Сулковка;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524887002;3;1
6ed81816-749b-11df-b112-00215aee3ebe;Нова Тернавщина;Новая Тернавщина;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424185502;3;1
6ed81817-749b-11df-b112-00215aee3ebe;Нова Україна;Новая Украина;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084805;3;1
6ed8179a-749b-11df-b112-00215aee3ebe;Нова Українка;Новая Украинка;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624687001;3;1
6ed8179b-749b-11df-b112-00215aee3ebe;Нова Ушиця;Новая Ушица;місто;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823355100;2;0
cdc44f4b-dde8-11df-9197-00215aee3ebe;Нова Ушиця;Новая Ушица;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386028;3;1
6ed81819-749b-11df-b112-00215aee3ebe;Нова Царичанка;Новая Царичанка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120885201;2;1
6ed8179c-749b-11df-b112-00215aee3ebe;Нова Чернещина;Новая Чернещина;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324885001;3;1
6ed8181a-749b-11df-b112-00215aee3ebe;Нова Чортория;Новая Чертория;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823185001;3;1
6ed8181b-749b-11df-b112-00215aee3ebe;Нова Шарпівка;Новая Шарповка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923888403;3;1
6ed8179d-749b-11df-b112-00215aee3ebe;Нова Шибка;Новая Шыбка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125281907;3;1
cdc44f56-dde8-11df-9197-00215aee3ebe;Нова Юрівка;Новая Юровка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825084404;3;1
6ed8181c-749b-11df-b112-00215aee3ebe;Нова Ярославка;Новая Ярославка;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125789202;3;1
6ed8181d-749b-11df-b112-00215aee3ebe;Новаки;Новаки;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822384001;3;1
6ed8181e-749b-11df-b112-00215aee3ebe;Новаки;Новаки;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823681004;3;1
6ed8181f-749b-11df-b112-00215aee3ebe;Новаки;Новаки;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620887101;3;1
6ed81820-749b-11df-b112-00215aee3ebe;Новаки;Новаки;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322885101;3;1
6ed81821-749b-11df-b112-00215aee3ebe;Новаківка;Новаковка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523181602;3;1
6ed81822-749b-11df-b112-00215aee3ebe;Новакове;Новаково;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121881405;3;1
6ed81823-749b-11df-b112-00215aee3ebe;Новачиха;Новачиха;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324881906;3;1
6ed81825-749b-11df-b112-00215aee3ebe;Новгородка;Новгородка;місто;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523455100;3;1
6ed81827-749b-11df-b112-00215aee3ebe;Новгород-Сіверський;Новгород-Северский;місто;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7410500000;2;1
6ed81828-749b-11df-b112-00215aee3ebe;Новгородське;Новгородское;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925080804;3;1
6ed8182a-749b-11df-b112-00215aee3ebe;Нове;Новое;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922086905;3;1
6ed8182b-749b-11df-b112-00215aee3ebe;Нове;Новое;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920386305;3;1
6ed8182c-749b-11df-b112-00215aee3ebe;Нове;Новое;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223284004;3;1
6ed8182d-749b-11df-b112-00215aee3ebe;Нове;Новое;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221482004;3;1
6ed8182e-749b-11df-b112-00215aee3ebe;Нове;Новое;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224883505;3;1
6ed8182f-749b-11df-b112-00215aee3ebe;Нове;Новое;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885509;3;1
6ed81830-749b-11df-b112-00215aee3ebe;Нове;Новое;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083813;3;1
6ed81831-749b-11df-b112-00215aee3ebe;Нове;Новое;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825181206;3;1
6ed81832-749b-11df-b112-00215aee3ebe;Нове;Новое;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322082003;3;1
6ed81833-749b-11df-b112-00215aee3ebe;Нове;Новое;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324584703;3;1
6ed81834-749b-11df-b112-00215aee3ebe;Нове;Новое;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323985507;3;1
6ed81835-749b-11df-b112-00215aee3ebe;Нове;Новое;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123985602;3;1
6ed81836-749b-11df-b112-00215aee3ebe;Нове;Новое;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122783004;3;1
6ed81837-749b-11df-b112-00215aee3ebe;Нове;Новое;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120884404;3;1
6ed81838-749b-11df-b112-00215aee3ebe;Нове;Новое;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125082403;2;1
6ed8183f-749b-11df-b112-00215aee3ebe;Нове;Новое;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222486603;3;1
6ed81840-749b-11df-b112-00215aee3ebe;Нове;Новое;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425556001;3;1
6ed81841-749b-11df-b112-00215aee3ebe;Нове;Новое;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420387504;3;1
6ed81842-749b-11df-b112-00215aee3ebe;Нове;Новое;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522280202;3;1
6ed81843-749b-11df-b112-00215aee3ebe;Нове;Новое;село;0c6de8b4-41ba-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3510166900;3;0
6ed81844-749b-11df-b112-00215aee3ebe;Нове;Новое;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623889004;3;1
6ed81845-749b-11df-b112-00215aee3ebe;Нове;Новое;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322684003;3;1
6ed81846-749b-11df-b112-00215aee3ebe;Нове;Новое;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323682602;3;1
6ed8184d-749b-11df-b112-00215aee3ebe;Нове Грякове;Новое Гряково;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325481203;3;1
6ed8184e-749b-11df-b112-00215aee3ebe;Нове Давидково;Новое Давыдково;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122785801;3;1
6ed8184f-749b-11df-b112-00215aee3ebe;Нове Життя;Новая Жизнь;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885708;3;1
6ed81850-749b-11df-b112-00215aee3ebe;Нове Життя;Новая Жизнь;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820683403;3;1
6ed81851-749b-11df-b112-00215aee3ebe;Нове Життя;Новая Жизнь;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221683603;3;1
6ed81852-749b-11df-b112-00215aee3ebe;Нове Життя;Новая Жизнь;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823486203;3;1
6ed81853-749b-11df-b112-00215aee3ebe;Нове Життя;Новая Жизнь;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524980313;3;1
6ed81854-749b-11df-b112-00215aee3ebe;Нове Життя;Новая Жизнь;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825285804;2;1
6ed81848-749b-11df-b112-00215aee3ebe;Нове Життя;Новая Жизнь;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125187201;3;1
6ed81856-749b-11df-b112-00215aee3ebe;Нове Залісся;Новое Залесье;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221082201;3;1
6ed81858-749b-11df-b112-00215aee3ebe;Нове Запоріжжя;Новое Запорожье;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322183505;2;0
6ed81859-749b-11df-b112-00215aee3ebe;Нове Клинове;Новое Клиновое;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121282406;3;1
6ed8185a-749b-11df-b112-00215aee3ebe;Нове Мажарове;Новое Мажарово;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322283001;3;1
6ed8185b-749b-11df-b112-00215aee3ebe;Нове Місто;Новое Мисто;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524584401;3;1
6ed81849-749b-11df-b112-00215aee3ebe;Нове Місто;Новое Мисто;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625183901;3;1
f9333451-db4e-11df-9197-00215aee3ebe;Нове Місто;Новое Мисто;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123410102;3;1
6ed8185c-749b-11df-b112-00215aee3ebe;Нове Остапове;Новое Остапово;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320283603;3;1
6ed8185d-749b-11df-b112-00215aee3ebe;Нове Пекельне;Новое Пекельное;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322283005;3;1
6ed8185e-749b-11df-b112-00215aee3ebe;Нове Поле;Новое Поле;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323681303;3;1
6ed81860-749b-11df-b112-00215aee3ebe;Нове Полісся;Новое Полесье;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420386009;3;1
6ed81861-749b-11df-b112-00215aee3ebe;Нове Поріччя;Новое Поречье;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821285501;3;1
6ed81862-749b-11df-b112-00215aee3ebe;Нове Селище;Новое Селище;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122781503;3;1
6ed81863-749b-11df-b112-00215aee3ebe;Нове Село;Новое Село;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622786406;3;1
6ed81864-749b-11df-b112-00215aee3ebe;Нове Село;Новое Село;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620980701;3;1
6ed81865-749b-11df-b112-00215aee3ebe;Нове Село;Новое Село;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621280303;3;1
6ed81866-749b-11df-b112-00215aee3ebe;Нове Село;Новое Село;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822184801;3;1
6ed81867-749b-11df-b112-00215aee3ebe;Нове Село;Новое Село;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825888802;3;1
6ed8184a-749b-11df-b112-00215aee3ebe;Нове Село;Новое Село;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124685001;3;1
6ed8184b-749b-11df-b112-00215aee3ebe;Нове Село;Новое Село;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621585607;3;1
6ed8184c-749b-11df-b112-00215aee3ebe;Нове Село;Новое Село;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121283001;3;1
6ed81868-749b-11df-b112-00215aee3ebe;Нове Тарутине;Новое Тарутино;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124786401;3;1
6ed81869-749b-11df-b112-00215aee3ebe;Новеньке;Новенькое;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924780703;3;1
6ed8186b-749b-11df-b112-00215aee3ebe;Новеньке;Новенькое;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423684706;3;1
6ed8186f-749b-11df-b112-00215aee3ebe;Новий Байрак;Новый Байрак;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324880309;3;1
6ed81870-749b-11df-b112-00215aee3ebe;Новий Берестовець;Новый Берестовец;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623485007;3;1
6ed81871-749b-11df-b112-00215aee3ebe;Новий Биків;Новый Быков;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420685501;3;1
6ed81872-749b-11df-b112-00215aee3ebe;Новий Білоус;Новый Белоус;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425585501;2;0
6ed81873-749b-11df-b112-00215aee3ebe;Новий Буг;Новый Буг;місто;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824510100;2;0
6ed81874-749b-11df-b112-00215aee3ebe;Новий Бурлук;Новый Бурлук;село;64024b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324684001;3;1
6ed81875-749b-11df-b112-00215aee3ebe;Новий Виселок;Новый Выселок;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320685805;3;1
6ed81876-749b-11df-b112-00215aee3ebe;Новий Виселок;Новый Выселок;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320485502;3;1
6ed81877-749b-11df-b112-00215aee3ebe;Новий Витків;Новый Витков;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623983401;3;1
6ed81878-749b-11df-b112-00215aee3ebe;Новий Вовчинець;Новый Волчинец;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321088202;3;1
6ed8187a-749b-11df-b112-00215aee3ebe;Новий Глібів;Новый Глебов;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823382005;3;1
6ed8187b-749b-11df-b112-00215aee3ebe;Новий Городок;Новый Городок;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821780203;3;1
6ed8187c-749b-11df-b112-00215aee3ebe;Новий Гребеник;Новый Гребеник;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123985205;3;1
6ed8187d-749b-11df-b112-00215aee3ebe;Новий Двір;Новый Двор;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725583401;3;1
6ed8187f-749b-11df-b112-00215aee3ebe;Новий Дорогинь;Новый Дорогинь;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755140;3;1
6ed81881-749b-11df-b112-00215aee3ebe;Новий Завод;Новый Завод;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825483601;3;1
6ed81882-749b-11df-b112-00215aee3ebe;Новий Загорів;Новый Загоров;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722485202;3;1
6ed81883-749b-11df-b112-00215aee3ebe;Новий Зборишів;Новий Зборишев;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720881302;3;1
6ed81884-749b-11df-b112-00215aee3ebe;Новий іржавець;Новый Иржавец;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323687702;3;1
6ed67cdb-5e7e-11e3-ae68-003048d2b473;Новий Калинів;Новый Калинив;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624210300;3;0
6ed81886-749b-11df-b112-00215aee3ebe;Новий Калкаїв;Новый Калкаев;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324584607;3;1
6ed81855-749b-11df-b112-00215aee3ebe;Новий Каракурт (Нове Жовтневе);Новий Каракурт (Новое Жовтневое);село;6b07f8a2-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5121483602;3;1
f06d7efe-bd14-11e0-9aaa-003048d2b473;Новий Киселів;Новый Киселив;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322586502;3;1
6ed81887-749b-11df-b112-00215aee3ebe;Новий Кокорів;Новый Кокорев;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123485903;3;1
6ed81889-749b-11df-b112-00215aee3ebe;Новий Корець;Новый Корец;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623086001;3;1
6ed8188a-749b-11df-b112-00215aee3ebe;Новий Корогод;Новый Корогод;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221083701;3;1
ef73219f-7541-11e8-80d4-1c98ec135261;Новий Коротич;Новый Коротич;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157001;3;1
6ed8188b-749b-11df-b112-00215aee3ebe;Новий Кременчук;Новый Кременчуг;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221881404;3;1
6ed8188c-749b-11df-b112-00215aee3ebe;Новий Кривин;Новый Кривин;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823987302;3;1
6ed8188d-749b-11df-b112-00215aee3ebe;Новий Кропивник;Новый Крапивник;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621285101;3;1
6ed8188e-749b-11df-b112-00215aee3ebe;Новий Лад;Новый Лад;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424155902;3;1
6ed8188f-749b-11df-b112-00215aee3ebe;Новий Лиман;Новый Лиман;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325785903;3;1
6ed81890-749b-11df-b112-00215aee3ebe;Новий Любар;Новый Любар;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823187303;3;1
6ed81891-749b-11df-b112-00215aee3ebe;Новий Майдан;Новый Майдан;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821583202;3;1
6ed81892-749b-11df-b112-00215aee3ebe;Новий Мартинів;Новый Мартынов;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621287002;3;1
6ed81893-749b-11df-b112-00215aee3ebe;Новий Мерчик;Новый Мерчик;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321285001;3;1
6ed81894-749b-11df-b112-00215aee3ebe;Новий Милятин;Новый Милятин;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620683601;3;1
6ed81895-749b-11df-b112-00215aee3ebe;Новий Мир;Новый Мир;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920985402;3;1
6ed81896-749b-11df-b112-00215aee3ebe;Новий Мир;Новый Мир;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221882906;3;1
6ed81897-749b-11df-b112-00215aee3ebe;Новий Мир;Новый Мир;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225482005;3;1
6ed81898-749b-11df-b112-00215aee3ebe;Новий Мир;Новый Мир;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321082007;3;1
6ed81899-749b-11df-b112-00215aee3ebe;Новий Мир;Новый Мир;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122987407;3;1
6ed8189d-749b-11df-b112-00215aee3ebe;Новий Мізунь;Новый Мизунь;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622086202;3;1
6ed8189e-749b-11df-b112-00215aee3ebe;Новий Моквин;Новый Моквин;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620487020;3;1
6ed8189f-749b-11df-b112-00215aee3ebe;Новий Мосир;Новый Мосыр;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155316;3;1
6ed818a0-749b-11df-b112-00215aee3ebe;Новий Олексинець;Новый Алексинец;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123480402;3;1
871626a7-749b-11df-b112-00215aee3ebe;Новий Орач (Червоний Орач);Новый Орач (Червоный Орач);село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123182307;3;1
6ed818a1-749b-11df-b112-00215aee3ebe;Новий Острів;Новий Остров;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624286605;3;1
6ed818a2-749b-11df-b112-00215aee3ebe;Новий Поділ;Новый Подол;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421786002;3;1
6ed818a3-749b-11df-b112-00215aee3ebe;Новий Посьолок;Новый Поселок;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224884903;3;1
6ed818a4-749b-11df-b112-00215aee3ebe;Новий Ріпнів;Новый Репнев;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620683602;3;1
6ed818a5-749b-11df-b112-00215aee3ebe;Новий Роговець;Новый Роговец;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122481502;3;1
6ed818a6-749b-11df-b112-00215aee3ebe;Новий Розділ;Новый Роздол;місто;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4610800000;2;0
6ed818ab-749b-11df-b112-00215aee3ebe;Новий Світ;Новый Свет;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821285801;3;1
6ed818ac-749b-11df-b112-00215aee3ebe;Новий Світ;Новый Свет;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622680804;3;1
6ed818ad-749b-11df-b112-00215aee3ebe;Новий Солотвин;Новый Солотвин;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820886502;3;1
6ed818ae-749b-11df-b112-00215aee3ebe;Новий Став;Новый Став;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622181202;3;1
6ed818af-749b-11df-b112-00215aee3ebe;Новий Ставок;Новый Ставок;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820380103;3;1
6ed818b0-749b-11df-b112-00215aee3ebe;Новий Стародуб;Новый Стародуб;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524983701;3;1
6ed818b1-749b-11df-b112-00215aee3ebe;Новий Степ;Новая Степь;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821785004;3;1
6ed818b2-749b-11df-b112-00215aee3ebe;Новий Тагамлик;Новый Тагамлык;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323084401;3;1
6ed818b3-749b-11df-b112-00215aee3ebe;Новий Тік;Новый Ток;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621480709;3;1
236f57d4-644f-11e1-b817-003048d2b473;Новий Чорторийськ;Новый Чорторийськ (ВОЛСК) (UA);село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;*072368760;3;1
6ed818b6-749b-11df-b112-00215aee3ebe;Новий Шлях;Новый Шлях;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221881205;3;1
6ed818b7-749b-11df-b112-00215aee3ebe;Новий Шлях;Новый Шлях;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825781404;3;1
6ed818b8-749b-11df-b112-00215aee3ebe;Новий Шлях;Новый Шлях;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224086602;3;1
6ed818b9-749b-11df-b112-00215aee3ebe;Новий Шлях;Новый Шлях;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422086350;3;1
6ed818ba-749b-11df-b112-00215aee3ebe;Новий Яр;Новый Яр;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625884507;3;1
6ed818bb-749b-11df-b112-00215aee3ebe;Новий Яричів;Новый Ярычев;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622155600;3;1
6ed818bc-749b-11df-b112-00215aee3ebe;Новики;Новики;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122487001;3;1
6ed818bd-749b-11df-b112-00215aee3ebe;Новики;Новики;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422080603;3;1
6ed818be-749b-11df-b112-00215aee3ebe;Новина;Новина;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822384101;3;1
6ed818bf-749b-11df-b112-00215aee3ebe;Новина-Добрятинська;Новина-Добрятинская;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623882302;3;1
6ed818c0-749b-11df-b112-00215aee3ebe;Новини;Новины;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720510414;3;1
6ed818c1-749b-11df-b112-00215aee3ebe;Новини;Новины;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625884508;3;1
6ed818c2-749b-11df-b112-00215aee3ebe;Новини;Новины;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825480802;3;1
6ed818c3-749b-11df-b112-00215aee3ebe;Новини;Новины;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623084203;3;1
6ed818c4-749b-11df-b112-00215aee3ebe;Новини;Новины;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623887603;3;1
6ed818c5-749b-11df-b112-00215aee3ebe;Новиця;Новица;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622884801;3;1
6ed818c6-749b-11df-b112-00215aee3ebe;Новицьке;Новицкое;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924181106;3;1
6ed818c7-749b-11df-b112-00215aee3ebe;Новичина;Новичина;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620388805;3;1
6ed818c8-749b-11df-b112-00215aee3ebe;Новичі;Новичи;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825585601;3;1
d3453c4f-9e14-11df-866f-00215aee3ebe;Новичка;Новычка;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622084301;3;1
6ed818ce-749b-11df-b112-00215aee3ebe;Нові Безрадичі;Новые Безрадичи;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223187705;3;1
6ed818cf-749b-11df-b112-00215aee3ebe;Нові Березичі;Новые Березичи;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155125;3;1
6ed818d0-749b-11df-b112-00215aee3ebe;Нові Білокоровичі;Новые Белокоровичи;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824456200;3;1
6ed818c9-749b-11df-b112-00215aee3ebe;Нові Біляри;Новые Беляры;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122755400;3;1
6ed818d1-749b-11df-b112-00215aee3ebe;Нові Бірчиці;Новые Бирчицы;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624283403;3;1
6ed818d2-749b-11df-b112-00215aee3ebe;Нові Боровичі;Новые Боровичи;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425884001;3;1
6ed818d3-749b-11df-b112-00215aee3ebe;Нові Бросківці;Новые Бросковцы;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324587501;3;1
6ed818d4-749b-11df-b112-00215aee3ebe;Нові Бутори;Новые Буторы;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121682803;3;1
6ed818d5-749b-11df-b112-00215aee3ebe;Нові Велідники;Новые Веледники;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824285201;3;1
6ed818d6-749b-11df-b112-00215aee3ebe;Нові Вербки;Новые Вербки;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223582805;3;1
6ed818d7-749b-11df-b112-00215aee3ebe;Нові Вирки;Новые Вирки;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920686001;3;1
6ed818d8-749b-11df-b112-00215aee3ebe;Нові Вороб'ї;Новые Воробьи;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823486201;3;1
6ed818d9-749b-11df-b112-00215aee3ebe;Нові Гончарі;Новые Гончары;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923885804;3;1
6ed818da-749b-11df-b112-00215aee3ebe;Нові Драчинці;Новые Драчинцы;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322583004;3;1
6ed818db-749b-11df-b112-00215aee3ebe;Нові Каплани;Новые Капланы;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120484201;3;1
6ed818dc-749b-11df-b112-00215aee3ebe;Нові Ковна;Новые Ковна;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225283312;3;1
6ed818dd-749b-11df-b112-00215aee3ebe;Нові Кошари;Новые Кошары;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155706;3;1
6ed818ca-749b-11df-b112-00215aee3ebe;Нові Кривотули;Новые Кривотулы;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625883201;3;1
6ed818de-749b-11df-b112-00215aee3ebe;Нові Лучки;Новые Лучки;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;;3;1
6ed818df-749b-11df-b112-00215aee3ebe;Нові Макалевичі;Новые Макалевичи;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222082103;3;1
6ed818e0-749b-11df-b112-00215aee3ebe;Нові Мартиновичі;Новые Мартыновичи;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323882204;3;1
6ed818e1-749b-11df-b112-00215aee3ebe;Нові Маяки;Новые Маяки;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125485605;3;1
6ed818e2-749b-11df-b112-00215aee3ebe;Нові Млини;Новые Млыны;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425884501;3;1
6ed818e3-749b-11df-b112-00215aee3ebe;Нові Млини;Новые Млыны;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420883601;3;1
6ed818e4-749b-11df-b112-00215aee3ebe;Нові Новаки;Новые Новаки;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822885203;3;1
6ed818e5-749b-11df-b112-00215aee3ebe;Нові Обиходи;Новые Обиходы;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523085701;3;1
6ed818e6-749b-11df-b112-00215aee3ebe;Нові Озеряни;Новые Озеряны;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820983001;3;1
6ed818e8-749b-11df-b112-00215aee3ebe;Нові Опачичі;Новые Опачичи;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222786405;3;1
6ed818cc-749b-11df-b112-00215aee3ebe;Нові Петликівці;Новые Петликовцы;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121284001;3;1
6ed818ea-749b-11df-b112-00215aee3ebe;Нові Петрівці;Новые Петровцы;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221886001;2;0
17af7b1f-ddb9-11df-9197-00215aee3ebe;Нові Підцаревичі;Новые Подцаревичи;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723680902;3;1
6ed818eb-749b-11df-b112-00215aee3ebe;Нові Садки;Новые Садки;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221883506;3;1
6ed818ec-749b-11df-b112-00215aee3ebe;Нові Сали;Новые Салы;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825687002;3;1
6ed818ed-749b-11df-b112-00215aee3ebe;Нові Санжари;Новые Санжары;місто;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323455100;2;0
6ed818ee-749b-11df-b112-00215aee3ebe;Нові Серби;Новые Сербы;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821785804;3;1
6ed818ef-749b-11df-b112-00215aee3ebe;Нові Скоморохи;Новые Скоморохи;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621285801;3;1
6ed818f0-749b-11df-b112-00215aee3ebe;Нові Соколи;Новые Соколы;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222085007;3;1
6ed818cd-749b-11df-b112-00215aee3ebe;Нові Стрілища;Новые Стрелища;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621555900;3;1
6ed818f1-749b-11df-b112-00215aee3ebe;Нові Трояни;Новые Трояны;село;6b07f8a2-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5121485301;3;1
87162597-749b-11df-b112-00215aee3ebe;Нові Хоменки (Чапаєве);Новые Хоменки (Чапаево);село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525388302;3;1
6ed818f2-749b-11df-b112-00215aee3ebe;Нові Червища;Новые Червища;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721485401;3;1
6ed818f3-749b-11df-b112-00215aee3ebe;Нові Чобручі;Новые Чобручи;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123985206;3;1
6ed818f4-749b-11df-b112-00215aee3ebe;Нові Шомполи;Новые Шомполы;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121883205;3;1
6ed818f5-749b-11df-b112-00215aee3ebe;Нові Яриловичі;Новые Ярыловичи;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424486401;3;1
6ed818f6-749b-11df-b112-00215aee3ebe;Новоаврамівка;Новоаврамовка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324884201;3;1
6ed818fa-749b-11df-b112-00215aee3ebe;Новоандріївка;Новоандреевка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920688602;3;1
6ed818fb-749b-11df-b112-00215aee3ebe;Новоандріївка;Новоандреевка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225080515;3;1
6ed818fc-749b-11df-b112-00215aee3ebe;Новоандріївка;Новоандреевка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220786615;3;1
6ed818fd-749b-11df-b112-00215aee3ebe;Новоандріївка;Новоандреевка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825485503;3;1
6ed818fe-749b-11df-b112-00215aee3ebe;Новоандріївка;Новоандреевка;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824286050;3;1
6ed818ff-749b-11df-b112-00215aee3ebe;Новоандріївка;Новоандреевка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125483401;3;1
6ed81900-749b-11df-b112-00215aee3ebe;Новоандріївка;Новоандреевка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323910104;3;1
6ed81907-749b-11df-b112-00215aee3ebe;Новоандріївка;Новоандреевка;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523483601;3;1
6ed81908-749b-11df-b112-00215aee3ebe;Новоантонівка;Новоантоновка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824583903;3;1
6ed81909-749b-11df-b112-00215aee3ebe;Новоантонівка;Новоантоновка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121685804;3;1
6ed8190a-749b-11df-b112-00215aee3ebe;Новоархангельськ;Новоархангельск;місто;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523655100;3;1
6ed8190c-749b-11df-b112-00215aee3ebe;Новобарово;Новобарово;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124485001;3;1
6ed8190f-749b-11df-b112-00215aee3ebe;Новоберецьке;Новоберекское;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324583503;3;1
6ed81910-749b-11df-b112-00215aee3ebe;Новоберислав;Новоберислав;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520684401;3;1
6ed81914-749b-11df-b112-00215aee3ebe;Новобілоусівка;Новобелоусовка;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083814;3;1
6ed81915-749b-11df-b112-00215aee3ebe;Новобірзулівка;Новобирзуловка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820685302;3;1
6ed81916-749b-11df-b112-00215aee3ebe;Новоблакитне;Новоблакитное;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823680305;3;1
6ed81918-749b-11df-b112-00215aee3ebe;Новобогданівка;Новобогдановка;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824283802;3;1
6ed81919-749b-11df-b112-00215aee3ebe;Новобогданівка;Новобогдановка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324882006;3;1
6ed8191b-749b-11df-b112-00215aee3ebe;Новобогданове;Новобогданово;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320481508;3;1
6ed8191c-749b-11df-b112-00215aee3ebe;Новобойківське;Новобойковское;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955416;3;1
6ed8191d-749b-11df-b112-00215aee3ebe;Новоборисівка;Новоборисовка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121683001;3;1
6ed81920-749b-11df-b112-00215aee3ebe;Новобратське;Новобратское;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521884003;3;1
6ed81921-749b-11df-b112-00215aee3ebe;Новобратське;Новобратское;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823480403;3;1
429bd925-56e0-11e9-80df-1c98ec135261;Новобудова;Новостройка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610116;3;1
6ed81956-749b-11df-b112-00215aee3ebe;Новов'язівське;Нововязовское;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225982501;3;1
6ed81923-749b-11df-b112-00215aee3ebe;Нововасилівка;Нововасильевка;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924483801;3;1
6ed81924-749b-11df-b112-00215aee3ebe;Нововасилівка;Нововасильевка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222386505;3;1
6ed81925-749b-11df-b112-00215aee3ebe;Нововасилівка;Нововасильевка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224584811;3;1
6ed81926-749b-11df-b112-00215aee3ebe;Нововасилівка;Нововасильевка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225283313;3;1
6ed81927-749b-11df-b112-00215aee3ebe;Нововасилівка;Нововасильевка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823083001;3;1
6ed81928-749b-11df-b112-00215aee3ebe;Нововасилівка;Нововасильевка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824581204;3;1
6ed81929-749b-11df-b112-00215aee3ebe;Нововасилівка;Нововасильевка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655118;3;1
6ed8192a-749b-11df-b112-00215aee3ebe;Нововасилівка;Нововасильевка;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822383601;3;1
6ed8192b-749b-11df-b112-00215aee3ebe;Нововасилівка;Нововасильевка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825782701;3;1
6ed8192d-749b-11df-b112-00215aee3ebe;Нововасилівка;Нововасильевка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321586107;3;1
6ed81930-749b-11df-b112-00215aee3ebe;Нововасилівка;Нововасильевка;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520984503;3;1
6ed81934-749b-11df-b112-00215aee3ebe;Нововасилівське;Нововасильевское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321584503;3;1
6ed81936-749b-11df-b112-00215aee3ebe;Нововасильківка;Нововасильковка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755128;3;1
6ed81937-749b-11df-b112-00215aee3ebe;Нововербське;Нововербское;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223881004;3;1
e0517ee0-db89-11df-9197-00215aee3ebe;Нововеселівка;Нововеселовка;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;3;1
6ed81939-749b-11df-b112-00215aee3ebe;Нововікторівка;Нововикторовка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323686610;3;1
6ed8193b-749b-11df-b112-00215aee3ebe;Нововікторівка;Нововикторовка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521780805;3;1
6ed8193c-749b-11df-b112-00215aee3ebe;Нововітебське;Нововитебское;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225283314;3;1
6ed81940-749b-11df-b112-00215aee3ebe;Нововодяне;Нововодяное;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524084005;3;1
6ed81941-749b-11df-b112-00215aee3ebe;Нововодяне;Нововодяное;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522282605;3;1
6ed81942-749b-11df-b112-00215aee3ebe;Нововоздвиженка;Нововоздвиженка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123383705;3;1
6ed81943-749b-11df-b112-00215aee3ebe;Нововознесенка;Нововознесенка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322181303;3;1
6ed81944-749b-11df-b112-00215aee3ebe;Нововознесенка;Нововознесенка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523183701;3;1
17af7b2c-ddb9-11df-9197-00215aee3ebe;Нововознесенка;Нововознесенка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224855605;3;1
6ed81945-749b-11df-b112-00215aee3ebe;Нововознесенське;Нововознесенское;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521882501;3;1
6ed81947-749b-11df-b112-00215aee3ebe;Нововолинськ;Нововолынск;місто;5394b340-41be-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0710700000;1;0
6ed81948-749b-11df-b112-00215aee3ebe;Нововолодимирівка;Нововладимировка;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924485202;3;1
6ed81949-749b-11df-b112-00215aee3ebe;Нововолодимирівка;Нововладимировка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823080404;3;1
6ed8194a-749b-11df-b112-00215aee3ebe;Нововолодимирівка;Нововладимировка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655119;3;1
6ed8194b-749b-11df-b112-00215aee3ebe;Нововолодимирівка;Нововладимировка;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821183550;3;1
6ed8194c-749b-11df-b112-00215aee3ebe;Нововолодимирівка;Нововладимировка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324883505;3;1
6ed8194d-749b-11df-b112-00215aee3ebe;Нововолодимирівка;Нововладимировка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323680803;3;1
6ed81950-749b-11df-b112-00215aee3ebe;Нововолодимирівка;Нововладимировка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522585404;3;1
6ed81951-749b-11df-b112-00215aee3ebe;Нововолодимирівка;Нововладимировка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822489803;3;1
6ed81952-749b-11df-b112-00215aee3ebe;Нововоронцовка;Нововоронцовка;місто;78f4540d-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6524155100;3;1
6ed81953-749b-11df-b112-00215aee3ebe;Нововоскресенівка;Нововоскресеновка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755129;3;1
6ed81954-749b-11df-b112-00215aee3ebe;Нововоскресенка;Нововоскресенка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821755116;3;1
6ed81955-749b-11df-b112-00215aee3ebe;Нововоскресенське;Нововоскресенское;село;78f4540d-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6524184001;3;1
6ed81957-749b-11df-b112-00215aee3ebe;Новоганнівка;Новоанновка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221084108;3;1
6ed81958-749b-11df-b112-00215aee3ebe;Новоганнівка;Новоанновка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221884005;3;1
6ed8195a-749b-11df-b112-00215aee3ebe;Новогеоргіївка;Новогеоргиевка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224282304;3;1
6ed8195b-749b-11df-b112-00215aee3ebe;Новогеоргіївка;Новогеоргиевка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610111;3;1
6ed8195c-749b-11df-b112-00215aee3ebe;Новогеоргіївка;Новогеоргиевка;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120284601;3;1
6ed8195d-749b-11df-b112-00215aee3ebe;Новоглиняне;Новоглиняное;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521781203;3;1
6ed8195f-749b-11df-b112-00215aee3ebe;Новогніде;Новогнедое;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224886401;3;1
6ed81960-749b-11df-b112-00215aee3ebe;Новогомельське;Новогомельское;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520881605;3;1
6ed81962-749b-11df-b112-00215aee3ebe;Новогородецьке;Новогородецкое;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822581707;3;1
6ed81963-749b-11df-b112-00215aee3ebe;Новогорожене;Новогорожено;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610112;3;1
6ed81965-749b-11df-b112-00215aee3ebe;Новоградениця;Новограденица;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123980706;3;1
6ed81966-749b-11df-b112-00215aee3ebe;Новоградівка;Новоградовка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520885601;3;1
6ed81967-749b-11df-b112-00215aee3ebe;Новоградківка;Новоградковка;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123783001;3;1
6ed81968-749b-11df-b112-00215aee3ebe;Новогреднєве;Новогреднево;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520980505;3;1
6ed81969-749b-11df-b112-00215aee3ebe;Новогригорівка;Новогригоровка;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955107;3;1
6ed8196a-749b-11df-b112-00215aee3ebe;Новогригорівка;Новогригоровка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221010101;3;1
6ed8196b-749b-11df-b112-00215aee3ebe;Новогригорівка;Новогригоровка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220787708;3;1
6ed8196c-749b-11df-b112-00215aee3ebe;Новогригорівка;Новогригоровка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655120;3;1
6ed8196d-749b-11df-b112-00215aee3ebe;Новогригорівка;Новогригоровка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221882404;3;1
6ed8196e-749b-11df-b112-00215aee3ebe;Новогригорівка;Новогригоровка;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820380406;3;1
6ed8196f-749b-11df-b112-00215aee3ebe;Новогригорівка;Новогригоровка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824581505;3;1
6ed81970-749b-11df-b112-00215aee3ebe;Новогригорівка;Новогригоровка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823684803;3;1
6ed81971-749b-11df-b112-00215aee3ebe;Новогригорівка;Новогригоровка;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821180402;3;1
6ed81972-749b-11df-b112-00215aee3ebe;Новогригорівка;Новогригоровка;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822380150;3;1
6ed81973-749b-11df-b112-00215aee3ebe;Новогригорівка;Новогригоровка;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083201;3;1
6ed81974-749b-11df-b112-00215aee3ebe;Новогригорівка;Новогригоровка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821783404;3;1
6ed81975-749b-11df-b112-00215aee3ebe;Новогригорівка;Новогригоровка;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824284604;3;1
6ed81976-749b-11df-b112-00215aee3ebe;Новогригорівка;Новогригоровка;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823384705;3;1
6ed81977-749b-11df-b112-00215aee3ebe;Новогригорівка;Новогригоровка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123582803;3;1
6ed81978-749b-11df-b112-00215aee3ebe;Новогригорівка;Новогригоровка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121286104;3;1
6ed81979-749b-11df-b112-00215aee3ebe;Новогригорівка;Новогригоровка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323685906;3;1
6ed8197b-749b-11df-b112-00215aee3ebe;Новогригорівка;Новогригоровка;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520983503;3;1
6ed81985-749b-11df-b112-00215aee3ebe;Новогригорівка;Новогригоровка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523183801;3;1
6ed81986-749b-11df-b112-00215aee3ebe;Новогригорівка;Новогригоровка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522582402;3;1
6ed81987-749b-11df-b112-00215aee3ebe;Новогригорівка Друга;Новогригоровка Вторая;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521986401;3;1
6ed81988-749b-11df-b112-00215aee3ebe;Новогригорівка Перша;Новогригоровка Первая;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521986901;3;1
6ed81989-749b-11df-b112-00215aee3ebe;Новогригорівське;Новогригоровское;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521882002;3;1
6ed8198c-749b-11df-b112-00215aee3ebe;Новогуйвинське;Новогуйвинское;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822055600;1;1
6ed8198d-749b-11df-b112-00215aee3ebe;Новогуляївка;Новогуляевка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125484703;3;1
6ed8198e-749b-11df-b112-00215aee3ebe;Новогупалівка;Новогупаловка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321585601;3;1
6ed8198f-749b-11df-b112-00215aee3ebe;Новогурівка;Новогуровка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222083007;3;1
6ed81990-749b-11df-b112-00215aee3ebe;Новоданилівка;Новоданиловка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824581205;3;1
6ed81991-749b-11df-b112-00215aee3ebe;Новоданилівка;Новоданиловка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655121;3;1
6ed81992-749b-11df-b112-00215aee3ebe;Новоданилівка;Новоданиловка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323910105;3;1
6ed81994-749b-11df-b112-00215aee3ebe;Новоданилівка;Новоданиловка;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521980708;3;1
6ed81995-749b-11df-b112-00215aee3ebe;Новоданилівський;Новоданиловский;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;;3;1
6ed81996-749b-11df-b112-00215aee3ebe;Новодар;Новодар;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320882803;3;1
6ed81999-749b-11df-b112-00215aee3ebe;Новодачне;Новодачное;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120285005;3;1
6ed8199d-749b-11df-b112-00215aee3ebe;Новодмитрівка;Новодмитровка;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922355103;3;1
6ed8199e-749b-11df-b112-00215aee3ebe;Новодмитрівка;Новодмитровка;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1223880503;3;1
6ed8199f-749b-11df-b112-00215aee3ebe;Новодмитрівка;Новодмитровка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824581905;3;1
6ed819a0-749b-11df-b112-00215aee3ebe;Новодмитрівка;Новодмитровка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324884508;3;1
6ed819a1-749b-11df-b112-00215aee3ebe;Новодмитрівка;Новодмитровка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123980509;3;1
6ed819a2-749b-11df-b112-00215aee3ebe;Новодмитрівка;Новодмитровка;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520983001;3;1
6ed819a3-749b-11df-b112-00215aee3ebe;Новодмитрівка;Новодмитровка;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520387102;3;1
62c3d16c-749b-11df-b112-00215aee3ebe;Новодмитрівка Друга (Ленінське Друге);Новодмитровка Вторая(Ленинское Второе);село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123980704;3;1
6ed819a9-749b-11df-b112-00215aee3ebe;Новодніпровка;Новоднепровка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322181304;3;1
6ed819ab-749b-11df-b112-00215aee3ebe;Новодністровськ;Новоднестровск;місто;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7310600000;3;0
6ed819ac-749b-11df-b112-00215aee3ebe;Новодобрянка;Новодобрянка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521786302;3;1
6ed819b1-749b-11df-b112-00215aee3ebe;Новодорожнє;Новодорожное;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610103;3;1
6ed819b3-749b-11df-b112-00215aee3ebe;Новодубище;Новодубище;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822782103;3;1
6ed819b6-749b-11df-b112-00215aee3ebe;Новоєгорівка;Новоегоровка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610113;3;1
6ed819b8-749b-11df-b112-00215aee3ebe;Новоєгорівка;Новоегоровка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324585003;3;1
6ed819ba-749b-11df-b112-00215aee3ebe;Новоєгорівка;Новоегоровка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524084001;3;1
6ed819bc-749b-11df-b112-00215aee3ebe;Новоєлизаветівка;Новоелизаветовка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125483601;3;1
6ed819c2-749b-11df-b112-00215aee3ebe;Новоживотів;Новоживотов;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523183001;3;1
6ed819c4-749b-11df-b112-00215aee3ebe;Новожитлівка;Новожитловка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222083506;3;1
6ed819c5-749b-11df-b112-00215aee3ebe;Новожитомир;Новожитомир;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221883507;3;1
3608ba51-14ea-11ea-80e7-1c98ec135261;Новожуків;Новожуков;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;00000000-0000-0000-0000-000000000000;;;1
56bdd93f-749b-11df-b112-00215aee3ebe;Новожуків (Іскра);Новожукив (Искра);село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624687902;3;1
6ed819c6-749b-11df-b112-00215aee3ebe;Новозалісся;Новозалесье;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224584813;3;1
6ed819c7-749b-11df-b112-00215aee3ebe;Новозаріцьке;Новозарицкое;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125283201;3;1
6ed819cb-749b-11df-b112-00215aee3ebe;Новозелене;Новозеленое;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824086204;3;1
6ed819d1-749b-11df-b112-00215aee3ebe;Новозолотарівка;Новозолотаревка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520382402;3;1
6ed819d3-749b-11df-b112-00215aee3ebe;Новоіванівка;Новоивановка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324888213;3;1
6ed819d4-749b-11df-b112-00215aee3ebe;Новоіванівка;Новоивановка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920683008;3;1
6ed819d5-749b-11df-b112-00215aee3ebe;Новоіванівка;Новоивановка;село;4ffc4b7d-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1211090007;3;1
6ed819d6-749b-11df-b112-00215aee3ebe;Новоіванівка;Новоивановка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222386005;3;1
6ed819d7-749b-11df-b112-00215aee3ebe;Новоіванівка;Новоивановка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224585907;3;1
6ed819d8-749b-11df-b112-00215aee3ebe;Новоіванівка;Новоивановка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755130;3;1
6ed819d9-749b-11df-b112-00215aee3ebe;Новоіванівка;Новоивановка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222984301;3;1
6ed819da-749b-11df-b112-00215aee3ebe;Новоіванівка;Новоивановка;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1220310111;3;1
6ed819db-749b-11df-b112-00215aee3ebe;Новоіванівка;Новоивановка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610114;3;1
6ed819dc-749b-11df-b112-00215aee3ebe;Новоіванівка;Новоивановка;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120283903;3;1
6ed819de-749b-11df-b112-00215aee3ebe;Новоіванівка;Новоивановка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955417;3;1
6ed819e9-749b-11df-b112-00215aee3ebe;Новоіванівка;Новоивановка;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824782003;3;1
6ed819f0-749b-11df-b112-00215aee3ebe;Новоіванівка;Новоивановка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221882004;3;1
6ed819f1-749b-11df-b112-00215aee3ebe;Новоіванівка;Новоивановка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320680503;3;1
66d97de5-a3fd-11ea-80c8-000c29800ae7;Новоіванівка;Новоивановка;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;2;1
6ed819ea-749b-11df-b112-00215aee3ebe;Новоіванівське;Новоивановское;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225982801;3;1
6ed819eb-749b-11df-b112-00215aee3ebe;Новоіванівське;Новоивановское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321581403;3;1
6ed819ec-749b-11df-b112-00215aee3ebe;Новоіванківка;Новоиванковка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323683501;3;1
6ed819ed-749b-11df-b112-00215aee3ebe;Новоіванківці;Новоиванковцы;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323082803;3;1
6ed819ef-749b-11df-b112-00215aee3ebe;Новоівницьке;Новоивницке;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820387601;3;1
50c5943c-749b-11df-b112-00215aee3ebe;Новоігорівка (Димитрове);Новоигоревка (Димитрово);село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525882301;3;1
6ed819f2-749b-11df-b112-00215aee3ebe;Новоіларіонівське;Новоилларионовское;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224886807;3;1
6ed819f3-749b-11df-b112-00215aee3ebe;Новоінгулка;Новоингулка;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824883202;3;1
6ed819f5-749b-11df-b112-00215aee3ebe;Новокаїри;Новокаиры;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520684801;3;1
6ed819f6-749b-11df-b112-00215aee3ebe;Новокаланчак;Новокаланчак;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122081302;3;1
6ed819f7-749b-11df-b112-00215aee3ebe;Новокалинівка;Новокалиновка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924182304;3;1
6ed819f8-749b-11df-b112-00215aee3ebe;Новокалинівка;Новокалиновка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055115;3;1
871626f3-749b-11df-b112-00215aee3ebe;Новокальчеве (Червоноармійське;Новокальчево (Червоноармейское);село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121285601;3;1
6ed819fa-749b-11df-b112-00215aee3ebe;Новокам'янка;Новокаменка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1211390005;3;1
6ed819fb-749b-11df-b112-00215aee3ebe;Новокам'янка;Новокаменка;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122081702;2;1
6ed819fe-749b-11df-b112-00215aee3ebe;Новокантакузівка;Новокантакузовка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822782003;3;1
6ed819ff-749b-11df-b112-00215aee3ebe;Новокарбівка;Новокарбовка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123383201;3;1
6ed81a01-749b-11df-b112-00215aee3ebe;Новокасянівка;Новокасьяновка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323681304;3;1
6ed81a02-749b-11df-b112-00215aee3ebe;Новокатеринівка;Новокатериновка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821780603;3;1
6ed81a05-749b-11df-b112-00215aee3ebe;Новокатьощине;Новокатещино;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455119;3;1
6ed81a06-749b-11df-b112-00215aee3ebe;Новокиївка;Новокиевка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1211390006;3;1
6ed81a07-749b-11df-b112-00215aee3ebe;Новокиївка;Новокиевка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825782901;3;1
6ed81a0a-749b-11df-b112-00215aee3ebe;Новокиївка;Новокиевка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520887206;3;1
6ed81a0b-749b-11df-b112-00215aee3ebe;Новокиївка;Новокиевка;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525884703;3;1
6ed81a0f-749b-11df-b112-00215aee3ebe;Новокондакове;Новокондаково;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825782705;3;1
6ed81a10-749b-11df-b112-00215aee3ebe;Новокостянтинів;Новоконстантинов;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823084204;3;1
6ed81a11-749b-11df-b112-00215aee3ebe;Новокостянтинівка;Новоконстантиновка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821483401;3;1
6ed81a12-749b-11df-b112-00215aee3ebe;Новокостянтинівка;Новоконстантиновка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824583404;3;1
6ed81a13-749b-11df-b112-00215aee3ebe;Новокостянтинівка;Новоконстантиновка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123983904;3;1
6ed81a16-749b-11df-b112-00215aee3ebe;Новокотів;Новокотов;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722885604;3;1
6ed81a17-749b-11df-b112-00215aee3ebe;Новокрасне;Новокрасное;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820355102;3;1
6ed81a18-749b-11df-b112-00215aee3ebe;Новокрасне;Новокрасное;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123985403;3;1
6ed81a19-749b-11df-b112-00215aee3ebe;Новокрасне;Новокрасное;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123184902;3;1
6ed81a1a-749b-11df-b112-00215aee3ebe;Новокрасне;Новокрасное;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523184003;3;1
6ed81a1d-749b-11df-b112-00215aee3ebe;Новокраснопіль;Новокраснополь;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225483205;3;1
6ed81a21-749b-11df-b112-00215aee3ebe;Новокубанка;Новокубанка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122782605;3;1
6ed81a22-749b-11df-b112-00215aee3ebe;Новокурське;Новокурское;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225855109;3;1
6ed81a23-749b-11df-b112-00215aee3ebe;Новолабунь;Новолабунь;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823685001;3;1
6ed81a24-749b-11df-b112-00215aee3ebe;Новолазарівка;Новолазаревка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823684501;3;1
6ed81a26-749b-11df-b112-00215aee3ebe;Новолатівка;Новолатовка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885504;3;1
6ed81a27-749b-11df-b112-00215aee3ebe;Новоленськ;Новоленск;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820981503;3;1
f1eda950-3455-11e5-93c7-003048d2b473;Новолікарське;Новоликарське;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822781507;3;1
6ed81a2a-749b-11df-b112-00215aee3ebe;Новолозуватівка;Новолозоватовка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655106;3;1
6ed81a2b-749b-11df-b112-00215aee3ebe;Новолозуватка;Новолозоватка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221882205;3;1
6ed81a2d-749b-11df-b112-00215aee3ebe;Новолутківка;Новолутковка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521784401;3;1
6ed81a30-749b-11df-b112-00215aee3ebe;Новомайданецьке;Новомайданецкое;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124086602;3;1
6ed81a32-749b-11df-b112-00215aee3ebe;Новомайське;Новомайское;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221884713;3;1
6ed81a33-749b-11df-b112-00215aee3ebe;Новомалин;Новомалин;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624285601;3;1
6ed81a34-749b-11df-b112-00215aee3ebe;Новомалинівка;Новомалиновка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225884810;3;1
6ed81a35-749b-11df-b112-00215aee3ebe;Новомануйлівка;Новомануйловка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524955101;3;1
6ed81a36-749b-11df-b112-00215aee3ebe;Новомар'ївка;Новомарьевка;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225084501;3;1
6ed81a38-749b-11df-b112-00215aee3ebe;Новомар'ївка;Новомарьевка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821483901;3;1
6ed81a39-749b-11df-b112-00215aee3ebe;Новомар'ївка;Новомарьевка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320686305;3;1
e0517ed3-db89-11df-9197-00215aee3ebe;Новомар'ївка;Новомарьевка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820683404;3;1
6ed81a37-749b-11df-b112-00215aee3ebe;Новомар'янівка;Новомарьяновка;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220310103;3;1
6ed81a3d-749b-11df-b112-00215aee3ebe;Новомар'янівське;Новомарьяновское;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221881206;3;1
6ed81a3e-749b-11df-b112-00215aee3ebe;Новоматвіївське;Новоматвеевское;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824883204;3;1
cdc44f32-dde8-11df-9197-00215aee3ebe;Новомикільськ;Новоникольск;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522684503;3;1
6ed81a42-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924780304;3;1
6ed81a43-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221055800;3;1
6ed81a44-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221487503;3;1
6ed81a45-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224883507;3;1
6ed81a46-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455120;3;1
6ed81a47-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224282305;3;1
6ed81a48-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823080702;3;1
6ed81a49-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824582201;3;1
6ed81a4a-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824884602;3;1
6ed81a4b-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821755117;3;1
6ed81a4c-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823383401;3;1
6ed81a4d-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325785501;3;1
6ed81a4e-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
6ed81a4f-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320280403;3;1
6ed81a50-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684703;3;1
6ed81a51-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125281909;3;1
6ed81a52-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;c7bb5b2d-671b-11ea-80c6-000c29800ae7;5122784001;3;1
6ed81a53-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;903ccab3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122310304;3;1
6ed81a54-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;місто;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323655100;3;1
6ed81a5a-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521883001;3;1
6ed81a66-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120683502;3;1
6ed81a68-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523110105;3;1
6ed81a69-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521781505;3;1
6ed81a6a-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524084301;3;1
6ed81a6b-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520885801;3;1
6ed81a6c-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;;3;1
6ed81a6d-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523483901;3;1
6ed81a6e-749b-11df-b112-00215aee3ebe;Новомиколаївка;Новониколаевка;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823087005;3;1
6ed81a6f-749b-11df-b112-00215aee3ebe;Новомиколаївське;Новониколаевское;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223582808;3;1
6ed81a70-749b-11df-b112-00215aee3ebe;Новомиколаївське;Новониколаевское;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822381305;3;1
6ed81a71-749b-11df-b112-00215aee3ebe;Новомилорадівка;Новомилорадовка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055716;3;1
6ed81a72-749b-11df-b112-00215aee3ebe;Новомильськ;Новомыльск;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622682803;3;1
6ed81a73-749b-11df-b112-00215aee3ebe;Новомиргород;Новомиргород;місто;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523810100;2;0
6ed81a74-749b-11df-b112-00215aee3ebe;Новомиргородка;Новомиргородка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321580505;3;1
6ed81a75-749b-11df-b112-00215aee3ebe;Новомирівка;Новомировка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222783502;3;1
6ed81a76-749b-11df-b112-00215aee3ebe;Новомирне;Новомирное;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120481003;3;1
6ed81a77-749b-11df-b112-00215aee3ebe;Новомихайлівка;Новомихайловка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786305;3;1
6ed81a78-749b-11df-b112-00215aee3ebe;Новомихайлівка;Новомихайловка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225286608;3;1
6ed81a79-749b-11df-b112-00215aee3ebe;Новомихайлівка;Новомихайловка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455121;3;1
6ed81a7a-749b-11df-b112-00215aee3ebe;Новомихайлівка;Новомихайловка;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820380407;3;1
6ed81a7b-749b-11df-b112-00215aee3ebe;Новомихайлівка;Новомихайловка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824581901;3;1
6ed81a7c-749b-11df-b112-00215aee3ebe;Новомихайлівка;Новомихайловка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324886005;3;1
6ed81a7d-749b-11df-b112-00215aee3ebe;Новомихайлівка;Новомихайловка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123182504;3;1
6ed81a7e-749b-11df-b112-00215aee3ebe;Новомихайлівка;Новомихайловка;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125085613;2;1
6ed81a7f-749b-11df-b112-00215aee3ebe;Новомихайлівка;Новомихайловка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955418;3;1
6ed81a85-749b-11df-b112-00215aee3ebe;Новомихайлівка;Новомихайловка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523184004;3;1
6ed81a86-749b-11df-b112-00215aee3ebe;Новомихайлівка;Новомихайловка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521786702;3;1
6ed81a87-749b-11df-b112-00215aee3ebe;Новомихайлівка;Новомихайловка;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521980402;3;1
6ed81a88-749b-11df-b112-00215aee3ebe;Новомихайлівське;Новомихайловское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321584504;3;1
56bdd237-749b-11df-b112-00215aee3ebe;Новомихайлівське (Жовтневе);Новомихайлівське (Жовтневе);село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822381701;3;1
6ed81a8b-749b-11df-b112-00215aee3ebe;Новомосковськ;Новомосковск;місто;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1211900000;2;0
6ed81a8c-749b-11df-b112-00215aee3ebe;Новомосковське;Новомосковское;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610104;3;1
6ed81a8d-749b-11df-b112-00215aee3ebe;Новомосковське;Новомосковское;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955114;3;1
6ed81a8f-749b-11df-b112-00215aee3ebe;Новомосковське;Новомосковское;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521981302;3;1
6ed81a90-749b-11df-b112-00215aee3ebe;Новомутин;Новомутин;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922083803;3;1
6ed81a91-749b-11df-b112-00215aee3ebe;Новонадеждине;Новонадеждино;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684601;3;1
6ed81a94-749b-11df-b112-00215aee3ebe;Новоодеса;Новоодесса;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521786303;3;1
6ed81a95-749b-11df-b112-00215aee3ebe;Новоозерне;Новоозерное;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122084802;3;1
5dd641cd-d5de-11ea-80ca-000c29800ae7;Новоозерянка;Новоозерянка;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;;3;1
cdc44f3f-dde8-11df-9197-00215aee3ebe;Новоолександрівка;Новоалександровка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224886001;3;1
8cdd5cdb-de27-11df-9197-00215aee3ebe;Новоолександрівка;Новоалександровка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;;3;1
6ed81a9b-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655121;3;1
6ed81a9c-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224284511;3;1
6ed81a9d-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825487604;3;1
6ed81a9e-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821483001;3;1
6ed81a9f-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823081703;3;1
6ed81aa0-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823681104;3;1
6ed81aa1-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820683701;3;1
6ed81aa2-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821184803;3;1
6ed81aa4-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324884501;3;1
6ed81aa5-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320685505;3;1
6ed81aa6-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120283901;3;1
6ed81aa7-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123382906;3;1
6ed81aa8-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121682801;3;1
6ed81aa9-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322187901;3;1
6ed81aac-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;78f4540d-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6524183901;3;1
6ed81aba-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821780306;3;1
6ed81abb-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523183803;3;1
6ed81abc-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524010102;3;1
6ed81abd-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522285402;3;1
6ed81abe-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522585401;3;1
6ed81abf-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;;3;1
6ed81ac0-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521985601;3;1
6ed81a98-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922382903;3;1
6ed81a99-749b-11df-b112-00215aee3ebe;Новоолександрівка;Новоалександровка;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221486201;2;0
6ed81ac1-749b-11df-b112-00215aee3ebe;Новоолександрівське;Новоалександровское;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223584511;3;1
6ed81ac2-749b-11df-b112-00215aee3ebe;Новоолександрівське;Новоалександровское;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824882604;3;1
cdc44f40-dde8-11df-9197-00215aee3ebe;Новоолександрівське;Новоалександровское;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224856202;3;1
6ed81a9a-749b-11df-b112-00215aee3ebe;Новоолександропіль;Новоалександрополь;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224856203;3;1
6ed81ac3-749b-11df-b112-00215aee3ebe;Новоолексіївка;Новоалексеевка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224881103;3;1
6ed81ac4-749b-11df-b112-00215aee3ebe;Новоолексіївка;Новоалексеевка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225283315;3;1
6ed81ac5-749b-11df-b112-00215aee3ebe;Новоолексіївка;Новоалексеевка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821455105;3;1
6ed81ac6-749b-11df-b112-00215aee3ebe;Новоолексіївка;Новоалексеевка;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822380405;3;1
6ed81ac7-749b-11df-b112-00215aee3ebe;Новоолексіївка;Новоалексеевка;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324080502;3;1
6ed81ace-749b-11df-b112-00215aee3ebe;Новоолексіївка;Новоалексеевка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520384603;3;1
6ed81acf-749b-11df-b112-00215aee3ebe;Новооленівка;Новоеленовка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322187902;3;1
6ed81ad3-749b-11df-b112-00215aee3ebe;Новооникієве;Новоаникиево;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523184005;3;1
6ed81ad4-749b-11df-b112-00215aee3ebe;Новооржицьке;Новооржицкое;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323655600;3;1
6ed81ad5-749b-11df-b112-00215aee3ebe;Новооріхівка;Новоореховка;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322885201;3;1
6ed81ad9-749b-11df-b112-00215aee3ebe;Новоочаків;Новоочаков;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821183801;3;1
6ed81adb-749b-11df-b112-00215aee3ebe;Новопавлівка;Новопавловка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222685501;3;1
6ed81adc-749b-11df-b112-00215aee3ebe;Новопавлівка;Новопавловка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225482006;3;1
6ed81add-749b-11df-b112-00215aee3ebe;Новопавлівка;Новопавловка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825484602;3;1
6ed81ade-749b-11df-b112-00215aee3ebe;Новопавлівка;Новопавловка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610115;3;1
6ed81adf-749b-11df-b112-00215aee3ebe;Новопавлівка;Новопавловка;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822383801;3;1
6ed81ae0-749b-11df-b112-00215aee3ebe;Новопавлівка;Новопавловка;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824884603;3;1
6ed81ae1-749b-11df-b112-00215aee3ebe;Новопавлівка;Новопавловка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821755118;3;1
6ed81ae2-749b-11df-b112-00215aee3ebe;Новопавлівка;Новопавловка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323381206;3;1
6ed81ae3-749b-11df-b112-00215aee3ebe;Новопавлівка;Новопавловка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322883503;3;1
6ed81ae4-749b-11df-b112-00215aee3ebe;Новопавлівка;Новопавловка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320482513;3;1
6ed81ae5-749b-11df-b112-00215aee3ebe;Новопавлівка;Новопавловка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320685506;3;1
6ed81ae6-749b-11df-b112-00215aee3ebe;Новопавлівка;Новопавловка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125255113;3;1
6ed81ae7-749b-11df-b112-00215aee3ebe;Новопавлівка;Новопавловка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125482902;3;1
6ed81ae8-749b-11df-b112-00215aee3ebe;Новопавлівка;Новопавловка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655505;3;1
6ed81ae9-749b-11df-b112-00215aee3ebe;Новопавлівка;Новопавловка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323910106;3;1
6ed81aea-749b-11df-b112-00215aee3ebe;Новопавлівка;Новопавловка;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520984001;3;1
6ed81af4-749b-11df-b112-00215aee3ebe;Новопавлівка;Новопавловка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523186805;3;1
6ed81af5-749b-11df-b112-00215aee3ebe;Новопавлівка;Новопавловка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524084802;3;1
6ed81af6-749b-11df-b112-00215aee3ebe;Новопавлівське;Новопавловское;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825782002;3;1
17af7b2d-ddb9-11df-9197-00215aee3ebe;Новопавлоградське;Новопавлоградское;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224888204;3;1
6ed81af7-749b-11df-b112-00215aee3ebe;Новопаволоцьке;Новопаволоцкое;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824780302;3;1
6ed81afa-749b-11df-b112-00215aee3ebe;Новопетрівка;Новопетровка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920684904;3;1
6ed81afb-749b-11df-b112-00215aee3ebe;Новопетрівка;Новопетровка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924180603;3;1
6ed81afc-749b-11df-b112-00215aee3ebe;Новопетрівка;Новопетровка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922989603;3;1
6ed81afd-749b-11df-b112-00215aee3ebe;Новопетрівка;Новопетровка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222385001;3;1
6ed81afe-749b-11df-b112-00215aee3ebe;Новопетрівка;Новопетровка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225285503;3;1
6ed81aff-749b-11df-b112-00215aee3ebe;Новопетрівка;Новопетровка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821483403;3;1
6ed81b00-749b-11df-b112-00215aee3ebe;Новопетрівка;Новопетровка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824580407;3;1
6ed81b01-749b-11df-b112-00215aee3ebe;Новопетрівка;Новопетровка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820681805;3;1
6ed81b02-749b-11df-b112-00215aee3ebe;Новопетрівка;Новопетровка;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;3;1
6ed81b03-749b-11df-b112-00215aee3ebe;Новопетрівка;Новопетровка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825783201;3;1
6ed81b04-749b-11df-b112-00215aee3ebe;Новопетрівка;Новопетровка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;3;1
6ed81b05-749b-11df-b112-00215aee3ebe;Новопетрівка;Новопетровка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123583001;3;1
6ed81b06-749b-11df-b112-00215aee3ebe;Новопетрівка;Новопетровка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655116;3;1
6ed81b07-749b-11df-b112-00215aee3ebe;Новопетрівка;Новопетровка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322187206;3;1
6ed81b0a-749b-11df-b112-00215aee3ebe;Новопетрівка;Новопетровка;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521883501;3;1
6ed81b0d-749b-11df-b112-00215aee3ebe;Новопетрівка;Новопетровка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523680403;3;1
6ed81b0e-749b-11df-b112-00215aee3ebe;Новопетрівка;Новопетровка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523183805;3;1
6ed81b0f-749b-11df-b112-00215aee3ebe;Новопетрівка;Новопетровка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521784002;3;1
6ed81b10-749b-11df-b112-00215aee3ebe;Новопетрівка;Новопетровка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522587204;3;0
6ed81b11-749b-11df-b112-00215aee3ebe;Новопетрівка;Новопетровка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524985905;3;1
6ed81b12-749b-11df-b112-00215aee3ebe;Новопетрівка Друга;Новопетровка Вторая;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125483603;3;1
6ed81b13-749b-11df-b112-00215aee3ebe;Новопетрівка Перша;Новопетровка Первая;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125483602;3;1
6ed81b14-749b-11df-b112-00215aee3ebe;Новопетрівське;Новопетровское;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224282306;3;1
6ed81b15-749b-11df-b112-00215aee3ebe;Новопетрівське;Новопетровское;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824883901;3;1
6ed81b18-749b-11df-b112-00215aee3ebe;Новопетрівське;Новопетровское;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425382803;3;1
6ed81b17-749b-11df-b112-00215aee3ebe;Новопетровське;Новопетровское;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;*122268330;3;1
6ed81b1a-749b-11df-b112-00215aee3ebe;Новопідгірне;Новоподгорное;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055102;3;1
6ed81b1b-749b-11df-b112-00215aee3ebe;Новопідгородне;Новоподгородное;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655122;3;1
6ed81b1c-749b-11df-b112-00215aee3ebe;Новопідкряж;Новоподкряж;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225684001;3;1
6ed81b1d-749b-11df-b112-00215aee3ebe;Новопілля;Новополье;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221884701;3;1
6ed81b20-749b-11df-b112-00215aee3ebe;Новопіль;Новополь;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825686201;3;1
6ed81b21-749b-11df-b112-00215aee3ebe;Новоплатонівка;Новоплатоновка;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321055102;3;1
6ed81b22-749b-11df-b112-00215aee3ebe;Новоподимка;Новоподымка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524084304;3;1
6ed81b23-749b-11df-b112-00215aee3ebe;Новоподілля;Новоподолье;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820980902;3;1
6ed81b24-749b-11df-b112-00215aee3ebe;Новоподільське;Новоподольское;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225283316;3;1
6ed81b25-749b-11df-b112-00215aee3ebe;Новоподільське;Новоподольское;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283403;3;1
6ed81b26-749b-11df-b112-00215aee3ebe;Новопокровка;Новопокровка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225055400;3;1
6ed81b27-749b-11df-b112-00215aee3ebe;Новопокровка;Новопокровка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221885906;3;1
6ed81b28-749b-11df-b112-00215aee3ebe;Новопокровка;Новопокровка;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325456200;3;1
6ed81b29-749b-11df-b112-00215aee3ebe;Новопокровка;Новопокровка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320680504;3;1
6ed81b2a-749b-11df-b112-00215aee3ebe;Новопокровка;Новопокровка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123585004;3;1
6ed81b2b-749b-11df-b112-00215aee3ebe;Новопокровка;Новопокровка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323982504;3;1
6ed81b31-749b-11df-b112-00215aee3ebe;Новопокровка;Новопокровка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522283702;3;1
6ed81b33-749b-11df-b112-00215aee3ebe;Новополтавка;Новополтавка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824582601;3;1
6ed81b35-749b-11df-b112-00215aee3ebe;Новополтавка;Новополтавка;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520955703;3;1
6ed81b37-749b-11df-b112-00215aee3ebe;Новополяна;Новополяна;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522282606;3;1
1dba1f18-56de-11e9-80df-1c98ec135261;Новополь;Новополь;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120688801;3;1
87162277-749b-11df-b112-00215aee3ebe;Новополь (Ухожани);Новополь (Ухожаны);село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120688801;3;1
6ed81b38-749b-11df-b112-00215aee3ebe;Новопостроєне;Новопостроенное;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920385403;3;1
6ed81b3b-749b-11df-b112-00215aee3ebe;Новопристань;Новопристань;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083815;3;1
6ed81b3c-749b-11df-b112-00215aee3ebe;Новопричепилівка;Новопричепиловка;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223882508;3;1
6ed81b3f-749b-11df-b112-00215aee3ebe;Новопрутівка;Новопрутовка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821484005;3;1
6ed81b42-749b-11df-b112-00215aee3ebe;Новопушкарівка;Новопушкаревка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055103;3;1
6ed81b43-749b-11df-b112-00215aee3ebe;Новорайськ;Новорайск;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520685201;3;1
6ed81b46-749b-11df-b112-00215aee3ebe;Новорічиця;Новоречица;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622284401;3;1
6ed81b48-749b-11df-b112-00215aee3ebe;Новородчиці;Новородчицы;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624286001;3;1
6ed81b49-749b-11df-b112-00215aee3ebe;Новорозанівка;Новорозановка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824581507;3;1
6ed81b4a-749b-11df-b112-00215aee3ebe;Новорозівка;Новорозовка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123180803;3;1
6ed81b4b-749b-11df-b112-00215aee3ebe;Новорозівка;Новорозовка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955419;3;1
6ed81b4d-749b-11df-b112-00215aee3ebe;Новороманівка;Новоромановка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655506;3;1
6ed81b4f-749b-11df-b112-00215aee3ebe;Новороманівка;Новоромановка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522280802;3;1
6ed81b50-749b-11df-b112-00215aee3ebe;Новоросійське;Новороссийское;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821181707;3;1
6ed81b52-749b-11df-b112-00215aee3ebe;Новоруське;Новорусское;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823382002;3;1
6ed81b53-749b-11df-b112-00215aee3ebe;Новосавицьке;Новосавицкое;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121683301;3;1
6ed81b54-749b-11df-b112-00215aee3ebe;Новосавицьке;Новосавицкое;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521987802;3;1
6ed81b56-749b-11df-b112-00215aee3ebe;Новосамара;Новосамара;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520885603;3;1
6ed81b57-749b-11df-b112-00215aee3ebe;Новосамарка;Новосамарка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123183001;3;1
6ed81b59-749b-11df-b112-00215aee3ebe;Новосафронівка;Новосафроновка;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824884201;3;1
6ed81b5a-749b-11df-b112-00215aee3ebe;Новосвітівка;Новосветовка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125484001;3;1
6ed81b5b-749b-11df-b112-00215aee3ebe;Новосвітлівка;Новосветловка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821783001;3;1
6ed81b5d-749b-11df-b112-00215aee3ebe;Новосевастополь;Новосевастополь;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821184201;3;1
6ed81b60-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324555111;3;1
6ed81b61-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922683903;3;1
6ed81b62-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924782904;3;1
6ed81b63-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322087201;3;1
6ed81b64-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;місто;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323010100;3;0
6ed81b65-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125082703;2;1
6ed81b66-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622083901;3;1
6ed81b67-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625283501;3;1
6ed81b68-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123282001;3;1
6ed81b69-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122788202;3;1
6ed81b6a-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122484801;3;1
6ed81b6b-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121282701;3;1
6ed81b6c-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124485301;3;1
6ed81b6d-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822083203;3;1
6ed81b6e-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823484803;3;1
6ed81b6f-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824784501;3;1
6ed81b70-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422286505;3;1
6ed81b71-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420687405;3;1
6ed81b72-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125185205;3;1
6ed81b73-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122284001;3;1
6ed81b74-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125484501;3;1
6ed81b75-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522481305;3;1
6ed81b76-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521083409;3;1
6ed81b77-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525586001;3;1
6ed81b78-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823685501;3;1
6ed81b79-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824285604;3;1
6ed81b7a-749b-11df-b112-00215aee3ebe;Новоселиця;Новоселица;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323288603;3;1
6ed81b7b-749b-11df-b112-00215aee3ebe;Новоселище;Новоселище;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322180407;3;1
6ed81b7c-749b-11df-b112-00215aee3ebe;Новоселище;Новоселище;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621885204;3;1
6ed81b7d-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321383801;3;1
6ed81b7e-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610117;3;1
6ed81b7f-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322080404;3;1
6ed81b80-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322480404;3;1
6ed81b81-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;;3;1
6ed81b82-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922085004;3;1
6ed81b83-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922989103;3;1
6ed81b84-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925080405;3;1
6ed81b85-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225685006;3;1
6ed81b86-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223285502;3;1
6ed81b87-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221081006;3;1
6ed81b88-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222084501;3;1
6ed81b89-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055112;3;1
6ed81b8a-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223886006;3;1
6ed81b8b-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221888204;3;1
6ed81b8c-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222984303;3;1
6ed81b8d-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224283405;3;1
6ed81b8e-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885501;3;1
6ed81b8f-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820382601;3;1
6ed81b90-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824581206;3;1
6ed81b91-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822781203;3;1
6ed81b92-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824284605;3;1
6ed81b93-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823355703;3;1
6ed81b94-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820955116;3;1
6ed81b95-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320885005;3;1
6ed81b97-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321281005;3;1
6ed81b98-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324255101;3;1
6ed81b99-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324255101;3;1
6ed81b9a-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320286204;3;1
6ed81b9b-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322284502;3;1
6ed81b9c-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323984505;3;1
6ed81b9d-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320686306;3;1
6ed81b9e-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122985601;3;1
6ed81b9f-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120284801;3;1
6ed81ba0-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123383401;3;1
6ed81ba1-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121681902;3;1
6ed81ba2-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283401;3;1
6ed81ba3-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123980403;3;1
6ed81ba4-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124583901;3;1
6ed81ba5-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;903ccab3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122382601;3;1
6ed81ba6-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120481902;3;1
6ed81ba7-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124782850;3;1
6ed81ba8-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321586108;3;1
6ed81ba9-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323987808;3;1
6ed81bac-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621682902;3;1
6ed81bba-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422487905;3;1
6ed81bbb-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425585701;1;0
6ed81bbc-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423683208;3;1
6ed81bbd-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425382503;3;1
6ed81bbe-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124981505;3;1
6ed81bbf-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523086101;3;1
6ed81bc0-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520880609;3;1
6ed81bc1-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524083405;3;1
6ed81bc2-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623881305;3;1
6ed81bc3-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320482603;3;1
6ed81bc4-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320886604;3;1
6ed81bc5-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323680405;3;1
6ed81b5e-749b-11df-b112-00215aee3ebe;Новоселівка;Новоселовка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324084001;3;1
5cb619ae-749b-11df-b112-00215aee3ebe;Новоселівка ( Комінтерн);Коминтерн;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520383701;3;1
6ed81bc9-749b-11df-b112-00215aee3ebe;Новоселівське;Новоселовское;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223584504;3;1
6ed81bcc-749b-11df-b112-00215aee3ebe;Новосеменів;Новосеменов;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820387303;3;1
6ed81bcd-749b-11df-b112-00215aee3ebe;Новосеменівка;Новосеменовка;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923286202;3;1
6ed81bce-749b-11df-b112-00215aee3ebe;Новосеменівка;Новосеменовка;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220310112;3;1
6ed81bcf-749b-11df-b112-00215aee3ebe;Новосеменівка;Новосеменовка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123182505;3;1
6ed81bd1-749b-11df-b112-00215aee3ebe;Новосергіївка;Новосергеевка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610116;3;1
6ed81bd2-749b-11df-b112-00215aee3ebe;Новосергіївка;Новосергеевка;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821183555;3;1
6ed81bd3-749b-11df-b112-00215aee3ebe;Новосергіївка;Новосергеевка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322185702;3;1
c6cc1c09-db7e-11df-9197-00215aee3ebe;Новосергіївка;Новосергеевка;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321084505;3;1
6ed81bd6-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125882402;3;1
6ed81bd7-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124684801;3;1
6ed81bd8-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124885501;3;1
6ed81bd9-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121684203;3;1
6ed81bda-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121288404;3;1
6ed81bdb-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122086201;3;1
6ed81bdc-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083816;3;1
6ed81bdd-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821783405;3;1
6ed81bde-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124786903;3;1
6ed81bdf-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520686602;3;1
6ed81be1-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625687205;3;1
6ed81be2-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623685603;3;1
6ed81be4-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821780803;3;1
6ed81be5-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822885204;3;1
6ed81be6-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825086904;3;1
6ed81be7-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825655101;3;1
6ed81be8-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123482003;3;1
6ed81be9-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522880404;3;1
6ed81bea-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521486505;3;1
6ed81beb-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822182204;3;1
6ed81bec-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822780803;3;1
6ed81bed-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821280402;3;1
6ed81bee-749b-11df-b112-00215aee3ebe;Новосілка;Новоселка;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821587801;3;1
6ed81bef-749b-11df-b112-00215aee3ebe;Новосілки;Новоселки;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723683002;3;1
6ed81bf0-749b-11df-b112-00215aee3ebe;Новосілки;Новоселки;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720581603;3;1
6ed81bf1-749b-11df-b112-00215aee3ebe;Новосілки;Новоселки;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725583601;3;1
6ed81bf2-749b-11df-b112-00215aee3ebe;Новосілки;Новоселки;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720884801;3;1
6ed81bf3-749b-11df-b112-00215aee3ebe;Новосілки;Новоселки;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620684001;3;1
6ed81bf4-749b-11df-b112-00215aee3ebe;Новосілки;Новоселки;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621884301;3;1
6ed81bf5-749b-11df-b112-00215aee3ebe;Новосілки;Новоселки;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625882203;3;1
6ed81bf6-749b-11df-b112-00215aee3ebe;Новосілки;Новоселки;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623380402;3;1
6ed81bf7-749b-11df-b112-00215aee3ebe;Новосілки;Новоселки;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221886401;3;1
6ed81bf8-749b-11df-b112-00215aee3ebe;Новосілки;Новоселки;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222786101;3;1
6ed81bf9-749b-11df-b112-00215aee3ebe;Новосілки;Новоселки;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222285801;3;1
6ed81bfa-749b-11df-b112-00215aee3ebe;Новосілки;Новоселки;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824286505;3;1
6ed81bfb-749b-11df-b112-00215aee3ebe;Новосілки;Новоселки;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424456403;3;1
6ed81bfc-749b-11df-b112-00215aee3ebe;Новосілки;Новоселки;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620880903;3;1
6ed81bfd-749b-11df-b112-00215aee3ebe;Новосілки;Новоселки;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623885201;3;1
6ed81bfe-749b-11df-b112-00215aee3ebe;Новосілки;Новоселки;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622684601;3;1
325c57ec-ddd2-11df-9197-00215aee3ebe;Новосілки;Новоселки;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222457401;3;0
6ed81bff-749b-11df-b112-00215aee3ebe;Новосілки-Гостинні;Новоселки-Гостинные;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624288601;3;1
6ed81c00-749b-11df-b112-00215aee3ebe;Новосілки-Опарські;Новоселки-Опарские;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623086001;3;1
6ed81c01-749b-11df-b112-00215aee3ebe;Новосілля;Новоселье;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824510104;3;1
6ed81c02-749b-11df-b112-00215aee3ebe;Новосілля;Новоселье;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823681403;3;1
6ed81c03-749b-11df-b112-00215aee3ebe;Новосілля;Новоселье;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622282302;3;1
6ed81c04-749b-11df-b112-00215aee3ebe;Новосільське;Новосельское;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922981603;3;1
6ed81c05-749b-11df-b112-00215aee3ebe;Новосільське;Новосельское;село;d00d3b62-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5124182901;3;1
6ed81c09-749b-11df-b112-00215aee3ebe;Новосільці;Новосельцы;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123985603;3;1
6ed81c0a-749b-11df-b112-00215aee3ebe;Новосільці;Новосельцы;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622482404;3;1
6ed81c0b-749b-11df-b112-00215aee3ebe;Новосільці;Новосельцы;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621585002;3;1
6ed81c0e-749b-11df-b112-00215aee3ebe;Новоскелювате;Новоскелюватое;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224280513;3;1
6ed81c0f-749b-11df-b112-00215aee3ebe;Новоскелюватка;Новоскелеватка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823682206;3;1
6ed81c10-749b-11df-b112-00215aee3ebe;Новоскотувате;Новоскотоватое;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223283303;3;1
6ed81c11-749b-11df-b112-00215aee3ebe;Новослобідка;Новослободка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322183506;3;0
6ed81c12-749b-11df-b112-00215aee3ebe;Новослобідське;Новослободское;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923884802;3;1
6ed81c14-749b-11df-b112-00215aee3ebe;Новосолоне;Новосоленое;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323684005;3;1
6ed81c15-749b-11df-b112-00215aee3ebe;Новосолошине;Новосолошино;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323987809;3;1
6ed81c16-749b-11df-b112-00215aee3ebe;Новософіївка;Новософиевка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222984501;3;1
6ed81c17-749b-11df-b112-00215aee3ebe;Новософіївка;Новософиевка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825780704;3;1
6ed81c18-749b-11df-b112-00215aee3ebe;Новософіївка;Новософиевка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320882005;3;1
6ed81c19-749b-11df-b112-00215aee3ebe;Новософіївка;Новософиевка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321581404;3;1
6ed81c1c-749b-11df-b112-00215aee3ebe;Новоспаське;Новосспаское;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222386506;3;1
6ed81c1e-749b-11df-b112-00215aee3ebe;Новостав;Новостав;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722880705;3;1
6ed81c1f-749b-11df-b112-00215aee3ebe;Новостав;Новостав;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720881403;3;1
6ed81c20-749b-11df-b112-00215aee3ebe;Новостав;Новостав;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125889502;3;1
6ed81c21-749b-11df-b112-00215aee3ebe;Новостав;Новостав;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822183604;3;1
6ed81c22-749b-11df-b112-00215aee3ebe;Новостав;Новостав;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624684908;3;1
6ed81c23-749b-11df-b112-00215aee3ebe;Новостав-Дальній;Новостав-Дальний;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624687903;3;1
6ed81c24-749b-11df-b112-00215aee3ebe;Новоставці;Новоставцы;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121285004;3;1
6ed81c25-749b-11df-b112-00215aee3ebe;Новоставці;Новоставцы;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623980604;3;1
6ed81c26-749b-11df-b112-00215aee3ebe;Новоставці;Новоставцы;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824785001;3;1
6ed81c27-749b-11df-b112-00215aee3ebe;Новоставці;Новоставцы;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621281206;3;1
6ed81c28-749b-11df-b112-00215aee3ebe;Новостанівка;Новостановка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523180308;3;1
6ed81c29-749b-11df-b112-00215aee3ebe;Новостанкувата;Новостанковатое;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521785702;3;1
6ed81c2a-749b-11df-b112-00215aee3ebe;Новостепанівка;Новостепановка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223284501;3;1
6ed81c2b-749b-11df-b112-00215aee3ebe;Новостепанівка;Новостепановка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325785904;3;1
6ed81c2c-749b-11df-b112-00215aee3ebe;Новостепанівка;Новостепановка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125483604;3;1
6ed81c2e-749b-11df-b112-00215aee3ebe;Новостепнянське;Новостепнянское;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322186804;3;1
6ed81c31-749b-11df-b112-00215aee3ebe;Новостроївка;Новостроевка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655117;3;1
6ed81c32-749b-11df-b112-00215aee3ebe;Новостроївка;Новостроевка;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225987803;3;1
6ed81c33-749b-11df-b112-00215aee3ebe;Новостроївка;Новостроевка;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821583203;3;1
6ed81c34-749b-11df-b112-00215aee3ebe;Новосуханівка;Новосухановка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924785601;3;1
6ed81c35-749b-11df-b112-00215aee3ebe;Новотавричеське;Новотаврическое;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955420;3;1
6ed81c36-749b-11df-b112-00215aee3ebe;Новотарасівка;Новотарасовка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225087303;3;1
6ed81f51-749b-11df-b112-00215aee3ebe;Новотаромське (Орджонікідзе);Новотаромське (Орджоникидзе);село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221487703;3;1
6ed81c39-749b-11df-b112-00215aee3ebe;Новотернуватка;Новотерноватка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225086005;3;1
6ed81c38-749b-11df-b112-00215aee3ebe;Новотерсянське;Новотерсянское;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755131;3;1
6ed81c3a-749b-11df-b112-00215aee3ebe;Новотимофіївка;Новотимофеевка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825783401;3;1
6ed81c3b-749b-11df-b112-00215aee3ebe;Новотимофіївське;Новотимофеевское;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225982803;3;1
6ed81c3c-749b-11df-b112-00215aee3ebe;Новотишківка;Новотишковка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521781005;3;1
6ed81c40-749b-11df-b112-00215aee3ebe;Новотрепівка;Новотреповка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522287405;3;1
6ed81c41-749b-11df-b112-00215aee3ebe;Новотроїцьке;Новотроицкое;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223282503;3;1
6ed81c42-749b-11df-b112-00215aee3ebe;Новотроїцьке;Новотроицкое;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224587507;3;1
6ed81c43-749b-11df-b112-00215aee3ebe;Новотроїцьке;Новотроицкое;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655123;3;1
6ed81c44-749b-11df-b112-00215aee3ebe;Новотроїцьке;Новотроицкое;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320287202;3;1
6ed81c45-749b-11df-b112-00215aee3ebe;Новотроїцьке;Новотроицкое;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684607;3;1
6ed81c46-749b-11df-b112-00215aee3ebe;Новотроїцьке;Новотроицкое;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123383804;3;1
6ed81c47-749b-11df-b112-00215aee3ebe;Новотроїцьке;Новотроицкое;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123581802;3;1
6ed81c48-749b-11df-b112-00215aee3ebe;Новотроїцьке;Новотроицкое;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582508;3;1
6ed81c49-749b-11df-b112-00215aee3ebe;Новотроїцьке;Новотроицкое;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955421;3;1
6ed81c4f-749b-11df-b112-00215aee3ebe;Новотягинка;Новотягинка;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520387003;3;1
6ed81c50-749b-11df-b112-00215aee3ebe;Новоугрузьке;Новоугрузское;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723384912;3;1
6ed81c51-749b-11df-b112-00215aee3ebe;Новоукраїнка;Новоукраинка;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925086203;3;1
6ed81c52-749b-11df-b112-00215aee3ebe;Новоукраїнка;Новоукраинка;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723655407;3;1
6ed81c53-749b-11df-b112-00215aee3ebe;Новоукраїнка;Новоукраинка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224555306;3;1
6ed81c54-749b-11df-b112-00215aee3ebe;Новоукраїнка;Новоукраинка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455122;3;1
6ed81c55-749b-11df-b112-00215aee3ebe;Новоукраїнка;Новоукраинка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225855110;3;1
6ed81c56-749b-11df-b112-00215aee3ebe;Новоукраїнка;Новоукраинка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655122;3;1
6ed81c57-749b-11df-b112-00215aee3ebe;Новоукраїнка;Новоукраинка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820680804;3;1
6ed81c58-749b-11df-b112-00215aee3ebe;Новоукраїнка;Новоукраинка;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821184601;3;1
6ed81c59-749b-11df-b112-00215aee3ebe;Новоукраїнка;Новоукраинка;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822085403;3;1
6ed81c5a-749b-11df-b112-00215aee3ebe;Новоукраїнка;Новоукраинка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320882006;3;1
6ed81c5b-749b-11df-b112-00215aee3ebe;Новоукраїнка;Новоукраинка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684701;3;1
6ed81c5c-749b-11df-b112-00215aee3ebe;Новоукраїнка;Новоукраинка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125481405;3;1
6ed81c5d-749b-11df-b112-00215aee3ebe;Новоукраїнка;Новоукраинка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123984201;3;1
6ed81c5e-749b-11df-b112-00215aee3ebe;Новоукраїнка;Новоукраинка;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124786404;3;1
6ed81c5f-749b-11df-b112-00215aee3ebe;Новоукраїнка;Новоукраинка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582006;3;1
6ed81c60-749b-11df-b112-00215aee3ebe;Новоукраїнка;Новоукраинка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323687204;3;1
6ed81c6b-749b-11df-b112-00215aee3ebe;Новоукраїнка;Новоукраинка;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122085705;3;1
6ed81c6c-749b-11df-b112-00215aee3ebe;Новоукраїнка;Новоукраинка;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125187601;3;1
6ed81c6d-749b-11df-b112-00215aee3ebe;Новоукраїнка;Новоукраинка;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122281502;3;1
6ed81c6e-749b-11df-b112-00215aee3ebe;Новоукраїнка;Новоукраинка;село;78f453a8-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0525087502;3;1
6ed81c6f-749b-11df-b112-00215aee3ebe;Новоукраїнка;Новоукраинка;місто;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524010100;2;1
6ed81c70-749b-11df-b112-00215aee3ebe;Новоукраїнка;Новоукраинка;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623885501;3;1
6ed81c73-749b-11df-b112-00215aee3ebe;Новоукраїнське;Новоукраинское;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424486001;3;1
6ed81c74-749b-11df-b112-00215aee3ebe;Новоукраїнське;Новоукраинское;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625882503;3;1
6ed81c77-749b-11df-b112-00215aee3ebe;Новоуплатне;Новоуплатное;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320686507;3;1
6ed81c7a-749b-11df-b112-00215aee3ebe;Новоустинівка;Новоустиновка;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525886704;3;1
6ed81c7b-749b-11df-b112-00215aee3ebe;Новофастів;Новофастов;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523483301;3;1
6ed81c7c-749b-11df-b112-00215aee3ebe;Новофедорівка;Новофедоровка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325455107;3;1
6ed81c7d-749b-11df-b112-00215aee3ebe;Новофедорівка;Новофедоровка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821480407;3;1
6ed81c7e-749b-11df-b112-00215aee3ebe;Новофедорівка;Новофедоровка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823684801;3;1
6ed81c7f-749b-11df-b112-00215aee3ebe;Новофедорівка;Новофедоровка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820982207;3;1
6ed81c80-749b-11df-b112-00215aee3ebe;Новофедорівка;Новофедоровка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321583004;3;1
6ed81c88-749b-11df-b112-00215aee3ebe;Новофедорівка;Новофедоровка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524982902;3;1
6ed81c89-749b-11df-b112-00215aee3ebe;Новофонтанка;Новофонтанка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820681806;3;1
cdc44f58-dde8-11df-9197-00215aee3ebe;Новохатки;Новохатки;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821484006;3;1
6ed81c8b-749b-11df-b112-00215aee3ebe;Новохолмське;Новохолмское;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120486402;3;1
6ed81c8c-749b-11df-b112-00215aee3ebe;Новохорошевське;Новохорошевское;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223886007;3;1
6ed81c8d-749b-11df-b112-00215aee3ebe;Новохортиця;Новохортица;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225283805;3;1
6ed81c8e-749b-11df-b112-00215aee3ebe;Новохристофорівка;Новохристофоровка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824583001;3;1
6ed81c91-749b-11df-b112-00215aee3ebe;Новочернігівське;Новочерниговское;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224884904;3;1
8cdd5ceb-de27-11df-9197-00215aee3ebe;Новочигиринка;Новочигиринка;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525880303;3;1
6ed81c92-749b-11df-b112-00215aee3ebe;Новочорноглазівське;Новочерноглазовское;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955115;3;1
6ed81c94-749b-11df-b112-00215aee3ebe;Новочудневе;Новочудново;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823686503;3;1
6ed81c95-749b-11df-b112-00215aee3ebe;Новошандрівка;Новошандровка;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225988505;3;1
6ed81c96-749b-11df-b112-00215aee3ebe;Новошевченкове;Новошевченково;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3521980403;3;1
6ed81c97-749b-11df-b112-00215aee3ebe;Новошин;Новошин;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622084201;3;1
6ed81c98-749b-11df-b112-00215aee3ebe;Новошини;Новошины;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621555601;3;1
6ed81c99-749b-11df-b112-00215aee3ebe;Новошичі;Новошичи;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621280805;3;1
6ed81c9a-749b-11df-b112-00215aee3ebe;Новошмідтівка;Новошмидтовка;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824884401;3;1
6ed81c9b-749b-11df-b112-00215aee3ebe;Новоюлівка;Новоюльевка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225285501;3;1
6ed81c9c-749b-11df-b112-00215aee3ebe;Новоюр'ївка;Новоюрьевка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824583401;3;1
6ed81c9d-749b-11df-b112-00215aee3ebe;Новоюр'ївка;Новоюрьевка;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824285003;3;1
6ed81c9e-749b-11df-b112-00215aee3ebe;Новоявленка;Новоявленка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823084206;3;1
6ed81ca0-749b-11df-b112-00215aee3ebe;Новояворівськ;Новояворовск;місто;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;7d1766f6-b6ff-11eb-80d4-000c29800ae7;4625810500;2;0
6ed81ca2-749b-11df-b112-00215aee3ebe;Новояковлівка;Новояковлевка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955422;3;1
6ed81ca4-749b-11df-b112-00215aee3ebe;Ногачівка;Ногачовка;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823986501;3;1
6ed81ca5-749b-11df-b112-00215aee3ebe;Ноздрище;Ноздрище;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755141;3;1
6ed81ca7-749b-11df-b112-00215aee3ebe;Норилів;Норилов;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820355603;3;1
6ed81ca8-749b-11df-b112-00215aee3ebe;Норинськ;Норинск;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824285401;3;1
6ed81ca9-749b-11df-b112-00215aee3ebe;Норинці;Норинцы;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755142;3;1
6ed81caa-749b-11df-b112-00215aee3ebe;Нориця;Норица;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422285004;3;1
6ed81cab-749b-11df-b112-00215aee3ebe;Норцівка;Норцовка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320282803;3;1
6ed81cac-749b-11df-b112-00215aee3ebe;Носачевичі;Носачевичи;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724584601;3;1
6ed81cad-749b-11df-b112-00215aee3ebe;Носачі;Носачи;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224881008;3;1
6ed81cae-749b-11df-b112-00215aee3ebe;Носачів;Носачов;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123785501;3;1
6ed81caf-749b-11df-b112-00215aee3ebe;Носелівка;Носелевка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420884001;3;1
6ed81cb0-749b-11df-b112-00215aee3ebe;Носенки;Носенки;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323287503;3;1
6ed81cb1-749b-11df-b112-00215aee3ebe;Носи;Носы;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755140;3;1
6ed81cb2-749b-11df-b112-00215aee3ebe;Носиківка;Носиковка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525385501;3;1
6ed81cb3-749b-11df-b112-00215aee3ebe;Носів;Носов;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124885801;3;1
6ed81cb4-749b-11df-b112-00215aee3ebe;Носівка;Носовка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086909;3;1
6ed81cb5-749b-11df-b112-00215aee3ebe;Носівка;Носовка;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825886001;3;1
6ed81cb6-749b-11df-b112-00215aee3ebe;Носівка;Носовка;місто;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423810100;2;1
6ed81cb7-749b-11df-b112-00215aee3ebe;Носівці;Носовцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122682802;3;1
6ed81cb8-749b-11df-b112-00215aee3ebe;Носівці;Носовцы;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520883306;3;1
6ed81cb9-749b-11df-b112-00215aee3ebe;Носківці;Носковцы;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521084203;3;1
6ed81cba-749b-11df-b112-00215aee3ebe;Носовиця;Носовица;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621686009;3;1
6ed81cbb-749b-11df-b112-00215aee3ebe;Носовичі;Носовичи;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721882902;3;1
6ed81cbc-749b-11df-b112-00215aee3ebe;Нотаріусівка;Нотариусовка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920983603;3;1
6ed81cbd-749b-11df-b112-00215aee3ebe;Нудиже;Нудыже;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723355308;3;1
6ed81cbe-749b-11df-b112-00215aee3ebe;Нужель;Нужель;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155304;3;1
6ed81cbf-749b-11df-b112-00215aee3ebe;Нуйно;Нуйно;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721485601;3;1
6ed81cc0-749b-11df-b112-00215aee3ebe;Нурове;Нурово;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320286205;3;1
6ed81cc1-749b-11df-b112-00215aee3ebe;Нянівка;Няневка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823483603;3;1
6ed81ccb-749b-11df-b112-00215aee3ebe;Об'єднане;Обьединенное;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925684904;3;1
6ed81ccc-749b-11df-b112-00215aee3ebe;Об'єднане;Обьединенное;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423687401;3;1
6ed81cc3-749b-11df-b112-00215aee3ebe;Обава;Обава;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122786201;3;1
6ed81cc4-749b-11df-b112-00215aee3ebe;Обарів;Обаров;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624687401;3;1
6ed81cc5-749b-11df-b112-00215aee3ebe;Обельниця;Обельница;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624483902;3;1
6ed81cc6-749b-11df-b112-00215aee3ebe;Обенижі;Обенижи;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725586503;3;1
6ed81cc8-749b-11df-b112-00215aee3ebe;Обертасів;Обертасов;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621882807;3;1
6ed81cc9-749b-11df-b112-00215aee3ebe;Обертасове;Обертасово;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522881403;3;1
6ed81cca-749b-11df-b112-00215aee3ebe;Обертин;Обертин;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625655400;3;1
6ed81ccd-749b-11df-b112-00215aee3ebe;Обжиле;Обжилое;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290018;3;1
6ed81cce-749b-11df-b112-00215aee3ebe;Обидів;Обидов;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622183506;3;1
6ed81ccf-749b-11df-b112-00215aee3ebe;Обиходи;Обиходы;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822384201;3;1
6ed81cd0-749b-11df-b112-00215aee3ebe;Обиходівка;Обиходовка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822384202;3;1
6ed81cd1-749b-11df-b112-00215aee3ebe;Обич;Обыч;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125886703;3;1
6ed81cd2-749b-11df-b112-00215aee3ebe;Обичів;Обычев;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424186101;3;1
6ed81cd3-749b-11df-b112-00215aee3ebe;Обище;Обище;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824487203;3;1
6ed81cd4-749b-11df-b112-00215aee3ebe;Обідне;Ободное;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523084006;3;1
6ed81cd8-749b-11df-b112-00215aee3ebe;Обірки;Обирки;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420386010;3;1
6ed81cd9-749b-11df-b112-00215aee3ebe;Обірки;Обирки;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625484809;3;1
6ed81cdb-749b-11df-b112-00215aee3ebe;Облаз;Облаз;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125385603;3;1
6ed81cdc-749b-11df-b112-00215aee3ebe;Облазниця;Облазница;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621585601;3;1
8cdd5cdc-de27-11df-9197-00215aee3ebe;Обланка;Обланка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520810102;3;1
6ed81cdd-749b-11df-b112-00215aee3ebe;Облапи;Облапы;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722182408;3;1
6ed81cde-749b-11df-b112-00215aee3ebe;Облітки;Облитки;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825087201;3;1
6ed81cdf-749b-11df-b112-00215aee3ebe;Облоги;Облоги;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924789105;3;1
6ed81ce0-749b-11df-b112-00215aee3ebe;Обложки;Обложки;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921583501;3;1
6ed81ce2-749b-11df-b112-00215aee3ebe;Обмачів;Обмачив;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420387001;3;1
6ed81ce3-749b-11df-b112-00215aee3ebe;Ободи;Ободы;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920686201;3;1
6ed81ce4-749b-11df-b112-00215aee3ebe;Ободівка;Ободовка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124688205;3;1
6ed81ce5-749b-11df-b112-00215aee3ebe;Ободівка;Ободовка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823480811;3;1
6ed81ce6-749b-11df-b112-00215aee3ebe;Ободівка;Ободовка;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524184201;3;1
6ed81ce7-749b-11df-b112-00215aee3ebe;Обозівка;Обозовка;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523482202;3;1
6ed81ce9-749b-11df-b112-00215aee3ebe;Обознівка;Обозновка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320685801;3;1
6ed81cea-749b-11df-b112-00215aee3ebe;Обознівка;Обозновка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522585801;3;1
6ed81ceb-749b-11df-b112-00215aee3ebe;Оболоння;Оболонье;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622084501;3;1
6ed81cec-749b-11df-b112-00215aee3ebe;Оболоння;Оболонье;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422285501;3;1
6ed81ced-749b-11df-b112-00215aee3ebe;Оболонь;Оболонь;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324585101;3;1
6ed81cf0-749b-11df-b112-00215aee3ebe;Обортів;Обортов;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623986202;3;1
6ed81cf1-749b-11df-b112-00215aee3ebe;Обоянівське;Обояновское;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224880512;3;1
6ed81cf2-749b-11df-b112-00215aee3ebe;Ображіївка;Ображиевка;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925385601;3;1
6ed81cf6-749b-11df-b112-00215aee3ebe;Обрізанівка;Обрезановка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321282007;3;1
6ed81cf8-749b-11df-b112-00215aee3ebe;Оброчне;Оброчное;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122982503;3;1
6ed81cf9-749b-11df-b112-00215aee3ebe;Оброшине;Оброшино;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;7d1766f6-b6ff-11eb-80d4-000c29800ae7;4623684901;3;0
3d4ed4b2-f062-11de-9277-00215aee3ebe;Оброшино;Оброшино;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623684901;3;1
6ed81cfa-749b-11df-b112-00215aee3ebe;Обсіч;Обсич;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625083203;3;1
6ed81cfb-749b-11df-b112-00215aee3ebe;Обтове;Обтово;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922686801;3;1
6ed81cfc-749b-11df-b112-00215aee3ebe;Обухів;Обухов;місто;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3211600000;2;0
6ed81cfd-749b-11df-b112-00215aee3ebe;Обухів;Обухов;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522884801;3;1
6ed81cfe-749b-11df-b112-00215aee3ebe;Обухівка;Обуховка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821483902;3;1
6ed81d00-749b-11df-b112-00215aee3ebe;Обухівка;Обуховка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820883602;3;1
5cb61733-749b-11df-b112-00215aee3ebe;Обухівка (Кіровське);Обуховка ( Кировское );село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221455400;2;0
6ed81d01-749b-11df-b112-00215aee3ebe;Обухове;Обухово;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425382805;3;1
6ed81d02-749b-11df-b112-00215aee3ebe;Обуховичі;Обуховичи;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222082701;3;1
6ed81d03-749b-11df-b112-00215aee3ebe;Обще;Общее;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323987810;3;1
6ed81d04-749b-11df-b112-00215aee3ebe;Овадне;Овадное;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720584401;3;1
6ed81d05-749b-11df-b112-00215aee3ebe;Овдієва Нива;Авдеева Нива;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221880802;3;1
6ed81d06-749b-11df-b112-00215aee3ebe;Овдіївка;Овдиевка;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322682803;3;1
6ed81d07-749b-11df-b112-00215aee3ebe;Овдянське;Овдянское;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922980402;3;1
6ed81d09-749b-11df-b112-00215aee3ebe;Овеча;Овечья;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923585002;3;1
6ed81d0a-749b-11df-b112-00215aee3ebe;Овідіополь;Овидиополь;місто;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123755100;2;0
6ed81d0b-749b-11df-b112-00215aee3ebe;Овлаші;Овлаши;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924185805;3;1
6ed81d0c-749b-11df-b112-00215aee3ebe;Овлочин;Овлочин;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725583901;3;1
6ed81d0d-749b-11df-b112-00215aee3ebe;Оводівка;Оводовка;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925087802;3;1
6ed81d10-749b-11df-b112-00215aee3ebe;Оврагове;Оврагово;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525281803;3;1
6ed81d13-749b-11df-b112-00215aee3ebe;Овруч;Овруч;місто;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824200000;2;0
6ed81d14-749b-11df-b112-00215aee3ebe;Овсюки;Овсюки;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320883701;3;1
6ed81d15-749b-11df-b112-00215aee3ebe;Овсяниківка;Овсяниковка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522586201;3;1
6ed81d16-749b-11df-b112-00215aee3ebe;Овчаренки;Овчаренки;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920383603;3;1
6ed81d17-749b-11df-b112-00215aee3ebe;Огирівка;Огировка;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281214;3;1
6ed81d18-749b-11df-b112-00215aee3ebe;Огіївка;Огиевка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324885501;3;1
6ed81d19-749b-11df-b112-00215aee3ebe;Огіївка;Огиевка;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825285201;3;1
6ed81d1a-749b-11df-b112-00215aee3ebe;Огіївка;Огиевка;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520882609;3;1
6ed81d1b-749b-11df-b112-00215aee3ebe;Огіївці;Огиевцы;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824282601;3;1
6ed81d1d-749b-11df-b112-00215aee3ebe;Оглядів;Оглядов;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623983801;3;1
6ed81d21-749b-11df-b112-00215aee3ebe;Огризківці;Огризковци;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123881806;3;1
6ed81d22-749b-11df-b112-00215aee3ebe;Огуївка;Огуевка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323084404;3;1
6ed81d23-749b-11df-b112-00215aee3ebe;Огульці;Огульцы;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321285501;3;1
6ed81d25-749b-11df-b112-00215aee3ebe;Огурцівка;Огурцовка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325755104;3;1
6ed81d26-749b-11df-b112-00215aee3ebe;Одаї;Одаи;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125455103;3;1
6ed81d27-749b-11df-b112-00215aee3ebe;Одаї;Одаи;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123181305;3;1
6ed81d28-749b-11df-b112-00215aee3ebe;Одаї;Одаи;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625882903;3;1
6ed81d29-749b-11df-b112-00215aee3ebe;Одаї;Одаи;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524380802;3;1
6ed81d2a-749b-11df-b112-00215aee3ebe;Одаї;Одаи;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521981507;3;1
6ed81d2b-749b-11df-b112-00215aee3ebe;Одаїв;Одаев;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625686208;3;1
6ed81d2c-749b-11df-b112-00215aee3ebe;Одай;Одай;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120989102;3;1
6ed81d2d-749b-11df-b112-00215aee3ebe;Одайпіль;Одайполь;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224685001;3;1
6ed81d2e-749b-11df-b112-00215aee3ebe;Одарівка;Одаровка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055104;3;1
6ed81d2f-749b-11df-b112-00215aee3ebe;Одарівка;Одаровка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955402;3;1
6ed81d30-749b-11df-b112-00215aee3ebe;Одарюківка;Одарюковка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321080803;3;1
6ed81d31-749b-11df-b112-00215aee3ebe;Одая;Одая;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0510490001;3;1
6ed81d32-749b-11df-b112-00215aee3ebe;Одая;Одая;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521486203;3;1
6ed81d33-749b-11df-b112-00215aee3ebe;Оддихне;Отдыхное;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323988505;3;1
6ed81d34-749b-11df-b112-00215aee3ebe;Одеради;Одерады;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721885402;3;1
6ed81d35-749b-11df-b112-00215aee3ebe;Одеради;Одерады;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881810;3;1
6ed81d36-749b-11df-b112-00215aee3ebe;Одерадівка;Одерадовка;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125888403;3;1
6ed81d37-749b-11df-b112-00215aee3ebe;Одеса;Одесса;місто;5394b336-41be-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5110100000;1;0
6ed81d38-749b-11df-b112-00215aee3ebe;Одинецьке;Одинецкое;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320682506;3;1
6ed81d39-749b-11df-b112-00215aee3ebe;Одиноке;Одинокое;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623655502;3;1
6ed81d3a-749b-11df-b112-00215aee3ebe;Одинці;Одинцы;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422086501;3;1
6ed81d3b-749b-11df-b112-00215aee3ebe;Однольків;Однольков;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421789005;3;1
6ed81d3c-749b-11df-b112-00215aee3ebe;Одноріг;Однорог;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220485302;3;1
6ed81d3d-749b-11df-b112-00215aee3ebe;Одноробівка;Одноробовка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322684501;3;1
6ed81d3e-749b-11df-b112-00215aee3ebe;Одноробівка, вокзал;Одноробовка, вокзал;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322684401;3;1
6ed81d3f-749b-11df-b112-00215aee3ebe;Одрада;Отрада;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323580503;3;1
6ed81d40-749b-11df-b112-00215aee3ebe;Одрадівка;Отрадовка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321387806;3;1
6ed81d41-749b-11df-b112-00215aee3ebe;Одрадівка;Отрадовка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323381205;3;1
6ed81d44-749b-11df-b112-00215aee3ebe;Одрадна Балка;Отрадная Балка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121281204;3;1
6ed81d45-749b-11df-b112-00215aee3ebe;Одрадне;Одрадное;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921581903;3;1
6ed81d46-749b-11df-b112-00215aee3ebe;Одрадне;Одрадное;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225855111;3;1
6ed81d47-749b-11df-b112-00215aee3ebe;Одрадне;Одрадное;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610117;3;1
6ed81d48-749b-11df-b112-00215aee3ebe;Одрадне;Одрадное;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821180705;3;1
6ed81d4a-749b-11df-b112-00215aee3ebe;Одрадне;Одрадное;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325784505;3;1
6ed81d4c-749b-11df-b112-00215aee3ebe;Одрадове;Отрадово;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324586001;3;1
6ed81d4d-749b-11df-b112-00215aee3ebe;Одрадове;Отрадово;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123981407;3;1
6ed81d4e-749b-11df-b112-00215aee3ebe;Одрадокам'янка;Отрадокаменка;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520685901;3;1
6ed81d4f-749b-11df-b112-00215aee3ebe;Одринка;Одрынка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324282501;3;1
6ed81d50-749b-11df-b112-00215aee3ebe;Одринки;Одрынки;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625482803;3;1
6ed81d51-749b-11df-b112-00215aee3ebe;Одруби;Отруби;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755143;3;1
6ed81d52-749b-11df-b112-00215aee3ebe;Одрубок;Одрубок;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225283317;3;1
6ed81d53-749b-11df-b112-00215aee3ebe;Ожарівка;Ожаровка;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824484501;3;1
6ed81d54-749b-11df-b112-00215aee3ebe;Ожгів;Ожгов;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820655903;3;1
6ed81d55-749b-11df-b112-00215aee3ebe;Ожеве;Ожево;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324087501;3;1
6ed81d56-749b-11df-b112-00215aee3ebe;Ожегівка;Ожеговка;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221685201;3;1
6ed81d57-749b-11df-b112-00215aee3ebe;Оженин;Оженин;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624286401;3;1
6ed81d58-749b-11df-b112-00215aee3ebe;Оженківка;Оженковка;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223584505;3;1
6ed81d59-749b-11df-b112-00215aee3ebe;Ожигівці;Ожиговцы;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820984701;3;1
6ed81d5a-749b-11df-b112-00215aee3ebe;Ожидів;Ожидов;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620684401;3;1
6ed81d5b-749b-11df-b112-00215aee3ebe;Ожоверх;Ожоверх;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125384803;3;1
6ed81d5c-749b-11df-b112-00215aee3ebe;Озадівка;Озадовка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820884501;3;1
6ed81d5d-749b-11df-b112-00215aee3ebe;Озаринці;Озаринцы;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522685000;3;1
6ed81d5e-749b-11df-b112-00215aee3ebe;Озаричі;Озаричи;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922086906;3;1
6ed81d5f-749b-11df-b112-00215aee3ebe;Озденіж;Оздениж;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724582602;3;1
6ed81d60-749b-11df-b112-00215aee3ebe;Оздів;Оздов;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881602;3;1
6ed81d61-749b-11df-b112-00215aee3ebe;Озера;Озера;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5321885101;3;1
6ed81d62-749b-11df-b112-00215aee3ebe;Озера;Озера;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920383604;3;1
6ed81d63-749b-11df-b112-00215aee3ebe;Озера;Озера;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221087001;3;1
6ed81d64-749b-11df-b112-00215aee3ebe;Озера;Озера;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820983501;3;1
6ed81d65-749b-11df-b112-00215aee3ebe;Озера;Озера;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525285801;3;1
6ed81d66-749b-11df-b112-00215aee3ebe;Озереди;Озереды;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422481509;3;1
6ed81d67-749b-11df-b112-00215aee3ebe;Озеринівка;Озериновка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821455106;3;1
6ed81d68-749b-11df-b112-00215aee3ebe;Озерище;Озерище;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122085601;3;1
6ed81d69-749b-11df-b112-00215aee3ebe;Озерівка;Озеровка;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821184801;3;1
6ed81d6c-749b-11df-b112-00215aee3ebe;Озерко;Озерко;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622655404;3;1
6ed81d6d-749b-11df-b112-00215aee3ebe;Озерна;Озерная;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122686701;3;1
6ed81d6e-749b-11df-b112-00215aee3ebe;Озерна;Озерная;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220484101;2;1
6ed81d6f-749b-11df-b112-00215aee3ebe;Озерна;Озерная;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523486203;3;1
cdc44f6b-dde8-11df-9197-00215aee3ebe;Озерна Перша;Озерная Первая;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;;3;1
6ed81d70-749b-11df-b112-00215aee3ebe;Озерне;Озерное;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923555607;3;1
6ed81d71-749b-11df-b112-00215aee3ebe;Озерне;Озерное;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722187404;3;1
6ed81d72-749b-11df-b112-00215aee3ebe;Озерне;Озерное;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223880505;3;1
6ed81d73-749b-11df-b112-00215aee3ebe;Озерне;Озерное;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885710;3;1
6ed81d75-749b-11df-b112-00215aee3ebe;Озерне;Озерное;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122084801;3;1
6ed81d7b-749b-11df-b112-00215aee3ebe;Озерне;Озерное;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225587403;3;1
6ed81d7c-749b-11df-b112-00215aee3ebe;Озерне;Озерное;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822055900;1;0
6ed81d7d-749b-11df-b112-00215aee3ebe;Озерне;Озерное;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823182905;3;1
6ed81d7e-749b-11df-b112-00215aee3ebe;Озерне;Озерное;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422086601;3;1
6ed81d7f-749b-11df-b112-00215aee3ebe;Озерне;Озерное;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523186603;3;1
6ed81d80-749b-11df-b112-00215aee3ebe;Озерне;Озерное;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322680404;3;1
44ac8019-749b-11df-b112-00215aee3ebe;Озерне (Артемівка);Озерне (Артемівка);село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824882602;3;1
6ed81d81-749b-11df-b112-00215aee3ebe;Озеро;Озеро;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721884801;3;1
6ed81d83-749b-11df-b112-00215aee3ebe;Озеро;Озеро;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523083406;3;1
6ed81d84-749b-11df-b112-00215aee3ebe;Озеро;Озеро;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620887601;3;1
6ed81d85-749b-11df-b112-00215aee3ebe;Озерськ;Озерск;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621883903;3;1
6ed81d86-749b-11df-b112-00215aee3ebe;Озерське;Озерское;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625889216;3;1
6ed81d87-749b-11df-b112-00215aee3ebe;Озерце;Озерцо;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721882706;3;1
6ed81d88-749b-11df-b112-00215aee3ebe;Озерце;Озерцо;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621283903;3;1
6ed81d89-749b-11df-b112-00215aee3ebe;Озерці;Озерцы;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720886902;3;1
6ed81d8a-749b-11df-b112-00215aee3ebe;Озерці;Озерцы;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620887401;3;1
6ed81d8b-749b-11df-b112-00215aee3ebe;Озерщина;Озерщина;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221081502;3;1
6ed81d8c-749b-11df-b112-00215aee3ebe;Озеряни;Озеряны;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725584201;3;1
6ed81d8d-749b-11df-b112-00215aee3ebe;Озеряни;Озеряны;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722880503;3;1
6ed81d8e-749b-11df-b112-00215aee3ebe;Озеряни;Озеряны;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121284201;3;1
6ed81d8f-749b-11df-b112-00215aee3ebe;Озеряни;Озеряны;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120885501;3;1
6ed81d91-749b-11df-b112-00215aee3ebe;Озеряни;Озеряны;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621286001;3;1
6ed81d92-749b-11df-b112-00215aee3ebe;Озеряни;Озеряны;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625686209;3;1
6ed81d93-749b-11df-b112-00215aee3ebe;Озеряни;Озеряны;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824456001;3;1
6ed81d94-749b-11df-b112-00215aee3ebe;Озеряни;Озеряны;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420685201;3;1
6ed81d95-749b-11df-b112-00215aee3ebe;Озеряни;Озеряны;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421183201;3;1
6ed81d96-749b-11df-b112-00215aee3ebe;Озеряни;Озеряны;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621684701;3;1
6ed81d98-749b-11df-b112-00215aee3ebe;Озерянка;Озерянка;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122683707;3;1
6ed81d99-749b-11df-b112-00215aee3ebe;Озерянка;Озерянка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822085301;3;1
6ed81d9a-749b-11df-b112-00215aee3ebe;Озерянка;Озерянка;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820683003;3;1
6ed81d9b-749b-11df-b112-00215aee3ebe;Озирянка;Озырянка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822085301;3;1
6ed81d9c-749b-11df-b112-00215aee3ebe;Озірна;Озирна;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121284801;3;1
6ed81d9d-749b-11df-b112-00215aee3ebe;Озірці;Озерцы;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620487602;3;1
6ed81d9e-749b-11df-b112-00215aee3ebe;Озліїв;Озлиев;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623887103;3;1
6ed81d9f-749b-11df-b112-00215aee3ebe;Озютичі;Озютичи;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722484401;3;1
6ed81da0-749b-11df-b112-00215aee3ebe;Окачеве;Окачево;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724288206;3;1
6ed81da1-749b-11df-b112-00215aee3ebe;Окілок;Околок;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825686203;3;1
6ed81da2-749b-11df-b112-00215aee3ebe;Окіп;Окоп;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925680404;3;1
6ed81da3-749b-11df-b112-00215aee3ebe;Окіп;Окоп;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923882305;3;1
6ed81da4-749b-11df-b112-00215aee3ebe;Окіп;Окоп;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922355603;3;1
6ed81da5-749b-11df-b112-00215aee3ebe;Окіп;Окоп;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322686003;3;1
6ed81da6-749b-11df-b112-00215aee3ebe;Окіп;Окоп;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820386005;3;1
6ed81da7-749b-11df-b112-00215aee3ebe;Окіп;Окоп;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322885401;3;1
6ed81da8-749b-11df-b112-00215aee3ebe;Окладне;Окладное;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520280207;3;1
73e94bed-ddc1-11df-9197-00215aee3ebe;Оклі;Окли;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121282403;3;1
73e94bee-ddc1-11df-9197-00215aee3ebe;Оклі Гедь;Окли Гедь;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121282404;3;1
5cb61ccd-749b-11df-b112-00215aee3ebe;Окни;Окны;місто;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123100000;3;1
6ed81daa-749b-11df-b112-00215aee3ebe;Околена;Околена;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323584002;3;1
6ed81dab-749b-11df-b112-00215aee3ebe;Оконськ;Оконск;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723685301;3;1
6ed81dac-749b-11df-b112-00215aee3ebe;Окопи;Окопы;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120881804;3;1
6ed81dad-749b-11df-b112-00215aee3ebe;Окопи;Окопы;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784809;3;1
6ed81dae-749b-11df-b112-00215aee3ebe;Округла;Округлая;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124410103;3;1
6ed81daf-749b-11df-b112-00215aee3ebe;Оксамитне;Оксамитное;село;6b07f8a2-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5121485501;3;1
6ed81db0-749b-11df-b112-00215aee3ebe;Оксанина;Оксанына;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124385501;3;1
6ed81db1-749b-11df-b112-00215aee3ebe;Оксанівка;Оксановка;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525681603;3;1
cdc44f38-dde8-11df-9197-00215aee3ebe;Октавин;Октавин;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720582003;3;1
6ed81dc4-749b-11df-b112-00215aee3ebe;Октябрське;Октябрьское;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524081804;3;1
6ed81dc6-749b-11df-b112-00215aee3ebe;Окунин;Окунин;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725583604;3;1
6ed81dc8-749b-11df-b112-00215aee3ebe;Олбин;Олбин;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422086801;3;1
6ed81dc9-749b-11df-b112-00215aee3ebe;Олевськ;Олевск;місто;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824410100;2;0
6ed81dca-749b-11df-b112-00215aee3ebe;Олег;Олег;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121684604;3;1
6ed81e10-749b-11df-b112-00215aee3ebe;Олександринівка;Александриновка;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221983003;3;1
6ed81e11-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222980301;3;1
6ed81e12-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822384103;3;1
6ed81e13-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824083705;3;1
6ed81e14-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821455604;3;1
6ed81e15-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424486804;3;1
6ed81e16-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422480501;3;1
6ed81e17-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424780501;3;1
6ed81e18-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;;3;1
6ed81e19-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420680401;3;1
6ed81e1a-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120980401;3;1
6ed81e1b-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121283602;3;1
6ed81e1c-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522482802;3;1
6ed81e1d-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522281403;3;1
6ed81e1e-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521080203;3;1
6ed81e1f-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520684606;3;1
6ed81e20-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523980301;3;1
6ed81e21-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524180301;3;1
6ed81e22-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523110106;3;1
6ed81e23-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521488302;3;1
6ed81e24-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521780301;3;1
6ed81e25-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;місто;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520555100;3;1
6ed81e26-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520555100;3;1
6ed81e28-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524986602;3;1
6ed81e29-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3521980401;3;1
6ed81e2a-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525880201;3;1
6ed81e2b-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824486504;3;1
6ed81e2c-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821289403;3;1
6ed81e2d-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625084403;3;1
6ed81e2e-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623486004;3;1
6ed81e2f-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323810108;3;1
6ed81e30-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325185505;3;1
6ed81e31-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320880401;3;1
6ed81e32-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322884903;3;1
6ed81dcb-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322080805;3;1
6ed81dcc-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920980401;3;1
6ed81dcd-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920689202;3;1
6ed81dce-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922985204;3;1
6ed81dcf-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786307;3;1
6ed81dd0-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921281304;3;1
6ed81dd1-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724582907;3;1
6ed81dd2-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881805;3;1
6ed81dd3-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222380501;3;1
6ed81dd4-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225980201;3;1
6ed81dd5-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221481001;3;0
6ed81dd6-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055717;3;1
6ed81dd7-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225083006;3;1
6ed81dd8-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223881005;3;1
6ed81dd9-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655124;3;1
6ed81dda-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282518;3;1
6ed81ddb-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222982205;3;1
6ed81ddc-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455102;3;1
6ed81ddd-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224280501;3;1
6ed81dde-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885712;3;1
6ed81ddf-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823680301;3;1
6ed81de0-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822783514;3;1
6ed81de1-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822055200;3;1
6ed81de2-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824880205;3;1
6ed81de3-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825780401;3;1
6ed81de4-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820981207;3;1
6ed81de5-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320880501;3;1
6ed81de6-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322680501;3;1
6ed81de7-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325185502;3;1
6ed81de8-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321280301;3;1
6ed81de9-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325785805;3;1
6ed81dea-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323180403;3;1
6ed81deb-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322880501;3;1
6ed81dec-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322281002;3;1
6ed81ded-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324883003;3;1
6ed81dee-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323988007;3;1
6ed81def-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320485506;3;1
6ed81df0-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320681507;3;1
6ed81df1-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122583902;3;1
6ed81df2-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122983803;3;1
6ed81df3-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123383707;3;1
6ed81df4-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125480301;3;1
6ed81df5-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123983011;3;1
6ed81df6-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122784004;3;0
6ed81df7-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123183003;3;1
6ed81df8-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;903cca7c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5110845200;2;1
6ed81df9-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124780401;3;1
6ed81dfa-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;6b07f8a2-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5121480401;3;1
6ed81dff-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520380501;3;1
6ed81e08-749b-11df-b112-00215aee3ebe;Олександрівка;Александровка;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125380201;3;1
6ed6f3bf-24df-11eb-80cd-000c29800ae7;Олександрівка;Александровка;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;;3;1
6ed81e33-749b-11df-b112-00215aee3ebe;Олександрівка Друга;Александровка Вторая;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322080405;3;1
6ed81e34-749b-11df-b112-00215aee3ebe;Олександрівка Друга;Александровка Вторая;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424483307;3;1
6ed81e36-749b-11df-b112-00215aee3ebe;Олександрівське;Александровское;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925680305;3;1
6ed81e37-749b-11df-b112-00215aee3ebe;Олександрівське;Александровское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321585604;3;1
50c5943b-749b-11df-b112-00215aee3ebe;Олександрійське ( Димитрове );Димитрово;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3510345300;3;1
6ed81e39-749b-11df-b112-00215aee3ebe;Олександрія;Александрия;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5321883805;3;1
6ed81e3a-749b-11df-b112-00215aee3ebe;Олександрія;Александрия;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922383302;3;1
6ed81e3b-749b-11df-b112-00215aee3ebe;Олександрія;Александрия;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721484605;3;1
6ed81e3c-749b-11df-b112-00215aee3ebe;Олександрія;Александрия;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721883603;3;1
6ed81e3d-749b-11df-b112-00215aee3ebe;Олександрія;Александрия;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223210503;3;1
6ed81e3e-749b-11df-b112-00215aee3ebe;Олександрія;Александрия;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225884811;3;1
6ed81e40-749b-11df-b112-00215aee3ebe;Олександрія;Александрия;місто;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3510300000;2;0
6ed81e41-749b-11df-b112-00215aee3ebe;Олександрія;Александрия;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624680401;3;1
6ed81e42-749b-11df-b112-00215aee3ebe;Олександро-Акацатове;Александро-Акацатово;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521784403;3;1
6ed81e43-749b-11df-b112-00215aee3ebe;Олександро-Білівка;Александро-Беловка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225255117;3;1
6ed81e44-749b-11df-b112-00215aee3ebe;Олександрове;Александрово;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622286603;3;1
6ed81e45-749b-11df-b112-00215aee3ebe;Олександро-Вовкове;Александро-Волково;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125484704;3;1
6ed81e47-749b-11df-b112-00215aee3ebe;Олександро-Григорівка;Александро-Григоровка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224580506;3;1
6ed81e48-749b-11df-b112-00215aee3ebe;Олександродар;Александродар;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822755106;3;1
6ed81e49-749b-11df-b112-00215aee3ebe;Олександродар;Александродар;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524955102;3;1
6ed81e4a-749b-11df-b112-00215aee3ebe;Олександро-Завадське;Александро-Завадское;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521781903;3;1
6ed81e4c-749b-11df-b112-00215aee3ebe;Олександро-Мар'ївка;Александро-Марьевка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524984805;3;1
6ed81e4d-749b-11df-b112-00215aee3ebe;Олександро-Пащенкове;Александро-Пащенково;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520355405;3;1
6ed81e4f-749b-11df-b112-00215aee3ebe;Олександропіль;Александрополь;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225080501;3;1
6ed81e50-749b-11df-b112-00215aee3ebe;Олександропіль;Александрополь;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224880513;3;1
6ed81e51-749b-11df-b112-00215aee3ebe;Олександропіль;Александрополь;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223880501;3;1
6ed81e52-749b-11df-b112-00215aee3ebe;Олександропіль;Александрополь;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222986904;3;1
6ed81e56-749b-11df-b112-00215aee3ebe;Олександро-Степанівка;Александро-Степановка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3510390409;3;1
6ed81e59-749b-11df-b112-00215aee3ebe;Олексенки;Алексенки;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920683009;3;1
6ed81e5a-749b-11df-b112-00215aee3ebe;Олексенкове;Алексенково;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5910590002;3;1
6ed81e5b-749b-11df-b112-00215aee3ebe;Олексине;Олексине;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925080406;3;1
6ed81e5c-749b-11df-b112-00215aee3ebe;Олексинці;Алексинцы;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
6ed81e5d-749b-11df-b112-00215aee3ebe;Олексинці;Алексинцы;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425181001;3;1
6ed81e5e-749b-11df-b112-00215aee3ebe;Олексичі;Алексичи;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625355303;3;1
6ed81e60-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323087203;3;1
6ed81e61-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187108;3;1
6ed81e62-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922984606;3;1
6ed81e63-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924780301;3;1
6ed81e64-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721410101;3;1
6ed81e65-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955116;3;1
6ed81e66-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055705;3;1
6ed81e67-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225084307;3;1
6ed81e68-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225283804;3;1
6ed81e69-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222983903;3;1
6ed81e6a-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224280515;3;1
6ed81e6b-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;3;1
6ed81e6c-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324080501;3;1
6ed81e6d-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323580301;3;1
6ed81e6e-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325785806;3;1
6ed81e6f-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324580501;3;1
6ed81e70-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324884003;3;1
6ed81e71-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323983005;3;1
6ed81e72-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320680201;3;1
6ed81e73-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122580401;3;1
6ed81e74-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122980401;3;1
6ed81e75-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123580401;3;1
6ed81e76-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120884205;3;1
6ed81e77-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124786405;3;1
6ed81e78-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323683507;3;1
6ed81e83-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221985503;3;1
6ed81e84-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220684402;3;1
6ed81e85-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420381505;3;1
6ed81e86-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521255408;3;1
6ed81e87-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523087803;3;1
6ed81e88-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521082206;3;1
6ed81e89-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523183605;3;1
6ed81e8a-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521487004;3;1
6ed81e8b-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521780601;3;1
6ed81e8c-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520880301;3;1
6ed81e8d-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525285802;3;1
6ed81e8e-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522580601;3;1
6ed81e8f-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824486505;3;1
6ed81e90-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625480902;3;1
6ed81e91-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621285903;3;1
6ed81e92-749b-11df-b112-00215aee3ebe;Олексіївка;Алексеевка;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320883204;3;1
6ed81e94-749b-11df-b112-00215aee3ebe;Олексіївщина;Алексеевщина;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422080201;3;1
6ed81e96-749b-11df-b112-00215aee3ebe;Оленине;Оленино;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721485701;3;1
6ed81e97-749b-11df-b112-00215aee3ebe;Оленинське;Оленинское;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920355505;3;1
6ed81e98-749b-11df-b112-00215aee3ebe;Оленичі;Оленичи;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287903;3;1
6ed81e99-749b-11df-b112-00215aee3ebe;Оленівка;Оленовка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322083701;3;1
6ed81e9a-749b-11df-b112-00215aee3ebe;Оленівка;Оленовка;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724585202;3;1
6ed81e9b-749b-11df-b112-00215aee3ebe;Оленівка;Оленовка;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121655402;3;1
6ed81e9c-749b-11df-b112-00215aee3ebe;Оленівка;Оленовка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222385301;3;1
6ed81e9d-749b-11df-b112-00215aee3ebe;Оленівка;Оленовка;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225980207;3;1
6ed81e9e-749b-11df-b112-00215aee3ebe;Оленівка;Оленовка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222088609;3;1
6ed81e9f-749b-11df-b112-00215aee3ebe;Оленівка;Оленовка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225855112;3;1
6ed81ea0-749b-11df-b112-00215aee3ebe;Оленівка;Оленовка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323381503;3;1
6ed81ea1-749b-11df-b112-00215aee3ebe;Оленівка;Оленовка;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120685001;3;1
6ed81ea2-749b-11df-b112-00215aee3ebe;Оленівка;Оленовка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125283005;3;1
6ed81ea3-749b-11df-b112-00215aee3ebe;Оленівка;Оленовка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655507;3;1
6ed81ea4-749b-11df-b112-00215aee3ebe;Оленівка;Оленовка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955423;3;1
6ed81ead-749b-11df-b112-00215aee3ebe;Оленівка;Оленовка;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224984801;3;1
6ed81eae-749b-11df-b112-00215aee3ebe;Оленівка;Оленовка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420884401;3;1
6ed81eaf-749b-11df-b112-00215aee3ebe;Оленівка;Оленовка;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;;3;1
6ed81eb0-749b-11df-b112-00215aee3ebe;Оленівка;Оленовка;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520684603;3;1
6ed81eb1-749b-11df-b112-00215aee3ebe;Оленівка;Оленовка;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522681405;2;1
6ed81eb2-749b-11df-b112-00215aee3ebe;Оленівка;Оленовка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522584802;3;1
6ed81eb3-749b-11df-b112-00215aee3ebe;Оленівка;Оленовка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822487405;3;1
e0517ede-db89-11df-9197-00215aee3ebe;Оленівка;Оленовка;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823381002;3;1
6ed81eb4-749b-11df-b112-00215aee3ebe;Оленівське;Оленовское;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323584705;3;1
6ed81eb5-749b-11df-b112-00215aee3ebe;Оленівське;Оленовское;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520882403;3;1
6ed81eb6-749b-11df-b112-00215aee3ebe;Олено-Косогорівка;Олено-Косогоровка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522585003;3;1
6ed81e95-749b-11df-b112-00215aee3ebe;Оленьово;Оленево;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124084403;3;1
6ed81eb8-749b-11df-b112-00215aee3ebe;Олепіри;Олепиры;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324082404;3;1
6ed81eb9-749b-11df-b112-00215aee3ebe;Олесине;Олесино;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123086101;3;1
6ed81eba-749b-11df-b112-00215aee3ebe;Олеськ;Олеск;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723383301;3;1
6ed81ebb-749b-11df-b112-00215aee3ebe;Олесько;Олеско;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620655600;3;0
6ed81ebc-749b-11df-b112-00215aee3ebe;Олефіри;Олефиры;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320283604;3;1
6ed81ebd-749b-11df-b112-00215aee3ebe;Олефірівка;Олефировка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322483807;3;1
6ed81ebe-749b-11df-b112-00215aee3ebe;Олефірівка;Олефировка;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223881505;3;1
6ed81ebf-749b-11df-b112-00215aee3ebe;Олефірівка;Олефировка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323287204;3;1
6ed81ec0-749b-11df-b112-00215aee3ebe;Олефірщина;Олефирщина;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321081703;3;1
6ed81ec1-749b-11df-b112-00215aee3ebe;Олеша;Олеша;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124287201;3;1
6ed81ec2-749b-11df-b112-00215aee3ebe;Олеша;Олеша;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625686201;3;1
6ed81ec3-749b-11df-b112-00215aee3ebe;Олешин;Олешин;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825085101;3;0
6ed81ec4-749b-11df-b112-00215aee3ebe;Олешів;Олешов;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625686401;3;1
6ed81ec5-749b-11df-b112-00215aee3ebe;Олешки;Олешки;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157902;3;1
6ed81ec6-749b-11df-b112-00215aee3ebe;Олешків;Олешков;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625255306;3;1
6ed81ec7-749b-11df-b112-00215aee3ebe;Олешківці;Олешковцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825082702;3;1
6ed81ec8-749b-11df-b112-00215aee3ebe;Олешковичі;Олешковичи;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724584605;3;1
6ed81ec9-749b-11df-b112-00215aee3ebe;Олешник;Олешник;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121283301;3;0
6ed81eca-749b-11df-b112-00215aee3ebe;Олешня;Олешня;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920386301;3;1
6ed81ecb-749b-11df-b112-00215aee3ebe;Олешня;Олешня;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424486801;3;1
6ed81ecc-749b-11df-b112-00215aee3ebe;Олешня;Олешня;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422455402;3;1
6ed81ecd-749b-11df-b112-00215aee3ebe;Олещенкове;Олещенково;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923280807;3;1
6ed81ece-749b-11df-b112-00215aee3ebe;Олещин;Олещин;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625684002;3;1
6ed81ecf-749b-11df-b112-00215aee3ebe;Олибів;Олыбов;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621687009;3;1
6ed81ed1-749b-11df-b112-00215aee3ebe;Олива;Олива;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222082901;3;1
6ed81ed2-749b-11df-b112-00215aee3ebe;Олизарівка;Олизаровка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222083001;3;1
6ed81ed3-749b-11df-b112-00215aee3ebe;Олика;Олыка;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721855400;3;1
6ed81ed4-749b-11df-b112-00215aee3ebe;Олине;Олино;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925680405;3;1
6ed81ed5-749b-11df-b112-00215aee3ebe;Олишва;Олышва;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624684909;3;1
6ed81ed6-749b-11df-b112-00215aee3ebe;Олишівка;Олишевка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425555700;3;1
6ed81ed7-749b-11df-b112-00215aee3ebe;Олишківці;Олишковцы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122483303;3;1
6ed81ed8-749b-11df-b112-00215aee3ebe;Олієво-Корнів;Олиево-Корнев;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621682903;3;1
6ed81ed9-749b-11df-b112-00215aee3ebe;Олієво-Королівка;Олиево-Королевка;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621682901;3;1
6ed81eda-749b-11df-b112-00215aee3ebe;Олізарка;Олизарка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825481202;3;1
6ed81edb-749b-11df-b112-00215aee3ebe;Оліїв;Олиев;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122687001;3;1
6ed81edc-749b-11df-b112-00215aee3ebe;Оліївка;Олиевка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822085601;1;1
6ed81edd-749b-11df-b112-00215aee3ebe;Оліївка;Олиевка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520381303;3;1
6ed81ede-749b-11df-b112-00215aee3ebe;Олійники;Олейники;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323483205;3;1
6ed81edf-749b-11df-b112-00215aee3ebe;Олійники;Олейники;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323580504;3;1
6ed81ee0-749b-11df-b112-00215aee3ebe;Олійники;Олейники;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324886001;3;1
6ed81ee1-749b-11df-b112-00215aee3ebe;Олійники;Олейники;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422487506;3;1
6ed81ee2-749b-11df-b112-00215aee3ebe;Олійники;Олейники;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824785301;3;1
6ed81ee3-749b-11df-b112-00215aee3ebe;Олійникова Слобода;Олейниковая Слобода;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220410509;3;1
6ed81ee4-749b-11df-b112-00215aee3ebe;Олійникове;Олейниково;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325182305;3;1
6ed81ee5-749b-11df-b112-00215aee3ebe;Олімпіадівка;Олимпиадовка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120686505;3;1
6ed81ee6-749b-11df-b112-00215aee3ebe;Олімпіадівка;Олимпиадовка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524983703;3;1
6ed81ee7-749b-11df-b112-00215aee3ebe;Оліхи;Олихи;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525386205;3;1
6ed81ee8-749b-11df-b112-00215aee3ebe;Олішівка;Олешовка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821185408;3;1
6ed81ee9-749b-11df-b112-00215aee3ebe;Оліярники;Олиярники;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622786707;3;1
6ed81eea-749b-11df-b112-00215aee3ebe;Олянине;Олянино;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121884504;3;1
6ed81eeb-749b-11df-b112-00215aee3ebe;Оляниця;Оляница;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524184601;3;1
6ed81eec-749b-11df-b112-00215aee3ebe;Олянівка;Оляновка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222384503;3;1
6ed81eed-749b-11df-b112-00215aee3ebe;Ольганівка;Ольгановка;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724585903;3;1
6ed81eee-749b-11df-b112-00215aee3ebe;Ольгине;Ольгино;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521883502;3;1
6ed81ef0-749b-11df-b112-00215aee3ebe;Ольгине;Ольгино;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524881803;3;1
6ed81dc5-749b-11df-b112-00215aee3ebe;Ольгине (Октябрщина);Ольгино (Октябрщина);село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925655105;3;1
6ed81ef2-749b-11df-b112-00215aee3ebe;Ольгинове;Ольгиново;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655508;3;1
6ed81ef4-749b-11df-b112-00215aee3ebe;Ольгівка;Ольговка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084009;3;1
6ed81ef5-749b-11df-b112-00215aee3ebe;Ольгівка;Ольговка;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321082009;3;1
6ed81ef6-749b-11df-b112-00215aee3ebe;Ольгівка;Ольговка;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520685501;3;1
6ed81ef8-749b-11df-b112-00215aee3ebe;Ольгівка;Ольговка;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523486903;3;1
6ed81efa-749b-11df-b112-00215aee3ebe;Ольгопіль;Ольгополь;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823083501;3;1
6ed81efb-749b-11df-b112-00215aee3ebe;Ольгопіль;Ольгополь;село;78f453a8-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0525084401;3;1
6ed81efd-749b-11df-b112-00215aee3ebe;Ольшани;Ольшаны;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721485805;3;1
6ed81efe-749b-11df-b112-00215aee3ebe;Ольшаниця;Ольшаница;село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223784001;3;1
73e94c0e-ddc1-11df-9197-00215aee3ebe;Ольшаниця;Ольшаница;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625883501;3;1
6ed81eff-749b-11df-b112-00215aee3ebe;Ольшаниця перша;Ольшаница Первая;село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;;3;1
6ed81f00-749b-11df-b112-00215aee3ebe;Ольшанка;Ольшанка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821483201;3;1
6ed81f01-749b-11df-b112-00215aee3ebe;Ольшанське;Ольшанское;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;3;1
e0517edf-db89-11df-9197-00215aee3ebe;Ольшанське;Ольшанское;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824255600;3;1
6ed81f02-749b-11df-b112-00215aee3ebe;Омбиш;Омбыш;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420884801;3;1
6ed81f03-749b-11df-b112-00215aee3ebe;Омелівка;Омелевка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821182703;3;1
6ed81f04-749b-11df-b112-00215aee3ebe;Омелуша;Омелуша;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821784202;3;1
6ed81f05-749b-11df-b112-00215aee3ebe;Омелянів;Омелянов;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422087101;3;1
6ed81f06-749b-11df-b112-00215aee3ebe;Омелянівка;Омельяновка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123185203;3;1
6ed81f08-749b-11df-b112-00215aee3ebe;Омелянівка;Омельяновка;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223588004;3;1
6ed81f09-749b-11df-b112-00215aee3ebe;Омелянівка;Омельяновка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823482806;3;1
6ed81f0a-749b-11df-b112-00215aee3ebe;Омелянівка;Омельяновка;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;;3;1
17af7b4c-ddb9-11df-9197-00215aee3ebe;Омелянівка;Омельяновка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;;3;1
6ed81f0b-749b-11df-b112-00215aee3ebe;Омельгород;Омельгород;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520588605;3;1
6ed81f0c-749b-11df-b112-00215aee3ebe;Омелькове;Омельково;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923586705;3;1
6ed81f0e-749b-11df-b112-00215aee3ebe;Омельне;Омельно;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725785602;3;1
6ed81f0f-749b-11df-b112-00215aee3ebe;Омельне;Омельно;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721884301;3;1
6ed81f10-749b-11df-b112-00215aee3ebe;Омельник;Омельник;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322483201;3;1
6ed81f11-749b-11df-b112-00215aee3ebe;Омельник;Омельник;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323987811;3;1
6ed81f12-749b-11df-b112-00215aee3ebe;Омельник;Омельник;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524685801;3;1
6ed81f13-749b-11df-b112-00215aee3ebe;Омельниче;Омельничье;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322055105;3;1
6ed81f14-749b-11df-b112-00215aee3ebe;Омельченки;Омельченки;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920682606;3;1
6ed81f15-749b-11df-b112-00215aee3ebe;Омельченки;Омельченки;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321782506;3;1
6ed81f16-749b-11df-b112-00215aee3ebe;Ометинці;Ометинцы;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523086201;3;1
6ed81f17-749b-11df-b112-00215aee3ebe;Омильне;Омыльное;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821480802;3;1
6ed81f18-749b-11df-b112-00215aee3ebe;Омит;Омыт;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622284801;3;1
6ed81f19-749b-11df-b112-00215aee3ebe;Онацьке;Онацкое;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323284603;3;1
6ed81f1a-749b-11df-b112-00215aee3ebe;Онацьки;Онацки;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321082607;3;1
6ed81f1b-749b-11df-b112-00215aee3ebe;Онацьки;Онацки;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222286402;3;1
6ed81f1c-749b-11df-b112-00215aee3ebe;Онацьківці;Онацковцы;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823686001;3;1
6ed81f1d-749b-11df-b112-00215aee3ebe;Оникієве;Оникиево;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523184001;3;1
6ed81f1e-749b-11df-b112-00215aee3ebe;Оникіївка;Оникиевка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424187902;3;1
6ed81f1f-749b-11df-b112-00215aee3ebe;Онилове;Онилово;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125283601;3;1
6ed81f20-749b-11df-b112-00215aee3ebe;Ониськове;Ониськово;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823983101;3;1
6ed81f21-749b-11df-b112-00215aee3ebe;Ониськове;Ониськово;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122782202;3;1
6ed81f22-749b-11df-b112-00215aee3ebe;Онитківці;Онитковцы;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524583205;3;1
6ed81f24-749b-11df-b112-00215aee3ebe;Онишківці;Онишковцы;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125885701;3;1
6ed81f25-749b-11df-b112-00215aee3ebe;Онишківці;Онишковцы;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825580803;3;1
6ed81f26-749b-11df-b112-00215aee3ebe;Онишківці;Онишковцы;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621680409;3;1
6ed81f27-749b-11df-b112-00215aee3ebe;Онишпіль;Онишполь;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510121;3;1
6ed81f28-749b-11df-b112-00215aee3ebe;Онищенки;Онищенки;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324285702;3;1
6ed81f29-749b-11df-b112-00215aee3ebe;Онищенки;Онищенки;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322485102;3;1
6ed81f2a-749b-11df-b112-00215aee3ebe;Онищенків;Онищенков;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424182105;3;1
6ed81f23-749b-11df-b112-00215aee3ebe;Онішки (Онишки);Онишки;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323683801;3;1
6ed81f2b-749b-11df-b112-00215aee3ebe;Онок;Онок;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121283601;3;0
6ed81f2c-749b-11df-b112-00215aee3ebe;Оноківці;Оноковцы;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2124884801;2;1
6ed81f2d-749b-11df-b112-00215aee3ebe;Онопріївка;Оноприевка;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124087401;3;1
6ed81f2e-749b-11df-b112-00215aee3ebe;Онорівка;Оноровка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283604;3;1
6ed81f2f-749b-11df-b112-00215aee3ebe;Онут;Онут;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321586401;3;1
6ed81f30-749b-11df-b112-00215aee3ebe;Онуфріївка;Онуфриевка;місто;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524655100;3;1
6ed81f31-749b-11df-b112-00215aee3ebe;Опака;Опака;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621285401;3;1
6ed81f32-749b-11df-b112-00215aee3ebe;Опаки;Опаки;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621884603;3;1
6ed81f33-749b-11df-b112-00215aee3ebe;Опанасів;Опанасов;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221255501;3;1
6ed81f34-749b-11df-b112-00215aee3ebe;Опарипси;Опарипсы;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625881604;3;1
6ed81eb7-749b-11df-b112-00215aee3ebe;Опеньки;Опенки;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422080604;3;1
6ed81f38-749b-11df-b112-00215aee3ebe;Опільсько;Опольско;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624884601;3;1
6ed81f39-749b-11df-b112-00215aee3ebe;Опішня;Опошня;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321355400;3;1
6ed81f3a-749b-11df-b112-00215aee3ebe;Опішняни;Опошняны;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086508;3;1
6ed81f3c-749b-11df-b112-00215aee3ebe;Оплітна;Оплитна;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622789205;3;1
6ed81f3b-749b-11df-b112-00215aee3ebe;Опліцько;Оплицкое;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623983805;3;1
6ed81f3e-749b-11df-b112-00215aee3ebe;Ополонське;Ополонское;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924789106;3;1
6ed81f3f-749b-11df-b112-00215aee3ebe;Ополонське;Ополонское;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420385502;3;1
6ed81f40-749b-11df-b112-00215aee3ebe;Опорець;Опорец;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624584901;3;1
6ed81f41-749b-11df-b112-00215aee3ebe;Опори;Опоры;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621285701;3;1
91fc81db-266d-11e7-80fd-1c98ec135263;Опришівці;Опришивци;село;a1e9f9b8-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;;3;1
6ed81f43-749b-11df-b112-00215aee3ebe;Опришки;Опрышки;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610118;3;1
6ed81f44-749b-11df-b112-00215aee3ebe;Опрілівці;Опреловцы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122487002;3;1
6ed81f46-749b-11df-b112-00215aee3ebe;Орадівка;Орадовка;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124684501;3;1
6ed81f47-749b-11df-b112-00215aee3ebe;Оране;Ораное;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222083201;3;1
6ed81f48-749b-11df-b112-00215aee3ebe;Орани;Ораны;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620385316;3;1
6ed81f49-749b-11df-b112-00215aee3ebe;Орані;Орани;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720581604;3;1
6ed81f4a-749b-11df-b112-00215aee3ebe;Оратів;Оратов;місто;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523155100;3;0
6ed81f4b-749b-11df-b112-00215aee3ebe;Оратів вокзал;Оратов Вокзал;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523183301;3;1
6ed81f4c-749b-11df-b112-00215aee3ebe;Оратівка;Оратовка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523184302;3;1
6ed81f4d-749b-11df-b112-00215aee3ebe;Орбіта;Орбита;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125400000;3;1
6ed81f4e-749b-11df-b112-00215aee3ebe;Орв'яниця;Орвяница;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621887703;3;1
6ed81f4f-749b-11df-b112-00215aee3ebe;Орданівка;Ордановка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321084201;3;1
6ed81f58-749b-11df-b112-00215aee3ebe;Орджонікідзе;Орджоникидзе;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223587401;3;1
6ed81f59-749b-11df-b112-00215aee3ebe;Орджонікідзе;Орджоникидзе;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;;3;1
6ed81f5a-749b-11df-b112-00215aee3ebe;Орджонікідзе;Орджоникидзе;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521110101;3;1
6ed81f53-749b-11df-b112-00215aee3ebe;Орджонікідзе (село);Орджоникидзе (село);село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;*122298450;3;1
6ed81f5b-749b-11df-b112-00215aee3ebe;Орджонікідзе, б/в;Орджоникидзе;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
6ed81f5c-749b-11df-b112-00215aee3ebe;Ординці;Ордынцы;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523482502;3;1
6ed81f5d-749b-11df-b112-00215aee3ebe;Ординці;Ордынцы;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824785501;3;1
6ed81f5e-749b-11df-b112-00215aee3ebe;Ордів;Ордов;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623980903;3;1
6ed81f5f-749b-11df-b112-00215aee3ebe;Ордівка;Ордовка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324283001;3;1
6ed81f61-749b-11df-b112-00215aee3ebe;Ордо-Василівка;Ордо-Василевка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225286601;3;1
6ed81f63-749b-11df-b112-00215aee3ebe;Орел;Орел;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121685805;3;1
6ed81f64-749b-11df-b112-00215aee3ebe;Орелець;Орелец;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625286303;3;1
6ed81f65-749b-11df-b112-00215aee3ebe;Орепи;Орепы;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824084201;3;1
6ed81f66-749b-11df-b112-00215aee3ebe;Орестів;Орестов;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622680604;3;1
6ed81f67-749b-11df-b112-00215aee3ebe;Орестівка;Орестовка;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325086803;3;1
6ed81f68-749b-11df-b112-00215aee3ebe;Орестопіль;Орестополь;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224283406;3;1
6ed81f69-749b-11df-b112-00215aee3ebe;Оржиця;Оржица;місто;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320884904;3;1
6ed81f6a-749b-11df-b112-00215aee3ebe;Оржиця;Оржица;місто;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323655100;3;1
6ed81f6b-749b-11df-b112-00215aee3ebe;Оржів;Оржев;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624655700;3;1
cdc44f4d-dde8-11df-9197-00215aee3ebe;Оржів;Оржев;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755144;3;1
6ed81f6c-749b-11df-b112-00215aee3ebe;Оринин;Оринин;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822485701;2;1
6ed81f7d-749b-11df-b112-00215aee3ebe;Орихівчик;Ореховчик;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620380703;3;1
6ed81f6d-749b-11df-b112-00215aee3ebe;Оришківці;Оришковцы;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123810105;3;1
6ed81f6e-749b-11df-b112-00215aee3ebe;Оришківці;Оришковцы;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121685401;3;1
6ed81f6f-749b-11df-b112-00215aee3ebe;Орищі;Орищи;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721182803;3;1
6ed81f70-749b-11df-b112-00215aee3ebe;Орів;Оров;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624585201;3;1
6ed81f71-749b-11df-b112-00215aee3ebe;Орілька;Орелька;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223283003;3;1
6ed81f72-749b-11df-b112-00215aee3ebe;Орілька;Орелька;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323955700;3;1
6ed81f73-749b-11df-b112-00215aee3ebe;Орільське;Орельское;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324881503;3;1
6ed81f74-749b-11df-b112-00215aee3ebe;Оріханове;Ореханово;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5310390004;3;1
6ed81f75-749b-11df-b112-00215aee3ebe;Оріхів;Орехов;місто;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323910100;2;1
6ed81f76-749b-11df-b112-00215aee3ebe;Оріхівка;Ореховка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923884805;3;1
6ed81f77-749b-11df-b112-00215aee3ebe;Оріхівка;Ореховка;село;6b07f8a2-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5121485701;3;1
6ed81f7b-749b-11df-b112-00215aee3ebe;Оріхівка;Ореховка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824281303;3;1
6ed81f7c-749b-11df-b112-00215aee3ebe;Оріхівка;Ореховка;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322885301;3;1
6ed81f7e-749b-11df-b112-00215aee3ebe;Оріхівщина;Ореховщина;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324881907;3;1
6ed81f7f-749b-11df-b112-00215aee3ebe;Оріхове;Орехово;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225084309;3;1
6ed81f85-749b-11df-b112-00215aee3ebe;Оріхове;Орехово;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222283202;3;1
6ed81f87-749b-11df-b112-00215aee3ebe;Оріховець;Ореховец;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124685401;3;1
6ed81f88-749b-11df-b112-00215aee3ebe;Оріховець;Ореховец;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224085201;3;1
6ed81f89-749b-11df-b112-00215aee3ebe;Оріховиця;Ореховица;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2124884802;3;1
6ed81f8c-749b-11df-b112-00215aee3ebe;Орішанка;Орешанка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322655108;3;1
6ed81f8d-749b-11df-b112-00215aee3ebe;Орішківці;Орешковцы;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621555908;3;1
6ed81f8e-749b-11df-b112-00215aee3ebe;Орли;Орлы;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224287801;3;1
6ed81f8f-749b-11df-b112-00215aee3ebe;Орли;Орлы;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122881202;3;1
6ed81f90-749b-11df-b112-00215aee3ebe;Орлик;Орлик;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;5321885701;3;1
6ed81f91-749b-11df-b112-00215aee3ebe;Орликівка;Орликовка;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424784501;3;1
6ed81f92-749b-11df-b112-00215aee3ebe;Орликівщина;Орликовщина;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324888215;3;1
6ed81f95-749b-11df-b112-00215aee3ebe;Орлинці;Орлинцы;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825586502;3;1
c6324772-8715-11e7-80ca-1c98ec135261;Орлинці;Орлинци;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;;3;1
6ed81f96-749b-11df-b112-00215aee3ebe;Орлів Яр;Орлов Яр;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925681504;3;1
6ed81f97-749b-11df-b112-00215aee3ebe;Орлівка;Орловка;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925683001;3;1
6ed81f98-749b-11df-b112-00215aee3ebe;Орлівка;Орловка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225683007;3;1
6ed81f99-749b-11df-b112-00215aee3ebe;Орлівка;Орловка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123181702;3;1
6ed81f9a-749b-11df-b112-00215aee3ebe;Орлівка;Орловка;село;d00d3b62-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5124183601;3;1
6ed81fa2-749b-11df-b112-00215aee3ebe;Орлівка;Орловка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423687801;3;1
6ed81fa3-749b-11df-b112-00215aee3ebe;Орлівка;Орловка;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422786001;3;1
6ed81fa4-749b-11df-b112-00215aee3ebe;Орлівка;Орловка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523784801;3;1
6ed81fa5-749b-11df-b112-00215aee3ebe;Орлівка;Орловка;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625482505;3;1
6ed81fa6-749b-11df-b112-00215aee3ebe;Орлівка;Орловка;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620483602;3;1
6ed81fa7-749b-11df-b112-00215aee3ebe;Орлівське;Орловское;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225982508;3;1
6ed81fa8-749b-11df-b112-00215aee3ebe;Орлівське;Орловское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321581302;3;1
6ed81fac-749b-11df-b112-00215aee3ebe;Орлівщина;Орловщина;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223285001;2;1
6ed81fad-749b-11df-b112-00215aee3ebe;Орлова Балка;Орлова Балка;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525888303;3;1
6ed81fae-749b-11df-b112-00215aee3ebe;Орлове;Орлово;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225083502;3;1
6ed81fb0-749b-11df-b112-00215aee3ebe;Орлове;Орлово;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;6521884001;3;1
6ed81fb1-749b-11df-b112-00215aee3ebe;Орлове;Орлово;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523684604;3;1
6ed81fb2-749b-11df-b112-00215aee3ebe;Орлове Поле;Орлово Поле;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821480805;3;1
6ed81fb3-749b-11df-b112-00215aee3ebe;Орловець;Орловец;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122082703;3;1
6ed81fb4-749b-11df-b112-00215aee3ebe;Орловець;Орловец;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120385501;3;1
6ed81fb9-749b-11df-b112-00215aee3ebe;Оросієво;Оросиево;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120487601;3;1
6ed81fba-749b-11df-b112-00215aee3ebe;Ортиничі;Ортиничи;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621280807;3;1
6ed81fbb-749b-11df-b112-00215aee3ebe;Орховичі;Орховичи;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624285603;3;1
6ed81fbc-749b-11df-b112-00215aee3ebe;Орчик;Орчик;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322282004;3;1
ab1d68bd-21ad-11e1-b44a-003048d2b473;Оршанець;Оршанец;село;5394b345-41be-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7110136701;3;1
6ed81fbd-749b-11df-b112-00215aee3ebe;Оршівці;Оршевцы;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322587001;3;1
6ed81fbe-749b-11df-b112-00215aee3ebe;Орява;Орява;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624585501;3;1
6ed81fbf-749b-11df-b112-00215aee3ebe;Орявчик;Орявчик;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624583002;3;1
6ed81fc0-749b-11df-b112-00215aee3ebe;Оса;Оса;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725580402;3;1
6ed81fc1-749b-11df-b112-00215aee3ebe;Осава;Осава;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125385205;3;1
6ed81fc2-749b-11df-b112-00215aee3ebe;Осадче;Осадчее;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223884001;3;1
6ed81fc4-749b-11df-b112-00215aee3ebe;Осекрів;Осекров;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725582607;3;1
6ed81fc5-749b-11df-b112-00215aee3ebe;Оселівка;Оселевка;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322087601;3;1
6ed81fc6-749b-11df-b112-00215aee3ebe;Оселя;Оселя;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625887003;3;1
6ed81fc7-749b-11df-b112-00215aee3ebe;Осереби;Осеребы;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725555104;3;1
6ed81fc8-749b-11df-b112-00215aee3ebe;Осетрівка;Осетровка;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825184805;3;1
6ed81fc9-749b-11df-b112-00215aee3ebe;Осещина;Осещина;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221888802;3;1
6ed81fca-749b-11df-b112-00215aee3ebe;Осика;Осыка;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755145;3;1
6ed81fcd-749b-11df-b112-00215aee3ebe;Осикове;Осыково;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222784202;3;1
6ed81fce-749b-11df-b112-00215aee3ebe;Осикове;Осыково;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822588409;3;1
6ed81fcf-749b-11df-b112-00215aee3ebe;Осикове;Осыково;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820884601;3;1
6ed81fd0-749b-11df-b112-00215aee3ebe;Осикове;Осыково;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521781006;3;1
6ed81fd1-749b-11df-b112-00215aee3ebe;Осикове;Осыково;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821584002;3;1
6ed81fd2-749b-11df-b112-00215aee3ebe;Осиковий Копець;Осыковый Копец;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822588411;3;1
6ed81fd3-749b-11df-b112-00215aee3ebe;Осикувате;Осыковатое;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224581708;3;1
6ed81fd4-749b-11df-b112-00215aee3ebe;Осикувате;Осыковатое;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520884205;3;1
6ed81fd5-749b-11df-b112-00215aee3ebe;Осикувате;Осыковатое;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522580604;3;1
6ed81fd6-749b-11df-b112-00215aee3ebe;Осинівка;Осиновка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125484701;3;1
6ed81fd7-749b-11df-b112-00215aee3ebe;Осинівка;Осиновка;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420381506;3;1
6ed81fd8-749b-11df-b112-00215aee3ebe;Осинова Роща;Осиновая Роща;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
6ed81fdb-749b-11df-b112-00215aee3ebe;Осипенко;Осипенко;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225083007;3;1
6ed81fde-749b-11df-b112-00215aee3ebe;Оситна;Оситная;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523885601;3;1
6ed81fdf-749b-11df-b112-00215aee3ebe;Оситняжка;Оситняжка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523885201;3;1
6ed81fe0-749b-11df-b112-00215aee3ebe;Оситняжка;Оситняжка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522586401;3;1
6ed81fe1-749b-11df-b112-00215aee3ebe;Осички;Осычки;село;6b07f835-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5124382801;3;1
6ed81fe2-749b-11df-b112-00215aee3ebe;Осички;Осычки;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825087401;3;1
6ed81fe3-749b-11df-b112-00215aee3ebe;Осички;Осычки;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524355103;3;1
6ed81fe4-749b-11df-b112-00215aee3ebe;Осична;Осычная;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524883302;3;1
6ed81fe5-749b-11df-b112-00215aee3ebe;Осична;Осычная;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523183601;3;1
6ed81fe6-749b-11df-b112-00215aee3ebe;Осичне;Осычно;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820655405;3;1
6ed81fe7-749b-11df-b112-00215aee3ebe;Осів;Осов;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825055301;3;1
6ed81fe8-749b-11df-b112-00215aee3ebe;Осівка;Осовка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821784401;3;1
6ed81fe9-749b-11df-b112-00215aee3ebe;Осівка;Осовка;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825687003;3;1
6ed81fea-749b-11df-b112-00215aee3ebe;Осівці;Осовцы;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721485801;3;1
6ed81feb-749b-11df-b112-00215aee3ebe;Осівці;Осовцы;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121281201;3;1
6ed81fec-749b-11df-b112-00215aee3ebe;Осівці;Осовцы;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820983601;3;1
6ed81fed-749b-11df-b112-00215aee3ebe;Осіївка;Осиевка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520483603;3;1
6ed81fee-749b-11df-b112-00215aee3ebe;Осій;Осий;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121986501;3;0
6ed81fef-749b-11df-b112-00215aee3ebe;Осітна;Осотная;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124684701;3;1
6ed81ff0-749b-11df-b112-00215aee3ebe;Осіч;Осич;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420387003;3;1
6ed81ff1-749b-11df-b112-00215aee3ebe;Осічок;Осичок;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522486804;3;1
871626a9-749b-11df-b112-00215aee3ebe;Оскіл (Червоний Оскіл);Оскіл (Червоний Оскіл);село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322888001;3;1
6ed81ff3-749b-11df-b112-00215aee3ebe;Осламів;Осламов;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820685501;3;1
6ed81ff4-749b-11df-b112-00215aee3ebe;Осмиловичі;Осмиловичи;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721187706;3;1
6ed81ff5-749b-11df-b112-00215aee3ebe;Осмолода;Осмолода;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624884101;3;1
6ed81ff6-749b-11df-b112-00215aee3ebe;Осни;Осны;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822883204;3;1
6ed81ff7-749b-11df-b112-00215aee3ebe;Осники;Осники;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123884505;3;1
6ed81ff8-749b-11df-b112-00215aee3ebe;Осники;Осники;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825682002;3;1
6ed81ff9-749b-11df-b112-00215aee3ebe;Осницьк;Осницк;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625085603;3;1
6ed81ffa-749b-11df-b112-00215aee3ebe;Основа;Основа;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283805;3;1
6ed81ffb-749b-11df-b112-00215aee3ebe;Основа;Основа;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425383502;3;1
6ed81ffc-749b-11df-b112-00215aee3ebe;Основинці;Основинцы;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323555101;3;1
6ed81ffd-749b-11df-b112-00215aee3ebe;Осняг;Осняг;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325180505;3;1
6ed81ffe-749b-11df-b112-00215aee3ebe;Осняги;Осняги;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5310390005;3;1
6ed81fff-749b-11df-b112-00215aee3ebe;Осова;Осова;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821782007;3;1
6ed82000-749b-11df-b112-00215aee3ebe;Осова;Осова;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621885901;3;1
6ed82001-749b-11df-b112-00215aee3ebe;Осова;Осова;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623484205;3;1
6ed82002-749b-11df-b112-00215aee3ebe;Осове;Осово;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423682404;3;1
6ed82003-749b-11df-b112-00215aee3ebe;Осове;Осово;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323287504;3;1
6ed82004-749b-11df-b112-00215aee3ebe;Осовець;Осовец;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222081305;3;1
6ed82005-749b-11df-b112-00215aee3ebe;Осовець;Осовец;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420688005;3;1
6ed82007-749b-11df-b112-00215aee3ebe;Осовиця;Осовица;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621885205;3;1
6ed82008-749b-11df-b112-00215aee3ebe;Осоївка;Осоевка;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922383801;3;1
6ed8200a-749b-11df-b112-00215aee3ebe;Осокори;Осокоры;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324582011;3;1
6ed8200b-749b-11df-b112-00215aee3ebe;Осокорівка;Осокоровка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224880514;3;1
6ed8200c-749b-11df-b112-00215aee3ebe;Осокорівка;Осокоровка;село;78f4540d-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6524184501;3;1
6ed8200d-749b-11df-b112-00215aee3ebe;Осокорівка;Осокоровка;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420685401;3;1
6ed8200e-749b-11df-b112-00215aee3ebe;Осолинка;Осолинка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522485801;3;1
6ed8200f-749b-11df-b112-00215aee3ebe;Осталовичі;Осталовичи;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623385601;3;1
6ed82010-749b-11df-b112-00215aee3ebe;Остальці;Остальцы;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125088602;3;1
74dc45f0-749b-11df-b112-00215aee3ebe;Остап'є;Остапье;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320283601;3;1
74dc45f1-749b-11df-b112-00215aee3ebe;Остап'є;Остапье;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124685701;3;1
6ed82013-749b-11df-b112-00215aee3ebe;Остапенки;Остапенки;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324881908;3;1
74dc45f2-749b-11df-b112-00215aee3ebe;Остапи;Остапы;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822884001;3;1
74dc45f3-749b-11df-b112-00215aee3ebe;Остапівка;Остаповка;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820380408;3;1
74dc45f4-749b-11df-b112-00215aee3ebe;Остапівка;Остаповка;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824880206;3;1
74dc45f5-749b-11df-b112-00215aee3ebe;Остапівка;Остаповка;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423055701;3;1
74dc45f6-749b-11df-b112-00215aee3ebe;Остапівка;Остаповка;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421183601;3;1
74dc45f7-749b-11df-b112-00215aee3ebe;Остапівка;Остаповка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120687601;3;1
74dc45f8-749b-11df-b112-00215aee3ebe;Остапівка;Остаповка;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322885701;3;1
74dc45f9-749b-11df-b112-00215aee3ebe;Остапівка;Остаповка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323284601;3;1
74dc45fa-749b-11df-b112-00215aee3ebe;Остапівське;Остаповское;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224281207;3;1
74dc45fb-749b-11df-b112-00215aee3ebe;Остапківці;Остапкивцы;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623285301;3;1
74dc45fc-749b-11df-b112-00215aee3ebe;Остапківці;Остапкивцы;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523088803;3;1
74dc45fd-749b-11df-b112-00215aee3ebe;Остапківці;Остапкивцы;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821286201;3;1
74dc45fe-749b-11df-b112-00215aee3ebe;Остапці;Остапцы;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322480706;3;1
74dc45ff-749b-11df-b112-00215aee3ebe;Осташівці;Осташевцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122687301;3;1
74dc4600-749b-11df-b112-00215aee3ebe;Осташки;Осташки;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825085201;3;1
74dc4601-749b-11df-b112-00215aee3ebe;Остер;Остер;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422010400;2;1
871626aa-749b-11df-b112-00215aee3ebe;Остер (Червоний Остер);Остер (Червоный Остер);село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420888803;3;1
74dc4602-749b-11df-b112-00215aee3ebe;Остерське;Остерское;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320681003;3;1
74dc4603-749b-11df-b112-00215aee3ebe;Остки;Остки;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625086203;3;1
74dc4605-749b-11df-b112-00215aee3ebe;Остра;Остра;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7322581505;3;1
74dc4607-749b-11df-b112-00215aee3ebe;Остриня;Острыня;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625610109;3;1
74dc4608-749b-11df-b112-00215aee3ebe;Остриця;Острица;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323083603;3;1
74dc4609-749b-11df-b112-00215aee3ebe;Остриця;Острица;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320784001;3;1
74dc460a-749b-11df-b112-00215aee3ebe;Острів;Остров;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721887204;3;1
74dc460b-749b-11df-b112-00215aee3ebe;Острів;Остров;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125286401;2;1
74dc460c-749b-11df-b112-00215aee3ebe;Острів;Остров;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621286202;3;1
74dc460d-749b-11df-b112-00215aee3ebe;Острів;Остров;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624884201;2;1
74dc460e-749b-11df-b112-00215aee3ebe;Острів;Остров;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620682803;3;1
74dc460f-749b-11df-b112-00215aee3ebe;Острів;Остров;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624284305;3;1
74dc4610-749b-11df-b112-00215aee3ebe;Острів;Остров;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623083603;3;1
74dc4611-749b-11df-b112-00215aee3ebe;Острів;Остров;село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223784501;3;1
74dc4612-749b-11df-b112-00215aee3ebe;Острів;Остров;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824283303;3;1
74dc4613-749b-11df-b112-00215aee3ebe;Острів;Остров;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420380503;3;1
74dc4614-749b-11df-b112-00215aee3ebe;Острів;Остров;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620880603;3;1
74dc4615-749b-11df-b112-00215aee3ebe;Острів;Остров;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625886502;3;1
74dc4616-749b-11df-b112-00215aee3ebe;Острів;Остров;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621682608;3;1
5cb6173a-749b-11df-b112-00215aee3ebe;Острів Надії (Кіровське);Острив Надии (Кировское);село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420889604;3;1
74dc4604-749b-11df-b112-00215aee3ebe;Острів'я;Островье;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725783904;3;1
74dc4617-749b-11df-b112-00215aee3ebe;Острівець;Островец;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125086201;3;1
74dc4618-749b-11df-b112-00215aee3ebe;Острівець;Островец;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621683101;3;1
74dc4619-749b-11df-b112-00215aee3ebe;Острівець;Островец;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124385801;3;1
74dc461a-749b-11df-b112-00215aee3ebe;Острівка;Островка;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825182207;3;1
74dc461b-749b-11df-b112-00215aee3ebe;Острівка;Островка;село;6b07f835-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5124383204;3;1
74dc461c-749b-11df-b112-00215aee3ebe;Острівка;Островка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524087203;3;1
74dc461d-749b-11df-b112-00215aee3ebe;Острівне;Островное;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120484701;3;1
74dc461e-749b-11df-b112-00215aee3ebe;Острівок;Островок;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721488602;3;1
74dc461f-749b-11df-b112-00215aee3ebe;Острівок;Островок;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720582015;3;1
74dc4620-749b-11df-b112-00215aee3ebe;Острівок;Островок;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624882004;3;1
74dc4621-749b-11df-b112-00215aee3ebe;Острівськ;Островск;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622282003;3;1
74dc4623-749b-11df-b112-00215aee3ebe;Острівці;Островцы;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620881303;3;1
87162677-749b-11df-b112-00215aee3ebe;Острівці (Червоне);Островцы (Червоное);село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621885403;3;1
74dc4624-749b-11df-b112-00215aee3ebe;Острівчик-Пильний;Островчик-Польный;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620680402;3;1
74dc4625-749b-11df-b112-00215aee3ebe;Острівщина;Островщина;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684801;3;1
74dc4626-749b-11df-b112-00215aee3ebe;Остріїв;Остриев;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623882303;3;1
74dc4627-749b-11df-b112-00215aee3ebe;Острійки;Острийки;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220484601;2;1
74dc4628-749b-11df-b112-00215aee3ebe;Островерхівка;Островерховка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321786205;3;1
74dc4629-749b-11df-b112-00215aee3ebe;Островерхівка;Островерховка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5310390006;3;1
74dc462a-749b-11df-b112-00215aee3ebe;Острови;Островы;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824283303;3;1
74dc462b-749b-11df-b112-00215aee3ebe;Островки;Островки;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723655408;3;1
74dc462c-749b-11df-b112-00215aee3ebe;Островське;Островское;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824881602;3;1
74dc462d-749b-11df-b112-00215aee3ebe;Островське;Островское;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323655102;3;1
74dc4631-749b-11df-b112-00215aee3ebe;Островчани;Островчаны;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822489303;3;1
74dc4632-749b-11df-b112-00215aee3ebe;Острог;Острог;місто;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5610900000;2;0
74dc4633-749b-11df-b112-00215aee3ebe;Острогірське;Острогорское;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822383804;3;1
74dc4634-749b-11df-b112-00215aee3ebe;Острожани;Острожаны;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120985001;3;1
74dc4635-749b-11df-b112-00215aee3ebe;Острожець;Острожец;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622483603;3;1
74dc4636-749b-11df-b112-00215aee3ebe;Острожець;Острожец;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623885801;3;1
74dc4637-749b-11df-b112-00215aee3ebe;Острожок;Острожок;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820684803;3;1
74dc4638-749b-11df-b112-00215aee3ebe;Остроушки;Остроушки;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925383702;3;1
74dc4639-749b-11df-b112-00215aee3ebe;Осьмаки;Осьмаки;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423087201;3;1
74dc463a-749b-11df-b112-00215aee3ebe;Осьмаки;Осьмаки;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422281002;3;1
74dc463b-749b-11df-b112-00215aee3ebe;Осьмиговичі;Осьмиговичи;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725583404;3;1
74dc463c-749b-11df-b112-00215aee3ebe;Отиневичі;Отыневичи;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621587201;3;1
74dc463d-749b-11df-b112-00215aee3ebe;Отинія;Отыния;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623255400;3;1
74dc463e-749b-11df-b112-00215aee3ebe;Отрішки;Отришки;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224255107;3;1
74dc463f-749b-11df-b112-00215aee3ebe;Отроків;Отроков;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823387001;3;1
74dc4640-749b-11df-b112-00215aee3ebe;Отрохи;Отрохи;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422085602;3;1
74dc4641-749b-11df-b112-00215aee3ebe;Отрохове;Отрохово;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922686307;3;1
74dc4642-749b-11df-b112-00215aee3ebe;Охиньки;Охиньки;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424186201;3;1
74dc4643-749b-11df-b112-00215aee3ebe;Охлопів;Охлопов;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720882403;3;1
74dc4644-749b-11df-b112-00215aee3ebe;Охматів;Охматов;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120985401;3;1
74dc4645-749b-11df-b112-00215aee3ebe;Охматків;Охматков;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621483509;3;1
74dc4646-749b-11df-b112-00215aee3ebe;Охнівка;Охновка;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720580409;3;1
74dc4647-749b-11df-b112-00215aee3ebe;Охотин;Охотин;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881811;3;1
74dc4648-749b-11df-b112-00215aee3ebe;Охотівка;Охотовка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386029;3;1
74dc4649-749b-11df-b112-00215aee3ebe;Охотники;Охотники;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725555105;3;1
74dc464b-749b-11df-b112-00215aee3ebe;Охотниче;Охотничье;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755132;3;1
74dc464c-749b-11df-b112-00215aee3ebe;Охотниче;Охотничье;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222982810;3;1
74dc4650-749b-11df-b112-00215aee3ebe;Охоче;Охочее;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325455109;3;1
74dc4651-749b-11df-b112-00215aee3ebe;Охоче;Охочее;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324283501;3;1
74dc4652-749b-11df-b112-00215aee3ebe;Охрамієвичі;Охрамиевичи;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422486001;3;1
74dc4655-749b-11df-b112-00215aee3ebe;Охрімівці;Охримовцы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122487250;3;1
74dc4656-749b-11df-b112-00215aee3ebe;Охрімівці;Охримовцы;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820686001;3;1
74dc4657-749b-11df-b112-00215aee3ebe;Охтирка;Ахтырка;місто;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5910200000;2;0
74dc4658-749b-11df-b112-00215aee3ebe;Очаків;Очаков;місто;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4810300000;2;1
74dc4659-749b-11df-b112-00215aee3ebe;Очаківське;Очаковское;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083817;3;1
74dc465d-749b-11df-b112-00215aee3ebe;Очеретне;Очеретное;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123488602;3;1
74dc465e-749b-11df-b112-00215aee3ebe;Очеретня;Очеретня;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823983301;3;1
74dc465f-749b-11df-b112-00215aee3ebe;Очеретня;Очеретня;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523483801;3;1
74dc4660-749b-11df-b112-00215aee3ebe;Очеретове;Очеретово;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321287005;3;1
74dc4661-749b-11df-b112-00215aee3ebe;Очеретувате;Очеретоватое;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324585601;3;1
74dc4662-749b-11df-b112-00215aee3ebe;Очеретувате;Очеретоватое;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222385501;3;1
74dc4663-749b-11df-b112-00215aee3ebe;Очеретувате;Очеретоватое;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224882004;3;1
74dc4664-749b-11df-b112-00215aee3ebe;Очеретувате;Очеретоватое;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220786616;3;1
74dc4667-749b-11df-b112-00215aee3ebe;Очеретяне;Очеретяное;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222281202;3;1
74dc4668-749b-11df-b112-00215aee3ebe;Очеретяне;Очеретяное;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521980709;3;1
74dc4669-749b-11df-b112-00215aee3ebe;Очеретянка;Очеретянка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825483901;3;1
74dc466a-749b-11df-b112-00215aee3ebe;Очеретянка;Очеретянка;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825686601;3;1
74dc466b-749b-11df-b112-00215aee3ebe;Очитків;Очитков;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523183901;3;1
74dc466c-749b-11df-b112-00215aee3ebe;Очканівка;Очкановка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324080309;3;1
74dc466d-749b-11df-b112-00215aee3ebe;Очкине;Очкино;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924484401;3;1
74dc466e-749b-11df-b112-00215aee3ebe;Ошихліби;Ошихлебы;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322587501;3;1
74dc466f-749b-11df-b112-00215aee3ebe;Ощів;Ощев;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720887703;3;1
7ad94f0b-749b-11df-b112-00215aee3ebe;П'ядики;Пядики;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623285801;3;1
7ad94f0c-749b-11df-b112-00215aee3ebe;П'ядиківці;Пядиковцы;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322555701;3;1
7ad94fa6-749b-11df-b112-00215aee3ebe;П'яннє (Радянське);Пянно (Радянское);село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623886801;3;1
7ad94f0d-749b-11df-b112-00215aee3ebe;П'яновичі;Пьяновичи;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624286901;3;1
7ad94f0e-749b-11df-b112-00215aee3ebe;П'ятигірське;Пятигорское;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320286001;3;1
7ad94f0f-749b-11df-b112-00215aee3ebe;П'ятигірці;Пятигорцы;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322884904;3;1
7ad94f11-749b-11df-b112-00215aee3ebe;П'ятигори;Пятигоры;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622685301;3;1
7ad94f13-749b-11df-b112-00215aee3ebe;П'ятидні;Пятидни;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720510416;3;1
7ad94f14-749b-11df-b112-00215aee3ebe;П'ятидуб;Пятидуб;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923581706;3;1
7ad94f15-749b-11df-b112-00215aee3ebe;П'ятидуб;Пятидуб;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823485506;3;1
7ad94f16-749b-11df-b112-00215aee3ebe;П'ятикори;Пятикоры;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722481806;3;1
7ad94f18-749b-11df-b112-00215aee3ebe;П'ятирічка;Пятиричка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821155101;3;1
7ad94f19-749b-11df-b112-00215aee3ebe;П'ятирічка;Пятиричка;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820681804;3;1
7ad94f1c-749b-11df-b112-00215aee3ebe;П'ятихатка;Пятихатка;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222988304;3;1
7ad94f1d-749b-11df-b112-00215aee3ebe;П'ятихатка;Пятихатка;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523283004;3;1
7ad94f1e-749b-11df-b112-00215aee3ebe;П'ятихатки;Пятихатки;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322485103;3;1
7ad94f1f-749b-11df-b112-00215aee3ebe;П'ятихатки;Пятихатки;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225982807;3;1
7ad94f20-749b-11df-b112-00215aee3ebe;П'ятихатки;Пятихатки;місто;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224510100;2;0
7ad94f21-749b-11df-b112-00215aee3ebe;П'ятихатки;Пятихатки;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821481304;3;1
7ad94f22-749b-11df-b112-00215aee3ebe;П'ятихатки;Пятихатки;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824282705;3;1
7ad94f24-749b-11df-b112-00215aee3ebe;П'ятихатки;Пятихатки;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520981003;3;1
7ad94f26-749b-11df-b112-00215aee3ebe;П'ятихатки;Пятихатки;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523180902;3;1
7ad94f27-749b-11df-b112-00215aee3ebe;П'ятихатки;Пятихатки;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522281904;3;1
7ad94f28-749b-11df-b112-00215aee3ebe;П'ятка;Пятка;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825886801;3;1
7ad94f29-749b-11df-b112-00215aee3ebe;П'яткине;Пяткино;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920382005;3;1
7ad94f2a-749b-11df-b112-00215aee3ebe;П'ятківка;Пятковка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520484203;3;1
7ad94f2b-749b-11df-b112-00215aee3ebe;П'ятниця;Пятница;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625186303;3;1
7ad94f2c-749b-11df-b112-00215aee3ebe;П'ятницьке;Пятницкое;село;64024b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324655105;3;1
7ad94f2d-749b-11df-b112-00215aee3ebe;П'ятничани;Пятничаны;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621588505;3;1
7ad94f2e-749b-11df-b112-00215aee3ebe;П'ятничани;Пятничаны;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625385801;3;1
7ad94f2f-749b-11df-b112-00215aee3ebe;П'ятничани;Пятничаны;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825286801;2;1
7ad94f30-749b-11df-b112-00215aee3ebe;П'ятомиколаївка;Пятониколаевка;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524684704;3;1
74dc4670-749b-11df-b112-00215aee3ebe;Павелки;Павелки;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820387801;3;1
74dc4671-749b-11df-b112-00215aee3ebe;Павленки;Павленки;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324881209;3;1
74dc4672-749b-11df-b112-00215aee3ebe;Павленки;Павленки;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325183007;3;1
74dc4673-749b-11df-b112-00215aee3ebe;Павленки;Павленки;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521684809;3;1
74dc4674-749b-11df-b112-00215aee3ebe;Павленківка;Павленковка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822085302;3;1
74dc4675-749b-11df-b112-00215aee3ebe;Павленкове;Павленково;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920655702;3;1
74dc4676-749b-11df-b112-00215aee3ebe;Павленкове;Павленково;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922987101;3;1
74dc4678-749b-11df-b112-00215aee3ebe;Павлики;Павлики;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620684407;3;1
5cb619a9-749b-11df-b112-00215aee3ebe;Павлики (Комінтерн);Павлики (Коминтерн);село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084905;3;1
74dc469b-749b-11df-b112-00215aee3ebe;Павликівка;Павликовка;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622885103;3;1
74dc4679-749b-11df-b112-00215aee3ebe;Павликівці;Павликовцы;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820985001;3;1
74dc467a-749b-11df-b112-00215aee3ebe;Павлинка;Павлинка;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121883201;3;1
74dc467b-749b-11df-b112-00215aee3ebe;Павлиш;Павлыш;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524655400;3;0
74dc467c-749b-11df-b112-00215aee3ebe;Павлів;Павлов;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120482707;3;1
74dc467d-749b-11df-b112-00215aee3ebe;Павлів;Павлов;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623984201;3;1
74dc467e-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755141;3;1
74dc467f-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610119;3;1
74dc4680-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322055106;3;1
74dc4681-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321880404;3;1
74dc4682-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323085001;3;1
74dc4683-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920686601;3;1
74dc4684-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721183801;3;1
74dc4685-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221084407;3;1
74dc4686-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222083008;3;1
74dc4687-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055409;3;1
74dc4688-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224885005;3;1
74dc4689-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220787701;3;1
74dc468a-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221881405;3;1
74dc468b-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225283306;3;1
74dc468c-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824582203;3;1
74dc468d-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655123;3;1
74dc468e-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825783701;3;1
74dc468f-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320886001;3;1
74dc4690-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324286003;3;1
74dc4691-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323183701;3;1
74dc4692-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;;3;1
74dc4693-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125283801;3;1
74dc4694-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123985404;3;1
74dc4695-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120485201;3;1
74dc469c-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2625888602;3;1
74dc469d-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625188205;3;1
74dc46a6-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220410510;3;1
74dc46a7-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825484101;3;1
74dc46a8-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424483203;3;1
74dc46a9-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425586003;3;0
74dc46aa-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122085701;3;1
74dc46ab-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120985801;3;1
74dc46ac-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121280403;3;1
74dc46ad-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523484001;3;1
74dc46ae-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521686103;3;1
74dc46af-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521210112;3;1
74dc46b0-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520283409;3;1
74dc46b1-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520883307;3;1
74dc46b2-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521985801;3;1
74dc46b3-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525286901;3;1
74dc46b4-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522884604;3;1
74dc46b5-749b-11df-b112-00215aee3ebe;Павлівка;Павловка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324881909;3;1
e0517ed0-db89-11df-9197-00215aee3ebe;Павлівка;Павловка;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;3;1
8cdd5ce6-de27-11df-9197-00215aee3ebe;Павлівка;Павловка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523183607;3;1
325c57f9-ddd2-11df-9197-00215aee3ebe;Павлівка;Павловка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;;3;1
25ce526a-a108-11e2-bb38-003048d2b473;Павлівка;Павловка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323585503;3;1
56bdd224-749b-11df-b112-00215aee3ebe;Павлівка (Жовтневе);Павловка (Жовтневое);село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325455301;3;1
74dc46b6-749b-11df-b112-00215aee3ebe;Павлівка Друга;Павловка Вторая;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323984501;3;1
74dc46b7-749b-11df-b112-00215aee3ebe;Павлівка Друга;Павловка Вторая;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124087405;3;1
74dc46b8-749b-11df-b112-00215aee3ebe;Павлівка Перша;Павловка Первая;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124087801;3;1
74dc46b9-749b-11df-b112-00215aee3ebe;Павлівське;Павловское;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920684905;3;1
74dc46ba-749b-11df-b112-00215aee3ebe;Павлівське;Павловское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321586101;3;1
74dc46bd-749b-11df-b112-00215aee3ebe;Павлівщина;Павловщина;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321683406;3;1
c8674a92-dbb0-11df-9197-00215aee3ebe;Павлівщина;Павловщина;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320880404;3;1
74dc46be-749b-11df-b112-00215aee3ebe;Павліщина;Павлищина;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120686902;3;1
74dc46c7-749b-11df-b112-00215aee3ebe;Павло - Григорівка;Павло-Григоровка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221081805;3;1
c6cc1c05-db7e-11df-9197-00215aee3ebe;Павлове;Павлово;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320888113;3;1
74dc46c0-749b-11df-b112-00215aee3ebe;Павловичі;Павловичи;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722481204;3;1
74dc46c1-749b-11df-b112-00215aee3ebe;Павловичі;Павловичи;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824285805;3;1
74dc46bf-749b-11df-b112-00215aee3ebe;Павлово;Павлово;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124084404;3;1
74dc46c2-749b-11df-b112-00215aee3ebe;Павловолуйськ;Павловолуйск;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524686209;3;1
74dc46c3-749b-11df-b112-00215aee3ebe;Павлогірківка;Павлогорковка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520886001;3;1
74dc46c4-749b-11df-b112-00215aee3ebe;Павлоград;Павлоград;місто;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1212400000;1;0
74dc46c8-749b-11df-b112-00215aee3ebe;Павлодарівка;Павлодаровка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821482604;3;1
74dc46c9-749b-11df-b112-00215aee3ebe;Павло-іванівське;Павло-Ивановское;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421410103;3;1
74dc46ca-749b-11df-b112-00215aee3ebe;Павло-Мар'янівка;Павло-Марьяновка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825781260;3;1
74dc46cb-749b-11df-b112-00215aee3ebe;Павло-Миколаївка;Павло-Николаевка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522580303;3;1
74dc46cc-749b-11df-b112-00215aee3ebe;Павлопілля;Павлополье;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222985001;3;1
74dc46ce-749b-11df-b112-00215aee3ebe;Павлюківка;Павлюковка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323581707;3;1
cdc44f54-dde8-11df-9197-00215aee3ebe;Павлюківка;Павлюковка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824282707;3;1
74dc46cf-749b-11df-b112-00215aee3ebe;Павлючки;Павлючки;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924782004;3;1
74dc46d0-749b-11df-b112-00215aee3ebe;Паволоч;Паволочь;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824784801;3;1
74dc46d1-749b-11df-b112-00215aee3ebe;Паволочка;Паволочка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821483603;3;1
74dc46d2-749b-11df-b112-00215aee3ebe;Павуки;Пауки;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623082405;3;1
74dc46d3-749b-11df-b112-00215aee3ebe;Павшино;Павшино;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122786301;3;0
74dc46d4-749b-11df-b112-00215aee3ebe;Пагурці;Пагурцы;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524887403;3;1
74dc46d6-749b-11df-b112-00215aee3ebe;Падалки;Падалки;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922987312;3;1
74dc46d7-749b-11df-b112-00215aee3ebe;Падрецеве;Падрецево;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122986404;3;1
74dc46d8-749b-11df-b112-00215aee3ebe;Падусі;Падуси;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324881910;3;1
74dc46d9-749b-11df-b112-00215aee3ebe;Пазиняки;Пазинаки;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625881908;3;1
74dc46da-749b-11df-b112-00215aee3ebe;Пазіївка;Пазиевка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320286206;3;1
74dc46db-749b-11df-b112-00215aee3ebe;Паївка;Паивка;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121681404;3;1
74dc46dc-749b-11df-b112-00215aee3ebe;Пайки;Пайки;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221982707;3;1
74dc46dd-749b-11df-b112-00215aee3ebe;Пакуль;Пакуль;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425586301;3;1
74dc46df-749b-11df-b112-00215aee3ebe;Палагичі;Палагичи;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625686901;3;1
74dc46e0-749b-11df-b112-00215aee3ebe;Паладь-Комарівці;Паладь-Комаровцы;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124883301;3;1
74dc46e2-749b-11df-b112-00215aee3ebe;Паланики;Паланики;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620987804;3;1
74dc46e3-749b-11df-b112-00215aee3ebe;Паланка;Паланка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124386101;3;1
74dc46e4-749b-11df-b112-00215aee3ebe;Паланка;Паланка;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523955110;3;1
e0517ece-db89-11df-9197-00215aee3ebe;Паланки;Паланки;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625882903;3;1
74dc46e5-749b-11df-b112-00215aee3ebe;Паланочка;Паланочка;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123186301;3;1
74dc46e6-749b-11df-b112-00215aee3ebe;Палатки;Палатки;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324285504;3;1
74dc46e7-749b-11df-b112-00215aee3ebe;Палашівка;Палашовка;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125586301;3;1
74dc46e8-749b-11df-b112-00215aee3ebe;Палащенкове;Палащенково;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925680808;3;1
74dc46e9-749b-11df-b112-00215aee3ebe;Паливода;Паливода;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423385903;3;1
74dc46ea-749b-11df-b112-00215aee3ebe;Паликорови;Паликоровы;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;4620384001;3;1
74dc46eb-749b-11df-b112-00215aee3ebe;Паліївка;Палиевка;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925683501;3;1
74dc46ec-749b-11df-b112-00215aee3ebe;Паліївка;Палиевка;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121081703;3;1
74dc46ee-749b-11df-b112-00215aee3ebe;Паліївка;Палиевка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523110107;3;1
74dc46ef-749b-11df-b112-00215aee3ebe;Палійове;Палиево;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523283003;3;1
74dc46f0-749b-11df-b112-00215aee3ebe;Палло;Палло;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124881503;3;1
74dc46f2-749b-11df-b112-00215aee3ebe;Паляничинці;Паляничинцы;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224985101;3;1
74dc46f3-749b-11df-b112-00215aee3ebe;Паляничники;Паляничники;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320810103;3;1
74dc46f4-749b-11df-b112-00215aee3ebe;Пальмирівка;Пальмировка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224584801;3;1
e21f1731-b6d1-11e0-9aaa-003048d2b473;Пальміра;Пальмира;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121582004;3;1
74dc46f5-749b-11df-b112-00215aee3ebe;Пальче;Пальче;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721888003;3;1
74dc46f6-749b-11df-b112-00215aee3ebe;Пальчик;Пальчик;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122285001;3;1
74dc46f7-749b-11df-b112-00215aee3ebe;Пальчики;Пальчики;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420387201;3;1
5cb6170c-749b-11df-b112-00215aee3ebe;Пальчиківка (Кірове);Пальчиковка (Кирово);село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081401;3;1
74dc46f8-749b-11df-b112-00215aee3ebe;Пальчинці;Пальчинцы;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124686001;3;1
74dc46f9-749b-11df-b112-00215aee3ebe;Пальчинці;Пальчинцы;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820980305;3;1
74dc46fa-749b-11df-b112-00215aee3ebe;Пальчишини;Пальчишины;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784810;3;1
74dc46fc-749b-11df-b112-00215aee3ebe;Пам'ятне;Памятное;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420885602;3;1
74dc4701-749b-11df-b112-00215aee3ebe;Панасівка;Панасовка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084605;3;1
74dc4702-749b-11df-b112-00215aee3ebe;Панасівка;Панасовка;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923283801;3;1
74dc4703-749b-11df-b112-00215aee3ebe;Панасівка;Панасовка;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122687601;3;1
74dc4704-749b-11df-b112-00215aee3ebe;Панасівка;Панасовка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124683602;3;1
74dc4705-749b-11df-b112-00215aee3ebe;Панасівка;Панасовка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223280502;3;1
74dc4706-749b-11df-b112-00215aee3ebe;Панасівка;Панасовка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224886007;3;1
74dc4707-749b-11df-b112-00215aee3ebe;Панасівка;Панасовка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823686505;3;1
74dc4708-749b-11df-b112-00215aee3ebe;Панасівка;Панасовка;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323280305;3;1
74dc4709-749b-11df-b112-00215aee3ebe;Панасівка;Панасовка;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823185401;3;1
74dc470a-749b-11df-b112-00215aee3ebe;Панасівка;Панасовка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521486609;3;1
74dc470b-749b-11df-b112-00215aee3ebe;Панасівка;Панасовка;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521680606;3;1
74dc470c-749b-11df-b112-00215aee3ebe;Панасівка;Панасовка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821887902;3;1
74dc470d-749b-11df-b112-00215aee3ebe;Панасівка;Панасовка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323280703;3;1
74dc470e-749b-11df-b112-00215aee3ebe;Паненки;Паненки;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324285703;3;1
74dc470f-749b-11df-b112-00215aee3ebe;Паніванівка;Панивановка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324555112;3;1
74dc4710-749b-11df-b112-00215aee3ebe;Панівка;Пановка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322482803;3;1
74dc4711-749b-11df-b112-00215aee3ebe;Панівці;Пановцы;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120885901;3;1
74dc4712-749b-11df-b112-00215aee3ebe;Панівці;Пановцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822485901;3;1
74dc4713-749b-11df-b112-00215aee3ebe;Панікарча;Паникарча;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222284402;3;1
74dc4714-749b-11df-b112-00215aee3ebe;Панка;Панка;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324584501;3;1
e0517ed1-db89-11df-9197-00215aee3ebe;Панкратове;Панкратово;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820381003;3;1
74dc4717-749b-11df-b112-00215aee3ebe;Пановичі;Пановичи;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124881403;3;1
56bdd227-749b-11df-b112-00215aee3ebe;Панське (Жовтневе);Панське (Жовтневое);село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321885403;3;1
74dc4718-749b-11df-b112-00215aee3ebe;Пантазіївка;Пантазиевка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522284901;3;1
74dc4719-749b-11df-b112-00215aee3ebe;Пантаївка;Пантаевка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3510345600;3;1
74dc471a-749b-11df-b112-00215aee3ebe;Панталиха;Панталиха;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125087605;3;1
74dc471b-749b-11df-b112-00215aee3ebe;Панталія;Панталия;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621685605;3;1
74dc4720-749b-11df-b112-00215aee3ebe;Панфили;Панфилы;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225585201;3;1
74dc4723-749b-11df-b112-00215aee3ebe;Панчеве;Панчево;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523885801;3;1
74dc4724-749b-11df-b112-00215aee3ebe;Панченки;Панченки;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982703;3;1
74dc4725-749b-11df-b112-00215aee3ebe;Панченки;Панченки;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323284604;3;1
74dc4727-749b-11df-b112-00215aee3ebe;Панчишине;Панчишино;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523781302;3;1
74dc4728-749b-11df-b112-00215aee3ebe;Панютине;Панютино;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6311045400;3;1
74dc472c-749b-11df-b112-00215aee3ebe;Паньківці;Паньковцы;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620355401;3;1
74dc472d-749b-11df-b112-00215aee3ebe;Паньківці;Паньковцы;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820355604;3;1
74dc472e-749b-11df-b112-00215aee3ebe;Паньківці;Паньковцы;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824483002;3;1
74dc472f-749b-11df-b112-00215aee3ebe;Панькова;Панькова;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620386606;3;1
50c59509-749b-11df-b112-00215aee3ebe;Панькове (Дніпрельстан);Паньковое (Днепрельстан);село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225081007;3;1
74dc4730-749b-11df-b112-00215aee3ebe;Папірна;Папирня;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821289003;3;1
74dc4731-749b-11df-b112-00215aee3ebe;Папірня;Папирня;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925686005;3;1
74dc4732-749b-11df-b112-00215aee3ebe;Папірня;Папирня;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125080502;3;1
74dc4733-749b-11df-b112-00215aee3ebe;Папірня;Папирня;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784406;3;1
74dc4734-749b-11df-b112-00215aee3ebe;Папірня;Папирня;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621886404;3;1
74dc4735-749b-11df-b112-00215aee3ebe;Папірня;Папирня;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824280604;3;1
74dc4736-749b-11df-b112-00215aee3ebe;Папірня;Папирня;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425586306;3;1
74dc4737-749b-11df-b112-00215aee3ebe;Папкине;Папкино;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922681705;3;1
74dc4738-749b-11df-b112-00215aee3ebe;Паплинці;Паплинцы;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824485001;3;1
74dc4739-749b-11df-b112-00215aee3ebe;Папужинці;Папужинцы;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124088201;3;1
74dc473a-749b-11df-b112-00215aee3ebe;Параївка;Параевка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822483002;3;1
74dc473b-749b-11df-b112-00215aee3ebe;Паранине;Паранино;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821783602;3;1
74dc473e-749b-11df-b112-00215aee3ebe;Парасковія;Парасковия;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324282002;3;1
74dc473f-749b-11df-b112-00215aee3ebe;Парасковія;Парасковия;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323184001;3;1
74dc4740-749b-11df-b112-00215aee3ebe;Парастовське;Парастовское;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422487906;3;1
74dc4741-749b-11df-b112-00215aee3ebe;Парафіївка;Парафиевка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421755700;3;1
74dc4742-749b-11df-b112-00215aee3ebe;Паращина;Паращина;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223187706;3;1
74dc4743-749b-11df-b112-00215aee3ebe;Паращине Поле;Паращино Поле;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522587403;3;1
74dc4744-749b-11df-b112-00215aee3ebe;Паридуби;Паридубы;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725085003;3;1
74dc474b-749b-11df-b112-00215aee3ebe;Паризьке;Парижское;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324587005;3;1
74dc4746-749b-11df-b112-00215aee3ebe;Парипси;Парипсы;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824785101;3;1
74dc4747-749b-11df-b112-00215aee3ebe;Паристівка;Паристовка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420881604;3;1
74dc4748-749b-11df-b112-00215aee3ebe;Паришеве;Парышево;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520385805;3;1
74dc4749-749b-11df-b112-00215aee3ebe;Паришків;Парышков;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220285701;3;1
74dc474a-749b-11df-b112-00215aee3ebe;Парище;Парище;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624084601;3;1
74dc474c-749b-11df-b112-00215aee3ebe;Паріївка;Париевка;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523480602;3;1
74dc474d-749b-11df-b112-00215aee3ebe;Паріївка;Париевка;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521285703;3;1
74dc474e-749b-11df-b112-00215aee3ebe;Парканівка;Паркановка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125283803;3;1
74dc474f-749b-11df-b112-00215aee3ebe;Парканці;Парканцы;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123985207;3;1
74dc4751-749b-11df-b112-00215aee3ebe;Паркулина;Паркулина;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323555101;3;1
74dc4752-749b-11df-b112-00215aee3ebe;Парне;Парное;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224888205;3;1
74dc4754-749b-11df-b112-00215aee3ebe;Парницьке;Парницкое;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322686203;3;1
74dc4755-749b-11df-b112-00215aee3ebe;Парнувате;Парноватое;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321083505;3;1
74dc4756-749b-11df-b112-00215aee3ebe;Парня;Парня;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;;3;1
74dc4757-749b-11df-b112-00215aee3ebe;Парня;Парня;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;;3;1
74dc4758-749b-11df-b112-00215aee3ebe;Парпурівці;Парпуровцы;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520684903;3;0
74dc4759-749b-11df-b112-00215aee3ebe;Парська;Парская;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;;3;1
74dc475b-749b-11df-b112-00215aee3ebe;Партизани;Партизаны;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224884905;3;1
74dc4760-749b-11df-b112-00215aee3ebe;Партизанське;Партизанское;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923886302;3;1
74dc4761-749b-11df-b112-00215aee3ebe;Партизанське;Партизанское;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155317;3;1
74dc4762-749b-11df-b112-00215aee3ebe;Партизанське;Партизанское;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221486601;2;1
74dc4763-749b-11df-b112-00215aee3ebe;Партизанське;Партизанское;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823383701;3;1
74dc4766-749b-11df-b112-00215aee3ebe;Партизанське;Партизанское;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824085803;3;1
74dc4767-749b-11df-b112-00215aee3ebe;Партизанське;Партизанское;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621884305;3;1
33ec0014-ddd5-11df-9197-00215aee3ebe;Партизанське;Партизанское;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520483609;3;1
74dc4768-749b-11df-b112-00215aee3ebe;Партинці;Партинцы;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824289104;3;1
74dc4769-749b-11df-b112-00215aee3ebe;Парутине;Парутино;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825182208;3;1
74dc476a-749b-11df-b112-00215aee3ebe;Парфили;Парфилы;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922986504;3;1
74dc476b-749b-11df-b112-00215aee3ebe;Пархимів;Пархимов;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422087401;3;1
74dc476d-749b-11df-b112-00215aee3ebe;Пархоменкове;Пархоменково;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720510403;3;1
74dc476e-749b-11df-b112-00215aee3ebe;Пархомівка;Пархомовка;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925080805;3;1
74dc476f-749b-11df-b112-00215aee3ebe;Пархомівка;Пархомовка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824583003;3;1
74dc4770-749b-11df-b112-00215aee3ebe;Пархомівка;Пархомовка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323585501;3;1
74dc4771-749b-11df-b112-00215aee3ebe;Пархомівка;Пархомовка;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221685601;2;1
74dc4772-749b-11df-b112-00215aee3ebe;Пархомівка;Пархомовка;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521210113;3;1
74dc4773-749b-11df-b112-00215aee3ebe;Пархомівці;Пархомовцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825085401;3;1
74dc4774-749b-11df-b112-00215aee3ebe;Пасат;Пасат;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290020;3;1
74dc4775-749b-11df-b112-00215aee3ebe;Пасинки;Пасынки;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525385801;3;1
74dc4776-749b-11df-b112-00215aee3ebe;Пасицели;Пасицелы;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120685701;3;1
74dc4777-749b-11df-b112-00215aee3ebe;Пасицели;Пасицелы;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120284809;3;1
74dc4778-749b-11df-b112-00215aee3ebe;Пасіка;Пасека;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922681205;3;1
74dc4779-749b-11df-b112-00215aee3ebe;Пасіка;Пасека;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322887502;3;1
74dc477a-749b-11df-b112-00215aee3ebe;Пасіка;Пасека;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124086902;3;0
74dc477b-749b-11df-b112-00215aee3ebe;Пасіки;Пасеки;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920386306;3;1
74dc477c-749b-11df-b112-00215aee3ebe;Пасіки;Пасеки;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321781505;3;1
74dc477d-749b-11df-b112-00215aee3ebe;Пасіки;Пасеки;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625880805;3;1
74dc477e-749b-11df-b112-00215aee3ebe;Пасіки-Зубрицькі;Пасеки-Зубрицкие;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623685101;1;0
74dc477f-749b-11df-b112-00215aee3ebe;Пасічна;Пасечная;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624085001;3;1
74dc4780-749b-11df-b112-00215aee3ebe;Пасічна;Пасечная;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220255102;3;1
74dc4781-749b-11df-b112-00215aee3ebe;Пасічна;Пасечная;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824485501;3;1
74dc4782-749b-11df-b112-00215aee3ebe;Пасічна;Пасечная;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825884801;3;1
74dc4783-749b-11df-b112-00215aee3ebe;Пасічне;Пасечное;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323486803;3;1
74dc4784-749b-11df-b112-00215aee3ebe;Пасічне;Пасечное;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321288505;3;1
74dc4785-749b-11df-b112-00215aee3ebe;Пасічне;Пасечное;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124583208;3;1
382f7008-44af-11e9-80dd-1c98ec135261;Пасічне;Пасичное;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523184002;3;1
74dc4788-749b-11df-b112-00215aee3ebe;Пасічники;Пасечники;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324284207;3;1
74dc4789-749b-11df-b112-00215aee3ebe;Пасківка;Пасковка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324084009;3;1
74dc478a-749b-11df-b112-00215aee3ebe;Пасківщина;Пасковщина;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221984901;3;1
74dc478c-749b-11df-b112-00215aee3ebe;Пастирське;Пастырское;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123786001;3;1
74dc478d-749b-11df-b112-00215aee3ebe;Пастілки;Пастилки;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123281002;3;1
74dc478e-749b-11df-b112-00215aee3ebe;Пастуше;Пастушье;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125586401;3;1
74dc478f-749b-11df-b112-00215aee3ebe;Паськів;Паськов;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122583704;3;1
74dc4790-749b-11df-b112-00215aee3ebe;Паськівка;Паськовка;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324283105;3;1
74dc4792-749b-11df-b112-00215aee3ebe;Пасьовини;Пасевины;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920986404;3;1
74dc4794-749b-11df-b112-00215aee3ebe;Патлаївка;Патлаивка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324082405;3;0
74dc4795-749b-11df-b112-00215aee3ebe;Патринці;Патринцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822483603;3;1
74dc4797-749b-11df-b112-00215aee3ebe;Патріотівка;Патриотовка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982408;3;1
74dc4798-749b-11df-b112-00215aee3ebe;Патюти;Патюты;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422087701;3;1
74dc479a-749b-11df-b112-00215aee3ebe;Пахар;Пахарь;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222982811;3;1
74dc479c-749b-11df-b112-00215aee3ebe;Пахарівка;Пахаревка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520384904;3;1
74dc479d-749b-11df-b112-00215aee3ebe;Пахиня;Пахиня;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123884305;3;1
74dc479f-749b-11df-b112-00215aee3ebe;Пахутинці;Пахутинцы;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820985301;3;1
74dc47a0-749b-11df-b112-00215aee3ebe;Пацали;Пацалы;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325183805;3;1
74dc47a1-749b-11df-b112-00215aee3ebe;Пациків;Пациков;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622055301;3;1
74dc47a2-749b-11df-b112-00215aee3ebe;Пацканьово;Пацканево;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124885101;3;1
74dc47a3-749b-11df-b112-00215aee3ebe;Пацьковичі;Пацьковичи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625182605;3;1
74dc47a4-749b-11df-b112-00215aee3ebe;Пашева;Пашевая;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621484012;3;1
74dc47a5-749b-11df-b112-00215aee3ebe;Пашена Балка;Пашена Балка;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221482006;3;1
74dc47a6-749b-11df-b112-00215aee3ebe;Пашенівка;Пашеновка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322085011;3;1
74dc47a7-749b-11df-b112-00215aee3ebe;Пашкине;Пашкино;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922986904;3;1
74dc47a8-749b-11df-b112-00215aee3ebe;Пашків;Пашков;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420380504;3;1
74dc47a9-749b-11df-b112-00215aee3ebe;Пашківка;Пашковка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084001;3;1
74dc47aa-749b-11df-b112-00215aee3ebe;Пашківка;Пашковка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222786501;3;1
74dc47ab-749b-11df-b112-00215aee3ebe;Пашківка;Пашковка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423380405;3;1
74dc47ac-749b-11df-b112-00215aee3ebe;Пашківці;Пашковцы;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325086001;3;1
74dc47ad-749b-11df-b112-00215aee3ebe;Пашківці;Пашковцы;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121586502;3;1
74dc47ae-749b-11df-b112-00215aee3ebe;Пашківці;Пашковцы;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824286401;3;1
74dc47af-749b-11df-b112-00215aee3ebe;Пашківці;Пашковцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825085701;3;1
74dc47b0-749b-11df-b112-00215aee3ebe;Пашкове;Пашково;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320483705;3;1
74dc47b1-749b-11df-b112-00215aee3ebe;Пашуки;Пашуки;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823988803;3;1
74dc47b2-749b-11df-b112-00215aee3ebe;Пашуки;Пашуки;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825582303;3;1
74dc47b3-749b-11df-b112-00215aee3ebe;Пашуки;Пашуки;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621287120;3;1
74dc47b4-749b-11df-b112-00215aee3ebe;Пашутинці;Пашутинцы;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822781703;3;1
74dc47b5-749b-11df-b112-00215aee3ebe;Пащенівка;Пащеновка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322482805;3;1
74dc47b6-749b-11df-b112-00215aee3ebe;Пащенівка;Пащеновка;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323281006;3;1
74dc47b7-749b-11df-b112-00215aee3ebe;Пащенки;Пащенки;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324283101;3;1
74dc47b8-749b-11df-b112-00215aee3ebe;Пащенкове;Пащенково;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920655309;3;1
74dc47b9-749b-11df-b112-00215aee3ebe;Певжа;Певжа;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623886101;3;1
74dc47ba-749b-11df-b112-00215aee3ebe;Певна;Певна;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825882802;3;1
74dc47bb-749b-11df-b112-00215aee3ebe;Педашка Перша;Педашка Первая;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322283502;3;1
74dc47bc-749b-11df-b112-00215aee3ebe;Пединівка;Пединовка;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121285601;3;1
74dc47bd-749b-11df-b112-00215aee3ebe;Пединка;Педынка;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823185701;3;1
74dc47be-749b-11df-b112-00215aee3ebe;Педоричі;Педоричи;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320481102;3;1
74dc47bf-749b-11df-b112-00215aee3ebe;Педоси;Педосы;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524884505;3;1
74dc47c0-749b-11df-b112-00215aee3ebe;Педоси;Педосы;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523484101;3;1
74dc47c1-749b-11df-b112-00215aee3ebe;Педоси;Педосы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825086001;3;1
74dc47c2-749b-11df-b112-00215aee3ebe;Пекалів;Пекалев;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623889406;3;1
74dc47c3-749b-11df-b112-00215aee3ebe;Пекарі;Пекари;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922086401;3;1
74dc47c4-749b-11df-b112-00215aee3ebe;Пекарі;Пекари;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122085801;3;1
74dc47c5-749b-11df-b112-00215aee3ebe;Пекарів;Пекарев;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424986501;3;1
74dc47c6-749b-11df-b112-00215aee3ebe;Пекарщина;Пекарщина;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825686801;3;1
74dc47c7-749b-11df-b112-00215aee3ebe;Пекурівка;Пекуровка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421487001;3;1
74dc46de-749b-11df-b112-00215aee3ebe;Пелагеївка;Пелагеевка;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755142;3;1
74dc47c8-749b-11df-b112-00215aee3ebe;Пелагіївка;Пелагеевка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824581509;3;1
74dc47cb-749b-11df-b112-00215aee3ebe;Пеленківщина;Пеленковщина;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321381605;3;1
74dc47cc-749b-11df-b112-00215aee3ebe;Пелехівщина;Пелеховщина;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320680704;3;1
74dc47cd-749b-11df-b112-00215aee3ebe;Пелинівка;Пелиновка;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524982303;3;1
74dc47ce-749b-11df-b112-00215aee3ebe;Пелюхівка;Пелюховка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421781604;3;1
74dc47cf-749b-11df-b112-00215aee3ebe;Пеніжкове;Пенежково;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124685001;3;1
74dc47d0-749b-11df-b112-00215aee3ebe;Пенязівка;Пенязовка;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422755101;3;1
74dc47d1-749b-11df-b112-00215aee3ebe;Пеняки;Пеняки;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620384401;3;1
74dc47d2-749b-11df-b112-00215aee3ebe;Пеньки;Пеньки;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824286701;3;1
74dc47d3-749b-11df-b112-00215aee3ebe;Пенькине;Пенькино;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523880403;3;1
74dc47d4-749b-11df-b112-00215aee3ebe;Пеньків;Пеньков;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623486001;3;1
74dc47d5-749b-11df-b112-00215aee3ebe;Пеньківка;Пеньковка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522486001;3;1
74dc47d6-749b-11df-b112-00215aee3ebe;Пеньківка;Пеньковка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525386001;3;1
74dc47d7-749b-11df-b112-00215aee3ebe;Пеньківка;Пеньковка;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523955111;3;1
7ad9551e-749b-11df-b112-00215aee3ebe;Пеньківка (Свердлівка);Пеньковка (Свердловка);село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522282604;3;1
74dc47d8-749b-11df-b112-00215aee3ebe;Пеньківці;Пеньковцы;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124680703;3;1
74dc47d9-749b-11df-b112-00215aee3ebe;Пенькове;Пеньково;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520885604;3;1
74dc4817-749b-11df-b112-00215aee3ebe;Перв' ятичі;Первятичи;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624884401;3;1
74dc47da-749b-11df-b112-00215aee3ebe;Первенець;Первенец;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821783406;3;1
74dc47db-749b-11df-b112-00215aee3ebe;Первозванівка;Первозвановка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325482603;3;1
74dc47dc-749b-11df-b112-00215aee3ebe;Первозванівка;Первозвановка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055116;3;1
74dc47dd-749b-11df-b112-00215aee3ebe;Первозванівка;Первозвановка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522586601;3;1
74dc47df-749b-11df-b112-00215aee3ebe;Первомайськ;Первомайск;місто;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4810400000;2;0
74dc47e2-749b-11df-b112-00215aee3ebe;Первомайськ;Первомайск;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124987505;3;1
74dc47e3-749b-11df-b112-00215aee3ebe;Первомайськ;Первомайск;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523185202;3;1
74dc47e4-749b-11df-b112-00215aee3ebe;Первомайське;Первомайское;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323083804;3;1
74dc47e5-749b-11df-b112-00215aee3ebe;Первомайське;Первомайское;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921583701;3;1
74dc47e6-749b-11df-b112-00215aee3ebe;Первомайське;Первомайское;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;;3;1
74dc47e7-749b-11df-b112-00215aee3ebe;Первомайське;Первомайское;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955117;3;1
74dc47e8-749b-11df-b112-00215aee3ebe;Первомайське;Первомайское;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222082009;3;1
74dc47e9-749b-11df-b112-00215aee3ebe;Первомайське;Первомайское;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224884006;3;1
74dc47ea-749b-11df-b112-00215aee3ebe;Первомайське;Первомайское;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755133;3;1
74dc47eb-749b-11df-b112-00215aee3ebe;Первомайське;Первомайское;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823355700;3;1
74dc47ec-749b-11df-b112-00215aee3ebe;Первомайське;Первомайское;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325755105;3;1
74dc47ed-749b-11df-b112-00215aee3ebe;Первомайське;Первомайское;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320284003;3;1
74dc47ee-749b-11df-b112-00215aee3ebe;Первомайське;Первомайское;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125283007;3;1
74dc47ef-749b-11df-b112-00215aee3ebe;Первомайське;Первомайское;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520955403;3;1
74dc47f0-749b-11df-b112-00215aee3ebe;Первомайське;Первомайское;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520381405;3;1
74dc47fd-749b-11df-b112-00215aee3ebe;Первомайське;Первомайское;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521682409;3;1
74dc47fe-749b-11df-b112-00215aee3ebe;Первомайське;Первомайское;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521281606;3;1
74dc47ff-749b-11df-b112-00215aee3ebe;Первомайське;Первомайское;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523110108;3;1
74dc4800-749b-11df-b112-00215aee3ebe;Первомайський;Первомайский;місто;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6311500000;2;0
74dc4802-749b-11df-b112-00215aee3ebe;Первухинка;Первухинка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320855302;3;1
74dc4803-749b-11df-b112-00215aee3ebe;Перга;Перга;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824487602;3;1
74dc4804-749b-11df-b112-00215aee3ebe;Перебиківці;Перебыковцы;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325086401;3;1
74dc4805-749b-11df-b112-00215aee3ebe;Переброди;Переброды;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287707;3;1
74dc4806-749b-11df-b112-00215aee3ebe;Переброди;Переброды;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621886401;3;1
74dc4808-749b-11df-b112-00215aee3ebe;Перебудова;Перебудова;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423387101;3;1
74dc4809-749b-11df-b112-00215aee3ebe;Перевали;Перевалы;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725555106;3;1
74dc480d-749b-11df-b112-00215aee3ebe;Перевальське;Перевальское;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755134;3;1
74dc480e-749b-11df-b112-00215aee3ebe;Перевередів;Перевередов;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623880302;3;1
74dc480f-749b-11df-b112-00215aee3ebe;Перевіз;Перевоз;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221482202;3;1
74dc4810-749b-11df-b112-00215aee3ebe;Перевіз;Перевоз;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320484106;3;1
74dc4811-749b-11df-b112-00215aee3ebe;Перевісся;Перевесье;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725582906;3;1
74dc4812-749b-11df-b112-00215aee3ebe;Переводчикове;Переводчиково;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755143;3;1
74dc4813-749b-11df-b112-00215aee3ebe;Перевозець;Перевозец;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622885101;3;1
74dc4814-749b-11df-b112-00215aee3ebe;Переволока;Переволока;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121284601;3;1
74dc4815-749b-11df-b112-00215aee3ebe;Переволочна;Переволочная;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620684601;3;1
74dc4816-749b-11df-b112-00215aee3ebe;Переволочна;Переволочная;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424186401;3;1
74dc4818-749b-11df-b112-00215aee3ebe;Перегінка;Перегонка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825886403;3;1
74dc4819-749b-11df-b112-00215aee3ebe;Перегінське;Перегинское;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2624855800;3;0
74dc481a-749b-11df-b112-00215aee3ebe;Перегноїв;Перегноев;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621884801;3;1
74dc481b-749b-11df-b112-00215aee3ebe;Перегонівка;Перегоновка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321883204;3;1
74dc481c-749b-11df-b112-00215aee3ebe;Перегонівка;Перегоновка;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223186401;3;1
74dc481d-749b-11df-b112-00215aee3ebe;Перегонівка;Перегоновка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521486001;3;1
74dc481e-749b-11df-b112-00215aee3ebe;Перегуди;Перегуды;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220886706;3;1
74dc481f-749b-11df-b112-00215aee3ebe;Передбір'я;Передборье;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625855604;3;1
74dc4821-749b-11df-b112-00215aee3ebe;Передеріївка;Передериевка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;;3;1
74dc4822-749b-11df-b112-00215aee3ebe;Передівання;Передиванье;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621680503;3;1
74dc4823-749b-11df-b112-00215aee3ebe;Переділ;Передел;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424456404;3;1
74dc4824-749b-11df-b112-00215aee3ebe;Переділ;Передел;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422485504;3;1
74dc4825-749b-11df-b112-00215aee3ebe;Переділи;Переделы;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624686707;3;1
74dc4826-749b-11df-b112-00215aee3ebe;Передільниця;Передельница;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625184105;3;1
74dc4828-749b-11df-b112-00215aee3ebe;Передмірка;Передмирка;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123884901;3;1
74dc482a-749b-11df-b112-00215aee3ebe;Передмістя;Предместье;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121285601;3;1
74dc482b-749b-11df-b112-00215aee3ebe;Передмістя;Предместье;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620988004;3;1
74dc4831-749b-11df-b112-00215aee3ebe;Перейма;Перейма;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290022;3;1
74dc4832-749b-11df-b112-00215aee3ebe;Перекалі;Перекали;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621483512;3;1
74dc4833-749b-11df-b112-00215aee3ebe;Перекалки;Перекалки;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622185603;3;1
74dc4834-749b-11df-b112-00215aee3ebe;Перекалля;Перекалье;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622285301;3;1
74dc4835-749b-11df-b112-00215aee3ebe;Перекіп;Перекоп;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321286001;3;1
74dc4836-749b-11df-b112-00215aee3ebe;Перекір;Перекор;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923583409;3;1
74dc4839-749b-11df-b112-00215aee3ebe;Перекопівка;Перекоповка;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320284304;3;1
74dc483a-749b-11df-b112-00215aee3ebe;Перекопівка;Перекоповка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187001;3;1
74dc483b-749b-11df-b112-00215aee3ebe;Перекора;Перекора;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824480503;3;1
74dc483c-749b-11df-b112-00215aee3ebe;Перекоринці;Перекоринцы;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522885903;3;1
74dc483d-749b-11df-b112-00215aee3ebe;Перекоси;Перекосы;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622887802;3;1
74dc483e-749b-11df-b112-00215aee3ebe;Перелисянка;Перелисянка;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623487403;3;1
74dc483f-749b-11df-b112-00215aee3ebe;Переліски;Перелески;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321381606;3;1
74dc4840-749b-11df-b112-00215aee3ebe;Переліски;Перелески;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982006;3;1
74dc4841-749b-11df-b112-00215aee3ebe;Переліски;Перелески;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620385313;3;1
74dc4842-749b-11df-b112-00215aee3ebe;Переліски;Перелески;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520255306;3;1
74dc4843-749b-11df-b112-00215aee3ebe;Перелісок;Перелесок;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824055609;3;1
74dc4844-749b-11df-b112-00215aee3ebe;Перелісянка;Перелесянка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824055610;3;1
74dc4845-749b-11df-b112-00215aee3ebe;Перелуг;Перелуг;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920383605;3;1
74dc4846-749b-11df-b112-00215aee3ebe;Перелюб;Перелюб;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422486501;3;1
74dc4847-749b-11df-b112-00215aee3ebe;Перельоти;Перелеты;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290023;3;1
74dc4848-749b-11df-b112-00215aee3ebe;Перемивки;Перемывки;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622788503;3;1
74dc4849-749b-11df-b112-00215aee3ebe;Перемилів;Перемилов;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121685701;3;1
74dc484a-749b-11df-b112-00215aee3ebe;Перемилівка;Перемиловка;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623886401;3;1
74dc484b-749b-11df-b112-00215aee3ebe;Перемиль;Перемиль;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720885401;3;1
74dc484c-749b-11df-b112-00215aee3ebe;Перемисловичі;Перемысловичи;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624882104;3;1
74dc484d-749b-11df-b112-00215aee3ebe;Перемишель;Перемышель;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823987705;3;1
74dc484e-749b-11df-b112-00215aee3ebe;Перемишляни;Перемышляны;місто;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;7d1766f6-b6ff-11eb-80d4-000c29800ae7;4623310100;3;0
74dc484f-749b-11df-b112-00215aee3ebe;Переміжжя;Переможье;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825087604;3;1
74dc4850-749b-11df-b112-00215aee3ebe;Перемога;Перемога;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924480404;3;1
74dc4851-749b-11df-b112-00215aee3ebe;Перемога;Перемога;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922681206;3;1
74dc4852-749b-11df-b112-00215aee3ebe;Перемога;Перемога;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921584001;3;1
74dc4853-749b-11df-b112-00215aee3ebe;Перемога;Перемога;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920610103;3;1
74dc4855-749b-11df-b112-00215aee3ebe;Перемога;Перемога;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920388703;3;1
74dc4856-749b-11df-b112-00215aee3ebe;Перемога;Перемога;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221411001;3;1
74dc4857-749b-11df-b112-00215aee3ebe;Перемога;Перемога;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820683005;3;1
74dc4858-749b-11df-b112-00215aee3ebe;Перемога;Перемога;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322684004;3;1
74dc4859-749b-11df-b112-00215aee3ebe;Перемога;Перемога;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325184502;3;1
74dc485a-749b-11df-b112-00215aee3ebe;Перемога;Перемога;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323985001;3;1
74dc485b-749b-11df-b112-00215aee3ebe;Перемога;Перемога;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123555103;3;1
74dc485c-749b-11df-b112-00215aee3ebe;Перемога;Перемога;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124786601;3;1
74dc4860-749b-11df-b112-00215aee3ebe;Перемога;Перемога;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221255605;1;0
74dc4861-749b-11df-b112-00215aee3ebe;Перемога;Перемога;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220286101;3;1
74dc4862-749b-11df-b112-00215aee3ebe;Перемога;Перемога;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824084004;3;1
74dc4863-749b-11df-b112-00215aee3ebe;Перемога;Перемога;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825681202;3;1
74dc4864-749b-11df-b112-00215aee3ebe;Перемога;Перемога;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420384502;3;1
74dc4865-749b-11df-b112-00215aee3ebe;Перемога;Перемога;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423387401;3;1
74dc4866-749b-11df-b112-00215aee3ebe;Перемога;Перемога;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521485803;3;1
74dc4867-749b-11df-b112-00215aee3ebe;Перемога;Перемога;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521785306;3;1
74dc4995-749b-11df-b112-00215aee3ebe;Перемога (Петрівське);Победа (Петровское);село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420384502;3;1
87162628-749b-11df-b112-00215aee3ebe;Перемога (Червона Перемога);Победа (Червоная Перемога);село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322281503;3;1
74dc4868-749b-11df-b112-00215aee3ebe;Переможець;Переможец;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221289002;3;1
74dc4869-749b-11df-b112-00215aee3ebe;Переможинці;Переможинцы;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122586303;3;1
74dc486a-749b-11df-b112-00215aee3ebe;Переможне;Переможное;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122782606;3;1
74dc486e-749b-11df-b112-00215aee3ebe;Переможне;Переможное;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620985901;3;1
74dc4871-749b-11df-b112-00215aee3ebe;Переможне;Переможное;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525887802;3;1
74dc4872-749b-11df-b112-00215aee3ebe;Переморівка;Переморовка;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125880303;3;1
74dc4873-749b-11df-b112-00215aee3ebe;Перенівка;Переновка;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624484304;3;1
74dc4874-749b-11df-b112-00215aee3ebe;Перенятин;Перенятин;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625880402;3;1
74dc4875-749b-11df-b112-00215aee3ebe;Переорки;Переорки;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520684006;3;1
74dc4876-749b-11df-b112-00215aee3ebe;Перепелицівка;Перепелицевка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321282507;3;1
74dc4877-749b-11df-b112-00215aee3ebe;Перепеличчя;Перепеличье;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523088804;3;1
74dc4878-749b-11df-b112-00215aee3ebe;Перепеляче;Перепелячье;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220787710;3;1
74dc4879-749b-11df-b112-00215aee3ebe;Перепельники;Перепельники;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122687801;3;1
74dc487a-749b-11df-b112-00215aee3ebe;Перепис;Перепись;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421487201;3;1
74dc487c-749b-11df-b112-00215aee3ebe;Перепільчинці;Перепильчинцы;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525386101;3;1
74dc487d-749b-11df-b112-00215aee3ebe;Перепростиня;Перепростыня;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621285104;3;1
74dc487e-749b-11df-b112-00215aee3ebe;Перервинці;Перервинцы;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120687901;3;1
74dc487f-749b-11df-b112-00215aee3ebe;Перерив;Перерыв;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623284202;3;1
74dc4880-749b-11df-b112-00215aee3ebe;Перерісль;Переросль;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624085301;3;1
74dc4881-749b-11df-b112-00215aee3ebe;Переросле;Перерослое;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820387001;3;1
74dc4882-749b-11df-b112-00215aee3ebe;Переросля;Переросля;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621685209;3;1
74dc4883-749b-11df-b112-00215aee3ebe;Перерост;Перерост;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421486408;3;1
74dc4884-749b-11df-b112-00215aee3ebe;Пересадівка;Пересадовка;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823383901;3;1
74dc4885-749b-11df-b112-00215aee3ebe;Пересаж;Пересаж;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424485204;3;1
74dc4886-749b-11df-b112-00215aee3ebe;Переселення;Переселение;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222286001;3;1
74dc4887-749b-11df-b112-00215aee3ebe;Переселенці;Переселенцы;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123583003;3;1
74dc4888-749b-11df-b112-00215aee3ebe;Пересипки;Пересыпки;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923882807;3;1
74dc4889-749b-11df-b112-00215aee3ebe;Пересіка;Пересека;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725584202;3;1
74dc488a-749b-11df-b112-00215aee3ebe;Пересічне;Пересечное;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322056500;3;1
74dc488b-749b-11df-b112-00215aee3ebe;Переславичі;Переславичи;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721183811;3;1
74dc488c-749b-11df-b112-00215aee3ebe;Пересопниця;Пересопница;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624682707;3;1
74dc488d-749b-11df-b112-00215aee3ebe;Переспа;Переспа;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724585001;3;1
74dc488e-749b-11df-b112-00215aee3ebe;Переспа;Переспа;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624884801;3;1
74dc4892-749b-11df-b112-00215aee3ebe;Перетік;Переток;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823183203;3;1
74dc4893-749b-11df-b112-00215aee3ebe;Перетічки;Перетички;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923585705;3;1
5cb6170e-749b-11df-b112-00215aee3ebe;Перетічки (Кірове);Перетички (Кирово);село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923585705;3;1
74dc4894-749b-11df-b112-00215aee3ebe;Перетоки;Перетоки;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623483005;3;1
74dc488f-749b-11df-b112-00215aee3ebe;Перетоки;Перетоки;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624886802;3;1
74dc4895-749b-11df-b112-00215aee3ebe;Переходи;Переходы;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125582203;3;1
74dc4896-749b-11df-b112-00215aee3ebe;Переходичі;Переходичи;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625082202;3;1
74dc4897-749b-11df-b112-00215aee3ebe;Переходівка;Переходовка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423388502;3;1
74dc4898-749b-11df-b112-00215aee3ebe;Перехресне;Перекрестное;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620886001;3;1
74dc4899-749b-11df-b112-00215aee3ebe;Перехресний;Перекрестный;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121585003;3;1
74dc489a-749b-11df-b112-00215aee3ebe;Перехрестівка;Перекрестовка;село;9b486f64-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786908;3;1
74dc489b-749b-11df-b112-00215aee3ebe;Перехрестівка;Перекрестовка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187101;3;1
74dc489c-749b-11df-b112-00215aee3ebe;Перехрестівка;Перекрестовка;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525281804;3;1
74dc489d-749b-11df-b112-00215aee3ebe;Перехрестове;Перекрестово;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125284201;3;1
74dc489e-749b-11df-b112-00215aee3ebe;Перехрестове Перше;Перекрестово Первое;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125284207;3;1
74dc489f-749b-11df-b112-00215aee3ebe;Перехрестя;Перекрестье;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121283901;3;1
74dc48a0-749b-11df-b112-00215aee3ebe;Перечин;Перечин;місто;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123210100;2;1
74dc48a1-749b-11df-b112-00215aee3ebe;Перешори;Перешоры;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122984860;3;1
74dc48a2-749b-11df-b112-00215aee3ebe;Перешпа;Перешпа;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725785003;3;1
74dc48a3-749b-11df-b112-00215aee3ebe;Перещепине;Перещепино;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223210500;2;0
74dc48a4-749b-11df-b112-00215aee3ebe;Переямпіль;Переямполь;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521181302;3;1
74dc48a6-749b-11df-b112-00215aee3ebe;Переяслав;Переяслав;місто;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3211000000;2;0
74dc48a5-749b-11df-b112-00215aee3ebe;Переяславське;Переяславское;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223385001;3;1
74dc48a7-749b-11df-b112-00215aee3ebe;Переяслівка;Переясловка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423387701;3;1
74dc48a9-749b-11df-b112-00215aee3ebe;Перківці;Перковцы;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322088001;3;1
74dc48aa-749b-11df-b112-00215aee3ebe;Перковичі;Перковичи;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722180404;3;1
74dc48ab-749b-11df-b112-00215aee3ebe;Перлівці;Перловцы;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621286201;3;1
74dc48ac-749b-11df-b112-00215aee3ebe;Перлявка;Перлявка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822087208;3;1
b36f1a3a-db87-11df-9197-00215aee3ebe;Перовське;Перовское;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322684404;3;1
74dc48ae-749b-11df-b112-00215aee3ebe;Перун;Перун;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321581303;3;1
74dc48af-749b-11df-b112-00215aee3ebe;Перчунове;Перчуново;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521784801;3;1
74dc48b0-749b-11df-b112-00215aee3ebe;Перше Травня;Перше Травня;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921583706;3;1
74dc48b1-749b-11df-b112-00215aee3ebe;Перше Травня;Перше Травня;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920685703;3;1
74dc48b2-749b-11df-b112-00215aee3ebe;Перше Травня;Перше Травня;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924783407;3;1
74dc48b3-749b-11df-b112-00215aee3ebe;Перше Травня;Перше Травня;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221010102;3;1
74dc48b4-749b-11df-b112-00215aee3ebe;Перше Травня;Перше Травня;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221484002;2;1
74dc48b5-749b-11df-b112-00215aee3ebe;Перше Травня;Перше Травня;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225081005;3;1
74dc48b6-749b-11df-b112-00215aee3ebe;Перше Травня;Перше Травня;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282519;3;1
74dc48b7-749b-11df-b112-00215aee3ebe;Перше Травня;Перше Травня;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220310113;3;1
74dc48b8-749b-11df-b112-00215aee3ebe;Перше Травня;Перше Травня;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125283205;3;1
74dc48b9-749b-11df-b112-00215aee3ebe;Перше Травня;Перше Травня;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123980708;3;1
74dc48bc-749b-11df-b112-00215aee3ebe;Перше Травня;Перше Травня;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221288002;3;1
74dc48bd-749b-11df-b112-00215aee3ebe;Перше Травня;Перше Травня;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223385303;3;1
74dc48be-749b-11df-b112-00215aee3ebe;Перше Травня;Перше Травня;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223186601;3;1
74dc48bf-749b-11df-b112-00215aee3ebe;Перше Травня;Перше Травня;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;;3;1
74dc48c0-749b-11df-b112-00215aee3ebe;Перше Травня;Перше Травня;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224610110;3;1
74dc48c1-749b-11df-b112-00215aee3ebe;Перше Травня;Перше Травня;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421484404;3;1
74dc48c2-749b-11df-b112-00215aee3ebe;Перше Травня;Перше Травня;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420389004;3;1
74dc48c3-749b-11df-b112-00215aee3ebe;Перше Травня;Перше Травня;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424155903;3;1
74dc48c4-749b-11df-b112-00215aee3ebe;Перше Травня;Перше Травня;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120682102;3;1
74dc48c5-749b-11df-b112-00215aee3ebe;Перше Травня;Перше Травня;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523483604;3;1
74dc48c6-749b-11df-b112-00215aee3ebe;Першозванівка;Першозвановка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224856204;3;1
74dc48c7-749b-11df-b112-00215aee3ebe;Першозванівка;Першозвановка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582007;3;1
74dc48cd-749b-11df-b112-00215aee3ebe;Першотравенка;Першотравенка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222385801;3;1
74dc48ce-749b-11df-b112-00215aee3ebe;Першотравенка;Першотравенка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522884301;3;1
74dc48cf-749b-11df-b112-00215aee3ebe;Першотравенськ;Першотравенск;місто;5394b34d-41be-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1212600000;2;1
74dc48d0-749b-11df-b112-00215aee3ebe;Першотравенськ;Першотравенск;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820656000;3;1
74dc48d1-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755144;3;1
74dc48d2-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321384001;3;1
74dc48d3-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325483203;3;1
74dc48d4-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922089303;3;1
74dc48d5-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920688205;3;1
74dc48d6-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;;3;1
74dc48d7-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922986505;3;1
74dc48d8-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925080806;3;1
74dc48d9-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222985201;3;1
74dc48da-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224284513;3;1
74dc48db-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825783901;3;1
74dc48dc-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320855703;3;1
74dc48dd-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322686508;3;1
74dc48de-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325111001;3;1
74dc48e0-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;64024b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324681005;3;1
74dc48e1-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323381504;3;1
74dc48e2-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321780509;3;1
74dc48e3-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321082501;3;1
74dc48e4-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320282003;3;1
74dc48e5-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322280502;3;1
74dc48e6-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121683701;3;1
74dc48e7-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122784201;3;1
74dc48e8-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122085301;3;1
74dc48ed-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520686603;3;1
74dc48ef-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221286002;3;1
74dc48f0-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223782503;3;1
74dc48f1-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824255400;3;1
74dc48f2-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386030;3;1
74dc48f3-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823487605;3;1
74dc48f4-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825284402;3;1
74dc48f6-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125187901;3;1
74dc48f7-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520386805;3;1
74dc48f8-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521987401;3;1
74dc48f9-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824280706;3;1
74dc48fa-749b-11df-b112-00215aee3ebe;Першотравневе;Першотравневое;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323884905;3;1
e0517edb-db89-11df-9197-00215aee3ebe;Першотравнівка;Першотравневка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822783505;3;1
74dc48fb-749b-11df-b112-00215aee3ebe;Песець;Песец;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823387501;3;1
74dc48fc-749b-11df-b112-00215aee3ebe;Песоцьке;Песоцкое;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422085004;3;1
cdc44f37-dde8-11df-9197-00215aee3ebe;Пестеля;Пестеля;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524385402;3;1
74dc478b-749b-11df-b112-00215aee3ebe;Пестичевське;Пестичевское ;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322685803;3;1
74dc48fd-749b-11df-b112-00215aee3ebe;Петики;Петыки;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484505;3;1
74dc48fe-749b-11df-b112-00215aee3ebe;Петраківка;Петраковка;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122285501;3;1
74dc48ff-749b-11df-b112-00215aee3ebe;Петракіївка;Петракеевка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324884401;3;1
74dc4900-749b-11df-b112-00215aee3ebe;Петрані;Петрани;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521082609;3;1
74dc4901-749b-11df-b112-00215aee3ebe;Петранка;Петранка;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624884401;3;1
74dc4902-749b-11df-b112-00215aee3ebe;Петраші;Петраши;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323582503;3;1
74dc4903-749b-11df-b112-00215aee3ebe;Петрашівка;Петрашевка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320680705;3;1
74dc4904-749b-11df-b112-00215aee3ebe;Петрашівка;Петрашевка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322485606;3;1
74dc4905-749b-11df-b112-00215aee3ebe;Петрашівка;Петрашевка;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320784401;3;1
74dc4906-749b-11df-b112-00215aee3ebe;Петрашівка;Петрашевка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524385202;3;1
74dc4907-749b-11df-b112-00215aee3ebe;Петрашівка;Петрашевка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523785001;3;1
74dc4908-749b-11df-b112-00215aee3ebe;Петрашівка;Петрашевка;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525683601;3;1
74dc4909-749b-11df-b112-00215aee3ebe;Петрашівка;Петрашевка;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820686501;3;1
871621d7-749b-11df-b112-00215aee3ebe;Петрашівка;Петрашовка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324084614;3;1
74dc490a-749b-11df-b112-00215aee3ebe;Петренки;Петренки;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321080804;3;1
74dc490b-749b-11df-b112-00215aee3ebe;Петренки;Петренки;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;;3;1
74dc490d-749b-11df-b112-00215aee3ebe;Петренкове;Петренково;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321288506;3;1
74dc490f-749b-11df-b112-00215aee3ebe;Петрик;Петрик;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522484604;3;1
74dc4910-749b-11df-b112-00215aee3ebe;Петрики;Петрики;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424480804;3;1
74dc4911-749b-11df-b112-00215aee3ebe;Петрики;Петрики;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120386001;3;1
74dc4912-749b-11df-b112-00215aee3ebe;Петриків;Петриков;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125286701;2;1
74dc4913-749b-11df-b112-00215aee3ebe;Петриків;Петриков;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224255109;3;1
74dc4914-749b-11df-b112-00215aee3ebe;Петриківка;Петриковка;місто;bad3ac3c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1223755100;3;1
74dc4915-749b-11df-b112-00215aee3ebe;Петриківка;Петриковка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225080517;3;1
74dc4916-749b-11df-b112-00215aee3ebe;Петриківка;Петриковка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755108;3;1
74dc4917-749b-11df-b112-00215aee3ebe;Петриківці;Петриковцы;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524885201;3;1
74dc4918-749b-11df-b112-00215aee3ebe;Петриківці;Петриковцы;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820985401;3;1
74dc4919-749b-11df-b112-00215aee3ebe;Петрикове;Петриково;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755703;3;1
74dc491a-749b-11df-b112-00215aee3ebe;Петрилів;Петрилов;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625687201;3;1
74dc491b-749b-11df-b112-00215aee3ebe;Петримани;Петриманы;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522885001;3;1
74dc491c-749b-11df-b112-00215aee3ebe;Петричанка;Петричанка;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321088502;3;1
74dc491d-749b-11df-b112-00215aee3ebe;Петричі;Петричи;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620680403;3;1
a9aeaaa5-3df2-11e2-bcf4-003048d2b473;Петрищеве;Петрищево;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321781007;3;1
74dc491e-749b-11df-b112-00215aee3ebe;Петрів;Петров;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625687601;3;1
74dc495b-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522282802;3;1
74dc495c-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521081406;3;1
74dc4958-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420686001;3;1
74dc4959-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424186601;3;1
74dc4940-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120886805;3;1
74dc4941-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124786901;3;1
74dc4947-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;78f4540d-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6524182503;3;1
74dc494e-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124885902;3;1
74dc4955-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221485802;3;1
74dc4956-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425885001;3;1
74dc4920-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321387807;3;1
74dc4921-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324080207;3;1
74dc4922-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320686101;3;1
74dc4923-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323086204;3;1
74dc4925-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221086607;3;1
74dc4926-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224581709;3;1
74dc4927-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223882509;3;1
74dc4928-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225255118;3;1
74dc4929-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455103;3;1
74dc492a-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825482204;3;1
74dc492b-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822784011;3;1
74dc492c-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821755119;3;1
74dc492d-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824283201;3;1
74dc492e-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322684507;3;1
74dc492f-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325183502;3;1
74dc4930-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323385001;3;1
74dc4931-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325785701;3;1
74dc4933-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324587006;3;1
74dc4934-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324884509;3;1
74dc4935-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320481806;3;1
74dc4936-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122583901;3;1
74dc4937-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120680805;3;1
74dc4938-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122986001;3;1
74dc4939-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123380904;3;1
74dc493a-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125283009;3;1
74dc493b-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123583401;3;1
74dc493c-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121855400;3;1
74dc493d-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283806;3;1
74dc493e-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123983905;3;1
74dc495e-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522685302;3;1
74dc495f-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525583602;3;1
74dc4960-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524084305;3;1
74dc4961-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524684703;3;1
74dc4962-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522884601;3;1
74dc4963-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820985904;3;1
74dc4964-749b-11df-b112-00215aee3ebe;Петрівка;Петровка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323288907;3;1
e0517ee1-db89-11df-9197-00215aee3ebe;Петрівка;Петровка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824510105;3;1
74dc4978-749b-11df-b112-00215aee3ebe;Петрівка (Петрівське);Петривка (Петровское);село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322283006;3;1
8716258e-749b-11df-b112-00215aee3ebe;Петрівка (Чапаєве);Петрівка (Чапаево);село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325484001;3;1
74dc4965-749b-11df-b112-00215aee3ebe;Петрівка Друга;Петровка Вторая;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821481703;3;1
74dc4967-749b-11df-b112-00215aee3ebe;Петрівка Перша;Петровка Первая;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821481702;3;1
74dc4969-749b-11df-b112-00215aee3ebe;Петрівка-Попівка;Петровка-Поповка;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122884801;3;1
74dc496a-749b-11df-b112-00215aee3ebe;Петрівка-Роменська;Петровка-Роменская;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320485201;3;1
74dc49df-749b-11df-b112-00215aee3ebe;Петрівськ (Петросталь);Петрівськ (Петросталь);село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124787201;3;1
74dc496b-749b-11df-b112-00215aee3ebe;Петрівська Гута;Петровская Гута;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122883602;3;1
74dc496e-749b-11df-b112-00215aee3ebe;Петрівське;Петровское;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225086006;3;1
74dc4973-749b-11df-b112-00215aee3ebe;Петрівське;Петровское;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323581708;3;1
74dc4970-749b-11df-b112-00215aee3ebe;Петрівське;Петровское;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;*122078110;3;1
74dc4971-749b-11df-b112-00215aee3ebe;Петрівське;Петровское;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;3;1
74dc4977-749b-11df-b112-00215aee3ebe;Петрівське;Петровское;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320284801;3;1
74dc497a-749b-11df-b112-00215aee3ebe;Петрівське;Петровское;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320683003;3;1
74dc4981-749b-11df-b112-00215aee3ebe;Петрівське;Петровское;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521884004;3;1
74dc4992-749b-11df-b112-00215aee3ebe;Петрівське;Петровское;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222281603;3;1
74dc498e-749b-11df-b112-00215aee3ebe;Петрівське;Петровское;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222080902;3;1
74dc498f-749b-11df-b112-00215aee3ebe;Петрівське;Петровское;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221888602;3;1
74dc4990-749b-11df-b112-00215aee3ebe;Петрівське;Петровское;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221986405;3;1
74dc4997-749b-11df-b112-00215aee3ebe;Петрівське;Петровское;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424183102;3;1
74dc4999-749b-11df-b112-00215aee3ebe;Петрівське;Петровское;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;;3;1
74dc499a-749b-11df-b112-00215aee3ebe;Петрівське;Петровское;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520386206;3;1
74dc499c-749b-11df-b112-00215aee3ebe;Петрівське;Петровское;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820984704;3;1
74dc497e-749b-11df-b112-00215aee3ebe;Петрівське;Петровское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582509;3;1
a5388eb9-db65-11df-9197-00215aee3ebe;Петрівське;Петровское;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821855700;3;1
8cdd5ce8-de27-11df-9197-00215aee3ebe;Петрівське;Петровское;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524081805;3;1
74dc4982-749b-11df-b112-00215aee3ebe;Петрівське (Білозерске);Петровское;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520384701;3;1
74dc499e-749b-11df-b112-00215aee3ebe;Петрівці;Петровцы;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323285201;3;1
74dc499f-749b-11df-b112-00215aee3ebe;Петрівщина;Петровщина;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820981703;3;1
74dc49a2-749b-11df-b112-00215aee3ebe;Петрова Слобода;Петрова Слобода;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422482003;3;1
74dc49a3-749b-11df-b112-00215aee3ebe;Петрове;Петрово;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721184404;3;1
74dc49a4-749b-11df-b112-00215aee3ebe;Петрове;Петрово;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225255103;3;1
74dc49a8-749b-11df-b112-00215aee3ebe;Петрове;Петрово;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425581003;3;1
74dc49a9-749b-11df-b112-00215aee3ebe;Петрове;Петрово;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522285401;3;1
74dc49aa-749b-11df-b112-00215aee3ebe;Петрове;Петрово;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;ada89f09-758d-11ea-80c6-000c29800ae7;;3;1
74dc49ab-749b-11df-b112-00215aee3ebe;Петрове;Петрово;місто;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524955100;3;1
025e6012-06f0-11ea-80e7-1c98ec135261;Петрове;Петрове;;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;;3;1
74dc497d-749b-11df-b112-00215aee3ebe;Петрове (Петрівське);Петрово (Петровское);село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121080706;3;1
56bdd21d-749b-11df-b112-00215aee3ebe;Петровірівка (Жовтень);Петроверовка (Жовтень);село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125481001;3;1
9d400caf-fa09-11e8-80d9-1c98ec135261;Петрово;Петрово;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121080706;3;1
74dc49a5-749b-11df-b112-00215aee3ebe;Петрово;Петрово;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121284101;3;1
74dc49ad-749b-11df-b112-00215aee3ebe;Петрово-Висунське;Петрово-Висунское;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655124;3;1
74dc49ae-749b-11df-b112-00215aee3ebe;Петрово-Солониха;Петрово-Солониха;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824280904;3;1
74dc49b0-749b-11df-b112-00215aee3ebe;Петровське;Петровское;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;*122268330;3;1
74dc49b8-749b-11df-b112-00215aee3ebe;Петровського;Петровского;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420389005;3;1
74dc49b9-749b-11df-b112-00215aee3ebe;Петровського;Петровского;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121582003;3;1
74dc49bb-749b-11df-b112-00215aee3ebe;Петро-Ганнівка;Петро-Анновка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321385303;3;1
74dc49bc-749b-11df-b112-00215aee3ebe;Петро-Давидівка;Петро-Давыдовка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321084501;3;1
74dc49bd-749b-11df-b112-00215aee3ebe;Петродолинське;Петродолинское;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123783401;3;1
74dc49be-749b-11df-b112-00215aee3ebe;Петро-Євдокіївка;Петро-Евдокиевка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123984203;3;1
74dc49bf-749b-11df-b112-00215aee3ebe;Петрозагір'я;Петрозагорье;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520382403;3;1
74dc49c0-749b-11df-b112-00215aee3ebe;Петрокорбівка;Петрокорбовка;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523484701;3;1
74dc49a0-749b-11df-b112-00215aee3ebe;Петро-Марківка;Петро-Марковка;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521286106;3;1
74dc49c2-749b-11df-b112-00215aee3ebe;Петро-Михайлівка;Петро-Михайловка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321586801;3;1
74dc49c3-749b-11df-b112-00215aee3ebe;Петроострів;Петроостров;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523886001;3;1
74dc49c4-749b-11df-b112-00215aee3ebe;Петропавлівка;Петропавловка;місто;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223855100;2;1
74dc49c5-749b-11df-b112-00215aee3ebe;Петропавлівка;Петропавловка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225283307;3;1
74dc49c6-749b-11df-b112-00215aee3ebe;Петропавлівка;Петропавловка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821484301;3;1
74dc49c7-749b-11df-b112-00215aee3ebe;Петропавлівка;Петропавловка;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821183802;3;1
74dc49c8-749b-11df-b112-00215aee3ebe;Петропавлівка;Петропавловка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320886501;3;1
74dc49ca-749b-11df-b112-00215aee3ebe;Петропавлівка;Петропавловка;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323281007;3;1
74dc49cc-749b-11df-b112-00215aee3ebe;Петропавлівка;Петропавловка;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124584601;3;1
74dc49cd-749b-11df-b112-00215aee3ebe;Петропавлівка;Петропавловка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323684006;3;1
74dc49ce-749b-11df-b112-00215aee3ebe;Петропавлівка;Петропавловка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322181305;3;1
74dc49cf-749b-11df-b112-00215aee3ebe;Петропавлівка;Петропавловка;село;78f4540d-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6524185001;3;1
74dc49d4-749b-11df-b112-00215aee3ebe;Петропавлівка;Петропавловка;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120386501;3;1
00443561-972b-11e8-80d4-1c98ec135261;Петропавлівка;Петропавлівка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822755107;3;1
74dc49d5-749b-11df-b112-00215aee3ebe;Петропавлівська Борщагівка;Петропавловская Борщаговка;місто;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222485901;1;0
74dc49d6-749b-11df-b112-00215aee3ebe;Петропавлівське;Петропавловское;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825780705;3;1
74dc49d7-749b-11df-b112-00215aee3ebe;Петропавлівське;Петропавловское;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323683508;3;1
73e94c11-ddc1-11df-9197-00215aee3ebe;Петропавлівське (Петрівське);Петропавловское (Петровское);село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220881303;3;0
74dc49d8-749b-11df-b112-00215aee3ebe;Петропілля;Петрополье;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325785801;3;1
74dc49d9-749b-11df-b112-00215aee3ebe;Петропілля;Петрополье;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322883003;3;1
74dc49da-749b-11df-b112-00215aee3ebe;Петропілля;Петрополье;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323955707;3;1
74dc49db-749b-11df-b112-00215aee3ebe;Петропіль;Петрополь;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322181306;3;1
74dc49dc-749b-11df-b112-00215aee3ebe;Петро-Свистунове;Петро-Свистуново;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225081905;3;1
74dc49dd-749b-11df-b112-00215aee3ebe;Петро-Свистунове;Петро-Свистуново;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321581304;3;1
74dc49de-749b-11df-b112-00215aee3ebe;Петроселівка;Петроселовка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5310390007;3;1
33ec001e-ddd5-11df-9197-00215aee3ebe;Петрунівка;Петруновка;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521982003;3;1
74dc49e0-749b-11df-b112-00215aee3ebe;Петрушин;Петрушин;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425586901;3;1
74dc49e1-749b-11df-b112-00215aee3ebe;Петруші;Петруши;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424487201;3;1
74dc49e2-749b-11df-b112-00215aee3ebe;Петрушів;Петрушов;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124487403;3;1
74dc49e3-749b-11df-b112-00215aee3ebe;Петрушівка;Петрушовка;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922355605;3;1
74dc49e4-749b-11df-b112-00215aee3ebe;Петрушівка;Петрушовка;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221685801;3;1
74dc49e5-749b-11df-b112-00215aee3ebe;Петрушівка;Петрушовка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421782401;3;1
74dc49e6-749b-11df-b112-00215aee3ebe;Петрушки;Петрушки;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222486001;3;1
74dc49e7-749b-11df-b112-00215aee3ebe;Петрушки;Петрушки;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122586701;3;1
74dc49e8-749b-11df-b112-00215aee3ebe;Петухівка;Петуховка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;;3;1
74dc49e9-749b-11df-b112-00215aee3ebe;Петьків;Петьков;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425383003;3;1
74dc49ea-749b-11df-b112-00215aee3ebe;Пехи;Пехи;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725755105;3;1
74dc49eb-749b-11df-b112-00215aee3ebe;Печанівка;Печановка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821483601;3;1
74dc49ec-749b-11df-b112-00215aee3ebe;Печеніги;Печенеги;місто;64024b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324655100;3;1
74dc49ed-749b-11df-b112-00215aee3ebe;Печеніжин;Печенежин;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623255700;3;1
74dc49ee-749b-11df-b112-00215aee3ebe;Печенія;Печения;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621883902;3;1
74dc49ef-749b-11df-b112-00215aee3ebe;Печенюги;Печенюги;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423688101;3;1
74dc49f0-749b-11df-b112-00215aee3ebe;Печера;Печера;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524385201;3;1
74dc49f1-749b-11df-b112-00215aee3ebe;Печеське;Печеское;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822787901;3;1
74dc49f2-749b-11df-b112-00215aee3ebe;Печеськи;Печески;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825086401;3;1
74dc49f3-749b-11df-b112-00215aee3ebe;Печиводи;Печиводы;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823986601;3;1
74dc49f4-749b-11df-b112-00215aee3ebe;Печини;Печины;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925086701;3;1
74dc49f5-749b-11df-b112-00215aee3ebe;Печихвости;Печихвосты;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720886001;3;1
74dc49f6-749b-11df-b112-00215aee3ebe;Печихвости;Печихвосты;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622181203;3;1
74dc49f7-749b-11df-b112-00215aee3ebe;Печище;Печище;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924787307;3;1
74dc49f8-749b-11df-b112-00215aee3ebe;Печі;Печи;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420885601;3;1
74dc49f9-749b-11df-b112-00215aee3ebe;Печіївка;Печиевка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324285010;3;1
74dc49fa-749b-11df-b112-00215aee3ebe;Печірна;Печорна;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123885001;3;1
74dc49fb-749b-11df-b112-00215aee3ebe;Печорна;Печорна;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122083702;3;1
74dc49fc-749b-11df-b112-00215aee3ebe;Пещера;Пещера;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124482402;3;1
74dc49fd-749b-11df-b112-00215aee3ebe;Пигарівка;Пигаревка;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924484801;3;1
74dc49fe-749b-11df-b112-00215aee3ebe;Пижівка;Пыжовка;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823382702;3;1
73e94bef-ddc1-11df-9197-00215aee3ebe;Пийтерфолво;Пийтерфолво;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121284101;3;1
74dc49ff-749b-11df-b112-00215aee3ebe;Пиків;Пиков;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521686303;3;1
74dc4a00-749b-11df-b112-00215aee3ebe;Пиківська Слобідка;Пиковская Слободка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522487005;3;1
74dc4a01-749b-11df-b112-00215aee3ebe;Пиковець;Пиковец;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521486003;3;1
74dc4a02-749b-11df-b112-00215aee3ebe;Пикуловичі;Пикуловичи;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623685201;3;1
74dc4a03-749b-11df-b112-00215aee3ebe;Пила;Пила;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625386404;3;1
74dc4a04-749b-11df-b112-00215aee3ebe;Пилатківці;Пилатковцы;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
74dc4a05-749b-11df-b112-00215aee3ebe;Пили;Пилы;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783211;3;1
74dc4a06-749b-11df-b112-00215aee3ebe;Пилипенки;Пилипенки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321310107;3;1
74dc4a07-749b-11df-b112-00215aee3ebe;Пилипенки;Пилипенки;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320255108;3;1
74dc4a08-749b-11df-b112-00215aee3ebe;Пилипенки;Пилипенки;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321883806;3;1
74dc4a09-749b-11df-b112-00215aee3ebe;Пилипенки;Пилипенки;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;;3;1
33ec001f-ddd5-11df-9197-00215aee3ebe;Пилипенкове;Пилипенково;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522281002;3;1
74dc4a0a-749b-11df-b112-00215aee3ebe;Пилипець;Пилипец;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122485201;3;0
74dc4a0b-749b-11df-b112-00215aee3ebe;Пилипи;Пилипы;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623285401;3;1
74dc4a0c-749b-11df-b112-00215aee3ebe;Пилипи;Пилипы;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520281612;3;1
74dc4a0d-749b-11df-b112-00215aee3ebe;Пилипи;Пилипы;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522685301;3;1
74dc4a0e-749b-11df-b112-00215aee3ebe;Пилипи;Пилипы;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822789205;3;1
74dc4a0f-749b-11df-b112-00215aee3ebe;Пилипи;Пилипы;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821588201;3;1
74dc4a10-749b-11df-b112-00215aee3ebe;Пилипи-Борівські;Пилипы-Боровские;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523955112;3;1
74dc4a11-749b-11df-b112-00215aee3ebe;Пилипи-Олександрівські;Пилипы-Александровские;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820686701;3;1
74dc4a12-749b-11df-b112-00215aee3ebe;Пилипи-Хребтіївські;Пилипы-Хребтиевские;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823388501;3;1
74dc4a13-749b-11df-b112-00215aee3ebe;Пилипівка;Пилиповка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655105;3;1
74dc4a14-749b-11df-b112-00215aee3ebe;Пилипівка;Пилиповка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123380303;3;1
74dc4a16-749b-11df-b112-00215aee3ebe;Пилипівка;Пилиповка;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224985401;3;1
74dc4a17-749b-11df-b112-00215aee3ebe;Пилипівка;Пилиповка;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825886802;3;1
74dc4a18-749b-11df-b112-00215aee3ebe;Пилипівці;Пилиповцы;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624486202;3;1
74dc4a19-749b-11df-b112-00215aee3ebe;Пилипківці;Пилипковцы;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823388001;3;1
74dc4a1a-749b-11df-b112-00215aee3ebe;Пилиповичі;Пилиповичи;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221087501;3;1
74dc4a1b-749b-11df-b112-00215aee3ebe;Пилиповичі;Пилиповичи;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824084401;3;1
74dc4a1c-749b-11df-b112-00215aee3ebe;Пилиповичі;Пилиповичи;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825087601;3;1
74dc4a1d-749b-11df-b112-00215aee3ebe;Пилиповичі;Пилиповичи;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323688204;3;1
74dc4a1e-749b-11df-b112-00215aee3ebe;Пилипо-Кошара;Пилипо-Кошара;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821455605;3;1
74dc4a1f-749b-11df-b112-00215aee3ebe;Пилипонка;Пилипонка;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820984303;3;1
74dc4a20-749b-11df-b112-00215aee3ebe;Пилипча;Пилипча;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220286301;3;1
74dc4a21-749b-11df-b112-00215aee3ebe;Пилипча;Пилипча;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220485101;2;1
74dc4a22-749b-11df-b112-00215aee3ebe;Пилипча;Пилипча;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424482402;3;1
74dc4a24-749b-11df-b112-00215aee3ebe;Пилипче;Пилипче;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120886401;3;1
74dc4a25-749b-11df-b112-00215aee3ebe;Пилівка;Пылевка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920388704;3;1
74dc4a26-749b-11df-b112-00215aee3ebe;Пилява;Пилява;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121285001;3;1
74dc4a27-749b-11df-b112-00215aee3ebe;Пилява;Пилява;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221880803;3;1
74dc4a28-749b-11df-b112-00215aee3ebe;Пилява;Пилява;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122087803;3;1
74dc4a29-749b-11df-b112-00215aee3ebe;Пилява;Пилява;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524584801;3;1
74dc4a2a-749b-11df-b112-00215aee3ebe;Пилява;Пилява;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824486501;3;1
74dc4a2b-749b-11df-b112-00215aee3ebe;Пилявка;Пилявка;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824486001;3;1
74dc4a2c-749b-11df-b112-00215aee3ebe;Пиляї;Пиляи;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825585103;3;1
74dc4a2d-749b-11df-b112-00215aee3ebe;Пилятин;Пилятин;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422088201;3;1
74dc4a2e-749b-11df-b112-00215aee3ebe;Пильгани;Пильганы;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720887503;3;1
74dc4a2f-749b-11df-b112-00215aee3ebe;Пильки;Пильки;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822187204;3;1
74dc4a30-749b-11df-b112-00215aee3ebe;Пильна;Пыльная;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325182307;3;1
74dc4a31-749b-11df-b112-00215aee3ebe;Пильня;Пыльня;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425586307;3;1
74dc4a32-749b-11df-b112-00215aee3ebe;Пимонівка;Пимоновка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322886507;3;1
74dc4a33-749b-11df-b112-00215aee3ebe;Пиняни;Пыняны;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624280303;3;1
74dc4a34-749b-11df-b112-00215aee3ebe;Пиратин;Пиратин;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623984301;3;1
74dc4a35-749b-11df-b112-00215aee3ebe;Пиріжки;Пирожки;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823486801;3;1
74dc4a36-749b-11df-b112-00215aee3ebe;Пиріжна;Пирожная;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122584101;3;1
74dc4a37-749b-11df-b112-00215aee3ebe;Пироги;Пироги;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610120;3;1
74dc4a38-749b-11df-b112-00215aee3ebe;Пирогів;Пирогов;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524585303;3;1
74dc4a39-749b-11df-b112-00215aee3ebe;Пирогівка;Пироговка;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925380404;3;1
74dc4a3a-749b-11df-b112-00215aee3ebe;Пирогівка;Пироговка;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420385003;3;1
74dc4a3b-749b-11df-b112-00215aee3ebe;Пирогівка;Пироговка;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820686503;3;1
74dc4a3c-749b-11df-b112-00215aee3ebe;Пирогівці;Пироговцы;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;7424188803;3;1
74dc4a3d-749b-11df-b112-00215aee3ebe;Пирогівці;Пироговцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825086701;3;1
74dc4a3e-749b-11df-b112-00215aee3ebe;Пироговичі;Пироговичи;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222083902;3;1
74dc4a40-749b-11df-b112-00215aee3ebe;Пиротчине;Пиротчино;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922682206;3;1
74dc4a41-749b-11df-b112-00215aee3ebe;Пирятин;Пирятин;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622780807;3;1
74dc4a42-749b-11df-b112-00215aee3ebe;Пирятин;Пирятин;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621683905;3;1
74dc4a43-749b-11df-b112-00215aee3ebe;Пирятин;Пырятин;місто;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323810100;2;0
74dc4a44-749b-11df-b112-00215aee3ebe;Пирятинщина;Пирятинщина;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320484603;3;1
325c57f4-ddd2-11df-9197-00215aee3ebe;Писанка;Писанка;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521984905;3;1
74dc4a45-749b-11df-b112-00215aee3ebe;Писанці;Писанцы;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224280517;3;1
74dc4a46-749b-11df-b112-00215aee3ebe;Писарева Воля;Писарева Воля;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720580411;3;1
74dc4a47-749b-11df-b112-00215aee3ebe;Писаренки;Писаренки;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324280911;3;1
74dc4a48-749b-11df-b112-00215aee3ebe;Писаренки;Писаренки;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081306;3;1
74dc4a49-749b-11df-b112-00215aee3ebe;Писарівка;Писаревка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323484301;3;1
74dc4a4a-749b-11df-b112-00215aee3ebe;Писарівка;Писаревка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924756201;3;1
74dc4a4b-749b-11df-b112-00215aee3ebe;Писарівка;Писаревка;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120487005;3;1
74dc4a4c-749b-11df-b112-00215aee3ebe;Писарівка;Писаревка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224886801;3;1
74dc4a4d-749b-11df-b112-00215aee3ebe;Писарівка;Писаревка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322685001;3;1
74dc4a4e-749b-11df-b112-00215aee3ebe;Писарівка;Писаревка;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323183704;3;1
74dc4a4f-749b-11df-b112-00215aee3ebe;Писарівка;Писаревка;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122584301;3;1
74dc4a51-749b-11df-b112-00215aee3ebe;Писарівка;Писаревка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823481802;3;1
74dc4a52-749b-11df-b112-00215aee3ebe;Писарівка;Писаревка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821181303;3;1
74dc4a53-749b-11df-b112-00215aee3ebe;Писарівка;Писаревка;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122884901;3;1
74dc4a54-749b-11df-b112-00215aee3ebe;Писарівка;Писаревка;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521686501;3;1
74dc4a55-749b-11df-b112-00215aee3ebe;Писарівка;Писаревка;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520685203;3;0
74dc4a56-749b-11df-b112-00215aee3ebe;Писарівка;Писаревка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525386301;3;1
74dc4a57-749b-11df-b112-00215aee3ebe;Писарівка;Писаревка;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525683801;3;1
74dc4a58-749b-11df-b112-00215aee3ebe;Писарівка;Писаревка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523885203;3;1
74dc4a59-749b-11df-b112-00215aee3ebe;Писарівка;Писаревка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822783404;3;1
74dc4a5a-749b-11df-b112-00215aee3ebe;Писарівка;Писаревка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824282607;3;1
74dc4a5b-749b-11df-b112-00215aee3ebe;Писарівка;Писаревка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820985601;3;1
74dc4a5c-749b-11df-b112-00215aee3ebe;Писарівка;Писаревка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323288908;3;1
74dc4a5d-749b-11df-b112-00215aee3ebe;Писарівщина;Писаревщина;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281215;3;1
74dc4a5e-749b-11df-b112-00215aee3ebe;Писарівщина;Писаревщина;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321081704;3;1
74dc4a5f-749b-11df-b112-00215aee3ebe;Писарівщина;Писаревщина;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320480409;3;1
74dc4a60-749b-11df-b112-00215aee3ebe;Писарщина;Писарщина;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322486305;3;1
74dc4a61-749b-11df-b112-00215aee3ebe;Писарщина;Писарщина;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320881303;3;1
74dc4a63-749b-11df-b112-00215aee3ebe;Письменне;Письменное;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755106;3;1
74dc4a64-749b-11df-b112-00215aee3ebe;Письмечеве;Письмечево;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225085501;3;1
74dc4a65-749b-11df-b112-00215aee3ebe;Питомник;Питомник;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922081104;3;1
74dc4a66-749b-11df-b112-00215aee3ebe;Питомник;Питомник;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322082005;3;1
74dc4a69-749b-11df-b112-00215aee3ebe;Пихи;Пыхи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484507;3;1
74dc4a6a-749b-11df-b112-00215aee3ebe;Пихтії;Пыхтии;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824280707;3;1
74dc4a6b-749b-11df-b112-00215aee3ebe;Пичугине;Пичугино;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221884305;3;1
74dc4a6c-749b-11df-b112-00215aee3ebe;Пишки;Пышки;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824484502;3;1
74dc4a6d-749b-11df-b112-00215aee3ebe;Пишківці;Пышковцы;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121285701;3;1
74dc4a6e-749b-11df-b112-00215aee3ebe;Пишне;Пышное;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521987501;3;1
74dc4a6f-749b-11df-b112-00215aee3ebe;Пишне;Пышное;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322885102;3;1
74dc4a70-749b-11df-b112-00215aee3ebe;Пишненки;Пышненки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321385301;3;1
74dc4a71-749b-11df-b112-00215aee3ebe;Пищальники;Пищальники;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122086603;3;1
74dc4a72-749b-11df-b112-00215aee3ebe;Пищатинці;Пещатинцы;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120886603;3;1
74dc4a73-749b-11df-b112-00215aee3ebe;Пищаче;Пищаче;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623282002;3;1
74dc4a75-749b-11df-b112-00215aee3ebe;Пищевиця;Пищевица;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823681105;3;1
74dc4a76-749b-11df-b112-00215aee3ebe;Пищики;Пищики;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224085601;2;1
74dc4a77-749b-11df-b112-00215aee3ebe;Пищики;Пищики;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424484211;3;1
74dc4a78-749b-11df-b112-00215aee3ebe;Пищикове;Пищиково;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923880404;3;1
74dc4a79-749b-11df-b112-00215aee3ebe;Південне;Пивденное;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222986702;3;1
74dc4a7a-749b-11df-b112-00215aee3ebe;Південне;Пивденное;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322080903;3;1
74dc4a7b-749b-11df-b112-00215aee3ebe;Південне;Пивденное;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325111000;2;1
74dc4a7c-749b-11df-b112-00215aee3ebe;Південне;Пивденное;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120886404;3;1
74dc4a7f-749b-11df-b112-00215aee3ebe;Півнева Гора;Пивнева Гора;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822181004;3;1
74dc4a80-749b-11df-b112-00215aee3ebe;Півні;Пивни;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823084210;3;1
74dc4a81-749b-11df-b112-00215aee3ebe;Півні;Пивни;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224982802;3;1
74dc4a83-749b-11df-b112-00215aee3ebe;Півнівщина;Пивнивщина;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421489602;3;1
74dc4a84-749b-11df-b112-00215aee3ebe;Північне;Пивничное;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922988207;3;1
74dc4a86-749b-11df-b112-00215aee3ebe;Півці;Певцы;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222286401;3;1
f933344d-db4e-11df-9197-00215aee3ebe;Півці;Певцы;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;;3;1
74dc4a88-749b-11df-b112-00215aee3ebe;Півче;Певчее;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622685101;3;1
74dc4b52-749b-11df-b112-00215aee3ebe;Під'язівні;Подьязовни;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724286908;3;1
74dc4b53-749b-11df-b112-00215aee3ebe;Під'ярків;Подъярков;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623386003;3;1
74dc4a89-749b-11df-b112-00215aee3ebe;Підбережжя;Подбережье;село;8067acf2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2610291501;3;1
74dc4a8a-749b-11df-b112-00215aee3ebe;Підбереззя;Подберезье;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720886201;3;1
74dc4a8b-749b-11df-b112-00215aee3ebe;Підберізці;Подберезцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122688602;3;1
74dc4a8c-749b-11df-b112-00215aee3ebe;Підберізці;Подберезцы;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623685301;2;1
74dc4a8e-749b-11df-b112-00215aee3ebe;Підбір'я;Подборье;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624486503;3;1
74dc4a8d-749b-11df-b112-00215aee3ebe;Підбірці;Подборцы;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623683802;2;0
74dc4a8f-749b-11df-b112-00215aee3ebe;Підбороччя;Подборочье;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721488603;3;1
74dc4a90-749b-11df-b112-00215aee3ebe;Підбрусинь;Подбрусинь;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623886907;3;1
74dc4a91-749b-11df-b112-00215aee3ebe;Підбуж;Подбуж;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621255700;3;1
74dc4a92-749b-11df-b112-00215aee3ebe;Підвальне;Подвальное;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320780303;3;1
74dc4a93-749b-11df-b112-00215aee3ebe;Підварівка;Подваровка;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322281906;3;1
74dc4a94-749b-11df-b112-00215aee3ebe;Підвелідники;Подвеледники;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824285403;3;1
74dc4a95-749b-11df-b112-00215aee3ebe;Підвербці;Подвербцы;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625687701;3;1
74dc4a96-749b-11df-b112-00215aee3ebe;Підвиння;Подвинье;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624484301;3;1
74dc4a97-749b-11df-b112-00215aee3ebe;Підвиноградів;Подвиноградов;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121284401;3;0
74dc4a98-749b-11df-b112-00215aee3ebe;Підвисоке;Подвысокое;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120485701;3;1
74dc4a99-749b-11df-b112-00215aee3ebe;Підвисоке;Подвысокое;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321083501;3;1
74dc4a9a-749b-11df-b112-00215aee3ebe;Підвисоке;Подвысокое;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625283901;3;1
74dc4a9b-749b-11df-b112-00215aee3ebe;Підвисоке;Подвысокое;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623386407;3;1
74dc4a9c-749b-11df-b112-00215aee3ebe;Підвисоке;Подвысокое;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523184101;3;1
74dc4a9d-749b-11df-b112-00215aee3ebe;Підвисоке;Подвысокое;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523684201;3;1
74dc4a9e-749b-11df-b112-00215aee3ebe;Підвисоке;Подвысокое;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625882102;3;1
74dc4a9f-749b-11df-b112-00215aee3ebe;Підволочиськ;Подволочиск;місто;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6124655100;3;0
74dc4aa0-749b-11df-b112-00215aee3ebe;Підгаврилівка;Подгавриловка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224285602;3;1
74dc4aa1-749b-11df-b112-00215aee3ebe;Підгай;Подгай;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623884702;3;1
74dc4aa2-749b-11df-b112-00215aee3ebe;Підгайне;Подгайное;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222081905;3;1
74dc4aa3-749b-11df-b112-00215aee3ebe;Підгайне;Подгайное;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423810104;3;1
74dc4aa4-749b-11df-b112-00215aee3ebe;Підгайне;Подгайное;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622685803;3;1
74dc4aa5-749b-11df-b112-00215aee3ebe;Підгайці;Подгайцы;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722884801;3;0
74dc4aa6-749b-11df-b112-00215aee3ebe;Підгайці;Подгайцы;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125885901;3;1
74dc4aa7-749b-11df-b112-00215aee3ebe;Підгайці;Подгайцы;місто;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124810100;3;0
74dc4aa8-749b-11df-b112-00215aee3ebe;Підгайці;Подгайцы;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623687005;3;1
74dc4aa9-749b-11df-b112-00215aee3ebe;Підгайці;Подгайцы;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522581207;3;0
74dc4aaa-749b-11df-b112-00215aee3ebe;Підгайці;Подгайцы;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623887101;3;1
17af7b1a-ddb9-11df-9197-00215aee3ebe;Підгайці;Подгайцы;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720586603;3;1
74dc4aab-749b-11df-b112-00215aee3ebe;Підгайчики;Подгайчики;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122689805;3;1
74dc4aac-749b-11df-b112-00215aee3ebe;Підгайчики;Подгайчики;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125086601;3;1
74dc4aad-749b-11df-b112-00215aee3ebe;Підгайчики;Подгайчики;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623285601;3;1
74dc4aae-749b-11df-b112-00215aee3ebe;Підгайчики;Подгайчики;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621884901;3;1
74dc4aaf-749b-11df-b112-00215aee3ebe;Підгайчики;Подгайчики;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624286701;3;1
74dc4ab0-749b-11df-b112-00215aee3ebe;Підгало;Пидгало;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620481603;3;1
74dc4ab1-749b-11df-b112-00215aee3ebe;Підгаття;Подгатье;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723687604;3;1
74dc4ab2-749b-11df-b112-00215aee3ebe;Підгать;Подгать;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622481203;3;1
74dc4acc-749b-11df-b112-00215aee3ebe;Підгір'я;Подгорье;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320283605;3;1
74dc4acd-749b-11df-b112-00215aee3ebe;Підгір'я;Подгорье;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724288207;3;1
74dc4ace-749b-11df-b112-00215aee3ebe;Підгір'я;Подгорье;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825485301;3;1
74dc4acf-749b-11df-b112-00215aee3ebe;Підгір'я;Подгорье;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620487201;3;1
74dc4ad0-749b-11df-b112-00215aee3ebe;Підгір'я;Подгорье;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620385315;3;1
74dc4ad1-749b-11df-b112-00215aee3ebe;Підгір'я;Подгорье;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621886102;3;1
74dc4ab3-749b-11df-b112-00215aee3ebe;Підгірне;Подгорное;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325482604;3;1
74dc4ab4-749b-11df-b112-00215aee3ebe;Підгірне;Подгорное;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724582911;3;1
74dc4ab5-749b-11df-b112-00215aee3ebe;Підгірне;Подгорное;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222086006;3;1
74dc4ab6-749b-11df-b112-00215aee3ebe;Підгірне;Подгорное;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222982206;3;1
74dc4ab7-749b-11df-b112-00215aee3ebe;Підгірне;Подгорное;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120885501;3;1
74dc4ab8-749b-11df-b112-00215aee3ebe;Підгірне;Подгорное;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124787601;3;1
74dc4ab9-749b-11df-b112-00215aee3ebe;Підгірне;Подгорное;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323684001;3;1
74dc4abd-749b-11df-b112-00215aee3ebe;Підгірне;Подгорное;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623684002;3;1
74dc4abf-749b-11df-b112-00215aee3ebe;Підгірне;Подгорное;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121987204;3;1
74dc4ac3-749b-11df-b112-00215aee3ebe;Підгірне;Подгорное;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425586603;3;1
74dc4ac4-749b-11df-b112-00215aee3ebe;Підгірне;Подгорное;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423680802;3;1
74dc4ac5-749b-11df-b112-00215aee3ebe;Підгірне;Подгорное;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824285605;3;1
74dc4ad7-749b-11df-b112-00215aee3ebe;Підгірне;Подгорное;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322480405;3;1
74dc4ac6-749b-11df-b112-00215aee3ebe;Підгірник;Подгорник;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625483903;3;1
74dc4ac7-749b-11df-b112-00215aee3ebe;Підгірці;Подгорцы;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620384801;3;0
74dc4ac8-749b-11df-b112-00215aee3ebe;Підгірці;Подгорцы;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623087403;3;1
74dc4ac9-749b-11df-b112-00215aee3ebe;Підгірці;Подгорцы;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625385201;3;1
74dc4aca-749b-11df-b112-00215aee3ebe;Підгірці;Подгорцы;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223186801;3;0
74dc4acb-749b-11df-b112-00215aee3ebe;Підгірці;Подгорцы;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624686708;3;1
74dc4ad2-749b-11df-b112-00215aee3ebe;Підгора;Подгора;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321885801;3;1
74dc4ad3-749b-11df-b112-00215aee3ebe;Підгора;Подгора;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125086607;3;1
74dc4ad4-749b-11df-b112-00215aee3ebe;Підгорб;Подгорб;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2124880304;3;1
74dc4ad5-749b-11df-b112-00215aee3ebe;Підгорівка;Подгоровка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322055107;3;1
74dc4ad8-749b-11df-b112-00215aee3ebe;Підгороддя;Подгородье;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624484401;3;1
74dc4ad9-749b-11df-b112-00215aee3ebe;Підгородище;Подгородище;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623386002;3;1
74dc4ada-749b-11df-b112-00215aee3ebe;Підгородна;Подгородная;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825455400;3;1
74dc4adb-749b-11df-b112-00215aee3ebe;Підгородне;Подгородное;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723310111;3;1
74dc4adc-749b-11df-b112-00215aee3ebe;Підгородне;Подгородное;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125286901;2;1
74dc4add-749b-11df-b112-00215aee3ebe;Підгородне;Подгородное;місто;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221411000;2;0
74dc4ade-749b-11df-b112-00215aee3ebe;Підгородне;Подгородное;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621885001;3;1
74dc4ae0-749b-11df-b112-00215aee3ebe;Підгородне;Подгородное;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820886002;3;1
8b30462e-b0b4-11e6-a7f1-001ec93df11f;Підгородне;Подгородное;місто;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221411000;2;1
74dc4ae1-749b-11df-b112-00215aee3ebe;Підгородці;Подгородцы;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624586401;3;1
74dc4ae2-749b-11df-b112-00215aee3ebe;Піддеревенка;Поддеревенка;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783213;3;1
74dc4ae3-749b-11df-b112-00215aee3ebe;Піддністряни;Подднестряны;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621587501;3;1
74dc4ae4-749b-11df-b112-00215aee3ebe;Піддовге;Поддолгое;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624880602;3;1
74dc4ae5-749b-11df-b112-00215aee3ebe;Піддубівка;Поддубовка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424187301;3;1
74dc4ae6-749b-11df-b112-00215aee3ebe;Піддубне;Поддубное;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624882403;3;1
74dc4ae8-749b-11df-b112-00215aee3ebe;Піддубне;Поддубное;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524083207;3;1
74dc4ae9-749b-11df-b112-00215aee3ebe;Піддубці;Поддубцы;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722885001;3;1
74dc4aea-749b-11df-b112-00215aee3ebe;Піддубці;Поддубцы;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823986701;3;1
74dc4aeb-749b-11df-b112-00215aee3ebe;Підзамочок;Подзамочек;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121285401;3;1
74dc4aec-749b-11df-b112-00215aee3ebe;Підзамче;Подзамче;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625886901;3;1
74dc4aed-749b-11df-b112-00215aee3ebe;Підзахаричі;Подзахарычи;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323583501;3;1
74dc4aee-749b-11df-b112-00215aee3ebe;Підзвіринець;Подзверинец;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620986601;3;1
74dc4aef-749b-11df-b112-00215aee3ebe;Підкамінь;Подкамень;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624485101;3;1
74dc4af0-749b-11df-b112-00215aee3ebe;Підкамінь;Подкамень;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;4620355400;3;0
74dc4af1-749b-11df-b112-00215aee3ebe;Підкормілля;Подкормилье;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155126;3;1
74dc4af3-749b-11df-b112-00215aee3ebe;Підлепичі;Подлепичи;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081307;3;1
74dc4af4-749b-11df-b112-00215aee3ebe;Підлиман;Подлиман;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321084001;3;1
74dc4af5-749b-11df-b112-00215aee3ebe;Підлипки;Подлипки;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625886911;3;1
74dc4af6-749b-11df-b112-00215aee3ebe;Підлипне;Подлипное;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5910490501;3;1
74dc4af7-749b-11df-b112-00215aee3ebe;Підлипці;Подлипцы;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621885201;3;1
74dc4b19-749b-11df-b112-00215aee3ebe;Підлисся;Подлысье;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4621880404;3;1
74dc4af8-749b-11df-b112-00215aee3ebe;Підліси;Подлесы;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722181208;3;1
74dc4af9-749b-11df-b112-00215aee3ebe;Підлісівка;Подлесовка;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524583803;3;1
74dc4afa-749b-11df-b112-00215aee3ebe;Підлісівка;Подлесовка;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525685502;3;1
74dc4afb-749b-11df-b112-00215aee3ebe;Підліски;Подлески;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724582903;3;1
74dc4afc-749b-11df-b112-00215aee3ebe;Підліски;Подлески;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622084601;3;1
74dc4afd-749b-11df-b112-00215aee3ebe;Підліски;Подлески;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621585003;3;1
74dc4afe-749b-11df-b112-00215aee3ebe;Підліски;Подлески;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621280402;3;1
74dc4aff-749b-11df-b112-00215aee3ebe;Підліски;Подлески;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622485101;3;1
74dc4b00-749b-11df-b112-00215aee3ebe;Підлісне;Подлесное;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123485602;3;1
74dc4b01-749b-11df-b112-00215aee3ebe;Підлісне;Подлесное;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120481804;3;1
74dc4b02-749b-11df-b112-00215aee3ebe;Підлісне;Подлесное;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124287501;3;1
74dc4b03-749b-11df-b112-00215aee3ebe;Підлісне;Подлесное;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223582809;3;1
74dc4b04-749b-11df-b112-00215aee3ebe;Підлісне;Подлесное;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824884601;3;1
74dc4b05-749b-11df-b112-00215aee3ebe;Підлісне;Подлесное;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323281008;3;1
74dc4b08-749b-11df-b112-00215aee3ebe;Підлісне;Подлесное;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825484605;3;1
74dc4b09-749b-11df-b112-00215aee3ebe;Підлісне;Подлесное;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422088401;3;1
74dc4b0a-749b-11df-b112-00215aee3ebe;Підлісне;Подлесное;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522486003;3;1
74dc4b0b-749b-11df-b112-00215aee3ebe;Підлісне;Подлесное;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522655502;3;1
74dc4b0c-749b-11df-b112-00215aee3ebe;Підлісне;Подлесное;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524181803;3;1
74dc4b0d-749b-11df-b112-00215aee3ebe;Підлісне;Подлесное;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520585001;3;1
74dc4b0e-749b-11df-b112-00215aee3ebe;Підлісне;Подлесное;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821587803;3;1
74dc4b0f-749b-11df-b112-00215aee3ebe;Підлісне;Подлесное;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621880605;3;1
17af7b14-ddb9-11df-9197-00215aee3ebe;Підлісне;Подлесное;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522287005;3;1
74dc4b10-749b-11df-b112-00215aee3ebe;Підлісний Веселець;Подлесный Веселец;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821288605;3;1
74dc4b11-749b-11df-b112-00215aee3ebe;Підлісний Мукарів;Подлесный Мукарив;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821887401;3;1
74dc4b12-749b-11df-b112-00215aee3ebe;Підлісний Олексинець;Подлесный Алексинец;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821286501;3;1
74dc4b13-749b-11df-b112-00215aee3ebe;Підлісний Ялтушків;Подлесный Ялтушков;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520284003;3;1
74dc4b14-749b-11df-b112-00215aee3ebe;Підліснівка;Подлесновка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786301;3;1
74dc4b15-749b-11df-b112-00215aee3ebe;Підлісся;Подлесье;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723384913;3;1
74dc4b16-749b-11df-b112-00215aee3ebe;Підлісся;Подлесье;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121285405;3;1
74dc4b17-749b-11df-b112-00215aee3ebe;Підлісся;Подлесье;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625884401;3;1
74dc4b18-749b-11df-b112-00215aee3ebe;Підлісся;Подлесье;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787401;3;1
74dc4b1a-749b-11df-b112-00215aee3ebe;Підлісся;Подлесье;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623081602;3;1
74dc4b1b-749b-11df-b112-00215aee3ebe;Підлісся;Подлесье;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221282013;3;1
74dc4b1c-749b-11df-b112-00215aee3ebe;Підлісся;Подлесье;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822780804;3;1
73e94c0c-ddc1-11df-9197-00215aee3ebe;Підлісся;Подлесье;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2624881305;3;1
74dc4b1d-749b-11df-b112-00215aee3ebe;Підлісці;Подлесцы;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123486202;3;1
74dc4b1e-749b-11df-b112-00215aee3ebe;Підлісці;Подлесцы;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822189203;3;1
74dc4b1f-749b-11df-b112-00215aee3ebe;Підлісці;Подлесцы;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623889805;3;1
74dc4b20-749b-11df-b112-00215aee3ebe;Підлозіївка;Подлозиевка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920384006;3;1
74dc4b21-749b-11df-b112-00215aee3ebe;Підлозці;Подлозцы;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623886701;3;1
74dc4b22-749b-11df-b112-00215aee3ebe;Підлуби;Подлуби;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625880403;3;1
74dc4b23-749b-11df-b112-00215aee3ebe;Підлуби;Подлуби;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821784601;3;1
74dc4b24-749b-11df-b112-00215aee3ebe;Підлужжя;Подлужье;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221010103;3;1
74dc4b25-749b-11df-b112-00215aee3ebe;Підлужжя;Подлужье;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625884201;3;0
74dc4b26-749b-11df-b112-00215aee3ebe;Підлужжя;Подлужье;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621686011;3;1
74dc4b27-749b-11df-b112-00215aee3ebe;Підлужне;Подлужное;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623486901;3;1
74dc4b28-749b-11df-b112-00215aee3ebe;Підманове;Подманово;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725785603;3;1
74dc4b29-749b-11df-b112-00215aee3ebe;Підмети;Пидметы;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;;3;1
74dc4b2a-749b-11df-b112-00215aee3ebe;Підмихайлівці;Подмихайловцы;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624484901;3;1
74dc4b2b-749b-11df-b112-00215aee3ebe;Підмихайля;Подмихайля;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622884802;3;1
74dc4b2c-749b-11df-b112-00215aee3ebe;Підмогилки;Подмогилки;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620983004;3;1
74dc4b2d-749b-11df-b112-00215aee3ebe;Підмонастир;Подмонастырь;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623381803;3;1
74dc4b2e-749b-11df-b112-00215aee3ebe;Підмонастирок;Подмонастырок;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623980605;3;1
74dc4b2f-749b-11df-b112-00215aee3ebe;Підмонастирок;Подмонастырок;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621288605;3;1
74dc4b30-749b-11df-b112-00215aee3ebe;Підмостичі;Подмостичи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625184107;3;1
74dc4b31-749b-11df-b112-00215aee3ebe;Підозірка;Подозирка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321381703;3;1
74dc4b32-749b-11df-b112-00215aee3ebe;Підок;Подок;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324285003;3;1
74dc4b33-749b-11df-b112-00215aee3ebe;Підопригори;Подопригоры;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922987301;3;1
74dc4b34-749b-11df-b112-00215aee3ebe;Підостапи;Подостапы;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822882003;3;1
74dc4b35-749b-11df-b112-00215aee3ebe;Підпечери;Подпечеры;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625884301;3;1
74dc4b36-749b-11df-b112-00215aee3ebe;Підпилип'я;Подпилипье;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120888104;3;1
74dc4b37-749b-11df-b112-00215aee3ebe;Підпилип'я;Подпилипье;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822486201;2;1
74dc4b38-749b-11df-b112-00215aee3ebe;Підпільне;Подпольное;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223282504;3;1
74dc4b39-749b-11df-b112-00215aee3ebe;Підплеша;Подплеша;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124484802;3;1
74dc4b3a-749b-11df-b112-00215aee3ebe;Підполоззя;Подполозье;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121584501;3;1
474670a6-f785-11e7-80cc-1c98ec135261;Підпорожнянка ;Подпорожнянка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;;3;1
74dc4b3b-749b-11df-b112-00215aee3ebe;Підріжжя;Подрожье;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722181209;3;1
74dc4b3c-749b-11df-b112-00215aee3ebe;Підріка;Подрека;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622780405;3;1
74dc4b3d-749b-11df-b112-00215aee3ebe;Підріччя;Подречье;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721486602;3;1
74dc4b3e-749b-11df-b112-00215aee3ebe;Підруда;Подруда;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125087303;3;1
74dc4b3f-749b-11df-b112-00215aee3ebe;Підруддя;Подрудье;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824286001;3;1
74dc4b40-749b-11df-b112-00215aee3ebe;Підрясне;Подрясное;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625887503;3;1
74dc4b41-749b-11df-b112-00215aee3ebe;Підсадки;Подсадки;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623685505;3;1
74dc4b43-749b-11df-b112-00215aee3ebe;Підсинівка;Подсыновка;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725085403;3;1
74dc4b44-749b-11df-b112-00215aee3ebe;Підсмереки;Подсмереки;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623382903;3;1
74dc4b45-749b-11df-b112-00215aee3ebe;Підсоснів;Подсоснов;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623684404;3;1
74dc4b46-749b-11df-b112-00215aee3ebe;Підставки;Подставки;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923284801;3;1
74dc4b47-749b-11df-b112-00215aee3ebe;Підставки;Подставки;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620681204;3;1
74dc4b48-749b-11df-b112-00215aee3ebe;Підставки;Подставки;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121588601;3;1
74dc4b4a-749b-11df-b112-00215aee3ebe;Підсулля;Подсулье;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983209;3;1
74dc4b4b-749b-11df-b112-00215aee3ebe;Підсухе;Подсухое;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621285105;3;1
73e94c0d-ddc1-11df-9197-00215aee3ebe;Підсухи;Подсухи;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624885502;3;1
74dc4b4c-749b-11df-b112-00215aee3ebe;Підтемне;Подтемное;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623685604;3;1
74dc4b4d-749b-11df-b112-00215aee3ebe;Підцаревичі;Подцаревичи;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;;3;1
74dc4b4e-749b-11df-b112-00215aee3ebe;Підцир'я;Подцирье;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721410102;3;1
74dc4b4f-749b-11df-b112-00215aee3ebe;Підцурків;Подцурков;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622681603;3;1
74dc4b50-749b-11df-b112-00215aee3ebe;Підчумаль;Подчумаль;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122480802;3;1
74dc4b51-749b-11df-b112-00215aee3ebe;Підшумлянці;Подшумлянцы;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621285802;3;1
74dc4b54-749b-11df-b112-00215aee3ebe;Пізнанка;Познанка;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121685801;3;1
74dc4b55-749b-11df-b112-00215aee3ebe;Пізнє;Позднее;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422081505;3;1
74dc4b56-749b-11df-b112-00215aee3ebe;Пізнопали;Познопалы;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424483003;3;1
74dc4b57-749b-11df-b112-00215aee3ebe;Пізняки;Позняки;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325180809;3;1
74dc4b58-749b-11df-b112-00215aee3ebe;Пії;Пии;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222985401;3;1
74dc4b59-749b-11df-b112-00215aee3ebe;Пійло;Пийло;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622885201;3;1
74dc4b5a-749b-11df-b112-00215aee3ebe;Піківець;Пикивец;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124386201;3;1
74dc4b5d-749b-11df-b112-00215aee3ebe;Пільний Мукарів;Польный Мукаров;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821881202;3;1
74dc4b5e-749b-11df-b112-00215aee3ebe;Пільний Олексинець;Пильный Алексинец;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821286801;3;1
74dc4b60-749b-11df-b112-00215aee3ebe;Пільце;Пильце;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622786708;3;1
74dc4b62-749b-11df-b112-00215aee3ebe;Пінчуки;Пинчуки;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221486001;3;1
74dc4b65-749b-11df-b112-00215aee3ebe;Піонерське;Пионерское;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922682207;3;1
74dc4b66-749b-11df-b112-00215aee3ebe;Піонерське;Пионерское;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323585504;3;1
74dc4b6c-749b-11df-b112-00215aee3ebe;Пірванче;Пирванче;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720886101;3;1
5cb619c3-749b-11df-b112-00215aee3ebe;Пірки (Комсомольське);Пірки (Комсомольське);село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321386403;3;1
74dc4b6d-749b-11df-b112-00215aee3ebe;Пірнове;Пирново;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221886801;3;1
74dc4b6f-749b-11df-b112-00215aee3ebe;Піски;Пески;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084201;3;1
74dc4b70-749b-11df-b112-00215aee3ebe;Піски;Пески;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920985401;3;1
74dc4b71-749b-11df-b112-00215aee3ebe;Піски;Пески;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924185403;3;1
74dc4b72-749b-11df-b112-00215aee3ebe;Піски;Пески;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920388004;3;1
74dc4b73-749b-11df-b112-00215aee3ebe;Піски;Пески;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720885801;3;1
74dc4b74-749b-11df-b112-00215aee3ebe;Піски;Пески;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610118;3;1
74dc4b75-749b-11df-b112-00215aee3ebe;Піски;Пески;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824882003;3;1
74dc4b77-749b-11df-b112-00215aee3ebe;Піски;Пески;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321288005;3;1
74dc4b78-749b-11df-b112-00215aee3ebe;Піски;Пески;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620383205;3;1
74dc4b79-749b-11df-b112-00215aee3ebe;Піски;Пески;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623685401;3;1
74dc4b7a-749b-11df-b112-00215aee3ebe;Піски;Пески;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620955304;3;1
74dc4b7d-749b-11df-b112-00215aee3ebe;Піски;Пески;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222083601;3;1
74dc4b7e-749b-11df-b112-00215aee3ebe;Піски;Пески;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822381204;3;1
74dc4b7f-749b-11df-b112-00215aee3ebe;Піски;Пески;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822085901;3;1
74dc4b80-749b-11df-b112-00215aee3ebe;Піски;Пески;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824786002;3;1
74dc4b81-749b-11df-b112-00215aee3ebe;Піски;Пески;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422480503;3;1
74dc4b82-749b-11df-b112-00215aee3ebe;Піски;Пески;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425586601;3;1
74dc4b83-749b-11df-b112-00215aee3ebe;Піски;Пески;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420381501;3;1
74dc4b84-749b-11df-b112-00215aee3ebe;Піски;Пески;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420685601;3;1
74dc4b85-749b-11df-b112-00215aee3ebe;Піски;Пески;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322684901;3;1
74dc4b86-749b-11df-b112-00215aee3ebe;Піски;Пески;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322881205;3;1
74dc4b87-749b-11df-b112-00215aee3ebe;Піски-Радьківські;Пески-Радьковские;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321083001;3;1
74dc4b8c-749b-11df-b112-00215aee3ebe;Піски-Річицькі;Пески-Речковые;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724286403;3;1
74dc4b88-749b-11df-b112-00215aee3ebe;Піски-Удайські;Пески-Удайские;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325183207;3;1
74dc4b89-749b-11df-b112-00215aee3ebe;Пісків;Песков;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623486501;3;1
74dc4b8a-749b-11df-b112-00215aee3ebe;Пісківка;Песковка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982409;3;1
74dc4b8b-749b-11df-b112-00215aee3ebe;Пісківка;Песковка;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221055600;3;1
74dc4b8e-749b-11df-b112-00215aee3ebe;Піскувате;Песковатое;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324883004;3;1
74dc4b8f-749b-11df-b112-00215aee3ebe;Пісок;Песок;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621886405;3;1
74dc4b90-749b-11df-b112-00215aee3ebe;Пісок;Песок;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622480804;3;1
74dc4b91-749b-11df-b112-00215aee3ebe;Пісоцький;Песоцкий;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625881909;3;1
74dc4b92-749b-11df-b112-00215aee3ebe;Пісочин;Песочин;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157900;2;0
74dc4b93-749b-11df-b112-00215aee3ebe;Пісочин;Песочин;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522282002;3;1
c6cc1c06-db7e-11df-9197-00215aee3ebe;Пісочин;Песочин;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320884504;3;1
74dc4b94-749b-11df-b112-00215aee3ebe;Пісочки;Песочки;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322681106;3;1
74dc4b95-749b-11df-b112-00215aee3ebe;Пісочна;Песочная;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623086401;3;1
74dc4b96-749b-11df-b112-00215aee3ebe;Пісочне;Песочное;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722187408;3;1
74dc4b97-749b-11df-b112-00215aee3ebe;Пісочне;Песочное;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624887803;3;1
74dc4b99-749b-11df-b112-00215aee3ebe;Пістинь;Пистинь;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623684401;3;1
74dc4b9a-749b-11df-b112-00215aee3ebe;Пістрялово;Пестрялово;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122786401;3;1
74dc4b9b-749b-11df-b112-00215aee3ebe;Пітушків;Петушков;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623886601;3;1
74dc4b9d-749b-11df-b112-00215aee3ebe;Пішки;Пешки;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122587101;3;1
74dc4b9e-749b-11df-b112-00215aee3ebe;Пішкове;Пешково;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923882808;3;1
74dc4b9f-749b-11df-b112-00215aee3ebe;Піща;Пища;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725783901;3;1
74dc4ba0-749b-11df-b112-00215aee3ebe;Піщана;Песчаная;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321381704;3;1
74dc4ba1-749b-11df-b112-00215aee3ebe;Піщана;Песчаная;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120686901;3;1
74dc4ba2-749b-11df-b112-00215aee3ebe;Піщана;Песчаная;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220484902;2;1
74dc4ba3-749b-11df-b112-00215aee3ebe;Піщана;Песчаная;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124086202;3;1
74dc4ba4-749b-11df-b112-00215aee3ebe;Піщана Петрівка;Песчаная Петровка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123582805;3;1
74dc4ba5-749b-11df-b112-00215aee3ebe;Піщане;Песчаное;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324283201;3;1
74dc4ba6-749b-11df-b112-00215aee3ebe;Піщане;Песчаное;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322483801;3;1
74dc4ba7-749b-11df-b112-00215aee3ebe;Піщане;Песчаное;село;9b486f64-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5910191501;2;1
74dc4ba8-749b-11df-b112-00215aee3ebe;Піщане;Песчаное;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920681002;3;1
74dc4ba9-749b-11df-b112-00215aee3ebe;Піщане;Песчаное;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721485901;3;1
74dc4baa-749b-11df-b112-00215aee3ebe;Піщане;Песчаное;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122688202;3;1
74dc4bab-749b-11df-b112-00215aee3ebe;Піщане;Песчаное;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224281208;3;1
74dc4bad-749b-11df-b112-00215aee3ebe;Піщане;Песчаное;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325482002;3;1
74dc4bb6-749b-11df-b112-00215aee3ebe;Піщане;Песчаное;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121588201;3;1
74dc4bb7-749b-11df-b112-00215aee3ebe;Піщане;Песчаное;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520886802;3;1
74dc4bb8-749b-11df-b112-00215aee3ebe;Піщани;Песчаны;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625388002;3;1
74dc4bb9-749b-11df-b112-00215aee3ebe;Піщаний Брід;Песчаный Брод;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521785301;3;1
74dc4bba-749b-11df-b112-00215aee3ebe;Піщаний Брід;Песчаный Брод;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821785602;3;1
74dc4bbb-749b-11df-b112-00215aee3ebe;Піщаний Брід;Песчаный Брод;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520383505;3;1
74dc4bbc-749b-11df-b112-00215aee3ebe;Піщаниця;Песчаница;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824285801;3;1
74dc4bbe-749b-11df-b112-00215aee3ebe;Піщанка;Песчанка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223285501;3;1
74dc4bc0-749b-11df-b112-00215aee3ebe;Піщанка;Песчанка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323385501;3;1
74dc4bc1-749b-11df-b112-00215aee3ebe;Піщанка;Песчанка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822085602;3;1
74dc4bc2-749b-11df-b112-00215aee3ebe;Піщанка;Песчанка;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425882002;3;1
74dc4bc3-749b-11df-b112-00215aee3ebe;Піщанка;Песчанка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;;3;1
74dc4bc4-749b-11df-b112-00215aee3ebe;Піщанка;Песчанка;місто;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523255100;3;1
74dc4bc5-749b-11df-b112-00215aee3ebe;Піщатинці;Пищатинцы;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125885601;3;1
74dc4bc6-749b-11df-b112-00215aee3ebe;Піщів;Пищов;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824084601;3;1
74dc4bce-749b-11df-b112-00215aee3ebe;Плав'я;Плавья;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624585901;3;1
74dc4bcf-749b-11df-b112-00215aee3ebe;Плав'я;Плавья;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124082305;3;1
74dc4bc7-749b-11df-b112-00215aee3ebe;Плавещина;Плавещина;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225685007;3;1
17af7b26-ddb9-11df-9197-00215aee3ebe;Плавещина;Плавещина;село;bad3ac3c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1223783805;3;1
74dc4bc8-749b-11df-b112-00215aee3ebe;Плавинище;Плавинище;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187301;3;1
74dc4bc9-749b-11df-b112-00215aee3ebe;Плавневе;Плавневое;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123981105;3;1
74dc4bca-749b-11df-b112-00215aee3ebe;Плавні;Плавни;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322081605;3;1
74dc4bcb-749b-11df-b112-00215aee3ebe;Плавні;Плавни;село;d00d3b62-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5124184001;3;1
74dc4bd0-749b-11df-b112-00215aee3ebe;Плай;Плай;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323586503;3;1
74dc4bd1-749b-11df-b112-00215aee3ebe;Пласківщина;Пласковщина;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322683404;3;1
74dc4bd2-749b-11df-b112-00215aee3ebe;Пластунівка;Пластуновка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324282503;3;1
74dc4bd4-749b-11df-b112-00215aee3ebe;Пластюки;Пластюки;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920387508;3;1
74dc4bd5-749b-11df-b112-00215aee3ebe;Платонівка;Платоновка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655118;3;1
74dc4bd8-749b-11df-b112-00215aee3ebe;Платонове;Платоново;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123180804;3;1
74dc4bd9-749b-11df-b112-00215aee3ebe;Плахівка;Плаховка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923880405;3;1
74dc4bdb-749b-11df-b112-00215aee3ebe;Плахтіївка;Плахтиевка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320686005;3;1
74dc4bdc-749b-11df-b112-00215aee3ebe;Плахтіївка;Плахтиевка;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124584901;3;1
74dc4bdd-749b-11df-b112-00215aee3ebe;Плахтянка;Плахтянка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222786801;3;1
74dc4bde-749b-11df-b112-00215aee3ebe;Плачинда;Плачинда;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124786407;3;1
74dc4bdf-749b-11df-b112-00215aee3ebe;Плаюць;Плаюц;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123682002;3;1
74dc4be0-749b-11df-b112-00215aee3ebe;Плебанівка;Плебановка;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125086401;3;1
74dc4be1-749b-11df-b112-00215aee3ebe;Плебанівка;Плебановка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525386201;3;1
74dc4be2-749b-11df-b112-00215aee3ebe;Племхоз;Племхоз;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
74dc4be3-749b-11df-b112-00215aee3ebe;Плеників;Плеников;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623382204;3;1
74dc4be4-749b-11df-b112-00215aee3ebe;Плесецьке;Плесецкое;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221486201;3;1
74dc4be5-749b-11df-b112-00215aee3ebe;Плескачі;Плескачи;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223380403;3;1
74dc4be6-749b-11df-b112-00215aee3ebe;Плескачівка;Плескачовка;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123786501;3;1
bd708e13-3a3a-11e3-9df6-003048d2b473;Плесківці;Плескивци;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122689503;3;1
74dc4be7-749b-11df-b112-00215aee3ebe;Плесна;Плесна;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825585801;3;1
74dc4be8-749b-11df-b112-00215aee3ebe;Плетений Ташлик;Плетеный Ташлык;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523185201;3;1
74dc4be9-749b-11df-b112-00215aee3ebe;Плетеничі;Плетеничи;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623382505;3;1
74dc4beb-749b-11df-b112-00215aee3ebe;Плетньове;Плетнево;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922985205;3;1
74dc4bec-749b-11df-b112-00215aee3ebe;Плехів;Плехов;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825684803;3;1
74dc4bed-749b-11df-b112-00215aee3ebe;Плехів;Плехов;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323684201;3;1
74dc4bee-749b-11df-b112-00215aee3ebe;Плехтіївка;Плехтиевка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424483004;3;1
74dc4bef-749b-11df-b112-00215aee3ebe;Плешевичі;Плешевичи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622485603;3;1
74dc4bf0-749b-11df-b112-00215aee3ebe;Плешкані;Плешканы;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121588401;3;1
74dc4bf1-749b-11df-b112-00215aee3ebe;Плещівка;Плещевка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822383802;3;1
74dc4bf3-749b-11df-b112-00215aee3ebe;Плиска;Плиска;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123884803;3;1
74dc4bf4-749b-11df-b112-00215aee3ebe;Плиски;Плиски;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420886001;3;1
74dc4bf5-749b-11df-b112-00215aee3ebe;Плисків;Плисков;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523484201;3;1
74dc4bf6-749b-11df-b112-00215aee3ebe;Плисове;Плисовое;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323985203;3;1
74dc4bf7-749b-11df-b112-00215aee3ebe;Плита;Плита;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323581003;3;1
74dc4bf8-749b-11df-b112-00215aee3ebe;Плісняни;Плесняны;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122687901;3;1
74dc4bf9-749b-11df-b112-00215aee3ebe;Пліхів;Плихов;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120489103;3;1
74dc4bfa-749b-11df-b112-00215aee3ebe;Плішивець;Плешивец;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320485301;3;1
74dc4bfb-749b-11df-b112-00215aee3ebe;Плішки;Плешки;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520555401;3;1
74dc4bfc-749b-11df-b112-00215aee3ebe;Пліщин;Плещин;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825586001;3;1
74dc4c00-749b-11df-b112-00215aee3ebe;Плодопітомник;Плодопитомник;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;;3;1
74dc4c03-749b-11df-b112-00215aee3ebe;Плоска;Плоская;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323583001;3;1
74dc4c04-749b-11df-b112-00215aee3ebe;Плоска;Плоская;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623380602;3;1
74dc4c05-749b-11df-b112-00215aee3ebe;Плоска;Плоская;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825285301;3;1
74dc4c06-749b-11df-b112-00215aee3ebe;Плоска;Плоская;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823985503;3;1
74dc4c07-749b-11df-b112-00215aee3ebe;Плоска;Плоская;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624686709;3;1
74dc4c08-749b-11df-b112-00215aee3ebe;Плоска;Плоская;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621685201;3;1
74dc4c09-749b-11df-b112-00215aee3ebe;Плоскановиця;Плоскановица;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122780803;3;1
74dc4c0a-749b-11df-b112-00215aee3ebe;Плоске;Плоское;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324283801;3;1
74dc4c0b-749b-11df-b112-00215aee3ebe;Плоске;Плоское;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725755106;3;1
74dc4c0c-749b-11df-b112-00215aee3ebe;Плоске;Плоское;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123485601;3;1
74dc4c0d-749b-11df-b112-00215aee3ebe;Плоске;Плоское;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123087803;3;1
74dc4c0e-749b-11df-b112-00215aee3ebe;Плоске;Плоское;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224580507;3;1
74dc4c10-749b-11df-b112-00215aee3ebe;Плоске;Плоское;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120687201;3;1
74dc4c11-749b-11df-b112-00215aee3ebe;Плоске;Плоское;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625183703;3;1
74dc4c12-749b-11df-b112-00215aee3ebe;Плоске;Плоское;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124084405;3;1
74dc4c13-749b-11df-b112-00215aee3ebe;Плоске;Плоское;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221286001;3;1
74dc4c14-749b-11df-b112-00215aee3ebe;Плоске;Плоское;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224484801;3;1
74dc4c15-749b-11df-b112-00215aee3ebe;Плоске;Плоское;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423884001;3;1
74dc4c16-749b-11df-b112-00215aee3ebe;Плоске;Плоское;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123783003;3;1
74dc4c17-749b-11df-b112-00215aee3ebe;Плоске;Плоское;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522885201;3;1
74dc4c18-749b-11df-b112-00215aee3ebe;Плоске;Плоское;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522281506;3;1
74dc4c19-749b-11df-b112-00215aee3ebe;Плоске;Плоское;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624286701;3;1
74dc4c1a-749b-11df-b112-00215aee3ebe;Плоский Потік;Плоский Поток;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124084406;3;1
74dc4c1b-749b-11df-b112-00215aee3ebe;Плоско-Забузьке;Плоско-Забужское;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524383001;3;1
74dc4c1c-749b-11df-b112-00215aee3ebe;Плоско-Таранівка;Плоско-Тарановка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224580508;3;1
74dc4c1f-749b-11df-b112-00215aee3ebe;Плотича;Плотыча;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123086501;3;1
74dc4c20-749b-11df-b112-00215aee3ebe;Плотича;Плотыча;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125286801;2;1
74dc4c21-749b-11df-b112-00215aee3ebe;Плотникове;Плотниково;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923888107;3;1
74dc4c22-749b-11df-b112-00215aee3ebe;Плоцьк;Плоцк;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120480703;3;1
74dc4c24-749b-11df-b112-00215aee3ebe;Площі;Площи;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323581503;3;1
74dc4c25-749b-11df-b112-00215aee3ebe;Плуг;Плуг;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420685204;3;1
871626b0-749b-11df-b112-00215aee3ebe;Плугатар;Плугатарь;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225884812;3;1
74dc4c27-749b-11df-b112-00215aee3ebe;Плугів;Плугов;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621885206;3;1
74dc4c28-749b-11df-b112-00215aee3ebe;Плужне;Плужное;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822185101;3;1
74dc4c29-749b-11df-b112-00215aee3ebe;Плужники;Плужники;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225582105;3;1
74dc4c2a-749b-11df-b112-00215aee3ebe;Плужникове;Плужниково;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924184102;3;1
74dc4c2b-749b-11df-b112-00215aee3ebe;Плюти;Плюты;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223110501;3;0
74dc4c2c-749b-11df-b112-00215aee3ebe;Плютинці;Плютинци;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;;3;1
74dc4c2d-749b-11df-b112-00215aee3ebe;Плющівка;Плющевка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610119;3;1
74dc4c2e-749b-11df-b112-00215aee3ebe;Пляківка;Пляковка;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121886003;3;1
74dc4c2f-749b-11df-b112-00215aee3ebe;Пляхова;Пляховая;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521486203;3;1
74dc4c30-749b-11df-b112-00215aee3ebe;Пляцина;Пляцына;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520280615;3;1
74dc4c31-749b-11df-b112-00215aee3ebe;Пляшева;Пляшева;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625886501;3;1
74dc4c32-749b-11df-b112-00215aee3ebe;Пляшівка;Пляшевка;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625888503;3;1
74dc4c33-749b-11df-b112-00215aee3ebe;Пльохів;Плехов;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425882502;3;1
74dc4c34-749b-11df-b112-00215aee3ebe;Пльохів;Плехов;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425587201;3;1
74dc4c35-749b-11df-b112-00215aee3ebe;Пнів;Пнев;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624085601;3;1
74dc4c36-749b-11df-b112-00215aee3ebe;Пнівне;Пневно;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721486201;3;1
74dc4c37-749b-11df-b112-00215aee3ebe;Пнікут;Пникут;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622485201;3;1
74dc4c38-749b-11df-b112-00215aee3ebe;Пнятин;Пнятын;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623381004;3;1
74dc4c39-749b-11df-b112-00215aee3ebe;Побережжя;Побережье;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625884101;3;1
74dc4c3a-749b-11df-b112-00215aee3ebe;Побережка;Побережка;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220683205;3;1
74dc4c3b-749b-11df-b112-00215aee3ebe;Побережне;Побережное;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620984903;3;1
74dc4c3c-749b-11df-b112-00215aee3ebe;Побережне;Побережное;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520685403;3;1
74dc4c3d-749b-11df-b112-00215aee3ebe;Побєда;Победа;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324587007;3;1
74dc4c46-749b-11df-b112-00215aee3ebe;Побиванка;Побиванка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;;3;1
74dc4c47-749b-11df-b112-00215aee3ebe;Побиванка;Побиванка;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923255105;3;1
74dc4c48-749b-11df-b112-00215aee3ebe;Побичі;Побычи;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824282102;3;1
74dc4c49-749b-11df-b112-00215aee3ebe;Побійна;Побойная;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120984603;3;1
74dc4c4a-749b-11df-b112-00215aee3ebe;Побірка;Поборка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523785401;3;1
74dc4c4b-749b-11df-b112-00215aee3ebe;Побіч;Побоч;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621886406;3;1
74dc4c4c-749b-11df-b112-00215aee3ebe;Побочіївка;Побочиевка;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425182507;3;1
74dc4c4d-749b-11df-b112-00215aee3ebe;Побужани;Побужаны;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620685063;3;1
74dc4c4e-749b-11df-b112-00215aee3ebe;Побузьке;Побужское;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521455500;3;1
74dc4c4f-749b-11df-b112-00215aee3ebe;Побук;Побук;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624587403;3;1
74dc4c50-749b-11df-b112-00215aee3ebe;Повал;Повал;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521080209;3;1
74dc4c51-749b-11df-b112-00215aee3ebe;Повергів;Повергов;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623084403;3;1
74dc4c52-749b-11df-b112-00215aee3ebe;Повідов;Повидов;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425581603;3;1
74dc4c53-749b-11df-b112-00215aee3ebe;Повітно;Повитное;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620986201;3;1
74dc4c54-749b-11df-b112-00215aee3ebe;Поводи;Поводы;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321885103;3;1
74dc4c57-749b-11df-b112-00215aee3ebe;Поворськ;Поворск;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722187401;3;1
74dc4c58-749b-11df-b112-00215aee3ebe;Повстанське;Повстанское;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5110590002;3;1
74dc4c59-749b-11df-b112-00215aee3ebe;Повстин;Повстин;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323881003;3;1
74dc4c5a-749b-11df-b112-00215aee3ebe;Повч;Повч;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822884401;3;1
74dc4c5b-749b-11df-b112-00215aee3ebe;Повча;Повча;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;Луцьк;3;1
74dc4c5c-749b-11df-b112-00215aee3ebe;Повчине;Повчино;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824084801;3;1
74dc4c5d-749b-11df-b112-00215aee3ebe;Погар;Погар;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624886703;3;1
74dc4c5e-749b-11df-b112-00215aee3ebe;Погар;Погар;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624585502;3;1
74dc4c5f-749b-11df-b112-00215aee3ebe;Погарисько;Погариско;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787301;3;1
74dc4c60-749b-11df-b112-00215aee3ebe;Погарщина;Погарщина;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322685301;3;1
74dc4c61-749b-11df-b112-00215aee3ebe;Погибляк;Погибляк;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122885001;3;1
74dc4c62-749b-11df-b112-00215aee3ebe;Погіньки;Погиньки;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155305;3;1
74dc4c63-749b-11df-b112-00215aee3ebe;Погірці;Погорцы;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624286601;3;1
74dc4c64-749b-11df-b112-00215aee3ebe;Погожа Криниця;Погожая Криница;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187601;3;1
74dc4c65-749b-11df-b112-00215aee3ebe;Погонівка;Погоновка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320480507;3;1
74dc4c66-749b-11df-b112-00215aee3ebe;Погоня;Погоня;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625884503;3;1
74dc4c67-749b-11df-b112-00215aee3ebe;Погоріла;Погорелая;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523785801;3;1
74dc4c69-749b-11df-b112-00215aee3ebe;Погоріле;Погорелое;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321780510;3;1
74dc4c6a-749b-11df-b112-00215aee3ebe;Погорілець;Погорелец;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624885503;3;1
74dc4c6b-749b-11df-b112-00215aee3ebe;Погорілівка;Погореловка;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922686803;3;1
74dc4c6c-749b-11df-b112-00215aee3ebe;Погорілівка;Погореловка;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321586801;3;1
74dc4c6d-749b-11df-b112-00215aee3ebe;Погорільці;Погорельцы;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621884903;3;1
74dc4c6e-749b-11df-b112-00215aee3ebe;Погорільці;Погорельцы;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424785001;3;1
74dc4c6f-749b-11df-b112-00215aee3ebe;Погорільці;Погорельцы;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125482502;3;1
74dc4c70-749b-11df-b112-00215aee3ebe;Погорільці;Погорельцы;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820380502;3;1
74dc4c71-749b-11df-b112-00215aee3ebe;Погребець;Погребец;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423355401;3;1
74dc4c72-749b-11df-b112-00215aee3ebe;Погреби;Погребы;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320686801;3;1
74dc4c73-749b-11df-b112-00215aee3ebe;Погреби;Погребы;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924186405;3;1
74dc4c75-749b-11df-b112-00215aee3ebe;Погреби;Погребы;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221286401;2;0
74dc4c76-749b-11df-b112-00215aee3ebe;Погреби;Погребы;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221486501;3;1
74dc4c77-749b-11df-b112-00215aee3ebe;Погреби;Погребы;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224684402;3;1
74dc4c78-749b-11df-b112-00215aee3ebe;Погреби;Погребы;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424187001;3;1
74dc4c79-749b-11df-b112-00215aee3ebe;Погреби;Погребы;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120688401;3;1
74dc4c7a-749b-11df-b112-00215aee3ebe;Погребище;Погребище;місто;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523410100;2;0
74dc4c7b-749b-11df-b112-00215aee3ebe;Погребище Друге станція;Погребище Вторая Станция;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523483803;3;1
74dc4c7c-749b-11df-b112-00215aee3ebe;Погребище Перше;Погребище Первое;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523480302;3;1
74dc4c7d-749b-11df-b112-00215aee3ebe;Погребівка;Погребовка;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624486203;3;1
5cb61aff-749b-11df-b112-00215aee3ebe;Погребки (Коротченкове);Погребки (Коротченково);село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925383701;3;1
74dc4c7e-749b-11df-b112-00215aee3ebe;Погребняки;Погребняки;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324586301;3;1
74dc4c7f-749b-11df-b112-00215aee3ebe;Погрібці;Погрибцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122684303;3;1
74dc4c80-749b-11df-b112-00215aee3ebe;Погулянка;Погулянка;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723182603;3;1
74dc4c81-749b-11df-b112-00215aee3ebe;Погулянка;Погулянка;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723683504;3;1
74dc4c82-749b-11df-b112-00215aee3ebe;Подвір'ївка;Подворьевка;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322088401;3;1
74dc4c83-749b-11df-b112-00215aee3ebe;Подвірки;Подворки;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322057602;2;0
74dc4c84-749b-11df-b112-00215aee3ebe;Подвірне;Подворное;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323085501;3;1
74dc4c85-749b-11df-b112-00215aee3ebe;Поди;Поды;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323983006;3;1
74dc4c86-749b-11df-b112-00215aee3ebe;Поди;Поды;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321586109;3;1
74dc4c88-749b-11df-b112-00215aee3ebe;Подидар;Подидар;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225855113;3;1
74dc4c89-749b-11df-b112-00215aee3ebe;Подимка;Подымка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524084307;3;1
74dc4c8a-749b-11df-b112-00215aee3ebe;Подимове;Подымово;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824282005;3;1
e0517ed5-db89-11df-9197-00215aee3ebe;Подимове;Подымово;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;3;1
74dc4c8b-749b-11df-b112-00215aee3ebe;Подин;Подин;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423086706;3;1
74dc4c8c-749b-11df-b112-00215aee3ebe;Подище;Подище;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424155401;3;1
74dc4c8d-749b-11df-b112-00215aee3ebe;Подібна;Подобная;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123186501;3;1
74dc4c8f-749b-11df-b112-00215aee3ebe;Поділ;Подол;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324580403;3;1
74dc4c90-749b-11df-b112-00215aee3ebe;Поділ;Подол;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281216;3;1
74dc4c92-749b-11df-b112-00215aee3ebe;Поділ;Подол;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920388005;3;1
74dc4c93-749b-11df-b112-00215aee3ebe;Поділ;Подол;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425186001;3;1
74dc4c94-749b-11df-b112-00215aee3ebe;Поділки;Подолки;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923284401;3;1
74dc4c95-749b-11df-b112-00215aee3ebe;Поділля;Подолье;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124686301;3;1
74dc4c96-749b-11df-b112-00215aee3ebe;Поділля;Подолье;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122087001;3;1
74dc4c97-749b-11df-b112-00215aee3ebe;Поділля;Подолье;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821783401;3;1
74dc4c98-749b-11df-b112-00215aee3ebe;Поділля;Подолье;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621255308;3;1
74dc4c99-749b-11df-b112-00215aee3ebe;Поділля;Подолье;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220286401;3;1
5cb61bd4-749b-11df-b112-00215aee3ebe;Подільськ;Подольск;місто;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122900000;2;0
17af7b11-ddb9-11df-9197-00215aee3ebe;Подільське;Подольское;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521083609;3;1
74dc4c9b-749b-11df-b112-00215aee3ebe;Подільське;Подольское;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121588501;3;1
74dc4c9c-749b-11df-b112-00215aee3ebe;Подільське;Подольское;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823084602;3;1
74dc4c9d-749b-11df-b112-00215aee3ebe;Подільське;Подольское;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822486301;3;1
5cb61726-749b-11df-b112-00215aee3ebe;Подільське (Кірове);Подільське (Кірове);село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523083801;3;1
74dc4c9e-749b-11df-b112-00215aee3ebe;Подільці;Подольцы;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125455105;3;1
74dc4c9f-749b-11df-b112-00215aee3ebe;Подільці;Подольцы;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624288204;3;1
74dc4ca0-749b-11df-b112-00215aee3ebe;Подішор;Подишор;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124482403;3;1
74dc4ca1-749b-11df-b112-00215aee3ebe;Подобовець;Подобовец;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122485202;3;1
74dc4ca2-749b-11df-b112-00215aee3ebe;Подове;Подовое;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885713;3;1
74dc4ca7-749b-11df-b112-00215aee3ebe;Подолівка;Подоловка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320485501;3;1
74dc4ca8-749b-11df-b112-00215aee3ebe;Подоляки;Подоляки;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422288403;3;1
74dc4ca9-749b-11df-b112-00215aee3ebe;Подоляни;Подоляны;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824481502;3;1
74dc4caa-749b-11df-b112-00215aee3ebe;Подоляни;Подоляны;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822486202;2;1
74dc4cab-749b-11df-b112-00215aee3ebe;Подоляни;Подоляны;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621282005;3;1
74dc4cac-749b-11df-b112-00215aee3ebe;Подолянка;Подолянка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821782503;3;1
74dc4cad-749b-11df-b112-00215aee3ebe;Подолянське;Подолянское;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821583204;3;1
74dc4cae-749b-11df-b112-00215aee3ebe;Подолянське;Подолянское;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820655105;3;1
da653f7b-dad5-11ea-80ca-000c29800ae7;Подолянці;Подолянцы;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825883601;3;1
74dc4caf-749b-11df-b112-00215aee3ebe;Подольох;Подолех;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325156401;3;1
74dc4cb0-749b-11df-b112-00215aee3ebe;Подорожнє;Подорожное;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621587804;3;1
74dc4cb1-749b-11df-b112-00215aee3ebe;Подорожнє;Подорожное;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625385601;3;1
74dc4cb2-749b-11df-b112-00215aee3ebe;Подорожнє;Подорожное;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525287601;3;1
74dc4cb3-749b-11df-b112-00215aee3ebe;Подорожня;Подорожня;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524885202;3;1
74dc4cb5-749b-11df-b112-00215aee3ebe;Подусів;Подусов;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623385801;3;1
74dc4cb6-749b-11df-b112-00215aee3ebe;Подусільна;Подусельна;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623385901;3;1
74dc4cb7-749b-11df-b112-00215aee3ebe;Поетин;Поетин;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425186003;3;1
74dc4cb8-749b-11df-b112-00215aee3ebe;Пожарки;Пожарки;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724585201;3;1
74dc4cb9-749b-11df-b112-00215aee3ebe;Пожарна Балка;Пожарная Балка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086207;3;1
74dc4cbb-749b-11df-b112-00215aee3ebe;Пожежа;Пожежа;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121288406;3;1
74dc4cbc-749b-11df-b112-00215aee3ebe;Пожня;Пожня;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921283201;3;1
74dc4cbd-749b-11df-b112-00215aee3ebe;Пожог;Пожог;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155127;3;1
74dc4cbe-749b-11df-b112-00215aee3ebe;Поздимир;Поздымир;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4623984401;3;1
74dc4cbf-749b-11df-b112-00215aee3ebe;Познанка Друга;Познанка Вторая;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123383605;3;1
74dc4cc0-749b-11df-b112-00215aee3ebe;Познанка Перша;Познанка Первая;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123383601;3;1
74dc4cc1-749b-11df-b112-00215aee3ebe;Познань;Познань;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625082604;3;1
74dc4cc3-749b-11df-b112-00215aee3ebe;Показне;Показное;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824581207;3;1
74dc4cc5-749b-11df-b112-00215aee3ebe;Показове;Показовое;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521786703;3;1
74dc4cc6-749b-11df-b112-00215aee3ebe;Показове;Показовое;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323281806;3;1
74dc4cc8-749b-11df-b112-00215aee3ebe;Покалів;Покалев;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824286201;3;1
74dc4cc9-749b-11df-b112-00215aee3ebe;Покащів;Покащов;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721885401;3;1
74dc4cca-749b-11df-b112-00215aee3ebe;Покостівка;Покостовка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822081502;3;1
74dc4ccb-749b-11df-b112-00215aee3ebe;Покотилівка;Покотиловка;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325158200;3;0
74dc4ccc-749b-11df-b112-00215aee3ebe;Покотилове;Покотилово;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523684601;3;1
74dc4ccd-749b-11df-b112-00215aee3ebe;Покошичі;Покошичи;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422286001;3;1
74dc4cce-749b-11df-b112-00215aee3ebe;Покощеве;Покощево;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821786403;3;1
74dc4ccf-749b-11df-b112-00215aee3ebe;Покощівка;Покощовка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822183903;3;1
74dc4cd0-749b-11df-b112-00215aee3ebe;Покришів;Покрышев;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820983901;3;1
74dc4cd1-749b-11df-b112-00215aee3ebe;Покрівці;Покровцы;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621555302;3;1
6ed81f54-749b-11df-b112-00215aee3ebe;Покров;Покров;місто;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1212100000;2;0
74dc4cd2-749b-11df-b112-00215aee3ebe;Покровка;Покровка;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922384201;3;1
74dc4cd3-749b-11df-b112-00215aee3ebe;Покровка;Покровка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055718;3;1
74dc4cd4-749b-11df-b112-00215aee3ebe;Покровка;Покровка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823084213;3;1
74dc4cd5-749b-11df-b112-00215aee3ebe;Покровка;Покровка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823684504;3;1
74dc4cd6-749b-11df-b112-00215aee3ebe;Покровка;Покровка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821783801;3;1
74dc4cd7-749b-11df-b112-00215aee3ebe;Покровка;Покровка;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;;3;1
74dc4cd8-749b-11df-b112-00215aee3ebe;Покровка;Покровка;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7324085603;3;1
74dc4cd9-749b-11df-b112-00215aee3ebe;Покровка;Покровка;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323280301;3;1
74dc4cda-749b-11df-b112-00215aee3ebe;Покровка;Покровка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123383501;3;1
74dc4cdb-749b-11df-b112-00215aee3ebe;Покровка;Покровка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121685806;3;1
74dc4cdc-749b-11df-b112-00215aee3ebe;Покровка;Покровка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123982605;3;1
74dc4ce0-749b-11df-b112-00215aee3ebe;Покровка;Покровка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424185503;3;1
74dc4ce1-749b-11df-b112-00215aee3ebe;Покровка;Покровка;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123485802;3;1
74dc4ce2-749b-11df-b112-00215aee3ebe;Покровка;Покровка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523684801;3;1
74dc4ce3-749b-11df-b112-00215aee3ebe;Покровка;Покровка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524955103;3;1
74dc4ce4-749b-11df-b112-00215aee3ebe;Покровка;Покровка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522885403;3;1
74dc4ce5-749b-11df-b112-00215aee3ebe;Покровка;Покровка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821289404;3;1
74dc4ce6-749b-11df-b112-00215aee3ebe;Покровська Багачка;Покровская Богачка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324885001;3;1
74dc4ce7-749b-11df-b112-00215aee3ebe;Покровське;Покровское;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321384401;3;1
74dc4ce8-749b-11df-b112-00215aee3ebe;Покровське;Покровское;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922688902;3;1
74dc4ce9-749b-11df-b112-00215aee3ebe;Покровське;Покровское;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222985501;3;1
74dc4cea-749b-11df-b112-00215aee3ebe;Покровське;Покровское;місто;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224200000;2;1
74dc4ceb-749b-11df-b112-00215aee3ebe;Покровське;Покровское;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825785002;3;1
74dc4cec-749b-11df-b112-00215aee3ebe;Покровське;Покровское;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;;3;1
74dc4cef-749b-11df-b112-00215aee3ebe;Покровське;Покровское;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424781702;3;1
74dc4cf0-749b-11df-b112-00215aee3ebe;Покровське;Покровское;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522586901;3;1
6ed81a97-749b-11df-b112-00215aee3ebe;Покровське;Покровское;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824484401;3;1
50c59234-749b-11df-b112-00215aee3ebe;Покровське (Гуляницьке);Покровське (Гуляницьке);село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822380801;3;1
56bdd223-749b-11df-b112-00215aee3ebe;Покровське (Жовтневе);Покровское (Жовтневое);село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324280901;3;1
56bdd23f-749b-11df-b112-00215aee3ebe;Покровське (Жовтневе);Покровське (Жовтневе);село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323385502;3;1
56bdd274-749b-11df-b112-00215aee3ebe;Покровське (Жовтневе);Покровское (Жовтневое);село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322883902;3;1
5cb619d4-749b-11df-b112-00215aee3ebe;Покровське (Комсомольське);Покровское (Комсомольское);село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824484401;3;1
62c3cf19-749b-11df-b112-00215aee3ebe;Покровське (Куйбишеве);Покровське (Куйбишеве);село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755145;3;1
74dc4cf1-749b-11df-b112-00215aee3ebe;Покровщина;Покровщина;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320883704;3;1
74dc4cf2-749b-11df-b112-00215aee3ebe;Покропивна;Покропивная;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123086901;3;1
74dc4cf3-749b-11df-b112-00215aee3ebe;Покутине;Покутино;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525382802;3;1
74dc4cf4-749b-11df-b112-00215aee3ebe;Покутинці;Покутинцы;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820687001;3;1
74dc4cf5-749b-11df-b112-00215aee3ebe;Полапи;Полапы;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723384914;3;1
74dc4cf6-749b-11df-b112-00215aee3ebe;Полезне;Полезное;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655119;3;1
74dc4cf8-749b-11df-b112-00215aee3ebe;Полив'яне;Полывяное;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323285601;3;1
74dc4cf7-749b-11df-b112-00215aee3ebe;Поливанівка;Поливановка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222386001;3;1
74dc4cf9-749b-11df-b112-00215aee3ebe;Полиці;Полицы;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721486401;3;1
74dc4cfa-749b-11df-b112-00215aee3ebe;Полиці;Полицы;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620888001;3;1
74dc4cfb-749b-11df-b112-00215aee3ebe;Поличинці;Поличинцы;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521486403;3;1
74dc4cfc-749b-11df-b112-00215aee3ebe;Полівська Гута;Полевская Гута;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821180203;3;1
74dc4cfd-749b-11df-b112-00215aee3ebe;Полівське;Полевское;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221086608;3;1
74dc4cfe-749b-11df-b112-00215aee3ebe;Полівці;Полевцы;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125586501;3;1
74dc4cff-749b-11df-b112-00215aee3ebe;Полівці;Полевцы;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621288405;3;1
74dc4d00-749b-11df-b112-00215aee3ebe;Полігон;Полигон;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823384101;3;1
74dc4d01-749b-11df-b112-00215aee3ebe;Полідарівка;Полидаровка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222084603;3;1
74dc4d02-749b-11df-b112-00215aee3ebe;Поліно-Осипенкове;Полино-Осипенковое;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121686009;3;1
74dc4d03-749b-11df-b112-00215aee3ebe;Полісся;Полесье;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421487301;3;1
74dc4d04-749b-11df-b112-00215aee3ebe;Полісся;Полесье;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424985502;3;1
74dc4d05-749b-11df-b112-00215aee3ebe;Поліське;Полесское;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924480905;3;1
74dc4d06-749b-11df-b112-00215aee3ebe;Поліське;Полесское;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725084001;3;1
74dc4d07-749b-11df-b112-00215aee3ebe;Поліське;Полесское;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824285806;3;1
74dc4d08-749b-11df-b112-00215aee3ebe;Поліське;Полесское;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386031;3;1
74dc4d09-749b-11df-b112-00215aee3ebe;Поліське;Полесское;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422010403;3;1
74dc4d0a-749b-11df-b112-00215aee3ebe;Поліське;Полесское;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822185401;3;1
74dc4d0b-749b-11df-b112-00215aee3ebe;Поліське;Полесское;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620487601;3;1
74dc4d0c-749b-11df-b112-00215aee3ebe;Політанки;Политанки;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525386401;3;1
74dc4d0d-749b-11df-b112-00215aee3ebe;Політвідділ;Политотдел;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
74dc4d0e-749b-11df-b112-00215aee3ebe;Політвідділовець;Политотделовец;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124380901;3;1
74dc4d0f-749b-11df-b112-00215aee3ebe;Політрудня;Политрудня;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421489603;3;1
628e56bd-9791-11e7-80ca-1c98ec135261;Полішпакове;Полишпаковое;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121683901;3;1
74dc497c-749b-11df-b112-00215aee3ebe;Полішпакове (Петрівське);Полішпакове (Петровское);село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121683901;3;1
74dc4d10-749b-11df-b112-00215aee3ebe;Поліянівка;Полияновка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824085001;3;1
74dc4d11-749b-11df-b112-00215aee3ebe;Полкова Микитівка;Полковая Никитовка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320887001;3;1
74dc4d13-749b-11df-b112-00215aee3ebe;Полковниче;Полковничье;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224284602;3;1
74dc4d14-749b-11df-b112-00215aee3ebe;Полова;Полова;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424188804;3;1
74dc4d15-749b-11df-b112-00215aee3ebe;Полове;Половое;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623984501;3;1
74dc4d16-749b-11df-b112-00215aee3ebe;Половецьке;Половецкое;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220685303;3;1
74dc4d17-749b-11df-b112-00215aee3ebe;Половецьке;Половецкое;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820884801;3;1
74dc4d18-749b-11df-b112-00215aee3ebe;Половинки;Половинки;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824282006;3;1
74dc4d1a-749b-11df-b112-00215aee3ebe;Половинники;Половинники;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824282609;3;1
74dc4d1b-749b-11df-b112-00215aee3ebe;Половинчик;Половинчик;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123485201;3;1
74dc4d1c-749b-11df-b112-00215aee3ebe;Половлі;Половли;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620888301;3;1
74dc4d1d-749b-11df-b112-00215aee3ebe;Пологи;Пологи;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323484401;3;1
74dc4d1e-749b-11df-b112-00215aee3ebe;Пологи;Пологи;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920386901;3;1
74dc4d1f-749b-11df-b112-00215aee3ebe;Пологи;Пологи;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885714;3;1
74dc4d22-749b-11df-b112-00215aee3ebe;Пологи;Пологи;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221486701;3;1
74dc4d23-749b-11df-b112-00215aee3ebe;Пологи;Пологи;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424187302;3;1
74dc4d24-749b-11df-b112-00215aee3ebe;Пологи;Пологи;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523786001;3;1
74dc4d26-749b-11df-b112-00215aee3ebe;Пологи-Вергуни;Пологи-Вергуны;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223385301;3;1
74dc4d27-749b-11df-b112-00215aee3ebe;Пологи-Низ;Пологи-Низ;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323480703;3;1
74dc4d28-749b-11df-b112-00215aee3ebe;Пологи-Чобітки;Пологи-Чоботки;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223384203;3;1
74dc4d29-749b-11df-b112-00215aee3ebe;Пологи-Яненки;Пологи-Яненки;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223385601;3;1
74dc4d2a-749b-11df-b112-00215aee3ebe;Положаї;Положаи;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223387402;3;1
74dc4d2b-749b-11df-b112-00215aee3ebe;Положеве;Положево;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725755107;3;1
74dc4d2c-749b-11df-b112-00215aee3ebe;Полонисте;Полонистое;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521486004;3;1
74dc4d2d-749b-11df-b112-00215aee3ebe;Полоничеве;Полоничево;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821784203;3;1
74dc4d2e-749b-11df-b112-00215aee3ebe;Полоничі;Полоничи;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620682003;3;1
74dc4d2f-749b-11df-b112-00215aee3ebe;Полонична;Полоничная;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622184801;3;1
74dc4d30-749b-11df-b112-00215aee3ebe;Полонка;Полонка;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881603;3;1
74dc4d31-749b-11df-b112-00215aee3ebe;Полонки;Полонки;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424189402;3;1
74dc4d32-749b-11df-b112-00215aee3ebe;Полонне;Полонное;місто;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823610100;2;0
74dc4d34-749b-11df-b112-00215aee3ebe;Полохачів;Полохачев;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824286206;3;1
74dc4d35-749b-11df-b112-00215aee3ebe;Полохівка;Полоховка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523182404;3;1
74dc4d36-749b-11df-b112-00215aee3ebe;Полошки;Полошки;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921584401;3;1
74dc4d37-749b-11df-b112-00215aee3ebe;Полствин;Полствин;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122086201;3;1
74dc4d38-749b-11df-b112-00215aee3ebe;Полтава;Полтава;місто;fbfb8c05-41be-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5310100000;1;0
74dc4d39-749b-11df-b112-00215aee3ebe;Полтава;Полтава;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325781504;3;1
74dc4d3b-749b-11df-b112-00215aee3ebe;Полтавка;Полтавка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922055305;3;1
74dc4d3c-749b-11df-b112-00215aee3ebe;Полтавка;Полтавка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225884813;3;1
74dc4d3d-749b-11df-b112-00215aee3ebe;Полтавка;Полтавка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825485501;3;1
74dc4d3e-749b-11df-b112-00215aee3ebe;Полтавка;Полтавка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824582604;3;1
74dc4d42-749b-11df-b112-00215aee3ebe;Полтавка;Полтавка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522885001;3;1
74dc4d43-749b-11df-b112-00215aee3ebe;Полтавобоголюбівка;Полтавобоголюбовка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224581009;3;1
74dc4d44-749b-11df-b112-00215aee3ebe;Полтавське;Полтавское;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222683308;3;1
74dc4d45-749b-11df-b112-00215aee3ebe;Полтавське;Полтавское;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323985502;3;1
74dc4d49-749b-11df-b112-00215aee3ebe;Полтва;Полтва;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620684801;3;1
74dc4d4a-749b-11df-b112-00215aee3ebe;Полуботки;Полуботки;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425589102;3;0
74dc4d4b-749b-11df-b112-00215aee3ebe;Полуднівка;Полудневка;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125484502;3;1
74dc4d4c-749b-11df-b112-00215aee3ebe;Полузір'я;Полузорье;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323484601;3;1
74dc4d4d-749b-11df-b112-00215aee3ebe;Полум'яне;Полымяное;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520886803;3;1
cdc44f39-dde8-11df-9197-00215aee3ebe;Полум'яне;Полымяное;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720510415;3;1
74dc4d4e-749b-11df-b112-00215aee3ebe;Полуничне;Полуничное;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625889003;3;1
74dc4d4f-749b-11df-b112-00215aee3ebe;Полуніївка;Полуниевка;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323684604;3;1
74dc4d50-749b-11df-b112-00215aee3ebe;Полупанівка;Полупановка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124687803;3;1
74dc4d52-749b-11df-b112-00215aee3ebe;Полюхне;Полюхно;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720886002;3;1
74dc4d53-749b-11df-b112-00215aee3ebe;Полюшкіне;Полюшкине;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423689305;3;1
74dc4d55-749b-11df-b112-00215aee3ebe;Поляківське;Поляковское;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323581504;3;1
74dc4d56-749b-11df-b112-00215aee3ebe;Поляна;Поляна;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;;3;1
74dc4d57-749b-11df-b112-00215aee3ebe;Поляна;Поляна;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725583405;3;1
74dc4d58-749b-11df-b112-00215aee3ebe;Поляна;Поляна;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222083507;3;1
74dc4d59-749b-11df-b112-00215aee3ebe;Поляна;Поляна;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825785003;3;1
74dc4d5a-749b-11df-b112-00215aee3ebe;Поляна;Поляна;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325086601;3;1
74dc4d5b-749b-11df-b112-00215aee3ebe;Поляна;Поляна;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321087404;3;1
74dc4d5c-749b-11df-b112-00215aee3ebe;Поляна;Поляна;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620985203;3;1
74dc4d5d-749b-11df-b112-00215aee3ebe;Поляна;Поляна;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623088414;3;1
74dc4d5e-749b-11df-b112-00215aee3ebe;Поляна;Поляна;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625186302;3;0
74dc4d5f-749b-11df-b112-00215aee3ebe;Поляна;Поляна;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124084401;3;0
74dc4d60-749b-11df-b112-00215aee3ebe;Поляна;Поляна;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125384003;3;1
74dc4d62-749b-11df-b112-00215aee3ebe;Поляна;Поляна;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423385204;3;1
74dc4d63-749b-11df-b112-00215aee3ebe;Поляна;Поляна;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525310101;3;1
74dc4d64-749b-11df-b112-00215aee3ebe;Поляна;Поляна;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825585604;3;1
74dc4d6b-749b-11df-b112-00215aee3ebe;Поляна;Поляна;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625483403;3;1
8cdd5cdd-de27-11df-9197-00215aee3ebe;Поляна;Поляна;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520886003;3;1
74dc4d65-749b-11df-b112-00215aee3ebe;Поляне;Поляное;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925087202;3;1
74dc4d66-749b-11df-b112-00215aee3ebe;Полянецьке;Полянецкое;село;6b07f835-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5124383201;3;1
74dc4d67-749b-11df-b112-00215aee3ebe;Полянецьке;Полянецкое;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124386401;3;1
74dc4d68-749b-11df-b112-00215aee3ebe;Поляни;Поляны;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122488103;3;1
74dc4d69-749b-11df-b112-00215aee3ebe;Поляни;Поляны;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621885501;3;1
74dc4d6a-749b-11df-b112-00215aee3ebe;Поляни;Поляны;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820985901;3;1
74dc4d6c-749b-11df-b112-00215aee3ebe;Поляни;Поляны;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620488001;3;1
74dc4d6d-749b-11df-b112-00215aee3ebe;Поляниця;Поляница;село;8067acf2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2611092001;3;1
74dc46f1-749b-11df-b112-00215aee3ebe;Поляниця (Буковель);Поляница;село;a1e9f9cf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2611092001;3;1
74dc4d6f-749b-11df-b112-00215aee3ebe;Полянка;Полянка;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820355103;3;1
74dc4d70-749b-11df-b112-00215aee3ebe;Полянка;Полянка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120884207;3;1
74dc4d71-749b-11df-b112-00215aee3ebe;Полянка;Полянка;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;;3;1
74dc4d72-749b-11df-b112-00215aee3ebe;Полянка;Полянка;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623684205;3;1
74dc4d73-749b-11df-b112-00215aee3ebe;Полянка;Полянка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822383604;3;1
74dc4d74-749b-11df-b112-00215aee3ebe;Полянка;Полянка;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820656300;3;1
74dc4d75-749b-11df-b112-00215aee3ebe;Полянки;Полянки;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620883003;3;1
74dc4d76-749b-11df-b112-00215aee3ebe;Полянська Гута;Полянская Гута;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123286202;3;1
74dc4d77-749b-11df-b112-00215aee3ebe;Полянь;Полянь;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823986801;3;1
74dc4d78-749b-11df-b112-00215aee3ebe;Поляхове;Поляхово;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824786001;3;1
74dc4d79-749b-11df-b112-00215aee3ebe;Польова;Полевая;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322080904;3;1
74dc4d7a-749b-11df-b112-00215aee3ebe;Польова Лисіївка;Полевая Лисеевка;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521683106;3;1
74dc4d7b-749b-11df-b112-00215aee3ebe;Польова Слобідка;Полевая Слободка;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825882803;3;1
74dc4d7c-749b-11df-b112-00215aee3ebe;Польова Слобідка;Полевая Слободка;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524583805;3;1
74dc4d7d-749b-11df-b112-00215aee3ebe;Польове;Полевое;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923584002;3;1
74dc4d7e-749b-11df-b112-00215aee3ebe;Польове;Полевое;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224856205;3;1
74dc4d7f-749b-11df-b112-00215aee3ebe;Польове;Полевое;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125481004;3;1
74dc4d80-749b-11df-b112-00215aee3ebe;Польове;Полевое;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120887707;3;1
74dc4d85-749b-11df-b112-00215aee3ebe;Польове;Полевое;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222985701;3;1
74dc4d86-749b-11df-b112-00215aee3ebe;Польове;Полевое;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424981004;3;1
74dc4d87-749b-11df-b112-00215aee3ebe;Польове;Полевое;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520282606;3;1
325c57fb-ddd2-11df-9197-00215aee3ebe;Польове;Полевое;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520581705;3;1
5c2dd8f2-6b45-11e5-93c7-003048d2b473;Польові Гринівці;Полевые Гриновцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825088102;3;1
74dc4d88-749b-11df-b112-00215aee3ebe;Помазани;Помазаны;село;903ccab3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122385902;3;1
74dc4d89-749b-11df-b112-00215aee3ebe;Помазанівка;Помазановка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225683008;3;1
4aba3c7c-749b-11df-b112-00215aee3ebe;Поминик (Вікторівка);Поминик (Вікторівка);село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123181001;3;1
74dc4d8a-749b-11df-b112-00215aee3ebe;Помірки;Померки;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922981604;3;1
74dc4d8b-749b-11df-b112-00215aee3ebe;Помірці;Помирцы;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121285802;3;1
74dc4d8c-749b-11df-b112-00215aee3ebe;Помічна;Помичная;місто;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521710300;3;1
74dc4d8d-749b-11df-b112-00215aee3ebe;Помічна село;Помична село;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524084801;3;1
74dc4d8e-749b-11df-b112-00215aee3ebe;Помлинів;Помлынов;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785214;3;1
74dc4d8f-749b-11df-b112-00215aee3ebe;Помоклі;Помокли;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223386001;3;1
74dc4d90-749b-11df-b112-00215aee3ebe;Помонята;Помонята;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624485001;3;1
74dc4d91-749b-11df-b112-00215aee3ebe;Поморяни;Поморяны;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621855600;3;1
74dc4d92-749b-11df-b112-00215aee3ebe;Понебель;Понебель;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624683306;3;1
74dc4d95-749b-11df-b112-00215aee3ebe;Пониква;Пониква;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620385301;3;1
74dc4d96-749b-11df-b112-00215aee3ebe;Пониковиця;Пониковица;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620385801;3;1
74dc4d97-749b-11df-b112-00215aee3ebe;Поничів;Поничев;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720582017;3;1
74dc4d98-749b-11df-b112-00215aee3ebe;Понінка;Понинка;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823655400;3;1
74dc4d99-749b-11df-b112-00215aee3ebe;Пономаренки;Пономаренки;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325183001;3;1
7ad94f93-749b-11df-b112-00215aee3ebe;Пономаренки (Радянське);Пономаренки (Радянское);село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921255101;3;1
74dc4d9a-749b-11df-b112-00215aee3ebe;Понора;Понора;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823988402;3;1
74dc4d9b-749b-11df-b112-00215aee3ebe;Понори;Поноры;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425382701;3;1
74dc4d9c-749b-11df-b112-00215aee3ebe;Понорниця;Понорница;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422255500;3;1
74dc4d9d-749b-11df-b112-00215aee3ebe;Понятівка;Понятовка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123984701;3;1
74dc4d9e-749b-11df-b112-00215aee3ebe;Понятівка;Понятовка;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520384502;3;1
74dc4d9f-749b-11df-b112-00215aee3ebe;Попаздра;Попаздра;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120885605;3;1
74dc4da1-749b-11df-b112-00215aee3ebe;Попасне;Попасное;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223286001;3;1
74dc4da2-749b-11df-b112-00215aee3ebe;Попасне;Попасное;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322882002;3;1
74dc4da7-749b-11df-b112-00215aee3ebe;Попелі;Попели;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621285901;3;1
74dc4da8-749b-11df-b112-00215aee3ebe;Попелів;Попелов;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625686904;3;1
74dc4da9-749b-11df-b112-00215aee3ebe;Попелівщина;Попелевщина;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920389205;3;1
74dc4daa-749b-11df-b112-00215aee3ebe;Попелюхи;Попелюхи;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522885601;3;1
74dc4dab-749b-11df-b112-00215aee3ebe;Попелюхи;Попелюхи;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523283403;3;1
74dc4dac-749b-11df-b112-00215aee3ebe;Попеляни;Попеляны;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623682603;3;1
74dc4dad-749b-11df-b112-00215aee3ebe;Попельнасте;Попельнастое;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520385501;3;1
74dc4daf-749b-11df-b112-00215aee3ebe;Попельники;Попельники;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625284101;3;1
74dc4db0-749b-11df-b112-00215aee3ebe;Поперечне;Поперечное;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223582810;3;1
74dc4db2-749b-11df-b112-00215aee3ebe;Попівка;Поповка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324884205;3;1
74dc4db3-749b-11df-b112-00215aee3ebe;Попівка;Поповка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321384801;3;1
74dc4db4-749b-11df-b112-00215aee3ebe;Попівка;Поповка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321081306;3;1
74dc4db5-749b-11df-b112-00215aee3ebe;Попівка;Поповка;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321683801;3;1
74dc4db6-749b-11df-b112-00215aee3ebe;Попівка;Поповка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922086601;3;1
74dc4db7-749b-11df-b112-00215aee3ebe;Попівка;Поповка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924182005;3;1
74dc4db8-749b-11df-b112-00215aee3ebe;Попівка;Поповка;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922384203;3;1
74dc4db9-749b-11df-b112-00215aee3ebe;Попівка;Поповка;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921283601;3;1
74dc4dba-749b-11df-b112-00215aee3ebe;Попівка;Поповка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221081007;3;1
74dc4dbb-749b-11df-b112-00215aee3ebe;Попівка;Поповка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323385701;3;1
74dc4dbc-749b-11df-b112-00215aee3ebe;Попівка;Поповка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320280404;3;1
74dc4dc2-749b-11df-b112-00215aee3ebe;Попівка;Поповка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423688501;3;1
74dc4dc3-749b-11df-b112-00215aee3ebe;Попівка;Поповка;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122086401;3;1
74dc4dc4-749b-11df-b112-00215aee3ebe;Попівка;Поповка;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123187001;3;1
74dc4dc5-749b-11df-b112-00215aee3ebe;Попівка;Поповка;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121286001;3;1
74dc4dc6-749b-11df-b112-00215aee3ebe;Попівка;Поповка;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123787001;3;1
74dc4dc7-749b-11df-b112-00215aee3ebe;Попівка;Поповка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522284801;3;1
74dc4dc8-749b-11df-b112-00215aee3ebe;Попівка;Поповка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521786704;3;1
74dc4dc9-749b-11df-b112-00215aee3ebe;Попівка;Поповка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522586603;3;1
74dc4dca-749b-11df-b112-00215aee3ebe;Попівка;Поповка;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524686201;3;1
74dc4dcb-749b-11df-b112-00215aee3ebe;Попівка;Поповка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323286001;3;1
c8674a91-dbb0-11df-9197-00215aee3ebe;Попівка;Поповка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320688904;3;1
74dc4dcc-749b-11df-b112-00215aee3ebe;Попівці;Поповцы;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123485901;3;1
74dc4dcd-749b-11df-b112-00215aee3ebe;Попівці;Поповцы;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122085403;3;1
74dc4dce-749b-11df-b112-00215aee3ebe;Попівці;Поповцы;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620386201;3;1
74dc4dcf-749b-11df-b112-00215aee3ebe;Попівці;Поповцы;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520284403;3;1
74dc4dd0-749b-11df-b112-00215aee3ebe;Попівці;Поповцы;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824286403;3;1
74dc4dd1-749b-11df-b112-00215aee3ebe;Попівці;Поповцы;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820986201;3;1
74dc4dd2-749b-11df-b112-00215aee3ebe;Попівці;Поповцы;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823084205;3;1
56bdd267-749b-11df-b112-00215aee3ebe;Попівці (Жовтневе);Поповке (Жовтневое);село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523485202;3;1
74dc4dd3-749b-11df-b112-00215aee3ebe;Попівщина;Поповщина;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921581904;3;1
74dc4dd4-749b-11df-b112-00215aee3ebe;Попівщина;Поповщина;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924180604;3;1
74dc4dd5-749b-11df-b112-00215aee3ebe;Попільне;Попельное;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820955104;3;1
74dc4dd6-749b-11df-b112-00215aee3ebe;Попільня;Попельня;місто;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824755100;2;0
74dc4dd7-749b-11df-b112-00215aee3ebe;Попільня;Попельня;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425888204;3;1
74dc4dd8-749b-11df-b112-00215aee3ebe;Поплави;Поплавы;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124610502;3;1
74dc4dd9-749b-11df-b112-00215aee3ebe;Поплави;Поплавы;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124886501;3;1
74dc4dda-749b-11df-b112-00215aee3ebe;Поплавка;Поплавка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122982504;3;1
74dc4ddb-749b-11df-b112-00215aee3ebe;Поплавка;Поплавка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121683006;3;1
74dc4ddc-749b-11df-b112-00215aee3ebe;Поплавка;Поплавка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825486203;3;1
74dc4ddd-749b-11df-b112-00215aee3ebe;Поплавники;Поплавники;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621281803;3;1
871625fc-749b-11df-b112-00215aee3ebe;Попова Гребля;Попова Гребля;село;78f453a8-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0525087501;3;1
74dc4dde-749b-11df-b112-00215aee3ebe;Попове;Попово;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281804;3;1
74dc4ddf-749b-11df-b112-00215aee3ebe;Попове;Попово;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323484801;3;1
74dc4de0-749b-11df-b112-00215aee3ebe;Попове;Попово;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224880516;3;1
74dc4de2-749b-11df-b112-00215aee3ebe;Попове;Попово;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;;3;1
74dc4de3-749b-11df-b112-00215aee3ebe;Поповичі;Поповичи;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155318;3;1
74dc4de4-749b-11df-b112-00215aee3ebe;Поповичі;Поповичи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622485601;3;1
74dc4de5-749b-11df-b112-00215aee3ebe;Поповичка;Поповичка;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425382801;3;1
74dc4de1-749b-11df-b112-00215aee3ebe;Попово;Попово;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120488001;3;1
74dc4de6-749b-11df-b112-00215aee3ebe;Поправка;Поправка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220485301;2;1
74dc4de7-749b-11df-b112-00215aee3ebe;Попружна;Попружна;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224284801;3;1
74dc4de8-749b-11df-b112-00215aee3ebe;Попудня;Попудня;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123485301;3;1
74dc4de9-749b-11df-b112-00215aee3ebe;Попутне;Попутное;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655125;3;1
74dc4dea-749b-11df-b112-00215aee3ebe;Порадівка;Порадовка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221486901;3;1
74dc4deb-749b-11df-b112-00215aee3ebe;Порик;Порик;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524885601;3;1
74dc4dec-749b-11df-b112-00215aee3ebe;Поріччя;Поречье;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821755120;3;1
74dc4ded-749b-11df-b112-00215aee3ebe;Поріччя;Поречье;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625886401;3;1
74dc4dee-749b-11df-b112-00215aee3ebe;Поріччя;Поречье;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620983606;3;1
74dc4df0-749b-11df-b112-00215aee3ebe;Поріччя-Грунтове;Поречье-Грунтовое;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620983607;3;1
74dc4df1-749b-11df-b112-00215aee3ebe;Поріччя-Задвірне;Поречье-Задворное;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620983605;3;1
74dc4df2-749b-11df-b112-00215aee3ebe;Пороги;Пороги;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620487601;3;1
74dc4df3-749b-11df-b112-00215aee3ebe;Пороги;Пороги;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525684001;3;1
74dc4df4-749b-11df-b112-00215aee3ebe;Порозове;Порозовое;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624688902;3;1
74dc4df5-749b-11df-b112-00215aee3ebe;Порозок;Порозок;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922386403;3;1
74dc4df6-749b-11df-b112-00215aee3ebe;Поромів;Поромов;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721184401;3;1
74dc4df7-749b-11df-b112-00215aee3ebe;Поромівка;Поромовка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821183601;3;1
74dc4df8-749b-11df-b112-00215aee3ebe;Пороскотень;Пороскотень;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221055501;3;1
74dc4df9-749b-11df-b112-00215aee3ebe;Порохань;Порохань;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924485203;3;1
74dc4dfb-749b-11df-b112-00215aee3ebe;Порохня;Порохня;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820986301;3;1
74dc4dfc-749b-11df-b112-00215aee3ebe;Порохова;Порохова;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
74dc4dfd-749b-11df-b112-00215aee3ebe;Порошково;Порошково;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123283001;3;1
74dc4dfe-749b-11df-b112-00215aee3ebe;Порскалівка;Порскалевка;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755146;3;1
74dc4dff-749b-11df-b112-00215aee3ebe;Порт;Порт;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122784206;3;1
74dc4e01-749b-11df-b112-00215aee3ebe;Портнівка;Портновка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081006;3;1
74dc4e03-749b-11df-b112-00215aee3ebe;Порубаї;Порубаи;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321883807;3;1
74dc4e04-749b-11df-b112-00215aee3ebe;Поруби;Порубы;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625884205;3;1
74dc4e05-749b-11df-b112-00215aee3ebe;Порубка;Порубка;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621883405;3;1
74dc4e06-749b-11df-b112-00215aee3ebe;Поруденко;Поруденко;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625884706;3;1
74dc4e07-749b-11df-b112-00215aee3ebe;Порухи;Порухи;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
74dc4e08-749b-11df-b112-00215aee3ebe;Поручин;Поручин;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120480605;3;1
74dc4e0a-749b-11df-b112-00215aee3ebe;Поршна;Поршна;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623685501;3;1
74dc4e0b-749b-11df-b112-00215aee3ebe;Посад;Посад;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924183207;3;1
74dc4e0c-749b-11df-b112-00215aee3ebe;Посада-Новоміська;Посада-Новомистская;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625183906;3;1
74dc4e0d-749b-11df-b112-00215aee3ebe;Посад-Покровське;Посад-Покровское;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520385001;3;1
74dc4e0e-749b-11df-b112-00215aee3ebe;Посвірж;Посвирж;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624455303;3;1
325c57fc-ddd2-11df-9197-00215aee3ebe;Поселянівка;Поселяновка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520587504;3;1
74dc4e10-749b-11df-b112-00215aee3ebe;Посива;Посыва;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624286002;3;1
74dc4e11-749b-11df-b112-00215aee3ebe;Посіч;Посич;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2625884601;3;1
74dc4e12-749b-11df-b112-00215aee3ebe;Посіч;Посич;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125382204;3;1
74dc4e13-749b-11df-b112-00215aee3ebe;Посників;Посников;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623887301;3;1
74dc4e14-749b-11df-b112-00215aee3ebe;Постав-Мука;Постав-Мука;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325183801;3;1
74dc4e15-749b-11df-b112-00215aee3ebe;Постійне;Постийное;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623487401;3;1
74dc4e16-749b-11df-b112-00215aee3ebe;Постолівка;Постоловка;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121686001;3;1
74dc4e17-749b-11df-b112-00215aee3ebe;Постолівка;Постоловка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820982602;3;1
74dc4e19-749b-11df-b112-00215aee3ebe;Постольне;Постольное;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786701;3;1
74dc4e18-749b-11df-b112-00215aee3ebe;Постоята;Постоята;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624085003;3;1
74dc4e1a-749b-11df-b112-00215aee3ebe;Поступель;Поступель;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724285801;3;1
74dc4e1b-749b-11df-b112-00215aee3ebe;Посуньки;Посуньки;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221084109;3;1
74dc4e1c-749b-11df-b112-00215aee3ebe;Посухів;Посухов;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120486001;3;1
74dc4e1d-749b-11df-b112-00215aee3ebe;Посухів;Посухов;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522883604;3;1
74dc4e1e-749b-11df-b112-00215aee3ebe;Посухівка;Посуховка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124386601;3;1
74dc4e1f-749b-11df-b112-00215aee3ebe;Посягва;Посягва;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621285901;3;1
74dc4e20-749b-11df-b112-00215aee3ebe;Поталіївка;Поталиевка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222082404;3;1
74dc4e21-749b-11df-b112-00215aee3ebe;Потапівка;Потаповка;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921586803;3;1
74dc4e22-749b-11df-b112-00215aee3ebe;Потаповичі;Потаповичи;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824282203;3;1
74dc4e23-749b-11df-b112-00215aee3ebe;Потапці;Потапцы;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122086601;3;1
74dc4e24-749b-11df-b112-00215aee3ebe;Поташ;Поташ;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123187201;3;1
74dc4e25-749b-11df-b112-00215aee3ebe;Поташ;Поташ;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124088601;3;1
74dc4e26-749b-11df-b112-00215aee3ebe;Поташенкове;Поташенково;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123981706;3;1
74dc4e27-749b-11df-b112-00215aee3ebe;Поташня;Поташня;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221082005;3;1
74dc4e28-749b-11df-b112-00215aee3ebe;Поташня;Поташня;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220687603;3;1
74dc4e29-749b-11df-b112-00215aee3ebe;Поташня;Поташня;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825084802;3;1
74dc4e2a-749b-11df-b112-00215aee3ebe;Поташня;Поташня;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122088204;3;1
74dc4e2b-749b-11df-b112-00215aee3ebe;Поташня;Поташня;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520484003;3;1
74dc4e2c-749b-11df-b112-00215aee3ebe;Потелич;Потелич;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787601;3;1
74dc4e2d-749b-11df-b112-00215aee3ebe;Потереба;Потереба;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823984703;3;1
74dc4e2e-749b-11df-b112-00215aee3ebe;Потеряйки;Потеряйки;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324282805;3;1
74dc4e2f-749b-11df-b112-00215aee3ebe;Потеряйки-Горові;Потеряйки-Горовые;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281509;3;1
74dc4e31-749b-11df-b112-00215aee3ebe;Потіївка;Потиевка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220485601;3;1
74dc4e32-749b-11df-b112-00215aee3ebe;Потіївка;Потиевка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825087801;3;1
74dc4e33-749b-11df-b112-00215aee3ebe;Потік;Поток;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123087140;3;1
74dc4e34-749b-11df-b112-00215aee3ebe;Потік;Поток;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624484501;3;1
74dc4e35-749b-11df-b112-00215aee3ebe;Потік;Поток;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625180905;3;1
74dc4e36-749b-11df-b112-00215aee3ebe;Потік;Поток;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122480402;3;1
74dc4e37-749b-11df-b112-00215aee3ebe;Потік;Поток;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222985901;3;1
74dc4e38-749b-11df-b112-00215aee3ebe;Потічок;Поточек;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324284201;3;1
74dc4e39-749b-11df-b112-00215aee3ebe;Потічок;Поточек;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625284301;3;1
74dc4e46-749b-11df-b112-00215aee3ebe;Потічок;Поточек;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125385604;3;1
74dc4e3a-749b-11df-b112-00215aee3ebe;Потоки;Потоки;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322484401;3;1
74dc4e3b-749b-11df-b112-00215aee3ebe;Потоки;Потоки;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055706;3;1
74dc4e3c-749b-11df-b112-00215aee3ebe;Потоки;Потоки;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222085009;3;1
74dc4e3d-749b-11df-b112-00215aee3ebe;Потоки;Потоки;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224483202;3;1
74dc4e3e-749b-11df-b112-00215aee3ebe;Потоки;Потоки;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122286001;3;1
74dc4e3f-749b-11df-b112-00215aee3ebe;Потоки;Потоки;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523084003;3;1
74dc4e40-749b-11df-b112-00215aee3ebe;Потоки;Потоки;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521084503;3;1
74dc4e41-749b-11df-b112-00215aee3ebe;Потопиха;Потопиха;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923284405;3;1
74dc4e42-749b-11df-b112-00215aee3ebe;Поториця;Поторица;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624885501;3;1
74dc4e43-749b-11df-b112-00215aee3ebe;Потоцьке;Потоцкое;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282520;3;1
5cb619d8-749b-11df-b112-00215aee3ebe;Потоцьківщина (Комсомольське);Потоцкивщина (Комсомольское);село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322683403;3;1
74dc4e44-749b-11df-b112-00215aee3ebe;Поточани;Поточаны;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120487007;3;1
74dc4e45-749b-11df-b112-00215aee3ebe;Поточище;Поточище;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621683401;3;1
74dc4e47-749b-11df-b112-00215aee3ebe;Потутори;Потуторы;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120486401;3;1
74dc4e48-749b-11df-b112-00215aee3ebe;Потуторів;Потуторов;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125886301;3;1
74dc4e49-749b-11df-b112-00215aee3ebe;Потуш;Потуш;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524587303;3;1
74dc4e4a-749b-11df-b112-00215aee3ebe;Потьомкине;Потемкино;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521855102;3;1
74dc4e4b-749b-11df-b112-00215aee3ebe;Похівка;Поховка;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620487801;3;1
74dc4e4c-749b-11df-b112-00215aee3ebe;Почаєвичі;Почаевичи;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621286062;3;1
74dc4e4d-749b-11df-b112-00215aee3ebe;Почаїв;Почаев;місто;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123410500;3;0
87162210-749b-11df-b112-00215aee3ebe;Почаївка;Почаевка;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320886601;3;1
74dc4e4e-749b-11df-b112-00215aee3ebe;Почапи;Почапы;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724283903;3;1
74dc4e4f-749b-11df-b112-00215aee3ebe;Почапи;Почапы;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723310112;3;1
74dc4e50-749b-11df-b112-00215aee3ebe;Почапи;Почапы;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621885801;3;1
74dc4e51-749b-11df-b112-00215aee3ebe;Почапинці;Почапинцы;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125287001;3;1
74dc4e52-749b-11df-b112-00215aee3ebe;Почапинці;Почапинцы;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122885201;3;1
74dc4e53-749b-11df-b112-00215aee3ebe;Почапинці;Почапинцы;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521084803;3;1
74dc4e54-749b-11df-b112-00215aee3ebe;Почапинці;Почапинцы;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825286401;2;1
74dc4e55-749b-11df-b112-00215aee3ebe;Почапки;Почапки;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624286901;3;1
74dc4e56-749b-11df-b112-00215aee3ebe;Почепин;Почепин;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222785202;3;1
74dc4e57-749b-11df-b112-00215aee3ebe;Почепці;Почепцы;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923884807;3;1
74dc4e59-749b-11df-b112-00215aee3ebe;Почечине;Почечино;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423387103;3;1
74dc4e5a-749b-11df-b112-00215aee3ebe;Почино-Софіївка;Почино-Софиевка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222386101;3;1
74dc4e5b-749b-11df-b112-00215aee3ebe;Почуйки;Почуйки;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824785501;3;1
74dc4e5d-749b-11df-b112-00215aee3ebe;Поштове;Почтовое;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825882804;3;1
74dc4e5e-749b-11df-b112-00215aee3ebe;Пояски;Пояски;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824486003;3;1
74dc4e5f-749b-11df-b112-00215aee3ebe;Правда;Правда;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755109;3;1
74dc4e61-749b-11df-b112-00215aee3ebe;Правда;Правда;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122555502;3;1
74dc4e63-749b-11df-b112-00215aee3ebe;Правдине;Правдино;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520385501;3;1
74dc4e65-749b-11df-b112-00215aee3ebe;Правдівка;Правдовка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825885201;3;1
74dc4e66-749b-11df-b112-00215aee3ebe;Правдюки;Правдюки;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187905;3;1
74dc4e67-749b-11df-b112-00215aee3ebe;Правилівка;Правиловка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
74dc4e69-749b-11df-b112-00215aee3ebe;Правобережна Сокілка;Правобережная Соколка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321884505;3;1
74dc4e6a-749b-11df-b112-00215aee3ebe;Правобережне;Правобережное;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221082205;3;1
871626b5-749b-11df-b112-00215aee3ebe;Прапор (Червоний Прапор);Прапор (Червоный Прапор);село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222086007;3;1
74dc4e6b-749b-11df-b112-00215aee3ebe;Працюки;Працюки;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621885603;3;1
74dc4e6c-749b-11df-b112-00215aee3ebe;Праця;Праця;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
74dc4e6d-749b-11df-b112-00215aee3ebe;Прачі;Прачи;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420886401;3;1
74dc4e6f-749b-11df-b112-00215aee3ebe;Прелесне;Прелестное;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157311;3;1
74dc4e71-749b-11df-b112-00215aee3ebe;Преображенівка;Преображеновка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320681004;3;1
74dc4e72-749b-11df-b112-00215aee3ebe;Преображенка;Преображенка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655118;3;1
74dc4e73-749b-11df-b112-00215aee3ebe;Преображенка;Преображенка;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955118;3;1
74dc4e74-749b-11df-b112-00215aee3ebe;Преображенка;Преображенка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222085501;3;1
74dc4e75-749b-11df-b112-00215aee3ebe;Преображенка;Преображенка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655126;3;1
74dc4e76-749b-11df-b112-00215aee3ebe;Преображенка;Преображенка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455123;3;1
74dc4e77-749b-11df-b112-00215aee3ebe;Преображенка;Преображенка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125486006;3;1
74dc4e78-749b-11df-b112-00215aee3ebe;Преображенка;Преображенка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121683007;3;1
74dc4e79-749b-11df-b112-00215aee3ebe;Преображенка;Преображенка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323987801;3;1
74dc4e7d-749b-11df-b112-00215aee3ebe;Преображенське;Преображенское;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755135;3;1
74dc4e82-749b-11df-b112-00215aee3ebe;Прибережне;Прибрежное;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120887703;3;1
74dc4e86-749b-11df-b112-00215aee3ebe;Прибережне;Прибрежное;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825285801;3;1
74dc4e87-749b-11df-b112-00215aee3ebe;Прибережне;Прибрежное;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523155101;3;1
74dc4e88-749b-11df-b112-00215aee3ebe;Прибилів;Прибылов;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625610110;3;1
74dc4e89-749b-11df-b112-00215aee3ebe;Прибинь;Прибынь;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623383602;3;1
74dc4e8a-749b-11df-b112-00215aee3ebe;Прибинь;Прибынь;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422487001;3;1
74dc4e8b-749b-11df-b112-00215aee3ebe;Прибитки;Прибытки;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824285204;3;1
74dc4e8c-749b-11df-b112-00215aee3ebe;Прибілля;Прибелье;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621582404;3;1
74dc4e8d-749b-11df-b112-00215aee3ebe;Прибірськ;Приборск;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222083901;3;1
74dc4e8e-749b-11df-b112-00215aee3ebe;Приборжавське;Приборжавское;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121986801;3;0
74dc4e8f-749b-11df-b112-00215aee3ebe;Приборівка;Приборовка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522285201;3;1
74dc4e90-749b-11df-b112-00215aee3ebe;Прибужани;Прибужаны;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083801;3;1
74dc4e91-749b-11df-b112-00215aee3ebe;Прибужани;Прибужаны;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622184901;3;1
74dc4e92-749b-11df-b112-00215aee3ebe;Прибужжя;Прибужье;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822784001;3;1
74dc4e93-749b-11df-b112-00215aee3ebe;Прибузьке;Прибужское;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823384201;3;1
74dc4e94-749b-11df-b112-00215aee3ebe;Прибузьке;Прибужское;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825182209;3;1
74dc4e95-749b-11df-b112-00215aee3ebe;Прибузьке;Прибужское;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520682806;3;0
87162617-749b-11df-b112-00215aee3ebe;Прибузьке;Прибуское;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825086703;3;1
74dc4e96-749b-11df-b112-00215aee3ebe;Прибуток;Прибуток;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825084205;3;1
74dc4e97-749b-11df-b112-00215aee3ebe;Привар;Привар;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824281307;3;1
74dc4e98-749b-11df-b112-00215aee3ebe;Привілля;Приволье;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921584801;3;1
74dc4e99-749b-11df-b112-00215aee3ebe;Привілля;Приволье;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222083009;3;1
74dc4e9a-749b-11df-b112-00215aee3ebe;Привілля;Приволье;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224284515;3;1
74dc4e9b-749b-11df-b112-00215aee3ebe;Привілля;Приволье;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823680306;3;1
74dc4e9c-749b-11df-b112-00215aee3ebe;Привілля;Приволье;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323982502;3;1
74dc4e9d-749b-11df-b112-00215aee3ebe;Привілля;Приволье;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684609;3;1
74dc4e9e-749b-11df-b112-00215aee3ebe;Привілля;Приволье;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121685807;3;1
74dc4ea5-749b-11df-b112-00215aee3ebe;Привілля;Приволье;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522580304;3;1
74dc4ea6-749b-11df-b112-00215aee3ebe;Привільне;Привольное;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223286004;3;1
74dc4ea7-749b-11df-b112-00215aee3ebe;Привільне;Привольное;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225086001;3;1
74dc4ea8-749b-11df-b112-00215aee3ebe;Привільне;Привольное;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222981003;3;1
74dc4ea9-749b-11df-b112-00215aee3ebe;Привільне;Привольное;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820685301;3;1
74dc4eaa-749b-11df-b112-00215aee3ebe;Привільне;Привольное;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121681903;3;1
74dc4eab-749b-11df-b112-00215aee3ebe;Привільне;Привольное;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321587001;3;1
74dc4eb7-749b-11df-b112-00215aee3ebe;Привільне;Привольное;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425883505;3;1
74dc4eb8-749b-11df-b112-00215aee3ebe;Привільне;Привольное;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521255409;3;1
74dc4eb9-749b-11df-b112-00215aee3ebe;Привільне;Привольное;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621685601;3;1
74dc4eba-749b-11df-b112-00215aee3ebe;Привільнянське;Привольнянское;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824582205;3;1
74dc4ebb-749b-11df-b112-00215aee3ebe;Привітів;Приветов;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823186001;3;1
74dc4ebc-749b-11df-b112-00215aee3ebe;Привітівка;Приветовка;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622285703;3;1
74dc4ebd-749b-11df-b112-00215aee3ebe;Привітне;Приветное;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722484601;3;1
74dc4ebe-749b-11df-b112-00215aee3ebe;Привітне;Приветное;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120887708;3;1
74dc4ebf-749b-11df-b112-00215aee3ebe;Привітне;Приветное;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322180405;3;1
74dc4ec5-749b-11df-b112-00215aee3ebe;Привітне;Приветное;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823481205;3;1
74dc4ec6-749b-11df-b112-00215aee3ebe;Привітне;Приветное;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422087103;3;1
74dc4ec7-749b-11df-b112-00215aee3ebe;Привітне;Приветное;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121588801;3;1
74dc4ec8-749b-11df-b112-00215aee3ebe;Привітне;Приветное;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522884805;3;1
74dc4ec9-749b-11df-b112-00215aee3ebe;Привітне;Приветное;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822482104;3;1
74dc4eca-749b-11df-b112-00215aee3ebe;Привітне;Приветное;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623884001;3;1
5cb619ad-749b-11df-b112-00215aee3ebe;Привітне (Комінтерн);Приветливое (Коминтерн);село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125183401;3;1
74dc49ba-749b-11df-b112-00215aee3ebe;Привітне (Петровського);Приветное (Петровского);село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120687402;3;1
74dc4ecb-749b-11df-b112-00215aee3ebe;Привовчанське;Приволчанское;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223587505;3;1
74dc4ecc-749b-11df-b112-00215aee3ebe;Привокзальне;Привокзальное;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925655106;3;1
74dc4ecd-749b-11df-b112-00215aee3ebe;Привокзальне;Привокзальное;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922088702;3;1
74dc4ece-749b-11df-b112-00215aee3ebe;Привокзальне;Привокзальное;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321256005;3;1
74dc4ecf-749b-11df-b112-00215aee3ebe;Приворіття;Приворотье;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820983801;3;1
74dc4ed0-749b-11df-b112-00215aee3ebe;Привороки;Привороки;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321086702;3;1
74dc4ed1-749b-11df-b112-00215aee3ebe;Привороття;Приворотье;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822486501;2;1
74dc4ed2-749b-11df-b112-00215aee3ebe;Привороття Друге;Приворотье Второе;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822481805;3;1
74dc4ed3-749b-11df-b112-00215aee3ebe;Пригарівка;Пригаровка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084601;3;1
74dc4ed4-749b-11df-b112-00215aee3ebe;Пригідь;Пригодь;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124488402;3;1
74dc4ed5-749b-11df-b112-00215aee3ebe;Пригір'я;Пригорье;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;6521884501;3;1
74dc4ed6-749b-11df-b112-00215aee3ebe;Пригоже;Пригожее;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320481509;3;1
74dc4ed7-749b-11df-b112-00215aee3ebe;Пригородок;Пригородок;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325086801;3;1
74dc4ed8-749b-11df-b112-00215aee3ebe;Придеснянське;Придеснянское;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422283303;3;1
74dc4eda-749b-11df-b112-00215aee3ebe;Придніпровське;Приднепровское;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222986001;3;1
74dc4edb-749b-11df-b112-00215aee3ebe;Придніпровське;Приднепровское;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322184604;3;1
74dc4edc-749b-11df-b112-00215aee3ebe;Придніпровське;Приднепровское;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520386002;3;1
74dc4edd-749b-11df-b112-00215aee3ebe;Придніпровське;Приднепровское;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125188001;3;1
74dc4ed9-749b-11df-b112-00215aee3ebe;Придніпрянське;Приднепрянское;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221484003;2;1
c8674a95-dbb0-11df-9197-00215aee3ebe;Придніпрянське (Дзержинське);Приднепрянское (Дзержинское);село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322484402;3;1
7ad94f8f-749b-11df-b112-00215aee3ebe;Придніпрянське (Радянське);Придніпрянське (Радянське);село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;5321886001;3;1
74dc4ede-749b-11df-b112-00215aee3ebe;Придністров'я;Приднестровье;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621281804;3;1
74dc4edf-749b-11df-b112-00215aee3ebe;Придністрянське;Приднестрянское;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525685503;3;1
74dc4ee0-749b-11df-b112-00215aee3ebe;Придолинівка;Придолиновка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321587304;3;1
74dc4ee1-749b-11df-b112-00215aee3ebe;Придонецьке;Придонецкое;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322883007;3;1
74dc4ee3-749b-11df-b112-00215aee3ebe;Придорожне;Придорожное;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621683505;3;1
74dc4ee4-749b-11df-b112-00215aee3ebe;Придорожнє;Придорожное;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323655506;3;1
74dc4ee9-749b-11df-b112-00215aee3ebe;Призове;Призовое;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225980515;3;1
74dc4eea-749b-11df-b112-00215aee3ebe;Приймівщина;Приймовщина;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323680406;3;1
74dc4eeb-749b-11df-b112-00215aee3ebe;Прикладники;Прикладники;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622286203;3;1
74dc4eec-749b-11df-b112-00215aee3ebe;Прикмище;Прикмыще;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621684302;3;1
74dc4eee-749b-11df-b112-00215aee3ebe;Прикордонна Улашівка;Приграничная Улашовка;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;;3;1
74dc4eef-749b-11df-b112-00215aee3ebe;Прикордонне;Прикордонное;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624284202;3;1
74dc4ef0-749b-11df-b112-00215aee3ebe;Прилбичі;Прилбичи;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625886701;3;1
74dc4ef1-749b-11df-b112-00215aee3ebe;Прилиманське;Прилиманское;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123783501;2;0
74dc4ef2-749b-11df-b112-00215aee3ebe;Прилипче;Прилипче;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321587201;3;1
74dc4ef3-749b-11df-b112-00215aee3ebe;Приліпка;Прилипка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084701;3;1
74dc4ef5-749b-11df-b112-00215aee3ebe;Прилісне;Прилесное;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723685501;3;1
74dc4ef6-749b-11df-b112-00215aee3ebe;Прилужжя;Прилужье;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922088402;3;1
74dc4ef7-749b-11df-b112-00215aee3ebe;Прилужне;Прилужное;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525680402;3;1
74dc4ef8-749b-11df-b112-00215aee3ebe;Прилужне;Прилужное;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823080406;3;1
74dc4efa-749b-11df-b112-00215aee3ebe;Прилуки;Прилуки;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824286707;3;1
74dc4efb-749b-11df-b112-00215aee3ebe;Прилуки;Прилуки;місто;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;7410700000;2;0
74dc4efd-749b-11df-b112-00215aee3ebe;Прилуцьке;Прилуцкое;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721885801;3;0
74dc4efe-749b-11df-b112-00215aee3ebe;Прилуцьке;Прилуцкое;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521610109;3;1
1980b551-8357-11e8-80d4-1c98ec135261;Приміське;Пригородское;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222983901;3;1
74dc4f02-749b-11df-b112-00215aee3ebe;Приморське;Приморское;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120885601;3;1
74dc4f03-749b-11df-b112-00215aee3ebe;Приморське;Приморское;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125083901;2;1
74dc4f04-749b-11df-b112-00215aee3ebe;Приморське;Приморское;село;903ccab3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122383001;3;1
6ed81a0e-749b-11df-b112-00215aee3ebe;Приморське (Новокомсомольське);Приморское (Новокомсомольское);село;64024b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324655103;3;1
74dc4f0b-749b-11df-b112-00215aee3ebe;Примощаниця;Примощаница;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520280403;3;1
74dc4f0c-749b-11df-b112-00215aee3ebe;Принада;Принада;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625888204;3;1
74dc4f0d-749b-11df-b112-00215aee3ebe;Принцеве;Принцево;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755147;3;1
74dc4f0f-749b-11df-b112-00215aee3ebe;Приозерне;Приозерное;село;903ccab3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122383401;3;1
74dc4f12-749b-11df-b112-00215aee3ebe;Приозерне;Приозерное;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624485203;3;1
74dc4f14-749b-11df-b112-00215aee3ebe;Приозерне;Приозерное;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424185103;3;1
5619c640-8356-11e8-80d4-1c98ec135261;Приорільське;Приорилске;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222384008;3;1
74dc4f19-749b-11df-b112-00215aee3ebe;Прип'ять;Припять;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725755108;3;1
74dc4f15-749b-11df-b112-00215aee3ebe;Припруття;Припрутье;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323085701;3;1
74dc4f16-749b-11df-b112-00215aee3ebe;Припутенка;Припутенка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822180403;3;1
74dc4f17-749b-11df-b112-00215aee3ebe;Припутні;Припутни;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421787601;3;1
74dc4f18-749b-11df-b112-00215aee3ebe;Припутні;Припутни;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822180404;3;1
74dc4f1b-749b-11df-b112-00215aee3ebe;Приріччя;Приречье;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723387204;3;1
74dc4f1c-749b-11df-b112-00215aee3ebe;Присеймів'я;Присеймивье;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922086901;3;1
74dc4f1e-749b-11df-b112-00215aee3ebe;Присівці;Присовцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122686007;3;1
74dc4f1f-749b-11df-b112-00215aee3ebe;Приски;Приски;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625880403;3;1
74dc4f20-749b-11df-b112-00215aee3ebe;Присліп;Прислоп;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625586701;3;1
74dc4f21-749b-11df-b112-00215aee3ebe;Присліп;Прислоп;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122485601;3;1
74dc4f22-749b-11df-b112-00215aee3ebe;Прислуч;Прислуч;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823686201;3;1
74dc4f23-749b-11df-b112-00215aee3ebe;Прислуч;Прислуч;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620488301;3;1
74dc4f24-749b-11df-b112-00215aee3ebe;Пристайлове;Пристайлово;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922987801;3;1
74dc4f25-749b-11df-b112-00215aee3ebe;Пристанційне;Пристанционное;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323483408;3;1
74dc4f26-749b-11df-b112-00215aee3ebe;Пристанційне;Пристанционное;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823455401;3;1
74dc4f27-749b-11df-b112-00215aee3ebe;Пристань;Пристань;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5910290007;3;1
74dc4f28-749b-11df-b112-00215aee3ebe;Пристань;Пристань;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624880603;3;1
74dc4f29-749b-11df-b112-00215aee3ebe;Пристань;Пристань;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324885407;3;1
74dc4f2a-749b-11df-b112-00215aee3ebe;Пристень;Пристень;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224886809;3;1
74dc4f2d-749b-11df-b112-00215aee3ebe;Присторонь;Присторонь;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424482803;3;1
74dc4f2e-749b-11df-b112-00215aee3ebe;Пристроми;Пристромы;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223386401;3;1
74dc4f2f-749b-11df-b112-00215aee3ebe;Притисянське;Притисянское;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121285302;3;1
7ad94dfe-749b-11df-b112-00215aee3ebe;Притулівка;Притуловка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821885603;3;1
7ad94dff-749b-11df-b112-00215aee3ebe;Прихід;Приход;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824055611;3;1
7ad94e00-749b-11df-b112-00215aee3ebe;Прихідьки;Приходьки;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323882602;3;1
7ad94e03-749b-11df-b112-00215aee3ebe;Причепівка;Причеповка;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121880403;3;1
7ad94e04-749b-11df-b112-00215aee3ebe;Причепівка;Причеповка;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825280802;3;1
7ad94e05-749b-11df-b112-00215aee3ebe;Причіл;Причил;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624080604;3;1
7ad94e06-749b-11df-b112-00215aee3ebe;Пришиб;Пришиб;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755148;3;1
7ad94e07-749b-11df-b112-00215aee3ebe;Пришиб;Пришиб;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322484801;3;1
7ad94e08-749b-11df-b112-00215aee3ebe;Пришиб;Пришиб;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755136;3;1
7ad94e09-749b-11df-b112-00215aee3ebe;Пришиб;Пришиб;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821180707;3;1
7ad94e0a-749b-11df-b112-00215aee3ebe;Пришиб;Пришиб;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320285201;3;1
7ad94e0e-749b-11df-b112-00215aee3ebe;Пришивальня;Пришивальня;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224985701;3;1
7ad94e0f-749b-11df-b112-00215aee3ebe;Пришляки;Пришляки;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623684906;3;1
7ad94e10-749b-11df-b112-00215aee3ebe;Прищепівка;Прищеповка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821481303;3;1
7ad94e11-749b-11df-b112-00215aee3ebe;Прищепівка;Прищеповка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523855401;3;1
7ad94e12-749b-11df-b112-00215aee3ebe;Приют;Приют;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222386501;3;1
7ad94e13-749b-11df-b112-00215aee3ebe;Приют;Приют;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222985006;3;1
7ad94e14-749b-11df-b112-00215aee3ebe;Приют;Приют;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823082303;3;1
7ad94e16-749b-11df-b112-00215aee3ebe;Приют;Приют;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523681206;3;1
7ad94e17-749b-11df-b112-00215aee3ebe;Приют;Приют;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524085201;3;1
7ad94e18-749b-11df-b112-00215aee3ebe;Приютівка;Приютовка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520355800;3;1
7ad94e19-749b-11df-b112-00215aee3ebe;Приютівка-1;Приютовка-1;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;;3;1
7ad94e1c-749b-11df-b112-00215aee3ebe;Пробабин;Пробабин;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621685502;3;1
7ad94e1d-749b-11df-b112-00215aee3ebe;Пробіжна;Пробежная;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125586701;3;1
7ad94e1e-749b-11df-b112-00215aee3ebe;Пробійнівка;Пробойновка;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620886501;3;1
7ad94e22-749b-11df-b112-00215aee3ebe;Провалівка;Проваловка;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823185702;3;1
7ad94e24-749b-11df-b112-00215aee3ebe;Прогрес;Прогресс;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321886606;3;1
7ad94e25-749b-11df-b112-00215aee3ebe;Прогрес;Прогресс;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922681706;3;1
7ad94e26-749b-11df-b112-00215aee3ebe;Прогрес;Прогресс;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225482905;3;1
7ad94e27-749b-11df-b112-00215aee3ebe;Прогрес;Прогресс;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323580309;3;1
7ad94e28-749b-11df-b112-00215aee3ebe;Прогрес;Прогресс;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320256003;3;1
7ad94e2b-749b-11df-b112-00215aee3ebe;Прогрес;Прогресс;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424787008;3;1
7ad94e2c-749b-11df-b112-00215aee3ebe;Прогрес;Прогресс;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423084505;3;1
7ad94e2d-749b-11df-b112-00215aee3ebe;Прогрес;Прогресс;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422088501;3;1
7ad94e2e-749b-11df-b112-00215aee3ebe;Прогрес;Прогресс;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521186802;3;1
3aa010cb-12ac-11e2-86ef-003048d2b473;Прогрес;Прогрес;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;;3;1
5cb61cc1-749b-11df-b112-00215aee3ebe;Прогрес (Красний Прогрес);Прогресс (Красный Прогресс);село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924410102;3;1
7ad94e2f-749b-11df-b112-00215aee3ebe;Прогресівка;Прогрессовка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820985004;3;1
7ad94e30-749b-11df-b112-00215aee3ebe;Продубіївка;Продубиевка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510122;3;1
7ad94e34-749b-11df-b112-00215aee3ebe;Прокопенкове;Прокопенково;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323583204;3;1
7ad94e35-749b-11df-b112-00215aee3ebe;Прокопівка;Прокоповка;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324210105;3;1
7ad94e37-749b-11df-b112-00215aee3ebe;Прокопівка;Прокоповка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423680803;3;1
7ad94e38-749b-11df-b112-00215aee3ebe;Прокурава;Прокурава;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623684003;3;1
b36f1a3b-db87-11df-9197-00215aee3ebe;Пролетар;Пролетар;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322686509;3;1
7ad94e3f-749b-11df-b112-00215aee3ebe;Пролетарська Рудня;Пролетарская Рудня;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424485607;3;1
7ad94e41-749b-11df-b112-00215aee3ebe;Пролетарське;Пролетарское;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222386801;3;1
7ad94e42-749b-11df-b112-00215aee3ebe;Пролетарське;Пролетарское;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;*122078771;3;1
7ad94e48-749b-11df-b112-00215aee3ebe;Пролетарське;Пролетарское;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422286501;3;1
7ad94e4d-749b-11df-b112-00215aee3ebe;Проліски;Пролиски;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220888003;2;0
7ad94e4e-749b-11df-b112-00215aee3ebe;Проліски;Пролиски;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421782407;3;1
7ad94e51-749b-11df-b112-00215aee3ebe;Промінь;Проминь;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722885101;3;1
7ad94e52-749b-11df-b112-00215aee3ebe;Промінь;Проминь;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222088601;3;1
7ad94e53-749b-11df-b112-00215aee3ebe;Промінь;Проминь;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
9e945b59-49c7-11eb-80d0-000c29800ae7;Промінь;Проминь;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225083509;3;1
871626ba-749b-11df-b112-00215aee3ebe;Промінь (Червоний Промінь);Промінь (Червоний Промінь);село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825780707;3;1
7ad94e59-749b-11df-b112-00215aee3ebe;Проні;Прони;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321055102;3;1
7ad94e5a-749b-11df-b112-00215aee3ebe;Пронозівка;Пронозовка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320687201;3;1
7ad94e5b-749b-11df-b112-00215aee3ebe;Пропашне;Пропашное;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225055410;3;1
7ad94e5c-749b-11df-b112-00215aee3ebe;Просівці;Просовцы;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124680704;3;1
7ad94e5d-749b-11df-b112-00215aee3ebe;Просіка;Просека;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321088503;3;1
7ad94e5e-749b-11df-b112-00215aee3ebe;Просіка;Просека;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821787307;3;1
7ad94e5f-749b-11df-b112-00215aee3ebe;Проскури;Проскуры;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321885405;3;1
7ad94e60-749b-11df-b112-00215aee3ebe;Проскурівка;Проскуровка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825885601;3;1
7ad94e61-749b-11df-b112-00215aee3ebe;Просокиряни;Просокиряны;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321088504;3;1
7ad94e65-749b-11df-b112-00215aee3ebe;Просторе;Просторное;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523482403;3;1
7ad94e67-749b-11df-b112-00215aee3ebe;Просяна;Просяная;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224255400;3;1
7ad94e68-749b-11df-b112-00215aee3ebe;Просяне;Просяное;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324284001;3;1
7ad94e6a-749b-11df-b112-00215aee3ebe;Просяниківка;Просяниковка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321886404;3;1
7ad94e6c-749b-11df-b112-00215aee3ebe;Протеси;Протесы;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621585303;3;1
7ad94e6d-749b-11df-b112-00215aee3ebe;Противень;Противень;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125386202;3;1
7ad94e6e-749b-11df-b112-00215aee3ebe;Протопопівка;Протопоповка;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322081001;3;1
7ad94e6f-749b-11df-b112-00215aee3ebe;Протопопівка;Протопоповка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320285601;3;1
7ad94e70-749b-11df-b112-00215aee3ebe;Протопопівка;Протопоповка;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121080304;3;1
7ad94e71-749b-11df-b112-00215aee3ebe;Протопопівка;Протопоповка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520386201;3;1
7ad94e72-749b-11df-b112-00215aee3ebe;Протопопівщина;Протопоповщина;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983210;3;1
7ad94e73-749b-11df-b112-00215aee3ebe;Проточі;Проточи;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225683602;3;1
7ad94e76-749b-11df-b112-00215aee3ebe;Прохід;Проход;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724286001;3;1
7ad94e79-749b-11df-b112-00215aee3ebe;Проходи;Проходы;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922387004;3;1
7ad94e7a-749b-11df-b112-00215aee3ebe;Проходи;Проходы;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155128;3;1
7ad94e7b-749b-11df-b112-00215aee3ebe;Прохори;Прохоры;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420886801;3;1
7ad94e7c-749b-11df-b112-00215aee3ebe;Прохори;Прохоры;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420386011;3;1
7ad94e7d-749b-11df-b112-00215aee3ebe;Прохорів;Прохоров;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425581604;3;1
7ad94e7f-749b-11df-b112-00215aee3ebe;Прохорівка;Прохоровка;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122087001;3;1
7ad94e80-749b-11df-b112-00215aee3ebe;Прохорівка;Прохоровка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824288906;3;1
7ad94e82-749b-11df-b112-00215aee3ebe;Прохорова Балка;Прохоровая Балка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;;3;1
7ad94e83-749b-11df-b112-00215aee3ebe;Прохорове;Прохорово;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121881209;3;1
7ad94e84-749b-11df-b112-00215aee3ebe;Проценки;Проценки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321385001;3;1
7ad94e85-749b-11df-b112-00215aee3ebe;Проценки;Проценки;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320688104;3;1
7ad94e86-749b-11df-b112-00215aee3ebe;Проців;Процев;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220886001;3;1
7ad94e87-749b-11df-b112-00215aee3ebe;Прошова;Прошова;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125287402;2;1
7ad94e88-749b-11df-b112-00215aee3ebe;Прощуради;Прощурады;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321885104;3;1
7ad94e89-749b-11df-b112-00215aee3ebe;Пругли;Пруглы;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321387209;3;1
7ad94e8b-749b-11df-b112-00215aee3ebe;Пруди;Пруды;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923883815;3;1
7ad94e8e-749b-11df-b112-00215aee3ebe;Прудище;Прудище;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925655107;3;1
7ad94e91-749b-11df-b112-00215aee3ebe;Прудянка;Прудянка;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322057000;3;1
7ad94e92-749b-11df-b112-00215aee3ebe;Прут;Прут;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323083605;3;1
7ad94e93-749b-11df-b112-00215aee3ebe;Прутильці;Прутильцы;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122586305;3;1
7ad94e94-749b-11df-b112-00215aee3ebe;Прутівка;Прутовка;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625284501;3;1
7ad94e95-749b-11df-b112-00215aee3ebe;Прутівка;Прутовка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821484001;3;1
7ad94e96-749b-11df-b112-00215aee3ebe;Пручаї;Пручаи;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424186203;3;1
7ad94e97-749b-11df-b112-00215aee3ebe;Прушинка;Прушинка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521483409;3;1
7ad94e98-749b-11df-b112-00215aee3ebe;Прядівка;Прядовка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655119;3;1
7ad94e99-749b-11df-b112-00215aee3ebe;Прядківка;Прядковка;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323280610;3;1
7ad94e9a-749b-11df-b112-00215aee3ebe;Пряжів;Пряжов;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822086505;1;1
7ad94e9b-749b-11df-b112-00215aee3ebe;Пряме;Прямое;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;3;1
7ad94e9d-749b-11df-b112-00215aee3ebe;Прямобалка;Прямобалка;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120485401;3;1
74dc4b5f-749b-11df-b112-00215aee3ebe;Псільське;Псельское;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320285305;3;1
7ad94ea0-749b-11df-b112-00215aee3ebe;Птича;Птичья;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621686001;3;1
7ad94ea1-749b-11df-b112-00215aee3ebe;Птиче;Птичье;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822784012;3;1
7ad94ea3-749b-11df-b112-00215aee3ebe;Птичне;Птичне;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920685302;3;1
7ad94ea4-749b-11df-b112-00215aee3ebe;Пугач;Пугач;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625455402;3;1
7ad94ea5-749b-11df-b112-00215aee3ebe;Пугачівка;Пугачовка;село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223780502;3;1
7ad94ea6-749b-11df-b112-00215aee3ebe;Пугачівка;Пугачовка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386005;3;1
7ad94ea7-749b-11df-b112-00215aee3ebe;Пугачівка;Пугачовка;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120986201;3;1
7ad94ea8-749b-11df-b112-00215aee3ebe;Пугачівка;Пугачовка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124386501;3;1
7ad94ea9-749b-11df-b112-00215aee3ebe;Пугачівка;Пугачовка;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623887601;3;1
7ad94eaa-749b-11df-b112-00215aee3ebe;Пудлівка;Пудловка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323485504;3;1
7ad94eab-749b-11df-b112-00215aee3ebe;Пудлівці;Пудловцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822481003;3;1
7ad94eac-749b-11df-b112-00215aee3ebe;Пужайкове;Пужайково;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120687501;3;1
7ad94ead-749b-11df-b112-00215aee3ebe;Пужники;Пужники;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625610111;3;1
7ad94eae-749b-11df-b112-00215aee3ebe;Пузикове;Пузиково;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320687501;3;1
7ad94eaf-749b-11df-b112-00215aee3ebe;Пузирі;Пузыри;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324586701;3;1
7ad94eb0-749b-11df-b112-00215aee3ebe;Пузирки;Пузырьки;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521486603;3;1
7ad94eb1-749b-11df-b112-00215aee3ebe;Пузирки;Пузырьки;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823983004;3;1
7ad94eb2-749b-11df-b112-00215aee3ebe;Пузняківці;Пузняковцы;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122786601;3;1
7ad94eb3-749b-11df-b112-00215aee3ebe;Пукасівці;Пукасовцы;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621280304;3;1
7ad94eb4-749b-11df-b112-00215aee3ebe;Пукеничі;Пукеничи;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625383604;3;1
7ad94eb5-749b-11df-b112-00215aee3ebe;Пуків;Пуков;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624485401;3;1
7ad94eb6-749b-11df-b112-00215aee3ebe;Пукляки;Пукляки;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825286601;2;1
7ad94eb7-749b-11df-b112-00215aee3ebe;Пулемець;Пулемец;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725784501;3;1
871626ee-749b-11df-b112-00215aee3ebe;Пулини;Пулыны;місто;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825400000;3;1
7ad94eb8-749b-11df-b112-00215aee3ebe;Пулино-Гута;Пулино-Гута;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825485701;3;1
7ad94eb9-749b-11df-b112-00215aee3ebe;Пулинці;Пулинцы;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322882702;3;1
7ad94eba-749b-11df-b112-00215aee3ebe;Пултівці;Пултовцы;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520685501;3;1
33ec0017-ddd5-11df-9197-00215aee3ebe;Пултівці;Пултовцы;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520685501;3;1
7ad94ebb-749b-11df-b112-00215aee3ebe;Пульмо;Пульмо;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725784601;3;1
7ad94ebd-749b-11df-b112-00215aee3ebe;Пурпурівка;Пурпуровка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523886501;3;1
7ad94ebe-749b-11df-b112-00215aee3ebe;Пуста;Пуста;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
7ad94ebf-749b-11df-b112-00215aee3ebe;Пуста Гребля;Пустая Гребля;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422280504;3;1
7ad94ec0-749b-11df-b112-00215aee3ebe;Пустельники;Пустельники;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623982802;3;1
7ad94ec1-749b-11df-b112-00215aee3ebe;Пустельники;Пустельники;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824788203;3;1
7ad94ec2-749b-11df-b112-00215aee3ebe;Пустельникове;Пустельниково;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520383506;3;1
7ad94ec3-749b-11df-b112-00215aee3ebe;Пустинка;Пустынка;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723382603;3;1
7ad94ec5-749b-11df-b112-00215aee3ebe;Пустинки;Пустынки;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425584904;3;1
7ad94ec6-749b-11df-b112-00215aee3ebe;Пустовари;Пустовары;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324280505;3;1
7ad94ec7-749b-11df-b112-00215aee3ebe;Пустоварівка;Пустоваровка;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224085901;2;1
7ad94ec8-749b-11df-b112-00215aee3ebe;Пустовійти;Пустовойты;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524885801;3;1
7ad94ec9-749b-11df-b112-00215aee3ebe;Пустовійтівка;Пустовойтовка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187901;3;1
7ad94eca-749b-11df-b112-00215aee3ebe;Пустовійтове;Пустовойтово;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320687601;3;1
7ad94ecb-749b-11df-b112-00215aee3ebe;Пустовіти;Пустовиты;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322483209;3;1
7ad94ecc-749b-11df-b112-00215aee3ebe;Пустовіти;Пустовиты;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222986301;3;1
7ad94ecd-749b-11df-b112-00215aee3ebe;Пустогород;Пустогород;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921585201;3;1
7ad94ece-749b-11df-b112-00215aee3ebe;Пустоіванне;Пустоиванье;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625887301;3;1
7ad94ecf-749b-11df-b112-00215aee3ebe;Пустомити;Пустомыты;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720886401;3;1
7ad94ed0-749b-11df-b112-00215aee3ebe;Пустомити;Пустомыты;місто;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623610100;2;0
7ad94ed1-749b-11df-b112-00215aee3ebe;Пустомити;Пустомыты;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621285405;3;1
7ad94ed2-749b-11df-b112-00215aee3ebe;Пустотине;Пустотино;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423883002;3;1
7ad94ed3-749b-11df-b112-00215aee3ebe;Пустоха;Пустоха;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521486006;3;1
7ad94ed5-749b-11df-b112-00215aee3ebe;Путивль;Путивль;місто;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5923810100;2;0
7ad94ed6-749b-11df-b112-00215aee3ebe;Путивськ;Путивск;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423682802;3;1
7ad94ed7-749b-11df-b112-00215aee3ebe;Путила;Путила;місто;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323555100;3;1
7ad94ed9-749b-11df-b112-00215aee3ebe;Путилівка;Путиловка;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721882004;3;1
7ad94eda-749b-11df-b112-00215aee3ebe;Путилівка;Путиловка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222984503;3;1
7ad94edb-749b-11df-b112-00215aee3ebe;Путилівка;Путиловка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121683008;3;1
7ad94edd-749b-11df-b112-00215aee3ebe;Путиловичі;Путиловичи;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822884801;3;1
7ad94edf-749b-11df-b112-00215aee3ebe;Путрине;Путрино;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322088002;3;1
7ad94ee0-749b-11df-b112-00215aee3ebe;Путринці;Путринцы;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822186002;3;1
7ad94ee1-749b-11df-b112-00215aee3ebe;Путрівка;Путровка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221487001;3;1
7ad94ee2-749b-11df-b112-00215aee3ebe;Путятинці;Путятинцы;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624485601;3;1
7ad94ee3-749b-11df-b112-00215aee3ebe;Путятичі;Путятичи;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620984904;3;1
7ad94ee4-749b-11df-b112-00215aee3ebe;Пухальщина;Пухальщина;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322487008;3;1
7ad94ee5-749b-11df-b112-00215aee3ebe;Пухівка;Пуховка;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221286801;3;0
7ad94ee6-749b-11df-b112-00215aee3ebe;Пухова;Пуховая;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624680407;3;1
7ad94ee7-749b-11df-b112-00215aee3ebe;Пучківщина;Пучковщина;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322687204;3;1
7ad94ee8-749b-11df-b112-00215aee3ebe;Пушкареве;Пушкарево;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320280902;3;1
7ad94ee9-749b-11df-b112-00215aee3ebe;Пушкарі;Пушкари;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121284003;3;1
7ad94eea-749b-11df-b112-00215aee3ebe;Пушкарі;Пушкари;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424488001;3;1
7ad94eeb-749b-11df-b112-00215aee3ebe;Пушкарі;Пушкари;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423684708;3;1
7ad94eec-749b-11df-b112-00215aee3ebe;Пушкарі;Пушкари;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422088902;3;1
7ad94eed-749b-11df-b112-00215aee3ebe;Пушкарівка;Пушкаревка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924781505;3;1
7ad94eee-749b-11df-b112-00215aee3ebe;Пушкарівка;Пушкаревка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221087701;3;1
7ad94eef-749b-11df-b112-00215aee3ebe;Пушкарне;Пушкарное;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325484503;3;1
7ad94ef6-749b-11df-b112-00215aee3ebe;Пушкіне;Пушкино;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424480805;3;1
7ad94ef1-749b-11df-b112-00215aee3ebe;Пушкіно;Пушкино;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121284501;3;1
7ad94ef7-749b-11df-b112-00215aee3ebe;Пушкове;Пушково;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521486201;3;1
d5a66b19-c709-11e0-a0cc-003048d2b473;Пуща Водиця;Пуща-Водица;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;;1;0
7ad94ef8-749b-11df-b112-00215aee3ebe;Пчани;Пчаны;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621585904;3;1
7ad94efc-749b-11df-b112-00215aee3ebe;Пшеничне;Пшеничное;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955119;3;1
7ad94efd-749b-11df-b112-00215aee3ebe;Пшеничне;Пшеничное;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225085504;3;1
7ad94efe-749b-11df-b112-00215aee3ebe;Пшеничне;Пшеничное;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124587202;3;1
7ad94f04-749b-11df-b112-00215aee3ebe;Пшеничне;Пшеничное;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221487201;3;1
7ad94f05-749b-11df-b112-00215aee3ebe;Пшеничники;Пшеничники;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625884501;3;1
7ad94f06-749b-11df-b112-00215aee3ebe;Пшеничники;Пшеничники;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622084202;3;1
7ad94f07-749b-11df-b112-00215aee3ebe;Пшеничники;Пшеничники;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122087401;3;1
7ad94f08-749b-11df-b112-00215aee3ebe;Пшінчине;Пшончино;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924181709;3;1
7ad94f09-749b-11df-b112-00215aee3ebe;Пшонець;Пшенец;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624581203;3;1
7ad94f0a-749b-11df-b112-00215aee3ebe;Пшонянове;Пшеняново;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122782404;3;1
7ad94f10-749b-11df-b112-00215aee3ebe;Пятигори;Пятигоры;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224685301;2;1
7ad94f31-749b-11df-b112-00215aee3ebe;Рава-Руська;Рава-Русская;місто;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622710400;2;0
7ad94f33-749b-11df-b112-00215aee3ebe;Равське;Равское;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785215;3;1
7ad94f34-749b-11df-b112-00215aee3ebe;Равщина;Равщина;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624886403;3;1
7ad94f35-749b-11df-b112-00215aee3ebe;Рагівка;Раговка;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223587601;3;1
7ad94f36-749b-11df-b112-00215aee3ebe;Радалівка;Радаловка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320682804;3;1
7ad94f37-749b-11df-b112-00215aee3ebe;Радванці;Радванцы;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4623981604;3;1
7ad94f38-749b-11df-b112-00215aee3ebe;Радвине;Радвино;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425883506;3;1
7ad94f39-749b-11df-b112-00215aee3ebe;Радгосп Черняхівський;Радгосп Черняховський;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;;3;1
7ad94f43-749b-11df-b112-00215aee3ebe;Радгоспне;Радгоспное;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824755402;3;1
7ad94f44-749b-11df-b112-00215aee3ebe;Радгоспне;Радгоспное;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423381906;3;1
7ad94f45-749b-11df-b112-00215aee3ebe;Радгоспне;Радгоспное;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120685803;3;1
7ad94f46-749b-11df-b112-00215aee3ebe;Радгоспне;Радгоспное;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823681507;3;1
7ad94f3f-749b-11df-b112-00215aee3ebe;Радгоспне;Радгоспное;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825785004;3;1
7ad94f40-749b-11df-b112-00215aee3ebe;Радгоспне;Радгоспное;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157312;3;1
7ad94f4a-749b-11df-b112-00215aee3ebe;Радевичеве;Радевичево;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225882205;3;1
7ad94f4b-749b-11df-b112-00215aee3ebe;Раделичі;Раделичи;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623086601;3;1
7ad94f4c-749b-11df-b112-00215aee3ebe;Раденичі;Раденичи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622485901;3;1
7ad94f4e-749b-11df-b112-00215aee3ebe;Радехів;Радехов;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723384701;3;1
7ad94f4f-749b-11df-b112-00215aee3ebe;Радехів;Радехов;місто;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623910100;2;0
7ad94f50-749b-11df-b112-00215aee3ebe;Радецька Болярка;Радецкая Болярка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825482204;3;1
7ad94f51-749b-11df-b112-00215aee3ebe;Радецьке Будище;Радецкое Будище;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825482205;3;1
7ad94f52-749b-11df-b112-00215aee3ebe;Радиванівка;Радивановка;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121884501;3;1
7ad94f53-749b-11df-b112-00215aee3ebe;Радивилів;Радывылив;місто;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625810100;3;0
7ad94f54-749b-11df-b112-00215aee3ebe;Радивонівка;Радивоновка;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320284301;3;1
7ad94f57-749b-11df-b112-00215aee3ebe;Радижеве;Радыжево;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620889802;3;1
7ad94f58-749b-11df-b112-00215aee3ebe;Радинка;Радинка;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223588001;3;1
7ad94f59-749b-11df-b112-00215aee3ebe;Радиславка;Радиславка;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624687005;3;1
7ad94f5a-749b-11df-b112-00215aee3ebe;Радихівщина;Радиховщина;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625880807;3;1
7ad94f5b-749b-11df-b112-00215aee3ebe;Радич;Радич;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625583207;3;1
7ad94f5c-749b-11df-b112-00215aee3ebe;Радичі;Радичи;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821781605;3;1
7ad94f5d-749b-11df-b112-00215aee3ebe;Радичі;Радичи;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821183801;3;1
7ad94f5e-749b-11df-b112-00215aee3ebe;Радичів;Радичев;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422287001;3;1
7ad94f5f-749b-11df-b112-00215aee3ebe;Радів;Радов;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623883802;3;1
7ad94f60-749b-11df-b112-00215aee3ebe;Радівка;Радовка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510123;3;1
7ad94f61-749b-11df-b112-00215aee3ebe;Радівка;Радовка;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521686803;3;1
7ad94f62-749b-11df-b112-00215aee3ebe;Радівці;Радивци;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821588401;3;1
7ad95143-749b-11df-b112-00215aee3ebe;Радіонівка;Радионовка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221887706;3;1
7ad94f64-749b-11df-b112-00215aee3ebe;Радісне;Радостное;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224281209;3;1
7ad94f65-749b-11df-b112-00215aee3ebe;Радісне;Радостное;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121855600;3;1
7ad94f69-749b-11df-b112-00215aee3ebe;Радісне;Радостное;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825855602;3;1
7ad94f6a-749b-11df-b112-00215aee3ebe;Радісне;Радостное;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524085605;3;1
7ad94f6b-749b-11df-b112-00215aee3ebe;Радісне;Радостное;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822789504;3;1
7af06539-972d-11e8-80d4-1c98ec135261;Радісне;Радісне;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121056802;3;1
7ad94f7e-749b-11df-b112-00215aee3ebe;Радісне (Радсело);Радісне (Радсело);село;bad3ac3c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1223781409;3;1
7ad94f6c-749b-11df-b112-00215aee3ebe;Радківці;Радковцы;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824283201;3;1
7ad94f6d-749b-11df-b112-00215aee3ebe;Радковиця;Радковица;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821287101;3;1
7ad94f6e-749b-11df-b112-00215aee3ebe;Радове;Радово;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622282303;3;1
7ad94f6f-749b-11df-b112-00215aee3ebe;Радовель;Радовель;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824486001;3;1
7ad94f70-749b-11df-b112-00215aee3ebe;Радовичі;Радовичи;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725587605;3;1
7ad94f71-749b-11df-b112-00215aee3ebe;Радовичі;Радовичи;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721183812;3;1
7ad94f72-749b-11df-b112-00215aee3ebe;Радогоща;Радогоща;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822880803;3;1
7ad94f74-749b-11df-b112-00215aee3ebe;Радомишль;Радомышль;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722885201;3;1
7ad94f75-749b-11df-b112-00215aee3ebe;Радомишль;Радомышль;місто;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825010100;2;0
7ad94f76-749b-11df-b112-00215aee3ebe;Радомка;Радомка;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424785501;3;1
20328b9c-f013-11e1-a474-003048d2b473;Радохінці;Радохинци;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622481604;3;1
7ad94f77-749b-11df-b112-00215aee3ebe;Радочини;Радочины;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322481005;3;1
7ad94f78-749b-11df-b112-00215aee3ebe;Радошин;Радошин;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155319;3;1
7ad94f79-749b-11df-b112-00215aee3ebe;Радошинка;Радошинка;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721487103;3;1
7ad94f7a-749b-11df-b112-00215aee3ebe;Радошівка;Радошевка;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125886501;3;1
7ad94f7b-749b-11df-b112-00215aee3ebe;Радошівка;Радошевка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6822186001;3;1
7ad94f7d-749b-11df-b112-00215aee3ebe;Радсело;Радсело;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;*122328450;3;1
7ad94f80-749b-11df-b112-00215aee3ebe;Радужне;Радужное;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624280407;3;1
7ad94f81-749b-11df-b112-00215aee3ebe;Радулин;Радулин;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820681810;3;1
7ad94f82-749b-11df-b112-00215aee3ebe;Радуль;Радуль;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424456400;3;1
7ad94f83-749b-11df-b112-00215aee3ebe;Радухівка;Радуховка;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624687901;3;1
7ad94f84-749b-11df-b112-00215aee3ebe;Радушне;Радушное;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221855500;3;1
7ad94f85-749b-11df-b112-00215aee3ebe;Радча;Радча;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2625884701;3;1
7ad94f86-749b-11df-b112-00215aee3ebe;Радча;Радча;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755146;3;1
7ad94f87-749b-11df-b112-00215aee3ebe;Радченки;Радченки;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323285604;3;1
7ad94f88-749b-11df-b112-00215aee3ebe;Радченкове;Радченково;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323288605;3;1
7ad94f89-749b-11df-b112-00215aee3ebe;Радчиха;Радчиха;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122286201;3;1
7ad94f8a-749b-11df-b112-00215aee3ebe;Радчиці;Радчицы;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824281905;3;1
7ad94f8b-749b-11df-b112-00215aee3ebe;Радчуки;Радчуки;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983708;3;1
7ad94f99-749b-11df-b112-00215aee3ebe;Радянське;Радянское;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322184605;3;1
7ad94fa5-749b-11df-b112-00215aee3ebe;Радянське;Радянское;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821580802;3;1
7ad94f9e-749b-11df-b112-00215aee3ebe;Радянське;Радянское;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223386603;3;1
7ad94f9f-749b-11df-b112-00215aee3ebe;Радянське;Радянское;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822382007;3;1
7ad94fa1-749b-11df-b112-00215aee3ebe;Радянське;Радянское;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421410100;3;1
7ad94fa8-749b-11df-b112-00215aee3ebe;Радьки;Радьки;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324885409;3;1
7ad94fa9-749b-11df-b112-00215aee3ebe;Радьківка;Радьковка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924787104;3;1
7ad94faa-749b-11df-b112-00215aee3ebe;Радьківка;Радьковка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424186103;3;1
7ad94fac-749b-11df-b112-00215aee3ebe;Раєво-Олександрівка;Раево-Александровка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221884006;3;1
7ad94fad-749b-11df-b112-00215aee3ebe;Ражнів;Ражнов;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620382009;3;1
7ad94faf-749b-11df-b112-00215aee3ebe;Разіне;Разино;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821484203;3;1
7ad94fb0-749b-11df-b112-00215aee3ebe;Раївка;Раевка;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325785205;3;1
7ad94fb1-749b-11df-b112-00215aee3ebe;Раївка;Раевка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224887101;3;1
7ad94fb2-749b-11df-b112-00215aee3ebe;Раївка;Раевка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325755106;3;1
7ad94fb4-749b-11df-b112-00215aee3ebe;Раївка;Раевка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825088303;3;1
7ad94fb5-749b-11df-b112-00215aee3ebe;Рай;Рай;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6110590002;3;1
7ad94fb6-749b-11df-b112-00215aee3ebe;Райгород;Райгород;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121885001;3;1
7ad94fb7-749b-11df-b112-00215aee3ebe;Райгород;Райгород;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523086401;3;1
7ad94fbb-749b-11df-b112-00215aee3ebe;Райгородок;Райгородок;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820885201;3;1
7ad94fbc-749b-11df-b112-00215aee3ebe;Райгородок;Райгородок;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422287801;3;1
7ad94fbd-749b-11df-b112-00215aee3ebe;Райдолина;Райдолина;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821755121;3;1
5cb619e3-749b-11df-b112-00215aee3ebe;Райдужне (Комунарівка);Райдужне (Комунарівка);село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224555601;3;1
6ed81cee-749b-11df-b112-00215aee3ebe;Райдужне (Оборона Рад);Радужное (Оборона Рад);село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323487206;3;1
7ad94fbe-749b-11df-b112-00215aee3ebe;Райки;Райки;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121683009;3;1
7ad94fbf-749b-11df-b112-00215aee3ebe;Райки;Райки;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820885301;3;1
7ad94fc0-749b-11df-b112-00215aee3ebe;Райки;Райки;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521684012;3;1
7ad94fc1-749b-11df-b112-00215aee3ebe;Райки;Райки;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521285706;3;1
7ad94fc2-749b-11df-b112-00215aee3ebe;Райки;Райки;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824288602;3;1
7ad94fc3-749b-11df-b112-00215aee3ebe;Райківці;Райковцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825087001;3;1
7ad94fc4-749b-11df-b112-00215aee3ebe;Райківщина;Райковщина;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225585801;3;1
7ad94fc5-749b-11df-b112-00215aee3ebe;Райківщина;Райковщина;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;;3;1
7ad94fc6-749b-11df-b112-00215aee3ebe;Райлів;Райлов;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625384805;3;1
7ad94fc7-749b-11df-b112-00215aee3ebe;Раймісто;Раймисто;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724584505;3;1
7ad94fc9-749b-11df-b112-00215aee3ebe;Райнова;Райнова;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625188206;3;1
7ad94fca-749b-11df-b112-00215aee3ebe;Райозеро;Райозеро;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323684601;3;1
7ad94fcb-749b-11df-b112-00215aee3ebe;Райок;Раек;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122086604;3;1
7ad94fce-749b-11df-b112-00215aee3ebe;Рай-Оленівка;Рай-Оленовка;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157903;3;1
7ad94fcf-749b-11df-b112-00215aee3ebe;Райполе;Райполе;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655127;3;1
7ad94fd0-749b-11df-b112-00215aee3ebe;Райполе;Райполе;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225286609;3;1
7ad94fd1-749b-11df-b112-00215aee3ebe;Райське;Райское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321585005;3;1
7ad94fd5-749b-11df-b112-00215aee3ebe;Раки;Раки;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922085007;3;1
7ad94fd6-749b-11df-b112-00215aee3ebe;Раків;Раков;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622085001;3;1
7ad94fd7-749b-11df-b112-00215aee3ebe;Раків Ліс;Раков Лес;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721486601;3;1
7ad94fd8-749b-11df-b112-00215aee3ebe;Раківка;Раковка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324285505;3;1
7ad94fd9-749b-11df-b112-00215aee3ebe;Раківка;Раковка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320255702;3;1
7ad94fda-749b-11df-b112-00215aee3ebe;Раківка;Раковка;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520686601;3;1
7ad94fdb-749b-11df-b112-00215aee3ebe;Раківка;Раковка;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221887504;3;1
7ad94fdd-749b-11df-b112-00215aee3ebe;Раківчик;Раковчик;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623286001;3;1
7ad94fde-749b-11df-b112-00215aee3ebe;Раківщина;Раковщина;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824286501;3;1
7ad94fdf-749b-11df-b112-00215aee3ebe;Ракобовти;Ракобовты;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620682402;3;1
7ad94fe0-749b-11df-b112-00215aee3ebe;Ракова;Ракова;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523955113;3;1
7ad94fe4-749b-11df-b112-00215aee3ebe;Ракова;Ракова;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625183104;3;1
7ad94fe1-749b-11df-b112-00215aee3ebe;Ракова Січ;Ракова Сечь;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923583410;3;1
7ad94fe2-749b-11df-b112-00215aee3ebe;Ракове;Раково;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224884007;3;1
7ad94fe3-749b-11df-b112-00215aee3ebe;Ракове;Раково;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083203;3;1
7ad94fe6-749b-11df-b112-00215aee3ebe;Ракове;Раково;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124485806;3;1
7ad94fe7-749b-11df-b112-00215aee3ebe;Раковець;Раковец;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122487401;3;1
7ad94fe8-749b-11df-b112-00215aee3ebe;Раковець;Раковец;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125088003;3;1
7ad94fe9-749b-11df-b112-00215aee3ebe;Раковець;Раковец;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620488001;3;1
7ad94fea-749b-11df-b112-00215aee3ebe;Раковець;Раковец;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621683701;3;1
7ad94feb-749b-11df-b112-00215aee3ebe;Раковець;Раковец;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623685601;3;1
7ad94fec-749b-11df-b112-00215aee3ebe;Раковичі;Раковичи;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825088301;3;1
7ad94fed-749b-11df-b112-00215aee3ebe;Раковище;Раковище;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623987204;3;1
7ad94fe5-749b-11df-b112-00215aee3ebe;Раково;Раково;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123285503;3;1
7ad94fee-749b-11df-b112-00215aee3ebe;Ракош;Ракош;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125355302;3;1
7ad94fef-749b-11df-b112-00215aee3ebe;Ракошино;Ракошино;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122786801;3;1
7ad94ff0-749b-11df-b112-00215aee3ebe;Ракужа;Ракужа;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;;3;1
7ad94ff1-749b-11df-b112-00215aee3ebe;Ракулове;Ракулово;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120689503;3;1
7ad94ff2-749b-11df-b112-00215aee3ebe;Ракути;Ракуты;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424782006;3;1
7ad94ff4-749b-11df-b112-00215aee3ebe;Ракшівка;Ракшевка;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221486211;3;1
7ad94ff5-749b-11df-b112-00215aee3ebe;Ралівка;Ралевка;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624287201;2;1
7ad94ff6-749b-11df-b112-00215aee3ebe;Рандава;Рандава;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323580505;3;1
7ad94ff7-749b-11df-b112-00215aee3ebe;Раневичі;Раневичи;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621286101;3;1
7ad94ff8-749b-11df-b112-00215aee3ebe;Ранжеве;Ранжево;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122783802;3;1
7ad94ff9-749b-11df-b112-00215aee3ebe;Ранній Ранок;Ранний Ранок;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221881406;3;1
7ad94ffa-749b-11df-b112-00215aee3ebe;Расавка;Расавка;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222286601;3;1
7ad94ffb-749b-11df-b112-00215aee3ebe;Раска;Раска;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221055601;3;1
7ad94ffc-749b-11df-b112-00215aee3ebe;Раславка;Раславка;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123484405;3;1
7ad94ffd-749b-11df-b112-00215aee3ebe;Растів;Растов;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725555107;3;1
7ad94ffe-749b-11df-b112-00215aee3ebe;Рата;Рата;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787902;3;1
7ad94fff-749b-11df-b112-00215aee3ebe;Ратищі;Ратыщи;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122688201;3;1
7ad95002-749b-11df-b112-00215aee3ebe;Ратівці;Ратовцы;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124885401;3;1
7ad95000-749b-11df-b112-00215aee3ebe;Ратне;Ратно;місто;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0724255100;2;1
7ad95001-749b-11df-b112-00215aee3ebe;Ратнів;Ратнов;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722885401;3;1
7ad95003-749b-11df-b112-00215aee3ebe;Ратуш;Ратуш;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221686002;3;1
7ad95004-749b-11df-b112-00215aee3ebe;Ратуш;Ратуш;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525684201;3;1
7ad95005-749b-11df-b112-00215aee3ebe;Раухівка;Рауховка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121255600;3;1
7ad95007-749b-11df-b112-00215aee3ebe;Рафайново;Рафайново;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120488201;3;1
7ad95008-749b-11df-b112-00215aee3ebe;Рафалівка;Рафаловка;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620855400;3;1
7ad95009-749b-11df-b112-00215aee3ebe;Рахвалівка;Рахваловка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222080603;3;1
7ad9500a-749b-11df-b112-00215aee3ebe;Рахиня;Рахиня;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622085201;3;1
7ad9500b-749b-11df-b112-00215aee3ebe;Рахів;Рахов;місто;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123610100;2;0
7ad9500c-749b-11df-b112-00215aee3ebe;Рахни;Рахны;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520885406;3;1
7ad9500d-749b-11df-b112-00215aee3ebe;Рахни-Лісові;Рахни-Лесовые;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525386901;3;1
7ad9500e-749b-11df-b112-00215aee3ebe;Рахни-Польові;Рахны-Полевые;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524585301;3;1
7ad9500f-749b-11df-b112-00215aee3ebe;Рахнівка;Рахновка;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520886506;3;1
7ad95010-749b-11df-b112-00215aee3ebe;Рахнівка;Рахновка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821887601;3;1
7ad95011-749b-11df-b112-00215aee3ebe;Рацеве;Рацево;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125485001;3;1
7ad95012-749b-11df-b112-00215aee3ebe;Рацинська Дача;Рацинская Дача;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822081205;3;1
7ad95013-749b-11df-b112-00215aee3ebe;Раціборівка;Рациборовка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820987403;3;1
7ad95014-749b-11df-b112-00215aee3ebe;Рачин;Рачин;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720886901;3;1
7ad95015-749b-11df-b112-00215aee3ebe;Рачин;Рачин;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621686501;3;1
7ad95016-749b-11df-b112-00215aee3ebe;Рачинці;Рачынци;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821887501;3;1
7ad95018-749b-11df-b112-00215aee3ebe;Рачки;Рачки;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221686001;3;1
7ad95019-749b-11df-b112-00215aee3ebe;Рачки;Рачки;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825887201;3;1
3a5c3cbe-834d-11e8-80d4-1c98ec135261;Рачки;Рачки;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
7ad9501a-749b-11df-b112-00215aee3ebe;Рашівка;Рашевка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320485501;3;1
7ad9501b-749b-11df-b112-00215aee3ebe;Рашків;Рашков;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325087201;3;1
7ad9501c-749b-11df-b112-00215aee3ebe;Рашків;Рашков;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621684001;3;1
7ad9501d-749b-11df-b112-00215aee3ebe;Рашкова Слобода;Рашкова Слобода;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424487206;3;1
7ad9501e-749b-11df-b112-00215aee3ebe;Раштівка;Раштовка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824281004;3;1
7ad9501f-749b-11df-b112-00215aee3ebe;Раштівці;Раштовцы;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121686301;3;1
7ad95020-749b-11df-b112-00215aee3ebe;Ребедайлівка;Ребедайловка;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121885601;3;1
7ad95033-749b-11df-b112-00215aee3ebe;Рев'якине;Ревякино;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923887001;3;1
7ad95023-749b-11df-b112-00215aee3ebe;Реваківці;Реваковцы;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322580504;3;1
7ad95024-749b-11df-b112-00215aee3ebe;Ревбинці;Ревбинцы;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125188401;3;1
7ad95025-749b-11df-b112-00215aee3ebe;Реви;Ревы;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923584405;3;1
7ad95026-749b-11df-b112-00215aee3ebe;Ревівка;Ревовка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322480707;3;1
7ad95027-749b-11df-b112-00215aee3ebe;Ревівка;Ревовка;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121886001;3;1
7ad95028-749b-11df-b112-00215aee3ebe;Ревки;Ревки;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922980403;3;1
7ad95029-749b-11df-b112-00215aee3ebe;Ревківці;Ревковцы;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323087102;3;1
7ad9502a-749b-11df-b112-00215aee3ebe;Ревне;Ревне;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220886201;3;1
04cb78c2-d36b-11e8-80d7-1c98ec135261;Ревне;Ревно;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322588504;3;1
7ad9502b-749b-11df-b112-00215aee3ebe;Ревова;Ревова;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125481005;3;1
7ad9502c-749b-11df-b112-00215aee3ebe;Реводарівка (Водолага);Реводаровка (Водолага);село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323985505;3;1
7ad9502e-749b-11df-b112-00215aee3ebe;Ревунів Круг;Ревунов Круг;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425583702;3;1
7ad9502f-749b-11df-b112-00215aee3ebe;Ревуха;Ревуха;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822182603;3;1
7ad95030-749b-11df-b112-00215aee3ebe;Ревуха;Ревуха;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823084603;3;1
7ad95031-749b-11df-b112-00215aee3ebe;Ревушки;Ревушки;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725580403;3;1
7ad95032-749b-11df-b112-00215aee3ebe;Ревущине;Ревущино;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;5321881703;3;1
7ad95034-749b-11df-b112-00215aee3ebe;Регентівка;Регентовка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520285609;3;1
7ad95035-749b-11df-b112-00215aee3ebe;Регляшинці;Регляшинцы;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525686605;3;1
7ad95036-749b-11df-b112-00215aee3ebe;Редвинці;Редвинцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825088702;3;1
7ad95037-749b-11df-b112-00215aee3ebe;Редути;Редуты;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321681002;3;1
7ad95038-749b-11df-b112-00215aee3ebe;Редьківка;Редьковка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424482509;3;1
7ad95039-749b-11df-b112-00215aee3ebe;Редьківка;Редьковка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425587401;3;1
7ad9503a-749b-11df-b112-00215aee3ebe;Резедівка;Резедовка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321586110;3;1
7ad9503c-749b-11df-b112-00215aee3ebe;Рейментарівка;Рейментаровка;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422487501;3;1
7ad9503d-749b-11df-b112-00215aee3ebe;Рекіти;Рекиты;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122482802;3;1
7ad9503e-749b-11df-b112-00215aee3ebe;Реклинець;Реклинец;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624885901;3;1
7ad9503f-749b-11df-b112-00215aee3ebe;Рекшин;Рекшин;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120487001;3;1
7ad95040-749b-11df-b112-00215aee3ebe;Ремезівці;Ремезовцы;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621886101;3;1
7ad95041-749b-11df-b112-00215aee3ebe;Ремель;Ремель;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624687006;3;1
7ad95042-749b-11df-b112-00215aee3ebe;Ременів;Ременов;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622185201;3;1
7ad95044-749b-11df-b112-00215aee3ebe;Ремчиці;Ремчицы;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625485801;3;1
7ad95045-749b-11df-b112-00215aee3ebe;Рені;Рени;місто;d00d3b62-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5124110100;2;0
7ad95046-749b-11df-b112-00215aee3ebe;Ренів;Ренев;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122688401;3;1
7ad95047-749b-11df-b112-00215aee3ebe;Репехів;Репехов;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621555909;3;1
7ad95048-749b-11df-b112-00215aee3ebe;Репинне;Репинное;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122486001;3;1
7ad95049-749b-11df-b112-00215aee3ebe;Репище;Репище;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823481803;3;1
7ad9504a-749b-11df-b112-00215aee3ebe;Репужинці;Репужинцы;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321587601;3;1
7ad9504b-749b-11df-b112-00215aee3ebe;Репужинці;Репужинцы;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621680807;3;1
7ad9504d-749b-11df-b112-00215aee3ebe;Республіканець;Республиканец;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520684802;3;1
7ad9504e-749b-11df-b112-00215aee3ebe;Ретик;Ретик;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922681707;3;1
7ad9504f-749b-11df-b112-00215aee3ebe;Реутинці;Реутинцы;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922687201;3;1
7ad95050-749b-11df-b112-00215aee3ebe;Рецюківщина;Рецюковщина;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120688701;3;1
7ad95051-749b-11df-b112-00215aee3ebe;Речичани;Речичаны;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620987201;3;1
7ad95052-749b-11df-b112-00215aee3ebe;Решетилівка;Решетиловка;місто;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324210100;3;0
7ad95054-749b-11df-b112-00215aee3ebe;Решнівка;Решневка;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122489602;3;1
7ad95055-749b-11df-b112-00215aee3ebe;Решнівка;Решневка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822788404;3;1
7ad95056-749b-11df-b112-00215aee3ebe;Решнівка;Решневка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824286901;3;1
7ad95057-749b-11df-b112-00215aee3ebe;Решуцьк;Решуцк;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624686502;3;1
7ad95058-749b-11df-b112-00215aee3ebe;Рея;Рея;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820885401;3;1
7ad9505a-749b-11df-b112-00215aee3ebe;Ржавець;Ржавец;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325185503;3;1
7ad9505b-749b-11df-b112-00215aee3ebe;Ржавинці;Ржавинцы;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321588001;3;1
7ad9505c-749b-11df-b112-00215aee3ebe;Ржавчик;Ржавчик;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324586801;3;1
7ad9505d-749b-11df-b112-00215aee3ebe;Ржане;Ржаное;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925683002;3;1
7ad9505e-749b-11df-b112-00215aee3ebe;Ржищів;Ржищев;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720880803;3;1
7ad9505f-749b-11df-b112-00215aee3ebe;Ржищів;Ржищев;місто;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3211300000;3;0
7ad95060-749b-11df-b112-00215aee3ebe;Рибаківка;Рыбаковка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820982208;3;1
7ad95062-749b-11df-b112-00215aee3ebe;Рибалки;Рыбалки;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084801;3;1
7ad95063-749b-11df-b112-00215aee3ebe;Рибалки;Рыбалки;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225682003;3;1
7ad95065-749b-11df-b112-00215aee3ebe;Рибальське;Рыбальское;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920387501;3;1
7ad95066-749b-11df-b112-00215aee3ebe;Рибальське;Рыбальское;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125084201;2;1
7ad95067-749b-11df-b112-00215aee3ebe;Рибальське;Рыбальское;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323681305;3;1
7ad95068-749b-11df-b112-00215aee3ebe;Рибальське;Рыбальское;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323281403;3;1
7ad9506c-749b-11df-b112-00215aee3ebe;Рибинськ;Рыбинск;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422487901;3;1
7ad9506e-749b-11df-b112-00215aee3ebe;Рибки;Рыбки;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523283404;3;1
7ad95070-749b-11df-b112-00215aee3ebe;Рибне;Рыбное;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2625885001;3;1
7ad95071-749b-11df-b112-00215aee3ebe;Рибне;Рыбное;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623685203;3;1
7ad95072-749b-11df-b112-00215aee3ebe;Рибник;Рыбник;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621286566;3;1
7ad95073-749b-11df-b112-00215aee3ebe;Рибники;Рыбники;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120487601;3;1
7ad95074-749b-11df-b112-00215aee3ebe;Риботень;Рыботень;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920389206;3;1
7ad95075-749b-11df-b112-00215aee3ebe;Риботин;Рыботин;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422288201;3;1
7ad95076-749b-11df-b112-00215aee3ebe;Рибці;Рыбцы;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924787105;3;1
7ad95077-749b-11df-b112-00215aee3ebe;Рибці;Рыбцы;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424184303;3;1
2513de6f-ba1a-11e7-80cc-1c98ec135261;Рибці;Рыбцы;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;;1;0
7ad95078-749b-11df-b112-00215aee3ebe;Рибча;Рыбча;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123482902;3;1
7ad95079-749b-11df-b112-00215aee3ebe;Рибченки;Рыбченки;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324881911;3;1
7ad9507a-749b-11df-b112-00215aee3ebe;Рибчине;Рыбчино;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523483605;3;1
7ad9507b-749b-11df-b112-00215aee3ebe;Рибчинці;Рыбчинцы;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224084902;3;1
7ad9507c-749b-11df-b112-00215aee3ebe;Рибчинці;Рыбчинцы;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524885901;3;1
7ad9507e-749b-11df-b112-00215aee3ebe;Риги;Риги;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322685801;3;1
7ad9507f-749b-11df-b112-00215aee3ebe;Ридодуби;Рыдодубы;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125587101;3;1
7ad95080-749b-11df-b112-00215aee3ebe;Ридомиль;Рыдомиль;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123486801;3;1
7ad95081-749b-11df-b112-00215aee3ebe;Ридохінці;Рудохинцы;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622481604;3;1
7ad95082-749b-11df-b112-00215aee3ebe;Рижа;Рыжа;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323555102;3;1
7ad95083-749b-11df-b112-00215aee3ebe;Рижавка;Рыжавка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124387301;3;1
7ad95084-749b-11df-b112-00215aee3ebe;Рижавка;Рыжавка;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521084506;3;1
7ad95085-749b-11df-b112-00215aee3ebe;Рижани;Рыжаны;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620685306;3;1
7ad95086-749b-11df-b112-00215aee3ebe;Рижани;Рыжаны;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821184001;3;1
7ad95087-749b-11df-b112-00215aee3ebe;Рижанівка;Рыжановка;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121286801;3;1
7ad95088-749b-11df-b112-00215aee3ebe;Рижики;Рыжики;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425589302;3;1
7ad95089-749b-11df-b112-00215aee3ebe;Рижів;Рыжов;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825882003;3;1
7ad9508a-749b-11df-b112-00215aee3ebe;Рижівка;Рыжевка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920687401;3;1
7ad9508b-749b-11df-b112-00215aee3ebe;Рижки;Рыжки;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422288301;3;1
7ad9508c-749b-11df-b112-00215aee3ebe;Рижове;Рыжово;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320680305;3;1
7ad9508d-749b-11df-b112-00215aee3ebe;Рижулинці;Рыжулинцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825084203;3;1
7ad9508e-749b-11df-b112-00215aee3ebe;Ризине;Ризино;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121286401;3;1
7ad9508f-749b-11df-b112-00215aee3ebe;Риків;Рыков;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625585601;3;1
7ad95090-749b-11df-b112-00215aee3ebe;Риків;Рыков;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624586701;3;1
7ad95091-749b-11df-b112-00215aee3ebe;Риків;Рыков;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422081506;3;1
7ad95092-749b-11df-b112-00215aee3ebe;Риковичі;Рыковичи;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721183813;3;1
7ad95093-749b-11df-b112-00215aee3ebe;Рикунь;Рыкунь;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221855303;3;1
7ad95095-749b-11df-b112-00215aee3ebe;Рилівка;Рыловка;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825587001;3;1
7ad95096-749b-11df-b112-00215aee3ebe;Римарівка;Рымаровка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123183401;3;1
7ad95097-749b-11df-b112-00215aee3ebe;Римарівка;Рымаровка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320486501;3;1
7ad95098-749b-11df-b112-00215aee3ebe;Римачі;Рымачи;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723384801;3;1
7ad95099-749b-11df-b112-00215aee3ebe;Римиги;Римиги;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325785206;3;1
7ad9509a-749b-11df-b112-00215aee3ebe;Рингач;Рынгач;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323086701;3;1
7ad9509b-749b-11df-b112-00215aee3ebe;Рипень;Рыпень;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323585003;3;1
7ad9509e-749b-11df-b112-00215aee3ebe;Рисв'янка;Рысвянка;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624689811;3;1
7ad950a1-749b-11df-b112-00215aee3ebe;Ритні;Ритни;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221880804;3;1
7ad950a2-749b-11df-b112-00215aee3ebe;Рихальське;Рыхальское;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821785001;3;1
7ad950a3-749b-11df-b112-00215aee3ebe;Рихли;Рыхли;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422255502;3;1
7ad950a4-749b-11df-b112-00215aee3ebe;Рихта;Рыхта;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221880805;3;1
7ad950a5-749b-11df-b112-00215aee3ebe;Рихта;Рыхта;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822487101;2;1
7ad950a6-749b-11df-b112-00215aee3ebe;Рихтичі;Рыхтичи;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621286301;3;1
7ad950a7-749b-11df-b112-00215aee3ebe;Ричагів;Рычагов;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623082407;3;1
7ad950a8-749b-11df-b112-00215aee3ebe;Ришавка;Рышавка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386032;3;1
7ad950a9-749b-11df-b112-00215aee3ebe;Рів;Ров;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521085003;3;1
7ad950aa-749b-11df-b112-00215aee3ebe;Рівець;Ровец;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520682209;3;1
7ad950ab-749b-11df-b112-00215aee3ebe;Рівки;Ровки;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823981503;3;1
7ad950ac-749b-11df-b112-00215aee3ebe;Рівна;Ровная;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623380705;3;1
7ad950ad-749b-11df-b112-00215aee3ebe;Рівне;Ровно;місто;fbfb8c0d-41be-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5610100000;1;0
7ad950ae-749b-11df-b112-00215aee3ebe;Рівне;Ровно;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923880704;3;1
7ad950af-749b-11df-b112-00215aee3ebe;Рівне;Ровно;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723384901;3;1
7ad950b0-749b-11df-b112-00215aee3ebe;Рівне;Ровно;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223280503;3;1
7ad950b1-749b-11df-b112-00215aee3ebe;Рівне;Ровно;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755704;3;1
7ad950b2-749b-11df-b112-00215aee3ebe;Рівне;Ровно;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825184806;3;1
7ad950b3-749b-11df-b112-00215aee3ebe;Рівне;Ровно;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322588504;3;1
7ad950b4-749b-11df-b112-00215aee3ebe;Рівне;Ровно;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283612;3;1
7ad950b5-749b-11df-b112-00215aee3ebe;Рівне;Ровно;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124788001;3;1
7ad950bb-749b-11df-b112-00215aee3ebe;Рівне;Ровно;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621284305;3;1
7ad950be-749b-11df-b112-00215aee3ebe;Рівне;Ровно;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124488002;3;1
7ad950c1-749b-11df-b112-00215aee3ebe;Рівне;Ровно;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522886201;3;1
7ad950c2-749b-11df-b112-00215aee3ebe;Рівне;Ровно;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523855402;3;1
7ad950c3-749b-11df-b112-00215aee3ebe;Рівне;Ровно;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524085601;3;1
7ad950c4-749b-11df-b112-00215aee3ebe;Рівне;Ровно;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323810109;3;1
7ad950c5-749b-11df-b112-00215aee3ebe;Рівнинне;Равнинное;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521610112;3;1
7ad950c7-749b-11df-b112-00215aee3ebe;Рівнопілля;Ровнополье;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521884005;3;1
7ad94f32-749b-11df-b112-00215aee3ebe;Рівнопілля;Равнополля;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425589303;3;1
d8d9eeae-daa0-11df-9197-00215aee3ebe;Рівнопілля;Ровнополье;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425589303;3;1
7ad950cb-749b-11df-b112-00215aee3ebe;Рівня;Ровня;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624884801;3;1
7ad950cc-749b-11df-b112-00215aee3ebe;Рівня;Ровня;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620881503;3;1
7ad950ce-749b-11df-b112-00215aee3ebe;Рівчак-Степанівка;Ровчак-Степановка;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423884501;3;1
7ad950cf-749b-11df-b112-00215aee3ebe;Ріг;Рог;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924484804;3;1
7ad950d1-749b-11df-b112-00215aee3ebe;Ріденьке;Реденькое;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224610111;3;1
7ad950d2-749b-11df-b112-00215aee3ebe;Рідка;Редкая;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824780202;3;1
7ad950d3-749b-11df-b112-00215aee3ebe;Рідків;Редков;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625885601;3;1
7ad950d4-749b-11df-b112-00215aee3ebe;Рідківці;Редковцы;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323086301;3;0
7ad950d6-749b-11df-b112-00215aee3ebe;Рідкодуб;Редкодуб;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321280305;3;1
7ad950da-749b-11df-b112-00215aee3ebe;Рідкодуб;Редкодуб;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824485503;3;1
7ad950db-749b-11df-b112-00215aee3ebe;Рідкодуби;Редкодубы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825087101;3;1
7ad950dc-749b-11df-b112-00215aee3ebe;Рідкодуби;Редкодубы;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621688509;3;1
7ad950dd-749b-11df-b112-00215aee3ebe;Рідколісся;Редколесье;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124282803;3;1
56bdd8f2-749b-11df-b112-00215aee3ebe;Рідне;Рідне;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320483701;3;1
4aba3f03-749b-11df-b112-00215aee3ebe;Рідне (Воровське);Родное (Воровское);село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921280803;3;1
7ad950e1-749b-11df-b112-00215aee3ebe;Рідний Край;Родной Край;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322682504;3;1
7ad950e2-749b-11df-b112-00215aee3ebe;Ріжки;Рожки;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224485201;3;1
7ad950e4-749b-11df-b112-00215aee3ebe;Ріжок;Рожок;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522485402;3;1
7ad950e5-749b-11df-b112-00215aee3ebe;Різдвяне;Риздвяное;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321284015;3;1
7ad950e6-749b-11df-b112-00215aee3ebe;Різдвяни;Риздвяны;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125087101;3;1
73e94c07-ddc1-11df-9197-00215aee3ebe;Різдвяни;Риздвяны;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621286603;3;1
7ad950e7-749b-11df-b112-00215aee3ebe;Різдвянка;Риздвянка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323986005;3;1
7ad950e8-749b-11df-b112-00215aee3ebe;Різдвянка;Риздвянка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323684701;3;1
7ad950e9-749b-11df-b112-00215aee3ebe;Різки;Ризки;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621881305;3;1
7ad950ea-749b-11df-b112-00215aee3ebe;Різники;Різники;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;;3;1
7ad950ed-749b-11df-b112-00215aee3ebe;Різня;Ризня;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823485203;3;1
7ad950ee-749b-11df-b112-00215aee3ebe;Різуненкове;Резуненково;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323280601;3;1
7ad950fc-749b-11df-b112-00215aee3ebe;Ріп'яна;Рипяна;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625184301;3;1
7ad950fd-749b-11df-b112-00215aee3ebe;Ріп'янка;Рипянка;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622886001;3;1
7ad950ef-749b-11df-b112-00215aee3ebe;Ріпинці;Репинцы;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121285801;3;1
7ad950f0-749b-11df-b112-00215aee3ebe;Ріпинці;Репинцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822485702;2;1
7ad950f1-749b-11df-b112-00215aee3ebe;Ріпинці;Репинцы;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821855703;3;1
7ad950f2-749b-11df-b112-00215aee3ebe;Ріпки;Репки;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924188201;3;1
7ad950f3-749b-11df-b112-00215aee3ebe;Ріпки;Репки;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320887505;3;1
7ad950f4-749b-11df-b112-00215aee3ebe;Ріпки;Репки;місто;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424455100;3;0
7ad950f5-749b-11df-b112-00215aee3ebe;Ріпки;Репки;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122885601;3;1
7ad950f6-749b-11df-b112-00215aee3ebe;Ріпки;Репки;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822186301;3;1
7ad950f7-749b-11df-b112-00215aee3ebe;Ріпки, санат;Репки, санат;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320887505;3;1
7ad950f8-749b-11df-b112-00215aee3ebe;Ріпки, станція;Репки, станция;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320887505;3;1
7ad950f9-749b-11df-b112-00215aee3ebe;Ріпна;Репна;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820988203;3;1
7ad950fa-749b-11df-b112-00215aee3ebe;Ріпне;Репное;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624884601;3;1
7ad950fb-749b-11df-b112-00215aee3ebe;Ріпнів;Репнев;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620683603;3;1
7ad9509c-749b-11df-b112-00215aee3ebe;Ріпчиці;Рипчицы;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621286401;3;1
7ad950fe-749b-11df-b112-00215aee3ebe;Річечина;Ричечина;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623086305;3;1
7ad950ff-749b-11df-b112-00215aee3ebe;Річиця;Речица;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724286401;3;1
7ad95100-749b-11df-b112-00215aee3ebe;Річиця;Речица;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622285701;3;1
7ad95101-749b-11df-b112-00215aee3ebe;Річиця;Речица;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621283602;3;1
7ad95102-749b-11df-b112-00215aee3ebe;Річище;Речище;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623886602;3;1
7ad95103-749b-11df-b112-00215aee3ebe;Річка;Речка;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623684801;3;1
7ad95104-749b-11df-b112-00215aee3ebe;Річка;Речка;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122486401;3;1
7ad95105-749b-11df-b112-00215aee3ebe;Річки;Речки;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920687001;3;1
7ad95106-749b-11df-b112-00215aee3ebe;Річки;Речки;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787901;3;1
7ad95107-749b-11df-b112-00215aee3ebe;Річки;Речки;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622285304;3;1
7ad95108-749b-11df-b112-00215aee3ebe;Річки;Речки;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623086301;3;1
7ad95109-749b-11df-b112-00215aee3ebe;Річне;Речное;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322182404;3;1
7ad9510c-749b-11df-b112-00215aee3ebe;Рішин;Ришин;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625888406;3;1
7ad9510e-749b-11df-b112-00215aee3ebe;Роботівка;Работовка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322484804;3;1
7ad9510f-749b-11df-b112-00215aee3ebe;Рованці;Рованцы;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722880707;2;0
7ad95110-749b-11df-b112-00215aee3ebe;Ровба;Ровба;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755147;3;1
7ad95112-749b-11df-b112-00215aee3ebe;Ровеньки;Ровеньки;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224584815;3;1
7ad95114-749b-11df-b112-00215aee3ebe;Ровжі;Ровжи;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221882802;3;1
7ad95115-749b-11df-b112-00215aee3ebe;Рови;Ровы;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221887001;3;1
7ad95116-749b-11df-b112-00215aee3ebe;Рогалі;Рогали;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622185917;3;1
7ad95118-749b-11df-b112-00215aee3ebe;Рогань;Рогань;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325158500;2;1
7ad95119-749b-11df-b112-00215aee3ebe;Рогатин;Рогатин;місто;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2624410100;3;0
7ad9511a-749b-11df-b112-00215aee3ebe;Рогачин;Рогачин;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120487301;3;1
7ad9511c-749b-11df-b112-00215aee3ebe;Рогачі;Рогачи;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825285601;3;1
7ad9511d-749b-11df-b112-00215aee3ebe;Рогачів;Рогачов;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820684801;3;1
7ad9511e-749b-11df-b112-00215aee3ebe;Рогачів;Рогачов;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624681105;3;1
7ad9511f-749b-11df-b112-00215aee3ebe;Роги;Роги;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123187501;3;1
7ad95120-749b-11df-b112-00215aee3ebe;Рогинці;Рогинцы;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924188501;3;1
7ad95121-749b-11df-b112-00215aee3ebe;Рогинці;Рогинцы;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524883503;3;1
7ad95122-749b-11df-b112-00215aee3ebe;Рогиня;Рогиня;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621683102;3;1
7ad95123-749b-11df-b112-00215aee3ebe;Рогівка;Роговка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321280506;3;1
7ad95124-749b-11df-b112-00215aee3ebe;Рогівка;Роговка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821782009;3;1
7ad95125-749b-11df-b112-00215aee3ebe;Рогівка;Роговка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423686202;3;1
7ad95126-749b-11df-b112-00215aee3ebe;Рогівське;Роговское;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222084505;3;1
7ad95127-749b-11df-b112-00215aee3ebe;Рогізка;Рогозка;село;78f453a8-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0525084801;3;1
7ad95128-749b-11df-b112-00215aee3ebe;Рогізки;Рогозки;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425885501;3;1
7ad9512a-749b-11df-b112-00215aee3ebe;Рогізна;Рогозна;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224086201;2;1
7ad9512b-749b-11df-b112-00215aee3ebe;Рогізна;Рогозна;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221686401;2;1
7ad9512c-749b-11df-b112-00215aee3ebe;Рогізна;Рогозна;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823180802;3;1
7ad9512d-749b-11df-b112-00215aee3ebe;Рогізна;Рогозна;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524583807;3;1
7ad9512e-749b-11df-b112-00215aee3ebe;Рогізна;Рогозна;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822483604;3;1
7ad9512f-749b-11df-b112-00215aee3ebe;Рогізне;Рогозное;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920683504;3;1
7ad95130-749b-11df-b112-00215aee3ebe;Рогізне;Рогозное;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786909;3;1
7ad95132-749b-11df-b112-00215aee3ebe;Рогізне;Рогозное;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621484503;3;1
7ad95133-749b-11df-b112-00215aee3ebe;Рогізно;Рогозно;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621580605;3;1
7ad95131-749b-11df-b112-00215aee3ebe;Рогізно;Рогозно;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625887001;3;1
7ad95129-749b-11df-b112-00215aee3ebe;Рогізно;Рогозно;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624281402;3;1
7ad95134-749b-11df-b112-00215aee3ebe;Рогова;Роговая;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124386801;3;1
7ad95137-749b-11df-b112-00215aee3ebe;Роговичі;Роговичи;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722483403;3;1
7ad95138-749b-11df-b112-00215aee3ebe;Роговичі;Роговичи;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823686501;3;1
7ad95139-749b-11df-b112-00215aee3ebe;Рогові Смоляри;Роговые Смоляры;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723384915;3;1
7ad9513a-749b-11df-b112-00215aee3ebe;Рогожани;Рогожаны;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0720510417;3;1
7ad9513b-749b-11df-b112-00215aee3ebe;Рогозів;Рогозов;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220886401;2;1
7ad9513d-749b-11df-b112-00215aee3ebe;Рогозянка, станція;Рогозянка, станция;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322686506;3;1
7ad9513e-749b-11df-b112-00215aee3ebe;Рогощі;Рогощи;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425581903;3;1
7ad95140-749b-11df-b112-00215aee3ebe;Родатичі;Родатичи;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620987601;3;1
7ad95141-749b-11df-b112-00215aee3ebe;Родинське;Родинское;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323684007;3;1
c9eb3821-8e4b-11eb-80d2-000c29800ae7;Родичи ;Родичи;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322083001;3;1
7ad94f63-749b-11df-b112-00215aee3ebe;Родіонівка;Родионовка;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925681904;3;1
7ad95144-749b-11df-b112-00215aee3ebe;Родники;Родники;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820680705;3;1
7ad95148-749b-11df-b112-00215aee3ebe;Родниківка;Родниковка;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124084407;3;1
7ad95149-749b-11df-b112-00215aee3ebe;Родниківка;Родниковка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124386701;3;1
7ad9514a-749b-11df-b112-00215aee3ebe;Родниківка;Родниковка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520586001;3;1
7ad9514c-749b-11df-b112-00215aee3ebe;Родникова Гута;Родниковая Гута;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124084408;3;1
7ad95150-749b-11df-b112-00215aee3ebe;Рожаївка;Рожаевка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324080105;3;1
7ad95151-749b-11df-b112-00215aee3ebe;Рожаки;Рожаки;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484509;3;1
7ad95152-749b-11df-b112-00215aee3ebe;Рожанівка;Рожановка;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122087802;3;1
7ad95153-749b-11df-b112-00215aee3ebe;Рождественка;Рождественка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;;3;1
7ad95154-749b-11df-b112-00215aee3ebe;Рождественське;Рождественское;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925680406;3;1
7ad95155-749b-11df-b112-00215aee3ebe;Рождественське;Рождественское;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120688801;3;1
7ad95156-749b-11df-b112-00215aee3ebe;Рожеве;Рожевое;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625186304;3;1
7ad95157-749b-11df-b112-00215aee3ebe;Рожеве;Рожевое;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520380803;3;1
7ad95158-749b-11df-b112-00215aee3ebe;Рожепи;Рожепы;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521086009;3;1
7ad95159-749b-11df-b112-00215aee3ebe;Рожиськ;Рожиск;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124686701;3;1
7ad9515a-749b-11df-b112-00215aee3ebe;Рожична;Рожичная;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523184301;3;1
7ad9515b-749b-11df-b112-00215aee3ebe;Рожична;Рожичная;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825586501;3;1
7ad9515c-749b-11df-b112-00215aee3ebe;Рожище;Рожище;місто;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724510100;3;0
7ad9515d-749b-11df-b112-00215aee3ebe;Рожів;Рожев;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222787101;3;1
7ad9515e-749b-11df-b112-00215aee3ebe;Рожівка;Рожевка;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221287001;3;1
7ad9515f-749b-11df-b112-00215aee3ebe;Рожковичі;Рожковичи;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924485201;3;1
7ad95160-749b-11df-b112-00215aee3ebe;Рожневі Поля;Рожневые Поля;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625255307;3;1
7ad95161-749b-11df-b112-00215aee3ebe;Рожни;Рожны;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221287201;3;1
7ad95162-749b-11df-b112-00215aee3ebe;Рожни;Рожны;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823083302;3;1
7ad95163-749b-11df-b112-00215aee3ebe;Рожнів;Рожнов;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623685201;3;1
7ad95164-749b-11df-b112-00215aee3ebe;Рожнівка;Рожновка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421788001;3;1
7ad95165-749b-11df-b112-00215aee3ebe;Рожнятів;Рожнятов;місто;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624855100;3;1
7ad95166-749b-11df-b112-00215aee3ebe;Рожнятівка;Рожнятовка;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523955114;3;1
7ad95167-749b-11df-b112-00215aee3ebe;Рожнятівка;Рожнятовка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522582104;3;1
7ad951b2-749b-11df-b112-00215aee3ebe;Роз'їзд Жемчужне;Разъезд Жемчужное;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;;3;1
7ad951b3-749b-11df-b112-00215aee3ebe;Роз'їзд Черняхівки;Разезд Черняховки;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;;3;1
7ad95168-749b-11df-b112-00215aee3ebe;Роза;Роза;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124786603;3;1
7ad9516b-749b-11df-b112-00215aee3ebe;Розалівка;Розалевка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122985606;3;1
7ad9516c-749b-11df-b112-00215aee3ebe;Розалівка;Розалевка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123986404;3;1
7ad9516d-749b-11df-b112-00215aee3ebe;Розаліївка;Розалиевка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220485801;2;1
7ad9516e-749b-11df-b112-00215aee3ebe;Розанівка;Розановка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824583901;3;1
7ad9516f-749b-11df-b112-00215aee3ebe;Розбишівка;Розбишевка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320485901;3;1
7ad95170-749b-11df-b112-00215aee3ebe;Розвадів;Розвадов;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623086801;3;1
7ad95171-749b-11df-b112-00215aee3ebe;Розваж;Розваж;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4621880405;3;1
7ad95172-749b-11df-b112-00215aee3ebe;Розваж;Розваж;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624287001;3;1
7ad95173-749b-11df-b112-00215aee3ebe;Розважів;Розважев;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222084201;3;1
7ad95175-749b-11df-b112-00215aee3ebe;Розвинівка;Развиновка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421482802;3;1
7ad95174-749b-11df-b112-00215aee3ebe;Розворяни;Разваряны;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621882207;3;1
7ad95176-749b-11df-b112-00215aee3ebe;Розгадів;Розгадов;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122688501;3;1
7ad95177-749b-11df-b112-00215aee3ebe;Розгірче;Розгирче;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625385901;3;1
7ad95178-749b-11df-b112-00215aee3ebe;Розділ;Роздол;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623055400;3;1
7ad9517a-749b-11df-b112-00215aee3ebe;Роздільне;Раздельное;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624287001;3;1
7ad9517b-749b-11df-b112-00215aee3ebe;Роздол;Роздол;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121280808;3;1
7ad9517d-749b-11df-b112-00215aee3ebe;Роздол;Роздол;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121588805;3;1
7ad9517e-749b-11df-b112-00215aee3ebe;Роздол;Роздол;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521486301;3;1
7ad9517f-749b-11df-b112-00215aee3ebe;Роздолівка;Раздоловка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320686307;3;1
7ad95181-749b-11df-b112-00215aee3ebe;Роздолівка;Раздоловка;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522885901;3;1
7ad95182-749b-11df-b112-00215aee3ebe;Роздолля;Роздолье;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324586501;3;1
7ad95183-749b-11df-b112-00215aee3ebe;Роздолля;Роздолье;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321586111;3;1
7ad95188-749b-11df-b112-00215aee3ebe;Роздолля;Роздолье;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520882803;3;1
7ad95189-749b-11df-b112-00215aee3ebe;Роздолля;Роздолье;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520581502;3;1
7ad9518a-749b-11df-b112-00215aee3ebe;Роздолля;Роздолье;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522885903;3;1
17af7b2e-ddb9-11df-9197-00215aee3ebe;Роздолля;Роздолье;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224855608;3;1
7ad9518b-749b-11df-b112-00215aee3ebe;Роздольне;Раздольное;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321786007;3;1
7ad9518c-749b-11df-b112-00215aee3ebe;Роздольне;Раздольное;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;;2;1
7ad95194-749b-11df-b112-00215aee3ebe;Роздольне;Раздольное;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822086203;3;1
7ad95195-749b-11df-b112-00215aee3ebe;Роздольне;Раздольное;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423885003;3;1
8716258d-749b-11df-b112-00215aee3ebe;Роздольне (Чапаєва);Роздольное (Чапаева);село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325456201;3;1
7ad95196-749b-11df-b112-00215aee3ebe;Роздори;Раздоры;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224855600;3;1
7ad95197-749b-11df-b112-00215aee3ebe;Роздори;Раздоры;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223882002;3;1
7ad95198-749b-11df-b112-00215aee3ebe;Роздори;Раздоры;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323985004;3;1
7ad95199-749b-11df-b112-00215aee3ebe;Роздоріжне;Роздорожное;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621883202;3;1
7ad9519a-749b-11df-b112-00215aee3ebe;Розжалів;Розжалов;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4623984601;3;1
7ad9519f-749b-11df-b112-00215aee3ebe;Розівка;Розовка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225884814;3;1
7ad951a0-749b-11df-b112-00215aee3ebe;Розівка;Розовка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123181703;3;1
7ad951a1-749b-11df-b112-00215aee3ebe;Розівка;Розовка;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124585101;3;1
7ad951a2-749b-11df-b112-00215aee3ebe;Розівка;Розовка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323686611;3;1
7ad951a7-749b-11df-b112-00215aee3ebe;Розівка;Розовка;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;6521884504;3;1
7ad951ab-749b-11df-b112-00215aee3ebe;Розівка;Розовка;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2124887404;2;1
7ad951af-749b-11df-b112-00215aee3ebe;Розівка;Розовка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822384607;3;1
7ad951b0-749b-11df-b112-00215aee3ebe;Розівка;Розовка;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422086351;3;1
7ad951b1-749b-11df-b112-00215aee3ebe;Розівка;Розовка;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520882006;3;1
7ad951b4-749b-11df-b112-00215aee3ebe;Розквіт;Розквит;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283601;3;1
7ad951b6-749b-11df-b112-00215aee3ebe;Розкидайлівка;Раскидайловка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822587907;3;1
7ad951b7-749b-11df-b112-00215aee3ebe;Розкішна;Роскошная;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224285601;3;1
7ad951b8-749b-11df-b112-00215aee3ebe;Розкішне;Роскошное;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125484002;3;1
7ad951b9-749b-11df-b112-00215aee3ebe;Розкішне;Роскошное;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120880703;3;1
7ad951be-749b-11df-b112-00215aee3ebe;Розкішне;Роскошное;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521486501;3;1
7ad951bf-749b-11df-b112-00215aee3ebe;Розкопане;Раскопаное;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523484701;3;1
7ad951c0-749b-11df-b112-00215aee3ebe;Розкопанці;Раскопанцы;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220686201;3;1
7ad951c1-749b-11df-b112-00215aee3ebe;Розкопинці;Раскопинцы;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324081502;3;1
7ad951c2-749b-11df-b112-00215aee3ebe;Розкошівка;Роскошевка;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122884403;3;1
7ad951c3-749b-11df-b112-00215aee3ebe;Розкошівка;Роскошевка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523787203;3;1
7ad951c4-749b-11df-b112-00215aee3ebe;Розлив;Разлив;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520355102;3;1
7ad951c6-749b-11df-b112-00215aee3ebe;Розлива;Разлива;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523882504;3;1
7ad951c8-749b-11df-b112-00215aee3ebe;Розлуч;Розлуч;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625586901;3;1
7ad951c9-749b-11df-b112-00215aee3ebe;Розльоти;Разлеты;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422287401;3;1
7ad951ca-749b-11df-b112-00215aee3ebe;Розничі;Розничи;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723655409;3;1
7ad951cb-749b-11df-b112-00215aee3ebe;Розношенське;Разношенское;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525588201;3;1
7ad951cc-749b-11df-b112-00215aee3ebe;Розношинці;Розношинцы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122485402;3;1
7ad951cf-749b-11df-b112-00215aee3ebe;Розохуватець;Розохуватец;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
7ad951d1-749b-11df-b112-00215aee3ebe;Розпашне;Распашное;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325480303;3;1
7ad951d7-749b-11df-b112-00215aee3ebe;Розсішки;Россошки;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124685501;3;1
7ad951d8-749b-11df-b112-00215aee3ebe;Розсохи;Россохи;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623385602;3;1
7ad951d9-749b-11df-b112-00215aee3ebe;Розсохи;Россохи;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821588802;3;1
7ad951da-749b-11df-b112-00215aee3ebe;Розсохівка;Разсоховка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321255402;3;1
7ad951db-749b-11df-b112-00215aee3ebe;Розсохівське;Розсоховское;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755109;3;1
7ad951dc-749b-11df-b112-00215aee3ebe;Розсохувата;Розсоховатая;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323184504;3;1
7ad951dd-749b-11df-b112-00215aee3ebe;Розсохувата;Розсоховатая;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521286103;3;1
7ad951de-749b-11df-b112-00215aee3ebe;Розсохувата;Розсоховатая;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823087006;3;1
7ad951df-749b-11df-b112-00215aee3ebe;Розсохувате;Розсоховатое;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920382006;3;1
7ad951e0-749b-11df-b112-00215aee3ebe;Розсохувате;Розсоховатое;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322882003;3;1
74d8ec93-ab80-11e8-80d4-1c98ec135261;Розсохувате;Розсохувате;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322655109;3;1
7ad951e2-749b-11df-b112-00215aee3ebe;Розсохуватець;Розсоховатец;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523685001;3;1
7ad951e3-749b-11df-b112-00215aee3ebe;Розсохуватка;Розсоховатка;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122286501;3;1
7ad951e4-749b-11df-b112-00215aee3ebe;Розсохуватка;Розсоховатка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523185701;3;1
7ad951e5-749b-11df-b112-00215aee3ebe;Розсоша;Розсоша;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825087201;3;1
7ad951e7-749b-11df-b112-00215aee3ebe;Розсошенці;Розсошенцы;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324087705;1;0
7ad951e8-749b-11df-b112-00215aee3ebe;Розсошинці;Розсошинцы;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125482503;3;1
7ad951e9-749b-11df-b112-00215aee3ebe;Розсоші;Розсоши;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921284001;3;1
7ad951ea-749b-11df-b112-00215aee3ebe;Розсудів;Рассудов;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424488804;3;1
7ad951eb-749b-11df-b112-00215aee3ebe;Розтання;Растанье;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225083008;3;1
7ad951ec-749b-11df-b112-00215aee3ebe;Розтичеве;Ростычево;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520883603;3;1
7ad951ed-749b-11df-b112-00215aee3ebe;Розтісне;Растесное;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221887003;3;1
7ad951ee-749b-11df-b112-00215aee3ebe;Розтока;Розтока;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121585001;3;1
7ad951ef-749b-11df-b112-00215aee3ebe;Розтока;Розтока;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122485203;3;1
7ad951f0-749b-11df-b112-00215aee3ebe;Розтоки;Розтоки;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123486501;3;1
7ad951f1-749b-11df-b112-00215aee3ebe;Розтоки;Розтоки;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323584001;3;1
7ad951f2-749b-11df-b112-00215aee3ebe;Розтоки;Розтоки;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;2623685601;3;1
7ad951f3-749b-11df-b112-00215aee3ebe;Розтоки;Розтоки;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123686001;3;1
7ad951f4-749b-11df-b112-00215aee3ebe;Розтоцька Пастіль;Розтоцкая Пастиль;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120884001;3;1
7ad951f5-749b-11df-b112-00215aee3ebe;Розточки;Розточки;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622080506;3;1
7ad951f6-749b-11df-b112-00215aee3ebe;Розтяжин;Растяжин;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386604;3;1
7ad951f8-749b-11df-b112-00215aee3ebe;Розумівка;Разумовка;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321682903;3;1
7ad951f9-749b-11df-b112-00215aee3ebe;Розумівка;Разумовка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322183509;3;1
7ad951fa-749b-11df-b112-00215aee3ebe;Розумівка;Разумовка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225585802;3;1
7ad951fb-749b-11df-b112-00215aee3ebe;Розумівка;Разумовка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520585501;3;1
7ad951fc-749b-11df-b112-00215aee3ebe;Розумниця;Разумница;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224285201;3;1
7ad951fd-749b-11df-b112-00215aee3ebe;Роїще;Роище;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425587501;3;1
7ad951fe-749b-11df-b112-00215aee3ebe;Ройлянка;Ройлянка;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125082407;2;1
7ad951ff-749b-11df-b112-00215aee3ebe;Ройлянка;Ройлянка;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124585201;3;1
7ad95200-749b-11df-b112-00215aee3ebe;Ройове;Роево;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322481702;3;1
7ad95201-749b-11df-b112-00215aee3ebe;Рокети;Рокети;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622185919;3;1
7ad95202-749b-11df-b112-00215aee3ebe;Рокині;Рокини;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722855200;3;1
7ad95203-749b-11df-b112-00215aee3ebe;Рокита;Ракита;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320284701;3;1
7ad95204-749b-11df-b112-00215aee3ebe;Рокита;Ракита;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725081305;3;1
56bdd222-749b-11df-b112-00215aee3ebe;Рокити (Жовтневе);Рокити (Жовтневое);село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324582001;3;1
7ad95205-749b-11df-b112-00215aee3ebe;Рокитна Слобода;Ракитная Слобода;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222086805;3;1
7ad95206-749b-11df-b112-00215aee3ebe;Рокитне;Ракитное;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322485101;3;1
7ad95207-749b-11df-b112-00215aee3ebe;Рокитне;Ракитное;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922087403;3;1
7ad95208-749b-11df-b112-00215aee3ebe;Рокитне;Ракитное;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323085901;3;1
7ad95209-749b-11df-b112-00215aee3ebe;Рокитне;Ракитное;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324284501;3;1
7ad9520a-749b-11df-b112-00215aee3ebe;Рокитне;Ракитное;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324586004;3;1
7ad9520b-749b-11df-b112-00215aee3ebe;Рокитне;Ракитное;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620687404;3;1
7ad9520c-749b-11df-b112-00215aee3ebe;Рокитне;Ракитное;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625880818;3;1
7ad9520d-749b-11df-b112-00215aee3ebe;Рокитне;Ракитное;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225585803;3;1
7ad9520e-749b-11df-b112-00215aee3ebe;Рокитне;Ракитное;місто;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223755100;1;0
7ad9520f-749b-11df-b112-00215aee3ebe;Рокитне;Ракитное;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824284003;3;1
7ad95210-749b-11df-b112-00215aee3ebe;Рокитне;Ракитное;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420687406;3;1
7ad95211-749b-11df-b112-00215aee3ebe;Рокитне;Ракитное;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822180702;3;1
7ad95212-749b-11df-b112-00215aee3ebe;Рокитне;Ракитное;місто;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625055100;2;0
7ad95213-749b-11df-b112-00215aee3ebe;Рокитне;Ракитное;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623486503;3;1
7ad95214-749b-11df-b112-00215aee3ebe;Рокитне;Ракитное;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625085601;3;1
7ad95215-749b-11df-b112-00215aee3ebe;Рокитне-Донівка;Ракитно-Доновка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322482807;3;1
7ad95216-749b-11df-b112-00215aee3ebe;Рокитниця;Ракитница;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0720510418;3;1
7ad95217-749b-11df-b112-00215aee3ebe;Рокитниця;Ракитница;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722184004;3;1
7ad95218-749b-11df-b112-00215aee3ebe;Рокосово;Рокосово;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125387101;3;0
7ad95219-749b-11df-b112-00215aee3ebe;Роксолани;Роксоланы;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123783701;3;1
7ad9521a-749b-11df-b112-00215aee3ebe;Ролів;Ролев;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621286201;3;1
7ad9521b-749b-11df-b112-00215aee3ebe;Роля;Роля;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525380507;3;1
7ad9521c-749b-11df-b112-00215aee3ebe;Романи;Романы;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321382707;3;1
7ad9521e-749b-11df-b112-00215aee3ebe;Романиха;Романиха;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322688403;3;1
7ad9521f-749b-11df-b112-00215aee3ebe;Романів;Романов;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724581402;3;1
7ad95220-749b-11df-b112-00215aee3ebe;Романів;Романов;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722885601;3;1
7ad95221-749b-11df-b112-00215aee3ebe;Романів;Романов;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623386001;3;1
7ad95222-749b-11df-b112-00215aee3ebe;Романів;Романов;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825583202;3;1
7ad95223-749b-11df-b112-00215aee3ebe;Романів;Романов;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824786005;3;1
73e94be4-ddc1-11df-9197-00215aee3ebe;Романів;Романов;місто;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821455100;3;0
50c59408-749b-11df-b112-00215aee3ebe;Романів(Дзержинськ);Романов(Дзержинск);село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821455100;3;1
7ad95224-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321382002;3;1
7ad95225-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320683202;3;1
7ad95226-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721155102;3;1
7ad95227-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722885203;3;1
7ad95228-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125287101;2;1
7ad95229-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125086901;3;1
7ad9522a-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224883511;3;1
7ad9522b-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221888206;3;1
7ad9522c-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655125;3;1
7ad9522d-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821186060;3;1
7ad9522e-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122985103;3;1
7ad9522f-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120285001;3;1
7ad95230-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123581403;3;1
7ad95231-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120884209;3;1
7ad95232-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624887404;3;1
7ad95233-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623985503;3;1
7ad95234-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620984502;3;1
7ad95235-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621583605;3;1
7ad95239-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824080404;3;1
7ad9523a-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820984301;3;1
7ad9523b-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821484201;3;1
7ad9523c-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824785701;3;1
7ad9523d-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124089001;3;1
7ad9523e-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520483206;3;1
7ad9523f-749b-11df-b112-00215aee3ebe;Романівка;Романовка;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825281602;2;1
7ad94fa0-749b-11df-b112-00215aee3ebe;Романівка (Радянське);Романовка (Радянское);село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820885001;3;1
7ad9521d-749b-11df-b112-00215aee3ebe;Романіни;Романины;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823985303;3;1
a6f74837-d46b-11e0-a755-003048d2b473;Романіни;Романины;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;;3;1
7ad95241-749b-11df-b112-00215aee3ebe;Романки;Романки;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755149;3;1
7ad95242-749b-11df-b112-00215aee3ebe;Романки;Романки;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322485104;3;1
7ad95243-749b-11df-b112-00215aee3ebe;Романки;Романки;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224255111;3;1
7ad95244-749b-11df-b112-00215aee3ebe;Романків;Романков;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223186803;3;1
7ad95245-749b-11df-b112-00215aee3ebe;Романківці;Романковцы;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324088001;3;1
7ad95246-749b-11df-b112-00215aee3ebe;Романова Балка;Романова Балка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825485701;3;1
7ad95248-749b-11df-b112-00215aee3ebe;Романове Село;Романово Село;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122487601;3;1
7ad95249-749b-11df-b112-00215aee3ebe;Романово-Булгакове;Романово-Булгаково;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825780706;3;1
7ad9524a-749b-11df-b112-00215aee3ebe;Романово-Хутір;Романово-Хутор;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521282606;3;1
7ad9524b-749b-11df-b112-00215aee3ebe;Романчукове;Романчуково;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920981202;3;1
7ad9524c-749b-11df-b112-00215aee3ebe;Романьки;Романьки;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422081205;3;1
7ad9524d-749b-11df-b112-00215aee3ebe;Романькове;Романково;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925680809;3;1
7ad9524f-749b-11df-b112-00215aee3ebe;Ромашівка;Ромашовка;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125586801;3;1
7ad95250-749b-11df-b112-00215aee3ebe;Ромашка;Ромашка;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524984509;3;1
7ad95252-749b-11df-b112-00215aee3ebe;Ромашки;Ромашки;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222984003;3;1
7ad95253-749b-11df-b112-00215aee3ebe;Ромашки;Ромашки;село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223785001;3;1
7ad95254-749b-11df-b112-00215aee3ebe;Ромашки;Ромашки;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522880903;3;1
7ad95256-749b-11df-b112-00215aee3ebe;Ромашківка;Ромашковка;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721882405;3;1
7ad95257-749b-11df-b112-00215aee3ebe;Ромашкове;Ромашковое;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924485601;3;1
7ad95258-749b-11df-b112-00215aee3ebe;Ромашкове;Ромашковое;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520381407;3;1
7ad95259-749b-11df-b112-00215aee3ebe;Ромейки;Ромейки;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620888601;3;1
7ad9525a-749b-11df-b112-00215aee3ebe;Ромейкове;Ромейково;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122281503;3;1
7ad9525b-749b-11df-b112-00215aee3ebe;Ромни;Ромны;місто;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5910700000;2;0
7ad9525c-749b-11df-b112-00215aee3ebe;Ромодан;Ромодан;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322885204;3;1
7ad9525d-749b-11df-b112-00215aee3ebe;Ромодан;Ромодан;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323255700;3;1
7ad9525e-749b-11df-b112-00215aee3ebe;Ромочевиця;Ромочевица;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122782603;2;1
7ad9524e-749b-11df-b112-00215aee3ebe;Ромош;Ромош;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624886803;3;1
7ad9525f-749b-11df-b112-00215aee3ebe;Ропавське;Ропавское;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625585907;3;1
7ad95260-749b-11df-b112-00215aee3ebe;Роплянське;Роплянское;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324888217;3;1
7ad95261-749b-11df-b112-00215aee3ebe;Ропоне;Ропоне;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;;3;1
7ad95262-749b-11df-b112-00215aee3ebe;Ропотуха;Ропотуха;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124387001;3;1
7ad95263-749b-11df-b112-00215aee3ebe;Ропча;Ропча;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324588001;3;1
7ad95264-749b-11df-b112-00215aee3ebe;Росава;Росава;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222986701;3;1
7ad95265-749b-11df-b112-00215aee3ebe;Росівка;Росовка;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825687004;3;1
7ad95266-749b-11df-b112-00215aee3ebe;Російська Буда;Российская Буда;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823085403;3;1
7ad95267-749b-11df-b112-00215aee3ebe;Росільна;Росильна;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620482404;3;1
7ad95268-749b-11df-b112-00215aee3ebe;Росішка;Росошка;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123686501;3;1
7ad95269-749b-11df-b112-00215aee3ebe;Росішки;Росишки;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223882003;3;1
7ad9526a-749b-11df-b112-00215aee3ebe;Росішки;Росишки;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224610112;3;1
7ad9526b-749b-11df-b112-00215aee3ebe;Росіянівка;Россияновка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125284501;3;1
7ad9526c-749b-11df-b112-00215aee3ebe;Рославичі;Рославичи;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221487301;3;1
7ad9526d-749b-11df-b112-00215aee3ebe;Роснівка;Росновка;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625887615;3;1
7ad9526e-749b-11df-b112-00215aee3ebe;Росолівці;Росоловцы;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822788201;3;1
7ad9526f-749b-11df-b112-00215aee3ebe;Росохач;Росохач;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125587001;3;1
7ad95270-749b-11df-b112-00215aee3ebe;Росохач;Росохач;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621684301;3;1
7ad95271-749b-11df-b112-00215aee3ebe;Росохач;Росохач;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624586901;3;1
7ad95272-749b-11df-b112-00215aee3ebe;Росохи;Росохи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625181502;3;1
7ad95273-749b-11df-b112-00215aee3ebe;Росохуватець;Росоховатец;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124688005;3;1
59bf103a-5bae-11e1-b817-003048d2b473;Росохуватець;Росохуватец;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123084002;3;1
7ad95274-749b-11df-b112-00215aee3ebe;Росош;Росош;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124082307;3;1
7ad95275-749b-11df-b112-00215aee3ebe;Росош;Росош;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124483602;3;1
7ad95276-749b-11df-b112-00215aee3ebe;Росоша;Росоша;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522285601;3;1
7ad95277-749b-11df-b112-00215aee3ebe;Росоша;Росоша;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523786201;3;1
7ad95278-749b-11df-b112-00215aee3ebe;Росошани;Росошаны;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322088801;3;1
7ad95279-749b-11df-b112-00215aee3ebe;Роставиця;Раставица;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825285401;3;1
7ad9527a-749b-11df-b112-00215aee3ebe;Ростань;Ростань;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725785001;3;1
7ad9527b-749b-11df-b112-00215aee3ebe;Ростов;Ростов;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925655108;3;1
7ad9527c-749b-11df-b112-00215aee3ebe;Ростов'ятиця;Ростовьятица;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122784203;3;1
7ad9527e-749b-11df-b112-00215aee3ebe;Ростуще;Растущее;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322188602;3;1
7ad95280-749b-11df-b112-00215aee3ebe;Ротівка;Ротовка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923887705;3;1
7ad95281-749b-11df-b112-00215aee3ebe;Ротмістрівка;Ротмистровка;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123787201;3;1
7ad95282-749b-11df-b112-00215aee3ebe;Рохманів;Рохманов;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125886701;3;1
7ad95283-749b-11df-b112-00215aee3ebe;Рошнів;Рошнев;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625884901;3;1
7ad95284-749b-11df-b112-00215aee3ebe;Рошняте;Рошняте;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624884003;3;1
7ad95285-749b-11df-b112-00215aee3ebe;Роща;Роща;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923882306;3;1
7ad95286-749b-11df-b112-00215aee3ebe;Роща;Роща;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120480502;3;1
7ad95287-749b-11df-b112-00215aee3ebe;Роща;Роща;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423684709;3;1
7ad95288-749b-11df-b112-00215aee3ebe;Рощахівка;Рощаховка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520886401;3;1
7ad9528a-749b-11df-b112-00215aee3ebe;Рояківка;Рояковка;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323184801;3;1
7ad9528b-749b-11df-b112-00215aee3ebe;Роятин;Роятин;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624887202;3;1
7ad9528c-749b-11df-b112-00215aee3ebe;Ртищівка;Ртищевка;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325483004;3;1
7ad9528d-749b-11df-b112-00215aee3ebe;Рубани;Рубаны;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321882003;3;1
7ad9528e-749b-11df-b112-00215aee3ebe;Рубани;Рубаны;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920381602;3;1
7ad9528f-749b-11df-b112-00215aee3ebe;Рубаний Міст;Рубаный Мост;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122886001;3;1
7ad95290-749b-11df-b112-00215aee3ebe;Рубаний Міст;Рубаный Мост;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523886801;3;1
7ad95291-749b-11df-b112-00215aee3ebe;Рубанів;Рубанов;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425382807;3;1
7ad95292-749b-11df-b112-00215aee3ebe;Рубанів;Рубанов;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421184003;3;1
7ad95293-749b-11df-b112-00215aee3ebe;Рубанівка;Рубановка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323087204;3;1
7ad95294-749b-11df-b112-00215aee3ebe;Рубанівка;Рубановка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
7ad95296-749b-11df-b112-00215aee3ebe;Рубанівське;Рубановское;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755137;3;1
7ad95297-749b-11df-b112-00215aee3ebe;Рубанка;Рубанка;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923585001;3;1
7ad95298-749b-11df-b112-00215aee3ebe;Рубанка;Рубанка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823483604;3;1
7ad95299-749b-11df-b112-00215aee3ebe;Рубанка;Рубанка;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420387501;3;1
7ad9529a-749b-11df-b112-00215aee3ebe;Рубанка;Рубанка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521487606;3;1
7ad9529b-749b-11df-b112-00215aee3ebe;Рубанників;Рубанников;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422280505;3;1
7ad9529c-749b-11df-b112-00215aee3ebe;Рубань;Рубань;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523086601;3;1
7ad9529e-749b-11df-b112-00215aee3ebe;Рубежівка;Рубежовка;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755148;3;1
7ad952a0-749b-11df-b112-00215aee3ebe;Рубіж;Рубеж;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421485603;3;1
17af7b15-ddb9-11df-9197-00215aee3ebe;Рубіжне;Рубежное;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523089202;3;1
871626cf-749b-11df-b112-00215aee3ebe;Рубіжне (Червоний Шахтар);Рубежное (Червоный Шахтер);село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323985506;3;1
7ad952a5-749b-11df-b112-00215aee3ebe;Рублин;Рублин;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121255503;3;1
7ad952a6-749b-11df-b112-00215aee3ebe;Рублівка;Рублевка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;;3;1
7ad952a7-749b-11df-b112-00215aee3ebe;Рублівка;Рублевка;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125484503;3;1
7ad952a8-749b-11df-b112-00215aee3ebe;Рублянка;Рублянка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822755202;3;1
7ad952aa-749b-11df-b112-00215aee3ebe;Рубче;Рубче;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623380706;3;1
7ad952ab-749b-11df-b112-00215aee3ebe;Рубче;Рубче;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624683307;3;1
7ad952ac-749b-11df-b112-00215aee3ebe;Рубченки;Рубченки;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221686801;3;1
7ad952ad-749b-11df-b112-00215aee3ebe;Руда;Руда;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320683203;3;1
7ad952ae-749b-11df-b112-00215aee3ebe;Руда;Руда;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920681003;3;1
7ad952af-749b-11df-b112-00215aee3ebe;Руда;Руда;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922989604;3;1
7ad952b0-749b-11df-b112-00215aee3ebe;Руда;Руда;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723386502;3;1
7ad952b1-749b-11df-b112-00215aee3ebe;Руда;Руда;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725580803;3;1
7ad952b2-749b-11df-b112-00215aee3ebe;Руда;Руда;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624484403;3;1
7ad952b3-749b-11df-b112-00215aee3ebe;Руда;Руда;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622789206;3;1
7ad952b4-749b-11df-b112-00215aee3ebe;Руда;Руда;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622184907;3;1
7ad952b5-749b-11df-b112-00215aee3ebe;Руда;Руда;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621885207;3;1
7ad952b6-749b-11df-b112-00215aee3ebe;Руда;Руда;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625855903;3;1
7ad952b7-749b-11df-b112-00215aee3ebe;Руда;Руда;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621555305;3;1
7ad952b8-749b-11df-b112-00215aee3ebe;Руда;Руда;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224086401;2;1
7ad952b9-749b-11df-b112-00215aee3ebe;Руда;Руда;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425883507;3;1
7ad952ba-749b-11df-b112-00215aee3ebe;Руда;Руда;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822486801;3;1
7ad952bb-749b-11df-b112-00215aee3ebe;Руда;Руда;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322687205;3;1
c8674a99-dbb0-11df-9197-00215aee3ebe;Руда;Руда;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323282202;3;1
7ad952bd-749b-11df-b112-00215aee3ebe;Руда-Брідська;Руда-Бродская;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620382010;3;1
7ad952be-749b-11df-b112-00215aee3ebe;Руда-Гірчичнянська;Руда-Горчичнянская;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821885302;3;1
7ad952bf-749b-11df-b112-00215aee3ebe;Рудаєве;Рудаево;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320686308;3;1
7ad952c0-749b-11df-b112-00215aee3ebe;Рудак;Рудак;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924410104;3;1
7ad952c1-749b-11df-b112-00215aee3ebe;Руда-Колтівська;Руда-Колтовская;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621884601;3;1
7ad952c2-749b-11df-b112-00215aee3ebe;Руда-Краковецька;Руда-Краковецкая;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625887804;3;1
7ad952bc-749b-11df-b112-00215aee3ebe;Руда-Красна;Руда-Красная;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624684503;3;1
7ad952c3-749b-11df-b112-00215aee3ebe;Руда-Крехівська;Руда-Креховская;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784407;3;1
7ad952c4-749b-11df-b112-00215aee3ebe;Руданське;Руданское;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525387201;3;1
7ad952c5-749b-11df-b112-00215aee3ebe;Руданці;Руданцы;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622186203;3;1
7ad952c6-749b-11df-b112-00215aee3ebe;Руда-Сілецька;Руда-Селецкая;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622184908;3;1
7ad952c7-749b-11df-b112-00215aee3ebe;Руде Село;Рудое Село;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221687201;2;1
cdc44f41-dde8-11df-9197-00215aee3ebe;Рудево - Миколаївка;Рудево-Николаевка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224888509;3;1
7ad952c8-749b-11df-b112-00215aee3ebe;Руденка;Руденка;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925681905;3;1
7ad952ca-749b-11df-b112-00215aee3ebe;Руденки-Гончарі;Руденки-Гончары;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321386907;3;1
7ad952cc-749b-11df-b112-00215aee3ebe;Руденківка;Руденковка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323485501;3;1
7ad952cd-749b-11df-b112-00215aee3ebe;Руденко;Руденко;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623982204;3;1
7ad952ce-749b-11df-b112-00215aee3ebe;Руденкове;Руденково;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920688603;3;1
7ad952cf-749b-11df-b112-00215aee3ebe;Руденька;Руденька;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821755103;3;1
7ad952d0-749b-11df-b112-00215aee3ebe;Руденька;Руденька;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822087209;3;1
7ad952d1-749b-11df-b112-00215aee3ebe;Руденька;Руденька;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822587305;3;1
7ad952d2-749b-11df-b112-00215aee3ebe;Рудий Байрак;Рудой Байрак;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321282508;3;1
7ad952d3-749b-11df-b112-00215aee3ebe;Рудиків;Рудиков;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5310390009;3;1
7ad952d6-749b-11df-b112-00215aee3ebe;Рудівка;Рудовка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424187601;3;1
7ad952d7-749b-11df-b112-00215aee3ebe;Рудка;Рудка;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155105;3;1
7ad952d8-749b-11df-b112-00215aee3ebe;Рудка;Рудка;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725083202;3;1
7ad952d9-749b-11df-b112-00215aee3ebe;Рудка;Рудка;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723680603;3;1
7ad952da-749b-11df-b112-00215aee3ebe;Рудка;Рудка;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123483103;3;1
7ad952db-749b-11df-b112-00215aee3ebe;Рудка;Рудка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225682004;3;1
7ad952dc-749b-11df-b112-00215aee3ebe;Рудка;Рудка;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321580403;3;1
7ad952dd-749b-11df-b112-00215aee3ebe;Рудка;Рудка;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624884205;3;1
7ad952de-749b-11df-b112-00215aee3ebe;Рудка;Рудка;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824783002;3;1
7ad952df-749b-11df-b112-00215aee3ebe;Рудка;Рудка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425587701;3;1
7ad952e0-749b-11df-b112-00215aee3ebe;Рудка;Рудка;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123184002;3;1
7ad952e1-749b-11df-b112-00215aee3ebe;Рудка;Рудка;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825286203;2;1
7ad952e2-749b-11df-b112-00215aee3ebe;Рудка;Рудка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821887701;3;1
7ad952e3-749b-11df-b112-00215aee3ebe;Рудка;Рудка;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620881204;3;1
7ad952e4-749b-11df-b112-00215aee3ebe;Рудка;Рудка;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621485006;3;1
7ad952e5-749b-11df-b112-00215aee3ebe;Рудка;Рудка;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322686603;3;1
7ad952e6-749b-11df-b112-00215aee3ebe;Рудка;Рудка;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320884601;3;1
c6cc1bf7-db7e-11df-9197-00215aee3ebe;Рудка;Рудка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920685704;3;1
7ad952e7-749b-11df-b112-00215aee3ebe;Рудка-Козинська;Рудка-Козинская;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724585601;3;1
7ad952e8-749b-11df-b112-00215aee3ebe;Рудка-Миринська;Рудка-Миринская;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722181210;3;1
7ad952e9-749b-11df-b112-00215aee3ebe;Рудка-Червинська;Рудка-Червинская;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721485402;3;1
7ad952ea-749b-11df-b112-00215aee3ebe;Рудки;Рудки;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121689602;3;1
7ad952eb-749b-11df-b112-00215aee3ebe;Рудки;Рудки;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624210400;3;1
7ad952ec-749b-11df-b112-00215aee3ebe;Рудківка;Рудковка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822080904;3;1
7ad952ed-749b-11df-b112-00215aee3ebe;Рудківці;Рудковцы;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621587503;3;1
7ad952ee-749b-11df-b112-00215aee3ebe;Рудківці;Рудковцы;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823386504;3;1
7ad952ef-749b-11df-b112-00215aee3ebe;Рудливе;Рудливо;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623881603;3;1
7ad952f0-749b-11df-b112-00215aee3ebe;Рудне;Рудне;село;8a199cde-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4610165500;2;0
7ad952f2-749b-11df-b112-00215aee3ebe;Рудневе;Руднево;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322886508;3;1
7ad952f3-749b-11df-b112-00215aee3ebe;Руднєве;Руднево;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923887201;3;1
7ad952f4-749b-11df-b112-00215aee3ebe;Рудник;Рудник;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521985507;3;1
7ad952f5-749b-11df-b112-00215aee3ebe;Рудники;Рудники;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723655410;3;1
7ad952f6-749b-11df-b112-00215aee3ebe;Рудники;Рудники;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124883502;3;1
7ad952f7-749b-11df-b112-00215aee3ebe;Рудники;Рудники;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625255308;3;1
7ad952f8-749b-11df-b112-00215aee3ebe;Рудники;Рудники;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623087201;3;1
7ad952f9-749b-11df-b112-00215aee3ebe;Рудниця;Рудница;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523255400;3;1
7ad952fa-749b-11df-b112-00215aee3ebe;Рудницьке;Рудницкое;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220286501;3;1
7ad952fb-749b-11df-b112-00215aee3ebe;Рудницьке;Рудницкое;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523282601;3;1
7ad952fc-749b-11df-b112-00215aee3ebe;Рудничне;Рудничное;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221887005;3;1
7ad952fd-749b-11df-b112-00215aee3ebe;Руднівка;Рудневка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924784202;3;1
7ad952fe-749b-11df-b112-00215aee3ebe;Рудня;Рудня;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924481305;3;1
7ad952ff-749b-11df-b112-00215aee3ebe;Рудня;Рудня;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725085005;3;1
7ad95300-749b-11df-b112-00215aee3ebe;Рудня;Рудня;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724585901;3;1
7ad95301-749b-11df-b112-00215aee3ebe;Рудня;Рудня;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221287601;3;1
7ad95302-749b-11df-b112-00215aee3ebe;Рудня;Рудня;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824482002;3;1
7ad95303-749b-11df-b112-00215aee3ebe;Рудня;Рудня;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824286701;3;1
7ad95304-749b-11df-b112-00215aee3ebe;Рудня;Рудня;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386034;3;1
7ad95305-749b-11df-b112-00215aee3ebe;Рудня;Рудня;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825686602;3;1
7ad95306-749b-11df-b112-00215aee3ebe;Рудня;Рудня;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822587909;3;1
7ad95307-749b-11df-b112-00215aee3ebe;Рудня;Рудня;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820684804;3;1
7ad95308-749b-11df-b112-00215aee3ebe;Рудня;Рудня;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422489602;3;1
7ad95309-749b-11df-b112-00215aee3ebe;Рудня;Рудня;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425586308;3;1
7ad9530a-749b-11df-b112-00215aee3ebe;Рудня;Рудня;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424984002;3;1
7ad9530b-749b-11df-b112-00215aee3ebe;Рудня;Рудня;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422085603;3;1
7ad9530c-749b-11df-b112-00215aee3ebe;Рудня;Рудня;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823084601;3;1
7ad9530d-749b-11df-b112-00215aee3ebe;Рудня;Рудня;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621884307;3;1
7ad9530e-749b-11df-b112-00215aee3ebe;Рудня;Рудня;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5623484207;3;1
7ad9530f-749b-11df-b112-00215aee3ebe;Рудня;Рудня;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625887305;3;1
7ad952f1-749b-11df-b112-00215aee3ebe;Рудня;Рудня;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624280603;2;1
73e94be2-ddc1-11df-9197-00215aee3ebe;Рудня-Базарська;Рудня-Базарская;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755149;3;1
7ad95311-749b-11df-b112-00215aee3ebe;Рудня-Бистра;Рудня-Быстрая;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824486201;3;1
7ad95312-749b-11df-b112-00215aee3ebe;Рудня-Білківська;Рудня-Белковская;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822380202;3;1
7ad95313-749b-11df-b112-00215aee3ebe;Рудня-Вороб'ївська;Рудня-Воробьевская;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823486204;3;1
7ad95314-749b-11df-b112-00215aee3ebe;Рудня-Гацьківка;Рудня-Гацковка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821183004;3;1
7ad95315-749b-11df-b112-00215aee3ebe;Рудня-Городецька;Рудня-Городецкая;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825085203;3;1
7ad95316-749b-11df-b112-00215aee3ebe;Рудня-Городище;Рудня-Городище;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822086201;3;1
7ad95317-749b-11df-b112-00215aee3ebe;Рудня-Городищенська;Рудня-Городищенская;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823482206;3;1
7ad95318-749b-11df-b112-00215aee3ebe;Рудня-Димерська;Рудня-Дымерская;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221887201;3;1
7ad95319-749b-11df-b112-00215aee3ebe;Рудня-Жеревці;Рудня-Жеревцы;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822881604;3;1
7ad9531a-749b-11df-b112-00215aee3ebe;Рудня-Замисловицька;Рудня-Замысловичская;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824482402;3;1
7ad95310-749b-11df-b112-00215aee3ebe;Рудня-Іванівська;Рудня-Ивановская;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821784801;3;1
7ad9531b-749b-11df-b112-00215aee3ebe;Рудня-Калинівка;Рудня-Калиновка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823484602;3;1
7ad9531c-749b-11df-b112-00215aee3ebe;Рудня-Кам'янка;Рудня-Каменка;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755110;3;1
7ad9531d-749b-11df-b112-00215aee3ebe;Рудня-Карпилівська;Рудня-Карпиловская;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625482302;3;1
7ad9531e-749b-11df-b112-00215aee3ebe;Рудня-Левківська;Рудня-Левківська;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222080903;3;1
7ad9531f-749b-11df-b112-00215aee3ebe;Рудня-Мигальська;Рудня-Мигальская;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;;3;1
7ad95320-749b-11df-b112-00215aee3ebe;Рудня-Миколаївка;Рудня-Николаевка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821784204;3;1
7ad95321-749b-11df-b112-00215aee3ebe;Рудня-Новенька;Рудня-Новенькая;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825588505;3;1
7ad95322-749b-11df-b112-00215aee3ebe;Рудня-Озерянська;Рудня-Озерянская;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824456002;3;1
7ad95323-749b-11df-b112-00215aee3ebe;Рудня-Перганська;Рудня-Перганская;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824487603;3;1
7ad95324-749b-11df-b112-00215aee3ebe;Рудня-Повчанська;Рудня-Повчанская;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822884403;3;1
7ad95325-749b-11df-b112-00215aee3ebe;Рудня-Пошта;Рудня-Почта;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822081503;3;1
7ad95326-749b-11df-b112-00215aee3ebe;Рудня-Радовельська;Рудня-Радовельская;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824486004;3;1
7ad95327-749b-11df-b112-00215aee3ebe;Рудня-Сидорівська;Рудня-Сидоровская;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222081603;3;1
7ad95328-749b-11df-b112-00215aee3ebe;Рудня-Тальська;Рудня-Тальская;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222086402;3;1
7ad95329-749b-11df-b112-00215aee3ebe;Рудня-Ушомирська;Рудня-Ушомирская;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386006;3;1
7ad9532a-749b-11df-b112-00215aee3ebe;Рудня-Фасова;Рудня-Фасова;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821186504;3;1
7ad9532b-749b-11df-b112-00215aee3ebe;Рудня-Хочинська;Рудня-Хочинская;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824487604;3;1
7ad9532c-749b-11df-b112-00215aee3ebe;Рудня-Шляхова;Рудня-Шляхова;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821155102;3;1
7ad9532d-749b-11df-b112-00215aee3ebe;Рудня-Шпилівська;Рудня-Шпилевская;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222086403;3;1
7ad9532f-749b-11df-b112-00215aee3ebe;Рудокопи;Рудокопы;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825480202;3;1
7ad95330-749b-11df-b112-00215aee3ebe;Рудоман;Рудоман;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923285607;3;1
7ad95331-749b-11df-b112-00215aee3ebe;Рудьківка;Рудьковка;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420686801;3;1
7ad95332-749b-11df-b112-00215aee3ebe;Ружа;Ружа;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825284602;2;1
7ad95333-749b-11df-b112-00215aee3ebe;Ружин;Ружин;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725585601;3;1
7ad95334-749b-11df-b112-00215aee3ebe;Ружин;Ружин;місто;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825255100;3;1
7ad95335-749b-11df-b112-00215aee3ebe;Ружицьке;Ружицкое;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0510690003;3;1
7ad95336-749b-11df-b112-00215aee3ebe;Ружичанка;Ружичанка;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825087301;3;0
7ad95337-749b-11df-b112-00215aee3ebe;Ружичеве;Ружичево;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520588502;3;1
7ad950e3-749b-11df-b112-00215aee3ebe;Ружки;Ружки;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822085303;3;1
7ad95338-749b-11df-b112-00215aee3ebe;Рукомиш;Рукомыш;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121281006;3;1
7ad95339-749b-11df-b112-00215aee3ebe;Рукшин;Рукшин;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325087601;3;1
7ad9533a-749b-11df-b112-00215aee3ebe;Рулево;Рулевое;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625888603;3;1
7ad9533b-749b-11df-b112-00215aee3ebe;Руликів;Руликов;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221486902;3;1
7ad9533c-749b-11df-b112-00215aee3ebe;Рунгури;Рунгуры;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623255706;3;1
7ad9533d-749b-11df-b112-00215aee3ebe;Рунівщина;Руновщина;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324084601;3;1
7ad9533e-749b-11df-b112-00215aee3ebe;Рунівщина;Руновщина;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322283501;3;1
7ad9533f-749b-11df-b112-00215aee3ebe;Рункошів;Рункошев;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822481504;3;1
7ad95341-749b-11df-b112-00215aee3ebe;Руня;Руня;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124480405;3;1
7ad95342-749b-11df-b112-00215aee3ebe;Русава;Русава;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525684301;3;1
7ad95343-749b-11df-b112-00215aee3ebe;Русава;Русава;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523981603;3;1
7ad95344-749b-11df-b112-00215aee3ebe;Русаки;Русаки;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222082104;3;1
7ad95346-749b-11df-b112-00215aee3ebe;Русакове;Русаково;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223882510;3;1
7ad95347-749b-11df-b112-00215aee3ebe;Русалівка;Русаловка;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123188001;3;1
7ad95348-749b-11df-b112-00215aee3ebe;Русанів;Русанов;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221288001;3;1
7ad95349-749b-11df-b112-00215aee3ebe;Русанівка;Русановка;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923285201;3;1
7ad9534b-749b-11df-b112-00215aee3ebe;Русанівка;Русановка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825087402;3;1
7ad9534c-749b-11df-b112-00215aee3ebe;Русанівці;Русановцы;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823081202;3;1
7ad9534d-749b-11df-b112-00215aee3ebe;Русивель;Русивель;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621287110;3;1
7ad9534e-749b-11df-b112-00215aee3ebe;Русилів;Русилов;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121285901;3;1
7ad9534f-749b-11df-b112-00215aee3ebe;Русилів;Русилов;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620680802;3;1
7ad95350-749b-11df-b112-00215aee3ebe;Русин;Русин;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624883904;3;1
7ad95352-749b-11df-b112-00215aee3ebe;Русів;Русов;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625284901;3;1
cdc44f3a-dde8-11df-9197-00215aee3ebe;Русів;Русов;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720510419;3;1
7ad95353-749b-11df-b112-00215aee3ebe;Руснів;Руснов;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720582018;3;1
7ad95354-749b-11df-b112-00215aee3ebe;Русовичі;Русовичи;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721183305;3;1
7ad95355-749b-11df-b112-00215aee3ebe;Руська;Русская;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323584503;3;1
7ad95356-749b-11df-b112-00215aee3ebe;Руська Гута;Русская Гута;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125880304;3;1
7ad95357-749b-11df-b112-00215aee3ebe;Руська Долина;Русская Долина;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121282505;3;1
7ad95358-749b-11df-b112-00215aee3ebe;Руська Кучава;Русская Кучава;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122783404;3;1
7ad95359-749b-11df-b112-00215aee3ebe;Руська Лозова;Русская Лозовая;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322082001;3;1
7ad9535a-749b-11df-b112-00215aee3ebe;Руська Мокра;Русская Мокрая;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124486101;3;1
7ad9535b-749b-11df-b112-00215aee3ebe;Руська Поляна;Русская Поляна;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124985201;3;0
7ad9535c-749b-11df-b112-00215aee3ebe;Руська Слобідка;Русская Слободка;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121883803;3;1
7ad9535d-749b-11df-b112-00215aee3ebe;Руське;Русское;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122786809;3;1
7ad9535f-749b-11df-b112-00215aee3ebe;Руське Поле;Русское Поле;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124410104;3;0
7ad95360-749b-11df-b112-00215aee3ebe;Руський Мочар;Русский Мочар;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120884004;3;1
7ad95361-749b-11df-b112-00215aee3ebe;Руські Геївці;Русские Геевцы;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124880603;3;1
7ad95362-749b-11df-b112-00215aee3ebe;Руські Комарівці;Русские Комаровцы;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124881803;3;1
7ad95363-749b-11df-b112-00215aee3ebe;Руські Тишки;Русские Тишки;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325183501;3;1
7ad95364-749b-11df-b112-00215aee3ebe;Руськоіванівка;Русскоивановка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120886001;3;1
7ad95366-749b-11df-b112-00215aee3ebe;Рутвянка;Рутвянка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823485507;3;1
7ad95367-749b-11df-b112-00215aee3ebe;Рутка;Рутка;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
7ad95368-749b-11df-b112-00215aee3ebe;Рухотин;Рухотин;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325088001;3;1
7ad95369-749b-11df-b112-00215aee3ebe;Ручаївка;Ручаевка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322188807;3;1
7ad9536a-749b-11df-b112-00215aee3ebe;Ручайки;Ручайки;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523482404;3;1
7ad9536c-749b-11df-b112-00215aee3ebe;Ручки;Ручки;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322680703;3;1
7ad9536d-749b-11df-b112-00215aee3ebe;Ручки;Ручки;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320486201;3;1
7ad9536e-749b-11df-b112-00215aee3ebe;Рюмівське;Рюмовское;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083818;3;1
7ad95370-749b-11df-b112-00215aee3ebe;Рябина;Рябина;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921284401;3;1
7ad95371-749b-11df-b112-00215aee3ebe;Рябівка;Рябовка;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925082002;3;1
7ad95372-749b-11df-b112-00215aee3ebe;Рябіївка;Рябиевка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820986501;3;1
7ad95373-749b-11df-b112-00215aee3ebe;Рябківка;Рябковка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325455304;3;1
7ad95374-749b-11df-b112-00215aee3ebe;Рябоконеве;Рябоконево;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820380409;3;1
7ad95375-749b-11df-b112-00215aee3ebe;Рябоконеве;Рябоконево;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323586201;3;1
7ad95376-749b-11df-b112-00215aee3ebe;Рябухи;Рябухи;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425383001;3;1
7ad95377-749b-11df-b112-00215aee3ebe;Рябухине;Рябухино;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324255304;3;1
7ad95378-749b-11df-b112-00215aee3ebe;Рябушки;Рябушки;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922988201;3;1
7ad95379-749b-11df-b112-00215aee3ebe;Рябці;Рябцы;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425589304;3;1
7ad9537a-749b-11df-b112-00215aee3ebe;Рядове;Рядовое;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524980403;3;1
7ad9537b-749b-11df-b112-00215aee3ebe;Ряпідь;Ряпидь;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125380603;3;1
7ad9537c-749b-11df-b112-00215aee3ebe;Рясне;Рясное;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922385001;3;1
7ad9537d-749b-11df-b112-00215aee3ebe;Рясне;Рясное;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225083503;3;1
7ad9537e-749b-11df-b112-00215aee3ebe;Рясне;Рясное;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322685007;3;1
7ad9537f-749b-11df-b112-00215aee3ebe;Рясне;Рясное;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320680505;3;1
7ad95381-749b-11df-b112-00215aee3ebe;Рясне;Рясное;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821785201;3;1
7ad95382-749b-11df-b112-00215aee3ebe;Рясне;Рясное;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522887102;3;1
7ad95383-749b-11df-b112-00215aee3ebe;Рясне-Руське;Рясное-Русское;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625887501;2;0
7ad95384-749b-11df-b112-00215aee3ebe;Рясники;Рясники;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621286301;3;1
7ad95385-749b-11df-b112-00215aee3ebe;Ряснопіль;Ряснополь;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283801;3;1
7ad95386-749b-11df-b112-00215aee3ebe;Ряське;Рясское;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323085601;3;1
7ad95387-749b-11df-b112-00215aee3ebe;Ряшки;Ряшки;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424187901;3;1
325c580d-ddd2-11df-9197-00215aee3ebe;Сабадаш;Сабадаш;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120986601;3;1
7ad95388-749b-11df-b112-00215aee3ebe;Сабадашеве;Собадашево;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524981303;3;1
80f555a3-749b-11df-b112-00215aee3ebe;Сабанівка;Собановка;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623986602;3;1
7ad95389-749b-11df-b112-00215aee3ebe;Сабарівка;Сабаровка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523184701;3;1
7ad9538a-749b-11df-b112-00215aee3ebe;Сабатинівка;Сабатиновка;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525588601;3;1
7ad9538d-749b-11df-b112-00215aee3ebe;Саблине;Саблино;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522280803;3;1
7ad9538e-749b-11df-b112-00215aee3ebe;Саблуківка;Саблуковка;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520681802;3;1
7ad9538f-749b-11df-b112-00215aee3ebe;Сабове;Сабово;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523682703;3;1
7ad95390-749b-11df-b112-00215aee3ebe;Саварка;Саварка;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220686601;3;1
7ad95391-749b-11df-b112-00215aee3ebe;Савелівка;Савелевка;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124287202;3;1
7ad95393-749b-11df-b112-00215aee3ebe;Савелове;Савелово;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925086702;3;1
7ad95394-749b-11df-b112-00215aee3ebe;Савенки;Савенки;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982704;3;1
7ad95395-749b-11df-b112-00215aee3ebe;Савенки;Савенки;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221880403;3;1
7ad95396-749b-11df-b112-00215aee3ebe;Саверське;Саверское;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421182802;3;1
7ad95397-749b-11df-b112-00215aee3ebe;Саверці;Саверцы;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824786001;3;1
7ad95398-749b-11df-b112-00215aee3ebe;Саверці;Саверцы;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825584102;3;1
7ad95399-749b-11df-b112-00215aee3ebe;Савин;Савин;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422088701;3;1
7ad9539a-749b-11df-b112-00215aee3ebe;Савинка;Савинка;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422086804;3;1
7ad9539b-749b-11df-b112-00215aee3ebe;Савинки;Савинки;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422488301;3;1
7ad9539c-749b-11df-b112-00215aee3ebe;Савинці;Савинцы;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320255700;3;1
7ad9539d-749b-11df-b112-00215aee3ebe;Савинці;Савинцы;село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223785501;3;1
7ad9539e-749b-11df-b112-00215aee3ebe;Савинці;Савинцы;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425187001;3;1
7ad9539f-749b-11df-b112-00215aee3ebe;Савинці;Савинцы;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524184801;3;1
7ad953a0-749b-11df-b112-00215aee3ebe;Савинці;Савинцы;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825886001;3;1
7ad953a1-749b-11df-b112-00215aee3ebe;Савинці;Савинцы;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323287201;3;1
7ad953a2-749b-11df-b112-00215aee3ebe;Савинці;Савинцы;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323685101;3;1
7ad953a3-749b-11df-b112-00215aee3ebe;Савинці-1;Савинци-1;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320255700;3;1
7ad953a5-749b-11df-b112-00215aee3ebe;Савичі;Савичи;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825589004;3;1
58d8eded-eaf2-11e3-9b87-003048d2b473;Савківка;Савковка;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125155101;3;1
7ad953a7-749b-11df-b112-00215aee3ebe;Савлуки;Савлуки;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823484603;3;1
7ad953a8-749b-11df-b112-00215aee3ebe;Савойське;Савойское;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922087605;3;1
7ad953a9-749b-11df-b112-00215aee3ebe;Савойське;Савойское;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187109;3;1
7ad953aa-749b-11df-b112-00215aee3ebe;Савранське;Савранское;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120687502;3;1
7ad953ab-749b-11df-b112-00215aee3ebe;Саврань;Саврань;місто;6b07f835-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5124355100;2;1
7ad953ad-749b-11df-b112-00215aee3ebe;Саврань;Саврань;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224086603;3;1
7ad953ae-749b-11df-b112-00215aee3ebe;Савро;Савро;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224585901;3;1
7ad953af-749b-11df-b112-00215aee3ebe;Савулівка;Сауловка;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;;3;1
7ad953b0-749b-11df-b112-00215aee3ebe;Савчин;Савчин;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624886201;3;1
7ad953b1-749b-11df-b112-00215aee3ebe;Савчине;Савчино;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521986001;3;1
7ad953b2-749b-11df-b112-00215aee3ebe;Савчинське;Савчинское;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125280911;3;1
7ad953b3-749b-11df-b112-00215aee3ebe;Савчиці;Савчицы;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123482304;3;1
7ad953b4-749b-11df-b112-00215aee3ebe;Савчуки;Савчуки;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625883904;3;1
7ad953b5-749b-11df-b112-00215aee3ebe;Сагайдак;Сагайдак;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755150;3;1
7ad953b6-749b-11df-b112-00215aee3ebe;Сагайдак;Сагайдак;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123184202;3;1
7ad953b7-749b-11df-b112-00215aee3ebe;Сагайдачне;Сагайдачное;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120355402;3;1
7ad953b9-749b-11df-b112-00215aee3ebe;Сагунівка;Сагуновка;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124985501;3;1
7ad953ba-749b-11df-b112-00215aee3ebe;Сад;Сад;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786801;3;1
7ad953bb-749b-11df-b112-00215aee3ebe;Сад;Сад;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224855900;3;1
7ad953bc-749b-11df-b112-00215aee3ebe;Саджава;Саджава;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620488401;3;1
7ad953bd-749b-11df-b112-00215aee3ebe;Саджавка;Саджавка;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624086001;3;1
7ad953be-749b-11df-b112-00215aee3ebe;Саджівка;Саджевка;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121686501;3;1
7ad953bf-749b-11df-b112-00215aee3ebe;Саджівка;Саджевка;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124283203;3;1
7ad953c0-749b-11df-b112-00215aee3ebe;Сади;Сады;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;;3;1
7ad953c1-749b-11df-b112-00215aee3ebe;Садибне;Садыбное;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825487004;3;1
7ad953c2-749b-11df-b112-00215aee3ebe;Садибне;Садыбное;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386007;3;1
7ad953c3-749b-11df-b112-00215aee3ebe;Садів;Садов;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722886001;3;1
7ad953c4-749b-11df-b112-00215aee3ebe;Садівські Дубини;Садовские Дубины;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722486304;3;1
7ad953c5-749b-11df-b112-00215aee3ebe;Садки;Садки;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322481703;3;1
7ad953c6-749b-11df-b112-00215aee3ebe;Садки;Садки;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924789502;3;1
7ad953c7-749b-11df-b112-00215aee3ebe;Садки;Садки;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920386307;3;1
7ad953c8-749b-11df-b112-00215aee3ebe;Садки;Садки;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125886901;3;1
7ad953c9-749b-11df-b112-00215aee3ebe;Садки;Садки;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122087501;3;1
7ad953ca-749b-11df-b112-00215aee3ebe;Садки;Садки;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621283904;3;1
7ad953cb-749b-11df-b112-00215aee3ebe;Садки;Садки;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621581503;3;1
7ad953ce-749b-11df-b112-00215aee3ebe;Садки;Садки;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822086301;3;1
7ad953cf-749b-11df-b112-00215aee3ebe;Садки;Садки;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821786404;3;1
7ad953d0-749b-11df-b112-00215aee3ebe;Садки;Садки;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825085803;3;1
7ad953d1-749b-11df-b112-00215aee3ebe;Садки;Садки;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821484601;3;1
7ad953d2-749b-11df-b112-00215aee3ebe;Садки;Садки;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820885601;3;1
7ad953d3-749b-11df-b112-00215aee3ebe;Садки;Садки;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522682603;3;1
7ad953d4-749b-11df-b112-00215aee3ebe;Садки;Садки;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525886705;3;1
7ad953d5-749b-11df-b112-00215aee3ebe;Садки;Садки;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623480805;3;1
7ad953d6-749b-11df-b112-00215aee3ebe;Садки;Садки;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624287103;3;1
7ad953d7-749b-11df-b112-00215aee3ebe;Садки-Строївка;Садки-Строевка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222780403;3;1
7ad953d8-749b-11df-b112-00215aee3ebe;Садківці;Садковцы;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525387501;3;1
7ad953d9-749b-11df-b112-00215aee3ebe;Садківці;Садковцы;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522686802;2;1
7ad953da-749b-11df-b112-00215aee3ebe;Садковичі;Садковичи;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624287601;3;1
7ad953db-749b-11df-b112-00215aee3ebe;Садова;Садовая;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522683502;3;1
7ad953dc-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324884609;3;1
7ad953dd-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920688604;3;1
7ad953de-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924185404;3;1
7ad953df-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124287701;3;1
7ad953e0-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224883004;3;1
7ad953e1-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225283309;3;1
7ad953e2-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455124;3;1
7ad953e3-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1220387706;3;1
7ad953e4-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820380410;3;1
7ad953e5-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825781405;3;1
7ad953e6-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323380507;3;1
7ad953e7-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323986001;3;1
7ad953e8-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320655102;3;1
7ad953e9-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121285603;3;1
7ad953ea-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120884406;3;1
7ad953eb-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123183405;3;1
7ad953ec-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125080305;2;1
7ad953ed-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120485902;3;1
7ad953ee-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323685907;3;1
7ad953f2-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520386001;3;1
7ad953fa-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220286601;3;1
7ad953fb-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822585001;3;1
7ad953fc-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423388102;3;1
7ad953fd-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521483215;3;1
7ad953fe-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522486202;3;1
7ad953ff-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520810107;3;1
7ad95400-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825286403;2;1
7ad95401-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821555402;3;1
7ad95402-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823380503;3;1
7ad95403-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621286701;3;1
62c3d132-749b-11df-b112-00215aee3ebe;Садове;Садове;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221883505;3;1
871625a8-749b-11df-b112-00215aee3ebe;Садове;Садовое;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423088701;3;1
7ad95407-749b-11df-b112-00215aee3ebe;Садок;Садок;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520981004;3;1
7ad95408-749b-11df-b112-00215aee3ebe;Саєве;Саево;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923582905;3;1
7ad95409-749b-11df-b112-00215aee3ebe;Сажалки;Сажалки;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922681207;3;1
7ad9540a-749b-11df-b112-00215aee3ebe;Сажка;Сажка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323288606;3;1
7ad9540b-749b-11df-b112-00215aee3ebe;Сажки;Сажки;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523083804;3;1
7ad9540c-749b-11df-b112-00215aee3ebe;Сазонівка;Сазоновка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325755107;3;1
7ad9540d-749b-11df-b112-00215aee3ebe;Сазонівка;Сазоновка;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323685601;3;1
7ad9540e-749b-11df-b112-00215aee3ebe;Сазоно-Баланівка;Сазоно-Балановка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320887501;3;1
7ad9540f-749b-11df-b112-00215aee3ebe;Саї;Саи;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923285601;3;1
7ad95410-749b-11df-b112-00215aee3ebe;Саївка;Саевка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224586001;3;1
7ad95411-749b-11df-b112-00215aee3ebe;Саївка;Саевка;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320884203;3;1
7ad95412-749b-11df-b112-00215aee3ebe;Саїнка;Саинка;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524985501;3;1
7ad95413-749b-11df-b112-00215aee3ebe;Сайків;Сайков;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623086007;3;1
7ad95414-749b-11df-b112-00215aee3ebe;Сакалівка;Сакаловка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323280704;3;1
56bdd233-749b-11df-b112-00215aee3ebe;Саксаганське (Жовтневе);Саксаганське (Жовтневе);село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222082501;3;1
7ad95417-749b-11df-b112-00215aee3ebe;Саксагань;Саксагань;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224587001;3;1
5cb61577-749b-11df-b112-00215aee3ebe;Саксагань (Карла Маркса);Саксагань (Карла Маркса);село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221086606;3;1
7ad95418-749b-11df-b112-00215aee3ebe;Сакуниха;Сакуниха;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923585701;3;1
7ad95419-749b-11df-b112-00215aee3ebe;Салаші;Салаши;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625855904;3;1
7ad9541a-749b-11df-b112-00215aee3ebe;Салашка;Салашка;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620387003;3;1
7ad9541b-749b-11df-b112-00215aee3ebe;Салгани;Салганы;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120887709;3;1
7ad9541d-749b-11df-b112-00215aee3ebe;Сали;Салы;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825687001;3;1
7ad9541e-749b-11df-b112-00215aee3ebe;Саливонки;Саливонки;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221487101;3;1
7ad9541f-749b-11df-b112-00215aee3ebe;Салимівщина;Салимовщина;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755151;3;1
7ad95420-749b-11df-b112-00215aee3ebe;Салинці;Салинцы;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523087005;3;1
7ad95421-749b-11df-b112-00215aee3ebe;Салиха;Салиха;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224486001;3;1
7ad95422-749b-11df-b112-00215aee3ebe;Салів;Салов;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222983303;3;1
7ad95423-749b-11df-b112-00215aee3ebe;Салівка;Саловка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322485601;3;1
7ad95424-749b-11df-b112-00215aee3ebe;Салівка;Саловка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225685008;3;1
7ad95425-749b-11df-b112-00215aee3ebe;Салогубівка;Салогубовка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924184707;3;1
7ad95426-749b-11df-b112-00215aee3ebe;Салтикова Дівиця;Салтыкова Девица;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422786501;3;1
7ad95427-749b-11df-b112-00215aee3ebe;Салтикове;Салтыково;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922087401;3;1
7ad9542a-749b-11df-b112-00215aee3ebe;Салькове;Сальково;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;3521155600;3;1
7ad9542b-749b-11df-b112-00215aee3ebe;Сальне;Сальное;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425881251;3;1
7ad9542c-749b-11df-b112-00215aee3ebe;Сальне;Сальное;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423388101;3;1
7ad9542d-749b-11df-b112-00215aee3ebe;Сальник;Сальник;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521687503;3;1
7ad9542e-749b-11df-b112-00215aee3ebe;Сальниця;Сальница;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524886101;3;1
7ad9542f-749b-11df-b112-00215aee3ebe;Самакова;Самакова;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323582003;3;1
7ad95430-749b-11df-b112-00215aee3ebe;Самара;Самара;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920655310;3;1
7ad95431-749b-11df-b112-00215aee3ebe;Самара;Самара;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;;3;1
7ad95432-749b-11df-b112-00215aee3ebe;Самари;Самары;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755152;3;1
7ad95433-749b-11df-b112-00215aee3ebe;Самари;Самары;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724286901;3;1
7ad95434-749b-11df-b112-00215aee3ebe;Самари-Оріхові;Самары-Ореховые;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724287101;3;1
7ad95435-749b-11df-b112-00215aee3ebe;Самарське;Самарское;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223581303;3;1
7ad95436-749b-11df-b112-00215aee3ebe;Самарське;Самарское;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220787712;3;1
7ad95437-749b-11df-b112-00215aee3ebe;Самарське;Самарское;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223884801;3;1
7ad95438-749b-11df-b112-00215aee3ebe;Самарське;Самарское;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325785807;3;1
7ad9543a-749b-11df-b112-00215aee3ebe;Самарщина;Самарщина;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321880405;3;1
7ad9543b-749b-11df-b112-00215aee3ebe;Самбір;Самбор;місто;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4610900000;1;0
7ad9543d-749b-11df-b112-00215aee3ebe;Самгородок;Самгородок;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224086601;2;1
7ad9543e-749b-11df-b112-00215aee3ebe;Самгородок;Самгородок;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123787501;3;1
7ad9543f-749b-11df-b112-00215aee3ebe;Самгородок;Самгородок;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521486803;3;1
7ad95441-749b-11df-b112-00215aee3ebe;Самійличі;Самойличи;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725755109;3;1
7ad95442-749b-11df-b112-00215aee3ebe;Самійлівка;Самойловка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320685501;3;1
7ad95443-749b-11df-b112-00215aee3ebe;Самійлівка;Самойловка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125281303;3;1
7ad95444-749b-11df-b112-00215aee3ebe;Самійлівка;Самойловка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125480504;3;1
7ad95445-749b-11df-b112-00215aee3ebe;Самійлівка;Самойловка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323685201;3;1
7ad95448-749b-11df-b112-00215aee3ebe;Самійлівка;Самойловка;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422086805;3;1
7ad9544a-749b-11df-b112-00215aee3ebe;Самоволя;Самоволя;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721183803;3;1
7ad9544b-749b-11df-b112-00215aee3ebe;Самолусківці;Самолусковцы;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121686701;3;1
7ad9544c-749b-11df-b112-00215aee3ebe;Саморідня;Самородня;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122510102;3;1
7ad9544d-749b-11df-b112-00215aee3ebe;Самостріли;Самострелы;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623086501;3;1
7ad9544e-749b-11df-b112-00215aee3ebe;Самоткань;Самоткань;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221010104;3;1
7ad9544f-749b-11df-b112-00215aee3ebe;Самотоївка;Самотоевка;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922385401;3;1
7ad95450-749b-11df-b112-00215aee3ebe;Самотуги;Самотуги;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422488703;3;1
7ad95452-749b-11df-b112-00215aee3ebe;Самсони;Самсоны;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422081206;3;1
7ad95455-749b-11df-b112-00215aee3ebe;Самсонівка;Самсоновка;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422489204;3;1
7ad95459-749b-11df-b112-00215aee3ebe;Самусівка;Самусевка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322487010;3;1
7ad9545a-749b-11df-b112-00215aee3ebe;Самушин;Самушин;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321588401;3;1
7ad9545b-749b-11df-b112-00215aee3ebe;Самчики;Самчики;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824287101;3;1
7ad9545c-749b-11df-b112-00215aee3ebe;Самчинці;Самчинцы;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523085703;3;1
7ad9545d-749b-11df-b112-00215aee3ebe;Самчинці;Самчинцы;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824287301;3;1
7ad9545f-749b-11df-b112-00215aee3ebe;Санжари;Санжары;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157606;3;1
7ad95460-749b-11df-b112-00215aee3ebe;Санжариха;Санжариха;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123787701;3;1
7ad95462-749b-11df-b112-00215aee3ebe;Санжійка;Санжейка;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123781303;3;1
7ad95463-749b-11df-b112-00215aee3ebe;Санківці;Санковцы;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325088401;3;1
7ad95464-749b-11df-b112-00215aee3ebe;Санники;Санники;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622486603;3;1
7ad95465-749b-11df-b112-00215aee3ebe;Саночани;Саночаны;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625182303;3;1
7ad95466-749b-11df-b112-00215aee3ebe;Сантарка;Сантарка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386008;3;1
7ad95467-749b-11df-b112-00215aee3ebe;Сапанів;Сапанов;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123487101;3;1
7ad95468-749b-11df-b112-00215aee3ebe;Сапанівчик;Сапанивчик;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621680411;3;1
7ad95469-749b-11df-b112-00215aee3ebe;Сапетня;Сапетня;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824255601;3;1
7ad9546a-749b-11df-b112-00215aee3ebe;Сапіжанка;Сапижанка;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622182605;3;1
7ad9546b-749b-11df-b112-00215aee3ebe;Сапіжанка;Сапижанка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525387601;3;1
7ad9546c-749b-11df-b112-00215aee3ebe;Сапова;Сапова;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125083103;3;1
7ad9546d-749b-11df-b112-00215aee3ebe;Сапогів;Сапогов;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120887101;3;1
7ad9546e-749b-11df-b112-00215aee3ebe;Сапогів;Сапогов;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621286501;3;1
7ad9546f-749b-11df-b112-00215aee3ebe;Сапогове;Сапогово;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721885804;3;1
7ad95470-749b-11df-b112-00215aee3ebe;Сапожин;Сапожин;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623086701;3;1
7ad95471-749b-11df-b112-00215aee3ebe;Сапожине;Сапожино;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086208;3;1
7ad95472-749b-11df-b112-00215aee3ebe;Сапонівка;Сапоновка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420889606;3;1
7ad95473-749b-11df-b112-00215aee3ebe;Сапушине;Сапушино;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920983206;3;1
7ad95474-749b-11df-b112-00215aee3ebe;Саражинка;Саражинка;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290025;3;1
7ad95475-749b-11df-b112-00215aee3ebe;Саражинці;Саражинцы;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523485201;3;1
7ad95476-749b-11df-b112-00215aee3ebe;Саранчине;Саранчино;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322683406;3;1
7ad95477-749b-11df-b112-00215aee3ebe;Саранчівка;Саранчовка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321385305;3;1
7ad95478-749b-11df-b112-00215aee3ebe;Саранчова Долина;Саранчова Долина;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320486905;3;1
7ad95479-749b-11df-b112-00215aee3ebe;Саранчуки;Саранчуки;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120487901;3;1
7ad9547a-749b-11df-b112-00215aee3ebe;Сарата;Сарата;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323586005;3;1
7ad9547b-749b-11df-b112-00215aee3ebe;Сарата;Сарата;місто;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124555100;3;1
7ad9547c-749b-11df-b112-00215aee3ebe;Сари;Сары;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320486901;3;1
7ad9547e-749b-11df-b112-00215aee3ebe;Сарнавщина;Сарнавщина;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922081105;3;1
7ad9547f-749b-11df-b112-00215aee3ebe;Сарни;Сарны;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625887601;3;1
7ad95480-749b-11df-b112-00215aee3ebe;Сарни;Сарны;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123485701;3;1
7ad95481-749b-11df-b112-00215aee3ebe;Сарни;Сарны;місто;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625410100;1;0
7ad95482-749b-11df-b112-00215aee3ebe;Сарники;Сарники;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624485801;3;1
7ad95483-749b-11df-b112-00215aee3ebe;Сарнів;Сарнов;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820987201;3;1
7ad95484-749b-11df-b112-00215aee3ebe;Сарнівка;Сарновка;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722886203;3;1
7ad95485-749b-11df-b112-00215aee3ebe;Сарнівка;Сарновка;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824485603;3;1
7ad95486-749b-11df-b112-00215aee3ebe;Сарнівка;Сарновка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821455301;3;1
7ad9563b-749b-11df-b112-00215aee3ebe;Сарнівка;Серновка;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622789207;3;1
7ad95487-749b-11df-b112-00215aee3ebe;Сарновичі;Сарновичи;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822385001;3;1
7ad95489-749b-11df-b112-00215aee3ebe;Сасанівка;Сасановка;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823687001;3;1
7ad9548a-749b-11df-b112-00215aee3ebe;Сасинівка;Сасиновка;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323884901;3;1
7ad9548b-749b-11df-b112-00215aee3ebe;Сасів;Сасов;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621886401;3;1
7ad9548c-749b-11df-b112-00215aee3ebe;Сасівка;Сасовка;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124083503;3;1
7ad9548d-749b-11df-b112-00215aee3ebe;Сасівка;Сасовка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522885301;3;1
7ad9548e-749b-11df-b112-00215aee3ebe;Сасово;Сасово;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121284701;3;1
7ad9548f-749b-11df-b112-00215aee3ebe;Сасово;Сасово;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124485803;3;1
7ad95490-749b-11df-b112-00215aee3ebe;Сатанів;Сатанов;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821255500;3;1
7ad95491-749b-11df-b112-00215aee3ebe;Сатанівка;Сатановка;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123485801;3;1
7ad95492-749b-11df-b112-00215aee3ebe;Сатанівка;Сатановка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821289405;3;1
7ad95493-749b-11df-b112-00215aee3ebe;Сатанівська Слобідка;Сатановская Слободка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821255500;3;1
7ad95494-749b-11df-b112-00215aee3ebe;Сатиїв;Сатыев;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621687001;3;1
7ad95497-749b-11df-b112-00215aee3ebe;Саф'яни;Сафьяны;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122085901;2;1
7ad95496-749b-11df-b112-00215aee3ebe;Сафонівка;Сафоновка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923883801;3;1
edbb3f4e-9993-11e0-82d0-003048d2b473;Сафонова;Сафонова;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;;3;1
7ad95499-749b-11df-b112-00215aee3ebe;Саханське;Саханское;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924180605;3;1
7ad9549a-749b-11df-b112-00215aee3ebe;Саханське;Саханское;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125485201;3;1
7ad9549b-749b-11df-b112-00215aee3ebe;Сахарове;Сахарово;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923885805;3;1
7ad9549c-749b-11df-b112-00215aee3ebe;Сахарове;Сахарово;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121281205;3;1
7ad9549d-749b-11df-b112-00215aee3ebe;Сахкамінь;Сахкамень;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822485403;3;1
7ad9549e-749b-11df-b112-00215aee3ebe;Сахни;Сахны;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922087601;3;1
7ad9549f-749b-11df-b112-00215aee3ebe;Сахни;Сахны;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825284002;3;1
7ad954a0-749b-11df-b112-00215aee3ebe;Сахни;Сахны;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823085001;3;1
7ad954a1-749b-11df-b112-00215aee3ebe;Сахнівка;Сахновка;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122587401;3;1
62c3d144-749b-11df-b112-00215aee3ebe;Сахнівка /Ленінівка/;Сахнивка /Лениновка/;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423085501;3;1
7ad954a2-749b-11df-b112-00215aee3ebe;Сахнівське;Сахновское;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122585804;3;1
7ad954a3-749b-11df-b112-00215aee3ebe;Сахнівці;Сахнивци;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822186601;3;1
7ad954a4-749b-11df-b112-00215aee3ebe;Сахнівці;Сахнивци;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824287701;3;1
7ad954a5-749b-11df-b112-00215aee3ebe;Сахнівщина;Сахновщина;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323086201;3;1
7ad954a7-749b-11df-b112-00215aee3ebe;Сахновщина;Сахновщина;місто;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324855100;2;1
7ad954a8-749b-11df-b112-00215aee3ebe;Сахутівка;Сахутовка;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422489205;3;1
7ad954aa-749b-11df-b112-00215aee3ebe;Саша;Саша;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523786301;3;1
7ad954ab-749b-11df-b112-00215aee3ebe;Сваловичі;Сваловичи;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155129;3;1
7ad954ac-749b-11df-b112-00215aee3ebe;Свалява;Свалява;місто;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124010100;2;0
7ad954ad-749b-11df-b112-00215aee3ebe;Свалявка;Свалявка;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123285002;3;1
7ad954ae-749b-11df-b112-00215aee3ebe;Сварині;Сварыни;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620883003;3;1
7ad954af-749b-11df-b112-00215aee3ebe;Сварицевичі;Сварицевичи;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621886901;3;1
7ad954b0-749b-11df-b112-00215aee3ebe;Сваричів;Сварычев;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624885101;3;1
7ad954b1-749b-11df-b112-00215aee3ebe;Сваричівка;Сваричевка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421786003;3;1
7ad954b2-749b-11df-b112-00215aee3ebe;Сваркове;Сварково;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921585701;3;1
7ad954b3-749b-11df-b112-00215aee3ebe;Сватки;Сватки;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320487201;3;1
7ad954b6-749b-11df-b112-00215aee3ebe;Свердликове;Свердликово;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523685601;3;1
7ad954bc-749b-11df-b112-00215aee3ebe;Свердловка;Свердловка;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422288601;3;1
7ad954c2-749b-11df-b112-00215aee3ebe;Свершківці;Свершковцы;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122089602;3;1
7ad954c3-749b-11df-b112-00215aee3ebe;Свеса;Свеса;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925655700;3;1
7ad954c4-749b-11df-b112-00215aee3ebe;Свидівок;Свидовок;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124986001;3;1
7ad954c5-749b-11df-b112-00215aee3ebe;Свидник;Свидник;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625584605;3;1
7ad954c6-749b-11df-b112-00215aee3ebe;Свидники;Свидники;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155320;3;1
7ad954c7-749b-11df-b112-00215aee3ebe;Свидниця;Свидница;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625887801;3;1
7ad954c8-749b-11df-b112-00215aee3ebe;Свидня;Свидня;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922681507;3;1
7ad954c9-749b-11df-b112-00215aee3ebe;Свидова;Свидова;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125587301;3;1
7ad954ca-749b-11df-b112-00215aee3ebe;Свидова;Свидова;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522885005;3;1
7ad954cb-749b-11df-b112-00215aee3ebe;Свидовець;Свидовец;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420687201;3;1
7ad954cc-749b-11df-b112-00215aee3ebe;Свидя;Свидя;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825681604;3;1
7ad954cd-749b-11df-b112-00215aee3ebe;Свинарин;Свинарин;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725582203;3;1
7ad954ce-749b-11df-b112-00215aee3ebe;Свинарі;Свинари;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321288507;3;1
7ad954cf-749b-11df-b112-00215aee3ebe;Свинарка;Свинарка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124387401;3;1
7ad954d0-749b-11df-b112-00215aee3ebe;Свиноїди;Свиноеды;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;;3;1
7ad954d1-749b-11df-b112-00215aee3ebe;Свириди;Свириды;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822184802;3;1
7ad954d2-749b-11df-b112-00215aee3ebe;Свиридівка;Свиридовка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823485508;3;1
7ad954d3-749b-11df-b112-00215aee3ebe;Свиридівка;Свиридовка;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322686201;3;1
7ad954d4-749b-11df-b112-00215aee3ebe;Свиридове;Свиридово;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220788803;3;1
7ad954d8-749b-11df-b112-00215aee3ebe;Свистунівка;Свистуновка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323083002;3;1
7ad954da-749b-11df-b112-00215aee3ebe;Свистунове;Свистуново;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885709;3;1
7ad954dc-749b-11df-b112-00215aee3ebe;Свитинці;Свитинцы;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523488202;3;1
7ad954db-749b-11df-b112-00215aee3ebe;Свитязів;Свитязев;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624886401;3;1
7ad954de-749b-11df-b112-00215aee3ebe;Свищів;Свищев;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623885511;3;1
7ad954df-749b-11df-b112-00215aee3ebe;Свідівок;Свидивок;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223582811;3;1
7ad954e0-749b-11df-b112-00215aee3ebe;Свійчів;Свийчев;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720581507;3;1
7ad954e1-749b-11df-b112-00215aee3ebe;Свірж;Свирж;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925383703;3;1
7ad954e2-749b-11df-b112-00215aee3ebe;Свірж;Свирж;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623386401;3;1
7ad954e3-749b-11df-b112-00215aee3ebe;Свірневе;Свирнево;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521487001;3;1
7ad954e4-749b-11df-b112-00215aee3ebe;Свірок;Свирок;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424987502;3;1
7ad954e5-749b-11df-b112-00215aee3ebe;Свіршківці;Свершковцы;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825287001;2;1
4aba3f09-749b-11df-b112-00215aee3ebe;Світанкове (Воровського);Свитанково (Воровского);село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424182802;3;1
7ad954e7-749b-11df-b112-00215aee3ebe;Світанок;Свитанок;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325455303;3;1
7ad954e8-749b-11df-b112-00215aee3ebe;Світанок;Свитанок;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322180406;3;1
7ad954e9-749b-11df-b112-00215aee3ebe;Світанок;Свитанок;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624486001;3;1
7ad954ea-749b-11df-b112-00215aee3ebe;Світанок;Свитанок;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223386501;3;1
7ad954eb-749b-11df-b112-00215aee3ebe;Світанок;Свитанок;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421484806;3;1
7ad954ec-749b-11df-b112-00215aee3ebe;Світанок;Свитанок;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423388301;3;1
7ad954ed-749b-11df-b112-00215aee3ebe;Світанок;Свитанок;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;;3;1
7ad954ee-749b-11df-b112-00215aee3ebe;Світанок;Свитанок;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623086901;3;1
7ad954ef-749b-11df-b112-00215aee3ebe;Світанок;Свитанок;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320886203;3;1
7ad954e6-749b-11df-b112-00215aee3ebe;Світанок (Чесний Хрест);Свитанок (Чесний Хрест);село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720582024;3;1
7ad954f0-749b-11df-b112-00215aee3ebe;Світильня;Светильня;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221288401;3;1
7ad954f1-749b-11df-b112-00215aee3ebe;Світин;Свитын;село;d00d3b86-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822085603;1;0
7ad954f2-749b-11df-b112-00215aee3ebe;Світівщина;Световщина;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924180606;3;1
7ad954f3-749b-11df-b112-00215aee3ebe;Світла Дача;Светлая Дача;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825785205;3;1
7ad954f4-749b-11df-b112-00215aee3ebe;Світла Долина;Светлая Долина;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323685204;3;1
7ad954f5-749b-11df-b112-00215aee3ebe;Світле;Светлое;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722187409;3;1
7ad954f6-749b-11df-b112-00215aee3ebe;Світле;Светлое;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124255503;3;1
7ad954f8-749b-11df-b112-00215aee3ebe;Світле;Светлое;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323381003;3;1
7ad954f9-749b-11df-b112-00215aee3ebe;Світле;Светлое;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122780207;3;1
7ad95504-749b-11df-b112-00215aee3ebe;Світле;Светлое;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222985703;3;1
7ad95505-749b-11df-b112-00215aee3ebe;Світле;Светлое;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;;3;1
87162200-749b-11df-b112-00215aee3ebe;Світле;Светлое;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822783407;3;1
7ad95508-749b-11df-b112-00215aee3ebe;Світлицьке;Светлицкое;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820680805;3;1
7ad95509-749b-11df-b112-00215aee3ebe;Світличне;Светличное;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322685501;3;1
7ad9550a-749b-11df-b112-00215aee3ebe;Світличне;Светличное;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421184001;3;1
7ad9550c-749b-11df-b112-00215aee3ebe;Світлівка;Светловка;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;6521881504;3;1
7ad9550d-749b-11df-b112-00215aee3ebe;Світлівщина;Свитливщина;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323486003;3;1
7ad9550e-749b-11df-b112-00215aee3ebe;Світловодськ;Светловодск;місто;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3510900000;2;0
b36f1a42-db87-11df-9197-00215aee3ebe;Світловщина;Светловщина;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323981506;3;1
7ad9550f-749b-11df-b112-00215aee3ebe;Світлогірське;Светлогорское;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321886401;3;1
7ad95510-749b-11df-b112-00215aee3ebe;Світлогірське;Светлогорское;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222081001;3;1
7ad95511-749b-11df-b112-00215aee3ebe;Світлогірське;Светлогорское;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123981405;3;1
7ad95513-749b-11df-b112-00215aee3ebe;Світлодолинське;Светлодолинское;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124585701;3;1
7ad95515-749b-11df-b112-00215aee3ebe;Світлопіль;Светлополь;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520386601;3;1
7ad95516-749b-11df-b112-00215aee3ebe;Світова Зірка;Свитова Зирка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520587002;3;1
7ad95517-749b-11df-b112-00215aee3ebe;Світязь;Свитязь;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725785601;2;1
7ad95518-749b-11df-b112-00215aee3ebe;Свічанівка;Свечановка;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223210504;3;1
7ad95519-749b-11df-b112-00215aee3ebe;Свічкареве;Свечкарево;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321884604;3;1
7ad9551a-749b-11df-b112-00215aee3ebe;Свічкарівщина;Свечкаревщина;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321384005;3;1
7ad9551b-749b-11df-b112-00215aee3ebe;Свічківка;Свечковка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120689001;3;1
7ad9551c-749b-11df-b112-00215aee3ebe;Свічківка;Свечковка;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322885306;3;1
7ad9551d-749b-11df-b112-00215aee3ebe;Свічна;Свечная;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823083303;3;1
7ad9551f-749b-11df-b112-00215aee3ebe;Свобода;Свобода;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820681807;3;1
7ad95520-749b-11df-b112-00215aee3ebe;Свобода;Свобода;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323987812;3;1
7ad95521-749b-11df-b112-00215aee3ebe;Свобода;Свобода;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122487204;3;1
7ad95522-749b-11df-b112-00215aee3ebe;Свобода;Свобода;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120488401;3;1
cdc44f6e-dde8-11df-9197-00215aee3ebe;Свобода;Свобода;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221980603;3;1
7ad95525-749b-11df-b112-00215aee3ebe;Свободне;Свободное;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;6521884505;3;1
7ad95529-749b-11df-b112-00215aee3ebe;Святе;Святое;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622680805;3;1
7ad9552a-749b-11df-b112-00215aee3ebe;Святець;Святец;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824784001;3;1
7ad9552b-749b-11df-b112-00215aee3ebe;Святилівка;Святиловка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320688101;3;1
56bdd166-749b-11df-b112-00215aee3ebe;Святовасилівка (Єлізарове);Святовасилівка (Єлізарове);село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225083501;3;1
74dc46ff-749b-11df-b112-00215aee3ebe;Святомиколаївка;Святониколаевка;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823383003;3;1
74dc4991-749b-11df-b112-00215aee3ebe;Святопетрівське (Петрівське);Петровское;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222485801;1;0
7ad9552d-749b-11df-b112-00215aee3ebe;Свяття;Святье;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624680409;3;1
7ad9552e-749b-11df-b112-00215aee3ebe;Святушине;Святушино;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323981003;3;1
7ad9552f-749b-11df-b112-00215aee3ebe;Себечів;Себечев;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624883703;3;1
7ad95530-749b-11df-b112-00215aee3ebe;Себине;Себино;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824884801;3;1
7ad95533-749b-11df-b112-00215aee3ebe;Северини;Северины;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824282004;3;1
7ad95534-749b-11df-b112-00215aee3ebe;Северинівка;Севериновка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786901;3;1
7ad95535-749b-11df-b112-00215aee3ebe;Северинівка;Севериновка;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723683602;3;1
7ad95536-749b-11df-b112-00215aee3ebe;Северинівка;Севериновка;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121883801;3;1
7ad95537-749b-11df-b112-00215aee3ebe;Северинівка;Севериновка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222783204;3;1
7ad95538-749b-11df-b112-00215aee3ebe;Северинівка;Севериновка;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224486401;3;1
7ad95539-749b-11df-b112-00215aee3ebe;Северинівка;Севериновка;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823180402;3;1
7ad9553a-749b-11df-b112-00215aee3ebe;Северинівка;Севериновка;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521085203;3;1
7ad9553b-749b-11df-b112-00215aee3ebe;Северинівка;Севериновка;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524183202;3;1
7ad9553c-749b-11df-b112-00215aee3ebe;Северинівка;Севериновка;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525684601;3;1
7ad9553d-749b-11df-b112-00215aee3ebe;Северинівка;Севериновка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520555105;3;1
7ad9553e-749b-11df-b112-00215aee3ebe;Севрюки;Севрюки;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822788401;3;1
325c580a-ddd2-11df-9197-00215aee3ebe;Сегединці;Сегединцы;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120386005;3;1
7ad9553f-749b-11df-b112-00215aee3ebe;Седлище;Седлище;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155130;3;1
7ad95540-749b-11df-b112-00215aee3ebe;Седлище;Седлище;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725084601;3;1
7ad95541-749b-11df-b112-00215aee3ebe;Седнів;Седнев;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425556000;3;1
7ad95542-749b-11df-b112-00215aee3ebe;Седнівка;Седневка;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525887801;3;1
7ad95543-749b-11df-b112-00215aee3ebe;Сезенків;Сезенков;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220286701;3;1
7ad95544-749b-11df-b112-00215aee3ebe;Сезьки;Сезьки;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421788401;3;1
7ad95545-749b-11df-b112-00215aee3ebe;Секретарівка;Секретаревка;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121084501;3;1
7ad95546-749b-11df-b112-00215aee3ebe;Секретарка;Секретарка;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823983801;3;1
7ad95547-749b-11df-b112-00215aee3ebe;Секунь;Секунь;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725084701;3;1
7ad95548-749b-11df-b112-00215aee3ebe;Селевинці;Селевинцы;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523083805;3;1
7ad95549-749b-11df-b112-00215aee3ebe;Селезенівка;Селезеновка;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224086801;2;1
7ad9554a-749b-11df-b112-00215aee3ebe;Селезівка;Селезовка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824280303;3;1
7ad9554b-749b-11df-b112-00215aee3ebe;Селезнівка;Селезневка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923883817;3;1
7ad9554e-749b-11df-b112-00215aee3ebe;Селекційне;Селекционное;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325110701;3;1
73e94c10-ddc1-11df-9197-00215aee3ebe;Селекційне ;Селекционное;;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;;3;1
7ad9554f-749b-11df-b112-00215aee3ebe;Селець;Селец;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720582019;3;1
7ad95550-749b-11df-b112-00215aee3ebe;Селець;Селец;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725586001;3;1
7ad95551-749b-11df-b112-00215aee3ebe;Селець;Селец;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621287803;3;1
7ad95552-749b-11df-b112-00215aee3ebe;Селець;Селец;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755151;3;1
7ad95553-749b-11df-b112-00215aee3ebe;Селець;Селец;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825687201;3;1
7ad95554-749b-11df-b112-00215aee3ebe;Селець;Селец;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621887301;3;1
7ad95555-749b-11df-b112-00215aee3ebe;Селещина;Селещина;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323086601;3;1
7ad95556-749b-11df-b112-00215aee3ebe;Селиванівка;Селивановка;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120280402;3;1
7ad95558-749b-11df-b112-00215aee3ebe;Селиська;Селиска;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623386004;3;1
7ad9555a-749b-11df-b112-00215aee3ebe;Селисько;Селиско;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623681807;3;1
7ad9555b-749b-11df-b112-00215aee3ebe;Селихів;Селихов;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421780402;3;1
7ad9555c-749b-11df-b112-00215aee3ebe;Селичів;Селичев;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823980605;3;1
7ad9555d-749b-11df-b112-00215aee3ebe;Селичівка;Селичевка;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220287101;3;1
7ad9555e-749b-11df-b112-00215aee3ebe;Селище;Селище;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922086603;3;1
7ad9555f-749b-11df-b112-00215aee3ebe;Селище;Селище;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922981206;3;1
7ad95560-749b-11df-b112-00215aee3ebe;Селище;Селище;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7324088501;3;1
7ad95562-749b-11df-b112-00215aee3ebe;Селище;Селище;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220287001;3;1
7ad95563-749b-11df-b112-00215aee3ebe;Селище;Селище;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425888805;3;1
7ad95564-749b-11df-b112-00215aee3ebe;Селище;Селище;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424781507;3;1
7ad95565-749b-11df-b112-00215aee3ebe;Селище;Селище;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423885001;3;1
7ad95566-749b-11df-b112-00215aee3ebe;Селище;Селище;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122587601;3;1
7ad95567-749b-11df-b112-00215aee3ebe;Селище;Селище;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521480406;3;1
7ad95568-749b-11df-b112-00215aee3ebe;Селище;Селище;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522486201;3;1
7ad95569-749b-11df-b112-00215aee3ebe;Селище;Селище;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524585601;3;1
7ad9556a-749b-11df-b112-00215aee3ebe;Селище;Селище;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625486801;3;1
1c1d8333-49f5-11e4-81a4-003048d2b473;Селище;Селище;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;;3;1
7ad9556b-749b-11df-b112-00215aee3ebe;Селище Гладківщина;Гладковщина;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;;3;1
7ad9556c-749b-11df-b112-00215aee3ebe;Селіванове;Селиваново;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525887803;3;1
7ad95559-749b-11df-b112-00215aee3ebe;Селіски;Селиски;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720510420;3;1
7ad9556d-749b-11df-b112-00215aee3ebe;Селісок;Селисок;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155131;3;1
7ad9556e-749b-11df-b112-00215aee3ebe;Селюків;Селюков;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322885407;3;1
7ad9556f-749b-11df-b112-00215aee3ebe;Селянівка;Селяновка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655106;3;1
7ad95570-749b-11df-b112-00215aee3ebe;Селянська Слобода;Крестьянская Слобода;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425587702;3;1
7ad95571-749b-11df-b112-00215aee3ebe;Селянщина;Селянщина;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825687401;3;1
7ad95572-749b-11df-b112-00215aee3ebe;Селятин;Селятин;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323584501;3;1
62c3ce53-749b-11df-b112-00215aee3ebe;Сем'янівка (Кротенки);Семьяновка (Кротенки);село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324082401;3;1
7ad95573-749b-11df-b112-00215aee3ebe;Семаки;Семаки;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424485205;3;1
7ad95574-749b-11df-b112-00215aee3ebe;Семаківці;Семаковцы;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125580704;3;1
7ad95575-749b-11df-b112-00215aee3ebe;Семаківці;Семаковцы;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621684601;3;1
7ad95576-749b-11df-b112-00215aee3ebe;Семаківці;Семаковцы;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623286401;3;1
0edb306d-62e3-11e6-b3d3-001ec93df11f;Семаківці;Семакивци;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;;3;1
7ad95578-749b-11df-b112-00215aee3ebe;Семенасте;Семенастое;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524086001;3;1
7ad95579-749b-11df-b112-00215aee3ebe;Семени;Семены;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824282711;3;1
7ad9557a-749b-11df-b112-00215aee3ebe;Семенів;Семенов;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125086603;3;1
7ad9557b-749b-11df-b112-00215aee3ebe;Семенів;Семенов;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820387301;3;1
7ad9557c-749b-11df-b112-00215aee3ebe;Семенів Яр;Семенов Яр;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320810104;3;1
7ad9557d-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;місто;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324555100;3;1
7ad9557e-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320280904;3;1
7ad9557f-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921586001;3;1
7ad95580-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922980804;3;1
7ad95581-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923286201;3;1
7ad95582-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655120;3;1
7ad95583-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224581209;3;1
7ad95584-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222086001;3;1
7ad95585-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455104;3;1
7ad95586-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820380411;3;1
7ad95587-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823080703;3;1
7ad95588-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821180709;3;1
7ad95589-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322010108;3;1
7ad9558a-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324583504;3;1
7ad9558b-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322887005;3;1
7ad9558c-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322284004;3;1
7ad9558d-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320686001;3;1
7ad9558e-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122583002;3;1
7ad9558f-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120886401;3;1
7ad95593-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621683703;3;1
7ad95594-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623685901;3;1
7ad95598-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220287301;3;1
7ad95599-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223187201;3;1
7ad9559a-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510124;3;1
7ad9559b-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823184602;3;1
7ad9559c-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820882402;3;1
7ad9559d-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;місто;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424710100;3;1
7ad9559e-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423087601;3;1
7ad9559f-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122886401;3;1
7ad955a0-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525381206;3;1
7ad955a1-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522881404;3;1
7ad95577-749b-11df-b112-00215aee3ebe;Семенівка;Семеновка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325785901;3;1
871626a6-749b-11df-b112-00215aee3ebe;Семенівка (Червоний Орач);Семеновка (Червоный Орач);село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823981603;3;1
7ad955a3-749b-11df-b112-00215aee3ebe;Семенки;Семенки;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523087001;3;1
7ad955a4-749b-11df-b112-00215aee3ebe;Семенки;Семенки;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520285009;3;1
7ad955a5-749b-11df-b112-00215aee3ebe;Семено-Карпівка;Семено-Карповка;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290026;3;1
7ad955a6-749b-11df-b112-00215aee3ebe;Семенягівка;Семеняговка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425586309;3;1
7ad955a7-749b-11df-b112-00215aee3ebe;Семеренки;Семеренки;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323287205;3;1
7ad955a8-749b-11df-b112-00215aee3ebe;Семереньки;Семереньки;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925087201;3;1
7ad955a9-749b-11df-b112-00215aee3ebe;Семеринське;Семеринское;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722482802;3;1
7ad955aa-749b-11df-b112-00215aee3ebe;Семигинів;Семигинов;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625386001;3;1
7ad955ac-749b-11df-b112-00215aee3ebe;Семигір'я;Семигорье;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525281204;3;1
7ad955ad-749b-11df-b112-00215aee3ebe;Семигори;Семигоры;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220685304;3;1
7ad955af-749b-11df-b112-00215aee3ebe;Семидуби;Семидубы;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521487301;3;1
7ad955b0-749b-11df-b112-00215aee3ebe;Семидуби;Семидубы;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621687401;3;1
7ad955b1-749b-11df-b112-00215aee3ebe;Семиківці;Семиковцы;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125087301;3;1
7ad955b3-749b-11df-b112-00215aee3ebe;Семиланне;Семиланное;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320483504;3;1
7ad955b4-749b-11df-b112-00215aee3ebe;Семимогили;Семимогилы;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610105;3;1
7ad955b5-749b-11df-b112-00215aee3ebe;Семиполки;Семиполки;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221255505;3;1
7ad955b6-749b-11df-b112-00215aee3ebe;Семиреньки;Семиренки;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824287303;3;1
7ad955b7-749b-11df-b112-00215aee3ebe;Семирівка;Семеровка;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625886005;3;1
7ad955b8-749b-11df-b112-00215aee3ebe;Семирічка;Семиречка;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520885403;3;1
7ad955b9-749b-11df-b112-00215aee3ebe;Семисотка;Семисотка;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124586402;3;1
7ad955bc-749b-11df-b112-00215aee3ebe;Семихатки;Семихатки;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121285604;3;1
7ad955be-749b-11df-b112-00215aee3ebe;Семки;Семки;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723655411;3;1
7ad955bf-749b-11df-b112-00215aee3ebe;Семки;Семки;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524882702;3;1
7ad955c0-749b-11df-b112-00215aee3ebe;Семківка;Семковка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321283504;3;1
7ad955c1-749b-11df-b112-00215aee3ebe;Сененкове;Сененково;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187305;3;1
7ad955c2-749b-11df-b112-00215aee3ebe;Сенечів;Сенечив;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622085401;3;1
7ad955c3-749b-11df-b112-00215aee3ebe;Сені;Сени;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324210106;3;1
7ad955c4-749b-11df-b112-00215aee3ebe;Сенів;Сенев;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621588507;3;1
7ad955c5-749b-11df-b112-00215aee3ebe;Сенігів;Сенигов;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825587003;3;1
7ad955c6-749b-11df-b112-00215aee3ebe;Сенкевичівка;Сенкевичевка;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720855700;3;1
7ad955c8-749b-11df-b112-00215aee3ebe;Сенча;Сенча;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322686601;3;1
7ad955c9-749b-11df-b112-00215aee3ebe;Сенчиці;Сенчицы;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622286201;3;1
7ad955ca-749b-11df-b112-00215aee3ebe;Сенюки;Сенюки;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424487207;3;1
7ad955cb-749b-11df-b112-00215aee3ebe;Сеньків;Сеньков;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123087150;3;1
7ad955cc-749b-11df-b112-00215aee3ebe;Сеньків;Сеньков;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124285003;3;1
7ad955cd-749b-11df-b112-00215aee3ebe;Сеньківка;Сеньковка;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220886701;3;1
7ad955ce-749b-11df-b112-00215aee3ebe;Сеньківка;Сеньковка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421487501;3;1
7ad955c7-749b-11df-b112-00215aee3ebe;Сеньківське;Сеньковское;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620888002;3;1
7ad955cf-749b-11df-b112-00215aee3ebe;Сеньківці;Сеньковцы;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121584303;3;1
7ad955d3-749b-11df-b112-00215aee3ebe;Сеньчине;Сеньчино;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824282710;3;1
7ad955d4-749b-11df-b112-00215aee3ebe;Серафимівка;Серафимовка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320286003;3;1
7ad955d5-749b-11df-b112-00215aee3ebe;Серафимівка;Серафимовка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684809;3;1
7ad955d6-749b-11df-b112-00215aee3ebe;Серафинці;Серафинцы;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621684901;3;1
7ad955d7-749b-11df-b112-00215aee3ebe;Сербанівка;Сербановка;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524886303;3;1
7ad955d8-749b-11df-b112-00215aee3ebe;Серби;Сербы;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122584601;3;1
7ad955d9-749b-11df-b112-00215aee3ebe;Серби;Сербы;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821785801;3;1
7ad955da-749b-11df-b112-00215aee3ebe;Сербинів;Сербинов;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820988204;3;1
7ad955db-749b-11df-b112-00215aee3ebe;Сербинівка;Сербиновка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824288001;3;1
7ad955dc-749b-11df-b112-00215aee3ebe;Сербинівка;Сербиновка;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320884201;3;1
7ad955dd-749b-11df-b112-00215aee3ebe;Сербинівці;Сербиновцы;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521085403;3;1
7ad955de-749b-11df-b112-00215aee3ebe;Сербичани;Сербичаны;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324089001;3;1
7ad955df-749b-11df-b112-00215aee3ebe;Сербка;Сербка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;c7bb5b2d-671b-11ea-80c6-000c29800ae7;5122785501;3;1
7ad955e0-749b-11df-b112-00215aee3ebe;Сербо-Слобідка;Сербо-Слободка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821786001;3;1
7ad955e2-749b-11df-b112-00215aee3ebe;Сергії;Сергии;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323585001;3;1
7ad955e3-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920687801;3;1
7ad955e4-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720881705;3;1
7ad955e5-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225980209;3;1
7ad955e6-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055106;3;1
7ad955e7-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225286610;3;1
7ad955e8-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455105;3;1
7ad955e9-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1220310114;3;1
7ad955ea-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821484601;3;1
7ad955eb-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655126;3;1
7ad955ec-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821185101;3;1
7ad955ed-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323582208;3;1
7ad955ee-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323988507;3;1
7ad955ef-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123383701;3;1
7ad955f0-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5110345700;3;1
7ad955f1-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124586201;3;1
7ad955f2-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321584010;3;1
7ad955f3-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323684008;3;1
7ad955fc-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821786201;3;1
7ad955fd-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424188201;3;1
7ad955fe-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525587102;3;1
7ad955ff-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;;3;1
7ad95600-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820981505;3;1
7ad95601-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624686503;3;1
7ad95602-749b-11df-b112-00215aee3ebe;Сергіївка;Сергеевка;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621285904;3;1
5cb61bdc-749b-11df-b112-00215aee3ebe;Сергіївка (Котовці);Сергіївка (Котовці);село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122581602;3;1
62c3ccfb-749b-11df-b112-00215aee3ebe;Сергіївка (Краснознаменка);Сергеевка (Краснознаменка);село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320483401;3;1
7ad95603-749b-11df-b112-00215aee3ebe;Сергіївське;Сергеевское;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821484603;3;1
7ad95604-749b-11df-b112-00215aee3ebe;Сергіївське;Сергеевское;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424783003;3;1
7ad95605-749b-11df-b112-00215aee3ebe;Серго;Серго;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323181003;3;1
7ad95606-749b-11df-b112-00215aee3ebe;Сердегівка;Сердеговка;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125786201;3;1
7ad95608-749b-11df-b112-00215aee3ebe;Сердиця;Сердица;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623655503;3;1
7ad9560a-749b-11df-b112-00215aee3ebe;Сердюки;Сердюки;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081308;3;1
7ad9560b-749b-11df-b112-00215aee3ebe;Сердюки;Сердюки;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824482803;3;1
7ad9560c-749b-11df-b112-00215aee3ebe;Сердюківка;Сердюковка;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123788001;3;1
7ad9560d-749b-11df-b112-00215aee3ebe;Серебринці;Серебринцы;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522685801;2;1
7ad9560e-749b-11df-b112-00215aee3ebe;Серебрія;Серебрия;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522685701;2;1
7ad9560f-749b-11df-b112-00215aee3ebe;Серебрія;Серебрия;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520484403;3;1
7ad95610-749b-11df-b112-00215aee3ebe;Серебряне;Серебряное;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525282403;3;1
7ad95611-749b-11df-b112-00215aee3ebe;Серебряниця;Серебряница;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725587606;3;1
7ad95614-749b-11df-b112-00215aee3ebe;Середи;Середы;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821786401;3;1
7ad95615-749b-11df-b112-00215aee3ebe;Середина;Середина;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625855905;3;1
7ad95616-749b-11df-b112-00215aee3ebe;Середина-Буда;Середина-Буда;місто;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924410100;3;1
7ad95617-749b-11df-b112-00215aee3ebe;Середине;Середино;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324582405;3;1
7ad95618-749b-11df-b112-00215aee3ebe;Серединка;Серединка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425587901;3;1
7ad95619-749b-11df-b112-00215aee3ebe;Серединка;Серединка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520484603;3;1
7ad9561a-749b-11df-b112-00215aee3ebe;Серединки;Серединки;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125280902;3;1
7ad9561b-749b-11df-b112-00215aee3ebe;Серединці;Серединцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122681607;3;1
7ad9561c-749b-11df-b112-00215aee3ebe;Серединці;Серединцы;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825587501;3;1
7ad9561e-749b-11df-b112-00215aee3ebe;Середівка;Середовка;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221985501;3;1
7ad9561f-749b-11df-b112-00215aee3ebe;Середкевичі;Середкевичи;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625888201;3;1
7ad95621-749b-11df-b112-00215aee3ebe;Середнє;Среднее;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124882503;3;1
7ad95623-749b-11df-b112-00215aee3ebe;Середнє;Среднее;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124855500;3;1
7ad95624-749b-11df-b112-00215aee3ebe;Середнє;Среднее;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625880809;3;1
7ad95625-749b-11df-b112-00215aee3ebe;Середнє;Среднее;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324884405;3;1
7ad95626-749b-11df-b112-00215aee3ebe;Середнє Водяне;Среднее Водяное;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123687001;3;1
7ad95628-749b-11df-b112-00215aee3ebe;Середній Бабин;Средний Бабин;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622887402;3;1
7ad95629-749b-11df-b112-00215aee3ebe;Середній Березів;Средний Березов;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623686501;3;1
7ad9562b-749b-11df-b112-00215aee3ebe;Середній Горб;Средний Горб;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625885205;3;1
7ad9562c-749b-11df-b112-00215aee3ebe;Середній Майдан;Средний Майдан;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624086401;3;1
7ad95627-749b-11df-b112-00215aee3ebe;Середній Майдан;Средний Майдан;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7320584503;3;1
7ad9562d-749b-11df-b112-00215aee3ebe;Середній Угринів;Средний Угринев;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622884806;3;1
7ad9562e-749b-11df-b112-00215aee3ebe;Середня;Средняя;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622855303;3;1
7ad9562f-749b-11df-b112-00215aee3ebe;Середня;Средняя;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820682203;3;1
7ad95630-749b-11df-b112-00215aee3ebe;Середня Деражня;Средняя Деражня;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824085201;3;1
7ad95631-749b-11df-b112-00215aee3ebe;Середня Рудня;Средняя Рудня;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287006;3;1
7ad95632-749b-11df-b112-00215aee3ebe;Середняки;Середняки;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320487501;3;1
7ad95634-749b-11df-b112-00215aee3ebe;Середпілля;Средиполье;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320655404;3;1
7ad95635-749b-11df-b112-00215aee3ebe;Середпільці;Середпольцы;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623985201;3;1
7ad95636-749b-11df-b112-00215aee3ebe;Серетець;Серетец;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122688601;3;1
2836a32a-5c5a-11e1-b817-003048d2b473;Сереховичі;Сереховичі;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725084801;3;1
7ad95638-749b-11df-b112-00215aee3ebe;Серкізів;Серкизов;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725582609;3;1
7ad95639-749b-11df-b112-00215aee3ebe;Серне;Серное;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122787001;2;1
7ad9563a-749b-11df-b112-00215aee3ebe;Серники;Серники;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622286601;3;1
7ad9563c-749b-11df-b112-00215aee3ebe;Серпневе;Серпневое;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321281008;3;1
7ad9563d-749b-11df-b112-00215aee3ebe;Серпневе;Серпневое;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124755900;3;1
7ad9563e-749b-11df-b112-00215aee3ebe;Серхів;Серхов;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723685507;3;1
7ad9563f-749b-11df-b112-00215aee3ebe;Сестринівка;Сестриновка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521487003;3;1
7ad95640-749b-11df-b112-00215aee3ebe;Сестрятин;Сестрятин;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625887801;3;1
7ad95641-749b-11df-b112-00215aee3ebe;Сеферівка;Сеферовка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520281615;3;1
7ad9564e-749b-11df-b112-00215aee3ebe;Сибереж;Сибереж;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424488601;3;1
7ad9564f-749b-11df-b112-00215aee3ebe;Сиваківці;Сиваковцы;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522285801;3;1
7ad95651-749b-11df-b112-00215aee3ebe;Сиваш;Сиваш;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6311590001;3;1
7ad95654-749b-11df-b112-00215aee3ebe;Сиверинівка;Сивериновка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321310109;3;1
7ad95655-749b-11df-b112-00215aee3ebe;Сивір;Сивер;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822182205;3;1
7ad95658-749b-11df-b112-00215aee3ebe;Сивки;Сивки;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820387501;3;1
7ad95659-749b-11df-b112-00215aee3ebe;Сиволож;Сиволож;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420887201;3;1
7ad9565a-749b-11df-b112-00215aee3ebe;Сивороги;Сиворогы;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623383904;3;1
7ad9565b-749b-11df-b112-00215aee3ebe;Сивороги;Сиворогы;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821888001;3;1
7ad9565c-749b-11df-b112-00215aee3ebe;Сивухи;Сивухи;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422080903;3;1
7ad9565d-749b-11df-b112-00215aee3ebe;Сивці;Сивцы;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321084107;3;1
7ad9565e-749b-11df-b112-00215aee3ebe;Сигловате;Сыгловатое;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625580305;3;1
7ad9565f-749b-11df-b112-00215aee3ebe;Сигнаївка;Сигнаевка;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125786401;3;1
7ad95660-749b-11df-b112-00215aee3ebe;Сигнал;Сигнал;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521487207;3;1
7ad95662-749b-11df-b112-00215aee3ebe;Сиделівка;Сиделевка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424486408;3;1
7ad95663-749b-11df-b112-00215aee3ebe;Сидинівка;Сидиновка;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620386403;3;1
7ad95664-749b-11df-b112-00215aee3ebe;Сидоренко;Сидоренко;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223882511;3;1
7ad95665-749b-11df-b112-00215aee3ebe;Сидоренкове;Сидоренково;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321287001;3;1
7ad95666-749b-11df-b112-00215aee3ebe;Сидори;Сидоры;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321786206;3;1
7ad95667-749b-11df-b112-00215aee3ebe;Сидори;Сидоры;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620684408;3;1
7ad95668-749b-11df-b112-00215aee3ebe;Сидори;Сидоры;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220486001;3;1
89726c97-98a9-11e7-80ca-1c98ec135261;Сидори;Сидоры;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320681403;3;1
7ad9566a-749b-11df-b112-00215aee3ebe;Сидорів;Сидоров;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121687001;3;1
7ad9566b-749b-11df-b112-00215aee3ebe;Сидорівка;Сидоровка;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921588605;3;1
7ad9566c-749b-11df-b112-00215aee3ebe;Сидорівка;Сидоровка;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621588301;3;1
7ad9566d-749b-11df-b112-00215aee3ebe;Сидорівка;Сидоровка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420888404;3;1
7ad9566e-749b-11df-b112-00215aee3ebe;Сидорівка;Сидоровка;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122588101;3;1
7ad9566f-749b-11df-b112-00215aee3ebe;Сидорівщина;Сидоровщина;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281212;3;1
7ad95670-749b-11df-b112-00215aee3ebe;Сидорова Яруга;Сидорова Яруга;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921281703;3;1
7ad95672-749b-11df-b112-00215aee3ebe;Сидоровичі;Сидоровичи;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222084601;3;1
7ad95673-749b-11df-b112-00215aee3ebe;Сидоряче;Сидоряче;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322285001;3;1
7ad95676-749b-11df-b112-00215aee3ebe;Сизони;Сызоны;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
7ad95677-749b-11df-b112-00215aee3ebe;Сила;Сила;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822755108;3;1
7ad95678-749b-11df-b112-00215aee3ebe;Силівка;Силовка;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121882004;3;1
7ad95679-749b-11df-b112-00215aee3ebe;Сильне;Сильное;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723382204;3;1
7ad9567a-749b-11df-b112-00215aee3ebe;Сильне;Сильное;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721886201;3;1
7ad9567d-749b-11df-b112-00215aee3ebe;Симони;Симоны;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821786601;3;1
7ad9567e-749b-11df-b112-00215aee3ebe;Симонів;Симонов;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621287501;3;1
7ad9567f-749b-11df-b112-00215aee3ebe;Симонівка;Симоновка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924782005;3;1
7ad95680-749b-11df-b112-00215aee3ebe;Симонівка;Симоновка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824581209;3;1
7ad95682-749b-11df-b112-00215aee3ebe;Синарна;Синарна;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523185302;3;1
7ad95683-749b-11df-b112-00215aee3ebe;Сингаї;Сингаи;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755152;3;1
7ad95684-749b-11df-b112-00215aee3ebe;Сингаї;Сингаи;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822385201;3;1
7ad95685-749b-11df-b112-00215aee3ebe;Сингаївка;Сингаевка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820882103;3;1
7ad95686-749b-11df-b112-00215aee3ebe;Синдаревське;Синдаревское;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423380406;3;1
7ad95687-749b-11df-b112-00215aee3ebe;Синевир;Синевир;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122486901;3;1
7ad95688-749b-11df-b112-00215aee3ebe;Синевирська Поляна;Синевирская Поляна;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122487201;3;1
7ad95689-749b-11df-b112-00215aee3ebe;Синельникове;Синельниково;місто;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1213000000;2;0
7ad9568e-749b-11df-b112-00215aee3ebe;Синицівка;Синицевка;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525589001;3;1
7ad9568f-749b-11df-b112-00215aee3ebe;Синиця;Синица;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220687001;3;1
7ad95690-749b-11df-b112-00215aee3ebe;Синиця;Синица;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124686001;3;1
7ad95692-749b-11df-b112-00215aee3ebe;Синичине;Синичино;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322884002;3;1
7ad95693-749b-11df-b112-00215aee3ebe;Синів;Синев;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621287801;3;1
7ad95694-749b-11df-b112-00215aee3ebe;Синівка;Синевка;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923286801;3;1
7ad95695-749b-11df-b112-00215aee3ebe;Синівці;Синевцы;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123880603;3;1
7ad95696-749b-11df-b112-00215aee3ebe;Синівці;Синевцы;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321085700;3;1
7ad95697-749b-11df-b112-00215aee3ebe;Синове;Сыново;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725086001;3;1
7ad95698-749b-11df-b112-00215aee3ebe;Синютин;Синютин;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424986504;3;1
7ad95699-749b-11df-b112-00215aee3ebe;Синютки;Синютки;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820381503;3;1
7ad9569a-749b-11df-b112-00215aee3ebe;Синюха;Синюха;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523655104;3;1
7ad9569b-749b-11df-b112-00215aee3ebe;Синюха;Синюха;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524382502;3;1
7ad9569c-749b-11df-b112-00215aee3ebe;Синюхин Брід;Синюхин Брод;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825486301;3;1
7ad9569d-749b-11df-b112-00215aee3ebe;Синява;Синява;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122487801;3;1
7ad9569e-749b-11df-b112-00215aee3ebe;Синява;Синява;село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223786001;3;1
7ad9569f-749b-11df-b112-00215aee3ebe;Синява;Синява;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821486903;3;1
7ad956a0-749b-11df-b112-00215aee3ebe;Синявка;Синявка;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725583406;3;1
7ad956a1-749b-11df-b112-00215aee3ebe;Синявка;Синявка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821788403;3;1
7ad956a2-749b-11df-b112-00215aee3ebe;Синявка;Синявка;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423088001;3;1
7ad956a3-749b-11df-b112-00215aee3ebe;Синявка;Синявка;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422282505;3;1
7ad956a4-749b-11df-b112-00215aee3ebe;Синявка;Синявка;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122082704;3;1
908b8f24-ca2d-11e0-bd76-003048d2b473;Синягівка;Синяговка;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122487802;3;1
7ad956a5-749b-11df-b112-00215aee3ebe;Синяк;Синяк;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920684404;3;1
7ad956a6-749b-11df-b112-00215aee3ebe;Синяк;Синяк;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122755502;2;1
7ad956a7-749b-11df-b112-00215aee3ebe;Синяк;Синяк;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221887501;3;1
7ad956a8-749b-11df-b112-00215aee3ebe;Синяківці;Синяковцы;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821885303;3;1
7ad956a9-749b-11df-b112-00215aee3ebe;Синяківщина;Синяковщина;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325180409;3;1
7ad956aa-749b-11df-b112-00215aee3ebe;Синьки;Синьки;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525589901;3;1
7ad956ab-749b-11df-b112-00215aee3ebe;Синьків;Синьков;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122087701;3;1
7ad956ac-749b-11df-b112-00215aee3ebe;Синьків;Синьков;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623984901;3;1
7ad956ae-749b-11df-b112-00215aee3ebe;Синьковичі;Синьковичи;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783502;3;1
74dc49b4-749b-11df-b112-00215aee3ebe;Синьожупанники;Синежупанники;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525380802;3;1
7ad956b0-749b-11df-b112-00215aee3ebe;Синьооківка;Синеоковка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121588901;3;1
7ad956b1-749b-11df-b112-00215aee3ebe;Синьощоки;Синещеки;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323284605;3;1
7ad956b2-749b-11df-b112-00215aee3ebe;Сираї;Сыраи;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422089301;3;1
7ad956b3-749b-11df-b112-00215aee3ebe;Сирватинці;Сирватинцы;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821287502;3;1
7ad956b5-749b-11df-b112-00215aee3ebe;Сирівське;Сыровское;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123383204;3;1
7ad956b6-749b-11df-b112-00215aee3ebe;Сирітське Друге;Сиротское Второе;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125483606;3;1
7ad956b7-749b-11df-b112-00215aee3ebe;Сирітське Перше;Сиротское Первое;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125483605;3;1
7ad956b8-749b-11df-b112-00215aee3ebe;Сирківщина;Сырковщина;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287904;3;1
7ad956b9-749b-11df-b112-00215aee3ebe;Сирники;Сырники;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722883707;3;1
7ad956ba-749b-11df-b112-00215aee3ebe;Сирниця;Сырница;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824282103;3;1
7ad956bc-749b-11df-b112-00215aee3ebe;Сировари;Сыровары;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122681004;3;1
7ad956bd-749b-11df-b112-00215aee3ebe;Сирове;Сырово;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822384501;3;1
7ad956be-749b-11df-b112-00215aee3ebe;Сиром'ятникове;Сыромятниково;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923885806;3;1
7ad956bf-749b-11df-b112-00215aee3ebe;Сиротенки;Сиротенки;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320689402;3;1
7ad956c2-749b-11df-b112-00215aee3ebe;Сиротинка;Сиротинка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123583402;3;1
7ad956c3-749b-11df-b112-00215aee3ebe;Ситисько;Ситиско;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820680404;3;1
7ad956c4-749b-11df-b112-00215aee3ebe;Ситихів;Сытыхов;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622710205;3;1
7ad956c5-749b-11df-b112-00215aee3ebe;Ситківці;Ситковцы;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523055600;3;1
7ad956c6-749b-11df-b112-00215aee3ebe;Ситне;Сытное;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924485204;3;1
7ad956c7-749b-11df-b112-00215aee3ebe;Ситне;Сытное;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821780603;3;1
7ad956c8-749b-11df-b112-00215aee3ebe;Ситне;Сытное;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625888201;3;1
7ad956c9-749b-11df-b112-00215aee3ebe;Ситники;Сытники;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982705;3;1
7ad956ca-749b-11df-b112-00215aee3ebe;Ситники;Сытники;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323555102;3;1
7ad956cb-749b-11df-b112-00215aee3ebe;Ситники;Сытники;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122585303;3;1
7ad956cc-749b-11df-b112-00215aee3ebe;Ситниця;Ситница;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723655412;3;1
7ad956cd-749b-11df-b112-00215aee3ebe;Ситняки;Ситняки;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222787401;3;1
7ad956ce-749b-11df-b112-00215aee3ebe;Ситовичі;Ситовичи;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722187410;3;1
7ad956cf-749b-11df-b112-00215aee3ebe;Сихів;Сыхов;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625387601;3;1
7ad956d0-749b-11df-b112-00215aee3ebe;Сичавка;Сычавка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122785801;3;1
7ad956d2-749b-11df-b112-00215aee3ebe;Сичі;Сычи;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124686502;3;1
7ad956d4-749b-11df-b112-00215aee3ebe;Сичівка;Сычовка;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221884803;3;1
7ad956d5-749b-11df-b112-00215aee3ebe;Сичівка;Сычовка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823481409;3;1
7ad956d6-749b-11df-b112-00215aee3ebe;Сичівка;Сычовка;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124686501;3;1
7ad95656-749b-11df-b112-00215aee3ebe;Сівка-Войнилівська;Сивка-Войниловская;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622886601;3;1
7ad95657-749b-11df-b112-00215aee3ebe;Сівка-Калуська;Сивка-Калушская;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622886901;3;1
7ad956d8-749b-11df-b112-00215aee3ebe;Сівки;Сивки;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620480404;3;1
7ad956d9-749b-11df-b112-00215aee3ebe;Сідава;Сидава;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521081409;3;1
7ad956da-749b-11df-b112-00215aee3ebe;Сіде;Сиде;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624282804;3;1
7ad956db-749b-11df-b112-00215aee3ebe;Сідлище;Седлище;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623286201;3;1
7ad956dc-749b-11df-b112-00215aee3ebe;Сілець;Селец;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2625888603;3;1
7ad956dd-749b-11df-b112-00215aee3ebe;Сілець;Селец;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624886601;3;1
7ad956de-749b-11df-b112-00215aee3ebe;Сілець;Селец;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622185601;3;1
7ad956df-749b-11df-b112-00215aee3ebe;Сіль;Соль;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120884801;3;1
7ad956e0-749b-11df-b112-00215aee3ebe;Сільвестрівське;Сильвестровское;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824883205;3;1
7ad956e1-749b-11df-b112-00215aee3ebe;Сільниця;Сильница;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524385301;3;1
7ad956e2-749b-11df-b112-00215aee3ebe;Сільце;Сельцо;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724281803;3;1
7ad956e3-749b-11df-b112-00215aee3ebe;Сільце;Сельцо;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722181211;3;1
7ad956e4-749b-11df-b112-00215aee3ebe;Сільце;Сельцо;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720881603;3;1
7ad956e5-749b-11df-b112-00215aee3ebe;Сільце;Сельцо;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124887001;3;1
7ad956e6-749b-11df-b112-00215aee3ebe;Сільце;Сельцо;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121987001;3;0
7ad956e7-749b-11df-b112-00215aee3ebe;Сільця-Млинівські;Сельца-Млиновские;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0724285505;3;1
7ad956f0-749b-11df-b112-00215aee3ebe;Сім'янівка;Семяновка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922055306;3;1
7ad956e8-749b-11df-b112-00215aee3ebe;Сімаківка;Семаковка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821785601;3;1
7ad956ea-749b-11df-b112-00215aee3ebe;Сімейкине;Семейкино;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923883403;3;1
7ad956ec-749b-11df-b112-00215aee3ebe;Сімер;Симер;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123210103;3;1
7ad956ed-749b-11df-b112-00215aee3ebe;Сімерки;Симерки;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123210104;3;1
7ad956f1-749b-11df-b112-00215aee3ebe;Сінгури;Сингуры;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822086501;2;1
fd476fec-987d-11e2-b79b-003048d2b473;Сініжево;Синижево;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;;3;1
7ad956f2-749b-11df-b112-00215aee3ebe;Сінне;Сенное;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321880907;3;1
7ad956f3-749b-11df-b112-00215aee3ebe;Сінне;Сенное;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922385801;3;1
7ad956f4-749b-11df-b112-00215aee3ebe;Сінне;Сенное;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320888001;3;1
7ad956f5-749b-11df-b112-00215aee3ebe;Сінне;Сенное;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120688201;3;1
7ad956f7-749b-11df-b112-00215aee3ebe;Сіноводи;Сеноводы;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821584003;3;1
7ad956f8-749b-11df-b112-00215aee3ebe;Сіножацьке;Сеножатское;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425587902;3;1
7ad956fa-749b-11df-b112-00215aee3ebe;Сіренки;Сиренки;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320684603;3;1
7ad956fb-749b-11df-b112-00215aee3ebe;Сіренки;Сиренки;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922987314;3;1
7ad956fd-749b-11df-b112-00215aee3ebe;Сіриків;Сериков;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421180604;3;1
7ad956fe-749b-11df-b112-00215aee3ebe;Сірники;Сирныки;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623384505;3;1
7ad956bb-749b-11df-b112-00215aee3ebe;Сірнички;Сирнычки;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722482803;3;1
7ad956ff-749b-11df-b112-00215aee3ebe;Сіробабине;Серобабино;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983006;3;1
7ad95701-749b-11df-b112-00215aee3ebe;Сіряки;Сиряки;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322057603;3;1
7ad95702-749b-11df-b112-00215aee3ebe;Сіряківщина;Серяковщина;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421182404;3;1
7ad95703-749b-11df-b112-00215aee3ebe;Сітківці;Ситковци;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;;3;1
7ad95704-749b-11df-b112-00215aee3ebe;Сітний;Ситный;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123683002;3;1
7ad95705-749b-11df-b112-00215aee3ebe;Січ;Сич;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;;3;1
7ad95706-749b-11df-b112-00215aee3ebe;Січинці;Сечинцы;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821887901;3;1
7ad95707-749b-11df-b112-00215aee3ebe;Січкарівка;Сичкаревка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222386103;3;1
7ad95708-749b-11df-b112-00215aee3ebe;Січневе;Сичневое;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125483406;3;1
7ad95709-749b-11df-b112-00215aee3ebe;Сіянці;Сеянцы;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624287101;3;1
7ad9570b-749b-11df-b112-00215aee3ebe;Скабарівщина;Скабаровщина;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720886003;3;1
7ad9570e-749b-11df-b112-00215aee3ebe;Сказинці;Сказинцы;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522682101;3;1
7ad9570f-749b-11df-b112-00215aee3ebe;Скаківка;Скаковка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820885801;3;1
7ad95710-749b-11df-b112-00215aee3ebe;Скала;Скала;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523184801;3;1
7ad95711-749b-11df-b112-00215aee3ebe;Скала-Подільська;Скала-Подольская;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120855700;3;0
7ad95712-749b-11df-b112-00215aee3ebe;Скалат;Скалат;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124610500;3;1
7ad95713-749b-11df-b112-00215aee3ebe;Скалева;Скалевая;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523686001;3;1
7ad95714-749b-11df-b112-00215aee3ebe;Скаливатка;Скалеватка;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7110290001;3;1
7ad95716-749b-11df-b112-00215aee3ebe;Скалівські Хутори;Скалевские Хутора;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523686501;3;1
7ad95717-749b-11df-b112-00215aee3ebe;Скалонівка;Скалоновка;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322255102;3;1
7ad95718-749b-11df-b112-00215aee3ebe;Скалопіль;Скалополь;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524984005;3;1
7ad9571a-749b-11df-b112-00215aee3ebe;Скаржинка;Скаржинка;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125483002;3;1
7ad9571b-749b-11df-b112-00215aee3ebe;Скаржинці;Скаржинцы;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524886601;3;1
7ad9571c-749b-11df-b112-00215aee3ebe;Скаржинці;Скаржинцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825083602;3;1
7ad9571d-749b-11df-b112-00215aee3ebe;Скаржинці;Скаржинцы;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825886401;3;1
7ad9571e-749b-11df-b112-00215aee3ebe;Скварява;Скварява;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621886701;3;1
7ad9571f-749b-11df-b112-00215aee3ebe;Сквира;Сквира;місто;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224010100;2;0
7ad95722-749b-11df-b112-00215aee3ebe;Скелівка;Скелевка;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625184501;3;1
7ad95723-749b-11df-b112-00215aee3ebe;Скелівка;Скелевка;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125481502;3;1
7ad95724-749b-11df-b112-00215aee3ebe;Скелювате;Скелеватое;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582510;3;1
7ad95726-749b-11df-b112-00215aee3ebe;Скелюватка;Скелеватка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055707;3;1
7ad95727-749b-11df-b112-00215aee3ebe;Скелька;Скелька;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920381603;3;1
7ad95728-749b-11df-b112-00215aee3ebe;Скельки;Скельки;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321284017;3;1
7ad9572a-749b-11df-b112-00215aee3ebe;Скиби;Скибы;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723310113;3;1
7ad9572b-749b-11df-b112-00215aee3ebe;Скибин;Скибин;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221255607;3;0
7ad9572c-749b-11df-b112-00215aee3ebe;Скибин;Скибин;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120987001;3;1
7ad9572d-749b-11df-b112-00215aee3ebe;Скибин;Скибин;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523187903;3;1
7ad9572e-749b-11df-b112-00215aee3ebe;Скибинці;Скибинци;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224610113;2;1
7ad9572f-749b-11df-b112-00215aee3ebe;Скибинці;Скибинци;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523481202;3;1
7ad95730-749b-11df-b112-00215aee3ebe;Скибинці;Скибинци;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524181203;3;1
7ad95731-749b-11df-b112-00215aee3ebe;Скибинці;Скибинци;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325182105;3;1
7ad95732-749b-11df-b112-00215aee3ebe;Скибівка;Скибовка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325455306;3;1
7ad95733-749b-11df-b112-00215aee3ebe;Скибівщина;Скибовщина;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320282203;3;1
7ad95734-749b-11df-b112-00215aee3ebe;Скиданки;Скиданки;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323288607;3;1
7ad95735-749b-11df-b112-00215aee3ebe;Скинешори;Скинешоры;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125255415;3;1
7ad95736-749b-11df-b112-00215aee3ebe;Скипче;Скипче;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824288301;3;1
7ad95737-749b-11df-b112-00215aee3ebe;Скиртяне;Скиртяное;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324886503;3;1
7ad95738-749b-11df-b112-00215aee3ebe;Скитка;Скитка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522286101;3;1
7ad95776-749b-11df-b112-00215aee3ebe;Скиток;Скиток;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424486409;3;1
7ad9573a-749b-11df-b112-00215aee3ebe;Скиття;Скыття;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;;3;1
7ad95739-749b-11df-b112-00215aee3ebe;Скитьки;Скитьки;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424488805;3;1
7ad9573b-749b-11df-b112-00215aee3ebe;Скірче;Скирче;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720887201;3;1
7ad9573c-749b-11df-b112-00215aee3ebe;Склименці;Склименцы;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122582903;3;1
7ad9573d-749b-11df-b112-00215aee3ebe;Склярівка;Скляровка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786910;3;1
7ad9573e-749b-11df-b112-00215aee3ebe;Скнилів;Скнилов;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621881203;3;1
7ad9573f-749b-11df-b112-00215aee3ebe;Скнилів;Скнилов;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623686201;2;0
7ad95740-749b-11df-b112-00215aee3ebe;Скобелеве;Скобелево;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823685301;3;1
7ad95741-749b-11df-b112-00215aee3ebe;Скобелка;Скобелка;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720887401;3;1
7ad95742-749b-11df-b112-00215aee3ebe;Скобичівка;Скобычевка;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620488602;3;1
7ad95743-749b-11df-b112-00215aee3ebe;Скобичівське;Скобычевское;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925686006;3;1
7ad95746-749b-11df-b112-00215aee3ebe;Сков'ятин;Сковятин;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120887401;3;1
7ad95744-749b-11df-b112-00215aee3ebe;Сковородинівка;Сковородиновка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322681504;3;1
7ad95745-749b-11df-b112-00215aee3ebe;Сковородки;Сковородки;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824288301;3;1
7ad95747-749b-11df-b112-00215aee3ebe;Сколе;Сколе;місто;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624510100;3;0
7ad95748-749b-11df-b112-00215aee3ebe;Сколобів;Сколобов;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825482206;3;1
7ad95749-749b-11df-b112-00215aee3ebe;Сколобів;Сколобов;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821184101;3;1
7ad9574a-749b-11df-b112-00215aee3ebe;Скомороха;Скомороха;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122583701;3;1
7ad9574b-749b-11df-b112-00215aee3ebe;Скоморохи;Скоморохи;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125287401;3;1
7ad9574c-749b-11df-b112-00215aee3ebe;Скоморохи;Скоморохи;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121286001;3;1
7ad9574d-749b-11df-b112-00215aee3ebe;Скоморохи;Скоморохи;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624886801;3;1
7ad9574e-749b-11df-b112-00215aee3ebe;Скоморохи;Скоморохи;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822085902;3;1
7ad9574f-749b-11df-b112-00215aee3ebe;Скомороше;Скоморошье;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125583602;3;1
7ad95750-749b-11df-b112-00215aee3ebe;Скоморошки;Скоморошки;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523185001;3;1
7ad95751-749b-11df-b112-00215aee3ebe;Скопівка;Скоповка;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623283603;3;1
7ad95752-749b-11df-b112-00215aee3ebe;Скопіївка;Скопиевка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521781904;3;1
7ad95753-749b-11df-b112-00215aee3ebe;Скорики;Скорики;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124687101;3;1
7ad95754-749b-11df-b112-00215aee3ebe;Скорики;Скорики;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322680503;3;1
7ad95755-749b-11df-b112-00215aee3ebe;Скориківка;Скориковка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121589001;3;1
7ad95756-749b-11df-b112-00215aee3ebe;Скорінець;Скоренец;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425584003;3;1
7ad95757-749b-11df-b112-00215aee3ebe;Скоробагатьки;Скоробагатьки;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322682804;3;1
7ad95758-749b-11df-b112-00215aee3ebe;Скородинці;Скородинцы;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125587401;3;1
7ad95759-749b-11df-b112-00215aee3ebe;Скородистик;Скородистик;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125182803;3;1
7ad9575b-749b-11df-b112-00215aee3ebe;Скороходове;Скороходово;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425384004;3;1
44ac8017-749b-11df-b112-00215aee3ebe;Скороходове (Артемівка);Быстроходное (Артемовка);село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325455300;3;1
7ad9575c-749b-11df-b112-00215aee3ebe;Скорячий Яр;Скорячий Яр;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524984511;3;1
7ad9575d-749b-11df-b112-00215aee3ebe;Скосарівка;Скосаревка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123583901;3;1
7ad9575e-749b-11df-b112-00215aee3ebe;Скосарівське;Скосаревское;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125483407;3;1
7ad9575f-749b-11df-b112-00215aee3ebe;Скосогорівка;Скосогоровка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320888115;3;1
7ad95760-749b-11df-b112-00215aee3ebe;Скотареве;Скотарево;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125786801;3;1
7ad95761-749b-11df-b112-00215aee3ebe;Скотарське;Скотарское;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121585501;3;1
7ad95762-749b-11df-b112-00215aee3ebe;Скотувате;Скотоватое;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223283302;3;1
7ad95763-749b-11df-b112-00215aee3ebe;Скотувате;Скотоватое;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224255113;3;1
7ad95764-749b-11df-b112-00215aee3ebe;Скочак;Скочак;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320886605;3;1
7ad95765-749b-11df-b112-00215aee3ebe;Скочище;Скочище;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820985003;3;1
7ad95766-749b-11df-b112-00215aee3ebe;Скочище станція;Скочище станция;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;;3;1
7ad95767-749b-11df-b112-00215aee3ebe;Скраглівка;Скраглевка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820886001;3;1
7ad95768-749b-11df-b112-00215aee3ebe;Скребеличі;Скребеличи;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824286207;3;1
7ad95769-749b-11df-b112-00215aee3ebe;Скребиші;Скребыши;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220488603;2;1
7ad9576a-749b-11df-b112-00215aee3ebe;Скреготівка;Скреготовка;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721881302;3;1
7ad9576b-749b-11df-b112-00215aee3ebe;Скриванівка;Скрывановка;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124781404;3;1
7ad9576c-749b-11df-b112-00215aee3ebe;Скригалівка;Скригалевка;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224985901;3;1
7ad9576d-749b-11df-b112-00215aee3ebe;Скригове;Скриговое;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720887501;3;1
7ad9576e-749b-11df-b112-00215aee3ebe;Скрипаї;Скрипаи;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321784501;3;1
7ad9576f-749b-11df-b112-00215aee3ebe;Скрипалі;Скрипали;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187906;3;1
7ad95770-749b-11df-b112-00215aee3ebe;Скрипиця;Скрипица;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723355303;3;1
7ad95771-749b-11df-b112-00215aee3ebe;Скрипки;Скрипки;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221484902;3;1
7ad95772-749b-11df-b112-00215aee3ebe;Скрипчин;Скрипчин;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422088901;3;1
7ad95773-749b-11df-b112-00215aee3ebe;Скрипчинці;Скрипчинцы;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122588103;3;1
7ad95774-749b-11df-b112-00215aee3ebe;Скрицьке;Скрицкое;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523087201;3;1
7ad95775-749b-11df-b112-00215aee3ebe;Скрягівка;Скряговка;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925081204;3;1
7ad95777-749b-11df-b112-00215aee3ebe;Скугарі;Скугари;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425587203;3;1
7ad95779-749b-11df-b112-00215aee3ebe;Скулин;Скулин;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722183606;3;1
7ad9577a-749b-11df-b112-00215aee3ebe;Скуносове;Скуносово;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923886703;3;1
7ad9577b-749b-11df-b112-00215aee3ebe;Скурати;Скураты;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823487201;3;1
7ad9577d-749b-11df-b112-00215aee3ebe;Слабаш;Слабаш;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622486904;3;1
80f5549e-749b-11df-b112-00215aee3ebe;Слабин;Слабин;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425588101;3;1
80f5549f-749b-11df-b112-00215aee3ebe;Слабунівка;Слабуновка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320286201;3;1
80f554a0-749b-11df-b112-00215aee3ebe;Слава;Слава;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425888404;3;1
80f554a1-749b-11df-b112-00215aee3ebe;Славгород;Славгород;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922386401;3;1
80f554a2-749b-11df-b112-00215aee3ebe;Славгород;Славгород;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224856200;3;1
80f554a3-749b-11df-b112-00215aee3ebe;Славенщина;Славенщина;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755153;3;1
80f554a4-749b-11df-b112-00215aee3ebe;Славів;Славов;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825687403;3;1
80f554a5-749b-11df-b112-00215aee3ebe;Славки;Славки;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324283206;3;1
80f554a6-749b-11df-b112-00215aee3ebe;Славковиці;Славковицы;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755154;3;1
80f554a7-749b-11df-b112-00215aee3ebe;Славна;Славная;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122688750;3;1
80f554a8-749b-11df-b112-00215aee3ebe;Славна;Славная;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522286401;3;1
80f554a9-749b-11df-b112-00215aee3ebe;Славне;Славное;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721886803;3;1
80f554aa-749b-11df-b112-00215aee3ebe;Славне;Славное;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655107;3;1
80f554ab-749b-11df-b112-00215aee3ebe;Славне;Славное;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955424;3;1
80f554b0-749b-11df-b112-00215aee3ebe;Славне;Славное;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520655903;3;1
80f554b1-749b-11df-b112-00215aee3ebe;Славне;Славное;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521980710;3;1
80f554b2-749b-11df-b112-00215aee3ebe;Славське;Славское;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624555700;3;1
80f554b3-749b-11df-b112-00215aee3ebe;Славута;Славута;місто;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6810600000;1;0
a5388eb7-db65-11df-9197-00215aee3ebe;Славута;Славута;місто;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823382006;3;1
80f554b5-749b-11df-b112-00215aee3ebe;Славутич;Славутич;місто;fbfb8c15-41be-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;3211500000;2;0
80f554b6-749b-11df-b112-00215aee3ebe;Слатине;Слатино;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322057300;3;1
80f554b7-749b-11df-b112-00215aee3ebe;Сливине;Сливино;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824280403;3;1
80f554b8-749b-11df-b112-00215aee3ebe;Сливки;Сливки;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624885201;3;1
80f554b9-749b-11df-b112-00215aee3ebe;Сливниця;Сливница;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625184704;3;1
80f554bd-749b-11df-b112-00215aee3ebe;Слинківщина;Слинковщина;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321386404;3;1
80f554be-749b-11df-b112-00215aee3ebe;Слиньків Яр;Слиньков Яр;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321084906;3;1
cdc44f5e-dde8-11df-9197-00215aee3ebe;Слипенький;Слипенький;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;;3;1
80f554bf-749b-11df-b112-00215aee3ebe;Сліди;Следы;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524585701;3;1
80f554c0-749b-11df-b112-00215aee3ebe;Сліди;Следы;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522686201;2;1
80f554c1-749b-11df-b112-00215aee3ebe;Сліпорід;Слепород;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323687403;3;1
80f554c2-749b-11df-b112-00215aee3ebe;Сліпорід-Іванівка;Слепород-Ивановка;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320882502;3;1
80f554c3-749b-11df-b112-00215aee3ebe;Сліпород;Слепород;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5910390001;3;1
80f554c5-749b-11df-b112-00215aee3ebe;Сліпчиці;Слепчицы;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825687601;3;1
80f554c6-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923284803;3;1
80f554c7-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123086201;3;1
80f554c8-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125082902;3;1
80f554c9-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122087601;3;1
80f554ca-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823682207;3;1
80f554cb-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322082002;3;1
80f554cc-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321081802;3;1
80f554cd-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323586208;3;1
80f554ce-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122555500;3;1
80f554cf-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123985208;3;1
80f554d0-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124783802;3;1
80f554d1-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622885104;3;1
80f554d2-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625885401;3;1
80f554d3-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621685201;3;1
80f554d4-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623685701;3;1
80f554d5-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625384605;3;1
80f554d6-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823487401;3;1
80f554d7-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825484606;3;1
80f554d8-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822585601;3;1
80f554d9-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423088201;3;1
80f554da-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423681203;3;1
80f554db-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420387004;3;1
80f554dc-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425383503;3;1
80f554dd-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521682806;3;1
80f554de-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521210114;3;1
80f554df-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523086403;3;1
80f554e0-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825882603;3;1
80f554e1-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821587403;3;1
80f554e2-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823389003;3;1
80f554e3-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624284203;3;1
80f554e4-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322682407;3;1
80f554e5-749b-11df-b112-00215aee3ebe;Слобідка;Слободка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323287501;3;1
73e94c08-ddc1-11df-9197-00215aee3ebe;Слобідка Більшівцівська;Слободка Большовцевская;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621255301;3;1
80f554e6-749b-11df-b112-00215aee3ebe;Слобідка-Балинська;Слободка-Балинская;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821889703;3;1
80f554e7-749b-11df-b112-00215aee3ebe;Слобідка-Гірчичнянська;Слободка-Горчичнянская;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821883304;3;1
80f554e8-749b-11df-b112-00215aee3ebe;Слобідка-Глушковецька;Слободка-Глушковецкая;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825882803;3;1
80f554e9-749b-11df-b112-00215aee3ebe;Слобідка-Гуменецька;Слободка-Гуменецкая;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822481806;2;1
80f554ea-749b-11df-b112-00215aee3ebe;Слобідка-Залісецька;Слободка-Залесецкая;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821884703;3;1
80f554eb-749b-11df-b112-00215aee3ebe;Слобідка-іванковецька;Слободка-Иванковецкая;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821883300;3;1
80f554ec-749b-11df-b112-00215aee3ebe;Слобідка-Кадиївська;Слободка-Кадиевская;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825883203;3;1
80f554ed-749b-11df-b112-00215aee3ebe;Слобідка-Кальнянська;Слободка-Кальнянская;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;;3;1
80f554ee-749b-11df-b112-00215aee3ebe;Слобідка-Красилівська;Слободка-Красиловская;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822789802;3;1
80f554ef-749b-11df-b112-00215aee3ebe;Слобідка-Кузьминська;Слободка-Кузьминская;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821286203;3;1
80f554f0-749b-11df-b112-00215aee3ebe;Слобідка-Кульчієвецька;Слободка-Кульчиевецкая;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822487401;2;1
80f554f1-749b-11df-b112-00215aee3ebe;Слобідка-Малиновецька;Слободка-Малиновецкая;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822487904;3;1
80f554f2-749b-11df-b112-00215aee3ebe;Слобідка-Малієвецька;Слободка-Малиевецкая;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821885002;3;1
80f554f3-749b-11df-b112-00215aee3ebe;Слобідка-Мушкатівська;Слободка-Мушкатовская;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120884903;3;1
80f554f4-749b-11df-b112-00215aee3ebe;Слобідка-Олексинецька;Слободка-Алексинецкая;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820686003;3;1
80f554f5-749b-11df-b112-00215aee3ebe;Слобідка-Охрімовецька;Слободка-Охримовецкая;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820686003;3;1
80f554f6-749b-11df-b112-00215aee3ebe;Слобідка-Рахнівська;Слободка-Рахновская;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821884705;3;1
80f554f7-749b-11df-b112-00215aee3ebe;Слобідка-Рихтівська;Слободка-Рыхтивская;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822487601;2;1
80f554f8-749b-11df-b112-00215aee3ebe;Слобідка-Скипчанська;Слободка-Скипчанская;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825281203;2;1
80f554f9-749b-11df-b112-00215aee3ebe;Слобідка-Смотрицька;Слободка-Смотричская;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825287401;2;1
80f554fa-749b-11df-b112-00215aee3ebe;Слобідка-Чернелівська;Слободка-Чернелевская;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822783405;3;1
80f554fb-749b-11df-b112-00215aee3ebe;Слобідка-Шелехівська;Слободка-Шелеховская;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821589207;3;1
74dc4e60-749b-11df-b112-00215aee3ebe;Слобідське (Правда);Слободское (Правда);село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324586201;3;1
87162332-749b-11df-b112-00215aee3ebe;Слобідське (Фрунзе);Слободское (Фрунзе);село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157313;3;1
80f554fc-749b-11df-b112-00215aee3ebe;Слобода;Слобода;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;;3;1
80f554fd-749b-11df-b112-00215aee3ebe;Слобода;Слобода;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;;3;1
80f554fe-749b-11df-b112-00215aee3ebe;Слобода;Слобода;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920986001;3;1
80f554ff-749b-11df-b112-00215aee3ebe;Слобода;Слобода;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323087101;3;1
80f55500-749b-11df-b112-00215aee3ebe;Слобода;Слобода;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621286003;3;1
80f55501-749b-11df-b112-00215aee3ebe;Слобода;Слобода;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623255707;3;1
80f55502-749b-11df-b112-00215aee3ebe;Слобода;Слобода;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222286801;3;1
80f55503-749b-11df-b112-00215aee3ebe;Слобода;Слобода;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287001;3;1
80f55504-749b-11df-b112-00215aee3ebe;Слобода;Слобода;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425588301;3;1
80f55505-749b-11df-b112-00215aee3ebe;Слобода;Слобода;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520285312;3;1
80f55506-749b-11df-b112-00215aee3ebe;Слобода;Слобода;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521084808;3;1
80f55507-749b-11df-b112-00215aee3ebe;Слобода;Слобода;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;;3;1
80f55508-749b-11df-b112-00215aee3ebe;Слобода;Слобода;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823382006;3;1
7ad95523-749b-11df-b112-00215aee3ebe;Слобода;Слобода;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;;3;1
7ad94f91-749b-11df-b112-00215aee3ebe;Слобода (Радянське);Слобода (Радянское);село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922987113;3;1
80f5550c-749b-11df-b112-00215aee3ebe;Слобода Перша;Слобода Первая;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;;3;1
80f5550e-749b-11df-b112-00215aee3ebe;Слобода-Банилів;Слобода-Банилов;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320585301;3;1
80f5550f-749b-11df-b112-00215aee3ebe;Слобода-Болехівська;Слобода-Болеховская;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622080507;3;1
80f55510-749b-11df-b112-00215aee3ebe;Слобода-Бушанська;Слобода-Бушанская;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525680804;3;1
80f55511-749b-11df-b112-00215aee3ebe;Слобода-В'язівка;Слобода-Вязовка;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755155;3;1
80f55509-749b-11df-b112-00215aee3ebe;Слобода-Гулівська;Слобода-Гулевская;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520281403;3;1
80f5550b-749b-11df-b112-00215aee3ebe;Слобода-Дашковецька;Слобода-Дашковецкая;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520688907;3;1
80f55512-749b-11df-b112-00215aee3ebe;Слобода-Долинська;Слобода-Долинская;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622086902;3;1
80f55513-749b-11df-b112-00215aee3ebe;Слобода-Комарівці;Слобода-Комаровцы;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324588301;3;1
80f55514-749b-11df-b112-00215aee3ebe;Слобода-Кустовецька;Слобода-Кустовецкая;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524883303;3;1
80f55515-749b-11df-b112-00215aee3ebe;Слобода-Кухарська;Слобода-Кухарская;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222081307;3;1
80f55516-749b-11df-b112-00215aee3ebe;Слобода-Межирівська;Слобода-Межировская;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521085603;3;1
80f55517-749b-11df-b112-00215aee3ebe;Слобода-Небилівська;Слобода-Небыловская;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624883604;3;1
80f55518-749b-11df-b112-00215aee3ebe;Слобода-Новоселицька;Слобода-Новоселицкая;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824286506;3;1
80f55519-749b-11df-b112-00215aee3ebe;Слобода-Носковецька;Слобода-Носковецкая;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521083006;3;1
80f5551a-749b-11df-b112-00215aee3ebe;Слобода-Підлісівська;Слобода-Подлесовская;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525685501;3;1
80f5551b-749b-11df-b112-00215aee3ebe;Слобода-Рівнянська;Слобода-Ровнянская;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624884802;3;1
80f5551c-749b-11df-b112-00215aee3ebe;Слобода-Романівська;Слобода-Романовская;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824084005;3;1
80f5551d-749b-11df-b112-00215aee3ebe;Слобода-Селець;Слобода-Селец;село;d00d3b86-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822086803;1;0
80f5550a-749b-11df-b112-00215aee3ebe;Слобода-Ходацька;Слобода-Ходакская;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520285315;3;1
80f5550d-749b-11df-b112-00215aee3ebe;Слобода-Чернятинська;Слобода-Чернятинская;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521087506;3;1
80f5551e-749b-11df-b112-00215aee3ebe;Слобода-Шаргородська;Слобода-Шаргородская;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525387701;3;1
80f5551f-749b-11df-b112-00215aee3ebe;Слобода-Шлишковецька;Слобода-Шлышковецкая;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522682604;3;1
80f55520-749b-11df-b112-00215aee3ebe;Слобода-Шоломківська;Слобода-Шоломковская;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824288805;3;1
80f55521-749b-11df-b112-00215aee3ebe;Слобода-Ялтушківська;Слобода-Ялтушковская;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520286515;3;1
80f55522-749b-11df-b112-00215aee3ebe;Слобода-Яришівська;Слобода-Ярышевская;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522686401;3;1
80f55523-749b-11df-b112-00215aee3ebe;Слободище;Слободище;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820886301;3;1
80f55524-749b-11df-b112-00215aee3ebe;Слободище;Слободище;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521286803;3;1
80f55525-749b-11df-b112-00215aee3ebe;Слободо-Петрівка;Слободо-Петровка;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320884901;3;1
80f55526-749b-11df-b112-00215aee3ebe;Слободяки;Слободяки;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625855906;3;1
08c6c902-1af9-11e9-80dc-1c98ec135261;Слобожанське;Слобожанскоє;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320683002;3;1
87162591-749b-11df-b112-00215aee3ebe;Слобожанське;Слобожанское;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323155600;3;1
56bdd241-749b-11df-b112-00215aee3ebe;Слобожанське (Жовтневе);Слобожанське (Жовтневе);село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320280402;3;1
56bdd23b-749b-11df-b112-00215aee3ebe;Слобожанське (Жовтневе);Слобожанское (Жовтневое);село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325181001;3;1
5cb619ac-749b-11df-b112-00215aee3ebe;Слобожанське (Комінтерн);Слобожанское (Коминтерн);село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324285006;3;1
5cb619ca-749b-11df-b112-00215aee3ebe;Слобожанське (Комсомольське);Слобожанское (Комсомольское);село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321755600;2;0
8d17eee5-749b-11df-b112-00215aee3ebe;Слобожанське (Ювілейне );Слобожанское (Юбилейное);село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221455800;1;0
80f5552a-749b-11df-b112-00215aee3ebe;Слов'янка;Славянка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222688801;3;1
80f5552b-749b-11df-b112-00215aee3ebe;Слов'янка;Славянка;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220310115;3;1
80f55530-749b-11df-b112-00215aee3ebe;Слов'яносербка;Словяносербка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121684601;3;1
80f55533-749b-11df-b112-00215aee3ebe;Слов'янськ;Славянск;місто;5d311214-41b9-11df-907f-00215aee3ebe;d15e301c-60b0-11de-be1e-0030485903e8;572fe7f2-5e88-11ee-80c0-000c2961d091;1414100000;1;0
80f55536-749b-11df-b112-00215aee3ebe;Слов'ятин;Славятин;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120488401;3;1
80f55527-749b-11df-b112-00215aee3ebe;Словатичі;Словатичи;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721886804;3;1
80f55528-749b-11df-b112-00215aee3ebe;Словечне;Словечно;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287301;3;1
80f55529-749b-11df-b112-00215aee3ebe;Словіта;Словита;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621886901;3;1
80f55537-749b-11df-b112-00215aee3ebe;Слоповий;Слоповый;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125384804;3;1
80f55538-749b-11df-b112-00215aee3ebe;Слоут;Слоут;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921586401;3;1
80f55539-749b-11df-b112-00215aee3ebe;Слохині;Слохини;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625184701;3;1
80f5553a-749b-11df-b112-00215aee3ebe;Случ;Случ;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820989004;3;1
80f5553b-749b-11df-b112-00215aee3ebe;Слюзиха;Слюзиха;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322681907;3;1
80f5553c-749b-11df-b112-00215aee3ebe;Слюзівка;Слюзовка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324555113;3;1
80f5553d-749b-11df-b112-00215aee3ebe;Слюсареве;Слюсарево;село;6b07f835-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5124380903;3;1
80f5553e-749b-11df-b112-00215aee3ebe;Слюсарі;Слюсари;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;;3;1
80f5553f-749b-11df-b112-00215aee3ebe;Смаржинці;Смаржинцы;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523481003;3;1
80f55540-749b-11df-b112-00215aee3ebe;Смереків;Смереков;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622788501;3;1
80f55541-749b-11df-b112-00215aee3ebe;Смереківка;Смерековка;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623382904;3;1
80f55542-749b-11df-b112-00215aee3ebe;Смереково;Смереково;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120884401;3;1
80f55543-749b-11df-b112-00215aee3ebe;Смеречка;Смеречка;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625184303;3;1
80f55544-749b-11df-b112-00215aee3ebe;Смерклів;Смерклов;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625685604;3;1
80f55545-749b-11df-b112-00215aee3ebe;Смига;Смыга;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621655900;3;1
80f55546-749b-11df-b112-00215aee3ebe;Смикарівка;Смыкаревка;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921585202;3;1
80f55547-749b-11df-b112-00215aee3ebe;Смиків;Смиков;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624886901;3;1
80f55548-749b-11df-b112-00215aee3ebe;Смиків;Смиков;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621480718;3;1
80f55549-749b-11df-b112-00215aee3ebe;Смиківка;Смыковка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822586705;3;1
80f5554a-749b-11df-b112-00215aee3ebe;Смиківці;Смыковцы;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125287501;2;1
80f5554c-749b-11df-b112-00215aee3ebe;Смирнівка;Смирновка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323986501;3;1
f9333455-db4e-11df-9197-00215aee3ebe;Смичин;Смычин;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421487601;3;1
80f5554e-749b-11df-b112-00215aee3ebe;Смідин;Смидин;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725085001;3;1
80f5554f-749b-11df-b112-00215aee3ebe;Сміла;Смела;місто;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7110500000;2;0
80f55550-749b-11df-b112-00215aee3ebe;Сміла;Смела;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524885203;3;1
80f55551-749b-11df-b112-00215aee3ebe;Сміле;Смелое;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924188801;3;1
80f55554-749b-11df-b112-00215aee3ebe;Сміле;Смелое;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422288602;3;1
80f55555-749b-11df-b112-00215aee3ebe;Сміливе;Смелое;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521784802;3;1
80f55556-749b-11df-b112-00215aee3ebe;Смільна;Смильная;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621286601;3;1
80f55557-749b-11df-b112-00215aee3ebe;Смільне;Смильное;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620386401;3;1
80f55558-749b-11df-b112-00215aee3ebe;Смільчинці;Смельчинцы;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122886801;3;1
80f55559-749b-11df-b112-00215aee3ebe;Смогів;Смогов;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621584907;3;1
80f5555a-749b-11df-b112-00215aee3ebe;Смодна;Смодна;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623685801;3;1
80f5555b-749b-11df-b112-00215aee3ebe;Сможе;Сможе;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624587201;3;1
80f5555c-749b-11df-b112-00215aee3ebe;Смолдирів;Смолдырев;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820685301;3;1
80f5555d-749b-11df-b112-00215aee3ebe;Смоленка;Смоленка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222082010;3;1
80f5555e-749b-11df-b112-00215aee3ebe;Смолигів;Смолигов;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722886201;3;1
80f5555f-749b-11df-b112-00215aee3ebe;Смолигівка;Смолиговка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424488801;3;1
80f55560-749b-11df-b112-00215aee3ebe;Смолин;Смолин;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625888401;3;1
80f55561-749b-11df-b112-00215aee3ebe;Смолин;Смолин;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425588501;3;1
80f55562-749b-11df-b112-00215aee3ebe;Смолине;Смолино;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921585203;3;1
80f55563-749b-11df-b112-00215aee3ebe;Смолівка;Смоловка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325785005;3;1
80f55564-749b-11df-b112-00215aee3ebe;Смолівка;Смоловка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822587911;3;1
80f55565-749b-11df-b112-00215aee3ebe;Смоліне;Смолино;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523155700;3;1
8716271b-749b-11df-b112-00215aee3ebe;Смолка;Смолка;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820685303;3;1
80f55566-749b-11df-b112-00215aee3ebe;Смолове;Смоловое;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420387505;3;1
80f55567-749b-11df-b112-00215aee3ebe;Смологовиця;Смологовица;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121987205;3;1
80f55568-749b-11df-b112-00215aee3ebe;Смолява;Смолява;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720887601;3;1
80f55569-749b-11df-b112-00215aee3ebe;Смоляж;Смоляж;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420887401;3;1
80f5556a-749b-11df-b112-00215aee3ebe;Смоляне;Смоляное;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322185703;3;1
80f5556b-749b-11df-b112-00215aee3ebe;Смоляне;Смоляное;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824282204;3;1
80f5556c-749b-11df-b112-00215aee3ebe;Смоляний;Смоляной;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625381605;3;1
80f5556d-749b-11df-b112-00215aee3ebe;Смоляниківка;Смоляниковка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920680303;3;1
80f5556f-749b-11df-b112-00215aee3ebe;Смолянка;Смолянка;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125287403;3;1
80f55570-749b-11df-b112-00215aee3ebe;Смолянка;Смолянка;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122584801;3;1
80f55571-749b-11df-b112-00215aee3ebe;Смолянка;Смолянка;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422787001;3;1
80f55573-749b-11df-b112-00215aee3ebe;Смоляри-Світязькі;Смоляры-Свитязские;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725780904;3;1
80f55572-749b-11df-b112-00215aee3ebe;Смолярі;Смоляры;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725085203;3;1
80f55574-749b-11df-b112-00215aee3ebe;Смордва;Смордва;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623888501;3;1
80f55575-749b-11df-b112-00215aee3ebe;Сморжів;Сморжев;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623985501;3;1
80f55576-749b-11df-b112-00215aee3ebe;Сморжів;Сморжев;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624684910;3;1
80f55577-749b-11df-b112-00215aee3ebe;Смородине;Смородино;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321510101;3;1
80f55578-749b-11df-b112-00215aee3ebe;Смородськ;Смородск;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621889202;3;1
80f55579-749b-11df-b112-00215aee3ebe;Смородське;Смородское;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6311290009;3;1
80f5557a-749b-11df-b112-00215aee3ebe;Смородщина;Смородщина;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325481602;3;1
80f5557c-749b-11df-b112-00215aee3ebe;Сморшки;Сморшки;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422087703;3;1
80f5557d-749b-11df-b112-00215aee3ebe;Сморшки;Сморшки;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822188402;3;1
80f5557e-749b-11df-b112-00215aee3ebe;Смотрики;Смотрики;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323885201;3;1
80f5557f-749b-11df-b112-00215aee3ebe;Смотрич;Смотрич;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822484203;2;0
80f55580-749b-11df-b112-00215aee3ebe;Смотрич;Смотрич;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821855700;3;1
80f55581-749b-11df-b112-00215aee3ebe;Смош;Смош;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424188501;3;1
80f55583-749b-11df-b112-00215aee3ebe;Смяч;Смяч;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425886001;3;1
80f55584-749b-11df-b112-00215aee3ebe;Смяч;Смяч;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423688901;3;1
cdc44f50-dde8-11df-9197-00215aee3ebe;Снитище;Снитище;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755156;3;1
80f55585-749b-11df-b112-00215aee3ebe;Сніги;Снеги;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322684509;3;1
80f55586-749b-11df-b112-00215aee3ebe;Снігурівка;Снегуровка;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123885201;3;1
80f55587-749b-11df-b112-00215aee3ebe;Снігурівка;Снегуровка;місто;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825710100;2;1
80f5558a-749b-11df-b112-00215aee3ebe;Снігурівка;Снегуровка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121586204;3;1
80f5558b-749b-11df-b112-00215aee3ebe;Снідавка;Снидавка;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2623686001;3;1
80f5558c-749b-11df-b112-00215aee3ebe;Сніжки;Снежки;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920986401;3;1
80f5558d-749b-11df-b112-00215aee3ebe;Сніжки;Снежки;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224286001;3;1
80f5558e-749b-11df-b112-00215aee3ebe;Сніжків;Снежков;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321287501;3;1
80f5558f-749b-11df-b112-00215aee3ebe;Сніжківка;Снежковка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322887006;3;1
80f55590-749b-11df-b112-00215aee3ebe;Сніжкова Балка;Снежковая Балка;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525280504;3;1
80f55591-749b-11df-b112-00215aee3ebe;Сніжкове;Снежково;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525288102;3;1
80f55592-749b-11df-b112-00215aee3ebe;Сніжна;Снежная;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523486201;3;1
80f55595-749b-11df-b112-00215aee3ebe;Снітин;Снитин;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322886201;3;1
80f55596-749b-11df-b112-00215aee3ebe;Снітине;Снитино;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322887907;3;1
80f55597-749b-11df-b112-00215aee3ebe;Снітівка;Снитовка;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823085401;3;1
80f55598-749b-11df-b112-00215aee3ebe;Снітків;Снитков;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522887101;3;1
80f5559e-749b-11df-b112-00215aee3ebe;Снов'янка;Сновянка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425581004;3;1
80f55599-749b-11df-b112-00215aee3ebe;Сновидів;Сновидов;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121286201;3;1
80f5559a-749b-11df-b112-00215aee3ebe;Сновидовичі;Сновидовичи;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824486204;3;1
80f5559b-749b-11df-b112-00215aee3ebe;Сновидовичі;Сновидовичи;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625086201;3;1
80f5559c-749b-11df-b112-00215aee3ebe;Сновичі;Сновичи;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621887201;3;1
8d17eece-749b-11df-b112-00215aee3ebe;Сновськ;Сновск;місто;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425810100;2;0
80f5559d-749b-11df-b112-00215aee3ebe;Сновське;Сновское;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425886501;3;1
80f5559f-749b-11df-b112-00215aee3ebe;Снопове;Сноповое;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083208;3;1
80f555a0-749b-11df-b112-00215aee3ebe;Снятин;Снятын;місто;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625210100;2;0
80f555a1-749b-11df-b112-00215aee3ebe;Снятинка;Снятынка;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621286801;3;1
80f555a2-749b-11df-b112-00215aee3ebe;Снячів;Снячев;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324588501;3;1
80f555a4-749b-11df-b112-00215aee3ebe;Собатин;Собатин;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121910101;3;1
80f555a5-749b-11df-b112-00215aee3ebe;Собич;Собич;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925386601;3;1
80f555a6-749b-11df-b112-00215aee3ebe;Собичеве;Собичево;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925386101;3;1
80f555a7-749b-11df-b112-00215aee3ebe;Собіщиці;Собещицы;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620888901;3;1
80f555a8-749b-11df-b112-00215aee3ebe;Собківка;Собковка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323482206;3;1
80f555a9-749b-11df-b112-00215aee3ebe;Собківка;Собковка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124387501;3;1
80f555ab-749b-11df-b112-00215aee3ebe;Соболівка;Соболевка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122985607;3;1
80f555ac-749b-11df-b112-00215aee3ebe;Соболівка;Соболевка;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221285202;3;1
80f555ad-749b-11df-b112-00215aee3ebe;Соболівка;Соболевка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222782304;3;1
80f555ae-749b-11df-b112-00215aee3ebe;Соболівка;Соболевка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386605;3;1
80f555af-749b-11df-b112-00215aee3ebe;Соболівка;Соболевка;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820985301;3;1
80f555b0-749b-11df-b112-00215aee3ebe;Соболівка;Соболевка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821484801;3;1
80f555b1-749b-11df-b112-00215aee3ebe;Соболівка;Соболевка;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125787201;3;1
80f555b2-749b-11df-b112-00215aee3ebe;Соболівка;Соболевка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522285802;3;1
80f555b3-749b-11df-b112-00215aee3ebe;Соболівка;Соболевка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523786401;3;1
80f555b4-749b-11df-b112-00215aee3ebe;Соболівка;Соболевка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820984706;3;1
f2163a11-6458-11e6-b3d3-001ec93df11f;Соболівка;Соболивка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;a8bc35df-4aa1-11e3-9df6-003048d2b473;3222782304;3;1
74dc4b63-749b-11df-b112-00215aee3ebe;Соборне;Соборное;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920382004;3;1
6dd84a04-c5c7-11e6-80f3-1c98ec135263;Соборне (Жовтневе);Соборное (Жовтневое);село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125289202;2;1
80f555ba-749b-11df-b112-00215aee3ebe;Совинка;Совинка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922081503;3;1
b8c51ec8-c762-11e1-973a-003048d2b473;Совіньйон;Совиньйон;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;;3;1
80f555bb-749b-11df-b112-00215aee3ebe;Совпа;Совпа;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620488902;3;1
80f555bc-749b-11df-b112-00215aee3ebe;Совхоз Дзержинського;Совхоз Дзержинського;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;;3;1
80f555bf-749b-11df-b112-00215aee3ebe;Созань;Созань;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625185503;3;1
80f555c0-749b-11df-b112-00215aee3ebe;Созонівка;Созоновка;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121883206;3;1
80f555c1-749b-11df-b112-00215aee3ebe;Созонівка;Созоновка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522587001;3;1
80f555c2-749b-11df-b112-00215aee3ebe;Сойми;Соймы;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122487401;3;1
34fec090-ff60-11de-9277-00215aee3ebe;Сокаль;Сокаль;місто;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624810100;2;0
80f555c3-749b-11df-b112-00215aee3ebe;Сокирин;Сокирин;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422089303;3;1
80f555c4-749b-11df-b112-00215aee3ebe;Сокиринці;Сокиринцы;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125587201;3;1
80f555c5-749b-11df-b112-00215aee3ebe;Сокиринці;Сокиринцы;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425188001;3;1
80f555c6-749b-11df-b112-00215aee3ebe;Сокиринці;Сокиринцы;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520686303;3;0
80f555c7-749b-11df-b112-00215aee3ebe;Сокиринці;Сокиринцы;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825287801;2;1
80f555c8-749b-11df-b112-00215aee3ebe;Сокириха;Сокириха;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322680405;3;1
80f555c9-749b-11df-b112-00215aee3ebe;Сокиричі;Сокиричи;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721886601;3;1
80f555cb-749b-11df-b112-00215aee3ebe;Сокирна;Сокирна;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124986002;3;1
80f555cc-749b-11df-b112-00215aee3ebe;Сокирне;Сокирное;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121882002;3;1
80f555cd-749b-11df-b112-00215aee3ebe;Сокирниця;Сокирница;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125387401;3;0
80f555ce-749b-11df-b112-00215aee3ebe;Сокирчин;Сокирчин;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625687603;3;1
80f555cf-749b-11df-b112-00215aee3ebe;Сокиряни;Сокиряны;місто;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324010100;3;0
80f555d0-749b-11df-b112-00215aee3ebe;Сокиряни;Сокиряны;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523786901;3;1
80f555d1-749b-11df-b112-00215aee3ebe;Сокіл;Сокол;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723384916;3;1
80f555d2-749b-11df-b112-00215aee3ebe;Сокіл;Сокол;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724586201;3;1
80f555d3-749b-11df-b112-00215aee3ebe;Сокіл;Сокол;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621284202;3;1
80f555d4-749b-11df-b112-00215aee3ebe;Сокіл;Сокол;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622183007;3;1
80f555d6-749b-11df-b112-00215aee3ebe;Сокіл;Сокол;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524986001;3;1
80f555d7-749b-11df-b112-00215aee3ebe;Сокіл;Сокол;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822487901;3;1
80f555d8-749b-11df-b112-00215aee3ebe;Сокілець;Соколец;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121283805;3;1
80f555d9-749b-11df-b112-00215aee3ebe;Сокілець;Соколец;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521487203;3;1
80f555da-749b-11df-b112-00215aee3ebe;Сокілець;Соколец;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523087801;3;1
80f555db-749b-11df-b112-00215aee3ebe;Сокілець;Соколец;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821888301;3;1
80f555dc-749b-11df-b112-00215aee3ebe;Сокільники;Сокольники;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125087603;3;1
80f555dd-749b-11df-b112-00215aee3ebe;Сокільники;Сокольники;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623686401;1;0
8cdd5ce3-de27-11df-9197-00215aee3ebe;Сокільники;Сокольники;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522285403;3;1
80f555df-749b-11df-b112-00215aee3ebe;Сокільське;Сокольское;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955120;3;1
80f555e0-749b-11df-b112-00215aee3ebe;Сокільці;Сокольцы;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520883308;3;1
80f555e1-749b-11df-b112-00215aee3ebe;Сокільча;Сокольча;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824786201;3;1
80f555e3-749b-11df-b112-00215aee3ebe;Соколине;Соколиное;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786911;3;1
80f555e5-749b-11df-b112-00215aee3ebe;Соколинці;Соколинцы;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524583505;3;1
80f555e6-749b-11df-b112-00215aee3ebe;Соколище;Соколище;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725085401;3;1
80f555e8-749b-11df-b112-00215aee3ebe;Соколів;Соколов;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125087601;3;1
80f555e9-749b-11df-b112-00215aee3ebe;Соколів;Соколов;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121286601;3;1
80f555ea-749b-11df-b112-00215aee3ebe;Соколів;Соколов;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321785001;3;1
80f555eb-749b-11df-b112-00215aee3ebe;Соколів;Соколов;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622181604;3;1
80f555ec-749b-11df-b112-00215aee3ebe;Соколів;Соколов;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825484901;3;1
80f555ed-749b-11df-b112-00215aee3ebe;Соколів Брід;Соколов Брод;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824784802;3;1
80f555ee-749b-11df-b112-00215aee3ebe;Соколівка;Соколовка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221010301;3;1
80f555ef-749b-11df-b112-00215aee3ebe;Соколівка;Соколовка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825485705;3;1
80f555f0-749b-11df-b112-00215aee3ebe;Соколівка;Соколовка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821484803;3;1
80f555f1-749b-11df-b112-00215aee3ebe;Соколівка;Соколовка;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821181302;3;1
80f555f2-749b-11df-b112-00215aee3ebe;Соколівка;Соколовка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320280405;3;1
80f555f3-749b-11df-b112-00215aee3ebe;Соколівка;Соколовка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321584011;3;1
80f555f4-749b-11df-b112-00215aee3ebe;Соколівка;Соколовка;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625686202;3;1
80f555f5-749b-11df-b112-00215aee3ebe;Соколівка;Соколовка;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623686301;3;1
80f555f6-749b-11df-b112-00215aee3ebe;Соколівка;Соколовка;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620685301;3;1
80f555f7-749b-11df-b112-00215aee3ebe;Соколівка;Соколовка;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623684601;3;1
80f555f8-749b-11df-b112-00215aee3ebe;Соколівка;Соколовка;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621588501;3;1
80f555f9-749b-11df-b112-00215aee3ebe;Соколівка;Соколовка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221487401;3;1
80f555fa-749b-11df-b112-00215aee3ebe;Соколівка;Соколовка;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422085303;3;1
80f555fb-749b-11df-b112-00215aee3ebe;Соколівка;Соколовка;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420687401;3;1
80f555fc-749b-11df-b112-00215aee3ebe;Соколівка;Соколовка;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120987401;3;1
80f555fd-749b-11df-b112-00215aee3ebe;Соколівка;Соколовка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522481604;3;1
80f555fe-749b-11df-b112-00215aee3ebe;Соколівка;Соколовка;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521986201;3;1
80f555ff-749b-11df-b112-00215aee3ebe;Соколівка;Соколовка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825886801;3;1
80f55600-749b-11df-b112-00215aee3ebe;Соколівка;Соколовка;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823388503;3;1
80f555e7-749b-11df-b112-00215aee3ebe;Соколівка;Соколовка;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123883005;3;1
17af7b16-ddb9-11df-9197-00215aee3ebe;Соколівка;Соколовка;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523483303;3;1
80f55601-749b-11df-b112-00215aee3ebe;Соколівочка;Соколовочка;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124089201;3;1
80f55602-749b-11df-b112-00215aee3ebe;Соколівське;Соколовское;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522587201;3;1
80f55603-749b-11df-b112-00215aee3ebe;Соколівщина;Соколовщина;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321381608;3;1
80f55604-749b-11df-b112-00215aee3ebe;Соколівщина;Соколовщина;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225510102;3;1
80f55605-749b-11df-b112-00215aee3ebe;Соколій;Соколий;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323581505;3;1
80f55606-749b-11df-b112-00215aee3ebe;Соколова;Соколова;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524886801;3;1
80f55607-749b-11df-b112-00215aee3ebe;Соколова Балка;Соколова Балка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323486001;3;1
80f55608-749b-11df-b112-00215aee3ebe;Соколове;Соколово;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223285503;3;1
80f55609-749b-11df-b112-00215aee3ebe;Соколове;Соколово;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221084110;3;1
80f5560a-749b-11df-b112-00215aee3ebe;Соколове;Соколово;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121881407;3;1
80f5560b-749b-11df-b112-00215aee3ebe;Соколовиця;Соколовица;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624085004;3;1
80f5560d-749b-11df-b112-00215aee3ebe;Соколя;Соколя;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620685801;3;1
80f5560e-749b-11df-b112-00215aee3ebe;Соколя;Соколя;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622486401;3;1
80f5560f-749b-11df-b112-00215aee3ebe;Сокорики;Сокорики;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822380604;3;1
80f55610-749b-11df-b112-00215aee3ebe;Сокорове;Сокорово;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121681904;3;1
80f55611-749b-11df-b112-00215aee3ebe;Солгутове;Солгутово;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521184901;3;1
80f55612-749b-11df-b112-00215aee3ebe;Солдатове;Солдатово;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320484107;3;1
80f55613-749b-11df-b112-00215aee3ebe;Солдатське;Солдатское;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921284801;3;1
80f55614-749b-11df-b112-00215aee3ebe;Солдатське;Солдатское;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1220387707;3;1
80f55615-749b-11df-b112-00215aee3ebe;Солдатське;Солдатское;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822081207;3;1
80f55616-749b-11df-b112-00215aee3ebe;Солдатське;Солдатское;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520385002;3;1
80f55618-749b-11df-b112-00215aee3ebe;Солдатське;Солдатское;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523655105;3;1
80f55619-749b-11df-b112-00215aee3ebe;Солдатське;Солдатское;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524081806;3;1
80f5561a-749b-11df-b112-00215aee3ebe;Солдатське;Солдатское;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524686211;3;1
80f5561b-749b-11df-b112-00215aee3ebe;Солдатське;Солдатское;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524980803;3;1
80f5561d-749b-11df-b112-00215aee3ebe;Солиги;Солыги;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625856502;3;1
80f5561e-749b-11df-b112-00215aee3ebe;Солідарне;Солидарное;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924783806;3;1
80f55621-749b-11df-b112-00215aee3ebe;Солобківці;Солобковцы;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825887201;3;1
80f55625-749b-11df-b112-00215aee3ebe;Солов'ї;Соловьи;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725085501;3;1
80f55626-749b-11df-b112-00215aee3ebe;Солов'ї;Соловьи;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822880403;3;1
80f55627-749b-11df-b112-00215aee3ebe;Солов'їв;Соловьев;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423685803;3;1
80f55628-749b-11df-b112-00215aee3ebe;Солов'ївка;Соловьевка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221083308;3;1
80f5562b-749b-11df-b112-00215aee3ebe;Солов'ївка;Соловьевка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520384403;3;1
80f55622-749b-11df-b112-00215aee3ebe;Солова;Солова;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621883903;3;1
80f55623-749b-11df-b112-00215aee3ebe;Соловичі;Соловичи;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725586501;3;1
80f55624-749b-11df-b112-00215aee3ebe;Соловіївка;Соловеевка;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820985601;3;1
80f5562c-749b-11df-b112-00215aee3ebe;Соловйове;Соловьево;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923884809;3;1
80f5562e-749b-11df-b112-00215aee3ebe;Соловка;Соловка;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124885901;3;1
80f5562f-749b-11df-b112-00215aee3ebe;Сологубівка;Сологубовка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523185201;3;1
80f55630-749b-11df-b112-00215aee3ebe;Солодирі;Солодыри;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821181503;3;1
80f55636-749b-11df-b112-00215aee3ebe;Соломахівка;Соломаховка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324080107;3;1
80f55637-749b-11df-b112-00215aee3ebe;Соломашине;Соломашино;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922681708;3;1
80f55638-749b-11df-b112-00215aee3ebe;Соломир;Соломир;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622286604;3;1
80f55639-749b-11df-b112-00215aee3ebe;Соломіївка;Соломиевка;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621887701;3;1
80f5563a-749b-11df-b112-00215aee3ebe;Соломія;Соломия;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521185102;3;1
80f5563b-749b-11df-b112-00215aee3ebe;Соломка;Соломка;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623482003;3;1
80f5563c-749b-11df-b112-00215aee3ebe;Соломна;Соломна;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820987401;3;1
80f5563d-749b-11df-b112-00215aee3ebe;Соломоново;Соломоново;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124886201;3;0
80f5563e-749b-11df-b112-00215aee3ebe;Солона Балка;Соленая Балка;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321610102;3;1
80f5563f-749b-11df-b112-00215aee3ebe;Солоне;Соленое;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122087801;3;1
80f55640-749b-11df-b112-00215aee3ebe;Солоне;Соленое;місто;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055100;2;1
80f55641-749b-11df-b112-00215aee3ebe;Солоне;Соленое;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222685508;3;1
80f55642-749b-11df-b112-00215aee3ebe;Солоне;Соленое;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224287810;3;1
80f55643-749b-11df-b112-00215aee3ebe;Солоне;Соленое;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321587201;3;1
80f55646-749b-11df-b112-00215aee3ebe;Солоне;Соленое;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;;3;1
80f55649-749b-11df-b112-00215aee3ebe;Солоний Яр;Соленый Яр;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322057302;3;1
80f5564a-749b-11df-b112-00215aee3ebe;Солоницівка;Солоницевка;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322057600;2;0
80f5564b-749b-11df-b112-00215aee3ebe;Солониця;Солоница;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084901;3;1
80f5564c-749b-11df-b112-00215aee3ebe;Солониця;Солоница;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322886605;3;1
80f5564d-749b-11df-b112-00215aee3ebe;Солонів;Солонев;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625886503;3;1
80f5564e-749b-11df-b112-00215aee3ebe;Солонівка;Солоновка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421488001;3;1
80f5564f-749b-11df-b112-00215aee3ebe;Солонка;Солонка;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623686601;1;0
80f55650-749b-11df-b112-00215aee3ebe;Солонське;Солонское;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621287201;3;1
80f55652-749b-11df-b112-00215aee3ebe;Солонці;Солонцы;село;d00d3b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5310290315;3;1
80f55653-749b-11df-b112-00215aee3ebe;Солонці;Солонцы;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755138;3;1
80f55656-749b-11df-b112-00215aee3ebe;Солонці;Солонцы;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323287701;3;1
80f55657-749b-11df-b112-00215aee3ebe;Солонцюватка;Солонцеватка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520886901;3;1
80f55658-749b-11df-b112-00215aee3ebe;Солончаки;Солончаки;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825182210;3;1
80f55659-749b-11df-b112-00215aee3ebe;Солотвин;Солотвин;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155321;3;1
80f5565a-749b-11df-b112-00215aee3ebe;Солотвин;Солотвин;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620455500;3;1
80f5565c-749b-11df-b112-00215aee3ebe;Солотвине;Солотвино;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925383004;3;1
80f5565b-749b-11df-b112-00215aee3ebe;Солотвино;Солотвино;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124455900;2;0
80f5565e-749b-11df-b112-00215aee3ebe;Солочин;Солочин;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124085601;3;1
80f5565f-749b-11df-b112-00215aee3ebe;Солошине;Солошино;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5321881303;3;1
80f55660-749b-11df-b112-00215aee3ebe;Солтанівка;Солтановка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123381405;3;1
80f55661-749b-11df-b112-00215aee3ebe;Солтиси;Солтысы;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622482806;3;1
80f55662-749b-11df-b112-00215aee3ebe;Солуки;Солуки;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625882904;3;1
80f55663-749b-11df-b112-00215aee3ebe;Солуків;Солуков;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622086001;3;1
80f55665-749b-11df-b112-00215aee3ebe;Соляники;Соляники;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920683505;3;1
80f55666-749b-11df-b112-00215aee3ebe;Солянуватка;Соляноватка;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625184901;3;1
80f55667-749b-11df-b112-00215aee3ebe;Сомин;Сомин;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725586701;3;1
80f55668-749b-11df-b112-00215aee3ebe;Сомівка;Сомовка;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322284001;3;1
80f55669-749b-11df-b112-00215aee3ebe;Сомкова Долина;Сомкова Долина;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223386601;3;1
80f5566a-749b-11df-b112-00215aee3ebe;Сонине;Сонино;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520380403;3;1
80f5566b-749b-11df-b112-00215aee3ebe;Сонцеве;Солнцево;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923882810;3;1
80f5566c-749b-11df-b112-00215aee3ebe;Сонцеве;Солнцево;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223881006;3;1
80f5566e-749b-11df-b112-00215aee3ebe;Сонцеве;Солнцево;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525888001;3;1
80f5566f-749b-11df-b112-00215aee3ebe;Сонцедарівка;Солнцедаровка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323580310;3;1
f505b854-fcb5-11ea-80cc-000c29800ae7;Сонцедарівка;Сонцедаровка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323580305;3;1
cdc44f5b-dde8-11df-9197-00215aee3ebe;Сонячне;Солнечное;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120488003;3;1
80f55671-749b-11df-b112-00215aee3ebe;Сонячне;Солнечное;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323087205;3;1
80f55672-749b-11df-b112-00215aee3ebe;Сонячне;Солнечное;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920387801;3;1
80f55673-749b-11df-b112-00215aee3ebe;Сонячне;Солнечное;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124886502;3;1
80f55674-749b-11df-b112-00215aee3ebe;Сонячне;Солнечное;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322188801;1;0
80f55679-749b-11df-b112-00215aee3ebe;Сонячне;Солнечное;село;d00d3b86-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822083204;1;0
80f5567a-749b-11df-b112-00215aee3ebe;Сонячне;Солнечное;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0510490003;2;1
80f5567b-749b-11df-b112-00215aee3ebe;Сонячне;Солнечное;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521980807;3;1
80f5567c-749b-11df-b112-00215aee3ebe;Сонячне;Солнечное;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522586605;3;0
62c3d16d-749b-11df-b112-00215aee3ebe;Сонячне;Солнечное;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121081702;3;1
62c3d2a2-749b-11df-b112-00215aee3ebe;Сонячне (Лікнеп);Солнечное (Ликнеп);село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055113;3;1
80f55680-749b-11df-b112-00215aee3ebe;Сопачів;Сопачов;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620889101;3;1
80f55681-749b-11df-b112-00215aee3ebe;Сопин;Сопин;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523482803;3;1
80f55683-749b-11df-b112-00215aee3ebe;Сопич;Сопыч;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921586801;3;1
80f55684-749b-11df-b112-00215aee3ebe;Сопів;Сопов;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623255708;3;1
80f55685-749b-11df-b112-00215aee3ebe;Сопіт;Сопит;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;;3;1
80f55686-749b-11df-b112-00215aee3ebe;Сопіт;Сопот;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625888407;3;1
80f55687-749b-11df-b112-00215aee3ebe;Сопки;Сопки;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122483203;3;1
80f55688-749b-11df-b112-00215aee3ebe;Сопошин;Сопошин;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622788801;3;0
80f55689-749b-11df-b112-00215aee3ebe;Сорока;Сорока;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920981603;3;1
80f5568a-749b-11df-b112-00215aee3ebe;Сорока;Сорока;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121687201;3;1
80f5568b-749b-11df-b112-00215aee3ebe;Сорока;Сорока;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521210115;3;1
80f5568c-749b-11df-b112-00215aee3ebe;Сороки;Сороки;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121287001;3;1
80f5568d-749b-11df-b112-00215aee3ebe;Сороки;Сороки;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621685401;3;1
80f5568e-749b-11df-b112-00215aee3ebe;Сороки;Сороки;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783909;3;1
80f5568f-749b-11df-b112-00215aee3ebe;Сороки;Сороки;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623684607;3;1
80f55690-749b-11df-b112-00215aee3ebe;Сороки-Львівські;Сороки-Львовские;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4623686901;2;0
80f55691-749b-11df-b112-00215aee3ebe;Сорокине;Сорокино;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924410106;3;1
80f55692-749b-11df-b112-00215aee3ebe;Сороківка;Сороковка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;;3;1
80f55693-749b-11df-b112-00215aee3ebe;Сороківка;Сороковка;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325184005;3;1
8bc352d7-102b-11e1-9215-003048d2b473;Сороківка;Сороковка;;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
3afd4bf0-43f3-11e4-81a4-003048d2b473;Сорокове;Сроковое;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323584707;3;1
80f55694-749b-11df-b112-00215aee3ebe;Сороковий Клин;Сороковый Клин;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925680407;3;1
80f55695-749b-11df-b112-00215aee3ebe;Сорокодуби;Сорокодубы;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523082803;3;1
80f55696-749b-11df-b112-00215aee3ebe;Сорокодуби;Сорокодубы;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822789206;3;1
80f55697-749b-11df-b112-00215aee3ebe;Сороколітове;Сорокалетово;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923581707;3;1
80f55698-749b-11df-b112-00215aee3ebe;Сорокопанівка;Сорокопановка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055719;3;1
80f55699-749b-11df-b112-00215aee3ebe;Сорокопень;Сорокопень;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824285205;3;1
80f5569a-749b-11df-b112-00215aee3ebe;Сорокотяга;Сорокотяга;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120987701;3;1
80f5569b-749b-11df-b112-00215aee3ebe;Сорокотяги;Сорокотяги;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220486201;3;1
80f5569c-749b-11df-b112-00215aee3ebe;Сорокотяжинці;Сорокотяжинцы;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523084404;3;1
80f5569d-749b-11df-b112-00215aee3ebe;Сорокошичі;Сорокошичи;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422083904;3;1
80f5569e-749b-11df-b112-00215aee3ebe;Сороцьке;Сороцкое;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125087801;3;1
80f5569f-749b-11df-b112-00215aee3ebe;Сорочанове;Сорочаново;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520884803;3;1
80f556a0-749b-11df-b112-00215aee3ebe;Сорочень;Сорочень;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821781404;3;1
80f556a1-749b-11df-b112-00215aee3ebe;Сорочине;Сорочино;село;bad3ac3c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1223783807;3;1
80f556a2-749b-11df-b112-00215aee3ebe;Сорочине;Сорочино;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222983409;3;1
80f556a3-749b-11df-b112-00215aee3ebe;Сорочине;Сорочино;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323687205;3;1
80f556a4-749b-11df-b112-00215aee3ebe;Сорочинка;Сорочинка;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823984202;3;1
80f556a5-749b-11df-b112-00215aee3ebe;Сорочинське;Сорочинское;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920986004;3;1
80f556a6-749b-11df-b112-00215aee3ebe;Сорочі Лози;Сорочьи Лозы;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783015;3;1
80f556a7-749b-11df-b112-00215aee3ebe;Сосни;Сосны;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522486401;3;1
80f556a8-749b-11df-b112-00215aee3ebe;Соснина;Соснына;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721155108;3;1
80f556a9-749b-11df-b112-00215aee3ebe;Соснина;Соснына;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785508;3;1
80f556aa-749b-11df-b112-00215aee3ebe;Сосниця;Сосница;місто;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424955100;2;1
80f556ab-749b-11df-b112-00215aee3ebe;Соснів;Соснов;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125088001;3;1
80f556ac-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325786005;3;1
80f556ad-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081911;3;1
80f556ae-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321884204;3;1
80f556af-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322484404;3;1
80f556b0-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921587101;3;1
80f556b1-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922087801;3;1
80f556b2-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721486205;3;1
80f556b3-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125887201;3;1
80f556b4-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224283407;3;1
80f556b5-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322685504;3;1
80f556b6-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321280307;3;1
80f556b7-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323386001;3;1
80f556b9-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;місто;bad3ac26-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4611870400;2;0
80f556ba-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623688204;3;1
80f556bb-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625180907;3;1
80f556bd-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222086404;3;1
80f556be-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222787701;3;1
80f556bf-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824487204;3;1
80f556c0-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823480813;3;1
80f556c1-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822086204;3;1
80f556c2-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422482004;3;1
80f556c3-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424987503;3;1
80f556c4-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523488203;3;1
80f556c5-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;*052531010;3;1
80f556c6-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524355603;3;1
80f556c7-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520586501;3;1
80f556c8-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823980304;3;1
80f556c9-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820384503;3;1
80f556ca-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825887601;3;1
80f556cb-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821888002;3;1
80f556cc-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621688201;3;1
80f556cd-749b-11df-b112-00215aee3ebe;Соснівка;Сосновка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320487801;3;1
56bdd22b-749b-11df-b112-00215aee3ebe;Соснівка (Жовтневе);Соснивка (Жовтневое);село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923583405;3;1
80f556ce-749b-11df-b112-00215aee3ebe;Соснівочка;Сосновочка;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820384504;3;1
80f556d0-749b-11df-b112-00215aee3ebe;Соснова;Соснова;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223386801;3;1
80f556d1-749b-11df-b112-00215aee3ebe;Соснова Поляна;Сосновая Поляна;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;*182501010;3;1
80f556d3-749b-11df-b112-00215aee3ebe;Соснове;Сосновое;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620455700;2;1
80f556d6-749b-11df-b112-00215aee3ebe;Сосонівка;Сосоновка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324284701;3;1
80f556d7-749b-11df-b112-00215aee3ebe;Сосонка;Сосонка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920388006;3;1
80f556d8-749b-11df-b112-00215aee3ebe;Сосонка;Сосонка;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520687003;3;1
80f556d9-749b-11df-b112-00215aee3ebe;Сосулівка;Сосулевка;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125587501;3;1
80f556da-749b-11df-b112-00215aee3ebe;Сотники;Сотники;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122588601;3;1
80f556db-749b-11df-b112-00215aee3ebe;Сотниківка;Сотниковка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225586501;3;1
80f556dc-749b-11df-b112-00215aee3ebe;Сотницька Балка;Сотницкая Балка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524010109;3;1
80f556dd-749b-11df-b112-00215aee3ebe;Сотницьке;Сотницкое;село;bad3ac3c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1223755105;3;1
80f556de-749b-11df-b112-00215aee3ebe;Сотницьке;Сотницкое;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523482405;3;1
80f556df-749b-11df-b112-00215aee3ebe;Сотницьке;Сотницкое;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323255702;3;1
56bdd273-749b-11df-b112-00215aee3ebe;Сотницьке (Жовтневе);Сотницкое (Жовтневое);село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320886603;3;1
80f556e0-749b-11df-b112-00215aee3ebe;Сотницький Козачок;Сотницкий Казачок;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322682004;3;1
80f556e1-749b-11df-b112-00215aee3ebe;Софине;Софино;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324880311;3;1
80f556e2-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922981207;3;1
80f556e3-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786912;3;1
80f556e4-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920355507;3;1
80f556e5-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720885007;3;1
80f556e6-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;місто;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225255100;3;1
80f556e7-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825486701;3;1
80f556e8-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824585001;3;1
80f556e9-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
80f556ea-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323184807;3;1
80f556eb-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320680506;3;1
80f556ec-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125483607;3;1
80f556ed-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123555104;3;1
80f556ee-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121282105;3;1
80f556ef-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120887710;3;1
80f556f0-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323685901;3;1
80f556f4-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520388602;3;1
80f556fc-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221981303;3;1
80f556fd-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224955601;3;1
80f556fe-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425887001;3;1
80f556ff-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421755703;3;1
80f55700-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423885501;3;1
80f55701-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124986501;3;1
80f55702-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121589201;3;1
80f55703-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;;3;1
80f55704-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524887203;3;1
80f55705-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521686806;3;1
80f55706-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524082405;3;1
80f55707-749b-11df-b112-00215aee3ebe;Софіївка;Софиевка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522885401;3;1
44ac8849-749b-11df-b112-00215aee3ebe;Софіївка (Валове);Софиевка (Валовое);село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221880501;3;1
80f55708-749b-11df-b112-00215aee3ebe;Софіївка Друга;Софиевка Вторая;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621681207;3;1
80f55709-749b-11df-b112-00215aee3ebe;Софіївка Перша;Софиевка Первая;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320686301;3;1
80f5570a-749b-11df-b112-00215aee3ebe;Софіївка Перша;Софиевка Первая;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621681206;3;1
80f5570b-749b-11df-b112-00215aee3ebe;Софіївська Борщагівка;Софиевская Борщаговка;місто;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222486201;1;0
80f5570e-749b-11df-b112-00215aee3ebe;Софійка;Софийка;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220683206;3;1
80f5570f-749b-11df-b112-00215aee3ebe;Софіпіль;Софиполь;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224681202;3;1
80f55710-749b-11df-b112-00215aee3ebe;Софія;София;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122783002;3;1
80f55711-749b-11df-b112-00215aee3ebe;Софіянівка;Софияновка;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723687902;3;1
80f55712-749b-11df-b112-00215aee3ebe;Сохани;Соханы;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920610104;3;1
80f55713-749b-11df-b112-00215aee3ebe;Сохацька Балка;Сохацкая Балка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321080406;3;1
80f55714-749b-11df-b112-00215aee3ebe;Сохацьке;Сохацкое;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323283410;3;1
80f55715-749b-11df-b112-00215aee3ebe;Сохачі;Сохачи;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422288401;3;1
80f55716-749b-11df-b112-00215aee3ebe;Сохужинці;Сохужинцы;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822187502;3;1
80f55717-749b-11df-b112-00215aee3ebe;Соцзмагання;Соцзмагання;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6312090003;3;1
80f55718-749b-11df-b112-00215aee3ebe;Сошанське;Сошанское;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521482412;3;1
80f55719-749b-11df-b112-00215aee3ebe;Соше-Острівське;Соше-Островское;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121685201;3;1
80f5571a-749b-11df-b112-00215aee3ebe;Сошичне;Сошичное;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721486901;3;1
80f5571b-749b-11df-b112-00215aee3ebe;Сошище;Сошище;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125884805;3;1
80f5571c-749b-11df-b112-00215aee3ebe;Сошки;Сошки;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825580503;3;1
80f5571d-749b-11df-b112-00215aee3ebe;Сошне;Сошное;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822186901;3;1
80f5571e-749b-11df-b112-00215aee3ebe;Сошники;Сошники;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620888004;3;1
80f5571f-749b-11df-b112-00215aee3ebe;Сошників;Сошников;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220887001;3;1
80f55720-749b-11df-b112-00215aee3ebe;Спадщина;Спадщина;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923883819;3;1
80f55721-749b-11df-b112-00215aee3ebe;Спартак;Спартак;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923586706;3;1
80f55723-749b-11df-b112-00215aee3ebe;Спартаси;Спартасы;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157607;3;1
80f55724-749b-11df-b112-00215aee3ebe;Спас;Спас;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624885501;3;1
80f55725-749b-11df-b112-00215aee3ebe;Спас;Спас;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623287001;3;1
80f55726-749b-11df-b112-00215aee3ebe;Спас;Спас;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622186603;3;1
80f55727-749b-11df-b112-00215aee3ebe;Спас;Спас;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625186603;3;1
80f55728-749b-11df-b112-00215aee3ebe;Спасів;Спасов;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624884402;3;1
80f55729-749b-11df-b112-00215aee3ebe;Спасів;Спасов;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622685601;3;1
80f5572a-749b-11df-b112-00215aee3ebe;Спасівка;Спасовка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321586112;3;1
80f5572c-749b-11df-b112-00215aee3ebe;Спасівка;Спасовка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821282902;3;1
80f5572d-749b-11df-b112-00215aee3ebe;Спасове;Спасово;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523485801;3;1
80f5572e-749b-11df-b112-00215aee3ebe;Спасо-Мажарівка;Спасо-Мажаровка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522287406;3;1
80f5572f-749b-11df-b112-00215aee3ebe;Спаська;Спасская;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324586504;3;1
80f55730-749b-11df-b112-00215aee3ebe;Спаське;Спасское;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924785404;3;1
80f55731-749b-11df-b112-00215aee3ebe;Спаське;Спасское;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223286501;3;0
80f55732-749b-11df-b112-00215aee3ebe;Спаське;Спасское;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125084703;2;1
80f55734-749b-11df-b112-00215aee3ebe;Спаське;Спасское;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821784205;3;1
80f55735-749b-11df-b112-00215aee3ebe;Спаське;Спасское;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424987001;3;1
62c3d14f-749b-11df-b112-00215aee3ebe;Спаське;Спаское;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922685101;3;1
7ad954c0-749b-11df-b112-00215aee3ebe;Спаське (Свердловське);Спасское (Свердловское);село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225855114;3;1
80f55737-749b-11df-b112-00215aee3ebe;Спичинці;Спичинцы;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523486401;3;1
80f55738-749b-11df-b112-00215aee3ebe;Спичувате;Спичеватое;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422485505;3;1
871626ce-749b-11df-b112-00215aee3ebe;Співаківка;Співаківка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322883009;3;1
80f5573a-749b-11df-b112-00215aee3ebe;Співакове;Спиваково;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425385007;3;1
80f5573b-749b-11df-b112-00215aee3ebe;Спільне;Спильное;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523483903;3;1
80f5573c-749b-11df-b112-00215aee3ebe;Спірне;Спорное;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921286303;3;1
80f5573e-749b-11df-b112-00215aee3ebe;Спірне;Спорное;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825084903;3;1
80f5573f-749b-11df-b112-00215aee3ebe;Сподахи;Сподахи;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523084007;3;1
80f55740-749b-11df-b112-00215aee3ebe;Сподобівка;Сподобовка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325786001;3;1
80f55742-749b-11df-b112-00215aee3ebe;Спокойствіє;Спокойствие;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282521;3;1
80f55743-749b-11df-b112-00215aee3ebe;Сприня;Спрыня;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;;3;1
80f55744-749b-11df-b112-00215aee3ebe;Сприсівка;Сприсовка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821885003;3;1
80f55746-749b-11df-b112-00215aee3ebe;Срібне;Срибное;місто;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;7425155100;3;1
80f55747-749b-11df-b112-00215aee3ebe;Срібне;Срибное;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625884806;3;1
80f55748-749b-11df-b112-00215aee3ebe;Срібровщина;Срибровщина;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922681709;3;1
80f55749-749b-11df-b112-00215aee3ebe;Ставецьке;Ставецкое;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822087604;3;1
80f5574a-749b-11df-b112-00215aee3ebe;Стави;Ставы;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222287201;3;1
80f5574b-749b-11df-b112-00215aee3ebe;Ставидла;Ставидла;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520587001;3;1
80f5574c-749b-11df-b112-00215aee3ebe;Ставиське;Стависское;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422089101;3;1
a5388ebd-db65-11df-9197-00215aee3ebe;Ставичани;Ставичаны;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823987101;3;1
80f5574d-749b-11df-b112-00215aee3ebe;Ставищани;Ставищаны;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820388001;3;1
80f5574e-749b-11df-b112-00215aee3ebe;Ставище;Ставище;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721484602;3;1
80f5574f-749b-11df-b112-00215aee3ebe;Ставище;Ставище;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325785808;3;1
80f55750-749b-11df-b112-00215aee3ebe;Ставище;Ставище;місто;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224255100;3;1
80f55751-749b-11df-b112-00215aee3ebe;Ставище;Ставище;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822385401;3;1
80f55752-749b-11df-b112-00215aee3ebe;Ставище;Ставище;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820986001;3;1
80f55753-749b-11df-b112-00215aee3ebe;Ставище;Ставище;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824786501;3;1
80f55754-749b-11df-b112-00215aee3ebe;Ставище;Ставище;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821888401;3;1
80f55755-749b-11df-b112-00215aee3ebe;Ставище;Ставище;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623889207;3;1
80f55756-749b-11df-b112-00215aee3ebe;Ставки;Ставки;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725583903;3;1
80f55757-749b-11df-b112-00215aee3ebe;Ставки;Ставки;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121683303;3;1
80f55758-749b-11df-b112-00215aee3ebe;Ставки;Ставки;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122080703;3;1
80f55759-749b-11df-b112-00215aee3ebe;Ставки;Ставки;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821785001;3;1
80f5575a-749b-11df-b112-00215aee3ebe;Ставки;Ставки;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122782203;3;1
80f5575e-749b-11df-b112-00215aee3ebe;Ставки;Ставки;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224985902;3;1
80f5575f-749b-11df-b112-00215aee3ebe;Ставки;Ставки;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821182005;3;1
80f55760-749b-11df-b112-00215aee3ebe;Ставки;Ставки;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520484803;3;1
80f55761-749b-11df-b112-00215aee3ebe;Ставки;Ставки;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523282801;3;1
80f55762-749b-11df-b112-00215aee3ebe;Ставки;Ставки;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624687402;3;1
80f55763-749b-11df-b112-00215aee3ebe;Ставки;Ставки;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324887719;3;1
0d0061fc-3a67-11e9-80dd-1c98ec135261;Ставки;Ставки;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625885206;3;1
871625a0-749b-11df-b112-00215aee3ebe;Ставки (Чапаєвка);Ставки (Чапаевка);село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122987401;3;1
7ad953a6-749b-11df-b112-00215aee3ebe;Ставківка;Ставковка;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;;3;1
80f55764-749b-11df-b112-00215aee3ebe;Ставкова Балка;Ставковая Балка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320483505;3;1
80f55765-749b-11df-b112-00215aee3ebe;Ставкове;Ставковое;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321386001;3;1
80f55766-749b-11df-b112-00215aee3ebe;Ставкове;Ставковое;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283613;3;1
4aba3f08-749b-11df-b112-00215aee3ebe;Ставкове (Воровського);Ставкове (Воровского);село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123555101;3;1
80f55767-749b-11df-b112-00215aee3ebe;Ставне;Ставное;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120885201;3;1
80f55768-749b-11df-b112-00215aee3ebe;Ставники;Ставники;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622183305;3;1
80f55769-749b-11df-b112-00215aee3ebe;Ставниця;Ставница;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823055401;3;1
80f5576a-749b-11df-b112-00215aee3ebe;Ставок;Ставок;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725555108;3;1
80f5576b-749b-11df-b112-00215aee3ebe;Ставок;Ставок;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721882005;3;1
80f5576c-749b-11df-b112-00215aee3ebe;Ставок;Ставок;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423887006;3;1
80f5576d-749b-11df-b112-00215aee3ebe;Ставок;Ставок;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820381002;3;1
80f5576e-749b-11df-b112-00215aee3ebe;Ставок;Ставок;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623482603;3;1
80f5576f-749b-11df-b112-00215aee3ebe;Ставрів;Ставров;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623886704;3;1
80f55770-749b-11df-b112-00215aee3ebe;Ставрівка;Ставровка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222084204;3;1
80f55771-749b-11df-b112-00215aee3ebe;Ставрове;Ставрово;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123183701;3;1
80f55772-749b-11df-b112-00215aee3ebe;Став-Слобода;Став-Слобода;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825084803;3;1
80f55773-749b-11df-b112-00215aee3ebe;Ставчани;Ставчаны;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322588001;3;1
80f55774-749b-11df-b112-00215aee3ebe;Ставчани;Ставчаны;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325088801;3;1
80f55775-749b-11df-b112-00215aee3ebe;Ставчани;Ставчаны;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623687001;3;1
80f55776-749b-11df-b112-00215aee3ebe;Ставчани;Ставчаны;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823389001;3;1
80f55777-749b-11df-b112-00215aee3ebe;Ставчинці;Ставчинцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825088101;3;1
80f55778-749b-11df-b112-00215aee3ebe;Стадники;Стадники;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625856503;3;1
80f55779-749b-11df-b112-00215aee3ebe;Стадники;Стадники;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624286404;3;1
80f5577a-749b-11df-b112-00215aee3ebe;Стадниця;Стадница;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322281507;3;1
80f5577b-749b-11df-b112-00215aee3ebe;Стадниця;Стадница;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224610114;3;1
80f5577c-749b-11df-b112-00215aee3ebe;Стадниця;Стадница;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120387502;3;1
80f5577d-749b-11df-b112-00215aee3ebe;Стадниця;Стадница;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520687303;3;1
80f5577e-749b-11df-b112-00215aee3ebe;Стадниця;Стадница;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
80f5577f-749b-11df-b112-00215aee3ebe;Стадня;Стадня;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621886703;3;1
80f55780-749b-11df-b112-00215aee3ebe;Стадня;Стадня;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322885307;3;1
80f55781-749b-11df-b112-00215aee3ebe;Стаївка;Стаевка;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624882802;3;1
80f55782-749b-11df-b112-00215aee3ebe;Стаїще;Стаище;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620885003;3;1
80f55783-749b-11df-b112-00215aee3ebe;Стайки;Стайки;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222287601;3;1
80f55784-749b-11df-b112-00215aee3ebe;Стайки;Стайки;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324885411;3;1
80f55787-749b-11df-b112-00215aee3ebe;Стальнівці;Стальновцы;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323087501;3;1
80f55788-749b-11df-b112-00215aee3ebe;Станилівка;Станиловка;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523486801;3;1
e0517ec7-db89-11df-9197-00215aee3ebe;Станиля;Станыля;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621287401;3;1
80f55789-749b-11df-b112-00215aee3ebe;Станимир;Станимир;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623386801;3;1
80f5578a-749b-11df-b112-00215aee3ebe;Станин;Станин;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623985701;3;1
80f5578b-749b-11df-b112-00215aee3ebe;Станичне;Станичное;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921280504;3;1
80f5578c-749b-11df-b112-00215aee3ebe;Станичне;Станичное;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324285001;3;1
80f5578e-749b-11df-b112-00215aee3ebe;Станишівка;Станишовка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222082702;3;1
80f5578f-749b-11df-b112-00215aee3ebe;Станишівка;Станишовка;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224486901;3;1
80f55790-749b-11df-b112-00215aee3ebe;Станишівка;Станишовка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822086801;1;0
80f55791-749b-11df-b112-00215aee3ebe;Станівці;Становцы;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321086001;3;1
80f55792-749b-11df-b112-00215aee3ebe;Станіслав;Станислав;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520386501;3;1
80f55793-749b-11df-b112-00215aee3ebe;Станіслави;Станиславы;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625882103;3;1
80f55794-749b-11df-b112-00215aee3ebe;Станіславівка;Станиславовка;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623286202;3;1
80f55795-749b-11df-b112-00215aee3ebe;Станіславівка;Станиславовка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821482704;3;1
80f55796-749b-11df-b112-00215aee3ebe;Станіславівка;Станиславовка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523683602;3;1
80f55797-749b-11df-b112-00215aee3ebe;Станіславівка;Станиславовка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822480702;3;1
80f55798-749b-11df-b112-00215aee3ebe;Станіславівка;Станиславовка;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820683503;3;1
80f55799-749b-11df-b112-00215aee3ebe;Станіславка;Станиславка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325781505;3;1
80f5579a-749b-11df-b112-00215aee3ebe;Станіславка;Станиславка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122986401;3;1
80f5579b-749b-11df-b112-00215aee3ebe;Станіславка;Станиславка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524387202;3;1
80f5579c-749b-11df-b112-00215aee3ebe;Станіславове;Станиславово;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525581302;3;1
80f5579d-749b-11df-b112-00215aee3ebe;Станіславчик;Станиславчик;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825480402;3;1
80f5579e-749b-11df-b112-00215aee3ebe;Станіславчик;Станиславчик;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620386601;3;1
80f5579f-749b-11df-b112-00215aee3ebe;Станіславчик;Станиславчик;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224286401;3;1
80f557a0-749b-11df-b112-00215aee3ebe;Станіславчик;Станиславчик;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124986505;3;1
80f557a1-749b-11df-b112-00215aee3ebe;Станіславчик;Станиславчик;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125787601;3;1
80f557a2-749b-11df-b112-00215aee3ebe;Станіславчик;Станиславчик;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521085803;3;1
80f557a3-749b-11df-b112-00215aee3ebe;Станків;Станков;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625386401;3;1
80f557a4-749b-11df-b112-00215aee3ebe;Станківці;Станковцы;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622080508;3;1
80f557a5-749b-11df-b112-00215aee3ebe;Станківці;Станковцы;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623087401;3;1
80f557a7-749b-11df-b112-00215aee3ebe;Станкувате;Станковатое;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524383501;3;1
80f557a8-749b-11df-b112-00215aee3ebe;Станова;Становая;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925087801;3;1
80f557aa-749b-11df-b112-00215aee3ebe;Станове;Становое;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422288405;3;1
80f557ab-749b-11df-b112-00215aee3ebe;Становець;Становец;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125382804;3;1
80f557a9-749b-11df-b112-00215aee3ebe;Станово;Становое;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122787201;3;1
80f557ad-749b-11df-b112-00215aee3ebe;Станційне;Станционное;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822855104;3;1
60030397-108c-11e8-80ce-1c98ec135261;Станційне;Станционное;місто;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824510102;3;1
80f557ae-749b-11df-b112-00215aee3ebe;Станція Генріховка;Станция Генриховка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;;3;1
80f557af-749b-11df-b112-00215aee3ebe;Станція Дашів;Станция Дашев;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
871626d1-749b-11df-b112-00215aee3ebe;Станція Лосинівська (Червоний ;Станція Лосинівська (Червоний Шлях);село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423387405;3;1
80f557b0-749b-11df-b112-00215aee3ebe;Станція Незабудине;Станция Незабудино;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225084007;3;1
80f557b1-749b-11df-b112-00215aee3ebe;Станція Устя;Станция Устье;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;;3;1
80f557b2-749b-11df-b112-00215aee3ebe;Станція Яланець;Станция Яланец;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;;3;1
80f557a6-749b-11df-b112-00215aee3ebe;Станькова;Станькова;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622880811;3;1
80f557bf-749b-11df-b112-00215aee3ebe;Стара Бандурка;Старая Бандурка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825487602;3;1
80f557c0-749b-11df-b112-00215aee3ebe;Стара Басань;Старая Басань;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420687601;3;1
80f557c1-749b-11df-b112-00215aee3ebe;Стара Білецьківка;Старая Белецковка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322480406;3;1
80f557c2-749b-11df-b112-00215aee3ebe;Стара Богданівка;Старая Богдановка;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824280702;3;1
80f557c3-749b-11df-b112-00215aee3ebe;Стара Брикуля;Старая Брикуля;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125089203;3;1
80f557c4-749b-11df-b112-00215aee3ebe;Стара Буда;Старая Буда;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221083004;3;1
80f557c5-749b-11df-b112-00215aee3ebe;Стара Буда;Старая Буда;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825082207;3;1
80f557c6-749b-11df-b112-00215aee3ebe;Стара Буда;Старая Буда;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121283603;3;1
80f557b3-749b-11df-b112-00215aee3ebe;Стара Вижівка;Старая Выжевка;місто;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725055100;3;1
80f557c7-749b-11df-b112-00215aee3ebe;Стара Водолага;Старая Водолага;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324286001;3;1
80f557c8-749b-11df-b112-00215aee3ebe;Стара Гнилиця;Старая Гнилица;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325486501;3;1
cdc44f57-dde8-11df-9197-00215aee3ebe;Стара Гребля;Старая Гребля;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825087205;3;1
80f557c9-749b-11df-b112-00215aee3ebe;Стара Гута;Старая Гута;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924486301;3;1
80f557ca-749b-11df-b112-00215aee3ebe;Стара Гута;Старая Гута;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;;3;1
80f557cb-749b-11df-b112-00215aee3ebe;Стара Гута;Старая Гута;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620482002;3;1
80f557cc-749b-11df-b112-00215aee3ebe;Стара Гута;Старая Гута;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821785004;3;1
80f557cd-749b-11df-b112-00215aee3ebe;Стара Гута;Старая Гута;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823485509;3;1
80f557ce-749b-11df-b112-00215aee3ebe;Стара Гута;Старая Гута;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820683603;3;1
80f557cf-749b-11df-b112-00215aee3ebe;Стара Гута;Старая Гута;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524881502;3;1
80f557d0-749b-11df-b112-00215aee3ebe;Стара Гута;Старая Гута;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821586403;3;1
80f557d1-749b-11df-b112-00215aee3ebe;Стара Гута;Старая Гута;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821888501;3;1
80f557b4-749b-11df-b112-00215aee3ebe;Стара Гута;Старая Гута;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725085801;3;1
80f557b5-749b-11df-b112-00215aee3ebe;Стара Гута;Старая Гута;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823389004;3;1
80f557d2-749b-11df-b112-00215aee3ebe;Стара Гутиська;Старая Гутиска;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822188703;3;1
80f557d3-749b-11df-b112-00215aee3ebe;Стара Гутка;Старая Гутка;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424786001;3;1
80f557d4-749b-11df-b112-00215aee3ebe;Стара Еметівка;Старая Эметовка;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121083807;3;1
80f557d5-749b-11df-b112-00215aee3ebe;Стара Єлизаветівка;Старая Елизаветовка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125483608;3;1
80f557d6-749b-11df-b112-00215aee3ebe;Стара Жадова;Старая Жадова;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324589501;3;1
80f557d7-749b-11df-b112-00215aee3ebe;Стара іванівка;Старая Ивановка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920388001;3;1
80f557d8-749b-11df-b112-00215aee3ebe;Стара Котельня;Старая Котельня;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820388301;3;1
80f557d9-749b-11df-b112-00215aee3ebe;Стара Красношора;Старая Красношора;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324589601;3;1
80f557db-749b-11df-b112-00215aee3ebe;Стара Кульна;Старая Кульна;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122986901;3;1
80f557dc-749b-11df-b112-00215aee3ebe;Стара Лішня;Старая Лешня;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721187701;3;1
80f557dd-749b-11df-b112-00215aee3ebe;Стара Миколаївка;Старая Николаевка;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621689511;3;1
80f557de-749b-11df-b112-00215aee3ebe;Стара Михайлівка;Старая Михайловка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321385004;3;1
80f557df-749b-11df-b112-00215aee3ebe;Стара Могильниця;Старая Могильница;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125088903;3;1
80f557e0-749b-11df-b112-00215aee3ebe;Стара Московка;Старая Московка;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157002;3;1
80f557e1-749b-11df-b112-00215aee3ebe;Стара Мощаниця;Старая Мощаница;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622685801;3;1
80f557e2-749b-11df-b112-00215aee3ebe;Стара Некрасівка;Старая Некрасовка;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122086301;3;1
80f557e3-749b-11df-b112-00215aee3ebe;Стара Носовиця;Старая Носовица;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621686013;3;1
80f557e4-749b-11df-b112-00215aee3ebe;Стара Олександрівка;Старая Александровка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825486204;3;1
80f557e5-749b-11df-b112-00215aee3ebe;Стара Оржиця;Старая Оржица;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221985801;3;1
80f557e6-749b-11df-b112-00215aee3ebe;Стара Осота;Старая Осота;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520587501;3;1
80f557b8-749b-11df-b112-00215aee3ebe;Стара Пісочна;Старая Песочная;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821287801;3;1
80f557e7-749b-11df-b112-00215aee3ebe;Стара Покровка;Старая Покровка;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325487001;3;1
80f557b9-749b-11df-b112-00215aee3ebe;Стара Прилука;Старая Прилука;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522286601;3;1
80f557e8-749b-11df-b112-00215aee3ebe;Стара Радча;Старая Радча;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755157;3;1
80f557ba-749b-11df-b112-00215aee3ebe;Стара Рафалівка;Старая Рафаловка;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620889301;3;1
80f557e9-749b-11df-b112-00215aee3ebe;Стара Ропа;Старая Ропа;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625155701;3;1
80f557ea-749b-11df-b112-00215aee3ebe;Стара Рудня;Старая Рудня;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825485105;3;1
80f557eb-749b-11df-b112-00215aee3ebe;Стара Рудня;Старая Рудня;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425888001;3;1
80f557ec-749b-11df-b112-00215aee3ebe;Стара Семенівка;Старая Семеновка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320484002;3;1
80f557bb-749b-11df-b112-00215aee3ebe;Стара Синява;Старая Синява;місто;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824455100;3;1
80f557bc-749b-11df-b112-00215aee3ebe;Стара Сіль;Старая Соль;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625155700;3;1
80f557ed-749b-11df-b112-00215aee3ebe;Стара Скварява;Старая Скварява;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622789001;3;1
7ad9567b-749b-11df-b112-00215aee3ebe;Стара Талалаївка (Сильченкове);Стара Талалаївка (Сильченкове);село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425383501;3;1
80f557bd-749b-11df-b112-00215aee3ebe;Стара Ушиця;Старая Ушица;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822455800;3;1
80f557ee-749b-11df-b112-00215aee3ebe;Стара Царичанка;Старая Царичанка;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120887201;3;1
80f557ef-749b-11df-b112-00215aee3ebe;Стара Чортория;Старая Чертория;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823186401;3;1
80f557f0-749b-11df-b112-00215aee3ebe;Стара Шарпівка;Старая Шарповка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923888405;3;1
80f557be-749b-11df-b112-00215aee3ebe;Стара Ягільниця;Старая Ягильница;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125587601;3;1
80f557f1-749b-11df-b112-00215aee3ebe;Старгород;Старгород;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624887804;3;1
80f557f2-749b-11df-b112-00215aee3ebe;Старе;Старое;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221984205;3;1
80f557f3-749b-11df-b112-00215aee3ebe;Старе;Старое;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220887601;3;1
80f557f6-749b-11df-b112-00215aee3ebe;Старе Давидково;Старое Давыдково;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122783203;3;1
80f557f7-749b-11df-b112-00215aee3ebe;Старе Мажарове;Старое Мажарово;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322283007;3;1
80f557f8-749b-11df-b112-00215aee3ebe;Старе Місто;Старый Город;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124887301;3;1
80f557f9-749b-11df-b112-00215aee3ebe;Старе Поріччя;Старое Поречье;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821285503;3;1
80f557fa-749b-11df-b112-00215aee3ebe;Старе Село;Старое Село;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785217;3;1
80f557fb-749b-11df-b112-00215aee3ebe;Старе Село;Старое Село;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621585304;3;1
80f557fc-749b-11df-b112-00215aee3ebe;Старе Село;Старое Село;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621286805;3;1
80f557f4-749b-11df-b112-00215aee3ebe;Старе Село;Старое Село;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623687101;3;1
80f557f5-749b-11df-b112-00215aee3ebe;Старе Село;Старое Село;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625086801;3;1
8716264b-749b-11df-b112-00215aee3ebe;Старе Село (Червоне);Старое Село (Червоное);село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924788701;2;1
80f557ff-749b-11df-b112-00215aee3ebe;Старий Биків;Старый Быков;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420685503;3;1
80f55800-749b-11df-b112-00215aee3ebe;Старий Білоус;Старый Белоус;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425588601;3;0
80f55801-749b-11df-b112-00215aee3ebe;Старий Бобрик;Старый Бобрик;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821187603;3;1
80f55802-749b-11df-b112-00215aee3ebe;Старий Вишнівець;Старый Вишневец;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122488101;3;1
80f55803-749b-11df-b112-00215aee3ebe;Старий Вовчинець;Старый Волчинец;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321086301;3;1
80f55804-749b-11df-b112-00215aee3ebe;Старий Гвіздець;Старый Гвоздец;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623286701;3;1
80f55805-749b-11df-b112-00215aee3ebe;Старий Добротвір;Старый Добротвор;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622185901;3;1
80f55806-749b-11df-b112-00215aee3ebe;Старий Дорогинь;Старый Дорогинь;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755158;3;1
80f55807-749b-11df-b112-00215aee3ebe;Старий Загорів;Старый Загоров;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722485201;3;1
80f55808-749b-11df-b112-00215aee3ebe;Старий Збараж;Старый Збараж;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122488201;3;1
80f557fd-749b-11df-b112-00215aee3ebe;Старий Іржавець;Старый Иржавец;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323686401;3;1
80f55809-749b-11df-b112-00215aee3ebe;Старий Калкаїв;Старый Калкаев;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324581504;3;1
80f5580b-749b-11df-b112-00215aee3ebe;Старий Коврай;Старый Коврай;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125188801;3;1
80f5580c-749b-11df-b112-00215aee3ebe;Старий Кокорів;Старый Кокорев;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123485905;3;1
80f5580d-749b-11df-b112-00215aee3ebe;Старий Колодязь;Старый Колодец;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223887104;3;1
80f5580e-749b-11df-b112-00215aee3ebe;Старий Корець;Старый Корец;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623082202;3;1
80f5580f-749b-11df-b112-00215aee3ebe;Старий Косів;Старый Косов;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623682403;3;1
80f55810-749b-11df-b112-00215aee3ebe;Старий Кривин;Старый Кривин;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6823987301;3;1
80f55813-749b-11df-b112-00215aee3ebe;Старий Кропивник;Старый Крапивник;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621287501;3;1
80f55814-749b-11df-b112-00215aee3ebe;Старий Кужіль;Старый Кужель;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755159;3;1
80f55815-749b-11df-b112-00215aee3ebe;Старий Лисець;Старый Лисец;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2625885801;3;1
80f55816-749b-11df-b112-00215aee3ebe;Старий Литвинів;Старый Литвинов;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
80f55817-749b-11df-b112-00215aee3ebe;Старий Любар;Старый Любар;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823186604;3;1
80f55818-749b-11df-b112-00215aee3ebe;Старий Майдан;Старый Майдан;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623982803;3;1
80f55819-749b-11df-b112-00215aee3ebe;Старий Майдан;Старый Майдан;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825484301;3;1
80f5581a-749b-11df-b112-00215aee3ebe;Старий Майдан;Старый Майдан;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821583205;3;1
80f5581b-749b-11df-b112-00215aee3ebe;Старий Мартинів;Старый Мартынов;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621286601;3;1
80f5581c-749b-11df-b112-00215aee3ebe;Старий Мерчик;Старый Мерчик;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321256000;3;1
80f5581d-749b-11df-b112-00215aee3ebe;Старий Милятин;Старый Милятин;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620683604;3;1
80f5581e-749b-11df-b112-00215aee3ebe;Старий Мізунь;Старый Мизунь;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622086201;3;1
80f5581f-749b-11df-b112-00215aee3ebe;Старий Мосир;Старый Мосыр;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155322;3;1
80f55820-749b-11df-b112-00215aee3ebe;Старий Мохнач;Старый Мохнач;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125187003;3;1
80f55821-749b-11df-b112-00215aee3ebe;Старий Нижбірок;Старый Нижборок;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121687301;3;1
80f55822-749b-11df-b112-00215aee3ebe;Старий Олексинець;Старый Алексинец;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123487601;3;1
80f55823-749b-11df-b112-00215aee3ebe;Старий Остропіль;Старый Острополь;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824288601;3;1
80f55824-749b-11df-b112-00215aee3ebe;Старий Порицьк;Старый Порицк;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721183814;3;1
80f55825-749b-11df-b112-00215aee3ebe;Старий Почаїв;Старый Почаев;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123487801;3;1
80f55827-749b-11df-b112-00215aee3ebe;Старий Самбір;Старый Самбор;місто;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625110100;2;0
80f55828-749b-11df-b112-00215aee3ebe;Старий Скалат;Старый Скалат;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124687801;3;1
80f55829-749b-11df-b112-00215aee3ebe;Старий Солотвин;Старый Солотвин;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820886501;3;1
80f5582a-749b-11df-b112-00215aee3ebe;Старий Тараж;Старый Тараж;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123488201;3;1
80f5582b-749b-11df-b112-00215aee3ebe;Старий Угринів;Старый Угринов;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622884807;3;1
80f5582c-749b-11df-b112-00215aee3ebe;Старий Хмерин;Старый Хмерин;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821787303;3;1
80f5582d-749b-11df-b112-00215aee3ebe;Старий Хутір;Старый Хутор;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610106;3;1
80f5582e-749b-11df-b112-00215aee3ebe;Старий Хутір;Старый Хутор;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322682408;3;1
80f5582f-749b-11df-b112-00215aee3ebe;Старий Чизвик;Старый Чизвик;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325781506;3;1
80f55830-749b-11df-b112-00215aee3ebe;Старий Чорторийськ;Старый Черторыйск;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723686601;3;1
80f55831-749b-11df-b112-00215aee3ebe;Старий Яр;Старый Яр;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625884509;3;1
80f55832-749b-11df-b112-00215aee3ebe;Старий Яричів;Старый Ярычев;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622186201;3;1
80f55833-749b-11df-b112-00215aee3ebe;Старик;Старик;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425581605;3;1
80f55834-749b-11df-b112-00215aee3ebe;Старики;Старики;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720885405;3;1
80f55835-749b-11df-b112-00215aee3ebe;Старики;Старики;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386803;3;1
80f55836-749b-11df-b112-00215aee3ebe;Старики;Старики;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625085604;3;1
80f55837-749b-11df-b112-00215aee3ebe;Старики;Старики;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625880404;3;1
80f55838-749b-11df-b112-00215aee3ebe;Старикове;Стариково;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921587301;3;1
80f5583a-749b-11df-b112-00215aee3ebe;Стариця;Старица;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520983703;3;1
80f5583b-749b-11df-b112-00215aee3ebe;Старицьке;Старицкое;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820982603;3;1
80f5583c-749b-11df-b112-00215aee3ebe;Старицьківка;Старицковка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323087201;3;1
80f5583d-749b-11df-b112-00215aee3ebe;Старичі;Старычи;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625888501;3;1
80f55841-749b-11df-b112-00215aee3ebe;Старі Бабани;Старые Бабаны;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124387701;3;1
80f55842-749b-11df-b112-00215aee3ebe;Старі Безрадичі;Старые Безрадичи;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223187701;3;1
80f55843-749b-11df-b112-00215aee3ebe;Старі Бейзими;Старые Бейзимы;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825588001;3;1
80f55844-749b-11df-b112-00215aee3ebe;Старі Богородчани;Старые Богородчаны;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620488601;3;1
80f55845-749b-11df-b112-00215aee3ebe;Старі Боровичі;Старые Боровичи;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425887501;3;1
80f55846-749b-11df-b112-00215aee3ebe;Старі Бросківці;Старые Бросковцы;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324589001;3;1
80f55847-749b-11df-b112-00215aee3ebe;Старі Валки;Старые Валки;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321282008;3;1
80f55848-749b-11df-b112-00215aee3ebe;Старі Велідники;Старые Веледники;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824285206;3;1
80f55849-749b-11df-b112-00215aee3ebe;Старі Вирки;Старые Вирки;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920686003;3;1
80f5584a-749b-11df-b112-00215aee3ebe;Старі Вороб'ї;Старые Воробьи;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823487601;3;1
80f5584b-749b-11df-b112-00215aee3ebe;Старі Гончарі;Старые Гончары;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923885807;3;1
80f5584c-749b-11df-b112-00215aee3ebe;Старі іванівці;Старые Ивановци;село;64024bcc-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7322084401;3;1
80f5583e-749b-11df-b112-00215aee3ebe;Старі Кодаки;Старые Кодаки;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221486207;3;0
80f5584d-749b-11df-b112-00215aee3ebe;Старі Кошари;Старые Кошары;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722155707;3;1
80f5584e-749b-11df-b112-00215aee3ebe;Старі Кошари;Старые Кошары;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825485506;3;1
80f5584f-749b-11df-b112-00215aee3ebe;Старі Кривотули;Старые Кривотулы;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625885501;3;1
80f55850-749b-11df-b112-00215aee3ebe;Старі Кути;Старые Куты;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623687001;3;1
80f55851-749b-11df-b112-00215aee3ebe;Старі Маяки;Старые Маяки;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125485601;3;1
80f55852-749b-11df-b112-00215aee3ebe;Старі Непізнаничі;Старые Непознаничи;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821782205;3;1
80f55853-749b-11df-b112-00215aee3ebe;Старі Новаки;Старые Новаки;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822885402;3;1
80f5583f-749b-11df-b112-00215aee3ebe;Старі Петликівці;Старые Петликовцы;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121287401;3;1
80f55854-749b-11df-b112-00215aee3ebe;Старі Петрівці;Старые Петровцы;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221887801;2;0
80f55855-749b-11df-b112-00215aee3ebe;Старі Санжари;Старые Санжары;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323485001;3;1
80f55856-749b-11df-b112-00215aee3ebe;Старі Серби;Старые Сербы;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821785805;3;1
80f55857-749b-11df-b112-00215aee3ebe;Старі Скоморохи;Старые Скоморохи;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621285803;3;1
80f55858-749b-11df-b112-00215aee3ebe;Старі Соколи;Старые Соколы;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222085001;3;1
80f55859-749b-11df-b112-00215aee3ebe;Старі Стрілища;Старые Стрелища;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621555902;3;1
80f55840-749b-11df-b112-00215aee3ebe;Старі Трояни;Старые Трояны;село;903ccab3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122383901;3;1
80f5585b-749b-11df-b112-00215aee3ebe;Старі Червища;Старые Червища;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721487402;3;1
80f5585c-749b-11df-b112-00215aee3ebe;Старі Шомполи;Старые Шомполы;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122786901;3;1
80f5585d-749b-11df-b112-00215aee3ebe;Старі Яриловичі;Старые Ярыловичи;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424486410;3;1
80f5585e-749b-11df-b112-00215aee3ebe;Староаврамівка;Староаврамовка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324885401;3;1
80f55862-749b-11df-b112-00215aee3ebe;Старобутівка;Старобутовка;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424981005;3;1
80f55864-749b-11df-b112-00215aee3ebe;Старовичі;Старовичи;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222082903;3;1
80f55865-749b-11df-b112-00215aee3ebe;Старовишневецьке;Старовишневецкое;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224888201;3;1
80f55866-749b-11df-b112-00215aee3ebe;Старовірівка;Староверовка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324285501;3;1
80f55867-749b-11df-b112-00215aee3ebe;Старовірівка;Староверовка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325786501;3;1
80f55868-749b-11df-b112-00215aee3ebe;Старовойтове;Старовойтовое;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723384903;3;1
80f55869-749b-11df-b112-00215aee3ebe;Староволодимирівка;Старовладимировка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324881504;3;1
80f5586b-749b-11df-b112-00215aee3ebe;Старогорожене;Старогорожено;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820685601;3;1
80f5586c-749b-11df-b112-00215aee3ebe;Стародачне;Стародачное;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;;3;1
80f5586d-749b-11df-b112-00215aee3ebe;Стародніпровське;Староднепровское;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225085505;3;1
80f5586e-749b-11df-b112-00215aee3ebe;Стародобровільське;Стародобровольское;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885506;3;1
80f55871-749b-11df-b112-00215aee3ebe;Старожуків;Старожуков;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624684911;3;1
80f55872-749b-11df-b112-00215aee3ebe;Старозаводське;Старозаводское;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222983904;3;1
80f55873-749b-11df-b112-00215aee3ebe;Старозінів;Старозинов;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;;3;1
80f55874-749b-11df-b112-00215aee3ebe;Старокасянівське;Старокасьяновское;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224255115;3;1
80f55875-749b-11df-b112-00215aee3ebe;Старокозаче;Староказачье;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120886801;3;1
80f55876-749b-11df-b112-00215aee3ebe;Старокостянтинів;Староконстантинов;місто;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6810800000;2;0
80f55877-749b-11df-b112-00215aee3ebe;Старокостянтинівка;Староконстантиновка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123980404;3;1
80f55879-749b-11df-b112-00215aee3ebe;Старолозуватка;Старолозоватка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224855304;3;1
80f5587e-749b-11df-b112-00215aee3ebe;Староміщина;Старомищина;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124687501;3;1
80f55880-749b-11df-b112-00215aee3ebe;Старонове;Староново;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982410;3;1
80f55881-749b-11df-b112-00215aee3ebe;Староолексіївка;Староалексеевка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821785005;3;1
80f55885-749b-11df-b112-00215aee3ebe;Старосілля;Староселье;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723655413;3;1
80f55886-749b-11df-b112-00215aee3ebe;Старосілля;Староселье;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721183805;3;1
80f55887-749b-11df-b112-00215aee3ebe;Старосілля;Староселье;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124586401;3;1
80f55888-749b-11df-b112-00215aee3ebe;Старосілля;Староселье;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520984301;3;1
80f55889-749b-11df-b112-00215aee3ebe;Старосілля;Староселье;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822885201;3;1
80f5588a-749b-11df-b112-00215aee3ebe;Старосілля;Староселье;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421480405;3;1
80f5588b-749b-11df-b112-00215aee3ebe;Старосілля;Староселье;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120387001;3;1
80f5588c-749b-11df-b112-00215aee3ebe;Старосілля;Староселье;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;;3;1
cdc44f46-dde8-11df-9197-00215aee3ebe;Старосілля;Староселье;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820388302;3;1
80f5588d-749b-11df-b112-00215aee3ebe;Старосільці;Старосельцы;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822586701;3;1
e0517ed4-db89-11df-9197-00215aee3ebe;Старосолдатське;Старосолдатское;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610120;3;1
cdc44f3b-dde8-11df-9197-00215aee3ebe;Старостав;Старостав;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720888303;3;1
80f5588e-749b-11df-b112-00215aee3ebe;Старостине;Старостино;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724286007;3;1
80f5588f-749b-11df-b112-00215aee3ebe;Старостине;Старостино;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123985201;3;1
80f55890-749b-11df-b112-00215aee3ebe;Старостинці;Старостинцы;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523487001;3;1
80f55892-749b-11df-b112-00215aee3ebe;Старочуднівська Гута;Старочудновская Гута;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821485001;3;1
80f55893-749b-11df-b112-00215aee3ebe;Старошийка;Старошейка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822081504;3;1
80f55894-749b-11df-b112-00215aee3ebe;Старуня;Старуня;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620488801;3;1
80f55896-749b-11df-b112-00215aee3ebe;Старява;Старява;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622487604;3;1
80f55897-749b-11df-b112-00215aee3ebe;Старява;Старява;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625185301;3;1
80f55898-749b-11df-b112-00215aee3ebe;Стасева;Стасева;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823484804;3;1
80f55899-749b-11df-b112-00215aee3ebe;Стаси;Стасы;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425588703;3;1
80f5589a-749b-11df-b112-00215aee3ebe;Стасі;Стаси;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321084901;3;1
80f5589b-749b-11df-b112-00215aee3ebe;Стасівщина;Стасевщина;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424182107;3;1
80f5589c-749b-11df-b112-00215aee3ebe;Стасюки;Стасюки;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520285612;3;1
80f558a0-749b-11df-b112-00215aee3ebe;Стахорщина;Стахорщина;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423684303;3;1
80f558a1-749b-11df-b112-00215aee3ebe;Сташки;Сташки;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824281906;3;1
80f558a2-749b-11df-b112-00215aee3ebe;Стеблі;Стебли;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722183607;3;1
80f558a3-749b-11df-b112-00215aee3ebe;Стеблів;Стеблев;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122555500;3;1
80f558a4-749b-11df-b112-00215aee3ebe;Стеблівка;Стеблевка;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125387801;3;0
80f558a5-749b-11df-b112-00215aee3ebe;Стеблівка;Стеблевка;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622685602;3;1
80f558a6-749b-11df-b112-00215aee3ebe;Стеблянки;Стеблянки;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982706;3;1
80f558a7-749b-11df-b112-00215aee3ebe;Стебне;Стебное;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121287001;3;1
80f558a8-749b-11df-b112-00215aee3ebe;Стебний;Стебный;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123656201;3;1
80f558a9-749b-11df-b112-00215aee3ebe;Стебник;Стебник;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2625885901;3;1
80f558aa-749b-11df-b112-00215aee3ebe;Стебник;Стебник;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4610670500;2;0
80f558ab-749b-11df-b112-00215aee3ebe;Стебниця;Стебница;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821183806;3;1
80f558ac-749b-11df-b112-00215aee3ebe;Стебні;Стебни;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323581004;3;1
80f558ad-749b-11df-b112-00215aee3ebe;Стебні;Стебни;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620887001;3;1
80f558ae-749b-11df-b112-00215aee3ebe;Стегниківці;Стегниковцы;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125287601;3;1
80f558b0-749b-11df-b112-00215aee3ebe;Стенжаричі;Стенжаричи;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720510421;3;1
80f558b1-749b-11df-b112-00215aee3ebe;Стені;Стени;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625810505;3;1
80f558b2-749b-11df-b112-00215aee3ebe;Стенятин;Стенятин;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624887201;3;1
80f558b3-749b-11df-b112-00215aee3ebe;Степ;Степь;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223582812;3;1
80f558b4-749b-11df-b112-00215aee3ebe;Степ;Степь;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420880802;3;1
80f558b5-749b-11df-b112-00215aee3ebe;Степ;Степь;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421785207;3;1
80f558b6-749b-11df-b112-00215aee3ebe;Степ;Степь;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425384504;3;1
80f558b7-749b-11df-b112-00215aee3ebe;Степ;Степь;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825583803;3;1
80f558b8-749b-11df-b112-00215aee3ebe;Степангород;Степангород;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620889501;3;1
80f558b9-749b-11df-b112-00215aee3ebe;Степаненки;Степаненки;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5310390010;3;1
80f558ba-749b-11df-b112-00215aee3ebe;Степаненкове;Степаненково;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786705;3;1
80f558bb-749b-11df-b112-00215aee3ebe;Степанецьке;Степанецкое;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122087805;3;1
80f558bc-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324555114;3;1
80f558bd-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320285001;3;1
80f558be-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321082006;3;1
80f558bf-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324082705;3;1
80f558c0-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325455305;3;1
80f558c1-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925681505;3;1
80f558c2-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920986801;3;1
80f558c3-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920683010;3;1
80f558c4-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924755800;2;1
80f558c5-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222383502;3;1
80f558c6-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222084506;3;1
80f558c7-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455125;3;1
80f558c8-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820681202;3;1
80f558c9-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821782504;3;1
80f558ca-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321587202;3;1
80f558cb-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323555103;3;1
80f558cc-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324884511;3;1
80f558cd-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323988509;3;1
80f558ce-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122986005;3;1
80f558cf-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123381804;3;1
80f558d0-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121285101;3;1
80f558d1-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123985401;3;1
80f558d2-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122784207;3;1
80f558d3-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123185204;3;1
80f558d6-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622880812;3;1
80f558de-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222083202;3;1
80f558df-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221485803;3;1
80f558e0-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821786801;3;1
80f558e1-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822885401;3;1
80f558e2-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423082002;3;1
80f558e3-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420887601;3;1
80f558e4-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120689301;3;1
80f558e5-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520687603;3;1
80f558e6-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523787001;3;1
80f558e7-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524382503;3;1
80f558e8-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520882404;3;1
80f558e9-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520380804;3;1
80f558ea-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525888301;3;1
80f558eb-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820389005;3;1
80f558ec-749b-11df-b112-00215aee3ebe;Степанівка;Степановка;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825288001;2;1
c8674aa6-dbb0-11df-9197-00215aee3ebe;Степанівка;Степановка;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622682804;3;1
80f558ef-749b-11df-b112-00215aee3ebe;Степанівське;Степановское;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425383004;3;1
80f558f0-749b-11df-b112-00215aee3ebe;Степанки;Степанки;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325184007;3;1
80f558f1-749b-11df-b112-00215aee3ebe;Степанки;Степанки;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124987001;3;1
80f558f2-749b-11df-b112-00215aee3ebe;Степанки;Степанки;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523487805;3;1
80f558f3-749b-11df-b112-00215aee3ebe;Степанки;Степанки;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520283006;3;1
80f558f4-749b-11df-b112-00215aee3ebe;Степанки;Степанки;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522887801;3;1
80f558f7-749b-11df-b112-00215aee3ebe;Степанці;Степанцы;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122087801;3;1
80f558f8-749b-11df-b112-00215aee3ebe;Степань;Степань;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625455700;3;1
80f558f9-749b-11df-b112-00215aee3ebe;Степашки;Степашки;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520885603;3;1
80f558fa-749b-11df-b112-00215aee3ebe;Степівка;Степовка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322481307;3;1
80f558fb-749b-11df-b112-00215aee3ebe;Степівка;Степовка;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123486001;3;1
80f558fc-749b-11df-b112-00215aee3ebe;Степівка;Степовка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520885803;3;1
80f55915-749b-11df-b112-00215aee3ebe;Степівка;Степовка;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821586404;3;1
80f558fd-749b-11df-b112-00215aee3ebe;Степківка;Степковка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825487001;3;1
80f558fe-749b-11df-b112-00215aee3ebe;Степківка;Степковка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124387801;3;1
80f558ff-749b-11df-b112-00215aee3ebe;Степне;Степное;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324084901;3;1
80f55901-749b-11df-b112-00215aee3ebe;Степне;Степное;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925683701;3;1
80f55902-749b-11df-b112-00215aee3ebe;Степне;Степное;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922986506;3;1
80f55903-749b-11df-b112-00215aee3ebe;Степне;Степное;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786308;3;1
80f55904-749b-11df-b112-00215aee3ebe;Степне;Степное;село;4ffc4b7d-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;;3;1
80f55905-749b-11df-b112-00215aee3ebe;Степне;Степное;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;;3;1
80f55906-749b-11df-b112-00215aee3ebe;Степне;Степное;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320888205;3;1
80f55907-749b-11df-b112-00215aee3ebe;Степне;Степное;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322188601;2;1
80f55909-749b-11df-b112-00215aee3ebe;Степне;Степное;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520686701;3;1
80f55912-749b-11df-b112-00215aee3ebe;Степне;Степное;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124089202;3;1
80f55913-749b-11df-b112-00215aee3ebe;Степне;Степное;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524980315;3;1
62c3d151-749b-11df-b112-00215aee3ebe;Степне;Степное;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924783405;3;1
7ad94e3a-749b-11df-b112-00215aee3ebe;Степне (Пролетар);Степное (Пролетар);село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322682406;3;1
80f55917-749b-11df-b112-00215aee3ebe;Степова Долина;Степовая Долина;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823384202;3;1
80f55918-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
80f55919-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221487201;3;1
80f5591a-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224555602;3;1
80f5591b-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224881504;3;1
80f5591c-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225285504;3;1
80f5591d-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1222984502;3;1
80f5591e-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885715;3;1
80f5591f-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825487005;3;1
80f55920-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822081209;3;1
80f55921-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824284001;3;1
80f55922-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320481511;3;1
80f55923-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684011;3;1
80f55924-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123985301;3;1
80f55925-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122783005;3;1
80f5592a-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520985005;3;1
80f55933-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224955602;3;1
80f55934-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;;3;1
80f55935-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224610115;3;1
80f55936-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824084405;3;1
80f55937-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423880505;3;1
80f55938-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425382504;3;1
80f55939-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523186604;3;1
80f5593a-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520884806;3;1
80f5593b-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524081807;3;1
80f5593c-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522587401;3;1
80f55900-749b-11df-b112-00215aee3ebe;Степове;Степовое;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320684604;3;1
62c3d153-749b-11df-b112-00215aee3ebe;Степове;Степное;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655105;3;1
3265ade6-8357-11e8-80d4-1c98ec135261;Степове;Степове;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222984502;3;1
ecd3b175-8356-11e8-80d4-1c98ec135261;Степове;Степове;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221884715;3;1
700aa40a-1af9-11e9-80dc-1c98ec135261;Степове;Степовое;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125184801;3;1
d80f03d5-ae27-11e5-a7d9-001ec93df11f;Степове;Степовое;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221487201;2;1
4aba3f04-749b-11df-b112-00215aee3ebe;Степове (Воровське);Степовое (Воровское);село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823380702;3;1
56bdd272-749b-11df-b112-00215aee3ebe;Степове (Жовтневе);Степове (Жовтневое);село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320482604;3;1
56bdd22c-749b-11df-b112-00215aee3ebe;Степове (Жовтневе);Степное (Жовтневое);село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922984605;3;1
5cb6172c-749b-11df-b112-00215aee3ebe;Степове (Кіровка);Степовое (Кировка);село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824884402;3;1
9f645612-5b93-11e1-b817-003048d2b473;Степове (Пролетарське);Степовое (Пролетарское);село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321084509;3;1
7ad94f98-749b-11df-b112-00215aee3ebe;Степове (Радянське);Степное (Радянское);село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323982005;3;1
80f555b5-749b-11df-b112-00215aee3ebe;Степове (Совєтське);Степовое (Советское);село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324584209;3;1
8716258f-749b-11df-b112-00215aee3ebe;Степове (Чапаєве);Степное (Чапаево);село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321886607;3;1
8d17eecf-749b-11df-b112-00215aee3ebe;Степове (Щорса);Степовое (Щорса);село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821480807;3;1
8d17eed0-749b-11df-b112-00215aee3ebe;Степове (Щорсівка);Степное (Щорсовка);село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325483709;3;1
80f5593d-749b-11df-b112-00215aee3ebe;Степові Хутори;Степные Хутора;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423886001;3;1
80f5593e-749b-11df-b112-00215aee3ebe;Степок;Степок;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922384204;3;1
80f5593f-749b-11df-b112-00215aee3ebe;Степок;Степок;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320482002;3;1
80f55941-749b-11df-b112-00215aee3ebe;Степок;Степок;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223186002;3;1
80f55942-749b-11df-b112-00215aee3ebe;Степок;Степок;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220485803;3;1
80f55943-749b-11df-b112-00215aee3ebe;Степок;Степок;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224487201;3;1
80f55944-749b-11df-b112-00215aee3ebe;Степок;Степок;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825882805;3;1
80f55945-749b-11df-b112-00215aee3ebe;Степок;Степок;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820388601;3;1
80f55946-749b-11df-b112-00215aee3ebe;Степок;Степок;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123789002;3;1
80f55947-749b-11df-b112-00215aee3ebe;Степок;Степок;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524981503;3;1
80f55948-749b-11df-b112-00215aee3ebe;Степок;Степок;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824287102;3;1
80f55949-749b-11df-b112-00215aee3ebe;Степок;Степок;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821889503;3;1
80f5594a-749b-11df-b112-00215aee3ebe;Степуки;Степуки;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322686204;3;1
80f5594b-749b-11df-b112-00215aee3ebe;Степурине;Степуриное;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187605;3;1
80f5594c-749b-11df-b112-00215aee3ebe;Степурі;Степури;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322885704;3;1
80f5594e-749b-11df-b112-00215aee3ebe;Стерче;Стерче;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321086401;3;1
80f5594f-749b-11df-b112-00215aee3ebe;Стетківці;Стетковцы;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825887601;3;1
80f55950-749b-11df-b112-00215aee3ebe;Стефанівка;Стефановка;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624486003;3;1
80f55952-749b-11df-b112-00215aee3ebe;Стефанівщина;Стефановщина;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320285007;3;1
80f55953-749b-11df-b112-00215aee3ebe;Стецева;Стецева;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625285101;3;1
80f55954-749b-11df-b112-00215aee3ebe;Стецівка;Стецовка;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625285102;3;1
80f55955-749b-11df-b112-00215aee3ebe;Стецівка;Стецовка;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121287201;3;1
80f55956-749b-11df-b112-00215aee3ebe;Стецівка;Стецовка;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125485501;3;1
80f55957-749b-11df-b112-00215aee3ebe;Стецьки;Стецки;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824288901;3;1
80f55958-749b-11df-b112-00215aee3ebe;Стецьківка;Стецковка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924787101;2;1
80f5595a-749b-11df-b112-00215aee3ebe;Стещина;Стещина;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223588401;3;1
80f5595b-749b-11df-b112-00215aee3ebe;Стиборівка;Стыборовка;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;4620380705;3;1
80f5595c-749b-11df-b112-00215aee3ebe;Стигле;Стыгле;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124255505;3;1
9c8beb1e-ceec-11e0-ad66-003048d2b473;Стинище;Стынище;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;;3;1
80f5595e-749b-11df-b112-00215aee3ebe;Стирківці;Стерковцы;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623983004;3;1
80f5595f-749b-11df-b112-00215aee3ebe;Стирти;Стырты;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825687901;3;1
80f55962-749b-11df-b112-00215aee3ebe;Стіжок;Стожок;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125887501;3;1
80f55963-749b-11df-b112-00215aee3ebe;Стійкове;Стийково;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122286801;3;1
80f55964-749b-11df-b112-00215aee3ebe;Стільсько;Стольско;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623088415;3;1
80f55965-749b-11df-b112-00215aee3ebe;Стіна;Стена;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523985701;3;1
80f55966-749b-11df-b112-00215aee3ebe;Стінка;Стенка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325455111;3;1
80f55967-749b-11df-b112-00215aee3ebe;Стінка;Стенка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924782905;3;1
80f55968-749b-11df-b112-00215aee3ebe;Стінка;Стенка;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121287801;3;1
80f55969-749b-11df-b112-00215aee3ebe;Стінка;Стенка;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621881503;3;1
80f5596a-749b-11df-b112-00215aee3ebe;Стінки;Стенки;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281217;3;1
80f5596c-749b-11df-b112-00215aee3ebe;Стобихва;Стобыхва;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721481003;3;1
80f5596d-749b-11df-b112-00215aee3ebe;Стобихівка;Стобыховка;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721487101;3;1
80f5596e-749b-11df-b112-00215aee3ebe;Стовбина Долина;Стовбина Долина;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323486401;3;1
80f5596f-749b-11df-b112-00215aee3ebe;Стовбине;Стовбино;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323281807;3;1
80f5597c-749b-11df-b112-00215aee3ebe;Стовп'яги;Стовпяги;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223387201;3;1
80f55970-749b-11df-b112-00215aee3ebe;Стовпець;Стовбец;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621688501;3;1
80f55971-749b-11df-b112-00215aee3ebe;Стовпи;Стовпы;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820681811;3;1
80f55972-749b-11df-b112-00215aee3ebe;Стовпин;Стовпин;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620687005;3;1
80f55973-749b-11df-b112-00215aee3ebe;Стовпин;Стовпин;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623087401;3;1
80f55974-749b-11df-b112-00215aee3ebe;Стовпинка;Стовпинка;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824486401;3;1
80f55976-749b-11df-b112-00215aee3ebe;Стовпів;Стовпов;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825887701;3;1
80f55977-749b-11df-b112-00215aee3ebe;Стовпівка;Стовповка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421483203;3;1
80f55978-749b-11df-b112-00215aee3ebe;Стовпне;Стовпное;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223581604;3;1
80f55979-749b-11df-b112-00215aee3ebe;Стовпні;Стовпни;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620886503;3;1
80f5597d-749b-11df-b112-00215aee3ebe;Стогнії;Стогнии;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322684510;3;1
80f5597e-749b-11df-b112-00215aee3ebe;Стоділка;Стодолка;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625587805;3;1
80f5597f-749b-11df-b112-00215aee3ebe;Стоділки;Стодолки;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620988002;3;1
80f55980-749b-11df-b112-00215aee3ebe;Стодоли;Стодолы;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423388501;3;1
80f55981-749b-11df-b112-00215aee3ebe;Стодульці;Стодульцы;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521086003;3;1
80f55983-749b-11df-b112-00215aee3ebe;Стоки;Стоки;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623387206;3;1
80f55985-749b-11df-b112-00215aee3ebe;Столинські Смоляри;Столинские Смоляры;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723384917;3;1
80f55986-749b-11df-b112-00215aee3ebe;Столярове;Столярово;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923283803;3;1
80f55987-749b-11df-b112-00215aee3ebe;Стольне;Стольное;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423088501;3;1
80f55988-749b-11df-b112-00215aee3ebe;Стоморги;Стоморги;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623884906;3;1
80f55989-749b-11df-b112-00215aee3ebe;Стопилка;Стопилка;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422487907;3;1
80f5598a-749b-11df-b112-00215aee3ebe;Стопчатів;Стопчатов;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623687201;3;1
80f5598d-749b-11df-b112-00215aee3ebe;Сторожинець;Сторожинец;місто;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7324510100;3;0
80f5598e-749b-11df-b112-00215aee3ebe;Сторожів;Сторожев;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623087501;3;1
80f5598f-749b-11df-b112-00215aee3ebe;Сторожниця;Сторожница;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2124886301;3;1
80f55990-749b-11df-b112-00215aee3ebe;Сторожове;Сторожевое;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325480403;3;1
80f55991-749b-11df-b112-00215aee3ebe;Сторона;Сторона;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621255701;3;1
bb721f65-379e-11e1-8e1b-003048d2b473;Сторона;Сторона;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;;3;1
80f55992-749b-11df-b112-00215aee3ebe;Стороневичі;Стороневичи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625183303;3;1
80f55993-749b-11df-b112-00215aee3ebe;Сторонибаби;Сторонибабы;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620685972;3;1
80f55994-749b-11df-b112-00215aee3ebe;Сторониче;Сторониче;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822181302;3;1
80f55995-749b-11df-b112-00215aee3ebe;Сторчове;Сторчевое;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323686201;3;1
80f55996-749b-11df-b112-00215aee3ebe;Стояни;Стояны;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
80f55997-749b-11df-b112-00215aee3ebe;Стоянів;Стоянов;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623985801;3;1
80f55998-749b-11df-b112-00215aee3ebe;Стоянівка;Стояновка;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625886913;3;1
80f55999-749b-11df-b112-00215aee3ebe;Стоянка;Стоянка;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222482004;2;0
80f5599a-749b-11df-b112-00215aee3ebe;Стоянове;Стояново;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125255117;3;1
80f5599b-749b-11df-b112-00215aee3ebe;Стоянове;Стояново;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655120;3;1
80f5599c-749b-11df-b112-00215aee3ebe;Стоянці;Стоянцы;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622486601;3;1
80f5599d-749b-11df-b112-00215aee3ebe;Страбичово;Страбычово;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122787401;2;1
80f5599e-749b-11df-b112-00215aee3ebe;Страдч;Страдч;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625886403;3;1
80f5599f-749b-11df-b112-00215aee3ebe;Стражгород;Стражгород;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523787201;3;1
80f559a0-749b-11df-b112-00215aee3ebe;Страсне;Страстное;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323983504;3;1
80f559a1-749b-11df-b112-00215aee3ebe;Стратин;Стратин;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624486201;3;1
80f559a2-749b-11df-b112-00215aee3ebe;Стратіївка;Стратиевка;село;78f453a8-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0525085301;3;1
80f559a3-749b-11df-b112-00215aee3ebe;Страхолісся;Страхолесье;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222081401;3;1
80f559a4-749b-11df-b112-00215aee3ebe;Страшеве;Страшево;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625482303;3;1
80f559a5-749b-11df-b112-00215aee3ebe;Страшевичі;Страшевичи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625185501;3;1
80f559a6-749b-11df-b112-00215aee3ebe;Стреків;Стреков;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821588404;3;1
80f559a7-749b-11df-b112-00215aee3ebe;Стремигород;Стремигород;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822385601;3;1
80f559a8-749b-11df-b112-00215aee3ebe;Стремільче;Стремильче;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623985901;3;1
80f559a9-749b-11df-b112-00215aee3ebe;Стремінь;Стремень;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624885902;3;1
80f559ab-749b-11df-b112-00215aee3ebe;Стрептів;Стрептов;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622186601;2;1
80f559ac-749b-11df-b112-00215aee3ebe;Стрехалюки;Стрехалюки;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620355402;3;1
80f559ad-749b-11df-b112-00215aee3ebe;Стрибіж;Стрибеж;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825484601;3;1
80f559ae-749b-11df-b112-00215aee3ebe;Стригальня;Стрыгальня;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122455103;3;1
80f559af-749b-11df-b112-00215aee3ebe;Стригани;Стриганы;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823984002;3;1
80f559b0-749b-11df-b112-00215aee3ebe;Стриганка;Стриганка;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622185921;3;1
80f559b1-749b-11df-b112-00215aee3ebe;Стриганці;Стриганцы;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120487009;3;1
80f559b2-749b-11df-b112-00215aee3ebe;Стриганці;Стриганцы;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625886001;3;1
80f559b3-749b-11df-b112-00215aee3ebe;Стриганці;Стриганцы;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625387001;3;1
80f559b4-749b-11df-b112-00215aee3ebe;Стриєва;Стриева;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824085401;3;1
80f559b5-749b-11df-b112-00215aee3ebe;Стрижавка;Стрижавка;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224286801;3;1
80f559b6-749b-11df-b112-00215aee3ebe;Стрижавка;Стрижавка;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520655900;3;0
80f559b7-749b-11df-b112-00215aee3ebe;Стрижавка Перша;Стрижавка Первая;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
80f559b8-749b-11df-b112-00215aee3ebe;Стрижаків;Стрижаков;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523185301;3;1
80f559b9-749b-11df-b112-00215aee3ebe;Стрижівка;Стрижевка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822587001;3;1
80f559ba-749b-11df-b112-00215aee3ebe;Стрижівка;Стрижевка;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823186601;3;1
80f559bb-749b-11df-b112-00215aee3ebe;Стрижівщина;Стрижевщина;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323484409;3;1
80f559bc-749b-11df-b112-00215aee3ebe;Стриївка;Стрыевка;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122488401;3;1
80f559bd-749b-11df-b112-00215aee3ebe;Стрий;Стрый;місто;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4611200000;1;0
80f559be-749b-11df-b112-00215aee3ebe;Стримба;Стрымба;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122583903;3;1
80f559bf-749b-11df-b112-00215aee3ebe;Стримба;Стрымба;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624086501;3;1
80f559c0-749b-11df-b112-00215aee3ebe;Стримба;Стрымба;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123681502;3;1
80f559c1-749b-11df-b112-00215aee3ebe;Стримівка;Стрымовка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520583004;3;1
80f559c2-749b-11df-b112-00215aee3ebe;Стримівщина;Стримовщина;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823455402;3;1
80f559c3-749b-11df-b112-00215aee3ebe;Стрипа;Стрипа;село;fbfb8c23-41be-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2124888202;3;1
80f559c4-749b-11df-b112-00215aee3ebe;Стричава;Стричава;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120885601;3;1
80f559c5-749b-11df-b112-00215aee3ebe;Стрілевщина;Стрелевщина;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321384405;3;1
80f559c6-749b-11df-b112-00215aee3ebe;Стрілецька Пушкарка;Стрелецкая Пушкарка;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921283603;3;1
80f559c7-749b-11df-b112-00215aee3ebe;Стрілецьке;Стрелецкое;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484005;3;1
80f559c8-749b-11df-b112-00215aee3ebe;Стрілецький Кут;Стрелецкий Кут;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322588501;3;1
80f559c9-749b-11df-b112-00215aee3ebe;Стрілеча;Стрелечья;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325180707;3;1
80f559ca-749b-11df-b112-00215aee3ebe;Стрілки;Стрелки;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623387201;3;1
80f559cb-749b-11df-b112-00215aee3ebe;Стрілки;Стрелки;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625185701;3;1
80f559cc-749b-11df-b112-00215aee3ebe;Стрілків;Стрелков;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625386801;3;1
80f559cd-749b-11df-b112-00215aee3ebe;Стрілківці;Стрелковцы;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120887701;3;1
80f559cf-749b-11df-b112-00215aee3ebe;Стрілковичі;Стрелковичи;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624287901;3;1
80f559d0-749b-11df-b112-00215aee3ebe;Стрільбичі;Стрельбичи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625185901;3;1
80f559d1-749b-11df-b112-00215aee3ebe;Стрільники;Стрельники;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923887701;3;1
80f559d2-749b-11df-b112-00215aee3ebe;Стрільники;Стрельники;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420388001;3;1
80f559d3-749b-11df-b112-00215aee3ebe;Стрільники;Стрельники;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424184904;3;1
80f559d4-749b-11df-b112-00215aee3ebe;Стрільники;Стрельники;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525387801;3;1
80f559d5-749b-11df-b112-00215aee3ebe;Стрільськ;Стрельск;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625487201;3;1
80f559d6-749b-11df-b112-00215aee3ebe;Стрільцеве;Стрельцово;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920655311;3;1
80f559d8-749b-11df-b112-00215aee3ebe;Стрільче;Стрельче;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720886004;3;1
80f559d9-749b-11df-b112-00215aee3ebe;Стрільче;Стрельче;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621685501;3;1
80f559da-749b-11df-b112-00215aee3ebe;Стрільчинці;Стрельчинцы;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523010110;3;1
80f559db-749b-11df-b112-00215aee3ebe;Стрітівка;Стретовка;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222287801;3;1
80f559dc-749b-11df-b112-00215aee3ebe;Стріхівці;Стреховцы;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825888001;3;1
80f559e1-749b-11df-b112-00215aee3ebe;Строївка;Строевка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424482003;3;1
80f559e2-749b-11df-b112-00215aee3ebe;Строїнці;Строинцы;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323087901;3;1
80f559e3-749b-11df-b112-00215aee3ebe;Строїнці;Строинцы;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524586501;3;1
80f559e5-749b-11df-b112-00215aee3ebe;Стройне;Стройное;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124085801;3;1
80f559e6-749b-11df-b112-00215aee3ebe;Строкачі;Строкачи;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324586709;3;1
80f559e7-749b-11df-b112-00215aee3ebe;Строки;Строки;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824783503;3;1
80f559e8-749b-11df-b112-00215aee3ebe;Строків;Строков;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824786701;3;1
80f559e9-749b-11df-b112-00215aee3ebe;Строкова;Строкова;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223387301;3;1
80f559ea-749b-11df-b112-00215aee3ebe;Стронятин;Стронятин;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622786407;3;1
80f559eb-749b-11df-b112-00215aee3ebe;Струга;Струга;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823389501;3;1
80f559ec-749b-11df-b112-00215aee3ebe;Струмівка;Струмовка;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722884803;2;0
80f559ed-749b-11df-b112-00215aee3ebe;Струмок;Струмок;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;7324089602;3;1
80f559ee-749b-11df-b112-00215aee3ebe;Струмок;Струмок;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125084701;2;1
80f559ef-749b-11df-b112-00215aee3ebe;Струмок;Струмок;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386009;3;1
80f559f0-749b-11df-b112-00215aee3ebe;Струпків;Струпков;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623286801;3;1
80f559f1-749b-11df-b112-00215aee3ebe;Струсів;Струсов;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125088301;3;1
80f559f2-749b-11df-b112-00215aee3ebe;Струсове;Струсово;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522884606;3;1
80f559f3-749b-11df-b112-00215aee3ebe;Струтин;Струтин;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621887601;3;1
80f559f7-749b-11df-b112-00215aee3ebe;Струтинівка ;Струтиновка ;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320688105;3;1
80f559f4-749b-11df-b112-00215aee3ebe;Струтинка;Струтинка;село;6b07f835-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5124382605;3;1
80f559f5-749b-11df-b112-00215aee3ebe;Струтинка;Струтинка;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120282004;3;1
80f559f6-749b-11df-b112-00215aee3ebe;Струтинка;Струтинка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522286801;3;1
80f559f8-749b-11df-b112-00215aee3ebe;Струцівка;Струцовка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510126;3;1
80f559f9-749b-11df-b112-00215aee3ebe;Стрюківка;Стрюковка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225482007;3;1
80f559fa-749b-11df-b112-00215aee3ebe;Стрюкове;Стрюково;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123584501;3;1
80f559fd-749b-11df-b112-00215aee3ebe;Стугівщина;Стуговщина;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287905;3;1
80f559fe-749b-11df-b112-00215aee3ebe;Студена;Студеная;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523283001;3;1
80f559ff-749b-11df-b112-00215aee3ebe;Студенець;Студенец;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122081102;3;1
56bdd259-749b-11df-b112-00215aee3ebe;Студеники (Жовтневе);Студеники (Жовтневе);село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223383701;3;1
80f55a00-749b-11df-b112-00215aee3ebe;Студениця;Студеница;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822587301;3;1
80f55a01-749b-11df-b112-00215aee3ebe;Студениця;Студеница;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524587905;3;1
80f55a02-749b-11df-b112-00215aee3ebe;Студенок;Студенок;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921587501;3;1
80f55a03-749b-11df-b112-00215aee3ebe;Студенок;Студенок;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325483404;3;1
80f55a04-749b-11df-b112-00215aee3ebe;Студенок;Студенок;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322887501;3;1
80f55a05-749b-11df-b112-00215aee3ebe;Студинець;Студинец;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625882405;3;1
80f55a06-749b-11df-b112-00215aee3ebe;Студинець;Студинец;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421486409;3;1
80f55a07-749b-11df-b112-00215aee3ebe;Студині;Студыни;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724581003;3;1
80f55a09-749b-11df-b112-00215aee3ebe;Студинка;Студинка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423687402;3;1
80f55a08-749b-11df-b112-00215aee3ebe;Студінка;Студинка;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622887401;3;1
80f55a0a-749b-11df-b112-00215aee3ebe;Студлів;Студлов;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623287602;3;1
80f55a0b-749b-11df-b112-00215aee3ebe;Студянка;Студянка;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621689513;3;1
80f55a0c-749b-11df-b112-00215aee3ebe;Стужиця;Стужица;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120886001;3;1
80f55a0d-749b-11df-b112-00215aee3ebe;Стукалівка;Стукаловка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920687405;3;1
80f55a0e-749b-11df-b112-00215aee3ebe;Стукалівка;Стукаловка;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320885301;3;1
80f55a10-749b-11df-b112-00215aee3ebe;Стулепівка;Стулеповка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324284707;3;1
80f55a13-749b-11df-b112-00215aee3ebe;Ступаківка;Ступаковка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421788601;3;1
80f55a14-749b-11df-b112-00215aee3ebe;Ступичне;Ступичное;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122287001;3;1
80f55a15-749b-11df-b112-00215aee3ebe;Ступки;Ступки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321385005;3;1
80f55a16-749b-11df-b112-00215aee3ebe;Ступки;Ступки;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083713;3;1
80f55a17-749b-11df-b112-00215aee3ebe;Ступки;Ступки;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982411;3;1
80f55a18-749b-11df-b112-00215aee3ebe;Ступки;Ступки;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125287701;2;1
80f55a19-749b-11df-b112-00215aee3ebe;Ступки;Ступки;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523180404;3;1
80f55a1b-749b-11df-b112-00215aee3ebe;Ступник;Ступник;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524883103;3;1
80f55a1c-749b-11df-b112-00215aee3ebe;Ступниця;Ступница;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621287801;3;1
80f55a1a-749b-11df-b112-00215aee3ebe;Ступно;Ступно;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622685701;3;1
80f55a1e-749b-11df-b112-00215aee3ebe;Стуфчинці;Стуфчинцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825088401;3;1
80f55a1f-749b-11df-b112-00215aee3ebe;Стягайлівка;Стягайловка;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924486701;3;1
80f55a20-749b-11df-b112-00215aee3ebe;Стягайлівка;Стягайловка;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923283205;3;1
80f55a21-749b-11df-b112-00215aee3ebe;Субине;Субино;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386203;3;1
80f55a22-749b-11df-b112-00215aee3ebe;Субіч;Субич;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822483605;3;1
80f55a23-749b-11df-b112-00215aee3ebe;Суботів;Субботов;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621286203;3;1
80f55a24-749b-11df-b112-00215aee3ebe;Суботів;Субботов;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125486001;3;1
80f55a25-749b-11df-b112-00215aee3ebe;Суботівка;Субботовка;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522686801;2;1
80f55a27-749b-11df-b112-00215aee3ebe;Суботці;Субботцы;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522286301;3;1
80f55a28-749b-11df-b112-00215aee3ebe;Сувид;Сувид;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221888001;3;1
8716260f-749b-11df-b112-00215aee3ebe;Суворе (Червона Зірка);Суворе (Червона Зірка);село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825785201;3;1
80f55a2a-749b-11df-b112-00215aee3ebe;Суворове;Суворово;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923888109;3;1
80f55a2b-749b-11df-b112-00215aee3ebe;Суворове;Суворово;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122055400;2;1
80f55a2f-749b-11df-b112-00215aee3ebe;Суворовка;Суворовка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221883508;3;1
80f55a30-749b-11df-b112-00215aee3ebe;Суворовка;Суворовка;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824885202;3;1
80f55a32-749b-11df-b112-00215aee3ebe;Суворовське;Суворовское;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055105;3;1
80f55a34-749b-11df-b112-00215aee3ebe;Суворовське;Суворовское;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524385401;3;1
80f55a35-749b-11df-b112-00215aee3ebe;Сугаки;Сугаки;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522687001;2;1
56bdd21c-749b-11df-b112-00215aee3ebe;Сугарівське (Жовтень);Сугаревское (Жовтень);село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324855102;3;1
80f55a36-749b-11df-b112-00215aee3ebe;Сугокліївка;Сугоклиевка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520887001;3;1
80f55a37-749b-11df-b112-00215aee3ebe;Сугрів;Сугров;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621581504;3;1
80f55a39-749b-11df-b112-00215aee3ebe;Суданівка;Судановка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055411;3;1
17af7b2f-ddb9-11df-9197-00215aee3ebe;Суданівка;Судановка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;;3;1
80f55a3a-749b-11df-b112-00215aee3ebe;Суданка;Суданка;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324587001;3;1
80f55a3b-749b-11df-b112-00215aee3ebe;Судачівка;Судачевка;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825888405;3;1
80f55a3c-749b-11df-b112-00215aee3ebe;Судилків;Судилков;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825588501;3;1
80f55a3d-749b-11df-b112-00215aee3ebe;Судимонт;Судимонт;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825584604;3;1
80f55a3e-749b-11df-b112-00215aee3ebe;Судиха;Судиха;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324886504;3;1
80f55a3f-749b-11df-b112-00215aee3ebe;Судівка;Судовка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323486701;3;1
80f55a40-749b-11df-b112-00215aee3ebe;Судівка;Судовка;село;bad3ac3c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1223783809;3;1
80f55a41-749b-11df-b112-00215aee3ebe;Судівка;Судовка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321082007;3;1
80f55a42-749b-11df-b112-00215aee3ebe;Судіївка;Судиевка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324085001;3;1
80f55a43-749b-11df-b112-00215aee3ebe;Судковичі;Судковичи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622488902;3;1
80f55a44-749b-11df-b112-00215aee3ebe;Судобичі;Судобичи;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621685211;3;1
80f55a45-749b-11df-b112-00215aee3ebe;Судова Вишня;Судовая Вишня;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;7d1766f6-b6ff-11eb-80d4-000c29800ae7;4622410500;3;0
80f55a46-749b-11df-b112-00215aee3ebe;Судче;Судче;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155132;3;1
80f55a48-749b-11df-b112-00215aee3ebe;Суємці;Суемцы;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820685801;3;1
80f55a4a-749b-11df-b112-00215aee3ebe;Суйми;Суймы;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622685103;3;1
80f55a4b-749b-11df-b112-00215aee3ebe;Сукачі;Сукачи;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725085802;3;1
80f55a4c-749b-11df-b112-00215aee3ebe;Сукачі;Сукачи;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222082002;3;1
80f55a4d-749b-11df-b112-00215aee3ebe;Сукіль;Сукиль;село;8067acf2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2610291002;3;1
80f55a4e-749b-11df-b112-00215aee3ebe;Сула;Сула;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924787301;3;1
80f55a4f-749b-11df-b112-00215aee3ebe;Сулак;Сулак;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423887007;3;1
80f55a50-749b-11df-b112-00215aee3ebe;Сулигівка;Сулиговка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322880803;3;1
80f55a51-749b-11df-b112-00215aee3ebe;Сулими;Сулимы;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755153;3;1
80f55a52-749b-11df-b112-00215aee3ebe;Сулими;Сулимы;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321382003;3;1
80f55a53-749b-11df-b112-00215aee3ebe;Сулими;Сулимы;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323480405;3;1
80f55a54-749b-11df-b112-00215aee3ebe;Сулими;Сулимы;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924189101;3;1
80f55a55-749b-11df-b112-00215aee3ebe;Сулимів;Сулимов;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622786408;3;1
80f55a56-749b-11df-b112-00215aee3ebe;Сулимівка;Сулимовка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225587201;3;1
80f55a57-749b-11df-b112-00215aee3ebe;Сулимівка;Сулимовка;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220884405;3;1
80f55a58-749b-11df-b112-00215aee3ebe;Суличівка;Суличевка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424484403;3;1
80f55a59-749b-11df-b112-00215aee3ebe;Сулківка;Сулковка;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524887001;3;1
80f55a5a-749b-11df-b112-00215aee3ebe;Сулятичі;Сулятичи;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621588303;3;1
80f55a5b-749b-11df-b112-00215aee3ebe;Сульжин;Сульжин;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825581303;3;1
80f55a5c-749b-11df-b112-00215aee3ebe;Сульжинівка;Сульжиновка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821485002;3;1
80f55a5d-749b-11df-b112-00215aee3ebe;Сульське;Сульское;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920681407;3;1
80f55a5e-749b-11df-b112-00215aee3ebe;Суми;Сумы;місто;9b486f64-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5910100000;1;0
80f55a5f-749b-11df-b112-00215aee3ebe;Сумівка;Сумовка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520485003;3;1
80f55a61-749b-11df-b112-00215aee3ebe;Сумське;Сумское;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325785809;3;1
80f55a63-749b-11df-b112-00215aee3ebe;Сумці;Сумцы;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324585508;3;1
80f55a64-749b-11df-b112-00215aee3ebe;Суничне;Суничное;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425888201;3;1
80f55a65-749b-11df-b112-00215aee3ebe;Сунки;Сунки;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123788501;3;1
80f55a66-749b-11df-b112-00215aee3ebe;Супина;Супина;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225684002;3;1
80f55a67-749b-11df-b112-00215aee3ebe;Супівка;Суповка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520284803;3;1
80f55a68-749b-11df-b112-00215aee3ebe;Супоївка;Супоевка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225587401;3;1
80f55a69-749b-11df-b112-00215aee3ebe;Супранівка;Супрановка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124688001;3;1
80f55a6a-749b-11df-b112-00215aee3ebe;Супротивна Балка;Супротивная Балка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323486801;3;1
80f55a6b-749b-11df-b112-00215aee3ebe;Супруни;Супруны;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982007;3;1
80f55a6c-749b-11df-b112-00215aee3ebe;Супрунів;Супрунов;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522486004;3;1
80f55a6d-749b-11df-b112-00215aee3ebe;Супрунівка;Супруновка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324085201;2;0
80f55a6e-749b-11df-b112-00215aee3ebe;Супрунівка;Супруновка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920688201;3;1
80f55a6f-749b-11df-b112-00215aee3ebe;Супрунівка;Супруновка;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523010111;3;1
80f55a70-749b-11df-b112-00215aee3ebe;Супрунківці;Супрунковцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822488501;3;1
80f55a71-749b-11df-b112-00215aee3ebe;Сураж;Сураж;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125887801;3;1
80f55a72-749b-11df-b112-00215aee3ebe;Сургани;Сурганы;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520380805;3;1
80f55a73-749b-11df-b112-00215aee3ebe;Суржа;Суржа;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822483302;2;1
80f55a74-749b-11df-b112-00215aee3ebe;Суржинці;Суржинцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822484804;3;1
80f55a75-749b-11df-b112-00215aee3ebe;Суржки;Суржки;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320285705;3;1
80f55a76-749b-11df-b112-00215aee3ebe;Сурмачівка;Сурмачевка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924184403;3;1
80f55a78-749b-11df-b112-00215aee3ebe;Сурогатка;Сурогатка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525380508;3;1
80f55a79-749b-11df-b112-00215aee3ebe;Сурське;Сурское;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221485603;3;1
80f55a7a-749b-11df-b112-00215aee3ebe;Сурсько - Клевцеве;Сурско-Клевцово;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221487504;3;1
80f55a7b-749b-11df-b112-00215aee3ebe;Сурсько-Литовське;Сурско-Литовское;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221487501;3;1
80f55a7c-749b-11df-b112-00215aee3ebe;Сурсько-Михайлівка;Сурско-Михайловка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225087301;3;1
56bdd22f-749b-11df-b112-00215aee3ebe;Сусваль (Жовтневе);(Сусваль (Жовтневое) ;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720584403;3;1
c8674a9e-dbb0-11df-9197-00215aee3ebe;Сусідки;Соседки;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083715;3;1
80f55a7e-749b-11df-b112-00215aee3ebe;Сусідовичі;Соседовичи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625185101;3;1
80f55a7f-749b-11df-b112-00215aee3ebe;Сусково;Сусково;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124086901;3;0
80f55a80-749b-11df-b112-00215aee3ebe;Сусли;Суслы;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824085601;3;1
80f55a81-749b-11df-b112-00215aee3ebe;Суслівка;Сусловка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221084409;3;1
80f55a82-749b-11df-b112-00215aee3ebe;Суслівка;Сусловка;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825886002;3;1
80f55a83-749b-11df-b112-00215aee3ebe;Суслівка;Сусловка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424483005;3;1
80f55a84-749b-11df-b112-00215aee3ebe;Суслівці;Сусловцы;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823084201;3;1
80f55a85-749b-11df-b112-00215aee3ebe;Сусолів;Сусолов;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624288201;3;1
80f55a86-749b-11df-b112-00215aee3ebe;Суськ;Суск;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721886801;3;1
80f55a87-749b-11df-b112-00215aee3ebe;Суськ;Суск;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623482004;3;1
80f55a88-749b-11df-b112-00215aee3ebe;Сутиски;Сутиски;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921582302;3;1
80f55a89-749b-11df-b112-00215aee3ebe;Сутиски;Сутиски;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524555700;3;1
80f55a8a-749b-11df-b112-00215aee3ebe;Сутківці;Сутковцы;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825888401;3;1
80f55a8b-749b-11df-b112-00215aee3ebe;Сутоки;Сутоки;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421486803;3;1
80f55a8c-749b-11df-b112-00215aee3ebe;Суха;Сухая;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121981602;3;1
80f55a8d-749b-11df-b112-00215aee3ebe;Суха Балка;Сухая Балка;село;d00d3b87-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1210790001;3;1
80f55a8e-749b-11df-b112-00215aee3ebe;Суха Балка;Сухая Балка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822783515;3;1
80f55a8f-749b-11df-b112-00215aee3ebe;Суха Балка;Сухая Балка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821782505;3;1
80f55a90-749b-11df-b112-00215aee3ebe;Суха Балка;Сухая Балка;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824286070;3;1
80f55a91-749b-11df-b112-00215aee3ebe;Суха Балка;Сухая Балка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321284019;3;1
80f55a94-749b-11df-b112-00215aee3ebe;Суха Гомільша;Сухая Гомельша;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321783003;3;1
56bdda31-749b-11df-b112-00215aee3ebe;Суха Грунь (Калінінське);Сухая Грунь (Калининское);село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923281701;3;1
80f55a95-749b-11df-b112-00215aee3ebe;Суха Долина;Сухая Долина;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623088416;3;1
80f55a96-749b-11df-b112-00215aee3ebe;Суха Долина;Сухая Долина;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521982004;3;1
80f55a97-749b-11df-b112-00215aee3ebe;Суха Журівка;Сухая Журовка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125482502;3;1
80f55a98-749b-11df-b112-00215aee3ebe;Суха Калигірка;Сухая Калигорка;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122287501;3;1
80f55a99-749b-11df-b112-00215aee3ebe;Суха Калина;Сухая Калина;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224886407;3;1
80f55a9a-749b-11df-b112-00215aee3ebe;Суха Кам'янка;Сухая Каменка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322884003;3;1
80f55a9b-749b-11df-b112-00215aee3ebe;Суха Лохвиця;Сухая Лохвица;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325180411;3;1
80f55a9c-749b-11df-b112-00215aee3ebe;Суха Маячка;Сухая Маячка;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323487201;3;1
c8674a98-dbb0-11df-9197-00215aee3ebe;Суха Солониця;Сухая Солоница;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322880402;3;1
80f55a9d-749b-11df-b112-00215aee3ebe;Суханівка;Сухановка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224588505;3;1
80f55a9f-749b-11df-b112-00215aee3ebe;Суханове;Суханово;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520684202;3;1
80f55aa0-749b-11df-b112-00215aee3ebe;Сухарева Балка;Сухарева Балка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655128;3;1
80f55aa1-749b-11df-b112-00215aee3ebe;Сухарівка;Сухаревка;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755160;3;1
80f55aa2-749b-11df-b112-00215aee3ebe;Сухе;Сухое;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321883205;3;1
80f55aa3-749b-11df-b112-00215aee3ebe;Сухе;Сухое;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123985209;3;1
80f55aa6-749b-11df-b112-00215aee3ebe;Сухий;Сухой;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120886403;3;1
80f55abb-749b-11df-b112-00215aee3ebe;Сухий;Сухой;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122486005;3;1
80f55aa7-749b-11df-b112-00215aee3ebe;Сухий Єланець;Сухой Еланец;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824885201;3;1
80f55aa8-749b-11df-b112-00215aee3ebe;Сухий Кобелячок;Сухой Кобелячок;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084607;3;1
80f55aa9-749b-11df-b112-00215aee3ebe;Сухий Лиман;Сухой Лиман;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123784201;3;0
80f55aaa-749b-11df-b112-00215aee3ebe;Сухий Овраг;Сухой Овраг;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123580406;3;1
80f55aab-749b-11df-b112-00215aee3ebe;Сухий Потік;Сухой Поток;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624586903;3;1
80f55aac-749b-11df-b112-00215aee3ebe;Сухий Ставок;Сухой Ставок;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520980506;3;1
80f55aad-749b-11df-b112-00215aee3ebe;Сухий Ташлик;Сухой Ташлык;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524384201;3;1
80f55aae-749b-11df-b112-00215aee3ebe;Сухий Хутір;Сухой Хутор;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055117;3;1
80f55aaf-749b-11df-b112-00215aee3ebe;Сухий Яр;Сухой Яр;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322882005;3;1
80f55ab1-749b-11df-b112-00215aee3ebe;Сухий Яр;Сухой Яр;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224287004;3;1
80f55ab2-749b-11df-b112-00215aee3ebe;Сухине;Сухино;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283807;3;1
80f55ab3-749b-11df-b112-00215aee3ebe;Сухини;Сухины;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320888101;3;1
80f55ab4-749b-11df-b112-00215aee3ebe;Сухини;Сухины;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122589001;3;1
80f55ab5-749b-11df-b112-00215aee3ebe;Сухинівка;Сухиновка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321886501;3;1
80f55ab6-749b-11df-b112-00215aee3ebe;Сухиня;Сухиня;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420687850;3;1
80f55ab9-749b-11df-b112-00215aee3ebe;Сухівці;Суховцы;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124685009;3;1
80f55aba-749b-11df-b112-00215aee3ebe;Сухівці;Суховцы;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624687904;3;1
80f55abc-749b-11df-b112-00215aee3ebe;Суховерхів;Суховерхов;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322588601;3;1
80f55abd-749b-11df-b112-00215aee3ebe;Суховерхівка;Суховерховка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920987001;3;1
80f55abe-749b-11df-b112-00215aee3ebe;Суховодка;Суховодка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420883203;3;1
80f55abf-749b-11df-b112-00215aee3ebe;Суховоля;Суховоля;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722885204;3;1
80f55ac0-749b-11df-b112-00215aee3ebe;Суховоля;Суховоля;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624886404;3;1
80f55ac1-749b-11df-b112-00215aee3ebe;Суховоля;Суховоля;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620387001;3;1
80f55ac2-749b-11df-b112-00215aee3ebe;Суховоля;Суховоля;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;7d1766f6-b6ff-11eb-80d4-000c29800ae7;4620987701;2;0
80f55ac3-749b-11df-b112-00215aee3ebe;Суховоля;Суховоля;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824085801;3;1
80f55ac4-749b-11df-b112-00215aee3ebe;Суховоля;Суховоля;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821184301;3;1
80f55ac5-749b-11df-b112-00215aee3ebe;Суховоля;Суховоля;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620886203;3;1
80f55ac6-749b-11df-b112-00215aee3ebe;Суходіл;Суходол;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921587901;3;1
80f55ac7-749b-11df-b112-00215aee3ebe;Суходіл;Суходол;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121687601;3;1
80f55ac8-749b-11df-b112-00215aee3ebe;Суходіл;Суходол;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820955117;3;1
80f55ac9-749b-11df-b112-00215aee3ebe;Суходіл;Суходол;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624885901;3;1
80f55aca-749b-11df-b112-00215aee3ebe;Суходіл;Суходол;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625686210;3;1
80f55acb-749b-11df-b112-00215aee3ebe;Суходіл;Суходол;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623387601;3;1
80f55acd-749b-11df-b112-00215aee3ebe;Суходілля;Суходолье;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823685306;3;1
80f55acf-749b-11df-b112-00215aee3ebe;Суходільське;Суходольское;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521987801;3;1
80f55ad0-749b-11df-b112-00215aee3ebe;Суходоли;Суходолы;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720581605;3;1
80f55ad1-749b-11df-b112-00215aee3ebe;Суходоли;Суходолы;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620385809;3;1
80f55ad2-749b-11df-b112-00215aee3ebe;Сухоліси;Сухолесы;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220410511;2;1
80f55ad3-749b-11df-b112-00215aee3ebe;Сухоліски;Сухолески;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424188202;3;1
80f55ad4-749b-11df-b112-00215aee3ebe;Сухолітівка;Сухолетовка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523883605;3;1
80f55ad5-749b-11df-b112-00215aee3ebe;Сухолужжя;Сухолужье;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120882002;3;1
80f55ad6-749b-11df-b112-00215aee3ebe;Сухолуччя;Сухолучье;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221888301;3;1
80f55ad7-749b-11df-b112-00215aee3ebe;Сухомлинове;Сухомлыново;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121880404;3;1
80f55ad8-749b-11df-b112-00215aee3ebe;Сухоносівка;Сухоносовка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323086603;3;1
80f55ad9-749b-11df-b112-00215aee3ebe;Сухоносівка;Сухоносовка;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325183807;3;1
80f55ada-749b-11df-b112-00215aee3ebe;Сухополова;Сухополова;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;7424188801;3;1
80f55adb-749b-11df-b112-00215aee3ebe;Сухорабівка;Сухорабовка;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324285001;3;1
80f55adc-749b-11df-b112-00215aee3ebe;Сухоріччя;Сухоречье;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623680803;3;1
80f55ade-749b-11df-b112-00215aee3ebe;Сухостав;Сухостав;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121687701;3;1
80f55adf-749b-11df-b112-00215aee3ebe;Сухоставець;Сухоставец;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424184003;3;1
80f55ae0-749b-11df-b112-00215aee3ebe;Сухота;Сухота;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620385317;3;1
80f55ae1-749b-11df-b112-00215aee3ebe;Сухоярівка;Сухояровка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424185006;3;1
80f55ae2-749b-11df-b112-00215aee3ebe;Сухувате;Суховатое;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124787202;3;1
80f55ae3-749b-11df-b112-00215aee3ebe;Сучевени;Сучевены;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321088501;3;1
80f55ae4-749b-11df-b112-00215aee3ebe;Сушибаба;Сушибаба;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725584203;3;1
80f55ae5-749b-11df-b112-00215aee3ebe;Сушилине;Сушилино;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920655312;3;1
80f55ae6-749b-11df-b112-00215aee3ebe;Сушиця;Сушица;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625186604;3;1
80f55ae7-749b-11df-b112-00215aee3ebe;Сушівці;Сушивцы;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820388501;3;1
80f55ae8-749b-11df-b112-00215aee3ebe;Сушки;Сушки;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084609;3;1
80f55ae9-749b-11df-b112-00215aee3ebe;Сушки;Сушки;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822385801;3;1
80f55aea-749b-11df-b112-00215aee3ebe;Сушки;Сушки;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122087003;3;1
80f55aeb-749b-11df-b112-00215aee3ebe;Сушки;Сушки;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822787205;3;1
80f55aec-749b-11df-b112-00215aee3ebe;Сушківка;Сушковка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124388001;3;1
80f55aed-749b-11df-b112-00215aee3ebe;Сушно;Сушно;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623986201;3;1
80f55aee-749b-11df-b112-00215aee3ebe;Сущани;Сущаны;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222288201;3;1
80f55aef-749b-11df-b112-00215aee3ebe;Сущани;Сущаны;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824486801;3;1
80f55af0-749b-11df-b112-00215aee3ebe;Сущанка;Сущанка;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824755408;3;1
80f55af1-749b-11df-b112-00215aee3ebe;Сущин;Сущин;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125088601;3;1
80f55af2-749b-11df-b112-00215aee3ebe;Схід;Схид;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524010110;3;1
80f55af3-749b-11df-b112-00215aee3ebe;Східне;Схидное;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222985204;3;1
80f55af5-749b-11df-b112-00215aee3ebe;Східне;Схидное;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520386701;3;1
80f55af6-749b-11df-b112-00215aee3ebe;Східниця;Схидныця;село;8067ad5d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4610345400;2;1
80f55af7-749b-11df-b112-00215aee3ebe;Сюрте;Сюртэ;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124886401;3;0
80f55af8-749b-11df-b112-00215aee3ebe;Сюрюк;Сюрюк;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125382205;3;1
80f55af9-749b-11df-b112-00215aee3ebe;Сягайли;Сягайлы;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324084613;3;1
80f55afa-749b-11df-b112-00215aee3ebe;Сягрів;Сягрив;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823686502;3;1
80f55afb-749b-11df-b112-00215aee3ebe;Сядрине;Сядрино;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422488701;3;1
80f55afc-749b-11df-b112-00215aee3ebe;Сянки;Сянки;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625587101;3;1
80f55afd-749b-11df-b112-00215aee3ebe;Сьомаки;Семаки;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725085006;3;1
80f55afe-749b-11df-b112-00215aee3ebe;Сьомаки;Семаки;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881812;3;1
80f55aff-749b-11df-b112-00215aee3ebe;Сьомаки;Семаки;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820881502;3;1
80f55b00-749b-11df-b112-00215aee3ebe;Сьомаки;Семаки;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524886301;3;1
80f55b01-749b-11df-b112-00215aee3ebe;Сьомаки;Семаки;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521055309;3;1
80f55b02-749b-11df-b112-00215aee3ebe;Сьомаки;Семаки;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823986901;3;1
80f55b03-749b-11df-b112-00215aee3ebe;Сьомаки;Семаки;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824487001;3;1
80f55b05-749b-11df-b112-00215aee3ebe;Табаївка;Табаевка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425581904;3;1
80f55b06-749b-11df-b112-00215aee3ebe;Табаки;Табаки;село;6b07f8a2-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5121486501;3;1
80f55b07-749b-11df-b112-00215aee3ebe;Табанове;Табаново;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521484203;3;1
80f55b0a-749b-11df-b112-00215aee3ebe;Табачуки;Табачуки;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625888205;3;1
80f55b0b-749b-11df-b112-00215aee3ebe;Табори;Таборы;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820682602;3;1
80f55b0c-749b-11df-b112-00215aee3ebe;Таборище;Таборище;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924483203;3;1
80f55b0d-749b-11df-b112-00215aee3ebe;Таборище;Таборище;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825084904;3;1
80f55b0e-749b-11df-b112-00215aee3ebe;Таборів;Таборов;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224087201;2;1
80f55b0f-749b-11df-b112-00215aee3ebe;Таборівка;Таборовка;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822084201;3;1
80f55b10-749b-11df-b112-00215aee3ebe;Тавежня;Тавежня;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324886501;3;1
80f55b11-749b-11df-b112-00215aee3ebe;Таверівка;Таверовка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325483201;3;1
80f55b12-749b-11df-b112-00215aee3ebe;Таверівка;Таверовка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320884505;3;1
80f55b17-749b-11df-b112-00215aee3ebe;Таврівка;Тавровка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520386902;3;1
80f55b19-749b-11df-b112-00215aee3ebe;Таврійське;Таврийское;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222982207;3;1
80f55b1b-749b-11df-b112-00215aee3ebe;Таврійське;Таврийское;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520680605;3;1
5cb6171b-749b-11df-b112-00215aee3ebe;Таврійське (Кірове);Таврійське (Кірове);село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520385502;3;1
5cb61718-749b-11df-b112-00215aee3ebe;Таврійське (Кірове);Таврийское (Кирово);село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323981501;3;1
80f55b21-749b-11df-b112-00215aee3ebe;Тагамлицьке;Тагамлыкское;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321681203;3;1
80f55b22-749b-11df-b112-00215aee3ebe;Таганка;Таганка;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325484504;3;1
80f55b23-749b-11df-b112-00215aee3ebe;Таганча;Таганча;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122088201;3;1
80f55b24-749b-11df-b112-00215aee3ebe;Тагачин;Тагачин;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725581306;3;1
80f55b25-749b-11df-b112-00215aee3ebe;Тадан;Тадан;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;;3;1
80f55b26-749b-11df-b112-00215aee3ebe;Тадані;Тадани;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622110103;3;1
80f55b27-749b-11df-b112-00215aee3ebe;Тадіївка;Тадиевка;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221687601;3;1
80f55b28-749b-11df-b112-00215aee3ebe;Таїрове;Таирово;село;5394b336-41be-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;;2;1
c8674a8c-dbb0-11df-9197-00215aee3ebe;Таїрове;Таирово;село;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123755800;2;0
80f55b29-749b-11df-b112-00215aee3ebe;Тайки;Тайки;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821787301;3;1
80f55b2a-749b-11df-b112-00215aee3ebe;Тайкури;Тайкуры;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624688901;3;1
80f55b2b-749b-11df-b112-00215aee3ebe;Тайниця;Тайница;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224610116;2;1
80f55b2d-749b-11df-b112-00215aee3ebe;Талалаї;Талалаи;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523486802;3;1
80f55b2e-749b-11df-b112-00215aee3ebe;Талалаївка;Талалаевка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423388901;3;1
80f55b2f-749b-11df-b112-00215aee3ebe;Талалаївка;Талалаевка;місто;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;7425355100;3;1
80f55b30-749b-11df-b112-00215aee3ebe;Талалаївка;Талалаевка;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124686601;3;1
80f55b31-749b-11df-b112-00215aee3ebe;Талова Балка;Таловая Балка;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525285803;3;1
80f55b34-749b-11df-b112-00215aee3ebe;Тальки;Тальки;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824080202;3;1
80f55b35-749b-11df-b112-00215aee3ebe;Тальківка;Тальковка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824080203;3;1
80f55b36-749b-11df-b112-00215aee3ebe;Тальне;Тальное;місто;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124010100;2;0
80f55b37-749b-11df-b112-00215aee3ebe;Тальське;Тальское;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221085905;3;1
80f55b38-749b-11df-b112-00215aee3ebe;Тальянки;Тальянки;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124089301;3;1
80f55b39-749b-11df-b112-00215aee3ebe;Тамановичі;Тамановичи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484906;3;1
80f55b3b-749b-11df-b112-00215aee3ebe;Тамарине;Тамарино;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825784201;3;1
80f55b3c-749b-11df-b112-00215aee3ebe;Тамарівка;Тамаровка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424488004;3;1
80f55b3d-749b-11df-b112-00215aee3ebe;Тамарівка;Тамаровка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;;3;1
4aba3d83-749b-11df-b112-00215aee3ebe;Танівка (Вовкове);Танивка (Вовково);село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121285103;3;1
80f55b42-749b-11df-b112-00215aee3ebe;Танське;Танское;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124388301;3;1
80f55b44-749b-11df-b112-00215aee3ebe;Танява;Танява;село;8067acf2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2610292005;3;1
80f55b46-749b-11df-b112-00215aee3ebe;Тарава;Тарава;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624286904;3;1
80f55b47-749b-11df-b112-00215aee3ebe;Тараканів;Тараканов;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621689101;3;1
80f55b49-749b-11df-b112-00215aee3ebe;Тарандинці;Тарандинцы;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322887001;3;1
80f55b4b-749b-11df-b112-00215aee3ebe;Таранівка;Тарановка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321786001;3;0
80f55b4c-749b-11df-b112-00215aee3ebe;Таранове;Тараново;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220786617;3;1
80f55b4d-749b-11df-b112-00215aee3ebe;Таранське;Таранское;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922081106;3;1
80f55b4e-749b-11df-b112-00215aee3ebe;Таранушине;Таранушино;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320288003;3;1
80f55b4f-749b-11df-b112-00215aee3ebe;Тараса Шевченка;Тараса Шевченко;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520686604;3;1
80f55b50-749b-11df-b112-00215aee3ebe;Тараса Шевченка;Тараса Шевченко;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424489201;3;1
80f55b51-749b-11df-b112-00215aee3ebe;Тарасенки;Тарасенки;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923281907;3;1
80f55b52-749b-11df-b112-00215aee3ebe;Тарасенки;Тарасенки;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224987204;3;1
80f55b53-749b-11df-b112-00215aee3ebe;Тарасенкове;Тарасенково;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323681602;3;1
80f55b54-749b-11df-b112-00215aee3ebe;Тарасів;Тарасов;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422080605;3;1
80f55b55-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321386401;3;1
80f55b56-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324555101;3;1
80f55b57-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321683407;3;1
80f55b58-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922682603;3;1
80f55b5a-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921285201;3;1
80f55b5b-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122480403;3;1
80f55b5c-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655107;3;1
80f55b5d-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222387405;3;1
80f55b5e-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221010105;3;1
80f55b5f-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222082011;3;1
80f55b60-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222685509;3;1
80f55b61-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225255104;3;1
80f55b62-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225483207;3;1
80f55b63-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825487601;3;1
80f55b64-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823684203;3;1
80f55b65-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610121;3;1
80f55b66-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822380804;3;1
80f55b67-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324884004;3;1
80f55b68-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121882005;3;1
80f55b69-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955425;3;1
80f55b6e-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625610112;3;1
80f55b6f-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623687603;3;1
80f55b72-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124486601;3;1
80f55b76-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221282009;3;1
80f55b77-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222486601;2;0
80f55b78-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220884802;3;1
80f55b79-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223387603;3;1
80f55b7a-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224984002;3;1
80f55b7b-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223187707;3;1
80f55b7c-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222982904;3;1
80f55b7d-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220410512;2;1
80f55b7e-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222282003;3;1
80f55b7f-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221687603;3;1
80f55b80-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224610117;3;1
80f55b81-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823488202;3;1
80f55b82-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822084402;3;1
80f55b83-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820384003;3;1
80f55b84-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422282006;3;1
80f55b85-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421787203;3;1
80f55b86-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424187303;3;1
80f55b87-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123484502;3;1
80f55b88-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125187202;3;1
80f55b89-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121287601;3;1
80f55b8a-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124082602;3;1
80f55b8b-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521683809;3;1
80f55b8c-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523184103;3;1
80f55b8d-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521086303;3;1
80f55b8e-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524385801;3;1
80f55b8f-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520886509;3;1
80f55b90-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522655503;3;1
80f55b91-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;78f453a8-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0525084802;3;1
80f55b92-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523684605;3;1
80f55b93-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523110109;3;1
80f55b94-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520887201;3;1
80f55b95-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520581503;3;1
80f55b96-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520384906;3;1
80f55b97-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;78f453fd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523486901;3;1
80f55b98-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822181603;3;1
80f55b99-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821284204;3;1
80f55b9a-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825888601;3;1
80f55b9b-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822489107;3;1
80f55b9c-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320886001;3;1
80f55b9d-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323684202;3;1
80f55b9e-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасовка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324885007;3;1
62c3d163-749b-11df-b112-00215aee3ebe;Тарасівка;Тарасівка;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224083601;2;1
80f55b9f-749b-11df-b112-00215aee3ebe;Тарасівці;Тарасовцы;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323088301;3;1
80f55ba0-749b-11df-b112-00215aee3ebe;Тарасівщина;Тарасовщина;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221882002;3;1
80f55ba1-749b-11df-b112-00215aee3ebe;Тараски;Тараски;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524887404;3;1
80f55ba2-749b-11df-b112-00215aee3ebe;Тарасове;Тарасово;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881813;3;0
80f55ba3-749b-11df-b112-00215aee3ebe;Тарасове;Тарасово;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223286005;3;1
80f55ba4-749b-11df-b112-00215aee3ebe;Тарасово-Шевченкове;Тарасово-Шевченково;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520386807;3;1
80f55ba5-749b-11df-b112-00215aee3ebe;Тарасо-Григорівка;Тарасо-Григорьевка;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220310116;3;1
80f55ba6-749b-11df-b112-00215aee3ebe;Тарасо-Григорівка;Тарасо-Григорьевка;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125481004;3;1
80f55ba7-749b-11df-b112-00215aee3ebe;Тарасо-Шевченківка;Тарасо-Шевченковка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222386507;3;1
80f55ba8-749b-11df-b112-00215aee3ebe;Таратутине;Таратутино;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922355104;3;1
80f55ba9-749b-11df-b112-00215aee3ebe;Тарашани;Тарашаны;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321086701;3;1
80f55baa-749b-11df-b112-00215aee3ebe;Тараща;Тараща;місто;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224410100;2;0
80f55bab-749b-11df-b112-00215aee3ebe;Тараща;Тараща;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122587603;3;1
80f55bac-749b-11df-b112-00215aee3ebe;Таращанка;Таращанка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824082605;3;1
80f55bad-749b-11df-b112-00215aee3ebe;Тарган;Тарган;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221688001;2;1
80f55bae-749b-11df-b112-00215aee3ebe;Тарнава;Тарнава;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123486501;3;1
80f55baf-749b-11df-b112-00215aee3ebe;Тарнавка;Тарнавка;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125587801;3;1
80f55bb0-749b-11df-b112-00215aee3ebe;Тарнавка;Тарнавка;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625183503;3;1
80f55bb1-749b-11df-b112-00215aee3ebe;Тарнівці;Тарновцы;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2124886801;3;1
80f55bb2-749b-11df-b112-00215aee3ebe;Тарновиця;Тарновица;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625888601;3;1
80f55bb3-749b-11df-b112-00215aee3ebe;Тарноруда;Тарноруда;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124689002;3;1
80f55bb4-749b-11df-b112-00215aee3ebe;Тарноруда;Тарноруда;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820987601;3;1
80f55bb5-749b-11df-b112-00215aee3ebe;Таромське;Таромское;село;d00d3b5d-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1210166500;1;1
80f55bb6-749b-11df-b112-00215aee3ebe;Тартак;Тартак;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622185604;3;1
80f55bb7-749b-11df-b112-00215aee3ebe;Тартак;Тартак;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820655703;3;1
80f55bb8-749b-11df-b112-00215aee3ebe;Тартак;Тартак;село;78f453a8-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0525086401;3;1
17af7b12-ddb9-11df-9197-00215aee3ebe;Тартак;Тартак;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521083612;3;1
80f55bb9-749b-11df-b112-00215aee3ebe;Тартаків;Тартаков;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624887401;3;1
80f55bba-749b-11df-b112-00215aee3ebe;Тартачок;Тартачок;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825483603;3;1
80f55bbb-749b-11df-b112-00215aee3ebe;Тарутине;Тарутино;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922086003;3;1
80f55bbc-749b-11df-b112-00215aee3ebe;Тарутине;Тарутино;місто;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124755100;3;1
80f55bbd-749b-11df-b112-00215aee3ebe;Тасуїв;Тасуев;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420385004;3;1
80f55bbf-749b-11df-b112-00215aee3ebe;Татарбунари;Татарбунары;місто;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125010100;3;0
80f55bc1-749b-11df-b112-00215aee3ebe;Татаринів;Татаринов;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620987801;3;1
80f55bc2-749b-11df-b112-00215aee3ebe;Татаринці;Татаринцы;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123880303;3;1
fdf0a12d-bf55-11e0-9095-003048d2b473;Татарів;татаров;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;;3;1
c4122e07-9729-11e8-80d4-1c98ec135261;Татарів;Татарів;село;a1e9f9cf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2611091201;3;1
80f55bc3-749b-11df-b112-00215aee3ebe;Татарівка;Татаровка;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420687203;3;1
80f55bc4-749b-11df-b112-00215aee3ebe;Таужне;Таужное;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521186001;3;1
80f55bc5-749b-11df-b112-00215aee3ebe;Таурів;Тауров;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123087401;3;1
80f55bc6-749b-11df-b112-00215aee3ebe;Тахтаулове;Тахтаулово;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324085701;3;1
80f55bc7-749b-11df-b112-00215aee3ebe;Таценки;Таценки;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3211690002;3;1
80f55bc9-749b-11df-b112-00215aee3ebe;Ташань;Ташань;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223387401;3;1
80f55bca-749b-11df-b112-00215aee3ebe;Ташине;Ташино;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820985001;3;1
80f55bcb-749b-11df-b112-00215aee3ebe;Ташки;Ташки;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823989203;3;1
80f55bcc-749b-11df-b112-00215aee3ebe;Ташлик;Ташлык;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123789001;3;1
80f55bcd-749b-11df-b112-00215aee3ebe;Ташлик;Ташлык;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521187603;3;1
80f55bcf-749b-11df-b112-00215aee3ebe;Тварі;Твари;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625155702;3;1
80f55bd0-749b-11df-b112-00215aee3ebe;Твердині;Твердыни;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722483203;3;1
80f55bd1-749b-11df-b112-00215aee3ebe;Твердомедове;Твердомедово;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520955102;3;1
80f55bd2-749b-11df-b112-00215aee3ebe;Твердохліби;Твердохлебы;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081309;3;1
80f55bd3-749b-11df-b112-00215aee3ebe;Твердохліби;Твердохлебы;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321881704;3;1
ddbf9962-6e62-11ee-80c1-000c2961d091;Твердохліби;Твердохлебы;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081309;3;1
80f55bd4-749b-11df-b112-00215aee3ebe;Твіржа;Твиржа;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622486901;3;1
80f55bd5-749b-11df-b112-00215aee3ebe;Твориничі;Твориничи;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722483405;3;1
80f55bd6-749b-11df-b112-00215aee3ebe;Тевеліївка;Тевелиевка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821484603;3;1
80f55bd8-749b-11df-b112-00215aee3ebe;Тейсарів;Тейсаров;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621585910;3;1
80f55bd9-749b-11df-b112-00215aee3ebe;Теклине;Теклино;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721482203;3;1
80f55bda-749b-11df-b112-00215aee3ebe;Теклине;Теклино;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123780502;3;1
80f55bdb-749b-11df-b112-00215aee3ebe;Теклинівка;Теклиновка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522286102;3;1
80f55bdc-749b-11df-b112-00215aee3ebe;Теклівка;Текловка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124684603;3;1
80f55bdd-749b-11df-b112-00215aee3ebe;Теклівка;Текловка;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121682703;3;1
80f55bde-749b-11df-b112-00215aee3ebe;Теклівка;Текловка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287906;3;1
80f55bdf-749b-11df-b112-00215aee3ebe;Теклівка;Текловка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525384802;3;1
80f55be0-749b-11df-b112-00215aee3ebe;Теклівка;Текловка;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521986002;3;1
80f55be1-749b-11df-b112-00215aee3ebe;Теклівка;Текловка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822485703;2;1
80f55be2-749b-11df-b112-00215aee3ebe;Текля;Текля;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725080802;3;1
80f55be3-749b-11df-b112-00215aee3ebe;Теклянівка;Текляновка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825084206;3;1
80f55be4-749b-11df-b112-00215aee3ebe;Теково;Теково;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121285001;3;1
80f55be5-749b-11df-b112-00215aee3ebe;Текуча;Текуча;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124388601;3;1
80f55be6-749b-11df-b112-00215aee3ebe;Текуче;Текучее;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623687401;3;1
80f55be7-749b-11df-b112-00215aee3ebe;Телелинці;Телелинцы;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521086803;3;1
80f55be8-749b-11df-b112-00215aee3ebe;Телелинці;Телелинци;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521086803;3;1
80f55be9-749b-11df-b112-00215aee3ebe;Телепине;Телепино;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121886501;3;1
80f55bea-749b-11df-b112-00215aee3ebe;Телешівка;Телешовка;село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223786501;3;1
80f55beb-749b-11df-b112-00215aee3ebe;Теліжинці;Тележинцы;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224687801;3;1
80f55bec-749b-11df-b112-00215aee3ebe;Теліжинці;Тележинцы;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822187201;3;1
80f55bed-749b-11df-b112-00215aee3ebe;Теліжинці;Тележинцы;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824455103;3;1
80f55bee-749b-11df-b112-00215aee3ebe;Теляж;Теляж;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624883101;3;1
80f55bf0-749b-11df-b112-00215aee3ebe;Тельмана;Тельмана;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520382002;3;1
33ec0020-ddd5-11df-9197-00215aee3ebe;Тельмана;Тельмана;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522286103;3;1
80f55bf2-749b-11df-b112-00215aee3ebe;Тельне;Тельное;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422488704;3;1
80f55bf3-749b-11df-b112-00215aee3ebe;Тельчі;Тельчи;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723683505;3;1
80f55bf5-749b-11df-b112-00215aee3ebe;Темирівці;Темировцы;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621280305;3;1
80f55bf6-749b-11df-b112-00215aee3ebe;Темне;Темное;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920984604;3;1
80f55bf7-749b-11df-b112-00215aee3ebe;Темнівка;Темновка;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325181503;3;1
80f55bf8-749b-11df-b112-00215aee3ebe;Темногайці;Темногайцы;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125888101;3;1
80f55bfa-749b-11df-b112-00215aee3ebe;Тенетники;Тенетники;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621287001;3;1
80f55bfb-749b-11df-b112-00215aee3ebe;Теньківка;Теньковка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825485101;3;1
80f55bfc-749b-11df-b112-00215aee3ebe;Теолин;Теолин;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123486801;3;1
80f55bfd-749b-11df-b112-00215aee3ebe;Теофілівка;Теофиловка;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125287407;3;1
80f55bfe-749b-11df-b112-00215aee3ebe;Теофілівка;Теофиловка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520487509;3;1
80f55bff-749b-11df-b112-00215aee3ebe;Теофіпілка;Теофипилка;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123087801;3;1
80f55c00-749b-11df-b112-00215aee3ebe;Теофіполь;Теофиполь;місто;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824755100;2;0
80f55c01-749b-11df-b112-00215aee3ebe;Тепениця;Тепеница;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824487201;3;1
80f55c02-749b-11df-b112-00215aee3ebe;Теперівка;Теперовка;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821588803;3;1
80f55c05-749b-11df-b112-00215aee3ebe;Тепле;Теплое;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320481703;3;1
80f55c06-749b-11df-b112-00215aee3ebe;Теплий Яр;Теплый Яр;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;;3;1
80f55c07-749b-11df-b112-00215aee3ebe;Теплик;Теплик;місто;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523755100;3;1
80f55c08-749b-11df-b112-00215aee3ebe;Теплиця;Теплица;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120485901;3;1
80f55c09-749b-11df-b112-00215aee3ebe;Тепличне;Тепличное;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2310167500;2;1
80f55c0b-749b-11df-b112-00215aee3ebe;Теплівка;Тепловка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222082504;3;1
80f55c0d-749b-11df-b112-00215aee3ebe;Теплівка;Тепловка;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323885801;3;1
80f55c0f-749b-11df-b112-00215aee3ebe;Теплодар;Теплодар;місто;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5111500000;2;0
80f55c10-749b-11df-b112-00215aee3ebe;Теплянка;Теплянка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320286207;3;1
80f55c11-749b-11df-b112-00215aee3ebe;Тептіївка;Тептиевка;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220687301;3;1
80f55c12-749b-11df-b112-00215aee3ebe;Теребежі;Теребежи;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620655602;3;1
80f55c13-749b-11df-b112-00215aee3ebe;Теребежі;Теребежи;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620388807;3;1
80f55c14-749b-11df-b112-00215aee3ebe;Тереблече;Тереблече;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321085401;3;1
80f55c15-749b-11df-b112-00215aee3ebe;Теребля;Теребля;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124487001;3;1
80f55c16-749b-11df-b112-00215aee3ebe;Теребовичі;Теребовичи;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724286909;3;1
80f55c17-749b-11df-b112-00215aee3ebe;Теребовля;Теребовля;місто;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125010100;2;0
80f55c18-749b-11df-b112-00215aee3ebe;Терезине;Терезино;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220455500;2;0
80f55c19-749b-11df-b112-00215aee3ebe;Теремецьке;Теремецкое;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323680802;3;1
a5388ebe-db65-11df-9197-00215aee3ebe;Теремківці;Теремковцы;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825289802;2;1
80f55c1a-749b-11df-b112-00215aee3ebe;Теремне;Теремное;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624286003;3;1
80f55c1b-749b-11df-b112-00215aee3ebe;Терентіїв;Терентиев;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621287802;3;1
c8674a9f-dbb0-11df-9197-00215aee3ebe;Терентіївка;Терентиевка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324084010;3;1
80f55c1c-749b-11df-b112-00215aee3ebe;Теренці;Теренцы;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821181304;3;1
80f55c1d-749b-11df-b112-00215aee3ebe;Тересва;Тересва;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124456200;2;0
80f55c1e-749b-11df-b112-00215aee3ebe;Терехи;Терехи;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723387205;3;1
80f55c1f-749b-11df-b112-00215aee3ebe;Терехівка;Тереховка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425588701;3;1
80f55c20-749b-11df-b112-00215aee3ebe;Терехове;Терехово;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922683002;3;1
80f55c21-749b-11df-b112-00215aee3ebe;Терехове;Терехово;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820886801;3;1
80f55c22-749b-11df-b112-00215aee3ebe;Терешиха;Терешиха;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420387506;3;1
80f55c23-749b-11df-b112-00215aee3ebe;Терешів;Терешов;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623884003;3;1
80f55c24-749b-11df-b112-00215aee3ebe;Терешівці;Терешевцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825088701;3;1
80f55c25-749b-11df-b112-00215aee3ebe;Терешки;Терешки;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324085901;3;0
80f55c26-749b-11df-b112-00215aee3ebe;Терешки;Терешки;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923581310;3;1
80f55c27-749b-11df-b112-00215aee3ebe;Терешки;Терешки;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224087802;3;1
80f55c28-749b-11df-b112-00215aee3ebe;Терешки;Терешки;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125788001;3;1
80f55c29-749b-11df-b112-00215aee3ebe;Терешки;Терешки;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520285003;3;1
80f55c2a-749b-11df-b112-00215aee3ebe;Терешки;Терешки;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822788801;3;1
80f55c2b-749b-11df-b112-00215aee3ebe;Терешківка;Терешковка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924787901;3;1
80f55c2c-749b-11df-b112-00215aee3ebe;Терешківка;Терешковка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423389201;3;1
80f55c2d-749b-11df-b112-00215aee3ebe;Терешківці;Терешковцы;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720887701;3;1
80f55c2e-749b-11df-b112-00215aee3ebe;Терешпіль;Терешполь;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524887201;3;1
80f55c2f-749b-11df-b112-00215aee3ebe;Терещенки;Терещенки;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322281907;3;1
80f55c30-749b-11df-b112-00215aee3ebe;Терещенки;Терещенки;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920688601;3;1
80f55c31-749b-11df-b112-00215aee3ebe;Терлещина;Терлещина;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221980303;3;1
80f55c32-749b-11df-b112-00215aee3ebe;Терлиця;Терлица;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123487001;3;1
80f55c33-749b-11df-b112-00215aee3ebe;Терлівка;Терловка;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823084604;3;1
80f55c34-749b-11df-b112-00215aee3ebe;Терло;Терло;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625186101;3;1
80f55c36-749b-11df-b112-00215aee3ebe;Термахівка;Термаховка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222085601;3;1
80f55c37-749b-11df-b112-00215aee3ebe;Тернава;Тернава;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625186301;3;1
80f55c38-749b-11df-b112-00215aee3ebe;Тернавка;Тернавка;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320785001;3;1
80f55c39-749b-11df-b112-00215aee3ebe;Тернавка;Тернавка;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621589203;3;1
80f55c3a-749b-11df-b112-00215aee3ebe;Тернавка;Тернавка;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624584002;3;1
80f55c3b-749b-11df-b112-00215aee3ebe;Тернавка;Тернавка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822187501;3;1
80f55c3c-749b-11df-b112-00215aee3ebe;Тернавка;Тернавка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822488504;3;1
80f55c3d-749b-11df-b112-00215aee3ebe;Терни;Терны;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322283705;3;1
80f55c3e-749b-11df-b112-00215aee3ebe;Терни;Терны;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923555600;3;1
80f55c3f-749b-11df-b112-00215aee3ebe;Терни;Терны;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955121;3;1
80f55c42-749b-11df-b112-00215aee3ebe;Терни;Терны;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322884905;3;1
80f55c43-749b-11df-b112-00215aee3ebe;Тернів;Тернов;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622785904;3;1
80f55c44-749b-11df-b112-00215aee3ebe;Тернівка;Терновка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922088101;3;1
80f55c45-749b-11df-b112-00215aee3ebe;Тернівка;Терновка;місто;fbfb8c1d-41be-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1213500000;2;0
80f55c46-749b-11df-b112-00215aee3ebe;Тернівка;Терновка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221882207;3;1
80f55c47-749b-11df-b112-00215aee3ebe;Тернівка;Терновка;село;5394b334-41be-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;2;1
80f55c48-749b-11df-b112-00215aee3ebe;Тернівка;Терновка;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821183006;3;1
80f55c49-749b-11df-b112-00215aee3ebe;Тернівка;Терновка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321587301;3;1
80f55c4a-749b-11df-b112-00215aee3ebe;Тернівка;Терновка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323686612;3;1
80f55c4b-749b-11df-b112-00215aee3ebe;Тернівка;Терновка;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623382905;3;1
80f55c4f-749b-11df-b112-00215aee3ebe;Тернівка;Терновка;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222285203;3;1
80f55c50-749b-11df-b112-00215aee3ebe;Тернівка;Терновка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824085901;3;1
80f55c51-749b-11df-b112-00215aee3ebe;Тернівка;Терновка;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123789201;3;1
80f55c52-749b-11df-b112-00215aee3ebe;Тернівка;Терновка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521487501;3;1
80f55c53-749b-11df-b112-00215aee3ebe;Тернівка;Терновка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520485203;3;1
80f55c54-749b-11df-b112-00215aee3ebe;Тернівка;Терновка;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521986401;3;1
80f55c55-749b-11df-b112-00215aee3ebe;Тернівка;Терновка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523687001;3;1
80f55c56-749b-11df-b112-00215aee3ebe;Тернівщина;Терновщина;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324082407;3;1
80f55c57-749b-11df-b112-00215aee3ebe;Тернівщина;Терновщина;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322881207;3;1
80f55c58-749b-11df-b112-00215aee3ebe;Тернова;Терновая;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322081005;3;1
80f55c59-749b-11df-b112-00215aee3ebe;Тернова;Терновая;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325184501;3;1
80f55c5a-749b-11df-b112-00215aee3ebe;Тернова;Терновая;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325455304;3;1
80f55c5b-749b-11df-b112-00215aee3ebe;Тернова;Терновая;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821888701;3;1
80f55c5c-749b-11df-b112-00215aee3ebe;Тернова Балка;Терновая Балка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522883003;3;1
80f55c5d-749b-11df-b112-00215aee3ebe;Тернове;Терновое;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224882007;3;1
80f55c5e-749b-11df-b112-00215aee3ebe;Тернове;Терновое;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823684204;3;1
80f55c65-749b-11df-b112-00215aee3ebe;Тернове;Терновое;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521785701;3;1
80f55c66-749b-11df-b112-00215aee3ebe;Терновиця;Терновица;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625883203;3;1
9100f8c9-d973-11ee-80c4-000c2961d091;Терновиця;Терновиця;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625888604;3;1
80f55c67-749b-11df-b112-00215aee3ebe;Тернові Поди;Терновые Поды;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825782205;3;1
80f55c62-749b-11df-b112-00215aee3ebe;Терново;Терново;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124487401;3;1
80f55c68-749b-11df-b112-00215aee3ebe;Терно-Лозуватка;Терно-Лозоватка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224555307;3;1
80f55c69-749b-11df-b112-00215aee3ebe;Тернопілля;Тернополье;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623088417;3;1
80f55c6a-749b-11df-b112-00215aee3ebe;Тернопіль;Тернополь;місто;bad3ac18-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6110100000;1;0
80f55c6b-749b-11df-b112-00215aee3ebe;Тернувате;Терноватое;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224586003;3;1
80f55c6c-749b-11df-b112-00215aee3ebe;Тернувате;Терноватое;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823981004;3;1
80f55c6d-749b-11df-b112-00215aee3ebe;Тернувате;Терноватое;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824255602;3;1
80f55c6e-749b-11df-b112-00215aee3ebe;Тернувате;Терноватое;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323655500;3;1
80f55c70-749b-11df-b112-00215aee3ebe;Тернуватий Кут;Терноватый Кут;село;4ffc4b7d-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1211090009;3;1
80f55c71-749b-11df-b112-00215aee3ebe;Тернуватка;Терноватка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221884503;3;1
80f55c72-749b-11df-b112-00215aee3ebe;Тернуватка;Терноватка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225283318;3;1
80f55c73-749b-11df-b112-00215aee3ebe;Тернуватка;Терноватка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324885503;3;1
80f55c74-749b-11df-b112-00215aee3ebe;Терпилівка;Терпиловка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124688201;3;1
80f55c76-749b-11df-b112-00215aee3ebe;Терса;Терса;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224884906;3;1
80f55c77-749b-11df-b112-00215aee3ebe;Терсянка;Терсянка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321587004;3;1
80f55c78-749b-11df-b112-00215aee3ebe;Терсянка;Терсянка;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323686601;3;1
80f55c79-749b-11df-b112-00215aee3ebe;Тертишники;Тертышники;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423886501;3;1
6e14de66-c9ac-11e1-973a-003048d2b473;Тертки ;Тертки ;;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881504;3;1
80f55c7a-749b-11df-b112-00215aee3ebe;Тершаків;Тершаков;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620985204;3;1
80f55c7b-749b-11df-b112-00215aee3ebe;Тершів;Тершев;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625186601;3;1
80f55c7c-749b-11df-b112-00215aee3ebe;Теси;Тесы;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522486801;3;1
80f55c7d-749b-11df-b112-00215aee3ebe;Тесів;Тесов;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624287601;3;1
80f55c7e-749b-11df-b112-00215aee3ebe;Теслугів;Теслугов;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625888501;3;1
80f55c7f-749b-11df-b112-00215aee3ebe;Тесницька;Тесницкая;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323585004;3;1
80f55c80-749b-11df-b112-00215aee3ebe;Теснівка;Тесновка;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822882402;3;1
80f55c81-749b-11df-b112-00215aee3ebe;Теснівка;Тесновка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824083803;3;1
80f55c82-749b-11df-b112-00215aee3ebe;Теснівка;Тесновка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510103;3;1
80f55c83-749b-11df-b112-00215aee3ebe;Тетевчиці;Тетевчицы;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623986601;3;1
80f55c84-749b-11df-b112-00215aee3ebe;Тетерівка;Тетеревка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822087201;1;0
80f55c85-749b-11df-b112-00215aee3ebe;Тетерівка;Тетеревка;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120988101;3;1
80f55c86-749b-11df-b112-00215aee3ebe;Тетерівське;Тетеревское;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222086001;3;1
80f55c87-749b-11df-b112-00215aee3ebe;Тетильківці;Тетильковцы;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125882403;3;1
80f55c88-749b-11df-b112-00215aee3ebe;Тетильківці;Тетильковцы;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620383604;3;1
80f55c89-749b-11df-b112-00215aee3ebe;Тетіїв;Тетиев;місто;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224610100;2;0
80f55c8a-749b-11df-b112-00215aee3ebe;Тетірка;Тетерка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825485201;3;1
80f55c8b-749b-11df-b112-00215aee3ebe;Тетлега;Тетлега;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325483302;3;1
80f55c8c-749b-11df-b112-00215aee3ebe;Тетющине;Тетющино;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321280507;3;1
80f55c8d-749b-11df-b112-00215aee3ebe;Тетянівка;Татьяновка;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821184203;3;1
80f55c8e-749b-11df-b112-00215aee3ebe;Тетянівка;Татьяновка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325784506;3;1
80f55c92-749b-11df-b112-00215aee3ebe;Тибава;Тибава;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124087201;3;1
80f55c93-749b-11df-b112-00215aee3ebe;Тиврів;Тывров;місто;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524555100;3;0
80f55c94-749b-11df-b112-00215aee3ebe;Тийглаш;Тыйглаш;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124886901;3;1
87162625-749b-11df-b112-00215aee3ebe;Тилігульське (Червона Нива);Тилигульское (Червоная Нива);село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122781703;3;1
80f55c96-749b-11df-b112-00215aee3ebe;Тилявка;Тылявка;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125888401;3;1
80f55c97-749b-11df-b112-00215aee3ebe;Тиманівка;Тимановка;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925386801;3;1
80f55c98-749b-11df-b112-00215aee3ebe;Тиманівка;Тимановка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524386201;3;1
80f55c99-749b-11df-b112-00215aee3ebe;Тимар;Тымар;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520882009;3;1
c8674a9b-dbb0-11df-9197-00215aee3ebe;Тимки;Тимки;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323682603;3;1
80f55c9b-749b-11df-b112-00215aee3ebe;Тимків;Тимков;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823387004;3;1
80f55c9c-749b-11df-b112-00215aee3ebe;Тимкове;Тимково;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122585001;3;1
80f55c9e-749b-11df-b112-00215aee3ebe;Тимоновичі;Тимоновичи;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424786501;3;1
80f55c9f-749b-11df-b112-00215aee3ebe;Тимофіївка;Тимофеевка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920983607;3;1
80f55ca0-749b-11df-b112-00215aee3ebe;Тимофіївка;Тимофеевка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821480806;3;1
80f55ca1-749b-11df-b112-00215aee3ebe;Тимофіївка;Тимофеевка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320883503;3;1
80f55ca2-749b-11df-b112-00215aee3ebe;Тимофіївка;Тимофеевка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322680504;3;1
80f55ca3-749b-11df-b112-00215aee3ebe;Тимофіївка;Тимофеевка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320681006;3;1
80f55ca4-749b-11df-b112-00215aee3ebe;Тимофіївка;Тимофеевка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125485606;3;1
80f55ca9-749b-11df-b112-00215aee3ebe;Тимофіївка;Тимофеевка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523682907;3;1
80f55caa-749b-11df-b112-00215aee3ebe;Тимофіївка;Тимофеевка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320485306;3;1
80f55cad-749b-11df-b112-00215aee3ebe;Тимошівка;Тимошовка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320681702;3;1
80f55cae-749b-11df-b112-00215aee3ebe;Тимошівка;Тимошовка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323987813;3;1
80f55cb0-749b-11df-b112-00215aee3ebe;Тимошівка;Тимошовка;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123182503;3;1
80f55cb1-749b-11df-b112-00215aee3ebe;Тимошівка;Тимошовка;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121886701;3;1
80f55cb2-749b-11df-b112-00215aee3ebe;Тимощенкове;Тимошенково;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923583411;3;1
80f55cb3-749b-11df-b112-00215aee3ebe;Тимченки;Тимченки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321385307;3;1
80f55cb4-749b-11df-b112-00215aee3ebe;Тимченки;Тимченки;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923582906;3;1
80f55cb5-749b-11df-b112-00215aee3ebe;Тимченки;Тимченки;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321786201;3;1
80f55cb6-749b-11df-b112-00215aee3ebe;Тимченки;Тимченки;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324585006;3;1
80f55cb7-749b-11df-b112-00215aee3ebe;Тимченки;Тимченки;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125189201;3;1
80f55cb8-749b-11df-b112-00215aee3ebe;Тимченківка;Тимченковка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323086604;3;1
80f55cb9-749b-11df-b112-00215aee3ebe;Тиниця;Тиница;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420388501;3;1
80f55cba-749b-11df-b112-00215aee3ebe;Тинів;Тынив;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621282407;3;1
80f55cbb-749b-11df-b112-00215aee3ebe;Тинівка;Тыновка;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120988801;3;1
80f55cbc-749b-11df-b112-00215aee3ebe;Тинна;Тынная;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821889201;3;1
80f55cbd-749b-11df-b112-00215aee3ebe;Тинне;Тынное;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625488001;3;1
80f55cbe-749b-11df-b112-00215aee3ebe;Тиранівка;Тирановка;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825088402;3;1
80f55cbf-749b-11df-b112-00215aee3ebe;Тирлівка;Тырловка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520487502;3;1
80f55c35-749b-11df-b112-00215aee3ebe;Тирлова Балка;Тирлова Балка;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525882303;3;1
80f55cc0-749b-11df-b112-00215aee3ebe;Тисаагтелек;Тисаагтелек;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124887001;3;1
80f55cc1-749b-11df-b112-00215aee3ebe;Тисаашвань;Тисаашвань;село;fbfb8c2b-41be-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124884201;3;1
80f55cc9-749b-11df-b112-00215aee3ebe;Тисалово;Тисалово;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124485302;3;1
80f55cc2-749b-11df-b112-00215aee3ebe;Тисауйфалу;Тисауйфалу;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124884203;3;1
80f55cc3-749b-11df-b112-00215aee3ebe;Тисів;Тисов;село;8067acf2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2610292001;3;1
80f55cc4-749b-11df-b112-00215aee3ebe;Тисмениця;Тисменица;місто;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625810100;2;0
80f55cc5-749b-11df-b112-00215aee3ebe;Тисменичани;Тысменичаны;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624086801;3;1
73e94bf0-ddc1-11df-9197-00215aee3ebe;Тисобикень;Тисобикень;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121284102;3;1
44ac84a0-749b-11df-b112-00215aee3ebe;Тисобикень (Бобове);Тисобикень (Бобовое);село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;a73a04a6-0835-11e4-a577-003048d2b473;2121284102;3;1
80f55cc6-749b-11df-b112-00215aee3ebe;Тисовець;Тисовец;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7324589701;3;1
80f55cc7-749b-11df-b112-00215aee3ebe;Тисовець;Тисовец;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624583003;3;1
80f55cc8-749b-11df-b112-00215aee3ebe;Тисовиця;Тисовица;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625186801;3;1
80f55ccb-749b-11df-b112-00215aee3ebe;Титівка;Титовка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423381907;3;1
80f55ccd-749b-11df-b112-00215aee3ebe;Титусівка;Титусовка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521487206;3;1
80f55cce-749b-11df-b112-00215aee3ebe;Титьків;Титьков;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823685002;3;1
80f55ccf-749b-11df-b112-00215aee3ebe;Тиха;Тихая;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625180909;3;1
80f55cd0-749b-11df-b112-00215aee3ebe;Тихе;Тихое;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225080519;3;1
80f55cd1-749b-11df-b112-00215aee3ebe;Тихе;Тихое;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224280502;3;1
80f55cd3-749b-11df-b112-00215aee3ebe;Тихе;Тихое;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623485404;3;1
5cb61734-749b-11df-b112-00215aee3ebe;Тихе (Кіровське);Тихое (Кировское);село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755139;3;1
87162593-749b-11df-b112-00215aee3ebe;Тихе (Чапаєве);Тихое (Чапаево);село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121082809;3;1
80f55cd4-749b-11df-b112-00215aee3ebe;Тихеньке;Тихонькое;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
80f55cd5-749b-11df-b112-00215aee3ebe;Тихий;Тихий;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120886401;3;1
80f55cd6-749b-11df-b112-00215aee3ebe;Тихий Став;Тихий Став;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225884815;3;1
80f55cd7-749b-11df-b112-00215aee3ebe;Тихий Хутір;Тихий Хутор;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120988501;3;1
80f55cd8-749b-11df-b112-00215aee3ebe;Тихі Верби;Тихие Вербы;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120383002;3;1
80f55cd9-749b-11df-b112-00215aee3ebe;Тиховиж;Тиховиж;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622285307;3;1
80f55cda-749b-11df-b112-00215aee3ebe;Тихомель;Тихомель;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820380503;3;1
80f55cde-749b-11df-b112-00215aee3ebe;Тихонівка;Тихоновка;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122887001;3;1
80f55cdf-749b-11df-b112-00215aee3ebe;Тихонівське;Тихоновское;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422484506;3;1
80f55ce0-749b-11df-b112-00215aee3ebe;Тихоновичі;Тихоновичи;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425888401;3;1
80f55ce1-749b-11df-b112-00215aee3ebe;Тихопілля;Тихополье;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323987001;3;1
80f55ce2-749b-11df-b112-00215aee3ebe;Тихотин;Тихотин;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724587001;3;1
80f55ce3-749b-11df-b112-00215aee3ebe;Тичків;Тычков;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755161;3;1
80f55ce4-749b-11df-b112-00215aee3ebe;Тичок;Тычок;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622185605;3;1
80f55ce5-749b-11df-b112-00215aee3ebe;Тишевичі;Тышевичи;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822188101;3;1
80f55ce6-749b-11df-b112-00215aee3ebe;Тишенківка;Тишенковка;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321685606;3;1
80f55ce7-749b-11df-b112-00215aee3ebe;Тишенківка;Тишенковка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323382002;3;1
80f55ce8-749b-11df-b112-00215aee3ebe;Тишиця;Тышица;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622185923;3;1
80f55ce9-749b-11df-b112-00215aee3ebe;Тишиця;Тышица;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620488601;3;1
80f55cea-749b-11df-b112-00215aee3ebe;Тишів;Тишев;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121586001;3;1
80f55ceb-749b-11df-b112-00215aee3ebe;Тишів;Тишев;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823488604;3;1
80f55cec-749b-11df-b112-00215aee3ebe;Тишівниця;Тышивница;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624584502;3;1
80f55ced-749b-11df-b112-00215aee3ebe;Тишки;Тишки;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322887401;3;1
80f55cef-749b-11df-b112-00215aee3ebe;Тишківка;Тишковка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421788405;3;1
80f55cf0-749b-11df-b112-00215aee3ebe;Тишківка;Тишковка;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520884809;3;1
80f55cf1-749b-11df-b112-00215aee3ebe;Тишківка;Тишковка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523887301;3;1
80f55cf2-749b-11df-b112-00215aee3ebe;Тишківка;Тишковка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521786001;3;1
80f55cf3-749b-11df-b112-00215aee3ebe;Тишківська Слобода;Тишковская Слобода;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520884812;3;1
80f55cf4-749b-11df-b112-00215aee3ebe;Тишківці;Тышковцы;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621686201;3;1
80f55cf5-749b-11df-b112-00215aee3ebe;Тишковичі;Тишковичи;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721181204;3;1
80f55cf6-749b-11df-b112-00215aee3ebe;Тишковичі;Тишковичи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622489005;3;1
80f55cf7-749b-11df-b112-00215aee3ebe;Тищенки;Тищенки;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755154;3;1
80f55cf9-749b-11df-b112-00215aee3ebe;Тік;Ток;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220384403;3;1
80f55cfd-749b-11df-b112-00215aee3ebe;Тімірязєвка;Тимерязевка;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083819;3;1
80f55cfc-749b-11df-b112-00215aee3ebe;Тімірязівка;Тимирязевка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920655313;3;1
80f55cff-749b-11df-b112-00215aee3ebe;Тіньки;Тиньки;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125486501;3;1
80f55d00-749b-11df-b112-00215aee3ebe;Тітківці;Титковцы;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122485603;3;1
80f55d01-749b-11df-b112-00215aee3ebe;Ткаченка;Ткаченко;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123181704;3;1
80f55d02-749b-11df-b112-00215aee3ebe;Тлумач;Тлумач;місто;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625610100;2;0
80f55d03-749b-11df-b112-00215aee3ebe;Тоболи;Тоболы;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721487401;3;1
80f55d04-749b-11df-b112-00215aee3ebe;Тоболів;Тоболов;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623986203;3;1
80f55d05-749b-11df-b112-00215aee3ebe;Товариський Труд;Товарищеский Труд;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055412;3;1
17af7b30-ddb9-11df-9197-00215aee3ebe;Товариський Труд;Товарищеский Труд;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;;3;1
80f55d06-749b-11df-b112-00215aee3ebe;Товарна;Товарная;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625183003;3;1
80f55d07-749b-11df-b112-00215aee3ebe;Товарниця;Товарница;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323584003;3;1
80f55d08-749b-11df-b112-00215aee3ebe;Товкачі;Товкачи;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824281907;3;1
80f55d09-749b-11df-b112-00215aee3ebe;Товкачі;Товкачи;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;;3;1
80f55d0a-749b-11df-b112-00215aee3ebe;Товкачівка;Толкачовка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424189101;3;1
80f55d0b-749b-11df-b112-00215aee3ebe;Товмач;Толмач;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622182607;3;1
80f55d0c-749b-11df-b112-00215aee3ebe;Товмач;Толмач;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125788401;3;1
80f55d0d-749b-11df-b112-00215aee3ebe;Товмачик;Товмачик;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623287201;3;1
80f55d0e-749b-11df-b112-00215aee3ebe;Товпижин;Товпыжин;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621481006;3;1
80f55d0f-749b-11df-b112-00215aee3ebe;Товста;Товстая;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920688901;3;1
80f55d10-749b-11df-b112-00215aee3ebe;Товста;Товстая;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120387501;3;1
80f55d11-749b-11df-b112-00215aee3ebe;Товсте;Товстое;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755105;3;1
80f55d12-749b-11df-b112-00215aee3ebe;Товсте;Товстое;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324555115;3;1
80f55d13-749b-11df-b112-00215aee3ebe;Товсте;Товстое;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923285608;3;1
80f55d14-749b-11df-b112-00215aee3ebe;Товсте;Товстое;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121688001;3;1
80f55d15-749b-11df-b112-00215aee3ebe;Товсте;Товстое;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122055500;3;1
80f55d16-749b-11df-b112-00215aee3ebe;Товсте;Товстое;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223880507;3;1
80f55d17-749b-11df-b112-00215aee3ebe;Товсте;Товстое;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825088304;3;1
80f55d18-749b-11df-b112-00215aee3ebe;Товстеньке;Товстенькое;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125587701;3;1
80f55d19-749b-11df-b112-00215aee3ebe;Товсті Роги;Товстые Роги;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122880402;3;1
80f55d1a-749b-11df-b112-00215aee3ebe;Товстодубове;Товстодубово;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921580702;3;1
80f55d1b-749b-11df-b112-00215aee3ebe;Товстоліс;Товстолес;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425588704;3;1
80f55d1c-749b-11df-b112-00215aee3ebe;Товстолуг;Товстолуг;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125287801;2;1
80f55d1d-749b-11df-b112-00215aee3ebe;Товтри;Товтры;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321588801;3;1
80f55d1e-749b-11df-b112-00215aee3ebe;Товченикове;Толвчениково;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923880406;3;1
80f55d1f-749b-11df-b112-00215aee3ebe;Товща;Товща;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821455302;3;1
80f55d20-749b-11df-b112-00215aee3ebe;Товщів;Товщев;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623681809;3;1
80f55d21-749b-11df-b112-00215aee3ebe;Тойкут;Тойкут;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722182801;3;1
80f55d22-749b-11df-b112-00215aee3ebe;Токареве;Токарево;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520980305;3;1
80f55d24-749b-11df-b112-00215aee3ebe;Токарі;Токари;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5910590003;3;1
80f55d25-749b-11df-b112-00215aee3ebe;Токарі;Токари;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924788301;3;1
80f55d26-749b-11df-b112-00215aee3ebe;Токарі;Токари;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322687201;3;1
80f55d27-749b-11df-b112-00215aee3ebe;Токарів;Токарев;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824086001;3;1
80f55d28-749b-11df-b112-00215aee3ebe;Токарівка;Токаревка;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823981005;3;1
80f55d29-749b-11df-b112-00215aee3ebe;Токарівка;Токаревка;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322082501;3;1
80f55d2b-749b-11df-b112-00215aee3ebe;Токарівка;Токаревка;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520387001;3;1
80f55d2c-749b-11df-b112-00215aee3ebe;Токарівка;Токаревка;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224088602;3;1
80f55d2d-749b-11df-b112-00215aee3ebe;Токарівка;Токаревка;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521087509;3;1
187e2f68-eb72-11e1-a474-003048d2b473;Токарівка;Токаривка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322082501;3;1
62c3cef6-749b-11df-b112-00215aee3ebe;Токарівка (Кудрявцівка);Токарівка (Кудрявцівка);село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821755103;3;1
80f55d2e-749b-11df-b112-00215aee3ebe;Токарівка Друга;Токаревка Вторая;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322082509;3;1
80f55d2f-749b-11df-b112-00215aee3ebe;Токи;Токи;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124688601;3;1
80f55d30-749b-11df-b112-00215aee3ebe;Токівське;Токовское;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220384405;3;1
80f55d32-749b-11df-b112-00215aee3ebe;Токове;Токовое;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224883005;3;1
80f55d33-749b-11df-b112-00215aee3ebe;Толокунь;Толокунь;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221888601;3;1
80f55d35-749b-11df-b112-00215aee3ebe;Томаківка;Томаковка;місто;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221083309;3;1
80f55d36-749b-11df-b112-00215aee3ebe;Томаківка;Томаковка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225083516;3;1
80f55d37-749b-11df-b112-00215aee3ebe;Томаківка;Томаковка;місто;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455100;3;0
80f55d38-749b-11df-b112-00215aee3ebe;Томаківське;Томаковское;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222982812;3;1
80f55d39-749b-11df-b112-00215aee3ebe;Томарине;Томарино;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520686901;3;1
80f55d3a-749b-11df-b112-00215aee3ebe;Томахів;Томахов;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621282006;3;1
80f55d3c-749b-11df-b112-00215aee3ebe;Томашгород;Томашгород;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625055500;3;1
c8674aa9-dbb0-11df-9197-00215aee3ebe;Томашгород Перший;Томашгород Первый;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;;3;1
80f55d3d-749b-11df-b112-00215aee3ebe;Томашгород село;Томашгород село;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625087401;3;1
80f55d3e-749b-11df-b112-00215aee3ebe;Томашівка;Томашовка;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923586201;3;1
80f55d40-749b-11df-b112-00215aee3ebe;Томашівка;Томашовка;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224986201;3;1
80f55d41-749b-11df-b112-00215aee3ebe;Томашівка;Томашовка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821186505;3;1
80f55d42-749b-11df-b112-00215aee3ebe;Томашівка;Томашовка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421780403;3;1
80f55d43-749b-11df-b112-00215aee3ebe;Томашівка;Томашовка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124388901;3;1
80f55d44-749b-11df-b112-00215aee3ebe;Томашівка;Томашовка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825888801;3;1
80f55d45-749b-11df-b112-00215aee3ebe;Томашівка;Томашовка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821888901;3;1
80f55d46-749b-11df-b112-00215aee3ebe;Томашівці;Томашовцы;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622887801;3;1
80f55d47-749b-11df-b112-00215aee3ebe;Томашпіль;Томашполь;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524885603;3;1
80f55d48-749b-11df-b112-00215aee3ebe;Томашпіль;Томашполь;місто;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523955100;3;0
80f55d49-749b-11df-b112-00215aee3ebe;Томилівка;Томиловка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220487201;2;1
80f55d4a-749b-11df-b112-00215aee3ebe;Томина Балка;Томина Балка;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520387101;3;1
80f55d4c-749b-11df-b112-00215aee3ebe;Тонка;Тонка;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421183203;3;1
80f55d4d-749b-11df-b112-00215aee3ebe;Топали;Топалы;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123184201;3;1
80f55d4e-749b-11df-b112-00215aee3ebe;Топик;Топик;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122984870;3;1
80f55d4f-749b-11df-b112-00215aee3ebe;Топила;Топила;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225485506;3;1
80f55d62-749b-11df-b112-00215aee3ebe;Топилище;Топилище;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721183815;3;1
80f55d50-749b-11df-b112-00215aee3ebe;Топилівка;Топиловка;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125487001;3;1
80f55d51-749b-11df-b112-00215aee3ebe;Топило;Топило;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522287407;3;1
80f55d52-749b-11df-b112-00215aee3ebe;Топильна;Топильна;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125788801;3;1
80f55d53-749b-11df-b112-00215aee3ebe;Топілля;Тополье;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623886705;3;1
80f55d54-749b-11df-b112-00215aee3ebe;Топільне;Топольно;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724586901;3;1
80f55d55-749b-11df-b112-00215aee3ebe;Топільниця;Топольница;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622482807;3;1
80f55d56-749b-11df-b112-00215aee3ebe;Топільниця;Топольница;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625187201;3;1
80f55d57-749b-11df-b112-00215aee3ebe;Топільня;Топильня;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822886501;3;1
80f55d58-749b-11df-b112-00215aee3ebe;Топільське;Топольское;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624880602;3;1
80f55d59-749b-11df-b112-00215aee3ebe;Топільче;Топильче;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620884003;3;1
80f55d5a-749b-11df-b112-00215aee3ebe;Топірчики;Топорчики;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822187802;3;1
80f55d5b-749b-11df-b112-00215aee3ebe;Тополеве;Тополевое;село;710c27e9-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5320886201;3;1
80f55d5c-749b-11df-b112-00215aee3ebe;Тополин;Тополин;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125385605;3;1
80f55d5d-749b-11df-b112-00215aee3ebe;Тополине;Тополиное;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922055307;3;1
80f55d5e-749b-11df-b112-00215aee3ebe;Тополине;Тополиное;село;6b07f8a2-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5121485502;3;1
80f55d60-749b-11df-b112-00215aee3ebe;Тополине;Тополиное;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521855103;3;1
80f55d66-749b-11df-b112-00215aee3ebe;Тополі;Тополя;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422080206;3;1
80f55d67-749b-11df-b112-00215aee3ebe;Тополі;Тополя;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120685702;3;1
80f55d68-749b-11df-b112-00215aee3ebe;Тополі;Тополя;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521186201;3;1
80f55d6a-749b-11df-b112-00215aee3ebe;Тополівка;Тополевка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321084206;3;1
80f55d6e-749b-11df-b112-00215aee3ebe;Тополівка;Тополевка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421488402;3;1
80f55d6f-749b-11df-b112-00215aee3ebe;Тополівка;Тополевка;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424785006;3;1
80f55d70-749b-11df-b112-00215aee3ebe;Тополівка;Тополевка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523787401;3;1
80f55d71-749b-11df-b112-00215aee3ebe;Тополя;Тополя;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921587903;3;1
80f55d72-749b-11df-b112-00215aee3ebe;Тополя;Тополя;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424180903;3;1
80f55d74-749b-11df-b112-00215aee3ebe;Топольське;Топольское;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322887008;3;1
80f55d75-749b-11df-b112-00215aee3ebe;Топори;Топоры;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825286301;3;1
80f55d76-749b-11df-b112-00215aee3ebe;Топори;Топоры;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822187801;3;1
80f55d77-749b-11df-b112-00215aee3ebe;Топорище;Топорище;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821185401;3;1
80f55d78-749b-11df-b112-00215aee3ebe;Топорів;Топоров;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620686201;3;1
80f55d79-749b-11df-b112-00215aee3ebe;Топорівці;Топоровцы;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323088601;3;1
80f55d7a-749b-11df-b112-00215aee3ebe;Топорівці;Топоровцы;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621685701;3;1
80f55d7b-749b-11df-b112-00215aee3ebe;Топча;Топча;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623080803;3;1
80f55d7c-749b-11df-b112-00215aee3ebe;Топчине;Топчино;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222387001;3;1
80f55d7d-749b-11df-b112-00215aee3ebe;Топчино;Топчино;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124487501;3;1
80f55d7e-749b-11df-b112-00215aee3ebe;Топчії;Топчии;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983709;3;1
80f55d7f-749b-11df-b112-00215aee3ebe;Топчіївка;Топчиевка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425587903;3;1
80f55d80-749b-11df-b112-00215aee3ebe;Тораки;Тораки;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323555103;3;1
80f55d81-749b-11df-b112-00215aee3ebe;Торгановичі;Торгановичи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625187411;3;1
80f55d82-749b-11df-b112-00215aee3ebe;Торгів;Торгов;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621855605;3;1
80f55d83-749b-11df-b112-00215aee3ebe;Торговиця;Торговица;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922089002;3;1
80f55d84-749b-11df-b112-00215aee3ebe;Торговиця;Торговица;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621685801;3;1
80f55d85-749b-11df-b112-00215aee3ebe;Торговиця;Торговица;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623287401;3;1
80f55d86-749b-11df-b112-00215aee3ebe;Торговиця;Торговица;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523687701;3;1
80f55d87-749b-11df-b112-00215aee3ebe;Торговиця;Торговица;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623889001;3;1
80f55d88-749b-11df-b112-00215aee3ebe;Торговище;Торговище;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725555109;3;1
80f55d8d-749b-11df-b112-00215aee3ebe;Торканівка;Торкановка;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524185301;3;1
80f55d8e-749b-11df-b112-00215aee3ebe;Торки;Торки;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623980904;3;1
80f55d8f-749b-11df-b112-00215aee3ebe;Торків;Торков;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524386601;3;1
62c3d13d-749b-11df-b112-00215aee3ebe;Торосове;Торосово;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125282501;3;1
80f55d90-749b-11df-b112-00215aee3ebe;Торське;Торское;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122088301;3;1
80f55d93-749b-11df-b112-00215aee3ebe;Торунь;Торунь;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122487901;3;1
80f55d94-749b-11df-b112-00215aee3ebe;Торф'яне;Торфяное;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221083005;3;1
80f55d95-749b-11df-b112-00215aee3ebe;Торчин;Торчин;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722855400;2;0
80f55d96-749b-11df-b112-00215aee3ebe;Торчин;Торчин;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821180205;3;1
80f55d97-749b-11df-b112-00215aee3ebe;Торчин;Торчин;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822587601;3;1
80f55d98-749b-11df-b112-00215aee3ebe;Торчин;Торчин;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524887003;3;1
80f55d99-749b-11df-b112-00215aee3ebe;Торчиновичі;Торчиновичи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625187401;3;1
80f55d9a-749b-11df-b112-00215aee3ebe;Торчиця;Торчица;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224287201;3;1
80f55d9b-749b-11df-b112-00215aee3ebe;Торчицький Степок;Торчицкий Степок;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224287602;3;1
80f55d9c-749b-11df-b112-00215aee3ebe;Тотчине;Тотчино;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322880305;3;1
80f55d9d-749b-11df-b112-00215aee3ebe;Точевики;Точевики;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624280409;3;1
87161ff2-749b-11df-b112-00215aee3ebe;Точене;Точеное;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425182303;3;1
87161ff3-749b-11df-b112-00215aee3ebe;Точилове;Точилово;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120285101;3;1
87161ff5-749b-11df-b112-00215aee3ebe;Травкине;Травкино;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420610104;3;1
87161ff6-749b-11df-b112-00215aee3ebe;Травневе;Травневое;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122488403;3;1
87161ff7-749b-11df-b112-00215aee3ebe;Травневе;Травневое;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157608;3;1
87161ff8-749b-11df-b112-00215aee3ebe;Травневе;Травневое;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121285605;3;1
87161ffc-749b-11df-b112-00215aee3ebe;Травневе;Травневое;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223384603;3;1
87161ffd-749b-11df-b112-00215aee3ebe;Травневе;Травневое;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822585005;3;1
87161ffe-749b-11df-b112-00215aee3ebe;Травневе;Травневое;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421489204;3;1
87161fff-749b-11df-b112-00215aee3ebe;Травневе;Травневое;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523482203;3;1
87162000-749b-11df-b112-00215aee3ebe;Травневе;Травневое;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522880905;3;1
87162001-749b-11df-b112-00215aee3ebe;Травневе;Травневое;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824482503;3;1
87162002-749b-11df-b112-00215aee3ebe;Травневе;Травневое;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623882304;3;1
87162003-749b-11df-b112-00215aee3ebe;Травневе;Травневое;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323283411;3;1
33ec001a-ddd5-11df-9197-00215aee3ebe;Травневе;Травневое;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521085806;3;1
7ad94e4b-749b-11df-b112-00215aee3ebe;Травневе (Пролетарське);Травневе (Пролетарське);село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520385901;3;1
8716268e-749b-11df-b112-00215aee3ebe;Травневе (Червоний Жовтень);Майское (Червоный Жовтень);село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322280503;3;1
87162004-749b-11df-b112-00215aee3ebe;Травотолоки;Травотолоки;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122683709;3;1
87162005-749b-11df-b112-00215aee3ebe;Тракт;Тракт;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521186002;3;1
87162008-749b-11df-b112-00215aee3ebe;Трактове;Трактовое;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524981505;3;1
87162009-749b-11df-b112-00215aee3ebe;Тракторне;Тракторное;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055413;3;1
8716200b-749b-11df-b112-00215aee3ebe;Трапівка;Траповка;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125082704;2;1
8716200c-749b-11df-b112-00215aee3ebe;Траулин;Траулин;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825589005;3;1
8716200d-749b-11df-b112-00215aee3ebe;Трахтемирів;Трахтемиров;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122081903;3;1
8716200e-749b-11df-b112-00215aee3ebe;Трач;Трач;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623687601;3;1
8716200f-749b-11df-b112-00215aee3ebe;Требухів;Требухов;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221289001;2;0
87162010-749b-11df-b112-00215aee3ebe;Требухівці;Требуховцы;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823085801;3;1
84784681-a72e-11eb-80d3-000c29800ae7;Требухівці;Требуховци;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125582901;3;1
87162013-749b-11df-b112-00215aee3ebe;Трепівка;Треповка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522287401;3;1
87162014-749b-11df-b112-00215aee3ebe;Третій інтернаціонал;Третий Интернационал;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525882304;3;1
87162016-749b-11df-b112-00215aee3ebe;Третяківка;Третьяковка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224881309;3;1
87162018-749b-11df-b112-00215aee3ebe;Третякове;Третьяково;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324880313;3;1
87162019-749b-11df-b112-00215aee3ebe;Три Копці;Три Копцы;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624680411;3;1
8716201a-749b-11df-b112-00215aee3ebe;Три Криниці;Три Криницы;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121682604;3;1
8716201b-749b-11df-b112-00215aee3ebe;Триби;Трибы;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;;3;1
8716201c-749b-11df-b112-00215aee3ebe;Трибоківці;Трибоковцы;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621555910;3;1
8716201d-749b-11df-b112-00215aee3ebe;Трибусівка;Трибусовка;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523283201;3;1
8716201e-749b-11df-b112-00215aee3ebe;Трибухи;Трибухи;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522483202;3;1
8716201f-749b-11df-b112-00215aee3ebe;Трибухівка;Трибуховка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821880604;3;1
87162020-749b-11df-b112-00215aee3ebe;Трибухівці;Трибуховцы;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121683903;3;1
87162021-749b-11df-b112-00215aee3ebe;Трибухівці;Трибуховцы;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121282001;3;1
87162022-749b-11df-b112-00215aee3ebe;Тривайли;Тривайлы;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281910;3;1
87162023-749b-11df-b112-00215aee3ebe;Тригір'я;Тригорье;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822087210;3;1
87162024-749b-11df-b112-00215aee3ebe;Тригради;Триграды;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123180805;3;1
87162025-749b-11df-b112-00215aee3ebe;Тригуби;Тригубы;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983710;3;1
87162026-749b-11df-b112-00215aee3ebe;Тригубщина;Тригубщина;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320482407;3;1
87162027-749b-11df-b112-00215aee3ebe;Тридуби;Тридубы;село;6b07f804-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4823984201;3;1
87162028-749b-11df-b112-00215aee3ebe;Трикопці;Трикопцы;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822588413;3;1
87162029-749b-11df-b112-00215aee3ebe;Трикрати;Трикраты;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822055207;3;1
8716202a-749b-11df-b112-00215aee3ebe;Трикратне;Трикратное;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822055203;3;1
8716202b-749b-11df-b112-00215aee3ebe;Триліси;Трилесы;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224986601;3;1
8716202c-749b-11df-b112-00215aee3ebe;Триліси;Трилесы;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520588001;3;1
8716202d-749b-11df-b112-00215aee3ebe;Трилісці;Трилесцы;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724585009;3;1
8716202f-749b-11df-b112-00215aee3ebe;Трипілля;Триполье;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223188001;3;1
87162031-749b-11df-b112-00215aee3ebe;Трипутня;Трипутня;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621888201;3;1
87162032-749b-11df-b112-00215aee3ebe;Трирогове;Трирогово;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086911;3;1
7ad94f8d-749b-11df-b112-00215aee3ebe;Трисвятська Слобода;Трисвя?тская Слобода;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425586001;3;0
87162033-749b-11df-b112-00215aee3ebe;Тристень;Тристень;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724587203;3;1
87162034-749b-11df-b112-00215aee3ebe;Трительники;Трительники;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820987801;3;1
87162035-749b-11df-b112-00215aee3ebe;Тритузне;Тритузное;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055114;3;1
87162036-749b-11df-b112-00215aee3ebe;Трифонівка;Трифоновка;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520984501;3;1
87162037-749b-11df-b112-00215aee3ebe;Трихати;Трихаты;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824255604;3;1
87162038-749b-11df-b112-00215aee3ebe;Трихатки;Трихатки;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125083903;2;1
87162039-749b-11df-b112-00215aee3ebe;Трихатське;Трихатское;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824284606;3;1
8716203a-749b-11df-b112-00215aee3ebe;Трихуторівка;Трихуторовка;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120386006;3;1
8716203b-749b-11df-b112-00215aee3ebe;Трійця;Тройця;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120883007;3;1
8716203c-749b-11df-b112-00215aee3ebe;Трійця;Тройця;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623982804;3;1
5cb619cb-749b-11df-b112-00215aee3ebe;Трійчате ( Комсомольське );Тройчатое ( Комсомольское );село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324581002;3;1
8716203d-749b-11df-b112-00215aee3ebe;Тріски;Триски;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822782502;3;1
8716203e-749b-11df-b112-00215aee3ebe;Тріскині;Трискини;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625485804;3;1
8716203f-749b-11df-b112-00215aee3ebe;Тріщуки;Трещуки;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620388808;3;1
87162040-749b-11df-b112-00215aee3ebe;Троєщина;Троещина;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823685003;3;1
87162041-749b-11df-b112-00215aee3ebe;Троїця;Троица;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625255309;3;1
87162042-749b-11df-b112-00215aee3ebe;Троїцьке;Троицкое;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924480405;3;1
87162043-749b-11df-b112-00215aee3ebe;Троїцьке;Троицкое;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223282005;3;1
87162044-749b-11df-b112-00215aee3ebe;Троїцьке;Троицкое;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223587501;3;1
87162045-749b-11df-b112-00215aee3ebe;Троїцьке;Троицкое;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224587501;3;1
87162046-749b-11df-b112-00215aee3ebe;Троїцьке;Троицкое;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224881310;3;1
87162047-749b-11df-b112-00215aee3ebe;Троїцьке;Троицкое;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223886008;3;1
87162048-749b-11df-b112-00215aee3ebe;Троїцьке;Троицкое;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822085405;3;1
87162049-749b-11df-b112-00215aee3ebe;Троїцьке;Троицкое;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824885801;3;1
8716204a-749b-11df-b112-00215aee3ebe;Троїцьке;Троицкое;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325755108;3;1
8716204b-749b-11df-b112-00215aee3ebe;Троїцьке;Троицкое;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123383801;3;1
8716204c-749b-11df-b112-00215aee3ebe;Троїцьке;Троицкое;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121084801;3;1
44ac842b-749b-11df-b112-00215aee3ebe;Троїцьке (Більшовик);Троїцьке (Більшовик);село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324583502;3;1
50c59587-749b-11df-b112-00215aee3ebe;Троїцьке (Довгалівське);Троицкое (Довгалевское);село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223784502;3;1
5cb61724-749b-11df-b112-00215aee3ebe;Троїцьке (Кірове);Троицкое (Кирово);село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423684301;3;1
8716233d-749b-11df-b112-00215aee3ebe;Троїцьке (Фрунзівка);Троїцьке (Фрунзівка);село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320689401;3;1
87162054-749b-11df-b112-00215aee3ebe;Троїцько-Сафонове;Троицко-Сафоново;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655127;3;1
87162056-749b-11df-b112-00215aee3ebe;Тройняки;Тройняки;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324582407;3;1
87162057-749b-11df-b112-00215aee3ebe;Троковичі;Троковичи;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825688201;3;1
87162058-749b-11df-b112-00215aee3ebe;Тронка;Тронка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820980904;3;1
87162059-749b-11df-b112-00215aee3ebe;Тропове;Троповое;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522687201;2;1
8716205a-749b-11df-b112-00215aee3ebe;Тросне;Тросное;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321786207;3;1
8716205b-749b-11df-b112-00215aee3ebe;Тросник;Тростник;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121285303;3;1
8716205c-749b-11df-b112-00215aee3ebe;Тростинка;Тростинка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221487801;3;1
8716205d-749b-11df-b112-00215aee3ebe;Тростинська Новоселиця;Тростинская Новоселица;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221488001;3;1
8716205e-749b-11df-b112-00215aee3ebe;Тростянець;Тростянец;місто;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925010100;2;0
8716205f-749b-11df-b112-00215aee3ebe;Тростянець;Тростянец;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721887201;3;1
87162060-749b-11df-b112-00215aee3ebe;Тростянець;Тростянец;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122688901;3;1
87162061-749b-11df-b112-00215aee3ebe;Тростянець;Тростянец;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120488501;3;1
87162062-749b-11df-b112-00215aee3ebe;Тростянець;Тростянец;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121685801;3;1
87162063-749b-11df-b112-00215aee3ebe;Тростянець;Тростянец;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622086901;3;1
87162064-749b-11df-b112-00215aee3ebe;Тростянець;Тростянец;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625255310;3;1
87162065-749b-11df-b112-00215aee3ebe;Тростянець;Тростянец;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621885208;3;1
87162066-749b-11df-b112-00215aee3ebe;Тростянець;Тростянец;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623088401;3;0
87162067-749b-11df-b112-00215aee3ebe;Тростянець;Тростянец;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123683003;3;1
87162068-749b-11df-b112-00215aee3ebe;Тростянець;Тростянец;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421788501;3;1
87162069-749b-11df-b112-00215aee3ebe;Тростянець;Тростянец;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425183003;3;1
8716206a-749b-11df-b112-00215aee3ebe;Тростянець;Тростянец;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122088401;3;1
8716206b-749b-11df-b112-00215aee3ebe;Тростянець;Тростянец;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524586801;3;1
8716206c-749b-11df-b112-00215aee3ebe;Тростянець;Тростянец;місто;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524155100;3;0
8716206d-749b-11df-b112-00215aee3ebe;Тростянець;Тростянец;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525686601;3;1
8716206e-749b-11df-b112-00215aee3ebe;Тростянець;Тростянец;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823986704;3;1
8716206f-749b-11df-b112-00215aee3ebe;Тростянець;Тростянец;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821285805;3;1
87162070-749b-11df-b112-00215aee3ebe;Тростянець;Тростянец;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623483403;3;1
87162071-749b-11df-b112-00215aee3ebe;Тростянець;Тростянец;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621687411;3;1
87162072-749b-11df-b112-00215aee3ebe;Тростяниця;Тростяница;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122786605;3;1
87162073-749b-11df-b112-00215aee3ebe;Тростяниця;Тростяница;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823488203;3;1
87162074-749b-11df-b112-00215aee3ebe;Тростянка;Тростянка;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720510404;3;1
87162075-749b-11df-b112-00215aee3ebe;Тростянка;Тростянка;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724582904;3;1
87162076-749b-11df-b112-00215aee3ebe;Тростянка;Тростянка;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623285404;3;1
87162077-749b-11df-b112-00215aee3ebe;Тростянка;Тростянка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420888001;3;1
87162078-749b-11df-b112-00215aee3ebe;Тростянка;Тростянка;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820355105;3;1
87162079-749b-11df-b112-00215aee3ebe;Тростянці;Тростянцы;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124288001;3;1
8716207a-749b-11df-b112-00215aee3ebe;Тростянчик;Тростянчик;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524185901;3;1
8716207b-749b-11df-b112-00215aee3ebe;Трофанівка;Трофановка;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625280303;3;1
8716207c-749b-11df-b112-00215aee3ebe;Трохименкове;Трофименково;село;9b486f64-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5910191511;3;1
8716207d-749b-11df-b112-00215aee3ebe;Трохимівка;Трофимовка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321255403;3;1
8716207e-749b-11df-b112-00215aee3ebe;Трохимівка;Трофимовка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655121;3;1
87162080-749b-11df-b112-00215aee3ebe;Троща;Троща;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825887801;3;1
87162081-749b-11df-b112-00215aee3ebe;Троща;Троща;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522287501;3;1
87162082-749b-11df-b112-00215aee3ebe;Трощин;Трощин;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122087405;3;1
87162083-749b-11df-b112-00215aee3ebe;Троян;Троян;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;;3;1
87162085-749b-11df-b112-00215aee3ebe;Троянди;Троянды;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582204;3;1
5cb61716-749b-11df-b112-00215aee3ebe;Трояндове (Кірове);Трояндовэ (Кирово);село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122782201;3;1
87162086-749b-11df-b112-00215aee3ebe;Трояни;Трояны;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321055103;3;1
87162087-749b-11df-b112-00215aee3ebe;Трояни;Трояны;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321884605;3;1
87162088-749b-11df-b112-00215aee3ebe;Трояни;Трояны;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824582670;3;1
8716208a-749b-11df-b112-00215aee3ebe;Трояни;Трояны;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624286303;3;1
8716208b-749b-11df-b112-00215aee3ebe;Трояни;Трояны;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521786301;3;1
8716208c-749b-11df-b112-00215aee3ebe;Троянів;Троянов;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822087601;3;1
8716208d-749b-11df-b112-00215aee3ebe;Троянівка;Трояновка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321381002;3;1
8716208e-749b-11df-b112-00215aee3ebe;Троянівка;Трояновка;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723687301;3;1
8716208f-749b-11df-b112-00215aee3ebe;Троянівка;Трояновка;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824783504;3;1
87162090-749b-11df-b112-00215aee3ebe;Троянка;Троянка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823684804;3;1
87162091-749b-11df-b112-00215aee3ebe;Троянка;Троянка;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521487701;3;1
87162092-749b-11df-b112-00215aee3ebe;Троянка;Троянка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522284903;3;1
87162093-749b-11df-b112-00215aee3ebe;Троянове;Трояново;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523886803;3;1
87162094-749b-11df-b112-00215aee3ebe;Трубайці;Трубайцы;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324886201;3;1
87162095-749b-11df-b112-00215aee3ebe;Трубиці;Трубицы;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623486909;3;1
87162096-749b-11df-b112-00215aee3ebe;Трубівщина;Трубовщина;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225584202;3;1
87162097-749b-11df-b112-00215aee3ebe;Трубіївка;Трубиевка;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825281602;3;1
87162098-749b-11df-b112-00215aee3ebe;Трубки;Трубки;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721183816;3;1
8716209a-749b-11df-b112-00215aee3ebe;Трубочка;Трубочка;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520883309;3;1
8716209b-749b-11df-b112-00215aee3ebe;Трубчин;Трубчин;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
8716209d-749b-11df-b112-00215aee3ebe;Труд-Гребеник;Труд-Гребеник;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123981106;3;1
8716209e-749b-11df-b112-00215aee3ebe;Труди;Труды;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625455706;3;1
8716209f-749b-11df-b112-00215aee3ebe;Трудівка;Трудовка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520382404;3;1
871620a1-749b-11df-b112-00215aee3ebe;Труд-Куток;Труд-Куток;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123985405;3;1
871620a2-749b-11df-b112-00215aee3ebe;Трудовач;Трудовач;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621881504;3;1
871620a3-749b-11df-b112-00215aee3ebe;Трудове;Трудовое;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923888111;3;1
871620a4-749b-11df-b112-00215aee3ebe;Трудове;Трудовое;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055708;3;1
871620a5-749b-11df-b112-00215aee3ebe;Трудове;Трудовое;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221883509;3;1
871620a6-749b-11df-b112-00215aee3ebe;Трудове;Трудовое;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610103;3;1
871620a7-749b-11df-b112-00215aee3ebe;Трудове;Трудовое;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083070;3;1
871620a8-749b-11df-b112-00215aee3ebe;Трудове;Трудовое;село;903ccab3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122384601;3;1
871620a9-749b-11df-b112-00215aee3ebe;Трудове;Трудовое;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323687201;3;1
871620ae-749b-11df-b112-00215aee3ebe;Трудове;Трудовое;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623081603;3;1
871620b4-749b-11df-b112-00215aee3ebe;Трудове;Трудовое;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825483604;3;1
871620b5-749b-11df-b112-00215aee3ebe;Трудове;Трудовое;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424482602;3;1
871620b6-749b-11df-b112-00215aee3ebe;Трудове;Трудовое;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523255101;3;1
871620b7-749b-11df-b112-00215aee3ebe;Трудове;Трудовое;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822782405;3;1
871620b8-749b-11df-b112-00215aee3ebe;Трудовик;Трудовик;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322080806;3;1
871620bb-749b-11df-b112-00215aee3ebe;Трудовик;Трудовик;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422410105;3;1
871620bc-749b-11df-b112-00215aee3ebe;Трудолюб;Трудолюб;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323289206;3;1
871620be-749b-11df-b112-00215aee3ebe;Трудолюбівка;Трудолюбовка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325455308;3;1
871620bf-749b-11df-b112-00215aee3ebe;Трудолюбівка;Трудолюбовка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222384505;3;1
871620c0-749b-11df-b112-00215aee3ebe;Трудолюбівка;Трудолюбовка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224584817;3;1
871620c1-749b-11df-b112-00215aee3ebe;Трудолюбівка;Трудолюбовка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225086007;3;1
871620c2-749b-11df-b112-00215aee3ebe;Трудолюбівка;Трудолюбовка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225285505;3;1
871620c3-749b-11df-b112-00215aee3ebe;Трудолюбівка;Трудолюбовка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885711;3;1
871620c4-749b-11df-b112-00215aee3ebe;Трудолюбівка;Трудолюбовка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821483404;3;1
871620c5-749b-11df-b112-00215aee3ebe;Трудолюбівка;Трудолюбовка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822784605;3;1
871620c6-749b-11df-b112-00215aee3ebe;Трудолюбівка;Трудолюбовка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825783905;3;1
871620c7-749b-11df-b112-00215aee3ebe;Трудолюбівка;Трудолюбовка;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323280309;3;1
871620c8-749b-11df-b112-00215aee3ebe;Трудолюбівка;Трудолюбовка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125480309;3;1
871620c9-749b-11df-b112-00215aee3ebe;Трудолюбівка;Трудолюбовка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321583005;3;1
871620ca-749b-11df-b112-00215aee3ebe;Трудолюбівка;Трудолюбовка;село;78f4540d-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6524183002;3;1
871620cb-749b-11df-b112-00215aee3ebe;Трудолюбівка;Трудолюбовка;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624883103;3;1
871620ce-749b-11df-b112-00215aee3ebe;Трудолюбівка;Трудолюбовка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823482603;3;1
871620cf-749b-11df-b112-00215aee3ebe;Трудолюбівка;Трудолюбовка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520283412;3;1
871620d0-749b-11df-b112-00215aee3ebe;Трудолюбівка;Трудолюбовка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524082805;3;1
871620d1-749b-11df-b112-00215aee3ebe;Трудолюбівка;Трудолюбовка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520886905;3;1
871620d2-749b-11df-b112-00215aee3ebe;Трудолюбівка;Трудолюбовка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522881405;3;1
cdc44f60-dde8-11df-9197-00215aee3ebe;Трудолюбівка;Трудолюбовка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955426;3;1
44ac8011-749b-11df-b112-00215aee3ebe;Трудомирівка (Артема);Трудомировка (Артема);село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121683402;3;1
871620d4-749b-11df-b112-00215aee3ebe;Трудооленівка;Трудооленовка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955427;3;1
871620d8-749b-11df-b112-00215aee3ebe;Трусилівка;Трусиловка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822786603;3;1
871620d9-749b-11df-b112-00215aee3ebe;Трускавець;Трускавец;місто;fbfb8c20-41be-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4611500000;2;0
871620da-749b-11df-b112-00215aee3ebe;Труханів;Труханов;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624587401;3;1
871620db-749b-11df-b112-00215aee3ebe;Трушевичі;Трушевичи;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625184101;3;1
871620dc-749b-11df-b112-00215aee3ebe;Трушівці;Трушевцы;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125487501;3;1
871620dd-749b-11df-b112-00215aee3ebe;Трушки;Трушки;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220487401;2;1
871620df-749b-11df-b112-00215aee3ebe;Тубільці;Тубольцы;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124987501;3;1
871620e0-749b-11df-b112-00215aee3ebe;Тугаївка;Тугаевка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321284512;3;1
c6cc1bfe-db7e-11df-9197-00215aee3ebe;Тудорів;Тудоров;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121688701;3;1
871620e1-749b-11df-b112-00215aee3ebe;Тудорковичі;Тудорковичи;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624887801;3;1
871620e2-749b-11df-b112-00215aee3ebe;Тужанівці;Тужановцы;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623087405;3;1
871620e3-749b-11df-b112-00215aee3ebe;Тужар;Тужар;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422083905;3;1
871620e4-749b-11df-b112-00215aee3ebe;Тужилів;Тужилов;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622888201;3;1
871620e5-749b-11df-b112-00215aee3ebe;Тужилів;Тужилов;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225582703;3;1
871620e6-749b-11df-b112-00215aee3ebe;Тузли;Тузлы;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820985401;3;1
871620e7-749b-11df-b112-00215aee3ebe;Тузли;Тузлы;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125085601;2;1
871620e8-749b-11df-b112-00215aee3ebe;Тукали;Тукалы;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324585111;3;1
871620e9-749b-11df-b112-00215aee3ebe;Тулиголове;Тулиголово;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922687701;3;1
871620ea-749b-11df-b112-00215aee3ebe;Тулиголове;Тулиголово;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620987978;3;1
871620eb-749b-11df-b112-00215aee3ebe;Тулин;Тулин;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120884505;3;1
871620ec-749b-11df-b112-00215aee3ebe;Тулинці;Тулинцы;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222988001;3;1
871620ed-749b-11df-b112-00215aee3ebe;Туличів;Туличев;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725587601;3;1
871620ee-749b-11df-b112-00215aee3ebe;Туліїв;Тулеев;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524984513;3;1
871620ef-749b-11df-b112-00215aee3ebe;Тулія;Тулия;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424488806;3;1
871620f0-749b-11df-b112-00215aee3ebe;Тулова;Тулова;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625286304;3;1
871620f1-749b-11df-b112-00215aee3ebe;Тулуків;Тулуков;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625285901;3;1
871620f2-749b-11df-b112-00215aee3ebe;Тулушка;Тулушка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922086604;3;1
871620f3-749b-11df-b112-00215aee3ebe;Тульчин;Тульчин;місто;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524310100;2;0
871620f4-749b-11df-b112-00215aee3ebe;Туманська Гута;Туманская Гута;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422080707;3;1
871620f5-749b-11df-b112-00215aee3ebe;Тумень;Тумень;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621888701;3;1
871620f6-749b-11df-b112-00215aee3ebe;Тумин;Тумин;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722481205;3;1
871620f7-749b-11df-b112-00215aee3ebe;Тумир;Тумир;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621287201;3;1
871620f9-749b-11df-b112-00215aee3ebe;Туники;Туники;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220688003;3;1
871620fa-749b-11df-b112-00215aee3ebe;Тупали;Тупалы;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725582907;3;1
871620fb-749b-11df-b112-00215aee3ebe;Тупальці;Тупальцы;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824086201;3;1
871620fc-749b-11df-b112-00215aee3ebe;Тупицівка;Тупицевка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321282509;3;1
871620fd-749b-11df-b112-00215aee3ebe;Тупичів;Тупичев;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421488401;3;1
871620fe-749b-11df-b112-00215aee3ebe;Тур;Тур;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724287601;3;1
87162109-749b-11df-b112-00215aee3ebe;Тур'є;Турье;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625187801;3;1
8716211c-749b-11df-b112-00215aee3ebe;Тур'ї Ремети;Турьи Реметы;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123286301;3;1
8716212c-749b-11df-b112-00215aee3ebe;Тур'я;Турья;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922387001;3;1
8716212d-749b-11df-b112-00215aee3ebe;Тур'я;Турья;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620686601;3;1
8716212e-749b-11df-b112-00215aee3ebe;Тур'я;Турья;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425888801;3;1
8716212f-749b-11df-b112-00215aee3ebe;Тур'я;Турья;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621680413;3;1
87162130-749b-11df-b112-00215aee3ebe;Тур'я-Бистра;Турья-Быстрая;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123285001;3;1
87162131-749b-11df-b112-00215aee3ebe;Тур'я-Пасіка;Турья-Пасека;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123285501;3;1
87162132-749b-11df-b112-00215aee3ebe;Тур'я-Поляна;Турья-Поляна;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123286201;3;1
871620ff-749b-11df-b112-00215aee3ebe;Туради;Турады;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621585913;3;1
87162100-749b-11df-b112-00215aee3ebe;Туранівка;Турановка;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925680408;3;1
87162101-749b-11df-b112-00215aee3ebe;Турбаза Світязь;Турбаза Свитязь;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;;3;1
87162102-749b-11df-b112-00215aee3ebe;Турбаї;Турбаи;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320686102;3;1
87162103-749b-11df-b112-00215aee3ebe;Турбів;Турбов;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522255500;3;0
87162104-749b-11df-b112-00215aee3ebe;Турбівка;Турбовка;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824755409;3;1
87162107-749b-11df-b112-00215aee3ebe;Тургенєвка;Тургеневка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224856206;3;1
8716210a-749b-11df-b112-00215aee3ebe;Тури;Туры;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281608;3;1
8716210b-749b-11df-b112-00215aee3ebe;Тури;Туры;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125882103;3;1
8716210c-749b-11df-b112-00215aee3ebe;Тури;Туры;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;;3;1
8716210d-749b-11df-b112-00215aee3ebe;Турильче;Турильче;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
8716210e-749b-11df-b112-00215aee3ebe;Туринка;Туринка;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622789201;3;1
8716210f-749b-11df-b112-00215aee3ebe;Туриця;Турица;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123284001;3;1
87162110-749b-11df-b112-00215aee3ebe;Турича;Турича;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625885207;3;1
87162111-749b-11df-b112-00215aee3ebe;Туричани;Туричаны;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725581002;3;1
87162112-749b-11df-b112-00215aee3ebe;Турички;Турички;село;a1e9f9b3-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2123284501;3;1
87162113-749b-11df-b112-00215aee3ebe;Турівка;Туровка;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720510422;3;1
87162114-749b-11df-b112-00215aee3ebe;Турівка;Туровка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124689001;3;1
87162115-749b-11df-b112-00215aee3ebe;Турівка;Туровка;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624886704;3;1
87162116-749b-11df-b112-00215aee3ebe;Турівка;Туровка;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221986401;3;1
87162117-749b-11df-b112-00215aee3ebe;Турівка;Туровка;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422485506;3;1
87162118-749b-11df-b112-00215aee3ebe;Турівка;Туровка;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824786201;3;1
87162119-749b-11df-b112-00215aee3ebe;Турійськ;Турийск;місто;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725555100;3;0
8716211a-749b-11df-b112-00215aee3ebe;Турія;Турия;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725583103;3;1
8716211b-749b-11df-b112-00215aee3ebe;Турія;Турия;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523887601;3;1
8716211d-749b-11df-b112-00215aee3ebe;Турка;Турка;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623287601;3;1
8716211e-749b-11df-b112-00215aee3ebe;Турка;Турка;місто;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625510100;3;0
8716211f-749b-11df-b112-00215aee3ebe;Турківка;Турковка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424180904;3;1
87162120-749b-11df-b112-00215aee3ebe;Турковичі;Турковичи;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621686015;3;1
87162121-749b-11df-b112-00215aee3ebe;Туркотин;Туркотин;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621884904;3;1
c8674a88-dbb0-11df-9197-00215aee3ebe;Турлаки;Турлаки;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;*512088200;3;1
87162122-749b-11df-b112-00215aee3ebe;Турова;Туровая;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820655904;3;1
87162123-749b-11df-b112-00215aee3ebe;Турове;Турово;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655108;3;1
87162124-749b-11df-b112-00215aee3ebe;Турове;Турово;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322685505;3;1
87162125-749b-11df-b112-00215aee3ebe;Турове;Турово;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424781508;3;1
87162126-749b-11df-b112-00215aee3ebe;Туровець;Туровец;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822087901;3;1
87162127-749b-11df-b112-00215aee3ebe;Туровичі;Туровичи;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725582908;3;1
87162128-749b-11df-b112-00215aee3ebe;Туропин;Туропин;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725583104;3;1
c6cc1bfa-db7e-11df-9197-00215aee3ebe;Турутине;Турутино;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922086003;3;1
87162129-749b-11df-b112-00215aee3ebe;Турчинівка;Турчиновка;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825888201;3;1
8716212a-749b-11df-b112-00215aee3ebe;Турчинка;Турчинка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821187604;3;1
8716212b-749b-11df-b112-00215aee3ebe;Турчинці;Турчинцы;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821287103;3;1
87162133-749b-11df-b112-00215aee3ebe;Турятка;Турятка;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321087401;3;1
87162134-749b-11df-b112-00215aee3ebe;Тустань;Тустань;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621287301;3;1
87162135-749b-11df-b112-00215aee3ebe;Тустоголови;Тустоголовы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122686009;3;1
87162136-749b-11df-b112-00215aee3ebe;Тутаки;Тутаки;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324280912;3;1
87162139-749b-11df-b112-00215aee3ebe;Тутовичі;Тутовичи;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625487601;3;1
8716213a-749b-11df-b112-00215aee3ebe;Тухля;Тухля;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624587801;3;1
8716213b-749b-11df-b112-00215aee3ebe;Тухолька;Тухолька;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624588301;3;1
8716213c-749b-11df-b112-00215aee3ebe;Туча;Туча;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521483606;3;1
8716213d-749b-11df-b112-00215aee3ebe;Тучапи;Тучапы;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625286001;3;1
8716213e-749b-11df-b112-00215aee3ebe;Тучапи;Тучапы;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620989101;3;1
8716213f-749b-11df-b112-00215aee3ebe;Тучин;Тучин;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621288401;3;1
87162140-749b-11df-b112-00215aee3ebe;Тучне;Тучное;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920689201;3;1
87162141-749b-11df-b112-00215aee3ebe;Тучне;Тучное;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925087803;3;1
87162142-749b-11df-b112-00215aee3ebe;Тучне;Тучное;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623380603;3;1
87162143-749b-11df-b112-00215aee3ebe;Тушебин;Тушебин;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623886603;3;1
87162144-749b-11df-b112-00215aee3ebe;Тхорин;Тхорин;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287305;3;1
87162145-749b-11df-b112-00215aee3ebe;Тхорівка;Тхоровка;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224087501;2;1
87162146-749b-11df-b112-00215aee3ebe;Тщенець;Тщенец;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622487201;3;1
87162147-749b-11df-b112-00215aee3ebe;Тюдів;Тюдов;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623687901;3;1
87162148-749b-11df-b112-00215aee3ebe;Тютюнники;Тютюнники;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324087706;3;1
87162149-749b-11df-b112-00215aee3ebe;Тютюнники;Тютюнники;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825888401;3;1
8716214a-749b-11df-b112-00215aee3ebe;Тютюнники;Тютюнники;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520684009;3;1
8716214b-749b-11df-b112-00215aee3ebe;Тютюнникове;Тютюнниково;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923583412;3;1
8716214c-749b-11df-b112-00215aee3ebe;Тютюнниця;Тютюнница;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422489201;3;1
8716214d-749b-11df-b112-00215aee3ebe;Тютюрівщина;Тютюривщина;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320481704;3;1
8716214e-749b-11df-b112-00215aee3ebe;Тютьки;Тютьки;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520682809;3;1
8716214f-749b-11df-b112-00215aee3ebe;Тютьків;Тютьков;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125082203;3;1
87162150-749b-11df-b112-00215aee3ebe;Тюшка;Тюшка;село;903cca64-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122488001;3;1
87162152-749b-11df-b112-00215aee3ebe;Тягинка;Тягинка;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520687101;3;1
87162153-749b-11df-b112-00215aee3ebe;Тяглів;Тяглов;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624888202;3;1
87162154-749b-11df-b112-00215aee3ebe;Тягун;Тягун;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521210116;3;1
87162155-749b-11df-b112-00215aee3ebe;Тязів;Тязев;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2625888604;3;1
87162156-749b-11df-b112-00215aee3ebe;Тяпче;Тяпче;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622087101;3;1
87162157-749b-11df-b112-00215aee3ebe;Тятри;Тятры;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121683010;3;1
cdc44f5c-dde8-11df-9197-00215aee3ebe;Тячів;Тячев;місто;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124410100;2;0
87162158-749b-11df-b112-00215aee3ebe;Тячівка;Тячевка;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124410101;3;1
87162159-749b-11df-b112-00215aee3ebe;Убереж;Убереж;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625483405;3;1
8716215a-749b-11df-b112-00215aee3ebe;Убині;Убыни;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622188001;3;1
8716215b-749b-11df-b112-00215aee3ebe;Убіжичі;Убежичи;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424483402;3;1
8716215c-749b-11df-b112-00215aee3ebe;Уборки;Уборки;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422782503;3;1
8716215f-749b-11df-b112-00215aee3ebe;Увин;Увин;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623986901;3;1
87162160-749b-11df-b112-00215aee3ebe;Увисла;Увисла;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121688501;3;1
87162161-749b-11df-b112-00215aee3ebe;Угарове;Угарово;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523185501;3;1
87162162-749b-11df-b112-00215aee3ebe;Угерсько;Угерско;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625388401;3;1
87162163-749b-11df-b112-00215aee3ebe;Угільня;Угольня;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625380803;3;1
87162164-749b-11df-b112-00215aee3ebe;Угільці;Угольцы;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621281207;3;1
87162165-749b-11df-b112-00215aee3ebe;Угли;Углы;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722181212;3;1
87162166-749b-11df-b112-00215aee3ebe;Угли;Углы;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424780507;3;1
87162167-749b-11df-b112-00215aee3ebe;Угли;Углы;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625483407;3;1
87162168-749b-11df-b112-00215aee3ebe;Углище;Углище;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624684504;3;1
87162169-749b-11df-b112-00215aee3ebe;Углова Рудня;Угловая Рудня;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424485206;3;1
8716216b-749b-11df-b112-00215aee3ebe;Углуватка;Угловатка;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124687001;3;1
8716216c-749b-11df-b112-00215aee3ebe;Угля;Угля;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124487601;3;1
8716216d-749b-11df-b112-00215aee3ebe;Угнів;Угнев;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624810900;3;1
8716216e-749b-11df-b112-00215aee3ebe;Угорники;Угорники;село;a1e9f9b8-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2610196601;2;0
8716216f-749b-11df-b112-00215aee3ebe;Угорники;Угорники;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623287801;3;1
87162170-749b-11df-b112-00215aee3ebe;Угорськ;Угорск;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125886201;3;1
87162171-749b-11df-b112-00215aee3ebe;Угри;Угры;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620988001;2;1
87162172-749b-11df-b112-00215aee3ebe;Угриничі;Угриничи;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155133;3;1
87162173-749b-11df-b112-00215aee3ebe;Угринів;Угринов;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720887801;3;1
87162174-749b-11df-b112-00215aee3ebe;Угринів;Угринов;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124887701;3;1
87162175-749b-11df-b112-00215aee3ebe;Угринів;Угринов;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625886801;2;0
87162176-749b-11df-b112-00215aee3ebe;Угринів;Угринов;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624885702;3;1
87162177-749b-11df-b112-00215aee3ebe;Угринь;Угринь;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125588001;3;1
87162178-749b-11df-b112-00215aee3ebe;Угриньківці;Угриньковцы;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122088701;3;1
87162179-749b-11df-b112-00215aee3ebe;Угроїди;Угроеды;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922355600;3;1
8716217a-749b-11df-b112-00215aee3ebe;Удайці;Удайцы;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424189401;3;1
8716217b-749b-11df-b112-00215aee3ebe;Ударне;Ударное;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325157609;3;1
8716217e-749b-11df-b112-00215aee3ebe;Ударне;Ударное;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520882804;3;1
87162180-749b-11df-b112-00215aee3ebe;Ударник;Ударник;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520688203;3;1
87162181-749b-11df-b112-00215aee3ebe;Удачне;Удачное;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222082012;3;1
87162185-749b-11df-b112-00215aee3ebe;Уди;Уды;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322686001;3;1
87162186-749b-11df-b112-00215aee3ebe;Удич;Удич;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523787601;3;1
87162187-749b-11df-b112-00215aee3ebe;Уділенське;Уделенское;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322185704;3;1
87162188-749b-11df-b112-00215aee3ebe;Удобне;Удобное;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120887401;3;1
87162189-749b-11df-b112-00215aee3ebe;Удовиченки;Удовиченки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321386901;3;1
8716218a-749b-11df-b112-00215aee3ebe;Удрицьк;Удрицк;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621889201;3;1
8716218b-749b-11df-b112-00215aee3ebe;Удріївці;Удриевцы;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821889801;3;1
8716218c-749b-11df-b112-00215aee3ebe;Ужачин;Ужачин;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824084006;3;1
8716218d-749b-11df-b112-00215aee3ebe;Ужгород;Ужгород;місто;fbfb8c23-41be-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2110100000;1;0
8716218e-749b-11df-b112-00215aee3ebe;Ужинець;Ужинец;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623887104;3;1
8716218f-749b-11df-b112-00215aee3ebe;Ужівка;Ужовка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825482207;3;1
87162190-749b-11df-b112-00215aee3ebe;Ужова;Ужова;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724582912;3;1
87162191-749b-11df-b112-00215aee3ebe;Ужок;Ужок;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120886801;3;1
87162192-749b-11df-b112-00215aee3ebe;Уздиця;Уздица;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921588301;3;1
87162193-749b-11df-b112-00215aee3ebe;Узин;Узин;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625887001;3;1
87162194-749b-11df-b112-00215aee3ebe;Узин;Узин;місто;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220410500;2;0
87162196-749b-11df-b112-00215aee3ebe;Узлісся;Узлесье;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621880403;3;1
87162197-749b-11df-b112-00215aee3ebe;Уїзд;Уезд;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624486401;3;1
87162198-749b-11df-b112-00215aee3ebe;Уїздці;Уездцы;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623889201;3;1
87162199-749b-11df-b112-00215aee3ebe;Уїздці;Уездцы;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622687001;3;1
8716219a-749b-11df-b112-00215aee3ebe;Уклин;Уклин;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124084402;3;1
8716219b-749b-11df-b112-00215aee3ebe;Українець;Украинец;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821484302;3;1
8716219c-749b-11df-b112-00215aee3ebe;Українка;Украинка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920385602;3;1
8716219d-749b-11df-b112-00215aee3ebe;Українка;Украинка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055302;3;1
8716219e-749b-11df-b112-00215aee3ebe;Українка;Украинка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655108;3;1
8716219f-749b-11df-b112-00215aee3ebe;Українка;Украинка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225283310;3;1
871621a0-749b-11df-b112-00215aee3ebe;Українка;Украинка;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220310101;3;1
871621a1-749b-11df-b112-00215aee3ebe;Українка;Украинка;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823384401;3;1
871621a3-749b-11df-b112-00215aee3ebe;Українка;Украинка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323381505;3;1
871621a4-749b-11df-b112-00215aee3ebe;Українка;Украинка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320286208;3;1
871621a5-749b-11df-b112-00215aee3ebe;Українка;Украинка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320484003;3;1
871621a6-749b-11df-b112-00215aee3ebe;Українка;Украинка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321581405;3;1
871621a9-749b-11df-b112-00215aee3ebe;Українка;Украинка;село;78f4540d-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6524180502;3;1
871621ad-749b-11df-b112-00215aee3ebe;Українка;Украинка;місто;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223110500;2;0
871621ae-749b-11df-b112-00215aee3ebe;Українка;Украинка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823487801;3;1
871621af-749b-11df-b112-00215aee3ebe;Українка;Украинка;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420682804;3;1
871621b0-749b-11df-b112-00215aee3ebe;Українка;Украинка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522483602;3;1
871621b1-749b-11df-b112-00215aee3ebe;Українка;Украинка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522585004;3;1
871621b2-749b-11df-b112-00215aee3ebe;Українка;Украинка;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820355109;3;1
871621b3-749b-11df-b112-00215aee3ebe;Українка;Украинка;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624288001;3;1
8716263e-749b-11df-b112-00215aee3ebe;Українка;Украинка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420888802;3;1
87162640-749b-11df-b112-00215aee3ebe;Українка;Украинка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820982209;3;1
871621b5-749b-11df-b112-00215aee3ebe;Українське;Украинское;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924482805;3;1
871621b6-749b-11df-b112-00215aee3ebe;Українське;Украинское;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955109;3;1
871621b7-749b-11df-b112-00215aee3ebe;Українське;Украинское;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1223886001;3;1
871621b8-749b-11df-b112-00215aee3ebe;Українське;Украинское;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325183503;3;1
871621ba-749b-11df-b112-00215aee3ebe;Українське;Украинское;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321782005;3;1
871621bf-749b-11df-b112-00215aee3ebe;Українське;Украинское;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824081803;3;1
871621c0-749b-11df-b112-00215aee3ebe;Українське;Украинское;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422785504;3;1
871621c1-749b-11df-b112-00215aee3ebe;Українське;Украинское;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420385503;3;1
871621c2-749b-11df-b112-00215aee3ebe;Українське;Украинское;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425384501;3;1
871621c3-749b-11df-b112-00215aee3ebe;Українське;Украинское;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524887204;3;1
871621c4-749b-11df-b112-00215aee3ebe;Українське;Украинское;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520255309;3;1
2be68a2e-8353-11e8-80d4-1c98ec135261;Українське;Украинское;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;;3;1
74dc4979-749b-11df-b112-00215aee3ebe;Українське;Українське;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323955704;3;1
6ed81db6-749b-11df-b112-00215aee3ebe;Українське (Октябрське);Українське (Октябрське);село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222082008;3;1
871621c5-749b-11df-b112-00215aee3ebe;Укромне;Укромное;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321585006;3;1
871621c7-749b-11df-b112-00215aee3ebe;Уладівка;Уладовка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522487001;3;1
871621c8-749b-11df-b112-00215aee3ebe;Уладівське;Уладовское;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521682203;3;1
871621ca-749b-11df-b112-00215aee3ebe;Уланів;Уланов;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524887401;3;1
871621cb-749b-11df-b112-00215aee3ebe;Уланівка;Улановка;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521210117;3;1
871621cc-749b-11df-b112-00215aee3ebe;Уланове;Уланово;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921588601;3;1
871621cd-749b-11df-b112-00215aee3ebe;Уласово-Русанівка;Уласово-Русановка;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824455104;3;1
871621ce-749b-11df-b112-00215aee3ebe;Улашанівка;Улашановка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825480203;3;1
871621cf-749b-11df-b112-00215aee3ebe;Улашанівка;Улашановка;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823987701;3;1
871621d0-749b-11df-b112-00215aee3ebe;Улашівка;Улашовка;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224488202;3;1
871621d1-749b-11df-b112-00215aee3ebe;Улашківці;Улашковцы;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125588301;3;1
871621d2-749b-11df-b112-00215aee3ebe;Улинівка;Улиновка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322085509;3;1
871621d3-749b-11df-b112-00215aee3ebe;Улиця;Улица;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924481705;3;1
871621d4-749b-11df-b112-00215aee3ebe;Уличне;Уличное;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621288201;3;1
871621d5-749b-11df-b112-00215aee3ebe;Уляники;Ульяники;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724582913;3;1
871621d6-749b-11df-b112-00215aee3ebe;Уляники;Ульяники;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222280402;3;1
871621d8-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922086005;3;1
871621d9-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920655700;3;1
871621da-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955122;3;1
871621db-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;bad3ac3c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1223783405;3;1
871621dc-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055118;3;1
871621dd-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755110;3;1
871621de-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222987003;3;1
871621df-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821484801;3;1
871621e0-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824585004;3;1
871621e1-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824881603;3;1
871621fd-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822481004;3;1
871621fe-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623881307;3;1
87162206-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522286403;3;1
87162207-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520283209;3;1
871621e3-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320888301;3;1
871621e4-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323155605;3;1
871621e5-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322283009;3;1
871621e6-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121855401;3;1
871621e7-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;c7bb5b2d-671b-11ea-80c6-000c29800ae7;5121285606;3;1
871621e8-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122755105;3;1
871621e9-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123180806;3;1
871621ea-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321586806;3;1
871621ef-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223387601;3;1
871621f0-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224084903;3;1
871621f1-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822087211;3;1
871621f2-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821483205;3;1
871621f4-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123180502;3;1
871621f5-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524382202;3;1
871621f6-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525681604;3;1
871621f7-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523110103;3;1
871621f8-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524010111;3;1
871621f9-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520386801;3;1
871621fa-749b-11df-b112-00215aee3ebe;Улянівка;Ульяновка;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823985904;3;1
b36f1a3d-db87-11df-9197-00215aee3ebe;Улянівка;Ульяновка;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323383005;3;1
87162203-749b-11df-b112-00215aee3ebe;Улянове;Ульяново;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821755122;3;1
87162205-749b-11df-b112-00215aee3ebe;Ульвівок;Ульвивок;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624883102;3;1
87162208-749b-11df-b112-00215aee3ebe;Ульянівка;Ульяновка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;;3;1
87162209-749b-11df-b112-00215aee3ebe;Ульяновка;Ульяновка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123585001;3;1
8716220b-749b-11df-b112-00215aee3ebe;Ульяновка;Ульяновка;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520387302;3;1
87162212-749b-11df-b112-00215aee3ebe;Уманці;Уманцы;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720886403;3;1
87162213-749b-11df-b112-00215aee3ebe;Уманцівка;Уманцевка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081408;3;1
87162214-749b-11df-b112-00215aee3ebe;Умань;Умань;місто;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7110800000;1;0
f35cffa2-9ef5-11e0-a658-003048d2b473;Унів;Унив;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623383903;3;1
68cf2994-749b-11df-b112-00215aee3ebe;Унів (Міжгір'я);Межгорье;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623383903;3;1
87162215-749b-11df-b112-00215aee3ebe;Уніж;Униж;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621682005;3;1
87162216-749b-11df-b112-00215aee3ebe;Унтилівка;Унтиловка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125281307;3;1
87162217-749b-11df-b112-00215aee3ebe;Унтилівка;Унтиловка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123182305;3;1
87162218-749b-11df-b112-00215aee3ebe;Унучки;Унучкы;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425581907;3;1
87162219-749b-11df-b112-00215aee3ebe;Унятичі;Унятичи;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621288401;3;1
8716221a-749b-11df-b112-00215aee3ebe;Уплатне;Уплатное;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320686501;3;1
8716221c-749b-11df-b112-00215aee3ebe;Уралове;Уралово;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924487201;3;1
e0517edd-db89-11df-9197-00215aee3ebe;Уральське;Уральское;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823081704;3;1
8716221f-749b-11df-b112-00215aee3ebe;Урвенна;Урвенна;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5622687601;3;1
87162220-749b-11df-b112-00215aee3ebe;Урзуй;Урзуй;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423689804;3;1
87162222-749b-11df-b112-00215aee3ebe;Уритва;Урытва;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123086103;3;1
87162224-749b-11df-b112-00215aee3ebe;Урицьке;Урицкое;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322181307;3;1
87162226-749b-11df-b112-00215aee3ebe;Урич;Урыч;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624586403;3;1
87162227-749b-11df-b112-00215aee3ebe;Уріж;Урож;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621288601;3;1
87162228-749b-11df-b112-00215aee3ebe;Уріччя;Уречье;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422484507;3;1
87162229-749b-11df-b112-00215aee3ebe;Уріччя;Уречье;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620886904;3;1
8716222a-749b-11df-b112-00215aee3ebe;Урмань;Урмань;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120489101;3;1
8716222b-749b-11df-b112-00215aee3ebe;Урожайне;Урожайное;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455126;3;1
8716222d-749b-11df-b112-00215aee3ebe;Урожайне;Урожайное;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520687301;3;1
87162232-749b-11df-b112-00215aee3ebe;Урожайне;Урожайное;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420610105;3;1
87162233-749b-11df-b112-00215aee3ebe;Урожайне;Урожайное;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524585603;3;1
87162234-749b-11df-b112-00215aee3ebe;Урожайне;Урожайное;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523682205;3;1
e34d6b8a-1a4e-11e5-93c7-003048d2b473;Урожайне;Урожайное;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120888301;3;1
241e6688-bd2c-11e2-bb38-003048d2b473;Урочище Млиново;Урочище Млиново;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;;3;1
87162235-749b-11df-b112-00215aee3ebe;Урсалівка;Урсаловка;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221986407;3;1
87162236-749b-11df-b112-00215aee3ebe;Урсолівка;Урсоловка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821782506;3;1
87162237-749b-11df-b112-00215aee3ebe;Урсоя;Урсоя;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324583501;3;1
87162238-749b-11df-b112-00215aee3ebe;Усатове;Усатово;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121085201;2;0
87162239-749b-11df-b112-00215aee3ebe;Усатове Перше;Усатово Первое;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121084203;2;0
8716223b-749b-11df-b112-00215aee3ebe;Усичі;Усычи;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722880902;3;1
8716223a-749b-11df-b112-00215aee3ebe;Усичівські Будки;Усычевские Будки;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722880903;3;1
8716223c-749b-11df-b112-00215aee3ebe;Усівка;Усовка;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221986901;3;1
8716223d-749b-11df-b112-00215aee3ebe;Усівка;Усовка;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323883202;3;1
8716223e-749b-11df-b112-00215aee3ebe;Усове;Усово;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287701;3;1
8716223f-749b-11df-b112-00215aee3ebe;Усок;Усок;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925684201;3;1
87162240-749b-11df-b112-00215aee3ebe;Усолуси;Усолусы;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821788401;3;1
87162241-749b-11df-b112-00215aee3ebe;Успенівка;Успеновка;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1223880509;3;1
87162242-749b-11df-b112-00215aee3ebe;Успенівка;Успеновка;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124586801;3;1
87162247-749b-11df-b112-00215aee3ebe;Успенівка;Успеновка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523183703;3;1
87162248-749b-11df-b112-00215aee3ebe;Успенка;Успенка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920987401;3;1
87162249-749b-11df-b112-00215aee3ebe;Успенка;Успенка;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123584505;3;1
8716224c-749b-11df-b112-00215aee3ebe;Успенка;Успенка;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524687701;3;1
8716224d-749b-11df-b112-00215aee3ebe;Успенське;Успенское;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320280406;3;1
8716224e-749b-11df-b112-00215aee3ebe;Устеріки;Устерики;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620887501;3;1
8716224f-749b-11df-b112-00215aee3ebe;Устечко;Устечко;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123488601;3;1
87162250-749b-11df-b112-00215aee3ebe;Устечко;Устечко;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122089201;3;1
87162251-749b-11df-b112-00215aee3ebe;Устивиця;Устивица;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320285301;3;1
87162252-749b-11df-b112-00215aee3ebe;Устилуг;Устилуг;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720510400;3;0
87162253-749b-11df-b112-00215aee3ebe;Устименки;Устименки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321384808;3;1
87162254-749b-11df-b112-00215aee3ebe;Устимівка;Устимовка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324588001;3;1
87162255-749b-11df-b112-00215aee3ebe;Устимівка;Устимовка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610121;3;1
87162256-749b-11df-b112-00215aee3ebe;Устимівка;Устимовка;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322283504;3;1
87162257-749b-11df-b112-00215aee3ebe;Устимівка;Устимовка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221488101;3;1
8716225b-749b-11df-b112-00215aee3ebe;Устинівка;Устиновка;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824482403;3;1
8716225c-749b-11df-b112-00215aee3ebe;Устинівка;Устиновка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823488201;3;1
8716225d-749b-11df-b112-00215aee3ebe;Устинівка;Устиновка;місто;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3525855100;3;1
8716225e-749b-11df-b112-00215aee3ebe;Устинкова Гребля;Устинкова Гребля;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220281707;3;1
8716225f-749b-11df-b112-00215aee3ebe;Устя;Устье;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120888501;3;1
87162260-749b-11df-b112-00215aee3ebe;Устя;Устье;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625286301;3;1
87162261-749b-11df-b112-00215aee3ebe;Устя;Устье;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623082002;3;1
87162262-749b-11df-b112-00215aee3ebe;Устя;Устье;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520485409;3;1
87162263-749b-11df-b112-00215aee3ebe;Устя;Устье;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822489101;3;1
87162264-749b-11df-b112-00215aee3ebe;Устя;Устье;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623087901;3;1
87162265-749b-11df-b112-00215aee3ebe;Устя-Зелене;Устье Зеленое;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124288801;3;1
87162266-749b-11df-b112-00215aee3ebe;Устянівка;Устьяновка;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825586503;3;1
87162267-749b-11df-b112-00215aee3ebe;Усть-Кам'янка;Усть-Каменка;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220384404;3;1
87162268-749b-11df-b112-00215aee3ebe;Усть-Лип'янка;Усть-Липянка;село;4ffc4b59-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323081902;3;1
87162269-749b-11df-b112-00215aee3ebe;Усть-Путила;Усть-Путила;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323585501;3;1
8716226a-749b-11df-b112-00215aee3ebe;Усть-Чорна;Усть-Черна;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;ec2fa446-dd78-11e6-80f8-1c98ec135263;2124456500;3;1
8716226b-749b-11df-b112-00215aee3ebe;Утіховичі;Утеховичи;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623385603;3;1
8716226c-749b-11df-b112-00215aee3ebe;Утішків;Утешков;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620686801;3;1
8716226d-749b-11df-b112-00215aee3ebe;Утішне;Утешное;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823684805;3;1
87162270-749b-11df-b112-00215aee3ebe;Утківка;Утковка;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325158800;3;1
87162272-749b-11df-b112-00215aee3ebe;Утконосівка;Утконосовка;село;6b07f800-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122086901;2;1
87162273-749b-11df-b112-00215aee3ebe;Уторопи;Уторопы;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623688001;3;1
87162275-749b-11df-b112-00215aee3ebe;Уханівка;Ухановка;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320283606;3;1
87162276-749b-11df-b112-00215aee3ebe;Уховецьк;Уховецк;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722183608;3;1
87162278-749b-11df-b112-00215aee3ebe;Уцькове;Уцково;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923885402;3;1
8716227b-749b-11df-b112-00215aee3ebe;Ушиця;Ушица;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386035;3;1
8716227c-749b-11df-b112-00215aee3ebe;Ушівка;Ушевка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423687403;3;1
8716227e-749b-11df-b112-00215aee3ebe;Ушковичі;Ушковичи;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623388001;3;1
8716227f-749b-11df-b112-00215aee3ebe;Ушня;Ушня;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621886407;3;1
87162280-749b-11df-b112-00215aee3ebe;Ушня;Ушня;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423089001;3;1
87162281-749b-11df-b112-00215aee3ebe;Ушомир;Ушомир;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386001;3;1
87162284-749b-11df-b112-00215aee3ebe;Уяринці;Уяринцы;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524586901;3;1
87162287-749b-11df-b112-00215aee3ebe;Фадіївка;Фадеевка;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823680503;3;1
87162288-749b-11df-b112-00215aee3ebe;Фаївка;Фаевка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423685804;3;1
87162289-749b-11df-b112-00215aee3ebe;Файгород;Файгород;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521985509;3;1
8716228a-749b-11df-b112-00215aee3ebe;Фалемичі;Фалемичи;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720582004;3;1
8716228b-749b-11df-b112-00215aee3ebe;Фалиш;Фалиш;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625386407;3;1
8716228c-749b-11df-b112-00215aee3ebe;Фальків;Фальков;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;;3;1
8716228d-749b-11df-b112-00215aee3ebe;Фалькове;Фальково;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521980711;3;1
8716228e-749b-11df-b112-00215aee3ebe;Фанчиково;Фанчиково;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121285301;3;1
8716228f-749b-11df-b112-00215aee3ebe;Фараонівка;Фараоновка;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124587201;3;1
87162290-749b-11df-b112-00215aee3ebe;Фарбоване;Фарбованое;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225588201;3;1
87162291-749b-11df-b112-00215aee3ebe;Фаринки;Фаринки;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721486206;3;1
87162293-749b-11df-b112-00215aee3ebe;Фартушине;Фартушино;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923584407;3;1
87162294-749b-11df-b112-00215aee3ebe;Фартушне;Фартушное;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
87162295-749b-11df-b112-00215aee3ebe;Фасівочка;Фасовочка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222755103;3;1
87162296-749b-11df-b112-00215aee3ebe;Фасова;Фасова;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222788001;3;1
87162297-749b-11df-b112-00215aee3ebe;Фасова;Фасова;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821186501;3;1
87162298-749b-11df-b112-00215aee3ebe;Фастів;Фастов;місто;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3211200000;2;0
87162299-749b-11df-b112-00215aee3ebe;Фастівець;Фастовец;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224986901;3;1
8716229a-749b-11df-b112-00215aee3ebe;Фастівка;Фастовка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220487701;3;1
8716229b-749b-11df-b112-00215aee3ebe;Фастівці;Фастовцы;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420389001;3;1
8716229c-749b-11df-b112-00215aee3ebe;Фатовець;Фатовец;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623280202;3;1
8716229d-749b-11df-b112-00215aee3ebe;Фащівка;Фащевка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124689003;3;1
871622a0-749b-11df-b112-00215aee3ebe;Фащіївка;Фащиевка;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820687505;3;1
871622a1-749b-11df-b112-00215aee3ebe;Федіївка;Федиевка;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324285401;3;1
871622a2-749b-11df-b112-00215aee3ebe;Федіївка;Федиевка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520887601;3;1
871622a3-749b-11df-b112-00215aee3ebe;Федірки;Федирки;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983007;3;1
871622a4-749b-11df-b112-00215aee3ebe;Федірки;Федирки;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525288101;3;1
871622a5-749b-11df-b112-00215aee3ebe;Федірки;Федирки;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820988201;3;1
871622a6-749b-11df-b112-00215aee3ebe;Федірці;Федорцы;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
871622a7-749b-11df-b112-00215aee3ebe;Федоренки;Федоренки;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322483211;3;1
871622a8-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321381402;3;1
871622a9-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321082008;3;1
871622aa-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320688901;3;1
871622ab-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321684901;3;1
871622ac-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720581606;3;1
871622ad-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
871622af-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821186080;3;1
871622b0-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822382605;3;1
871622b1-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821785006;3;1
871622b2-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820982210;3;1
871622b5-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324280505;3;1
871622b6-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321780511;3;1
871622b7-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325786003;3;1
871622b8-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322881504;3;1
871622b9-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323982506;3;1
871622ba-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320484004;3;1
871622bb-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320682005;3;1
871622bc-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122583602;3;1
871622bd-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122985608;3;1
871622be-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123183705;3;1
871622bf-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322187207;3;1
871622c2-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521882003;3;1
871622c4-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520387301;3;1
871622d4-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223588005;3;1
871622d5-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222055104;3;1
871622d6-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221887004;3;1
871622d7-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225581803;3;1
871622d8-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224980803;3;1
871622d9-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823488401;3;1
871622da-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824086401;3;1
871622db-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825687903;3;1
871622dc-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524584007;3;1
871622dd-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525388001;3;1
871622de-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521786701;3;1
871622df-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520887603;3;1
871622e0-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522587601;3;1
871622e2-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524983707;3;1
871622e3-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822786604;3;1
871622e4-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625480903;3;1
871622e5-749b-11df-b112-00215aee3ebe;Федорівка;Федоровка;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621288901;3;1
871622e7-749b-11df-b112-00215aee3ebe;Федорівське;Федоровское;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955123;3;1
871622ae-749b-11df-b112-00215aee3ebe;Федорівське;Федоровское;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222685510;3;1
871622e8-749b-11df-b112-00215aee3ebe;Федоро-Михайлівка;Федоро-Михайловка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823083005;3;1
871622e9-749b-11df-b112-00215aee3ebe;Федоро-Шулічине;Федоро-Шуличино;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521985603;3;1
871622ea-749b-11df-b112-00215aee3ebe;Федосіївка;Федосеевка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125283209;3;1
871622eb-749b-11df-b112-00215aee3ebe;Федосіївка;Федосеевка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123184901;3;1
871622ed-749b-11df-b112-00215aee3ebe;Федотове;Федотово;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924184906;3;1
871622ee-749b-11df-b112-00215aee3ebe;Федунка;Федунка;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325785201;3;1
871622f0-749b-11df-b112-00215aee3ebe;Федюківка;Федюковка;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122887201;3;1
871622f1-749b-11df-b112-00215aee3ebe;Федьки;Федьки;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;;3;1
871622f2-749b-11df-b112-00215aee3ebe;Федьківка;Федьковка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524383203;3;1
871622f3-749b-11df-b112-00215aee3ebe;Федьківці;Федьковцы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122488180;3;1
871622f4-749b-11df-b112-00215aee3ebe;Феневичі;Феневичи;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222086401;3;1
871622f6-749b-11df-b112-00215aee3ebe;Феофілівка;Феофиловка;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925683703;3;1
871622f7-749b-11df-b112-00215aee3ebe;Ферма;Ферма;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222783503;3;1
73e94bf1-ddc1-11df-9197-00215aee3ebe;Фертешолмаш;Фертешолмаш;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121281202;3;1
56bdd310-749b-11df-b112-00215aee3ebe;Фертешолмаш (Заболоття);Фертешолмаш (Заболотье);село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;a73a04a6-0835-11e4-a577-003048d2b473;2121281202;3;1
871622f8-749b-11df-b112-00215aee3ebe;Ферубки;Ферубки;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424783502;3;1
871622f9-749b-11df-b112-00215aee3ebe;Фесівка;Фесовка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922086007;3;1
871622fa-749b-11df-b112-00215aee3ebe;Фесюри;Фесюры;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220488001;3;1
871622fb-749b-11df-b112-00215aee3ebe;Феськи;Феськи;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322686501;3;1
871622fc-749b-11df-b112-00215aee3ebe;Феськівка;Феськовка;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423089501;3;1
871622fd-749b-11df-b112-00215aee3ebe;Фещуки;Фещуки;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;;3;1
871622ff-749b-11df-b112-00215aee3ebe;Филенкове;Филенково;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325483701;3;1
87162300-749b-11df-b112-00215aee3ebe;Филимонівка;Филимоновка;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822380805;3;1
87162301-749b-11df-b112-00215aee3ebe;Филинці;Филинцы;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823182103;3;1
8716230e-749b-11df-b112-00215aee3ebe;Фисуни;Фисуны;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324084615;3;1
8716230f-749b-11df-b112-00215aee3ebe;Фитьків;Фитьков;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624086901;3;1
87162302-749b-11df-b112-00215aee3ebe;Фійна;Фийна;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784408;3;1
87162304-749b-11df-b112-00215aee3ebe;Філатове;Филатово;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320882505;3;1
87162305-749b-11df-b112-00215aee3ebe;Філіопіль;Филиополь;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524880605;3;1
a6b77321-20da-11ea-80e7-1c98ec135261;Філіція;Филиция;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123187003;3;1
87162306-749b-11df-b112-00215aee3ebe;Філія;Филия;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222685503;3;1
87162307-749b-11df-b112-00215aee3ebe;Філонівка;Филоновка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825084602;3;1
87162308-749b-11df-b112-00215aee3ebe;Філонівка;Филоновка;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425883508;3;1
87162309-749b-11df-b112-00215aee3ebe;Філонівка;Филоновка;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424987003;3;1
8716230a-749b-11df-b112-00215aee3ebe;Філонівщина;Филоновщина;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983008;3;1
8716230b-749b-11df-b112-00215aee3ebe;Філонове;Филоново;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923584408;3;1
8716230c-749b-11df-b112-00215aee3ebe;Філютка;Филютка;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523480603;3;1
8716230d-749b-11df-b112-00215aee3ebe;Філянівка;Филяновка;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823355102;3;1
87162310-749b-11df-b112-00215aee3ebe;Флора;Флора;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123155103;3;1
87162311-749b-11df-b112-00215aee3ebe;Флорине;Флорино;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520485603;3;1
87162312-749b-11df-b112-00215aee3ebe;Флоринське;Флоринское;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125480311;3;1
87162313-749b-11df-b112-00215aee3ebe;Флоріанівка;Флориановка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521487603;3;1
87162314-749b-11df-b112-00215aee3ebe;Флорівка;Флоровка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325480703;3;1
87162315-749b-11df-b112-00215aee3ebe;Флярківка;Флярковка;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121882003;3;1
87162318-749b-11df-b112-00215aee3ebe;Фонтанка;Фонтанка;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122786401;2;0
87162319-749b-11df-b112-00215aee3ebe;Фонтиняси;Фонтынясы;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124488403;3;1
73e94bf2-ddc1-11df-9197-00215aee3ebe;Форголань;Форголань;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121284103;3;1
8716231a-749b-11df-b112-00215aee3ebe;Форнош;Форнош;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122785301;3;1
8716231c-749b-11df-b112-00215aee3ebe;Форосна;Форосна;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323089001;3;1
8716231d-749b-11df-b112-00215aee3ebe;Форостовичі;Форостовичи;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423684304;3;1
8716231e-749b-11df-b112-00215aee3ebe;Фортунатівка;Фортунатовка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823488204;3;1
8716231f-749b-11df-b112-00215aee3ebe;Фотовиж;Фотовиж;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921589001;3;1
87162320-749b-11df-b112-00215aee3ebe;Фошки;Фошки;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323585005;3;1
87162321-749b-11df-b112-00215aee3ebe;Фрага;Фрага;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624486501;3;1
87162322-749b-11df-b112-00215aee3ebe;Франівка;Франовка;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621287503;3;1
87162323-749b-11df-b112-00215aee3ebe;Франківка;Франковка;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125189601;3;1
87162324-749b-11df-b112-00215aee3ebe;Франківка;Франковка;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525684003;3;1
87162325-749b-11df-b112-00215aee3ebe;Франкопіль;Франкополь;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623087903;3;1
87162326-749b-11df-b112-00215aee3ebe;Фрасине;Фрасино;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655123;3;1
87162327-749b-11df-b112-00215aee3ebe;Фроли;Фролы;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424780508;3;1
87162328-749b-11df-b112-00215aee3ebe;Фролівка;Фроловка;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222980903;3;1
87162329-749b-11df-b112-00215aee3ebe;Фронтівка;Фронтовка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523185801;3;1
8716233b-749b-11df-b112-00215aee3ebe;Фрузинівка;Фрузиновка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222080805;3;1
87162331-749b-11df-b112-00215aee3ebe;Фрунзе;Фрунзе;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822784901;3;1
8716232f-749b-11df-b112-00215aee3ebe;Фрунзе;Фрунзе;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;*122321050;3;1
87162340-749b-11df-b112-00215aee3ebe;Фуглі;Фугли;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323288910;3;1
87162341-749b-11df-b112-00215aee3ebe;Фуратівка;Фуратовка;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124582302;3;1
87162342-749b-11df-b112-00215aee3ebe;Фурманівка;Фурмановка;село;903ccab3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122385101;3;1
87162343-749b-11df-b112-00215aee3ebe;Фурманівка;Фурмановка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524087001;3;1
87162344-749b-11df-b112-00215aee3ebe;Фурманівка;Фурмановка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822484805;3;1
87162345-749b-11df-b112-00215aee3ebe;Фурманка;Фурманка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124389001;3;1
87162348-749b-11df-b112-00215aee3ebe;Фурси;Фурсы;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220488301;2;0
87162349-749b-11df-b112-00215aee3ebe;Фурсове;Фурсово;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423686604;3;1
8716234a-749b-11df-b112-00215aee3ebe;Фусів;Фусов;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624882602;3;1
8716234b-749b-11df-b112-00215aee3ebe;Футори;Футоры;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122610101;3;1
8716234c-749b-11df-b112-00215aee3ebe;Х. івановецький;Хутор Ивановецкий;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
8716234d-749b-11df-b112-00215aee3ebe;Х. Шевченка;Хутор Шевченко;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
8716234e-749b-11df-b112-00215aee3ebe;Хабарище;Хабарище;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724283904;3;1
8716234f-749b-11df-b112-00215aee3ebe;Хаєнки;Хаенки;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421788801;3;1
87162350-749b-11df-b112-00215aee3ebe;Хажин;Хажин;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820887201;3;1
87162351-749b-11df-b112-00215aee3ebe;Хаїха;Хаиха;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421786403;3;1
87162352-749b-11df-b112-00215aee3ebe;Хайнівка;Хайновка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520587003;3;1
87162353-749b-11df-b112-00215aee3ebe;Халаїдове;Халаидово;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123487501;3;1
87162355-749b-11df-b112-00215aee3ebe;Халеп'я;Халепье;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223188401;3;1
87162354-749b-11df-b112-00215aee3ebe;Халепці;Халепцы;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322883204;3;1
87162356-749b-11df-b112-00215aee3ebe;Халимонівка;Халимоновка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321255404;3;1
87162357-749b-11df-b112-00215aee3ebe;Халимонове;Халимоново;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420389501;3;1
87162359-749b-11df-b112-00215aee3ebe;Халтуріна;Халтурина;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324883005;3;1
8716235a-749b-11df-b112-00215aee3ebe;Халча;Халча;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222288601;3;1
8716235b-749b-11df-b112-00215aee3ebe;Халявин;Халявин;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425589101;3;1
74dc48f5-749b-11df-b112-00215aee3ebe;Хандобоківка (Першотравневе);Хандобокивка (Першотравневое);село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424784506;3;1
8716235c-749b-11df-b112-00215aee3ebe;Харалуг;Харалуг;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623088401;3;1
8716235d-749b-11df-b112-00215aee3ebe;Харенки;Харенки;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755155;3;1
8716235e-749b-11df-b112-00215aee3ebe;Харитинівка;Харитиновка;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5110290027;3;1
87162360-749b-11df-b112-00215aee3ebe;Харитонівка;Харитоновка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822587901;3;1
87162361-749b-11df-b112-00215aee3ebe;Харитонівка;Харитоновка;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425189001;3;1
87162362-749b-11df-b112-00215aee3ebe;Харитонівка;Харитоновка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821888702;3;1
87162364-749b-11df-b112-00215aee3ebe;Харівка;Харевка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923882811;3;1
87162365-749b-11df-b112-00215aee3ebe;Харків;Харьков;місто;fbfb8c27-41be-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6310100000;1;0
87162368-749b-11df-b112-00215aee3ebe;Харківка;Харьковка;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921583203;3;1
87162369-749b-11df-b112-00215aee3ebe;Харківка;Харьковка;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123185001;3;1
8716236b-749b-11df-b112-00215aee3ebe;Харківці;Харьковцы;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223382403;3;1
8716236c-749b-11df-b112-00215aee3ebe;Харківці;Харьковцы;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824487501;3;1
8716236d-749b-11df-b112-00215aee3ebe;Харківці;Харьковцы;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323886201;3;1
8716236e-749b-11df-b112-00215aee3ebe;Харківці;Харьковцы;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322687801;3;1
8716236f-749b-11df-b112-00215aee3ebe;Харківці;Харьковцы;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320488201;3;1
87162370-749b-11df-b112-00215aee3ebe;Харківщина;Харковщина;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924789107;3;1
87162371-749b-11df-b112-00215aee3ebe;Харкове;Харьково;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425382501;3;1
87162372-749b-11df-b112-00215aee3ebe;Харліївка;Харлиевка;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824787801;3;1
87162373-749b-11df-b112-00215aee3ebe;Харпачка;Харпачка;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520885803;3;1
87162374-749b-11df-b112-00215aee3ebe;Харсіки;Харсики;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325185401;3;1
87162363-749b-11df-b112-00215aee3ebe;Хартонівці;Хартоновци;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122088705;3;1
87162376-749b-11df-b112-00215aee3ebe;Харченки;Харченки;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322083004;3;1
87162377-749b-11df-b112-00215aee3ebe;Харченки;Харченки;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920987903;3;1
87162378-749b-11df-b112-00215aee3ebe;Харченки;Харченки;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982707;3;1
8716237a-749b-11df-b112-00215aee3ebe;Хасанівка;Хасановка;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124683003;3;1
8716237b-749b-11df-b112-00215aee3ebe;Хатилова Гута;Хатилова Гута;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425580807;3;1
8716237c-749b-11df-b112-00215aee3ebe;Хатки;Хатки;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125282202;3;1
8716237e-749b-11df-b112-00215aee3ebe;Хатки;Хатки;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622485904;3;1
8716237f-749b-11df-b112-00215aee3ebe;Хатки;Хатки;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624287204;3;1
87162380-749b-11df-b112-00215aee3ebe;Хатки;Хатки;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621286306;3;1
87162381-749b-11df-b112-00215aee3ebe;Хатки;Хатки;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521085212;3;1
87162383-749b-11df-b112-00215aee3ebe;Хацьки;Хацки;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124988001;3;1
87162384-749b-11df-b112-00215aee3ebe;Хащеве;Хащевое;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223281505;3;1
87162385-749b-11df-b112-00215aee3ebe;Хащів;Хащев;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625587201;3;1
87162386-749b-11df-b112-00215aee3ebe;Хащованя;Хащованя;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624580802;3;1
87162387-749b-11df-b112-00215aee3ebe;Хащувате;Хащеватое;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521186801;3;1
87162388-749b-11df-b112-00215aee3ebe;Хвалибога;Хвалибога;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621680202;3;1
87162389-749b-11df-b112-00215aee3ebe;Хвальки;Хвальки;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325786006;3;1
8716238a-749b-11df-b112-00215aee3ebe;Хватів;Хватов;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620683203;3;1
8716238b-749b-11df-b112-00215aee3ebe;Хвилі;Хвыли;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220786618;3;1
8716238c-749b-11df-b112-00215aee3ebe;Хвилівка;Хвылевка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423388904;3;1
8716238d-749b-11df-b112-00215aee3ebe;Хвильово-Сорочин;Хвылево-Сорочин;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121583102;3;1
8716238e-749b-11df-b112-00215aee3ebe;Хвойне;Хвойное;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922385405;3;1
8716238f-749b-11df-b112-00215aee3ebe;Хворосна;Хворосна;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823387005;3;1
87162390-749b-11df-b112-00215aee3ebe;Хворостів;Хворостов;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723386501;3;1
87162391-749b-11df-b112-00215aee3ebe;Хворостів;Хворостов;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720580809;3;1
87162392-749b-11df-b112-00215aee3ebe;Хворостове;Хворостово;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321288508;3;1
87162395-749b-11df-b112-00215aee3ebe;Хвощівка;Хвощовка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324884407;3;1
87162396-749b-11df-b112-00215aee3ebe;Хвощівка;Хвощовка;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823988001;3;1
87162397-749b-11df-b112-00215aee3ebe;Хвощова;Хвощовая;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925080407;3;1
87162398-749b-11df-b112-00215aee3ebe;Хвощове;Хвощово;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755106;3;1
87162399-749b-11df-b112-00215aee3ebe;Хейлівщина;Хейловщина;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325185501;3;1
74dc495a-749b-11df-b112-00215aee3ebe;Хейлове (Петрівка);Хейловое;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122583701;3;1
8716239a-749b-11df-b112-00215aee3ebe;Херсон;Херсон;місто;5d311278-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;6510100000;1;1
72579d78-61d3-11ee-80c0-000c2961d091;Херсон-Розетка;Херсон;місто;5d311278-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;6510100000;1;0
8716239c-749b-11df-b112-00215aee3ebe;Хибалівка;Хибаловка;село;4ffc4b8d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422787501;3;1
8716239d-749b-11df-b112-00215aee3ebe;Хижа;Хижа;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121285601;3;1
8716239e-749b-11df-b112-00215aee3ebe;Хижине;Хижино;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225083517;3;1
8716239f-749b-11df-b112-00215aee3ebe;Хижинці;Хижинцы;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821485801;3;1
871623a0-749b-11df-b112-00215aee3ebe;Хижинці;Хижинцы;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122887601;3;1
871623a1-749b-11df-b112-00215aee3ebe;Хижинці;Хижинцы;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520684909;3;0
871623a2-749b-11df-b112-00215aee3ebe;Хижівка;Хижовка;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820681812;3;1
871623a3-749b-11df-b112-00215aee3ebe;Хижки;Хижки;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922088401;3;1
871623a4-749b-11df-b112-00215aee3ebe;Хижники;Хижники;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824283903;3;1
871623a5-749b-11df-b112-00215aee3ebe;Хижня;Хижня;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120989101;3;1
871623a6-749b-11df-b112-00215aee3ebe;Хижняківка;Хижняковка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321383604;3;1
871623a7-749b-11df-b112-00215aee3ebe;Хижняківка;Хижняковка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323955708;3;1
871623b7-749b-11df-b112-00215aee3ebe;Хилін;Хилин;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621882005;3;1
871623a8-749b-11df-b112-00215aee3ebe;Хильки;Хильки;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122584402;3;1
871623a9-749b-11df-b112-00215aee3ebe;Хильківка;Хильковка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324886501;3;1
871623aa-749b-11df-b112-00215aee3ebe;Хилькове;Хильковое;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982412;3;1
871623ab-749b-11df-b112-00215aee3ebe;Хильчиці;Хильчицы;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621888504;3;1
871623ac-749b-11df-b112-00215aee3ebe;Хильчичі;Хильчичи;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924483204;3;1
871623ad-749b-11df-b112-00215aee3ebe;Химрич;Химрич;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821482203;3;1
871623ae-749b-11df-b112-00215aee3ebe;Химчин;Химчин;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623685204;3;1
871623af-749b-11df-b112-00215aee3ebe;Хиночі;Хиночи;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620889801;3;1
871623b0-749b-11df-b112-00215aee3ebe;Хирів;Хыров;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625110700;3;1
871623b1-749b-11df-b112-00215aee3ebe;Хитрейки;Хитрейки;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784605;3;1
871623b2-749b-11df-b112-00215aee3ebe;Хитці;Хитцы;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320483603;3;1
871623b3-749b-11df-b112-00215aee3ebe;Хитці;Хитцы;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322888402;3;1
871623b4-749b-11df-b112-00215aee3ebe;Хичів;Хичев;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821183403;3;1
871623b5-749b-11df-b112-00215aee3ebe;Хишевичі;Хишевичи;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620984503;3;1
871623b6-749b-11df-b112-00215aee3ebe;Хідновичі;Ходновичи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622489001;3;1
871623b8-749b-11df-b112-00215aee3ebe;Хімічі;Химични;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;;3;1
871623b9-749b-11df-b112-00215aee3ebe;Хітар;Хитар;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624588501;3;1
871623ba-749b-11df-b112-00215aee3ebe;Хлепча;Хлепча;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221480903;3;1
871623bb-749b-11df-b112-00215aee3ebe;Хлерівка;Хлеровка;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122582904;3;1
871623bc-749b-11df-b112-00215aee3ebe;Хлиплі;Хлипли;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622488901;3;1
871623bd-749b-11df-b112-00215aee3ebe;Хлипнівка;Хлипновка;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121288001;3;1
871623be-749b-11df-b112-00215aee3ebe;Хлистунівка;Хлыстуновка;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120388001;3;1
871623bf-749b-11df-b112-00215aee3ebe;Хлібичин;Хлебычин;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625280603;3;1
871623c0-749b-11df-b112-00215aee3ebe;Хлібне;Хлебное;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6311045401;3;1
871623c6-749b-11df-b112-00215aee3ebe;Хлібодарське;Хлебодарское;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121056800;3;1
871623c7-749b-11df-b112-00215aee3ebe;Хлібороб;Хлебороб;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924410107;3;1
33ec001b-ddd5-11df-9197-00215aee3ebe;Хліборобне;Хлеборобное;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521480212;3;1
871623c9-749b-11df-b112-00215aee3ebe;Хлівище;Хлевище;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322588701;3;1
871623ca-749b-11df-b112-00215aee3ebe;Хлівчани;Хлевчаны;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624888201;3;1
871623ce-749b-11df-b112-00215aee3ebe;Хлоп'яники;Хлопяники;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424987501;3;1
871623cb-749b-11df-b112-00215aee3ebe;Хлопівка;Хлоповка;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121688801;3;1
871623cc-749b-11df-b112-00215aee3ebe;Хлопків;Хлопков;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220282003;3;1
871623cd-749b-11df-b112-00215aee3ebe;Хлопчиці;Хлопчицы;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624288605;3;1
871623cf-749b-11df-b112-00215aee3ebe;Хлупляни;Хлупляны;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287901;3;1
871623d0-749b-11df-b112-00215aee3ebe;Хляни;Хляны;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625881911;3;1
871623d1-749b-11df-b112-00215aee3ebe;Хмарине;Хмариное;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322080409;3;1
871623d2-749b-11df-b112-00215aee3ebe;Хмарівка;Хмаровка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321310111;3;1
871623d3-749b-11df-b112-00215aee3ebe;Хмарівка;Хмаровка;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325181504;3;1
871623d4-749b-11df-b112-00215aee3ebe;Хмарівка;Хмаровка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520481601;3;1
871623d5-749b-11df-b112-00215aee3ebe;Хмелева;Хмелевая;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122089601;3;1
871623d6-749b-11df-b112-00215aee3ebe;Хмелева;Хмелевая;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621655702;3;1
871623d7-749b-11df-b112-00215aee3ebe;Хмелева;Хмелевая;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621884605;3;1
871623d8-749b-11df-b112-00215aee3ebe;Хмелиська;Хмелиска;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124689401;3;1
871623d9-749b-11df-b112-00215aee3ebe;Хмелище;Хмелище;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820885803;3;1
871623da-749b-11df-b112-00215aee3ebe;Хмелів;Хмелев;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924189401;3;1
871623db-749b-11df-b112-00215aee3ebe;Хмелів;Хмелев;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720586004;3;1
871623dc-749b-11df-b112-00215aee3ebe;Хмелів;Хмелев;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123682503;3;1
871623dd-749b-11df-b112-00215aee3ebe;Хмелівець;Хмелевец;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;;3;1
871623de-749b-11df-b112-00215aee3ebe;Хмелівка;Хмелевка;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922387401;3;1
871623df-749b-11df-b112-00215aee3ebe;Хмелівка;Хмелевка;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720586001;3;1
871623e0-749b-11df-b112-00215aee3ebe;Хмелівка;Хмелевка;село;6b07f803-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125089201;3;1
871623e1-749b-11df-b112-00215aee3ebe;Хмелівка;Хмелевка;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620489001;3;1
871623e2-749b-11df-b112-00215aee3ebe;Хмелівка;Хмелевка;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224684002;2;1
871623e3-749b-11df-b112-00215aee3ebe;Хмелівка;Хмелевка;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824487205;3;1
871623e4-749b-11df-b112-00215aee3ebe;Хмелівка;Хмелевка;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825584605;3;1
871623e5-749b-11df-b112-00215aee3ebe;Хмелівка;Хмелевка;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821288201;3;1
871623e6-749b-11df-b112-00215aee3ebe;Хмелівка;Хмелевка;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620488901;3;1
871623e7-749b-11df-b112-00215aee3ebe;Хмелівщина;Хмелевщина;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525683603;3;1
871623e8-749b-11df-b112-00215aee3ebe;Хмельницьке;Хмельницкое;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720881303;3;1
871623e9-749b-11df-b112-00215aee3ebe;Хмельницьке;Хмельницкое;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222984504;3;1
871623eb-749b-11df-b112-00215aee3ebe;Хмельницьке;Хмельницкое;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524382908;3;1
871623ec-749b-11df-b112-00215aee3ebe;Хмельницький;Хмельницкий;місто;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6810100000;1;0
871623ef-749b-11df-b112-00215aee3ebe;Хмельове;Хмелевое;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820884802;3;1
871623f0-749b-11df-b112-00215aee3ebe;Хмельове;Хмелевое;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523186801;3;1
871623f9-749b-11df-b112-00215aee3ebe;Хмельове;Хмелевое;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521282609;3;1
871623f1-749b-11df-b112-00215aee3ebe;Хмельовик;Хмелевик;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220288704;3;1
871623f2-749b-11df-b112-00215aee3ebe;Хміль;Хмель;село;710c27ff-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625082605;3;1
871623f3-749b-11df-b112-00215aee3ebe;Хмільна;Хмельная;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222480805;3;1
871623f4-749b-11df-b112-00215aee3ebe;Хмільна;Хмельная;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122088901;3;1
871623f5-749b-11df-b112-00215aee3ebe;Хмільник;Хмельник;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121984804;3;1
871623f6-749b-11df-b112-00215aee3ebe;Хмільник;Хмельник;місто;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0510900000;2;0
871623f7-749b-11df-b112-00215aee3ebe;Хмільниця;Хмельница;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425589301;3;1
871623f8-749b-11df-b112-00215aee3ebe;Хмільно;Хмельно;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623987601;3;1
871623fa-749b-11df-b112-00215aee3ebe;Хобултова;Хобултова;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720586601;3;1
871623fb-749b-11df-b112-00215aee3ebe;Ховдіївка;Ховдиевка;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422481511;3;1
871623fc-749b-11df-b112-00215aee3ebe;Ховзівка;Ховзовка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923882307;3;1
871623fd-749b-11df-b112-00215aee3ebe;Ховківка;Ховковка;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125788003;3;1
871623fe-749b-11df-b112-00215aee3ebe;Ховми;Ховмы;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420888401;3;1
871623ff-749b-11df-b112-00215aee3ebe;Ходаки;Ходаки;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125887803;3;1
87162400-749b-11df-b112-00215aee3ebe;Ходаки;Ходаки;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386201;3;1
87162401-749b-11df-b112-00215aee3ebe;Ходаки;Ходаки;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520285303;3;1
87162402-749b-11df-b112-00215aee3ebe;Ходаківці;Ходаковцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825086402;3;1
87162403-749b-11df-b112-00215aee3ebe;Ходачки;Ходачки;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822387002;3;1
87162405-749b-11df-b112-00215aee3ebe;Ходине;Ходино;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921589301;3;1
87162406-749b-11df-b112-00215aee3ebe;Ходовичі;Ходовичи;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625388001;3;1
87162407-749b-11df-b112-00215aee3ebe;Ходори;Ходоры;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825086203;3;1
87162408-749b-11df-b112-00215aee3ebe;Ходорів;Ходоров;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621510500;3;0
87162409-749b-11df-b112-00215aee3ebe;Ходорів;Ходоров;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222981504;3;1
8716240a-749b-11df-b112-00215aee3ebe;Ходорівка;Ходоровка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825485203;3;1
8716240b-749b-11df-b112-00215aee3ebe;Ходорівці;Ходоровцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822489301;3;1
8716240c-749b-11df-b112-00215aee3ebe;Ходорків;Ходорков;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824788201;3;1
8716240d-749b-11df-b112-00215aee3ebe;Ходорківці;Ходорковцы;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621588509;3;1
8716240e-749b-11df-b112-00215aee3ebe;Ходоси;Ходосы;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624689505;3;1
8716240f-749b-11df-b112-00215aee3ebe;Ходосиха;Ходосиха;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755107;3;1
87162410-749b-11df-b112-00215aee3ebe;Ходосівка;Ходосовка;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222487001;2;0
87162411-749b-11df-b112-00215aee3ebe;Ходунаївка;Ходунаевка;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323583205;3;1
87162412-749b-11df-b112-00215aee3ebe;Ходуня;Ходуня;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921585204;3;1
87162413-749b-11df-b112-00215aee3ebe;Ходурки;Ходурки;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824082804;3;1
87162414-749b-11df-b112-00215aee3ebe;Холми;Холмы;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422455400;3;1
87162418-749b-11df-b112-00215aee3ebe;Холмовець;Холмовец;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121286202;3;1
87162419-749b-11df-b112-00215aee3ebe;Холмок;Холмок;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2124887401;3;1
8716241a-749b-11df-b112-00215aee3ebe;Холмське;Холмское;село;64024b48-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120486401;3;1
8716241b-749b-11df-b112-00215aee3ebe;Холмці;Холмци;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124880307;3;1
8716241c-749b-11df-b112-00215aee3ebe;Холодець;Холодец;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820988301;3;1
8716241e-749b-11df-b112-00215aee3ebe;Холодівка;Холодовка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524386801;3;1
8716241f-749b-11df-b112-00215aee3ebe;Холодівщина;Холодовщина;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;;3;1
87162420-749b-11df-b112-00215aee3ebe;Холодівщина;Холодовщина;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925383005;3;1
87162421-749b-11df-b112-00215aee3ebe;Холодіївка (Байдаківка);Холодиевка (Байдаковка);село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224588001;3;1
87162422-749b-11df-b112-00215aee3ebe;Холодна Балка;Холодная Балка;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121085401;3;1
87162423-749b-11df-b112-00215aee3ebe;Холодне;Холодное;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;;3;1
87162424-749b-11df-b112-00215aee3ebe;Холодне;Холодное;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923555608;3;1
87162425-749b-11df-b112-00215aee3ebe;Холодне;Холодное;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684705;3;1
87162427-749b-11df-b112-00215aee3ebe;Холодне;Холодное;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121581876;3;1
87162428-749b-11df-b112-00215aee3ebe;Холодне Плесо;Холодное Плесо;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321681003;3;1
87162429-749b-11df-b112-00215aee3ebe;Холодний Яр;Холодный Яр;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923581311;3;1
8716242a-749b-11df-b112-00215aee3ebe;Холодник;Холодник;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924180903;3;1
8716242b-749b-11df-b112-00215aee3ebe;Холодновідка;Холодноведка;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623683703;3;0
8716242c-749b-11df-b112-00215aee3ebe;Холоднянське;Холоднянское;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123784504;3;1
8716242d-749b-11df-b112-00215aee3ebe;Холоневичі;Холоневичи;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721855708;3;1
8716242e-749b-11df-b112-00215aee3ebe;Холонів;Холонов;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720888301;3;1
8716242f-749b-11df-b112-00215aee3ebe;Холопичі;Холопичи;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722485801;3;1
87162430-749b-11df-b112-00215aee3ebe;Холосне;Холосно;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386401;3;1
87162431-749b-11df-b112-00215aee3ebe;Холявки;Холявки;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423381908;3;1
8716244d-749b-11df-b112-00215aee3ebe;Хом'яківка;Хомяковка;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625887301;3;1
8716244e-749b-11df-b112-00215aee3ebe;Хом'яківка;Хомяковка;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625283302;3;1
8716244f-749b-11df-b112-00215aee3ebe;Хом'янка;Хомянка;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820982605;3;1
87162432-749b-11df-b112-00215aee3ebe;Хоменки;Хоменки;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321084509;3;1
87162433-749b-11df-b112-00215aee3ebe;Хоменки;Хоменки;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525388301;3;1
87162434-749b-11df-b112-00215aee3ebe;Хоменки;Хоменки;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324883215;3;1
87162435-749b-11df-b112-00215aee3ebe;Хоменкове;Хоменково;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922686308;3;1
87162436-749b-11df-b112-00215aee3ebe;Хоменкове;Хоменково;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923283206;3;1
87162439-749b-11df-b112-00215aee3ebe;Хомець;Хомец;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621886413;3;1
8716243a-749b-11df-b112-00215aee3ebe;Хоми;Хомы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122481802;3;1
8716243b-749b-11df-b112-00215aee3ebe;Хомина;Хомина;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623380403;3;1
8716243c-749b-11df-b112-00215aee3ebe;Хомине;Хомино;село;9b486f64-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924782204;2;0
8716243e-749b-11df-b112-00215aee3ebe;Хомине;Хомино;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423381909;3;1
8716243f-749b-11df-b112-00215aee3ebe;Хоминка;Хоминка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123981406;3;1
87162441-749b-11df-b112-00215aee3ebe;Хоминці;Хоминцы;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924189601;3;1
87162442-749b-11df-b112-00215aee3ebe;Хоминці;Хоминцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825089003;3;1
87162443-749b-11df-b112-00215aee3ebe;Хомичі;Хомичи;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725755110;3;1
87162444-749b-11df-b112-00215aee3ebe;Хомівка;Хомовка;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122687703;3;1
87162446-749b-11df-b112-00215aee3ebe;Хомівка;Хомовка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825084905;3;1
f9333459-db4e-11df-9197-00215aee3ebe;Хомівка;Хомовка;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;;3;1
f933345a-db4e-11df-9197-00215aee3ebe;Хомівка;Хомовка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423380407;3;1
87162447-749b-11df-b112-00215aee3ebe;Хомівці;Хомовцы;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420686804;3;1
87162448-749b-11df-b112-00215aee3ebe;Хомівщина;Хомовщина;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120689002;3;1
87162449-749b-11df-b112-00215aee3ebe;Хомутець;Хомутец;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820987001;3;1
8716244a-749b-11df-b112-00215aee3ebe;Хомутець;Хомутец;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323288201;3;1
8716244b-749b-11df-b112-00215aee3ebe;Хомутинці;Хомутинцы;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521688603;3;1
87162450-749b-11df-b112-00215aee3ebe;Хоняків;Хоняков;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823988401;3;1
87162451-749b-11df-b112-00215aee3ebe;Хоньківці;Хоньковцы;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522687401;3;1
87162452-749b-11df-b112-00215aee3ebe;Хопнів;Хопнев;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721887205;3;1
87162453-749b-11df-b112-00215aee3ebe;Хоптинці;Хоптынци;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821288601;3;1
87162454-749b-11df-b112-00215aee3ebe;Хоптянка;Хоптянка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124686303;3;1
87162455-749b-11df-b112-00215aee3ebe;Хорів;Хорев;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722485203;3;1
87162456-749b-11df-b112-00215aee3ebe;Хорів;Хорев;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624288801;3;1
87162457-749b-11df-b112-00215aee3ebe;Хорішки;Хоришки;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322085001;3;1
87162459-749b-11df-b112-00215aee3ebe;Хорлупи;Хорлупы;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721888001;3;1
8716245a-749b-11df-b112-00215aee3ebe;Хоробичі;Хоробичи;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421488801;3;1
8716245b-749b-11df-b112-00215aee3ebe;Хоробрів;Хоробров;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123080207;3;1
8716245c-749b-11df-b112-00215aee3ebe;Хоробрів;Хоробров;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624885701;3;1
8716245d-749b-11df-b112-00215aee3ebe;Хоровець;Хоровец;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823988801;3;1
8716245e-749b-11df-b112-00215aee3ebe;Хорови;Хоровы;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323583503;3;1
8716245f-749b-11df-b112-00215aee3ebe;Хоровиця;Хоровица;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823986805;3;1
87162460-749b-11df-b112-00215aee3ebe;Хорол;Хорол;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923582903;3;1
87162461-749b-11df-b112-00215aee3ebe;Хорол;Хорол;місто;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324810100;2;0
87162462-749b-11df-b112-00215aee3ebe;Хоросна;Хоросна;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623286203;3;1
87162463-749b-11df-b112-00215aee3ebe;Хоросниця;Хоросница;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622486905;3;1
87162464-749b-11df-b112-00215aee3ebe;Хоросно;Хоросно;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623685605;3;1
87162465-749b-11df-b112-00215aee3ebe;Хоростець;Хоростец;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123080202;3;1
87162466-749b-11df-b112-00215aee3ebe;Хоростків;Хоростков;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121611000;3;0
87162467-749b-11df-b112-00215aee3ebe;Хоросток;Хоросток;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823980305;3;1
87162468-749b-11df-b112-00215aee3ebe;Хорохорин;Хорохорин;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722886206;3;1
87162469-749b-11df-b112-00215aee3ebe;Хороцеве;Хороцево;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;2620887701;3;1
8716246a-749b-11df-b112-00215aee3ebe;Хороша;Хороша;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522286104;3;1
8716246b-749b-11df-b112-00215aee3ebe;Хороша;Хороша;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520885409;3;1
8716246c-749b-11df-b112-00215aee3ebe;Хороше;Хорошее;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223887101;3;1
8716246d-749b-11df-b112-00215aee3ebe;Хороше;Хорошее;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224283408;3;1
8716246f-749b-11df-b112-00215aee3ebe;Хороше Озеро;Хорошее Озеро;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420888801;3;1
87162470-749b-11df-b112-00215aee3ebe;Хорошеве;Хорошево;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224884008;3;1
87162471-749b-11df-b112-00215aee3ebe;Хорошеве;Хорошево;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325159100;3;0
87162473-749b-11df-b112-00215aee3ebe;Хорошів;Хорошев;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820389001;3;1
4aba3e57-749b-11df-b112-00215aee3ebe;Хорошів;Хорошів;місто;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821100000;3;0
87162474-749b-11df-b112-00215aee3ebe;Хорошки;Хорошки;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322887901;3;1
87162475-749b-11df-b112-00215aee3ebe;Хорошки;Хорошки;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323687404;3;1
87162476-749b-11df-b112-00215aee3ebe;Хортиця;Хортица;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421182605;3;1
a3e8e052-d4a5-11e0-a755-003048d2b473;Хортиця;Хортица;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322183507;1;0
87162477-749b-11df-b112-00215aee3ebe;Хоружі;Хоружи;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324210108;3;1
87162478-749b-11df-b112-00215aee3ebe;Хоружівка;Хоружевка;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923586701;3;1
87162479-749b-11df-b112-00215aee3ebe;Хоружівка;Хоружевка;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323682604;3;1
8716247a-749b-11df-b112-00215aee3ebe;Хорупань;Хорупань;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623889401;3;1
8716247b-749b-11df-b112-00215aee3ebe;Хотень;Хотень;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822185100;3;1
8716247c-749b-11df-b112-00215aee3ebe;Хотень Другий;Хотень Второй;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822185104;3;1
a5388eba-db65-11df-9197-00215aee3ebe;Хотень Перший;Хотень Первый;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822185103;3;1
8716247d-749b-11df-b112-00215aee3ebe;Хотешів;Хотешов;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721487901;3;1
8716247e-749b-11df-b112-00215aee3ebe;Хотивель;Хотивель;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725083203;3;1
8716247f-749b-11df-b112-00215aee3ebe;Хотиж;Хотыж;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821785205;3;1
87162480-749b-11df-b112-00215aee3ebe;Хотимир;Хотимир;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625688201;3;1
87162481-749b-11df-b112-00215aee3ebe;Хотин;Хотин;місто;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7325010100;3;0
87162482-749b-11df-b112-00215aee3ebe;Хотин;Хотин;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620488303;3;1
87162483-749b-11df-b112-00215aee3ebe;Хотин;Хотин;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624689506;3;1
87162484-749b-11df-b112-00215aee3ebe;Хотин;Хотин;село;6b07f8b7-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;5625889001;3;1
87162485-749b-11df-b112-00215aee3ebe;Хотинівка;Хотиновка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386601;3;1
87162486-749b-11df-b112-00215aee3ebe;Хотинівка;Хотиновка;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423886701;3;1
87162487-749b-11df-b112-00215aee3ebe;Хотів;Хотов;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222487201;2;0
87162488-749b-11df-b112-00215aee3ebe;Хотівка;Хотовка;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123481403;3;1
87162489-749b-11df-b112-00215aee3ebe;Хотівля;Хотивля;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421489201;3;1
8716248a-749b-11df-b112-00215aee3ebe;Хотіївка;Хотиевка;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424787001;3;1
f9333458-db4e-11df-9197-00215aee3ebe;Хотіївка;Хотиевка;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422489401;3;1
8716248c-749b-11df-b112-00215aee3ebe;Хотінь;Хотень;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924756200;3;1
8716248d-749b-11df-b112-00215aee3ebe;Хотминівка;Хотминовка;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921584805;3;1
8716248e-749b-11df-b112-00215aee3ebe;Хотовиця;Хотовица;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123485103;3;1
8716248f-749b-11df-b112-00215aee3ebe;Хотуничі;Хотуничи;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425889201;3;1
87162490-749b-11df-b112-00215aee3ebe;Хотянівка;Хотяновка;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221888801;3;1
87162491-749b-11df-b112-00215aee3ebe;Хотячів;Хотячив;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720510423;3;1
87162492-749b-11df-b112-00215aee3ebe;Хотьківці;Хотьковцы;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822787404;3;1
87162493-749b-11df-b112-00215aee3ebe;Хохітва;Хохитва;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220687601;3;1
87162494-749b-11df-b112-00215aee3ebe;Хохонів;Хохонев;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621282403;3;1
87162495-749b-11df-b112-00215aee3ebe;Хоцунь;Хоцунь;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155134;3;1
87162496-749b-11df-b112-00215aee3ebe;Хоцьки;Хоцки;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223387801;3;1
87162497-749b-11df-b112-00215aee3ebe;Хочева;Хочева;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222083203;3;1
87162498-749b-11df-b112-00215aee3ebe;Хочин;Хочин;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621889203;3;1
87162499-749b-11df-b112-00215aee3ebe;Хочине;Хочино;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824487601;3;1
8716249a-749b-11df-b112-00215aee3ebe;Храбузна;Храбузна;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122681104;3;1
8716249b-749b-11df-b112-00215aee3ebe;Храбузна;Храбузна;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823686003;3;1
8716249c-749b-11df-b112-00215aee3ebe;Храпате;Храпатое;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425584905;3;1
8716249d-749b-11df-b112-00215aee3ebe;Храпачі;Храпачи;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220488601;2;1
8716249e-749b-11df-b112-00215aee3ebe;Храпачів Яр;Храпачов Яр;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321384006;3;1
8716249f-749b-11df-b112-00215aee3ebe;Храпин;Храпин;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622282702;3;1
871624a0-749b-11df-b112-00215aee3ebe;Храпівщина;Храповщина;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924785908;3;1
871624a1-749b-11df-b112-00215aee3ebe;Хребтіїв;Хребтиев;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823388504;3;1
871624a2-749b-11df-b112-00215aee3ebe;Хренів;Хренив;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721181205;3;1
871624a3-749b-11df-b112-00215aee3ebe;Хренів;Хренив;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622182303;3;1
871624a4-749b-11df-b112-00215aee3ebe;Хрестителеве;Крестителево;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125184001;3;1
871624a5-749b-11df-b112-00215aee3ebe;Хрестище;Крестище;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323381501;3;1
68cf2c50-749b-11df-b112-00215aee3ebe;Хрестівка (Незаможник);Хрестівка (Незаможник);село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120355401;3;1
871624a8-749b-11df-b112-00215aee3ebe;Хрещате;Крещатое;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281304;3;1
871624a9-749b-11df-b112-00215aee3ebe;Хрещате;Крещатое;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422084701;3;1
871624aa-749b-11df-b112-00215aee3ebe;Хрещатик;Крещатик;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922681208;3;1
871624ab-749b-11df-b112-00215aee3ebe;Хрещатик;Крещатик;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924184907;3;1
871624ac-749b-11df-b112-00215aee3ebe;Хрещатик;Крещатик;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321585401;3;1
871624ad-749b-11df-b112-00215aee3ebe;Хрещатик;Крещатик;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124987503;3;1
871624ae-749b-11df-b112-00215aee3ebe;Хрещенівка;Крещеновка;село;78f4540d-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6524182501;3;1
871624af-749b-11df-b112-00215aee3ebe;Хрещикове;Крещиково;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;;3;1
871624b0-749b-11df-b112-00215aee3ebe;Хрипаличі;Хрипаличи;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720510424;3;1
871624b1-749b-11df-b112-00215aee3ebe;Хрипівка;Хриповка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421489601;3;1
871624b2-749b-11df-b112-00215aee3ebe;Хрипки;Хрипки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321387808;3;1
871624b3-749b-11df-b112-00215aee3ebe;Хриплин;Хриплин;село;a1e9f9b8-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2610197401;2;0
871624b4-749b-11df-b112-00215aee3ebe;Хрипськ;Хрипск;село;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725785005;3;1
871624b6-749b-11df-b112-00215aee3ebe;Христанівка;Христановка;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322681503;3;1
871624b7-749b-11df-b112-00215aee3ebe;Христинівка;Христиновка;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755162;3;1
871624b8-749b-11df-b112-00215aee3ebe;Христинівка;Христиновка;місто;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124610100;2;0
8cdd5cfc-de27-11df-9197-00215aee3ebe;Христинівка село;Христиновка село;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124610101;3;1
871624b9-749b-11df-b112-00215aee3ebe;Христівка;Христевка;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755156;3;1
871624ba-749b-11df-b112-00215aee3ebe;Христівка;Христевка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822188401;3;1
44ac847a-749b-11df-b112-00215aee3ebe;Христо-Ботеве (Благоєве);Христо-Ботеве (Благоєве);село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122783003;3;1
871624bc-749b-11df-b112-00215aee3ebe;Христофорівка;Христофоровка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221855800;3;1
871624bd-749b-11df-b112-00215aee3ebe;Христофорівка;Христофоровка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222983410;3;1
871624be-749b-11df-b112-00215aee3ebe;Христофорівка;Христофоровка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610122;3;1
871624c0-749b-11df-b112-00215aee3ebe;Христофорове;Христофорово;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525583603;3;1
871624c1-749b-11df-b112-00215aee3ebe;Хрінів;Хренов;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624287602;3;1
871624c2-749b-11df-b112-00215aee3ebe;Хрінівка;Хриновка;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425881003;3;1
871624c3-749b-11df-b112-00215aee3ebe;Хрінівка;Хриновка;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521210118;3;1
871624c4-749b-11df-b112-00215aee3ebe;Хрінники;Хренники;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621485512;3;1
871624c5-749b-11df-b112-00215aee3ebe;Хроли;Хролы;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325183009;3;1
871624c6-749b-11df-b112-00215aee3ebe;Хролин;Хролин;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825589001;3;1
871624c9-749b-11df-b112-00215aee3ebe;Хром'яків;Хромяков;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721881607;3;1
871624c8-749b-11df-b112-00215aee3ebe;Хромогорб;Хромогорб;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625384402;3;1
871624ca-749b-11df-b112-00215aee3ebe;Хропотова;Хропотова;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825288201;2;1
871624cb-749b-11df-b112-00215aee3ebe;Хрулі;Хрули;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322681107;3;1
871624cd-749b-11df-b112-00215aee3ebe;Хрущівка;Хрущовка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121587403;3;1
871624ce-749b-11df-b112-00215aee3ebe;Хрущова Микитівка;Хрущовая Никитовка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320888501;3;1
871624cf-749b-11df-b112-00215aee3ebe;Хряськ;Хряск;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723687605;3;1
871624d0-749b-11df-b112-00215aee3ebe;Хряцька;Хряцка;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320786001;3;1
871624d3-749b-11df-b112-00215aee3ebe;Худиківці;Худыковцы;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
871624d5-749b-11df-b112-00215aee3ebe;Худіївці;Худеевцы;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120888802;3;1
871624d6-749b-11df-b112-00215aee3ebe;Худльово;Худлево;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124887601;3;1
871624d7-749b-11df-b112-00215aee3ebe;Худоліївка;Худолиевка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324588801;3;1
871624d8-749b-11df-b112-00215aee3ebe;Худоліївка;Худолиевка;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125488001;3;1
871624d9-749b-11df-b112-00215aee3ebe;Худоярове;Худоярово;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325783007;3;1
871624da-749b-11df-b112-00215aee3ebe;Худяки;Худяки;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124988501;3;1
871624db-749b-11df-b112-00215aee3ebe;Хукалівка;Хукаловка;село;903ccabc-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425187004;3;1
871624dc-749b-11df-b112-00215aee3ebe;Хуст;Хуст;місто;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2110800000;2;0
871624dd-749b-11df-b112-00215aee3ebe;Хустець;Хустец;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125384004;3;1
871624de-749b-11df-b112-00215aee3ebe;Хустянка;Хустянка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920987901;3;1
871624df-749b-11df-b112-00215aee3ebe;Хутір;Хутор;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825583203;3;1
871624e0-749b-11df-b112-00215aee3ebe;Хутір Верхівка;Хутор Верховка;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524180801;3;1
871624e1-749b-11df-b112-00215aee3ebe;Хутір Глинське;Хутор Глинское;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520484206;3;1
74dc499d-749b-11df-b112-00215aee3ebe;Хутір Дашківський (Петрівське);Хутор Дашкивский (Петровское);село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824487004;3;1
871624e3-749b-11df-b112-00215aee3ebe;Хутір Зайців;Хутор Зайцев;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221485503;3;1
871624e4-749b-11df-b112-00215aee3ebe;Хутір Партизанський;Хутор Партинзанский;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;;3;1
871624e5-749b-11df-b112-00215aee3ebe;Хутір Пестеля;Хутор Пестеля;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
871624e6-749b-11df-b112-00215aee3ebe;Хутір Петрівський;Хутор Петровский;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;;3;1
c6d5cf9a-6171-11ec-80e4-000c29800ae7;Хутір Редьки-1;Хутор Редьки-1;село;00000000-0000-0000-0000-000000000000;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;;2;1
871624e7-749b-11df-b112-00215aee3ebe;Хутір Травневе;Хутор Майский;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521085806;3;1
871624e8-749b-11df-b112-00215aee3ebe;Хутір-Будилів;Хутор-Будилов;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625286401;2;1
871624e9-749b-11df-b112-00215aee3ebe;Хутір-Мокляки;Хутор-Мокляки;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821783404;3;1
871624ea-749b-11df-b112-00215aee3ebe;Хутірське;Хуторское;село;bad3ac3c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1223755102;3;1
871624eb-749b-11df-b112-00215aee3ebe;Хутір-Хмільна;Хутор-Хмельная;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122088907;3;1
871624ec-749b-11df-b112-00215aee3ebe;Хутомир;Хутомир;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155135;3;1
871624ed-749b-11df-b112-00215aee3ebe;Хутор-Дубовина;Хутор-Дубовина;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
871624ee-749b-11df-b112-00215aee3ebe;Хутори;Хуторы;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124988701;3;0
871624ef-749b-11df-b112-00215aee3ebe;Хутори;Хуторы;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824283205;3;1
871624f0-749b-11df-b112-00215aee3ebe;Хутори-Кривошиїнецькі;Хуторы-Кривошеецкие;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524884203;3;1
871624f1-749b-11df-b112-00215aee3ebe;Хуторо-Губиниха;Хуторо-Губиниха;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223286503;3;1
871624f3-749b-11df-b112-00215aee3ebe;Хуторо-Чаплине;Хуторо-Чаплино;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220788804;3;1
871624f4-749b-11df-b112-00215aee3ebe;Хухра;Хухра;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920388701;3;1
871624f5-749b-11df-b112-00215aee3ebe;Цапівка;Цаповка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322655111;3;1
871624f6-749b-11df-b112-00215aee3ebe;Цапівка;Цаповка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;;3;1
871624f7-749b-11df-b112-00215aee3ebe;Цапіївка;Цапиевка;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224083202;3;1
871624f8-749b-11df-b112-00215aee3ebe;Царедарівка;Царедаровка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822755109;3;1
871624f9-749b-11df-b112-00215aee3ebe;Царедарівка;Царедаровка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323985501;3;1
871624fa-749b-11df-b112-00215aee3ebe;Царичанка;Царичанка;місто;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655100;2;0
871624fb-749b-11df-b112-00215aee3ebe;Царичанка;Царичанка;село;6b07f828-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5125082409;2;1
871624fe-749b-11df-b112-00215aee3ebe;Царівка;Царевка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510127;3;1
871624ff-749b-11df-b112-00215aee3ebe;Цвіжин;Цвижин;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520682006;3;1
87162500-749b-11df-b112-00215aee3ebe;Цвіклівці;Цвикловцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822486803;3;1
87162501-749b-11df-b112-00215aee3ebe;Цвіклівці Другі;Цвикловцы Вторые;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822489108;3;1
87162502-749b-11df-b112-00215aee3ebe;Цвіклівці Перші;Цвикловцы Первые;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822486803;3;1
87162503-749b-11df-b112-00215aee3ebe;Цвіле;Цвелое;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224588007;3;1
87162504-749b-11df-b112-00215aee3ebe;Цвіліхівка;Цвилиховка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523782603;3;1
87162505-749b-11df-b112-00215aee3ebe;Цвіт;Цвет;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825587004;3;1
87162506-749b-11df-b112-00215aee3ebe;Цвіткове;Цветковое;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225884816;3;1
87162507-749b-11df-b112-00215aee3ebe;Цвіткове;Цветковое;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;3;1
8716250b-749b-11df-b112-00215aee3ebe;Цвіткове;Цветковое;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7120355700;3;1
8716250c-749b-11df-b112-00215aee3ebe;Цвіткове;Цветковое;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521481802;3;1
56bdd238-749b-11df-b112-00215aee3ebe;Цвіткове (Жовтневе );Жовтневое;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822784013;3;1
8716250d-749b-11df-b112-00215aee3ebe;Цвітне;Цветное;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520588501;3;1
8716250f-749b-11df-b112-00215aee3ebe;Цвітова;Цветовая;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121288001;3;1
87162510-749b-11df-b112-00215aee3ebe;Цвітова;Цветовая;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622884204;3;1
87162511-749b-11df-b112-00215aee3ebe;Цвітове;Цветово;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321381003;3;1
87162512-749b-11df-b112-00215aee3ebe;Цвітоха;Цветоха;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823989201;3;1
87162515-749b-11df-b112-00215aee3ebe;Цвітянка;Цветянка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825482604;3;1
87162516-749b-11df-b112-00215aee3ebe;Цеберка;Цеберка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821483207;3;1
87162517-749b-11df-b112-00215aee3ebe;Цеблів;Цеблов;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624882105;3;1
87162518-749b-11df-b112-00215aee3ebe;Цебрикове;Цебриково;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655500;3;1
87162519-749b-11df-b112-00215aee3ebe;Цебрів;Цебров;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122689201;3;1
8716251a-749b-11df-b112-00215aee3ebe;Цевеличі;Цевеличи;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722483407;3;1
8716251b-749b-11df-b112-00215aee3ebe;Цегів;Цегов;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720888601;3;1
8716251c-749b-11df-b112-00215aee3ebe;Цекинівка;Цекиновка;село;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525687701;3;1
8716251d-749b-11df-b112-00215aee3ebe;Целіїв;Целиев;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121689001;3;1
aa7bf358-9113-11eb-80d2-000c29800ae7;Ценжів;Ценжив;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2625882701;3;1
8716251e-749b-11df-b112-00215aee3ebe;Ценів;Ценев;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123088401;3;1
8716251f-749b-11df-b112-00215aee3ebe;Центральне;Центральное;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825784701;3;1
87162520-749b-11df-b112-00215aee3ebe;Центральне;Центральное;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222988301;3;1
87162521-749b-11df-b112-00215aee3ebe;Центральне;Центральное;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425584906;3;1
87162524-749b-11df-b112-00215aee3ebe;Ценява;Ценява;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623288201;3;1
87162525-749b-11df-b112-00215aee3ebe;Цеперів;Цеперов;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722880303;3;1
87162526-749b-11df-b112-00215aee3ebe;Цеперів;Цеперов;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622186204;3;1
87162527-749b-11df-b112-00215aee3ebe;Цепочкине;Цепочкино;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323281010;3;1
87162528-749b-11df-b112-00215aee3ebe;Цепцевичі;Цепцевичи;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625487604;3;1
87162529-749b-11df-b112-00215aee3ebe;Церковна;Церковна;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622080509;3;1
8716252a-749b-11df-b112-00215aee3ebe;Цетуля;Цетуля;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625884510;3;1
8716252b-749b-11df-b112-00215aee3ebe;Цеханівка;Цехановка;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123185201;3;1
8716252c-749b-11df-b112-00215aee3ebe;Цеценівка;Цеценевка;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125888701;3;1
8716252d-749b-11df-b112-00215aee3ebe;Цецівка;Цецовка;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122682530;3;1
8716252e-749b-11df-b112-00215aee3ebe;Цибиківка;Цибиковка;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;;3;1
8716252f-749b-11df-b112-00215aee3ebe;Цибівка;Цибовка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322083005;3;1
87162531-749b-11df-b112-00215aee3ebe;Циблі;Цибли;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223388201;3;1
8cdd5cd6-de27-11df-9197-00215aee3ebe;Циблі Перші;Цибли Первые;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;;3;1
87162532-749b-11df-b112-00215aee3ebe;Цибулеве;Цибулево;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522288501;3;1
87162533-749b-11df-b112-00215aee3ebe;Цибуленки;Цибуленки;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923581312;3;1
87162534-749b-11df-b112-00215aee3ebe;Цибулі;Цыбули;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083912;3;1
87162535-749b-11df-b112-00215aee3ebe;Цибулів;Цибулев;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123455500;3;1
87162536-749b-11df-b112-00215aee3ebe;Цибулів Перший;Цибулев Первый;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122583701;3;1
87162537-749b-11df-b112-00215aee3ebe;Цибулівка;Цибулевка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121686001;3;1
87162538-749b-11df-b112-00215aee3ebe;Цибулівка;Цибулевка;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524186501;3;1
87162539-749b-11df-b112-00215aee3ebe;Цибулівка;Цибулевка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822485905;2;1
8716253a-749b-11df-b112-00215aee3ebe;Цибульки;Цыбульки;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821480408;3;1
8716253b-749b-11df-b112-00215aee3ebe;Цибульківка;Цибульковка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225685001;3;1
8716253c-749b-11df-b112-00215aee3ebe;Цигани;Цыганы;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120886801;3;1
8716253d-749b-11df-b112-00215aee3ebe;Циганівка;Цыгановка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224883006;3;1
8716253e-749b-11df-b112-00215aee3ebe;Циганівці;Цыгановцы;село;fbfb8c23-41be-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2124881202;3;1
8716253f-749b-11df-b112-00215aee3ebe;Циганське;Цыганское;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324081409;3;1
87162540-749b-11df-b112-00215aee3ebe;Цикали;Цыкалы;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281806;3;1
87162541-749b-11df-b112-00215aee3ebe;Циків;Цыков;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620655603;3;1
87162542-749b-11df-b112-00215aee3ebe;Циків;Цыков;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622485606;3;1
87162543-749b-11df-b112-00215aee3ebe;Цикова;Цикова;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825288601;3;1
87162544-749b-11df-b112-00215aee3ebe;Цикове (Левадне);Циково (Левадне);село;00000000-0000-0000-0000-000000000000;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224255117;3;1
87162545-749b-11df-b112-00215aee3ebe;Цилюрики;Цылюрики;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322684511;3;1
87162546-749b-11df-b112-00215aee3ebe;Цимбалівка;Цимбаловка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920689503;3;1
87162547-749b-11df-b112-00215aee3ebe;Цимбалівка;Цимбаловка;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824488001;3;1
87162548-749b-11df-b112-00215aee3ebe;Цимбалове;Цымбалово;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320486504;3;1
87162549-749b-11df-b112-00215aee3ebe;Цир;Цир;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723155136;3;1
8716254a-749b-11df-b112-00215aee3ebe;Циркуни;Циркуны;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325185001;2;1
8716254b-749b-11df-b112-00215aee3ebe;Цисеве;Цысево;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323280404;3;1
8716254c-749b-11df-b112-00215aee3ebe;Цитуля;Цытуля;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622784604;3;1
8716254d-749b-11df-b112-00215aee3ebe;Цицори;Цыцоры;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123080802;3;1
e831f422-e268-11e8-80d7-1c98ec135261;Цівки;Цивкы;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224581203;3;1
8716254e-749b-11df-b112-00215aee3ebe;Цівківці;Цывковцы;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823381003;3;1
87162523-749b-11df-b112-00215aee3ebe;Цінева;Циневая;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624886201;3;1
87162551-749b-11df-b112-00215aee3ebe;Ціпівки;Цеповки;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625881912;3;1
87162552-749b-11df-b112-00215aee3ebe;Ціпки;Цепки;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320488501;3;1
87162553-749b-11df-b112-00215aee3ebe;Цмівка;Цмовка;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825584606;3;1
87162554-749b-11df-b112-00215aee3ebe;Цміни;Цмины;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723687601;3;1
87162557-749b-11df-b112-00215aee3ebe;Цумань;Цумань;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721855700;3;1
87162558-749b-11df-b112-00215aee3ebe;Цупівка;Цуповка;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322057001;3;1
8716255a-749b-11df-b112-00215aee3ebe;Цурень;Цурень;село;6b07f84d-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320786201;3;1
8716255b-749b-11df-b112-00215aee3ebe;Цурків;Цурков;село;78f453ec-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5622685603;3;1
8716255c-749b-11df-b112-00215aee3ebe;Цуцилів;Цуцилов;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624087001;3;1
73e94c09-ddc1-11df-9197-00215aee3ebe;Цуцулин;Цуцулин;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623687602;3;1
8716255f-749b-11df-b112-00215aee3ebe;Цяцьківка;Цяцковка;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
87162560-749b-11df-b112-00215aee3ebe;Цьови;Цевы;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755157;3;1
87162561-749b-11df-b112-00215aee3ebe;Чабан;Чабан;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824285207;3;1
87162562-749b-11df-b112-00215aee3ebe;Чабани;Чабаны;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222457400;2;0
87162563-749b-11df-b112-00215aee3ebe;Чабани;Чабаны;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825089001;3;1
87162564-749b-11df-b112-00215aee3ebe;Чабанівка;Чабановка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123381406;3;1
87162565-749b-11df-b112-00215aee3ebe;Чабанівка;Чабановка;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124882703;3;1
87162567-749b-11df-b112-00215aee3ebe;Чабанівка;Чабановка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822489501;3;1
87162568-749b-11df-b112-00215aee3ebe;Чабанове;Чабаново;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221887006;3;1
8716256a-749b-11df-b112-00215aee3ebe;Чабанове;Чабаново;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523283005;3;1
8716256b-749b-11df-b112-00215aee3ebe;Чабанське;Чабанское;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120885606;3;1
8716256c-749b-11df-b112-00215aee3ebe;Чабарівка;Чабаровка;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121689301;3;1
8716256d-749b-11df-b112-00215aee3ebe;Чабель;Чабель;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625486802;3;1
8716256e-749b-11df-b112-00215aee3ebe;Чабин;Чабин;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122786207;3;1
8716256f-749b-11df-b112-00215aee3ebe;Чагарі;Чагари;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121683004;3;1
87162570-749b-11df-b112-00215aee3ebe;Чагарівка;Чагаровка;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825285805;2;1
87162571-749b-11df-b112-00215aee3ebe;Чагарі-Збаразькі;Чагари-Збаражские;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122486403;3;1
87162572-749b-11df-b112-00215aee3ebe;Чагів;Чагов;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523186301;3;1
87162573-749b-11df-b112-00215aee3ebe;Чагівське;Чаговское;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523186304;3;1
87162574-749b-11df-b112-00215aee3ebe;Чагор;Чагор;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7321087901;3;0
87162575-749b-11df-b112-00215aee3ebe;Чагрів;Чагров;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624486601;3;1
87162577-749b-11df-b112-00215aee3ebe;Чайки;Чайки;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455127;3;1
87162578-749b-11df-b112-00215aee3ebe;Чайки;Чайки;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222485903;1;0
87162579-749b-11df-b112-00215aee3ebe;Чайки;Чайки;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220683602;3;1
8716257a-749b-11df-b112-00215aee3ebe;Чайкине;Чайкино;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520682406;3;1
8716257d-749b-11df-b112-00215aee3ebe;Чайкине;Чайкино;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423689301;3;1
8716257e-749b-11df-b112-00215aee3ebe;Чайківка;Чайковка;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322055904;3;1
87162580-749b-11df-b112-00215aee3ebe;Чайківка;Чайковка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123380905;3;1
87162581-749b-11df-b112-00215aee3ebe;Чайківка;Чайковка;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825088801;3;1
87162582-749b-11df-b112-00215aee3ebe;Чайківка;Чайковка;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124687003;3;1
87162583-749b-11df-b112-00215aee3ebe;Чайківське;Чайковское;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123383205;3;1
87162584-749b-11df-b112-00215aee3ebe;Чайківщина;Чайковщина;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323686403;3;1
87162585-749b-11df-b112-00215aee3ebe;Чайковичі;Чайковичи;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624288901;3;1
87162587-749b-11df-b112-00215aee3ebe;Чайчинці;Чайчинцы;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123885701;3;1
87162588-749b-11df-b112-00215aee3ebe;Чаква;Чаква;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5620880302;3;1
87162589-749b-11df-b112-00215aee3ebe;Чалищівка;Чалищевка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920986802;3;1
8716258a-749b-11df-b112-00215aee3ebe;Чалівка;Чаловка;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321682506;3;1
8716258b-749b-11df-b112-00215aee3ebe;Чаниж;Чаныж;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620687001;3;1
8716258c-749b-11df-b112-00215aee3ebe;Чаньків;Чаньков;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821889501;3;1
87162596-749b-11df-b112-00215aee3ebe;Чапаєве;Чапаеве;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223556103;3;1
0790d972-9ca5-11e0-82d0-003048d2b473;Чапаєве;Чапаево;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;;3;1
b36f1a43-db87-11df-9197-00215aee3ebe;Чапаєвка;Чапаевка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6324886505;3;1
8716259c-749b-11df-b112-00215aee3ebe;Чапаєвка;Чапаевка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;*122188471;3;1
871625a1-749b-11df-b112-00215aee3ebe;Чапаєвка;Чапаевка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321581305;3;1
871625a9-749b-11df-b112-00215aee3ebe;Чапаєвка;Чапаевка;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122583701;3;1
871625a7-749b-11df-b112-00215aee3ebe;Чапаєвка;Чапаевка;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822886502;3;1
871625ad-749b-11df-b112-00215aee3ebe;Чапаєвка;Чапаевка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822787007;3;1
871625ae-749b-11df-b112-00215aee3ebe;Чапаївка;Чапаевка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;;3;1
871625b0-749b-11df-b112-00215aee3ebe;Чаплине;Чаплино;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755700;3;1
871625b1-749b-11df-b112-00215aee3ebe;Чаплинка;Чаплинка;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225987801;3;1
871625b2-749b-11df-b112-00215aee3ebe;Чаплинка;Чаплинка;село;bad3ac3c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1223783401;3;1
871625b4-749b-11df-b112-00215aee3ebe;Чаплинка;Чаплинка;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122888001;3;1
871625b5-749b-11df-b112-00215aee3ebe;Чаплинка;Чаплинка;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325185507;3;1
871625b6-749b-11df-b112-00215aee3ebe;Чаплинці;Чаплинцы;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324580804;3;1
871625b7-749b-11df-b112-00215aee3ebe;Чаплищі;Чаплищи;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923888007;3;1
871625b8-749b-11df-b112-00215aee3ebe;Чаплі;Чапли;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625188201;3;1
871625b9-749b-11df-b112-00215aee3ebe;Чапліївка;Чаплиевка;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925387301;3;1
871625ba-749b-11df-b112-00215aee3ebe;Чапля;Чапля;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823086201;3;1
871625bb-749b-11df-b112-00215aee3ebe;Чарівне;Чаривное;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123580407;3;1
871625bd-749b-11df-b112-00215aee3ebe;Чарівне;Чаривное;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520984801;3;1
871625be-749b-11df-b112-00215aee3ebe;Чарівне;Чаривное;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520887801;3;1
871625bf-749b-11df-b112-00215aee3ebe;Чаруків;Чаруков;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722886801;3;1
871625c1-749b-11df-b112-00215aee3ebe;Часлівці;Часловцы;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124887701;3;1
871625c2-749b-11df-b112-00215aee3ebe;Часниківка;Часниковка;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420383004;3;1
871625c3-749b-11df-b112-00215aee3ebe;Часниківка;Часниковка;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322681908;3;1
871625c4-749b-11df-b112-00215aee3ebe;Часнівці;Часновцы;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422080904;3;1
871625c5-749b-11df-b112-00215aee3ebe;Чаульське;Чаульское;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523084603;3;1
871625c6-749b-11df-b112-00215aee3ebe;Чаус;Чаус;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222685511;3;1
871625c7-749b-11df-b112-00215aee3ebe;Чаусове;Чаусово;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825488201;3;1
e0517ee3-db89-11df-9197-00215aee3ebe;Чаусове Друге;Чаусово Второе;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825484090;3;1
871625c8-749b-11df-b112-00215aee3ebe;Чеберяки;Чеберяки;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924184404;3;1
871625c9-749b-11df-b112-00215aee3ebe;Чевель;Чевель;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725084003;3;1
871625ca-749b-11df-b112-00215aee3ebe;Чевельча;Чевельча;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323687001;3;1
871625cb-749b-11df-b112-00215aee3ebe;Чекно;Чекно;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623889807;3;1
871625ce-749b-11df-b112-00215aee3ebe;Чемер;Чемер;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422089801;3;1
871625cf-749b-11df-b112-00215aee3ebe;Чемерин;Чемерин;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721882903;3;1
871625d0-749b-11df-b112-00215aee3ebe;Чемеринці;Чемеринцы;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623388301;3;1
871625d1-749b-11df-b112-00215aee3ebe;Чемериси-Барські;Чемерисы-Барские;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520280209;3;1
871625d2-749b-11df-b112-00215aee3ebe;Чемериське;Чемерисское;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121288201;3;1
871625d3-749b-11df-b112-00215aee3ebe;Чемериське;Чемерисское;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520285603;3;1
871625d4-749b-11df-b112-00215aee3ebe;Чемерівці;Чемеровцы;місто;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825255100;3;0
7ad94f3e-749b-11df-b112-00215aee3ebe;Чемерлієве (Радгоспне);Чемерлієве (Радгоспне);село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824282706;3;1
871625d5-749b-11df-b112-00215aee3ebe;Чемерне;Чемерное;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625487605;3;1
871625d6-749b-11df-b112-00215aee3ebe;Чемерпіль;Чемерполь;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521187601;3;1
871625d7-749b-11df-b112-00215aee3ebe;Чемоданівка;Чемодановка;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923582204;3;1
871625d8-749b-11df-b112-00215aee3ebe;Чемужівка;Чемужовка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321786501;3;1
871625d9-749b-11df-b112-00215aee3ebe;Ченчики;Ченчики;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422455403;3;1
871625da-749b-11df-b112-00215aee3ebe;Чепа;Чепа;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121285901;3;1
871625db-749b-11df-b112-00215aee3ebe;Чепелі;Чепели;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322686511;3;1
871625dc-749b-11df-b112-00215aee3ebe;Чепелі;Чепели;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620384404;3;1
871625dd-749b-11df-b112-00215aee3ebe;Чепелі;Чепели;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524887405;3;1
871625de-749b-11df-b112-00215aee3ebe;Чепелів;Чепелив;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425889601;3;1
871625df-749b-11df-b112-00215aee3ebe;Чепелівка;Чепелевка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822789101;3;1
871625e1-749b-11df-b112-00215aee3ebe;Чепиліївка;Чепилиевка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220410513;2;1
871625e2-749b-11df-b112-00215aee3ebe;Чепине;Чепино;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221055803;3;1
17af7b4d-ddb9-11df-9197-00215aee3ebe;Чепири;Чепиры;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;;3;1
871625e3-749b-11df-b112-00215aee3ebe;Чепіжинці;Чепижинцы;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221682803;3;1
871625e4-749b-11df-b112-00215aee3ebe;Чепіль;Чепель;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320286401;3;1
871625e5-749b-11df-b112-00215aee3ebe;Чепоноси;Чепоносы;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325089001;3;1
871625e6-749b-11df-b112-00215aee3ebe;Чепці;Чепци;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822183305;3;1
871625e7-749b-11df-b112-00215aee3ebe;Черванівка;Червановка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920684906;3;1
871625e9-749b-11df-b112-00215aee3ebe;Червень;Червень;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
871625e8-749b-11df-b112-00215aee3ebe;Червеньово;Червенево;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122782805;2;1
871625ea-749b-11df-b112-00215aee3ebe;Червінка;Червинка;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523682704;3;1
871625eb-749b-11df-b112-00215aee3ebe;Червлене;Червленое;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922989101;3;1
871625ec-749b-11df-b112-00215aee3ebe;Червний Степ;Красный Луг;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324884005;3;1
871625ef-749b-11df-b112-00215aee3ebe;Червона Балка;Червоная Балка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222085508;3;1
871625f0-749b-11df-b112-00215aee3ebe;Червона Балка;Червоная Балка;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320483605;3;1
871625f1-749b-11df-b112-00215aee3ebe;Червона Балка;Червоная Балка;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322682409;3;1
871625f2-749b-11df-b112-00215aee3ebe;Червона Буда;Червоная Буда;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422486007;3;1
871625f4-749b-11df-b112-00215aee3ebe;Червона Волока;Червоная Волока;село;8067acbf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822887601;3;1
871625f5-749b-11df-b112-00215aee3ebe;Червона Воля;Червоная Воля;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824055612;3;1
871625f6-749b-11df-b112-00215aee3ebe;Червона Гірка;Червоная Горка;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922681710;3;1
871625f7-749b-11df-b112-00215aee3ebe;Червона Гірка;Червоная Горка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224588008;3;1
871625f8-749b-11df-b112-00215aee3ebe;Червона Гірка;Червоная Горка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320256004;3;1
871625f9-749b-11df-b112-00215aee3ebe;Червона Гірка;Червоная Горка;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121083203;3;1
871625fa-749b-11df-b112-00215aee3ebe;Червона Гірка;Червоная Горка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222780202;3;1
871625fb-749b-11df-b112-00215aee3ebe;Червона Гірка;Червоная Горка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420883603;3;1
871625fe-749b-11df-b112-00215aee3ebe;Червона Гута;Червоная Гута;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424485608;3;1
325c580c-ddd2-11df-9197-00215aee3ebe;Червона Дача;Червоная Дача;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120689702;3;1
871625ff-749b-11df-b112-00215aee3ebe;Червона Деріївка;Красная Дериевка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224587508;3;1
87162600-749b-11df-b112-00215aee3ebe;Червона Діброва;Червоная Диброва;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924788303;3;1
87162601-749b-11df-b112-00215aee3ebe;Червона Діброва;Червоная Диброва;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321084202;3;1
87162603-749b-11df-b112-00215aee3ebe;Червона Діброва;Червоная Диброва;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822482904;3;1
87162604-749b-11df-b112-00215aee3ebe;Червона Долина;Червоная Долина;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324080109;3;1
87162605-749b-11df-b112-00215aee3ebe;Червона Долина;Червоная Долина;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923255106;3;1
87162606-749b-11df-b112-00215aee3ebe;Червона Долина;Червоная Долина;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223584512;3;1
87162607-749b-11df-b112-00215aee3ebe;Червона Долина;Червоная Долина;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220787713;3;1
87162608-749b-11df-b112-00215aee3ebe;Червона Долина;Червоная Долина;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825785001;3;1
87162609-749b-11df-b112-00215aee3ebe;Червона Долина;Червоная Долина;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324880505;3;1
8716260a-749b-11df-b112-00215aee3ebe;Червона Долина;Червоная Долина;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523780402;3;1
8716260b-749b-11df-b112-00215aee3ebe;Червона Долина;Червоная Долина;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520810108;3;1
8716260c-749b-11df-b112-00215aee3ebe;Червона Дубина;Червоная Дубина;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824786203;3;1
8716260e-749b-11df-b112-00215aee3ebe;Червона Зірка;Червоная Зирка;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820684904;3;1
87162614-749b-11df-b112-00215aee3ebe;Червона Зірка;Червоная Зирка;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223585204;3;1
87162615-749b-11df-b112-00215aee3ebe;Червона Зірка;Червоная Зирка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820881204;3;1
871625ed-749b-11df-b112-00215aee3ebe;Червона Знам'янка;Червоная Знаменка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823686501;3;1
87162619-749b-11df-b112-00215aee3ebe;Червона Зоря;Червоная Зоря;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921588606;3;1
8716261a-749b-11df-b112-00215aee3ebe;Червона Зоря;Червоная Зоря;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322686004;3;1
8716261b-749b-11df-b112-00215aee3ebe;Червона Зоря;Червоная Зоря;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320481512;3;1
8716261e-749b-11df-b112-00215aee3ebe;Червона Кам'янка;Червоная Каменка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520386901;3;1
8716261f-749b-11df-b112-00215aee3ebe;Червона Колона;Червоная Колона;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220310117;3;1
87162620-749b-11df-b112-00215aee3ebe;Червона Криниця;Червоная Криница;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323987803;3;1
87162621-749b-11df-b112-00215aee3ebe;Червона Людмилівка;Червоная Людмиловка;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520983304;3;1
87162623-749b-11df-b112-00215aee3ebe;Червона Нива;Червоная Нива;село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223584506;3;1
87162626-749b-11df-b112-00215aee3ebe;Червона Новоселівка;Червоная Новоселовка;село;903ccadc-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;4823655128;3;1
87162627-749b-11df-b112-00215aee3ebe;Червона Орілька;Червоная Орелька;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655121;3;1
87162629-749b-11df-b112-00215aee3ebe;Червона Поляна;Червоная Поляна;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224585909;3;1
8716262a-749b-11df-b112-00215aee3ebe;Червона Поляна;Червоная Поляна;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221882907;3;1
8716262b-749b-11df-b112-00215aee3ebe;Червона Поляна;Червоная Поляна;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324281003;3;1
8716262c-749b-11df-b112-00215aee3ebe;Червона Поляна;Червоная Поляна;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324281003;3;1
8716262d-749b-11df-b112-00215aee3ebe;Червона Поляна;Червоная Поляна;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320483506;3;1
87162631-749b-11df-b112-00215aee3ebe;Червона Поляна;Червоная Поляна;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422285506;3;1
87162632-749b-11df-b112-00215aee3ebe;Червона Поляна;Червоная Поляна;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523283006;3;1
87162633-749b-11df-b112-00215aee3ebe;Червона Поляна;Червоная Поляна;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523182002;3;1
87162634-749b-11df-b112-00215aee3ebe;Червона Поляна;Червоная Поляна;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521786801;3;1
87162635-749b-11df-b112-00215aee3ebe;Червона Семенівка;Червона Семеновка;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824282107;3;1
87162636-749b-11df-b112-00215aee3ebe;Червона Слобідка;Червоная Слободка;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322680406;3;1
87162637-749b-11df-b112-00215aee3ebe;Червона Слобода;Червоная Слобода;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923587201;3;1
87162638-749b-11df-b112-00215aee3ebe;Червона Слобода;Червоная Слобода;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222788201;3;1
87162639-749b-11df-b112-00215aee3ebe;Червона Слобода;Червоная Слобода;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124989001;2;0
8716263a-749b-11df-b112-00215aee3ebe;Червона Слобода;Червоная Слобода;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522885801;3;1
871625ee-749b-11df-b112-00215aee3ebe;Червона Слобода;Червоная Слобода;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920988401;3;1
8716263b-749b-11df-b112-00215aee3ebe;Червона Стінка;Червоная Стенка;село;78f45411-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125280505;3;1
87162642-749b-11df-b112-00215aee3ebe;Червона Чагарівка;Червоная Чагаровка;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822484204;3;1
87162643-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324888219;3;1
87162648-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920683904;3;1
87162649-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924188802;3;1
8716264a-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982413;3;1
8716266b-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421781605;3;1
8716266c-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122582002;3;1
8716266d-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124089203;3;1
8716266e-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123787003;3;1
8716266f-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521210119;3;1
87162670-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523085602;3;1
87162678-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323884906;3;1
87162645-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321887508;3;1
87162646-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924482806;3;1
87162672-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521188301;3;1
87162673-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521987503;3;1
87162674-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820387005;3;1
87162675-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823686503;3;1
87162676-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824785503;3;1
8716264c-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120483904;3;1
8716264d-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222085509;3;1
8716264e-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;;3;1
8716264f-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221887001;3;1
87162650-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225884817;3;1
87162651-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823380704;3;1
87162652-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323381004;3;1
87162653-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320483507;3;1
87162654-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320680507;3;1
87162655-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;c7bb5b2d-671b-11ea-80c6-000c29800ae7;5121285113;3;1
87162656-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124781405;3;1
87162659-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;78f4540d-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6524185003;3;1
8716265a-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621888001;3;1
8716265e-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124887801;3;1
87162662-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221887505;3;1
87162663-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225588801;3;1
87162664-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224987101;3;1
87162665-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221488602;3;1
87162666-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224287005;3;1
87162667-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755163;3;1
87162668-749b-11df-b112-00215aee3ebe;Червоне;Червоное;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820355600;3;1
357f3f96-c055-11ee-80c4-000c2961d091;Червоне;Червоне;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221482801;3;1
8716267a-749b-11df-b112-00215aee3ebe;Червоне Заріччя;Червоное Заречье;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225510101;3;1
8716267b-749b-11df-b112-00215aee3ebe;Червоне Знамено;Червоное Знамено;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324584705;3;1
8716267d-749b-11df-b112-00215aee3ebe;Червоне Озеро;Червоное Озеро;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420882803;3;1
8716267e-749b-11df-b112-00215aee3ebe;Червоне Озеро;Червоное Озеро;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521984908;3;1
87162679-749b-11df-b112-00215aee3ebe;Червоне Озеро;Червоное Озеро;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923888001;3;1
8716267f-749b-11df-b112-00215aee3ebe;Червоне Парутине;Червоное Парутино;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825182211;3;1
87162681-749b-11df-b112-00215aee3ebe;Червоне Поле;Червоное Поле;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282522;3;1
87162682-749b-11df-b112-00215aee3ebe;Червоне Поле;Червоное Поле;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824284602;3;1
87162684-749b-11df-b112-00215aee3ebe;Червоне Поле;Червоное Поле;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221488603;3;1
87162686-749b-11df-b112-00215aee3ebe;Червоний Бір;Червоный Бор;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821786603;3;1
87162687-749b-11df-b112-00215aee3ebe;Червоний Брід;Червоный Брод;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122282002;3;1
87162688-749b-11df-b112-00215aee3ebe;Червоний Відділок;Красный Участок;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;;3;1
87162689-749b-11df-b112-00215aee3ebe;Червоний Гай;Червоный Гай;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822585603;3;1
8716268b-749b-11df-b112-00215aee3ebe;Червоний Довжик;Червоный Довжик;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422488303;3;1
8716268f-749b-11df-b112-00215aee3ebe;Червоний Жовтень (Жовтеньке);Червоный Жовтень (Жовтеньке);село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323981305;3;1
87162693-749b-11df-b112-00215aee3ebe;Червоний Запорожець;Червоный Запорожец;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220384406;3;1
87162694-749b-11df-b112-00215aee3ebe;Червоний Київ;Червоный Киев;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822780907;3;1
87162695-749b-11df-b112-00215aee3ebe;Червоний Колодязь;Червоный Колодязь;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423387404;3;1
87162696-749b-11df-b112-00215aee3ebe;Червоний Кут;Червоный Кут;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786309;3;1
87162697-749b-11df-b112-00215aee3ebe;Червоний Кут;Червоный Кут;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323985005;3;1
87162698-749b-11df-b112-00215aee3ebe;Червоний Кут;Червоный Кут;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125486001;3;1
87162699-749b-11df-b112-00215aee3ebe;Червоний Кут;Червоный Кут;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120989401;3;1
8716269a-749b-11df-b112-00215aee3ebe;Червоний Кут;Червоный Кут;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523787603;3;1
8716269b-749b-11df-b112-00215aee3ebe;Червоний Кут;Червоный Кут;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522582105;3;1
8716269c-749b-11df-b112-00215aee3ebe;Червоний Кут;Червоный Кут;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820986204;3;1
8716269d-749b-11df-b112-00215aee3ebe;Червоний Кут;Червоный Кут;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320486906;3;1
8716269e-749b-11df-b112-00215aee3ebe;Червоний Лан;Червоный Лан;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823482807;3;1
8716269f-749b-11df-b112-00215aee3ebe;Червоний Лан;Червоный Лан;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422284303;3;1
871626a0-749b-11df-b112-00215aee3ebe;Червоний Лиман;Червоный Лиман;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324555116;3;1
871626a2-749b-11df-b112-00215aee3ebe;Червоний Лиман;Червоный Лиман;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320485003;3;1
871626a1-749b-11df-b112-00215aee3ebe;Червоний Лиман (Добропасове);Червоный Лиман (Добропасово);село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224280521;3;1
871626a3-749b-11df-b112-00215aee3ebe;Червоний Маяк;Червоный Маяк;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;*122508600;3;1
871626a4-749b-11df-b112-00215aee3ebe;Червоний Маяк;Червоный Маяк;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520688201;3;1
871626a5-749b-11df-b112-00215aee3ebe;Червоний Маяк;Червоный Маяк;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423085004;3;1
871626a8-749b-11df-b112-00215aee3ebe;Червоний Орлик;Червоный Орлик;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055720;3;1
871626ab-749b-11df-b112-00215aee3ebe;Червоний Острів;Червоный Остров;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820980607;3;1
871626ac-749b-11df-b112-00215aee3ebe;Червоний Пахар;Червоный Пахарь;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921587904;3;1
871626ad-749b-11df-b112-00215aee3ebe;Червоний Пахар;Червоный Пахарь;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424786507;3;1
871626ae-749b-11df-b112-00215aee3ebe;Червоний Пахар;Червоный Пахарь;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423382403;3;1
871626b1-749b-11df-b112-00215aee3ebe;Червоний Плугатар;Червоный Плугатарь;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823482604;3;1
871626b2-749b-11df-b112-00215aee3ebe;Червоний Плугатар;Червоный Плугатарь;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425383005;3;1
871626b3-749b-11df-b112-00215aee3ebe;Червоний Поділ;Червоный Подол;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820981208;3;1
871626b4-749b-11df-b112-00215aee3ebe;Червоний Поділ;Червоный Подол;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520385806;3;1
325c57fe-ddd2-11df-9197-00215aee3ebe;Червоний Поділ;Червоный Подол;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520386809;3;1
871626b9-749b-11df-b112-00215aee3ebe;Червоний Промінь;Червоный Проминь;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;*122208860;3;1
871626bc-749b-11df-b112-00215aee3ebe;Червоний Ранок;Червоный Ранок;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885510;3;1
871626bd-749b-11df-b112-00215aee3ebe;Червоний Ранок;Червоный Ранок;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422283502;3;1
871626be-749b-11df-b112-00215aee3ebe;Червоний Ровець;Червоный Ровец;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822581803;3;1
871626bf-749b-11df-b112-00215aee3ebe;Червоний Розділ;Червоный Раздел;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524084803;3;1
871626c1-749b-11df-b112-00215aee3ebe;Червоний Садок;Червоный Садок;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221486212;3;1
871626c2-749b-11df-b112-00215aee3ebe;Червоний Случ;Червоный Случ;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824782004;3;1
871626c3-749b-11df-b112-00215aee3ebe;Червоний Став;Червоный Став;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055119;3;1
871626c4-749b-11df-b112-00215aee3ebe;Червоний Став;Червоный Став;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610123;3;1
871626c5-749b-11df-b112-00215aee3ebe;Червоний Став;Червоный Став;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821182604;3;1
871626c6-749b-11df-b112-00215aee3ebe;Червоний Степ;Червоная Степь;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324884005;3;1
871626c7-749b-11df-b112-00215aee3ebe;Червоний Степ;Червоная Степь;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524881203;3;1
871626c8-749b-11df-b112-00215aee3ebe;Червоний Степ;Червоная Степь;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521680912;3;1
871626c9-749b-11df-b112-00215aee3ebe;Червоний Степок;Червоный Степок;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822085004;3;1
871626ca-749b-11df-b112-00215aee3ebe;Червоний Ташлик;Червоный Ташлык;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524085606;3;1
871626cb-749b-11df-b112-00215aee3ebe;Червоний Тік;Червоный Ток;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220384407;3;1
871626cc-749b-11df-b112-00215aee3ebe;Червоний Цвіт;Червоный Цвет;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825582605;3;1
871626d0-749b-11df-b112-00215aee3ebe;Червоний Шлях;Червоный Шлях;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320284004;3;1
871626d6-749b-11df-b112-00215aee3ebe;Червоний Яр;Червоный Яр;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282523;3;1
871626d7-749b-11df-b112-00215aee3ebe;Червоний Яр;Червоный Яр;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455128;3;1
871626d8-749b-11df-b112-00215aee3ebe;Червоний Яр;Червоный Яр;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821181713;3;1
871626d9-749b-11df-b112-00215aee3ebe;Червоний Яр;Червоный Яр;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320288005;3;1
871626da-749b-11df-b112-00215aee3ebe;Червоний Яр;Червоный Яр;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123383206;3;1
871626db-749b-11df-b112-00215aee3ebe;Червоний Яр;Червоный Яр;село;903ccab3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122385601;3;1
871626dc-749b-11df-b112-00215aee3ebe;Червоний Яр;Червоный Яр;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322181308;3;1
871626dd-749b-11df-b112-00215aee3ebe;Червоний Яр;Червоный Яр;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323987814;3;1
871626de-749b-11df-b112-00215aee3ebe;Червоний Яр;Червоный Яр;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520684203;3;1
871626e0-749b-11df-b112-00215aee3ebe;Червоний Яр;Червоный Яр;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522587901;3;1
871626d2-749b-11df-b112-00215aee3ebe;Червоний Яр;Червоный Яр;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922081504;3;1
871626d3-749b-11df-b112-00215aee3ebe;Червоний Яр;Червоный Яр;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224555308;3;1
871626d4-749b-11df-b112-00215aee3ebe;Червоний Яр;Червоный Яр;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055106;3;1
871626e1-749b-11df-b112-00215aee3ebe;Червоні Гори;Червоные Горы;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423085505;3;1
871626e2-749b-11df-b112-00215aee3ebe;Червоні Квіти;Червоные Квиты;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321886601;3;1
871626e3-749b-11df-b112-00215aee3ebe;Червоні Кошари;Червоные Кошары;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083811;3;1
871626e4-749b-11df-b112-00215aee3ebe;Червоні Луки;Червоные Луки;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423085905;3;1
871626e5-749b-11df-b112-00215aee3ebe;Червоні Луки;Червоные Луки;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322681108;3;1
871626e6-749b-11df-b112-00215aee3ebe;Червоні Маяки;Червоные Маяки;село;6b07f89d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521186003;3;1
871626e7-749b-11df-b112-00215aee3ebe;Червоні Партизани;Червоные Партизаны;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423010100;3;1
871626e8-749b-11df-b112-00215aee3ebe;Червоні Партизани (Володькова ;Червоные Партизаны;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423887001;3;1
871626e9-749b-11df-b112-00215aee3ebe;Червоні Поди;Червоные Поды;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221884717;3;1
871626ea-749b-11df-b112-00215aee3ebe;Червоні Пологи;Червоные Пологи;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322883903;3;1
871626eb-749b-11df-b112-00215aee3ebe;Червоні Хатки;Червоные Хатки;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821485601;3;1
871626ec-749b-11df-b112-00215aee3ebe;Червоні Яри;Червоные Яры;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224481103;3;1
871626ed-749b-11df-b112-00215aee3ebe;Червонка;Червонка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287709;3;1
871626f7-749b-11df-b112-00215aee3ebe;Червоноармійське;Червоноармейское;село;64024b41-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6521883003;3;1
871626fa-749b-11df-b112-00215aee3ebe;Червоноармійське;Червоноармейское;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220285703;3;1
871626f0-749b-11df-b112-00215aee3ebe;Червоноармійське;Червоноармейское;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824282707;3;1
871626ff-749b-11df-b112-00215aee3ebe;Червоновершка;Червоновершка;село;a1e9f9a9-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522885901;3;1
87162700-749b-11df-b112-00215aee3ebe;Червоноволодимирівка;Червоновладимировка;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824881604;3;1
87162702-749b-11df-b112-00215aee3ebe;Червоногвардійське;Червоногвардейское;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924185004;3;1
87162704-749b-11df-b112-00215aee3ebe;Червоногірка;Червоногорка;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923255107;3;1
87162706-749b-11df-b112-00215aee3ebe;Червоногірка;Червоногорка;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125182804;3;1
87162707-749b-11df-b112-00215aee3ebe;Червоноград;Червоноград;місто;bad3ac26-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4611800000;1;0
87162708-749b-11df-b112-00215aee3ebe;Червоногранітне;Червоногранитное;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821185409;3;1
87162709-749b-11df-b112-00215aee3ebe;Червоногригорівка;Червоногригоровка;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222955700;3;1
8716270a-749b-11df-b112-00215aee3ebe;Червонодвірка;Червонодворка;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820655704;3;1
8716270b-749b-11df-b112-00215aee3ebe;Червонодніпровка;Червоноднепровка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322180803;3;1
8716270e-749b-11df-b112-00215aee3ebe;Червонозорівка;Червонозоревка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520888001;3;1
87162710-749b-11df-b112-00215aee3ebe;Червоноіванівка;Червоноивановка;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222086501;3;1
87162711-749b-11df-b112-00215aee3ebe;Червонокам'яне;Червонокаменное;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055115;3;1
87162713-749b-11df-b112-00215aee3ebe;Червонокостянтинівка;Червоноконстантиновка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524985901;3;1
87162716-749b-11df-b112-00215aee3ebe;Червонопілля;Червонополье;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821155104;3;1
87162717-749b-11df-b112-00215aee3ebe;Червонопілля;Червонополье;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520887207;3;1
8716271d-749b-11df-b112-00215aee3ebe;Червоносілка;Червоноселка;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824284703;3;1
8716271e-749b-11df-b112-00215aee3ebe;Червоносілка;Червоноселка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825480803;3;1
8716271f-749b-11df-b112-00215aee3ebe;Червоносілля;Червоноселье;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524983709;3;1
87162722-749b-11df-b112-00215aee3ebe;Червонохижинці;Червонохижинцы;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125186403;3;1
87162723-749b-11df-b112-00215aee3ebe;Черганівка;Чергановка;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623688301;3;1
87162724-749b-11df-b112-00215aee3ebe;Черевані;Черевани;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610107;3;1
87162725-749b-11df-b112-00215aee3ebe;Череватівка;Череватовка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920689504;3;1
87162726-749b-11df-b112-00215aee3ebe;Череваха;Череваха;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723687901;3;1
87162727-749b-11df-b112-00215aee3ebe;Черевичне;Черевичное;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121080305;3;1
87162728-749b-11df-b112-00215aee3ebe;Черевки;Черевки;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221987501;3;1
87162729-749b-11df-b112-00215aee3ebe;Черевки;Черевки;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824288201;3;1
8716272a-749b-11df-b112-00215aee3ebe;Черевки;Черевки;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323288601;3;1
8716272b-749b-11df-b112-00215aee3ebe;Черевки;Черевки;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323687401;3;1
c8674aa1-dbb0-11df-9197-00215aee3ebe;Чередники;Чередники;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324283805;3;1
8716272f-749b-11df-b112-00215aee3ebe;Черемошна;Черемошна;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620888003;3;1
87162730-749b-11df-b112-00215aee3ebe;Черемошна;Черемошна;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223587407;3;1
87162731-749b-11df-b112-00215aee3ebe;Черемошна Воля;Черемошная Воля;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723355309;3;1
87162732-749b-11df-b112-00215aee3ebe;Черемошне;Черемошное;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722183609;3;1
87162733-749b-11df-b112-00215aee3ebe;Черемошне;Черемошное;село;6b07f8c7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822080306;3;1
87162734-749b-11df-b112-00215aee3ebe;Черемошне;Черемошное;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523487801;3;1
87162735-749b-11df-b112-00215aee3ebe;Черемошне;Черемошное;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524587001;3;1
87162736-749b-11df-b112-00215aee3ebe;Черемошня;Черемошня;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4621880406;3;1
87162737-749b-11df-b112-00215aee3ebe;Черемуха;Черемуха;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825282602;3;1
87162738-749b-11df-b112-00215aee3ebe;Черемухівка;Черемуховка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5922983711;3;1
87162739-749b-11df-b112-00215aee3ebe;Черемушки;Черемушки;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;;3;1
8716273a-749b-11df-b112-00215aee3ebe;Черемушки;Черемушки;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420384503;3;1
c8674a94-dbb0-11df-9197-00215aee3ebe;Черемушки;Черемушки;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5321883808;3;1
8716273b-749b-11df-b112-00215aee3ebe;Черемушна;Черемушная;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321288001;3;1
8716273c-749b-11df-b112-00215aee3ebe;Черемушне;Черемушное;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321755305;3;1
8716273d-749b-11df-b112-00215aee3ebe;Черемхів;Черемхов;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623288601;3;1
8716273e-749b-11df-b112-00215aee3ebe;Черемхів;Черемхов;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621585004;3;1
8716273f-749b-11df-b112-00215aee3ebe;Черемшанка;Черемшанка;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725084604;3;1
87162741-749b-11df-b112-00215aee3ebe;Черепашинці;Черепашинцы;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521688803;3;1
87162742-749b-11df-b112-00215aee3ebe;Черепин;Черепин;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623682402;3;1
87162743-749b-11df-b112-00215aee3ebe;Черепин;Черепин;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224610118;3;1
87162744-749b-11df-b112-00215aee3ebe;Черепин;Черепин;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824288401;3;1
87162745-749b-11df-b112-00215aee3ebe;Черепин;Черепин;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122589401;3;1
87162746-749b-11df-b112-00215aee3ebe;Черепина;Черепина;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624886705;3;1
87162747-749b-11df-b112-00215aee3ebe;Черепинка;Черепинка;село;903cca7a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224610119;2;1
87162748-749b-11df-b112-00215aee3ebe;Черепинки;Черепинки;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824288405;3;1
87162749-749b-11df-b112-00215aee3ebe;Черепівка;Череповка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920988801;3;1
8716274b-749b-11df-b112-00215aee3ebe;Черепівка;Череповка;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825089301;3;1
8716274c-749b-11df-b112-00215aee3ebe;Черепівське;Череповское;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322186805;3;1
8716274d-749b-11df-b112-00215aee3ebe;Черепківці;Черепковцы;село;6b07f810-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321088201;3;1
8716274e-749b-11df-b112-00215aee3ebe;Черепова;Череповая;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825085105;3;1
8716274f-749b-11df-b112-00215aee3ebe;Черетів;Черетов;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620881307;3;1
87162750-749b-11df-b112-00215aee3ebe;Черецьке;Черецкое;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421486410;3;1
87162751-749b-11df-b112-00215aee3ebe;Череш;Череш;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324589801;3;1
87162752-749b-11df-b112-00215aee3ebe;Черешенька;Черешенка;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320585501;3;1
87162753-749b-11df-b112-00215aee3ebe;Черешенька;Черешенка;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821587405;3;1
87162754-749b-11df-b112-00215aee3ebe;Черешеньки;Черешеньки;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520355101;3;1
87162755-749b-11df-b112-00215aee3ebe;Черешеньки;Черешеньки;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422281503;3;1
87162757-749b-11df-b112-00215aee3ebe;Черешневе;Черешневое;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520285318;3;1
87162758-749b-11df-b112-00215aee3ebe;Черешнівка;Черешневка;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621685607;3;1
8716272d-749b-11df-b112-00215aee3ebe;Черіївці;Чериевцы;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122785205;2;1
87162759-749b-11df-b112-00215aee3ebe;Черкавщина;Черкавщина;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125587602;3;1
8716275a-749b-11df-b112-00215aee3ebe;Черкас;Черкас;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220485303;2;1
8716275b-749b-11df-b112-00215aee3ebe;Черкаси;Черкассы;місто;5394b345-41be-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7110100000;1;0
8716275c-749b-11df-b112-00215aee3ebe;Черкаси;Черкассы;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722155708;3;1
8716275d-749b-11df-b112-00215aee3ebe;Черкаси;Черкассы;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623682604;3;1
8716275e-749b-11df-b112-00215aee3ebe;Черкасівка;Черкасовка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086501;3;1
8716275f-749b-11df-b112-00215aee3ebe;Черкасівка;Черкасовка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225587404;3;1
87162760-749b-11df-b112-00215aee3ebe;Черкасівка;Черкасовка;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820687003;3;1
87162761-749b-11df-b112-00215aee3ebe;Черкаська Лозова;Черкасская Лозовая;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322083001;3;1
87162762-749b-11df-b112-00215aee3ebe;Черкаське;Черкасское;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223256200;3;1
5cb616f5-749b-11df-b112-00215aee3ebe;Черкаське (Кімовка);Черкаське (Кимовка);село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222085503;3;1
87162764-749b-11df-b112-00215aee3ebe;Черкаський Бишкин;Черкасский Бышкин;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321783004;3;1
87162765-749b-11df-b112-00215aee3ebe;Черкаські Тишки;Черкасские Тишки;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325183504;3;1
87162766-749b-11df-b112-00215aee3ebe;Черкащани;Черкащаны;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323288901;3;1
87162767-749b-11df-b112-00215aee3ebe;Черкеси;Черкесы;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120887711;3;1
87162768-749b-11df-b112-00215aee3ebe;Черленівка;Черленовка;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323089301;3;1
87162769-749b-11df-b112-00215aee3ebe;Черляни;Черляны;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620988003;3;1
8716276a-749b-11df-b112-00215aee3ebe;Черлянське;Черлянское;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620988004;3;1
e0517ec5-db89-11df-9197-00215aee3ebe;Черлянське Передмістя;Черлянское Предместье;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620988004;3;1
8716276c-749b-11df-b112-00215aee3ebe;Черна;Черна;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121286501;3;1
8716276d-749b-11df-b112-00215aee3ebe;Чернацьке;Чернацкое;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924487601;3;1
8716276e-749b-11df-b112-00215aee3ebe;Чернацьке;Чернацкое;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423685004;3;1
8716276f-749b-11df-b112-00215aee3ebe;Черневе;Червневое;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921589501;3;1
87162770-749b-11df-b112-00215aee3ebe;Черневе;Червневое;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622487601;3;1
87162771-749b-11df-b112-00215aee3ebe;Чернелиця;Чернелица;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621655700;3;1
87162772-749b-11df-b112-00215aee3ebe;Чернелівка;Чернелевка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822789201;3;1
87162773-749b-11df-b112-00215aee3ebe;Чернелів-Руський;Чернелев-Русский;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125289201;2;1
87162774-749b-11df-b112-00215aee3ebe;Чернелівці;Чернелевцы;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821584403;3;1
87162776-749b-11df-b112-00215aee3ebe;Чернета;Чернета;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;;3;1
87162777-749b-11df-b112-00215aee3ebe;Чернецьке;Чернецкое;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922981209;3;1
87162778-749b-11df-b112-00215aee3ebe;Чернецьке;Чернецкое;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924783807;3;1
87162779-749b-11df-b112-00215aee3ebe;Чернецьке;Чернецкое;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425385001;3;1
8716277a-749b-11df-b112-00215aee3ebe;Чернеча Слобода;Чернеча Слобода;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920989101;3;1
8716277b-749b-11df-b112-00215aee3ebe;Чернече;Чернече;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055107;3;1
8716277c-749b-11df-b112-00215aee3ebe;Чернече;Чернече;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120689101;3;1
8716277d-749b-11df-b112-00215aee3ebe;Чернече;Чернече;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320483405;3;1
8716277e-749b-11df-b112-00215aee3ebe;Чернече;Чернече;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322887909;3;1
f9333453-db4e-11df-9197-00215aee3ebe;Чернече;Чернече;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125410101;3;1
8716277f-749b-11df-b112-00215aee3ebe;Чернечий Яр;Чернечий Яр;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321081705;3;1
87162780-749b-11df-b112-00215aee3ebe;Чернеччина;Чернетчина;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922387601;3;1
87162781-749b-11df-b112-00215aee3ebe;Чернеччина;Чернетчина;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920389201;3;1
87162782-749b-11df-b112-00215aee3ebe;Чернеччина;Чернетчина;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222387201;3;1
87162783-749b-11df-b112-00215aee3ebe;Чернещина;Чернещина;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321084208;3;1
87162784-749b-11df-b112-00215aee3ebe;Чернещина;Чернещина;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322255105;3;1
87162785-749b-11df-b112-00215aee3ebe;Чернещина;Чернещина;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323555104;3;1
87162786-749b-11df-b112-00215aee3ebe;Чернещина;Чернещина;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321084501;3;1
87162787-749b-11df-b112-00215aee3ebe;Чернещина;Чернещина;село;4ffc4b24-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322284501;3;1
87162788-749b-11df-b112-00215aee3ebe;Чернещина;Чернещина;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121584702;3;1
87162789-749b-11df-b112-00215aee3ebe;Черник;Черник;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624082103;3;1
8716278a-749b-11df-b112-00215aee3ebe;Черник;Черник;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124085802;3;1
8716278b-749b-11df-b112-00215aee3ebe;Чернилівка;Черниловка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124689501;3;1
8716278c-749b-11df-b112-00215aee3ebe;Чернилява;Чернилява;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625888801;3;1
8716278d-749b-11df-b112-00215aee3ebe;Чернин;Чернин;село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224488201;3;1
8716278e-749b-11df-b112-00215aee3ebe;Чернин;Чернин;село;8067ad6b-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5622255103;3;1
8716278f-749b-11df-b112-00215aee3ebe;Чернихів;Чернихов;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122689501;3;1
87162790-749b-11df-b112-00215aee3ebe;Чернихів;Чернихов;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624284307;3;1
87162791-749b-11df-b112-00215aee3ebe;Чернихівці;Черниховцы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122488801;3;1
87162792-749b-11df-b112-00215aee3ebe;Черниця;Черница;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620388001;3;1
87162793-749b-11df-b112-00215aee3ebe;Черниця;Черница;село;f9333446-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4623086407;3;1
87162794-749b-11df-b112-00215aee3ebe;Черниця;Черница;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623080403;3;1
87162795-749b-11df-b112-00215aee3ebe;Чернички;Чернички;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521480215;3;1
87162796-749b-11df-b112-00215aee3ebe;Черниш;Черныш;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425589501;3;1
87162797-749b-11df-b112-00215aee3ebe;Черниші;Черныши;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122089201;3;1
87162798-749b-11df-b112-00215aee3ebe;Чернишівка;Чернышовка;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755108;3;1
87162799-749b-11df-b112-00215aee3ebe;Чернишки;Чернышки;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922984205;3;1
8716279b-749b-11df-b112-00215aee3ebe;Чернів;Чернев;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624455308;3;1
8716279d-749b-11df-b112-00215aee3ebe;Чернівці;Черновцы;місто;fbfb8c29-41be-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7310100000;1;0
8716279e-749b-11df-b112-00215aee3ebe;Чернівці;Черновцы;місто;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524955100;3;0
8716279f-749b-11df-b112-00215aee3ebe;Чернігів;Чернигов;місто;5394b31b-41be-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7410100000;1;0
871627a0-749b-11df-b112-00215aee3ebe;Чернігівка;Черниговка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225083518;3;1
871627a1-749b-11df-b112-00215aee3ebe;Чернігівка;Черниговка;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824583004;3;1
e778c21c-7642-11e9-80e0-1c98ec135261;Чернігівка;Черниговка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822783507;3;1
871627a4-749b-11df-b112-00215aee3ebe;Чернігівське;Черниговское;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323987501;3;1
871627a6-749b-11df-b112-00215aee3ebe;Черніїв;Черниев;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625887801;2;0
871627a7-749b-11df-b112-00215aee3ebe;Черніївка;Черниевка;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823683003;3;1
871627aa-749b-11df-b112-00215aee3ebe;Чернява;Чернява;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820988601;3;1
871627ab-749b-11df-b112-00215aee3ebe;Чернявка;Чернявка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825485702;3;1
871627ac-749b-11df-b112-00215aee3ebe;Чернявка;Чернявка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523186901;3;1
871627ad-749b-11df-b112-00215aee3ebe;Чернявщина;Чернявщина;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955124;3;1
871627ae-749b-11df-b112-00215aee3ebe;Черняки;Черняки;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325185007;3;1
871627b0-749b-11df-b112-00215aee3ebe;Черняківка;Черняковка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325484201;3;1
871627b1-749b-11df-b112-00215aee3ebe;Чернятин;Чернятин;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621687001;3;1
871627b2-749b-11df-b112-00215aee3ebe;Чернятин;Чернятин;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521685601;3;1
871627b3-749b-11df-b112-00215aee3ebe;Чернятин;Чернятин;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521087503;3;1
871627b4-749b-11df-b112-00215aee3ebe;Чернятинці;Чернятинцы;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524885803;3;1
871627b5-749b-11df-b112-00215aee3ebe;Чернятка;Чернятка;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520481602;3;1
871627b6-749b-11df-b112-00215aee3ebe;Черняхів;Черняхов;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222288801;3;1
871627b7-749b-11df-b112-00215aee3ebe;Черняхів;Черняхов;місто;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825655100;2;0
871627b8-749b-11df-b112-00215aee3ebe;Черняхів;Черняхов;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624285102;3;1
871627b9-749b-11df-b112-00215aee3ebe;Черняхівка;Черняховка;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225589001;3;1
871627ba-749b-11df-b112-00215aee3ebe;Черняхівка;Черняховка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423389501;3;1
871627bb-749b-11df-b112-00215aee3ebe;Черняхівка;Черняховка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522587205;3;1
871627bc-749b-11df-b112-00215aee3ebe;Черняхівське;Черняховское;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121880705;3;1
871627bd-749b-11df-b112-00215aee3ebe;Черповоди;Черповоды;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124389201;3;1
871627be-749b-11df-b112-00215aee3ebe;Черськ;Черск;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723687304;3;1
871627c0-749b-11df-b112-00215aee3ebe;Чертеж;Чертеж;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124887605;3;1
871627c1-749b-11df-b112-00215aee3ebe;Чертіж;Чертиж;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2110890003;3;1
871627bf-749b-11df-b112-00215aee3ebe;Чертіж;Чертиж;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621589201;3;1
871627c2-749b-11df-b112-00215aee3ebe;Черхава;Черхава;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624286304;3;1
871627c3-749b-11df-b112-00215aee3ebe;Черці;Черцы;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923582907;3;1
871627c4-749b-11df-b112-00215aee3ebe;Черче;Черче;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721488601;3;1
871627c5-749b-11df-b112-00215aee3ebe;Черче;Черче;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624487201;3;1
871627c6-749b-11df-b112-00215aee3ebe;Черче;Черче;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825283402;3;1
871627c7-749b-11df-b112-00215aee3ebe;Черчик;Черчик;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625887004;3;1
871627c8-749b-11df-b112-00215aee3ebe;Черчичі;Черчичи;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720582023;3;1
871627c9-749b-11df-b112-00215aee3ebe;Чесники;Чесники;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624487301;3;1
871627ca-749b-11df-b112-00215aee3ebe;Чеснівка;Чесновка;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122887603;3;1
871627cb-749b-11df-b112-00215aee3ebe;Чеснівка;Чесновка;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524884003;3;1
871627cc-749b-11df-b112-00215aee3ebe;Чеснівський Раковець;Чесновский Раковец;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122481803;3;1
871627cd-749b-11df-b112-00215aee3ebe;Чеснопіль;Чеснополь;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124084804;3;1
871627ce-749b-11df-b112-00215aee3ebe;Честині;Честыни;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622181204;3;1
5cb616f2-749b-11df-b112-00215aee3ebe;Четвертакове (Кім);Четвертаково (Ким);село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924482803;3;1
871627d0-749b-11df-b112-00215aee3ebe;Четвертинівка;Четвертиновка;село;710c27cf-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0524187601;3;1
871627d1-749b-11df-b112-00215aee3ebe;Четвертня;Четвертня;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723655414;3;1
871627d2-749b-11df-b112-00215aee3ebe;Четфалва;Четфалва;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120489201;3;1
871627d3-749b-11df-b112-00215aee3ebe;Чехи;Чехи;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824482005;3;1
871627d4-749b-11df-b112-00215aee3ebe;Чехів;Чехов;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124289401;3;1
871627d5-749b-11df-b112-00215aee3ebe;Чехівка;Чеховка;село;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125184403;3;1
871627d6-749b-11df-b112-00215aee3ebe;Чехівці;Чеховцы;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825483605;3;1
871627d7-749b-11df-b112-00215aee3ebe;Чехова;Чехова;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623285305;3;1
871627d8-749b-11df-b112-00215aee3ebe;Чехова;Чехова;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825280804;3;1
871627db-749b-11df-b112-00215aee3ebe;Чечелеве;Чечелево;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322480407;3;1
871627dc-749b-11df-b112-00215aee3ebe;Чечелівка;Чечелевка;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520886503;3;1
871627dd-749b-11df-b112-00215aee3ebe;Чечеліївка;Чечелиевка;село;6b07f876-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;3524986601;3;1
871627de-749b-11df-b112-00215aee3ebe;Чечельник;Чечельник;місто;78f453a8-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0525055100;3;1
871627df-749b-11df-b112-00215aee3ebe;Чечельник;Чечельник;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821889701;3;1
871627e0-749b-11df-b112-00215aee3ebe;Чечужине;Чечужино;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322080410;3;1
871627e1-749b-11df-b112-00215aee3ebe;Чигин;Чигин;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924487202;3;1
871627e2-749b-11df-b112-00215aee3ebe;Чигирин;Чигирин;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121285805;3;1
871627e3-749b-11df-b112-00215aee3ebe;Чигирин;Чигирин;місто;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125410100;3;0
8cdd5cde-de27-11df-9197-00215aee3ebe;Чигиринське;Чигиринское;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520885805;3;1
871627e4-749b-11df-b112-00215aee3ebe;Чигирі;Чигири;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;;3;1
871627e5-749b-11df-b112-00215aee3ebe;Чигринівка;Чигриновка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224585910;3;1
871627e6-749b-11df-b112-00215aee3ebe;Чижевичі;Чижевичи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622485905;3;1
871627e7-749b-11df-b112-00215aee3ebe;Чижиків;Чижиков;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623687901;3;1
871627e8-749b-11df-b112-00215aee3ebe;Чижикове;Чижиково;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925680307;3;1
871627e9-749b-11df-b112-00215aee3ebe;Чижикове;Чижиково;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924184709;3;1
871627ea-749b-11df-b112-00215aee3ebe;Чижичі;Чижичи;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621581808;3;1
871627eb-749b-11df-b112-00215aee3ebe;Чижі;Чижи;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322682410;3;1
871627ec-749b-11df-b112-00215aee3ebe;Чижів;Чижов;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621885504;3;1
871627ed-749b-11df-b112-00215aee3ebe;Чижівка;Чижовка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824086801;3;1
871627ee-749b-11df-b112-00215aee3ebe;Чижівка;Чижовка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424482004;3;1
871627ef-749b-11df-b112-00215aee3ebe;Чижівка;Чижовка;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121288401;3;1
871627f0-749b-11df-b112-00215aee3ebe;Чижівка;Чижовка;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822187205;3;1
871627f1-749b-11df-b112-00215aee3ebe;Чижки;Чижки;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625182304;3;1
871627f2-749b-11df-b112-00215aee3ebe;Чижове;Чижево;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922989104;3;1
871627f3-749b-11df-b112-00215aee3ebe;Чижове;Чижево;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121282005;3;1
871627f4-749b-11df-b112-00215aee3ebe;Чикалівка;Чикаловка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322481704;3;1
871627f5-749b-11df-b112-00215aee3ebe;Чикош-Горонда;Чикош-Горонда;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120482803;3;1
871627f6-749b-11df-b112-00215aee3ebe;Чимбарівка;Чимбаровка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821880605;3;1
871627f7-749b-11df-b112-00215aee3ebe;Чинадійово;Чинадиево;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122755500;2;0
871627f8-749b-11df-b112-00215aee3ebe;Чирвине;Чирвино;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923281709;3;1
871627f9-749b-11df-b112-00215aee3ebe;Чирське;Чирское;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223380805;3;1
871627fa-749b-11df-b112-00215aee3ebe;Чисте;Чистое;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924182604;3;1
871627fc-749b-11df-b112-00215aee3ebe;Чистилів;Чистилов;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125289701;2;1
871627fd-749b-11df-b112-00215aee3ebe;Чисті Лужі;Чистые Лужи;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424480806;3;1
871627fe-749b-11df-b112-00215aee3ebe;Чистоводівка;Чистоводовка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322888501;3;1
871627ff-749b-11df-b112-00215aee3ebe;Чистоводне;Чистоводное;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120881803;3;1
87162800-749b-11df-b112-00215aee3ebe;Чистопади;Чистопады;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122689601;3;1
87162805-749b-11df-b112-00215aee3ebe;Чистопілля;Чистополье;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420685505;3;1
87162806-749b-11df-b112-00215aee3ebe;Чистопілля;Чистополье;село;8a199cee-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3524385501;3;1
871627cf-749b-11df-b112-00215aee3ebe;Чистопілля;Чистополье;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787210;3;1
87162807-749b-11df-b112-00215aee3ebe;Чистопіль;Чистополь;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224588507;3;1
441c6e42-8357-11e8-80d4-1c98ec135261;Чистопіль;Чистополь;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222982201;3;1
87162809-749b-11df-b112-00215aee3ebe;Чичиркозівка;Чичиркозовка;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121288801;3;1
8716280a-749b-11df-b112-00215aee3ebe;Чишки;Чишки;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623688201;3;1
8716280b-749b-11df-b112-00215aee3ebe;Чишки;Чишки;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622483906;3;1
8716280c-749b-11df-b112-00215aee3ebe;Чішки;Чишки;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620683204;3;1
8716280d-749b-11df-b112-00215aee3ebe;Чкалове;Чкалове;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222986701;3;1
8716280f-749b-11df-b112-00215aee3ebe;Чкалове;Чкалово;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321885806;3;1
87162812-749b-11df-b112-00215aee3ebe;Чкалове;Чкалово;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520985001;3;1
87162815-749b-11df-b112-00215aee3ebe;Чкаловка;Чкаловка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221055804;3;1
87162816-749b-11df-b112-00215aee3ebe;Чкаловка;Чкаловка;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221887701;3;1
87162817-749b-11df-b112-00215aee3ebe;Чкаловка;Чкаловка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222085602;3;1
87162818-749b-11df-b112-00215aee3ebe;Чкаловське;Чкаловское;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325456700;2;1
87162819-749b-11df-b112-00215aee3ebe;Чмикос;Чмикос;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723384703;3;1
8716281b-749b-11df-b112-00215aee3ebe;Чмирівка;Чмыровка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220488302;3;1
8716281c-749b-11df-b112-00215aee3ebe;Чмирівка;Чмыровка;село;8067ad70-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125484504;3;1
8716281d-749b-11df-b112-00215aee3ebe;Чмихалове;Чмыхалово;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323680803;3;1
8716281e-749b-11df-b112-00215aee3ebe;Чміль;Чмель;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821784403;3;1
8716281f-749b-11df-b112-00215aee3ebe;Чоботарі;Чеботари;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322283009;3;1
87162820-749b-11df-b112-00215aee3ebe;Чоботарівка;Чеботаревка;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121082811;3;1
87162822-749b-11df-b112-00215aee3ebe;Човгузів;Човгузов;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824786501;3;1
87162823-749b-11df-b112-00215aee3ebe;Човниця;Човница;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721883302;3;1
87162824-749b-11df-b112-00215aee3ebe;Човнова;Човнова;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821182006;3;1
87162825-749b-11df-b112-00215aee3ebe;Човновиця;Челновица;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523186601;3;1
87162826-749b-11df-b112-00215aee3ebe;Човно-Федорівка;Челно-Федоровка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321387201;3;1
87162827-749b-11df-b112-00215aee3ebe;Чогодарівка;Чегодаровка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125485901;3;1
87162828-749b-11df-b112-00215aee3ebe;Чолгині;Чолгини;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625888604;3;1
87162829-749b-11df-b112-00215aee3ebe;Чолівка;Чоловка;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;;3;1
8716282a-749b-11df-b112-00215aee3ebe;Чома;Чома;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120483401;3;1
8716282b-749b-11df-b112-00215aee3ebe;Чомонин;Чомонин;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122787801;2;1
8716282d-749b-11df-b112-00215aee3ebe;Чоп;Чоп;місто;fbfb8c2b-41be-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2111000000;2;0
8716282e-749b-11df-b112-00215aee3ebe;Чопилки;Чопилки;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223381402;3;1
73e94bf6-ddc1-11df-9197-00215aee3ebe;Чопівці;Чоповцы;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122786811;3;1
8716282f-749b-11df-b112-00215aee3ebe;Чоповичі;Чоповичи;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823455400;3;1
87162830-749b-11df-b112-00215aee3ebe;Чорбівка;Чорбовка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321887501;3;1
87162831-749b-11df-b112-00215aee3ebe;Чорна;Чорная;село;5d311274-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123185701;3;1
87162832-749b-11df-b112-00215aee3ebe;Чорна;Чорная;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824289401;3;1
87162833-749b-11df-b112-00215aee3ebe;Чорна;Чорная;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825289001;2;1
87162834-749b-11df-b112-00215aee3ebe;Чорна Гребля;Черная Гребля;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;;3;1
87162836-749b-11df-b112-00215aee3ebe;Чорна Кам'янка;Черная Каменка;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123188501;3;1
87162837-749b-11df-b112-00215aee3ebe;Чорна Річка;Черная Речка;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620882002;3;1
87162838-749b-11df-b112-00215aee3ebe;Чорна Тиса;Черная Тиса;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123687501;3;1
8716283a-749b-11df-b112-00215aee3ebe;Чорненкове;Черненково;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224255119;3;1
8716283b-749b-11df-b112-00215aee3ebe;Чорниводи;Чорныводы;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821289001;3;1
8716283c-749b-11df-b112-00215aee3ebe;Чорниж;Чорныж;село;710c27d5-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723688201;3;1
8716283d-749b-11df-b112-00215aee3ebe;Чорний Ліс;Черный Лес;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881505;3;1
8716283e-749b-11df-b112-00215aee3ebe;Чорний Ліс;Черный Лес;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122687602;3;1
8716283f-749b-11df-b112-00215aee3ebe;Чорний Ліс;Черный Лес;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122480404;3;1
87162840-749b-11df-b112-00215aee3ebe;Чорний Острів;Чорный Остров;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621589101;3;1
87162841-749b-11df-b112-00215aee3ebe;Чорний Острів;Чорный Остров;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825055500;3;1
87162842-749b-11df-b112-00215aee3ebe;Чорний Потік;Черный Поток;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321589101;3;1
87162843-749b-11df-b112-00215aee3ebe;Чорний Потік;Черный Поток;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624087401;3;1
87162844-749b-11df-b112-00215aee3ebe;Чорний Потік;Черный Поток;село;64024aed-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121987201;3;1
87162845-749b-11df-b112-00215aee3ebe;Чорний Потік;Черный Поток;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121281802;3;1
87162846-749b-11df-b112-00215aee3ebe;Чорний Ріг;Черный Рог;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424787501;3;1
87162847-749b-11df-b112-00215aee3ebe;Чорників;Чорников;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0720510425;3;1
87162848-749b-11df-b112-00215aee3ebe;Чорні Лози;Черные Лозы;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925383505;3;1
87162849-749b-11df-b112-00215aee3ebe;Чорні Ослави;Чорные Ославы;село;78f453dd-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2624087101;3;1
8716284a-749b-11df-b112-00215aee3ebe;Чорнівка;Черновка;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323089701;3;1
8716284b-749b-11df-b112-00215aee3ebe;Чорнії;Чорнии;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783023;3;1
8716284c-749b-11df-b112-00215aee3ebe;Чорніїв;Черниев;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725582204;3;1
8716284d-749b-11df-b112-00215aee3ebe;Чорнобаї;Чорнобаи;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755158;3;1
8716284e-749b-11df-b112-00215aee3ebe;Чорнобаївка;Чернобаевка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322882007;3;1
871627a8-749b-11df-b112-00215aee3ebe;Чорнобаївка;Чернобаевка;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520387501;2;1
8716284f-749b-11df-b112-00215aee3ebe;Чорнобай;Чернобай;місто;6b07f852-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7125155100;3;1
87162850-749b-11df-b112-00215aee3ebe;Чорнобиль;Чернобыль;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;a8bc35df-4aa1-11e3-9df6-003048d2b473;3222010500;3;1
87162851-749b-11df-b112-00215aee3ebe;Чорнобривкине;Чернобровкино;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923888101;3;1
87162852-749b-11df-b112-00215aee3ebe;Чорногірка;Черногорка;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121285115;3;1
87162853-749b-11df-b112-00215aee3ebe;Чорноглазівка;Черноглазовка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324086901;3;1
87162854-749b-11df-b112-00215aee3ebe;Чорноглазівка;Черноглазовка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084807;3;1
87162855-749b-11df-b112-00215aee3ebe;Чорноглазівка;Черноглазовка;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955125;3;1
87162856-749b-11df-b112-00215aee3ebe;Чорноглазівка;Черноглазовка;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322683004;3;1
87162857-749b-11df-b112-00215aee3ebe;Чорноголова;Черноголова;село;8a199cf5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2120887201;3;1
87162858-749b-11df-b112-00215aee3ebe;Чорногородка;Черногородка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222788301;3;1
87162859-749b-11df-b112-00215aee3ebe;Чорногорці;Черногорцы;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423088504;3;1
8716285a-749b-11df-b112-00215aee3ebe;Чорногузи;Черногузы;село;64024bce-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7320586001;3;1
8716285b-749b-11df-b112-00215aee3ebe;Чорнозем;Чернозем;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424786508;3;1
8716285f-749b-11df-b112-00215aee3ebe;Чорнокінецька Воля;Чорноконецкая Воля;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125588801;3;1
87162860-749b-11df-b112-00215aee3ebe;Чорнокозинці;Чорнокозинцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822489804;2;1
87162861-749b-11df-b112-00215aee3ebe;Чорнокунці;Чернокунцы;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625882204;3;1
87162862-749b-11df-b112-00215aee3ebe;Чорнолізці;Чернолозцы;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625888101;3;1
87162863-749b-11df-b112-00215aee3ebe;Чорноліска;Чернолеска;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;;3;1
87162864-749b-11df-b112-00215aee3ebe;Чорнолозка;Чернолозка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324855103;3;1
87162865-749b-11df-b112-00215aee3ebe;Чорномин;Черномин;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523283401;3;1
87162867-749b-11df-b112-00215aee3ebe;Чорноморка;Черноморка;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825184801;3;1
0f65b53b-16f1-11e3-9df6-003048d2b473;Чорноморка;Черноморка;;4ffc4b7f-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;;2;0
56bdd8f8-749b-11df-b112-00215aee3ebe;Чорноморськ;Черноморск;місто;903cca7c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5110800000;2;0
87162868-749b-11df-b112-00215aee3ebe;Чорноморське;Черноморское;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122755900;3;1
8716286c-749b-11df-b112-00215aee3ebe;Чорнопарівка;Чернопаровка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225083504;3;1
8716286e-749b-11df-b112-00215aee3ebe;Чорноплатове;Черноплатово;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922086907;3;1
8716286f-749b-11df-b112-00215aee3ebe;Чорноплеси;Черноплесы;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723310114;3;1
87162870-749b-11df-b112-00215aee3ebe;Чорнорудка;Чернорудка;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825286801;3;1
87162871-749b-11df-b112-00215aee3ebe;Чорнотисів;Чернотисов;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121286201;3;1
87162872-749b-11df-b112-00215aee3ebe;Чорнотичі;Чернотичи;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424988001;3;1
87162873-749b-11df-b112-00215aee3ebe;Чорнухи;Чернухи;місто;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325155100;3;1
87162875-749b-11df-b112-00215aee3ebe;Чорнушовичі;Чернушовичи;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623687601;3;1
87162876-749b-11df-b112-00215aee3ebe;Чорнявка;Чернявка;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422281504;3;1
87162877-749b-11df-b112-00215aee3ebe;Чорнявка;Чернявка;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124989201;3;1
87162878-749b-11df-b112-00215aee3ebe;Чорняки;Черняки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321381403;3;1
8716287a-749b-11df-b112-00215aee3ebe;Чорталка;Черталка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822755104;3;1
8716287b-749b-11df-b112-00215aee3ebe;Чортків;Чортков;місто;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6110300000;2;0
8716287c-749b-11df-b112-00215aee3ebe;Чортовець;Чортовец;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621682101;3;1
589f9612-ac60-11e8-80d4-1c98ec135261;Чортомлик;Чортомлик;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1212145700;3;1
8716287e-749b-11df-b112-00215aee3ebe;Чортория;Чертория;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322581507;3;1
8716287f-749b-11df-b112-00215aee3ebe;Чортория;Чертория;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521281609;3;1
87162880-749b-11df-b112-00215aee3ebe;Чотирбоки;Чотырбоки;село;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6825589501;3;1
87162882-749b-11df-b112-00215aee3ebe;Чубарівка;Чубаревка;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820382003;3;1
87162883-749b-11df-b112-00215aee3ebe;Чубачівка;Чубачивка;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;;3;1
87162884-749b-11df-b112-00215aee3ebe;Чубинське;Чубинское;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220880905;3;0
87162885-749b-11df-b112-00215aee3ebe;Чубинці;Чубинцы;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224087202;2;1
87162886-749b-11df-b112-00215aee3ebe;Чубівка;Чубовка;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122985609;3;1
87162887-749b-11df-b112-00215aee3ebe;Чубівка;Чубовка;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124983503;3;1
87162888-749b-11df-b112-00215aee3ebe;Чувилине;Чувилино;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221486208;3;1
8716288a-749b-11df-b112-00215aee3ebe;Чугалі;Чугали;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123489001;3;1
8716288c-749b-11df-b112-00215aee3ebe;Чугуєве;Чугуево;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222685504;3;1
8716288d-749b-11df-b112-00215aee3ebe;Чугуїв;Чугуев;місто;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6312000000;3;0
87162890-749b-11df-b112-00215aee3ebe;Чудви;Чудвы;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623482604;3;1
87162891-749b-11df-b112-00215aee3ebe;Чудей;Чудей;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324586001;3;1
87162892-749b-11df-b112-00215aee3ebe;Чудель;Чудель;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625488801;3;1
87162893-749b-11df-b112-00215aee3ebe;Чудин;Чудин;село;78f453e7-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825080903;3;1
87162894-749b-11df-b112-00215aee3ebe;Чудинівці;Чудиновцы;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;b7f0ddba-d97f-11eb-80d5-000c29800ae7;0524880304;3;1
87162895-749b-11df-b112-00215aee3ebe;Чудівка;Чудовка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424482403;3;1
87162896-749b-11df-b112-00215aee3ebe;Чудля;Чудля;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620886602;3;1
87162897-749b-11df-b112-00215aee3ebe;Чудниця;Чудница;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621283203;3;1
87162898-749b-11df-b112-00215aee3ebe;Чуднів;Чуднов;місто;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825810100;3;0
87162899-749b-11df-b112-00215aee3ebe;Чуднівці;Чудновцы;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322881405;3;1
5cb61bd7-749b-11df-b112-00215aee3ebe;Чудське (Котовське);Чудське (Котовське);село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121283650;3;1
8716289a-749b-11df-b112-00215aee3ebe;Чуйківка;Чуйковка;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925684901;3;1
8716289b-749b-11df-b112-00215aee3ebe;Чукалівка;Чукаловка;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625888301;3;0
8716289c-749b-11df-b112-00215aee3ebe;Чуква;Чуква;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624289201;3;1
8716289d-749b-11df-b112-00215aee3ebe;Чуків;Чуков;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523088801;3;1
8716289f-749b-11df-b112-00215aee3ebe;Чулатів;Чулатов;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423685005;3;1
871628a0-749b-11df-b112-00215aee3ebe;Чуловичі;Чуловичи;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620955305;3;1
871628a2-749b-11df-b112-00215aee3ebe;Чумак;Чумак;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424481203;3;1
871628a3-749b-11df-b112-00215aee3ebe;Чумаки;Чумаки;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321880711;3;1
871628a4-749b-11df-b112-00215aee3ebe;Чумаки;Чумаки;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1221488001;2;1
871628a5-749b-11df-b112-00215aee3ebe;Чумаки;Чумаки;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1224587002;3;1
871628a6-749b-11df-b112-00215aee3ebe;Чумаки;Чумаки;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223881506;3;1
871628a7-749b-11df-b112-00215aee3ebe;Чумаки;Чумаки;село;9b486f93-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1225455129;3;1
871628aa-749b-11df-b112-00215aee3ebe;Чумакове;Чумаково;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920988403;3;1
871628ac-749b-11df-b112-00215aee3ebe;Чумалі;Чумалы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122487005;3;1
871628ab-749b-11df-b112-00215aee3ebe;Чумальово;Чумалево;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124488001;3;1
871628ae-749b-11df-b112-00215aee3ebe;Чумгак;Чумгак;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;;3;1
871628b0-749b-11df-b112-00215aee3ebe;Чуньків;Чуньков;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321589201;3;1
871628b1-749b-11df-b112-00215aee3ebe;Чупахівка;Чупаховка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920355500;3;1
871628b2-749b-11df-b112-00215aee3ebe;Чуперносів;Чуперносов;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623388004;3;1
871628b3-749b-11df-b112-00215aee3ebe;Чупира;Чупира;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220489201;2;1
871628b4-749b-11df-b112-00215aee3ebe;Чупринівка;Чуприновка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522282803;3;1
871628b5-749b-11df-b112-00215aee3ebe;Чутівка;Чутовка;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;;3;1
871628b6-749b-11df-b112-00215aee3ebe;Чутівка;Чутовка;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323687701;3;1
871628b7-749b-11df-b112-00215aee3ebe;Чутове;Чутово;місто;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325455100;3;0
871628b8-749b-11df-b112-00215aee3ebe;Чухелі;Чухели;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820989001;3;1
871628b9-749b-11df-b112-00215aee3ebe;Чучеве;Чучево;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620881504;3;1
871628ba-749b-11df-b112-00215aee3ebe;Чучмани;Чучманы;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620681514;3;1
871628bb-749b-11df-b112-00215aee3ebe;Шабалинів;Шабалинов;село;64024b87-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7422289501;3;1
871628bc-749b-11df-b112-00215aee3ebe;Шабалтаєве;Шабалтаево;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920381604;3;1
871628bd-749b-11df-b112-00215aee3ebe;Шабастівка;Шабастовка;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123488501;3;1
871628c0-749b-11df-b112-00215aee3ebe;Шабельники;Шабельники;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321883206;3;1
871628c1-749b-11df-b112-00215aee3ebe;Шабельники;Шабельники;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123585501;3;1
871628c2-749b-11df-b112-00215aee3ebe;Шабельники;Шабельники;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121589701;3;1
871628c3-749b-11df-b112-00215aee3ebe;Шабельня;Шабельня;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622787903;3;1
871628c4-749b-11df-b112-00215aee3ebe;Шабельня;Шабельня;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521255410;3;1
871628c5-749b-11df-b112-00215aee3ebe;Шабо;Шабо;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120887701;3;1
871628c6-749b-11df-b112-00215aee3ebe;Шаболтасівка;Шаболтасовка;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424988501;3;1
871628c7-749b-11df-b112-00215aee3ebe;Шаварі;Шавары;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625855907;3;1
871628c9-749b-11df-b112-00215aee3ebe;Шагова;Шагова;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823986603;3;1
871628ca-749b-11df-b112-00215aee3ebe;Шадура;Шадура;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821182704;3;1
871628cb-749b-11df-b112-00215aee3ebe;Шадурка;Шадурка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320484706;3;1
871628cc-749b-11df-b112-00215aee3ebe;Шайбівка;Шайбовка;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120485103;3;1
871628cd-749b-11df-b112-00215aee3ebe;Шайноги;Шайноги;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623987205;3;1
871628ce-749b-11df-b112-00215aee3ebe;Шайтанка;Шайтанка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123383807;3;1
871628cf-749b-11df-b112-00215aee3ebe;Шакутівщина;Шакутовщина;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921583005;3;1
871628d0-749b-11df-b112-00215aee3ebe;Шаланки;Шаланки;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121286801;3;1
871628d1-749b-11df-b112-00215aee3ebe;Шалаське;Шаласское;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124089002;3;1
871628d3-749b-11df-b112-00215aee3ebe;Шалигине;Шалыгино;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921555800;3;1
871628d4-749b-11df-b112-00215aee3ebe;Шалимівка;Шалимовка;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924410108;3;1
871628d5-749b-11df-b112-00215aee3ebe;Шаліївка;Шалиевка;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224087801;2;1
871628d6-749b-11df-b112-00215aee3ebe;Шалойки;Шалойки;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422085005;3;1
871628d7-749b-11df-b112-00215aee3ebe;Шаманівка;Шамановка;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121883207;3;1
871628d8-749b-11df-b112-00215aee3ebe;Шами;Шамы;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422080606;3;1
b8e7938d-5654-11e2-b7ac-003048d2b473;Шампанія ;Шампания;;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121285602;3;1
871628d9-749b-11df-b112-00215aee3ebe;Шамраєве;Шамраево;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525589701;3;1
871628da-749b-11df-b112-00215aee3ebe;Шамраївка;Шамраевка;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324285608;3;1
871628db-749b-11df-b112-00215aee3ebe;Шамраївка;Шамраевка;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224088201;2;1
8cdd5cd8-de27-11df-9197-00215aee3ebe;Шамраївка Перша;Шамраевка Первая;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;;2;1
871628dc-749b-11df-b112-00215aee3ebe;Шандра;Шандра;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222988401;3;1
871628de-749b-11df-b112-00215aee3ebe;Шандрівка;Шандровка;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225988501;3;1
871628df-749b-11df-b112-00215aee3ebe;Шандровець;Шандровец;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625587301;3;1
871628e2-749b-11df-b112-00215aee3ebe;Шапіївка;Шапиевка;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224088601;3;1
871628e1-749b-11df-b112-00215aee3ebe;Шапіхи;Шапихи;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422085006;3;1
871628e3-749b-11df-b112-00215aee3ebe;Шапки;Шапки;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321880712;3;1
871628e4-749b-11df-b112-00215aee3ebe;Шаповалівка;Шаповаловка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922088701;3;1
871628e5-749b-11df-b112-00215aee3ebe;Шаповалівка;Шаповаловка;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920387509;3;1
871628e6-749b-11df-b112-00215aee3ebe;Шаповалівка;Шаповаловка;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322057002;3;1
871628e7-749b-11df-b112-00215aee3ebe;Шаповалівка;Шаповаловка;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420889201;3;1
871628e8-749b-11df-b112-00215aee3ebe;Шаповалове;Шаповалово;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923584409;3;1
871628e9-749b-11df-b112-00215aee3ebe;Шапошникове;Шапошниково;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786806;3;1
871628ed-749b-11df-b112-00215aee3ebe;Шарапанівка;Шарапановка;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521986601;3;1
871628ee-749b-11df-b112-00215aee3ebe;Шаргород;Шаргород;місто;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525310100;3;1
871628ef-749b-11df-b112-00215aee3ebe;Шарин;Шарин;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124389301;3;1
871628f0-749b-11df-b112-00215aee3ebe;Шарівка;Шаровка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225683010;3;1
871628f1-749b-11df-b112-00215aee3ebe;Шарівка;Шаровка;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320855700;3;1
871628f2-749b-11df-b112-00215aee3ebe;Шарівка;Шаровка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321288501;3;1
871628f4-749b-11df-b112-00215aee3ebe;Шарівка;Шаровка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520387301;3;1
871628f5-749b-11df-b112-00215aee3ebe;Шарівка;Шаровка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825889201;3;1
871628f6-749b-11df-b112-00215aee3ebe;Шарівка, санат.;Шаровка, санат.;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320855700;3;1
871628f7-749b-11df-b112-00215aee3ebe;Шарки;Шарки;село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3223787001;3;1
871628f8-749b-11df-b112-00215aee3ebe;Шарки;Шарки;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821589203;3;1
8cdd5cd7-de27-11df-9197-00215aee3ebe;Шарки Перші;Шарки Первые;село;78f453d5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;;3;1
871628f9-749b-11df-b112-00215aee3ebe;Шарківщина;Шарковщина;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323255703;3;1
871628fa-749b-11df-b112-00215aee3ebe;Шарківщина;Шарковщина;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324887721;3;1
871628fb-749b-11df-b112-00215aee3ebe;Шарлаї;Шарлаи;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324282807;3;1
871628fc-749b-11df-b112-00215aee3ebe;Шарлаївка;Шарлаевка;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755159;3;1
871628fd-749b-11df-b112-00215aee3ebe;Шарнопіль;Шарнополь;село;4ffc4b9c-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123489001;3;1
871628fe-749b-11df-b112-00215aee3ebe;Шаровечка;Шаровечка;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825089601;2;0
871628ff-749b-11df-b112-00215aee3ebe;Шарпанці;Шарпанцы;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624883203;3;1
87162900-749b-11df-b112-00215aee3ebe;Шатава;Шатава;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821884707;3;1
87162901-749b-11df-b112-00215aee3ebe;Шатерники;Шатерники;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823984903;3;1
87162902-749b-11df-b112-00215aee3ebe;Шатівка;Шатовка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323988001;3;1
87162905-749b-11df-b112-00215aee3ebe;Шатрище;Шатрище;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925686001;3;1
87162906-749b-11df-b112-00215aee3ebe;Шатрище;Шатрище;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822385205;3;1
87162907-749b-11df-b112-00215aee3ebe;Шатура;Шатура;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423389801;3;1
87162908-749b-11df-b112-00215aee3ebe;Шаулиха;Шаулиха;село;78f453ed-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124089701;3;1
8716290a-749b-11df-b112-00215aee3ebe;Шафранівка;Шафрановка;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755160;3;1
8716290c-749b-11df-b112-00215aee3ebe;Шахворостівка;Шахворостовка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822588401;3;1
8716290d-749b-11df-b112-00215aee3ebe;Шахворостівка;Шахворостовка;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323289201;3;1
8716290e-749b-11df-b112-00215aee3ebe;Шахи;Шахи;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621881307;3;1
5e58b9ff-8357-11e8-80d4-1c98ec135261;Шахтар;Шахторь;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222985506;3;1
589f960f-ac60-11e8-80d4-1c98ec135261;Шахтар;Шахтар;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;;3;1
87162912-749b-11df-b112-00215aee3ebe;Шахтарське;Шахтерское;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721180405;3;1
87162913-749b-11df-b112-00215aee3ebe;Шахтарське;Шахтерское;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1224884009;3;1
80f55bef-749b-11df-b112-00215aee3ebe;Шахтарське (Тельмана);Шахтерское (Тельмана);село;8067acc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223581304;3;1
87162918-749b-11df-b112-00215aee3ebe;Шацьк;Шацк;місто;bad3ac39-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0725755100;2;1
87162919-749b-11df-b112-00215aee3ebe;Шаян;Шаян;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125355303;3;0
8716291a-749b-11df-b112-00215aee3ebe;Швадрони;Швадроны;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755161;3;1
8716291b-749b-11df-b112-00215aee3ebe;Швайківка;Швайковка;село;4ffc4bad-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820887701;3;1
8716291c-749b-11df-b112-00215aee3ebe;Швайківці;Швайковцы;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125589101;3;1
8716291d-749b-11df-b112-00215aee3ebe;Швачиха;Швачиха;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220255104;3;1
8716291e-749b-11df-b112-00215aee3ebe;Шведове;Шведово;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225885511;3;1
8716291f-749b-11df-b112-00215aee3ebe;Шведчина;Шведчина;село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424786009;3;1
87162920-749b-11df-b112-00215aee3ebe;Швейків;Швейков;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124288401;3;1
87162921-749b-11df-b112-00215aee3ebe;Шебедиха;Шебедиха;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824482404;3;1
87162922-749b-11df-b112-00215aee3ebe;Шебелинка;Шебелинка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320287201;3;1
87162923-749b-11df-b112-00215aee3ebe;Шебутинці;Шебутинцы;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324089501;3;1
87162924-749b-11df-b112-00215aee3ebe;Шебутинці;Шебутинцы;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823380703;3;1
8d17ed31-749b-11df-b112-00215aee3ebe;Шев'якине;Шевякино;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220787715;3;1
87162925-749b-11df-b112-00215aee3ebe;Шевелівка;Шевелевка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320287401;3;1
87162926-749b-11df-b112-00215aee3ebe;Шевське;Шевское;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222385004;3;1
87162927-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321685607;3;1
87162928-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;8a199ccb-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1223881007;3;1
87162929-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322055604;3;1
8716292a-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123583403;3;1
8716292b-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321587305;3;1
8d17ecb6-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821480603;3;1
8d17ecb7-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825883202;3;1
8d17ecb8-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425589103;3;1
8d17ecb9-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420886802;3;1
8d17ecba-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420389006;3;1
8d17ecbb-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421782409;3;1
8d17ecbc-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425382505;3;1
8d17ecbd-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424155904;3;1
8d17ecbe-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;6b07f870-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7123786502;3;1
8d17ecbf-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522487401;3;1
8d17ecc0-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523084008;3;1
8d17ecc1-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523781303;3;1
8d17ecc2-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523682207;3;1
8d17ecc3-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;8067accd-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3525581902;3;1
8d17ecc4-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521781905;3;1
8d17ecc5-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524655402;3;1
8d17ecc6-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823985304;3;1
8d17ecc7-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822183306;3;1
8d17ecc8-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824289403;3;1
8d17ecc9-749b-11df-b112-00215aee3ebe;Шевченка;Шевченко;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825855101;3;1
33ec0011-ddd5-11df-9197-00215aee3ebe;Шевченка;Шевченко;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520281812;3;1
8d17ecca-749b-11df-b112-00215aee3ebe;Шевченки;Шевченки;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321382709;3;1
8d17eccb-749b-11df-b112-00215aee3ebe;Шевченки;Шевченки;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324085003;3;1
8d17eccc-749b-11df-b112-00215aee3ebe;Шевченки;Шевченки;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320684605;3;1
8d17eccd-749b-11df-b112-00215aee3ebe;Шевченки;Шевченки;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322084909;3;1
8d17ecce-749b-11df-b112-00215aee3ebe;Шевченки;Шевченки;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321881705;3;1
8d17eccf-749b-11df-b112-00215aee3ebe;Шевченки;Шевченки;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322684902;3;1
8d17ecd0-749b-11df-b112-00215aee3ebe;Шевченків Гай;Шевченков Гай;село;f9333441-db4e-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5925080807;3;1
8d17ecd1-749b-11df-b112-00215aee3ebe;Шевченківка;Шевченковка;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325455307;3;1
8d17ecd2-749b-11df-b112-00215aee3ebe;Шевченківка;Шевченковка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920655314;3;1
8d17ecd3-749b-11df-b112-00215aee3ebe;Шевченківка;Шевченковка;село;8a199ced-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1222387401;3;1
8d17ecd4-749b-11df-b112-00215aee3ebe;Шевченківка;Шевченковка;село;78f4540d-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;6524182504;3;1
8d17ecd5-749b-11df-b112-00215aee3ebe;Шевченківка;Шевченковка;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221488601;3;1
8d17ecd6-749b-11df-b112-00215aee3ebe;Шевченківка;Шевченковка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523783007;3;1
8d17ecd7-749b-11df-b112-00215aee3ebe;Шевченківське;Шевченковское;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224888501;3;1
8d17ecd8-749b-11df-b112-00215aee3ebe;Шевченківське;Шевченковское;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220755140;3;1
8d17ecd9-749b-11df-b112-00215aee3ebe;Шевченківське;Шевченковское;село;6b07f8ce-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323681306;3;1
8d17ecda-749b-11df-b112-00215aee3ebe;Шевченківське;Шевченковское;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322188603;3;1
6ed81f52-749b-11df-b112-00215aee3ebe;Шевченківське (Орджонікідзе);Шевченковское (Орджоникидзе);село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221885901;3;1
c8674a89-dbb0-11df-9197-00215aee3ebe;Шевченко;Шевченко;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;;3;1
e0517ee4-db89-11df-9197-00215aee3ebe;Шевченко;Шевченко;село;f9333444-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;4825487606;3;1
8d17ecdc-749b-11df-b112-00215aee3ebe;Шевченко;Шевченко;село;6b07f886-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221482008;3;1
8d17ecdd-749b-11df-b112-00215aee3ebe;Шевченко;Шевченко;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220787714;3;1
8d17ecde-749b-11df-b112-00215aee3ebe;Шевченко;Шевченко;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1220310118;3;1
8d17ecdf-749b-11df-b112-00215aee3ebe;Шевченко;Шевченко;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;;3;1
8d17ece0-749b-11df-b112-00215aee3ebe;Шевченко;Шевченко;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821485101;3;1
8d17ece1-749b-11df-b112-00215aee3ebe;Шевченко;Шевченко;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610104;3;1
8d17ece2-749b-11df-b112-00215aee3ebe;Шевченко;Шевченко;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822382606;3;1
8d17ece3-749b-11df-b112-00215aee3ebe;Шевченко;Шевченко;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822783508;3;1
8d17ece4-749b-11df-b112-00215aee3ebe;Шевченко;Шевченко;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822085407;3;1
8d17ece5-749b-11df-b112-00215aee3ebe;Шевченко;Шевченко;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825181290;3;1
8d17ecf5-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324285601;3;1
8d17ecf6-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;78f4540b-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5322283707;3;1
8d17ecf7-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;9b486f64-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924787107;3;1
8d17ecf8-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925683003;3;1
8d17ecf9-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921589701;3;1
8d17ecfa-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922089001;3;1
8d17ecfb-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920982404;3;1
8d17ecfc-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922987804;3;1
8d17ecfd-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921281305;3;1
8d17ecfe-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124683302;3;1
8d17ecff-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220788801;3;1
8d17ed00-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221888207;3;1
8d17ed01-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222986901;3;1
8d17ed02-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824582606;3;1
8d17ed03-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823384701;2;1
8d17ed04-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;903cca9b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323581710;3;1
8d17ed07-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321288509;3;1
8d17ed08-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;місто;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325755100;2;1
8d17ed09-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323180804;3;1
8d17ed0a-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324586005;3;1
8d17ed0b-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324887001;3;1
8d17ed0c-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120283904;3;1
8d17ed0d-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;c7bb5b2d-671b-11ea-80c6-000c29800ae7;5121285801;3;1
8d17ed0e-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123985210;3;1
8d17ed0f-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;903ccab3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;568519c5-92de-11eb-80d3-000c29800ae7;5122385901;3;1
8d17ed10-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582008;3;1
8d17ed14-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621283302;3;1
8d17ed15-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622087601;3;1
8d17ed16-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;6b07f885-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2625255311;3;1
8d17ed18-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222084205;3;1
8d17ed19-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;78f45436-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;77c2cde7-3459-11ea-80e7-1c98ec135261;3221289501;3;1
8d17ed1a-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221987901;3;1
8d17ed1b-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225582707;3;1
8d17ed1c-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222480404;3;0
8d17ed1d-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3223388501;3;1
8d17ed1e-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223184203;3;1
8d17ed1f-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;710c27ee-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221682402;3;1
8d17ed20-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821784003;3;1
8d17ed21-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823488601;3;1
8d17ed22-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420382005;3;1
8d17ed23-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121289201;3;1
8d17ed24-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521210120;3;1
8d17ed25-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520255312;3;1
8d17ed26-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523185203;3;1
8d17ed27-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522284904;3;1
8d17ed28-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522585005;3;1
8d17ed29-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;710c27e7-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;;3;1
8d17ed2a-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322682411;3;1
8d17ed2b-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320487204;3;1
8d17ed30-749b-11df-b112-00215aee3ebe;Шевченкове;Шевченково;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521080212;3;1
8d17ed2c-749b-11df-b112-00215aee3ebe;Шевченкове Друге;Шевченково Второе;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320683005;3;1
8d17ed2e-749b-11df-b112-00215aee3ebe;Шевченкове Перше;Шевченково Первое;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320683004;3;1
8d17ed2f-749b-11df-b112-00215aee3ebe;Шевченкове-Кут;Шевченково-Кут;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122755104;3;1
8d17ed32-749b-11df-b112-00215aee3ebe;Шегині;Шегини;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622487901;3;1
8d17ed33-749b-11df-b112-00215aee3ebe;Шедієве;Шедиево;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323487601;3;1
8d17ed34-749b-11df-b112-00215aee3ebe;Шекеринці;Шекерынци;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822188701;3;1
8d17ed35-749b-11df-b112-00215aee3ebe;Шеки;Шеки;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322888401;3;1
8d17ed36-749b-11df-b112-00215aee3ebe;Шелепухи;Шелепухи;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124986507;3;1
8d17ed38-749b-11df-b112-00215aee3ebe;Шелестове;Шелестово;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323281001;3;1
8d17ed39-749b-11df-b112-00215aee3ebe;Шелестяни;Шелестяны;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823384004;3;1
8d17ed3a-749b-11df-b112-00215aee3ebe;Шелехове;Шелехово;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120282803;3;1
8d17ed3b-749b-11df-b112-00215aee3ebe;Шелехове;Шелехово;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821580803;3;1
8d17ed3c-749b-11df-b112-00215aee3ebe;Шелудьківка;Шелудьковка;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321787001;3;1
8d17ed3d-749b-11df-b112-00215aee3ebe;Шелудькове;Шелудьково;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321287006;3;1
8d17ed3f-749b-11df-b112-00215aee3ebe;Шельвів;Шельвов;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722486301;3;1
8d17ed40-749b-11df-b112-00215aee3ebe;Шельвів;Шельвов;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820383504;3;1
8d17ed41-749b-11df-b112-00215aee3ebe;Шельпаки;Шельпаки;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124684202;3;1
8d17ed42-749b-11df-b112-00215aee3ebe;Шельпахівка;Шельпаховка;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124688001;3;1
8d17ed44-749b-11df-b112-00215aee3ebe;Шеметове;Шеметово;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123981707;3;1
8d17eed4-749b-11df-b112-00215aee3ebe;Шеметове (Щорсове);Шеметове (Щорсово);село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121880706;3;1
8d17ed43-749b-11df-b112-00215aee3ebe;Шенборн;Шенборн;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122788101;2;1
8d17ed45-749b-11df-b112-00215aee3ebe;Шенгаріївка;Шенгариевка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321384007;3;1
8d17ed46-749b-11df-b112-00215aee3ebe;Шенгури;Шенгуры;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321888001;3;1
8d17ed47-749b-11df-b112-00215aee3ebe;Шендерів;Шендеров;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524587301;3;1
8d17ed48-749b-11df-b112-00215aee3ebe;Шендерівка;Шендеровка;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122589701;3;1
8d17ed49-749b-11df-b112-00215aee3ebe;Шендерівка;Шендеровка;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522280207;3;1
8d17ed4a-749b-11df-b112-00215aee3ebe;Шендерівка;Шендеровка;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524989001;3;1
8d17ed4b-749b-11df-b112-00215aee3ebe;Шепарівці;Шепаровцы;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623288701;3;1
ec611fcf-f47a-11e8-80d9-1c98ec135261;Шепелі;Шепели;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320255109;3;1
8d17ed4c-749b-11df-b112-00215aee3ebe;Шепелівка;Шепелевка;село;f9333443-db4e-11df-9197-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5324588015;3;1
8d17ed4d-749b-11df-b112-00215aee3ebe;Шепелівка;Шепелевка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610108;3;1
8d17ed4e-749b-11df-b112-00215aee3ebe;Шепель;Шепель;село;5d31126e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722881814;3;1
8d17ed4f-749b-11df-b112-00215aee3ebe;Шепетин;Шепетин;село;710c27c9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621689501;3;1
8d17ed50-749b-11df-b112-00215aee3ebe;Шепетівка;Шепетовка;місто;78f453e9-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6810700000;1;0
8d17ed51-749b-11df-b112-00215aee3ebe;Шепилове;Шепилово;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521488301;3;1
8d17ed52-749b-11df-b112-00215aee3ebe;Шепіївка;Шепиевка;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521686306;3;1
8d17ed53-749b-11df-b112-00215aee3ebe;Шепіт;Шепот;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323586001;3;1
8d17ed54-749b-11df-b112-00215aee3ebe;Шепіт;Шепот;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623688501;3;1
8d17ed55-749b-11df-b112-00215aee3ebe;Шептаки;Шептаки;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423689801;3;1
8d17ed56-749b-11df-b112-00215aee3ebe;Шептичі;Шептичи;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624284903;3;1
8d17ed57-749b-11df-b112-00215aee3ebe;Шереметів;Шереметев;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825484107;3;1
8d17ed58-749b-11df-b112-00215aee3ebe;Шерове;Шерово;село;4ffc4bd5-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121882006;3;1
8d17ed59-749b-11df-b112-00215aee3ebe;Шершенівка;Шершеневка;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120888701;3;1
8d17ed5a-749b-11df-b112-00215aee3ebe;Шершенці;Шершенцы;село;710c273d-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122585601;3;1
8d17ed5b-749b-11df-b112-00215aee3ebe;Шершні;Шершни;село;710c27f0-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822386801;3;1
8d17ed5c-749b-11df-b112-00215aee3ebe;Шершні;Шершни;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520285615;3;1
8d17ed5d-749b-11df-b112-00215aee3ebe;Шершні;Шершни;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524587501;3;1
8d17ed5e-749b-11df-b112-00215aee3ebe;Шершнівка;Шершневка;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322888901;3;1
8d17ed60-749b-11df-b112-00215aee3ebe;Шестакове;Шестаково;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520984309;3;1
8d17ed61-749b-11df-b112-00215aee3ebe;Шестеринці;Шестеринцы;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122888201;3;1
8d17ed62-749b-11df-b112-00215aee3ebe;Шестипілля;Шестиполье;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055116;3;1
8d17ed63-749b-11df-b112-00215aee3ebe;Шестірня;Шестирня;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225855115;3;1
8d17ed64-749b-11df-b112-00215aee3ebe;Шестовиця;Шестовица;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425589701;3;1
8d17ed65-749b-11df-b112-00215aee3ebe;Шешори;Шешоры;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623688801;3;1
8d17ed66-749b-11df-b112-00215aee3ebe;Шибалин;Шибалин;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120489401;3;1
8d17ed67-749b-11df-b112-00215aee3ebe;Шибена;Шибена;село;6b07f823-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824787001;3;1
8d17ed68-749b-11df-b112-00215aee3ebe;Шибене;Шибеное;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221088601;3;1
8d17ed69-749b-11df-b112-00215aee3ebe;Шибин;Шибин;село;78f453a1-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621480721;3;1
8d17ed6a-749b-11df-b112-00215aee3ebe;Шибиринівка;Шибириновка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425589901;3;1
c6cc1c07-db7e-11df-9197-00215aee3ebe;Шигимагине;Шигимагино;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320884506;3;1
8d17ed6b-749b-11df-b112-00215aee3ebe;Шидлівці;Шидловцы;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121687002;3;1
8d17ed6c-749b-11df-b112-00215aee3ebe;Шидлівці;Шидловцы;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825289401;2;1
8d17ed6d-749b-11df-b112-00215aee3ebe;Шиїнці;Шиенцы;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821588601;3;1
8d17ed6e-749b-11df-b112-00215aee3ebe;Шийки;Шийки;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321283009;3;1
8d17ed6f-749b-11df-b112-00215aee3ebe;Шийківка;Шийковка;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321055103;3;1
8d17ed70-749b-11df-b112-00215aee3ebe;Шийчине;Шийчино;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320888002;3;1
8d17ed71-749b-11df-b112-00215aee3ebe;Шили;Шилы;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324083509;3;1
8d17ed72-749b-11df-b112-00215aee3ebe;Шили;Шилы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122489201;3;1
8d17ed73-749b-11df-b112-00215aee3ebe;Шили;Шилы;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123884105;3;1
8d17ed74-749b-11df-b112-00215aee3ebe;Шилівка;Шиловка;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321387801;3;1
8d17ed75-749b-11df-b112-00215aee3ebe;Шилівка;Шиловка;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324285701;3;1
8d17ed77-749b-11df-b112-00215aee3ebe;Шилівське;Шиловское;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187907;3;1
8d17ed78-749b-11df-b112-00215aee3ebe;Шилівці;Шиловцы;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325089101;3;1
8d17ed79-749b-11df-b112-00215aee3ebe;Шиловичі;Шиловичи;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421782003;3;1
8d17ed7a-749b-11df-b112-00215aee3ebe;Шиманівка;Шимановка;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0523788001;3;1
8d17ed7b-749b-11df-b112-00215aee3ebe;Шимківці;Шимковцы;село;6b07f82a-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6122489601;3;1
8d17ed7c-749b-11df-b112-00215aee3ebe;Шимківці;Шимковцы;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820385003;3;1
8d17ed7d-749b-11df-b112-00215aee3ebe;Шимкове;Шимково;село;5d3111cc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120285601;3;1
8d17ed7f-749b-11df-b112-00215aee3ebe;Шинківщина;Шинковщина;село;710c27ea-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322880306;3;1
8d17ed81-749b-11df-b112-00215aee3ebe;Шипинки;Шипинки;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520255315;3;1
8d17ed82-749b-11df-b112-00215aee3ebe;Шипинці;Шипинцы;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322589001;3;1
8d17ed83-749b-11df-b112-00215aee3ebe;Шипівці;Шиповцы;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122089801;3;1
8d17ed84-749b-11df-b112-00215aee3ebe;Шипоші;Шипоши;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320281218;3;1
8d17ed86-749b-11df-b112-00215aee3ebe;Ширівці;Шировцы;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325089201;3;1
8d17ed87-749b-11df-b112-00215aee3ebe;Ширмівка;Ширмовка;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523488201;3;1
8d17ed88-749b-11df-b112-00215aee3ebe;Широка Балка;Широкая Балка;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520388601;3;1
8d17ed8c-749b-11df-b112-00215aee3ebe;Широка Балка;Широкая Балка;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225980517;3;1
8d17ed8d-749b-11df-b112-00215aee3ebe;Широка Балка;Широкая Балка;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121084102;3;1
8d17ed91-749b-11df-b112-00215aee3ebe;Широка Балка;Широкая Балка;село;f9333449-db4e-11df-9197-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3521986905;3;1
8d17ed92-749b-11df-b112-00215aee3ebe;Широка Гребля;Широкая Гребля;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521485006;3;1
8d17ed89-749b-11df-b112-00215aee3ebe;Широка Гребля;Широкая Гребля;село;710c27ed-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524887801;3;1
8d17ed8a-749b-11df-b112-00215aee3ebe;Широка Гребля;Широкая Гребля;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520688203;3;1
8d17ed93-749b-11df-b112-00215aee3ebe;Широка Дача;Широкая Дача;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225884818;3;1
8d17ed94-749b-11df-b112-00215aee3ebe;Широка Долина;Широкая Долина;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225884819;3;1
8d17ed8b-749b-11df-b112-00215aee3ebe;Широка Долина;Широкая Долина;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320285701;3;1
8d17ed95-749b-11df-b112-00215aee3ebe;Широке;Широкое;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324884210;3;1
8d17ed96-749b-11df-b112-00215aee3ebe;Широке;Широкое;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320280907;3;1
8d17ed97-749b-11df-b112-00215aee3ebe;Широке;Широкое;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720888604;3;1
8d17ed98-749b-11df-b112-00215aee3ebe;Широке;Широкое;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225055117;3;1
8d17ed99-749b-11df-b112-00215aee3ebe;Широке;Широкое;село;4ffc4b9b-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1220787716;3;1
8d17ed9a-749b-11df-b112-00215aee3ebe;Широке;Широкое;село;903ccaa5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1221888201;3;1
8d17ed9b-749b-11df-b112-00215aee3ebe;Широке;Широкое;місто;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225855100;2;1
8d17ed9c-749b-11df-b112-00215aee3ebe;Широке;Широкое;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825785005;3;1
8d17ed9f-749b-11df-b112-00215aee3ebe;Широке;Широкое;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320685507;3;1
8d17eda0-749b-11df-b112-00215aee3ebe;Широке;Широкое;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123583004;3;1
8d17eda1-749b-11df-b112-00215aee3ebe;Широке;Широкое;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123986405;3;1
8d17eda2-749b-11df-b112-00215aee3ebe;Широке;Широкое;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122781704;3;1
8d17eda3-749b-11df-b112-00215aee3ebe;Широке;Широкое;село;4ffc4bd4-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5120884206;3;1
8d17eda4-749b-11df-b112-00215aee3ebe;Широке;Широкое;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322189005;3;1
8d17eda5-749b-11df-b112-00215aee3ebe;Широке;Широкое;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323987815;3;1
8d17edab-749b-11df-b112-00215aee3ebe;Широке;Широкое;село;78f4542f-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121287201;3;1
8d17edac-749b-11df-b112-00215aee3ebe;Широке;Широкое;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125386203;3;1
8d17edb1-749b-11df-b112-00215aee3ebe;Широке;Широкое;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520280105;3;1
17af7b20-ddb9-11df-9197-00215aee3ebe;Широке;Широкое;місто;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221010302;3;1
68cf2744-749b-11df-b112-00215aee3ebe;Широке (Менжинка);Широкое (Менжинка);село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225255101;3;1
8716221e-749b-11df-b112-00215aee3ebe;Широке (Уральське);Широкое (Уральское);село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321583006;3;1
8d17edb4-749b-11df-b112-00215aee3ebe;Широкий Берег;Широкий Берег;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921280505;3;1
8d17edb6-749b-11df-b112-00215aee3ebe;Широкий Луг;Широкий Луг;село;710c274c-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124488401;3;1
8d17edb7-749b-11df-b112-00215aee3ebe;Широкий Яр;Широкий Яр;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322680505;3;1
8d17edba-749b-11df-b112-00215aee3ebe;Широкі Криниці;Широкие Криницы;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822781209;3;1
8d17edbb-749b-11df-b112-00215aee3ebe;Широколанівка;Широколановка;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821785601;3;1
8d17edbc-749b-11df-b112-00215aee3ebe;Широкополе;Широкополе;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225081006;3;1
8d17edbd-749b-11df-b112-00215aee3ebe;Широкосмоленка;Широкосмоленка;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224881505;3;1
8d17edbe-749b-11df-b112-00215aee3ebe;Широчани;Широчаны;село;5d3112a9-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1220310119;3;1
8d17edbf-749b-11df-b112-00215aee3ebe;Ширяєве;Ширяево;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923885808;3;1
8d17edc0-749b-11df-b112-00215aee3ebe;Ширяєве;Ширяево;місто;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125455100;3;1
8d17edc1-749b-11df-b112-00215aee3ebe;Шистів;Шистов;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720582005;3;1
8d17edc2-749b-11df-b112-00215aee3ebe;Шихтарі;Шихтари;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624887805;3;1
8d17edc3-749b-11df-b112-00215aee3ebe;Шишаки;Шишаки;місто;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755100;2;0
8d17edc4-749b-11df-b112-00215aee3ebe;Шишаки;Шишаки;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624880607;3;1
8d17edc5-749b-11df-b112-00215aee3ebe;Шишаки;Шишаки;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324881912;3;1
8d17edc6-749b-11df-b112-00215aee3ebe;Шишацьке;Шишацкое;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324281511;3;1
8d17edc7-749b-11df-b112-00215aee3ebe;Шишка;Шишка;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422482006;3;1
8d17edc8-749b-11df-b112-00215aee3ebe;Шишкине;Шишкино;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222985007;3;1
8d17edca-749b-11df-b112-00215aee3ebe;Шишкине;Шишкино;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524087201;3;1
8d17edcb-749b-11df-b112-00215aee3ebe;Шишківка;Шишковка;село;64024b06-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325785007;3;1
8d17edcc-749b-11df-b112-00215aee3ebe;Шишківка;Шишковка;село;78f45408-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422489601;3;1
8d17edcd-749b-11df-b112-00215aee3ebe;Шишківці;Шишковцы;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
8d17edce-749b-11df-b112-00215aee3ebe;Шишківці;Шишковцы;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322589501;3;1
8d17edcf-749b-11df-b112-00215aee3ebe;Шишківці;Шишковцы;село;78f45405-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;7324089601;3;1
8d17edd0-749b-11df-b112-00215aee3ebe;Шишківці;Шишковцы;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323086703;3;1
8d17edd1-749b-11df-b112-00215aee3ebe;Шишківці;Шишковцы;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620380803;3;1
8d17edd2-749b-11df-b112-00215aee3ebe;Шишківці;Шишковцы;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821283602;3;1
8d17edd4-749b-11df-b112-00215aee3ebe;Шишлівці;Шишловцы;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;9e40dc6a-010e-11df-b41f-00215aee3ebe;2124886804;3;1
8d17edd5-749b-11df-b112-00215aee3ebe;Шишоровичі;Шишеровичи;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622484405;3;1
8d17eddb-749b-11df-b112-00215aee3ebe;Шкаврове;Шкавровое;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323381209;3;1
8d17edd6-749b-11df-b112-00215aee3ebe;Шкадрети;Шкадреты;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322681909;3;1
8d17edd7-749b-11df-b112-00215aee3ebe;Шкарбинка;Шкарбинка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123383207;3;1
8d17edd8-749b-11df-b112-00215aee3ebe;Шкарів;Шкаров;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621282007;3;1
8d17edd9-749b-11df-b112-00215aee3ebe;Шкарівка;Шкаровка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220489501;2;0
8d17edda-749b-11df-b112-00215aee3ebe;Шкарлати;Шкарлаты;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320888315;3;1
8d17eddc-749b-11df-b112-00215aee3ebe;Шкилі;Шкили;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324883217;3;1
8d17eddd-749b-11df-b112-00215aee3ebe;Шкирманівка;Шкирмановка;село;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925384903;3;1
8d17eddf-749b-11df-b112-00215aee3ebe;Шклинь;Шклинь;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720889201;3;1
8d17ede0-749b-11df-b112-00215aee3ebe;Шклинь Другий;Шклинь Второй;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720889205;3;1
8d17ede1-749b-11df-b112-00215aee3ebe;Шкло;Шкло;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625856500;3;1
8d17ede2-749b-11df-b112-00215aee3ebe;Шкнева;Шкнева;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223589201;3;1
8d17ede3-749b-11df-b112-00215aee3ebe;Шкодунівка;Шкодуновка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121580802;3;1
8d17ede4-749b-11df-b112-00215aee3ebe;Школярі;Школяры;село;64024b1e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622783911;3;1
8d17ede5-749b-11df-b112-00215aee3ebe;Шкроби;Шкробы;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725085404;3;1
8d17ede6-749b-11df-b112-00215aee3ebe;Шкробове;Шкробово;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425888207;3;1
8d17ede7-749b-11df-b112-00215aee3ebe;Шкроботи;Шкроботы;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923585003;3;1
8d17ede8-749b-11df-b112-00215aee3ebe;Шкроботівка;Шкроботовка;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125889201;3;1
8d17ede9-749b-11df-b112-00215aee3ebe;Шкуматове;Шкуматово;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920987904;3;1
8d17edea-749b-11df-b112-00215aee3ebe;Шкуранка;Шкуранка;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424482511;3;1
8d17edeb-749b-11df-b112-00215aee3ebe;Шкурат;Шкурат;село;64024b18-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0722187411;3;1
8d17edec-749b-11df-b112-00215aee3ebe;Шкурати;Шкураты;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323882603;3;1
8d17eded-749b-11df-b112-00215aee3ebe;Шкуратівка;Шкуратовка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920689501;3;1
8d17edee-749b-11df-b112-00215aee3ebe;Шкуратівці;Шкуратовцы;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122783405;3;1
8d17edef-749b-11df-b112-00215aee3ebe;Шкуратове;Шкуратово;село;4ffc4bc4-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820382611;3;1
8d17edf0-749b-11df-b112-00215aee3ebe;Шкурпели;Шкурпелы;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321384406;3;1
8d17edf1-749b-11df-b112-00215aee3ebe;Шкурупії;Шкурупеи;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324280913;3;1
8d17edf2-749b-11df-b112-00215aee3ebe;Шкурупіївка;Шкурупиевка;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324210109;3;1
8d17edf3-749b-11df-b112-00215aee3ebe;Шлапань;Шлапань;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723184803;3;1
8d17edf4-749b-11df-b112-00215aee3ebe;Шликареве;Шликарево;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123381407;3;1
8d17edf5-749b-11df-b112-00215aee3ebe;Шлишківці;Шлишковцы;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522685303;3;1
8d17edf6-749b-11df-b112-00215aee3ebe;Шлях;Шлях;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922681209;3;1
8d17edf7-749b-11df-b112-00215aee3ebe;Шлях;Шлях;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321288510;3;1
8d17edf8-749b-11df-b112-00215aee3ebe;Шлях;Шлях;село;710c2723-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5624281605;3;1
8d17edfc-749b-11df-b112-00215aee3ebe;Шляхова;Шляховая;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520487503;3;1
8d17edfe-749b-11df-b112-00215aee3ebe;Шляхове;Шляховое;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323281201;3;1
8d17edff-749b-11df-b112-00215aee3ebe;Шляхове;Шляховое;село;5d311226-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323186001;3;1
8d17ee00-749b-11df-b112-00215aee3ebe;Шляхове;Шляховое;село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120689501;3;1
8d17ee01-749b-11df-b112-00215aee3ebe;Шляхове;Шляховое;село;78f453a7-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520688401;3;1
8d17ee02-749b-11df-b112-00215aee3ebe;Шляхове;Шляховое;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523683903;3;1
8d17ee03-749b-11df-b112-00215aee3ebe;Шляхове;Шляховое;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520810103;3;1
8d17ee04-749b-11df-b112-00215aee3ebe;Шляхтинці;Шляхтинцы;село;5d3111de-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125289801;3;1
8d17ee05-749b-11df-b112-00215aee3ebe;Шмаївка;Шмаевка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425581606;3;1
8d17ee06-749b-11df-b112-00215aee3ebe;Шмакове;Шмаково;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055120;3;1
8d17ee07-749b-11df-b112-00215aee3ebe;Шманьківці;Шманьковцы;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125589201;3;1
8d17ee08-749b-11df-b112-00215aee3ebe;Шманьківчики;Шманьковчики;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125589301;3;1
8d17ee09-749b-11df-b112-00215aee3ebe;Шматове;Шматово;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923555610;3;1
8d17ee0a-749b-11df-b112-00215aee3ebe;Шменьки;Шменьки;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724284503;3;1
8d17ee0b-749b-11df-b112-00215aee3ebe;Шмиглі;Шмыгли;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324087707;3;1
8d17ee0c-749b-11df-b112-00215aee3ebe;Шмиглі;Шмыгли;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322688404;3;1
8d17ee0d-749b-11df-b112-00215aee3ebe;Шмидове;Шмидово;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;;3;1
8d17ee0e-749b-11df-b112-00215aee3ebe;Шмирки;Шмырки;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820984301;3;1
8d17ee10-749b-11df-b112-00215aee3ebe;Шмідтівка;Шмидтовка;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820955118;3;1
8d17ee11-749b-11df-b112-00215aee3ebe;Шмідтове;Шмидтово;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825784707;3;1
8d17ee13-749b-11df-b112-00215aee3ebe;Шнирів;Шнырев;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620388401;3;1
8d17ee14-749b-11df-b112-00215aee3ebe;Шнурів Ліс;Шнуров Лес;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222780404;3;1
8d17ee16-749b-11df-b112-00215aee3ebe;Шняківка;Шняковка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423389901;3;1
8d17ee19-749b-11df-b112-00215aee3ebe;Шовкове;Шелковое;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220281709;3;1
8d17ee1b-749b-11df-b112-00215aee3ebe;Шовкопляси;Шелкоплясы;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323480406;3;1
8d17ee1c-749b-11df-b112-00215aee3ebe;Шовкопляси;Шелкоплясы;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322010109;3;1
8d17ee1e-749b-11df-b112-00215aee3ebe;Шоломиничі;Шоломыничи;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620989001;3;1
8d17ee1f-749b-11df-b112-00215aee3ebe;Шоломинь;Шоломынь;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623683105;3;1
8d17ee20-749b-11df-b112-00215aee3ebe;Шоломки;Шоломки;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824288801;3;1
8d17ee21-749b-11df-b112-00215aee3ebe;Шолохове;Шолохово;село;8067ad5c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;28d880c8-0c75-11e1-9215-003048d2b473;1222987001;3;1
8d17ee22-749b-11df-b112-00215aee3ebe;Шолудьки;Шелудьки;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920382403;3;1
8d17ee23-749b-11df-b112-00215aee3ebe;Шолудьки;Шелудьки;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523085803;3;1
8d17ee24-749b-11df-b112-00215aee3ebe;Шом;Шом;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120484001;3;1
8d17ee25-749b-11df-b112-00215aee3ebe;Шопине;Шопино;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322082510;3;1
8d17ee26-749b-11df-b112-00215aee3ebe;Шопки;Шопкы;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621884909;3;1
8d17ee27-749b-11df-b112-00215aee3ebe;Шостаки;Шостаки;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324085209;3;0
8d17ee28-749b-11df-b112-00215aee3ebe;Шостаків;Шостаков;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624682709;3;1
8d17ee2a-749b-11df-b112-00215aee3ebe;Шостаківка;Шестаковка;село;4ffc4be0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522585006;3;1
8d17ee2b-749b-11df-b112-00215aee3ebe;Шостакове;Шостаково;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824285001;3;1
8d17ee2c-749b-11df-b112-00215aee3ebe;Шостакове;Шостаково;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122287801;3;1
8d17ee2d-749b-11df-b112-00215aee3ebe;Шостка;Шостка;місто;8067acca-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5911000000;1;0
8d17ee2f-749b-11df-b112-00215aee3ebe;Шпаки;Шпаки;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620386205;3;1
8d17ee30-749b-11df-b112-00215aee3ebe;Шпаків;Шпаков;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422084202;3;1
8d17ee31-749b-11df-b112-00215aee3ebe;Шпаківка;Шпаковка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322887009;3;1
8d17ee32-749b-11df-b112-00215aee3ebe;Шпакове;Шпаково;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523880401;3;1
8d17ee33-749b-11df-b112-00215aee3ebe;Шпакове;Шпаково;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323281808;3;1
8d17ee34-749b-11df-b112-00215aee3ebe;Шпанів;Шпанов;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624689501;3;1
8d17ee35-749b-11df-b112-00215aee3ebe;Шпендівка;Шпендовка;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222289001;3;1
8d17ee36-749b-11df-b112-00215aee3ebe;Шпетки;Шпетки;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323585503;3;1
8d17ee37-749b-11df-b112-00215aee3ebe;Шпиків;Шпиков;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524355600;3;1
8d17ee38-749b-11df-b112-00215aee3ebe;Шпиківка;Шпиковка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524355604;3;1
8d17ee39-749b-11df-b112-00215aee3ebe;Шпиколоси;Шпиколосы;село;710c273f-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123489401;3;1
8d17ee3a-749b-11df-b112-00215aee3ebe;Шпиколоси;Шпиколосы;село;710c277a-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4624882603;3;1
8d17ee3b-749b-11df-b112-00215aee3ebe;Шпиколоси;Шпиколосы;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621888201;3;1
8d17ee3c-749b-11df-b112-00215aee3ebe;Шпилі;Шпили;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222086801;3;1
8d17ee3d-749b-11df-b112-00215aee3ebe;Шпилівка;Шпилевка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924789101;2;1
8d17ee3e-749b-11df-b112-00215aee3ebe;Шпиль;Шпиль;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920686609;3;1
8d17ee3f-749b-11df-b112-00215aee3ebe;Шпильчина;Шпильчина;село;710c2734-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623310301;3;1
8d17ee40-749b-11df-b112-00215aee3ebe;Шпирки;Шпиркы;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520281009;3;1
8d17ee41-749b-11df-b112-00215aee3ebe;Шпитьки;Шпитьки;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222488201;3;1
8d17ee42-749b-11df-b112-00215aee3ebe;Шпичинці;Шпичинцы;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825287501;3;1
8d17ee43-749b-11df-b112-00215aee3ebe;Шпичинці;Шпичинцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825089801;3;1
8d17ee44-749b-11df-b112-00215aee3ebe;Шпичинці;Шпичинцы;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821588801;3;1
8d17ee45-749b-11df-b112-00215aee3ebe;Шпокалка;Шпокалка;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920983207;3;1
8d17ee46-749b-11df-b112-00215aee3ebe;Шпола;Шпола;місто;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125710100;2;0
8d17ee47-749b-11df-b112-00215aee3ebe;Шпортьки;Шпортки;село;5d311221-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5323486710;3;1
8d17ee49-749b-11df-b112-00215aee3ebe;Шпотівка;Шпотовка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922089301;3;1
8d17ee4a-749b-11df-b112-00215aee3ebe;Шрамки;Шрамки;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324282603;3;1
8d17ee4b-749b-11df-b112-00215aee3ebe;Шрамківка;Шрамковка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120689501;3;1
8d17ee4c-749b-11df-b112-00215aee3ebe;Шрубків;Шрубков;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823086601;3;1
8d17ee4d-749b-11df-b112-00215aee3ebe;Штанівка;Штановка;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920682204;3;1
8d17ee4e-749b-11df-b112-00215aee3ebe;Штепівка;Штеповка;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922989601;3;1
8d17ee50-749b-11df-b112-00215aee3ebe;Штомпелі;Штомпели;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323283412;3;1
8d17ee51-749b-11df-b112-00215aee3ebe;Штомпелівка;Штомпелевка;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324887701;3;1
8d17ee54-749b-11df-b112-00215aee3ebe;Штукар;Штукар;село;8067ad55-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821785007;3;1
8d17ee55-749b-11df-b112-00215aee3ebe;Штуковець;Штуковец;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625585909;3;1
8d17ee56-749b-11df-b112-00215aee3ebe;Штунь;Штунь;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723387201;3;1
8d17ee57-749b-11df-b112-00215aee3ebe;Шуби;Шубы;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320888003;3;1
8d17ee59-749b-11df-b112-00215aee3ebe;Шубині Стави;Шубины Ставы;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122888401;3;1
8d17ee5a-749b-11df-b112-00215aee3ebe;Шубівка;Шубовка;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222289201;3;1
8d17ee5b-749b-11df-b112-00215aee3ebe;Шубіне;Шубино;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325181505;3;1
8d17ee5c-749b-11df-b112-00215aee3ebe;Шубків;Шубков;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624689801;3;1
8d17ee5d-749b-11df-b112-00215aee3ebe;Шубранець;Шубранец;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321589501;3;1
8d17ee5e-749b-11df-b112-00215aee3ebe;Шугаївка;Шугаевка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323955709;3;1
8d17ee5f-749b-11df-b112-00215aee3ebe;Шукайвода;Шукайвода;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124688501;3;1
8d17ee60-749b-11df-b112-00215aee3ebe;Шулешівка;Шулешовка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923881404;3;1
8d17ee62-749b-11df-b112-00215aee3ebe;Шуляки;Шуляки;село;78f453ad-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7422085007;3;1
8d17ee63-749b-11df-b112-00215aee3ebe;Шуляки;Шуляки;село;710c273e-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7120989701;3;1
8d17ee64-749b-11df-b112-00215aee3ebe;Шульганівка;Шульгановка;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125589501;3;1
8d17ee65-749b-11df-b112-00215aee3ebe;Шульги;Шульги;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323255407;3;1
8d17ee67-749b-11df-b112-00215aee3ebe;Шульгівка;Шульговка;село;bad3ac3c-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1223783801;3;1
8d17ee68-749b-11df-b112-00215aee3ebe;Шульгівка;Шульговка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225083505;3;1
8d17ee69-749b-11df-b112-00215aee3ebe;Шульгівка;Шульговка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425583704;3;1
8d17ee6a-749b-11df-b112-00215aee3ebe;Шульське;Шульское;село;f9333440-db4e-11df-9197-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324586504;3;1
8d17ee79-749b-11df-b112-00215aee3ebe;Шум'яч;Шумяч;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625587401;3;1
8d17ee6b-749b-11df-b112-00215aee3ebe;Шумани;Шуманы;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424483204;3;1
8d17ee6c-749b-11df-b112-00215aee3ebe;Шумбар;Шумбар;село;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125889501;3;1
8d17ee6d-749b-11df-b112-00215aee3ebe;Шумейкине;Шумейкино;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420386012;3;1
8d17ee6e-749b-11df-b112-00215aee3ebe;Шумейків;Шумейков;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420385504;3;1
8d17ee70-749b-11df-b112-00215aee3ebe;Шуми;Шумы;село;5d31122b-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0521980404;3;1
8d17ee71-749b-11df-b112-00215aee3ebe;Шумили;Шумилы;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922986507;3;1
8d17ee72-749b-11df-b112-00215aee3ebe;Шумилів;Шумилов;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520483009;3;1
87162610-749b-11df-b112-00215aee3ebe;Шумилове (Червона Зірка);Шумилово (Червоная Зирка);село;5d31122c-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5120689504;3;1
8d17ee73-749b-11df-b112-00215aee3ebe;Шумин;Шумин;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420383005;3;1
8d17ee74-749b-11df-b112-00215aee3ebe;Шумина;Шумина;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625183504;3;1
8d17ee75-749b-11df-b112-00215aee3ebe;Шумівці;Шумовцы;село;5d31122a-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825089901;3;0
8d17ee76-749b-11df-b112-00215aee3ebe;Шумляни;Шумляны;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;;3;1
8d17ee78-749b-11df-b112-00215aee3ebe;Шумськ;Шумск;місто;5d311238-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6125810100;3;1
8d17ee77-749b-11df-b112-00215aee3ebe;Шумське;Шумское;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924181403;3;1
8d17ee7a-749b-11df-b112-00215aee3ebe;Шуньки;Шуньки;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820389505;3;1
8d17ee7b-749b-11df-b112-00215aee3ebe;Шупарка;Шупарка;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120888801;3;1
8d17ee7c-749b-11df-b112-00215aee3ebe;Шупики;Шупики;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220688001;3;1
8d17ee7d-749b-11df-b112-00215aee3ebe;Шура;Шура;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523087204;3;1
8d17ee7e-749b-11df-b112-00215aee3ebe;Шура-Бондурівська;Шура-Бондуровская;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520880306;3;1
8d17ee7f-749b-11df-b112-00215aee3ebe;Шура-Копіівська;Шура-Копеевская;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524387001;3;1
8d17ee80-749b-11df-b112-00215aee3ebe;Шура-Мітлинецька;Шура-Метлинецкая;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520883310;3;1
8d17ee81-749b-11df-b112-00215aee3ebe;Шурине;Шурино;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824280209;3;1
8d17ee82-749b-11df-b112-00215aee3ebe;Шурине;Шурино;село;78f4540f-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
8d17ee83-749b-11df-b112-00215aee3ebe;Шурове;Шурово;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921280506;3;1
8d17ee84-749b-11df-b112-00215aee3ebe;Шустівці;Шустовцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822489801;2;1
8d17ee85-749b-11df-b112-00215aee3ebe;Шутеньке;Шутенькое;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524080305;3;1
8d17ee86-749b-11df-b112-00215aee3ebe;Шутнівці;Шутновцы;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822489111;3;1
8d17ee87-749b-11df-b112-00215aee3ebe;Шутова;Шутова;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625884709;3;1
8d17ee88-749b-11df-b112-00215aee3ebe;Шутове;Шутово;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;c7bb5b2d-671b-11ea-80c6-000c29800ae7;5121283606;3;1
8d17ee89-749b-11df-b112-00215aee3ebe;Шутроминці;Шутроминцы;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122089901;3;1
8d17ee8a-749b-11df-b112-00215aee3ebe;Шуфрачанка;Шуфрачанка;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623655504;3;1
8d17ee8b-749b-11df-b112-00215aee3ebe;Шушвалівка;Шушваловка;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320687205;3;1
8d17ee8c-749b-11df-b112-00215aee3ebe;Шушківка;Шушковка;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122888002;3;1
8d17ee8d-749b-11df-b112-00215aee3ebe;Шушківці;Шушковцы;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123880905;3;1
8d17ee8e-749b-11df-b112-00215aee3ebe;Щаслива;Счастливая;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522287001;3;1
8d17ee8f-749b-11df-b112-00215aee3ebe;Щасливе;Счасливое;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955428;3;1
8d17ee91-749b-11df-b112-00215aee3ebe;Щасливе;Счасливое;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625355304;3;1
8d17ee92-749b-11df-b112-00215aee3ebe;Щасливе;Счасливое;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122784204;3;1
8d17ee94-749b-11df-b112-00215aee3ebe;Щасливе;Счасливое;село;5d31121a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220888001;2;0
8d17ee95-749b-11df-b112-00215aee3ebe;Щасливе;Счасливое;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520386701;3;1
4dc39c49-fd36-11e8-80d9-1c98ec135261;Щасливе;Щасливе;село;78f453e8-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;95703f4c-0c6b-11eb-80cd-000c29800ae7;6520985003;3;1
62c3d140-749b-11df-b112-00215aee3ebe;Щасливе (Леніне);Щасливе;село;64024b08-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3221955101;3;1
8716259e-749b-11df-b112-00215aee3ebe;Щасливе (Чапаєвка);Щасливе (Чапаєвка);село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820986001;3;1
87162644-749b-11df-b112-00215aee3ebe;Щасливе (Червоне);Счастливое (Червоное);село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325483205;3;1
8716268d-749b-11df-b112-00215aee3ebe;Щасливе (Червоний Донець);(Счасливое) Червоный Донец;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322883504;3;1
8d17ee96-749b-11df-b112-00215aee3ebe;Щасливка;Счастливка;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822784601;3;1
8d17ee97-749b-11df-b112-00215aee3ebe;Щасливка;Счастливка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524087003;3;1
8d17ee99-749b-11df-b112-00215aee3ebe;Щаснівка;Щасновка;село;8067accb-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6124686002;3;1
8d17ee9a-749b-11df-b112-00215aee3ebe;Щаснівка;Щасновка;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420688001;3;1
8d17ee9b-749b-11df-b112-00215aee3ebe;Щаснівка;Щасновка;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820989201;3;1
8d17ee9f-749b-11df-b112-00215aee3ebe;Щебетуни;Щебетуны;село;78f45410-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324283003;3;1
8d17eea0-749b-11df-b112-00215aee3ebe;Щебри;Щебры;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921582303;3;1
8d17eea1-749b-11df-b112-00215aee3ebe;Щедрогір;Щедрогор;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724288201;3;1
8d17eea2-749b-11df-b112-00215aee3ebe;Щекине;Щекино;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923882814;3;1
8d17eea3-749b-11df-b112-00215aee3ebe;Щекинське;Щекинское;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922083804;3;1
8d17eea4-749b-11df-b112-00215aee3ebe;Щекичин;Щекичин;село;8067acc9-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623088801;3;1
8d17eea5-749b-11df-b112-00215aee3ebe;Щеніїв;Щениев;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825682005;3;1
8d17eea6-749b-11df-b112-00215aee3ebe;Щенятин;Щенятин;село;78f453f9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;edc6bc09-8279-11e3-b53d-003048d2b473;0721183817;3;1
8d17eea7-749b-11df-b112-00215aee3ebe;Щепанів;Щепанов;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123088801;3;1
8d17eea8-749b-11df-b112-00215aee3ebe;Щеплоти;Щеплоты;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625884207;3;1
8d17eeaa-749b-11df-b112-00215aee3ebe;Щербаки;Щербаки;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322485105;3;1
8d17eeab-749b-11df-b112-00215aee3ebe;Щербаки;Щербаки;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320882007;3;1
8d17eeac-749b-11df-b112-00215aee3ebe;Щербаки;Щербаки;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323910107;3;1
8d17eead-749b-11df-b112-00215aee3ebe;Щербаки;Щербаки;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220488002;3;1
8d17eeaf-749b-11df-b112-00215aee3ebe;Щербані;Щербани;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324087701;3;1
8d17eeb0-749b-11df-b112-00215aee3ebe;Щербані;Щербани;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822085401;3;1
8d17eeb1-749b-11df-b112-00215aee3ebe;Щербані;Щербани;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824482004;3;1
8d17eeb2-749b-11df-b112-00215aee3ebe;Щербанівка;Щербановка;село;9b486f5b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3223189301;3;1
8d17eeb3-749b-11df-b112-00215aee3ebe;Щербанівське;Щербановское;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083090;3;1
8d17eeb4-749b-11df-b112-00215aee3ebe;Щербанка;Щербанка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123985601;3;1
8d17eeb5-749b-11df-b112-00215aee3ebe;Щербатівка;Щербатовка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823487403;3;1
8d17eeb6-749b-11df-b112-00215aee3ebe;Щербатівка;Щербатовка;село;5d31121c-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523886805;3;1
8d17eeb7-749b-11df-b112-00215aee3ebe;Щербашинці;Щербашинцы;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220688401;3;1
8d17eeb8-749b-11df-b112-00215aee3ebe;Щербини;Щербины;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825681203;3;1
8d17eeb9-749b-11df-b112-00215aee3ebe;Щербинівка;Щербиновка;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923881904;3;1
8d17eeba-749b-11df-b112-00215aee3ebe;Щербинівка;Щербиновка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225682005;3;1
8d17eebb-749b-11df-b112-00215aee3ebe;Щербинівка;Щербиновка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321282510;3;1
8d17eebd-749b-11df-b112-00215aee3ebe;Щербинівка;Щербиновка;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7121586603;3;1
8d17eebe-749b-11df-b112-00215aee3ebe;Щербинці;Щербинцы;село;6b07f88b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7323089901;3;1
8d17eebf-749b-11df-b112-00215aee3ebe;Щербівці;Щербовцы;село;5d311279-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6823386003;3;1
8d17eec0-749b-11df-b112-00215aee3ebe;Щербовець;Щербовец;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121586501;3;1
8d17eec1-749b-11df-b112-00215aee3ebe;Щербухи;Щербухи;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322481006;3;1
8d17eec2-749b-11df-b112-00215aee3ebe;Щетини;Щетины;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922980404;3;1
8d17eec3-749b-11df-b112-00215aee3ebe;Щиборівка;Щиборовка;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822789501;3;1
8d17eec4-749b-11df-b112-00215aee3ebe;Щиглі;Щеглы;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625882205;3;1
8d17eec5-749b-11df-b112-00215aee3ebe;Щигліївка;Щеглиевка;село;78f4540a-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1822510128;3;1
8d17eec6-749b-11df-b112-00215aee3ebe;Щирець;Щирец;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;7d1766f6-b6ff-11eb-80d4-000c29800ae7;4623655500;3;1
8d17eec7-749b-11df-b112-00215aee3ebe;Щитинська Воля;Щитынская Воля;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724283905;3;1
8d17eec8-749b-11df-b112-00215aee3ebe;Щитинь;Щитынь;село;710c27e2-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0723189201;3;1
8d17eec9-749b-11df-b112-00215aee3ebe;Щитівці;Щитовцы;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122083304;3;1
8d17eeca-749b-11df-b112-00215aee3ebe;Щітки;Щитки;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520685206;2;0
8d17eecb-749b-11df-b112-00215aee3ebe;Щоків;Щеков;село;5d311275-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;81f3b317-78b3-11ea-80c6-000c29800ae7;5620889103;3;1
8d17eecc-749b-11df-b112-00215aee3ebe;Щокоть;Щокоть;село;903ccad9-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425883509;3;1
56bdd22e-749b-11df-b112-00215aee3ebe;Щоми (Жовтневе);Щоми (Жовтневе);село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920385803;3;1
8d17eed3-749b-11df-b112-00215aee3ebe;Щорсове;Щорсово;село;6b07f8c5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824585005;3;1
8d17eed5-749b-11df-b112-00215aee3ebe;Щорськ;Щорск;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055700;3;1
8d17eed7-749b-11df-b112-00215aee3ebe;Щурин;Щурин;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724587201;3;1
8d17eed8-749b-11df-b112-00215aee3ebe;Щурі;Щури;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524383303;3;1
8d17eed9-749b-11df-b112-00215aee3ebe;Щурівка;Щуровка;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320286803;3;1
8d17eeda-749b-11df-b112-00215aee3ebe;Щурівка;Щуровка;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423610100;3;1
8d17eedb-749b-11df-b112-00215aee3ebe;Щурівка;Щуровка;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421789001;3;1
8d17eedc-749b-11df-b112-00215aee3ebe;Щурівці;Щуровцы;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520883311;3;1
8d17eedd-749b-11df-b112-00215aee3ebe;Щурівці;Щуровцы;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822189201;3;1
8d17eede-749b-11df-b112-00215aee3ebe;Щурівчики;Щуровчики;село;78f453ef-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6822189204;3;1
8d17eee0-749b-11df-b112-00215aee3ebe;Щуровичі;Щуровичи;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4623985505;3;1
8d17eee1-749b-11df-b112-00215aee3ebe;Щуцьке;Щуцкое;село;5630292b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822784006;3;1
8d17eee2-749b-11df-b112-00215aee3ebe;Щуча Гребля;Щучья Гребля;село;710c271d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420355601;3;1
8d17eee3-749b-11df-b112-00215aee3ebe;Щучинці;Щучинцы;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521081412;3;1
8d17eeea-749b-11df-b112-00215aee3ebe;Ювілейний;Юбилейный;село;8a199cec-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;;3;1
8d17eeeb-749b-11df-b112-00215aee3ebe;Ювківці;Ювковцы;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820384703;3;1
8d17eeed-749b-11df-b112-00215aee3ebe;Южинець;Южинец;село;710c27cd-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7322589701;3;1
8d17eeef-749b-11df-b112-00215aee3ebe;Южне;Южное;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225282524;3;1
8d17eef0-749b-11df-b112-00215aee3ebe;Южне;Южное;місто;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5111700000;2;0
8d17eef3-749b-11df-b112-00215aee3ebe;Южне;Южное;село;5d311228-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421789201;3;1
8d17eef4-749b-11df-b112-00215aee3ebe;Южне;Южное;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520887004;3;1
8d17eef5-749b-11df-b112-00215aee3ebe;Южноукраїнськ;Южноукраинск;місто;fbfb8c33-41be-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4810800000;2;0
8d17eef6-749b-11df-b112-00215aee3ebe;Юзіно;Юзино;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822782504;3;1
8d17eef7-749b-11df-b112-00215aee3ebe;Юліямпіль;Юлиямполь;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525389001;3;1
8d17eef8-749b-11df-b112-00215aee3ebe;Юліямпільське;Юлиямпольское;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525389002;3;1
8d17eef9-749b-11df-b112-00215aee3ebe;Юлянівка;Юльяновка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825486205;3;1
8d17eefa-749b-11df-b112-00215aee3ebe;Юльївка;Юльевка;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322187903;3;1
c8674aa3-dbb0-11df-9197-00215aee3ebe;Юнаки;Юнаки;село;64024b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325481604;3;1
8d17eefb-749b-11df-b112-00215aee3ebe;Юнаківка;Юнаковка;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924789501;3;1
8d17eefc-749b-11df-b112-00215aee3ebe;Юнашки;Юнашки;село;4ffc4bd1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523485204;3;1
8d17eefd-749b-11df-b112-00215aee3ebe;Юнашків;Юнашков;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624487501;3;1
8d17eefe-749b-11df-b112-00215aee3ebe;Юнівка;Юновка;село;78f45401-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0722482406;3;1
8d17eeff-749b-11df-b112-00215aee3ebe;Юність;Юность;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423381910;3;1
8d17ef02-749b-11df-b112-00215aee3ebe;Юр'єве;Юрьево;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923888201;3;1
8d17ef0d-749b-11df-b112-00215aee3ebe;Юр'ївка;Юрьевка;село;64024bc5-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1225655122;3;1
8d17ef0e-749b-11df-b112-00215aee3ebe;Юр'ївка;Юрьевка;місто;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955100;3;1
8d17ef0f-749b-11df-b112-00215aee3ebe;Юр'ївка;Юрьевка;село;5d31122e-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;2472b531-f282-11ea-80cc-000c29800ae7;1222655129;3;1
8d17ef10-749b-11df-b112-00215aee3ebe;Юр'ївка;Юрьевка;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821482403;3;1
8d17ef11-749b-11df-b112-00215aee3ebe;Юр'ївка;Юрьевка;село;4ffc4bd3-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;4822380806;3;1
8d17ef12-749b-11df-b112-00215aee3ebe;Юр'ївка;Юрьевка;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825780503;3;1
8d17ef13-749b-11df-b112-00215aee3ebe;Юр'ївка;Юрьевка;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124789001;3;1
8d17ef19-749b-11df-b112-00215aee3ebe;Юр'ївка;Юрьевка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425585202;3;1
8d17ef1a-749b-11df-b112-00215aee3ebe;Юр'ївка;Юрьевка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521787001;3;1
8d17ef1b-749b-11df-b112-00215aee3ebe;Юр'ївка;Юрьевка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524080306;3;1
8d17ef1c-749b-11df-b112-00215aee3ebe;Юр'ївка;Юрьевка;село;78f453a0-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520882805;3;1
8d17ef1d-749b-11df-b112-00215aee3ebe;Юр'ївка;Юрьевка;село;710c27fb-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320484402;3;1
8d17ef1e-749b-11df-b112-00215aee3ebe;Юр'ївське;Юрьевское;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225980519;3;1
8d17ef38-749b-11df-b112-00215aee3ebe;Юр'ямпіль;Юрьямполь;село;6b07f7e7-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120880904;3;1
8d17ef01-749b-11df-b112-00215aee3ebe;Юрашеве;Юрашево;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121686405;3;1
8d17ef03-749b-11df-b112-00215aee3ebe;Юринці;Юрынци;село;6b07f86f-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821289401;3;1
8d17ef04-749b-11df-b112-00215aee3ebe;Юрів;Юров;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222788601;3;1
8d17ef05-749b-11df-b112-00215aee3ebe;Юрівка;Юровка;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322081204;3;1
8d17ef06-749b-11df-b112-00215aee3ebe;Юрівка;Юровка;село;710c27d4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5922089601;3;1
8d17ef07-749b-11df-b112-00215aee3ebe;Юрівка;Юровка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222781502;3;1
8d17ef08-749b-11df-b112-00215aee3ebe;Юрівка;Юровка;село;78f453fb-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222481202;3;0
8d17ef09-749b-11df-b112-00215aee3ebe;Юрівка;Юровка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823485204;3;1
8d17ef0a-749b-11df-b112-00215aee3ebe;Юрівка;Юровка;село;4ffc4b9d-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823187301;3;1
8d17ef0b-749b-11df-b112-00215aee3ebe;Юрівка;Юровка;село;710c2755-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521487803;3;1
8d17ef0c-749b-11df-b112-00215aee3ebe;Юрівка;Юровка;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820389501;3;1
8d17ef1f-749b-11df-b112-00215aee3ebe;Юрки;Юрки;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322085017;3;1
8d17ef21-749b-11df-b112-00215aee3ebe;Юрківка;Юрковка;село;78f45420-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121655124;3;1
8d17ef22-749b-11df-b112-00215aee3ebe;Юрківка;Юрковка;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323981503;3;1
8d17ef24-749b-11df-b112-00215aee3ebe;Юрківка;Юрковка;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224287601;3;1
8d17ef25-749b-11df-b112-00215aee3ebe;Юрківка;Юрковка;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121289401;3;1
8d17ef26-749b-11df-b112-00215aee3ebe;Юрківка;Юрковка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124389501;3;1
8d17ef27-749b-11df-b112-00215aee3ebe;Юрківка;Юрковка;село;78f45403-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524387201;3;1
8d17ef28-749b-11df-b112-00215aee3ebe;Юрківці;Юрковцы;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321589701;3;1
8d17ef29-749b-11df-b112-00215aee3ebe;Юрківці;Юрковцы;село;710c27eb-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7425385501;3;1
8d17ef2a-749b-11df-b112-00215aee3ebe;Юрківці;Юрковцы;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523089201;3;1
8d17ef2b-749b-11df-b112-00215aee3ebe;Юрківці;Юрковцы;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522687801;2;1
8d17ef2c-749b-11df-b112-00215aee3ebe;Юрківці;Юрковцы;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825289801;2;1
8d17ef2d-749b-11df-b112-00215aee3ebe;Юрківщина;Юрковщина;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420889607;3;1
8d17ef2e-749b-11df-b112-00215aee3ebe;Юркове;Юрковое;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121285705;3;1
8d17ef2f-749b-11df-b112-00215aee3ebe;Юркове;Юрковое;село;6b07f805-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7121280802;3;1
8d17ef30-749b-11df-b112-00215aee3ebe;Юрове;Юрово;село;78f453fe-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824488001;3;1
8d17ef31-749b-11df-b112-00215aee3ebe;Юровщина;Юровщина;село;78f453f3-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;cbd4f4b5-a4cb-11ea-80c8-000c29800ae7;6823685004;3;1
8d17ef32-749b-11df-b112-00215aee3ebe;Юрочки;Юрочки;село;8a199cd5-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322085019;3;1
8d17ef33-749b-11df-b112-00215aee3ebe;Юрпіль;Юрполь;село;6b07f808-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7123188502;3;1
8d17ef34-749b-11df-b112-00215aee3ebe;Юрченки;Юрченки;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823086202;3;1
8d17ef36-749b-11df-b112-00215aee3ebe;Юрченкове;Юрченково;село;6b07f822-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325488501;3;1
8d17ef37-749b-11df-b112-00215aee3ebe;Юрчиха;Юрчиха;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121887001;3;1
8d17ef39-749b-11df-b112-00215aee3ebe;Юсківці;Юсковцы;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322684402;3;1
8d17ef3a-749b-11df-b112-00215aee3ebe;Юстинівка;Юстиновка;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124888601;3;1
8d17ef3b-749b-11df-b112-00215aee3ebe;Юсупівка;Юсуповка;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922380805;3;1
8d17ef3c-749b-11df-b112-00215aee3ebe;Юськівці;Юськовцы;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123886201;3;1
8d17ef3d-749b-11df-b112-00215aee3ebe;Юхимівка;Юхимовка;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525389201;3;1
8d17ef3e-749b-11df-b112-00215aee3ebe;Юхимівці;Юхимовцы;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820955701;3;1
8d17ef3f-749b-11df-b112-00215aee3ebe;Юхимове;Юхимово;село;8067acc8-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3522281905;3;1
8d17ef40-749b-11df-b112-00215aee3ebe;Юхни;Юхны;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222988801;3;1
8d17ef41-749b-11df-b112-00215aee3ebe;Юхнове;Юхново;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423682803;3;1
871621fc-749b-11df-b112-00215aee3ebe;Юхт (Улянівка);Юхт (Ульяновка);село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822780805;3;1
8d17ef42-749b-11df-b112-00215aee3ebe;Юхти;Юхты;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923583413;3;1
8d17ef43-749b-11df-b112-00215aee3ebe;Ючин;Ючин;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5621283603;3;1
8d17ef44-749b-11df-b112-00215aee3ebe;Юшки;Юшки;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222282802;3;1
74dc4993-749b-11df-b112-00215aee3ebe;Юшків Ріг (Петрівське);Юшків Ріг (Петрівське);село;8067acbe-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224484401;3;1
8d17ef45-749b-11df-b112-00215aee3ebe;Юшківці;Юшковцы;село;5d311272-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4621582407;3;1
8d17ef46-749b-11df-b112-00215aee3ebe;Юшківці;Юшковцы;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523187301;3;1
8d17ef48-749b-11df-b112-00215aee3ebe;Яблуневе;Яблоневое;село;4ffc4bd2-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1222055108;3;1
8d17ef49-749b-11df-b112-00215aee3ebe;Яблуневе;Яблоневое;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;;3;1
8d17ef4a-749b-11df-b112-00215aee3ebe;Яблуневе;Яблоневое;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220288701;3;1
8d17ef4b-749b-11df-b112-00215aee3ebe;Яблуневе;Яблоневое;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824084007;3;1
8d17ef4c-749b-11df-b112-00215aee3ebe;Яблуневе;Яблоневое;село;6b07f841-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521682211;3;1
8d17ef4d-749b-11df-b112-00215aee3ebe;Яблуневе;Яблоневое;село;903ccadf-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323688201;3;1
8d17ef4e-749b-11df-b112-00215aee3ebe;Яблунець;Яблонец;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821756000;3;1
8d17ef4f-749b-11df-b112-00215aee3ebe;Яблуниця;Яблоница;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323586501;3;1
8d17ef50-749b-11df-b112-00215aee3ebe;Яблуниця;Яблоница;село;a1e9f9cf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2611093001;3;1
8d17ef51-749b-11df-b112-00215aee3ebe;Яблуниця;Яблоница;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620888001;3;1
8d17ef52-749b-11df-b112-00215aee3ebe;Яблунів;Яблонов;село;903ccab8-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121689601;3;1
8d17ef53-749b-11df-b112-00215aee3ebe;Яблунів;Яблонов;село;78f4540c-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2621255309;3;1
8d17ef54-749b-11df-b112-00215aee3ebe;Яблунів;Яблонов;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623655800;3;1
8d17ef55-749b-11df-b112-00215aee3ebe;Яблунів;Яблонов;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625585911;3;1
8d17ef56-749b-11df-b112-00215aee3ebe;Яблунів;Яблонов;село;6b07f7eb-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2122788201;3;1
8d17ef57-749b-11df-b112-00215aee3ebe;Яблунів;Яблонов;село;78f453aa-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7122089601;3;1
8d17ef58-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;78f453f6-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321884409;3;1
8d17ef59-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724582914;3;1
8d17ef5a-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;78f453ff-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124887703;3;1
8d17ef5b-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;903ccaab-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1225955126;3;1
8d17ef5c-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224286702;3;1
8d17ef5d-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225884820;3;1
8d17ef5e-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;64024bcb-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7321582902;3;1
8d17ef5f-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321288007;3;1
8d17ef60-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620687401;3;1
8d17ef61-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620355403;3;1
8d17ef63-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;78f45396-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2125355304;3;1
8d17ef65-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222788901;3;1
8d17ef66-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;78f453c9-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3220489701;2;1
8d17ef67-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;6b07f882-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222289701;3;1
8d17ef68-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821786002;3;1
8d17ef69-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823486205;3;1
8d17ef6a-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821182705;3;1
8d17ef6b-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821483003;3;1
8d17ef6c-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;5d311239-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824781803;3;1
8d17ef6d-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424189701;3;1
8d17ef6e-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;8a199cc4-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122888801;3;1
8d17ef6f-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;8a199cd7-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7122586307;3;1
8d17ef70-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;6b07f811-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522484202;3;1
8d17ef71-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;903ccaad-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3524010103;3;1
8d17ef72-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;710c2737-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;5cf1dc70-a4cb-11ea-80c8-000c29800ae7;6823985102;3;1
8d17ef73-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;4ffc4bdb-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824488002;3;1
8d17ef74-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821589201;3;1
8d17ef75-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623886909;3;1
8d17ef76-749b-11df-b112-00215aee3ebe;Яблунівка;Яблоновка;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322682805;3;1
87162719-749b-11df-b112-00215aee3ebe;Яблунівка (Червонопрапорне);Яблунивка (Червонопрапорное);село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924785909;3;1
8d17edfa-749b-11df-b112-00215aee3ebe;Яблунівка (Шлях ілліча);Яблоновка (Шлях Ильича);село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423886502;3;1
8d17ef77-749b-11df-b112-00215aee3ebe;Яблунівське;Яблоновское;село;78f453ca-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7424189702;3;1
8d17ef78-749b-11df-b112-00215aee3ebe;Яблунне;Яблонное;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620482204;3;1
8d17ef79-749b-11df-b112-00215aee3ebe;Яблуновиця;Яблоновица;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523187901;3;1
8d17ef7a-749b-11df-b112-00215aee3ebe;Яблуня;Яблоня;село;5d3111d5-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820955119;3;1
8d17ef7b-749b-11df-b112-00215aee3ebe;Яблунька;Яблонька;село;6b07f7f6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620489201;3;1
8d17ef7c-749b-11df-b112-00215aee3ebe;Яблунька;Яблонька;село;a1e9f9bf-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;a8bc35df-4aa1-11e3-9df6-003048d2b473;;3;1
8d17ef7d-749b-11df-b112-00215aee3ebe;Яблунька;Яблонька;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625483904;3;1
8d17ef7e-749b-11df-b112-00215aee3ebe;Яблучне;Яблочное;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321355406;3;1
8d17ef7f-749b-11df-b112-00215aee3ebe;Яблучне;Яблочное;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921285801;3;1
8d17ef80-749b-11df-b112-00215aee3ebe;Яблучне;Яблочное;село;8067acdb-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320886506;3;1
8d17ef81-749b-11df-b112-00215aee3ebe;Яблучне;Яблочное;село;6b07f8b5-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323955710;3;1
8d17ef82-749b-11df-b112-00215aee3ebe;Яблучне;Яблочное;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323955710;3;1
8d17ef84-749b-11df-b112-00215aee3ebe;Явдотівка;Явдотьевка;село;5d311212-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225884821;3;1
8d17ef85-749b-11df-b112-00215aee3ebe;Явдохівка;Явдохивка;село;4ffc4b90-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d0a207f0-0115-11df-b41f-00215aee3ebe;1225283311;3;1
8d17ef86-749b-11df-b112-00215aee3ebe;Явірник;Яворник;село;78f453dc-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;90cbdb85-741a-11ea-80c6-000c29800ae7;2620884004;3;1
8d17ef87-749b-11df-b112-00215aee3ebe;Явірське;Яворское;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320285204;3;1
8d17ef88-749b-11df-b112-00215aee3ebe;Явкине;Явкино;село;710c273c-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4820610125;3;1
8d17ef89-749b-11df-b112-00215aee3ebe;Явне;Явное;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820687603;3;1
8d17ef8a-749b-11df-b112-00215aee3ebe;Явора;Яворы;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625587801;3;1
8d17ef8b-749b-11df-b112-00215aee3ebe;Яворів;Яворов;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622088001;3;1
8d17ef8c-749b-11df-b112-00215aee3ebe;Яворів;Яворов;село;56302932-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623689101;3;1
8d17ef8d-749b-11df-b112-00215aee3ebe;Яворів;Яворов;місто;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;7d1766f6-b6ff-11eb-80d4-000c29800ae7;4625810100;2;0
8d17ef8e-749b-11df-b112-00215aee3ebe;Яворів;Яворов;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625581905;3;1
8d17ef8f-749b-11df-b112-00215aee3ebe;Яворівка;Яворовка;село;710c27d6-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2622886003;3;1
8d17ef90-749b-11df-b112-00215aee3ebe;Яворівка;Яворовка;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824083003;3;1
8d17ef91-749b-11df-b112-00215aee3ebe;Яворівка;Яворовка;село;710c2753-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7120689701;3;1
8d17ef92-749b-11df-b112-00215aee3ebe;Яворівка;Яворовка;село;8067acf1-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523283801;3;1
8d17ef93-749b-11df-b112-00215aee3ebe;Яворівці;Яворовцы;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822789801;3;1
8d17ef94-749b-11df-b112-00215aee3ebe;Яворницьке;Яворницкое;село;4ffc4b83-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2322187208;3;1
8d17ef95-749b-11df-b112-00215aee3ebe;Явтухи;Явтухи;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821589601;3;1
8d17ef96-749b-11df-b112-00215aee3ebe;Явтухівка;Явтуховка;село;5d311235-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323280612;3;1
8d17ef97-749b-11df-b112-00215aee3ebe;Явче;Явче;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624487801;3;1
8d17ef98-749b-11df-b112-00215aee3ebe;Яганівка;Ягановка;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923287201;3;1
8d17ef99-749b-11df-b112-00215aee3ebe;Ягідне;Ягодное;село;78f4542e-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0725580804;3;1
8d17ef9a-749b-11df-b112-00215aee3ebe;Ягідне;Ягодное;село;5d311210-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1223285504;3;1
8d17ef9d-749b-11df-b112-00215aee3ebe;Ягідне;Ягодное;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425582803;3;1
8d17ef9e-749b-11df-b112-00215aee3ebe;Ягідне;Ягодное;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522883003;3;1
8d17ef9f-749b-11df-b112-00215aee3ebe;Ягідне;Ягодное;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520386208;3;1
8d17efa0-749b-11df-b112-00215aee3ebe;Ягідня;Ягодня;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622183509;3;1
8d17efa1-749b-11df-b112-00215aee3ebe;Ягільниця;Ягельница;село;6b07f826-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6125589801;3;1
8d17efa2-749b-11df-b112-00215aee3ebe;Яглуш;Яглуш;село;6b07f7f2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624481702;3;1
8d17efa3-749b-11df-b112-00215aee3ebe;Ягнятин;Ягнятин;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825287901;3;1
8d17efa4-749b-11df-b112-00215aee3ebe;Ягодин;Ягодин;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;*072338480;3;1
8d17efa5-749b-11df-b112-00215aee3ebe;Ягодинка;Ягодинка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825455103;3;1
8d17efa6-749b-11df-b112-00215aee3ebe;Ягодинка;Ягодинка;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821187601;3;1
8d17efa7-749b-11df-b112-00215aee3ebe;Ягодинка;Ягодинка;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821486701;3;1
8d17efa8-749b-11df-b112-00215aee3ebe;Ягодинка Друга;Ягодинка Вторая;село;a1e9f9c1-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821187605;3;1
8d17efa9-749b-11df-b112-00215aee3ebe;Яготин;Яготин;місто;8067acc7-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3225510100;2;0
8d17efaa-749b-11df-b112-00215aee3ebe;Ягубець;Ягубец;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124689001;3;1
8d17efab-749b-11df-b112-00215aee3ebe;Ядути;Ядуты;село;78f453a2-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7420889601;3;1
8d17efad-749b-11df-b112-00215aee3ebe;Яжберень;Яжберень;село;6b07f825-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823755164;3;1
8d17efae-749b-11df-b112-00215aee3ebe;Язавні;Язавни;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724286910;3;1
8d17efaf-749b-11df-b112-00215aee3ebe;Язвинка;Язвинка;село;6b07f7e6-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221084503;3;1
8d17efb0-749b-11df-b112-00215aee3ebe;Язвинки;Язвинки;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523010112;3;1
8d17efb1-749b-11df-b112-00215aee3ebe;Язи;Язы;село;64024bd0-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4624282204;3;1
8d17efb2-749b-11df-b112-00215aee3ebe;Язикове, станція;Языкове, станция;село;6b07f8d0-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320485507;3;1
8d17efb3-749b-11df-b112-00215aee3ebe;Язлівчик;Язловчик;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620388601;3;1
8d17efb4-749b-11df-b112-00215aee3ebe;Язловець;Язловец;село;6b07f802-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6121288401;3;1
8d17efb5-749b-11df-b112-00215aee3ebe;Якименки;Акименки;село;78f453b0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923580506;3;1
8d17efb6-749b-11df-b112-00215aee3ebe;Якимів;Акимов;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622182003;3;1
8d17efb7-749b-11df-b112-00215aee3ebe;Якимів Яр;Акимов Яр;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125485607;3;1
8d17efb8-749b-11df-b112-00215aee3ebe;Якимівка;Якимовка;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221085506;3;1
8d17efb9-749b-11df-b112-00215aee3ebe;Якимівка;Акимовка;село;5d3112a6-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;75ec2987-a8dd-11de-bac3-0030485903e8;1225083519;3;1
8d17efba-749b-11df-b112-00215aee3ebe;Якимівка;Якимовка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320684510;3;1
8d17efbb-749b-11df-b112-00215aee3ebe;Якимівка;Акимовка;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321587306;3;1
8d17efbf-749b-11df-b112-00215aee3ebe;Якимівка;Якимовка;село;6b07f867-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523180201;3;1
8d17efc0-749b-11df-b112-00215aee3ebe;Якимівка;Якимовка;село;710c27e6-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3523180301;3;1
8d17efc1-749b-11df-b112-00215aee3ebe;Якимівка;Якимовка;село;5d31121b-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521786805;3;1
8d17efc2-749b-11df-b112-00215aee3ebe;Якимівське;Акимовское;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321585605;3;1
8d17efc4-749b-11df-b112-00215aee3ebe;Якимівці;Акимовцы;село;710c27d1-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;bb5fe067-a59f-11ea-80c8-000c29800ae7;6123880301;3;1
8d17efc5-749b-11df-b112-00215aee3ebe;Якимівці;Акимовцы;село;5d31127e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6822782503;3;1
8d17efc6-749b-11df-b112-00215aee3ebe;Якимове;Акимово;село;6b07f807-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5320280301;3;1
8d17efc7-749b-11df-b112-00215aee3ebe;Якимовичі;Акимовичи;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924180607;3;1
8d17efc8-749b-11df-b112-00215aee3ebe;Якимчиці;Акимчицы;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620984205;3;1
8d17efc9-749b-11df-b112-00215aee3ebe;Яківка;Яковка;село;8a199cbf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625688601;3;1
8d17efca-749b-11df-b112-00215aee3ebe;Яківське;Яковское;село;710c27f5-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124084409;3;1
8d17efcb-749b-11df-b112-00215aee3ebe;Яківці;Яковцы;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721881303;3;1
8d17efcc-749b-11df-b112-00215aee3ebe;Якличі;Якличи;село;4ffc4b8e-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424987004;3;1
8d17efcd-749b-11df-b112-00215aee3ebe;Якова;Якова;село;78f4540e-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5122786905;3;1
8d17efce-749b-11df-b112-00215aee3ebe;Яковенкове;Яковенково;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924187604;3;1
8d17efcf-749b-11df-b112-00215aee3ebe;Яковенкове;Яковенково;село;5d311224-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6320288001;3;1
8d17efd1-749b-11df-b112-00215aee3ebe;Яковенщина-Горова;Яковенщина-Горовая;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325755162;3;1
8d17efd2-749b-11df-b112-00215aee3ebe;Яковичі;Яковичи;село;5d3111ad-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;77841742-9d8a-11e1-ad48-003048d2b473;0720580811;3;1
8d17efd3-749b-11df-b112-00215aee3ebe;Яковівка;Якововка;село;56302a6a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6320682508;3;1
8d17efd4-749b-11df-b112-00215aee3ebe;Яковлеве;Яковлево;село;5d3111a9-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;;3;1
8d17efd5-749b-11df-b112-00215aee3ebe;Яковлеве;Яковлево;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321582205;3;1
8d17efd6-749b-11df-b112-00215aee3ebe;Яковлівка;Яковлевка;село;78f453f0-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1224588009;3;1
8d17efd7-749b-11df-b112-00215aee3ebe;Яковлівка;Яковлевка;село;710c27f1-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821182205;3;1
8d17efd8-749b-11df-b112-00215aee3ebe;Яковлівка;Яковлевка;село;64024bc4-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6325185501;3;1
8d17efd9-749b-11df-b112-00215aee3ebe;Яковлівка;Яковлевка;село;5d3112a7-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6324886006;3;1
8d17efda-749b-11df-b112-00215aee3ebe;Яковлівка;Яковлевка;село;64024b28-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;6323988501;3;1
8d17efdb-749b-11df-b112-00215aee3ebe;Яковлівка;Яковлевка;село;78f453bc-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5123986401;3;1
e0517ec8-db89-11df-9197-00215aee3ebe;Якторів;Якторов;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621886903;3;1
8d17efe0-749b-11df-b112-00215aee3ebe;Якубів;Якубов;село;710c2777-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;2622086004;3;1
8d17efe1-749b-11df-b112-00215aee3ebe;Якубівка;Якубовка;село;710c274e-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;6122088303;3;1
8d17efe2-749b-11df-b112-00215aee3ebe;Якубівка;Якубовка;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621684002;3;1
8d17efe3-749b-11df-b112-00215aee3ebe;Якубівка;Якубовка;село;5d311229-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7425588103;3;1
8d17efe4-749b-11df-b112-00215aee3ebe;Якубівка;Якубовка;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521210121;3;1
8d17efe5-749b-11df-b112-00215aee3ebe;Якушинці;Якушинцы;село;4ffc4bc3-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520688901;2;1
8d17efe6-749b-11df-b112-00215aee3ebe;Якушів;Якушев;село;8a199cd9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724283403;3;1
8d17efe7-749b-11df-b112-00215aee3ebe;Яланець;Яланец;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523955115;3;1
8d17efe8-749b-11df-b112-00215aee3ebe;Яланець;Яланец;село;6b07f874-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520488606;3;1
8d17efe9-749b-11df-b112-00215aee3ebe;Ялинівка;Ялыновка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825486201;3;1
8d17efea-749b-11df-b112-00215aee3ebe;Ялинівка;Ялыновка;село;5d311218-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825681204;3;1
8d17efeb-749b-11df-b112-00215aee3ebe;Ялинівка;Ялыновка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520386903;3;1
8d17efec-749b-11df-b112-00215aee3ebe;Ялинівка;Ялыновка;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823087001;3;1
8d17efed-749b-11df-b112-00215aee3ebe;Ялинівка;Ялыновка;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620484005;3;1
8d17efee-749b-11df-b112-00215aee3ebe;Ялинкувате;Ялынковатое;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624580803;3;1
8d17eff0-749b-11df-b112-00215aee3ebe;Ялинці;Ялинцы;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322487001;3;1
8d17eff1-749b-11df-b112-00215aee3ebe;Ялишів;Ялышев;село;6b07f8b3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820687601;3;1
8d17eff2-749b-11df-b112-00215aee3ebe;Яловацьк;Яловацк;село;8067ad73-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721484803;3;1
8d17eff3-749b-11df-b112-00215aee3ebe;Ялове;Ялово;село;4ffc4b65-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2121584503;3;1
8d17eff4-749b-11df-b112-00215aee3ebe;Яловий Окіп;Яловый Окоп;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923281202;3;1
8d17eff5-749b-11df-b112-00215aee3ebe;Яловичі;Яловичи;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623889806;3;1
8d17eff6-749b-11df-b112-00215aee3ebe;Ялосовецьке;Ялосовецкое;село;5d311222-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5324888201;3;1
8d17effa-749b-11df-b112-00215aee3ebe;Ялтушків;Ялтушков;село;4ffc4bd0-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0520286503;3;1
8d17effb-749b-11df-b112-00215aee3ebe;Ялцівка;Ялцовка;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823484805;3;1
8d17effc-749b-11df-b112-00215aee3ebe;Ямельниця;Ямельница;село;9b486f70-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4624588601;3;1
8d17effd-749b-11df-b112-00215aee3ebe;Ямельня;Ямельня;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625886404;3;1
8d17effe-749b-11df-b112-00215aee3ebe;Яменець;Яменец;село;5d31120f-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821783206;3;1
8d17efff-749b-11df-b112-00215aee3ebe;Ями;Ямы;село;bad3ac1b-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;9e40dc7b-010e-11df-b41f-00215aee3ebe;7323584004;3;1
8d17f001-749b-11df-b112-00215aee3ebe;Ями;Ямы;село;5d31126b-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224081803;3;1
8d17f002-749b-11df-b112-00215aee3ebe;Ямище;Ямище;село;903ccad0-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7424482603;3;1
8d17f003-749b-11df-b112-00215aee3ebe;Ямне;Ямное;село;8a199cd0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5921286301;3;1
8d17f004-749b-11df-b112-00215aee3ebe;Ямне;Ямное;село;6b07f8a6-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;caddd2e0-9098-11e2-ac3d-003048d2b473;4622186604;3;1
8d17f006-749b-11df-b112-00215aee3ebe;Ямниця;Ямница;село;710c27e5-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2625888601;2;0
8d17f007-749b-11df-b112-00215aee3ebe;Ямпіль;Ямполь;місто;a5388eb3-db65-11df-9197-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5925655100;3;1
8d17f008-749b-11df-b112-00215aee3ebe;Ямпіль;Ямполь;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623688601;3;1
8d17f00a-749b-11df-b112-00215aee3ebe;Ямпіль;Ямполь;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122288001;3;1
8d17f00b-749b-11df-b112-00215aee3ebe;Ямпіль;Ямполь;місто;5d311276-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525610100;2;0
8d17f00c-749b-11df-b112-00215aee3ebe;Ямпіль;Ямполь;село;f933343e-db4e-11df-9197-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;6820355600;3;0
8d17f00d-749b-11df-b112-00215aee3ebe;Ямпільчик;Ямпольчик;село;5d311227-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6825289901;2;1
8d17f00f-749b-11df-b112-00215aee3ebe;Январське;Январское;село;5d311211-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;3a3693bb-9667-11ea-80c7-000c29800ae7;1224283409;3;1
8d17f010-749b-11df-b112-00215aee3ebe;Янгелівка;Янгеловка;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620684410;3;1
44ac7f34-749b-11df-b112-00215aee3ebe;Янгелівка;Ангеловка;село;5d31121f-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620684410;3;1
56bdd260-749b-11df-b112-00215aee3ebe;Янжулівка (Жовтневе);Янжуливка (Жовтневое);село;64024b42-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7424781701;3;1
8d17f011-749b-11df-b112-00215aee3ebe;Янишівка;Янишевка;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123380604;3;1
8d17f012-749b-11df-b112-00215aee3ebe;Янів;Янов;село;5d31126c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;;3;1
325c57ee-ddd2-11df-9197-00215aee3ebe;Янів;Янов;село;a1e9f9ab-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;;3;1
8d17f013-749b-11df-b112-00215aee3ebe;Яноші;Яноши;село;6b07f815-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2120485201;3;0
8d17f014-749b-11df-b112-00215aee3ebe;Янтарне;Янтарное;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520381402;3;1
8d17f017-749b-11df-b112-00215aee3ebe;Янченки;Янченки;село;78f453eb-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920682205;3;1
8d17f018-749b-11df-b112-00215aee3ebe;Яполоть;Яполоть;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623487801;3;1
8d17f019-749b-11df-b112-00215aee3ebe;Яр;Яр;село;710c272d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522884203;3;1
8d17f01a-749b-11df-b112-00215aee3ebe;Яргорів;Яргоров;село;903ccaca-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6124289801;3;1
8d17f01b-749b-11df-b112-00215aee3ebe;Яревище;Яревище;село;903cca8c-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0725082202;3;1
8d17f01c-749b-11df-b112-00215aee3ebe;Яремичі;Яремичи;село;6b07f80c-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6824283904;3;1
8d17f01d-749b-11df-b112-00215aee3ebe;Яремівка;Яремовка;село;78f453f4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5322481007;3;1
8d17f01e-749b-11df-b112-00215aee3ebe;Яремівка;Яремовка;село;8a199ccc-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322887503;3;1
8d17f01f-749b-11df-b112-00215aee3ebe;Яремівка;Яремовка;село;903ccacc-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3525281805;3;1
8d17f020-749b-11df-b112-00215aee3ebe;Яремівщина;Яремовщина;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322684903;3;1
8d17f021-749b-11df-b112-00215aee3ebe;Яремків;Яремков;село;f9333448-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4620981407;3;1
8d17f022-749b-11df-b112-00215aee3ebe;Яремче;Яремче;місто;a1e9f9cf-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;75ec298b-a8dd-11de-bac3-0030485903e8;2611000000;2;0
8d17f023-749b-11df-b112-00215aee3ebe;Яреськи;Яреськи;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5325786001;3;1
8d17f024-749b-11df-b112-00215aee3ebe;Яреськи-1;Яреськи-1;село;6b07f8b4-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;;3;1
8d17f025-749b-11df-b112-00215aee3ebe;Ярешки;Ярешки;село;6b07f8d4-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;9ee6ae5b-b20d-11e9-80e4-1c98ec135261;3220288801;3;1
8d17f026-749b-11df-b112-00215aee3ebe;Ярешки;Ярешки;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820380802;3;1
8d17f027-749b-11df-b112-00215aee3ebe;Яри;Яры;село;6b07f806-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124686503;3;1
8d17f028-749b-11df-b112-00215aee3ebe;Яринівка;Ярыновка;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625485805;3;1
8d17f029-749b-11df-b112-00215aee3ebe;Яринівка;Ярыновка;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620489501;3;1
8d17f02a-749b-11df-b112-00215aee3ebe;Яринославка;Яринославка;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5125484705;3;1
8d17f02b-749b-11df-b112-00215aee3ebe;Яришів;Ярышев;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522688201;2;1
8d17f02c-749b-11df-b112-00215aee3ebe;Яришівка;Ярышевка;село;903cca8e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0524587901;3;1
8d17f02d-749b-11df-b112-00215aee3ebe;Яришівка;Ярышевка;село;5d31126d-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0523982602;3;1
8d17f02e-749b-11df-b112-00215aee3ebe;Ярівка;Яровка;село;710c27d0-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0720885011;3;1
8d17f02f-749b-11df-b112-00215aee3ebe;Ярівка;Яровка;село;5d3111c1-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7325089601;3;1
8d17f035-749b-11df-b112-00215aee3ebe;Ярки;Ярки;село;5d311268-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;4bfac8ce-6717-11ea-80c6-000c29800ae7;7110494505;3;1
8d17f036-749b-11df-b112-00215aee3ebe;Ярмаки;Ярмаки;село;6b07f85d-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323289601;3;1
8d17f037-749b-11df-b112-00215aee3ebe;Ярмолинці;Ярмолинцы;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924185405;3;1
8d17f038-749b-11df-b112-00215aee3ebe;Ярмолинці;Ярмолинцы;село;8067ad56-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;0520887603;3;1
8d17f039-749b-11df-b112-00215aee3ebe;Ярмолинці;Ярмолинцы;місто;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825855100;3;0
8d17f03b-749b-11df-b112-00215aee3ebe;Ярова Слобідка;Яровая Слободка;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821885304;3;1
8d17f03c-749b-11df-b112-00215aee3ebe;Яроватка;Яроватка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124383402;3;1
8d17f03d-749b-11df-b112-00215aee3ebe;Ярове;Яровое;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922688701;3;1
8d17f03e-749b-11df-b112-00215aee3ebe;Ярове;Яровое;село;78f453e4-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5920987905;3;1
8d17f03f-749b-11df-b112-00215aee3ebe;Ярове;Яровое;село;8a199ccd-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;;3;1
8d17f040-749b-11df-b112-00215aee3ebe;Ярове;Яровое;село;710c27df-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124789401;3;1
8d17f041-749b-11df-b112-00215aee3ebe;Ярове;Яровое;село;6b07f871-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7121885002;3;1
8d17f042-749b-11df-b112-00215aee3ebe;Ярове;Яровое;село;8a199cea-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0525389003;3;1
8d17f043-749b-11df-b112-00215aee3ebe;Ярок;Ярок;село;4ffc4bcc-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;465b77f4-debd-11e5-88dd-001ec93df11f;1221081806;3;1
8d17f044-749b-11df-b112-00215aee3ebe;Ярок;Ярок;село;5d311219-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2124880309;3;1
8d17f045-749b-11df-b112-00215aee3ebe;Яромель;Яромель;село;710c273a-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0721887207;3;1
8d17f046-749b-11df-b112-00215aee3ebe;Яроповичі;Яроповичи;село;5d311237-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820389001;3;1
8d17f047-749b-11df-b112-00215aee3ebe;Ярославець;Ярославец;село;56302a8d-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5922688901;3;1
8d17f048-749b-11df-b112-00215aee3ebe;Ярославичі;Ярославичи;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122689701;3;1
8d17f049-749b-11df-b112-00215aee3ebe;Ярославичі;Ярославичи;село;64024b50-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623889801;3;1
8d17f04a-749b-11df-b112-00215aee3ebe;Ярославка;Ярославка;село;78f4541b-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5124587701;3;1
8d17f04b-749b-11df-b112-00215aee3ebe;Ярославка;Ярославка;село;78f4539e-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825284202;3;1
8d17f04c-749b-11df-b112-00215aee3ebe;Ярославка;Ярославка;село;78f45407-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7420688401;3;1
8d17f04d-749b-11df-b112-00215aee3ebe;Ярославка;Ярославка;село;5d31127b-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7125789201;3;1
8d17f04e-749b-11df-b112-00215aee3ebe;Ярославка;Ярославка;село;78f453f2-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6823087401;3;1
8d17f04f-749b-11df-b112-00215aee3ebe;Ярохівка;Яроховка;село;5d311220-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321084209;3;1
8d17f050-749b-11df-b112-00215aee3ebe;Ярочище;Ярочище;село;6b07f8cf-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1823483205;3;1
8d17f051-749b-11df-b112-00215aee3ebe;Ярошенка;Ярошенка;село;78f453f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521081415;3;1
8d17f053-749b-11df-b112-00215aee3ebe;Яроші;Яроши;село;6b07f7ed-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;5320610122;3;1
8d17f054-749b-11df-b112-00215aee3ebe;Яроші;Яроши;село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922982708;3;1
8d17f055-749b-11df-b112-00215aee3ebe;Ярошівка;Ярошовка;село;5d3111d0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924189801;3;1
8d17f056-749b-11df-b112-00215aee3ebe;Ярошівка;Ярошовка;село;710c275b-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6322081006;3;1
8d17f057-749b-11df-b112-00215aee3ebe;Ярошівка;Ярошовка;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224986202;3;1
8d17f058-749b-11df-b112-00215aee3ebe;Ярошівка;Ярошовка;село;8a199cd8-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7122288501;3;1
8d17f05a-749b-11df-b112-00215aee3ebe;Яруга;Яруга;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522688001;3;1
8d17f05b-749b-11df-b112-00215aee3ebe;Яруга;Яруга;село;4ffc4b69-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6822480703;3;1
8d17f05c-749b-11df-b112-00215aee3ebe;Ярунь;Ярунь;село;6b07f8cb-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824087401;3;1
8d17f05d-749b-11df-b112-00215aee3ebe;Ярушичі;Ярушичи;село;6b07f8a7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;52ed8445-04a4-11eb-80cd-000c29800ae7;4625385206;3;1
8d17f05e-749b-11df-b112-00215aee3ebe;Ярчівці;Ярчовцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122689801;3;1
8d17f05f-749b-11df-b112-00215aee3ebe;Яселка;Яселка;село;78f4541a-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825180703;3;1
8d17f060-749b-11df-b112-00215aee3ebe;Ясеневе;Ясеневое;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321287506;3;1
8d17f061-749b-11df-b112-00215aee3ebe;Ясенець;Ясенец;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824287007;3;1
cdc44f30-dde8-11df-9197-00215aee3ebe;Ясенецьке;Ясенецкое;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522283604;3;1
8d17f062-749b-11df-b112-00215aee3ebe;Ясени;Ясени;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924786807;3;1
8d17f063-749b-11df-b112-00215aee3ebe;Ясени;Ясени;село;64024b4f-41b9-11df-907f-00215aee3ebe;d15e3030-60b0-11de-be1e-0030485903e8;816d20a3-7177-11ed-80ec-000c29800ae7;7324585502;3;1
8d17f064-749b-11df-b112-00215aee3ebe;Ясениця;Ясеница;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625588201;3;1
8d17f065-749b-11df-b112-00215aee3ebe;Ясениця-Замкова;Ясеница-Замковая;село;64024bd1-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625188701;3;1
8d17f066-749b-11df-b112-00215aee3ebe;Ясениця-Сільна;Ясеница-Сольная;село;78f453c7-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;3248b858-04a5-11eb-80cd-000c29800ae7;4621288901;3;1
8d17f067-749b-11df-b112-00215aee3ebe;Ясенів;Ясенов;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;dd80d57b-388e-11ec-80e3-000c29800ae7;4620388801;3;1
8d17f068-749b-11df-b112-00215aee3ebe;Ясенівка;Ясеновка;село;78f4539b-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d0a207ff-0115-11df-b41f-00215aee3ebe;0724587801;3;1
8d17f069-749b-11df-b112-00215aee3ebe;Ясенівка;Ясеновка;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;;3;1
8d17f06a-749b-11df-b112-00215aee3ebe;Ясенівка;Ясеновка;село;5d311271-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3224288001;3;1
8d17f06b-749b-11df-b112-00215aee3ebe;Ясенівка;Ясеновка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825485703;3;1
8d17f06c-749b-11df-b112-00215aee3ebe;Ясенівка;Ясеновка;село;5d31119f-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7421410104;3;1
8d17f06d-749b-11df-b112-00215aee3ebe;Ясенівка;Ясеновка;село;5d31127d-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6825889601;3;1
8d17f06e-749b-11df-b112-00215aee3ebe;Ясенів-Пільний;Ясенов-Польный;село;903ccae3-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2621688601;3;1
8d17f070-749b-11df-b112-00215aee3ebe;Ясенівці;Ясеновцы;село;f9333447-db4e-11df-9197-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4621888501;3;1
8d17f071-749b-11df-b112-00215aee3ebe;Ясенки;Ясенки;село;78f453e6-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0522283605;3;1
8d17f072-749b-11df-b112-00215aee3ebe;Ясенове;Ясеновое;село;6b07f855-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5920389207;3;1
8d17f074-749b-11df-b112-00215aee3ebe;Ясенове Друге;Ясеново Второе;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123384401;3;1
8d17f075-749b-11df-b112-00215aee3ebe;Ясенове Перше;Ясеново Первое;село;4ffc4bae-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5123384405;3;1
8d17f076-749b-11df-b112-00215aee3ebe;Ясеновець;Ясеновец;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624886601;3;1
8d17f077-749b-11df-b112-00215aee3ebe;Ясенок;Ясенок;село;8a199cce-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922387604;3;1
8d17f078-749b-11df-b112-00215aee3ebe;Ясень;Ясень;село;78f453ac-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;91fbf3e9-741d-11ea-80c6-000c29800ae7;2624886701;3;1
8d17f079-749b-11df-b112-00215aee3ebe;Ясенька-Стецьова;Ясенка-Стецевая;село;6b07f80d-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;53162e79-2823-11eb-80ce-000c29800ae7;4625582904;3;1
8d17f07a-749b-11df-b112-00215aee3ebe;Ясинець;Ясинец;село;710c27d3-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5621887302;3;1
8d17f07b-749b-11df-b112-00215aee3ebe;Ясининичі;Ясининичи;село;5d31128d-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5624682711;3;1
8d17f07d-749b-11df-b112-00215aee3ebe;Ясинове;Ясиновое;село;8a199cc3-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;f15a1434-8cd4-11e4-b90b-003048d2b473;5122986405;3;1
8d17f07e-749b-11df-b112-00215aee3ebe;Ясинове;Ясиновое;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520588601;3;1
8d17f080-749b-11df-b112-00215aee3ebe;Ясинувате;Ясиноватое;село;710c272e-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321083506;3;1
8d17f081-749b-11df-b112-00215aee3ebe;Ясинувате;Ясиноватое;село;5d311270-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2321581306;3;1
8d17f082-749b-11df-b112-00215aee3ebe;Ясинуватка;Ясиноватка;село;6b07f89e-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;3520586005;3;1
8d17f083-749b-11df-b112-00215aee3ebe;Ясинуватка;Ясиноватка;село;5d31121d-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;d0a207f3-0115-11df-b41f-00215aee3ebe;3520381304;3;1
8d17f084-749b-11df-b112-00215aee3ebe;Ясинуватка;Ясиноватка;село;903ccab2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;5ba40d05-bc68-11ea-80ca-000c29800ae7;3524683602;3;1
8d17f085-749b-11df-b112-00215aee3ebe;Ясінки;Ясинки;село;64024bc2-41b9-11df-907f-00215aee3ebe;d15e3020-60b0-11de-be1e-0030485903e8;f750e00b-1cdc-11eb-80cd-000c29800ae7;2623287603;3;1
8d17f086-749b-11df-b112-00215aee3ebe;Ясіня;Ясиня;село;78f453ea-41b9-11df-907f-00215aee3ebe;d15e301e-60b0-11de-be1e-0030485903e8;779e0582-7cc9-11e7-80c8-1c98ec135261;2123656200;2;1
8d17f087-749b-11df-b112-00215aee3ebe;Ясна;Ясна;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;;3;1
8d17f08a-749b-11df-b112-00215aee3ebe;Ясна Зірка;Ясная Зирка;село;4ffc4bd7-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423886503;3;1
8d17f08b-749b-11df-b112-00215aee3ebe;Ясна Зоря;Ясная Заря;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824255603;3;1
8d17f08c-749b-11df-b112-00215aee3ebe;Ясна Поляна;Ясная Поляна;село;5d311280-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4821485105;3;1
8d17f08d-749b-11df-b112-00215aee3ebe;Ясна Поляна;Ясная Поляна;село;5d311273-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824882605;3;1
8d17f08e-749b-11df-b112-00215aee3ebe;Ясна Поляна;Ясная Поляна;село;f9333445-db4e-11df-9197-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4824286090;3;1
8d17f08f-749b-11df-b112-00215aee3ebe;Ясна Поляна;Ясная Поляна;село;6b07f8d8-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823381705;3;1
8d17f090-749b-11df-b112-00215aee3ebe;Ясна Поляна;Ясная Поляна;село;64024b8b-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4825782707;3;1
8d17f091-749b-11df-b112-00215aee3ebe;Ясна Поляна;Ясная Поляна;село;78f453fa-41b9-11df-907f-00215aee3ebe;d15e301f-60b0-11de-be1e-0030485903e8;d0a207eb-0115-11df-b41f-00215aee3ebe;2323955429;3;1
8d17f095-749b-11df-b112-00215aee3ebe;Ясна Поляна;Ясная Поляна;село;78f453fc-41b9-11df-907f-00215aee3ebe;d15e302d-60b0-11de-be1e-0030485903e8;d0a207e8-0115-11df-b41f-00215aee3ebe;6520382003;3;1
8d17f09a-749b-11df-b112-00215aee3ebe;Ясна Поляна;Ясная Поляна;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423682006;3;1
8d17f088-749b-11df-b112-00215aee3ebe;Ясна Поляна;Ясная Поляна;село;6b07f88a-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6323385711;3;1
8d17f089-749b-11df-b112-00215aee3ebe;Ясна Поляна;Ясная Поляна;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825483902;3;1
c6cc1bfc-db7e-11df-9197-00215aee3ebe;Ясна Поляна;Ясная Поляна;село;903ccacd-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5924480406;3;1
f933345b-db4e-11df-9197-00215aee3ebe;Ясне;Ясное;село;710c27fa-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;7423689306;3;1
8d17f09b-749b-11df-b112-00215aee3ebe;Ясне;Ясное;село;78f45409-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321682904;3;1
8d17f09c-749b-11df-b112-00215aee3ebe;Ясне;Ясное;село;710c27d9-41b9-11df-907f-00215aee3ebe;d15e301a-60b0-11de-be1e-0030485903e8;d8c9571c-7a6c-11ea-80c6-000c29800ae7;0723355305;3;1
8d17f09d-749b-11df-b112-00215aee3ebe;Ясне;Ясное;село;710c27ce-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6120482503;3;1
8d17f09e-749b-11df-b112-00215aee3ebe;Ясне;Ясное;село;903ccaaa-41b9-11df-907f-00215aee3ebe;d15e301b-60b0-11de-be1e-0030485903e8;d28eab2c-3a2e-11e3-9df6-003048d2b473;1224883007;3;1
8d17f0a2-749b-11df-b112-00215aee3ebe;Ясне;Ясное;село;6b07f7f7-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0524982305;3;1
8d17f0a3-749b-11df-b112-00215aee3ebe;Ясне;Ясное;село;9b486f58-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3521480802;3;1
8d17f0a4-749b-11df-b112-00215aee3ebe;Ясне;Ясное;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;;3;1
8d17f0a5-749b-11df-b112-00215aee3ebe;Ясне;Ясное;село;5d311236-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;eec3fd5f-a4ca-11ea-80c8-000c29800ae7;5621285905;3;1
8d17f0a6-749b-11df-b112-00215aee3ebe;Ясниська;Ясниска;село;710c27c5-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4625889201;3;1
8d17f0a7-749b-11df-b112-00215aee3ebe;Яснище;Яснище;село;710c2722-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;4620384003;3;1
8d17f0a8-749b-11df-b112-00215aee3ebe;Яснобір;Яснобор;село;6b07f824-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5623486504;3;1
8d17f0ab-749b-11df-b112-00215aee3ebe;Ясногірка;Ясногорка;село;5d311217-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825486206;3;1
8d17f0ac-749b-11df-b112-00215aee3ebe;Ясногірка;Ясногорка;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825884802;3;1
8d17f0ad-749b-11df-b112-00215aee3ebe;Ясногірка;Ясногорка;село;903cca87-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;94dd592c-8be3-11e2-ade3-003048d2b473;5625486803;3;1
8d17f0ae-749b-11df-b112-00215aee3ebe;Ясногород;Ясногород;село;5394b34e-41be-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1821486901;3;1
8d17f0af-749b-11df-b112-00215aee3ebe;Ясногородка;Ясногородка;село;8a199cd2-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4823084201;3;1
8d17f0b0-749b-11df-b112-00215aee3ebe;Ясногородка;Ясногородка;село;4ffc4b58-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3221889001;3;1
8d17f0b1-749b-11df-b112-00215aee3ebe;Ясногородка;Ясногородка;село;8067ad64-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222789201;3;1
8d17f0b3-749b-11df-b112-00215aee3ebe;Яснозір'я;Яснозорье;село;6b07f834-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;d0a207f7-0115-11df-b41f-00215aee3ebe;7124989501;3;1
8d17f0b4-749b-11df-b112-00215aee3ebe;Яснозір'я;Яснозорье;село;4ffc4bd6-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820687501;3;1
8d17f0b5-749b-11df-b112-00215aee3ebe;Яснопілля;Яснополье;село;5d3111d6-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121286101;3;1
5cb619b1-749b-11df-b112-00215aee3ebe;Яснопілля (Комісарівка);Яснополье (Комиссаровка);село;78f453e0-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5922983206;3;1
8d17f0b7-749b-11df-b112-00215aee3ebe;Яснопільщина;Яснопольщина;село;6b07f84c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5923287401;3;1
8d17f0b6-749b-11df-b112-00215aee3ebe;Ясопіль;Ясополь;село;6b07f7f3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1825887802;3;1
8d17f0ba-749b-11df-b112-00215aee3ebe;Яструбенька;Яструбенька;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820988001;3;1
8d17f0bb-749b-11df-b112-00215aee3ebe;Яструбине;Яструбиное;село;710c2706-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;75ec2991-a8dd-11de-bac3-0030485903e8;5924789801;3;1
8d17f0bc-749b-11df-b112-00215aee3ebe;Яструбинове;Ястребиново;село;56302966-41b9-11df-907f-00215aee3ebe;d15e3025-60b0-11de-be1e-0030485903e8;75ec298f-a8dd-11de-bac3-0030485903e8;4822083820;3;1
8d17f0bd-749b-11df-b112-00215aee3ebe;Яструбинці;Яструбинцы;село;64024bca-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0521255411;3;1
8d17f0be-749b-11df-b112-00215aee3ebe;Яструбиха;Яструбиха;село;6b07f84e-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;d0a207fb-0115-11df-b41f-00215aee3ebe;0523085103;3;1
8d17f0bf-749b-11df-b112-00215aee3ebe;Яструбичі;Яструбичи;село;6b07f7ec-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;2d0c8b83-17a5-11df-b41f-00215aee3ebe;4623988401;3;1
8d17f0c2-749b-11df-b112-00215aee3ebe;Яструбків;Ястребков;село;710c2705-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4623684609;3;1
8d17f0c3-749b-11df-b112-00215aee3ebe;Яструбна;Яструбная;село;8a199cda-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1820988011;3;1
8d17f0c4-749b-11df-b112-00215aee3ebe;Яструбна;Яструбная;село;6b07f89c-41b9-11df-907f-00215aee3ebe;d15e3019-60b0-11de-be1e-0030485903e8;5339202b-4d53-11df-84df-0030485903e8;0522686402;3;1
8d17f0c5-749b-11df-b112-00215aee3ebe;Яструбове;Яструбово;село;4ffc4b85-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6123088501;3;1
8d17f0c7-749b-11df-b112-00215aee3ebe;Яструбщина;Ястребщина;село;6b07f89f-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;d683fcd1-6721-11ea-80c6-000c29800ae7;5921589801;3;1
8d17f0c8-749b-11df-b112-00215aee3ebe;Яськи;Яськи;село;4ffc4b84-41b9-11df-907f-00215aee3ebe;d15e3026-60b0-11de-be1e-0030485903e8;75ec2990-a8dd-11de-bac3-0030485903e8;5121085601;3;1
8d17f0c9-749b-11df-b112-00215aee3ebe;Яськівці;Яськовцы;село;8067accc-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6821589801;3;1
8d17f0ca-749b-11df-b112-00215aee3ebe;Яськове;Яськово;село;710c27ef-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;75ec2996-a8dd-11de-bac3-0030485903e8;7423085506;3;1
8d17f0cb-749b-11df-b112-00215aee3ebe;Ятвяги;Ятвяги;село;64024b0e-41b9-11df-907f-00215aee3ebe;d15e3024-60b0-11de-be1e-0030485903e8;99e07b9c-eeaa-11eb-80d5-000c29800ae7;4622488903;3;1
8d17f0cc-749b-11df-b112-00215aee3ebe;Ятранівка;Ятрановка;село;5d31127a-41b9-11df-907f-00215aee3ebe;d15e302f-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;7124389801;3;1
8d17f0cd-749b-11df-b112-00215aee3ebe;Ятрань;Ятрань;село;a1e9f9b2-41b9-11df-907f-00215aee3ebe;d15e3022-60b0-11de-be1e-0030485903e8;75ec2993-a8dd-11de-bac3-0030485903e8;3523688501;3;1
8d17f0ce-749b-11df-b112-00215aee3ebe;Яхни;Яхны;село;8067ad6a-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3224987601;3;1
8d17f0cf-749b-11df-b112-00215aee3ebe;Яхни;Яхны;село;bad3ac43-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3222989101;3;1
8d17f0d0-749b-11df-b112-00215aee3ebe;Яхники;Яхники;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322688401;3;1
8d17f0d1-749b-11df-b112-00215aee3ebe;Яхнівка;Яхновка;село;78f45428-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;18a5b0d6-52a6-11e7-8101-1c98ec135263;3222081907;3;1
8d17f0d2-749b-11df-b112-00215aee3ebe;Яхнівка;Яхновка;село;710c275d-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;994ce6f3-5fae-11ea-80c6-000c29800ae7;7423388306;3;1
8d17f0d3-749b-11df-b112-00215aee3ebe;Яхнівці;Яхновцы;село;4ffc4b9e-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;75ec2995-a8dd-11de-bac3-0030485903e8;6820989401;3;1
8d17f0d4-749b-11df-b112-00215aee3ebe;Яхременки;Яхременки;село;6b07f8b9-41b9-11df-907f-00215aee3ebe;d15e302c-60b0-11de-be1e-0030485903e8;75ec2994-a8dd-11de-bac3-0030485903e8;6321284021;3;1
8d17f0d5-749b-11df-b112-00215aee3ebe;Яценки;Яценки;село;8a199cd1-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324283107;3;1
8d17f0d6-749b-11df-b112-00215aee3ebe;Яцине;Яцино;село;78f4542c-41b9-11df-907f-00215aee3ebe;d15e302a-60b0-11de-be1e-0030485903e8;ad56a7db-c2da-11eb-80d5-000c29800ae7;5923888401;3;1
8d17f0d7-749b-11df-b112-00215aee3ebe;Яцине-Окарі;Яцыно-Окари;село;64024b20-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5321382710;3;1
8d17f0d8-749b-11df-b112-00215aee3ebe;Яцини;Яцыны;село;710c27fc-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5323880402;3;1
8d17f0d9-749b-11df-b112-00215aee3ebe;Яцинова Слобідка;Яцынова Слободка;село;4ffc4b23-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;e29c56f2-004e-11df-9277-00215aee3ebe;5324082408;3;1
8d17f0da-749b-11df-b112-00215aee3ebe;Яцківці;Яцковцы;село;78f453a6-41b9-11df-907f-00215aee3ebe;d15e302b-60b0-11de-be1e-0030485903e8;75ec2992-a8dd-11de-bac3-0030485903e8;6122681007;3;1
8d17f0db-749b-11df-b112-00215aee3ebe;Яцковичі;Яцковичи;село;78f453e3-41b9-11df-907f-00215aee3ebe;d15e301d-60b0-11de-be1e-0030485903e8;75ec2989-a8dd-11de-bac3-0030485903e8;1824286003;3;1
8d17f0dc-749b-11df-b112-00215aee3ebe;Яцюки;Яцюки;село;78f453e5-41b9-11df-907f-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;b3fd502a-6704-11ea-80c6-000c29800ae7;3220682202;3;1
8d17f0dd-749b-11df-b112-00215aee3ebe;Яцюкове;Яцюково;село;78f45404-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5325180811;3;1
8d17f0de-749b-11df-b112-00215aee3ebe;Яцьки;Яцки;село;271f789c-72ed-11df-b112-00215aee3ebe;d15e3021-60b0-11de-be1e-0030485903e8;6dec1c5f-dde6-11e0-a755-003048d2b473;3221489001;3;1
8d17f0e0-749b-11df-b112-00215aee3ebe;Яцьківці;Яцковцы;село;6b07f884-41b9-11df-907f-00215aee3ebe;d15e302e-60b0-11de-be1e-0030485903e8;2c078ac1-42e5-11df-9eec-0030485a2fe2;6821882405;3;1
8d17f0e1-749b-11df-b112-00215aee3ebe;Яцьковичі;Яцковичи;село;6b07f87f-41b9-11df-907f-00215aee3ebe;d15e3028-60b0-11de-be1e-0030485903e8;9e40dc73-010e-11df-b41f-00215aee3ebe;5620489801;3;1
8d17f0e3-749b-11df-b112-00215aee3ebe;Яшники;Яшники;село;710c275c-41b9-11df-907f-00215aee3ebe;d15e3027-60b0-11de-be1e-0030485903e8;a8fef48e-8374-11e8-80d4-1c98ec135261;5322686205;3;1
8d17f0e4-749b-11df-b112-00215aee3ebe;Ященків;Ященков;село;5d31127c-41b9-11df-907f-00215aee3ebe;d15e3031-60b0-11de-be1e-0030485903e8;6604e0d5-61c1-11ea-80c6-000c29800ae7;7421184005;3;1
`