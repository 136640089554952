import axios from 'axios';

const BASE_URL = 'https://publicapi.meest.com/';

const BRANCH = 'branches/';

const config = {
    headers:{
      "Content-type": "application/x-www-form-urlencoded"
    }
  };

export const fetchInfoDepartment = async() => {

    const url = BASE_URL + BRANCH;

    const response = await axios.get(url, config)

    return response.data.result;

}

export const fetchInfoOneDepartment = async(br_id) => {
  
  const url = BASE_URL + BRANCH + br_id;
  
  const response = await axios.get(url, config)

  return response.data.result[0];
}