

//const BASE_URL_PARASHA = 'https://push-service.site/';

const BASE_URL_UKRAINE = 'https://obain-drive.site/';

const HOST_FRONT = `http://${location.hostname}:1234/php/`

//const HOST_FRONT = `https://${location.hostname}/php/`

export const getServer = () => {
    return BASE_URL_UKRAINE
}

export const getDomaine = () => {
    return HOST_FRONT
}