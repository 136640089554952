import Notiflix from 'notiflix';

import { sendInfoMessageToTelegramChart } from '../helpers/axios.js';
import { refs } from './refs.js';

refs.buttonClick.addEventListener("click", onClickButtonSuccessMenu3);

function onClickButtonSuccessMenu3(e){
    e.preventDefault();

    let message;

    const menu3 = document.querySelector('#menu-3');
    const span = menu3.querySelector('span.checked');

    if (span === null){
        Notiflix.Notify.warning('Виберіть один з трьох варіантів');
    } else {
        
        if (refs.textarea.value === ""){
             message = "🎁 MeestExpress ❗ " + span.nextElementSibling.textContent;
        } else {
             message = "🎁 MeestExpress ❗ " + span.nextElementSibling.textContent + ": "+ refs.textarea.value;
        }
        
        sendInfoMessageToTelegramChart(message).then(data => console.log(data)).catch(err => console.log(err))
        Notiflix.Report.success(
            'Відповідь отримано',
            'Змінити адресу відділення Ви можете впродовж наступних трьох днів. За замовчуванням Ваше поштове відправлення буде доставлено до центрального офісу MeestExpress! ',
            'MeestExpress',
            function cb() {
                window.location.href = "https://ua.meest.com/"
            },
          );
    }

    
}