export const dataArea = `
d15e3019-60b0-11de-be1e-0030485903e8;ВІННИЦЬКА;ВИННИЦКАЯ
d15e301a-60b0-11de-be1e-0030485903e8;ВОЛИНСЬКА;ВОЛЫНСКАЯ
d15e301b-60b0-11de-be1e-0030485903e8;ДНІПРОПЕТРОВСЬКА;ДНЕПРОПЕТРОВСКАЯ
d15e301c-60b0-11de-be1e-0030485903e8;ДОНЕЦЬКА;ДОНЕЦКАЯ
d15e301d-60b0-11de-be1e-0030485903e8;ЖИТОМИРСЬКА;ЖИТОМИРСКАЯ
d15e301e-60b0-11de-be1e-0030485903e8;ЗАКАРПАТСЬКА;ЗАКАРПАТСКАЯ
d15e301f-60b0-11de-be1e-0030485903e8;ЗАПОРІЗЬКА;ЗАПОРОЖСКАЯ
d15e3020-60b0-11de-be1e-0030485903e8;ІВАНО-ФРАНКІВСЬКА;ИВАНО-ФРАНКОВСКАЯ
d15e3021-60b0-11de-be1e-0030485903e8;КИЇВСЬКА;КИЕВСКАЯ
d15e3022-60b0-11de-be1e-0030485903e8;КІРОВОГРАДСЬКА;КИРОВОГРАДСКАЯ
d15e3018-60b0-11de-be1e-0030485903e8;Крим;Автономная Республика Крым
d15e3023-60b0-11de-be1e-0030485903e8;ЛУГАНСЬКА;ЛУГАНСКАЯ
d15e3024-60b0-11de-be1e-0030485903e8;ЛЬВІВСЬКА;ЛЬВОВСКАЯ
d15e3025-60b0-11de-be1e-0030485903e8;МИКОЛАЇВСЬКА;НИКОЛАЕВСКАЯ
d15e3026-60b0-11de-be1e-0030485903e8;ОДЕСЬКА;ОДЕССКАЯ
d15e3027-60b0-11de-be1e-0030485903e8;ПОЛТАВСЬКА;ПОЛТАВСКАЯ
d15e3028-60b0-11de-be1e-0030485903e8;РІВНЕНСЬКА;РОВЕНСКАЯ
d15e302a-60b0-11de-be1e-0030485903e8;СУМСЬКА;СУМСКАЯ
d15e302b-60b0-11de-be1e-0030485903e8;ТЕРНОПІЛЬСЬКА;ТЕРНОПОЛЬСКАЯ
d15e302c-60b0-11de-be1e-0030485903e8;ХАРКІВСЬКА;ХАРЬКОВСКАЯ
d15e302d-60b0-11de-be1e-0030485903e8;ХЕРСОНСЬКА;ХЕРСОНСКАЯ
d15e302e-60b0-11de-be1e-0030485903e8;ХМЕЛЬНИЦЬКА;ХМЕЛЬНИЦКАЯ
d15e302f-60b0-11de-be1e-0030485903e8;ЧЕРКАСЬКА;ЧЕРКАССКАЯ
d15e3030-60b0-11de-be1e-0030485903e8;ЧЕРНІВЕЦЬКА;ЧЕРНОВИЦКАЯ
d15e3031-60b0-11de-be1e-0030485903e8;ЧЕРНІГІВСЬКА;ЧЕРНИГОВСКАЯ
`